import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PrerequisiteGroup } from 'common-typescript/types';
import { ModalService } from 'sis-common/modal/modal.service';

export interface PrerequisiteGroupEditValues {
    prerequisiteGroup: PrerequisiteGroup;
}

@Component({
    selector: 'sis-prerequisite-group-edit-modal',
    template: '<prerequisite-group-edit-modal (close)="activeModal.close($event.$value)" (dismiss)="activeModal.dismiss()" [resolve]="values"></prerequisite-group-edit-modal>',
    encapsulation: ViewEncapsulation.None,
})
export class PrerequisiteGroupEditModalComponent {
    constructor(@Inject(ModalService.injectionToken) public values: PrerequisiteGroupEditValues,
                public activeModal: NgbActiveModal) {}
}
