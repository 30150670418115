import { Component, ExistingProvider, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { Education, OtmId, StudyRight } from 'common-typescript/types';
import _ from 'lodash';
import { combineLatest, of, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { AuthService } from 'sis-common/auth/auth-service';
import { ModalService } from 'sis-common/modal/modal.service';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { EducationEntityService } from 'sis-components/service/education-entity.service';
import { StudentContextAttainmentService } from 'sis-components/service/student-context-attainment.service';
import { StudyRightEntityService, StudyRightExpirationInfo } from 'sis-components/service/study-right-entity.service';
import { UniversityService } from 'sis-components/service/university.service';

import { MyAttainmentService } from '../../../common/service/my-attainment.service';
import { StudyRightExtensionApplyingService } from '../../../common/service/study-right-extension-applying.service';
import { CreateStudyCertificateModalComponent } from '../create-study-certificate-modal/create-study-certificate-modal.component';

@Component({
    selector: 'app-study-rights',
    templateUrl: './study-rights.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [
        // provided to ReadOnlyStudyRightComponent
        <ExistingProvider>{ provide: StudentContextAttainmentService, useExisting: MyAttainmentService },
    ],
})
export class StudyRightsComponent implements OnInit, OnDestroy {
    studyRights: StudyRight[] = [];
    studyRightExpirations: { [index: string]: StudyRightExpirationInfo } = {};
    educations: { [educationId: string]: Education } = {};
    degreeStudyRights: StudyRight[] = [];
    openUniversityStudyRights: StudyRight[] = [];
    oldOpenUniversityStudyRights: StudyRight[] = [];
    otherStudyRights: StudyRight[] = [];
    otherBasedOnEnrolmentRightStudyRights: StudyRight[] = [];
    ready = false;
    studyRightExtensionApplicationEnabledByUniversity = false;
    destroyed$ = new Subject<void>();
    openUniversityEducationUrn = 'urn:code:education-type:non-degree-education:open-university-studies';
    degreeEducationUrn = 'urn:code:education-type:degree-education';

    constructor(
        private studyRightEntityService: StudyRightEntityService,
        private educationEntityService: EducationEntityService,
        private universityService: UniversityService,
        private stateService: StateService,
        private modalService: ModalService,
        private appErrorHandler: AppErrorHandler,
        private authService: AuthService,
        private readonly _studyRightExtensionApplyingService: StudyRightExtensionApplyingService,
    ) { }

    ngOnInit(): void {
        this.universityService.getCurrentUniversitySettings()
            .pipe(
                takeUntil(this.destroyed$),
                this.appErrorHandler.defaultErrorHandler())
            .subscribe((universitySettings) => {
                const extensionEnabled = _.get(universitySettings, 'frontendFeatureToggles.studyRightExtensionApplicationEnabled');
                this.studyRightExtensionApplicationEnabledByUniversity = extensionEnabled !== false;
            });

        this.studyRightEntityService.getStudyRightsForCurrentUser().pipe(
            switchMap((studyRights: StudyRight[]) => {
                const educationIds = _.uniq(studyRights.map(sr => sr.educationId));

                return combineLatest([
                    of(studyRights),
                    this.studyRightEntityService.getStudyRightExpirationInfosForStudyRights(this.authService.personId(), studyRights),
                    this.educationEntityService.getByIds(educationIds),
                ]);
            }),
            this.appErrorHandler.defaultErrorHandler(),
            takeUntil(this.destroyed$),
        ).subscribe(([studyRights, expirationInfos, educations]) => {
            expirationInfos.forEach(expirationInfo => this.studyRightExpirations[expirationInfo.studyRightId] = expirationInfo);
            educations.forEach(education => this.educations[education.id] = education);
            this.filterAndSortStudyRights(studyRights);
            this.ready = true;
        });
    }

    filterAndSortStudyRights(studyRights: StudyRight[]): void {
        this.degreeStudyRights = this.sortStudyRightsByStartDate(this.getDegreeStudyRights(studyRights));
        this.openUniversityStudyRights = this.sortStudyRightsByStartDate(this.getOpenUniversityStudyRights(studyRights, true));
        this.oldOpenUniversityStudyRights = this.sortStudyRightsByStartDate(this.getOpenUniversityStudyRights(studyRights, false));
        this.otherStudyRights = this.sortStudyRightsByStartDate(this.getOtherStudyRights(studyRights));
        this.otherBasedOnEnrolmentRightStudyRights = this.sortStudyRightsByStartDate(this.getOtherBasedOnEnrolmentRightStudyRights(studyRights));
    }

    getDegreeStudyRights(studyRights: StudyRight[]): StudyRight[] {
        return studyRights.filter(studyRight =>
            this.checkEducationType(studyRight, this.degreeEducationUrn));
    }

    getOpenUniversityStudyRights(studyRights: StudyRight[], basedOnEnrolmentRights: boolean): StudyRight[] {
        return studyRights.filter(studyRight =>
            this.checkEducationType(studyRight, this.openUniversityEducationUrn) &&
                                      (this.isBasedOnEnrolmentRights(studyRight) === basedOnEnrolmentRights));
    }

    getOtherStudyRights(studyRights: StudyRight[]): StudyRight[] {
        return studyRights.filter(studyRight =>
            !this.checkEducationType(studyRight, this.degreeEducationUrn) &&
                !this.checkEducationType(studyRight, this.openUniversityEducationUrn) &&
        !this.isBasedOnEnrolmentRights(studyRight));
    }

    getOtherBasedOnEnrolmentRightStudyRights(studyRights: StudyRight[]): StudyRight[] {
        return studyRights.filter(studyRight =>
            !this.checkEducationType(studyRight, this.degreeEducationUrn) &&
                                      !this.checkEducationType(studyRight, this.openUniversityEducationUrn) &&
        this.isBasedOnEnrolmentRights(studyRight));
    }

    private isBasedOnEnrolmentRights(studyRight: StudyRight): boolean {
        // this is same as studyRight.basedOnEnrolmentRights
        return studyRight.basedOnEnrolmentRights; // OTM-32348 temporary fix
    }

    sortStudyRightsByStartDate(studyRights: StudyRight[]): StudyRight[] {
        return _.sortBy(studyRights, 'valid.startDate');
    }

    checkEducationType(studyRight: StudyRight, educationType: string): boolean {
        return this.educations[studyRight.educationId]?.educationType.includes(educationType) ?? false;
    }

    openCreateStudyCertificateModal(): void {
        this.modalService.open(CreateStudyCertificateModalComponent, null);
    }

    initStudyRightExtensionApplication(studyRight: StudyRight): void {
        this._studyRightExtensionApplyingService.checkRequirementsAndProceedToApplication(studyRight);
    }

    getStudyRightExpiration(studyRightId: OtmId): StudyRightExpirationInfo {
        return this.studyRightExpirations[studyRightId];
    }

    openStudyRightExtensionApplication(studyRightId: OtmId) {
        const applicationId = this.getStudyRightExpiration(studyRightId)?.pendingExtensionApplication?.id;
        if (applicationId) {
            this.stateService.go('student.logged-in.profile.applications.study-right-extension-application', { applicationId });
        }
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
    }
}
