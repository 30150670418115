<sis-course-unit-box
  [courseUnitGroupId]="selection.courseUnitGroupId"
  [attainment]="attainment">
  <div course-unit-box="right-of-title" *ngIf="canDelete">
    <div class="extra-items-holder">
      <div class="additional-box">
        <sis-button class="button-box" link="true"
                    (clicked)="deleteCallback.emit()"
                    [ariaLabel]="'ARIA_LABEL.DELETE' |translate">
          <sis-icon icon="delete"></sis-icon>
        </sis-button>
      </div>
    </div>
  </div>

  <div course-unit-box="notifications">
    <div *ngIf="selection.validityPeriod">
      <ng-container *ngIf="selection.validityPeriod.endDate && !(selection.validityPeriod|dateRangePast)"
                    [ngTemplateOutlet]="notification"
                    [ngTemplateOutletContext]="{icon:'open', key:'PROFILE.STUDY_RIGHT_SELECTIONS.VALID_PERIOD', params:{range:(selection.validityPeriod | localDateRange)}}">
      </ng-container>

      <ng-container *ngIf="selection.validityPeriod|dateRangePast"
                    [ngTemplateOutlet]="notification"
                    [ngTemplateOutletContext]="{icon:'close', key:'PROFILE.STUDY_RIGHT_SELECTIONS.VALIDITY_ENDED', params:{endDate:(selection.validityPeriod.endDate|previousDay|localDateFormat)}}">
      </ng-container>

      <ng-container *ngIf="!selection.validityPeriod.endDate"
                    [ngTemplateOutlet]="notification"
                    [ngTemplateOutletContext]="{icon:'open', key:'PROFILE.STUDY_RIGHT_SELECTIONS.VALID_FROM', params:{startDate:(selection.validityPeriod.startDate|localDateFormat)}}">
      </ng-container>
    </div>
  </div>
</sis-course-unit-box>

<ng-template #notification let-key="key" let-params="params" let-icon="icon">
  <sis-tooltip [tooltipText]="key|translate:params">
    <div class="cu-notification sis-tooltip-trigger-content">
      <span class="cu-notification-header">
        <sis-icon [icon]="icon==='open' ? 'lock-open' : 'lock'"></sis-icon>
      </span>
      <span class="cu-notification-content"
            [translate]="key"
            [translateParams]="params"></span>
    </div>
  </sis-tooltip>
</ng-template>
