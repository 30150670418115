organisationJSDataModel.$inject = ["DS"];
import * as angular from 'angular';
import 'js-data';
export const organisationModelModule = 'sis-components.model.organisation';
angular.module(organisationModelModule, ['js-data']).factory('organisationJSDataModel', organisationJSDataModel);

/**
 * @ngInject
 */
function organisationJSDataModel(DS) {
  return DS.defineResource({
    idAttribute: 'id',
    name: 'organisation',
    endpoint: '/kori/api/organisations',
    computed: {
      matchedName: ['name', function (name) {
        if (name) {
          return name.fi || name.en || name.sv;
        }
        return '';
      }]
    }
  });
}