import { InjectionToken } from '@angular/core';
import {
    CompositeRule,
    CourseUnit,
    CustomCourseUnitAttainment,
    CustomModuleAttainment,
    CustomStudyDraft,
    EntityWithRule,
    LocalId,
    Module,
    OtmId,
} from 'common-typescript/types';
import { Subject } from 'rxjs';

import { PlanOperation } from '../plan-manager/plan-manager.service';

export enum UiOperationType {
    OPEN_CUSTOM_STUDY_DRAFT_CREATION_MODAL = 'OpenCustomStudyDraftCreationModal',
    OPEN_CUSTOM_STUDY_DRAFT_INFO_MODAL = 'OpenCustomStudyDraftInfoModal',
    OPEN_CUSTOM_ATTAINMENT_INFO_MODAL = 'OpenCustomAttainmentInfoModal',
    OPEN_MODULE = 'OpenModule',
    OPEN_MODULE_INFO_MODAL = 'OpenModuleInfoModal',
    OPEN_COURSE_UNIT_INFO_MODAL = 'OpenCourseUnitInfoModal',
    CLOSE_AND_SAVE = 'CloseAndSave',
}

export interface UiOperation {
    uiOperationType: UiOperationType;
    target: CourseUnit | EntityWithRule | CustomCourseUnitAttainment | CustomModuleAttainment | CustomStudyDraft | OtmId;
}

export const PLAN_ACTIONS_SERVICE_INJECTION_TOKEN = new InjectionToken<PlanActionsService>('PlanActionsService');

export interface PlanActionsService {
    planOperationSubject: Subject<PlanOperation>;
    uiOperationSubject: Subject<UiOperation>;

    openCustomStudyDraftCreationModal?: (parentModule: EntityWithRule) => void;
    openCustomStudyDraftInfoModal?: (customStudyDraft: CustomStudyDraft) => void;
    openCustomCourseUnitAttainmentInfoModal?: (customCourseUnitAttainment: CustomCourseUnitAttainment) => void;
    openCourseUnitInfoModal?: (courseUnit: CourseUnit) => void;
    openModuleInfoModal?: (module: Module) => void;
    openModule?: (module: EntityWithRule) => void;
    closeAndSave?: () => void;

    selectCourseUnit?: (courseUnit: CourseUnit, parentModule: EntityWithRule) => void;
    forceSelectCourseUnitById?: (courseUnitId: OtmId, parentModule: EntityWithRule) => void;
    selectCourseUnitByGroupId?: (courseUnitGroupId: OtmId, parentModule: EntityWithRule) => void;
    removeCourseUnit?: (courseUnit: CourseUnit, parentModule: EntityWithRule) => void;
    forceRemoveCourseUnit?: (courseUnit: CourseUnit, parentModule: EntityWithRule) => void;
    moveCourseUnit?: (courseUnit: CourseUnit, parentModule: EntityWithRule) => void;
    selectModule?: (module: EntityWithRule, parentModule: EntityWithRule) => void;
    forceSelectModuleById?: (moduleId: OtmId, parentModule: EntityWithRule) => void;
    selectModuleByGroupId?: (moduleGroupId: OtmId, parentModule: EntityWithRule) => void;
    removeModule?: (module: EntityWithRule, parentModule: EntityWithRule) => void;
    forceRemoveModule?: (module: Module, parentModule: EntityWithRule) => void;
    activateRuleGroup?: (rule: CompositeRule, parentModule: EntityWithRule) => void;
    deactivateRuleGroup?: (rule: CompositeRule, parentModule: EntityWithRule) => void;
    selectCustomCourseUnitAttainment?: (customCourseUnitAttainment: CustomCourseUnitAttainment, parentModule: EntityWithRule) => void;
    removeCustomCourseUnitAttainmentById?: (customCourseUnitAttainmentId: OtmId, parentModule: EntityWithRule) => void;
    selectCustomModuleAttainment?: (customModuleAttainment: CustomModuleAttainment, parentModule: EntityWithRule) => void;
    removeCustomModuleAttainmentById?: (customModuleAttainmentId: OtmId, parentModule: EntityWithRule) => void;
    addCustomStudyDraft?: (customStudyDraft: CustomStudyDraft, parentModule: EntityWithRule) => void;
    removeCustomStudyDraft?: (customStudyDraft: CustomStudyDraft, parentModule: EntityWithRule) => void;
    selectCompletionMethod?: (courseUnit: CourseUnit, completionMethodId: LocalId) => void;
    addAssessmentItemSelection?: (courseUnit: CourseUnit, assessmentItemId: OtmId) => void;
    removeAssessmentItemSelection?: (courseUnit: CourseUnit, assessmentItemId: OtmId) => void;
    changeCourseUnitVersion?: (oldCourseUnitVersion: CourseUnit, newCourseUnitVersion: CourseUnit) => void;
}
