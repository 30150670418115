import * as _ from 'lodash';

import {
    AssessmentItem,
    Attainment,
    CourseUnit,
    CustomCourseUnitAttainment,
    CustomModuleAttainment,
    CustomStudyDraft,
    EntityWithRule,
    ModuleContentApprovalState,
    OtmId,
    PlanValidationState,
} from '../../../../types';
import { Range } from '../../../model/range';
import { PlanValidationStateService } from '../../../service/planValidationState.service';

export class RuleContext {

    implicitCourseUnitIds: OtmId[] = [];
    implicitModuleIds: OtmId[] = [];
    matchingModulesByGroupId: { [groupId: string]: EntityWithRule } = {};
    matchingCourseUnitsByGroupId: { [groupId: string]: CourseUnit } = {};
    matchingAssessmentItemsById: { [id: string]: AssessmentItem } = {};
    matchingCustomModuleAttainmentsById: { [id: string]: CustomModuleAttainment } = {};
    matchingCustomCourseUnitAttainmentsById: { [id: string]: CustomCourseUnitAttainment } = {};
    matchingCustomStudyDraftsById: { [id: string]: CustomStudyDraft } = {};

    moduleContentApprovalValidationState: (ModuleContentApprovalState | null) = null;

    plannedCredits: Range = Range.zero();
    attainedCredits = 0;

    state: PlanValidationState = PlanValidationState.EMPTY;
    contextualState: PlanValidationState = PlanValidationState.EMPTY;

    static mergeAttainment(ruleContext: RuleContext, attainment: Attainment) {
        ruleContext.mergeState(PlanValidationState.ATTAINED);
        ruleContext.plannedCredits = ruleContext.plannedCredits.add(new Range(attainment.credits));
        ruleContext.attainedCredits += attainment.credits;
    }

    static addCredits(ruleContext: RuleContext, other: RuleContext, partialCredits: number | null) {
        if (partialCredits) {
            ruleContext.plannedCredits = ruleContext.plannedCredits.add(new Range(partialCredits));
            // Merge only fully attained credits as we do not know which part is attained
            if (ruleContext.state === PlanValidationState.ATTAINED) {
                ruleContext.attainedCredits += partialCredits;
            }
        } else {
            ruleContext.plannedCredits = ruleContext.plannedCredits.add(other.plannedCredits);
            ruleContext.attainedCredits += other.attainedCredits;
        }
    }

    static addSelections(ruleContext: RuleContext, other: RuleContext) {
        _.assign(ruleContext.matchingModulesByGroupId, other.matchingModulesByGroupId);
        _.assign(ruleContext.matchingCourseUnitsByGroupId, other.matchingCourseUnitsByGroupId);
        _.assign(ruleContext.matchingAssessmentItemsById, other.matchingAssessmentItemsById);
        _.assign(ruleContext.matchingCustomCourseUnitAttainmentsById, other.matchingCustomCourseUnitAttainmentsById);
        _.assign(ruleContext.matchingCustomModuleAttainmentsById, other.matchingCustomModuleAttainmentsById);
        _.assign(ruleContext.matchingCustomStudyDraftsById, other.matchingCustomStudyDraftsById);
    }

    getActualCredits(): Range {
        if (this.state === PlanValidationState.ATTAINED) {
            return new Range(this.attainedCredits);
        }
        return this.plannedCredits;
    }

    addPlannedCredits(credits: Range) {
        this.mergeState(PlanValidationState.PLANNED);
        this.plannedCredits = this.plannedCredits.add(credits);
    }

    addModule(module: EntityWithRule) {
        this.matchingModulesByGroupId[module.groupId] = module;
    }

    addCourseUnit(courseUnit: CourseUnit) {
        this.matchingCourseUnitsByGroupId[courseUnit.groupId] = courseUnit;
    }

    addImplicitModule(module: EntityWithRule) {
        this.implicitModuleIds.push(module.id);
    }

    addImplicitCourseUnit(courseUnit: CourseUnit) {
        this.implicitCourseUnitIds.push(courseUnit.id);
    }

    addAssessmentItem(assessmentItem: AssessmentItem) {
        this.matchingAssessmentItemsById[assessmentItem.id] = assessmentItem;
    }

    addCustomModuleAttainment(customModuleAttainment: CustomModuleAttainment) {
        this.matchingCustomModuleAttainmentsById[customModuleAttainment.id] = customModuleAttainment;
    }

    addCustomCourseUnitAttainment(customCourseUnitAttainment: CustomCourseUnitAttainment) {
        this.matchingCustomCourseUnitAttainmentsById[customCourseUnitAttainment.id] = customCourseUnitAttainment;
    }

    addCustomStudyDraft(customStudyDraft: CustomStudyDraft) {
        this.matchingCustomStudyDraftsById[customStudyDraft.id] = customStudyDraft;
    }

    mergeState(state: PlanValidationState) {
        this.state = PlanValidationStateService.higherPriorityOf(this.state, state);
        this.contextualState = PlanValidationStateService.higherPriorityOf(this.contextualState, state);
    }

    mergePlanValidationState(state: PlanValidationState) {
        this.state = PlanValidationStateService.higherPriorityOf(this.state, state);
    }

    mergeContextualState(state: PlanValidationState) {
        this.contextualState = PlanValidationStateService.higherPriorityOf(this.contextualState, state);
    }

    mergeStatesFromOtherContext(other: RuleContext) {
        this.state = PlanValidationStateService.higherPriorityOf(this.state, other.state);
        this.contextualState = PlanValidationStateService.higherPriorityOf(this.contextualState, other.contextualState);
    }

    mergeModuleAttainment(attainment: Attainment) {
        RuleContext.mergeAttainment(this, attainment);
    }

    mergeCourseUnitAttainment(attainment: Attainment) {
        RuleContext.mergeAttainment(this, attainment);
    }

    mergeCustomModuleAttainment(attainment: Attainment) {
        RuleContext.mergeAttainment(this, attainment);
    }

    mergeCustomCourseUnitAttainment(attainment: Attainment) {
        RuleContext.mergeAttainment(this, attainment);
    }

    mergeAssessmentItemAttainment(attainment: Attainment) {
        RuleContext.mergeAttainment(this, attainment);
    }

    mergeContext(other: RuleContext) {
        this.mergeImplicitSelections(other.implicitCourseUnitIds, other.implicitModuleIds);
        this.mergePartial(other, null);
    }

    mergeImplicitSelections(implicitCourseUnitIds: string[], implicitModuleIds: string[]) {
        if (implicitCourseUnitIds) {
            this.implicitCourseUnitIds = _.concat(this.implicitCourseUnitIds, implicitCourseUnitIds);
        }
        if (implicitModuleIds) {
            this.implicitModuleIds = _.concat(this.implicitModuleIds, implicitModuleIds);
        }
    }

    mergePartial(other: RuleContext, partialCredits: number | null) {
        this.mergeStatesFromOtherContext(other);

        RuleContext.addCredits(this, other, partialCredits);
        RuleContext.addSelections(this, other);
    }

    mergePartialRuleContextForModule(other: RuleContext) {
        const contextValidationState = _.includes(PlanValidationStateService.disallowedContextStatesForModule, other.state) ? PlanValidationState.PLANNED : other.state;
        this.state = PlanValidationStateService.higherPriorityOf(this.state, other.state);
        this.contextualState = PlanValidationStateService.higherPriorityOf(this.contextualState, contextValidationState);

        RuleContext.addCredits(this, other, null);
        RuleContext.addSelections(this, other);
    }

    mergeSubContext(subContext: RuleContext) {
        RuleContext.addCredits(this, subContext, null);
        RuleContext.addSelections(this, subContext);
    }

    mergeChildAttainments(other: RuleContext) {
        // NOTE: Credits from main/top level attainment!
        RuleContext.addSelections(this, other);
    }

    isEmpty(): boolean {
        return _.isEmpty(this.matchingModulesByGroupId) &&
            _.isEmpty(this.matchingCourseUnitsByGroupId) &&
            _.isEmpty(this.matchingCustomCourseUnitAttainmentsById) &&
            _.isEmpty(this.matchingCustomModuleAttainmentsById) &&
            _.isEmpty(this.matchingCustomStudyDraftsById);
    }

    isActive(): boolean {
        return !this.isEmpty();
    }

    getResults(options?: any) {
        return _.assign(options || {}, {
            state: this.state,
            contextualState: this.contextualState,
            plannedCredits: this.plannedCredits,
            implicitCourseUnitIds: this.implicitCourseUnitIds,
            implicitModuleIds: this.implicitModuleIds,
            attainedCredits: this.attainedCredits,
            invalidSelection: false,
            active: this.isActive(),
            getValidationResultsForModule(study: any) {
                return _.get(study, 'validationResults');
            },
            getValidationResultsForCourseUnit(study: any) {
                return _.get(study, 'validationResults');
            },
        });
    }

}
