import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'mimeTypeToExtension' })
export class MimeTypeToExtensionPipe implements PipeTransform {

    transform(mimeType: string): string {
        const mimeToExtensionMap: { [key: string]: string } = {
            'application/msword': '.doc',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '.docx',
            'application/pdf': '.pdf',
            'image/jpeg': '.jpg',
            'image/png': '.png',
        };

        return mimeToExtensionMap[mimeType] || '';
    }
}
