import { localeServiceModule } from 'sis-common/l10n/localeService';
"use strict";
(function () {
  codeLocalizedName.$inject = ["localeService"];
  angular.module("sis-components.string.codeFilter", [localeServiceModule]).filter('codeLocalizedName', codeLocalizedName);

  /**
   * @ngInject
   */
  function codeLocalizedName(localeService) {
    return function (input) {
      if (input && input.name) {
        return input.name[localeService.getCurrentLocale()] || input.name.fi || input.name.en || input.name.sv;
      }
      return '';
    };
  }
})();