import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { OtmId } from 'common-typescript/types';
import { Observable, take } from 'rxjs';
import { AuthService } from 'sis-common/auth/auth-service';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';

import { COURSE_UNIT_INFO_MODAL } from '../ajs-upgraded-modules';

import { CourseCartEntityService } from './../common/service/course-cart-entity.service';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'app-course-cart',
    templateUrl: './course-cart.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourseCartComponent implements OnInit {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sisComponents.CourseCart',
        directiveName: 'appCourseCart',
    };

    constructor(@Inject(COURSE_UNIT_INFO_MODAL) private courseUnitInfoModal: any,
                private appErrorHandler: AppErrorHandler,
                private courseCartEntityService: CourseCartEntityService,
                private authService: AuthService) { }

    courseCartCourseUnits$: Observable<OtmId[]>;
    ngOnInit(): void {
        if (this.isLoggedIn()) {
            this.courseCartCourseUnits$ = this.courseCartEntityService.getCourseCart(true)
                .pipe(this.appErrorHandler.defaultErrorHandler(), this.appErrorHandler.defaultErrorHandler());
        }
    }

    isLoggedIn(): boolean {
        return this.authService.loggedIn();
    }

    removeCourseUnitFromCourseCart(courseUnitId: string) {
        this.courseCartEntityService.deleteCurFromCourseCart(courseUnitId)
            .pipe(
                take(1),
                this.appErrorHandler.defaultErrorHandler())
            .subscribe();
    }

    openCourseUnitInfo(id: OtmId) {
        this.courseUnitInfoModal.showCourseUnit(id);
    }

}
