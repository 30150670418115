<app-grants-table *ngIf="activeGrants.length > 0" [grants]="activeGrants"></app-grants-table>

<sis-expandable *ngIf="cancelledGrants.length > 0"
                [variant]="'link'"
                [level]="level"
                [title]="'PROFILE.GRANTS.CANCELLED_GRANTS' | translate">
  <ng-template sisExpandableContent>
    <app-grants-table [grants]="cancelledGrants"></app-grants-table>
  </ng-template>
</sis-expandable>
