import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Urn } from 'common-typescript/types';

@Component({
    selector: 'app-course-unit-info-title-classification',
    templateUrl: './course-unit-info-title-classification.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CourseUnitInfoTitleClassificationComponent {
    @Input() studyFields: Urn[];
    @Input() searchTags: string[];
}
