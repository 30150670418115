<div class="search-filter education-filter">
  <form name="filterSelectorForm" [formGroup]="filterSelectorForm" novalidate>
    <div class="row">
      <div class="col-12">
        <sis-typeahead class="education-filter-typeahead"
                       [model]="selectedEducation"
                       [placeholder]="'SIS_COMPONENTS.EDUCATION.SEARCH_EDUCATION.SEARCH_EDUCATIONS' | translate"
                       [resultTemplate]="customEducationTemplate"
                       (selectItem)="toggleValue.emit({ form: filterSelectorForm, value: $event.item })"
                       [typeahead]="searchEducations.bind(this)">
        </sis-typeahead>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <ul class="filter-selectbox selectbox-multi ng-scope">
          <div *ngFor="let filterValue of listSelected()">
            <li class="ng-binding parent-value">
              <button type="button"
                      class="btn btn-primary btn-naked btn-sm btn-just-icon"
                      (click)="toggleValue.emit({ event: $event, form: filterSelectorForm, value: filterValue })">
                <sis-icon icon="delete"></sis-icon>
              </button>
              <div class="parent-value-text">{{filterValue.name | localizedString}}</div>
            </li>
            <li class="ng-binding child-value" *ngFor="let childValue of filterValue.children">
              <button type="button"
                      class="btn btn-primary btn-naked btn-sm btn-just-icon"
                      (click)="toggleChildValue.emit({ childValue: childValue, event: $event, form: filterSelectorForm, value: filterValue })">
                <sis-icon icon="delete"></sis-icon>
              </button>
              <small>{{childValue.name | localizedString }}</small>
            </li>
          </div>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <button type="button" class="btn btn-sm btn-primary btn-naked float-end open-education-selector-with-text form-control"
                (click)="openEducationModal.emit()"
                [attr.aria-label]="'ARIA_LABEL.OPEN_UNIVERSITY_MENU' | translate">
          <sis-icon icon="picker"></sis-icon>
          <span translate>SIS_COMPONENTS.EDUCATION.OPEN_EDUCATION_MODAL</span>
        </button>
      </div>
    </div>
    <div class="row filter-preview-row">
      <div class="col-9">
        <div class="filter-preview-holder">
          <span class="filter-preview-title">
            {{filterName}}
          </span>
          <span class="filter-preview-content">
            <span *ngFor="let filterValue of listSelected(); first as first"
                  [class.item-separator]="!first">{{getFilterName(filterValue)}}</span>
          </span>
        </div>
      </div>
      <div class="col-3">
        <button type="submit"
                class="btn btn-lg btn-primary filter-submit"
                (click)="submitFilter.emit()"
                [disabled]="filterSelectorForm.pristine"
                translate="SEARCH.FILTER_SELECTOR.OK">
        </button>
      </div>
    </div>
  </form>
</div>

<ng-template #customEducationTemplate let-result="result">
  <sis-tooltip [tooltipText]="(result.name | localizedString) + ' ' + (result.validityPeriod | localDateRange)" [appendToBody]="true" tooltipPlacement="left">
    <span class="sis-tooltip-trigger-content">
      {{result.code ? result.code + ', ' : '' }}{{result.name | localizedString}}
    </span>
  </sis-tooltip>
</ng-template>
