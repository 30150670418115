import { Component, Input, ViewEncapsulation } from '@angular/core';
import { TuitionFeePayment } from 'common-typescript/types';

@Component({
    selector: 'app-tuition-fee-payments-table',
    templateUrl: './tuition-fee-payments-table.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class TuitionFeePaymentsTableComponent {

    @Input() payments: TuitionFeePayment[];
    @Input() caption: string;

    paymentId(i: number, payment: TuitionFeePayment): any {
        // Fall back to the default functionality if the id is missing
        return payment?.id ?? payment;
    }
}
