"use strict";

import { lastValueFrom, take } from 'rxjs';
(function () {
  unplannedService.$inject = ["$q", "uiContext", "planService", "courseUnitModel", "defaultPromiseHandler", "validAttainmentFilterService", "attainmentService", "courseCartEntityService", "courseUnitService", "commonEnrolmentService", "commonCourseUnitRealisationService", "appErrorHandler"];
  angular.module('student.plan.unplannedService', ['student.common.context.uiContext', 'student.common.service.planService', 'student.common.model.courseUnit', 'sis-common.errorhandler.errorService', 'sis-components.service.validAttainmentFilterService', 'sis-components.service.enrolmentService', 'sis-components.service.courseUnitRealisationService', 'student.common.service.attainmentService', 'student.common.service.courseUnitService']).factory('unplannedService', unplannedService);
  function unplannedService($q, uiContext, planService, courseUnitModel, defaultPromiseHandler,
  //NOSONAR
  validAttainmentFilterService, attainmentService, courseCartEntityService, courseUnitService, commonEnrolmentService, commonCourseUnitRealisationService, appErrorHandler) {
    return {
      getUpdatedCollections: function (unfiltered) {
        return (_.isUndefined(unfiltered) || _.isEmpty(unfiltered) ? loadUnfilteredCollections() : $q.when(unfiltered)).then(function (unfiltered) {
          return {
            unfiltered: unfiltered,
            filtered: filterCollections(unfiltered)
          };
        });
      }
    };
    function loadUnfilteredCollections() {
      var validatablePlan = uiContext.planContext.validatablePlan;
      var unfiltered = {};
      const courseCartPromise = lastValueFrom(courseCartEntityService.getCourseCart(true).pipe(take(1), appErrorHandler.defaultErrorHandler())).then(ids => courseUnitService.findByIds(ids).then(curs => {
        unfiltered.courseCart = curs;
      }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler));
      var attainmentsPromise = attainmentService.getMyValidAttainments().then(function (allAttainments) {
        unfiltered.allAttainments = allAttainments;
        var unattachedAttainments = _.filter(allAttainments, function (attainment) {
          return !validAttainmentFilterService.isAttached(attainment, allAttainments);
        });
        unfiltered.attainedCourseUnits = unattachedAttainments.filter(attainment => attainment.type === 'CourseUnitAttainment').map(attainment => validatablePlan.getCourseUnit(attainment.courseUnitId));
        unfiltered.customCourseUnitAttainments = unattachedAttainments.filter(attainment => attainment.type === 'CustomCourseUnitAttainment').map(attainment => validatablePlan.getCustomCourseUnitAttainment(attainment.id));
      });
      var courseUnitRealisationPromise =
      // note: must bypass cache, as added enrolments are known only by the backend
      commonEnrolmentService.findMyEnrolments(true, true).then(function (enrolments) {
        enrolments = _.filter(enrolments, function (enrolment) {
          return enrolment.isInCalendar || enrolment.state === 'ENROLLED' || enrolment.state === 'PROCESSING';
        });
        unfiltered.enrolmentsByCourseUnitRealisationId = _.keyBy(enrolments, 'courseUnitRealisationId');
        return commonCourseUnitRealisationService.findByIds(_.map(enrolments, 'courseUnitRealisationId')).then(function (curs) {
          unfiltered.courseUnitRealisations = curs;
        });
      });
      var myPlansPromise = planService.findMyPlans().then(function (plans) {
        unfiltered.courseUnitIdsInOtherPrimaryPlans = _.map(_.flatMap(_.filter(plans, function (plan) {
          return plan.primary && plan.rootId !== validatablePlan.rootModule.id;
        }), 'courseUnitSelections'), 'courseUnitId');
        unfiltered.customCourseUnitAttainmentIdsInOtherPrimaryPlans = _.map(_.flatMap(_.filter(plans, function (plan) {
          return plan.primary && plan.rootId !== validatablePlan.rootModule.id;
        }), 'customCourseUnitAttainmentSelections'), 'customCourseUnitAttainmentId');
      });
      return $q.all([courseCartPromise, courseUnitRealisationPromise, myPlansPromise, attainmentsPromise]).then(function () {
        return unfiltered;
      });
    }
    function filterCollections(unfiltered) {
      // This check guards against exceptions when creating a new plan from an NG Bootstrap Modal: for some reason an extra
      // 'unplanned' component is momentarily created without planContext, then shortly afterwards a correct one is created with it
      if (!uiContext.planContext) {
        return null;
      }
      var validatablePlan = uiContext.planContext.validatablePlan;
      var filtered = {};
      var selectedAssessmentItemsInCurrentPlan = validatablePlan.getAllAssessmentItems();
      filtered.courseCart = _.filter(unfiltered.courseCart, function (courseUnitInCourseCart) {
        return !validatablePlan.isCourseUnitInPlan(courseUnitInCourseCart) && !validatablePlan.isSubstituted(courseUnitInCourseCart) && !validatablePlan.isSubstitute(courseUnitInCourseCart);
      });
      filtered.attainedCourseUnits = _.filter(unfiltered.attainedCourseUnits, function (attainedCourseUnit) {
        if (attainedCourseUnit) {
          return !(validatablePlan.isCourseUnitInPlan(attainedCourseUnit) || _.includes(unfiltered.courseUnitIdsInOtherPrimaryPlans, attainedCourseUnit.id)) && !validatablePlan.isSubstitute(attainedCourseUnit);
        }
        return false;
      });
      function courseUnitAttained(courseUnitRealisationId) {
        const enrolment = _.get(unfiltered.enrolmentsByCourseUnitRealisationId, courseUnitRealisationId);
        return _.some(unfiltered.attainedCourseUnits, cu => cu.id === _.get(enrolment, 'courseUnitId'));
      }
      filtered.courseUnitRealisations = _.filter(unfiltered.courseUnitRealisations, function (courseUnitRealisation) {
        return _.isEmpty(_.intersection(_.flatMap(selectedAssessmentItemsInCurrentPlan, 'id'), courseUnitRealisation.assessmentItemIds)) && !courseUnitAttained(courseUnitRealisation.id);
      });
      filtered.customCourseUnitAttainments = _.filter(unfiltered.customCourseUnitAttainments, function (customCourseUnitAttainment) {
        if (customCourseUnitAttainment) {
          return !(validatablePlan.isCustomCourseUnitAttainmentInPlan(customCourseUnitAttainment) || _.includes(unfiltered.customCourseUnitAttainmentIdsInOtherPrimaryPlans, customCourseUnitAttainment.id));
        }
      });
      return filtered;
    }
  }
})();