import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MinorSelectionModule } from 'sis-components/studyRight/minor-selection/minor-selection.module';

import { PlanStudyRightModalComponent } from './planStudyRightModal.component';
import { PlanStudyRightModalDirective } from './planStudyRightModal.dir.upgraded';

@NgModule({
    declarations: [
        PlanStudyRightModalComponent,
        PlanStudyRightModalDirective,
    ],
    imports: [
        CommonModule,
        MinorSelectionModule,
    ],
})
export class PlanStudyRightModalModule {
}
