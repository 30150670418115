import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    EMP, GenerateEmrexSessionResponse,
} from 'common-typescript/types';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EmrexService {

    constructor(
        private http: HttpClient,
    ) {}

    getEMPList(): Observable<EMP[]> {
        return this.http.get<EMP[]>(
            '/elmo/api/emrex/emp-list',
        );
    }

    generateSession(url: string, redirectUrl: string): Observable<GenerateEmrexSessionResponse> {
        return this.http.post<GenerateEmrexSessionResponse>(
            '/elmo/api/emrex/generate-session',
            { url, redirectUrl },
        );
    }
}
