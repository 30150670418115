import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';

import { getChildControlLabelState } from '../../form/formUtils';
import { Placement } from '../local-date-editor/local-date-editor.component';

@Component({
    selector: 'sis-local-date-range-editor',
    templateUrl: './local-date-range-editor.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class LocalDateRangeEditorComponent implements OnInit {

    @Input() control: FormGroup;
    /**
     * If true, the start and end date fields will be placed on top of each other with screen sizes below the 'md' breakpoint.
     * With screen sizes 'md' and larger, the fields will remain side-by-side, but their labels will be hidden.
     */
    @Input() fieldsOneBelowAnother = false;
    @Input() hideLabels = false;
    @Input() name?: string;
    @Input() endDateSubtractByOne?: boolean;
    @Input() endNotRequired = false;
    @Input() showLabelIcons = true;
    /**
     * NgbBootsrap placement property defines date picker pop-over placement. The placement is set to auto as default.
     * Optional values 'bottom-start' or 'top-start' sticks the date picker to open always either on top or under the input field.
     */
    @Input() datepickerPlacementStartDate?: Placement = 'auto';
    @Input() datepickerPlacementEndDate?: Placement = 'auto';

    constructor(private dateParserFormatter: NgbDateParserFormatter) {}

    ngOnInit() {
        if (this.endDate.value && this.endDateSubtractByOne) {
            this.endDate.setValue(moment(this.endDate.value).subtract(1, 'days'));
            this.endDate.updateValueAndValidity();
        }
    }

    get startDate(): FormControl {
        return this.control.get('startDate') as FormControl;
    }

    get endDate(): FormControl {
        return this.control.get('endDate') as FormControl;
    }

    get minDateValue(): NgbDateStruct {
        return this.dateParserFormatter.parse(this.startDate.value);
    }

    get endDateStartDate(): NgbDateStruct {
        if (this.endDate.value) {
            return this.dateParserFormatter.parse(this.endDate.value);
        }
        return this.dateParserFormatter.parse(this.startDate.value);
    }

    getLabelStateFor(childControl: AbstractControl): string {
        return getChildControlLabelState(this.control, childControl);
    }
}
