import { APP_INITIALIZER } from '@angular/core';

import { AuthService } from './auth-service';

function initializeAppAuth(authService: AuthService) {
    return () => new Promise((resolve) => {
        authService.initializeAppAuth().subscribe(() => resolve(true));
    });
}

export const appAuthInitializer = ({
    provide: APP_INITIALIZER,
    useFactory: initializeAppAuth,
    deps: [AuthService],
    multi: true,
});
