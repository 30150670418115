import {
    ChangeDetectionStrategy,
    Component, EventEmitter, inject,
    Input,
    OnChanges, Output,
    SimpleChanges,
    ViewEncapsulation,
} from '@angular/core';
import { Attainment, OtmId, StudyModule } from 'common-typescript/types';
import _ from 'lodash';

import {
    PLAN_ACTIONS_SERVICE_INJECTION_TOKEN,
    PlanActionsService,
} from '../../plan/plan-actions-service/plan-actions.service';
import { ModuleStateObject, PlanData, PlanStateObject } from '../../service/plan-state.service';
import { Action } from '../../study-boxes/study-action-box/study-action-box.component';
import { StudyBoxType } from '../../study-boxes/study-box/study-box.component';

@Component({
    selector: 'sis-plan-structure-study-module-box',
    templateUrl: './plan-structure-study-module-box.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanStructureStudyModuleBoxComponent implements OnChanges {
    @Input({ required: true }) module: StudyModule;
    @Input({ required: true }) headingLevel: number;
    @Input({ required: true }) planStateObject: PlanStateObject;
    @Input({ required: true }) planData: PlanData;
    @Input() selected?: boolean;
    @Input() action?: Action;
    @Output() actionChange? = new EventEmitter<OtmId>();

    moduleStateObject: ModuleStateObject;
    boxType: StudyBoxType = StudyBoxType.studyModule;
    attainment: Attainment;
    studyState: 'DRAFT' | 'DELETED';
    invalidSelection: boolean;
    planActionsService: PlanActionsService = inject(PLAN_ACTIONS_SERVICE_INJECTION_TOKEN);

    ngOnChanges(changes: SimpleChanges) {
        this.moduleStateObject = _.get(this.planStateObject?.modules, this.module.id);
        if (this.moduleStateObject) {
            this.attainment = this.planData.attainmentsById[this.moduleStateObject.attainmentId];
            this.invalidSelection = this.moduleStateObject.invalidSelection && !this.moduleStateObject.hasModuleContentApproval;
        }
        if (this.module.documentState !== 'ACTIVE') {
            this.studyState = this.module.documentState;
        }
    }

    openStudyModuleInfoModal() {
        this.planActionsService.openModuleInfoModal(this.module);
    }
}
