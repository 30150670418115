import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StateService } from '@uirouter/angular';
import { CurriculumPeriod, Module, OtmId } from 'common-typescript/types';
import { combineLatest, map, Observable, of, OperatorFunction, switchMap } from 'rxjs';
import { take } from 'rxjs/operators';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { ModalService } from 'sis-common/modal/modal.service';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { CurriculumPeriodEntityService } from 'sis-components/service/curriculum-period-entity.service';
import { ModuleEntityService } from 'sis-components/service/module-entity.service';

import { ModuleInfoService } from '../module-info.service';

export interface ModuleInfoVersionChangeWarningValues {
    fromModuleId: OtmId;
    toModuleId: OtmId;
}
interface ModuleInfoVersionWarningData {
    fromModule: Module;
    toModule: Module;
    fromCurriculumPeriods: string;
    toCurriculumPeriods: string;
}
@Component({
    selector: 'app-module-info-version-change-warning',
    templateUrl: './module-info-version-change-warning.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModuleInfoVersionChangeWarningComponent implements OnInit {

    data$: Observable<ModuleInfoVersionWarningData>;

    constructor(@Inject(ModalService.injectionToken) private values: ModuleInfoVersionChangeWarningValues,
                private moduleInfoService: ModuleInfoService,
                private localeService: LocaleService,
                private activeModal: NgbActiveModal,
                private modalService: NgbModal,
                private stateService: StateService,
                private appErrorHandler: AppErrorHandler,
                private curriculumPeriodEntityService: CurriculumPeriodEntityService,
                private moduleEntityService: ModuleEntityService) {
    }

    ngOnInit(): void {
        this.data$ = combineLatest([
            this.moduleEntityService.getById(this.values.fromModuleId),
            this.moduleEntityService.getById(this.values.toModuleId)]).pipe(
            switchMap(([fromModule, toModule]) =>
                combineLatest({
                    fromModule: of(fromModule),
                    toModule: of(toModule),
                    fromCurriculumPeriods: this.curriculumPeriodEntityService.getByIds(fromModule.curriculumPeriodIds)
                        .pipe(this.getCurriculumPeriodsAsString()),
                    toCurriculumPeriods: this.curriculumPeriodEntityService.getByIds(toModule.curriculumPeriodIds)
                        .pipe(this.getCurriculumPeriodsAsString()),
                })),
        );
    }

    getCurriculumPeriodsAsString(): OperatorFunction<CurriculumPeriod[], string> {
        return map((curriculumPeriods) => curriculumPeriods.map((curriculumPeriod) =>
            this.localeService.localize(curriculumPeriod.abbreviation)).join(', '));
    }

    copyAndReplace(fromModule: Module, toModule: Module) {
        this.moduleInfoService.copyPlanAndReplaceModule(fromModule, toModule)
            .pipe(take(1),
                  this.appErrorHandler.defaultErrorHandler())
            .subscribe((newPlan) => {
                this.modalService.dismissAll();
                this.stateService.go('student.logged-in.structure', { planId: newPlan.id });
            });
    }

    replace(fromModule: Module, toModule: Module) {
        this.moduleInfoService.replaceModule(fromModule, toModule)
            .pipe(take(1),
                  this.appErrorHandler.defaultErrorHandler())
            .subscribe((module) => {
                this.modalService.dismissAll();
            });
    }

    cancel() {
        this.activeModal.close();
    }

}
