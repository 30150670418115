import courseUnitWithSubstitutionsTpl from './courseUnitWithSubstitutions.tpl.html';
'use strict';
(function () {
  CourseUnitWithSubstitutionsCtrl.$inject = ["SELECTION_TYPES"];
  angular.module('sis-components.plan.courseUnitWithSubstitutions', ['sis-components.courseUnit', 'sis-components.plan.planSelectorService']).component('courseUnitWithSubstitutions', {
    template: courseUnitWithSubstitutionsTpl,
    controller: CourseUnitWithSubstitutionsCtrl,
    controllerAs: '$ctrl',
    bindings: {
      courseUnitId: '@',
      validatablePlan: '<',
      parentModuleGroupId: '<',
      parentModuleId: '<',
      selectable: '<?',
      showExpiryWarnings: '<?',
      // Set to true for an implicit course unit selection
      showSelectedParent: '<?'
    }
  });

  /**
   * @ngInject
   * @ngDoc
   * This component resolves if given courseUnitId is substituted in shown plan
   */
  function CourseUnitWithSubstitutionsCtrl(SELECTION_TYPES) {
    // NOSONAR
    var $ctrl = this;
    $ctrl.substitutions = [];
    $ctrl.SELECTION_TYPES = SELECTION_TYPES;
    $ctrl.$onChanges = function (changesObject) {
      if (changesObject.courseUnitId) {
        var courseUnitSelection = $ctrl.validatablePlan.getCourseUnitSelection(changesObject.courseUnitId.currentValue);
        if (courseUnitSelection) {
          $ctrl.substitutions = courseUnitSelection.substitutedBy;
        }
      }
    };
    $ctrl.hasSubstitutions = function () {
      return _.get($ctrl.substitutions, 'length', 0) > 0;
    };
  }
})();