import angular from 'angular';
import 'js-data';
import { jsDataRelationHelperModule } from '../service/jsDataRelationHelper.service';
export const educationModelModule = 'sis-components.model.education';
(function () {
  educationJSDataModel.$inject = ["DS", "EDUCATION_URL", "EDUCATION_TYPE", "$q", "jsDataRelationHelperService"];
  angular.module(educationModelModule, ['js-data', jsDataRelationHelperModule]).factory('educationJSDataModel', educationJSDataModel).constant('EDUCATION_URL', {
    ENDPOINT_URL: '/kori/api/educations',
    AUTHENTICATED_ENDPOINT_URL: '/kori/api/authenticated/educations',
    STAFF: '/kori/api/educations/staff',
    AUTHENTICATED_SEARCH_ENDPOINT_URL: '/kori/api/authenticated/education-search'
  }).constant('EDUCATION_TYPE', {
    SPECIALISATION_STUDIES: 'urn:code:education-type:non-degree-education:separate-studies:specialisation-studies',
    BACHELORS_AND_MASTERS: 'urn:code:education-type:degree-education:bachelors-and-masters-degree'
  }).run(["educationJSDataModel", educationJSDataModel => {// NOSONAR
  }]);

  /**
   * @ngInject
   */
  function educationJSDataModel(DS, EDUCATION_URL, EDUCATION_TYPE, $q, jsDataRelationHelperService) {
    function loadRelations(education) {
      const relationLoads = [jsDataRelationHelperService.loadOrganisationRelations(education.organisations), jsDataRelationHelperService.loadEducationResponsibilityInfoRelations(education.responsibilityInfos), jsDataRelationHelperService.loadUniversityOrgs([education])];
      return relationLoads;
    }
    return DS.defineResource({
      idAttribute: 'id',
      name: 'education',
      endpoint: EDUCATION_URL.ENDPOINT_URL,
      methods: {
        loadRelations() {
          const education = this;
          return $q.all(loadRelations(education)).then(() => education);
        }
      },
      relations: {
        hasOne: {
          educationType: {
            localField: 'localEducationType',
            localKey: 'educationType'
          }
        },
        hasMany: {
          organisation: {
            localField: 'universityOrgs',
            localKeys: 'universityOrgIds'
          },
          studyField: {
            localField: 'localStudyFields',
            localKeys: 'studyFields'
          },
          language: {
            localField: 'localAttainmentLanguages',
            localKeys: 'attainmentLanguages'
          }
        }
      },
      computed: {
        isSpecialisationStudies: ['educationType', educationType => educationType === EDUCATION_TYPE.SPECIALISATION_STUDIES]
      },
      afterUpdate(resource, education, cb) {
        return $q.all(loadRelations(education)).then(() => cb(null, education));
      }
    });
  }
})();