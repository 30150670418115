import { Injectable } from '@angular/core';
import { Range, RangeValidation } from 'common-typescript';
import { ValidatablePlan } from 'common-typescript/src/plan/validation/validatablePlan';
import {
    CreditRange,
    DegreeProgramme,
    GroupingModule,
    Module,
    ModuleContentApplication,
    RangeValidationResultState,
    StudyModule,
} from 'common-typescript/types';
import { PlanValidationState } from 'common-typescript/types/baseTypes';
import _ from 'lodash';
import { WorkflowEntityService } from 'sis-components/service/workflow-entity.service';

@Injectable({
    providedIn: 'root',
})
export class ShowViewModuleContentWorkflowLinkService {

    constructor(private workflowEntityService: WorkflowEntityService) {
    }

    getStatusCode(validatablePlan: ValidatablePlan, module: Module, moduleValidationResult: any, isFreeEdit: boolean) {
        let targetCredits;
        switch (module.type) {
            case 'GroupingModule':
                targetCredits = null;
                break;
            case 'DegreeProgramme':
                targetCredits = (module as DegreeProgramme).targetCredits;
                break;
            case 'StudyModule':
                targetCredits = (module as StudyModule).targetCredits;
                break;
        }

        let caseCode = isFreeEdit ? 'FREE_EDIT.' : 'SELECTION_ASSISTANT.';
        caseCode += this.isModuleContentApplicationRequired(module) ? 'APPROVAL_REQUIRED.' : 'APPROVAL_NOT_REQUIRED.';

        if (this.isModuleEmpty(validatablePlan, module)) {
            return `${caseCode}EMPTY`;
        }
        if (this.isPlanStateValid(_.get(moduleValidationResult, 'state'))) {
            return `${caseCode}VALID_CONTENTS`;
        }
        if (this.hasInvalidOrMissingSelections(moduleValidationResult) || // NOSONAR
            (!isFreeEdit) ||
            (isFreeEdit &&
                (!this.isPlannedCreditsMatchingTargetCredits(module, targetCredits, moduleValidationResult) ||
                    _.isNil(targetCredits) ||
                    _.get(moduleValidationResult, 'state')
                )
            )
        ) {
            return `${caseCode}INVALID_CONTENTS`;
        }
        return `${caseCode}VALID_CONTENTS`;
    }

    isPlanStateValid(moduleValidationResultState: PlanValidationState) {
        const validPlanStates = [
            PlanValidationState.PLANNED,
            PlanValidationState.APPROVED,
            PlanValidationState.APPROVED_CONDITIONALLY,
            PlanValidationState.APPROVED_CONDITIONALLY_PARTS_ATTAINED,
            PlanValidationState.APPROVED_PARTS_ATTAINED,
            PlanValidationState.PARTS_ATTAINED,
            PlanValidationState.IMPLICIT,
        ];
        return _.includes(validPlanStates, moduleValidationResultState);
    }

    isPlannedCreditsMatchingTargetCredits(module: Module, targetCredits: CreditRange, moduleValidationResult: any) {
        if (_.isNil(targetCredits)) {
            return true; // eg. GroupingModule
        }

        const rangeValidationResult = RangeValidation.validateRange(new Range(targetCredits),
                                                                    new Range(_.get(moduleValidationResult, 'plannedCredits')));

        return rangeValidationResult.result === RangeValidationResultState.IMPLICIT ||
            rangeValidationResult.result === RangeValidationResultState.OK;
    }

    isModuleContentApplicationRequired(module: Module) {
        if (module) {
            return module.moduleContentApprovalRequired;
        }
        return false;
    }

    isModuleEmpty(validatablePlan: ValidatablePlan, module: Module) {
        const selectedModulesUnderModule = _.some(validatablePlan.getSelectedModulesUnderModule(module), selection => !!selection);
        const selectedCourseUnitsUnderModule = _.some(validatablePlan.getSelectedCourseUnitsUnderModule(module), selection => !!selection);
        const selectedCustomModuleAttainmentsUnderModule = _.some(validatablePlan.getSelectedCustomModuleAttainmentsUnderModule(module), selection => !!selection);
        const selectedCustomCourseUnitAttainmentsUnderModule = _.some(validatablePlan.getSelectedCustomCourseUnitAttainmentsUnderModule(module), selection => !!selection);
        const selectedCustomStudyDraftsByParentModuleId = _.some(validatablePlan.getSelectedCustomStudyDraftsByParentModuleId(module.id), selection => !!selection);

        return !(selectedModulesUnderModule || selectedCourseUnitsUnderModule || selectedCustomModuleAttainmentsUnderModule || selectedCustomCourseUnitAttainmentsUnderModule || selectedCustomStudyDraftsByParentModuleId);
    }

    hasInvalidOrMissingSelections(moduleValidationResult: any) {
        return !!_.get(moduleValidationResult, 'hasInvalidOrMissingSelections');
    }

    shouldShowModuleContentWorkflowLink(validatablePlan: ValidatablePlan, module: Module) {
        const studentViewableModuleContentApplication = this.getStudentViewableModuleContentWorkflow(validatablePlan, module);
        return !!studentViewableModuleContentApplication;
    }

    getStudentViewableModuleContentWorkflow(validatablePlan: ValidatablePlan, module: Module) {
        let studentViewableModuleContentApplication;

        let moduleContentApplication: ModuleContentApplication;

        if (validatablePlan && module) {
            moduleContentApplication = validatablePlan.getEffectiveModuleContentApproval(module.id);
        }
        if (!moduleContentApplication && validatablePlan && module) {
            const parentModuleSelection = _.get(validatablePlan.moduleIdSelectionMap, module.id);
            const parentModuleId = _.get(parentModuleSelection, 'parentModuleId');

            let mcas = _.filter(validatablePlan.moduleContentApplications,
                                mca => mca.educationId === _.get(validatablePlan.rootModule, 'id') &&
                    mca.approvedModuleId === module.id &&
                    mca.parentModuleId === parentModuleId);

            // rejected or revoked
            // order by last modified date to get the latest first.
            mcas = _.orderBy(mcas, mca => mca.metadata.lastModifiedOn, 'desc');
            moduleContentApplication = _.first(mcas);
        }
        if (moduleContentApplication &&
            this.workflowEntityService.isStudentViewableModuleContentWorkflow(moduleContentApplication.state) &&
            this.workflowEntityService.isMatchingModuleContentWorkflow(moduleContentApplication, validatablePlan, module.id)) {
            studentViewableModuleContentApplication = moduleContentApplication;
        }

        return studentViewableModuleContentApplication;
    }
}

