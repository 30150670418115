import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { ValidatablePlan } from 'common-typescript';
import { PlanValidationResult } from 'common-typescript/src/plan/validation/planValidationResult';
import { AnyModuleRule, EntityWithRule } from 'common-typescript/types';

import { PlanData, PlanStateObject } from '../../../service/plan-state.service';

@Component({
    selector: 'sis-plan-structure-any-module-rule',
    templateUrl: './plan-structure-any-module-rule.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanStructureAnyModuleRuleComponent {
    @Input({ required: true }) rule: AnyModuleRule;
    @Input({ required: true }) parentModule: EntityWithRule;
    @Input({ required: true }) planValidationResult: PlanValidationResult;
    @Input({ required: true }) planStateObject: PlanStateObject;
    @Input({ required: true }) planData: PlanData;
    @Input({ required: true }) validatablePlan: ValidatablePlan;
    @Input({ required: true }) ruleDepthLevel: number;
    @Input({ required: true }) headingLevel: number;
}
