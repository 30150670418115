import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { PriorCompetence } from 'common-typescript/types';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-prior-competence-summary',
    templateUrl: './prior-competence-summary.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriorCompetenceSummaryComponent {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.lib.applications.prior-competence-summary',
        directiveName: 'sisPriorCompetenceSummary',
    };

    @Input() priorCompetence: PriorCompetence;
}
