export const decimalInputModule = 'sis-components.number.decimalInput';
(function () {
  angular.module(decimalInputModule, []).directive('decimalInput', decimalInput);

  /**
   * @ngInject
   */

  function decimalInput() {
    return {
      require: '?ngModel',
      link: function (scope, elem, attrs, ctrl) {
        if (!ctrl) {
          return;
        }
        ctrl.$formatters.unshift(function (modelValue) {
          if (angular.isDefined(modelValue) && modelValue != null) {
            return modelValue.replace('.', ',');
          }
          return modelValue;
        });
        ctrl.$parsers.unshift(function (viewValue) {
          if (angular.isDefined(viewValue) && viewValue != null) {
            return viewValue.replace(',', '.');
          }
          return viewValue;
        });
      }
    };
  }
})();