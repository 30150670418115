import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { CreditRange, LocalDateRange, LocalizedString, OtmId } from 'common-typescript/types';

import { Action } from '../study-action-box/study-action-box.component';

@Component({
    selector: 'sis-study-realisation-box',
    templateUrl: './study-realisation-box.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudyRealisationBoxComponent {
    @Input() id: OtmId;
    /**
     * Determines header's semantic aria-level for screen readers, default is equivalent for h4
     */
    @Input() level? = 4;
    @Input() realisationName: LocalizedString;
    @Input() name: LocalizedString;
    @Input() code: string;
    @Input() credits: CreditRange;
    @Input() activityPeriod: LocalDateRange;
    @Input() attainedCredits?: number;
    @Input() gradeId?: number;
    @Input() gradeScaleId?: OtmId;
    @Input() showCancelledBadge?: boolean = false;
    @Input() action?: Action;
    @Input() disableBoxMaxWidth?: boolean;
    @Output() actionChange = new EventEmitter<OtmId>();
    // Name will be not clickable when not observing this output.
    @Output() nameClickAction = new EventEmitter<OtmId>();
}
