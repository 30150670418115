<h2 translate>STUDENT_APPLICATIONS.MODULE_ATTAINMENT_APPLICATION_ATTAINMENT.ATTAINED_STUDYMODULE</h2>
<sis-attainment-tree-attainment
  *ngIf="attainmentsById"
  [attainment]="attainment"
  [attainmentsById]="attainmentsById"
  [expanded]="true"
  [showHeader]="true">
</sis-attainment-tree-attainment>
<div class="confirmation-summary" data-cy="confirmation-summary">
  <div class="row" *ngIf="viewOptions.evaluationType === 'SEPARATE'">
    <div class="col-6">
      <div class="form-group">
        <label class="control-label" translate>
          STUDENT_APPLICATIONS.MODULE_ATTAINMENT_APPLICATION_ATTAINMENT.STUDY_RIGHT_FOR_ATTAINMENT
        </label>
        <p class="form-control-static text-area-static">{{attainment.studyRightId|studyRightName|async|localizedString}}</p>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-3">
      <div class="form-group">
        <label class="control-label" translate>
          STUDENT_APPLICATIONS.MODULE_ATTAINMENT_APPLICATION_ATTAINMENT.MEAN
        </label>
        <p class="form-control-static">
          {{attainment.gradeAverage.value}}
        </p>
      </div>
    </div>
    <div class="col-3">
      <div class="form-group">
        <label class="control-label" translate>
          STUDENT_APPLICATIONS.MODULE_ATTAINMENT_APPLICATION_ATTAINMENT.GRADE
        </label>
        <p class="form-control-static">
          {{attainment.gradeId|grade:attainment.gradeScaleId|async}}
        </p>
      </div>
    </div>
    <div class="col-6">
      <div class="form-group" *ngIf="attainment.resolutionRationale">
        <label class="control-label" translate>
          STUDENT_APPLICATIONS.MODULE_ATTAINMENT_APPLICATION_ATTAINMENT.RESOLUTION_RATIONALE
        </label>
        <p class="form-control-static text-area-static">{{attainment.resolutionRationale}}</p>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-6">
      <div class="form-group" *ngIf="!viewOptions.hideAdministrativeNote">
        <label class="control-label" *ngIf="viewOptions.evaluationType === 'APPLICATION'" translate>
          STUDIES.EVALUATION.EVALUATION_FORM.ADMINISTRATIVE_NOTE
        </label>
        <label class="control-label" *ngIf="viewOptions.evaluationType === 'SEPARATE'" translate>
          STUDIES.EVALUATION.EVALUATION_FORM.ADMINISTRATIVE_NOTE_FOR_SEPARATE_EVALUATION
        </label>
        <p class="form-control-static text-area-static">{{attainment.administrativeNote}}</p>
      </div>
    </div>
    <div class="col-6">
      <div class="form-group">
        <sis-localized-string [data]="attainment.additionalInfo">
          <label class="control-label" translate>
            STUDENT_APPLICATIONS.MODULE_ATTAINMENT_APPLICATION_ATTAINMENT.ADDITIONAL_INFO
          </label>
        </sis-localized-string>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-6">
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label class="control-label" translate>
              STUDENT_APPLICATIONS.MODULE_ATTAINMENT_APPLICATION_ATTAINMENT.ATTAINMENT_LANGUAGE
            </label>
            <p class="form-control-static">
              {{attainment.attainmentLanguageUrn|codeLocalizedName|async}}
            </p>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label class="control-label" translate>
              STUDENT_APPLICATIONS.MODULE_ATTAINMENT_APPLICATION_ATTAINMENT.ATTAINMENT_DATE
            </label>
            <p class="form-control-static">
              {{attainment.attainmentDate | localDateFormat}}
            </p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label class="control-label" translate>
              STUDENT_APPLICATIONS.APPROVED_BY
            </label>
            <p *ngFor="let acceptor of attainment.acceptorPersons" class="form-control-static">
              <span *ngIf="acceptor.personId">{{acceptor.personId|publicPerson|async|personFullNameReversed}}</span>
              <span *ngIf="!acceptor.personId">{{acceptor.text|localizedString}}</span>
            </p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label class="control-label" translate>
              STUDENT_APPLICATIONS.MODULE_ATTAINMENT_APPLICATION_ATTAINMENT.ATTAINMENT_STUDY_FIELD
            </label>
            <p class="form-control-static">
              {{attainment.studyFieldUrn|codeLocalizedName|async}}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="col-6">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label class="control-label" translate>
              STUDENT_APPLICATIONS.MODULE_ATTAINMENT_APPLICATION_ATTAINMENT.RESPONSIBLE_ORGANISATION
            </label>
            <sis-organisation-role-share
              [hideLabel]="true"
              [organisations]="attainment.organisations"
              [organisationRoleUrn]="'urn:code:organisation-role:responsible-organisation'">
            </sis-organisation-role-share>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label class="control-label" translate>
              STUDENT_APPLICATIONS.MODULE_ATTAINMENT_APPLICATION_ATTAINMENT.COORDINATING_ORGANISATION
            </label>
            <sis-organisation-role-share
              [hideLabel]="true"
              [organisations]="attainment.organisations"
              [organisationRoleUrn]="'urn:code:organisation-role:coordinating-organisation'">
            </sis-organisation-role-share>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
