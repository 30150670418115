<div *ngIf="attainment" class="courseunit" [ngClass]="[categoryCssClass ? categoryCssClass : '']">
    <div class="courseunit-top-row">
        <div class="credits-box">
            <sis-tooltip
                    [tooltipText]="({min: attainment.credits, max: attainment.credits} | creditRangeLong: 'ATTAINED')"
                    [appendToBody]="true">
                <span class="credit-amount sis-tooltip-trigger-content">
                    {{attainment.credits}}
                </span>
            </sis-tooltip>
            <span class="credit-abbreviation" translate>SIS_COMPONENTS.CREDIT_RANGE.CREDITS_ABBR</span>
        </div>
        <div class="grade-box">
            <sis-icon icon="achievement"></sis-icon>
            <span class="grade-text">{{attainment.gradeId | grade: attainment.gradeScaleId | async }}</span>
        </div>
        <div class="title-box">
            <span class="courseunit-code">
                <span class="no-icon">
                    <sis-button *ngIf="canInfoModalBeOpened"
                                link="true"
                                [ariaLabel]="attainment.code + ' ' + (attainment.name | localizedString) + ' ' + ('ARIA_LABEL.OPEN_INFO' | translate)">
                        <sis-icon icon="brochure"></sis-icon>{{attainment.code}}
                    </sis-button>
                </span>
                <span *ngIf="!canInfoModalBeOpened">
                    <sis-icon icon="brochure"></sis-icon>{{attainment.code}}
                </span>
                <sis-non-active-document-state-badge [documentState]="attainment.documentState"></sis-non-active-document-state-badge>
            </span>
            <span class="courseunit-title">
                <sis-tooltip [tooltipText]="(attainment.name | localizedString)" [appendToBody]="true">
                    <span class="sis-tooltip-trigger-content">
                        {{attainment.name | localizedString}}
                    </span>
                </sis-tooltip>
            </span>
        </div>
        <ng-content select="[attainment-box=right-of-title]">
        </ng-content>
    </div>
    <div class="cu-notification-bar">
        <ng-content select="[attainment-box=notifications]" class="notifications">
        </ng-content>
    </div>
</div>
