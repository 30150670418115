import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ModuleToggles } from 'common-typescript/types';
import { BehaviorSubject, combineLatest, Observable, of, startWith } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { LocalStorageService } from 'sis-common/storage/local-storage.service';

import { UniversityService } from '../service/university.service';

interface ModuleTogglesProperties {
    description: string;
}

interface OpenApiSchema {
    properties: {
        [key in keyof ModuleToggles]: ModuleTogglesProperties
    };
}

export interface OpenApiComponents {
    schemas: { ModuleToggles: OpenApiSchema };
}

export interface OpenApiDocument {
    components: OpenApiComponents;
}

interface ModuleToggleResultObject {
    moduleToggle: keyof ModuleToggles,
    overrideValue?: boolean;
    persistedValue?: boolean;
    description?: string;
}

/**
 * This component will be visible at /feature-toggles if the university configuration 'isTestModeEnabled' is set to true.
 * The control panel allows the user to set true/false values on the 'moduleToggleOverrides' property in local storage, which overrides UniversitySettings.ModuleToggles properties.
 * This helps when testing front end features that are behind ModuleToggles-switches.
 * This component should never be visible in production environments.
 * */
@Component({
    selector: 'sis-feature-toggle',
    templateUrl: './feature-toggle.component.html',
})
export class FeatureToggleComponent implements OnInit {

    moduleToggles$: Observable<ModuleToggleResultObject[]>;
    localOverride$ = new BehaviorSubject<Partial<ModuleToggles>>(this.getLocalOverride());

    constructor(
        private localStorage: LocalStorageService,
        private universitySettingsService: UniversityService,
        private httpClient: HttpClient,
    ) {
    }

    ngOnInit() {
        this.moduleToggles$ = this.getApiDocs().pipe(
            switchMap((res: OpenApiDocument) => combineLatest([
                of(res.components.schemas.ModuleToggles.properties),
                this.universitySettingsService.getCurrentUniversitySettings(true).pipe(
                    map(settings => settings.moduleToggles),
                ),
                this.localOverride$.pipe(startWith(this.getLocalOverride())),
            ]).pipe(map(this.moduleToggleSchemaToResultObjects))),
        );
    }

    moduleToggleSchemaToResultObjects([moduleToggleSchema, persistedModuleToggles, localOverride]: [{ [key in keyof ModuleToggles]: ModuleTogglesProperties }, ModuleToggles, ModuleToggles]): ModuleToggleResultObject[] {
        return Object.entries(moduleToggleSchema)
            .map(([moduleToggle, moduleToggleProperties]: [keyof ModuleToggles, ModuleTogglesProperties]) => {
                const description = moduleToggleProperties.description;
                const resultObject: ModuleToggleResultObject = { moduleToggle, description };
                if (persistedModuleToggles && moduleToggle in persistedModuleToggles) {
                    resultObject.persistedValue = persistedModuleToggles[moduleToggle];
                }
                if (localOverride && moduleToggle in localOverride) {
                    resultObject.overrideValue = localOverride[moduleToggle];
                } else {
                    resultObject.overrideValue = null;
                }
                return resultObject;
            });
    }

    toggleOverrideValue(key: string) {
        const currentOverrides = this.localOverride$.value || {};
        const typedKey = key as keyof ModuleToggles;
        currentOverrides[typedKey] = !currentOverrides[typedKey];
        this.localOverride$.next(currentOverrides);
        this.saveLocalOverride(currentOverrides);
    }

    private getLocalOverride(): any {
        return JSON.parse(this.localStorage.getItem('moduleToggleOverrides')) || {};
    }

    private saveLocalOverride(override: Partial<ModuleToggles>): void {
        this.localStorage.setItem('moduleToggleOverrides', JSON.stringify(override));
    }

    private removeLocalOverride(): void {
        this.localStorage.removeItem('moduleToggleOverrides');
    }

    clear() {
        this.localOverride$.next(null);
        this.removeLocalOverride();
    }

    getApiDocs(): Observable<OpenApiDocument> {
        return this.httpClient.get('/kori/v3/api-docs/default').pipe(
            map(res => res as OpenApiDocument),
        );
    }
}
