import { Transition } from '@uirouter/angular';
import { Enrolment } from 'common-typescript/types';
import { catchError, firstValueFrom, lastValueFrom, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { AuthService } from 'sis-common/auth/auth-service';
import { getInjectables } from 'sis-components/router/router-utils';
import { SisStateDeclaration } from 'sis-components/router/types';
import { PlanEntityService } from 'sis-components/service/plan-entity.service';
import { UserSettingsEntityService } from 'sis-components/service/user-settings-entity.service';

import { FrontpageBetaComponent } from './frontpage-beta/frontpage-beta.component';
import { FrontpageWrapperComponent } from './frontpage-wrapper.component';

export const frontpageRootState = 'student.logged-in.frontpage';
const defaultFrontpageState = `${frontpageRootState}.default`;
const betaFrontpageState = `${frontpageRootState}.beta`;

export const frontpageRoutes: SisStateDeclaration[] = [
    {
        name: frontpageRootState,
        url: '/frontpage',
        redirectTo: resolveFrontpageRoute,
        data: {
            browserTabTitle: 'APPLICATION_FRONTPAGE',
            hasSecondaryBackground: true,
        },
        params: {
            focusBetaFrontpageToggle: {
                value: false,
            },
        },
    },
    {
        name: defaultFrontpageState,
        component: FrontpageWrapperComponent,
        resolve: {
            userHasPlans,
            enrolments: userEnrolments,
        },
    },
    {
        name: betaFrontpageState,
        component: FrontpageBetaComponent,
    },
    {
        // This state exists for backwards compatibility reasons, as the link to the beta frontpage is used in
        // external systems. It enables the beta frontpage for the user and then redirects to the frontpage route.
        name: 'student.logged-in.beta-frontpage-redirect',
        url: '/beta/frontpage',
        redirectTo: enableBetaFrontpageAndRedirect,
    },
];

export async function userHasPlans($transition$: Transition): Promise<boolean> {
    const [planService] = getInjectables($transition$, PlanEntityService);
    return firstValueFrom(planService.getMyPlans()
        .pipe(
            map(plans => plans?.length > 0),
            catchError(() => of(false)),
        ));
}
userHasPlans.$inject = ['$transition$'];

export async function userEnrolments<T extends Enrolment>($transition$: Transition): Promise<T[]> {
    const [authService, enrolmentService] = getInjectables($transition$, AuthService, 'enrolmentService');
    if (!authService.loggedIn()) {
        return Promise.resolve([]);
    }
    return enrolmentService.findMyEnrolments(true, false)
        // return only enrolments that are in calendar and load relations only for those
        .then((enrolments: T[]) => enrolments?.filter(e => e.isInCalendar) ?? [])
        .then((enrolments: T[]) => enrolmentService.loadMinimumDataForCourseUnitRealisationComponent(enrolments));
}

userEnrolments.$inject = ['$transition$'];

export async function resolveFrontpageRoute($transition$: Transition): Promise<string> {
    const [userSettingsService] = getInjectables($transition$, UserSettingsEntityService);
    return firstValueFrom(userSettingsService.getOwnSettings())
        .then(settings => settings?.studentBetaFrontpageEnabled)
        .catch(() => false)
        .then(betaFrontpageEnabled => betaFrontpageEnabled ? betaFrontpageState : defaultFrontpageState);
}

resolveFrontpageRoute.$inject = ['$transition$'];

export function enableBetaFrontpageAndRedirect(transition: Transition) {
    const userSettingsService: UserSettingsEntityService = transition.injector().get(UserSettingsEntityService);
    return lastValueFrom(userSettingsService.getOwnSettings()
        .pipe(
            switchMap(settings => settings.studentBetaFrontpageEnabled ?
                of(settings) : userSettingsService.saveOwnSettings({ ...settings, studentBetaFrontpageEnabled: true })),
            catchError(() => of(null)),
            take(1),
            map(() => betaFrontpageState),
        ));
}
enableBetaFrontpageAndRedirect.$inject = ['$transition$'];
