import { Injectable } from '@angular/core';
import { LearningOpportunitySelectionPath, OtmId } from 'common-typescript/types';

@Injectable({ providedIn: 'root' })
export class LearningOpportunityAllowedPathService {
    /**
     * Checks if {@link allowedPaths} contains paths having {@link moduleGroupId} as the value of the property {@link educationPathKey}.
     */
    isModuleInLearningOpportunityAllowedPaths(
        allowedPaths: readonly LearningOpportunitySelectionPath[],
        moduleGroupId: OtmId,
        educationPathKey: keyof LearningOpportunitySelectionPath,
    ): boolean {
        return allowedPaths.some(allowedPath => allowedPath[educationPathKey] === moduleGroupId);
    }
}
