import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { LocalizedString, ModuleResultItem, OtmId, SearchFilterToggleValueEvent } from 'common-typescript/types';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { SisFormBuilder } from '../../form/sis-form-builder.service';
import { filterInput } from '../../typeahead/typeahead.component';
import { convertAJSPromiseToNative } from '../../util/utils';

interface ModuleValue {
    name: LocalizedString;
    groupId: OtmId;
}

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-search-filter-module',
    templateUrl: './search-filter-module.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class SearchFilterModuleComponent implements OnChanges, OnInit {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.downgraded.search.searchFilterModule',
        directiveName: 'sisSearchFilterModule',
    };

    @Input() filterName: string;
    @Input() getFilterName: (filter: ModuleValue) => string;
    @Input() getModules: (searchString: string) => Promise<ModuleResultItem[]>;
    @Input() listSelected: () => ModuleValue[];
    @Input() placeholderTextKey: string;
    @Input() popoverIsOpen: boolean;
    @Input() selectedModule?: ModuleResultItem;
    @Input() titleTextKey: string;
    @Output() submitFilter = new EventEmitter<void>();
    @Output() toggleModuleValue = new EventEmitter<SearchFilterToggleValueEvent<ModuleResultItem, MouseEvent>>();
    @Output() toggleValue = new EventEmitter<SearchFilterToggleValueEvent<ModuleValue, MouseEvent>>();

    filterSelectorForm: FormGroup;

    constructor(private fb: SisFormBuilder) {}

    ngOnInit(): void {
        this.filterSelectorForm = this.fb.group({});
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.popoverIsOpen && !changes.popoverIsOpen.firstChange && !changes.popoverIsOpen.currentValue) {
            this.filterSelectorForm.reset();
        }
    }

    searchModules(searchString$: Observable<string>): Observable<ModuleResultItem[]> {
        return searchString$.pipe(
            filterInput(),
            switchMap(searchString => from(convertAJSPromiseToNative(this.getModules(searchString)))),
        );
    }
}
