<div class="modal-header">
  <h2 class="modal-title">{{'OPENUNIVERSITY.STUDY_RIGHTS.ENROLMENT_RIGHT_ADD_MODAL.TITLE' | translate}}</h2>
</div>
<form (ngSubmit)="onSubmit()">
  <div class="modal-body" *ngIf="values?.product">
    <div class="row">
      <div class="col-12">
        <sis-tiny-static-form-group label="OPENUNIVERSITY.STUDY_RIGHTS.ENROLMENT_RIGHT_ADD_MODAL.COURSE_UNIT_NAME">
          <span class="value" *ngIf="values.product.courseUnitId | courseUnit | async as courseUnit">
            {{ courseUnit | localizeProperty: 'name' }}
          </span>
        </sis-tiny-static-form-group>
        <sis-tiny-static-form-group label="OPENUNIVERSITY.STUDY_RIGHTS.ENROLMENT_RIGHT_ADD_MODAL.PRODUCT_NAME">
          <span class="value">{{ values.product | localizeProperty: 'name' }}</span>
        </sis-tiny-static-form-group>

      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <label [class]="getLabelState(studentId)">{{ 'OPENUNIVERSITY.STUDY_RIGHTS.ENROLMENT_RIGHT_ADD_MODAL.STUDENT_LABEL' | translate}}</label>
        <sis-search-student (studentChange)="selectStudent($event)" [id]="'add-enrolment-right'"></sis-search-student>
        <sis-validation-errors [control]="studentId" [formFieldId]="'add-enrolment-right'" [ariaLive]="false"></sis-validation-errors>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <sis-contextual-notification variant="warning">
          {{'OPENUNIVERSITY.STUDY_RIGHTS.ENROLMENT_RIGHT_ADD_MODAL.NOT_YET_ENROLLED_WARNING' | translate }}
        </sis-contextual-notification>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <sis-button [hollow]="true" (clicked)="onCancel()">{{'SIS_COMPONENTS.BUTTON.CANCEL' | translate}}</sis-button>
    <sis-button type="submit">{{'SIS_COMPONENTS.BUTTON.YES' | translate}}</sis-button>
  </div>
</form>
