import { KeyValue } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Grant, OtmId } from 'common-typescript/types';
import { Subscription } from 'rxjs';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { GrantEntityService } from 'sis-components/service/grant-entity.service';
import { getStudyTermLocator } from 'sis-components/study-terms/study-year-utils';

@Component({
    selector: 'app-grants-page',
    templateUrl: './grants-page.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class GrantsPageComponent implements OnInit, OnDestroy {

    @Input() studentProfile: any;

    readonly currentAndFutureGrants: Map<number, Grant[]> = new Map();
    readonly pastGrants: Map<number, Grant[]> = new Map();

    private grantSubscription: Subscription;

    /** A comparator function that causes the `keyvalue` pipe to sort the entries by key in descending order. */
    readonly reverseKeyOrder = (a: KeyValue<number, any>, b: KeyValue<number, any>) => b.key - a.key;

    constructor(
        private grantService: GrantEntityService,
        private appErrorHandler: AppErrorHandler,
    ) {}

    ngOnInit(): void {
        this.loadGrants(this.studentProfile.id);
    }

    ngOnDestroy(): void {
        this.grantSubscription?.unsubscribe();
    }

    private loadGrants(personId: OtmId): void {
        if (personId) {
            this.grantSubscription = this.grantService.findForPerson(personId)
                .pipe(this.appErrorHandler.defaultErrorHandler())
                .subscribe(
                    grants => this.groupAndSetGrants(grants),
                );
        }
    }

    private groupAndSetGrants(grants: Grant[]): void {
        if (!grants) {
            return;
        }

        const currentStudyTerm = getStudyTermLocator();
        const studyYearsWithGrants = grants
            .map(grant => grant?.studyTerm?.studyYearStartYear)
            .filter(Boolean)
            .concat(currentStudyTerm.studyYearStartYear)
            .sort((a, b) => b - a)
            .reduce((set, year) => set.add(year), new Set<number>());

        studyYearsWithGrants.forEach((studyYearStartYear) => {
            const grantsForStudyYear = grants
                .filter(grant => grant?.studyTerm?.studyYearStartYear === studyYearStartYear)
                .sort((a, b) => (b.registrationDate ?? '').localeCompare(a.registrationDate ?? ''));
            if (studyYearStartYear >= currentStudyTerm.studyYearStartYear) {
                this.currentAndFutureGrants.set(studyYearStartYear, grantsForStudyYear);
            } else {
                this.pastGrants.set(studyYearStartYear, grantsForStudyYear);
            }
        });
    }
}
