/* globals $ */
(function () {
  'use strict';

  angular.module('student.timing.untimedCourseUnits.untimedCourseUnitsAutoScroll', []).directive('untimedCourseUnitsAutoScroll', ["$window", function ($window) {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        element.bind('click', function () {
          if (_.get(attrs, 'untimedCourseUnitsAutoScroll', '') === 'up') {
            $window.scroll(0, 0);
          }
        });
      }
    };
  }]);
})();