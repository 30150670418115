import { Pipe, PipeTransform } from '@angular/core';
import { EntityState } from '@datorama/akita';
import { CourseUnit, Module, OtmId, StudyEvent } from 'common-typescript/types';
import { Observable } from 'rxjs';

import { AppErrorHandler } from '../error-handler/app-error-handler';
import { CourseUnitEntityService } from '../service/course-unit-entity.service';
import { EntityService } from '../service/entity.service';
import { ModuleEntityService } from '../service/module-entity.service';
import { StudyEventEntityService } from '../service/study-event-entity.service';

/** A variant of `EntityPipe` that returns lists of entities instead of single entities. */
export class EntityListPipe<T> {
    constructor(
        private entityService: EntityService<EntityState<T, OtmId>>,
        private appErrorHandler: AppErrorHandler,
    ) {}

    transform(ids: OtmId[]): Observable<T[]> | null {
        if (!ids || ids.length === 0) {
            return null;
        }
        return this.entityService.getByIds(ids)
            .pipe(this.appErrorHandler.defaultErrorHandler());
    }
}

@Pipe({ name: 'studyEvents' })
export class StudyEventsPipe extends EntityListPipe<StudyEvent> implements PipeTransform {
    constructor(private service: StudyEventEntityService, appErrorHandler: AppErrorHandler) {
        super(service, appErrorHandler);
    }
}

@Pipe({ name: 'courseUnits' })
export class CourseUnitsPipe extends EntityListPipe<CourseUnit> implements PipeTransform {
    constructor(private service: CourseUnitEntityService, appErrorHandler: AppErrorHandler) {
        super(service, appErrorHandler);
    }
}

@Pipe({ name: 'modules' })
export class ModulesPipe extends EntityListPipe<Module> implements PipeTransform {
    constructor(private service: ModuleEntityService, appErrorHandler: AppErrorHandler) {
        super(service, appErrorHandler);
    }
}
