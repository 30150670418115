<div class="sis-mb-xs">
  <sis-module-box [moduleGroupId]="selection.moduleGroupId" [attainment]="attainment" [categoryCssClass]="moduleColorCategory">
    <div module-box="extraitemholder">
      <div *ngIf="editMode" class="additional-box">
        <sis-button class="button-box" link="true" (clicked)="removeModule.emit()" [ariaLabel]="'ARIA_LABEL.DELETE' | translate">
          <sis-icon icon="delete"></sis-icon>
        </sis-button>
      </div>
    </div>
    <div module-box="notifications">
      <div *ngIf="isApprovedMinorSelection && (this.editMode || !this.attainment) && selection.validityPeriod" class="studymodule-notification-bar">

        <div class="studymodule-notification">
          <ng-container [ngTemplateOutlet]="notification"
                        [ngTemplateOutletContext]="{key:'PROFILE.MINOR_SELECTIONS.VALID', params: {validityPeriod: (selection.validityPeriod | localDateRange)}, icon: 'open'}">
          </ng-container>
        </div>

        <div *ngIf="!editMode && (selection.validityPeriod|dateRangePast)" class="studymodule-notification">
          <ng-container [ngTemplateOutlet]="notification"
                        [ngTemplateOutletContext]="{key:'PROFILE.MINOR_SELECTIONS.REQUEST_FOR_ENDED', icon: 'close'}">
          </ng-container>
        </div>

        <div *ngIf="selection.validityPeriod|dateRangePast" class="studymodule-notification notification-continued">
          <ng-container [ngTemplateOutlet]="notification"
                        [ngTemplateOutletContext]="{key:'PROFILE.MINOR_SELECTIONS.ENDED', params: {endDate: (selection.validityPeriod.endDate | previousDay | localDateFormat)}}">
          </ng-container>
        </div>
      </div>

      <div *ngIf="this.isRequestedMinorSelection" class="studymodule-notification-bar">
        <div class="studymodule-notification">
          <ng-container [ngTemplateOutlet]="notification"
                        [ngTemplateOutletContext]="{key:'PROFILE.MINOR_SELECTIONS.REQUESTED', icon: 'close'}">
          </ng-container>
        </div>
      </div>
    </div>
  </sis-module-box>
</div>

<ng-template #notification let-key="key" let-params="params" let-icon="icon">
  <span class="studymodule-notification-content" [translate]="key" [translateParams]="params"></span>
  <span class="studymodule-notification-header">
    <sis-icon *ngIf="icon" [icon]="icon==='open' ? 'lock-open' : 'lock'"></sis-icon>
  </span>
</ng-template>
