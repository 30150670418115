<ng-container *transloco="let t">
  <sis-common-attainment-details [attainment]="attainment" [secondaryAttainments]="secondaryAttainments">
    <ng-template *ngIf="expirationImminent" #expirationNotification>
      <sis-attainment-expiration-notification [attainment]="attainment"></sis-attainment-expiration-notification>
    </ng-template>
    <ng-template #registrationDetails>
      <div class="form-group-mimic" role="listitem">
        <dt>{{ t('ATTAINMENTS.ATTAINMENT_DETAILS.REGISTRATION_DATE') }}</dt>
        <dd>{{ attainment.registrationDate | localDateFormat }}</dd>
      </div>
    </ng-template>
  </sis-common-attainment-details>
</ng-container>
