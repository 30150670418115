<div *ngIf="this.questionnaireAnswers && this.questionnaireAnswers.length > 0">
  <div class="grouping-panel">
    <fudis-heading [level]="headingLevel">{{ 'STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.OTHER_INFO.APPLICATION_QUESTIONS' | translate }}</fudis-heading>
    <div class="grouping-panel-content">
      <div *ngFor="let answer of this.questionnaireAnswers; first as isFirst;"
           [class.answer-margin]="!isFirst">
        <sis-degree-programme-attainment-application-answer
            [answer]="answer">
        </sis-degree-programme-attainment-application-answer>
      </div>
    </div>
  </div>
</div>
