<ng-container *transloco="let t">
  <ng-container *ngIf="ruleModule() as module">
    <ng-container *ngIf="module.type === 'StudyModule'">
      <sis-plan-structure-study-module-box [module]="$any(module)"
                                           [planStateObject]="_planStateObject()"
                                           [planData]="planData"
                                           [selected]="this.ruleValidationResult()?.active"
                                           [action]="this.isRuleSelectionDisabled() ? undefined : 'select-to-plan'"
                                           (actionChange)="handleToggle()"
                                           [headingLevel]="headingLevel">
        <sis-study-notification-box *ngIf="ruleValidationResult()?.active && isParentRuleSelectAllRule()">
          {{ t('PLAN_EDIT.SELECTION_MODAL.RULE_BOX_NOTIFICATION.MANDATORY_SELECTION') }}
        </sis-study-notification-box>
      </sis-plan-structure-study-module-box>
    </ng-container>
    <ng-container *ngIf="module.type === 'DegreeProgramme'">
      <sis-plan-structure-degree-programme-box [module]="$any(module)"
                                               [planStateObject]="_planStateObject()"
                                               [planData]="planData"
                                               [selected]="this.ruleValidationResult()?.active"
                                               [action]="this.isRuleSelectionDisabled() ? undefined : 'select-to-plan'"
                                               (actionChange)="handleToggle()"
                                               [headingLevel]="headingLevel">
        <sis-study-notification-box *ngIf="ruleValidationResult()?.active && isParentRuleSelectAllRule()">
          {{ t('PLAN_EDIT.SELECTION_MODAL.RULE_BOX_NOTIFICATION.MANDATORY_SELECTION') }}
        </sis-study-notification-box>
      </sis-plan-structure-degree-programme-box>
    </ng-container>
  </ng-container>
</ng-container>
