import { provideTranslationMarkupTranspiler } from 'ngx-transloco-markup';

import {
    BoldTranspiler,
    BrTranspiler,
    EmphasisTranspiler,
    H5Transpiler,
    H6Transpiler,
    HyperlinkTranspiler,
    ItalicTranspiler,
    LiTranspiler,
    MarkTranspiler,
    OlTranspiler,
    ParagraphTranspiler,
    SmallTranspiler,
    SpanTranspiler,
    StrongTranspiler,
    SubTranspiler,
    SupTranspiler,
    UlTranspiler,
    UnderlineTranspiler,
} from './all-transpilers';

export const provideAllTranspilers = () => [
    BoldTranspiler,
    BrTranspiler,
    EmphasisTranspiler,
    H5Transpiler,
    H6Transpiler,
    HyperlinkTranspiler,
    ItalicTranspiler,
    LiTranspiler,
    MarkTranspiler,
    OlTranspiler,
    ParagraphTranspiler,
    SmallTranspiler,
    SpanTranspiler,
    StrongTranspiler,
    SubTranspiler,
    SupTranspiler,
    UlTranspiler,
    UnderlineTranspiler,
].map(
    transpiler => provideTranslationMarkupTranspiler(transpiler),
);
