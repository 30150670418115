<ng-container *transloco="let t">
  <ul class="message-conversations">
    <li *ngFor="let messageConversation of messageConversations">
      <div class="list-item" [ngClass]="{'unread-message': messageConversation.messagesUnread > 0}">
        <div class="checkbox-container">
          <sis-checkbox
            [attr.aria-label]="t('ARIA_LABEL.SELECT_MESSAGES')"
            [checked]="isMessageConversationSelected(messageConversation.conversationId)"
            (checkedChange)="toggleSelection(messageConversation.conversationId)">
          </sis-checkbox>
        </div>
        <a href="javascript:void(0);" class="message anchor-button"
           (click)="goToSpecificMessageConversation($event, messageConversation)" data-cy="frontpage-messages"
           [attr.aria-label]="
           ((messageConversation.messagesUnread > 0 ? (messageConversation.messagesUnread === 1 ? t('SIS_COMPONENTS.MESSENGER.MESSAGE_CONVERSATIONS.MESSAGE_CONVERSATION_NUMBER_OF_UNREAD_MESSAGES_ONE') : t('SIS_COMPONENTS.MESSENGER.MESSAGE_CONVERSATIONS.MESSAGE_CONVERSATION_NUMBER_OF_UNREAD_MESSAGES_TOTAL', { unreadMessageCount: messageConversation.messagesUnread }) ) : '') + ' ' +
           (t('ARIA_LABEL.SENDER')) + ' ' +
           (getSenderFullName(messageConversation.selectedMessage) || '') + ' ' +
           ((messageConversation.selectedMessage.senderName | localizedString) || '') + ' ' +
           (messageConversation.selectedMessage.title || '') + ' ' +
           ((messageConversation.selectedMessage.localizedTitle | localizedString) || '') + ' ' +
           (t('ARIA_LABEL.MESSAGE_TYPE') + ' ' + t('' + getMessageTypeTranslationKey(messageConversation.selectedMessage))) + ' ' +
           (messageConversation.selectedMessage.messageTime | localDateFormat) + ' ' + t('TIME.WITH_PREFIX', { time: messageConversation.selectedMessage.messageTime | localTimeFormat }))">
          <div class="col-lg-12 col-10 message-content">
            <div class="message-preview-header">
              <span class="message-preview-header-additional" *ngIf="messageConversation.selectedMessage.type === 'AutomaticCourseUnitRealisationMessage' || messageConversation.selectedMessage.type === 'EnrolmentReminderMessage'">
                <strong>{{ messageConversation.selectedMessage.senderName | localizedString }}</strong>
              </span>
              <span class="message-preview-header-additional" *ngIf="messageConversation.selectedMessage.type !== 'AutomaticCourseUnitRealisationMessage' && messageConversation.selectedMessage.type !== 'EnrolmentReminderMessage'">
                <strong>{{ getSenderFullName(messageConversation.selectedMessage) }}</strong>
              </span>
              <span class="message-preview-header-additional">{{ t('' + getMessageTypeTranslationKey(messageConversation.selectedMessage))}} {{ t(getMessageTargetName(messageConversation.selectedMessage)) }}</span>
              <span class="message-preview-header-additional" *ngIf="messageConversation.messagesUnread > 1">
                <strong>{{ t('SIS_COMPONENTS.MESSENGER.MESSAGE_CONVERSATIONS.MESSAGE_CONVERSATION_NUMBER_OF_UNREAD_MESSAGES_TOTAL', {unreadMessageCount: messageConversation.messagesUnread}) }}</strong>
              </span>
              <span class="message-preview-header-additional" *ngIf="messageConversation.messagesUnread === 1">
                <strong>{{ t('SIS_COMPONENTS.MESSENGER.MESSAGE_CONVERSATIONS.MESSAGE_CONVERSATION_NUMBER_OF_UNREAD_MESSAGES_ONE') }}</strong>
              </span>
              <span class="message-conversation-date message-preview-header-additional">
                {{messageConversation.selectedMessage.messageTime | localDateFormat }}
                {{t('TIME.WITH_PREFIX', { time: messageConversation.selectedMessage.messageTime | localTimeFormat })}}
              </span>
            </div>
            <div class="message-snipped">
              <span *ngIf="messageConversation.selectedMessage.title"
                    class="message-title">{{messageConversation.selectedMessage.title}}</span>
              <span *ngIf="messageConversation.selectedMessage.localizedTitle"
                    class="message-title">{{messageConversation.selectedMessage.localizedTitle | localizedString}}</span>
              <p class="message-comment">
                <span
                  *ngIf="messageConversation.selectedMessage.title || messageConversation.selectedMessage.localizedTitle"></span>
                {{messageConversation.selectedMessage.comment ? messageConversation.selectedMessage.comment : ''}}
                <sis-localized-markup-string-view *ngIf="messageConversation.selectedMessage.localizedComment" [content]="messageConversation.selectedMessage.localizedComment"/>
              </p>
            </div>
          </div>
        </a>
      </div>
    </li>
  </ul>
</ng-container>
