import { Injectable } from '@angular/core';
import { ValidatablePlan } from 'common-typescript/src/plan/validation/validatablePlan';
import { CourseUnit, CurriculumPeriod, OtmId } from 'common-typescript/types';
import _ from 'lodash';
import moment from 'moment/moment';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';
import { CourseUnitEntityService } from 'sis-components/service/course-unit-entity.service';
import { CurriculumPeriodEntityService } from 'sis-components/service/curriculum-period-entity.service';

@StaticMembers<DowngradedService>()
@Injectable({ providedIn: 'root' })
export class StudentPlanOutdatedCourseUnitsService {

    static downgrade: ServiceDowngradeMappings = {
        moduleName: 'student.common.service.studentPlanOutdatedCourseUnitsService.downgraded',
        serviceName: 'studentPlanOutdatedCourseUnitsService',
    };

    constructor(private courseUnitEntityService: CourseUnitEntityService,
                private readonly _curriculumPeriodService: CurriculumPeriodEntityService,
    ) {}

    hasCurrentOrFutureCurriculumPeriods(courseUnitCurrIds: OtmId[], currentOrFutureCurIds: Set<OtmId>): boolean {
        return courseUnitCurrIds.some((courseUnitCurrId) => currentOrFutureCurIds.has(courseUnitCurrId));
    }

    // start date same or after the start date of currentCurriculumPeriod start date
    findCurrentOrFutureCurIds(curPeriods: CurriculumPeriod[], currentCurriculumPeriod: CurriculumPeriod) {
        return new Set(curPeriods
            .filter((curr: CurriculumPeriod) => moment(curr.activePeriod.startDate).isSameOrAfter(currentCurriculumPeriod.activePeriod.startDate))
            .map((curr: CurriculumPeriod) => curr.id));
    }

    // collect curriculumPeriodIds from the course units
    getCurriculumPeriodIdSet(courseUnitsNotYetAttained: CourseUnit[]) {
        const cuCurriculumPeriodIds: OtmId[][] = courseUnitsNotYetAttained.map(courseUnit => courseUnit.curriculumPeriodIds);
        return new Set(cuCurriculumPeriodIds?.flat());
    }

    checkLatestCourseUnitsAreInSelectedPlan(validatablePlan: ValidatablePlan): Observable<[CourseUnit, CourseUnit][]> {
        return this._curriculumPeriodService.findCurrentCurriculumPeriod().pipe(
            switchMap((currentCurriculumPeriod: CurriculumPeriod) => {
                const currentCurriculumPeriodId: string = currentCurriculumPeriod.id;
                // Returns all course units in the plan. For course units that have been substituted, only the substituting course units will be returned.
                const courseUnitsInPlan: CourseUnit[] = validatablePlan.getAllCourseUnitsInPlan();

                if (_.isEmpty(courseUnitsInPlan)) {
                    return of([]);
                }
                const courseUnitsNotYetAttained: CourseUnit[] =
                    courseUnitsInPlan.filter(courseUnit => !validatablePlan.isCourseUnitAttained(courseUnit.id));

                if (_.isEmpty(courseUnitsNotYetAttained)) {
                    return of([]);
                }
                // collect curriculumPeriodIds from the course units
                const cuCurriculumPeriodIdSet: Set<OtmId> = this.getCurriculumPeriodIdSet(courseUnitsNotYetAttained);

                // get curriculum periods using the ids
                return this._curriculumPeriodService.getByIds(Array.from(cuCurriculumPeriodIdSet)).pipe(
                    switchMap((curPeriods: CurriculumPeriod[]) => {
                        const currentOrFutureCurriculumPeriodIds = this.findCurrentOrFutureCurIds(curPeriods, currentCurriculumPeriod);
                        const courseUnitsInThePast: CourseUnit[] =
                            courseUnitsNotYetAttained.filter(
                                (cu: CourseUnit) => !this.hasCurrentOrFutureCurriculumPeriods(cu.curriculumPeriodIds, currentOrFutureCurriculumPeriodIds));

                        if (_.isEmpty(courseUnitsInThePast)) {
                            return of([]);
                        }
                        const courseUnitGroupIds: string[] = courseUnitsInThePast.map(courseUnit => courseUnit.groupId);

                        return this.courseUnitEntityService.getByGroupIdsCurriculumPeriodIdDocumentState(
                            courseUnitGroupIds,
                            currentCurriculumPeriodId,
                            ['ACTIVE'],
                        ).pipe(
                            map((courseUnitsInCurrentCurriculumPeriod: CourseUnit[]) =>
                                this.getListOfOutdatedCourseUnits(courseUnitsInThePast, courseUnitsInCurrentCurriculumPeriod)),
                        );
                    }));
            }),
        );
    }

    getListOfOutdatedCourseUnits(courseUnitsInPlan: CourseUnit[], currentCurriculumPeriodCourseUnits: CourseUnit[]): [CourseUnit, CourseUnit][] {
        const outdatedCourseUnits: [CourseUnit, CourseUnit][] = [];
        if (_.isEmpty(currentCurriculumPeriodCourseUnits)) {
            return [];
        }
        _.forEach(courseUnitsInPlan, (planCourseUnit) => {
            const matchingCourseUnitFromCurrentCurriculumPeriod =
                currentCurriculumPeriodCourseUnits.find(courseUnit => courseUnit.groupId === planCourseUnit.groupId);
            if (matchingCourseUnitFromCurrentCurriculumPeriod) {
                if (matchingCourseUnitFromCurrentCurriculumPeriod.id !== planCourseUnit.id) {
                    outdatedCourseUnits.push([planCourseUnit, matchingCourseUnitFromCurrentCurriculumPeriod]);
                }
            }
        });
        return outdatedCourseUnits;
    }
}
