import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { dateUtils } from 'common-typescript';
import { LocalDateRange, LocalDateTimeRange } from 'common-typescript/types';
import moment from 'moment';
import { DatePeriod } from 'sis-common/generated/graphql';

/**
 * Transforms a `LocalDateRange` or `LocalDateTimeRange` object into a string with a locale-specific format for the UI.
 * As date and date time ranges in SISU are half-open, one day is subtracted from the end date (time) in the output.
 *
 * If the input is a `LocalDateTimeRange`, the time parts are ignored. This can cause unwanted behavior in some
 * situations, since one day will in any case be subtracted from the end date, (e.g. if the end date time is
 * '2020-02-02T12:00', the corresponding output will be '1.2.2020'). You have been warned. If needed, this behavior
 * can be changed e.g. by introducing a new optional argument.
 */
@Pipe({ name: 'localDateRange' })
export class LocalDateRangePipe implements PipeTransform {

    constructor(private translate: TranslateService) {}

    transform(input: LocalDateRange | LocalDateTimeRange | DatePeriod): string {
        if (!input) {
            return '';
        }
        let startDate: moment.Moment;
        let endDate: moment.Moment;
        if ('startDate' in input || 'endDate' in input) {
            startDate = dateUtils.createMoment(input.startDate);
            // End date is always excluded in our date ranges
            endDate = dateUtils.createMoment(input.endDate)?.subtract(1, 'day');
        } else if ('startDateTime' in input || 'endDateTime' in input) {
            const cast = input as LocalDateTimeRange;
            startDate = dateUtils.createMoment(cast.startDateTime);
            // End date is always excluded in our date ranges
            endDate = dateUtils.createMoment(cast.endDateTime)?.subtract(1, 'day');
        }
        if (!startDate && !endDate) {
            return '';
        }

        let format: string;
        if (startDate && endDate && startDate.isSame(endDate)) {
            format = 'ARE_SAME';
        } else if (startDate && endDate && startDate.isAfter(endDate)) {
            format = 'EMPTY';
        } else if (startDate && !endDate) {
            format = 'START_';
        } else if (!startDate && endDate) {
            format = '_END';
        } else {
            format = 'START_END';
        }

        return this.translate.instant(`DATE_RANGE.${format}`, {
            start: startDate ? startDate.format('l') : undefined,
            end: endDate ? endDate.format('l') : undefined,
        });
    }
}
