<div class="clear">
  <ul class="tree-list tree-list-study-plan clearfix">
    <li class="{{classes}}">
      <div class="module-border" [class.collapsed]="!isOpen" [class.module-border-selected]="false">
        <div class="tree-list-heading tree-list-heading-has-children clearfix no-padding">
          <div class="accordion-panel accordion-panel-long-title accordion-panel-noblock" [class.highlighted]="highlighted">
            <div class="header has-header-items">
              <button type="button"
                      class="collapse non-button"
                      (click)="isOpen = !isOpen"
                      [class.collapsed]="!isOpen"
                      [attr.aria-expanded]="isOpen"
                      [attr.aria-label]="(attainmentGroupNode.name | localizedString)">
                <sis-icon icon="chevron-ring-fill" [iconClass]="isOpen ? 'cw-90' : null"></sis-icon>
              </button>
              <div class="title grouping-module">
                <span class="plan-module-name">{{attainmentGroupNode.name | localizedString}}</span>
                <span class="additional"> </span>
              </div>
            </div>
            <div *ngIf="isOpen" class="content animate-if" [class.collapsed]="!isOpen">
              <sis-attainment-node-structure [attainmentNodes]="attainmentGroupNode.nodes"
                                             [planStateObject]="planStateObject"
                                             [planData]="planData"
                                             [uiStateObject]="uiStateObject"
                                             [level]="level">
              </sis-attainment-node-structure>
            </div>
          </div>
        </div>
      </div>
    </li>
  </ul>
</div>





