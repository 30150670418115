<div class="search-filter organisation-filter">
  <form name="filterSelectorForm" [formGroup]="filterSelectorForm" novalidate>
    <div class="row">
      <div class="col-12">
        <sis-typeahead class="organisation-filter-typeahead"
                       [model]="selectedOrganisation"
                       [placeholder]="'SIS_COMPONENTS.ORGANISATION.SEARCH_ORGANISATION.SEARCH_ORGANISATIONS' | translate"
                       [resultTemplate]="customOrganisationTemplate"
                       (selectItem)="toggleValue.emit({ form: filterSelectorForm, value: $event.item })"
                       [typeahead]="searchOrganisations.bind(this)">
        </sis-typeahead>
      </div>
    </div>
    <div ng-if="ctrl.helpText" class="row help-block">
      <div class="col-12">
        <p>{{helpText}}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <button type="button"
                class="btn btn-sm btn-primary btn-naked float-end open-organisation-selector-with-text form-control"
                data-cy="open-organisation-modal"
                (click)="openOrganisationModal.emit()"
                [attr.aria-label]="'ARIA_LABEL.OPEN_UNIVERSITY_MENU' | translate">
          <sis-icon icon="picker"></sis-icon>
          <span translate>SEARCH.OPEN_ORGANISATION_MODAL</span>
        </button>
      </div>
    </div>
    <div class="row filter-preview-row">
      <div class="col-9">
        <div class="filter-preview-holder">
          <span class="filter-preview-title">
            {{filterName}}
          </span>
          <span class="filter-preview-content">
            <span
              *ngFor="let filterValue of listSelected(); first as first"
              [class.item-separator]="!first"
            >
              {{getFilterName(filterValue)}}
            </span>
          </span>
        </div>
      </div>
      <div class="col-3">
        <button
          type="submit"
          class="btn btn-lg btn-primary filter-submit"
          (click)="submitFilter.emit()"
          [disabled]="filterSelectorForm.pristine"
          translate="SEARCH.FILTER_SELECTOR.OK"
        ></button>
      </div>
    </div>
  </form>
</div>

<ng-template #customOrganisationTemplate let-result="result">
  <sis-tooltip [tooltipText]="result.name | localizedString" [appendToBody]="true" tooltipPlacement="left">
    <span class="sis-tooltip-trigger-content"
          data-cy="organisation-search-result">{{ result.name | localizedString }}</span>
  </sis-tooltip>
</ng-template>
