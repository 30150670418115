import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { CustomModuleAttainment } from 'common-typescript/types';
import _ from 'lodash';

import { CustomModuleAttainmentStateObject, PlanData, PlanStateObject } from '../../service/plan-state.service';

@Component({
    selector: 'sis-plan-structure-custom-module-attainment',
    templateUrl: './plan-structure-custom-module-attainment.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanStructureCustomModuleAttainmentComponent implements OnChanges {
    @Input({ required: true }) customModuleAttainment: CustomModuleAttainment;
    @Input({ required: true }) planStateObject: PlanStateObject;
    @Input({ required: true }) planData: PlanData;
    @Input({ required: true }) headingLevel: number;

    customModuleAttainmentStateObject: CustomModuleAttainmentStateObject;

    ngOnChanges(changes: SimpleChanges) {
        if (this.customModuleAttainment) {
            this.customModuleAttainmentStateObject = _.get(this.planStateObject.customModuleAttainments, this.customModuleAttainment.id);
        }
    }
}
