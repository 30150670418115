import { AsyncValidatorFn, FormControl, FormControlOptions, FormControlState, ValidatorFn } from '@angular/forms';
import { Observable, Subject } from 'rxjs';

/**
 *
 * @link FormControl provides observables to track changes to its value and status changes.
 * Using similar observables, this form control adds additional functionality to track changes to the control's following fields:
 * @link FormControl.touched
 * @link FormControl.untouched
 * @link FormControl.dirty
 * @link FormControl.pristine
 *
 * Usage is identical to FormControls native valueChanges and statusChanges:
 *
 * const myControl = new SisTrackedFormControl(null);
 * myControl.touchedChanges.subscribe(() => console.log('touched by an angel'));
 * myControl.markAsTouched(); // => touched by an angel
 *
 * This can be used in the same way as FormControl, but the added functionality becomes apparent when paired with {@link FormControlChangeDetectorComponent}
 * */
export class SisFormControl<T> extends FormControl<T> {
    constructor(value: FormControlState<T> | T, validatorOrOpts?: ValidatorFn | ValidatorFn[] | FormControlOptions | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null) {
        super(value, validatorOrOpts, asyncValidator);
        this.touchedChanges = this.onTouchedSubject.asObservable();
        this.pristineChanges = this.onPristineSubject.asObservable();
    }

    private onTouchedSubject = new Subject<boolean>();
    private onPristineSubject = new Subject<boolean>();

    touchedChanges: Observable<boolean>;
    pristineChanges: Observable<boolean>;

    markAsTouched(options?: { onlySelf?: boolean }): void {
        super.markAsTouched(options);
        this.onTouchedSubject.next(this.touched);
    }

    markAsUntouched(opts?: { onlySelf?: boolean }) {
        super.markAsUntouched(opts);
        this.onTouchedSubject.next(this.touched);
    }

    markAsDirty(opts?: { onlySelf?: boolean }) {
        super.markAsDirty(opts);
        this.onPristineSubject.next(this.pristine);
    }

    markAsPristine(opts?: { onlySelf?: boolean }) {
        super.markAsPristine(opts);
        this.onPristineSubject.next(this.pristine);
    }
}
