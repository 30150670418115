import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { PlacementArray } from '@ng-bootstrap/ng-bootstrap/util/positioning';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-tooltip',
    templateUrl: './tooltip.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipComponent {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sisComponents.tooltip.tooltip',
        directiveName: 'sisTooltip',
    };

    @Input() tooltipText?: string;

    @Input() tooltipPlacement: PlacementArray = 'auto';

    @Input() appendToBody?: boolean;

    @Input() tooltipEnabled = true;

    @Input() tooltipClass?: string;

    @Input() tooltipInnerHtml?: string;

    /* set true if tooltip aligns wrong, sets display: inline-block for .sis-tooltip-trigger-content */
    @Input() tooltipBlockElement?: boolean;

    @Input() tooltipTriggers = 'hover focus';
}
