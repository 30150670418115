<div class="row event-detail-row">
    <div class="col-11 col-sm-10">

        <div class="row">
            <div class="col-3 col-xl-2">{{ studyEvent | studyEventDateRange }}</div>
            <div class="col-2 col-sm-1">{{ studyEvent | studyEventWeekdayOrInterval }}</div>
            <div class="col-2">{{ studyEvent | studyEventTimeRange }}</div>
            <div class="locations col-5"
                 [ngClass]="{'col-sm-4 col-md-5 col-lg-6': cancelledEventCount > 0,
                         'col-sm-5 col-md-6': cancelledEventCount === 0}">
                <div *ngIf="allEventsExcludedOrCancelled">
                    <span class="tiny-badge danger" translate="SIS_COMPONENTS.COURSE_UNIT_REALISATION_STRUCTURE.CANCELLED">
                    </span>
                </div>
                <div *ngIf="!allEventsExcludedOrCancelled">
                    <div *ngFor="let locationId of studyEvent?.locationIds" class="location-name">
                        <sis-icon icon="pin-small"></sis-icon>
                        <span class="location" *ngIf="locationId | location | async as location">
                                    {{location.building.name | localizedString}}, {{location.name | localizedString}}</span>
                    </div>
                    <div *ngIf="hasNoLocations" class="no-location">
                        <sis-icon icon="pin-small"></sis-icon>
                        <span class="location" translate="SIS_COMPONENTS.COURSE_UNIT_REALISATION_STRUCTURE.NO_LOCATION"></span>
                    </div>
                    <div *ngIf="hasLocationOverrides" class="irregularities">
                        (<span translate="SIS_COMPONENTS.COURSE_UNIT_REALISATION_STRUCTURE.IRREGULAR_LOCATIONS"></span>)
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="cancelledEventCount > 0 && !allEventsExcludedOrCancelled"
         class="col-1 col-sm-2">
        <sis-icon icon="alert" color="danger"></sis-icon>
        <span class="cancelled-events">
            <span translate="SIS_COMPONENTS.COURSE_UNIT_REALISATION_STRUCTURE.CANCELLED_EVENTS"></span>
            ({{ cancelledEventCount }})
        </span>
    </div>
</div>
<sis-study-sub-group-structure-study-event-exceptions *ngIf="irregularEvents.length > 0"
  [irregularEvents]="irregularEvents"
  [studyEventLocationIds]="studyEvent.locationIds"
  [studyEventTeacherIds]="teacherIds"
  [language]="language">
</sis-study-sub-group-structure-study-event-exceptions>
