<h3>{{ 'PROFILE.GRANTS.TAB_TITLE' | translate }}</h3>
<div *ngFor="let entry of currentAndFutureGrants | keyvalue: reverseKeyOrder">
  <h4>{{ 'PROFILE.GRANTS.STUDY_YEAR' | translate:{name: entry.key | studyYearName} }}</h4>
  <p *ngIf="entry.value?.length === 0" class="guidance">
    {{ 'PROFILE.GRANTS.NO_GRANTS_FOR_STUDY_YEAR' | translate }}
  </p>
  <app-study-year-grants [grants]="currentAndFutureGrants.get(entry.key)" [level]="5"></app-study-year-grants>
</div>
<sis-expandable *ngIf="pastGrants.size > 0"
                [title]="'PROFILE.GRANTS.EARLIER_STUDY_YEARS' | translate"
                [level]="4">
  <ng-template sisExpandableContent>
    <div *ngFor="let entry of pastGrants | keyvalue: reverseKeyOrder">
      <h5 class="fudis-heading__md sis-mb-md">{{'PROFILE.GRANTS.STUDY_YEAR' | translate:{name: entry.key + '–' + (entry.key + 1)} }}</h5>
      <app-study-year-grants [level]="6" [grants]="pastGrants.get(entry.key)"></app-study-year-grants>
    </div>
  </ng-template>
</sis-expandable>
