import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { OtmId, UserSettings } from 'common-typescript/types';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UserSettingsEntityService {

    private readonly api = '/ori/api/user-settings';
    private readonly store = new UserSettingsStore();
    private readonly query = new UserSettingsQuery(this.store);

    constructor(
        private httpClient: HttpClient,
    ) {}

    getOwnSettings(bypassCache = false): Observable<UserSettings> {
        if (!bypassCache && this.query.hasActive()) {
            return this.query.selectActive();
        }
        return this.httpClient.get<UserSettings>(`${this.api}/own`)
            .pipe(switchMap(settings => {
                this.store.upsert(settings.personId, settings);
                this.store.setActive(settings.personId);
                return this.query.selectActive();
            }));
    }

    saveOwnSettings(settings: Partial<UserSettings>): Observable<UserSettings> {
        return this.httpClient.post<UserSettings>(`${this.api}/own`, settings)
            .pipe(switchMap(savedSettings => {
                this.store.upsert(savedSettings.personId, savedSettings);
                this.store.setActive(savedSettings.personId);
                return this.query.selectActive();
            }));
    }
}

type UserSettingsState = EntityState<UserSettings, OtmId> & ActiveState;

@StoreConfig({
    name: 'user-settings',
    idKey: 'personId',
})
class UserSettingsStore extends EntityStore<UserSettingsState> {}

class UserSettingsQuery extends QueryEntity<UserSettingsState> {
    constructor(protected store: UserSettingsStore) {
        super(store);
    }
}
