import { Injectable } from '@angular/core';
import { UniversityConfig } from 'common-typescript/types';

declare let universityConfig: UniversityConfig;

/**
 * Provides access to the university configs (stored as a global variable). Angular and AngularJS have their own instances
 * of this service available for injection. This makes it easier to write tests, since no mocking is needed.
 */
@Injectable({ providedIn: 'root' })
export class ConfigService {
    private readonly config = universityConfig;

    get(): UniversityConfig {
        return this.config;
    }
}
