import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';

/**
 * A simple checkbox form control component. This is the preferred component to use in Reactive forms. If you need
 * a generic checkbox e.g. for simply selecting items outside of any forms, look at `sis-checkbox`.
 *
 * Children with class name `sis-form-checkbox-label-content` can be projected next to the label. For example:
 * <pre>
 *     <sis-form-checkbox>
 *         <div class="sis-form-checkbox-label-content">...</div>
 *     </sis-form-checkbox>
 * </pre>
 */
@Component({
    selector: 'sis-form-checkbox',
    templateUrl: './form-checkbox.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class FormCheckboxComponent {
    @Input() id?: string;
    @Input() control: FormControl;
    @Input() label: string;
    @Input() name: string;
}
