import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

/**
 * Red notification circle component.
 * Remember to set position: relative to the notification-badge's parent element!
 */
@Component({
    selector: 'sis-notification-badge',
    templateUrl: './notification-badge.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationBadgeComponent {

    @Input() value: number;

}
