import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { ModuleContentWorkflowDecision, WorkflowState, WorkflowType } from 'common-typescript/types';

import { STUDENT_WORKFLOW_STATE } from '../../model/student-workflow-constants';

@Component({
    selector: 'sis-module-content-workflow-decision',
    templateUrl: './module-content-workflow-decision.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModuleContentWorkflowDecisionComponent {
    readonly workflowState = STUDENT_WORKFLOW_STATE;

    @Input() moduleContentWorkflowType: WorkflowType;
    @Input() moduleContentWorkflowState: WorkflowState;
    @Input() moduleContentWorkflowDecision: ModuleContentWorkflowDecision;
}
