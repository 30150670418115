import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'sis-version-switch-notification',
    templateUrl: './version-switch-notification.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class VersionSwitchNotificationComponent {

    @Input() versionSwitchAllowed = false;
    @Input() switchVersionTranslationKey: string;
    @Input() versionNotCompatibleTranslationKey: string;
    @Input() versionCompatibleTranslationKey: string;
    @Output() saveVersionChange = new EventEmitter<void>();

}
