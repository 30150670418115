import { Pipe, PipeTransform } from '@angular/core';
import { Attainment } from 'common-typescript/types';
import { Observable } from 'rxjs';

import { AppErrorHandler } from '../error-handler/app-error-handler';
import { AttainmentEntityService } from '../service/attainment-entity.service';

@Pipe({ name: 'attainmentCode' })
export class AttainmentCodePipe implements PipeTransform {

    constructor(
        private appErrorHandler: AppErrorHandler,
        private attainmentEntityService: AttainmentEntityService,
    ) {
    }

    transform(attainment: Attainment): Observable<string> {
        return this.attainmentEntityService.getAttainmentCode(attainment)
            .pipe(this.appErrorHandler.defaultErrorHandler());
    }
}
