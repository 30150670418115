"use strict";

(function () {
  angular.module("sis-components.number.intRangeFilter", []).filter('intRange', intRange);

  /**
   * @ngInject
   */
  function intRange() {
    return function (input) {
      if (input) {
        var retval = '';
        if (input.min) {
          retval += input.min;
        }
        if (input.min && input.max && input.min !== input.max) {
          retval += '–';
        }
        if (input.max && input.min !== input.max) {
          retval += input.max;
        }
        return retval;
      }
      return '';
    };
  }
})();