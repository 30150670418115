<sis-rule-module-box *ngIf="module"
                      [module]="module"
                      [planValidationResult]="planValidationResult"
                      [planStateObject]="planStateObject"
                      [planData]="planData">
    <div module-action class="additional-box toggle-box">
        <div class="checkbox checkbox-toggle sis-toggle">
            <label>
                <input type="checkbox" (click)="toggle($event)" [checked]="isSelectedModule(module.id)" [disabled]="this.planRuleSelectionService.isModuleSelectionDisabled(module, parentModule, validatablePlan)"><span></span>
            </label>
        </div>
    </div>

</sis-rule-module-box>
