import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { HttpConfig, NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { OtmId, StudentAssessmentInfo } from 'common-typescript/types';
import { Observable, switchMap, tap, throwError } from 'rxjs';
import { EntityService } from 'sis-components/service/entity.service';

const CONFIG = {
    ENDPOINTS: {
        backend: '/arto/api',
        getMyAssessmentInfoByCurId(courseUnitRealisationId: OtmId): string {
            return `${this.backend}/assessments/${courseUnitRealisationId}/mine`;
        },
    },
} as const;
@Injectable({
    providedIn: 'root',
})
@NgEntityServiceConfig({
    baseUrl: CONFIG.ENDPOINTS.backend,
})
/**
 * A service for fetching StudentAssessmentInfos, used only for student. Currently, it is only possible to fetch
 * StudentAssessmentInfos using course unit realisation ids. This service tries to fetch the data first from the
 * store and then if it is not found, it is fetched from the backend.
 *
 * Course unit realisation id is used as the idKey for the store since StudentAssessmentInfo doesn't have a primary id
 * (it's a collection created from Assessment, FinalEvaluation and PublicGradeDistribution)
 */
export class StudentAssessmentInfoService extends EntityService<StudentAssessmentInfoEntityState> {

    constructor() {
        super(StudentAssessmentInfoStore, StudentAssessmentInfoQuery);
    }

    /**
     * Currently unsupported by the backend.
     */
    override getById(id: OtmId, bypassStore = false, config: HttpConfig = {}): Observable<StudentAssessmentInfo> {
        return throwError(() => new Error('getById is not currently supported for this entity'));
    }

    /**
     * Currently unsupported by the backend.
     */
    override getByIds(ids: OtmId[], bypassStore: boolean = false, config: HttpConfig = {}): Observable<StudentAssessmentInfo[]> {
        return throwError(() => new Error('getByIds is not currently supported for this entity'));
    }

    /**
     * Fetches StudentAssessmentInfo using the course unit realisation id. Primarily fetches from the store cache, if not found
     * makes the request to backend. Batching is not supported by the backend currently so all calls to this method will result
     * in separate backend requests.
     */
    getMyAssessmentInfoByCurId(courseUnitRealisationId: OtmId, bypassStore: boolean = false): Observable<StudentAssessmentInfo> {
        if (!courseUnitRealisationId) {
            return throwError(() => new Error('Course unit realisation id is undefined'));
        }

        // Currently nothing fetches and sets all StudentAssessmentInfos at once so getHasCache will never return true.
        // However, single ids can return a match from the store with hasEntity.
        const getByCurId = (studentAssessmentInfo: StudentAssessmentInfo) =>
            studentAssessmentInfo?.assessment?.courseUnitRealisationId === courseUnitRealisationId;
        if (!bypassStore && (this.query.getHasCache() || this.query.hasEntity(getByCurId))) {
            return this.query.selectEntity(getByCurId);
        }

        return this.createGetMyAssessmentInfoByCurIdRequest(courseUnitRealisationId)
            .pipe(
                tap((studentAssessmentInfo: StudentAssessmentInfo) => this.store.upsertMany([studentAssessmentInfo])),
                switchMap(() => this.query.selectEntity(getByCurId)),
            );
    }

    private createGetMyAssessmentInfoByCurIdRequest(courseUnitRealisationId: OtmId): Observable<StudentAssessmentInfo> {
        return this.getHttp().get<StudentAssessmentInfo>(CONFIG.ENDPOINTS.getMyAssessmentInfoByCurId(courseUnitRealisationId));
    }

}

type StudentAssessmentInfoEntityState = EntityState<StudentAssessmentInfo, OtmId>;
@StoreConfig({
    name: 'student-assessment-infos',
})

class StudentAssessmentInfoStore extends EntityStore<StudentAssessmentInfoEntityState> {}

class StudentAssessmentInfoQuery extends QueryEntity<StudentAssessmentInfoEntityState> {
    constructor(protected store: StudentAssessmentInfoStore) {
        super(store);
    }
}
