import angular from 'angular';
import _ from 'lodash';
import { enrolmentCalculationConfigModelModule } from '../model/enrolmentCalculationConfig.model';
import { enrolmentCalculationResultModelModule } from '../model/enrolmentCalculationResult.model';
export const enrolmentCalculationModule = 'sis-components.service.enrolmentCalculation';
(function () {
  enrolmentCalculationService.$inject = ["$http", "$q", "enrolmentCalculationResultJSDataModel", "enrolmentCalculationConfigJSDataModel", "ENROLMENT_CALCULATION_CONFIG_URL"];
  angular.module(enrolmentCalculationModule, [enrolmentCalculationConfigModelModule, enrolmentCalculationResultModelModule]).factory('enrolmentCalculationService', enrolmentCalculationService);

  /**
   * @ngInject
   */
  function enrolmentCalculationService($http, $q, enrolmentCalculationResultJSDataModel, enrolmentCalculationConfigJSDataModel, ENROLMENT_CALCULATION_CONFIG_URL) {
    function resolveEmptyObjectIfStatusIs404(err) {
      if (_.get(err, 'status') === 404) {
        return $q.resolve();
      }
      return $q.reject(err);
    }
    return {
      getEnrolmentCalculationConfigOrEmpty(courseUnitRealisationId) {
        return enrolmentCalculationConfigJSDataModel.find(courseUnitRealisationId, {
          bypassCache: true
        }).then(x => x, resolveEmptyObjectIfStatusIs404);
      },
      createEnrolmentCalculationConfig(enrolmentCalculationConfig) {
        const options = {
          method: 'POST'
        };
        return enrolmentCalculationConfigJSDataModel.create(enrolmentCalculationConfig, options);
      },
      updateEnrolmentCalculationConfig(enrolmentCalculationConfig) {
        return enrolmentCalculationConfigJSDataModel.save(enrolmentCalculationConfig);
      },
      revertEnrolmentCalculationConfig(enrolmentCalculationConfig) {
        enrolmentCalculationConfigJSDataModel.revert(enrolmentCalculationConfig.id);
        // This function might need to load relations in the future and that is asynchronous.
        // CourseUnitRealisation revert is asynchronous so make this function analogous.
        return $q.resolve(enrolmentCalculationConfig);
      },
      getEnrolmentCalculationConfigs(courseUnitRealisationIds) {
        return $http.get(ENROLMENT_CALCULATION_CONFIG_URL.DEFAULT, {
          params: {
            courseUnitRealisationIds
          }
        });
      },
      /**
       *
       * @param courseUnitRealisationId
       * @param bypassCache
       * @Return enrolmentCalculationResult or undefined if it doesn't exists
       */
      getCalculationResultOrEmpty(courseUnitRealisationId, bypassCache = false) {
        const options = {
          bypassCache: !!bypassCache
        };
        return enrolmentCalculationResultJSDataModel.find(courseUnitRealisationId, options).then(x => x, resolveEmptyObjectIfStatusIs404);
      },
      startTentativeCalculation(courseUnitRealisationId) {
        return $http.post(`/ilmo/api/enrolment-calculation-tentative/${courseUnitRealisationId}`).then(result => result.data);
      },
      startFinalCalculation(courseUnitRealisationId) {
        return $http.post(`/ilmo/api/enrolment-calculation-final/${courseUnitRealisationId}`).then(result => result.data);
      },
      getAllocationCounts(courseUnitRealisationIds) {
        return $http.get(`/ilmo/api/enrolment-allocation-counts/${courseUnitRealisationIds}`).then(result => result.data);
      },
      getEnrolmentCalculationState(courseUnitRealisationId) {
        return $http.get(`/ilmo/api/enrolment-calculation-results/state/${courseUnitRealisationId}`).then(result => result.data, resolveEmptyObjectIfStatusIs404);
      }
    };
  }
})();