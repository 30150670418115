'use strict';

(function () {
  searchResultHelperService.$inject = ["validAttainmentFilterService", "uiContext"];
  angular.module('student.plan.rules').factory('searchResultHelperService', searchResultHelperService);

  /**
   * @ngInject
   */
  function searchResultHelperService(validAttainmentFilterService, uiContext) {
    /*
        These helper functions are meant to be used for handling search results of inline searches
        in anyCourseUnitRule and anyModuleRule components.
     */

    var api = {
      isCourseUnitSearchResultDisabled: function (courseUnitSearchResult, openModule, validatablePlan) {
        var plannedCourseUnit = validatablePlan.getCourseUnitInPlanByGroupId(courseUnitSearchResult.groupId);
        if (plannedCourseUnit) {
          if (validatablePlan.isCourseUnitInPlanAsSubstitute(plannedCourseUnit)) {
            return true;
          }
          if (validatablePlan.isCourseUnitInPlan(plannedCourseUnit)) {
            var parentModule = validatablePlan.getParentModuleOrCustomModuleAttainmentForCourseUnit(plannedCourseUnit);
            if (_.get(parentModule, 'type') === 'CustomModuleAttainment') {
              return true;
            }
            if (!(plannedCourseUnit.id === courseUnitSearchResult.id && _.get(parentModule, 'id') === openModule.id)) {
              return true;
            }
          }
        }
        var allAttainments = uiContext.planContext.getAttainmentContext().getAttainments();
        var matchingAttainment = _.find(allAttainments, {
          type: 'CourseUnitAttainment',
          courseUnitGroupId: courseUnitSearchResult.groupId
        });
        if (matchingAttainment && validAttainmentFilterService.isAttached(matchingAttainment, allAttainments)) {
          return true;
        }
        return false;
      },
      isModuleSearchResultDisabled: function (moduleSearchResult, openModule, validatablePlan) {
        var plannedModule = validatablePlan.getModuleInPlanByGroupId(moduleSearchResult.groupId);
        if (plannedModule && validatablePlan.isModuleInPlan(_.get(plannedModule, 'id'))) {
          return true;
        }
        var allAttainments = uiContext.planContext.getAttainmentContext().getAttainments();
        var matchingAttainment = _.find(allAttainments, function (attainment) {
          return _.includes(['ModuleAttainment', 'DegreeProgrammeAttainment'], _.get(attainment, 'type')) && _.get(attainment, 'moduleGroupId') === moduleSearchResult.groupId;
        });
        if (matchingAttainment && validAttainmentFilterService.isAttached(matchingAttainment, allAttainments)) {
          return true;
        }
        return false;
      }
    };
    return api;
  }
})();