import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ValidatablePlan } from 'common-typescript/src/plan/validation/validatablePlan';
import { CourseUnit, CurriculumPeriod, DocumentState, OtmId } from 'common-typescript/types';
import _ from 'lodash';
import { combineLatest, from, map, Observable, of, OperatorFunction, switchMap, take } from 'rxjs';

import { COURSE_UNIT_SERVICE, CURRICULUM_PERIOD_SERVICE } from '../ajs-upgraded-modules';
import { AppErrorHandler } from '../error-handler/app-error-handler';
import { SelectOption } from '../select/select-combobox/select-combobox.component';
import { convertAJSPromiseToNative } from '../util/utils';

export interface PreviewModeConfig {
    documentStates: DocumentState;
    url: string;
}

export interface CourseUnitDisplayNamesById {
    [id: string]: string;
}

export interface CourseUnitInfoVersion {
    courseUnit: CourseUnit;
    curriculumPeriods: CurriculumPeriod[];
}

@Injectable({
    providedIn: 'root',
})
export class CourseUnitInfoService {

    constructor(
        @Inject(COURSE_UNIT_SERVICE) private commonCourseUnitService: any,
        @Inject(CURRICULUM_PERIOD_SERVICE) private commonCurriculumPeriodService: any,
        protected appErrorHandler: AppErrorHandler,
        private translate: TranslateService,
    ) { }

    /** @param courseUnit
     * @param previewMode is passed if a course unit brochure is viewed in preview mode (path includes /student/plan/preview)
     * @returns all active versions and names for given course unit. with preview mode configuration provided, return ALL versions (including deleted and drafts)
     **/
    getSelectableVersionsAndNamesByCu(courseUnit: CourseUnit, previewMode?: PreviewModeConfig): Observable<[CourseUnitInfoVersion[], CourseUnitDisplayNamesById]> {
        const versionsPromise = previewMode ? this.getAllVersions(courseUnit.groupId, previewMode) : this.getAllActiveVersions(courseUnit.groupId);
        return from(convertAJSPromiseToNative(versionsPromise)).pipe(
            take(1),
            this.appErrorHandler.defaultErrorHandler(),
            this.getVersions(),
            this.getCourseUnitDisplayNamesById(),
        );
    }

    getCourseUnitDisplayNamesById(): OperatorFunction<CourseUnitInfoVersion[], [CourseUnitInfoVersion[], CourseUnitDisplayNamesById]> {
        return switchMap((courseUnitVersions: CourseUnitInfoVersion[]) => combineLatest([
            of(courseUnitVersions),
            this.getDisplayNames(courseUnitVersions),
        ]));
    }

    getVersions(): OperatorFunction<CourseUnit[], CourseUnitInfoVersion[]> {
        return switchMap((courseUnits) => from(convertAJSPromiseToNative(this.commonCurriculumPeriodService.findAll())).pipe(
            take(1),
            map((curriculumPeriods: CurriculumPeriod[]) =>
                this.createCourseUnitVersions(curriculumPeriods, courseUnits),
            ),
        ));
    }

    getDisplayNames(courseUnitVersions: CourseUnitInfoVersion[]): Observable<CourseUnitDisplayNamesById> {
        const displayNameObservables = courseUnitVersions.map(cuv => this.getDisplayName(cuv));
        return combineLatest([...displayNameObservables]).pipe(
            switchMap((courseUnitDisplayNames) => of(this.createNamesById(courseUnitDisplayNames))),
        );
    }

    getDisplayName(version: CourseUnitInfoVersion): Observable<CourseUnitDisplayNamesById> {
        return from(convertAJSPromiseToNative(this.commonCourseUnitService.getDisplayName(version.curriculumPeriods))).pipe(
            take(1),
            map((name: string) => ({ id: version.courseUnit.id, name })),
        );
    }

    /**
     * @param courseUnitDisplayNames a list of objects [{ id: "cu-1", name: "Pottery"},  {id: "cu-2", name: "Carpentry" }]
     * @returns an object with course unit id as key and display name as value { "cu-1": "Pottery", "cu-2": "Carpentry" }
     */
    createNamesById(courseUnitDisplayNames: CourseUnitDisplayNamesById[]): CourseUnitDisplayNamesById {
        const displayNamesById: CourseUnitDisplayNamesById = {};
        courseUnitDisplayNames.forEach((displayName) => {
            displayNamesById[displayName.id] = displayName.name;
        });
        return displayNamesById;
    }

    createCourseUnitVersions(curriculumPeriods: CurriculumPeriod[], courseUnits: CourseUnit[]): CourseUnitInfoVersion[] {
        const curriculumPeriodsById = _.keyBy(curriculumPeriods, 'id');
        return courseUnits.map(cu => ({
            courseUnit: cu,
            curriculumPeriods: _.chain(cu.curriculumPeriodIds)
                .map(cpId => _.get(curriculumPeriodsById, cpId))
                .sortBy('activePeriod.startDate')
                .value(),
        }));
    }

    createSelectOptions(courseUnitVersions: CourseUnitInfoVersion[], namesById: CourseUnitDisplayNamesById): SelectOption[] {
        return _(courseUnitVersions)
            .sortBy('curriculumPeriods[0].activePeriod.startDate')
            .filter('courseUnit')
            .map(({ courseUnit }) => ({
                value: courseUnit.id,
                label: _.get(namesById, courseUnit.id),
            }))
            .value();
    }

    createSelectOptionsForModal(courseUnitVersions: CourseUnitInfoVersion[], namesById: CourseUnitDisplayNamesById, validatablePlan: ValidatablePlan): SelectOption[] {
        return _(courseUnitVersions)
            .sortBy('curriculumPeriods[0].activePeriod.startDate')
            .filter('courseUnit')
            .map(({ courseUnit }) => ({
                value: courseUnit.id,
                label: _.get(namesById, courseUnit.id),
                info: this.resolveModalInfoLabel(courseUnit, validatablePlan),
            }))
            .value();
    }

    resolveModalInfoLabel(courseUnit: CourseUnit, validatablePlan: ValidatablePlan): string {
        if (validatablePlan?.isCourseUnitAttained(courseUnit.id)) {
            return this.translate.instant('STUDIES.COURSE_UNIT_INFO_MODAL.VERSION_ATTAINED');
        }
        if (validatablePlan?.isCourseUnitInPlan(courseUnit)) {
            return this.translate.instant('STUDIES.COURSE_UNIT_INFO_MODAL.PLACED_IN_PLAN');
        }
    }

    getAllActiveVersions(cuGroupId: OtmId) {
        return this.commonCourseUnitService.findAllActiveVersions(cuGroupId, true, false);
    }

    getAllVersions(cuGroupId: OtmId, previewMode: PreviewModeConfig) {
        return this.commonCourseUnitService.findAllVersions(cuGroupId, false, previewMode.documentStates, previewMode.url);
    }
}
