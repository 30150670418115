import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewEncapsulation,
} from '@angular/core';
import { Tab } from 'sis-components/tabs/tab-content-switch/tab-content-switch.component';

export interface ModalTab extends Tab {
    name: string;
}
@Component({
    selector: 'app-course-unit-info-modal-header-tabs',
    templateUrl: './course-unit-info-modal-header-tabs.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourseUnitInfoModalHeaderTabsComponent implements OnChanges {
    @Input() openTab: 'BASIC' | 'COMPLETION_METHODS' | 'SUBSTITUTIONS' | 'OPEN_UNIVERSITY_OFFERING';
    @Input() tabs: ModalTab[];
    @Output() selectTab = new EventEmitter<string>();

    currentTabIndex: number;

    ngOnChanges(changes: SimpleChanges): void {
        this.currentTabIndex = this.tabs.findIndex(t => t.name === this.openTab);
    }

    onTabClick(tabIndex: number) {
        const tabName = this.tabs[tabIndex].name;
        this.selectTab.emit(tabName);
    }
}
