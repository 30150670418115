import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-course-unit-info-learning-materials',
    templateUrl: './course-unit-info-learning-materials.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CourseUnitInfoLearningMaterialsComponent {

    @Input() learningMaterial: any;
    @Input() literature: any;

}
