import angular from 'angular';
import _ from 'lodash';
export const courseUnitRealisationNameFilterModule = 'student.common.filters.courseUnitRealisationName';
(function () {
  angular.module(courseUnitRealisationNameFilterModule, ['sis-common.l10n.localeService', 'sis-common.l10n.localizedStringFilter']).filter('courseUnitRealisationName', ["$filter", function ($filter) {
    return function (cur, useFullName, preferredLanguage) {
      if (!cur) {
        return undefined;
      }
      var nameKey = useFullName ? 'fullName' : 'name';
      var name;
      if (preferredLanguage) {
        name = $filter('localizedString')(_.result(cur, nameKey, cur.name), preferredLanguage);
      } else {
        name = $filter('localizedString')(_.result(cur, nameKey, cur.name));
      }
      return name;
    };
  }]);
})();