import { Injectable } from '@angular/core';
import {
    Education,
    LearningOpportunitySelectionPath,
    OtmId,
    PersonalizedPhase,
    PersonalizedSelectionPath,
    StudyRight,
} from 'common-typescript/types';
import _ from 'lodash';

@Injectable({ providedIn: 'root' })
export class StudyRightSelectionPathService {
    /**
     * Returns allowed paths of the learning opportunity defined by {@link education} and {@link studyRight},
     * overridden by personalized paths of the study right,
     * or an empty array if there's no learning opportunity having allowed paths.
     */
    getStudyRightSelectionPaths(education: Education, studyRight: StudyRight): LearningOpportunitySelectionPath[] {
        const allowedPaths: LearningOpportunitySelectionPath[] | undefined = education
            .structure
            ?.learningOpportunities
            ?.find(learningOpportunity => learningOpportunity.localId === studyRight.learningOpportunityId)
            ?.allowedPaths;
        if (!allowedPaths?.length) {
            return [];
        }

        return studyRight.personalizedSelectionPath
            ? createPersonalizedAllowedPaths(allowedPaths, studyRight.personalizedSelectionPath)
            // avoid effects of possible later tampering by copying the array and its items
            : _.cloneDeep(allowedPaths);
    }
}

function createPersonalizedAllowedPaths(allowedPaths: readonly LearningOpportunitySelectionPath[], personalizedSelectionPath: PersonalizedSelectionPath): LearningOpportunitySelectionPath[] {
    // personalization can produce duplicates --> remove them
    return _.uniqWith(allowedPaths.map(allowedPath => createPersonalizedAllowedPath(allowedPath, personalizedSelectionPath)), _.isEqual);
}

function createPersonalizedAllowedPath(allowedPath: LearningOpportunitySelectionPath, personalizedSelectionPath: PersonalizedSelectionPath): LearningOpportunitySelectionPath {
    const result: LearningOpportunitySelectionPath = <LearningOpportunitySelectionPath>{};
    createPersonalizedPhaseAllowedPath(result, personalizedSelectionPath.phase1, allowedPath, 'educationPhase1GroupId', 'educationPhase1ChildGroupId');
    createPersonalizedPhaseAllowedPath(result, personalizedSelectionPath.phase2, allowedPath, 'educationPhase2GroupId', 'educationPhase2ChildGroupId');
    return result;
}

function createPersonalizedPhaseAllowedPath(
    target: LearningOpportunitySelectionPath,
    personalizedPhase: PersonalizedPhase | null,
    allowedPath: LearningOpportunitySelectionPath,
    groupIdProperty: keyof LearningOpportunitySelectionPath,
    childGroupIdProperty: keyof LearningOpportunitySelectionPath,
): void {
    let groupId: OtmId | null | undefined;
    let childGroupId: OtmId | null | undefined;

    if (personalizedPhase) {
        // If the module group is not defined, fall back to the original module group...
        groupId = personalizedPhase.moduleGroupId ?? allowedPath[groupIdProperty];
        // ...BUT if the module group is defined, don't fall back to the original child module group.
        childGroupId = personalizedPhase.moduleGroupId
            ? personalizedPhase.childModuleGroupId
            : (personalizedPhase.childModuleGroupId ?? allowedPath[childGroupIdProperty]);
    } else {
        groupId = allowedPath[groupIdProperty];
        childGroupId = allowedPath[childGroupIdProperty];
    }

    // Null properties of backend-side LearningOpportunitySelectionPath are not serialized, so they never arrive to frontend.
    // So let's not explicitly set null nor undefined property values here either.
    if (groupId) {
        target[groupIdProperty] = groupId;
    }
    if (childGroupId) {
        target[childGroupIdProperty] = childGroupId;
    }
}
