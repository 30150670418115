<ng-container *transloco="let t">
  <div *ngIf="isCreatingWorkflowPossible">
    <div>
      <h4 class="apply-for-mca-header">{{t('CREATE_CUSTOM_MODULE_CONTENT_WORKFLOW.HEADER')}}</h4>
      <p>{{t('CREATE_CUSTOM_MODULE_CONTENT_WORKFLOW.DESCRIPTION')}}</p>
    </div>
    <div class="float-end mca-button">
      <sis-button
        [size]="'sm'"
        (clicked)="openModal()">{{t('CREATE_CUSTOM_MODULE_CONTENT_WORKFLOW.OPEN_MODAL_BUTTON')}}
      </sis-button>
    </div>
    <div class="clearfix"></div>
  </div>
</ng-container>

