import { ChangeDetectorRef, Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { merge, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SisFormControl } from '../sis-form-control';

/**
 * This (abstract!) component supports the common practice of validating controls by marking them touched (often when submitting the form),
 * even if the control has been passed to a component which uses OnPush change detection strategy.
 *
 * In the OnPush strategy, Angular checks for changes only when the reference of an input property to a component changes.
 * If the reference does not change, Angular assumes that the input has not changed, and therefore does not trigger change detection.
 * Normally, just marking a form control as touched will in other words not run a change detection cycle for the component.
 *
 * The observables provided by {@link SisFormControl} allows us to trigger change detection when there are changes to the value, status, touched, untouched, dirty or pristine fields.
 * */
@Component({ template: '' })
export abstract class FormControlChangeDetectorComponent<T> implements OnInit, OnDestroy {
    private changeDetectorRef = inject(ChangeDetectorRef);

    @Input() control: SisFormControl<T>;
    destroyed$ = new Subject<void>();

    ngOnInit(): void {
        if (this.control) {
            const stateChangeObservables = [
                this.control?.valueChanges,
                this.control?.statusChanges,
                this.control?.touchedChanges,
                this.control?.pristineChanges,
            ].filter(stateChangeObservable => stateChangeObservable instanceof Observable);

            merge(...stateChangeObservables).pipe(
                takeUntil(this.destroyed$),
            ).subscribe(() => {
                this.changeDetectorRef.markForCheck();
            });
        }
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
    }
}
