import angular from 'angular';
import moment from 'moment';
export const previousDayFilterModule = 'sis-components.date.previousDayFilter';
(function () {
  angular.module('sis-components.date.previousDayFilter', []).filter('previousDay', previousDay);

  /**
   * @ngInject
   */
  function previousDay() {
    return function (input) {
      if (input) {
        return moment(input, 'YYYY-MM-DD').subtract(1, 'days').format('YYYY-MM-DD');
      }
      return null;
    };
  }
})();