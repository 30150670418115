import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { EnrolmentQuestionnaireAnswers, OtmId } from 'common-typescript/types';
import { Observable } from 'rxjs';

import { EntityService } from '../service/entity.service';

const CONFIG = {
    ENDPOINTS: {
        baseUrl: '/ilmo/api',
        answersByCourseUnitRealisationId(courseUnitRealisationId: string) {
            return `${this.baseUrl}/enrolment-questionnaire-answers-by-course-unit-realisation-id/${courseUnitRealisationId}`;
        },
        answersByEnrolmentId(enrolmentId: OtmId) {
            return `${this.baseUrl}/enrolment-questionnaire-answers/${enrolmentId}`;
        },
    },
};

@Injectable({
    providedIn: 'root',
})
@NgEntityServiceConfig({
    baseUrl: CONFIG.ENDPOINTS.baseUrl,
    resourceName: 'enrolment-questionnaire-answers',
})
export class EnrolmentQuestionnaireAnswersEntityService extends EntityService<EnrolmentQuestionnaireAnswersState> {
    constructor() {
        super(EnrolmentQuestionnaireAnswersStore, EnrolmentQuestionnaireAnswersQuery);
    }

    getByCourseUnitRealisationId(courseUnitRealisationId: string): Observable<EnrolmentQuestionnaireAnswers[]> {
        return this.getHttp().get<EnrolmentQuestionnaireAnswers[]>(CONFIG.ENDPOINTS.answersByCourseUnitRealisationId(courseUnitRealisationId));
    }

    getByEnrolmentId(enrolmentId: string): Observable<EnrolmentQuestionnaireAnswers> {
        return this.getHttp().get<EnrolmentQuestionnaireAnswers>(CONFIG.ENDPOINTS.answersByEnrolmentId(enrolmentId))
            .pipe(this.upsertAndSwitchToStoreObservable());
    }
}

type EnrolmentQuestionnaireAnswersState = EntityState<EnrolmentQuestionnaireAnswers, OtmId>;

@StoreConfig({ name: 'enrolment-questionnaire-answers', idKey: 'enrolmentId' })
class EnrolmentQuestionnaireAnswersStore extends EntityStore<EnrolmentQuestionnaireAnswersState> {}

class EnrolmentQuestionnaireAnswersQuery extends QueryEntity<EnrolmentQuestionnaireAnswersState> {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(store: EntityStore<EnrolmentQuestionnaireAnswersState>) {
        super(store);
    }
}
