import { FeatureToggleComponent } from 'sis-components/feature-toggle/feature-toggle.component';
import { redirectIfFeatureTogglesAreNotEnabled } from 'sis-components/router/feature-toggle-route.utils';
import { SisStateDeclaration } from 'sis-components/router/types';

export const featureToggleStates: SisStateDeclaration[] = [
    {
        name: 'student.featuretoggle',
        url: '/feature-toggle',
        component: FeatureToggleComponent,
        redirectTo: redirectIfFeatureTogglesAreNotEnabled('student.logged-in.frontpage'),
    },
];
