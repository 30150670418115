import { Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { StateService } from '@uirouter/core';
import { OpenUniversityCart, PaymentId } from 'common-typescript/types';
import { catchError } from 'rxjs';
import { take } from 'rxjs/operators';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { OpenUniversityCartCustomerService } from 'sis-components/service/open-university-cart-customer.service';

import { CART_ERROR_MSG_TYPE } from '../cart-error/cart-error.component';
import { CART_PURCHASE_WIZARD_STEPS, STEP } from '../constants';

@Component({
    selector: 'app-cart-confirmation',
    templateUrl: './cart-confirmation.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CartConfirmationComponent implements OnInit {

    constructor(
        private openUniversityCartCustomerService: OpenUniversityCartCustomerService,
        private stateService: StateService,
        private appErrorHandler: AppErrorHandler,
    ) {}

    @ViewChild('currentStepNameFocus', {}) currentStepNameFocus: ElementRef;

    @Input() paymentId: PaymentId;

    readonly wizardStepNames = CART_PURCHASE_WIZARD_STEPS.map(step => step.wizardProgressStep);
    readonly currentStep = STEP.CONFIRMATION;

    cart: OpenUniversityCart;

    ngOnInit(): void {
        if (this.paymentId?.length > 0) {
            this.openUniversityCartCustomerService.getCartByPaymentId(this.paymentId)
                .pipe(
                    take(1),
                    catchError((err) => {
                        // This can happen when anonymous user stays in payment view too long and session will timeout.
                        if (err.status === 403 || err.status === 401) {
                            this.sessionTimeout();
                        }
                        throw err;
                    }),
                    this.appErrorHandler.defaultErrorHandler(),
                )
                .subscribe({
                    next: (cart) => {
                        this.cart = cart;
                        setTimeout(() => this.currentStepNameFocus?.nativeElement?.focus?.());
                    },
                });
        }
    }

    redirectToStudiesActivateWizard(): void {
        this.stateService.go(
            'student.open-university-studies-activate',
            { openUniversityCartId: this.cart.id, activationCode: this.cart.activationCode },
        );
    }

    private sessionTimeout() {
        this.stateService.go('student.open-university-cart.error', { errorType: CART_ERROR_MSG_TYPE.SESSION_TIMEOUT_WHILE_IN_PAYMENT_SYSTEM });
    }

}
