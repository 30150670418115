import { Injectable } from '@angular/core';
import { TranslationMarkupRendererFactory } from 'ngx-transloco-markup';

import { HtmlElementTranspiler } from '../common/html-element-transpiler';

export const LI_START = new (class LiStart {})();
export const LI_END = new (class LiEnd {})();

@Injectable()
export class LiTranspiler extends HtmlElementTranspiler {
    constructor(private readonly rendererFactory: TranslationMarkupRendererFactory) {
        super({
            rendererFactory,
            start: {
                search: '<li>',
                token: LI_START,
            },
            end: {
                search: '</li>',
                token: LI_END,
            },
            elementTag: 'li',
        });
    }
}
