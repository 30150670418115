export enum STUDENT_WORKFLOW_STATE {
    REQUESTED = 'REQUESTED',
    REJECTED = 'REJECTED',
    ACCEPTED = 'ACCEPTED',
    IN_HANDLING = 'IN_HANDLING',
    CANCELLED = 'CANCELLED',
    CONDITIONAL = 'CONDITIONAL',
    REVOKED = 'REVOKED',
    ACCEPTANCE_REVOKED = 'ACCEPTANCE_REVOKED',
    SUPPLEMENT_REQUESTED = 'SUPPLEMENT_REQUESTED',
}

export enum STUDENT_WORKFLOW_TYPE {
    MODULE_ATTAINMENT_WORKFLOW = 'ModuleAttainmentWorkflow',
    DEGREE_PROGRAMME_ATTAINMENT_WORKFLOW = 'DegreeProgrammeAttainmentWorkflow',
    PRIOR_LEARNING_INCLUSION_WORKFLOW = 'PriorLearningInclusionWorkflow',
    PRIOR_LEARNING_SUBSTITUTION_WORKFLOW = 'PriorLearningSubstitutionWorkflow',
    CUSTOM_ATTAINMENT_WORKFLOW = 'CustomAttainmentWorkflow',
    CUSTOM_MODULE_CONTENT_WORKFLOW = 'CustomModuleContentWorkflow',
    REQUIRED_MODULE_CONTENT_WORKFLOW = 'RequiredModuleContentWorkflow',
    STUDY_RIGHT_EXTENSION_WORKFLOW = 'StudyRightExtensionWorkflow',
}

export enum STUDENT_WORKFLOW_APPLICATION_TYPE {
    PRIOR_LEARNING_SUBSTITUTION_APPLICATION = 'PriorLearningSubstitutionApplication',
    PRIOR_LEARNING_INCLUSION_APPLICATION = 'PriorLearningInclusionApplication',
    MODULE_ATTAINMENT_APPLICATION = 'ModuleAttainmentApplication',
    CUSTOM_ATTAINMENT_APPLICATION = 'CustomAttainmentApplication',
    DEGREE_PROGRAMME_ATTAINMENT_APPLICATION = 'DegreeProgrammeAttainmentApplication',
    CUSTOM_MODULE_CONTENT_APPLICATION = 'CustomModuleContentApplication',
    REQUIRED_MODULE_CONTENT_APPLICATION = 'RequiredModuleContentApplication',
    STUDY_RIGHT_EXTENSION_APPLICATION = 'StudyRightExtensionApplication',
}

export enum PRIOR_LEARNING_TYPE {
    STUDIES = 'STUDIES',
    COMPETENCE = 'COMPETENCE',
}

export enum RECOMMENDATION_STATE {
    NOT_HANDLED = 'NOT_HANDLED',
    RECOMMENDED = 'RECOMMENDED',
    NOT_RECOMMENDED = 'NOT_RECOMMENDED',
}

export enum EFFECTIVE_MODULE_CONTENT_APPLICATION_STATES {
    REQUESTED = 'REQUESTED',
    IN_HANDLING = 'IN_HANDLING',
    ACCEPTED = 'ACCEPTED',
    CONDITIONAL = 'CONDITIONAL',
}
