import { Inject, Pipe, PipeTransform } from '@angular/core';
import { LocalizedString } from 'common-typescript/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { LocaleService } from './locale.service';

@Pipe({ name: 'localeNames' })
export class LocaleNamesPipe implements PipeTransform {

    constructor(private localeService: LocaleService) { }

    transform(languages: string[]): Observable<LocalizedString[]> {
        return this.localeService.getLocalizedLanguageNames(languages).pipe(map(names => Object.values(names)));
    }
}
