import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { LearningEnvironmentUrl } from 'common-typescript/types';

@Component({
    selector: 'sis-learning-environment',
    templateUrl: './learning-environment.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class LearningEnvironmentComponent implements OnChanges {

    hasValue: boolean;
    linkName: string;

    @Input() learningEnvironment: LearningEnvironmentUrl;

    ngOnChanges() {
        this.linkName = this.learningEnvironment.name ? this.learningEnvironment.name : this.learningEnvironment.url;
    }
}
