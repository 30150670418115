<sis-expandable *ngIf="enrolmentRight?.courseUnitId | courseUnit | async as courseUnit"
                [variant]="'naked-dotted'"
                [level]="6"
                [title]="courseUnit.code + ' ' + (courseUnit.name | localizedString)"
                [attr.data-cy]="'open-university-enrolment-right-title'">
  <ng-template sisExpandableCustomTitle>
    <sis-enrolment-right-badges [enrolmentRight]="enrolmentRight"/>
  </ng-template>
  <ng-template sisExpandableHeaderButtons>
    <sis-menu-button *ngIf="componentType === 'STAFF' && enrolmentRight?.type !== 'SUBSTITUTION'"
                     [options]="menuOptions"
                     [attr.data-cy]="'open-university-enrolment-right-menu-button'"
                     (click)="$event.preventDefault(); $event.stopPropagation();" />
  </ng-template>
  <ng-template sisExpandableContent>
    <sis-enrolment-right-expandable-body [enrolmentRight]="enrolmentRight" [componentType]="componentType"/>
  </ng-template>
</sis-expandable>
