import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ApplicationDecision, ModuleAttainmentApplication } from 'common-typescript/types';
import { NEVER, Observable } from 'rxjs';

import { ApplicationDecisionEntityService } from '../../../service/application-decision-entity.service';

@Component({
    selector: 'sis-maa-decision-expandable',
    templateUrl: './maa-decision-expandable.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class MaaDecisionExpandableComponent implements OnInit {
    @Input() application: ModuleAttainmentApplication;
    decision$: Observable<ApplicationDecision> = NEVER;

    constructor(
        private applicationDecisionEntityService: ApplicationDecisionEntityService,
    ) { }

    ngOnInit(): void {
        if (this.application.state === 'REJECTED') {
            this.decision$ = this.applicationDecisionEntityService.getById(this.application.id);
        }
    }
}
