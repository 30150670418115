<ng-container *transloco="let t">
  <ng-container *ngIf="questionnaire$ | async as questionnaire">
    <ng-container *ngIf="answers$ | async as answers">
      <sis-description-list [columnCount]="1">
        <ng-container *ngFor="let question of questionnaire.enrolmentQuestions; trackBy:entityId">
          <ng-container *ngIf="question.questionType === 'LANGUAGE_SELECT'">
            <dl>
              <ng-template #listItem>
                <dt>{{ t('ENROLMENT_QUESTIONAIRE_LANG_ANSWER_TITLE') }}</dt>
                <dd>{{ (getAnswerByQuestionId(answers, question.localId)?.selections[0] | codeLocalizedName | async) || '-' }}</dd>
              </ng-template>
            </dl>
          </ng-container>
          <ng-container *ngIf="question.questionType === 'FREE_TEXT'">
            <dl>
              <ng-template #listItem>
                <dt>{{ question.question | localizedString }}</dt>
                <dd>{{ getAnswerByQuestionId(answers, question.localId)?.answerText || '-' }}</dd>
              </ng-template>
            </dl>
          </ng-container>
        </ng-container>
      </sis-description-list>
    </ng-container>
  </ng-container>
</ng-container>
