import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import {
    CourseUnitSubstitution,
    OtmId,
} from 'common-typescript/types';

@Component({
    selector: 'sis-course-unit-substitution',
    templateUrl: './course-unit-substitution.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class CourseUnitSubstitutionComponent {

    @Input() substitution: CourseUnitSubstitution[];
    @Input() colorIndex: number;
    @Input() language: string;
    // Course unit box will not be clickable if not observing this output
    @Output() openCourseUnitModalEvent = new EventEmitter<OtmId>();
}
