import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { OtmId, PaymentSystemPayment } from 'common-typescript/types';
import { Observable, of } from 'rxjs';

import { EntityService } from '../service/entity.service';

const CONFIG = {
    ENDPOINTS: {
        backend: '/ilmo/api',
        byOpenUniversityCartId(cartId: OtmId) {
            return `${this.backend}/payment-system-payments/${cartId}`;
        },
        byOpenUniversityCartIdForStudent(cartId: OtmId) {
            return `${this.backend}/open-university/payment-system-payments/${cartId}`;
        },
    },
};

@Injectable({ providedIn: 'root' })
@NgEntityServiceConfig({
    baseUrl: CONFIG.ENDPOINTS.backend,
    resourceName: 'course-units',
})
export class PaymentSystemPaymentService extends EntityService<PaymentSystemPaymentState> {

    constructor(
    ) {
        super(PaymentSystemPaymentStore, PaymentSystemPaymentStoreQuery);
    }

    getByCartId(openUniversityCartId: OtmId): Observable<PaymentSystemPayment[]> {
        return this.getHttp().get<PaymentSystemPayment[]>(CONFIG.ENDPOINTS.byOpenUniversityCartId(openUniversityCartId));
    }

    getByCartIdForStudent(openUniversityCartId: OtmId): Observable<PaymentSystemPayment[]> {
        if (!openUniversityCartId) {
            return of(null);
        }
        return this.getHttp().get<PaymentSystemPayment[]>(CONFIG.ENDPOINTS.byOpenUniversityCartIdForStudent(openUniversityCartId));
    }

}

type PaymentSystemPaymentState = EntityState<PaymentSystemPayment, OtmId>;

@StoreConfig({ name: 'payment-system-payments' })
class PaymentSystemPaymentStore extends EntityStore<PaymentSystemPaymentState> {}

class PaymentSystemPaymentStoreQuery extends QueryEntity<PaymentSystemPaymentState> {
    constructor(protected store: PaymentSystemPaymentStore) {
        super(store);
    }
}
