import _ from 'lodash';
import angular from 'angular';
import { TabContentSwitchComponent } from 'sis-components/tabs/tab-content-switch/tab-content-switch.component.ts';
import { SelectEducationModalComponent } from './selectEducationModal.component.ts';
import selectEducationModalTpl from './selectEducationModal.tpl.html';
export const selectEducationModalModule = 'student.plan.selectEducation';
(function () {
  selectEducationModal.$inject = ["modalService"];
  SelectEducationController.$inject = ["$log", "$q", "$scope", "uiContext", "commonStudyRightService", "educationService", "curriculumPeriodService", "createPlanService", "$translate", "defaultPromiseHandler"];
  angular.module(selectEducationModalModule, ['student.plan', TabContentSwitchComponent.downgrade.moduleName]).controller('SelectEducationController', SelectEducationController).factory('selectEducationModal', selectEducationModal).component('selectEducationModal', {
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&'
    },
    template: selectEducationModalTpl,
    controller: SelectEducationController,
    controllerAs: 'ctrl'
  });
  function selectEducationModal(modalService) {
    return {
      open: studyRightId => modalService.open(SelectEducationModalComponent, {
        studyRightId
      }, {
        keyboard: false,
        size: 'sm'
      }).result
    };
  }
  function SelectEducationController($log, $q, $scope, uiContext, commonStudyRightService,
  // NOSONAR
  educationService, curriculumPeriodService, createPlanService, $translate, defaultPromiseHandler) {
    const ctrl = this;
    ctrl.scope = $scope;
    ctrl.selectedEducation = null;
    ctrl.selectableCurriculumPeriods = [];
    ctrl.selectedCurriculumPeriod = undefined;
    ctrl.status = {
      saving: false
    };
    ctrl.myEducations = undefined;
    ctrl.selectedTab = 0;
    ctrl.studyRightId = undefined;
    let ongoingStudyRights;
    ctrl.$onInit = function () {
      ctrl.tabs = [{
        title: 'STUDY_PLAN_SELECT_MY_EDUCATIONS'
      }, {
        title: 'STUDY_PLAN_SELECT_ALL_EDUCATIONS'
      }];
      ctrl.studyRightId = _.get(ctrl.resolve, 'studyRightId');
      educationService.getMyStudyRightEducations(true).then(educations => {
        ctrl.myEducations = _.filter(educations, education => !(education.documentState === 'DELETED' || education.documentState === 'DRAFT'));
      }).then(() => commonStudyRightService.studyRights(false, false).then(studyRights => {
        ongoingStudyRights = _.filter(studyRights, studyRight => studyRight.state === 'ACTIVE' || studyRight.state === 'ACTIVE_NONATTENDING' || studyRight.state === 'NOT_STARTED' || studyRight.state === 'PASSIVE');
      }).then(() => {
        if (ctrl.myEducations.length === 0) {
          ctrl.openTab(1);
        } else {
          ctrl.openTab(0);
        }
      })).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    };
    ctrl.openTab = function (tabIndex) {
      ctrl.selectedEducation = null;
      ctrl.selectedLearningOpportunityId = null;
      ctrl.selectedTab = tabIndex;
      if (ctrl.studyRightId) {
        commonStudyRightService.studyRights().then(myStudyRights => {
          const {
            educationId
          } = _.find(myStudyRights, {
            id: ctrl.studyRightId
          });
          const predeterminedMyEducation = _.find(ctrl.myEducations, {
            id: educationId
          });
          if (predeterminedMyEducation) {
            ctrl.selectEducation(predeterminedMyEducation);
          }
        });
      } else if (tabIndex === 0 && ctrl.myEducations.length === 1) {
        ctrl.selectEducation(ctrl.myEducations[0]);
      }
    };
    ctrl.selectEducation = function (education) {
      ctrl.selectedEducation = education;
      ctrl.name = `${$translate.instant('STUDY_PLAN_DEFAULT_NAME')} ${moment().format('DD.MM.YYYY')}`;
      const matchingStudyRight = createPlanService.getMatchingStudyRight(education, ongoingStudyRights);
      let selectableCurriculumPeriods;
      if (ctrl.selectedTab === 0) {
        ctrl.selectedLearningOpportunityId = _.get(matchingStudyRight, 'learningOpportunityId');
      } else if (ctrl.selectedTab === 1) {
        const learningOpportunities = _.get(education, 'structure.learningOpportunities', []);
        ctrl.selectedLearningOpportunityId = learningOpportunities.length === 1 ? learningOpportunities[0].localId : null;
      }
      curriculumPeriodService.findAll().then(curriculumPeriods => {
        selectableCurriculumPeriods = createPlanService.getSelectableCurriculumPeriods(matchingStudyRight, curriculumPeriods);
        ctrl.selectableCurriculumPeriods = selectableCurriculumPeriods;
        ctrl.selectedCurriculumPeriod = createPlanService.getDefaultCurriculumPeriod(matchingStudyRight, selectableCurriculumPeriods);
      });
    };
    ctrl.createPlan = function () {
      ctrl.status.saving = true;
      return uiContext.newPlan(ctrl.selectedEducation, ctrl.name, ctrl.selectedCurriculumPeriod.id, ctrl.selectedLearningOpportunityId).then(() => {
        if (uiContext.planContext.primary) {
          return commonStudyRightService.hasStudyRights(uiContext.planContext.root.id).then(hasStudyRight => {
            if (hasStudyRight) {
              uiContext.planContext.forbidDeletion();
            }
          }, error => {
            $log.error('Failed to check hasStudyRights', error);
          });
        }
        return $q.when();
      }).then(() => ctrl.close({
        $value: {
          planId: uiContext.planContext.id
        }
      }), error => {
        $log.warn('error in uiContext.newPlan', error);
        ctrl.status.saving = false;
      });
    };
    ctrl.isCreateDisabled = function () {
      const formIsInvalid = $scope.newPlanForm && $scope.newPlanForm.$invalid;
      return !ctrl.selectedEducation || formIsInvalid || ctrl.status.saving;
    };
  }
})();