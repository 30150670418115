import { Component, Input, OnChanges } from '@angular/core';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

export interface Substring {
    start: number;
    end: number;
    content?: string;
    mark?: boolean;
}
@StaticMembers<DowngradedComponent>()
/**
 * an alternative to {@link SearchBoldFilterPipe}
 * whereas the pipe returns markup string, this component creates a template with HTML.
 * the mark element is applied on matching substrings, case-insensitive.
 *
 * with example inputs:
 * target='It is a dark time for the Rebellion'
 * query='is dark bellion'
 * ->
 * It <mark>is</mark> a <mark>dark</mark> time for the Re<mark>bellion</mark>
 * */
@Component({
    selector: 'sis-mark-string',
    templateUrl: './mark-string.component.html',
})
export class MarkStringComponent implements OnChanges {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sisComponents.sisMarkString',
        directiveName: 'sisMarkString',
    };

    @Input() target: string;
    @Input() query: string;
    substrings: Substring[];
    targetStringWithoutMatches: string;

    ngOnChanges() {
        this.generateSubstrings(this.target, this.query);
    }

    generateSubstrings(target: string, query: string): void {
        if (!target || !query) {
            this.targetStringWithoutMatches = target;
            this.substrings = null;
            return;
        }

        const upperCaseTarget = target.toUpperCase();
        const upperCaseQuery = query.toUpperCase();

        const intervalsOfMatchingSubstrings = this.findIntervalsOfMatchingSubstrings(upperCaseTarget, upperCaseQuery);

        if (intervalsOfMatchingSubstrings.length === 0) {
            this.targetStringWithoutMatches = target;
            this.substrings = null;
            return;
        }

        this.targetStringWithoutMatches = null;
        const sortedMatchedIntervals = intervalsOfMatchingSubstrings.sort((a, b) => a.start - b.start);
        const mergedMatchedIntervals = this.mergeOverlappingMatches(sortedMatchedIntervals);
        const allIntervals = this.fillMissingIntervals(mergedMatchedIntervals, target.length);
        this.substrings = this.mapIndicesToSubstrings(allIntervals, target);
    }

    findIntervalsOfMatchingSubstrings(target: string, query: string): Substring[] {
        const intervals: Substring[] = [];
        const queryStrings: string[] = query.split(' ').filter(s => s.length > 0);

        queryStrings.forEach(s => {
            let currentIndex = target.indexOf(s);
            while (currentIndex !== -1) {
                intervals.push({ start: currentIndex, end: currentIndex + s.length - 1 });
                currentIndex = target.indexOf(s, currentIndex + 1);
            }
        });

        return intervals;
    }

    mergeOverlappingMatches(substrings: Substring[]): Substring[] {
        const mergedSubstrings: Substring[] = [];
        let currentSubstring = substrings[0];

        for (let i = 1; i < substrings.length; i += 1) {
            const nextSubstring = substrings[i];
            const substringsOverlap = currentSubstring.end >= nextSubstring.start;

            if (substringsOverlap) {
                currentSubstring.end = Math.max(currentSubstring.end, nextSubstring.end);
            } else {
                mergedSubstrings.push(currentSubstring);
                currentSubstring = nextSubstring;
            }
        }

        mergedSubstrings.push(currentSubstring);
        return mergedSubstrings;
    }

    fillMissingIntervals(substrings: Substring[], lengthOfTargetString: number): Substring[] {
        const result: Substring[] = [];

        const addInterval = (start: number, end: number, mark = false) => {
            result.push({ start, end, mark });
        };

        const addMarkedInterval = (start: number, end: number) => {
            addInterval(start, end, true);
        };

        const firstSubstring = substrings[0];
        const missingLeadingInterval = firstSubstring.start > 0;

        if (missingLeadingInterval) {
            addInterval(0, firstSubstring.start - 1);
        }

        for (let i = 0; i < substrings.length; i += 1) {
            const currentSubstring = substrings[i];
            const nextSubstring = substrings[i + 1];

            addMarkedInterval(currentSubstring.start, currentSubstring.end);

            if (nextSubstring) {
                addInterval(currentSubstring.end + 1, nextSubstring.start - 1);
            }
        }

        const lastMatchedInterval = substrings[substrings.length - 1];
        const hasTrailingInterval = lastMatchedInterval.end + 1 < lengthOfTargetString;

        if (hasTrailingInterval) {
            addInterval(lastMatchedInterval.end + 1, lengthOfTargetString - 1);
        }

        return result;
    }

    mapIndicesToSubstrings(substrings: Substring[], target: string): Substring[] {
        return substrings.map(substring => ({ ...substring, content: target.substring(substring.start, substring.end + 1) }));
    }
}
