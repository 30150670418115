<ng-select [clearable]="allowClear && !disabled"
           [disabled]="disabled"
           [placeholder]="'SIS_COMPONENTS.SELECT.CODE_SELECTION_EDITOR.SEARCH' | translate"
           [groupBy]="groupByColumn"
           [attr.aria-describedby]="id + '_errors'"
           notFoundText="{{ notFoundKeyToUse() | translate }}"
           (search)="search($event)"
           (blur)="closeOnBlur()"
           (open)="setDefaultCodeOptionsForSearch(true)"
           (close)="setStoredCodeBackAsSelected()"
           [inputAttrs]="{'aria-required': required ? 'true' : 'false'}"
           id="{{ id }}"
           labelForId="{{ labelForId }}"
           data-cy="code-select-editor-match"
           [ngModel]="selectedCode"
           (ngModelChange)="selectCode($event)">

  <ng-template ng-label-tmp let-item="item" class="ng-select-match">
    {{ showNumericalCodeValue ? (getLastPart(item.urn) + ', ' + (item.name | localizedString)) : item.name | localizedString }}
    <ng-container [ngTemplateOutlet]="lastPartTemplate" [ngTemplateOutletContext]="{ codeObj: item }"></ng-container>
  </ng-template>

  <ng-option *ngFor="let code of codeOptionsResults" [value]="code" [disabled]="code.disabled">
    <div *ngIf="!showAllLanguageVersions"
         title="{{ code.name | localizedString }}"
         class="ng-select-choices-row-inner-container">
      <span data-cy="code-select-editor-option">{{ showNumericalCodeValue && !code.isTooManySearchResult ? (getLastPart(code.urn) + ', ' + (code.name | localizedString)) : code.name | localizedString }}</span>
      <ng-container [ngTemplateOutlet]="lastPartTemplate" [ngTemplateOutletContext]="{ codeObj: code }"></ng-container>
    </div>

    <div *ngIf="showAllLanguageVersions"
         title="{{ code.name | localizedString }}"
         class="ng-select-choices-row-inner-container multiple-language-versions">
      <ng-container *ngFor="let locale of locales; let last = last;">
        <span data-cy="code-select-editor-option">{{ showNumericalCodeValue && !code.isTooManySearchResult ? (getLastPart(code.urn) + ', ' + code.name[locale] ) : code.name[locale] }}</span>
        <span *ngIf="!last"> / </span>
      </ng-container>
      <ng-container [ngTemplateOutlet]="lastPartTemplate" [ngTemplateOutletContext]="{ codeObj: code }"></ng-container>
    </div>
  </ng-option>
</ng-select>

<ng-template #lastPartTemplate let-codeObj="codeObj">
  <span class="additional-info" *ngIf="codeObj.universitySpecifier">({{ codeObj.universitySpecifier }})</span>
  <span class="additional-info" *ngIf="showLastPart">{{getLastPart(codeObj.urn)}}</span>
  <span class="additional-info" *ngIf="codeObj.deprecated" translate>SIS_COMPONENTS.SELECT.DEPRECATED</span>
</ng-template>
