import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SisCommonModule } from 'sis-common/sis-common.module';

import { SisComponentsModule } from '../../../sis-components.module';

import { MinorSelectionModuleBoxComponent } from './minor-selection-module-box/minor-selection-module-box.component';
import { MinorSelectionsComponent } from './minor-selections/minor-selections.component';

@NgModule({
    declarations: [
        MinorSelectionModuleBoxComponent,
        MinorSelectionsComponent,
    ],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        SisCommonModule,
        SisComponentsModule,
    ],
    exports: [
        MinorSelectionsComponent,
    ],
})
export class MinorSelectionModule {
}
