import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { OtmId, SearchResult, Location as SisuLocation } from 'common-typescript/types';
import { Observable } from 'rxjs';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

import { EntityService } from './entity.service';

const CONFIG = {
    ENDPOINTS: {
        backend: '/kori/api',
        get search() {
            return `${this.backend}/locations/search`;
        },
    },
};

@StaticMembers<DowngradedService>()
@Injectable({
    providedIn: 'root',
})
@NgEntityServiceConfig({
    baseUrl: CONFIG.ENDPOINTS.backend,
    resourceName: 'locations',
})
export class LocationEntityService extends EntityService<LocationsState> {
    constructor() {
        super(LocationStore, LocationQuery);
    }

    static downgrade: ServiceDowngradeMappings = {
        dependencies: [],
        moduleName: 'sis-components.service.locationEntityService',
        serviceName: 'locationEntityService',
    };

    search(fullTextQuery: string, universityOrgId: OtmId): Observable<SearchResult<SisuLocation>> {
        return this.getHttp().get<SearchResult<SisuLocation>>(CONFIG.ENDPOINTS.search, { params: { fullTextQuery, universityOrgId } });
    }
}

type LocationsState = EntityState<SisuLocation, OtmId>;

@StoreConfig({ name: 'locations' })
class LocationStore extends EntityStore<LocationsState> {}

class LocationQuery extends QueryEntity<LocationsState> {
    constructor(protected store: LocationStore) {
        super(store);
    }
}
