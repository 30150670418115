import radioTpl from './radio.tpl.html';
(function () {
  'use strict';

  angular.module('student.common.components').directive('radio', radio);
  function radio() {
    return {
      element: 'E',
      require: ['ngModel'],
      scope: {
        ngModel: '=',
        cb: '&ngChange',
        ngValue: '=',
        disabled: '=',
        inline: '@',
        label: '@',
        name: '@'
      },
      template: radioTpl
    };
  }
})();