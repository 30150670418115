import angular from 'angular';
import angularTranslate from 'angular-translate';
import _ from 'lodash';
import moment from 'moment';
import { PlanValidationTs } from 'common-typescript';
import 'sis-components/error-handler/legacy/errorService';
import { localeServiceModule } from 'sis-common/l10n/localeService';
import { UuidService } from 'sis-common/uuid/uuid.service.ts';
import 'sis-components/plan/moduleName.component';
import 'sis-components/courseUnit/courseUnit.component';
import 'sis-components/number/creditRange.filter';
import 'sis-components/plan/courseUnitWithSubstitutions.component';
import 'sis-components/service/studentAttainment.service';
import 'sis-components/attainment/customCourseUnitAttainmentBox/customCourseUnitAttainmentBox.component';
import { commonShowModuleContentApplicationModalModule } from 'sis-components/applications/commonShowModuleContentApplicationModal.service';
import 'sis-components/service/gradeScale.service';
import 'sis-components/service/validAttainmentFilter.service';
import 'sis-components/planStudyRight/planStudyRight.service';
import 'sis-components/service/applicationDecision.service';
import { customStudyDraftModule } from 'sis-components/customStudyDraft/customStudyDraft.component';
import { customStudyDraftInfoModalModule } from 'sis-components/customStudyDraft/customStudyDraftInfoModal.service';
import { IconComponent } from 'sis-components/icon/icon.component.ts';
import { TooltipComponent } from 'sis-components/tooltip/tooltip.component.ts';
import { planValidationResultHelperModule } from 'sis-components/service/planValidationResultHelper.service';
import { commonCurriculumPeriodServiceModule } from '../service/curriculumPeriod.service';
import { CommonGradeAverageService } from '../service/common-grade-average.service.ts';
import planModuleTpl from './planModule.tpl.html';
(function () {
  PlanModuleCtrl.$inject = ["$log", "$q", "$translate", "localeService", "commonCurriculumPeriodService", "commonStudentAttainmentService", "validAttainmentFilterService", "planStudyRightService", "uuidService", "commonApplicationDecisionService", "commonGradeAverageService", "commonGradeScaleService", "commonShowModuleContentApplicationModal", "PlanState", "STUDENT_APPLICATION_STATE", "defaultPromiseHandler", "customStudyDraftInfoModalService", "planValidationResultHelper"];
  angular.module('sis-components.plan.planModule', [UuidService.downgrade.moduleName, 'sis-components.plan.moduleName', 'sis-components.courseUnit', 'sis-components.number.creditRangeFilter', 'sis-components.plan.courseUnitWithSubstitutions', 'sis-components.service.studentAttainmentService', 'sis-components.attainment.customCourseUnitAttainmentBox', commonShowModuleContentApplicationModalModule, 'sis-common.errorhandler.errorService', CommonGradeAverageService.downgrade.moduleName, 'sis-components.service.gradeScale', 'sis-components.service.validAttainmentFilterService', 'sis-components.service.planStudyRightService', 'sis-components.service.applicationDecisionService', 'sis-components.number.decimalNumberFilter', angularTranslate, commonCurriculumPeriodServiceModule, customStudyDraftModule, customStudyDraftInfoModalModule, localeServiceModule, planValidationResultHelperModule, IconComponent.downgrade.moduleName, TooltipComponent.downgrade.moduleName]).component('commonPlanModule', {
    template: planModuleTpl,
    controller: PlanModuleCtrl,
    bindings: {
      plan: '<',
      moduleId: '<',
      parentModuleId: '<',
      validatablePlan: '<',
      planValidationResult: '<',
      moduleAttainment: '<',
      moduleContentApprovals: '<',
      highlightedModuleId: '<?',
      educationOptions: '<',
      // This is to show link to moduleAttainmentApplication and it is optional.
      requestedApplications: '<?',
      // These are for implicit selections; shown in the tree if set tot true
      showImplicitSelections: '<?',
      // Set to true for an implicit module selection; children are not shown in this case
      showSelectedParent: '<?',
      // Set to true to show an action button column on the right side
      showActionButtonColumn: '<?'
    }
  });

  /**
   * @ngInject
   */
  function PlanModuleCtrl(
  // NOSONAR
  $log, $q, $translate, localeService, commonCurriculumPeriodService, commonStudentAttainmentService, validAttainmentFilterService, planStudyRightService, uuidService, commonApplicationDecisionService, commonGradeAverageService, commonGradeScaleService, commonShowModuleContentApplicationModal, PlanState, STUDENT_APPLICATION_STATE, defaultPromiseHandler, customStudyDraftInfoModalService, planValidationResultHelper) {
    const $ctrl = this;
    $ctrl.moduleAttainment = undefined;
    $ctrl.moduleGroupId = undefined;
    $ctrl.isOpen = false;
    $ctrl.moduleValidationResults = undefined;
    $ctrl.gradeAbbreviation = undefined;
    $ctrl.expiryDate = undefined;
    $ctrl.titleAriaLabel = undefined;

    // Cache the custom attainment expiry dates, as calculating them in each digest loop is expensive
    $ctrl.customCourseUnitAttainmentExpiryDates = {};
    $ctrl.isAttainmentGroupNode = function () {
      return _.get($ctrl.moduleAttainment, 'type') === 'AttainmentGroupNode';
    };
    $ctrl.isGroupingModule = function () {
      return _.get($ctrl.module, 'type') === 'GroupingModule';
    };
    $ctrl.isCustomAttainment = () => _.get($ctrl.moduleAttainment, 'type') === 'CustomModuleAttainment';
    function attainmentNodesToModuleLike(attainmentNodes) {
      const childAttainments = _.chain($ctrl.moduleAttainment.nodes).filter({
        type: 'AttainmentReferenceNode'
      }).map(node => _.get($ctrl.validatablePlan.attainmentsById, node.attainmentId)).compact().value();

      // AttainmentGroupNode
      const groupModules = _.chain(attainmentNodes).filter({
        type: 'AttainmentGroupNode'
      }).map(n => ({
        code: '',
        id: `agn_${uuidService.randomUUID()}`,
        credits: 0,
        documentState: 'ACTIVE',
        attainment: n
      })).compact().value();
      return _.concat(groupModules, attainmentsToModuleLike(childAttainments));
    }
    function attainmentsToModuleLike(childAttainments) {
      const customModuleAttainments = _.filter(childAttainments, {
        type: 'CustomModuleAttainment'
      });
      const customModules = _.map(customModuleAttainments, customModuleAttainment => ({
        id: customModuleAttainment.id,
        attainment: customModuleAttainment
      }));
      const moduleAttainments = _.filter(childAttainments, attainment => _.includes(['ModuleAttainment', 'DegreeProgrammeAttainment'], _.get(attainment, 'type')));
      const modules = _.map(moduleAttainments, moduleAttainment => ({
        id: moduleAttainment.moduleId,
        attainment: moduleAttainment
      }));
      return _.concat(modules, customModules);
    }
    $ctrl.$onInit = function () {
      if (!$ctrl.moduleId) {
        $log.warn('PlanModuleCtrl $onInit without moduleId');
      }
      $ctrl.module = _.get($ctrl.validatablePlan.modulesById, $ctrl.moduleId);
      if (!$ctrl.moduleAttainment) {
        $ctrl.moduleAttainment = $ctrl.validatablePlan.getModuleAttainment($ctrl.moduleId);
      }
      if (!$ctrl.moduleAttainment) {
        $ctrl.moduleValidationResults = planValidationResultHelper.getModuleValidationResult(_.get($ctrl.module, 'id'), $ctrl.planValidationResult);
        $ctrl.childModuleSelections = $ctrl.validatablePlan.getSelectedModulesById($ctrl.module);
        $ctrl.childCourseUnitSelections = $ctrl.validatablePlan.getSelectedCourseUnitsById($ctrl.module);
        $ctrl.childCustomCourseUnitAttainments = $ctrl.validatablePlan.getSelectedCustomCourseUnitAttainmentsById($ctrl.module);
        $ctrl.moduleLikeItems = attainmentsToModuleLike($ctrl.validatablePlan.getSelectedCustomModuleAttainmentsById($ctrl.module));
        $ctrl.customStudyDrafts = $ctrl.validatablePlan.getSelectedCustomStudyDraftsByParentModuleId($ctrl.module.id);
      } else {
        setLocalizedGrade($ctrl.moduleAttainment);
        $ctrl.childModuleSelections = [];
        $ctrl.childCourseUnitSelections = [];
        const childAttainments = _.chain($ctrl.moduleAttainment.nodes).filter({
          type: 'AttainmentReferenceNode'
        }).map(node => _.get($ctrl.validatablePlan.attainmentsById, node.attainmentId)).compact().value();
        $ctrl.childCustomCourseUnitAttainments = _.filter(childAttainments, {
          type: 'CustomCourseUnitAttainment'
        });
        const courseUnitAttainments = _.filter(childAttainments, {
          type: 'CourseUnitAttainment'
        });
        $ctrl.childCourseUnitSelections = _.chain(courseUnitAttainments).map(attainment => {
          const {
            courseUnit
          } = attainment;
          if (!_.isNil(courseUnit)) {
            courseUnit.attainment = attainment;
          }
          return courseUnit;
        }).compact().value();
        if (!$ctrl.isAttainmentGroupNode()) {
          $ctrl.moduleValidationResults = {
            state: 'ATTAINED',
            attainedCredits: $ctrl.moduleAttainment.credits,
            plannedCredits: {
              min: $ctrl.moduleAttainment.credits,
              max: $ctrl.moduleAttainment.credits
            }
          };
        }
        $ctrl.moduleLikeItems = attainmentNodesToModuleLike($ctrl.moduleAttainment.nodes);
      }

      // OTM-17848 always open all levels
      // in the future OTM-17845 will set focus on the cu / module in question
      $ctrl.isOpen = !$ctrl.showSelectedParent;
      $ctrl.studyRightState = $ctrl.getStudyRightState();
      $ctrl.isEducationOption = !!$ctrl.studyRightState;
      $ctrl.moduleContentApproval = _.first(getMatchingModuleContentApprovals());
      if (!$ctrl.module) {
        // Somewhat nasty but currently template refers directly to module for name, code etc.
        // Should be refactored to use a different name
        $ctrl.module = $ctrl.moduleAttainment;
      }
      if ($ctrl.showSelectedParent) {
        $ctrl.selectedParent = _.get($ctrl.validatablePlan.modulesById, $ctrl.parentModuleId);
      }
      if ($ctrl.showGradeAverage()) {
        $ctrl.loadGradeAverage();
      }
      loadCurriculumPeriods($ctrl.module);
    };
    $ctrl.openCustomStudyDraftInfoModal = customStudyDraft => customStudyDraftInfoModalService.open(customStudyDraft).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    $ctrl.isAboutToExpire = function () {
      return $ctrl.getExpiryDate() !== null;
    };
    $ctrl.getStudyRightState = function () {
      if (!$ctrl.module) {
        return undefined;
      }
      if (!$ctrl.educationOptions) {
        return undefined;
      }
      const matchingEducationOption = planStudyRightService.getMatchingEducationOption($ctrl.module.groupId, $ctrl.educationOptions, $ctrl.validatablePlan);
      if (!!matchingEducationOption && matchingEducationOption.isInPlan === true && matchingEducationOption.isInPlanAsMinor === false) {
        return matchingEducationOption.studyRightState;
      }
      return undefined;
    };
    $ctrl.getExpiryDate = function () {
      if (!_.isUndefined($ctrl.expiryDate)) {
        return $ctrl.expiryDate;
      }
      const attainment = $ctrl.validatablePlan.getModuleAttainment($ctrl.moduleId) || $ctrl.validatablePlan.getCustomModuleAttainment($ctrl.moduleId);
      if (attainment && attainment.expiryDate && validAttainmentFilterService.isAttainmentAboutToExpire(attainment) && !validAttainmentFilterService.isAttached(attainment, $ctrl.validatablePlan.getAllAttainments())) {
        $ctrl.expiryDate = moment(attainment.expiryDate);
      } else {
        $ctrl.expiryDate = null;
      }
      return $ctrl.expiryDate;
    };
    function setLocalizedGrade(attainment) {
      if (_.isNil(attainment)) {
        return '';
      }
      const gradeScale = commonStudentAttainmentService.get('gradeScales', attainment.gradeScaleId);
      if (_.isNil(gradeScale)) {
        return '';
      }
      $ctrl.gradeAbbreviation = _.get(_.find(gradeScale.grades, {
        localId: attainment.gradeId
      }), 'abbreviation');
    }
    $ctrl.getGrade = function (attainment) {
      const gradeScale = commonStudentAttainmentService.get('gradeScales', attainment.gradeScaleId);
      return _.find(gradeScale.grades, {
        localId: attainment.gradeId
      });
    };
    $ctrl.getCustomCourseUnitAttainmentExpiryDate = function (attainment) {
      if ($ctrl.customCourseUnitAttainmentExpiryDates.hasOwnProperty(attainment.id)) {
        return $ctrl.customCourseUnitAttainmentExpiryDates[attainment.id];
      }
      const allAttainments = $ctrl.validatablePlan.getAllAttainments();
      if (attainment.expiryDate && allAttainments && validAttainmentFilterService.isAttainmentAboutToExpire(attainment) && !validAttainmentFilterService.isAttached(attainment, allAttainments)) {
        const expiryDate = moment(attainment.expiryDate);
        $ctrl.customCourseUnitAttainmentExpiryDates[attainment.id] = expiryDate;
        return expiryDate;
      }
      return null;
    };
    $ctrl.hasCustomCourseUnitAttainments = function () {
      return !_.isNil($ctrl.childCustomCourseUnitAttainments) && $ctrl.childCustomCourseUnitAttainments.length > 0;
    };
    function getMatchingModuleContentApprovals() {
      return _.filter($ctrl.moduleContentApprovals, {
        parentModuleId: $ctrl.parentModuleId,
        approvedModuleId: $ctrl.moduleId
      });
    }
    $ctrl.showModuleContentApproval = () => commonShowModuleContentApplicationModal.open($ctrl.plan.userId, $ctrl.moduleContentApproval).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    $ctrl.isInvalidSelection = function (module) {
      return _.get(module, 'validationResults.invalidSelection') === true;
    };
    $ctrl.isInvalidAccordingToModuleContentApproval = function (module) {
      return _.get(module, 'validationResults.invalidAccordingToModuleContentApproval') === true;
    };
    $ctrl.hasModuleContentApproval = function (module) {
      return _.get(module, 'validationResults.invalidAccordingToModuleContentApproval') !== undefined;
    };
    $ctrl.getPlanState = function () {
      if ($ctrl.isAttainmentGroupNode()) {
        return null;
      }
      return PlanValidationTs.getPlanStateForModule($ctrl.module, $ctrl.validatablePlan, $ctrl.planValidationResult);
    };
    $ctrl.toggleOpen = function () {
      $ctrl.isOpen = !$ctrl.isOpen && !$ctrl.showSelectedParent;
    };
    $ctrl.getImplicitCourseUnitIds = function () {
      return _.uniq(_.get($ctrl.moduleValidationResults, 'implicitCourseUnitIds'));
    };
    $ctrl.getImplicitModuleIds = function () {
      return _.uniq(_.get($ctrl.moduleValidationResults, 'implicitModuleIds'));
    };
    $ctrl.getParentModuleIdForCourseUnitId = function (courseUnitId) {
      const courseUnitSelection = _.get($ctrl.validatablePlan.courseUnitIdSelectionMap, courseUnitId);
      return _.get(courseUnitSelection, 'parentModuleId');
    };
    $ctrl.getParentModuleIdForModuleId = function (moduleId) {
      const moduleSelection = _.get($ctrl.validatablePlan.moduleIdSelectionMap, moduleId);
      return _.get(moduleSelection, 'parentModuleId');
    };
    $ctrl.showGradeAverage = function () {
      if (!$ctrl.module && !$ctrl.moduleAttainment) {
        return false;
      }
      return !$ctrl.isAttainmentGroupNode() && !$ctrl.isGroupingModule();
    };
    $ctrl.loadGradeAverage = function () {
      commonGradeScaleService.getGradeScale('sis-0-5').then(gradeScale => {
        let gradeAverageResult;
        let attainmentIds;
        const method = 'COURSE_UNIT_AND_EMPTY_MODULE_ARITHMETIC_MEAN_WEIGHTED_BY_CREDITS';
        const allAttainments = _.values($ctrl.validatablePlan.attainmentsById);
        if ($ctrl.moduleAttainment) {
          attainmentIds = [$ctrl.moduleAttainment.id];
          gradeAverageResult = commonGradeAverageService.calculateGradeAverage(attainmentIds, allAttainments, gradeScale, method);
        } else {
          const module = _.get($ctrl.validatablePlan.modulesById, $ctrl.moduleId);
          attainmentIds = commonGradeAverageService.getAttainmentIdsForModule(module, $ctrl.validatablePlan);
          gradeAverageResult = commonGradeAverageService.calculateGradeAverage(attainmentIds, allAttainments, gradeScale, method);
        }
        const gradeAverage = _.get(gradeAverageResult, 'gradeAverage');
        $ctrl.gradeAverage = _.isNumber(gradeAverage) ? +gradeAverage.toFixed(2) : undefined;
      }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    };
    function loadCurriculumPeriods(module) {
      commonCurriculumPeriodService.findCurriculumPeriodsByIds(module.curriculumPeriodIds).then(curriculumPeriods => {
        $ctrl.curriculumPeriods = _.sortBy(curriculumPeriods, 'activePeriod.startDate');
        setTitleAriaLabel();
      }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    }
    $ctrl.showModuleContentApprovalButtonVisible = function () {
      return $ctrl.moduleContentApproval && _.includes(['ACCEPTED', 'CONDITIONAL', 'REQUESTED', 'IN_HANDLING'], $ctrl.moduleContentApproval.state);
    };
    function setTitleAriaLabel() {
      const moduleName = localeService.getLocalizedValue($ctrl.module.name);
      const moduleType = getModuleTypeAriaLabel();
      const curriculumPeriods = $ctrl.curriculumPeriods && $ctrl.curriculumPeriods.length > 0 ? getCurriculumPeriodsAriaLabel() : '';
      $ctrl.titleAriaLabel = `${moduleName}${moduleType}${curriculumPeriods}`;
    }
    function getModuleTypeAriaLabel() {
      if ($ctrl.isAttainmentGroupNode() || $ctrl.isGroupingModule()) {
        return `, ${$translate.instant('SIS_COMPONENTS.PLAN.STUDY_TOOLTIP.GROUPING_MODULE')}`;
      }
      if ($ctrl.isCustomAttainment()) {
        return `, ${$translate.instant('SIS_COMPONENTS.PLAN.STUDY_TOOLTIP.CUSTOM_ATTAINMENT')}`;
      }
      return '';
    }
    function getCurriculumPeriodsAriaLabel() {
      const curriculumPeriodsHeading = $translate.instant('SIS_COMPONENTS.PLAN.STUDY_TOOLTIP.CURRICULUM_PERIODS');
      const curriculumPeriodNames = $ctrl.curriculumPeriods.map(period => localeService.getLocalizedValue(period.name)).join(', ');
      return `, ${curriculumPeriodsHeading}: ${curriculumPeriodNames}`;
    }
  }
})();