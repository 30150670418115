import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { enrolmentValidationServiceProvider } from '../ajs-upgraded-modules';

import { AllocationRuleDescriptionPipe } from './allocation-rule-description.pipe';
import { AllocationRuleKeyPipe } from './allocation-rule-key.pipe';
import { AllocationRuleParamsPipe } from './allocation-rule-params.pipe';
import { EnrolmentProcessingStateIconPipe } from './enrolment-processing-state-icon.pipe';
import { EnrolmentProcessingStateMessageKeyPipe } from './enrolment-processing-state-message-key.pipe';
import { PersonRequirementRuleNamePipe } from './person-requirement-rule-name.pipe';

@NgModule({
    declarations: [
        AllocationRuleDescriptionPipe,
        AllocationRuleKeyPipe,
        AllocationRuleParamsPipe,
        EnrolmentProcessingStateIconPipe,
        EnrolmentProcessingStateMessageKeyPipe,
        PersonRequirementRuleNamePipe,
    ],
    imports: [
        CommonModule,
    ],
    exports: [
        AllocationRuleDescriptionPipe,
        AllocationRuleKeyPipe,
        AllocationRuleParamsPipe,
        EnrolmentProcessingStateIconPipe,
        EnrolmentProcessingStateMessageKeyPipe,
        PersonRequirementRuleNamePipe,
    ],
    providers: [
        enrolmentValidationServiceProvider,
    ],
})
export class EnrolmentModule {
}
