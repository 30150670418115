<div class="row">
  <div class="col-6 form-group-mimic">
    <h4>{{'STUDENT_APPLICATIONS.DECISION' | translate}}</h4>
    <p *ngIf="moduleContentWorkflowState === workflowState.ACCEPTED ||
    moduleContentWorkflowState === workflowState.REJECTED ||
    moduleContentWorkflowState === workflowState.CONDITIONAL; else empty">
      {{('STUDENT_APPLICATIONS.STATE.' + moduleContentWorkflowState) | translate}}
    </p>
    <ng-template #empty>
      <p>-</p>
    </ng-template>
  </div>
  <div *ngIf="moduleContentWorkflowDecision.resolutionRationale" class="col-6">
    <div class="form-group-mimic">
      <h4>{{'STUDENT_APPLICATIONS.RESOLUTION_RATIONALE' | translate}}</h4>
      <p class="text-area-static">{{moduleContentWorkflowDecision.resolutionRationale}}</p>
    </div>
    <div *ngIf="moduleContentWorkflowState === workflowState.CONDITIONAL" class="form-group-mimic">
      <h4>{{'SHOW_MODULE_CONTENT_APPROVAL_MODAL.' + moduleContentWorkflowType + '.CONDITIONS' | translate}}</h4>
      <p class="text-area-static">{{moduleContentWorkflowDecision.conditionalApprovalTerms}}</p>
    </div>
  </div>
</div>
<sis-recommendation [contentRecommendation]="moduleContentWorkflowDecision.contentRecommendation"
                    [formalRecommendation]="moduleContentWorkflowDecision.formalRecommendation"
                    [approvalRequestType]="moduleContentWorkflowType">
</sis-recommendation>
<div class="row">
  <div class="col-6 form-group-mimic">
    <h4>{{'STUDENT_APPLICATIONS.APPROVAL_DATE' | translate}}</h4>
    <p>{{moduleContentWorkflowDecision.approvalDate | localDateFormat}}</p>
  </div>
  <div *ngIf="moduleContentWorkflowDecision.approvedBy"class="col-6 form-group-mimic">
    <h4>{{'STUDENT_APPLICATIONS.HANDLED_BY' | translate}}</h4>
    <p>
      <span>{{moduleContentWorkflowDecision.approvedBy | publicPerson | async | personFullName}}</span>
      <span *ngIf="moduleContentWorkflowDecision.approverTitle">, {{moduleContentWorkflowDecision.approverTitle}}</span>
    </p>
  </div>
</div>
