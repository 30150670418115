import { Inject, Injectable } from '@angular/core';
import { DocumentState } from 'common-typescript/types';

import { PREVIEW_MODE } from '../../ajs-upgraded-modules';

@Injectable({
    providedIn: 'root',
})
/**
 * Wrapper service for AngularJs PreviewMode. PreviewMode implementation should be moved here
 * once the AngularJs service is no longer called directly anywhere. In new Angular use this instead
 * of injecting the old service using the injection token.
 */
export class PreviewModeService {

    constructor(@Inject(PREVIEW_MODE) private previewMode: any) { }

    setPreviewMode(): void {
        this.previewMode.setPreviewMode();
    }

    isPreviewMode(): boolean {
        return this.previewMode.isPreviewMode();
    }

    documentStatesForPreview(): DocumentState[] {
        return this.previewMode.documentStatesForPreview();
    }
}
