import angular from 'angular';
import _ from 'lodash';
import { courseUnitRealisationModelModule } from 'sis-components/model/courseUnitRealisation.model';
import { assessmentItemServiceModule } from 'sis-components/service/assessmentItem.service';
import { commonEnrolmentServiceModule } from 'sis-components/service/enrolment.service';
import { commonStudyEventServiceModule } from 'sis-components/service/studyEvent.service';
import { commonEnrolmentPeriodDateServiceModule } from 'sis-components/service/enrolmentPeriodDate.service';
import { personRuleModule } from 'sis-components/service/personRule.service';
import { enrolmentCalculationResultForPersonModelModule } from '../model/enrolmentCalculationResultForPerson.model';
export const enrolmentServiceModule = 'student.service.enrolmentService';
(function () {
  enrolmentService.$inject = ["$q", "commonEnrolmentService", "commonAssessmentItemService", "jsDataCacheHelper", "courseUnitRealisationJSDataModel", "personRuleService", "enrolmentCalculationResultForPersonModel", "EnrolmentState", "enrolmentPeriodDateService", "commonStudyEventService"];
  angular.module(enrolmentServiceModule, [commonEnrolmentServiceModule, commonStudyEventServiceModule, assessmentItemServiceModule, courseUnitRealisationModelModule, enrolmentCalculationResultForPersonModelModule, commonEnrolmentPeriodDateServiceModule, personRuleModule]).factory('enrolmentService', enrolmentService);

  /**
   * @ngInject
   */
  function enrolmentService($q, commonEnrolmentService, commonAssessmentItemService, jsDataCacheHelper, courseUnitRealisationJSDataModel, personRuleService, enrolmentCalculationResultForPersonModel, EnrolmentState, enrolmentPeriodDateService, commonStudyEventService) {
    const api = {
      refreshEnrolmentIfChanged: (enrolmentId, revision) => commonEnrolmentService.refreshEnrolmentIfChanged(enrolmentId, revision),
      findMyEnrolments: (bypassCache, loadRelations) => commonEnrolmentService.findMyEnrolments(bypassCache, loadRelations),
      findMyCalculationResults(courseUnitRealisationIds, bypassCache) {
        return jsDataCacheHelper.findByIds(enrolmentCalculationResultForPersonModel, courseUnitRealisationIds, bypassCache, 'courseUnitRealisationIds');
      },
      getCalculationResult: curId => enrolmentCalculationResultForPersonModel.get(curId),
      /**
       * No enrichment should be assumed. It may enrich or not. It's up yours 50-60.
       * Loads ais, cus, curs for enrolments - the data that is needed when component is collapsed.
       */
      loadMinimumDataForCourseUnitRealisationComponent(enrolments) {
        return commonEnrolmentService.loadEnrolmentRelations(enrolments, false).then(() => api.$private.loadRelationsForCredits(enrolments)).then(() => enrolments);
      },
      /**
       * Loads ais, cus, curs (and study events for curs), relations for person rule texts but not calculation results for enrolments.
       * In other words this method loads the data that is needed when component is expanded.
       * This method assumes that calculation results are cached.
       */
      loadDataForCourseUnitRealisationComponent(enrolments) {
        function loadCalculationResultRelations() {
          const calculationResults = _.chain(enrolments).map('courseUnitRealisationId').map(api.getCalculationResult).compact().value();
          return personRuleService.loadRelationsForRuleInfoTextInResults(calculationResults);
        }
        function loadStudyEventsAndLocations() {
          return api.loadMinimumDataForCourseUnitRealisationComponent(enrolments).then(() => {
            const courseUnitRealisations = _.chain(enrolments).map('courseUnitRealisationId').map(getCur).compact().value();
            return api.$private.loadStudyEventsAndLocationsForCourseUnitRealisations(courseUnitRealisations);
          });
        }
        return $q.all([loadCalculationResultRelations(), loadStudyEventsAndLocations()]).then(() => enrolments);
      },
      /**
       * This method is synchronous. So all related objects must be present in js-data cache.
       * This method either should not be called before calling loadMinimumDataForCourseUnitRealisationComponent or you need to make
       * sure that assessment item of enrolment or course unit realisation and some of it's assessment items are in js-data cache.
       */
      getEnrolmentCredits(enrolment) {
        const ai = getAi(enrolment.assessmentItemId);
        if (ai) {
          return ai.credits;
        }
        const cur = getCur(enrolment.courseUnitRealisationId);
        const ais = _.chain(_.get(cur, 'assessmentItemIds', [])).map(getAi).compact().value();
        if (_.isEmpty(ais)) {
          return undefined;
        }
        const min = _.chain(ais).map('credits.min').min().value();
        const max = _.chain(ais).map('credits.max').max().value();
        return {
          min,
          max
        };
      },
      /**
       * Calculates that which filter this enrolment and cur applies to.
       * undefined: enrolment not in calendar
       * ACTIVITY_ENDED: activity period has ended for cur
       * ABORTED: enrolment state is ABORTED_BY_*
       * NOT_ENROLLED_AND_ENROLMENT_PERIODS_PASSED: enrolment state is NOT_ENROLLED and enrolment period is defined and passed
       * ENROLMENT_NOT_STARTED: enrolment state is NOT_ENROLLED and enrolment period not defined or not started
       * NOT_ENROLLED: enrolment state is NOT_ENROLLED and enrolment period is ongoing
       * PROCESSING: enrolment state is PROCESSING
       * ENROLLED: enrolment state is ENROLLED
       * REJECTED: enrolment state is REJECTED
       */
      getFilterKeyForEnrolment(enrolment, cur) {
        if (!enrolment.isInCalendar) {
          return undefined;
        }
        if (enrolmentPeriodDateService.activityPeriodEnded(cur)) {
          return 'ACTIVITY_ENDED';
        }
        const {
          state
        } = enrolment;
        if (_.includes([EnrolmentState.ABORTED_BY_STUDENT, EnrolmentState.ABORTED_BY_TEACHER], state)) {
          return 'ABORTED';
        }
        if (state === EnrolmentState.NOT_ENROLLED) {
          if (enrolmentPeriodDateService.allEnrolmentPeriodsHaveEnded(cur)) {
            return 'NOT_ENROLLED_AND_ENROLMENT_PERIODS_PASSED';
          }
          return enrolmentPeriodDateService.enrolmentPeriodStarted(cur) ? EnrolmentState.NOT_ENROLLED : 'ENROLMENT_NOT_STARTED';
        }
        if (state == EnrolmentState.INVALID) {
          return 'PROCESSING';
        }
        return state;
      },
      getDefaultCalendarFilterStates() {
        return {
          ENROLMENT_NOT_STARTED: true,
          NOT_ENROLLED: true,
          PROCESSING: true,
          ENROLLED: true,
          REJECTED: true
        };
      },
      $private: {
        loadRelationsForCredits(enrolments) {
          const enrolmentsWithoutAiButWithCur = _.chain(enrolments).reject(enrolment => getAi(enrolment.assessmentItemId)).filter(enrolment => getCur(enrolment.courseUnitRealisationId)).value();
          if (_.isEmpty(enrolmentsWithoutAiButWithCur)) {
            return $q.when();
          }
          const aiIds = _.chain(enrolmentsWithoutAiButWithCur).map(enrolment => getCur(enrolment.courseUnitRealisationId)).compact().uniq().flatMap('assessmentItemIds').value();
          return commonAssessmentItemService.findByIds(aiIds);
        },
        loadStudyEventsAndLocationsForCourseUnitRealisations(courseUnitRealisations) {
          return commonStudyEventService.findStudyEventsForCourseUnitRealisations(courseUnitRealisations).then(studyEvents => commonStudyEventService.findAllLocationsForStudyEvents(studyEvents)).then(() => commonStudyEventService.loadStudyEventsForCourseUnitRealisations(courseUnitRealisations));
        }
      }
    };
    return api;
    function getAi(aiId) {
      return aiId ? commonAssessmentItemService.getAssessmentItem(aiId) : undefined;
    }
    function getCur(curId) {
      return curId ? courseUnitRealisationJSDataModel.get(curId) : undefined;
    }
  }
})();