import * as angular from 'angular';
import { timetableGridModule } from './timetableGrid.module';
import timetableItemTpl from './timetableItem.tpl.html';
angular.module(timetableGridModule).directive('timetableItem', timetableItemDirective);

/** @ngInject */
function timetableItemDirective() {
  return {
    template: timetableItemTpl,
    scope: {
      item: '<'
    },
    link(scope, element) {
      if (scope.item.left === 0) {
        element.find('div.box').css('border-left', 'none');
      }
      element.css('height', `${scope.item.height + 1}px`);
      element.css('width', `${scope.item.width + 1}px`);
      element.css('top', scope.item.top);
      element.css('left', `${scope.item.left}px`);
      const event = scope.item.event || {};
      scope.backgroundColorClass = event.backgroundColor ? `${event.backgroundColor}-bg` : '';
      scope.textColorClass = event.textColor ? `${event.textColor}-text` : '';
      scope.cancelledTimeClass = event.cancelled ? 'cancelled-time' : '';
    }
  };
}