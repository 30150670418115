import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import {
    CourseUnitRealisation,
    Enrolment,
    EnrolmentCalculationConfig,
    EnrolmentCalculationConfigForPerson,
    RuleResult,
} from 'common-typescript/types';
import { EMPTY, Observable } from 'rxjs';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { CourseUnitRealisationEntityService } from 'sis-components/service/course-unit-realisation-entity.service';
import { EnrolmentCalculationConfigEntityService } from 'sis-components/service/enrolment-calculation-config-entity.service';
import { UniversityService } from 'sis-components/service/university.service';

import { EnrolmentStudentService } from '../../common/service/enrolment-student.service';

@Component({
    selector: 'app-enrolment-basic-info',
    templateUrl: './enrolment-basic-info.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnrolmentBasicInfoComponent implements OnInit {

    @Input({ required: true }) enrolment: Enrolment;

    enrolmentCalculationConfig$: Observable<EnrolmentCalculationConfig>;
    courseUnitRealisation$: Observable<CourseUnitRealisation>;
    personCalculationConfig: Observable<EnrolmentCalculationConfigForPerson>;

    constructor(
        private enrolmentCalculationConfigService: EnrolmentCalculationConfigEntityService,
        private courseUnitRealisationEntityService: CourseUnitRealisationEntityService,
        private universityService: UniversityService,
        private enrolmentStudentService: EnrolmentStudentService,
        private appErrorHandler: AppErrorHandler,
    ) {}

    ngOnInit(): void {
        this.enrolmentCalculationConfig$ = this.enrolmentCalculationConfigService.getEnrolmentCalculationConfigOrNull(this.enrolment.courseUnitRealisationId)
            .pipe(this.appErrorHandler.defaultErrorHandler());
        this.courseUnitRealisation$ = this.courseUnitRealisationEntityService.getById(this.enrolment.courseUnitRealisationId)
            .pipe(this.appErrorHandler.defaultErrorHandler());
        this.personCalculationConfig = this.enrolment?.courseUnitRealisationId ? this.enrolmentStudentService.getCalculationConfig(this.enrolment.courseUnitRealisationId) : EMPTY;
    }

    isCrossStudy(realisation: CourseUnitRealisation) {
        return !realisation.universityOrgIds?.includes(this.universityService.getCurrentUniversityOrgId());
    }

    noFalseRequirements = (requirementRules: RuleResult[]): boolean => requirementRules.every(rule => rule.result);
}
