<div class="choose-plan">
  <div ngbDropdown #dropdown=ngbDropdown>
    <h1 id="skip-link-target" tabindex="-1">
      <button type="button"
              ngbDropdownToggle
              class="non-button choose-plan__button"
              data-cy="choose-study-plan"
              [attr.aria-label]="(selectedPlanName) + ' ' + ('ARIA_LABEL.SELECT_PLAN' | translate)">
        <span class="row d-inline-flex">
          <span class="choose-plan__button__title">
            {{ selectedPlanName || ('STUDY_PLANS' | translate) }}
            <sis-tiny-badge *ngIf="selectedPlan?.primary" [variant]="'success'" data-cy="study-plan-primary-badge">
              {{'PRIMARY_STUDY_PLAN' | translate}}
            </sis-tiny-badge>
          </span>
          <span class="choose-plan__button__icon">
            <sis-icon icon="chevron" iconClass="cw-90"></sis-icon>
          </span>
        </span>
      </button>
    </h1>
    <div ngbDropdownMenu class="multiple-line-items" role="menu">
      <ng-container *ngFor="let option of options">
        <span *ngIf="option.education" class="dropdown-header" role="heading">
          {{option.education.name | localizedString}}
        </span>
        <button *ngIf="!option.education && option.plan"
                ngbDropdownItem role="option"
                data-cy="plan-select-button"
                (click)="onSelect(option.id); dropdown.close()"
                [attr.aria-current]="option.id === selectedPlan?.id"
                [class.active]="option.id === selectedPlan?.id">
          {{option.plan.name}}
          <span *ngIf="option.id === selectedPlan?.id" class="visually-hidden">{{'ARIA_LABEL.SELECTED' | translate}}</span>
          <sis-tiny-badge *ngIf="option.plan.primary" [variant]="'success'" data-cy="study-plan-primary-badge">
            {{'PRIMARY_STUDY_PLAN' | translate}}
          </sis-tiny-badge>
          <span class="additional-data">
            <span *ngIf="option.selectedLearningOpportunityName">{{option.selectedLearningOpportunityName | localizedString}}, </span>
            {{option.plan['curriculumPeriod']?.name | localizedString}},
            {{'STUDY_PLAN_MODIFIED' | translate:{modified: option.plan.metadata.lastModifiedOn | localDateFormat} }}
          </span>
        </button>
      </ng-container>
    </div>
    <div *ngIf="selectedPlan" class="plan-description">
      {{selectedPlanEducationName | localizedString}}
      (<ng-container *ngIf="selectedLearningOpportunityName">{{selectedLearningOpportunityName | localizedString}}, </ng-container>
      <ng-container *ngIf="selectedPlanCurriculumPeriodName">{{selectedPlanCurriculumPeriodName | localizedString}}, </ng-container>
      {{'STUDY_PLAN_MODIFIED_CREATED' | translate:{
        created: selectedPlan.metadata.createdOn | localDateFormat,
        modified: selectedPlan.metadata.lastModifiedOn | localDateFormat
      } }})
    </div>
  </div>
</div>
