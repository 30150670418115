import angular from 'angular';
import _ from 'lodash';
export const courseUnitRealisationNameServiceModule = 'sis-common.model.courseUnitRealisationNameService';
(function () {
  courseUnitRealisationNameService.$inject = ["$translate", "localeService", "curNameLocalizationKeys"];
  angular.module(courseUnitRealisationNameServiceModule, ['pascalprecht.translate', 'sis-common.l10n.localeService']).factory('courseUnitRealisationNameService', courseUnitRealisationNameService).constant('curNameLocalizationKeys', {
    DATE_FORMAT_LONG: 'CUR.COMPUTED_NAME_DATE_FORMAT_LONG',
    DATE_FORMAT_SHORT: 'CUR.COMPUTED_NAME_DATE_FORMAT_SHORT',
    DATE_RANGE: 'CUR.COMPUTED_NAME_DATE_FORMAT_RANGE'
  });

  /**
   * @ngInject
   */
  function courseUnitRealisationNameService($translate, localeService, curNameLocalizationKeys) {
    function activityPeriodAsText(courseUnitRealisation) {
      var activityPeriodName = {};
      if (courseUnitRealisation.activityPeriod) {
        // Empty string ends with invalid date, and not included in text object
        var startDate = moment(_.defaultTo(courseUnitRealisation.activityPeriod.startDate, ""));
        var endDate = moment(_.defaultTo(courseUnitRealisation.activityPeriod.endDate, "")).subtract(1, 'days');
        _.forEach(localeService.supportedLocales, function (locale) {
          var localizedName;
          var dateFormatLong = $translate.instant(curNameLocalizationKeys.DATE_FORMAT_LONG, undefined, undefined, locale);
          var dateFormatShort = $translate.instant(curNameLocalizationKeys.DATE_FORMAT_SHORT, undefined, undefined, locale);
          var hasDates = startDate.isValid() && endDate.isValid();
          var startAndEndDateAreSame = hasDates && endDate.isSame(startDate, 'day');
          var useShortStartDate = !startAndEndDateAreSame && hasDates && endDate.isSame(startDate, 'year');
          var startDateFormat = useShortStartDate ? dateFormatShort : dateFormatLong;
          if (hasDates && !startAndEndDateAreSame) {
            localizedName = $translate.instant(curNameLocalizationKeys.DATE_RANGE, {
              start: moment(startDate).format(startDateFormat),
              end: moment(endDate).format(dateFormatLong)
            }, undefined, locale);
          } else if (startDate.isValid()) {
            localizedName = moment(startDate).format(startDateFormat);
          } else if (endDate.isValid()) {
            localizedName = moment(endDate).format(startDateFormat);
          }
          activityPeriodName[locale] = localizedName;
        });
      }
      return activityPeriodName;
    }
    return {
      generateShortName: function (courseUnitRealisation) {
        var shortName = {};
        var activityPeriodName = activityPeriodAsText(courseUnitRealisation);
        _.forEach(localeService.supportedLocales, function (locale) {
          shortName[locale] = _.trim(courseUnitRealisation.name[locale]) + ' ' + activityPeriodName[locale];
        });
        return shortName;
      },
      generateFullName: function (courseUnitRealisation) {
        var fullName = {};
        var nameSpecifier = courseUnitRealisation.nameSpecifier;
        nameSpecifier = !nameSpecifier || nameSpecifier === null ? {} : nameSpecifier;
        var name = courseUnitRealisation.name;
        name = !name || name === null ? {} : name;
        var activityPeriodName = activityPeriodAsText(courseUnitRealisation);
        var fallbackName;
        var fallbackNameSpecifier;
        _.forEach(localeService.supportedLocales, function (locale) {
          if (!fallbackNameSpecifier && nameSpecifier[locale] && nameSpecifier[locale].trim().length > 0) {
            fallbackNameSpecifier = nameSpecifier[locale];
          }
          if (!fallbackName && name[locale] && name[locale].trim().length > 0) {
            fallbackName = name[locale];
          }
        });
        fallbackName = fallbackName ? fallbackName : '';
        fallbackNameSpecifier = fallbackNameSpecifier ? fallbackNameSpecifier : '';
        _.forEach(localeService.supportedLocales, function (locale) {
          var nameSpecifierForLocale = nameSpecifier[locale];
          nameSpecifierForLocale = nameSpecifierForLocale && nameSpecifierForLocale.trim().length > 0 ? nameSpecifierForLocale : fallbackNameSpecifier;
          nameSpecifierForLocale += nameSpecifierForLocale.length === 0 ? '' : ', ';
          var nameForLocale = name[locale];
          nameForLocale = nameForLocale && nameForLocale.trim().length > 0 ? nameForLocale : fallbackName;
          var activityPeriodForLocale = activityPeriodName[locale];
          activityPeriodForLocale = activityPeriodForLocale ? ' ' + activityPeriodForLocale : '';
          fullName[locale] = _.trim(nameSpecifierForLocale + nameForLocale + activityPeriodForLocale);
        });
        return fullName;
      },
      generateFullNameFromCourseUnit: function (courseUnitRealisation, courseUnit) {
        if (courseUnitRealisation && courseUnit) {
          var fullName = {};
          var courseUnitName = courseUnit.name;
          courseUnitName = !courseUnitName ? courseUnitRealisation.nameSpecifier : courseUnitName;
          courseUnitName = !courseUnitName ? {} : courseUnitName;
          var name = courseUnitRealisation.name;
          name = !name ? {} : name;
          var activityPeriodName = activityPeriodAsText(courseUnitRealisation);
          var fallbackName;
          var fallbackNameSpecifier;
          _.forEach(localeService.supportedLocales, function (locale) {
            if (!fallbackNameSpecifier && courseUnitName[locale] && courseUnitName[locale].trim().length > 0) {
              fallbackNameSpecifier = courseUnitName[locale];
            }
            if (!fallbackName && name[locale] && name[locale].trim().length > 0) {
              fallbackName = name[locale];
            }
          });
          fallbackName = fallbackName ? fallbackName : '';
          fallbackNameSpecifier = fallbackNameSpecifier ? fallbackNameSpecifier : '';
          _.forEach(localeService.supportedLocales, function (locale) {
            var nameSpecifierForLocale = courseUnitName[locale];
            nameSpecifierForLocale = nameSpecifierForLocale && nameSpecifierForLocale.trim().length > 0 ? nameSpecifierForLocale : fallbackNameSpecifier;
            nameSpecifierForLocale += nameSpecifierForLocale.length === 0 ? '' : ', ';
            var nameForLocale = name[locale];
            nameForLocale = nameForLocale && nameForLocale.trim().length > 0 ? nameForLocale : fallbackName;
            var activityPeriodForLocale = activityPeriodName[locale];
            activityPeriodForLocale = activityPeriodForLocale ? ' ' + activityPeriodForLocale : '';
            fullName[locale] = _.trim(nameSpecifierForLocale + nameForLocale + activityPeriodForLocale);
          });
          return fullName;
        } else {
          return this.generateFullName(courseUnitRealisation);
        }
      }
    };
  }
})();