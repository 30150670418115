import { Injectable } from '@angular/core';
import { EntityState, EntityStore, getIDType as ID, QueryEntity, StoreConfig } from '@datorama/akita';
import { HttpConfig, NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { OtmId, Plan } from 'common-typescript/types';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

import { EntityService } from './entity.service';

const CONFIG = {
    ENDPOINTS: {
        backend: '/osuva/api',
    },
};

@StaticMembers<DowngradedService>()
@Injectable({ providedIn: 'root' })
@NgEntityServiceConfig({ baseUrl: CONFIG.ENDPOINTS.backend })
export class PlanEntityService extends EntityService<PlanState> {

    static downgrade: ServiceDowngradeMappings = {
        dependencies: [],
        moduleName: 'sis-components.service.planEntityService',
        serviceName: 'planEntityService',
    };

    constructor() {
        super(PlanStore, PlanQuery);
    }

    getByUserIdAndEducationIdAndLearningOpportunityId(educationId: OtmId, userId: OtmId, learningOpportunityId: OtmId): Observable<Plan[]> {
        return this.getHttp().get<Plan[]>(`${this.baseUrl}/plans/for-user-and-education`,
                                          {
                                              params: {
                                                  userId,
                                                  educationId,
                                                  learningOpportunityId,
                                              },
                                          }).pipe(tap(plans => this.store.upsertMany(plans)));
    }

    getMyPlans(): Observable<Plan[]> {
        return this.getHttp().get<Plan[]>(`${this.baseUrl}/my-plans`)
            .pipe(
                tap((plans) => this.store.set(plans)),
                switchMap(() => this.selectAll()),
            );
    }

    // Override getById because at the moment normal getByIds end point is not supported by osuva
    getById(id: ID<PlanState>, bypassStore: boolean = false, config: HttpConfig = {}): Observable<Plan> {
        return this.get(id);
    }

    /**
     * Used by students to mark a plan as primary. Uses the /my-plans aka update -endpoint.
     * The plan is updated and all the plans are fetched again bypassing cache, because backend unset previous primary plan.
     * The updated plan is selected and returned.
     *
     * @param planId The id of the plan that should be marked as primary.
     * @param plan The plan that should be marked as primary.
     */
    markAsPrimaryAsStudent(planId: OtmId, plan: Plan): Observable<Plan> {
        const newPlan = { ...plan, primary: true };
        return this.getHttp().put<Plan>(`${this.baseUrl}/my-plans/${planId}/`, newPlan)
            .pipe(
                switchMap((updatedPlan: Plan) => this.getMyPlans().pipe(
                    switchMap(() => this.query.selectEntity(updatedPlan.id)),
                )),
            );
    }

    /**
     * Used by students to create a new plan. Uses the /my-plans -endpoint.
     *
     * @param plan The new plan that should be created.
     */
    createMyPlan(plan: Plan): Observable<Plan> {
        return this.getHttp().post<Plan>(`${this.baseUrl}/my-plans`, plan)
            .pipe(this.upsertAndSwitchToStoreObservable());
    }

    /**
     * Used by students to update a plan (/my-plans -endpoints).
     * @param plan The updated plan that should be saved.
     */
    updateMyPlan(plan: Plan): Observable<Plan> {
        return this.getHttp().put<Plan>(`${this.baseUrl}/my-plans/${plan.id}`, plan)
            .pipe(this.upsertAndSwitchToStoreObservable());
    }

    /**
     * Used by students to delete a plan (/my-plans -endpoints). Sends a DELETE REQUEST and
     * removes the plan from the store on success.
     *
     * @param id The id of the plan that should be deleted.
     */
    deleteMyPlan(id: OtmId): Observable<void> {
        return this.getHttp().delete<void>(`${this.baseUrl}/my-plans/${id}`)
            .pipe(tap(() => this.store.remove(id)));
    }
}

type PlanState = EntityState<Plan, OtmId>;

@StoreConfig({ name: 'plans' })
class PlanStore extends EntityStore<PlanState> {}

class PlanQuery extends QueryEntity<PlanState> {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(store: PlanStore) {
        super(store);
    }
}
