import { Inject, Injectable } from '@angular/core';
import { EventSourceFuncArg } from '@fullcalendar/core';
import {
    CalendarEvent,
    FullCalendarCustomEvent,
} from 'common-typescript/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CALENDAR_EVENT_SERVICE } from 'sis-components/ajs-upgraded-modules';
import { CalendarEventEntityService } from 'sis-components/calendar/calendar-event-entity.service';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';

import { CalendarViewDateRange, EventInstance, FullCalendarEventService } from './full-calendar-event.service';

@Injectable({
    providedIn: 'root',
})
export class FullCalendarOwnEventService {

    constructor(
        private fullCalendarEventService: FullCalendarEventService,
        private calendarEventEntityService: CalendarEventEntityService,
        private appErrorHandler: AppErrorHandler,
        @Inject(CALENDAR_EVENT_SERVICE) private calendarEventService: any,
    ) { }

    getEvents(fetchInfo: EventSourceFuncArg): Observable<FullCalendarCustomEvent[]> {
        const calendarViewDateRange: CalendarViewDateRange = { start: fetchInfo.start, end: fetchInfo.end };

        return this.calendarEventEntityService.getCalendarEvents(fetchInfo.start, fetchInfo.end).pipe(
            this.appErrorHandler.defaultErrorHandler(),
            map((events: CalendarEvent[]) => this.calendarEventsToFullCalendarEvents(events, calendarViewDateRange)));
    }

    calendarEventsToFullCalendarEvents(calendarEvents: CalendarEvent[], calendarViewDateRange: CalendarViewDateRange): FullCalendarCustomEvent[] {
        return calendarEvents
            .map((calendarEvent: CalendarEvent) => this.calendarEventToFullCalendarEvent(calendarEvent, calendarViewDateRange))
            .flat();
    }

    calendarEventToFullCalendarEvent(calendarEvent: CalendarEvent, calendarViewDateRange: CalendarViewDateRange): FullCalendarCustomEvent[] {
        return this.calendarEventService.createEventInstances(calendarEvent)
            .filter((eventInstance: EventInstance) => !eventInstance.excluded)
            .filter((eventInstance: EventInstance) => this.fullCalendarEventService.isEventInstanceBetweenDateRange(eventInstance, calendarViewDateRange))
            .map((eventInstance: EventInstance): FullCalendarCustomEvent => ({
                start: eventInstance.start,
                end: eventInstance.end,
                className: 'own-calendar-event',
                extendedProps: {
                    eventTitle: calendarEvent.name,
                    type: 'OWN_CALENDAR_EVENT',
                    eventObject: calendarEvent,
                },
            }));
    }
}
