import { ChangeDetectorRef, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {
    COMPLETION_METHODS_2_TAB_ENABLED,
    CROSS_STUDY_FEATURES_ENABLED_FOR_STUDENT_DEFAULT,
    OPEN_UNIVERSITY_FEATURES_ENABLED_FOR_STUDENT_DEFAULT,
} from 'common-typescript/src/frontendFeatureToggleConstants';
import { UniversitySettings } from 'common-typescript/types';
import _ from 'lodash';
import { take } from 'rxjs';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { UniversityService } from 'sis-components/service/university.service';
import { Tab, TabNavigationComponent } from 'sis-components/tabs/tab-navigation/tab-navigation.component';

export const CourseUnitInfoTabIds = {
    BROCHURE: 'course-unit-info-brochure-tab',
    COMPLETION_METHODS: 'course-unit-info-completion-methods-tab',
    COMPLETION_METHODS_2: 'course-unit-info-completion-methods-2-tab',
    SUBSTITUTIONS: 'course-unit-info-substitutions-tab',
    OPEN_UNIVERSITY_OFFERING: 'course-unit-info-open-university-offering-tab',
    CROSS_STUDY: 'course-unit-info-cross-study-tab',
};
@Component({
    selector: 'app-course-unit-info-navigation',
    templateUrl: './course-unit-info-navigation.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CourseUnitInfoNavigationComponent implements OnInit {

    readonly defaultTabs: Tab[] = [
        { path: 'student.course-unit.brochure', controls: CourseUnitInfoTabIds.BROCHURE, id: CourseUnitInfoTabIds.BROCHURE, title: 'COURSE_UNIT_INFO_MODAL.TAB_HEADINGS.INFO' },
        { path: 'student.course-unit.completion-methods', controls: CourseUnitInfoTabIds.COMPLETION_METHODS, id: CourseUnitInfoTabIds.COMPLETION_METHODS, title: 'COURSE_UNIT_INFO_MODAL.TAB_HEADINGS.COMPLETION_METHODS' },
        { path: 'student.course-unit.substitutions', controls: CourseUnitInfoTabIds.SUBSTITUTIONS, id: CourseUnitInfoTabIds.SUBSTITUTIONS, title: 'COURSE_UNIT_INFO_MODAL.TAB_HEADINGS.SUBSTITUTIONS' },
    ];

    @ViewChild('tabNavigation') private tabNavigation: TabNavigationComponent;

    tabs: Tab[] = [];

    constructor(
        private ref: ChangeDetectorRef,
        private universityService: UniversityService,
        private appErrorHandler: AppErrorHandler,
    ) {}

    ngOnInit() {
        this.universityService.getCurrentUniversitySettings()
            .pipe(take(1),
                  this.appErrorHandler.defaultErrorHandler())
            .subscribe((settings: UniversitySettings) => {
                const showOpenUniversityTab = this.openUniversityFeaturesEnabledForStudent(settings);
                const showCrossStudyTab = this.crossStudyFeaturesEnabledForStudent(settings);
                const showCompletionMethods2 = this.completionMethods2TabEnabled(settings);
                this.setTabs(showOpenUniversityTab, showCrossStudyTab, showCompletionMethods2);
                // This prevents an NG0100 error due to changes being made outside a change detection cycle
                this.ref.detectChanges();
            });
    }

    get selectedTab(): Tab | null {
        return this.tabNavigation?.selected ?? null;
    }

    setTabs(showOpenUniversityTab: boolean, showCrossStudyTab: boolean, showCompletionMethods2: boolean) {
        // show open university, cross study and completion methods 2.0 tabs if these features are enabled for student in current university settings
        const tabsWithInsertions = [...this.defaultTabs];

        // Insert completion methods 2.0 tab after current completion methods tab
        if (showCompletionMethods2) {
            tabsWithInsertions.splice(2, 0, {
                path: 'student.course-unit.completion-methods-list',
                title: 'COURSE_UNIT_INFO_MODAL.TAB_HEADINGS.COMPLETION_METHODS_2',
                controls: CourseUnitInfoTabIds.COMPLETION_METHODS_2,
                id: CourseUnitInfoTabIds.COMPLETION_METHODS_2,
            });
        }
        this.tabs = [
            ...tabsWithInsertions,
            ...showOpenUniversityTab ? [{
                path: 'student.course-unit.open-university',
                title: 'COURSE_UNIT_INFO_MODAL.TAB_HEADINGS.OPEN_UNIVERSITY',
                controls: CourseUnitInfoTabIds.OPEN_UNIVERSITY_OFFERING,
                id: CourseUnitInfoTabIds.OPEN_UNIVERSITY_OFFERING,
            }] : [],
            ...showCrossStudyTab ? [{
                path: 'student.course-unit.cross-study',
                title: 'STUDIES.CROSS_STUDY.TITLE',
                controls: CourseUnitInfoTabIds.CROSS_STUDY,
                id: CourseUnitInfoTabIds.CROSS_STUDY,
            }] : [],
        ];
    }

    openUniversityFeaturesEnabledForStudent(settings: UniversitySettings) {
        return _.get(
            settings,
            'frontendFeatureToggles.openUniversityFeaturesEnabledForStudent',
            OPEN_UNIVERSITY_FEATURES_ENABLED_FOR_STUDENT_DEFAULT,
        );
    }

    crossStudyFeaturesEnabledForStudent(settings: UniversitySettings) {
        return _.get(
            settings,
            'frontendFeatureToggles.crossStudyFeaturesEnabledForStudent',
            CROSS_STUDY_FEATURES_ENABLED_FOR_STUDENT_DEFAULT,
        );
    }

    completionMethods2TabEnabled(settings: UniversitySettings) {
        return _.get(
            settings,
            'frontendFeatureToggles.completionMethods2TabEnabled',
            COMPLETION_METHODS_2_TAB_ENABLED,
        );
    }
}
