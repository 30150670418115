import { Injectable } from '@angular/core';
import { StudyTermLocator, TermRegistrationPeriod } from 'common-typescript/types';
import moment from 'moment/moment';
import { combineLatestWith, Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';

import { CurrentStudyTermService } from '../../service/current-study-term.service';
import { TermRegistrationPeriodService } from '../../service/term-registration-period.service';

@Injectable({ providedIn: 'root' })
export class RegistrationPeriodEndingService {
    /**
     * Checks if there is a current study term having registration periods, and if all periods have ended.
     *
     * In practice this means that for the fall study term, for which there is only one registration
     * period, emits true if a registration period has been defined and it has ended. However, for the spring
     * study term, for which there can be two registration periods (the "main" registration period around when
     * the study year starts, and an optional second registration period around when the study term changes),
     * if both registration periods have been defined, this will only emit `true` after both of those
     * periods have ended. I.e. if two registration periods have been defined for the spring study term, and a
     * student hasn't registered for the spring study term when the "main" registration period ends, the spring
     * term registration won't be considered as neglected.
     */
    readonly hasRegistrationPeriodEndedForCurrentStudyTerm$: Observable<boolean>;

    constructor(
        currentStudyTermService: CurrentStudyTermService,
        termRegistrationPeriodService: TermRegistrationPeriodService,
    ) {
        this.hasRegistrationPeriodEndedForCurrentStudyTerm$ = currentStudyTermService.currentStudyTermLocator$.pipe(
            combineLatestWith(termRegistrationPeriodService.termRegistrationPeriods$),
            map(([currentStudyTermLocator, termRegistrationPeriods]: [StudyTermLocator | null, readonly TermRegistrationPeriod[]]) => {
                if (!currentStudyTermLocator || !termRegistrationPeriods.length) {
                    return false;
                }

                const matchingPeriods: TermRegistrationPeriod[] = termRegistrationPeriods.filter(period => areLocatorsTruthyAndEqual(currentStudyTermLocator, period.term1) || areLocatorsTruthyAndEqual(currentStudyTermLocator, period.term2));
                if (!matchingPeriods.length) {
                    return false;
                }

                const now: moment.Moment = moment();
                return matchingPeriods.every(period => !!period.validityPeriod?.endDate && now.isSameOrAfter(period.validityPeriod.endDate, 'day'));
            }),
            distinctUntilChanged(),
            shareReplay({ bufferSize: 1, refCount: true }),
        );
    }
}

function areLocatorsTruthyAndEqual(a: StudyTermLocator | null, b: StudyTermLocator | null) {
    return !!a && !!b && a.studyYearStartYear === b.studyYearStartYear && a.termIndex === b.termIndex;
}
