import { Inject, Injectable } from '@angular/core';
import { EnrolmentGetRequestForOpenUniStudent, EnrolmentQuestionnaireAnswers, OpenUniversityCart, OpenUniversityProduct } from 'common-typescript/types';
import { from, Observable, of } from 'rxjs';
import { map, mergeMap, switchMap, take, toArray } from 'rxjs/operators';
import { COMMON_ENROLMENT_QUESTIONNAIRE_SERVICE } from 'sis-components/ajs-upgraded-modules';
import { convertAJSPromiseToNative } from 'sis-components/util/utils';

import { EnrolmentStudentService } from '../../../common/service/enrolment-student.service';
import { EnrichedAssItem, EnrichedEnrolment } from '../../open-university-enrolment-wizard/open-university-enrolment-wizard.component';

@Injectable({
    providedIn: 'root',
})
export class OpenUniversityEnrichedEnrolmentsService {

    constructor(@Inject(COMMON_ENROLMENT_QUESTIONNAIRE_SERVICE) private enrolmentQuestionnaireService: any,
                private enrolmentStudentService: EnrolmentStudentService) { }

    getExistingEnrolmentsForCartItem(assessmentItemsData: EnrichedAssItem[], openUniversityCart: OpenUniversityCart, openUniversityProduct: OpenUniversityProduct): Observable<EnrichedEnrolment[]> {
        const enrolmentGetRequestForOpenUniStudentList: EnrolmentGetRequestForOpenUniStudent[] = [];
        assessmentItemsData.forEach(assItem => {
            enrolmentGetRequestForOpenUniStudentList.push({
                assessmentItemId: assItem.id,
                courseUnitRealisationIds: assItem.courseUnitRealisations.map(cur => cur.id),
                openUniversityCartId: openUniversityCart.id,
                openUniversityCartItemId: openUniversityCart.items.find(item => item.openUniversityProductId === openUniversityProduct.id).localId,
            });
        });
        return this.enrolmentStudentService.getOpenUniversityEnrolments(enrolmentGetRequestForOpenUniStudentList)
            .pipe(
                switchMap((enrolments: EnrichedEnrolment[]) => this.findQuestionnaireAnswersWithEnrolmentId(enrolments)),
                take(1),
            );
    }

    private findQuestionnaireAnswersWithEnrolmentId(enrolments: EnrichedEnrolment[]): Observable<EnrichedEnrolment[]> {
        if (enrolments.length === 0) {
            return of([]);
        }

        return from(enrolments)
            .pipe(
                mergeMap(enrolment => this.findQuestionnaireAnswers(enrolment)),
                toArray(),
            );
    }

    private findQuestionnaireAnswers(enrolment: EnrichedEnrolment): Observable<EnrichedEnrolment> {
        return from(convertAJSPromiseToNative(this.enrolmentQuestionnaireService.findOpenUniversityQuestionnaireAnswersByEnrolmentId(enrolment.id)))
            .pipe(
                map((questionnaireResponse: EnrolmentQuestionnaireAnswers) =>
                // Shallow copy openUniStudentEnrolments so akita wont give "object is not extensible" error.
                    Object.assign({}, enrolment, { enrolmentQuestionnaireAnswers: questionnaireResponse }),
                ),
            );
    }
}
