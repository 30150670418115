<div>
  <span class="additional-data">
    <span><sis-mark-string [target]="result.code" [query]="textQuery"/></span>
    <span *ngIf="searchTarget === 'CourseUnit' && !isHomeUniversityResult(result)">
      <span *ngFor="let universityOrgId of getResultUniversityOrgIds(result)">
        <sis-tiny-badge [content]="universityOrgId | organisation | async | entityName | localizedString" [variant]="'primary'"></sis-tiny-badge>
      </span>
    </span>
  </span>
</div>
