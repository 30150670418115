import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import {
    OrganisationRoleShare,
} from 'common-typescript/types';
import _ from 'lodash';
import {
    ComponentDowngradeMappings,
    DowngradedComponent,
    StaticMembers,
} from 'sis-common/types/angular-hybrid';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-responsible-organisation-info',
    templateUrl: './responsible-organisation-info.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class ResponsibleOrganisationInfoComponent implements OnChanges {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.responsibleOrganisationInfo',
        directiveName: 'sisResponsibleOrganisationInfo',
    };

    hasCoordinatingOrganisations = false;

    COORDINATING_ORGANISATION_ROLE_URN = 'urn:code:organisation-role:coordinating-organisation';
    RESPONSIBLE_ORGANISATION_ROLE_URN = 'urn:code:organisation-role:responsible-organisation';

    @Input() organisations: OrganisationRoleShare[] = [];

    ngOnChanges() {
        this.hasCoordinatingOrganisations = _.some(
            this.organisations,
            { roleUrn: this.COORDINATING_ORGANISATION_ROLE_URN },
        );
    }

}
