<div class="badge" *ngIf="isOk">
    <sis-icon icon="rule" color="white"></sis-icon>
    <span translate>PLAN_EDIT.SELECTION_ASSISTANT.RULE_HEADER.SELECTIONS_COMPLETE</span>
</div>
<div class="selected float-end">
    <span
            translate="PLAN_EDIT.SELECTION_ASSISTANT.RULE_HEADER.COUNT_STATUS"
            [translateParams]="{ from: selected, to: require | translateRange }">
    </span>
</div>
<div class="badge" *ngIf="!isOk && targetRange !== null">
    <sis-icon icon="rule" color="white"></sis-icon>
    <span
            *ngIf="tooFewSelected"
            translate="PLAN_EDIT.SELECTION_ASSISTANT.RULE_HEADER.ITEMS_TO_ADD"
            [translateParams]="{range:  addRange | translateRange }">
    </span>
    <span
            *ngIf="tooManySelected"
            translate="PLAN_EDIT.SELECTION_ASSISTANT.RULE_HEADER.ITEMS_TO_REMOVE"
            [translateParams]="{ range: removeRange | translateRange }">
    </span>
</div>
