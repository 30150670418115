import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OtmId, Thesis, ThesisSearchRequest, Urn } from 'common-typescript/types';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

const CONFIG = {
    ENDPOINTS: {
        backend: '/ori/api/thesis',
    },
};

@StaticMembers<DowngradedService>()
@Injectable({ providedIn: 'root' })
export class ThesisService {

    constructor(private http: HttpClient) {
    }

    static downgrade: ServiceDowngradeMappings = {
        dependencies: [],
        moduleName: 'sis-components.service.thesisService',
        serviceName: 'thesisService',
    };

    private static toQueryParams(searchParams: Partial<ThesisSearchRequest>): { [key: string]: string | string[] } {
        if (_.isEmpty(searchParams)) {
            return {};
        }

        return _.omitBy(
            {
                personId: searchParams.personId,
                attainmentId: searchParams.attainmentId,
                thesisState: searchParams.thesisState,
            },
            _.isEmpty,
        );
    }

    put(thesis: Thesis): Observable<Thesis> {
        return this.http.put(`${CONFIG.ENDPOINTS.backend}/${thesis.id}`, thesis) as Observable<Thesis>;
    }

    save(thesis: Thesis): Observable<Thesis> {
        return this.http.post(`${CONFIG.ENDPOINTS.backend}`, thesis) as Observable<Thesis>;
    }

    search(searchParams: ThesisSearchRequest): Observable<Thesis[]> {
        return this.http.get(`${CONFIG.ENDPOINTS.backend}/search`, { params: ThesisService.toQueryParams(searchParams) }) as Observable<Thesis[]>;
    }

    delete(thesisId: OtmId): Observable<void> {
        return this.http.delete<void>(`${CONFIG.ENDPOINTS.backend}/${thesisId}`);
    }

    isAmkThesis(thesisTypeUrn: Urn): boolean {
        return ['urn:code:thesis-type:amk-bachelors-thesis', 'urn:code:thesis-type:amk-masters-thesis'].includes(thesisTypeUrn);
    }
}
