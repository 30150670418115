<div class="ssg-name">
    {{ studySubGroup.name | localizedString }}
    <span *ngIf="studySubGroup.cancelled"
          class="tiny-badge danger"
          translate="SIS_COMPONENTS.COURSE_UNIT_REALISATION_STRUCTURE.CANCELLED">
  </span>
</div>

<span class="teacher-time-header"
      translate="SIS_COMPONENTS.COURSE_UNIT_REALISATION_STRUCTURE.TEACHERS_TITLE"></span>

<div class="teachers">
    <span class="teacher-name" *ngFor="let teacherId of studySubGroup.teacherIds">
        <sis-icon icon="person"></sis-icon>
        <span class="teacher">{{ teacherId | publicPerson | async | personFullName }}</span>
    </span>
    <span *ngIf="!studySubGroup.teacherIds || studySubGroup.teacherIds.length === 0" class="no-teacher">
        <sis-icon icon="person"></sis-icon>
        <span class="teacher" translate="SIS_COMPONENTS.COURSE_UNIT_REALISATION_STRUCTURE.NO_TEACHER"></span>
    </span>

    <div *ngIf="studySubGroupHasTeacherOverrides" class="irregularities">
        (<span translate="SIS_COMPONENTS.COURSE_UNIT_REALISATION_STRUCTURE.IRREGULAR_TEACHERS"></span>)
    </div>
</div>

<div class="study-sub-group-info">
    <span class="teacher-time-header"
        translate="SIS_COMPONENTS.COURSE_UNIT_REALISATION_STRUCTURE.STUDY_EVENTS_TITLE"></span>

<div *ngIf="!sortedStudyEvents || sortedStudyEvents.length === 0"
     class="no-events"
     translate="SIS_COMPONENTS.COURSE_UNIT_REALISATION_STRUCTURE.NO_STUDY_EVENT">
</div>
    <div *ngFor="let studyEvent of sortedStudyEvents">
        <sis-study-sub-group-structure-study-event [studyEvent]="studyEvent" [language]="language" [teacherIds]="studySubGroup.teacherIds"></sis-study-sub-group-structure-study-event>
    </div>
</div>
