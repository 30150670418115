<div class="guidance-div">
  <span class="sis-guidance asterisk-required-guidance" aria-hidden="true">
    <sis-icon [icon]="'required'"></sis-icon>
    {{ 'OPEN_UNIVERSITY_CART.WIZARD.CONTACT_INFORMATION.REQUIRED_FIELD' | translate }}
  </span>
</div>
<form [formGroup]="form" *ngIf="form" autocomplete="on">
  <div class="row buyer-details">
    <div class="col-sm-4">
      <div class="form-group">
        <sis-text-field [ngClass]="{'cart-error-class': form.controls.firstName.invalid}"
                        [control]="form.controls.firstName"
                        [id]="'fname'"
                        [label]="'OPEN_UNIVERSITY_CART.WIZARD.CONTACT_INFORMATION.FIRST_NAME' | translate"
                        [autocomplete]="'given-name'"
                        [attr.tabindex]="-1">
        </sis-text-field>
      </div>
    </div>
    <div class="col-sm-5">
      <div class="form-group">
        <sis-text-field [ngClass]="{'cart-error-class': form.controls.lastName.invalid}"
                        [control]="form.controls.lastName"
                        [id]="'lname'"
                        [label]="'OPEN_UNIVERSITY_CART.WIZARD.CONTACT_INFORMATION.LAST_NAME' | translate"
                        [autocomplete]="'family-name'"
                        [attr.tabindex]="-1">
        </sis-text-field>
      </div>
    </div>
    <div class="col-sm-5">
      <div class="form-group">
        <sis-text-field [ngClass]="{'cart-error-class': form.controls.email.invalid}"
                        [control]="form.controls.email"
                        [id]="'email'"
                        [label]="'OPEN_UNIVERSITY_CART.WIZARD.CONTACT_INFORMATION.EMAIL' | translate"
                        [autocomplete]="'email'"
                        [attr.tabindex]="-1">
        </sis-text-field>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="form-group terms">
        <div *ngIf="form.controls.acceptTerms.invalid && form.controls.acceptTerms.touched"
             class="accept-terms-error cart-error-class"
             data-cy="accept-terms-error"
             [attr.tabindex]="-1">
          <sis-contextual-notification variant="danger">
            {{ 'OPEN_UNIVERSITY_CART.WIZARD.CONTACT_INFORMATION.ACCEPT_TERMS_MISSING_NOTIFICATION' | translate }}
          </sis-contextual-notification>
        </div>
        <sis-form-checkbox [control]="form.controls.acceptTerms" [name]="'terms-of-delivery-checkbox'" data-cy="terms-of-delivery">
          <span class="sis-form-checkbox-label-content">
            {{ 'OPEN_UNIVERSITY_CART.WIZARD.CONTACT_INFORMATION.ACCEPT_TERMS_PART_1' | translate }}
            <fudis-link [externalLink]="termsOfDeliveryUrl | localizedString" [title]="'OPEN_UNIVERSITY_CART.WIZARD.CONTACT_INFORMATION.ACCEPT_TERMS_PART_2_LINK' | translate"></fudis-link>
            {{ 'OPEN_UNIVERSITY_CART.WIZARD.CONTACT_INFORMATION.ACCEPT_TERMS_PART_3' | translate }}
          </span>
        </sis-form-checkbox>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="form-group">
        <sis-contextual-notification variant="light">
          {{ 'OPEN_UNIVERSITY_CART.WIZARD.CONTACT_INFORMATION.GUIDANCE_TEXT' | translate }}
        </sis-contextual-notification>
      </div>
    </div>
  </div>
</form>
