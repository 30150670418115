import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { PersonDataChange, PrivatePerson } from 'common-typescript/types';
import { filter, sortBy } from 'lodash';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { PrivatePersonEntityService } from 'sis-components/service/private-person-entity.service';

@Component({
    selector: 'app-basic-info-personal-info-history',
    templateUrl: './basic-info-personal-info-history.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasicInfoPersonalInfoHistoryComponent implements OnInit {

    @Input() person: PrivatePerson;

    isHistoryVisible = false;
    isHistoryPICVisible = false;
    sortedHistory$: Observable<PersonDataChange[]>;

    constructor(
        private appErrorHandler: AppErrorHandler,
        private privatePersonService: PrivatePersonEntityService,
    ) {
    }

    ngOnInit(): void {
        this.setSortedHistory();
    }

    private setSortedHistory(): void {
        this.sortedHistory$ = this.privatePersonService.getFullPersonDataChanges(this.person.id)
            .pipe(
                take(1),
                map((dataChanges: PersonDataChange[]) => this.filterAndSortPersonDataChanges(dataChanges)),
                this.appErrorHandler.defaultErrorHandler(),
            );
    }

    private filterAndSortPersonDataChanges(dataChanges: PersonDataChange[]) {
        return sortBy(filter(dataChanges, { correction: false }), ['validUntil', 'modified']);
    }
}
