<div class="row tuition-fee-obligation-periods" *ngIf="tuitionFeeObligationPeriods?.length">
  <div class="col-12">
    <hr class="sis-hr sis-mb-lg" aria-hidden="true"/>
    <h5 class="fudis-heading__md">{{ 'STUDY_RIGHTS.TUITION_FEE_OBLIGATION_PERIODS.TUITION_FEE_OBLIGATION' | translate }}</h5>
    <div class="row">
      <div class="col-12 col-md-6 col-lg-5"
           *ngFor="let period of tuitionFeeObligationPeriods; let index = index; let count = count; odd as isOdd"
           [ngClass]="{'offset-lg-1': isOdd}">
        <sis-basic-grouping-panel [level]="6"
                                  [heading]="('STUDY_RIGHTS.TUITION_FEE_OBLIGATION_PERIODS.TUITION_FEE_OBLIGATION_PERIOD' | translate) + (count > 1 ? (' ' + (index + 1)) : '')">
          <sis-tiny-badge *ngIf="period.valid|dateRangeOngoing" [variant]="'success'" class="header-badge">
            {{ 'STUDY_RIGHTS.TUITION_FEE_OBLIGATION_PERIODS.STATE.ACTIVE' | translate }}
          </sis-tiny-badge>
          <sis-tiny-badge *ngIf="period.valid|dateRangeFuture" [variant]="'accent'" class="header-badge">
            {{ 'STUDY_RIGHTS.TUITION_FEE_OBLIGATION_PERIODS.STATE.FUTURE' | translate:{date: period.valid.startDate | localDateFormat} }}
          </sis-tiny-badge>
          <sis-tiny-badge *ngIf="period.valid|dateRangePast" [variant]="'secondary'" class="header-badge">
            {{ 'STUDY_RIGHTS.TUITION_FEE_OBLIGATION_PERIODS.STATE.PAST' | translate }}
          </sis-tiny-badge>

          <div class="sis-mb-sm" *ngIf="period.exempt">
            <sis-contextual-notification variant="success">
              {{ 'STUDY_RIGHTS.TUITION_FEE_OBLIGATION_PERIODS.EXEMPT_NOTIFICATION' | translate }}
            </sis-contextual-notification>
          </div>

          <sis-description-list>
            <dl>
              <ng-template #listItem>
                <dt class="sis-label">{{ 'STUDY_RIGHTS.TUITION_FEE_OBLIGATION_PERIODS.VALID' | translate }}</dt>
                <dd>{{ period.valid | localDateRange }}</dd>
              </ng-template>
              <ng-template #listItem>
                <dt class="sis-label">{{ 'STUDY_RIGHTS.TUITION_FEE_OBLIGATION_PERIODS.TUITION_FEE' | translate }}</dt>
                <dd>{{ period.tuitionFee | currency:'EUR' }}{{ 'STUDY_RIGHTS.TUITION_FEE_OBLIGATION_PERIODS.PER_YEAR' | translate }}</dd>
              </ng-template>
            </dl>
          </sis-description-list>

          <sis-localized-string *ngIf="period.additionalInfo" [data]="period.additionalInfo">
            <h6 class="sis-label">{{ 'STUDY_RIGHTS.TUITION_FEE_OBLIGATION_PERIODS.ADDITIONAL_INFO_LABEL' | translate }}</h6>
          </sis-localized-string>
        </sis-basic-grouping-panel>
      </div>
    </div>
  </div>
</div>
