import { ChangeDetectorRef, Component, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Attainment, CreditRange, Module, OtmId } from 'common-typescript/types';
import { NEVER, Observable, Subscription } from 'rxjs';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { AppErrorHandler } from '../../error-handler/app-error-handler';
import { ModuleEntityService } from '../../service/module-entity.service';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-module-box',
    templateUrl: './module-box.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ModuleBoxComponent implements OnDestroy {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.module.moduleBox',
        directiveName: 'sisModuleBox',
    };

    /**
     * You should define only one of module, moduleId and moduleGroupId. Anything else does not make sense. The resolved module
     * will be cleared if moduleId or moduleGroupId is changed to undefined.
     */
    @Input() module?: ModuleWithCredits;

    @Input() set moduleId(moduleId: OtmId) {
        this.setSubscription(moduleId, id => this.moduleEntityService.getById(id));
    }

    @Input() set moduleGroupId(moduleGroupId: OtmId) {
        this.setSubscription(moduleGroupId, id => this.moduleEntityService.getByGroupId(id));
    }

    @Input() attainment: Attainment;

    // css class for root div, possibly a color
    @Input() categoryCssClass?: string;
    moduleSubscription: Subscription = NEVER.subscribe();

    constructor(
        private appErrorHandler: AppErrorHandler,
        private moduleEntityService: ModuleEntityService,
        private ref: ChangeDetectorRef,
    ) {
    }

    ngOnDestroy() {
        this.moduleSubscription.unsubscribe();
    }

    private setSubscription(id: OtmId, callback: (id: OtmId) => Observable<Module>) {
        this.moduleSubscription.unsubscribe();
        if (!id) {
            this.module = null;
            return;
        }
        this.moduleSubscription = callback(id)
            .pipe(this.appErrorHandler.defaultErrorHandler())
            .subscribe((module: Module) => {
                this.module = module;
                // manually fire change detection as it seemed slow when using in angularjs
                this.ref.detectChanges();
            },
            );
    }
}

/**
 * It seemed easier to define this interface than to use obscure "DPSMBase".
 */
interface ModuleWithCredits extends Module {
    targetCredits?: CreditRange;
}
