import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StudyRight, StudyRightExtension, StudyRightExtensionApplicationDecision } from 'common-typescript/types';
import { take } from 'rxjs/operators';
import { ModalService } from 'sis-common/modal/modal.service';

import { AppErrorHandler } from '../../error-handler/app-error-handler';
import { ApplicationDecisionEntityService } from '../../service/application-decision-entity.service';

export interface ExtensionInfoModalValues {
    applicationViewUrl: string;
    studyRightExtension: StudyRightExtension;
    studyRight: StudyRight;
}

@Component({
    selector: 'sis-extension-info-modal',
    templateUrl: './extension-info-modal.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ExtensionInfoModalComponent implements OnInit {
    extension: StudyRightExtension;
    applicationDecision: StudyRightExtensionApplicationDecision;

    constructor(
        @Inject(ModalService.injectionToken) public values: ExtensionInfoModalValues,
        private activeModal: NgbActiveModal,
        private applicationDecisionEntityService: ApplicationDecisionEntityService,
        private appErrorHandler: AppErrorHandler,
    ) { }

    ngOnInit(): void {
        this.extension = this.values.studyRightExtension;
        if (this.extension?.applicationId) {
            this.applicationDecisionEntityService.getById(this.extension.applicationId)
                .pipe(
                    take(1),
                    this.appErrorHandler.defaultErrorHandler(),
                )
                .subscribe({
                    next: (decision: StudyRightExtensionApplicationDecision) => this.applicationDecision = decision,
                });
        }
    }

    close(): void {
        this.activeModal.close();
    }
}
