import { Injectable } from '@angular/core';
import { EntityState, EntityStore, Order, QueryConfig, QueryEntity, StoreConfig } from '@datorama/akita';
import { NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { dateUtils } from 'common-typescript';
import { OtmId, TermRegistration, TuitionFeeObligationPeriod } from 'common-typescript/types';
import _ from 'lodash';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

import { getStudyTermEndDate, getStudyTermStartDate } from '../study-terms/study-year-utils';

import { EntityService } from './entity.service';
import { SisuDataLoader } from './SisuDataLoader';

const CONFIG = {
    ENDPOINTS: {
        backend: '/ori/api',
    },
};

@StaticMembers<DowngradedService>()
@Injectable({ providedIn: 'root' })
@NgEntityServiceConfig({ baseUrl: CONFIG.ENDPOINTS.backend })
export class TuitionFeeObligationPeriodEntityService extends EntityService<TuitionFeeObligationPeriodsState> {

    static downgrade: ServiceDowngradeMappings = {
        dependencies: [],
        moduleName: 'sis-components.service.tuitionFeeObligationPeriodService',
        serviceName: 'tuitionFeeObligationPeriodService',
    };

    constructor() {
        super(TuitionFeeObligationPeriodStore, TuitionFeeObligationPeriodQuery);
        this.studyRightIdLoader = new SisuDataLoader<OtmId, TuitionFeeObligationPeriod, TuitionFeeObligationPeriod[]>(
            {
                getByIdsCall: studyRightIds => this.createGetByStudyRightIdsCall(studyRightIds),
                successEntitiesCallback: periods => this.store.upsertMany(periods),
                resultExtractor: (studyRightId, periods) => periods.filter(period => period.studyRightId === studyRightId),
            },
        );
    }

    private readonly studyRightIdLoader: SisuDataLoader<OtmId, TuitionFeeObligationPeriod, TuitionFeeObligationPeriod[]>;

    findForStudyRight(studyRightId: OtmId): Observable<TuitionFeeObligationPeriod[]> {
        if (!studyRightId) {
            return of([]);
        }
        return this.studyRightIdLoader.load(studyRightId)
            .pipe(switchMap(() => this.selectAll({ filterBy: period => period.studyRightId === studyRightId })));
    }

    private createGetByStudyRightIdsCall(studyRightIds: OtmId[]): Observable<TuitionFeeObligationPeriod[]> {
        return this.getHttp().get<TuitionFeeObligationPeriod[]>(
            this.api,
            { params: { studyRightId: studyRightIds.toString() } },
        );
    }

    /**
     * Returns true, if the `tuitionFeePaymentState` of the given registration is not `PAID`, and the given array of
     * tuition fee obligation periods contains a non-exempt period whose validity overlaps with the study term of the
     * given registrations. Otherwise, returns false.
     */
    isTuitionFeePaymentMissing(registration: TermRegistration, tuitionFeeObligationPeriods: TuitionFeeObligationPeriod[]): boolean {
        return this.hasTuitionFeeObligation(registration, tuitionFeeObligationPeriods) &&
            registration.tuitionFeePaymentState !== 'PAID';
    }

    /**
     * Returns true if the given tuition fee obligation periods contain a non-exempt period whose validity overlaps
     * with the study term of the given registration. Otherwise, returns false.
     */
    hasTuitionFeeObligation(registration: TermRegistration, tuitionFeeObligationPeriods: TuitionFeeObligationPeriod[]): boolean {
        if (!registration || _.isEmpty(tuitionFeeObligationPeriods)) {
            return false;
        }
        const studyTermStart = getStudyTermStartDate(registration.studyTerm);
        const studyTermEnd = getStudyTermEndDate(registration.studyTerm);
        return tuitionFeeObligationPeriods
            .filter(period => !period.exempt)
            .some(({ valid }) =>
                dateUtils.dateTimeRangesOverlapNullsAsInfinity(studyTermStart, studyTermEnd, valid.startDate, valid.endDate, 'day'));
    }
}

type TuitionFeeObligationPeriodsState = EntityState<TuitionFeeObligationPeriod, OtmId>;

@StoreConfig({ name: 'tuition-fee-obligation-periods' })
class TuitionFeeObligationPeriodStore extends EntityStore<TuitionFeeObligationPeriodsState> {}

@QueryConfig({
    sortBy: (a, b) => (_.get(a, 'valid.startDate') || '').localeCompare(_.get(b, 'valid.startDate') || ''),
    sortByOrder: Order.ASC,
})
class TuitionFeeObligationPeriodQuery extends QueryEntity<TuitionFeeObligationPeriodsState> {
    constructor(protected store: TuitionFeeObligationPeriodStore) {
        super(store);
    }
}
