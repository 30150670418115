import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Signal,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { TransitionService } from '@uirouter/angular';
import { RawParams, StateService, UIRouterGlobals } from '@uirouter/core';
import { AuthService } from 'sis-common/auth/auth-service';
import { EnvironmentService } from 'sis-common/environmentService/environment.service';

import { NavUtilsService } from '../nav-utils/nav-utils.service';

export interface MainNavigationItem {
    /** Unique id of the link */
    linkId: string;
    /** Visible label of the link */
    translationKey: string;
    /** If true, the link will only be visible for authenticated users */
    loginRequired?: boolean;
    /** The name of the state where the link points to. Alternative to `toUrl`. */
    toState?: string;
    /** Additional state parameters for `toState` */
    toParams?: any;
    /** A URL the link points to. Alternative to `toState`. */
    toUrl?: string;
}

@Component({
    selector: 'sis-top-navigation',
    templateUrl: './top-navigation.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopNavigationComponent implements OnDestroy, OnInit {

    /** The target router state the frontpage link. Alternative to {@link homeUrl}. */
    @Input() homeState: string;
    /** The target URL the frontpage link. Alternative to {@link homeState}. */
    @Input() homeUrl: string;

    @Input() mainNavigationItems: MainNavigationItem[];

    /** View child for the component's left navigation wrapper div */
    @ViewChild('leftNavigation') leftNavigation: ElementRef;

    readonly authenticated: Signal<boolean>;
    activeIndex: number;
    mobileMenuOpen = false;
    mobileMenuToggled = false;
    frontendName: string;

    deregisterActiveUrlChangeHook: Function;

    constructor(authService: AuthService,
                private environmentService: EnvironmentService,
                private navUtilsService: NavUtilsService,
                private state: StateService,
                private transitionService: TransitionService,
                private changeDetectorRef: ChangeDetectorRef,
                private uiRouterGlobals: UIRouterGlobals,
    ) {
        this.authenticated = toSignal(authService.userLoggedIn$);
    }

    get focusMode() {
        return this.navUtilsService.focusMode;

    }

    ngOnInit() {
        this.frontendName = this.environmentService.frontendName;
        this.setActiveIndex(this.uiRouterGlobals.current.name);
        this.deregisterActiveUrlChangeHook = this.registerActiveUrlChangeHook();
    }

    ngOnDestroy() {
        this.deregisterActiveUrlChangeHook();
    }

    isValidState(state: string, params: RawParams = null) {
        return !!state && !!this.state.href(state, params);
    }

    registerActiveUrlChangeHook() {
        return this.transitionService.onSuccess({}, (transition) => {
            this.mobileMenuOpen = false;
            this.setActiveIndex(transition.to().name);
            this.changeDetectorRef.markForCheck();
        });
    }

    setActiveIndex(activePathName: string) {
        this.activeIndex = undefined;

        if (activePathName.includes('logged-in.frontpage') || activePathName.includes('.login')) {
            this.activeIndex = -1;
        } else if (activePathName.includes('student.logged-in.structure')) {
            // path changes from plan-select to structure before the transition updates
            this.activeIndex = 0;
        } else {
            this.mainNavigationItems?.forEach((item: any, index) => {
                if (activePathName.includes(item.toState)) {
                    this.activeIndex = index;
                }
            });
        }
    }

    toggleMobileMenu() {
        this.mobileMenuOpen = !this.mobileMenuOpen;
        this.mobileMenuToggled = true;
    }

    /** Close mobile navigation when next targeted element is not inside the mobile nav */
    blurMobileMenu(event: any) {
        if (this.mobileMenuOpen && !this.leftNavigation?.nativeElement.contains(event.relatedTarget)) {
            this.mobileMenuOpen = false;
            this.changeDetectorRef.markForCheck();
        }
    }

    /** Close open mobile navigation if targeted element is not inside the mobile nav, expect when the click event opens the nav */
    @HostListener('document:click', ['$event'])
    private offClickHandler(event: any) {
        if (this.mobileMenuOpen && !this.leftNavigation?.nativeElement.contains(event.target)) {
            if (!this.mobileMenuToggled) {
                this.mobileMenuOpen = false;
            }
            this.mobileMenuToggled = false;
            this.changeDetectorRef.markForCheck();
        }
    }
}
