import { Component, Input, ViewEncapsulation } from '@angular/core';
import { CustomModuleAttainment } from 'common-typescript/types';

@Component({
    selector: 'sis-custom-module-attainment-box',
    templateUrl: './custom-module-attainment-box.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CustomModuleAttainmentBoxComponent {
    @Input() attainment: CustomModuleAttainment;
    // css class for root div, possibly a color
    @Input() categoryCssClass?: string;
}
