<div class="menu-button {{color}}">
  <div class="dropdown" ngbDropdown #dropdown=ngbDropdown (openChange)="openChange.emit($event)">
    <button [disabled]="disabled"
            [id]="id"
            data-cy="menu-button"
            name="menu-button" type="button"
            [class]="buttonClasses || ('btn btn-sm btn-' + color + (naked ? ' btn-naked' : ' btn-hollow'))"
            ngbDropdownToggle
            [attr.aria-haspopup]="true"
            [attr.aria-label]="('ARIA_LABEL.ADDITIONAL_MENU' | translate)">
      <sis-icon icon="three-dots"></sis-icon>
    </button>

    <div ngbDropdownMenu [ngClass]="{'dropdown-menu-right': !placeLeft}">
      <div *ngIf="menuTitle" class="dropdown-header"><transloco [content]="menuTitle" /></div>
      <ng-container *ngFor="let option of options; trackBy: getOptionName">
        <div *ngIf="option.header" class="dropdown-header">
          <span translate>{{ option.name }}</span>
        </div>
          <ng-container *ngIf="!option.header && (!option.hide || !option.hide())">
            <button *ngIf="!option.disabled || !option.disabled()"
               ngbDropdownItem
               [attr.data-cy]="option.dataCy || 'menu-item'"
               [disabled]="option.disabled && option.disabled()"
               [ngClass]="option.class"
               (click)="onItemClick(option, dropdown, $event)"
               role="menuitem"
               type="button"
               class="menu-item"
               translate>{{option.name}}</button>
            <sis-tooltip *ngIf="option.disabled && option.disabled()"
                         [tooltipEnabled]="!!option.disabledTooltip"
                         [tooltipText]="option.disabledTooltip"
                         [appendToBody]="true"
                         tooltipTriggers="hover"
                         class="dropdown-item disabled menu-tooltip-holder">
              <span role="menuitem" class="sis-tooltip-trigger-content" [attr.aria-disabled]="true">
                {{option.name | translate}}
                <span class="d-block">{{'SIS_COMPONENTS.SELECT.OPTION_DISABLED' | translate}}</span>
              </span>
            </sis-tooltip>
          </ng-container>
      </ng-container>
    </div>
  </div>
</div>
