import angular from 'angular';
import angularTranslate from 'angular-translate';
export const formErrorTranslateFilterModule = 'sis-common.l10n.formErrorTranslateFilter';
(function () {
  formErrorTranslate.$inject = ["$translate"];
  angular.module(formErrorTranslateFilterModule, [angularTranslate]).filter('formErrorTranslate', formErrorTranslate);

  /**
   * @ngInject
   */
  function formErrorTranslate($translate) {
    // Too bad Angular does not provide us with details of the validation rule violated, such as the max length value.
    // So we cannot simply show that.
    // We could write our own max length directive and hook that up to the ng-maxlength, or we could use angular-formly.
    return function (input) {
      // In case of an error object, just take the first error key and translate that.
      // We could also enumerate all keys, but not needed at this time.
      if (_.isObject(input) && _.keys(input).length > 0) {
        var key = _.keys(input)[0];
        return $translate.instant("FORM_VALIDATION_ERROR." + key);
      } else {
        return "";
      }
    };
  }
})();