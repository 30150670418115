<div *ngIf="data$ | async">
  <!-- Active open studies list -->
  <ng-container [ngTemplateOutlet]="listTemplate"
                [ngTemplateOutletContext]="{
                  title: 'ENROLMENTS.ACTIVE_ENROLMENT_RIGHTS',
                  infoText: 'ENROLMENTS.ACTIVE_ENROLMENT_RIGHTS_CONTENT',
                  emptyText: 'ENROLMENTS.NO_ACTIVE_ENROLMENT_RIGHTS_INFO',
                  allCourseUnits: allActiveCourseUnits,
                  visibleCourseUnits: visibleActiveCourseUnits,
                  setVisibleCourseUnits: setVisibleActiveCourseUnits,
                }"/>
  <!-- Upcoming open studies list -->
  <ng-container [ngTemplateOutlet]="listTemplate"
                [ngTemplateOutletContext]="{
                  title: 'ENROLMENTS.UPCOMING_ENROLMENT_RIGHTS',
                  infoText: 'ENROLMENTS.UPCOMING_ENROLMENT_RIGHTS_CONTENT',
                  emptyText: 'ENROLMENTS.NO_UPCOMING_ENROLMENT_RIGHTS_INFO',
                  allCourseUnits: allUpcomingCourseUnits,
                  visibleCourseUnits: visibleUpcomingCourseUnits,
                  setVisibleCourseUnits: setVisibleUpcomingCourseUnits,
                }"/>

  <ng-template #listTemplate
    let-title="title"
    let-infoText="infoText"
    let-emptyText="emptyText"
    let-allCourseUnits="allCourseUnits"
    let-visibleCourseUnits="visibleCourseUnits"
    let-setVisibleCourseUnits="setVisibleCourseUnits"
  >
    <h3>{{ title | translate }}</h3>
    <p class="guidance">{{ infoText | translate }}</p>
    <div class="grid-container">
      <div *ngFor="let item of visibleCourseUnits" class="grid-item">
        <sis-study-box [level]="4"
                       [id]="item.courseUnit.id"
                       [name]="item.courseUnit.name"
                       [code]="item.courseUnit.code"
                       [credits]="item.courseUnit.credits">
          <sis-study-notification-box study-box=notifications>
            {{'ENROLMENTS.OPEN_COURSE_UNIT_VALIDITY_PERIOD' | translate: { date: item.enrolmentRight.validityPeriod | localDateRange } }}
          </sis-study-notification-box>
          <sis-study-notification-box study-box=notifications
                                      [primaryButtonText]="'ENROLMENTS.SELECT_REALISATIONS' | translate"
                                      (primaryClickAction)="openEnrolmentModal({courseUnit: item.courseUnit, enrolmentRight: item.enrolmentRight})">
          </sis-study-notification-box>
        </sis-study-box>
      </div>
    </div>
    <div *ngIf="allCourseUnits.length > defaultCourseUnitCount">
      <sis-show-more *ngIf="visibleCourseUnits.length > defaultCourseUnitCount" (click)="setVisibleCourseUnits(false)" placement="top" text="{{'ENROLMENTS.SHOW_LESS_COURSE_UNITS' | translate}}"></sis-show-more>
      <sis-show-more *ngIf="allCourseUnits.length > visibleCourseUnits.length"  (click)="setVisibleCourseUnits(true)" placement="bottom" text="{{'ENROLMENTS.SHOW_MORE_COURSE_UNITS' | translate: { count:  allCourseUnits.length - visibleCourseUnits.length } }}"></sis-show-more>
    </div>
    <sis-contextual-notification
      variant="light"
      *ngIf="visibleCourseUnits.length === 0"
      [content]="emptyText | translate">
    </sis-contextual-notification>
  </ng-template>
</div>
