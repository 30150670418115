
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { WorkflowState } from 'common-typescript/types';
import { BadgeVariant } from 'sis-components/badge/tiny-badge/tiny-badge.component';
import { STUDENT_WORKFLOW_STATE } from 'sis-components/model/student-workflow-constants';

/**
 * Use this component for student views only.
 * These variants do not necessarily apply for other views.
 */
@Component({
    selector: 'app-workflow-state-badge',
    templateUrl: './workflow-state-badge.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class WorkflowStateBadgeComponent {
    @Input() set state(state: WorkflowState) {
        this._variant = this.getBadgeVariant(state);
        this._state = state;
    }

    _variant: BadgeVariant;
    _state: WorkflowState;

    getBadgeVariant(state: WorkflowState) {
        switch (state) {
            case STUDENT_WORKFLOW_STATE.SUPPLEMENT_REQUESTED:
                return 'accent';
            case STUDENT_WORKFLOW_STATE.REQUESTED:
            case STUDENT_WORKFLOW_STATE.IN_HANDLING:
            case STUDENT_WORKFLOW_STATE.CANCELLED:
            case STUDENT_WORKFLOW_STATE.REVOKED:
                return 'secondary';
            case STUDENT_WORKFLOW_STATE.REJECTED:
                return 'danger';
            default:
                return 'success';
        }
    }
}
