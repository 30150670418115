import {
    AssessmentItemAttainment,
    Attainment,
    CourseUnitAttainment,
    CustomCourseUnitAttainment,
    CustomModuleAttainment,
    DegreeProgrammeAttainment,
    Grade,
    LocalDateString,
    LocalizedString,
    ModuleAttainment,
} from 'common-typescript/types';

export enum AttainmentTreeNodeType {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    Attainment = 'attainment',
    Group = 'group',
}

export interface AttainmentTreeNode {
    /**
     * Unique ID of this node.
     * Notice that this ID is generated,
     * so don't persist it between frontend sessions!
     */
    readonly id: string;
    readonly name: LocalizedString;
    readonly type: AttainmentTreeNodeType;
    readonly attainmentDate: LocalDateString | null;
    readonly registrationDate: LocalDateString | null;
    readonly childNodes?: readonly AttainmentTreeNode[];
}

export interface AttainmentTreeAttainmentNode extends AttainmentTreeNode {
    readonly type: AttainmentTreeNodeType.Attainment;
    readonly attainment: Attainment;
    readonly grade: Grade | null;
    readonly hasValidParentAttainment?: boolean;
    readonly code: string | null;
}

export interface AssessmentItemAttainmentNode extends AttainmentTreeAttainmentNode {
    readonly attainment: AssessmentItemAttainment;
    readonly hasValidParentAttainment: boolean;
}

export interface CourseUnitAttainmentNode extends AttainmentTreeAttainmentNode {
    readonly attainment: CourseUnitAttainment | CustomCourseUnitAttainment;
    readonly hasValidParentAttainment: boolean;
    readonly childNodes: readonly AssessmentItemAttainmentNode[];
}

export interface AttainmentTreeGroupNode extends AttainmentTreeNode {
    readonly type: AttainmentTreeNodeType.Group;
    readonly childNodes: readonly (ModuleAttainmentNode | CourseUnitAttainmentNode | AttainmentTreeGroupNode)[];
}

export interface ModuleAttainmentNode extends AttainmentTreeAttainmentNode {
    readonly attainment: ModuleAttainment | CustomModuleAttainment;
    readonly hasValidParentAttainment: boolean;
    readonly childNodes: readonly (ModuleAttainmentNode | CourseUnitAttainmentNode | AttainmentTreeGroupNode)[];
}

export interface DegreeProgrammeAttainmentNode extends AttainmentTreeAttainmentNode {
    readonly attainment: DegreeProgrammeAttainment;
    readonly childNodes: readonly (ModuleAttainmentNode | CourseUnitAttainmentNode | AttainmentTreeGroupNode)[];
}
