<div *ngIf="courseUnit"
     class="courseunit {{courseUnitStateObject?.colorCategoryCssClass}}">
    <div class="courseunit-top-row">
        <div class="credits-box">
            <sis-tooltip [tooltipText]="visibleCredits | creditRangeLong" [appendToBody]="true">
                <span class="credit-amount sis-tooltip-trigger-content">{{visibleCredits | creditRange:'SYMBOLS' : true }}</span>
            </sis-tooltip>
            <span class="credit-abbreviation" translate="SIS_COMPONENTS.CREDIT_RANGE.CREDITS_ABBR"></span>
        </div><!--
     --><div class="grade-box" *ngIf="courseUnitStateObject?.attainmentId">
        <sis-icon icon="achievement"></sis-icon>
        <span class="grade-text"> {{ courseUnitStateObject?.grade.abbreviation | localizedString }} </span>
    </div><!--
     -->
      <div *ngIf="substitutedCourseUnitId" class="substitution-indicator-box">
        <sis-tooltip
          [tooltipText]="'STUDIES.SUBSTITUTIONS.IS_SUBSTITUTING_COURSE_UNIT' | translate"
          [appendToBody]="true">
          <span class="sis-tooltip-trigger-content"><sis-icon icon="switch"></sis-icon></span>
        </sis-tooltip>
      </div>
      <div class="title-box">
        <span class="courseunit-code">
            <sis-button *ngIf="courseUnitInfoModalEnabled"
                        link="true"
                        (clicked)="openCourseUnitInfoModal()"
                        [ariaLabel]="courseUnit.code + ' ' + (courseUnit.name | localizedString) + ' ' + ('ARIA_LABEL.OPEN_INFO' | translate)">
              <sis-icon icon="brochure"></sis-icon>{{courseUnit.code}}
            </sis-button>
            <span *ngIf="!courseUnitInfoModalEnabled"><sis-icon icon="brochure"></sis-icon>{{courseUnit.code}}</span>
                <span *ngIf="courseUnit.documentState !== 'ACTIVE'"
                      class="tiny-badge"
                      [class.accent]="courseUnit.documentState === 'DRAFT'"
                      [class.danger]="courseUnit.documentState === 'DELETED'">
                    {{ ('VISIBLE_DOCUMENT_STATE.' + courseUnit.documentState) | translate }}
                </span>
            </span>
        <span class="courseunit-title">
              <sis-tooltip [tooltipText]="(courseUnit.name | localizedString)" [appendToBody]="true">
                <span class="sis-tooltip-trigger-content">{{ courseUnit.name | localizedString }}</span>
              </sis-tooltip>
            </span>
    </div><!--
   --><ng-content select="[course-unit-action]"></ng-content>
    </div>
    <div class="cu-notification-bar">
        <sis-tooltip *ngIf="courseUnitStateObject?.selectedParentModuleId && this.parentModule && courseUnitStateObject?.selectedParentModuleId !== this.parentModule?.id"
                [tooltipText]="'SIS_COMPONENTS.PLAN.COURSE_UNIT.IMPLICIT_SELECTION' | translate: { parentModuleName: planData?.modulesById[courseUnitStateObject?.selectedParentModuleId]?.name | localizedString }">
            <div class="cu-notification sis-tooltip-trigger-content">
                <span class="cu-notification-header"><sis-icon icon="alert-fill"></sis-icon></span><!--
           --><span class="cu-notification-content"><!--
                  --><span translate="SIS_COMPONENTS.PLAN.COURSE_UNIT.IMPLICIT_SELECTION" [translateParams]="{ parentModuleName: planData?.modulesById[courseUnitStateObject?.selectedParentModuleId]?.name | localizedString }"></span>
              </span>
            </div>
        </sis-tooltip>
        <sis-tooltip
                *ngIf="courseUnitStateObject?.invalidSelection && !courseUnitStateObject?.hasModuleContentApproval"
                [tooltipText]="'SIS_COMPONENTS.PLAN.COURSE_UNIT.INVALID_SELECTION' | translate">
            <div class="cu-notification sis-tooltip-trigger-content">
                <span class="cu-notification-header"><sis-icon icon="alert-fill"></sis-icon></span><!--
           --><span class="cu-notification-content"><!--
                  --><span translate="SIS_COMPONENTS.PLAN.COURSE_UNIT.INVALID_SELECTION"></span>
              </span>
            </div>
        </sis-tooltip>
        <sis-tooltip *ngIf="showExpiryWarning && courseUnitStateObject?.isAttainmentAboutToExpire"
                     [tooltipText]="('SIS_COMPONENTS.PLAN.COURSE_UNIT.EXPIRATION_IMMINENT' | translate) + ' ' + (courseUnitStateObject.attainmentExpiryDate | localDateFormat)">
            <div class="cu-notification sis-tooltip-trigger-content">
                <span class="cu-notification-header"><sis-icon icon="alert-fill"></sis-icon></span><!--
             --><span class="cu-notification-content">
                    <span translate="SIS_COMPONENTS.PLAN.COURSE_UNIT.EXPIRATION_IMMINENT"></span>
                {{courseUnitStateObject.attainmentExpiryDate | localDateFormat}}.
                </span>
            </div>
        </sis-tooltip>
        <sis-tooltip *ngIf="courseUnitStateObject?.invalidSelection && courseUnitStateObject?.hasModuleContentApproval"
                     [tooltipText]="'PLAN.INVALID_SELECTIONS.NOTIFICATION' | translate">
            <div class="cu-notification sis-tooltip-trigger-content">
                <span class="cu-notification-header"><sis-icon icon="alert-fill"></sis-icon></span><!--
             --><span class="cu-notification-content">
                    <span translate="PLAN.INVALID_SELECTIONS.NOTIFICATION"></span>
                </span>
            </div>
        </sis-tooltip>
        <sis-tooltip *ngIf="courseUnitStateObject?.invalidSelectionAccordingToModuleContentApproval"
                     [tooltipText]="'SIS_COMPONENTS.PLAN.NOTIFICATIONS.INVALID_ACCORDING_TO_MODULE_CONTENT_APPLICATION' | translate">
            <div class="cu-notification sis-tooltip-trigger-content">
                <span class="cu-notification-header"><sis-icon icon="alert-fill"></sis-icon></span><!--
             --><span class="cu-notification-content">
                    <span translate="SIS_COMPONENTS.PLAN.NOTIFICATIONS.INVALID_ACCORDING_TO_MODULE_CONTENT_APPLICATION"></span>
                </span>
            </div>
        </sis-tooltip>
        <div ng-transclude="notifications">
        </div>
    </div>
</div>
