commonShowModuleContentApplicationModalController.$inject = ["$q", "$scope", "commonStudentService", "defaultPromiseHandler", "STUDENT_APPLICATION_STATE", "moduleContentApplicationService", "commonApplicationDecisionService", "applicationDecisionJSDataModel", "workflowEntityService", "privatePersonBasicInfoEntityService", "personNameService"];
commonShowModuleContentApplicationModalService.$inject = ["modalService", "$q"];
import angular from 'angular';
import { ModalService } from 'sis-common/modal/modal.service.ts';
import { customStudyDraftSummaryModule } from 'sis-components/customStudyDraft/customStudyDraftSummary.component';
import { take, tap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { personNameServiceModule } from 'sis-components/service/personName.service';
import { WorkflowEntityService } from '../service/workflow-entity.service.ts';
import { PrivatePersonBasicInfoEntityService } from '../service/private-person-basic-info-entity.service.ts';
import commonShowModuleContentApplicationModalTpl from './commonShowModuleContentApplicationModal.tpl.html';
import { CommonShowModuleContentApplicationModalComponent } from './commonShowModuleContentApplicationModal.component.ts';
import { studentApplicationStateBadgeModule } from './studentApplicationStateBadge.dir';
import 'sis-common/auth/auth.module';
import 'sis-common/auth/auth.service';
import 'sis-components/date/filters/localDateRange/localDateRange.filter';
import 'sis-components/service/student.service';
import 'sis-components/applications/moduleContentApplicationStructure.component';
import 'sis-components/applications/moduleContentApplicationDecisionSummary.component';
import 'sis-components/service/studentApplication.service';
import 'sis-components/error-handler/legacy/errorService';
import 'sis-components/model/studentApplication.model';
import 'sis-components/service/moduleContentApplication.service';
import 'sis-components/model/applicationDecision.model';
import 'sis-components/service/applicationDecision.service';
export const commonShowModuleContentApplicationModalModule = 'sis-components.applications.commonShowModuleContentApplicationModal';
angular.module(commonShowModuleContentApplicationModalModule, ['sis-common.auth', 'sis-components.date.localDateRangeFilter', 'sis-components.service.studentService', 'sis-components.applications.moduleContentApplicationStructure', 'sis-components.applications.moduleContentApplicationDecisionSummary', studentApplicationStateBadgeModule, 'sis-components.service.studentApplicationService', 'sis-common.errorhandler.errorService', 'sis-components.model.studentApplication', 'sis-components.service.moduleContentApplicationService', 'sis-components.model.applicationDecision', 'sis-components.service.applicationDecisionService', customStudyDraftSummaryModule, personNameServiceModule, ModalService.downgrade.moduleName, WorkflowEntityService.downgrade.moduleName, PrivatePersonBasicInfoEntityService.downgrade.moduleName]).factory('commonShowModuleContentApplicationModal', commonShowModuleContentApplicationModalService).component('commonShowModuleContentApplicationModal', {
  bindings: {
    close: '&',
    resolve: '<'
  },
  controller: commonShowModuleContentApplicationModalController,
  template: commonShowModuleContentApplicationModalTpl
});
function commonShowModuleContentApplicationModalService(modalService, $q) {
  return {
    open: (studentId, approvalRequest) => modalService.open(CommonShowModuleContentApplicationModalComponent, {
      approvalRequest,
      studentId
    }, {
      size: 'lg'
    }).result.catch(() => $q.reject())
  };
}
function commonShowModuleContentApplicationModalController($q, $scope, commonStudentService, defaultPromiseHandler, STUDENT_APPLICATION_STATE, moduleContentApplicationService, commonApplicationDecisionService, applicationDecisionJSDataModel, workflowEntityService, privatePersonBasicInfoEntityService, personNameService) {
  const $ctrl = this;
  $ctrl.$onInit = () => {
    commonStudentService.findStudent($ctrl.resolve.studentId).then(student => {
      $ctrl.student = student;
    }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    $ctrl.approvalRequest = $ctrl.resolve.approvalRequest;
    moduleContentApplicationService.loadModuleContentApplicationObjects($ctrl.approvalRequest).then(result => {
      $ctrl.module = result.module;
      $ctrl.selectedCustomCourseUnitAttainments = result.selectedCustomCourseUnitAttainments;
      $ctrl.selectedCustomModuleAttainments = result.selectedCustomModuleAttainments;
      $ctrl.education = result.education;
      $ctrl.plan = result.plan;
      $ctrl.selectedModules = result.selectedModules;
      $ctrl.selectedModuleAttainmentsByGroupId = result.selectedModuleAttainmentsByGroupId;
      $ctrl.selectedGroupingModules = result.selectedGroupingModules;
      $ctrl.selectedCourseUnits = result.selectedCourseUnits;
      $ctrl.selectedCourseUnitAttainmentsByGroupId = result.selectedCourseUnitAttainmentsByGroupId;
      $ctrl.parentModule = result.parentModule;
      $ctrl.gradeScalesById = result.gradeScalesById;
      $ctrl.customStudyDrafts = $ctrl.approvalRequest.customStudyDrafts;
    }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    $ctrl.decision = undefined;
    commonApplicationDecisionService.findByApplicationId($ctrl.approvalRequest.id).then(decision => {
      if (decision) {
        $ctrl.decision = applicationDecisionJSDataModel.loadRelations(decision, ['approver', 'formalAssessor', 'contentAssessor']);
      }
    });
    getWorkflow($ctrl.approvalRequest.id);
    $ctrl.badgeClass = getBadgeClass($ctrl.approvalRequest);
  };
  function getWorkflow(applicationId) {
    workflowEntityService.getById(applicationId, {
      bypassCache: true
    }).pipe(take(1), tap(workflow => {
      $ctrl.workflow = workflow;
    }), switchMap(workflow => {
      if (workflow.initiatorType === 'STAFF') {
        return privatePersonBasicInfoEntityService.getById(workflow.createdByPersonId);
      }
      return of(undefined);
    }), take(1), tap(person => {
      if (person) {
        $ctrl.initiatorPersonFullName = personNameService.getFullName(person);
      }
    }))
    // Ensure change detection is run after finished.
    .subscribe(() => $scope.$evalAsync(), defaultPromiseHandler.loggingRejectedPromiseHandler);
  }
  function getBadgeClass(studentApplication) {
    switch (studentApplication.state) {
      case STUDENT_APPLICATION_STATE.REQUESTED:
      case STUDENT_APPLICATION_STATE.IN_HANDLING:
      case STUDENT_APPLICATION_STATE.CANCELLED:
      case STUDENT_APPLICATION_STATE.REVOKED:
        return 'secondary';
      case STUDENT_APPLICATION_STATE.REJECTED:
        return 'danger';
      default:
        return 'success';
    }
  }
}