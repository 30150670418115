import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Enrolment } from 'common-typescript/types/generated/common-backend';
import { ComponentDowngradeMappings } from 'sis-common/types/angular-hybrid';

@Component({
    selector: 'app-processing-state-indicator',
    templateUrl: './processing-state-indicator.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ProcessingStateIndicatorComponent {
    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'student.common.components.enrolment.processingStateIndicatorNg',
        directiveName: 'appProcessingStateIndicator',
    };

    @Input() enrolment: Enrolment;
}
