import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ValidatablePlan } from 'common-typescript';
import { CourseUnit, PersonWithModuleResponsibilityInfoType } from 'common-typescript/types';
import _ from 'lodash';
import { ModalService } from 'sis-common/modal/modal.service';

export interface StudentCourseUnitInfoModalValues {
    courseUnit: CourseUnit;
    validatablePlan: ValidatablePlan
}
@Component({
    selector: 'app-student-course-unit-info-modal',
    templateUrl: './student-course-unit-info-modal.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class StudentCourseUnitInfoModalComponent implements OnInit {

    courseUnit: CourseUnit;
    validatablePlan: ValidatablePlan;
    filteredResponsibilityInfos: PersonWithModuleResponsibilityInfoType[];

    constructor(
        @Inject(ModalService.injectionToken) public values: StudentCourseUnitInfoModalValues,
        public activeModal: NgbActiveModal) {
        this.courseUnit = values.courseUnit;
        this.validatablePlan = values.validatablePlan;
    }

    close() {
        this.activeModal.close();
    }

    ngOnInit() {
        this.filteredResponsibilityInfos = _.chain(this.courseUnit.responsibilityInfos)
            .filter(info =>
                _.get(info, 'roleUrn') !== 'urn:code:module-responsibility-info-type:administrative-person')
            .value();
    }

}
