<table class="table styled-table">
    <caption *ngIf="caption">{{ caption }}</caption>
  <thead>
  <tr class="d-flex">
    <th class="col-3">{{'ENTITY_NAMES.studyRight' | translate}}</th>
    <th class="col-4 col-xl-3"></th>
    <th class="col-1">{{'PROFILE.PAYMENTS.TABLE.STUDY_TERM' | translate}}</th>
    <th class="col-1">{{'PROFILE.PAYMENTS.TABLE.PAYMENT_AMOUNT' | translate}}</th>
    <th class="col-1">{{'PROFILE.PAYMENTS.TABLE.PAYMENT_DATE' | translate}}</th>
    <th class="col-1 col-xl-2">{{'PROFILE.PAYMENTS.TABLE.REGISTRATION_DATE' | translate}}</th>
    <th class="col-1"></th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let payment of payments; trackBy: paymentId" class="d-flex">
    <td class="col-3">{{payment.studyRightId | studyRightName | async | localizedString}}</td>
    <th class="col-4 col-xl-3"></th>
    <td class="col-1">{{payment.studyTerm ? (payment.studyTerm | studyTermName) : ('STUDY_TERMS.AUTUMN_AND_SPRING' | translate)}}</td>
    <td class="col-1">{{payment.paymentAmount | currency:null:''}}</td>
    <td class="col-1">{{payment.paymentDate | localDateFormat}}</td>
    <td class="col-1 col-xl-2">{{payment.registrationDate | localDateFormat}}</td>
    <td class="col-1 info-icon">
      <sis-tooltip *ngIf="payment.invalidated"
                   [tooltipText]="'PROFILE.PAYMENTS.TABLE.INVALIDATION_RATIONALE' | translate: {invalidationRationale: payment.invalidationRationale}"
                   [tooltipBlockElement]="true"
                   tooltipClass="tooltip-wide"
                   [attr.aria-label]="'PROFILE.PAYMENTS.TABLE.INVALIDATION_RATIONALE' | translate: {invalidationRationale: payment.invalidationRationale}">
        <sis-icon icon="info-circle" color="primary" class="sis-tooltip-trigger-content"></sis-icon>
      </sis-tooltip>
    </td>
  </tr>
  </tbody>
</table>
