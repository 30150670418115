import { Component, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PRIOR_LEARNING_TYPE } from 'sis-components/model/student-application-constants';

@Component({
    selector: 'app-add-prior-learning-modal',
    templateUrl: './add-prior-learning-modal.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class AddPriorLearningModalComponent {

    PRIOR_LEARNING_TYPE = PRIOR_LEARNING_TYPE;
    priorLearningTypeControl = new FormControl(PRIOR_LEARNING_TYPE.STUDIES);

    constructor(private modal: NgbActiveModal) {}

    cancel(): void {
        this.modal.dismiss();
    }

    confirm(): void {
        this.modal.close(this.priorLearningTypeControl.value);
    }
}
