"use strict";

(function () {
  studyYearTemplateModel.$inject = ["DS"];
  angular.module('sis-components.model.studyYearTemplate', ['js-data']).factory('studyYearTemplateJSDataModel', studyYearTemplateModel).run(["studyYearTemplateJSDataModel", function (studyYearTemplateJSDataModel) {}]);

  /**
   * @ngInject
   */
  function studyYearTemplateModel(DS) {
    return DS.defineResource({
      idAttribute: 'id',
      name: 'studyYearTemplate',
      endpoint: '/kori/api/study-year-templates',
      methods: {
        generateTemplateLocatorString: function (termIndex, periodIndex) {
          var templateStartYear = moment(this.valid.startDate).format('YYYY');
          return this.org + '/' + templateStartYear + '/' + termIndex + '/' + periodIndex;
        }
      }
    });
  }
})();