'use strict';

(function () {
  focus.$inject = ["$timeout"];
  angular.module('student.common.directive.focus', []).directive('focus', focus);

  /**
   * @ngInject
   */
  function focus($timeout) {
    return {
      restrict: 'A',
      link: function (scope, element) {
        $timeout(function () {
          element[0].focus();
        });
      }
    };
  }
})();