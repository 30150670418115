<ng-container *transloco="let t">
  <sis-description-list [fillByColumn]="false">
    <dl>
      <ng-container *ngIf="!isStudentInfo">
        <ng-template #listItem>
          <dt class="sis-label">{{t('STUDIES.COURSE_UNIT_ASSESSMENT_ITEMS.NAME_SPECIFIER')}}</dt>
          <dd>
            <sis-course-unit-info-modal-text-field [localizedStringValue]="assessmentItem.nameSpecifier"
                                                   [language]="language">
            </sis-course-unit-info-modal-text-field>
          </dd>
        </ng-template>
        <ng-template #listItem>
          <dt class="sis-label">{{t('STUDIES.COURSE_UNIT_ASSESSMENT_ITEMS.TYPE')}}</dt>
          <dd>{{ assessmentItem.assessmentItemType | codeLocalizedName | async }}</dd>
        </ng-template>
      </ng-container>
      <ng-template #listItem *ngIf="assessmentItem.possibleAttainmentLanguages?.length > 0 || showEmptyFields">
        <dt class="sis-label">{{t('STUDIES.COURSE_UNIT_ASSESSMENT_ITEMS.POSSIBLE_ATTAINMENT_LANGUAGES')}}</dt>
        <dd>
          <ng-container *ngIf="assessmentItem.possibleAttainmentLanguages?.length > 0 ; else noContent">
            <ng-container *ngFor="let languageUrn of assessmentItem.possibleAttainmentLanguages">
              {{ languageUrn | codeLocalizedName | async }}
            </ng-container>
          </ng-container>
        </dd>
      </ng-template>
      <ng-template #listItem *ngIf="assessmentItem.studyFormat || showEmptyFields">
        <dt class="sis-label">{{t('STUDIES.COURSE_UNIT_ASSESSMENT_ITEMS.STUDY_FORMAT')}}</dt>
        <dd>
          <sis-localized-markup-string-view *ngIf="useLocalizationFallback"
                                            [content]="assessmentItem.studyFormat">
          </sis-localized-markup-string-view>
          <sis-course-unit-info-modal-text-field *ngIf="!useLocalizationFallback"
                                                 [localizedMarkupStringValue]="assessmentItem.studyFormat"
                                                 [language]="language">
          </sis-course-unit-info-modal-text-field>
        </dd>
      </ng-template>
      <ng-template #listItem *ngIf="assessmentItem.learningMaterial || showEmptyFields">
        <dt class="sis-label">{{t('STUDIES.COURSE_UNIT_ASSESSMENT_ITEMS.LEARNING_MATERIAL')}}</dt>
        <dd>
          <sis-localized-markup-string-view *ngIf="useLocalizationFallback"
                                            [content]="assessmentItem.learningMaterial">
          </sis-localized-markup-string-view>

          <sis-course-unit-info-modal-text-field *ngIf="!useLocalizationFallback"
                                                 [localizedMarkupStringValue]="assessmentItem.learningMaterial"
                                                 [language]="language">
          </sis-course-unit-info-modal-text-field>
        </dd>
      </ng-template>
      <ng-template #listItem *ngIf="assessmentItem.literature?.length > 0 || showEmptyFields">
        <dt class="sis-label">{{t('STUDIES.COURSE_UNIT_ASSESSMENT_ITEMS.LITERATURE')}}</dt>
        <dd>
          <ng-container *ngIf="assessmentItem.literature?.length > 0; else noContent">
            <sis-literature *ngFor="let item of assessmentItem.literature" [literature]="item"></sis-literature>
          </ng-container>
        </dd>
      </ng-template>
      <ng-template #listItem *ngIf="assessmentItem.contentDescription || showEmptyFields">
        <dt class="sis-label">{{t('STUDIES.COURSE_UNIT_ASSESSMENT_ITEMS.CONTENT_DESCRIPTION')}}</dt>
        <dd>
          <sis-localized-markup-string-view *ngIf="useLocalizationFallback"
                                            [content]="assessmentItem.contentDescription">
          </sis-localized-markup-string-view>
          <sis-course-unit-info-modal-text-field *ngIf="!useLocalizationFallback"
                                                 [localizedMarkupStringValue]="assessmentItem.contentDescription"
                                                 [language]="language">
          </sis-course-unit-info-modal-text-field>
        </dd>
      </ng-template>
      <ng-template #listItem *ngIf="assessmentItem.grading || showEmptyFields">
        <dt class="sis-label">{{t('STUDIES.COURSE_UNIT_ASSESSMENT_ITEMS.GRADING')}}</dt>
        <dd>
          <sis-localized-markup-string-view *ngIf="useLocalizationFallback"
                                            [content]="assessmentItem.grading">
          </sis-localized-markup-string-view>
          <sis-course-unit-info-modal-text-field *ngIf="!useLocalizationFallback"
                                                 [localizedMarkupStringValue]="assessmentItem.grading"
                                                 [language]="language">
          </sis-course-unit-info-modal-text-field>
        </dd>
      </ng-template>
      <ng-template #listItem *ngIf="assessmentItem.studyField || showEmptyFields">
        <dt class="sis-label">{{t('STUDIES.COURSE_UNIT_ASSESSMENT_ITEMS.STUDY_FIELD')}}</dt>
        <dd>
          <ng-container *ngIf="assessmentItem.studyField; else noContent">
            {{ assessmentItem.studyField | codeLocalizedName | async }}
          </ng-container>
        </dd>
      </ng-template>
    </dl>
  </sis-description-list>

  <div class="row">
    <div class="col-12 form-group-mimic">
      <div role="heading" [attr.aria-level]="responsibilityInfoHeaderLevel"
           class="{{assessmentItem.responsibilityInfos?.length > 0 ? 'fudis-heading__md sis-mb-sm sis-mt-sm' : 'sis-label'}}">
        {{t('STUDIES.COURSE_UNIT_ASSESSMENT_ITEMS.RESPONSIBILITY_INFOS')}}
      </div>
      <ng-container *ngIf="assessmentItem.responsibilityInfos?.length > 0; else noContent">
        <sis-module-responsibility-info [responsibilityInfos]="assessmentItem.responsibilityInfos" [language]="language"></sis-module-responsibility-info>
      </ng-container>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <sis-responsible-organisation-info [organisations]="assessmentItem.organisations"></sis-responsible-organisation-info>
    </div>
  </div>

  <ng-template #noContent>
    <p class="sis-placeholder">
    <sis-icon icon="alert-fill-attention"></sis-icon>{{t('SIS_COMPONENTS.COURSE_UNIT_REALISATION_INFO.NO_INFORMATION_GIVEN')}}
    </p>
  </ng-template>
</ng-container>
