import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({ selector: '[appCollapsibleListContent]' })
export class CollapsibleListContentDirective {

    /** Unique tracking id used to identify the item in the list */
    @Input('appCollapsibleListContent') id: string;

    constructor(public templateRef: TemplateRef<unknown>) {}
}
