import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Inject,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FudisDialogService, FudisErrorSummaryService } from '@funidata/ngx-fudis';
import { ClassifiedPersonInfo, PrivatePerson } from 'common-typescript/types';
import { take } from 'rxjs/operators';

import { AppErrorHandler } from '../error-handler/app-error-handler';
import { PrivatePersonEntityService } from '../service/private-person-entity.service';

interface ClassifiedPersonInfoToggleForm {
    isPhoneNumberClassified: FormControl<boolean>;
    isSecondaryEmailClassified: FormControl<boolean>;
    isMunicipalityUrnClassified: FormControl<boolean>;
    isPrimaryAddressClassified: FormControl<boolean>;
    isSecondaryAddressClassified: FormControl<boolean>;
}

interface DialogInjectData {
    privatePerson: PrivatePerson;
    staffEdit: boolean;
}

@Component({
    selector: 'sis-classified-person-info-toggle-modal',
    templateUrl: './classified-person-info-toggle-modal.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClassifiedPersonInfoToggleModalComponent implements OnInit {
    @Output() updatedToggleValues = new EventEmitter<PrivatePerson>();

    classifiedPersonInfoToggleForm: FormGroup<ClassifiedPersonInfoToggleForm>;
    originalClassifiedPersonInfoToggleValues: Partial<ClassifiedPersonInfo>;
    staffEdit: boolean;
    privatePerson: PrivatePerson;
    changedByDate: string;
    changedById: string;
    errorSummaryVisible = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: DialogInjectData,
        private privatePersonService: PrivatePersonEntityService,
        private errorSummaryService: FudisErrorSummaryService,
        private dialogService: FudisDialogService,
        private appErrorHandler: AppErrorHandler) {
        if (this.data) {
            this.staffEdit = this.data.staffEdit;
            this.privatePerson = this.data.privatePerson;
            this.originalClassifiedPersonInfoToggleValues = this.data.privatePerson?.classifiedPersonInfo || {};
        }
    }

    ngOnInit(): void {
        this.classifiedPersonInfoToggleForm = this.buildForm();
        if (this.changedByOther()) {
            this.changedByDate = this.originalClassifiedPersonInfoToggleValues.changedOn;
            if (!!this.staffEdit) {
                this.changedById = this.originalClassifiedPersonInfoToggleValues.changedById;
            }
        }
    }

    onSubmit() {
        if (this.classifiedPersonInfoToggleForm.valid) {
            const updatedClassifiedPersonInfoToggleValues = { ...this.originalClassifiedPersonInfoToggleValues, ...this.classifiedPersonInfoToggleForm.value };

            this.privatePersonService.updateClassifiedPersonInfo(this.privatePerson.id, updatedClassifiedPersonInfoToggleValues)
                .pipe(
                    take(1),
                    this.appErrorHandler.defaultErrorHandler())
                .subscribe({
                    next: (result: PrivatePerson) => {
                        this.updatedToggleValues.emit(result);
                        this.dialogService.close(result);
                    },
                });
        } else {
            this.classifiedPersonInfoToggleForm.markAllAsTouched();
            this.errorSummaryVisible = true;
            this.errorSummaryService.reloadAllErrors();
        }
    }

    buildForm(): FormGroup {
        return new FormGroup<ClassifiedPersonInfoToggleForm>({
            isPhoneNumberClassified: new FormControl(this.originalClassifiedPersonInfoToggleValues['isPhoneNumberClassified']),
            isSecondaryEmailClassified: new FormControl({ value: this.originalClassifiedPersonInfoToggleValues['isSecondaryEmailClassified'], disabled: !this.userHasSecondaryEmail() }),
            isMunicipalityUrnClassified: new FormControl(this.originalClassifiedPersonInfoToggleValues['isMunicipalityUrnClassified']),
            isPrimaryAddressClassified: new FormControl(this.originalClassifiedPersonInfoToggleValues['isPrimaryAddressClassified']),
            isSecondaryAddressClassified: new FormControl({ value: this.originalClassifiedPersonInfoToggleValues['isSecondaryAddressClassified'], disabled: !this.userHasSecondaryAddress() }),
        });
    }

    get isPhoneNumberClassified(): FormControl {
        return this.classifiedPersonInfoToggleForm ? this.classifiedPersonInfoToggleForm.controls.isPhoneNumberClassified as FormControl : null;
    }

    get isSecondaryEmailClassified(): FormControl {
        return this.classifiedPersonInfoToggleForm ? this.classifiedPersonInfoToggleForm.controls.isSecondaryEmailClassified as FormControl : null;
    }

    get isMunicipalityUrnClassified(): FormControl {
        return this.classifiedPersonInfoToggleForm ? this.classifiedPersonInfoToggleForm.controls.isMunicipalityUrnClassified as FormControl : null;
    }

    get isPrimaryAddressClassified(): FormControl {
        return this.classifiedPersonInfoToggleForm ? this.classifiedPersonInfoToggleForm.controls.isPrimaryAddressClassified as FormControl : null;
    }

    get isSecondaryAddressClassified(): FormControl {
        return this.classifiedPersonInfoToggleForm ? this.classifiedPersonInfoToggleForm.controls.isSecondaryAddressClassified as FormControl : null;
    }

    secondaryEmailDisabledClass(): string {
        return this.userHasSecondaryEmail() ? '' : 'disabled-person-info-checkbox';
    }

    secondaryAddressDisabledClass(): string {
        return this.userHasSecondaryAddress() ? '' : 'disabled-person-info-checkbox';
    }

    userHasSecondaryEmail(): boolean {
        return !!this.privatePerson.secondaryEmail;
    }

    userHasSecondaryAddress(): boolean {
        return !!this.privatePerson.secondaryAddress;
    }

    changedByOther(): boolean {
        if (this.originalClassifiedPersonInfoToggleValues.changedById) {
            return this.originalClassifiedPersonInfoToggleValues.changedById !== this.privatePerson.id;
        }
        return false;
    }
}
