import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { Attainment, StudyRightMinorSelection } from 'common-typescript/types';

@Component({
    selector: 'sis-minor-selection-module-box',
    templateUrl: './minor-selection-module-box.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class MinorSelectionModuleBoxComponent {

    @Input() attainment: Attainment;
    @Input() editMode: boolean;
    @Output() removeModule = new EventEmitter<void>();

    @Input() set selection(selection: StudyRightMinorSelection) {
        this._selection = selection;
        this.isApprovedMinorSelection = selection.selectionState === 'APPROVED';
        this.isRequestedMinorSelection = selection.selectionState === 'REQUESTED';
    }

    get selection(): StudyRightMinorSelection {
        return this._selection;
    }

    @Input() set moduleColorIndex(moduleColorIndex: number) {
        this.moduleColorCategory = `cu-color-${((moduleColorIndex - 1) % 11) + 1}`;
    }

    isApprovedMinorSelection: boolean;
    isRequestedMinorSelection: boolean;
    moduleColorCategory: string;
    private _selection: StudyRightMinorSelection;

}
