import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import angular from 'angular';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

export interface Option {
    /** The callback to execute when the option is selected */
    action?: () => void;
    /** Additional CSS class(es) for the option. */
    class?: string;
    /** A `data-cy` attribute for the option, used in e2e tests. */
    dataCy?: string;
    /** A callback for checking if the option is disabled */
    disabled?: () => boolean;
    /** If defined, and if the option is disabled, a tooltip will be shown on hover with this text content */
    disabledTooltip?: string;
    /** A callback for checking if the option is hidden */
    hide?: () => boolean;
    /** Display name, possibly a translation key */
    name?: string;
    /** If defined and if the option is header, the option will be shown as a non-selectable header in the options list */
    header?: boolean;
}

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-menu-button',
    templateUrl: './menu-button.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuButtonComponent {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sisComponents.downgraded.menuButton',
        directiveName: 'sisMenuButton',
    };

    @Input() id?: string;
    @Input() disabled?: boolean;
    @Input() menuTitle?: string;
    @Input() options: Option[];
    @Input() placeLeft?: boolean;
    /** Renders the menu button with no background or border. */
    @Input() naked?: boolean;
    /** Color input defines the color of menu button */
    @Input() color?: 'white' | 'primary' | 'secondary' = 'secondary';
    /** Can be used to override the default CSS classes applied to the button that opens the menu */
    @Input() buttonClasses?: string;

    /**
     * The $scope instance of the containing AngularJS component. Required when this component is being used in an
     * AngularJS component; irrelevant otherwise. Without this there might be a varying amount of delay after
     * clicking on an option before anything happens in the UI.
     */
    @Input() scope: angular.IScope;

    @Output() openChange = new EventEmitter<boolean>();

    execute(option: Option): void {
        if (option.action) {
            if (this.scope) {
                this.scope.$apply(() => option.action());
            } else {
                option.action();
            }
        }
    }

    onItemClick(option: Option, dropdown: NgbDropdown, $event: MouseEvent): void {
        this.execute(option);
        $event.stopPropagation();

        // Delay dropdown close: if we close the dropdown immediately, possible modal entry animations don't trigger
        window.setTimeout(() => {
            dropdown.close();
        });
    }

    getOptionName(i: number, option: Option): string {
        return option.name ?? i.toString();
    }
}
