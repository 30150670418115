import {
    AccessDeniedResponse,
    ConstraintViolationExplanation,
    ExpectedPermissionsResponse,
    SisuLocalizedException,
} from 'common-typescript/types';

export function isAccessDeniedResponse(input: any): input is AccessDeniedResponse {
    return isObject(input) && 'accessDeniedType' in input && typeof input.accessDeniedType === 'string';
}

export function isConstraintViolationExplanation(input: any): input is ConstraintViolationExplanation {
    return isObject(input) && 'violations' in input && (!input.violations || typeof input.violations === 'object');
}

export function isExpectedPermissionsResponse(input: any): input is ExpectedPermissionsResponse {
    return isAccessDeniedResponse(input) && input.accessDeniedType === 'EXPECTED_PERMISSIONS';
}

export function isSisuLocalizedException(input: any): input is SisuLocalizedException {
    return isObject(input) && 'messageKey' in input && typeof input.messageKey === 'string';
}

function isObject(input: any): input is object {
    return typeof input === 'object' && input !== null;
}
