import { Component, inject, Inject, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { ModalService } from 'sis-common/modal/modal.service';

import { AppErrorHandler } from '../error-handler/app-error-handler';

/**
 * Instead of "hard coded" template where descriptions come before objects, these can be used to have multiple sets of descriptions
 * or objects and especially it supports this case:
 * descriptions
 * objects
 * descriptions
 */
export interface DescriptionSet {
    name?: string;
    descriptions?: string[];
    listTitle?: string;
    objects?: any[];
}

export interface ConfirmDialogValues<T> {
    title: string;
    description?: string;
    descriptions?: string[];
    notifications?: string[];
    name?: string;
    listTitle?: string;
    objects?: any[];
    descriptionSets?: DescriptionSet[];
    hideCancel?: boolean;
    confirmText?: string;
    cancelText?: string;
    /**
     * "Callback" observable that will be subscribed to when user click on "confirm" button. Modal will be closed after first value is
     * emitted from observable.
     */
    confirmObservable?: Observable<T>;
}

/**
 * This returns the confirmation dialog modal opener. Use "forceChangeDetection" parameter,
 * if you have problems opening the modal in the Angular/AngularJS hybrid app.
 */
export function getConfirmationModalOpener(): (values: ConfirmDialogValues<any>, forceChangeDetection?: boolean) => NgbModalRef {
    const [modalService, zone] = [inject(ModalService), inject(NgZone)];
    return (values, forceChangeDetection = false) => {
        const open = () => modalService.open(ConfirmDialogComponent, values, { windowClass: 'confirm-dialog', size: 'sm' });
        return forceChangeDetection ? zone.run(open) : open();
    };
}

/**
 * Confirmation dialog modal has confirmation buttons (OK / cancel) in the footer.
 *
 * If you need a modal for displaying information without confirmation buttons, use {@link InfoDialogComponent} instead.
 */
@Component({
    selector: 'sis-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ConfirmDialogComponent<T> implements OnInit {

    descriptions: string[] = [];
    notifications: string[] = [];
    private closed = false;

    constructor(
        private appErrorHandler: AppErrorHandler,
        @Inject(ModalService.injectionToken) public values: ConfirmDialogValues<T>,
        public modalInstance: NgbActiveModal,
    ) {
    }

    ngOnInit(): void {
        this.descriptions = this.values.descriptions || [];
        if (this.values.description) {
            this.descriptions.unshift(this.values.description);
        }
        this.notifications = this.values.notifications || [];
    }

    close() {
        // prevent double clicks
        if (this.closed) {
            return;
        }
        this.closed = true;
        if (!this.values.confirmObservable) {
            this.modalInstance.close();
            return;
        }
        this.values.confirmObservable
            .pipe(
                take(1),
                this.appErrorHandler.defaultErrorHandler(),
            )
            .subscribe((response: T) => {
                this.modalInstance.close(response);
            });
    }
}
