import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { dateUtils } from 'common-typescript';
import { OtmId, StudyEvent } from 'common-typescript/types';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';
import { EntityService } from 'sis-components/service/entity.service';

const CONFIG = {
    ENDPOINTS: {
        backend: '/ilmo/api',
        getMyStudyEvents() {
            return `${this.backend}/my-study-events`;
        },
    },
};

@StaticMembers<DowngradedService>()
@Injectable({
    providedIn: 'root',
})
@NgEntityServiceConfig({
    baseUrl: CONFIG.ENDPOINTS.backend,
})
export class StudyEventEntityService extends EntityService<StudyEventsState> {

    static downgrade: ServiceDowngradeMappings = {
        dependencies: [],
        moduleName: 'sis-components.service.studyEventEntityService',
        serviceName: 'studyEventEntityService',
    };

    constructor() {
        super(StudyEventStore, StudyEventQuery);
    }

    getMyStudyEvents(startDate: Date, endDate: Date) {
        const params = { startDate: dateUtils.convertDateToIsoLocalDate(startDate), endDate: dateUtils.convertDateToIsoLocalDate(endDate) };
        return this.getHttp().get<StudyEvent[]>(CONFIG.ENDPOINTS.getMyStudyEvents(), { params });
    }
}

type StudyEventsState = EntityState<StudyEvent, OtmId>;

@StoreConfig({ name: 'study-events' })
class StudyEventStore extends EntityStore<StudyEventsState> {}

class StudyEventQuery extends QueryEntity<StudyEventsState> {
    constructor(protected store: StudyEventStore) {
        super(store);
    }
}
