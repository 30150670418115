import attainmentTreeParentTpl from './attainmentTreeParent.tpl.html';
'use strict';
(function () {
  attainmentTreeParent.$inject = ["attainmentTreeUtil"];
  angular.module('sis-components.attainment.tree').directive('attainmentTreeParent', attainmentTreeParent);

  /** @ngInject */
  function attainmentTreeParent(attainmentTreeUtil) {
    return {
      restrict: 'E',
      template: attainmentTreeParentTpl,
      scope: {
        row: '<',
        onAttainmentClick: '&',
        attainmentsDisplayed: '<',
        attainmentEditActionSelected: '&',
        showMenuButton: '<'
      },
      link: function (scope, element) {
        scope.toggle = function () {
          if (scope.row.hide) {
            scope.row.hide = false;
            element.removeClass('hide-child');
          } else {
            scope.row.hide = true;
            element.addClass('hide-child');
          }
        };
        if (scope.row.hide) {
          element.addClass('hide-child');
        }
        for (var i = 0; i < scope.row.attainments.length; ++i) {
          var childScope = {};
          childScope.row = scope.row.attainments[i];
          childScope.onAttainmentClick = scope.onAttainmentClick;
          childScope.attainmentsDisplayed = scope.attainmentsDisplayed;
          childScope.attainmentEditActionSelected = scope.attainmentEditActionSelected;
          childScope.showMenuButton = scope.showMenuButton;
          element.append(attainmentTreeUtil.getDirective(childScope));
        }
      }
    };
  }
})();