<p *ngIf="description">
  <transloco [content]="description | translate:{ count: items.length }" />
</p>
<p *ngIf="listShowState !== ListShowState.ALWAYS_SHOWING_ALL" class="guidance truncated-results">
  <ng-container *ngIf="listShowState === ListShowState.SHOWING_LESS"> {{ 'SHOW_MORE_LIST.SHOWING_LESS_ITEMS' | translate:{ count: INITIALLY_SHOWN_LIST_ITEMS_MAX_COUNT } }} </ng-container>
  <ng-container *ngIf="listShowState === ListShowState.SHOWING_MORE"> {{ (showingAllItemsText || 'SHOW_MORE_LIST.SHOWING_ALL_ITEMS') | translate:{ count: items.length } }} </ng-container>
  <button (click)="toggleListShowState()" class="link-button">{{ (listShowState === ListShowState.SHOWING_LESS ? 'SHOW_MORE_LIST.SHOW_MORE' : 'SHOW_MORE_LIST.SHOW_LESS') | translate }}</button>
</p>
<ul>
  <li *ngFor="let listItem of items.slice(0, listShowState === ListShowState.SHOWING_LESS ? INITIALLY_SHOWN_LIST_ITEMS_MAX_COUNT : undefined)">
    {{ listItem }}
  </li>
</ul>
