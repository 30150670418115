'use strict';

(function () {
  planAttainmentsService.$inject = ["$q", "commonStudentAttainmentService", "validAttainmentFilterService"];
  angular.module('sis-components.service.planAttainmentsService', ['sis-components.service.validAttainmentFilterService', 'sis-components.service.studentAttainmentService']).factory('planAttainmentsService', planAttainmentsService);

  /**
   * @ngInject
   */
  function planAttainmentsService($q, commonStudentAttainmentService, validAttainmentFilterService) {
    var loadAttainments = _.memoize(function (userId) {
      return commonStudentAttainmentService.load(userId);
    });
    var getAllAttainmentsForUser = _.memoize(function (userId) {
      return commonStudentAttainmentService.findByPersonId(userId).then(function (allAttainments) {
        return validAttainmentFilterService.getValidAttainments(allAttainments);
      });
    });
    var api = {
      getAllAttainments: function (plan) {
        if (plan) {
          var userId = _.get(plan, 'userId');
          return loadAttainments(userId).then(function () {
            return getAllAttainmentsForUser(userId);
          });
        }
        return $q.reject('Plan is required to get attainments');
      },
      getAllCourseUnitAttainments: function (plan) {
        return api.getAllAttainments(plan).then(function (allAttainments) {
          return _.filter(allAttainments, {
            type: 'CourseUnitAttainment'
          });
        });
      },
      getAllModuleAttainments: function (plan) {
        return api.getAllAttainments(plan).then(function (allAttainments) {
          return _.filter(allAttainments, function (attainment) {
            return _.includes(['ModuleAttainment', 'DegreeProgrammeAttainment'], _.get(attainment, 'type'));
          });
        });
      },
      getCourseUnitAttainment: function (plan, courseUnitId) {
        return api.getAllCourseUnitAttainments(plan).then(function (courseUnitAttainments) {
          return _.find(courseUnitAttainments, {
            courseUnitId: courseUnitId
          });
        });
      },
      getModuleAttainment: function (plan, moduleId) {
        return api.getAllModuleAttainments(plan).then(function (moduleAttainments) {
          return _.find(moduleAttainments, {
            moduleId: moduleId
          });
        });
      }
    };
    return api;
  }
})();