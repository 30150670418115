import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { Attainment } from 'common-typescript/types';
import {
    BehaviorSubject,
    distinctUntilChanged,
    Observable,
    of,
    shareReplay,
    ShareReplayConfig,
    startWith,
    switchMap,
} from 'rxjs';

import { AttainmentStudentModalService } from '../../../../common/service/attainment-student-modal-service';
import { AttainmentTreeExpansionStateService } from '../attainment-tree-expansion-state.service';
import { isAttainmentTreeAttainmentNode, isAttainmentTreeGroupNode } from '../attainment-tree-node.type-guards';
import {
    AttainmentTreeAttainmentNode,
    AttainmentTreeGroupNode,
    AttainmentTreeNode,
} from '../attainment-tree-node.types';

@Component({
    selector: 'app-student-attainment-node-wrapper',
    templateUrl: './student-attainment-node-wrapper.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudentAttainmentNodeWrapperComponent {
    private readonly _attainmentNode$ = new BehaviorSubject<AttainmentTreeAttainmentNode | AttainmentTreeGroupNode | null>(null);

    @Input({ required: true }) expandableLevel: number;

    readonly isAttainmentTreeAttainmentNode = isAttainmentTreeAttainmentNode;
    readonly isAttainmentTreeGroupNode = isAttainmentTreeGroupNode;
    readonly isClosed$: Observable<boolean>;

    constructor(
        private attainmentModalService: AttainmentStudentModalService,
        private readonly _attainmentTreeExpansionStateService: AttainmentTreeExpansionStateService,
    ) {
        this.isClosed$ = this._attainmentNode$.pipe(
            switchMap((node: AttainmentTreeNode | null) => node
                ? _attainmentTreeExpansionStateService.isNodeCollapsed(node)
                : of(true),
            ),
            // make sure AsyncPipe won't produce implicit null
            startWith(true),
            distinctUntilChanged(),
            shareReplay(<ShareReplayConfig>{ bufferSize: 1, refCount: true }),
        );
    }

    get attainmentNode(): AttainmentTreeAttainmentNode | AttainmentTreeGroupNode | null {
        return this._attainmentNode$.value;
    }

    @Input({ required: true })
    set attainmentNode(value: AttainmentTreeAttainmentNode | AttainmentTreeGroupNode) {
        this._attainmentNode$.next(value);
    }

    openAttainmentDetailsModal(attainment: Attainment): void {
        this.attainmentModalService.openAttainmentDetailsModal(attainment, false, null)
            .subscribe();
    }

    setClosed(closed: boolean): void {
        if (this._attainmentNode$.value) {
            this._attainmentTreeExpansionStateService.setNodeCollapsed(this._attainmentNode$.value, closed);
        }
    }
}
