<app-cart-container [pageTitle]="'OPEN_UNIVERSITY_CART.WIZARD.PAYMENT_FAILURE.NAME'">
  <ng-container *ngIf="cart$ | async as cart; else noCart">
    <div class="row">
      <div class="col-md-10 col-sm-12">
        <p class="mt-3">{{ 'OPEN_UNIVERSITY_CART.WIZARD.PAYMENT_FAILURE.DESCRIPTION' | translate }}</p>
        <p>{{ 'OPEN_UNIVERSITY_CART.WIZARD.PAYMENT_FAILURE.GUIDANCE_CART_ACTIVE' | translate }}</p>
        <p data-cy="contact-organizer-guidance">
          <ng-container *ngTemplateOutlet="contactOrganizer"></ng-container>
          <ng-container *ngIf="cart.orderNumber">
            <transloco key="OPEN_UNIVERSITY.ERRORS.PROVIDE_ORDER_NUMBER" [params]="{ orderNumber: cart.orderNumber }" />
          </ng-container>
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-10 col-sm-12">
        <sis-wizard-step-buttons
          [backButtonTitle]="'OPEN_UNIVERSITY_CART.WIZARD.PAYMENT_FAILURE.RETURN_TO_CART_BUTTON' | translate"
          (back)="backToCart()"
          [forwardButtonTitle]="'OPEN_UNIVERSITY_CART.WIZARD.PAYMENT_FAILURE.RETRY_PAYMENT_BUTTON' | translate"
          [forwardButtonDisabled]="requestInProgress"
          [forwardButtonDisabledTooltip]="'OPEN_UNIVERSITY_CART.WIZARD.CONTACT_INFORMATION.WAITING_GUIDANCE' | translate"
          (forward)="retryPayment(cart.id)">
        </sis-wizard-step-buttons>
      </div>
    </div>
  </ng-container>
</app-cart-container>

<ng-template #noCart>
  <div class="row">
    <div class="col-md-10 col-sm-12">
      <p class="mt-3">{{ 'OPEN_UNIVERSITY_CART.WIZARD.PAYMENT_FAILURE.DESCRIPTION' | translate }}</p>
      <p>{{ 'OPEN_UNIVERSITY_CART.WIZARD.PAYMENT_FAILURE.GUIDANCE_CART_EXPIRED' | translate }}</p>
      <p data-cy="contact-organizer-guidance"><ng-container *ngTemplateOutlet="contactOrganizer"></ng-container></p>
    </div>
  </div>

  <div class="row">
    <div class="col-md-10 col-sm-12">
      <sis-wizard-step-buttons
        [backButtonDisplay]="false"
        [forwardButtonTitle]="'OPEN_UNIVERSITY_CART.WIZARD.PAYMENT_FAILURE.RETURN_TO_SEARCH_BUTTON' | translate"
        (forward)="backToSearch()">
      </sis-wizard-step-buttons>
    </div>
  </div>
</ng-template>

<ng-template #contactOrganizer>
  {{ 'OPEN_UNIVERSITY.ERRORS.CONTACT_ORGANIZER' | translate }}<!--
  --><ng-container *ngIf="openUniversitySettings$ | async as settings">
    (<app-customer-service-contact-link [settings]="settings"></app-customer-service-contact-link>)<!--
  --></ng-container>.
</ng-template>
