import angular from 'angular';
import _ from 'lodash';
import { organisationModelModule } from '../model/organisation.model';
import { jsDataCacheHelperModule } from './jsDataCacheHelper.service';
export const commonOrganisationServiceModule = 'sis-components.service.organisationService';
(function () {
  organisationService.$inject = ["$http", "$q", "$log", "organisationJSDataModel", "jsDataCacheHelper"];
  angular.module(commonOrganisationServiceModule, [organisationModelModule, jsDataCacheHelperModule]).factory('commonOrganisationService', organisationService);

  /**
   * @ngInject
   */
  function organisationService($http, $q, $log, organisationJSDataModel, jsDataCacheHelper) {
    const baseUri = '/kori/api/organisations';
    const searchUri = '/kori/api/organisation-snapshot-search';
    return {
      search(fullTextQuery, universityOrgId) {
        // TODO: js-data tähän hakuun käyttöön
        return $http.get(baseUri, {
          params: {
            name: fullTextQuery,
            universityOrgId
          }
        }).then(result => ({
          start: 0,
          total: result.data.length,
          searchResults: result.data
        })).catch(err => {
          $log.error('Failed to search organisations', baseUri, fullTextQuery, err);
          return $q.reject(err);
        });
      },
      searchSnapshots(fullTextQuery, universityOrgId) {
        return $http.get(searchUri, {
          params: {
            name: fullTextQuery,
            universityOrgId
          }
        }).then(result => ({
          start: 0,
          total: result.data.length,
          searchResults: result.data
        })).catch(err => {
          $log.error('Failed to search organisation snapshots', searchUri, fullTextQuery, universityOrgId, err);
          return $q.reject(err);
        });
      },
      searchSnapshotsById(ids) {
        return $http.get(searchUri, {
          params: {
            id: ids
          }
        }).then(result => result.data).catch(err => {
          $log.error('Failed to search organisation snapshots', searchUri, ids, err);
          return $q.reject(err);
        });
      },
      find(organisationId) {
        return organisationJSDataModel.find(organisationId).then(organisation => organisation, err => {
          $log.error('Failed to find organisation by Id', organisationId, err);
          return $q.reject(err);
        });
      },
      findByIds(ids) {
        return jsDataCacheHelper.findByIds(organisationJSDataModel, ids);
      },
      findAll(params, bypassCache) {
        return organisationJSDataModel.findAll(params, {
          bypassCache: !!bypassCache
        });
      },
      get(id) {
        return organisationJSDataModel.get(id);
      },
      /**
       * Organisation is a root organisation if its universityOrgId is same as the id of itself.
       * @param organisation The organisation to check if it is a root.
       * @return True if a root organisation.
       */
      isRootOrganisation(organisation) {
        return organisation && _.get(organisation, 'universityOrgId', 'unknown') === organisation.id;
      },
      findByParentOrganisationId(organisationId) {
        return $http.get(baseUri, {
          params: {
            universityOrgId: organisationId
          }
        }).then(res => res.data).catch(err => {
          $log.error('Failed to find organisation by parent ID', organisationId, err);
          return $q.reject(err);
        });
      }
    };
  }
})();