import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { StateService, UIRouterGlobals } from '@uirouter/core';
import { PlanValidationResult } from 'common-typescript/src/plan/validation/planValidationResult';
import { ValidatablePlan } from 'common-typescript/src/plan/validation/validatablePlan';
import { DegreeProgrammeAttainmentApplication, ModuleAttainmentApplication, Plan, SearchResult } from 'common-typescript/types';
import _ from 'lodash';
import { from } from 'rxjs';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { COMMON_STUDENT_APPLICATION_SERVICE } from '../../ajs-upgraded-modules';
import { AppErrorHandler } from '../../error-handler/app-error-handler';
import { STUDENT_APPLICATION_STATE, STUDENT_APPLICATION_TYPE } from '../../model/student-application-constants';
import { RequestedApplications } from '../../plan/planModule.comp.upgraded';
import { convertAJSPromiseToNative } from '../../util/utils';

import {
    AttainmentApplicationPlanValidationService,
    PlanValidationForSnapshotPlan,
} from './attainment-application-plan-validation.service';

/**
 * planSnapshot binding is used by common-plan-module component.
 * This component will decorate module info boxes with links to another REQUESTED module attainment applications.
 * Note that links point to current route, modify this component if you need them to point to another route.
 */
@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-attainment-application-structure',
    templateUrl: './attainment-application-structure.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class AttainmentApplicationStructureComponent implements OnInit {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.lib.applications.attainment-application-structure',
        directiveName: 'sisAttainmentApplicationStructure',
    };

    @Input() application: ModuleAttainmentApplication | DegreeProgrammeAttainmentApplication;
    // Optional callback that will be called with object containing applicationId, studyModuleId.
    @Input() resolveApplicationLinkUrl?: (params: ResolveApplicationUrlObject) => string;
    @Output() planValidationResultsCompleted = new EventEmitter<PlanValidationForSnapshotPlan>();

    planSnapshot: Plan;
    planValidationResult: PlanValidationResult;
    validatablePlan: ValidatablePlan;
    parentModuleId: string;
    requestedApplications: RequestedApplications;
    planDataLoaded = false;

    constructor(
        private appErrorHandler: AppErrorHandler,
        private stateService: StateService,
        private uiRouterGlobals: UIRouterGlobals,
        private attainmentApplicationPlanValidationService: AttainmentApplicationPlanValidationService,
        @Inject(COMMON_STUDENT_APPLICATION_SERVICE) private commonStudentApplicationService: any,
    ) { }

    ngOnInit(): void {
        from(convertAJSPromiseToNative(this.attainmentApplicationPlanValidationService.planValidationForSnapshotPlan(this.application)))
            .pipe(this.appErrorHandler.defaultErrorHandler())
            .subscribe((planValidationForSnapshotPlan) => {
                this.planValidationResultsCompleted.emit(planValidationForSnapshotPlan);
                this.planSnapshot = planValidationForSnapshotPlan.planSnapshot;
                this.planValidationResult = planValidationForSnapshotPlan.planValidationResult;
                this.validatablePlan = planValidationForSnapshotPlan.validatablePlan;
                const module = _.get(this.validatablePlan.modulesById, this.application.moduleId);
                const parentModule = this.validatablePlan.getParentModuleOrCustomModuleAttainmentForModule(module);
                this.parentModuleId = parentModule ? parentModule.id : this.planSnapshot.rootId;
                this.planDataLoaded = true;
                return this.setRequestedApplications();
            });
    }

    setRequestedApplications(): Promise<void> {
        const build = () => ({
            params: {
                studentId: this.application.studentId,
                applicationState: [
                    STUDENT_APPLICATION_STATE.REQUESTED,
                    STUDENT_APPLICATION_STATE.IN_HANDLING,
                ],
                applicationType: [
                    STUDENT_APPLICATION_TYPE.MODULE_ATTAINMENT_APPLICATION,
                ],
            },
        });
        return this.commonStudentApplicationService.search({ build })
            .then((searchResult: SearchResult<ModuleAttainmentApplication>) =>
                // We want non attained modules, applications that are ModuleAttainmentApplication, REQUESTED or IN_HANDLING
                // and not for this module.
                _.chain(searchResult.searchResults)
                    .reject({ moduleId: this.application.moduleId })
                    .reject((existingApplication) => this.validatablePlan.isModuleAttained(existingApplication.moduleId))
                    .map((existingApplication: ModuleAttainmentApplication) => {
                        const urlParams: ResolveApplicationUrlObject = {
                            applicationId: existingApplication.id,
                            studyModuleId: existingApplication.moduleId,
                        };
                        return {
                            applicationId: existingApplication.id,
                            moduleId: existingApplication.moduleId,
                            url: this.resolveApplicationLinkUrl !== undefined ?
                                this.resolveApplicationLinkUrl(urlParams) :
                                this.stateService.href('.', urlParams),
                        };
                    })
                    .keyBy('moduleId')
                    .value(),
            )
            .then((requestedApplications: RequestedApplications) => {
                this.requestedApplications = requestedApplications;
            });
    }
}

interface ResolveApplicationUrlObject {
    applicationId: string;
    studyModuleId: string;
}
