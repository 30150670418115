import { CourseUnitRealisation, Enrolment, StudyGroupSet, StudySubGroup } from 'common-typescript/types';

export interface StudyGroupSetViewData {
    studyGroupSet: StudyGroupSet;
    confirmedStudySubGroups: StudySubGroup[];
    tentativeStudySubGroups: StudySubGroup[];
    notSelectedStudySubGroups: StudySubGroup[];
    studySubGroupsForLaterPlacement: StudySubGroup[];
}

export interface StudyGroupsForEnrolmentViewData {
    enrolment: Enrolment;
    courseUnitRealisation: CourseUnitRealisation;
    studyGroupSetViewData: StudyGroupSetViewData[];
}

export function getStudyGroupsForEnrolmentViewData(enrolment: Enrolment, courseUnitRealisation: CourseUnitRealisation): StudyGroupsForEnrolmentViewData {
    return {
        enrolment,
        courseUnitRealisation,
        studyGroupSetViewData: generateStudyGroupSetViewData(enrolment, courseUnitRealisation),
    };
}

function generateStudyGroupSetViewData(enrolment: Enrolment, courseUnitRealisation: CourseUnitRealisation): StudyGroupSetViewData[] {
    if (!courseUnitRealisation || !enrolment) {
        return [];
    }
    return courseUnitRealisation.studyGroupSets?.map((studyGroupSet) => generateViewData(enrolment, studyGroupSet));
}
function generateConfirmedStudySubGroups(enrolment: Enrolment, studyGroupSet: StudyGroupSet) {
    return studyGroupSet.studySubGroups.filter((studySubGroup) => enrolment.confirmedStudySubGroupIds?.includes(studySubGroup.id));
}

function generateTentativeStudySubGroups(enrolment: Enrolment, studyGroupSet: StudyGroupSet) {
    return studyGroupSet.studySubGroups.filter((studySubGroup) => enrolment.tentativeStudySubGroupIds?.includes(studySubGroup.id));
}

function selectedStudySubGroupsNotInTentativeOrConfirmedFilter(enrolment: Enrolment) {
    return (studySubGroup: StudySubGroup) =>
        enrolment.studySubGroups &&
        enrolment.studySubGroups
            .filter((enrolmentStudySubGroup) => enrolmentStudySubGroup.enrolmentStudySubGroupPriority !== 'NOT_SUITABLE')
            .map((enrolmentStudySubGroup) => enrolmentStudySubGroup.studySubGroupId)
            .includes(studySubGroup.id)
        && (!enrolment.tentativeStudySubGroupIds?.includes(studySubGroup.id) && !enrolment.confirmedStudySubGroupIds?.includes(studySubGroup.id));
}

function generateNotSelectedStudySubGroups(enrolment: Enrolment, studyGroupSet: StudyGroupSet) {
    return studyGroupSet.studySubGroups.filter(selectedStudySubGroupsNotInTentativeOrConfirmedFilter(enrolment));
}

function generateViewData(enrolment: Enrolment, studyGroupSet: StudyGroupSet): StudyGroupSetViewData {
    const validStudyGroupSet = { ...studyGroupSet, studySubGroups: studyGroupSet.studySubGroups.filter(ssg => !ssg.cancelled) };
    return {
        studyGroupSet,
        confirmedStudySubGroups: generateConfirmedStudySubGroups(enrolment, validStudyGroupSet),
        tentativeStudySubGroups: generateTentativeStudySubGroups(enrolment, validStudyGroupSet),
        notSelectedStudySubGroups: generateNotSelectedStudySubGroups(enrolment, validStudyGroupSet),
        studySubGroupsForLaterPlacement: validStudyGroupSet.subGroupRange.min === 0 && validStudyGroupSet.subGroupRange.max === 0 ? validStudyGroupSet.studySubGroups : [],
    };
}
