(function () {
  'use strict';

  angular.module('student.shared').factory('defineEnum', defineEnumFactory);
  function defineEnumFactory() {
    return function (values) {
      if (!_.isArray(values)) {
        values = Array.prototype.slice.call(arguments);
      }
      var enumType = {
        values: [],
        indexes: {}
      };
      _.forEach(values, function (value, index) {
        enumType[value] = value;
        enumType.values.push(value);
        enumType.indexes[value] = index;
      });
      enumType.compare = function (state1, state2) {
        return enumType.indexes[state1] - enumType.indexes[state2];
      };
      return enumType;
    };
  }
})();