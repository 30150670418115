import angular from 'angular';
import { SearchFilterOptionsMenuComponent } from './search-filter-options-menu/search-filter-options-menu.component.ts';
import { searchFilterMenuItemModule } from './searchFilters/searchFilterMenuItem.component';
import searchFilterOptionsMenuTpl from './searchFilterOptionsMenu.tpl.html';
export const searchFilterOptionsMenuModule = 'sis-components.search.searchFilterOptionsMenu';

/**
 * This component is meant to provide backward compatibility for legacy code by working as an adapter that maps the
 * inputs/outputs of the old searchFilterOptionsMenu component with the new Angular SearchFilterOptionsMenuComponent.
 * It is designed to work as a drop-in replacement for the old component, requiring no changes in existing codebase.
 *
 * This component should NOT be used when writing new code; the downgraded version of SearchFilterOptionsMenuComponent
 * is the preferred choice. The only purpose of this component was to allow transitioning from UI Bootstrap to
 * NG Bootstrap without changing existing search pages.
 */
angular.module(searchFilterOptionsMenuModule, [searchFilterMenuItemModule, SearchFilterOptionsMenuComponent.downgrade.moduleName]).component('searchFilterOptionsMenu', {
  bindings: {
    searchParameterTitles: '<',
    searchParameterOptions: '<',
    disable: '<',
    searchParams: '<',
    searchAgain: '&',
    onSelect: '&',
    university: '<?',
    mainButtonKey: '@?'
  },
  controller: ["$scope", function ($scope) {
    this.$scope = $scope;
  }],
  template: searchFilterOptionsMenuTpl
});