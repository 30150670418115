<div *ngIf="isAnyServiceBreakInfoToShow" class="service-breaks">
  <div class="service-break"
       [ngClass]="hasServiceBreakPassed(serviceBreak) ? 'service-break--passed' : 'service-break--not-passed'"
       *ngFor="let serviceBreak of currentServiceBreaks">
    <fudis-icon *ngIf="!hasServiceBreakPassed(serviceBreak)"
                [icon]="'exclamation-mark-circle-fill'"
                class="service-break__exclamation-mark-icon"
                aria-hidden="true"/>
    <span *ngIf="!hasServiceBreakPassed(serviceBreak)" class="visually-hidden">{{ 'ARIA_LABEL.NOTIFICATION_WARNING' | translate }}</span>
    <span class="service-break__info-text">{{ getServiceInfoText(serviceBreak) }}</span>
    <div class="service-break__secondary">
      <div class="service-break__secondary__additional-info" *ngIf="isAdditionalInfo(serviceBreak)">
        <button type="button" class="link-button ng-scope" fudisTooltip
                [tooltip]="'UNAVAILABLE.ADDITIONAL_INFO_TOOLTIP' | translate"
                (click)="openInfoDialog(serviceBreak)">
          <fudis-icon [icon]="'info-circle'" [color]="'primary'"/>
          <span translate>UNAVAILABLE.ADDITIONAL_INFO</span>
        </button>
      </div>
      <div class="service-break__secondary__release-notes" *ngIf="isReleaseNotes(serviceBreak)">
        <fudis-link [externalLink]="serviceBreak.releaseNotesLink | localizedString"
                    [title]="'UNAVAILABLE.EXTERNAL_LINK' | translate"/>
      </div>
    </div>
  </div>
</div>
