import { Injectable } from '@angular/core';

/**
 * Wrapper for sessionStorage. This will make mocking easier.
 */
@Injectable({ providedIn: 'root' })
export class SessionStorageService {

    clear(): void {
        return sessionStorage.clear();
    }

    getItem(key: string): string | null {
        return sessionStorage.getItem(key);
    }

    key(index: number): string | null {
        return sessionStorage.key(index);
    }

    get length(): number {
        return sessionStorage.length;
    }

    removeItem(key: string): void {
        return sessionStorage.removeItem(key);
    }

    setItem(key: string, value: string): void {
        return sessionStorage.setItem(key, value);
    }

    getValueOrDefault<T>(key: string, defaultValue: T): T {
        return JSON.parse(this.getItem(key)) ?? defaultValue;
    }

    setValue<T>(key: string, value: T): void {
        this.setItem(key, JSON.stringify(value));
    }

}
