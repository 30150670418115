import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ValidatablePlan } from 'common-typescript/src/plan/validation/validatablePlan';
import { CourseUnit, CourseUnitAttainment, LocalizedString } from 'common-typescript/types';

@Component({
    selector: 'app-course-unit-info-modal-header-info-list',
    templateUrl: './course-unit-info-modal-header-info-list.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourseUnitInfoModalHeaderInfoListComponent implements OnChanges {

    @Input() validatablePlan: ValidatablePlan;
    @Input() courseUnit: CourseUnit;
    planLocation: LocalizedString;
    courseUnitAttainment: CourseUnitAttainment;

    ngOnChanges(changes: SimpleChanges) {
        this.planLocation = this.getPlanLocation();
        this.courseUnitAttainment = this.getAttainment();
    }

    getAttainment(): CourseUnitAttainment {
        return this.validatablePlan.getCourseUnitAttainment(this.courseUnit.id);
    }

    getPlanLocation(): LocalizedString {
        return this.validatablePlan.getParentModuleOrCustomModuleAttainmentForCourseUnit(this.courseUnit)?.name;
    }

}
