(function () {
  angular.module('sis-components.string.emptyStringToNull', []).directive('emptyStringToNull', emptyStringToNullDirective);

  /**
   * @ngInject
   */
  function emptyStringToNullDirective() {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, elem, attrs, ctrl) {
        ctrl.$parsers.push(function (viewValue) {
          if (viewValue === '') {
            return null;
          }
          return viewValue;
        });
      }
    };
  }
})();