<ng-container *ngIf="this.enrolment.courseUnitRealisationId | courseUnitRealisation | async as courseUnitRealisation">
  <ng-container *transloco="let t">
    <h2 class="modal-title" sisAutofocus>{{ t('ENROLMENTS.ENROLMENT_DIALOG.HEADER') }}<br />{{ courseUnitRealisation | courseUnitRealisationFullName | localizedString }}</h2>
    <sis-description-list-compact *ngIf="this.enrolment.courseUnitId | courseUnit | async as courseUnit">
      <dl>
        <ng-template #compactListItem>
          <dt class="label colon">{{ t('ENROLMENTS.ENROLMENT_DIALOG.COURSE_UNIT') }}</dt>
          <dd class="value">
            {{ courseUnit.code }}, {{ courseUnit.name | localizedString }}
            ({{ courseUnit.credits | creditRange }})
          </dd>
        </ng-template>
        <ng-template #compactListItem>
          <dt class="label colon">{{ t('ENROLMENTS.ENROLMENT_DIALOG.COURSE_UNIT_VERSION') }}</dt>
          <dd class="value">
            {{ courseUnit.curriculumPeriodIds | curriculumPeriodName | async }}
          </dd>
        </ng-template>
      </dl>
    </sis-description-list-compact>
  </ng-container>
</ng-container>
