<ng-container *transloco="let t">
  <div class="guidance-info">
    <sis-description-list [fillByColumn]="false">
      <dl *ngIf="completionMethod?.typeOfRequire === 'ALL_SELECTED_REQUIRED'">
        <ng-template #listItem>
          <dt class="sis-label">{{t('STUDIES.COURSE_UNIT_INFO_MODAL.REQUIRE_RANGE_TITLE')}}</dt>
          <dd>{{t('STUDIES.COURSE_UNIT_INFO_MODAL.ALL_ASSESSMENT_ITEMS_REQUIRED')}}</dd>
        </ng-template>
      </dl>
      <dl *ngIf="completionMethod?.typeOfRequire !== 'ALL_SELECTED_REQUIRED'" >
        <ng-template #listItem *ngIf="completionMethod?.typeOfRequire === 'OPTIONAL_WITH_REQUIRE_RANGE'">
          <dt class="sis-label">{{t('STUDIES.COURSE_UNIT_INFO_MODAL.REQUIRE_RANGE_TITLE')}}</dt>
          <dd>{{ completionMethod?.require | intRange }}</dd>
        </ng-template>
        <ng-template #listItem  *ngIf="completionMethod?.assessmentItemOptionalityDescription">
          <dt class="sis-label">{{t('STUDIES.COURSE_UNIT_INFO_MODAL.OPTIONALITY_DESCRIPTION_TITLE')}}</dt>
          <dd>
            <sis-localized-markup-string-view *ngIf="useLocalizationFallback"
                                              [content]="completionMethod.assessmentItemOptionalityDescription">
            </sis-localized-markup-string-view>
            <sis-course-unit-info-modal-text-field *ngIf="!useLocalizationFallback"
                                                   [localizedMarkupStringValue]="completionMethod.assessmentItemOptionalityDescription"
                                                   [language]="language">
            </sis-course-unit-info-modal-text-field>
          </dd>
        </ng-template>
      </dl>
    </sis-description-list>
  </div>

  <div class="assessment-item-info" *ngIf="completionMethod">
    <sis-description-list [fillByColumn]="false">
      <dl>
        <ng-template #listItem *ngIf="completionMethod.description || showEmptyFields">
          <dt class="sis-label">{{t('STUDIES.COURSE_UNITS_COMPLETION_METHODS.COMPLETION_METHOD.DESCRIPTION')}}</dt>
          <dd>
            <sis-localized-markup-string-view *ngIf="useLocalizationFallback"
                                              [content]="completionMethod.description">
            </sis-localized-markup-string-view>
            <sis-course-unit-info-modal-text-field *ngIf="!useLocalizationFallback"
                                                   [localizedMarkupStringValue]="completionMethod.description"
                                                   [language]="language">

            </sis-course-unit-info-modal-text-field>
          </dd>
        </ng-template>
        <ng-template #listItem *ngIf="completionMethod.evaluationCriteria || showEmptyFields">
          <dt class="sis-label">{{t('STUDIES.COURSE_UNITS_COMPLETION_METHODS.COMPLETION_METHOD.EVALUATION_CRITERIA')}}</dt>
          <dd>
            <sis-localized-markup-string-view *ngIf="useLocalizationFallback"
                                              [content]="completionMethod.evaluationCriteria">
            </sis-localized-markup-string-view>
            <sis-course-unit-info-modal-text-field *ngIf="!useLocalizationFallback"
                                                   [localizedMarkupStringValue]="completionMethod.evaluationCriteria"
                                                   [language]="language">

            </sis-course-unit-info-modal-text-field>
          </dd>
        </ng-template>
        <ng-template #listItem>
          <dt class="sis-label">{{t('STUDIES.COURSE_UNITS_COMPLETION_METHODS.COMPLETION_METHOD.STUDY_PERIOD_REPEAT')}}</dt>
          <dd>
            <sis-completion-method-repeats [completionMethod]="completionMethod" [language]="language"></sis-completion-method-repeats>
          </dd>
        </ng-template>
      </dl>
    </sis-description-list>
  </div>
</ng-container>
