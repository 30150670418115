import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { DegreeProgramme, EntityWithRule, Module, ModuleResultItem, ModuleSearch, OtmId, SearchResult } from 'common-typescript/types';
import * as _ from 'lodash';
import { combineLatest, Observable, of, tap, throwError } from 'rxjs';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

import { searchRequestToQueryParams } from '../search-ng/search-utils';
import { UniversityService } from '../service/university.service';

import { EntityService } from './entity.service';
import { SisuDataLoader } from './SisuDataLoader';

const CONFIG = {
    ENDPOINTS: {
        backend: '/kori/api',
        get byGroupId() {
            return `${this.backend}/modules/by-group-id`;
        },
        findByUnauthenticated() {
            return `${this.backend}/modules`;
        },
        findByAuthenticated() {
            return `${this.backend}/authenticated/modules`;
        },
        searchUnauthenticated() {
            return `${this.backend}/module-search`;
        },
        searchAuthenticated() {
            return `${this.backend}/authenticated/module-search`;
        },
    },
};

export const ModuleType = {
    EDUCATION: 'Education',
    DEGREE_PROGRAMME: 'DegreeProgramme',
    STUDY_MODULE: 'StudyModule',
    GROUPING_MODULE: 'GroupingModule',
};

export const StudyRightSelectionType = {
    NONE: 'urn:code:study-right-selection-type:none',
    MINOR: 'urn:code:study-right-selection-type:minor-study-right',
};

export function isDegreeProgramme(module: EntityWithRule): module is DegreeProgramme {
    return module?.type === ModuleType.DEGREE_PROGRAMME;
}

@StaticMembers<DowngradedService>()
@Injectable({
    providedIn: 'root',
})
@NgEntityServiceConfig({
    baseUrl: CONFIG.ENDPOINTS.backend,
    resourceName: 'modules',
})
export class ModuleEntityService extends EntityService<ModuleState> {

    static downgrade: ServiceDowngradeMappings = {
        dependencies: [],
        moduleName: 'sis-components.service.moduleEntityService',
        serviceName: 'moduleEntityService',
    };

    constructor(private universityService: UniversityService) {
        super(ModuleStore, ModuleQuery);
        this.groupIdDataloader = new SisuDataLoader<OtmId, Module, Module>(
            {
                getByIdsCall: groupIds => this.createGetByGroupIdsCall(groupIds),
                resultExtractor: (groupId, entities) => entities.find(entity => entity.groupId === groupId),
            },
        );
    }

    private readonly groupIdDataloader: SisuDataLoader<OtmId, Module, Module>;

    /**
     * Optional parameters (curriculumPeriodId, documentStates, preferByState) in corresponding KORI endpoint are not supported by this
     * method. Also universityId is hard coded to be current university. Optional parameters would make batching quite complex as the
     * batching should be done per distinct parameter set. "Simplest solution" that came to my mind was:
     *  1. Check if there exists Dataloader in Map<parameters,Dataloader> for these parameters. If yes skip to 3.
     *  2. Create new Dataloader and insert it with parameters into Map<parameters,Dataloader>.
     *  3. Get Dataloader from Map<parameters,Dataloader> with parameters.
     *  4. Call Dataloader.load.
     *
     * @param groupId groupId of the module
     */
    getByGroupId(groupId: OtmId): Observable<Module> {
        if (!groupId) {
            return throwError(() => new Error('The group id was missing!'));
        }
        return this.groupIdDataloader.load(groupId);
    }

    /**
     * This is a wrapper to getByGroupId. You probably should be using it directly. Usage of this method is usually for centralized
     * relation loading which is unnecessary with dataloader.
     */
    getByGroupIds(groupIds: OtmId[]): Observable<Module[]> {
        const filteredGroupIds = _.uniq(groupIds ?? []).filter(Boolean);
        if (filteredGroupIds.length === 0) {
            return of([]);
        }
        return combineLatest(filteredGroupIds.map(groupId => this.getByGroupId(groupId)));
    }

    /**
     *  Not using data loader
     *  In the getByGroupId function's description there is a note how this could be implemented,
     *  implementing left for someone who has the will to do it
     */
    getByGroupIdsAndCurriculumPeriodIdAndDocumentStates(groupIds: OtmId[], curriculumPeriodId: OtmId, documentStates: string[]): Observable<Module[]> {
        return this.getHttp().get<Module[]>(
            CONFIG.ENDPOINTS.byGroupId,
            { params: { groupId: groupIds.toString(), universityId: this.universityService.getCurrentUniversityOrgId(), curriculumPeriodId, documentStates } },
        ).pipe(tap((modules) => this.store.upsertMany(modules)));
    }

    private createGetByGroupIdsCall(groupIds: OtmId[]): Observable<Module[]> {
        return this.getHttp().get<Module[]>(
            CONFIG.ENDPOINTS.byGroupId,
            { params: { groupId: groupIds.toString(), universityId: this.universityService.getCurrentUniversityOrgId() } },
        ).pipe(tap((modules) => this.store.upsertMany(modules)));
    }

    findByGroupId(groupId: OtmId) {
        return this.getHttp().get<Module[]>(
            CONFIG.ENDPOINTS.findByAuthenticated(),
            {
                params: {
                    groupId,
                    documentState: ['ACTIVE', 'DRAFT'],
                },
            },
        ).pipe();
    }

    findByGroupIdUnauthenticated(groupId: OtmId): Observable<Module[]> {
        return this.getHttp().get<Module[]>(
            CONFIG.ENDPOINTS.findByUnauthenticated(),
            {
                params: {
                    groupId,
                },
            },
        );
    }

    storeUpsert(module: Module) {
        this.store.upsert(module.id, module);
    }

    storeRemove(id: OtmId) {
        this.store.remove(id);
    }

    searchUnauthenticated(searchParams: Partial<ModuleSearch>): Observable<SearchResult<ModuleResultItem>> {
        return this.getHttp().get<SearchResult<ModuleResultItem>>(CONFIG.ENDPOINTS.searchUnauthenticated(), { params: this.toQueryParams(searchParams) });
    }

    searchAuthenticated(searchParams: Partial<ModuleSearch>): Observable<SearchResult<ModuleResultItem>> {
        return this.getHttp().get<SearchResult<ModuleResultItem>>(CONFIG.ENDPOINTS.searchAuthenticated(), { params: this.toQueryParams(searchParams) });
    }

    private toQueryParams(searchRequest: Partial<ModuleSearch>): { [key: string]: string | string[] } {
        if (_.isEmpty(searchRequest)) {
            return {};
        }

        return _.omitBy(
            {
                ...searchRequestToQueryParams(searchRequest),
                approvalState: searchRequest.approvalStates,
                documentState: searchRequest.documentStates,
                moduleType: searchRequest.type,
                codeUrn: searchRequest.codeUrns,
                curriculumPeriodId: searchRequest.curriculumPeriodIds,
                universityOrgId: searchRequest.universityOrgIds,
                orgId: searchRequest.organisationIds,
                orgRootId: searchRequest.organisationRootIds,
                ignoreValidityPeriod: _.toString(searchRequest.ignoreValidityPeriod),
                validity: searchRequest.validity,
            },
            _.isEmpty,
        );
    }
}

type ModuleState = EntityState<Module, OtmId>;

@StoreConfig({ name: 'modules' })
class ModuleStore extends EntityStore<ModuleState> {}

class ModuleQuery extends QueryEntity<ModuleState> {
    constructor(protected store: ModuleStore) {
        super(store);
    }
}
