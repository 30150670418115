<h4>{{ labelText }}</h4>
<sis-popover [autoClose]="autoClose"
             (shown)="isOpen()"
             (hidden)="isHidden()"
             *ngIf="!hideInfo"
             popoverClass="{{flip && 'flip'}} {{dark && 'dark'}}"
             popoverPlacement="{{flip ? 'right' : popoverPlacement}}">
  <button type="button"
          class="non-button sis-popover-trigger-content"
          [class.flip]="flip"
          [attr.aria-expanded]="popoverShown"
          [attr.aria-label]="'ARIA_LABEL.SHOW_DETAILS' | translate">
    <sis-icon [icon]="iconName" [color]="iconColor"></sis-icon>
  </button>
  <div class="sis-popover-popover-content">
    <p class="guidance">{{ popoverText }}</p>
    <ng-content></ng-content>
  </div>
</sis-popover>
