import {
    AfterContentInit,
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewEncapsulation,
} from '@angular/core';

import { ExpandableContentDirective } from '../expandable-directives/expandable-content.directive';
import { ExpandableCustomTitleDirective } from '../expandable-directives/expandable-custom-title.directive';
import { ExpandableHeaderButtonsDirective } from '../expandable-directives/expandable-header-buttons.directive';

export type ExpandableVariant = 'regular' | 'naked' | 'naked-dotted' | 'naked-dotted-transparent' | 'primary' | 'primary-light' | 'dark-gray' | 'middle-gray' | 'link';

/**
 *  Sis-expandable component has 9 different variants: regular, naked, naked-dotted, naked-dotted-transparent,
 *  primary, primary-light, dark-gray, middle-gray and link, from which regular is the default.
 *
 * The title can be given either as a normal string trough title input or by using `<ng-template sisExpandableCustomTitle>` template block,
 * if you need to build a custom title with other elements like badge. If you'd like to add just a secondary title, you can do so by using
 * the subTitle input with simple default style. It's possible to use both ways simultaneously as well, for example giving title as
 * an input and conditional badge through sisExpandableCustomTitle template.
 *
 * If you want to add some extra functional buttons for the expandable header, you can use the `<ng-template sisExpandableHeaderButtons>`
 * template block. It's not recommended to add buttons through customTitle block due to accessibility reasons. sisExpandableHeaderButtons
 * sets buttons to the right side of the header bar by default and on sm-sized screen they move to their own level.
 *
 * The expandable semantic level is set to equal `h2` as a default and can be changed via the level input: `level: 2` equals to `h2`,
 * `level: 3` equals to `h3` etc. Changing the level does not affect to the appearance of the component, as it is used only for the semantic
 * purposes to support screen reader use.
 *
 * If you want to adjust visual size of the header text, you can use the 'size' input with values `xxs`, `xs`, `sm`, `md`, `lg`, `xl` or
 * `xxl`. Size defaults to `sm`, except with the link variant the size is always `xxs`. Sizes from `md` to `xxl` equals to Fudis heading
 * size styles. Sizes `xs` and `xxs` also shrinks the component padding.
 *
 * Expandable variant link is more simplified version of the expandable: custom title block, and additional title properties are not
 * applicable. Visually it's a link button that toggles the expandable content, but also provides the aria-level support for screen readers.
 *
 * The expandable is closed initially by default; this can be controlled with the `closed` input property.
 *
 * The content of the expandable is only initialized when the expandable is expanded for the first time. This helps
 * to avoid unnecessary requests to the backend (among other expensive operations). Subsequent closing/expanding
 * only hides the content instead of removing it from the DOM, for the same reason. For this lazy initialization to
 * work properly, the content needs to be provided using an `ng-template` tagged with an `ExpandableContentDirective`.
 * See https://angular.io/guide/content-projection#conditional-content-projection for more background.
 *
 * Example usage:
 *
 *  ```
 * <sis-expandable [closed]="true">
 *   <ng-template sisExpandableCustomTitle>
 *     <your-title-template/>
 *   </ng-template>
 *   <ng-template sisExpandableHeaderButtons>
 *     <button/>
 *   </ng-template>
 *   <ng-template sisExpandableContent>
 *     <your-body-template/>
 *   </ng-template>
 * </sis-expandable>
 * ```
 */
@Component({
    selector: 'sis-expandable',
    templateUrl: './expandable.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ExpandableComponent implements AfterContentInit {

    @ContentChild(ExpandableContentDirective) content: ExpandableContentDirective;
    @ContentChild(ExpandableCustomTitleDirective) customTitle: ExpandableCustomTitleDirective;
    @ContentChild(ExpandableHeaderButtonsDirective) headerButtons: ExpandableHeaderButtonsDirective;

    /** If you need to add other elements like badge, use `<ng-template sisExpandableCustomTitle>` like shown above. */
    @Input() title?: string;
    /** Size defaults to `sm`, except with the link variant the size is always `xxs`. Sizes from `md` to `xxl` equals to Fudis heading size
     * styles.
     * */
    @Input() size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' = 'sm';
    /**
     * If you need a two level title you can use the `subTitle` input string with default styles or build and style yourself with the
     * `<ng-template sisExpandableCustomTitle>` like shown above. DO NOT USE with the `nakedDotted` or `nakedDottedTransparent` variants,
     * as it's not supported!
     */
    @Input() subTitle?: string;
    /**
     * Determines header's semantic aria-level for screen readers, default is equivalent for h2
     */
    @Input() level?: number = 2;
    @Input() variant?: ExpandableVariant = 'regular';
    @Input() contentPadding?: 'default' | 'small' | 'none' = 'default';

    /**
     * elementId can be passed to enable scrolling/focusing to expandable header button element
     */
    @Input() elementId?: string;

    /**
     * Expandable is initially closed by default but can be controlled by [closed] input property
     */
    @Input() set closed(value: boolean) {
        this.setClosedStatus(value);
    }

    /**
     * Optional output function when the closed status changes
     */
    @Output() closedChange = new EventEmitter<boolean>();

    /** Internal boolean of whether the expandable is currently closed */
    protected _closed = true;

    /** Lazy loading check for expanding content */
    protected _openedOnce = false;

    customTitleTemplate: TemplateRef<unknown>;
    headerButtonsTemplate: TemplateRef<unknown>;
    contentTemplate: TemplateRef<unknown>;

    constructor(public ref: ElementRef) {
    }

    ngAfterContentInit() {
        this.customTitleTemplate = this.customTitle?.templateRef;
        this.headerButtonsTemplate = this.headerButtons?.templateRef;
        this.contentTemplate = this.content?.templateRef;
    }

    get naked(): boolean {
        return this.variant.includes('naked');
    }

    get dotted(): boolean {
        return this.variant.includes('dotted');
    }

    get transparent(): boolean {
        return this.variant.includes('transparent');
    }

    get link(): boolean {
        return this.variant.includes('link');
    }

    get slim(): boolean {
        return this.link || this.size.includes('xxs') || this.size.includes('xs');
    }

    get _size(): string {
        return this.link ? 'xxs' : this.size;
    }

    get _contentPadding(): string {
        if (this.contentPadding.includes('default')) {
            if (this.size.includes('xxs') || this.link) return 'small';
            if (this.size.includes('xs')) return 'slim';
        }
        return this.contentPadding;
    }

    public getClosedStatus(): boolean {
        return this._closed;
    }

    setClosedStatus(value: boolean): void {
        this._closed = value ?? this._closed;
        this._openedOnce = this._openedOnce || !this._closed;
        this.closedChange.emit(this._closed);
    }
}
