import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SisCommonModule } from 'sis-common/sis-common.module';
import { SisComponentsModule } from 'sis-components/sis-components.module';

import { StudentMessageConversationsListComponent } from './student-message-conversations-list.component';

@NgModule({
    declarations: [
        StudentMessageConversationsListComponent,
    ],
    imports: [
        CommonModule,
        SisCommonModule,
        SisComponentsModule,
        TranslateModule.forChild(),
    ],
})
export class MessageConversationsModule {}
