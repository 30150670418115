(function () {
  'use strict';

  rangeFactory.$inject = ["RangeType"];
  angular.module('student.shared').factory('Range', rangeFactory);
  function rangeFactory(RangeType) {
    /**
     * Usage:
     *
     * new Range(1) -> min=1, max=1
     * new Range(1, null) -> min=1, unlimited max
     * new Range({ min: 1, max: 2 }) -> min=1, max=2
     *
     * @param min
     * @param max
     * @constructor
     */
    function Range(min, max) {
      if (arguments.length === 1) {
        if (_.isObject(min)) {
          this.min = toNumber(min.min, 'min', 0);
          this.max = toNumber(min.max, 'max', null);
        } else {
          this.min = this.max = toNumber(min, 'min', 0);
        }
      } else {
        this.min = toNumber(min, 'min', 0);
        this.max = toNumber(max, 'max', null);
      }
    }
    Range.prototype.comparableMax = function () {
      return toComparableMax(this.max);
    };
    Range.prototype.hasDistinctMax = function () {
      return this.hasMax() && this.max !== this.min;
    };
    Range.prototype.hasMax = function () {
      return _.isNumber(this.max) && this.max !== +Infinity;
    };
    Range.prototype.add = function (other) {
      if (other) {
        other = Range.cast(other);
        var min = null,
          max = null;
        var thisMin = _.isFinite(this.min) ? this.min : null;
        var otherMin = _.isFinite(_.get(other, 'min')) ? other.min : null;
        if (thisMin !== null || otherMin !== null) {
          // Missing lower bound defaults to 0, but only if at least one of the input ranges has a lower bound
          min = _.defaultTo(thisMin, 0) + _.defaultTo(otherMin, 0);
        }
        if (_.isFinite(this.max) && _.isFinite(_.get(other, 'max'))) {
          // The result will only have an upper bound if both input ranges have an upper bound
          max = this.max + other.max;
        }
        return new Range(min, max);
      }
      return this;
    };
    Range.prototype.getType = function () {
      if (this.min === this.max) {
        if (this.min === 1) {
          return RangeType.ONE;
        }
        return RangeType.EXACTLY;
      } else if (this.hasMax()) {
        if (this.min === 0) {
          return RangeType.MAX;
        }
        return RangeType.BETWEEN;
      } else {
        return RangeType.MIN;
      }
    };
    // This cannot be a constant as Range is NOT immutable!
    Range.zero = function () {
      return new Range(0);
    };
    Range.cast = function (input) {
      if (input instanceof Range) {
        return input;
      } else {
        return new Range(input);
      }
    };
    Range.sumRanges = function (ranges) {
      // This function can be invoked either with a plain array or using varargs (i.e. rest parameters),
      // or it can be used as an iteratee for _.reduce()
      if (!_.isArray(ranges)) {
        ranges = _.filter(arguments, function (arg) {
          return _.isPlainObject(arg) || arg instanceof Range;
        });
      }
      return _.reduce(ranges, function (sum, range) {
        return Range.cast(sum).add(range);
      }, new Range(0));
    };
    Range.divideRange = function (range, divider) {
      return Range.cast({
        min: range.min / divider,
        max: range.max === null ? null : range.max / divider
      });
    };
    return Range;
  }
  function toNumber(value, name, defaultValue) {
    if (_.isNil(value)) {
      return defaultValue;
    } else if (_.isFinite(value) || _.isFinite(Number(value))) {
      return Number(value);
    } else {
      throw Error('Expected number for ' + name + ', got ' + value);
    }
  }
  function toComparableMax(max) {
    if (_.isNumber(max)) {
      return max;
    } else {
      return +Infinity;
    }
  }
})();