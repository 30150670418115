<sis-expandable *ngIf="loaded"
                data-cy="open-university-study-right"
                [level]="4"
                [title]="(education.name | localizedString) + ', ' + (organisation.name | localizedString)"
                [subTitle]="('OPENUNIVERSITY.STUDY_RIGHTS.VALID' | translate) + ' ' + (studyRight.valid | localDateRange)">
  <ng-template sisExpandableCustomTitle>
    <sis-study-right-status-badge [studyRightId]="studyRight.id"/>
  </ng-template>
  <ng-template sisExpandableContent>
    <div class="row">
      <div class="col-12 col-md-6">
        <h5 class="fudis-heading__md">{{'OPENUNIVERSITY.STUDY_RIGHTS.BASIC_INFO' | translate}}</h5>
        <sis-description-list [fillByColumn]="false">
          <dl>
            <ng-template #listItem>
              <dt class="sis-label">{{ 'OPENUNIVERSITY.STUDY_RIGHTS.ORGANISATION' | translate }}</dt>
              <dd>{{ organisation.name | localizedString }}</dd>
            </ng-template>
            <ng-template #listItem>
              <dt class="sis-label">{{ 'OPENUNIVERSITY.STUDY_RIGHTS.VALID' | translate }}</dt>
              <dd>{{ studyRight.valid | localDateRange }}</dd>
            </ng-template>
            <ng-template #listItem>
              <dt class="sis-label">{{ 'OPENUNIVERSITY.STUDY_RIGHTS.GRANT_DATE' | translate }}</dt>
              <dd>{{ studyRight.grantDate | localDateFormat }}</dd>
            </ng-template>
            <ng-template #listItem>
              <dt class="sis-label">{{ 'OPENUNIVERSITY.STUDY_RIGHTS.EXPIRATION_RULES' | translate }}</dt>
              <dd>{{ studyRight.studyRightExpirationRulesUrn | codeLocalizedName | async }}</dd>
            </ng-template>
          </dl>
        </sis-description-list>
        <sis-custom-code-urns *ngIf="studyRight.codeUrns" [codeUrns]="studyRight.codeUrns" [classificationScope]="'study-right'"/>
      </div>
      <div class="col-12 col-md-6">
        <sis-study-right-states [studyRightId]="studyRight.id"/>
      </div>
    </div>
    <hr class="sis-hr sis-mb-md" aria-hidden="true"/>
    <ng-container *ngIf="enrolmentRights?.length > 0">
      <h5 class="fudis-heading__md">{{ 'OPENUNIVERSITY.STUDY_RIGHTS.ENROLMENT_RIGHTS' | translate }}</h5>
      <div data-cy="open-university-enrolment-rights">
        <sis-enrolment-right-expandable *ngFor="let enrolmentRight of enrolmentRights; trackBy: entityId"
                                        [enrolmentRight]="enrolmentRight" [componentType]="'STUDENT'"/>
      </div>
    </ng-container>
  </ng-template>
</sis-expandable>
