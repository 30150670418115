import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output, ViewEncapsulation } from '@angular/core';
import { Attainment, CourseUnit, OtmId } from 'common-typescript/types';
import { NEVER, Observable, Subscription } from 'rxjs';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { AppErrorHandler } from '../../error-handler/app-error-handler';
import { CourseUnitEntityService } from '../../service/course-unit-entity.service';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-course-unit-box',
    templateUrl: './course-unit-box.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CourseUnitBoxComponent implements OnDestroy {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.courseUnit.courseUnitBox',
        directiveName: 'sisCourseUnitBox',
    };

    /**
     * You should define only one of courseUnit, courseUnitId and courseUnitGroupId. Anything else does not make sense. The resolved courseUnit
     * will be cleared if courseUnitId or courseUnitGroupId is changed to undefined.
     */
    @Input() courseUnit?: CourseUnit;

    @Input() set courseUnitId(courseUnitId: OtmId) {
        this.setSubscription(courseUnitId, id => this.courseUnitEntityService.getById(id));
    }

    @Input() set courseUnitGroupId(courseUnitGroupId: OtmId) {
        this.setSubscription(courseUnitGroupId, id => this.courseUnitEntityService.getByGroupId(id));
    }

    @Input() attainment: Attainment;

    // css class for root div, possibly a color
    @Input() categoryCssClass?: string;
    // Code will be not clickable when not observing this output.
    @Output() codeClickAction = new EventEmitter<OtmId>();

    courseUnitSubscription: Subscription = NEVER.subscribe();

    constructor(
        private appErrorHandler: AppErrorHandler,
        private courseUnitEntityService: CourseUnitEntityService,
        private ref: ChangeDetectorRef,
    ) {
    }

    ngOnDestroy() {
        this.courseUnitSubscription.unsubscribe();
    }

    private setSubscription(id: OtmId, callback: (id: OtmId) => Observable<CourseUnit>) {
        this.courseUnitSubscription.unsubscribe();
        if (!id) {
            this.courseUnit = null;
            return;
        }
        this.courseUnitSubscription = callback(id)
            .pipe(this.appErrorHandler.defaultErrorHandler())
            .subscribe((courseUnit: CourseUnit) => {
                this.courseUnit = courseUnit;
                // manually fire change detection as it seemed slow when using in angularjs
                this.ref.detectChanges();
            });
    }
}
