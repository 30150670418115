import { Injectable } from '@angular/core';

/**
 * Wrapper for localStorage. This will make mocking easier.
 */
@Injectable({ providedIn: 'root' })
export class LocalStorageService {

    clear(): void {
        return localStorage.clear();
    }

    getItem(key: string): string | null {
        return localStorage.getItem(key);
    }

    key(index: number): string | null {
        return localStorage.key(index);
    }

    get length(): number {
        return localStorage.length;
    }

    removeItem(key: string): void {
        return localStorage.removeItem(key);
    }

    setItem(key: string, value: string): void {
        return localStorage.setItem(key, value);
    }

}
