import { Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { AssessmentItem, CompletionMethod, CourseUnit, CourseUnitRealisation } from 'common-typescript/types';
import { Observable } from 'rxjs';
import { AssessmentItemEntityService } from 'sis-components/service/assessment-item-entity.service';
import { trackByEntityId } from 'sis-components/util/utils';

import { AggregateProductInfo } from '../types';

@Component({
    selector: 'app-product-details',
    templateUrl: './product-details.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ProductDetailsComponent implements OnChanges {

    @Input() courseUnit: CourseUnit;
    @Input() product: AggregateProductInfo;
    @Input() mobileView: boolean;

    completionMethod: CompletionMethod;
    assessmentItems$: Observable<AssessmentItem[]>;

    readonly entityId = trackByEntityId;

    constructor(
        private assessmentItemService: AssessmentItemEntityService,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.courseUnit?.currentValue?.id !== changes.courseUnit?.previousValue?.id ||
            changes.product?.currentValue?.id !== changes.product?.previousValue?.id) {
            this.completionMethod = this.courseUnit?.completionMethods?.find(cm => cm.localId === this.product?.completionMethodId);
            const assessmentItemIds = Object.keys(this.product?.teachingOnSale ?? {});
            if (assessmentItemIds?.length > 0) {
                this.assessmentItems$ = this.assessmentItemService.getByIdsSorted(assessmentItemIds, this.courseUnit?.id);
            } else {
                delete this.assessmentItems$;
            }
        }
    }
}
