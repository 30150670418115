import angular from 'angular';
import 'js-data';
import { studyRightModelModule } from 'sis-components/model/studyRight.model';
import { applicationDecisionModelModule } from 'sis-components/model/applicationDecision.model';
import { courseUnitJSDataModelModule } from 'sis-components/model/courseUnit.model';
import { degreeProgrammeModelModule } from 'sis-components/model/degreeProgramme.model';
import { educationModelModule } from 'sis-components/model/education.model';
import { privatePersonInfoModelModule } from 'sis-components/model/privatePersonInfo.model';
import { publicPersonModuleModel } from 'sis-components/model/publicPerson.model';
import { moduleJSDataModelModule } from 'sis-components/model/module.model';
export const studentApplicationModelModule = 'sis-components.model.studentApplication';
(function () {
  studentApplicationJSDataModel.$inject = ["DS", "studentApplicationEndpoint", "applicationDecisionJSDataModel", "privatePersonInfoJSDataModel", "courseUnitJSDataModel", "publicPersonModel", "degreeProgrammeJSDataModel", "planModuleJSDataModel", "educationJSDataModel", "studyRightJSDataModel", "EFFECTIVE_MODULE_CONTENT_APPLICATION_STATES"];
  angular.module(studentApplicationModelModule, ['js-data', applicationDecisionModelModule, courseUnitJSDataModelModule, degreeProgrammeModelModule, educationModelModule, privatePersonInfoModelModule, publicPersonModuleModel, moduleJSDataModelModule, studyRightModelModule]).factory('studentApplicationJSDataModel', studentApplicationJSDataModel).constant('studentApplicationEndpoint', {
    DEFAULT: '/ori/api/student-applications',
    SEARCH: '/ori/api/student-applications/search',
    EFFECTIVE_STUDENT_APPLICATIONS_FOR_STUDENT: '/ori/api/student-applications/effective-for-student',
    MY_EFFECTIVE_STUDENT_APPLICATIONS: '/ori/api/student-applications/my-effective/',
    REVOKE: '/ori/api/student-applications/revoke'
  }).constant('STUDENT_APPLICATION_STATE', {
    REQUESTED: 'REQUESTED',
    REJECTED: 'REJECTED',
    ACCEPTED: 'ACCEPTED',
    IN_HANDLING: 'IN_HANDLING',
    CANCELLED: 'CANCELLED',
    CONDITIONAL: 'CONDITIONAL',
    REVOKED: 'REVOKED',
    ACCEPTANCE_REVOKED: 'ACCEPTANCE_REVOKED',
    SUPPLEMENT_REQUESTED: 'SUPPLEMENT_REQUESTED'
  }).constant('STUDENT_WORKFLOW_STATE', {
    REQUESTED: 'REQUESTED',
    REJECTED: 'REJECTED',
    ACCEPTED: 'ACCEPTED',
    IN_HANDLING: 'IN_HANDLING',
    CANCELLED: 'CANCELLED',
    CONDITIONAL: 'CONDITIONAL',
    REVOKED: 'REVOKED',
    ACCEPTANCE_REVOKED: 'ACCEPTANCE_REVOKED',
    SUPPLEMENT_REQUESTED: 'SUPPLEMENT_REQUESTED'
  }).constant('STUDENT_APPLICATION_TYPE', {
    MODULE_ATTAINMENT_APPLICATION: 'ModuleAttainmentApplication',
    DEGREE_PROGRAMME_ATTAINMENT_APPLICATION: 'DegreeProgrammeAttainmentApplication',
    PRIOR_LEARNING_INCLUSION_APPLICATION: 'PriorLearningInclusionApplication',
    PRIOR_LEARNING_SUBSTITUTION_APPLICATION: 'PriorLearningSubstitutionApplication',
    CUSTOM_ATTAINMENT_APPLICATION: 'CustomAttainmentApplication',
    CUSTOM_MODULE_CONTENT_APPLICATION: 'CustomModuleContentApplication',
    REQUIRED_MODULE_CONTENT_APPLICATION: 'RequiredModuleContentApplication',
    STUDY_RIGHT_EXTENSION_APPLICATION: 'StudyRightExtensionApplication'
  }).constant('STUDENT_WORKFLOW_TYPE', {
    MODULE_ATTAINMENT_WORKFLOW: 'ModuleAttainmentWorkflow',
    DEGREE_PROGRAMME_ATTAINMENT_WORKFLOW: 'DegreeProgrammeAttainmentWorkflow',
    PRIOR_LEARNING_INCLUSION_WORKFLOW: 'PriorLearningInclusionWorkflow',
    PRIOR_LEARNING_SUBSTITUTION_WORKFLOW: 'PriorLearningSubstitutionWorkflow',
    CUSTOM_ATTAINMENT_WORKFLOW: 'CustomAttainmentWorkflow',
    CUSTOM_MODULE_CONTENT_WORKFLOW: 'CustomModuleContentWorkflow',
    REQUIRED_MODULE_CONTENT_WORKFLOW: 'RequiredModuleContentWorkflow',
    STUDY_RIGHT_EXTENSION_WORKFLOW: 'StudyRightExtensionWorkflow'
  }).constant('PRIOR_LEARNING_TYPE', {
    STUDIES: 'STUDIES',
    COMPETENCE: 'COMPETENCE'
  }).constant('RECOMMENDATION_STATE', {
    NOT_HANDLED: 'NOT_HANDLED',
    RECOMMENDED: 'RECOMMENDED',
    NOT_RECOMMENDED: 'NOT_RECOMMENDED'
  }).constant('EFFECTIVE_MODULE_CONTENT_APPLICATION_STATES', ['REQUESTED', 'IN_HANDLING', 'ACCEPTED', 'CONDITIONAL']).run(["studentApplicationJSDataModel", "applicationDecisionJSDataModel", "privatePersonInfoJSDataModel", function (studentApplicationJSDataModel, applicationDecisionJSDataModel, privatePersonInfoJSDataModel) {// NOSONAR
  }]);

  /**
   * @ngInject
   */
  function studentApplicationJSDataModel(DS, studentApplicationEndpoint, applicationDecisionJSDataModel, privatePersonInfoJSDataModel, courseUnitJSDataModel, publicPersonModel, degreeProgrammeJSDataModel, planModuleJSDataModel, educationJSDataModel, studyRightJSDataModel, EFFECTIVE_MODULE_CONTENT_APPLICATION_STATES) {
    return DS.defineResource({
      idAttribute: 'id',
      name: 'studentApplication',
      endpoint: studentApplicationEndpoint.DEFAULT,
      relations: {
        hasOne: {
          applicationDecision: {
            localField: 'decision',
            foreignKey: 'applicationId'
          },
          privatePersonInfo: {
            localField: 'student',
            localKey: 'studentId'
          },
          degreeProgramme: {
            localField: 'degreeProgramme',
            localKey: 'degreeProgrammeId'
          },
          courseUnit: {
            localField: 'courseUnit',
            localKey: 'courseUnitId'
          },
          module: {
            localField: 'module',
            localKey: 'moduleId'
          },
          publicPerson: {
            localField: 'handler',
            localKey: 'lastHandlerPersonId'
          },
          education: {
            localField: 'education',
            localKey: 'educationId'
          },
          studyRight: {
            localField: 'studyRight',
            localKey: 'studyRightId'
          }
        }
      },
      computed: {
        isEffective: ['state', function (applicationState) {
          return _.includes(EFFECTIVE_MODULE_CONTENT_APPLICATION_STATES, applicationState);
        }]
      }
    });
  }
})();