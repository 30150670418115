import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import angular from 'angular';
import _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';
import { UuidService } from 'sis-common/uuid/uuid.service';

import { getActionFromKey, SelectActions } from '../select-utility';

export interface Option {
    /** The underlying value of the option (will not be shown in the UI) */
    value: any;
    /** The label that will be shown for this option in the UI */
    label: string;
    /** Additional info to be displayed after the label */
    info?: string;
    /** Controls whether this option is disabled in the dropdown */
    disabled?: boolean;
    /** Controls whether this option is a header in the list of options */
    header?: boolean;
    /** Accessibility helper to figure out item indexes with headers */
    indexId?: number;
}

/** Header option interface for internal refactoredOptions */
interface HeaderOption {
    headerLabel: string;
    options: Option[];
}

/** Interface for internal refactoredOptions */
interface Refactored {
    nonGroupedOptions: Option[];
    groupedOptions: {
        [key: string]: HeaderOption;
    };
}

/**
 * Dropdown select component build with combobox and listbox structure. It supports two distinct modes of operation; providing
 * a `FormControl` object that stores the selected value OR using `selection` and `selectionChange` input and output.
 *
 * If a `FormControl` object is provided, the selected option in the UI will be kept in sync with the control, and the
 * `selectionChange` events will NOT be emitted (changes can be listened to via the control's `valueChanges` observable).
 * This is the recommended way of using the component in an Angular Reactive Form. If the `FormControl` is not provided,
 * then the component will emit selections via `selectionChange`.
 *
 * When using this component in an AngularJS component the `scope` parameter is mandatory, as otherwise the AngularJS
 * change detection will not be triggered by the selection events. Also, as the `FormControl` is an Angular-only concept,
 * using selection/selectionChanged is the only meaningful usage mode under AngularJS.
 */
@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-dropdown-select',
    templateUrl: './dropdown-select.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class DropdownSelectComponent implements OnInit, OnChanges, OnDestroy {
    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.select.dropdownSelect.downgraded',
        directiveName: 'sisDropdownSelect',
    };

    /** Dropdown options array */
    @Input() options: Option[];
    /** Possibility to disable the whole dropdown */
    @Input() disabled = false;
    /** Is dropdown required field */
    @Input() required = false;
    /** If true, the dropdown will have a cross icon that can be used to clear the selected value */
    @Input() clearable = false;
    /** If true, the dropdown will be rendered with a smaller form factor and without a border */
    @Input() compact = false;
    /** Align listbox element to the right */
    @Input() alignRight = false;
    /** A custom placeholder text to show when no selection has been made. Defaults to SIS_COMPONENTS.SELECT.CHOOSE. */
    @Input() placeholder: string;
    /** Track options by index */
    @Input() trackByIndex = false;
    /** Translation key for additional help-text block */
    @Input() helpBlock: string;
    /** Possible parameters for help-text */
    @Input() helpBlockParams: any;
    /** Dropdown form control */
    @Input() control: FormControl;
    /** Selected option */
    @Input() selection: any;
    /** Additional class attribute values to add to the input element, `form-control` is always added. */
    @Input() class: string;
    /** Given id or generated random UUID */
    @Input() id: string;
    /** Option to skip control's dirty check */
    @Input() skipDirtyCheck: boolean;
    /** If true, text color is lighter */
    @Input() onDarkBackground = false;
    /** Dropdown should always have a label which is paired as dropdown's `aria-labelledby` by the label id */
    @Input() ariaLabelledBy: string;
    /**
   * The $scope instance of the containing AngularJS component. Required when this component is used in an AngularJS
   * component; irrelevant otherwise. Without this the AngularJS change detection will not pick up the changes made
   * in this component.
   */
    @Input() scope: angular.IScope;
    /** Selection change output */
    @Output() selectionChange = new EventEmitter<any>();
    /** Is dropdown open output */
    @Output() openChange = new EventEmitter<any>();
    /** View child for the component wrapper div */
    @ViewChild('dropdownSelect') dropdownSelect: ElementRef;
    /** View child for the component "input" div */
    @ViewChild('dropdownSelectInput') dropdownSelectInput: ElementRef;

    selected: Option;
    destroyed$ = new Subject<void>();
    trackerFunction: Function;
    keyboardSearch = '';
    isDropDownOpen = false;

    /** Internal refactoredOptions object for modifying purposes */
    _refactoredOptions: Refactored = {
        nonGroupedOptions: [],
        groupedOptions: {},
    };

    /** Internal helper variable for looping possible option headers */
    _headersToLoop: string[] = [];

    /** Internal helper variable to calculate option indexes if headers exists */
    _headerArrayLength: number;

    static getNoSelectionOption(translateService: TranslateService): Option {
        return { label: translateService.instant('DROPDOWN_SELECT.NO_SELECTION'), value: null };
    }

    constructor(private uuidService: UuidService) {
    /** Follows the click and button events on current document,
     * so the dropdown can be closed if user clicks or tabulates outside the
     * dropdown-select component */
        document.addEventListener('click', this.offClickHandler.bind(this));
        document.addEventListener('keyup', this.offClickHandler.bind(this));
    }

    ngOnInit(): void {
        this.updateSelected(this.value);
        if (this.control) {
            this.control.valueChanges
                .pipe(takeUntil(this.destroyed$))
                .subscribe(value => this.updateSelected(value));
        }
        if (this.trackByIndex) {
            this.trackerFunction = this.getIndexTrackerFunction();
        } else {
            this.trackerFunction = this.getDefaultTrackerFunction();
        }

        this.id = this.id ? this.id : this.uuidService.randomUUID();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selection || changes.options) {
            const value = changes.selection ? changes.selection.currentValue : this.value;
            const options = changes.options ? changes.options.currentValue : this.options;
            this.updateSelected(value, options);
        }

        if (this.options?.length > 0) {
            let latestHeader: string | null = null;
            // Clear all options to avoid overlapping in spread operator below
            this._refactoredOptions.groupedOptions = {};
            this._refactoredOptions.nonGroupedOptions = [];
            this._headersToLoop = [];

            // Loop through all options and categorize them in header groups and non-header groups.
            // This is necessary for accessibility reasons; items have to be grouped in the template if header exists
            this.options.forEach((option, index) => {
                if (option.header) {
                    this._headersToLoop.push(option.value);
                    latestHeader = option.value;
                    this._refactoredOptions.groupedOptions = { ...this._refactoredOptions.groupedOptions, [option.value]: { headerLabel: option.label, options: [] } };
                } else if (!option.header && latestHeader === null) {
                    option.indexId = index;
                    this._refactoredOptions.nonGroupedOptions.push(option);
                } else if (latestHeader) {
                    this._headerArrayLength = Object.keys(this._refactoredOptions.groupedOptions).length;
                    option.indexId = index - this._headerArrayLength;
                    this._refactoredOptions.groupedOptions[latestHeader].options.push(option);
                }
            });
        }
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
    }

    get value(): any {
        if (this.selected) {
            return this.selected.value;
        }
        if (this.control) {
            return this.control.value;
        }
        if (this.selection) {
            return this.selection;
        }
        return undefined;
    }

    set value(value: any) {
        if (value !== this.value) {
            this.updateSelected(value);
            if (this.control) {
                this.control.setValue(value);
                this.control.markAsTouched();
                this.control.markAsDirty();
            } else if (this.scope) {
                this.scope.$apply(() => this.selectionChange.emit(value));
            } else {
                this.selectionChange.emit(value);
            }
        }
    }

    /** Select value by mouse click */
    selectValue(value: any) {
        this.value = value;
        this.toggleDropDown(false);
    }

    /** Keydown actions for combobox and listbox options */
    handleKeyDown(event: KeyboardEvent, index?: number | undefined) {
        const { key } = event;
        const action = getActionFromKey(event, this.isDropDownOpen);
        switch (action) {
            case SelectActions.Next:
            case SelectActions.PageDown:
                event.preventDefault();
                this.goToNext(index);
                break;
            case SelectActions.Previous:
            case SelectActions.PageUp:
                event.preventDefault();
                this.goToPrevious(index);
                break;
            case SelectActions.Close:
                event.preventDefault();
                this.toggleDropDown(false);
                break;
            case SelectActions.CloseSelect:
                event.preventDefault();
                this.selectOption(index);
                this.toggleDropDown(false);
                break;
            case SelectActions.Type:
                this.searchOptionWithKeyboard(key);
                break;
            case SelectActions.Open:
                event.preventDefault();
                this.toggleDropDown(true);
                break;
        }
    }

    /** Keydown actions for listbox item if there are no options  */
    noItemsKeyDown(event: any) {
        const action = getActionFromKey(event, this.isDropDownOpen);

        switch (action) {
            case SelectActions.Next:
            case SelectActions.PageDown:
            case SelectActions.Previous:
            case SelectActions.PageUp:
                event.preventDefault();
                break;
            case SelectActions.Close:
                event.preventDefault();
                this.toggleDropDown(false);
                break;
            case SelectActions.CloseSelect:
                event.preventDefault();
                break;
            case SelectActions.Open:
                event.preventDefault();
                this.toggleDropDown(true);
                break;
        }
    }

    /** Filter and jump to matching option */
    searchOptionWithKeyboard(e: any) {
        if (this.options) {
            this.keyboardSearch = this.keyboardSearch + e.key;
            const searchLower = this.keyboardSearch.toLowerCase();
            const matchingOptions = this.options.filter((option: Option) =>
                option.label?.toLowerCase().startsWith(searchLower) ||
                option.info?.toLowerCase() === searchLower);

            if (matchingOptions.length === 0) {
                this.keyboardSearch = '';
            }

            if (matchingOptions.length === 1) {
                this.value = matchingOptions[0].value;
                this.keyboardSearch = '';
            }
        }
    }

    /** Update selected option to the current value */
    private updateSelected(value: any, options: Option[] = this.options): void {
        this.selected = (options || []).find((option) => _.isEqual(option.value, value));
    }

    private getIndexTrackerFunction(): Function {
        return function (index: number, item: any) {
            return index;
        };
    }

    private getDefaultTrackerFunction(): Function {
        return function (index: number, item: any) {
            return item;
        };
    }

    /** Dropdown open state */
    toggleDropDown(isOpen?: boolean) {
        this.isDropDownOpen = isOpen === undefined ? !this.isDropDownOpen : isOpen;
        this.openChange.emit(this.isDropDownOpen);

        if (this.isDropDownOpen) {
            if (this.scope) {
                // workaround for the angularJS-angular update problem
                setTimeout(() => {
                    this.focusOnFirstOption();
                });
            } else {
                this.focusOnFirstOption();
            }
        } else {
            const el = document.getElementById(this.id);
            el.focus();
        }
    }

    /** Clear selected value from the dropdown field */
    clearSelection(): void {
        this.value = null;
        this.dropdownSelectInput.nativeElement.focus();
    }

    /** When dropdown opens focus on the first option */
    private focusOnFirstOption() {
        const firstElement = this.dropdownSelect.nativeElement.querySelector(`#sis-dropdown-select-${this.id}-item-0`);

        if (firstElement) {
            // Wait for the element to be in the DOM
            setTimeout(() => {
                firstElement.focus();
            }, 50);
        }
    }

    /** Arrow down action for listbox options */
    private goToNext(index: number | undefined) {
        if (index === this.options.length - (this._headerArrayLength + 1) || index === this.options.length - 1) {
            this.dropdownSelect.nativeElement.querySelector(`#sis-dropdown-select-${this.id}-item-0`).focus();
        } else if (index !== undefined) {
            this.dropdownSelect.nativeElement.querySelector(`#sis-dropdown-select-${this.id}-item-${index + 1}`).focus();
        }
    }

    /** Arrow up action for listbox options */
    private goToPrevious(index: number | undefined) {
        if (index === 0) {
            if (this._headerArrayLength > 0) {
                this.dropdownSelect.nativeElement.querySelector(`#sis-dropdown-select-${this.id}-item-${this.options.length - (this._headerArrayLength + 1)}`).focus();
            } else {
                this.dropdownSelect.nativeElement.querySelector(`#sis-dropdown-select-${this.id}-item-${this.options.length - 1}`).focus();
            }
        } else if (index !== undefined) {
            this.dropdownSelect.nativeElement.querySelector(`#sis-dropdown-select-${this.id}-item-${index - 1}`).focus();
        }
    }

    /** Key down action to select option and close dropdown action */
    private selectOption(index: number) {
        // Filter header options out to maintain correct index for selected option
        const optionsWithoutHeaders = this.options.filter(option => !option.header);

        if (!optionsWithoutHeaders[index].disabled) {
            this.value = optionsWithoutHeaders[index].value;
        }
    }

    /** Close dropdown if clicking outside the dropdown element */
    private offClickHandler(event: any) {
        if (!this.dropdownSelect?.nativeElement.contains(event.target)) {
            this.isDropDownOpen = false;
            this.openChange.emit(this.isDropDownOpen);
        }
    }
}
