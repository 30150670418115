import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UIRouterGlobals } from '@uirouter/core';
import { FeedbackUserGroup } from 'common-typescript/types';
import moment from 'moment';
import { LocalStorageService } from 'sis-common/storage/local-storage.service';

import { FeedbackService } from './feedback.service';

@Component({
    selector: 'sis-feedback-modal',
    templateUrl: './feedback-modal.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class FeedbackModalComponent implements OnInit {

    private readonly localStorageKey = 'feedback-timestamp';
    private lastFeedbackTimestamp: moment.Moment;

    constructor(
        @Inject(FeedbackService) private feedbackService: FeedbackService,
        private uiRouterGlobals: UIRouterGlobals,
        public modal: NgbActiveModal,
        private localStorageService: LocalStorageService,
    ) { }

    ngOnInit() {
        const value = this.localStorageService.getItem(this.localStorageKey);
        if (value) {
            this.lastFeedbackTimestamp = moment(value);
        }
    }

    private resolveUserGroup(): FeedbackUserGroup {
        if (this.uiRouterGlobals.current.name.startsWith('student.')) { return 'STUDENT'; }
        if (this.uiRouterGlobals.current.name.startsWith('teacher.')) { return 'TEACHER'; }
        if (this.uiRouterGlobals.current.name.startsWith('tutor.')) { return 'TUTOR'; }
        if (this.uiRouterGlobals.current.name.startsWith('staff.')) { return 'STAFF'; }
        if (this.uiRouterGlobals.current.name.startsWith('admin.')) { return 'ADMIN'; }

        return null;
    }

    public leavingFeedbackAllowed(): boolean {
        return !this.lastFeedbackTimestamp || this.lastFeedbackTimestamp.isSameOrBefore(moment().subtract(12, 'hours'));
    }

    public leaveFeedback(npsValue: number): void {
        this.localStorageService.setItem(this.localStorageKey, moment().format());
        this.lastFeedbackTimestamp = moment(this.localStorageService.getItem(this.localStorageKey));
        this.feedbackService.postFeedback(npsValue, this.resolveUserGroup());
    }
}
