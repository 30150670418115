import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input, OnChanges,
    Output,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import angular from 'angular';

import {
    getActionFromKey,
    getIndexByLetter,
    getUpdatedIndex,
    isElementInView,
    isScrollable,
    maintainScrollVisibility,
    SelectActions,
} from '../select-utility';

export interface SelectOption {
    /** The underlying value of the option (will not be shown in the UI) */
    value: any;
    /** The label that will be shown for this option in the UI */
    label: string;
    /** additional info to be displayed on the right side of the label */
    info?: string;
}

/**
 * This software or document includes material copied from or derived from https://w3c.github.io/aria-practices/examples/combobox/combobox-select-only.html. Copyright © 2022 W3C® (MIT, ERCIM, Keio, Beihang).
 * Utility functions moved to another file 'select-utility.ts'
 * Should work like Editable Combobox Example - https://www.24a11y.com/2019/select-your-poison-part-2/#select-poison-recommendations
 *
 * When collapsed
 *
 *     Enter, Space, Up Arrow or Down Arrow will all expand the dropdown, and focus will be on the first option, or the most recently highlighted option. Printable characters will also expand the dropdown, update the value, and move focus to the first matching option.
 *
 * When expanded
 *
 *     Enter - selects the current option and closes the dropdown
 *     Up Arrow - moves focus to the previous option, if one exists. If focus is already on the first option, it will not move.
 *     Down Arrow - moves focus to the next option, if one exists. If focus is already on the last option, it will not move.
 *     Home/End - moves focus to the first or last option.
 *     Escape - closes the dropdown and reverts selection to the previously selected option.
 *     Tab - selects the current option, closes the dropdown, and focus moves to the next focusable item after the combobox.
 *     Alt + Up Arrow - selects the current option and closes the dropdown.
 *     Printable characters will alter the input value and move focus to the first option that starts with the full value string, if one exists.
 *
 * from: https://github.com/microsoft/sonder-ui/tree/master/src/components/combobox
 */

@Component({
    selector: 'sis-select-combobox',
    templateUrl: './select-combobox.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class SelectComboboxComponent implements AfterViewInit, OnChanges {

    /** aria label (provide the id of the element) for the combobox, if using a label outside this element */
    @Input() ariaLabelledBy?: string;
    /** label for the combobox. if using this, the ariaLabelledBy binding is ignored */
    @Input() label?: string;
    @Input() options: SelectOption[];
    /** value of the pre-selected option */
    @Input() selection?: any;
    /**
     * The $scope instance of the containing AngularJS component. Required when this component is used in an AngularJS
     * component; irrelevant otherwise. Without this the AngularJS change detection will not pick up the changes made
     * in this component.
     */
    @Input() scope: angular.IScope;
    @Output() selectionChange = new EventEmitter<any>();

    @ViewChild('combobox') private comboboxEl: ElementRef<HTMLDivElement>;
    @ViewChild('listbox') private listboxEl: ElementRef<HTMLDivElement>;
    @ViewChild('parent') private parentEl: ElementRef<HTMLDivElement>;

    open = false;
    activeIndex = 0;
    /** intellij shows false positive: invalid id reference  */
    ariaActiveDescendant: string;
    ignoreBlur = false;
    searchString = '';
    searchTimeout: number = null;
    idBase: string;
    selected: SelectOption;

    ngOnChanges() {
        this.options.forEach((option: SelectOption, i: number) => {
            if (this.selection === option.value) {
                this.selected = option;
                this.activeIndex = i;
            }
        });
    }

    ngAfterViewInit() {
        this.idBase = this.comboboxEl.nativeElement.id || 'combo';
    }

    onComboBlur() {
        // do not do blur action if ignoreBlur flag has been set by clicking on an option with mousedown
        if (this.ignoreBlur) {
            this.ignoreBlur = false;
            return;
        }

        if (this.open) {
            this.selectOption(this.activeIndex);
            this.updateMenuState(false, false);
        }
    }

    onComboKeyDown(event: any) {
        const { key } = event;
        const max = this.options.length - 1;

        const action = getActionFromKey(event, this.open);

        switch (action) {
            case SelectActions.Last:
            case SelectActions.First:
                this.updateMenuState(true);
            // intentional fallthrough
            case SelectActions.Next:
            case SelectActions.Previous:
            case SelectActions.PageUp:
            case SelectActions.PageDown:
                event.preventDefault();
                return this.onOptionChange(
                    getUpdatedIndex(this.activeIndex, max, action),
                );
            case SelectActions.CloseSelect:
                event.preventDefault();
                this.selectOption(this.activeIndex);
            // intentional fallthrough
            case SelectActions.Close:
                event.preventDefault();
                return this.updateMenuState(false);
            case SelectActions.Type:
                return this.onComboType(key);
            case SelectActions.Open:
                event.preventDefault();
                return this.updateMenuState(true);
        }
    }

    onComboType(letter: string) {
        this.updateMenuState(true);

        const searchString = this.getSearchString(letter);
        const searchIndex = getIndexByLetter(
            this.options,
            searchString,
            this.activeIndex + 1,
        );
        if (searchIndex >= 0) {
            this.onOptionChange(searchIndex);
        } else {
            window.clearTimeout(this.searchTimeout);
            this.searchString = '';
        }
    }

    onOptionClick(index: number) {
        this.onOptionChange(index);
        this.selectOption(index);
        this.updateMenuState(false);
    }

    onOptionMouseDown() {
        this.ignoreBlur = true;
    }

    updateMenuState(open: any, callFocus = true) {
        if (this.open === open) {
            return;
        }

        this.open = open;

        const activeID = open ? `${this.idBase}-${this.activeIndex}` : '';
        this.ariaActiveDescendant = activeID;

        if (activeID === '' && !isElementInView(this.comboboxEl.nativeElement)) {
            this.comboboxEl.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }

        if (callFocus) {
            this.comboboxEl.nativeElement.focus();
        }
    }

    private getSearchString(char: string) {
        // reset typing timeout and start new timeout
        // this allows us to make multiple-letter matches, like a native select
        if (typeof this.searchTimeout === 'number') {
            window.clearTimeout(this.searchTimeout);
        }

        this.searchTimeout = window.setTimeout(() => {
            this.searchString = '';
        }, 500);

        this.searchString += char;
        return this.searchString;
    }

    private onOptionChange(index: number) {
        this.activeIndex = index;

        this.ariaActiveDescendant = `${this.idBase}-${index}`;

        const optionElements = this.parentEl.nativeElement.querySelectorAll('[role=option]');

        if (isScrollable(this.listboxEl.nativeElement)) {
            maintainScrollVisibility(optionElements[index] as HTMLDivElement, this.listboxEl.nativeElement);
        }

        if (!isElementInView(optionElements[index] as HTMLDivElement)) {
            optionElements[index].scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
    }

    selectOption(index: number) {
        this.activeIndex = index;
        this.selected = this.options[index];

        if (this.scope) {
            this.scope.$apply(() => this.selectionChange.emit(this.selected.value));
        } else {
            this.selectionChange.emit(this.selected.value);
        }
    }
}
