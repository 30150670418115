import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { StateService, UIRouterGlobals } from '@uirouter/core';
import { MaxLength } from 'common-typescript/constants';
import { LocalizedUrl, OpenUniversityCart, OpenUniversityCartUpdateRequest, UniversitySettings } from 'common-typescript/types';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { AlertsService, AlertType } from 'sis-components/alerts/alerts-ng.service';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { email, maxLength, required } from 'sis-components/form/form-validators';
import { SisFormBuilder } from 'sis-components/form/sis-form-builder.service';
import { PaymentSystemLogEntityService } from 'sis-components/service/payment-system-log-entity.service';
import { UniversityService } from 'sis-components/service/university.service';

interface ContactInfoForm {
    firstName: FormControl<string>;
    lastName: FormControl<string>;
    email: FormControl<string>;
    acceptTerms: FormControl<boolean>;
}

@Component({
    selector: 'app-cart-contact-information',
    templateUrl: './cart-contact-information.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CartContactInformationComponent implements OnInit, OnDestroy {

    @Input() cart: OpenUniversityCart;
    @Input() submitClicked: Observable<void>;
    @Output() formSubmitted = new EventEmitter<OpenUniversityCartUpdateRequest>();

    form: FormGroup<ContactInfoForm>;
    destroyed$ = new Subject<void>();
    termsOfDeliveryUrl: LocalizedUrl;

    constructor(private appErrorHandler: AppErrorHandler,
                private uiRouterGlobals: UIRouterGlobals,
                private alertService: AlertsService,
                private localeService: LocaleService,
                private paymentSystemLogEntityService: PaymentSystemLogEntityService,
                private stateService: StateService,
                private translateService: TranslateService,
                private universityService: UniversityService,
                private fb: SisFormBuilder) {
    }

    ngOnInit(): void {
        this.buildForm();
        this.submitClicked
            .pipe(
                takeUntil(this.destroyed$),
                map(() => this.isValidForm()),
                filter(Boolean),
                this.appErrorHandler.defaultErrorHandler(),
            )
            .subscribe(() => this.postFormData());
        this.getOpenUniversitySettings();
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
    }

    private getOpenUniversitySettings() {
        this.universityService.getCurrentUniversitySettings()
            .pipe(
                takeUntil(this.destroyed$),
                this.appErrorHandler.defaultErrorHandler())
            .subscribe(universitySettings => this.getTermsOfDeliveryUrl(universitySettings));
    }

    private getTermsOfDeliveryUrl(universitySettings: UniversitySettings) {
        this.termsOfDeliveryUrl = universitySettings?.openUniversitySettings?.termsOfDeliveryUrl;
    }

    private buildForm(): void {
        this.form = this.fb.group({
            firstName: this.fb.control<string | null>(this.cart?.buyerFirstName, [
                required('OPEN_UNIVERSITY_CART.WIZARD.CONTACT_INFORMATION.FIRST_NAME_MISSING'),
                maxLength(MaxLength.MAX_SHORT_STRING_LENGTH),
            ]),
            lastName: this.fb.control<string | null>(this.cart?.buyerLastName, [
                required('OPEN_UNIVERSITY_CART.WIZARD.CONTACT_INFORMATION.LAST_NAME_MISSING'),
                maxLength(MaxLength.MAX_SHORT_STRING_LENGTH),
            ]),
            email: this.fb.control<string | null>(this.cart?.buyerEmail, [
                required('OPEN_UNIVERSITY_CART.WIZARD.CONTACT_INFORMATION.EMAIL_MISSING'),
                maxLength(MaxLength.MAX_SHORT_STRING_LENGTH),
                email(),
            ]),
            acceptTerms: this.fb.control(false, Validators.requiredTrue),
        });
    }

    private postFormData(): void {
        const request: OpenUniversityCartUpdateRequest = {
            buyerFirstName: this.form.value.firstName,
            buyerLastName: this.form.value.lastName,
            buyerEmail: this.form.value.email,
        };
        this.formSubmitted.emit(request);
    }

    private isValidForm(): boolean {
        const isValid = this.form.valid;
        const identifier = 'open-uni-cart-wizard-validation-err';
        if (!isValid) {
            this.form.markAllAsTouched();
            this.initValidationErrorAlert(identifier);
        } else {
            this.alertService.dismissAlertIfExists(identifier);
        }
        return isValid;
    }

    private initValidationErrorAlert(identifier: string) {
        this.alertService.addAlert({
            message: this.translateService.instant('OPEN_UNIVERSITY.VALIDATION_ERROR_ALERT'),
            type: AlertType.DANGER,
            scrollToElement: 'cart-error-class',
            identifier,
        });
    }
}
