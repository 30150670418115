<ng-container *transloco="let t">
  <sis-study-box [id]="customCourseUnitAttainment?.id"
                 [name]="customCourseUnitAttainment?.name"
                 [gradeScaleId]="customCourseUnitAttainment?.gradeScaleId"
                 [gradeId]="customCourseUnitAttainment?.gradeId"
                 [code]="customCourseUnitAttainment?.code"
                 [attainedCredits]="customCourseUnitAttainment?.credits"
                 [type]="studyBoxType"
                 [credits]="visibleCredits"
                 [level]="headingLevel"
                 (nameClickAction)="openCustomCourseUnitAttainmentInfoModal()">
    <ng-container study-box="notifications">
      <sis-study-notification-box *ngIf="customCourseUnitAttainmentStateObject?.invalidSelection && !this.customCourseUnitAttainmentStateObject?.hasModuleContentApproval" type="alert">{{ t('SIS_COMPONENTS.STUDY.INVALID_SELECTION') }}</sis-study-notification-box>
      <sis-study-notification-box *ngIf="customCourseUnitAttainmentStateObject?.isInvalidAccordingToModuleContentApprovalRequested" type="alert">{{ t('SIS_COMPONENTS.STUDY.INVALID_ACCORDING_TO_MODULE_CONTENT_APPROVAL') }}</sis-study-notification-box>
      <sis-study-notification-box *ngIf="customCourseUnitAttainmentStateObject?.isInvalidAccordingToModuleContentApprovalAccepted" type="alert">{{ t('SIS_COMPONENTS.STUDY.CONTENT_NOT_AS_APPROVED') }}</sis-study-notification-box>
      <sis-study-notification-box *ngIf="customCourseUnitAttainmentStateObject?.isAttainmentAboutToExpire" type="info">{{ t('SIS_COMPONENTS.STUDY.EXPIRATION_IMMINENT', { expiryDate: customCourseUnitAttainmentStateObject.attainmentExpiryDate | localDateFormat }) }}</sis-study-notification-box>
    </ng-container>
  </sis-study-box>
</ng-container>

