import { Injectable } from '@angular/core';
import { Education, StudyRight, StudyRightExtension } from 'common-typescript/types';
import { from, Observable } from 'rxjs';
import { ModalService } from 'sis-common/modal/modal.service';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

import { ExtensionInfoModalComponent, ExtensionInfoModalValues } from './extension-info-modal/extension-info-modal.component';
import {
    PersonalizedSelectionRationaleComponent,
    PersonalizedSelectionRationaleValues,
} from './personalized-selection-rationale/personalized-selection-rationale.component';

@StaticMembers<DowngradedService>()
@Injectable()
export class CommonStudyRightModalService {
    static downgrade: ServiceDowngradeMappings = {
        dependencies: [],
        moduleName: 'sis-components.studyRight.studyRightModal',
        serviceName: 'commonStudyRightModalService',
    };

    constructor(
        private modalService: ModalService,
    ) { }

    openExtensionInfoModal(studyRightExtension: StudyRightExtension, studyRight: StudyRight, applicationViewUrl: string): Observable<void> {
        const values: ExtensionInfoModalValues = { studyRight, studyRightExtension, applicationViewUrl };
        const options = { closeWithOutsideClick: true, size: 'sm' };
        return from(this.modalService.open(ExtensionInfoModalComponent, values, options).result);
    }

    openPersonalizedSelectionRationaleModal(studyRight: StudyRight, education: Education) {
        const options = { closeWithOutsideClick: true, size: 'sm' };
        const values: PersonalizedSelectionRationaleValues = { studyRight, education };
        return from(this.modalService.open(PersonalizedSelectionRationaleComponent, values, options).result);
    }
}
