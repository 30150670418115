export const validAttainmentFilterServiceModule = 'sis-components.service.validAttainmentFilterService';
(function () {
  validAttainmentFilterService.$inject = ["commonAttainmentService", "WarningLimits"];
  angular.module(validAttainmentFilterServiceModule, ['sis-components.constant.warningLimits', 'sis-components.service.attainmentService']).factory('validAttainmentFilterService', validAttainmentFilterService);

  /**
   * @ngInject
   */
  function validAttainmentFilterService(commonAttainmentService, WarningLimits) {
    var api = {
      /**
       * This method is not related to attainment validity so in a way it should not be here. However removing
       * this from here would be risky at the moment (release incoming).
       * @param attainment that may be attached
       * @param allAttainments potential parents
       * @returns {boolean} true if parent attainment was found, false otherwise
       */
      isAttached: function (attainment, allAttainments) {
        return commonAttainmentService.isAttached(attainment, allAttainments);
      },
      /**
       * @deprecated Validity filtering logic itself is implemented in AttainmentValidityService.getValidAttainmentIds,
       * whereas grouping/sorting needs may vary and can be implemented locally where needed.
       */
      groupAttainmentsByValidity: function (allAttainments) {
        var validAttainments = filterValidAttainments(allAttainments);
        var attachedAttainments = [];
        _.forEach(validAttainments, function (attainment) {
          collectValidAttainmentsRecursively(attainment, allAttainments, attachedAttainments);
        });
        var finalValidAttainments = _.unionBy(validAttainments, attachedAttainments, 'id');
        var finalValidAttainmentIds = _.map(finalValidAttainments, 'id');
        var notValidAttainments = _.filter(allAttainments, function (attainment) {
          return !_.includes(finalValidAttainmentIds, attainment.id);
        });
        return {
          validAttainments: finalValidAttainments,
          notValidAttainments: notValidAttainments
        };
      },
      /**
       * @deprecated Use AttainmentValidityService.getValidAttainments,
       * but NOTICE that the result order is not necessarily the same as it used to be.
       */
      getValidAttainments: function (allAttainments) {
        return api.groupAttainmentsByValidity(allAttainments).validAttainments;
      },
      isAttainmentAboutToExpire: function (attainment) {
        var expiryWarningPeriod = attainment.type === 'CourseUnitAttainment' || attainment.type === 'CustomCourseUnitAttainment' ? WarningLimits.COURSE_UNIT_EXPIRY_WARNING_PERIOD_MONTHS : WarningLimits.MODULE_EXPIRY_WARNING_PERIOD_MONTHS;
        return _.get(attainment, 'expiryDate', null) !== null && moment(_.get(attainment, 'expiryDate')).subtract(expiryWarningPeriod, 'months').isBefore(moment());
      }
    };
    return api;
    function collectValidAttainmentsRecursively(attainment, allAttainments, attachedAttainments) {
      _.chain(commonAttainmentService.toChildAttainmentIds(attainment)).map(function (childAttainmentId) {
        return _.find(allAttainments, {
          id: childAttainmentId
        });
      }).compact().forEach(function (attainmentObject) {
        collectValidAttainmentsRecursively(attainmentObject, allAttainments, attachedAttainments);
        attachedAttainments.push(attainmentObject);
      }).value();
    }
    function filterValidAttainments(attainments) {
      var currentMoment = moment();
      return _.filter(attainments, function (attainment) {
        if (attainment.misregistration) {
          return false;
        }
        if (!attainment.primary) {
          return false;
        }
        if (attainment.state === 'FAILED') {
          return false;
        }
        return !(attainment.expiryDate && moment(attainment.expiryDate).isSameOrBefore(currentMoment, 'days'));
      });
    }
  }
})();