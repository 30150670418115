import { Pipe, PipeTransform } from '@angular/core';
import { EnrolmentProcessingState } from 'common-typescript/types/generated/common-backend';

@Pipe({ name: 'enrolmentProcessingStateMessageKey' })
export class EnrolmentProcessingStateMessageKeyPipe implements PipeTransform {

    transform(state: EnrolmentProcessingState): string {
        return `ENROLMENT.ENROLMENT_PROCESSING_STATE.${state}`;
    }
}
