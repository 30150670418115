import { TooltipComponent } from 'sis-components/tooltip/tooltip.component.ts';
import studyRightIndicatorTpl from './studyRightIndicator.tpl.html';
'use strict';
angular.module('student.timing.studyRightIndicator.studyRightIndicator', ['pascalprecht.translate', 'student.timing.constant', TooltipComponent.downgrade.moduleName]).component('timelineStudyRightIndicator', {
  template: studyRightIndicatorTpl,
  bindings: {
    period: '<'
  },
  controller: ["timelineConfig", function (timelineConfig) {
    const $ctrl = this;
    $ctrl.$onInit = function () {
      $ctrl.status = timelineConfig.studyRight.status;
    };
  }]
});