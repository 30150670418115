'use strict';

(function () {
  educationClassificationModel.$inject = ["DS", "$http"];
  angular.module('sis-components.model.educationClassification', ['js-data']).factory('educationClassificationModel', educationClassificationModel);

  /**
   * @ngInject
   */
  function educationClassificationModel(DS, $http) {
    var model = DS.defineResource({
      idAttribute: 'urn',
      name: 'educationClassification',
      endpoint: '/kori/api/cached/codebooks/urn:code:education-classification'
    });
    model.lazyGetAll = _.once(function () {
      return $http.get(model.endpoint).then(function (response) {
        model.inject(_.values(response.data));
        return model.getAll();
      });
    });
    model.lazyGet = function (relModel, def, instance, orig) {
      // Model first first return undefined, and when lazyGetAll has initialized the model, the value will change
      // to the actual value by angular watch.
      //
      // Onetime binding works, as there will be a watch as long as the value is undefined
      model.lazyGetAll();
      return orig();
    };
    return model;
  }
})();