frontpageTimetableController.$inject = ["$translate", "frontPageTimetableService", "timetableUtil", "timetableItemUtil", "defaultPromiseHandler"];
import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import timetableTpl from './timetable.tpl.html';
angular.module('student.frontpage.timetable').component('timetable', {
  template: timetableTpl,
  bindings: {
    enrolments: '<'
  },
  controller: frontpageTimetableController
});

/**
 * @ngInject
 */
function frontpageTimetableController($translate, frontPageTimetableService, timetableUtil, timetableItemUtil, defaultPromiseHandler) {
  const $ctrl = this;
  $ctrl.menuOptions = [];
  $ctrl.$onInit = function () {
    $ctrl.day = 'today';
    $ctrl.todayDate = moment();
    $ctrl.tomorrowDate = moment().add(1, 'day');
    findEvents().catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    $ctrl.menuOptions = [{
      value: 'today',
      label: $translate.instant('FRONTPAGE.TIMETABLE.TODAY')
    }, {
      value: 'tomorrow',
      label: $translate.instant('FRONTPAGE.TIMETABLE.TOMORROW')
    }];
  };
  function findEvents() {
    const enrolments = _.filter($ctrl.enrolments, 'isInCalendar');
    return frontPageTimetableService.findEventsForTodayAndTomorrow(enrolments).then(events => {
      if (!_.isEmpty(_.get(events, 'today.events'))) {
        $ctrl.hourRangeToday = _.range(timetableUtil.findEarliestHour(events.today.events), timetableUtil.findLatestHour(events.today.events));
        $ctrl.today = timetableItemUtil.getEventsWithPositions(events.today.events, $ctrl.hourRangeToday);
      }
      if (!_.isEmpty(_.get(events, 'tomorrow.events'))) {
        $ctrl.hourRangeTomorrow = _.range(timetableUtil.findEarliestHour(events.tomorrow.events), timetableUtil.findLatestHour(events.tomorrow.events));
        $ctrl.tomorrow = timetableItemUtil.getEventsWithPositions(events.tomorrow.events, $ctrl.hourRangeTomorrow);
      }
    });
  }
}