import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { MarkupString } from 'common-typescript/types';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-markup-string-view',
    templateUrl: './markup-string-view.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarkupStringViewComponent {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.markupStringView',
        directiveName: 'sisMarkupStringView',
    };

    @Input({ required: true }) content: MarkupString;

}
