<ngb-pagination
  data-cy="pagination-component"
  [collectionSize]="totalItems"
  [page]="currentPage"
  (pageChange)="currentPageChange.emit($event)"
  [pageSize]="itemsPerPage"
  [maxSize]="maxSize"
  [ellipses]="true"
  [rotate]="true"
  [disabled]="disabled"
  [attr.aria-label]="'ARIA_LABEL.PAGINATION' | translate"
>
  <ng-template ngbPaginationPrevious>
    <fudis-icon [icon]="'chevron'" [rotate]="'flip-180'"></fudis-icon>
  </ng-template>
  <ng-template ngbPaginationNext>
    <fudis-icon [icon]="'chevron'"></fudis-icon>
  </ng-template>
</ngb-pagination>
