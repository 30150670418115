import applyForGraduationModalTpl from './applyForGraduationModal.tpl.html';
import { GraduationInstructionsUrlComponent } from './graduation-instructions-url/graduation-instructions-url.component';
'use strict';
(function () {
  applyForGraduationModalController.$inject = ["$state"];
  angular.module('student.common.components.applyForGraduationModal', [GraduationInstructionsUrlComponent.downgrade.moduleName, 'ui.router', 'ui.router.upgrade']).component('applyForGraduationModal', {
    template: applyForGraduationModalTpl,
    bindings: {
      close: '&',
      dismiss: '&',
      resolve: '<'
    },
    controller: applyForGraduationModalController
  });
  function applyForGraduationModalController($state) {
    const ctrl = this;
    ctrl.cancel = function () {
      ctrl.close();
    };
    ctrl.apply = function () {
      $state.go('student.logged-in.profile.applications.create-degree-programme-attainment-application', {
        degreeProgrammeId: ctrl.resolve.moduleId,
        planId: ctrl.resolve.planId
      }).then(() => {
        ctrl.dismiss();
      });
    };
  }
})();