import { Injectable } from '@angular/core';
import { LocalDateRange, OrganisationRoleShare, OrganisationRoleShareBase, OtmId } from 'common-typescript/types';
import _ from 'lodash';

@Injectable({ providedIn: 'root' })
export class OrganisationRoleShareValidityGroupService {

    public build(organisationRoleShares: OrganisationRoleShareBase[]): OrganisationRoleShareValidityGroup[] {
        return _.orderBy(
            _.reduce(
                organisationRoleShares,
                (validityGroups: { [index: string]: OrganisationRoleShareValidityGroup }, organisationRoleShare: OrganisationRoleShareBase) => {
                    // For role shares of attainments do not have validity periods. However organisation role share editor creates role
                    // shares with validityPeriod={}. So with attainments we might have validityPeriod={} and validityPeriod=undefined role
                    // shares. So we default to empty object here.
                    const period = (organisationRoleShare as OrganisationRoleShare).validityPeriod;
                    const validityPeriod: LocalDateRange = { startDate: period?.startDate || null, endDate: period?.endDate || null };
                    const key = `${validityPeriod.startDate}-${validityPeriod.endDate}`;
                    if (!validityGroups[key]) {
                        validityGroups[key] = {
                            validityPeriod,
                            organisationRoleShares: [],
                            disabledOrganisationIds: [],
                        };
                    }
                    validityGroups[key].organisationRoleShares.push(organisationRoleShare);
                    validityGroups[key].disabledOrganisationIds.push(organisationRoleShare.organisationId);
                    return validityGroups;
                },
                {},
            ),
            [group => !!group.validityPeriod.startDate, 'validityPeriod.startDate'],
        );
    }
}

export interface OrganisationRoleShareValidityGroup {
    validityPeriod: LocalDateRange;
    organisationRoleShares: OrganisationRoleShareBase[];
    disabledOrganisationIds: OtmId[];
}
