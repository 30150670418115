import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CourseUnit } from 'common-typescript/types';
import {
    ComponentDowngradeMappings,
    DowngradedComponent,
    StaticMembers,
} from 'sis-common/types/angular-hybrid';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-plan-course-unit-info-modal-basic-info',
    templateUrl: './plan-course-unit-info-modal-basic-info.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class PlanCourseUnitInfoModalBasicInfoComponent {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.courseUnitInfoModal.planCourseUnitInfoModalBasicInfo',
        directiveName: 'sisPlanCourseUnitInfoModalBasicInfo',
    };

    @Input() courseUnit: CourseUnit;
    @Input() language: string;

}
