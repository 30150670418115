import { Component, Inject, Input, ViewEncapsulation } from '@angular/core';
import { ValidatablePlan } from 'common-typescript/src';
import { CourseUnit, OtmId } from 'common-typescript/types';
import {
    ComponentDowngradeMappings,
    DowngradedComponent,
    StaticMembers,
} from 'sis-common/types/angular-hybrid';

import { COURSE_UNIT_INFO_MODAL } from '../../../ajs-upgraded-modules';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-prior-learning-substitutions-container',
    templateUrl: './prior-learning-substitutions-container.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class PriorLearningSubstitutionsContainerComponent {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.courseUnit.priorLearningSubstitutionsContainer',
        directiveName: 'sisPriorLearningSubstitutionsContainer',
    };

    constructor(
        @Inject(COURSE_UNIT_INFO_MODAL) private courseUnitInfoModal: any,
    ) {}

    @Input() courseUnit: CourseUnit;
    @Input() validatablePlan: ValidatablePlan;
    openCourseUnitModal(courseUnitId: OtmId) {
        if (this.validatablePlan) {
            this.courseUnitInfoModal.showCourseUnit(courseUnitId, this.validatablePlan);
        }
    }

}
