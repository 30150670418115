import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Attainment } from 'common-typescript/types';

@Component({
    selector: 'sis-secondary-attainment-details',
    templateUrl: './secondary-attainment-details.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class SecondaryAttainmentDetailsComponent {
    @Input() secondaryAttainments: Attainment[];
}
