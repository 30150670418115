import { TokenizeResult } from 'ngx-transloco-markup';

import { TokenizationSpec } from './html-element-transpiler';

const recognizeToken = (
    translation: string,
    offset: number,
    { search, token }: TokenizationSpec,
): TokenizeResult | undefined => {
    if (!translation.startsWith(search, offset)) {
        return undefined;
    }

    return {
        nextOffset: offset + search.length,
        token,
    };
};

/**
 * Generalized tokenizer for HTML elements with opening and closing tags.
 */
export const htmlElementTokenizer = (
    translation: string,
    offset: number,
    start: TokenizationSpec,
    end: TokenizationSpec,
): TokenizeResult | undefined =>
    recognizeToken(translation, offset, start) || recognizeToken(translation, offset, end);
