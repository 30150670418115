import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { Enrolment } from 'common-typescript/types';

@Component({
    selector: 'app-enrolment-status-badge',
    templateUrl: './enrolment-status-badge.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnrolmentStatusBadgeComponent {

    @Input() enrolment: Enrolment;
}
