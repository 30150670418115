import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

/**
 * A simple presentational component for displaying a label with a grey left border and an associated value,
 * placed on the same line and separated with a colon. The value is provided via content projection by
 * tagging an element that contains the actual value with the CSS class 'value'. The indent version of static
 * form group component can be achieved by using customClass indentation.
 * <pre>
 *     <sis-tiny-static-form-group customClasses="some-class" label="SOME.LABEL">
 *       <span class="value">Some value</span>
 *     </sis-tiny-static-form-group>
 * </pre>
 */
@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-tiny-static-form-group',
    templateUrl: './tiny-static-form-group.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TinyStaticFormGroupComponent implements OnChanges {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sisComponents.tinyStaticFormGroup.tinyStaticFormGroup',
        directiveName: 'sisTinyStaticFormGroup',
    };

    /** Translation key for the label */
    @Input() label: string;
    /** If true, the label's grey left border will not show */
    @Input() plain = false;
    /** Translation key for the tooltip text */
    @Input() tooltipText: string;
    /** Any custom CSS classes for the tiny static form group. */
    @Input() customClasses?: string;

    classes = '';

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.customClasses) {
            const customClasses = changes.customClasses.currentValue ?? this.customClasses;
            this.classes = `${customClasses ?? ''}`.trim();
        }
    }
}
