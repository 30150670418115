import { Injectable } from '@angular/core';
import { dateUtils } from 'common-typescript';
import {
    Attainment,
    StudyTerm,
    TermRegistration,
} from 'common-typescript/types';
import _ from 'lodash';
import moment from 'moment/moment';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { AppErrorHandler } from '../error-handler/app-error-handler';
import { StudyYearsEntityService } from '../service/study-years-entity.service';
import { UniversityService } from '../service/university.service';

export interface AttainedCreditsByStudyTerm {
    studyTerm: StudyTerm,
    termRegistration: TermRegistration,
    credits: number
}
@Injectable({
    providedIn: 'root',
})

export class StudyTermService {

    constructor(
        private universityService: UniversityService,
        private studyYearEntityService: StudyYearsEntityService,
        private errorHandler: AppErrorHandler,
    ) { }

    groupAttainedCreditsByStudyTerm(attainments: Attainment[], fromDate: string, untilDate?: string, termRegistrations: TermRegistration[] = []): Observable<AttainedCreditsByStudyTerm[]> {

        const untilYear = untilDate ? moment(untilDate).year() : moment().year();
        const universityOrgId = this.universityService.getCurrentUniversityOrgId();
        const fromYear = moment(fromDate).year();
        const firstYear = fromYear - 1;
        const numYears = untilYear - fromYear + 2;

        return this.studyYearEntityService.getStudyYears(universityOrgId, firstYear, numYears)
            .pipe(
                take(1),
                this.errorHandler.defaultErrorHandler(),
                map(studyYears => {
                    const termsAndRegistrations = studyYears.map(({ studyTerms, startYear }) => (
                        studyTerms.map((studyTerm, index) => ({
                            studyTerm,
                            termRegistration: termRegistrations
                                .find(reg => reg.studyTerm.studyYearStartYear === startYear && reg.studyTerm.termIndex === index),
                        }))))
                        .reduce((entries, entry) => entries.concat(entry), []) // Flatten the nested arrays
                        .filter(({ studyTerm: { valid: { startDate, endDate } } }) =>
                            dateUtils.dateRangesOverlap(startDate, endDate, fromDate, untilDate))
                        .sort((entry1, entry2) => moment(entry1.studyTerm.valid.startDate).diff(moment(entry2.studyTerm.valid.startDate)));

                    const firstTermStartDate = moment(_.first(termsAndRegistrations)?.studyTerm.valid.startDate);
                    const attainmentsBeforeFirstTerm = attainments
                        .filter(({ attainmentDate }) => moment(attainmentDate).isBefore(firstTermStartDate, 'day'));
                    const results: AttainedCreditsByStudyTerm[] = attainmentsBeforeFirstTerm.length > 0 ?
                        [{
                            studyTerm: {
                                name: undefined,
                                studyPeriods: undefined,
                                valid: undefined,
                            },
                            termRegistration: undefined,
                            credits: this.countTotalCredits(attainmentsBeforeFirstTerm),
                        }] : [];

                    termsAndRegistrations.map(({ studyTerm, termRegistration }) => {
                        const { startDate, endDate } = studyTerm.valid;
                        const attainmentsForTerm = attainments
                            .filter(({ attainmentDate }) => moment(attainmentDate).isBetween(startDate, endDate, 'day', '[)'));
                        results.push({
                            studyTerm,
                            termRegistration,
                            credits: this.countTotalCredits(attainmentsForTerm),
                        });
                        return results;
                    });
                    return results;
                }),
            );
    }

    private countTotalCredits(attainments: Attainment[]): number {
        return attainments.reduce((sum, { credits }) => sum + (credits || 0), 0);
    }

}
