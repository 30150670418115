import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Attainment } from 'common-typescript/types';

@Component({
    selector: 'sis-attainment-expiration-notification',
    templateUrl: './attainment-expiration-notification.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class AttainmentExpirationNotificationComponent {
    @Input() attainment: Attainment;
}
