import { Component, Input, ViewEncapsulation } from '@angular/core';
import { OiliPayment, StudentPayment, TuitionFeePayment } from 'common-typescript/types';

const isOiliPayment = (payment: StudentPayment): payment is OiliPayment => payment?.type === 'OILI_PAYMENT';
const isTuitionFeePayment = (payment: StudentPayment): payment is TuitionFeePayment => payment?.type === 'TUITION_FEE_PAYMENT';
const isActive = (payment: StudentPayment) => !payment?.invalidated;
const isInvalidated = (payment: StudentPayment) => !!payment?.invalidated;

@Component({
    selector: 'app-study-year-payments',
    templateUrl: './study-year-payments.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class StudyYearPaymentsComponent {

    @Input() set payments(payments: StudentPayment[]) {
        this.activeOiliPayments = payments?.filter(isOiliPayment).filter(isActive) ?? [];
        this.invalidatedOiliPayments = payments?.filter(isOiliPayment).filter(isInvalidated) ?? [];
        this.activeTuitionFeePayments = payments?.filter(isTuitionFeePayment).filter(isActive) ?? [];
        this.invalidatedTuitionFeePayments = payments?.filter(isTuitionFeePayment).filter(isInvalidated) ?? [];
    }

    @Input() level?: number = 3;

    activeOiliPayments: OiliPayment[] = [];
    invalidatedOiliPayments: OiliPayment[] = [];
    activeTuitionFeePayments: TuitionFeePayment[] = [];
    invalidatedTuitionFeePayments: TuitionFeePayment[] = [];
}
