import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from 'sis-common/auth/auth-service';
import { ConfigService } from 'sis-common/config/config.service';

interface IdentityProvider {
    name: string;
    navigate: Function;
}

@Component({
    selector: 'sis-login-provider-menu',
    templateUrl: './login-provider-menu.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class LoginProviderMenuComponent implements OnInit {

    identityProviders: IdentityProvider[] = [];

    constructor(private configService: ConfigService,
                private authService: AuthService) {}

    ngOnInit() {
        const universityConfig = this.configService.get();
        this.identityProviders = (universityConfig.identityProviderLogins || []).map(login => ({
            name: `Login ${login.abbr}`,
            navigate: () => this.authService.moveToAuthUrl(login.shibbolethUrl, window.location.href),
        }));
    }
}
