export enum MessageTypes {
    PLAN_GENERIC = 'PlanGeneric',
    PLAN_MODULE = 'PlanModuleSelection',
    PLAN_COURSE_UNIT = 'PlanCourseUnitSelection',
    PLAN_CUSTOM_STUDY_DRAFT = 'PlanCustomStudyDraft',
    CUSTOM_MODULE_CONTENT_APPLICATION_MESSAGE = 'CustomModuleContentApplicationMessage',
    REQUIRED_MODULE_CONTENT_APPLICATION_MESSAGE = 'RequiredModuleContentApplicationMessage',
    PRIOR_LEARNING_INCLUSION_APPLICATION_MESSAGE = 'PriorLearningInclusionApplicationMessage',
    PRIOR_LEARNING_SUBSTITUTION_APPLICATION_MESSAGE = 'PriorLearningSubstitutionApplicationMessage',
    MODULE_ATTAINMENT_APPLICATION_MESSAGE = 'ModuleAttainmentApplicationMessage',
    DEGREE_PROGRAMME_ATTAINMENT_APPLICATION_MESSAGE = 'DegreeProgrammeAttainmentApplicationMessage',
    CUSTOM_ATTAINMENT_APPLICATION_MESSAGE = 'CustomAttainmentApplicationMessage',
    STUDY_RIGHT_EXTENSION_APPLICATION_MESSAGE = 'StudyRightExtensionApplicationMessage',
    MANUAL_COURSE_UNIT_REALISATION_MESSAGE = 'ManualCourseUnitRealisationMessage',
    AUTOMATIC_COURSE_UNIT_REALISATION_MESSAGE = 'AutomaticCourseUnitRealisationMessage',
    COURSE_UNIT_MANUAL_EVALUATION_REQUIRED_MESSAGE = 'CourseUnitManualEvaluationRequiredMessage',
    ENROLMENT_REMINDER_MESSAGE = 'EnrolmentReminderMessage',
}
