<div *ngIf="data$ | async" class="module-content-application">
  <div class="row">
    <div class="offset-md-1 col-md-10 offset-lg-2 col-lg-8">
      <div class="row">
        <div class="col-12">
          <div *ngIf="isApproved || isPendingApproval" class="revoke-cancel-button-holder float-end button-row">
            <sis-button *ngIf="isPendingApproval"
                        [size]="'lg'"
                        [variant]="'primary'"
                        (clicked)="cancel()">
              {{'SHOW_MODULE_CONTENT_APPROVAL_MODAL.' + moduleContentWorkflow.type + '.CANCEL_REQUEST' | translate}}
            </sis-button>
            <sis-button *ngIf="isApproved"
                        [size]="'lg'"
                        [variant]="'primary'"
                        (clicked)="revoke()">
              {{'SHOW_MODULE_CONTENT_APPROVAL_MODAL.' + moduleContentWorkflow.type + '.REVOKE_APPROVAL' | translate}}
            </sis-button>
          </div>
          <sis-contextual-notification *ngIf="isApplicationCancelledByStaff"
                                       [variant]="'warning'">{{'PROFILE.APPLICATIONS.APPLICATION_CANCELLED_BY_STAFF' | translate}}
            <sis-button [link]=true
                        (clicked)="showWorkflowApplicationCancelledInfoModal()">
              {{'PROFILE.APPLICATIONS.APPLICATION_CANCELLED_SHOW_INFORMATION' | translate}}
            </sis-button>
          </sis-contextual-notification>
          <sis-contextual-notification *ngIf="isApplicationAcceptanceRevokedByStaff"
                                       [variant]="'warning'">{{'PROFILE.APPLICATIONS.APPLICATION_ACCEPTANCE_REVOKED_BY_STAFF' | translate}}
            <sis-button [link]=true
                        (clicked)="showWorkflowApplicationAcceptanceRevokedInfoModal()">
              {{'PROFILE.APPLICATIONS.APPLICATION_CANCELLED_SHOW_INFORMATION' | translate}}
            </sis-button>
          </sis-contextual-notification>
          <sis-contextual-notification *ngIf="isCreatedByStaff"
                                       [variant]="'light'">{{'PROFILE.APPLICATIONS.APPLICATION_CREATED_BY_STAFF' | translate}}</sis-contextual-notification>
          <sis-contextual-notification *ngIf="!planExists"
                                       [variant]="'warning'">{{'SHOW_MODULE_CONTENT_APPROVAL_MODAL.PLAN_DELETED' | translate:{planName: plan.name} }}</sis-contextual-notification>
          <sis-contextual-notification *ngIf="planExists && !isInPlan"
                                       [variant]="'warning'">{{'SHOW_MODULE_CONTENT_APPROVAL_MODAL.REMOVED_FROM_PLAN' | translate }}</sis-contextual-notification>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="clearfix"></div>
          <div *ngIf="!moduleContentWorkflow.decision">
            <h2>{{'STUDENT_APPLICATIONS.APPLICATION_INFO' | translate}}</h2>
            <app-module-content-workflow-summary [moduleContentWorkflow]="moduleContentWorkflow"
                                                 [moduleContentWorkflowStructureData]="moduleContentWorkflowStructureData"
                                                 (openPlan)="openPlan()">
            </app-module-content-workflow-summary>
          </div>
          <div *ngIf="moduleContentWorkflow.decision">
            <sis-expandable [title]="'STUDENT_APPLICATIONS.APPLICATION_INFO' | translate"
                            [closed]="false">
              <ng-template sisExpandableContent>
                <app-module-content-workflow-summary [moduleContentWorkflow]="moduleContentWorkflow"
                                                     [moduleContentWorkflowStructureData]="moduleContentWorkflowStructureData"
                                                     (openPlan)="openPlan()">
                </app-module-content-workflow-summary>
              </ng-template>
            </sis-expandable>
            <sis-expandable [title]="'STUDENT_APPLICATIONS.DECISION_INFO' | translate"
                            [closed]="false">
              <ng-template sisExpandableContent>
                <sis-module-content-workflow-decision [moduleContentWorkflowDecision]="$any(moduleContentWorkflow.decision)"
                                                      [moduleContentWorkflowState]="moduleContentWorkflow.state"
                                                      [moduleContentWorkflowType]="$any(moduleContentWorkflow.type)">
                </sis-module-content-workflow-decision>
              </ng-template>
            </sis-expandable>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
