import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ValidatablePlan } from 'common-typescript/src/plan/validation/validatablePlan';
import {
    CompositeRule,
    CourseUnit,
    CustomCourseUnitAttainment,
    CustomModuleAttainment,
    CustomStudyDraft,
    EntityWithRule,
    LocalId,
    Module,
    OtmId,
    Plan,
    StudyRight,
} from 'common-typescript/types';
import _ from 'lodash';
import { Subject } from 'rxjs';

import {
    COMMON_PLAN_SELECTION_SERVICE,
    COMMON_PLAN_SERVICE,
} from '../../ajs-upgraded-modules';
import { AlertsService, AlertType } from '../../alerts/alerts-ng.service';
import { PlanCourseUnitService } from '../../service/plan-course-unit.service';
import { PlanSelectionService } from '../../service/plan-selection.service';
import { RawPlanEditService } from '../../service/raw-plan-edit.service';

export enum PlanOperationType {
    SELECT_COURSE_UNIT = 'SelectCourseUnit',
    FORCE_SELECT_COURSE_UNIT = 'ForceSelectCourseUnit',
    SELECT_COURSE_UNIT_BY_GROUP_ID = 'SelectCourseUnitByGroupId',
    UNSELECT_COURSE_UNIT = 'UnselectCourseUnit',
    FORCE_REMOVE_COURSE_UNIT = 'ForceRemoveCourseUnit',
    SELECT_MODULE = 'SelectModule',
    FORCE_SELECT_MODULE = 'ForceSelectModule',
    SELECT_MODULE_BY_GROUP_ID = 'SelectModuleByGroupId',
    UNSELECT_MODULE = 'UnselectModule',
    FORCE_REMOVE_MODULE = 'ForceRemoveModule',
    FORCE_SELECT_CUSTOM_COURSE_UNIT_ATTAINMENT = 'ForceSelectCustomCourseUnitAttainment',
    UNSELECT_CUSTOM_COURSE_UNIT_ATTAINMENT_BY_ID = 'UnselectCustomCourseUnitAttainmentById',
    FORCE_SELECT_CUSTOM_MODULE_ATTAINMENT = 'ForceSelectCustomModuleAttainment',
    UNSELECT_CUSTOM_MODULE_ATTAINMENT_BY_ID = 'UnselectCustomModuleAttainmentById',
    MOVE_COURSE_UNIT = 'MoveCourseUnit',
    ADD_CUSTOM_STUDY_DRAFT = 'AddCustomStudyDraft',
    UNSELECT_CUSTOM_STUDY_DRAFT_BY_ID = 'RemoveCustomStudyDraftById',
    SELECT_COMPLETION_METHOD = 'SelectCompletionMethod',
    ADD_ASSESSMENT_ITEM_SELECTION = 'AddAssessmentItemSelection',
    REMOVE_ASSESSMENT_ITEM_SELECTION = 'RemoveAssessmentItemSelection',
    CHANGE_COURSE_UNIT_VERSION = 'ChangeCourseUnitVersion',
    ACTIVATE_RULE_GROUP = 'ActivateRuleGroup',
    DEACTIVATE_RULE_GROUP = 'DeactivateRuleGroup',
}

export interface PlanOperation {
    planOperationType: PlanOperationType;
    target: CourseUnit | EntityWithRule | CustomCourseUnitAttainment | CustomModuleAttainment | CustomStudyDraft | OtmId | CompositeRule;
    parentModule: EntityWithRule;
    parentCourseUnit: CourseUnit;
    oldCourseUnitVersion?: CourseUnit;
    newCourseUnitVersion?: CourseUnit;
}

@Injectable()
export class PlanManager {

    message: string;
    validatablePlan: ValidatablePlan;
    studyRight: StudyRight;
    planOperationQueue: PlanOperation[];
    public validatablePlanSubject: Subject<ValidatablePlan>;
    processing = false;

    constructor(
        @Inject(COMMON_PLAN_SELECTION_SERVICE) private commonPlanSelectionService: any,
        @Inject(COMMON_PLAN_SERVICE) private commonPlanService: any,
        private rawPlanEditService: RawPlanEditService,
        private planCourseUnitService: PlanCourseUnitService,
        private alertsService: AlertsService,
        private translateService: TranslateService,
        private planSelectionService: PlanSelectionService,
    ) {
        this.planOperationQueue = [];
    }

    setValidatablePlan(validatablePlan: ValidatablePlan) {
        this.validatablePlan = validatablePlan;
        this.validatablePlanSubject = new Subject<ValidatablePlan>();
    }

    setStudyRight(studyRight: StudyRight) {
        this.studyRight = studyRight;
    }

    async processPlanOperation(planOperation: PlanOperation) {
        this.planOperationQueue.push(planOperation);
        if (!this.processing) {
            this.processing = true;
            while (!_.isEmpty(this.planOperationQueue)) {
                const nextPlanOperation = _.first(this.planOperationQueue);
                await this.executePlanOperation(nextPlanOperation);
                this.planOperationQueue = _.drop(this.planOperationQueue);
            }
            this.processing = false;
            this.validatablePlanSubject.next(this.validatablePlan);
        }
    }

    private async executePlanOperation(planOperation: PlanOperation) {
        switch (planOperation.planOperationType) {
            case PlanOperationType.SELECT_COURSE_UNIT:
                return this.selectCourseUnit(planOperation.target as CourseUnit, planOperation.parentModule);
            case PlanOperationType.FORCE_SELECT_COURSE_UNIT:
                return this.forceSelectCourseUnit(planOperation.target as OtmId, planOperation.parentModule);
            case PlanOperationType.SELECT_COURSE_UNIT_BY_GROUP_ID:
                return this.selectCourseUnitByGroupId(planOperation.target as OtmId, planOperation.parentModule);
            case PlanOperationType.UNSELECT_COURSE_UNIT:
                return this.unselectCourseUnit(planOperation.target as CourseUnit, planOperation.parentModule);
            case PlanOperationType.FORCE_REMOVE_COURSE_UNIT:
                return this.forceRemoveCourseUnit(planOperation.target as CourseUnit, planOperation.parentModule);
            case PlanOperationType.MOVE_COURSE_UNIT:
                return this.moveCourseUnit(planOperation.target as CourseUnit, planOperation.parentModule);
            case PlanOperationType.SELECT_MODULE:
                return this.selectModule(planOperation.target as Module, planOperation.parentModule);
            case PlanOperationType.FORCE_SELECT_MODULE:
                return this.forceSelectModule(planOperation.target as OtmId, planOperation.parentModule);
            case PlanOperationType.SELECT_MODULE_BY_GROUP_ID:
                return this.selectModuleByGroupId(planOperation.target as OtmId, planOperation.parentModule);
            case PlanOperationType.UNSELECT_MODULE:
                return this.unselectModule(planOperation.target as Module, planOperation.parentModule);
            case PlanOperationType.FORCE_REMOVE_MODULE:
                return this.forceRemoveModule(planOperation.target as Module, planOperation.parentModule);
            case PlanOperationType.ACTIVATE_RULE_GROUP:
                return this.activateRuleGroup(planOperation.target as CompositeRule, planOperation.parentModule);
            case PlanOperationType.DEACTIVATE_RULE_GROUP:
                return this.deactivateRuleGroup(planOperation.target as CompositeRule, planOperation.parentModule);
            case PlanOperationType.FORCE_SELECT_CUSTOM_COURSE_UNIT_ATTAINMENT:
                return this.forceSelectCustomCourseUnitAttainment(planOperation.target as CustomCourseUnitAttainment, planOperation.parentModule);
            case PlanOperationType.UNSELECT_CUSTOM_COURSE_UNIT_ATTAINMENT_BY_ID:
                return this.unselectCustomCourseUnitAttainmentById(planOperation.target as OtmId, planOperation.parentModule);
            case PlanOperationType.FORCE_SELECT_CUSTOM_MODULE_ATTAINMENT:
                return this.forceSelectCustomModuleAttainment(planOperation.target as CustomModuleAttainment, planOperation.parentModule);
            case PlanOperationType.UNSELECT_CUSTOM_MODULE_ATTAINMENT_BY_ID:
                return this.unselectCustomModuleAttainmentById(planOperation.target as OtmId, planOperation.parentModule);
            case PlanOperationType.ADD_CUSTOM_STUDY_DRAFT:
                return this.addCustomStudyDraft(planOperation.target as CustomStudyDraft, planOperation.parentModule);
            case PlanOperationType.UNSELECT_CUSTOM_STUDY_DRAFT_BY_ID:
                return this.unselectCustomStudyDraftById(planOperation.target as LocalId, planOperation.parentModule);
            case PlanOperationType.SELECT_COMPLETION_METHOD:
                return this.selectCompletionMethod(planOperation.target as LocalId, planOperation.parentCourseUnit);
            case PlanOperationType.ADD_ASSESSMENT_ITEM_SELECTION:
                return this.selectAssessmentItem(planOperation.target as OtmId, planOperation.parentCourseUnit);
            case PlanOperationType.REMOVE_ASSESSMENT_ITEM_SELECTION:
                return this.unselectAssessmentItem(planOperation.target as OtmId, planOperation.parentCourseUnit);
            case PlanOperationType.CHANGE_COURSE_UNIT_VERSION:
                return this.changeCourseUnitVersion(planOperation.oldCourseUnitVersion, planOperation.newCourseUnitVersion);
        }
    }

    private async selectCourseUnit(courseUnit: CourseUnit, parentModule: EntityWithRule) {
        const rawPlan = await this.commonPlanSelectionService.selectCourseUnit(courseUnit, parentModule, this.validatablePlan.plan, this.validatablePlan);
        await this.updateValidatablePlan(rawPlan);
    }

    private async forceSelectCourseUnit(courseUnitId: OtmId, parentModule: EntityWithRule) {
        const rawPlan = await this.commonPlanSelectionService.selectCourseUnitById(courseUnitId, parentModule, this.validatablePlan.plan, this.validatablePlan);
        await this.updateValidatablePlan(rawPlan);
    }

    private async selectCourseUnitByGroupId(courseUnitGroupId: OtmId, parentModule: EntityWithRule) {
        const rawPlan = await this.commonPlanSelectionService.selectCourseUnitByGroupId(courseUnitGroupId, parentModule, this.validatablePlan.plan, this.validatablePlan);
        await this.updateValidatablePlan(rawPlan);
    }

    private async unselectCourseUnit(courseUnit: CourseUnit, parentModule: EntityWithRule) {
        const rawPlan = this.commonPlanSelectionService.unselectCourseUnit(courseUnit, parentModule, this.validatablePlan.plan, this.validatablePlan);
        await this.updateValidatablePlan(rawPlan);
    }

    private async forceRemoveCourseUnit(courseUnit: CourseUnit, parentModule: EntityWithRule) {
        const rawPlan = _.cloneDeep(this.validatablePlan.plan);
        this.rawPlanEditService.removeCourseUnit(courseUnit.id, rawPlan);
        await this.updateValidatablePlan(rawPlan);
    }

    private async moveCourseUnit(courseUnit: CourseUnit, parentModule: EntityWithRule) {
        const rawPlan = await this.commonPlanSelectionService.moveCourseUnit(courseUnit, parentModule, this.validatablePlan);
        await this.updateValidatablePlan(rawPlan);
    }

    private async selectModule(module: EntityWithRule, parentModule: EntityWithRule) {
        const rawPlan = await this.commonPlanSelectionService.selectModule(module, parentModule, this.validatablePlan.plan, this.validatablePlan, this.studyRight);
        await this.updateValidatablePlan(rawPlan);
    }

    private async forceSelectModule(moduleId: OtmId, parentModule: EntityWithRule) {
        const rawPlan = _.cloneDeep(this.validatablePlan.plan);
        this.rawPlanEditService.selectModule(moduleId, parentModule.id, rawPlan);
        await this.updateValidatablePlan(rawPlan);
    }

    private async selectModuleByGroupId(moduleGroupId: OtmId, parentModule: EntityWithRule) {
        const rawPlan = await this.commonPlanSelectionService.selectModuleByGroupId(moduleGroupId, parentModule, this.validatablePlan.plan, this.validatablePlan, this.studyRight);
        await this.updateValidatablePlan(rawPlan);
    }

    private async unselectModule(module: Module, parentModule: EntityWithRule) {
        const rawPlan = await this.commonPlanSelectionService.unselectModule(module, this.validatablePlan.plan, this.validatablePlan);
        await this.updateValidatablePlan(rawPlan);
    }

    private async forceRemoveModule(module: Module, parentModule: EntityWithRule) {
        const rawPlan = _.cloneDeep(this.validatablePlan.plan);
        this.rawPlanEditService.removeModuleRecursively(module.id, rawPlan);
        await this.updateValidatablePlan(rawPlan);
    }

    private async activateRuleGroup(rule: CompositeRule, parentModule: EntityWithRule) {
        const rawPlan = await this.planSelectionService.activateRuleGroup(rule, parentModule, this.validatablePlan.plan, this.validatablePlan, this.studyRight);
        await this.updateValidatablePlan(rawPlan);
    }

    private async deactivateRuleGroup(rule: CompositeRule, parentModule: EntityWithRule) {
        const rawPlan = _.cloneDeep(this.validatablePlan.plan);
        this.planSelectionService.deactivateRuleGroup(rule, parentModule, rawPlan, this.validatablePlan);
        await this.updateValidatablePlan(rawPlan);
    }

    private async forceSelectCustomCourseUnitAttainment(customCourseUnitAttainment: CustomCourseUnitAttainment, parentModule: EntityWithRule) {
        const rawPlan = _.cloneDeep(this.validatablePlan.plan);
        this.rawPlanEditService.selectCustomCourseUnitAttainment(customCourseUnitAttainment.id, parentModule.id, rawPlan);
        await this.updateValidatablePlan(rawPlan);
    }

    private async unselectCustomCourseUnitAttainmentById(customCourseUnitAttainmentId: OtmId, parentModule: EntityWithRule) {
        const rawPlan = await this.commonPlanSelectionService.unselectCustomCourseUnitAttainmentById(customCourseUnitAttainmentId, parentModule, this.validatablePlan.plan);
        await this.updateValidatablePlan(rawPlan);
    }

    private async forceSelectCustomModuleAttainment(customModuleAttainment: CustomModuleAttainment, parentModule: EntityWithRule) {
        const rawPlan = _.cloneDeep(this.validatablePlan.plan);
        this.rawPlanEditService.selectCustomModuleAttainment(customModuleAttainment.id, parentModule.id, rawPlan);
        await this.updateValidatablePlan(rawPlan);
    }

    private async unselectCustomModuleAttainmentById(customModuleAttainmentId: OtmId, parentModule: EntityWithRule) {
        const rawPlan = await this.commonPlanSelectionService.unselectCustomModuleAttainmentById(customModuleAttainmentId, parentModule, this.validatablePlan.plan);
        await this.updateValidatablePlan(rawPlan);
    }

    private async addCustomStudyDraft(customStudyDraft: CustomStudyDraft, parentModule: EntityWithRule) {
        const rawPlan = await this.commonPlanSelectionService.addCustomStudyDraft(customStudyDraft, parentModule, this.validatablePlan.plan);
        await this.updateValidatablePlan(rawPlan);
    }

    private async unselectCustomStudyDraftById(customStudyDraftId: LocalId, parentModule: EntityWithRule) {
        const rawPlan = await this.commonPlanSelectionService.unselectCustomStudyDraftById(customStudyDraftId, parentModule, this.validatablePlan.plan);
        await this.updateValidatablePlan(rawPlan);
    }

    private async selectCompletionMethod(completionMethodId: LocalId, parentCourseUnit: CourseUnit) {
        const rawPlan = await this.commonPlanSelectionService.selectCompletionMethodById(parentCourseUnit, completionMethodId, this.validatablePlan.plan, this.validatablePlan);
        await this.updateValidatablePlan(rawPlan);
    }

    private async selectAssessmentItem(assessmentItemId: OtmId, parentCourseUnit: CourseUnit) {
        const rawPlan = await this.commonPlanSelectionService.selectAssessmentItemById(parentCourseUnit, assessmentItemId, this.validatablePlan.plan, this.validatablePlan);
        await this.updateValidatablePlan(rawPlan);
    }

    private async unselectAssessmentItem(assessmentItemId: OtmId, parentCourseUnit: CourseUnit) {
        const rawPlan = await this.commonPlanSelectionService.unselectAssessmentItemById(parentCourseUnit, assessmentItemId, this.validatablePlan.plan);
        await this.updateValidatablePlan(rawPlan);
    }

    private async changeCourseUnitVersion(oldCourseUnitVersion: CourseUnit, newCourseUnitVersion: CourseUnit) {
        const rawPlan = this.planCourseUnitService.changeCourseUnitVersion(oldCourseUnitVersion, newCourseUnitVersion, this.validatablePlan);
        this.commonPlanSelectionService.makeAutomaticSelectionsForCourseUnits(rawPlan, [newCourseUnitVersion]);
        await this.updateValidatablePlan(rawPlan);
        if (this.wasVersionChangeSuccessful(oldCourseUnitVersion, newCourseUnitVersion, rawPlan)) {
            this.alertsService.addAlert({
                type: AlertType.SUCCESS,
                message: this.translateService.instant('STUDIES.COURSE_UNIT_INFO_MODAL.VERSION_CHANGE_SUCCESS_ALERT'),
            });
        }
    }

    private async updateValidatablePlan(rawPlan: Plan) {
        if (rawPlan) {
            const updatedValidatablePlan = await this.commonPlanService.getValidatablePlan(rawPlan, false, false);
            if (updatedValidatablePlan) {
                this.validatablePlan = updatedValidatablePlan;
            }
        }
    }

    private wasVersionChangeSuccessful(oldCourseUnitVersion: CourseUnit, newCourseUnitVersion: CourseUnit, rawPlan: Plan) {
        const courseUnitSelections = _.get(rawPlan, 'courseUnitSelections') || [];
        const oldVersionCourseUnitSelection = _.find(courseUnitSelections, { courseUnitId: oldCourseUnitVersion?.id });
        const newVersionCourseUnitSelection = _.find(courseUnitSelections, { courseUnitId: newCourseUnitVersion?.id });
        if (newVersionCourseUnitSelection && !oldVersionCourseUnitSelection) {
            return true;
        }
        return false;
    }

}
