import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { ExternalIssuer, OtmId } from 'common-typescript/types';

import { EntityService } from './entity.service';

const CONFIG = {
    ENDPOINTS: {
        backend: '/ori/api',
    },
};

@Injectable({ providedIn: 'root' })
@NgEntityServiceConfig({ baseUrl: CONFIG.ENDPOINTS.backend })
export class ExternalIssuerEntityService extends EntityService<ExternalIssuerState> {

    constructor() {
        super(ExternalIssuerStore, ExternalIssuerQuery);
    }
}

type ExternalIssuerState = EntityState<ExternalIssuer, OtmId>;

@StoreConfig({ name: 'external-issuers' })
class ExternalIssuerStore extends EntityStore<ExternalIssuerState> {}

class ExternalIssuerQuery extends QueryEntity<ExternalIssuerState> {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(store: ExternalIssuerStore) {
        super(store);
    }
}
