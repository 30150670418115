<table class="table styled-table">
  <thead>
  <tr>
    <th>{{'FIELD_NAMES.type' | translate}}</th>
    <th>{{'ENTITY_NAMES.studyRight' | translate}}</th>
    <th>{{'PROFILE.GRANTS.STUDY_TERM' | translate}}</th>
    <th>{{'PROFILE.GRANTS.AMOUNT_EUROS' | translate}}</th>
    <th></th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let grant of grants; trackBy: grantId">
    <td>{{grant.grantTypeUrn | codeLocalizedName | async}}</td>
    <td>{{grant.studyRightId | studyRightName | async | localizedString}}</td>
    <td>{{grant.studyTerm | studyTermName}}</td>
    <td>{{grant.amount | currency:null:''}}</td>
    <td class="info-icon">
      <sis-tooltip *ngIf="grant.cancelled"
                   [tooltipBlockElement]="true"
                   [attr.aria-label]="'PROFILE.GRANTS.CANCELLATION_REASON' | translate:{reason: grant.cancellationReason}">
        <sis-icon icon="info-circle" color="primary" class="sis-tooltip-trigger-content"></sis-icon>
        <div class="sis-tooltip-content">
          <p>{{'PROFILE.GRANTS.CANCELLATION_REASON' | translate:{reason: grant.cancellationReason} }}</p>
        </div>
      </sis-tooltip>
    </td>
  </tr>
  </tbody>
</table>
