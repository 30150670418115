import { Pipe, PipeTransform } from '@angular/core';
import { CourseUnit, Education, Module, Workflow } from 'common-typescript/types';
import { EMPTY, Observable } from 'rxjs';

import { AppErrorHandler } from '../../error-handler/app-error-handler';
import { CourseUnitEntityService } from '../../service/course-unit-entity.service';
import { EducationEntityService } from '../../service/education-entity.service';
import { ModuleEntityService } from '../../service/module-entity.service';
import {
    isDegreeProgrammeAttainmentWorkflow,
    isModuleAttainmentWorkflow,
    isModuleContentWorkflow,
    isPriorLearningSubstitutionWorkflow,
    isStudyRightExtensionWorkflow,
} from '../../workflow/WorkflowUtils';

/**
 * resolves the closest study (opinto) to the workflow.
 * the name of the study can then be used when listing workflows in {@link StudentWorkflowListComponent}
 * n.b: the logic here is somewhat arbitrary - this just returns a study whose name makes the most sense to show in the application listing. I wouldn't use it for anything critical
 * */
@Pipe({
    name: 'workflowRelatedStudy',
})
export class WorkflowRelatedStudyPipe implements PipeTransform {

    constructor(
        private appErrorHandler: AppErrorHandler,
        private courseUnitEntityService: CourseUnitEntityService,
        private moduleEntityService: ModuleEntityService,
        private educationEntityService: EducationEntityService,
    ) {
    }

    transform(workflow: Workflow): Observable<CourseUnit | Module | Education> {
        if (!workflow) {
            return null;
        }

        let workflowRelatedEntity$: Observable<CourseUnit | Module | Education> = EMPTY;

        if (isPriorLearningSubstitutionWorkflow(workflow) && workflow.courseUnitId) {
            workflowRelatedEntity$ = this.courseUnitEntityService.getById(workflow.courseUnitId);
        } else if (isModuleContentWorkflow(workflow) && workflow.approvedModuleId) {
            workflowRelatedEntity$ = this.moduleEntityService.getById(workflow.approvedModuleId);
        } else if (isModuleAttainmentWorkflow(workflow) || isDegreeProgrammeAttainmentWorkflow(workflow) && workflow.moduleId) {
            workflowRelatedEntity$ = this.moduleEntityService.getById(workflow.moduleId);
        } else if (isStudyRightExtensionWorkflow(workflow) && workflow.educationId) {
            workflowRelatedEntity$ = this.educationEntityService.getById(workflow.educationId);
        }

        return workflowRelatedEntity$
            .pipe(
                this.appErrorHandler.defaultErrorHandler(),
            );
    }

}
