import { Type } from '@angular/core';

/**
 * Contains Angular -> AngularJS component downgrade mapping information.
 */
export interface ComponentDowngradeMappings extends DowngradeMappings {
    /**
     * The name of the directive in AngularJS. You include the component in an AngularJS template with a kebab-case version of
     * the directive name, e.g. `<sis-checkbox ...></sis-checkbox>`.
     */
    directiveName: string;
}

export type DowngradedComponent = Type<any> & WithComponentDowngradeMappings;

export type DowngradedService = Type<any> & WithServiceDowngradeMappings;

/**
 * Downgrade mapping information common to all kinds of Angular elements: components, directives, services etc..
 */
export interface DowngradeMappings {
    /**
     * AngularJS dependencies for this directive/component/service.
     */
    dependencies?: string[];
    /**
     * The name of the AngularJS module that the directive/component/service will be included in.
     */
    moduleName: string;
}

/**
 * Contains Angular -> AngularJS service downgrade mapping information.
 */
export interface ServiceDowngradeMappings extends DowngradeMappings {
    /**
     * The name that will be used when injecting the service in AngularJS.
     */
    serviceName: string;
}

/**
 * A decorator for denoting that a class implements an interface with static member fields.
 *
 * This is a workaround adapted from https://github.com/microsoft/TypeScript/issues/14600#issuecomment-333416173.
 */
/* eslint-disable-next-line */
export function StaticMembers<T>() {
    return (constructor: T) => {};
}

export interface WithComponentDowngradeMappings {
    downgrade: ComponentDowngradeMappings;
}

export interface WithServiceDowngradeMappings {
    downgrade: ServiceDowngradeMappings;
}
