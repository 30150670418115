import { Pipe, PipeTransform } from '@angular/core';
import { LocalizedString, OtmId } from 'common-typescript/types';
import { EMPTY, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { AppErrorHandler } from '../error-handler/app-error-handler';
import { ModuleEntityService } from '../service/module-entity.service';
import { StudyRightEntityService } from '../service/study-right-entity.service';

@Pipe({ name: 'studyRightActivePhaseModuleName' })
export class StudyRightActivePhaseModuleNamePipe implements PipeTransform {

    constructor(
        private appErrorHandler: AppErrorHandler,
        private moduleEntityService: ModuleEntityService,
        private studyRightEntityService: StudyRightEntityService,
    ) {}

    transform(studyRightId: OtmId): Observable<LocalizedString> {
        if (!studyRightId) {
            return null;
        }
        return this.studyRightEntityService.getStudyRightActivePhaseModuleGroupId(studyRightId)
            .pipe(
                mergeMap(studyRightActivePhaseModule =>
                    studyRightActivePhaseModule.moduleGroupId
                        ? this.moduleEntityService.getByGroupId(studyRightActivePhaseModule.moduleGroupId)
                        : EMPTY,
                ),
                map(module => module?.name),
                this.appErrorHandler.defaultErrorHandler(),
            );
    }
}
