<ng-container *transloco="let t">
  <div class="module-attainment-application-block">
    <div class="row">
      <div class="col-12 col-md-8">
        <p>
          {{t('MODULE_INFO.MODULE_ATTAINMENT_APPLICATION.APPLICATION_IS_SENT_INFO')}}
          <span class="warning-guidance">
            {{t('MODULE_INFO.MODULE_ATTAINMENT_APPLICATION.MODULE_APPLICATION_NOTIFICATION')}}
          </span>
        </p>
      </div>
      <div class="col-12 col-md-4 button-holder">
        <button data-cy="show-module-attainment-application-button" class="btn btn-white btn-lg"
                (click)="navigateToModuleAttainmentApplication()">
          {{t('MODULE_INFO.MODULE_ATTAINMENT_APPLICATION.SHOW_APPLICATION')}}
        </button>
      </div>
    </div>
  </div>
</ng-container>


