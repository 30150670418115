<ng-container *transloco="let t">
  <div class="modal-header">
    <h2 class="modal-title" sisAutofocus>
      <ng-container *ngIf="event.extendedProps.eventName">{{event.extendedProps.eventName | localizedString}}<br/></ng-container>
      <ng-container *ngIf="!event.extendedProps.eventName && event.extendedProps.courseUnitRealisation">{{event.extendedProps.courseUnitRealisation | courseUnitRealisationName:true}}<br/></ng-container>
      <ng-container *ngIf="!event.extendedProps.courseUnitRealisation">{{event.extendedProps.name | localizedString}}<br/></ng-container>
      <ng-container>{{event.extendedProps.eventTitle?.name | localizedString}}, {{event.extendedProps.studySubGroupName | localizedString}}</ng-container>
    </h2>
    <sis-modal-close-button/>
  </div>
  <div class="modal-body">
    <sis-tiny-badge *ngIf="event.extendedProps.cancelled" [variant]="'danger'">{{ t('SIS_COMPONENTS.CALENDAR.EVENT_CANCELLED') }}</sis-tiny-badge>
    <sis-tiny-badge *ngIf="event.extendedProps.enrolment.state === 'NOT_ENROLLED'" [variant]="'accent'">{{ t('SIS_COMPONENTS.CALENDAR.NOT_ENROLLED') }}</sis-tiny-badge>
    <sis-description-list-compact [columnCount]="1">
      <dl>
        <ng-template #compactListItem>
          <dt class="label colon">{{ t('SIS_COMPONENTS.COURSE_UNIT_REALISATION_STRUCTURE.EVENTS_TABLE.COLUMNS.TIME') }}</dt>
          <dd class="value">
            {{ event.start | weekdayAndDateTimeRange: event.end }}
          </dd>
        </ng-template>
        <ng-template #compactListItem>
          <dt class="label colon">{{ t('SIS_COMPONENTS.COURSE_UNIT_REALISATION_STRUCTURE.EVENTS_TABLE.COLUMNS.LOCATIONS') }}</dt>
          <ng-container *ngFor="let locationId of event.extendedProps.locationIds">
            <dd class="value" *ngIf="locationId | location | async as location">
              {{ location.name | localizedString }}
            </dd>
          </ng-container>
          <dd *ngIf="event.extendedProps.locationIds?.length === 0 && !event?.extendedProps.locationsRemoved" class="value">
            {{ t('SIS_COMPONENTS.CALENDAR.NO_LOCATION') }}
          </dd>
          <dd *ngIf="event.extendedProps.locationsRemoved" class="value">
            {{ t('SIS_COMPONENTS.CALENDAR.LOCATIONS_REMOVED') }}
          </dd>
        </ng-template>
        <ng-template #compactListItem>
          <dt class="label colon">{{ t('SIS_COMPONENTS.COURSE_UNIT_REALISATION_STRUCTURE.EVENTS_TABLE.COLUMNS.TEACHERS') }}</dt>
          <dd *ngFor="let teacherId of event.extendedProps.teacherIds; last as last" class="value">
            {{ teacherId | publicPerson | async | personFullName }}{{last ? '' : ','}}
          </dd>
          <dd *ngIf="!event.extendedProps.teacherIds || event.extendedProps.teacherIds.length === 0" class="value">
            {{ t('SIS_COMPONENTS.CALENDAR.NO_TEACHER') }}
          </dd>
        </ng-template>
        <ng-template #compactListItem *ngIf="event.extendedProps.notice">
          <dt class="label colon">{{ t('FIELD_NAMES.notice') }}</dt>
          <dd class="value">{{event.extendedProps.notice | localizedString }}</dd>
        </ng-template>
      </dl>
    </sis-description-list-compact>
    <sis-button (clicked)="openCourseUnitInfoModal()" link="true">
      {{ t('OPEN_COURSE_UNIT_INFO') }}
    </sis-button>
  </div>
  <div class="modal-footer confirm-dialog-footer">
    <sis-button (clicked)="activeModal.dismiss()">{{ t('BUTTON.CLOSE') }}</sis-button>
  </div>
</ng-container>
