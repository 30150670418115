import {
    ChangeDetectionStrategy,
    Component,
    inject,
    Input,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { Enrolment } from 'common-typescript/types';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import {
    getStudyGroupsForEnrolmentViewData, StudyGroupSetViewData,
} from 'sis-common/enrolment/enrolment-utils';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { CourseUnitRealisationEntityService } from 'sis-components/service/course-unit-realisation-entity.service';

@Component({
    selector: 'app-enrolment-study-groups-info',
    templateUrl: './enrolment-study-groups-info.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnrolmentStudyGroupsInfoComponent implements OnInit {

    @Input({ required: true }) enrolment: Enrolment;

    courseUnitRealisationEntityService = inject(CourseUnitRealisationEntityService);
    appErrorHandler = inject(AppErrorHandler);
    viewData$: Observable<StudyGroupSetViewData[]>;

    ngOnInit(): void {
        this.viewData$ = this.courseUnitRealisationEntityService.getById(this.enrolment.courseUnitRealisationId)
            .pipe(
                take(1),
                map(courseUnitRealisation => getStudyGroupsForEnrolmentViewData(this.enrolment, courseUnitRealisation).studyGroupSetViewData),
                this.appErrorHandler.defaultErrorHandler(),
            );
    }

}

