<span>
  <span data-cy="enrolment-right-is-activated-label" class="tiny-badge secondary"
        *ngIf="resolvedEnrolmentRightState === 'ACTIVATED_FUTURE'">
    {{'ENROLMENT_RIGHT_RESOLVED_STATE.ACTIVATED_FUTURE' | translate }}
  </span>

  <span data-cy="enrolment-right-is-activated-label" class="tiny-badge success"
        *ngIf="resolvedEnrolmentRightState === 'ACTIVATED_ACTIVE'">
    {{'ENROLMENT_RIGHT_RESOLVED_STATE.ACTIVATED_ACTIVE' | translate }}
  </span>

  <span data-cy="enrolment-right-is-expired-label" class="tiny-badge secondary"
        *ngIf="resolvedEnrolmentRightState === 'ACTIVATED_EXPIRED'">
    {{ 'ENROLMENT_RIGHT_RESOLVED_STATE.ACTIVATED_EXPIRED' | translate}}
  </span>

  <span data-cy="enrolment-right-is-cancelled-label" class="tiny-badge danger"
        *ngIf="resolvedEnrolmentRightState === 'CANCELLED'">
    {{'ENROLMENT_RIGHT_RESOLVED_STATE.CANCELLED' | translate}}
  </span>

  <span *ngIf="isSubstitutionRight" class="tiny-badge primary">
    {{'ENROLMENT_RIGHT_TYPE.SUBSTITUTION' | translate}}
  </span>
</span>
