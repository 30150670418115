<ng-container *transloco="let t">
  <div class="degree-programme-application-requested-block">
    <div class="row ">
      <div class="col-12">
        {{t('MODULE_INFO.GRADUATION.GRADUATION_REQUESTED', {degreeProgrammeName: selectedModule.name | localizedString})}}.
        <a id="navigate-to-dpa-application" class="application-link"
           [uiSref]="'student.logged-in.profile.applications.degree-programme-attainment-workflow'"
           [uiParams]="{applicationId: degreeProgrammeAttainmentWorkflow.id}"
           (click)="close()">
          {{t('MODULE_INFO.GRADUATION.NAVIGATE_TO_DPA_APPLICATION')}}
        </a>
      </div>
    </div>
  </div>
</ng-container>
