import { Component, ViewEncapsulation } from '@angular/core';
import { Tab } from 'sis-components/tabs/tab-navigation/tab-navigation.component';

@Component({
    selector: 'app-payments-and-grants-tabs',
    templateUrl: './payment-and-grant-tabs.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class PaymentAndGrantTabsComponent {

    readonly tabs: Tab[] = [
        { path: 'student.logged-in.profile.payments-and-grants.payments', title: 'PROFILE.PAYMENTS.TAB_TITLE' },
        { path: 'student.logged-in.profile.payments-and-grants.grants', title: 'PROFILE.GRANTS.TAB_TITLE' },
    ];
}
