import angular from 'angular';
import 'js-data';
export const enrolmentCalculationResultModelModule = 'sis-components.model.enrolmentCalculationResultModel';
(function () {
  enrolmentCalculationResultModel.$inject = ["DS"];
  angular.module(enrolmentCalculationResultModelModule, ['js-data']).factory('enrolmentCalculationResultJSDataModel', enrolmentCalculationResultModel);

  /**
   * @ngInject
   */
  function enrolmentCalculationResultModel(DS) {
    return DS.defineResource({
      idAttribute: 'id',
      name: 'enrolmentCalculationResult',
      endpoint: '/ilmo/api/enrolment-calculation-results'
    });
  }
})();