<ul class="list-group-horizontal row sis-plan-structure-attainment-node-structure-list">
  <li *ngFor="let courseUnitId of filteredNodes().courseUnitAttainmentCourseUnitIds"
      class="list-group-item col-sm-12 col-md-6 col-lg-4">
    <div class="sis-mb-sm sis-mr-sm">
      <sis-plan-structure-course-unit [courseUnit]="planData.courseUnitsById[courseUnitId]"
                                      [planStateObject]="planStateObject"
                                      [headingLevel]="headingLevel"
                                      [planData]="planData">
      </sis-plan-structure-course-unit>
    </div>
  </li>
  <li *ngFor="let customCourseUnitAttainmentId of filteredNodes().customCourseUnitAttainmentIds"
      class="list-group-item col-sm-12 col-md-6 col-lg-4">
    <div class="sis-mb-sm sis-mr-sm">
      <sis-plan-structure-custom-course-unit-attainment
        [customCourseUnitAttainment]="getCustomCourseUnitAttainment(customCourseUnitAttainmentId)"
        [planStateObject]="planStateObject"
        [headingLevel]="headingLevel">
      </sis-plan-structure-custom-course-unit-attainment>
    </div>
  </li>
</ul>
<sis-plan-structure-module *ngFor="let moduleId of filteredNodes().moduleAttainmentModuleIds"
                           [module]="getModule(moduleId)"
                           [planStateObject]="planStateObject"
                           [planData]="planData"
                           [headingLevel]="headingLevel">
</sis-plan-structure-module>
<sis-plan-structure-custom-module-attainment
  *ngFor="let customModuleAttainmentId of filteredNodes().customModuleAttainmentIds"
  [customModuleAttainment]="getCustomModuleAttainment(customModuleAttainmentId)"
  [planStateObject]="planStateObject"
  [planData]="planData"
  [headingLevel]="headingLevel">
</sis-plan-structure-custom-module-attainment>
<sis-plan-structure-attainment-group-node *ngFor="let attainmentGroupNode of filteredNodes().attainmentGroupNodes"
                                          [attainmentGroupNode]="attainmentGroupNode"
                                          [planStateObject]="planStateObject"
                                          [planData]="planData"
                                          [headingLevel]="headingLevel">
</sis-plan-structure-attainment-group-node>
