import { InjectionToken, Provider } from '@angular/core';
import * as angular from 'angular';
import { Organisation } from 'common-typescript/types';
import { DSResourceDefinition } from 'js-data';

/* **************************************************************************************
 * NOTE: Don't add any new upgrade configs here without a very good reason. If you need *
 * functionality from a legacy AngularJS service, your first choice should always be to *
 * move/reimplement that functionality to a new/existing Angular service.               *
 ************************************************************************************** */

const angularJSInjectorName = '$injector';

/**
 * @deprecated
 */
export const ORGANISATION_JS_DATA_MODEL = new InjectionToken<DSResourceDefinition<Organisation>>('organisationJSDataModel');
export const organisationJsDataModelFactory = (injector: angular.auto.IInjectorService) => injector.get('organisationJSDataModel');
export const organisationJSDataModelProvider: Provider = {
    provide: ORGANISATION_JS_DATA_MODEL,
    useFactory: organisationJsDataModelFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated
 */
export const COURSE_UNIT_INFO_MODAL = new InjectionToken<any>('courseUnitInfoModal');
export const courseUnitInfoModalFactory = (injector: angular.auto.IInjectorService) => injector.get('courseUnitInfoModal');
export const courseUnitInfoModalProvider: Provider = {
    provide: COURSE_UNIT_INFO_MODAL,
    useFactory: courseUnitInfoModalFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated
 */
export const CHOOSE_PLAN_SERVICE = new InjectionToken<any>('choosePlanService');
export const choosePlanServiceFactory = (injector: angular.auto.IInjectorService) => injector.get('choosePlanService');
export const choosePlanServiceProvider: Provider = {
    provide: CHOOSE_PLAN_SERVICE,
    useFactory: choosePlanServiceFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated
 */
export const PLAN_SEEKER = new InjectionToken<any>('planSeeker');
export const planSeekerFactory = (injector: angular.auto.IInjectorService) => injector.get('planSeeker');
export const planSeekerProvider: Provider = {
    provide: PLAN_SEEKER,
    useFactory: planSeekerFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated
 */
export const STUDY_PROGRESS_GRAPH_DROPDOWN_CONTENT_FETCHER = new InjectionToken<any>('studyProgressGraphDropDownContentFetcher');
export const studyProgressGraphDropDownContentFetcherFactory = (injector: angular.auto.IInjectorService) => injector.get('studyProgressGraphDropDownContentFetcher');
export const studyProgressGraphDropDownContentFetcherProvider: Provider = {
    provide: STUDY_PROGRESS_GRAPH_DROPDOWN_CONTENT_FETCHER,
    useFactory: studyProgressGraphDropDownContentFetcherFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated
 */
export const STUDENT_PROFILE_SERVICE = new InjectionToken<any>('studentProfileService');
export const studentProfileService = (injector: angular.auto.IInjectorService) => injector.get('studentProfileService');
export const studentProfileServiceProvider = {
    provide: STUDENT_PROFILE_SERVICE,
    useFactory: studentProfileService,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated
 */
export const DEGREE_PROGRAMME_ATTAINMENT_APPLICATION_SERVICE = new InjectionToken<any>('degreeProgrammeAttainmentApplicationService');
export const degreeProgrammeAttainmentApplicationService = (injector: angular.auto.IInjectorService) => injector.get('degreeProgrammeAttainmentApplicationService');
export const degreeProgrammeAttainmentApplicationServiceProvider = {
    provide: DEGREE_PROGRAMME_ATTAINMENT_APPLICATION_SERVICE,
    useFactory: degreeProgrammeAttainmentApplicationService,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated
 */
export const STUDY_PERIOD_SERVICE = new InjectionToken<any>('studyPeriodService');
export const studyPeriodService = (injector: angular.auto.IInjectorService) => injector.get('studyPeriodService');
export const studyPeriodServiceProvider = {
    provide: STUDY_PERIOD_SERVICE,
    useFactory: studyPeriodService,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated
 */
export const ENROLMENT_MODAL_SERVICE = new InjectionToken<any>('enrolmentModalService');
export const enrolmentModalService = (injector: angular.auto.IInjectorService) => injector.get('enrolmentModalService');
export const enrolmentModalServiceProvider: Provider = {
    provide: ENROLMENT_MODAL_SERVICE,
    useFactory: enrolmentModalService,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated Use `EnrolmentStudentService` instead
 */
export const ENROLMENT_SERVICE = new InjectionToken<any>('enrolmentService');
export const enrolmentService = (injector: angular.auto.IInjectorService) => injector.get('enrolmentService');
export const enrolmentServiceProvider = {
    provide: ENROLMENT_SERVICE,
    useFactory: enrolmentService,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated
 */
export const PREVIEW_MODE = new InjectionToken<Function>('previewMode');
export const getPreviewMode = (injector: angular.auto.IInjectorService) => injector.get('previewMode');
export const previewModeProvider = {
    provide: PREVIEW_MODE,
    useFactory: getPreviewMode,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated
 */
export const ATTAINMENT_SERVICE = new InjectionToken<Function>('attainmentService');
export const getAttainmentService = (injector: angular.auto.IInjectorService) => injector.get('attainmentService');
export const attainmentServiceProvider = {
    provide: ATTAINMENT_SERVICE,
    useFactory: getAttainmentService,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated
 */
export const UI_CONTEXT = new InjectionToken<any>('uiContext');
export const getUiContext = (injector: angular.auto.IInjectorService) => injector.get('uiContext');
export const uiContextProvider = {
    provide: UI_CONTEXT,
    useFactory: getUiContext,
    deps: [angularJSInjectorName],
};

export const MODULE_ATTAINMENT_APPLICATION_SERVICE = new InjectionToken<any>('moduleAttainmentApplicationService');
export const getModuleAttainmentApplicationService = (injector: angular.auto.IInjectorService) => injector.get('moduleAttainmentApplicationService');
export const moduleAttainmentApplicationServiceProvider = {
    provide: MODULE_ATTAINMENT_APPLICATION_SERVICE,
    useFactory: getModuleAttainmentApplicationService,
    deps: [angularJSInjectorName],
};

