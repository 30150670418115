<ng-container *transloco="let t">
  <button class="event-button" [class]="viewType === 'timeGridWeek' ? 'time-grid-week' : 'day-grid-month'" (click)="openStudyEventPreviewModal()">
    <span *ngIf="event.extendedProps.enrolment.state === 'NOT_ENROLLED'" [attr.aria-label]="t('SIS_COMPONENTS.CALENDAR.NOT_ENROLLED')"></span>
    <ng-container
      [ngTemplateOutlet]="viewType === 'timeGridWeek' ? timeGridWeek : dayGridMonth"
      [ngTemplateOutletContext]="{label: t('SIS_COMPONENTS.CALENDAR.EVENT_CANCELLED')}">
    </ng-container>
  </button>
</ng-container>

<ng-template #timeGridWeek let-label="label">
  <span [ngClass]="event.extendedProps.cancelled ? 'event-info-row time-grid-week__alert' : 'event-info-row time-grid-week'">
    <span *ngIf="event.extendedProps.cancelled" [attr.aria-label]="label" class="cancelled-icon" data-cy="cancelled-study-event"><sis-icon [color]="'danger'" [icon]="'alert'"/></span>
    {{event.start | localTimeFormat}}–{{event.end | localTimeFormat}}
  </span>
  <span *ngIf="event.extendedProps.eventName" class="event-info-row time-grid-week" >{{event.extendedProps.eventName | localizedString}}</span>
  <span *ngIf="!event.extendedProps.eventName && event.extendedProps.courseUnitRealisation" class="event-info-row time-grid-week" >{{event.extendedProps.courseUnitRealisation | courseUnitRealisationName:true}}</span>
  <span *ngIf="!event.extendedProps.courseUnitRealisation" class="event-info-row time-grid-week" >{{event.extendedProps.name | localizedString}}</span>
  <span class="event-info-row time-grid-week" >{{event.extendedProps.eventTitle?.name | localizedString}}, {{event.extendedProps.studySubGroupName | localizedString}}</span>
</ng-template>

<ng-template #dayGridMonth let-label="label">
  <span *ngIf="event.extendedProps.eventName" class="event-info-row day-grid-month">
    <span *ngIf="event.extendedProps.cancelled" [attr.aria-label]="label" class="cancelled-icon" data-cy="cancelled-study-event"><sis-icon [color]="'danger'" [icon]="'alert'"/></span>
    {{event.extendedProps.eventName | localizedString}}
  </span>
</ng-template>
