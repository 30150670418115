<sis-expandable [title]="'SUBSTITUTIONS.SELECTOR.TITLE' | transloco: {currentUniversity: universityName$ | async }"
                [level]="4" [closed]="false">
  <ng-template sisExpandableContent>
    <div *ngIf="hasSubstitutes">
      <sis-contextual-notification *ngIf="isSubstitute"
                                   variant="warning">{{'SUBSTITUTIONS.UNABLE_TO_SUBSTITUTE' | transloco}}</sis-contextual-notification>
      <p><transloco key="{{ 'SUBSTITUTIONS.SELECTOR.SUBSTITUTE_INFORMATION' }}" [params]="{currentUniversity: universityName$ | async}"></transloco></p>
      <p *ngIf="hasSomeSubstitutesToSelect"><transloco key="{{ 'SUBSTITUTIONS.SELECTOR.SUBSTITUTE_SELECT_INFORMATION' }}"></transloco></p>
      <form novalidate>
        <fieldset>
          <legend class="sis-label">{{'SUBSTITUTIONS.SELECTOR.SUBTITLE' | transloco}}</legend>
          <div [attr.disabled]="isSubstitute" id="substitutions-form-group">
            <sis-radio-button *ngIf="hasSomeSubstitutesToSelect"
                              [control]="selectedSubstitutionsLocalId"
                              [value]="null"
                              [inline]="true"
                              [label]="'SUBSTITUTIONS.SELECTOR.NO'">
            </sis-radio-button>
            <div
              *ngFor="let substitutionOption of mySubstitutionOptions; index as substitutionOptionIndex; trackBy:substitutionOptionByLocalId">
              <h5 class="sis-label" *ngIf="showAsGrouped">
                {{ ('STUDIES.COURSE_UNIT_INFO_MODAL.SUBSTITUTIONS_OPTION' | translate) + ' ' + (substitutionOptionIndex + 1)}}
              </h5>
              <div [ngClass]="showAsGrouped ? 'substitution-container-no-radio' : 'substitution-container'">
                <div class="radio-button-substitution-option">
                  <sis-radio-button
                    *ngIf="substitutionOption.isSelectable"
                    [control]="selectedSubstitutionsLocalId"
                    [value]="substitutionOption.localId">
                  </sis-radio-button>
                </div>
                <div class="substitution-course-units">
                  <div
                    [class.course-unit-substitution-group]="substitutionOption.isGroup || substitutionOption.isAttainmentAlreadyAttached"
                    *ngFor="let courseUnitSubstitution of substitutionOption.substitution">
                    <sis-course-unit-box
                      *ngIf="substituteCourseUnitsByGroupId && substituteCourseUnitsByGroupId[courseUnitSubstitution.courseUnitGroupId]"
                      [courseUnit]="substituteCourseUnitsByGroupId[courseUnitSubstitution.courseUnitGroupId]"
                      [attainment]="getAttainmentByCourseUnitGroupId(courseUnitSubstitution.courseUnitGroupId)"
                      [categoryCssClass]="'cu-color-' + (substitutionOptionIndex + 1)"
                      (codeClickAction)="handleOpenCourseUnitInfoModal($event)">
                      <div course-unit-box="notifications" *ngIf="courseUnitSubstitution.credits" class="cu-notification-bar">
                        <sis-tooltip [tooltipText]="'SUBSTITUTIONS.CREDITS' | transloco: {credits: courseUnitSubstitution.credits}">
                          <span class="cu-notification sis-tooltip-trigger-content">
                            <span class="cu-notification-header"><sis-icon icon="info"></sis-icon></span>
                            <span class="cu-notification-content">{{'SUBSTITUTIONS.CREDITS' | transloco: {credits: courseUnitSubstitution.credits} }}</span>
                          </span>
                        </sis-tooltip>
                      </div>
                    </sis-course-unit-box>
                  </div>
                  <sis-contextual-notification *ngIf="substitutionOption.isAttainmentAlreadyAttached"
                                               variant="warning">{{'SUBSTITUTIONS.ATTAINMENT_ALREADY_ATTACHED' | transloco }}</sis-contextual-notification>
                  <sis-contextual-notification
                    *ngIf="isEditable && substitutionOption.isSubstituteCourseUnitAlreadyInPlan"
                    variant="warning">{{'SUBSTITUTIONS.COURSE_UNIT_ALREADY_IN_PLAN' | transloco }}</sis-contextual-notification>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </form>
      <sis-localized-markup-string-view class="equivalent-courses-info"
                                        *ngIf="courseUnit.equivalentCoursesInfo"
                                        [content]="courseUnit.equivalentCoursesInfo">
      </sis-localized-markup-string-view>
    </div>

    <div *ngIf="!hasSubstitutes">
      <p class="sis-placeholder">{{'SUBSTITUTIONS.SELECTOR.TITLE_NONE' | transloco }}</p>
    </div>
  </ng-template>
</sis-expandable>
