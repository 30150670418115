import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarEvent } from 'common-typescript/types';
import { ModalService } from 'sis-common/modal/modal.service';

export interface AddOwnCalendarEventValues {
    existingCalendarEvent: CalendarEvent;
}

@Component({
    selector: 'app-add-own-calendar-event-modal',
    template: '<add-own-calendar-event-modal (close)="activeModal.close()" (dismiss)="activeModal.dismiss()" [resolve]="values"></add-own-calendar-event-modal>',
    encapsulation: ViewEncapsulation.None,
})
export class AddOwnCalendarEventModalComponent {
    constructor(@Inject(ModalService.injectionToken) public values: AddOwnCalendarEventValues,
                public activeModal: NgbActiveModal) {}
}
