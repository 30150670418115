import {
    ChangeDetectionStrategy,
    Component,
    computed,
    Input,
    Signal,
    signal,
    ViewEncapsulation,
} from '@angular/core';
import {
    AttainmentGroupNode,
    AttainmentNode,
    AttainmentReferenceNode,
    CourseUnitAttainment, CustomCourseUnitAttainment, CustomModuleAttainment, DegreeProgramme, GroupingModule,
    ModuleAttainment, OtmId, StudyModule,
} from 'common-typescript/types';
import _ from 'lodash';

import { PlanData, PlanStateObject } from '../../service/plan-state.service';

interface NodeData {
    attainmentGroupNodes: AttainmentGroupNode[];
    courseUnitAttainmentCourseUnitIds: OtmId[];
    moduleAttainmentModuleIds: OtmId[];
    customCourseUnitAttainmentIds: OtmId[];
    customModuleAttainmentIds: OtmId[];
}

@Component({
    selector: 'sis-plan-structure-attainment-node-structure',
    templateUrl: './plan-structure-attainment-node-structure.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanStructureAttainmentNodeStructureComponent {

    @Input({ required: true }) set attainmentNodes(nodes: AttainmentNode[]) {
        this._attainmentNodes.set(nodes);
    }

    _attainmentNodes = signal([], { equal: _.isEqual });
    @Input({ required: true }) planStateObject: PlanStateObject;
    @Input({ required: true }) planData: PlanData;
    @Input({ required: true }) headingLevel: number;

    filteredNodes: Signal<NodeData> = computed(() => {
        const attainmentGroupNodes: AttainmentGroupNode[] = [];
        const courseUnitAttainmentCourseUnitIds: OtmId[] = [];
        const moduleAttainmentModuleIds: OtmId[] = [];
        const customCourseUnitAttainmentIds: OtmId[] = [];
        const customModuleAttainmentIds: OtmId[] = [];

        _.forEach(this._attainmentNodes(), (node) => {
            if (node.type === 'AttainmentGroupNode') {
                attainmentGroupNodes.push(node as AttainmentGroupNode);
            }
            if (node.type === 'AttainmentReferenceNode') {
                const attainment = this.planData.attainmentsById[(node as AttainmentReferenceNode).attainmentId];

                if (attainment.type === 'CourseUnitAttainment') {
                    courseUnitAttainmentCourseUnitIds.push((attainment as CourseUnitAttainment).courseUnitId);
                }
                if (attainment.type === 'ModuleAttainment') {
                    moduleAttainmentModuleIds.push((attainment as ModuleAttainment).moduleId);
                }
                if (attainment.type === 'CustomCourseUnitAttainment') {
                    customCourseUnitAttainmentIds.push(attainment.id);
                }
                if (attainment.type === 'CustomModuleAttainment') {
                    customModuleAttainmentIds.push(attainment.id);
                }
            }
        });
        return {
            attainmentGroupNodes,
            courseUnitAttainmentCourseUnitIds,
            moduleAttainmentModuleIds,
            customCourseUnitAttainmentIds,
            customModuleAttainmentIds,
        };
    });

    getCustomCourseUnitAttainment(id: OtmId): CustomCourseUnitAttainment {
        return this.planData.attainmentsById[id] as CustomCourseUnitAttainment;
    }

    getCustomModuleAttainment(id: OtmId): CustomModuleAttainment {
        return this.planData.attainmentsById[id] as CustomModuleAttainment;
    }

    getModuleAttainment(id: OtmId): ModuleAttainment {
        return this.planData.attainmentsById[id] as ModuleAttainment;
    }

    getModule(id: OtmId): StudyModule | DegreeProgramme | GroupingModule {
        return this.planData.modulesById[id] as StudyModule | DegreeProgramme | GroupingModule;
    }
}
