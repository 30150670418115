<div class="row">
    <div class="col-md-12">
        <div class="state-credits-header float-end">
            <div class="module-grade-average-header">
                <h4 translate>SIS_COMPONENTS.PLAN.LABEL.GRADE_AVERAGE</h4>
            </div>
            <div class="module-state-header">
                <h4 translate>SIS_COMPONENTS.PLAN.LABEL.STATE</h4>
            </div>
            <div class="module-credits-header">
                <h4 translate>SIS_COMPONENTS.PLAN.LABEL.TARGET_CREDITS</h4>
                <div class="sub-headers">
                    <span translate>SIS_COMPONENTS.PLAN.LABEL.SELECTED_CREDITS</span>
                    <span class="credits-done" translate>SIS_COMPONENTS.PLAN.LABEL.ATTAINED</span>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-12" id="plan-snapshot-structure">
        <common-plan-module
            *ngIf="planDataLoaded"
            [moduleId]="application.moduleId"
            [parentModuleId]="parentModuleId"
            [plan]="planSnapshot"
            [planValidationResult]="planValidationResult"
            [validatablePlan]="validatablePlan"
            [educationOptions]="[]"
            [requestedApplications]="requestedApplications"
            [showImplicitSelections]="true">
        </common-plan-module>
    </div>
</div>
