import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AssessmentItemAttainment, Attainment, StudentAssessmentInfo } from 'common-typescript/types';
import { EMPTY, Observable } from 'rxjs';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';

import { StudentAssessmentInfoService } from '../../../../common/service/student-assessment-info.service';

@Component({
    selector: 'app-assessment-item-attainment-details',
    templateUrl: './assessment-item-attainment-details.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssessmentItemAttainmentDetailsComponent implements OnInit {
    @Input() attainment: AssessmentItemAttainment;
    @Input() secondaryAttainments: Attainment[];
    @Input() expirationImminent: boolean;

    $studentAssessmentInfoForCur: Observable<StudentAssessmentInfo>;

    constructor(private studentAssessmentInfoService: StudentAssessmentInfoService,
                private appErrorHandler: AppErrorHandler) {
    }

    ngOnInit() {
        this.$studentAssessmentInfoForCur = this.attainment?.courseUnitRealisationId ?
            this.studentAssessmentInfoService
                .getMyAssessmentInfoByCurId(this.attainment.courseUnitRealisationId)
                .pipe(this.appErrorHandler.defaultErrorHandler()) : EMPTY;
    }
}
