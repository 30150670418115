import _ from 'lodash';
import moment from 'moment';
import { dateUtils } from 'common-typescript';
export const commonStudyPeriodServiceModule = 'sis-components.service.studyPeriodService';
(function () {
  commonStudyPeriodService.$inject = ["errorService", "universityService", "studyYearTemplateJSDataModel", "studyYearJSDataModel", "$translate", "localeService"];
  angular.module(commonStudyPeriodServiceModule, ['sis-common.errorhandler.errorService', 'sis-common.university', 'sis-components.model.studyYearTemplate', 'sis-components.model.studyYear', 'sis-common.l10n.localeService']).factory('commonStudyPeriodService', commonStudyPeriodService);

  /**
   * @ngInject
   */
  function commonStudyPeriodService(errorService, universityService, studyYearTemplateJSDataModel, studyYearJSDataModel, $translate, localeService) {
    var orgIndex = 0;
    var yearIndex = 1;
    var api = {
      getStudyYears: getStudyYears,
      getPeriodsFor: getPeriodsFor,
      getStudyYearTemplates: getStudyYearTemplates,
      getCurrentStudyYear: getCurrentStudyYear,
      getCurrentStudyTerm: getCurrentStudyTerm,
      getCurrentStudyTermOfStudyYear: getCurrentStudyTermOfStudyYear,
      getCurrentUniversityStudyTermIndex: getCurrentUniversityStudyTermIndex,
      transformRepeatArrayToStudyPeriodRangesArray: transformRepeatArrayToStudyPeriodRangesArray,
      extractStudyYearPeriodFromPossibility: extractStudyYearPeriodFromPossibility,
      getStudyPeriodByDate: getStudyPeriodByDate,
      getStudyTerms: getStudyTerms,
      getStudyPeriods: getStudyPeriods,
      parseStudyPeriodLocator: parseStudyPeriodLocator,
      getStudyPeriodsForSearchFilter: getStudyPeriodsForSearchFilter,
      addSpecifiersToDuplicateStudyPeriodNames: addSpecifiersToDuplicateStudyPeriodNames
    };
    return api;
    function getStudyYears(org, firstYear, numYears) {
      var params = {
        organisationId: org,
        firstYear: firstYear,
        numberOfYears: numYears
      };
      return studyYearJSDataModel.findAll(params).then(function (result) {
        return result;
      });
    }
    function getPeriodsFor(locator) {
      var parts = locator.split('/');
      var organisation = parts[orgIndex];
      var firstYear = parts[yearIndex];
      return getStudyYears(organisation, firstYear, 1).then(function (studyYears) {
        var years = _.filter(studyYears, function (year) {
          return year.startYear === parseInt(firstYear);
        });
        var periods = _(years).flatMap(function (year) {
          return year.studyTerms;
        }).flatMap(function (term) {
          return term.studyPeriods;
        }).value();
        return _.find(periods, function (period) {
          return period.locator === locator;
        });
      });
    }

    /**
     * @param universityOrgId The id of the university whose study year to fetch. Defaults to the currently
     * selected university.
     * @param showErrorIfNotFound Whether to show a system error modal if the current study year is not found.
     * Defaults to true.
     */
    function getCurrentStudyYear(universityOrgId, showErrorIfNotFound) {
      universityOrgId = universityOrgId ? universityOrgId : universityService.getCurrentUniversityOrgId();
      showErrorIfNotFound = _.isUndefined(showErrorIfNotFound) ? true : !!showErrorIfNotFound;
      var firstYear = moment().year() - 1;
      const today = moment();
      return getStudyYears(universityOrgId, firstYear, 2).then(studyYears => {
        const currentStudyYear = _.find(studyYears, studyYear => dateUtils.rangeContains(today, studyYear.valid));
        if (currentStudyYear) {
          return currentStudyYear;
        } else {
          if (showErrorIfNotFound) {
            errorService.showTranslatedError({
              titleKey: 'COMMON.SYSTEM_ERROR',
              messageKey: 'ERROR.BACKEND.CURRENT_STUDY_YEAR_TEMPLATE_MISSING'
            });
          }
          throw 'Current study year template missing';
        }
      });
    }
    function getCurrentStudyTerm(universityOrgId) {
      return getCurrentStudyYear(universityOrgId).then(function (studyYear) {
        return getCurrentStudyTermOfStudyYear(studyYear);
      });
    }
    function getCurrentStudyTermOfStudyYear(studyYear) {
      const currentMoment = moment();
      return _.find(_.get(studyYear, 'studyTerms', []), term => dateUtils.rangeContains(currentMoment, term.valid));
    }
    function getCurrentUniversityStudyTermIndex(currentStudyYear) {
      var firstStudyTerm = _.first(currentStudyYear.studyTerms);
      var currentTermIsFirstTerm = moment().isBetween(firstStudyTerm.valid.startDate, firstStudyTerm.valid.endDate, null, '[)');
      return currentTermIsFirstTerm ? 0 : 1;
    }
    function extractStudyYearPeriodFromPossibility(possibilityString, studyYearTemplates) {
      var possibility = possibilityString.split('/');
      var org = possibility[0];
      var term = parseInt(possibility[2]);
      var period = parseInt(possibility[3]);
      var template = _.find(studyYearTemplates, ['org', org]);
      var order = period;
      for (var i = 0; i < term; i++) {
        var length = _.get(template, 'studyTerms[' + i + '].studyPeriods.length');
        if (length === undefined) {
          return undefined;
        }
        order = order + length;
      }
      var studyYearPeriod = _.clone(_.get(template, 'studyTerms[' + term + '].studyPeriods[' + period + ']'));
      if (studyYearPeriod) {
        return _.merge(studyYearPeriod, {
          order: order
        });
      } else {
        return undefined;
      }
    }
    function transformRepeatArrayToStudyPeriodRangesArray(repeatPossibility, studyYearTemplates) {
      var reducedTemplatedPossibilities = _(repeatPossibility).map(function (onePossibility) {
        return extractStudyYearPeriodFromPossibility(onePossibility, studyYearTemplates);
      }).sortBy('order').transform(function (result, template) {
        if (!template) {
          return true;
        }
        var lastAddition = result[result.length - 1];
        if (lastAddition && lastAddition.length > 0) {
          var latestTemplate = lastAddition.pop();
          if (template.order - latestTemplate.order === 1) {
            if (lastAddition.length === 0) {
              lastAddition.push(latestTemplate);
            }
            lastAddition.push(template);
            return true;
          } else {
            lastAddition.push(latestTemplate);
          }
        }
        result.push([template]);
        return true;
      }).value();

      //clean obsolete field order
      _(reducedTemplatedPossibilities).flatMap().forEach(function (possibility) {
        _.unset(possibility, 'order');
      });
      return reducedTemplatedPossibilities;
    }
    function getStudyYearTemplates(organisationId, activityPeriod) {
      const params = {
        organisationId
      };
      if (activityPeriod) {
        params.betweenDates = `${activityPeriod.startDate},${activityPeriod.endDate}`;
      }
      return studyYearTemplateJSDataModel.findAll(params);
    }
    function getGeneratedStudyPeriod(date, studyYearTemplate) {
      var result = null;
      var referenceDate = moment(date);
      var templateValidFrom = moment(studyYearTemplate.studyTerms[0].studyPeriods[0].defaultValid.startDate);
      referenceDate.year(templateValidFrom.year());
      if (referenceDate.isBefore(templateValidFrom)) {
        referenceDate.add(1, 'years');
      }
      _.forEach(studyYearTemplate.studyTerms, function (studyTerm) {
        _.forEach(studyTerm.studyPeriods, function (period) {
          var startDate = moment(period.defaultValid.startDate);
          var endDate = moment(period.defaultValid.endDate);
          if (referenceDate.isBetween(startDate, endDate, null, '[)')) {
            result = period;
          }
        });
      });
      return result;
    }
    function getStudyYearTemplateByDate(date, studyYearTemplates) {
      if (studyYearTemplates.length === 0) {
        return null;
      }
      var result = null;
      var referenceDate = moment(date);
      _.forEach(studyYearTemplates, function (template) {
        var startDate = moment(template.valid.startDate);
        var endDate = moment(template.valid.endDate);
        if (referenceDate.isBetween(startDate, endDate, null, '[)')) {
          result = template;
        }
      });
      if (result === null) {
        studyYearTemplates.sort(function (a, b) {
          return moment(b.valid.startDate).isBefore(moment(a.valid.startDate)) ? -1 : 1;
        });
        result = studyYearTemplates[studyYearTemplates.length - 1];
      }
      return result;
    }
    function getStudyPeriodByDate(date, studyYearTemplates) {
      var studyYearTemplate = getStudyYearTemplateByDate(date, studyYearTemplates);
      if (studyYearTemplate === null) {
        return null;
      }
      var result = null;
      var referenceDate = moment(date);
      if (referenceDate.isBetween(moment(studyYearTemplate.valid.startDate), moment(studyYearTemplate.valid.endDate), null, '[)')) {
        _.forEach(studyYearTemplate.studyTerms, function (studyTerm) {
          _.forEach(studyTerm.studyPeriods, function (period) {
            _.forEach(period.overrideValidities, function (overrideValidity) {
              var startDate = moment(overrideValidity.startDate);
              var endDate = moment(overrideValidity.endDate);
              if (referenceDate.isBetween(startDate, endDate, null, '[)')) {
                result = period;
              }
            });
          });
        });
      }
      if (result === null) {
        result = getGeneratedStudyPeriod(date, studyYearTemplate);
      }
      return result;
    }
    function getStudyTerms(universityOrgId, firstYear, numYears, config) {
      return getStudyYears(universityOrgId, firstYear, numYears, config).then(function (studyYears) {
        var studyTerms = [];
        if (studyYears) {
          _.forEach(studyYears, function (studyYear) {
            _.forEach(studyYear.studyTerms, function (studyTerm) {
              studyTerm.key = studyTerm.valid.startDate + '_' + studyTerm.valid.endDate;
              studyTerm.startYear = _.split(studyTerm.valid.startDate, '-')[0];
              studyTerm.studyYearStart = resolveStudyYearStart(studyTerm);
              studyTerm.studyYearEnd = resolveStudyYearEnd(studyTerm);
              studyTerms.push(studyTerm);
            });
          });
          return studyTerms;
        }
      });
    }
    function resolveStudyYearStart(studyTerm) {
      const startYear = studyTerm.valid.startDate.substring(0, 4);
      const endYear = studyTerm.valid.endDate.substring(0, 4);
      const studyYearStart = startYear !== endYear ? Number(startYear) : Number(startYear) - 1;
      return studyYearStart;
    }
    function resolveStudyYearEnd(studyTerm) {
      return resolveStudyYearStart(studyTerm) + 1;
    }
    function getStudyPeriods(universityOrgId, firstYear, numYears, config) {
      return getStudyTerms(universityOrgId, firstYear, numYears, config).then(function (studyTerms) {
        var studyPeriods = [];
        if (studyTerms) {
          _.forEach(studyTerms, function (studyTerm) {
            _.forEach(studyTerm.studyPeriods, function (studyPeriod) {
              studyPeriod.startYear = _.split(studyPeriod.startDate, '-')[0];
              studyPeriods.push(studyPeriod);
            });
          });
          return studyPeriods;
        }
      });
    }

    /**
     * @deprecated Use StudyPeriodLocatorService.parseStudyPeriodLocator.
     */
    function parseStudyPeriodLocator(locatorString) {
      if (!_.isEmpty(locatorString) && _.isString(locatorString)) {
        var match = locatorString.match(/^([^/]+)\/(\d{4})\/(\d)\/(\d)$/);
        return _.isEmpty(match) ? null : {
          organisationId: match[1],
          year: parseInt(match[2]),
          termIndex: parseInt(match[3]),
          periodIndex: parseInt(match[4])
        };
      }
      return null;
    }
    function getStudyPeriodsForSearchFilter() {
      var universityOrgId = universityService.getCurrentUniversityOrgId();
      var today = moment();
      var firstYear = today.year() - 1;
      var todayNextYear = moment().add(1, 'years');
      return getStudyYears(universityOrgId, firstYear, 3).then(function (result) {
        var filteredStudyYears = _.filter(result, function (studyYear) {
          return today.isBetween(studyYear.valid.startDate, studyYear.valid.endDate, 'day', '[)') || todayNextYear.isBetween(studyYear.valid.startDate, studyYear.valid.endDate, 'day', '[)');
        });

        // Cloning studyYears is necessary here, since we are about to manipulate the name properties
        // of the nested studyPeriods and we do not want these changes to be affect the objects in
        // js-data store. Unfortunately the way searchFilters have been implemented requires this kind
        // of manipulation.

        var studyYears = _.cloneDeep(filteredStudyYears);
        var resultStudyPeriods = [];
        _.forEach(studyYears, function (studyYear) {
          var periods = getSearchFilterPeriodsByStudyYear(studyYear, today);
          resultStudyPeriods = _.concat(resultStudyPeriods, periods);
        });
        api.addSpecifiersToDuplicateStudyPeriodNames(resultStudyPeriods);
        return resultStudyPeriods;
      });
    }
    function getSearchFilterPeriodsByStudyYear(studyYear, todayMoment) {
      var allStudyPeriods = _.flatMap(studyYear.studyTerms, 'studyPeriods');
      var resultStudyPeriods = [];
      _.forEach(allStudyPeriods, function (studyPeriod) {
        if (todayMoment.isBefore(studyPeriod.valid.endDate, 'day')) {
          _.forEach(studyPeriod.name, (name, locale) => {
            studyPeriod.name[locale] = `${studyYear.name}: ${localeService.getLocalizedValue(studyPeriod.name, locale)}`;
          });
          resultStudyPeriods.push(studyPeriod);
        }
      });
      return resultStudyPeriods;
    }

    /**
     * Handle duplicate period names. Grouping is done by the assumption, that the Finnish localisation is enough
     * to define when two periods match.
     * @param studyPeriods
     */
    function addSpecifiersToDuplicateStudyPeriodNames(studyPeriods) {
      _(studyPeriods).groupBy(function (studyPeriod) {
        return studyPeriod.name.fi;
      }).pickBy(function (studyPeriodsWithCertainName) {
        return studyPeriodsWithCertainName.length > 1;
      }).values().forEach(function (duplicateStudyPeriods) {
        const firstDuplicateStudyPeriod = _.first(duplicateStudyPeriods);
        const lastDuplicateStudyPeriod = _.last(duplicateStudyPeriods);
        _.forEach(firstDuplicateStudyPeriod.name, (name, locale) => {
          firstDuplicateStudyPeriod.name[locale] += ` ${$translate.instant('STUDY_PERIODS.NAME_SPECIFIER_FIRST', undefined, undefined, locale)}`;
        });
        _.forEach(lastDuplicateStudyPeriod.name, (name, locale) => {
          lastDuplicateStudyPeriod.name[locale] += ` ${$translate.instant('STUDY_PERIODS.NAME_SPECIFIER_LAST', undefined, undefined, locale)}`;
        });
      });
    }
  }
})();