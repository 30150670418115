import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { FeedbackUserGroup } from 'common-typescript/types';

@Injectable({
    providedIn: 'root',
})
export class FeedbackService {

    static ORI_URL = '/ori/api/feedback';

    constructor(@Inject(HttpClient) private http: HttpClient) {}

    public postFeedback(npsValue: number, userGroup: FeedbackUserGroup): void {
        this.http.post(FeedbackService.ORI_URL, { npsValue, userGroup }).subscribe();
    }
}
