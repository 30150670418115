import { Component, ViewEncapsulation } from '@angular/core';
import { StateService } from '@uirouter/core';

@Component({
    selector: 'app-ripa-enrolment-information',
    templateUrl: './ripa-enrolment-information.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class RipaEnrolmentInformationComponent {

    constructor(private stateService: StateService) {}

    returnToFrontPage() {
        this.stateService.go('student.logged-in.frontpage');
    }
}
