<div class="notification-box" *transloco="let t">
  <div class="notification-box__info"
       [class.d-none]="!notificationContent.textContent && !notificationContent.children.length">
    <fudis-icon *ngIf="this.type !== 'none'" [icon]="icon" [color]="iconColor"/>
    <div class="notification-box__info__text"
         [class.notification-box__info__text--alert]="this.type === 'alert'"
         #notificationContent>
      <ng-content></ng-content>
      <sis-button *ngIf="infoClickAction.observed" link="true" (clicked)="infoClickAction.emit()">
        {{t('SIS_COMPONENTS.STUDY.SHOW_INFO')}}
      </sis-button>
    </div>
  </div>
  <div class="notification-box__actions" *ngIf="primaryButtonText || secondaryButtonText">
    <div class="notification-box__actions__buttons">
      <div class="float-right-preserve-tab-order">
        <fudis-button *ngIf="secondaryButtonText" [size]="'small'" [variant]="'secondary'"
                      [label]="t(secondaryButtonText)" (handleClick)="secondaryClickAction.emit()"/>
        <fudis-button *ngIf="primaryButtonText" [size]="'small'" [label]="t(primaryButtonText)"
                      (handleClick)="primaryClickAction.emit()"/>
      </div>
    </div>
  </div>
</div>
