<div *ngIf="module" class="studymodule" [ngClass]="[categoryCssClass ? categoryCssClass : '']">
    <div class="studymodule-top-row">
        <div class="title-box">
            <sis-tooltip [tooltipText]="(module.name | localizedString)" [appendToBody]="true">
                <span class="studymodule-title sis-tooltip-trigger-content">{{module.name | localizedString}}</span>
            </sis-tooltip>
            <span class="studymodule-code"><sis-icon icon="brochure"></sis-icon>{{module.code}}
                <sis-non-active-document-state-badge [documentState]="module.documentState"></sis-non-active-document-state-badge>
            </span>
        </div>
        <div class="extra-items-holder">
            <ng-content select="[module-box=extraitemholder]"></ng-content>
            <div class="grade-box" *ngIf="attainment">
                <sis-icon icon="achievement"></sis-icon>
                <span class="grade-text">{{attainment.gradeId | grade: attainment.gradeScaleId | async }}</span>
            </div>
            <div class="credits-box">
                <span *ngIf="attainment" class="credit-amount">{{{min: attainment.credits, max: attainment.credits} | creditRange:'SYMBOLS':true}}</span>
                <span *ngIf="!attainment && module" class="credit-amount">{{module.targetCredits | creditRange:'SYMBOLS':true}}</span>
                <span class="credit-abbreviation" translate>SIS_COMPONENTS.CREDIT_RANGE.CREDITS_ABBR</span>
            </div>
        </div>
    </div>
    <div class="studymodule-notification-bar">
        <ng-content select="[module-box=notifications]" class="notifications"></ng-content>
    </div>
</div>
