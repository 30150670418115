import { Injectable } from '@angular/core';
import { EntityState, EntityStore, getIDType as ID, QueryEntity, StoreConfig } from '@datorama/akita';
import { HttpConfig, NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { ValidatablePlan } from 'common-typescript/src/plan/validation/validatablePlan';
import {
    ModuleContentApplication,
    OtmId,
    SearchResult,
    StudentApplicationState,
    StudentWorkflow,
    Workflow,
    WorkflowApplication,
    WorkflowCancelSupplementRequestCommentDto,
    WorkflowHandlerChangeCommentDto,
    WorkflowSearchRequest,
} from 'common-typescript/types';
import _ from 'lodash';
import { map, Observable } from 'rxjs';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

import { STUDENT_WORKFLOW_STATE } from '../model/student-workflow-constants';

import { EntityService } from './entity.service';

const CONFIG = {
    ENDPOINTS: {
        backend: '/ori/api',
        get search(): string {
            return `${this.backend}/workflows/search`;
        },
        removeLastHandler(workflowId: OtmId): string {
            return `${this.backend}/workflows/${workflowId}/remove-last-handler`;
        },
        updateLastHandler(workflowId: OtmId): string {
            return `${this.backend}/workflows/${workflowId}/update-last-handler`;
        },
        cancel(workflowId: OtmId): string {
            return `${this.backend}/workflows/${workflowId}/cancel`;
        },
        revoke(workflowId: OtmId): string {
            return `${this.backend}/workflows/revoke/${workflowId}`;
        },
        cancelAsStaff(workflowId: OtmId): string {
            return `${this.backend}/workflows/${workflowId}/cancel/staff`;
        },
        revokeAsStaff(workflowId: OtmId): string {
            return `${this.backend}/workflows/revoke/${workflowId}/staff`;
        },
        cancelSupplementRequest(workflowId: OtmId): string {
            return `${this.backend}/workflows/${workflowId}/cancel-supplement-request`;
        },
        supplementWorkflowApplication(workflowId: OtmId): string {
            return `${this.backend}/workflows/${workflowId}/supplement-application`;
        },
        get getWorkflows(): string {
            return `${this.backend}/workflows`;
        },
        supplementRequest(workflowId: OtmId): string {
            return `${this.backend}/workflows/${workflowId}/supplement-request`;
        },
        createAsStudent(): string {
            return `${this.backend}/workflows/create-as-student`;
        },
    },
};

@StaticMembers<DowngradedService>()
@Injectable({ providedIn: 'root' })
@NgEntityServiceConfig({
    baseUrl: CONFIG.ENDPOINTS.backend,
    resourceName: 'workflows',
})
export class WorkflowEntityService extends EntityService<WorkflowState> {

    static downgrade: ServiceDowngradeMappings = {
        dependencies: [],
        moduleName: 'sis-components.service.workflowEntityService',
        serviceName: 'workflowEntityService',
    };

    readonly studentWorkflowStates = STUDENT_WORKFLOW_STATE;

    constructor() {
        super(WorkflowStore, WorkflowQuery);
    }

    search(searchRequest?: Partial<WorkflowSearchRequest>): Observable<SearchResult<Workflow>> {
        const options = { params: { ...searchRequest } };

        return this.getHttp().get<SearchResult<Workflow>>(CONFIG.ENDPOINTS.search, options);
    }

    removeLastHandler(workflowId: OtmId, workflowHandlerChangeCommentDto: WorkflowHandlerChangeCommentDto): Observable<Workflow> {
        return this.getHttp().put(CONFIG.ENDPOINTS.removeLastHandler(workflowId), workflowHandlerChangeCommentDto) as Observable<Workflow>;
    }

    updateLastHandler(workflowId: OtmId, personId: OtmId, workflowHandlerChangeCommentDto: WorkflowHandlerChangeCommentDto): Observable<Workflow> {
        const options = { params: { personId } };
        return this.getHttp().put<Workflow>(CONFIG.ENDPOINTS.updateLastHandler(workflowId), workflowHandlerChangeCommentDto, options);
    }

    cancel(workflowId: OtmId): Observable<Workflow> {
        return this.getHttp().post<Workflow>(CONFIG.ENDPOINTS.cancel(workflowId), {})
            .pipe(this.upsertAndSwitchToStoreObservable());
    }

    revoke(workflowId: OtmId): Observable<Workflow> {
        return this.getHttp().put<Workflow>(CONFIG.ENDPOINTS.revoke(workflowId), {});
    }

    cancelSupplementRequest(workflowId: OtmId, cancelComment: WorkflowCancelSupplementRequestCommentDto): Observable<Workflow> {
        return this.getHttp().put<Workflow>(CONFIG.ENDPOINTS.cancelSupplementRequest(workflowId), cancelComment);
    }

    cancelAsStaff(workflowId: OtmId, comment: string) {
        return this.getHttp().put<Workflow>(CONFIG.ENDPOINTS.cancelAsStaff(workflowId), { comment });
    }

    revokeAsStaff(workflowId: OtmId, comment: string) {
        return this.getHttp().put<Workflow>(CONFIG.ENDPOINTS.revokeAsStaff(workflowId), { comment });
    }

    supplementWorkflowApplication<T extends WorkflowApplication>(workflowId: OtmId, workflowApplication: Partial<T>): Observable<Workflow> {
        return this.getHttp().post<Workflow>(CONFIG.ENDPOINTS.supplementWorkflowApplication(workflowId), workflowApplication)
            .pipe(this.upsertAndSwitchToStoreObservable());
    }

    getWorkflowsByStudentId(studentId: OtmId): Observable<Workflow[]> {
        const options = { params: { studentId } };
        return this.getHttp().get<Workflow[]>(CONFIG.ENDPOINTS.getWorkflows, options);
    }

    supplementRequest(workflowId: OtmId, supplementRequestReason: string) {
        return this.getHttp().put<Workflow>(CONFIG.ENDPOINTS.supplementRequest(workflowId), { supplementRequestReason });
    }

    getStudentWorkflowsMatchingCustomStudyDraft(studentId: OtmId, customStudyDraftId: OtmId): Observable<Workflow[]> {
        return this.getWorkflowsByStudentId(studentId)
            .pipe(
                map((workflows: Workflow[]) => _.filter(workflows, workflow => _.get(workflow, 'customStudyDraft.id') === customStudyDraftId)),
            );
    }

    isStudentViewableModuleContentWorkflow(moduleContentApplicationState: StudentApplicationState) {
        return _.includes(this.studentWorkflowStates, moduleContentApplicationState);
    }

    isMatchingModuleContentWorkflow(moduleContentApplication: ModuleContentApplication, validatablePlan: ValidatablePlan, moduleId: OtmId) {
        if (moduleContentApplication && validatablePlan) {
            return moduleContentApplication.educationId === validatablePlan.rootModule.id &&
            moduleContentApplication.parentModuleId ===
            validatablePlan.moduleIdSelectionMap[moduleId]?.parentModuleId &&
            moduleContentApplication.approvedModuleId === moduleId;
        }
        return false;
    }

    createAsStudent<T extends WorkflowApplication>(workflowApplication: Partial<T>): Observable<StudentWorkflow> {
        return this.getHttp().post<StudentWorkflow>(CONFIG.ENDPOINTS.createAsStudent(), { ...workflowApplication });
    }
}

type WorkflowState = EntityState<Workflow, OtmId>;

@StoreConfig({ name: 'workflows' })
class WorkflowStore extends EntityStore<WorkflowState> {}

class WorkflowQuery extends QueryEntity<WorkflowState> {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(store: WorkflowStore) {
        super(store);
    }
}
