<h3>{{ 'PROFILE.PAYMENTS.TAB_TITLE' | translate }}</h3>
<div *ngFor="let entry of currentAndFuturePayments | keyvalue: reverseKeyOrder" class="study-year">
  <h4 data-cy="current-and-future-payments-table-header">{{'PROFILE.PAYMENTS.TABLE.STUDY_YEAR' | translate: {years: entry.key | studyYearName} }}</h4>
  <app-study-year-payments [payments]="entry.value" [level]="5"></app-study-year-payments>
</div>
<sis-expandable
  *ngIf="pastPayments.size > 0"
  [title]="'PROFILE.PAYMENTS.PAST_STUDY_YEARS' | translate"
  [level]="4">
  <ng-template sisExpandableContent>
    <div *ngFor="let entry of pastPayments | keyvalue: reverseKeyOrder" class="study-year">
      <h5 class="fudis-heading__md sis-mb-md">{{'PROFILE.PAYMENTS.TABLE.STUDY_YEAR' | translate: {years: entry.key | studyYearName} }}</h5>
      <app-study-year-payments [level]="6" [payments]="entry.value"></app-study-year-payments>
    </div>
  </ng-template>
</sis-expandable>
