<h5 class="fudis-heading__md">{{ 'PROFILE.STUDY_RIGHT_STATE_TITLE' | translate }}</h5>
<div *ngIf="!!activeStatePeriod">{{'PROFILE.STATE.' + activeStatePeriod.state|translate}}</div>

<div *ngIf="studyRightCancellation" class="sis-pb-sm">
  <sis-button class="show-decision-details-button" [dataCy]="'show-decision-details-button'"
              [link]="true" (clicked)="showDecisionDetails()">
    {{'PROFILE.EXTENSIONS.SHOW_DETAILS' | translate }}
  </sis-button>
</div>

<sis-expandable [level]="6" [variant]="'link'" [title]="'PROFILE.SHOW_ALL_STUDY_RIGHT_STATES' | translate"
                data-cy="show-all-states-button">
  <ng-template sisExpandableContent>
    <table class="sis-table styled-table" role="table" data-cy="study-right-states-table" [attr.aria-label]="'PROFILE.STUDY_RIGHT_STATES_PREVIOUS' | translate">
      <thead>
      <tr>
        <th>{{'PROFILE.STARTS' | translate }}</th>
        <th>{{'PROFILE.EXPIRES' | translate }}</th>
        <th>{{'PROFILE.STATE' | translate }}</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let period of statePeriods" class="form-control-static" [class.past-study-rights]="period|dateRangePast">
        <td [class.text-center]="!period.startDate">
          <strong *ngIf="period===activeStatePeriod; else startDate">
            <ng-container *ngTemplateOutlet="startDate"/>
          </strong>
        </td>
        <td [class.text-center]="!period.endDate">
          <strong *ngIf="period===activeStatePeriod; else endDate">
            <ng-container *ngTemplateOutlet="endDate"/>
          </strong>
        </td>
        <td>
          <strong *ngIf="period===activeStatePeriod; else state">
            <ng-container *ngTemplateOutlet="state"/>
          </strong>
        </td>
        <ng-template #startDate>{{(period?.startDate|localDateFormat) || '-'}}</ng-template>
        <ng-template #endDate>{{(getInclusiveEndDate(period)|localDateFormat) || '-'}}</ng-template>
        <ng-template #state>{{'PROFILE.STATE.' + period.state|translate}}</ng-template>
      </tr>
      </tbody>
    </table>
  </ng-template>
</sis-expandable>
