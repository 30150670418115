import { PlanValidationTs, ValidatablePlan } from 'common-typescript';
(function () {
  'use strict';

  PlanContextFactory.$inject = ["$q", "moduleService", "courseUnitService", "attainmentContextBuilder", "moduleModel", "courseUnitModel", "assessmentItemModel", "queueFactory", "targetStates", "cacheUtils", "planPriority", "planLoader", "attainmentBuilder", "PlanMap", "PlanSelection", "$log", "legacyValidationResultsHelperService", "assessmentItemLoader", "studySelectorService", "colorService", "planContextEventType", "$rootScope", "commonStudentApplicationService", "previewMode", "planSelectionService", "commonAttainmentService", "ruleService"];
  angular.module('student.common.context.plan-context').constant('planContextEventType', {
    purge: 'planContext.purge',
    planUpdated: 'planContext.planUpdated',
    planBuilt: 'planContext.planBuilt'
  }).factory('PlanContext', PlanContextFactory);
  function PlanContextFactory(
  // NOSONAR
  $q, moduleService, courseUnitService, attainmentContextBuilder, moduleModel, courseUnitModel, assessmentItemModel, queueFactory, targetStates, cacheUtils, planPriority, planLoader, attainmentBuilder, PlanMap, PlanSelection, $log, legacyValidationResultsHelperService, assessmentItemLoader, studySelectorService, colorService, planContextEventType, $rootScope, commonStudentApplicationService, previewMode, planSelectionService, commonAttainmentService, ruleService) {
    var PLAN_METADATA_PROPERTIES = ['id', 'metadata', 'userId', 'name', 'timelineNotes'];
    function PlanContext() {
      var self = this;
      self.forbidDeletion = forbidDeletion;
      self.setPrimary = setPrimary;
      self.toPlan = toPlan;
      self.build = build;
      self.buildAndUpdate = buildAndUpdate;
      self.updateMetadata = updateMetadata;
      self.planUpdated = planUpdated;
      self.getPlannedModule = getPlannedModule;
      self.getPlannedCourseUnit = getPlannedCourseUnit;
      self.getPlannedCourseUnits = getPlannedCourseUnits;
      self.getModule = getModule;
      self.getModulesByType = getModulesByType;
      self.getCourseUnit = getCourseUnit;
      self.getAssessmentItem = getAssessmentItem;
      self.getAttainmentContext = getAttainmentContext;
      self.lock = lock;
      self.addModule = addModuleIfNotPlanned;
      self.isSelectedModule = isSelectedModule;
      self.isSelectedCourseUnit = isSelectedCourseUnit;
      self.isSelectedCustomCourseUnitAttainment = isSelectedCustomCourseUnitAttainment;
      self.isCourseUnitRemoveAllowed = isCourseUnitRemoveAllowed;
      self.isCourseUnitSelectionDisabled = isCourseUnitSelectionDisabled;
      self.isCustomCourseUnitAttainmentSelectionDisabled = isCustomCourseUnitAttainmentSelectionDisabled;
      self.isModuleSelectionDisabled = isModuleSelectionDisabled;
      self.selectModule = selectModule;
      self.selectModuleByGroupId = selectModuleByGroupId;
      self.removeModule = removeModule;
      self.forceRemoveModule = forceRemoveModule;
      self.removeCourseUnit = removeCourseUnit;
      self.selectCourseUnit = selectCourseUnit;
      self.selectCourseUnitByGroupId = selectCourseUnitByGroupId;
      self.unSelectCourseUnit = unSelectCourseUnit;
      self.getCourseUnitTargets = getCourseUnitTargets;
      self.getCustomCourseUnitAttainmentTargets = getCustomCourseUnitAttainmentTargets;
      self.getCourseUnits = getCourseUnits;
      self.getCourseUnitOfGroup = getCourseUnitOfGroup;
      self.getModuleOfGroup = getModuleOfGroup;
      self.getCustomModuleAttainment = getCustomModuleAttainment;
      self.getCustomCourseUnitAttainment = getCustomCourseUnitAttainment;
      self.canImplicitlySelectedCourseUnitBeMovedToTargetModule = canImplicitlySelectedCourseUnitBeMovedToTargetModule;
      self.moveCourseUnit = moveCourseUnit;
      self.getPlanValidationResult = getPlanValidationResult;
      self.getRuleValidationResult = getRuleValidationResult;
      self.forcePlanReloadUpdate = forcePlanReloadUpdate;
      self.selectCompletionMethodWithAssessmentItems = selectCompletionMethodWithAssessmentItems;
      self.removeGradeRaiseAttempt = removeGradeRaiseAttempt;
      function setPrimary(status) {
        self.primary = status;
      }
      function forbidDeletion() {
        self.deleteNotAllowed = true;
      }
      var planBuildQueue = queueFactory();
      var selections = new PlanSelection();
      var attainmentContext;
      var modulesById = new PlanMap('id');
      var assessmentItemsById = new PlanMap();
      var courseUnitsById = new PlanMap();
      var customModuleAttainmentsById = new PlanMap('id');
      var customCourseUnitAttainmentsById = new PlanMap('id');
      function build(data, reloadApplications) {
        if (!data) {
          throw 'no data';
        }
        return buildFromData(data, reloadApplications);
      }
      function forcePlanReloadUpdate() {
        return buildAndUpdate(self.validatablePlan.plan, true);
      }
      function buildAndUpdate(data, reloadApplications) {
        return build(data, reloadApplications).then(planUpdated);
      }
      function toPlan() {
        return planBuildQueue.execute(toRawPlan);
      }
      function getPlannedModule(groupId) {
        return _.find(_.values(_.get(self, 'validatablePlan.modulesById')), {
          groupId: groupId
        });
      }
      function getPlannedCourseUnit(groupId) {
        return _.find(_.values(_.get(self, 'validatablePlan.courseUnitsById')), {
          groupId: groupId
        });
      }
      function getPlannedCourseUnits() {
        const validatablePlan = _.get(self, 'validatablePlan');
        return _.chain(_.get(validatablePlan, 'plan.courseUnitSelections')).map('id').map(id => validatablePlan.getCourseUnit(id)).value();
      }
      function getCourseUnits() {
        return courseUnitsById.collection;
      }
      function getPlanValidationResult() {
        return self.planValidationResult;
      }
      function getRuleValidationResult(parentModuleId, ruleId) {
        const ruleValidationResults = _.get(self, 'planValidationResult.ruleValidationResults');
        return _.get(_.get(ruleValidationResults, parentModuleId), ruleId);
      }

      // selectModule function is meant to be used when the module to be selected is known exactly.
      // it will check whether the module can be added to plan but it will not consider if any
      // other version is more suitable. for that purpose selectModuleByGroupId should be used

      function selectModule(parentModule, moduleToBeSelected) {
        return planBuildQueue.execute(self, () => {
          const rawPlan = _.cloneDeep(self.validatablePlan.plan);
          return planSelectionService.selectModule(moduleToBeSelected, parentModule, rawPlan, self.validatablePlan, self.studyRight).then(function (updatedPlan) {
            if (updatedPlan) {
              return buildAndUpdate(updatedPlan);
            }
            return $q.when(self);
          });
        });
      }
      function selectModuleByGroupId(parentModule, moduleGroupId) {
        if (!parentModule || !moduleGroupId) {
          return $q.when();
        }
        return planBuildQueue.execute(self, () => {
          return planSelectionService.selectModuleByGroupId(moduleGroupId, parentModule, self.validatablePlan.plan, self.validatablePlan).then(function (updatedPlan) {
            if (updatedPlan) {
              return buildAndUpdate(updatedPlan);
            }
            return $q.when(self);
          });
        });
      }
      function unSelectCourseUnit(module, courseUnit) {
        return planBuildQueue.execute(self, () => {
          const updatedPlan = planSelectionService.unselectCourseUnit(courseUnit, module, self.validatablePlan.plan, self.validatablePlan);
          if (updatedPlan) {
            return buildAndUpdate(updatedPlan);
          }
          return $q.when(self);
        });
      }

      // selectCourseUnit function is meant to be used when the courseUnit to be selected is known exactly.
      // it will check whether the courseUnit can be added to plan but it will not consider if any
      // other version is more suitable. for that purpose selectCourseUnitByGroupId should be used

      function selectCourseUnit(module, courseUnit) {
        return planBuildQueue.execute(self, () => {
          return planSelectionService.selectCourseUnit(courseUnit, module, self.validatablePlan.plan, self.validatablePlan).then(function (updatedPlan) {
            if (updatedPlan) {
              return buildAndUpdate(updatedPlan);
            }
            return $q.when(self);
          });
        });
      }
      function selectCompletionMethodWithAssessmentItems(courseUnitId, completionMethod, assessmentItemSelections, gradeRaiseAttempt) {
        return planBuildQueue.execute(self, () => {
          const updatedPlan = planSelectionService.selectCompletionMethodWithAssessmentItems(courseUnitId, completionMethod, assessmentItemSelections, self.validatablePlan.plan, gradeRaiseAttempt);
          if (updatedPlan) {
            return buildAndUpdate(updatedPlan);
          }
          return $q.when(self);
        });
      }
      function removeGradeRaiseAttempt(courseUnitId) {
        return planBuildQueue.execute(self, () => {
          const updatedPlan = planSelectionService.removeGradeRaiseAttempt(courseUnitId, self.validatablePlan.plan);
          if (updatedPlan) {
            return buildAndUpdate(updatedPlan);
          }
          return $q.when(self);
        });
      }
      function selectCourseUnitByGroupId(module, courseUnitGroupId) {
        if (!module || !courseUnitGroupId) {
          return $q.when();
        }
        return planBuildQueue.execute(self, () => {
          return planSelectionService.selectCourseUnitByGroupId(courseUnitGroupId, module, self.validatablePlan.plan, self.validatablePlan).then(function (updatedPlan) {
            if (updatedPlan) {
              return buildAndUpdate(updatedPlan);
            }
            return $q.when(self);
          });
        });
      }

      // moveCourseUnit() function is intended for moving implicitly selected courseUnit to
      // targetModule explicitly. it is assumed, that it has been checked beforehand that
      // this is allowed

      function moveCourseUnit(courseUnit, targetModule) {
        if (!targetModule || !courseUnit) {
          return $q.when();
        }
        return planBuildQueue.execute(self, () => {
          const updatedPlan = planSelectionService.moveCourseUnit(courseUnit, targetModule, self.validatablePlan);
          if (updatedPlan) {
            return buildAndUpdate(updatedPlan);
          }
          return $q.when(self);
        });
      }
      function removeModule(module) {
        return planBuildQueue.execute(self, function () {
          var updatedPlan = planSelectionService.unselectModule(module, self.validatablePlan.plan, self.validatablePlan);
          if (updatedPlan) {
            return buildAndUpdate(updatedPlan);
          }
          return $q.when(self);
        });
      }
      function forceRemoveModule(module, parentModule) {
        return planBuildQueue.execute(self, function () {
          var updatedPlan = planSelectionService.forceRemoveModule(module, parentModule, self.validatablePlan.plan, self.validatablePlan);
          return buildAndUpdate(updatedPlan);
        });
      }
      function removeCourseUnit(courseUnit) {
        return planBuildQueue.execute(function () {
          getCourseUnit(courseUnit.id).detach();
          removeCourseUnitFromMaps(courseUnit);
          return planUpdated();
        });
      }
      function getModuleOfGroup(groupId, parentCurriculumPeriodIds) {
        if (!parentCurriculumPeriodIds || parentCurriculumPeriodIds.length < 1) {
          parentCurriculumPeriodIds = [self.curriculumPeriodId];
        }

        // If we have an attainment for given groupId, use it *always*
        var attainment = attainmentContext.getModuleAttainmentByGroupId(groupId);
        if (attainment) {
          var moduleInCache = getModule(attainment.moduleId);
          if (moduleInCache) {
            return $q.when([moduleInCache]);
          }
          return moduleService.findById(attainment.moduleId).then(function (module) {
            return [module];
          });
        }
        return moduleService.findByGroupId(groupId, modulesById.collection).then(function (modules) {
          return studySelectorService.selectStudyForPlan(modules, parentCurriculumPeriodIds, self.curriculumPeriodId);
        });
      }
      function getCourseUnitOfGroup(groupId, parentCurriculumPeriodIds) {
        if (!parentCurriculumPeriodIds || parentCurriculumPeriodIds.length < 1) {
          $log.error('getCourseUnitOfGroup: invalid parentCurriculumPeriodIds ', parentCurriculumPeriodIds, ' for studies of group ', groupId);
          return $q.when([]);
        }

        // If we have an attainment for given groupId, use it *always*
        var attainment = attainmentContext.getCourseUnitAttainmentByGroupId(groupId);
        if (attainment) {
          var cuInCache = courseUnitsById.get(attainment.courseUnitId);
          if (cuInCache) {
            return $q.when([cuInCache]);
          }
          return courseUnitService.findById(attainment.courseUnitId).then(function (cu) {
            return [cu];
          });
        }
        return courseUnitService.findByGroupId(groupId, courseUnitsById.collection).then(function (courseUnits) {
          return studySelectorService.selectStudyForPlan(courseUnits, parentCurriculumPeriodIds, self.curriculumPeriodId);
        });
      }
      function isSelectedModule(parent, child) {
        parent = getModule(parent.id);
        return parent && !!parent.selectedModulesById[child.id];
      }
      function isSelectedCourseUnit(module, courseUnit) {
        module = getModule(module.id);
        return module && !!module.selectedCourseUnitsById[courseUnit.id];
      }
      function isSelectedCustomCourseUnitAttainment(module, customCourseUnitAttainment) {
        module = getModule(module.id);
        return module && _.includes(module.selectedCustomCourseUnitAttainments, customCourseUnitAttainment);
      }
      function isCourseUnitRemoveAllowed(courseUnit, targetParent) {
        targetParent = getModule(targetParent.id);
        const currentParent = self.validatablePlan.getParentModuleOrCustomModuleAttainmentForCourseUnit(courseUnit);
        if (_.get(currentParent, 'id') !== _.get(targetParent, 'id')) {
          // Cannot remove if already selected elsewhere
          return false;
        }
        if (self.validatablePlan.isModuleAttained(targetParent.id)) {
          return false;
        }
        courseUnit = getCourseUnit(courseUnit.id, null);
        if (courseUnit) {
          if (courseUnit.locked) {
            return false;
          }
          if (currentParent) {
            return !ruleService.isRequiredCourseUnit(currentParent, courseUnit);
          }
        }
        return true;
      }
      function isCourseUnitSelectionDisabled(courseUnit, targetParent) {
        targetParent = getModule(targetParent.id);
        if (self.validatablePlan.isModuleAttained(targetParent.id)) {
          return true;
        }
        const currentParent = self.validatablePlan.getParentModuleOrCustomModuleAttainmentForCourseUnit(courseUnit);
        if (currentParent && (currentParent.type === 'CustomModuleAttainment' || self.validatablePlan.isModuleAttained(currentParent.id))) {
          return true;
        }
        courseUnit = getCourseUnit(courseUnit.id, null);
        if (courseUnit) {
          if (courseUnit.locked) {
            return true;
          }
          if (self.validatablePlan.isCourseUnitInPlanAsSubstitute(courseUnit)) {
            return true;
          }
          if (currentParent) {
            return ruleService.isRequiredCourseUnit(currentParent, courseUnit);
          }
        }
        return false;
      }
      function canImplicitlySelectedCourseUnitBeMovedToTargetModule(courseUnit, targetModule) {
        if (doesCourseUnitHaveAttachedAttainment(courseUnit) || self.validatablePlan.isCourseUnitInPlanAsSubstitute(courseUnit)) {
          return false;
        }
        if (planSelectionService.isImplicitCourseUnitObligatory(courseUnit, targetModule)) {
          return true;
        }
        const currentParentModule = self.validatablePlan.getParentModuleOrCustomModuleAttainmentForCourseUnit(courseUnit);
        if (currentParentModule && !planSelectionService.isImplicitCourseUnitObligatory(courseUnit, currentParentModule)) {
          return true;
        }
        return false;
      }
      function doesCourseUnitHaveAttachedAttainment(courseUnit) {
        const courseUnitAttainment = self.validatablePlan.getCourseUnitAttainment(_.get(courseUnit, 'id'));
        if (!courseUnitAttainment) {
          return false;
        }
        const allAttainments = _.values(self.validatablePlan.getAllAttainments());
        return commonAttainmentService.isAttached(courseUnitAttainment, allAttainments);
      }
      function isCustomCourseUnitAttainmentSelectionDisabled(targetParent) {
        return self.validatablePlan.isModuleAttained(targetParent.id);
      }
      function isModuleSelectionDisabled(module, targetParent) {
        targetParent = getModule(targetParent.id);
        if (self.validatablePlan.isModuleAttained(targetParent.id)) {
          return true;
        }
        const currentParent = self.validatablePlan.getParentModuleOrCustomModuleAttainmentForModule(module);
        if (currentParent && (currentParent.type === 'CustomModuleAttainment' || self.validatablePlan.isModuleAttained(currentParent.id))) {
          return true;
        }
        module = getModule(module.id, null);
        if (module) {
          // If the selected module is already in the plan
          if (module.locked) {
            return true;
          }
          if (_.some(targetParent.ancestors(), {
            'id': module.id
          })) {
            // Cannot select an ancestor
            return true;
          }
          if (targetParent.id === module.id) {
            return true;
          }
          if (currentParent) {
            return ruleService.isRequiredModule(currentParent, module);
          }
        }
        return false;
      }
      function getModule(id, ifNotFound) {
        return modulesById.get(id, ifNotFound);
      }
      function getModulesByType(type) {
        return modulesById.getBy('type', type);
      }
      function getCourseUnit(id, ifNotFound) {
        if (arguments.length === 2) {
          return courseUnitsById.get(id, ifNotFound);
        }
        return courseUnitsById.get(id);
      }
      function getAttainmentContext() {
        return attainmentContext;
      }
      function getAssessmentItem(id, ifNotFound) {
        if (arguments.length === 2) {
          return assessmentItemsById.get(id, ifNotFound);
        }
        return assessmentItemsById.get(id);
      }
      function toRawPlan() {
        selections.clear();
        const plan = _.pick(self, PLAN_METADATA_PROPERTIES);
        const cleanedPlan = _.get(self, 'validatablePlan.plan');
        selections.addModuleSelection(self.root);
        selections.addCustomCourseUnitAttainmentSelections(self.customCourseUnitAttainmentSelections);
        selections.addCustomModuleAttainmentSelections(self.customModuleAttainmentSelections);
        plan.rootId = self.root.id;
        plan.learningOpportunityId = self.learningOpportunityId;
        plan.deleteNotAllowed = self.deleteNotAllowed;
        plan.primary = self.primary;
        plan.curriculumPeriodId = self.curriculumPeriodId;
        if (cleanedPlan) {
          plan.moduleSelections = cleanedPlan.moduleSelections || [];
          plan.customStudyDrafts = cleanedPlan.customStudyDrafts || [];
          plan.courseUnitSelections = cleanedPlan.courseUnitSelections || [];
          plan.customCourseUnitAttainmentSelections = cleanedPlan.customCourseUnitAttainmentSelections || [];
          plan.customModuleAttainmentSelections = cleanedPlan.customModuleAttainmentSelections || [];
          plan.assessmentItemSelections = cleanedPlan.assessmentItemSelections || [];
          plan.timelineNotes = cleanedPlan.timelineNotes || [];
        } else {
          plan.moduleSelections = _.values(selections.moduleSelections);
          plan.customStudyDrafts = self.customStudyDrafts;
          if (!_.isEmpty(selections.courseUnitSelections)) {
            plan.courseUnitSelections = _.values(selections.courseUnitSelections);
          }
          if (!_.isEmpty(selections.customCourseUnitAttainmentSelections)) {
            plan.customCourseUnitAttainmentSelections = _.values(selections.customCourseUnitAttainmentSelections);
          }
          if (!_.isEmpty(selections.customModuleAttainmentSelections)) {
            plan.customModuleAttainmentSelections = _.values(selections.customModuleAttainmentSelections);
          }
          if (!_.isEmpty(selections.assessmentItemSelections)) {
            plan.assessmentItemSelections = _.values(selections.assessmentItemSelections);
          }
          if (!plan.timelineNotes) {
            plan.timelineNotes = [];
          }
        }
        return plan;
      }
      function planUpdated() {
        const promise = self.root ? validate(toRawPlan()) : $q.when();
        return promise.then(() => {
          $rootScope.$broadcast(planContextEventType.planUpdated);
          return $q.when(self);
        });
      }
      function validate(rawPlan) {
        return findAllModuleContentApprovals().then(moduleContentApprovals => {
          self.validatablePlan = new ValidatablePlan(rawPlan, _.values(self.getAttainmentContext().getAttainments()), self.root, _.values(modulesById.collection), _.values(courseUnitsById.collection), _.values(assessmentItemsById.collection), moduleContentApprovals, self.studyRight);
          legacyValidationResultsHelperService.sortAndAssignSelections(self.validatablePlan);
          self.planValidationResult = PlanValidationTs.validatePlan(self.validatablePlan);
          legacyValidationResultsHelperService.insertValidationResultsIntoPlanObjects(self.validatablePlan, self.planValidationResult);
          self.validationResults = self.planValidationResult.rootValidationResult;
          colorService.clearColorCaches();
        });
      }
      function updateMetadata(plan) {
        _.assign(self, _.pick(plan, PLAN_METADATA_PROPERTIES));
      }
      function findAllModuleContentApprovals(reloadApplications) {
        return previewMode.isPreviewMode() ? $q.when([]) : commonStudentApplicationService.getMyEffectiveApplicationsByTypes(['CustomModuleContentApplication', 'RequiredModuleContentApplication'], reloadApplications);
      }
      function buildFromData(plan, reloadApplications) {
        self.validationResults = null;
        _.assign(self, _.pick(plan, PLAN_METADATA_PROPERTIES));
        return findAllModuleContentApprovals(reloadApplications).then(function (moduleContentApplications) {
          self.moduleContentApplications = moduleContentApplications;
          return attainmentContextBuilder.buildContext().then(function (_attainmentContext_) {
            attainmentContext = _attainmentContext_;
            var idMap = {
              modules: modulesById.collection,
              courseUnits: courseUnitsById.collection,
              assessmentItems: assessmentItemsById.collection
            };
            return planLoader.loadStudyReferenceData(plan, attainmentContext, idMap).then(function (resolved) {
              addModules(resolved.modules, planPriority.firstPriority());
              addCourseUnits(resolved.courseUnits, planPriority.firstPriority());
              addAssessmentItems(resolved.assessmentItems, planPriority.firstPriority());
              setSelectedParents(_.filter(plan.moduleSelections, function (o) {
                return o.moduleId !== plan.rootId;
              }));
            });
          }).then(function () {
            $rootScope.$broadcast(planContextEventType.purge);
            return buildPlan(plan);
          });
        });
      }
      function buildPlan(plan) {
        self.root = getModule(plan.rootId);
        if (self.root) {
          self.validatablePlan = new ValidatablePlan(plan, _.values(self.getAttainmentContext().getAttainments()), self.root, _.values(modulesById.collection), _.values(courseUnitsById.collection), _.values(assessmentItemsById.collection), previewMode.isPreviewMode() ? [] : self.moduleContentApplications, self.studyRight);
        }
        var cleanedPlan = _.get(self, 'validatablePlan.plan', plan);
        clearSelections(modulesById.collection);
        clearSelections(courseUnitsById.collection);
        attainmentBuilder.connect(self);
        connectModules(cleanedPlan);
        connectCourseUnits(cleanedPlan);
        connectAssessmentItems(cleanedPlan);
        self.primary = cleanedPlan.primary;
        self.learningOpportunityId = cleanedPlan.learningOpportunityId;
        self.curriculumPeriodId = cleanedPlan.curriculumPeriodId;
        self.customCourseUnitAttainmentSelections = cleanedPlan.customCourseUnitAttainmentSelections;
        self.customModuleAttainmentSelections = cleanedPlan.customModuleAttainmentSelections;
        self.customStudyDrafts = cleanedPlan.customStudyDrafts;
        if (self.root) {
          legacyValidationResultsHelperService.sortAndAssignSelections(self.validatablePlan);
          self.planValidationResult = PlanValidationTs.validatePlan(self.validatablePlan);
          legacyValidationResultsHelperService.insertValidationResultsIntoPlanObjects(self.validatablePlan, self.planValidationResult);
          self.validationResults = self.planValidationResult.rootValidationResult;
          colorService.clearColorCaches();
        }
        $rootScope.$broadcast(planContextEventType.planBuilt);
        return self;
      }
      function connectAssessmentItems(plan) {
        _.forEach(plan.assessmentItemSelections, function (assessmentItemSelection) {
          var assessmentItem = addAssessmentItem(getAssessmentItem(assessmentItemSelection.assessmentItemId), planPriority.firstPriority());
          if (assessmentItem) {
            var parent = getCourseUnit(assessmentItemSelection.courseUnitId);
            if (parent) {
              parent.addSelectedAssessmentItem(assessmentItem);
            } else {
              $log.info("Failed to get courseUnit for assessmentItemSelection", assessmentItemSelection);
            }
          }
        });
      }
      function connectCourseUnits(plan) {
        _.each(plan.courseUnitSelections, function (courseUnitSelection) {
          var courseUnit = getCourseUnit(courseUnitSelection.courseUnitId);
          if (courseUnitSelection.plannedPeriods) {
            courseUnit.plannedPeriods = courseUnitSelection.plannedPeriods;
          }
          var added = addCourseUnitIfNotPlanned(courseUnit, planPriority.firstPriority());
          if (added) {
            var parent = getModule(courseUnitSelection.parentModuleId);
            if (!parent) {
              $log.info("Failed to get parentModule for courseUnitSelection", courseUnitSelection);
            }
            if (!_.get(parent, 'attainment')) {
              if (courseUnitSelection.parentModuleId) {
                parent.addSelectedCourseUnit(courseUnit);
              }
              if (courseUnitSelection.completionMethodId) {
                added.selectCompletionMethod(courseUnitSelection.completionMethodId);
              }
              if (!_.isEmpty(courseUnitSelection.substitutedBy)) {
                var substitutes = _.map(courseUnitSelection.substitutedBy, getCourseUnit);
                added.substituteWith(substitutes);
              }
            } else {
              $log.debug('Do not add courseUnitSelection to attained parent', courseUnitSelection, 'parent:', parent);
            }
          }
        });
      }
      function connectModules(plan) {
        _.forEach(plan.moduleSelections, function (moduleSelection) {
          var module = addModuleIfNotPlanned(getModule(moduleSelection.moduleId), planPriority.firstPriority());
          if (module && module.moduleId !== plan.rootId && moduleSelection.parentModuleId) {
            var parent = getModule(moduleSelection.parentModuleId);
            if (!parent) {
              $log.info("Failed to get parentModule for moduleSelection", moduleSelection);
            }
            if (!_.get(parent, 'attainment')) {
              parent.addSelectedModule(module);
            } else {
              $log.info('Do not add moduleSelections to attained parent', moduleSelection, 'parent:', parent);
            }
          }
        });
      }
      function lock(study, index) {
        study.locked = true;
        study._index = index;
      }
      function addModules(modules, priority) {
        _.each(modules, _.partial(addOrMaybeReplaceModule, _, priority));
      }
      function addOrMaybeReplaceModule(module, selectionPriority) {
        if (!(module instanceof moduleModel.Module)) {
          throw 'Expected moduleModel.Module';
        }
        var oldModule = getPlannedModule(module.groupId);
        if (oldModule) {
          module = getReplacement(oldModule, module, selectionPriority);
          if (!module) {
            return null;
          }
          if (module === oldModule) {
            return oldModule;
          }
          replaceModule(oldModule, module);
        }
        module._priority = selectionPriority;
        modulesById.set(module);
        return module;
      }
      function replaceModule(oldModule, newModule) {
        oldModule.detach();
        if (self.root === oldModule) {
          self.root = newModule;
        }
      }
      function addModuleIfNotPlanned(module, selectionPriority) {
        if (!(module instanceof moduleModel.Module)) {
          throw 'Expected moduleModel.Module';
        }
        modulesById.set(module);
        var plannedModule = getPlannedModule(module.groupId);
        if (plannedModule) {
          return plannedModule;
        }
        module._priority = selectionPriority;
        return module;
      }
      function setSelectedParents(moduleSelections) {
        _.each(moduleSelections, function (moduleSelection) {
          var module = getModule(moduleSelection.moduleId);
          if (module) {
            module.selectedParent = getModule(moduleSelection.parentModuleId);
          }
        });
      }
      function addCourseUnits(courseUnitsList, priority) {
        _.each(courseUnitsList, _.partial(addCourseUnitIfNotPlanned, _, priority));
      }
      function addCourseUnitIfNotPlanned(courseUnit, selectionPriority) {
        if (!(courseUnit instanceof courseUnitModel.CourseUnit)) {
          throw 'Expected courseUnitModel.CourseUnit';
        }
        courseUnitsById.set(courseUnit);
        var plannedCourseUnit = getPlannedCourseUnit(courseUnit.groupId);
        if (plannedCourseUnit) {
          return plannedCourseUnit;
        }
        courseUnit._priority = selectionPriority;
        return courseUnit;
      }
      function addAssessmentItems(assessmentItemList, priority) {
        _.each(assessmentItemList, _.partial(addAssessmentItem, _, priority));
      }
      function addAssessmentItem(assessmentItem, selectionPriority) {
        if (!(assessmentItem instanceof assessmentItemModel.AssessmentItem)) {
          throw 'Expected assessmentItemModel.AssessmentItem';
        }
        var oldAssessmentItem = assessmentItemsById.get(assessmentItem.id, false);
        if (oldAssessmentItem) {
          assessmentItem = getReplacement(oldAssessmentItem, assessmentItem, selectionPriority);
          if (!assessmentItem) {
            return null;
          }
          if (assessmentItem === oldAssessmentItem) {
            return oldAssessmentItem;
          }
        }
        assessmentItem._priority = selectionPriority;
        assessmentItemsById.set(assessmentItem);
        return assessmentItem;
      }
      function getReplacement(prev, next, nextPriority) {
        if (prev.attainment) {
          return prev.locked ? null : prev; // Is prev locked?
        }
        if (next.attainment) {
          return next;
        }
        return planPriority.isHigherPriorityThan(nextPriority, prev._priority) ? next : null;
      }

      /**
       * Returns a map {moduleId: {is_target:true|false, descendant_is_target:true|false}}
       * @param courseUnits|Array
       */
      function getCourseUnitTargets(courseUnits) {
        return targetStates.get(this, _.values(modulesById.collection), courseUnits);
      }
      function getCustomCourseUnitAttainmentTargets() {
        return targetStates.getCustomCourseUnitAttainmentTargets(this, _.values(modulesById.collection));
      }
      function getCustomModuleAttainment(customModuleAttainment) {
        var module = customModuleAttainmentsById.get(customModuleAttainment.id) || undefined;
        if (!module) {
          module = createStudyFromAttainment(moduleModel.newModule({
            id: customModuleAttainment.id,
            groupId: customModuleAttainment.id
          }), customModuleAttainment);
          customModuleAttainmentsById.set(module);
        }
        return module;
      }
      function getCustomCourseUnitAttainment(customCourseUnitAttainment) {
        var courseUnit = customCourseUnitAttainmentsById.get(customCourseUnitAttainment.id) || undefined;
        if (!courseUnit) {
          courseUnit = createStudyFromAttainment(courseUnitModel.newCourseUnit({
            id: customCourseUnitAttainment.id,
            groupId: customCourseUnitAttainment.id
          }), customCourseUnitAttainment);
          customCourseUnitAttainmentsById.set(courseUnit);
        }
        return courseUnit;
      }
      function createStudyFromAttainment(study, customAttainment) {
        study.code = customAttainment.code;
        study.name = customAttainment.name;
        study.credits = customAttainment.credits;
        study.attainment = customAttainment;
        study.type = customAttainment.type;
        return study;
      }
      function removeCourseUnitFromMaps(courseUnit) {
        if (!courseUnit.attainment) {
          _.unset(courseUnitsById.collection, courseUnit.id);
        }
      }
    }
    function clearSelections(selectionCollection) {
      _.forEach(selectionCollection, function (selection) {
        selection.clearSelections();
      });
    }
    return PlanContext;
  }
})();