import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { LocalId } from 'common-typescript/types';

import { RuleError, RuleErrorState } from '../rule-error-state.service';

@Component({
    selector: 'sis-plan-structure-rule-error-summary',
    templateUrl: './plan-structure-rule-error-summary.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanStructureRuleErrorSummaryComponent {

    @Input({ required: true }) ruleErrorStates: RuleErrorState[] = [];
    @Output() errorClick: EventEmitter<[RuleErrorState, RuleError]> = new EventEmitter();

    trackByRuleLocalId(index: number, ruleErrorState: RuleErrorState): LocalId {
        return ruleErrorState.ruleLocalId;
    }

    trackByErrorId(index: number, error: RuleError): string {
        return error.errorId;
    }
}
