import { Pipe, PipeTransform } from '@angular/core';
import { Code } from 'common-typescript/types';
import { LocaleService } from 'sis-common/l10n/locale.service';

import { CommonCodeService } from '../service/common-code.service';

@Pipe({
    name: 'codeLocalizedName',
})
export class CodeLocalizedNamePipe implements PipeTransform {
    constructor(
        private commonCodeService: CommonCodeService,
        private localeService: LocaleService,
    ) {
    }

    transform(urn: string): Promise<string> {
        if (!urn) {
            return Promise.resolve(undefined);
        }
        return this.commonCodeService.getCode(urn)
            .then((code: Code) => this.localeService.localize(code?.name));
    }
}
