<div class="container sis-pt-md" *ngIf="openUniversityCart">
  <div class="sis-my-md" *ngIf="isSuomiFiPath">
    <sis-contextual-notification variant="light">
      <span tabindex="-1" #currentStepNameFocus>
        {{ 'OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.SUOMI_FI.LOGOUT_BUTTON_TEXT' | translate }}
      </span>
      <sis-button [link]="true" (clicked)="suomiFiLogout()">
        {{ 'OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.SUOMI_FI.LOGOUT_BUTTON' | translate }}
      </sis-button>
    </sis-contextual-notification>
  </div>
  <app-open-university-studies-activate-wizard-step1 *ngIf="currentStep === 0"
                                                     [openUniversityCart]="openUniversityCart"
                                                     [wizardProgressStepKeys]="wizardProgressStepKeys"
                                                     [courseUnits]="courseUnits"/>
  <app-open-university-studies-activate-wizard-step2 *ngIf="currentStep === 1"
                                                     [openUniversityCart]="openUniversityCart"
                                                     [wizardProgressStepKeys]="wizardProgressStepKeys"
                                                     [isSuomiFiPath]="isSuomiFiPath"
                                                     [suomiFiUserBasicDetails]="suomiFiUserBasicDetails"
                                                     (goToLastStep)="goToLastStep($event)"/>
  <app-open-university-studies-activate-wizard-step3 *ngIf="currentStep === 2 && openUniversityProducts.length > 0"
                                                     [openUniversityCart]="openUniversityCart"
                                                     [wizardProgressStepKeys]="wizardProgressStepKeys"
                                                     [openUniversityProducts]="openUniversityProducts"
                                                     [courseUnits]="courseUnits"
                                                     [assessmentItemsData]="assessmentItemsData"
                                                     [isSuomiFiPath]="isSuomiFiPath"
                                                     [isStaffUser]="isStaffUser"
                                                     (logoutFromSuomiFi)="suomiFiLogout()"/>
</div>
<app-open-university-studies-activate-error *ngIf="!openUniversityCart"/>
