import { APP_INITIALIZER } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { LocalStorageService } from '../storage/local-storage.service';

/**
 * TODO: Angular 12 supports returning Observable instead of Promise. Would be cleaner to use that here.
 */
function setInitialLanguage(localStorage: LocalStorageService, translate: TranslateService) {
    return () => new Promise((resolve) => {
        const selectedLanguage = localStorage.getItem('selected_language') || 'fi';
        translate.use(selectedLanguage)
            .pipe(catchError((error) => throwError(() => error)))
            .subscribe(() => resolve(selectedLanguage));
    });
}

export const initialLanguageProvider = ({
    provide: APP_INITIALIZER,
    useFactory: setInitialLanguage, // Makes sure that initial language is loaded before app is initialized
    deps: [LocalStorageService, TranslateService],
    multi: true,
});
