import { ChangeDetectionStrategy, Component, Inject, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from 'sis-common/modal/modal.service';

export interface OpenUniversityErrorDialogValues {
    title: string;
    descriptions?: string[];
    orderNumberMsg?: string,
    contactOrganizer?: {
        msg: string;
        link: string;
    },
    completePaymentMsgAnchorElement?: string;
}

@Component({
    selector: 'app-open-university-error-dialog',
    templateUrl: './open-university-error-dialog.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpenUniversityErrorDialogComponent {

    constructor(@Inject(ModalService.injectionToken) public values: OpenUniversityErrorDialogValues,
                public modalInstance: NgbActiveModal) {}

    removeMailtoFromName(link: string): string {
        const mailto = 'mailto:';
        return link.includes(mailto) ? link.replace(mailto, '') : link;
    }

}
