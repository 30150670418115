import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { LocaleService } from 'sis-common/l10n/locale.service';
import {
    DowngradedService,
    ServiceDowngradeMappings,
    StaticMembers,
} from 'sis-common/types/angular-hybrid';

@StaticMembers<DowngradedService>()
@Injectable({ providedIn: 'root' })
export class MarkupService {

    static downgrade: ServiceDowngradeMappings = {
        moduleName: 'sis-components.string.markupService',
        serviceName: 'markupService',
    };

    constructor(
        private localeService: LocaleService,
        private translateService: TranslateService,
    ) {}

    private parser = new DOMParser();

    parse(markup: string, locale?: string): string {
        if (!markup) {
            return '';
        }
        const doc = this.parser.parseFromString(markup, 'text/html');

        this.appendScreenReaderTextToTargetBlankAnchorElements(doc, locale);
        // eslint-disable-next-line custom-rules/no-inner-html-property-usage
        return doc.body.innerHTML;
    }

    private appendScreenReaderTextToTargetBlankAnchorElements(doc: Document, locale: string): void {
        const text = this.getOpensToNewWindowTranslation(locale);
        const anchors = doc.getElementsByTagName('a');

        Array.from(anchors).forEach(anchor => {
            if (anchor.hasAttribute('target') && anchor.getAttribute('target') === '_blank') {
                // eslint-disable-next-line custom-rules/no-inner-html-property-usage
                anchor.innerHTML += `<span class="visually-hidden"> ${text}</span>`;
            }
        });
    }

    private getOpensToNewWindowTranslation(locale: string) {
        if (locale) {
            const localizedTranslation = this.localeService.getLocalizedString('ARIA_LABEL.OPENS_TO_A_NEW_TAB');
            return _.get(localizedTranslation, locale, '');
        }
        return this.translateService.instant('ARIA_LABEL.OPENS_TO_A_NEW_TAB');
    }
}
