'use strict';

import { ModuleEntityService } from 'sis-components/service/module-entity.service';
export const moduleJSDataModelModule = 'sis-components.model.module';
(function () {
  planModuleJSDataModel.$inject = ["DS", "MODULE_URL", "$rootScope", "$injector"];
  angular.module(moduleJSDataModelModule, ['js-data', ModuleEntityService.downgrade.moduleName]).factory('planModuleJSDataModel', planModuleJSDataModel).constant('MODULE_URL', {
    ENDPOINT_URL: '/kori/api/modules',
    SEARCH_URI: '/kori/api/module-search',
    AUTHENTICATED_ENDPOINT_URL: '/kori/api/authenticated/modules',
    AUTHENTICATED_SEARCH_URI: '/kori/api/authenticated/module-search',
    FIND_BY_GROUP_ID: '/kori/api/modules/by-group-id'
  }).run(["planModuleJSDataModel", function (planModuleJSDataModel) {// NOSONAR
  }]);

  /**
   * @ngInject
   */
  function planModuleJSDataModel(DS, MODULE_URL, $rootScope, $injector) {
    function getRules(rule, allRules) {
      allRules = allRules || [];
      if (rule) {
        allRules.push(rule);
      }
      if (rule.rule) {
        getRules(rule.rule, allRules);
      }
      if (rule.rules) {
        _.forEach(rule.rules, function (subRule) {
          getRules(subRule, allRules);
        });
      }
      return allRules;
    }
    return DS.defineResource({
      idAttribute: 'id',
      name: 'module',
      endpoint: MODULE_URL.ENDPOINT_URL,
      computed: {
        allRules: ['rule', function (rule) {
          return getRules(rule);
        }],
        hasAnyCourseUnitRule: ['allRules', function (allRules) {
          return !!_.find(allRules, {
            type: 'AnyCourseUnitRule'
          });
        }],
        hasAnyModuleRule: ['allRules', function (allRules) {
          return !!_.find(allRules, {
            type: 'AnyModuleRule'
          });
        }],
        isGroupingModule: ['type', function (type) {
          return type === 'GroupingModule';
        }]
      },
      afterUpdate: (resource, module, cb) => {
        const moduleEntityService = $injector.get('moduleEntityService');
        moduleEntityService.storeUpsert(_.cloneDeep(module));
        $rootScope.$broadcast('ModuleChange', module);
        cb(null, module);
      },
      afterCreate: (resource, module, cb) => {
        const moduleEntityService = $injector.get('moduleEntityService');
        moduleEntityService.storeUpsert(_.cloneDeep(module));
        $rootScope.$broadcast('ModuleChange', module);
        cb(null, module);
      }
    });
  }
})();