<div ngbDropdown #dropdown=ngbDropdown>
  <button ngbDropdownToggle
          type="button"
          [attr.disabled]="disabled || null"
          [ngClass]="buttonClasses">{{buttonText}}<sis-icon icon="chevron" iconClass="cw-90"></sis-icon></button>

  <div ngbDropdownMenu role="menu" [class.dropdown-menu-right]="dropdownMenuRight">
    <ng-container *ngFor="let option of options">

      <div *ngIf="option.header" class="dropdown-header">{{ option.label }}</div>

      <button type="button"
              *ngIf="!option.header"
              ngbDropdownItem
              [title]="option.label" (click)="!option.disabled && onSelect(option.value)"
              [attr.disabled]="option.disabled || null"
              [attr.tabindex]="option.disabled ? -1 : 0"
              [attr.aria-disabled]="option.disabled"
              role="menuitem">
          <span class="option-label">{{option.label}}</span>
          <span class="additional-info" *ngIf="option.info || option.disabled">{{option?.info}}
            <span *ngIf="option.disabled" class="d-block">{{'SIS_COMPONENTS.SELECT.OPTION_DISABLED' | translate}}</span>
          </span>
      </button>

    </ng-container>
  </div>
</div>
