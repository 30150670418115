import { Component, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { dateUtils } from 'common-typescript/src/dateUtils';
import { OtmId, StudyRightCancellation, StudyRightStatePeriod } from 'common-typescript/types';
import _ from 'lodash';
import moment from 'moment';
import { Subject, Subscription } from 'rxjs';
import { ModalService } from 'sis-common/modal/modal.service';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { AppErrorHandler } from '../../error-handler/app-error-handler';
import { StudyRightTermRegistrationsEntityService } from '../../service/study-right-term-registrations-entity.service';

import { StudyRightCancelDecisionModalComponent } from './study-right-cancel-decision-modal/study-right-cancel-decision-modal.component';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-study-right-states',
    templateUrl: './study-right-states.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class StudyRightStatesComponent implements OnDestroy {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.studyRight.studyRightStatesNg',
        directiveName: 'sisStudyRightStates',
    };

    constructor(
        private studyRightTermRegistrationsEntityService: StudyRightTermRegistrationsEntityService,
        private modalService: ModalService,
        private appErrorHandler: AppErrorHandler,
    ) { }

    @Input() studyRightCancellation: StudyRightCancellation;
    @Input() set studyRightId(studyRightId: OtmId) {
        this.initStatePeriods(studyRightId, false);
    }

    // Use this to manually trigger update on study right states view.
    @Input() set studyRightStatesUpdateTrigger(studyRightIdSubject: Subject<OtmId>) {
        this.studyRightIdSubscription = studyRightIdSubject
            .subscribe((studyRightId: OtmId) => this.initStatePeriods(studyRightId, true));
    }

    statePeriods: StudyRightStatePeriod[] = [];
    activeStatePeriod: StudyRightStatePeriod;
    studyRightIdSubscription: Subscription;

    private subscription = Subscription.EMPTY;

    initStatePeriods(studyRightId: OtmId, bypassStore: boolean) {
        this.subscription.unsubscribe();
        this.statePeriods = [];
        if (studyRightId) {
            this.subscription = this.studyRightTermRegistrationsEntityService.getById(studyRightId)
                .pipe(this.appErrorHandler.defaultErrorHandler())
                .subscribe({
                    next: (srtr) => {
                        this.statePeriods = this.sortPeriods(srtr.statePeriods);
                        this.activeStatePeriod = (this.statePeriods || [])
                            .find(({ startDate, endDate }) => dateUtils.dateRangeContains(moment(), startDate, endDate));
                    },
                });
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        if (this.studyRightIdSubscription) {
            this.studyRightIdSubscription.unsubscribe();
        }
    }

    getInclusiveEndDate(dateRange: { endDate: moment.MomentInput }) {
        return _.has(dateRange, 'endDate') ?
            moment(dateRange.endDate).subtract(1, 'days').format('YYYY-MM-DD') : null;
    }

    showDecisionDetails() {
        this.modalService.open(
            StudyRightCancelDecisionModalComponent,
            this.studyRightCancellation,
            {
                size: 'sm',
            }).result.catch(() => {
            // The modal was dismissed => do nothing
        });
    }

    private sortPeriods(periods: StudyRightStatePeriod[]): StudyRightStatePeriod[] {
        return _.orderBy([...periods], [x => (x.startDate ? 1 : 0), 'startDate'], ['desc', 'desc']);
    }
}
