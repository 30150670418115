fullCalendarService.$inject = ["$log", "ENROLMENT_COLORS", "courseUnitRealisationNameService"];
import * as angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import { dateUtils } from 'common-typescript';
angular.module('sis-components.service.fullCalendarService', ['sis-components.model.enrolment', 'sis-common.model.courseUnitRealisationNameService']).factory('fullCalendarService', fullCalendarService);

/**
 * @ngInject
 */
function fullCalendarService($log, ENROLMENT_COLORS, courseUnitRealisationNameService) {
  function getFullCalendarTitle(studyGroupSet) {
    if (!studyGroupSet) {
      return {};
    }
    const title = {
      name: studyGroupSet.name
    };
    return title;
  }
  function findEnrolmentAndGroupInfoForStudyEvent(studyEvent = {}, enrolments = []) {
    // eslint-disable-next-line no-restricted-syntax
    for (const enrolment of enrolments) {
      // eslint-disable-next-line no-restricted-syntax
      for (const studyGroupSet of _.get(enrolment, 'courseUnitRealisation.studyGroupSets', [])) {
        const studySubGroup = _.get(studyGroupSet, 'studySubGroups', []).find(ssg => _.includes(ssg.studyEventIds, studyEvent.id));
        if (studySubGroup) {
          return {
            enrolment,
            studyGroupSet,
            studySubGroup
          };
        }
      }
    }
    return null;
  }
  const api = {
    /**
     * Returns single array containing events for calendar of all study events.
     * Relies on enrichment of enrolments.
     */
    studyEventsToFullCalendarEvents(studyEvents, dateRange, enrolments) {
      return _.flatMap(studyEvents, studyEvent => api.studyEventToFullCalendarEvents(studyEvent, dateRange, enrolments));
    },
    studyEventToFullCalendarEvents(studyEvent, dateRange, enrolments) {
      const {
        enrolment,
        studyGroupSet,
        studySubGroup
      } = findEnrolmentAndGroupInfoForStudyEvent(studyEvent, enrolments) || {};
      if (!enrolment) {
        $log.warn('Couldn\'t find an enrolment for the study event. Failed to convert studyEvent to fullcalendar event', 'studyEvent:', studyEvent, 'enrolments:', enrolments);
      }
      return _(studyEvent.events).filter({
        excluded: false
      }).filter(event => dateUtils.dateTimeRangesOverlapNullsAsInfinity(event.start, event.end, dateRange.start, dateRange.end)).map(event => {
        const courseUnit = _.get(enrolment, 'courseUnit');
        const courseUnitRealisation = _.get(enrolment, 'courseUnitRealisation');
        const flowState = _.get(courseUnitRealisation, 'flowState', 'ACTIVE');
        let eventName = !courseUnitRealisation ? undefined : courseUnitRealisationNameService.generateFullNameFromCourseUnit(courseUnitRealisation, courseUnit);
        if (courseUnit) {
          eventName = _.mapValues(eventName, value => `${courseUnit.code} ${value}`);
        }
        const cancelled = !!(flowState === 'CANCELLED' || _.get(studyGroupSet, 'cancelled') || _.get(studySubGroup, 'cancelled') || event.cancelled);
        return {
          title: getFullCalendarTitle(studyGroupSet),
          start: moment(event.start),
          end: moment(event.end),
          teacherIds: _.defaultTo(event.irregularTeacherIds, _.get(studySubGroup, 'teacherIds')),
          locations: _.defaultTo(event.irregularLocations, studyEvent.locations),
          locationsRemoved: _.isArray(event.irregularLocations) && _.isEmpty(event.irregularLocations),
          notice: event.notice,
          name: _.get(studyEvent, 'name'),
          color: _.get(ENROLMENT_COLORS, _.get(enrolment, 'colorIndex', 0)),
          borderColor: 'white',
          cancelled,
          enrolment,
          courseUnitRealisation,
          flowState,
          eventName,
          studyGroupSetName: _.get(studyGroupSet, 'name'),
          studySubGroupName: _.get(studySubGroup, 'name'),
          type: event.type || 'CALENDAR_EVENT'
        };
      }).value();
    }
  };
  return api;
}