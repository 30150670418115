import { Directive, ElementRef, EventEmitter, Injector, Input, Output } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { Organisation, SearchFilterChangeEvent, SearchParameterOption, UniversityOrganisation } from 'common-typescript/types';

/**
 * @deprecated
 */
/**
 * Renders a button that opens a dropdown menu for selecting search filters.
 */
// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'search-filter-options-menu' })
export class SearchFilterOptionsMenuDirective extends UpgradeComponent {

    /** The search parameters to show in the dropdown menu. */
    @Input() searchParameterOptions: { [name: string]: SearchParameterOption };
    /**
     * Can be used to group the searchParameterOptions into groups with their own sub-headers. The keys are the names of
     * the groups, and the values are the translation keys for the group headers. The grouping is made by matching the
     * group names with the 'title' property in the searchParameterOptions objects.
     */
    @Input() searchParameterTitles: { [title: string]: string };
    /** An instance of the AngularJS SearchParameters object. */
    @Input() searchParams: any;
    /** Controls the state of the button that opens the menu. */
    @Input() disable: boolean;
    /** Translation key of the button that opens the menu, defaults to SEARCH.FILTER_TAGS.FILTER */
    @Input() mainButtonKey: string;
    /** Used only to search organisations, educations and modules under specific university. Unnecessary otherwise. */
    @Input() university: Organisation | UniversityOrganisation;

    /** Emits changes to the values of search filters (excluding the selection modals; see searchAgain). */
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onSelect: EventEmitter<SearchFilterChangeEvent>;
    /**
     * Emits empty events when search filters are changed using a separate modal (e.g. the hierarchical organisation and
     * education selectors). The values selected in the modal are updated directly to the searchParams object, and can be
     * read from there in response to this event.
     */
    @Output() searchAgain: EventEmitter<void>;

    constructor(elementRef: ElementRef<any>, injector: Injector) {
        super('searchFilterOptionsMenu', elementRef, injector);
    }
}
