import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterModule } from '@uirouter/angular';
import { TranslocoMarkupModule } from 'ngx-transloco-markup';

import { AuthSelectionPageComponent } from './lib/auth/auth-selection-page/auth-selection-page.component';
import { FocusableErrorDirective } from './lib/directives/focusable-error.directive';
import { EnvironmentService } from './lib/environmentService/environment.service';
import { LocaleNamesPipe } from './lib/l10n/locale-names.pipe';
import { LocalizePropertyPipe } from './lib/l10n/localize-property.pipe';
import { LocalizedStringArrayPipe } from './lib/l10n/localized-string-array.pipe';
import { LocalizedStringExistingLocalesPipe } from './lib/l10n/localized-string-existing-locales.pipe';
import { LocalizedStringMissingLocalesPipe } from './lib/l10n/localized-string-missing-locales.pipe';
import { LocalizedStringWithoutFallbackPipe } from './lib/l10n/localized-string-without-fallback.pipe';
import { LocalizedStringPipe } from './lib/l10n/localized-string.pipe';
import { AssessmentItemNameService } from './lib/name/assessment-item-name.service';
import { CourseUnitRealisationNameService } from './lib/name/course-unit-realisation-name.service';
import { TranslocoCommonModule } from './lib/transloco/transloco-common.module';
import { TranslocoLoaderHelper } from './lib/transloco/transloco-loader-helper';

@NgModule({
    declarations: [
        LocaleNamesPipe,
        LocalizedStringArrayPipe,
        LocalizedStringExistingLocalesPipe,
        LocalizedStringMissingLocalesPipe,
        LocalizedStringPipe,
        LocalizedStringWithoutFallbackPipe,
        LocalizePropertyPipe,
        FocusableErrorDirective,
        AuthSelectionPageComponent,
    ],
    imports: [
        TranslateModule.forChild(),
        TranslocoMarkupModule,
        CommonModule,
        TranslocoModule,
        UIRouterModule,
    ],
    exports: [
        LocaleNamesPipe,
        LocalizedStringArrayPipe,
        LocalizedStringExistingLocalesPipe,
        LocalizedStringMissingLocalesPipe,
        LocalizedStringPipe,
        LocalizedStringWithoutFallbackPipe,
        LocalizePropertyPipe,
        TranslocoCommonModule,
        TranslocoLoaderHelper,
        TranslocoModule,
        TranslocoMarkupModule,
        FocusableErrorDirective,
        AuthSelectionPageComponent,
    ],
    providers: [
        AssessmentItemNameService,
        CourseUnitRealisationNameService,
        EnvironmentService,
    ],
})
export class SisCommonModule {}
