import { ChangeDetectionStrategy, Component, inject, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Enrolment, EnrolmentQuestionnaire, OtmId } from 'common-typescript/types';
import { Observable, shareReplay, tap } from 'rxjs';
import { ModalService } from 'sis-common/modal/modal.service';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { EnrolmentEntityService } from 'sis-components/service/enrolment-entity.service';
import { EnrolmentQuestionnaireEntityService } from 'sis-components/service/enrolment-questionnaire-entity.service';

interface EnrolmentDialogValues {
    enrolmentId: OtmId
}

export function enrolmentDialogOpener() {
    const modalService = inject(ModalService);
    return (values: EnrolmentDialogValues) => modalService.open(EnrolmentDialogComponent, values, { size: 'lg' }).closed;
}

@Component({
    selector: 'app-enrolment-dialog',
    templateUrl: './enrolment-dialog.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnrolmentDialogComponent implements OnInit {

    enrolment: Observable<Enrolment>;
    enrolmentQuestionnaire$: Observable<EnrolmentQuestionnaire>;

    constructor(
        @Inject(ModalService.injectionToken) public values: EnrolmentDialogValues,
        public activeModal: NgbActiveModal,
        private appErrorHandler: AppErrorHandler,
        private enrolmentEntityService: EnrolmentEntityService,
        private enrolmentQuestionnaireService: EnrolmentQuestionnaireEntityService,
    ) {}

    ngOnInit(): void {
        this.enrolment = this.enrolmentEntityService.getById(this.values?.enrolmentId, true).pipe(
            tap(enrolment => {
                this.enrolmentQuestionnaire$ = this.enrolmentQuestionnaireService.getByCourseUnitRealisationId(enrolment.courseUnitRealisationId)
                    .pipe(this.appErrorHandler.defaultErrorHandler());
            }),
            this.appErrorHandler.defaultErrorHandler(),
            shareReplay(1),
        );
    }

}

