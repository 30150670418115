import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import 'sis-common/datepicker/datepicker.constant';
import localDateRangeEditorTpl from './localDateRangeEditor.tpl.html';
(function () {
  localDateRangeEditorController.$inject = ["$scope"];
  localDateRangeEditorDirective.$inject = ["dateFormat"];
  angular.module('sis-components.date.localDateRangeEditor', ['pascalprecht.translate', 'sis-common.l10n.localizedStringFilter', 'sis-common.datepicker', 'sis-components.date.constants']).directive('localDateRangeEditor', localDateRangeEditorDirective).controller('localDateRangeEditorController', localDateRangeEditorController);

  /**
   * @ngInject
   */
  function localDateRangeEditorDirective(dateFormat) {
    return {
      restrict: 'E',
      scope: {
        name: '@',
        hideLabels: '@',
        hideStartDate: '<?',
        hideEndDate: '<?',
        startDateDisabledMessage: '@',
        startDateDisabled: '@attrStartDateDisabled',
        endDateDisabledMessage: '@',
        endDateDisabled: '@attrEndDateDisabled',
        startDateRequired: '@',
        endDateRequired: '@',
        required: '<',
        fieldsOneBelowAnother: '<',
        onChange: '&?'
      },
      require: ['^^form', 'ngModel'],
      template: localDateRangeEditorTpl,
      controller: 'localDateRangeEditorController as ctrl',
      bindToController: true,
      link: function (scope, elem, attrs, [form, ngModel]) {
        const {
          ctrl
        } = scope;
        ctrl.form = form;
        ctrl.ngModel = ngModel;
        attrs.$observe('startDateDisabled', () => {
          ctrl.startDateDisabled = attrs.startDateDisabled !== 'false';
        });
        attrs.$observe('endDateDisabled', () => {
          ctrl.endDateDisabled = attrs.endDateDisabled !== 'false';
        });
        attrs.$observe('startDateRequired', () => {
          ctrl.startDateRequired = attrs.startDateRequired === 'true';
        });
        attrs.$observe('endDateRequired', () => {
          ctrl.endDateRequired = attrs.endDateRequired === 'true';
        });
        ctrl.ngModel.$parsers.push(value => ({
          startDate: value.startDate ? moment(value.startDate).format(dateFormat.LOCAL_DATE) : null,
          endDate: value.endDate ? moment(value.endDate).add(1, 'days').format(dateFormat.LOCAL_DATE) : null
        }));
        ctrl.ngModel.$formatters.push(value => ({
          startDate: value ? value.startDate : null,
          endDate: value && value.endDate ? moment(value.endDate).subtract(1, 'days').format(dateFormat.LOCAL_DATE) : null
        }));
        ctrl.ngModel.$validators.startOrEndRequired = function (modelValue) {
          return !ctrl.required || ctrl.startDateRequired || ctrl.endDateRequired || modelValue && (!_.isNil(modelValue.startDate) || !_.isNil(modelValue.endDate));
        };
        ctrl.ngModel.$validators.startBeforeEnd = function (modelValue) {
          return _.isNil(modelValue) || _.isNil(modelValue.startDate) || _.isNil(modelValue.endDate) || modelValue.startDate < modelValue.endDate;
        };
      }
    };
  }

  /**
   * @ngInject
   */
  function localDateRangeEditorController($scope) {
    const ctrl = this;
    ctrl.minEndDate = null;
    ctrl.initialEndDate = null;
    $scope.$watch('ctrl.ngModel.$viewValue.startDate', updateEndDatepickerOptions);

    // $setViewValue is needed because angular ngModel does not do deep watch on the object, and will not notice
    // changes in the underlying objects.
    //
    // in certain scenarios ctrl.ngModel may not be initialized
    // when update is called the first time, because controller is created before ngModel is set in the
    // link function
    ctrl.update = function () {
      if (_.isFunction(_.get(ctrl.ngModel, '$setViewValue'))) {
        ctrl.ngModel.$setViewValue(angular.copy(ctrl.ngModel.$viewValue));
        if (ctrl.onChange) {
          ctrl.onChange({
            value: ctrl.ngModel.$viewValue
          });
        }
      }
    };
    ctrl.showErrorList = messages => !_.isEmpty(ctrl.form[ctrl.name][messages].$error);
    function updateEndDatepickerOptions(startDate) {
      if (!_.isNil(startDate) && moment(startDate).isValid()) {
        ctrl.minEndDate = moment(startDate).toDate();
        ctrl.initialEndDate = moment(startDate).add(1, 'day').toDate();
      } else {
        ctrl.minEndDate = null;
        ctrl.initialEndDate = null;
      }
    }
  }
})();