import { Injectable } from '@angular/core';
import { TranslationMarkupRendererFactory } from 'ngx-transloco-markup';

import { HtmlElementTranspiler } from '../common/html-element-transpiler';

export const H6_START = new (class H6Start {})();
export const H6_END = new (class H6End {})();

@Injectable()
export class H6Transpiler extends HtmlElementTranspiler {
    constructor(private readonly rendererFactory: TranslationMarkupRendererFactory) {
        super({
            rendererFactory,
            start: {
                search: '<h6>',
                token: H6_START,
            },
            end: {
                search: '</h6>',
                token: H6_END,
            },
            elementTag: 'h6',
        });
    }
}
