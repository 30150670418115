<ng-container *transloco="let t">
  <sis-expandable [closed]="false" [title]="title">
    <ng-template sisExpandableHeaderButtons>
      <sis-button hollow="true"
                  [ariaLabel]="t('ARIA_LABEL.DELETE') + ' ' + title"
                  (clicked)="delete.emit()">
        <sis-icon icon="delete"></sis-icon>
      </sis-button>
    </ng-template>
    <ng-template sisExpandableContent>
      <fudis-grid [rowGap]="'md'" *ngIf="emrexIntegrationEnabled">
        <ng-container *ngIf="externalAttainedStudies.length === 0; else externalAttainedStudiesFound">
          <fudis-grid-item>
            <fudis-body-text [variant]="'md-light'">{{ t('STUDENT_APPLICATIONS.PRIOR_LEARNING.NO_EMREX_ATTAINMENTS_GUIDANCE') }}</fudis-body-text>
          </fudis-grid-item>
        </ng-container>
        <ng-template #externalAttainedStudiesFound>
          <fudis-grid-item>
            <fudis-body-text [variant]="'md-light'">{{ t('STUDENT_APPLICATIONS.PRIOR_LEARNING.EMREX_GUIDANCE') }}</fudis-body-text>
            <fudis-button class="sis-mt-xs"
                          [size]="'small'"
                          [variant]="'secondary'"
                          [label]="t('STUDENT_APPLICATIONS.PRIOR_LEARNING.SELECT_EMREX_ATTAINMENT')"
                          (handleClick)="this.openSelectEmrexAttainmentDialog()" />
          </fudis-grid-item>
        </ng-template>
        <ng-container *ngIf="!!selectedExternalAttainedStudy">
          <fudis-grid-item>
            <fudis-body-text [variant]="'md-light'">{{ t('STUDENT_APPLICATIONS.PRIOR_LEARNING.SELECTED_EMREX_ATTAINMENT_FORM_GUIDANCE') }}</fudis-body-text>
            <fudis-button class="sis-mt-xs"
                          [size]="'small'"
                          [variant]="'tertiary'"
                          [label]="t('STUDENT_APPLICATIONS.PRIOR_LEARNING.VIEW_EMREX_ATTAINMENT')"
                          (handleClick)="this.openEmrexAttainmentDialog()" />
          </fudis-grid-item>
        </ng-container>
      </fudis-grid>
        <div class="row">
          <div class="col-12">
            <sis-required-field-legend class="float-end"></sis-required-field-legend>
          </div>
        </div>
        <div class="row">
          @if (selectedExternalAttainedStudy?.learningOpportunitySpecification?.title && name?.value) {
            <div class="col-9 col-sm-8">
              <fudis-dl>
                <fudis-dl-item>
                  <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.PRIOR_LEARNING.STUDIES_NAME')"></fudis-dt>
                  <fudis-dd [contentText]="name.value"></fudis-dd>
                </fudis-dl-item>
              </fudis-dl>
            </div>
          }
          @else if (name) {
            <div class="col-9 col-sm-8">
              <div class="form-group">
                <sis-text-field [control]="name"
                                [label]="t('STUDENT_APPLICATIONS.PRIOR_LEARNING.STUDIES_NAME')"
                                id="course-unit-name"
                                name="name">
                </sis-text-field>
              </div>
            </div>
          }
          @if (selectedExternalAttainedStudy?.learningOpportunitySpecification?.iscedCode && code?.value) {
            <div class="col-9 col-sm-3 offset-sm-1">
              <fudis-dl>
                <fudis-dl-item>
                  <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.PRIOR_LEARNING.STUDIES_CODE')"></fudis-dt>
                  <fudis-dd [contentText]="code.value"></fudis-dd>
                </fudis-dl-item>
              </fudis-dl>
            </div>
          }
          @else if (code) {
            <div class="col-9 col-sm-3 offset-sm-1">
              <div class="form-group">
                <sis-text-field [control]="code"
                                [label]="t('STUDENT_APPLICATIONS.PRIOR_LEARNING.STUDIES_CODE')"
                                id="course-unit-code"
                                name="code">
                </sis-text-field>
              </div>
            </div>
          }
        </div>
        <div class="row">
          @if (selectedExternalAttainedStudy?.externalIssuerId && organisation?.value) {
            <div class="col-9 col-sm-8">
              <fudis-dl>
                <fudis-dl-item>
                  <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.PRIOR_LEARNING.STUDIES_ORGANISATION')"></fudis-dt>
                  <fudis-dd [contentText]="organisation.value"></fudis-dd>
                </fudis-dl-item>
              </fudis-dl>
            </div>
          }
          @else if (organisation) {
            <div class="col-9 col-sm-8">
              <div class="form-group">
                <sis-text-field [control]="organisation"
                                [label]="t('STUDENT_APPLICATIONS.PRIOR_LEARNING.STUDIES_ORGANISATION')"
                                id="course-unit-organisation"
                                name="organisation">
                </sis-text-field>
              </div>
            </div>
          }
          @if (selectedExternalAttainedStudy?.learningOpportunityInstance?.date && attainmentDate?.value) {
            <div class="col-9 col-sm-3 offset-sm-1">
              <fudis-dl>
                <fudis-dl-item>
                  <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.PRIOR_LEARNING.STUDIES_ATTAINMENT_DATE')"></fudis-dt>
                  <fudis-dd [contentText]="attainmentDate.value"></fudis-dd>
                </fudis-dl-item>
              </fudis-dl>
            </div>
          }
          @else if (attainmentDate) {
            <div class="col-9 col-sm-3 offset-sm-1">
              <div class="form-group">
                <sis-local-date-editor [control]="attainmentDate"
                                       [label]="t('STUDENT_APPLICATIONS.PRIOR_LEARNING.STUDIES_ATTAINMENT_DATE')"
                                       [id]="'studies-attainment-date'"
                                       [name]="'attainment-date-input'">
                </sis-local-date-editor>
              </div>
            </div>
          }
        </div>
        <div class="row">
          @if (selectedExternalAttainedStudy?.learningOpportunityInstance?.credit?.length && credits?.value) {
            <div class="col-9 col-sm-5">
              <fudis-dl>
                <fudis-dl-item>
                  <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.PRIOR_LEARNING.STUDIES_CREDITS')"></fudis-dt>
                  <fudis-dd [contentText]="credits.value">
                  </fudis-dd>
                </fudis-dl-item>
              </fudis-dl>
            </div>
          }
          @else if (credits) {
            <div class="col-9 col-sm-5">
              <div class="form-group">
                <sis-text-field [control]="credits"
                                [label]="t('STUDENT_APPLICATIONS.PRIOR_LEARNING.STUDIES_CREDITS')"
                                id="course-unit-credits"
                                name="credits">
                </sis-text-field>
              </div>
            </div>
          }
          @if (selectedExternalAttainedStudy?.learningOpportunityInstance?.languageOfInstruction && attainmentLanguage?.value) {
          <div class="col-9 col-sm-6 offset-sm-1">
            <fudis-dl>
              <fudis-dl-item>
                <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.ATTAINMENT_LANGUAGE')"></fudis-dt>
                <fudis-dd [contentText]="attainmentLanguage.value | codeLocalizedName | async"></fudis-dd>
              </fudis-dl-item>
            </fudis-dl>
          </div>
          }
          @else if (attainmentLanguage) {
            <div class="col-9 col-sm-6 offset-sm-1">
              <div class="form-group">
                <label [for]="'language-code-select'" [class]="getLabelState(attainmentLanguage)">{{t('STUDENT_APPLICATIONS.ATTAINMENT_LANGUAGE')}}</label>
                <sis-code-select-editor [id]="'language-code-select'"
                                        [codebookUrn]="languageCodeBookUrn"
                                        [selectedUrn]="attainmentLanguage.value"
                                        [required]="true"
                                        [attr.data-cy]="'prior-studies-attainment-language-editor'"
                                        (onSelectCode)="onAttainmentLanguageChange($event.selectedUrn)">
                </sis-code-select-editor>
                <sis-validation-errors [control]="attainmentLanguage" [formFieldId]="'language-code-select'" [ariaLive]="false" />
              </div>
            </div>
          }
        </div>
        <div class="row">
          @if (selectedExternalAttainedStudy?.learningOpportunityInstance?.resultLabel && grade?.value) {
          <div class="col-9 col-sm-5">
            <fudis-dl>
              <fudis-dl-item>
                <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.PRIOR_LEARNING.STUDIES_GRADE')"></fudis-dt>
                <fudis-dd [contentText]="grade.value"></fudis-dd>
              </fudis-dl-item>
            </fudis-dl>
          </div>
          }
          @else if (grade) {
          <div class="col-9 col-sm-5">
            <div class="form-group">
              <sis-text-field [control]="grade"
                              [label]="t('STUDENT_APPLICATIONS.PRIOR_LEARNING.STUDIES_GRADE')"
                              id="course-unit-grade"
                              name="grade">
              </sis-text-field>
            </div>
          </div>
          }
          @if (selectedExternalAttainedStudy?.learningOpportunityInstance?.gradingScheme && gradeScale?.value) {
            <div class="col-9 col-sm-6 offset-sm-1">
              <fudis-dl>
                <fudis-dl-item>
                  <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.PRIOR_LEARNING.STUDIES_GRADE_SCALE')"></fudis-dt>
                  <fudis-dd [contentText]="gradeScale.value"></fudis-dd>
                </fudis-dl-item>
              </fudis-dl>
            </div>
          }
          @else if (gradeScale) {
            <div class="col-9 col-sm-6 offset-sm-1">
              <div class="form-group">
                <sis-text-field [control]="gradeScale"
                                [label]="t('STUDENT_APPLICATIONS.PRIOR_LEARNING.STUDIES_GRADE_SCALE')"
                                id="course-unit-grade-scale"
                                name="gradeScale">
                </sis-text-field>
              </div>
            </div>
          }
        </div>
        @if (selectedExternalAttainedStudy?.learningOpportunitySpecification?.description && description?.value) {
          <div class="row">
            <div class="col-12">
              <fudis-dl>
                <fudis-dl-item>
                  <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.PRIOR_LEARNING.STUDIES_DESCRIPTION')"></fudis-dt>
                  <fudis-dd [contentText]="description.value"></fudis-dd>
                </fudis-dl-item>
              </fudis-dl>
            </div>
          </div>
        }
        @else if (description) {
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <sis-text-field [control]="description"
                                [label]="t('STUDENT_APPLICATIONS.PRIOR_LEARNING.STUDIES_DESCRIPTION')"
                                [textarea]="true"
                                helpBlock="STUDENT_APPLICATIONS.PRIOR_LEARNING.STUDIES_DESCRIPTION_INFO"
                                id="course-unit-description"
                                name="description">
                </sis-text-field>
              </div>
            </div>
          </div>
        }
    </ng-template>
  </sis-expandable>
</ng-container>
