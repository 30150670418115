import { Transition } from '@uirouter/core';
import { SisStateDeclaration } from 'sis-components/router/types';

import { StudentAttainmentsComponent } from './attainments/student-attainments/student-attainments.component';
import { BasicInfoComponent } from './basic-info/basic-info.component';
import { ProfileEnrolmentsComponent } from './enrolments/profile-enrolments.component';
import { GrantsPageComponent } from './payments-and-grants/grants/grants-page/grants-page.component';
import { PaymentAndGrantTabsComponent } from './payments-and-grants/payment-and-grant-tabs/payment-and-grant-tabs.component';
import { PaymentsComponent } from './payments-and-grants/payments/payments.component';
import { StudentPersonGroupsComponent } from './personGroups/student-person-groups/student-person-groups.component';
import { StudyRightsComponent } from './studyRights/study-rights/study-rights.component';

const paymentsAndGrantsRootState = 'student.logged-in.profile.payments-and-grants';
const basicInfoRootState = 'student.logged-in.profile.basic-info';
const personGroupsRootState = 'student.logged-in.profile.person-groups';
const enrolmentsRootState = 'student.logged-in.profile.enrolments';
const attainmentsRootState = 'student.logged-in.profile.attainments';

export const profileStates: SisStateDeclaration[] = [
    {
        name: 'student.logged-in.profile.study-right',
        url: '/study-rights',
        component: StudyRightsComponent,
        headerParams: {
            skipBreadcrumb: false,
            displayNameKey: 'PROFILE.STUDY-RIGHTS',
            displayPageTitleNameKey: 'MY_INFORMATION',
        },
    },
    {
        name: attainmentsRootState,
        url: '/attainments',
        component: StudentAttainmentsComponent,
        headerParams: {
            skipBreadcrumb: false,
            displayNameKey: 'PROFILE.ATTAINMENTS',
            displayPageTitleNameKey: 'MY_INFORMATION',
        },
        resolve: {
            studentProfile: studentProfileResolver,
        },
    },
    {
        name: paymentsAndGrantsRootState,
        url: '/payments-and-grants',
        component: PaymentAndGrantTabsComponent,
        redirectTo: `${paymentsAndGrantsRootState}.payments`,
        headerParams: {
            skipBreadcrumb: false,
            displayNameKey: 'PROFILE.PAYMENTS_AND_GRANTS.TAB_TITLE',
            displayPageTitleNameKey: 'MY_INFORMATION',
        },
        resolve: {
            studentProfile: studentProfileResolver,
        },
    },
    {
        name: basicInfoRootState,
        url: '/basic-info',
        component: BasicInfoComponent,
        headerParams: {
            skipBreadcrumb: false,
            displayNameKey: 'PROFILE.PERSONAL_INFO',
            displayPageTitleNameKey: 'MY_INFORMATION',
        },
        resolve: {
            studentProfile: studentProfileResolver,
        },
    },
    {
        name: `${paymentsAndGrantsRootState}.payments`,
        url: '/payments',
        component: PaymentsComponent,
        headerParams: {
            skipBreadcrumb: false,
            displayNameKey: 'PROFILE.PAYMENTS.TAB_TITLE',
            displayPageTitleNameKey: 'MY_INFORMATION',
        },
        resolve: {
            studentProfile: studentProfileResolver,
        },
    },
    {
        name: `${paymentsAndGrantsRootState}.grants`,
        url: '/grants',
        component: GrantsPageComponent,
        headerParams: {
            skipBreadcrumb: false,
            displayNameKey: 'PROFILE.GRANTS.TAB_TITLE',
            displayPageTitleNameKey: 'MY_INFORMATION',
        },
        resolve: {
            studentProfile: studentProfileResolver,
        },
    },
    {
        name: personGroupsRootState,
        url: '/person-groups',
        component: StudentPersonGroupsComponent,
        headerParams: {
            skipBreadcrumb: false,
            displayNameKey: 'PROFILE.PERSON_GROUPS.TITLE',
            displayPageTitleNameKey: 'MY_INFORMATION',
        },
    },
    {
        name: `${enrolmentsRootState}`,
        url: '/enrolments',
        component: ProfileEnrolmentsComponent,
        headerParams: {
            skipBreadcrumb: false,
            displayNameKey: 'PROFILE.ENROLMENTS',
            displayPageTitleNameKey: 'MY_INFORMATION',
        },
        resolve: {
        },
    },
];

export function studentProfileResolver($transition$: Transition): Promise<any> {
    return $transition$.injector().get('studentProfileService')
        .profile();
}

studentProfileResolver.$inject = ['$transition$'];
