import angular from 'angular';
export const onEnterModule = 'sis-components.search.onEnter';
(function () {
  angular.module(onEnterModule, []).directive('onEnter', function () {
    return {
      restrict: 'A',
      scope: {
        onEnter: '&'
      },
      link: function (scope, element) {
        element.on('keyup', function (event) {
          if (event.which === 13) {
            scope.onEnter({
              event: event
            });
          }
        });
      }
    };
  });
})();