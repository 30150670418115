import * as angular from 'angular';

export const editModeServiceModule = 'sis-components.edit.editModeService';

export class EditModeService {
    constructor(private $rootScope: angular.IRootScopeService,
                private $timeout: angular.ITimeoutService) { }

    static editModeServiceFactory($rootScope: angular.IRootScopeService, $timeout: angular.ITimeoutService) {
        return new EditModeService($rootScope, $timeout);
    }

    setEditMode(isEditMode: boolean): void {
        this.$timeout(() => this.$rootScope.$emit('setEditMode', isEditMode));
    }
}
EditModeService.editModeServiceFactory.$inject = ['$rootScope', '$timeout'];

angular.module(editModeServiceModule, [])
    .factory('editModeService', EditModeService.editModeServiceFactory);
