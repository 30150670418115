import educationStructurePreviewTpl from './educationStructurePreview.tpl.html';
export const educationStructurePreviewModule = 'sis-components.educationStructurePreview';
(function () {
  educationStructurePreviewController.$inject = ["$scope", "$element"];
  angular.module(educationStructurePreviewModule, []).directive('educationStructurePreview', educationStructurePreview).controller('educationStructurePreviewController', educationStructurePreviewController);
  function educationStructurePreview() {
    return {
      restrict: 'E',
      template: educationStructurePreviewTpl,
      scope: {
        educationModel: '<',
        connectionModel: '<'
      },
      controller: educationStructurePreviewController,
      controllerAs: 'ctrl',
      bindToController: true,
      transclude: {
        left: 'leftBoxes',
        right: '?rightBoxes'
      }
    };
  }
  function educationStructurePreviewController($scope, $element) {
    const ctrl = this;

    // internal use only
    let startsDrawn = {};
    let endingsDrawn = {};
    let leftArrowsFromY = [];

    // here you can adjust the dimensions of items. we can't get these sizes programmatically, because the elements might not exist yet in DOM.
    // education sizes should reflect the heights of the boxes in css
    const largeEducationSize = 52;
    const smallEducationSize = 24;
    // dividers should reflect the margin-top of the boxes in css
    const largeEducationDivider = 10;
    const smallEducationDivider = 4;
    // length of the horizontal part of the arrow lines
    const horizontalLinePartLength = 25;
    // sizes of the triangles used at the end of the arrows. final horizontal size is 1,5 * this value, final vertical is 2 * this value
    const activeTriangleFactor = 7;
    const inactiveTriangleFactor = 4;
    // arrow colors
    const activeArrowColor = '#1076db';
    const inactiveArrowColor = 'rgba(72,72,72)';
    ctrl.updateArrows = function () {
      // wrapper function to update the arrows. always call this, never the draw functions by hand!
      drawMiddleArrows();
      drawLeftArrows();
    };
    $scope.$watch('ctrl.educationModel', () => {
      // these two update functions are not a mistake. don't remove either of them or you will break everything
      ctrl.updateArrows();
      ctrl.updateArrows();
    }, true);
    $scope.$watch('ctrl.connectionModel', () => {
      // these two update functions are not a mistake. don't remove either of them or you will break everything
      ctrl.updateArrows();
      ctrl.updateArrows();
    }, true);
    ctrl.isStartActive = function (nodeId) {
      // there are two possible cases in which the node is active:
      //      1. the node has an active link to a ending node
      //      2. this node is not part of any connection, i.e. there aren't any arrows in the middle
      const active1 = nodeId in startsDrawn && startsDrawn[nodeId] === 'active';
      const active2 = typeof ctrl.connectionModel[nodeId] !== 'undefined' && Object.keys(ctrl.connectionModel[nodeId]).length === 0;
      return active1 || active2;
    };
    ctrl.isEndingActive = function (nodeId) {
      // if we have drawn this node, and it is active
      return nodeId in endingsDrawn && endingsDrawn[nodeId] === 'active';
    };
    function distinct(value, index, origArray) {
      // filter to get distinct array values only
      return origArray.indexOf(value) === index;
    }
    function drawLeftArrows() {
      const canvas = $element[0].getElementsByClassName('left-arrows')[0];
      // canvasSizeX is used for internal calculations, that might happen outside the visible canvas before we correct the size
      const canvasSizeX = canvas.width;
      let leftArrowStart = 0;
      let smallestArrowStart = -1;
      let largestArrowStart = -1;
      if (typeof ctrl.educationModel.left !== 'undefined' && Object.keys(ctrl.educationModel.left).length !== 0 && canvas.getContext) {
        const ctx = canvas.getContext('2d');
        // clear canvas before drawing
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        if (leftArrowsFromY.length > 0) {
          // if we have any left arrows to draw, get their positions. only distinct values count. we are interested in the smallest and largest value
          leftArrowsFromY.filter(distinct).forEach(endingY => {
            endingY = parseInt(endingY);
            if (smallestArrowStart < 0 || endingY < smallestArrowStart) {
              smallestArrowStart = endingY;
            }
            if (largestArrowStart < 0 || endingY > largestArrowStart) {
              largestArrowStart = endingY;
            }
          });
          // calculate where to start the arrows, taking the average of the smallest and largest value
          if (smallestArrowStart !== largestArrowStart) {
            leftArrowStart = Math.round((smallestArrowStart + largestArrowStart) / 2);
          } else {
            leftArrowStart = smallestArrowStart;
          }
          leftArrowsFromY.filter(distinct).forEach(endingY => {
            endingY = parseInt(endingY);
            // make actual canvas larger, if our internal Y coordinate is out of bounds
            if (canvas.height < endingY) {
              canvas.height = endingY + 10;
            }

            // draw arrow line part
            ctx.beginPath();
            ctx.moveTo(0, leftArrowStart);
            ctx.lineTo(horizontalLinePartLength - activeTriangleFactor, leftArrowStart);
            ctx.lineTo(canvasSizeX - horizontalLinePartLength, endingY);
            ctx.lineTo(canvasSizeX - activeTriangleFactor, endingY);
            ctx.strokeStyle = activeArrowColor;
            ctx.lineWidth = 2;
            ctx.setLineDash([]);
            ctx.stroke();
            ctx.closePath();

            // actual triangular part of arrow. activeTriangleFactor is just a number used to define the size of the triangle.
            ctx.beginPath();
            ctx.moveTo(canvasSizeX, endingY);
            ctx.lineTo(canvasSizeX - activeTriangleFactor * 1.5, endingY + activeTriangleFactor);
            ctx.lineTo(canvasSizeX - activeTriangleFactor * 1.5, endingY - activeTriangleFactor);
            ctx.fillStyle = activeArrowColor;
            ctx.fill();
            ctx.closePath();
          });
        }
      }
    }
    function getStartingY(startingConnectionNodeId) {
      const startingConnectionNodeSize = ctrl.educationModel.left[startingConnectionNodeId].size;
      let startingY = 0;
      // calculating the Y (vertical coordinate) of the arrow on the starting (left) side.
      // looping all preceding nodes on the left side
      for (let i = 1; i < startingConnectionNodeId; i++) {
        switch (ctrl.educationModel.left[i].size) {
          case 'large':
            startingY += largeEducationSize;
            if (i !== 1) {
              startingY += largeEducationDivider;
            }
            break;
          case 'small':
            startingY += smallEducationSize;
            if (i !== 1) {
              startingY += smallEducationDivider;
            }
            break;
          default:
            break;
        }
      }
      // adding half of the size of the actual starting node to start the line on the middle of the node.
      // also adding the size of the divider, if starting node is not the first node (which does not have a divider)

      switch (startingConnectionNodeSize) {
        case 'large':
          startingY += Math.floor(largeEducationSize / 2);
          if (_.parseInt(startingConnectionNodeId) !== 1) {
            startingY += largeEducationDivider;
          }
          break;
        case 'small':
          startingY += Math.floor(smallEducationSize / 2);
          if (_.parseInt(startingConnectionNodeId) !== 1) {
            startingY += smallEducationDivider;
          }
          break;
        default:
          break;
      }
      return parseInt(startingY);
    }
    function drawMiddleArrows() {
      const canvas = $element[0].getElementsByClassName('middle-arrows')[0];
      // canvasSizeX is used for internal calculations, that might happen outside the visible canvas before we correct the size
      const canvasSizeX = canvas.width;

      // if the education model on the left size doesn't exist, we won't even try
      if (typeof ctrl.educationModel.left !== 'undefined' && Object.keys(ctrl.educationModel.left).length !== 0 && canvas.getContext) {
        const ctx = canvas.getContext('2d');
        // clear canvas before drawing
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        startsDrawn = {};
        endingsDrawn = {};
        leftArrowsFromY = [];
        Object.keys(ctrl.connectionModel).forEach(startingConnectionNodeId => {
          // foreach all the starting nodes in the connection model
          if (Object.keys(ctrl.connectionModel[startingConnectionNodeId]).length > 0) {
            // if the education model on the right side doesn't exist, we won't do anything for the connections here
            if (typeof ctrl.educationModel.right !== 'undefined' && Object.keys(ctrl.educationModel.right).length !== 0) {
              Object.keys(ctrl.connectionModel[startingConnectionNodeId]).forEach(endingConnectionNodeId => {
                // foreach all the ending nodes from the current starting node
                const endingConnectionNodeSize = ctrl.educationModel.right[endingConnectionNodeId].size;
                const connectionStatus = ctrl.connectionModel[startingConnectionNodeId][endingConnectionNodeId];
                const startingY = getStartingY(startingConnectionNodeId);

                // same stuff for the ending Y coordinate, looping all the preceding elements on the right side
                let endingY = 0;
                for (let j = 1; j < endingConnectionNodeId; j++) {
                  switch (ctrl.educationModel.right[j].size) {
                    case 'large':
                      endingY += largeEducationSize;
                      if (j !== 1) {
                        endingY += largeEducationDivider;
                      }
                      break;
                    case 'small':
                      endingY += smallEducationSize;
                      if (j !== 1) {
                        endingY += smallEducationDivider;
                      }
                      break;
                    default:
                      break;
                  }
                }
                // and adding half of the size of the ending node arrow is drawn to, and divider if ending node is not 1st node
                switch (endingConnectionNodeSize) {
                  case 'large':
                    endingY += Math.floor(largeEducationSize / 2);
                    if (_.parseInt(endingConnectionNodeId) !== 1) {
                      endingY += largeEducationDivider;
                    }
                    break;
                  case 'small':
                    endingY += Math.floor(smallEducationSize / 2);
                    if (_.parseInt(endingConnectionNodeId) !== 1) {
                      endingY += smallEducationDivider;
                    }
                    break;
                  default:
                    break;
                }
                // start drawing!
                ctx.beginPath();
                // if this starting node does not yet have an arrow drawn on it, or we are trying to draw an active arrow over an inactive arrow, draw it
                if (!(startingConnectionNodeId in startsDrawn) || connectionStatus === 'active') {
                  ctx.moveTo(0, startingY);
                  ctx.lineTo(horizontalLinePartLength, startingY);
                  startsDrawn[startingConnectionNodeId] = connectionStatus;
                  if (connectionStatus === 'active') {
                    leftArrowsFromY.push(startingY);
                  }
                  // make actual canvas larger, if our internal Y coordinate is out of bounds
                  if (canvas.height < startingY) {
                    canvas.height = startingY + 10;
                  }
                } else {
                  // else just move the line to start from the diagonal part, skipping the horizontal part
                  ctx.moveTo(horizontalLinePartLength, startingY);
                }
                // draw diagonal part
                ctx.lineTo(canvasSizeX - horizontalLinePartLength, endingY);
                // if this ending node doesn't have the arrow yet, or we are drawing an active arrow over an inactive one, do it
                if (!(endingConnectionNodeId in endingsDrawn) || connectionStatus === 'active') {
                  // avoid drawing over the inactive triangle by removing it from the x coordinate. doesn't matter when active
                  ctx.lineTo(canvasSizeX - inactiveTriangleFactor * 1.5, endingY);
                  // make actual canvas larger, if our internal Y coordinate is out of bounds
                  if (canvas.height < endingY) {
                    canvas.height = endingY + 10;
                  }
                }
                switch (connectionStatus) {
                  // what kind of arrow style to draw?
                  case 'active':
                    ctx.strokeStyle = activeArrowColor;
                    ctx.lineWidth = 2;
                    // disable dashing (used only in inactive, must be reset here like this)
                    ctx.setLineDash([]);
                    ctx.stroke();
                    ctx.closePath();

                    // actual triangular part of arrow. activeTriangleFactor is just a number used to define the size of the triangle.
                    ctx.beginPath();
                    ctx.moveTo(canvasSizeX, endingY);
                    ctx.lineTo(canvasSizeX - activeTriangleFactor * 1.5, endingY + activeTriangleFactor);
                    ctx.lineTo(canvasSizeX - activeTriangleFactor * 1.5, endingY - activeTriangleFactor);
                    ctx.fillStyle = activeArrowColor;
                    ctx.fill();
                    ctx.closePath();
                    // add to endings drawn, so we won't draw this again
                    endingsDrawn[endingConnectionNodeId] = connectionStatus;
                    break;
                  case 'inactive':
                    ctx.strokeStyle = inactiveArrowColor;
                    ctx.lineWidth = 1;
                    // enable dashing
                    ctx.setLineDash([5, 5]);
                    ctx.stroke();
                    ctx.closePath();
                    if (!(endingConnectionNodeId in endingsDrawn)) {
                      // if this triangle wasn't drawn yet, draw the triangle. inactiveTriangleFactor is just a number to define the size of the triangle.
                      ctx.beginPath();
                      ctx.moveTo(canvasSizeX, endingY);
                      ctx.lineTo(canvasSizeX - inactiveTriangleFactor * 1.5, endingY + inactiveTriangleFactor);
                      ctx.lineTo(canvasSizeX - inactiveTriangleFactor * 1.5, endingY - inactiveTriangleFactor);
                      ctx.fillStyle = inactiveArrowColor;
                      ctx.fill();
                      ctx.closePath();
                      endingsDrawn[endingConnectionNodeId] = connectionStatus;
                    }
                    break;
                  default:
                    // something horrible has happened, draw a red line to dispatch coders
                    ctx.strokeStyle = '#ff0000';
                    ctx.stroke();
                    ctx.closePath();
                    break;
                }
              });
            }
          } else {
            // right side didn't exist, adding the arrows just for the left side
            leftArrowsFromY.push(getStartingY(startingConnectionNodeId));
          }
        });
      }
    }
  }
})();