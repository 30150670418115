import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
    selector: '[sisFocusableError]',
})
export class FocusableErrorDirective {

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2,
    ) {
        this.renderer.setAttribute(this.elementRef.nativeElement, 'tabindex', '-1');
    }

}
