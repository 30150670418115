<ng-container *transloco="let t">
  <ng-container *ngIf="data$ | async as data">
    <div class="modal-header">
      <h2 tabindex="0" class="modal-title">
        {{t(data.fromModule.type === 'StudyModule'
        ? 'MODULE_INFO.WARNING_PANEL.MODULE_TITLE'
        : 'MODULE_INFO.WARNING_PANEL.DEGREE_PROGRAMME_TITLE')}}
      </h2>
      <sis-button [link]="true"
                  class="modal-close"
                  (clicked)="this.cancel()"
                  [ariaLabel]="t('ARIA_LABEL.CLOSE')">
        <sis-icon [icon]="'ring-close'"></sis-icon>
      </sis-button>
    </div>
    <div class="modal-body">
      <p>{{t(data.fromModule.type === 'StudyModule'
          ? 'MODULE_INFO.WARNING_PANEL.MODULE_VERSION_CHANGE_TEXT'
          : 'MODULE_INFO.WARNING_PANEL.DEGREE_PROGRAMME_VERSION_CHANGE_TEXT',
        {
          fromModuleName: data.fromModule.name | localizedString,
          fromCurriculumPeriods: data.fromCurriculumPeriods,
          toCurriculumPeriods: data.toCurriculumPeriods
        })}}</p>
      <p>{{t(data.fromModule.type === 'StudyModule'
        ? 'MODULE_INFO.WARNING_PANEL.MODULE_INFO_TEXT'
        : 'MODULE_INFO.WARNING_PANEL.DEGREE_PROGRAMME_INFO_TEXT')}}</p>
    </div>
    <div class="modal-footer">
      <sis-button [variant]="'primary'"
                  [hollow]="true"
                  [size]="'lg'"
                  (clicked)="cancel()">
        {{t('CANCEL')}}
      </sis-button>
      <sis-button [variant]="'primary'"
                  [size]="'lg'"
                  (clicked)="copyAndReplace(data.fromModule, data.toModule)">
        {{t(data.fromModule.type === 'StudyModule'
        ? 'MODULE_INFO.WARNING_PANEL.MODULE_COPY_AND_REPLACE'
        : 'MODULE_INFO.WARNING_PANEL.DEGREE_PROGRAMME_COPY_AND_REPLACE')}}
      </sis-button>
      <sis-button [variant]="'primary'"
                  [size]="'lg'"
                  (clicked)="replace(data.fromModule, data.toModule)">
        {{t(data.fromModule.type === 'StudyModule'
        ? 'MODULE_INFO.WARNING_PANEL.MODULE_REPLACE'
        : 'MODULE_INFO.WARNING_PANEL.DEGREE_PROGRAMME_REPLACE')}}
      </sis-button>
    </div>
  </ng-container>
</ng-container>

