<ng-container *ngIf="loaded$ | async"> <!-- OnPush change detector gets triggered (only) when this observable emits. *ngIf function bindings were performance hit without this. -->
  <span class="tiny-badge" [ngClass]="getStatusBadgeColor()" data-cy="study-right-status">
    {{'PROFILE.STATE.' + currentState | translate}}
    <span *ngIf="getStateSpecifier() as stateSpecifier"> - {{stateSpecifier | translate}}</span>
  </span>
  <span class="tiny-badge secondary" *ngIf="hasUpcomingTransferOutDate()">
    {{'PROFILE.TRANSFERS_OUT_AT'|translate:{date: (studyRight.transferOutDate | localDateFormat)} }}
  </span>
  <span class="tiny-badge accent" *ngIf="tuitionFeeObligationPeriods.length">
    {{'STUDY_RIGHTS.TUITION_FEE_OBLIGATION_PERIODS.PAID'|translate}}
  </span>
</ng-container>
