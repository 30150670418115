import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { StateService, UrlService } from '@uirouter/core';
import { PaymentSystemPayment } from 'common-typescript/types';
import { catchError, Observable, retry, throwError } from 'rxjs';
import { delay, map, switchMap, take } from 'rxjs/operators';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { OpenUniversityCartCustomerService } from 'sis-components/service/open-university-cart-customer.service';
import { PaymentSystemLogEntityService } from 'sis-components/service/payment-system-log-entity.service';
import { PaymentSystemPaymentService } from 'sis-components/service/payment-system-payment.service';
import { PaymentSystemService } from 'sis-components/service/payment-system.service';

@Component({
    selector: 'app-paytrail-payment-complete',
    templateUrl: './paytrail-payment-complete.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaytrailPaymentCompleteComponent implements OnInit {

    constructor(
        private paymentSystemLogEntityService: PaymentSystemLogEntityService,
        private paymentSystemService: PaymentSystemService,
        private paymentSystemPaymentService: PaymentSystemPaymentService,
        private openUniversityCartCustomerService: OpenUniversityCartCustomerService,
        private stateService: StateService,
        private urlService: UrlService,
        private appErrorHandler: AppErrorHandler,
    ) {}

    ngOnInit(): void {
        // Paytrail redirects the user back to Sisu with all the payment information included in the query parameters.
        // All received parameters need to be sent to the backend for signature verification and payment processing.
        const queryParams = this.urlService.search();

        this.logPaymentEvent(queryParams);
        this.paymentSystemService.completePayment(queryParams)
            .pipe(
                take(1),
                retry({ count: 3, delay: 10000 }), // if error, wait 10 seconds and try the request again (max 3 times)
                catchError((err) => this.checkForExistingCompletedPayment(err)),
                catchError((err) => this.redirectToPaymentProcessingPage(err)),
                this.appErrorHandler.defaultErrorHandler(),
            )
            .subscribe({
                next: (payment) => {
                    if (payment?.status === 'PAID') {
                        this.stateService.go('student.open-university-cart.payment-successful', { paymentId: payment.id });
                    } else if (payment?.status === 'CANCELLED') {
                        this.stateService.go('student.open-university-cart.payment-failed');
                    } else {
                        this.stateService.go('student.open-university-cart.payment-processing');
                    }
                },
            });
    }

    // There is a small chance that user returns back from payment system too fast while payment
    // is still processing. In this case, Paytrail response is incomplete, which causes an error.
    // That's why we put small delay and try to fetch PAID payment one more time.
    // If we cannot find PAID payment, then we just forward the error.
    private checkForExistingCompletedPayment(err: any): Observable<PaymentSystemPayment> {
        return this.openUniversityCartCustomerService.getCurrentCart().pipe(
            delay(3000),
            switchMap(cart => this.paymentSystemPaymentService.getByCartIdForStudent(cart?.id)),
            map((payments: PaymentSystemPayment[]) => {
                const paidPayment = payments?.find(payment => payment?.status === 'PAID');
                if (!paidPayment) {
                    throw err;
                }
                return paidPayment;
            }),
        );
    }

    private redirectToPaymentProcessingPage(err: any) {
        this.stateService.go('student.open-university-cart.payment-processing');
        return throwError(() => err);
    }

    private logPaymentEvent(queryParams: { [key: string]: string }): void {
        this.paymentSystemLogEntityService.create({
            jsonLog: JSON.stringify(queryParams),
            paymentId: queryParams['checkout-stamp'],
            paymentSystem: 'PAYTRAIL',
            paymentSystemLogMsgDirection: 'FRONTEND_INBOUND',
        });
    }
}
