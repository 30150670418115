import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { exhaustMap, expand, filter, tap } from 'rxjs/operators';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

/**
 * Helper service for preventing the performance issues that multiple asynchronous searches creates by forcing only one http call to be sent at a time.
 * */
@StaticMembers<DowngradedService>()
@Injectable({ providedIn: 'root' })
export class SearchInOrderService {

    static downgrade: ServiceDowngradeMappings = {
        moduleName: 'sis-components.service.searchInOrderService',
        serviceName: 'searchInOrderService',
    };

    /**
     * Ordered http request searches with "string" search
     *
     * Helper logic which allows "search" -http calls to trigger in order. It will wait the previous request to return response before firing the next request in the queue.
     * Keeps only the most recent searchQuery inside the queue (while previous request is still in progress), because user only needs to receive his most recent text input results.
     *
     * Example usage: frontend-angular/projects/sis-components/webapp/lib/plan/inline-search/inline-search.component.ts
     * */
    orderedSearchWithString(searchQueryObs$: Observable<string>, callback: (textQuery: string) => Observable<any>): Observable<any> {
        let queryInQueue: string = null;

        const search = (searchQuery: string) => {
            let currentQuery = searchQuery;
            return callback(searchQuery)
                .pipe(
                    // "expand" -operator reruns the callback -method again with updated queryString if it differs from earlier request's queryString.
                    expand(() => {
                        if (queryInQueue !== currentQuery) {
                            currentQuery = queryInQueue;
                            return callback(queryInQueue);
                        }
                        return EMPTY;
                    }),
                );
        };

        return searchQueryObs$.pipe(
            filter((searchQuery: string) => searchQuery !== null),
            tap((searchQuery: string) => queryInQueue = searchQuery),
            exhaustMap((searchQuery: string) => search(searchQuery)),
        );
    }

    /**
     * Ordered http request searches with {@link SearchParameters}
     *
     * Helper logic which allows "search" -http calls to trigger in order. It will wait the previous request to return response before firing the next request in queue.
     * Keeps only the most recent searchParams inside the queue (while previous request is still in progress), because user only needs to receive his most recent text input results.
     *
     * Example usage: frontend-angular/projects/student/webapp/app/search/search-main/search-main.component.ts
     * */
    orderedSearchWithSearchParams(searchParamsObs$: Observable<any>, callback: (searchParams: any) => Observable<any>): Observable<any> {
        let queryInQueue: boolean;
        let searchParamsInQueue: any;

        const search = (searchParams: any) => {
            queryInQueue = false;
            return callback(searchParams)
                .pipe(
                    // "expand" -operator reruns the callback -method again with updated searchParams if we have a new query in queue.
                    expand(() => {
                        if (queryInQueue) {
                            queryInQueue = false;
                            return callback(searchParamsInQueue);
                        }
                        return EMPTY;
                    }),
                );
        };

        return searchParamsObs$.pipe(
            filter((searchParams: any) => searchParams !== null),
            tap((searchParams: any) => {
                queryInQueue = true;
                searchParamsInQueue = searchParams;
            }),
            exhaustMap((searchParams: any) => search(searchParams)),
        );
    }
}
