<sis-recommendation [formalRecommendation]="$any(workflowDecision)?.formalRecommendation"
                    [contentRecommendation]="$any(workflowDecision)?.contentRecommendation"
                    [approvalRequestType]="workflowType">
</sis-recommendation>
<br>
<sis-description-list>
  <dl>
    <ng-template #listItem>
      <dt class="sis-label">{{'STUDENT_APPLICATIONS.DECISION' | translate}}</dt>
      <dd>{{ shouldShowDecisionState(workflowState) ? (('STUDENT_APPLICATIONS.STATE.' + workflowState) | translate) : '-' }}
      </dd>
    </ng-template>
    <ng-template #listItem *ngIf="workflowDecision.resolutionRationale">
      <dt class="sis-label">{{'STUDENT_APPLICATIONS.RESOLUTION_RATIONALE' | translate }}</dt>
      <dd class="text-area-static">{{workflowDecision.resolutionRationale}}</dd>
    </ng-template>
    <ng-template #listItem>
      <dt class="sis-label">{{'STUDENT_APPLICATIONS.DECISION_APPROVER' | translate}}</dt>
      <dd>{{workflowDecision.approvedBy | publicPerson | async | personFullName}}, {{workflowDecision.approverTitle | localizedString}}</dd>
    </ng-template>
    <ng-template #listItem *ngIf="workflowDecision.approvalDate">
      <dt class="sis-label">{{'STUDENT_APPLICATIONS.APPROVAL_DATE' | translate}}</dt>
      <dd>{{workflowDecision.approvalDate | localDateFormat}}</dd>
    </ng-template>
    <ng-template #listItem>
      <dt class="sis-label">{{'STUDENT_APPLICATIONS.APPEAL_INSTRUCTIONS' | translate}}</dt>
      <dd>
        <sis-markup-string-view [content]="$any(workflowDecision)?.appealInstructions"></sis-markup-string-view>
      </dd>
    </ng-template>
  </dl>
</sis-description-list>
<br>
<sis-description-list *ngIf="attainment">
  <dl>
    <ng-container *ngIf="workflowState === this.studentWorkflowState.ACCEPTED">
      <ng-template #listItem>
        <dt class="sis-label">{{'STUDENT_APPLICATIONS.ATTAINMENT' | translate}}</dt>
        <dd>{{('STUDENT_APPLICATIONS.ATTAINMENT_HAS_BEEN_CREATED.' + attainment.type) | translate}}</dd>
      </ng-template>
      <ng-template #listItem>
        <dt class="sis-label">{{'STUDENT_APPLICATIONS.ATTAINMENT_NAME' | translate}}</dt>
        <dd>
          <ng-container *ngIf="$any(attainment)?.courseUnit?.name; else attainmentName">
            {{$any(attainment).courseUnit.name | localizedString}}
          </ng-container>
          <ng-template #attainmentName>
            <ng-container *ngIf="$any(attainment)?.name; else empty">
              {{$any(attainment).name | localizedString}}
            </ng-container>
          </ng-template>
          <ng-template #empty>-</ng-template>
        </dd>
      </ng-template>
      <ng-template #listItem>
        <dt class="sis-label">{{'STUDENT_APPLICATIONS.ATTAINMENT_ADDITIONAL_INFO' | translate}}</dt>
        <dd class="text-area-static">{{attainment.additionalInfo | localizedString}}</dd>
      </ng-template>
      <ng-template #listItem>
        <dt class="sis-label">{{'STUDENT_APPLICATIONS.ATTAINMENT_DATE' | translate}}</dt>
        <dd>{{attainment.attainmentDate | localDateFormat}}</dd>
      </ng-template>
      <ng-template #listItem>
        <dt class="sis-label">{{'STUDENT_APPLICATIONS.ATTAINMENT_CREDITS' | translate}}</dt>
        <dd data-cy="attainment-credits">{{attainment.credits | decimalNumber}} {{'CREDITS' | translate}}</dd>
      </ng-template>
      <ng-template #listItem>
        <dt class="sis-label">{{'STUDENT_APPLICATIONS.ATTAINMENT_GRADE' | translate}}</dt>
        <dd class="text-capitalize">
          {{attainment.gradeId | grade: attainment.gradeScaleId | async}}
          ({{attainment.gradeId | grade: attainment.gradeScaleId : 'NAME'  | async }})
        </dd>
      </ng-template>
    </ng-container>
    <ng-container *ngIf="attainment.creditTransferInfo">
      <ng-template #listItem>
        <dt class="sis-label">{{'STUDENT_APPLICATIONS.ATTAINMENT_ORGANISATION' | translate}}</dt>
        <dd data-cy="attainment-credit-transfer-organisation">
          {{attainment.creditTransferInfo.educationalInstitutionUrn | codeLocalizedName | async}}</dd>
      </ng-template>
      <ng-template #listItem *ngIf="attainment.creditTransferInfo.internationalInstitutionUrn">
        <dt class="sis-label">{{'STUDENT_APPLICATIONS.ATTAINMENT_FOREIGN_UNI' | translate}}</dt>
        <dd data-cy="attainment-credit-transfer-international-institution">
          {{attainment.creditTransferInfo.internationalInstitutionUrn | codeLocalizedName | async}}</dd>
      </ng-template>
      <ng-template #listItem *ngIf="attainment.creditTransferInfo.organisation">
        <dt class="sis-label">{{'STUDENT_APPLICATIONS.ATTAINMENT_FOREIGN_UNI' | translate}}</dt>
        <dd>{{attainment.creditTransferInfo.organisation}}</dd>
      </ng-template>
    </ng-container>
    <ng-template #listItem>
      <dt class="sis-label">{{'STUDENT_APPLICATIONS.ATTAINMENT_LANGUAGE' | translate}}</dt>
      <dd class="text-capitalize">{{attainment.attainmentLanguageUrn | codeLocalizedName | async}}</dd>
    </ng-template>
    <ng-template #listItem>
      <dt class="sis-label">{{'STUDENT_APPLICATIONS.ATTAINMENT_STUDY_FIELD' | translate}}</dt>
      <dd class="text-capitalize">{{attainment.studyFieldUrn | codeLocalizedName | async}}</dd>
    </ng-template>
    <ng-container *ngIf="attainment.type !== 'CourseUnitAttainment'">
      <ng-template #listItem>
        <dt class="sis-label">{{'STUDENT_APPLICATIONS.ATTAINMENT_STUDY_LEVEL' | translate}}</dt>
        <dd data-cy="attainment-study-level" class="text-capitalize">
          {{$any(attainment)?.studyLevelUrn | codeLocalizedName | async}}
        </dd>
      </ng-template>
      <ng-template #listItem>
        <dt class="sis-label">{{'STUDENT_APPLICATIONS.ATTAINMENT_COURSE_UNIT_TYPE' | translate}}</dt>
        <dd class="text-capitalize">{{$any(attainment)?.courseUnitTypeUrn | codeLocalizedName | async}}</dd>
      </ng-template>
    </ng-container>
    <ng-template #listItem>
      <dt class="sis-label">{{'STUDENT_APPLICATIONS.ATTAINMENT_ACCEPTOR_ORGANISATION' | translate}}</dt>
      <dd *ngFor="let organisationRoleShare of attainment.organisations" class="text-capitalize">
        <ng-container *ngIf="organisationRoleShare.organisationId; else educationalInstitution">
          {{(organisationRoleShare.organisationId | organisation | async)?.name | localizedString}}
        </ng-container>
        <ng-template #educationalInstitution>
          {{organisationRoleShare.educationalInstitutionUrn | codeLocalizedName | async}}
        </ng-template>
        <span>, </span>
        {{organisationRoleShare.roleUrn | codeLocalizedName | async}}
      </dd>
    </ng-template>
  </dl>
</sis-description-list>
