import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';

import { loginStates } from './login.route';
import { StudentAuthRouteService } from './student-auth-route.service';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        UIRouterModule.forChild({ states: loginStates }),
    ],
})
export class StudentAuthRouteModule {
    constructor(private studentAuthRouteService: StudentAuthRouteService) {
        this.studentAuthRouteService.initialize();
    }
}
