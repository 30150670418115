import { Inject, Injectable } from '@angular/core';
import { ValidatablePlan } from 'common-typescript/src/plan/validation/validatablePlan';
import { CourseUnitResultItem, EntityWithRule, ModuleResultItem } from 'common-typescript/types';
import _ from 'lodash';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

import {
    VALID_ATTAINMENT_FILTER_SERVICE,
} from '../ajs-upgraded-modules';

@StaticMembers<DowngradedService>()
@Injectable({ providedIn: 'root' })
export class SearchResultsHelperService {

    static downgrade: ServiceDowngradeMappings = {
        dependencies: [],
        moduleName: 'sis-components.service.searchResultsHelperService',
        serviceName: 'searchResultsHelperService',
    };

    constructor(
        @Inject(VALID_ATTAINMENT_FILTER_SERVICE) private validAttainmentFilterService: any,
    ) {}

    isCourseUnitSearchResultDisabled(
        courseUnitResultItem: CourseUnitResultItem,
        openModule: EntityWithRule,
        validatablePlan: ValidatablePlan,
    ) {
        const plannedCourseUnit = validatablePlan.getCourseUnitInPlanByGroupId(courseUnitResultItem.groupId);
        if (plannedCourseUnit) {
            if (validatablePlan.isCourseUnitInPlanAsSubstitute(plannedCourseUnit)) {
                return true;
            }
            if (validatablePlan.isCourseUnitInPlan(plannedCourseUnit)) {
                const parentModule = validatablePlan.getParentModuleOrCustomModuleAttainmentForCourseUnit(plannedCourseUnit);
                if (_.get(parentModule, 'type') === 'CustomModuleAttainment') {
                    return true;
                }
                if (!(plannedCourseUnit.id === courseUnitResultItem.id && _.get(parentModule, 'id') === openModule.id)) {
                    return true;
                }
            }
        }
        const allAttainments = _.values(validatablePlan.getAllAttainments());
        const matchingAttainment = _.find(allAttainments, {
            type: 'CourseUnitAttainment',
            courseUnitGroupId: courseUnitResultItem.groupId,
        });
        if (matchingAttainment && this.validAttainmentFilterService.isAttached(matchingAttainment, allAttainments)) {
            return true;
        }
        return false;
    }

    isModuleSearchResultDisabled(
        moduleResultItem: ModuleResultItem,
        openModule: EntityWithRule,
        validatablePlan: ValidatablePlan,
    ) {
        const plannedModule = validatablePlan.getModuleInPlanByGroupId(moduleResultItem.groupId);
        if (plannedModule && validatablePlan.isModuleInPlan(_.get(plannedModule, 'id'))) {
            return true;
        }
        const allAttainments = _.values(validatablePlan.getAllAttainments());
        const matchingAttainment = _.find(allAttainments, (attainment) => _.includes(['ModuleAttainment', 'DegreeProgrammeAttainment'], _.get(attainment, 'type')) &&
                _.get(attainment, 'moduleGroupId') === moduleResultItem.groupId);
        if (matchingAttainment && this.validAttainmentFilterService.isAttached(matchingAttainment, allAttainments)) {
            return true;
        }
        return false;
    }

}
