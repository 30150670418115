import { Directive, ElementRef, Input } from '@angular/core';

/**
 * A marker directive used to tag the elements that should be referenced by aria-describedby attribute.
 * Returns either the customContentId given to the directive as input parameter or the tagged element's own id.
 *
 * Examples:
 *
 * 1. Default usage: <p id="id-1" sisDescribedByContent>Descriptive text</p>
 *
 * DescribedByContentDirective id getter will return value 'id-1' for the content child above, which can then be added
 * to aria-describedby attribute allowing screen reader to read the tagged content "Descriptive text" where appropriate.
 * See reference from carousel.stories.ts and carousel-selection-box-slide component.
 *
 * 2. With customContentId: <some-sis-component-tag id="some-id" sisDescribedByContent [customContentId]="'custom-id-1 custom-id-2'"></some-sis-component-tag>
 *
 * DescribedByContentDirective id getter will return value 'custom-id-1 custom-id-2' for the content child above.
 * This can be used e.g. when only part of the component's content should be read by a screen reader or the suitable
 * content is otherwise too deep inside the component's html structure.
 */
@Directive({ selector: '[sisDescribedByContent]' })
export class DescribedByContentDirective {
    /**
     * Optional element id or space-separated list of ids to be referenced by aria-describedby attribute instead of tagged element's own id.
     */
    @Input() customContentId?: string;

    constructor(public elementRef: ElementRef<HTMLElement>) {}

    get id() {
        const id = this.customContentId ?? this.elementRef.nativeElement.id;
        if (!id) {
            console.warn('Mandatory id value not found for element tagged with sisDescribedByContent!');
        }
        return id;
    }
}
