import angular from 'angular';
import searchFilterTpl from './searchFilterSingle.tpl.html';
export const searchFilterSingleModule = 'sis-components.search.searchFilterSingle';
angular.module(searchFilterSingleModule, []).component('searchFilterSingle', {
  bindings: {
    filterName: '<',
    filterSearch: '<',
    filterValues: '<',
    getFilterNameCb: '&',
    helpText: '<',
    popoverIsOpen: '<',
    searchWithinFilter: '=',
    setValueCb: '&',
    submitFilter: '&'
  },
  template: searchFilterTpl,
  controllerAs: 'ctrl',
  controller: ["$scope", function ($scope) {
    const ctrl = this;

    // Invoke callback methods that take arguments in a way that allows to keep the template file unchanged
    // (that used to call controller methods directly when UI Bootstrap was used)
    ctrl.getFilterName = filter => ctrl.getFilterNameCb()(filter);
    ctrl.setValue = (value, form) => ctrl.setValueCb()(value, form);
    $scope.$watch('ctrl.popoverIsOpen', newVal => {
      if (!newVal) {
        // Reset form when popover closes
        $scope.filterSelectorForm.$setPristine();
      }
    });
  }]
});