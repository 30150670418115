import studyPeriodTpl from './studyPeriod.tpl.html';
'use strict';
(function () {
  StudyPeriodController.$inject = ["localeService", "SLASH"];
  angular.module('sis-components.studyPeriod', ['sis-common.l10n.localeService']).controller('StudyPeriodController', StudyPeriodController).directive('studyPeriod', studyPeriod).value('SLASH', '/');

  /**
   * @ngInject
   */
  function studyPeriod() {
    return {
      restrict: 'E',
      scope: {
        periods: '='
      },
      controller: 'StudyPeriodController',
      controllerAs: 'ctrl',
      bindToController: true,
      template: studyPeriodTpl
    };
  }

  /**
   * @ngInject
   */
  function StudyPeriodController(localeService, SLASH) {
    var ctrl = this;
    ctrl.periodText = function () {
      if (ctrl.periods) {
        if (Array.isArray(ctrl.periods)) {
          var periods = _.filter(ctrl.periods, function (item) {
            return !_.isNil(item);
          });
          if (periods.length === 1) {
            return textualPeriod(_.head(periods));
          } else if (periods.length > 1) {
            var periodTexts = [];
            var sortedPeriods = _.sortBy(periods, ['valid.startDate']);
            var p = mapByYear(sortedPeriods);
            _.forEach(_.keys(p), function (year) {
              periodTexts.push(reducePeriods(p[year]));
            });
            return periodTexts.join(', ');
          } else {
            return '';
          }
        } else {
          return textualPeriod(ctrl.periods);
        }
      }
      return '';
    };
    function textualPeriod(period) {
      return periodYear(period) + SLASH + localeService.getLocalizedValue(period.name);
    }
    function periodYear(p) {
      var yearIndex = 1;
      var parts = p.locator.split(SLASH);
      var year = parseInt(parts[yearIndex], 10);
      var nextYear = year + 1;
      return year + '-' + nextYear;
    }
    function periodStartYear(period) {
      var yearIndex = 1;
      var parts = period.locator.split(SLASH);
      return parts[yearIndex];
    }
    function mapByYear(sortedPeriods) {
      var p = {};
      _.forEach(sortedPeriods, function (period) {
        var year = periodStartYear(period);
        if (!p[year]) {
          p[year] = [];
        }
        p[year].push(period);
      });
      return p;
    }
    function reducePeriods(yearlyPeriods) {
      var previous,
        concat = false;
      return _.reduce(yearlyPeriods, function (accumulator, period) {
        if (!previous) {
          previous = period;
          return accumulator + localeService.getLocalizedValue(period.name);
        } else {
          if (previous.valid.endDate === period.valid.startDate) {
            // skip this period in text for now
            concat = true;
            previous = period;
            if (_.last(yearlyPeriods) === period) {
              // unless this is really the last period
              return accumulator + '-' + localeService.getLocalizedValue(period.name);
            }
            return accumulator;
          } else {
            if (concat) {
              concat = false;
              // no more consecutive periods, transform previous
              // period to textual representation
              accumulator += '-' + localeService.getLocalizedValue(previous.name);
              previous = period;
              // and then add the next one
              return accumulator + ',' + localeService.getLocalizedValue(period.name);
            } else {
              previous = period;
              return accumulator + ',' + localeService.getLocalizedValue(period.name);
            }
          }
        }
      }, periodYear(_.head(yearlyPeriods)) + SLASH);
    }
  }
})();