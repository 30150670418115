<div class="row">
  <div class="col-12">
    <ng-content></ng-content>
    <sis-language-pills [attr.data-cy]="'localized-string-lang'"
                        [invalidLocales]="!preventValidation ? (data|localizedStringMissingLocales) : []"
                        [selectedLanguage]="selectedLocale"
                        (selectedLanguageChange)="setLocale($event)"></sis-language-pills>
    <sis-tooltip *ngIf="tooltipText" [tooltipBlockElement]="true">
      <span class="sis-tooltip-content" aria-hidden="true">{{ tooltipText }}</span>
      <span class="sis-tooltip-trigger-content" tabindex="0" [attr.aria-label]="tooltipText" role="tooltip">
        <sis-icon icon="info-circle" color="primary"></sis-icon>
      </span>
    </sis-tooltip>
    <div *ngIf="(data || placeholderText) && !showMoreText && selectedLocale" class="form-control-static text-area-static">
      {{data ? (data | localizedStringWithoutFallback:selectedLocale) : placeholderText}}
    </div>
    <sis-show-more-text
      *ngIf="showMoreText && selectedLocale"
      [data]="data"
      [selectedLocale]="selectedLocale"
      [maxCharsBeforeOverFlow]="maxCharsBeforeOverFlow"
      [showMoreButtonText]="showMoreButtonText"
      [showLessButtonText]="showLessButtonText"
      [emptyText]="emptyText">
    </sis-show-more-text>
  </div>
</div>
