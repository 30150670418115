import { Injectable } from '@angular/core';
import { FudisDialogService } from '@funidata/ngx-fudis';
import { firstValueFrom } from 'rxjs';

import { ReadMoreNgModalComponent, ReadMoreValues } from './read-more-ng-modal/read-more-ng-modal.component';

@Injectable({
    providedIn: 'root',
})
export class ReadMoreModalService {

    constructor(
        private dialogService: FudisDialogService,
    ) { }

    open(options: ReadMoreValues) {
        return firstValueFrom(this.dialogService.open(ReadMoreNgModalComponent, { data: { ...options } }).afterClosed());
    }
}
