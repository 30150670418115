import { Component, Input, ViewEncapsulation } from '@angular/core';
import { AttainmentGroupNode } from 'common-typescript/types';

import { AttainmentsById } from './AttainmentTreeTypes';

@Component({
    selector: 'sis-attainment-tree-group-node',
    templateUrl: './attainment-tree-group-node.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class AttainmentTreeGroupNodeComponent {
    @Input() groupingNode: AttainmentGroupNode;
    @Input() attainmentsById: AttainmentsById;
    @Input() selectedLocale?: string;
    collapsed = false;
}
