<div class="answer-container">

  <h4 class="application-question-question">{{ answer.question | localizedString }}</h4>

  <div class="application-question-guidance" *ngIf="answer.guidance">
    <div class="application-question-guidance-info-icon"><sis-icon icon="info"></sis-icon></div>
    <div class="application-question-guidance-text">
      <sis-show-more-text
          [text]="answer.guidance | localizedString" [maxCharsBeforeOverFlow]="100"
          [showMoreButtonText]="'ARIA_LABEL.SHOW_MORE'" [showLessButtonText]="'ARIA_LABEL.SHOW_LESS'">
      </sis-show-more-text>
    </div>
  </div>

  <p class="application-question-answer">{{ answer.answer }}</p>

</div>

