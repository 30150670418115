<ng-container *transloco="let t">
  <div class="modal-header">
    <h2 class="modal-title" sisAutofocus>{{event.extendedProps.eventTitle}}</h2>
    <sis-modal-close-button/>
  </div>

  <div class="modal-body">
    <sis-description-list-compact>
      <dl>
        <ng-template #compactListItem>
          <dt class="label colon">{{ t('SIS_COMPONENTS.COURSE_UNIT_REALISATION_STRUCTURE.EVENTS_TABLE.COLUMNS.TIME') }}</dt>
          <dd class="value">
            {{ event.start | weekdayAndDateTimeRange: event.end }}
          </dd>
        </ng-template>
      </dl>
    </sis-description-list-compact>
    <sis-button (clicked)="openOwnCalendarEventModal()" link="true">
      {{ t('EDIT_CALENDAR_EVENT') }}
    </sis-button>
  </div>
  <div class="modal-footer confirm-dialog-footer">
    <sis-button (clicked)="activeModal.dismiss()">{{ t('BUTTON.CLOSE') }}</sis-button>
  </div>
</ng-container>
