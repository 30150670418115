import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { InternationalContractualDegree, InternationalContractualDegreeAgreement } from 'common-typescript/types';
import { Subscription } from 'rxjs';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { AppErrorHandler } from '../../error-handler/app-error-handler';
import { InternationalContractualDegreeAgreementEntityService } from '../../service/international-contractual-degree-agreement-entity.service';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-international-contractual-degree',
    templateUrl: './international-contractual-degree.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class InternationalContractualDegreeComponent implements OnInit, OnDestroy {
    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.studyRight.internationalContractualDegreeNg',
        directiveName: 'sisInternationalContractualDegree',
    };

    constructor(
        private internationalContractualDegreeAgreementEntityService: InternationalContractualDegreeAgreementEntityService,
        private appErrorHandler: AppErrorHandler,
    ) { }

    /**
     * Determines expandable heading's semantic aria-level for screen readers, default is equivalent for level 7
     */
    @Input() level?: number = 7;
    @Input() internationalContractualDegree: InternationalContractualDegree;
    internationalContractualDegreeAgreement: InternationalContractualDegreeAgreement;
    subscription = Subscription.EMPTY;

    ngOnInit(): void {
        this.subscription = this.internationalContractualDegreeAgreementEntityService.getById(
            this.internationalContractualDegree.internationalContractualDegreeAgreementId,
        )
            .pipe(this.appErrorHandler.defaultErrorHandler())
            .subscribe({
                next: (agreement) => {
                    this.internationalContractualDegreeAgreement = agreement;
                },
            });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
