import angular from 'angular';
import 'js-data';
export const studyRightExpirationRulesModelModule = 'sis-components.model.studyRightExpirationRules';
(function () {
  studyRightExpirationRulesJSDataModel.$inject = ["$http", "DS"];
  angular.module(studyRightExpirationRulesModelModule, ['js-data']).factory('studyRightExpirationRulesJSDataModel', studyRightExpirationRulesJSDataModel);
  function studyRightExpirationRulesJSDataModel($http, DS) {
    var model = DS.defineResource({
      idAttribute: 'urn',
      name: 'studyRightExpirationRules',
      endpoint: '/kori/api/cached/codebooks/urn:code:study-right-expiration-rules'
    });
    model.lazyGetAll = _.once(function () {
      return $http.get(model.endpoint).then(function (response) {
        model.inject(_.values(response.data));
        return model.getAll();
      });
    });
    return model;
  }
})();