graduationModalService.$inject = ["modalService"];
import angular from 'angular';
import { ModalService } from 'sis-common/modal/modal.service.ts';
import { ApplyForGraduationModalComponent } from './applyForGraduationModal-ng.component.ts';
angular.module('student.common.components.graduationModalService', ['student.common.components.applyForGraduationModal', ModalService.downgrade.moduleName]).factory('graduationModalService', graduationModalService);
function graduationModalService(modalService) {
  return {
    openApplyForGraduationModal: (organisationId, moduleId, planId) => modalService.open(ApplyForGraduationModalComponent, {
      organisationId,
      moduleId,
      planId
    }, {
      size: 'sm'
    }).result
  };
}