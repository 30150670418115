(function () {
  angular.module('student.common.filters.activityPeriod', []).filter('activityPeriod', activityPeriod);
  function activityPeriod() {
    return function (cur) {
      var format = 'D.M.YYYY';
      var start = moment(_.result(cur, 'activityPeriod.startDate'), 'YYYY-MM-DD');
      var end = moment(_.result(cur, 'activityPeriod.endDate'), 'YYYY-MM-DD').subtract(1, 'days');
      var diff = end.diff(start, 'days');
      if (diff > 0 && start.isValid() && end.isValid()) {
        return start.format(format) + '–' + end.format(format);
      } else if (start.isValid()) {
        return start.format(format);
      }
      return '';
    };
  }
})();