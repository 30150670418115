<ul>
  <ng-container *ngFor="let requirementRule of rules">
    <li>
      <sis-icon [icon]="requirementRule.result ? 'check' : 'fail'"
                [color]="requirementRule.result ? 'success' : 'danger'"
                aria-hidden="true"></sis-icon>
      {{ requirementRule.rule | personRequirementRuleName | async }}
    </li>
  </ng-container>
</ul>
