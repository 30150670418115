import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MaxLength } from 'common-typescript/constants';
import { OtmId } from 'common-typescript/types';
import { ModalService } from 'sis-common/modal/modal.service';
import { UuidService } from 'sis-common/uuid/uuid.service';

import { AlertsService } from '../../alerts/alerts-ng.service';
import { isNumber, max, maxLength, min, required } from '../../form/form-validators';
import { getLabelState, getNumericInputValue } from '../../form/formUtils';
import { SisFormBuilder } from '../../form/sis-form-builder.service';

export interface CreateCustomStudyDraftModalValues {
    parentModuleId: OtmId;
}

@Component({
    selector: 'sis-create-custom-study-draft',
    templateUrl: './create-custom-study-draft.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CreateCustomStudyDraftComponent implements OnInit {

    form: FormGroup;

    readonly getLabelState = getLabelState;

    constructor(
        private alertsService: AlertsService,
        private fb: SisFormBuilder,
        private modal: NgbActiveModal,
        private uuidService: UuidService,
        @Inject(ModalService.injectionToken) private values: CreateCustomStudyDraftModalValues,
    ) {}

    ngOnInit(): void {
        this.form = this.fb.group({
            name: [null, [required(), maxLength(MaxLength.MAX_SHORT_STRING_LENGTH)]],
            credits: [null, [required(), isNumber(), min(0), max(999)]],
            location: [null, [required(), maxLength(MaxLength.MAX_TERSE_STRING_LENGTH)]],
            description: [null, [required(), maxLength(MaxLength.MAX_LONG_STRING_LENGTH)]],
        });
    }

    onSubmit(): void {
        if (this.form.valid) {
            this.modal.close({
                ...this.form.value,
                credits: getNumericInputValue(this.credits),
                parentModuleId: this.values?.parentModuleId,
                id: this.uuidService.randomUUID(),
            });
        } else {
            this.alertsService.addFormSubmissionFailureAlert();
            this.form.markAllAsTouched();
        }
    }

    onCancel(): void {
        this.modal.dismiss();
    }

    get name(): FormControl {
        return this.form?.get('name') as FormControl;
    }

    get credits(): FormControl {
        return this.form?.get('credits') as FormControl;
    }

    get location(): FormControl {
        return this.form?.get('location') as FormControl;
    }

    get description(): FormControl {
        return this.form?.get('description') as FormControl;
    }
}
