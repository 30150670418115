import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SlideBanner } from 'sis-components/carousel/carousel.types';

import { AggregateProductInfo } from '../types';

@Pipe({ name: 'productSlideBanner' })
export class ProductSlideBannerPipe implements PipeTransform {

    constructor(private translate: TranslateService) {}

    transform(product: AggregateProductInfo): SlideBanner[] {
        const successBanner: SlideBanner = {
            className: 'success',
            text: this.translate.instant('OPEN_UNIVERSITY.STUDY_OFFERING.ADDED_TO_CART'),
        };
        const dangerBanner: SlideBanner = {
            className: 'danger',
            text: this.translate.instant('OPEN_UNIVERSITY.STUDY_OFFERING.NO_SEATS_AVAILABLE'),
        };

        if (product?.isInCart && product?.isFull) {
            return [successBanner, dangerBanner];
        }
        if (product?.isInCart) {
            return [successBanner];
        }
        if (product?.isFull) {
            return [dangerBanner];
        }

        return null;
    }
}
