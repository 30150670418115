export const educationServiceModule = 'student.common.service.educationService';
angular.module(educationServiceModule, ['sis-components.service.educationService', 'sis-components.service.studyRightService', 'sis-components.search.searchParameterService']).service('educationService', ["$q", "commonEducationService", "commonStudyRightService", "universityService", function ($q, commonEducationService, commonStudyRightService, universityService) {
  var api = {
    getAllEducations: function (universityOrgId, includeExpired) {
      var params = {};
      params.universityOrgId = universityOrgId ? universityOrgId : universityService.getCurrentUniversityOrgId();
      params.includeExpired = includeExpired ? includeExpired : false;
      return commonEducationService.findAll(params);
    },
    getEducationsByIds: function (ids) {
      return commonEducationService.findByIds(ids).then(function (educations) {
        return educations;
      });
    },
    getEducationById: function (id) {
      return commonEducationService.findById(id).then(function (education) {
        return education;
      });
    },
    /**
     * @param {boolean} onlyOngoing if true, only returns educations related to study rights that are still
     * ongoing (i.e. excludes graduated and canceled ones)
     */
    getMyStudyRightEducations: function (onlyOngoing) {
      return commonStudyRightService.studyRights(false, false).then(function (studyRights) {
        if (onlyOngoing) {
          studyRights = _.filter(studyRights, function (studyRight) {
            return studyRight.state === 'ACTIVE' || studyRight.state === 'ACTIVE_NONATTENDING' || studyRight.state === 'NOT_STARTED' || studyRight.state === 'PASSIVE';
          });
        }
        var educationIds = _.map(studyRights, 'educationId');
        return api.getEducationsByIds(educationIds);
      });
    }
  };
  return api;
}]);