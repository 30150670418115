import * as _ from 'lodash';

import { Rule } from '../../types';
import { ModuleContext } from '../plan/validation/context/moduleContext';
import { ruleUtils } from '../ruleUtils';

export interface RuleSortDetails {
    ruleId: string | null;
    anyRuleCount: number;
    courseUnitRuleCount: number;
    matchingCourseUnitCount: number;
    moduleRuleCount: number;
    matchingModuleCount: number;
}
export class RuleSortingService {

    static getRuleSortDetailsMap(rule: Rule, moduleContext: ModuleContext) {
        const ruleSortDetailsMap: { [id: string]: RuleSortDetails } = {};

        this.getRuleSortDetails(rule, moduleContext, ruleSortDetailsMap);

        return ruleSortDetailsMap;
    }

    static getRuleSortDetails(
        rule: Rule,
        moduleContext: ModuleContext,
        ruleSortDetailsMap: { [id: string]: RuleSortDetails },
    ) {
        if (ruleUtils.isCompositeRule(rule)) {
            const ruleSortDetailsObject = this.getEmptyRuleSortDetailsObject(rule);
            _.forEach(rule.rules, (childRule) => {
                this.addChildRuleDetails(ruleSortDetailsObject, this.getRuleSortDetails(childRule, moduleContext, ruleSortDetailsMap));
            });
            ruleSortDetailsMap[rule.localId] = ruleSortDetailsObject;
            return ruleSortDetailsObject;
        }

        if (ruleUtils.isCourseUnitCountRule(rule)) {
            const ruleSortDetailsObject = this.getEmptyRuleSortDetailsObject(rule);
            this.addChildRuleDetails(ruleSortDetailsObject, this.getRuleSortDetails(rule.rule, moduleContext, ruleSortDetailsMap));
            ruleSortDetailsMap[rule.localId] = ruleSortDetailsObject;
            return ruleSortDetailsObject;
        }

        if (ruleUtils.isCreditsRule(rule)) {
            const ruleSortDetailsObject = this.getEmptyRuleSortDetailsObject(rule);
            this.addChildRuleDetails(ruleSortDetailsObject, this.getRuleSortDetails(rule.rule, moduleContext, ruleSortDetailsMap));
            ruleSortDetailsMap[rule.localId] = ruleSortDetailsObject;
            return ruleSortDetailsObject;
        }

        if (ruleUtils.isCourseUnitRule(rule)) {
            const ruleSortDetailsObject = this.getEmptyRuleSortDetailsObject(rule);
            ruleSortDetailsObject.courseUnitRuleCount = 1;
            ruleSortDetailsObject.matchingCourseUnitCount = moduleContext.unmatchedCourseUnitsByGroupId[rule.courseUnitGroupId] ? 1 : 0;
            ruleSortDetailsMap[rule.localId] = ruleSortDetailsObject;
            return ruleSortDetailsObject;
        }

        if (ruleUtils.isModuleRule(rule)) {
            const ruleSortDetailsObject = this.getEmptyRuleSortDetailsObject(rule);
            ruleSortDetailsObject.moduleRuleCount = 1;
            ruleSortDetailsObject.matchingModuleCount = moduleContext.unmatchedModulesByGroupId[rule.moduleGroupId] ? 1 : 0;
            ruleSortDetailsMap[rule.localId] = ruleSortDetailsObject;
            return ruleSortDetailsObject;
        }

        if (ruleUtils.isAnyCourseUnitRule(rule)) {
            const ruleSortDetailsObject = this.getEmptyRuleSortDetailsObject(rule);
            ruleSortDetailsObject.anyRuleCount = 1;
            ruleSortDetailsMap[rule.localId] = ruleSortDetailsObject;
            return ruleSortDetailsObject;
        }

        if (ruleUtils.isAnyModuleRule(rule)) {
            const ruleSortDetailsObject = this.getEmptyRuleSortDetailsObject(rule);
            ruleSortDetailsObject.anyRuleCount = 1;
            ruleSortDetailsMap[rule.localId] = ruleSortDetailsObject;
            return ruleSortDetailsObject;
        }
        return this.getEmptyRuleSortDetailsObject(null);

    }

    static getEmptyRuleSortDetailsObject(rule: Rule | null): RuleSortDetails {
        return {
            ruleId: rule ? rule.localId : null,
            anyRuleCount: 0,
            courseUnitRuleCount: 0,
            matchingCourseUnitCount: 0,
            moduleRuleCount: 0,
            matchingModuleCount: 0,
        };
    }

    static addChildRuleDetails(parentDetails: RuleSortDetails, childDetails: RuleSortDetails) {
        parentDetails.anyRuleCount += childDetails.anyRuleCount;
        parentDetails.courseUnitRuleCount += childDetails.courseUnitRuleCount;
        parentDetails.matchingCourseUnitCount += childDetails.matchingCourseUnitCount;
        parentDetails.moduleRuleCount += childDetails.moduleRuleCount;
        parentDetails.matchingModuleCount += childDetails.matchingModuleCount;
    }

    static sortRules(rules: Rule[], ruleSortDetailsMap: { [id: string]: RuleSortDetails }) {
        const firstPriorityRules = _.filter(rules, rule =>
            ruleUtils.isCourseUnitRule(rule) || ruleUtils.isModuleRule(rule));
        const lastPriorityRules = _.filter(rules, rule =>
            ruleUtils.isAnyCourseUnitRule(rule) || ruleUtils.isAnyModuleRule(rule));
        const middlePriorityRules = _.filter(rules, rule =>
            ruleUtils.isCompositeRule(rule) || ruleUtils.isCreditsRule(rule) || ruleUtils.isCourseUnitCountRule(rule));
        const sortedRules = _.sortBy(middlePriorityRules, [
            (rule) => {
                const ruleSortDetails = _.get(ruleSortDetailsMap, rule.localId);
                if (ruleSortDetails?.anyRuleCount === 0) {
                    return -10000;
                }
                return 10000 - (ruleSortDetails.matchingCourseUnitCount + ruleSortDetails.matchingModuleCount);

            },
        ]);

        // @ts-ignore
        return _.concat(firstPriorityRules, sortedRules, lastPriorityRules);
    }

}
