<ng-container *ngFor="let entry of registrationsByStudyYear | slice:0:DEFAULT_STUDY_YEAR_COUNT">
  <ng-container *ngTemplateOutlet="registrationsForStudyYear; context:
  {studyYear: entry.studyYear, registrations: entry.registrations, level: level}"/>
</ng-container>
<sis-expandable *ngIf="registrationsByStudyYear.length > DEFAULT_STUDY_YEAR_COUNT" [variant]="'link'" [level]="level"
                [title]="'PROFILE.SHOW_OLD_SEMESTERS' | translate">
  <ng-template sisExpandableContent>
    <ng-container *ngFor="let entry of registrationsByStudyYear | slice:DEFAULT_STUDY_YEAR_COUNT">
      <ng-container *ngTemplateOutlet="registrationsForStudyYear; context:
      {studyYear: entry.studyYear, registrations: entry.registrations, level: level+1}"/>
    </ng-container>
  </ng-template>
</sis-expandable>

<ng-template #registrationsForStudyYear let-studyYear="studyYear" let-registrations="registrations" let-level="level">
  <sis-study-year-term-registrations
    [level]="level"
    [studyTermNamesByTermIndex]="studyTermNamesByTermIndex"
    [studyYearName]="studyYear + '–' + (studyYear + 1)"
    [termRegistrations]="registrations"
    [studyRightId]="studyRightId"/>
</ng-template>
