<div class="course-unit-info-modal">
    <div class="course-unit-info-modal-header courseunit cu-color-0">
        <div class="credits-box">
            <span *ngIf="!courseUnitAttainment" class="credit-amount">{{courseUnit.credits | creditRange: 'SYMBOLS': true}}</span>
            <span *ngIf="courseUnitAttainment" class="credit-amount">{{courseUnitAttainment.credits}}</span>
            <span class="credit-abbreviation" translate>STUDIES.COURSE_UNIT_INFO_MODAL.CREDITS_ABBREVIATION</span>
            <div class="grade-box" *ngIf="courseUnitAttainment">
                <sis-icon icon="achievement"></sis-icon>
                <span>{{ courseUnitAttainment.gradeId | grade: courseUnitAttainment.gradeScaleId | async }}</span>
            </div>
        </div>
        <div class="info-box">
            <div class="top-section">
                <div class="code-and-name">
                    <div class="course-unit-code">{{ courseUnit.code }}</div>
                    <h2 *ngIf="courseUnit.name[contentLanguage]" class="modal-title">
                        {{courseUnit.name[contentLanguage]}}
                        <span *ngIf="courseUnit.documentState==='DRAFT' || courseUnit.documentState==='DELETED'"
                              class="tiny-badge"
                              [class.accent]="courseUnit.documentState==='DRAFT'"
                              [class.danger]="courseUnit.documentState==='DELETED'">
                            {{'DOCUMENT_STATE.' + courseUnit.documentState | translate}}
                        </span>
                    </h2>
                    <h2 *ngIf="!courseUnit.name[contentLanguage]" class="modal-title">({{fallbackName}})</h2>
                </div>
                <div class="top-icons">
                    <button (click)="dismiss()" class="btn-close" type="button" [attr.aria-label]="('ARIA_LABEL.CLOSE' | translate)">
                      <sis-icon icon="close-big"></sis-icon>
                    </button>
                </div>
            </div>
            <div class="middle-section">
              <div *ngIf="!courseUnit.name[contentLanguage]" class="bottom-margin">
                <sis-contextual-notification variant="warning">
                {{'STUDIES.COURSE_UNIT_INFO_MODAL.NO_NAME_IN_SELECTED_LANGUAGE' | translate }}
                </sis-contextual-notification>
              </div>
                <sis-course-unit-version-selector *ngIf="courseUnitVersions?.length"
                                                  [currentCourseUnit]="courseUnit"
                                                  (change)="setCourseUnitVersion($event)"
                                                  [courseUnitVersions]="courseUnitVersions"
                                                  [displayNamesByCourseUnitId]="displayNamesByCourseUnitId">
                </sis-course-unit-version-selector>
              <div *ngIf="isAnyVersionInPlan && !isCurrentVersionInPlan && !courseUnitVersionInPlanIsSubstituted && canCurrentVersionBeSelectedInPlan" class="bottom-margin">
                <sis-contextual-notification variant="warning">
                  <span translate="STUDIES.COURSE_UNIT_INFO_MODAL.VERSION_CHANGE_IS_ALLOWED"></span>
                  <button type="button" class="link-button" (click)="changeCourseUnitVersionInPlan()">
                    <span translate="STUDIES.COURSE_UNIT_INFO_MODAL.SELECT_CURRENT_VERSION_IN_PLAN"></span>
                  </button>
              </sis-contextual-notification>
              </div>
              <div *ngIf="isAnyVersionInPlan && !isCurrentVersionInPlan && (!canCurrentVersionBeSelectedInPlan || courseUnitVersionInPlanIsSubstituted)" class="bottom-margin">
                <sis-contextual-notification variant="danger">
                  <span>{{'STUDIES.COURSE_UNIT_INFO_MODAL.VERSION_CHANGE_IS_NOT_ALLOWED' | translate }}</span>
                </sis-contextual-notification>
              </div>
              <div *ngIf="!isAnyVersionInPlan" class="bottom-margin">
                <sis-contextual-notification variant="warning">
                   <span>{{'STUDIES.COURSE_UNIT_INFO_MODAL.NO_VERSION_OF_COURSE_UNIT_IS_IN_PLAN' | translate }}</span>
              </sis-contextual-notification>
              </div>
            </div>
            <div class="bottom-section">
                <ul class="nav nav-pills">
                    <li role="presentation" class="no-margin" [class.active]="activeTab === 0">
                        <a href="javascript:void(0);"
                           (click)="openTab(0)"
                           [attr.aria-selected]="activeTab === 0"
                           role="tab"
                           translate>STUDIES.COURSE_UNIT_INFO_MODAL.CUR_INFO</a>
                    </li>
                    <li role="presentation" [class.active]="activeTab === 1">
                        <a href="javascript:void(0);"
                           (click)="openTab(1)"
                           [attr.aria-selected]="activeTab === 1"
                           role="tab"
                           translate>STUDIES.COURSE_UNIT_INFO_MODAL.COMPLETION_METHODS</a>
                    </li>
                    <li role="presentation" [class.active]="activeTab === 2">
                        <a href="javascript:void(0);"
                           (click)="openTab(2)"
                           [attr.aria-selected]="activeTab === 2"
                           role="tab"
                           translate>STUDIES.COURSE_UNIT_INFO_MODAL.TITLE_SUBSTITUTIONS</a>
                    </li>
                </ul>
              <sis-language-pills *ngIf="locales?.length > 1" [(selectedLanguage)]="contentLanguage"></sis-language-pills>
            </div>
        </div>
    </div>
    <div class="modal-body">
        <div *ngIf="activeTab === 0">
          <sis-plan-course-unit-info-modal-basic-info [courseUnit]="courseUnit"
                                                      [language]="contentLanguage">
          </sis-plan-course-unit-info-modal-basic-info>
        </div>
        <div *ngIf="activeTab === 1">
            <div *ngIf="!isCurrentCourseUnitVersionInPlan() || courseUnitAttainment">
                <sis-completion-methods [courseUnit]="courseUnit"
                                        [language]="contentLanguage">
                </sis-completion-methods>
            </div>
            <div *ngIf="isCurrentCourseUnitVersionInPlan() && !courseUnitAttainment">
                <sis-selectable-completion-methods [courseUnit]="courseUnit"
                                                   [validatablePlan]="validatablePlan"
                                                   [planActionsService]="planActionsService"
                                                   [language]="contentLanguage">
                </sis-selectable-completion-methods>
            </div>
        </div>
        <div *ngIf="activeTab === 2">
            <sis-expandable [level]="3"
                            [title]="'STUDIES.COURSE_UNIT_INFO_MODAL.SUBSTITUTIONS' | translate"
                            [closed]="false">
                <ng-template sisExpandableContent>
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="form-group-mimic" *ngIf="courseUnit.substitutions?.length > 0 || courseUnit.equivalentCoursesInfo">
                                <h4 translate>STUDIES.COURSE_UNITS_BASICINFO.SUBSTITUTIONS</h4>
                                <div class="substitution-top-padding"
                                     *ngFor="let substitution of courseUnit.substitutions; index as substitutionIndex">
                                    <div class="substitution-container">
                                        <sis-course-unit-substitution [substitution]="substitution"
                                                                      [colorIndex]="substitutionIndex"
                                                                      [language]="contentLanguage">
                                        </sis-course-unit-substitution>
                                    </div>
                                </div>
                                <sis-course-unit-info-modal-text-field [localizedMarkupStringValue]="courseUnit.equivalentCoursesInfo"
                                                                       [language]="contentLanguage">
                                </sis-course-unit-info-modal-text-field>
                            </div>
                            <div class="form-group-mimic" *ngIf="courseUnit.substitutions?.length === 0 && !courseUnit.equivalentCoursesInfo">
                                <p class="guidance" translate="STUDIES.COURSE_UNIT_INFO_MODAL.NO_SUBSTITUTIONS"></p>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </sis-expandable>
        </div>
    </div>
</div>


