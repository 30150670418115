<h2>{{ 'FRONTPAGE.MY_STUDIES.TITLE' | translate }}</h2>

<sis-generic-container>
  <ng-container *ngIf="enrolments$ | async as enrolments">
    <ng-container *ngFor="let enrolment of enrolments">
      <ng-container *ngIf="{
        courseUnitRealisation: enrolment.courseUnitRealisationId | courseUnitRealisation | async,
        courseUnit: enrolment.courseUnitId | courseUnit | async
      } as data">
        <course-unit-realisation
          *ngIf="data.courseUnitRealisation && data.courseUnit"
          [colorIndex]="enrolment.colorIndex"
          [courseUnitRealisation]="data.courseUnitRealisation"
          [courseUnit]="data.courseUnit"
          [enrolment]="enrolment"
          frontPageView="true">
        </course-unit-realisation>
      </ng-container>
    </ng-container>

    <div *ngIf="enrolments.length === 0" class="no-studies">
      <p>{{ 'FRONTPAGE.MY_STUDIES.NO_STUDIES' | translate }}</p>
    </div>
  </ng-container>
</sis-generic-container>
