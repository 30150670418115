import showModuleContentApplicationTpl from './showModuleContentApplication.tpl.html';
import 'angular';
import 'angular-translate';
import _ from 'lodash';
import { commonPlanServiceModule } from 'sis-components/service/plan.service';
import 'sis-components/service/module.service';
import 'sis-components/service/student.service';
import 'sis-components/service/publicPerson.service';
import 'sis-common/l10n/localeService';
import 'sis-components/service/gradeScale.service';
import 'sis-components/date/filters/localDateRange/localDateRange.filter';
import 'sis-components/applications/recommendation.component';
import 'sis-components/service/education.service';
import 'sis-components/service/studentApplication.service';
import 'sis-components/model/applicationDecision.model';
import 'sis-components/service/applicationDecision.service';
import 'sis-components/service/moduleContentApplication.service';
import 'sis-components/customStudyDraft/customStudyDraftSummary.component';
'use strict';
(function () {
  showModuleContentApplicationController.$inject = ["$q", "commonStudentService", "commonCourseUnitService", "defaultPromiseHandler", "$window", "publicPersonService", "commonModuleService", "commonAttainmentService", "commonEducationService", "commonStudentApplicationService", "commonPlanService", "$translate", "localeService", "commonGradeScaleService", "moduleContentApplicationService"];
  angular.module('student.profile.applications.showModuleContentApplication', ['sis-components.service.moduleService', commonPlanServiceModule, 'sis-components.service.studentService', 'sis-components.service.publicPersonService', 'pascalprecht.translate', 'sis-common.l10n.localeService', 'sis-components.service.gradeScale', 'sis-components.date.localDateRangeFilter', 'sis-components.applications.recommendation', 'sis-components.service.educationService', 'sis-components.service.studentApplicationService', 'sis-components.model.applicationDecision', 'sis-components.service.applicationDecisionService', 'sis-components.service.moduleContentApplicationService', 'sis-components.customStudyDraftSummary']).component('showModuleContentApplication', {
    template: showModuleContentApplicationTpl,
    bindings: {
      application: '<',
      readOnly: '<'
    },
    controller: showModuleContentApplicationController
  });

  /**
   * @ngInject
   */
  function showModuleContentApplicationController($q, commonStudentService,
  // NOSONAR
  commonCourseUnitService, defaultPromiseHandler, $window, publicPersonService, commonModuleService, commonAttainmentService, commonEducationService, commonStudentApplicationService, commonPlanService, $translate, localeService, commonGradeScaleService, moduleContentApplicationService) {
    const $ctrl = this;
    $ctrl.$onInit = function () {
      commonStudentService.findStudent($ctrl.application.studentId).then(student => {
        $ctrl.student = student;
      });
      moduleContentApplicationService.loadModuleContentApplicationObjects($ctrl.application).then(result => {
        $ctrl.module = result.module;
        $ctrl.selectedCustomCourseUnitAttainments = result.selectedCustomCourseUnitAttainments;
        $ctrl.selectedCustomModuleAttainments = result.selectedCustomModuleAttainments;
        $ctrl.education = result.education;
        $ctrl.plan = result.plan;
        $ctrl.selectedModules = result.selectedModules;
        $ctrl.selectedModuleAttainmentsByGroupId = result.selectedModuleAttainmentsByGroupId;
        $ctrl.selectedGroupingModules = result.selectedGroupingModules;
        $ctrl.selectedCourseUnits = result.selectedCourseUnits;
        $ctrl.selectedCourseUnitAttainmentsByGroupId = result.selectedCourseUnitAttainmentsByGroupId;
        $ctrl.parentModule = result.parentModule;
        $ctrl.gradeScalesById = result.gradeScalesById;
      }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
      $ctrl.customStudyDrafts = _.get($ctrl.application, 'customStudyDrafts');
    };
    $ctrl.isPendingApproval = function () {
      return $ctrl.application.state === 'REQUESTED' || $ctrl.application.state === 'IN_HANDLING';
    };
    $ctrl.isApproved = function () {
      return $ctrl.application.state === 'ACCEPTED' || $ctrl.application.state === 'CONDITIONAL';
    };
    $ctrl.isRejected = function () {
      return $ctrl.application.state === 'REJECTED';
    };
  }
})();