(function () {
  'use strict';

  angular.module('student.common.context.plan-context').factory('planPriority', planPriority);
  function planPriority() {
    return {
      firstPriority: function () {
        return [0];
      },
      isHigherPriorityThan: function (thisPriority, otherPriority) {
        if (!(thisPriority && otherPriority)) {
          return false;
        }
        var thisComparedToOther = 0;
        for (var i = 0; thisComparedToOther === 0 && i < thisPriority.length && i < otherPriority.length; i++) {
          thisComparedToOther = thisPriority[i] - otherPriority[i];
        }
        if (thisComparedToOther === 0) {
          thisComparedToOther = thisPriority.length - otherPriority.length;
        }
        // Lower number is higher priority, i.e. first priority is higher than second!
        return thisComparedToOther <= 0;
      },
      getLowerPriorityClass: function (priority) {
        var clone = _.clone(priority);
        clone[0] += 1000;
        return clone;
      },
      getNextPriority: function (priority) {
        var clone = _.clone(priority);
        clone[clone.length - 1]++;
        return clone;
      },
      getChildPriority: function (priority) {
        var clone = _.clone(priority);
        clone[clone.length] = 0;
        return clone;
      }
    };
  }
})();