<div>
  <div *ngIf="ready">
    <div class="input-col" [ngClass]="{'invalid-selection-editor' : invalid}">
      <sis-code-selection-editor
              [labelForId]="labelForId"
              [filterTranslations]="filterTranslations"
              [required]="required"
              [disabled]="disabled"
              [selectedCode]="selectedCode"
              [universityCodes]="universityCodes"
              [otherCodes]="otherCodes"
              [hideOtherCodes]="groupedCodes.hideOtherCodes"
              (onSelect)="selectCode($event)"
              [showAllLanguageVersions]="showAllLanguageVersions"
              [allowDeprecatedValueSelection]="allowDeprecatedValueSelection"
              [showLastPart]="showLastPart"
              [groupResults]="groupResults"
              [notFoundKey]="notFoundKey"
              [showNumericalCodeValue]="showNumericalCodeValue"
              [id]="id">
      </sis-code-selection-editor>
    </div>
  </div>
  <ul class="error-list">
    <li [hidden]="!showErrors || hasSelectionOrNotRequired()"
        translate>SIS_COMPONENTS.SELECT.CODE_MISSING</li>
  </ul>
</div>
