import angular from 'angular';
import { jsDataCacheHelperModule } from 'sis-components/service/jsDataCacheHelper.service';
import { locationModelModule } from 'sis-components/model/location.model';
export const locationServiceModule = 'sis-components.service.locationService';
angular.module(locationServiceModule, [jsDataCacheHelperModule, locationModelModule]).factory('locationService', ["jsDataCacheHelper", "locationJSDataModel", "$http", (jsDataCacheHelper, locationJSDataModel, $http) => ({
  findByIds(ids) {
    return jsDataCacheHelper.findByIds(locationJSDataModel, ids);
  },
  searchByString(fullTextQuery, universityOrgId) {
    return $http({
      url: '/kori/api/locations/search',
      method: 'GET',
      params: {
        fullTextQuery,
        universityOrgId
      }
    }).then(result => result.data);
  }
})]);