<div class="row">
  <div class="col-6">
    <h4 translate>STUDENT_APPLICATIONS.EXTENSION_APPLICATION.STUDIES_BY_STUDY_TERM</h4>
  </div>
  <div class="col-6 text-right">
    <h4 translate>STUDENT_APPLICATIONS.PLANNED_CREDITS</h4>
  </div>
</div>

<div class="row" *ngFor="let entry of plannedStudies?.studiesByStudyTerm">
  <div class="col-12">
    <hr>
    <sis-expandable [variant]="'naked'" [level]="5" [size]="'xs'">
      <ng-template sisExpandableCustomTitle>
        <span class="study-term-title">
          {{entry.studyTerm.name | localizedString}} {{getStudyTermYear(entry.studyTerm)}}
          <span *ngIf="isCurrentTerm(entry.studyTerm)">({{'STUDENT_APPLICATIONS.ONGOING' | translate}})</span>
          <span class="float-end">{{entry.plannedCredits | creditRange}}</span>
        </span>
      </ng-template>
      <ng-template sisExpandableContent>
        <div *ngIf="hasNoPlannedStudies(entry)" class="row">
          <div class="col-12">
            <p class="guidance" translate>STUDENT_APPLICATIONS.EXTENSION_APPLICATION.NO_PLANNED_STUDIES_FOR_TERM</p>
          </div>
        </div>
        <div *ngFor="let courseUnit of entry.courseUnits" class="row">
          <div class="col-8">
            <p>
              <span class="code">{{courseUnit.code}}</span> {{courseUnit.name | localizedString}}
              <sis-course-unit-timing-info-tooltip [studyTermLocators]="entry.courseUnitTimingInfo[courseUnit.id]"></sis-course-unit-timing-info-tooltip>
            </p>
          </div>
          <div class="col-4 text-right">
            <p>{{courseUnit.credits | creditRange}}</p>
          </div>
        </div>
        <div *ngFor="let customStudyDraft of entry.customStudyDrafts" class="row">
          <div class="col-8">
            <p>
              <span class="code" translate>STUDENT_APPLICATIONS.EXTENSION_APPLICATION.CUSTOM_STUDY_DRAFT</span>
              {{customStudyDraft.name}}
              <sis-course-unit-timing-info-tooltip [studyTermLocators]="entry.customStudyDraftTimingInfo[customStudyDraft.id]"
                                                   [isCustomStudyDraft]="true"></sis-course-unit-timing-info-tooltip>
            </p>
          </div>
          <div class="col-4 text-right">
            <p>{{customStudyDraft.credits}} {{'CREDITS' | translate}}</p>
          </div>
        </div>
      </ng-template>
    </sis-expandable>
  </div>
</div>
<div class="listing-summary">
  <h4 translate>STUDENT_APPLICATIONS.EXTENSION_APPLICATION.TOTAL_CREDITS</h4>
  <span class="credits">{{plannedStudies?.plannedCreditsTotal | creditRange}}</span>
</div>
