import studyProgressGraphSubModuleElementTpl from './studyProgressGraphSubModuleElement.tpl.html';
import studyProgressGraphModuleElementTpl from './studyProgressGraphModuleElement.tpl.html';
import Chart from 'chart.js';
'use strict';
angular.module('student.frontpage.studyProgressGraph').component('studyProgressGraphSubModuleElement', {
  template: studyProgressGraphSubModuleElementTpl,
  bindings: {
    type: '<',
    result: '<'
  },
  controller: 'studyProgressGraphModuleElementController'
}).component('studyProgressGraphModuleElement', {
  transclude: true,
  template: studyProgressGraphModuleElementTpl,
  bindings: {
    type: '<',
    result: '<',
    name: '@'
  },
  controller: 'studyProgressGraphModuleElementController'
}).controller('studyProgressGraphModuleElementController', ["$element", "studyProgressGraphConfig", function ($element, studyProgressGraphConfig) {
  var $ctrl = this;
  $ctrl.$onInit = function () {
    var canvasElement = $element.find('canvas');
    var chartOptions = angular.copy(studyProgressGraphConfig.chartjs.OPTIONS);
    if ($ctrl.type === 'parent') {
      // horse shoe color is different in parent and sub, hackish way to change it..
      chartOptions.data.datasets[0].backgroundColor = ["rgba(255,255,255,1)", "rgba(255,255,255,0.3)", "rgba(0,0,0,0)"];
    }
    chartOptions.data.datasets[0].data[0] = _.get($ctrl, 'result.percentage', 0);
    chartOptions.data.datasets[0].data[1] = 100 - _.get($ctrl, 'result.percentage', 0);
    new Chart(canvasElement, chartOptions);
  };
}]);