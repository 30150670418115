<div class="checkbox checkbox-toggle sis-toggle" [class.inverted]="inverted">
  <label>
    <input type="checkbox"
           [name]="name"
           [disabled]="disabled"
           [required]="required"
           [sisAutofocus]="autofocus"
           [attr.aria-describedby]="ariaDescribedBy"
           [ngModel]="checked"
           (ngModelChange)="checkedChange.emit($event)"><!--
    --><span *ngIf="label">{{label}}</span>
  </label>
</div>

