<ng-container *transloco="let t">
  <div class="sis-mb-xs">
    <sis-contextual-notification [variant]="'light'" *ngIf="!selectedModuleAttainment
              && plan?.primary
              && !!degreeProgrammeAttainmentWorkflow">
      {{t('MODULE_INFO.GRADUATION.GRADUATION_REQUESTED', {degreeProgrammeName: module.name | localizedString})}}.
      <a id="navigate-to-dpa-application-graduation-section"
         [uiSref]="'student.logged-in.profile.applications.degree-programme-attainment-workflow'"
         [uiParams]="{applicationId: degreeProgrammeAttainmentWorkflow.id}"
         (click)="closeModal.emit()">
        {{t('MODULE_INFO.GRADUATION.NAVIGATE_TO_DPA_APPLICATION')}}
      </a>
    </sis-contextual-notification>
  </div>
  <app-graduation-instructions-url [organisationId]="organisationId">
  </app-graduation-instructions-url>
  <ng-container *ngIf="!selectedModuleAttainment">
    <p class="guidance">{{t('MODULE_INFO.GRADUATION.GUIDANCE')}}</p>
    <sis-button *ngIf="plan?.primary && !degreeProgrammeAttainmentWorkflow"
                [size]="'lg'"
                [variant]="'primary'"
                [id]="'navigate-to-dpa-application-creation'"
                (clicked)="navigateToDegreeProgrammeAttainmentWorkflowCreation()"
                [type]="'button'">
      {{t('MODULE_INFO.GRADUATION.NAVIGATE_TO_APPLICATION_CREATION')}}
    </sis-button>
  </ng-container>
</ng-container>

