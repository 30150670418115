import { AfterViewInit, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-pagination',
    templateUrl: './pagination.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class PaginationComponent implements AfterViewInit {

    constructor(private translate: TranslateService) {}

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sisComponents.pagination.pagination',
        directiveName: 'sisPagination',
    };

    @Input() currentPage = 1;
    @Output() currentPageChange: EventEmitter<number> = new EventEmitter<number>();
    @Input() disabled = false;
    @Input() itemsPerPage = 10;
    @Input() maxSize = 5;
    @Input() totalItems: number;

    ngAfterViewInit() {
        const component = document?.querySelector('ngb-pagination');
        const next = component?.querySelector(('[aria-label="Next"]'));
        const prev = component?.querySelector(('[aria-label="Previous"]'));
        if (next) next.setAttribute('aria-label', this.translate.instant('ARIA_LABEL.NEXT'));
        if (prev) prev.setAttribute('aria-label', this.translate.instant('ARIA_LABEL.PREVIOUS'));
    }
}
