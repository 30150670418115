timelineDialogService.$inject = ["modalService"];
import angular from 'angular';
import { ModalService } from 'sis-common/modal/modal.service.ts';
import { RemoveAllDialogComponent } from './removeAllDialog-ng.component.ts';
import { removeAllDialogModule } from './removeAllDialog.component';
angular.module('student.timing.controls.timelineDialogService', [removeAllDialogModule, ModalService.downgrade.moduleName]).factory('timelineDialogService', timelineDialogService);
function timelineDialogService(modalService) {
  return {
    openRemoveAllDialog: (state, validatablePlan, validatablePlanConstructor, module) => modalService.open(RemoveAllDialogComponent, {
      module,
      state,
      validatablePlan,
      validatablePlanConstructor
    }, {
      size: 'sm'
    }).result
  };
}