import angular from 'angular';
import moment from 'moment';
import { commonStudyEventServiceModule } from 'sis-components/service/studyEvent.service';
import { studyEventJSDataModelModule } from 'sis-components/model/studyEvent.model';
export const studentStudyEventServiceModule = 'student.common.service.studyEventService';
(function () {
  studyEventService.$inject = ["$http", "studyEventJSDataModel", "commonStudyEventService"];
  angular.module(studentStudyEventServiceModule, [commonStudyEventServiceModule, studyEventJSDataModelModule]).factory('studyEventService', studyEventService);

  /**
   * @ngInject
   */
  function studyEventService($http, studyEventJSDataModel, commonStudyEventService) {
    const ownStudyEventUri = '/ilmo/api/my-study-events';
    return {
      findByIds: ids => commonStudyEventService.findByIds(ids),
      /**
       * Returns all study events if both arguments are omitted.
       * @param startDate, optional
       * @param endDate, optional
       * @returns array of study events
       */
      findMyStudyEvents(startDate, endDate) {
        const formatDate = date => date ? moment(date).format('YYYY-MM-DD') : undefined;
        const params = {
          startDate: formatDate(startDate),
          endDate: formatDate(endDate)
        };
        return $http.get(ownStudyEventUri, {
          params
        }).then(result => studyEventJSDataModel.inject(result.data));
      },
      getIrregularEventsForStudent(studyEvents) {
        return commonStudyEventService.getIrregularEventsForStudent(studyEvents);
      },
      orderStudyEventsByTime(studyEvents) {
        return commonStudyEventService.orderStudyEventsByTime(studyEvents);
      },
      findStudyEventsForCourseUnitRealisation(courseUnitRealisation) {
        return commonStudyEventService.findStudyEventsForCourseUnitRealisation(courseUnitRealisation);
      },
      findAllLocationsForStudyEvents(studyEvents) {
        return commonStudyEventService.findAllLocationsForStudyEvents(studyEvents);
      },
      loadLocationsForStudyEventsAndEvents(studyEvents) {
        return commonStudyEventService.loadLocationsForStudyEventsAndEvents(studyEvents);
      }
    };
  }
})();