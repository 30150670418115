import { Component, Input, ViewEncapsulation } from '@angular/core';
import { OpenUniversityCart, OtmId } from 'common-typescript/types';
import { EMPTY, Observable, of } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { OpenUniversityCartCustomerService } from 'sis-components/service/open-university-cart-customer.service';

import { OpenUniversityModalService } from '../../common/service/open-university-modal.service';
import { OpenUniversityErrorHelperService } from '../../service/open-university-error-helper.service';

@Component({
    selector: 'app-add-to-cart-button',
    templateUrl: './add-to-cart-button.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class AddToCartButtonComponent {

    @Input() courseUnitId: OtmId;
    @Input() productId: OtmId;
    @Input() isProductInCart: boolean;

    requestInProgress: boolean;

    constructor(
        private openUniversityCartService: OpenUniversityCartCustomerService,
        private openUniversityErrorHelper: OpenUniversityErrorHelperService,
        private openUniversityModalService: OpenUniversityModalService,
        private appErrorHandler: AppErrorHandler,
    ) {}

    addToCart(): void {
        this.requestInProgress = true;

        this.validateNoPaymentInProgress()
            .pipe(
                switchMap(() => this.openUniversityCartService.isProductFromCourseUnitInCurrentCart(this.courseUnitId)),
                take(1),
                switchMap(isInCart => isInCart ? this.replaceProductInCart() : this.addProductToCart()),
                take(1),
                this.appErrorHandler.defaultErrorHandler(),
            )
            .subscribe({
                next: cart => this.openUniversityModalService.openProductAddedToCartModal(cart, this.productId, this.courseUnitId),
            })
            .add(() => this.requestInProgress = false);
    }

    removeFromCart(): void {
        this.requestInProgress = true;

        this.validateNoPaymentInProgress()
            .pipe(
                switchMap(() => this.openUniversityModalService.openRemoveProductFromCartConfirmation(this.courseUnitId)),
                switchMap(() => this.openUniversityCartService.removeProductFromCurrentCart(this.productId)),
                take(1),
                this.appErrorHandler.defaultErrorHandler(),
            )
            .subscribe()
            .add(() => this.requestInProgress = false);
    }

    private addProductToCart(): Observable<OpenUniversityCart> {
        return this.openUniversityCartService.addProductsToCurrentCart(this.productId);
    }

    private replaceProductInCart(): Observable<OpenUniversityCart> {
        // The cart already has another product from the same course unit; ask if the user wants to replace it.
        // Currently, this can't be done atomically; the old product needs to be removed and then the new one added.
        return this.openUniversityModalService.openProductReplacementConfirmation()
            .pipe(
                switchMap(() => this.openUniversityCartService.removeCourseUnitFromCurrentCart(this.courseUnitId)),
                take(1),
                switchMap(() => this.openUniversityCartService.addProductsToCurrentCart(this.productId)),
            );
    }

    validateNoPaymentInProgress(): Observable<void | never> {
        return this.openUniversityCartService.isPaymentInProgress()
            .pipe(
                take(1),
                tap(isPaymentInProgress => isPaymentInProgress && this.openUniversityErrorHelper.showPaymentInProgressFailureAlert()),
                switchMap(isPaymentInProgress => isPaymentInProgress ? EMPTY : of(null)),
            );
    }
}
