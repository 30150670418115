<ng-container *ngIf="data$ | async as data">
  <div *ngIf="messageBannerVisible(data)" class="message-banner">
    <div class="container">
      <markdown [data]="getLocalizedBannerContent(data.content)" class="message-content"></markdown>
      <div *ngIf="data.info | localizedString" class="message-banner-additional-info">
        <sis-tooltip
          [tooltipText]="'UNAVAILABLE.ADDITIONAL_INFO_TOOLTIP' | translate"
          [tooltipBlockElement]="true"
        >
          <button
            type="button"
            class="link-button ng-scope sis-tooltip-trigger-content"
            (click)="openInfoDialog(data.info)"
          >
            <sis-icon icon="info-circle" color="primary"></sis-icon>
            <span translate>UNAVAILABLE.ADDITIONAL_INFO</span>
          </button>
        </sis-tooltip>
      </div>
    </div>
    <sis-tooltip
      [tooltipText]="'BUTTON.CLOSE' | translate"
      [tooltipBlockElement]="true"
    >
      <button
        class="non-button ng-scope sis-tooltip-trigger-content"
        [attr.aria-label]="'BUTTON.CLOSE' | translate"
        (click)="closeMessageBanner(data.id)"
      >
        <sis-icon icon="ring-close"></sis-icon>
      </button>
    </sis-tooltip>
  </div>
</ng-container>
