import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SisCommonModule } from 'sis-common/sis-common.module';

import { SisComponentsHybridModule } from '../../../sis-components.module.hybrid';

import { StudyRightStatusBadgeComponent } from './study-right-status-badge.component';

@NgModule({
    declarations: [
        StudyRightStatusBadgeComponent,
    ],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        SisCommonModule,
        SisComponentsHybridModule,
    ],
    exports: [
        StudyRightStatusBadgeComponent,
    ],
})
export class StudyRightStatusBadgeModule {
}
