'use strict';

(function () {
  studentProfileService.$inject = ["$http", "personNameService"];
  angular.module('student.profile').service('studentProfileService', studentProfileService);

  /**
   * @ngInject
   */
  function studentProfileService($http, personNameService) {
    const enrichWithName = function (response) {
      const personObject = _.get(response, 'data');
      personObject.fullName = personNameService.getFullName(personObject);
      personObject.fullNameReversed = personNameService.getFullNameReversed(personObject);
      return personObject;
    };
    return {
      profile: function () {
        return $http.get('/ori/api/user-details').then(enrichWithName);
      },
      saveLimitedStudentBasicInfo: function (profile) {
        return $http.put('/ori/api/user-details', profile).then(enrichWithName);
      }
    };
  }
})();