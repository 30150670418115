import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageBannerData } from 'common-typescript/types';
import { ConfigService } from 'sis-common/config/config.service';
import { LocalStorageService } from 'sis-common/storage/local-storage.service';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

export const DEFAULT_URL = '/unavailable/api/message-banner-data';
const LOCAL_STORAGE_KEY = 'messageBanner.closedId';

@Injectable({
    providedIn: 'root',
})
@StaticMembers<DowngradedService>()
export class MessageBannerService {
    static downgrade: ServiceDowngradeMappings = {
        moduleName: 'sis-components.service.messageBannerService',
        serviceName: 'messageBannerService',
    };

    private readonly dataUrl: string;

    constructor(
        private http: HttpClient,
        private configService: ConfigService,
        private localStorageService: LocalStorageService,
    ) {
        const universityConfig = this.configService.get();
        this.dataUrl = universityConfig.messageBannerDataUrl || DEFAULT_URL;
    }

    loadMessageBannerData() {
        return this.http.get<MessageBannerData>(this.dataUrl);
    }

    bannerHasBeenClosed(id: string) {
        return this.localStorageService.getItem(LOCAL_STORAGE_KEY) === id;
    }

    markBannerAsClosed(id: string) {
        this.localStorageService.setItem(LOCAL_STORAGE_KEY, id);
    }
}
