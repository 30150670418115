import { Injectable } from '@angular/core';
import {
    CourseUnit,
    CustomCourseUnitAttainment,
    CustomModuleAttainment,
    CustomStudyDraft,
    EntityWithRule,
    LocalId,
    Module,
    OtmId,
} from 'common-typescript/types';
import { Subject } from 'rxjs';

import { PlanOperation, PlanOperationType } from '../plan-manager/plan-manager.service';

import { PlanActionsService, UiOperation, UiOperationType } from './plan-actions.service';

@Injectable()
export class GeneralPlanActionsService implements PlanActionsService {

    public planOperationSubject: Subject<PlanOperation>;
    public uiOperationSubject: Subject<UiOperation>;

    constructor() {
        this.planOperationSubject = new Subject<PlanOperation>();
        this.uiOperationSubject = new Subject<UiOperation>();
    }

    openCustomStudyDraftCreationModal(parentModule: EntityWithRule): void {
        this.uiOperationSubject.next({
            uiOperationType: UiOperationType.OPEN_CUSTOM_STUDY_DRAFT_CREATION_MODAL,
            target: parentModule,
        });
    }

    openCustomStudyDraftInfoModal(customStudyDraft: CustomStudyDraft) {
        this.uiOperationSubject.next({
            uiOperationType: UiOperationType.OPEN_CUSTOM_STUDY_DRAFT_INFO_MODAL,
            target: customStudyDraft,
        });
    }

    openCustomCourseUnitAttainmentInfoModal(customCourseUnitAttainment: CustomCourseUnitAttainment) {
        this.uiOperationSubject.next({
            uiOperationType: UiOperationType.OPEN_CUSTOM_ATTAINMENT_INFO_MODAL,
            target: customCourseUnitAttainment,
        });
    }

    openCourseUnitInfoModal(courseUnit: CourseUnit) {
        this.uiOperationSubject.next({
            uiOperationType: UiOperationType.OPEN_COURSE_UNIT_INFO_MODAL,
            target: courseUnit,
        });
    }

    openModule(module: EntityWithRule) {
        this.uiOperationSubject.next({
            uiOperationType: UiOperationType.OPEN_MODULE,
            target: module,
        });
    }

    closeAndSave() {
        this.uiOperationSubject.next({
            uiOperationType: UiOperationType.CLOSE_AND_SAVE,
            target: null,
        });
    }

    selectCourseUnit(courseUnit: CourseUnit, parentModule: EntityWithRule) {
        this.planOperationSubject.next({
            parentModule,
            planOperationType: PlanOperationType.SELECT_COURSE_UNIT,
            target: courseUnit,
            parentCourseUnit: null,
        });
    }

    forceSelectCourseUnitById(courseUnitId: OtmId, parentModule: EntityWithRule) {
        this.planOperationSubject.next({
            parentModule,
            planOperationType: PlanOperationType.FORCE_SELECT_COURSE_UNIT,
            target: courseUnitId,
            parentCourseUnit: null,
        });
    }

    selectCourseUnitByGroupId(courseUnitGroupId: OtmId, parentModule: EntityWithRule) {
        this.planOperationSubject.next({
            parentModule,
            planOperationType: PlanOperationType.SELECT_COURSE_UNIT_BY_GROUP_ID,
            target: courseUnitGroupId,
            parentCourseUnit: null,
        });
    }

    removeCourseUnit(courseUnit: CourseUnit, parentModule: EntityWithRule) {
        this.planOperationSubject.next({
            parentModule,
            planOperationType: PlanOperationType.UNSELECT_COURSE_UNIT,
            target: courseUnit,
            parentCourseUnit: null,
        });
    }

    forceRemoveCourseUnit(courseUnit: CourseUnit, parentModule: EntityWithRule) {
        this.planOperationSubject.next({
            parentModule,
            planOperationType: PlanOperationType.FORCE_REMOVE_COURSE_UNIT,
            target: courseUnit,
            parentCourseUnit: null,
        });
    }

    moveCourseUnit(courseUnit: CourseUnit, parentModule: EntityWithRule) {
        this.planOperationSubject.next({
            parentModule,
            planOperationType: PlanOperationType.MOVE_COURSE_UNIT,
            target: courseUnit,
            parentCourseUnit: null,
        });
    }

    selectModule(module: EntityWithRule, parentModule: EntityWithRule) {
        this.planOperationSubject.next({
            parentModule,
            planOperationType: PlanOperationType.SELECT_MODULE,
            target: module,
            parentCourseUnit: null,
        });
    }

    forceSelectModuleById(moduleId: OtmId, parentModule: EntityWithRule) {
        this.planOperationSubject.next({
            parentModule,
            planOperationType: PlanOperationType.FORCE_SELECT_MODULE,
            target: moduleId,
            parentCourseUnit: null,
        });
    }

    selectModuleByGroupId(moduleGroupId: OtmId, parentModule: EntityWithRule) {
        this.planOperationSubject.next({
            parentModule,
            planOperationType: PlanOperationType.SELECT_MODULE_BY_GROUP_ID,
            target: moduleGroupId,
            parentCourseUnit: null,
        });
    }

    removeModule(module: EntityWithRule, parentModule: EntityWithRule) {
        this.planOperationSubject.next({
            parentModule,
            planOperationType: PlanOperationType.UNSELECT_MODULE,
            target: module,
            parentCourseUnit: null,
        });
    }

    forceRemoveModule(module: Module, parentModule: EntityWithRule) {
        this.planOperationSubject.next({
            parentModule,
            planOperationType: PlanOperationType.FORCE_REMOVE_MODULE,
            target: module,
            parentCourseUnit: null,
        });
    }

    selectCustomCourseUnitAttainment(customCourseUnitAttainment: CustomCourseUnitAttainment, parentModule: EntityWithRule) {
        this.planOperationSubject.next({
            parentModule,
            planOperationType: PlanOperationType.FORCE_SELECT_CUSTOM_COURSE_UNIT_ATTAINMENT,
            target: customCourseUnitAttainment,
            parentCourseUnit: null,
        });
    }

    removeCustomCourseUnitAttainmentById(customCourseUnitAttainmentId: OtmId, parentModule: EntityWithRule) {
        this.planOperationSubject.next({
            parentModule,
            planOperationType: PlanOperationType.UNSELECT_CUSTOM_COURSE_UNIT_ATTAINMENT_BY_ID,
            target: customCourseUnitAttainmentId,
            parentCourseUnit: null,
        });
    }

    selectCustomModuleAttainment(customModuleAttainment: CustomModuleAttainment, parentModule: EntityWithRule) {
        this.planOperationSubject.next({
            parentModule,
            planOperationType: PlanOperationType.FORCE_SELECT_CUSTOM_MODULE_ATTAINMENT,
            target: customModuleAttainment,
            parentCourseUnit: null,
        });
    }

    removeCustomModuleAttainmentById(customModuleAttainmentId: OtmId, parentModule: EntityWithRule) {
        this.planOperationSubject.next({
            parentModule,
            planOperationType: PlanOperationType.UNSELECT_CUSTOM_MODULE_ATTAINMENT_BY_ID,
            target: customModuleAttainmentId,
            parentCourseUnit: null,
        });
    }

    addCustomStudyDraft(customStudyDraft: CustomStudyDraft, parentModule: EntityWithRule): void {
        this.planOperationSubject.next({
            parentModule,
            planOperationType: PlanOperationType.ADD_CUSTOM_STUDY_DRAFT,
            target: customStudyDraft,
            parentCourseUnit: null,
        });
    }

    removeCustomStudyDraft(customStudyDraft: CustomStudyDraft, parentModule: EntityWithRule) {
        this.planOperationSubject.next({
            parentModule,
            planOperationType: PlanOperationType.UNSELECT_CUSTOM_STUDY_DRAFT_BY_ID,
            target: customStudyDraft.id,
            parentCourseUnit: null,
        });
    }

    selectCompletionMethod(courseUnit: CourseUnit, completionMethodId: LocalId) {
        this.planOperationSubject.next({
            parentModule: null,
            planOperationType: PlanOperationType.SELECT_COMPLETION_METHOD,
            target: completionMethodId,
            parentCourseUnit: courseUnit,
        });
    }

    addAssessmentItemSelection(courseUnit: CourseUnit, assessmentItemId: LocalId) {
        this.planOperationSubject.next({
            parentModule: null,
            planOperationType: PlanOperationType.ADD_ASSESSMENT_ITEM_SELECTION,
            target: assessmentItemId,
            parentCourseUnit: courseUnit,
        });
    }

    removeAssessmentItemSelection(courseUnit: CourseUnit, assessmentItemId: LocalId) {
        this.planOperationSubject.next({
            parentModule: null,
            planOperationType: PlanOperationType.REMOVE_ASSESSMENT_ITEM_SELECTION,
            target: assessmentItemId,
            parentCourseUnit: courseUnit,
        });
    }

    changeCourseUnitVersion(oldCourseUnitVersion: CourseUnit, newCourseUnitVersion: CourseUnit) {
        this.planOperationSubject.next({
            oldCourseUnitVersion,
            newCourseUnitVersion,
            parentModule: null,
            planOperationType: PlanOperationType.CHANGE_COURSE_UNIT_VERSION,
            target: null,
            parentCourseUnit: null,
        });
    }

}
