import { Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { Attainment, CompletionMethod, CourseUnit, CourseUnitRealisation, Module, Plan } from 'common-typescript/types';

/**
 * @deprecated
 */
// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'completion-methods' })
export class CompletionMethodsDirective extends UpgradeComponent {
    @Input() plan: Plan;
    @Input() attainments: Attainment[];
    @Input() courseUnit: CourseUnit;
    @Input() completionMethods: CompletionMethod[];
    @Input() courseUnitRealisation: CourseUnitRealisation;
    @Input() forceEnableCalendarAdd: boolean;
    @Input() language: string;
    @Input() level: number;
    @Input() versionChangeInProgress: boolean;
    @Input() unplannedCourseUnit: CourseUnit;
    @Input() module: Module;
    @Input() realisationTabId: number;

    constructor(elementRef: ElementRef, injector: Injector) {
        super('completionMethods', elementRef, injector);
    }
}
