<div *ngIf="!loading" class="container sis-pt-md">
  <div class="row justify-content-center">
    <div class="col-md-10 col-sm-12 form-group">
      <sis-page-title [title]="'OPEN_UNIVERSITY.ENROLMENT_WIZARD.SELECT_TEACHING_AND_ENROLMENT' | translate"
                      [dataCy]="'wizard-page-title'"/>
      <transloco class="text-area-static" key="OPEN_UNIVERSITY.ENROLMENT_WIZARD.SELECT_TEACHING_GUIDANCE" [params]="{courseUnit: courseUnit.name | localizedString}"/>

      <sis-wizard-progress [stepKeys]="wizardProgressStepKeys" [currentStep]="currentStep"></sis-wizard-progress>

      <div id="currentStepName-heading">
        <h2 tabindex="-1" data-cy="enrolment-wizard-step-header" #currentStepNameFocus>{{ currentStepName }}</h2>
        <p *ngIf="!isFinalStep()" class="guidance">{{ 'OPEN_UNIVERSITY.ENROLMENT_WIZARD.SELECT_TEACHING_CUR_GUIDANCE' | translate }}</p>
        <p *ngIf="isFinalStep()" class="guidance">{{ 'OPEN_UNIVERSITY.ENROLMENT_WIZARD.SELECT_TEACHING_CUR_GUIDANCE_FINAL_STEP' | translate }}</p>
      </div>

      <form>
        <div *ngFor="let assessmentItem of assessmentItemsData; let index = index">
            <app-open-university-cur-selection-step *ngIf="index === currentStep"
                                                    [form]="form.at(index)"
                                                    [assessmentItem]="assessmentItem"
                                                    [curSeats]="curSeats"
                                                    [courseUnit]="courseUnit"
                                                    [showErrors]="showErrors"
                                                    (selectedCurValueChange)="updateForm($event)"
            >
            </app-open-university-cur-selection-step>
        </div>
        <app-open-university-cur-selection-final-step
                *ngIf="isFinalStep()"
                [assessmentItemsData]="assessmentItemsData"
                [openUniStudentEnrolments]="openUniStudentEnrolments"
                (navigateBack)="navigateBackInView($event)">
        </app-open-university-cur-selection-final-step>
        <sis-wizard-step-buttons
              [ngClass]="'form-group'"
              [backButtonTitle]="'BUTTON.PREVIOUS' | translate"
              [backButtonDisabled]="saving"
              [backButtonDisplay]="this.currentStep > 0"
              [forwardButtonTitle]="forwardButtonTitle() | translate"
              [forwardButtonDisabled]="saving"
              [forwardButtonDisplay]="true"
              (back)="back()"
              (forward)="forward()"
              >
         </sis-wizard-step-buttons>
      </form>
    </div>
  </div>
</div>
