<div class="modal-header">
  <h2 class="modal-title">{{'OPENUNIVERSITY.STUDY_RIGHTS.ENROLMENT_RIGHT_CANCEL_MODAL.TITLE' | translate}}</h2>
</div>
<form (ngSubmit)="onSubmit()">
  <div class="modal-body" *ngIf="values?.enrolmentRight">
    <div class="form-group">
      <transloco [content]="'OPENUNIVERSITY.STUDY_RIGHTS.ENROLMENT_RIGHT_CANCEL_MODAL.CANCEL_DESCRIPTION' | translate:{
              studentName: values.enrolmentRight.personId | personInfo | async | personFullName,
              courseUnitName: values.enrolmentRight.courseUnitId | courseUnit | async | localizeProperty:'name',
              productName: values.enrolmentRight?.openUniversityProductId ?
                (values.enrolmentRight?.openUniversityProductId | openUniversityProduct | async | localizeProperty:'name') : '-'
            }" />
    </div>
    <div class="form-group">
      <sis-text-field [label]="'OPENUNIVERSITY.STUDY_RIGHTS.ENROLMENT_RIGHT_CANCEL_MODAL.CANCEL_REASON' | translate"
                      [control]="cancelReason"
                      [textarea]="true">
      </sis-text-field>
    </div>
    <div class="form-group">
      <sis-local-date-editor [label]="'OPENUNIVERSITY.STUDY_RIGHTS.ENROLMENT_RIGHT_CANCEL_MODAL.CANCEL_DATE' | translate"
                             [name]="'cancel-date-input'"
                             [control]="cancelDate"
                             [maxDate]="dateEditorMaxDate">
      </sis-local-date-editor>
    </div>
    <div class="form-group">
      <span>{{'OPENUNIVERSITY.STUDY_RIGHTS.ENROLMENT_RIGHT_CANCEL_MODAL.CANCEL_CONFIRMATION' | translate}}</span>
    </div>
  </div>
  <div class="modal-footer">
    <sis-button [hollow]="true" (clicked)="onCancel()">{{'SIS_COMPONENTS.BUTTON.CANCEL' | translate}}</sis-button>
    <sis-button type="submit">{{'SIS_COMPONENTS.BUTTON.YES' | translate}}</sis-button>
  </div>
</form>
