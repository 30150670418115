import {
    TokenizeResult,
    TranslationMarkupRenderer,
    TranslationMarkupRendererFactory,
    TranslationMarkupTranspiler,
    TranslationMarkupTranspilerContext,
    TranspileResult,
} from 'ngx-transloco-markup';

import { htmlElementTokenizer } from './html-element-tokenizer';

export interface TokenizationSpec {
    search: string;
    token: unknown;
}

interface HtmlElementTranspilerConfig {
    rendererFactory: TranslationMarkupRendererFactory;
    start: TokenizationSpec;
    end: TokenizationSpec;
    elementTag: string;
}

/**
 * Generalized transpiler for HTML tags. Use this to create transpilers for simple HTML tags
 * concisely. Does not handle attributes or void elements (i.e., elements without a closing tag).
 */
export class HtmlElementTranspiler implements TranslationMarkupTranspiler {
    private config: HtmlElementTranspilerConfig;

    constructor(config: HtmlElementTranspilerConfig) {
        this.config = config;
    }

    tokenize(translation: string, offset: number): TokenizeResult | undefined {
        return htmlElementTokenizer(
            translation,
            offset,
            this.config.start,
            this.config.end,
        );
    }

    transpile(offset: number, context: TranslationMarkupTranspilerContext): TranspileResult | undefined {
        const nextToken = context.tokens[offset];

        if (![this.config.start.token, this.config.end.token].includes(nextToken)) {
            return undefined;
        }

        const { nextOffset, renderers } = context.transpileUntil(
            offset + 1,
            (token) => token === this.config.end.token,
        );

        return {
            nextOffset: Math.min(nextOffset + 1, context.tokens.length),
            renderer: this.createRenderer(renderers),
        };
    }

    private createRenderer(childRenderers: TranslationMarkupRenderer[]): TranslationMarkupRenderer {
        return this.config.rendererFactory.createElementRenderer(
            this.config.elementTag,
            childRenderers,
        );
    }
}
