import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from 'sis-common/modal/modal.service';

/**
 * Information dialog list content values.
 */
export interface InfoDialogListValues {
    /** List description text, shown in a paragraph before the list. */
    description: string;
    /** List items. */
    items: string[];
    /** Optional translation key for the text to show when the list is initially truncated and the user has clicked the button to show all items.
        If not given, a generic "showing all" text is shown. */
    showingAllItemsText?: string;
}

/**
 * Information dialog content values.
 */
export interface InfoDialogValues {
    /** Description texts, each shown in their own paragraph. Can contain HTML. */
    descriptions?: string[];
    /** List values with a related description text. */
    list?: InfoDialogListValues;
    /** Dialog title, shown as a heading. */
    title: string;
}

/**
 * An information dialog modal. The modal has no buttons in the footer but a close 'X' button in the top right corner.
 *
 * @see {@link InfoDialogService}
 */
@Component({
    selector: 'sis-info-dialog',
    templateUrl: './info-dialog.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class InfoDialogComponent {
    constructor(@Inject(ModalService.injectionToken) public values: InfoDialogValues,
                public modalInstance: NgbActiveModal) {}
}
