<sis-tooltip
        [tooltipText]="note.text"
        [appendToBody]="true">
  <div class="note-box sis-tooltip-trigger-content"
       [ngClass]="{
          first: isFirst(),
          middle: isMiddle(),
          last: isLast()
      }">
    <div class="box-content" [ngClass]="{first: isFirst()}">
      <div *ngIf="isFirst()" class="box-top">
        {{note.text}}
      </div>
      <div *ngIf="isLast() || active" class="box-bottom">
        <a *ngIf="active" href="javascript:void(0)" type="button"><sis-icon icon="minus-ring-fill"></sis-icon></a>
        <sis-menu-button *ngIf="!active" [options]="menuItems"></sis-menu-button>
      </div>
    </div>
  </div>
</sis-tooltip>
