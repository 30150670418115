import { InjectionToken, Provider } from '@angular/core';
import { MassExamSession } from 'common-typescript/types';
import { DS, DSResourceDefinition } from 'js-data';

import { JS_DATA_STORE } from '../ajs-upgraded-modules';

export function massExamSessionModelFactory(dataStore: DS): DSResourceDefinition<MassExamSession> {
    return dataStore.defineResource({
        idAttribute: 'id',
        name: 'massExamSession',
        endpoint: '/kori/api/mass-exam-sessions',
    });
}

export const MASS_EXAM_SESSION_MODEL = new InjectionToken<DSResourceDefinition<MassExamSession>>('massExamSessionModel');
export const massExamSessionModelProvider: Provider = {
    provide: MASS_EXAM_SESSION_MODEL,
    useFactory: massExamSessionModelFactory,
    deps: [JS_DATA_STORE],
};
