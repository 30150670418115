import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from 'sis-common/modal/modal.service';

export interface CalendarSettingsValues {
    forTeacher: boolean;
}

@Component({
    selector: 'sis-calendar-settings-modal',
    template: '<calendar-settings-modal (dismiss)="activeModal.dismiss()" [resolve]="values"></calendar-settings-modal>',
    encapsulation: ViewEncapsulation.None,
})
export class CalendarSettingsModalComponent {
    constructor(@Inject(ModalService.injectionToken) public values: CalendarSettingsValues,
                public activeModal: NgbActiveModal) {}
}
