import _ from 'lodash';
import angular from 'angular';
import moment from 'moment';
import { l10nModule } from 'sis-common/l10n/l10n.module';
import { formErrorTranslateFilterModule } from 'sis-common/l10n/formErrorTranslateFilter';
import { localDateRangeFilterModule } from '../date/filters/localDateRange/localDateRange.filter';
import { localDateFormatFilterModule } from '../date/filters/localDateFormat/localDateFormat.filter';
import { salesPeriodFilterModule } from '../date/filters/salesPeriod/salesPeriod.filter';
import { creditRangeFilterModule } from '../number/creditRange.filter';
import { personNameServiceModule } from '../service/personName.service';
import { localizedStringModule } from '../string/localizedString.dir';
export const searchParameterModelsModule = 'sis-components.search.searchParameterModels';
(function () {
  booleanParameter.$inject = ["Parameter"];
  studyTermLocatorParameter.$inject = ["Parameter", "ValidityPeriodParameter"];
  validityPeriodParameter.$inject = ["Parameter", "localizedStringFilter"];
  rangeParameter.$inject = ["creditRangeFilter", "Parameter"];
  dateParameter.$inject = ["localDateFormatFilter", "Parameter"];
  salesPeriodParameter.$inject = ["localDateRangeFilter", "Parameter"];
  dateRangeParameter.$inject = ["localDateRangeFilter", "Parameter"];
  stringParameter.$inject = ["Parameter"];
  personParameter.$inject = ["Parameter", "personNameService"];
  urnObjectParameter.$inject = ["Parameter", "localizedStringFilter"];
  jsHierarchicalDataObjectParameter.$inject = ["Parameter", "localizedStringFilter"];
  jsDataObjectWithGroupIdParameter.$inject = ["Parameter", "JsDataObjectParameter"];
  jsDataObjectParameter.$inject = ["Parameter", "localizedStringFilter"];
  parameter.$inject = ["$translate"];
  andParameter.$inject = ["SearchParameter"];
  orParameter.$inject = ["SearchParameter"];
  angular.module(searchParameterModelsModule, [l10nModule, formErrorTranslateFilterModule, localDateRangeFilterModule, localizedStringModule, salesPeriodFilterModule, creditRangeFilterModule, personNameServiceModule, localDateFormatFilterModule]).factory('SearchParameter', searchParameter).factory('OrParameter', orParameter).factory('AndParameter', andParameter).factory('HiddenParameter', hiddenParameter).factory('Parameter', parameter).factory('JsDataObjectParameter', jsDataObjectParameter).factory('JsDataObjectWithGroupIdParameter', jsDataObjectWithGroupIdParameter).factory('JsHierarchicalDataObjectParameter', jsHierarchicalDataObjectParameter).factory('UrnObjectParameter', urnObjectParameter).factory('PersonParameter', personParameter).factory('StringParameter', stringParameter).factory('DateRangeParameter', dateRangeParameter).factory('SalesPeriodParameter', salesPeriodParameter).factory('DateParameter', dateParameter).factory('RangeParameter', rangeParameter).factory('ValidityPeriodParameter', validityPeriodParameter).factory('StudyTermLocatorParameter', studyTermLocatorParameter).factory('BooleanParameter', booleanParameter);

  /**
   * @ngInject
   * @description
   * Used to test if parameter should be shown for user.
   * AndParameter and OrParameter are instances of SearchParameter.
   */
  function searchParameter() {
    function SearchParameter() {
      return this;
    }
    return SearchParameter;
  }

  /**
   * @ngInject
   * @description
   * Used to mark parameters in UI that are combined using OR in search.
   */
  function orParameter(SearchParameter) {
    function OrParameter() {
      return this;
    }
    OrParameter.prototype = new SearchParameter();
    OrParameter.prototype.constructor = OrParameter;
    return OrParameter;
  }

  /**
   * @ngInject
   * @description
   * Used to mark parameters in UI that are combined using AND in search.
   */
  function andParameter(SearchParameter) {
    function AndParameter() {
      return this;
    }
    AndParameter.prototype = new SearchParameter();
    AndParameter.prototype.constructor = AndParameter;
    return AndParameter;
  }

  /**
   * @ngInject
   * @description
   * search parameters that should not be shown for user
   */
  function hiddenParameter() {
    function HiddenParameter() {
      return this;
    }
    return HiddenParameter;
  }

  /**
   * @ngInject
   */
  function parameter($translate) {
    function Parameter(ParameterType, nameFieldPath) {
      this.parameterType = ParameterType;
      this.nameFieldPath = nameFieldPath;
      this.value = [];
      this.nameFieldPath = nameFieldPath || 'name';
      this.parameterAllowedFields = _.concat(['id'], this.nameFieldPath);
      if (ParameterType) {
        Parameter.prototype = angular.extend(new ParameterType(), Parameter.prototype);
        Parameter.prototype.constructor = Parameter;
        return new Parameter(undefined, nameFieldPath);
      }
      return this;
    }
    Parameter.prototype.clone = function () {
      const newObject = this.__proto__.constructor(this.parameterType, this.nameFieldPath);
      angular.copy(this.value, newObject.value);
      return newObject;
    };
    Parameter.prototype.getValues = function () {
      return this.value;
    };
    Parameter.prototype.displayValue = function (localeOverride) {
      const parameter = this;
      return _.map(this.value, oneValue => {
        if (parameter.nameFieldPath) {
          return $translate.instant(_.get(oneValue, parameter.nameFieldPath), undefined, undefined, localeOverride);
        }
        if (oneValue.name) {
          return $translate.instant(oneValue.name, undefined, undefined, localeOverride);
        }
      });
    };
    Parameter.prototype.isValidForSearch = function () {
      return this.value.length > 0;
    };
    Parameter.prototype.toggleValue = function (value) {
      const filteredValueObject = _.pick(value, this.parameterAllowedFields);
      if (!_.isEmpty(filteredValueObject)) {
        this.value = _.xorBy(this.value, [filteredValueObject], this.parameterAllowedFields[0]);
      }
    };
    Parameter.prototype.removeAllValues = function () {
      this.value.length = 0;
    };
    Parameter.prototype.isSelected = function (value) {
      const idField = this.parameterAllowedFields[0];
      const testedValue = _.get(value, idField);
      return idField && testedValue && _.find(this.value, oneParam => _.get(oneParam, idField) === testedValue);
    };
    Parameter.prototype.getValueInSearchFormat = function () {
      return _.map(this.value, 'id');
    };
    return Parameter;
  }

  /**
   * @ngInject
   * @description
   * Works like Parameter but display uses localizedString.
   */
  function urnObjectParameter(Parameter, localizedStringFilter) {
    function UrnObjectParameter(type) {
      if (type) {
        this.__proto__ = angular.extend(Parameter.call(this, type, ['urn', 'name']), UrnObjectParameter.prototype);
      }
      this.value = [];
      this.parameterAllowedFields = ['urn', 'name'];
      return this;
    }
    UrnObjectParameter.prototype = Object.create(Parameter.prototype);
    UrnObjectParameter.prototype.constructor = UrnObjectParameter;
    UrnObjectParameter.prototype.isSelected = function (value) {
      return !!_.find(this.value, oneParam => oneParam.urn === value.urn);
    };
    UrnObjectParameter.prototype.displayValue = function (localeOverride) {
      return _.map(this.value, urnObject => localizedStringFilter(urnObject.name, localeOverride));
    };
    UrnObjectParameter.prototype.getValueInSearchFormat = function () {
      return _.map(this.value, 'urn');
    };
    return UrnObjectParameter;
  }

  /**
   * @ngInject
   * @description
   * Works like Parameter but display uses localizedString.
   */
  function jsDataObjectParameter(Parameter, localizedStringFilter) {
    function JsDataObjectParameter(type) {
      if (type) {
        this.__proto__ = angular.extend(Parameter.call(this, type), JsDataObjectParameter.prototype);
      }
      return this;
    }
    JsDataObjectParameter.prototype = Object.create(Parameter.prototype);
    JsDataObjectParameter.prototype.constructor = JsDataObjectParameter;
    JsDataObjectParameter.prototype.displayValue = function (localeOverride) {
      const jsDataParameter = this;
      return _.map(this.value, jsDataObject => {
        const name = _.get(jsDataObject, jsDataParameter.nameFieldPath);
        return localizedStringFilter(name, localeOverride) || name;
      });
    };
    return JsDataObjectParameter;
  }

  /**
   * @ngInject
   * @description
   * Works like JsDataObjectParameter but uses groupId as identification.
   */
  function jsDataObjectWithGroupIdParameter(Parameter, JsDataObjectParameter) {
    function JsDataObjectWithGroupIdParameter(type) {
      if (type) {
        this.__proto__ = angular.extend(Parameter.call(this, type), JsDataObjectWithGroupIdParameter.prototype);
      }
      this.parameterAllowedFields = ['groupId', 'name'];
      return this;
    }
    JsDataObjectWithGroupIdParameter.prototype = Object.create(Parameter.prototype);
    JsDataObjectWithGroupIdParameter.prototype.constructor = JsDataObjectWithGroupIdParameter;
    JsDataObjectWithGroupIdParameter.prototype.displayValue = JsDataObjectParameter.prototype.displayValue;
    JsDataObjectWithGroupIdParameter.prototype.getValueInSearchFormat = function () {
      return _.map(this.value, 'groupId');
    };
    return JsDataObjectWithGroupIdParameter;
  }

  /**
   * @ngInject
   * @description
   * Works like Parameter but value holds parent's OtmId and child LocalId, and display uses localizedString.
   * Necessary when we have multiple parent objects for objects with only local ids, since the local ids may
   * be identical.
   */
  function jsHierarchicalDataObjectParameter(Parameter, localizedStringFilter) {
    function JsHierarchicalDataObjectParameter(type) {
      if (type) {
        this.__proto__ = angular.extend(Parameter.call(this, type), JsHierarchicalDataObjectParameter.prototype);
      }
      this.parameterAllowedFields = ['id', 'localId', 'children', this.nameFieldPath];
      return this;
    }
    JsHierarchicalDataObjectParameter.prototype = Object.create(Parameter.prototype);
    JsHierarchicalDataObjectParameter.prototype.constructor = JsHierarchicalDataObjectParameter;
    JsHierarchicalDataObjectParameter.prototype.displayValue = function (localeOverride) {
      const jsHierarchicalDataParameter = this;
      return _.map(this.value, jsHierarchicalDataObject => localizedStringFilter(_.get(jsHierarchicalDataObject, jsHierarchicalDataParameter.nameFieldPath), localeOverride));
    };
    JsHierarchicalDataObjectParameter.prototype.toggleValue = function (value) {
      const filteredValueObject = _.pick(value, this.parameterAllowedFields);
      if (!_.isEmpty(filteredValueObject)) {
        if (_.find(this.value, {
          id: filteredValueObject.id
        })) {
          _.remove(this.value, {
            id: filteredValueObject.id
          });
        } else {
          this.value.push(filteredValueObject);
        }
      }
    };
    JsHierarchicalDataObjectParameter.prototype.toggleChildValue = function (value, childValue) {
      const filteredValueObject = _.pick(childValue, this.parameterAllowedFields);
      let currentValue = _.find(this.value, {
        id: value.id
      });
      if (!currentValue) {
        currentValue = value;
        this.value.push(value);
      }
      const children = _.get(currentValue, 'children', []);
      if (_.find(children, {
        localId: filteredValueObject.localId
      })) {
        _.remove(children, {
          localId: filteredValueObject.localId
        });
      } else {
        children.push(filteredValueObject);
      }
      currentValue.children = children;
    };
    return JsHierarchicalDataObjectParameter;
  }

  /**
   * @ngInject
   * @description
   * Works like Parameter but display uses localizedString.
   */
  function personParameter(Parameter, personNameService) {
    function PersonParameter(type) {
      if (type) {
        this.__proto__ = angular.extend(Parameter.call(this, type, ['firstNames', 'lastName']), PersonParameter.prototype);
      }
      return this;
    }
    PersonParameter.prototype = Object.create(Parameter.prototype);
    PersonParameter.prototype.constructor = PersonParameter;
    PersonParameter.prototype.displayValue = function () {
      return _.map(this.value, person => personNameService.getFullNameReversed(person));
    };
    PersonParameter.prototype.getValueInSearchFormat = function () {
      return _.map(this.value, 'id');
    };
    return PersonParameter;
  }

  /**
   * @ngInject
   * Has a list of date ranges that are used to search for items that are active/valid during any of the given ranges
   * (e.g. validity periods of study years, periods or terms).
   */
  function validityPeriodParameter(Parameter, localizedStringFilter) {
    function ValidityPeriodParameter(type) {
      if (type) {
        this.__proto__ = angular.extend(Parameter.call(this, type), ValidityPeriodParameter.prototype);
      }
      this.value = [];
      this.parameterAllowedFields = ['name', 'valid.startDate', 'valid.endDate', 'startYear', 'studyYearStart', 'studyYearEnd'];
      return this;
    }
    ValidityPeriodParameter.prototype = Object.create(Parameter.prototype);
    ValidityPeriodParameter.prototype.constructor = ValidityPeriodParameter;
    ValidityPeriodParameter.prototype.displayValue = function (localeOverride) {
      return _.map(this.value, obj => displayName(obj, localeOverride));
    };
    ValidityPeriodParameter.prototype.isSelected = function (value) {
      const idField = this.parameterAllowedFields[0];
      const testedValue = _.get(value, idField);
      return idField && testedValue && _.find(this.value, oneParam => _.isEqual(_.get(oneParam, idField), testedValue) && oneParam.startYear === value.startYear);
    };
    ValidityPeriodParameter.prototype.isValidForSearch = function () {
      return this.value.length > 0;
    };
    ValidityPeriodParameter.prototype.getValueInSearchFormat = function () {
      if (this.isValidForSearch()) {
        return _.reduce(this.value, (accumulator, dateRange) => {
          if (dateRange.valid && dateRange.valid.startDate) {
            accumulator.push(dateRange.valid.startDate + (dateRange.valid.endDate ? `,${dateRange.valid.endDate}` : ''));
          }
          return accumulator;
        }, []);
      }
    };
    ValidityPeriodParameter.prototype.toggleValue = function (value) {
      const filteredValueObject = _.pick(value, this.parameterAllowedFields);
      if (!_.isEmpty(filteredValueObject)) {
        this.value = _.xorBy(this.value, [filteredValueObject], displayName);
      }
    };
    function displayName(dateObj, localeOverride) {
      if (typeof dateObj.name === 'string') {
        return dateObj.name;
      }
      if (dateObj.studyYearStart && dateObj.studyYearEnd) {
        return `${dateObj.studyYearStart}-${dateObj.studyYearEnd}: ${localizedStringFilter(dateObj.name, localeOverride)}`;
      }
      return localizedStringFilter(dateObj.name, localeOverride);
    }
    return ValidityPeriodParameter;
  }

  /**
   * @ngInject
   * Works like ValidityPeriodParameter but value search format is study term locator.
   */
  function studyTermLocatorParameter(Parameter, ValidityPeriodParameter) {
    function StudyTermLocatorParameter(type) {
      if (type) {
        this.__proto__ = angular.extend(Parameter.call(this, type), StudyTermLocatorParameter.prototype);
      }
      this.value = [];
      this.parameterAllowedFields = ['name', 'valid.startDate', 'valid.endDate', 'startYear', 'studyYearStart', 'studyYearEnd'];
      return this;
    }
    StudyTermLocatorParameter.prototype = Object.create(Parameter.prototype);
    StudyTermLocatorParameter.prototype.constructor = StudyTermLocatorParameter;
    StudyTermLocatorParameter.prototype.isSelected = ValidityPeriodParameter.prototype.isSelected;
    StudyTermLocatorParameter.prototype.displayValue = ValidityPeriodParameter.prototype.displayValue;
    StudyTermLocatorParameter.prototype.toggleValue = ValidityPeriodParameter.prototype.toggleValue;
    StudyTermLocatorParameter.prototype.getValueInSearchFormat = function () {
      if (this.isValidForSearch()) {
        return _.reduce(this.value, (accumulator, dateRange) => {
          if (dateRange.valid && dateRange.valid.startDate) {
            const termIndex = moment(dateRange.valid.startDate).month() < 7 ? 1 : 0;
            const studyYearStartYear = termIndex == 1 ? dateRange.startYear - 1 : dateRange.startYear;
            accumulator.push(`${termIndex}/${studyYearStartYear}`);
          }
          return accumulator;
        }, []);
      }
    };
    return StudyTermLocatorParameter;
  }

  /**
   * @ngInject
   */
  function stringParameter(Parameter) {
    function StringParameter(type, options) {
      if (type) {
        this.__proto__ = angular.extend(Parameter.call(this, type), StringParameter.prototype);
      }
      this.value = '';
      if (options && options.allowEmptySearchString) {
        this.allowEmptySearchString = true;
      }
      return this;
    }
    StringParameter.prototype = Object.create(Parameter.prototype);
    StringParameter.prototype.constructor = StringParameter;
    StringParameter.prototype.toggleValue = function (value) {
      this.value = value;
    };
    StringParameter.prototype.isSelected = function (value) {
      return this.value === value;
    };
    StringParameter.prototype.removeAllValues = function () {
      this.value = '';
    };
    StringParameter.prototype.displayValue = function () {
      return this.value;
    };
    StringParameter.prototype.isValidForSearch = function () {
      if (typeof this.value === 'string') {
        return this.value.length > 2 && this.value.length <= 255;
      }
      return false;
    };
    StringParameter.prototype.getValueInSearchFormat = function () {
      return this.value;
    };
    return StringParameter;
  }

  /**
   * @ngInject
   * Used to filter results by a single date range value.
   */
  function dateRangeParameter(localDateRangeFilter, Parameter) {
    function DateRangeParameter(type) {
      if (type) {
        this.__proto__ = angular.extend(Parameter.call(this, type), DateRangeParameter.prototype);
      }
      this.value = {
        startDate: undefined,
        endDate: undefined
      };
      return this;
    }
    DateRangeParameter.prototype = Object.create(Parameter.prototype);
    DateRangeParameter.prototype.constructor = DateRangeParameter;
    DateRangeParameter.prototype.displayValue = function () {
      return localDateRangeFilter(this.value);
    };
    DateRangeParameter.prototype.isValidForSearch = function () {
      const {
        startDate,
        endDate
      } = this.value;
      return !_.isNil(startDate) && moment(startDate).isValid() && (_.isEmpty(endDate) || moment(endDate).isValid());
    };
    DateRangeParameter.prototype.toggleValue = function (value) {
      this.value = value;
    };
    DateRangeParameter.prototype.isSelected = function (value) {
      return _.isEqual(value, this.value);
    };
    DateRangeParameter.prototype.removeAllValues = function () {
      this.value = {
        startDate: undefined,
        endDate: undefined
      };
    };
    DateRangeParameter.prototype.getValueInSearchFormat = function () {
      const {
        startDate,
        endDate
      } = this.value;
      return _.isEmpty(startDate) ? '' : `${startDate}${!_.isEmpty(endDate) ? `,${endDate}` : ''}`;
    };
    return DateRangeParameter;
  }

  /**
   * @ngInject
   * Used to filter results by a single date time range value.
   */
  function salesPeriodParameter(localDateRangeFilter, Parameter) {
    function SalesPeriodParameter(type) {
      if (type) {
        this.__proto__ = angular.extend(Parameter.call(this, type), SalesPeriodParameter.prototype);
      }
      this.value = {
        startDate: undefined,
        endDate: undefined
      };
      return this;
    }
    SalesPeriodParameter.prototype = Object.create(Parameter.prototype);
    SalesPeriodParameter.prototype.constructor = SalesPeriodParameter;
    SalesPeriodParameter.prototype.displayValue = function () {
      return localDateRangeFilter(this.value);
    };
    SalesPeriodParameter.prototype.isValidForSearch = function () {
      const {
        startDate,
        endDate
      } = this.value;
      const notEmpty = !(_.isEmpty(startDate) && _.isEmpty(endDate));
      const validStartDate = _.isEmpty(startDate) || moment(startDate).isValid();
      const validEndDate = _.isEmpty(endDate) || moment(endDate).isValid();
      return notEmpty && validStartDate && validEndDate;
    };
    SalesPeriodParameter.prototype.toggleValue = function (value) {
      this.value = value;
    };
    SalesPeriodParameter.prototype.isSelected = function (value) {
      return _.isEqual(value, this.value);
    };
    SalesPeriodParameter.prototype.removeAllValues = function () {
      this.value = {
        startDate: undefined,
        endDate: undefined
      };
    };
    SalesPeriodParameter.prototype.getValueInSearchFormat = function () {
      const {
        startDate,
        endDate
      } = this.value;
      return _.isEmpty(startDate) ? '' : `${startDate}${!_.isEmpty(endDate) ? `,${endDate}` : ''}`;
    };
    return SalesPeriodParameter;
  }

  /**
   * @ngInject
   * Used to filter results by a single date value.
   */
  function dateParameter(localDateFormatFilter, Parameter) {
    function DateParameter(type) {
      if (type) {
        this.__proto__ = angular.extend(Parameter.call(this, type), DateParameter.prototype);
      }
      this.value = {
        localDate: undefined
      };
      return this;
    }
    DateParameter.prototype = Object.create(Parameter.prototype);
    DateParameter.prototype.constructor = DateParameter;
    DateParameter.prototype.displayValue = function () {
      return localDateFormatFilter(this.value.localDate);
    };
    DateParameter.prototype.isValidForSearch = function () {
      return !_.isNil(this.value.localDate) && moment(this.value.localDate).isValid();
    };
    DateParameter.prototype.toggleValue = function (value) {
      this.value = value;
    };
    DateParameter.prototype.isSelected = function (value) {
      return _.isEqual(value, this.value);
    };
    DateParameter.prototype.removeAllValues = function () {
      this.value = {
        localDate: undefined
      };
    };
    DateParameter.prototype.getValueInSearchFormat = function () {
      return _.isEmpty(this.value.localDate) ? '' : `${this.value.localDate}`;
    };
    return DateParameter;
  }

  /**
   * @ngInject
   * @description
   * Has object as a value with min & max keys. Uses CreditRangeFilter for display
   */
  function rangeParameter(creditRangeFilter, Parameter) {
    function RangeParameter(type) {
      if (type) {
        this.__proto__ = angular.extend(Parameter.call(this, type), RangeParameter.prototype);
      }
      this.value = {
        min: undefined,
        max: undefined
      };
      return this;
    }
    RangeParameter.prototype = Object.create(Parameter.prototype);
    RangeParameter.prototype.constructor = RangeParameter;
    RangeParameter.prototype.displayValue = function () {
      return creditRangeFilter(this.value);
    };
    RangeParameter.prototype.isValidForSearch = function () {
      return this.value.min !== undefined || this.value.max !== undefined;
    };
    RangeParameter.prototype.toggleValue = function (value) {
      this.value = value;
    };
    RangeParameter.prototype.isSelected = function () {
      return _.pickBy(this.value, _.isNumber);
    };
    RangeParameter.prototype.removeAllValues = function () {
      this.value = {
        min: undefined,
        max: undefined
      };
    };
    RangeParameter.prototype.getValueInSearchFormat = function () {
      return this.value.min + (this.value.max ? `,${this.value.max}` : '');
    };
    return RangeParameter;
  }

  /**
   * @ngInject
   */
  function booleanParameter(Parameter) {
    function BooleanParameter(type) {
      if (type) {
        this.__proto__ = angular.extend(Parameter.call(this, type), BooleanParameter.prototype);
      }
      this.value = false;
      return this;
    }
    BooleanParameter.prototype = Object.create(Parameter.prototype);
    BooleanParameter.prototype.constructor = BooleanParameter;
    BooleanParameter.prototype.toggleValue = function (value) {
      this.value = value;
    };
    BooleanParameter.prototype.removeAllValues = function () {
      this.value = false;
    };
    BooleanParameter.prototype.displayValue = function () {
      return this.value;
    };
    BooleanParameter.prototype.isValidForSearch = function () {
      return typeof this.value === 'boolean';
    };
    BooleanParameter.prototype.getValueInSearchFormat = function () {
      return this.value;
    };
    return BooleanParameter;
  }
})();