import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CourseUnitRealisation, EnrolmentQuestionnaire, EnrolmentQuestionnaireAnswers } from 'common-typescript/types';
import { ModalService } from 'sis-common/modal/modal.service';

export interface EnrolmentQuestionnaireValues {
    enrolmentQuestionnaire: EnrolmentQuestionnaire;
    enrolmentQuestionnaireAnswers: EnrolmentQuestionnaireAnswers;
    courseUnitRealisation: CourseUnitRealisation;
}

@Component({
    selector: 'sis-enrolment-questionnaire-modal',
    template: '<enrolment-questionnaire-modal (close)="activeModal.close()" [resolve]="values"></enrolment-questionnaire-modal>',
    encapsulation: ViewEncapsulation.None,
})
export class EnrolmentQuestionnaireModalComponent {
    constructor(@Inject(ModalService.injectionToken) public values: EnrolmentQuestionnaireValues,
                public activeModal: NgbActiveModal) {}
}
