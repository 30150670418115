import { HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationAttachments, Attachment, StoredDocumentSignatureInfo } from 'common-typescript/types';
import { EMPTY, forkJoin, from, Observable, of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, switchMap } from 'rxjs/operators';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

import { AlertsService, AlertType } from '../alerts/alerts-ng.service';
import { FileDownloadService } from '../file-download/file-download.service';

import { EntityService } from './entity.service';

const CONFIG = {
    ENDPOINTS: {
        backend: '/tasku/api/student-applications/',
    },
};

@StaticMembers<DowngradedService>()
@Injectable({
    providedIn: 'root',
})
@NgEntityServiceConfig({
    baseUrl: CONFIG.ENDPOINTS.backend,
})
export class ApplicationAttachmentEntityService extends EntityService<ApplicationAttachmentState> {

    static downgrade: ServiceDowngradeMappings = {
        dependencies: [],
        moduleName: 'sis-components.service.applicationAttachmentEntityService',
        serviceName: 'applicationAttachmentEntityService',
    };

    constructor(private alertsService: AlertsService,
                private fileDownloadService: FileDownloadService,
                private translateService: TranslateService) {
        super(ApplicationAttachmentStore, ApplicationAttachmentQuery);
    }

    getAttachmentsByApplicationId(applicationId: string) {
        const url = `${CONFIG.ENDPOINTS.backend}${applicationId}/attachments`;
        return this.getHttp().get(url);
    }

    uploadFiles(applicationAttachmentsPayload: ApplicationAttachments, files: File[]) {
        const url = `${CONFIG.ENDPOINTS.backend}${applicationAttachmentsPayload.applicationId}/attachments/upload`;
        return this.getHttp().post(url, applicationAttachmentsPayload)
            .pipe(
                switchMap((res: ApplicationAttachments) => this.uploadFilesWithPreSignedUrl(res, files)),
            );
    }

    private uploadFilesWithPreSignedUrl(applicationAttachments: ApplicationAttachments, files: File[]): Observable<[ApplicationAttachments] | []> {
        const combinedAttachmentsAndFilesList = this.createCombinedAttachmentsAndFilesList(applicationAttachments, files);
        if (combinedAttachmentsAndFilesList.length === 0) {
            return of([]);
        }
        return forkJoin([
            from(combinedAttachmentsAndFilesList)
                .pipe(
                    mergeMap((item: CombinedAttachmentAndFileObj) => this.uploadFile(item)),
                    /**
                  We need to run virus-scans in synchronous order(previous finished, next starts), because "ApplicationAttachments" is ONE entity
                 and updating entity "ApplicationAttachments.attachments" in async exposes old "attachment" data to be overwritten, when in background
                 happens applicationAttachments entity update, while other update process is running(referencing to the old "ApplicationAttachments" -object) */
                    concatMap((item: CombinedAttachmentAndFileObj) => this.virusScanAndUpdateAttachmentStatus(item.attachment.localId, applicationAttachments.applicationId)),
                ),
        ]);
    }

    private virusScanAndUpdateAttachmentStatus(localId: string, applicationId: string): Observable<ApplicationAttachments> {
        const url = `${CONFIG.ENDPOINTS.backend}${applicationId}/attachments/${localId}/complete`;
        return this.getHttp().post(url, {})
            .pipe(
                map(res => res as ApplicationAttachments),
            );
    }

    private uploadFile(item: CombinedAttachmentAndFileObj): Observable<CombinedAttachmentAndFileObj | Error> {
        /** used in "auth-token-interceptor.ts" to identify preSignedGetRequest and prevent interceptor putting auth
         token to the request */
        const headers = new HttpHeaders({
            isPreSignedUrl: 'true',
        });

        const formData = new FormData();
        if (item.attachment.preSignedPostPolicy) {
            for (const key in item.attachment.preSignedPostPolicy) {
                if (key !== 'url') {
                    formData.append(key, item.attachment.preSignedPostPolicy[key]);
                }
            }
        }
        formData.append('file', item.file);

        return this.getHttp().post(`${item.attachment.preSignedPostPolicy['url']}`, formData, { headers, observe: 'response', responseType: 'text' })
            .pipe(
                map((res: HttpResponse<any>) =>
                    // 204 is a default success status code from s3
                    res.status === 204 ? item : new Error(`Failed to upload the file: ${res}`),
                ),
                catchError((error: HttpErrorResponse) => {
                    console.error(`Failed to upload the attachment: ${item.attachment.name}. Error: ${error.message}`);
                    this.alertsService.addAlert({
                        type: AlertType.DANGER,
                        message: this.translateService.instant('FILE_UPLOAD.FAILED_TO_UPLOAD', { name: item.attachment.name }),
                    });
                    return EMPTY;
                }),
            );
    }

    createCombinedAttachmentsAndFilesList(res: ApplicationAttachments, files: File[]): CombinedAttachmentAndFileObj[] {
        const combinedAttachmentsAndFilesList: CombinedAttachmentAndFileObj[] = [];
        res.attachments.forEach((attachment: Attachment) => {

            if (attachment.preSignedPostPolicy) {
                const combinedAttachmentAndFileObj: CombinedAttachmentAndFileObj = {
                    attachment,
                    file: this.findFileByName(attachment.name, files),
                };
                combinedAttachmentsAndFilesList.push(combinedAttachmentAndFileObj);
            }
        });
        return combinedAttachmentsAndFilesList;
    }

    private findFileByName(attachmentName: string, files: File[]) {
        return Array.from(files).find((file: File) => file.name.normalize() === attachmentName);
    }

    downloadZip(applicationId: string) {
        const url = `${CONFIG.ENDPOINTS.backend}${applicationId}/attachments/presigned-download`;
        this.getHttp().post(url, {})
            .subscribe({
                next: (res: StoredDocumentSignatureInfo) => {
                    this.fileDownloadService.downloadFileFromUrl(`/tasku${res.urlPath}`);
                },
                error: err => console.error(err),
            });
    }
}

type ApplicationAttachmentState = EntityState<ApplicationAttachments>;

@StoreConfig({ name: 'applicationAttachment' })
class ApplicationAttachmentStore extends EntityStore<ApplicationAttachmentState, ApplicationAttachments> {}

class ApplicationAttachmentQuery extends QueryEntity<ApplicationAttachmentState> {
    constructor(protected store: ApplicationAttachmentStore) {
        super(store);
    }
}

interface CombinedAttachmentAndFileObj {
    attachment: Attachment;
    file: File;
}
