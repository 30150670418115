import timelineAddNoteModalTpl from './timelineAddNoteModal.tpl.html';
angular.module('student.timing.note.timelineNoteModal', ['student.timing.timingService']).component('timelineNoteModal', {
  template: timelineAddNoteModalTpl,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  },
  controller: ["$element", "$timeout", "timingService", function ($element, $timeout, timingService) {
    var $ctrl = this;
    $ctrl.$onInit = function () {
      $ctrl.note = $ctrl.resolve.note;
      $ctrl.periods = _.map(_.get($ctrl.note, 'notePeriods'), timingService.getPeriodByLocator);
      $timeout(function () {
        $element.find('#note-text').click();
      });
    };
    $ctrl.save = function () {
      $ctrl.close();
    };
    $ctrl.cancel = function () {
      $ctrl.dismiss();
    };
  }]
});