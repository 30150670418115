import { Pipe, PipeTransform } from '@angular/core';

import { MarkupService } from '../service/markup.service';

@Pipe({ name: 'markupString' })
export class MarkupStringPipe implements PipeTransform {

    constructor(
        private markupService: MarkupService,
    ) { }

    transform(markup: string, locale?: string): string {
        return this.markupService.parse(markup, locale);
    }
}
