import angular from 'angular';
import { SisServiceBreakBannerComponent } from 'sis-components/service-break-banner/sis-service-break-banner.component';
import { ContextualNotificationComponent } from 'sis-components/contextual-notification/contextual-notification.component';
import { IconComponent } from 'sis-components/icon/icon.component';
import { MessageBannerComponent } from 'sis-components/message-banner/message-banner.component';
import { BetaFrontpageToggleComponent } from './beta-frontpage-toggle/beta-frontpage-toggle.component.ts';
import { StudentTermRegistrationPeriodInfoComponent } from './student-term-registration-period-info/student-term-registration-period-info.component.ts';
import frontpageTpl from './frontpage.tpl.html';
import { MyStudiesComponent } from './my-studies/my-studies.component';
import { StudyRightExpirationInfoComponent } from './study-right-expiration-info/study-right-expiration-info.component.ts';
(function () {
  frontPageController.$inject = ["$state", "AuthService"];
  angular.module('student.frontpage', ['ui.router', 'sis-components.string.sortByLocalizedValueFilter', 'student.frontpage.studyProgressGraph', 'student.frontpage.studentBadge', 'student.frontpage.timetable', 'sis-common.auth', 'sis-common.errorhandler.errorService', 'sis-common.university', 'student.frontpage.studentFrontpageMessageConversations', BetaFrontpageToggleComponent.downgrade.moduleName, MyStudiesComponent.downgrade.moduleName, IconComponent.downgrade.moduleName, ContextualNotificationComponent.downgrade.moduleName, StudentTermRegistrationPeriodInfoComponent.downgrade.moduleName, StudyRightExpirationInfoComponent.downgrade.moduleName, SisServiceBreakBannerComponent.downgrade.moduleName, MessageBannerComponent.downgrade.moduleName]).component('frontpage', {
    template: frontpageTpl,
    bindings: {
      userHasPlans: '<',
      enrolments: '<'
    },
    controller: frontPageController
  });
  function frontPageController($state, AuthService) {
    const $ctrl = this;
    $ctrl.$onInit = function () {
      $ctrl.studentId = AuthService.personId();
    };
    $ctrl.goToMessages = () => {
      $state.go('student.logged-in.message-conversations.list.all');
    };
  }
})();