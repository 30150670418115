'use strict';

export const organisationRoleModelModule = 'sis-components.model.organisationRole';
(function () {
  initOrganisationRole.$inject = ["organisationRoleModel", "$http", "$log"];
  organisationRoleModel.$inject = ["DS"];
  angular.module(organisationRoleModelModule, ['js-data']).factory('organisationRoleModel', organisationRoleModel).run(initOrganisationRole);

  /**
   * @ngInject
   */
  function organisationRoleModel(DS) {
    return DS.defineResource({
      idAttribute: 'urn',
      name: 'organisationRole',
      endpoint: '/kori/api/cached/codebooks/urn:code:organisation-role',
      watchChanges: false
    });
  }

  /**
   * @ngInject
   */
  function initOrganisationRole(organisationRoleModel, $http, $log) {
    $http.get(organisationRoleModel.endpoint).then(function (response) {
      organisationRoleModel.inject(_.values(response.data));
    }).catch(function (error) {
      $log.error(error);
    });
  }
})();