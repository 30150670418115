import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StudentApplication } from 'common-typescript/types';
import { ModalService } from 'sis-common/modal/modal.service';

export interface CancelApplicationValues {
    application: StudentApplication;
}

@Component({
    selector: 'app-cancel-application-modal',
    template: '<cancel-application-modal (close)="activeModal.close($event.$value)" (dismiss)="activeModal.dismiss()" [resolve]="values"></cancel-application-modal>',
    encapsulation: ViewEncapsulation.None,
})
export class CancelApplicationModalComponent {
    constructor(@Inject(ModalService.injectionToken) public values: CancelApplicationValues,
                public activeModal: NgbActiveModal) {}
}
