<div>
  <p>{{'STUDIES.CROSS_STUDY.GENERAL_INFO' | translate}}</p>

  <p class="no-results" *ngIf="isCurrentUniversityCourse()">{{'STUDIES.CROSS_STUDY.CURRENT_UNIVERSITY' | translate}}</p>

  <div *ngIf="!isCurrentUniversityCourse()">
    <div role="heading" [attr.aria-level]="level" class="fudis-heading__lg mt-4 mb-3">{{'STUDIES.CROSS_STUDY.NETWORKS' | translate}}</div>

    <p class="network-info">
      {{ 'STUDIES.CROSS_STUDY.NETWORKS_INFO' | translate: {currentUniversity: currentUniversity.name} }}
    </p>

    <p class="no-results" *ngIf="!validCooperationNetworks?.length">
      {{ 'STUDIES.CROSS_STUDY.NO_NETWORKS' | translate: {currentUniversity: currentUniversity.name} }}
    </p>

    <sis-description-list *ngIf="validCooperationNetworks?.length" [columnCount]="1">
      <dl>
        <ng-template *ngFor="let network of validCooperationNetworks;" #listItem>
          <dt>{{ network.abbreviation }} {{network.name | localizedString}}</dt>
          <sis-show-more-text
            [text]="getNetworkDescription(network) | localizedString"
            [maxCharsBeforeOverFlow]="isMobileView ? 80 : 200"
            [showMoreButtonText]="'ARIA_LABEL.SHOW_MORE'"
            [showLessButtonText]="'ARIA_LABEL.SHOW_LESS'">
          </sis-show-more-text>
        </ng-template>
      </dl>
    </sis-description-list>
  </div>
</div>
