import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { Plan } from 'common-typescript/types';

@Component({
    selector: 'app-plan-header-info-list',
    templateUrl: './plan-header-info-list.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanHeaderInfoListComponent {
    @Input() currentPlan: Plan;
}
