import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { combineLatest, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class FullCalendarTranslationService {

    constructor(
        private translocoService: TranslocoService,
    ) { }

    translations$: Observable<string[]> = combineLatest([
        this.weekText(),
        this.today(),
        this.week(),
        this.month(),
        this.previous(),
        this.next(),
    ]);

    weekText(): Observable<string> {
        return this.translocoService.selectTranslate('WEEK_ABBR');
    }

    today(): Observable<string> {
        return this.translocoService.selectTranslate('TODAY');
    }

    week(): Observable<string> {
        return this.translocoService.selectTranslate('WEEK');
    }

    month(): Observable<string> {
        return this.translocoService.selectTranslate('MONTH');
    }

    previous(): Observable<string> {
        return this.translocoService.selectTranslate('ARIA_LABEL.PREVIOUS');
    }

    next(): Observable<string> {
        return this.translocoService.selectTranslate('ARIA_LABEL.NEXT');
    }

}
