import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxFudisModule } from '@funidata/ngx-fudis';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterModule } from '@uirouter/angular';
import { SisCommonModule } from 'sis-common/sis-common.module';
import { SisComponentsModule } from 'sis-components/sis-components.module';

import { OpenUniversityCommonModule } from '../common/open-university-common.module';

import { CartAccordionsComponent } from './cart-accordions/cart-accordions.component';
import { CartContainerComponent } from './cart-container/cart-container.component';
import { CartErrorComponent } from './cart-error/cart-error.component';
import { CeeposPaymentCompleteComponent } from './ceepos-payment-complete/ceepos-payment-complete.component';
import { CartCheckSelectionsComponent } from './check-selections/cart-check-selections.component';
import { CartPriceComponent } from './check-selections/cart-price/cart-price.component';
import { EmptyCartComponent } from './check-selections/empty-cart/empty-cart.component';
import { CartConfirmationComponent } from './confirmation/cart-confirmation.component';
import { CartContactInformationComponent } from './contact-information/cart-contact-information.component';
import { OpenUniversityCartComponent } from './open-university-cart.component';
import { PaymentFailedComponent } from './payment-failed/payment-failed.component';
import { PaymentProcessingComponent } from './payment-processing/payment-processing.component';
import { PaytrailPaymentCompleteComponent } from './paytrail-payment-complete/paytrail-payment-complete.component';

@NgModule({
    declarations: [
        CartAccordionsComponent,
        CartCheckSelectionsComponent,
        CartConfirmationComponent,
        CartContactInformationComponent,
        CartContainerComponent,
        CartPriceComponent,
        CeeposPaymentCompleteComponent,
        EmptyCartComponent,
        OpenUniversityCartComponent,
        PaymentFailedComponent,
        PaytrailPaymentCompleteComponent,
        CartErrorComponent,
        PaymentProcessingComponent,
    ],
    imports: [
        CommonModule,
        OpenUniversityCommonModule,
        ReactiveFormsModule,
        SisCommonModule,
        SisComponentsModule,
        TranslateModule.forChild(),
        UIRouterModule,
        NgxFudisModule,
    ],
})
export class CartPurchaseWizardModule {}
