import angular from 'angular';
import _ from 'lodash';
import { studentApplicationModelModule } from '../model/studentApplication.model';
export const commonStudentApplicationServiceModule = 'sis-components.service.studentApplicationService';
(function () {
  studentApplicationService.$inject = ["$http", "studentApplicationJSDataModel", "studentApplicationEndpoint"];
  angular.module(commonStudentApplicationServiceModule, [studentApplicationModelModule]).factory('commonStudentApplicationService', studentApplicationService);

  /**
   * @ngInject
   */
  function studentApplicationService($http, studentApplicationJSDataModel, studentApplicationEndpoint) {
    const blockingModuleContentApplicationStates = ['REQUESTED', 'IN_HANDLING', 'ACCEPTED', 'CONDITIONAL'];
    const studentViewableModuleContentApplicationStates = ['REQUESTED', 'IN_HANDLING', 'ACCEPTED', 'CONDITIONAL', 'REJECTED'];
    const api = {
      findAll: function (params, options) {
        return studentApplicationJSDataModel.findAll(params, options);
      },
      findById: function (studentApplicationId, options) {
        return studentApplicationJSDataModel.find(studentApplicationId, options).then(studentApplication => studentApplicationJSDataModel.loadRelations(studentApplication));
      },
      getAll: function (params) {
        return studentApplicationJSDataModel.getAll(params);
      },
      /**
       *
       * @param studentApplication The application to create
       * @returns studentApplication
       */
      create: function (studentApplication) {
        return studentApplicationJSDataModel.create(studentApplication);
      },
      search: function (searchParams) {
        const searchConfig = searchParams.build();
        if (searchConfig.params.fullTextQuery) {
          searchConfig.params.searchString = searchConfig.params.fullTextQuery;
        }
        return $http.get(studentApplicationEndpoint.SEARCH, searchConfig).then(result => result.data);
      },
      updateLastHandler: function (studentApplication, personId) {
        return $http.put(`${studentApplicationEndpoint.DEFAULT}/${studentApplication.id}/update-last-handler?personId=${personId}`).then(response => studentApplicationJSDataModel.inject(response.data));
      },
      removeLastHandler: function (studentApplication) {
        return $http.put(`${studentApplicationEndpoint.DEFAULT}/${studentApplication.id}/remove-last-handler`).then(response => studentApplicationJSDataModel.inject(response.data));
      },
      cancel: function (studentApplication) {
        return $http.post(`${studentApplicationEndpoint.DEFAULT}/${studentApplication.id}/cancel`).then(response => studentApplicationJSDataModel.inject(response.data));
      },
      revoke: function (studentApplication) {
        const options = {
          endpoint: studentApplicationEndpoint.REVOKE
        };
        return studentApplicationJSDataModel.save(studentApplication, options);
      },
      findMatchingModuleContentApplication: function (studentId, educationId, parentModuleId, approvedModuleId) {
        return api.findAll({
          studentId
        }, {
          bypassCache: true
        }).then(moduleContentApplications => _.filter(moduleContentApplications, moduleContentApplication => moduleContentApplication.educationId === educationId && moduleContentApplication.parentModuleId === parentModuleId && moduleContentApplication.approvedModuleId === approvedModuleId));
      },
      isBlockingModuleContentApplication: function (studentId, educationId, parentModuleId, approvedModuleId) {
        return api.findMatchingModuleContentApplication(studentId, educationId, parentModuleId, approvedModuleId).then(matchingModuleContentApplications => {
          const blockingModuleContentApplication = _.find(matchingModuleContentApplications, moduleContentApplication => _.includes(blockingModuleContentApplicationStates, moduleContentApplication.state));
          return !!blockingModuleContentApplication;
        });
      },
      isStudentViewableModuleContentApplication: function (moduleContentApplicationState) {
        return _.includes(studentViewableModuleContentApplicationStates, moduleContentApplicationState);
      },
      isMatchingModuleContentApplication: function (moduleContentApplication, validatablePlan, moduleId) {
        if (moduleContentApplication && validatablePlan) {
          return moduleContentApplication.educationId === validatablePlan.rootModule.id && moduleContentApplication.parentModuleId === validatablePlan.moduleIdSelectionMap[moduleId].parentModuleId && moduleContentApplication.approvedModuleId === moduleId;
        }
        return false;
      },
      getApplicationsForStudentByTypes: function (studentId, types) {
        if (studentId && types) {
          const params = {
            params: {
              types
            }
          };
          return $http.get(`${studentApplicationEndpoint.DEFAULT}/${studentId}/by-types`, params).then(response => studentApplicationJSDataModel.inject(response.data));
        }
      },
      getMyEffectiveApplications: bypassCache => studentApplicationJSDataModel.findAll({}, {
        endpoint: studentApplicationEndpoint.MY_EFFECTIVE_STUDENT_APPLICATIONS,
        bypassCache: !_.isNil(bypassCache) ? bypassCache : false
      }),
      getMyEffectiveApplicationsByTypes: (types, bypassCache) => api.getMyEffectiveApplications(bypassCache).then(results => _.filter(results, result => _.includes(types, result.type)))
    };
    return api;
  }
})();