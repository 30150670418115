import { Directive, ElementRef, EventEmitter, Injector, Input, Output } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { Organisation, SearchFilterChangeEvent, SearchParameterOption, UniversityOrganisation } from 'common-typescript/types';

/**
 * @deprecated
 */
// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'display-selected-parameters' })
export class DisplaySelectedParametersDirective extends UpgradeComponent {

    /** The search parameter options that are available on the current page. */
    @Input() selectableParameters: { [name: string]: SearchParameterOption };
    /** An instance of the AngularJS SearchParameters object. */
    @Input() searchParams: any;
    /** Controls whether the active search filters can be edited or removed. */
    @Input() readOnly: boolean;
    /** Used only to search organisations, educations and modules under specific university. Unnecessary otherwise. */
    @Input() university: Organisation | UniversityOrganisation;

    /** Emits changes to the values of search filters (excluding the selection modals; see searchAgain). */
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onParameterChange: EventEmitter<SearchFilterChangeEvent>;
    /**
     * Emits empty events when a specific type of search filter is removed (e.g. the search is no longer filtered by any
     * organisations), or when search filters are changed using a separate modal (e.g. the hierarchical organisation and
     * education selectors). The changes are updated to the searchParams object directly, and up-to-date filter values
     * can be read from there in response to this event.
     */
    @Output() searchAgain: EventEmitter<void>;

    constructor(elementRef: ElementRef<any>, injector: Injector) {
        super('displaySelectedParameters', elementRef, injector);
    }
}
