import { Pipe, PipeTransform } from '@angular/core';

import { PersonNameService, PersonWithName } from '../service/person-name.service';

@Pipe({
    name: 'personFullNameReversed',
})
export class PersonFullNameReversedPipe implements PipeTransform {
    constructor(private personNameService: PersonNameService) {
    }

    transform(person: PersonWithName): string {
        return person && this.personNameService.getFullNameReversed(person);
    }
}
