import { CommonModule, CurrencyPipe, DatePipe, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxFudisModule } from '@funidata/ngx-fudis';
import {
    NgbActiveModal,
    NgbAlertModule,
    NgbDateAdapter,
    NgbDateParserFormatter, NgbDatepickerI18n,
    NgbDatepickerModule,
    NgbDropdownModule,
    NgbModalModule,
    NgbPaginationModule,
    NgbPopoverModule,
    NgbTooltipModule,
    NgbTypeaheadModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterModule } from '@uirouter/angular';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { MarkdownModule } from 'ngx-markdown';
import { LocalizedStringWithoutFallbackPipe } from 'sis-common/l10n/localized-string-without-fallback.pipe';
import { LocalizedStringPipe } from 'sis-common/l10n/localized-string.pipe';
import { SisCommonModule } from 'sis-common/sis-common.module';

import { AboutComponent } from './lib/about/about.component';
import { AddButtonComponent } from './lib/add-button/add-button.component';
import { AlertsComponent } from './lib/alerts/alerts.component';
import { AttainedCreditsByStudyTermComponent } from './lib/applications/attained-credits-by-study-term/attained-credits-by-study-term.component';
import { AttainmentWorkflowDecisionSummaryComponent } from './lib/applications/attainment-workflow-decision-summary/attainment-workflow-decision-summary.component';
import { CodePanelComponent } from './lib/applications/code-panel/code-panel.component';
import { DegreeProgrammeAttainmentApplicationAnswerComponent } from './lib/applications/degree-programme-attainment-application/degree-programme-attainment-application-answer/degree-programme-attainment-application-answer.component';
import { DegreeProgrammeAttainmentApplicationAnswersComponent } from './lib/applications/degree-programme-attainment-application/degree-programme-attainment-application-answers/degree-programme-attainment-application-answers.component';
import { HandlerPanelComponent } from './lib/applications/handler-panel/handler-panel.component';
import { ModuleContentWorkflowDecisionComponent } from './lib/applications/module-content-workflow-decision/module-content-workflow-decision.component';
import { ModuleContentWorkflowStructureComponent } from './lib/applications/module-content-workflow-structure/module-content-workflow-structure.component';
import { CourseUnitTimingInfoTooltipComponent } from './lib/applications/planned-studies-by-study-term/course-unit-timing-info-tooltip/course-unit-timing-info-tooltip.component';
import { PlannedStudiesByStudyTermComponent } from './lib/applications/planned-studies-by-study-term/planned-studies-by-study-term.component';
import { PriorCompetenceSummaryComponent } from './lib/applications/prior-competence-summary/prior-competence-summary.component';
import { PriorLearningListComponent } from './lib/applications/prior-learning-list/prior-learning-list.component';
import { PriorLearningNamePipe } from './lib/applications/prior-learning-list/prior-learning-name.pipe';
import { PriorLearningWorkflowSummaryComponent } from './lib/applications/prior-learning-workflow/prior-learning-workflow-summary/prior-learning-workflow-summary.component';
import { PriorStudiesSummaryComponent } from './lib/applications/prior-studies-summary/prior-studies-summary.component';
import { RecommendationComponent } from './lib/applications/recommendation/recommendation.component';
import { StudentWorkflowListComponent } from './lib/applications/student-workflow-list/student-workflow-list.component';
import { WorkflowRelatedStudyPipe } from './lib/applications/student-workflow-list/workflow-related-study.pipe';
import { WorkflowDataChangeModalComponent } from './lib/applications/workflow-data-change-modal/workflow-data-change-modal.component';
import { AssessmentItemFullNamePipe } from './lib/assessmentItem/assessment-item-full-name.pipe';
import { AssessmentItemNameInCourseUnitContextPipe } from './lib/assessmentItem/assessment-item-name-in-course-unit-context.pipe';
import { AssessmentItemSnapshotPipe } from './lib/assessmentItem/assessment-item-snapshot.pipe';
import { SelectAssessmentItemComponent } from './lib/assessmentItem/select-assessment-item/select-assessment-item.component';
import { AttainedQualificationComponent } from './lib/attainedQualification/attained-qualification.component';
import { AdditionalInfoDetailsComponent } from './lib/attainment/additional-info-details/additional-info-details.component';
import { AttainmentBoxComponent } from './lib/attainment/attainment-box/attainment-box.component';
import { AttainmentCodePipe } from './lib/attainment/attainment-code.pipe';
import { AttainmentCooperationNetworkStatusDetailsComponent } from './lib/attainment/attainment-cooperation-network-status-details/attainment-cooperation-network-status-details.component';
import { AttainmentCreditTransferInfoDetailsComponent } from './lib/attainment/attainment-credit-transfer-info-details/attainment-credit-transfer-info-details.component';
import { AttainmentExpirationNotificationComponent } from './lib/attainment/attainment-expiration-notification/attainment-expiration-notification.component';
import { AttainmentListComponent } from './lib/attainment/attainment-list/attainment-list.component';
import { AttainmentMarkAsSecondaryModalComponent } from './lib/attainment/attainment-mark-as-secondary-modal/attainment-mark-as-secondary-modal.component';
import { AttainmentNamePipe } from './lib/attainment/attainment-name.pipe';
import { AttainmentStatusTranslationPipe } from './lib/attainment/attainment-status-translation.pipe';
import { AttainmentStructureNodeComponent } from './lib/attainment/attainment-structure-node/attainment-structure-node.component';
import { CommonAttainmentDetailsComponent } from './lib/attainment/common-attainment-details/common-attainment-details.component';
import { CurriculumPeriodsDetailsComponent } from './lib/attainment/curriculum-periods-details/curriculum-periods-details.component';
import { CustomAttainmentCreditInfoModalComponent } from './lib/attainment/custom-attainment-credit-info-modal/custom-attainment-credit-info-modal.component';
import { CustomCourseUnitAttainmentBoxComponent } from './lib/attainment/custom-course-unit-attainment-box/custom-course-unit-attainment-box.component';
import { CustomModuleAttainmentBoxComponent } from './lib/attainment/custom-module-attainment-box/custom-module-attainment-box.component';
import { GradeAverageDescriptionComponent } from './lib/attainment/grade-average-description/grade-average-description.component';
import { AttainmentListForStudyRowMenuButtonComponent } from './lib/attainment/list/attainment-list-for-study-row-menu-button/attainment-list-for-study-row-menu-button.component';
import { SecondaryAttainmentDetailsComponent } from './lib/attainment/secondary-attainment-details/secondary-attainment-details.component';
import { AttainmentRowMenuButtonComponent } from './lib/attainment/tree/attainment-row-menu-button/attainment-row-menu-button.component';
import { CurFlowStateBadgeDynamicComponent } from './lib/badge/cur-flow-state-badge-dynamic/cur-flow-state-badge-dynamic.component';
import { CurFlowStateBadgeComponent } from './lib/badge/cur-flow-state-badge/cur-flow-state-badge.component';
import { DocumentStateBadgeComponent } from './lib/badge/document-state-badge/document-state-badge.component';
import { NonActiveDocumentStateBadgeComponent } from './lib/badge/non-active-document-state-badge/non-active-document-state-badge.component';
import { NotificationBadgeComponent } from './lib/badge/notification-badge/notification-badge.component';
import { TinyBadgeComponent } from './lib/badge/tiny-badge/tiny-badge.component';
import {
    SelectValidityPeriodEndDateComponent,
} from './lib/bulk-edit-wizard/select-validity-period-end-date/select-validity-period-end-date.component';
import { BatchOperationErrorComponent } from './lib/bulkEdit/batch-operation-error/batch-operation-error.component';
import { BulkEditContainerComponent } from './lib/bulkEdit/bulk-edit-container/bulk-edit-container.component';
import { ChangesAndDetailsTooltipComponent } from './lib/bulkEdit/changes-and-details-tooltip/changes-and-details-tooltip.component';
import { ButtonComponent } from './lib/button/button.component';
import { ModalCloseButtonComponent } from './lib/buttons/modal-close-button/modal-close-button.component';
import { CarouselSelectionBoxSlideComponent } from './lib/carousel-selection-box-slide/carousel-selection-box-slide.component';
import { CarouselComponent } from './lib/carousel/carousel.component';
import { CheckboxIconifiedComponent } from './lib/checkbox-iconified/checkbox-iconified.component';
import { CheckboxTreeComponent } from './lib/checkbox-tree/checkbox-tree.component';
import { CheckboxComponent } from './lib/checkbox/checkbox.component';
import { ClassifiedInfoToggleComponent } from './lib/classified-info-toggle/classified-info-toggle.component';
import { ClassifiedPersonInfoToggleModalComponent } from './lib/classified-person-info-toggle-modal/classified-person-info-toggle-modal.component';
import { CodeLocalizedNamePipe } from './lib/codes/code-localized-name.pipe';
import { CodesSortedByNamePipe } from './lib/codes/codes-sorted-by-name.pipe';
import { UrnLastPartPipe } from './lib/codes/urn-last-part.pipe';
import { ConfirmDialogComponent } from './lib/confirm/confirm-dialog.component';
import { GenericContainerComponent } from './lib/containers/generic-container/generic-container.component';
import { ContextualNotificationComponent } from './lib/contextual-notification/contextual-notification.component';
import { CooperationNetworkEditModalComponent } from './lib/cooperation-network/cooperation-network-edit-modal/cooperation-network-edit-modal.component';
import { CooperationNetworkSelectorComponent } from './lib/cooperation-network/cooperation-network-selector/cooperation-network-selector.component';
import { CooperationNetworkSharesComponent } from './lib/cooperation-network/cooperation-network-shares.component';
import { CooperationNetworkDetailsEditorComponent } from './lib/cooperation-network/cooperation-networks-editor/cooperation-network-details-editor.component';
import { CooperationNetworkShareEditorComponent } from './lib/cooperation-network/cooperation-networks-editor/cooperation-network-share-editor.component';
import { OutboundStatusErrorModalComponent } from './lib/cooperation-network/outbound-status-error-modal/outbound-status-error-modal.component';
import { CourseUnitBoxComponent } from './lib/courseUnit/course-unit-box/course-unit-box.component';
import { CourseUnitCrossStudyComponent } from './lib/courseUnit/course-unit-cross-study/course-unit-cross-study.component';
import { CourseUnitSubstitutionComponent } from './lib/courseUnit/course-unit-substitution/course-unit-substitution.component';
import { PriorLearningSubstitutionsComponent } from './lib/courseUnit/prior-learning-substitutions/prior-learning-substitutions.component';
import { CompletionMethodAssessmentItemsDetailsComponent } from './lib/courseUnitInfoModal/completion-methods/completion-method-assessment-items-details/completion-method-assessment-items-details.component';
import { CourseUnitInfoModalTextFieldComponent } from './lib/courseUnitInfoModal/course-unit-info-modal-text-field/course-unit-info-modal-text-field.component';
import { CourseUnitRealisationResponsibilityInfoComponent } from './lib/courseUnitInfoModal/course-unit-realisation-responsibility-info/course-unit-realisation-responsibility-info.component';
import { CourseUnitRealisationSeatsComponent } from './lib/courseUnitInfoModal/course-unit-realisation-seats/course-unit-realisation-seats.component';
import { CourseUnitVersionSelectorComponent } from './lib/courseUnitInfoModal/course-unit-version-selector/course-unit-version-selector.component';
import { ModuleResponsibilityInfoComponent } from './lib/courseUnitInfoModal/module-responsibility-info/module-responsibility-info.component';
import { ResponsibleOrganisationInfoComponent } from './lib/courseUnitInfoModal/responsible-organisation-info/responsible-organisation-info.component';
import {
    CourseUnitRealisationCourseUnitCodesPipe,
} from './lib/courseUnitRealisation/course-unit-realisation-course-unit-codes-pipe';
import { CourseUnitRealisationFullNamePipe } from './lib/courseUnitRealisation/course-unit-realisation-full-name.pipe';
import { FilterCourseUnitRealisationsByAssessmentItemIdPipe } from './lib/courseUnitRealisation/filter-course-unit-realisations-by-assessment-item-id.pipe';
import { ResolvedFlowStatePipe } from './lib/courseUnitRealisation/resolved-flow-state.pipe';
import { StudySubGroupStructureStudyEventExceptionsComponent } from './lib/courseUnitRealisation/study-sub-group-structure-study-event-exceptions/study-sub-group-structure-study-event-exceptions.component';
import { StudySubGroupStructureStudyEventComponent } from './lib/courseUnitRealisation/study-sub-group-structure-study-event/study-sub-group-structure-study-event.component';
import { CurriculumPeriodNamePipe } from './lib/curriculumPeriod/curriculum-period-name.pipe';
import { CustomCodeUrnsEditComponent } from './lib/custom-code-urns/custom-code-urns-edit/custom-code-urns-edit.component';
import { CustomCodeUrnsComponent } from './lib/custom-code-urns/custom-code-urns/custom-code-urns.component';
import { CustomStudyDraftSummaryComponent } from './lib/customStudyDraft/custom-study-draft-summary.component';
import { DateAdapter } from './lib/date/date-adapter/date-adapter';
import { DateParserFormatter } from './lib/date/date-parser-formatter/date-parser-formatter';
import { DatePickerI18n } from './lib/date/date-picker-i18n/date-picker-i18n.service';
import { LegacyLocalDateEditorComponent } from './lib/date/local-date-editor/legacy-local-date-editor/legacy-local-date-editor.component';
import { LocalDateEditorComponent } from './lib/date/local-date-editor/local-date-editor.component';
import { LocalDateRangeEditorComponent } from './lib/date/local-date-range-editor/local-date-range-editor.component';
import { LocalDateTimeEditorComponent } from './lib/date/local-date-time-editor/local-date-time-editor.component';
import { LocalDateTimeRangeEditorComponent } from './lib/date/local-date-time-range-editor/local-date-time-range-editor.component';
import { LegacyLocalTimeEditorComponent } from './lib/date/local-time-editor/legacy-local-time-editor/legacy-local-time-editor.component';
import { LocalTimeEditorComponent } from './lib/date/local-time-editor/local-time-editor.component';
import { LegacyLocalTimeRangeEditorComponent } from './lib/date/local-time-range-editor/legacy-local-time-range-editor/legacy-local-time-range-editor.component';
import { LocalTimeRangeEditorComponent } from './lib/date/local-time-range-editor/local-time-range-editor.component';
import { DateRangeDisplayPipe } from './lib/date/pipes/date-range-display/date-range-display.pipe';
import { DateRangeFuturePipe } from './lib/date/pipes/date-range-future/date-range-future.pipe';
import { DateRangeOngoingPipe } from './lib/date/pipes/date-range-ongoing/date-range-ongoing.pipe';
import { DateRangePastPipe } from './lib/date/pipes/date-range-past/date-range-past.pipe';
import { DurationPipe } from './lib/date/pipes/duration/duration.pipe';
import { EventTimeRangePipe } from './lib/date/pipes/event-time-range/event-time-range.pipe';
import { IsFutureDatePipe } from './lib/date/pipes/is-future-date/is-future-date.pipe';
import { IsPastDatePipe } from './lib/date/pipes/is-past-date/is-past-date.pipe';
import { LocalDateFormatPipe } from './lib/date/pipes/local-date-format/local-date-format.pipe';
import { LocalDateRangePipe } from './lib/date/pipes/local-date-range/local-date-range.pipe';
import { LocalDateTimeFormatPipe } from './lib/date/pipes/local-date-time-format/local-date-time-format.pipe';
import { LocalDateTimeRangePipe } from './lib/date/pipes/local-date-time-range/local-date-time-range.pipe';
import { LocalTimeFormatPipe } from './lib/date/pipes/local-time-format/local-time-format.pipe';
import { PreviousDayPipe } from './lib/date/pipes/previous-day/previous-day.pipe';
import { SalesPeriodPipe } from './lib/date/pipes/sales-period/sales-period.pipe';
import { StudyEventDateRangePipe } from './lib/date/pipes/study-event-date-range/study-event-date-range.pipe';
import { StudyEventTimeRangePipe } from './lib/date/pipes/study-event-time-range/study-event-time-range.pipe';
import { StudyEventWeekdayOrIntervalPipe } from './lib/date/pipes/study-event-weekday-or-interval/study-event-weekday-or-interval.pipe';
import { ValidityPeriodDisplayPipe } from './lib/date/pipes/validity-period-display/validity-period-display.pipe';
import {
    WeekdayAndDateTimeRangePipe,
} from './lib/date/pipes/weekday-and-date-time-range/weekday-and-date-time-range.pipe';
import { YearRangePipe } from './lib/date/pipes/year-range/year-range.pipe';
import { DescriptionListCompactComponent } from './lib/description-list/description-list-compact/description-list-compact.component';
import { DescriptionListComponent } from './lib/description-list/description-list.component';
import { DisabledLinkComponent } from './lib/disabled-link/disabled-link.component';
import { DisclosureAuthorizationsComponent } from './lib/disclosure-authorization/disclosure-authorization.component';
import { DisclosureAuthorizationsEditModalComponent } from './lib/disclosure-authorization/disclosure-authorizations-edit-modal/disclosure-authorizations-edit-modal.component';
import { EducationPhaseProgressComponent } from './lib/education-phase-progress/education-phase-progress.component';
import { IsAgreementStudyPipe } from './lib/education/is-agreement-study.pipe';
import { IsDegreeEducationPipe } from './lib/education/is-degree-education.pipe';
import { LearningOpportunityNamePipe } from './lib/education/learning-opportunity-name.pipe';
import { EnrolmentModule } from './lib/enrolment/enrolment.module';
import { SelectEnrolmentRightForEnrolmentComponent } from './lib/enrolment/select-enrolment-right/select-enrolment-right-for-enrolment.component';
import { EntityAbbreviationPipe } from './lib/entity-pipe/entity-abbreviation.pipe';
import {
    CourseUnitsPipe,
    ModulesPipe,
    StudyEventsPipe,
} from './lib/entity-pipe/entity-list.pipe';
import { EntityNamePipe } from './lib/entity-pipe/entity-name.pipe';
import {
    AdmissionTargetPipe,
    AssessmentItemPipe,
    AttainedQualificationPipe,
    AttainmentPipe,
    CooperationNetworkPipe,
    CourseUnitPipe,
    CourseUnitRealisationPipe,
    CurriculumPeriodPipe,
    EducationPipe,
    EnrolmentRightPipe,
    ExternalIssuerPipe,
    GradeScalePipe,
    LocationPipe,
    ModulePipe,
    OpenUniversityProductCodePipe,
    OpenUniversityProductPipe,
    OrganisationPipe,
    PaymentCategoryPipe,
    PersonInfoPipe,
    PrivatePersonBasicInfoPipe,
    PrivatePersonPipe,
    PublicPersonPipe,
    QualificationPipe,
    StudyEventPipe,
    WorkflowPipe,
} from './lib/entity-pipe/entity.pipe';
import { AccessDeniedModalComponent } from './lib/error-handler/access-denied-modal/access-denied-modal.component';
import { SystemErrorModalComponent } from './lib/error-handler/system-error-modal/system-error-modal.component';
import { ValidationErrorModalComponent } from './lib/error-handler/validation-error-modal/validation-error-modal.component';
import { ExpandableContentDirective } from './lib/expandables/expandable-directives/expandable-content.directive';
import { ExpandableCustomTitleDirective } from './lib/expandables/expandable-directives/expandable-custom-title.directive';
import { ExpandableHeaderButtonsDirective } from './lib/expandables/expandable-directives/expandable-header-buttons.directive';
import { ExpandableComponent } from './lib/expandables/expandable/expandable.component';
import { ExternalLinkComponent } from './lib/external-link/external-link.component';
import { FeedbackModalComponent } from './lib/feedback-modal/feedback-modal.component';
import { EditApplicationAttachmentsModalComponent } from './lib/file-upload/edit-application-attachments-modal/edit-application-attachments-modal.component';
import { EditApplicationAttachmentsComponent } from './lib/file-upload/edit-application-attachments.component';
import { FileUploadErrorModalComponent } from './lib/file-upload/file-upload-error-modal.component';
import { FileUploadInfoModalComponent } from './lib/file-upload/file-upload-info-modal.component';
import { FileUploadComponent } from './lib/file-upload/file-upload.component';
import { FooterComponent } from './lib/footer/footer.component';
import { FormCheckboxComponent } from './lib/form-checkbox/form-checkbox.component';
import { FormLocationSearchComponent } from './lib/form-location-search/form-location-search.component';
import { FormToggleComponent } from './lib/form-toggle/form-toggle.component';
import { PropertyEditorEditingDirective } from './lib/form/property-editor/property-editor-editing.directive';
import { PropertyEditorReadOnlyDirective } from './lib/form/property-editor/property-editor-read-only.directive';
import { PropertyEditorComponent } from './lib/form/property-editor/property-editor.component';
import { RequiredFieldLegendComponent } from './lib/form/required-field/required-field-legend.component';
import { ValidationErrorSummaryComponent } from './lib/form/validation-error-summary/validation-error-summary.component';
import { ValidationErrorsComponent } from './lib/form/validation-errors/validation-errors.component';
import { GradeSelectEditorComponent } from './lib/gradeScale/grade-select-editor/grade-select-editor.component';
import { GradePipe } from './lib/gradeScale/grade.pipe';
import { BasicGroupingPanelComponent } from './lib/grouping-panel/basic-grouping-panel.component';
import { BreadcrumbsComponent } from './lib/header/breadcrumbs/breadcrumbs.component';
import { PageTitleComponent } from './lib/header/page-title/page-title.component';
import { SimpleHeaderComponent } from './lib/header/simple-header.component';
import { IconComponent } from './lib/icon/icon.component';
import { InfoDialogComponent } from './lib/info-dialog/info-dialog.component';
import { LabelWithInfoPopoverComponent } from './lib/label-with-info-popover/label-with-info-popover.component';
import { LanguagePillsComponent } from './lib/language-pills/language-pills.component';
import { LearningEnvironmentComponent } from './lib/learningEnvironment/learning-environment.component';
import { LiteratureComponent } from './lib/literature/literature.component';
import { LiteraturePipe } from './lib/literature/literature.pipe';
import { LoadingScreenComponent } from './lib/loading-screen/loading-screen.component';
import { LocalizedMarkupStringViewComponent } from './lib/localized-markup-string-view/localized-markup-string-view.component';
import { LocationSearchComponent } from './lib/location-search/location-search.component';
import { SelectedLocationsViewComponent } from './lib/location-search/selected-locations-view/selected-locations-view.component';
import { LocationComponent } from './lib/location/location.component';
import { MarkupStringViewComponent } from './lib/markup-string-view/markup-string-view.component';
import { MenuButtonComponent } from './lib/menuButton/menu-button.component';
import { MessageBannerComponent } from './lib/message-banner/message-banner.component';
import { SisFrontpageMessageConversationsComponent } from './lib/messenger/sis-frontpage-message-conversations.component';
import { SisMessageConversationListItemComponent } from './lib/messenger/sis-message-conversation-list-item.component';
import { SisMessageComponent } from './lib/messenger/sis-message.component';
import { ModuleBoxComponent } from './lib/module/module-box/module-box.component';
import { ModuleGroupPipe } from './lib/module/module-group.pipe';
import { CreditRangeLongPipe } from './lib/number/credit-range-long.pipe';
import { CreditRangePipe } from './lib/number/credit-range.pipe';
import { DecimalNumberPipe } from './lib/number/decimal-number.pipe';
import { IntRangePipe } from './lib/number/int-range.pipe';
import { IsSingletonNumberRangePipe } from './lib/number/is-singleton-number-range.pipe';
import { MoneyPipe } from './lib/number/money.pipe';
import { NumberInputComponent } from './lib/number/number-input/number-input.component';
import { NumberRangeEditorComponent } from './lib/number/number-range-editor/number-range-editor.component';
import { PercentEditorComponent } from './lib/number/percent-editor/percent-editor.component';
import { PercentPipe } from './lib/number/percent.pipe';
import { ReferredObjectSelectEditorComponent } from './lib/object/referred-object-select-editor/referred-object-select-editor.component';
import { ToParamsPipe } from './lib/object/to-params.pipe';
import { UrnSpecifierEditorComponent } from './lib/object/urn-specifier-editor/urn-specifier-editor.component';
import { OpenUniversityCartStateBadgeComponent } from './lib/open-university/cart-state-badge/open-university-cart-state-badge.component';
import { EnrolmentRightAddModalComponent } from './lib/open-university/enrolment-right/add-modal/enrolment-right-add-modal.component';
import { EnrolmentRightBadgesComponent } from './lib/open-university/enrolment-right/badges/enrolment-right-badges.component';
import { EnrolmentRightCancelModalComponent } from './lib/open-university/enrolment-right/cancel-modal/enrolment-right-cancel-modal.component';
import { AssessmentItemCurSelectionComponent } from './lib/open-university/enrolment-right/edit-modal/assessment-item-cur-selection/assessment-item-cur-selection.component';
import { EnrolmentRightEditModalComponent } from './lib/open-university/enrolment-right/edit-modal/enrolment-right-edit-modal.component';
import { EnrolmentRightExpandableBodyComponent } from './lib/open-university/enrolment-right/expandable-body/enrolment-right-expandable-body.component';
import { EnrolmentRightExpandableComponent } from './lib/open-university/enrolment-right/expandable/enrolment-right-expandable.component';
import { EnrolmentRightResolvedStatePipe } from './lib/open-university/enrolment-right/resolved-state-pipe/enrolment-right-resolved-state.pipe';
import { SelectEnrolmentRightForCourseUnitComponent } from './lib/open-university/enrolment-right/select-enrolment-right-for-course-unit/select-enrolment-right-for-course-unit.component';
import { OpenUniversityAssessmentItemComponent } from './lib/open-university/open-university-assessment-item/open-university-assessment-item.component';
import { OpenUniversityConfirmBatchActionModalComponent } from './lib/open-university/open-university-confirm-batch-action-modal/open-university-confirm-batch-action-modal.component';
import { OpenUniversityProductCreationFormComponent } from './lib/open-university/product-creation-form/open-university-product-creation-form.component';
import { ProductInfoLinkComponent } from './lib/open-university/product-info-link/product-info-link.component';
import { OpenUniversityProductStateBadgeComponent } from './lib/open-university/product-state-badge/open-university-product-state-badge.component';
import { OpenUniversityProductBasicInfoComponent } from './lib/open-university/product-view/basic-info/open-university-product-basic-info.component';
import { OpenUniversityProductInfoViewComponent } from './lib/open-university/product-view/basic-info/open-university-product-info-view.component';
import { OpenUniversityProductEnrolmentRightViewComponent } from './lib/open-university/product-view/enrolment-right/open-university-product-enrolment-right-view.component';
import { OpenUniversityProductViewComponent } from './lib/open-university/product-view/open-university-product-view.component';
import { CurrentOrganisationRoleShareEditorComponent } from './lib/organisation/current-organisation-role-share-editor/current-organisation-role-share-editor.component';
import { HasOrganisationRolePipe } from './lib/organisation/has-organisation-role.pipe';
import { OrganisationRoleShareComponent } from './lib/organisation/organisation-role-share/organisation-role-share.component';
import { SearchOrganisationComponent } from './lib/organisation/search-organisation.component';
import { PaginationComponent } from './lib/pagination/pagination.component';
import { ContactInfoForGraduationComponent } from './lib/person/contact-info-for-graduation/contact-info-for-graduation.component';
import { GroupResponsibilityInfoNamePipe } from './lib/person/group-responsibility-info-name.pipe';
import { PersonFullNameReversedWithEmailPipe } from './lib/person/person-full-name-reversed-with-email.pipe';
import { PersonFullNameReversedPipe } from './lib/person/person-full-name-reversed.pipe';
import { PersonFullNamePipe } from './lib/person/person-full-name.pipe';
import { PersonInfoForGraduationComponent } from './lib/person/person-info-for-graduation/person-info-for-graduation.component';
import { PersonTitleSelectorComponent } from './lib/person/person-title-selector/person-title-selector.component';
import { PersonalIdentityCodeComponent } from './lib/person/personal-identity-code/personal-identity-code.component';
import { SearchPersonComponent } from './lib/person/search-person/search-person.component';
import { SearchStudentComponent } from './lib/person/search-student/search-student.component';
import { ToPersonNamesPipe } from './lib/person/to-person-names.pipe';
import { ArrayIncludesPipe } from './lib/pipe/array-includes.pipe';
import { ArrayJoinPipe } from './lib/pipe/array-join.pipe';
import { ArrayMapPipe } from './lib/pipe/array-map.pipe';
import { CommonCodePipe } from './lib/pipe/codebook.pipe';
import { MimeTypeToExtensionPipe } from './lib/pipe/mime-type-to-extension.pipe';
import { LocalizedMarkupStringPipe } from './lib/pipe/localized-markup-string.pipe';
import { MarkupStringPipe } from './lib/pipe/markup-string.pipe';
import { AttainmentGroupNodeComponent } from './lib/plan/attainment-group-node/attainment-group-node.component';
import { AttainmentNodeStructureComponent } from './lib/plan/attainment-node-structure/attainment-node-structure.component';
import { CreateCustomModuleContentWorkflowComponent } from './lib/plan/create-custom-module-content-workflow/create-custom-module-content-workflow.component';
import { CreateCustomStudyDraftComponent } from './lib/plan/create-custom-study-draft/create-custom-study-draft.component';
import { CreateModuleContentWorkflowModalComponent } from './lib/plan/create-module-content-workflow-modal/create-module-content-workflow-modal.component';
import { EditPlanNameModalComponent } from './lib/plan/edit-plan-name-modal/edit-plan-name-modal.component';
import { InlineSearchAdditionalDataRowComponent } from './lib/plan/inline-search/inline-search-additional-data-row/inline-search-additional-data-row.component';
import { InlineSearchComponent } from './lib/plan/inline-search/inline-search.component';
import { PlanCourseUnitComponent } from './lib/plan/plan-course-unit/plan-course-unit.component';
import { PlanCustomCourseUnitAttainmentComponent } from './lib/plan/plan-custom-course-unit-attainment/plan-custom-course-unit-attainment.component';
import { PlanCustomModuleAttainmentComponent } from './lib/plan/plan-custom-module-attainment/plan-custom-module-attainment.component';
import { PlanCustomStudyDraftComponent } from './lib/plan/plan-custom-study-draft/plan-custom-study-draft.component';
import { PlanEducationComponent } from './lib/plan/plan-education/plan-education.component';
import { PlanModuleComponent } from './lib/plan/plan-module/plan-module.component';
import { PlanSelectorConfig } from './lib/plan/planSelectorConfig';
import { CountPhraseComponent } from './lib/plan/rules/header/count-phrase/count-phrase.component';
import { CourseUnitCountPhraseComponent } from './lib/plan/rules/header/course-unit-count-phrase/course-unit-count-phrase.component';
import { CreditPhraseComponent } from './lib/plan/rules/header/credit-phrase/credit-phrase.component';
import { RuleCustomModuleAttainmentBoxComponent } from './lib/plan/rules/rule-custom-module-attainment-box/rule-custom-module-attainment-box.component';
import { RuleModuleBoxComponent } from './lib/plan/rules/rule-module-box/rule-module-box.component';
import { VersionSwitchNotificationComponent } from './lib/plan/version-switch-notification/version-switch-notification.component';
import { PopoverComponent } from './lib/popover/popover.component';
import { RadioButtonComponent } from './lib/radio-button/radio-button.component';
import { TranslateRangePipe } from './lib/range/translate-range.pipe';
import { ReadMoreNgModalComponent } from './lib/read-more-ng/read-more-ng-modal/read-more-ng-modal.component';
import { ReadMoreNgComponent } from './lib/read-more-ng/read-more-ng.component';
import { ResponsibilityInfoComponent } from './lib/responsibility-info/responsibility-info.component';
import { ResponsibilityInfosComponent } from './lib/responsibility-infos/responsibility-infos.component';
import { ScrollIntoViewOnPageChangeDirective } from './lib/scroll/scroll-into-view-on-page-change.directive';
import { MarkStringComponent } from './lib/search/mark-string/mark-string.component';
import { SearchFilterCourseUnitComponent } from './lib/search/searchFilters/search-filter-course-unit.component';
import { SearchFilterEducationComponent } from './lib/search/searchFilters/search-filter-education.component';
import { SearchFilterModuleComponent } from './lib/search/searchFilters/search-filter-module.component';
import { SearchFilterOrganisationComponent } from './lib/search/searchFilters/search-filter-organisation.component';
import { CodeMultiSelectEditorComponent } from './lib/select/code-multi-select-editor/code-multi-select-editor.component';
import { CodeMultiSelectorComponent } from './lib/select/code-multi-selector/code-multi-selector.component';
import { CodeSelectEditorComponent } from './lib/select/code-select-editor.component';
import { CodeSelectComponent } from './lib/select/code-select/code-select.component';
import { CodeSelectionEditorComponent } from './lib/select/code-selection-editor/code-selection-editor.component';
import { ComboboxComponent } from './lib/select/combobox/combobox.component';
import { DropdownSelectButtonComponent } from './lib/select/dropdown-select-button/dropdown-select-button.component';
import { DropdownSelectComponent } from './lib/select/dropdown-select/dropdown-select.component';
import { EducationTypeComboboxSelectorComponent } from './lib/select/education-type-combobox-selector/education-type-combobox-selector.component';
import { SelectComboboxComponent } from './lib/select/select-combobox/select-combobox.component';
import { SelectEducationComponent } from './lib/select/select-education/select-education.component';
import { SelectOrganisationComponent } from './lib/select/select-organisation/select-organisation.component';
import { SelectComponent } from './lib/select/select.component';
import { SelectionBoxComponent } from './lib/selection-box/selection-box.component';
import { SisServiceBreakBannerComponent } from './lib/service-break-banner/sis-service-break-banner.component';
import { SessionTimeoutWarningModalComponent } from './lib/session-timeout-warning/session-timeout-warning-modal.component';
import { ShowMoreListComponent } from './lib/show-more-list/show-more-list.component';
import { ShowMoreComponent } from './lib/show-more/show-more.component';
import { SkipLinkComponent } from './lib/skip-link/skip-link.component';
import { SortByIndicatorComponent } from './lib/sort/sort-by-indicator.component';
import { SortableTableColumnHeaderComponent } from './lib/sortable-table-column-header/sortable-table-column-header.component';
import { SmallSpinnerComponent } from './lib/spinners/small-spinner/small-spinner.component';
import { CodeEditorComponent } from './lib/string/code-editor/code-editor.component';
import { InputWithDebounceComponent } from './lib/string/input-with-debounce/input-with-debounce.component';
import { LegacyLocalizedStringEditorComponent } from './lib/string/legacy-localized-string-editor/legacy-localized-string-editor.component';
import { LocalizedMarkupStringEditorComponent } from './lib/string/localized-markup-string-editor/localized-markup-string-editor.component';
import { LocalizedMarkupStringComponent } from './lib/string/localized-markup-string/localized-markup-string.component';
import { LocalizedStringEditorComponent } from './lib/string/localized-string-editor/localized-string-editor.component';
import { LocalizedStringHeaderComponent } from './lib/string/localized-string-header/localized-string-header.component';
import { LocalizedStringComponent } from './lib/string/localized-string/localized-string.component';
import { MarkupStringComponent } from './lib/string/markup-string/markup-string.component';
import { PlainTextPipe } from './lib/string/plain-text.pipe';
import { ShowMoreTextComponent } from './lib/string/show-more-text/show-more-text.component';
import { TextFieldLimitedLengthComponent } from './lib/string/text-field-limited-length/text-field-limited-length.component';
import { TextFieldComponent } from './lib/string/text-field/text-field.component';
import { StudyActionBoxComponent } from './lib/study-boxes/study-action-box/study-action-box.component';
import { StudyBoxComponent } from './lib/study-boxes/study-box/study-box.component';
import { StudyNotificationBoxComponent } from './lib/study-boxes/study-notification-box/study-notification-box.component';
import { StudyRealisationBoxComponent } from './lib/study-boxes/study-realisation-box/study-realisation-box.component';
import { StudySubstitutionBoxComponent } from './lib/study-boxes/study-substitution-box/study-substitution-box.component';
import { StudyTermNamePipe } from './lib/study-terms/study-term-name.pipe';
import { StudyYearNamePipe } from './lib/study-terms/study-year-name.pipe';
import { StudyRightActivePhaseModuleNamePipe } from './lib/studyRight/study-right-active-phase-module-name.pipe';
import { StudyRightActivePhaseModulePipe } from './lib/studyRight/study-right-active-phase-module.pipe';
import { StudyRightNamePipe } from './lib/studyRight/study-right-name.pipe';
import { StudyRightSelectorComponent } from './lib/studyRight/study-right-selector/study-right-selector.component';
import { TabContentSwitchComponent } from './lib/tabs/tab-content-switch/tab-content-switch.component';
import { TabNavigationComponent } from './lib/tabs/tab-navigation/tab-navigation.component';
import { TinyStaticFormGroupComponent } from './lib/tiny-static-form-group/tiny-static-form-group.component';
import { ToggleComponent } from './lib/toggle-ng/toggle.component';
import { TooltipComponent } from './lib/tooltip/tooltip.component';
import { LanguageSelectionMenuComponent } from './lib/topNav/language-selection-menu/language-selection-menu.component';
import { LoginProviderMenuComponent } from './lib/topNav/login-provider-menu/login-provider-menu.component';
import { PersonnelTopNavigationComponent } from './lib/topNav/personnel-top-navigation/personnel-top-navigation.component';
import { TopNavigationIconComponent } from './lib/topNav/top-navigation/top-navigation-icons/top-navigation-icon/top-navigation-icon.component';
import { TopNavigationIconsComponent } from './lib/topNav/top-navigation/top-navigation-icons/top-navigation-icons.component';
import { TopNavigationComponent } from './lib/topNav/top-navigation/top-navigation.component';
import { UserSettingsMenuComponent } from './lib/topNav/user-settings-menu/user-settings-menu.component';
import { TranspileComponent } from './lib/transpile/transpile.component';
import { TypeaheadComponent } from './lib/typeahead/typeahead.component';
import { UnauthorizedComponent } from './lib/unauthorized/unauthorized.component';
import { UniversityIframeComponent } from './lib/university-iframe/university-iframe.component';
import { UniversityOrgsComponent } from './lib/university/university-orgs/university-orgs.component';
import { UniversitySelectEditorComponent } from './lib/university/university-select-editor/university-select-editor.component';
import { UrnBoxComponent } from './lib/urn-box/urn-box.component';
import { DescribedByContentDirective } from './lib/util/described-by-content.directive';
import { AutofocusDirective } from './lib/util/edit-mode/autofocus.directive';
import { VersionSelectorComponent } from './lib/version-selector/version-selector.component';
import { WizardProgressComponent } from './lib/wizard-progress/wizard-progress.component';
import { WizardStepButtonsComponent } from './lib/wizard-step-buttons/wizard-step-buttons.component';
import { EmrexAttachmentsComponent } from './lib/applications/emrex-attachments/emrex-attachments.component';
import { FeatureToggleComponent } from './lib/feature-toggle/feature-toggle.component';

/**
 * This module contains pure Angular code, do not put anything that depends on AngularJS here. Hybrid code belongs
 * in {@link SisComponentsHybridModule}.
 */
@NgModule({
    declarations: [
        AboutComponent,
        AutofocusDirective,
        AccessDeniedModalComponent,
        AddButtonComponent,
        AdmissionTargetPipe,
        AlertsComponent,
        ArrayIncludesPipe,
        ArrayJoinPipe,
        ArrayMapPipe,
        AssessmentItemCurSelectionComponent,
        AssessmentItemPipe,
        AssessmentItemSnapshotPipe,
        AttainedQualificationComponent,
        AttainedQualificationPipe,
        AttainmentBoxComponent,
        AttainmentGroupNodeComponent,
        AttainmentMarkAsSecondaryModalComponent,
        AttainmentCodePipe,
        AttainmentNamePipe,
        AttainmentNodeStructureComponent,
        AttainmentPipe,
        BreadcrumbsComponent,
        BulkEditContainerComponent,
        ButtonComponent,
        ChangesAndDetailsTooltipComponent,
        CheckboxComponent,
        ClassifiedInfoToggleComponent,
        CodeEditorComponent,
        CodeLocalizedNamePipe,
        CodeSelectComponent,
        ContextualNotificationComponent,
        ConfirmDialogComponent,
        ContactInfoForGraduationComponent,
        CooperationNetworkSharesComponent,
        CooperationNetworkPipe,
        CourseUnitVersionSelectorComponent,
        CourseUnitPipe,
        CourseUnitRealisationPipe,
        CourseUnitTimingInfoTooltipComponent,
        CreateCustomStudyDraftComponent,
        CreditRangePipe,
        CreditRangeLongPipe,
        CurFlowStateBadgeDynamicComponent,
        CurrentOrganisationRoleShareEditorComponent,
        CurriculumPeriodPipe,
        CustomCodeUrnsEditComponent,
        DateRangeDisplayPipe,
        DateRangePastPipe,
        DateRangeOngoingPipe,
        DateRangeFuturePipe,
        DecimalNumberPipe,
        DescribedByContentDirective,
        DisabledLinkComponent,
        DisclosureAuthorizationsComponent,
        DisclosureAuthorizationsEditModalComponent,
        DocumentStateBadgeComponent,
        DropdownSelectButtonComponent,
        DropdownSelectComponent,
        DurationPipe,
        DegreeProgrammeAttainmentApplicationAnswersComponent,
        DegreeProgrammeAttainmentApplicationAnswerComponent,
        EditApplicationAttachmentsComponent,
        EditPlanNameModalComponent,
        EducationPhaseProgressComponent,
        EducationPipe,
        EnrolmentRightPipe,
        EnrolmentRightAddModalComponent,
        EnrolmentRightCancelModalComponent,
        EnrolmentRightEditModalComponent,
        EnrolmentRightExpandableComponent,
        EnrolmentRightResolvedStatePipe,
        EnrolmentRightBadgesComponent,
        ExpandableComponent,
        ExpandableContentDirective,
        ExternalLinkComponent,
        FeedbackModalComponent,
        FileUploadComponent,
        FileUploadErrorModalComponent,
        FileUploadInfoModalComponent,
        FilterCourseUnitRealisationsByAssessmentItemIdPipe,
        FooterComponent,
        FormCheckboxComponent,
        HasOrganisationRolePipe,
        IconComponent,
        InfoDialogComponent,
        InputWithDebounceComponent,
        IsAgreementStudyPipe,
        IsDegreeEducationPipe,
        IsFutureDatePipe,
        IsPastDatePipe,
        IsSingletonNumberRangePipe,
        LabelWithInfoPopoverComponent,
        LanguagePillsComponent,
        LanguageSelectionMenuComponent,
        LearningOpportunityNamePipe,
        LocalizedMarkupStringEditorComponent,
        LocalizedStringComponent,
        LegacyLocalizedStringEditorComponent,
        LocalizedStringEditorComponent,
        LocalizedStringHeaderComponent,
        LocalDateFormatPipe,
        LoginProviderMenuComponent,
        LegacyLocalDateEditorComponent,
        LocalDateEditorComponent,
        LocalDateRangeEditorComponent,
        LocalDateRangePipe,
        LocalDateTimeEditorComponent,
        LocalDateTimeFormatPipe,
        LocalDateTimeRangeEditorComponent,
        LocalDateTimeRangePipe,
        LocalTimeEditorComponent,
        LocalTimeFormatPipe,
        LegacyLocalTimeRangeEditorComponent,
        MenuButtonComponent,
        ModulePipe,
        ModuleGroupPipe,
        MoneyPipe,
        NumberInputComponent,
        EnrolmentRightExpandableBodyComponent,
        OpenUniversityAssessmentItemComponent,
        OpenUniversityCartStateBadgeComponent,
        OpenUniversityConfirmBatchActionModalComponent,
        OpenUniversityProductBasicInfoComponent,
        OpenUniversityProductCodePipe,
        OpenUniversityProductCreationFormComponent,
        OpenUniversityProductEnrolmentRightViewComponent,
        OpenUniversityProductStateBadgeComponent,
        OpenUniversityProductViewComponent,
        OpenUniversityProductInfoViewComponent,
        OpenUniversityProductPipe,
        OrganisationPipe,
        OrganisationRoleShareComponent,
        OutboundStatusErrorModalComponent,
        PageTitleComponent,
        PaginationComponent,
        PaymentCategoryPipe,
        PercentEditorComponent,
        PercentPipe,
        PersonalIdentityCodeComponent,
        PersonInfoForGraduationComponent,
        PersonInfoPipe,
        PersonnelTopNavigationComponent,
        PlanCourseUnitComponent,
        PlanCustomCourseUnitAttainmentComponent,
        PlanCustomModuleAttainmentComponent,
        PlanCustomStudyDraftComponent,
        PlanEducationComponent,
        PlanModuleComponent,
        PlannedStudiesByStudyTermComponent,
        PopoverComponent,
        PreviousDayPipe,
        PriorLearningListComponent,
        PriorLearningNamePipe,
        PriorLearningWorkflowSummaryComponent,
        PrivatePersonPipe,
        PrivatePersonBasicInfoPipe,
        ProductInfoLinkComponent,
        PropertyEditorComponent,
        PropertyEditorEditingDirective,
        PropertyEditorReadOnlyDirective,
        PublicPersonPipe,
        QualificationPipe,
        RadioButtonComponent,
        ResponsibilityInfoComponent,
        ResponsibilityInfosComponent,
        SalesPeriodPipe,
        SearchFilterCourseUnitComponent,
        SearchFilterEducationComponent,
        SearchFilterModuleComponent,
        SearchFilterOrganisationComponent,
        SearchOrganisationComponent,
        SearchStudentComponent,
        ShowMoreListComponent,
        ShowMoreTextComponent,
        SisMessageComponent,
        SisFrontpageMessageConversationsComponent,
        SisMessageConversationListItemComponent,
        SisServiceBreakBannerComponent,
        SkipLinkComponent,
        StudyEventsPipe,
        StudyEventPipe,
        StudyActionBoxComponent,
        StudyBoxComponent,
        StudyNotificationBoxComponent,
        StudySubstitutionBoxComponent,
        StudyRightNamePipe,
        StudyRightActivePhaseModuleNamePipe,
        StudyRightActivePhaseModulePipe,
        StudyTermNamePipe,
        StudyYearNamePipe,
        SystemErrorModalComponent,
        TextFieldComponent,
        TinyBadgeComponent,
        TinyStaticFormGroupComponent,
        TopNavigationComponent,
        UrnLastPartPipe,
        UrnSpecifierEditorComponent,
        ValidationErrorModalComponent,
        LocationComponent,
        LegacyLocalTimeEditorComponent,
        WizardProgressComponent,
        WizardStepButtonsComponent,
        ToggleComponent,
        TooltipComponent,
        TypeaheadComponent,
        PlainTextPipe,
        ValidationErrorsComponent,
        YearRangePipe,
        ShowMoreComponent,
        FormToggleComponent,
        LocalTimeRangeEditorComponent,
        LocationSearchComponent,
        SelectedLocationsViewComponent,
        FormLocationSearchComponent,
        BasicGroupingPanelComponent,
        ModuleBoxComponent,
        StudyRealisationBoxComponent,
        CourseUnitBoxComponent,
        CustomCourseUnitAttainmentBoxComponent,
        CustomModuleAttainmentBoxComponent,
        NonActiveDocumentStateBadgeComponent,
        CourseUnitRealisationFullNamePipe,
        CourseUnitRealisationCourseUnitCodesPipe,
        PersonFullNamePipe,
        PersonFullNameReversedPipe,
        CurFlowStateBadgeComponent,
        ResolvedFlowStatePipe,
        UrnBoxComponent,
        GradePipe,
        ToParamsPipe,
        GradeSelectEditorComponent,
        AssessmentItemFullNamePipe,
        ToPersonNamesPipe,
        SortByIndicatorComponent,
        SortableTableColumnHeaderComponent,
        NumberRangeEditorComponent,
        ReferredObjectSelectEditorComponent,
        PersonTitleSelectorComponent,
        ContactInfoForGraduationComponent,
        PersonInfoForGraduationComponent,
        CountPhraseComponent,
        CourseUnitCountPhraseComponent,
        CreditPhraseComponent,
        RuleModuleBoxComponent,
        RuleCustomModuleAttainmentBoxComponent,
        TranslateRangePipe,
        SisServiceBreakBannerComponent,
        InlineSearchComponent,
        StudyRightSelectorComponent,
        CourseUnitInfoModalTextFieldComponent,
        GradeScalePipe,
        EntityNamePipe,
        CompletionMethodAssessmentItemsDetailsComponent,
        AssessmentItemNameInCourseUnitContextPipe,
        ModuleResponsibilityInfoComponent,
        LearningEnvironmentComponent,
        LiteraturePipe,
        CourseUnitRealisationResponsibilityInfoComponent,
        LocationPipe,
        PriorStudiesSummaryComponent,
        PriorCompetenceSummaryComponent,
        StudySubGroupStructureStudyEventComponent,
        StudyEventDateRangePipe,
        StudyEventTimeRangePipe,
        StudyEventWeekdayOrIntervalPipe,
        CourseUnitSubstitutionComponent,
        ResponsibleOrganisationInfoComponent,
        CodeSelectEditorComponent,
        CommonCodePipe,
        MimeTypeToExtensionPipe,
        TabContentSwitchComponent,
        TabNavigationComponent,
        IntRangePipe,
        CodeSelectionEditorComponent,
        CourseUnitRealisationSeatsComponent,
        CodeMultiSelectorComponent,
        CurriculumPeriodNamePipe,
        CreateCustomModuleContentWorkflowComponent,
        CreateModuleContentWorkflowModalComponent,
        ModuleContentWorkflowStructureComponent,
        CustomStudyDraftSummaryComponent,
        CarouselComponent,
        CarouselSelectionBoxSlideComponent,
        SelectionBoxComponent,
        ScrollIntoViewOnPageChangeDirective,
        EventTimeRangePipe,
        CooperationNetworkSelectorComponent,
        SessionTimeoutWarningModalComponent,
        StudySubGroupStructureStudyEventExceptionsComponent,
        AttainmentWorkflowDecisionSummaryComponent,
        RecommendationComponent,
        StudentWorkflowListComponent,
        SelectComboboxComponent,
        VersionSelectorComponent,
        PriorLearningSubstitutionsComponent,
        CooperationNetworkDetailsEditorComponent,
        CooperationNetworkShareEditorComponent,
        LoadingScreenComponent,
        DescriptionListCompactComponent,
        CourseUnitsPipe,
        ModulesPipe,
        CodePanelComponent,
        ModuleContentWorkflowDecisionComponent,
        DescriptionListComponent,
        SelectOrganisationComponent,
        CheckboxTreeComponent,
        LoadingScreenComponent,
        CheckboxIconifiedComponent,
        TextFieldLimitedLengthComponent,
        AttainmentStatusTranslationPipe,
        VersionSwitchNotificationComponent,
        HandlerPanelComponent,
        WorkflowPipe,
        ExternalIssuerPipe,
        MarkupStringComponent,
        LocalizedMarkupStringComponent,
        MarkupStringPipe,
        LocalizedMarkupStringPipe,
        InlineSearchAdditionalDataRowComponent,
        SelectEducationComponent,
        CustomCodeUrnsComponent,
        CourseUnitCrossStudyComponent,
        SearchPersonComponent,
        WorkflowDataChangeModalComponent,
        RequiredFieldLegendComponent,
        ExpandableCustomTitleDirective,
        ExpandableHeaderButtonsDirective,
        EditApplicationAttachmentsModalComponent,
        GenericContainerComponent,
        CommonAttainmentDetailsComponent,
        EntityAbbreviationPipe,
        GradeAverageDescriptionComponent,
        AdditionalInfoDetailsComponent,
        EntityAbbreviationPipe,
        SecondaryAttainmentDetailsComponent,
        CurriculumPeriodsDetailsComponent,
        AttainmentExpirationNotificationComponent,
        ClassifiedPersonInfoToggleModalComponent,
        MessageBannerComponent,
        SelectComponent,
        AttainmentCreditTransferInfoDetailsComponent,
        UniversityOrgsComponent,
        CustomAttainmentCreditInfoModalComponent,
        SelectAssessmentItemComponent,
        UnauthorizedComponent,
        GroupResponsibilityInfoNamePipe,
        WorkflowRelatedStudyPipe,
        ValidationErrorSummaryComponent,
        AttainmentListComponent,
        UniversitySelectEditorComponent,
        AttainmentRowMenuButtonComponent,
        CodesSortedByNamePipe,
        AttainmentCooperationNetworkStatusDetailsComponent,
        SelectEnrolmentRightForCourseUnitComponent,
        SelectEnrolmentRightForEnrolmentComponent,
        SmallSpinnerComponent,
        AttainmentListForStudyRowMenuButtonComponent,
        ModalCloseButtonComponent,
        ComboboxComponent,
        EducationTypeComboboxSelectorComponent,
        MarkStringComponent,
        AttainmentMarkAsSecondaryModalComponent,
        TranspileComponent,
        LiteratureComponent,
        BatchOperationErrorComponent,
        LocalizedMarkupStringViewComponent,
        MarkupStringViewComponent,
        ReadMoreNgComponent,
        ReadMoreNgModalComponent,
        WeekdayAndDateTimeRangePipe,
        CooperationNetworkEditModalComponent,
        DisclosureAuthorizationsEditModalComponent,
        TopNavigationIconComponent,
        TopNavigationIconsComponent,
        NotificationBadgeComponent,
        PersonFullNameReversedWithEmailPipe,
        ValidityPeriodDisplayPipe,
        SimpleHeaderComponent,
        UniversityIframeComponent,
        UserSettingsMenuComponent,
        AttainedCreditsByStudyTermComponent,
        CodeMultiSelectEditorComponent,
        AttainmentStructureNodeComponent,
        SelectValidityPeriodEndDateComponent,
        EmrexAttachmentsComponent,
        FeatureToggleComponent,
    ],
    imports: [
        CommonModule,
        EnrolmentModule,
        FormsModule,
        NgbAlertModule,
        NgbDatepickerModule,
        NgbDropdownModule,
        NgbModalModule,
        NgbPaginationModule,
        NgbPopoverModule,
        NgbTooltipModule,
        NgbTypeaheadModule,
        NgOptimizedImage,
        NgxDropzoneModule,
        NgxFudisModule,
        ReactiveFormsModule,
        SisCommonModule,
        NgSelectModule,
        NgxFudisModule,
        TranslateModule.forChild(),
        UIRouterModule.forChild(),
        MarkdownModule.forRoot(),
    ],
    exports: [
        ValidationErrorSummaryComponent,
        AboutComponent,
        AutofocusDirective,
        AddButtonComponent,
        AdmissionTargetPipe,
        AlertsComponent,
        ArrayIncludesPipe,
        ArrayJoinPipe,
        ArrayMapPipe,
        AssessmentItemPipe,
        AssessmentItemSnapshotPipe,
        AttainedQualificationComponent,
        AttainedQualificationPipe,
        AttainmentBoxComponent,
        AttainmentGroupNodeComponent,
        AttainmentCodePipe,
        AttainmentListComponent,
        AttainmentListForStudyRowMenuButtonComponent,
        AttainmentNamePipe,
        AttainmentNodeStructureComponent,
        AttainmentPipe,
        AttainmentStatusTranslationPipe,
        BreadcrumbsComponent,
        BulkEditContainerComponent,
        ButtonComponent,
        CarouselComponent,
        CarouselSelectionBoxSlideComponent,
        CheckboxComponent,
        ClassifiedInfoToggleComponent,
        CodeLocalizedNamePipe,
        CodeEditorComponent,
        ComboboxComponent,
        CodeSelectComponent,
        ContextualNotificationComponent,
        CooperationNetworkPipe,
        ConfirmDialogComponent,
        ContactInfoForGraduationComponent,
        CooperationNetworkSharesComponent,
        CourseUnitBoxComponent,
        CourseUnitVersionSelectorComponent,
        CourseUnitPipe,
        CourseUnitRealisationPipe,
        CourseUnitRealisationFullNamePipe,
        CourseUnitRealisationCourseUnitCodesPipe,
        CourseUnitTimingInfoTooltipComponent,
        CreditRangePipe,
        CreditRangeLongPipe,
        CurFlowStateBadgeDynamicComponent,
        CurrentOrganisationRoleShareEditorComponent,
        CurriculumPeriodPipe,
        CustomCodeUrnsEditComponent,
        DateRangeDisplayPipe,
        DateRangePastPipe,
        DateRangeOngoingPipe,
        DateRangeFuturePipe,
        DecimalNumberPipe,
        DescribedByContentDirective,
        DocumentStateBadgeComponent,
        DropdownSelectButtonComponent,
        DropdownSelectComponent,
        DurationPipe,
        DegreeProgrammeAttainmentApplicationAnswersComponent,
        DegreeProgrammeAttainmentApplicationAnswerComponent,
        EditApplicationAttachmentsComponent,
        EducationPhaseProgressComponent,
        EducationPipe,
        EnrolmentRightPipe,
        EnrolmentRightExpandableComponent,
        EnrolmentRightResolvedStatePipe,
        EnrolmentRightBadgesComponent,
        EducationTypeComboboxSelectorComponent,
        ExpandableComponent,
        ExpandableContentDirective,
        FeatureToggleComponent,
        FileUploadComponent,
        FileUploadErrorModalComponent,
        FileUploadInfoModalComponent,
        FilterCourseUnitRealisationsByAssessmentItemIdPipe,
        FooterComponent,
        FormCheckboxComponent,
        GradeSelectEditorComponent,
        IconComponent,
        HasOrganisationRolePipe,
        InputWithDebounceComponent,
        IsAgreementStudyPipe,
        IsDegreeEducationPipe,
        IsFutureDatePipe,
        IsPastDatePipe,
        IsSingletonNumberRangePipe,
        LabelWithInfoPopoverComponent,
        LanguagePillsComponent,
        LearningOpportunityNamePipe,
        LegacyLocalizedStringEditorComponent,
        LegacyLocalDateEditorComponent,
        LocalizedMarkupStringComponent,
        LocalizedMarkupStringPipe,
        LocalizedMarkupStringEditorComponent,
        LocalizedStringComponent,
        LocalizedStringEditorComponent,
        LocalDateFormatPipe,
        LocalDateEditorComponent,
        LocalDateRangeEditorComponent,
        LocalDateRangePipe,
        LocalDateTimeEditorComponent,
        LocalDateTimeFormatPipe,
        LocalDateTimeRangeEditorComponent,
        LocalDateTimeRangePipe,
        LocalTimeEditorComponent,
        LocalTimeFormatPipe,
        LegacyLocalTimeRangeEditorComponent,
        MarkupStringComponent,
        MenuButtonComponent,
        ModuleBoxComponent,
        ModulePipe,
        ModuleGroupPipe,
        MoneyPipe,
        NonActiveDocumentStateBadgeComponent,
        NumberInputComponent,
        EnrolmentRightExpandableBodyComponent,
        OpenUniversityAssessmentItemComponent,
        OpenUniversityCartStateBadgeComponent,
        OpenUniversityProductBasicInfoComponent,
        OpenUniversityProductCodePipe,
        OpenUniversityProductCreationFormComponent,
        OpenUniversityProductEnrolmentRightViewComponent,
        OpenUniversityProductPipe,
        OpenUniversityProductStateBadgeComponent,
        OpenUniversityProductViewComponent,
        OpenUniversityProductInfoViewComponent,
        OrganisationPipe,
        OrganisationRoleShareComponent,
        OutboundStatusErrorModalComponent,
        PageTitleComponent,
        PaginationComponent,
        PaymentCategoryPipe,
        PercentEditorComponent,
        PersonalIdentityCodeComponent,
        PersonFullNamePipe,
        PersonFullNameReversedPipe,
        PersonInfoForGraduationComponent,
        PersonInfoPipe,
        PersonnelTopNavigationComponent,
        PlainTextPipe,
        PlannedStudiesByStudyTermComponent,
        PlanCourseUnitComponent,
        PlanCustomCourseUnitAttainmentComponent,
        PlanCustomModuleAttainmentComponent,
        PlanCustomStudyDraftComponent,
        PlanEducationComponent,
        PlanModuleComponent,
        PopoverComponent,
        PreviousDayPipe,
        PriorLearningListComponent,
        PriorLearningWorkflowSummaryComponent,
        PrivatePersonPipe,
        PrivatePersonBasicInfoPipe,
        ProductInfoLinkComponent,
        PropertyEditorComponent,
        PropertyEditorEditingDirective,
        PropertyEditorReadOnlyDirective,
        PublicPersonPipe,
        QualificationPipe,
        RadioButtonComponent,
        ResponsibilityInfoComponent,
        ResponsibilityInfosComponent,
        SalesPeriodPipe,
        ScrollIntoViewOnPageChangeDirective,
        SearchFilterCourseUnitComponent,
        SearchFilterEducationComponent,
        SearchFilterModuleComponent,
        SearchFilterOrganisationComponent,
        SearchOrganisationComponent,
        SearchPersonComponent,
        SearchStudentComponent,
        ShowMoreTextComponent,
        SisMessageComponent,
        SisFrontpageMessageConversationsComponent,
        SisMessageConversationListItemComponent,
        SisServiceBreakBannerComponent,
        SkipLinkComponent,
        StudyActionBoxComponent,
        StudyBoxComponent,
        StudyNotificationBoxComponent,
        StudySubstitutionBoxComponent,
        StudyEventsPipe,
        StudyEventPipe,
        StudyRealisationBoxComponent,
        StudyRightNamePipe,
        StudyRightActivePhaseModuleNamePipe,
        StudyRightActivePhaseModulePipe,
        StudyRightSelectorComponent,
        StudyTermNamePipe,
        StudyYearNamePipe,
        TextFieldComponent,
        TinyBadgeComponent,
        TinyStaticFormGroupComponent,
        ToggleComponent,
        TopNavigationComponent,
        TypeaheadComponent,
        UniversityOrgsComponent,
        UrnLastPartPipe,
        UrnSpecifierEditorComponent,
        LocationComponent,
        LegacyLocalTimeEditorComponent,
        WizardProgressComponent,
        WizardStepButtonsComponent,
        ValidationErrorsComponent,
        TooltipComponent,
        ShowMoreComponent,
        FormToggleComponent,
        LocalTimeRangeEditorComponent,
        LocationSearchComponent,
        SelectedLocationsViewComponent,
        FormLocationSearchComponent,
        BasicGroupingPanelComponent,
        CurFlowStateBadgeComponent,
        ResolvedFlowStatePipe,
        GradePipe,
        ToParamsPipe,
        AssessmentItemFullNamePipe,
        ToPersonNamesPipe,
        SortByIndicatorComponent,
        SortableTableColumnHeaderComponent,
        ExternalLinkComponent,
        NumberRangeEditorComponent,
        ReferredObjectSelectEditorComponent,
        PersonTitleSelectorComponent,
        CountPhraseComponent,
        CourseUnitCountPhraseComponent,
        CreditPhraseComponent,
        RuleModuleBoxComponent,
        RuleCustomModuleAttainmentBoxComponent,
        TranslateRangePipe,
        InlineSearchComponent,
        CourseUnitInfoModalTextFieldComponent,
        GradeScalePipe,
        EntityNamePipe,
        CompletionMethodAssessmentItemsDetailsComponent,
        AssessmentItemNameInCourseUnitContextPipe,
        ModuleResponsibilityInfoComponent,
        LearningEnvironmentComponent,
        LiteraturePipe,
        CourseUnitRealisationResponsibilityInfoComponent,
        LocationPipe,
        StudySubGroupStructureStudyEventComponent,
        CourseUnitSubstitutionComponent,
        ResponsibleOrganisationInfoComponent,
        CodeSelectEditorComponent,
        CommonCodePipe,
        MimeTypeToExtensionPipe,
        TabNavigationComponent,
        TabContentSwitchComponent,
        IntRangePipe,
        CodeSelectionEditorComponent,
        CourseUnitRealisationSeatsComponent,
        CodeMultiSelectorComponent,
        CurriculumPeriodNamePipe,
        CreateCustomModuleContentWorkflowComponent,
        CreateModuleContentWorkflowModalComponent,
        ModuleContentWorkflowStructureComponent,
        CustomStudyDraftSummaryComponent,
        SelectionBoxComponent,
        StudyEventTimeRangePipe,
        StudyEventDateRangePipe,
        StudyEventWeekdayOrIntervalPipe,
        EventTimeRangePipe,
        CooperationNetworkSelectorComponent,
        SessionTimeoutWarningModalComponent,
        ChangesAndDetailsTooltipComponent,
        VersionSelectorComponent,
        PriorLearningSubstitutionsComponent,
        CooperationNetworkDetailsEditorComponent,
        SelectOrganisationComponent,
        SelectComboboxComponent,
        LoadingScreenComponent,
        DescriptionListCompactComponent,
        CheckboxIconifiedComponent,
        CourseUnitsPipe,
        ModulesPipe,
        RecommendationComponent,
        CodePanelComponent,
        ModuleContentWorkflowDecisionComponent,
        DescriptionListComponent,
        CheckboxTreeComponent,
        TextFieldLimitedLengthComponent,
        VersionSwitchNotificationComponent,
        WorkflowPipe,
        ExternalIssuerPipe,
        MarkupStringPipe,
        LocalizedMarkupStringPipe,
        AttainmentWorkflowDecisionSummaryComponent,
        InlineSearchAdditionalDataRowComponent,
        DisclosureAuthorizationsComponent,
        DisclosureAuthorizationsEditModalComponent,
        CustomCodeUrnsComponent,
        CourseUnitCrossStudyComponent,
        WorkflowDataChangeModalComponent,
        RequiredFieldLegendComponent,
        ExpandableCustomTitleDirective,
        ExpandableHeaderButtonsDirective,
        GenericContainerComponent,
        CommonAttainmentDetailsComponent,
        EntityAbbreviationPipe,
        CurriculumPeriodsDetailsComponent,
        AttainmentExpirationNotificationComponent,
        StudentWorkflowListComponent,
        MessageBannerComponent,
        AttainmentCreditTransferInfoDetailsComponent,
        SelectAssessmentItemComponent,
        UnauthorizedComponent,
        GroupResponsibilityInfoNamePipe,
        UniversitySelectEditorComponent,
        AttainmentRowMenuButtonComponent,
        AttainmentCooperationNetworkStatusDetailsComponent,
        CodesSortedByNamePipe,
        SelectEnrolmentRightForEnrolmentComponent,
        SelectEnrolmentRightForCourseUnitComponent,
        SelectEnrolmentRightForEnrolmentComponent,
        SmallSpinnerComponent,
        CooperationNetworkShareEditorComponent,
        ModalCloseButtonComponent,
        MarkStringComponent,
        TranspileComponent,
        YearRangePipe,
        LiteratureComponent,
        BatchOperationErrorComponent,
        LocalizedMarkupStringViewComponent,
        MarkupStringViewComponent,
        WeekdayAndDateTimeRangePipe,
        SelectEducationComponent,
        LocalizedStringHeaderComponent,
        ClassifiedPersonInfoToggleModalComponent,
        TopNavigationIconComponent,
        TopNavigationIconsComponent,
        NotificationBadgeComponent,
        PersonFullNameReversedWithEmailPipe,
        ValidityPeriodDisplayPipe,
        SimpleHeaderComponent,
        UniversityIframeComponent,
        UserSettingsMenuComponent,
        AttainedCreditsByStudyTermComponent,
        CodeMultiSelectEditorComponent,
        AttainmentStructureNodeComponent,
        SelectValidityPeriodEndDateComponent,
        EmrexAttachmentsComponent,
    ],
    providers: [
        DatePipe,
        CreditRangePipe,
        CreditRangeLongPipe,
        CurrencyPipe,
        LocalizedStringPipe,
        LocalizedMarkupStringPipe,
        LocalizedStringWithoutFallbackPipe,
        LocalDateFormatPipe,
        LocalDateRangePipe,
        LocalTimeFormatPipe,
        IsDegreeEducationPipe,
        PlanSelectorConfig,
        PlainTextPipe,
        SalesPeriodPipe,
        StudyRightActivePhaseModuleNamePipe,
        StudyRightActivePhaseModulePipe,
        StudyRightNamePipe,
        CurriculumPeriodNamePipe,
        YearRangePipe,
        CourseUnitRealisationFullNamePipe,
        CourseUnitRealisationCourseUnitCodesPipe,
        FilterCourseUnitRealisationsByAssessmentItemIdPipe,
        AssessmentItemFullNamePipe,
        GradePipe,
        GradeScalePipe,
        ToParamsPipe,
        TranslateRangePipe,
        CodeLocalizedNamePipe,
        EntityNamePipe,
        OrganisationPipe,
        AssessmentItemNameInCourseUnitContextPipe,
        LiteraturePipe,
        StudyEventDateRangePipe,
        StudyEventTimeRangePipe,
        StudyEventWeekdayOrIntervalPipe,
        IntRangePipe,
        NgbActiveModal,
        { provide: NgbDateAdapter, useClass: DateAdapter },
        { provide: NgbDateParserFormatter, useClass: DateParserFormatter },
        { provide: NgbDatepickerI18n, useClass: DatePickerI18n },
        CodesSortedByNamePipe,
        PersonFullNameReversedWithEmailPipe,
        ValidityPeriodDisplayPipe,
    ],
})
export class SisComponentsModule {
}
