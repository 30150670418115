export const moduleModelModule = 'student.common.model.module';
(function () {
  'use strict';

  moduleModelFactory.$inject = ["ruleModel", "attainableModuleTypes", "moduleType", "RuleVisitor"];
  angular.module(moduleModelModule, ['student.common.utils.RuleVisitor', 'student.common.model.rule']).value('attainableModuleTypes', {
    'Education': false,
    'DegreeProgramme': true,
    'StudyModule': true,
    'GroupingModule': false
  }).constant('moduleType', {
    education: 'Education',
    degreeProgramme: 'DegreeProgramme',
    studyModule: 'StudyModule',
    groupingModule: 'GroupingModule'
  }).factory('moduleModel', moduleModelFactory);
  function moduleModelFactory(ruleModel, attainableModuleTypes, moduleType, RuleVisitor) {
    var moduleModel = {
      newModule: newModule,
      Module: Module
    };
    function newModule(data) {
      if (data) {
        return new Module(data);
      } else {
        return null;
      }
    }
    function Module(data) {
      if (!data.id) {
        throw 'id required for Module';
      }
      if (!data.groupId) {
        throw 'groupId required for Module';
      }
      var self = this;
      var defaults = {
        selectedParent: null,
        selectedModules: [],
        selectedCourseUnits: [],
        selectedModulesById: {},
        selectedCourseUnitsById: {},
        validationResults: null,
        locked: false,
        attainable: !!attainableModuleTypes[data.type],
        attainment: null,
        _index: 0 // Set by ModuleContext in validation
      };
      _.assign(self, defaults, data);
      self.rule = ruleModel.newRule(data.rule);
      self.indexRules(self);
    }
    Module.prototype.detach = function () {
      if (this.selectedParent) {
        // Clear selectedParent before removing from parent which calls right back to this detach
        var parent = this.selectedParent;
        this.selectedParent = null;
        parent.removeSelectedModule(this);
      }
    };

    /**
     * Detaches this module from its parent. Does not remove module subselections.
     */
    Module.prototype.detachFromParent = function () {
      if (this.selectedParent) {
        // Clear selectedParent before removing from parent which calls right back to this detach
        var parent = this.selectedParent;
        this.selectedParent = null;
        parent.removeSelectedModuleSelection(this);
      }
    };
    Module.prototype.ancestors = function () {
      if (!this.selectedParent || this.selectedParent === null) {
        return [];
      } else {
        return this.selectedParent.ancestors().concat(this.selectedParent);
      }
    };

    /**
     * Removes given module but leaves module subselections intact
     * @param module
     */
    Module.prototype.removeSelectedModuleSelection = function (module) {
      delete this.selectedModulesById[module.id];
      module.detach();
      sortAndAssignSelections(this);
    };

    /**
     * Removes given module and all of its subselections.
     * @param module
     */
    Module.prototype.removeSelectedModule = function (module) {
      delete this.selectedModulesById[module.id];
      _.forEach(module.selectedModules, function (m) {
        module.removeSelectedModule(m);
      });
      _.forEach(module.selectedCourseUnits, function (cu) {
        module.removeSelectedCourseUnit(cu);
      });
      module.detach();
      sortAndAssignSelections(this);
    };
    Module.prototype.removeSelectedCourseUnit = function (courseUnit) {
      delete this.selectedCourseUnitsById[courseUnit.id];
      courseUnit.detach();
      sortAndAssignSelections(this);
    };
    Module.prototype.addSelectedModule = function (module) {
      if (this.hasParent(module)) {
        return false;
      }
      if (this.selectedModulesById[module.id]) {
        if (this.selectedModulesById[module.id] === module && module.selectedParent === this) {
          return false;
        } else {
          this.selectedModulesById[module.id].detach();
        }
      }
      module.detachFromParent();
      this.selectedModulesById[module.id] = module;
      module.selectedParent = this;
      sortAndAssignSelections(this);
      return true;
    };
    Module.prototype.hasParent = function (module) {
      if (this.id === module.id) {
        return true;
      } else if (this.selectedParent) {
        return this.selectedParent.hasParent(module);
      }
      return false;
    };
    Module.prototype.addSelectedCourseUnit = function (courseUnit) {
      if (this.selectedCourseUnitsById[courseUnit.id]) {
        if (this.selectedCourseUnitsById[courseUnit.id] === courseUnit && courseUnit.selectedParent === this) {
          return false;
        } else {
          this.selectedCourseUnitsById[courseUnit.id].detach();
        }
      }
      // Detach courseUnit from it's old parent
      courseUnit.detach();
      this.selectedCourseUnitsById[courseUnit.id] = courseUnit;
      courseUnit.selectedParent = this;
      sortAndAssignSelections(this);
      return true;
    };
    Module.prototype.getSelectedModules = function () {
      return _.values(this.selectedModulesById);
    };
    Module.prototype.getSelectedCourseUnits = function () {
      return _.values(this.selectedCourseUnitsById);
    };
    Module.prototype.clearSelections = function () {
      this.selectedModulesById = {};
      this.selectedCourseUnitsById = {};
      // NOTE: Prevent flickering by resetting selectedModules and selectedCourseUnits later in validate
      // REMOVED: sortAndAssignSelections(this);
    };
    Module.prototype.findModuleById = function (id) {
      return _.find(this.selectedModules, function (module) {
        return module.id === id;
      });
    };
    Module.prototype.findMatchingCourseUnitRule = function (courseUnit) {
      return this.courseUnitRulesByGroupId[courseUnit.groupId] || this.anyCourseUnitRule || null;
    };
    Module.prototype.findMatchingModuleRule = function (module) {
      return this.moduleRulesByGroupId[module.groupId] || this.anyModuleRule || null;
    };

    // indexRules exposed for tests!
    Module.prototype.indexRules = function () {
      var self = this;
      self.moduleRulesByGroupId = {};
      self.courseUnitRulesByGroupId = {};
      self.anyModuleRule = null;
      self.anyCourseUnitRule = null;
      if (self.rule) {
        self.rule.accept(new RuleVisitor({
          visitCourseUnitRule: function (rule) {
            self.courseUnitRulesByGroupId[rule.courseUnitGroupId] = rule;
          },
          visitAnyCourseUnitRule: function (rule) {
            self.anyCourseUnitRule = rule;
          },
          visitAnyModuleRule: function (rule) {
            self.anyModuleRule = rule;
          },
          visitModuleRule: function (rule) {
            self.moduleRulesByGroupId[rule.moduleGroupId] = rule;
          }
        }));
      }
    };
    Module.prototype.getCurriculumPeriodIds = function () {
      var moduleWithCurrPeriodIds = this;
      while (moduleWithCurrPeriodIds.type === 'GroupingModule') {
        moduleWithCurrPeriodIds = moduleWithCurrPeriodIds.selectedParent;
      }
      return moduleWithCurrPeriodIds.curriculumPeriodIds;
    };
    return moduleModel;
  }
  function sortAndAssignSelections(module) {
    module.selectedModules = module.getSelectedModules().sort(compareIndex);
    module.selectedCourseUnits = module.getSelectedCourseUnits().sort(compareIndex);
  }
  function compareIndex(a, b) {
    if (hasIndex(a)) {
      if (hasIndex(b)) {
        return a._index - b._index;
      } else {
        // value before null
        return -1;
      }
    } else if (b._index) {
      // value before null
      return 1;
    } else {
      if (a.id < b.id) {
        return -1;
      } else if (a.id > b.id) {
        return 1;
      } else {
        return 0;
      }
    }
  }
  function hasIndex(study) {
    return study._index || study._index === 0;
  }
})();