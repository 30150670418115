import { Option } from './dropdown-select/dropdown-select.component';
import { SelectOption } from './select-combobox/select-combobox.component';

/**
 * Utility functions separated from select-combobox.component.ts
 * This software or document includes material copied from or derived from https://www.w3.org/WAI/ARIA/apg/patterns/combobox/examples/combobox-select-only/
 */

export const SelectActions = {
    Close: 0,
    CloseSelect: 1,
    First: 2,
    Last: 3,
    Next: 4,
    Open: 5,
    PageDown: 6,
    PageUp: 7,
    Previous: 8,
    Select: 9,
    Type: 10,
};

export const getActionFromKey = (event: any, menuOpen: boolean) => {
    const { key, altKey, ctrlKey, metaKey } = event;
    const openKeys = ['ArrowDown', 'ArrowUp', 'Enter', ' ']; // all keys that will do the default open action
    if (!menuOpen && openKeys.includes(key)) {
        return SelectActions.Open;
    }

    if (key === 'Home') {
        return SelectActions.First;
    }
    if (key === 'End') {
        return SelectActions.Last;
    }

    if (
        key === 'Backspace' ||
        key === 'Clear' ||
        (key.length === 1 && key !== ' ' && !altKey && !ctrlKey && !metaKey)
    ) {
        return SelectActions.Type;
    }

    if (menuOpen) {
        if (key === 'ArrowUp' && altKey) {
            return SelectActions.CloseSelect;
        } if (key === 'ArrowDown' && !altKey) {
            return SelectActions.Next;
        } if (key === 'ArrowUp') {
            return SelectActions.Previous;
        } if (key === 'PageUp') {
            return SelectActions.PageUp;
        } if (key === 'PageDown') {
            return SelectActions.PageDown;
        } if (key === 'Escape') {
            return SelectActions.Close;
        } if (key === 'Enter' || key === ' ') {
            return SelectActions.CloseSelect;
        }
    }
};

export const isElementInView = (element: HTMLDivElement) => {
    const bounding = element.getBoundingClientRect();

    return (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
        bounding.right <=
        (window.innerWidth || document.documentElement.clientWidth)
    );
};

export const getUpdatedIndex = (currentIndex: any, maxIndex: any, action: any) => {
    const pageSize = 10; // used for pageup/pagedown

    switch (action) {
        case SelectActions.First:
            return 0;
        case SelectActions.Last:
            return maxIndex;
        case SelectActions.Previous:
            return Math.max(0, currentIndex - 1);
        case SelectActions.Next:
            return Math.min(maxIndex, currentIndex + 1);
        case SelectActions.PageUp:
            return Math.max(0, currentIndex - pageSize);
        case SelectActions.PageDown:
            return Math.min(maxIndex, currentIndex + pageSize);
        default:
            return currentIndex;
    }
};

function filterOptions(filter: any, options: SelectOption[] | Option[] = [], exclude: any[] = []) {
    return options.filter((opt: SelectOption | Option) => {
        const matches = opt.label.toLowerCase().indexOf(filter.toLowerCase()) === 0;
        return matches && exclude.indexOf(opt) < 0;
    });
}

// Return the index of an option from an array of options, based on a search string
// if the filter is multiple iterations of the same letter (e.g "aaa"), then cycle through first-letter matches
export const getIndexByLetter = (options: any, filter: any, startIndex = 0) => {
    const orderedOptions = [
        ...options.slice(startIndex),
        ...options.slice(0, startIndex),
    ];
    const firstMatch = filterOptions(filter, orderedOptions)[0];
    const allSameLetter = (array: any) => array.every((letter: any) => letter === array[0]);

    if (firstMatch) {
        return options.indexOf(firstMatch);
    }

    // if the same letter is being repeated, cycle through first-letter matches
    if (allSameLetter(filter.split(''))) {
        const matches = filterOptions(filter[0], orderedOptions);
        return options.indexOf(matches[0]);
    }
    return -1;
};

export const isScrollable = (element: any) => element && element.clientHeight < element.scrollHeight;

// Ensure a given child element is within the parent's visible scroll area
// if the child is not visible, scroll the parent
export const maintainScrollVisibility = (activeElement: HTMLDivElement, scrollParent: HTMLDivElement) => {
    const { offsetHeight, offsetTop } = activeElement;
    const { offsetHeight: parentOffsetHeight, scrollTop } = scrollParent;

    const isAbove = offsetTop < scrollTop;
    const isBelow = offsetTop + offsetHeight > scrollTop + parentOffsetHeight;

    if (isAbove) {
        scrollParent.scrollTo(0, offsetTop);
    } else if (isBelow) {
        scrollParent.scrollTo(0, offsetTop - parentOffsetHeight + offsetHeight);
    }
};
