import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CourseUnit, LocalizedString, OtmId, SearchFilterToggleValueEvent } from 'common-typescript/types';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { SisFormBuilder } from '../../form/sis-form-builder.service';
import { filterInput } from '../../typeahead/typeahead.component';
import { convertAJSPromiseToNative } from '../../util/utils';

interface FilterValue extends CourseUnit {
    localId: OtmId;
    name: LocalizedString;
}

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-search-filter-course-unit',
    templateUrl: './search-filter-course-unit.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class SearchFilterCourseUnitComponent implements OnChanges, OnInit {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.downgraded.search.searchFilterCourseUnit',
        directiveName: 'sisSearchFilterCourseUnit',
    };

    @Input() filterName: string;
    @Input() getCourseUnits: (searchString: string) => Promise<FilterValue[]>;
    @Input() getFilterName: (filter: FilterValue) => string;
    @Input() listSelected: () => FilterValue[];
    @Input() popoverIsOpen: boolean;
    @Input() selectedCourseUnit?: FilterValue;
    @Output() submitFilter = new EventEmitter<void>();
    @Output() toggleValue = new EventEmitter<SearchFilterToggleValueEvent<FilterValue, MouseEvent>>();

    filterSelectorForm: FormGroup;

    constructor(private fb: SisFormBuilder) {}

    ngOnInit(): void {
        this.filterSelectorForm = this.fb.group({});
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.popoverIsOpen && !changes.popoverIsOpen.firstChange && !changes.popoverIsOpen.currentValue) {
            this.filterSelectorForm.reset();
        }
    }

    searchCourseUnits(searchString$: Observable<string>): Observable<FilterValue[]> {
        return searchString$.pipe(
            filterInput(),
            switchMap(searchString => from(convertAJSPromiseToNative(this.getCourseUnits(searchString)))),
        );
    }
}
