<ng-container *ngIf="enrolmentPeriod; else noEnrolmentPeriod">
  <sis-tiny-badge *ngIf="enrolmentPeriod | dateRangeFuture"
                  variant="primary">
    {{'ENROLMENTS.OPEN_UNIVERSITY_ENROLMENT.ENROLMENT_PERIOD_STARTS' | translate:
      {date: enrolmentPeriod.startDateTime | localDateTimeFormat} }}
  </sis-tiny-badge>
  <sis-tiny-badge *ngIf="enrolmentPeriod | dateRangeOngoing"
                  variant="primary">
    {{'ENROLMENTS.OPEN_UNIVERSITY_ENROLMENT.ENROLMENT_PERIOD_ONGOING' | translate:
      {date: enrolmentPeriod.endDateTime | localDateTimeFormat} }}
  </sis-tiny-badge>
  <ng-container *ngIf="enrolmentPeriod | dateRangePast">
    <sis-tiny-badge *ngIf="!lateEnrolmentEnd"
                    variant="secondary">
      {{'ENROLMENTS.OPEN_UNIVERSITY_ENROLMENT.ENROLMENT_PERIOD_ENDED' | translate:
        {date: enrolmentPeriod.endDateTime | localDateTimeFormat} }}
    </sis-tiny-badge>
    <sis-tiny-badge *ngIf="lateEnrolmentEnd | isFutureDate:'second'"
                    variant="primary">
      {{'ENROLMENTS.OPEN_UNIVERSITY_ENROLMENT.LATE_ENROLMENT_ONGOING' | translate:
        {date: lateEnrolmentEnd | localDateTimeFormat} }}
    </sis-tiny-badge>
    <sis-tiny-badge *ngIf="lateEnrolmentEnd | isPastDate:'second'"
                    variant="secondary">
      {{'ENROLMENTS.OPEN_UNIVERSITY_ENROLMENT.LATE_ENROLMENT_ENDED' | translate:
        {date: lateEnrolmentEnd | localDateTimeFormat} }}
    </sis-tiny-badge>
  </ng-container>

</ng-container>

<ng-template #noEnrolmentPeriod>
  <sis-tiny-badge variant="secondary">
    {{'ENROLMENTS.OPEN_UNIVERSITY_ENROLMENT.ENROLMENT_PERIOD_MISSING' | translate}}
  </sis-tiny-badge>
</ng-template>
