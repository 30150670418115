"use strict";

(function () {
  angular.module("sis-components.date.yearRangeFilter", []).filter('yearRange', yearRange);
  function notNull(value) {
    return angular.isDefined(value) && value !== null;
  }

  /**
   * @ngInject
   */
  function yearRange() {
    return function (input) {
      if (input) {
        var start = notNull(input.startYear) ? input.startYear : null;
        var end = notNull(input.endYear) ? input.endYear : null;
        var retval = '';
        if (notNull(start)) {
          retval += start;
        }
        if (notNull(start) && notNull(end) && start !== end) {
          retval += '–';
        }
        if (notNull(end) && start !== end) {
          retval += end;
        }
        return retval;
      }
      return '';
    };
  }
})();