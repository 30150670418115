<ng-container *ngIf="cart">
  <app-cart-accordions [cart]="cart"
                       [isEditable]="true"
                       [checkEnrolments$]="checkEnrolments$"
                       (hasValidEnrolments)="hasValidEnrolments.emit()">
  </app-cart-accordions>
  <div class="row price-and-discount">
    <div class="col-sm-7"> <!-- TODO: discount here --></div>
    <div class="col-sm-5">
      <app-cart-price [cart]="cart"></app-cart-price>
    </div>
  </div>
</ng-container>

