import 'js-data';
import { privatePersonInfoModelModule } from './privatePersonInfo.model';
import { personGroupTypesModule } from '../constant/personGroupTypes.constant';
import { jsDataRelationHelperModule } from '../service/jsDataRelationHelper.service';
export const personGroupModelModule = 'sis-components.model.personGroup';
(function () {
  personGroupJSDataModel.$inject = ["DS", "PersonGroupEndpoint", "PersonGroupTypes", "jsDataRelationHelperService", "groupResponsibilityInfoTypeModel", "privatePersonInfoJSDataModel", "$log"];
  angular.module(personGroupModelModule, ['js-data', privatePersonInfoModelModule, personGroupTypesModule, jsDataRelationHelperModule]).factory('personGroupJSDataModel', personGroupJSDataModel).constant('PersonGroupEndpoint', {
    DEFAULT: '/ori/api/person-groups',
    SEARCH: '/ori/api/person-groups/search'
  });

  /**
   * @ngInject
   */
  function personGroupJSDataModel(DS, PersonGroupEndpoint, PersonGroupTypes, jsDataRelationHelperService, groupResponsibilityInfoTypeModel, privatePersonInfoJSDataModel, $log) {
    function loadResponsibilityInfos(personGroup) {
      return jsDataRelationHelperService.loadResponsibilityInfoRelations(personGroup.responsibilityInfos, groupResponsibilityInfoTypeModel, privatePersonInfoJSDataModel).then(() => personGroup, error => {
        $log.warn('Failed to load personGroup person relations', personGroup, error);
      });
    }
    return DS.defineResource({
      idAttribute: 'id',
      name: 'personGroup',
      endpoint: PersonGroupEndpoint.DEFAULT,
      methods: {
        loadRelations() {
          return loadResponsibilityInfos(this);
        }
      },
      computed: {
        isTargetStudentGroup: ['type', function (type) {
          return type === PersonGroupTypes.TARGET_STUDENT_GROUP;
        }],
        isTutoringStudentGroup: ['type', function (type) {
          return type === PersonGroupTypes.TUTORING_STUDENT_GROUP;
        }]
      }
    });
  }
})();