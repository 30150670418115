import timelineControlsTpl from './timelineControls.tpl.html';
(function () {
  timeLineControlsController.$inject = ["timingViewStateService", "timelineConfig", "timelineDialogService", "defaultPromiseHandler"];
  angular.module('student.timing.controls.timelineControls', ['pascalprecht.translate', 'sis-components.menuButton', 'student.timing.timingViewStateService', 'student.timing.constant', 'student.timing.controls.timelineDialogService']).component('timelineControls', {
    template: timelineControlsTpl,
    bindings: {
      state: '<',
      validatablePlan: '<',
      validatablePlanConstructor: '<'
    },
    controller: timeLineControlsController
  });
  function timeLineControlsController(timingViewStateService, timelineConfig, timelineDialogService, defaultPromiseHandler) {
    var ctrl = this;
    ctrl.$onInit = function () {
      ctrl.showAllPeriods = ctrl.state.showAllPeriods;
      ctrl.menuOptions = [{
        action: ctrl.createTimeLineNote,
        name: 'TIMELINE.ADD_NOTE',
        disabled: ctrl.isDisableAddNote
      }, {
        action: ctrl.startRemoveAllDialog,
        name: 'TIMELINE.TIMING_TEMPLATE.REMOVE_TIMINGS'
      }];
    };
    ctrl.setZoom = function (step) {
      timingViewStateService.setZoom(ctrl.state, step);
    };
    ctrl.toggleShowAllPeriods = function () {
      timingViewStateService.toggleShowAllPeriods(ctrl.state);
    };
    ctrl.createTimeLineNote = function () {
      timingViewStateService.startCreateTimeLineNote(ctrl.state);
    };
    ctrl.isDisableAddNote = function () {
      return ctrl.state.type === timelineConfig.viewStates.TIME_COURSE_UNIT || ctrl.state.type === timelineConfig.viewStates.MOVE_COURSE_UNIT_TIMING;
    };
    ctrl.startRemoveAllDialog = function () {
      timelineDialogService.openRemoveAllDialog(ctrl.state, ctrl.validatablePlan, ctrl.validatablePlanConstructor, undefined).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    };
  }
})();