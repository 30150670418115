import angular from 'angular';
import _ from 'lodash';
import { errorServiceModule } from 'sis-components/error-handler/legacy/errorService';
import { localeServiceModule } from 'sis-common/l10n/localeService';
import { localDateRangeFilterModule } from 'sis-components/date/filters/localDateRange/localDateRange.filter';
import { degreeProgramTypeModule } from '../model/degreeProgramType.model';
import { personRuleTypesModule } from '../constant/personRuleTypes.constant';
import { commonEducationServiceModule } from './education.service';
import { commonPersonGroupServiceModule } from './personGroup.service';
import { courseUnitRealisationServiceModule } from './courseUnitRealisation.service';
export const personRuleModule = 'sis-components.service.personRule';
(function () {
  personRuleService.$inject = ["$q", "$translate", "$filter", "localeService", "commonPersonGroupService", "defaultPromiseHandler", "PersonRuleTypeTranslationKeys", "PersonRuleTypes", "commonEducationService", "degreeProgramTypeModel", "commonCourseUnitRealisationService"];
  angular.module(personRuleModule, [localeServiceModule, errorServiceModule, commonPersonGroupServiceModule, personRuleTypesModule, commonEducationServiceModule, degreeProgramTypeModule, localDateRangeFilterModule, courseUnitRealisationServiceModule]).factory('personRuleService', personRuleService);

  /**
   * @ngInject
   */
  function personRuleService(
  // NOSONAR
  $q, $translate, $filter, localeService, commonPersonGroupService, defaultPromiseHandler, PersonRuleTypeTranslationKeys, PersonRuleTypes, commonEducationService, degreeProgramTypeModel, commonCourseUnitRealisationService) {
    function fetchAttainedCreditRangeRuleInfoText(rule) {
      let educationsById = {};
      const degreeProgramTypeName = {};
      const promises = [];
      if (!_.isNil(rule.educationIds)) {
        promises.push(commonEducationService.findByIds(rule.educationIds).then(educations => {
          educationsById = _.keyBy(educations, 'id');
        }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler));
      }
      if (!_.isNil(rule.degreeProgramTypeUrn)) {
        promises.push(degreeProgramTypeModel.find(rule.degreeProgramTypeUrn).then(type => {
          _.assign(degreeProgramTypeName, type.name);
        }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler));
      }
      return $q.all(promises).then(() => {
        let ruleName = $translate.instant(PersonRuleTypeTranslationKeys[rule.type]);
        ruleName += ': ';
        if (!_.has(rule, 'creditRange.max') || _.isNil(rule.creditRange.max)) {
          ruleName += $translate.instant('PERSON_RULE.ATTAINED_CREDIT_RANGE.CREDITS_WITHOUT_MAX', {
            min: rule.creditRange.min
          });
        } else if (rule.creditRange.min === 0) {
          ruleName += $translate.instant('PERSON_RULE.ATTAINED_CREDIT_RANGE.CREDITS_WITHOUT_MIN', {
            max: rule.creditRange.max
          });
        } else {
          ruleName += $translate.instant('PERSON_RULE.ATTAINED_CREDIT_RANGE.CREDITS_WITH_MAX', {
            max: rule.creditRange.max,
            min: rule.creditRange.min
          });
        }
        ruleName += ' \n';
        if (_.isNil(rule.educationIds) || _.isEmpty(rule.educationIds)) {
          ruleName += $translate.instant('PERSON_RULE.ATTAINED_CREDIT_RANGE.ALL_EDUCATIONS');
        } else {
          const educations = [];
          _.forEach(rule.educationIds, id => {
            const education = educationsById[id];
            if (!_.isNil(education)) {
              educations.push(`- ${education.code}, ${localeService.getLocalizedValue(education.name)} (${$filter('localDateRange')(education.validityPeriod)})`);
            }
          });
          ruleName += $translate.instant('PERSON_RULE.ATTAINED_CREDIT_RANGE.EDUCATIONS', {
            educations: `\n${educations.join('\n')}`
          });
        }
        ruleName += ' \n';
        if (_.isNil(rule.degreeProgramTypeUrn)) {
          ruleName += $translate.instant('PERSON_RULE.ATTAINED_CREDIT_RANGE.ALL_DEGREE_PROGRAM_TYPES');
        } else {
          ruleName += $translate.instant('PERSON_RULE.ATTAINED_CREDIT_RANGE.DEGREE_PROGRAM_TYPE', {
            degreeProgramTypeName: localeService.getLocalizedValue(degreeProgramTypeName)
          });
        }
        return ruleName;
      });
    }
    function fetchPersonGroupMembershipRuleInfoText(rule) {
      const ruleName = $translate.instant(PersonRuleTypeTranslationKeys[rule.type]);
      return commonPersonGroupService.findPersonGroupDetailsByIds(rule.personGroupIds).then(groups => _.map(groups, 'name')).then(groupNames => _.map(groupNames, name => localeService.getLocalizedValue(name))).then(localNames => `${ruleName}: ${localNames.join(', ')}`);
    }
    function fetchEnrolmentForCurRuleInfoText(rule) {
      const ruleName = $translate.instant(PersonRuleTypeTranslationKeys[rule.type]);
      return loadCurRelations([rule]).then(curs => _.map(curs, cur => {
        const localizedFullName = localeService.getLocalizedValue(cur.fullName);
        if (cur.flowState === 'PUBLISHED') {
          return localizedFullName;
        }
        const key = `FLOW_STATE.${cur.flowState}`;
        return `${localizedFullName} (${$translate.instant(key)})`;
      })).then(localNames => `${ruleName}:\n${localNames.join('\n')}`);
    }
    function loadPersonGroupMembershipRelations(rules) {
      const personGroupIds = _.chain(rules).flatMap('personGroupIds').compact().uniq().sortBy().value();
      if (_.isEmpty(personGroupIds)) {
        return $q.when();
      }
      return commonPersonGroupService.findPersonGroupDetailsByIds(personGroupIds);
    }
    function loadAttainedCreditsRuleRelations(rules) {
      const educationIds = _.chain(rules).flatMap('educationIds').compact().uniq().sortBy().value();
      if (_.isEmpty(educationIds)) {
        return $q.when();
      }
      return $q.all([commonEducationService.findByIds(educationIds), degreeProgramTypeModel.lazyGetAll()]);
    }
    function loadCurRelations(rules) {
      const courseUnitRealisationIds = _.chain(rules).flatMap('courseUnitRealisationIds').compact().uniq().sortBy().value();
      if (_.isEmpty(courseUnitRealisationIds)) {
        return $q.when();
      }
      return commonCourseUnitRealisationService.findByIds(courseUnitRealisationIds);
    }
    function loadRelationsForRules(rules) {
      const attainedCreditsRules = _.filter(rules, {
        type: PersonRuleTypes.ATTAINED_CREDIT_RANGE
      });
      const personGroupMembershipRules = _.filter(rules, {
        type: PersonRuleTypes.PERSON_GROUP_MEMBERSHIP
      });
      const enrolmentForCurRules = _.filter(rules, {
        type: PersonRuleTypes.ENROLMENT_FOR_COURSE_UNIT_REALISATION
      });
      return $q.all([loadAttainedCreditsRuleRelations(attainedCreditsRules), loadPersonGroupMembershipRelations(personGroupMembershipRules), loadCurRelations(enrolmentForCurRules)])
      // don't return any actual data, we don't guarantee any return format
      .then(() => undefined);
    }
    return {
      getAllRuleTypes() {
        return _.values(PersonRuleTypes);
      },
      fetchRuleInfoText(rule) {
        switch (rule.type) {
          case PersonRuleTypes.PERSON_GROUP_MEMBERSHIP:
            return fetchPersonGroupMembershipRuleInfoText(rule);
          case PersonRuleTypes.ATTAINED_CREDIT_RANGE:
            return fetchAttainedCreditRangeRuleInfoText(rule);
          case PersonRuleTypes.ENROLMENT_FOR_COURSE_UNIT_REALISATION:
            return fetchEnrolmentForCurRuleInfoText(rule);
          default:
            return $q.when($translate.instant(PersonRuleTypeTranslationKeys[rule.type]));
        }
      },
      /**
       * Load data into js-data cache so that we don't need to load related objects separately (between rules).
       * @param enrolmentCalculationResults - array of enrolmentCalculationResult
       * @returns promise that resolves into undefined
       */
      loadRelationsForRuleInfoTextInResults(enrolmentCalculationResults) {
        const ruleResults = _.concat(_.flatMap(enrolmentCalculationResults, 'requirementRules'), _.flatMap(enrolmentCalculationResults, 'orderingRules'));
        const rules = _.map(ruleResults, 'rule');
        return loadRelationsForRules(rules);
      },
      /**
       * Load data into js-data cache so that we don't need to load related objects separately (between rules).
       * @param enrolmentCalculationConfigs - array of enrolmentCalculationConfig
       * @returns promise that resolves into undefined
       */
      loadRelationsForRuleInfoTextInConfigs(enrolmentCalculationConfigs) {
        const rules = _.concat(_.flatMap(enrolmentCalculationConfigs, 'requirementPersonRules'), _.flatMap(enrolmentCalculationConfigs, 'orderingPersonRules'));
        return loadRelationsForRules(rules);
      },
      getRuleName(type) {
        return $translate.instant(PersonRuleTypeTranslationKeys[type]);
      },
      isGroupMembershipRule(type) {
        return type === PersonRuleTypes.PERSON_GROUP_MEMBERSHIP;
      },
      isAttainedCreditRangeRule(type) {
        return type === PersonRuleTypes.ATTAINED_CREDIT_RANGE;
      },
      isEnrolmentForCurRule(type) {
        return type === PersonRuleTypes.ENROLMENT_FOR_COURSE_UNIT_REALISATION;
      }
    };
  }
})();