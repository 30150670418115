import angular from 'angular';
import _ from 'lodash';
import { commonCurriculumPeriodServiceModule } from '../service/curriculumPeriod.service';
import { commonEducationServiceModule } from '../service/education.service';
import { commonPlanServiceModule } from '../service/plan.service';
import { dropdownSelectModule } from '../select/dropdownSelect.component';
import planMessagesTpl from './planMessages.tpl.html';
(function () {
  PlanMessagesController.$inject = ["$q", "$log", "$window", "$translate", "$anchorScroll", "commonMessageService", "NewMessage", "NewMessageType", "AuthService", "backendPollService", "commonCurriculumPeriodService", "commonEducationService", "commonPlanService", "defaultPromiseHandler"];
  angular.module('sis-components.messenger.planMessages', ['sis-common.l10n.localeService', 'pascalprecht.translate', 'sis-common.errorhandler.errorService', 'sis-components.service.commonMessageService', 'sis-components.service.NewMessage', 'sis-components.date.localDateFormatFilter', 'sis-components.checkbox', 'sis-components.messenger.planMessage', commonCurriculumPeriodServiceModule, dropdownSelectModule, commonEducationServiceModule, commonPlanServiceModule, 'tutor.service.backendPoll']).component('planMessages', {
    template: planMessagesTpl,
    controller: PlanMessagesController,
    bindings: {
      plan: '<',
      validatablePlan: '<'
    }
  });
  function PlanMessagesController($q, $log, $window, $translate, $anchorScroll, commonMessageService, NewMessage, NewMessageType, AuthService, backendPollService, commonCurriculumPeriodService, commonEducationService, commonPlanService, defaultPromiseHandler) {
    const $ctrl = this;
    $ctrl.expandedPlans = {};
    function planChanged() {
      $ctrl.newMessage = new NewMessage($ctrl.validatablePlan, $ctrl.plan);
      $ctrl.isStudent = $ctrl.plan.userId === AuthService.personId();
      $ctrl.selectedMessageType = $ctrl.newMessage.newMessageType;
      const messageTypes = $ctrl.isStudent ? [NewMessageType.PLAN_GENERIC, NewMessageType.PLAN_TARGETED] : _.values(NewMessageType);
      $ctrl.messageTypeOptions = messageTypes.map(type => ({
        value: type,
        label: $translate.instant(type)
      }));
    }
    function loadMessages() {
      const promises = [];
      let otherRelevantPlans;
      let otherPlansWithMessages;
      if (!_.get($ctrl.plan, 'learningOpportunityId')) {
        promises.push(commonMessageService.findByPlanId($ctrl.plan.id, true, null).then(result => {
          $ctrl.allRelevantMessages = _.filter(result, plan => plan.id);
        }));
      } else {
        promises.push(commonPlanService.findAllPlansForUser($ctrl.plan.userId, $ctrl.plan.rootId, $ctrl.plan.learningOpportunityId).then(result => {
          otherRelevantPlans = _.filter(result, plan => plan.id !== $ctrl.plan.id);
          const curriculumPeriodIds = _.map(otherRelevantPlans, 'curriculumPeriodId');
          commonCurriculumPeriodService.findCurriculumPeriodsByIds(curriculumPeriodIds).then(cpResult => {
            $ctrl.curriculumPeriodsById = _.keyBy(cpResult, 'id');
          });
        }));
        promises.push(commonMessageService.findForPlansByEducation($ctrl.plan.userId, $ctrl.plan.rootId, $ctrl.plan.learningOpportunityId).then(result => {
          $ctrl.allRelevantMessages = _.filter(result, plan => plan.id);
        }));
      }
      return $q.all(promises).then(() => {
        $ctrl.messagesByPlanId = _.groupBy($ctrl.allRelevantMessages, 'planId');
        otherPlansWithMessages = _.filter(otherRelevantPlans, plan => _.has($ctrl.messagesByPlanId, plan.id));
        expandPlans(otherPlansWithMessages);
        $ctrl.otherPlansWithoutMessages = _.filter(otherRelevantPlans, plan => !_.has($ctrl.messagesByPlanId, plan.id));
        $ctrl.otherPlansWithMessages = _.orderBy(otherPlansWithMessages, plan => _.get(_.first(_.get($ctrl.messagesByPlanId, plan.id)), 'messageTime'), ['desc']);
        $ctrl.messages = _.get($ctrl.messagesByPlanId, $ctrl.plan.id);
        startPollForNewMessages();
      }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    }
    function pollNewMessages() {
      const latestMessageTime = _.get(_.first($ctrl.messages), 'messageTime');
      commonMessageService.findByPlanId($ctrl.plan.id, false, latestMessageTime).then(newMessages => {
        const messageIds = _.map($ctrl.messages, 'id');
        _.forEach(newMessages, newMessage => {
          if (!_.includes(messageIds, newMessage.id)) {
            $ctrl.messages.unshift(newMessage);
          }
        });
      }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    }
    function startPollForNewMessages() {
      backendPollService.start($window);
      backendPollService.removePoll('PLAN_MESSAGES');
      backendPollService.addPoll('PLAN_MESSAGES', pollNewMessages);
    }
    function expandPlans(plans) {
      _.forEach(plans, plan => {
        $ctrl.expandedPlans[plan.id] = true;
      });
    }
    $ctrl.saveNewMessage = () => {
      $ctrl.newMessage.save().then(() => {
        loadMessages(true);
      }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    };
    $ctrl.resetMessageType = () => {
      $ctrl.newMessage.resetMessageType();
    };
    $ctrl.setMessageType = messageType => {
      $ctrl.selectedMessageType = messageType;
      $ctrl.newMessage.setType(messageType).then(angular.noop).catch(() => {
        $ctrl.selectedMessageType = $ctrl.newMessage.newMessageType;
      });
    };
    $ctrl.scrollToOtherPlans = () => {
      $anchorScroll('other-plans');
    };
    $ctrl.togglePlanExpandable = planId => {
      if ($ctrl.expandedPlans[planId]) {
        $ctrl.expandedPlans[planId] = false;
      } else {
        $ctrl.expandedPlans[planId] = true;
      }
    };
    $ctrl.$onChanges = () => {
      planChanged();
      loadMessages();
      commonEducationService.findById($ctrl.plan.rootId).then(education => {
        $ctrl.education = education;
        const learningOpportunities = _.get($ctrl.education, 'structure.learningOpportunities') || [];
        $ctrl.learningOpportunity = _.find(learningOpportunities, lo => lo.localId === $ctrl.plan.learningOpportunityId);
      }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    };
    $ctrl.$onDestroy = () => {
      backendPollService.removePoll('PLAN_MESSAGES');
    };
  }
})();