import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { dateUtils } from 'common-typescript/src/dateUtils';
import { CooperationNetwork, CooperationNetworkValidity, OtmId } from 'common-typescript/types';
import _ from 'lodash';
import { NGXLogger } from 'ngx-logger';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

import { EntityService } from './entity.service';

const CONFIG = {
    ENDPOINTS: {
        backend: '/kori/api',
        getByUniversityOrgId(universityOrgId: OtmId) {
            return `${this.backend}/cooperation-networks/by-university-org-id/${universityOrgId}`;
        },
    },
};

@StaticMembers<DowngradedService>()
@Injectable({
    providedIn: 'root',
})
@NgEntityServiceConfig({
    baseUrl: CONFIG.ENDPOINTS.backend,
    resourceName: 'cooperation-networks',
})
export class CooperationNetworkEntityService extends EntityService<CooperationNetworkState> {

    static downgrade: ServiceDowngradeMappings = {
        dependencies: [],
        moduleName: 'sis-components.service.cooperationNetworkEntityService',
        serviceName: 'cooperationNetworkEntityService',
    };

    constructor(private logger: NGXLogger) {
        super(CooperationNetworkStore, CooperationNetworkQuery);
    }

    getByUniversityOrgId(universityOrgId: OtmId): Observable<CooperationNetwork[]> {
        const url = CONFIG.ENDPOINTS.getByUniversityOrgId(universityOrgId.toString());

        return this.getHttp()
            .get<CooperationNetwork[]>(url)
            .pipe(
                tap(cooperationNetworks => this.store.upsertMany(cooperationNetworks)),
                catchError((err) => {
                    this.logger.error('Failed to fetch cooperation networks', url, err);
                    return throwError(() => err);
                }),
            );
    }

    getCooperationNetworkValidity(cooperationNetwork: CooperationNetwork): CooperationNetworkValidity {
        if (dateUtils.dateInFuture(_.get(cooperationNetwork.validityPeriod, 'startDateTime'))) {
            return 'FUTURE';
        }
        if (dateUtils.dateInPast(_.get(cooperationNetwork.validityPeriod, 'endDateTime'))) {
            return 'PAST';
        }
        return 'ONGOING';
    }
}

type CooperationNetworkState = EntityState<CooperationNetwork, OtmId>;

@StoreConfig({ name: 'cooperation-networks' })
class CooperationNetworkStore extends EntityStore<CooperationNetworkState> {}

class CooperationNetworkQuery extends QueryEntity<CooperationNetworkState> {
    constructor(protected store: CooperationNetworkStore) {
        super(store);
    }
}
