<label [ngClass]="getLabelState(control)" id="cooperation-network-dropdown-label">
  {{ label }}
</label>

<button *ngIf="removeButton"
        type="button"
        class="link-button"
        (click)="removeCooperationNetwork()">
  <sis-icon icon="delete"></sis-icon>
</button>

<sis-dropdown-select [ariaLabelledBy]="'cooperation-network-dropdown-label'"
                     [clearable]="clearable"
                     [options]="options$ | async"
                     [control]="control"
                     [required]="required"
                     data-cy="cooperation-network-dropdown">
</sis-dropdown-select>
