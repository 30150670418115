import * as _ from 'lodash';

import {
    CompositeRule,
    Education,
    ModuleRule,
    PersonalizedSelectionPath,
    Rule,
    StudyRight,
} from '../../../types';

const isStudyRightPersonalized = (studyRight: StudyRight) => !_.isNil(_.get(studyRight, 'personalizedSelectionPath'));

const resolveFirstRule = (personalizedSelectionPath: PersonalizedSelectionPath): Rule => (
    <ModuleRule>{
        localId: 'personalized-module-rule-phase-1-id',
        type: 'ModuleRule',
        moduleGroupId: _.get(personalizedSelectionPath, ['phase1', 'moduleGroupId']),
    });

const resolveSecondRule = (education: Education, personalizedSelectionPath: PersonalizedSelectionPath): Rule =>
    !_.isNil(_.get(personalizedSelectionPath, ['phase2', 'moduleGroupId'])) ?
        <ModuleRule>{
            localId: 'personalized-module-rule-phase-2-id',
            type: 'ModuleRule',
            moduleGroupId: _.get(personalizedSelectionPath, ['phase2', 'moduleGroupId']),
        } :
        Object.assign({}, (<CompositeRule>education.rule).rules[1]);

const hasTwoPhases = (education: Education) =>
    _.size(_.get(education, ['rule', 'rules'])) === 2;

const resolveRules = (education: Education, personalizedSelectionPath: PersonalizedSelectionPath) => {
    const result = [resolveFirstRule(personalizedSelectionPath)];
    if (hasTwoPhases(education)) {
        result.push(resolveSecondRule(education, personalizedSelectionPath));
    }
    return result;
};

const createPersonalizedRule = (education: Education, personalizedSelectionPath: PersonalizedSelectionPath) => <CompositeRule>{
    localId: 'personalized-composite-rule-id',
    type: 'CompositeRule',
    require: {
        min: hasTwoPhases(education) ? 2 : 1,
        max: hasTwoPhases(education) ? 2 : 1,
    },
    rules: resolveRules(education, personalizedSelectionPath),
};

/**
 * Creates a root rule combining education's rule and possible rules from student's study right.
 * If study right is not personalized or undefined, education's rule is returned.
 *
 * @param education module
 * @param studyRight to use for resolving root rule
 */
const createRootRule = (education: Education, studyRight: StudyRight): CompositeRule =>
    isStudyRightPersonalized(studyRight) ?
        createPersonalizedRule(education, _.get(studyRight, 'personalizedSelectionPath')) :
        <CompositeRule>education.rule;

export { isStudyRightPersonalized, createRootRule };
