import {
    ChangeDetectionStrategy,
    Component, EventEmitter,
    Input, OnInit, Output,
    ViewEncapsulation,
} from '@angular/core';
import { ValidatablePlan } from 'common-typescript';
import { CourseUnit, GradeRaiseAttempt, OtmId } from 'common-typescript/types';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { ModalService } from 'sis-common/modal/modal.service';
import { ComponentDowngradeMappings } from 'sis-common/types/angular-hybrid';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { CourseUnitEntityService } from 'sis-components/service/course-unit-entity.service';

import {
    CompletionMethodSelections,
    SelectCompletionMethodComponent, SelectCompletionMethodDialogValues,
} from '../../../plan/select-completion-method/select-completion-method.component';

@Component({
    selector: 'app-course-unit-grade-raise-info',
    templateUrl: './course-unit-grade-raise-info.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourseUnitGradeRaiseInfoComponent implements OnInit {
    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'student.courseUnitGradeRaiseInfo',
        directiveName: 'appCourseUnitGradeRaiseInfo',
    };

    @Input({ required: true }) gradeRaiseAttempt: GradeRaiseAttempt;
    @Input({ required: true }) validatablePlan: ValidatablePlan;
    @Output() selectCompletionMethod = new EventEmitter<CompletionMethodSelections>();

    courseUnit$: Observable<CourseUnit>;

    constructor(
        private modalService: ModalService,
        private courseUnitEntityService: CourseUnitEntityService,
        private appErrorHandler: AppErrorHandler,
    ) {
    }

    ngOnInit(): void {
        this.courseUnit$ = this.getCourseUnit(this.gradeRaiseAttempt.courseUnitId);
    }

    getCompletionMethodIndex(courseUnit: CourseUnit): number {
        const index = courseUnit.completionMethods.map(completionMethod =>
            completionMethod.localId).indexOf(this.gradeRaiseAttempt.completionMethodId);
        return index >= 0 ? index + 1 : undefined;
    }

    openDialog(courseUnit: CourseUnit) {
        this.modalService.open(SelectCompletionMethodComponent, {
            assessmentItemSelections: this.gradeRaiseAttempt?.selectedAssessmentItemIds ?? [],
            validatablePlan: this.validatablePlan,
            completionMethodId: this.gradeRaiseAttempt?.completionMethodId,
            courseUnit,
            isGradeRaiseAttempt: true,
            gradeRaiseAttempt: this.gradeRaiseAttempt,
        } as SelectCompletionMethodDialogValues, { size: 'sm' }).closed
            .subscribe((completionMethodSelections: CompletionMethodSelections) => {
                this.selectCompletionMethod.emit(completionMethodSelections);
            });
    }

    getOpenDialogTranslationKeyWhenCompletionMethodSelected(courseUnit: CourseUnit): string {
        return courseUnit.completionMethods?.length === 1 ? 'PLAN.VIEW_COMPLETION_METHOD' : 'PLAN.CHANGE_COMPLETION_METHOD';
    }

    getCourseUnit(courseUnitId: OtmId) {
        return this.courseUnitEntityService.getById(courseUnitId)
            .pipe(
                take(1),
                this.appErrorHandler.defaultErrorHandler());
    }
}
