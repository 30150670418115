<div *ngFor="let mobilityPeriod of mobilityPeriods">
  <sis-basic-grouping-panel [attr.data-cy]="'mobility-period-panel'" [level]="6"
                            [heading]="(mobilityPeriod.mobilityTypeUrn|codeLocalizedName|async) + ' ' + (mobilityPeriod.activityPeriod | localDateRange)">
    <sis-tiny-badge *ngIf="mobilityPeriod.isCancelled" variant="danger" class="header-badge">
      {{'FLOW_STATE.CANCELLED' | translate}}
    </sis-tiny-badge>
    <sis-description-list [columnCount]="1">
      <dl>
        <ng-template #listItem *ngIf="mobilityPeriod.mobilityDirection === 'OUTBOUND'">
          <dt class="sis-label">{{ 'MOBILITY_PERIOD.OUTBOUND_STUDENT.PHASE' | translate}}</dt>
          <dd *ngIf="mobilityPeriod.phase === 'PHASE1'">{{ education.structure.phase1.name | localizedString }}</dd>
          <dd *ngIf="mobilityPeriod.phase === 'PHASE2'">{{ education.structure.phase2.name | localizedString }}</dd>
        </ng-template>
        <ng-template #listItem *ngIf="mobilityPeriod.mobilityDirection === 'INBOUND'">
          <dt class="sis-label">{{ 'MOBILITY_PERIOD.INBOUND_STUDENT.PHASE' | translate }}</dt>
          <dd>{{ mobilityPeriod.mobilityStudyRightTypeUrn|codeLocalizedName|async }}</dd>
        </ng-template>
        <ng-template #listItem>
          <dt class="sis-label">{{ 'MOBILITY_PERIOD.MOBILITY_PROGRAM' | translate }}</dt>
          <dd>
            {{mobilityPeriod.mobilityProgramUrn|codeLocalizedName|async}}
            <ng-container *ngIf="mobilityPeriod.mobilityProgramDescription">
              , {{ mobilityPeriod.mobilityProgramDescription }}
            </ng-container>
          </dd>
        </ng-template>
        <ng-template #listItem>
          <dt class="sis-label" translate>
            {{'MOBILITY_PERIOD.' + (mobilityPeriod.mobilityDirection === 'OUTBOUND' ? 'OUT' : 'IN') + 'BOUND_STUDENT.DESTINATION_COUNTRY'}}
          </dt>
          <dd>{{ mobilityPeriod.countryUrn|codeLocalizedName|async }}</dd>
        </ng-template>
        <ng-template #listItem>
          <dt class="sis-label">{{ 'MOBILITY_PERIOD.ORGANISATION' | translate }}</dt>
          <dd *ngIf="mobilityPeriod.internationalInstitutionUrn">
            {{ mobilityPeriod.internationalInstitutionUrn|codeLocalizedName|async }}
          </dd>
          <dd *ngIf="!mobilityPeriod.internationalInstitutionUrn">{{ mobilityPeriod.organisation }}</dd>
        </ng-template>
        <ng-template #listItem *ngIf="mobilityPeriod.virtualMobilityType">
          <dt class="sis-label">{{ 'MOBILITY_PERIOD.VIRTUAL_EXCHANGE_TITLE' | translate }}</dt>
          <dd data-cy="mobility-period-virtual-mobility-type">
            {{ 'MOBILITY_PERIOD.VIRTUAL_EXCHANGE_' + mobilityPeriod.virtualMobilityType.toUpperCase() | translate }}
          </dd>
        </ng-template>
      </dl>
    </sis-description-list>
  </sis-basic-grouping-panel>
</div>

<div *ngIf="!mobilityPeriods.length" class="sis-guidance">{{ 'MOBILITY_PERIOD.NO_PRIOR_MOBILITY_INFO' | translate }}</div>
