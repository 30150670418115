<div class="row" *ngIf="studyFields?.length > 0 || searchTags?.length > 0">
  <div class="col-md-12">
    <sis-description-list>
      <dl> <!-- This dl element is just to trick SonarQube, it won't actually end up in the final HTML at all -->
        <ng-template #listItem *ngIf="studyFields?.length > 0">
          <dt class="label">{{'STUDIES.COURSE_UNIT_INFO_MODAL.STUDY_FIELDS' | translate}}</dt>
          <dd class="value">
            <ul class="no-bullets">
              <li *ngFor="let studyFieldUrn of studyFields">
                {{ studyFieldUrn | codeLocalizedName | async }}
              </li>
            </ul>
          </dd>
        </ng-template>
        <ng-template #listItem *ngIf="searchTags?.length > 0">
          <dt class="label">{{'STUDIES.COURSE_UNIT_INFO_MODAL.SEARCH_TAGS' | translate}}</dt>
          <dd class="value">
            <ul class="no-bullets">
              <li *ngFor="let tag of searchTags">
                {{tag}}
              </li>
            </ul>
          </dd>
        </ng-template>
      </dl>
    </sis-description-list>
  </div>
</div>
<div *ngIf="studyFields?.length === 0 && searchTags?.length === 0">
  <span>{{ 'SIS_COMPONENTS.COURSE_UNIT_REALISATION_INFO.NO_INFORMATION_GIVEN' | translate }}</span>
</div>
