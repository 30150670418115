import angular from 'angular';
import { PlanSelectComponent } from '../common/components/plan-select/plan-select.component.ts';
import plansTpl from './plans.tpl.html';
export const plansModule = 'student.tutoring.plans';
angular.module(plansModule, ['sis-components.date.localDateFormatFilter', PlanSelectComponent.downgrade.moduleName]).component('plans', {
  template: plansTpl,
  controller: ["$state", function ($state) {
    this.state = $state;
  }],
  bindings: {
    plans: '<',
    selectedPlan: '<'
  }
});