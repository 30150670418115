'use strict';

angular.module('student.frontpage.studyProgressGraph').factory('studyProgressGraphResults', ["studyProgressGraphConfig", "commonGradeAverageService", "commonGradeScaleService", "moduleType", "planValidationResultHelper", function (studyProgressGraphConfig, commonGradeAverageService, commonGradeScaleService, moduleType, planValidationResultHelper) {
  /**
   * @deprecated Use `StudyProgressGraphResultService` instead
   */
  return {
    getResults: function (planContext, module) {
      const validatablePlan = _.get(planContext, 'validatablePlan');
      var moduleFromPlanContext = planContext.getModule(module.id);
      var plannedModule = planContext.getPlannedModule(moduleFromPlanContext.groupId);
      var results = {};
      const relevantAttainments = validatablePlan.getAllCourseUnitAndCustomCourseUnitAttainmentsUnderModule(plannedModule.id);
      const subModules = _.filter(validatablePlan.getSelectedModulesUnderModule(plannedModule), childModule => childModule.type !== 'GroupingModule');
      const planValidationResult = planContext.getPlanValidationResult();
      const moduleValidationResult = planValidationResultHelper.getModuleValidationResult(plannedModule.id, planValidationResult);
      if (subModules.length === 0 && relevantAttainments.length === 0 && !moduleValidationResult) {
        return 0;
      }
      commonGradeScaleService.getGradeScale('sis-0-5').then(function (gradeScale) {
        return getGradeAverageResult(module.id, planContext.validatablePlan, gradeScale);
      }).then(function (gradeAverageResult) {
        var average = _.get(gradeAverageResult, 'gradeAverage');
        if (_.isNumber(average)) {
          results.average = +average.toFixed(2);
        }
      });

      // study year dates
      var currentYear = new Date().getFullYear();
      var studyYear = moment().isBefore(currentYear + '-08-01', 'days') ? currentYear - 1 : currentYear;
      var studyYearDates = {
        start: studyYear + '-08-01',
        end: studyYear + 1 + '-08-01'
      };
      var thisYearsAttainments = _.filter(relevantAttainments, function (attainment) {
        return moment(attainment.attainmentDate).isBetween(studyYearDates.start, studyYearDates.end, 'days', '[)');
      });
      // Vuoden opintopistetavoite
      results.yearAttained = {
        from: _.sumBy(thisYearsAttainments, 'credits'),
        to: studyProgressGraphConfig.studies.TARGET_YEARLY
      };

      // Suunniteltu
      results.planned = {
        name: moduleFromPlanContext.name,
        from: moduleValidationResult.plannedCredits.min,
        to: plannedModule.targetCredits.min
      };
      results.planned.percentage = percentage(results.planned.from, results.planned.to);

      // Suoritettu
      results.attained = {
        name: moduleFromPlanContext.name,
        from: moduleValidationResult.attainedCredits,
        to: plannedModule.targetCredits.min
      };
      results.attained.percentage = percentage(results.attained.from, results.attained.to);
      results.subModuleResults = getGraphDataForModules(subModules, planValidationResult);
      return results;
    }
  };
  function getGradeAverageResult(moduleId, validatablePlan, gradeScale) {
    var module = validatablePlan.getModule(moduleId);
    var attainmentIds = commonGradeAverageService.getAttainmentIdsForModule(module, validatablePlan).sort();
    var allAttainments = _.orderBy(_.values(validatablePlan.attainmentsById), 'id');
    var method = 'COURSE_UNIT_ARITHMETIC_MEAN_WEIGHTING_BY_CREDITS';
    return commonGradeAverageService.calculateGradeAverage(attainmentIds, allAttainments, gradeScale, method);
  }
  function getGraphDataForModules(subModules, planValidationResult) {
    return _.map(subModules, module => {
      const moduleValidationResult = planValidationResultHelper.getModuleValidationResult(_.get(module, 'id'), planValidationResult);
      return {
        planned: {
          name: module.name,
          from: _.get(moduleValidationResult, 'plannedCredits.min'),
          to: module.targetCredits.min,
          percentage: percentage(_.get(moduleValidationResult, 'plannedCredits.min'), module.targetCredits.min)
        },
        attained: {
          name: module.name,
          from: _.get(moduleValidationResult, 'attainedCredits'),
          to: module.targetCredits.min,
          percentage: percentage(_.get(moduleValidationResult, 'attainedCredits'), module.targetCredits.min)
        }
      };
    });
  }
  // never return over 100%
  function percentage(n, m) {
    if (_.isNil(n) || n === 0 || n === 0 && m === 0) {
      return 0;
    }
    if (_.isNil(m) || m === 0) {
      return 100;
    }
    var result = Math.floor(n / m * 100);
    return result > 100 ? 100 : result;
  }
}]);