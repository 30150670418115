export const enrolmentModelModule = 'sis-components.model.enrolment';
'use strict';
(function () {
  enrolmentJSDataModel.$inject = ["DS", "ENROLMENT_URL"];
  angular.module(enrolmentModelModule, ['js-data', 'sis-components.model.courseUnitRealisation', 'sis-components.model.assessmentItem']).factory('enrolmentJSDataModel', enrolmentJSDataModel).constant('ENROLMENT_URL', {
    DEFAULT: '/ilmo/api/enrolments',
    MY_ENROLMENTS: '/ilmo/api/my-enrolments'
  }).constant('ENROLMENT_COLORS', ['silver', 'appelsiini', 'kaakao', 'minttu', 'flamingo', 'auringonkukka', 'topaasi', 'karviainen', 'kirva', 'ametisti', 'hiekka', 'kesataivas']).run(["enrolmentJSDataModel", enrolmentJSDataModel => {}]);

  /**
   * @ngInject
   */
  function enrolmentJSDataModel(DS, ENROLMENT_URL) {
    return DS.defineResource({
      idAttribute: 'id',
      name: 'enrolment',
      endpoint: ENROLMENT_URL.DEFAULT,
      relations: {
        hasOne: {
          courseUnitRealisation: {
            localField: 'courseUnitRealisation',
            localKey: 'courseUnitRealisationId'
          },
          assessmentItem: {
            localField: 'assessmentItem',
            localKey: 'assessmentItemId'
          },
          courseUnit: {
            localField: 'courseUnit',
            localKey: 'courseUnitId'
          }
        }
      }
    });
  }
})();