<ng-container *transloco="let t">
  <fudis-expandable [title]="t('FREE_EDIT.COURSE_UNIT_EXPANDABLE.TITLE')" [closed]="false">
    <ng-template fudisContent [type]="'expandable'">
      <fudis-heading [level]="3">{{ t('FREE_EDIT.COURSE_UNIT_EXPANDABLE.ADD_COURSE_UNITS') }}</fudis-heading>
      <label for="add_cu">{{ t('FREE_EDIT.COURSE_UNIT_EXPANDABLE.SEARCH_COURSE_UNITS_LABEL') }}</label>
      <sis-typeahead
        id="add_cu"
        (selectItem)="selectCourseUnitFromSelectList($event.item)"
        [placeholder]="t('FREE_EDIT.COURSE_UNIT_EXPANDABLE.SEARCH_COURSE_UNITS_PLACEHOLDER')"
        [typeahead]="getCourseUnitSearchSuggestions.bind(this)"
        [resultTemplate]="courseUnitSearchResult">
      </sis-typeahead>
      <p class="help-block">{{t('FREE_EDIT.COURSE_UNIT_EXPANDABLE.SEARCH_COURSE_UNITS_HELP_TEXT')}}</p>
      <div class="sis-pt-sm" *ngFor="let courseUnit of allCourseUnits">
        <sis-plan-structure-course-unit
          [courseUnit]="courseUnit"
          [planStateObject]="planStateObject"
          [planData]="planData"
          [headingLevel]="4"
          [action]="'select-to-plan'"
          [selected]="validatablePlan.isCourseUnitInPlan(courseUnit)"
          (actionChange)="handleToggle({
            courseUnit: courseUnit,
            isInPlan: validatablePlan.isCourseUnitInPlan(courseUnit),
          })">
        </sis-plan-structure-course-unit>
      </div>
    </ng-template>
  </fudis-expandable>

  <ng-template #courseUnitSearchResult let-result="result">
    <div class="course-unit-search__label">
      <p class="course-unit-search__label__main">
        <span>{{ result.name }}</span>
        <span *ngIf="result?.credits">{{' (' + (result.credits | creditRange) + ')'}}</span>
      </p>
      <p class="course-unit-search__label__sub">{{ result.code }} | {{ t('FREE_EDIT.COURSE_UNIT_EXPANDABLE.COURSE_UNIT')}}</p>
    </div>
  </ng-template>
</ng-container>


