<ng-container *transloco="let t">
  <div
    *ngIf="!completionMethods || completionMethods.length === 0">{{t('STUDIES.COURSE_UNIT_INFO_MODAL.NO_COMPLETION_METHODS')}}</div>
  <div *ngIf="assessmentItemsById">
    <table class="sis-table table-completion-method-matrix" [attr.aria-label]="t('COMPLETION_METHOD_MATRIX')" *ngIf="completionMethods?.length > 0">
      <thead>
      <tr>
        <th *ngFor="let completionMethod of completionMethods; index as cmIndex"
            [class.is-highlighted]="highlightedCompletionMethodIndex === cmIndex"
            class="col-completion-method-matrix-completion-selection">
          <button type="button" class="non-button completion-method-container"
                  [attr.data-cy]="'completion-method-button-' + completionMethod.localId" (click)="highlightCompletionMethod(cmIndex)">
            <span class="completion-method-header"
                  [attr.aria-label]="t('ARIA_LABEL.VIEW_COMPLETION_METHOD_INFO') + ' ' +
                                     t('STUDIES.COURSE_UNITS_COMPLETION_METHODS.METHOD') + ' ' + (cmIndex + 1)">
              {{t('STUDIES.COURSE_UNITS_COMPLETION_METHODS.METHOD')}} {{cmIndex + 1}}
            </span>
            <sis-tiny-badge variant="secondary">
              {{t(('STUDIES.COURSE_UNITS_COMPLETION_METHODS.COMPLETION_METHOD.STUDY_TYPE.' +
              (completionMethod.studyType === 'OPEN_UNIVERSITY_STUDIES' ? 'OPEN_UNIVERSITY_STUDIES' : 'DEGREE_STUDIES')))}}
            </sis-tiny-badge>
          </button>
        </th>
        <th class="completion-method-table-title">
          <h4>{{t('STUDIES.COURSE_UNIT_INFO_MODAL.ASSESSMENT_ITEM_HEADER')}}</h4>
        </th>
        <th class="completion-method-table-title">
          <h4>{{t('STUDIES.COURSE_UNIT_INFO_MODAL.COURSE_UNIT_REALISATIONS')}}</h4>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let assessmentItemId of orderedAssessmentItemIds; index as rowIndex"
          [class.highlighted-completion-methods]="completionMethodContainsAssessmentItemId(highlightedCompletionMethodIndex, assessmentItemId)"
          [class.is-selected]="isAssessmentItemSelected(rowIndex)"
          class="col-completion-method-matrix-assessment-item">
        <td *ngFor="let completionMethod of completionMethods; index as colIndex"
            class="col-completion-method-matrix-completion-selection"
            [class.is-highlighted]="highlightedCompletionMethodIndex === colIndex">
          <sis-icon icon="dot" *ngIf="completionMethodContainsAssessmentItemId(colIndex, assessmentItemId)"></sis-icon>
        </td>
        <td class="assessment-items">
          {{ assessmentItemsById[assessmentItemId] | assessmentItemNameInCourseUnitContext: courseUnit | localizedString }}
          ({{ assessmentItemsById[assessmentItemId].credits | creditRange }})
        </td>
        <td class="course-unit-realisations">
          <ng-container *ngIf="courseUnitRealisationsReady">
            <span class="course-unit-realisations-list"
                  *ngFor="let courseUnitRealisation of getCourseUnitRealisationsForAssessmentItem(assessmentItemsById[assessmentItemId])">
              <sis-button *ngIf="completionMethodContainsAssessmentItemId(highlightedCompletionMethodIndex, assessmentItemId)"
                          [link]="true" (clicked)="navigateToCurSection(courseUnitRealisation)">
                {{ courseUnitRealisation | courseUnitRealisationFullName | localizedString}}
              </sis-button>
              <ng-container *ngIf="!completionMethodContainsAssessmentItemId(highlightedCompletionMethodIndex, assessmentItemId)">
                {{ courseUnitRealisation | courseUnitRealisationFullName | localizedString }}
              </ng-container>
              <sis-cur-flow-state-badge [courseUnitRealisation]="courseUnitRealisation" [hidePublishDate]="true"></sis-cur-flow-state-badge>
            </span>
            <span *ngIf="getCourseUnitRealisationsForAssessmentItem(assessmentItemsById[assessmentItemId])?.length === 0" class="guidance">
              {{t('STUDIES.COURSE_UNIT_INFO_MODAL.NO_COURSE_UNIT_REALISATIONS')}}
            </span>
          </ng-container>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="completionMethods?.length > 0 && assessmentItemsById" class="completion-method-info-container">
    <h2>{{t('STUDIES.COURSE_UNITS_COMPLETION_METHODS.METHOD_LONG', { n: highlightedCompletionMethodIndex + 1 })}}</h2>

    <sis-completion-method-assessment-item-info [completionMethod]="highlightedCompletionMethod"
                                                [language]="language"
                                                [useLocalizationFallback]="false"
                                                [showEmptyFields]="true">

    </sis-completion-method-assessment-item-info>

    <h2>{{t('STUDIES.COURSE_UNIT_INFO_MODAL.ASSESSMENT_ITEMS')}}</h2>
    <div *ngIf="completionMethodAssessmentItems?.length === 0" class="form-group-mimic">
      <ng-container *ngTemplateOutlet="noContent"></ng-container>
    </div>
    <div *ngFor="let assessmentItem of completionMethodAssessmentItems">
      <sis-expandable [level]="3"
                      [title]="(assessmentItem | assessmentItemNameInCourseUnitContext: courseUnit | localizedString) + ' ' + (assessmentItem.credits | creditRange)"
                      [closed]="!isAssessmentItemExpandedById[assessmentItem.id]"
                      (closedChange)="toggleAssessmentItem($event, assessmentItem.id)">
        <ng-template sisExpandableContent>
          <div class="row">
            <div class="col-12 col-md-6 form-group-mimic">
              <h4>{{t('STUDIES.COURSE_UNIT_ASSESSMENT_ITEMS.NAME_SPECIFIER')}}</h4>
              <p class="form-control-static">{{ assessmentItem.nameSpecifier | localizedString }}</p>
            </div>
            <div class="col-12 col-md-6 form-group-mimic">
              <h4>{{t('STUDIES.COURSE_UNIT_ASSESSMENT_ITEMS.TYPE')}}</h4>
              <p class="form-control-static">{{ assessmentItem.assessmentItemType | codeLocalizedName | async }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6 form-group-mimic">
              <h4>{{t('STUDIES.COURSE_UNIT_ASSESSMENT_ITEMS.POSSIBLE_ATTAINMENT_LANGUAGES')}}</h4>
              <p class="form-control-static" *ngFor="let languageUrn of assessmentItem.possibleAttainmentLanguages">{{ languageUrn | codeLocalizedName | async }}</p>
              <p *ngIf="assessmentItem.possibleAttainmentLanguages?.length === 0" class="form-control-static">
                <ng-container *ngTemplateOutlet="noContent"></ng-container>
              </p>
            </div>
            <div class="col-12 col-md-6 form-group-mimic">
              <h4>{{t('STUDIES.COURSE_UNIT_ASSESSMENT_ITEMS.STUDY_FORMAT')}}</h4>
              <p class="form-control-static">
                <sis-course-unit-info-modal-text-field [localizedMarkupStringValue]="assessmentItem.studyFormat" [language]="language"></sis-course-unit-info-modal-text-field>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6 form-group-mimic">
              <h4>{{t('STUDIES.COURSE_UNIT_ASSESSMENT_ITEMS.LEARNING_MATERIAL')}}</h4>
              <p class="form-control-static">
                <sis-course-unit-info-modal-text-field [localizedMarkupStringValue]="assessmentItem.learningMaterial" [language]="language"></sis-course-unit-info-modal-text-field>
              </p>
            </div>
            <div class="col-12 col-md-6 form-group-mimic">
              <h4>{{t('STUDIES.COURSE_UNIT_ASSESSMENT_ITEMS.LITERATURE')}}</h4>
              <ng-container *ngIf="assessmentItem.literature?.length > 0; else noContent">
                <sis-markup-string-view *ngFor="let item of assessmentItem.literature" [content]="item | literature">
                </sis-markup-string-view>
              </ng-container>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6 form-group-mimic">
              <h4>{{t('STUDIES.COURSE_UNIT_ASSESSMENT_ITEMS.CONTENT_DESCRIPTION')}}</h4>
              <p class="form-control-static">
                <sis-course-unit-info-modal-text-field [localizedMarkupStringValue]="assessmentItem.contentDescription" [language]="language"></sis-course-unit-info-modal-text-field>
              </p>
            </div>
            <div class="col-12 col-md-6 form-group-mimic">
              <h4>{{t('STUDIES.COURSE_UNIT_ASSESSMENT_ITEMS.GRADING')}}</h4>
              <p class="form-control-static">
                <sis-course-unit-info-modal-text-field [localizedMarkupStringValue]="assessmentItem.grading" [language]="language"></sis-course-unit-info-modal-text-field>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6 form-group-mimic">
              <h4>{{t('STUDIES.COURSE_UNIT_ASSESSMENT_ITEMS.STUDY_FIELD')}}</h4>
              <p class="form-control-static">{{ assessmentItem.studyField | codeLocalizedName | async }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 teacher form-group-mimic">
              <h4>{{t('STUDIES.COURSE_UNIT_ASSESSMENT_ITEMS.RESPONSIBILITY_INFOS')}}</h4>
              <sis-module-responsibility-info [responsibilityInfos]="assessmentItem.responsibilityInfos" [language]="language"></sis-module-responsibility-info>
              <p *ngIf="assessmentItem.responsibilityInfos?.length === 0" class="form-group-mimic form-control-static">
                <ng-container *ngTemplateOutlet="noContent"></ng-container>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6 form-group-mimic">
              <sis-responsible-organisation-info [organisations]="assessmentItem.organisations"></sis-responsible-organisation-info>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <h4>{{t('STUDIES.COURSE_UNIT_INFO_MODAL.COURSE_UNIT_REALISATIONS')}}</h4>
              <ng-container *ngFor="let courseUnitRealisation of getCourseUnitRealisationsForAssessmentItem(assessmentItem)">
                <div id="{{courseUnitRealisation.id}}">
                  <sis-expandable [variant]="'naked-dotted'"
                                  [closed]="!isCourseUnitRealisationExpandedById[courseUnitRealisation.id]"
                                  (closedChange)="toggleCourseUnitRealisation($event, courseUnitRealisation.id)"
                                  [title]="courseUnitRealisation | courseUnitRealisationFullName | localizedString"
                                  [level]="5">
                    <ng-template sisExpandableCustomTitle
                                 *ngIf="courseUnitRealisation.flowState === 'CANCELLED' || courseUnitRealisation.flowState === 'ARCHIVED'">
                      <sis-tiny-badge variant="secondary">{{('FLOW_STATE.' + courseUnitRealisation.flowState) | translate}}</sis-tiny-badge>
                    </ng-template>
                    <ng-template sisExpandableContent>
                      <sis-course-unit-info-modal-course-unit-realisation [courseUnitRealisation]="courseUnitRealisation"
                                                                          [language]="language"></sis-course-unit-info-modal-course-unit-realisation>
                    </ng-template>
                  </sis-expandable>
                </div>
              </ng-container>
              <p class="guidance" *ngIf="getCourseUnitRealisationsForAssessmentItem(assessmentItem)?.length === 0">
                {{t('STUDIES.COURSE_UNIT_INFO_MODAL.NO_COURSE_UNIT_REALISATIONS')}}
              </p>
            </div>
          </div>
        </ng-template>
      </sis-expandable>
    </div>
  </div>

  <ng-template #noContent>
    <span class="sis-placeholder">
    <sis-icon icon="alert-fill-attention"></sis-icon>{{t('SIS_COMPONENTS.COURSE_UNIT_REALISATION_INFO.NO_INFORMATION_GIVEN')}}
    </span>
  </ng-template>
</ng-container>
