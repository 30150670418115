<div ngbDropdown>
  <button type="button"
          ngbDropdownToggle
          class="form-control form-control-inverted"
          [attr.aria-label]="selectedPlan?.name + '. ' + ('ARIA_LABEL.SELECT_PLAN_NOW_SELECTED' | translate)">
    {{selectedPlan?.name}}
    <sis-icon icon="chevron" iconClass="cw-90" color="white"></sis-icon>
  </button>
  <div ngbDropdownMenu class="multiple-line-items" role="menu">
    <ng-container *ngFor="let option of options">
      <span *ngIf="option.education" class="dropdown-header" role="heading">
        {{option.education.name | localizedString}}
      </span>
      <button *ngIf="!option.education && option.plan"
              ngbDropdownItem role="option"
              (click)="onSelect(option.id)"
              [attr.aria-current]="option.id === selectedPlan?.id"
              [class.active]="option.id === selectedPlan?.id">
        {{option.plan.name}}
        <span *ngIf="option.id === selectedPlan?.id" class="visually-hidden">{{'ARIA_LABEL.SELECTED' | translate}}</span>
        <span *ngIf="option.plan.primary"
              class="tiny-badge success"
              data-cy="study-plan-primary-badge">
          {{'PRIMARY_STUDY_PLAN' | translate}}
        </span>
        <span class="additional-data">
          {{'STUDY_PLAN_MODIFIED' | translate:{modified: option.plan.metadata.lastModifiedOn | localDateFormat} }}
        </span>
      </button>
    </ng-container>
  </div>
</div>
