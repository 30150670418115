import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-toggle',
    templateUrl: './toggle.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ToggleComponent {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sisComponents.downgraded.toggle',
        directiveName: 'sisToggle',
    };

    @Input() name: string;
    @Input() label: string;
    @Input() disabled: boolean;
    @Input() required: boolean;
    @Input() inverted: boolean;
    @Input() checked: boolean;
    /** If `true`, the toggle will be automatically focused when rendered. */
    @Input() autofocus = false;
    /** The id of an element that contains additional info related to the selection. */
    @Input() ariaDescribedBy: string;

    @Output() checkedChange = new EventEmitter<boolean>();
}
