<div class="container sis-pt-md app-full-calendar__header-and-calendar-container">
  <div class="app-full-calendar__header-and-calendar-container__header sis-pb-sm">
    <div #breadcrumbsContainer>
      <sis-breadcrumbs></sis-breadcrumbs>
    </div>
    <div class="row align-items-center">
      <div class="col-xl-6">
        <sis-page-title [title]="'CALENDAR_TITLE' | transloco" [showCrumbs]="true"></sis-page-title>
      </div>
      <div class="col-xl-6">
        <div class="row d-md-none sis-pb-xs">
          <sis-button (clicked)="toggleRightBar.emit()">
            {{ 'OPEN_SIDEBAR' | transloco }}
          </sis-button>
        </div>
        <div class="row">
          <div class="d-flex flex-column flex-sm-row justify-content-start justify-content-xl-end">
            <sis-button [hollow]="true"
                        (clicked)="addOwnCalendarEvent()"
                        data-cy="add-custom-event-button"
                        class="sis-pb-xs pb-sm-0">
              {{ 'CALENDAR_ADD_CALENDAR_EVENT' | transloco }}
            </sis-button>
            <sis-button [hollow]="true"
                        (clicked)="openCalendarSettings()"
                        data-cy="export-calendar-events-button">
              {{ 'CALENDAR_SETTINGS.TAKE_TO_OWN' | transloco }}
            </sis-button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 sis-pt-xs">
        <div class="float-start float-xl-end">
          <sis-checkbox
            data-cy="toggle-cancelled-event-visibility"
            (checkedChange)="toggleCancelledEventVisibility()"
            [label]="'CALENDAR_SETTINGS.HIDE_CANCELLED_EVENTS'"
            [checked]="cancelledEventsHidden"/>
        </div>
      </div>
    </div>
  </div>
  <div class="app-full-calendar__header-and-calendar-container__calendar">
    <ng-container *ngIf="calendarOptions | async as options">
      <full-calendar #fullCalendar #calendarContainer [options]="options">
        <ng-template #eventContent let-arg>
          <app-study-event *ngIf="arg.event.extendedProps.type === 'CALENDAR_EVENT'" [viewType]="arg.view.type" [event]="arg.event" [getValidatablePlan]="getValidatablePlan"/>
          <app-own-calendar-event *ngIf="arg.event.extendedProps.type === 'OWN_CALENDAR_EVENT'" [viewType]="arg.view.type" [event]="arg.event"/>
        </ng-template>
      </full-calendar>
    </ng-container>
  </div>
</div>
