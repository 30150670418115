import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Organisation, SearchFilterToggleValueEvent } from 'common-typescript/types';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { SisFormBuilder } from '../../form/sis-form-builder.service';
import { filterInput } from '../../typeahead/typeahead.component';
import { convertAJSPromiseToNative } from '../../util/utils';

type FilterValue = Organisation;

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-search-filter-organisation',
    templateUrl: './search-filter-organisation.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class SearchFilterOrganisationComponent implements OnChanges, OnInit {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.downgraded.search.searchFilterOrganisation',
        directiveName: 'sisSearchFilterOrganisation',
    };

    @Input() filterName: string;
    @Input() getFilterName: (filter: FilterValue) => string;
    @Input() getOrganisations: (searchString: string) => Promise<FilterValue[]>;
    @Input() helpText: string;
    @Input() listSelected: () => FilterValue[];
    @Output() openOrganisationModal = new EventEmitter<void>();
    @Input() popoverIsOpen: boolean;
    @Input() selectedOrganisation?: FilterValue;
    @Output() submitFilter = new EventEmitter<void>();
    @Output() toggleValue = new EventEmitter<SearchFilterToggleValueEvent<FilterValue, MouseEvent>>();

    filterSelectorForm: FormGroup;

    constructor(private fb: SisFormBuilder) {}

    ngOnInit(): void {
        this.filterSelectorForm = this.fb.group({});
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.popoverIsOpen && !changes.popoverIsOpen.firstChange && !changes.popoverIsOpen.currentValue) {
            this.filterSelectorForm.reset();
        }
    }

    searchOrganisations(searchString$: Observable<string>): Observable<FilterValue[]> {
        return searchString$.pipe(
            filterInput(),
            switchMap(searchString => from(convertAJSPromiseToNative(this.getOrganisations(searchString)))),
        );
    }
}
