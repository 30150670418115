import studentBadgeTpl from './studentBadge.tpl.html';
(function () {
  'use strict';

  BadgeController.$inject = ["AuthService"];
  angular.module('student.frontpage.studentBadge', ['sis-common.auth']).component('studentBadge', {
    template: studentBadgeTpl,
    controller: 'BadgeController as studentBadgeController'
  }).controller('BadgeController', BadgeController);
  function BadgeController(AuthService) {
    this.$onInit = function () {
      this.displayName = AuthService.displayname();
      this.initialLetter = AuthService.initialLetter;
      this.rating = 4;
      this.slogan = 'Lorem ipsum dolor sit amet';
      this.image = '';
    };
  }
})();