'use strict';

(function () {
  angular.module('student.common.utils.cacheUtils', []).factory('cacheUtils', cacheUtils);
  function cacheUtils() {
    return {
      purgeCacheAndGetUnloadedIds: function (cache, args) {
        var ids = this.analyzeIds.apply(null, Array.prototype.slice.call(arguments));
        this.purgeCache(cache, ids.activeIds);
        return ids.unloadedIds;
      },
      analyzeIds: function (cache) {
        // Active ids for filtering duplicates and purge cache
        var activeIds = {};
        var unloadedIds = [];
        for (var i = 1; i < arguments.length; i++) {
          _.forEach(arguments[i], function (id) {
            if (id && !activeIds[id]) {
              activeIds[id] = id;
              if (!cache[id]) {
                unloadedIds.push(id);
              }
            }
          });
        }
        return {
          activeIds: activeIds,
          unloadedIds: unloadedIds
        };
      },
      purgeCache: function (cache, activeIds) {
        _.forIn(cache, function (value, id) {
          if (!activeIds[id]) {
            delete cache[id];
          }
        });
      }
    };
  }
})();