<ng-container *transloco="let t">
  <fudis-dialog [size]="'sm'">
    <fudis-dialog-content>
      <fudis-form [title]="t('PROFILE.CONTACT_INFO')"
                  [level]="2"
                  [errorSummaryHelpText]="t('ERROR-SUMMARY.HELP_TEXT')"
                  [errorSummaryVisible]="errorSummaryVisible">
        <ng-template fudisActions [type]="'form'">
          <fudis-button fudisDialogClose [label]="t('CANCEL')" [variant]="'secondary'" />
          <fudis-button fudisFormSubmit [formValid]="form.valid" [label]="t('SAVE')" (handleClick)="saveContactInformationForm()" />
        </ng-template>
        <ng-template fudisContent [type]="'form'">
          <fudis-grid [columns]="{ sm: 1, md: 'inputMd inputMd' }" [rowGap]="'sm'">
            <fudis-text-input
              [id]="'phoneNumber'"
              [control]="phoneNumber"
              [type]="'tel'"
              [label]="t('PROFILE.PHONE_NUMBER')"/>
            <fudis-dl>
              <fudis-dl-item>
                <fudis-dt [contentText]="t('PROFILE.EMAIL')" />
                <fudis-dd [contentText]="copyStudentProfile.primaryEmail" />
              </fudis-dl-item>
            </fudis-dl>
            <fudis-text-input
              [id]="'secondaryEmail'"
              [control]="secondaryEmail"
              [type]="'email'"
              [label]="t('PROFILE.EMAIL2')" />
            <fudis-dl>
              <fudis-dl-item>
                <fudis-dt [contentText]="t('PROFILE.HOME_MUNICIPALITY')" />
                <fudis-dd [contentText]="copyStudentProfile.municipalityUrn | codeLocalizedName | async" />
              </fudis-dl-item>
            </fudis-dl>
          </fudis-grid>
          <ng-container *ngIf="!isPrimaryAddressEditable">
            <fudis-grid [rowGap]="'sm'">
              <fudis-heading [level]="3" [variant]="'xs'">
                {{ t('PROFILE.PRIMARY_ADDRESS') }}
              </fudis-heading>
              <fudis-body-text>{{ 'PROFILE.GUIDANCE_ADDRESS_NOT_USER_EDITABLE' | translate }}</fudis-body-text>
              <fudis-link [externalLink]="t('PROFILE.GUIDANCE_ADDRESS_NOT_USER_EDITABLE_URL')" [size]="'md'" />
              <fudis-dl [columns]="{ sm: 1, md: 2 }">
                <fudis-dl-item>
                  <fudis-dt [contentText]="t('PROFILE.STREET_ADDRESS')" />
                  <fudis-dd [contentText]="copyStudentProfile.primaryAddress.streetAddress" />
                </fudis-dl-item>
                <fudis-dl-item>
                  <fudis-dt [contentText]="t('PROFILE.POSTAL_CODE')" />
                  <fudis-dd [contentText]="copyStudentProfile.primaryAddress.postalCode" />
                </fudis-dl-item>
                <fudis-dl-item>
                  <fudis-dt [contentText]="t('PROFILE.CITY')" />
                  <fudis-dd [contentText]="copyStudentProfile.primaryAddress.city" />
                </fudis-dl-item>
                <fudis-dl-item>
                  <fudis-dt [contentText]="t('PROFILE.COUNTRY')" />
                  <fudis-dd [contentText]="copyStudentProfile.primaryAddress.countryUrn | codeLocalizedName | async" />
                </fudis-dl-item>
              </fudis-dl>
            </fudis-grid>
          </ng-container>
          <fudis-fieldset *ngIf="isPrimaryAddressEditable" [label]="t('PROFILE.PRIMARY_ADDRESS')" [errorSummaryBreadcrumb]="true" [marginTop]="'md'">
            <ng-template fudisContent [type]="'fieldset'">
              <fudis-grid [columns]="{ sm: 1, md: 'inputMd inputMd' }" [rowGap]="'sm'">
                <ng-container *ngIf="isPrimaryAddressEditable">
                  <fudis-text-input
                    [id]="'primaryAddressStreetAddress'"
                    [control]="primaryAddressStreetAddress"
                    [label]="t('PROFILE.STREET_ADDRESS')" />
                  <fudis-text-input
                    [id]="'primaryAddressPostalCode'"
                    [control]="primaryAddressPostalCode"
                    [label]="t('PROFILE.POSTAL_CODE')" />
                  <fudis-text-input
                    [id]="'primaryAddressCity'"
                    [control]="primaryAddressCity"
                    [label]="t('PROFILE.CITY')" />
                  <fudis-dl>
                    <fudis-dl-item>
                      <fudis-dt [contentText]="t('PROFILE.COUNTRY')" />
                      <fudis-dd [contentText]="copyStudentProfile.primaryAddress?.countryUrn | codeLocalizedName | async" />
                    </fudis-dl-item>
                  </fudis-dl>
                </ng-container>
              </fudis-grid>
            </ng-template>
          </fudis-fieldset>
          <fudis-heading *ngIf="!copyStudentProfile.secondaryAddress" [level]="3" [variant]="'xs'">
            {{ t('PROFILE.SECONDARY_ADDRESS') }}
          </fudis-heading>
          <fudis-fieldset *ngIf="copyStudentProfile.secondaryAddress" [label]="t('PROFILE.SECONDARY_ADDRESS')" [errorSummaryBreadcrumb]="true" [marginTop]="'md'">
            <ng-template fudisActions [type]="'fieldset'">
              <fudis-button  *ngIf="copyStudentProfile.secondaryAddress"
                             (handleClick)="removeSecondaryAddress()"
                             [labelHidden]="true"
                             [icon]="'delete'"
                             [size]="'icon-only'"
                             [variant]="'tertiary'"
                             [label]="('ARIA_LABEL.DELETE' | translate ) + ' ' + ('PROFILE.SECONDARY_ADDRESS' | translate)" />
            </ng-template>
            <ng-template fudisContent [type]="'fieldset'">
              <fudis-grid [columns]="{ sm: 1, md: 'inputMd inputMd' }" [rowGap]="'sm'">
                <ng-container *ngIf="copyStudentProfile.secondaryAddress">
                  <sis-radio-button
                    [name]="'secondaryAddressType'"
                    [id]="'finnishAddressRadioButton'"
                    [control]="secondaryAddressType"
                    [inline]="true"
                    [value]="'FinnishAddress'"
                    [label]="t('PROFILE.SECONDARY_ADDRESS_IN_FINLAND')">
                  </sis-radio-button>
                  <sis-radio-button
                    [name]="'secondaryAddressType'"
                    [id]="'genericAddressRadioButton'"
                    [control]="secondaryAddressType"
                    [inline]="true"
                    [label]="t('PROFILE.SECONDARY_ADDRESS_ABROAD')"
                    [value]="'GenericAddress'">
                  </sis-radio-button>
                  <ng-container *ngIf="!isSecondaryAddressInFinland()">
                    <fudis-text-area
                      fudisGridItem [columns]="{ sm: 1, md: 2 }"
                      [id]="'secondaryAddressAddress'"
                      [control]="genericSecondaryAddressAddress"
                      [label]="t('PROFILE.SECONDARY_ADDRESS_GENERIC_FORM_TITLE')"
                      [helpText]="'PROFILE.SECONDARY_ADDRESS_GUIDANCE' | translate">
                    </fudis-text-area>
                    <div class="form-group" fudisGridItem [columns]="1">
                      <label [for]="'secondaryAddressCountryUrn'">{{ t('PROFILE.COUNTRY') }}</label>
                      <sis-code-select-editor
                        [codebookUrn]="countryCodebookUrns"
                        (onSelectCode)="onSelectCodeUrn(genericSecondaryAddressCountryUrn, $event.selectedUrn)"
                        [name]="'secondaryAddressCountryUrn'"
                        [id]="'secondaryAddressCountryUrn'"
                        [labelForId]="'secondaryAddressCountryInput'"
                        [required]="!isSecondaryAddressInFinland()"
                        [showErrors]="!isSecondaryAddressInFinland()"
                        [selectedUrn]="genericSecondaryAddressCountryUrn.value"
                        [excludeCodes]="[countryUrnFI, countryUrnAX]">
                      </sis-code-select-editor>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="isSecondaryAddressInFinland()">
                    <fudis-text-input
                      [id]="'secondaryAddressStreetAddress'"
                      [control]="finnishSecondaryAddressStreetAddress"
                      [label]="t('PROFILE.STREET_ADDRESS')" />
                    <fudis-text-input
                      [id]="'secondaryAddressPostalCode'"
                      [control]="finnishSecondaryAddressPostalCode"
                      [label]="t('PROFILE.POSTAL_CODE')" />
                    <fudis-text-input
                      [id]="'secondaryAddressCity'"
                      [control]="finnishSecondaryAddressCity"
                      [label]="t('PROFILE.CITY')" />
                  </ng-container>
                </ng-container>
              </fudis-grid>
            </ng-template>
          </fudis-fieldset>
          <fudis-button *ngIf="!copyStudentProfile.secondaryAddress"
                        [label]="t('PROFILE.ADD_SECONDARY_ADDRESS')"
                        (handleClick)="addSecondaryAddress()"
                        [variant]="'tertiary'"
                        [icon]="'ring-plus'" />
        </ng-template>
      </fudis-form>
    </fudis-dialog-content>
  </fudis-dialog>
</ng-container>
