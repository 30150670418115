<ng-template
  *ngIf="option"
  [ngTemplateOutlet]="checkboxTree"
  [ngTemplateOutletContext]="{ option: option }"
></ng-template>

<ng-template #checkboxTree let-option="option">
  <div *ngIf="!shouldHideCheckbox(option)" class="checkbox-tree">
    <div class="checkbox-tree-button-container">
      <button
        *ngIf="option.children.length"
        type="button"
        class="non-button"
        (click)="toggleExpanded(option.value)"
        [attr.aria-label]="'CHECKBOX_TREE.EXPAND_BUTTON_ARIA_LABEL' | translate: {title: option.label | localizedString}"
        [attr.aria-expanded]="isExpanded(option.value)"
        [attr.aria-controls]="'checkbox-children-' + option.value"
      >
        <sis-icon icon="chevron" [iconClass]="isExpanded(option.value) ? 'cw-90' : null"></sis-icon>
      </button>
    </div>
    <sis-checkbox-iconified
      [id]="'checkbox-option-' + option.value"
      [value]="option.value"
      [name]="option.value"
      (checkedChange)="toggleChecked(option.value)"
      [checked]="isChecked(option.value) || isDisabled(option.value)"
      [indeterminate]="isIndeterminate(option.value)"
      [disabled]="isDisabled(option.value)"
      [label]="option.label"
      [code]="option.code"
      [secondaryLabel]="option.secondaryLabel"
      [labelClasses]="makeCheckboxLabelStyles(option.value)"
      [controlClasses]="['checkbox-tree-control']"
    ></sis-checkbox-iconified>
  </div>

  <ul
    *ngIf="option.children.length && (shouldHideCheckbox(option) || isExpanded(option.value))"
    class="checkbox-children"
    [class.checkbox-expanded]="isExpanded(option.value)"
    [id]="'checkbox-children-' + option.value"
  >
    <li *ngFor="let child of option.children">
      <ng-template
        [ngTemplateOutlet]="checkboxTree"
        [ngTemplateOutletContext]="{ option: child }"
      ></ng-template>
    </li>
  </ul>
</ng-template>
