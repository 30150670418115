import { Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { OtmId, StudyEvent } from 'common-typescript/types';
import { StudyEvent as GraphQLStudyEvent } from 'sis-common/generated/graphql';

@Component({
    selector: 'app-study-event-info',
    templateUrl: './study-event-info.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class StudyEventInfoComponent implements OnChanges {

    @Input() studyEvent: StudyEvent | GraphQLStudyEvent;
    @Input() teacherIds?: OtmId[];

    twoColumns = false;

    ngOnChanges(changes: SimpleChanges): void {
        this.twoColumns = this.teacherIds?.length > 0 || this.studyEvent?.cancellations?.length > 0;
    }
}
