import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import {
    CourseUnitRealisationResponsibilityInfoType,
    EducationResponsibilityInfoType,
    ModuleResponsibilityInfoType, PersonGroupResponsibilityInfoType,
    PersonWithCourseUnitRealisationResponsibilityInfoType,
    PersonWithEducationResponsibilityInfoType,
    PersonWithModuleResponsibilityInfoType,
    PublicPerson,
} from 'common-typescript/types';
import { isEqual, sortBy } from 'lodash';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { ComponentDowngradeMappings } from 'sis-common/types/angular-hybrid';

import { PersonNameService } from '../service/person-name.service';
import { PublicPersonEntityService } from '../service/public-person-entity.service';

export type ResponsibilityInfo =
    PersonWithCourseUnitRealisationResponsibilityInfoType |
    PersonWithEducationResponsibilityInfoType |
    PersonWithModuleResponsibilityInfoType;
export type ResponsibilityType =
    CourseUnitRealisationResponsibilityInfoType |
    EducationResponsibilityInfoType |
    ModuleResponsibilityInfoType |
    PersonGroupResponsibilityInfoType;

const DEFAULT_ROLE_ORDER: ResponsibilityType[] = [
    'urn:code:course-unit-realisation-responsibility-info-type:administrative-person',
    'urn:code:course-unit-realisation-responsibility-info-type:contact-info',
    'urn:code:course-unit-realisation-responsibility-info-type:responsible-teacher',
    'urn:code:course-unit-realisation-responsibility-info-type:teacher',
    'urn:code:education-responsibility-info-type:administrative-person',
    'urn:code:education-responsibility-info-type:contact-info',
    'urn:code:education-responsibility-info-type:academic-responsibility',
    'urn:code:module-responsibility-info-type:administrative-person',
    'urn:code:module-responsibility-info-type:contact-info',
    'urn:code:module-responsibility-info-type:responsible-teacher',
    'urn:code:group-responsibility-info-type:administrative-person',
    'urn:code:group-responsibility-info-type:responsible-tutor',
    'urn:code:group-responsibility-info-type:tutor',
];

/**
 * Renders a list of responsibility info entries. The entries are sorted...
 * - primarily by role (according to the given `roleVisibilityAndOrder`),
 * - secondarily by the existence of `personId` (so that entries with a `personId` are shown first),
 * - thirdly by name (either the name of the person or the textual representation of the entry).
 */
@Component({
    selector: 'sis-responsibility-infos',
    templateUrl: './responsibility-infos.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ResponsibilityInfosComponent implements OnInit, OnChanges {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sisComponents.responsibilityInfos',
        directiveName: 'sisResponsibilityInfos',
    };

    @Input() responsibilityInfos: ResponsibilityInfo[];

    /**
     *  Defines which responsibility roles to be shown in the output, and the ordering of those roles.
     *  If not defined all roles will be shown, in the order defined in `DEFAULT_ROLE_ORDER`.
     */
    @Input() roleVisibilityAndOrder: ResponsibilityType[];
    @Input() showInactiveResponsibilities: boolean;
    @Input() showResponsibilityValidityPeriod: boolean;

    visibleResponsibilityInfos$: Observable<ResponsibilityInfo[]>;

    constructor(
        private localeService: LocaleService,
        private publicPersonService: PublicPersonEntityService,
        private personNameService: PersonNameService,
    ) {}

    ngOnInit() {
        if (this.responsibilityInfos?.length > 0) {
            this.visibleResponsibilityInfos$ = this.filterAndSortResponsibilityInfos(this.responsibilityInfos);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!isEqual(changes.responsibilityInfos?.previousValue, changes.responsibilityInfos?.currentValue) ||
            (!isEqual(changes.typeVisibilityAndOrder?.previousValue, changes.typeVisibilityAndOrder?.currentValue))) {
            if (this.responsibilityInfos?.length > 0) {
                this.visibleResponsibilityInfos$ = this.filterAndSortResponsibilityInfos(this.responsibilityInfos);
            } else {
                delete this.visibleResponsibilityInfos$;
            }
        }
    }

    private filterAndSortResponsibilityInfos(infos: ResponsibilityInfo[]): Observable<ResponsibilityInfo[]> {
        const ordering = this.roleVisibilityAndOrder ?? DEFAULT_ROLE_ORDER;
        const filtered = infos?.filter(Boolean).filter(info => ordering.includes(info.roleUrn)) ?? [];
        const personIds = filtered.map(info => info.personId).filter(Boolean);

        return (personIds.length > 0 ? this.publicPersonService.getByIds(personIds) : of([]))
            .pipe(
                map(persons => sortBy(
                    filtered,
                    [
                        info => ordering.indexOf(info.roleUrn),
                        info => !!info.personId ? 0 : 1,
                        info => this.resolveName(info, persons),
                    ],
                )),
            );
    }

    private resolveName(responsibilityInfo: ResponsibilityInfo, persons: PublicPerson[]): string {
        const person = persons?.find(({ id }) => id === responsibilityInfo.personId);
        return person ? this.personNameService.getFullName(person) : this.localeService.localize(responsibilityInfo.text);
    }
}
