import { Pipe, PipeTransform } from '@angular/core';
import { LocalizedString } from 'common-typescript/types';

@Pipe({
    name: 'entityAbbreviation',
})
export class EntityAbbreviationPipe implements PipeTransform {

    transform(value: any): LocalizedString {
        return value?.abbreviation ? value.abbreviation : null;
    }

}
