timingController.$inject = ["$log", "$state", "selectEducationModal", "PlanLoader", "commonStudyRightService", "timingService", "timingViewStateService", "timelineDialogService", "defaultPromiseHandler"];
import angular from 'angular';
import { PlanSelectComponent } from '../common/components/plan-select/plan-select.component.ts';
import timingTpl from './timing.tpl.html';
angular.module('student.timing.timing', [PlanSelectComponent.downgrade.moduleName, 'student.timing.controls.timelineControls', 'student.timing.timeline', 'student.timing.timingService', 'student.timing.timingViewStateService', 'student.timing.studentTimingTemplates', 'student.timing.cancelBox.timingCancelBox', 'student.timing.untimedCourseUnits.untimedCourseUnits', 'student.timing.untimedCourseUnits.untimedCourseUnitsAutoScroll', 'sis-components.service.studyRightService', 'sis-components.plan.planLoader', 'student.timing.controls.timelineDialogService']).component('timing', {
  template: timingTpl,
  bindings: {
    selectedPlan: '<',
    plans: '<'
  },
  controller: timingController
});
function timingController($log, $state, selectEducationModal, PlanLoader, commonStudyRightService, timingService, timingViewStateService, timelineDialogService, defaultPromiseHandler) {
  const ctrl = this;
  ctrl.leftBarOpen = false;
  const initializeTimingServices = () => PlanLoader.createValidatablePlan(ctrl.selectedPlan).then(validatablePlan => {
    ctrl.validatablePlan = validatablePlan;
    // Pura timingService tilallisuus! Jira: OTM-17011
    return timingService.init(ctrl.selectedPlan, ctrl.validatablePlan, ctrl.studyRights);
  }).then(() => {
    ctrl.state = timingViewStateService.getInitState();
  });
  ctrl.$onInit = () => {
    if (!ctrl.selectedPlan) {
      return;
    }
    commonStudyRightService.getStudyRightByEducation(ctrl.selectedPlan.education).then(studyRights => {
      ctrl.studyRights = studyRights;
      initializeTimingServices();
    });
  };

  /**
   * Creates new validatablePlan from plan.
   * Binds new validatablePlan to ctrl so that new validatablePlan would be updated to child components.
   * @param plan
   * @returns created validatablePlan so that timing service could use it.
   */
  ctrl.createNewValidatablePlanAndBindToCtrl = plan => PlanLoader.createValidatablePlan(plan).then(validatablePlan => {
    ctrl.validatablePlan = validatablePlan;
    return ctrl.validatablePlan;
  });
  ctrl.createTimeLineNote = function () {
    timingViewStateService.startCreateTimeLineNote(ctrl.state);
  };
  ctrl.startRemoveAllDialog = function () {
    timelineDialogService.openRemoveAllDialog(ctrl.state, ctrl.validatablePlan, ctrl.createNewValidatablePlanAndBindToCtrl, undefined).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
  };
  ctrl.triggerSelectEducationModal = function () {
    return selectEducationModal.open().then(() => {
      $state.go('student.logged-in.planSelect');
    }, () => {
      $log.debug('selectEducationModal cancelled');
    });
  };
}