import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

/**
 * A simple checkbox component. Not meant to be used with forms, but instead for simply selecting items (e.g. table rows)
 * outside of any form context. If you need a checkbox for a Reactive form, look at `sis-form-checkbox`.
 */
@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-checkbox',
    templateUrl: './checkbox.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CheckboxComponent {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sisComponents.checkbox.checkbox',
        directiveName: 'sisCheckbox',
    };

    /**
     * Optional `aria-labelledby` value to set on the checkbox `input` element.
     */
    @Input() ariaLabelledBy?: string;
    @Input() ariaRole?: string;
    @Input() ariaPosinset?: number;
    @Input() ariaSetsize?: number;
    @Input() ariaDescribedBy?: string;
    /**
     * Extra info after label only accessible to screen reader users.
     */
    @Input() visuallyHiddenInfo?: string;

    @Input() disabled: boolean;
    @Input() invalid: boolean;
    @Input() label: string;
    @Input() secondaryLabel?: string;
    @Input() name: string;
    @Input() required: boolean;
    @Input() checked: boolean;
    @Input() id?: string;
    @Output() checkedChange = new EventEmitter<boolean>();

}
