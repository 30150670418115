import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CourseUnit, EnrolmentQuestion, LocalizedString } from 'common-typescript/types';

import { COMMON_ENROLMENT_QUESTIONNAIRE_SERVICE } from '../../ajs-upgraded-modules';
import { CommonCodeService } from '../../service/common-code.service';

@Component({
    selector: 'sis-enrolment-questionnaire-question',
    templateUrl: './enrolment-questionnaire-question.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class EnrolmentQuestionnaireQuestionComponent implements OnInit {

    @Input() question: EnrolmentQuestion;
    @Input() control: FormControl;
    @Input() increment: number;
    @Input() courseUnit: CourseUnit;
    langQuestion: EnrolmentQuestion;
    parsedFreeTextQuestion: LocalizedString = {};

    constructor(@Inject(COMMON_ENROLMENT_QUESTIONNAIRE_SERVICE) private enrolmentQuestionnaireService: any,
                private commonCodeService: CommonCodeService) { }

    ngOnInit(): void {

        if (this.question.questionType === 'LANGUAGE_SELECT') {
            this.commonCodeService.getCodebook('urn:code:language')
                .then((codes) => {
                    this.langQuestion = this.enrolmentQuestionnaireService.constructLanguageSelectQuestionForStudentWithCodes(
                        this.question, this.courseUnit, codes,
                    );
                });
        }

        if (this.question?.questionType === 'FREE_TEXT') {
            this.parseFreeTextQuestion();
        }
    }

    parseFreeTextQuestion() {
        if (!this.question?.question) { return; }
        Object.keys(this.question?.question).forEach(key => {
            if (this.question.question[key]) {
                // Enrolment question editor wraps question automatically inside <p> -tags, when using html markup.
                // We need to remove it, so that question title would align correctly.
                this.parsedFreeTextQuestion[key] = `${this.increment}. ${this.question?.question[key]
                    .replace('<p>', '')
                    .replace('</p>', '')}`;
            } else {
                this.parsedFreeTextQuestion[key] = null;
            }
        });
    }
}
