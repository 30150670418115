<ng-container *ngIf="this.control">
  <label [for]="id" *ngIf="label" [ngClass]="[labelState]">{{label}}</label>
  <div class="input-and-type">
  <input
    (keydown)="arrowKeyMovement($event)"
    [attr.disabled]="disabled || null"
    [id]="id"
    [attr.aria-describedby]="id + '_errors'"
    [formControl]="control"
    class="form-control"
    data-cy="number-input"
    placeholder="{{ this.placeholder }}"
    type="number"
  >
    <span *ngIf="valueType" class="type-block">{{valueType}}</span>
  </div>
  <sis-validation-errors [control]="control" [formFieldId]="id" [ariaLive]="false" />
</ng-container>
