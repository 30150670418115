import { Injectable } from '@angular/core';
import { TermRegistrationPeriod } from 'common-typescript/types';
import { BehaviorSubject, Observable } from 'rxjs';
import { shareReplay, switchMap } from 'rxjs/operators';

import { TermRegistrationPeriodEntityService } from './term-registration-period-entity.service';
import { UniversityService } from './university.service';

@Injectable({ providedIn: 'root' })
export class TermRegistrationPeriodService {
    /**
     * Term registration periods of the selected organization.
     */
    readonly termRegistrationPeriods$: Observable<readonly TermRegistrationPeriod[]>;

    constructor(
        universityService: UniversityService,
        termRegistrationPeriodEntityService: TermRegistrationPeriodEntityService,
    ) {
        // The subject is just a trick to prevent the result from being cached forever.
        // It also delays running any actual logic before the first subscription.
        this.termRegistrationPeriods$ = new BehaviorSubject(undefined).pipe(
            switchMap(() => termRegistrationPeriodEntityService.getPeriods(universityService.getCurrentUniversityOrgId())),
            shareReplay({ bufferSize: 1, refCount: true }),
        );
    }
}
