import { Injectable } from '@angular/core';
import { ModuleAttainmentApplication, OtmId } from 'common-typescript/types';
import { from, Observable } from 'rxjs';
import { ModalService } from 'sis-common/modal/modal.service';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

import { ModuleInfoModalComponent } from './module-info-modal/module-info-modal.component';

export interface ModuleInfoModalSectionExpandableOpen {
    basics: boolean;
    description: boolean;
    classification: boolean;
    graduation: boolean;
}
export interface ModuleInfoModalValues {
    moduleId: OtmId;
    planId: OtmId;
    organisationId: OtmId;
    isPrimaryPlan: boolean;
    // See resolveDataForModuleAttainmentApplication in planModule.component.js for how the moduleAttainmentApplicationWrapper values are created
    moduleAttainmentApplicationWrapper?: {
        applications: ModuleAttainmentApplication[];
        applyForModuleAttainment?: () => Promise<ModuleAttainmentApplication>;
        mode: 'APPLICATION_IS_SENT' | 'FLAT' | 'RECURSIVE' | 'STUDY_RIGHT_NOT_FOUND';
    };
    sectionExpandableOpenSettings: ModuleInfoModalSectionExpandableOpen;
}

@StaticMembers<DowngradedService>()
@Injectable({
    providedIn: 'root',
})
export class ModuleInfoModalService {

    static downgrade: ServiceDowngradeMappings = {
        moduleName: 'student.common.moduleInfo.moduleInfoModalService',
        serviceName: 'moduleInfoModalServiceNg',
    };

    constructor(private modalService: ModalService) { }

    open(moduleInfoModalValues: ModuleInfoModalValues): Observable<void> {
        return from(this.modalService.open(
            ModuleInfoModalComponent,
            moduleInfoModalValues,
            {
                closeWithOutsideClick: true,
                size: 'xl',
                windowClass: 'brochure-modal',
            }).result);
    }
}
