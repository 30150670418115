import { Pipe, PipeTransform } from '@angular/core';
import { RuleResult } from 'common-typescript/types';

@Pipe({
    name: 'sortRequirementRules',
})
export class SortRequirementRulesPipe implements PipeTransform {

    transform(requirementRule: RuleResult[], filter?: 'TRUE' | 'FALSE' | 'ALL'): RuleResult[] {
        if (!requirementRule) return [];

        const trueResults = requirementRule.filter(rule => rule.result);
        const falseResults = requirementRule.filter(rule => rule.result != null && !rule.result);

        switch (filter || 'ALL') {
            case 'TRUE':
                return trueResults;
            case 'FALSE':
                return falseResults;
            case 'ALL':
            default:
                return [...trueResults, ...falseResults];
        }
    }

}
