import angular from 'angular';
import _ from 'lodash';
export const assessmentItemServiceModule = 'sis-components.service.assessmentItemService';
(function () {
  commonAssessmentItemService.$inject = ["$q", "$http", "assessmentItemJSDataModel", "jsDataCacheHelper", "jsDataRelationHelperService", "assessmentItemsUri", "assessmentItemNameService"];
  angular.module(assessmentItemServiceModule, ['sis-common.university', 'sis-common.model.assessmentItemNameService', 'sis-components.model.assessmentItem', 'sis-components.service.jsDataCacheHelper', 'sis-components.service.jsDataRelationHelper']).factory('commonAssessmentItemService', commonAssessmentItemService).constant('assessmentItemsUri', {
    assessmentItemsUri: '/kori/api/assessment-items'
  });

  /**
   * @ngInject
   */

  function commonAssessmentItemService($q, $http, assessmentItemJSDataModel, jsDataCacheHelper, jsDataRelationHelperService, assessmentItemsUri, assessmentItemNameService) {
    let clearFindAll = false;
    return {
      findAssessmentItemByIdAndDate(id, date) {
        if (/^\d\d\d\d-\d\d-\d\d$/.test(date)) {
          date = `${date}T00:00:00`;
        }
        return $http.get(`${assessmentItemsUri.assessmentItemsUri}/${id}/at-date/${date}`).then(result => {
          const assessmentItem = result.data;
          assessmentItem.fullName = assessmentItemNameService.generateFullName(assessmentItem);
          return assessmentItem;
        });
      },
      findById(id, bypassCache) {
        const options = {
          bypassCache: !!bypassCache
        };
        return assessmentItemJSDataModel.find(id, options).then(assessmentItem => assessmentItem);
      },
      findAllByIds(ids) {
        return jsDataCacheHelper.findByIds(assessmentItemJSDataModel, ids, clearFindAll).then(assessmentItems => {
          clearFindAll = false;
          const promises = _.flatMap(assessmentItems, assessmentItem => [jsDataRelationHelperService.loadOrganisationRelations(assessmentItem.organisations), jsDataRelationHelperService.loadModuleResponsibilityInfoRelations(assessmentItem.responsibilityInfos)]);
          return $q.all(promises).then(() => assessmentItems);
        });
      },
      findByIds: ids => jsDataCacheHelper.findByIds(assessmentItemJSDataModel, ids, false),
      getAssessmentItem: id => assessmentItemJSDataModel.get(id)
    };
  }
})();