import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';

const FOCUSABLE_ELEMENTS = [
    HTMLAnchorElement,
    HTMLButtonElement,
    HTMLInputElement,
    HTMLSelectElement,
    HTMLTextAreaElement,
];

@Directive({ selector: '[sisAutofocus]' })
export class AutofocusDirective implements AfterViewInit {

    /** Can be used to apply the autofocus conditionally. If `false`, the element will not be focused. */
    @Input() sisAutofocus = true;

    constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

    ngAfterViewInit() {
        if (!(this.sisAutofocus ?? true)) {
            return;
        }
        // in case of sis-element, focus to element's first child, otherwise focus to element itself
        const isSisElement = this.elementRef.nativeElement?.tagName?.startsWith('SIS-');
        const focusableElement = isSisElement ? this.elementRef.nativeElement.firstChild : this.elementRef.nativeElement;
        if (!this.isFocusable(focusableElement)) {
            this.renderer.setAttribute(focusableElement, 'tabindex', '-1');
        }
        focusableElement.focus();
    }

    private isFocusable(element: HTMLElement) {
        return FOCUSABLE_ELEMENTS.some(type => element instanceof type) || element?.hasAttribute('tabindex');
    }
}
