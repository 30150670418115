import { Component, Inject, Input, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';
import {
    AbstractPersonRule,
    CourseUnitRealisation,
    EnrolmentCalculationConfig,
    Urn,
} from 'common-typescript/types';
import _ from 'lodash';
import { from } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import {
    ComponentDowngradeMappings,
    DowngradedComponent,
    StaticMembers,
} from 'sis-common/types/angular-hybrid';

import {
    PERSON_RULE_SERVICE,
} from '../../ajs-upgraded-modules';
import { AppErrorHandler } from '../../error-handler/app-error-handler';
import { EnrolmentCalculationConfigEntityService } from '../../service/enrolment-calculation-config-entity.service';
import { UniversityService } from '../../service/university.service';
import { Tab } from '../../tabs/tab-content-switch/tab-content-switch.component';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-course-unit-info-modal-course-unit-realisation',
    templateUrl: './course-unit-info-modal-course-unit-realisation.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class CourseUnitInfoModalCourseUnitRealisationComponent implements OnChanges, OnInit {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.courseUnitInfoModal.courseUnitRealisation',
        directiveName: 'sisCourseUnitInfoModalCourseUnitRealisation',
    };

    openTabIndex = 0;
    currentUniversityCustomCodeUrns: Urn[] = [];
    hasCoordinatingOrganisations: boolean;
    requirementPersonRulesTexts: string[];
    orderingPersonRulesTexts: string[];
    enrolmentCalculationConfig: EnrolmentCalculationConfig;
    tabs: Tab[];

    @Input() courseUnitRealisation: CourseUnitRealisation;
    @Input() language: string;

    constructor(
        private appErrorHandler: AppErrorHandler,
        private universityService: UniversityService,
        private enrolmentCalculationConfigEntityService: EnrolmentCalculationConfigEntityService,
        @Inject(PERSON_RULE_SERVICE) private personRuleService: any,
    ) { }

    ngOnInit() {
        this.tabs = [
            {
                title: 'SIS_COMPONENTS.COURSE_UNIT_REALISATION_INFO.TAB_HEADINGS.BASIC_INFO',
                id: `course-unit-info-modal-cur-basic-info-tab-${this.courseUnitRealisation.id}`,

            },
            {
                title: 'SIS_COMPONENTS.COURSE_UNIT_REALISATION_INFO.TAB_HEADINGS.ENROLMENT_INFO',
                id: `course-unit-info-modal-cur-enrolment-info-tab-${this.courseUnitRealisation.id}`,

            },
            {
                title: 'SIS_COMPONENTS.COURSE_UNIT_REALISATION_INFO.TAB_HEADINGS.STUDY_GROUPS',
                id: `course-unit-info-modal-cur-study-groups-tab-${this.courseUnitRealisation.id}`,

            },
        ];

    }

    ngOnChanges() {
        this.currentUniversityCustomCodeUrns = _.get(this.courseUnitRealisation.customCodeUrns, this.universityService.getCurrentUniversityOrgId()) || [];
        this.hasCoordinatingOrganisations = _.some(
            this.courseUnitRealisation.organisations,
            { roleUrn: 'urn:code:organisation-role:coordinating-organisation' },
        );
        this.getEnrolmentConfig();
    }

    onTabChange(tabIndex: number) {
        this.openTabIndex = tabIndex;
    }

    getEnrolmentConfig() {
        this.enrolmentCalculationConfigEntityService.getEnrolmentCalculationConfigOrNull(this.courseUnitRealisation.id).pipe(
            take(1),
            tap((result) => {
                this.enrolmentCalculationConfig = result;
                if (result !== null) {
                    const requirementPersonRules = result.requirementPersonRules;
                    const orderingPersonRules = result.orderingPersonRules;
                    this.fetchPersonRuleTexts(requirementPersonRules, orderingPersonRules);
                }
            }),
            this.appErrorHandler.defaultErrorHandler());
    }

    fetchPersonRuleTexts(requirementPersonRules: AbstractPersonRule[], orderingPersonRules: AbstractPersonRule[]) {
        const requirementPersonRulesTextPromises = _.map(requirementPersonRules, rule =>
            this.personRuleService.fetchRuleInfoText(rule));

        const orderingPersonRulesTextPromises = _.map(orderingPersonRules, rule =>
            this.personRuleService.fetchRuleInfoText(rule));
        from(Promise.all(requirementPersonRulesTextPromises))
            .pipe(this.appErrorHandler.defaultErrorHandler())
            .subscribe((result) => {
                this.requirementPersonRulesTexts = result;
            });
        from(Promise.all(orderingPersonRulesTextPromises))
            .pipe(this.appErrorHandler.defaultErrorHandler())
            .subscribe((result) => {
                this.orderingPersonRulesTexts = result;
            });
    }

    hasRequirementPersonRules() {
        return !_.isEmpty(this.requirementPersonRulesTexts);
    }

    hasOrderingPersonRules() {
        return !_.isEmpty(this.orderingPersonRulesTexts);
    }

}
