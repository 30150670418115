import courseUnitInfoSectionTpl from './courseUnitInfoSection.tpl.html';
export const courseUnitInfoSectionModule = 'student.common.components.courseUnitInfoSection';
(function () {
  'use strict';

  angular.module(courseUnitInfoSectionModule, []).component('courseUnitInfoSection', {
    bindings: {
      headingKey: '<',
      badgeClass: '<',
      badgeKey: '<',
      placeholder: '<'
    },
    transclude: true,
    template: courseUnitInfoSectionTpl,
    controller: CourseUnitInfoSectionController
  });
  function CourseUnitInfoSectionController() {
    var ctrl = this;
    if (ctrl.openSectionName) {
      ctrl.collapsed = ctrl.openSectionName !== ctrl.headingKey;
    }
  }
})();