import { Injectable } from '@angular/core';
import { Observable, shareReplay, ShareReplayConfig } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthService } from 'sis-common/auth/auth-service';
import { StudyRightEntityService, StudyRightExpirationInfo } from 'sis-components/service/study-right-entity.service';

import { MyStudyRightService } from '../../common/service/my-study-right.service';

@Injectable({ providedIn: 'root' })
export class StudyRightExpirationInfoService {
    /**
     * Emits information about the student's study rights that are expiring in the current study term.
     *
     * The returned information is tailored for being shown in a context notification.
     */
    readonly studyRightExpirationInfos$: Observable<readonly StudyRightExpirationInfo[]>;

    constructor(
        myStudyRightService: MyStudyRightService,
        studyRightEntityService: StudyRightEntityService,
        authService: AuthService,
    ) {
        this.studyRightExpirationInfos$ = myStudyRightService.studyRights$.pipe(
            switchMap(srs => studyRightEntityService.getStudyRightExpirationInfos(authService.personId(), srs, false)),
            shareReplay(<ShareReplayConfig>{ bufferSize: 1, refCount: true }),
        );
    }
}
