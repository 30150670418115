import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';
import { UuidService } from 'sis-common/uuid/uuid.service';

import { getLabelState } from '../form/formUtils';

/**
 * A presentational component for displaying a grouping panel. The content of the panel is provided using
 * content projection. Additionally, a badge can be displayed next to the panel header by projecting a
 * TinyBadgeComponent tagged with the CSS class "header-badge". Example:
 *
 * ```
 * <sis-basic-grouping-panel>
 *   <sis-tiny-badge class="header-badge">Badge</sis-tiny-badge>
 *   <div>The main content of the panel</div>
 * </sis-basic-grouping-panel>
 * ```
 */
@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-basic-grouping-panel',
    templateUrl: './basic-grouping-panel.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class BasicGroupingPanelComponent implements OnInit {
    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sisComponents.downgraded.basic-grouping-panel',
        directiveName: 'sisBasicGroupingPanel',
    };

    /** Translation key for the grouping panel's heading. */
    @Input() heading: string;
    /**
     * Determines heading's semantic aria-level for screen readers, default is equivalent for h4
     */
    @Input() level?: number = 4;
    /** Translation key for an additional info with a class 'guidance' to be displayed after the heading but before
     * the actual content. */
    @Input() guidance?: string;
    /** Icon name for a tooltip icon shown next to the heading. No icon is shown if none are given. */
    @Input() tooltipIcon?: string;
    /** Icon color for a tooltip icon shown next to the heading. Color is default gray if non is given */
    @Input() tooltipIconColor?: string;
    /** Translation key for the text shown in the tooltip that is triggered by {@link #tooltipIconClass}. */
    @Input() tooltipText?: string;
    /** Type of the tooltip component. If true, use popover component instead of default tooltip */
    @Input() popoverTooltip?: boolean;
    /** Disables grouping-panel style **/
    @Input() hideGroupingPanel = false;
    @Input() control?: AbstractControl;
    @Input() tooltipClass?: string;

    public tooltipId: string;

    constructor(private uuidService: UuidService) {}

    ngOnInit() {
        this.tooltipId = this.uuidService.randomUUID();
    }

    get labelState(): string {
        return this.control ? getLabelState(this.control) : '';
    }
}
