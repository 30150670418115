'use strict';

(function () {
  initModuleResponsibilityInfoTypeModel.$inject = ["groupResponsibilityInfoTypeModel", "$http", "$log"];
  groupResponsibilityInfoTypeModel.$inject = ["DS"];
  angular.module('sis-components.model.groupResponsibilityInfoType', ['js-data']).factory('groupResponsibilityInfoTypeModel', groupResponsibilityInfoTypeModel).run(initModuleResponsibilityInfoTypeModel);

  /**
   * @ngInject
   */
  function groupResponsibilityInfoTypeModel(DS) {
    return DS.defineResource({
      idAttribute: 'urn',
      name: 'groupResponsibilityInfoType',
      endpoint: '/kori/api/cached/codebooks/urn:code:group-responsibility-info-type',
      watchChanges: false
    });
  }

  /**
   * @ngInject
   */
  function initModuleResponsibilityInfoTypeModel(groupResponsibilityInfoTypeModel, $http, $log) {
    $http.get(groupResponsibilityInfoTypeModel.endpoint).then(function (response) {
      groupResponsibilityInfoTypeModel.inject(_.values(response.data));
    }).catch(function (error) {
      $log.error(error);
    });
  }
})();