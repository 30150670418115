CourseUnitInfoModalController.$inject = ["$state", "$rootScope", "$controller", "$timeout", "STUDY_TYPE"];
import { CourseUnitInfoController } from './courseUnitInfo.controller';

/**
 * @ngInject
 */
export function CourseUnitInfoModalController($state, $rootScope, $controller, $timeout, STUDY_TYPE) {
  const ctrl = $controller(CourseUnitInfoController, {
    $scope: $rootScope
  });
  ctrl.close = () => ctrl.dismiss();
  const listener = $rootScope.$on('closeCourseInfoModal', ctrl.close);
  ctrl.$onDestroy = function () {
    listener();
  };
  ctrl.setModalCourseUnitVersion = cu => {
    ctrl.courseUnit = cu;
    ctrl.versionChangeInProgress = ctrl.versionIsChanged();
    ctrl.completionMethods = cu.completionMethods.filter(cm => cm.studyType !== STUDY_TYPE.OPEN_UNIVERSITY_STUDIES);
    ctrl.updateResponsibilityInfos();
    $timeout(() => ctrl.updatePlanStatus());
  };
  ctrl.openSubstitutionForm = function (courseUnitId, planId) {
    ctrl.dismiss();
    $state.go('student.logged-in.profile.applications.create-prior-learning-substitution-application', {
      planId,
      courseUnitId
    });
  };
  ctrl.openApplication = function (applicationId) {
    ctrl.dismiss();
    $state.go('student.logged-in.profile.applications.prior-learning-application', {
      applicationId
    });
  };
  ctrl.getBrochureUrl = () => $state.href('student.course-unit', {
    courseUnitId: ctrl.resolve.data.courseUnit.id
  }, {
    absolute: true
  });
  return ctrl;
}