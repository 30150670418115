export const decimalNumberFilterModule = 'sis-components.number.decimalNumberFilter';
(function () {
  angular.module('sis-components.number.decimalNumberFilter', []).filter('decimalNumber', () => input => {
    if (_.isNil(input)) {
      return '';
    }
    if (Number(input).toString() !== input.toString()) {
      return input;
    }
    const s = _.round(input, 2).toString();
    return s.replace('.', ',');
  });
})();