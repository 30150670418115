import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { AttainmentGroupNode } from 'common-typescript/types';

import { PlanData, PlanStateObject } from '../../service/plan-state.service';

@Component({
    selector: 'sis-plan-structure-attainment-group-node',
    templateUrl: './plan-structure-attainment-group-node.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanStructureAttainmentGroupNodeComponent {
    @Input({ required: true }) attainmentGroupNode: AttainmentGroupNode;
    @Input({ required: true }) planStateObject: PlanStateObject;
    @Input({ required: true }) planData: PlanData;
    @Input({ required: true }) headingLevel: number;
}
