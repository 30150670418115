import { Inject, Injectable } from '@angular/core';
import {
    Attainment,
    CourseUnit,
    CourseUnitAttainment,
    CustomCourseUnitAttainment,
    CustomModuleAttainment,
    Module,
    ModuleContentWorkflow,
    OtmId } from 'common-typescript/types';
import _ from 'lodash';
import { combineLatest, map, Observable, of, switchMap } from 'rxjs';

import { VALID_ATTAINMENT_FILTER_SERVICE } from '../ajs-upgraded-modules';
import { AttainmentEntityService } from '../service/attainment-entity.service';
import { CourseUnitEntityService } from '../service/course-unit-entity.service';
import { ModuleEntityService } from '../service/module-entity.service';

export interface ModuleContentWorkflowStructureData {
    module: Module;
    courseUnitSelections: CourseUnit[];
    moduleSelections: Module[];
    customCourseUnitAttainments: CustomCourseUnitAttainment[];
    customModuleAttainments: CustomModuleAttainment[];
    attainments: Attainment[];
}

@Injectable({
    providedIn: 'root',
})
export class ModuleContentWorkflowService {

    constructor(private courseUnitEntityService: CourseUnitEntityService,
                private moduleEntityService: ModuleEntityService,
                private attainmentEntityService: AttainmentEntityService,
                @Inject(VALID_ATTAINMENT_FILTER_SERVICE) private validAttainmentFilterService: any) { }

    /**
     * Get data for showing ModuleContentWorkflowStructure for a ModuleContentWorkflow.
     *
     * @param moduleContentWorkflow
     */
    getModuleContentWorkflowStructureData(moduleContentWorkflow: ModuleContentWorkflow): Observable<ModuleContentWorkflowStructureData> {
        return combineLatest([
            this.moduleEntityService.getById(moduleContentWorkflow.approvedModuleId),
            this.moduleEntityService.getByGroupIds(moduleContentWorkflow.moduleSelections),
            this.attainmentEntityService.getModuleContentApplicationsCustomAttainments(moduleContentWorkflow.id),
            this.getValidPrimaryAttainmentsForStudent(moduleContentWorkflow.studentId),
        ]).pipe(
            switchMap(([module, moduleSelections, customAttainments, attainments]) => combineLatest({
                module: of(module),
                courseUnitSelections: this.getCorrectCourseUnitVersionsForSelections(
                    moduleContentWorkflow.courseUnitSelections,
                    _.first(module.curriculumPeriodIds),
                    attainments.filter(attainment => attainment.type === 'CourseUnitAttainment') as CourseUnitAttainment[],
                ),
                moduleSelections: of(moduleSelections),
                customCourseUnitAttainments: of(this.filterCustomCourseUnitAttainments(customAttainments)),
                customModuleAttainments: of(this.filterCustomModuleAttainment(customAttainments)),
                attainments: of(attainments),
            })),
        );
    }

    private filterCustomCourseUnitAttainments(customAttainments: Attainment[]): CustomCourseUnitAttainment[] {
        return customAttainments.filter(att => att.type === 'CustomCourseUnitAttainment') as CustomCourseUnitAttainment[];
    }

    private filterCustomModuleAttainment(customAttainments: Attainment[]): CustomModuleAttainment[] {
        return customAttainments.filter(att => att.type === 'CustomModuleAttainment') as CustomModuleAttainment[];
    }

    /**
     * If there is an attainment for a course unit selection use that version, else fetch using the selection group id and approvedModule curriculumPeriodId.
     *
     * @private
     */
    private getCorrectCourseUnitVersionsForSelections(courseUnitSelections: OtmId[], curriculumPeriodId: OtmId, courseUnitAttainments: CourseUnitAttainment[]): Observable<CourseUnit[]> {
        const courseUnitIdsWithAttainments: OtmId[] = [];
        const courseUnitGroupIdsWithoutAttainments: OtmId[] = [];
        _.forEach(courseUnitSelections, groupId => {
            const selectionAttainment = courseUnitAttainments.find(attainment => attainment.courseUnitGroupId === groupId);
            if (selectionAttainment) {
                courseUnitIdsWithAttainments.push(selectionAttainment.courseUnitId);
            } else {
                courseUnitGroupIdsWithoutAttainments.push(groupId);
            }
        });
        return combineLatest(
            [
                this.courseUnitEntityService.getByGroupIds(courseUnitGroupIdsWithoutAttainments, curriculumPeriodId),
                this.courseUnitEntityService.getByIds(courseUnitIdsWithAttainments),
            ],
        ).pipe(
            switchMap(([cuByGroupId, cuById]) => of([...cuByGroupId, ...cuById])),
        );

    }

    /**
     * Finds valid primary attainments for student.
     *
     * @param studentId studentId in ModuleContentWorklow
     * @private
     */
    private getValidPrimaryAttainmentsForStudent(studentId: OtmId): Observable<Attainment[]> {
        return this.attainmentEntityService.getByPersonId(studentId)
            .pipe(
                map(attainments => this.filterAttainments(attainments)),
            );
    }

    private filterAttainments(attainments: Attainment[]) {
        const primaryAttainments = attainments.filter(attainment => attainment.primary);
        return this.validAttainmentFilterService.getValidAttainments(primaryAttainments);
    }
}
