import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SisCommonModule } from 'sis-common/sis-common.module';

import { SisComponentsHybridModule } from '../../../sis-components.module.hybrid';

import { StudyRightEducationStructureViewComponent } from './study-right-education-structure-view.component';

/**
 * I wanted to isolate usage of CUSTOM_ELEMENTS_SCHEMA to this component only. It is used because EducationStructurePreviewDirective
 * uses angularJS multi-slot transclusion for left-boxes and right-boxes.
 */
@NgModule({
    declarations: [StudyRightEducationStructureViewComponent],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        SisCommonModule,
        SisComponentsHybridModule,
    ],
    exports: [StudyRightEducationStructureViewComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class StudyRightEducationStructureViewModule {
}
