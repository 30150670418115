import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CourseUnitRealisation } from 'common-typescript/types';
import _ from 'lodash';
import moment from 'moment';

import { CourseUnitRealisation as CourseUnitRealisationGql } from '../generated/graphql';
import { LocaleService } from '../l10n/locale.service';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from '../types/angular-hybrid';

import { NameService } from './name.service';

@StaticMembers<DowngradedService>()
@Injectable({ providedIn: 'root' })
export class CourseUnitRealisationNameService {

    static downgrade: ServiceDowngradeMappings = {
        moduleName: 'sis-common.name.course-unit-realisation-service',
        serviceName: 'courseUnitRealisationNameService',
    };

    constructor(private localeService: LocaleService,
                private translateService: TranslateService,
                private nameService: NameService) {
    }

    private DATE_FORMAT_LONG = 'COURSE_UNIT_REALISATION.COMPUTED_NAME_DATE_FORMAT_LONG';
    private DATE_FORMAT_SHORT = 'COURSE_UNIT_REALISATION.COMPUTED_NAME_DATE_FORMAT_SHORT';
    private DATE_RANGE = 'COURSE_UNIT_REALISATION.COMPUTED_NAME_DATE_FORMAT_RANGE';

    generateFullName(courseUnitRealisation: CourseUnitRealisation | CourseUnitRealisationGql) {
        const fullName = this.nameService.generateFullName(courseUnitRealisation);
        const activityPeriodName = this.activityPeriodAsText(courseUnitRealisation);
        this.localeService.getOfficialLanguages().forEach((locale) => {
            fullName[locale] = _.trim(`${fullName[locale]} ${activityPeriodName}`);
        });
        return fullName;
    }

    activityPeriodAsText(courseUnitRealisation: CourseUnitRealisation | CourseUnitRealisationGql) {
        let activityPeriodName = '';
        if (courseUnitRealisation.activityPeriod) {
            const startDate = moment(_.defaultTo(courseUnitRealisation.activityPeriod.startDate, ''));
            const endDate = moment(_.defaultTo(courseUnitRealisation.activityPeriod.endDate, '')).subtract(1, 'days');
            let localizedName;
            const dateFormatLong = this.translateService.instant(this.DATE_FORMAT_LONG);
            const dateFormatShort = this.translateService.instant(this.DATE_FORMAT_SHORT);
            const hasDates = startDate.isValid() && endDate.isValid();
            const startAndEndDateAreSame = hasDates && endDate.isSame(startDate, 'day');
            const useShortStartDate = !startAndEndDateAreSame && hasDates && endDate.isSame(startDate, 'year');
            const startDateFormat = useShortStartDate ? dateFormatShort : dateFormatLong;
            if (hasDates && !startAndEndDateAreSame) {
                localizedName = this.translateService.instant(this.DATE_RANGE, {
                    start: moment(startDate).format(startDateFormat),
                    end: moment(endDate).format(dateFormatLong),
                });
            } else if (startDate.isValid()) {
                localizedName = moment(startDate).format(startDateFormat);
            } else if (endDate.isValid()) {
                localizedName = moment(endDate).format(startDateFormat);
            }
            activityPeriodName = localizedName;
        }
        return activityPeriodName;
    }
}
