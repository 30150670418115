import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { OtmId, UniversitySettings } from 'common-typescript/types';
import { Observable } from 'rxjs';

import { EntityService } from './entity.service';

const CONFIG = {
    ENDPOINTS: {
        baseUrl: '/kori/api',
    },
};

@Injectable({
    providedIn: 'root',
})
@NgEntityServiceConfig({
    baseUrl: CONFIG.ENDPOINTS.baseUrl,
    resourceName: 'university-settings',
})
export class UniversitySettingsEntityService extends EntityService<UniversitySettingsState> {

    constructor() {
        super(UniversitySettingsStore, UniversitySettingsQuery);
    }

    // Override this as SisuDataLoader is batching calls and we don't want/need it here
    override getById(universityId: OtmId, bypassStore: boolean = false, config = {}): Observable<UniversitySettings> {
        if (!bypassStore && this.query.hasEntity(universityId)) {
            return this.query.selectEntity(universityId);
        }
        return this.get(universityId);
    }

    updateById(universityId: OtmId, universitySettings: UniversitySettings, config = {}): Observable<UniversitySettings> {
        return this.update(universityId, universitySettings);
    }
}

type UniversitySettingsState = EntityState<UniversitySettings, OtmId>;

@StoreConfig({ name: 'university-settings' })
class UniversitySettingsStore extends EntityStore<UniversitySettingsState> {}

class UniversitySettingsQuery extends QueryEntity<UniversitySettingsState> {
    constructor(protected store: UniversitySettingsStore) {
        super(store);
    }
}
