import { ChangeDetectionStrategy, Component, computed, inject, input, output, ViewEncapsulation } from '@angular/core';
import { ValidatablePlan } from 'common-typescript';
import {
    CurriculumPeriod,
    DegreeProgramme,
    GroupingModule,
    ModuleResultItem,
    StudyModule,
} from 'common-typescript/types';
import { map, Observable, switchMap } from 'rxjs';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { ModuleEntityService } from 'sis-components/service/module-entity.service';
import { PlanData, PlanStateObject } from 'sis-components/service/plan-state.service';
import { UniversityService } from 'sis-components/service/university.service';
import { filterInput } from 'sis-components/typeahead/typeahead.component';

@Component({
    selector: 'app-free-edit-module-expandable',
    templateUrl: './free-edit-module-expandable.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FreeEditModuleExpandableComponent {
    searchCurriculumPeriods = input<CurriculumPeriod[]>([]);
    /**
     * All modules that should be toggleable and listed under the search
     */
    allModules = input<(GroupingModule | StudyModule | DegreeProgramme)[]>([]);
    planStateObject = input<PlanStateObject>();
    planData = input<PlanData>();
    validatablePlan = input<ValidatablePlan>();

    searchCurriculumPeriodIds = computed(() => this.searchCurriculumPeriods().map(cur => cur.id));
    allModulesIdSet = computed(() => new Set(this.allModules().map(mod => mod.id)));

    /**
     * Emits a module selection that has been toggled
     */
    toggleModule = output<{
        module: GroupingModule | StudyModule | DegreeProgramme;
        isInPlan: boolean;
    }>();

    /**
     * Emits a newly selected module
     */
    selectModule = output<GroupingModule | StudyModule | DegreeProgramme>();

    private moduleEntityService = inject(ModuleEntityService);
    private localeService = inject(LocaleService);
    private universityService = inject(UniversityService);
    private appErrorHandler = inject(AppErrorHandler);

    doSearch(query$: Observable<string>) {
        return query$.pipe(
            filterInput(),
            switchMap(searchText => this.moduleEntityService.searchUnauthenticated({
                fullTextQuery: searchText,
                uiLang: this.localeService.getCurrentLanguage(),
                start: 0,
                limit: 100,
                validity: 'ONGOING_AND_FUTURE',
                curriculumPeriodIds: this.searchCurriculumPeriodIds(),
                type: 'StudyModule',
                organisationRootIds: [this.universityService.getCurrentUniversityOrgId()],
            })),
            map(result =>
                result.searchResults.filter((mod: ModuleResultItem) =>
                    // TODO: instead of filtering, these should be shown as disabled. This is temporary until typeahead can
                    // be replaced with a equivalent fudis component.
                    !this.validatablePlan().isModuleInPlan(mod.id) && !this.allModulesIdSet().has(mod.id),
                ),
            ),
            this.appErrorHandler.defaultErrorHandler(),
        );
    }
}
