import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
    AssessmentItem,
    CourseUnit,
    CourseUnitRealisation,
    CreditRange,
    Enrolment, EnrolmentState, FlowState, LocalizedString,
    OtmId,
} from 'common-typescript/types';
import { BehaviorSubject, combineLatest, combineLatestWith, Observable, of, Subject, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { AssessmentItemEntityService } from 'sis-components/service/assessment-item-entity.service';
import { CourseUnitEntityService } from 'sis-components/service/course-unit-entity.service';
import {
    CourseUnitRealisationEntityService,
} from 'sis-components/service/course-unit-realisation-entity.service';

import { EnrolmentStudentService } from '../../common/service/enrolment-student.service';

@Component({
    selector: 'app-profile-enrolments',
    templateUrl: './profile-enrolments.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileEnrolmentsComponent implements OnInit {

    sorting$: BehaviorSubject<SortingInfo>;
    sortedRows$: Observable<ProfileEnrolmentRow[]>;

    constructor(
        private appErrorHandler: AppErrorHandler,
        private enrolmentStudentService: EnrolmentStudentService,
        private assessmentItemEntityService: AssessmentItemEntityService,
        private courseUnitRealisationEntityService: CourseUnitRealisationEntityService,
        private courseUnitEntityService: CourseUnitEntityService,
        private localeService: LocaleService,
    ) { }

    ngOnInit(): void {
        const defaultSort: SortingInfo = { sortKey: '', reverse: false };
        this.sorting$ = new BehaviorSubject(defaultSort);

        this.sortedRows$ = this.enrolmentStudentService.getAllEnrolments().pipe(
            map(enrolments => enrolments.filter((e) => e.state !== 'NOT_ENROLLED')),
            switchMap(
                (enrolments) => combineLatest(
                    of(enrolments),
                    this.assessmentItemEntityService.getByIds(enrolments.map(e => e.assessmentItemId)),
                    this.courseUnitEntityService.getByIds(enrolments.map(e => e.courseUnitId)),
                    this.courseUnitRealisationEntityService.getByIds(enrolments.map(e => e.courseUnitRealisationId)),
                )),
            map(([enrolments, assessmentItems, courseUnits, courseUnitRealisations]) => this.combineData(enrolments, assessmentItems, courseUnits, courseUnitRealisations)),
            combineLatestWith(this.sorting$),
            map(([rowsArray, sortingInfo]) => this.sortRows(rowsArray, sortingInfo)),
            this.appErrorHandler.defaultErrorHandler(),
        );

        // Send first value after setting up the sortedRowStream so that it activates.
        this.sorting$.next(defaultSort);
    }

    combineData(enrolments: Enrolment[], assessmentItems: AssessmentItem[], courseUnits: CourseUnit[], courseUnitRealisations: CourseUnitRealisation[]): ProfileEnrolmentRow[] {
        return enrolments.map(e => this.createRow(
            e,
            assessmentItems.find(a => a.id === e.assessmentItemId),
            courseUnits.find(c => c.id === e.courseUnitId),
            courseUnitRealisations.find(curs => curs.id === e.courseUnitRealisationId)),
        );
    }

    createRow(enrolment: Enrolment, assessmentItem: AssessmentItem, courseUnit: CourseUnit, curs: CourseUnitRealisation): ProfileEnrolmentRow {
        return {
            enrolmentId: enrolment.id,
            courseUnitName: courseUnit?.name,
            code: courseUnit?.code,
            enrolmentState: enrolment.state,
            courseUnitRealisationName: curs?.name,
            courseUnitRealisationFlowState: curs?.flowState,
            assessmentItemName: assessmentItem?.name,
            credits: this.getCredits(assessmentItem, courseUnit),
        };
    }

    getCredits(assessmentItem: AssessmentItem, courseUnit: CourseUnit): CreditRange {
        return assessmentItem?.credits ?? courseUnit?.credits;
    }

    sortBy(sortKey: string) {
        this.sorting$.next({ sortKey, reverse: this.sorting$.value?.sortKey === sortKey ? !this.sorting$.value?.reverse : false });
    }

    sortRows(rows: ProfileEnrolmentRow[], sortingInfo: SortingInfo) {
        let sortedRows: ProfileEnrolmentRow[];
        switch (sortingInfo.sortKey) {
            case 'name':
                sortedRows = rows.sort(this.courseUnitNameComparator(this.localeService));
                break;
            case 'credits.min':
                sortedRows = rows.sort((a, b) => (a.credits?.min ?? 0) - (b.credits?.min ?? 0));
                break;
            case 'state':
                sortedRows = rows.sort(this.enrolmentStateComparator());
                break;
            default:
                sortedRows = rows;
        }

        sortedRows = sortingInfo.reverse ? sortedRows.reverse() : sortedRows;
        return sortedRows;
    }

    courseUnitNameComparator(localeService: LocaleService) {
        // Compare LocalizedStrings alphabetically, taking locale into account
        return (v1: ProfileEnrolmentRow, v2: ProfileEnrolmentRow) => localeService.localize(v1.courseUnitName)
            .localeCompare(
                localeService.localize(v2.courseUnitName),
                localeService.getCurrentLanguage(),
            );
    }

    enrolmentStateComparator() {
        return (v1: ProfileEnrolmentRow, v2: ProfileEnrolmentRow) => v1.enrolmentState.localeCompare(v2.enrolmentState);
    }

    getSortKey(): string {
        return this.sorting$.value?.sortKey;
    }

    getProfileEnrolmentRowEnrolmentId(index: number, row: ProfileEnrolmentRow): OtmId {
        return row.enrolmentId;
    }
}

class SortingInfo {
    sortKey: string;
    reverse: boolean;
}

export class ProfileEnrolmentRow {
    enrolmentId: OtmId;
    enrolmentState: EnrolmentState;
    credits: CreditRange;
    courseUnitName: LocalizedString;
    assessmentItemName: LocalizedString;
    courseUnitRealisationName: LocalizedString;
    courseUnitRealisationFlowState: FlowState;
    code: string;
}
