import { TranspileComponent } from 'sis-components/transpile/transpile.component.ts';
import courseUnitInfoTextFieldTpl from './courseUnitInfoTextField.tpl.html';
import { MarkupStringViewComponent } from 'sis-components/markup-string-view/markup-string-view.component.ts';
export const courseUnitInfoTextFieldModule = 'student.common.components.courseUnitInfoTextField';
(function () {
  'use strict';

  angular.module(courseUnitInfoTextFieldModule, [TranspileComponent.downgrade.moduleName, MarkupStringViewComponent.downgrade.moduleName]).component('courseUnitInfoTextField', {
    bindings: {
      headingKey: '<',
      fieldValue: '<',
      fieldValueMarkup: '<',
      md: '<'
    },
    template: courseUnitInfoTextFieldTpl,
    controller: CourseUnitInfoTextFieldController
  });
  function CourseUnitInfoTextFieldController() {
    var ctrl = this;
    ctrl.fieldValueHasValue = function () {
      return ctrl.fieldValue && ctrl.fieldValue !== '';
    };
    ctrl.fieldValueMarkupHasValue = function () {
      return ctrl.fieldValueMarkup && ctrl.fieldValueMarkup !== '';
    };
  }
})();