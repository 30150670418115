import { ChangeDetectionStrategy, Component, inject, ViewEncapsulation } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { StudyRight } from 'common-typescript/types';
import { Observable, of, shareReplay, ShareReplayConfig, startWith } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { StudyRightExpirationInfo } from 'sis-components/service/study-right-entity.service';
import { UniversityService } from 'sis-components/service/university.service';
import { trackByNestedEntityId } from 'sis-components/util/utils';

import { StudyRightExtensionApplyingService } from '../../common/service/study-right-extension-applying.service';

import { StudyRightExpirationInfoService } from './study-right-expiration-info.service';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'app-study-right-expiration-info',
    templateUrl: './study-right-expiration-info.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudyRightExpirationInfoComponent {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'student.frontpage.study-right-expiration-info',
        directiveName: 'appStudyRightExpirationInfo',
    };

    private readonly studyRightExtensionApplyingService = inject(StudyRightExtensionApplyingService);
    private readonly universityService = inject(UniversityService);
    private readonly studyRightExpirationInfoService: StudyRightExpirationInfoService = inject(StudyRightExpirationInfoService);
    private readonly appErrorHandler: AppErrorHandler = inject(AppErrorHandler);

    readonly expirationInfos$ = this.getStudyRightExpirationInfos();
    readonly extensionApplicationEnabled = toSignal(this.universityService.getCurrentUniversitySettings()
        .pipe(
            map(settings => settings?.frontendFeatureToggles?.studyRightExtensionApplicationEnabled ?? true),
            catchError(() => of(true)),
            startWith(true),
        ));

    readonly studyRightId = trackByNestedEntityId<StudyRightExpirationInfo>('studyRight');

    applyForExtension(studyRight: StudyRight) {
        this.studyRightExtensionApplyingService.checkRequirementsAndProceedToApplication(studyRight);
    }

    private getStudyRightExpirationInfos(): Observable<readonly StudyRightExpirationInfo[]> {
        return this.studyRightExpirationInfoService.studyRightExpirationInfos$.pipe(
            this.appErrorHandler.defaultErrorHandler(),
            shareReplay(<ShareReplayConfig>{ bufferSize: 1, refCount: true }),
        );
    }
}
