<ng-container *transloco="let t">
  <div class="tweet-text sis-ml-zero">
    <span>{{t('COMPLETION_METHODS_LIST.INFO_TEXT', { name: courseUnit.name | localizedString, credits: courseUnit.credits | creditRange })}}</span>
  </div>
  <div class="row" *ngIf="completionMethodListData$ | async as completionMethodListData">
    <div class="col-4 d-none d-md-block completion-methods-table-container">
      <table class="table styled-table" [attr.aria-label]="t('COMPLETION_METHODS')">
        <th>
          {{t('COMPLETION_METHODS')}}
        </th>
        <tbody>
          <ng-container *ngFor="let completionMethodListItem of completionMethodListData">
            <tr (click)="select(completionMethodListItem)" class="clickable">
              <td class ="completion-method-cell">
                <span class="title"><strong>{{t('STUDIES.COURSE_UNITS_COMPLETION_METHODS.METHOD_LONG', { n: completionMethodListItem.listIndex + 1 })}}</strong></span>
                <span>{{ getAssessmentItemNameForCompletionMethod(completionMethodListItem) }}</span>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
    <div *ngIf="selectedCompletionMethodListItem$ | async as selectedCompletionMethodListItem" class="col-md-8 d-none d-md-block completion-methods-view-container">
      <app-completion-methods-list-view
        [selectedCompletionMethodListItem]="selectedCompletionMethodListItem"
        [title]="t('STUDIES.COURSE_UNITS_COMPLETION_METHODS.METHOD_LONG', { n: selectedCompletionMethodListItem.listIndex + 1 })"
        [subTitle]="getAssessmentItemNameForCompletionMethod(selectedCompletionMethodListItem)">
      </app-completion-methods-list-view>
    </div>
    <div class="d-md-none d-sm-block">
    <sis-expandable *ngFor="let completionMethodListItem of completionMethodListData"
      [title]="t('STUDIES.COURSE_UNITS_COMPLETION_METHODS.METHOD_LONG', { n: completionMethodListItem.listIndex + 1 })"
      [subTitle]="getAssessmentItemNameForCompletionMethod(completionMethodListItem)">
      <ng-template sisExpandableContent>
        <app-completion-methods-list-view
          [selectedCompletionMethodListItem]="completionMethodListItem">
        </app-completion-methods-list-view>
      </ng-template>>
    </sis-expandable>
    </div>
  </div>
</ng-container>
