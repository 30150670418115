import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-widget-container',
    template: `
      <article class="app-widget-container">
        <div class="app-widget-container__title-container">
          <h2 class="app-widget-container__title-container__title">{{title}}</h2>
          <p *ngIf="subtitle" class="app-widget-container__title-container__subtitle">{{subtitle}}</p>
        </div>
        <div class="app-widget-container__content">
          <ng-content/>
        </div>
      </article>`,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetContainerComponent {

    @Input({ required: true }) title: string;
    @Input({ required: false }) subtitle: string;
}
