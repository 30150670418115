export const commonFormModule = 'sis-common.form';
(function () {
  constraintService.$inject = ["valdr", "$log", "constraintServiceConfig"];
  angular.module(commonFormModule, ['valdr']).factory('constraintService', constraintService).constant('constraintServiceConfig', {
    customPrefix: 'fi.helsinki.otm.common.validation.'
  });

  /**
   * @ngInject
   */

  function constraintService(valdr, $log, constraintServiceConfig) {
    var constraints = valdr.getConstraints();
    var api = {
      getFieldConstraints: function (fieldPath, validationGroups) {
        var constrainedField = _.cloneDeep(_.get(constraints, fieldPath));
        if (_.isArray(validationGroups)) {
          filterByValidationGroups(constrainedField, validationGroups);
          shortenCustomConstraintNames(constrainedField);
        }
        if (!constrainedField) {
          $log.warn('constrainedField not found for fieldPath:' + fieldPath);
        }
        return constrainedField;
      },
      getTypeConstraints: function (typeName, validationGroups) {
        var typeObj = _.cloneDeep(_.get(constraints, typeName));
        if (_.isArray(validationGroups)) {
          _.each(typeObj, function (constrainedField) {
            filterByValidationGroups(constrainedField, validationGroups);
            shortenCustomConstraintNames(constrainedField);
            copyRequiredConstraintFromNotEmpty(constrainedField);
          });
        }
        return typeObj;
      },
      all: function () {
        return constraints;
      }
    };
    return api;
    function filterByValidationGroups(constrainedField, validationGroups) {
      _.forEach(constrainedField, function (constraint, name) {
        if (_.isEmpty(_.intersection(validationGroups, constraint.groups))) {
          delete constrainedField[name];
        }
      });
    }
    function shortenCustomConstraintNames(constrainedField) {
      var prefix = constraintServiceConfig.customPrefix;
      _.forEach(constrainedField, function (constraint, name) {
        if (_.startsWith(name, prefix)) {
          var newName = _.replace(name, prefix, '');
          constrainedField[newName] = constrainedField[name];
          delete constrainedField[name];
        }
      });
    }
    function copyRequiredConstraintFromNotEmpty(constrainedField) {
      if (constrainedField['jakarta.validation.constraints.NotEmpty'] && !constrainedField.required) {
        constrainedField.required = constrainedField['jakarta.validation.constraints.NotEmpty'];
      }
    }
  }
})();