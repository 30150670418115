import { Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { PlanValidationResult } from 'common-typescript/src/plan/validation/planValidationResult';
import { ValidatablePlan } from 'common-typescript/src/plan/validation/validatablePlan';
import { EducationOption, ModuleAttainment, ModuleContentApplication, Plan } from 'common-typescript/types';

export interface RequestedApplications {
    [key: string]: RequestedApplication;
}

export interface RequestedApplication {
    applicationId: string;
    moduleId: string;
    url: string;
}

/**
 * @deprecated
 */
// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'common-plan-module' })
export class PlanModuleDirective extends UpgradeComponent {

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('name') _name: string;
    @Input() plan: Plan;
    @Input() moduleId: string;
    @Input() parentModuleId: string;
    @Input() validatablePlan: ValidatablePlan;
    @Input() planValidationResult: PlanValidationResult;
    @Input() moduleAttainment: ModuleAttainment;
    @Input() moduleContentApprovals: ModuleContentApplication[];
    @Input() highlightedModuleId?: string;
    @Input() educationOptions: EducationOption[];
    // This is to show link to moduleAttainmentApplication and it is optional.
    @Input() requestedApplications?: RequestedApplications;
    // These are for implicit selections; shown in the tree if set tot true
    @Input() showImplicitSelections?: boolean;
    // Set to true for an implicit module selection; children are not shown in this case
    @Input() showSelectedParent?: boolean;
    // Set to true to show an action button column on the right side
    @Input() showActionButtonColumn?: boolean;

    constructor(elementRef: ElementRef, injector: Injector) {
        super('commonPlanModule', elementRef, injector);
    }
}
