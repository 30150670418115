<div class="row" *ngIf="responsibilityInfos?.length > 0">
  <div class="col-12">
    <sis-module-responsibility-info [responsibilityInfos]="responsibilityInfos" [language]="language">
    </sis-module-responsibility-info>
  </div>
</div>
<div class="row" *ngIf="organisations.length > 0 || hasCoordinatingOrganisations">
  <div class="col-12 col-md-6" *ngIf="organisations.length > 0">
    <div class="form-group-mimic">
      <sis-organisation-role-share
        [organisations]="organisations"
        [organisationRoleUrn]="RESPONSIBLE_ORGANISATION_ROLE_URN">
      </sis-organisation-role-share>
    </div>
    <div class="form-group-mimic" *ngIf="hasCoordinatingOrganisations">
      <sis-organisation-role-share
        [organisations]="organisations"
        [organisationRoleUrn]="COORDINATING_ORGANISATION_ROLE_URN">
      </sis-organisation-role-share>
    </div>
  </div>
</div>
<div *ngIf="responsibilityInfos?.length === 0 && organisations.length === 0 && !hasCoordinatingOrganisations">
  <span>{{ 'SIS_COMPONENTS.COURSE_UNIT_REALISATION_INFO.NO_INFORMATION_GIVEN' | translate }}</span>
</div>
