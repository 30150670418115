import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent, merge, of, switchMap, tap } from 'rxjs';
import { delay, map } from 'rxjs/operators';

import { AlertsService, AlertType } from '../alerts/alerts-ng.service';

@Injectable({
    providedIn: 'root',
})
export class ConnectionStatusService {

    constructor(private translate: TranslateService,
                private alertsService: AlertsService) {
        this.handleAppConnectivityChanges();
    }

    private handleAppConnectivityChanges(): void {
        merge(
            fromEvent(window, 'online').pipe(map(() => true)),
            fromEvent(window, 'offline').pipe(map(() => false)),
        ).pipe(
            switchMap((isOnline) => of(isOnline).pipe(delay(3000))),
            tap((isOnline) => {
                if (!isOnline) {
                    this.alertsService.addAlert({
                        type: AlertType.DANGER,
                        message: this.translate.instant('OFFLINE_NOTIFICATION'),
                        identifier: 'error-offline',
                        hideDismissibleButton: true,
                    });
                } else {
                    this.alertsService.dismissAlertIfExists('error-offline');
                }
            }),
        ).subscribe();
    }

}
