function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import angular from 'angular';
import { firstValueFrom, lastValueFrom, of, throwError, EmptyError } from 'rxjs';
import { AuthTokenInterceptorService } from '../http-interceptors/auth-token-interceptor.service.ts';
import { AuthService as NgAuthService } from './auth-service.ts';
import { TokenStorageService } from './token-storage.service.ts';
export const authServiceModule = 'sis-common.auth.service';

/**
 * This is a simplified version of the HttpRequest interface from Angular's HttpClient.
 * It is used to make the request object of the request and responseError methods compatible
 * with an implementation {@link AuthTokenInterceptorService} that is shared by the Angular and AngularJS http-clients
 */
export const httpRequestLike = request => _objectSpread(_objectSpread({}, request), {}, {
  body: request?.data,
  headers: _objectSpread(_objectSpread({}, request?.headers), {}, {
    get: key => request?.headers[key],
    delete: key => delete request?.headers[key]
  }),
  clone: updated => {
    const newHeaders = _objectSpread(_objectSpread({}, request?.headers), updated?.setHeaders);
    return _objectSpread(_objectSpread({}, request), {}, {
      headers: _objectSpread(_objectSpread({}, newHeaders), {}, {
        get: key => newHeaders[key],
        delete: key => delete newHeaders[key]
      })
    });
  }
});

// As opposed to AngularJs interceptors, Angular HttpInterceptor doesn't have a separate method for handling errors.
// Throw an error to handle it as such.
export const asError = {
  handle: response => throwError(response)
};
export const next = {
  handle: request => of(request)
};
(function () {
  configAuth.$inject = ["$httpProvider"];
  AuthService.$inject = ["$injector", "$rootScope", "$q"];
  BootstrappingDeferralInterceptor.$inject = ["$q", "$timeout", "$rootScope"];
  TokenAuthInterceptor.$inject = ["$q", "$injector"];
  TokenStorage.$inject = ["$window", "$log", "$rootScope", "$injector"];
  angular.module(authServiceModule, [NgAuthService.downgrade.moduleName, AuthTokenInterceptorService.downgrade.moduleName, TokenStorageService.downgrade.moduleName]).factory('TokenStorage', TokenStorage).factory('TokenAuthInterceptor', TokenAuthInterceptor).factory('BootstrappingDeferralInterceptor', BootstrappingDeferralInterceptor).factory('AuthService', AuthService).config(configAuth);

  /**
   * @ngInject
   */
  function configAuth($httpProvider) {
    $httpProvider.interceptors.push('BootstrappingDeferralInterceptor');
    $httpProvider.interceptors.push('TokenAuthInterceptor');
  }

  /**
   * @ngInject
   */
  function TokenStorage($window, $log, $rootScope, $injector) {
    return {
      store: function (token, tokenExpiresIn, sessionTimeoutAt) {
        return $injector.get('tokenStorageService').store(token, tokenExpiresIn, sessionTimeoutAt);
      },
      retrieve: function () {
        return $injector.get('tokenStorageService').retrieve();
      },
      clear: function () {
        $injector.get('tokenStorageService').clear();
      },
      logout: function () {
        $injector.get('tokenStorageService').logout();
      }
    };
  }

  /**
   * @ngInject
   */
  function TokenAuthInterceptor($q, $injector) {
    return {
      request: function (config) {
        const interception$ = $injector.get(AuthTokenInterceptorService.downgrade.serviceName).handleRequest(httpRequestLike(config), next);
        return firstValueFrom(interception$).catch(error => handleError($q, error));
      },
      responseError: function (error) {
        const interception$ = $injector.get(AuthTokenInterceptorService.downgrade.serviceName).handleResponse(httpRequestLike(error?.config), asError);
        return firstValueFrom(interception$).catch(() => handleError($q, error));
      }
    };
  }

  /* When an observable returns empty, firstValueFrom throws an EmptyError.
  We want to ignore those as they should not raise an error. This maybe could have been done in errorservice.js as well? */
  function handleError($q, error) {
    if (error instanceof EmptyError) {
      return $q.reject({
        config: {
          bypassErrorInterceptor: true
        }
      });
    }
    return $q.reject(error);
  }

  /**
   * @ngInject
   * Defers any requests originating from AngularJs $http until the Angular app has been initialized
   * If the Angular app has been initialized, the request is passed through immediately
   * This is needed because of requests that are made from AngularJs during the bootstrap phase, such as JSDataModel initializations
   */
  function BootstrappingDeferralInterceptor($q, $timeout, $rootScope) {
    return {
      request: function (config) {
        if ($rootScope.NgAppInitialized) {
          return config;
        }
        const deferred = $q.defer();
        $rootScope.$on('NgAppInitialized', () => deferred.resolve(config));
        return deferred.promise;
      }
    };
  }

  /**
   * @ngInject
   */
  function AuthService($injector, $rootScope, $q) {
    // NOSONAR
    const api = {
      setAnonymousUrls: function (includedUrls, excludedUrls) {
        $injector.get('ngAuthService').setAnonymousUrls(includedUrls, excludedUrls);
      },
      isAnonymousUrl: function (url) {
        $injector.get('ngAuthService').isAnonymousUrl(url);
      },
      logout: function () {
        $injector.get('ngAuthService').logout();
      },
      username: function () {
        return $injector.get('ngAuthService').parseUser()?.username;
      },
      personId: function () {
        return $injector.get('ngAuthService').parseUser()?.id;
      },
      roles: function () {
        return $injector.get('ngAuthService').parseUser()?.roles;
      },
      scope: function () {
        return $injector.get('ngAuthService').parseUser()?.scope;
      },
      hasScope: function (s) {
        return $injector.get('ngAuthService').hasScope(s);
      },
      displayname: function () {
        return $injector.get('ngAuthService').parseUser()?.displayname;
      },
      firstNameInitialLetter: function () {
        return $injector.get('ngAuthService').parseUser()?.firstNameInitialLetter;
      },
      lastNameInitialLetter: function () {
        return $injector.get('ngAuthService').parseUser()?.lastNameInitialLetter;
      },
      loggedIn: function () {
        return !!$injector.get('ngAuthService').parseUser();
      },
      initializeAppAuth: function () {
        const deferred = $q.defer();
        deferred.resolveAppAuthInitialization = resolveAppAuthInitialization;
        if ($rootScope.NgAppInitialized) {
          deferred.resolveAppAuthInitialization();
        }
        $rootScope.$on('NgAppInitialized', () => {
          deferred.resolveAppAuthInitialization();
        });
        return deferred.promise;
      },
      preAuth: function () {
        return lastValueFrom($injector.get('ngAuthService').refreshAuthToken());
      },
      moveToAuthUrl: function (shibbolethPath, loginTargetUrl) {
        $injector.get('ngAuthService').moveToAuthUrl(shibbolethPath, loginTargetUrl);
      }
    };
    function resolveAppAuthInitialization() {
      return $injector.get('ngAuthService').initializeAppAuth().subscribe(res => {
        this.resolve(res);
      });
    }
    return api;
  }
})();