import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'sis-button',
    templateUrl: './button.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ButtonComponent implements OnChanges, OnInit {

    @Input() id?: string;
    @Input() dataCy?: string;
    /**
     * Custom title can be set to a button. It will be shown when hovering the button with mouse (helper for visual users).
     * If there is no need for custom title it can/will be set equal to button's aria-label or empty string if there is no aria-label available.
     */
    @Input() customTitle?: string;
    /**
     * The button label. Using content projection for defining the label is more preferable, since it e.g. allows adding
     * an icon to the button. This input property mainly exists to make writing Storybook stories easier.
     */
    @Input() label: string;
    @Input() ariaLabel: string;
    @Input() ariaExpanded: boolean;
    /**
     * The type `submit` can be used with forms, in which case no `clicked` handler is necessary. Just make sure to
     * put the button inside a `<form>` element which has an `(ngSubmit)` handler.
     */
    @Input() type: 'button' | 'submit' = 'button';
    @Input() size: 'sm' | 'lg' = 'lg';
    @Input() variant: 'primary' | 'secondary' = 'primary';
    /** Renders the button with a border and a transparent background. */
    @Input() hollow = false;
    /** Renders the button with no background or border. */
    @Input() naked = false;
    /**
     * If true, the button will look like an anchor element. Link-styled buttons will ignore the `variant`, `size`,
     * `hollow` and `naked` inputs.
     */
    @Input() link = false;
    @Input() disabled = false;
    @Output() clicked = new EventEmitter<void>();

    classes: string;

    ngOnChanges(changes: SimpleChanges): void {
        this.classes = this.getClasses();
    }

    ngOnInit(): void {
        this.classes = this.getClasses();
    }

    private getClasses(): string {
        if (this.link) {
            return 'link-button';
        }

        return [
            'btn',
            `btn-${this.size || 'lg'}`,
            `btn-${this.variant || 'primary'}`,
            this.hollow ? 'btn-hollow' : null,
            this.naked ? 'btn-naked' : null,
        ].filter(Boolean).join(' ');
    }
}
