import angular from 'angular';
import 'js-data';
export const enrolmentQuestionnaireModelModule = 'sis-components.model.enrolmentQuestionnaireModel';
(function () {
  enrolmentQuestionnaireModel.$inject = ["DS"];
  angular.module(enrolmentQuestionnaireModelModule, ['js-data']).factory('enrolmentQuestionnaireJSDataModel', enrolmentQuestionnaireModel);

  /**
   * @ngInject
   */
  function enrolmentQuestionnaireModel(DS) {
    return DS.defineResource({
      idAttribute: 'courseUnitRealisationId',
      name: 'enrolmentQuestionnaire',
      endpoint: '/kori/api/enrolment-questionnaires'
    });
  }
})();