import { Component, Input, ViewEncapsulation } from '@angular/core';
import { LocalizedMarkupString } from 'common-typescript/types';

@Component({
    selector: 'app-course-unit-info-content-and-goals',
    templateUrl: './course-unit-info-content-and-goals.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CourseUnitInfoContentAndGoalsComponent {

    @Input() outcomes: LocalizedMarkupString;
    @Input() content: LocalizedMarkupString;
    @Input() additional: LocalizedMarkupString;

}
