import { Component, Input, ViewEncapsulation } from '@angular/core';
import { FullCalendarCustomEvent } from 'common-typescript/types';
import { ModalService } from 'sis-common/modal/modal.service';
import { ComponentDowngradeMappings } from 'sis-common/types/angular-hybrid';

import { FullCalendarViewType } from '../full-calendar.types';

import { OwnCalendarEventSummaryModalComponent } from './calendar-event-preview/own-calendar-event-summary-modal.component';

@Component({
    selector: 'app-own-calendar-event',
    templateUrl: './own-calendar-event.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class OwnCalendarEventComponent {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'student.calendar.customCalendarEvent.downgraded',
        directiveName: 'appCustomCalendarEvent',
    };

    @Input() viewType: FullCalendarViewType.TIME_GRID_WEEK | FullCalendarViewType.DAY_GRID_MONTH;
    @Input() event: FullCalendarCustomEvent;

    constructor(private modalService: ModalService) {}

    openEventSummaryModal(): void {
        this.modalService.open(OwnCalendarEventSummaryModalComponent, { event: this.event }, { size: 'sm' });
    }
}
