import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { OtmId } from 'common-typescript/types';
import { map, Observable, switchMap, tap } from 'rxjs';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';
import { EntityService } from 'sis-components/service/entity.service';

const CONFIG = {
    ENDPOINTS: {
        backend: '/osuva/api',
        get courseCart() {
            return `${this.backend}/course-cart`;
        },
        addCurToCourseCart(curId: OtmId): string {
            return `${this.backend}/course-cart/${curId}`;
        },
        deleteCurFromCourseCart(curId: OtmId): string {
            return `${this.backend}/course-cart/${curId}`;
        },
    },
};
@StaticMembers<DowngradedService>()
@Injectable({ providedIn: 'root' })
@NgEntityServiceConfig({ baseUrl: CONFIG.ENDPOINTS.backend })
export class CourseCartEntityService extends EntityService<CourseCartEntityState> {

    static downgrade: ServiceDowngradeMappings = {
        moduleName: 'student.common.service.courseCartEntityService',
        serviceName: 'courseCartEntityService',
    };

    constructor() {
        super(CourseCartStore, CourseCartQuery);
    }

    getCourseCart(bypassStore = false): Observable<OtmId[]> {
        if (!bypassStore && this.query.getHasCache()) {
            return this.selectAll().pipe(
                map(entities => entities.map((entity) => entity.id)),
            );
        }
        return this.getHttp().get<OtmId[]>(CONFIG.ENDPOINTS.courseCart).pipe(
            tap(entities => this.store.set(entities)),
            switchMap(() => this.selectAll().pipe(
                map(entities => entities.map((entity) => entity.id)),
            )),
        );
    }

    addCurToCourseCart(curId: OtmId) {
        return this.getHttp().put(CONFIG.ENDPOINTS.addCurToCourseCart(curId), {}).pipe(
            tap(() => this.store.add(curId)),
        );
    }

    deleteCurFromCourseCart(curId: OtmId): Observable<void> {
        return this.getHttp().delete<void>(CONFIG.ENDPOINTS.deleteCurFromCourseCart(curId)).pipe(
            tap(() => this.store.remove(curId)),
        );
    }
}
// Because we want to save only ids to akita store we need to do this way.
// All methods take otm ids, but they are wrapped inside store hook to CourseCartEntity object, so that akita finds needed id.
type CourseCartEntityState = EntityState<any, OtmId>;
@StoreConfig({ name: 'course-cart' })
class CourseCartStore extends EntityStore<CourseCartEntityState> {
    akitaPreAddEntity(newEntity: OtmId): CourseCartEntity {
        return {
            id: newEntity,
        };
    }
}

class CourseCartQuery extends QueryEntity<CourseCartEntityState> {
    constructor(protected store: CourseCartStore) {
        super(store);
    }
}

interface CourseCartEntity {
    id: OtmId;
}
