import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {
    DowngradedService,
    ServiceDowngradeMappings,
    StaticMembers,
} from 'sis-common/types/angular-hybrid';

@StaticMembers<DowngradedService>()
@Injectable()
export class FullCalendarMessageService {
    static downgrade: ServiceDowngradeMappings = {
        moduleName: 'student.calendar.fullCalendarMessageService',
        serviceName: 'fullCalendarMessageService',
    };

    goToDateSubject: Subject<string> = new Subject<string>();
    goToDate$: Observable<string> = this.goToDateSubject;

    reFetchEventsSubject: Subject<void> = new Subject<void>();
    reFetchEvents$: Observable<void> = this.reFetchEventsSubject;
}
