import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { HttpConfig, NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { OtmId, TermRegistrationRequirements, Urn } from 'common-typescript/types';
import { Observable, throwError } from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

import { UniversityService } from '../service/university.service';

import { EntityService } from './entity.service';

const CONFIG = {
    ENDPOINTS: {
        baseUrl: '/kori/api',
        termRegistrationRequirements(universityOrgId: OtmId): string {
            return `${this.baseUrl}/term-registration-requirements/${universityOrgId}`;
        },
    },
};

@StaticMembers<DowngradedService>()
@Injectable({ providedIn: 'root' })
@NgEntityServiceConfig({ baseUrl: CONFIG.ENDPOINTS.baseUrl })
export class TermRegistrationRequirementsEntityService extends EntityService<TermRegistrationRequirementsState> {

    static downgrade: ServiceDowngradeMappings = {
        moduleName: 'sis-components.service.termRegistrationRequirementsService',
        serviceName: 'termRegistrationRequirementsService',
    };

    constructor(private universityService: UniversityService) {
        super(TermRegistrationRequirementsStore, TermRegistrationRequirementsQuery);
    }

    /**
     * Alias for `getById()` (exists mainly for backwards compatibility).
     */
    getRequirements(universityOrgId?: OtmId, bypassStore?: boolean): Observable<TermRegistrationRequirements> {
        return this.getById(universityOrgId ?? this.universityService.getCurrentUniversityOrgId(), bypassStore);
    }

    isTermRegistrationRequired(educationType: Urn, universityOrgId?: OtmId, bypassStore?: boolean): Observable<boolean> {
        if (!educationType) {
            return throwError(() => new Error('Education type URN was not provided'));
        }
        return this.getById(universityOrgId ?? this.universityService.getCurrentUniversityOrgId(), bypassStore)
            .pipe(
                first(),
                map(result => !!result?.requirements?.some(entry => entry?.educationTypeUrn === educationType)),
            );
    }

    save(requirements: TermRegistrationRequirements, universityOrgId: OtmId): Observable<TermRegistrationRequirements> {
        return this.getHttp()
            .post<TermRegistrationRequirements>(CONFIG.ENDPOINTS.termRegistrationRequirements(universityOrgId), requirements)
            .pipe(
                tap(result => this.store.upsert(result.id, result)),
                switchMap(() => this.selectEntity(universityOrgId)),
            );
    }
}

type TermRegistrationRequirementsState = EntityState<TermRegistrationRequirements, OtmId>;

@StoreConfig({ name: 'term-registration-requirements' })
class TermRegistrationRequirementsStore extends EntityStore<TermRegistrationRequirementsState> {}

class TermRegistrationRequirementsQuery extends QueryEntity<TermRegistrationRequirementsState> {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(store: EntityStore<TermRegistrationRequirementsState>) {
        super(store);
    }
}
