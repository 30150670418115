import * as _ from 'lodash';

import {
    EntityWithRule,
    ModuleContentApprovalState,
    PlanValidationState,
    RangeValidationResultState, Rule,
} from '../../../types';
import { Range } from '../../model/range';
import { PlanValidationStateService } from '../../service/planValidationState.service';
import { isModule } from '../planUtils';

import { AttainmentValidation } from './attainmentValidation';
import { ModuleContext } from './context/moduleContext';
import { RuleContext } from './context/ruleContext';
import { CourseUnitValidation } from './courseUnitValidation';
import { ModuleContentApplicationValidation } from './moduleContentApplicationValidation';
import { PlanValidationResult } from './planValidationResult';
import { RuleValidation } from './ruleValidation';
import { ValidatablePlan } from './validatablePlan';

export class ModuleValidation {

    public static validateModule(module: EntityWithRule, validatablePlan: ValidatablePlan, planValidationResult: PlanValidationResult, customRule?: Rule): RuleContext {
        const ruleContext = new RuleContext();
        if (isModule(module) && validatablePlan.getModuleAttainment(module.id)) {
            AttainmentValidation.validateModuleAttainment(module, validatablePlan, ruleContext, planValidationResult);
        } else {
            ModuleValidation.validatePlan(module, validatablePlan, ruleContext, planValidationResult, customRule);
            if (['DegreeProgramme', 'StudyModule', 'GroupingModule'].includes(module.type) && ruleContext.state === PlanValidationState.ATTAINED) {
                ruleContext.mergeState(PlanValidationState.PARTS_ATTAINED);
            }
        }
        ruleContext.addModule(module);
        const moduleValidationResults = ruleContext.getResults();

        if (isModule(module) && module.moduleContentApprovalRequired) {
            moduleValidationResults.approvalRequiredState = ModuleContentApplicationValidation.getApprovalRequiredState(module, planValidationResult);
        }

        if (!!ruleContext.moduleContentApprovalValidationState) {
            moduleValidationResults.moduleContentApprovalValidationState = ruleContext.moduleContentApprovalValidationState;
            moduleValidationResults.moduleContentApprovalState = ModuleContentApplicationValidation.getModuleContentApprovalState(module, planValidationResult);
            moduleValidationResults.isModuleContentApproved = ModuleContentApplicationValidation.isModuleContentApproved(moduleValidationResults.moduleContentApprovalState);
        }

        moduleValidationResults.validationStateOfChildren = this.getValidationStateOfChildren(validatablePlan, module, planValidationResult);
        moduleValidationResults.hasInvalidOrMissingSelections = this.hasInvalidOrMissingSelections(validatablePlan, module, planValidationResult, customRule);

        planValidationResult.moduleValidationResults[module.id] = moduleValidationResults;

        return ruleContext;
    }

    public static validatePlan(module: EntityWithRule, validatablePlan: ValidatablePlan, ruleContext: RuleContext, planValidationResult: PlanValidationResult, customRule?: Rule): void {
        const moduleContext = new ModuleContext(module, validatablePlan);
        const rule = customRule || module.rule;

        let ruleValidationResult;
        if (rule) {
            ruleValidationResult = RuleValidation.validateRule(rule, validatablePlan, moduleContext, planValidationResult);
        }
        if (ruleValidationResult || !rule) {
            if (ruleValidationResult) {
                ruleContext.mergeContext(ruleValidationResult);
            }

            if (!moduleContext.isEmpty()) {
                _.forEach(moduleContext.unmatchedModulesById, (unMatchedModule) => {
                    ruleContext.mergePartial(ModuleValidation.validateModule(unMatchedModule, validatablePlan, planValidationResult), null);
                    _.set(planValidationResult.moduleValidationResults[unMatchedModule.id], 'invalidSelection', true);
                    planValidationResult.moduleIndexes[unMatchedModule.id] = null;
                });
                _.forEach(moduleContext.unmatchedCourseUnitsById, (courseUnit) => {
                    ruleContext.mergePartial(CourseUnitValidation.validateCourseUnit(courseUnit, validatablePlan, planValidationResult), null);
                    _.set(planValidationResult.courseUnitValidationResults[courseUnit.id], 'invalidSelection', true);
                    planValidationResult.courseUnitIndexes[courseUnit.id] = null;
                });
                _.forEach(moduleContext.unmatchedCustomModuleAttainmentsById, (customModuleAttainment) => {
                    ruleContext.mergeState(PlanValidationState.ATTAINED);
                    AttainmentValidation.validateCustomModuleAttainment(customModuleAttainment, ruleContext);
                    const cmaValidationResult = _.get(planValidationResult.customModuleAttainmentValidationResults, customModuleAttainment.id) || {};
                    _.set(cmaValidationResult, 'invalidSelection', true);
                    _.set(planValidationResult.customModuleAttainmentValidationResults, customModuleAttainment.id, cmaValidationResult);
                    planValidationResult.customModuleAttainmentIndexes[customModuleAttainment.id] = null;
                });
                _.forEach(moduleContext.unmatchedCustomCourseUnitAttainmentsById, (customCourseUnitAttainment) => {
                    ruleContext.mergeState(PlanValidationState.ATTAINED);
                    AttainmentValidation.validateCustomCourseUnitAttainment(customCourseUnitAttainment, ruleContext);
                    const ccuaValidationResult = _.get(planValidationResult.customCourseUnitAttainmentValidationResults, customCourseUnitAttainment.id) || {};
                    _.set(ccuaValidationResult, 'invalidSelection', true);
                    _.set(planValidationResult.customCourseUnitAttainmentValidationResults, customCourseUnitAttainment.id, ccuaValidationResult);
                    planValidationResult.customCourseUnitAttainmentIndexes[customCourseUnitAttainment.id] = null;
                });
                _.forEach(moduleContext.unmatchedCustomStudyDraftsById, (customStudyDraft) => {
                    ruleContext.addCustomStudyDraft(customStudyDraft);
                    ruleContext.addPlannedCredits(new Range(customStudyDraft.credits));
                    ruleContext.mergeState(PlanValidationState.INVALID);
                    RuleValidation.validateContentFilterForCustomStudyDraft(customStudyDraft, validatablePlan, ruleContext);
                });

                // Module without rule (e.g. synthetic GroupingModule of ModuleAttainment) allows anything
                if (rule) {
                    ruleContext.mergeState(PlanValidationState.INVALID);
                }
            }
        }

        // Finally, module content approval can override invalid selections set in normal validation
        const matchingModuleContentApproval = validatablePlan.getEffectiveModuleContentApproval(module.id);
        if (matchingModuleContentApproval) {
            _.set(planValidationResult.matchingModuleContentApprovalsByModuleId, module.id, matchingModuleContentApproval);
            const mcaModuleContext = new ModuleContext(module, validatablePlan);
            ruleContext.moduleContentApprovalValidationState =
                ModuleContentApplicationValidation.validateModuleContentApplication(matchingModuleContentApproval, mcaModuleContext, planValidationResult);

            if (ruleContext.moduleContentApprovalValidationState === ModuleContentApprovalState.INVALID) {
                ruleContext.state = PlanValidationState.INVALID;
                ruleContext.contextualState = PlanValidationState.INVALID;
            } else {
                ruleContext.state = this.getValidationStateOfChildren(validatablePlan, module, planValidationResult);
                ruleContext.contextualState = this.getContextualStateOfChildren(validatablePlan, module, planValidationResult);
            }
        } else if (isModule(module) && module.moduleContentApprovalRequired) {

            // updating ruleValidationResult does not seem to have any effect anywhere.
            // the state should probably be merged to ruleContext. For now it is left as it was.

            if (!_.isUndefined(ruleValidationResult) && _.get(ruleValidationResult, 'state')) {
                ruleValidationResult.state = PlanValidationStateService.higherPriorityOf(ruleValidationResult.state, PlanValidationState.INCOMPLETE);
            } else {
                ruleValidationResult = {
                    state: PlanValidationState.INCOMPLETE,
                };
            }

        }
    }

    public static getValidationStateOfChildren(validatablePlan: ValidatablePlan, module: EntityWithRule, planValidationResult: PlanValidationResult): PlanValidationState {
        return this.getStateOfChildren(validatablePlan, module, planValidationResult, false);
    }

    public static getContextualStateOfChildren(validatablePlan: ValidatablePlan, module: EntityWithRule, planValidationResult: PlanValidationResult): PlanValidationState {
        return this.getStateOfChildren(validatablePlan, module, planValidationResult, true);
    }

    public static getStateOfChildren(
        validatablePlan: ValidatablePlan,
        module: EntityWithRule,
        planValidationResult: PlanValidationResult,
        getContextualState: boolean = false,
    ): PlanValidationState {

        let mergedStateOfChildren: PlanValidationState;

        mergedStateOfChildren = PlanValidationState.EMPTY;

        const disallowedContextStatesForModule = getContextualState ? PlanValidationStateService.disallowedContextStatesForModule : [];

        const customStudyDrafts = validatablePlan.getSelectedCustomStudyDraftsByParentModuleId(module.id);

        if (!_.isEmpty(customStudyDrafts)) {
            mergedStateOfChildren = PlanValidationStateService.higherPriorityOf(mergedStateOfChildren, PlanValidationState.PLANNED);
        }

        _.forEach(validatablePlan.getSelectedModulesUnderModule(module), (childModule) => {
            const moduleValidationState = _.get(planValidationResult.moduleValidationResults, [childModule.id, 'state']);
            const contextualValidationState = _.includes(disallowedContextStatesForModule, moduleValidationState) ? PlanValidationState.PLANNED : moduleValidationState;
            mergedStateOfChildren = PlanValidationStateService.higherPriorityOf(
                mergedStateOfChildren,
                contextualValidationState,
            );
        });

        _.forEach(validatablePlan.getSelectedCourseUnitsUnderModule(module), (courseUnit) => {
            mergedStateOfChildren = PlanValidationStateService.higherPriorityOf(
                mergedStateOfChildren,
                _.get(_.get(planValidationResult.courseUnitValidationResults, courseUnit.id), 'state'));
        });

        if (!_.isEmpty(validatablePlan.getSelectedCustomModuleAttainmentsUnderModule(module)) ||
            !_.isEmpty(validatablePlan.getSelectedCustomCourseUnitAttainmentsUnderModule(module))) {

            mergedStateOfChildren = PlanValidationStateService.higherPriorityOf(mergedStateOfChildren, PlanValidationState.ATTAINED);
        }

        if (mergedStateOfChildren === PlanValidationState.ATTAINED) {
            return PlanValidationState.PARTS_ATTAINED;
        }
        return mergedStateOfChildren;
    }

    public static hasInvalidOrMissingSelections(validatablePlan: ValidatablePlan, module: EntityWithRule, planValidationResult: PlanValidationResult, customRule?: Rule): boolean {
        const studies: any[] = [];
        const rule = customRule || module.rule;

        if (_.some(_.concat(
                studies,
                validatablePlan.getSelectedModulesUnderModule(module),
                validatablePlan.getSelectedCourseUnitsUnderModule(module),
                validatablePlan.getSelectedCustomModuleAttainmentsUnderModule(module),
                validatablePlan.getSelectedCustomCourseUnitAttainmentsUnderModule(module)),
            childStudy => _.get(this.getValidationResultForStudy(childStudy, planValidationResult), 'invalidSelection') === true)
        ) {
            return true;
        }

        return !!rule && this.isRuleMissingSelections(module, rule, planValidationResult);
    }

    public static isRuleMissingSelections(module: EntityWithRule, rule: any, planValidationResult: PlanValidationResult): boolean {
        const ruleValidationResults = _.get(_.get(planValidationResult.ruleValidationResults, module.id), <string> rule.localId);
        if (!ruleValidationResults) {
            return false;
        }
        if (_.get(ruleValidationResults, 'active') === false) {
            return false;
        }

        switch (_.get(rule, 'type')) {
            case 'CourseUnitRule':
                if (!_.get(ruleValidationResults, 'state') || _.get(ruleValidationResults, 'state') === PlanValidationState.EMPTY) {
                    return true;
                }
                break;
            case 'ModuleRule':
                if (!_.get(ruleValidationResults, 'selectedModulesById')) {
                    return true;
                }
                break;
            case 'CreditsRule':
                // credits rule is considered separately
                break;
            default:
                if (_.get(ruleValidationResults, 'result') === RangeValidationResultState.MORE_REQUIRED ||
                    _.get(ruleValidationResults, 'result') === RangeValidationResultState.LESS_REQUIRED) {
                    return true;
                }
        }

        return _.some(_.concat(rule.rule, rule.rules), subRule =>
            !!subRule && this.isRuleMissingSelections(module, subRule, planValidationResult));
    }

    private static getValidationResultForStudy(study: any, planValidationResult: PlanValidationResult): PlanValidationResult | null {
        if (['StudyModule', 'DegreeProgramme'].includes(study.type)) {
            return _.get(planValidationResult.moduleValidationResults, study.id, null);
        }
        if (study.type === 'CustomCourseUnitAttainment') {
            return _.get(planValidationResult.customCourseUnitAttainmentValidationResults, study.id, null);
        }
        if (study.type === 'CustomModuleAttainment') {
            return _.get(planValidationResult.customModuleAttainmentValidationResults, study.id, null);
        }

        // since the study did not match any of the above types, we assume that it is a courseUnit

        return _.get(planValidationResult.courseUnitValidationResults, study.id, null);
    }

}
