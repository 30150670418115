'use strict';

(function () {
  colorService.$inject = ["colorConstants"];
  angular.module('sis-components.service.colorService', []).factory('colorService', colorService).constant('colorConstants', {
    NUMBER_OF_COURSE_UNIT_CATEGORIES: 11
  });

  // Recursive search memoized with key searchedModuleId+current traversed moduleId
  var findModuleColorCategory = _.memoize(function (module, searchedModuleId, index, validatablePlan) {
    if (module.id === searchedModuleId || _.get(module, 'moduleId') === searchedModuleId) {
      if (!index && module.type !== 'DegreeProgramme') {
        index = 1;
      }
      return {
        found: true,
        index: index
      };
    }
    var searchObject = {
      found: false,
      index: index
    };
    let childModules;
    if (module.type === 'CustomModuleAttainment') {
      childModules = _.concat(validatablePlan.getSelectedModulesUnderCustomModuleAttainment(module), validatablePlan.getSelectedCustomModuleAttainmentsUnderCustomModuleAttainment(module));
    } else {
      childModules = _.concat(validatablePlan.getSelectedModulesUnderModule(module), validatablePlan.getSelectedCustomModuleAttainmentsUnderModule(module));
    }
    childModules = _.uniq(_.compact(childModules));
    _.find(childModules, function (childModule) {
      // Index numbers are cycled only at directly under degreeProgrammes and education
      if (module.type === 'DegreeProgramme' || module.type === 'Education') {
        searchObject.index += 1;
      }
      searchObject = findModuleColorCategory(childModule, searchedModuleId, searchObject.index, validatablePlan);
      return searchObject.found;
    });
    return searchObject;
  }, function (module, searchedModuleId) {
    return '' + searchedModuleId + module.id;
  });
  function findModuleIndexNumber(validatablePlan, searchedModuleId) {
    var colorTraverseResultObject = findModuleColorCategory(validatablePlan.rootModule, searchedModuleId, 0, validatablePlan);
    if (!colorTraverseResultObject.found) {
      return undefined;
    }
    return _.get(colorTraverseResultObject, 'index');
  }

  /**
   * @ngInject
   */
  function colorService(colorConstants) {
    return {
      /**
       * @description Gives courseUnit or customCourseUnitAttainment a category number that depends on
       * order number from direct child of degreeProgramme.
       * @param validatablePlan
       * @param courseUnitId
       * @returns Integer between 1, NUMBER_OF_COURSE_UNIT_CATEGORIES
       */
      getCourseUnitColorCategory: function (validatablePlan, courseUnitId) {
        if (!validatablePlan || !courseUnitId) {
          return undefined;
        }
        var courseUnitSelection = validatablePlan.getCourseUnitSelection(courseUnitId);
        if (!courseUnitSelection) {
          var customCourseUnitAttainment = validatablePlan.getCustomCourseUnitAttainment(courseUnitId);
          courseUnitSelection = validatablePlan.getSelectionForAttainment(customCourseUnitAttainment);
          if (!courseUnitSelection) {
            return undefined;
          }
        }
        var parentModuleId = !!courseUnitSelection.parentModuleAttainmentId ? courseUnitSelection.parentModuleAttainmentId : courseUnitSelection.parentModuleId;
        if (!parentModuleId) {
          return undefined;
        }
        var moduleIndex = findModuleIndexNumber(validatablePlan, parentModuleId);
        return (moduleIndex - 1) % colorConstants.NUMBER_OF_COURSE_UNIT_CATEGORIES + 1;
      },
      /**
       * @description If plan structure is changed (e.g. new validatablePlan is created) colors should
       * be searched again
       */
      clearColorCaches: function () {
        findModuleColorCategory.cache.clear();
      },
      /**
       * Same as getCourseUnitColorCategory, but for modules
       */
      getModuleColorCategory: function (validatablePlan, moduleId) {
        if (!validatablePlan || !moduleId) {
          return undefined;
        }
        var moduleIndex = findModuleIndexNumber(validatablePlan, moduleId);
        return (moduleIndex - 1) % colorConstants.NUMBER_OF_COURSE_UNIT_CATEGORIES + 1;
      }
    };
  }
})();