//
// These constants should match the backend ones in ValidationConstants.java.
//

export enum MaxLength {
    /** Use sparingly, this might be problematic for UIs */
    MAX_VERY_LONG_STRING_LENGTH = 65000,
    MAX_LONG_STRING_LENGTH = 8000,
    /** ~ 12 lines of text, or a URL or such */
    MAX_MEDIUM_STRING_LENGTH = 1024,
    /** names, groupIds and such */
    MAX_TERSE_STRING_LENGTH = 255,
    MAX_TWEET_LENGTH = 160,
    /** codes, abbreviations and such */
    MAX_SHORT_STRING_LENGTH = 100,
}

export enum MaxSize {
    /** This has only been used for educational-institution codebook that has ~7000 codes */
    MAX_VERY_LARGE_SET_SIZE = 20000,
    MAX_BIG_SET_SIZE = 4000,
    MAX_MEDIUM_SET_SIZE = 200,
    MAX_SMALL_SET_SIZE = 20,
}
