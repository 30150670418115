import assessmentItemSelectorTpl from './assessmentItemSelector.tpl.html';
'use strict';
(function () {
  assessmentItemSelectorController.$inject = ["universityService", "SearchParameters", "$scope"];
  angular.module('sis-components.assessmentItem.assessmentItemSelector', ['pascalprecht.translate', 'sis-common.l10n.localizedStringFilter', 'sis-common.university', 'sis-components.search.searchParameterService', 'sis-components.number.creditRangeFilter', 'sis-components.service.assessmentItemService']).directive('assessmentItemSelector', assessmentItemSelectorDirective).controller('assessmentItemSelectorController', assessmentItemSelectorController);
  /**
   * @ngInject
   */
  function assessmentItemSelectorDirective() {
    return {
      restrict: 'E',
      require: '^^form',
      scope: {
        assessmentItemService: '<',
        courseUnitService: '<',
        selectedCourseUnit: '=?',
        selectedAssessmentItem: '=',
        selectedCurriculumPeriod: '=',
        allowOnlyOwnAssessmentItems: '=?',
        disabledAssessmentItems: '=?',
        required: '@?'
      },
      template: assessmentItemSelectorTpl,
      controller: 'assessmentItemSelectorController as ctrl',
      bindToController: true,
      link: function (scope, element, attrs, formCtrl) {
        var ctrl = scope.ctrl;
        ctrl.form = formCtrl;
      }
    };
  }

  /**
   * @ngInject
   */

  function assessmentItemSelectorController(universityService, SearchParameters, $scope) {
    var ctrl = this;
    ctrl.$onInit = function () {
      if (_.isEmpty(ctrl.assessmentItemService)) {
        throw 'assesmentItemSelector directive: assessmentItemService attribute is required';
      }
      if (_.isEmpty(ctrl.courseUnitService)) {
        throw 'assesmentItemSelector directive: courseUnitService attribute is required';
      }
      ctrl.assessmentItem = undefined;
      ctrl.searchResults = {};
      ctrl.searchText = '';
      ctrl.selectedAssessmentItemIndex = undefined;
      ctrl.selectedAssesmentItem = undefined;
      ctrl.selectedCourseUnit = undefined;
      ctrl.currentUniversity = universityService.getCurrentUniversity();
      ctrl.courseUnitsWithAssessmentItems = [];
      $scope.$watch('ctrl.selectedCourseUnit', function () {
        validateForm();
      });
      $scope.$watch('ctrl.selectedAssessmentItem', function () {
        validateForm();
      });
      validateForm();
      $scope.$watch('ctrl.searchText', function (newValue, oldValue) {
        if (ctrl.searchText && newValue !== oldValue) {
          search();
        }
      });
      $scope.$watch('ctrl.selectedCurriculumPeriod', function () {
        initSearchStringAndResults();
        search();
      });
    };
    function validateForm() {
      if (ctrl.form) {
        ctrl.form.$setDirty();
        ctrl.form.$setValidity(ctrl.form.$name + ".assessmentItemSelector.selectedCourseUnit.required", ctrl.selectedCourseUnit !== undefined);
        ctrl.form.$setValidity(ctrl.form.$name + ".assessmentItemSelector.selectedAssessmentItem.required", ctrl.selectedAssessmentItem !== undefined);
      }
    }
    function getSearchParams() {
      var searchParams = new SearchParameters({
        documentState: ['ACTIVE', 'DRAFT']
      });
      searchParams.searchString.toggleValue(ctrl.searchText);
      if (ctrl.selectedCurriculumPeriod) {
        searchParams.curriculumPeriods.toggleValue(ctrl.selectedCurriculumPeriod);
      }
      searchParams.universityOrgId.toggleValue(ctrl.universityOrgId);
      searchParams.validity = 'ONGOING_AND_FUTURE';
      return searchParams;
    }
    function search() {
      initSearchStringAndResults();
      if (ctrl.searchText.length > 255) {
        ctrl.searchStringIsTooLong = true;
        return;
      }
      if (ctrl.searchText.length < 3 || angular.isUndefined(ctrl.selectedCurriculumPeriod)) {
        ctrl.isValidSearch = false;
        return;
      }
      ctrl.loadingCourseUnits = true;
      return ctrl.courseUnitService.search(getSearchParams()).then(function (result) {
        ctrl.searchResults.results = result.searchResults;
        ctrl.searchResults.totalCount = result.total;
        ctrl.searchResults.hasMore = result.searchResults.length < result.total;
        ctrl.loadingCourseUnits = false;
        const courseUnitIdArray = _.map(ctrl.searchResults.results, 'id');
        if (!_.includes(courseUnitIdArray, _.get(ctrl.selectedCourseUnit, 'id'))) {
          //This will remember selection if search string changes 'kaupun' -> 'kaupunki'
          //This will forget selection if search string changes 'kaupun' -> 'johdatus'
          ctrl.selectedCourseUnit = undefined;
        }
        checkIfCourseUnitHasAssessmentItems(courseUnitIdArray);
      });
    }
    function checkIfCourseUnitHasAssessmentItems(courseUnitIdArray) {
      return ctrl.courseUnitService.findAllByIds(courseUnitIdArray).then(function (courseUnits) {
        _.forEach(courseUnits, function (courseUnit) {
          var courseUnitAssessmentItemIds = _.flatMap(courseUnit.completionMethods, 'assessmentItemIds');
          if (!_.isEmpty(courseUnitAssessmentItemIds)) {
            ctrl.courseUnitsWithAssessmentItems.push(courseUnit.id);
            ctrl.courseUnitsWithAssessmentItems = _.uniq(ctrl.courseUnitsWithAssessmentItems);
          }
        });
      });
    }
    ctrl.hasAssessmentItems = function (courseUnitId) {
      return _.includes(ctrl.courseUnitsWithAssessmentItems, courseUnitId);
    };
    function initSearchStringAndResults() {
      ctrl.loadingCourseUnits = false;
      ctrl.isValidSearch = true;
      ctrl.searchStringIsTooLong = false;
      ctrl.searchResults = {
        totalCount: 0,
        results: [],
        hasMore: false
      };
      ctrl.assessmentItems = [];
      ctrl.selectedAssessmentItem = undefined;
    }
    ctrl.selectCourseUnit = function (searchResult) {
      ctrl.loadingAssessmentItems = true;
      ctrl.courseUnitService.findById(searchResult.id).then(function (courseUnit) {
        ctrl.selectedCourseUnit = courseUnit;
        var courseUnitAssessmentItemIds = _.flatMap(courseUnit.completionMethods, 'assessmentItemIds');
        courseUnitAssessmentItemIds = _.uniq(courseUnitAssessmentItemIds);
        if (courseUnitAssessmentItemIds.length > 0) {
          return ctrl.assessmentItemService.findAllByIds(courseUnitAssessmentItemIds).then(function (courseUnitAssessmentItems) {
            return _.sortBy(courseUnitAssessmentItems, function (assessmentItem) {
              return !assessmentItem.isPrimaryCourseUnit(ctrl.selectedCourseUnit);
            });
          });
        } else {
          return [];
        }
      }).then(function (assessmentItems) {
        ctrl.selectedAssessmentItem = undefined;
        ctrl.assessmentItems = assessmentItems;
        ctrl.loadingAssessmentItems = false;
      });
    };
    ctrl.selectAssessmentItem = function (assessmentItem) {
      if (!ctrl.isDisabled(assessmentItem)) {
        ctrl.selectedAssessmentItem = assessmentItem;
      }
    };
    ctrl.isDisabled = function (assessmentItem) {
      return _.includes(ctrl.disabledAssessmentItems, assessmentItem.id) || ctrl.allowOnlyOwnAssessmentItems === true && !assessmentItem.isPrimaryCourseUnit(ctrl.selectedCourseUnit);
    };
    ctrl.disableReason = function (assessmentItem) {
      if (!ctrl.isDisabled(assessmentItem)) {
        return '';
      } else if (_.includes(ctrl.disabledAssessmentItems, assessmentItem.id)) {
        return 'SIS_COMPONENTS.ASSESSMENT_ITEM.ASSESSMENT_ITEM_SELECTOR.DISABLED_BECAUSE_ALREADY_SELECTED';
      } else {
        return 'SIS_COMPONENTS.ASSESSMENT_ITEM.ASSESSMENT_ITEM_SELECTOR.DISABLED_BECAUSE_NOT_PRIMARY_COURSE_UNIT';
      }
    };
  }
})();