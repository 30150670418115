import { Component, Input, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DATEPICKER_FORMAT, MOMENT_DATE_DISPLAY_FORMAT } from 'common-typescript/constants';
import moment from 'moment';

import { getLabelState } from '../../form/formUtils';

export type Placement = 'bottom-start' | 'top-start' | 'auto';

@Component({
    selector: 'sis-local-date-editor',
    templateUrl: './local-date-editor.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class LocalDateEditorComponent {

    @Input() control: FormControl;
    @Input() label: string;
    @Input() showLabelIcon = true;
    @Input() minDate?: NgbDateStruct;
    @Input() maxDate?: NgbDateStruct;
    @Input() name: string;
    @Input() startDate?: NgbDateStruct;
    /**
     * NgbBootsrap placement property defines date picker pop-over placement. The placement is set to auto as default.
     * Optional values 'bottom-start' or 'top-start' sticks the date picker to open always either on top or under the input field.
     */
    @Input() datepickerPlacement?: Placement = 'auto';

    readonly dateFormat = DATEPICKER_FORMAT;
    readonly currentDate = moment().format('D.M');

    get labelState(): string {
        return !this.label || !this.showLabelIcon ? '' : getLabelState(this.control);
    }

    selectToday(): void {
        this.control.setValue(moment().format(MOMENT_DATE_DISPLAY_FORMAT));
        this.control.markAsDirty();
    }
}
