import {
    AttainmentTreeAttainmentNode,
    AttainmentTreeGroupNode,
    AttainmentTreeNode,
    AttainmentTreeNodeType,
} from './attainment-tree-node.types';

export function isAttainmentTreeGroupNode(node: AttainmentTreeNode): node is AttainmentTreeGroupNode {
    return node.type === AttainmentTreeNodeType.Group;
}

export function isAttainmentTreeAttainmentNode(node: AttainmentTreeNode): node is AttainmentTreeAttainmentNode {
    return node.type === AttainmentTreeNodeType.Attainment;
}
