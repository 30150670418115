import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { iconMap } from './icon-helper';

export type Icon = 'achievement' | 'achievement-small' | 'alert' | 'alert-fill' | 'alert-fill-attention' | 'alert-small' | 'archive' | 'arrow-big' | 'arrow-dashed' |
'arrow-solid' | 'back' | 'brochure' | 'bullets' | 'calendar' | 'change-log' | 'check' | 'check-indeterminate-small' | 'check-small' |
'checkmark-circle' | 'chevron' | 'chevron-ring' | 'chevron-ring-fill' | 'clock' | 'close' | 'close-big' | 'code' | 'decrease-indent' | 'delete' |
'dot' | 'edit' | 'editor' | 'exclamation-mark-circle' | 'exclamation-mark-circle-fill' | 'exclamation-mark-small' | 'eye' |
'eye-blind' | 'fail' | 'hourglass' | 'increase-indent' | 'info' | 'info-circle' | 'info-circle-fill' |
'info-small' | 'junction' | 'link' | 'list-add' | 'list-minus' | 'lock' | 'lock-open' | 'magic-wand' |
'mail' | 'menu' | 'message' | 'messages' | 'minus' | 'minus-ring-fill' | 'new-tab' | 'notebook' | 'notification' | 'numbering' | 'pdf' | 'people' | 'person' | 'person-small' |
'picker' | 'pin-small' | 'place' | 'place-ring-fill' | 'plus' | 'print' | 'question-mark' | 'question-mark-small' | 'refresh' |
'required' | 'ring-close' | 'ring-close-fill' | 'ring-plus' |
'ring-plus-fill' | 'rosette' | 'rule' | 'search' | 'seats' | 'settings' | 'shopping-cart' | 'sorter' |
'star' | 'switch' | 'three-dots' | 'three-dots-small' | 'waiting-approval' | 'waiting-decline' | 'zoom-in' | 'zoom-out';

export type IconColor = 'default' | 'primary' | 'primary-dark' | 'danger' | 'white' | 'success' | 'attention' | 'light';

/**
 * Example: <sis-icon icon="info" color="primary"></sis-icon>
 * icon.scss has styles with fill attributes that can be used to color icons.
 *
 * Icons have default size 32x32. Icons with suffix -small are scaled to size 16x16.
 * Icons with suffix -fill ( "alert-fill" and "info-circle-fill" etc.) have their default style with white background. These icons cannot be colored accordingly with css fill.
 */
@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-icon',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './icon.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class IconComponent {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sisComponents.icon.icon',
        directiveName: 'sisIcon',
    };

    iconMap = iconMap;

    @Input() icon: Icon;
    @Input() color?: IconColor = 'default';
    @Input() iconClass?: string;
}
