<ng-container *transloco="let t">
  <div class="modal-header">
    <h2 class="modal-title" #headingElementRef tabindex="-1">
      {{ t('STUDENT_CUSTOM_STUDY_DRAFT_MODAL.TITLE') }} {{ studyDraft.name }}
    </h2>
    <button type="button"
            class="modal-close"
            (click)="close.emit()"
            [attr.aria-label]="t('ARIA_LABEL.CLOSE')">
      <sis-icon icon="ring-close"></sis-icon>
    </button>
  </div>
  <div class="modal-body">
    <p class="guidance">{{ t('STUDENT_CUSTOM_STUDY_DRAFT_MODAL.VIEW_MODE_GUIDANCE') }}</p>
    <sis-description-list>
      <dl>
        <ng-template #listItem>
          <dt class="label">{{t('STUDENT_CUSTOM_STUDY_DRAFT_MODAL.NAME')}}</dt>
          <dd class="value">
            {{ studyDraft.name }}
          </dd>
        </ng-template>
        <ng-template #listItem>
          <dt class="label">{{t('STUDENT_CUSTOM_STUDY_DRAFT_MODAL.CREDITS') + ' (' + t('CREDITS') + ')'}}</dt>
          <dd class="value">
            {{ studyDraft.credits | decimalNumber }}
          </dd>
        </ng-template>

        <ng-template #listItem>
          <dt class="label">{{t('STUDENT_CUSTOM_STUDY_DRAFT_MODAL.LOCATION')}}</dt>
          <dd class="value">
            {{ studyDraft.location }}
          </dd>
        </ng-template>

        <ng-template #listItem>
          <dt class="label">{{t('STUDENT_CUSTOM_STUDY_DRAFT_MODAL.DESCRIPTION')}}</dt>
          <dd class="value">
            {{ studyDraft.description }}
          </dd>
        </ng-template>
      </dl>
    </sis-description-list>
  </div>
  <div class="modal-footer" *ngIf="isSuggestButtonVisible">
    <sis-button [hollow]="true" (clicked)="close.emit()"  [label]="t('BUTTON.CANCEL')"></sis-button>
    <sis-button (clicked)="suggestAttainment.emit()" [label]="t('STUDENT_CUSTOM_STUDY_DRAFT_MODAL.SUGGEST_ATTAINMENT')"></sis-button>
  </div>
</ng-container>
