import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FullCalendarStudyEvent, ValidatablePlan } from 'common-typescript/types';
import { NGXLogger } from 'ngx-logger';
import { DEFAULT_PROMISE_HANDLER } from 'sis-common/ajs-upgraded-modules';
import { ModalService } from 'sis-common/modal/modal.service';

import { COURSE_UNIT_INFO_MODAL } from '../../../../ajs-upgraded-modules';

@Component({
    selector: 'app-study-event-summary-modal',
    templateUrl: './study-event-summary-modal.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class StudyEventSummaryModalComponent implements OnInit {

    constructor(private logger: NGXLogger,
                public activeModal: NgbActiveModal,
                @Inject(ModalService.injectionToken) public values: any,
                @Inject(COURSE_UNIT_INFO_MODAL) private courseUnitInfoModal: any,
                @Inject(DEFAULT_PROMISE_HANDLER) private defaultPromiseHandler: any,
    ) {}

    event: FullCalendarStudyEvent;
    getValidatablePlan: () => Promise<ValidatablePlan>;

    ngOnInit() {
        this.event = this.values.event;
        this.getValidatablePlan = this.values.getValidatablePlan;
    }

    openCourseUnitInfoModal(): void {
        if (this.event?.extendedProps.enrolment && this.getValidatablePlan) {
            const { courseUnitId } = this.event.extendedProps.enrolment;
            const { courseUnitRealisation } = this.event.extendedProps;
            if (courseUnitId) {
                this.getValidatablePlan()
                    .then(validatablePlan => this.courseUnitInfoModal.showCourseUnitRealisationForCourseUnit(courseUnitId, courseUnitRealisation, validatablePlan).then((this.activeModal.dismiss)))
                    .catch(this.defaultPromiseHandler.loggingRejectedPromiseHandler);
            } else {
                this.logger.info('courseUnit for calendar event not defined, can not open courseUnitInfoModal');
            }
        }
    }

}
