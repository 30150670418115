<ng-container *ngIf="loadUniversities$ | async">
  <sis-dropdown-select [alignRight]="!placeOnLeft"
                       [ariaLabelledBy]="ariaLabelledBy"
                       [compact]="compact"
                       [onDarkBackground]="onDarkBackground"
                       [options]="universityOptions"
                       [selection]="university.id"
                       (selectionChange)="selectUniversity($event)">
  </sis-dropdown-select>
</ng-container>

