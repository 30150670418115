import { Pipe, PipeTransform } from '@angular/core';
import { LocalizedString } from 'common-typescript/types';

import { LocaleService } from './locale.service';

@Pipe({ name: 'localizedStringMissingLocales' })
export class LocalizedStringMissingLocalesPipe implements PipeTransform {

    constructor(private localeService: LocaleService) { }

    /**
     * Returns the two-letter language codes of those university official languages for which the given localized
     * string doesn't have a (non-empty) value.
     */
    transform(value: LocalizedString): string[] {
        return (this.localeService.getOfficialLanguages() || []).filter(language => !value?.[language]);
    }
}
