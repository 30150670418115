import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { ValidatablePlan } from 'common-typescript/src/plan/validation/validatablePlan';
import { CompositeRule, EntityWithRule, Rule } from 'common-typescript/types';
import _ from 'lodash';
import {
    ComponentDowngradeMappings,
    DowngradedComponent,
    StaticMembers,
} from 'sis-common/types/angular-hybrid';

import { PlanData, PlanStateObject } from '../../../service/plan-state.service';
import { UiStateObject } from '../../plan-edit/plan-edit.component';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-composite-rule',
    templateUrl: './composite-rule.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class CompositeRuleComponent implements OnChanges {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.plan.rules.compositeRule',
        directiveName: 'sisCompositeRule',
    };

    sortedRules: Rule[];
    ruleValidationResult: any;
    subRuleClassesByLocalId: { [id: string]: string };
    showCountPhrase = false;

    @Input() parentModule: EntityWithRule;
    @Input() compositeRule: CompositeRule;
    @Input() planValidationResult: any;
    @Input() validatablePlan: ValidatablePlan;
    @Input() planStateObject: PlanStateObject;
    @Input() planData: PlanData;
    @Input() uiStateObject: UiStateObject;
    @Input() level: number;
    @Input() blockLevel: number;

    ngOnChanges() {
        this.sortedRules = _.sortBy(this.compositeRule.rules, (rule) => {
            switch (rule.type) {
                case 'CourseUnitRule':
                case 'AnyCourseUnitRule':
                    return 1;
                case 'ModuleRule':
                case 'AnyModuleRule':
                    return 2;
                default:
                    return 3;
            }
        });
        const rulesByLocalId = _.keyBy(this.sortedRules, 'localId');
        this.subRuleClassesByLocalId = _.mapValues(rulesByLocalId, rule => this.getSubRuleClass(rule));
        this.ruleValidationResult = this.getRuleValidationResult(this.planValidationResult, this.parentModule);

        this.showCountPhrase = this.compositeRule && !(
            _.get(this.compositeRule, 'require.min') === 0 &&
            _.get(this.compositeRule, 'require.max') === null
        );
    }

    getSubRuleClass(subRule: Rule) {
        const classes: string[] = [];
        if (this.isBlock(subRule)) {
            classes.push('sub-rule-block');
            classes.push(`sub-rule-block-level-${this.blockLevel}`);
            classes.push(`sub-rule-block-${(this.blockLevel % 2 === 0 ? 'even' : 'odd')}`);
        } else {
            classes.push('sub-rule');
        }

        const result = classes.join(' ');
        return result;
    }

    isBlock(rule: Rule): boolean {
        const result = _.includes(
            [
                'CompositeRule',
                'CreditsRule',
                'CourseUnitCountRule',
                'AnyCourseUnitRule',
                'AnyModuleRule',
            ],
            rule.type,
        );
        return result;
    }

    getRuleValidationResult(planValidationResult: any, module: EntityWithRule): any {
        return _.get(planValidationResult.ruleValidationResults, [module.id, this.compositeRule.localId]);
    }

}
