import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { ApplicationAttachments,
    Attachment,
    PriorLearningSubstitutionWorkflow,
    PriorLearningSubstitutionWorkflowApplication,
    PriorLearningWorkflowApplication,
} from 'common-typescript/types';
import _ from 'lodash';
import { catchError, finalize, Observable, of, tap, throwError } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { FileItem } from 'sis-components/file-upload/file-upload.component';
import { ApplicationAttachmentEntityService } from 'sis-components/service/application-attachment-entity.service';
import { WorkflowDataChangeService } from 'sis-components/service/workflow-data-change.service';

import { ApplicationCreationService } from '../../../../common/service/application-creation.service';

@Component({
    selector: 'app-prior-learning-substitution-workflow-supplement-wizard',
    templateUrl: './prior-learning-substitution-workflow-supplement-wizard.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class PriorLearningSubstitutionWorkflowSupplementWizardComponent implements OnInit {
    @Input() workflow: PriorLearningSubstitutionWorkflow;

    readonly wizardStepKeys = [
        'PROFILE.APPLICATIONS.PRIOR_LEARNING.SUPPLEMENT.SUBSTITUTION_PHASE_1',
        'PROFILE.APPLICATIONS.PRIOR_LEARNING.SUPPLEMENT.SUBSTITUTION_PHASE_2',
    ];

    currentStep = 0;

    application: Partial<PriorLearningSubstitutionWorkflowApplication>;
    attachments: FileItem[] = [];
    applicationAttachments: ApplicationAttachments;
    uploading = false;

    existingAttachments$: Observable<FileItem[]>;
    supplementRequestRationale$: Observable<string>;

    constructor(
        private applicationCreationService: ApplicationCreationService,
        private appErrorHandler: AppErrorHandler,
        private applicationAttachmentEntityService: ApplicationAttachmentEntityService,
        private workflowDataChangeService: WorkflowDataChangeService,
        private state: StateService,
        @Inject(DOCUMENT) private document: Document,
    ) {}

    ngOnInit(): void {
        this.existingAttachments$ = this.applicationAttachmentEntityService.getAttachmentsByApplicationId(this.workflow.id).pipe(
            catchError(err => {
                // Expect 404 if the workflow has no attachments, pass other errors to default handler
                if (err.status === 404) {
                    return of(undefined);
                }
                return throwError(() => err);
            }),
            this.appErrorHandler.defaultErrorHandler(),
            map(((applicationAttachments: ApplicationAttachments) => {
                this.applicationAttachments = applicationAttachments;
                if (applicationAttachments?.attachments) {
                    return this.getFileItemsFromAttachments(applicationAttachments.attachments as Attachment[]);
                }
                return [];
            })),
            tap(fileItems => this.attachments = fileItems),
        );
        this.application = { ...this.workflow.application as PriorLearningSubstitutionWorkflowApplication };
        this.supplementRequestRationale$ = this.workflowDataChangeService.getActiveSupplementRequestDescription(this.workflow.id)
            .pipe(this.appErrorHandler.defaultErrorHandler());
    }

    getFileItemsFromAttachments(attachments: Attachment[]): FileItem[] {
        const fileItems: FileItem[] = [];
        _.each(attachments, (attachment) => {
            const file = new File([new ArrayBuffer(attachment.size)], attachment.name, { type: attachment.fileType });
            fileItems.push({
                file,
                explanation: attachment.comment,
                preSignedGetUrl: attachment.preSignedGetUrl,
                localId: attachment.localId,
                name: file.name.normalize() });
        });
        return fileItems;
    }

    onAttachmentsChange(attachments: FileItem[]): void {
        this.attachments = attachments ?? [];
    }

    onFormValueChange(changes: Partial<PriorLearningSubstitutionWorkflowApplication>): void {
        if (changes) {
            this.application = { ...this.application, ...changes };
        }
    }

    isFirstStep(): boolean {
        return this.currentStep === 0;
    }

    isLastStep(): boolean {
        return this.currentStep === this.wizardStepKeys.length - 1;
    }

    exit(): void {
        this.state.go('^');
    }

    previous(): void {
        if (!this.isFirstStep()) {
            this.currentStep -= 1;
        }
    }

    continue(changes: Partial<PriorLearningSubstitutionWorkflowApplication>): void {
        this.onFormValueChange(changes);
        if (!this.isLastStep()) {
            this.currentStep += 1;
        }
    }

    submit(changes: Partial<PriorLearningWorkflowApplication>) {
        if (!this.uploading) {
            this.onFormValueChange(changes as PriorLearningSubstitutionWorkflowApplication);
            this.uploading = true;
            this.applicationCreationService.supplementWorkflowApplication(
                this.workflow.id,
                this.workflow.studentId,
                this.application,
                this.attachments,
                this.applicationAttachments?.attachments,
                this.applicationAttachments?.metadata,
            ).pipe(
                take(1),
                this.appErrorHandler.defaultErrorHandler(),
                finalize(() => this.uploading = false),
            ).subscribe(
                {
                    next: () => {
                        this.document.defaultView?.scrollTo(0, 0);
                        this.state.go('^', {}, { custom: { skipConfirmationDialog: true }, reload: true });
                    },
                },
            );
        }
    }
}
