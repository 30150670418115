import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Range } from 'common-typescript';
import { CourseUnit, CreditRange, CustomStudyDraft, StudyTerm, StudyTermLocator } from 'common-typescript/types';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

// TODO: This interface represents the return type of groupPlannedStudiesByStudyTerm() in the legacy plan.service.js.
// Move it to the Angular version of the service when one exists.
export interface PlannedStudies {
    studiesByStudyTerm: StudyByStudyTerm[];
    plannedCreditsTotal: CreditRange;
}

export interface StudyByStudyTerm {
    studyTerm: StudyTerm;
    plannedCredits: Range;
    courseUnits: CourseUnit[];
    courseUnitTimingInfo: { [courseUnitId: string]: StudyTermLocator[] };
    customStudyDrafts: CustomStudyDraft[];
    customStudyDraftTimingInfo: { [customStudyDraftId: string]: StudyTermLocator[] };
}

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-planned-studies-by-study-term',
    templateUrl: './planned-studies-by-study-term.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlannedStudiesByStudyTermComponent implements OnInit {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.lib.applications.planned-studies-by-study-term',
        directiveName: 'sisPlannedStudiesByStudyTerm',
    };

    @Input() title?: string;
    @Input() plannedStudies: PlannedStudies;
    @Input() applicationCreationTime: string;

    applicationSubmitDate: Moment;

    ngOnInit() {
        this.applicationSubmitDate = moment(this.applicationCreationTime);
    }

    getStudyTermYear = (studyTerm: StudyTerm) => moment(studyTerm.valid.startDate).year();

    isCurrentTerm = (studyTerm: StudyTerm) => {
        const { startDate, endDate } = studyTerm.valid;
        return this.applicationSubmitDate && this.applicationSubmitDate.isBetween(startDate, endDate, 'day', '[)');
    };

    hasNoPlannedStudies = (entry: any) => _.isEmpty(entry.courseUnits) && _.isEmpty(entry.customStudyDrafts);
}
