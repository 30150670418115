<sis-description-list *ngIf="product" [columnCount]="1">
  <ng-template #listItem>
    <dt>{{'OPEN_UNIVERSITY.STUDY_OFFERING.CREDITS_LABEL' | translate}}</dt>
    <dd>{{product.credits | decimalNumber}} {{'CREDITS' | translate}}</dd>
  </ng-template>
  <ng-template #listItem>
    <dt>{{'OPEN_UNIVERSITY.STUDY_OFFERING.ASSESSMENT_ITEMS' | translate}}</dt>
    <dd *ngFor="let assessmentItem of assessmentItems$ | async; trackBy: entityId">
      {{assessmentItem.name | localizedString}}
    </dd>
  </ng-template>
  <ng-template #listItem>
    <dt>{{'OPEN_UNIVERSITY.STUDY_OFFERING.PRICE' | translate}}</dt>
    <dd>{{product.price | money}}</dd>
  </ng-template>
  <ng-template #listItem>
    <ng-container *ngIf="product.isOnSale; else futureProduct">
      <dt>{{('OPEN_UNIVERSITY.STUDY_OFFERING.SALES_PERIOD_ENDS' | translate)}}</dt>
      <dd>{{product.salesPeriod.endDateTime | localDateTimeFormat}}</dd>
    </ng-container>
    <ng-template #futureProduct>
      <dt>{{('OPEN_UNIVERSITY.STUDY_OFFERING.SALES_PERIOD_STARTS' | translate)}}</dt>
      <dd>{{product.salesPeriod.startDateTime | localDateTimeFormat}}</dd>
    </ng-template>
  </ng-template>
</sis-description-list>
