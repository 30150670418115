import angular from 'angular';
import 'js-data';
import { jsDataRelationHelperModule } from '../service/jsDataRelationHelper.service';
import { DegreeProgrammeEntityService } from "sis-components/service/degree-programme-entity.service";
export const degreeProgrammeModelModule = 'sis-components.model.degreeProgramme';
(function () {
  degreeProgrammeModel.$inject = ["DS", "$q", "$log", "$injector", "$rootScope", "jsDataRelationHelperService", "educationClassificationModel", "municipalityJSDataModel"];
  angular.module(degreeProgrammeModelModule, ['js-data', 'sis-components.model.educationClassification', 'sis-components.model.municipality', jsDataRelationHelperModule, DegreeProgrammeEntityService.downgrade.moduleName]).factory('degreeProgrammeJSDataModel', degreeProgrammeModel).run(["degreeProgrammeJSDataModel", function (degreeProgrammeJSDataModel) {}]); //NOSONAR

  /**
   * @ngInject
   */
  function degreeProgrammeModel(DS, $q, $log, $injector, $rootScope, jsDataRelationHelperService, educationClassificationModel, municipalityJSDataModel) {
    function loadDegreeProgrammeUniversityOrgsPersonAndOrganisationRelations(degreeProgramme) {
      var relationLoads = [jsDataRelationHelperService.loadOrganisationRelations(degreeProgramme.organisations), jsDataRelationHelperService.loadModuleResponsibilityInfoRelations(degreeProgramme.responsibilityInfos), jsDataRelationHelperService.loadUniversityOrgs([degreeProgramme])];
      return $q.all(relationLoads).then(function () {
        return degreeProgramme;
      }, function (error) {
        $log.warn("Failed to load degreeProgramme organisations and person relations", degreeProgramme, error);
      });
    }
    return DS.defineResource({
      idAttribute: 'id',
      name: 'degreeProgramme',
      endpoint: '/kori/api/degree-programmes',
      relations: {
        hasOne: {
          degreeProgramType: {
            localField: 'degreeProgramType',
            localKey: 'degreeProgramTypeUrn'
          },
          gradeScale: {
            localField: 'gradeScale',
            localKey: 'gradeScaleId'
          }
        },
        hasMany: {
          'studyField': {
            localField: 'localStudyFields',
            localKeys: 'studyFields'
          },
          educationClassification: {
            localField: 'educationClassifications',
            localKeys: 'educationClassificationUrns'
          },
          organisation: {
            localField: 'universityOrgs',
            localKeys: 'universityOrgIds'
          },
          degreeTitle: {
            localField: 'degreeTitles',
            localKeys: 'degreeTitleUrns'
          },
          officialLanguage: {
            localField: 'degreeLanguages',
            localKeys: 'degreeLanguageUrns'
          },
          curriculumPeriod: {
            localField: 'curriculumPeriods',
            localKeys: 'curriculumPeriodIds'
          },
          municipality: {
            localField: 'educationLocations',
            localKeys: 'educationLocationUrns',
            get: municipalityJSDataModel.lazyGet
          }
        }
      },
      methods: {
        loadRelations: function () {
          return loadDegreeProgrammeUniversityOrgsPersonAndOrganisationRelations(this);
        }
      },
      beforeUpdate: function (resource, degreeProgramme, cb) {
        if (degreeProgramme.contentDescription) {
          degreeProgramme.contentDescription = _(degreeProgramme.contentDescription).omitBy(_.isUndefined).omitBy(_.isNull).value();
        }
        if (degreeProgramme.validityPeriod && !degreeProgramme.validityPeriod.startDate && !degreeProgramme.validityPeriod.endDate) {
          degreeProgramme.validityPeriod = undefined;
        }
        cb(null, degreeProgramme);
      },
      afterUpdate: function (resource, degreeProgramme, cb) {
        const degreeProgrammeEntityService = $injector.get('degreeProgrammeEntityService');
        degreeProgrammeEntityService.storeUpsert(_.cloneDeep(degreeProgramme));
        $rootScope.$broadcast('DegreeProgrammeChange', degreeProgramme);
        return loadDegreeProgrammeUniversityOrgsPersonAndOrganisationRelations(degreeProgramme).then(function () {
          cb(null, degreeProgramme);
        });
      },
      afterCreate: (resource, degreeProgramme, cb) => {
        const degreeProgrammeEntityService = $injector.get('degreeProgrammeEntityService');
        degreeProgrammeEntityService.storeUpsert(_.cloneDeep(degreeProgramme));
        $rootScope.$broadcast('DegreeProgrammeChange', degreeProgramme);
        cb(null, degreeProgramme);
      }
    });
  }
})();