import { ChangeDetectionStrategy, Component, inject, ViewEncapsulation } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from 'sis-common/auth/auth-service';
import { UniversityService } from 'sis-components/service/university.service';
import { MainNavigationItem } from 'sis-components/topNav/top-navigation/top-navigation.component';

import { studentRoutes } from '../../../auth/student-routes';

const ALL_ITEMS: MainNavigationItem[] = [
    {
        translationKey: 'APPLICATION_STRUCTURE_OF_STUDIES',
        toState: 'student.logged-in.planSelect',
        loginRequired: true,
        linkId: 'top-nav-student-studies-structure',
    },
    {
        translationKey: 'APPLICATION_STRUCTURE_OF_STUDIES_WIP',
        toState: 'student.logged-in.new-plan',
        loginRequired: true,
        linkId: 'top-nav-student-studies-structure-wip',
    },
    {
        translationKey: 'APPLICATION_TIMELINE',
        toState: 'student.logged-in.plan.timing',
        loginRequired: true,
        linkId: 'top-nav-student-timing',
    },
    {
        translationKey: 'APPLICATION_ENROLMENTS',
        toState: 'student.logged-in.enrolments',
        loginRequired: true,
        linkId: 'top-nav-student-enrolments',
    },
    {
        translationKey: 'CALENDAR',
        toState: 'student.logged-in.calendar',
        loginRequired: true,
        linkId: 'top-nav-student-calendar',
    },
    {
        translationKey: 'APPLICATION_STUDENT_PROFILE',
        toState: 'student.logged-in.profile',
        loginRequired: true,
        linkId: 'top-nav-student-profile',
    },
    {
        translationKey: 'APPLICATION_SEARCH',
        toState: 'student.search',
        loginRequired: false,
        linkId: 'top-nav-student-course-unit-search',
    },
];

@Component({
    selector: 'app-student-top-navigation',
    template: '<sis-top-navigation *ngIf="items$ | async as items" [mainNavigationItems]="items" [homeState]="homeState"/>',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudentTopNavigationComponent {

    private readonly authService = inject(AuthService);
    private readonly universityService = inject(UniversityService);

    readonly homeState = studentRoutes.defaultView;

    items$ = combineLatest([this.isNewPlanTabEnabled(), this.authService.userLoggedIn$])
        .pipe(
            map(([newPlanTabEnabled, loggedIn]) => ALL_ITEMS
                .filter(item => newPlanTabEnabled || item.linkId !== 'top-nav-student-studies-structure-wip')
                .filter(item => loggedIn || !item.loginRequired)),
        );

    private isNewPlanTabEnabled(): Observable<boolean> {
        return this.universityService.getCurrentUniversitySettings()
            .pipe(
                map(settings => settings?.frontendFeatureToggles?.newPlanTabEnabled ?? false),
                catchError(() => of(false)),
            );
    }
}
