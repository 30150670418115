'use strict';

(function () {
  assessmentItemService.$inject = ["$http", "assessmentItemModel", "findByIdsHelper"];
  angular.module('student.common.service.assessmentItemService', ['student.common.model.assessmentItem', 'student.common.utils.findByIdsHelper']).factory('assessmentItemService', assessmentItemService);

  /**
   * @ngInject
   */
  function assessmentItemService($http, assessmentItemModel, findByIdsHelper) {
    var baseUri = '/kori/api/assessment-items';
    var api = {
      get: function (id) {
        return $http.get(baseUri + '/' + id).then(function (result) {
          return assessmentItemModel.newAssessmentItem(result.data);
        });
      },
      findById: function (id) {
        return api.findByIds([id]).then(function (list) {
          return list[0];
        });
      },
      findByIds: function (ids) {
        return findByIdsHelper(baseUri, ids, 'id').then(function (data) {
          return toAssessmentItems(data);
        });
      },
      findAssessmentItemByDate: function (id, date) {
        return $http.get(baseUri + '/' + id + '/at-date/' + date).then(function (result) {
          return assessmentItemModel.newAssessmentItem(result.data);
        });
      }
    };
    function toAssessmentItems(list, assessmentItemCache) {
      return _.map(list, function (data) {
        if (assessmentItemCache && assessmentItemCache[data.id]) {
          return assessmentItemCache[data.id];
        }
        return assessmentItemModel.newAssessmentItem(data);
      });
    }
    return api;
  }
})();