import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'sis-wizard-progress',
    templateUrl: './wizard-progress.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class WizardProgressComponent {

    @Input() stepKeys: string[];
    @Input() currentStep: number;
    /**
     * An optional aria-label, which may be used to provide more information about the content of the wizard progress
     */
    @Input() ariaLabel: string;
}
