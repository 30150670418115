<div>
    <div *ngFor="let studyGroupSet of studyGroupSets"
         class="study-sub-group-set">
        <div class="study-group-set-title">
            {{ studyGroupSet.name | localizedString }}
        </div>
        <div *ngFor="let studySubGroup of studyGroupSet.studySubGroups" class="study-group-set-content">
            <sis-study-sub-group-structure
                    [studySubGroup]="studySubGroup"
                    [language]="language">
            </sis-study-sub-group-structure>
        </div>
    </div>
</div>
