import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { PlanValidationState, StudyRight } from 'common-typescript/types';
import { take } from 'rxjs';
import { ModalService } from 'sis-common/modal/modal.service';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';

import { StudyRightExtensionRequirementsModalComponent, StudyRightExtensionRequirementsModalValues } from '../components/study-right-extension-requirements-modal/study-right-extension-requirements-modal.component';

import { StudyRightExtensionRequirements, StudyRightExtensionRequirementsService } from './study-right-extension-requirements.service';

@Injectable({ providedIn: 'root' })
export class StudyRightExtensionApplyingService {

    constructor(
        private readonly _requirementsService: StudyRightExtensionRequirementsService,
        private readonly _stateService: StateService,
        private readonly _modalService: ModalService,
        private readonly _appErrorHandler: AppErrorHandler,
    ) {
    }

    /**
     * Checks if the given study right has a primary plan meeting the requirements to extend the study right,
     * and redirects to the extension application.
     * If requirements are not met, a modal is opened to inform the user about this and to guide the user to fix the situation.
     */
    checkRequirementsAndProceedToApplication(studyRight: StudyRight): void {
        this._requirementsService.getStudyRightExtensionRequirements(studyRight).pipe(
            take(1),
            this._appErrorHandler.defaultErrorHandler(),
        ).subscribe((requirements: StudyRightExtensionRequirements | null) => {
            if (!!requirements && requirements.planState === PlanValidationState.PLANNED && allStudiesTimedCorrectly(requirements)) {
                this._stateService.go(
                    'student.logged-in.profile.applications.create-study-right-extension-application',
                    {
                        planId: requirements.primaryPlan.id,
                        studyRightId: studyRight.id,
                    },
                );
            } else {
                this._modalService.open(
                    StudyRightExtensionRequirementsModalComponent,
                    <StudyRightExtensionRequirementsModalValues>{
                        studyRight,
                        primaryPlan: requirements?.primaryPlan ?? undefined,
                        planState: requirements?.planState ?? undefined,
                        courseUnitIdsWithNoTiming: requirements?.courseUnitIdsWithNoTiming ?? [],
                        courseUnitIdsWithTimingInThePast: requirements?.courseUnitIdsWithTimingInThePast ?? [],
                        customStudyDraftIdsWithNoTiming: requirements?.customStudyDraftIdsWithNoTiming ?? [],
                        customStudyDraftIdsWithTimingInThePast: requirements?.customStudyDraftIdsWithTimingInThePast ?? [],
                    },
                    { size: 'sm' },
                );
            }
        });
    }
}

function allStudiesTimedCorrectly(requirements: StudyRightExtensionRequirements) {
    return !requirements.courseUnitIdsWithNoTiming.length
        && !requirements.customStudyDraftIdsWithNoTiming.length
        && !requirements.courseUnitIdsWithTimingInThePast.length
        && !requirements.customStudyDraftIdsWithTimingInThePast.length;
}
