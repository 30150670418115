import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

/**
 * A small loading spinner. Same size as regular text, so fits nicely inside text elements (e.g. form labels).
 */
@Component({
    selector: 'sis-small-spinner',
    templateUrl: './small-spinner.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmallSpinnerComponent {}
