import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { DegreeProgramme, StudyModule } from 'common-typescript/types';

@Component({
    selector: 'app-module-info-classification',
    templateUrl: './module-info-classification.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModuleInfoClassificationComponent {

    @Input() set module(module: StudyModule | DegreeProgramme) {
        this._module = module;
        this.showClassificationInfo = this.moduleHasAnyClassifications(this._module);
    }

    _module: StudyModule | DegreeProgramme;
    showClassificationInfo = false;

    isDegreeProgramme(module: StudyModule | DegreeProgramme): module is DegreeProgramme { return module.type === 'DegreeProgramme'; }
    isStudyModule(module: StudyModule | DegreeProgramme): module is StudyModule { return module.type === 'StudyModule'; }

    moduleHasAnyClassifications(module: StudyModule | DegreeProgramme): boolean {
        let anyDegreeProgrammeFields = false;
        if (this.isDegreeProgramme(module)) {
            anyDegreeProgrammeFields = module?.degreeLanguageUrns?.length > 0
            || module?.degreeTitleUrns?.length > 0
            || module?.studyFields?.length > 0;
        }
        let anyStudyModuleFields = false;
        if (this.isStudyModule(module)) {
            anyStudyModuleFields = module?.possibleAttainmentLanguages?.length > 0;
        }
        return anyDegreeProgrammeFields
            || anyStudyModuleFields;
    }
}
