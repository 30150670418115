import angular from 'angular';
import _ from 'lodash';
export const commonCourseUnitServiceModule = 'sis-components.service.courseUnitService';
(function () {
  courseUnitService.$inject = ["$q", "$http", "courseUnitJSDataModel", "COURSE_UNIT_URL", "jsDataCacheHelper", "universityService", "localeService", "commonCurriculumPeriodService"];
  angular.module(commonCourseUnitServiceModule, ['sis-components.model.courseUnit', 'sis-components.service.curriculumPeriodService', 'sis-common.university', 'sis-common.l10n.localeService', 'sis-components.service.jsDataCacheHelper']).factory('commonCourseUnitService', courseUnitService);

  /**
   * @ngInject
   */
  function courseUnitService($q, $http, courseUnitJSDataModel, COURSE_UNIT_URL, jsDataCacheHelper, universityService, localeService, commonCurriculumPeriodService) {
    function doSearch(endpoint, searchParams) {
      return $http.get(endpoint, searchParams.build()).then(result => result.data);
    }
    function doSearchWithParams(endpoint, params) {
      return $http.get(endpoint, params).then(result => result.data);
    }
    function courseUnitAssessmentItemIds(courseUnit) {
      const completionMethods = _.get(courseUnit, 'completionMethods', []);
      return _.uniq(_.compact(_.reduce(completionMethods, (assessmentItemIds, completionMethod) => _.concat(assessmentItemIds, _.get(completionMethod, 'assessmentItemIds', [])), [])));
    }
    function courseUnitHasAtLeastOneCommonAssessmentItemIdWithCourseUnitRealisation(courseUnit, courseUnitRealisation) {
      return _.size(_.intersection(_.get(courseUnitRealisation, 'assessmentItemIds', []), courseUnitAssessmentItemIds(courseUnit))) > 0;
    }
    function findAll(cuGroupId, bypassCache, endpoint, documentState) {
      const options = {
        endpoint,
        bypassCache: !!bypassCache
      };
      const params = {
        groupId: cuGroupId,
        includeExpired: true
      };
      if (!_.isNil(documentState)) {
        params.documentState = documentState;
      }
      return courseUnitJSDataModel.findAll(params, options);
    }
    const api = {
      courseUnitAssessmentItemIds,
      courseUnitHasAtLeastOneCommonAssessmentItemIdWithCourseUnitRealisation,
      findById: function (courseUnitId, options) {
        return courseUnitJSDataModel.find(courseUnitId, options).then(courseUnit => {
          if (_.get(options, 'loadRelations')) {
            return courseUnit.loadRelations();
          }
          return courseUnit;
        });
      },
      findByGroupId: function (courseUnitGroupId, curriculumPeriodId, documentStates) {
        const options = {
          endpoint: COURSE_UNIT_URL.FIND_BY_GROUP_ID
        };
        const params = {
          groupId: courseUnitGroupId,
          curriculumPeriodId,
          universityId: universityService.getCurrentUniversityOrgId(),
          documentStates
        };
        return courseUnitJSDataModel.findAll(params, options).then(result => _.first(result));
      },
      findByGroupIds: function (courseUnitGroupIds, curriculumPeriodId, documentStates) {
        if (_.isEmpty(courseUnitGroupIds)) {
          return $q.when([]);
        }
        const options = {
          endpoint: COURSE_UNIT_URL.FIND_BY_GROUP_ID
        };
        const params = {
          groupId: courseUnitGroupIds,
          curriculumPeriodId,
          universityId: universityService.getCurrentUniversityOrgId(),
          documentStates
        };
        return courseUnitJSDataModel.findAll(params, options);
      },
      search: function (searchParameters) {
        return doSearch(COURSE_UNIT_URL.AUTHENTICATED_SEARCH_URL, searchParameters);
      },
      // Same as search but with unwrapped parameters
      searchWithParams: function (params) {
        return doSearchWithParams(COURSE_UNIT_URL.AUTHENTICATED_SEARCH_URL, {
          params
        });
      },
      searchActive: function (searchParameters) {
        return doSearch(COURSE_UNIT_URL.SEARCH_URL, searchParameters);
      },
      findAllVersions: function (cuGroupId, bypassCache, documentState, endpoint) {
        if (!endpoint) {
          endpoint = COURSE_UNIT_URL.ENDPOINT_URL;
        }
        return findAll(cuGroupId, bypassCache, endpoint, documentState || ['ACTIVE', 'DRAFT', 'DELETED']);
      },
      findAllVersionsForStaff: function (cuGroupId, documentState) {
        const endpoint = COURSE_UNIT_URL.AUTHENTICATED_ENDPOINT_URL;
        return findAll(cuGroupId, true, endpoint, documentState || ['ACTIVE', 'DRAFT', 'DELETED']);
      },
      findAllVersionsForStudent: function (courseUnitGroupId) {
        return findAll(courseUnitGroupId, true, COURSE_UNIT_URL.ENDPOINT_URL);
      },
      findAllActiveVersions: function (cuGroupId, bypassCache, loadRelations) {
        return findAll(cuGroupId, bypassCache, COURSE_UNIT_URL.ENDPOINT_URL).then(results => loadRelations ? $q.all(results.map(result => result.loadRelations())) : results);
      },
      getDataForStudySelector: groupId => {
        const curriculumPeriodPromise = commonCurriculumPeriodService.findByUniversityOrgId(universityService.getCurrentUniversityOrgId());
        const allVersionsPromise = api.findAllVersionsForStudent(groupId);
        return $q.all([curriculumPeriodPromise, allVersionsPromise]).then(res => {
          const result = {
            allCurriculumPeriods: res[0],
            allCourseUnitVersions: res[1]
          };
          return result;
        });
      },
      findAllByIds: function (ids) {
        return jsDataCacheHelper.findByIds(courseUnitJSDataModel, ids, false).then(courseUnits => courseUnits);
      },
      findByAssessmentItemId: function (assessmentItemId, includeDrafts) {
        const params = {
          assessmentItemId
        };
        if (includeDrafts) {
          params.documentState = ['ACTIVE', 'DRAFT'];
        }
        const options = {
          endpoint: includeDrafts ? COURSE_UNIT_URL.AUTHENTICATED_ENDPOINT_URL : COURSE_UNIT_URL.ENDPOINT_URL
        };
        return courseUnitJSDataModel.findAll(params, options).then(courseUnits => $q.all(_.map(courseUnits, courseUnit => courseUnit.loadRelations())).then(() => courseUnits));
      },
      findCourseUnitsByCourseUnitRealisationTeacherFilteredByGroupIdAndCurriculumPeriod: function (courseUnitRealisations, activityStatus) {
        if (!activityStatus) {
          activityStatus = 'ALL';
        }
        const params = {
          activityStatus,
          documentState: ['ACTIVE', 'DRAFT']
        };
        return $http.get(COURSE_UNIT_URL.COURSE_UNIT_REALISATION_TEACHER_URL, {
          params
        }).then(result => {
          const retval = {};
          _.forEach(courseUnitRealisations, courseUnitRealisation => {
            retval[courseUnitRealisation.id] = _.filter(result.data, courseUnit => courseUnitHasAtLeastOneCommonAssessmentItemIdWithCourseUnitRealisation(courseUnit, courseUnitRealisation));
          });
          return retval;
        });
      },
      findAssociatedCourseUnitsForCourseUnitRealisation: function (courseUnitRealisation, includeDrafts) {
        const assessmentItemIds = _.uniq(courseUnitRealisation.assessmentItemIds);
        if (_.isEmpty(assessmentItemIds)) {
          return $q.when([]);
        }
        return api.findByAssessmentItemId(assessmentItemIds, includeDrafts).then(courseUnitsForAssessmentItemIds => courseUnitsForAssessmentItemIds);
      },
      // Gets the display name for the course unit from its curriculum periods
      getDisplayName: function (curriculumPeriods) {
        return universityService.getUniversities().then(universities => {
          let displayName = '';
          const universitiesById = _.keyBy(universities, 'id');
          _.forEach(curriculumPeriods, curriculumPeriod => {
            const university = _.get(universitiesById, curriculumPeriod.universityOrgId);
            if (displayName.length > 0) {
              displayName += '; ';
            }
            displayName += `${localeService.getLocalizedValue(curriculumPeriod.abbreviation)} (${localeService.getLocalizedValue(university.abbreviation)})`;
          });
          return displayName;
        });
      },
      getVersionSelectorDataForCourseUnitVersions: courseUnits => {
        const displayNamesByCourseUnitId = {};
        const courseUnitVersions = [];
        let curriculumPeriodsById = {};
        let universitiesById = {};
        const curriculumPeriodIds = _.chain(courseUnits).flatMap('curriculumPeriodIds').compact().value();
        const curriculumPeriodPromise = commonCurriculumPeriodService.findCurriculumPeriodsByIds(curriculumPeriodIds).then(curriculumPeriods => {
          curriculumPeriodsById = _.keyBy(curriculumPeriods, 'id');
        });
        const universityPromise = universityService.getUniversities().then(universities => {
          universitiesById = _.keyBy(universities, 'id');
        });
        return $q.all([curriculumPeriodPromise, universityPromise]).then(() => {
          _.forEach(courseUnits, courseUnit => {
            let displayName = '';
            _.forEach(courseUnit.curriculumPeriodIds, curriculumPeriodId => {
              const curriculumPeriod = _.get(curriculumPeriodsById, curriculumPeriodId);
              const university = _.get(universitiesById, curriculumPeriod.universityOrgId);
              if (displayName.length > 0) {
                displayName += '; ';
              }
              displayName += `${localeService.getLocalizedValue(_.get(curriculumPeriod, 'abbreviation'))} (${localeService.getLocalizedValue(_.get(university, 'abbreviation'))})`;
            });
            courseUnitVersions.push({
              courseUnit,
              curriculumPeriods: _.map(courseUnit.curriculumPeriodIds, cpId => _.get(curriculumPeriodsById, cpId))
            });
            displayNamesByCourseUnitId[courseUnit.id] = displayName;
          });
          return {
            courseUnitVersions,
            displayNamesByCourseUnitId
          };
        });
      },
      getAllSubstituteCourseUnitGroupIds: courseUnit => _.chain(courseUnit).get('substitutions', []).flatMap().map('courseUnitGroupId').uniq().value()
    };
    return api;
  }
})();