<ng-container *transloco="let t">
  <div class="container sis-pt-md">
    <sis-breadcrumbs></sis-breadcrumbs>
    <sis-page-title [title]="t('APPLICATION_STRUCTURE_OF_STUDIES')" [showCrumbs]="true"/>
    <p>{{ t('PLAN.CREATE_FIRST_PLAN.INFO_TEXT') }}</p>
    <fudis-button (handleClick)="this.openCreatePlanModal()"
                  [label]="t('PLAN.CREATE_FIRST_PLAN.CREATE_NEW_PLAN_BUTTON_TEXT')">
    </fudis-button>
  </div>
</ng-container>
