import { Injectable } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UIRouterGlobals } from '@uirouter/core';
import { dateUtils } from 'common-typescript';
import { EnrolmentRightChangeLogItem, PersonInfo } from 'common-typescript/types';
import { combineLatest, Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ModalService } from 'sis-common/modal/modal.service';

import { AppErrorHandler } from '../../error-handler/app-error-handler';
import { InfoDialogService } from '../../info-dialog/info-dialog.service';
import { PersonInfoEntityService } from '../../service/person-info-entity.service';
import { PersonNameService } from '../../service/person-name.service';

import { AddModalValues, EnrolmentRightAddModalComponent } from './add-modal/enrolment-right-add-modal.component';
import { CancelModalValues, EnrolmentRightCancelModalComponent } from './cancel-modal/enrolment-right-cancel-modal.component';
import { EditModalValues, EnrolmentRightEditModalComponent } from './edit-modal/enrolment-right-edit-modal.component';

@Injectable({ providedIn: 'root' })
export class EnrolmentRightModalService {

    constructor(
        private infoDialogService: InfoDialogService,
        private modalService: ModalService,
        private personInfoService: PersonInfoEntityService,
        private personNameService: PersonNameService,
        private uiRouterGlobals: UIRouterGlobals,
        private appErrorHandler: AppErrorHandler,
    ) {}

    openEditModal(modalValues: EditModalValues): Promise<any> {
        return this.modalService.open(EnrolmentRightEditModalComponent, modalValues).result;
    }

    openCancelModal(modalValues: CancelModalValues): Promise<any> {
        return this.modalService.open(EnrolmentRightCancelModalComponent, modalValues, { size: 'sm' }).result;
    }

    openAddModal(modalValues: AddModalValues): NgbModalRef {
        return this.modalService.open(EnrolmentRightAddModalComponent, modalValues, { size: 'sm' });
    }

    openEditInfoDialog(editInfos: EnrolmentRightChangeLogItem[]): void {
        const sortedEditInfos = (editInfos ?? [])
            .filter(item => item?.changeType === 'EDIT')
            .sort((a, b) => (b?.modificationTime ?? '').localeCompare(a?.modificationTime ?? ''));
        if (sortedEditInfos.length > 0) {
            this.changeLogItemsToInfoDialogDescriptions(sortedEditInfos)
                .pipe(this.appErrorHandler.defaultErrorHandler())
                .subscribe((descriptions) => {
                    this.infoDialogService.open({
                        descriptions,
                        title: 'OPENUNIVERSITY.STUDY_RIGHTS.ENROLMENT_RIGHT.EDIT_RATIONALE',
                    });
                });
        }
    }

    openCancellationInfoDialog(cancellationInfo: EnrolmentRightChangeLogItem): void {
        if (cancellationInfo?.changeType === 'CANCEL' || cancellationInfo?.changeType === 'AUTOMATED_CANCEL') {
            this.changeLogItemsToInfoDialogDescriptions([cancellationInfo])
                .pipe(this.appErrorHandler.defaultErrorHandler())
                .subscribe((descriptions) => {
                    this.infoDialogService.open({
                        descriptions,
                        title: 'OPENUNIVERSITY.STUDY_RIGHTS.ENROLMENT_RIGHT.CANCELLATION_RATIONALE',
                    });
                });
        }
    }

    /**
     * Generates HTML snippets that contain the info to display for the change log items in the info dialog.
     * This sucks, but the info modal doesn't support styling the contents in any way, so this is the best we can
     * do without making the info dialog API even more complex (or creating a new, almost identical component).
     */
    private changeLogItemsToInfoDialogDescriptions(items: EnrolmentRightChangeLogItem[]): Observable<string[]> {
        return combineLatest(
            items.map(item => this.isStudentApp() ?
                of(this.parseInfoDialogDescription(item, null)) :
                this.personInfoService.getById(item.changedByPersonId)
                    .pipe(
                        take(1),
                        map(person => this.parseInfoDialogDescription(item, person)),
                    ),
            ),
        );
    }

    private parseInfoDialogDescription(item: EnrolmentRightChangeLogItem, person: PersonInfo): string {
        return `<h4>${dateUtils.formatDateIfValid(item.modificationTime, 'l LT')}${this.addPersonFullName(person)}</h4>\
<span>${item.changeMessage}</span>`;
    }

    private addPersonFullName(person: PersonInfo): string {
        return person ? `, ${this.personNameService.getFullName(person)}` : '';
    }

    private isStudentApp() {
        return this.uiRouterGlobals.current.name.startsWith('student.');
    }
}
