import angular from 'angular';
import _ from 'lodash';
import { educationModelModule } from '../model/education.model';
import { jsDataCacheHelperModule } from './jsDataCacheHelper.service';
export const commonEducationServiceModule = 'sis-components.service.educationService';
(function () {
  educationService.$inject = ["$http", "educationJSDataModel", "jsDataCacheHelper", "EDUCATION_URL", "EDUCATION_TYPE"];
  angular.module(commonEducationServiceModule, [educationModelModule, jsDataCacheHelperModule]).factory('commonEducationService', educationService);

  /**
   * @ngInject
   */
  function educationService($http, educationJSDataModel, jsDataCacheHelper, EDUCATION_URL, EDUCATION_TYPE) {
    function areConnected(rightModule, learningOpportunity) {
      return _.find(learningOpportunity.allowedPaths, allowedPath => {
        if (rightModule.parentModuleGroupId) {
          return rightModule.parentModuleGroupId === allowedPath.educationPhase2GroupId && rightModule.moduleGroupId === allowedPath.educationPhase2ChildGroupId;
        }
        return rightModule.moduleGroupId === allowedPath.educationPhase2GroupId && !allowedPath.educationPhase2ChildGroupId;
      });
    }
    function generateChildOption(studyRight, phase) {
      let childOption;
      if (_.get(studyRight.personalizedSelectionPath, `phase${phase}.childModuleGroupId`)) {
        childOption = {};
        childOption.moduleGroupId = _.get(studyRight.personalizedSelectionPath, `phase${phase}.childModuleGroupId`);
        childOption.acceptanceType = 'ACCEPTED_BY_TEACHER';
      }
      return childOption;
    }
    function generateMainOption(studyRight, phase, childOption) {
      const option = {};
      option.moduleGroupId = _.get(studyRight.personalizedSelectionPath, `phase${phase}.moduleGroupId`);
      option.acceptanceType = 'ACCEPTED_BY_TEACHER';
      if (childOption) {
        option.childNaming = _.get(studyRight.personalizedSelectionPath, `phase${phase}.childNamingUrn`);
        option.childOptions = [childOption];
      }
      return option;
    }
    const api = {
      findById(educationId, bypassCache) {
        const options = {
          bypassCache: !!bypassCache
        };
        return educationJSDataModel.find(educationId, options);
      },
      findAll(params, bypassCache) {
        const endpoint = _.isNil(params.documentState) ? EDUCATION_URL.ENDPOINT_URL : EDUCATION_URL.AUTHENTICATED_ENDPOINT_URL;
        const options = {
          endpoint,
          bypassCache: !!bypassCache
        };
        return educationJSDataModel.findAll(params, options);
      },
      findAllFromEducationSearch(params) {
        return $http.get(EDUCATION_URL.AUTHENTICATED_SEARCH_ENDPOINT_URL, {
          params
        });
      },
      findByIds(educationIds) {
        return jsDataCacheHelper.findByIds(educationJSDataModel, educationIds);
      },
      get(id) {
        return educationJSDataModel.get(id);
      },
      getModuleGroupIdsFromEducationStructure(education) {
        return _.chain(['structure.phase1.options', 'structure.phase2.options']).flatMap(prop => _.get(education, prop, [])).flatMap(option => _.concat(_.map(option.childOptions, 'moduleGroupId'), option.moduleGroupId)).compact().uniq().value();
      },
      getPreviewModel(education, studyRight) {
        const leftPhaseObject = {};
        if (education.structure.phase1) {
          if (_.get(studyRight, 'personalizedSelectionPath.phase1')) {
            let index = 1;
            const childOption = generateChildOption(studyRight, 1);
            const option = generateMainOption(studyRight, 1, childOption);
            leftPhaseObject[index] = api.createPreviewModelObject(option, studyRight, index, 1);
            index++;
            if (childOption) {
              leftPhaseObject[index] = api.createPreviewModelChildObject(childOption, option, studyRight, index, 1);
            }
          } else {
            let index = 1;
            _.forEach(education.structure.phase1.options, option => {
              leftPhaseObject[index] = api.createPreviewModelObject(option, studyRight, index, 1);
              index++;
              _.forEach(option.childOptions, childOption => {
                leftPhaseObject[index] = api.createPreviewModelChildObject(childOption, option, studyRight, index, 1);
                index++;
              });
            });
          }
        }
        const rightPhaseObject = {};
        if (education.structure.phase2) {
          if (_.get(studyRight, 'personalizedSelectionPath.phase2')) {
            let index = 1;
            const childOption = generateChildOption(studyRight, 2);
            const option = generateMainOption(studyRight, 2, childOption);
            rightPhaseObject[index] = api.createPreviewModelObject(option, studyRight, index, 2);
            index++;
            if (childOption) {
              rightPhaseObject[index] = api.createPreviewModelChildObject(childOption, option, studyRight, index, 2);
            }
          } else {
            let index = 1;
            _.forEach(education.structure.phase2.options, option => {
              rightPhaseObject[index] = api.createPreviewModelObject(option, studyRight, index, 2);
              index++;
              _.forEach(option.childOptions, childOption => {
                rightPhaseObject[index] = api.createPreviewModelChildObject(childOption, option, studyRight, index, 2);
                index++;
              });
            });
          }
        }
        return {
          left: leftPhaseObject,
          right: rightPhaseObject
        };
      },
      /**
       * @deprecated Use LearningOpportunityAllowedPathService instead.
       */
      isModuleInLearningOpportunityAllowedPaths(allowedPaths, moduleGroupId, educationPathKey) {
        return _.some(allowedPaths, allowedPath => _.get(allowedPath, educationPathKey) === moduleGroupId);
      },
      createPreviewModelObject(option, studyRight, index, phaseIndex) {
        const educationPathKey = `educationPhase${phaseIndex}GroupId`;
        const object = {
          index,
          size: 'large',
          educationPathKey,
          moduleGroupId: option.moduleGroupId,
          color: (index - 1) % 11 + 1,
          matchesAcceptedSelectionPath: _.get(studyRight.acceptedSelectionPath, educationPathKey) === option.moduleGroupId,
          acceptanceType: option.acceptanceType
        };
        if (!_.isEmpty(option.childOptions)) {
          object.childOptionPathKey = `educationPhase${phaseIndex}ChildGroupId`;
        }
        return object;
      },
      createPreviewModelChildObject(childOption, option, studyRight, index, phaseIndex) {
        const educationPathKey = `educationPhase${phaseIndex}ChildGroupId`;
        return {
          index,
          size: 'small',
          educationPathKey,
          parentModuleGroupId: option.moduleGroupId,
          moduleGroupId: childOption.moduleGroupId,
          color: (index - 1) % 11 + 1,
          childOptionNaming: option.childNaming,
          matchesAcceptedSelectionPath: api.childOptionMatchesAcceptedSelectionPath(childOption, option, studyRight, phaseIndex),
          acceptanceType: childOption.acceptanceType
        };
      },
      childOptionMatchesAcceptedSelectionPath(childOption, option, studyRight, phaseIndex) {
        const parentOptionPathKey = `educationPhase${phaseIndex}GroupId`;
        const childOptionPathKey = `educationPhase${phaseIndex}ChildGroupId`;
        const parentOptionMatches = _.get(studyRight.acceptedSelectionPath, parentOptionPathKey) === _.get(option, 'moduleGroupId');
        const childOptionMatches = _.get(studyRight.acceptedSelectionPath, childOptionPathKey) === _.get(childOption, 'moduleGroupId');
        return parentOptionMatches && childOptionMatches;
      },
      /**
       * @deprecated Use ConnectionModelService.getConnectionModel.
       */
      getConnectionModel(previewModel, education, studyRight) {
        if (_.get(education.structure, 'phase2')) {
          return api.getConnectionModelForTwoPhaseEducation(previewModel, education, studyRight);
        }
        return api.getConnectionModelForSinglePhaseEducation(previewModel, education, studyRight);
      },
      getConnectionModelForTwoPhaseEducation(previewModel, education, studyRight) {
        const connectionModel = {};
        const learningOpportunity = _.find(education.structure.learningOpportunities, {
          localId: studyRight.learningOpportunityId
        });
        const personalizedSelectionPath = _.get(studyRight, 'personalizedSelectionPath');
        if (learningOpportunity || !!_.get(personalizedSelectionPath, 'phase1')) {
          _.forEach(previewModel.left, (leftModule, leftIndex) => {
            _.forEach(previewModel.right, (rightModule, rightIndex) => {
              if (api.areConnected(leftModule, rightModule, learningOpportunity, personalizedSelectionPath)) {
                if (!_.has(connectionModel, leftIndex)) {
                  connectionModel[leftIndex] = {};
                }
                if (api.isMatchingSelectionPath(studyRight.acceptedSelectionPath, api.getEducationPath(leftModule, rightModule))) {
                  connectionModel[leftIndex][rightIndex] = 'active';
                } else {
                  connectionModel[leftIndex][rightIndex] = 'inactive';
                }
              }
            });
          });
        }
        return connectionModel;
      },
      getConnectionModelForSinglePhaseEducation(previewModel, education, studyRight) {
        const connectionModel = {};
        const learningOpportunity = _.find(education.structure.learningOpportunities, {
          localId: studyRight.learningOpportunityId
        });
        const personalizedSelectionPath = _.get(studyRight, 'personalizedSelectionPath');
        if (learningOpportunity || personalizedSelectionPath) {
          _.forEach(previewModel.left, (leftModule, leftIndex) => {
            if (api.areConnected(leftModule, null, learningOpportunity, personalizedSelectionPath) && api.isMatchingSelectionPath(studyRight.acceptedSelectionPath, api.getEducationPath(leftModule, null))) {
              connectionModel[leftIndex] = {};
            }
          });
        }
        return connectionModel;
      },
      areConnected(leftModule, rightModule, learningOpportunity, personalizedSelectionPath) {
        if (personalizedSelectionPath) {
          const personalizedPhase1Module = _.get(personalizedSelectionPath, 'phase1.moduleGroupId');
          const personalizedPhase1ChildModule = _.get(personalizedSelectionPath, 'phase1.childModuleGroupId');
          const personalizedPhase2Module = _.get(personalizedSelectionPath, 'phase2.moduleGroupId');
          const personalizedPhase2ChildModule = _.get(personalizedSelectionPath, 'phase2.childModuleGroupId');
          if (rightModule && !_.isNil(personalizedPhase1Module)) {
            // phase1 without child or phase2
            if (personalizedPhase1Module === leftModule.moduleGroupId && _.isNil(personalizedPhase1ChildModule) && _.isNil(personalizedPhase2Module)) {
              return areConnected(rightModule, learningOpportunity);
            }
            // phase1 with child without phase2
            if (personalizedPhase1Module === leftModule.parentModuleGroupId && !_.isNil(personalizedPhase1ChildModule) && personalizedPhase1ChildModule === leftModule.moduleGroupId && _.isNil(personalizedPhase2Module)) {
              return areConnected(rightModule, learningOpportunity);
            }
            // phase1 without child, phase2 without child
            if (personalizedPhase1Module === leftModule.moduleGroupId && _.isNil(personalizedPhase1ChildModule) && !_.isNil(personalizedPhase2Module) && personalizedPhase2Module === rightModule.moduleGroupId && _.isNil(personalizedPhase2ChildModule)) {
              return true;
            }
            // phase1 without child, phase2 with child
            if (personalizedPhase1Module === leftModule.moduleGroupId && _.isNil(personalizedPhase1ChildModule) && personalizedPhase2Module === rightModule.parentModuleGroupId && !_.isNil(personalizedPhase2ChildModule) && personalizedPhase2ChildModule === rightModule.moduleGroupId) {
              return true;
            }
            // phase1 with child, phase2 without child
            if (personalizedPhase1Module === leftModule.parentModuleGroupId && !_.isNil(personalizedPhase1ChildModule) && personalizedPhase1ChildModule === leftModule.moduleGroupId && !_.isNil(personalizedPhase2Module) && personalizedPhase2Module === rightModule.moduleGroupId && _.isNil(personalizedPhase2ChildModule)) {
              return true;
            }
            // phase1 with child, phase2 with child
            if (personalizedPhase1Module === leftModule.parentModuleGroupId && !_.isNil(personalizedPhase1ChildModule) && personalizedPhase1ChildModule === leftModule.moduleGroupId && !_.isNil(personalizedPhase2Module) && personalizedPhase2Module === rightModule.parentModuleGroupId && !_.isNil(personalizedPhase2ChildModule) && personalizedPhase2ChildModule === rightModule.moduleGroupId) {
              return true;
            }
          } else if (!_.isNil(personalizedPhase1Module)) {
            // phase1 without child
            if (personalizedPhase1Module === leftModule.moduleGroupId && !leftModule.childOptionPathKey) {
              return true;
            }
            // phase1 with child
            if (personalizedPhase1ChildModule && personalizedPhase1ChildModule === leftModule.moduleGroupId) {
              return true;
            }
          }
        }
        const educationPath = api.getEducationPath(leftModule, rightModule);
        const connection = _.find(learningOpportunity.allowedPaths, allowedPath => _.isEqual(allowedPath, educationPath));
        return !!connection;
      },
      getEducationPath(leftModule, rightModule) {
        const educationPath = {};
        if (leftModule.parentModuleGroupId) {
          educationPath.educationPhase1GroupId = leftModule.parentModuleGroupId;
          educationPath.educationPhase1ChildGroupId = leftModule.moduleGroupId;
        } else {
          educationPath.educationPhase1GroupId = leftModule.moduleGroupId;
        }
        if (rightModule) {
          if (rightModule.parentModuleGroupId) {
            educationPath.educationPhase2GroupId = rightModule.parentModuleGroupId;
            educationPath.educationPhase2ChildGroupId = rightModule.moduleGroupId;
          } else {
            educationPath.educationPhase2GroupId = rightModule.moduleGroupId;
          }
        }
        return educationPath;
      },
      isMatchingSelectionPath(acceptedSelectionPath, educationSelectionPath) {
        let isMatchingSelectionPath = true;
        const keys = _.keys(acceptedSelectionPath);
        _.forEach(keys, key => {
          if (_.get(acceptedSelectionPath, key) !== _.get(educationSelectionPath, key)) {
            isMatchingSelectionPath = false;
            return false;
          }
        });
        return isMatchingSelectionPath;
      },
      isDegreeEducation(education) {
        return _.startsWith(_.get(education, 'educationType'), 'urn:code:education-type:degree-education');
      },
      isAgreementStudiesEducation(education) {
        return _.startsWith(_.get(education, 'educationType'), 'urn:code:education-type:non-degree-education:agreement-studies');
      },
      is2PhasedEducation(education) {
        if (!education) {
          return false;
        }
        return _.get(education, 'educationType', '').trim() === EDUCATION_TYPE.BACHELORS_AND_MASTERS;
      }
    };
    return api;
  }
})();