import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'sis-add-button',
    templateUrl: './add-button.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class AddButtonComponent {

    @Input() id?: string;
    @Input() dataCy?: string;
    @Input() label: string;
    @Input() disabled = false;
    @Input() dashBox = false;
    @Output() clicked = new EventEmitter<void>();

}
