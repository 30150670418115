import { Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

/**
 * @deprecated
 */
// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'education-structure-preview' })
export class EducationStructurePreviewDirective extends UpgradeComponent {

    @Input() educationModel: any;
    @Input() connectionModel: any;

    constructor(elementRef: ElementRef<any>, injector: Injector) {
        super('educationStructurePreview', elementRef, injector);
    }
}
