import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthTokenInterceptorService } from '../http-interceptors/auth-token-interceptor.service';

@Injectable({ providedIn: 'root' })
export class AuthRetryInterceptor implements HttpInterceptor {

    constructor(private authTokenService: AuthTokenInterceptorService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.authTokenService.handleResponse(req, next);
    }
}
