import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { numberUtils } from 'common-typescript';
import { ModalService } from 'sis-common/modal/modal.service';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

export interface FileUploadInfoModalValues {
    title: string;
    confirmText?: string;
    invalidFileFormat?: File[];
    invalidFileSize?: File[];
    filesWithSameFileName?: File[];
    filesWithInvalidFileName?: File[];
    acceptedFiles?: File[];
    selectedFiles?: File[];
    maxFileSizeString?: string;
    filesWithVirus?: [];
    editPossible?: boolean;
}
@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-file-upload-info-modal',
    templateUrl: './file-upload-info-modal.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class FileUploadInfoModalComponent {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.fileUploadInfoModal.downgraded',
        directiveName: 'sisFileUploadInfoModal',
    };

    constructor(@Inject(ModalService.injectionToken) public values: FileUploadInfoModalValues,
                public modalInstance: NgbActiveModal) {
    }

    virusModal = this.values.filesWithVirus;
    otherAcceptedFiles = this.virusModal ? false :
        (this.values.selectedFiles.length - this.values.invalidFileSize.length - this.values.invalidFileFormat.length - this.values.filesWithSameFileName.length - this.values.filesWithInvalidFileName.length) > 0;

    readableFileSizeString(number: any) {
        return numberUtils.readableFileSizeString(number);
    }
}
