<ng-container *transloco="let t">
  <sis-common-attainment-details [attainment]="attainment" [secondaryAttainments]="secondaryAttainments">
    <ng-template *ngIf="expirationImminent" #expirationNotification>
      <sis-attainment-expiration-notification [attainment]="attainment"></sis-attainment-expiration-notification>
    </ng-template>
    <ng-template #thesisInformation>
      <sis-thesis-details [attainment]="attainment"></sis-thesis-details>
    </ng-template>
    <ng-template *ngIf="attainment?.creditTransferInfo" #creditTransferInformation>
      <sis-attainment-credit-transfer-info-details [creditTransferInfo]="attainment.creditTransferInfo">
      </sis-attainment-credit-transfer-info-details>
    </ng-template>
    <ng-template #registrationDetails>
      <div class="form-group-mimic" role="listitem">
        <dt>{{ t('ATTAINMENTS.ATTAINMENT_DETAILS.REGISTRATION_DATE') }}</dt>
        <dd>{{ attainment.registrationDate | localDateFormat }}</dd>
      </div>
    </ng-template>

    <ng-template #curriculumPeriods>
      <div sis-curriculum-periods-details class="form-group-mimic" role="listitem" [attainment]="attainment"></div>
    </ng-template>

    <ng-template #rdiCredits>
      <div *ngIf="rdiCreditsEnabled === 'ENABLED' && attainment.rdiCredits" class="form-group-mimic" role="listitem">
        <dt>{{ t('ATTAINMENTS.ATTAINMENT_DETAILS.RDI_CREDITS') }}</dt>
        <dd>
          {{ attainment.rdiCredits }}
        </dd>
      </div>
      <div *ngIf="rdiCreditsEnabled === 'ENABLED' && !attainment.rdiCredits" class="form-group-mimic" role="listitem">
        <dt>{{ t('ATTAINMENTS.ATTAINMENT_DETAILS.RDI_CREDITS') }}</dt>
        <dd> - </dd>
      </div>
      <div *ngIf="rdiCreditsEnabled === 'DISABLED' && attainment.rdiCredits" class="form-group-mimic" role="listitem">
        <dt>{{ t('ATTAINMENTS.ATTAINMENT_DETAILS.RDI_CREDITS') }}</dt>
        <dd>
          {{ attainment.rdiCredits }}
        </dd>
      </div>
    </ng-template>
  </sis-common-attainment-details>
  <!-- Hidden for CAPPUCCINO 5.0 as backend is not yet ready
  <div class="grade-raise-info" *ngIf="gradeRaiseEnabled">
    <fudis-heading [level]="3">{{ t('ATTAINMENTS.ATTAINMENT_DETAILS.GRADE_RAISE_ATTEMPT_TITLE') }}</fudis-heading>
    <p *ngIf="completionMethodsAvailable && !gradeRaiseAttempt" class="sis-mt-sm sis-mb-sm">{{ t('ATTAINMENTS.ATTAINMENT_DETAILS.GRADE_RAISE_ATTEMPT_HELP_TEXT') }}</p>
    <ng-container *ngIf="gradeRaiseCourseUnitVersion$ | async as data">
      <p *ngIf="completionMethodsAvailable && gradeRaiseAttempt" class="sis-mt-sm sis-mb-sm">{{ t('ATTAINMENTS.ATTAINMENT_DETAILS.GRADE_RAISE_ATTEMPT_ONGOING_HELP_TEXT', { completionMethodIndex: getCompletionMethodIndexForGradeRaise(data) }) }}</p>
    </ng-container>
    <div *ngIf="!completionMethodsAvailable" class="sis-mb-sm">
      <sis-contextual-notification variant="warning">{{ t('ATTAINMENTS.ATTAINMENT_DETAILS.GRADE_RAISE_WARNING_TEXT') }}</sis-contextual-notification>
    </div>
    <fudis-button *ngIf="completionMethodsAvailable" [size]="'small'" [variant]="'tertiary'" [label]="t(gradeRaiseButtonTranslationKey())" (handleClick)="this.modalRef.close({ openSelectCompletionMethodModal: true })"></fudis-button>
  </div>
  -->
</ng-container>
