import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { Enrolment } from 'common-typescript/types';

@Component({
    selector: 'app-enrolment-header-info',
    templateUrl: './enrolment-header-info.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnrolmentHeaderInfoComponent {

    @Input({ required: true }) enrolment: Enrolment;

}
