// eslint-disable-next-line import/order
import { initAppModule } from './app/angularApp'; // eslint-disable-next-line import/order
import { enableProdMode, StaticProvider } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { downgradeModule } from '@angular/upgrade/static';
import { enableAkitaProdMode } from '@datorama/akita';
import * as angular from 'angular';
import { initAngular, initUIRouter } from 'sis-common/angular-hybrid/hybrid';

import './student-import'; // eslint-disable-next-line import/order
import { downgradedComponents, downgradedServices } from './app/ajs-downgraded-modules';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
    enableAkitaProdMode();
}

// Note: the argument to bootstrapModule() must be a direct reference to AppModule (and not a variable),
// so that the Ahead-of-Time compiler can find it with static analysis
const bootstrapFn = (extraProviders: StaticProvider[]) => platformBrowserDynamic(extraProviders)
    .bootstrapModule(AppModule)
    .then(initUIRouter);
const downgradedModuleName = downgradeModule(bootstrapFn);

initAngular(downgradedComponents, downgradedServices);
const appModule = initAppModule([downgradedModuleName]);
angular.bootstrap(document, [appModule.name]);
