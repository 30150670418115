<ng-container *transloco="let t">
  <sis-description-list *ngIf="showClassificationInfo; else noInfo">
    <ng-template *ngIf="_module?.degreeLanguageUrns?.length > 0" #listItem>
      <dt>{{t('MODULE_INFO.TITLE_DEGREE_LANGUAGES')}}</dt>
      <ng-container *ngFor="let degreeLanguageUrn of _module.degreeLanguageUrns">
        <dd>{{degreeLanguageUrn | codeLocalizedName | async}}</dd>
      </ng-container>
    </ng-template>
    <ng-template *ngIf="_module?.degreeTitleUrns?.length > 0" #listItem>
      <dt>{{t('MODULE_INFO.TITLE_DEGREE_TITLES')}}</dt>
      <ng-container *ngFor="let degreeTitleUrn of _module.degreeTitleUrns">
        <dd>{{degreeTitleUrn | codeLocalizedName | async}}</dd>
      </ng-container>
    </ng-template>
    <ng-template *ngIf="_module?.possibleAttainmentLanguages?.length > 0" #listItem>
      <dt>{{t('MODULE_INFO.TITLE_STUDY_MODULE_LANGUAGES')}}</dt>
      <ng-container *ngFor="let attainmentLanguageUrn of _module.possibleAttainmentLanguages">
        <dd>{{attainmentLanguageUrn | codeLocalizedName | async}}</dd>
      </ng-container>
    </ng-template>
    <ng-template *ngIf="_module?.studyFields?.length > 0" #listItem>
      <dt>{{t('MODULE_INFO.STUDY_FIELDS')}}</dt>
      <ng-container *ngFor="let studyFieldUrn of _module.studyFields">
        <dd>{{studyFieldUrn | codeLocalizedName | async}}</dd>
      </ng-container>
    </ng-template>
  </sis-description-list>
  <ng-template #noInfo>
    <p>{{t('MODULE_INFO.NO_INFORMATION_GIVEN')}}</p>
  </ng-template>
</ng-container>

