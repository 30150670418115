import { computed, Injectable, Signal, signal } from '@angular/core';
import { LocalId } from 'common-typescript/types';

export interface RuleErrorState {
    /**
     * LocalId of the rule
     */
    ruleLocalId: LocalId;
    /**
     * Id for moving the focus to the rule in the UI
     */
    ruleFocusId: string;
    /**
     * Name that should be displayed in the UI for this rule
     */
    ruleDisplayName: string;
    errors: RuleError[];
}
export interface RuleError {
    errorId: string;
    errorType: RuleErrorType;
}

type RuleErrorType = 'LESS_SELECTIONS_REQUIRED';
/**
 * Service that manages what rule errors are shown in the error summary and
 * as notifications under the rule groups.
 */
@Injectable()
export class RuleErrorStateService {

    private readonly state = {
        ruleErrorStates$: signal<RuleErrorState[]>([]),
        shownErrorStates$: signal<RuleErrorState[]>([]),
    } as const;

    /**
     * All error states that are updated in real time
     */
    public readonly ruleErrorStates$: Signal<RuleErrorState[]> = this.state.ruleErrorStates$.asReadonly();
    /**
     * Error states that should be shown. Synced to ruleErrorStates$ when showCurrentErrorStates is called.
     * If a shown error is fixed it will be removed immediately from this list in updateRuleErrorState.
     */
    public readonly shownErrorStates$: Signal<RuleErrorState[]> = this.state.shownErrorStates$.asReadonly();

    /**
     * Are there any errors in ruleErrorStates
     */
    public readonly hasAnyErrors$: Signal<boolean> = computed(() => this.ruleErrorStates$().some((state) => state.errors?.length > 0));
    /**
     * Are there any errors in shownErrorStates
     */
    public readonly hasShownErrors$: Signal<boolean> = computed(() => this.shownErrorStates$().some((state) => state.errors?.length > 0));

    /**
     * Updates or adds the rule error state to the list of rule error states
     *
     * @param ruleErrorState Rule error state to update or add
     */
    public updateRuleErrorState(ruleErrorState: RuleErrorState): void {
        this.state.ruleErrorStates$.update((ruleErrorStates) => {
            const index = ruleErrorStates.findIndex((state) =>
                state.ruleLocalId === ruleErrorState.ruleLocalId);
            if (index === -1) {
                return [...ruleErrorStates, ruleErrorState];
            }
            const newErrorStates = [...ruleErrorStates.slice(0, index),
                ruleErrorState,
                ...ruleErrorStates.slice(index + 1)];
            this.removeErrorsFromShownState(ruleErrorState);
            return newErrorStates;
        });
    }

    /**
     * Removes shown errors if they are not present in the new rule error state.
     * @param newRuleErrorState the new rule error state
     * @private
     */
    private removeErrorsFromShownState(newRuleErrorState: RuleErrorState): void {
        const shownState = this.shownErrorStates$().find((state) => state.ruleLocalId === newRuleErrorState.ruleLocalId);
        if (shownState) {
            const removeRemovedRulesFromShown = shownState.errors.filter((error) => newRuleErrorState.errors.some((newError) => newError.errorId === error.errorId));
            this.state.shownErrorStates$.update((shownErrorStates) => {
                const index = shownErrorStates.findIndex((state) => state.ruleLocalId === newRuleErrorState.ruleLocalId);
                return [...shownErrorStates.slice(0, index),
                    { ...shownState, errors: removeRemovedRulesFromShown },
                    ...shownErrorStates.slice(index + 1)];
            });
        }
    }

    /**
     * Get errors of a specific rule by its localId as a signal.
     *
     * @param ruleLocalId the localId of the rule
     */
    getRuleErrors(ruleLocalId: LocalId): Signal<RuleError[]> {
        return computed(() => {
            const ruleErrorState = this.state.shownErrorStates$().find((state) => state.ruleLocalId === ruleLocalId);
            return ruleErrorState?.errors ?? [];
        });
    }

    /**
     * Copy current error states to shown error states
     */
    showCurrentErrorStates(): void {
        this.state.shownErrorStates$.set(this.state.ruleErrorStates$());
    }
}
