'use strict';

(function () {
  translateRange.$inject = ["Range", "RangeType"];
  angular.module('sis-components.translateRange', ['student.shared']).factory('translateRange', translateRange);
  function translateRange(Range, RangeType) {
    return function (credits) {
      if (!credits) {
        return {};
      }
      var range = Range.cast(credits);
      var min = '' + range.min || '';
      var max = '' + range.max || '';
      var rangeType = range.getType();
      var t = RangeType;
      var returnObj = {};
      returnObj[t.ONE] = {
        t: 'SIS_COMPONENTS.PLAN.RANGE.ONE',
        values: {
          credits: min
        }
      };
      returnObj[t.EXACTLY] = {
        t: 'SIS_COMPONENTS.PLAN.RANGE.EXACTLY',
        values: {
          credits: min
        }
      };
      returnObj[t.MAX] = {
        t: 'SIS_COMPONENTS.PLAN.RANGE.MAX',
        values: {
          credits: max
        }
      };
      returnObj[t.MIN] = {
        t: 'SIS_COMPONENTS.PLAN.RANGE.MIN',
        values: {
          credits: min
        }
      };
      returnObj[t.BETWEEN] = {
        t: 'SIS_COMPONENTS.PLAN.RANGE.BETWEEN',
        values: {
          min: min,
          max: max
        }
      };
      return returnObj[rangeType] || {};
    };
  }
})();