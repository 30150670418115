'use strict';

(function () {
  initEducationResponsibilityInfoTypeModel.$inject = ["educationResponsibilityInfoTypeJSDataModel", "$http", "$log"];
  educationResponsibilityInfoTypeModel.$inject = ["DS"];
  angular.module('sis-components.model.educationResponsibilityInfoType', ['js-data']).factory('educationResponsibilityInfoTypeJSDataModel', educationResponsibilityInfoTypeModel).run(initEducationResponsibilityInfoTypeModel);
  var ENDPOINT = '/kori/api/cached/codebooks/urn:code:education-responsibility-info-type';

  /**
   * @ngInject
   */
  function educationResponsibilityInfoTypeModel(DS) {
    return DS.defineResource({
      idAttribute: 'urn',
      name: 'educationResponsibilityInfoType',
      endpoint: ENDPOINT,
      watchChanges: false
    });
  }

  /**
   * @ngInject
   */
  function initEducationResponsibilityInfoTypeModel(educationResponsibilityInfoTypeJSDataModel, $http, $log) {
    $http.get(ENDPOINT).then(function (response) {
      educationResponsibilityInfoTypeJSDataModel.inject(_.values(response.data));
    }).catch(function (error) {
      $log.error(error);
    });
  }
})();