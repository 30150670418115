import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { StateService } from '@uirouter/core';
import { dateUtils } from 'common-typescript';
import { CourseUnit, OpenUniversityProduct, OpenUniversityProductTeaching, OtmId } from 'common-typescript/types';
import _ from 'lodash';
import moment from 'moment';
import { combineLatest, Observable, of, Subject, tap } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { Breakpoint, BreakpointService } from 'sis-components/service/breakpoint.service';
import { CourseUnitRealisationEntityService } from 'sis-components/service/course-unit-realisation-entity.service';
import { EnrolmentCalculationConfigEntityService } from 'sis-components/service/enrolment-calculation-config-entity.service';
import { OpenUniversityCartCustomerService } from 'sis-components/service/open-university-cart-customer.service';
import { OpenUniversityProductEntityService } from 'sis-components/service/open-university-product-entity.service';
import { UniversityService } from 'sis-components/service/university.service';

import { AggregateProductInfo, ProductTeaching } from '../types';

enum TAB { CURRENT_PRODUCTS, FUTURE_PRODUCTS }

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'app-open-university-offering',
    templateUrl: './open-university-offering.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class OpenUniversityOfferingComponent implements OnInit, OnDestroy {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'student.common.components.courseUnitInfoModal.openUniversityOffering.downgraded',
        directiveName: 'appOpenUniversityOffering',
    };

    @Input() set courseUnit(courseUnit: CourseUnit) {
        this._courseUnit = courseUnit;
        this.hasOpenUniversityOffering = courseUnit?.completionMethods?.some(cm => cm?.studyType === 'OPEN_UNIVERSITY_STUDIES');
        this.currentProducts = [];
        this.futureProducts = [];
        this.selectedProduct = null;
        if (this.hasOpenUniversityOffering) {
            this.fetchProductsAndCurs(courseUnit.id);
        }
    }

    get courseUnit() {
        return this._courseUnit;
    }

    /** Id of the product which should be automatically selected in the carousel when the view is initialized */
    @Input() productId?: OtmId;

    /**
     * If provided, the component will start a state transition to the given state name when a product is selected,
     * with the id of the selected product as the `productId` parameter.
     */
    @Input() productSelectionTargetState?: string;

    _selectedProduct: AggregateProductInfo;

    set selectedProduct(selected: AggregateProductInfo) {
        this._selectedProduct = selected;
        this.ref.detectChanges();
    }

    get selectedProduct(): AggregateProductInfo {
        return this._selectedProduct;
    }

    readonly TAB = TAB;

    currentProducts: AggregateProductInfo[] = [];
    futureProducts: AggregateProductInfo[] = [];
    hasOpenUniversityOffering: boolean;
    currentTabIndex = TAB.CURRENT_PRODUCTS;
    isMobileView: boolean;
    isCurrentOrgIdDifferentThanAllProductOrgIds: boolean;
    allProducts: OpenUniversityProduct[];
    currentOrgId: string;

    private _courseUnit: CourseUnit;

    private readonly destroyed$ = new Subject<void>();

    constructor(
        private breakpointService: BreakpointService,
        private cartService: OpenUniversityCartCustomerService,
        private courseUnitRealisationService: CourseUnitRealisationEntityService,
        private enrolmentCalculationConfigService: EnrolmentCalculationConfigEntityService,
        private localeService: LocaleService,
        private openUniversityProductService: OpenUniversityProductEntityService,
        private ref: ChangeDetectorRef,
        private state: StateService,
        private appErrorHandler: AppErrorHandler,
        private universityService: UniversityService,
    ) {}

    ngOnInit(): void {
        this.breakpointService.breakpoint$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(breakpoint => this.isMobileView = (breakpoint < Breakpoint.SM));
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
    }

    onTabChange(tabIndex: TAB): void {
        if (tabIndex !== this.currentTabIndex) {
            this.currentTabIndex = tabIndex;
            this.onProductSelect(null);
            this.ref.detectChanges();
        }
    }

    onProductSelect(product: AggregateProductInfo): void {
        if (product?.id !== this.selectedProduct?.id) {
            this.selectedProduct = product;

            if (this.productSelectionTargetState) {
                // Update the router state, which also updates the current URL. This causes the current product selection to
                // survive page refreshes, and makes it possible for a user to share a URL to a specific product.
                this.state.go(this.productSelectionTargetState, { productId: product?.id ?? null });
            }
        }
    }

    private fetchProductsAndCurs(courseUnitId: OtmId): void {
        this.currentOrgId = this.universityService.getCurrentUniversityOrgId();
        this.openUniversityProductService.getByCourseUnitId(courseUnitId, 'ACTIVE', 'PUBLISHED', 'ONGOING_AND_FUTURE')
            .pipe(
                tap(products => this.allProducts = products),
                map(products => this.groupAndSortProducts(products)),
                switchMap(groupedProducts => combineLatest([
                    of(groupedProducts),
                    this.fetchCourseUnitRealisations(groupedProducts),
                    this.cartService.getProductsInCurrentCart(),
                    this.enrolmentCalculationConfigService.getOpenUniversityProductSeats(groupedProducts.current.map(({ id }) => id)),
                ])),
                takeUntil(this.destroyed$),
                this.appErrorHandler.defaultErrorHandler(),
            )
            .subscribe({
                next: ([groupedProducts, allTeaching, productsInCart, currentProductSeats]) => {
                    this.currentProducts = groupedProducts.current.map(product => ({
                        ...product,
                        isOnSale: true,
                        isInCart: productsInCart.some(({ id }) => id === product.id),
                        isFull: (currentProductSeats?.find(({ productId }) => productId === product.id)?.availableSeats === 0) ?? false,
                        isCurrentOrgIdDifferentThanProductOrgId: this.isCurrentOrganisationDifferentThanProductOrganisation(product),
                        currentOrgId: this.currentOrgId,
                        teachingOnSale: this.getGroupedTeachingForProduct(product.id, allTeaching),
                        curAvailableSeats: (currentProductSeats?.find(({ productId }) => productId === product.id)?.curSeats),
                    }));
                    this.futureProducts = groupedProducts.future.map(product => ({
                        ...product,
                        isOnSale: false,
                        isInCart: false,
                        isFull: false,
                        isCurrentOrgIdDifferentThanProductOrgId: this.isCurrentOrganisationDifferentThanProductOrganisation(product),
                        currentOrgId: this.currentOrgId,
                        teachingOnSale: this.getGroupedTeachingForProduct(product.id, allTeaching),
                        curAvailableSeats: [],
                    }));

                    if (this.productId) {
                        this.handleInitialProductSelection(this.productId);
                        // The initial product selection should only be performed once
                        delete this.productId;
                    } else if (this.selectedProduct) {
                        // Make sure the status flags in the selected product are up-to-date
                        this.selectedProduct = ([...this.currentProducts, ...this.futureProducts]
                            .find(({ id }) => id === this.selectedProduct.id));
                    }
                    this.isCurrentOrgIdDifferentThanAllProductOrgIds = this.checkDiffBetweenCurrentOrgAndProductsOrgs();
                    this.ref.detectChanges();
                },
            });
    }

    private fetchCourseUnitRealisations(
        groupedProducts: { [group: string]: OpenUniversityProduct[] },
    ): Observable<OpenUniversityProductTeaching[]> {
        return this.courseUnitRealisationService.findByOpenUniversityProductIds(
            Object.values(groupedProducts).flat().map(product => product.id),
            {
                documentStates: 'ACTIVE',
                flowStates: 'PUBLISHED',
                activityStatus: 'ONGOING_AND_FUTURE',
                excludeDelayedPublish: true,
                onlyForSalesPeriod: true,
            },
        );
    }

    private groupAndSortProducts(products: OpenUniversityProduct[]) {
        return {
            current: _(products)
                .filter(product => dateUtils.rangeContains(moment(), product.salesPeriod))
                .sortBy([product => product.salesPeriod?.endDateTime, product => this.localeService.localize(product.name)])
                .value(),
            future: _(products)
                .filter(product => dateUtils.isRangeAfter(moment(), product.salesPeriod))
                .sortBy([product => product.salesPeriod?.startDateTime, product => this.localeService.localize(product.name)])
                .value(),
        };
    }

    private handleInitialProductSelection(initialProductId: OtmId): void {
        this.selectedProduct = ([...this.currentProducts, ...this.futureProducts].find(({ id }) => id === initialProductId));
        if (this.selectedProduct) {
            this.currentTabIndex = [this.currentProducts, this.futureProducts]
                .findIndex(productCategory => productCategory.some(p => p.id === initialProductId));
        }
    }

    private getGroupedTeachingForProduct(productId: OtmId, teachingForAllProducts: OpenUniversityProductTeaching[]): ProductTeaching {
        return teachingForAllProducts.filter(item => item.productId === productId)
            .reduce((result, item) => ({ ...result, [item.assessmentItemId]: item.courseUnitRealisations }), {});
    }

    private isCurrentOrganisationDifferentThanProductOrganisation(product: OpenUniversityProduct): boolean {
        return !product?.universityOrgIds.some(prodOrg => prodOrg === this.currentOrgId);
    }

    private checkDiffBetweenCurrentOrgAndProductsOrgs(): boolean {
        if (!this.allProducts || this.allProducts.length === 0) {
            return false;
        }
        return !this.allProducts?.some(product => product.universityOrgIds.find(prodOrg => prodOrg === this.currentOrgId));
    }
}
