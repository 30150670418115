import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { OpenUniversitySettings } from 'common-typescript/types';

@Component({
    selector: 'app-customer-service-contact-link',
    templateUrl: './customer-service-contact-link.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerServiceContactLinkComponent {

    @Input() settings: OpenUniversitySettings;
}
