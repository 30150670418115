import angular from 'angular';
import _ from 'lodash';
import { ValidatablePlan } from 'common-typescript';
import courseUnitInfoModalTpl from './courseUnitInfoModal.tpl.html';
import { CourseUnitInfoData } from './courseUnitInfo.controller';
import { CourseUnitInfoModalComponent } from './courseUnitInfoModal.component.ts';
import { CourseUnitInfoModalController } from './CourseUnitInfoModalController.controller';
(function () {
  courseUnitInfoModalFactory.$inject = ["$rootScope", "modalService", "$controller", "$q", "$log", "$state", "commonPlanService", "commonEnrolmentService", "AuthService", "commonCourseUnitService", "attainmentJSDataModel", "validAttainmentFilterService", "planServiceEventType", "enrolmentServiceEventType", "STUDY_TYPE", "universityService"];
  angular.module('student.common.components.courseUnitInfoModal').factory('courseUnitInfoModal', courseUnitInfoModalFactory).component('courseUnitInfoModal', {
    bindings: {
      dismiss: '&',
      resolve: '<'
    },
    bindToController: true,
    controller: CourseUnitInfoModalController,
    controllerAs: 'ctrl',
    template: courseUnitInfoModalTpl
  }).constant('MODULE_RESPONSIBILITY_URN', 'urn:code:module-responsibility-info-type:administrative-person');
  function courseUnitInfoModalFactory($rootScope, modalService, $controller, $q, $log, $state,
  // NOSONAR
  commonPlanService, commonEnrolmentService, AuthService, commonCourseUnitService, attainmentJSDataModel, validAttainmentFilterService, planServiceEventType, enrolmentServiceEventType, STUDY_TYPE, universityService) {
    let openTab = 'BASIC';
    function openModal(courseUnit, plan, validatablePlan, courseUnitRealisation,
    // NOSONAR
    attainments, openTabTarget, forceEnableCalendarAdd, unplannedCourseUnit, module, color) {
      if (!validatablePlan) {
        $log.info('opening courseUnitInfoModal without selected Plan. Using empty ValidatablePlan!');
        validatablePlan = new ValidatablePlan({
          rootId: 'emptyPlan'
        }, [], {
          id: 'emptyPlan'
        }, [], [], [], []);
      }
      const deferred = $q.defer();
      const data = new CourseUnitInfoData();
      data.validatablePlan = validatablePlan;
      data.plan = plan;
      data.courseUnit = courseUnit;
      data.completionMethods = courseUnit.completionMethods.filter(cm => cm.studyType !== STUDY_TYPE.OPEN_UNIVERSITY_STUDIES);
      data.courseUnitRealisation = courseUnitRealisation;
      data.attainments = attainments;
      data.openTab = openTabTarget;
      data.forceEnableCalendarAdd = forceEnableCalendarAdd;
      data.unplannedCourseUnit = unplannedCourseUnit;
      data.module = module;
      data.color = color;
      data.currentUniversityOrgId = universityService.getCurrentUniversityOrgId();
      modalService.open(CourseUnitInfoModalComponent, {
        data
      }, {
        closeWithOutsideClick: true,
        size: 'xl',
        windowClass: 'brochure-modal'
      }).result.then(() => deferred.resolve(), () => deferred.resolve());
      return deferred.promise;
    }
    function open(courseUnitId, validatablePlan, courseUnitRealisation,
    // NOSONAR
    forceEnableCalendarAdd, unplannedCourseUnit, module, color) {
      const loads = [commonCourseUnitService.findById(courseUnitId, {
        loadRelations: true
      })];
      if (_.has(validatablePlan, 'plan.id')) {
        loads.push(commonPlanService.findById(validatablePlan.plan.id, true));
      } else {
        loads.push($q.when(undefined));
        $log.info('Opening courseUnit info without plan.', 'courseUnitId:', courseUnitId, 'courseUnitRealisation:', courseUnitRealisation, 'openTab:', openTab);
      }
      if (AuthService.loggedIn()) {
        loads.push(attainmentJSDataModel.findAll({
          personId: AuthService.personId()
        }, {
          bypassCache: false
        }).then(attainments => validAttainmentFilterService.getValidAttainments(attainments)));
      } else {
        $q.when([]);
      }
      return $q.all(loads).then(results => {
        const courseUnit = results[0];
        const plan = results[1];
        const attainments = results[2];
        let planIsChanged = false;
        let enrolmentsChanged = false;
        let isCalendarStateChangePriority = false;
        $rootScope.$on(planServiceEventType.planSaved, () => {
          planIsChanged = true;
        });
        $rootScope.$on(enrolmentServiceEventType.updated, () => {
          enrolmentsChanged = true;
        });
        $rootScope.$on('calendarStateChangePriority', () => {
          isCalendarStateChangePriority = true;
        });
        return openModal(courseUnit, plan, validatablePlan, courseUnitRealisation, attainments, openTab, forceEnableCalendarAdd, unplannedCourseUnit, module, color).then(() => {
          if (!isCalendarStateChangePriority && (planIsChanged || enrolmentsChanged)) {
            $log.info('plan or enrolments changed in couseUnit info modal. Reload state', 'planIsChanged:', planIsChanged, 'enrolmentsChanged:', enrolmentsChanged);
            $state.go($state.current, {}, {
              reload: true
            });
            return $q.when(true);
          }
          return $q.when(false);
        });
      });
    }

    // The actual modal API
    return {
      openTab: () => openTab,
      // exposed for tests
      showCourseUnitRealisationForCourseUnit: (courseUnitId, courseUnitRealisation, validatablePlan) => {
        openTab = 'COMPLETION_METHODS';
        // all usages of this method are in calendar and frontpage views - calendar add should be enabled there.
        const forceEnableCalendarAdd = true;
        return open(courseUnitId, validatablePlan, courseUnitRealisation, forceEnableCalendarAdd);
      },
      showCompletionMethodsForCourseUnit: (courseUnitId, validatablePlan, color) => {
        openTab = 'COMPLETION_METHODS';
        return open(courseUnitId, validatablePlan, undefined, undefined, undefined, undefined, color);
      },
      showCompletionMethodsForUnplannedCourseUnit: (courseUnitId, validatablePlan, unplannedCourseUnit, module, unplannedCourseUnitRealisation) => {
        openTab = 'COMPLETION_METHODS';
        return open(courseUnitId, validatablePlan, unplannedCourseUnitRealisation, undefined, unplannedCourseUnit, module);
      },
      showCourseUnit: (courseUnitId, validatablePlan, forceEnableCalendarAdd, color) => {
        openTab = 'BASIC';
        return open(courseUnitId, validatablePlan, undefined, forceEnableCalendarAdd, undefined, undefined, color);
      },
      showSubstitutions: (courseUnitId, validatablePlan) => {
        openTab = 'SUBSTITUTIONS';
        return open(courseUnitId, validatablePlan);
      }
    };
  }
})();