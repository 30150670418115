import { dateUtils } from 'common-typescript';
import { UuidService } from 'sis-common/uuid/uuid.service.ts';
import { courseUnitRealisationServiceModule } from 'sis-components/service/courseUnitRealisation.service';
(function () {
  timingService.$inject = ["$q", "studyPeriodService", "universityService", "Range", "timeLineNoteService", "timingHelper", "timelineConfig", "timeLineCourseUnitService", "commonCourseUnitRealisationService", "uuidService", "commonCurriculumPeriodService"];
  angular.module('student.timing.timingService', ['sis-common.organisationSelector.organisationSelectorService', 'sis-common.university', UuidService.downgrade.moduleName, 'sis-components.service.studyRightService', 'student.shared', 'student.common.service.studyPeriodService', courseUnitRealisationServiceModule, 'student.common.utils.timingHelper', 'student.timing.constant', 'student.timing.timeLineNoteService', 'student.timing.timeLineCourseUnitService']).factory('timingService', timingService);
  function timingService($q, studyPeriodService, universityService, Range, timeLineNoteService, timingHelper, timelineConfig, timeLineCourseUnitService, commonCourseUnitRealisationService, uuidService, commonCurriculumPeriodService) {
    let courseUnitsByLocator = {};
    let customCourseUnitAttainmentsByLocator = {};
    let plannedPeriodsByCourseUnitId = {};
    let plannedPeriodsByCustomCourseUnitAttainmentId = {};
    let customStudyDraftsByLocator = {};
    let plannedPeriodsByCustomStudyDraftId = {};

    /**
     * Some methods of api modify plan and use validatablePlan. ValidatablePlan should be created after plan
     * modification because the pre-modification validatablePlan is invalid after plan modification.
     */
    const api = {
      private: {
        // private functions exposed for testing
        origTimedPeriodLocator: null,
        untimedCourseUnit: null,
        studyYears: [],
        refreshAfterNoteAction: refreshAfterNoteAction,
        refresh: refresh,
        getTimeLineStartYear: getTimeLineStartYear,
        getEndYearForTimeLine: getEndYearForTimeLine,
        loadStudyYears: loadStudyYears,
        calculateTimedCredits: calculateTimedCredits,
        getCourseUnitsByPeriodLocator: getCourseUnitsByPeriodLocator,
        getCustomStudyDraftsByPeriodLocator: getCustomStudyDraftsByPeriodLocator
      },
      init: (plan, validatablePlan, studyRights) => {
        return api.private.getTimeLineStartYear(validatablePlan, studyRights).then(startYear => {
          const timeLineEndYear = api.private.getEndYearForTimeLine(studyRights);
          const currentUniversityOrgId = universityService.getCurrentUniversityOrgId();
          return api.private.loadStudyYears(startYear, timeLineEndYear, currentUniversityOrgId);
        }).then(() => {
          api.private.refresh(plan, validatablePlan);
          return true;
        });
      },
      getPeriods: () => {
        return _.flattenDeep(_.map(api.private.studyYears, studyYear => {
          return _.map(studyYear.$studyTerms, studyTerm => {
            return studyTerm.$studyPeriods;
          });
        }));
      },
      getPeriodByLocator: locator => {
        return _.find(api.getPeriods(), {
          locator: locator
        });
      },
      getStudyYears: () => {
        return api.private.studyYears;
      },
      startTimingCourseUnit: (courseUnit, periodLocator, plan, validatablePlan) => {
        clearGrid();
        api.private.untimedCustomStudyDraft = null;
        api.private.untimedCourseUnit = courseUnit;
        api.private.origTimedPeriodLocator = periodLocator;
        const plannedTeachingPeriods = getFixedTeachingPlanningPeriods(courseUnit, validatablePlan);
        if (courseUnit.selectedCompletionMethod) {
          commonCourseUnitRealisationService.findActiveCourseUnitRealisationsForAssessmentItemIds(_.values(courseUnit.selectedCompletionMethod.assessmentItemIds)).then(courseUnitRealisationsByAssessmentItems => {
            createGrid(plannedTeachingPeriods, _.flatten(_.values(courseUnitRealisationsByAssessmentItems)), plan, validatablePlan);
          });
        } else {
          createGrid(plannedTeachingPeriods, [], plan, validatablePlan);
        }
      },
      startTimingCustomStudyDraft: (customStudyDraft, periodLocator, plan, validatablePlan) => {
        clearGrid();
        api.private.untimedCourseUnit = null;
        api.private.untimedCustomStudyDraft = customStudyDraft;
        api.private.origTimePeriodLocator = periodLocator;
        createGrid([], [], plan, validatablePlan);
      },
      cancelTimingOfCourseUnit: (plan, validatablePlan) => {
        api.private.untimedCourseUnit = null;
        api.private.untimedCustomStudyDraft = null;
        api.private.origTimedPeriodLocator = null;
        clearGrid();
        updateGrid(plan, validatablePlan);
      },
      /**
       * This method will modify plan. See documentation of api for more info.
       */
      timeMultipleCourseUnitsToPeriods: (plan, courseUnitTimings, validatablePlanConstructor) => {
        return timeLineCourseUnitService.timeMultipleCourseUnitsToPeriods(plan, courseUnitTimings).then(() => {
          return validatablePlanConstructor(plan);
        }).then(validatablePlan => {
          api.private.refresh(plan, validatablePlan);
        });
      },
      unTimeStudiesFromTimeLine: (plan, courseUnitTimings, customStudyDrafts, validatablePlanConstructor) => {
        return timeLineCourseUnitService.unTimeStudiesFromTimeLine(plan, courseUnitTimings, customStudyDrafts).then(() => {
          return validatablePlanConstructor(plan);
        }).then(validatablePlan => {
          api.private.refresh(plan, validatablePlan);
        });
      },
      /**
       * This method will modify plan. See documentation of api for more info.
       */
      timeCourseUnitToPeriods: (courseUnit, periodLocators, plan, validatablePlanConstructor) => {
        return timeLineCourseUnitService.timeCourseUnitToPeriods(courseUnit, periodLocators, plan).then(() => {
          return validatablePlanConstructor(plan);
        }).then(validatablePlan => {
          api.private.refresh(plan, validatablePlan);
        });
      },
      /**
       * This method will modify plan. See documentation of api for more info.
       */
      timeCustomStudyDraftToPeriods: (customStudyDraft, periodLocators, plan, validatablePlanConstructor) => {
        return timeLineCourseUnitService.timeCustomStudyDraftToPeriods(customStudyDraft, periodLocators, plan).then(() => {
          return validatablePlanConstructor(plan);
        }).then(validatablePlan => {
          api.private.refresh(plan, validatablePlan);
        });
      },
      /**
       * This method will modify plan. See documentation of api for more info.
       */
      moveCourseUnitTimingToPeriods: (courseUnit, periodLocators, plan, validatablePlanConstructor) => {
        return timeLineCourseUnitService.moveCourseUnitTimingToPeriods(courseUnit, periodLocators, plan).then(() => {
          return validatablePlanConstructor(plan);
        }).then(validatablePlan => {
          api.private.refresh(plan, validatablePlan);
        });
      },
      /**
       * This method will modify plan. See documentation of api for more info.
       */
      moveCustomStudyDraftTimingToPeriods: (customStudyDraft, periodLocators, plan, validatablePlanConstructor) => {
        return timeLineCourseUnitService.moveCustomStudyDraftTimingToPeriods(customStudyDraft, periodLocators, plan).then(() => {
          return validatablePlanConstructor(plan);
        }).then(validatablePlan => {
          api.private.refresh(plan, validatablePlan);
        });
      },
      /**
       * This method will modify plan. See documentation of api for more info.
       */
      unTimeCourseUnitFromTimeLine: (courseUnit, plan, validatablePlanConstructor) => {
        return timeLineCourseUnitService.unTimeCourseUnitFromTimeLine(courseUnit, plan).then(() => {
          return validatablePlanConstructor(plan);
        }).then(validatablePlan => {
          api.private.refresh(plan, validatablePlan);
        });
      },
      /**
       * This method will modify plan. See documentation of api for more info.
       */
      unTimeCustomStudyDraftFromTimeLine: (customStudyDraft, plan, validatablePlanConstructor) => {
        return timeLineCourseUnitService.unTimeCustomStudyDraftFromTimeLine(customStudyDraft, plan).then(() => {
          return validatablePlanConstructor(plan);
        }).then(validatablePlan => {
          api.private.refresh(plan, validatablePlan);
        });
      },
      /**
       * This method will modify plan. See documentation of api for more info.
       */
      unTimeCourseUnitFromPeriod: (courseUnit, periodLocator, plan, validatablePlanConstructor) => {
        return timeLineCourseUnitService.unTimeCourseUnitFromPeriod(courseUnit, periodLocator, plan).then(() => {
          return validatablePlanConstructor(plan);
        }).then(validatablePlan => {
          api.private.refresh(plan, validatablePlan);
        });
      },
      /**
       * This method will modify plan. See documentation of api for more info.
       */
      unTimeCustomStudyDraftFromPeriod: (customStudyDraft, periodLocator, plan, validatablePlanConstructor) => {
        return timeLineCourseUnitService.unTimeCustomStudyDraftFromPeriod(customStudyDraft, periodLocator, plan).then(() => {
          return validatablePlanConstructor(plan);
        }).then(validatablePlan => {
          api.private.refresh(plan, validatablePlan);
        });
      },
      /**
       * This method will modify plan. See documentation of api for more info.
       */
      createTimeLineNote: (period, plan, validatablePlanConstructor) => {
        return timeLineNoteService.createTimeLineNote(period, plan).then(() => {
          return validatablePlanConstructor(plan);
        }).then(validatablePlan => {
          api.private.refreshAfterNoteAction(plan, validatablePlan);
        });
      },
      /**
       * This method will modify plan. See documentation of api for more info.
       */
      editTimeLineNote: (note, plan, validatablePlanConstructor) => {
        return timeLineNoteService.editTimeLineNote(note, plan).then(() => {
          return validatablePlanConstructor(plan);
        }).then(validatablePlan => {
          api.private.refreshAfterNoteAction(plan, validatablePlan);
        });
      },
      /**
       * This method will modify plan. See documentation of api for more info.
       */
      deleteNote: (note, plan, validatablePlanConstructor) => {
        return timeLineNoteService.deleteTimeLineNote(note, plan).then(() => {
          return validatablePlanConstructor(plan);
        }).then(validatablePlan => {
          api.private.refreshAfterNoteAction(plan, validatablePlan);
        });
      },
      /**
       * This method will modify plan. See documentation of api for more info.
       */
      addNoteToPeriod: (note, period, plan, validatablePlanConstructor) => {
        return timeLineNoteService.addNoteToPeriod(note, period, plan).then(() => {
          return validatablePlanConstructor(plan);
        }).then(validatablePlan => {
          api.private.refreshAfterNoteAction(plan, validatablePlan);
        });
      },
      /**
       * This method will modify plan. See documentation of api for more info.
       */
      moveNoteToPeriod: (note, period, plan, validatablePlanConstructor) => {
        return timeLineNoteService.moveNoteToPeriod(note, period, plan).then(() => {
          return validatablePlanConstructor(plan);
        }).then(validatablePlan => {
          api.private.refreshAfterNoteAction(plan, validatablePlan);
        });
      },
      /**
       * This method will modify plan. See documentation of api for more info.
       */
      removeNoteFromPeriod: (note, period, plan, validatablePlanConstructor) => {
        return timeLineNoteService.removeNoteFromPeriod(note, period, plan).then(() => {
          return validatablePlanConstructor(plan);
        }).then(validatablePlan => {
          api.private.refreshAfterNoteAction(plan, validatablePlan);
        });
      }
    };
    return api;
    function getEndYearForTimeLine(studyRights) {
      var endYearForTimeLine;
      if (_.isEmpty(studyRights)) {
        endYearForTimeLine = moment().add(timelineConfig.studyYearsOffset, 'year').year();
      } else {
        _.forEach(studyRights, function (studyRight) {
          var studyRightEndDate = _.get(studyRight, 'valid.endDate');
          if (studyRightEndDate) {
            var studyRightEndYear = moment(studyRightEndDate).add(timelineConfig.studyYearsVisibleAfterStudyRight, 'years').year();
            if (!endYearForTimeLine || studyRightEndYear > endYearForTimeLine) {
              endYearForTimeLine = studyRightEndYear;
            }
          }
        });
      }
      if (_.isNil(endYearForTimeLine)) {
        endYearForTimeLine = moment().add(timelineConfig.studyYearsOffset, 'year').year();
      }
      return endYearForTimeLine;
    }
    function refreshAfterNoteAction(plan, validatablePlan) {
      clearGrid();
      updateGrid(plan, validatablePlan);
    }
    function refresh(plan, validatablePlan) {
      // mapLocatorToPlannedCourseUnits needs to be run if course units are added to/removed from the plan.
      mapLocatorToPlannedCourseUnits(validatablePlan);
      api.private.calculateTimedCredits(validatablePlan);
      clearGrid();
      updateGrid(plan, validatablePlan);
    }
    function loadStudyYears(startYear, endYear, currentUniversityOrgId) {
      var firstYear = startYear ? startYear : moment().year() - 5;
      var lastYear = endYear ? endYear : moment().year() + 10;
      var visibleYears = lastYear - firstYear;
      return studyPeriodService.getStudyYears(currentUniversityOrgId, firstYear, visibleYears).then(function (studyYears) {
        api.private.studyYears = studyYears.sort(function (a, b) {
          return a.startDate.toDate() < b.startDate.toDate() ? -1 : 1;
        });
      });
    }
    function findAttainmentPeriod(attainmentDate, periods) {
      for (let i = 0; i < periods.length - 1; i++) {
        const range = {
          startDate: periods[i].startDate,
          endDate: periods[i + 1].startDate
        };
        if (dateUtils.rangeContains(attainmentDate, range)) {
          return periods[i];
        }
      }
      return undefined;
    }
    function findAttainmentPeriodLocator(attainmentDate, periods) {
      const period = findAttainmentPeriod(attainmentDate, periods);
      return period ? period.locator : {};
    }
    function mapLocatorToPlannedCourseUnits(validatablePlan) {
      const courseUnitLocatorMap = {};
      const courseUnitIdMap = {};
      const customCourseUnitAttainmentLocatorMap = {};
      const customCourseUnitAttainmentIdMap = {};
      const customStudyDraftLocatorMap = {};
      const customStudyDraftIdMap = {};
      const allCourseUnitsInPlan = validatablePlan.getAllCourseUnitsInPlan();
      _.forEach(allCourseUnitsInPlan, courseUnit => {
        const courseUnitAttainment = validatablePlan.getCourseUnitAttainment(courseUnit.id);
        if (courseUnitAttainment) {
          const attainmentPeriodLocator = findAttainmentPeriodLocator(_.get(courseUnitAttainment, 'attainmentDate'), api.getPeriods());
          attachToPeriod(courseUnitLocatorMap, attainmentPeriodLocator, courseUnit);
          courseUnitIdMap[courseUnit.id] = [attainmentPeriodLocator];
        } else {
          const plannedPeriodsForCourseUnit = validatablePlan.getPlannedPeriods(courseUnit.id);
          if (!_.isEmpty(plannedPeriodsForCourseUnit)) {
            _.forEach(plannedPeriodsForCourseUnit, periodLocator => {
              attachToPeriod(courseUnitLocatorMap, periodLocator, courseUnit);
            });
            courseUnitIdMap[courseUnit.id] = plannedPeriodsForCourseUnit;
          } else {
            courseUnitIdMap[courseUnit.id] = [];
          }
        }
      });
      const allCustomCourseUnitAttainmentsInPlan = validatablePlan.getAllCustomCourseUnitAttainmentsInPlan();
      _.forEach(allCustomCourseUnitAttainmentsInPlan, customCourseUnitAttainment => {
        const attainmentPeriodLocator = findAttainmentPeriodLocator(_.get(customCourseUnitAttainment, 'attainmentDate'), api.getPeriods());
        attachToPeriod(customCourseUnitAttainmentLocatorMap, attainmentPeriodLocator, customCourseUnitAttainment);
        customCourseUnitAttainmentIdMap[customCourseUnitAttainment.id] = [attainmentPeriodLocator];
      });
      const allCustomStudyDraftsInPlan = _.get(validatablePlan, 'plan.customStudyDrafts') || [];
      _.forEach(allCustomStudyDraftsInPlan, customStudyDraft => {
        const plannedPeriods = _.get(customStudyDraft, 'plannedPeriods') || [];
        if (!_.isEmpty(plannedPeriods)) {
          _.forEach(plannedPeriods, periodLocator => {
            attachToPeriod(customStudyDraftLocatorMap, periodLocator, customStudyDraft);
          });
          customStudyDraftIdMap[customStudyDraft.id] = plannedPeriods;
        } else {
          customStudyDraftIdMap[customStudyDraft.id] = [];
        }
      });
      courseUnitsByLocator = courseUnitLocatorMap;
      plannedPeriodsByCourseUnitId = courseUnitIdMap;
      customCourseUnitAttainmentsByLocator = customCourseUnitAttainmentLocatorMap;
      plannedPeriodsByCustomCourseUnitAttainmentId = customCourseUnitAttainmentIdMap;
      customStudyDraftsByLocator = customStudyDraftLocatorMap;
      plannedPeriodsByCustomStudyDraftId = customStudyDraftIdMap;
    }
    function attachToPeriod(mapObject, periodLocator, study) {
      if (!mapObject[periodLocator]) {
        mapObject[periodLocator] = [];
      }
      mapObject[periodLocator].push(study);
    }
    function getCourseUnitsByPeriodLocator(locator) {
      return _.get(courseUnitsByLocator, locator, []);
    }
    function getCustomStudyDraftsByPeriodLocator(locator) {
      return _.get(customStudyDraftsByLocator, locator, []);
    }
    function getCustomCourseUnitAttainmentsByPeriodLocator(locator) {
      return _.get(customCourseUnitAttainmentsByLocator, locator, []);
    }
    function calculateTimedCredits(validatablePlan) {
      const periods = api.getPeriods();
      _.forEach(periods, period => {
        period.timedCredits = null;
        period.$term.timedCredits = null;
        period.$year.timedCredits = null;
        period.attainedCredits = 0;
        period.$term.attainedCredits = 0;
        period.$year.attainedCredits = 0;
      });
      _.forEach(periods, period => {
        const allCourseUnits = api.private.getCourseUnitsByPeriodLocator(period.locator);
        const allCustomCourseUnitAttainments = getCustomCourseUnitAttainmentsByPeriodLocator(period.locator);
        const allStudies = _.concat(allCourseUnits, allCustomCourseUnitAttainments);
        _.forEach(allStudies, study => {
          let plannedPeriodCount = 1;
          let credits;
          let attainedCredits = 0;
          if (study.type !== 'CustomCourseUnitAttainment') {
            const courseUnitAttainment = validatablePlan.getCourseUnitAttainment(study.id);
            if (!courseUnitAttainment) {
              plannedPeriodCount = _.size(validatablePlan.getPlannedPeriods(study));
              credits = study.credits;
            } else {
              plannedPeriodCount = 1;
              credits = new Range(courseUnitAttainment.credits);
              attainedCredits = courseUnitAttainment.credits;
            }
          }
          if (study.type === 'CustomCourseUnitAttainment') {
            plannedPeriodCount = 1;
            credits = new Range(study.credits);
            attainedCredits = study.credits;
          }
          const periodShare = Range.divideRange(credits, plannedPeriodCount);
          period.timedCredits = period.timedCredits ? Range.sumRanges(period.timedCredits, periodShare) : periodShare;
          period.attainedCredits += attainedCredits;
          period.$term.timedCredits = period.$term.timedCredits ? Range.sumRanges(period.$term.timedCredits, periodShare) : periodShare;
          period.$term.attainedCredits += attainedCredits;
          period.$year.timedCredits = period.$year.timedCredits ? Range.sumRanges(period.$year.timedCredits, periodShare) : periodShare;
          period.$year.attainedCredits += attainedCredits;
        });
        const allCustomStudyDrafts = api.private.getCustomStudyDraftsByPeriodLocator(period.locator);
        _.forEach(allCustomStudyDrafts, customStudyDraft => {
          const plannedPeriodCount = _.size(customStudyDraft.plannedPeriods) || 1;
          const credits = new Range(customStudyDraft.credits);
          const periodShare = Range.divideRange(credits, plannedPeriodCount);
          period.timedCredits = period.timedCredits ? Range.sumRanges(period.timedCredits, periodShare) : periodShare;
          period.$term.timedCredits = period.$term.timedCredits ? Range.sumRanges(period.$term.timedCredits, periodShare) : periodShare;
          period.$year.timedCredits = period.$year.timedCredits ? Range.sumRanges(period.$year.timedCredits, periodShare) : periodShare;
        });
      });
    }
    function updateGrid(plan, validatablePlan) {
      const notes = timeLineNoteService.getTimeLineNotes(plan);
      _.forEach(notes, note => {
        // wrap to locator object
        const notePeriods = _.map(note.notePeriods, periodLocator => {
          return {
            locator: periodLocator
          };
        });
        insertIntoGrid(notePeriods, timelineConfig.rowType.NOTE, note);
      });
      const periods = api.getPeriods();
      const allCourseUnitsInPlan = validatablePlan.getAllCourseUnitsInPlan();
      const allCustomCourseUnitAttainmentsInPlan = validatablePlan.getAllCustomCourseUnitAttainmentsInPlan();
      const allCustomStudyDraftsInPlan = _.get(validatablePlan, 'plan.customStudyDrafts');
      _.forEach(allCourseUnitsInPlan, function (courseUnit) {
        var plannedPeriods = _.filter(periods, period => {
          const plannedPeriodsForCourseUnit = plannedPeriodsByCourseUnitId[courseUnit.id];
          return _.includes(plannedPeriodsForCourseUnit, period.locator);
        });
        insertIntoGrid(plannedPeriods, timelineConfig.rowType.COURSE_UNIT, courseUnit, courseUnit.id);
      });
      _.forEach(allCustomCourseUnitAttainmentsInPlan, customCourseUnitAttainment => {
        const plannedPeriods = _.filter(periods, period => _.includes(plannedPeriodsByCustomCourseUnitAttainmentId[customCourseUnitAttainment.id], period.locator));
        insertIntoGrid(plannedPeriods, timelineConfig.rowType.CUSTOM_COURSE_UNIT_ATTAINMENT, customCourseUnitAttainment, customCourseUnitAttainment.id);
      });
      _.forEach(allCustomStudyDraftsInPlan, customStudyDraft => {
        const plannedPeriods = _.filter(periods, period => _.includes(plannedPeriodsByCustomStudyDraftId[customStudyDraft.id], period.locator));
        insertIntoGrid(plannedPeriods, timelineConfig.rowType.CUSTOM_STUDY_DRAFT, customStudyDraft, customStudyDraft.id);
      });
    }
    function clearGrid() {
      _.forEach(api.getPeriods(), function (period) {
        period.rows = [];
      });
    }
    function insertIntoGrid(selectedPeriods, type, courseUnitOrNote, id) {
      if (!id) {
        id = _.has(courseUnitOrNote, 'localId') ? _.get(courseUnitOrNote, 'localId') : _.get(courseUnitOrNote, 'id');
      }
      var conflict = true;
      if (!_.isEmpty(selectedPeriods)) {
        var periods = api.getPeriods();
        var periodRange = _.slice(periods, _.findIndex(periods, {
          locator: _.first(selectedPeriods).locator
        }), _.findIndex(periods, {
          locator: _.last(selectedPeriods).locator
        }) + 1);
        var rowIndex = -1;
        while (conflict) {
          rowIndex += 1;
          conflict = false;
          _.forEach(periodRange, checkConflict);
        }
        _.forEach(periodRange, function (period) {
          var row = {
            type: type,
            item: courseUnitOrNote
          };
          if (period.rows.length <= rowIndex) {
            var i = period.rows.length;
            period.rows.length = rowIndex + 1;
            for (; i < period.rows.length; i++) {
              period.rows[i] = {
                type: 'spacer',
                id: uuidService.randomUUID()
              };
            }
          }
          var idx = _.findIndex(selectedPeriods, {
            locator: period.locator
          });
          if (idx < 0) {
            row.type = type + '-gap';
          }
          row.position = '';
          if (idx === 0) {
            row.position = 'left';
          }
          if (idx === selectedPeriods.length - 1) {
            row.position += ' right';
          }
          if (row.position === '') {
            row.position = 'middle';
          }
          if (id) {
            row.id = id;
          }
          period.rows[rowIndex] = row;
        });
      }
      function checkConflict(period) {
        if (period.rows.length > rowIndex && period.rows[rowIndex] && period.rows[rowIndex].type !== 'spacer') {
          conflict = true;
        }
        // plannedTeaching should be before note
        if (!conflict && type === timelineConfig.rowType.NOTE) {
          for (var i = rowIndex; i < period.rows.length; ++i) {
            if (period.rows[i].type === timelineConfig.rowType.PLANNED_TEACHING) {
              conflict = true;
            }
          }
        }
      }
    }
    function createGrid(plannedTeachingPeriods, courseUnitRealisations, plan, validatablePlan) {
      // Remove duplicates
      var uniqueTeachingPeriods = _.uniqWith(plannedTeachingPeriods, _.isEqual);
      var periods = api.getPeriods();
      _.forEach(uniqueTeachingPeriods, function (repeatSet, index) {
        var plannedPeriods = _.filter(periods, function (period) {
          return _.includes(repeatSet, period.locator);
        });
        var active = _.some(courseUnitRealisations, function (courseUnitRealisation) {
          var mappedStudyPeriod = _.map(repeatSet, api.getPeriodByLocator);
          var missingFiltered = _.filter(mappedStudyPeriod, function (studyPeriod) {
            return !_.isUndefined(studyPeriod);
          });
          return courseUnitRealisationMatchesPeriods(courseUnitRealisation, missingFiltered);
        });
        insertIntoGrid(plannedPeriods, timelineConfig.rowType.PLANNED_TEACHING, {
          repeatSet: repeatSet,
          active: active
        }, index);
      });
      updateGrid(plan, validatablePlan);
    }
    function getFixedTeachingPlanningPeriods(courseUnit, validatablePlan) {
      var selectedCompletionMethod = validatablePlan.getSelectedCompletionMethod(courseUnit);
      var plannedTeachingPeriods = timingHelper.getCompletionMethodRepeatSets(courseUnit.completionMethods, selectedCompletionMethod);
      var lastPeriod = _.last(api.getPeriods());
      if (plannedTeachingPeriods.length > 0) {
        timingHelper.addYearsToPlannedTeachingPeriods(plannedTeachingPeriods, lastPeriod.$year.startYear);
      }
      return plannedTeachingPeriods;
    }
    function courseUnitRealisationMatchesPeriods(courseUnitRealisation, periods) {
      var teachingMatchesTiming = false;
      _.forEach(periods, function (periodLocator) {
        var rangesOverLap = dateUtils.dateRangesOverlap(courseUnitRealisation.activityPeriod.startDate, courseUnitRealisation.activityPeriod.endDate, periodLocator.valid.startDate, periodLocator.valid.endDate);
        if (rangesOverLap) {
          teachingMatchesTiming = true;
        }
      });
      return teachingMatchesTiming;
    }
    function getTimeLineStartYear(validatablePlan, studyRights) {
      return $q.all([commonCurriculumPeriodService.findById(validatablePlan.plan.curriculumPeriodId), timingHelper.getFirstTimedPeriod(validatablePlan)]).then(([curriculumPeriod, firstPlannedDate]) => {
        let earliestDate = moment(curriculumPeriod.activePeriod.startDate, 'YYYY-MM-DD');
        if (firstPlannedDate && firstPlannedDate.isBefore(earliestDate)) {
          earliestDate = firstPlannedDate;
        }
        const courseUnitAttainmentsInPlan = timeLineCourseUnitService.getCourseUnitAttainmentsForModuleRecursively(validatablePlan.rootModule, validatablePlan);
        const customCourseUnitAttainmentsInPlan = validatablePlan.getAllCustomCourseUnitAttainmentsInPlan();
        const allAttainmentsInPlan = _.concat(courseUnitAttainmentsInPlan, customCourseUnitAttainmentsInPlan);
        const firstAttainmentInPlan = _.chain(allAttainmentsInPlan).values().sortBy('attainmentDate').first().value();
        if (firstAttainmentInPlan) {
          const firstAttainmentDateInPlan = moment(firstAttainmentInPlan.attainmentDate, 'YYYY-MM-DD');
          if (firstAttainmentDateInPlan.isBefore(earliestDate)) {
            earliestDate = firstAttainmentDateInPlan;
          }
        }
        let studyRightDate = null;
        if (!_.isNull(studyRights)) {
          studyRightDate = timingHelper.getFirstStudyRightDate(studyRights);
        }
        if (studyRightDate && studyRightDate.isBefore(earliestDate)) {
          earliestDate = studyRightDate;
        }
        if (earliestDate.month() < 7) {
          earliestDate = earliestDate.subtract(1, 'year');
        }
        return earliestDate.year();
      });
    }
  }
})();