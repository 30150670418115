import { ChangeDetectionStrategy, Component, inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Enrolment, EnrolmentQuestionnaire, EnrolmentQuestionnaireAnswers, LocalId } from 'common-typescript/types';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import {
    EnrolmentQuestionnaireAnswersEntityService,
} from 'sis-components/service/enrolment-questionnaire-answers-entity.service';
import { EnrolmentQuestionnaireEntityService } from 'sis-components/service/enrolment-questionnaire-entity.service';
import { trackByEntityId } from 'sis-components/util/utils';

@Component({
    selector: 'app-enrolment-questionnaire-answers',
    templateUrl: './enrolment-questionnaire-answers.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnrolmentQuestionnaireAnswersComponent implements OnInit {

    @Input({ required: true }) enrolment: Enrolment;

    appErrorHandler = inject(AppErrorHandler);
    questionnaireService = inject(EnrolmentQuestionnaireEntityService);
    questionnaireAnswersService = inject(EnrolmentQuestionnaireAnswersEntityService);

    readonly entityId = trackByEntityId;

    questionnaire$: Observable<EnrolmentQuestionnaire>;
    answers$: Observable<EnrolmentQuestionnaireAnswers>;

    ngOnInit(): void {
        this.questionnaire$ = this.questionnaireService.getByCourseUnitRealisationId(this.enrolment.courseUnitRealisationId)
            .pipe(this.appErrorHandler.defaultErrorHandler());
        this.answers$ = this.questionnaireAnswersService.getByEnrolmentId(this.enrolment.id)
            .pipe(
                catchError((error) => {
                    if (error.status === 404) {
                        return of({ answers: [] } as EnrolmentQuestionnaireAnswers);
                    }
                    return throwError(() => error);
                }),
                this.appErrorHandler.defaultErrorHandler());
    }

    getAnswerByQuestionId(answers: EnrolmentQuestionnaireAnswers, questionId: LocalId) {
        return answers.answers?.find(a => a.questionId === questionId);
    }
}
