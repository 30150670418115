import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { PersonWithGroupResponsibilityInfoType } from 'common-typescript/types';
import * as _ from 'lodash';
import { LocaleService } from 'sis-common/l10n/locale.service';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'groupResponsibilityInfoName' })
export class GroupResponsibilityInfoNamePipe implements PipeTransform {
    constructor(private localeService: LocaleService) {}

    transform(responsibilityInfo: PersonWithGroupResponsibilityInfoType): string {
        if (responsibilityInfo) {
            if (!_.isNil(responsibilityInfo.personId)) {
                return [responsibilityInfo.personFirstNames, responsibilityInfo.personLastName].join(' ');
            }
            return this.localeService.localize(_.get(responsibilityInfo, 'text'));
        }
        return '';
    }
}
