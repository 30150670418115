import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
export const commonCurriculumPeriodServiceModule = 'sis-components.service.curriculumPeriodService';
(function () {
  curriculumPeriodService.$inject = ["$log", "$q", "$http", "curriculumPeriodModel", "universityService", "jsDataCacheHelper"];
  angular.module(commonCurriculumPeriodServiceModule, ['sis-components.model.curriculumPeriod', 'sis-common.university', 'sis-components.service.jsDataCacheHelper']).service('commonCurriculumPeriodService', curriculumPeriodService);

  /**
   * @ngInject
   */
  function curriculumPeriodService($log, $q, $http, curriculumPeriodModel, universityService, jsDataCacheHelper) {
    const baseUri = '/kori/api/curriculum-periods';
    const sortOrder = ['universityOrgId', 'activePeriod.startDate'];
    function belongsToCurriculumPeriod(version, curriculumPeriodId) {
      if (!version || version.type && version.type === 'GroupingModule') {
        return true;
      }
      if (!version.curriculumPeriodIds) {
        return false;
      }
      return _.includes(version.curriculumPeriodIds, curriculumPeriodId);
    }
    const api = {
      findCurriculumPeriodsByIds: function (ids) {
        return jsDataCacheHelper.findByIds(curriculumPeriodModel, ids, false);
      },
      filterActiveVersionForCurriculumPeriod(versions, curriculumPeriodId) {
        const versionsForCurriculumPeriod = _.filter(versions, version => belongsToCurriculumPeriod(version, curriculumPeriodId));
        let usedVersion;
        if (_.size(versionsForCurriculumPeriod) === 1) {
          [usedVersion] = versionsForCurriculumPeriod;
        } else if (_.size(versionsForCurriculumPeriod) > 1) {
          const notDeleted = _.find(versionsForCurriculumPeriod, version => version.documentState !== 'DELETED');
          if (notDeleted) {
            usedVersion = notDeleted;
          } else {
            usedVersion = _.last(_.sortBy(versionsForCurriculumPeriod, 'metadata.createdOn'));
          }
        }
        if (!usedVersion) {
          $log.warn('Failed to resolve version for curriculumPeriod. Use first version.', curriculumPeriodId, versions);
          usedVersion = _.first(versionsForCurriculumPeriod);
        }
        return usedVersion;
      },
      findAll: function () {
        return curriculumPeriodModel.findAll().then(function (curriculumPeriods) {
          return _.sortBy(curriculumPeriods, sortOrder);
        });
      },
      findVersionForCurriculumPeriodId: function (versions, curriculumPeriodId) {
        let resolveCurriculumPeriodId;
        if (!curriculumPeriodId) {
          $log.warn('curriculumPeriodId not given for findVersionForCurriculumPeriodId. ' + 'Using CurrentCurriculumPeriod.', 'versions:', versions, 'curriculumPeriodId:', curriculumPeriodId);
          resolveCurriculumPeriodId = api.getCurrentCurriculumPeriod();
        } else {
          resolveCurriculumPeriodId = $q.when(curriculumPeriodId);
        }
        return resolveCurriculumPeriodId.then(function (curriculumPeriodId) {
          let version;
          if (versions && versions.length === 1) {
            version = versions[0];
          } else {
            version = api.filterActiveVersionForCurriculumPeriod(versions, curriculumPeriodId);
          }
          if (version) {
            return $q.when(version);
          }
          return api.findAll().then(function (curriculumPeriods) {
            curriculumPeriods = curriculumPeriods ? curriculumPeriods.reverse() : [];
            for (const i = 0; i < curriculumPeriods.length; i++) {
              version = api.filterActiveVersionForCurriculumPeriod(versions, curriculumPeriods[i].id);
              if (version) {
                return version;
              }
            }
            $log.warn("Version not found for any known curriculumPeriod", versions);
            return _.last(versions);
          });
        }, function (error) {
          $log.error("Failed to find version for CurriculumPeriodId", error, 'versions', versions, 'curriculumPeriodId', curriculumPeriodId);
        });
      },
      getCurrentCurriculumPeriod: function (universityOrgId) {
        universityOrgId = universityOrgId || universityService.getCurrentUniversityOrgId();
        const today = moment().startOf('day');
        return api.findByUniversityOrgId(universityOrgId).then(curriculumPeriods => {
          let currentCurriculumPeriod;
          _.forEach(curriculumPeriods, curriculumPeriod => {
            const starts = curriculumPeriod.activePeriod.startDate;
            const ends = curriculumPeriod.activePeriod.endDate;
            if (today.isBetween(starts, ends, 'day', '[)')) {
              currentCurriculumPeriod = curriculumPeriod;
            }
          });
          return currentCurriculumPeriod;
        });
      },
      getNextCurriculumPeriod: function (universityOrgId) {
        universityOrgId = universityOrgId ? universityOrgId : universityService.getCurrentUniversityOrgId();
        const today = moment().startOf('day');
        return api.findByUniversityOrgId(universityOrgId).then(function (curriculumPeriods) {
          return _(curriculumPeriods).filter(function (period) {
            const startDate = moment(_.get(period, 'activePeriod.startDate'));
            return startDate.isValid() && startDate.isAfter(today);
          }).sortBy('activePeriod.startDate').head();
        });
      },
      getCurriculumPeriodDateRange: function (curriculumPeriods) {
        const sortedByStartDate = _.sortBy(curriculumPeriods, 'activePeriod.startDate');
        const sortedByEndDate = _.sortBy(curriculumPeriods, 'activePeriod.endDate');
        return {
          startDate: _.get(_.first(sortedByStartDate), 'activePeriod.startDate'),
          endDate: _.get(_.last(sortedByEndDate), 'activePeriod.endDate')
        };
      },
      getCurriculumPeriodYearRange(curriculumPeriodIds, universityOrgId) {
        universityOrgId = universityOrgId || universityService.getCurrentUniversityOrgId();
        return api.findCurriculumPeriodsByIds(curriculumPeriodIds).then(curriculumPeriods => _.filter(curriculumPeriods, cp => cp.universityOrgId === universityOrgId)).then(curriculumPeriods => {
          const activePeriods = _.map(curriculumPeriods, 'activePeriod');
          const startYear = _.min(_.map(activePeriods, ap => moment(ap.startDate).year()));
          let endYear;
          if (!_.some(_.map(activePeriods, 'endDate'), _.isUndefined)) {
            // End year year set, no valid for now curriculum period
            endYear = _.max(_.map(curriculumPeriods, cp => moment(cp.activePeriod.endDate).year()));
          }
          return {
            startYear,
            endYear
          };
        });
      },
      getCurriculumPeriodDateRangeForCurrentUniversity: function (curriculumPeriodIds) {
        const currentUniversityOrgId = universityService.getCurrentUniversityOrgId();
        return api.findCurriculumPeriodsByIds(curriculumPeriodIds).then(function (curriculumPeriods) {
          const universityPeriods = _.filter(curriculumPeriods, {
            universityOrgId: currentUniversityOrgId
          });
          return api.getCurriculumPeriodDateRange(universityPeriods);
        });
      },
      findByUniversityOrgId: function (universityOrgIds) {
        return curriculumPeriodModel.findAll({
          universityOrgId: universityOrgIds
        }).then(function (curriculumPeriods) {
          return _.sortBy(curriculumPeriods, sortOrder);
        });
      },
      findById: function (curriculumPeriodId) {
        return curriculumPeriodModel.find(curriculumPeriodId);
      },
      getCurriculumPeriodsForSearch: function (planCurriculumPeriodId) {
        return api.findByUniversityOrgId([universityService.getCurrentUniversityOrgId()]).then(curriculumPeriods => {
          return api.filterCurriculumPeriodsForSearch(planCurriculumPeriodId, curriculumPeriods);
        });
      },
      filterCurriculumPeriodsForSearch: (planCurriculumPeriodId, curriculumPeriods) => {
        const result = [];
        sortCurriculumPeriods(curriculumPeriods);
        const firstIndex = _.findIndex(curriculumPeriods, curriculumPeriod => {
          return curriculumPeriod.id === planCurriculumPeriodId;
        });
        if (firstIndex === -1) {
          return [];
        }
        if (firstIndex !== curriculumPeriods.length - 1) {
          result.push(curriculumPeriods[firstIndex]);
          const remainingCurriculumPeriods = _.slice(curriculumPeriods, firstIndex + 1);
          const currentMoment = moment();
          _.forEach(remainingCurriculumPeriods, cp => {
            if (moment(cp.activePeriod.startDate).isSameOrBefore(currentMoment, 'day')) {
              result.push(cp);
            }
          });
          const lastIndex = _.indexOf(remainingCurriculumPeriods, _.last(result));
          const lastResult = _.last(result);
          if (currentMoment.isBetween(lastResult.activePeriod.startDate, lastResult.activePeriod.endDate, 'days', '[)') && lastIndex !== remainingCurriculumPeriods.length - 1) {
            result.push(remainingCurriculumPeriods[lastIndex + 1]);
          }
        }
        return result;
      },
      loadCurriculumPeriodsStartingFrom: firstCurrPeriodId => {
        const currentUniOrgId = universityService.getCurrentUniversityOrgId();
        return $http.get(`${baseUri}?universityOrgId=${currentUniOrgId}&firstCurriculumPeriodId=${firstCurrPeriodId}`, {
          cache: true
        }).then(result => _.sortBy(result.data, curriculumPeriod => curriculumPeriod.activePeriod.startDate));
      }
    };
    function sortCurriculumPeriods(curriculumPeriods) {
      curriculumPeriods.sort((a, b) => {
        return moment(a.activePeriod.startDate).isBefore(b.activePeriod.startDate, 'day') ? -1 : 1;
      });
    }
    return api;
  }
})();