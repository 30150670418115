<ng-container *transloco="let t">
  <div data-cy="continue-session-modal" class="modal-header" sisAutofocus>
    <h2>{{ t('SESSION_TIMEOUT_WARNING_MODAL.TITLE') }}</h2>
  </div>
  <div class="modal-body">
    <ng-container *ngIf="timerValues$ | async as timerValues">
      <div role="alert">
        <span *ngIf="timerValues.assertive">
          {{ t('SESSION_TIMEOUT_WARNING_MODAL.BODY', { timeRemaining: timerValues.timeRemainingInSeconds * 1000 | date:'mm:ss' }) }}
        </span>
      </div>
      <span *ngIf="!timerValues.assertive">
          {{ t('SESSION_TIMEOUT_WARNING_MODAL.BODY', { timeRemaining: timerValues.timeRemainingInSeconds * 1000 | date:'mm:ss' }) }}
      </span>
    </ng-container>
  </div>
  <div class="modal-footer">
    <button type="button" data-cy="continue-session-button" class="btn btn-primary btn-lg" (click)="modal.close(true)">
      {{ t('BUTTON.CONTINUE') }}
    </button>
  </div>
</ng-container>

