<div role="group"
     [attr.aria-labelledby]="selectionUIState !== 'DISABLED' ? ruleLegendId() : undefined"
     [attr.id]="ruleFocusId()"
     [tabindex]="-1">
  <sis-plan-structure-credit-rule-header *ngIf="selectionUIState !== 'DISABLED'"
                                         [onlyShowRule]="selectionUIState === 'SELECTABLE'"
                                         [parentModule]="_parentModule()"
                                         [ruleDepthLevel]="ruleDepthLevel"
                                         [ruleValidationResults]="ruleValidationResults()"
                                         [ruleLegendId]="ruleLegendId()"
                                         [rule]="_rule()">
  </sis-plan-structure-credit-rule-header>
  <sis-plan-structure-rule-error-list *ngIf="ruleErrors && ruleErrors()?.length > 0"
                                      [ruleErrors]="ruleErrors()">
  </sis-plan-structure-rule-error-list>
  <sis-plan-structure-select-rule [rule]="_rule().rule"
                                  [headingLevel]="headingLevel"
                                  [parentModule]="_parentModule()"
                                  [planData]="planData"
                                  [planRuleData]="planRuleData"
                                  [planStateObject]="planStateObject"
                                  [planValidationResult]="_planValidationResult()"
                                  [validatablePlan]="validatablePlan"
                                  [selectionUIState]="selectionUIState"
                                  [groupPrefix]="groupPrefix"
                                  [ruleDepthLevel]="ruleDepthLevel + 1">
  </sis-plan-structure-select-rule>
</div>



