import { Injectable } from '@angular/core';
import { TranslationMarkupRendererFactory } from 'ngx-transloco-markup';

import { HtmlElementTranspiler } from '../common/html-element-transpiler';

export const SMALL_START = new (class SmallStart {})();
export const SMALL_END = new (class SmallEnd {})();

@Injectable()
export class SmallTranspiler extends HtmlElementTranspiler {
    constructor(private readonly rendererFactory: TranslationMarkupRendererFactory) {
        super({
            rendererFactory,
            start: {
                search: '<small>',
                token: SMALL_START,
            },
            end: {
                search: '</small>',
                token: SMALL_END,
            },
            elementTag: 'small',
        });
    }
}
