import { Injectable } from '@angular/core';
import { AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { OtmId, PublicPerson } from 'common-typescript/types';
import { Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

import { EntityService } from './entity.service';

const CONFIG = {
    ENDPOINTS: {
        backend: '/kori/api',
    },
};

@StaticMembers<DowngradedService>()
@Injectable({
    providedIn: 'root',
})
@NgEntityServiceConfig({
    baseUrl: CONFIG.ENDPOINTS.backend,
})
export class PublicPersonEntityService extends EntityService<PublicPersonsState> {

    static downgrade: ServiceDowngradeMappings = {
        dependencies: [],
        moduleName: 'sis-components.service.publicPersonEntityService',
        serviceName: 'publicPersonEntityService',
    };

    constructor() {
        super(PublicPersonStore, PublicPersonQuery);
    }

    publicPersonValidator(): AsyncValidatorFn {
        return (control) => {
            if (!control.value) {
                return of(null);
            }
            const observable: Observable<ValidationErrors | null> = this.getById(control.value)
                .pipe(
                    take(1),
                    map(() => null),
                    catchError(() => of({ notPublicPerson: { translationKey: 'SIS_COMPONENTS.PERSON.SEARCH_EMPLOYEE.PERSON_NOT_FOUND' } })),
                );
            return observable;
        };
    }
}

type PublicPersonsState = EntityState<PublicPerson, OtmId>;

@StoreConfig({ name: 'persons' })
class PublicPersonStore extends EntityStore<PublicPersonsState> {}

class PublicPersonQuery extends QueryEntity<PublicPersonsState> {
    constructor(protected store: PublicPersonStore) {
        super(store);
    }
}
