import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { StateDeclaration, Transition, TransitionService } from '@uirouter/angular';
import { Attainment, CompletionMethod, CourseUnit, OtmId, ValidatablePlan } from 'common-typescript/types';

export interface CourseUnitInfoData {
    courseUnit: CourseUnit;
    completionMethods: CompletionMethod[];
    productId: OtmId;
    forceEnableCalendarAdd: boolean;
    validatablePlan: Partial<ValidatablePlan>;
    attainments: Attainment[];
}
@Component({
    selector: 'app-course-unit-info',
    templateUrl: './course-unit-info.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CourseUnitInfoComponent implements OnInit, AfterViewInit, OnDestroy {

    constructor(
        private transitionService: TransitionService,
        @Inject(DOCUMENT) private document: Document,
    ) {}

    @Input() courseUnit: CourseUnit;
    // id for aria-controls
    @Input() tabId: string;

    @Input() previousState: StateDeclaration;

    deregisterTabChangeHook: Function;

    ngOnInit() {
        this.deregisterTabChangeHook = this.registerTabChangeHook();
    }

    /** Solution to a problem occurring in Chromium browsers:
     * When arriving from a known previous state (e.g. course unit version selected, redirected from course unit search result),
     * a tab key press would skip the whole course unit info section and focus the first clickable element in the footer.
     * To solve this, we focus the h1 course unit info heading so that the next tab key press focuses an element within the course unit info page
     *
     * For full page refreshes or arrivals from other domains, this does nothing
     */
    ngAfterViewInit() {
        if (this.previousState?.name) {
            this.scrollToTop();
            this.focusHeading();
        }
    }

    ngOnDestroy() {
        this.deregisterTabChangeHook();
    }

    /**
     * When flipping between tabs, we want to update the id of the course-unit-info-tab-content view
     * so that is corresponds to the aria-controls value of the selected tab
     *
     * note: onSuccess returns a function to deregister the hook
     */
    registerTabChangeHook() {
        // blob matches router states for course unit info tabs (brochure, completion methods, substitutions, open university offering)
        const courseUnitInfoTabState = 'student.course-unit.*';
        return this.transitionService.onSuccess({ to: courseUnitInfoTabState }, (transition: Transition) => {
            this.tabId = transition.to().params.tabId;
        });
    }

    scrollToTop() {
        this.document.defaultView?.scrollTo(0, 0);
    }

    focusHeading() {
        const courseUnitInfoHeading = document.querySelector('h1');
        courseUnitInfoHeading?.focus();
    }
}
