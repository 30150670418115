import { Injectable } from '@angular/core';
import { TargetStateDef } from '@uirouter/core';
import { AuthRouteService } from 'sis-common/auth/auth-route.service';

import { studentRoutes } from './student-routes';

@Injectable({
    providedIn: 'root',
})
export class StudentAuthRouteService extends AuthRouteService {

    override get loginPageStateName() {
        return studentRoutes.login;
    }

    override get defaultView() {
        return {
            state: studentRoutes.defaultView,
        };
    }

    override get loggedInStatesGlob() {
        return studentRoutes.loggedIn;
    }

    override get unauthorized() {
        return {
            state: studentRoutes.defaultView,
        };
    }

    override get loginPage() {
        return {
            state: studentRoutes.login,
        };
    }

    override get loggedOut() {
        return {
            state: studentRoutes.login,
            options: { custom: { loginTarget: window.location.href } },
        };
    }

    override get sessionChanged() {
        return {
            state: studentRoutes.login,
            options: { reload: true },
        };
    }

    override get anonymousUrls() {
        return ['/kori/api'];
    }

    override get excludedUrls() {
        return ['/kori/api/authenticated'];
    }
}
