import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { Attainment, StudyRightCourseUnitSelection } from 'common-typescript/types';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-course-unit-selection',
    templateUrl: './course-unit-selection.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CourseUnitSelectionComponent {
    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.studyRight.courseUnitSelectionsNg',
        directiveName: 'sisCourseUnitSelection',
    };

    @Input() selection: StudyRightCourseUnitSelection;
    @Input() attainment: Attainment;
    @Input() canDelete = false;
    @Output() deleteCallback = new EventEmitter<void>();
}
