"use strict";

/**
 * Use this directive to indicate a required field. Works on a label, or any other element, inside a valdr-type element.
 * If an element with a valdr-validation-groups attribute is found among the ancestry, only constraints belonging to
 * those validation groups are enforced.
 *
 * <form valdr-type="TypeName" valdr-validation-groups="ctrl.validationGroups">
 *  <label label-required="fieldName">
 */
(function () {
  labelRequiredDirective.$inject = ["constraintService", "$log"];
  angular.module('sis-common.form').directive('labelRequired', labelRequiredDirective);

  /**
   * @ngInject
   */
  function labelRequiredDirective(constraintService, $log) {
    var nullValidationGroupsController = {
      getValidationGroups: function () {
        return null;
      }
    };
    return {
      restrict: 'A',
      require: ['^^?valdrType', '?^^valdrValidationGroups'],
      link: function (scope, element, attrs, requiredControllers) {
        var fieldName = attrs.labelRequired;
        var constraintPath;
        if (fieldName.indexOf(".") > -1) {
          constraintPath = fieldName;
        } else {
          constraintPath = requiredControllers[0] ? requiredControllers[0].getType() + '.' + fieldName : '';
        }
        var validationGroupsCtrl = requiredControllers[1] || nullValidationGroupsController;
        markRequired(validationGroupsCtrl.getValidationGroups());
        scope.$watch(validationGroupsCtrl.getValidationGroups, markRequired);
        function markRequired(validationGroups) {
          var constraint = constraintService.getFieldConstraints(constraintPath, validationGroups);
          if (!constraint) {
            $log.warn('Did not find validation constraint for field ' + fieldName + "\n constraintPath: " + constraintPath + "\n validationGroups: " + validationGroups);
          }
          if (_.get(constraint, 'required') || _.get(constraint, 'jakarta.validation.constraints.NotEmpty')) {
            element.addClass('required');
          } else {
            element.removeClass('required');
          }
        }
      }
    };
  }
})();