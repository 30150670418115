<ng-container *transloco="let t">
  <div class="module-attainment-application-block">
    <div class="row">
      <div class="col-12 col-md-8">
        <p>
          <ng-container [ngSwitch]="mode">
            <span *ngSwitchCase="'FLAT'">
              {{t('MODULE_INFO.MODULE_ATTAINMENT_APPLICATION.FLAT_MODULE_INFO')}}
            </span>
            <span *ngSwitchCase="'RECURSIVE'">
              {{t('MODULE_INFO.MODULE_ATTAINMENT_APPLICATION.DEEP_MODULE_INFO')}}
            </span>
            <span *ngSwitchCase="'STUDY_RIGHT_NOT_FOUND'">
              {{t('MODULE_INFO.MODULE_ATTAINMENT_APPLICATION.STUDY_RIGHT_NOT_FOUND_INFO')}}
            </span>
            <span class="warning-guidance">
              {{t('MODULE_INFO.MODULE_ATTAINMENT_APPLICATION.MODULE_APPLICATION_NOTIFICATION')}}
            </span>
          </ng-container>
        </p>
      </div>
      <div class="col-12 col-md-4 button-holder">
        <button class="btn btn-white btn-lg"
                data-cy="apply-for-module-attainment-button"
                [disabled]="attainmentApplicationSendPending || !studyRight"
                (click)="applyForModuleAttainment()">
          {{t('MODULE_INFO.MODULE_ATTAINMENT_APPLICATION.APPLY')}}
        </button>
      </div>
    </div>
  </div>
</ng-container>
