<div class="modal-header">
  <h2 class="modal-title" [tabIndex]="0">
    <ng-container *ngIf="customTitle">
      {{ customTitle | transloco}}
    </ng-container>
    <ng-container *ngIf="!customTitle">
      {{'SIS_COMPONENTS.HIERARCHY_SELECTOR_MODAL.EDUCATION_MODAL_TITLE' | transloco}}
    </ng-container>
  </h2>
</div>

<div class="modal-body">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-6">
        <h4 class="form-label">
          {{'SIS_COMPONENTS.HIERARCHY_SELECTOR_MODAL.EDUCATION_TITLE' | transloco}}
        </h4>
      </div>

      <div class="col-12 col-md-6 reset-button-container">
        <button
          type="button"
          class="btn btn-sm btn-secondary btn-hollow reset-selection-button"
          [disabled]="!selected.length"
          (click)="reset()"
        >
          {{'SELECT_ORGANISATION.RESET_SELECTION' | translate}}
        </button>
      </div>
    </div>
  </div>

    <sis-checkbox-tree
      (checkedChange)="selectChange($event)"
      [selected]="selected"
      [option]="optionTree"
      [defaultExpanded]="defaultExpanded"
    ></sis-checkbox-tree>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-primary btn-hollow btn-lg" (click)="close()">
    {{'BUTTON.CANCEL' | translate}}
  </button>
  <button type="button" class="btn btn-primary btn-lg" (click)="confirmSelection()">
    {{'BUTTON.DONE' | translate}}
  </button>
</div>
