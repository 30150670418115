import { inject, Injectable } from '@angular/core';
import { dateUtils } from 'common-typescript';
import { AssessmentItem, Attainment, CourseUnit, CourseUnitRealisation, EnrolmentState, OtmId } from 'common-typescript/types';
import { combineLatest, Observable, of, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { isAssessmentItemAttainment } from 'sis-components/attainment/AttainmentUtil';
import { AssessmentItemEntityService } from 'sis-components/service/assessment-item-entity.service';
import { CourseUnitEntityService } from 'sis-components/service/course-unit-entity.service';
import { CourseUnitRealisationEntityService } from 'sis-components/service/course-unit-realisation-entity.service';
import { StudyYearsEntityService } from 'sis-components/service/study-years-entity.service';
import { UniversityService } from 'sis-components/service/university.service';

import { AttainmentStudentService } from '../../../common/service/attainment-student.service';
import { EnrolmentStudentService } from '../../../common/service/enrolment-student.service';

function hasAttainmentForCourseUnitRealisation(attainments: Attainment[], courseUnitRealisationId: OtmId): boolean {
    return attainments.filter(isAssessmentItemAttainment).some(att => att.courseUnitRealisationId === courseUnitRealisationId);
}

export interface StudyData {
    courseUnit: CourseUnit;
    assessmentItem: AssessmentItem;
    courseUnitRealisation: CourseUnitRealisation;
}

@Injectable({ providedIn: 'root' })
export class ActiveStudiesService {

    private readonly assessmentItemService = inject(AssessmentItemEntityService);
    private readonly attainmentService = inject(AttainmentStudentService);
    private readonly courseUnitService = inject(CourseUnitEntityService);
    private readonly courseUnitRealisationService = inject(CourseUnitRealisationEntityService);
    private readonly enrolmentService = inject(EnrolmentStudentService);
    private readonly universityService = inject(UniversityService);
    private readonly studyYearService = inject(StudyYearsEntityService);

    private readonly validEnrolmentStates: EnrolmentState[] = ['ENROLLED', 'RESERVED', 'CONFIRMED'];

    getActiveStudies(): Observable<StudyData[]> {
        return combineLatest([
            this.enrolmentService.getAllEnrolments(),
            this.attainmentService.getMyValidAttainments(),
        ])
            .pipe(
                map(([enrolments, attainments]) => enrolments
                    .filter(enrolment => this.validEnrolmentStates.includes(enrolment.state))
                    .filter(enrolment => !hasAttainmentForCourseUnitRealisation(attainments, enrolment.courseUnitRealisationId)),
                ),
                switchMap(enrolments => combineLatest([
                    of(enrolments),
                    this.courseUnitRealisationService.getByIds(enrolments.map(enrolment => enrolment.courseUnitRealisationId)),
                    this.studyYearService.getCurrentStudyPeriod(this.universityService.getCurrentUniversityOrgId()),
                ])),
                switchMap(([enrolments, courseUnitRealisations, currentStudyPeriod]) => {
                    const currentPeriodCurs = courseUnitRealisations
                        .filter(cur => dateUtils.dateRangesOverlap(
                            cur.activityPeriod?.startDate,
                            cur.activityPeriod?.endDate,
                            currentStudyPeriod?.valid?.startDate,
                            currentStudyPeriod?.valid?.endDate,
                        ));
                    const currentPeriodEnrolments = enrolments.filter(({ courseUnitRealisationId }) => currentPeriodCurs
                        .some(cur => cur.id === courseUnitRealisationId));
                    return combineLatest([
                        of(currentPeriodEnrolments),
                        of(this.courseUnitRealisationService.sortByActivityPeriodAndName(currentPeriodCurs)),
                        this.assessmentItemService.getByIds(currentPeriodEnrolments.map(enrolment => enrolment.assessmentItemId)),
                        this.courseUnitService.getByIds(currentPeriodEnrolments.map(enrolment => enrolment.courseUnitId)),
                    ]);
                }),
                map(([enrolments, courseUnitRealisations, assessmentItems, courseUnits]) => courseUnitRealisations
                    .map(courseUnitRealisation => ({
                        courseUnitRealisation,
                        courseUnit: courseUnits.find(({ id }) => id === enrolments
                            .find(enrolment => enrolment.courseUnitRealisationId === courseUnitRealisation.id)?.courseUnitId),
                        assessmentItem: assessmentItems.find(({ id }) => id === enrolments
                            .find(enrolment => enrolment.courseUnitRealisationId === courseUnitRealisation.id)?.assessmentItemId),
                    }))),
            );
    }
}
