import { dateUtils } from 'common-typescript';
import { StudyTermLocator } from 'common-typescript/types';
import moment, { Moment } from 'moment';

export function getAutumnStudyTermStartDate(studyYearStartYear: number): Moment {
    return moment({ year: studyYearStartYear, month: 7, day: 1 }).startOf('day');
}

export function getSpringStudyTermStartDate(studyYearStartYear: number): Moment {
    return moment({ year: studyYearStartYear + 1, month: 0, day: 1 }).startOf('day');
}

export function getStudyTermStartDate(locator: StudyTermLocator): Moment {
    const { termIndex, studyYearStartYear: startYear } = locator ?? {};
    return termIndex === 0 ? getAutumnStudyTermStartDate(startYear) : getSpringStudyTermStartDate(startYear);
}

export function getStudyTermEndDate(locator: StudyTermLocator): Moment {
    const { termIndex, studyYearStartYear: startYear } = locator ?? {};
    return termIndex === 0 ? getSpringStudyTermStartDate(startYear) : getAutumnStudyTermStartDate(startYear + 1);
}

/**
 * Returns a {@link StudyTermLocator} for the study term that contains the given date. If the date is omitted, returns
 * the locator for the current study term.
 */
export function getStudyTermLocator(date: string | Moment | Date = moment()): StudyTermLocator | null {
    const parsedDate = dateUtils.createMoment(date);
    if (!parsedDate) {
        return null;
    }

    const autumnTerm = getAutumnStudyTermStartDate(parsedDate.year());
    const termIndex = parsedDate.isBefore(autumnTerm) ? 1 : 0;
    const studyYearStartYear = termIndex === 0 ? parsedDate.year() : parsedDate.year() - 1;
    return { studyYearStartYear, termIndex };
}

/**
 * Returns a locator for the next study term calculated from the given date. If the date is omitted, returns the locator
 * for the study term that comes after the current one.
 */
export function getNextStudyTermLocator(date: string | Moment | Date = moment()): StudyTermLocator | null {
    const parsedDate = dateUtils.createMoment(date);
    const currentTerm = getStudyTermLocator(date);

    if (!parsedDate || !currentTerm) {
        return null;
    }

    return {
        studyYearStartYear: parsedDate.year(),
        termIndex: (currentTerm.termIndex === 0 ? 1 : 0),
    };
}

/**
 * Returns the amount of study terms have elapsed between the two dates. The study term of {@code startDate} is included
 * in the count but the study term of {@code endDate} isn't. Returns null if either date is missing or invalid.
 */
export function getStudyTermsElapsedBetweenDates(startDate: string | Moment | Date, endDate: string | Moment | Date): number | null {
    const start = dateUtils.createMoment(startDate);
    const end = dateUtils.createMoment(endDate);
    if (!start || !end) {
        return null;
    }
    if (start.isSameOrAfter(endDate, 'day')) {
        return 0;
    }

    const startLocator = getStudyTermLocator(startDate);
    const endLocator = getStudyTermLocator(endDate);

    return (endLocator.studyYearStartYear - startLocator.studyYearStartYear) * 2 +
        (endLocator.termIndex - startLocator.termIndex);
}
