import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { OtmId, PrivatePersonBasicInfo } from 'common-typescript/types';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

import { EntityService } from './entity.service';

const CONFIG = {
    ENDPOINTS: {
        baseUrl: '/ori/api',
    },
};

@StaticMembers<DowngradedService>()
@Injectable({ providedIn: 'root' })
@NgEntityServiceConfig({
    baseUrl: CONFIG.ENDPOINTS.baseUrl,
    resourceName: 'persons-basic',
})
export class PrivatePersonBasicInfoEntityService extends EntityService<PrivatePersonBasicInfoState> {
    static downgrade: ServiceDowngradeMappings = {
        dependencies: [],
        moduleName: 'sis-components.service.privatePersonBasicInfoEntityService',
        serviceName: 'privatePersonBasicInfoEntityService',
    };

    constructor() {
        super(PrivatePersonBasicInfoStore, PrivatePersonBasicInfoQuery);
    }
}

type PrivatePersonBasicInfoState = EntityState<PrivatePersonBasicInfo, OtmId>;

@StoreConfig({ name: 'private-person-basic-info' })
class PrivatePersonBasicInfoStore extends EntityStore<PrivatePersonBasicInfoState> {}

class PrivatePersonBasicInfoQuery extends QueryEntity<PrivatePersonBasicInfoState> {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(store: EntityStore<PrivatePersonBasicInfoState>) {
        super(store);
    }
}
