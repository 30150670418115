<form>
  <div class="modal-header">
    <h2 class="modal-title" [tabIndex]="0">{{'PROFILE.CREATE_STUDY_CERTIFICATE_MODAL.TITLE' | translate}}</h2>
  </div>
  <div class="modal-body">
    <p class="sis-guidance">{{'PROFILE.CREATE_STUDY_CERTIFICATE_MODAL.LANGUAGE_GUIDANCE' | translate}}</p>
    <fieldset>
      <legend [class]="'sis-label ' + getLabelState()">{{'PROFILE.CREATE_STUDY_CERTIFICATE_MODAL.LANGUAGE' | translate}}</legend>
      <sis-checkbox *ngFor="let locale of availableLocales"
                    [(checked)]="selectedLocales[locale]"
                    [label]="[locale] | localeNames | async | localizedStringArray"
                    [name]="'locale-' + locale">
      </sis-checkbox>
    </fieldset>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary btn-hollow btn-lg" (click)="cancel()">
      {{'SIS_COMPONENTS.BUTTON.CANCEL' | translate}}
    </button>
    <button type="button" class="btn btn-primary btn-lg" (click)="createCertificate()">
      {{'PROFILE.CREATE_STUDY_CERTIFICATE_MODAL.CREATE' | translate}}
    </button>
  </div>
</form>
