import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import angular from 'angular';

@Component({
    selector: 'sis-classified-info-toggle',
    templateUrl: './classified-info-toggle.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ClassifiedInfoToggleComponent {

    /**
     * The $scope instance of the containing AngularJS component. Required when this component is used in an AngularJS
     * component; irrelevant otherwise. Without this the AngularJS change detection will not pick up the change of the toggle state
     * in this component.
     */
    @Input() scope: angular.IScope;
    /** Determines if the classified content is visible. If no value is given, the classified state is hidden as a default. */
    @Input() visible?: boolean;
    /** Optional aria-label for the toggle button. */
    @Input() ariaLabel?: string;
    /** Emits toggle state change  */
    @Output() toggle = new EventEmitter<void>();

    onToggle(): void {
        this.visible = !this.visible;

        if (this.scope) {
            this.scope.$apply(() => this.toggle.emit());
        } else {
            this.toggle.emit();
        }
    }
}
