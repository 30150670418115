'use strict';

(function () {
  ModuleNameCtrl.$inject = ["commonModuleService"];
  angular.module('sis-components.plan.moduleName', ['sis-common.l10n.localizedStringFilter', 'sis-components.service.moduleService']).component('moduleName', {
    template: '<span>{{::$ctrl.name | localizedString}}</span>',
    controller: ModuleNameCtrl,
    bindings: {
      moduleId: '<'
    }
  });

  /**
   * @ngInject
   */
  function ModuleNameCtrl(commonModuleService) {
    var $ctrl = this;
    $ctrl.module = undefined;
    $ctrl.$onChanges = function (changesObject) {
      if (changesObject.moduleId && !_.isNil(changesObject.moduleId.currentValue)) {
        commonModuleService.findById(changesObject.moduleId.currentValue).then(function (module) {
          $ctrl.name = _.get(module, 'name');
        });
      }
    };
  }
})();