<ng-container *transloco="let t">
  <div class="modal-header">
    <h2 class="modal-title" tabindex="-1" #headingElementRef>{{ t('STUDENT_CUSTOM_STUDY_DRAFT_MODAL.SUGGEST_ATTAINMENT_VIEW_TITLE') }}</h2>
    <button type="button"
            class="modal-close"
            (click)="close.emit()"
            [attr.aria-label]="t('ARIA_LABEL.CLOSE')">
      <sis-icon icon="ring-close"></sis-icon>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ t('STUDENT_CUSTOM_STUDY_DRAFT_MODAL.ATTAINMENT_TYPE_GUIDANCE', { customStudyDraftName: studyDraftName }) }}</p>
    <div class="form-group checkbox-group">
      <div *ngIf="!options.priorLearningInclusionApplicationHidden">
        <sis-radio-button [id]="'PriorLearningInclusionWorkflowOptionRadioButton'"
                          [control]="workflowOptionType"
                          [value]="'INCLUSION_APPLICATION'"
                          [label]="t('STUDENT_CUSTOM_STUDY_DRAFT_MODAL.PRIOR_LEARNING_INCLUSION_APPLICATION_OPTION_TITLE')">
        </sis-radio-button>
        <p class="help-block">{{ t('STUDENT_CUSTOM_STUDY_DRAFT_MODAL.PRIOR_LEARNING_INCLUSION_APPLICATION_OPTION_GUIDANCE', { homeUniversityName: homeUniversityName }) }}</p>
      </div>
      <div *ngIf="!options.customAttainmentApplicationHidden">
        <sis-radio-button [id]="'CustomAttainmentWorkflowOptionRadioButton'"
                          [control]="workflowOptionType"
                          [value]="'CUSTOM_ATTAINMENT_APPLICATION'"
                          [label]="t('STUDENT_CUSTOM_STUDY_DRAFT_MODAL.CUSTOM_ATTAINMENT_APPLICATION_OPTION_TITLE')">
        </sis-radio-button>
        <p class="help-block">{{ t('STUDENT_CUSTOM_STUDY_DRAFT_MODAL.CUSTOM_ATTAINMENT_APPLICATION_OPTION_GUIDANCE', {homeUniversityName: homeUniversityName}) }}</p>
      </div>
      <div *ngIf="!options.internshipCustomAttainmentApplicationHidden">
        <sis-radio-button [id]="'WorkPracticeCustomAttainmentWorkflowOptionRadioButton'"
                          [control]="workflowOptionType"
                          [value]="'INTERNSHIP_CUSTOM_ATTAINMENT_APPLICATION'"
                          [label]="t('STUDENT_CUSTOM_STUDY_DRAFT_MODAL.INTERNSHIP_CUSTOM_ATTAINMENT_APPLICATION_OPTION_TITLE')">
        </sis-radio-button>
        <p class="help-block">{{ t('STUDENT_CUSTOM_STUDY_DRAFT_MODAL.INTERNSHIP_CUSTOM_ATTAINMENT_APPLICATION_OPTION_GUIDANCE') }}</p>
      </div>
    </div>
    <p>{{ t('STUDENT_CUSTOM_STUDY_DRAFT_MODAL.APPROVED_APPLICATION_GUIDANCE') }}</p>
  </div>
  <div class="modal-footer">
    <sis-button [hollow]="true" (clicked)="back.emit()"
                [label]="t('BUTTON.CANCEL')"></sis-button>
    <sis-button (clicked)="selectWorkflowOperationType()"
                [label]="t('STUDENT_CUSTOM_STUDY_DRAFT_MODAL.BUTTON_CONTINUE')"></sis-button>
  </div>
</ng-container>
