import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { LocalizedString } from 'common-typescript/types';

@Component({
    selector: 'sis-checkbox-iconified',
    templateUrl: './checkbox-iconified.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxIconifiedComponent {
    /**
     * Optional `aria-labelledby` value to set on the checkbox `input` element.
     */
    @Input() ariaLabelledBy?: string;
    @Input() ariaRole?: string;
    @Input() disabled?: boolean = false;
    @Input() invalid: boolean;
    /**
     * Primary label shown beside the checkbox.
     */
    @Input() label: LocalizedString;
    /**
     * Optional secondary label shown beside the primary label with less emphasis.
     */
    @Input() secondaryLabel?: LocalizedString;
    @Input() name: string;
    @Input() required: boolean;
    @Input() checked?: boolean = false;
    @Input() indeterminate?: boolean = false;
    @Input() id: string;
    @Input() value?: string;
    @Input() labelClasses?: string[] = [];
    @Input() controlClasses?: string[] = [];
    @Input() code?: string;

    @Output() checkedChange = new EventEmitter<boolean>();

    get ariaChecked() {
        if (!this.checked && this.indeterminate) {
            return 'mixed';
        }
        return this.checked ? 'true' : 'false';
    }

    toggle() {
        if (this.disabled) {
            return;
        }
        this.checked = !this.checked;
        this.checkedChange.emit(this.checked);
    }
}
