import { Observable, Subscriber } from 'rxjs';
import { take } from 'rxjs/operators';

/**
 * Mono class represents an {@link Observable}, which emits only the first value from the source Observable and then completes.
 */
export class Mono<T> extends Observable<T> {
    readonly isMono = true; // prevents direct Observable to Mono assignment

    /**
     * @param source Observable from which only the first value will be taken when this Mono is subscribed to.
     * Helper function {@link mono}, should be preferred over calling this constructor directly.
     */
    constructor(source: Observable<T>) {
        super((subscriber: Subscriber<T>) => {
            source.pipe(take(1)).subscribe(subscriber);
        });
    }
}

/**
 * Helper function for constructing a {@link Mono} instance.
 *
 * @param source Observable from which only the first value will be taken when the resulting Mono is subscribed to.
 */
export function mono<T>(source: Observable<T>): Mono<T> {
    return new Mono(source);
}
