import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { StateService } from '@uirouter/core';
import { CourseUnit, OpenUniversityCart, UniversityConfig } from 'common-typescript/types';
import { AuthService } from 'sis-common/auth/auth-service';
import { ConfigService } from 'sis-common/config/config.service';

@Component({
    selector: 'app-open-university-studies-activate-wizard-step1',
    templateUrl: './open-university-studies-activate-wizard-step1.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class OpenUniversityStudiesActivateWizardStep1Component implements OnInit {

    @Input() wizardProgressStepKeys: string[] = [];
    @Input() courseUnits: CourseUnit[];
    @Input() openUniversityCart: OpenUniversityCart;
    copiedLink = false;
    isLoggedIn: boolean;
    universityConfig: UniversityConfig;

    constructor(private stateService: StateService,
                private configService: ConfigService,
                private authService: AuthService) { }

    ngOnInit(): void {
        // Disable suomi.fi login option when user is logged in with sisu credentials.
        this.isLoggedIn = this.authService.loggedIn();

        this.universityConfig = this.configService.get();
    }

    suomiFiLogin() {
        const targetView = `${window.location.href}-authenticated-suomi-fi`;

        /*
        When "suomiFiShibbolethLoginUrl" exists we do suomi.fi authentication trough shibboleth service.
        "dev" environment does not use shibboleth so we go instantly to targetView.
         */
        window.location.href = this.universityConfig.suomiFiShibbolethLoginUrl ?
            `${this.universityConfig.suomiFiShibbolethLoginUrl}?target=${targetView}` :
            targetView;
    }

    universityLogin() {
        this.stateService.go('student.logged-in.open-university-studies-activate',
                             {
                                 openUniversityCartId: this.openUniversityCart.id,
                                 activationCode: this.openUniversityCart.activationCode,
                             },
                             { custom: { customTargetPath: `${window.location.href}-authenticated`, skipConfirmationDialog: true } });
    }

    copyLink() {
        window.navigator.clipboard.writeText(window.location.href);
        this.copiedLink = true;

        setTimeout(() => this.copiedLink = false, 1500);
    }
}
