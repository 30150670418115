<div class="form-group-mimic">
  <div role="heading" class="sis-label" [attr.aria-level]="level">{{ 'SIS_COMPONENTS.COURSE_UNIT_REALISATION_INFO.SEATS' | translate }}</div>
  <p *ngIf="maxSelected" class="form-control-static">
    {{ maxSelected }}
  </p>
  <p *ngIf="!maxSelected" class="form-control-static">
    {{ 'COMMON.NOT_DEFINED' | translate }}
  </p>
  <ng-container *ngIf="selectedUsersQuotas.length > 0 && maxSelected">
    <div *ngFor="let quota of selectedUsersQuotas">
      <p>{{ quota.name | localizedString }}</p>
      <ul>
        <li>
          <p class="form-control-static">
            <transloco [content]="'SIS_COMPONENTS.COURSE_UNIT_REALISATION_INFO.SEATS_MINIMUM' | translate: { seats: quota.size }" />
        </li>
      </ul>
    </div>
  </ng-container>
  <ng-container *ngIf="maximumUsersQuotas.length > 0 && maxSelected">
    <div *ngFor="let quota of maximumUsersQuotas">
      <p>{{ quota.name | localizedString }}</p>
      <ul>
        <li>
          <p class="form-control-static">
            <transloco [content]="'SIS_COMPONENTS.COURSE_UNIT_REALISATION_INFO.SEATS_MAXIMUM' | translate: { seats: quota.size }" />
        </li>
      </ul>
    </div>
  </ng-container>
</div>
