import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import {
    Code,
    CodeBook,
    CodeUniversityUsage,
    OtmId,
    UniversityCodeBookSettings,
    Urn,
} from 'common-typescript/types';
import _ from 'lodash';
import { forkJoin, lastValueFrom, Observable, of } from 'rxjs';
import { mergeMap, shareReplay, take, tap } from 'rxjs/operators';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

import { UniversityService } from '../service/university.service';

import { EntityService } from './entity.service';

const CONFIG = {
    ENDPOINTS: {
        backend: '/kori/api/cached',
        cachedCodebook(urn: string) {
            return `/kori/api/cached/codebooks/${urn}`;
        },
        codebook(urn: string) {
            return `/kori/api/codebooks/${urn}`;
        },
        customCodebookByClassification(classificationScopeUrn: string, universityOrgId: string) {
            return `/kori/api/custom-codebooks/${classificationScopeUrn}?universityOrgId=${universityOrgId}`;
        },
        codebookUniversityUsage(urn: string, universityOrgId: string) {
            return `/kori/api/codebooks/${urn}/code-usage/${universityOrgId}`;
        },
        codeUniversityUsage(universityOrgId: string) {
            return `/kori/api/codes/usage/${universityOrgId}`;
        },
        groupedCodes(urn: string, universityOrgId: string) {
            return `/kori/api/codebooks/${urn}/grouped-codes/${universityOrgId}`;
        },
    },
};

/**
 * This service could be names CodeEntityService because it extends EntityService but unfortunately renaming was a bit too large task to be
 * included in the ticket scope.
 */
@StaticMembers<DowngradedService>()
@Injectable({ providedIn: 'root' })
@NgEntityServiceConfig({
    baseUrl: CONFIG.ENDPOINTS.backend,
    resourceName: 'codes',
})
export class CommonCodeService extends EntityService<CodeState> {

    constructor(private universityService: UniversityService) {
        super(CodeStore, CodeQuery);
    }

    static downgrade: ServiceDowngradeMappings = {
        moduleName: 'sis-components.service.codeService',
        serviceName: 'commonCodeService',
    };

    private readonly universityUsageMap: Map<string, Observable<Urn[]>> = new Map();
    private readonly codeBookMap: Map<string, Observable<IndexedCodes>> = new Map();
    private readonly groupMap: Map<string, Observable<GroupedCodes>> = new Map();
    // These are codebooks with over 1000 codes.
    private readonly largeCodebooks = new Set([
        'urn:code:education-classification',
        'urn:code:educational-institution',
        'urn:code:international-institution',
    ]);

    getCodebook(urn: string): Promise<IndexedCodes> {
        this.setCodebookUrn(urn);
        return lastValueFrom(this.codeBookMap.get(urn));
    }

    getCodebookObservable(urn: string): Observable<IndexedCodes> {
        this.setCodebookUrn(urn);
        return this.codeBookMap.get(urn);
    }

    private setCodebookUrn(urn: string) {
        if (!this.codeBookMap.has(urn)) {
            const codebookObservable = (this.getHttp().get(CONFIG.ENDPOINTS.cachedCodebook(urn)) as Observable<IndexedCodes>)
                .pipe(
                    tap(indexedCodes => this.store.upsertMany(Object.values(indexedCodes))),
                    shareReplay(1),
                );
            this.codeBookMap.set(urn, codebookObservable);
        }
    }

    getCode(urn: string): Promise<Code> {
        const urnPrefix = this.getUrnPrefix(urn);
        if (!urnPrefix) {
            return Promise.resolve(undefined);
        }
        if (this.largeCodebooks.has(urnPrefix)) {
            return lastValueFrom(this.getById(urn).pipe(take(1)));
        }
        return this.getCodebook(urnPrefix)
            .then(indexCodes => indexCodes[urn]);
    }

    getCodesByUrns(urns: string[]): Promise<Code[]> {
        return Promise.all(_.uniq(urns).map(urn => this.getCode(urn)))
            .then(codes => _.compact(codes));
    }

    /**
     * This makes ONE request for urn for single set of concurrent calls. In other words: results are not cached but concurrent calls do
     * not create multiple http calls. This method could cache response values but the usage values can be modified on ui so caching may be
     * dangerous.
     */
    getCodeBookUniversityUsage(urn: string): Promise<Urn[]> {
        const url = CONFIG.ENDPOINTS.codebookUniversityUsage(urn, this.universityService.getCurrentUniversityOrgId());
        if (!this.universityUsageMap.has(url)) {
            this.universityUsageMap.set(
                url,
                this.getHttp().get<Urn[]>(url)
                    .pipe(
                        shareReplay(1),
                        tap(() => this.universityUsageMap.delete(url)),
                    ),
            );
        }
        return lastValueFrom(this.universityUsageMap.get(url));
    }

    getGroupedCodes(urn: string): Promise<GroupedCodes> {
        return lastValueFrom(this.getGroupedCodesObservable(urn));
    }

    getGroupedCodesObservable(urn: string): Observable<GroupedCodes> {
        const url = CONFIG.ENDPOINTS.groupedCodes(urn, this.universityService.getCurrentUniversityOrgId());
        if (!this.groupMap.has(url)) {
            this.groupMap.set(
                url,
                this.getHttp().get<GroupedCodes>(url)
                    .pipe(
                        shareReplay(1),
                        tap(() => this.groupMap.delete(url)),
                    ),
            );
        }
        return this.groupMap.get(url);
    }

    getCodeUniversityUsage(): Promise<CodeUniversityUsage> {
        const url = CONFIG.ENDPOINTS.codeUniversityUsage(this.universityService.getCurrentUniversityOrgId());
        return lastValueFrom(this.getHttp().get(url) as Observable<CodeUniversityUsage>);
    }

    /**
     * @deprecated Prefer use of observable returning getCustomCodebooksByClassificationScopeUrnObservable
     * @param classificationScopeUrn
     */
    getCustomCodebooksByClassificationScopeUrn(classificationScopeUrn: string): Promise<CodeBook[]> {
        return lastValueFrom(
            this.getCustomCodebooksByClassificationScopeUrnObservable(classificationScopeUrn));
    }

    getCustomCodebooksByClassificationScopeUrnObservable(classificationScopeUrn: string): Observable<CodeBook[]> {
        const universityOrgId = this.universityService.getCurrentUniversityOrgId();
        const url = CONFIG.ENDPOINTS.customCodebookByClassification(classificationScopeUrn, universityOrgId);
        return this.getHttp().get<Urn[]>(url)
            .pipe(
                mergeMap((codebookUrns: Urn[]) => codebookUrns.length > 0 ?
                    forkJoin(codebookUrns.map(codebookUrn => this.httpGetCodebook(codebookUrn))) :
                    of([])),
            );
    }

    private httpGetCodebook(codebookUrn: Urn): Observable<CodeBook> {
        return this.getHttp().get(CONFIG.ENDPOINTS.codebook(codebookUrn)) as Observable<CodeBook>;
    }

    private getUrnPrefix(urn: string): string {
        const parts = _.split(urn, ':');
        return _.join(_.take(parts, Math.min(parts.length - 1, 3)), ':');
    }
}

type CodeState = EntityState<Code, OtmId>;

@StoreConfig({ name: 'codes', idKey: 'urn' })
class CodeStore extends EntityStore<CodeState> {}

class CodeQuery extends QueryEntity<CodeState> {
    constructor(protected store: CodeStore) {
        super(store);
    }
}

export interface IndexedCodes {
    [index: string]: Code;
}

export interface GroupedCodes {
    universityCodes: IndexedCodes;
    otherCodes: IndexedCodes;
    hideOtherCodes: boolean;
}
