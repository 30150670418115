import angular from 'angular';
import { TabNavigationComponent } from 'sis-components/tabs/tab-navigation/tab-navigation.component';
import calendarTpl from './calendar.tpl.html';
import { FullCalendarComponent } from './full-calendar/full-calendar.component';
import { calendarSettingsModalServiceModule } from "sis-components/calendar/dialog/calendarSettingsModal.service";
(function () {
  calendarController.$inject = ["$scope"];
  angular.module('student.calendar', [TabNavigationComponent.downgrade.moduleName, FullCalendarComponent.downgrade.moduleName, calendarSettingsModalServiceModule]).component('calendar', {
    template: calendarTpl,
    bindings: {
      enrolments: '<',
      getValidatablePlan: '<'
    },
    controller: calendarController
  });

  /**
   * @ngInject
   */
  function calendarController($scope) {
    const $ctrl = this;
    $ctrl.tabs = [{
      path: 'student.logged-in.calendar.not-enrolled',
      title: 'UNSCHEDULED_COURSE_UNITS'
    }, {
      path: 'student.logged-in.calendar.enrolments',
      title: 'COURSE_UNIT_REALISATIONS'
    }, {
      path: 'student.logged-in.calendar.aborted-and-finished-enrolments',
      id: 'aborted-finished-enrolments-tab',
      title: 'FINISHED_AND_ABORTED_ENROLMENTS'
    }];
    $ctrl.isRightBarOpen = false;
    $ctrl.toggleRightBar = function () {
      $ctrl.isRightBarOpen = !$ctrl.isRightBarOpen;
      $scope.$apply();
    };
    $ctrl.openRightBar = function () {
      $ctrl.isRightBarOpen = true;
    };
    $ctrl.closeRightBar = function () {
      $ctrl.isRightBarOpen = false;
    };
  }
})();