import angular from 'angular';
export const commonCurriculumPeriodModelModule = 'sis-components.model.curriculumPeriod';
(function () {
  curriculumPeriodModel.$inject = ["DS"];
  angular.module(commonCurriculumPeriodModelModule, ['js-data']).factory('curriculumPeriodModel', curriculumPeriodModel);

  /**
   * @ngInject
   */
  function curriculumPeriodModel(DS) {
    return DS.defineResource({
      endpoint: '/kori/api/curriculum-periods',
      idAttribute: 'id',
      name: 'curriculumPeriod',
      watchChanges: false
    });
  }
})();