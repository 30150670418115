<sis-expandable *ngIf="attainment"
                [closed]="false"
                [title]="'STUDENT_APPLICATIONS.MODULE_ATTAINMENT_APPLICATION_ATTAINMENT.MODULE_COMPLETION_INFO_TITLE'|translate">
  <ng-template sisExpandableContent>
    <sis-module-attainment
      *ngIf="!attainment.misregistration"
      [attainment]="attainment"
      [attainments]="attainments"
      [viewOptions]="viewOptions">
    </sis-module-attainment>
    <div *ngIf="attainment.misregistration" translate>
      PROFILE.ATTAINMENT.MISREGISTERED_ATTAINMENT
    </div>
  </ng-template>
</sis-expandable>
