import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ValidatablePlan } from 'common-typescript/src/plan/validation/validatablePlan';
import { CourseUnit, CurriculumPeriod, LocalizedString, Organisation, OtmId, Plan } from 'common-typescript/types';
import { catchError, combineLatest, from, map, merge, Observable, of, shareReplay, Subject, switchMap } from 'rxjs';
import { take } from 'rxjs/operators';
import { DEFAULT_PROMISE_HANDLER } from 'sis-common/ajs-upgraded-modules';
import { ModalService } from 'sis-common/modal/modal.service';
import { COMMON_PLAN_SERVICE } from 'sis-components/ajs-upgraded-modules';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { CurriculumPeriodEntityService } from 'sis-components/service/curriculum-period-entity.service';
import { CurriculumPeriodNameService } from 'sis-components/service/curriculum-period-name.service';
import { OrganisationEntityService } from 'sis-components/service/organisation-entity.service';
import { convertAJSPromiseToNative } from 'sis-components/util/utils';

import { COURSE_UNIT_INFO_MODAL } from '../../ajs-upgraded-modules';
import { StudentPlanOutdatedCourseUnitsService } from '../../common/service/student-plan-outdated-course-units.service';

import { OutdatedCourseUnitsModalValues } from './outdated-course-units-modal.service';

interface OutdatedCourseUnitsModalData {
    courseUnits: [CourseUnit, CourseUnit][];
    validatablePlan: ValidatablePlan;
    curriculumPeriodNamesByCuId: { [cuId: string]: LocalizedString[] };
}

// This component is used to display a modal that shows the outdated course units in the student plan.
// TODO: This component should be refactored to use validatablePlanSubject when it is available.
@Component({
    selector: 'app-outdated-course-units-modal',
    templateUrl: './outdated-course-units-modal.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OutdatedCourseUnitsModalComponent implements OnInit {

    updateSubject$ = new Subject<void>();
    data$: Observable<OutdatedCourseUnitsModalData>;

    constructor(@Inject(ModalService.injectionToken) public values: OutdatedCourseUnitsModalValues,
                @Inject(COURSE_UNIT_INFO_MODAL) private courseUnitInfoModal: any,
                @Inject(DEFAULT_PROMISE_HANDLER) private defaultPromiseHandler: any,
                @Inject(COMMON_PLAN_SERVICE) private commonPlanService: any,
                private organisationEntityService: OrganisationEntityService,
                private studentPlanOutdatedCourseUnitsService: StudentPlanOutdatedCourseUnitsService,
                private curriculumPeriodNameService: CurriculumPeriodNameService,
                private curriculumPeriodEntityService: CurriculumPeriodEntityService,
                private appErrorHandlingService: AppErrorHandler,
                public modal: NgbActiveModal) {
    }

    ngOnInit(): void {
        const { courseUnits, validatablePlan } = this.values;
        this.data$ = this.createDataObservable(courseUnits, validatablePlan, this.updateSubject$);
    }

    createDataObservable(originalCourseUnits: [CourseUnit, CourseUnit][],
                         originalValidatablePlan: ValidatablePlan,
                         update$: Subject<void>): Observable<OutdatedCourseUnitsModalData> {
        const rootOrganisations$ = this.createRootOrganisationsObservable()
            .pipe(shareReplay({ bufferSize: 1, refCount: true }));
        const validatablePlanUpdate$ = this.createValidatablePlanUpdateObservable(update$)
            .pipe(shareReplay({ bufferSize: 1, refCount: true }));
        // First value is the initial value, then we listen to updates
        const validatablePlan$ = merge(
            of(originalValidatablePlan),
            validatablePlanUpdate$,
        );
        const outdatedCourseUnitUpdate$ = this.createOutdatedCourseUnitsUpdateObservable(validatablePlanUpdate$);
        // Same thing here
        const outdatedCourseUnits$: Observable<[CourseUnit, CourseUnit][]> = merge(
            of(originalCourseUnits),
            outdatedCourseUnitUpdate$,
        ).pipe(shareReplay({ bufferSize: 1, refCount: true }));
        const curriculumPeriodNamesByCuId$ =
            this.createCurriculumPeriodNamesByCuIdObservable(outdatedCourseUnits$, rootOrganisations$);

        return combineLatest([
            validatablePlan$,
            outdatedCourseUnits$,
            curriculumPeriodNamesByCuId$,
        ]).pipe(
            map(([vPlan, outdatedCourseUnits, curriculumPeriodNamesByCuId]) => ({
                courseUnits: outdatedCourseUnits,
                validatablePlan: vPlan,
                curriculumPeriodNamesByCuId,
            })));
    }

    createCurriculumPeriodNamesByCuIdObservable(outdatedCourseUnits$: Observable<[CourseUnit, CourseUnit][]>, rootOrganisations$: Observable<Organisation[]>): Observable<{ [cuId: string]: LocalizedString[] }> {
        return combineLatest([outdatedCourseUnits$, rootOrganisations$])
            .pipe(
                switchMap(([outdatedCourseUnits, rootOrganisations]) => {
                    const cuIds = new Set<OtmId>();
                    outdatedCourseUnits.forEach(([cu1, cu2]) => {
                        cu1.curriculumPeriodIds.forEach((id) => cuIds.add(id));
                        cu2.curriculumPeriodIds.forEach((id) => cuIds.add(id));
                    });
                    return this.curriculumPeriodEntityService.getByIds(Array.from(cuIds)).pipe(
                        take(1),
                        map((curriculumPeriods) => {
                            const curriculumPeriodsById = new Map<OtmId, CurriculumPeriod>();
                            curriculumPeriods.forEach((curriculumPeriod) => {
                                curriculumPeriodsById.set(curriculumPeriod.id, curriculumPeriod);
                            });
                            const curriculumPeriodNamesByCuId: { [cuId: string]: LocalizedString[] } = {};
                            outdatedCourseUnits.forEach(([cu1, cu2]) => {
                                const cu1Curs = cu1.curriculumPeriodIds.map((id) => curriculumPeriodsById.get(id));
                                const cu2Curs = cu2.curriculumPeriodIds.map((id) => curriculumPeriodsById.get(id));
                                const cu1Names = this.curriculumPeriodNameService.getCurriculumPeriodNamesWithRootOrganisationAbbreviations(cu1Curs, rootOrganisations);
                                const cu2Names = this.curriculumPeriodNameService.getCurriculumPeriodNamesWithRootOrganisationAbbreviations(cu2Curs, rootOrganisations);
                                curriculumPeriodNamesByCuId[cu1.id] = cu1Names;
                                curriculumPeriodNamesByCuId[cu2.id] = cu2Names;
                            });
                            return curriculumPeriodNamesByCuId;
                        }),
                        this.appErrorHandlingService.defaultErrorHandler());
                }),
            );
    }

    createRootOrganisationsObservable(): Observable<Organisation[]> {
        return this.organisationEntityService.getRootOrganisations()
            .pipe(this.appErrorHandlingService.defaultErrorHandler());
    }

    createValidatablePlanUpdateObservable(update$: Subject<void>): Observable<ValidatablePlan> {
        return update$.pipe(
            switchMap(() => this.getUpdatedValidatablePlan()),
        );
    }

    createOutdatedCourseUnitsUpdateObservable(validatablePlanUpdate$: Observable<ValidatablePlan>): Observable<[CourseUnit, CourseUnit][]> {
        return validatablePlanUpdate$.pipe(
            switchMap((validatablePlanUpdate: ValidatablePlan) =>
                this.studentPlanOutdatedCourseUnitsService.checkLatestCourseUnitsAreInSelectedPlan(validatablePlanUpdate)
                    .pipe(
                        take(1),
                        this.appErrorHandlingService.defaultErrorHandler(),
                    ),
            ),
        );
    }

    openCourseUnitInfoModal(courseUnit: CourseUnit, data: OutdatedCourseUnitsModalData): void {
        this.courseUnitInfoModal.showCourseUnit(
            courseUnit.id,
            data.validatablePlan,
        ).then((curModified: boolean) => {
            // if the course unit was modified somehow, we need to get updated validatablePlan and outdatedCourseUnits
            if (curModified) {
                this.updateSubject$.next();
            }
        }).catch(this.defaultPromiseHandler.loggingRejectedPromiseHandler);
    }

    getUpdatedValidatablePlan(): Observable<ValidatablePlan> {
        return from(convertAJSPromiseToNative(this.commonPlanService.findById(this.values.validatablePlan.plan.id).then((plan: Plan) => this.commonPlanService.getValidatablePlan(plan))))
            .pipe(catchError((err) => {
                this.appErrorHandlingService.handleError(err);
                return of(null);
            })) as Observable<ValidatablePlan>;
    }
}
