import angular from 'angular';
import _ from 'lodash';
import { codeBookJSDataModelModule } from 'sis-components/model/codebook.model';
import customCodeValuesTpl from './customCodeValues.tpl.html';
export const customCodeValuesModule = 'sis-components.codes.customCodeValues';
(function () {
  customCodeValuesController.$inject = ["$q", "codebookJSDataModel", "defaultPromiseHandler"];
  angular.module('sis-components.codes.customCodeValues', [codeBookJSDataModelModule]).component('customCodeValues', {
    bindings: {
      title: '<?',
      customCodeUrns: '<',
      language: '<?'
    },
    controller: customCodeValuesController,
    template: customCodeValuesTpl
  });
  function customCodeValuesController($q, codebookJSDataModel, defaultPromiseHandler) {
    const $ctrl = this;
    $ctrl.$onInit = function () {
      $ctrl.codeBookObjects = {};
      const promises = [];
      _.forEach(_.keys($ctrl.customCodeUrns), parentUrn => {
        promises.push(codebookJSDataModel.find(parentUrn).then(codeBook => {
          $ctrl.codeBookObjects[parentUrn] = codeBook;
        }));
      });
      $q.all(promises).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    };
    $ctrl.getParentUrnName = parentUrn => {
      const codeBookObject = _.get($ctrl.codeBookObjects, parentUrn);
      return _.get(codeBookObject, 'name');
    };
    $ctrl.getChildUrnName = (parentUrn, childUrn) => {
      const codeBookObject = _.get($ctrl.codeBookObjects, parentUrn);
      const childCodeObject = _.find(_.get(codeBookObject, 'codes'), {
        urn: childUrn
      });
      return _.get(childCodeObject, 'name');
    };
  }
})();