'use strict';

(function () {
  angular.module('sis-components.plan.elementSelect', []).component('elementSelect', {
    transclude: true,
    template: '<ng-transclude></ng-transclude>',
    controller: ElementSelectCtrl,
    bindings: {
      selectionClass: '@',
      onSelect: '&',
      config: '<'
    }
  }).directive('selectableElement', function () {
    return {
      require: '^^elementSelect',
      scope: false,
      link: function (scope, elem, attr, elementSelectCtrl) {
        elementSelectCtrl.elements.push(elem);
        var selectionObject = scope.$eval(attr.selectionObject);
        elem.on('click.selection', function ($event) {
          if (!$event.isDefaultPrevented() && !scope.$eval(attr.selectionDisabled)) {
            elementSelectCtrl.selected(elem, selectionObject);
          }
        });
        if (_.isEqual(_.get(elementSelectCtrl, 'config.defaultSelection'), selectionObject)) {
          elementSelectCtrl.showSelection(elem);
        }
        scope.$on('$destroy', function () {
          elem.off('click.selection');
        });
      }
    };
  });

  /**
   * @ngInject
   */
  function ElementSelectCtrl() {
    var $ctrl = this;
    $ctrl.elements = [];
    $ctrl.showSelection = function (selectedElement) {
      _.forEach($ctrl.elements, function (element) {
        element.removeClass($ctrl.selectionClass);
      });
      selectedElement.addClass($ctrl.selectionClass);
    };
    $ctrl.selected = function (selectedElement, selectionObject) {
      $ctrl.showSelection(selectedElement);
      $ctrl.onSelect({
        selectionObject: selectionObject
      });
      $ctrl.config.defaultSelection = selectionObject;
    };
  }
})();