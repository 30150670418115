import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { Grant, OtmId } from 'common-typescript/types';
import { Observable, throwError } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { EntityService } from './entity.service';

const CONFIG = {
    ENDPOINTS: {
        backend: '/ori/api',
        forPerson(personId: OtmId): string {
            return `${this.backend}/grants/for-person/${personId}`;
        },
        forStudyRight(studyRightId: OtmId): string {
            return `${this.backend}/grants/for-study-right/${studyRightId}`;
        },
        get createGrants(): string {
            return `${this.backend}/grants-collection`;
        },
    },
};

@Injectable({ providedIn: 'root' })
@NgEntityServiceConfig({ baseUrl: CONFIG.ENDPOINTS.backend })
export class GrantEntityService extends EntityService<GrantsState> {

    constructor() {
        super(GrantStore, GrantQuery);
    }

    findForPerson(personId: OtmId): Observable<Grant[]> {
        return this.getHttp().get<Grant[]>(CONFIG.ENDPOINTS.forPerson(personId))
            .pipe(
                tap(grants => this.store.upsertMany(grants)),
                switchMap(() => this.selectAll(({ filterBy: grant => grant.personId === personId }))),
            );
    }

    findForStudyRight(studyRightId: OtmId): Observable<Grant[]> {
        return this.getHttp().get<Grant[]>(CONFIG.ENDPOINTS.forStudyRight(studyRightId))
            .pipe(
                tap(grants => this.store.upsertMany(grants)),
                switchMap(() => this.selectAll({ filterBy: grant => grant.studyRightId === studyRightId })),
            );
    }

    getById(): Observable<never> {
        return throwError(() => new Error('Fetching grants by id is not supported'));
    }

    getByIds(): Observable<never> {
        return throwError(() => new Error('Fetching grants by id is not supported'));
    }

    createGrants(grants: Partial<Grant>[]): Observable<Grant[]> {
        return this.getHttp().post<Grant[]>(CONFIG.ENDPOINTS.createGrants, grants)
            .pipe(
                tap(savedGrants => this.store.upsertMany(savedGrants)),
                switchMap((savedGrants) => {
                    const ids = savedGrants.map(grant => grant.id);
                    return this.selectAll({ filterBy: grant => ids.includes(grant.id) });
                }),
            );
    }
}

type GrantsState = EntityState<Grant, OtmId>;

@StoreConfig({ name: 'grants' })
class GrantStore extends EntityStore<GrantsState> {}

class GrantQuery extends QueryEntity<GrantsState> {
    constructor(protected store: GrantStore) {
        super(store);
    }
}
