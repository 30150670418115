<ul class="no-list-style-type">
  <li *ngFor="let extension of studyRightExtensions" class="sis-mb-sm">
    {{ extension.extensionCount }}
    {{ 'PROFILE.EXTENSIONS.' + (extension.extensionCount === 1 ? 'SEMESTER' : 'SEMESTERS') | translate }},
    {{ extension|studyRightExtensionValidity|localDateRange }}
    <sis-button *ngIf="showShowDetails" [link]="true" (clicked)="showDetails.emit(extension)"
                class="d-block details-button">
      {{ 'PROFILE.EXTENSIONS.SHOW_DETAILS' | translate }}
    </sis-button>
  </li>
</ul>
