import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SisCommonModule } from 'sis-common/sis-common.module';
import { SisComponentsModule } from 'sis-components/sis-components.module';

import { StudentCommonModule } from '../../common/common.module';

import {
    CourseUnitRealisationExpandableComponent,
} from './course-unit-realisation-expandable/course-unit-realisation-expandable.component';
import { EnrolmentPeriodBadgeComponent } from './enrolment-period-badge/enrolment-period-badge.component';
import { EnrolmentStatusBadgeComponent } from './enrolment-status-badge/enrolment-status-badge.component';
import { OpenUniversityEnrolmentModalComponent } from './open-university-enrolment-modal/open-university-enrolment-modal.component';

@NgModule({
    declarations: [
        CourseUnitRealisationExpandableComponent,
        EnrolmentPeriodBadgeComponent,
        EnrolmentStatusBadgeComponent,
        OpenUniversityEnrolmentModalComponent,
    ],
    imports: [
        CommonModule,
        SisCommonModule,
        SisComponentsModule,
        StudentCommonModule,
        TranslateModule.forChild(),
    ],
    exports: [
        OpenUniversityEnrolmentModalComponent,
    ],
})
export class OpenUniversityEnrolmentModule {}
