'use strict';

(function () {
  range.$inject = ["Range", "$filter", "$log", "RangeType"];
  angular.module('sis-components.rangeFilter', ['student.shared']).filter('range', range);
  function range(Range, $filter, $log, RangeType) {
    return function (input, numDecimalsIfNotInteger) {
      if (input) {
        numDecimalsIfNotInteger = numDecimalsIfNotInteger === undefined ? 1 : numDecimalsIfNotInteger;
        var range = Range.cast(input);
        switch (range.getType()) {
          case RangeType.ONE:
          case RangeType.EXACTLY:
            return '' + roundIfNecessary(range.min);
          case RangeType.MAX:
            return '≤ ' + roundIfNecessary(range.max);
          case RangeType.BETWEEN:
            return roundIfNecessary(range.min) + '–' + roundIfNecessary(range.max);
          case RangeType.MIN:
            return '≥ ' + roundIfNecessary(range.min);
          default:
            $log.error('Invalid RangeType: ' + range.getType());
            throw new Error('Invalid RangeType: ' + range.getType());
        }
      } else {
        return '';
      }
      function roundIfNecessary(value) {
        if (_.isInteger(value)) {
          return value;
        } else {
          return $filter('number')(value, numDecimalsIfNotInteger);
        }
      }
    };
  }
})();