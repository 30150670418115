<ng-container *transloco="let t">
  <div class="modal-header">
    <button type="button"
            class="modal-close"
            (click)="close()"
            [attr.aria-label]="t('ARIA_LABEL.CLOSE')">
      <sis-icon icon="ring-close"></sis-icon>
    </button>
    <h2>{{t('STUDY_RIGHT_EXTENSION_REQUIREMENTS_MODAL.TITLE')}}</h2>
  </div>
  <div class="modal-body">
    <div *ngIf="isNoPrimaryPlanInfoVisible && educationId | education | async as education">
      <p>
        <transloco key="STUDY_RIGHT_EXTENSION_REQUIREMENTS_MODAL.PRIMARY_PLAN_NEEDED" [params]="{ educationname: education.name | localizedString }" />
      </p>
    </div>
    <div *ngIf="isNotPlannedInfoVisible">
      <p>
        <transloco key="STUDY_RIGHT_EXTENSION_REQUIREMENTS_MODAL.PLAN_HAS_TO_BE_IN_PLANNED_STATE" />
      </p>
      <p>
        <transloco key="STUDY_RIGHT_EXTENSION_REQUIREMENTS_MODAL.TIMING_INSTRUCTIONS" />
      </p>
    </div>
    <div *ngIf="isUntimedStudiesInfoVisible">
      <p *ngIf="untimedCourseUnits">
        <transloco key="STUDY_RIGHT_EXTENSION_REQUIREMENTS_MODAL.UNTIMED_COURSE_UNITS" />
      </p>
      <p *ngIf="untimedCustomStudyDrafts">
        <transloco key="STUDY_RIGHT_EXTENSION_REQUIREMENTS_MODAL.UNTIMED_CUSTOM_STUDY_DRAFTS" />
      </p>
    </div>
    <div *ngIf="isStudiesTimedInThePastInfoVisible">
      <p *ngIf="courseUnitsTimedInThePast">
        <transloco key="STUDY_RIGHT_EXTENSION_REQUIREMENTS_MODAL.COURSE_UNITS_WITH_TIMING_IN_THE_PAST" />
      </p>
      <p *ngIf="customStudyDraftsTimedInThePast">
        <transloco key="STUDY_RIGHT_EXTENSION_REQUIREMENTS_MODAL.CUSTOM_STUDY_DRAFTS_WITH_TIMING_IN_THE_PAST" />
      </p>
    </div>
  </div>

  <div class="modal-footer">
    <button *ngIf="isCreatePlanButtonVisible"
            type="button" (click)="createPlan()" class="btn btn-primary btn-hollow btn-sm">
      <span>
        {{t('STUDY_RIGHT_EXTENSION_REQUIREMENTS_MODAL.CREATE_PLAN')}}
      </span>
    </button>
    <button *ngIf="isProceedToPlanButtonVisible"
            type="button" (click)="proceedToPlan()" class="btn btn-primary btn-hollow btn-sm">
      <span>
        {{t('STUDY_RIGHT_EXTENSION_REQUIREMENTS_MODAL.PROCEED_TO_PLAN')}}
      </span>
    </button>
    <button *ngIf="isProceedToTimingButtonVisible"
            type="button" (click)="proceedToTiming()" class="btn btn-primary btn-hollow btn-sm">
      <span>
        {{t('STUDY_RIGHT_EXTENSION_REQUIREMENTS_MODAL.PROCEED_TO_TIMING')}}
      </span>
    </button>
  </div>
</ng-container>
