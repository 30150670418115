"use strict";

import 'sis-components/model/attainment.model.js';
(function () {
  attainmentService.$inject = ["$http", "attainmentModel", "attainmentJSDataModel", "attainmentEndpoint", "validAttainmentFilterService"];
  angular.module('student.common.service.attainmentService', ['student.common.model.attainment', 'sis-components.model.attainment', 'sis-components.service.validAttainmentFilterService']).factory('attainmentService', attainmentService);

  /**
   * @ngInject
   */
  function attainmentService($http, attainmentModel, attainmentJSDataModel, attainmentEndpoint, validAttainmentFilterService) {
    const api = {
      getMyAttainments: bypassCache => {
        return api.getAttainmentsForStudent(bypassCache);
      },
      getMyValidAttainments: bypassCache => {
        return api.getAttainmentsForStudent(bypassCache).then(results => {
          return validAttainmentFilterService.getValidAttainments(results);
        });
      },
      // getMyValidModelAttainments function is specific for attainmentContextBuilder,
      // which assumes that attainments are instances of student attainmentModel

      getMyValidModelAttainments: bypassCache => {
        return api.getMyValidAttainments(bypassCache).then(results => {
          return _.map(results, attainmentModel.newAttainment);
        });
      },
      getAttainmentsForStudent: bypassCache => {
        const options = {
          bypassCache: !!bypassCache,
          endpoint: attainmentEndpoint.MY_ATTAINMENTS
        };
        const params = null;
        return attainmentJSDataModel.findAll(params, options);
      }
    };
    return api;
  }
})();