import { ModalService } from 'sis-common/modal/modal.service.ts';
import { ForceLearningOpportunityModalComponent } from './forceLearningOpportunityModal.component.ts';
import forceLearningOpportunityModalTpl from './forceLearningOpportunityModal.tpl.html';
'use strict';
(function () {
  ForceLearningOpportunityModalController.$inject = ["$scope", "$state", "$q", "$translate", "localeService", "educationService", "planService", "defaultPromiseHandler"];
  forceLearningOpportunityModal.$inject = ["$q", "modalService", "educationService", "commonStudyRightService"];
  angular.module('student.common.components.forceLearningOpportunityModal', ['sis-common.l10n.localeService', ModalService.downgrade.moduleName]).factory('forceLearningOpportunityModal', forceLearningOpportunityModal).controller('ForceLearningOpportunityModalController', ForceLearningOpportunityModalController).component('forceLearningOpportunityModal', {
    bindings: {
      close: '&',
      dismiss: '&',
      resolve: '<'
    },
    controller: ForceLearningOpportunityModalController,
    template: forceLearningOpportunityModalTpl
  });

  /**
   * @ngInject
   */
  function forceLearningOpportunityModal($q, modalService, educationService, commonStudyRightService) {
    return {
      open: plans => $q.all([educationService.getMyStudyRightEducations(true), commonStudyRightService.studyRights(false, true)]).then(([myEducations, myStudyRights]) => modalService.open(ForceLearningOpportunityModalComponent, {
        plans,
        myEducations,
        myStudyRights
      }, {
        size: 'lg'
      }).result)
    };
  }

  /**
   * @ngInject
   */
  function ForceLearningOpportunityModalController($scope, $state, $q, $translate, localeService, educationService, planService, defaultPromiseHandler) {
    var $ctrl = this;
    $ctrl.$onInit = function () {
      $scope.plans = _.filter($ctrl.resolve.plans, function (plan) {
        return !plan.learningOpportunityId;
      });
      var otherEducationIds = [];
      $scope.plansInMyEducations = [];
      $scope.plansInOtherEducations = [];
      $scope.myEducations = $ctrl.resolve.myEducations;
      $scope.myStudyRights = $ctrl.resolve.myStudyRights;
      _.forEach($scope.plans, function (plan) {
        var foundEducation = _.some($scope.myEducations, {
          id: plan.rootId
        });
        if (foundEducation) {
          $scope.plansInMyEducations = _.concat($scope.plansInMyEducations, plan);
        } else {
          otherEducationIds.push(plan.rootId);
          $scope.plansInOtherEducations = _.concat($scope.plansInOtherEducations, plan);
        }
      });
      educationService.getEducationsByIds(otherEducationIds).then(function (otherEducations) {
        $scope.otherEducations = otherEducations;
        $scope.myEducationsWithPlans = _.chain($scope.myEducations).filter(function (ed) {
          return _.some($scope.plans, {
            rootId: ed.id
          });
        }).uniqBy('id').value();
        $scope.otherEducationsWithPlans = _.chain($scope.otherEducations).filter(function (ed) {
          return _.some($scope.plans, {
            rootId: ed.id
          });
        }).uniqBy('id').value();
        $scope.learningOpportunityOptionsForMyNonPrimaryEducations = {};
        $scope.learningOpportunityOptionsForMyPrimaryEducations = {};
        $scope.learningOpportunityOptionsForOtherEducations = {};
        _.forEach($scope.myEducationsWithPlans, function (education) {
          var studyRight = _.find($scope.myStudyRights, {
            educationId: education.id
          });
          $scope.learningOpportunityOptionsForMyPrimaryEducations[education.id] = _.map(education.structure.learningOpportunities, function (lo) {
            var additionalInfo = studyRight && studyRight.learningOpportunityId !== lo.localId ? ' (' + $translate.instant('PLAN.FORCE_LEARNING_OPPORTUNITY_MODAL.NOT_IN_STUDY_RIGHT') + ')' : '';
            return {
              value: lo.localId,
              label: localeService.getLocalizedValue(lo.name),
              info: additionalInfo,
              disabled: studyRight && studyRight.learningOpportunityId !== lo.localId
            };
          });
          $scope.learningOpportunityOptionsForMyNonPrimaryEducations[education.id] = _.map(education.structure.learningOpportunities, function (lo) {
            var additionalInfo = studyRight && studyRight.learningOpportunityId === lo.localId ? ' (' + $translate.instant('PLAN.FORCE_LEARNING_OPPORTUNITY_MODAL.BELONGS_TO_STUDY_RIGHT') + ')' : '';
            return {
              value: lo.localId,
              label: localeService.getLocalizedValue(lo.name),
              info: additionalInfo
            };
          });
        });
        _.forEach($scope.otherEducationsWithPlans, function (education) {
          $scope.learningOpportunityOptionsForOtherEducations[education.id] = _.map(education.structure.learningOpportunities, function (lo) {
            return {
              value: lo.localId,
              label: localeService.getLocalizedValue(lo.name)
            };
          });
        });
      });
    };
    $scope.notSaveable = function () {
      return !_.some($scope.plans, 'learningOpportunityId');
    };
    $scope.ok = function () {
      var planUpdates = [];
      _.forEach($scope.plans, function (plan) {
        planUpdates.push(planService.update(plan));
      });
      $q.all(planUpdates).then(function () {
        $ctrl.close();
      }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    };
    $scope.cancel = function () {
      $ctrl.dismiss();
    };
    $scope.setLearningOpportunity = function (plan, selection) {
      plan.learningOpportunityId = selection;
    };
  }
})();