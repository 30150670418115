import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CourseUnit, CourseUnitAttainment, CurriculumPeriod,
    DegreeProgramme, DegreeProgrammeAttainment, ModuleAttainment } from 'common-typescript/types';
import { map, Observable, switchMap, take, tap } from 'rxjs';
import { LocaleService } from 'sis-common/l10n/locale.service';

import {
    isCourseUnitAttainment,
    isDegreeProgrammeAttainment,
    isModuleAttainment,
} from '../../attainment/AttainmentUtil';
import { AppErrorHandler } from '../../error-handler/app-error-handler';
import { CourseUnitEntityService } from '../../service/course-unit-entity.service';
import { CurriculumPeriodEntityService } from '../../service/curriculum-period-entity.service';
import { ModuleEntityService } from '../../service/module-entity.service';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'div[sis-curriculum-periods-details]',
    templateUrl: './curriculum-periods-details.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurriculumPeriodsDetailsComponent implements OnInit {
    curriculumPeriods$: Observable<string>;

    constructor(
        private errorHandler: AppErrorHandler,
        private courseUnitEntityService: CourseUnitEntityService,
        private curriculumPeriodEntityService: CurriculumPeriodEntityService,
        private moduleEntityService: ModuleEntityService,
        private localeService: LocaleService,
    ) { }

    @Input() attainment: DegreeProgrammeAttainment | ModuleAttainment | CourseUnitAttainment;

    ngOnInit() {
        if (isCourseUnitAttainment(this.attainment)) {
            this.curriculumPeriods$ = this.courseUnitEntityService.getById(this.attainment.courseUnitId)
                .pipe(
                    switchMap((result: CourseUnit) => this.curriculumPeriodEntityService.getByIds(result.curriculumPeriodIds).pipe(take(1))),
                    map(curriculumPeriods => {
                        const curriculumPeriodsSorted = [...curriculumPeriods].sort(this.compareCurriculumPeriodEndDates);
                        return curriculumPeriodsSorted.map(curriculumPeriod => this.localeService.localize(curriculumPeriod.abbreviation)).join(', ');
                    }),
                    this.errorHandler.defaultErrorHandler());
        }
        if (isModuleAttainment(this.attainment) || isDegreeProgrammeAttainment(this.attainment)) {
            this.curriculumPeriods$ = this.moduleEntityService.getById(this.attainment.moduleId)
                .pipe(
                    switchMap((result: DegreeProgramme) => this.curriculumPeriodEntityService.getByIds(result.curriculumPeriodIds).pipe(take(1))),
                    map(curriculumPeriods => {
                        const curriculumPeriodsSorted = [...curriculumPeriods].sort(this.compareCurriculumPeriodEndDates);
                        return curriculumPeriodsSorted.map(curriculumPeriod => this.localeService.localize(curriculumPeriod.abbreviation)).join(', ');
                    }),
                    this.errorHandler.defaultErrorHandler());
        }
    }

    private compareCurriculumPeriodEndDates(a: CurriculumPeriod, b: CurriculumPeriod): number {
        return (a.activePeriod.endDate > b.activePeriod.endDate ? 1 : -1);
    }
}
