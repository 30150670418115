'use strict';

(function () {
  educationTypeJSDataModel.$inject = ["$http", "DS"];
  angular.module('sis-components.model.educationType', ['js-data']).factory('educationTypeJSDataModel', educationTypeJSDataModel);
  function educationTypeJSDataModel($http, DS) {
    var model = DS.defineResource({
      idAttribute: 'urn',
      name: 'educationType',
      endpoint: '/kori/api/cached/codebooks/urn:code:education-type',
      watchChanges: false
    });
    model.lazyGetAll = _.once(function () {
      return $http.get(model.endpoint).then(function (response) {
        model.inject(_.values(response.data));
        return model.getAll();
      });
    });
    return model;
  }
})();