import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { Icon, IconColor } from '../icon/icon.component';

/**
 * Simple label component with an info icon that opens a helper text in a popover. Prefer this instead of the label-with-tooltip-icon
 * class when using larger and more informative helper text, or if you need a certainly accessible solution.
 */
@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-label-with-info-popover',
    templateUrl: './label-with-info-popover.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class LabelWithInfoPopoverComponent {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sisComponents.labelWithInfoPopover.labelWithInfoPopover',
        directiveName: 'sisLabelWithInfoPopover',
    };

    popoverShown = false;

    @Input() popoverText: string;
    @Input() labelText: string;
    @Input() hideInfo?: boolean;
    @Input() flip?: boolean;
    @Input() dark?: boolean;
    @Input() iconName?: Icon;
    @Input() iconColor?: IconColor;
    @Input() popoverPlacement = 'auto';
    @Input() autoClose: boolean | 'inside' | 'outside';

    isOpen() {
        this.popoverShown = true;
    }

    isHidden() {
        this.popoverShown = false;
    }
}
