<div class="row" data-cy="module-attainment-application-view">
  <div class="offset-md-1 col-md-10 offset-lg-2 col-lg-8">
    <div class="row" *ngIf="application.state === 'REQUESTED' || application.state === 'IN_HANDLING'">
      <div class="col-12 button-row">
        <button id="open-cancel-application-modal-button"
                type="button"
                class="btn btn-primary btn-lg"
                (click)="cancelApplication()"
                translate>
          PROFILE.APPLICATIONS.CANCEL_MODULE_ATTAINMENT_MODAL.BUTTON.OPEN_CANCEL_APPLICATION_MODAL
        </button>
      </div>
    </div>

    <ng-container
      *ngIf="(application.state === 'REQUESTED' || application.state === 'IN_HANDLING' || application.state === 'CANCELLED'); then cancelledOrRequested else otherStructure">
    </ng-container>

    <sis-maa-attainment-expandable [application]="application" [hideAdministrativeNote]="true"></sis-maa-attainment-expandable>
    <sis-maa-decision-expandable [application]="application"></sis-maa-decision-expandable>
  </div>
</div>

<ng-template #cancelledOrRequested>
  <div class="row">
    <div class="col-12" *ngIf="application.moduleId|module|async as studyModule">
      <span
        class="guidance"
        [translateParams]="{moduleName: studyModule.name|localizedString}"
        [translate]="application.state === 'CANCELLED' ? 'PROFILE.APPLICATIONS.MODULE_ATTAINMENT.MODULE_ATTAINMENT_APPLICATION_CANCELLED' : 'PROFILE.APPLICATIONS.MODULE_ATTAINMENT.HAS_REQUESTED_EVALUATION'">
      </span>
    </div>
  </div>


  <div class="col-12 title">
    <h2>{{'PROFILE.APPLICATIONS.MODULE_ATTAINMENT.CONTENTS_INCLUDED_IN_APPLICATION'|translate}}</h2>
  </div>
  <div class="col-12">
    <sis-attainment-application-structure [application]="application"></sis-attainment-application-structure>
  </div>
</ng-template>

<ng-template #otherStructure>
  <sis-expandable [title]="'PROFILE.APPLICATIONS.MODULE_ATTAINMENT.CONTENTS_INCLUDED_IN_APPLICATION'|translate" [level]="3">
    <ng-template sisExpandableContent>
      <sis-attainment-application-structure [application]="application"></sis-attainment-application-structure>
    </ng-template>
  </sis-expandable>
</ng-template>
