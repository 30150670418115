(function () {
  'use strict';

  timetableItemUtil.$inject = ["timetableConfigConstant"];
  angular.module('student.frontpage.timetable-grid').factory('timetableItemUtil', timetableItemUtil);

  /** @ngInject */
  function timetableItemUtil(timetableConfigConstant) {
    var ONE_HOUR_IN_MINUTES = 60;
    return {
      getEventsWithPositions: function (events, hourRange) {
        var self = this;
        var items = _.map(events, function (event) {
          var itemWidth = timetableConfigConstant.size.itemWidth;
          return {
            top: self.getPositionTop(event, hourRange),
            height: self.getHeight(event),
            width: itemWidth,
            event: event
          };
        });
        var addedItems = [];
        _.each(items, function (item) {
          item.left = self.getPositionLeft(addedItems, item);
          addedItems.push(item);
        });
        return items;
      },
      getPositionTop: function (event, hourRange) {
        var height = timetableConfigConstant.size.hourHeight;
        var t = {
          hours: event.time.start.hours() - hourRange[0],
          minutes: event.time.start.minutes()
        };
        var minutes = t.hours * ONE_HOUR_IN_MINUTES + t.minutes;
        return height - (height - height * minutes / ONE_HOUR_IN_MINUTES);
      },
      getHeight: function (event) {
        var height = timetableConfigConstant.size.hourHeight;
        var minutes = 21; //min-height for timetableItem
        if (event.time.end.diff(event.time.start, 'minutes') > 21) {
          minutes = event.time.end.diff(event.time.start, 'minutes');
        }
        return height * (minutes / ONE_HOUR_IN_MINUTES);
      },
      getPositionLeft: function (currentItems, t) {
        var left = 0;
        while (this.isBlocked(currentItems, {
          top: t.top,
          left: left,
          width: t.width,
          text: t.text,
          height: t.height
        }) && left < 1000) {
          left += t.width;
        }
        return left;
      },
      isBlocked: function (currentItems, a) {
        var blocked = false;
        var self = this;
        _.each(currentItems, function (b) {
          var block = {
            vertical: self.isVerticallyBlocked(a, b),
            horizontal: self.isHorizontallyBlocked(a, b)
          };
          if (block.horizontal && block.vertical) {
            blocked = true;
          }
        });
        return blocked;
      },
      isVerticallyBlocked: function (a, b) {
        var top = a.top + a.height < b.top + b.height && a.top + a.height > b.top;
        var bottom = a.top + a.height >= b.top + b.height && a.top < b.top + b.height;
        return top || bottom;
      },
      isHorizontallyBlocked: function (a, b) {
        var left = a.left >= b.left && a.left < b.left + b.width;
        var right = a.left < b.left && a.left + a.width > b.left;
        return left || right;
      }
    };
  }
})();