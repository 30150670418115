import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Attainment, ModuleAttainment } from 'common-typescript/types';
import _ from 'lodash';

import { AttainmentsById } from '../../../attainment/editableTree/AttainmentTreeTypes';

export interface ViewOptions {
    evaluationType: 'SEPARATE' | 'APPLICATION';
    hideAdministrativeNote: boolean;
}

@Component({
    selector: 'sis-module-attainment',
    templateUrl: './module-attainment.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ModuleAttainmentComponent implements OnInit {
    @Input() attainment: ModuleAttainment;
    @Input() attainments: Attainment[];
    @Input() viewOptions: ViewOptions;
    attainmentsById: AttainmentsById;

    ngOnInit(): void {
        this.attainmentsById = _.keyBy(this.attainments, 'id');
    }
}
