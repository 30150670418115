(function () {
  'use strict';

  angular.module('student.frontpage.timetable-grid').directive('timetableItems', timetableItemsDirective);

  /** @ngInject */
  function timetableItemsDirective() {
    return {
      transclude: true,
      template: '<ng-transclude></ng-transclude>'
    };
  }
})();