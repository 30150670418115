<sis-expandable [id]="'person-data-change-history-expandable'" [level]="4" [variant]="'link'"
                [title]="'FIELD_NAMES.personDataChange' | translate">
  <ng-template sisExpandableContent>
    <table aria-describedby="person-data-change-history-expandable" class="table styled-table personal-history">
      <thead>
      <tr>
        <th class="">{{ 'FIELD_NAMES.firstNames' | translate }}</th>
        <th>{{ 'FIELD_NAMES.lastName' | translate }}</th>
        <th>{{ 'FIELD_NAMES.personalIdentityCode' | translate }}
          <sis-classified-info-toggle [visible]="isHistoryPICVisible"
                                      [ariaLabel]="(isHistoryPICVisible ? 'ARIA_LABEL.HIDE_PREVIOUS_ID_NUMBERS' : 'ARIA_LABEL.SHOW_PREVIOUS_ID_NUMBERS') | translate"
                                      (toggle)="isHistoryPICVisible = !isHistoryPICVisible"/>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let historyRow of sortedHistory$ | async">
        <td class="notranslate">{{ historyRow.oldFirstNames }}</td>
        <td class="notranslate">{{ historyRow.oldLastName }}</td>
        <td *ngIf="!isHistoryPICVisible" aria-hidden="true">&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;</td>
        <td *ngIf="isHistoryPICVisible" class="notranslate">{{ historyRow.oldPersonalIdentityCode }}</td>
      </tr>
      </tbody>
    </table>
  </ng-template>
</sis-expandable>
