import angular from 'angular';
import _ from 'lodash';
import { PopoverComponent } from '../../popover/popover.component.ts';
import { searchFilterModule } from './searchFilter.component';
import searchFilterTpl from './searchFilterMenuItem.tpl.html';
export const searchFilterMenuItemModule = 'sis-components.search.searchFilterMenuItem';
angular.module(searchFilterMenuItemModule, [searchFilterModule, PopoverComponent.downgrade.moduleName]).component('searchFilterMenuItem', {
  bindings: {
    onSelect: '&',
    option: '<',
    parameterModel: '<',
    // Necessary only if searchFilterType is 'Education', 'Organisation' or 'OrganisationRoots'
    searchAgain: '&?',
    // Necessary only if searchFilterType is 'Education', 'Organisation' or 'OrganisationRoots'
    searchParams: '<?',
    university: '<'
  },
  template: searchFilterTpl,
  controller: ["$timeout", "$translate", "SearchFilterTypes", function ($timeout, $translate, SearchFilterTypes) {
    const ctrl = this;
    ctrl.$onInit = () => {
      ctrl.popoverIsOpen = false;
      ctrl.filterValues = ctrl.option.options;
      ctrl.filterPlacement = _.get(ctrl, 'filterPlacement', 'left right');
      ctrl.searchFilterType = ctrl.option.type;
      ctrl.filterName = $translate.instant(`SEARCH.FILTER_TAGS.${ctrl.option.name.toUpperCase()}`);
    };
    ctrl.submitFilter = values => {
      ctrl.onSelect({
        values
      });
      ctrl.onHidden();
    };
    ctrl.onHidden = () => {
      $timeout(() => {
        ctrl.popoverIsOpen = false;
      });
    };
    ctrl.onShown = () => {
      if (ctrl.searchFilterType === SearchFilterTypes.ORGANISATIONROOTS) {
        ctrl.openOrganisationDialog();
      }
      $timeout(() => {
        ctrl.popoverIsOpen = true;
      });
    };
  }]
});