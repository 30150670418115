import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { LocalizedMarkupString, LocalizedString } from 'common-typescript/types';
import {
    ComponentDowngradeMappings,
    DowngradedComponent,
    StaticMembers,
} from 'sis-common/types/angular-hybrid';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-course-unit-info-modal-text-field',
    templateUrl: './course-unit-info-modal-text-field.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class CourseUnitInfoModalTextFieldComponent implements OnChanges {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.plan.courseUnitInfoModalTextField',
        directiveName: 'sisCourseUnitInfoModalTextField',
    };

    hasValue: boolean;

    @Input() localizedMarkupStringValue?: LocalizedMarkupString;
    @Input() localizedStringValue?: LocalizedString;
    @Input() language: string;

    ngOnChanges() {
        this.hasValue = this.containsValue(this.localizedStringValue) || this.containsValue(this.localizedMarkupStringValue);
    }

    containsValue(value: LocalizedString | LocalizedMarkupString) {
        return value && value[this.language] && value[this.language] !== '';
    }
}
