import { ChangeDetectionStrategy, Component, inject, ViewEncapsulation } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { StateService } from '@uirouter/angular';
import { UIRouterGlobals } from '@uirouter/core';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';
import { UserSettingsEntityService } from 'sis-components/service/user-settings-entity.service';

import { frontpageRootState } from '../frontpage.route';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'app-beta-frontpage-toggle',
    template: `
        <ng-container *transloco="let t">
            <sis-toggle
                    *ngIf="settings()"
                    [label]="t('FRONTPAGE.USE_NEW_FRONTPAGE')"
                    [checked]="settings().studentBetaFrontpageEnabled ?? false"
                    [autofocus]="autofocus"
                    [ariaDescribedBy]="'beta-frontpage-toggle-description'"
                    (checkedChange)="setBetaFrontpageEnabled($event)"
            />
            <span id="beta-frontpage-toggle-description" class="visually-hidden">
                {{t('FRONTPAGE.USE_NEW_FRONTPAGE_DESCRIPTION')}}
            </span>
        </ng-container>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BetaFrontpageToggleComponent {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'student.frontpage.beta-frontpage-toggle',
        directiveName: 'appBetaFrontpageToggle',
    };

    private readonly state = inject(StateService);
    private readonly uiRouterGlobals = inject(UIRouterGlobals);
    private readonly userSettingsService = inject(UserSettingsEntityService);

    readonly settings = toSignal(this.userSettingsService.getOwnSettings());
    readonly autofocus = this.uiRouterGlobals.params?.focusBetaFrontpageToggle ?? false;

    setBetaFrontpageEnabled(studentBetaFrontpageEnabled: boolean) {
        this.userSettingsService.saveOwnSettings({ ...this.settings(), studentBetaFrontpageEnabled })
            // The frontpage root state determines which frontpage to use based on the updated user settings
            .subscribe(() => this.state.go(frontpageRootState, { focusBetaFrontpageToggle: true }));
    }
}
