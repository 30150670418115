import { Breakpoint } from '../service/breakpoint.service';

export enum SlideWidth {
    auto = 'auto',
    default = 220, // if new widths are added, related styles should go to carousel.component.scss (see class .default-1 etc. for reference)
}

export class Arrows {
    constructor(
        public prev: Element | string,
        public next: Element | string) {}
}

export class CommonConfig {
    constructor(
        public slidesToShow: 'auto' | number = 1,
        public scrollLock = false,
        public itemWidth: SlideWidth = SlideWidth.auto,
        public exactWidth = false,
        public slidesToScroll: 'auto' | number = 'auto',
        public scrollLockDelay: number = 250,
        public arrows: Arrows = new Arrows('.glider-prev', '.glider-next'),
        public dots: Element | string = undefined,
        public resizeLock = true,
        public rewind = false,
        public scrollPropagate = false,
        public draggable = false, // dragging messes up selection, better leave it disabled by default
        public dragVelocity: number = 3.3,
        public duration: number = 0.5,
        public eventPropagate = true,
        public skipTrack = true) {
        if ([slidesToShow, itemWidth].filter(value => value === 'auto').length === 2) {
            throw new Error('Either "slidesToShow" or "itemWidth" must be a number!');
        }
    }
}

export class ResponsiveConfig {
    constructor(
        public breakpoint: Breakpoint,
        public settings: CommonConfig) {}
}

export class CarouselConfig extends CommonConfig {
    constructor(
        public responsive: ResponsiveConfig[] = [
            new ResponsiveConfig(Breakpoint.XXS, new CommonConfig(1, true)),
            new ResponsiveConfig(Breakpoint.SM, new CommonConfig(3)),
            new ResponsiveConfig(Breakpoint.LG, new CommonConfig(5)),
        ],
        ...rest: any[]) {
        super(...rest);
    }
}

export class SlideBanner {
    constructor(
        public className: 'danger' | 'success',
        public text: string) {}
}

export const prevKeys = [
    'ArrowLeft',
    'ArrowUp',
];

export const arrowKeys = [...prevKeys, 'ArrowRight', 'ArrowDown'];
