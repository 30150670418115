import angular from 'angular';
import { localeServiceModule } from 'sis-common/l10n/localeService';
import _ from 'lodash';
import studentTimingTemplateTpl from './studentTimingTemplate.tpl.html';
(function () {
  studentTimingTemplatesController.$inject = ["$log", "commonTimingTemplateService", "timeLineCourseUnitService", "studentTimingTemplateService", "localeService", "timingViewStateService", "defaultPromiseHandler", "timelineDialogService", "commonCurriculumPeriodService"];
  angular.module('student.timing.studentTimingTemplate', ['sis-common.errorhandler.errorService', localeServiceModule, 'sis-components.string.localizedString', 'sis-components.select.dropdownSelect', 'sis-components.service.curriculumPeriodService', 'sis-components.service.timingTemplateService', 'student.timing.timeLineCourseUnitService', 'student.timing.studentTimingTemplateService', 'student.timing.timingViewStateService', 'student.timing.controls.timelineDialogService']).component('studentTimingTemplate', {
    template: studentTimingTemplateTpl,
    bindings: {
      module: '<',
      universityOrgId: '<',
      selectedPlan: '<',
      validatablePlan: '<',
      validatablePlanConstructor: '<',
      state: '<'
    },
    controller: studentTimingTemplatesController
  });

  /**
   * @ngInject
   */
  function studentTimingTemplatesController($log, commonTimingTemplateService, timeLineCourseUnitService, studentTimingTemplateService, localeService, timingViewStateService, defaultPromiseHandler, timelineDialogService, commonCurriculumPeriodService) {
    const ctrl = this;
    ctrl.timingTemplates = [];
    ctrl.timingTemplateOptions = [];
    ctrl.selectedTemplate = undefined;
    ctrl.$onInit = function () {
      const moduleId = _.get(ctrl, 'module.id');
      commonCurriculumPeriodService.findCurriculumPeriodsByIds(_.get(ctrl.module, 'curriculumPeriodIds', [])).then(curriculumPeriods => {
        commonTimingTemplateService.getActiveTimingTemplates(moduleId).then(templates => {
          if (_.isEmpty(templates)) {
            $log.debug("No 'ACTIVE' timing templates found for module id:", moduleId);
          } else {
            $log.debug(`Found ${templates.length} 'ACTIVE' timing template(s) for module id: `, moduleId);
            ctrl.timingTemplates = commonTimingTemplateService.filterTimingTemplates(templates, ctrl.universityOrgId, curriculumPeriods);
            ctrl.timingTemplateOptions = ctrl.timingTemplates.map(template => ({
              value: template.id,
              label: `${localeService.getLocalizedValue(template.name)} ${template.studyYear}`
            }));
          }
        });
      });
    };

    /**
     * @return <code>True</code> if there are any templates available to select from
     */
    ctrl.isTimingTemplateOptions = function () {
      return !_.isEmpty(ctrl.timingTemplateOptions);
    };

    /**
     * @return <code>True</code> if there is a timing template selected
     */
    ctrl.isSelectedTemplate = function () {
      return !!ctrl.selectedTemplate;
    };
    ctrl.getSelectedTemplateId = function () {
      return _.get(ctrl, 'selectedTemplate.id', '');
    };
    ctrl.setSelectedTemplate = function (templateId) {
      ctrl.selectedTemplate = _.find(ctrl.timingTemplates, {
        id: templateId
      });
    };
    ctrl.introduceSelectedTemplate = function () {
      timingViewStateService.startIntroducingTimingTemplate(ctrl.state);
      studentTimingTemplateService.introduceTimingTemplateToPlan(ctrl.selectedPlan, ctrl.selectedTemplate, ctrl.validatablePlan, ctrl.module, ctrl.validatablePlanConstructor).then(() => {
        timingViewStateService.onEndTiming(ctrl.state);
        delete ctrl.selectedTemplate;
      }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    };
    ctrl.startRemoveAllDialog = function () {
      timelineDialogService.openRemoveAllDialog(ctrl.state, ctrl.validatablePlan, ctrl.validatablePlanConstructor, ctrl.module).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    };
    ctrl.timingsExist = function (module) {
      const courseUnitTimings = timeLineCourseUnitService.getTimedCourseUnitsForModuleRecursively(module, ctrl.validatablePlan);
      const customStudyDraftTimings = timeLineCourseUnitService.getTimedCustomStudyDraftsForModuleRecursively(module, ctrl.validatablePlan);
      return !_.isEmpty(courseUnitTimings) || !_.isEmpty(customStudyDraftTimings);
    };
  }
})();