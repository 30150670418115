import angular from 'angular';
import searchFilterTpl from './searchFilterMultiWithHeadings.tpl.html';
export const searchFilterMultiWithHeadingsModule = 'sis-components.search.searchFilterMultiWithHeadings';
angular.module(searchFilterMultiWithHeadingsModule, []).component('searchFilterMultiWithHeadings', {
  bindings: {
    allFilters: '<',
    filterName: '<',
    filterSearch: '<',
    filtersLimit: '<',
    getFilterIndexCb: '&',
    getFilterNameCb: '&',
    getPrimaryFilterHeadings: '&',
    helpText: '<',
    isInitialized: '<',
    isValueSelectedCb: '&',
    listSelected: '&',
    popoverIsOpen: '<',
    primaryFiltersByHeading: '<',
    searching: '<',
    searchWithinFilter: '=',
    startIndex: '<',
    submitFilter: '&',
    toggleValueCb: '&'
  },
  template: searchFilterTpl,
  controllerAs: 'ctrl',
  controller: ["$scope", function ($scope) {
    const ctrl = this;

    // Invoke callback methods that take arguments in a way that allows to keep the template file unchanged
    // (that used to call controller methods directly when UI Bootstrap was used)
    ctrl.getFilterIndex = filter => ctrl.getFilterIndexCb()(filter);
    ctrl.getFilterName = filter => ctrl.getFilterNameCb()(filter);
    ctrl.isValueSelected = value => ctrl.isValueSelectedCb()(value);
    ctrl.toggleValue = (value, form, event) => ctrl.toggleValueCb()(value, form, event);
    $scope.$watch('ctrl.popoverIsOpen', newVal => {
      if (!newVal) {
        // Reset form when popover closes
        $scope.filterSelectorForm.$setPristine();
      }
    });
  }]
});