import { ChangeDetectionStrategy, Component, Inject, inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Enrolment } from 'common-typescript/types';
import { catchError, EMPTY, Observable, Subject } from 'rxjs';
import { exhaustMap, take, tap } from 'rxjs/operators';
import { ModalService } from 'sis-common/modal/modal.service';
import { AlertsService, AlertType } from 'sis-components/alerts/alerts-ng.service';

import { EnrolmentStudentService } from '../../common/service/enrolment-student.service';

export interface EnrolmentAbortModalValues {
    enrolment: Enrolment;
}

export function enrolmentAbortModalOpener(): (enrolment: Enrolment) => NgbModalRef {
    const modalService = inject(ModalService);
    return enrolment => modalService.open(EnrolmentAbortModalComponent, { enrolment }, { size: 'sm' });
}

@Component({
    selector: 'app-enrolment-abort-modal',
    templateUrl: './enrolment-abort-modal.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnrolmentAbortModalComponent implements OnInit, OnDestroy {

    abort$: Subject<void> = new Subject<void>();
    abortFailed$ = new Subject<boolean>();

    constructor(
        private alertsService: AlertsService,
        private enrolmentService: EnrolmentStudentService,
        private modal: NgbActiveModal,
        private translate: TranslateService,
        @Inject(ModalService.injectionToken) private values: EnrolmentAbortModalValues,
    ) {}

    ngOnInit(): void {
        this.abort$.pipe(exhaustMap(() => this.abortEnrolment()), take(1)).subscribe();
    }

    ngOnDestroy(): void {
        this.abort$.complete();
    }

    dismiss(): void {
        this.modal.dismiss();
    }

    get enrolment(): Enrolment {
        return this.values?.enrolment;
    }

    private abortEnrolment(): Observable<Enrolment> {
        return this.enrolmentService.abortEnrolment(this.enrolment.id)
            .pipe(
                take(1),
                tap((abortedEnrolment) => this.modal.close(abortedEnrolment)),
                tap(() =>
                    this.alertsService.addTemporaryAlert({
                        type: AlertType.INFO,
                        message: this.translate.instant('ENROLMENT.ENROLMENT_ABORT_SUCCESS'),
                    })),
                catchError(() => {
                    this.abortFailed$.next(true);
                    return EMPTY;
                }),
            );
    }
}
