import { Pipe, PipeTransform } from '@angular/core';
import { CourseUnitRealisation, ResolvedFlowState } from 'common-typescript/types';

import { CourseUnitRealisationEntityService } from '../service/course-unit-realisation-entity.service';

@Pipe({
    name: 'resolvedFlowState',
})
export class ResolvedFlowStatePipe implements PipeTransform {
    constructor(private courseUnitRealisationEntityService: CourseUnitRealisationEntityService) {
    }

    transform(value: CourseUnitRealisation): ResolvedFlowState {
        if (!value) {
            return null;
        }
        return this.courseUnitRealisationEntityService.getResolvedFlowState(value);
    }
}
