import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Education, Module, Plan } from 'common-typescript/types';
import _ from 'lodash';
import { DEFAULT_PROMISE_HANDLER } from 'sis-common/ajs-upgraded-modules';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { STUDY_PROGRESS_GRAPH_DROPDOWN_CONTENT_FETCHER } from '../../../ajs-upgraded-modules';

interface MenuItem {
    education: Education;
    plan: Plan;
    module?: Module;
    modules?: Module[];
}

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'app-study-progress-graph-dropdown',
    templateUrl: './study-progress-graph-dropdown.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class StudyProgressGraphDropdownComponent implements OnInit {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'student.frontpage.studyProgressGraph.studyProgressGraphDropdown.downgraded',
        directiveName: 'appStudyProgressGraphDropdown',
    };

    @Input() state: any;
    @Output() select = new EventEmitter<MenuItem>();

    menuItems: MenuItem[];

    constructor(@Inject(STUDY_PROGRESS_GRAPH_DROPDOWN_CONTENT_FETCHER) private contentFetcher: any,
                @Inject(DEFAULT_PROMISE_HANDLER) private defaultPromiseHandler: any) {}

    ngOnInit() {
        this.contentFetcher.findEducationsWithPrimaryPlans()
            .then((menuItems: MenuItem[]) => {
                this.menuItems = menuItems;
                if (_.isEmpty(menuItems)) {
                    return;
                }
                const selected = menuItems.find(item => _.get(item, 'plan.id') === _.get(this.state, 'selected.planId'));
                if (selected) {
                    // select previously selected automatically
                    this.onSelect(
                        selected.education,
                        selected.plan,
                        (selected.modules || []).find(module => module.id === _.get(this.state, 'selected.moduleId')),
                    );
                } else {
                    const mostRecent = _.first(_.orderBy(menuItems, ['plan.metadata.lastModifiedOn'], ['desc']));
                    this.onSelect(mostRecent.education, mostRecent.plan, _.first(mostRecent.modules));
                }
            }).catch(this.defaultPromiseHandler.loggingRejectedPromiseHandler);
    }

    onSelect(education: Education, plan: Plan, module: Module): void {
        this.select.emit({ education, plan, module });
    }
}
