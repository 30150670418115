<ng-container *transloco="let t">
  <div class="modal-header row">
    <div class="col-12 sis-mb-md">
      <h2 class="sis-mb-zero" tabindex="0">
        {{ attainment | attainmentName | async | localizedString }} ({{ attainment.credits }} {{ t('CREDITS') }})
      </h2>
      <strong>
        {{ attainment | attainmentCode | async }}
        | {{ t('STUDENT_APPLICATIONS.TYPE.CustomCourseUnitAttainment') }}
      </strong>
      <sis-modal-close-button/>
    </div>
    <div class="col-12 sis-mb-md">
      <sis-tab-content-switch [(currentIndex)]="currentTabIndex"
                              [wide]="'xs'"
                              [tabs]="[
                                    { id: 'attainment-tab', title: 'STUDENT_APPLICATIONS.ATTAINMENT_INFO' },
                                    { id: 'decision-tab', title: 'STUDENT_APPLICATIONS.ATTAINMENT_DECISION_INFO' },
                                ]">
      </sis-tab-content-switch>
    </div>
  </div>
  <div class="modal-body">
    <!-- Attainment tab -->
    <div *ngIf="currentTabIndex === 0" aria-labelledby="attainment-tab" role="tabpanel" tabindex="0">
      <h3 class="sis-mb-md">{{ t('STUDENT_APPLICATIONS.ATTAINMENT_INFO') }}</h3>
      <sis-description-list>
        <dl>
          <ng-template #listItem>
            <dt class="sis-label">{{ t('STUDENT_APPLICATIONS.ATTAINMENT_STATE') }}</dt>
            <dd>{{ attainment.state | attainmentStatusTranslation | translate }}</dd>
          </ng-template>
          <ng-template #listItem>
            <dt class="sis-label">{{ t('STUDENT_APPLICATIONS.ATTAINMENT_GRADE') }}</dt>
            <dd>{{ attainment.gradeId | grade: attainment.gradeScaleId: 'NAME' | async }}</dd>
          </ng-template>
          <ng-template #listItem>
            <dt class="sis-label">{{ t('STUDENT_APPLICATIONS.ATTAINMENT_CREDITS') }}</dt>
            <dd>{{ attainment.credits}} {{t('STUDENT_APPLICATIONS.CUSTOM_ATTAINMENT.CREDITS_SHORT') }}</dd>
          </ng-template>
          <ng-template #listItem *ngIf="attainment.state !== 'INCLUDED'">
            <dt class="sis-label">{{ t('STUDENT_APPLICATIONS.ATTAINMENT_RDI_CREDITS') }}</dt>
            <dd>{{ attainment.rdiCredits }}</dd>
          </ng-template>
          <ng-template #listItem>
            <dt class="sis-label">{{ t('STUDENT_APPLICATIONS.ATTAINMENT_DATE') }}</dt>
            <dd>{{ attainment.attainmentDate | localDateFormat }}</dd>
          </ng-template>
          <ng-template #listItem>
            <dt class="sis-label">{{ t('STUDENT_APPLICATIONS.ATTAINMENT_EXPIRATION') }}</dt>
            <dd>{{ attainment.expiryDate ? (attainment.expiryDate | localDateFormat) : t('STUDENT_APPLICATIONS.ATTAINMENT_EXPIRATION_NONE') }}</dd>
          </ng-template>
          <ng-template #listItem>
            <dt class="sis-label">{{ t('STUDENT_APPLICATIONS.ATTAINMENT_LANGUAGE') }}</dt>
            <dd>{{ attainment.attainmentLanguageUrn | codeLocalizedName | async }}</dd>
          </ng-template>
          <ng-template #listItem>
            <dt class="sis-label">{{ t('STUDENT_APPLICATIONS.ATTAINMENT_APPROVER') }}</dt>
            <dd *ngFor="let acceptor of attainment.acceptorPersons">
              {{ acceptor.personId | publicPerson | async | personFullName }}
            </dd>
          </ng-template>
          <ng-template #listItem>
            <dt class="sis-label">{{ t('STUDENT_APPLICATIONS.ATTAINMENT_ADDITIONAL_INFO') }}</dt>
            <dd>{{ attainment.additionalInfo | localizedString }}</dd>
          </ng-template>
        </dl>
      </sis-description-list>
    </div>

    <!-- Decision tab -->
    <div *ngIf="currentTabIndex === 1" aria-labelledby="decision-tab" role="tabpanel" tabindex="0">
      <ng-container *ngIf="workflowObs$ | async as workflow else noDecisionInfoText">
        <!-- FormalRecommendation -->
        <ng-container *ngIf="$any(workflow.decision)?.formalRecommendation as formalRecommendation">
          <h3 class="sis-mb-md">{{ t('STUDENT_APPLICATIONS.FORMAL_RECOMMENDATION') }}</h3>
          <sis-description-list>
            <dl>
              <ng-template #listItem>
                <dt class="sis-label">{{ t('RECOMMENDATION.STATE') }}</dt>
                <dd [ngSwitch]="formalRecommendation.state">
                  <ng-container
                    *ngSwitchCase="recommendationState.NOT_HANDLED">{{ t('RECOMMENDATION.' + workflow.type + '.NOT_HANDLED') }}</ng-container>
                  <ng-container
                    *ngSwitchCase="recommendationState.RECOMMENDED">{{ t('RECOMMENDATION.' + workflow.type + '.RECOMMENDED') }}</ng-container>
                  <ng-container
                    *ngSwitchCase="recommendationState.NOT_RECOMMENDED">{{ t('RECOMMENDATION.' + workflow.type + '.NOT_RECOMMENDED') }}</ng-container>
                </dd>
              </ng-template>
              <ng-template #listItem *ngIf="formalRecommendation.responsiblePerson || formalRecommendation.additionalInformation">
                <dt class="sis-label">{{ t('RECOMMENDATION.ASSESSOR') }}</dt>
                <dd>
                  {{ formalRecommendation.responsiblePerson | publicPerson | async | personFullName }},
                  {{ formalRecommendation.responsiblePersonTitle | localizedString }}
                  <p *ngIf="formalRecommendation.additionalInformation">
                    {{ formalRecommendation.additionalInformation }}
                  </p>
                </dd>
              </ng-template>
              <ng-template #listItem *ngIf="formalRecommendation.comment">
                <dt class="sis-label">{{ t('RECOMMENDATION.COMMENT') }}</dt>
                <dd>{{ formalRecommendation.comment }}</dd>
              </ng-template>
            </dl>
          </sis-description-list>
        </ng-container>

        <!-- ContentRecommendation -->
        <ng-container *ngIf="$any(workflow.decision)?.contentRecommendation as contentRecommendation">
          <h3 class="sis-mb-md">{{ t('STUDENT_APPLICATIONS.CONTENT_RECOMMENDATION') }}</h3>
          <sis-description-list>
            <dl>
              <ng-template #listItem>
                <dt class="sis-label">{{ t('RECOMMENDATION.STATE') }}</dt>
                <dd [ngSwitch]="contentRecommendation.state">
                  <ng-container
                    *ngSwitchCase="recommendationState.NOT_HANDLED">{{ t('RECOMMENDATION.' + workflow.type + '.NOT_HANDLED') }}</ng-container>
                  <ng-container
                    *ngSwitchCase="recommendationState.RECOMMENDED">{{ t('RECOMMENDATION.' + workflow.type + '.RECOMMENDED') }}</ng-container>
                  <ng-container
                    *ngSwitchCase="recommendationState.NOT_RECOMMENDED">{{ t('RECOMMENDATION.' + workflow.type + '.NOT_RECOMMENDED') }}</ng-container>
                </dd>
              </ng-template>
              <ng-template #listItem *ngIf="contentRecommendation.responsiblePerson || contentRecommendation.additionalInformation">
                <dt class="sis-label">{{ t('RECOMMENDATION.ASSESSOR') }}</dt>
                <dd>
                  {{ contentRecommendation.responsiblePerson | publicPerson | async | personFullName }},
                  {{ contentRecommendation.responsiblePersonTitle | localizedString }}
                  <p *ngIf="contentRecommendation.additionalInformation">
                    {{ contentRecommendation.additionalInformation }}
                  </p>
                </dd>
              </ng-template>
              <ng-template #listItem *ngIf="contentRecommendation.comment">
                <dt class="sis-label">{{ t('RECOMMENDATION.COMMENT') }}</dt>
                <dd>{{ contentRecommendation.comment }}</dd>
              </ng-template>
            </dl>
          </sis-description-list>
        </ng-container>

        <h3 class="sis-mb-md">{{ t('STUDENT_APPLICATIONS.ATTAINMENT_DECISION_INFO') }}</h3>
        <sis-description-list [fillByColumn]="false">
          <dl>
            <!-- Decision -->
            <ng-template #listItem>
              <dt class="sis-label">{{ t('STUDENT_APPLICATIONS.DECISION') }}</dt>
              <dd>{{
                (workflow.state === (this.studentWorkflowState.ACCEPTED || this.studentWorkflowState.REJECTED)
                  ? (('STUDENT_APPLICATIONS.STATE.' + workflow.state) | translate)
                  : '-') }}
              </dd>
            </ng-template>
            <ng-template #listItem>
              <dt class="sis-label">{{ t('STUDENT_APPLICATIONS.RESOLUTION_RATIONALE') }}</dt>
              <dd>{{ workflow.decision.resolutionRationale }}</dd>
            </ng-template>
            <ng-template #listItem>
              <dt class="sis-label">{{ t('STUDENT_APPLICATIONS.APPROVED_BY') }}</dt>
              <dd>{{ workflow.decision.approvedBy | publicPerson | async | personFullName }}</dd>
            </ng-template>
            <ng-template #listItem>
              <dt class="sis-label">{{ t('STUDENT_APPLICATIONS.HANDLED_BY') }}</dt>
              <dd>{{ workflow.lastHandlerPersonId | publicPerson | async | personFullName }}</dd>
            </ng-template>
            <ng-template #listItem>
              <dt class="sis-label">{{ t('STUDENT_APPLICATIONS.APPROVAL_DATE') }}</dt>
              <dd>{{ workflow.decision.approvalDate | localDateFormat }}</dd>
            </ng-template>
            <ng-template #listItem>
              <dt class="sis-label">{{ t('STUDENT_APPLICATIONS.APPEAL_INSTRUCTIONS') }}</dt>
              <dd>
                <sis-markup-string-view [content]="$any(workflow.decision)?.appealInstructions"></sis-markup-string-view>
              </dd>
            </ng-template>
            <ng-template #listItem>
              <dt class="sis-label">{{ t('STUDENT_APPLICATIONS.ATTAINMENT') }}</dt>
              <dd>{{ t('STUDENT_APPLICATIONS.ATTAINMENT_HAS_BEEN_CREATED.' + attainment.type) }}</dd>
            </ng-template>
          </dl>
        </sis-description-list>
      </ng-container>
      <ng-template #noDecisionInfoText>
        <p>{{ t('STUDENT_APPLICATIONS.CUSTOM_CREDIT_WITH_NO_APPLICATION_AND_DECISION') }}</p>
      </ng-template>
    </div>
  </div>
</ng-container>
