import { Injectable } from '@angular/core';
import { Attainment } from 'common-typescript/types';
import { BehaviorSubject, Observable, shareReplay, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from 'sis-common/auth/auth-service';
import { AttainmentEntityService } from 'sis-components/service/attainment-entity.service';
import { AttainmentValidityService } from 'sis-components/service/attainment-validity.service';
import { StudentContextAttainmentService } from 'sis-components/service/student-context-attainment.service';

@Injectable({ providedIn: 'root' })
export class MyAttainmentService extends StudentContextAttainmentService {
    /**
     * Emits all attainments of the logged-in student.
     */
    readonly allAttainments$: Observable<readonly Attainment[]>;

    /**
     * Emits those from {@link validAttainments$} (in original order) that
     * a) are themselves valid
     * or
     * b) are child/descendant attainments of other valid {@link validAttainments$}.
     */
    readonly validAttainments$: Observable<readonly Attainment[]>;

    constructor(
        authService: AuthService,
        attainmentEntityService: AttainmentEntityService,
        attainmentValidityService: AttainmentValidityService,
    ) {
        super();

        // The subject is just a trick to prevent the result from being cached forever.
        // It also delays running any actual logic before the first subscription.
        this.allAttainments$ = new BehaviorSubject(undefined).pipe(
            switchMap(() => attainmentEntityService.getByPersonId(authService.personId())),
            shareReplay({ bufferSize: 1, refCount: true }),
        );

        this.validAttainments$ = this.allAttainments$.pipe(
            map((attainments: readonly Attainment[]) => attainmentValidityService.getValidAttainments(attainments)),
            shareReplay({ bufferSize: 1, refCount: true }),
        );
    }
}
