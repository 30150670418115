<sis-expandable [title]="attainmentGroupNode.name | localizedString"
                [closed]="false"
                [level]="headingLevel"
                [contentPadding]="'none'"
                [variant]="'naked-dotted'">
  <ng-template sisExpandableCustomTitle>
    <div class="sub-title sub-title__size__md">
      <hr class="sis-hr sis-mb-zero sis-mt-zero"/>
    </div>
  </ng-template>
  <ng-template sisExpandableContent>
    <sis-plan-structure-attainment-node-structure [attainmentNodes]="attainmentGroupNode.nodes"
                                                  [planStateObject]="planStateObject"
                                                  [planData]="planData"
                                                  [headingLevel]="headingLevel + 1">
    </sis-plan-structure-attainment-node-structure>
  </ng-template>
</sis-expandable>
