<div class="row" *ngIf="product">
  <div class="col-12 col-md-4 sis-mb-md">
    <sis-description-list-compact>
      <dl>
        <ng-template #compactListItem>
          <dt class="sis-label colon">{{'OPEN_UNIVERSITY.STUDY_OFFERING.SALES_PERIOD' | translate}}</dt>
          <dd class="value">{{product.salesPeriod | localDateTimeRange}}</dd>
        </ng-template>
        <ng-template #compactListItem>
          <dt class="sis-label colon">{{'OPEN_UNIVERSITY.STUDY_OFFERING.ENROLMENT_RIGHT_VALIDITY' | translate}}</dt>
          <dd class="value">{{product.validityPeriod | localDateRange}}</dd>
        </ng-template>
        <ng-template #compactListItem>
          <dt class="sis-label colon">{{'OPEN_UNIVERSITY.STUDY_OFFERING.CREDITS_LABEL' | translate}}</dt>
          <dd class="value">{{product.credits | decimalNumber}} {{'OPEN_UNIVERSITY.STUDY_OFFERING.CREDITS_LONG' | translate}}</dd>
        </ng-template>
        <ng-template #compactListItem>
          <dt class="sis-label colon">{{'OPEN_UNIVERSITY.STUDY_OFFERING.PRICE' | translate}}</dt>
          <dd class="value">{{product.price | money}}</dd>
        </ng-template>
      </dl>
    </sis-description-list-compact>
  </div>
  <div class="col-12 col-md-8">
    <sis-description-list [columnCount]="1">
      <ng-template #listItem *ngIf="product.description as description">
        <dt>{{'OPEN_UNIVERSITY.STUDY_OFFERING.PRODUCT_DESCRIPTION' | translate}}</dt>
        <dd><sis-localized-markup-string-view [content]="description"></sis-localized-markup-string-view></dd>
      </ng-template>
      <ng-template #listItem *ngIf="completionMethod?.prerequisites | localizedString as prerequisites">
        <dt>{{'FIELD_NAMES.prerequisites' | translate}}</dt>
        <dd>{{prerequisites}}</dd>
      </ng-template>
      <ng-template #listItem *ngIf="completionMethod?.evaluationCriteria | localizedString as evaluationCriteria">
        <dt>{{'FIELD_NAMES.grading' | translate}}</dt>
        <dd>{{evaluationCriteria}}</dd>
      </ng-template>
    </sis-description-list>
  </div>
</div>
