import { inject, Injectable } from '@angular/core';
import { RawParams } from '@uirouter/core';
import { Plan, StudyRight } from 'common-typescript/types';

import { PlanSeekerService } from '../common/service/plan-seeker.service';

import { STUDENT_PLAN_CREATE_FIRST_PLAN,
    STUDENT_PLAN_STRUCTURE } from './plan-ng.route.constant';

@Injectable({
    providedIn: 'root',
})
export class PlanRedirectionService {

    planSeekerService = inject(PlanSeekerService);
    resolvePlanRedirectionState(studentPlans: Plan[], studentStudyRights: StudyRight[]): { state?: string, params?: RawParams } {
        if (studentPlans.length === 0 || !studentPlans) {
            return { state: STUDENT_PLAN_CREATE_FIRST_PLAN };
        }

        const planId = this.planSeekerService.resolvePlan(studentPlans, studentStudyRights);

        return { state: STUDENT_PLAN_STRUCTURE, params: { planId } };
    }
}
