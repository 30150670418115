<div class="guidance" *ngIf="activeOiliPayments.length === 0 && activeTuitionFeePayments.length === 0">
  {{'PROFILE.PAYMENTS.TABLE.NO_PAYMENTS' | translate}}
</div>
<ng-container *ngIf="activeOiliPayments.length > 0">
  <app-oili-payments-table [payments]="activeOiliPayments"
                           [caption]="'PROFILE.PAYMENTS.COMMON_PAYMENTS' | translate"
                           data-cy="current-and-future-payments-table">
  </app-oili-payments-table>
</ng-container>
<div *ngIf="invalidatedOiliPayments.length > 0" class="invalidated-payments">
  <sis-expandable [variant]="'link'" [title]="'PROFILE.PAYMENTS.INVALIDATED_COMMON_PAYMENTS' | translate" [level]="level">
    <ng-template sisExpandableContent>
      <app-oili-payments-table *ngIf="invalidatedOiliPayments.length > 0"
                               [payments]="invalidatedOiliPayments"
                               [caption]="'PROFILE.PAYMENTS.INVALIDATED_COMMON_PAYMENTS' | translate">
      </app-oili-payments-table>
    </ng-template>
  </sis-expandable>
</div>
<ng-container *ngIf="activeTuitionFeePayments.length > 0">
  <app-tuition-fee-payments-table [payments]="activeTuitionFeePayments"
                                  [caption]="'PROFILE.PAYMENTS.TUITION_FEE_PAYMENTS' | translate">
  </app-tuition-fee-payments-table>
</ng-container>
<div *ngIf="invalidatedTuitionFeePayments.length > 0"
     class="invalidated-payments">
  <sis-expandable [variant]="'link'" [title]="'PROFILE.PAYMENTS.INVALIDATED_TUITION_FEE_PAYMENTS' | translate" [level]="level">
    <ng-template sisExpandableContent>
      <ng-container *ngIf="invalidatedTuitionFeePayments.length > 0">
        <app-tuition-fee-payments-table [payments]="invalidatedTuitionFeePayments"
                                        [caption]="'PROFILE.PAYMENTS.INVALIDATED_TUITION_FEE_PAYMENTS' | translate">
        </app-tuition-fee-payments-table>
      </ng-container>
    </ng-template>
  </sis-expandable>
</div>
