commonMessageService.$inject = ["$translate", "$http", "$q", "messageJSDataModel", "MessageType", "PlanOperationType", "MessageEndpoint", "defaultPromiseHandler"];
import angular from 'angular';
import _ from 'lodash';
export const commonMessageServiceModule = 'sis-components.service.commonMessageService';
angular.module(commonMessageServiceModule, ['sis-components.constant.messageTypes', 'sis-components.model.message', 'sis-common.errorhandler.errorService']).service('commonMessageService', commonMessageService);

/**
 * @ngInject
 */

function commonMessageService($translate, $http, $q, messageJSDataModel, MessageType, PlanOperationType, MessageEndpoint, defaultPromiseHandler) {
  function loadRelations(message) {
    const relations = ['parentModule', 'module', 'courseUnit', 'plan', 'student', 'courseUnitRealisation'];
    return messageJSDataModel.loadRelations(message, relations).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
  }
  function resolveMessageFilterType(rawFilterString) {
    if (_.isNil(rawFilterString) || _.isEmpty(rawFilterString)) {
      return 'ALL';
    }
    if (!_.isString(rawFilterString)) {
      return 'ALL';
    }
    const comparisonString = rawFilterString.toLowerCase();
    if (_.isEqual(comparisonString, 'student')) {
      return 'STUDENT';
    }
    if (_.includes(['staff', 'tutor', 'admin', 'personnel', 'teacher'], comparisonString)) {
      return 'PERSONNEL';
    }
    return 'ALL';
  }
  return {
    createMessage(message) {
      const clone = _.clone(message);
      return messageJSDataModel.create(clone);
    },
    findByPlanId(planId, force, sinceTime) {
      const options = {
        endpoint: MessageEndpoint.FOR_PLAN,
        bypassCache: !!force
      };
      const params = {
        planId
      };
      if (sinceTime) {
        params.sinceTime = sinceTime;
        options.bypassCache = true;
      }
      return messageJSDataModel.findAll(params, options).then(messages => _.map(messages, message => {
        loadRelations(message);
        return message;
      }));
    },
    findForPlansByEducation(userId, educationId, learningOpportunityId) {
      const params = {
        userId,
        educationId,
        learningOpportunityId
      };
      const options = {
        endpoint: MessageEndpoint.FOR_PLANS_BY_EDUCATION,
        bypassCache: true
      };
      return messageJSDataModel.findAll(params, options).then(messages => _.map(messages, message => {
        loadRelations(message);
        return message;
      }));
    },
    findMessage(messageId, force) {
      const options = {
        endpoint: MessageEndpoint.DEFAULT,
        bypassCache: !!force
      };
      return messageJSDataModel.find(messageId, options).then(message => message
      // OTM-18221 no need to load relations for now (causes access denied for plan & student on plain STAFF role)
      // return loadRelations(message);
      );
    },
    findAllReceivedMessages(personId, sinceTime, start, limit, filterType, studentSearch, messageTypes) {
      const params = {
        params: {
          recipientId: personId,
          start,
          limit,
          filterType: resolveMessageFilterType(filterType),
          studentSearch,
          messageTypes
        }
      };
      if (sinceTime) {
        params.params.sinceTime = sinceTime;
      }
      return $http.get(MessageEndpoint.RECEIVED, params).then(result => {
        result.data.messages = _.isEmpty(result.data.searchResults) ? [] : messageJSDataModel.inject(result.data.searchResults);
        return result.data;
      });
    },
    findAllSentMessages(personId, sinceTime, start, limit) {
      const params = {
        params: {
          senderId: personId,
          start,
          limit
        }
      };
      if (sinceTime) {
        params.params.sinceTime = sinceTime;
      }
      return $http.get(MessageEndpoint.SENT, params).then(result => {
        result.data.messages = _.isEmpty(result.data.searchResults) ? [] : messageJSDataModel.inject(result.data.searchResults);
        return result.data;
      });
    },
    findReceivedByTuteesFromTutorsMessages(tutorPersonId, sinceTime, start, limit, filterType) {
      const params = {
        params: {
          tutorPersonId,
          filterType,
          start,
          limit
        }
      };
      if (sinceTime) {
        params.params.sinceTime = sinceTime;
      }
      return $http.get(MessageEndpoint.FOR_TUTEES_OF_TUTOR, params).then(result => {
        result.data.messages = _.isEmpty(result.data.searchResults) ? [] : messageJSDataModel.inject(result.data.searchResults);
        return result.data;
      });
    },
    markAsRead(messageId) {
      return $http.put(MessageEndpoint.MARK_AS_READ + messageId, {}, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(() => {
        messageJSDataModel.eject(messageId);
        return messageJSDataModel.find(messageId, {
          endpoint: MessageEndpoint.DEFAULT
        }).then(message => message
        // OTM-18221 no need for loading relations for now (causes access denied for plan & student on plain STAFF role)
        // return loadRelations(message);
        );
      });
    },
    findByCourseUnitRealisationId(courseUnitRealisationId, force, sinceTime) {
      const options = {
        endpoint: MessageEndpoint.FOR_COURSE_UNIT_REALISATION,
        bypassCache: !!force
      };
      const params = {
        courseUnitRealisationId
      };
      if (sinceTime) {
        params.sinceTime = sinceTime;
        options.bypassCache = true;
      }
      return messageJSDataModel.findAll(params, options).then(messages => $q.all(messages.map(loadRelations)));
    },
    /**
     * The specific type of a message shown in the UI (e.g. "Suggestion for adding a course") is defined by
     * the combination of message.type and message.operationType, but all messages don't have the latter
     * property. This function resolves the translation key for for the specific type of any kind of message.
     */
    getMessageTypeTranslationKey(message) {
      if (message && _(MessageType).values().includes(_.get(message, 'type'))) {
        return `SIS_COMPONENTS.MESSENGER.MESSAGE.TYPE.${message.type}.${_.get(PlanOperationType, message.operationType, 'NAME')}`;
      }
      return '';
    },
    getMessageTypeOptionsForSearch() {
      return _.map(_.keys(MessageType), messageTypeKey => ({
        name: `SIS_COMPONENTS.MESSENGER.MESSAGE.TYPE.${_.get(MessageType, messageTypeKey)}.NAME`,
        id: messageTypeKey
      }));
    },
    getMessageStateOptionsForSearch() {
      return [{
        id: 'Unread',
        name: $translate.instant('SIS_COMPONENTS.MESSENGER.MESSAGE.READSTATE.UNREAD')
      }];
    },
    getMessageReferences(message) {
      return {
        courseUnitRealisationId: _.get(message, 'courseUnitRealisationId', null),
        applicationId: _.get(message, 'applicationId', null),
        planId: _.get(message, 'planId', null),
        courseUnitId: _.get(message, 'courseUnitId', null)
      };
    },
    resolveMessageFilterType
  };
}