import {
    ChangeDetectionStrategy,
    Component, computed,
    Input,
    Signal,
    signal,
    ViewEncapsulation,
    WritableSignal,
} from '@angular/core';
import { CreditRange, CreditsRule, EntityWithRule, RangeValidationResultState } from 'common-typescript/types';
import _ from 'lodash';

@Component({
    selector: 'sis-plan-structure-credit-rule-header',
    templateUrl: './plan-structure-credit-rule-header.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,

})
export class PlanStructureCreditRuleHeaderComponent {

    _rule: WritableSignal<CreditsRule> = signal(undefined);
    @Input({ required: true }) set rule(rule: CreditsRule) {
        this._rule.set(rule);
    }

    _ruleValidationResults: WritableSignal<any> = signal(undefined);
    @Input({ required: true }) set ruleValidationResults(ruleValidationResults: any) {
        this._ruleValidationResults.set(ruleValidationResults);
    }

    _parentModule: WritableSignal<EntityWithRule> = signal(undefined);
    @Input({ required: true }) set parentModule(parentModule: EntityWithRule) {
        this._parentModule.set(parentModule);
    }

    @Input() ruleLegendId: string;
    @Input({ required: true }) ruleDepthLevel: number;
    @Input() onlyShowRule: boolean;

    targetCredits: Signal<CreditRange> = computed(this.targetCreditsComputation());
    plannedCredits: Signal<CreditRange> = computed(this.plannedCreditsComputation());
    rangeValidationResultState: Signal<RangeValidationResultState> = computed(this.rangeValidationResultStateComputation());

    targetCreditsComputation(): () => CreditRange {
        return () => {
            if (this.ruleDepthLevel === 1 && _.get(this._parentModule(), 'targetCredits')) {
                return {
                    min: _.get(this._parentModule(), 'targetCredits.min', null),
                    max: _.get(this._parentModule(), 'targetCredits.max', null),
                };
            }
            return {
                min: this._rule().credits.min,
                max: this._rule().credits.max || null,
            };
        };
    }

    plannedCreditsComputation(): () => CreditRange {
        return () => this._ruleValidationResults().plannedCredits;
    }

    rangeValidationResultStateComputation(): () => RangeValidationResultState {
        return () => this._ruleValidationResults()?.result as unknown as RangeValidationResultState;
    }

}
