import { Inject, Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { Attainment, OtmId } from 'common-typescript/types';
import { Observable, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { mono } from 'sis-common/mono/monoUtils';
import { VALID_ATTAINMENT_FILTER_SERVICE } from 'sis-components/ajs-upgraded-modules';
import { EntityService } from 'sis-components/service/entity.service';
import { SisuSimpleDataLoader } from 'sis-components/service/SisuSimpleDataLoader';

const CONFIG = {
    ENDPOINTS: {
        backend: '/ori/api',
        getMyAttainments() {
            return `${this.backend}/my-attainments`;
        },
    },
} as const;
@Injectable({
    providedIn: 'root',
})
@NgEntityServiceConfig({
    baseUrl: CONFIG.ENDPOINTS.backend,
    resourceName: 'attainments',
})
export class AttainmentStudentService extends EntityService<AttainmentState> {

    private readonly allAttainmentsLoader: SisuSimpleDataLoader<Attainment[]>;

    constructor(@Inject(VALID_ATTAINMENT_FILTER_SERVICE) private validAttainmentFilterService: any) {
        super(AttainmentStore, AttainmentQuery);

        this.allAttainmentsLoader = new SisuSimpleDataLoader({
            requestCreator: () => mono(this.getHttp().get<Attainment[]>(CONFIG.ENDPOINTS.getMyAttainments())),
            onBeforeRequest: () => this.setLoading(true),
            onAfterRequest: {
                next: attainments => this.store.set(attainments),
                error: () => this.setLoading(false),
            },
        });
    }

    /**
     * Returns all attainments for student filtered using the logic in ValidAttainmentFilterService.
     * This should be used most of the time.
     *
     * @param bypassStore Ignore cache and fetch from backend
     */
    getMyValidAttainments(bypassStore = false): Observable<Attainment[]> {
        return this.getMyAttainments(bypassStore).pipe(
            map((attainments) =>
                this.validAttainmentFilterService.getValidAttainments(attainments) as Attainment[]));
    }

    /**
     * Returns all attainments for student without any filtering.
     * Use this if you are sure it is what you need.
     *
     * @param bypassStore Ignore cache and fetch from backend
     */
    getMyUnfilteredAttainments(bypassStore = false): Observable<Attainment[]> {
        return this.getMyAttainments(bypassStore);
    }

    /**
     * Returns all student attainments. Selects all from store if marked as cached.
     *
     * @param bypassStore Ignore cache and fetch from backend
     * @private
     */
    private getMyAttainments(bypassStore = false): Observable<Attainment[]> {
        if (!bypassStore && this.query.getHasCache()) {
            return this.selectAll();
        }

        return this.allAttainmentsLoader.load()
            .pipe(switchMap(() => this.query.selectAll()));
    }
}

type AttainmentState = EntityState<Attainment, OtmId>;

@StoreConfig({ name: 'student-attainments', cache: { ttl: 60000 } })
class AttainmentStore extends EntityStore<AttainmentState> {}

class AttainmentQuery extends QueryEntity<AttainmentState> {
    constructor(protected store: AttainmentStore) {
        super(store);
    }
}
