import { ChangeDetectionStrategy, Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Event, OtmId, StudyEvent } from 'common-typescript/types';
import { STUDY_EVENT_SERVICE } from 'sis-components/ajs-upgraded-modules';

@Component({
    selector: 'app-student-study-event-structure',
    templateUrl: './student-study-event-structure.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudentStudyEventStructureComponent implements OnInit {

    @Input() studyEvent: StudyEvent;
    @Input() teacherIds: OtmId[];
    @Input() language: string;

    cancelledOrExcludedEventCount: number;
    allEventsCancelledOrExcluded: boolean;
    allStudyEventTeacherIds: OtmId[];
    hasIrregularLocations: boolean;
    hasNoLocations: boolean;

    irregularEvents: Event[] = [];

    constructor(@Inject(STUDY_EVENT_SERVICE) private commonStudyEventService: any) { }

    ngOnInit(): void {
        this.cancelledOrExcludedEventCount = this.countCancelledOrExcludedEvents();
        this.allEventsCancelledOrExcluded = this.getAllEventsCancelledOrExcluded();
        this.allStudyEventTeacherIds = this.getAllTeacherIds();
        this.hasIrregularLocations = this.getHasIrregularLocations();
        this.hasNoLocations = this.studyEvent.locationIds?.length === 0;
        this.irregularEvents = this.getIrregularEvents();
    }

    countCancelledOrExcludedEvents(): number {
        return this.filteredEvents(event => event.cancelled || event.excluded).length;
    }

    getAllEventsCancelledOrExcluded(): boolean {
        return this.studyEvent.events.every(event => event.cancelled || event.excluded);
    }

    getAllTeacherIds(): OtmId[] {
        const overrideTeachers = this.filteredEvents(ev => !ev.excluded)
            .flatMap(ev => ev?.irregularTeacherIds ?? []);
        return [...new Set([...this.teacherIds, ...overrideTeachers])];
    }

    getHasIrregularLocations(): boolean {
        return this.filteredEvents(ev => (!ev.excluded || !ev.cancelled)
            && ev.irregularLocationIds?.length > 0).length > 0;
    }

    getIrregularEvents(): Event[] {
        return this.filteredEvents((ev: Event) =>
            ev.excluded ||
            ev.cancelled ||
            ev.irregularLocationIds !== null ||
            !!ev.notice,
        );
    }

    filteredEvents(predicate: (ev: Event) => boolean): Event[] {
        return this.studyEvent.events.filter(predicate);
    }

}
