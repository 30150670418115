import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UIRouterGlobals } from '@uirouter/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { BadgeData, HeaderService } from '../header.service';

/**
 * Sis-page-title component can either fetch route and page information from header service, or the information can be
 * given through the input properties. If given, input attributes overrides the data fetched from the header service.
 */
@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-page-title',
    templateUrl: './page-title.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class PageTitleComponent implements OnInit, OnDestroy {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.downgraded.page-title',
        directiveName: 'sisPageTitle',
    };

    /** Set true if page has header crumbs visible */
    @Input() showCrumbs?: boolean = false;
    @Input() title?: string;
    @Input() subTitle?: string;
    @Input() dataCy?: string = 'page-title';

    badges: BadgeData[] = [];
    breadcrumbCount: number;

    private breadcrumbPageTitle: string;
    private customPageTitle: string;
    private customPageSubTitle: string;
    private subscriptions: Subscription[] = [];

    constructor(private headerService: HeaderService,
                private uiRouterGlobals: UIRouterGlobals) {}

    ngOnInit(): void {
        this.subscriptions = [
            this.headerService.breadcrumbsChanged.subscribe((breadcrumbs) => {
                this.breadcrumbCount = breadcrumbs.length;
                this.breadcrumbPageTitle = _.last(breadcrumbs)?.displayName;
            }),
            this.headerService.customPageTitleChanged.subscribe(title => this.customPageTitle = title),
            this.headerService.customPageSubTitleChanged.subscribe(subTitle => this.customPageSubTitle = subTitle),
            this.headerService.pageTitleBadgesChanged.subscribe(badges => this.badges = badges),
        ];
    }

    ngOnDestroy(): void {
        this.subscriptions?.forEach(sub => sub.unsubscribe());
    }

    get showBreadcrumbs(): boolean {
        return this.showCrumbs ? true : (this.breadcrumbCount > 1 && !this.uiRouterGlobals.$current?.data?.headerWithoutBreadcrumbs);
    }

    get pageTitle(): string {
        return this.title ?? this.customPageTitle ?? this.breadcrumbPageTitle;
    }

    get pageSubTitle(): string {
        return this.subTitle || this.customPageSubTitle;
    }
}
