<ng-container *ngIf="productId">
  <sis-button *ngIf="!isProductInCart"
              (clicked)="addToCart()"
              [disabled]="requestInProgress"
              data-cy="add-to-cart">
    {{'OPEN_UNIVERSITY.STUDY_OFFERING.ADD_TO_CART' | translate}}
  </sis-button>
  <sis-button *ngIf="isProductInCart"
              (clicked)="removeFromCart()"
              [disabled]="requestInProgress"
              [hollow]="true"
              data-cy="remove-from-cart">
    <sis-icon icon="delete" color="primary"></sis-icon>
    {{'OPEN_UNIVERSITY.STUDY_OFFERING.REMOVE_FROM_CART' | translate}}
  </sis-button>
</ng-container>
