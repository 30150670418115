<ng-container *ngIf="identityProviders?.length > 0">
  <a href="javascript:void(0)"
     *ngIf="identityProviders.length === 1"
     (click)="identityProviders[0].navigate()"
     class="login-link">
    {{'LOGIN' | translate}}
  </a>
    <div *ngIf="identityProviders.length > 1" ngbDropdown display="static" class="nav-menu">
      <button type="button" ngbDropdownToggle class="non-button" data-cy="login-button">
        {{'LOGIN' | translate}}
        <sis-icon icon="chevron" iconClass="cw-90" color="white"></sis-icon>
      </button>
      <nav [attr.aria-label]="'LOGIN' | translate"  role="navigation" ngbDropdownMenu>
        <ul class="sis-p-zero">
          <li *ngFor="let provider of identityProviders" class="d-block">
            <a class="dropdown-login-link" href="javascript:void(0)"  (click)="provider.navigate()" ngbDropdownItem>
              {{provider.name}}
            </a>
          </li>
        </ul>
      </nav>
    </div>
</ng-container>
