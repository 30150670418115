import angular from 'angular';
export const personRuleTypesModule = 'sis-components.constant.personRuleTypes';
(function () {
  angular.module(personRuleTypesModule, []).constant('PersonRuleTypes', {
    VALID_STUDY_RIGHT: 'ValidStudyRight',
    VALID_TERM_REGISTRATION: 'ValidTermRegistration',
    PERSON_GROUP_MEMBERSHIP: 'PersonGroupMembership',
    COURSE_UNIT_IN_PRIMARY_PLAN: 'CourseUnitInPrimaryPlan',
    COMPULSORY_FORMAL_PREREQUISITES: 'CompulsoryFormalPrerequisites',
    RECOMMENDED_FORMAL_PREREQUISITES: 'RecommendedFormalPrerequisites',
    ATTAINED_CREDIT_RANGE: 'AttainedCreditRange',
    ENROLMENT_FOR_COURSE_UNIT_REALISATION: 'EnrolmentForCourseUnitRealisation',
    NOT_ALREADY_ENROLLED_TO_ASSESSMENT_ITEM: 'NotAlreadyEnrolledToAssessmentItemRule'
  }).constant('PersonRuleTypeTranslationKeys', {
    ValidStudyRight: 'PERSON_RULE.VALID_STUDY_RIGHT',
    ValidTermRegistration: 'PERSON_RULE.VALID_TERM_REGISTRATION',
    PersonGroupMembership: 'PERSON_RULE.PERSON_GROUP_MEMBERSHIP',
    CourseUnitInPrimaryPlan: 'PERSON_RULE.COURSE_UNIT_IN_PRIMARY_PLAN',
    CompulsoryFormalPrerequisites: 'PERSON_RULE.COMPULSORY_FORMAL_PREREQUISITES',
    RecommendedFormalPrerequisites: 'PERSON_RULE.RECOMMENDED_FORMAL_PREREQUISITES',
    AttainedCreditRange: 'PERSON_RULE.ATTAINED_CREDIT_RANGE.NAME',
    EnrolmentForCourseUnitRealisation: 'PERSON_RULE.ENROLMENT_FOR_COURSE_UNIT_REALISATION',
    NotAlreadyEnrolledToAssessmentItemRule: 'PERSON_RULE.NOT_ALREADY_ENROLLED_TO_ASSESSMENT_ITEM'
  });
})();