<span [ngbTooltip]="defaultTemplate"
      [placement]="tooltipPlacement"
      [container]="(appendToBody === true) ? 'body': ''"
      [disableTooltip]="!tooltipEnabled"
      [tooltipClass]="tooltipClass"
      [triggers]="tooltipTriggers"
      [ngClass]="{'treat-as-a-block-element': tooltipBlockElement}">
  <ng-content select=".sis-tooltip-trigger-content"></ng-content>
</span>
<ng-template #defaultTemplate>
  <span *ngIf="tooltipText">{{ tooltipText }}</span>
  <div *ngIf="tooltipInnerHtml" class="sis-tooltip-content">
    <sis-markup-string-view [content]="tooltipInnerHtml"></sis-markup-string-view>
  </div>
  <ng-content select=".sis-tooltip-content"></ng-content>
</ng-template>
