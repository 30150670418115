<ng-container *ngFor="let node of nodes">
    <div *ngIf="node.type === 'CourseUnit'" class="tree-list-body">
        <div class="row">
            <div class="col-sm-6 tree-list-body-inner">
                <sis-plan-course-unit [courseUnit]="this.planData.courseUnitsById[node.courseUnitId]"
                                      [planStateObject]="planStateObject"
                                      [planData]="planData">
                </sis-plan-course-unit>
            </div>
        </div>
    </div>


    <sis-plan-module *ngIf="node.type === 'Module'"
                     [module]="this.planData.modulesById[node.moduleId]"
                     [planStateObject]="planStateObject"
                     [planData]="planData"
                     [uiStateObject]="uiStateObject"
                     [level]="level+1">
    </sis-plan-module>
    <div *ngIf="node.type === 'CustomCourseUnitAttainment'" class="tree-list-body">
        <div class="row">
            <div class="col-sm-6 tree-list-body-inner">
                <sis-plan-custom-course-unit-attainment [customCourseUnitAttainment]="this.planData.attainmentsById[node.customCourseUnitAttainmentId]"
                                                        [planStateObject]="planStateObject">
                </sis-plan-custom-course-unit-attainment>
            </div>
        </div>
    </div>

    <sis-plan-custom-module-attainment *ngIf="node.type === 'CustomModuleAttainment'"
                                       [customModuleAttainment]="this.planData.attainmentsById[node.customModuleAttainmentId]"
                                       [planStateObject]="planStateObject"
                                       [planData]="planData"
                                       [uiStateObject]="uiStateObject"
                                       [level]="level+1">
    </sis-plan-custom-module-attainment>

    <sis-attainment-group-node *ngIf="node.type === 'AttainmentGroupNode'"
                               [attainmentGroupNode]="node"
                               [planStateObject]="planStateObject"
                               [planData]="planData"
                               [uiStateObject]="uiStateObject"
                               [level]="level+1">

    </sis-attainment-group-node>
</ng-container>
