import { Component, Input, ViewEncapsulation } from '@angular/core';
import { CourseUnit } from 'common-typescript/types';

@Component({
    selector: 'app-course-unit-info-basic-info-header',
    templateUrl: './course-unit-info-basic-info-header.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CourseUnitInfoBasicInfoHeaderComponent {

    @Input() courseUnit: CourseUnit;
}
