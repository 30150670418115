import angular from 'angular';
export const removeHtmlFilterModule = 'sis-components.search.removeHtmlFilter';
(function () {
  angular.module(removeHtmlFilterModule, []).filter('removeHtml', removeHtmlFilter);

  /**
   * @ngInject
   * @ngDoc
   * Handy filter for search results where you don't want name field bold.
   */
  function removeHtmlFilter() {
    return function (text) {
      return text ? String(text).replace(/<[^>]+>/gm, '') : '';
    };
  }
})();