import studentApplicationStateBadgeTpl from './studentApplicationStateBadge.tpl.html';
export const studentApplicationStateBadgeModule = 'sis-components.applications.studentApplicationStateBadge';
"use strict";
(function () {
  angular.module(studentApplicationStateBadgeModule, []).directive('studentApplicationStateBadge', studentApplicationStateBadge).controller('studentApplicationStateBadgeController', studentApplicationStateBadgeController);

  /**
   * @ngInject
   */
  function studentApplicationStateBadge() {
    return {
      restrict: 'E',
      scope: {
        state: '<',
        badgeClass: '<'
      },
      controller: 'studentApplicationStateBadgeController as ctrl',
      template: studentApplicationStateBadgeTpl,
      bindToController: true
    };
  }

  /**
   * @ngInject
   */
  function studentApplicationStateBadgeController() {}
})();