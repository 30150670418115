import { Component, Inject, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ValidatablePlan } from 'common-typescript';
import { AssessmentItem, Attainment, AttainmentType, CompletionMethod, CourseUnit, CourseUnitRealisation, LocalizedString, OtmId } from 'common-typescript/types';
import _ from 'lodash';
import { map, take } from 'rxjs/operators';
import { DEFAULT_PROMISE_HANDLER } from 'sis-common/ajs-upgraded-modules';
import {
    ComponentDowngradeMappings,
    DowngradedComponent,
    StaticMembers,
} from 'sis-common/types/angular-hybrid';

import {
    COURSE_UNIT_REALISATION_SERVICE,
} from '../../ajs-upgraded-modules';
import { AppErrorHandler } from '../../error-handler/app-error-handler';
import { PlanActionsService } from '../../plan/plan-actions-service/plan-actions.service';
import { AssessmentItemEntityService } from '../../service/assessment-item-entity.service';
import { CourseUnitRealisationEntityService } from '../../service/course-unit-realisation-entity.service';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-selectable-completion-methods',
    templateUrl: './selectable-completion-methods.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class SelectableCompletionMethodsComponent implements OnChanges {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.courseUnit.selectableCompletionMethods',
        directiveName: 'sisSelectableCompletionMethods',
    };

    hasValue: boolean;
    completionMethods: CompletionMethod[];
    assessmentItemsById: { [id: string]: AssessmentItem };
    assessmentItemAttainmentsById: { [id: string]: Attainment };
    orderedAssessmentItemIds: OtmId[];
    courseUnitRealisationsById: { [id: string]: CourseUnitRealisation };
    selectedCompletionMethod: CompletionMethod;
    highlightedCompletionMethod: CompletionMethod;
    courseUnitRealisationsReady = false;
    completionMethodAssessmentItems: AssessmentItem[];
    isCourseUnitRealisationExpandedById: { [id: string]: boolean };
    isAssessmentItemExpandedById: { [id: string]: boolean };
    shownRelations = 5;

    @Input() courseUnit: CourseUnit;
    @Input() validatablePlan: ValidatablePlan;
    @Input() planActionsService?: PlanActionsService;
    @Input() language: string;

    constructor(
        private appErrorHandler: AppErrorHandler,
        private assessmentItemEntityService: AssessmentItemEntityService,
        private courseUnitRealisationEntityService: CourseUnitRealisationEntityService,
        @Inject(COURSE_UNIT_REALISATION_SERVICE) private courseUnitRealisationService: any,
        @Inject(DEFAULT_PROMISE_HANDLER) private defaultPromiseHandler: any,

    ) {}

    ngOnChanges(changes: SimpleChanges) {
        this.selectedCompletionMethod = this.validatablePlan.getSelectedCompletionMethod(this.courseUnit);
        this.assessmentItemAttainmentsById = _.keyBy(
            _.filter(this.validatablePlan.getAllAttainments(), attainment => attainment.type === AttainmentType.ASSESSMENT_ITEM_ATTAINMENT),
            'assessmentItemId',
        );
        this.completionMethods = this.courseUnit.completionMethods;
        if (this.selectedCompletionMethod) {
            this.highlightedCompletionMethod = this.selectedCompletionMethod;
        }
        if (!this.highlightedCompletionMethod) {
            this.highlightedCompletionMethod = _.first(this.completionMethods);
        }
        if (changes.courseUnit) {
            this.courseUnitRealisationsReady = false;
            this.assessmentItemsById = null;
            this.getAssessmentItemsAndCourseUnitRealisations();
        }
    }

    highlightCompletionMethod(completionMethod: CompletionMethod) {
        this.highlightedCompletionMethod = completionMethod;
        this.updateHighlightedCompletionMethod(completionMethod);
    }

    selectCompletionMethod(completionMethod: CompletionMethod) {
        this.planActionsService.selectCompletionMethod(this.courseUnit, completionMethod.localId);
    }

    getAssessmentItemsAndCourseUnitRealisations() {
        this.courseUnitRealisationsReady = false;
        const assessmentItemIdsFromCompletionMethods = _.flatMap(this.completionMethods, completionMethod =>
            completionMethod.assessmentItemIds);
        this.orderedAssessmentItemIds = _.compact(_.union(this.courseUnit.assessmentItemOrder, assessmentItemIdsFromCompletionMethods));
        this.assessmentItemEntityService.getByIds(this.orderedAssessmentItemIds).pipe(
            take(1),
            map((assessmentItems) => {
                this.assessmentItemsById = _.keyBy(assessmentItems, 'id');
                this.updateHighlightedCompletionMethod(this.highlightedCompletionMethod);
            }),
            this.appErrorHandler.defaultErrorHandler())
            .subscribe();
        this.getCourseUnitRealisationsForAssessmentItems(this.orderedAssessmentItemIds);
    }

    getCourseUnitRealisationsForAssessmentItems(assessmentItemIds: OtmId[]) {
        this.courseUnitRealisationsById = {};
        const promises = _.map(assessmentItemIds, (assessmentItemId) => this.courseUnitRealisationService.findByAssessmentItemId(assessmentItemId).then((courseUnitRealisations: CourseUnitRealisation[]) => {
            _.forEach(courseUnitRealisations, (courseUnitRealisation) => {
                this.courseUnitRealisationsById[courseUnitRealisation.id] = courseUnitRealisation;
            });
        }));
        Promise.all(promises).then(() => {
            this.courseUnitRealisationsReady = true;
        }).catch(this.defaultPromiseHandler.loggingRejectedPromiseHandler);
    }

    completionMethodContainsAssessmentItemId(completionMethod: CompletionMethod, assessmentItemId: OtmId) {
        if (!completionMethod) {
            return false;
        }
        return _.includes(completionMethod.assessmentItemIds, assessmentItemId);
    }

    updateCompletionMethodAssessmentItems(completionMethod: CompletionMethod) {
        if (!this.assessmentItemsById || !completionMethod) {
            return;
        }
        const completionMethodAssessmentItems: AssessmentItem[] = [];
        _.forEach(this.orderedAssessmentItemIds, (assessmentItemId) => {
            if (_.includes(completionMethod.assessmentItemIds, assessmentItemId)) {
                completionMethodAssessmentItems.push(this.assessmentItemsById[assessmentItemId]);
            }
        });
        this.completionMethodAssessmentItems = completionMethodAssessmentItems;
        const openCourseUnitRealisationMap: { [id: string]: boolean } = {};
        const openAssessmentItemMap: { [id: string]: boolean } = {};
        _.forEach(this.completionMethodAssessmentItems, (assessmentItem) => {
            openAssessmentItemMap[assessmentItem.id] = true;
            const courseUnitRealisations = this.getCourseUnitRealisationsForAssessmentItem(assessmentItem);
            _.forEach(courseUnitRealisations, (courseUnitRealisation) => {
                openCourseUnitRealisationMap[courseUnitRealisation.id] = false;
            });
        });
        this.isAssessmentItemExpandedById = openAssessmentItemMap;
        this.isCourseUnitRealisationExpandedById = openCourseUnitRealisationMap;
    }

    isAssessmentItemSelectedInCompletionMethod(assessmentItemId: OtmId, completionMethod: CompletionMethod) {
        if (!assessmentItemId || !completionMethod) {
            return false;
        }
        if (completionMethod.localId !== this.selectedCompletionMethod?.localId) {
            return false;
        }
        if (!_.includes(completionMethod.assessmentItemIds, assessmentItemId)) {
            return false;
        }
        const assessmentItemSelections = _.get(this.validatablePlan, 'plan.assessmentItemSelections');
        const assessmentItemSelection = _.find(assessmentItemSelections, { assessmentItemId });
        if (!assessmentItemSelection || assessmentItemSelection.courseUnitId !== this.courseUnit.id) {
            return false;
        }
        return true;
    }

    isAssessmentItemSelectedForCourseUnit(assessmentItemId: OtmId) {
        const assessmentItemSelections = _.get(this.validatablePlan, 'plan.assessmentItemSelections');
        const assessmentItemSelection = _.find(assessmentItemSelections, { assessmentItemId });
        return assessmentItemSelection && assessmentItemSelection.courseUnitId === this.courseUnit.id;
    }

    toggleAssessmentItemSelection(assessmentItemId: OtmId, completionMethod: CompletionMethod) {
        const isAssessmentItemSelected = this.isAssessmentItemSelectedInCompletionMethod(assessmentItemId, completionMethod);
        if (isAssessmentItemSelected) {
            this.planActionsService.removeAssessmentItemSelection(this.courseUnit, assessmentItemId);
            return;
        }
        if (!isAssessmentItemSelected) {
            const assessmentItemSelections = _.get(this.validatablePlan, 'plan.assessmentItemSelections');
            const assessmentItemSelection = _.find(assessmentItemSelections, { assessmentItemId });
            if (assessmentItemSelection && assessmentItemSelection.courseUnitId !== this.courseUnit.id) {
                return;
            }
            this.planActionsService.addAssessmentItemSelection(this.courseUnit, assessmentItemId);
        }

    }

    canAssessmentItemBeSelected(assessmentItemId: OtmId): boolean {
        const assessmentItemSelections = _.get(this.validatablePlan, 'plan.assessmentItemSelections');
        const assessmentItemSelection = _.find(assessmentItemSelections, { assessmentItemId });
        return !(assessmentItemSelection && assessmentItemSelection.courseUnitId !== this.courseUnit.id);
    }

    getCourseUnitForAssessmentItem(assessmentItemId: OtmId): CourseUnit {
        const assessmentItemSelections = _.get(this.validatablePlan, 'plan.assessmentItemSelections');
        const assessmentItemSelection = _.find(assessmentItemSelections, { assessmentItemId });
        if (assessmentItemSelection) {
            return this.validatablePlan?.getCourseUnit(assessmentItemSelection.courseUnitId);
        }
    }

    getCourseUnitRealisationsForAssessmentItem(assessmentItem: AssessmentItem) {
        return _.chain(_.values(this.courseUnitRealisationsById))
            .filter(cur => _.includes(cur.assessmentItemIds, assessmentItem.id))
            .value();
    }

    navigateToCurSection(courseUnitRealisation: CourseUnitRealisation) {
        const relevantAssessmentItem = _.find(this.completionMethodAssessmentItems, assessmentItem =>
            _.includes(courseUnitRealisation.assessmentItemIds, assessmentItem.id));
        if (relevantAssessmentItem) {
            this.isAssessmentItemExpandedById[relevantAssessmentItem.id] = true;
        }
        this.isCourseUnitRealisationExpandedById[courseUnitRealisation.id] = true;
        setTimeout(() => {
            const element = document.querySelector(`#${courseUnitRealisation.id}`);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        });
    }

    updateHighlightedCompletionMethod(completionMethod: CompletionMethod) {
        this.highlightedCompletionMethod = completionMethod;
        this.updateCompletionMethodAssessmentItems(completionMethod);
    }

    toggleAssessmentItem(collapsed: boolean, assessmentItemId: OtmId) {
        this.isAssessmentItemExpandedById[assessmentItemId] = !collapsed;
    }

    toggleCourseUnitRealisation(collapsed: boolean, courseUnitRealisationId: OtmId) {
        this.isCourseUnitRealisationExpandedById[courseUnitRealisationId] = !collapsed;
    }

    getHighlightedCompletionMethodIndex() {
        return _.indexOf(this.completionMethods, this.highlightedCompletionMethod);
    }

}
