<div class="sis-mb-md" *ngFor="let cooperationNetworkShare of shareListItems; let i = index">
  <ng-container [formGroup]="form">
    <label
      class="cooperation-network-details-editor-title">{{ 'COOPERATION_NETWORK_SHARE_EDITOR.TITLE' | translate:{index: i + 1} }}</label>
    <sis-cooperation-network-share-editor
      (shareRemoved)="onRemoveShare($event)"
      [cooperationNetworkShareListItem]=cooperationNetworkShare
      [cooperationNetworks]="cooperationNetworks$ | async"
      [label]="'COOPERATION_NETWORK_SHARE_EDITOR.LABEL' | translate"
      [organisationTkCode]="(organisation$ | async)?.cooperationNetworkIdentifiers?.organisationTkCode">
    </sis-cooperation-network-share-editor>
  </ng-container>
</div>
<div *ngIf="shareListItems.length > 0" class="cooperation-network-details-editor-divider"></div>
<div class="sis-mt-md">
  <sis-button size="sm" (clicked)="addShare()">
    <sis-icon icon="plus"></sis-icon>
    {{ 'COOPERATION_NETWORK_SHARE_EDITOR.ADD_NEW' | translate }}
  </sis-button>
</div>

