import { NgModule } from '@angular/core';
import _ from 'lodash';

@NgModule()
export class TranslocoLoaderHelper {
    /**
     * Get only the language part of a Transloco language parameter possibly prefixed with a scope.
     */
    static getLanguage(scopedLanguage: string): string {
        return scopedLanguage.split('/').pop();
    }

    /**
     * Join base and scoped translation files for consumption in a frontend Transloco instance.
     *
     * The parameters passed to this function must be JSON modules imported with Webpack's `import` method. Note that
     * this is different from the Transloco documentation because we use Webpack to include and dynamically import
     * the translation files. The import return values take the shape of a `Promise<any>` and as they are effectively
     * modules, the JSON contents are included as an object under the `default` key.
     *
     * @param translations List of `Promise`s of translation JSON file imports to join together.
     */
    static joinTranslations(translations: Promise<any>[]): Promise<object> {
        return Promise.all(translations)
            .then(([frontendTranslations, commonTranslations, optionalTranslations]) =>
                _.merge({},
                        commonTranslations['SIS-COMMON'],
                        commonTranslations['SIS-COMPONENTS'],
                        frontendTranslations,
                        optionalTranslations ? optionalTranslations : {}),
            );
    }
}
