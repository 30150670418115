<fudis-dl [columns]="{ sm: 1, md: 2 }" [rowGap]="'xl'" *transloco="let t">
  <ng-container *ngIf="emrexPriorStudies$ | async as emrexPriorStudies">
    <fudis-dl-item id="name">
      <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.PRIOR_LEARNING.STUDIES_NAME')"/>
      <fudis-dd [contentText]="this.priorStudies.name"/>
      <ng-container [ngTemplateOutlet]="emrexBadge" [ngTemplateOutletContext]="{emrexValue:emrexPriorStudies.name, priorStudiesValue: priorStudies.name}"></ng-container>
    </fudis-dl-item>
    <fudis-dl-item id="code">
      <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.PRIOR_LEARNING.STUDIES_CODE')"/>
      <fudis-dd [contentText]="this.priorStudies.code"/>
      <ng-container [ngTemplateOutlet]="emrexBadge" [ngTemplateOutletContext]="{emrexValue:emrexPriorStudies.code, priorStudiesValue: priorStudies.code}"></ng-container>
    </fudis-dl-item>
    <fudis-dl-item id="organisation">
      <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.PRIOR_LEARNING.STUDIES_ORGANISATION')"/>
      <fudis-dd [contentText]="this.priorStudies.organisation"/>
      <ng-container [ngTemplateOutlet]="emrexBadge" [ngTemplateOutletContext]="{emrexValue:emrexPriorStudies.organisation, priorStudiesValue: priorStudies.organisation}"></ng-container>
    </fudis-dl-item>
    <fudis-dl-item id="attainment-date">
      <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.PRIOR_LEARNING.STUDIES_ATTAINMENT_DATE')"/>
      <fudis-dd [contentText]="this.priorStudies.attainmentDate | localDateFormat"/>
      <ng-container [ngTemplateOutlet]="emrexBadge" [ngTemplateOutletContext]="{emrexValue:emrexPriorStudies.attainmentDate, priorStudiesValue: priorStudies.attainmentDate}"></ng-container>
    </fudis-dl-item>
    <fudis-dl-item id="grade">
      <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.PRIOR_LEARNING.STUDIES_GRADE')"/>
      <fudis-dd [contentText]="this.priorStudies.grade"/>
      <ng-container [ngTemplateOutlet]="emrexBadge" [ngTemplateOutletContext]="{emrexValue:emrexPriorStudies.grade, priorStudiesValue: priorStudies.grade}"></ng-container>
    </fudis-dl-item>
    <fudis-dl-item id="grade-scale">
      <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.PRIOR_LEARNING.STUDIES_GRADE_SCALE')"/>
      <fudis-dd [contentText]="this.priorStudies.gradeScale"/>
      <ng-container [ngTemplateOutlet]="emrexBadge" [ngTemplateOutletContext]="{emrexValue:emrexPriorStudies.gradeScale, priorStudiesValue: priorStudies.gradeScale}"></ng-container>
    </fudis-dl-item>
    <fudis-dl-item id="credits">
      <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.PRIOR_LEARNING.STUDIES_CREDITS')"/>
      <fudis-dd [contentText]="this.priorStudies.credits"/>
      <ng-container [ngTemplateOutlet]="emrexBadge" [ngTemplateOutletContext]="{emrexValue:emrexPriorStudies.credits, priorStudiesValue: priorStudies.credits}"></ng-container>
    </fudis-dl-item>
    <fudis-dl-item id="attainment-language">
      <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.PRIOR_LEARNING.STUDIES_ATTAINMENT_LANGUAGE')"></fudis-dt>
      <fudis-dd [contentText]="this.priorStudies.attainmentLanguage | codeLocalizedName | async"/>
      <ng-container [ngTemplateOutlet]="emrexBadge" [ngTemplateOutletContext]="{emrexValue:emrexPriorStudies.attainmentLanguage, priorStudiesValue: priorStudies.attainmentLanguage}"></ng-container>
    </fudis-dl-item>
    <fudis-dl-item id="description">
      <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.PRIOR_LEARNING.STUDIES_DESCRIPTION')"/>
      <fudis-dd [contentText]="priorStudies.description"/>
      <ng-container [ngTemplateOutlet]="emrexBadge" [ngTemplateOutletContext]="{emrexValue:emrexPriorStudies.description, priorStudiesValue: priorStudies.description}"></ng-container>
    </fudis-dl-item>
  </ng-container>
  <ng-template #emrexBadge let-emrexValue="emrexValue" let-priorStudiesValue="priorStudiesValue">
    <fudis-badge class="emrex-badge" variant="accent" *ngIf="showEmrexStudentEntryBadge(emrexValue, priorStudiesValue)">
      {{ t('STUDENT_APPLICATIONS.PRIOR_LEARNING.STUDENT_ENTRY_EMREX_BADGE') }}
    </fudis-badge>
  </ng-template>
</fudis-dl>
