"use strict";

(function () {
  booleanLocalized.$inject = ["$translate"];
  angular.module("sis-components.string.localizedFilter", []).filter('booleanLocalized', booleanLocalized);

  /**
   * @ngInject
   */
  function booleanLocalized($translate) {
    return function (input) {
      if (input === null || typeof input === 'undefined' || input === '') {
        return '';
      } else if (input === true) {
        return $translate.instant('GENERAL.TRUE');
      } else if (input === false) {
        return $translate.instant('GENERAL.FALSE');
      }
      return '';
    };
  }
})();