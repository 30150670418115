export const commonEmployeeServiceModule = 'sis-components.service.commonEmployeeService';
"use strict";
(function () {
  commonEmployeeService.$inject = ["$http"];
  angular.module(commonEmployeeServiceModule, []).factory('commonEmployeeService', commonEmployeeService);

  /**
   * @ngInject
   */
  function commonEmployeeService($http) {
    var baseUri = "/ori/api/employees";
    var api = {
      search: function (fullTextQuery, start, limit) {
        var params = {
          params: {
            searchString: fullTextQuery,
            start: start ? start : 0,
            limit: limit ? limit : 20
          }
        };
        return $http.get(baseUri, params).then(function (result) {
          return result.data;
        });
      }
    };
    return api;
  }
})();