import moduleContentApplicationDecisionSummaryTpl from './moduleContentApplicationDecisionSummary.tpl.html';
import 'angular';
import _ from 'lodash';
import 'sis-components/model/studentApplication.model';
'use strict';
(function () {
  angular.module('sis-components.applications.moduleContentApplicationDecisionSummary', ['sis-components.applications.textWithDefaultMissingValue', 'sis-components.model.studentApplication']).component('moduleContentApplicationDecisionSummary', {
    template: moduleContentApplicationDecisionSummaryTpl,
    bindings: {
      application: '<',
      decision: '<'
    },
    controller: ["$scope", "$q", "STUDENT_APPLICATION_TYPE", "STUDENT_APPLICATION_STATE", function ($scope, $q, STUDENT_APPLICATION_TYPE, STUDENT_APPLICATION_STATE) {
      $scope.STUDENT_APPLICATION_TYPE = STUDENT_APPLICATION_TYPE;
      const ctrl = this;
      ctrl.applicationValue = function (fieldPath) {
        return _.get(ctrl.application, fieldPath);
      };
      ctrl.hasFinalDecision = function () {
        return ctrl.decision && _.includes([STUDENT_APPLICATION_STATE.ACCEPTED, STUDENT_APPLICATION_STATE.CONDITIONAL, STUDENT_APPLICATION_STATE.REJECTED, STUDENT_APPLICATION_STATE.ACCEPTANCE_REVOKED, STUDENT_APPLICATION_STATE.REVOKED], ctrl.application.state);
      };
      ctrl.valueKey = function (value, translationKeyPrefix) {
        return value ? translationKeyPrefix + value : value;
      };
    }]
  });
})();