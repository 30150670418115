import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StateService } from '@uirouter/core';
import { FullCalendarCustomEvent } from 'common-typescript/types';
import { catchError, EMPTY, from, take, tap } from 'rxjs';
import { ModalService } from 'sis-common/modal/modal.service';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';

import { AddOwnCalendarEventModalComponent } from '../../../dialog/addOwnCalendarEventModal.component';

export interface OwnCalendarEventSummaryModalComponentValues {
    event: FullCalendarCustomEvent
}

@Component({
    selector: 'app-own-calendar-event-summary-modal',
    templateUrl: './own-calendar-event-summary-modal.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class OwnCalendarEventSummaryModalComponent implements OnInit {
    constructor(
        public activeModal: NgbActiveModal,
        private modalService: ModalService,
        private state: StateService,
        @Inject(ModalService.injectionToken) public values: OwnCalendarEventSummaryModalComponentValues,
        private appErrorHandler: AppErrorHandler,
    ) {}

    event: FullCalendarCustomEvent;

    ngOnInit() {
        this.event = this.values.event;
    }

    openOwnCalendarEventModal(): any {

        from(this.modalService.open(AddOwnCalendarEventModalComponent, { existingCalendarEvent: this.event.extendedProps.eventObject }).result)
            .pipe(
                take(1),
                catchError(() => {
                    this.activeModal.dismiss();
                    return EMPTY;
                }),
                tap(() => {
                    this.state.reload().then(this.activeModal.dismiss);
                }),
            ).subscribe();
    }
}
