import _ from "lodash";
import { ModuleType, StudyRightSelectionType } from './module-entity.service.ts';
export const commonModuleServiceModule = 'sis-components.service.moduleService';
(function () {
  commonModuleService.$inject = ["$q", "$http", "planModuleJSDataModel", "universityService", "jsDataCacheHelper", "commonCurriculumPeriodService", "MODULE_URL", "RequestSettings"];
  angular.module(commonModuleServiceModule, ['sis-components.model.module', 'sis-components.service.curriculumPeriodService', 'sis-components.service.jsDataCacheHelper', 'sis-common.university']).constant('ModuleType', ModuleType).constant('StudyRightSelectionType', StudyRightSelectionType).factory('commonModuleService', commonModuleService);

  /**
   * @ngInject
   */
  function commonModuleService($q, $http, planModuleJSDataModel, universityService,
  // NOSONAR
  jsDataCacheHelper, commonCurriculumPeriodService, MODULE_URL, RequestSettings) {
    function doSearch(endpoint, searchParams) {
      return doSearchWithParams(endpoint, searchParams.build());
    }
    function doSearchWithParams(endpoint, params) {
      return $http.get(endpoint, params).then(result => result.data);
    }
    function findAll(moduleGroupId, bypassCache, endpoint, documentState) {
      var options = {
        endpoint: endpoint,
        bypassCache: !!bypassCache
      };
      var params = {
        groupId: moduleGroupId,
        includeExpired: true
      };
      if (!_.isNil(documentState)) {
        params.documentState = documentState;
      }
      return planModuleJSDataModel.findAll(params, options);
    }
    var api = {
      findById: function (moduleId, bypassCache) {
        var options = {
          bypassCache: !!bypassCache
        };
        return planModuleJSDataModel.find(moduleId, options);
      },
      findAllByIds: function (ids) {
        return jsDataCacheHelper.findByIds(planModuleJSDataModel, ids, false);
      },
      findAllVersions: function (moduleGroupId, bypassCache, documentState) {
        return findAll(moduleGroupId, bypassCache, MODULE_URL.AUTHENTICATED_ENDPOINT_URL, documentState ? documentState : ['ACTIVE', 'DRAFT', 'DELETED']);
      },
      findAllVersionsForStudent: function (moduleGroupId) {
        return findAll(moduleGroupId, true, MODULE_URL.ENDPOINT_URL).then(function (response) {
          return response;
        });
      },
      searchActive: function (searchParameters) {
        return doSearch(MODULE_URL.SEARCH_URI, searchParameters);
      },
      search: function (searchParameters) {
        return doSearch(MODULE_URL.AUTHENTICATED_SEARCH_URI, searchParameters);
      },
      // Same as search but with unwrapped parameters
      searchWithParams: function (params) {
        return doSearchWithParams(MODULE_URL.AUTHENTICATED_SEARCH_URI, {
          params
        });
      },
      findByGroupIds: function (groupIds, universityOrgId, curriculumPeriodId, documentStates, maxParameterLength) {
        groupIds = _.uniq(_.compact(groupIds));
        if (groupIds.length === 0) {
          return $q.when([]);
        }
        var promises = [];
        var modules = [];
        var requestStrings = [];
        var currentString = '';
        if (!universityOrgId) {
          universityOrgId = universityService.getCurrentUniversityOrgId();
        }
        var extraParametersString = '&universityId=' + universityOrgId;
        if (curriculumPeriodId) {
          extraParametersString += '&curriculumPeriodId=' + curriculumPeriodId;
        }
        if (documentStates) {
          _.forEach(documentStates, function (documentState) {
            extraParametersString += '&documentStates=' + documentState;
          });
        }
        if (!maxParameterLength) {
          maxParameterLength = RequestSettings.MAX_PARAMETER_LENGTH;
        }
        var maxGroupIdStringLength = maxParameterLength - extraParametersString.length;
        _.forEach(groupIds, function (groupId) {
          var str = 'groupId=' + groupId;
          if (currentString.length + str.length + 1 < maxGroupIdStringLength) {
            currentString += currentString.length === 0 ? '?' : '&';
            currentString += str;
          } else {
            requestStrings.push(currentString);
            currentString = '?' + str;
          }
        });
        if (currentString.length > 0) {
          requestStrings.push(currentString);
        }
        _.forEach(requestStrings, function (groupIdString) {
          var promise = $http.get('/kori/api/modules/by-group-id' + groupIdString + extraParametersString).then(function (result) {
            modules = _.concat(modules, result.data);
          });
          promises.push(promise);
        });
        return $q.all(promises).then(function () {
          return modules;
        });
      },
      getDataForStudySelector: groupId => {
        const curriculumPeriodPromise = commonCurriculumPeriodService.findByUniversityOrgId(universityService.getCurrentUniversityOrgId());
        const allVersionsPromise = api.findAllVersionsForStudent(groupId);
        return $q.all([curriculumPeriodPromise, allVersionsPromise]).then(res => {
          const result = {
            allCurriculumPeriods: res[0],
            allCourseUnitVersions: res[1]
          };
          return result;
        });
      },
      getDegreeProgrammesOrderedByTypeUrn(degreeProgrammes) {
        return _.orderBy(degreeProgrammes, [dp => _.indexOf(['urn:code:degree-program-type:bachelors-degree', 'urn:code:degree-program-type:lic', 'urn:code:degree-program-type:masters-degree', 'urn:code:degree-program-type:doctor', 'urn:code:degree-program-type:postgraduate-professional'], dp.degreeProgramTypeUrn)]);
      }
    };
    return api;
  }
})();