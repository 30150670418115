hybridAppBootstrapController.$inject = ["$scope"];
import * as angular from 'angular';
export const hybridAppBootstrapModule = 'hybridAppBootstrap';
angular.module(hybridAppBootstrapModule, []).directive('hybridAppBootstrap', hybridAppBootstrapDirective);

/**
 * An AngularJS wrapper directive that enables us to bootstrap downgraded Angular services (and components) before instantiating the
 * AngularJS controllers that require those services. Adapted from https://stackoverflow.com/a/51175142/305436.
 */
function hybridAppBootstrapDirective() {
  return {
    transclude: true,
    template: `
            <service-bootstrap (initialized)="onInit()"></service-bootstrap>
            <div ng-if="initialized">
                <ng-transclude></ng-transclude>
            </div>
        `,
    controller: hybridAppBootstrapController,
    scope: {}
  };
}
function hybridAppBootstrapController($scope) {
  $scope.initialized = false;
  $scope.onInit = () => {
    $scope.initialized = true;
  };
}