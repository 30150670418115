import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { CreditRange, DocumentState, LocalizedString, OtmId } from 'common-typescript/types';

export interface SubstituteCourseUnit {
    id: OtmId;
    name: LocalizedString;
    code: string;
    credits: CreditRange;
    substituteCredits?: number | CreditRange;
    attainedCredits?: number;
    gradeId?: number;
    gradeScaleId?: OtmId;
    isPartialSubstitute?: boolean;
    studyState?: DocumentState;
    isAttainmentAboutToExpire?: boolean;
    attainmentExpiryDate?: string;
}

@Component({
    selector: 'sis-study-substitution-box',
    templateUrl: './study-substitution-box.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudySubstitutionBoxComponent {

    @Input() courseUnitGroupId: OtmId;
    /**
     * Determines course units' headers' semantic aria-level for screen readers, default is equivalent for h4
     */
    @Input() level? = 4;
    @Input() substituteCourseUnits: SubstituteCourseUnit[] = [];
    @Output() nameClickAction = new EventEmitter<OtmId>();

    getSubstituteCredits(substituteCourseUnits: SubstituteCourseUnit[]): CreditRange {
        const credits: CreditRange = { min: 0, max: 0 };
        substituteCourseUnits.forEach((s) => {
            if (typeof s?.substituteCredits === 'number') {
                credits.min += s.substituteCredits;
                credits.max += s.substituteCredits;
            } else {
                if (s.substituteCredits && 'min' in s.substituteCredits) {
                    credits.min += s.substituteCredits.min ?? 0;
                }
                if (s.substituteCredits && 'max' in s.substituteCredits) {
                    credits.max += s.substituteCredits.max ?? 0;
                }
            }
        });
        return credits;
    }

    isPartialSubstitute(substituteCourseUnits: SubstituteCourseUnit[]): boolean {
        return !!substituteCourseUnits.find((s) => !!s.isPartialSubstitute);
    }

    openSubstitutionInfo(): void {}

}
