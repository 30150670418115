import angular from 'angular';
import checkboxTpl from './checkbox.tpl.html';
export const checkboxModule = 'sis-components.checkbox';
(function () {
  angular.module(checkboxModule, []).directive('checkbox', checkbox);

  /** @ngInject */
  function checkbox() {
    return {
      element: 'E',
      require: ['^form', 'ngModel'],
      scope: {
        ngModel: '=',
        ngChange: '&',
        required: '=',
        disable: '=',
        label: '@',
        name: '@'
      },
      template: checkboxTpl,
      link: function (scope, element, attrs, form) {
        scope.form = _.first(form);
      }
    };
  }
})();