<div class="sis-mb-md">
    <sis-cooperation-network-selector
      (cooperationNetworkRemoved)="onCooperationNetworkRemoved($event)"
      [removeButton]="enableRemoveButton"
      [label]="label"
      [control]="cooperationNetworkId">
    </sis-cooperation-network-selector>
    <p *ngIf="this.cooperationNetworkShareListItem.cooperationNetworkShare.cooperationNetworkId !== null" class="guidance ng-scope sis-mt-md sis-mb-zero"> {{ 'COOPERATION_NETWORK_SHARE_EDITOR.ORGANISATION_VALIDITY_PERIOD' | translate }} {{ organisationValidityPeriod(cooperationNetworkId.value) | localDateRange }} </p>
</div>
<div *ngIf="this.cooperationNetworkShareListItem.cooperationNetworkShare.cooperationNetworkId !== null">
  <ng-container *ngIf="showValidityPeriod">
    <label translate>COOPERATION_NETWORK_SHARE_EDITOR.VALIDITY_PERIOD</label>
    <button type="button"
            class="link-button"
            (click)="removeValidityPeriod()">
      <sis-icon icon="delete"></sis-icon>
    </button>
    <sis-local-date-range-editor
      [control]="validityPeriod"
      [endDateSubtractByOne]=false>
    </sis-local-date-range-editor>
  </ng-container>
  <ng-container *ngIf="!showValidityPeriod">
    <sis-button (clicked)="addValidityPeriod()" size="sm" naked="true">
      <sis-icon icon="plus"></sis-icon>
      {{ 'COOPERATION_NETWORK_SHARE_EDITOR.ADD_LIMITED_VALIDITY_PERIOD' | translate }}
    </sis-button>
  </ng-container>
</div>
