import angular from 'angular';
import 'sis-common/model/assesmentItemName.service';
export const assessmentItemModelModule = 'sis-components.model.assessmentItem';
(function () {
  assessmentItemModel.$inject = ["DS", "$q", "jsDataRelationHelperService", "assessmentItemNameService", "localizedStringUtils"];
  angular.module(assessmentItemModelModule, ['js-data', 'sis-common.model.assessmentItemNameService', 'sis-components.service.jsDataRelationHelper', 'sis-components.model.gradeScale', 'sis-components.date.constants', 'sis-components.model.assessmentItemType']).factory('assessmentItemJSDataModel', assessmentItemModel).run(["assessmentItemJSDataModel", "gradeScaleJSDataModel", "assessmentItemTypeJsDataModel", function (assessmentItemJSDataModel, gradeScaleJSDataModel, assessmentItemTypeJsDataModel) {// NOSONAR
  }]);

  /**
   * @ngInject
   */
  function assessmentItemModel(DS, $q, jsDataRelationHelperService, assessmentItemNameService, localizedStringUtils) {
    function loadRelations(assessmentItems) {
      var relationLoads = [];
      _.forEach(assessmentItems, function (assessmentItem) {
        if (assessmentItem) {
          relationLoads = _.concat(relationLoads, jsDataRelationHelperService.loadOrganisationRelations(assessmentItem.organisations));
          relationLoads = _.concat(relationLoads, jsDataRelationHelperService.loadModuleResponsibilityInfoRelations(assessmentItem.responsibilityInfos));
        }
      });
      return relationLoads;
    }
    return DS.defineResource({
      idAttribute: 'id',
      name: 'assessmentItem',
      endpoint: '/kori/api/assessment-items',
      relations: {
        hasOne: {
          gradeScale: {
            localField: 'gradeScale',
            localKey: 'gradeScaleId'
          },
          assessmentItemType: {
            localField: 'assessmentItemTypeLocal',
            localKey: 'assessmentItemType'
          },
          studyField: {
            localField: 'studyFieldLocal',
            localKey: 'studyField'
          }
        }
      },
      computed: {
        fullName: ['name', 'nameSpecifier', function (name, nameSpecifier) {
          return assessmentItemNameService.generateFullName({
            name: name,
            nameSpecifier: nameSpecifier
          });
        }]
      },
      methods: {
        loadListRelations: function () {
          var assessmentItem = this;
          return $q.all(loadRelations([assessmentItem])).then(function () {
            return assessmentItem;
          });
        },
        isPrimaryCourseUnit: function (courseUnitOrGroupId) {
          if (courseUnitOrGroupId && courseUnitOrGroupId.groupId) {
            return this.primaryCourseUnitGroupId === courseUnitOrGroupId.groupId;
          }
          return this.primaryCourseUnitGroupId === courseUnitOrGroupId;
        },
        order: function (courseUnit) {
          var index = _.indexOf(courseUnit.assessmentItemOrder, this.id);
          return index === -1 ? 10000 : index;
        },
        getDefaultName: function () {
          var defaultName;
          if (this.assessmentItemTypeLocal) {
            defaultName = this.assessmentItemTypeLocal.name;
          }
          return _.cloneDeep(defaultName);
        },
        nameInCUContext: function (courseUnit) {
          if (this.isPrimaryCourseUnit(courseUnit) && this.hasDefaultNameSpecifier(courseUnit)) {
            return this.name;
          } else {
            return this.fullName;
          }
        },
        hasDefaultNameSpecifier: function (courseUnit) {
          return localizedStringUtils.areEqual(this.nameSpecifier, courseUnit ? courseUnit.name : {});
        },
        hasCredits: function () {
          return !_.isNil(this.credits) && (!_.isNil(this.credits.min) || !_.isNil(this.credits.max));
        }
      }
    });
  }
})();