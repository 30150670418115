<div [class.container]="version.name !== 'admin'" *transloco="let t">
  <table class="table table-condensed styled-table" data-cy="about" [attr.aria-label]="t('ABOUT.TITLE')">
    <thead>
    <tr class="d-flex">
      <th class="col-3">{{ t('ABOUT.NAME') }}</th>
      <th class="col-3">{{ t('ABOUT.VERSION') }}</th>
      <th class="col-3">{{ t('ABOUT.BUILD') }}</th>
      <th class="col-3">{{ t('ABOUT.STATUS') }}</th>
    </tr>
    </thead>
    <tbody>
    <tr class="d-flex">
      <td class="col-3">{{ version.name }}</td>
      <td class="col-3">{{ version.version }}</td>
      <td class="col-3">{{ version.build }}</td>
      <td class="col-3"></td>
    </tr>
    <ng-container *ngIf="serviceStatus$ | async as services">
      <tr *ngFor="let service of services; trackBy: serviceName" class="d-flex">
        <td class="col-3">{{ service.name }}</td>
        <td class="col-3">{{ service.version }}</td>
        <td class="col-3">{{ service.build }}</td>
        <td class="col-3">{{ service.status }}</td>
      </tr>
    </ng-container>
    </tbody>
  </table>
</div>
