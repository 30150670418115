<ng-container *transloco="let t">
  <ng-container *ngIf="stateObject?.isAttainmentAboutToExpire">
    <sis-tiny-badge [variant]="'accent'">
      {{ t('PLAN.MODULE.EXPIRATION_IMMINENT') }}
      {{ stateObject.attainmentExpiryDate | localDateFormat}}
    </sis-tiny-badge>
  </ng-container>
  <ng-container *ngIf="stateObject?.invalidSelection && !stateObject?.hasModuleContentApproval">
    <sis-tiny-badge [variant]="'danger'">
      {{t('PLAN.INVALID_SELECTIONS.NOTIFICATION')}}
    </sis-tiny-badge>
  </ng-container>
  <ng-container *ngIf="stateObject?.invalidSelectionAccordingToModuleContentApproval">
    <sis-tiny-badge [variant]="'danger'">
      {{t('PLAN.INVALID_ACCORDING_TO_MODULE_CONTENT_APPROVAL.NOTIFICATION')}}
    </sis-tiny-badge>
  </ng-container>
  <ng-container *ngIf="documentState === 'DRAFT'">
    <sis-tiny-badge [variant]="'accent'">
      {{t('VISIBLE_DOCUMENT_STATE.DRAFT')}}
    </sis-tiny-badge>
  </ng-container>
  <ng-container *ngIf="documentState === 'DELETED'">
    <sis-tiny-badge [variant]="'danger'">
      {{t('VISIBLE_DOCUMENT_STATE.DELETED')}}
    </sis-tiny-badge>
  </ng-container>
</ng-container>
