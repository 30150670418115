(function () {
  'use strict';

  angular.module('student.shared').factory('dateTimeService', dateTimeServiceFactory);
  function dateTimeServiceFactory() {
    var api = {
      currentDateTime: function () {
        return moment();
      }
    };
    return api;
  }
})();