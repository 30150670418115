ShowModuleContentApplicationModalController.$inject = ["$scope", "commonStudentService", "publicPersonService", "commonStudentApplicationService", "STUDENT_APPLICATION_STATE", "workflowEntityService", "defaultPromiseHandler", "sisAlertsService", "$translate", "appErrorHandler"];
ShowModuleContentApplicationModalService.$inject = ["$q", "modalService", "commonApplicationDecisionService", "applicationDecisionJSDataModel", "commonStudentApplicationService", "defaultPromiseHandler"];
import angular from 'angular';
import { ModalService } from 'sis-common/modal/modal.service.ts';
import 'sis-components/model/studentApplication.model';
import 'sis-components/service/student.service';
import 'sis-components/service/publicPerson.service';
import 'sis-components/date/filters/localDateRange/localDateRange.filter';
import 'sis-components/applications/recommendation.component';
import 'sis-components/service/studentApplication.service';
import 'sis-components/model/applicationDecision.model';
import 'sis-components/service/applicationDecision.service';
import './showModuleContentApplication.component';
import { WorkflowEntityService } from 'sis-components/service/workflow-entity.service.ts';
import { take } from 'rxjs/operators';
import { AlertsService, AlertType } from 'sis-components/alerts/alerts-ng.service.ts';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler.ts';
import { ShowModuleContentApplicationModalComponent } from './showModuleContentApplicationModal.component.ts';
import showModuleContentApplicationModalTpl from './showModuleContentApplicationModal.tpl.html';
angular.module('student.profile.applications.showModuleContentApplicationModal', ['student.profile.applications.showModuleContentApplication', 'sis-components.service.studentService', 'sis-components.service.publicPersonService', 'sis-components.date.localDateRangeFilter', 'sis-components.applications.recommendation', 'sis-components.service.studentApplicationService', 'sis-components.model.studentApplication', 'sis-components.model.applicationDecision', 'sis-components.service.applicationDecisionService', ModalService.downgrade.moduleName, WorkflowEntityService.downgrade.moduleName, AlertsService.downgrade.moduleName, AppErrorHandler.downgrade.moduleName]).factory('ShowModuleContentApplicationModal', ShowModuleContentApplicationModalService).controller('ShowModuleContentApplicationModalController', ShowModuleContentApplicationModalController).component('showModuleContentApplicationModal', {
  bindings: {
    close: '&',
    dismiss: '&',
    resolve: '<'
  },
  template: showModuleContentApplicationModalTpl,
  controller: ShowModuleContentApplicationModalController
});
function ShowModuleContentApplicationModalService($q, modalService, commonApplicationDecisionService, applicationDecisionJSDataModel, commonStudentApplicationService, defaultPromiseHandler) {
  return {
    open: (module, application, readOnly) => $q.all([commonStudentApplicationService.findById(application.id, {
      bypassCache: 'true'
    }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler), commonApplicationDecisionService.findByApplicationId(application.id).then(decision => decision ? applicationDecisionJSDataModel.loadRelations(decision, ['approver', 'formalAssessor', 'contentAssessor']) : decision)]).then(([upToDateApplication, decision]) => modalService.open(ShowModuleContentApplicationModalComponent, {
      module,
      application: upToDateApplication,
      readOnly,
      decision
    }, {
      closeWithOutsideClick: true,
      size: 'lg'
    }).result)
  };
}
function ShowModuleContentApplicationModalController($scope, commonStudentService, publicPersonService, commonStudentApplicationService, STUDENT_APPLICATION_STATE, workflowEntityService, defaultPromiseHandler, sisAlertsService, $translate, appErrorHandler) {
  const $ctrl = this;
  $ctrl.isRevoking = false;
  $ctrl.isCanceling = false;
  $ctrl.$onInit = () => {
    $ctrl.module = $ctrl.resolve.module;
    $ctrl.application = $ctrl.resolve.application;
    $ctrl.readOnly = !!$ctrl.resolve.readOnly;
    commonStudentService.findStudent($ctrl.application.studentId).then(student => {
      $ctrl.student = student;
    }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    $ctrl.badgeClass = getBadgeClass($ctrl.application);
    workflowEntityService.getById($ctrl.application.id, {
      bypassCache: true
    }).pipe(take(1), appErrorHandler.defaultErrorHandler()).subscribe(workflow => {
      $ctrl.workflow = workflow;
      $scope.$evalAsync();
    }, defaultPromiseHandler.loggingRejectedPromiseHandler);
  };
  function getBadgeClass(studentApplication) {
    switch (studentApplication.state) {
      case STUDENT_APPLICATION_STATE.REQUESTED:
      case STUDENT_APPLICATION_STATE.IN_HANDLING:
      case STUDENT_APPLICATION_STATE.CANCELLED:
      case STUDENT_APPLICATION_STATE.REVOKED:
        return 'secondary';
      case STUDENT_APPLICATION_STATE.REJECTED:
        return 'danger';
      default:
        return 'success';
    }
  }
  $scope.isPendingApproval = () => $ctrl.application.state === 'REQUESTED' || $ctrl.application.state === 'IN_HANDLING';
  $scope.isApproved = () => $ctrl.application.state === 'ACCEPTED' || $ctrl.application.state === 'CONDITIONAL';
  $scope.isRejected = () => $ctrl.application.state === 'REJECTED';
  $scope.revoke = () => {
    if ($ctrl.workflow.cancellingDisabled) {
      sisAlertsService.addAlert({
        type: AlertType.DANGER,
        message: $translate.instant('PROFILE.APPLICATIONS.APPLICATION_REVOKE_DISABLED_ALERT')
      });
      return;
    }
    $ctrl.isRevoking = true;
  };
  $scope.unRevoke = () => {
    $ctrl.isRevoking = false;
  };
  $scope.confirmRevoke = () => {
    commonStudentApplicationService.revoke($ctrl.application).then(() => {
      $ctrl.close({
        $value: $ctrl.application
      });
    }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
  };
  $scope.cancel = () => {
    if ($ctrl.workflow.cancellingDisabled) {
      sisAlertsService.addAlert({
        type: AlertType.DANGER,
        message: $translate.instant('PROFILE.APPLICATIONS.APPLICATION_CANCELLATION_DISABLED_ALERT')
      });
      return;
    }
    $ctrl.isCanceling = true;
  };
  $scope.unCancel = () => {
    $ctrl.isCanceling = false;
  };
  $scope.confirmCancel = () => {
    commonStudentApplicationService.cancel($ctrl.application).then(() => {
      $ctrl.close({
        $value: $ctrl.application
      });
    }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
  };
}