planStudyRightModal.$inject = ["modalService"];
PlanStudyRightModalController.$inject = ["$q", "$rootScope", "commonEducationService", "commonModuleService", "planStudyRightService", "commonStudyRightService", "defaultPromiseHandler", "universityService", "tuitionFeeObligationPeriodService", "appErrorHandler"];
import angular from 'angular';
import _ from 'lodash';
import { lastValueFrom } from 'rxjs';
import { first } from 'rxjs/operators';
import { errorServiceModule } from 'sis-components/error-handler/legacy/errorService';
import { ModalService } from 'sis-common/modal/modal.service.ts';
import { TuitionFeeObligationPeriodEntityService } from 'sis-components/service/tuition-fee-obligation-period.entity.service.ts';
import { localizedUrnNameDisplayModule } from 'sis-components/codes/localizedUrnNameDisplay.component';
import { MinorSelectionsComponent } from 'sis-components/studyRight/minor-selection/minor-selections/minor-selections.component.ts';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler.ts';
import planStudyRightModalTpl from './planStudyRightModal.tpl.html';
import { PlanStudyRightModalComponent } from './planStudyRightModal.component.ts';
angular.module('student.plan.planStudyRightModal', ['sis-components.service.educationService', 'sis-components.educationStructurePreview', 'sis-components.service.moduleService', 'sis-components.service.planStudyRightService', 'sis-components.service.studyRightService', errorServiceModule, 'sis-common.university', ModalService.downgrade.moduleName, TuitionFeeObligationPeriodEntityService.downgrade.moduleName, localizedUrnNameDisplayModule, MinorSelectionsComponent.downgrade.moduleName, AppErrorHandler.downgrade.moduleName]).controller('PlanStudyRightModalController', PlanStudyRightModalController).factory('planStudyRightModal', planStudyRightModal).component('planStudyRightModal', {
  bindings: {
    close: '&',
    dismiss: '&',
    resolve: '<'
  },
  template: planStudyRightModalTpl,
  controller: PlanStudyRightModalController
});
function planStudyRightModal(modalService) {
  return {
    open: (education, studyRight, validatablePlan, educationOptions) => modalService.open(PlanStudyRightModalComponent, {
      education,
      studyRight,
      validatablePlan,
      educationOptions
    }, {
      size: 'xl'
    }).result
  };
}
function PlanStudyRightModalController($q, $rootScope, commonEducationService, commonModuleService, planStudyRightService, commonStudyRightService, defaultPromiseHandler, universityService, tuitionFeeObligationPeriodService, appErrorHandler) {
  const $ctrl = this;
  const educationPathKeys = ['educationPhase1GroupId', 'educationPhase1ChildGroupId', 'educationPhase2GroupId', 'educationPhase2ChildGroupId'];
  $ctrl.$onInit = () => {
    $ctrl.education = $ctrl.resolve.education;
    $ctrl.studyRight = $ctrl.resolve.studyRight;
    $ctrl.educationOptions = $ctrl.resolve.educationOptions;
    $ctrl.validatablePlan = $ctrl.resolve.validatablePlan;
    $ctrl.modulesByGroupId = {};
    $ctrl.attainmentsByModuleGroupId = _.chain($ctrl.validatablePlan.attainmentsById).values().filter('moduleGroupId').keyBy('moduleGroupId').value();
    $ctrl.selectionPathInPlan = planStudyRightService.getSelectionPathInPlan($ctrl.validatablePlan, $ctrl.education, $ctrl.studyRight);
    $ctrl.educationOptionToBeConfirmed = planStudyRightService.getFirstNotConfirmedEducationOption($ctrl.educationOptions, $ctrl.selectionPathInPlan.selectionPath, $ctrl.studyRight, $ctrl.education);
    if (!!_.get($ctrl.studyRight, 'personalizedSelectionPath.phase1') && !!_.get($ctrl.studyRight, 'personalizedSelectionPath.phase2')) {
      $ctrl.hasBothPersonalized = true;
    } else if (_.get($ctrl.studyRight, 'personalizedSelectionPath.phase2')) {
      $ctrl.hasPhase2Personalized = true;
    } else if (_.get($ctrl.studyRight, 'personalizedSelectionPath.phase1')) {
      $ctrl.hasPhase1Personalized = true;
    }
    $ctrl.hasPersonalizedStudyRight = $ctrl.hasPhase1Personalized || $ctrl.hasPhase2Personalized || $ctrl.hasBothPersonalized;
    if ($ctrl.educationOptionToBeConfirmed) {
      $ctrl.isEducationOptionSelectableBasedOnStudyRightAllowedSelectionPaths = planStudyRightService.isEducationOptionSelectableBasedOnStudyRightAllowedSelectionPaths($ctrl.educationOptionToBeConfirmed, $ctrl.studyRight, $ctrl.education);
      $ctrl.educationOption = $ctrl.educationOptionToBeConfirmed;
      $ctrl.blockingEducationOptions = planStudyRightService.getBlockingEducationOptions($ctrl.educationOptionToBeConfirmed, $ctrl.educationOptions, $ctrl.studyRight);
    }
    $ctrl.previewModel = commonEducationService.getPreviewModel($ctrl.education, $ctrl.studyRight);
    $ctrl.connectionModel = commonEducationService.getConnectionModel($ctrl.previewModel, $ctrl.education, $ctrl.studyRight);
    const moduleGroupIds = _.concat(commonEducationService.getModuleGroupIdsFromEducationStructure($ctrl.education), getModuleGroupIdsFromMinorSelections(), getModuleIdsFromPersonalizedSelectionPath(_.get($ctrl.studyRight, 'personalizedSelectionPath')));
    const promises = [];
    const namingTypePromise = commonStudyRightService.getEducationOptionNamingTypes().then(result => {
      $ctrl.educationOptionNamingTypes = result;
      $ctrl.optionNaming = $ctrl.getOptionNaming();
      planStudyRightService.populateNamingData($ctrl.educationOptions, $ctrl.education, $ctrl.educationOptionNamingTypes);
    });
    promises.push(namingTypePromise);
    const modulePromise = commonModuleService.findByGroupIds(moduleGroupIds).then(result => {
      $ctrl.modulesByGroupId = _.keyBy(result, 'groupId');
    });
    promises.push(modulePromise);

    // Set up feature toggles from university settings
    $ctrl.educationOptionsConfirmable = false;
    promises.push(universityService.getCurrentUniversitySettings().then(data => {
      $ctrl.educationOptionsConfirmable = _.get(data, 'frontendFeatureToggles.educationOptionsConfirmable', true);
    }));
    promises.push(lastValueFrom(tuitionFeeObligationPeriodService.findForStudyRight($ctrl.studyRight.id).pipe(first(), appErrorHandler.defaultErrorHandler())).then(periods => {
      $ctrl.tuitionFeeObligationPeriods = periods;
    }));
    $q.all(promises).then(() => {
      $ctrl.studyRightPhaseInfo = getStudyRightPhaseInfo();
      $ctrl.existingTuitionFeeObligationPeriods = !_.isEmpty($ctrl.tuitionFeeObligationPeriods);
      $ctrl.showConfirm = $ctrl.canSelectionBeMade() && $ctrl.blockingEducationOptions.length === 0 && !$ctrl.hasPersonalizedStudyRight && !$ctrl.existingTuitionFeeObligationPeriods;
      $ctrl.ready = true;
    }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    commonStudyRightService.allowedToUpdateFutureSnapshot($ctrl.studyRight).then(response => {
      $ctrl.isAllowedToUpdateFutureStudyRight = _.get(response, 'isAllowed');
      $ctrl.futureStudyRight = _.get(response, 'futureStudyRight');
    }).catch(error => {
      $ctrl.isAllowedToUpdateFutureStudyRight = false;
      $ctrl.futureStudyRight = undefined;
      defaultPromiseHandler.loggingRejectedPromiseHandler(error);
    });
  };
  $ctrl.isModuleInLearningOpportunityAllowedPaths = (moduleGroupId, educationPathKey) => commonEducationService.isModuleInLearningOpportunityAllowedPaths(planStudyRightService.getStudyRightSelectionPaths($ctrl.education, $ctrl.studyRight), moduleGroupId, educationPathKey);
  $ctrl.isOptionToBeConfirmed = educationOption => {
    if (!$ctrl.educationOptionToBeConfirmed) {
      return false;
    }
    if (_.has(educationOption, 'parentModuleGroupId')) {
      return educationOption.parentModuleGroupId === $ctrl.educationOptionToBeConfirmed.parentModuleGroupId && educationOption.moduleGroupId === $ctrl.educationOptionToBeConfirmed.moduleGroupId;
    }
    return educationOption.moduleGroupId === $ctrl.educationOptionToBeConfirmed.moduleGroupId;
  };
  $ctrl.getOptionNaming = () => {
    if ($ctrl.educationOptionToBeConfirmed) {
      if (!_.has($ctrl.educationOptionToBeConfirmed, 'parentModuleGroupId')) {
        const educationStructurePhase = _.get($ctrl.education.structure, $ctrl.educationOptionToBeConfirmed.phaseName);
        return educationStructurePhase.name;
      }
      return _.get($ctrl.educationOptionNamingTypes, $ctrl.educationOptionToBeConfirmed.namingType).name;
    }
    return undefined;
  };
  $ctrl.isEducationOptionInPlan = educationOption => {
    const module = $ctrl.validatablePlan.getModuleInPlanByGroupId(educationOption.moduleGroupId);
    if (planStudyRightService.isModuleSelectedAsMinor(module, $ctrl.validatablePlan)) {
      return false;
    }
    if (!_.has(educationOption, 'parentModuleGroupId')) {
      return $ctrl.isModuleInPlan(educationOption.moduleGroupId);
    }
    const parentModuleInPlan = $ctrl.validatablePlan.getModuleInPlanByGroupId(educationOption.parentModuleGroupId);
    const childModuleInPlan = $ctrl.validatablePlan.getModuleInPlanByGroupId(educationOption.moduleGroupId);
    return parentModuleInPlan && childModuleInPlan && $ctrl.validatablePlan.isModuleInPlanUnderModule(childModuleInPlan.id, parentModuleInPlan.id);
  };
  $ctrl.isModuleInPlan = moduleGroupId => {
    const modulesInPlanMap = _.filter(_.values($ctrl.validatablePlan.modulesById), {
      groupId: moduleGroupId
    });
    if (_.isEmpty(modulesInPlanMap)) {
      return false;
    }
    const moduleInPlan = _.find(modulesInPlanMap, module => $ctrl.validatablePlan.isModuleInPlan(module.id));
    return !!moduleInPlan;
  };
  $ctrl.canSelectionBeMade = function () {
    if (!$ctrl.educationOptionsConfirmable) {
      return false;
    }
    if ($ctrl.educationOptionToBeConfirmed) {
      if ($ctrl.educationOptionToBeConfirmed.acceptanceType !== 'AUTOMATIC') {
        return false;
      }
      if (!$ctrl.isEducationOptionSelectableBasedOnStudyRightAllowedSelectionPaths) {
        return false;
      }
      return true;
    }
    return false;
  };
  $ctrl.futureSnapshotOverridesChangesWarningVisible = () => $ctrl.futureStudyRight && !$ctrl.isAllowedToUpdateFutureStudyRight;
  $ctrl.getFutureSnapshotDate = () => _.get($ctrl.futureStudyRight, 'snapshotDateTime');
  function getModuleGroupIdsFromMinorSelections() {
    const minorSelections = _.concat($ctrl.studyRight.phase1MinorSelections, $ctrl.studyRight.phase2MinorSelections);
    return _.chain(minorSelections).map('moduleGroupId').uniq().value();
  }
  function getModuleIdsFromPersonalizedSelectionPath(personalizedSelectionPath) {
    const moduleIds = [];
    if (_.get(personalizedSelectionPath, 'phase1')) {
      moduleIds.push(_.get(personalizedSelectionPath, 'phase1.moduleGroupId'));
      if (_.get(personalizedSelectionPath, 'phase1.childModuleGroupId')) {
        moduleIds.push(_.get(personalizedSelectionPath, 'phase1.childModuleGroupId'));
      }
    }
    if (_.get(personalizedSelectionPath, 'phase2')) {
      moduleIds.push(_.get(personalizedSelectionPath, 'phase2.moduleGroupId'));
      if (_.get(personalizedSelectionPath, 'phase2.childModuleGroupId')) {
        moduleIds.push(_.get(personalizedSelectionPath, 'phase2.childModuleGroupId'));
      }
    }
    return moduleIds;
  }
  function getPhaseName(educationPathKey) {
    if (educationPathKey === 'educationPhase1GroupId') {
      return $ctrl.education.structure.phase1.name;
    }
    if (educationPathKey === 'educationPhase2GroupId') {
      return $ctrl.education.structure.phase2.name;
    }
    return getPhaseNameForChildOption(educationPathKey);
  }
  function getPhaseNameForChildOption(educationPathKey) {
    if (_.get($ctrl.studyRight, 'personalizedSelectionPath.phase1')) {
      const childNamingPhase1 = _.get($ctrl.studyRight, 'personalizedSelectionPath.phase1.childNamingUrn');
      const childNamingPhase2 = _.get($ctrl.studyRight, 'personalizedSelectionPath.phase2.childNamingUrn');
      if (educationPathKey === 'educationPhase1ChildGroupId' && childNamingPhase1) {
        return _.get(_.get($ctrl.educationOptionNamingTypes, childNamingPhase1), 'name');
      }
      if (educationPathKey === 'educationPhase2ChildGroupId' && childNamingPhase2) {
        return _.get(_.get($ctrl.educationOptionNamingTypes, childNamingPhase2), 'name');
      }
    }
    const parentKey = educationPathKeys[_.indexOf(educationPathKeys, educationPathKey) - 1];
    const acceptedSelectionPathParentModuleGroupId = _.get($ctrl.studyRight.acceptedSelectionPath, parentKey);
    const parentModuleGroupId = acceptedSelectionPathParentModuleGroupId || _.get($ctrl.selectionPathInPlan.selectionPath, parentKey);
    const phase = _.indexOf(educationPathKeys, parentKey) === 0 ? $ctrl.education.structure.phase1 : $ctrl.education.structure.phase2;
    const parentOption = _.find(phase.options, {
      moduleGroupId: parentModuleGroupId
    });
    const childNaming = _.get(parentOption, 'childNaming');
    if (childNaming) {
      return _.get(_.get($ctrl.educationOptionNamingTypes, childNaming), 'name');
    }
    return null;
  }
  function getStudyRightPhaseInfo() {
    const studyRightPhaseInfo = [];
    _.forEach(educationPathKeys, key => {
      const acceptedModuleGroupId = _.get($ctrl.studyRight.acceptedSelectionPath, key);
      const moduleInPlanGroupId = _.get($ctrl.selectionPathInPlan.selectionPath, key);
      if (acceptedModuleGroupId || moduleInPlanGroupId) {
        const phaseInfoObject = {};
        phaseInfoObject.name = getPhaseName(key);
        phaseInfoObject.acceptedModule = _.get($ctrl.modulesByGroupId, acceptedModuleGroupId);
        if (acceptedModuleGroupId !== moduleInPlanGroupId) {
          phaseInfoObject.moduleInPlan = _.get($ctrl.modulesByGroupId, moduleInPlanGroupId);
        }
        studyRightPhaseInfo.push(phaseInfoObject);
      }
    });
    return studyRightPhaseInfo;
  }
  $ctrl.cancel = () => {
    commonStudyRightService.revert($ctrl.studyRight);
    $ctrl.dismiss();
  };
  $ctrl.confirm = () => {
    $ctrl.studyRight.requestedSelectionPath = planStudyRightService.getSelectionPathToBeSelected($ctrl.educationOptionToBeConfirmed, $ctrl.studyRight);
    commonStudyRightService.requestSelectionPath($ctrl.studyRight).then(studyRight => {
      $rootScope.$broadcast('studyRightChanged');
      $ctrl.close({
        $value: studyRight
      });
    }, error => {
      $ctrl.dismiss({
        $value: error
      });
    });
  };
}