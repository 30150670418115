import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OtmId } from 'common-typescript/types';
import { Observable } from 'rxjs';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

const CONFIG = {
    ENDPOINTS: {
        backend: '/ori/api',
        getActiveSupplementRequestDescription(workflowId: OtmId) {
            return `${this.backend}/workflow-data-changes/for-workflow/${workflowId}/active-supplement-request-description`;
        },
    },
};

@StaticMembers<DowngradedService>()
@Injectable({ providedIn: 'root' })
export class WorkflowDataChangeService {

    static downgrade: ServiceDowngradeMappings = {
        dependencies: [],
        moduleName: 'sis-components.service.workflowDataChangeService',
        serviceName: 'workflowDataChangeService',
    };

    constructor(private http: HttpClient) {
    }

    getActiveSupplementRequestDescription(workflowId: OtmId): Observable<string> {
        return this.http.get(CONFIG.ENDPOINTS.getActiveSupplementRequestDescription(workflowId), { responseType: 'text' });
    }
}
