import { PlanValidationTs } from 'common-typescript';
import { legacyValidationResultsHelperServiceModule } from '../service/legacyValidationResultsHelper.service';
angular.module('sis-components.plan.planLoader', ['sis-components.service.planService', 'sis-components.service.colorService', legacyValidationResultsHelperServiceModule]).factory('PlanLoader', ["commonPlanService", "colorService", "legacyValidationResultsHelperService", function (commonPlanService, colorService, legacyValidationResultsHelperService) {
  var api = {
    /**
     * This is hopefully temporary solution that was done during student timing view/service refactoring.
     * Timing view/service is/was using 'plan' field of validatablePlan. That is wrong. ValidatablePlan should
     * be used as immutable object. The 'plan' field is the data that is used to create validatablePlan.
     */
    createValidatablePlan: function (planOrPlanId, bypassCache) {
      var planId = planOrPlanId.id ? planOrPlanId.id : planOrPlanId;
      return commonPlanService.findById(planId, bypassCache).then(createValidatablePlanFromPlan);
    },
    /**
     * Uses validatablePlan returned by createValidatablePlan method to create validation result.
     * @param planOrPlanId
     * @param bypassCache
     * @returns Validation result
     */
    validatePlan: function (planOrPlanId, bypassCache) {
      return api.createValidatablePlan(planOrPlanId, bypassCache).then(validatePlanAndClearColors);
    },
    /**
     * Unlike validatePlan this method does not fetch plan from back end.
     */
    validatePlanWithThisPlan: function (selectedPlan) {
      return createValidatablePlanFromPlan(selectedPlan).then(validatePlanAndClearColors);
    }
  };
  function createValidatablePlanFromPlan(selectedPlan) {
    return commonPlanService.preLoadedPlanData(selectedPlan).then(function (preLoadedPlanData) {
      return commonPlanService.createValidatablePlan(selectedPlan, preLoadedPlanData);
    });
  }
  function validatePlanAndClearColors(validatablePlan) {
    legacyValidationResultsHelperService.sortAndAssignSelections(validatablePlan);
    const planValidationResult = PlanValidationTs.validatePlan(validatablePlan);
    legacyValidationResultsHelperService.insertValidationResultsIntoPlanObjects(validatablePlan, planValidationResult);
    colorService.clearColorCaches();
    return planValidationResult;
  }
  return api;
}]);