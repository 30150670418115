import referredObjectMultiSelectEditorTpl from './referredObjectMultiSelectEditor.tpl.html';
(function () {
  referredObjectMultiSelectEditorController.$inject = ["$scope"];
  angular.module('sis-components.object.referredObjectMultiSelectEditor', ['pascalprecht.translate', 'js-data', 'sis-common.l10n.localizedStringFilter', 'sis-components.search.removeHtmlFilter']).directive('referredObjectMultiSelectEditor', referredObjectMultiSelectEditor).controller('referredObjectMultiSelectEditorController', referredObjectMultiSelectEditorController);

  /**
   * @ngInject
   */
  function referredObjectMultiSelectEditor() {
    return {
      restrict: 'E',
      require: '^^form',
      scope: {
        name: '@',
        placeholder: '@',
        selectedIds: '=',
        disabledIds: '=?',
        model: '=',
        minCount: '@',
        maxCount: '@',
        required: '@',
        onChange: '&',
        showAllUniversities: '@',
        showShortName: '@',
        universityOrgId: '=',
        reversedOrder: '<?'
      },
      template: referredObjectMultiSelectEditorTpl,
      controller: 'referredObjectMultiSelectEditorController as ctrl',
      bindToController: true,
      link: function (scope, element, attrs, formCtrl) {
        var ctrl = scope.ctrl;
        ctrl.form = formCtrl;
      }
    };
  }

  /**
   * @ngInject
   */
  function referredObjectMultiSelectEditorController($scope) {
    var ctrl = this;
    ctrl.disableAdd = false;
    ctrl.$onInit = function () {
      if (_.isEmpty(ctrl.model)) {
        throw 'referredObjectMultiSelectEditor directive: model attribute is required';
      }
      ctrl.idAttribute = ctrl.model.idAttribute;
      ctrl.showAllUniversities = ctrl.showAllUniversities === "true";
      ctrl.showShortName = ctrl.showShortName === "true";
    };
    function getId(jsDataObject) {
      return jsDataObject ? jsDataObject[ctrl.idAttribute] : undefined;
    }
    function ensureIdsArray() {
      if (!_.isArray(ctrl.selectedIds)) {
        ctrl.selectedIds = [];
      }
    }
    $scope.$watchCollection('ctrl.selectedIds', function (selectedIds) {
      if (!_.isNil(selectedIds)) {
        ctrl.selectedObjects = _.map(selectedIds, function (selectedId) {
          return selectedId ? ctrl.model.get(selectedId) : null;
        });
        ctrl.validate();
      } else {
        ctrl.selectedObjects = [];
        ctrl.validate();
      }
    });
    $scope.$watch('ctrl.required', ctrl.validate);
    $scope.$watch('ctrl.maxCount', ctrl.validate);
    ctrl.validate = function () {
      if (_.isNil(ctrl.selectedIds) || _.isEmpty(ctrl.selectedIds)) {
        ctrl.disableAdd = false;
        ctrl.form.$setValidity(ctrl.name + '.required', !ctrl.required);
        ctrl.form.$setValidity(ctrl.name + '.too_few', !ctrl.minCount || ctrl.required);
        ctrl.form.$setValidity(ctrl.name + '.id_missing', true);
      } else {
        var containsNullIds = _.every(ctrl.selectedIds, function (selectedId) {
          return !_.isNil(selectedId);
        });
        ctrl.form.$setValidity(ctrl.name + '.id_missing', containsNullIds);
        ctrl.disableAdd = !containsNullIds;
        ctrl.form.$setValidity(ctrl.name + '.required', found());
        ctrl.form.$setValidity(ctrl.name + '.too_few', validMinLength());
        ctrl.form.$setValidity(ctrl.name + '.too_many', validMaxLength());
        ctrl.form.$setValidity(ctrl.name + '.has_duplicate_values', noDuplicates());
      }
    };
    function found() {
      return !ctrl.required || ctrl.selectedIds && !_.isEmpty(_.compact(ctrl.selectedIds));
    }
    function validMinLength() {
      return !ctrl.minCount || ctrl.selectedIds.length === 0 && ctrl.required || ctrl.selectedIds && ctrl.selectedIds.length >= ctrl.minCount;
    }
    function validMaxLength() {
      return !ctrl.maxCount || ctrl.selectedIds && ctrl.selectedIds.length <= ctrl.maxCount;
    }
    function noDuplicates() {
      return ctrl.selectedIds && _.uniq(ctrl.selectedIds).length === ctrl.selectedIds.length;
    }
    ctrl.remove = function (index) {
      var removeId = ctrl.selectedIds[index];
      _.pullAt(ctrl.selectedIds, index);
      _.pull(ctrl.disabledIds, removeId);
      if (ctrl.selectedIds.length === 0) {
        ctrl.selectedIds = [];
        ctrl.selectedObjects = [];
      }
      ctrl.form.$setDirty();
      if (ctrl.onChange) {
        ctrl.onChange();
      }
      ctrl.validate();
    };
    ctrl.addNew = function () {
      if (!ctrl.disableAdd) {
        ensureIdsArray();
        ctrl.selectedIds.push(null);
        ctrl.form.$setDirty();
        ctrl.validate();
      }
    };
    ctrl.onSelect = function (index, oldSelectedObject, newSelectedObject) {
      if (newSelectedObject) {
        ctrl.selectedIds[index] = getId(newSelectedObject);
      } else {
        ctrl.selectedIds[index] = null;
      }
      if (ctrl.onChange) {
        ctrl.onChange();
      }
      ctrl.validate();
    };
  }
})();