import angular from 'angular';
import { environmentNgjsServiceModule } from 'sis-common/environmentService/environmentNgjs.service';
import { key as frontendInfoHeaderKey, value as frontendInfoHeaderValue } from 'sis-common/http-interceptors/frontend-info/frontend-info-header';
export const frontendInfoNgjsInterceptorModule = 'sis-common.http-interceptors.frontend-info.interceptor';
(function () {
  config.$inject = ["$httpProvider"];
  FrontendInfoNgjsInterceptor.$inject = ["environmentNgjsService"];
  angular.module(frontendInfoNgjsInterceptorModule, [environmentNgjsServiceModule]).factory('FrontendInfoNgjsInterceptor', FrontendInfoNgjsInterceptor).config(config);

  /**
   * @ngInject
   */
  function config($httpProvider) {
    $httpProvider.interceptors.push('FrontendInfoNgjsInterceptor');
  }

  /**
   * @ngInject
   */
  function FrontendInfoNgjsInterceptor(environmentNgjsService) {
    return {
      request: function (request) {
        request.headers[getFrontendInfoHeaderKey()] = getFrontendInfoHeaderValue();
        return request;
      }
    };
    function getFrontendInfoHeaderKey() {
      return frontendInfoHeaderKey();
    }
    function getFrontendInfoHeaderValue() {
      const frontendName = environmentNgjsService.getFrontendName();
      return frontendInfoHeaderValue(frontendName);
    }
  }
})();