import { inject } from '@angular/core';
import {
    StateService,
    TargetState,
    TargetStateDef,
    Transition, TransitionService,
} from '@uirouter/core';
import { filter, firstValueFrom, map, Observable, skipWhile } from 'rxjs';

import { AuthService } from '../auth/auth-service';
import { NavigationService } from '../auth/navigation-service';
import { TokenStorageService } from '../auth/token-storage.service';
import { SessionStorageService } from '../storage/session-storage.service';

export abstract class AuthRouteService {

    authService = inject(AuthService);
    transitionService = inject(TransitionService);
    tokenStorage = inject(TokenStorageService);
    state = inject(StateService);
    sessionStorageService = inject(SessionStorageService);
    navigationService = inject(NavigationService);

    initialize() {
        this.registerRouteTransitionListeners();
        this.registerUserLogoutEvents();
        this.setAnonymousUrls();
    }

    registerRouteTransitionListeners() {
        this.transitionService.onStart({ to: this.loginPageStateName }, () => firstValueFrom(this.handleLoginPageTransition()));
        this.transitionService.onStart({ to: this.loggedInStatesGlob }, (transition) => firstValueFrom(this.handleLoggedInTransition(transition)));
    }

    registerUserLogoutEvents() {
        this.tokenStorage.userSessionChanged$.subscribe(() => {
            const { state, params, options } = this.sessionChanged;
            this.state.go(state, params, options);
        });
        this.authService.userLoggedIn$
            .pipe(
                // Ignore values until the user has logged in
                skipWhile(loggedIn => !loggedIn),
                filter(loggedIn => !loggedIn),
            )
            .subscribe(() => {
                const { state, params, options } = this.loggedOut;
                const loginTarget = options?.custom?.loginTarget;
                if (loginTarget) {
                    this.sessionStorageService.setItem('loginTarget', loginTarget);
                }
                const navigation = this.state.go(state, params, options) || Promise.resolve();
                navigation.then(() => {
                    this.navigationService.navigate(this.state.href(state, params));
                });
            });
    }

    setAnonymousUrls() {
        this.authService.setAnonymousUrls(this.anonymousUrls, this.excludedUrls);
    }

    handleLoginPageTransition(): Observable<TargetState> {
        return this.authService.initializeAppAuth().pipe(
            map((loggedIn: boolean) => {
                if (loggedIn) {
                    const { state, params, options } = this.defaultView;
                    return this.state.target(state, params, options);
                }
            }));
    }

    handleLoggedInTransition(transition: Transition): Observable<TargetState> {
        return this.authService.initializeAppAuth().pipe(
            map((loggedIn: boolean) => {
                if (!loggedIn) {
                    return this.state.target(this.loginPage.state);
                }
                if (this.authService.insufficientScope(transition.$to().data?.requiresScope)) {
                    const { state, params, options } = this.unauthorized;
                    return this.state.target(state, params, options);
                }
            }));
    }

    abstract get loginPageStateName(): string;

    abstract get loggedInStatesGlob(): string;

    abstract get defaultView(): TargetStateDef;

    abstract get unauthorized(): TargetStateDef;

    abstract get loggedOut(): TargetStateDef;

    abstract get sessionChanged(): TargetStateDef;

    abstract get loginPage(): TargetStateDef;

    abstract get anonymousUrls(): string[];

    abstract get excludedUrls(): string[];

}
