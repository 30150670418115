export const controlsFactoryModule = 'sis-components.select.code-selector-controls';
(function () {
  angular.module(controlsFactoryModule, []).factory('controlsFactory', controlsFactory);
  function controlsFactory() {
    function SISControl() {
      this.state = {
        enabled: true
      };
    }
    function build(prototype) {
      return new prototype.constructor();
    }
    function buildButton() {
      return build(SISControlButton.prototype);
    }
    function buildControl() {
      return build(SISControl);
    }
    function disable() {
      this.state.enabled = false;
    }
    function enable() {
      this.state.enabled = true;
    }
    function enabled() {
      return this.state.enabled;
    }
    SISControl.prototype = SISControl;
    SISControl.prototype.constructor = SISControl;
    SISControl.prototype.disable = disable;
    SISControl.prototype.enable = enable;
    SISControl.prototype.enabled = enabled;
    function SISControlButton() {
      SISControl.call(this);
    }
    SISControlButton.prototype = Object.create(SISControl.prototype);
    SISControlButton.prototype.constructor = SISControlButton;
    return {
      buildButton: buildButton,
      buildControl: buildControl
    };
  }
})();