<ng-container *transloco="let t">
  <ng-container *ngIf="stateObject">
    <ng-container *ngIf="stateObject.type === 'ModuleStateObject'">
      <div class="sis-plan-structure-module-selection-state" *ngIf="stateObject">
        <div class="sis-plan-structure-module-selection-state__status"
             *ngIf="stateObject.moduleValidationResult.state !== 'ATTAINED';else attainedModule"
             [ngSwitch]="stateObject.planState">
          <p *ngSwitchCase="'INVALID'"
             class="sis-mb-zero sis-plan-structure-module-selection-state__status--invalid">
            {{t('SIS_COMPONENTS.PLAN_STATE.INVALID')}}
            <sis-icon [icon]="'alert-fill'"></sis-icon>
          </p>
          <p *ngSwitchDefault
             class="sis-mb-zero">
            {{t('SIS_COMPONENTS.PLAN_STATE.' + stateObject.planState)}}
          </p>
        </div>
        <ng-template #attainedModule>
          <div class=" sis-plan-structure-module-selection-state__status">
            <p class="sis-mb-zero sis-plan-structure-module-selection-state__status--attained">
              {{t('SIS_COMPONENTS.PLAN_STATE.ATTAINED')}}
              <sis-icon icon="achievement" color="success"></sis-icon>
              {{ stateObject.grade?.abbreviation | localizedString }}
            </p>
          </div>
        </ng-template>
      </div>
    </ng-container>
    <ng-container *ngIf="stateObject.type === 'EducationStateObject'">
      <div class="sis-plan-structure-module-selection-state">
        <div class="sis-plan-structure-module-selection-state__status"
             [ngSwitch]="stateObject.educationValidationResult.state">
          <p *ngSwitchCase="'INVALID'"
             class="sis-mb-zero sis-plan-structure-module-selection-state__status--invalid">
            {{t('SIS_COMPONENTS.PLAN_STATE.INVALID')}}
            <sis-icon [icon]="'alert-fill'"></sis-icon>
          </p>
          <p *ngSwitchDefault
             class="sis-mb-zero">
            {{t('SIS_COMPONENTS.PLAN_STATE.' + stateObject.educationValidationResult.state)}}
          </p>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="stateObject.type === 'CustomModuleAttainmentStateObject'">
      <div *ngIf="stateObject.grade"
           class="sis-plan-structure-module-selection-state">
        <div class="sis-plan-structure-module-selection-state__status">
          <p class="sis-plan-structure-module-selection-state__status--attained sis-mb-zero">
            {{t('SIS_COMPONENTS.PLAN_STATE.ATTAINED')}}
            <sis-icon icon="achievement" color="success"></sis-icon>
            {{ stateObject.grade.abbreviation | localizedString }}
          </p>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
