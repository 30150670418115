<sis-typeahead
        [placeholder]="placeholderText"
        [id]="id"
        [typeahead]="search.bind(this)"
        (selectItem)="onSelectResult($event.item)"
        [resultTemplate]="inlineSearchResultTemplate"
        class="multiple-line-items">
</sis-typeahead>
<ng-template #inlineSearchResultTemplate let-item="result">
    <div class="row" [class.disabled]="isSearchResultDisabled(item)">
        <div class="col-12">
            <div class="row">
                <div class="col-9">
                    <span class="additional-data">
                        <span>{{ item.code }}</span>
                        <span *ngIf="searchTarget === 'CourseUnit' && !isHomeUniversityResult(item)">
                            <span *ngFor="let universityOrgId of item.universityOrgIds">
                                <sis-tiny-badge [content]="universityOrgId | organisation | async | entityName | localizedString" [variant]="'primary'"></sis-tiny-badge>
                            </span>
                        </span>
                    </span>
                </div>
                <div class="col-3 additional-data text-right">
                    <span *ngIf="item.credits">{{item.credits | creditRange}}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <span>{{ item.name }}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div *ngIf="someOtherFieldThanNameInUILangMatched(item)">
                        <span class="additional-data">
                          <transloco [content]="someOtherFieldThanNameInUILangMatched(item)" />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>

</ng-template>
