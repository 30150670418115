import { Component, Input, ViewEncapsulation } from '@angular/core';
import { LocalDateRange, LocalizedString, OtmId, Urn } from 'common-typescript/types';

/**
 * Displays the name/description and role of a person who is in some way responsible for an entity (module, course unit, etc.).
 * Automatically hides responsibility infos that are not valid at the moment.
 */
@Component({
    selector: 'sis-responsibility-info',
    templateUrl: './responsibility-info.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ResponsibilityInfoComponent {

    @Input() responsibilityInfo: {
        personId?: OtmId;
        text?: LocalizedString;
        roleUrn: Urn;
        validityPeriod?: LocalDateRange;
    };

    @Input() showInactiveResponsibilities: boolean;

    @Input() showResponsibilityValidityPeriod: boolean;
}
