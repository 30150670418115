import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { MobilityPeriod, OtmId } from 'common-typescript/types';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

import { EntityService } from './entity.service';
import { SisuDataLoader } from './SisuDataLoader';

const CONFIG = {
    ENDPOINTS: {
        backend: '/ori/api',
        get baseUrl() {
            return `${this.backend}/mobility-periods`;
        },
        mobilityPeriod(id: OtmId) {
            return `${CONFIG.ENDPOINTS.baseUrl}/${id}`;
        },
    },
};

@StaticMembers<DowngradedService>()
@Injectable({
    providedIn: 'root',
})
@NgEntityServiceConfig({
    baseUrl: CONFIG.ENDPOINTS.backend,
    resourceName: 'mobility-periods',
})
export class MobilityPeriodEntityService extends EntityService<MobilityPeriodState> {

    static downgrade: ServiceDowngradeMappings = {
        dependencies: [],
        moduleName: 'sis-components.service.mobilityPeriodEntityService',
        serviceName: 'mobilityPeriodEntityService',
    };

    constructor() {
        super(MobilityPeriodStore, MobilityPeriodQuery);
        this.studyRightIdDataLoader = new SisuDataLoader<OtmId, MobilityPeriod, MobilityPeriod[]>(
            {
                getByIdsCall: id => this.createGetByStudyRightIdsCall(id),
                successEntitiesCallback: entities => this.store.upsertMany(entities),
                resultExtractor: (id, entities) => entities.filter(entity => entity.studyRightId === id),
            },
        );
    }

    private readonly studyRightIdDataLoader: SisuDataLoader<OtmId, MobilityPeriod, MobilityPeriod[]>;

    getActiveByStudyRightId(srId: OtmId): Observable<MobilityPeriod[]> {
        return this.studyRightIdDataLoader.load(srId)
            .pipe(switchMap(() => this.query.selectAll({ filterBy: e => e.studyRightId === srId && e.documentState === 'ACTIVE' })));
    }

    addMobilityPeriod(body: MobilityPeriod): void {
        this.getHttp().post(CONFIG.ENDPOINTS.baseUrl, body)
            .pipe(
                catchError((error: HttpErrorResponse) => throwError(() => new Error(error.message))),
            )
            .subscribe((data: MobilityPeriod) => this.store.add(data));
    }

    deleteMobilityPeriod(id: string): void {
        this.getHttp().delete(CONFIG.ENDPOINTS.mobilityPeriod(id))
            .pipe(
                catchError((error: HttpErrorResponse) => throwError(() => new Error(error.message))),
            )
            .subscribe(() => this.store.remove(id));
    }

    updateMobilityPeriod(body: MobilityPeriod): void {
        this.getHttp().put(CONFIG.ENDPOINTS.mobilityPeriod(body.id), body)
            .pipe(
                catchError((error: HttpErrorResponse) => throwError(() => new Error(error.message))),
            )
            .subscribe((data: MobilityPeriod) => this.store.update(data.id, { ...data }));
    }

    private createGetByStudyRightIdsCall(ids: OtmId[]): Observable<MobilityPeriod[]> {
        return this.getHttp().get<MobilityPeriod[]>(CONFIG.ENDPOINTS.baseUrl, { params: { studyRightId: ids.toString() } });
    }
}

type MobilityPeriodState = EntityState<MobilityPeriod>;

@StoreConfig({ name: 'mobilityPeriod' })
class MobilityPeriodStore extends EntityStore<MobilityPeriodState, MobilityPeriod> {}

class MobilityPeriodQuery extends QueryEntity<MobilityPeriodState> {
    constructor(protected store: MobilityPeriodStore) {
        super(store);
    }
}
