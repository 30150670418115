<ng-container *ngIf="cart && loaded">
  <ng-container *ngFor="let cartItem of cart.items">
    <sis-expandable [id]="cartItem.localId"
                    [level]="3"
                    [title]="(courseUnitsByCartItemId[cartItem.localId].name | localizedString) + ' (' + (courseUnitsByCartItemId[cartItem.localId].credits | creditRange: 'TEXT': false) + ')'"
                    [subTitle]="courseUnitsByCartItemId[cartItem.localId].code"
                    #selectionExpandable>
      <ng-template sisExpandableCustomTitle>
        <div class="course-unit-cart-expandable-info d-lg-inline">
          <sis-tiny-badge *ngIf="isEditable && !isValidByCartItemId[cartItem.localId]" variant="danger" class="ms-lg-0">
            {{ 'OPEN_UNIVERSITY_CART.WIZARD.TEACHING.ENROLMENT_MISSING_BADGE' | translate }}
          </sis-tiny-badge>
          <div class="price-text">{{ cartItem.price | money }}</div>
        </div>
      </ng-template>
      <ng-template sisExpandableHeaderButtons *ngIf="isEditable">
        <sis-tooltip [appendToBody]="true" [tooltipBlockElement]="true" class="d-none d-md-inline-block sis-ml-xs">
          <span class="sis-tooltip-content" aria-hidden="true">
            {{ 'OPEN_UNIVERSITY_CART.WIZARD.TEACHING.DELETE_FROM_CART' | translate }}
          </span>
          <sis-button class="delete-icon-button sis-tooltip-trigger-content"
                      (clicked)="removeItemFromCart(cartItem.openUniversityProductId, courseUnitsByCartItemId[cartItem.localId].id)"
                      [hollow]="true"
                      [disabled]="requestInProgress"
                      [ariaLabel]="'OPEN_UNIVERSITY_CART.WIZARD.TEACHING.DELETE_FROM_CART_ARIA_LABEL' | translate: {cartItemName: courseUnitsByCartItemId[cartItem.localId].name | localizedString}">
            <sis-icon icon="delete"></sis-icon>
          </sis-button>
        </sis-tooltip>
      </ng-template>
      <ng-template sisExpandableContent>
        <app-product-basic-info
          [product]="productsByCartItemId[cartItem.localId]"
          [completionMethod]="getCompletionMethod(courseUnitsByCartItemId[cartItem.localId], productsByCartItemId[cartItem.localId])">
        </app-product-basic-info>

        <sis-contextual-notification *ngIf="isEditable && !isValidByCartItemId[cartItem.localId]" variant="danger">
          {{ 'OPEN_UNIVERSITY_CART.WIZARD.TEACHING.ENROLMENT_MISSING_NOTIFICATION' | translate }}
        </sis-contextual-notification>

        <app-open-university-enrolled-info *ngIf="isValidByCartItemId[cartItem.localId]"
                                           [assessmentItemsData]="assessmentItemsData"
                                           [openUniStudentEnrolments]="enrolmentsByCartItemId[cartItem.localId]"
                                           [level]="4"
                                           [hideEnrolmentQuestionnaire]="true">
        </app-open-university-enrolled-info>
        <div *ngIf="isEditable" class="row">
          <div [ngClass]="{'col-sm-6': !isValidByCartItemId[cartItem.localId], 'col-12': isValidByCartItemId[cartItem.localId]}">
            <sis-button class="d-block d-md-none delete-text-button"
                        (clicked)="removeItemFromCart(cartItem.openUniversityProductId, courseUnitsByCartItemId[cartItem.localId].id)"
                        [hollow]="true"
                        [disabled]="requestInProgress">
              <sis-icon icon="delete"></sis-icon>
              {{ 'OPEN_UNIVERSITY_CART.WIZARD.TEACHING.DELETE_FROM_CART' | translate }}
            </sis-button>
          </div>
          <div class="col-sm-6">
            <sis-button class="d-block choose-groups-button"
                        *ngIf="!isValidByCartItemId[cartItem.localId]"
                        (clicked)="goEnrolmentWizard(courseUnitsByCartItemId[cartItem.localId], productsByCartItemId[cartItem.localId])" >
              {{ 'OPEN_UNIVERSITY_CART.WIZARD.TEACHING.ENROL_BUTTON' | translate }}
            </sis-button>
          </div>
        </div>
      </ng-template>
    </sis-expandable>
  </ng-container>
</ng-container>

<div *ngIf="!loaded" class="spinner-border" role="status">
  <span class="visually-hidden">{{'OPEN_UNIVERSITY_CART.WIZARD.LOADING' | translate}}</span>
</div>
