import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { FudisIcon, FudisIconColor } from '@funidata/ngx-fudis/lib/types/icons';

export type NotificationType = 'info' | 'alert' | 'waiting' | 'waiting-approval' | 'waiting-decline' | 'approved' | 'declined' | 'substitution' | 'study-path' | 'implicit' | 'none';

@Component({
    selector: 'sis-study-notification-box',
    templateUrl: './study-notification-box.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudyNotificationBoxComponent implements OnInit {

    @Input() type?: NotificationType = 'info';
    @Input() primaryButtonText?: string;
    @Input() secondaryButtonText?: string;
    @Output() infoClickAction = new EventEmitter<void>();
    @Output() primaryClickAction = new EventEmitter<void>();
    @Output() secondaryClickAction = new EventEmitter<void>();

    icon: FudisIcon;
    iconColor: FudisIconColor;

    ngOnInit() {
        this.icon = this.getIcon();
        this.iconColor = this.getIconColor();
    }

    getIcon(): FudisIcon {
        switch (this.type) {
            case 'waiting-approval':
            case 'waiting-decline':
                return this.type;
            case 'alert':
                return 'alert-fill';
            case 'waiting':
                return 'hourglass';
            case 'approved':
                return 'check';
            case 'declined':
                return 'fail';
            case 'substitution':
                return 'switch';
            case 'study-path':
                return 'junction';
            case 'implicit':
                return 'exclamation-mark-circle';
            case 'info':
            default:
                return 'info-circle-fill';
        }
    }

    getIconColor(): FudisIconColor {
        switch (this.type) {
            case 'declined':
                return 'red';
            case 'approved':
                return 'green';
            default:
                return 'gray-dark';
        }
    }
}
