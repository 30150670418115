import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FudisDialogService } from '@funidata/ngx-fudis';
import { LocalizedString } from 'common-typescript/types';
import { LocaleService } from 'sis-common/l10n/locale.service';

interface GraduationSurveyValues {
    surveyUrl: LocalizedString;
    infoText: string;
    infoQuestion: string;
    surveyUrlInstructions: string;
    modalTitle: string;
    continueButtonText: string;
}

@Component({
    selector: 'app-graduation-survey-modal',
    templateUrl: './graduation-survey-modal.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class GraduationSurveyModalComponent {

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: GraduationSurveyValues,
        private dialogService: FudisDialogService,
        private localeService: LocaleService,
    ) { }

    getLocalizedSurveyUrl() {
        return this.localeService.localize(this.data.surveyUrl);
    }

    closeSurveyDialog() {
        this.dialogService.close();
    }
}
