import angular from 'angular';
import _ from 'lodash';
import 'sis-components/error-handler/legacy/errorService';
import { IconComponent } from '../icon/icon.component.ts';
import moduleSelectionTpl from './moduleSelection.tpl.html';
import { PlanSelectorConfig } from './planSelectorConfig.ts';
(function () {
  moduleSelectionCtrl.$inject = ["$scope", "SELECTION_TYPES", "commonModuleService"];
  angular.module('sis-components.plan.moduleSelection', ['sis-common.errorhandler.errorService', 'sis-components.plan.elementSelect', 'sis-components.plan.moduleName', 'sis-components.service.moduleService', 'sis-components.plan.courseUnitWithSubstitutions', IconComponent.downgrade.moduleName]).component('moduleSelection', {
    template: moduleSelectionTpl,
    controller: moduleSelectionCtrl,
    bindings: {
      plan: '<',
      validatablePlan: '<',
      moduleId: '<',
      parentId: '<',
      selectionDisabled: '<',
      config: '<' // PlanSelectorConfig
    }
  });

  /**
   * @ngInject
   */
  function moduleSelectionCtrl($scope, SELECTION_TYPES, commonModuleService) {
    const $ctrl = this;
    $ctrl.moduleId = undefined;
    $ctrl.moduleGroupId = undefined;
    $ctrl.SELECTION_TYPES = SELECTION_TYPES;
    $ctrl.$onInit = () => {
      if (!$ctrl.config) {
        $ctrl.config = new PlanSelectorConfig();
      }
      $ctrl.isOpen = $ctrl.config.allOpen;
      $ctrl.isEmpty = false;
      $ctrl.hasAnyModuleRule = false;
      $ctrl.hasAnyCourseUnitRule = false;
    };
    $ctrl.$onChanges = changesObject => {
      if (changesObject.plan) {
        $ctrl.isolatedPlan = _.cloneDeep(changesObject.plan.currentValue);
      }
      if (changesObject.moduleId) {
        $ctrl.moduleId = changesObject.moduleId.currentValue;
      }
      if ($ctrl.moduleId && changesObject.validatablePlan && changesObject.validatablePlan.currentValue) {
        const validatablePlan = changesObject.validatablePlan.currentValue;
        commonModuleService.findById($ctrl.moduleId).then(module => {
          $ctrl.moduleGroupId = module.groupId;
          return module;
        }).then(module => {
          $ctrl.childModuleSelections = validatablePlan.getSelectedModulesById(module);
          $ctrl.childCourseUnitSelections = validatablePlan.getSelectedCourseUnitsById(module);
          $ctrl.childCustomStudyDrafts = validatablePlan.getSelectedCustomStudyDraftsByParentModuleId(module.id);
          $ctrl.hasAnyModuleRule = module.hasAnyModuleRule;
          $ctrl.hasAnyCourseUnitRule = module.hasAnyCourseUnitRule;
          if ($ctrl.childModuleSelections.length > 0) {
            $scope.$applyAsync(() => {
              $ctrl.isOpen = true;
              $ctrl.moduleId = changesObject.moduleId.currentValue;
            });
          }
        });
      }
    };
    $ctrl.openAccordion = $event => {
      $ctrl.isOpen = !$ctrl.isOpen;
      $event.preventDefault();
    };
  }
})();