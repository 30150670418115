import {
    ChangeDetectionStrategy,
    Component, EventEmitter, Input, Output,
    ViewEncapsulation,
} from '@angular/core';
import { DegreeProgrammeAttainmentWorkflow, Module } from 'common-typescript/types';

@Component({
    selector: 'app-student-module-info-modal-dp-attainment-application-block',
    templateUrl: './student-module-info-modal-dp-attainment-application-block.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudentModuleInfoModalDPAttainmentApplicationBlockComponent {

    @Input() selectedModule: Module;
    @Input() degreeProgrammeAttainmentWorkflow: DegreeProgrammeAttainmentWorkflow;
    @Output() closeModal = new EventEmitter<void>();

    close() {
        this.closeModal.emit();
    }

}
