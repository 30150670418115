import { Pipe, PipeTransform } from '@angular/core';
import { LocalizedString } from 'common-typescript/types';

import { LocaleService } from './locale.service';

@Pipe({ name: 'localizedStringArray' })
export class LocalizedStringArrayPipe implements PipeTransform {

    constructor(private localeService: LocaleService) {}

    transform(value: LocalizedString[]): string {
        return this.localeService.localizeArray(value);
    }
}
