import angular from 'angular';
import { jsDataCacheHelperModule } from './jsDataCacheHelper.service';
export const publicPersonServiceModule = 'sis-components.service.publicPersonService';
(function () {
  publicPersonService.$inject = ["publicPersonModel", "jsDataCacheHelper"];
  angular.module(publicPersonServiceModule, ['sis-components.model.publicPerson', jsDataCacheHelperModule]).service('publicPersonService', publicPersonService);

  /**
   * @ngInject
   */

  function publicPersonService(publicPersonModel, jsDataCacheHelper) {
    const api = {
      findById: id => {
        return publicPersonModel.find(id);
      },
      findByIds: ids => {
        return jsDataCacheHelper.findByIds(publicPersonModel, ids, false);
      }
    };
    return api;
  }
})();