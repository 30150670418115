import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { InternationalContractualDegreeAgreement, OtmId } from 'common-typescript/types';
import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

import { EntityService } from './entity.service';

const CONFIG = {
    ENDPOINTS: {
        backend: '/kori/api',
        get getByUniversityOrgId() {
            return `${this.backend}/international-contractual-degree-agreements/by-university-org-id/`;
        },
    },
};

@StaticMembers<DowngradedService>()
@Injectable({
    providedIn: 'root',
})
@NgEntityServiceConfig({
    baseUrl: CONFIG.ENDPOINTS.backend,
    resourceName: 'international-contractual-degree-agreements',
})
export class InternationalContractualDegreeAgreementEntityService extends EntityService<InternationalContractualDegreeAgreementState> {
    constructor() {
        super(MobilityPeriodStore, MobilityPeriodQuery);
    }

    static downgrade: ServiceDowngradeMappings = {
        dependencies: [],
        moduleName: 'sis-components.service.internationalContractualDegreeAgreementEntityService',
        serviceName: 'internationalContractualDegreeAgreementEntityService',
    };

    getByUniversityOrgId(universityOrgId: OtmId): Observable<InternationalContractualDegreeAgreement[]> {
        if (!universityOrgId) {
            throwError(() => new Error('The id was missing!'));
        }

        return this.getHttp()
            .get<InternationalContractualDegreeAgreement[]>(`${CONFIG.ENDPOINTS.getByUniversityOrgId}${universityOrgId}`)
            .pipe(tap(agreements => this.store.upsertMany(agreements)));
    }
}

type InternationalContractualDegreeAgreementState = EntityState<InternationalContractualDegreeAgreement>;

@StoreConfig({ name: 'international-contractual-degree-agreements' })
class MobilityPeriodStore extends EntityStore<InternationalContractualDegreeAgreementState, InternationalContractualDegreeAgreement> {}

class MobilityPeriodQuery extends QueryEntity<InternationalContractualDegreeAgreementState> {
    constructor(protected store: MobilityPeriodStore) {
        super(store);
    }
}
