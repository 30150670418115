import { Pipe, PipeTransform } from '@angular/core';
import { Education, LocalId, LocalizedString } from 'common-typescript/types';

import { EducationEntityService } from '../service/education-entity.service';

@Pipe({ name: 'learningOpportunityName' })
export class LearningOpportunityNamePipe implements PipeTransform {
    constructor(private educationEntityService: EducationEntityService) {
    }

    transform(localId: LocalId, education: Education): LocalizedString {
        return this.educationEntityService.getLearningOpportunity(localId, education)?.name;
    }
}
