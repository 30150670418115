import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '@uirouter/core';
import { OpenUniversityCart } from 'common-typescript/types';
import { catchError, combineLatest, Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { ModalService } from 'sis-common/modal/modal.service';
import { OpenUniversityCartCustomerService } from 'sis-components/service/open-university-cart-customer.service';
import { UniversityService } from 'sis-components/service/university.service';

import { OpenUniversityErrorDialogComponent, OpenUniversityErrorDialogValues } from '../open-university-error-dialog/open-university-error-dialog.component';

@Injectable({ providedIn: 'root' })
export class OpenUniversityErrorHelperService {
    private readonly modalService = inject(ModalService);

    constructor(
        private cartService: OpenUniversityCartCustomerService,
        private localeService: LocaleService,
        private state: StateService,
        private translate: TranslateService,
        private universityService: UniversityService,
    ) {}

    showPaymentInitializationFailureAlert(): void {
        combineLatest([
            this.fetchCurrentCartOrNull(),
            this.fetchOpenUniversityContactInfoLinkOrNull(),
        ])
            .subscribe(([cart, contactInfoLink]) => {
                this.initErrorDialog('OPEN_UNIVERSITY.ERRORS.PAYMENT_INITIALIZATION.TITLE',
                                     ['OPEN_UNIVERSITY.ERRORS.PAYMENT_INITIALIZATION.DESC'],
                                     cart?.orderNumber,
                                     contactInfoLink);
            });
    }

    showPaymentInProgressFailureAlert(): void {
        combineLatest([
            this.cartService.getCurrentCart().pipe(take(1)),
            this.fetchOpenUniversityContactInfoLinkOrNull(),
        ])
            .subscribe(([cart, contactInfoLink]) => {
                const completePaymentMsg = this.getLink(
                    this.state.href('student.open-university-cart'),
                    this.translate.instant('OPEN_UNIVERSITY.ERRORS.PAYMENT_IN_PROGRESS.DESC'),
                );
                this.initErrorDialog('OPEN_UNIVERSITY.ERRORS.PAYMENT_IN_PROGRESS.TITLE',
                                     ['OPEN_UNIVERSITY.ERRORS.PAYMENT_IN_PROGRESS.DESC_2'],
                                     cart?.orderNumber,
                                     contactInfoLink,
                                     completePaymentMsg);
            });
    }

    showEnrolmentConfirmationFailureAlert(orderNumber?: string): void {
        this.fetchOpenUniversityContactInfoLinkOrNull()
            .subscribe(contactInfoLink => {
                this.initErrorDialog('OPEN_UNIVERSITY.ERRORS.ENROLMENT_CONFIRMATION.TITLE',
                                     ['OPEN_UNIVERSITY.ERRORS.ENROLMENT_CONFIRMATION.DESC'],
                                     orderNumber,
                                     contactInfoLink);
            });
    }

    private initErrorDialog(title: string, descriptions: string[], orderNumber: string, contactInfoLink: string, completePaymentMsgAnchorElement?: string) {
        const values: OpenUniversityErrorDialogValues = {
            title,
            descriptions,
            orderNumberMsg: this.getOrderNumberMsg(orderNumber),
            contactOrganizer: {
                msg: 'OPEN_UNIVERSITY.ERRORS.CONTACT_ORGANIZER',
                link: contactInfoLink,
            },
            completePaymentMsgAnchorElement,
        };
        this.modalService.open(OpenUniversityErrorDialogComponent, values, { size: 'sm' }).result
            .catch(() => {}); // Treat closing with Esc key (that rejects the Promise) similarly to clicking the close icon
    }

    private fetchCurrentCartOrNull(): Observable<OpenUniversityCart | null> {
        // Force fetch the current cart from the backend to make sure it's up to date
        return this.cartService.getCurrentCart(true)
            .pipe(
                take(1),
                catchError(() => of(null)),
            );
    }

    fetchOpenUniversityContactInfoLinkOrNull(): Observable<string | null> {
        return this.universityService.getCurrentUniversitySettings()
            .pipe(
                take(1),
                map(settings => this.getMailtoLink(settings?.openUniversitySettings?.customerServiceEmail) ??
                    this.getExternalLink(this.localeService.localize(settings?.openUniversitySettings?.customerServiceUrl))),
                catchError(() => of(null)),
            );
    }

    private getMailtoLink(email?: string): string | null {
        return email ? `mailto:${email}` : null;
    }

    private getLink(url?: string, linkText?: string): string | null {
        return url ? `<a href="${url}">${linkText ?? url.replace(/^https?:\/\//, '')}</a>` : null;
    }

    private getExternalLink(url?: string): string | null {
        return url ? `${url.replace(/^https?:\/\//, '')}` : null;
    }

    private getOrderNumberMsg(orderNumber: string): string | null {
        return orderNumber ? this.translate.instant(
            'OPEN_UNIVERSITY.ERRORS.PROVIDE_ORDER_NUMBER',
            { orderNumber },
        ) : null;
    }

    fetchOpenUniversityContactInfoEmailOrUrlOrNull(): Observable<{ link: string, isEmail: boolean, contactLinkText: string } | null> {
        return this.universityService.getCurrentUniversitySettings().pipe(
            map(settings => {
                const email = settings?.openUniversitySettings?.customerServiceEmail;
                const url = this.localeService.localize(settings?.openUniversitySettings?.customerServiceUrl);

                if (email) {
                    return { link: email, isEmail: true, contactLinkText: null };
                } if (url) {
                    return { link: url, isEmail: false, contactLinkText: url.replace(/^https?:\/\//, '') };
                } return null;
            }),
            catchError(() => of(null)),
        );
    }
}
