import angular from 'angular';
(function () {
  messageJSDataModel.$inject = ["DS", "MessageEndpoint", "MessageType", "courseUnitJSDataModel", "planModuleJSDataModel", "studyModuleJSDataModel", "privatePersonInfoJSDataModel", "planJSDataModel"];
  angular.module('sis-components.model.message', ['js-data', 'sis-components.constant.messageTypes', 'sis-components.model.privatePersonInfo', 'sis-components.model.module', 'sis-components.model.plan', 'sis-components.model.studyModule', 'sis-components.model.courseUnit']).factory('messageJSDataModel', messageJSDataModel).constant('MessageEndpoint', {
    DEFAULT: '/osuva/api/messages',
    FOR_PLAN: '/osuva/api/messages/for-plan',
    RECEIVED: '/osuva/api/messages/received',
    SENT: '/osuva/api/messages/sent',
    MARK_AS_READ: '/osuva/api/messages/mark-as-read/',
    FOR_TUTEES_OF_TUTOR: '/osuva/api/messages/for-tutees-of-tutor',
    FOR_COURSE_UNIT_REALISATION: '/osuva/api/messages/for-course-unit-realisation',
    FOR_PLANS_BY_EDUCATION: '/osuva/api/messages/for-plans-by-education'
  });

  /**
   * @ngInject
   */
  function messageJSDataModel(DS, MessageEndpoint, MessageType, courseUnitJSDataModel, planModuleJSDataModel, studyModuleJSDataModel, privatePersonInfoJSDataModel, planJSDataModel) {
    return DS.defineResource({
      idAttribute: 'id',
      name: 'message',
      endpoint: MessageEndpoint.DEFAULT,
      relations: {
        hasOne: {
          privatePersonInfo: {
            localField: 'student',
            localKey: 'studentId'
          },
          courseUnit: {
            localField: 'courseUnit',
            localKey: 'courseUnitId'
          },
          plan: {
            localField: 'plan',
            localKey: 'planId'
          },
          module: [{
            localField: 'module',
            localKey: 'moduleId'
          }, {
            localField: 'parentModule',
            localKey: 'parentModuleId'
          }],
          courseUnitRealisation: {
            localField: 'courseUnitRealisation',
            localKey: 'courseUnitRealisationId'
          }
        }
      },
      computed: {
        isPlanModuleComment: ['type', function (type) {
          return type === MessageType.PLAN_MODULE;
        }],
        isPlanCourseUnitComment: ['type', function (type) {
          return type === MessageType.PLAN_COURSE_UNIT;
        }],
        isPlanCustomStudyDraftComment: ['type', function (type) {
          return type === MessageType.PLAN_CUSTOM_STUDY_DRAFT;
        }],
        isPlanGenericComment: ['type', function (type) {
          return type === MessageType.PLAN_GENERIC;
        }],
        isModuleContentApplicationMessage: ['type', function (type) {
          return type === MessageType.CUSTOM_MODULE_CONTENT_APPLICATION_MESSAGE || type === MessageType.REQUIRED_MODULE_CONTENT_APPLICATION_MESSAGE;
        }],
        isPriorLearningInclusionApplicationMessage: ['type', function (type) {
          return type === MessageType.PRIOR_LEARNING_INCLUSION_APPLICATION_MESSAGE;
        }],
        isPriorLearningSubstitutionApplicationMessage: ['type', function (type) {
          return type === MessageType.PRIOR_LEARNING_SUBSTITUTION_APPLICATION_MESSAGE;
        }],
        isModuleAttainmentApplicationMessage: ['type', function (type) {
          return type === MessageType.MODULE_ATTAINMENT_APPLICATION_MESSAGE;
        }],
        isDegreeProgrammeAttainmentApplicationMessage: ['type', function (type) {
          return type === MessageType.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION_MESSAGE;
        }],
        isCustomAttainmentApplicationMessage: ['type', function (type) {
          return type === MessageType.CUSTOM_ATTAINMENT_APPLICATION_MESSAGE;
        }],
        isStudyRightExtensionApplicationMessage: ['type', function (type) {
          return type === MessageType.STUDY_RIGHT_EXTENSION_APPLICATION_MESSAGE;
        }],
        isManualCourseUnitRealisationMessage: ['type', function (type) {
          return type === MessageType.MANUAL_COURSE_UNIT_REALISATION_MESSAGE;
        }],
        isAutomaticCourseUnitRealisationMessage: ['type', function (type) {
          return type === MessageType.AUTOMATIC_COURSE_UNIT_REALISATION_MESSAGE;
        }],
        isCourseUnitManualEvaluationRequiredMessage: ['type', function (type) {
          return type === MessageType.COURSE_UNIT_MANUAL_EVALUATION_REQUIRED_MESSAGE;
        }],
        isTeacherMessage: ['plan', 'senderId', function (plan, senderId) {
          return plan && plan.userId !== senderId;
        }],
        isDeleted: ['documentState', function (documentState) {
          return documentState === 'DELETED';
        }],
        isModified: ['metadata', function (metadata) {
          return metadata && metadata.revision !== 1;
        }],
        senderFullName: ['senderFirstNames', 'senderLastName', function (firstNames, lastName) {
          return [firstNames, lastName].filter(Boolean).join(' ');
        }]
      }
    });
  }
})();