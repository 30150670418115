<ng-container *transloco="let t">
  <ng-container *ngIf="this.localizedGraduationInstructionsUrl$ | async as graduationInstructionsUrl; else noSettings">
    <p>
      <span>{{t('GRADUATION.APPLY_FOR_GRADUATION_MODAL.GRADUATION_INSTRUCTIONS')}}</span>
      <span *ngIf="graduationInstructionsUrl">
        {{t('GRADUATION.APPLY_FOR_GRADUATION_MODAL.GRADUATION_INSTRUCTIONS_URL')}}
      </span>
    </p>
    <p>
      <fudis-link *ngIf="graduationInstructionsUrl" [externalLink]="graduationInstructionsUrl"></fudis-link>
    </p>
  </ng-container>
  <ng-template #noSettings>
    <p>
      <span>{{t('GRADUATION.APPLY_FOR_GRADUATION_MODAL.GRADUATION_INSTRUCTIONS')}}</span>
    </p>
  </ng-template>
</ng-container>
