<ul #tabList
    [class.nav-invert]="invert"
    class="nav nav-{{type}} {{wide && 'nav-wide-' + wide}}"
    role="tablist">
  <li *ngFor="let tab of tabs; index as index"
      [class.has-tail]="tab.showTail"
      role="presentation"
      uiSrefActive="active">
    <a
      (blur)="handleBlur($event)"
      (click)="handleClick(index)"
      (keydown)="handleKeyDown($event)"
      (keyup)="handleKeyUp($event, index)"
      [attr.aria-selected]="selectedTabId === tab.id"
      [attr.aria-controls]="tab.controls"
      [attr.data-cy]="tab.id"
      [attr.id]="tab.id"
      [attr.tabindex]="selectedTabId === tab.id ? 0 : -1"
      [uiParams]="tab.params"
      [uiSref]="tab.path"
      role="tab">
      {{tab.title | translate}}<span *ngIf="tab.showTail" class="tail">{{tab.tail}}</span>
    </a>
  </li>
</ul>
