import { Injectable } from '@angular/core';
import { StudyRight } from 'common-typescript/types';
import { Observable, shareReplay, ShareReplayConfig } from 'rxjs';
import { StudyRightEntityService } from 'sis-components/service/study-right-entity.service';

@Injectable({ providedIn: 'root' })
export class MyStudyRightService {
    /**
     * Emits study rights of the logged-in user.
     */
    readonly studyRights$: Observable<readonly StudyRight[]>;

    constructor(studyRightEntityService: StudyRightEntityService) {
        // It is expected that getStudyRightsForCurrentUser never completes,
        // and thus shareReplay will keep studyRights$ warm only as long as there are subscribers
        // (a completing source stream would lead to a forever-warm stream).
        this.studyRights$ = studyRightEntityService.getStudyRightsForCurrentUser().pipe(
            shareReplay(<ShareReplayConfig>{ bufferSize: 1, refCount: true }),
        );
    }
}
