import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { CustomAttainmentWorkflow } from 'common-typescript/types';

@Component({
    selector: 'app-custom-attainment-workflow-summary',
    templateUrl: './custom-attainment-workflow-summary.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomAttainmentWorkflowSummaryComponent {
    @Input() studentWorkflow!: CustomAttainmentWorkflow;

    isWorkflowRequested() {
        return this.studentWorkflow?.state === 'REQUESTED';
    }

}
