export const curriculumPeriodServiceModule = 'student.common.service.curriculumPeriodService';
"use strict";
(function () {
  curriculumPeriodServiceFactory.$inject = ["findByIdsHelper", "universityService", "$http"];
  angular.module(curriculumPeriodServiceModule, ['student.common.utils.findByIdsHelper', 'sis-common.university']).factory('curriculumPeriodService', curriculumPeriodServiceFactory);

  /**
   * @ngInject
   */
  function curriculumPeriodServiceFactory(findByIdsHelper, universityService, $http) {
    var baseUri = '/kori/api/curriculum-periods';
    var api = {
      findByIds: function (ids) {
        return findByIdsHelper(baseUri, ids).then(function (data) {
          return data;
        });
      },
      findAll: function (universityOrgIds) {
        universityOrgIds = universityOrgIds ? universityOrgIds : [universityService.getCurrentUniversityOrgId()];
        return $http.get(baseUri + '?universityOrgId=' + universityOrgIds, {
          cache: true
        }).then(function (result) {
          return _.sortBy(result.data, function (curriculumPeriod) {
            return curriculumPeriod.activePeriod.startDate;
          });
        });
      },
      loadCurriculumPeriodsStartingFrom: function (firstCurrPeriodId) {
        var currentUniOrgId = universityService.getCurrentUniversityOrgId();
        return $http.get(baseUri + '?universityOrgId=' + currentUniOrgId + '&firstCurriculumPeriodId=' + firstCurrPeriodId, {
          cache: true
        }).then(function (result) {
          return _.sortBy(result.data, function (curriculumPeriod) {
            return curriculumPeriod.activePeriod.startDate;
          });
        });
      }
    };
    return api;
  }
})();