import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { dateUtils } from 'common-typescript';
import {
    CourseUnit, CourseUnitRealisation,
    Education,
    Enrolment,
    EnrolmentCalculationConfig,
    OtmId,
    Plan,
    StudyRight,
} from 'common-typescript/types';
import _ from 'lodash';
import moment from 'moment';
import { combineLatest, combineLatestWith, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { IsDegreeEducationPipe } from 'sis-components/education/is-degree-education.pipe';
import { getLabelState } from 'sis-components/form/formUtils';
import { EducationEntityService } from 'sis-components/service/education-entity.service';
import { PlanEntityService } from 'sis-components/service/plan-entity.service';
import { StudyRightEntityService } from 'sis-components/service/study-right-entity.service';

@Component({
    selector: 'app-study-right-select',
    templateUrl: './study-right-select.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudyRightSelectComponent implements OnInit {
    @Input() id?: string = 'studyRightId';
    @Input() enrolment: Enrolment;
    @Input() control: FormControl<OtmId>;
    @Input() enrolmentCalculationConfig: EnrolmentCalculationConfig;

    studyRights$: Observable<StudyRight[]>;
    educations: Education[];
    plans: Plan[];

    protected readonly getLabelState = getLabelState;

    constructor(private studyRightEntityService: StudyRightEntityService,
                private isDegreeEducationPipe: IsDegreeEducationPipe,
                private educationEntityService: EducationEntityService,
                private planEntityService: PlanEntityService) {
    }

    ngOnInit(): void {
        this.studyRights$ = this.getStudyRights();
    }

    getStudyRights() {
        return this.studyRightEntityService.getStudyRightsForCurrentUser()
            .pipe(
                map(studyRights => studyRights
                    .filter(studyRight => !studyRight.valid?.endDate || moment().isSameOrBefore(studyRight.valid.endDate))),
                combineLatestWith(this.planEntityService.getMyPlans()),
                mergeMap(([studyRights, plans]) => {
                    const educationsObservable: Observable<Education[]> = this.educationEntityService.getByIds(studyRights.map(sr => sr.educationId));
                    return combineLatest([educationsObservable, of(plans), of(studyRights)]);
                }),
                map(([educations, plans, studyRights]) => {
                    this.educations = educations;
                    this.plans = plans;
                    const eligibleStudyRights = this.setStudyRightsForEnrolment(studyRights);
                    return eligibleStudyRights;
                }));
    }

    setStudyRightsForEnrolment(studyRights: StudyRight[]): StudyRight[] {
        const degreeEducationIds = _.chain(studyRights)
            .filter(sr => this.isDegreeEducationPipe.transform(_.find(this.educations, { id: sr.educationId })))
            .map('educationId')
            .value();

        const activeAndPassiveDegreeStudyRights =
            _.filter(studyRights,
                     sr => ['NOT_STARTED', 'ACTIVE', 'ACTIVE_NONATTENDING', 'PASSIVE']
                         .includes(sr.state) && degreeEducationIds.includes(sr.educationId));

        const activeNonDegreeStudyRights =
            _.filter(studyRights,
                     sr => ['NOT_STARTED', 'ACTIVE', 'ACTIVE_NONATTENDING'].includes(sr.state) &&
                    !_.includes(degreeEducationIds, sr.educationId));

        const possibleStudyRightsForEnrolment =
            _.concat(activeAndPassiveDegreeStudyRights, activeNonDegreeStudyRights) as StudyRight[];

        let defaultStudyRightID: OtmId;

        if (this.enrolment.state === 'NOT_ENROLLED') {
            defaultStudyRightID = this.resolveStudyRightForEnrolment(activeAndPassiveDegreeStudyRights, activeNonDegreeStudyRights)?.id;
        } else {
            // If editing enrolment, use the study right associated with the enrolment
            defaultStudyRightID = this.enrolment.studyRightId;
        }

        if (!_.isNil(defaultStudyRightID) && this.control.value !== defaultStudyRightID) {
            // Override default value if it conflicts with the resolved study right
            if (possibleStudyRightsForEnrolment.some(sr => sr.id === defaultStudyRightID)) {
                this.control.setValue(defaultStudyRightID);
            }
        } else if (!possibleStudyRightsForEnrolment.some(sr => sr.id === this.control.value)) {
            // If default value is invalid for enrolment, remove it.
            this.control.setValue(null);
        }

        return possibleStudyRightsForEnrolment;
    }

    resolveStudyRightForEnrolment(activeAndPassiveDegreeStudyRights: StudyRight[], activeNonDegreeStudyRights: StudyRight[]) {
        if (_.size(activeAndPassiveDegreeStudyRights) === 1) {
            return _.head(activeAndPassiveDegreeStudyRights);
        }

        if (_.size(activeAndPassiveDegreeStudyRights) === 0 && _.size(activeNonDegreeStudyRights) === 1) {
            return _.head(activeNonDegreeStudyRights);
        }

        // primary plans with course unit selection that references enrolment.courseUnitId
        const plansWithCourseUnitSelection = _.chain(this.plans)
            .filter('primary')
            .filter(plan => _.includes(
                _.map(_.get(plan, 'courseUnitSelections'), 'courseUnitId'),
                _.get(this.enrolment, 'courseUnitId'),
            ))
            .value();

        // if only one primary plan with cu selection, then related study right should be selected for enrolment
        if (_.size(plansWithCourseUnitSelection) === 1) {
            return _.chain(_.concat(activeAndPassiveDegreeStudyRights, activeNonDegreeStudyRights))
                .find({ educationId: _.get(_.head(plansWithCourseUnitSelection), 'rootId') })
                .value();
        }

        // if study right cannot be resolved, return undefined
        return undefined;
    }

    showCourseUnitNotInPrimaryPlanNotification = (studyRights: StudyRight[], courseUnit: CourseUnit, courseUnitRealisation: CourseUnitRealisation) => {
        if (!this.control.value) {
            return false; // no study right selection
        }

        const studyRight = studyRights.find(sr => sr.id === this.control.value);
        const education = this.educations.find(e => e.id === studyRight?.educationId);
        if (education?.studySelectionRequirement !== 'UNRESTRICTED') {
            return false;
        }

        if (this.hasCourseUnitInPrimaryPlanRule()) {
            return !this.courseUnitInPrimaryPlan(studyRight) && !this.courseUnitInCourseUnitSelections(studyRight, courseUnit, courseUnitRealisation);
        }

        return false;
    };

    private hasCourseUnitInPrimaryPlanRule = (): boolean => {
        const requirementPersonRules = _.get(this.enrolmentCalculationConfig, 'requirementPersonRules');
        const orderingPersonRules = _.get(this.enrolmentCalculationConfig, 'orderingPersonRules');
        return _.some(requirementPersonRules, { type: 'CourseUnitInPrimaryPlan' }) ||
            _.some(orderingPersonRules, { type: 'CourseUnitInPrimaryPlan' });
    };

    private courseUnitInPrimaryPlan = (studyRight: StudyRight) => {
        // check if my primary plan include course unit with selected study right or is grade raise attempt
        const primaryPlanWithCourseUnit = _.chain(this.plans)
            .filter('primary')
            .filter(plan => _.includes(
                _.map(_.get(plan, 'courseUnitSelections'), 'courseUnitId'),
                _.get(this.enrolment, 'courseUnitId'),
            ) || this.isGradeRaiseAttempt(plan))
            .filter(plan => plan.rootId === studyRight?.educationId)
            .value();
        return _.size(primaryPlanWithCourseUnit) > 0;
    };

    private isGradeRaiseAttempt(plan: Plan): boolean {
        return _.includes(
            _.map(_.get(plan, 'courseUnitSelections'), (cus) => cus.gradeRaiseAttempt?.courseUnitId),
            _.get(this.enrolment, 'courseUnitId'));
    }

    private courseUnitInCourseUnitSelections = (studyRight: StudyRight, courseUnit: CourseUnit, courseUnitRealisation: CourseUnitRealisation) =>
        (studyRight.courseUnitSelections || [])
            .filter(selection => selection.courseUnitGroupId === courseUnit.groupId)
            .some(selection => dateUtils.dateTimeRangesOverlapNullsAsInfinity(
                selection.validityPeriod?.startDate,
                selection.validityPeriod?.endDate,
                courseUnitRealisation.activityPeriod?.startDate,
                courseUnitRealisation.activityPeriod?.endDate,
                'day',
            ));

    showCourseUnitNotInSelectionsNotification = (studyRights: StudyRight[], courseUnit: CourseUnit, courseUnitRealisation: CourseUnitRealisation): boolean => {
        if (!this.control.value) {
            return false; // no study right selection
        }

        const studyRight = studyRights.find(sr => sr.id === this.control.value);
        const education = this.educations.find(e => e.id === studyRight?.educationId);
        if (!['SELECTION_REQUIRED', 'ENROLMENT_RIGHT_REQUIRED'].includes(education?.studySelectionRequirement)) {
            return false;
        }

        if (this.hasCourseUnitInPrimaryPlanRule()) {
            return !this.courseUnitInCourseUnitSelections(studyRight, courseUnit, courseUnitRealisation);
        }

        return false;
    };
}
