import { Injectable } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { filter } from 'rxjs';
import { AuthService } from 'sis-common/auth/auth-service';
import { TokenStorageService } from 'sis-common/auth/token-storage.service';
import { IdleService, SessionTimeoutEvent } from 'sis-common/idle/idle-ng.service';
import { ModalService } from 'sis-common/modal/modal.service';
import { LocalStorageService } from 'sis-common/storage/local-storage.service';
import { SessionStorageService } from 'sis-common/storage/session-storage.service';

import { SessionTimeoutWarningModalComponent } from './session-timeout-warning-modal.component';

@Injectable({
    providedIn: 'root',
})
export class SessionTimeoutWarningService {

    modalTimer: null | ReturnType<typeof setTimeout> = null;
    modalRef: NgbModalRef;
    countdownTimeInSeconds = 300;

    constructor(private modalService: ModalService,
                private idleService: IdleService,
                private localStorageService: LocalStorageService,
                private sessionStorageService: SessionStorageService,
                private authService: AuthService,
                private tokenStorageService: TokenStorageService) {
        this.registerUserLoginEvents();
        this.registerIdleEvent();
        this.registerSessionStatusChangeEvents();
    }

    // Clears timer when user returns from idle. Starts timer when user goes idle.
    registerIdleEvent() {
        this.idleService.getSessionTimeoutEvent().subscribe((evt: SessionTimeoutEvent) => {
            if (!evt) {
                return;
            }
            if (evt.start) {
                this.startExpiryTimer();
            } else if (evt.stop) {
                this.clearTimer();
            }
        });
    }

    // Dismiss session timeout modal on other tabs
    registerUserLoginEvents() {
        this.authService.userLoggedIn$.pipe(filter((loggedIn) => loggedIn)).subscribe(() => this.localStorageService.setItem('sessionStatus', 'continued'));
    }

    // If the session status is continued on another tab, dismiss the session time modal on current tab
    registerSessionStatusChangeEvents() {
        if (window.addEventListener) {
            // sonar S2819 is a false positive, fixed in Sonarqube 9.X, see:
            // https://github.com/SonarSource/SonarJS/issues/2666
            window.addEventListener('storage', (event) => { // NOSONAR
                if (event.key === 'sessionStatus' && event.newValue === 'continued' && this.idleService.getLoginStatus()) {
                    this.extendSession();
                }
            });
        }
    }

    startExpiryTimer() {
        const sessionTimeoutAt = this.sessionStorageService.getItem('session_timeout_at');

        // In local environments where shibboleth headers are not provided which means there is no sessionTimeoutAt unless using ModHeaders
        if (sessionTimeoutAt) {
            const parsedSessionTimeoutAtInSeconds = parseInt(sessionTimeoutAt, 10);
            const currentTimeInSeconds = Math.floor(new Date().getTime() / 1000);
            const timeoutAdjustedInMilliseconds = (parsedSessionTimeoutAtInSeconds - currentTimeInSeconds - this.countdownTimeInSeconds) * 1000;

            this.modalTimer = setTimeout(() => {
                this.idleService.toggleIdleEvents(); // disable idle events when modal is visible
                this.localStorageService.removeItem('sessionStatus'); // listen for updates in case the modal is closed/new login on another tab
                this.clearTimer();
                this.initModal();
            }, timeoutAdjustedInMilliseconds);
        }
    }

    initModal() {
        this.modalRef = this.modalService.open(SessionTimeoutWarningModalComponent, { countdownTimeInSeconds: this.countdownTimeInSeconds, assertive: true }, { keyboard: false });
        this.modalRef.result.then((byUser: boolean) => {
            this.modalClosed(byUser);
        }, () => {
            this.logout();
        });
    }

    extendSession() {
        clearTimeout(this.modalTimer);
        this.modalRef.close(false);
    }

    modalClosed(byUser: boolean) {
        // closes the modal and timers. if the user clicked the modal, also propagates the closing event to other open tabs
        if (byUser) {
            this.localStorageService.setItem('sessionStatus', 'continued');
        }
        this.idleService.toggleIdleEvents(); // enable idle events once the modal has been dismissed
        this.idleService.poll();
    }

    clearTimer() {
        clearTimeout(this.modalTimer);
        // setting to null helps with testing, easier to know if it's been cleared
        this.modalTimer = null;
    }

    logout() {
        const logoutReason = 'sessionTimeout';
        this.authService.clear();
        this.authService.goToLoginPage(logoutReason);
    }

}
