import { Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { Option } from '../dropdown-select/dropdown-select.component';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-dropdown-select-button',
    templateUrl: './dropdown-select-button.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class DropdownSelectButtonComponent {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.select.dropdownSelectButton.downgraded',
        directiveName: 'sisDropdownSelectButton',
    };

    @Input() buttonClasses: string;
    @Input() buttonText: string;
    @Input() options: Option[];
    @Input() disabled: boolean;
    @Input() dropdownMenuRight: boolean;

    @ViewChild('dropdown', { static: true }) dropdown: NgbDropdown;

    @Output() select = new EventEmitter<any>();

    onSelect(value: any): void {
        this.select.emit(value);
        this.dropdown.close();
    }
}
