import { Component, Input, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'sis-radio-button',
    templateUrl: './radio-button.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class RadioButtonComponent {

    @Input() control: FormControl;
    @Input() disabled?: boolean;
    @Input() required?: boolean;
    @Input() inline?: boolean;
    @Input() label: string;
    @Input() name?: string;
    @Input() value: any;
    /** Additional info shown below the radio button label. Accepts a translation key. */
    @Input() guidance?: string;
    @Input() id?: string;
    /**
     * Optional `aria-describedby` value to set on the `input` element.
     */
    @Input() ariaDescribedBy?: string;
}
