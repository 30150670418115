<ng-container *transloco="let t">
  <sis-common-attainment-details [attainment]="attainment" [secondaryAttainments]="secondaryAttainments">
    <ng-template *ngIf="expirationImminent" #expirationNotification>
      <sis-attainment-expiration-notification [attainment]="attainment"></sis-attainment-expiration-notification>
    </ng-template>
    <ng-template *ngIf="attainment.rdiCredits ||
    (attainment?.assessmentItemId | assessmentItem | async)?.rdiCreditsEnabled === 'ENABLED'" #rdiCredits>
      <div class="form-group-mimic" role="listItem">
        <dt>{{ t('ATTAINMENTS.ATTAINMENT_DETAILS.RDI_CREDITS') }}</dt>
        <dd>{{attainment?.rdiCredits ?? '-'}}<dd>
      </div>
    </ng-template>
    <ng-template #registrationDetails>
      <div class="form-group-mimic" role="listitem">
        <dt>{{ t('ATTAINMENTS.ATTAINMENT_DETAILS.REGISTRATION_DATE') }}</dt>
        <dd>{{ attainment.registrationDate | localDateFormat }}</dd>
      </div>
    </ng-template>

    <ng-template #assessmentDetails>
      <h3>{{ t('ATTAINMENTS.ATTAINMENT_DETAILS.HEADINGS.ASSESSMENT_DETAILS') }}</h3>
      <ng-container *ngIf="{ studentAssessmentInfo: $studentAssessmentInfoForCur | async } as data">
        <dl>
          <div class="form-group-mimic full-width"
               role="listitem">
            <dt>{{ t('ATTAINMENTS.ATTAINMENT_DETAILS.ASSESSMENT_CRITERIA') }}</dt>
            <dd>{{ data.studentAssessmentInfo?.finalEvaluation?.assessmentCriteriaText ?? '-' }}</dd>
          </div>

          <div class="form-group-mimic full-width"
               role="listitem">
            <dt>{{ t('ATTAINMENTS.ATTAINMENT_DETAILS.FEEDBACK_SESSION') }}</dt>
            <dd *ngIf="data.studentAssessmentInfo?.finalEvaluation?.feedbackSession as feedbackSession; else emptyField">
              {{ feedbackSession.dateTime | localDateTimeFormat}}, {{ feedbackSession.location }}
            </dd>
          </div>

          <div class="form-group-mimic full-width"
               role="listitem">
            <dt>{{ t('ATTAINMENTS.ATTAINMENT_DETAILS.VERBAL_FEEDBACK') }}</dt>
            <dd>{{ data.studentAssessmentInfo?.assessment?.verbalFeedback ?? '-' }}</dd>
          </div>
        </dl>
      </ng-container>
    </ng-template>


  </sis-common-attainment-details>
</ng-container>

<ng-template #emptyField>
  <dd> - </dd>
</ng-template>

