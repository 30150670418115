<ng-container *transloco="let t">
  <button class="event-button" [class]="viewType === 'timeGridWeek' ? 'time-grid-week' : 'day-grid-month'" (click)="openEventSummaryModal()">
    <ng-container *ngIf="viewType === 'timeGridWeek'; then timeGridWeek; else dayGridMonth"></ng-container>
  </button>
</ng-container>

<ng-template #timeGridWeek>
  <span class="event-info-row time-grid-week">{{event.start | localTimeFormat}}–{{event.end | localTimeFormat}}</span>
  <span class="event-info-row time-grid-week">{{event.extendedProps.eventTitle}}</span>
</ng-template>

<ng-template #dayGridMonth>
  <span class="event-info-row day-grid-month">{{event.extendedProps.eventTitle}}</span>
</ng-template>
