/**
 * When placing an unplanned course unit into a plan, sets a target states to modules
 * to indicate whether the course unit can be placed into that module. Or whether the
 * course unit can be placed into a descendant of that module, so that module should
 * be epxanded in the tree view.
 */
'use strict';

(function () {
  angular.module('student.common.context.plan-context').factory('targetStates', targetStatesFactory);
  function targetStatesFactory() {
    return {
      get: function (planContext, modules, source) {
        if (_.isArray(source)) {
          return courseUnitList(planContext, modules, source);
        }
        return courseUnit(planContext, modules, source);
      },
      getCustomCourseUnitAttainmentTargets: function (planContext, modules) {
        return customCourseUnitAttainmentTargets(planContext, modules);
      }
    };
  }
  function courseUnit(planContext, modules, courseUnit) {
    var ret = {};
    _.forEach(modules, function (mod) {
      ret[mod.id] = {
        descendant_is_target: false,
        is_target: false
      };
    });
    _.forEach(modules, function (mod) {
      if (mod.findMatchingCourseUnitRule(courseUnit) != null && !planContext.isCourseUnitSelectionDisabled(courseUnit, mod)) {
        ret[mod.id].is_target = true;
        _.forEach(mod.ancestors(), function (ancestor) {
          ret[ancestor.id].descendant_is_target = true;
        });
      }
    });
    return ret;
  }
  function customCourseUnitAttainmentTargets(planContext, modules) {
    var ret = {};
    _.forEach(modules, function (mod) {
      ret[mod.id] = {
        descendant_is_target: false,
        is_target: false
      };
    });
    _.forEach(modules, function (mod) {
      if (!!mod.anyCourseUnitRule && !planContext.isCustomCourseUnitAttainmentSelectionDisabled(mod)) {
        ret[mod.id].is_target = true;
        _.forEach(mod.ancestors(), function (ancestor) {
          ret[ancestor.id].descendant_is_target = true;
        });
      }
    });
    return ret;
  }
  function courseUnitList(planContext, modules, courseUnits) {
    var allTargets = [];
    var allFalsifiedTargetStates = {};
    var trueModuleTargetStates = {};
    // get all targetStates
    _.each(courseUnits, function (cu) {
      allTargets.push(courseUnit(planContext, modules, cu));
    });
    // format all states to false
    _.each(allTargets, function (obj) {
      _.each(Object.keys(obj), function (key) {
        allFalsifiedTargetStates[key] = {
          is_target: false,
          descendant_is_target: false
        };
      });
    });

    // merge all true marked objects
    _.each(allTargets, function (obj) {
      _.merge(trueModuleTargetStates, getByKey(obj, 'is_target'), getByKey(obj, 'descendant_is_target'));
    });
    function getByKey(obj, keyName) {
      var picked = _.pickBy(obj, function (n) {
        return n[keyName] === true;
      });
      _.each(Object.keys(picked), function (key) {
        picked[key] = _.pick(picked[key], keyName);
      });
      return picked;
    }
    return _.merge(allFalsifiedTargetStates, trueModuleTargetStates);
  }
})();