import { Inject, Injectable } from '@angular/core';
import { ValidatablePlan } from 'common-typescript';
import {
    Attainment,
    CurriculumPeriod,
    DegreeProgrammeAttainment,
    Module,
    ModuleAttainment,
    OtmId,
    Plan, StudyRight,
} from 'common-typescript/types';
import { from, Observable, switchMap, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { COMMON_PLAN_SELECTION_SERVICE, COMMON_PLAN_SERVICE } from 'sis-components/ajs-upgraded-modules';
import { CurriculumPeriodEntityService } from 'sis-components/service/curriculum-period-entity.service';
import { PlanEntityService } from 'sis-components/service/plan-entity.service';
import { RawPlanEditService } from 'sis-components/service/raw-plan-edit.service';
import { convertAJSPromiseToNative } from 'sis-components/util/utils';

import { UI_CONTEXT } from '../../../ajs-upgraded-modules';
import { PlanCopyService } from '../../service/plan-copy.service';

@Injectable({
    providedIn: 'root',
})
export class ModuleInfoService {

    constructor(@Inject(UI_CONTEXT) private uiContext: any,
                @Inject(COMMON_PLAN_SELECTION_SERVICE) private commonPlanSelectionService: any,
                @Inject(COMMON_PLAN_SERVICE) private commonPlanService: any,
                private planEntityService: PlanEntityService,
                private rawPlanEditService: RawPlanEditService,
                private planCopyService: PlanCopyService,
                private curriculumPeriodEntityService: CurriculumPeriodEntityService) { }

    /**
     * Version ottaminen käyttöön suunnitelmassa on mahdollista silloin:
     * - jos versio on kiinni opintosuunnitelmalle valitussa opetussuunnitelmakaudessa
     * - tai sitä uudemmassa kaudessa
     * - jos opiskelijalla on suoritettuna kyseinen versio.
     *
     * @param moduleVersions - The Module versions
     * @param attainments - list of valid student attainments
     * @returns Compatible module id list
     */
    getModuleVersionCompatibility(moduleVersions: Module[], attainments: Attainment[]): Observable<OtmId[]> {
        const compatibleModuleIds = new Set<OtmId>();
        const attainedModuleIds = new Set((attainments.filter(
            att => att.type === 'DegreeProgrammeAttainment' || att.type === 'ModuleAttainment',
        ) as (DegreeProgrammeAttainment | ModuleAttainment)[])
            .map(att => att.moduleId));

        const planCurriculumPeriodId = this.uiContext.planContext.curriculumPeriodId;
        const moduleCurriculumPeriodIds = new Set(moduleVersions.flatMap(moduleVersion => moduleVersion.curriculumPeriodIds));
        return this.curriculumPeriodEntityService.getByIds([...new Set([planCurriculumPeriodId, ...moduleCurriculumPeriodIds])]).pipe(
            map((curriculumPeriods) => {
                const curriculumPeriodsById = new Map<OtmId, CurriculumPeriod>();
                for (const curriculumPeriod of curriculumPeriods) {
                    curriculumPeriodsById.set(curriculumPeriod.id, curriculumPeriod);
                }
                for (const moduleVersion of moduleVersions) {
                    // Add attained module version ids to the set
                    if (attainedModuleIds.has(moduleVersion.id)) {
                        compatibleModuleIds.add(moduleVersion.id);
                    }
                    // Add module version if some curriculum period matches the plan curriculum period.
                    if (moduleVersion.curriculumPeriodIds.some(curriculumPeriodId => curriculumPeriodId === planCurriculumPeriodId)) {
                        compatibleModuleIds.add(moduleVersion.id);
                    }
                    // Add module version if it has some curriculum period that is after the plan curriculum period
                    if (moduleVersion.curriculumPeriodIds.some(curriculumPeriodId => {
                        if (!curriculumPeriodsById.has(curriculumPeriodId) || !curriculumPeriodsById.has(planCurriculumPeriodId)) {
                            return false;
                        }
                        const curriculumPeriodStartDate = new Date(curriculumPeriodsById.get(curriculumPeriodId).activePeriod.startDate);
                        const planCurriculumPeriodStartDate = new Date(curriculumPeriodsById.get(planCurriculumPeriodId).activePeriod.startDate);
                        return curriculumPeriodStartDate > planCurriculumPeriodStartDate;
                    })) {
                        compatibleModuleIds.add(moduleVersion.id);
                    }
                }
                return [...compatibleModuleIds];
            }),
        );
    }

    copyPlanAndReplaceModule(oldModule: Module, newModule: Module): Observable<Plan> {
        if (!this.uiContext.hasActivePlan()) {
            return throwError(() => 'No active plan');
        }
        return from(convertAJSPromiseToNative(this.uiContext.planContext.toPlan()))
            .pipe(switchMap((plan: Plan) => {
                const newPlan = this.planCopyService.copyPlanObject(plan);
                this.rawPlanEditService.removeModuleRecursively(oldModule.id, newPlan);
                const studyRight: StudyRight = this.uiContext.planContext?.studyRight;
                const oldModuleParentModule = this.uiContext.planContext.validatablePlan.getParentModuleOrCustomModuleAttainmentForModule(oldModule);
                return from(convertAJSPromiseToNative(this.commonPlanService.getValidatablePlan(newPlan, false, false)))
                    .pipe(switchMap((validatablePlan: ValidatablePlan) =>
                        from(convertAJSPromiseToNative(this.commonPlanSelectionService.selectModule(newModule, oldModuleParentModule, newPlan, validatablePlan, studyRight)))
                            .pipe(switchMap(() => this.planEntityService.createMyPlan(newPlan)))));
            }));
    }

    replaceModule(oldModule: Module, newModule: Module): Observable<Module> {
        const oldModuleParentModule = this.uiContext.planContext.validatablePlan.getParentModuleOrCustomModuleAttainmentForModule(oldModule);
        const studyRight = this.uiContext.planContext?.studyRight;

        return from(convertAJSPromiseToNative(this.uiContext.planContext.forceRemoveModule(oldModule, oldModuleParentModule)))
            .pipe(switchMap(() => from(convertAJSPromiseToNative(this.uiContext.planContext.selectModule(
                oldModuleParentModule,
                newModule,
                this.uiContext.planContext.validatablePlan.plan,
                this.uiContext.planContext.validatablePlan,
                studyRight,
            )))),
                  map(() => this.uiContext.planContext.getModule(newModule.id)));
    }
}
