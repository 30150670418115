import { Pipe, PipeTransform } from '@angular/core';
import { LocalizedString, OtmId } from 'common-typescript/types';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { AppErrorHandler } from '../error-handler/app-error-handler';
import { EducationEntityService } from '../service/education-entity.service';
import { StudyRightEntityService } from '../service/study-right-entity.service';

@Pipe({ name: 'studyRightName' })
export class StudyRightNamePipe implements PipeTransform {
    constructor(
        private appErrorHandler: AppErrorHandler,
        private studyRightEntityService: StudyRightEntityService,
        private educationEntityService: EducationEntityService,
    ) {
    }

    transform(studyRightId: OtmId): Observable<LocalizedString> {
        if (!studyRightId) {
            return null;
        }
        return this.studyRightEntityService.getById(studyRightId)
            .pipe(
                mergeMap(studyRight => this.educationEntityService.getById(studyRight.educationId)),
                map(education => education.name),
                this.appErrorHandler.defaultErrorHandler(),
            );
    }
}
