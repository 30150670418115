import angular from 'angular';
import { ModalService } from 'sis-common/modal/modal.service.ts';
import { StudyRightStatusBadgeComponent } from 'sis-components/studyRight/study-right-status-badge/study-right-status-badge.component.ts';
import { CommonCodeService } from 'sis-components/service/common-code.service.ts';
import { localizedUrnNameDisplayModule } from 'sis-components/codes/localizedUrnNameDisplay.component';
import { publicPersonServiceModule } from 'sis-components/service/publicPerson.service';
import { courseUnitRealisationServiceModule } from 'sis-components/service/courseUnitRealisation.service';
import { commonStudentAttainmentServiceModule } from 'sis-components/service/studentAttainment.service';
import { codePanelModule } from 'sis-components/applications/codePanel.dir';
import { ProfileSecondaryNavigationComponent } from './profile-secondary-navigation/profile-secondary-navigation.component.ts';
import { EnrolmentRightStudentStudyRightComponent } from './studyRights/enrolment-right-student-study-right/enrolment-right-student-study-right.component.ts';
angular.module('student.profile', ['sis-common.auth', 'sis-common.form', ModalService.downgrade.moduleName, 'sis-components.attainment.tree', 'sis-components.date.localDateFormatFilter', 'sis-components.string.localizedFilter', 'student.profile.applications', 'sis-components.date.localDateRangeFilter', 'student.common.service.moduleService', 'sis-components.service.jsDataCacheHelper', 'sis-components.constant.warningLimits', CommonCodeService.downgrade.moduleName, 'sis-components.service.studyTermService', 'sis-components.service.educationService', 'sis-components.educationStructurePreview', 'sis-components.service.moduleService', 'sis-components.service.studentAttainmentService', 'sis-components.service.studyRightService', 'sis-components.service.courseUnitService', 'sis-common.errorhandler.errorService', codePanelModule, 'sis-components.service.personNameService', 'sis-components.service.organisationService', EnrolmentRightStudentStudyRightComponent.downgrade.moduleName, ProfileSecondaryNavigationComponent.downgrade.moduleName, 'sis-common.university', StudyRightStatusBadgeComponent.downgrade.moduleName, publicPersonServiceModule, courseUnitRealisationServiceModule, localizedUrnNameDisplayModule,
// commonStudentAttainmentService is used as upgraded version in ng components
commonStudentAttainmentServiceModule]).constant('CodeBookUrn', {
  country: 'urn:code:country'
});