import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    AbstractPersonRule,
    AttainedCreditRangeRule,
    Code, CourseUnitRealisation,
    Education,
    EnrolmentForCourseUnitRealisationRule,
    PersonGroupDetails,
    PersonGroupMembershipRule,
} from 'common-typescript/types';
import _ from 'lodash';
import { combineLatest, from, map, Observable, of } from 'rxjs';
import { LocaleService } from 'sis-common/l10n/locale.service';
import {
    CourseUnitRealisationNameService,
} from 'sis-common/name/course-unit-realisation-name.service';

import { PersonRuleTypes, PersonRuleTypeTranslationKeys } from '../constant/personRuleTypes';
import { AppErrorHandler } from '../error-handler/app-error-handler';
import { CommonCodeService } from '../service/common-code.service';
import { CourseUnitRealisationEntityService } from '../service/course-unit-realisation-entity.service';
import { EducationEntityService } from '../service/education-entity.service';
import { PersonGroupEntityService } from '../service/person-group-entity.service';
import { convertAJSPromiseToNative } from '../util/utils';

@Pipe({
    name: 'personRequirementRuleName',
})
export class PersonRequirementRuleNamePipe implements PipeTransform {
    constructor(private appErrorHandler: AppErrorHandler,
                private translate: TranslateService,
                private localeService: LocaleService,
                private personGroupEntityService: PersonGroupEntityService,
                private courseUnitRealisationEntityService: CourseUnitRealisationEntityService,
                private educationEntityService: EducationEntityService,
                private nameService: CourseUnitRealisationNameService,
                private commonCodeService: CommonCodeService,
    ) {}

    transform(rule: AbstractPersonRule): Observable<string> {
        if (!Object.keys(PersonRuleTypeTranslationKeys).includes(rule.type)) return;

        const ruleType = rule.type as keyof typeof PersonRuleTypeTranslationKeys;
        const translationKey = PersonRuleTypeTranslationKeys[ruleType];

        switch (ruleType) {
            case PersonRuleTypes.PERSON_GROUP_MEMBERSHIP:
                return this.getPersonGroupInfoText(rule as PersonGroupMembershipRule);
            case PersonRuleTypes.ENROLMENT_FOR_COURSE_UNIT_REALISATION:
                return this.getEnrolmentForCurRuleInfoText(rule as EnrolmentForCourseUnitRealisationRule);
            case PersonRuleTypes.ATTAINED_CREDIT_RANGE:
                return this.getAttainedCreditRangeInfoText(rule as AttainedCreditRangeRule);
            default:
                return of(this.translate.instant(translationKey));
        }
    }

    private getPersonGroupInfoText(personGroupRule: PersonGroupMembershipRule): Observable<string> {
        return this.personGroupEntityService.getDetailsByIds(personGroupRule?.personGroupIds).pipe(
            this.appErrorHandler.defaultErrorHandler(),
            map((details: PersonGroupDetails[]) => details?.map(detail => this.localeService.localize(detail.name))),
            map(groupNames => `${this.translate.instant(PersonRuleTypeTranslationKeys.PersonGroupMembership)}: ${groupNames?.join(', ')}`),
        );
    }

    private getEnrolmentForCurRuleInfoText(enrolmentForCurRule: EnrolmentForCourseUnitRealisationRule): Observable<string> {
        return this.courseUnitRealisationEntityService.getByIds(enrolmentForCurRule?.courseUnitRealisationIds).pipe(
            this.appErrorHandler.defaultErrorHandler(),
            map(curs => curs.map((cur: CourseUnitRealisation) => {
                const localizedFullName = this.localeService.localize(this.nameService.generateFullName(cur));
                if (cur.flowState === 'PUBLISHED') {
                    return localizedFullName;
                }
                const key = `FLOW_STATE.${cur.flowState}`;
                return `${localizedFullName} (${this.translate.instant(key)})`;
            })),
            map(curNames => `${this.translate.instant(PersonRuleTypeTranslationKeys.EnrolmentForCourseUnitRealisation)}:\n${curNames.join('\n')}`),
        );
    }

    private getAttainedCreditRangeInfoText(attainedCreditsRule: AttainedCreditRangeRule) {
        const educations$: Observable<Education[]> = this.educationEntityService.getByIds(attainedCreditsRule.educationIds);
        const code$: Observable<Code> = from(convertAJSPromiseToNative(this.commonCodeService.getCode(attainedCreditsRule.degreeProgramTypeUrn)));

        return combineLatest([educations$, code$]).pipe(
            map(([educationResults, codeResult]) => {
                let ruleName = this.translate.instant(PersonRuleTypeTranslationKeys[attainedCreditsRule.type]);

                ruleName += ': ';

                if (!_.has(attainedCreditsRule, 'creditRange.max') || _.isNil(attainedCreditsRule.creditRange.max)) {
                    ruleName += this.translate.instant('PERSON_RULE.ATTAINED_CREDIT_RANGE.CREDITS_WITHOUT_MAX', {
                        min: attainedCreditsRule.creditRange.min,
                    });
                } else if (attainedCreditsRule.creditRange.min === 0) {
                    ruleName += this.translate.instant('PERSON_RULE.ATTAINED_CREDIT_RANGE.CREDITS_WITHOUT_MIN', {
                        max: attainedCreditsRule.creditRange.max,
                    });
                } else {
                    ruleName += this.translate.instant('PERSON_RULE.ATTAINED_CREDIT_RANGE.CREDITS_WITH_MAX', {
                        max: attainedCreditsRule.creditRange.max,
                        min: attainedCreditsRule.creditRange.min,
                    });
                }

                ruleName += ' \n';

                if (_.isNil(attainedCreditsRule.educationIds) || _.isEmpty(attainedCreditsRule.educationIds)) {
                    ruleName += this.translate.instant('PERSON_RULE.ATTAINED_CREDIT_RANGE.ALL_EDUCATIONS');
                } else {
                    const educations: string[] = [];
                    const educationsById = _.keyBy(educationResults, 'id');

                    _.forEach(attainedCreditsRule.educationIds, (id) => {
                        const education = educationsById[id];
                        if (!_.isNil(education)) {
                            educations.push(
                                this.localeService.localize(education.name),
                            );
                        }
                    });

                    ruleName += this.translate.instant('PERSON_RULE.ATTAINED_CREDIT_RANGE.EDUCATIONS', {
                        educations: educations.join(', '),
                    });
                }

                ruleName += ' \n';

                if (_.isNil(attainedCreditsRule.degreeProgramTypeUrn)) {
                    ruleName += this.translate.instant('PERSON_RULE.ATTAINED_CREDIT_RANGE.ALL_DEGREE_PROGRAM_TYPES');
                } else {
                    const degreeProgramTypeName = codeResult?.name;
                    ruleName += this.translate.instant('PERSON_RULE.ATTAINED_CREDIT_RANGE.DEGREE_PROGRAM_TYPE', {
                        degreeProgramTypeName: this.localeService.localize(degreeProgramTypeName),
                    });
                }

                return ruleName;
            }),
        );

    }
}
