import { Directive, ElementRef, Injector, Input, Output } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

import { CourseUnitInfoValues } from './courseUnitInfoModal.component';

/**
 * @deprecated
 */
// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'course-unit-info-modal' })
export class CourseUnitInfoModalDirective extends UpgradeComponent {

    @Output() dismiss: () => void;
    @Input() resolve: CourseUnitInfoValues;

    constructor(elementRef: ElementRef<any>, injector: Injector) {
        super('courseUnitInfoModal', elementRef, injector);
    }
}
