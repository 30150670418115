"use strict";

(function () {
  organisationSelectorService.$inject = ["$http", "$q", "$log"];
  angular.module("sis-common.organisationSelector.organisationSelectorService", []).factory("organisationSelectorService", organisationSelectorService);

  /**
   * @ngInject
   */
  function organisationSelectorService($http, $q, $log) {
    var baseUri = "/kori/api/organisations/";
    var orgsById = undefined;
    var orgHierarchies = undefined;
    var cachedRootOrgIds = undefined;
    var api = {
      get: function (id) {
        if (orgsById && orgsById[id]) {
          return $q.when(orgsById[id]);
        } else {
          return $http.get(baseUri + id).then(function (result) {
            return result.data;
          });
        }
      },
      // Returns a map of id -> organisation of all organisations valid NOW.
      all: function (rootOrgIds) {
        return getAll(rootOrgIds);
      },
      roots: function (rootOrgIds) {
        return getHierarchies(rootOrgIds);
      },
      // Populates organisations into an array of objects. Supports also objects having arrays of organisation ids.
      // The indicated field should either contain an organisation id, or an array of organisation ids on each object.
      populate: function (objects, fieldName) {
        return getAll().then(function () {
          return populate(objects, fieldName);
        });
      },
      // Finds from allOrgs (a map id->org) the first ancestor of the indicated organisation that has an ID in the acceptedParentIds array
      findParentId: findParentId
    };
    return api;
    function getAll(rootOrgIds) {
      if (orgsById && cacheValid(rootOrgIds)) {
        return $q.when(_.clone(orgsById));
      } else {
        cachedRootOrgIds = rootOrgIds;
        return $http.get(baseUri, {
          params: {
            universityOrgId: rootOrgIds
          }
        }).then(function (result) {
          orgsById = _.keyBy(result.data, "id");
          return orgsById;
        });
      }
    }
    function findParentId(startOrgId, acceptedParentIds, allOrgs) {
      if (_.includes(acceptedParentIds, startOrgId)) {
        return startOrgId;
      } else {
        var startOrg = allOrgs[startOrgId];
        if (startOrg && startOrg.parentId) {
          return findParentId(startOrg.parentId, acceptedParentIds, allOrgs);
        }
        return undefined;
      }
    }
    function populate(objects, fieldName) {
      _.forEach(objects, function (object) {
        if (object && object[fieldName]) {
          var thing = object[fieldName];
          if (thing) {
            if (_.isArray(thing)) {
              var newArray = [];
              _.forEach(thing, function (id) {
                if (id) {
                  if (typeof id === 'object') {
                    newArray.push(orgsById[id.id]);
                  } else if (orgsById[id]) {
                    newArray.push(orgsById[id]);
                  } else {
                    newArray.push(id);
                    reportMissing(1, object, id);
                  }
                } else {
                  newArray.push(id);
                  reportMissing(2, object, id);
                }
              });
              object[fieldName] = newArray;
            } else if (orgsById[thing]) {
              object[fieldName] = orgsById[thing];
            } else {
              reportMissing(3, object, thing);
            }
          }
        }
      });
      return objects;
    }
    function getHierarchies(rootOrgIds) {
      if (orgHierarchies && cacheValid(rootOrgIds)) {
        return $q.when(_.clone(orgHierarchies));
      } else {
        return getAll(rootOrgIds).then(function (orgsById) {
          var roots = [];
          _.forEach(_.keys(orgsById), function (orgId) {
            var org = orgsById[orgId];
            if (!org.parentId) {
              roots.push(org);
            } else {
              var parent = orgsById[org.parentId];
              if (parent) {
                if (!parent.children) {
                  parent.children = [];
                }
                parent.children.push(org);
              } else {
                reportMissing(4, org, org.parentId);
              }
            }
          });
          orgHierarchies = roots;
          return _.clone(orgHierarchies);
        });
      }
    }
    function reportMissing(errorType, object, id) {
      $log.warn('The following object referred to a non-existing organisation by id.', 'id:', id, 'object:', object, 'errorType:', errorType);
    }
    function cacheValid(rootOrgIds) {
      if (!_.isArray(cachedRootOrgIds) && _.isArray(rootOrgIds)) {
        return false;
      } else if (_.isArray(cachedRootOrgIds) && _.isArray(rootOrgIds)) {
        return _.isEqual(cachedRootOrgIds.sort(), rootOrgIds.sort());
      }
      return true;
    }
  }
})();