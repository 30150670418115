/**
 * Temporal way to map relations to module. In future JSData models should replace this but not until models are moved
 * to sis-components and making requests in `run´ blocks are removed.
 */
angular.module('student.common.service.urnService', []).constant('urnConfig', {
  path: '/kori/api/cached/codebooks/',
  endpoint: {
    educationClassification: 'urn:code:education-classification',
    officialLanguage: 'urn:code:official-language',
    moduleResponsibilityInfoType: 'urn:code:module-responsibility-info-type',
    studyField: 'urn:code:study-field',
    studyLevel: 'urn:code:study-level',
    degreeProgramType: 'urn:code:degree-program-type',
    organisationRole: 'urn:code:organisation-role',
    degreeTitle: 'urn:code:degree-title'
  }
}).factory('urnService', ["$http", "$q", "$log", "urnConfig", function ($http, $q, $log, urnConfig) {
  var promises = {};
  var values = {};
  return {
    load: function (urnType) {
      if (_.has(promises, urnType)) {
        return promises[urnType];
      }
      var endpoint = getEndpoint(urnType);
      if (!endpoint) {
        $log.error('no urn endpoint found for ' + urnType);
        return $q.reject('no urn endpoint found for ' + urnType);
      }
      promises[urnType] = $http.get(endpoint).then(function (response) {
        values[urnType] = response.data;
        return values[urnType];
      }, function (err) {
        $log.error(err);
        promises[urnType] = undefined;
        return $q.reject(err);
      });
      return promises[urnType];
    },
    get: function (urnType, code) {
      var val = _.get(values, urnType + '.' + code, false);
      if (!val) {
        $log.warn('urnService.get: urn not found ', urnType, code);
        return {};
      }
      return val;
    }
  };
  function getEndpoint(urnType) {
    var code = _.get(urnConfig, 'endpoint.' + urnType, false);
    return code === false ? false : urnConfig.path + code;
  }
}]);