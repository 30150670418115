import { Injectable } from '@angular/core';
import { ModalService } from 'sis-common/modal/modal.service';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

import { FileUploadInfoModalComponent } from './file-upload-info-modal.component';

@StaticMembers<DowngradedService>()
@Injectable({
    providedIn: 'root',
})
export class FileUploadInfoModalService {

    static downgrade: ServiceDowngradeMappings = {
        moduleName: 'sis-components.service.FileUploadInfoModalService',
        serviceName: 'fileUploadInfoModalService',
    };

    constructor(private modalService: ModalService) {}

    confirm(values: any) {
        return this.modalService.open(FileUploadInfoModalComponent, values, { windowClass: 'file-upload-info-modal', size: 'sm' }).result;
    }
}
