import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UIRouterGlobals } from '@uirouter/core';
import { Subscription } from 'rxjs';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { NavUtilsService } from '../../topNav/nav-utils/nav-utils.service';
import { BreadcrumbItem, HeaderService } from '../header.service';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.downgraded.breadcrumbs',
        directiveName: 'sisBreadcrumbs',
    };

    breadcrumbs: BreadcrumbItem[] = [];
    homeUrl: string;

    private subscriptions: Subscription[] = [];

    constructor(navUtilsService: NavUtilsService,
                private headerService: HeaderService,
                private uiRouterGlobals: UIRouterGlobals) {
        this.homeUrl = navUtilsService.getHomeUrl();
    }

    ngOnInit(): void {
        this.subscriptions = [
            this.headerService.breadcrumbsChanged.subscribe(breadcrumbs => this.breadcrumbs = breadcrumbs),
        ];
    }

    ngOnDestroy(): void {
        this.subscriptions?.forEach(sub => sub.unsubscribe());
    }

    get showBreadcrumbs(): boolean {
        return !this.uiRouterGlobals.$current?.data?.headerWithoutBreadcrumbs;
    }

    get showHomeNode(): boolean {
        return this.uiRouterGlobals.$current?.data?.showHome === true;
    }

    get showLastNode(): boolean {
        return this.uiRouterGlobals.$current?.data?.hideLastNode === false;
    }
}
