import { Injectable } from '@angular/core';
import { StudyYear } from 'common-typescript/types';
import { BehaviorSubject, Observable } from 'rxjs';
import { shareReplay, switchMap } from 'rxjs/operators';

import { StudyYearsEntityService } from './study-years-entity.service';
import { UniversityService } from './university.service';

@Injectable({ providedIn: 'root' })
export class CurrentStudyYearService {
    /**
     * Emits the current study year,
     * or produces an error if not found.
     */
    readonly currentStudyYear$: Observable<StudyYear>;

    constructor(
        universityService: UniversityService,
        studyYearsEntityService: StudyYearsEntityService,
    ) {
        // The subject is just a trick to prevent the result from being cached forever.
        // It also delays running any actual logic before the first subscription.
        this.currentStudyYear$ = new BehaviorSubject(undefined).pipe(
            switchMap(() => studyYearsEntityService.getCurrentStudyYear(universityService.getCurrentUniversityOrgId())),
            shareReplay({ bufferSize: 1, refCount: true }),
        );
    }
}
