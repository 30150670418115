import { mergeMap, Observable, Subject, TapObserver } from 'rxjs';
import { share, tap } from 'rxjs/operators';
import { mono, Mono } from 'sis-common/mono/monoUtils';

/**
 * Configuration for {@link SisuSimpleDataLoader}. The callback functions and side effects are only executed when a new request
 * is sent or when a request completes. They are not executed when a new client subscribes to an outstanding request.
 */
export interface SimpleDataLoaderConfig<T> {
    /** A function that creates a new request to be sent. */
    requestCreator: () => Mono<T>;
    /** An optional function that is called before a new request is sent. */
    onBeforeRequest?: () => void;
    /** Allows defining side effects that are executed when a request completes, see {@link tap}. */
    onAfterRequest?: Partial<TapObserver<T>> | ((value: T) => void);
}

/**
 * A simplified version of {@link SisuDataLoader} which allows executing **unparameterized and repeatable** requests in a way
 * that only one request is active at a time. If multiple clients fetch the data at the same time, they will all be subscribed
 * to the same request. If there is no outstanding request when `load()` is called, a new request will be sent.
 *
 * Useful when the same set of data from an unparameterized API (e.g. all attainments for the current user) is needed in
 * several places in a single view, as this loader removes the need to orchestrate the data loading across all the components
 * that need it.
 *
 * @param T The type of the data returned by the request.
 */
export class SisuSimpleDataLoader<T> {

    private readonly requestInitiator = new Subject<void>();
    private readonly requests$: Observable<T>;

    constructor(config: SimpleDataLoaderConfig<T>) {
        if (!config?.requestCreator) {
            throw new Error('requestCreator is required');
        }
        this.requests$ = this.requestInitiator
            .pipe(
                mergeMap(() => {
                    config.onBeforeRequest?.();
                    return config.requestCreator().pipe(tap(config.onAfterRequest));
                }, 1),
                share(),
            );
    }

    load(): Mono<T> {
        return mono(new Observable<T>(observer => {
            this.requests$.subscribe(observer);
            this.requestInitiator.next();
        }));
    }
}
