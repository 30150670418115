(function () {
  angular.module('student.common.model.courseUnit', ['student.shared']).factory('courseUnitModel', courseUnitModel);
  courseUnitModel.$inject = ["$log", "Range"];
  function courseUnitModel($log, Range) {
    var courseUnitModel = {
      newCourseUnit: newCourseUnit,
      newCompletionMethod: newCompletionMethod,
      CourseUnit: CourseUnit,
      CompletionMethod: CompletionMethod
    };
    function newCourseUnit(data) {
      if (data) {
        return new CourseUnit(data);
      } else {
        return null;
      }
    }
    function newCompletionMethod(data) {
      if (data) {
        return new CompletionMethod(data);
      } else {
        return null;
      }
    }
    function CourseUnit(data) {
      var self = this;
      if (!data.id) {
        throw 'id required for CourseUnit';
      }
      if (!data.groupId) {
        throw 'groupId required for CourseUnit';
      }
      var defaults = {
        selectedParent: null,
        attainment: null,
        validationResults: null,
        completionMethodsById: {},
        completionMethodsArray: [],
        selectedCompletionMethod: null,
        selectedAssessmentItemsById: {},
        plannedPeriods: [],
        substitutedBy: {},
        // <id, CourseUnit>
        substituteFor: {},
        // <id, credits|undefined>

        _index: 0 // Set by ModuleContext in validation
      };
      _.assign(self, defaults, data);
      self.credits = data.credits ? new Range(data.credits) : Range.zero();
      self.completionMethodsArray = _.map(self.completionMethods, courseUnitModel.newCompletionMethod);
      self.completionMethodsById = _.keyBy(self.completionMethodsArray, 'localId');
    }
    CourseUnit.prototype.isSubstitute = function () {
      return !_.isEmpty(this.substituteFor);
    };
    CourseUnit.prototype.clearSelections = function () {
      var self = this;
      self.selectedCompletionMethod = null;
      self.selectedAssessmentItemsById = {};
      self.substitutedBy = {};
      self.substituteFor = {};
      self.plannedPeriods = [];
    };
    CourseUnit.prototype.clearSelectedCompletionMethod = function () {
      var self = this;
      self.selectedCompletionMethod = null;
      self.selectedAssessmentItemsById = {};
    };
    CourseUnit.prototype.selectCompletionMethod = function (completionMethodOrId) {
      var self = this;
      var id = completionMethodOrId && completionMethodOrId.localId || completionMethodOrId;
      self.selectedCompletionMethod = self.completionMethodsById[id];
      self.selectedAssessmentItemsById = {};
      return self.selectedCompletionMethod;
    };
    CourseUnit.prototype.addSelectedAssessmentItem = function (assessmentItem) {
      var self = this;
      if (self.selectedAssessmentItemsById[assessmentItem.id]) {
        if (self.selectedAssessmentItemsById[assessmentItem.id] === assessmentItem && assessmentItem.selectedParent === self) {
          return false;
        } else {
          self.selectedAssessmentItemsById[assessmentItem.id].detach();
        }
      }
      // Detach courseUnit from it's old parent
      assessmentItem.detach();
      self.selectedAssessmentItemsById[assessmentItem.id] = assessmentItem;
      assessmentItem.selectedParent = self;
      return true;
    };
    function findFirstMatchingSubstitution(substitutions, substitutedBy) {
      var substitutesByGroupId = _.keyBy(_.map(substitutedBy, function (courseUnit) {
        return {
          courseUnitId: courseUnit.id,
          courseUnitGroupId: courseUnit.groupId
        };
      }), 'courseUnitGroupId');
      var match = _.find(substitutions, function (substitution) {
        return substitutionMatches(substitution, _.keys(substitutesByGroupId));
      });
      if (match) {
        _.forEach(match, function (courseUnitSubstitution) {
          courseUnitSubstitution.courseUnitId = _.get(substitutesByGroupId[courseUnitSubstitution.courseUnitGroupId], 'courseUnitId');
        });
      }
      return match;
    }
    CourseUnit.prototype.substituteWith = function (substitutes) {
      var self = this;
      if (self.isSubstitute()) {
        $log.warn('Substitution in course unit which is a substitute already', self, substitutes);
      }
      self.detachSubstitutes();
      _.forEach(substitutes, function (substitute) {
        self.substitutedBy[substitute.id] = substitute;
      });
      var substitutions = findFirstMatchingSubstitution(self.substitutions, substitutes);
      if (substitutions) {
        _.forEach(substitutions, function (courseUnitSubstitution) {
          var substitutedBy = self.substitutedBy[courseUnitSubstitution.courseUnitId];
          substitutedBy.substituteFor[self.id] = courseUnitSubstitution.credits || 0;
        });
      } else {
        _.forEach(self.substitutedBy, function (substitute) {
          substitute.substituteFor[self.id] = null;
        });
      }
      self.plannedPeriods = [];
    };
    CourseUnit.prototype.detachSubstitutes = function () {
      var self = this;
      _.forEach(self.substitutedBy, function (substitute, id) {
        delete self.substitutedBy[id];
        delete substitute.substituteFor[self.id];
      });
    };
    CourseUnit.prototype.removeSelectedAssessmentItem = function (assessmentItem) {
      delete this.selectedAssessmentItemsById[assessmentItem.id];
      assessmentItem.detach();
    };
    CourseUnit.prototype.detach = function () {
      var self = this;
      self.clearSelectedCompletionMethod();
      if (self.selectedParent) {
        // Clear selectedParent before removing from parent which calls right back to this detach
        var parent = self.selectedParent;
        self.selectedParent = null;
        parent.removeSelectedCourseUnit(self);
      }

      // remove substitutions
      this.detachSubstitutes();
    };
    function substitutionMatches(substitution, substitutedBy) {
      var substitutionBy = _.map(substitution, 'courseUnitGroupId');
      if (_.size(substitutionBy) === _.size(substitutedBy)) {
        return _.every(substitution, function (courseUnitSubstitution) {
          return _.includes(substitutedBy, courseUnitSubstitution.courseUnitGroupId);
        });
      }
      return false;
    }
    function CompletionMethod(data) {
      var self = this;
      _.assign(self, data);
      if (data.require) {
        self.require = new Range(data.require);
      }
      self.typeOfRequire = data.typeOfRequire || 'ALL_SELECTED_REQUIRED';
      self.assessmentItemIds = _.keyBy(self.assessmentItemIds);
    }
    return courseUnitModel;
  }
})();