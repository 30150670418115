import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

/**
 * Renders a wizard footer with two (optional) buttons.
 */
@Component({
    selector: 'sis-wizard-step-buttons',
    templateUrl: './wizard-step-buttons.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class WizardStepButtonsComponent {

    @Input() backButtonTitle?: string;
    @Input() backButtonDisabled?: boolean = false;
    @Input() backButtonDisabledTooltip?: string;
    @Input() backButtonDisplay?: boolean = true;

    @Input() forwardButtonTitle?: string;
    @Input() forwardButtonDisabled?: boolean = false;
    @Input() forwardButtonDisabledTooltip?: string;
    @Input() forwardButtonDisplay?: boolean = true;

    @Output() back = new EventEmitter<void>();
    @Output() forward = new EventEmitter<void>();
}
