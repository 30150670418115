<hr class="sis-hr sis-mb-lg" aria-hidden="true">
<div *ngIf="!hideHeader" role="heading" [attr.aria-level]="level" class="fudis-heading__md">
  {{ 'PROFILE.MINOR_SELECTIONS.TITLE' | translate }}
</div>
<ng-content select="[minor-selections=notifications]"></ng-content>
<sis-description-list>
  <dl>
    <ng-template #listItem>
      <dt class="sis-label">{{ education.structure.phase1.name | localizedString }}</dt>
      <dd data-cy="phase-1-minor-selections">
        <ng-container [ngTemplateOutlet]="selectionList"
                      [ngTemplateOutletContext]="{selections:studyRight.phase1MinorSelections, phase: 1}">
        </ng-container>
      </dd>
    </ng-template>
    <ng-template #listItem *ngIf="education.structure.phase2">
      <dt class="sis-label">{{ education.structure.phase2.name | localizedString }}</dt>
      <dd data-cy="phase-2-minor-selections">
        <ng-container [ngTemplateOutlet]="selectionList"
                      [ngTemplateOutletContext]="{selections:studyRight.phase2MinorSelections, phase: 2}">
        </ng-container>
      </dd>
    </ng-template>
  </dl>
</sis-description-list>

<ng-template #selectionList let-selections="selections" let-phase="phase">
  <ul *ngIf="selections?.length" class="col-12 col-sm-10 col-md-9 col-xxl-8 no-list-style-type">
    <li *ngFor="let selection of selections; trackBy:localId; index as $index">
      <sis-minor-selection-module-box
        [moduleColorIndex]="$index + 1"
        [attainment]="attainmentsByModuleGroupId[selection.moduleGroupId]"
        [selection]="selection"
        [editMode]="editMode"
        data-cy="minor-selection-box"
        (removeModule)="removeSelection(selection.moduleGroupId, phase)">
      </sis-minor-selection-module-box>
    </li>
  </ul>
  <p *ngIf="!selections?.length" data-cy="minor-selections-not-exists" [class.sis-pb-xl]="editMode">
    {{ 'PROFILE.MINOR_SELECTIONS.NO_SELECTIONS' | translate }}
  </p>
  <sis-add-button *ngIf="editMode"
                  dashBox="true"
                  dataCy="add-minor-selection"
                  [label]="'PROFILE.MINOR_SELECTIONS.ADD_MINOR_SELECTION' | translate"
                  (clicked)="addSelection(phase)"></sis-add-button>
</ng-template>
