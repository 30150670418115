<fudis-dl [columns]="{ sm: 1, md: 2 }" [rowGap]="'xl'">
  <fudis-dl-item>
    <fudis-dt [contentText]="'STUDENT_APPLICATIONS.PRIOR_LEARNING.COMPETENCE_ORGANISATION' | translate"/>
    <fudis-dd [contentText]="this.priorCompetence.organisation"/>
  </fudis-dl-item>
  <fudis-dl-item>
    <fudis-dt [contentText]="'STUDENT_APPLICATIONS.PRIOR_LEARNING.COMPETENCE_ATTAINMENT_PERIOD' | translate"/>
    <fudis-dd [contentText]="this.priorCompetence.attainmentPeriod | localDateRange"/>
  </fudis-dl-item>
  <fudis-dl-item>
    <fudis-dt [contentText]="'STUDENT_APPLICATIONS.PRIOR_LEARNING.STUDIES_ATTAINMENT_LANGUAGE' | translate"/>
    <fudis-dd [contentText]="this.priorCompetence.attainmentLanguage | codeLocalizedName | async"/>
  </fudis-dl-item>
  <fudis-dl-item>
    <fudis-dt [contentText]="'STUDENT_APPLICATIONS.PRIOR_LEARNING.COMPETENCE_DESCRIPTION' | translate"/>
    <fudis-dd [contentText]="this.priorCompetence.description"/>
  </fudis-dl-item>
</fudis-dl>
