import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { OtmId, PersonGroup, PersonGroupDetails, PersonGroupDetailsForOwnGroup } from 'common-typescript/types';
import { Observable } from 'rxjs';

import { EntityService } from './entity.service';

const CONFIG = {
    ENDPOINTS: {
        baseUrl: '/ori/api',
        getPersonGroupsForStudent() {
            return `${this.baseUrl}/person-groups/for-student`;
        },
        ownPersonGroupDetails: '/ori/api/person-group-details/own',
        getDetailsByIds() {
            return `${this.baseUrl}/person-group-details`;
        },
    },
};

@Injectable({
    providedIn: 'root',
})

@NgEntityServiceConfig({
    baseUrl: CONFIG.ENDPOINTS.baseUrl,
    resourceName: 'person-groups',
})
export class PersonGroupEntityService extends EntityService<PersonGroupState> {

    constructor() {
        super(PersonGroupStore, PersonGroupQuery);
    }

    getOwnPersonGroupDetails(): Observable<PersonGroupDetailsForOwnGroup[]> {
        return this.getHttp().get<PersonGroupDetailsForOwnGroup[]>(CONFIG.ENDPOINTS.ownPersonGroupDetails);
    }

    getDetailsByIds(personGroupIds: OtmId[], bypassStore: boolean = false, config = {}): Observable<PersonGroupDetails[]> {
        const options = { params: { id: personGroupIds.toString() } };
        return this.getHttp().get<PersonGroupDetails[]>(CONFIG.ENDPOINTS.getDetailsByIds(), options);
    }

    getPersonGroupsForStudent(studentId: OtmId): Observable<PersonGroup[]> {
        const url = `${CONFIG.ENDPOINTS.getPersonGroupsForStudent()}/${studentId}`;
        return this.getHttp().get<PersonGroup[]>(url);
    }
}

type PersonGroupState = EntityState<PersonGroup, OtmId>;

@StoreConfig({ name: 'person-groups' })
class PersonGroupStore extends EntityStore<PersonGroupState> {}

class PersonGroupQuery extends QueryEntity<PersonGroupState> {
    constructor(protected store: PersonGroupStore) {
        super(store);
    }
}
