import angular from 'angular';
import template from './menuButton.tpl.html';
import { MenuButtonComponent } from './menu-button.component.ts';
export const menuButtonModule = 'sis-components.menuButton';

/**
 * This component is meant to provide backward compatibility for legacy code by working as an adapter that
 * maps the inputs/outputs of the old menuButton component with the new Angular MenuButtonComponent.
 * It is designed to work as a drop-in replacement for the old component, requiring no changes in existing
 * codebase.
 *
 * This component should NOT be used when writing new code; the downgraded version of MenuButtonComponent
 * is the preferred choice. The only purpose of this component is to allow transitioning from the old
 * menu (using UI Bootstrap) to the new one (using NG Bootstrap) without changing existing codebase.
 */
angular.module(menuButtonModule, [MenuButtonComponent.downgrade.moduleName]).component('menuButton', {
  template,
  controller: ["$scope", function ($scope) {
    this.scope = $scope;
  }],
  bindings: {
    /** See `Option` in menu-button.component.ts */
    options: '<',
    disabled: '<?',
    menuTitle: '<?',
    placeLeft: '<?',
    /** Can be used to override the default CSS classes applied to the button that opens the menu */
    buttonClasses: '<',
    openChange: '&?',
    color: '<?'
  }
});