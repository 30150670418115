<sis-page-title [title]="'OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.STEP3.TITLE' | translate"
                [dataCy]="'activate-wizard-title-step-3'"/>
<sis-wizard-progress [stepKeys]="wizardProgressStepKeys" [currentStep]="2"></sis-wizard-progress>
<div class="row col-12 sis-my-sm">
  <p>{{ 'OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.STEP3.DESC' | translate }}</p>
  <p *ngIf="isStaffUser" data-cy="activate-wizard-step-3-staff-user-desc">
    {{ 'OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.STEP3.STAFF_USER_DESC' | translate }}
  </p>
</div>
<ng-container *ngFor="let cartItem of openUniversityCart.items">
  <app-open-university-enrolled-item-info [cartItem]="cartItem"
                                          [openUniversityCart]="openUniversityCart"
                                          [openUniversityProducts]="openUniversityProducts"
                                          [courseUnits]="courseUnits"
                                          [assessmentItemsData]="assessmentItemsData"/>
</ng-container>
<sis-wizard-step-buttons *ngIf="!isSuomiFiPath"
                         [forwardButtonTitle]="'OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.STEP3.CONFIRM_BUTTON' | translate"
                         [backButtonDisplay]="false"
                         (forward)="goToFrontPage()"/>
<ng-container *ngIf="isSuomiFiPath">
  <h2>{{ 'OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.STEP3.SUOMI_FI.TITLE' | translate }}</h2>
  <div class="sis-my-sm">
    <p>{{ 'OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.STEP3.SUOMI_FI.DESC1' | translate }}</p>
    <p>{{ 'OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.STEP3.SUOMI_FI.DESC2' | translate }}</p>
    <p>{{ 'OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.STEP3.SUOMI_FI.DESC3' | translate }}</p>
  </div>
  <hr class="sis-hr"/>
  <sis-button class="suomi-fi-activate-button" [naked]="true" (clicked)="goToSearch()">
    {{ 'OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.STEP3.SUOMI_FI.CONFIRM_BUTTON' | translate }}
  </sis-button>
</ng-container>
