import { Directive, ElementRef, Injector, Input, Output } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

import { ChangeLearningOpportunityValues } from './changeLearningOpportunityModal.component';

/**
 * @deprecated
 */
// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'change-learning-opportunity-modal' })
export class ChangeLearningOpportunityModalDirective extends UpgradeComponent {

    @Output() close: ($value?: any) => void;
    @Output() dismiss: () => void;
    @Input() resolve: ChangeLearningOpportunityValues;

    constructor(elementRef: ElementRef<any>, injector: Injector) {
        super('changeLearningOpportunityModal', elementRef, injector);
    }
}
