<ng-container *transloco="let t">
  <div class="text-center sis-pt-xl d-block">
    <div *ngIf="!loggedOut">
      <h1 tabindex="-1">{{ t('AUTH.TITLE') }}</h1>
      <p>{{ t('AUTH.MESSAGE') }}</p>
    </div>
    <div *ngIf="loggedOut">
      <h1 tabindex="-1">{{ t('AUTH.LOGGED_OUT_TITLE') }}</h1>
      <p>{{ t('AUTH.LOGGED_OUT_MESSAGE') }}</p>
    </div>
    <div class="container" *ngIf="showLoginOptions$ | async">
      <div class="col-12">
        <button *ngFor="let login of logins" class="btn btn-primary btn-lg sis-mt-xs" (click)="selectAction(login)" data-cy="sisu-login-button">
          {{ login.name | localizedString }}
        </button>

        <div *ngIf="continueWithoutLogin">
          <a role="button" class="btn btn-primary btn-naked btn-lg sis-mt-lg" [uiSref]="continueWithoutLogin">
            {{ t('AUTH.CONTINUE_WITHOUT_LOGIN')}}
          </a>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </div>
</ng-container>
