extensionApplicationDecisionSummaryController.$inject = ["universityService", "commonStudyPeriodService", "STUDENT_APPLICATION_STATE", "ADMINISTRATIVE_REVIEW_RESULT"];
import _ from 'lodash';
import angular from 'angular';
import moment from 'moment';
import { commonUniversityServiceModule } from 'sis-common/university/university.service.ts';
import { personNameAndTitleModule } from '../person/personNameAndTitle.component';
import { MarkupStringComponent } from '../string/markup-string/markup-string.component.ts';
import { commonStudyPeriodServiceModule } from '../service/studyPeriod.service';
import extensionApplicationDecisionSummaryTpl from './extensionApplicationDecisionSummary.tpl.html';
import '../model/applicationDecision.model';
import '../model/studentApplication.model';
angular.module('sis-components.applications.extensionApplicationDecisionSummary', [commonUniversityServiceModule, personNameAndTitleModule, commonStudyPeriodServiceModule, 'sis-components.model.applicationDecision', 'sis-components.model.studentApplication', MarkupStringComponent.downgrade.moduleName]).component('extensionApplicationDecisionSummary', {
  template: extensionApplicationDecisionSummaryTpl,
  bindings: {
    application: '<',
    decision: '<',
    showAdministrativeReviewNotes: '<?'
  },
  controller: extensionApplicationDecisionSummaryController
});
function extensionApplicationDecisionSummaryController(universityService, commonStudyPeriodService, STUDENT_APPLICATION_STATE, ADMINISTRATIVE_REVIEW_RESULT) {
  const ctrl = this;
  ctrl.$onInit = function () {
    const extensionStartDate = _.get(ctrl.decision, 'extensionStartDate');
    const grantedTerms = _.get(ctrl.decision, 'grantedTerms');
    if (!_.isEmpty(extensionStartDate) && _.isSafeInteger(grantedTerms)) {
      const startDate = moment(extensionStartDate);
      const universityOrgId = universityService.getCurrentUniversityOrgId();
      const firstYear = startDate.year() - 1;
      const numYears = Math.ceil(grantedTerms / 2) + 1;
      commonStudyPeriodService.getStudyTerms(universityOrgId, firstYear, numYears).then(studyTerms => {
        const startTermIndex = _.findIndex(studyTerms, studyTerm => startDate.isBetween(studyTerm.valid.startDate, studyTerm.valid.endDate, 'day', '[)'));
        ctrl.extensionValidity = {
          startDate: _.get(studyTerms, `[${startTermIndex}].valid.startDate`),
          endDate: _.get(studyTerms, `[${startTermIndex + grantedTerms - 1}].valid.endDate`)
        };
      });
    }
  };
  ctrl.wasApplicationAccepted = function () {
    return _.get(ctrl.application, 'state') === STUDENT_APPLICATION_STATE.ACCEPTED;
  };
  ctrl.wasAdministrativeReviewPerformed = function () {
    return _.get(ctrl.decision, 'administrativeReview.result') !== ADMINISTRATIVE_REVIEW_RESULT.REVIEW_NOT_PERFORMED;
  };
}