import { commonPlanServiceModule } from 'sis-components/service/plan.service';
(function () {
  planAssessmentItemService.$inject = ["commonPlanService"];
  angular.module('student.common.service.planAssessmentItemService', [commonPlanServiceModule]).factory('planAssessmentItemService', planAssessmentItemService);
  function planAssessmentItemService(commonPlanService) {
    var api = {
      toggleAssessmentItemSelection: function (assessmentItemId, courseUnitId, plan) {
        var assessmentItemSelection = _.find(plan.assessmentItemSelections, {
          assessmentItemId: assessmentItemId
        });
        if (assessmentItemSelection) {
          return api.removeAssessmentItem(assessmentItemId, plan);
        } else {
          return api.addAssessmentItem(assessmentItemId, courseUnitId, plan);
        }
      },
      removeAssessmentItem: function (assessmentItemId, plan) {
        _.remove(plan.assessmentItemSelections, {
          assessmentItemId: assessmentItemId
        });
        return commonPlanService.saveMyPlan(plan);
      },
      addAssessmentItem: function (assessmentItemId, courseUnitId, plan) {
        if (!plan.assessmentItemSelections) {
          plan.assessmentItemSelections = [];
        }
        plan.assessmentItemSelections.push({
          assessmentItemId: assessmentItemId,
          courseUnitId: courseUnitId
        });
        return commonPlanService.saveMyPlan(plan);
      }
    };
    return api;
  }
})();