import { Pipe, PipeTransform } from '@angular/core';
import { dateUtils } from 'common-typescript';
import { LocalDateString, LocalDateTimeString } from 'common-typescript/types';
import moment, { unitOfTime } from 'moment/moment';

@Pipe({ name: 'isPastDate' })
export class IsPastDatePipe implements PipeTransform {

    /**
     * Returns `true` if the given date / date time is in the past (relative to `targetDate`); `false` otherwise.
     * If the given value is a string that can't be parsed into date / date time instance, returns `false`.
     *
     * @param value An ISO-8601 date / date time string
     * @param granularity Comparison granularity, defaults to `day`
     * @param inclusive Whether the comparison should be inclusive, defaults to `false`
     * @param targetDate A date / date time to compare against, defaults to the current date and time
     */
    transform(
        value: LocalDateString | LocalDateTimeString | Date | moment.Moment,
        granularity: unitOfTime.StartOf = 'day',
        inclusive = false,
        targetDate?: LocalDateString | LocalDateTimeString | Date | moment.Moment,
    ): boolean {
        const date = dateUtils.createMoment(value);
        if (!date) {
            return false;
        }
        const target = dateUtils.createMoment(targetDate) ?? moment();

        return inclusive ? date.isSameOrBefore(target, granularity) : date.isBefore(target, granularity);
    }
}
