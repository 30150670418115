studentApplicationService.$inject = ["$q", "$http", "studentApplicationEndpoint", "commonModuleService", "commonCourseUnitService", "commonAttainmentService", "commonGradeScaleService", "commonEducationService", "commonPlanService", "validAttainmentFilterService"];
import angular from 'angular';
import _ from 'lodash';
import { commonAttainmentServiceModule } from './attainment.service';
import { commonCourseUnitServiceModule } from './courseUnit.service';
import { commonEducationServiceModule } from './education.service';
import { gradeScaleModule } from './gradeScale.service';
import { commonModuleServiceModule } from './module.service';
import { commonPlanServiceModule } from './plan.service';
import { validAttainmentFilterServiceModule } from './validAttainmentFilter.service';
angular.module('sis-components.service.moduleContentApplicationService', [commonAttainmentServiceModule, commonCourseUnitServiceModule, commonEducationServiceModule, commonModuleServiceModule, commonPlanServiceModule, gradeScaleModule, validAttainmentFilterServiceModule]).factory('moduleContentApplicationService', studentApplicationService);
function studentApplicationService($q, $http, studentApplicationEndpoint, commonModuleService, commonCourseUnitService, commonAttainmentService, commonGradeScaleService, commonEducationService, commonPlanService, validAttainmentFilterService) {
  return {
    loadModuleContentApplicationObjects(application) {
      const resultObject = {
        module: {},
        selectedCustomCourseUnitAttainments: [],
        selectedCustomModuleAttainments: [],
        education: {},
        plan: {},
        selectedModules: [],
        selectedModuleAttainmentsByGroupId: [],
        selectedGroupingModules: [],
        selectedCourseUnits: [],
        selectedCourseUnitAttainmentsByGroupId: [],
        parentModule: {},
        gradeScalesById: {}
      };
      return commonModuleService.findById(application.approvedModuleId).then(approvedModule => {
        resultObject.module = approvedModule;
        const courseUnits = [];
        let modules = [];
        const curriculumPeriodId = _.first(approvedModule.curriculumPeriodIds);
        const promises = [];
        let parentModule = {};
        let gradeScales = [];
        const getCourseUnitPromises = () => {
          const courseUnitGroupIds = [];
          const attainmentIds = [];
          const courseUnitAttainments = Object.values(resultObject.selectedCourseUnitAttainmentsByGroupId);
          application.courseUnitSelections.forEach(courseUnitGroupId => {
            const attainmentForSelection = courseUnitAttainments.find(attainment => attainment.courseUnitGroupId === courseUnitGroupId);
            if (attainmentForSelection) {
              attainmentIds.push(attainmentForSelection.courseUnitId);
            } else {
              courseUnitGroupIds.push(courseUnitGroupId);
            }
          });
          return $q.all([commonCourseUnitService.findByGroupIds(courseUnitGroupIds, curriculumPeriodId), commonCourseUnitService.findAllByIds(attainmentIds)]).then(([courses, attainments]) => {
            [...courses, ...attainments].forEach(courseUnit => courseUnits.push(courseUnit));
          });
        };
        const modulePromise = commonModuleService.findByGroupIds(application.moduleSelections).then(selectedModules => {
          modules = selectedModules;
        });
        promises.push(modulePromise);
        const parentPromise = commonModuleService.findById(application.parentModuleId).then(module => {
          parentModule = module;
        }, () => {
          // parentModule is not a module, it is education
        });
        promises.push(parentPromise);
        const customAttainmentsPromise = commonAttainmentService.findModuleContentApplicationsCustomAttainments(application.id).then(attainments => {
          resultObject.selectedCustomCourseUnitAttainments = _.filter(attainments, {
            type: 'CustomCourseUnitAttainment'
          });
          resultObject.selectedCustomModuleAttainments = _.filter(attainments, {
            type: 'CustomModuleAttainment'
          });
          return [...resultObject.selectedCustomCourseUnitAttainments, ...resultObject.selectedCustomModuleAttainments];
        });
        const regularAttainmentsPromise = commonAttainmentService.searchForPerson(application.studentId, {
          primary: true
        }).then(validAttainmentFilterService.getValidAttainments).then(validAttainments => {
          resultObject.selectedCourseUnitAttainmentsByGroupId = _.chain(validAttainments).filter({
            type: 'CourseUnitAttainment'
          }).filter(attainment => application.courseUnitSelections.includes(attainment.courseUnitGroupId)).keyBy('courseUnitGroupId').value();
          resultObject.selectedModuleAttainmentsByGroupId = _.chain(validAttainments).filter({
            type: 'ModuleAttainment'
          }).filter(attainment => application.moduleSelections.includes(attainment.moduleGroupId)).keyBy('moduleGroupId').value();
          return [...Object.values(resultObject.selectedCourseUnitAttainmentsByGroupId), ...Object.values(resultObject.selectedModuleAttainmentsByGroupId)];
        });
        const attainmentsPromise = $q.all([customAttainmentsPromise, regularAttainmentsPromise]).then(([customAttainments, regularAttainments]) => {
          const gradeScalePromises = commonGradeScaleService.getGradeScales([...customAttainments, ...regularAttainments]).then(scales => {
            gradeScales = scales;
          });
          return $q.all([gradeScalePromises, getCourseUnitPromises()]);
        });
        promises.push(attainmentsPromise);
        const educationPromise = commonEducationService.findById(application.educationId).then(edu => {
          resultObject.education = edu;
        });
        promises.push(educationPromise);
        const planPromise = commonPlanService.findById(_.get(application, 'originalReferredPlanId')).then(result => {
          resultObject.plan = result;
        });
        promises.push(planPromise);
        return $q.all(promises).then(() => {
          resultObject.selectedModules = _.filter(modules, module => module.type !== 'GroupingModule');
          resultObject.selectedGroupingModules = _.difference(modules, resultObject.selectedModules);
          resultObject.selectedCourseUnits = courseUnits;
          resultObject.parentModule = parentModule || resultObject.education;
          resultObject.gradeScalesById = _.keyBy(gradeScales, 'id');
          return resultObject;
        });
      });
    }
  };
}