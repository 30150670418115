import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { StateService } from '@uirouter/core';
import { OpenUniversityCart, OpenUniversitySettings, OtmId } from 'common-typescript/types';
import { EMPTY, from, Observable } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { OpenUniversityCartCustomerService } from 'sis-components/service/open-university-cart-customer.service';
import { PaymentSystemService } from 'sis-components/service/payment-system.service';
import { UniversityService } from 'sis-components/service/university.service';

import { OpenUniversityErrorHelperService } from '../../service/open-university-error-helper.service';

@Component({
    selector: 'app-payment-failed',
    templateUrl: './payment-failed.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentFailedComponent implements OnInit {

    requestInProgress = false;
    cart$: Observable<OpenUniversityCart>;
    openUniversitySettings$: Observable<OpenUniversitySettings>;

    constructor(
        private localeService: LocaleService,
        private openUniversityCartService: OpenUniversityCartCustomerService,
        private openUniversityErrorHelper: OpenUniversityErrorHelperService,
        private paymentSystemService: PaymentSystemService,
        private ref: ChangeDetectorRef,
        private state: StateService,
        private universityService: UniversityService,
    ) {}

    ngOnInit(): void {
        this.cart$ = this.openUniversityCartService.getCurrentCart(true)
            .pipe(catchError(() => EMPTY));
        this.openUniversitySettings$ = this.universityService.getCurrentUniversitySettings()
            .pipe(
                catchError(() => EMPTY),
                map(settings => settings?.openUniversitySettings),
                filter(settings => !!settings?.customerServiceEmail || !!this.localeService.localize(settings?.customerServiceUrl)),
            );
    }

    backToCart(): void {
        this.state.go('student.open-university-cart');
    }

    backToSearch(): void {
        this.state.go('student.search.open-university');
    }

    retryPayment(cartId: OtmId): void {
        this.requestInProgress = true;
        this.paymentSystemService.initializePaymentAndNavigateToPaymentSystem(cartId, this.localeService.getCurrentLanguage(), null)
            .pipe(
                catchError(() => {
                    this.openUniversityErrorHelper.showPaymentInitializationFailureAlert();
                    return EMPTY;
                }),
            )
            .subscribe()
            .add(() => {
                this.requestInProgress = false;
                this.ref.markForCheck();
            });
    }
}
