import 'angular';
import 'angular-translate';
import _ from 'lodash';
import 'sis-components/applications/textWithDefaultMissingValue.component.js';
import 'sis-components/model/gradeScale.model.js';
import 'sis-components/model/publicPerson.model.js';
import 'sis-components/model/studentApplication.model.js';
import 'sis-components/model/organisation.model.js';
import 'sis-components/service/attainment.service.js';
import { decimalNumberFilterModule } from 'sis-components/number/decimalNumber.filter';
import { MarkupStringComponent } from '../string/markup-string/markup-string.component.ts';
import { localizedUrnNameDisplayModule } from '../codes/localizedUrnNameDisplay.component';
import attainmentApplicationDecisionSummaryTpl from './attainmentApplicationDecisionSummary.tpl.html';
'use strict';
(function () {
  attainmentApplicationDecisionSummaryController.$inject = ["$q", "$scope", "gradeScaleJSDataModel", "commonAttainmentService", "publicPersonModel", "organisationJSDataModel", "STUDENT_APPLICATION_STATE"];
  /**
   * Use Angular component AttainmentWorkflowDecisionSummary instead in future development.
   *
   * @deprecated
   */
  angular.module('sis-components.applications.attainmentApplicationDecisionSummary', [localizedUrnNameDisplayModule, decimalNumberFilterModule, 'sis-components.applications.textWithDefaultMissingValue', 'sis-components.model.gradeScale', 'sis-components.model.publicPerson', 'sis-components.model.studentApplication', 'sis-components.model.organisation', 'sis-components.service.attainmentService', MarkupStringComponent.downgrade.moduleName]).component('attainmentApplicationDecisionSummary', {
    template: attainmentApplicationDecisionSummaryTpl,
    bindings: {
      application: '<'
    },
    controller: attainmentApplicationDecisionSummaryController
  });
  function attainmentApplicationDecisionSummaryController($q, $scope, gradeScaleJSDataModel, commonAttainmentService, publicPersonModel, organisationJSDataModel, STUDENT_APPLICATION_STATE) {
    //NOSONAR
    $scope.STUDENT_APPLICATION_STATE = STUDENT_APPLICATION_STATE;
    var ctrl = this;
    ctrl.$onInit = function () {
      commonAttainmentService.findAttainmentsForStudentApplication(ctrl.application.id, true).then(function (attainments) {
        // Application should be linked to only one active attainment
        ctrl.attainment = _.find(attainments, function (attainment) {
          return !attainment.misregistration && !!attainment.primary;
        });
        if (ctrl.attainment) {
          ctrl.attainmentOrganisationsById = {};
          if (ctrl.attainment.gradeScaleId && _.isNumber(ctrl.attainment.gradeId)) {
            gradeScaleJSDataModel.find(ctrl.attainment.gradeScaleId).then(function (gradeScale) {
              var grades = _.get(gradeScale, 'grades', []);
              if (ctrl.attainment.gradeId < grades.length) {
                ctrl.attainmentGrade = grades[ctrl.attainment.gradeId];
              }
            });
          }
          if (_.has(ctrl.attainment, 'acceptorPersons[0].personId')) {
            publicPersonModel.find(ctrl.attainment.acceptorPersons[0].personId).then(function (person) {
              ctrl.attainmentAcceptor = person;
            });
          }
          if (ctrl.attainment.organisations) {
            $q.all(_.chain(ctrl.attainment.organisations).filter(organisation => !_.isNil(organisation.organisationId)).map(organisationShare => organisationJSDataModel.find(organisationShare.organisationId)).value()).then(organisations => {
              ctrl.attainmentOrganisationsById = _.keyBy(organisations, 'id');
            });
          }
        }
      });
    };
    ctrl.getAttainmentName = function () {
      if (_.hasIn(ctrl, 'attainment.courseUnit.name')) {
        return ctrl.attainment.courseUnit.name;
      } else if (_.hasIn(ctrl, 'attainment.name')) {
        return ctrl.attainment.name;
      }
      return {
        fi: '-'
      };
    };
    ctrl.applicationValue = function (fieldPath) {
      return _.get(ctrl.application, fieldPath);
    };
    ctrl.valueKey = function (value, translationKeyPrefix) {
      return value ? translationKeyPrefix + value : value;
    };
    ctrl.hasFinalDecision = function () {
      return ctrl.application.decision && _.includes([STUDENT_APPLICATION_STATE.ACCEPTED, STUDENT_APPLICATION_STATE.REJECTED, STUDENT_APPLICATION_STATE.ACCEPTANCE_REVOKED, STUDENT_APPLICATION_STATE.REVOKED], ctrl.application.state);
    };
    ctrl.isCourseUnitAttainment = function () {
      return _.get(ctrl, 'attainment.type') === 'CourseUnitAttainment';
    };
    ctrl.missingTextValueKey = 'STUDENT_APPLICATIONS.INFO_MISSING';
    ctrl.missingOptionValue = '-';
  }
})();