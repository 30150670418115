import { Injectable } from '@angular/core';
import {
    LocalizedString,
} from 'common-typescript/types';
import _ from 'lodash';

import { LocaleService } from '../l10n/locale.service';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from '../types/angular-hybrid';

@StaticMembers<DowngradedService>()
@Injectable({ providedIn: 'root' })
export class NameService {

    static downgrade: ServiceDowngradeMappings = {
        moduleName: 'sis-common.name.name-service',
        serviceName: 'nameService',
    };

    constructor(protected localeService: LocaleService) { }

    generateFullName<T extends { nameSpecifier?: LocalizedString; name?: LocalizedString }>(itemToLocalize: T): LocalizedString {
        const fullName: LocalizedString = {};
        const nameSpecifier = itemToLocalize.nameSpecifier ?? {};
        const name = itemToLocalize.name ?? {};

        let fallbackName = '';
        let fallbackNameSpecifier = '';
        _.forEach(this.localeService.getOfficialLanguages(), (locale) => {
            if (!fallbackNameSpecifier && this.nameExists(nameSpecifier, locale)) {
                fallbackNameSpecifier = nameSpecifier[locale];
            }
            if (!fallbackName && this.nameExists(name, locale)) {
                fallbackName = name[locale];
            }
        });

        _.forEach(this.localeService.getOfficialLanguages(), (locale) => {
            let nameSpecifierForLocale = this.nameExists(nameSpecifier, locale) ? nameSpecifier[locale] : fallbackNameSpecifier;
            nameSpecifierForLocale += nameSpecifierForLocale.length === 0 ? '' : ', ';

            const nameForLocale = this.nameExists(name, locale) ? name[locale] : fallbackName;
            fullName[locale] = _.trim(nameSpecifierForLocale + nameForLocale);
        });

        return fullName;
    }

    private nameExists(name: LocalizedString, locale: string): boolean {
        return name[locale] && name[locale].trim().length > 0;
    }

    localizedStringAreEqual(str1: LocalizedString, str2: LocalizedString) {
        return str1 && _.keys(str1).sort().join() === _.keys(str2).sort().join() &&
            _.values(str1).sort().join() === _.values(str2).sort().join();
    }
}
