import { Injectable } from '@angular/core';
import { StudentApplication } from 'common-typescript/types';
import { from, Observable } from 'rxjs';
import { ModalService } from 'sis-common/modal/modal.service';

import { CancelApplicationModalComponent, CancelApplicationValues } from './cancelApplicationModal.component';

@Injectable()
export class ApplicationModalService {

    constructor(private modalService: ModalService) { }

    openCancelApplicationModal(application: StudentApplication): Observable<any> {
        const values: CancelApplicationValues = { application };
        return from(this.modalService.open(CancelApplicationModalComponent, values, { size: 'sm' }).result);
    }
}
