import { Injectable } from '@angular/core';
import { DatesSetArg } from '@fullcalendar/core';
import { SessionStorageService } from 'sis-common/storage/session-storage.service';

import { FullCalendarViewType, HiddenEventPreference } from './full-calendar.types';

@Injectable({
    providedIn: 'root',
})
export class FullCalendarWebStorageService {

    readonly STUDENT_CALENDAR_CONFIGURATION = 'otm.student.calendar.config';
    readonly HIDDEN_EVENT_TYPE_CONFIGURATION = `${this.STUDENT_CALENDAR_CONFIGURATION}.hidden_events`;

    constructor(
        private sessionStorageService: SessionStorageService,
    ) { }

    persistCalendarState(arg: DatesSetArg) {
        this.sessionStorageService.setValue(this.STUDENT_CALENDAR_CONFIGURATION, {
            initialView: arg.view.type,
            initialDate: arg.view.currentStart,
        });
    }

    persistHiddenEventTypePreference(hiddenEventPreference: HiddenEventPreference) {
        this.sessionStorageService.setValue(this.HIDDEN_EVENT_TYPE_CONFIGURATION, hiddenEventPreference);
    }

    getInitialView(): string {
        return this.sessionStorageService.getValueOrDefault(this.STUDENT_CALENDAR_CONFIGURATION, { initialView: FullCalendarViewType.TIME_GRID_WEEK }).initialView;
    }

    getInitialDate(): Date {
        return this.sessionStorageService.getValueOrDefault(this.STUDENT_CALENDAR_CONFIGURATION, { initialDate: new Date() }).initialDate;
    }

    getHiddenEventPreference(): HiddenEventPreference {
        return this.sessionStorageService.getValueOrDefault(this.HIDDEN_EVENT_TYPE_CONFIGURATION, []);
    }
}
