<app-student-study-event-summary [studyEvent]="studyEvent"
                                 [hasNoLocations]="hasNoLocations"
                                 [hasIrregularLocations]="hasIrregularLocations"
                                 [allStudyEventTeacherIds]="allStudyEventTeacherIds"
                                 [cancelledOrExcludedEventCount]="cancelledOrExcludedEventCount"
                                 [allEventsCancelledOrExcluded]="allEventsCancelledOrExcluded"
                                 [language]="language">
</app-student-study-event-summary>
<app-student-study-event-exceptions *ngIf="irregularEvents.length > 0"
                                    [irregularEvents]="irregularEvents"
                                    [studyEventLocationIds]="studyEvent.locationIds"
                                    [studyEventTeacherIds]="teacherIds"
                                    [language]="language">
</app-student-study-event-exceptions>



