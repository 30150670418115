import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AttainmentGroupNode } from 'common-typescript/types';
import {
    ComponentDowngradeMappings,
    DowngradedComponent,
    StaticMembers,
} from 'sis-common/types/angular-hybrid';

import { PlanData, PlanStateObject } from '../../service/plan-state.service';
import { UiStateObject } from '../plan-edit/plan-edit.component';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-attainment-group-node',
    templateUrl: './attainment-group-node.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class AttainmentGroupNodeComponent implements OnInit {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.plan.attainmentGroupNode',
        directiveName: 'sisAttainmentGroupNode',
    };

    isOpen: boolean;
    highlighted: boolean;
    classes: string;

    @Input() attainmentGroupNode: AttainmentGroupNode;
    @Input() planStateObject: PlanStateObject;
    @Input() planData: PlanData;
    @Input() uiStateObject: UiStateObject;
    @Input() level: number;

    ngOnInit() {
        this.isOpen = true;
        this.highlighted = false;
        this.classes = this.getClasses();
    }

    getClasses(): string {
        const classes = [
            `tree-list-item-level-${this.level}`,
        ];
        if (this.level > 1) {
            classes.push('tree-list-item', 'clearfix');
        }
        return classes.join(' ');
    }

}
