import { Injectable } from '@angular/core';
import { TranslationMarkupRendererFactory } from 'ngx-transloco-markup';

import { HtmlElementTranspiler } from '../common/html-element-transpiler';

export const H5_START = new (class H5Start {})();
export const H5_END = new (class H5End {})();

@Injectable()
export class H5Transpiler extends HtmlElementTranspiler {
    constructor(private readonly rendererFactory: TranslationMarkupRendererFactory) {
        super({
            rendererFactory,
            start: {
                search: '<h5>',
                token: H5_START,
            },
            end: {
                search: '</h5>',
                token: H5_END,
            },
            elementTag: 'h5',
        });
    }
}
