import { Inject, Injectable } from '@angular/core';
import { EntityActions } from '@datorama/akita';
import { CourseUnit, DegreeProgramme, Module, StudyModule } from 'common-typescript/types';
import { DSResourceDefinition } from 'js-data';
import _ from 'lodash';
import { tap } from 'rxjs/operators';

import {
    COURSE_UNIT_JS_DATA_MODEL,
    DEGREE_PROGRAMME_JS_DATA_MODEL,
    MODULE_JS_DATA_MODEL, STUDY_MODULE_JS_DATA_MODEL,
} from './../ajs-upgraded-modules';
import { CourseUnitEntityService } from './course-unit-entity.service';
import { DegreeProgrammeEntityService } from './degree-programme-entity.service';
import { EntityService } from './entity.service';
import { ModuleEntityService } from './module-entity.service';
import { StudyModuleEntityService } from './study-module-entity.service';

@Injectable()
export class JsDataSyncService {
    constructor(
        private moduleEntityService: ModuleEntityService,
        @Inject(MODULE_JS_DATA_MODEL) private moduleJSDataModel: DSResourceDefinition<Module>,
        private degreeProgrammeEntityService: DegreeProgrammeEntityService,
        @Inject(DEGREE_PROGRAMME_JS_DATA_MODEL) private degreeProgrammeJsDataModel: DSResourceDefinition<DegreeProgramme>,
        private courseUnitEntityService: CourseUnitEntityService,
        @Inject(COURSE_UNIT_JS_DATA_MODEL) private courseUnitJsDataModel: DSResourceDefinition<CourseUnit>,
        private studyModuleEntityService: StudyModuleEntityService,
        @Inject(STUDY_MODULE_JS_DATA_MODEL) private studyModuleJsDataModel: DSResourceDefinition<StudyModule>,
    ) {
        this.syncToJsData(moduleEntityService, moduleJSDataModel);
        this.syncToJsData(degreeProgrammeEntityService, degreeProgrammeJsDataModel);
        this.syncToJsData(courseUnitEntityService, courseUnitJsDataModel);
        this.syncToJsData(studyModuleEntityService, studyModuleJsDataModel);
    }

    private syncToJsData<T>(entityService: EntityService<T>, model: DSResourceDefinition<T>): void {
        entityService.query.selectEntityAction([EntityActions.Add, EntityActions.Set, EntityActions.Update])
            .pipe(
                tap((data) => {
                    data.ids.forEach((id) => {
                        const entity = entityService.query.getEntity(id);
                        if (!!entity) {
                            // Akita freezes object and JSData tries to mutate it.
                            // Errors will occur if they hold the same object.
                            model.inject(_.cloneDeep(entity));
                        }
                    });
                }),
            )
            .subscribe();
        entityService.query.selectEntityAction(EntityActions.Remove)
            .pipe(
                tap((ids) => {
                    ids.forEach((id) => {
                        model.eject(id as string);
                    });
                }),
            )
            .subscribe();
    }
}
