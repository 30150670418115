import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OtmId, TermRegistration } from 'common-typescript/types';
import { Subscription } from 'rxjs';

import { StudyRightTermRegistrationsEntityService } from '../../service/study-right-term-registrations-entity.service';
import { StudyYearAndTermRegistrations, TermRegistrationGroupingService } from '../../service/term-registration-grouping.service';

@Component({
    selector: 'sis-study-years-term-registrations',
    templateUrl: './study-years-term-registrations.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [TermRegistrationGroupingService],
})
export class StudyYearsTermRegistrationsComponent implements OnInit, OnDestroy {
    constructor(
        private translate: TranslateService,
        private termRegistrationGroupingService: TermRegistrationGroupingService,
        private studyRightTermRegistrationsEntityService: StudyRightTermRegistrationsEntityService,
    ) { }

    /**
     * Determines grouping panel's heading's semantic aria-level for screen readers, default is equivalent for h6
     */
    @Input() level?: number = 6;
    @Input() studyRightId: OtmId;
    readonly DEFAULT_STUDY_YEAR_COUNT = 3;
    private subscription = Subscription.EMPTY;
    registrationsByStudyYear: StudyYearAndTermRegistrations[] = [];
    studyTermNamesByTermIndex: Map<number, string>;
    showOldStudyYears = false;

    ngOnInit(): void {
        this.studyTermNamesByTermIndex = new Map([0, 1].map(termIndex => [termIndex, this.getStudyTermName(termIndex)]));
        this.subscription = this.studyRightTermRegistrationsEntityService.getById(this.studyRightId)
            .subscribe((srtr) => {
                const termRegistrations: TermRegistration[] = srtr.termRegistrations;
                this.registrationsByStudyYear = this.termRegistrationGroupingService.groupRegistrationsByStudyYear(termRegistrations);
            });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    private getStudyTermName(termIndex: number): string {
        return this.translate.instant(`STUDY_TERMS.${termIndex === 0 ? 'AUTUMN' : 'SPRING'}`);
    }
}
