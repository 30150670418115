import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxFudisModule } from '@funidata/ngx-fudis';
import { TranslateModule } from '@ngx-translate/core';
import { SisCommonModule } from 'sis-common/sis-common.module';

import { SisComponentsHybridModule } from '../../sis-components.module.hybrid';
import { commonStudentAttainmentServiceProvider } from '../ajs-upgraded-modules';

import { AttainedDegreesComponent } from './attained-degrees/attained-degrees.component';
import { CommonStudyRightModalService } from './common-study-right-modal.service';
import { CourseUnitSelectionComponent } from './course-unit-selection/course-unit-selection.component';
import { ExtensionInfoModalComponent } from './extension-info-modal/extension-info-modal.component';
import { ExtensionInfoComponent } from './extension-info/extension-info.component';
import { InternationalContractualDegreeComponent } from './international-contractual-degree/international-contractual-degree.component';
import { MinorSelectionModule } from './minor-selection/minor-selection.module';
import { MobilityPeriodEditComponent } from './mobility-periods/mobility-period-edit/mobility-period-edit.component';
import { MobilityPeriodsComponent } from './mobility-periods/mobility-periods.component';
import { ModuleSelectionComponent } from './module-selection/module-selection.component';
import { PersonalizedSelectionNotificationComponent } from './personalized-selection-notification/personalized-selection-notification.component';
import { PersonalizedSelectionRationaleComponent } from './personalized-selection-rationale/personalized-selection-rationale.component';
import { ReadOnlyStudyRightComponent } from './read-only-study-right/read-only-study-right.component';
import { SemestersIndicatorComponent } from './semesters-indicator/semesters-indicator.component';
import { StudyRightEducationStructureViewModule } from './study-right-education-structure-view/study-right-education-structure-view.module';
import { StudyRightCancelDecisionModalComponent } from './study-right-states/study-right-cancel-decision-modal/study-right-cancel-decision-modal.component';
import { StudyRightStatesModule } from './study-right-states/study-right-states.module';
import { StudyRightStatusBadgeModule } from './study-right-status-badge/study-right-status-badge.module';
import { StudyYearTermRegistrationsComponent } from './study-year-term-registrations/study-year-term-registrations.component';
import { StudyYearsTermRegistrationsComponent } from './study-years-term-registrations/study-years-term-registrations.component';
import { ReadonlyTuitionFeeObligationPeriodComponent } from './tuition-fee-obligation-periods/readonly-tuition-fee-obligation-period.component';

@NgModule({
    declarations: [
        AttainedDegreesComponent,
        CourseUnitSelectionComponent,
        ExtensionInfoComponent,
        ExtensionInfoModalComponent,
        InternationalContractualDegreeComponent,
        MobilityPeriodEditComponent,
        MobilityPeriodsComponent,
        ModuleSelectionComponent,
        PersonalizedSelectionNotificationComponent,
        PersonalizedSelectionRationaleComponent,
        ReadOnlyStudyRightComponent,
        ReadonlyTuitionFeeObligationPeriodComponent,
        SemestersIndicatorComponent,
        StudyYearsTermRegistrationsComponent,
        StudyYearTermRegistrationsComponent,
        StudyRightCancelDecisionModalComponent,
    ],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        SisCommonModule,
        SisComponentsHybridModule,
        MinorSelectionModule,
        StudyRightStatusBadgeModule,
        StudyRightStatesModule,
        StudyRightEducationStructureViewModule,
        FormsModule,
        ReactiveFormsModule,
        NgxFudisModule,
    ],
    exports: [
        AttainedDegreesComponent,
        CourseUnitSelectionComponent,
        ExtensionInfoComponent,
        InternationalContractualDegreeComponent,
        MobilityPeriodsComponent,
        ModuleSelectionComponent,
        PersonalizedSelectionNotificationComponent,
        ReadOnlyStudyRightComponent,
        ReadonlyTuitionFeeObligationPeriodComponent,
        StudyYearsTermRegistrationsComponent,
        StudyRightEducationStructureViewModule,
    ],
    providers: [
        CommonStudyRightModalService,
        commonStudentAttainmentServiceProvider,
    ],
})
export class StudyRightModule {
}
