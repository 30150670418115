"use strict";

/**
 * Our custom frontend implementation of Hibernate NotEmpty validation annotation.
 */
(function () {
  angular.module('sis-common.form').factory('notEmptyValidator', notEmptyValidator);

  /**
   * @ngInject
   */
  function notEmptyValidator() {
    return {
      name: 'jakarta.validation.constraints.NotEmpty',
      /**
       * Checks that the value is not an empty object, collection, map, or set or string.
       *
       * @param value the value to validate
       * @returns {boolean} true if the value is not empty
       */
      validate: function (value) {
        return !_.isEmpty(value);
      }
    };
  }
})();