import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LocalizedString } from 'common-typescript/types';
import { LocaleService } from 'sis-common/l10n/locale.service';

import { LanguageChangeService } from '../../l10n/language-change.service';

interface LanguageSelection {
    language: string;
    name: LocalizedString | string;
}

@Component({
    selector: 'sis-language-selection-menu',
    templateUrl: './language-selection-menu.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class LanguageSelectionMenuComponent implements OnInit {

    languageSelections: LanguageSelection[] = [];

    constructor(private localeService: LocaleService,
                private languageChangeService: LanguageChangeService,
    ) { }

    ngOnInit() {
        this.initLanguageSelections();
    }

    get currentLanguage(): string {
        return this.localeService.getCurrentLanguage();
    }

    get currentLanguageName(): string {
        return this.languageName(this.currentLanguage);
    }

    changeLanguage(language: string): void {
        this.languageChangeService.use(language);
    }

    private initLanguageSelections(): void {
        this.localeService.getLocalizedLanguageNames()
            .subscribe((languageNames) => {
                this.languageSelections = this.localeService.getOfficialLanguages()
                    .map(language => ({ language, name: languageNames[language] }));

                // Languages are hardcoded in language menu since language options should not be translated
                this.languageSelections.forEach((value) => {
                    value.name = this.languageName(value.language);
                });
            });
    }

    private languageName(language: string): string {
        if (language === 'fi') return 'Suomi';
        if (language === 'sv') return 'Svenska';
        if (language === 'en') return 'English';
        return '';
    }
}
