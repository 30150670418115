import timingCancelBoxTpl from './timingCancelBox.tpl.html';
angular.module('student.timing.cancelBox.timingCancelBox', ['pascalprecht.translate', 'sis-common.l10n.localizedStringFilter', 'student.timing.timingViewStateService', 'student.timing.constant']).component('timingCancelBox', {
  template: timingCancelBoxTpl,
  bindings: {
    state: '<'
  },
  controller: ["timingViewStateService", "timelineConfig", function (timingViewStateService, timelineConfig) {
    const $ctrl = this;
    $ctrl.cancel = function () {
      timingViewStateService.onEndTiming($ctrl.state);
    };
    $ctrl.isNoteTiming = function () {
      return _.includes([timelineConfig.viewStates.ADD_NOTE, timelineConfig.viewStates.EDIT_NOTE, timelineConfig.viewStates.MOVE_NOTE_TIMING], $ctrl.state.type);
    };
    $ctrl.isCourseUnitTiming = function () {
      return _.includes([timelineConfig.viewStates.TIME_COURSE_UNIT, timelineConfig.viewStates.MOVE_COURSE_UNIT_TIMING], $ctrl.state.type);
    };
    $ctrl.isCustomStudyDraftTiming = () => {
      return _.includes([timelineConfig.viewStates.TIME_CUSTOM_STUDY_DRAFT, timelineConfig.viewStates.MOVE_CUSTOM_STUDY_DRAFT_TIMING], $ctrl.state.type);
    };
  }]
});