<div>
  <div *ngIf="popoverInfoKey && popoverLabelKey">
    <span id="version-label">{{popoverLabelKey | translate}}</span>
    <sis-popover
      popoverClass="dark"
      [popoverPlacement]="popoverPlacement"
      (shown)="popoverShown = true"
      (hidden)="popoverShown = false">
      <button type="button"
        class="non-button sis-popover-trigger-content"
        [attr.aria-label]="popoverLabelKey | translate"
        [attr.aria-expanded]="popoverShown">
        <sis-icon icon="info-circle-fill" aria-hidden="true"></sis-icon>
      </button>
      <div class="sis-popover-popover-content">
        <p>{{popoverInfoKey | translate}}</p>
      </div>
    </sis-popover>
  </div>
  <sis-select-combobox
    [options]="options"
    (selectionChange)="selectVersion($event)"
    [selection]="currentVersionId"
    [ariaLabelledBy]="'version-label'">
  </sis-select-combobox>
</div>
