import {
    Component, DestroyRef,
    Inject, OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { interval, map, Observable, tap, timer } from 'rxjs';
import { ModalService } from 'sis-common/modal/modal.service';

export interface SessionExpiryWarningValues {
    countdownTimeInSeconds: number;
}

@Component({
    selector: 'sis-session-timeout-warning',
    templateUrl: './session-timeout-warning.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class SessionTimeoutWarningModalComponent implements OnInit {
    constructor(
        @Inject(ModalService.injectionToken) public values: SessionExpiryWarningValues,
        private destroyedRef: DestroyRef,
        public modal: NgbActiveModal,
    ) {}

    timerValues$: Observable<any>;

    ngOnInit() {
        this.startSessionTimeoutTimer();
        this.displayTimeUntilSessionTimeout();
    }

    displayTimeUntilSessionTimeout() {
        const timeoutAt = Date.now() + (this.values.countdownTimeInSeconds * 1000);
        this.timerValues$ = interval(1000).pipe(
            takeUntilDestroyed(this.destroyedRef),
            map(() => {
                const timeRemainingInSeconds = Math.floor((timeoutAt - Date.now()) / 1000);
                const assertive = this.assertiveAnnouncement(timeRemainingInSeconds);

                return { timeRemainingInSeconds, assertive };
            }),
        );
    }

    startSessionTimeoutTimer() {
        timer(this.values.countdownTimeInSeconds * 1000).pipe(
            takeUntilDestroyed(this.destroyedRef),
            tap(() => this.modal.dismiss()),
        ).subscribe();
    }

    /**
     * every once in a while for accessibility purposes the countdown needs to be shown with the aria-live attribute ASSERTIVE
     * the assertive attribute cancels any ongoing screen reader activity and focuses on that announcement
     * assertively announce the time remaining as following:
     * more than 1 minute left: every minute
     * 1 minute left: every fifteen seconds
     * */
    assertiveAnnouncement(timeRemainingInSeconds: number) {
        return (timeRemainingInSeconds > 60 && timeRemainingInSeconds % 60 === 0) ||
                (timeRemainingInSeconds <= 60 && timeRemainingInSeconds % 15 === 0);
    }

}

