import codePanelTpl from './codePanel.tpl.html';
export const codePanelModule = 'sis-components.applications.codePanel';
"use strict";
(function () {
  /**
   * Deprecated. Use the Angular CodePanelComponent instead.
   *
   * @deprecated
   */
  angular.module(codePanelModule, []).directive('codePanel', codePanel);

  /**
   * @ngInject
   */
  function codePanel() {
    return {
      restrict: 'E',
      scope: {
        code: '<'
      },
      template: codePanelTpl
    };
  }
})();