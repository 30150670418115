import { TranslateLoader } from '@ngx-translate/core';
import { merge } from 'lodash';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { loadSisCommonTranslations } from 'sis-common/translate/translation-loader';
import { loadSisComponentsTranslations } from 'sis-components/translate/translation-loader';

export function loadStudentTranslations(lang: string): Promise<any> {
    // The Webpack magic comments cause the translation files to be extracted into their own lazy loaded chunks.
    // Without the comments, the app for some reason throws the infamous bootstrapping error during page load:
    // "Trying to get the Angular injector before bootstrapping an Angular module."
    // See Webpack docs for details: https://webpack.js.org/api/module-methods/#magic-comments
    return import(/* webpackChunkName: 'student-[request]', webpackInclude: /\.json$/ */ `./${lang}.json`);
}

export class StudentTranslateLoader implements TranslateLoader {
    getTranslation(lang: string): Observable<any> {
        return from(Promise.all([
            loadSisCommonTranslations(lang),
            loadSisComponentsTranslations(lang),
            loadStudentTranslations(lang),
        ])).pipe(map(translations => merge({}, ...translations)));
    }
}
