import { Component, EventEmitter, Inject, Input, Output, ViewEncapsulation } from '@angular/core';
import { ValidatablePlan } from 'common-typescript';
import { DegreeProgramme, EntityWithRule, OtmId, Plan } from 'common-typescript/types';
import { COMMON_PLAN_SERVICE, PLAN_SELECTOR_SERVICE } from 'sis-components/ajs-upgraded-modules';
import { PlanSelectorConfig } from 'sis-components/plan/planSelectorConfig';
import { isDegreeProgramme } from 'sis-components/service/module-entity.service';

export interface SelectedModule {
    module: EntityWithRule;
    /** The closest ancestor degree programme of the selected module in the plan structure */
    degreeProgramme: DegreeProgramme;
}

@Component({
    selector: 'app-plan-module-selector',
    templateUrl: './plan-module-selector.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class PlanModuleSelectorComponent {

    @Input() set plan(plan: Plan) {
        this.commonPlanService.preLoadedPlanData(plan)
            .then((planData: unknown) => {
                this.validatablePlan = this.commonPlanService.createValidatablePlan(plan, planData);
                if (this._moduleId) {
                    this.selectModule(this._moduleId);
                }
            });
    }

    @Input() set moduleId(moduleId: OtmId) {
        this._moduleId = moduleId;
        this.selectModule(moduleId);
    }

    @Output() select = new EventEmitter<SelectedModule>();

    validatablePlan: ValidatablePlan;
    selectedModule: EntityWithRule;
    _moduleId: OtmId;

    constructor(
        @Inject(COMMON_PLAN_SERVICE) private commonPlanService: any,
        @Inject(PLAN_SELECTOR_SERVICE) private planSelectorService: any,
    ) {}

    openPlanSelector(): void {
        const title = 'PROFILE.APPLICATIONS.SELECT_LOCATION.MODAL_HEADER';
        const description = 'PROFILE.APPLICATIONS.SELECT_LOCATION.MODAL_INFO';

        const config = new PlanSelectorConfig();
        config.allowCourseUnitSelection = false;

        this.planSelectorService.openPlanSelectorModal(title, description, this.validatablePlan, config)
            .then((moduleSelection: { id: OtmId }) => this.selectModule(moduleSelection?.id))
            .catch(() => {});
    }

    findParentDegreeProgramme(moduleId: OtmId): DegreeProgramme {
        const module = this.validatablePlan?.modulesById?.[moduleId];
        const moduleSelection = this.validatablePlan?.moduleIdSelectionMap?.[moduleId];
        if (isDegreeProgramme(module)) {
            return module;
        }
        if (!moduleSelection?.parentModuleId) {
            return null;
        }
        return this.findParentDegreeProgramme(moduleSelection.parentModuleId);
    }

    selectModule(moduleId: OtmId): void {
        if (this.validatablePlan) {
            this.selectedModule = this.validatablePlan.modulesById?.[moduleId];
            this.select.emit({
                module: this.selectedModule ?? null,
                degreeProgramme: this.findParentDegreeProgramme(moduleId),
            });
        }
    }
}
