<div class="container-fluid ms-3 pe-3 me-2">
  <div class="row">
    <div class="col-7 pe-0">
      <div class="mt-4">
        <h2 class="modal-title" tabindex="0">
        {{ module.name | localizedString}} ({{ module.targetCredits | creditRange }})
          <sis-non-active-document-state-badge [documentState]="module.documentState"></sis-non-active-document-state-badge>
        </h2>
      </div>
      <div class="mb-3">
        <span>{{ module.code }} | {{ moduleLocalizationKey  | translate }}</span>
      </div>
    </div>
    <div class="col-5 ps-0">
      <div class="float-end mt-3 pe-3">
        <sis-button link="true" (clicked)="closeModal.emit()" [ariaLabel]="'ARIA_LABEL.CLOSE_BROCHURE' | translate">
          <sis-icon icon="close-big" color="light"></sis-icon>
        </sis-button>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-xl-4 col-md-6 col-sm-12 order-first">
      <sis-version-selector
        [currentVersionId]="module.id"
        [options]="options"
        (change)="changeVersion($event)"
        [popoverInfoKey]="popoverInfoLocalizationKey"
        [popoverLabelKey]="popoverLabelLocalizationKey"
        [popoverPlacement]="'right'">
      </sis-version-selector>
    </div>
    <div class="col-lg-12 order-lg-2 order-md-1">
      <div *ngFor="let headerNotification of headerNotifications" class="row mb-3 pe-3 me-2">
        <div class="col-12">
          <ng-container [ngTemplateOutlet]="headerNotification"></ng-container>
        </div>
      </div>
    </div>
    <div class="col-lg-6 ms-xl-5 ps-xl-5 order-lg-1 order-md-2">
      <sis-description-list-compact [columnCount]="1">
        <dl>
          <ng-template #compactListItem>
            <dt class="label colon">{{'FIELD_NAMES.attainment' | translate}}</dt>
            <dd *ngIf="attainment; else notAttained" class="value">
              {{ attainment.state | attainmentStatusTranslation | translate }}
              <span *ngIf="attainment.credits">({{attainment.credits | decimalNumber}} {{'CREDITS' | translate}})</span>
            </dd>
            <ng-template #notAttained>
              <dd class="value">
                {{'MODULE_INFO.NOT_ATTAINED' | translate}}
              </dd>
            </ng-template>
          </ng-template>
          <ng-template #compactListItem>
            <dt class="label colon">{{'FIELD_NAMES.grade' | translate}}</dt>
            <dd *ngIf="attainment; else notGraded" class="value">
              {{ attainment.gradeId | grade: attainment.gradeScaleId : 'NAME' | async }}
            </dd>
            <ng-template #notGraded>
              <dd class="value">
                {{'MODULE_INFO.NOT_GRADED' | translate}}
              </dd>
            </ng-template>
          </ng-template>
        </dl>
      </sis-description-list-compact>
    </div>
  </div>
</div>
