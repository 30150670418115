<ng-container *transloco="let t">
  <sis-study-notification-box
    *ngIf="enrolment"
    [type]="getNotificationType()"
    [primaryButtonText]="primaryButtonText"
    (primaryClickAction)="primaryClickAction.emit()"
    [secondaryButtonText]="secondaryButtonText"
    (secondaryClickAction)="secondaryClickAction.emit()"
  >
    <ng-container *ngIf="enrolment.state === 'PROCESSING' && !['NOT_PROCESSED', 'NOT_VALID'].includes(enrolment.processingState)">
      {{t(enrolment.processingState | enrolmentProcessingStateMessageKey)}}
    </ng-container>
    <ng-container *ngIf="processedStates.includes(enrolment.state)">
      {{t('COURSE_UNIT_REALISATION.ENROLMENT_STATE_FOR_VIEW.' + enrolment.state)}}
    </ng-container>
  </sis-study-notification-box>
</ng-container>
