import {
    ChangeDetectionStrategy,
    Component,
    computed, DestroyRef,
    inject,
    Input, OnChanges, OnInit,
    signal,
    Signal,
    ViewEncapsulation,
    WritableSignal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslocoService } from '@ngneat/transloco';
import { ValidatablePlan } from 'common-typescript';
import { PlanValidationResult } from 'common-typescript/src/plan/validation/planValidationResult';
import {
    CompositeRule,
    CourseUnitRule,
    EntityWithRule,
    ModuleRule,
    OtmId, RangeValidationResultState,
    Rule,
} from 'common-typescript/types';
import _ from 'lodash';
import { LocaleService } from 'sis-common/l10n/locale.service';

import { RULE_SERVICE } from '../../../ajs-upgraded-modules';
import {
    PLAN_ACTIONS_SERVICE_INJECTION_TOKEN,
    PlanActionsService,
} from '../../../plan/plan-actions-service/plan-actions.service';
import { PlanRuleData } from '../../../service/plan-rule-data.service';
import { PlanData, PlanStateObject } from '../../../service/plan-state.service';
import { trackByEntityId } from '../../../util/utils';
import { RuleError, RuleErrorStateService } from '../../rules/rule-error-state.service';
import { RuleClearSignalService } from '../rule-clear-signal.service';

@Component({
    selector: 'sis-plan-structure-composite-rule',
    templateUrl: './plan-structure-composite-rule.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanStructureCompositeRuleComponent implements OnInit, OnChanges {

    planActionsService: PlanActionsService = inject(PLAN_ACTIONS_SERVICE_INJECTION_TOKEN);
    ruleService: any = inject(RULE_SERVICE);
    ruleClearSignalService = inject(RuleClearSignalService);
    ruleErrorStateService = inject(RuleErrorStateService);
    destroyRef = inject(DestroyRef);
    transloco: TranslocoService = inject(TranslocoService);
    localeService: LocaleService = inject(LocaleService);

    // TODO: These input setters can be replaced once input signals are available
    _rule: WritableSignal<CompositeRule> = signal(undefined);
    @Input({ required: true }) set rule(rule: CompositeRule) {
        this._rule.set(rule);
    }

    _parentModule: WritableSignal<EntityWithRule> = signal(undefined);
    @Input({ required: true }) set parentModule(parentModule: EntityWithRule) {
        this._parentModule.set(parentModule);
    }

    _planValidationResult: WritableSignal<PlanValidationResult> = signal(undefined);
    @Input({ required: true }) set planValidationResult(planValidationResult: PlanValidationResult) {
        this.validationChangesInProgress = false;
        this._planValidationResult.set(planValidationResult);
        this.groupRuleUISelectionStates.update(value => {
            const currentActiveRules = this.selectionsUnderCompositeRule();
            // Initial validation results, initialize current selections directly from active rules
            if (!value) {
                return currentActiveRules;
            }
            const updatedSelections = { ...value };
            if (this.isSelectOneRule()) {
                const oldSelected = Object.keys(value).find(key => value[key]);
                const newSelected = Object.keys(currentActiveRules).find(key => currentActiveRules[key]);
                if (newSelected && (oldSelected !== newSelected)) {
                    updatedSelections[oldSelected] = false;
                    this.ruleClearSignalService.sendClearSignal(oldSelected);
                }
            }
            // Group rules that are active should be shown as selected in the UI.
            // Disabling should only be done manually.
            this.sortedAndFilteredRules().forEach(rule => {
                if (rule.type !== 'CourseUnitRule'
                    && rule.type !== 'ModuleRule'
                    && currentActiveRules[rule.localId]) {
                    updatedSelections[rule.localId] = true;
                }
            });
            return updatedSelections;
        });
    }

    @Input({ required: true }) planStateObject: PlanStateObject;
    @Input({ required: true }) planData: PlanData;
    @Input({ required: true }) planRuleData: PlanRuleData;
    _validatablePlan: WritableSignal<ValidatablePlan> = signal(undefined);
    @Input({ required: true }) set validatablePlan(validatablePlan: ValidatablePlan) {
        this._validatablePlan.set(validatablePlan);
    }

    @Input({ required: true }) ruleDepthLevel: number;
    @Input({ required: true }) headingLevel: number;
    @Input({ required: true }) groupPrefix: string;
    @Input({ required: true }) selectionUIState: 'ACTIVE' | 'DISABLED' | 'SELECTABLE' = 'ACTIVE';

    rules: Signal<Rule[]> = computed(() => this._rule()?.rules ?? []);
    sortedAndFilteredRules: Signal<Rule[]> = computed(this.sortedAndFilteredRulesComputation());
    courseUnitAndModuleRules: Signal<Rule[]> = computed(() => _.filter(this.sortedAndFilteredRules(), rule => rule.type === 'CourseUnitRule' || rule.type === 'ModuleRule'));
    groupRules: Signal<Rule[]> = computed(() => _.filter(this.sortedAndFilteredRules(), rule => rule.type === 'CompositeRule'
        || rule.type === 'AnyCourseUnitRule'
        || rule.type === 'AnyModuleRule'
        || rule.type === 'CreditsRule'
        || rule.type === 'CourseUnitCountRule'));

    isSelectOneRule: Signal<boolean> = computed(this.ruleIsSelectOneRule());
    ruleLegendId: Signal<string> = computed(this.ruleLegendIdComputation());
    ruleFocusId: Signal<string> = computed(() => `${this._rule().localId}-focus`);
    ruleValidationResults: Signal<{ [id: string]: any }> = computed(this.ruleValidationResultsComputation());
    selectionsUnderCompositeRule: Signal<{ [id: string]: boolean }> = computed(this.selectionsUnderCompositeRuleComputation());

    readonly trackByFunc = trackByEntityId;

    groupRuleUISelectionStates: WritableSignal<{ [id: string]: boolean }> = signal(undefined);
    ruleErrors: Signal<RuleError[]>;

    validationChangesInProgress = false;

    // Todo: Fix this workaround when the change detection is possibly fixed in Angular 17
    async ngOnChanges() {
        // https://github.com/angular/angular/issues/50320
        await 0;
        this.updateRuleErrors();
    }

    ngOnInit(): void {
        this.ruleErrors = this.ruleErrorStateService.getRuleErrors(this._rule().localId);
        this.ruleClearSignalService.getClearSignal()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((localId) => {
                if (localId === this._rule().localId) {
                    this.sortedAndFilteredRules().forEach(subRule => this.ruleClearSignalService.sendClearSignal(subRule.localId));
                    this.groupRuleUISelectionStates.update(value => {
                        const updatedValue = { ...value };
                        this.sortedAndFilteredRules().forEach(subRule => { updatedValue[subRule.localId] = false; });
                        return updatedValue;
                    });
                }
            });
    }

    updateRuleErrors(): void {
        const ruleValidationResults = this.ruleValidationResults()?.result as unknown as RangeValidationResultState;
        const ruleErrors: RuleError[] = [];
        if (ruleValidationResults) {
            if (ruleValidationResults === 'LESS_REQUIRED') {
                ruleErrors.push({
                    errorId: `${this._rule().localId}-LESS_REQUIRED`,
                    errorType: 'LESS_SELECTIONS_REQUIRED',
                });
            }
        }
        const ruleDisplayName = this.resolveRuleDisplayName();
        this.ruleErrorStateService.updateRuleErrorState({
            ruleDisplayName,
            ruleFocusId: this.ruleFocusId(),
            ruleLocalId: this._rule().localId,
            errors: ruleErrors,
        });
    }

    resolveRuleDisplayName(): string {
        if (this.ruleDepthLevel === 1) {
            return this.localeService.localize(this._parentModule().name);
        }
        return `${this.transloco.translate('PLAN_EDIT.SELECTION_MODAL.RULE_BODY.SELECTION_GROUP')} ${this.groupPrefix}`;
    }

    sortedAndFilteredRulesComputation(): () => Rule[] {
        return () => _.filter(_.sortBy(this.rules(), this.ruleSortOrderFunc),
                              (rule) => this.doesRuleHaveResolvedCourseUnitOrModuleVersion(rule));
    }

    doesRuleHaveResolvedCourseUnitOrModuleVersion(rule: Rule): boolean {
        if (rule.type === 'CourseUnitRule') {
            return !!this.planRuleData.ruleCourseUnitVersionsByGroupId[(rule as CourseUnitRule).courseUnitGroupId];
        }
        if (rule.type === 'ModuleRule') {
            return !!this.planRuleData.ruleModuleVersionsByGroupId[(rule as ModuleRule).moduleGroupId];
        }
        return true;
    }

    ruleIsSelectOneRule(): () => boolean {
        return () => this.ruleService.isSelectOneRule(this._rule());
    }

    ruleLegendIdComputation(): () => string {
        return () => (`rule-legend-${this._rule().localId}`);
    }

    ruleValidationResultsComputation(): () => { [id: string]: { [id: string]: any } } {
        return () => this.getValidationResultsForRule(
            this._planValidationResult()?.ruleValidationResults,
            this._parentModule().id,
            this._rule()?.localId);
    }

    getValidationResultsForRule(ruleValidationResults: any, parentModuleId: OtmId, ruleLocalId: OtmId): any {
        return _.get(ruleValidationResults,
                     [parentModuleId, ruleLocalId]);
    }

    selectionsUnderCompositeRuleComputation(): () => { [id: string]: boolean } {
        return () => {
            const selections: { [id: string]: boolean } = {};
            this._rule().rules.forEach((rule) => {
                const validationResults = this.getValidationResultsForRule(this._planValidationResult()?.ruleValidationResults,
                                                                           this._parentModule()?.id, rule?.localId);
                selections[rule.localId] = validationResults?.active ?? false;
            });
            return selections;
        };
    }

    toggle(change: any, rule: Rule) {
        this.validationChangesInProgress = true;
        const parentModule = this._parentModule();
        if (rule.type === 'CompositeRule') {
            const compositeRule = rule as CompositeRule;
            this.handleCompositeRuleToggle(change, compositeRule, parentModule);
        }
        // Disabling of uistate for composite or any rules needs to be done separately as otherwise
        // these rules can automatically become unselected if last rule under them is disabled.
        this.groupRuleUISelectionStates.update(value => {
            const updatedValue = { ...value };
            if (this.ruleService.isSelectOneRule(this._rule())) {
                // eslint-disable-next-line guard-for-in
                for (const key in updatedValue) {
                    updatedValue[key] = false;
                    if (key !== rule.localId) {
                        this.ruleClearSignalService.sendClearSignal(key);
                    }
                }
            }
            updatedValue[rule.localId] = change;
            return updatedValue;
        });
    }

    handleCompositeRuleToggle(change: boolean, compositeRule: CompositeRule, parentModule: EntityWithRule): void {
        if (change) {
            this.planActionsService.activateRuleGroup(compositeRule, parentModule);
        } else {
            this.planActionsService.deactivateRuleGroup(compositeRule, parentModule);
            this.ruleClearSignalService.sendClearSignal(compositeRule.localId);
        }
    }

    ruleSortOrderFunc(rule: Rule): number {
        switch (rule.type) {
            case 'CourseUnitRule':
            case 'AnyCourseUnitRule':
                return 1;
            case 'ModuleRule':
            case 'AnyModuleRule':
                return 2;
            default:
                return 3;
        }
    }

    resolveGroupUiState(rule: Rule): 'ACTIVE' | 'DISABLED' | 'SELECTABLE' {
        const ruleSelectionState = this.groupRuleUISelectionStates()[rule.localId];
        if (ruleSelectionState) {
            return 'ACTIVE';
        }
        if (this.selectionUIState === 'DISABLED' || this.selectionUIState === 'SELECTABLE') {
            return 'DISABLED';
        }
        return 'SELECTABLE';
    }
}
