(function () {
  initialAppCheck.$inject = ["$log", "planService", "commonPlanService", "commonStudyRightService", "selectEducationModal", "forceLearningOpportunityModal", "$state", "defaultPromiseHandler"];
  angular.module('student.common.service.initialAppCheck', ['student.common.service.planService', 'student.common.service.educationService', 'student.plan', 'student.common.components.forceLearningOpportunityModal']).factory('initialAppCheck', initialAppCheck);

  /**
   * @ngInject
   */
  function initialAppCheck($log, planService, commonPlanService, commonStudyRightService, selectEducationModal,
  //NOSONAR
  forceLearningOpportunityModal, $state, defaultPromiseHandler) {
    return {
      triggerSelectEducationModalIfNoPlans: function (plans) {
        if (plans.length === 0) {
          return selectEducationModal.open().then(function (result) {
            $state.go('student.logged-in.structure', {
              planId: result.planId
            });
          }, function () {
            $log.debug('selectEducationModal cancelled');
          });
        }
      },
      triggerForceLearningOpportunityModalIfNotSelected: function (plans) {
        if (plans.length !== 0) {
          var planWithoutLearningOpportunity = _.find(plans, function (plan) {
            return !plan.learningOpportunityId;
          });
          if (planWithoutLearningOpportunity) {
            commonPlanService.loadPlanRelations(plans, false);
            forceLearningOpportunityModal.open(plans).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
          }
        }
      }
    };
  }
})();