<ng-container *transloco="let t">
  <sis-description-list>
    <dl>
      <ng-template #listItem>
        <dt class="sis-label">{{t('SIS_COMPONENTS.ORGANISATION.ORGANISATION_ROLE_SHARE.RESPONSIBLE_ORGANISATION')}}</dt>
        <dd>
          <sis-organisation-role-share *ngIf="organisations && organisations.length > 0; else noContent"
                                       [hideLabel]="true"
                                       [organisations]="organisations"
                                       [organisationRoleUrn]="RESPONSIBLE_ORGANISATION_ROLE_URN"></sis-organisation-role-share>
        </dd>
      </ng-template>
      <ng-template #listItem>
        <dt class="sis-label">{{t('SIS_COMPONENTS.ORGANISATION.ORGANISATION_ROLE_SHARE.COORDINATING_ORGANISATION')}}</dt>
        <dd>
          <sis-organisation-role-share *ngIf="hasCoordinatingOrganisations; else noContent"
                                       [hideLabel]="true"
                                       [organisations]="organisations"
                                       [organisationRoleUrn]="COORDINATING_ORGANISATION_ROLE_URN"></sis-organisation-role-share>
        </dd>
      </ng-template>
    </dl>
  </sis-description-list>

  <ng-template #noContent>
      <span class="sis-placeholder">
      <sis-icon icon="alert-fill-attention"></sis-icon>{{t('SIS_COMPONENTS.COURSE_UNIT_REALISATION_INFO.NO_INFORMATION_GIVEN')}}
      </span>
  </ng-template>
</ng-container>
