import { Injectable } from '@angular/core';
import { dateUtils } from 'common-typescript';
import moment from 'moment/moment';

export interface EventInstance { start: Date, end: Date, excluded: boolean }
export interface CalendarViewDateRange { start: Date, end: Date }
@Injectable({
    providedIn: 'root',
})
export class FullCalendarEventService {

    isEventInstanceBetweenDateRange(eventInstance: { start: Date | string, end: Date | string }, calendarViewDateRange: CalendarViewDateRange) {
        const eventInstanceStart = moment(eventInstance.start);
        const eventInstanceEnd = moment(eventInstance.end);

        const start = moment(calendarViewDateRange.start);
        const end = moment(calendarViewDateRange.end);

        return dateUtils.dateRangesOverlap(eventInstanceStart, eventInstanceEnd, start, end, true);
    }
}
