import { Injectable } from '@angular/core';
import { Education, LearningOpportunity, LocalizedString, OtmId, Plan } from 'common-typescript/types';
import _ from 'lodash';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

/**
 * Represents selection options in a dropdown menu. Items with the education property set represent group headers
 * (the plans are grouped by education), and items with the plan property set are the actual selectable items.
 */
export interface PlanOption {
    id: OtmId;
    education?: Education;
    plan?: Plan;
    selectedLearningOpportunityName?: LocalizedString;
}

@StaticMembers<DowngradedService>()
@Injectable({ providedIn: 'root' })
export class PlanSelectService {

    static downgrade: ServiceDowngradeMappings = {
        moduleName: 'student.common.service.planSelectService.downgraded',
        serviceName: 'planSelectService',
    };

    constructor(private localeService: LocaleService) {}

    createSelectOptions(plans: Plan[]): PlanOption[] {
        const currentLocale = this.localeService.getCurrentLanguage();
        const plansByEducationId: { [educationId: string]: Plan[] } = _.groupBy(plans, 'rootId');
        const educations: Education[] = _.chain(plans)
            .map('education')
            .compact()
            .uniqBy('id')
            .sortBy(`name.${currentLocale}`)
            .value();

        const options: PlanOption[] = [];
        educations.forEach((education) => {
            options.push({ education, id: education.id });
            options.push(...plansByEducationId[education.id].map(plan => ({
                plan,
                id: plan.id,
                selectedLearningOpportunityName: this.getSelectedLearningOpportunityName(plan),
            })));
        });

        return options;
    }

    getSelectedLearningOpportunityName(plan: Plan): LocalizedString | undefined {
        const learningOpportunity: LearningOpportunity = (_.get(plan, 'education.structure.learningOpportunities') || [])
            .find((lo: LearningOpportunity) => lo.localId === plan.learningOpportunityId);
        return learningOpportunity ? learningOpportunity.name : undefined;
    }
}
