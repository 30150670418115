import { ChangeDetectionStrategy, Component, inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { StateService } from '@uirouter/core';
import {
    Attainment,
    DegreeProgrammeAttainmentWorkflow,
    FinnishAddress,
    GenericAddress,
    OtmId,
    WorkflowState,
} from 'common-typescript/types';
import _ from 'lodash';
import { Observable, take } from 'rxjs';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { HeaderService } from 'sis-components/header/header.service';
import { AttainmentEntityService, filterLatestAttainment } from 'sis-components/service/attainment-entity.service';

import { workflowCancellationDialogOpener } from '../cancel-workflow-dialog/cancel-workflow-dialog.component';

function createAttainmentByWorkflowIdFetcher() {
    const appErrorHandler = inject(AppErrorHandler);
    const attainmentEntityService = inject(AttainmentEntityService);
    return (workflowId: OtmId) => attainmentEntityService.getByWorkflowId(workflowId)
        .pipe(take(1), filterLatestAttainment(), appErrorHandler.defaultErrorHandler());
}

@Component({
    selector: 'app-degree-programme-attainment-workflow',
    templateUrl: './degree-programme-attainment-workflow.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DegreeProgrammeAttainmentWorkflowComponent implements OnInit {

    @Input() studentWorkflow: DegreeProgrammeAttainmentWorkflow;
    latestAttainment$: Observable<Attainment>;
    attainmentByWorkflowIdFetcher = createAttainmentByWorkflowIdFetcher();

    headerService = inject(HeaderService);
    stateService = inject(StateService);
    private readonly openCancelWorkflowDialog = workflowCancellationDialogOpener();

    ngOnInit(): void {
        this.headerService.setApplicationStateBadge(this.studentWorkflow);
        this.latestAttainment$ = this.attainmentByWorkflowIdFetcher(this.studentWorkflow?.id);
    }

    openCancelDialog() {
        this.openCancelWorkflowDialog({
            workflowId: this.studentWorkflow?.id,
            workflowType: this.studentWorkflow?.type,
            successCallback: () => this.stateService.reload(),
        });
    }

    isWorkflowRequestedOrInHandling() {
        return (['REQUESTED', 'IN_HANDLING'] as WorkflowState[]).includes(this.studentWorkflow?.state);
    }

    isWorkflowCancelled() {
        return this.studentWorkflow?.state === 'CANCELLED';
    }

    isWorkflowRejected() {
        return this.studentWorkflow?.state === 'REJECTED';
    }

    isWorkflowAcceptanceRevoked() {
        return this.studentWorkflow?.state === 'ACCEPTANCE_REVOKED';
    }

    getResolutionRationale() {
        return this.studentWorkflow?.decision?.resolutionRationale;
    }

    isDeliveryByPickUp() {
        return this.studentWorkflow?.degreeDeliveryMethod === 'PICK_UP';
    }

    isDeliveryByMail() {
        return this.studentWorkflow?.degreeDeliveryMethod === 'MAIL';
    }

    isDigitalDegreeCertificatePreferred() {
        return this.studentWorkflow?.degreeDeliveryMethod === 'DIGITAL_CERTIFICATE';
    }

    isDeliveryAddressInFinland() {
        return this.studentWorkflow?.deliveryAddress?.type === 'FinnishAddress';
    }

    getStreetAddress() {
        return (this.studentWorkflow?.deliveryAddress as FinnishAddress)?.streetAddress;
    }

    getPostalCode() {
        return (this.studentWorkflow?.deliveryAddress as FinnishAddress)?.postalCode;
    }

    getCity() {
        return (this.studentWorkflow?.deliveryAddress as FinnishAddress)?.city;
    }

    getAddress() {
        return (this.studentWorkflow?.deliveryAddress as GenericAddress)?.address;
    }

    shouldShowAlumniAssociationInfo() {
        return !_.isNil(this.studentWorkflow?.joinsAlumniAssociation);
    }

    shouldShowOtherInfo() {
        return !!this.studentWorkflow?.additionalInfo || !!this.studentWorkflow?.questionnaireAnswers;
    }
}

