<sis-contextual-notification *ngIf="hasPhase1Personalized && !hasPhase2Personalized" variant="warning">
  <span [translateParams]="{phaseNames: education.structure.phase1.name|localizedString}"
          translate>PROFILE.STUDY_PATH.PERSONALIZED_SELECTION_PATH</span>
  <ng-template [ngTemplateOutlet]="openModalButton"></ng-template>
</sis-contextual-notification>
<sis-contextual-notification variant="warning" *ngIf="!hasPhase1Personalized && hasPhase2Personalized">
  <span [translateParams]="{phaseNames: education.structure.phase2.name|localizedString}" translate>
    PROFILE.STUDY_PATH.PERSONALIZED_SELECTION_PATH</span>
  <ng-template [ngTemplateOutlet]="openModalButton"></ng-template>
</sis-contextual-notification>
<sis-contextual-notification *ngIf="hasPhase1Personalized && hasPhase2Personalized" variant="warning">
    <span [translateParams]="{phaseNames: ((education.structure.phase1.name|localizedString) + ' ' + ('COMMON.AND'|translate) + ' ' + (education.structure.phase2.name|localizedString))}" translate>
    PROFILE.STUDY_PATH.PERSONALIZED_SELECTION_PATH</span>
  <ng-template [ngTemplateOutlet]="openModalButton"></ng-template>
</sis-contextual-notification>
<ng-template #openModalButton>
  <button type="button" class="link-button"
          (click)="showRationale()"
          data-cy="show-personalization-rationale-button" translate>
    PROFILE.STUDY_PATH.SHOW_PERSONALIZED_SELECTION_RATIONALE
  </button>
</ng-template>
