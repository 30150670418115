import { UniversityConfig } from 'common-typescript/types';
import { NGXLogger, NgxLoggerLevel } from 'ngx-logger';

declare let universityConfig: UniversityConfig;

export const HIGHEST_LOGGING_LEVEL = NgxLoggerLevel.LOG;

export function setUpLogging(logger: NGXLogger) {
    const loggingLevel = getLoggingLevel();
    logger.updateConfig({ level: loggingLevel });
}

/**
 * Determines the logging level with a similar logic to the `configureLogs` method in angularApp.js.
 */
function getLoggingLevel(): NgxLoggerLevel {
    if (universityConfig.isInfoEnabled !== undefined && !universityConfig.isInfoEnabled) {
        return HIGHEST_LOGGING_LEVEL;
    }
    if (!universityConfig.isDebugEnabled) {
        return NgxLoggerLevel.INFO;
    }
    return NgxLoggerLevel.DEBUG;
}
