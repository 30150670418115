<ng-container *ngIf="variant === 'open-university-cart' && (enableCartObservable | async)">
  <ng-container *ngTemplateOutlet="openUniversityCartDesktop"/>
</ng-container>
<ng-container *ngIf="variant === 'messages'">
  <ng-container *ngTemplateOutlet="mobileMode ? messagesMobile : messagesDesktop"/>
</ng-container>
<ng-container *ngIf="variant === 'notes'">
  <ng-container *ngTemplateOutlet="mobileMode ? notesMobile : notesDesktop"/>
</ng-container>
<ng-container *ngIf="variant === 'help'">
  <ng-container *ngTemplateOutlet="mobileMode ? helpMobile : helpDesktop"/>
</ng-container>
<ng-container *ngIf="variant === 'feedback'">
  <ng-container *ngTemplateOutlet="mobileMode ? feedbackMobile : feedbackDesktop"/>
</ng-container>
<ng-template #openUniversityCartDesktop>
  <a class="top-navigation-icon top-navigation-icon__desktop"
     [class.top-navigation-icon__desktop--active]="isActive"
     [attr.aria-current]="isActive && 'page'"
     [attr.aria-label]="name + ', ' +  ((notificationObservable | async) || 0) + ' ' + ariaLabel"
     [attr.data-cy]="dataCy"
     [uiSref]="'student.open-university-cart'"
     fudisTooltip
     [tooltip]="name">
    <fudis-icon [icon]="'shopping-cart'" [color]="isActive ? 'white' : 'gray-dark'"/>
    <sis-notification-badge *ngIf="notificationObservable | async as notificationCount"
                            [value]="notificationCount"
                            class="top-navigation-icon__desktop__badge"/>
  </a>
</ng-template>
<ng-template #messagesDesktop>
  <a class="top-navigation-icon top-navigation-icon__desktop"
     [class.top-navigation-icon__desktop--active]="isActive"
     [attr.data-cy]="dataCy"
     [attr.aria-current]="isActive && 'page'"
     [href]="path()"
     [attr.aria-label]="name + ', ' +  ((notificationObservable | async) || 0) + ' ' + ariaLabel"
     fudisTooltip
     [tooltip]="name">
    <fudis-icon [icon]="'mail'" [color]="isActive ? 'white' : 'gray-dark'"/>
    <sis-notification-badge *ngIf="notificationObservable | async as notificationCount"
                            [value]="notificationCount"
                            [attr.data-cy]="'message-notifications'"
                            class="top-navigation-icon__desktop__badge"/>
  </a>
</ng-template>

<ng-template #messagesMobile>
  <a class="top-navigation-icon top-navigation-icon__mobile"
     [class.top-navigation-icon__mobile--active]="isActive"
     [attr.data-cy]="dataCy"
     [attr.aria-current]="isActive && 'page'"
     [href]="path()"
     [attr.aria-label]="name + ', ' +  ((notificationObservable | async) || 0) + ' ' + ariaLabel"
     (blur)="blurMobileMenuIcon.emit($event)">{{ name }}
    <sis-notification-badge *ngIf="notificationObservable | async as notificationCount"
                            [value]="notificationCount"
                            [attr.data-cy]="'message-notifications'"
                            class="top-navigation-icon__mobile__badge"/>
    <fudis-icon *ngIf="isActive" [icon]="'check'" [color]="'primary'" class="float-end top-navigation-icon__mobile__check"/>
  </a>
</ng-template>
<ng-template #notesDesktop>
  <a class="top-navigation-icon top-navigation-icon__desktop"
     [class.top-navigation-icon__desktop--active]="isActive"
     [attr.data-cy]="dataCy"
     [attr.aria-current]="isActive && 'page'"
     [uiSref]="'student.logged-in.course-cart'"
     [attr.aria-label]="name"
     fudisTooltip
     [tooltip]="name">
    <fudis-icon [icon]="'notebook'" [color]="isActive ? 'white' : 'gray-dark'"/>
  </a>
</ng-template>

<ng-template #notesMobile>
  <a class="top-navigation-icon top-navigation-icon__mobile"
     [class.top-navigation-icon__mobile--active]="isActive"
     [attr.data-cy]="dataCy"
     [attr.aria-current]="isActive && 'page'"
     [uiSref]="'student.logged-in.course-cart'"
     (blur)="blurMobileMenuIcon.emit($event)">{{ name }}
    <fudis-icon *ngIf="isActive" [icon]="'check'" [color]="'primary'" class="float-end top-navigation-icon__mobile__check"/>
  </a>
</ng-template>
<ng-template #helpDesktop>
  <a class="top-navigation-icon top-navigation-icon__desktop"
     [href]="path()"
     target="_blank"
     rel="noopener noreferrer"
     [attr.data-cy]="dataCy"
     [attr.aria-label]="ariaLabel"
     fudisTooltip
     [tooltip]="name">
    <fudis-icon [icon]="'question-mark'"/>
  </a>
</ng-template>

<ng-template #helpMobile>
  <a class="top-navigation-icon top-navigation-icon__mobile"
     [href]="path()"
     target="_blank"
     rel="noopener noreferrer"
     [attr.data-cy]="dataCy"
     [attr.aria-label]="ariaLabel"
     (blur)="blurMobileMenuIcon.emit($event)">{{ name }}
    <fudis-icon [icon]="'new-tab'" class="float-end"/>
  </a>
</ng-template>
<ng-template #feedbackDesktop>
  <button class="top-navigation-icon top-navigation-icon__desktop"
          [attr.data-cy]="dataCy"
          [attr.aria-label]="name"
          fudisTooltip
          [tooltip]="name"
          (click)="openFeedbackModal()">
    <fudis-icon [icon]="'star'"/>
  </button>
</ng-template>

<ng-template #feedbackMobile>
  <button class="top-navigation-icon top-navigation-icon__mobile"
          [attr.data-cy]="dataCy"
          (click)="openFeedbackModal()"
          (blur)="blurMobileMenuIcon.emit($event)">{{ name }}</button>
</ng-template>
