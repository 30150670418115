import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { getLabelState } from '../../form/formUtils';

@Component({
    selector: 'sis-text-field',
    templateUrl: './text-field.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class TextFieldComponent implements OnInit, OnDestroy, OnChanges {

    destroyed$ = new Subject<void>();

    @Input() id?: string;

    @Input() control: FormControl;
    /** The content of the `<label>` element that wraps the form control */
    @Input() label?: string;
    /** The content of the `<label>` element that wraps the form control (markup-string) */
    @Input() labelHTML?: string;
    /** If true, the label element will have the form validation status icons */
    @Input() showLabelIcon = true;
    @Input() name: string;
    /** The type of the input field to use. Ignored when using a `textarea`. */
    @Input() type?: 'email' | 'number' | 'password' | 'tel' | 'text' | 'url' = 'text';
    /** The placeholder text to show in the UI if the form control has no value */
    @Input() placeholder: string;
    /** If true, a `<textarea>` will be rendered instead of an `<input>` */
    @Input() textarea = false;
    /** Translation key for additional help-block */
    @Input() helpBlock?: string;
    /** Translation key for additional help-block containing HTML */
    @Input() helpBlockHTML?: string;
    /** If text is setted shows info tooltip */
    @Input() guidanceText: string;
    /** A string displayd next to input field, usually credit points */
    @Input() valueType: string;
    /** An autocomplete suggestion to the input/textarea field.
     * See https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete */
    @Input() autocomplete?: string;
    @Input() disabled: boolean;

    popoverShown: boolean;
    isRequired: boolean;

    ngOnInit(): void {
        this.control.valueChanges
            .pipe(takeUntil(this.destroyed$))
            .subscribe((value) => {
                if (typeof value === 'string' && _.isEmpty(value.trim())) {
                    this.control.setValue(null);
                }
            });
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.control) {
            this.isRequired = changes.control.currentValue?.errors?.required !== null;
        }
    }

    get labelState(): string {
        return this.showLabelIcon ? getLabelState(this.control) : '';
    }

    isValidationErrorBlockVisible() {
        if (_.get(this.control, 'updateOn') === 'change') {
            return (this.control.touched || this.control.dirty) && this.control.invalid;
        }
        return this.control.touched && this.control.invalid;
    }
}
