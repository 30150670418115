import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AssessmentItem, CompletionMethod, CourseUnit, OtmId } from 'common-typescript/types';
import _ from 'lodash';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { CreditRangePipe } from 'sis-components/number/credit-range.pipe';
import { AssessmentItemEntityService } from 'sis-components/service/assessment-item-entity.service';

export interface CompletionMethodListItem {
    listIndex: number,
    completionMethod: CompletionMethod,
    assessmentItems: AssessmentItem[],
}

@Component({
    selector: 'app-completion-methods-list',
    templateUrl: './completion-methods-list.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompletionMethodsListComponent implements OnInit {
    @Input() courseUnit: CourseUnit;
    completionMethodListData$: Observable<CompletionMethodListItem[]>;
    selectedCompletionMethodListItem$: Subject<CompletionMethodListItem> = new Subject<CompletionMethodListItem>();

    constructor(
        private assessmentItemEntityService: AssessmentItemEntityService,
        private appErrorHandler: AppErrorHandler,
        private localeService: LocaleService,
        private translate: TranslateService,
        private creditRangePipe: CreditRangePipe,
    ) {}

    ngOnInit(): void {
        const sortedAssessmentItemIds = this.getSortedAssessmentItemIds(this.courseUnit);
        this.completionMethodListData$ = this.assessmentItemEntityService.getByIds(sortedAssessmentItemIds)
            .pipe(
                map(items => this.courseUnit.completionMethods.map((method, index) => ({
                    listIndex: index,
                    completionMethod: method,
                    assessmentItems: items.filter(item => method.assessmentItemIds.includes(item.id)),
                } as CompletionMethodListItem))),
                this.appErrorHandler.defaultErrorHandler(),
            );
    }

    getAssessmentItemNameForCompletionMethod(completionMethodListItem: CompletionMethodListItem): string {
        const translatedNames: string[] = [];
        for (const assessmentItem of completionMethodListItem.assessmentItems) {
            translatedNames.push(
                `${this.localeService.localize(assessmentItem.name)} (${this.creditRangePipe.transform(assessmentItem.credits)})`,
            );
        }
        if (completionMethodListItem.completionMethod.typeOfRequire === 'ALL_SELECTED_REQUIRED') {
            return translatedNames.join(` ${this.translate.instant('COMMON.AND')} `);
        }
        return translatedNames.join(', ');
    }

    select(completionMethodListItem: CompletionMethodListItem): void {
        this.selectedCompletionMethodListItem$.next(completionMethodListItem);
    }

    private getSortedAssessmentItemIds(courseUnit: CourseUnit) {
        const ids: OtmId[] = _.flatMap(this.courseUnit.completionMethods, completionMethod => completionMethod.assessmentItemIds);
        if (!_.isEmpty(courseUnit.assessmentItemOrder)) {
            const ignored: OtmId[] = [];
            _.forEach(courseUnit.assessmentItemOrder, id => {
                if (_.indexOf(ids, id) === -1) {
                    ignored.push(id);
                }
            });
            const matchingOrderedIds: OtmId[] = _.difference(courseUnit.assessmentItemOrder, ignored);
            const nonOrderedIds: OtmId[] = _.difference(ids, matchingOrderedIds);
            return _.concat(matchingOrderedIds, nonOrderedIds);
        }
        return ids;
    }

}
