export function setEventHandlersForEditMode(): void {
    document.addEventListener('keydown', focusHandlerInEditMode.bind(this));
}

function focusHandlerInEditMode(event: KeyboardEvent): void {
    if (event.key === 'Tab') {
        const focusableElements = 'a[href]:not([disabled]),' +
            ' select:not([disabled]),' +
            ' button:not([disabled]),' +
            ' input:not([disabled]),' +
            ' textarea:not([disabled]),' +
            ' div[role="textbox"]:not([disabled]),' +
            ' [tabindex]:not([disabled]):not([tabindex="-1"])';

        const containerElement = document.getElementById('edit-mode-modal');

        if (containerElement) {
            const currentElement = event.target as HTMLElement;
            const focusable = Array.prototype.filter.call(containerElement.querySelectorAll(focusableElements),
                                                          (element: HTMLElement) => element.offsetWidth > 0
                    || element.offsetHeight > 0
                    || element === currentElement);

            const index = focusable.indexOf(currentElement);

            if (index > -1) {
                const nextIndex = event.shiftKey ? index - 1 : index + 1;
                const startIndex = event.shiftKey ? focusable.length - 1 : 0;

                if ((nextIndex <= focusable.length || nextIndex >= 0)
                    || (startIndex === 0 || startIndex === focusable.length)) {
                    event.preventDefault();
                    const nextElement = focusable[nextIndex] || focusable[startIndex];
                    nextElement.focus();
                }
            }
        }
    }
}
