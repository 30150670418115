import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import {
    EnrolmentCalculationConfig,
    EnrolmentSelectedUsersQuota,
} from 'common-typescript/types';
import {
    ComponentDowngradeMappings,
    DowngradedComponent,
    StaticMembers,
} from 'sis-common/types/angular-hybrid';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-course-unit-realisation-seats',
    templateUrl: './course-unit-realisation-seats.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CourseUnitRealisationSeatsComponent implements OnChanges {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.courseUnitInfoModal.courseUnitRealisation.seats.downgraded',
        directiveName: 'sisCourseUnitRealisationSeats',
    };

    /**
     * Determines heading's semantic aria-level for screen readers, default is equivalent for h4
     */
    @Input() level?: number = 4;
    @Input() enrolmentCalculationConfig: EnrolmentCalculationConfig;

    maxSelected: number;
    selectedUsersQuotas: EnrolmentSelectedUsersQuota[] = [];
    maximumUsersQuotas: EnrolmentSelectedUsersQuota[] = [];

    ngOnChanges() {
        this.maxSelected = this.enrolmentCalculationConfig?.maxSelected;
        this.selectedUsersQuotas = this.enrolmentCalculationConfig?.selectedUsersQuotas ?? [];
        this.maximumUsersQuotas = this.enrolmentCalculationConfig.maximumUsersQuotas ?? [];
    }
}
