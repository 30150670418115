import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewEncapsulation,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import angular from 'angular';
import { Code, Urn } from 'common-typescript/types';
import _ from 'lodash';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { CommonCodeService, GroupedCodes, IndexedCodes } from '../service/common-code.service';

/**
 * An Angular version of the old AngularJS `<code-select-editor>` component. Allows selecting a single Code urn from a
 * given code book. Works in both AngularJS and Angular.
 *
 * If you're looking for a code selection component for an Angular reactive form, check out `CodeSelectComponent` instead.
 */
@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-code-select-editor',
    templateUrl: './code-select-editor.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CodeSelectEditorComponent implements OnInit, OnChanges {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.select.codeSelectEditor',
        directiveName: 'sisCodeSelectEditor',
    };

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input() name: string;
    @Input() codebookUrn: Urn;
    @Input() selectedUrn: Urn;
    // You should create a new array of excludeCodes and includeCodes every time the content of array changes. Otherwise onChanges does
    // not detect the change and furthermore the available codes (in dropdown) don't get updated.
    @Input() excludeCodes: Urn[];
    @Input() includeCodes: Urn[];
    // Allow showing an empty selection list instead of fallback to showing all code values
    @Input() allowEmptyCodes = false;
    // Excludes those codes that do not have isLeafNode: true
    @Input() excludeNonLeafNodes: boolean;
    @Input() disabled: boolean;
    @Input() required: boolean;
    @Input() showAllLanguageVersions: boolean;
    @Input() allowDeprecatedValueSelection: boolean;
    @Input() showLastPart: boolean;
    @Input() showNumericalCodeValue: boolean;
    @Input() invalid: boolean;
    // Way to show required error if the field is missing values. Should not be used reactive forms, instead using sis-validation -component after this will suffice.
    @Input() showErrors = false;
    @Input() id?: string;
    // to pair label with related input, labelForId is to target Input element rendered by ng-select used by code-selection-editor
    @Input() labelForId?: string;
    // AngularJs components which uses this components downgraded version have a problems with change detection when user changes the value.
    // Value change(ngOnChanges) is triggered multiple seconds too late and it also does double change: first it passes the initial selected urn and
    // right after that null -value. That's why we want to enable selectedUrn Change detection only when this flag is enabled.
    @Input() allowSelectedUrnChangeDetection: boolean;
    @Input() notFoundKey?: string;

    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onSelectCode = new EventEmitter<{ selectedUrn: Urn }>();
    @Input() groupResults = true;

    /**
     * The $scope instance of the containing AngularJS component. Required when this component is used in an AngularJS
     * component; irrelevant otherwise. Without this the AngularJS change detection will not pick up the changes made
     * in this component.
     */
    @Input() scope: angular.IScope;

    selectedCode: Code;
    universityCodes: Code[];
    otherCodes: Code[];
    groupedCodes: GroupedCodes;
    filterTranslations: { [key: string]: string };
    ready: boolean;
    validators: { [key: string]: { [k: string]: any } };

    constructor(private commonCodeService: CommonCodeService,
                private translate: TranslateService,
                private ref: ChangeDetectorRef) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ((changes.includeCodes || changes.excludeCodes) && this.groupedCodes) {
            this.initCodes(this.groupedCodes);
        }

        if (this.allowSelectedUrnChangeDetection && changes.selectedUrn) {
            setTimeout(() => {
                this.initSelectedCode(changes.selectedUrn.currentValue);
            });
        }
    }

    ngOnInit() {
        const groupPromise = this.commonCodeService.getGroupedCodes(this.codebookUrn);

        Promise.resolve(groupPromise)
            .then((groupedCodes) => {
                this.groupedCodes = groupedCodes;
                this.initProperties();
            });
    }

    hasSelectionOrNotRequired() {
        return !this.required ? true : !_.isNil(this.selectedUrn);
    }

    initProperties() {
        this.filterTranslations = {
            myUniversity: this.translate.instant('SIS_COMPONENTS.SELECT.MY_UNIVERSITY'),
            otherUniversities: this.translate.instant('SIS_COMPONENTS.SELECT.OTHER_UNIVERSITIES'),
            tooManyResults: this.translate.instant('SIS_COMPONENTS.SELECT.TOO_MANY_SEARCH_RESULTS'),
        };
        if (this.groupedCodes) {
            this.initCodes(this.groupedCodes);
        }
        if (this.universityCodes && this.otherCodes) {
            this.initSelectedCode(this.selectedUrn);
        }
        this.ready = true;
        this.ref.detectChanges();
    }

    initCodes(groupedCodes: GroupedCodes) {
        const otherCodes = groupedCodes.otherCodes;
        const universityCodes = groupedCodes.universityCodes;

        this.otherCodes = this.filterCodes(otherCodes);
        this.universityCodes = this.filterCodes(universityCodes);
    }

    filterCodes(codes: IndexedCodes) {
        const excludedCodesWithoutSelectedUrn = _.filter(this.excludeCodes, code => code !== this.selectedUrn);
        const includeCodesEmptyFallback = this.allowEmptyCodes ? _.stubFalse : _.stubTrue;
        return _.chain(_.values(codes))
            .filter(this.excludeNonLeafNodes ? code => _.get(code, 'isLeafNode') : _.stubTrue)
            .filter(_.isEmpty(this.includeCodes) ? includeCodesEmptyFallback : code => _.includes(this.includeCodes, _.get(code, 'urn')))
            .filter(code => !_.includes(excludedCodesWithoutSelectedUrn, _.get(code, 'urn')))
            .value();
    }

    initSelectedCode(urn: Urn) {
        // If a code that university hadn't selected was chosen as the value, and the university has since decided
        // to hide unselected values from the selector, it is possible that the selected value is not found from
        // universityCodes. Because of this, search from both arrays so the value can always be shown on the UI.
        const codes = this.universityCodes ? this.universityCodes.concat(this.otherCodes) : this.otherCodes;
        this.setSelectedCode(_.find(codes, { urn }));
    }

    setSelectedCode(selectedCode: Code) {
        if (this.selectedCode?.urn !== selectedCode?.urn) {
            this.selectedCode = selectedCode;
            if (this.scope) {
                this.scope.$apply(() => this.onSelectCode.emit({ selectedUrn: _.get(selectedCode, 'urn', null) }));
            } else {
                this.onSelectCode.emit({ selectedUrn: _.get(selectedCode, 'urn', null) });
            }
        }
        this.ref.detectChanges();
    }

    selectCode(selectedCode: { code: Code; previous: Code }) {
        this.setSelectedCode(selectedCode.code);
    }

    getAllCodes() {
        return _.map(this.otherCodes.concat(this.universityCodes), 'urn').sort();
    }
}
