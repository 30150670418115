suggestAddMessage.$inject = ["$scope", "$timeout", "SELECTION_TYPES", "universityService", "commonModuleService", "commonCourseUnitService"];
import angular from 'angular';
import _ from 'lodash';
import { CourseUnitSearchComponent } from '../courseUnit/course-unit-search/course-unit-search.component.ts';
import { CourseUnitBoxComponent } from '../courseUnit/course-unit-box/course-unit-box.component.ts';
import { ModuleSearchComponent } from '../module/module-search/module-search.component.ts';
import { ModuleBoxComponent } from '../module/module-box/module-box.component.ts';
import { PlanSelectorConfig } from '../plan/planSelectorConfig.ts';
import { UniversitySelectEditorComponent } from '../university/university-select-editor/university-select-editor.component.ts';
import suggestAddMessageTpl from './suggestAddMessage.tpl.html';
export const suggestAddMessageServiceComponentModule = 'tutor.student.tutoring.plan.suggestAddMessageServiceComponent';
angular.module(suggestAddMessageServiceComponentModule, ['sis-components.plan.planSelectorService', 'sis-components.service.moduleService', 'sis-components.service.courseUnitService', 'sis-common.l10n.localizedStringFilter', 'sis-components.date.yearRangeFilter', 'sis-components.courseUnit', CourseUnitSearchComponent.downgrade.moduleName, ModuleSearchComponent.downgrade.moduleName, ModuleBoxComponent.downgrade.moduleName, CourseUnitBoxComponent.downgrade.moduleName, UniversitySelectEditorComponent.downgrade.moduleName]).component('suggestAddMessageModal', {
  template: suggestAddMessageTpl,
  controller: suggestAddMessage,
  bindings: {
    close: '&',
    dismiss: '&',
    resolve: '<'
  }
});
function suggestAddMessage($scope, $timeout, SELECTION_TYPES, universityService, commonModuleService, commonCourseUnitService) {
  const $ctrl = this;
  $ctrl.updateState = () => {
    $ctrl.showCancel = $ctrl.state === 0;
    $ctrl.showBack = $ctrl.state !== 0;
    $ctrl.showNext = $ctrl.state < 2;
    $ctrl.showSave = $ctrl.state === 2;
    $ctrl.nextEnabled = false;
    if ($ctrl.state === 0) {
      $ctrl.nextEnabled = _.get($ctrl.selectedTarget, 'type') === SELECTION_TYPES.ANY_COURSE_UNIT || _.get($ctrl.selectedTarget, 'type') === SELECTION_TYPES.ANY_MODULE;
    } else if ($ctrl.state > 0) {
      $ctrl.nextEnabled = !!_.get($ctrl.suggestedObject, 'id');
    }
    $ctrl.showCourseUnitSearch = _.get($ctrl.selectedTarget, 'type') === SELECTION_TYPES.ANY_COURSE_UNIT;
  };
  $ctrl.back = () => {
    $ctrl.state -= 1;
    $ctrl.updateState();
  };
  $ctrl.next = () => {
    $ctrl.state += 1;
    $ctrl.updateState();
  };
  $ctrl.save = () => {
    $ctrl.close({
      $value: {
        targetModule: $ctrl.targetModule,
        suggestedObject: $ctrl.suggestedObject,
        selectedTarget: $ctrl.selectedTarget
      }
    });
  };
  $ctrl.cancel = () => {
    $ctrl.dismiss();
  };
  $ctrl.selectTarget = selectedObject => {
    if (_.get($ctrl.selectedTarget, 'type') !== _.get(selectedObject, 'type')) {
      $ctrl.suggestedObject = undefined;
    }
    $ctrl.selectedTarget = selectedObject;
    commonModuleService.findById(selectedObject.parentId).then(module => {
      $ctrl.targetModule = module;
    });
    $ctrl.updateState();
    $scope.$digest();
  };
  $ctrl.selectSuggestedObject = suggestedObject => {
    if (suggestedObject) {
      if ($ctrl.showCourseUnitSearch) {
        commonCourseUnitService.findById(suggestedObject.id, {
          loadRelations: true
        }).then(courseUnit => {
          $ctrl.suggestedObject = courseUnit;
          $timeout(() => $ctrl.updateState());
        });
      } else {
        commonModuleService.findById(suggestedObject.id).then(module => {
          $ctrl.suggestedObject = module;
          $timeout(() => $ctrl.updateState());
        });
      }
    } else {
      $ctrl.suggestedObject = undefined;
      $timeout(() => $ctrl.updateState());
    }
  };
  $ctrl.universityOnChange = university => {
    // We assume that course unit or module search will clear selected object for us if needed (with callback)
    $ctrl.university = university;
  };
  $ctrl.$onInit = () => {
    $ctrl.state = 0;
    $ctrl.selectedTarget = undefined;
    $ctrl.targetModule = undefined;
    $ctrl.suggestedObject = undefined;
    $ctrl.plan = $ctrl.resolve.plan;
    $ctrl.validatablePlan = $ctrl.resolve.validatablePlan;
    $ctrl.planSelectorConfig = new PlanSelectorConfig();
    $ctrl.planSelectorConfig.allowModuleSelection = false;
    $ctrl.planSelectorConfig.allowCourseUnitSelection = false;
    $ctrl.planSelectorConfig.allowAnyCourseUnitRuleSelection = true;
    $ctrl.planSelectorConfig.allowAnyModuleRuleSelection = true;
    $ctrl.localCourseUnitSearchService = {
      search(params) {
        params.setOverridingDefaults({
          validity: 'ONGOING_AND_FUTURE'
        });
        return commonCourseUnitService.searchActive(params);
      }
    };
    $ctrl.updateState();
  };
}