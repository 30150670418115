import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { OpenUniversityCart } from 'common-typescript/types';

@Component({
    selector: 'app-cart-price',
    templateUrl: './cart-price.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartPriceComponent {

    @Input() cart: OpenUniversityCart;
    @Input() showOnlySummary?: boolean = false;
    @Input() alignRight?: boolean = false;

    readonly zeroPrice = 'EUR 0.00';
}
