<ng-container
  *ngIf="{
     isMobileView: isMobileView$ | async,
     enrolment: enrolment$ | async
   } as props">
  <sis-expandable *ngIf="courseUnitRealisation"
                  [title]="(courseUnitRealisation.name | localizedString) + ' ' + (courseUnitRealisation.activityPeriod | localDateRange)"
                  [level]="4"
                  [variant]="'naked-dotted'">

    <ng-template sisExpandableCustomTitle>
      <app-enrolment-status-badge *ngIf="props.enrolment" [enrolment]="props.enrolment"></app-enrolment-status-badge>
      <app-enrolment-period-badge *ngIf="!props.enrolment" [courseUnitRealisation]="courseUnitRealisation"></app-enrolment-period-badge>
    </ng-template>

    <ng-template *ngIf="!props.isMobileView" sisExpandableHeaderButtons>
      <sis-button *ngIf="!props.enrolment && isEnrolmentAllowed(); else notSelectable"
                  (clicked)="enrolClicked.emit()"
                  size="sm"
                  data-cy="enrol-button">
        {{'ENROLMENT.ENROL' | translate}}
      </sis-button>
    </ng-template>

    <ng-template sisExpandableContent>
      <div *ngIf="props.isMobileView" class="mb-5">
        <sis-button *ngIf="!props.enrolment && isEnrolmentAllowed(); else notSelectable"
                    (clicked)="enrolClicked.emit()"
                    data-cy="enrol-button">
          {{'ENROLMENT.ENROL' | translate}}
        </sis-button>
      </div>
      <course-unit-realisation-info [courseUnitRealisation]="courseUnitRealisation"
                                    [courseUnit]="courseUnit">
      </course-unit-realisation-info>
    </ng-template>
  </sis-expandable>
</ng-container>

<ng-template #notSelectable>
  <span class="title-info guidance" data-cy="enrolment-disabled-guidance">
    {{'ENROLMENTS.OPEN_UNIVERSITY_ENROLMENT.TEACHING_NOT_SELECTABLE' | translate}}
  </span>
</ng-template>
