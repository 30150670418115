export const findByIdsHelperModule = 'student.common.utils.findByIdsHelper';
'use strict';
(function () {
  findByIds.$inject = ["$http", "$q", "findByIdsBatchUriLength", "$log"];
  angular.module(findByIdsHelperModule, []).constant('findByIdsBatchUriLength', 1900).factory('findByIdsHelper', findByIds);

  /**
   * Split too long findByIds requests into smaller batches and combine the results.
   * Remove duplicates. Return empty array if ids is empty.
   *
   * @ngInject
   */
  function findByIds($http, $q, findByIdsBatchUriLength, $log) {
    return function (baseUri, ids, paramName, otherParams) {
      if (ids && !_.isArray(ids)) {
        $log.warn('Expected ids in an array.');
      }
      paramName = paramName ? paramName : 'id';
      otherParams = otherParams ? otherParams + '&' : '';
      if (_.isEmpty(ids)) {
        return $q.when([]);
      }
      var promises = [];
      var duplicates = {};
      var uri;
      for (var i = 0; i < ids.length; i++) {
        var id = ids[i];
        if (!duplicates[id]) {
          duplicates[id] = true;
          if (uri) {
            uri += '&';
          } else {
            uri = baseUri + '?' + otherParams;
          }
          uri += paramName + '=' + id;
          if (uri.length >= findByIdsBatchUriLength) {
            promises.push($http.get(uri));
            uri = null;
          }
        }
      }
      if (uri) {
        promises.push($http.get(uri));
      }
      return $q.all(promises).then(function (results) {
        var fetched = _.flatten(_.map(results, 'data'));
        return _.sortBy(fetched, function (obj) {
          return _.findIndex(ids, function (id) {
            return obj.id === id;
          });
        });
      });
    };
  }
})();