import angular from 'angular';
import * as _ from 'lodash';
import { ruleServiceModule } from './rule.service';
export const legacyValidationResultsHelperServiceModule = 'sis-components.service.legacyValidationResultsHelper';
(function () {
  legacyValidationResultsHelperService.$inject = ["ruleService"];
  angular.module('sis-components.service.legacyValidationResultsHelper', [ruleServiceModule]).factory('legacyValidationResultsHelperService', legacyValidationResultsHelperService);

  /**
   * @ngInject
   */
  function legacyValidationResultsHelperService(ruleService) {
    const api = {
      insertValidationResultsIntoPlanObjects: (validatablePlan, planValidationResult) => {
        // this service is needed until frontend-student and frontend-tutor are refactored so that
        // they do not depend on validationResults (and in case of frontend-student _index) properties
        // inside study and rule objects.

        // unset all previous validationResults and _index properties, then insert new validationResults from
        // planValidationResult object

        _.forEach(_.values(validatablePlan.modulesById), module => {
          _.unset(module, 'validationResults');
          _.unset(module, '_index');
          _.unset(module, 'matchingModuleContentApproval');
          const moduleValidationResults = _.get(planValidationResult.moduleValidationResults, module.id);
          const moduleIndex = _.get(planValidationResult.moduleIndexes, module.id);
          const matchingModuleContentApproval = _.get(planValidationResult.matchingModuleContentApprovalsByModuleId, module.id);
          if (moduleValidationResults) {
            module.validationResults = moduleValidationResults;
          }
          if (moduleIndex || moduleIndex === null) {
            module._index = moduleIndex;
          }
          if (matchingModuleContentApproval) {
            module.matchingModuleContentApproval = matchingModuleContentApproval;
          }
          const allRules = ruleService.allRules(module.rule);
          _.forEach(_.compact(allRules), rule => {
            _.unset(rule, 'validationResults');
            const ruleValidationResults = _.get(_.get(planValidationResult.ruleValidationResults, module.id), rule.localId);
            if (ruleValidationResults) {
              rule.validationResults = ruleValidationResults;
            }
          });
        });
        _.forEach(_.values(validatablePlan.courseUnitsById), courseUnit => {
          _.unset(courseUnit, 'validationResults');
          _.unset(courseUnit, '_index');
          const courseUnitValidationResults = _.get(planValidationResult.courseUnitValidationResults, courseUnit.id);
          const courseUnitIndex = _.get(planValidationResult.courseUnitIndexes, courseUnit.id);
          if (courseUnitValidationResults) {
            courseUnit.validationResults = courseUnitValidationResults;
          }
          if (courseUnitIndex || courseUnitIndex === null) {
            courseUnit._index = courseUnitIndex;
          }
        });
        _.forEach(_.values(validatablePlan.customModuleAttainmentsById), customModuleAttainment => {
          _.unset(customModuleAttainment, 'validationResults');
          _.unset(customModuleAttainment, '_index');
          const customModuleAttainmentValidationResults = _.get(planValidationResult.customModuleAttainmentValidationResults, customModuleAttainment.id);
          const customModuleAttainmentIndex = _.get(planValidationResult.customModuleAttainmentIndexes, customModuleAttainment.id);
          if (customModuleAttainmentValidationResults) {
            customModuleAttainment.validationResults = customModuleAttainmentValidationResults;
          }
          if (customModuleAttainmentIndex || customModuleAttainmentIndex === null) {
            customModuleAttainment._index = customModuleAttainmentIndex;
          }
        });
        _.forEach(_.values(validatablePlan.customCourseUnitAttainmentsById), customCourseUnitAttainment => {
          _.unset(customCourseUnitAttainment, 'validationResults');
          _.unset(customCourseUnitAttainment, '_index');
          const customCourseUnitAttainmentValidationResults = _.get(planValidationResult.customCourseUnitAttainmentValidationResults, customCourseUnitAttainment.id);
          const customCourseUnitAttainmentIndex = _.get(planValidationResult.customCourseUnitAttainmentIndexes, customCourseUnitAttainment.id);
          if (customCourseUnitAttainmentValidationResults) {
            customCourseUnitAttainment.validationResults = customCourseUnitAttainmentValidationResults;
          }
          if (customCourseUnitAttainmentIndex || customCourseUnitAttainmentIndex === null) {
            customCourseUnitAttainment._index = customCourseUnitAttainmentIndex;
          }
        });
      },
      // the following functions have been moved here from typescript planValidation, where they do not belong
      // the functions are needed to support the old design of frontend-student

      sortAndAssignSelections: validatablePlan => {
        api.assignModuleSelections(validatablePlan.rootModule, validatablePlan);
      },
      assignAttainmentSelections: module => {
        // Use list from module itself if it exists
        module.selectedModules = module.getSelectedModules();
        module.selectedCourseUnits = module.getSelectedCourseUnits();
        _.forEach(module.selectedModules, selectedModule => {
          api.assignAttainmentSelections(selectedModule);
        });
      },
      assignModuleSelections: (module, validatablePlan) => {
        // Use list from module itself if it exists
        if (module.attainment) {
          api.assignAttainmentSelections(module);
          return;
        }
        module.selectedModules = validatablePlan.getSelectedModulesUnderModule(module);
        module.selectedCourseUnits = validatablePlan.getSelectedCourseUnitsUnderModule(module);
        module.selectedCustomModuleAttainments = validatablePlan.getSelectedCustomModuleAttainmentsUnderModule(module);
        module.selectedCustomCourseUnitAttainments = validatablePlan.getSelectedCustomCourseUnitAttainmentsUnderModule(module);
        _.forEach(module.selectedModules, selectedModule => {
          api.assignModuleSelections(selectedModule, validatablePlan);
        });
      }
    };
    return api;
  }
})();