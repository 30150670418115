<div id="student-application" class="container student-application prior-learning-substitution-application">
  <div class="row">
    <div class="offset-md-1 col-md-10 offset-lg-2 col-lg-8">
      <div class="form-group-mimic">
        <sis-wizard-progress [stepKeys]="wizardStepKeys" [currentStep]="currentStep"></sis-wizard-progress>
      </div>

      <app-prior-learning-and-attachments-edit [hidden]="!isFirstStep()"
                                               [hidePreviousButton]="isFirstStep()"
                                               type="SUBSTITUTE"
                                               (attachmentsChange)="onAttachmentsChange($event)"
                                               (selectedExternalStudyIdsChange)="onExternalAttachmentsChange($event)"
                                               (exit)="exit()"
                                               (continue)="continue($event)">
      </app-prior-learning-and-attachments-edit>
      <app-prior-learning-application-confirmation [hidden]="!isLastStep()"
                                                   [application]="workflowApplication"
                                                   [attachments]="attachments"
                                                   [disableButtons]="uploading"
                                                   [externalAttainedStudyIds$]="externalAttainedStudyIds$"
                                                   (exit)="exit()"
                                                   (previous)="previous()"
                                                   (submit)="submit($event)">
      </app-prior-learning-application-confirmation>
    </div>
  </div>
</div>
