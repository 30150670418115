import { ChangeDetectionStrategy, Component, Inject, inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FudisDialogService } from '@funidata/ngx-fudis';
import { ExternalAttainedStudy } from 'common-typescript/types';
import { SisFormBuilder } from 'sis-components/form/sis-form-builder.service';

export interface SelectEmrexAttainmentDialogValues {
    externalAttainedStudies: ExternalAttainedStudy[]
    selection: ExternalAttainedStudy
}

interface SelectEmrexAttainmentForm {
    externalAttainedStudy: FormControl<ExternalAttainedStudy>
}

@Component({
    selector: 'app-select-emrex-attainment-dialog',
    templateUrl: './select-emrex-attainment-dialog.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectEmrexAttainmentDialogComponent implements OnInit {
    private dialogService = inject(FudisDialogService);
    private fb = inject(SisFormBuilder);

    form: FormGroup<SelectEmrexAttainmentForm>;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: SelectEmrexAttainmentDialogValues,
    ) {
    }

    ngOnInit() {
        this.form = this.fb.group({
            externalAttainedStudy: this.fb.sisFormControl(this.data.selection || null),
        });
    }

    submit() {
        this.dialogService.close({
            selection: this.form?.controls?.externalAttainedStudy?.value,
        });
    }
}
