import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { dateUtils } from 'common-typescript';
import { OtmId, StudyPeriod, StudyTerm, StudyYear } from 'common-typescript/types';
import _ from 'lodash';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { EntityService } from './entity.service';

const CONFIG = {
    ENDPOINTS: {
        backend: '/kori/api',
        getStudyYears() {
            return `${this.backend}/study-years`;
        },
    },
};

@Injectable({ providedIn: 'root' })
@NgEntityServiceConfig({
    baseUrl: CONFIG.ENDPOINTS.backend,
    resourceName: 'study-years',
})
export class StudyYearsEntityService extends EntityService<StudyYearsState> {

    constructor() {
        super(StudyYearsStore, StudyYearsQuery);
    }

    /**
     * Returns study years corresponding to the given study year start years. Fetches the study years with a single API request
     * that includes all study years between the first and the last of the given years; i.e. a continuous range is always returned.
     */
    getStudyYears(organisationId: OtmId, studyYearStartYears: number[]): Observable<StudyYear[]>;

    /**
     * Returns the requested amount of study years starting from the given study year.
     */
    getStudyYears(organisationId: OtmId, firstYear: number, numberOfYears: number): Observable<StudyYear[]>;

    getStudyYears(organisationId: OtmId, firstYearOrYearRange: number | number[], numberOfYears?: number): Observable<StudyYear[]> {
        const params: { [key: string]: string | number } = { organisationId };
        if (Array.isArray(firstYearOrYearRange)) {
            const firstYear = Math.min(...firstYearOrYearRange);
            const lastYear = Math.max(...firstYearOrYearRange);
            params.firstYear = firstYear;
            params.numberOfYears = lastYear - firstYear + 1;
        } else {
            params.firstYear = firstYearOrYearRange;
            params.numberOfYears = numberOfYears;
        }
        return this.getHttp().get<StudyYear[]>(CONFIG.ENDPOINTS.getStudyYears(), { params });
    }

    /**
     * Emits the current study year,
     * or produces an error if not found.
     */
    getCurrentStudyYear(organisationId: OtmId): Observable<StudyYear> {
        if (!organisationId) {
            throw Error('No organisationId given');
        }
        const firstYear = moment().year() - 1;
        return this.getStudyYears(organisationId, firstYear, 2).pipe(
            map((studyYears: StudyYear[]) => {
                const today = moment();
                const currentStudyYear: StudyYear = _.find(studyYears, studyYear => dateUtils.rangeContains(today, studyYear.valid));
                if (currentStudyYear) {
                    return currentStudyYear;
                }
                throw Error('Current study year template missing');
            }),
        );
    }

    getCurrentStudyPeriod(organisationId: OtmId): Observable<StudyPeriod> {
        return this.getCurrentStudyYear(organisationId)
            .pipe(
                map(studyYear => studyYear.studyTerms
                    .flatMap(studyTerm => studyTerm.studyPeriods)
                    .find(studyPeriod => dateUtils.rangeContains(moment(), studyPeriod.valid))),
            );
    }

    getCurrentStudyTermOfStudyYear(studyYear: StudyYear): StudyTerm {
        const currentMoment = moment();
        return _.find(_.get(studyYear, 'studyTerms', []), term => dateUtils.rangeContains(currentMoment, term.valid));
    }

    getCurrentUniversityStudyTermIndex(currentStudyYear: StudyYear): number {
        const firstStudyTerm = _.first(currentStudyYear.studyTerms);
        const currentTermIsFirstTerm = moment().isBetween(firstStudyTerm.valid.startDate, firstStudyTerm.valid.endDate, null, '[)');
        return currentTermIsFirstTerm ? 0 : 1;
    }
}

type StudyYearsState = EntityState<StudyYear>;

@StoreConfig({ name: 'study-years' })
class StudyYearsStore extends EntityStore<StudyYearsState> {}

class StudyYearsQuery extends QueryEntity<StudyYearsState> {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(store: StudyYearsStore) {
        super(store);
    }
}
