import { Pipe, PipeTransform } from '@angular/core';
import { OrganisationRoleShareBase, Urn } from 'common-typescript/types';

@Pipe({ name: 'hasOrganisationRole' })
export class HasOrganisationRolePipe implements PipeTransform {

    transform(value: OrganisationRoleShareBase[] = [], roleUrn: Urn): boolean {
        return (value || []).some(roleShare => roleShare?.roleUrn === roleUrn);
    }
}
