import { Pipe, PipeTransform } from '@angular/core';
import { AssessmentItem, LocalizedString } from 'common-typescript/types';
import { AssessmentItemNameService } from 'sis-common/name/assessment-item-name.service';

@Pipe({
    name: 'assessmentItemFullName',
})
export class AssessmentItemFullNamePipe implements PipeTransform {

    constructor(private assessmentItemNameService: AssessmentItemNameService) {
    }

    transform(value: AssessmentItem): LocalizedString {
        if (!value) {
            return null;
        }
        return this.assessmentItemNameService.generateFullName(value);
    }
}
