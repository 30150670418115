import angular from 'angular';
import _ from 'lodash';
import 'js-data';
export const degreeProgramTypeModule = 'sis-components.model.degreeProgramType';
(function () {
  initDegreeProgramTypeModel.$inject = ["degreeProgramTypeModel"];
  degreeProgramTypeModel.$inject = ["DS", "$http", "$log"];
  angular.module(degreeProgramTypeModule, ['js-data']).factory('degreeProgramTypeModel', degreeProgramTypeModel).run(initDegreeProgramTypeModel);

  /**
   * @ngInject
   */
  function degreeProgramTypeModel(DS, $http, $log) {
    const model = DS.defineResource({
      idAttribute: 'urn',
      name: 'degreeProgramType',
      endpoint: '/kori/api/cached/codebooks/urn:code:degree-program-type',
      watchChanges: false
    });
    model.lazyGetAll = _.once(() => {
      return $http.get(model.endpoint).then(response => {
        model.inject(_.values(response.data));
        return model.getAll();
      }).catch(error => {
        $log.error(error);
      });
    });
    return model;
  }

  /**
   * @ngInject
   */
  function initDegreeProgramTypeModel(degreeProgramTypeModel) {
    degreeProgramTypeModel.lazyGetAll();
  }
})();