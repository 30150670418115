import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Range } from 'common-typescript/src/model/range';
import { RangeType } from 'common-typescript/types';

@Pipe({ name: 'translateRange' })
export class TranslateRangePipe implements PipeTransform {

    constructor(private translateService: TranslateService) {}

    transform(input: any): string {
        if (!input) {
            return '';
        }
        const range = Range.cast(input);
        const rangeType = range.getType();

        switch (rangeType) {
            case RangeType.ONE:
                return this.translateService.instant('SIS_COMPONENTS.PLAN.RANGE.ONE', { credits: range.min });
            case RangeType.EXACTLY:
                return this.translateService.instant('SIS_COMPONENTS.PLAN.RANGE.EXACTLY', { credits: range.min });
            case RangeType.MAX:
                return this.translateService.instant('SIS_COMPONENTS.PLAN.RANGE.MAX', { credits: range.max });
            case RangeType.MIN:
                return this.translateService.instant('SIS_COMPONENTS.PLAN.RANGE.MIN', { credits: range.min });
            case RangeType.BETWEEN:
                return this.translateService.instant('SIS_COMPONENTS.PLAN.RANGE.BETWEEN', { min: range.min, max: range.max });
        }

    }
}
