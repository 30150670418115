<ng-container *ngIf="data$ | async as data">
  <app-course-unit-info-modal-substitutions-correspondences-select-form
    [courseUnit]="courseUnit"
    [isLoggedIn]="data.isLoggedIn"
    [selected]="data.selected"
    [substitutionOptions]="data.substitutionOptions"
    (selectSubstitution)="this.selectOrRemoveSubstitutionOption($event)"
    (openCourseUnitInfoModal)="this.openCourseUnitModal.emit($event)"
    [versionChangeInProgress]="versionChangeInProgress"
    [substituteCourseUnitsByGroupId]="data.substituteCourseUnitsByGroupId"
    [validatablePlan]="validatablePlan"
    [attainmentsByCourseUnitGroupId]="data.attainmentsByCourseUnitGroupId"
    [allAttainments]="data.allAttainments"
  ></app-course-unit-info-modal-substitutions-correspondences-select-form>
</ng-container>

