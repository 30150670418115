"use strict";

(function () {
  angular.module("sis-components.date.momentDateRangeFilter", []).filter('momentDateRange', momentDateRange);

  /**
   * @ngInject
   */
  function momentDateRange() {
    return function (input) {
      if (input) {
        if (input.startDate === null && input.endDate === null) {
          return '';
        }
        if (input.startDate.isSame(input.endDate)) {
          // Using format 'l' would give localized equivalent of this.
          // The reason I did not do this outright is the fact that Moment does not support
          // localized format equivalent of D.M. (see 5 lines below), so some coding would be needed in order to get around that.
          return input.endDate.format('D.M.YYYY');
        }
        if (input.startDate.year() === input.endDate.year()) {
          return input.startDate.format('D.M.') + '–' + input.endDate.format('D.M.YYYY');
        }
        return input.startDate.format('D.M.YYYY') + '–' + input.endDate.format('D.M.YYYY');
      }
      return '';
    };
  }
})();