<sis-typeahead [inputFormatter]="renderStudent"
               [model]="student"
               [id]="id"
               (modelChange)="studentChange.emit($event)"
               [placeholder]="'SEARCH.FILTER_SELECTOR.STUDENT_INPUT_PLACEHOLDER' | translate"
               [resultTemplate]="studentResultTemplate"
               [typeahead]="doSearch">
</sis-typeahead>

<ng-template #studentResultTemplate let-result="result" let-term="term">
  <div>
    <span><sis-mark-string [target]="result.lastName" [query]="term"/></span>
    <span>&nbsp;</span>
    <span><sis-mark-string [target]="result.firstNames" [query]="term"/></span>
    <span> / </span>
    <span><sis-mark-string [target]="result.studentNumber" [query]="term"/></span>
  </div>
</ng-template>
