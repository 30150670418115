<ng-container *ngIf="control">
  <label [ngClass]="[labelState]">
    <label *ngIf="label">{{label}}</label>
    <div class="inner-addon" data-cy="date-picker-input">
      <input type="text"
             class="form-control"
             ngbDatepicker
             #datepicker="ngbDatepicker"
             [name]="name"
             [attr.id]="name + '_id'"
             [attr.aria-describedby]="name + '_errors'"
             [formControl]="control"
             [class]="{invalid: control.invalid && control.touched}"
             [minDate]="minDate"
             [maxDate]="maxDate"
             [placeholder]="dateFormat"
             [showWeekNumbers]="true"
             [startDate]="startDate"
             [footerTemplate]="footer"
             [placement]="datepickerPlacement"
             autocomplete="off"
             data-cy="date-input">
      <button type="button"
              class="non-button addon-button"
              data-cy="date-picker-toggle"
              (click)="datepicker.toggle(); $event.preventDefault()"
              [attr.disabled]="datepicker.disabled || null"
              [attr.aria-expanded]="datepicker.isOpen()"
              [attr.aria-label]="'ARIA_LABEL.CALENDAR_ASSISTANT' | translate">
        <sis-icon icon="calendar"></sis-icon>
      </button>
    </div>
  </label>
  <sis-validation-errors [control]="control" [formFieldId]="name" [ariaLive]="false" />

  <ng-template #footer>
    <div class="ng-dp-footer">
      <button type="button"
              (click)="$event.preventDefault(); selectToday(); datepicker.close()"
              class="btn btn-today">
        {{'SIS_COMPONENTS.DATE.LOCAL_DATE_EDITOR.TODAY' | translate}} {{currentDate}}
      </button>
    </div>
  </ng-template>
</ng-container>
