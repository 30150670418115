import angular from 'angular';
import _ from 'lodash';
import { educationPhaseProgressModule } from 'sis-components/plan/educationPhaseProgress.component';
import { commonPlanServiceModule } from 'sis-components/service/plan.service';
import 'sis-components/applications/extensionApplicationSummary.component';
import 'sis-components/applications/extensionApplicationDecisionSummary.component';
import 'sis-components/applications/textWithDefaultMissingValue.component';
import 'sis-components/expandables/sisuRegularExpandable/sisuRegularExpandable.component';
import 'sis-components/model/applicationDecision.model';
import 'sis-components/model/studentApplication.model';
import 'sis-components/number/decimalNumber.filter';
import 'sis-components/service/attainment.service';
import 'sis-components/service/plan.service';
import 'sis-components/service/studentApplication.service';
import 'sis-components/service/studyTerm.service';
import 'sis-components/service/studyRight.service';
import { ExtensionInfoComponent } from 'sis-components/studyRight/extension-info/extension-info.component.ts';
import './cancelApplicationModal.service';
import extensionApplicationTpl from './extensionApplication.tpl.html';
import { PlannedStudiesByStudyTermComponent } from 'sis-components/applications/planned-studies-by-study-term/planned-studies-by-study-term.component';
import { AttainedCreditsByStudyTermComponent } from 'sis-components/applications/attained-credits-by-study-term/attained-credits-by-study-term.component';
(function () {
  extensionApplicationController.$inject = ["$q", "$state", "$timeout", "$translate", "alertsService", "commonPlanService", "commonStudentApplicationService", "commonAttainmentService", "commonStudyTermService", "cancelApplicationModalService", "applicationDecisionJSDataModel", "defaultPromiseHandler", "STUDENT_APPLICATION_STATE"];
  angular.module('student.profile.applications.extensionApplication', [educationPhaseProgressModule, 'sis-components.applications.extensionApplicationSummary', 'sis-components.applications.extensionApplicationDecisionSummary', 'sis-components.applications.textWithDefaultMissingValue', 'sis-components.expandable.sisuRegularExpandable', 'sis-components.model.applicationDecision', 'sis-components.model.studentApplication', 'sis-components.number.decimalNumberFilter', 'sis-components.service.attainmentService', commonPlanServiceModule, 'sis-components.service.studentApplicationService', 'sis-components.service.studyTermService', 'sis-components.service.studyRightService', 'student.profile.applications.cancelApplicationModal', ExtensionInfoComponent.downgrade.moduleName, PlannedStudiesByStudyTermComponent.downgrade.moduleName, AttainedCreditsByStudyTermComponent.downgrade.moduleName]).component('extensionApplication', {
    template: extensionApplicationTpl,
    bindings: {
      applicationId: '<',
      showSubmitSuccess: '<',
      showCancellationMessage: '<'
    },
    controller: extensionApplicationController
  });
  function extensionApplicationController($q, $state, $timeout, $translate, alertsService, commonPlanService, commonStudentApplicationService, commonAttainmentService, commonStudyTermService, cancelApplicationModalService, applicationDecisionJSDataModel, defaultPromiseHandler, STUDENT_APPLICATION_STATE) {
    const ctrl = this;
    ctrl.ready = false;
    ctrl.$onInit = function () {
      loadApplicationAndDecision(ctrl.applicationId).then(application => {
        ctrl.application = application;
        commonAttainmentService.findByIds(application.attainmentIds).then(attainments => {
          const totalCredits = attainments.reduce((sum, {
            credits
          }) => sum + (credits || 0), 0);
          ctrl.creditsPerTerm = application.usedAttendanceTerms > 0 ? _.round(totalCredits / application.usedAttendanceTerms, 1) : 0;
          const attainedCourseUnitIds = attainments.map(attainment => attainment.courseUnitId);
          $q.all([commonStudyTermService.groupAttainedCreditsByStudyTerm(attainments, application.studyRightValidity.startDate, application.creationTime, application.termRegistrations), commonPlanService.groupPlannedStudiesByStudyTerm(application.planSnapshot, application.creationTime, attainedCourseUnitIds)]).then(([attainedCreditsByStudyTerm, plannedStudies]) => {
            Object.assign(ctrl, {
              attainedCreditsByStudyTerm,
              plannedStudies
            });
            ctrl.ready = true;
            ctrl.applicationCollapsed = ctrl.hasDecision();
            ctrl.decisionCollapsed = !ctrl.applicationCollapsed;
          });
        });
      });
      if (ctrl.showSubmitSuccess) {
        $timeout(() => alertsService.success($translate.instant('PROFILE.APPLICATIONS.APPLICATION_SENT')));
      }
      if (ctrl.showCancellationMessage) {
        $timeout(() => alertsService.success($translate.instant('PROFILE.APPLICATIONS.APPLICATION_CANCELLED_ALERT')));
      }
    };
    ctrl.isCancelled = function () {
      return ctrl.ready && ctrl.application.state === STUDENT_APPLICATION_STATE.CANCELLED;
    };
    ctrl.isWaitingForDecision = function () {
      return ctrl.ready && _.includes([STUDENT_APPLICATION_STATE.REQUESTED, STUDENT_APPLICATION_STATE.IN_HANDLING], ctrl.application.state);
    };
    ctrl.hasDecision = function () {
      return ctrl.ready && _.includes([STUDENT_APPLICATION_STATE.ACCEPTED, STUDENT_APPLICATION_STATE.REJECTED], ctrl.application.state) && _.isObject(ctrl.application.decision);
    };
    ctrl.openCancelApplicationModal = function () {
      cancelApplicationModalService.open(ctrl.application).then(() => {
        // Refresh the breadcrumb and show the cancellation message;
        // set 'showSubmitSuccess' to 'undefined' to clear it and not show it in the URL
        $state.go($state.current, {
          showSubmitSuccess: undefined,
          showCancellationMessage: true
        }, {
          reload: true
        });
      }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    };
    function loadApplicationAndDecision(applicationId) {
      return commonStudentApplicationService.findById(applicationId, {
        bypassCache: true
      }).then(application => {
        if (application.decision) {
          return applicationDecisionJSDataModel.loadRelations(application.decision).then(() => application);
        }
        return application;
      });
    }
  }
})();