import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Grant } from 'common-typescript/types';

@Component({
    selector: 'app-grants-table',
    templateUrl: './grants-table.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class GrantsTableComponent {

    @Input() grants: Grant[];

    grantId(i: number, grant: Grant): any {
        // Fall back to default functionality if the id is missing
        return grant?.id ?? grant;
    }
}
