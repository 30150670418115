controller.$inject = ["$scope", "$timeout"];
import angular from 'angular';
import { ISO_LOCAL_DATE_FORMAT } from 'common-typescript/constants';
import _ from 'lodash';
import moment from 'moment';
import { LegacyLocalDateEditorComponent, validators as datepickerValidators } from '../legacy-local-date-editor/legacy-local-date-editor.component.ts';
import template from './localDateEditor.tpl.html';
export const localDateEditorModule = 'sis-components.date.localDateEditor';

/**
 * This component is a wrapper for the Angular LegacyLocalDateEditorComponent. The purpose is to provide backwards compatibility
 * for old AngularJS forms, so that they can use the new NG Bootstrap datepicker. This component acts as a drop-in replacement
 * for the old localDateEditor directive which used the UI Bootstrap datepicker. This component should NOT be used in new
 * Angular forms.
 */
angular.module(localDateEditorModule, ['pascalprecht.translate', LegacyLocalDateEditorComponent.downgrade.moduleName]).component('localDateEditor', {
  template,
  controller,
  bindings: {
    localDate: '=',
    /** The name of the form control. Note that form validations won't work without this. */
    name: '@?',
    label: '@?',
    required: '<?',
    disabled: '=?',
    // min and max dates are validated as closed range [minDate, maxDate],
    // When input is min 2018-08-01 & max 2018-08-31, then all dates in august of 2018 are valid and no
    // other dates. Should probably be half open range [minDate, maxDate) because that is our default format
    // of ranges, but refactoring all usages so close to release is a risk. Date-picker-options uses closed
    // range.
    // js Date object
    minDate: '<?',
    // js Date object
    maxDate: '<?',
    /** A JS Date object that indicates where to open the calendar view */
    initDate: '<?',
    onChange: '&?'
  }
});
function controller($scope, $timeout) {
  const ctrl = this;
  ctrl.$scope = $scope;
  ctrl.minDateString = undefined;
  ctrl.maxDateString = undefined;

  // The original local date editor invokes onChange during initialization, and some forms depend on it
  ctrl.$onInit = () => triggerOnChange();
  ctrl.$onChanges = changes => {
    // Convert minDate, maxDate and startDate from JS Date objects to strings, as the existing AngularJS forms
    // provide them as Date objects but the new LegacyLocalDateEditorComponent expects ISO-formatted date strings
    if (changes.minDate) {
      const minDate = changes.minDate.currentValue;
      ctrl.minDateString = _.isNil(minDate) ? undefined : moment(minDate).format(ISO_LOCAL_DATE_FORMAT);
    }
    if (changes.maxDate) {
      const maxDate = changes.maxDate.currentValue;
      ctrl.maxDateString = _.isNil(maxDate) ? undefined : moment(maxDate).format(ISO_LOCAL_DATE_FORMAT);
    }
    if (changes.initDate) {
      const initDate = changes.initDate.currentValue;
      ctrl.initDateString = _.isNil(initDate) ? undefined : moment(initDate).format(ISO_LOCAL_DATE_FORMAT);
    }
  };
  ctrl.handleDatePickerChange = selectedDate => {
    // Sync changes from LegacyLocalDateEditorComponent to the parent component
    // Use $timeout so that AngularJS change detection works when date is written in the input field (instead of using the picker)
    $timeout(() => {
      ctrl.localDate = selectedDate;
    });
    triggerOnChange();
  };
  ctrl.handleValidityChange = error => {
    // Sync the validation state of the LegacyLocalDateEditorComponent with the AngularJS form. The component is wrapped in an
    // ngForm in the template so that it can be accessed programmatically via the $scope (forms are published to the $scope
    // using their name, but ngModels are not). The form and the input field have the same name for backward compatibility
    // reasons (it allows checking for validation errors as if the wrapper ngForm wasn't there). The dirtying and validation
    // state change made here will automatically propagate to the parent form (if one exists).
    if (!ctrl.disabled && _.has($scope, `${ctrl.name}.${ctrl.name}`)) {
      const formControl = $scope[ctrl.name][ctrl.name];
      formControl.$setDirty();
      formControl.$setTouched();
      datepickerValidators.forEach(validator => formControl.$setValidity(validator, error !== validator));
    }
  };
  function triggerOnChange() {
    if (ctrl.onChange) {
      // Invoke onChange() in a separate digest loop, as otherwise at least in new Angular forms the callback in the
      // parent component is sometimes invoked before the localDate binding gets updated in the parent
      $timeout(() => ctrl.onChange());
    }
  }
}