(function () {
  'use strict';

  translatedRangeFactory.$inject = ["Range", "translateRange", "$translate"];
  angular.module('student.plan.rules.header').filter('translatedRange', translatedRangeFactory);
  function translatedRangeFactory(Range, translateRange, $translate) {
    return function (input) {
      var translation = '';
      if (input) {
        var translatedRange = translateRange(Range.cast(input));
        translation = $translate.instant(translatedRange.t, translatedRange.values);
      }
      return translation;
    };
  }
})();