<ng-container *transloco="let t">
  <fudis-dialog size="sm">
    <fudis-heading [level]="2">{{ t('PROFILE.APPLICATIONS.CANCEL_APPLICATION_MODAL.TITLE.' + data.workflowType) }}</fudis-heading>
    <fudis-dialog-content>
      <fudis-grid [rowGap]="'sm'">
        <fudis-body-text [variant]="'lg-light'">{{ t('PROFILE.APPLICATIONS.CANCEL_APPLICATION_MODAL.SUBTITLE') }}</fudis-body-text>
        <fudis-body-text [variant]="'lg-light'" *ngIf="data.hasDescription">{{ t('PROFILE.APPLICATIONS.CANCEL_APPLICATION_MODAL.CONFIRMATION_DESCRIPTION.' + data.workflowType) }}</fudis-body-text>
        <fudis-body-text [variant]="'lg-light'">{{ t('PROFILE.APPLICATIONS.CANCEL_APPLICATION_MODAL.CONFIRMATION.' + data.workflowType) }}</fudis-body-text>
      </fudis-grid>
    </fudis-dialog-content>
    <fudis-dialog-actions>
      <fudis-button [label]="t('SIS_COMPONENTS.BUTTON.CANCEL')" (handleClick)="closeSurveyDialog()" [variant]="'secondary'" />
      <fudis-button data-cy="cancel-workflow-dialog-yes-button" [label]="t('SIS_COMPONENTS.BUTTON.YES')" (handleClick)="cancel$.next()" />
    </fudis-dialog-actions>
  </fudis-dialog>
</ng-container>
