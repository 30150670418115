import { Pipe, PipeTransform } from '@angular/core';
import { YearRange } from 'common-typescript/types';

@Pipe({ name: 'yearRange' })
export class YearRangePipe implements PipeTransform {

    private static notNull(value: any) {
        return value !== undefined && value !== null;
    }

    transform(value: YearRange, ...args: any[]): any {
        if (value) {
            const start = YearRangePipe.notNull(value.startYear) ? value.startYear : null;
            const end = YearRangePipe.notNull(value.endYear) ? value.endYear : null;
            let retval = '';
            if (YearRangePipe.notNull(start)) {
                retval += start;
            }
            if (YearRangePipe.notNull(start) && YearRangePipe.notNull(end) && start !== end) {
                retval += '–';
            }
            if (YearRangePipe.notNull(end) && start !== end) {
                retval += end;
            }
            return retval;
        }
        return '';
    }
}
