<div class="modal-header">
  <h2 class="modal-title" [tabIndex]="0">
    {{'SIS_COMPONENTS.HIERARCHY_SELECTOR_MODAL.ORGANISATION_MODAL_TITLE' | transloco}}
  </h2>
</div>

<div class="modal-body">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-6">
        <h4 class="form-label">
          {{'SELECT_ORGANISATION.INSTITUTION' | transloco}}
        </h4>
        <p class="form-control-static">
          {{university.name | localizedString}}
        </p>
      </div>

      <div class="col-12 col-md-6 reset-button-container">
        <button
          type="button"
          class="btn btn-sm btn-secondary btn-hollow reset-selection-button"
          [disabled]="!selected.length"
          (click)="reset()"
        >
          {{'SELECT_ORGANISATION.RESET_SELECTION' | translate}}
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="optionTree" class="mt-3">
    <sis-checkbox-tree
      (checkedChange)="selectChange($event)"
      [selected]="selected"
      [option]="optionTree"
      [defaultExpanded]="defaultExpanded"
      [topLevelStyleTargets]="topLevelStyleTargets"
    ></sis-checkbox-tree>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-primary btn-hollow btn-lg" (click)="close()">
    {{'BUTTON.CANCEL' | translate}}
  </button>
  <button type="button" class="btn btn-primary btn-lg" (click)="confirmSelection()">
    {{'BUTTON.DONE' | translate}}
  </button>
</div>
