import angular from 'angular';
import 'js-data';
import { jsDataRelationHelperModule } from '../service/jsDataRelationHelper.service';
import { decreeOnUniversityDegreesModelModule } from './decreeOnUniversityDegrees.model';
import { studyRightExpirationRulesModelModule } from './studyRightExpirationRules.model';
import { educationalInstitutionModelModule } from './educationalInstitution.model';
export const studyRightModelModule = 'sis-components.model.studyRight';
(function () {
  studyRightJSDataModel.$inject = ["$q", "$log", "DS", "StudyRightEndpoint"];
  angular.module(studyRightModelModule, ['js-data', jsDataRelationHelperModule, decreeOnUniversityDegreesModelModule, studyRightExpirationRulesModelModule, educationalInstitutionModelModule]).factory('studyRightJSDataModel', studyRightJSDataModel).constant('StudyRightEndpoint', {
    DEFAULT: '/ori/api/study-rights',
    OWN: '/ori/api/study-rights/own'
  }).run(function () {});

  /**
   * @ngInject
   */
  function studyRightJSDataModel($q, $log, DS, StudyRightEndpoint) {
    return DS.defineResource({
      idAttribute: 'id',
      name: 'studyRight',
      endpoint: StudyRightEndpoint.DEFAULT,
      relations: {
        hasOne: {
          decreeOnUniversityDegrees: {
            localField: 'decreeOnUniversityDegrees',
            localKey: 'decreeOnUniversityDegreesUrn'
          },
          studyRightExpirationRules: {
            localField: 'studyRightExpirationRules',
            localKey: 'studyRightExpirationRulesUrn'
          },
          education: {
            localField: 'education',
            localKey: 'educationId'
          },
          organisation: {
            localField: 'organisation',
            localKey: 'organisationId'
          },
          educationalInstitution: [{
            localField: 'transferOutUniversity',
            localKey: 'transferOutUniversityUrn'
          }, {
            localField: 'studyRightTransferOriginalUniversity',
            localKey: 'studyRightTransfer.originalUniversityUrn'
          }]
        }
      }
    });
  }
})();