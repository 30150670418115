<ng-container *transloco="let t">
  <div *ngIf="{
  isLoading: this.loading$ | async,
  enrolmentsData: enrolmentsData$ | async,
  isMobileView: isMobileView$ | async,
  events: enrolmentDataChangeEvents$ | async
  } as data" class="container sis-pt-md">
    <ng-container *ngIf="!!data.enrolmentsData">
      <sis-breadcrumbs></sis-breadcrumbs>
      <sis-page-title [title]="t('ENROLMENTS.PAGE_TITLE')" [showCrumbs]="true"/>

      <app-teaching-not-selected *ngIf="!!data.enrolmentsData" [enrolments]="this.enrolments"
      ></app-teaching-not-selected>

      <div class="row sis-mt-lg">

        <div class="col-sm-8 col-12">
          <h2>{{ t('ENROLMENTS.ENROL_TO_CUR_TITLE') }}</h2>
        </div>

        <div class="col-sm-4 col-12 sis-mb-xs refresh-button-container">
          <sis-button (clicked)="this.updateButtonClicked()" [hollow]="true" [label]="t('ENROLMENTS.UPDATE_BUTTON')" [size]="'sm'"
                      class="float-end"></sis-button>
          <span #updateStatus aria-live="off" class="visually-hidden">
            {{ data.isLoading ? t('ENROLMENTS.LOADING_TEXT') : t('ENROLMENTS.LOADED_TEXT') }}
          </span>
        </div>

        <div aria-live="polite" id="enrolment-data-change-notification-area">
          <div data-cy="enrolment-data-change-notifications" class="col-12" *ngIf="(data.events?.length > 0 && !data.isLoading)">
            <sis-contextual-notification variant="warning">
              <p>{{ 'ENROLMENT_DATA_CHANGE.NOTIFICATION.GENERIC_CHANGES_TEXT' | translate }}</p>
              <ul>
                <ng-container *ngFor="let enrolmentDataChange of data.events">
                  <li *ngIf="this.getEnrolmentChangeDescription(enrolmentDataChange.value, data.enrolmentsData) as description">
                    <span>{{ description.translationKey | translate: description.translationParams }}</span>
                  </li>
                </ng-container>
              </ul>
            </sis-contextual-notification>
          </div>
        </div>

        <ng-container *ngIf="!data.isLoading; then loaded; else loading;"></ng-container>
        <ng-template #loading>
          <div aria-busy="true" class="col-12 d-flex justify-content-center">
            <div class="spinner-border enrolment-spinner"></div>
          </div>
        </ng-template>
        <ng-template #loaded>
          <div class="main-content col-12">
            <div class="row">
              <div *ngFor="let list of realisationLists; let last = last" class="col-12 col-lg-4 column-container">
                <ng-container *ngIf="data.isMobileView; then mobileView; else desktopView;"></ng-container>

                <ng-template #mobileView>
                  <sis-expandable
                    [title]="list.titleKey | translate: { count: getEnrolmentsByType(data.enrolmentsData, list.enrolmentTypeKeys).length }">
                    <ng-template sisExpandableContent>
                      <app-enrolment-state-course-units
                        (handleCancelEnrolment)="handleCancelEnrolment($event)"
                        (handleEnrolment)="handleEnrolment($event)"
                        (handleRemoveEnrolment)="handleRemoveEnrolment($event)"
                        [emptyListText]="list.emptyListTextKey | translate"
                        [enrolments]="getEnrolmentsByType(data.enrolmentsData, list.enrolmentTypeKeys)"
                      ></app-enrolment-state-course-units>
                    </ng-template>
                  </sis-expandable>
                </ng-template>

                <ng-template #desktopView>
                  <app-enrolment-state-course-units
                    (handleCancelEnrolment)="handleCancelEnrolment($event)"
                    (handleEnrolment)="handleEnrolment($event)"
                    (handleRemoveEnrolment)=" handleRemoveEnrolment($event)"
                    [emptyListText]="list.emptyListTextKey | translate"
                    [enrolments]="getEnrolmentsByType(data.enrolmentsData, list.enrolmentTypeKeys)"
                    [title]="list.titleKey | translate: { count: getEnrolmentsByType(data.enrolmentsData, list.enrolmentTypeKeys).length }"
                  ></app-enrolment-state-course-units>
                  <hr *ngIf="!last" class="vertical-divider d-none d-md-block" aria-hidden="true">
                </ng-template>

              </div>
            </div>
          </div>
        </ng-template>

      </div>
    </ng-container>
  </div>
</ng-container>
