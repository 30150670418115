import * as _ from 'lodash';

import {
    Attainment,
    AttainmentType,
    CourseUnit,
    CourseUnitAttainment,
    CourseUnitSelection,
    CustomCourseUnitAttainment,
    EntityWithRule,
    LocalId,
    ModuleAttainment,
    OtmId,
    Plan,
} from '../../types';
import { AnySelection } from '../customTypes';
import { getAllLeafNodes, isModuleAttainment, isModuleLikeAttainment, notNil } from '../plan/planUtils';

export class CleanPlanService {

    static cleanPlan(plan: Plan, attainments: Attainment[] = [], modules: EntityWithRule[] = [], courseUnits: CourseUnit[] = []): void {
        if (_.isNil(plan)) {
            throw Error('Given plan was null/undefined');
        }

        this.selectAttainedModuleVersions(plan, attainments, modules);
        this.selectAttainedCourseUnitVersions(plan, attainments, courseUnits);
        this.selectAttainedSubstituteCourseUnitVersions(plan, attainments, courseUnits);

        this.cleanSelectionsForAttainedParentModules(plan, attainments);
        this.cleanSelectionsForAttainedChildModules(plan, attainments);
        this.cleanOrphanSelections(plan);

        this.removeDuplicateSelections(plan);
        this.cleanSubstitutions(plan, attainments);
        this.cleanAssessmentItemSelections(plan, attainments);
        this.replaceCustomStudyDraftsWithCorrespondingAttainments(plan, attainments);
    }

    private static selectAttainedModuleVersions(plan: Plan, attainments: Attainment[], modules: EntityWithRule[]): void {
        const selections = ['moduleSelections', 'customModuleAttainmentSelections', 'courseUnitSelections',
            'customCourseUnitAttainmentSelections'];
        const selectionArrays = _.compact(_.map(selections, _.partial(_.get, plan)));

        const moduleAttainments = _.filter(attainments, isModuleAttainment);
        const moduleSelectionsByGroupId = _.chain(_.get(plan, 'moduleSelections'))
            .map((selection) => {
                const groupId = _.get(_.find(modules, { id: selection.moduleId }), 'groupId');
                return groupId ? { groupId, selection } : null;
            })
            .compact()
            .keyBy('groupId')
            .mapValues('selection')
            .value();
        _.forEach(moduleAttainments, (moduleAttainment) => {
            const moduleSelection = _.get(moduleSelectionsByGroupId, moduleAttainment.moduleGroupId);
            if (moduleSelection && moduleSelection.moduleId !== moduleAttainment.moduleId) {
                _.forEach(selectionArrays, (array) => {
                    this.removeSelectionsWithModuleIdAsParent(array, moduleSelection.moduleId);
                });
                moduleSelection.moduleId = moduleAttainment.moduleId;
            }
        });
    }

    private static selectAttainedCourseUnitVersions(plan: Plan, attainments: Attainment[], courseUnits: CourseUnit[]): void {
        const courseUnitAttainments = _.filter(attainments, { type: 'CourseUnitAttainment' }) as CourseUnitAttainment[];
        const courseUnitSelectionsByGroupId = _.chain(_.get(plan, 'courseUnitSelections'))
            .map((selection) => {
                const groupId = _.get(_.find(courseUnits, { id: selection.courseUnitId }), 'groupId');
                return groupId ? { groupId, selection } : null;
            })
            .compact()
            .keyBy('groupId')
            .mapValues('selection')
            .value();

        _.forEach(courseUnitAttainments, (courseUnitAttainment) => {
            const courseUnitSelection = _.get(courseUnitSelectionsByGroupId, courseUnitAttainment.courseUnitGroupId);

            // ignore substitute selections (those without parentModuleId) here since they are handled separately
            // by selectAttainedSubstituteCourseUnitVersions
            // substituted selections are handled here, but the corresponding substitute selections will be removed
            // separately by cleanSubstitutions function

            if (courseUnitSelection && courseUnitSelection.courseUnitId !== courseUnitAttainment.courseUnitId &&
                // TODO: Should parentId be parentModuleId?
                // @ts-ignore
                !courseUnitSelection.parentId) {

                courseUnitSelection.courseUnitId = courseUnitAttainment.courseUnitId;
            }
        });
    }

    // Visible for testing
    static selectAttainedSubstituteCourseUnitVersions(plan: Plan, attainments: Attainment[], courseUnits: CourseUnit[]): void {
        const courseUnitAttainments = _.filter(attainments, { type: 'CourseUnitAttainment' }) as CourseUnitAttainment[];
        const courseUnitSelections = _.get(plan, 'courseUnitSelections');
        const substituteCourseUnitIds = _.uniq(_.compact(_.flatMap(courseUnitSelections, 'substitutedBy')));
        const substituteCourseUnits = _.filter(courseUnits, (courseUnit) => _.includes(substituteCourseUnitIds, courseUnit.id));
        _.forEach(courseUnitAttainments, (courseUnitAttainment) => {
            const matchingSubstitutes = _.filter(substituteCourseUnits, { groupId: courseUnitAttainment.courseUnitGroupId });
            _.forEach(matchingSubstitutes, (substituteCourseUnit) => {
                if (courseUnitAttainment.courseUnitId !== substituteCourseUnit.id) {
                    this.updateSubstituteReferences(courseUnitAttainment, substituteCourseUnit, courseUnitSelections);
                }
            });
        });
    }

    /**
     * Remove all selections that refer to an attained module as parentModule.
     */
    private static cleanSelectionsForAttainedParentModules(plan: Plan, attainments: Attainment[]): void {
        const moduleAttainments = _.filter(attainments, isModuleAttainment);
        const selections = ['moduleSelections', 'customModuleAttainmentSelections', 'courseUnitSelections',
            'customCourseUnitAttainmentSelections', 'customStudyDrafts'];
        const selectionArrays = _.compact(_.map(selections, _.partial(_.get, plan)));

        // If parent module is attained, all child selections should be cleared
        _.forEach(moduleAttainments, (moduleAttainment) => {
            _.forEach(selectionArrays, (array) => {
                this.removeSelectionsWithModuleIdAsParent(array, moduleAttainment.moduleId);
            });
        });
    }

    /**
     * Remove all selections that refer to an attainment that is attached under an attained parentModule.
     */
    private static cleanSelectionsForAttainedChildModules(plan: Plan, attainments: Attainment[]): void {

        // If parent module is attained, all child attainments should be cleared as selections
        const moduleAttainments = _.filter(attainments, isModuleLikeAttainment);
        const attainmentsById = _.keyBy(attainments, 'id');
        const childAttainmentIds = moduleAttainments.map(getAllLeafNodes).flat().map(node => node.attainmentId);
        const childCourseUnitIds: string[] = [];
        _.forEach(childAttainmentIds, (childAttainmentId) => {
            const attainment = _.get(attainmentsById, childAttainmentId);
            if (!attainment) {
                return;
            }
            if (_.has(attainment, 'courseUnitId')) {
                const attainmentCourseUnitId = (attainment as CourseUnitAttainment).courseUnitId;
                _.remove(plan.courseUnitSelections, { courseUnitId: attainmentCourseUnitId });
                childCourseUnitIds.push(attainmentCourseUnitId);
            }
            if (_.has(attainment, 'moduleId')) {
                _.remove(plan.moduleSelections, { moduleId: (attainment as ModuleAttainment).moduleId });
            }
            if (attainment.type === 'CustomCourseUnitAttainment') {
                _.remove(plan.customCourseUnitAttainmentSelections, { customCourseUnitAttainmentId: attainment.id });
            }
            if (attainment.type === 'CustomModuleAttainment') {
                _.remove(plan.customModuleAttainmentSelections, { customModuleAttainmentId: attainment.id });
            }
        });
        _.forEach(plan.courseUnitSelections, (courseUnitSelection) => {
            if (!_.isEmpty(_.intersection(courseUnitSelection.substitutedBy, childCourseUnitIds))) {
                courseUnitSelection.substitutedBy = [];
            }
        });
    }

    /**
     * Remove all selections that are not connected to the tree structure starting from the rootModule of the plan.
     */
    private static cleanOrphanSelections(plan: Plan): void {
        const selections = ['moduleSelections', 'customModuleAttainmentSelections', 'courseUnitSelections',
            'customCourseUnitAttainmentSelections', 'customStudyDrafts'];
        const selectionArrays = _.compact(_.map(selections, _.partial(_.get, plan)));
        _.forEach(selectionArrays, (array) => {
            this.removeSelectionsNotConnectedToPlanRoot(array, plan);
        });
    }

    /**
     * Remove substitutions from attained courseUnits. Then remove all courseUnitSelections that do not have parentModuleId
     * and are not included in substitutedBy array of at least one courseUnitSelection. Then remove items from substituteFor
     * properties of courseUnitSelections when referred courseUnit is attained or it's courseUnitSelection does not have
     * corresponding substitutedBy reference.
     */
    private static cleanSubstitutions(plan: Plan, attainments: Attainment[]): void {
        const attainedCourseUnitIds = _.map(_.filter(attainments, { type: 'CourseUnitAttainment' }), 'courseUnitId');
        _.forEach(plan.courseUnitSelections, (courseUnitSelection) => {
            if (_.includes(attainedCourseUnitIds, courseUnitSelection.courseUnitId)) {
                _.unset(courseUnitSelection, 'substitutedBy');
            }
        });
        const substituted = _.compact(_.flatMap(plan.courseUnitSelections, 'substitutedBy'));
        _.remove(plan.courseUnitSelections, (courseUnitSelection) => {
            if (_.has(courseUnitSelection, 'parentModuleId')) {
                return false;
            }
            if (!_.includes(substituted, courseUnitSelection.courseUnitId)) {
                return true;
            }
            const substituteFor = _.get(courseUnitSelection, 'substituteFor', []);
            _.remove(substituteFor, (substituteItem) => {
                if (_.includes(attainedCourseUnitIds, substituteItem.substitutedCourseUnitId)) {
                    return true;
                }
                const substitutedSelection = (plan.courseUnitSelections || [])
                    .find(selection => selection.courseUnitId === substituteItem.substitutedCourseUnitId);
                return !substitutedSelection || !_.includes(substitutedSelection.substitutedBy, courseUnitSelection.courseUnitId);
            });

            return false;
        });
    }

    /**
     * Remove all assessment item selections that refer to a courseUnit that is not in courseUnitSelections array,
     * attained courseUnit or substituted courseUnit.
     */
    private static cleanAssessmentItemSelections(plan: Plan, attainments: Attainment[]): void {
        const courseUnitAttainments = _.filter(attainments, { type: 'CourseUnitAttainment' });
        const attainedCourseUnitIds = _.map(courseUnitAttainments, 'courseUnitId');
        const substitutedSelections = _.filter(plan.courseUnitSelections, selection => !_.isEmpty(_.get(selection, 'substitutedBy', [])));
        const substitutedCourseUnitIds = _.map(substitutedSelections, 'courseUnitId');
        const courseUnitIds = _.map(plan.courseUnitSelections, 'courseUnitId');
        plan.assessmentItemSelections = _.filter(plan.assessmentItemSelections, (aiSelection) => {
            const referredCourseUnitId = _.get(aiSelection, 'courseUnitId');
            return _.includes(courseUnitIds, referredCourseUnitId) &&
                !_.includes(attainedCourseUnitIds, referredCourseUnitId) &&
                !_.includes(substitutedCourseUnitIds, referredCourseUnitId);
        });
    }

    private static replaceCustomStudyDraftsWithCorrespondingAttainments(plan: Plan, allAttainments: Attainment[]): void {
        const replacedCustomStudyDraftIds: LocalId[] = [];
        _.forEach(plan.customStudyDrafts, (customStudyDraft) => {
            const correspondingCustomCourseUnitAttainment = _.find(allAttainments, attainment =>
                attainment.type === 'CustomCourseUnitAttainment' &&
                (attainment as CustomCourseUnitAttainment).customStudyDraftId === customStudyDraft.id &&
                !this.isAttached(attainment, allAttainments) &&
                !this.isCustomCourseUnitAttainmentInPlanSelections(plan, attainment.id));
            if (correspondingCustomCourseUnitAttainment) {
                const newCustomCourseUnitAttainmentSelection = {
                    parentModuleId: customStudyDraft.parentModuleId,
                    customCourseUnitAttainmentId: correspondingCustomCourseUnitAttainment.id,
                };
                plan.customCourseUnitAttainmentSelections.push(newCustomCourseUnitAttainmentSelection);
                replacedCustomStudyDraftIds.push(customStudyDraft.id);
            }
        });
        _.remove(plan.customStudyDrafts, customStudyDraft =>
            _.includes(replacedCustomStudyDraftIds, customStudyDraft.id));
    }

    private static isCustomCourseUnitAttainmentInPlanSelections(plan: Plan, customCourseUnitAttainmentId: OtmId): boolean {
        return !!_.find(plan.customCourseUnitAttainmentSelections, { customCourseUnitAttainmentId });
    }

    private static updateSubstituteReferences(courseUnitAttainment: CourseUnitAttainment, substituteCourseUnit: CourseUnit,
                                              courseUnitSelections: CourseUnitSelection[]): void {
        // replace courseUnitId of substitute courseUnitSelection with attained version

        const substituteSelection = _.find(courseUnitSelections, { courseUnitId: substituteCourseUnit.id });
        if (!_.isNil(substituteSelection)) {
            substituteSelection.courseUnitId = courseUnitAttainment.courseUnitId;
        }

        // update substitutedBy id's in substituted courseUnitSelections

        const substitutedSelections = _.filter(courseUnitSelections, (courseUnitSelection) => _.includes(_.get(courseUnitSelection, 'substitutedBy', []), substituteCourseUnit.id));
        _.forEach(substitutedSelections, (substitutedSelection) => {
            _.pull(substitutedSelection.substitutedBy, substituteCourseUnit.id);
            substitutedSelection.substitutedBy.push(courseUnitAttainment.courseUnitId);
        });
    }

    private static isSelectionConnectedToPlanRoot(selection: AnySelection, plan: Plan): boolean {
        if (_.isNil(selection.parentModuleId)) {
            if (_.get(selection, 'moduleId') === plan.rootId) {
                return true;
            }
            if (!_.has(selection, 'courseUnitId')) {
                return false;
            }
            const substitutedSelections = this.getSubstitutedSelections(selection as CourseUnitSelection, plan);
            return _.some(substitutedSelections, substitutedSelection => this.isSelectionConnectedToPlanRoot(substitutedSelection, plan));
        }
        if (selection.parentModuleId === plan.rootId) {
            return true;
        }
        const parentSelection = _.find(plan.moduleSelections, { moduleId: selection.parentModuleId });
        return parentSelection ? this.isSelectionConnectedToPlanRoot(parentSelection, plan) : false;
    }

    private static getSubstitutedSelections(selection: CourseUnitSelection, plan: Plan): CourseUnitSelection[] {
        if (_.isNil(selection)) {
            return [];
        }

        return (plan.courseUnitSelections || [])
            .filter(courseUnitSelection => _.includes(courseUnitSelection.substitutedBy, selection.courseUnitId));
    }

    private static removeSelectionsWithModuleIdAsParent(selectionsArray: AnySelection[], moduleId: OtmId): void {
        _.remove(selectionsArray, { parentModuleId: moduleId });
    }

    private static removeSelectionsNotConnectedToPlanRoot(selectionsArray: AnySelection[], plan: Plan): void {
        _.remove(selectionsArray, selection => !this.isSelectionConnectedToPlanRoot(selection, plan));
    }

    /**
     * @param attainment that may be attached
     * @param allAttainments potential parents
     * @returns true if parent attainment was found, false otherwise
     */
    private static isAttached(attainment: Attainment, allAttainments: Attainment[]): boolean {
        if (_.isNil(attainment) || _.isEmpty(allAttainments)) {
            return false;
        }

        return allAttainments.some(potentialParent => this.toChildAttainmentIds(potentialParent).includes(attainment.id));
    }

    /**
     * If the attainment is a CourseUnitAttainment, returns all the assessment item ids of the attainment.
     * If the attainment is a ModuleAttainment, DegreeProgrammeAttainment, or a CustomModuleAttainment, returns
     * the ids of the attainments grouped under it (direct children only).
     * Otherwise returns an empty array.
     */
    private static toChildAttainmentIds(attainment: Attainment): OtmId[] {
        if (_.isNil(attainment)) {
            return [];
        }
        if (isModuleLikeAttainment(attainment)) {
            return getAllLeafNodes(attainment).map(node => node.attainmentId);
        }
        if (attainment.type === AttainmentType.COURSE_UNIT_ATTAINMENT) {
            return ((attainment as CourseUnitAttainment).assessmentItemAttainmentIds || []).filter(notNil);
        }

        return [];
    }

    private static removeDuplicateSelections(plan: Plan) {
        plan.courseUnitSelections = _.uniqBy(
            (plan.courseUnitSelections || []),
            'courseUnitId',
        );
        plan.moduleSelections = _.uniqBy(
            (plan.moduleSelections || []),
            'moduleId',
        );
        plan.customCourseUnitAttainmentSelections = _.uniqBy(
            (plan.customCourseUnitAttainmentSelections || []),
            'customCourseUnitAttainmentId',
        );
        plan.customModuleAttainmentSelections = _.uniqBy(
            (plan.customModuleAttainmentSelections || []),
            'customModuleAttainmentId',
        );
        plan.assessmentItemSelections = _.uniqBy(
            (plan.assessmentItemSelections || []),
            aiSelection => `${aiSelection.assessmentItemId}-${aiSelection.courseUnitId}`,
        );
        plan.customStudyDrafts = _.uniqBy(
            (plan.customStudyDrafts || []),
            'id',
        );
    }
}
