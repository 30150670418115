import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Enrolment } from 'common-typescript/types';

/** Wraps the legacy AngularJS frontpage in an Angular component so that it's usable in UI-Router state declarations. */
@Component({
    selector: 'app-frontpage-wrapper',
    template: '<frontpage [userHasPlans]="userHasPlans" [enrolments]="enrolments"/>',
    encapsulation: ViewEncapsulation.None,
})
export class FrontpageWrapperComponent {
    @Input() userHasPlans: boolean;
    @Input() enrolments: Enrolment[];
}
