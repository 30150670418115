import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { EducationPhaseProgress } from 'common-typescript/types';

@Component({
    selector: 'sis-education-phase-progress',
    templateUrl: './education-phase-progress.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EducationPhaseProgressComponent {

    @Input() educationPhaseProgress: EducationPhaseProgress;
}
