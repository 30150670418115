import { Component, Inject, Input, OnChanges, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { ValidatablePlan } from 'common-typescript/src/plan/validation/validatablePlan';
import {
    CourseUnit,
    CourseUnitResultItem,
    CurriculumPeriod,
    CustomCourseUnitAttainment,
    CustomModuleAttainment,
    CustomStudyDraft,
    EntityWithRule,
    Module,
    ModuleResultItem,
    OtmId,
    StudyRight,
} from 'common-typescript/types';
import _ from 'lodash';
import { DEFAULT_PROMISE_HANDLER } from 'sis-common/ajs-upgraded-modules';

import {
    CURRICULUM_PERIOD_SERVICE,
} from '../../ajs-upgraded-modules';
import { PlanData, PlanStateObject } from '../../service/plan-state.service';
import { RawPlanEditService } from '../../service/raw-plan-edit.service';
import { InlineSearchTarget } from '../inline-search/inline-search.component';
import { PLAN_ACTIONS_SERVICE_INJECTION_TOKEN, PlanActionsService } from '../plan-actions-service/plan-actions.service';

@Component({
    selector: 'sis-free-edit',
    templateUrl: './free-edit.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class FreeEditComponent implements OnInit, OnChanges {

    @Input() parentModule: EntityWithRule;
    @Input() validatablePlan: ValidatablePlan;
    @Input() planValidationResult: any;
    @Input() planStateObject: PlanStateObject;
    @Input() planData: PlanData;
    @Input() matchingStudyRight: StudyRight;

    courseUnitAttainmentsExpanded = false;
    moduleAttainmentsExpanded = false;

    selectedCourseUnits: CourseUnit[] = [];
    selectedCustomCourseUnitAttainments: CustomCourseUnitAttainment[] = [];
    selectedModules: Module[] = [];
    selectedCustomModuleAttainments: CustomModuleAttainment[] = [];

    selectableAttainedCourseUnits: CourseUnit[] = [];
    selectableCustomCourseUnitAttainments: CustomCourseUnitAttainment[] = [];
    selectableAttainedModules: Module[] = [];
    selectableCustomModuleAttainments: CustomModuleAttainment[] = [];
    selectedCustomStudyDrafts: CustomStudyDraft[] = [];

    curriculumPeriodIdsForSearch: OtmId[];

    readonly SEARCH_TARGET = InlineSearchTarget;

    constructor(
        private rawPlanEditService: RawPlanEditService,
        @Optional() @Inject(PLAN_ACTIONS_SERVICE_INJECTION_TOKEN) private planActionsService: PlanActionsService,
        @Inject(CURRICULUM_PERIOD_SERVICE) private curriculumPeriodService: any,
        @Inject(DEFAULT_PROMISE_HANDLER) private defaultPromiseHandler: any,
    ) {}

    ngOnInit() {
        this.curriculumPeriodService.getCurriculumPeriodsForSearch(this.validatablePlan.plan.curriculumPeriodId).then((curriculumPeriods: CurriculumPeriod[]) => {
            this.curriculumPeriodIdsForSearch = _.map(curriculumPeriods, 'id');
        }).catch(this.defaultPromiseHandler.loggingRejectedPromiseHandler);
    }

    ngOnChanges() {
        const moduleStateObject = _.get(this.planStateObject.modules, this.parentModule.id);
        if (moduleStateObject) {
            this.selectedCourseUnits = _.map(moduleStateObject.selectedCourseUnitIds, courseUnitId =>
                _.get(this.planData.courseUnitsById, courseUnitId));
            this.selectedCustomCourseUnitAttainments = _.map(moduleStateObject.selectedCustomCourseUnitAttainmentIds, ccuaId =>
                _.get(this.planData.attainmentsById, ccuaId) as CustomCourseUnitAttainment);
            this.selectedModules = _.map(moduleStateObject.selectedModuleIds, moduleId =>
                _.get(this.planData.modulesById, moduleId));
            this.selectedCustomModuleAttainments = _.map(moduleStateObject.selectedCustomModuleAttainmentIds, cmaId =>
                _.get(this.planData.attainmentsById, cmaId) as CustomModuleAttainment);
            this.selectedCustomStudyDrafts = _.map(moduleStateObject.selectedCustomStudyDraftIds, customStudyDraftId =>
                _.get(this.planData.customStudyDraftsById, customStudyDraftId));
            const unattachedAttainments = this.rawPlanEditService.getAllUnattachedAttainments(_.values(this.validatablePlan.attainmentsById));
            this.selectableAttainedCourseUnits = this.rawPlanEditService.getSelectableAttainedCourseUnits(unattachedAttainments, this.validatablePlan);
            this.selectableCustomCourseUnitAttainments = this.rawPlanEditService.getSelectableCustomCourseUnitAttainments(
                unattachedAttainments,
                this.validatablePlan,
                this.matchingStudyRight,
            );
            this.selectableAttainedModules = this.rawPlanEditService.getSelectableAttainedModules(unattachedAttainments, this.validatablePlan);
            this.selectableCustomModuleAttainments = this.rawPlanEditService.getSelectableCustomModuleAttainments(
                unattachedAttainments,
                this.validatablePlan,
                this.matchingStudyRight,
            );
        }
    }

    selectCourseUnit(courseUnit: CourseUnit) {
        this.planActionsService?.forceSelectCourseUnitById?.(courseUnit.id, this.parentModule);
    }

    selectCustomCourseUnitAttainment(customCourseUnitAttainment: CustomCourseUnitAttainment) {
        this.planActionsService?.selectCustomCourseUnitAttainment?.(customCourseUnitAttainment, this.parentModule);
    }

    selectModule(module: Module) {
        this.planActionsService?.forceSelectModuleById?.(module.id, this.parentModule);
    }

    selectCustomModuleAttainment(customModuleAttainment: CustomModuleAttainment) {
        this.planActionsService?.selectCustomModuleAttainment?.(customModuleAttainment, this.parentModule);
    }

    unselectCourseUnit(courseUnit: CourseUnit) {
        this.planActionsService?.forceRemoveCourseUnit?.(courseUnit, this.parentModule);
    }

    unselectCustomCourseUnitAttainment(customCourseUnitAttainment: CustomCourseUnitAttainment) {
        this.planActionsService?.removeCustomCourseUnitAttainmentById?.(customCourseUnitAttainment.id, this.parentModule);
    }

    unselectModule(module: Module) {
        this.planActionsService?.forceRemoveModule?.(module, this.parentModule);
    }

    unselectCustomModuleAttainment(customModuleAttainment: CustomModuleAttainment) {
        this.planActionsService?.removeCustomModuleAttainmentById?.(customModuleAttainment.id, this.parentModule);
    }

    unselectCustomStudyDraft(customStudyDraft: CustomStudyDraft) {
        this.planActionsService?.removeCustomStudyDraft?.(customStudyDraft, this.parentModule);
    }

    onSelectCourseUnitResultItem(courseUnitResultItem: CourseUnitResultItem) {
        const attainedCourseUnitVersionId = this.rawPlanEditService.findAttainedVersionIdForCourseUnit(
            courseUnitResultItem.groupId,
            _.values(this.validatablePlan.getAllAttainments()),
        );
        const courseUnitIdToBeSelected = attainedCourseUnitVersionId || courseUnitResultItem.id;
        this.planActionsService?.forceSelectCourseUnitById?.(courseUnitIdToBeSelected, this.parentModule);
    }

    onSelectModuleResultItem(moduleResultItem: ModuleResultItem) {
        const attainedModuleVersionId = this.rawPlanEditService.findAttainedVersionIdForModule(
            moduleResultItem.groupId,
            _.values(this.validatablePlan.getAllAttainments()),
        );

        const moduleIdToBeSelected = attainedModuleVersionId || moduleResultItem.id;
        this.planActionsService?.forceSelectModuleById?.(moduleIdToBeSelected, this.parentModule);
    }

    openCustomStudyDraftCreateModal() {
        this.planActionsService?.openCustomStudyDraftCreationModal?.(this.parentModule);
    }
}
