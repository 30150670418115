<div class="row editable-attainment-tree-group-node-row">
  <div class="col-12 name">
    <button type="button" class="non-button" (click)="collapsed = !collapsed" [attr.aria-expanded]="!collapsed"
            [attr.aria-label]="groupingNode.name|localizedStringWithoutFallback:selectedLocale">
      <sis-icon icon="chevron" [iconClass]="collapsed ? null : 'cw-90'"></sis-icon>
    </button>
    <span data-cy="tree-node-name">{{groupingNode.name|localizedStringWithoutFallback:selectedLocale}}</span>
  </div>
</div>
<sis-attainment-tree-nodes
  [hidden]="collapsed"
  [attainmentNodes]="groupingNode.nodes"
  [attainmentsById]="attainmentsById"
  [selectedLocale]="selectedLocale">
</sis-attainment-tree-nodes>
