import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { DegreeProgrammeAttainmentApplication,
    DegreeProgrammeAttainmentApplicationAnswer, DegreeProgrammeAttainmentWorkflow } from 'common-typescript/types';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-degree-programme-attainment-application-answers',
    templateUrl: './degree-programme-attainment-application-answers.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DegreeProgrammeAttainmentApplicationAnswersComponent implements OnInit, OnChanges {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.applications.degreeProgrammeAttainmentApplication.degreeProgrammeAttainmentApplicationAnswers',
        directiveName: 'sisDegreeProgrammeAttainmentApplicationAnswers',
    };

    /**
     * @deprecated Use {@link workflow} instead
     */
    @Input() application: DegreeProgrammeAttainmentApplication;

    @Input() workflow: DegreeProgrammeAttainmentWorkflow;

    /**
     * Heading level to use for the title. Defaults to <h3>.
     */
    @Input() headingLevel = 3;

    questionnaireAnswers: DegreeProgrammeAttainmentApplicationAnswer[] = [];

    ngOnInit() {
        if (!!this.application) {
            this.questionnaireAnswers = this.application?.questionnaireAnswers;
        }
        if (!!this.workflow) {
            this.questionnaireAnswers = this.workflow?.questionnaireAnswers;
        }
    }

    ngOnChanges(simpleChanges: SimpleChanges): void {
        if (simpleChanges.application?.currentValue !== simpleChanges.application?.previousValue) {
            this.questionnaireAnswers = this.application?.questionnaireAnswers;
        }
        if (simpleChanges.workflow?.currentValue !== simpleChanges.workflow?.previousValue) {
            this.questionnaireAnswers = this.workflow?.questionnaireAnswers;
        }
    }

}
