import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import angular from 'angular';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-sortable-table-column-header',
    templateUrl: './sortable-table-column-header.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class SortableTableColumnHeaderComponent implements OnChanges {
    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.sortableTableColumnHeader',
        directiveName: 'sisSortableTableColumnHeader',
        dependencies: [],
    };

    /* The `$scope` of the parent AngularJS component.
      Required when this component is used inside an AngularJS component to prevent any delays in change detection. */
    @Input() scope?: angular.IScope;
    @Input() name: string;
    @Input() column: string;
    @Input() orderKey: string;
    @Input() reverse: boolean;
    /* Defines the correct translation key for the sorting button's aria-label. The key describes how the column is sorted.  */
    @Input() ariaLabelType: OrderType = 'ALPHABETICAL_ORDER';
    @Output() orderKeyChange = new EventEmitter<void>();
    @Output() reverseChange = new EventEmitter<boolean>();

    ariaLiveMessage: string;
    ariaSortValue: string;

    ngOnChanges(changes: SimpleChanges) {
        this.ariaSortValue = this.orderKey === this.column ? this.reverse ? 'descending' : 'ascending' : 'none';
        this.ariaLiveMessage = (this.reverse ? 'ARIA_LABEL.DESCENDING_' : 'ARIA_LABEL.ASCENDING_') + this.ariaLabelType;
    }

    sortBy(): void {
        if (this.scope) {
            this.scope.$apply(() => {
                this.orderKeyChange.emit();
            });
        } else {
            this.orderKeyChange.emit();
        }
    }
}

export type OrderType = 'ALPHABETICAL_ORDER' | 'DATE' | 'DATE_TIME' | 'COURSE_UNIT_APPROVAL_STATE' | 'OPEN_UNIVERSITY_PRODUCT_STATE';
