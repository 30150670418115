<fudis-dl [columns]="{ sm: 1, md: 2 }" [marginBottom]="'lg'" *transloco="let t">
  <fudis-dl-item>
    <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.PERSON_INFO.FIRST_NAMES')"/>
    <fudis-dd [contentText]="person?.firstNames"/>
  </fudis-dl-item>
  <fudis-dl-item>
    <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.PERSON_INFO.LAST_NAME')"/>
    <fudis-dd [contentText]="person?.lastName"/>
  </fudis-dl-item>
  <fudis-dl-item>
    <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.PERSON_INFO.STUDENT_NUMBER')"/>
    <fudis-dd [contentText]="person?.studentNumber"/>
  </fudis-dl-item>
  <fudis-dl-item>
    <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.PERSON_INFO.DATE_OF_BIRTH')"/>
    <fudis-dd [contentText]="person?.dateOfBirth|localDateFormat"/>
  </fudis-dl-item>
  <fudis-dl-item>
    <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.PERSON_INFO.MOTHER_TONGUE')"/>
    <fudis-dd [contentText]="person?.motherTongueUrn|codeLocalizedName|async"/>
  </fudis-dl-item>
  <fudis-dl-item>
    <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.PERSON_INFO.PREFERRED_LANGUAGE')"/>
    <fudis-dd [contentText]="person?.preferredLanguageUrn|codeLocalizedName|async"/>
  </fudis-dl-item>
</fudis-dl>

