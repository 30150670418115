<completion-methods
  [plan]="plan"
  [attainments]="attainments"
  [courseUnit]="courseUnit"
  [completionMethods]="completionMethods"
  [courseUnitRealisation]="courseUnitRealisation"
  [forceEnableCalendarAdd]="forceEnableCalendarAdd"
  [language]="language"
  [level]="3"
  [versionChangeInProgress]="versionChangeInProgress"
  [unplannedCourseUnit]="unplannedCourseUnit"
  [module]="module"
  [realisationTabId]="realisationTabId"
></completion-methods>
