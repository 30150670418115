import { inject, Injectable } from '@angular/core';
import {
    NgbDatepickerI18nDefault,
    NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import 'moment/locale/sv';
import moment from 'moment';
import { LocaleService } from 'sis-common/l10n/locale.service';

/**
 * Dynamic localization for the ngb datepicker.
 */
@Injectable({
    providedIn: 'root',
})
export class DatePickerI18n extends NgbDatepickerI18nDefault {

    localeService = inject(LocaleService);

    get locale() {
        return this.localeService.getCurrentLanguage();
    }

    override getDayAriaLabel(date: NgbDateStruct): string {
        const { day, month, year } = date;
        return Intl.DateTimeFormat(this.locale, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }).format(new Date(year, month - 1, day));
    }

    override getMonthFullName(month: number, year?: number): string {
        const date = new Date();
        date.setDate(1);
        date.setMonth(month - 1);
        return Intl.DateTimeFormat(this.locale, { month: 'long' }).format(date);
    }

    override getMonthShortName(month: number, year?: number): string {
        const date = new Date();
        date.setDate(1);
        date.setMonth(month - 1);
        return Intl.DateTimeFormat(this.locale, { month: 'short' }).format(date);
    }

    override getWeekdayLabel(weekday: number, width?: Exclude<Intl.DateTimeFormatOptions['weekday'], undefined>): string {
        return moment().locale(this.locale).isoWeekday(weekday).format('ddd');
    }
}
