import { UuidService } from 'sis-common/uuid/uuid.service.ts';
import { moduleResponsibilityInfoTypeModule } from '../model/moduleResponsibilityInfoType.model';
import '../model/educationResponsibilityInfoType.model';
import './jsDataCacheHelper.service';
import '../model/courseUnitRealisationResponsibilityInfoType.model';
import '../model/curriculumPeriod.model';
import '../model/groupResponsibilityInfoType.model';
import '../model/organisationRole.model';
import '../model/organisation.model';
import '../model/publicPerson.model';
export const jsDataRelationHelperModule = 'sis-components.service.jsDataRelationHelper';
(function () {
  jsDataRelationHelperService.$inject = ["jsDataCacheHelper", "moduleResponsibilityInfoTypeJSDataModel", "educationResponsibilityInfoTypeJSDataModel", "curriculumPeriodModel", "courseUnitRealisationResponsibilityInfoTypeModel", "organisationJSDataModel", "organisationRoleModel", "publicPersonModel", "$injector"];
  angular.module(jsDataRelationHelperModule, [moduleResponsibilityInfoTypeModule, 'sis-components.model.educationResponsibilityInfoType', 'sis-components.service.jsDataCacheHelper', 'sis-components.model.courseUnitRealisationResponsibilityInfoType', 'sis-components.model.curriculumPeriod', 'sis-components.model.groupResponsibilityInfoType', UuidService.downgrade.moduleName, 'sis-components.model.organisationRole', 'sis-components.model.organisation', 'sis-components.model.publicPerson']).factory('jsDataRelationHelperService', jsDataRelationHelperService);

  /**
   * @ngInject
   */
  function jsDataRelationHelperService(jsDataCacheHelper, moduleResponsibilityInfoTypeJSDataModel, educationResponsibilityInfoTypeJSDataModel, curriculumPeriodModel, courseUnitRealisationResponsibilityInfoTypeModel, organisationJSDataModel, organisationRoleModel, publicPersonModel, $injector) {
    function loadRelatedObjects(objectsToBeMapped, relationType) {
      var relationObjectModel = relationType.baseModel;
      var objectIds = _.flatMap(objectsToBeMapped, function (objectToBeMapped) {
        return objectToBeMapped[relationType.idField];
      });
      return jsDataCacheHelper.findByIds(relationObjectModel, objectIds, false, undefined, relationType.endpoint);
    }
    function relationLoader(objectsToBeMapped, roleModel, relationType) {
      var relationObjectModel = relationType.baseModel;

      // Inject uuidService manually since the injectors are not available at this point when using downgraded services
      const uuidService = $injector.get('uuidService');
      return loadRelatedObjects(objectsToBeMapped, relationType).then(function () {
        _.forEach(objectsToBeMapped, function (objectToBeMapped) {
          objectToBeMapped._id = uuidService.randomUUID();
          if (objectToBeMapped.roleUrn) {
            objectToBeMapped.role = roleModel.get(objectToBeMapped.roleUrn);
          }
          if (objectToBeMapped[relationType.idField]) {
            objectToBeMapped[relationType.objField] = relationObjectModel.get(objectToBeMapped[relationType.idField]);
          }
        });
      });
    }
    var roleRelationType = {
      baseModel: publicPersonModel,
      idField: 'personId',
      objField: 'person'
    };
    var organisationRelationType = {
      baseModel: organisationJSDataModel,
      idField: 'organisationId',
      objField: 'organisation',
      endpoint: '/kori/api/organisation-snapshot-search'
    };
    var universityOrganisationRelationType = {
      baseModel: organisationJSDataModel,
      idField: 'universityOrgIds'
    };
    const curriculumPeriodRelationType = {
      baseModel: curriculumPeriodModel,
      idField: 'curriculumPeriodIds',
      objField: 'curriculumPeriod'
    };
    return {
      loadCourseUnitRealisationResponsibilityInfoRelations: function (responsibilityInfos) {
        return relationLoader(responsibilityInfos, courseUnitRealisationResponsibilityInfoTypeModel, roleRelationType);
      },
      loadModuleResponsibilityInfoRelations: function (responsibilityInfos) {
        return relationLoader(responsibilityInfos, moduleResponsibilityInfoTypeJSDataModel, roleRelationType);
      },
      loadEducationResponsibilityInfoRelations: function (responsibilityInfos) {
        return relationLoader(responsibilityInfos, educationResponsibilityInfoTypeJSDataModel, roleRelationType);
      },
      loadResponsibilityInfoRelations: function (responsibilityInfos, roleInfoTypeModel, personModel) {
        return relationLoader(responsibilityInfos, roleInfoTypeModel, {
          baseModel: personModel,
          idField: 'personId',
          objField: 'person'
        });
      },
      loadOrganisationRelations: function (organisations) {
        return relationLoader(organisations, organisationRoleModel, organisationRelationType);
      },
      loadAcceptorPersonRelations: function (acceptorPersons) {
        return relationLoader(acceptorPersons, publicPersonModel, roleRelationType);
      },
      loadUniversityOrgs: function (objects) {
        return loadRelatedObjects(objects, universityOrganisationRelationType);
      },
      loadCurriculumPeriods: function (objects) {
        return loadRelatedObjects(objects, curriculumPeriodRelationType);
      },
      loadRelationForArray: function (relationJsDataModel, objectJsDataModel, objects, bypassCache, relationId, objectsWithoutRelation = []) {
        var relationModelName = relationJsDataModel.name;
        var idAttribute = relationId || relationModelName + 'Id';
        var ids = _.chain(objects).map(idAttribute).uniq().compact().value();
        return jsDataCacheHelper.findByIds(relationJsDataModel, ids, bypassCache).then(function (relationObjects) {
          _.forEach(objects, function (objectWithRelation) {
            objectWithRelation[relationModelName] = _.find(relationObjects, {
              id: objectWithRelation[idAttribute]
            });
          });
          return objects.concat(objectsWithoutRelation);
        });
      },
      /**
       * Loads a specific 'hasMany'-relation for an array of objects.
       *
       * @param relationJsDataModel The js-data model definition for the referenced objects to load
       * @param objects The array of js-data model instances which have references to relationJsDataModel
       * @param bypassCache Whether to bypass js-data caches
       * @param assignedRelationName The name assigned to the relation to load in the given objects. Defaults to the
       * model name defined in relationJsDataModel. E.g. if relationJsDataModel is the definition for PublicPerson,
       * this argument defaults to 'publicPerson', in which case the objects should have a field 'publicPersonIds',
       * and this function will assign the fetched people to the field 'publicPersons'. If, however, the objects
       * have a field 'teacherIds' which contain the person ids, this argument should be 'teacher', in which case
       * the fetched people will be assigned to the field 'teachers'.
       * @returns {Promise<Array>} The array of input objects with the specified relation loaded
       */
      loadArrayRelationForArray(relationJsDataModel, objects = [], bypassCache = false, assignedRelationName = relationJsDataModel.name) {
        const idArrayProperty = `${assignedRelationName}Ids`;
        const objectArrayProperty = `${assignedRelationName}s`;
        const ids = _.chain(objects).flatMap(idArrayProperty).uniq().compact().value();
        return jsDataCacheHelper.findByIds(relationJsDataModel, ids, bypassCache).then(allRelationObjects => objects.filter(object => !!object).map(object => {
          let relationObjects;
          if (_.isNil(object[idArrayProperty])) {
            relationObjects = null;
          } else if (_.isEmpty(object[idArrayProperty])) {
            relationObjects = [];
          } else {
            const idAttribute = _.get(relationJsDataModel, 'idAttribute', 'id');
            relationObjects = allRelationObjects.filter(relationObject => object[idArrayProperty].includes(relationObject[idAttribute]));
          }
          return Object.assign(object, {
            [objectArrayProperty]: relationObjects
          });
        }));
      }
    };
  }
})();