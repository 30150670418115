<span *ngIf="studyTermLocators.length > 1" class="course-unit-timing-info-tooltip">
  <sis-tooltip [appendToBody]="true" [tooltipBlockElement]="true">
    <sis-icon icon="info-circle" color="primary" class="sis-tooltip-trigger-content"></sis-icon>
    <div class="text-left sis-tooltip-content">
      <span *ngIf="!isCustomStudyDraft" translate>STUDENT_APPLICATIONS.EXTENSION_APPLICATION.COURSE_UNIT_TIMING_INFO</span>
      <span *ngIf="isCustomStudyDraft" translate>STUDENT_APPLICATIONS.EXTENSION_APPLICATION.CUSTOM_STUDY_DRAFT_TIMING_INFO</span>
      <span *ngFor="let locator of studyTermLocators ; last as isLast">
        {{(locator.termIndex === 0 ? 'STUDY_TERMS.AUTUMN' : 'STUDY_TERMS.SPRING' )| translate}}
        {{locator.studyYearStartYear + locator.termIndex}}{{isLast ? '' : ', '}}
      </span>
    </div>
  </sis-tooltip>
</span>
