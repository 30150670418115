import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import {
    Enrolment,
    EnrolmentCalculationConfigForPerson,
    EnrolmentGetRequestForOpenUniStudent,
    EnrolmentReservationRequest,
    EnrolmentUpdateByStudentRequest,
    EnrolRequest,
    LocalDateRange,
    OtmId,
} from 'common-typescript/types';
import moment from 'moment/moment';
import { combineLatest, Observable, of, switchMap, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { mono } from 'sis-common/mono/monoUtils';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';
import { CourseUnitRealisationEntityService } from 'sis-components/service/course-unit-realisation-entity.service';
import { EntityService } from 'sis-components/service/entity.service';
import { SisuDataLoader } from 'sis-components/service/SisuDataLoader';
import { SisuSimpleDataLoader } from 'sis-components/service/SisuSimpleDataLoader';

/**
 * A service for handling a student's own enrolments. The store will only contain enrolments made by the currently
 * logged-in user.
 *
 * This service utilises [Akita's caching support](https://opensource.salesforce.com/akita/docs/additional/cache/).
 * The store is marked as cached when all the student's enrolments are fetched using `getAllEnrolments()`, after
 * which all queries will be made against the store instead of the backend by default (this can be overridden with
 * the `bypassStore` argument).
 */
@StaticMembers<DowngradedService>()
@Injectable({ providedIn: 'root' })
@NgEntityServiceConfig({
    baseUrl: '/ilmo/api',
    resourceName: 'enrolments',
})
export class EnrolmentStudentService extends EntityService<EnrolmentEntityState> {

    static downgrade: ServiceDowngradeMappings = {
        dependencies: [],
        moduleName: 'student.service.enrolmentStudentService',
        serviceName: 'enrolmentStudentService',
    };

    private readonly courseUnitRealisationEnrolmentLoader: SisuDataLoader<OtmId, Enrolment, Enrolment>;
    private readonly calculationConfigForPersonLoader: SisuDataLoader<OtmId, EnrolmentCalculationConfigForPerson, EnrolmentCalculationConfigForPerson>;
    private readonly allEnrolmentsLoader: SisuSimpleDataLoader<Enrolment[]>;

    constructor(private courseUnitRealisationService: CourseUnitRealisationEntityService) {
        super(EnrolmentStore, EnrolmentQuery);
        this.courseUnitRealisationEnrolmentLoader = new SisuDataLoader<OtmId, Enrolment, Enrolment>({
            getByIdsCall: curIds => this.createFindOwnEnrolmentsRequest(curIds),
            resultExtractor: (curId, enrolments) => enrolments.find(enrolment => enrolment.courseUnitRealisationId === curId),
            successEntitiesCallback: enrolments => enrolments?.length > 0 && this.store.upsertMany(enrolments),
        });
        this.calculationConfigForPersonLoader = new SisuDataLoader<OtmId, EnrolmentCalculationConfigForPerson, EnrolmentCalculationConfigForPerson>({
            getByIdsCall: curIds => this.createGetConfigsForPersonByCurIdsCall(curIds),
            resultExtractor: (curId, configs) => configs.find(config => config.courseUnitRealisationId === curId),
        });
        this.allEnrolmentsLoader = new SisuSimpleDataLoader({
            requestCreator: () => mono(this.createFindOwnEnrolmentsRequest()),
            onBeforeRequest: () => this.setLoading(true),
            onAfterRequest: {
                // Using EntityStore#set() marks the store as cached and clears the loading state
                next: enrolments => this.store.set(enrolments),
                error: () => this.setLoading(false),
            },
        });
    }

    storeUpsert(enrolment: Enrolment) {
        this.store.upsert(enrolment.id, enrolment);
    }

    storeRemove(enrolment: Enrolment) {
        this.store.remove(enrolment.id);
    }

    /**
     * Get an enrolment by its id.
     *
     * Overrides the implementation from the parent class which utilizes SisuDataLoader, as the enrolment REST API doesn't
     * support querying enrolments with multiple ids. Due to this, calling `getByIds()` from the parent class will result
     * in a separate backend request for each given id.
     */
    override getById(id: OtmId, bypassStore = false): Observable<Enrolment> {
        if (!id) {
            return throwError(() => new Error('Enrolment id was missing'));
        }
        if (!bypassStore && (this.query.getHasCache() || this.query.hasEntity(id))) {
            return this.selectEntity(id);
        }

        return this.getHttp().get<Enrolment>(`${this.api}/${id}`)
            .pipe(this.upsertAndSwitchToStoreObservable());
    }

    /**
     * Get all the current user's enrolments. Returns a store observable that emits whenever the store contents change
     * (i.e. enrolments are added/removed/modified).
     *
     * **Note:** By default, this method won't fetch anything from the backend if the store contains any entities. Pass
     * `true` as the `bypassStore` argument if you need to get all the enrolments from the backend.
     */
    getAllEnrolments(bypassStore = false): Observable<Enrolment[]> {
        if (!bypassStore && this.query.getHasCache()) {
            return this.selectAll();
        }

        return this.allEnrolmentsLoader.load()
            .pipe(switchMap(() => this.selectAll()));
    }

    getCalculationConfig(courseUnitRealisationId: OtmId): Observable<EnrolmentCalculationConfigForPerson> {
        return this.calculationConfigForPersonLoader.load(courseUnitRealisationId);
    }

    /**
     * Gets all enrolments for course unit realisations that are either ongoing or starting within a week, sorted
     * by course unit realisation activity start date.
     */
    getOngoingAndUpcomingEnrolments(bypassStore = false): Observable<Enrolment[]> {
        return this.getAllEnrolments(bypassStore)
            .pipe(
                map((enrolments) => enrolments.filter(enrolment => enrolment.isInCalendar)),
                switchMap((enrolments) => this.courseUnitRealisationService
                    .getByIds(enrolments.map(enrolment => enrolment.courseUnitRealisationId))
                    .pipe(
                        map(curs => curs.filter(cur => this.isOngoingOrStartingWithinAWeek(cur.activityPeriod))),
                        map(curs => this.courseUnitRealisationService.sortByActivityPeriodAndName(curs)),
                        map(curs => curs.map(cur => enrolments.find(enrolment => enrolment.courseUnitRealisationId === cur.id))),
                        map(ongoingAndUpcomingEnrolments => ongoingAndUpcomingEnrolments.filter(Boolean)),
                    ),
                ),
            );
    }

    getOpenUniversityEnrolments(request: EnrolmentGetRequestForOpenUniStudent[]): Observable<Enrolment[]> {
        return this.getHttp().post<Enrolment[]>(`${this.apiOpenUniversity}/by-cart-data`, request)
            .pipe(this.upsertManyAndSwitchToStoreObservable());
    }

    /**
     * Finds an enrolment for the given course unit realisation, made by the currently logged-in user. Returns an observable
     * that emits when a matching enrolment is added/removed/modified. I.e. even if the observable emits `null` initially,
     * if the user later enrols to the CUR while the observable is still open, the new enrolment will be emitted.
     */
    findEnrolment(courseUnitRealisationId: OtmId, bypassStore = false): Observable<Enrolment> {
        if (!courseUnitRealisationId) {
            return throwError(() => new Error('Course unit realisation id was missing'));
        }

        const curIdMatcher = (enrolment: Enrolment) => enrolment.courseUnitRealisationId === courseUnitRealisationId;
        if (!bypassStore && (this.query.getHasCache() || this.query.hasEntity(curIdMatcher))) {
            return this.query.selectEntity(curIdMatcher);
        }

        return this.courseUnitRealisationEnrolmentLoader.load(courseUnitRealisationId)
            .pipe(switchMap(() => this.query.selectEntity(enrolment => enrolment.courseUnitRealisationId === courseUnitRealisationId)));
    }

    /**
     * @see findEnrolment
     */
    findEnrolments(courseUnitRealisationIds?: OtmId[], bypassStore = false): Observable<Enrolment[]> {
        const validIds = this.removeEmptyAndDuplicateValues(courseUnitRealisationIds);

        if (validIds.length === 0) {
            return of([]);
        }

        return combineLatest(validIds.map(curId => this.findEnrolment(curId, bypassStore)))
            .pipe(map(enrolments => enrolments.filter(Boolean)));
    }

    /**
     * Reserve an enrolment as part of the open university product purchasing.
     */
    reserveEnrolment(request: EnrolmentReservationRequest): Observable<Enrolment> {
        return this.getHttp().post<Enrolment>(`${this.apiOpenUniversity}/reserve-enrolment`, request)
            .pipe(this.upsertAndSwitchToStoreObservable());
    }

    enrol(request: EnrolRequest): Observable<Enrolment> {
        return this.getHttp().post<Enrolment>(`${this.api}/${request.enrolmentId}/enrol`, request)
            .pipe(this.upsertAndSwitchToStoreObservable());
    }

    /**
     * This endpoint allows updates only for enrolments that:
     * - have ENROLLED state
     * - confirmed ssg modifications allowed for referenced course unit realisation
     */
    updateEnrolment(request: EnrolmentUpdateByStudentRequest): Observable<Enrolment> {
        return this.getHttp().post<Enrolment>(`${this.api}/update-enrolment-student`, request)
            .pipe(this.upsertAndSwitchToStoreObservable());
    }

    updateOwnEnrolment(enrolment: Enrolment): Observable<Enrolment> {
        return this.getHttp().put<Enrolment>(`${this.api}/${enrolment.id}`, enrolment)
            .pipe(this.upsertAndSwitchToStoreObservable());
    }

    cancelEnrolment(enrolmentId: OtmId): Observable<Enrolment> {
        return this.getHttp().post<Enrolment>(`${this.api}/${enrolmentId}/cancel-enrolment`, null)
            .pipe(this.upsertAndSwitchToStoreObservable());
    }

    abortEnrolment(enrolmentId: OtmId): Observable<Enrolment> {
        return this.getHttp().post<Enrolment>(`${this.api}/${enrolmentId}/abort-own-enrolment`, null)
            .pipe(this.upsertAndSwitchToStoreObservable());
    }

    /**
     * Open university endpoints are under a different path for authentication purposes (they work for unauthenticated users as well).
     */
    private get apiOpenUniversity(): string {
        return `${this.baseUrl}/open-university/enrolments`;
    }

    private createFindOwnEnrolmentsRequest(courseUnitRealisationIds?: OtmId | OtmId[]): Observable<Enrolment[]> {
        return this.getHttp().get<Enrolment[]>(
            `${this.baseUrl}/my-enrolments`,
            { params: { courseUnitRealisationId: this.removeEmptyAndDuplicateValues(courseUnitRealisationIds) } });
    }

    private createGetConfigsForPersonByCurIdsCall(curIds: OtmId[]): Observable<EnrolmentCalculationConfigForPerson[]> {
        return this.getHttp().get<EnrolmentCalculationConfigForPerson[]>(
            `${this.baseUrl}/my-enrolment-calculation-results`,
            { params: { courseUnitRealisationIds: curIds.toString() } },
        );
    }

    private isOngoingOrStartingWithinAWeek(dateRange: LocalDateRange): boolean {
        const today = moment();
        const nextWeek = moment().add(1, 'weeks');

        const courseUnitRealisationStartDate = moment(dateRange?.startDate, 'YYYY-MM-DD');
        const courseUnitRealisationEndDate = moment(dateRange?.endDate, 'YYYY-MM-DD');

        return ((courseUnitRealisationStartDate.isSameOrBefore(today) || !dateRange?.startDate) &&
                (courseUnitRealisationEndDate.isSameOrAfter(today) || !dateRange?.endDate)) ||
            courseUnitRealisationStartDate.isBetween(today, nextWeek, null, '[]');
    }
}

type EnrolmentEntityState = EntityState<Enrolment, OtmId>;

@StoreConfig({ name: 'student-enrolments', cache: { ttl: 60000 } })
class EnrolmentStore extends EntityStore<EnrolmentEntityState> {}

class EnrolmentQuery extends QueryEntity<EnrolmentEntityState> {
    constructor(protected store: EnrolmentStore) {
        super(store);
    }
}
