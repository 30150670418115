import { Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { dateUtils } from 'common-typescript';
import { AssessmentItem, CourseUnitRealisation, LocalDateRange, OtmId } from 'common-typescript/types';
import { isEqual } from 'lodash';
import { map, take } from 'rxjs/operators';

import { AppErrorHandler } from '../../../../error-handler/app-error-handler';
import { CourseUnitRealisationEntityService } from '../../../../service/course-unit-realisation-entity.service';
import { EnrolmentConstraintControls } from '../enrolment-right-edit-modal.component';

@Component({
    selector: 'sis-assessment-item-cur-selection',
    templateUrl: './assessment-item-cur-selection.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class AssessmentItemCurSelectionComponent implements OnChanges {

    @Input() assessmentItem: AssessmentItem;
    @Input() activityPeriodFilter: LocalDateRange;
    @Input() productAllowedCURIds: OtmId[] | null;
    @Input() usedEnrolments: number;
    @Input() form: FormGroup<EnrolmentConstraintControls>;

    visibleCourseUnitRealisations: CourseUnitRealisation[];
    private _allCourseUnitRealisations: CourseUnitRealisation[];

    constructor(
        private appErrorHandler: AppErrorHandler,
        private courseUnitRealisationService: CourseUnitRealisationEntityService,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.assessmentItem) {
            if (changes.assessmentItem.currentValue?.id) {
                this.fetchAndFilterCourseUnitRealisations(changes.assessmentItem.currentValue.id);
            } else {
                delete this._allCourseUnitRealisations;
                delete this.visibleCourseUnitRealisations;
            }
        }

        if (!isEqual(changes.activityPeriodFilter?.previousValue, changes.activityPeriodFilter?.currentValue)) {
            this.visibleCourseUnitRealisations =
                this.filterCourseUnitRealisations(this._allCourseUnitRealisations, changes.activityPeriodFilter.currentValue);
            this.removeSelectionsFromHiddenCurs();
        }
    }

    onSelectCUR(curId: OtmId, selected: boolean): void {
        if (curId) {
            this.form?.controls.allowedCourseUnitRealisationIds.setValue(
                selected
                    ? [...this.form.controls.allowedCourseUnitRealisationIds.value, curId]
                    : this.form.controls.allowedCourseUnitRealisationIds.value.filter(id => id !== curId),
            );
        }
    }

    private fetchAndFilterCourseUnitRealisations(assessmentItemId: OtmId): void {
        this.courseUnitRealisationService.getByAssessmentItemId(assessmentItemId, { documentStates: ['ACTIVE'], flowState: ['PUBLISHED'] })
            .pipe(
                take(1),
                map(curs => this.courseUnitRealisationService.sortByActivityPeriodAndName(curs)),
                this.appErrorHandler.defaultErrorHandler(),
            )
            .subscribe((curs) => {
                this._allCourseUnitRealisations = curs;
                this.visibleCourseUnitRealisations = this.filterCourseUnitRealisations(curs, this.activityPeriodFilter);
                this.removeSelectionsFromHiddenCurs();
            });
    }

    private filterCourseUnitRealisations(curs: CourseUnitRealisation[], activityPeriod: LocalDateRange): CourseUnitRealisation[] {
        if (!curs?.length || !activityPeriod) {
            return null;
        }

        return curs.filter(cur => dateUtils.dateRangesOverlap(
            cur?.activityPeriod?.startDate,
            cur?.activityPeriod?.endDate,
            activityPeriod.startDate,
            activityPeriod.endDate,
        ));
    }

    private removeSelectionsFromHiddenCurs(): void {
        if (this.visibleCourseUnitRealisations && this.activityPeriodFilter) {
            this.form?.controls.allowedCourseUnitRealisationIds.setValue(
                this.form?.controls.allowedCourseUnitRealisationIds.value
                    .filter(id => this.visibleCourseUnitRealisations.some(cur => cur.id === id)),
            );
        }
    }
}
