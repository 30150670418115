import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StateService } from '@uirouter/angular';
import { LocalId, OtmId, Plan, PlanValidationState, StudyRight } from 'common-typescript/types';
import { ModalService } from 'sis-common/modal/modal.service';

@Component({
    templateUrl: './study-right-extension-requirements-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudyRightExtensionRequirementsModalComponent {
    constructor(
        @Inject(ModalService.injectionToken) private readonly _values: StudyRightExtensionRequirementsModalValues,
        private readonly _activeModal: NgbActiveModal,
        private readonly _stateService: StateService,
    ) {}

    get educationId(): OtmId {
        return this._values.studyRight.educationId;
    }

    get untimedCourseUnits(): boolean {
        return this._values.courseUnitIdsWithNoTiming.length > 0;
    }

    get untimedCustomStudyDrafts(): boolean {
        return this._values.customStudyDraftIdsWithNoTiming.length > 0;
    }

    get courseUnitsTimedInThePast(): boolean {
        return this._values.courseUnitIdsWithTimingInThePast.length > 0;
    }

    get customStudyDraftsTimedInThePast(): boolean {
        return this._values.customStudyDraftIdsWithTimingInThePast.length > 0;
    }

    get isNoPrimaryPlanInfoVisible(): boolean {
        return !this.primaryPlanExists;
    }

    get isNotPlannedInfoVisible(): boolean {
        return this.primaryPlanExists && !this.planned;
    }

    get isUntimedStudiesInfoVisible(): boolean {
        return this.primaryPlanExists && this.planned && this.untimedStudies;
    }

    get isStudiesTimedInThePastInfoVisible(): boolean {
        return this.primaryPlanExists && this.planned && !this.untimedStudies && this.studiesTimedInThePast;
    }

    get isCreatePlanButtonVisible(): boolean {
        return !this.primaryPlanExists;
    }

    get isProceedToPlanButtonVisible(): boolean {
        return this.primaryPlanExists && !this.planned;
    }

    get isProceedToTimingButtonVisible(): boolean {
        return this.primaryPlanExists && this.planned && (this.untimedStudies || this.studiesTimedInThePast);
    }

    createPlan(): void {
        this.close();
        // We could also actually open the plan creation modal (with this education pre-selected),
        // but having an expiring study right without a primary plan should be such a rare corner case,
        // that - for now - let's just implement the minimum effort to take the student at least _closer_ to plan creation.
        this._stateService.go('student.logged-in.planSelect');
    }

    proceedToPlan(): void {
        this.close();
        this._stateService.go('student.logged-in.structure', { planId: this._values.primaryPlan.id });
    }

    proceedToTiming(): void {
        this.close();
        this._stateService.go('student.logged-in.plan.timing', { planId: this._values.primaryPlan.id });
    }

    close(): void {
        this._activeModal.close();
    }

    private get primaryPlanExists(): boolean {
        return !!this._values.primaryPlan;
    }

    private get planned(): boolean {
        return this._values.planState === PlanValidationState.PLANNED;
    }

    private get untimedStudies(): boolean {
        return this.untimedCourseUnits || this.untimedCustomStudyDrafts;
    }

    private get studiesTimedInThePast(): boolean {
        return this.courseUnitsTimedInThePast || this.customStudyDraftsTimedInThePast;
    }
}

export interface StudyRightExtensionRequirementsModalValues {
    /**
     * Study right for which the student is trying to apply extension.
     */
    readonly studyRight: StudyRight,

    /**
     * The primary plan of {@link studyRight} if such exists,
     * otherwise undefined.
     */
    readonly primaryPlan?: Plan;

    /**
     * State of {@link primaryPlan} when it exists,
     * otherwise undefined.
     */
    readonly planState?: PlanValidationState;

    /**
     * IDs of unattained course units selected into {@link primaryPlan} but having no planned study periods.
     * Empty when {@link primaryPlan} doesn't exist.
     */
    readonly courseUnitIdsWithNoTiming: readonly OtmId[];

    /**
     * IDs of unattained course units selected into {@link primaryPlan} but having their planned study periods already in the past.
     * Empty when {@link primaryPlan} doesn't exist.
     */
    readonly courseUnitIdsWithTimingInThePast: readonly OtmId[];

    /**
     * IDs of custom study drafts of {@link primaryPlan} having no planned study periods.
     * Empty when {@link primaryPlan} doesn't exist.
     */
    readonly customStudyDraftIdsWithNoTiming: readonly LocalId[];

    /**
     * IDs of custom study drafts of {@link primaryPlan} having their planned study periods already in the past.
     * Empty when {@link primaryPlan} doesn't exist.
     */
    readonly customStudyDraftIdsWithTimingInThePast: readonly LocalId[];
}
