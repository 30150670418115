import { Injectable } from '@angular/core';
import { Attainment, OtmId } from 'common-typescript/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AttainmentEntityService } from './attainment-entity.service';
import { AttainmentValidityService } from './attainment-validity.service';

@Injectable({ providedIn: 'root' })
export class StudentAttainmentService {
    constructor(
        private readonly _attainmentEntityService: AttainmentEntityService,
        private readonly _attainmentValidityService: AttainmentValidityService,
    ) {}

    /**
     * Returns attainments of the requested person, that
     * a) are themselves valid
     * or
     * b) are child/descendant attainments of other valid attainments,
     * not in any specific order.
     */
    findValidAttainmentsByPersonId(personId: OtmId): Observable<Attainment[]> {
        return this._attainmentEntityService.getByPersonId(personId).pipe(
            map((attainments: Attainment[]) => this._attainmentValidityService.getValidAttainments(attainments)),
        );
    }
}
