import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { AttainedQualification, DocumentState, OtmId } from 'common-typescript/types';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

import { EntityService } from './entity.service';

const CONFIG = {
    ENDPOINTS: {
        backend: '/ori/api',
        forPerson(personId: OtmId) {
            return `${this.backend}/attained-qualifications-person/${personId}`;
        },
        forPersonAndModuleGroup() {
            return `${this.backend}/attained-qualifications-person-and-module-group`;
        },
        attachModuleGroup(attainedQualificationId: OtmId, moduleGroupId: OtmId) {
            return `${this.backend}/attained-qualifications/${attainedQualificationId}/attach-module-group/${moduleGroupId}`;
        },
        detachModuleGroup(attainedQualificationId: OtmId) {
            return `${this.backend}/attained-qualifications/${attainedQualificationId}/detach-module-group`;
        },
        attachChildren(attainedQualificationId: OtmId) {
            return `${this.backend}/attained-qualifications/${attainedQualificationId}/attach-children`;
        },
        detachChildren(attainedQualificationId: OtmId) {
            return `${this.backend}/attained-qualifications/${attainedQualificationId}/detach-children`;
        },
        startAutomaticAttainedQualificationWriting(attainedQualificationId: OtmId) {
            return `${this.backend}/attained-qualifications/automatic-attainment-creation/${attainedQualificationId}`;
        },
    },
};

@StaticMembers<DowngradedService>()
@Injectable({
    providedIn: 'root',
})
@NgEntityServiceConfig({
    baseUrl: CONFIG.ENDPOINTS.backend,
})
export class AttainedQualificationEntityService extends EntityService<AttainedQualificationsState> {
    static downgrade: ServiceDowngradeMappings = {
        moduleName: 'sis-components.service.attainedQualificationEntity',
        serviceName: 'attainedQualificationEntityService',
    };

    constructor() {
        super(AttainedQualificationStore, AttainedQualificationQuery);
    }

    getByPersonId(personId: OtmId, documentState: DocumentState[] = []): Observable<AttainedQualification[]> {
        const url = CONFIG.ENDPOINTS.forPerson(personId);
        const storePredicate = (aq: AttainedQualification) => aq.personId === personId
            && (documentState.length ? documentState.includes(aq.documentState) : aq.documentState === 'ACTIVE');
        return this.getHttp().get<AttainedQualification[]>(url, { params: { documentState } })
            .pipe(
                tap(attainedQualifications => {
                    this.store.remove(storePredicate);
                    this.store.upsertMany(attainedQualifications);
                }),
                switchMap(() => this.selectAll({
                    filterBy: storePredicate,
                })),
            );
    }

    getByPersonAndModuleGroupId(personId: OtmId, moduleGroupId: OtmId): Observable<AttainedQualification[]> {
        const url = CONFIG.ENDPOINTS.forPersonAndModuleGroup();
        return this.getHttp().get<AttainedQualification[]>(url, { params: { personId, moduleGroupId } })
            .pipe(
                tap(attainedQualifications => this.store.upsertMany(attainedQualifications)),
                switchMap(() => this.selectAll({ filterBy: aq => aq.personId === personId && aq.moduleGroupId === moduleGroupId })),
            );
    }

    attachModuleGroup(attainedQualificationId: OtmId, moduleGroupId: OtmId): Observable<AttainedQualification> {
        const url = CONFIG.ENDPOINTS.attachModuleGroup(attainedQualificationId, moduleGroupId);
        return this.updateWithEmptyPost(url, attainedQualificationId);
    }

    detachModuleGroup(attainedQualificationId: OtmId): Observable<AttainedQualification> {
        const url = CONFIG.ENDPOINTS.detachModuleGroup(attainedQualificationId);
        return this.updateWithEmptyPost(url, attainedQualificationId);
    }

    attachChildren(attainedQualificationId: OtmId, childIds: OtmId[]): Observable<AttainedQualification> {
        const url = CONFIG.ENDPOINTS.attachChildren(attainedQualificationId);
        const params = new HttpParams().set('childAttainedQualificationId', childIds.toString());
        return this.updateWithEmptyPost(url, attainedQualificationId, params);
    }

    detachChildren(attainedQualificationId: OtmId, childIds: OtmId[]): Observable<AttainedQualification> {
        const url = CONFIG.ENDPOINTS.detachChildren(attainedQualificationId);
        const params = new HttpParams().set('childAttainedQualificationId', childIds.toString());
        return this.updateWithEmptyPost(url, attainedQualificationId, params);
    }

    startAutomaticAttainedQualificationWriting(attainedQualificationId: OtmId): Observable<number> {
        return this.getHttp().post<number>(CONFIG.ENDPOINTS.startAutomaticAttainedQualificationWriting(attainedQualificationId), {});
    }

    private updateWithEmptyPost(url: string, attainedQualificationId: OtmId, params?: HttpParams) {
        return this.getHttp().post<AttainedQualification>(url, null, { params })
            .pipe(switchMap((attainedQualification) => {
                this.store.update(attainedQualificationId, attainedQualification);
                return this.query.selectEntity(attainedQualificationId);
            }));
    }
}

type AttainedQualificationsState = EntityState<AttainedQualification, OtmId>;

@StoreConfig({ name: 'attained-qualifications' })
class AttainedQualificationStore extends EntityStore<AttainedQualificationsState> {}

class AttainedQualificationQuery extends QueryEntity<AttainedQualificationsState> {
    constructor(protected store: AttainedQualificationStore) {
        super(store);
    }
}
