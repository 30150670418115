<ng-container *transloco="let t">
  <app-widget-container [title]="t('FRONTPAGE.GRADE_AVERAGE.TITLE')" [subtitle]="t('FRONTPAGE.GRADE_AVERAGE.SUBTITLE')">
      <app-select-plan-dropdown
        [widget]="'app-grade-average'"
        (selectionChange)="onDropdownSelectionChange($event)"
      ></app-select-plan-dropdown>

      <ng-container *ngIf="studyProgressGraphResults$ | async as studyProgressGraphResults">
        <div *ngIf="studyProgressGraphResults" class="col-12 sis-mt-xl sis-mb-lg grade-container">
          <p class="sis-mb-zero">{{t('FRONTPAGE.GRADE_AVERAGE.TITLE')}}</p>
          <p *ngIf="studyProgressGraphResults.average" class="big-font sis-mt-sm sis-mb-sm" data-cy="grade-average">
            {{ studyProgressGraphResults.average | decimalNumber }}
          </p>
          <p *ngIf="!studyProgressGraphResults.average" class="big-font sis-mt-sm sis-mb-sm">-</p>
        </div>
        <div *ngIf="!studyProgressGraphResults" class="sis-mt-md">
          <p>{{t('FRONTPAGE.GRADE_AVERAGE.FETCHING_PLANS_FAILED')}}</p>
        </div>
      </ng-container>

      <div *ngIf="loading()" aria-busy="true" class="d-flex justify-content-center pt-3">
        <sis-small-spinner />
      </div>
  </app-widget-container>
</ng-container>
