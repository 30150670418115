<ng-container *transloco="let t">
  <div class="modal-header">
    <h2 class="modal-title">{{t('SIS_COMPONENTS.ACCESS_DENIED.REQUIRED_ACCESS_PERMISSIONS.TITLE')}}</h2>
    <sis-modal-close-button/>
  </div>
  <div class="modal-body">
    <p>{{t('SIS_COMPONENTS.ACCESS_DENIED.REQUIRED_ACCESS_PERMISSIONS.DESCRIPTION')}}</p>
    <ng-container *ngFor="let name of localizedNames; last as last">
      <span class="fw-semibold">{{name}}</span>
      <ng-container *ngIf="!last">
        {{t('SIS_COMPONENTS.ACCESS_DENIED.REQUIRED_ACCESS_PERMISSIONS.OR')}}
      </ng-container>
    </ng-container>
  </div>
</ng-container>
