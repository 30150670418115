<ng-container *ngIf="this.calculationConfig | async as calculationConfigForPerson">
  <ng-container *transloco="let t">
    <sis-description-list [columnCount]="1" *ngIf="this.enrolment.studyRightId">
      <dl>
        <ng-container *ngIf="calculationConfigForPerson.requirementRules as rules">
          <ng-container *ngIf="rules | sortRequirementRules: 'TRUE' as trueRules">
            <ng-template *ngIf="trueRules.length > 0" #listItem>
              <dt>{{ t('ENROLMENTS.ENROLMENT_DIALOG.REQUIREMENT_RULES.RESULT_TRUE') }}</dt>
              <dd>
                <app-requirement-rules [rules]="trueRules"></app-requirement-rules>
              </dd>
            </ng-template>
          </ng-container>
          <ng-container *ngIf="rules | sortRequirementRules: 'FALSE' as falseRules">
            <ng-template *ngIf="falseRules.length > 0" #listItem>
              <dt>{{ t('ENROLMENTS.ENROLMENT_DIALOG.REQUIREMENT_RULES.RESULT_FALSE') }}</dt>
              <dd>
                <app-requirement-rules [rules]="falseRules"></app-requirement-rules>
              </dd>
            </ng-template>
          </ng-container>
        </ng-container>
      </dl>
    </sis-description-list>
  </ng-container>
</ng-container>
