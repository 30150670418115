import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { Enrolment, EnrolmentState } from 'common-typescript/types';
import { NotificationType } from 'sis-components/study-boxes/study-notification-box/study-notification-box.component';

/**
 * Renders a {@link StudyNotificationBoxComponent} for an enrolment, to be used inside a {@link StudyRealisationBoxComponent}.
 * Displays an info text describing the current state of the enrolment.
 */
@Component({
    selector: 'app-enrolment-state-notification-box',
    templateUrl: './enrolment-state-notification-box.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnrolmentStateNotificationBoxComponent {

    @Input({ required: true }) enrolment: Enrolment;

    @Input() primaryButtonText: string;
    @Input() secondaryButtonText: string;
    @Output() primaryClickAction = new EventEmitter<void>();
    @Output() secondaryClickAction = new EventEmitter<void>();

    readonly processedStates: EnrolmentState[] = ['REJECTED', 'INVALID', 'ENROLLED'];

    getNotificationType(): NotificationType {
        if (this.enrolment?.state === 'PROCESSING') {
            switch (this.enrolment.processingState) {
                case 'SELECTED':
                case 'CURRENTLY_SELECTED':
                    return 'waiting-approval';
                case 'NOT_SELECTED':
                case 'CURRENTLY_NOT_SELECTED':
                case 'REQ_NOT_FULFILLED':
                    return 'waiting-decline';
                case 'NOT_PROCESSED':
                case 'PENDING':
                default:
                    return 'info';
            }
        }
        if (this.processedStates.includes(this.enrolment?.state)) {
            switch (this.enrolment.state) {
                case 'REJECTED':
                case 'INVALID':
                    return 'declined';
                case 'ENROLLED':
                default:
                    return 'approved';
            }
        }

        return 'none';
    }
}
