import angular from 'angular';
import { CommonCodeService } from './common-code.service.ts';
import { jsDataCacheHelperModule } from './jsDataCacheHelper.service';
export const commonPrivatePersonServiceModule = 'sis-components.service.privatePersonService';
(function () {
  privatePersonService.$inject = ["privatePersonJSDataModel", "privatePersonNameJSDataModel", "$http", "$q", "commonCodeService", "jsDataCacheHelper"];
  angular.module(commonPrivatePersonServiceModule, ['sis-components.model.privatePerson', CommonCodeService.downgrade.moduleName, jsDataCacheHelperModule]).factory('commonPrivatePersonService', privatePersonService);

  /**
   * @ngInject
   */
  function privatePersonService(privatePersonJSDataModel, privatePersonNameJSDataModel, $http, $q, commonCodeService, jsDataCacheHelper) {
    const baseUrl = '/ori/api/persons/';
    return {
      findById: (personId, bypassCache) => privatePersonJSDataModel.find(personId, {
        bypassCache: !!bypassCache
      }),
      findByIds: (ids, bypassCache) => jsDataCacheHelper.findByIds(privatePersonJSDataModel, ids, bypassCache),
      findNameById: function (personId) {
        return privatePersonNameJSDataModel.find(personId);
      },
      getFullPersonDataChange: function (personId) {
        return $http.get(baseUrl + personId + '/history/full').then(function (result) {
          return result.data;
        });
      },
      getFullPerson: function (personId) {
        return $http.get(baseUrl + personId + '/full').then(function (result) {
          return result.data;
        });
      },
      updateNamesAndPic: function (personId, personDataChange) {
        return $http.put(baseUrl + personId + '/sensitive', personDataChange).then(function (result) {
          return result.data;
        });
      },
      updateAdditionalInformation: function (personId, person) {
        return $http.put(baseUrl + personId, person).then(function (result) {
          return result.data;
        });
      },
      updateClassifiedPersonInfo: function (personId, classifiedPersonInfo) {
        var url = '/ori/api/persons-classified/' + personId;
        return $http.put(url, classifiedPersonInfo).then(function (result) {
          return result.data;
        });
      },
      loadAddressRelations: function (person) {
        var primaryAddressCountryUrn = _.get(person, 'primaryAddress.countryUrn');
        var secondaryAddressCountryUrn = _.get(person, 'secondaryAddress.countryUrn');
        var promises = [];
        if (primaryAddressCountryUrn) {
          promises.push(commonCodeService.getCode(primaryAddressCountryUrn).then(function (countryObj) {
            person.primaryAddress.$country = countryObj;
          }));
        }
        if (secondaryAddressCountryUrn) {
          promises.push(commonCodeService.getCode(secondaryAddressCountryUrn).then(function (countryObj) {
            person.secondaryAddress.$country = countryObj;
          }));
        }
        return $q.all(promises).then(function () {
          return person;
        });
      },
      updatePersonalDataSafetyNonDisclosure: (personId, dataChange) => $http.put(`${baseUrl}${personId}/personal-data-safety-nondisclosure`, dataChange).then(result => result.data)
    };
  }
})();