import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StudyRightExpirationRule } from 'common-typescript/types';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class StudyRightExpirationRuleDetailsService {
    private readonly rulesCache: Observable<StudyRightExpirationRule[]>;

    /**
     * Should make GET request on first subscription and never after that.
     */
    constructor(private http: HttpClient) {
        this.rulesCache = http.get<StudyRightExpirationRule[]>('/kori/api/study-right-expiration-rules')
            .pipe(shareReplay(1));
    }

    getRules(): Observable<StudyRightExpirationRule[]> {
        return this.rulesCache;
    }
}
