import { Component, Input, ViewEncapsulation } from '@angular/core';
import { LocalizedMarkupString, PrerequisiteGroup } from 'common-typescript/types';

@Component({
    selector: 'app-course-unit-info-prerequisites',
    templateUrl: './course-unit-info-prerequisites.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CourseUnitInfoPrerequisitesComponent {
    @Input() prerequisites: LocalizedMarkupString;
    @Input() compulsoryFormalPrerequisites?: PrerequisiteGroup[];
    @Input() recommendedFormalPrerequisites?: PrerequisiteGroup[];

}
