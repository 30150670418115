import angular from 'angular';
import _ from 'lodash';
import { enrolmentQuestionnaireModelModule } from '../model/enrolmentQuestionnaire.model';
import { enrolmentQuestionnaireAnswersModelModule } from '../model/enrolmentQuestionnaireAnswers.model';
import { languageModelModule } from '../model/language.model';
export const commonEnrolmentQuestionnaireServiceModule = 'sis-components.service.commonEnrolmentQuestionnaire';
(function () {
  commonEnrolmentQuestionnaireService.$inject = ["$q", "enrolmentQuestionnaireJSDataModel", "enrolmentQuestionnaireAnswersJSDataModel", "EnrolmentQuestionnaireAnswersURL", "$http", "languageJSDataModel"];
  angular.module(commonEnrolmentQuestionnaireServiceModule, [enrolmentQuestionnaireModelModule, enrolmentQuestionnaireAnswersModelModule, languageModelModule]).factory('commonEnrolmentQuestionnaireService', commonEnrolmentQuestionnaireService).constant('EnrolmentQuestionType', {
    FREE_TEXT: 'FREE_TEXT',
    LANGUAGE_SELECT: 'LANGUAGE_SELECT'
  });

  /**
   * @ngInject
   */
  function commonEnrolmentQuestionnaireService($q, enrolmentQuestionnaireJSDataModel, enrolmentQuestionnaireAnswersJSDataModel, EnrolmentQuestionnaireAnswersURL, $http, languageJSDataModel) {
    function resolveEmptyObjectIfStatusIs404(err) {
      if (_.get(err, 'status') === 404) {
        return $q.resolve();
      }
      return $q.reject(err);
    }
    function resolveEmptyQuestionnaireIfStatusIs404(err, courseUnitRealisationId) {
      if (_.get(err, 'status') === 404) {
        return $q.resolve(enrolmentQuestionnaireJSDataModel.inject({
          courseUnitRealisationId,
          enrolmentQuestions: []
        }));
      }
      return $q.reject(err);
    }
    return {
      findQuestionnaireByCourseUnitRealisationId(courseUnitRealisationId, bypassCache) {
        const options = {
          bypassCache: !!bypassCache
        };
        return enrolmentQuestionnaireJSDataModel.find(courseUnitRealisationId, options).then(response => response, error => resolveEmptyQuestionnaireIfStatusIs404(error, courseUnitRealisationId));
      },
      findQuestionnaireAnswersByEnrolmentId(enrolmentId) {
        return enrolmentQuestionnaireAnswersJSDataModel.find(enrolmentId).then(response => response, resolveEmptyObjectIfStatusIs404);
      },
      findQuestionnaireAnswersByCourseUnitRealisationId(courseUnitRealisationId) {
        return $http.get(EnrolmentQuestionnaireAnswersURL.ANSWERS_BY_CUR + courseUnitRealisationId).then(response => enrolmentQuestionnaireAnswersJSDataModel.inject(response.data));
      },
      createQuestionnaire(questionnaire) {
        return enrolmentQuestionnaireJSDataModel.create(questionnaire);
      },
      updateQuestionnaire(questionnaire) {
        return enrolmentQuestionnaireJSDataModel.save(questionnaire);
      },
      revertQuestionnaire(questionnaire) {
        return enrolmentQuestionnaireJSDataModel.revert(questionnaire);
      },
      createQuestionnaireAnswers(answers) {
        return enrolmentQuestionnaireAnswersJSDataModel.create(answers);
      },
      updateQuestionnaireAnswers(answers) {
        return enrolmentQuestionnaireAnswersJSDataModel.update(answers.enrolmentId, answers);
      },
      deleteQuestionnaireAnswers(enrolmentId) {
        return enrolmentQuestionnaireAnswersJSDataModel.destroy(enrolmentId);
      },
      revertQuestionnaireAnswers(answers) {
        return enrolmentQuestionnaireAnswersJSDataModel.revert(answers);
      },
      findOpenUniversityQuestionnaireAnswersByEnrolmentId(enrolmentId) {
        return $http.get(EnrolmentQuestionnaireAnswersURL.OPEN_UNIVERSITY + enrolmentId).then(response => enrolmentQuestionnaireAnswersJSDataModel.inject(response.data)).catch(err => resolveEmptyObjectIfStatusIs404(err));
      },
      updateOpenUniversityQuestionnaireAnswers(answers) {
        return $http.put(EnrolmentQuestionnaireAnswersURL.OPEN_UNIVERSITY + answers.enrolmentId, answers).then(response => enrolmentQuestionnaireAnswersJSDataModel.inject(response.data));
      },
      /**
       * Creates new language question object with populated selections (from course unit).
       * Requires that language urn are loaded to js-data cache. Won't work otherwise.
       */
      constructLanguageSelectQuestionForStudent(languageQuestion, courseUnit) {
        if (!languageQuestion) {
          return undefined;
        }
        // copy array, otherwise it is enrichment
        const languages = _.get(courseUnit, 'possibleAttainmentLanguages', []).slice();

        // if fi or sv not present add them
        if (languages.indexOf('urn:code:language:fi') === -1) {
          languages.push('urn:code:language:fi');
        }
        if (languages.indexOf('urn:code:language:sv') === -1) {
          languages.push('urn:code:language:sv');
        }
        const selections = _.chain(languages).map(languageJSDataModel.get).compact().map(language => ({
          option: language.name,
          value: language.urn
        })).value();
        return {
          localId: languageQuestion.localId,
          required: languageQuestion.required,
          questionType: languageQuestion.questionType,
          selections
        };
      },
      constructLanguageSelectQuestionForStudentWithCodes(languageQuestion, courseUnit, indexedCodes) {
        if (!languageQuestion) {
          return undefined;
        }
        // copy array, otherwise it is enrichment
        const languages = _.get(courseUnit, 'possibleAttainmentLanguages', []).slice();

        // if fi or sv not present add them
        if (languages.indexOf('urn:code:language:fi') === -1) {
          languages.push('urn:code:language:fi');
        }
        if (languages.indexOf('urn:code:language:sv') === -1) {
          languages.push('urn:code:language:sv');
        }
        const selections = [];
        languages.forEach(lang => {
          const code = indexedCodes[lang];
          selections.push({
            option: code.name,
            value: code.urn
          });
        });
        return {
          localId: languageQuestion.localId,
          required: languageQuestion.required,
          questionType: languageQuestion.questionType,
          selections
        };
      }
    };
  }
})();