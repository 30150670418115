import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Module, Plan, ValidatablePlan } from 'common-typescript/types';
import { ModalService } from 'sis-common/modal/modal.service';

// These values are from viewStates in timeline.constant.js
type ViewStateType = 'view' | 'timeCourseUnit' | 'timeCustomStudyDraft' | 'moveCourseUnitTiming' | 'moveCustomStudyDraftTiming' | 'moveNoteTiming' |
'unTimeCourseUnit' | 'unTimeMultipleCourseUnits' | 'addNote' | 'editNote' | 'introduceTimingTemplate';

export interface RemoveAllValues {
    module?: Module;
    state: {
        showAllPeriods: boolean;
        type: ViewStateType;
        untimedCourseUnitsFocused: boolean;
        zoomLevel: number;
    };
    validatablePlan: ValidatablePlan;
    validatablePlanConstructor: (plan: Plan) => ValidatablePlan;
}

@Component({
    selector: 'app-remove-all-dialog',
    template: '<remove-all-dialog (close)="activeModal.close($event ? $event.$value : null)" [resolve]="values"></remove-all-dialog>',
    encapsulation: ViewEncapsulation.None,
})
export class RemoveAllDialogComponent {
    constructor(@Inject(ModalService.injectionToken) public values: RemoveAllValues,
                public activeModal: NgbActiveModal) {}
}
