<label *ngIf="label">{{ label }}</label>
<div class="inner-addon" data-cy="date-picker-input">
  <input type="text"
         class="form-control"
         [attr.name]="name"
         [ngModel]="ngbModel"
         (ngModelChange)="onDateValueChange($event)"
         [placeholder]="dateFormat"
         ngbDatepicker
         #datepicker="ngbDatepicker"
         [minDate]="minNgbDate"
         [maxDate]="maxNgbDate"
         [showWeekNumbers]="true"
         [startDate]="ngbModel ? null : startNgbDate"
         [disabled]="disabled"
         [required]="required"
         [footerTemplate]="footer"
         autocomplete="off"
         [class.invalid]="invalid">
  <button type="button"
          class="non-button addon-button"
          data-cy="date-picker-toggle"
          (click)="datepicker.toggle()"
          [attr.disabled]="datepicker.disabled || null"
          [attr.aria-expanded]="datepicker.isOpen()"
          [attr.aria-label]="'ARIA_LABEL.CALENDAR_ASSISTANT' | translate">
    <sis-icon icon="calendar"></sis-icon>
  </button>
</div>
<ng-template #footer>
  <div class="ng-dp-footer">
    <button type="button"
            (click)="selectToday(); datepicker.close()"
            class="btn btn-today">
      {{'SIS_COMPONENTS.DATE.LOCAL_DATE_EDITOR.TODAY' | translate}} {{todayString}}
    </button>
  </div>
</ng-template>
