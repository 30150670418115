import angular from 'angular';
import courseUnitCountRuleTpl from './courseUnitCountRule.tpl.html';
(function () {
  angular.module('student.plan.rules').component('courseUnitCountRule', {
    template: courseUnitCountRuleTpl,
    bindings: {
      module: '<',
      rule: '<',
      validatablePlan: '<',
      planValidationResult: '<',
      educationOptions: '<',
      studyRight: '<',
      openCustomStudyDraftInfoModalCallback: '&'
    },
    controller: ["RangeValidationResult", "Range", "uiContext", function (RangeValidationResult, Range, uiContext) {
      const ctrl = this;
      ctrl.isOK = isOK;
      ctrl.getTargetRange = getTargetRange;
      ctrl.openCustomStudyDraftInfo = customStudyDraft => {
        ctrl.openCustomStudyDraftInfoModalCallback({
          customStudyDraft
        });
      };
      ctrl.$onChanges = () => {
        ctrl.ruleValidationResult = uiContext.planContext.getRuleValidationResult(ctrl.module.id, ctrl.rule.localId);
      };
      function isOK() {
        if (ctrl.ruleValidationResult) {
          return ctrl.ruleValidationResult.result === RangeValidationResult.OK;
        }
        return false;
      }
      function getTargetRange() {
        if (ctrl.ruleValidationResult) {
          return new Range(ctrl.ruleValidationResult.minRequired, ctrl.ruleValidationResult.maxAllowed);
        }
        return null;
      }
    }]
  });
})();