<form class="reactive" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="form-group">
    <div class="text-md-end">
      <sis-required-field-legend></sis-required-field-legend>
    </div>
    <h4 class="required" [class]="getLabelState(plannedParentModuleId)">{{'PROFILE.APPLICATIONS.PLANNED_LOCATION' | translate}}</h4>
    <app-plan-module-selector [plan]="plan"
                              [moduleId]="plannedParentModuleId.value"
                              (select)="onModuleSelect($event)">
    </app-plan-module-selector>
  </div>
  <div class="form-group">
    <sis-text-field [label]="'STUDENT_APPLICATIONS.ATTAINMENT_NAME' | translate"
                    [control]="name"
                    id="attainment-name"
                    helpBlock="PROFILE.APPLICATIONS.CUSTOM_ATTAINMENT.ATTAINMENT_NAME_SUGGESTION">
    </sis-text-field>
  </div>
  <div class="float-end">
    <sis-button [naked]="true"
                (clicked)="exit.emit()">
      <sis-icon icon="back"></sis-icon>{{'SIS_COMPONENTS.BUTTON.EXIT' | translate}}
    </sis-button>
    <sis-button type="submit"
                [attr.data-cy]="'continue-button'">
      {{'SIS_COMPONENTS.BUTTON.CONTINUE' | translate}}
    </sis-button>
  </div>
</form>
