import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { RuleResult } from 'common-typescript/types';

@Component({
    selector: 'app-requirement-rules',
    templateUrl: './requirement-rules.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,

})
export class RequirementRulesComponent {
    @Input({ required: true }) rules: RuleResult[];
}
