import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    BatchOperationError,
    BatchOperationResult,
    ConstraintViolationExplanation,
    ParsedConstraintViolation,
    SisuLocalizedException,
    Violation,
} from 'common-typescript/types';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

import { isConstraintViolationExplanation, isSisuLocalizedException } from './type-guards';

const SUPPORTED_TRANSLATABLE_ATTRIBUTES: { [messageTemplate: string]: string[] } = {
    '{fi.helsinki.otm.common.validation.localizedFieldLangs}': ['allLangsField', 'subsetLangsField'],
    '{fi.helsinki.otm.common.validation.universityOrganisations}': ['organisationsProperty', 'universitiesProperty'],
    '{fi.helsinki.otm.common.validation.fieldsOrdered}': ['lesserField', 'greaterField'],
    '{fi.helsinki.otm.common.validation.fieldsOrdered_allowEqual}': ['lesserField', 'greaterField'],
};

@StaticMembers<DowngradedService>()
@Injectable({ providedIn: 'root' })
export class ValidationErrorHandlerService {

    static downgrade: ServiceDowngradeMappings = {
        moduleName: 'sis-common.errorhandler.validationErrorHandler.downgraded',
        serviceName: 'validationErrorHandler',
    };

    constructor(private translate: TranslateService) {
        this.toConstraintViolation = this.toConstraintViolation.bind(this);
    }

    /**
     * This function parses batch operation dry dun results into a format that's easier to present in the frontend. The logic
     * is adapted from the AngularJS `bulkEditUtils` service, but it has been streamlined (e.g. no more special handling for
     * certain specific validation error messages in the response).
     */
    parseBatchDryRunErrors(results: { [entityId: string]: BatchOperationResult }): { [entityId: string]: BatchOperationError } {
        return Object.entries(results ?? {}).reduce(
            (errors: { [entityId: string]: BatchOperationError }, [entityId, batchOperationResult]) => {
                if (batchOperationResult.resultCode === 'FORBIDDEN') {
                    errors[entityId] = { messageKey: 'BULK_EDIT.VALIDATION.ERROR.BACK_END_FORBIDDEN.INFO' };
                } else if (batchOperationResult.resultCode === 'NOT_FOUND') {
                    errors[entityId] = { messageKey: 'BULK_EDIT.VALIDATION.ERROR.BACK_END_NOT_FOUND.INFO' };
                } else if (batchOperationResult.resultCode === 'VALIDATION_ERROR') {
                    errors[entityId] = {
                        // errorDetails is a string, assume it's a translation key (e.g. from a SisuLocalizedException)
                        messageKey: typeof batchOperationResult.errorDetails === 'string' ? batchOperationResult.errorDetails : null,
                        // errorDetails is an object, assume it's a ConstraintViolationExplanation
                        constraintViolations: typeof batchOperationResult.errorDetails === 'object' ?
                            this.parseViolations(batchOperationResult.errorDetails) : null,
                    };
                }
                return errors;
            },
            {},
        );
    }

    /**
     * Parses a validation error response from the backend into a structured form which can more easily be presented in the UI.
     * The current implementation is a functionally equivalent reimplementation of the legacy AngularJS `validationErrorHandler`,
     * with all hidden features and quirks intact. Enjoy!
     */
    parseViolations(response: ConstraintViolationExplanation | SisuLocalizedException): ParsedConstraintViolation[] {
        if (isConstraintViolationExplanation(response)) {
            const violations = Object.values(response.violations ?? {})
                .flat()
                .filter(Boolean)
                .map(this.toConstraintViolation);

            if (violations.length > 0) {
                return violations;
            }
        } else if (isSisuLocalizedException(response) && response.messageKey) {
            // SisuLocalizedExceptions don't have field-level violation details, only a translation key which describes the error
            return [];
        }

        return [{
            entityKey: '',
            fieldKeys: [],
            origPath: JSON.stringify(response),
            messageKey: 'ERROR.BACKEND.CONSTRAINT_VIOLATION_IS_INVALID',
        }];
    }

    /**
     * Maps a `Violation` entry from the backend into a `ConstraintViolation` understood by the validation error modal.
     *
     * any possible collection index values in the property segments are discarded:
     * ["field", "nestedField[0]", "deeperNestedField[otm-123]"] becomes ["field", "nestedField", "deeperNestedField"]
     *
     */
    toConstraintViolation(violation: Violation): ParsedConstraintViolation {
        let pathWithoutIndices = violation?.propertyPath ?? '';
        // In some cases the collection item identifier in the path is a serialized format of the whole entity, which can contain
        // nested square bracket segments, e.g. 'action.entity.someSet[NestedEntity[nestedProperty=[value]]].nestedProperty[value]'.
        // Remove the square bracket blocks one layer at a time until all are gone.
        while (pathWithoutIndices.includes('[')) {
            const stripped = pathWithoutIndices.replace(/\.?\[[^\[\]]*]/g, '');
            if (stripped === pathWithoutIndices) {
                // Invalid path value (opening bracket without a closing one), prevent an infinite loop
                break;
            }
            pathWithoutIndices = stripped;
        }
        const pathSegments = pathWithoutIndices.split('.').filter(item => item) ?? [];

        return {
            entityKey: violation.entity,
            fieldKeys: pathSegments,
            origPath: violation.path,
            messageKey: `valdr.${violation.messageTemplate}`,
            attributes: this.processTranslatableAttributes(violation.messageTemplate, violation.attributes),
        };
    }

    /**
     * Compares the given `messageTemplate` and `attributes` map against the map of supported translatable attributes, and
     * replaces the matching values in the `attributes` map with translation keys. Returns a new map, the original one remains
     * unchanged.
     *
     * The purpose is to make it possible to define additional attributes in validation rules in the backend whose values
     * are field names of an entity, and these field names should be translated and included in the error message in the
     * frontend. See OTM-19836.
     */
    private processTranslatableAttributes(messageTemplate: string, attributes: { [key: string]: unknown }): { [key: string]: unknown } {
        if (!messageTemplate || !attributes) {
            return attributes;
        }

        const copy = { ...attributes };

        if (SUPPORTED_TRANSLATABLE_ATTRIBUTES.hasOwnProperty(messageTemplate)) {
            Object.keys(copy)
                .filter(key => SUPPORTED_TRANSLATABLE_ATTRIBUTES[messageTemplate]?.includes(key))
                .filter(key => typeof copy[key] === 'string')
                .forEach(key => copy[key] = this.translate.instant(`FIELD_NAMES.${copy[key]}`));
        }

        return copy;
    }
}
