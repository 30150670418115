<ng-container *transloco="let t">
  <ng-container *ngIf="planObs$ | async">
    <form [formGroup]="planNameForm" (ngSubmit)="onSubmit()">
      <div class="modal-header">
        <h2 class="modal-title">{{ t('PLAN.EDIT_PLAN_NAME_MODAL.TITLE') }}</h2>
        <sis-modal-close-button/>
      </div>
      <div class="modal-body">
        <sis-validation-error-summary [validationErrorDetails]="validationDetails"
                                      [description]="t('PLAN.EDIT_PLAN_NAME_MODAL.VALIDATION.ERROR_SUMMARY_TITLE')">
        </sis-validation-error-summary>
        <div class="form-group">
          <sis-text-field sisAutofocus [id]="'plan-name-edit-modal-name-input-id'"
                          [label]="t('PLAN.EDIT_PLAN_NAME_MODAL.NAME')"
                          [control]="name">
          </sis-text-field>
        </div>
      </div>
      <div class="modal-footer">
        <sis-button [dataCy]="'modal-close'" [hollow]="true" (clicked)="activeModal.dismiss()">{{ t('SIS_COMPONENTS.BUTTON.CANCEL') }}</sis-button>
        <sis-button [dataCy]="'modal-submit'" type="submit">{{ t('SIS_COMPONENTS.BUTTON.CONFIRM') }}</sis-button>
      </div>
    </form>
  </ng-container>
</ng-container>
