import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { Education, StudyRight } from 'common-typescript/types';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { AppErrorHandler } from '../../error-handler/app-error-handler';
import { StudyRightEntityService } from '../../service/study-right-entity.service';
import { CommonStudyRightModalService } from '../common-study-right-modal.service';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-personalized-selection-notification',
    templateUrl: './personalized-selection-notification.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class PersonalizedSelectionNotificationComponent implements OnChanges {
    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.studyRight.personalizedSelectionNotification',
        directiveName: 'sisPersonalizedSelectionNotification',
    };

    @Input() studyRight: StudyRight;
    @Input() education: Education;
    hasPhase1Personalized: boolean;
    hasPhase2Personalized: boolean;

    constructor(
        private studyRightEntityService: StudyRightEntityService,
        private commonStudyRightModalService: CommonStudyRightModalService,
        private appErrorHandler: AppErrorHandler,
    ) { }

    ngOnChanges(): void {
        this.hasPhase1Personalized = this.studyRightEntityService.hasPhase1Personalized(this.studyRight);
        this.hasPhase2Personalized = this.studyRightEntityService.hasPhase2Personalized(this.studyRight);
    }

    showRationale() {
        this.commonStudyRightModalService.openPersonalizedSelectionRationaleModal(this.studyRight, this.education)
            .pipe(this.appErrorHandler.defaultErrorHandler())
            .subscribe();
    }
}
