import {
    ChangeDetectionStrategy,
    Component,
    Input,
    ViewEncapsulation,
} from '@angular/core';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

/**
 * Transpile raw strings into styled components.
 *
 * Content passed in via `content` prop is transpiled using Sisu's common set of transpilers. This means that allowed
 * HTML elements within the string are transpiled into actual DOM elements rather than blindly injected through
 * `innerHTML` attributes.
 *
 * This component should be preferred over `<transloco>` for simple string transpilation (i.e., no need for translation
 * functionality) even with Angular as it provides better abstraction over the functionality. Downgraded for use with
 * AngularJS, too.
 */
@StaticMembers<DowngradedComponent>()
@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'transpile',
    encapsulation: ViewEncapsulation.None,
    template: '<transloco [content]="content" />',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TranspileComponent {
    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sisComponents.transpile',
        directiveName: 'transpile',
    };

    /**
     * Content to be transpiled.
     *
     * Value is passed as is to `<transloco>` as `content` prop.
     */
    @Input() content: string;
}
