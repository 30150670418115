import recommendationTpl from './recommendation.tpl.html';
import 'angular';
import 'sis-components/model/studentApplication.model';
import 'sis-components/service/publicPerson.service';
'use strict';
(function () {
  recommendationController.$inject = ["publicPersonService"];
  /**
   * Use Angular component RecommendationComponent instead in future development.
   *
   * @deprecated
   */
  angular.module('sis-components.applications.recommendation', ['sis-components.model.studentApplication', 'sis-components.service.publicPersonService']).component('recommendation', {
    template: recommendationTpl,
    controllerAs: '$ctrl',
    controller: recommendationController,
    bindings: {
      formalRecommendation: '<',
      contentRecommendation: '<',
      approvalRequestType: '<'
    }
  });

  /**
   * @ngInject
   */
  function recommendationController(publicPersonService) {
    const $ctrl = this;
    $ctrl.$onInit = function () {
      if ($ctrl.formalRecommendation && $ctrl.formalRecommendation.responsiblePerson) {
        publicPersonService.findById($ctrl.formalRecommendation.responsiblePerson).then(function (person) {
          $ctrl.formalRecommendationResponsiblePerson = person.fullName;
        });
      }
      if ($ctrl.contentRecommendation && $ctrl.contentRecommendation.responsiblePerson) {
        publicPersonService.findById($ctrl.contentRecommendation.responsiblePerson).then(function (person) {
          $ctrl.contentRecommendationResponsiblePerson = person.fullName;
        });
      }
    };
  }
})();