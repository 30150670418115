'use strict';

(function () {
  frontPageTimetableService.$inject = ["calendarService", "timetableUtil"];
  angular.module('student.frontpage.timetable').service('frontPageTimetableService', frontPageTimetableService);

  /** @ngInject */
  function frontPageTimetableService(calendarService, timetableUtil) {
    return {
      findEventsForTodayAndTomorrow: function (enrolments) {
        var today = moment();
        var tomorrow = moment(today).add(1, 'day');
        var afterTomorrow = moment(today).add(2, 'day');
        return calendarService.getFullCalendarEvents(enrolments, today, afterTomorrow).then(function (events) {
          var timetableEvents = {};
          timetableEvents.today = timetableUtil.mapToTimetableObject(events, today);
          timetableEvents.tomorrow = timetableUtil.mapToTimetableObject(events, tomorrow);
          return timetableEvents;
        });
      }
    };
  }
})();