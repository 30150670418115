import removeAllDialogTpl from './removeAllDialog.tpl.html';
import angularTranslate from 'angular-translate';
import { alertsServiceModule } from 'sis-components/alerts/alerts.service';
export const removeAllDialogModule = 'student.timing.controls.removeAllDialog';
'use strict';
(function () {
  removeAllDialogController.$inject = ["$filter", "$translate", "timingService", "timeLineCourseUnitService", "timingViewStateService", "alertsService"];
  angular.module(removeAllDialogModule, [angularTranslate, 'student.timing.timingService', 'student.timing.timeLineCourseUnitService', 'sis-components.select.dropdownSelect', 'sis-components.string.localizedString', 'student.timing.timingViewStateService', alertsServiceModule]).component('removeAllDialog', {
    bindings: {
      close: '&',
      resolve: '<'
    },
    controller: removeAllDialogController,
    template: removeAllDialogTpl
  });
  function removeAllDialogController($filter, $translate, timingService, timeLineCourseUnitService, timingViewStateService, alertsService) {
    var $ctrl = this;
    $ctrl.$onInit = function () {
      if ($ctrl.resolve.module) {
        $ctrl.degreeProgrammeSelectionOptions = [{
          value: $ctrl.resolve.module.id,
          label: $filter('localizedString')($ctrl.resolve.module.name)
        }];
      } else {
        $ctrl.degreeProgrammes = $ctrl.resolve.validatablePlan.getSelectedModulesById($ctrl.resolve.validatablePlan.rootModule);
        $ctrl.degreeProgrammeSelectionOptions = _.map($ctrl.degreeProgrammes, function (dp) {
          return {
            value: dp.id,
            label: $filter('localizedString')(dp.name)
          };
        });
      }
      ;
      $ctrl.isValid = true;
    };
    $ctrl.closeDialog = function () {
      $ctrl.close();
    };
    $ctrl.unTimeChosenDegreeProgramme = function () {
      $ctrl.isValid = $ctrl.firstCheckbox || $ctrl.secondCheckbox;
      if (!$ctrl.isValid) {
        alertsService.error($translate.instant('SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.FORM_SUBMISSION_FAILED'));
        return;
      }
      timingViewStateService.startUnTimingForDegreeProgramme($ctrl.resolve.state);
      $ctrl.chosenDegreeProgramme = $ctrl.firstCheckbox ? _.first($ctrl.degreeProgrammeSelectionOptions).value : $ctrl.degreeProgrammeSelectionOptions[1].value;
      const module = $ctrl.firstCheckbox && $ctrl.secondCheckbox ? $ctrl.resolve.validatablePlan.rootModule : _.get($ctrl.resolve.validatablePlan.modulesById, $ctrl.chosenDegreeProgramme);
      const timedCourseUnits = timeLineCourseUnitService.getTimedCourseUnitsForModuleRecursively(module, $ctrl.resolve.validatablePlan);
      const timedCustomStudyDrafts = timeLineCourseUnitService.getTimedCustomStudyDraftsForModuleRecursively(module, $ctrl.resolve.validatablePlan);
      timingService.unTimeStudiesFromTimeLine($ctrl.resolve.validatablePlan.plan, timedCourseUnits, timedCustomStudyDrafts, $ctrl.resolve.validatablePlanConstructor).then(function () {
        timingViewStateService.onEndTiming($ctrl.resolve.state);
        $ctrl.close();
      });
    };
  }
  ;
})();