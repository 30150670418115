<div class="row teacher-rows">
  <div class="col-12 col-md-6 teacher-data" *ngFor="let responsibilityInfo of sortedResponsibilityInfos">
    <span>
      {{ responsibilityInfo.roleUrn | codeLocalizedName | async }}
      <span *ngIf="responsibilityInfo.validityPeriod.startDate || responsibilityInfo.validityPeriod.endDate">
        ({{ responsibilityInfo.validityPeriod | localDateRange }})
      </span>
    </span>
    <span class="teacher-name">{{responsiblePersonsById[responsibilityInfo.personId] | personFullName }}</span>
    <span>{{ responsiblePersonsById[responsibilityInfo.personId]?.emailAddress }}</span>
    <span [class.additional-data]="responsibilityInfo.personId" [class.additional-data-no-person]="!responsibilityInfo.personId">
      {{ responsibilityInfo.text | localizedString: language }}
    </span>
  </div>
</div>
<div *ngIf="sortedResponsibilityInfos.length === 0" class="form-group-mimic">-</div>
