import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

import { EnrichedAssItem, EnrichedEnrolment } from '../open-university-enrolment-wizard.component';

@Component({
    selector: 'app-open-university-cur-selection-final-step',
    templateUrl: './open-university-cur-selection-final-step.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpenUniversityCurSelectionFinalStepComponent {

    @Input() assessmentItemsData: EnrichedAssItem[];
    @Input() openUniStudentEnrolments: EnrichedEnrolment[];
    @Output() navigateBack = new EventEmitter<number>();

    navigateBackToStep(step: number) {
        this.navigateBack.emit(step);
    }
}
