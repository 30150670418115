import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { StudyRightExtension } from 'common-typescript/types';
import _ from 'lodash';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-extension-info',
    templateUrl: './extension-info.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ExtensionInfoComponent {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.studyRight.extensionInfo',
        directiveName: 'sisExtensionInfo',
    };

    studyRightExtensions: StudyRightExtension[] = [];
    @Output() showDetails = new EventEmitter<StudyRightExtension>();
    @Input() showShowDetails: boolean;

    @Input() set extensions(extensions: StudyRightExtension[]) {
        this.studyRightExtensions = _.sortBy(extensions || [], 'extensionStartDate');
    }
}
