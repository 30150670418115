import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Education, LocalizedString, StudyRight } from 'common-typescript/types';
import _ from 'lodash';
import { ModalService } from 'sis-common/modal/modal.service';

import { StudyRightEntityService } from '../../service/study-right-entity.service';

interface PhaseInfo {
    description: string;
    name: LocalizedString;
}

export interface PersonalizedSelectionRationaleValues {
    studyRight: StudyRight;
    education: Education;
}

@Component({
    selector: 'sis-personalized-selection-rationale',
    templateUrl: './personalized-selection-rationale.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class PersonalizedSelectionRationaleComponent implements OnInit {

    constructor(
        @Inject(ModalService.injectionToken) public values: PersonalizedSelectionRationaleValues,
        private activeModal: NgbActiveModal,
        private studyRightEntityService: StudyRightEntityService,
    ) { }

    phase1: PhaseInfo;
    phase2: PhaseInfo;

    ngOnInit(): void {
        const studyRight = this.values.studyRight;
        if (this.studyRightEntityService.hasPhase1Personalized(studyRight)) {
            this.phase1 = {
                description: studyRight.personalizedSelectionPath.phase1.rationale,
                name: _.get(this.values.education, 'structure.phase1.name'),
            };
        }
        if (this.studyRightEntityService.hasPhase2Personalized(studyRight)) {
            this.phase2 = {
                description: studyRight.personalizedSelectionPath.phase2.rationale,
                name: _.get(this.values.education, 'structure.phase2.name'),
            };
        }
    }

    close() {
        this.activeModal.close();
    }
}
