import angular from 'angular';
import { localDateFormatFilterModule } from 'sis-components/date/filters/localDateFormat/localDateFormat.filter';
import searchFilterTpl from './searchFilterDate.tpl.html';
export const searchFilterDateModule = 'sis-components.search.searchFilterDate';
angular.module(searchFilterDateModule, [localDateFormatFilterModule]).component('searchFilterDate', {
  bindings: {
    clonedParameterModel: '=',
    filterName: '<',
    onDateChange: '&',
    popoverIsOpen: '<',
    submitFilter: '&',
    minDate: '<?'
  },
  template: searchFilterTpl,
  controllerAs: 'ctrl',
  controller: ["$scope", "localDateFormatFilter", function ($scope, localDateFormatFilter) {
    const ctrl = this;
    // Invoke callback methods that take arguments in a way that allows to keep the template file unchanged
    // (that used to call controller methods directly when UI Bootstrap was used)
    // ctrl.toggleValue = (value, form, event) => ctrl.toggleValueCb()(value, form, event);

    ctrl.minDateTranslateData = () => ({
      minDate: localDateFormatFilter(ctrl.minDate)
    });
    $scope.$watch('ctrl.popoverIsOpen', newVal => {
      if (!newVal) {
        // Reset form when popover closes
        $scope.filterSelectorForm.$setPristine();
      }
    });
  }]
});