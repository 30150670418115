import angular from 'angular';
import _ from 'lodash';
import { PlanValidationTs } from 'common-typescript';
import { TabNavigationComponent } from 'sis-components/tabs/tab-navigation/tab-navigation.component.ts';
import { planValidationResultHelperModule } from 'sis-components/service/planValidationResultHelper.service';
import { customStudyDraftInfoModalModule } from 'sis-components/customStudyDraft/customStudyDraftInfoModal.service';
import { errorServiceModule } from 'sis-components/error-handler/legacy/errorService';
import untimedCourseUnitsTpl from './untimedCourseUnits.tpl.html';

/* globals $ */
(function () {
  untimedCourseUnitsController.$inject = ["$state", "$stateParams", "$scope", "Range", "timingService", "timelineConfig", "timeLineCourseUnitService", "timingViewStateService", "customStudyDraftInfoModalService", "localeService", "defaultPromiseHandler", "planValidationResultHelper"];
  angular.module('student.timing.untimedCourseUnits.untimedCourseUnits', [TabNavigationComponent.downgrade.moduleName, 'sis-components.rangeFilter', 'student.shared', 'student.timing.timelineCourseUnit', 'student.timing.timingService', 'student.timing.timeLineCourseUnitService', 'student.timing.timingViewStateService', errorServiceModule, customStudyDraftInfoModalModule, planValidationResultHelperModule]).component('untimedCourseUnits', {
    template: untimedCourseUnitsTpl,
    bindings: {
      selectedPlan: '<',
      validatablePlan: '<',
      state: '<'
    },
    controller: untimedCourseUnitsController
  });
  function untimedCourseUnitsController($state, $stateParams, $scope, Range, timingService, timelineConfig, timeLineCourseUnitService, timingViewStateService, customStudyDraftInfoModalService, localeService, defaultPromiseHandler, planValidationResultHelper) {
    const $ctrl = this;
    $ctrl.tabs = [];
    function updateCourseUnitLists() {
      $ctrl.untimedCourseUnitsForModules = {};
      _.forEach($ctrl.modulesForSecondLevelTab, secondLevelModule => {
        $ctrl.untimedCourseUnitsForModules[secondLevelModule.id] = timeLineCourseUnitService.getUntimedCourseUnitsForModuleRecursively(secondLevelModule, $ctrl.validatablePlan);
      });
      $ctrl.timedOrAttainedCourseUnitsForModules = {};
      _.forEach($ctrl.modulesForTabs, moduleForTab => {
        $ctrl.timedOrAttainedCourseUnitsForModules[moduleForTab.id] = timeLineCourseUnitService.getTimedOrAttainedCourseUnitsForModuleRecursively(moduleForTab, $ctrl.validatablePlan);
      });
      $ctrl.rootLevelCourseUnits = [];
      if ($ctrl.moduleForSelectedTab) {
        const selectedCourseUnits = $ctrl.validatablePlan.getCourseUnitsOrSubstitutingCourseUnitsForModule($ctrl.moduleForSelectedTab);
        $ctrl.rootLevelCourseUnits = _.filter(selectedCourseUnits, courseUnit => !$ctrl.validatablePlan.isCourseUnitAttained(courseUnit.id) && !$ctrl.validatablePlan.isCourseUnitTimed(courseUnit.id));
      }
    }
    function updateCustomStudyDraftLists() {
      $ctrl.untimedCustomStudyDraftsForModules = {};
      _.forEach($ctrl.modulesForSecondLevelTab, secondLevelModule => {
        $ctrl.untimedCustomStudyDraftsForModules[secondLevelModule.id] = timeLineCourseUnitService.getUntimedCustomStudyDraftsForModuleRecursively(secondLevelModule, $ctrl.validatablePlan);
      });
      $ctrl.rootLevelCustomStudyDrafts = [];
      if ($ctrl.moduleForSelectedTab) {
        const selectedCustomStudyDrafts = $ctrl.validatablePlan.getSelectedCustomStudyDraftsByParentModuleId($ctrl.moduleForSelectedTab.id);
        $ctrl.rootLevelCustomStudyDrafts = _.filter(selectedCustomStudyDrafts, customStudyDraft => _.isEmpty(_.get(customStudyDraft, 'plannedPeriods')));
      }
    }
    $ctrl.$onInit = () => {
      $ctrl.modulesForTabs = $ctrl.validatablePlan.getSelectedModulesById($ctrl.validatablePlan.rootModule);
      _.forEach($ctrl.modulesForTabs, moduleForTab => {
        $ctrl.tabs.push({
          path: 'student.logged-in.plan.timing.untimed',
          params: $ctrl.getRouteParams(moduleForTab),
          id: moduleForTab.id,
          title: localeService.getLocalizedValue(moduleForTab.name),
          controls: `${moduleForTab.id}-content`
        });
      });
      if ($stateParams.selectedModuleId) {
        $ctrl.moduleForSelectedTab = _.find($ctrl.modulesForTabs, {
          id: $stateParams.selectedModuleId
        });
      } else {
        $state.go('student.logged-in.plan.timing.untimed', {
          selectedModuleId: _.first($ctrl.modulesForTabs)?.id
        });
      }
      if (!$ctrl.moduleForSelectedTab) {
        $ctrl.moduleForSelectedTab = _.first($ctrl.modulesForTabs);
      }
      if ($ctrl.moduleForSelectedTab) {
        $ctrl.modulesForSecondLevelTab = $ctrl.validatablePlan.getSelectedModulesById($ctrl.moduleForSelectedTab);
      } else {
        $ctrl.modulesForSecondLevelTab = [];
      }
      $ctrl.planValidationResult = $ctrl.validatePlan();
      updateCourseUnitLists();
      updateCustomStudyDraftLists();
    };
    $ctrl.validatePlan = () => PlanValidationTs.validatePlan($ctrl.validatablePlan);
    $ctrl.getModuleValidationResult = moduleId => planValidationResultHelper.getModuleValidationResult(moduleId, $ctrl.planValidationResult);
    $scope.$watch('$ctrl.state.type', (newStateType, oldStateType) => {
      if (_.includes([timelineConfig.viewStates.TIME_COURSE_UNIT, timelineConfig.viewStates.UNTIME_MULTIPLE, timelineConfig.viewStates.INTRODUCE_TIMING_TEMPLATE, timelineConfig.viewStates.MOVE_COURSE_UNIT_TIMING, timelineConfig.viewStates.TIME_CUSTOM_STUDY_DRAFT, timelineConfig.viewStates.MOVE_CUSTOM_STUDY_DRAFT_TIMING, timelineConfig.viewStates.UNTIME_COURSE_UNIT], oldStateType) && newStateType !== timelineConfig.viewStates.TIME_COURSE_UNIT) {
        updateCourseUnitLists();
        updateCustomStudyDraftLists();
      }
    });
    $ctrl.getUntimedCourseUnitsForModule = function (module) {
      const moduleId = _.get(module, 'id');
      return _.get($ctrl.untimedCourseUnitsForModules, moduleId);
    };
    $ctrl.getUntimedCustomStudyDraftsForModule = module => {
      const moduleId = _.get(module, 'id');
      return _.get($ctrl.untimedCustomStudyDraftsForModules, moduleId);
    };

    // TODO: $ctrl.getTimedCreditsForModule needs to be checked for correct logic

    $ctrl.getTimedCreditsForModule = function (module) {
      const timedCourseUnits = $ctrl.timedOrAttainedCourseUnitsForModules[module.id];
      let timedCredits;
      if (_.isEmpty(timedCourseUnits)) {
        timedCredits = new Range(0, 0);
      } else {
        timedCredits = Range.sumRanges(_.map(timedCourseUnits, 'credits'));
      }
      return timedCredits;
    };
    $ctrl.toggleUntimedCourseUnitsOpen = function () {
      timingViewStateService.toggleFocusUntimedCourseUnit($ctrl.state);
    };
    $ctrl.isActiveCourseUnit = function (courseUnit) {
      return _.get($ctrl, 'state.activeCourseUnit.id') === courseUnit.id;
    };
    $ctrl.isActiveCustomStudyDraft = customStudyDraft => _.get($ctrl, 'state.activeCustomStudyDraft.id') === customStudyDraft.id;
    $ctrl.startTimingForCourseUnit = function (courseUnit) {
      timingService.cancelTimingOfCourseUnit($ctrl.selectedPlan, $ctrl.validatablePlan);
      timingService.startTimingCourseUnit(courseUnit, undefined, $ctrl.selectedPlan, $ctrl.validatablePlan);
      timingViewStateService.startTimingForCourseUnit($ctrl.state, courseUnit);
    };
    $ctrl.startTimingForCustomStudyDraft = customStudyDraft => {
      timingService.cancelTimingOfCourseUnit($ctrl.selectedPlan, $ctrl.validatablePlan);
      timingService.startTimingCustomStudyDraft(customStudyDraft, undefined, $ctrl.selectedPlan, $ctrl.validatablePlan);
      timingViewStateService.startTimingForCustomStudyDraft($ctrl.state, customStudyDraft);
    };
    $ctrl.isCourseUnitTiming = function () {
      return $ctrl.state.type === timelineConfig.viewStates.TIME_COURSE_UNIT || $ctrl.state.type === timelineConfig.viewStates.MOVE_COURSE_UNIT_TIMING;
    };
    $ctrl.isCustomStudyDraftTiming = () => $ctrl.state.type === timelineConfig.viewStates.TIME_CUSTOM_STUDY_DRAFT || $ctrl.state.type === timelineConfig.viewStates.MOVE_CUSTOM_STUDY_DRAFT_TIMING;
    $ctrl.getRouteParams = function (module) {
      return {
        selectedModuleId: module.id
      };
    };
    $ctrl.openCustomStudyDraftInfoModal = customStudyDraft => {
      customStudyDraftInfoModalService.open(customStudyDraft).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    };
  }
})();