'use strict';

(function () {
  angular.module('student.common.context.plan-context').factory('PlanSelection', planSelectionFactory);
  function planSelectionFactory() {
    return function () {
      return {
        moduleSelections: {},
        courseUnitSelections: {},
        customModuleAttainmentSelections: {},
        customCourseUnitAttainmentSelections: {},
        assessmentItemSelections: {},
        addModuleSelection: function (module) {
          var self = this;
          if (!self.moduleSelections[module.id]) {
            var moduleSelection = {
              moduleId: module.id
            };
            self.moduleSelections[module.id] = moduleSelection;
            if (module.selectedParent) {
              moduleSelection.parentModuleId = module.selectedParent.id;
            }
            if (!module.attainment) {
              _.forEach(module.selectedModulesById, function (selectedModule) {
                self.addModuleSelection(selectedModule);
              });
              _.forEach(module.selectedCourseUnitsById, function (courseUnit) {
                self.addCourseUnitSelection(courseUnit);
              });
            }
          }
        },
        addCourseUnitSelection: function (courseUnit) {
          var self = this;
          if (!self.courseUnitSelections[courseUnit.id]) {
            var courseUnitSelection = {
              courseUnitId: courseUnit.id
            };
            self.courseUnitSelections[courseUnit.id] = courseUnitSelection;
            if (courseUnit.selectedParent) {
              courseUnitSelection.parentModuleId = courseUnit.selectedParent.id;
            }
            if (!courseUnit.attainment) {
              if (courseUnit.selectedCompletionMethod) {
                courseUnitSelection.completionMethodId = courseUnit.selectedCompletionMethod.localId;
              }
              if (!_.isEmpty(courseUnit.substitutedBy)) {
                courseUnitSelection.substitutedBy = [];
                _.forEach(courseUnit.substitutedBy, function (substitute) {
                  courseUnitSelection.substitutedBy.push(substitute.id);
                  self.addCourseUnitSelection(substitute);
                });
              }
              if (courseUnit.plannedPeriods) {
                courseUnitSelection.plannedPeriods = _.cloneDeep(courseUnit.plannedPeriods);
              }
              _.forEach(courseUnit.selectedAssessmentItemsById, function (assessmentItem) {
                self.addAssessmentItemSelection(assessmentItem);
              });
            }
            if (!_.isEmpty(courseUnit.substituteFor)) {
              var substitutesFor = Object.getOwnPropertyNames(courseUnit.substituteFor);
              courseUnitSelection.substituteFor = [];
              _.forEach(substitutesFor, function (substituteFor) {
                courseUnitSelection.substituteFor.push({
                  substitutedCourseUnitId: substituteFor,
                  substitutedCredits: courseUnit.substituteFor[substituteFor]
                });
              });
            }
          }
        },
        addCustomModuleAttainmentSelections: function (customModuleAttainmentSelections) {
          var self = this;
          _.forEach(customModuleAttainmentSelections, function (customModuleAttainmentSelection) {
            if (customModuleAttainmentSelection.parentModuleId !== null && !self.customModuleAttainmentSelections[customModuleAttainmentSelection.customModuleAttainmentId]) {
              self.customModuleAttainmentSelections[customModuleAttainmentSelection.customModuleAttainmentId] = customModuleAttainmentSelection;
            }
          });
        },
        addCustomCourseUnitAttainmentSelections: function (customCourseUnitAttainmentSelections) {
          var self = this;
          _.forEach(customCourseUnitAttainmentSelections, function (customCourseUnitAttainmentSelection) {
            if (customCourseUnitAttainmentSelection.parentModuleId !== null && !self.customCourseUnitAttainmentSelections[customCourseUnitAttainmentSelection.customCourseUnitAttainmentId]) {
              self.customCourseUnitAttainmentSelections[customCourseUnitAttainmentSelection.customCourseUnitAttainmentId] = customCourseUnitAttainmentSelection;
            }
          });
        },
        addAssessmentItemSelection: function (assessmentItem) {
          if (!this.assessmentItemSelections[assessmentItem.id]) {
            this.assessmentItemSelections[assessmentItem.id] = {
              courseUnitId: assessmentItem.selectedParent.id,
              assessmentItemId: assessmentItem.id
            };
          }
        },
        clear: function () {
          this.moduleSelections = {};
          this.courseUnitSelections = {};
          this.customModuleAttainmentSelections = {};
          this.customCourseUnitAttainmentSelections = {};
          this.assessmentItemSelections = {};
          return this;
        }
      };
    };
  }
})();