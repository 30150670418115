<div class="modal-header">
  <h2 class="modal-title">{{'ENROLMENT.ENROLMENT_MODAL.ABORT_HEADER' | translate}}</h2>
</div>
<div class="modal-body">
  <sis-contextual-notification *ngIf="abortFailed$ | async"
                               variant="danger"
                               data-cy="abort-failed-notification">
    {{'ENROLMENT.ENROLMENT_MODAL.ABORT_ENROLMENT_FAILED' | translate}}
  </sis-contextual-notification>
  <sis-contextual-notification variant="warning"
                               data-cy="abort-warning-notification">
    {{'ENROLMENT.ENROLMENT_MODAL.ABORT_WARNING' | translate}}
  </sis-contextual-notification>
  <sis-study-realisation-box
    *ngIf="{
      courseUnit: enrolment?.courseUnitId | courseUnit | async,
      assessmentItem: enrolment?.assessmentItemId | assessmentItem | async,
      courseUnitRealisation: enrolment?.courseUnitRealisationId | courseUnitRealisation | async
    } as data"
    [activityPeriod]="data.courseUnitRealisation?.activityPeriod"
    [code]="data.courseUnit?.code"
    [credits]="data.assessmentItem?.credits"
    [name]="data.courseUnit?.name"
    [realisationName]="data.courseUnitRealisation?.name"
    [level]="3"
    class="my-5 d-block">
  </sis-study-realisation-box>
  <sis-description-list *ngIf="enrolment?.studyRightId | studyRightName | async as studyRightName">
    <dl>
      <ng-template #listItem>
        <dt>{{'ENTITY_NAMES.StudyRight' | translate}}</dt>
        <dd>{{studyRightName | localizedString}}</dd>
      </ng-template>
    </dl>
  </sis-description-list>
</div>
<div class="modal-footer">
  <sis-button (clicked)="dismiss()" [hollow]="true">{{'SIS_COMPONENTS.BUTTON.CANCEL' | translate}}</sis-button>
  <sis-button (clicked)="abort$.next()" data-cy="confirm-button">{{'SIS_COMPONENTS.BUTTON.CONFIRM' | translate}}</sis-button>
</div>
