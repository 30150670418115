import moment from 'moment';

function getEndStartDiff(start: string, end: string): number | undefined {
    const endTime = moment(end, 'hh.mm');
    const startTime = moment(start, 'hh.mm');
    return endTime.isValid() && startTime.isValid() ? endTime.diff(startTime) : undefined;
}

export function calculateDuration(startTime: string, endTime: string): string | undefined {
    const endStartDiff = getEndStartDiff(startTime, endTime);
    return endStartDiff ? moment.duration(endStartDiff).toJSON() : undefined;
}
