import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CompletionMethod, CourseUnit, OpenUniversityCart, OpenUniversityCartItem, OpenUniversityProduct } from 'common-typescript/types';
import { Observable } from 'rxjs';

import { OpenUniversityEnrichedEnrolmentsService } from '../../../common/service/open-university-enriched-enrolments.service';
import { EnrichedAssItem, EnrichedEnrolment } from '../../../open-university-enrolment-wizard/open-university-enrolment-wizard.component';

@Component({
    selector: 'app-open-university-enrolled-item-info',
    templateUrl: './open-university-enrolled-item-info.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class OpenUniversityEnrolledItemInfoComponent implements OnInit {

    @Input() cartItem: OpenUniversityCartItem;
    @Input() openUniversityCart: OpenUniversityCart;
    @Input() openUniversityProducts: OpenUniversityProduct[] = [];
    @Input() courseUnits: CourseUnit[] = [];
    @Input() assessmentItemsData: EnrichedAssItem[] = [];

    openUniStudentEnrolments: EnrichedEnrolment[];
    courseUnit: CourseUnit;
    product: OpenUniversityProduct;
    completionMethod: CompletionMethod;
    ready = false;

    constructor(private openUniversityEnrichedEnrolmentsService: OpenUniversityEnrichedEnrolmentsService) { }

    ngOnInit(): void {
        this.courseUnit = this.getCuByCartItem();
        this.product = this.getProductByCartItem();
        this.completionMethod = this.getCompletionMethodByProduct();
        this.getOpenUniStudentEnrolments()
            .subscribe(enrolments => {
                this.openUniStudentEnrolments = enrolments;
                this.ready = true;
            });
    }

    getCuByCartItem(): CourseUnit {
        const cuId = this.getProductByCartItem().courseUnitId;
        return this.courseUnits.find(cu => cu.id === cuId);
    }

    getProductByCartItem(): OpenUniversityProduct {
        return this.openUniversityProducts.find(oup => oup.id === this.cartItem.openUniversityProductId);
    }

    getOpenUniStudentEnrolments(): Observable<EnrichedEnrolment[]> {
        return this.openUniversityEnrichedEnrolmentsService.getExistingEnrolmentsForCartItem(this.assessmentItemsData, this.openUniversityCart, this.product);
    }

    getCompletionMethodByProduct(): CompletionMethod {
        return this.courseUnit?.completionMethods
            ?.find(cm => this.product.completionMethodId === cm.localId && cm.studyType === 'OPEN_UNIVERSITY_STUDIES');
    }
}
