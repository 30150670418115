import { Injectable } from '@angular/core';
import { LocalId } from 'common-typescript/types';
import { Observable, Subject } from 'rxjs';

/**
 * Service for signaling rule subtrees to clear their UI state.
 */
@Injectable()
export class RuleClearSignalService {

    private clearSignal$ = new Subject<LocalId>();

    getClearSignal(): Observable<LocalId> {
        return this.clearSignal$.asObservable();
    }

    sendClearSignal(localId: LocalId): void {
        this.clearSignal$.next(localId);
    }
}
