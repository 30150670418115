import _ from 'lodash';
import angular from 'angular';
import angularTranslate from 'angular-translate';
import { searchParameterServiceModule } from './searchParameters';
import { searchEventConstantModule } from './searchEvent.constant';
import { searchParameterStorageServiceModule } from './searchParameterStorage.service';
import { commonDateConstantsModule } from '../date/constants';
export const searchAndResultsModule = 'sis-components.search.searchAndResults';
(function () {
  SearchAndResultsController.$inject = ["$scope", "$log", "DOCUMENT_STATES", "SearchParameters", "searchParameterStorageService", "$state", "$q", "SEARCH_EVENT", "$timeout"];
  searchBar.$inject = ["$log"];
  searchAndResults.$inject = ["$log"];
  angular.module(searchAndResultsModule, [angularTranslate, 'ui.router', 'ui.router.upgrade', searchParameterServiceModule, commonDateConstantsModule, searchParameterStorageServiceModule, searchEventConstantModule]).directive('searchAndResults', searchAndResults).directive('searchBar', searchBar).directive('searchResults', searchResults).controller('SearchAndResultsController', SearchAndResultsController);
  const searchErrorHandler = log => err => {
    // empty error should be ignored
    if (!_.isEmpty(err)) {
      log.info(err);
    }
  };

  /**
   * @ngInject
   */
  // This directive listens event SEARCH_EVENT.SEARCH_AGAIN and it will launch new search. This allows user to launch search manually from
  // the component that is using this directive.
  function searchAndResults($log) {
    // IMO this directive should not call search service directly. The parent component (component that uses this directive) should be
    // able to execute search manually. Currently scope.on listener is just a workaround. Instead search call back should be
    // binded to this directive and the search results should be binded separately.
    return {
      require: 'searchAndResults',
      scope: {
        initialSearch: '=?',
        searchService: '=',
        searchParams: '=?',
        searchResults: '=searchAndResults',
        onSearch: '&',
        disableStorage: '<',
        // By default the storage key is the name of the ui router state. This comes handy when you need
        // multiple search boxes in the same ui router state. It is necessary to set same attribute for
        // searchParameters directive
        customStorageKey: '<?'
      },
      controller: 'SearchAndResultsController',
      controllerAs: 'searchAndResultsCtrl',
      bindToController: true,
      link(scope, elem, attr, searchAndResultsCtrl) {
        if (searchAndResultsCtrl.initialSearch === true) {
          $log.debug('Search component - searchAndResults link - executing search');
          searchAndResultsCtrl.search(searchAndResultsCtrl.searchParams.searchString.displayValue()).catch(searchErrorHandler($log));
        }
        if (scope.searchResults) {
          searchAndResultsCtrl.results = scope.searchResults;
        }
      }
    };
  }

  /**
   * @ngInject
   */
  function searchBar($log) {
    return {
      restrict: 'A',
      require: ['^searchAndResults', 'ngModel'],
      scope: true,
      link(scope, elem, attr, ctrls) {
        scope.searchAndResultsCtrl = ctrls[0];
        const ngModel = ctrls[1];
        scope.$watch(() => ngModel.$modelValue, newString => {
          $log.debug('Search component - searchBar link - executing search');
          scope.searchAndResultsCtrl.search(newString).catch(searchErrorHandler($log));
        });
      }
    };
  }

  /**
   * @ngInject
   */
  function searchResults() {
    return {
      restrict: 'A',
      scope: false,
      require: '^searchAndResults',
      link(scope, elem, attr, ctrl) {
        scope.searchAndResultsCtrl = ctrl;
      }
    };
  }

  /**
   * @ngInject
   */
  function SearchAndResultsController($scope, $log, DOCUMENT_STATES, SearchParameters, searchParameterStorageService, $state, $q, SEARCH_EVENT, $timeout) {
    const ctrl = this;
    function initSearchStringAndResults() {
      ctrl.searchResult = {};
      ctrl.totalCount = undefined;
      ctrl.searchString = undefined;
      ctrl.results = [];
      ctrl.limit = undefined;
      ctrl.start = undefined;
    }
    function hasTooManyResults(result) {
      return !!_.get(result, 'truncated', false);
    }
    function matchInOtherLang(valueInUILang, matchedValue) {
      if (valueInUILang !== matchedValue && /<b>/.test(matchedValue)) {
        return matchedValue;
      }
      return false;
    }
    function saveSearchParametersToLocalStorage(saveSearchParameters) {
      if (saveSearchParameters && ctrl.disableStorage !== false) {
        const storageKey = _.get(ctrl, 'customStorageKey', $state.get('.').name);
        searchParameterStorageService.saveSearchParameterToStorage(storageKey, ctrl.searchParams);
      }
    }
    function clearSearchParametersFromStorage(saveSearchParameters) {
      if (saveSearchParameters && ctrl.disableStorage !== false) {
        const storageKey = _.get(ctrl, 'customStorageKey', $state.get('.').name);
        searchParameterStorageService.clearSearchParameterFromStorage(storageKey);
      }
    }
    ctrl.$onInit = function () {
      if (_.isEmpty(ctrl.searchService)) {
        throw new Error('searchAndResults directive: searchService attribute is required');
      }
      ctrl.searchResult = {};
      ctrl.totalCount = undefined;
      ctrl.searchString = undefined;
      ctrl.results = [];
      ctrl.saveSearchParameters = false; // this is changed in searchParameters
      ctrl.tooManyResults = false;
      ctrl.searchParams = ctrl.searchParams || new SearchParameters();
    };
    $scope.$on(SEARCH_EVENT.SEARCH_AGAIN, () => {
      $log.debug('Search component - searchAndResults event listener - executing search');
      ctrl.search().catch(searchErrorHandler($log));
    });
    ctrl.toggleShowMaxResults = function () {
      if (ctrl.searchParams.showMaxResults.displayValue()) {
        ctrl.searchParams.fetchLimitedResults();
      } else {
        ctrl.searchParams.fetchAllResults();
      }
      return ctrl.search();
    };

    // There are logging declarations across search related files to debug more easily double or triple search function calls.
    // Double or triple gets are quite ugly and they should be fixed.
    ctrl.search = function (searchString, start) {
      if (angular.isDefined(searchString)) {
        ctrl.searchParams.searchString.toggleValue(searchString);
      }

      // Reset search start if not given, e.g. when the search criteria or sorting changes. This fixes the problem
      // when e.g. you are viewing page 4 of search results, then change the search criteria and the new results
      // only have 2 pages worth of results (but you are still requesting page 4), which results in empty search
      // results.
      ctrl.searchParams.start = angular.isDefined(start) ? start : 0;

      // Always save parameters to storage. Otherwise will cause phantom searchString to appear in
      // 1. Type "Jallu" 2. Clear search field 3. Re-enter the page. Result: search box contains "Jallu"
      // instead of "".
      saveSearchParametersToLocalStorage(ctrl.saveSearchParameters);
      if (!ctrl.isValidQuery() || !ctrl.searchParams.isValid()) {
        // $timeout maybe helps with e2e tests
        $timeout(initSearchStringAndResults);
        // Reject without message so that it doesn't get logged as an error, this is nothing exceptional
        return $q.reject();
      }
      return ctrl.searchService.search(ctrl.searchParams).then(result => {
        ctrl.searchResult = result;
        ctrl.results = [];
        _.forEach(result.searchResults, resultItem => {
          ctrl.results.push(resultItem);
        });
        if (angular.isDefined(searchString)) {
          ctrl.searchString = searchString;
        }
        ctrl.totalCount = result.total;
        ctrl.limit = result.limit;
        ctrl.start = result.start;
        ctrl.tooManyResults = hasTooManyResults(result);
        if (ctrl.onSearch) {
          ctrl.onSearch({
            result
          });
        }
        return result;
      }).catch(error => {
        clearSearchParametersFromStorage(ctrl.saveSearchParameters);
        throw error;
      });
    };
    ctrl.isValidQuery = function () {
      if (ctrl.searchParams.searchString) {
        return ctrl.searchParams.searchString.value.length <= 255;
      }
      return true;
    };
    ctrl.someOtherFieldThanNameInUILangMatched = function (resultItem) {
      if (resultItem) {
        return matchInOtherLang(resultItem.name, resultItem.nameMatch) || matchInOtherLang(resultItem.name, resultItem.searchTagsMatch);
      }
    };
    ctrl.containsHit = function (value) {
      return _.includes(value, '<b>');
    };
  }
})();