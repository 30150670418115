<sis-contextual-notification *ngIf="validationErrorDetails.length > 0" variant="danger">
  <div sisAutofocus [id]="id" tabindex="-1" aria-live="assertive">
    <div *ngIf="description" class="row">
      <div class="col-12">
        <p>{{ description }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <ul class="error-list">
          <li class="error-list__item" *ngFor="let error of validationErrorDetails">
            <sis-button [link]="true" (clicked)="focusOnElement(error.inputId)">{{ error.inputLabel }}</sis-button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</sis-contextual-notification>
