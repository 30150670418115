import angular from 'angular';
import _ from 'lodash';
(function () {
  NewMessage.$inject = ["$q", "$translate", "messageJSDataModel", "commonMessageService", "planSelectorService", "MessageType", "NewMessageType", "PlanOperationType", "SELECTION_TYPES", "suggestAddMessageService", "localeService"];
  angular.module('sis-components.service.NewMessage', ['sis-components.model.message', 'sis-components.service.commonMessageService', 'sis-components.plan.planSelectorService', 'tutor.student.tutoring.plan.suggestAddMessageService', 'sis-components.plan.planSelectorService', 'sis-common.l10n.localeService']).constant('NewMessageType', {
    PLAN_GENERIC: 'SIS_COMPONENTS.MESSENGER.NEW.TYPE_PLAN_GENERIC',
    PLAN_TARGETED: 'SIS_COMPONENTS.MESSENGER.NEW.TYPE_PLAN_TARGETED',
    PLAN_SUGGEST_ADD: 'SIS_COMPONENTS.MESSENGER.NEW.TYPE_PLAN_SUGGEST_ADD'
  }).factory('NewMessage', NewMessage);

  /**
   * @ngInject
   */
  function NewMessage($q, $translate, messageJSDataModel, commonMessageService, planSelectorService, MessageType, NewMessageType, PlanOperationType, SELECTION_TYPES, suggestAddMessageService, localeService) {
    function resetMessageType(newMessage) {
      newMessage.message.type = MessageType.PLAN_GENERIC;
      newMessage.message.moduleId = undefined;
      newMessage.message.courseUnitId = undefined;
      newMessage.message.customStudyDraft = undefined;
      newMessage.message.parentModuleId = undefined;
      newMessage.message.operationType = undefined;
      newMessage.newMessageType = NewMessageType.PLAN_GENERIC;
    }
    function setMessageTarget(newMessage, validatablePlan) {
      const title = 'SIS_COMPONENTS.MESSENGER.TARGETED.TITLE';
      const description = 'SIS_COMPONENTS.MESSENGER.TARGETED.TITLE_HELP';
      return planSelectorService.openPlanSelectorModal(title, description, validatablePlan).then(selection => {
        newMessage.newMessageType = NewMessageType.PLAN_TARGETED;
        const {
          message
        } = newMessage;
        message.operationType = PlanOperationType.COMMENT;
        message.parentModuleId = selection.parentId;
        if (selection.type === SELECTION_TYPES.COURSE_UNIT) {
          message.type = MessageType.PLAN_COURSE_UNIT;
          message.courseUnitId = selection.id;
        } else if (selection.type === SELECTION_TYPES.CUSTOM_STUDY_DRAFT) {
          message.type = MessageType.PLAN_CUSTOM_STUDY_DRAFT;
          message.customStudyDraft = selection.customStudyDraft;
        } else {
          message.type = MessageType.PLAN_MODULE;
          message.moduleId = selection.id;
        }
        return messageJSDataModel.loadRelations(message, ['courseUnit', 'module']);
      });
    }
    function suggestAddMessage(newMessage, validatablePlan, plan) {
      return suggestAddMessageService.open(plan, validatablePlan).then(selection => {
        newMessage.newMessageType = NewMessageType.PLAN_SUGGEST_ADD;
        const {
          message
        } = newMessage;
        const suggestedObjectName = localeService.getLocalizedValue(_.get(selection, 'suggestedObject.name'));
        message.operationType = PlanOperationType.SUGGEST_ADD;
        message.parentModuleId = selection.targetModule.id;
        if (selection.selectedTarget.type === SELECTION_TYPES.ANY_COURSE_UNIT) {
          message.type = MessageType.PLAN_COURSE_UNIT;
          message.courseUnitId = selection.suggestedObject.id;
          if (!_.isEmpty(suggestedObjectName)) {
            message.title = $translate.instant('SIS_COMPONENTS.MESSENGER.SUGGEST_ADD.MSG_TITLE_ADD_COURSE_UNIT', {
              name: suggestedObjectName
            });
          }
        } else {
          message.type = MessageType.PLAN_MODULE;
          message.moduleId = selection.suggestedObject.id;
          if (!_.isEmpty(suggestedObjectName)) {
            message.title = $translate.instant('SIS_COMPONENTS.MESSENGER.SUGGEST_ADD.MSG_TITLE_ADD_MODULE', {
              name: suggestedObjectName
            });
          }
        }
        return messageJSDataModel.loadRelations(message, ['courseUnit', 'module']);
      });
    }
    return function (validatablePlan, plan) {
      const newMessage = this;
      this.message = messageJSDataModel.createInstance({
        type: MessageType.PLAN_GENERIC,
        studentId: plan.userId,
        planId: plan.id
      });
      this.newMessageType = NewMessageType.PLAN_GENERIC;
      this.resetMessageType = () => {
        resetMessageType(newMessage);
      };
      this.setType = type => {
        newMessage.resetMessageType();
        if (NewMessageType.PLAN_TARGETED === type) {
          return setMessageTarget(newMessage, validatablePlan);
        }
        if (NewMessageType.PLAN_SUGGEST_ADD === type) {
          return suggestAddMessage(newMessage, validatablePlan, plan);
        }
        return $q.when(null);
      };
      this.save = () => commonMessageService.createMessage(newMessage.message).then(createdMessage => {
        newMessage.resetMessageType();
        newMessage.message.title = undefined;
        newMessage.message.comment = '';
        newMessage.agreementCheck = undefined;
        return createdMessage;
      });
    };
  }
})();