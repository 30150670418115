import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';

import { featureToggleStates } from './feature-toggle.route';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        UIRouterModule.forChild({ states: featureToggleStates }),

    ],
})
export class FeatureToggleModule { }
