import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { DegreeProgrammeAttainment, DPSMBase, ModuleAttainment } from 'common-typescript/types';

@Component({
    selector: 'app-student-module-info-modal-header',
    templateUrl: './student-module-info-modal-header.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudentModuleInfoModalHeaderComponent implements OnInit {

    @Input() attainment: ModuleAttainment | DegreeProgrammeAttainment;
    @Input() module: DPSMBase;

    @Output() closeModal = new EventEmitter<void>();

    moduleLocalizationKey: string;

    ngOnInit() {
        switch ((this.module.type as 'DegreeProgramme' | 'StudyModule')) {
            case 'DegreeProgramme': {
                this.moduleLocalizationKey = 'ENTITY_NAMES.DegreeProgramme';
                break;
            }
            case 'StudyModule': {
                this.moduleLocalizationKey = 'ENTITY_NAMES.StudyModule';
                break;
            }
            default: {
                break;
            }
        }
    }

}
