(function () {
  'use strict';

  angular.module('student.common.model.rule', ['student.common.utils.RuleVisitor', 'student.common.model.module', 'student.shared']).factory('ruleModel', ruleModel).factory('Rule', ruleFactory);
  ruleModel.$inject = ["Range", "Rule"];
  function ruleModel(Range, Rule) {
    var ruleModel = {
      newRule: newRule,
      CreditsRule: CreditsRule,
      CompositeRule: CompositeRule,
      ModuleRule: ModuleRule,
      CourseUnitRule: CourseUnitRule,
      AnyCourseUnitRule: AnyCourseUnitRule,
      AnyModuleRule: AnyModuleRule,
      CourseUnitCountRule: CourseUnitCountRule
    };
    function newRule(data, parentRule, blockLevel) {
      if (data) {
        return new ruleModel[data.type](data, parentRule, blockLevel);
      } else {
        return null;
      }
    }
    function CreditsRule(data) {
      Rule.apply(this, arguments);
      this.type = 'CreditsRule';
      if (data.credits) {
        this.credits = new Range(data.credits);
      } else {
        this.credits = new Range(data.min, data.max);
      }
      this.rule = newRule(data.rule, this, this.blockLevel);
    }
    CreditsRule.prototype = Object.create(Rule.prototype);
    CreditsRule.prototype.constructor = CreditsRule;
    function CourseUnitCountRule(data) {
      Rule.apply(this, arguments);
      this.type = 'CourseUnitCountRule';
      if (data.count) {
        this.count = new Range(data.count);
      } else {
        this.count = new Range(data.min, data.max);
      }
      this.rule = newRule(data.rule, this, this.blockLevel);
    }
    CourseUnitCountRule.prototype = Object.create(Rule.prototype);
    CourseUnitCountRule.prototype.constructor = CourseUnitCountRule;
    function CompositeRule(data) {
      Rule.apply(this, arguments);
      var self = this;
      self.type = 'CompositeRule';
      if (data.require) {
        self.require = new Range(data.require);
      } else {
        // Missing require indicates all sub rules are mandatory
        self.require = new Range(data.rules.length, data.rules.length);
      }
      self.description = _.get(data, 'description', null);
      self.rules = _.map(data.rules, function (ruleData) {
        var subRule = newRule(ruleData, self, self.blockLevel + 1);
        subRule.parentRule = self;
        return subRule;
      });
    }
    CompositeRule.prototype = Object.create(Rule.prototype);
    CompositeRule.prototype.constructor = CompositeRule;
    CompositeRule.prototype.isSelectAll = function () {
      return this.require.min === this.rules.length;
    };
    CompositeRule.prototype.isSelectOne = function () {
      return this.rules.length > 1 && this.require.max === 1;
    };
    CompositeRule.prototype.isSelectOneRequired = function () {
      return this.require.max === 1 && this.require.min === 1;
    };
    CompositeRule.prototype.getMatchingModules = function () {
      requireValidationResults(this);
      return _.values(this.validationResults.matchingModulesByGroupId);
    };
    CompositeRule.prototype.getMatchingCourseUnits = function () {
      requireValidationResults(this);
      return _.values(this.validationResults.matchingCourseUnitsByGroupId);
    };
    CompositeRule.prototype.isMatchingModule = function (module) {
      requireValidationResults(this);
      return !!this.validationResults.matchingModulesByGroupId[module.id];
    };
    CompositeRule.prototype.isMatchingCourseUnit = function (courseUnit) {
      requireValidationResults(this);
      return !!this.validationResults.matchingCourseUnitsByGroupId[courseUnit.groupId];
    };
    CompositeRule.prototype.isRequiredCourseUnit = function (courseUnit) {
      return this.isSelectAll() || this.isSelectOneRequired() && this.isOnlyCourseUnitSelection(courseUnit);
    };
    CompositeRule.prototype.isRequiredModule = function (module) {
      return this.isSelectAll() || this.isSelectOneRequired() && this.isOnlyModuleSelection(module);
    };
    CompositeRule.prototype.isOnlyCourseUnitSelection = function (courseUnit) {
      requireValidationResults(this);
      return isOnlySelectionIn(courseUnit, this.validationResults.matchingCourseUnitsByGroupId, this);
    };
    CompositeRule.prototype.isOnlyModuleSelection = function (module) {
      requireValidationResults(this);
      return this.validationResults ? isOnlySelectionIn(module, this.validationResults.matchingModulesByGroupId, this) : null;
    };
    function isOnlySelectionIn(selection, selectionsById, rule) {
      return !!(selectionsById[selection.id] && rule.validationResults.directCount === 1);
    }
    function ModuleRule(data) {
      Rule.apply(this, arguments);
      var self = this;
      self.type = 'ModuleRule';
      self.moduleGroupId = data.moduleGroupId;
    }
    ModuleRule.prototype = Object.create(Rule.prototype);
    ModuleRule.prototype.constructor = ModuleRule;
    ModuleRule.prototype.matches = function (module) {
      return this.moduleGroupId === module.groupId;
    };

    // AnyModuleRule
    function AnyModuleRule() {
      Rule.apply(this, arguments);
      var self = this;
      self.type = 'AnyModuleRule';
    }
    AnyModuleRule.prototype = Object.create(Rule.prototype);
    AnyModuleRule.prototype.constructor = AnyModuleRule;
    AnyModuleRule.prototype.matches = function () {
      return true;
    };
    AnyModuleRule.prototype.isBlock = function () {
      return true;
    };
    AnyModuleRule.prototype.isRequiredModule = function () {
      return false;
    };
    function CourseUnitRule(data) {
      Rule.apply(this, arguments);
      var self = this;
      self.type = 'CourseUnitRule';
      self.courseUnitGroupId = data.courseUnitGroupId;
    }
    CourseUnitRule.prototype = Object.create(Rule.prototype);
    CourseUnitRule.prototype.constructor = CourseUnitRule;
    CourseUnitRule.prototype.matches = function (courseUnit) {
      return this.courseUnitGroupId === courseUnit.groupId;
    };
    function AnyCourseUnitRule() {
      Rule.apply(this, arguments);
      var self = this;
      self.type = 'AnyCourseUnitRule';
    }
    AnyCourseUnitRule.prototype = Object.create(Rule.prototype);
    AnyCourseUnitRule.prototype.constructor = AnyCourseUnitRule;
    AnyCourseUnitRule.prototype.matches = function () {
      return true;
    };
    AnyCourseUnitRule.prototype.isBlock = function () {
      return true;
    };
    AnyCourseUnitRule.prototype.isRequiredCourseUnit = function () {
      return false;
    };
    return ruleModel;
    function requireValidationResults(rule) {
      return rule.validationResults ? rule.validationResults : function () {
        throw 'Illegal state: validation required first';
      };
    }
  }
  function ruleFactory() {
    function Rule(data, parentRule, blockLevel) {
      this.localId = _.get(data, 'localId');
      this.parentRule = parentRule ? parentRule : null;
      this.blockLevel = blockLevel ? blockLevel : 1;
    }
    Rule.prototype.accept = function (visitor, ctx) {
      return visitor['visit' + this.type].call(visitor, this, ctx);
    };
    Rule.prototype.isBlock = function () {
      return !!(this.rule || this.rules);
    };
    Rule.prototype.isActive = function () {
      return this.validationResults && this.validationResults.active;
    };
    return Rule;
  }
})();