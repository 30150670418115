<div class="row" data-cy="open-university-enrolment-right">
  <div class="col-12 sis-mb-md" *ngIf="editInfos?.length > 0">
    <sis-contextual-notification variant="warning" data-cy="enrolment-right-edit-info">
      {{'OPENUNIVERSITY.STUDY_RIGHTS.ENROLMENT_RIGHT.EDITED' | translate}}
      <sis-button [link]="true" (clicked)="openEditInfoDialog()">
        {{'OPENUNIVERSITY.STUDY_RIGHTS.ENROLMENT_RIGHT.SHOW_EDIT_RATIONALE' | translate}}
      </sis-button>
    </sis-contextual-notification>
  </div>
  <div class="col-12 sis-mb-md" *ngIf="cancellationInfo">
    <sis-contextual-notification variant="warning" data-cy="enrolment-right-cancellation-info">
      {{'OPENUNIVERSITY.STUDY_RIGHTS.ENROLMENT_RIGHT.CANCELLED' | translate: {date: cancellationInfo.modificationTime | localDateFormat} }}
      <sis-button [link]="true" (clicked)="openCancellationInfoDialog()">
        {{'OPENUNIVERSITY.STUDY_RIGHTS.ENROLMENT_RIGHT.SHOW_CANCELLATION_RATIONALE' | translate}}
      </sis-button>
    </sis-contextual-notification>
  </div>
  <div class="col-12 col-sm-6 col-lg-5 sis-mb-sm">
    <sis-description-list-compact>
      <dl>
        <ng-template #compactListItem *ngIf="enrolmentRight?.courseUnitId | courseUnit | async as courseUnit">
          <dt class="sis-label colon">{{ 'OPENUNIVERSITY.STUDY_RIGHTS.ENROLMENT_RIGHT.COURSE_UNIT_CODE' | translate}}</dt>
          <dd class="sis-value">
            <a *ngIf="componentType === 'STAFF' && curriculumPeriod$ | async as curriculumPeriod"
               href="/staff/studies/staff/{{curriculumPeriod.id}}/courseunit/{{courseUnit.id}}/basicinfo">{{courseUnit.code}}
            </a>
            <a *ngIf="componentType === 'STUDENT'" href="/student/courseunit/{{courseUnit.id}}">{{courseUnit.code}}</a>
          </dd>
        </ng-template>
        <ng-template #compactListItem *ngIf="formattedCredits$ | async as formattedCredits">
          <dt class="sis-label colon">{{ 'OPENUNIVERSITY.STUDY_RIGHTS.ENROLMENT_RIGHT.SCOPE' | translate}}</dt>
          <dd class="sis-value">
            {{formattedCredits}}
          </dd>
        </ng-template>
        <ng-template #compactListItem>
          <dt class="sis-label colon">{{ 'OPENUNIVERSITY.STUDY_RIGHTS.ENROLMENT_RIGHT.VALIDITY_PERIOD' | translate}}</dt>
          <dd class="sis-value">{{enrolmentRight.validityPeriod | localDateRange}}</dd>
        </ng-template>
        <ng-template #compactListItem *ngIf="isCurEnrolmentRight">
          <dt class="sis-label colon">{{ 'OPENUNIVERSITY.STUDY_RIGHTS.ENROLMENT_RIGHT.GRANTED_BY_PRODUCT' | translate}}</dt>
          <dd class="sis-value" *ngIf="openUniversityProduct$ | async as openUniversityProduct">
            <a *ngIf="componentType === 'STAFF' && curriculumPeriod$ | async as curriculumPeriod"
               href="/staff/studies/staff/{{curriculumPeriod.id}}/courseunit/{{enrolmentRight?.courseUnitId}}/openuniversity-studyoffers/{{enrolmentRight?.courseUnitId}}/view-product/{{openUniversityProduct.id}}/info"
            >{{openUniversityProduct.name | localizedString}}</a>
            <ng-container *ngIf="componentType === 'STUDENT'">{{openUniversityProduct.name | localizedString}}</ng-container>
          </dd>
        </ng-template>
      </dl>
    </sis-description-list-compact>
  </div>
  <div class="col-12 col-sm-6 col-lg-7" *ngIf="isCurEnrolmentRight">
    <ng-container *ngIf="courseUnitRealisationsByAssessmentItemId$ | async as courseUnitRealisationsByAssessmentItemId">
      <ng-container *ngIf="showInfoTextByAssessmentItemId$ | async as showInfoTextByAssessmentItemId">
        <ng-container *ngIf="remainingEnrolmentCountsByAssessmentItemId$ | async as remainingEnrolmentCountsByAssessmentItemId">
          <sis-description-list [columnCount]="1">
            <dl>
              <ng-template #listItem *ngFor="let assessmentItem of assessmentItems$ | async">
                <dt class="sis-label">
                  {{ assessmentItem.name | localizedString }} ({{ assessmentItem.credits | creditRange}})
                </dt>
                <dd>
                  <sis-open-university-assessment-item
                    [courseUnitRealisations]="courseUnitRealisationsByAssessmentItemId[assessmentItem.id] || []"
                    [showCourseUnitRealisationInfoText]="showInfoTextByAssessmentItemId[assessmentItem.id]"
                    [remainingEnrolmentCounts]="remainingEnrolmentCountsByAssessmentItemId[assessmentItem.id]"
                    [componentType]="componentType">
                  </sis-open-university-assessment-item>
                </dd>
              </ng-template>
            </dl>
          </sis-description-list>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>
