import {
    AfterViewInit,
    Component, ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output, ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { required } from 'sis-components/form/form-validators';
import { SisFormBuilder } from 'sis-components/form/sis-form-builder.service';

import {
    StudentCustomStudyDraftOptions,
} from '../../custom-study-draft-modal/custom-study-draft-modal.component';

@Component({
    selector: 'app-apply-custom-study-draft-credit',
    templateUrl: './apply-custom-study-draft-credit.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ApplyCustomStudyDraftCreditComponent implements OnInit, AfterViewInit {

    @Input() options: StudentCustomStudyDraftOptions;
    @Input() studyDraftName: string;
    @Input() homeUniversityName: string;
    @Output() close = new EventEmitter<void>();
    @Output() back = new EventEmitter<void>();
    @Output() suggestedAttainment = new EventEmitter<string>();

    @ViewChild('headingElementRef') headingElementRef: ElementRef;

    workflowOptionTypeFormControl: FormControl<string>;

    constructor(private fb: SisFormBuilder) { }

    ngOnInit(): void {
        this.workflowOptionTypeFormControl = this.fb.control('INCLUSION_APPLICATION', required());
    }

    ngAfterViewInit(): void {
        this.headingElementRef.nativeElement.focus();
    }

    selectWorkflowOperationType(): void {
        this.workflowOptionTypeFormControl.markAllAsTouched();
        if (this.workflowOptionTypeFormControl.valid) {
            // if user picks INTERNSHIP_CUSTOM_ATTAINMENT_APPLICATION (which is not a real workflow type) it is redirected to CUSTOM_ATTAINMENT_APPLICATION process. Internship is created only for viewing purposes in frontend
            let workflowOperation = this.workflowOptionType.value;
            if (workflowOperation === 'INTERNSHIP_CUSTOM_ATTAINMENT_APPLICATION') {
                workflowOperation = 'CUSTOM_ATTAINMENT_APPLICATION';
            }

            this.suggestedAttainment.emit(workflowOperation);
        }
    }

    get workflowOptionType(): FormControl {
        return this.workflowOptionTypeFormControl;
    }

}
