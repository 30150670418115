import { Pipe, PipeTransform } from '@angular/core';
import { ISO_LOCAL_DATE_FORMAT } from 'common-typescript/constants';
import { LocalDateString } from 'common-typescript/types';
import moment from 'moment';

@Pipe({ name: 'previousDay' })
export class PreviousDayPipe implements PipeTransform {

    transform(value: LocalDateString | moment.Moment): LocalDateString {
        return value ? moment(value).subtract(1, 'days').format(ISO_LOCAL_DATE_FORMAT) : null;
    }
}
