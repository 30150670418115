import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Plan } from 'common-typescript/types';
import moment from 'moment';

@Injectable({
    providedIn: 'root',
})
export class PlanCopyService {

    constructor(private translocoService: TranslocoService) {
    }

    copyPlanObject(plan: Plan): Plan {
        const planCopy: Plan = { ...plan };
        this.removePlanMeta(planCopy);
        planCopy.name = this.getCopyName(plan.name);
        return planCopy;
    }

    private removePlanMeta(plan: Plan) {
        delete plan.metadata;
        delete plan.id;
        plan.primary = false;
    }

    private getCopyName(name: string): string {
        return `${name} ${this.translocoService.translate('PLAN.COPY').toUpperCase()} ${moment().format('DD.MM.YYYY HH:mm:ss')}`;
    }

}
