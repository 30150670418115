<button type="button"
        class="non-button sortable-table-column-header"
        (click)="sortBy()"
        [attr.aria-label]="(name | translate) + ' ' + ('ARIA_LABEL.SORT_BY' | translate)"
        [attr.aria-sort]="ariaSortValue">
  {{name | translate}}
  <sis-icon icon="sorter"
            [color]="orderKey !== column ? 'default' : 'primary-dark'"
            [iconClass]="orderKey === column && reverse ? 'flip-180' : ''"></sis-icon>
</button>
<div id="sorting-guidance-{{column}}" *ngIf="orderKey === column" aria-live="polite" class="visually-hidden">
  {{ariaLiveMessage | translate}}
</div>
