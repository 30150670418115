import { RangeValidationResultState } from '../../../types';

export class RangeValidationResult {

    result: RangeValidationResultState;
    minRequired: number;
    maxAllowed: number | null;

    constructor(rangeValidationResultState: RangeValidationResultState, minRequired: number, maxAllowed: number | null) {
        this.result = rangeValidationResultState;
        this.minRequired = minRequired;
        this.maxAllowed = maxAllowed;
    }

}
