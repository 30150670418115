<div class="tweet-text" *ngIf="courseUnit.tweetText">
  <span>
    <span>{{courseUnit.tweetText | localizedString}}</span>
  </span>
</div>

<div role="heading" [attr.aria-level]="level" class="fudis-heading__md">{{'COURSE_UNIT_INFO_MODAL.BASIC_INFO' | translate}}</div>
<app-course-unit-info-basic-info-header [courseUnit]="courseUnit" class="d-block mb-5">
</app-course-unit-info-basic-info-header>

<sis-expandable [title]="'STUDIES.COURSE_UNIT_INFO_MODAL.CONTENT_AND_GOALS' | translate" [closed]="false" [level]="level">
  <ng-template sisExpandableContent>
    <app-course-unit-info-content-and-goals
      [outcomes]="courseUnit.outcomes"
      [content]="courseUnit.content"
      [additional]="courseUnit.additional">
    </app-course-unit-info-content-and-goals>
  </ng-template>
</sis-expandable>

<sis-expandable [title]="'STUDIES.COURSE_UNIT_INFO_MODAL.PREREQUISITES' | translate" [closed]="false" [level]="level">
  <ng-template sisExpandableContent>
    <app-course-unit-info-prerequisites
      [prerequisites]="courseUnit.prerequisites"
      [compulsoryFormalPrerequisites]="courseUnit.compulsoryFormalPrerequisites"
      [recommendedFormalPrerequisites]="courseUnit.recommendedFormalPrerequisites">
    </app-course-unit-info-prerequisites>
  </ng-template>
</sis-expandable>

<sis-expandable [title]="'STUDIES.COURSE_UNIT_INFO_MODAL.COURSE_UNIT_LEARNING_MATERIALS' | translate" [closed]="false" [level]="level">
  <ng-template sisExpandableContent>
    <app-course-unit-info-learning-materials
      [learningMaterial]="courseUnit.learningMaterial"
      [literature]="courseUnit.literature">
    </app-course-unit-info-learning-materials>
  </ng-template>
</sis-expandable>

<sis-expandable [title]="'STUDIES.COURSE_UNIT_INFO_MODAL.TITLE_CLASSIFICATION' | translate" [closed]="false" [level]="level">
  <ng-template sisExpandableContent>
    <app-course-unit-info-title-classification
      [studyFields]="courseUnit.studyFields"
      [searchTags]="courseUnit.searchTags">
    </app-course-unit-info-title-classification>
  </ng-template>
</sis-expandable>

<sis-expandable [title]="'STUDIES.COURSE_UNIT_INFO_MODAL.RESPONSIBILITY_TEACHERS_AND_CONTACT_INFO' | translate" [closed]="false" [level]="level">
  <ng-template sisExpandableContent>
    <app-course-unit-info-responsibility-teachers-contact-info
      [responsibilityInfos]="sortedByNameResponsibilityInfos"
      [language]="language"
      [organisations]="courseUnit.organisations">
    </app-course-unit-info-responsibility-teachers-contact-info>
  </ng-template>
</sis-expandable>
