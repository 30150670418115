import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { Grade, GradeScale, OtmId } from 'common-typescript/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

import { EntityService } from './entity.service';

const CONFIG = {
    ENDPOINTS: {
        backend: '/kori/api',
    },
};

@StaticMembers<DowngradedService>()
@Injectable({ providedIn: 'root' })
@NgEntityServiceConfig({ baseUrl: CONFIG.ENDPOINTS.backend })
export class GradeScaleEntityService extends EntityService<GradeScaleState> {

    static downgrade: ServiceDowngradeMappings = {
        dependencies: [],
        moduleName: 'sis-components.service.gradeScaleEntityService',
        serviceName: 'gradeScaleEntityService',
    };

    constructor() {
        super(GradeScaleStore, GradeScaleQuery);
    }

    /**
     * Same as {@link #getGrade} but returns an observable.
     *
     * @param gradeId The grade.localId of desired grade
     * @param gradeScaleId scale where the grade should belong
     * @return observable that emits resolved grade or undefined
     */
    findGrade(gradeId: number, gradeScaleId: OtmId): Observable<Grade> {
        return this.getById(gradeScaleId)
            .pipe(map(scale => this.getGrade(gradeId, gradeScaleId, [scale])));
    }

    /**
     * Resolves grade by gradeId and gradeScaleId from given gradeScales.
     *
     * @param gradeId The grade.localId of desired grade
     * @param gradeScaleId scale where the grade should belong
     * @param gradeScales list of scales that should contain one with given grade scale id
     * @return resolved Grade object or undefined
     */
    getGrade(gradeId: number, gradeScaleId: OtmId, gradeScales: GradeScale[]): Grade {
        const gradeScale = gradeScales?.find(scale => scale.id === gradeScaleId);
        if (!gradeScale) {
            return undefined;
        }
        return gradeScale.grades.find(g => g.localId === gradeId);
    }
}

type GradeScaleState = EntityState<GradeScale, OtmId>;

@StoreConfig({ name: 'grade-scales' })
class GradeScaleStore extends EntityStore<GradeScaleState> {}

class GradeScaleQuery extends QueryEntity<GradeScaleState> {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(store: GradeScaleStore) {
        super(store);
    }
}
