import angular from 'angular';
(function () {
  angular.module('student.timing.route', ['ui.router', 'ui.router.upgrade', 'student.timing.timing', 'student.common.service.initialAppCheck']).config(["$stateProvider", $stateProvider => {
    const initLearningOpportunityCheck = function ($transition$, initialAppCheck, plans) {
      if ($transition$.params().planId) {
        return initialAppCheck.triggerForceLearningOpportunityModalIfNotSelected(plans);
      }
    };
    initLearningOpportunityCheck.$inject = ["$transition$", "initialAppCheck", "plans"];
    const setTimingViewData = function ($scope, plans, selectedPlan) {
      $scope.plans = plans;
      $scope.selectedPlan = selectedPlan;
    };
    setTimingViewData.$inject = ["$scope", "plans", "selectedPlan"];
    $stateProvider.state('student.logged-in.plan.timing', {
      url: '/timing',
      resolve: {
        learningOpportunityCheck: initLearningOpportunityCheck
      },
      redirectTo: transition => {
        const selectedModuleId = transition.params().selectedModuleId ?? '';
        const planId = transition.params().planId ?? '';
        return {
          state: 'student.logged-in.plan.timing.untimed',
          params: {
            selectedModuleId,
            planId
          }
        };
      }
    }).state('student.logged-in.plan.timing.untimed', {
      url: '/:selectedModuleId?',
      template: '<timing plans="plans" selected-plan="selectedPlan"></timing>',
      data: {
        showHome: true,
        hideLastNode: false,
        hideFooter: true,
        browserTabTitle: 'APPLICATION_TIMING'
      },
      headerParams: {
        displayNameKey: 'APPLICATION_TIMING',
        skipBreadcrumb: false
      },
      controller: setTimingViewData
    });
  }]);
})();