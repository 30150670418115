import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormArray, FormControl } from '@angular/forms';
import { CourseUnit, CourseUnitRealisationSeats, OtmId, StudyGroupSet } from 'common-typescript/types';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { EnrichedAssItem, EnrichedCur } from '../open-university-enrolment-wizard.component';

@Component({
    selector: 'app-open-university-cur-selection-step',
    templateUrl: './open-university-cur-selection-step.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class OpenUniversityCurSelectionStepComponent implements OnInit, OnDestroy {

    @Input() form: AbstractControl;
    @Input() assessmentItem: EnrichedAssItem;
    @Input() courseUnit: CourseUnit;
    @Input() showErrors: boolean;
    @Input() curSeats: CourseUnitRealisationSeats[];
    @Output() selectedCurValueChange = new EventEmitter<EmittedCurDataChangeObj>();
    selectedCourseUnitRealisation: EnrichedCur;
    destroyed$ = new Subject<void>();
    isFormDataFirstUpdate = true;
    fullCourseUnitRealisations: OtmId[] = [];

    ngOnInit(): void {
        this.curControl.valueChanges.pipe(takeUntil(this.destroyed$))
            .subscribe((curId) => this.selectedCur(curId));

        // This is used when navigating back to previous page.
        if (this.curControl.value) {
            this.findCur(this.curControl.value);
        }

        // Check which curs are full
        this.assessmentItem?.courseUnitRealisations.forEach(cur => {
            const currentCurSeats = this.curSeats?.find(curSeat => curSeat.curId === cur.id);
            if (currentCurSeats?.availableSeats === 0) this.fullCourseUnitRealisations.push(cur.id);
        });

        // Selects cur automatically if there is only one inside the assItem.
        if (!this.curControl.value && this.assessmentItem?.courseUnitRealisations?.length === 1) {
            this.curControl.setValue(this.assessmentItem?.courseUnitRealisations[0].id);
        }
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
    }

    findCur(curId: string) {
        this.selectedCourseUnitRealisation = this.assessmentItem?.courseUnitRealisations.find(cur => cur?.id === curId);
    }

    selectedCur(curId: string) {
        this.findCur(curId);
        this.selectedCurValueChange.emit({ cur: this.selectedCourseUnitRealisation, isFormDataFirstUpdate: false });
        this.isFormDataFirstUpdate = false;
    }

    get curControl(): FormControl {
        return this.form.get('cur') as FormControl;
    }

    enrolmentQuestionControlArray(): FormArray {
        return this.form.get('enrolmentQuestionAnswers') as FormArray;
    }

    enrolmentQuestionControl(index: number, localId: string): FormArray {
        const formArray = this.enrolmentQuestionControlArray();
        return formArray.at(index).get(localId) as FormArray;
    }

    ssgControl(sgsIndex: number, sgs: StudyGroupSet, ssgIndex: number): FormControl {
        const studyGroupSetFormArray = this.sgsControlArray(sgsIndex, sgs);
        return sgs.subGroupRange.min === 1 && sgs.subGroupRange.max === 1 ?
            studyGroupSetFormArray.at(0) as FormControl :
            studyGroupSetFormArray.at(ssgIndex) as FormControl;
    }

    sgsControlArray(sgsIndex: number, sgs: StudyGroupSet): FormArray {
        const studyGroupSetsFormArray = this.form.get('sgs') as FormArray;
        return studyGroupSetsFormArray.at(sgsIndex).get(sgs.localId) as FormArray;
    }
}

export interface EmittedCurDataChangeObj {
    cur: EnrichedCur;
    isFormDataFirstUpdate: boolean;
}
