<ng-container *transloco="let t">
  <ng-container *ngIf="canApplyForModuleContentApproval()">
    <h4 class="apply-for-mca-header">{{ t('MODULE_CONTENT_APPROVAL_INFO_MODAL.MODULE_CONTENT_APPROVAL_REQUEST_TITLE') }}</h4>
    <div *ngIf="translationExists('MODULE_CONTENT_APPROVAL_HELP.' + statusCode + '.DESCRIPTION')">{{ t('MODULE_CONTENT_APPROVAL_HELP.' + statusCode + '.DESCRIPTION') }}</div>
    <sis-contextual-notification *ngIf="translationExists('MODULE_CONTENT_APPROVAL_HELP.' + statusCode + '.INSTRUCTION')" variant="danger">{{ t('MODULE_CONTENT_APPROVAL_HELP.' + statusCode + '.INSTRUCTION') }}</sis-contextual-notification>
    <div class="float-end mca-buttons">
      <sis-button
        *ngIf="isFreeEdit && !isButtonHidden()"
        [size]="'sm'"
        [dataCy]="'request-approval-for-exceptions'"
        (clicked)="makeCustomModuleContentApplicationRequest()">{{t('FREE_EDIT.MAKE_MODULE_CONTENT_APPROVAL_REQUEST')}}
      </sis-button>
      <sis-button
        *ngIf="!isFreeEdit && moduleContentApplicationRequired && !isButtonHidden()"
        [size]="'sm'"
        [dataCy]="'request-approval-for-module'"
        (clicked)="makeRequiredModuleContentApplicationRequest()">{{t('MODULE_CONTENT_APPROVAL_INFO_MODAL.MAKE_MODULE_CONTENT_APPROVAL_REQUEST')}}
      </sis-button>
    </div>
    <div class="clearfix"></div>
  </ng-container>
</ng-container>
