import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { OpenUniversityCart } from 'common-typescript/types';
import { Subscription } from 'rxjs';

import { OpenUniversityErrorHelperService } from '../service/open-university-error-helper.service';

@Component({
    selector: 'app-open-university-studies-activate-error',
    templateUrl: './open-university-studies-activate-error.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class OpenUniversityStudiesActivateErrorComponent implements OnDestroy, OnInit {
    constructor(
        private errorHelperService: OpenUniversityErrorHelperService,
    ) {
    }

    @Input() openUniversityCart: OpenUniversityCart;

    contactLinkObject: { link: string, isEmail: boolean, contactLinkText: string };
    private openUniversityContactInfoSubscription: Subscription;

    ngOnInit(): void {
        this.openUniversityContactInfoSubscription = this.errorHelperService
            .fetchOpenUniversityContactInfoEmailOrUrlOrNull()
            .subscribe(linkObject => {
                this.contactLinkObject = linkObject;
            });
    }

    ngOnDestroy() {
        this.openUniversityContactInfoSubscription.unsubscribe();
    }
}
