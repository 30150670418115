<ng-container *transloco="let t">
  <dl class="d-flex flex-wrap">
    <div class="tiny-static-form-group sis-mr-xs">
      <dt class="label colon">{{ t('PLAN_EDIT.SELECTION_MODAL.RULE_HEADER.FIELDS.RULE') }}</dt>
      <dd *ngIf="targetCredits()"
          class="value"
          [attr.id]="ruleLegendId">{{
          t('PLAN_EDIT.SELECTION_MODAL.RULE_HEADER.RULE_CLAUSES.CHOOSE') + ' '
          + (targetCredits() | creditRange)
        }}
      </dd>
    </div>
    <ng-container *ngIf="!onlyShowRule">
      <div *ngIf="targetCredits()" class="tiny-static-form-group sis-mr-xs">
        <dt class="label colon">{{ t('PLAN_EDIT.SELECTION_MODAL.RULE_HEADER.FIELDS.CHOSEN') }}</dt>
        <dd class="value">{{ (plannedCredits() | intRange) + '/' + (targetCredits() | creditRange) }}</dd>
      </div>
      <div class="tiny-static-form-group">
        <dt class="label colon">{{ t('PLAN_EDIT.SELECTION_MODAL.RULE_HEADER.FIELDS.STATE') }}</dt>
        <dd class="value">{{ rangeValidationResultState() ?? '-' }}</dd>
      </div>
    </ng-container>
  </dl>
</ng-container>
