export interface CartWizardStep {
    /** Translation key for the page title */
    pageTitle?: string;
    /** Translation key for the wizard progress component */
    wizardProgressStep: string;
    /** Translation key for the back button in the bulk edit container */
    backButtonTitle?: string;
    /** Translation key for the forward button in the bulk edit container */
    forwardButtonTitle?: string;
}

/**
 * Defines the steps of the cart purchase wizard. The first two steps are implemented in `open-university-cart.component.ts`.
 * The third step (payment) is performed in an external payment system. Step 4 (confirmation) is implemented in
 * `cart-confirmation.component.ts`. The last step (activating studies) is actually not part of the purchase wizard at all,
 * but a completely separate wizard. However, it's visible in the progress bar of the purchase wizard to signal to the user
 * that the studies must be activated before the process is complete.
 */
export const CART_PURCHASE_WIZARD_STEPS: CartWizardStep[] = [
    {
        pageTitle: 'OPEN_UNIVERSITY_CART.CART',
        wizardProgressStep: 'OPEN_UNIVERSITY_CART.WIZARD.TEACHING.NAME',
        backButtonTitle: 'SIS_COMPONENTS.BUTTON.EXIT',
        forwardButtonTitle: 'SIS_COMPONENTS.BUTTON.CONTINUE',
    },
    {
        pageTitle: 'OPEN_UNIVERSITY_CART.CART',
        wizardProgressStep: 'OPEN_UNIVERSITY_CART.WIZARD.CONTACT_INFORMATION.NAME',
        backButtonTitle: 'SIS_COMPONENTS.BUTTON.PREVIOUS',
        forwardButtonTitle: 'SIS_COMPONENTS.BUTTON.CONTINUE',
    },
    {
        wizardProgressStep: 'OPEN_UNIVERSITY_CART.WIZARD.PAYMENT.NAME',
    },
    {
        wizardProgressStep: 'OPEN_UNIVERSITY_CART.WIZARD.CONFIRMATION.NAME',
    },
    {
        wizardProgressStep: 'OPEN_UNIVERSITY_CART.WIZARD.ACTIVATE_CART.NAME',
    },
];

export enum STEP {
    CHECK_SELECTIONS = 0,
    CONTACT_INFORMATION = 1,
    PAYMENT = 2,
    CONFIRMATION = 3,
    ACTIVATE_STUDIES = 4,
}
