<ng-container *transloco="let t">
    <fudis-dialog [size]="'sm'">
        <fudis-heading [level]="2" fudisDialogTitle>{{data.modalTitle | transloco}}</fudis-heading>
        <fudis-dialog-content>
            <fudis-grid [marginTop]="'md'" [marginBottom]="'md'">
                <fudis-body-text [variant]="'md-light'">{{data.infoText | transloco}}</fudis-body-text>
                <fudis-body-text [variant]="'md-light'">{{data.infoQuestion | transloco}}</fudis-body-text>
                <fudis-link [externalLink]="getLocalizedSurveyUrl()" [title]="data.continueButtonText | transloco"></fudis-link>
            </fudis-grid>
        </fudis-dialog-content>
        <fudis-dialog-actions>
            <fudis-button fudisDialogClose [label]="t('SIS_COMPONENTS.BUTTON.CLOSE')" (handleClick)="closeSurveyDialog()" />
        </fudis-dialog-actions>
    </fudis-dialog>
</ng-container>
