import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { Graduation, OtmId } from 'common-typescript/types';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { EntityService } from './entity.service';

const CONFIG = {
    ENDPOINTS: {
        backend: '/ori/api',
        forApplication(applicationId: OtmId) {
            return `${this.backend}/graduations/application/${applicationId}`;
        },
        forAttainment(attainmentId: OtmId) {
            return `${this.backend}/graduations/attainment/${attainmentId}`;
        },
    },
};

@NgEntityServiceConfig({
    baseUrl: CONFIG.ENDPOINTS.backend,
    resourceName: 'graduations',
})
@Injectable({
    providedIn: 'root',
})
export class GraduationEntityService extends EntityService<GraduationState> {

    constructor() {
        super(GraduationStore, GraduationQuery);
    }

    getByApplicationId(applicationId: OtmId): Observable<Graduation> {
        return this.getHttp().get<Graduation>(CONFIG.ENDPOINTS.forApplication(applicationId))
            .pipe(
                switchMap((graduation) => {
                    this.store.upsert(graduation.id, graduation);
                    return this.query.selectEntity(graduation.id);
                }),
            );
    }

    getByAttainmentId(attainmentId: OtmId): Observable<Graduation> {
        return this.getHttp().get<Graduation>(CONFIG.ENDPOINTS.forAttainment(attainmentId))
            .pipe(
                switchMap((graduation) => {
                    this.store.upsert(graduation.id, graduation);
                    return this.query.selectEntity(graduation.id);
                }),
            );
    }
}

type GraduationState = EntityState<Graduation, OtmId>;

@StoreConfig({ name: 'graduation' })
class GraduationStore extends EntityStore<GraduationState> {}

class GraduationQuery extends QueryEntity<GraduationState> {
    constructor(protected store: GraduationStore) {
        super(store);
    }
}
