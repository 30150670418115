import { Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { CourseUnit, CourseUnitRealisation } from 'common-typescript/types';

/**
 * @deprecated
 */
// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'course-unit-realisation-info' })
export class CourseUnitRealisationInfoDirective extends UpgradeComponent {

    /** The CUR whose info to show. The object must be mutable. */
    @Input() courseUnitRealisation: CourseUnitRealisation;
    @Input() courseUnit: CourseUnit;
    @Input() language?: string;

    constructor(elementRef: ElementRef, injector: Injector) {
        super('courseUnitRealisationInfo', elementRef, injector);
    }
}
