<ng-container *transloco="let t">
  <sis-study-box [id]="courseUnit.id"
                 [name]="courseUnit.name"
                 [code]="courseUnit.code"
                 [credits]="courseUnit.credits"
                 [level]="headingLevel"
                 [studyState]="studyState"
                 [attainedCredits]="attainment?.credits"
                 [gradeId]="attainment?.gradeId"
                 [gradeScaleId]="attainment?.gradeScaleId"
                 [isSubstituted]="isSubstituted"
                 [isCrossStudyCourseUnit]="isCrossStudyCourseUnit"
                 [action]="action"
                 [selected]="selected"
                 (actionChange)="actionChange.emit($event)"
                 [dottedBorder]="true"
                 (nameClickAction)="openCourseUnitInfoModal()">
    <ng-container study-box="notifications">
      <sis-study-notification-box type="implicit">
        <ng-container *ngTemplateOutlet="showSkipLink ? withSkipLink : noSkipLink"></ng-container>
      </sis-study-notification-box>
      <sis-study-notification-box *ngIf="newerVersionAvailable" type="info">{{t('SIS_COMPONENTS.PLAN.COURSE_UNIT.NEWER_VERSION_AVAILABLE')}}</sis-study-notification-box>
    </ng-container>
  </sis-study-box>

  <ng-template #noSkipLink>
    {{ t('SIS_COMPONENTS.PLAN.COURSE_UNIT.IMPLICIT_IN_MODULE_SHORT') }} {{ parentModule?.name | localizedString }}
  </ng-template>

  <ng-template #withSkipLink>
    {{ t('SIS_COMPONENTS.PLAN.COURSE_UNIT.IMPLICIT_IN_MODULE_SHORT') }}
    <button class="button link-button"
                (click)="scrollToParentModule()">
      {{ parentModule?.name | localizedString }}
    </button>
  </ng-template>
</ng-container>
