import {
    ChangeDetectionStrategy,
    Component, EventEmitter,
    Input, OnInit, Output,
    ViewEncapsulation,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CooperationNetwork } from 'common-typescript/types/generated/common-backend';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { LocaleService } from 'sis-common/l10n/locale.service';

import {
    FormControlChangeDetectorComponent,
} from '../../form/form-control-change-detector/form-control-change-detector.component';
import { getLabelState } from '../../form/formUtils';
import { Option } from '../../select/dropdown-select/dropdown-select.component';
import { CooperationNetworkEntityService } from '../../service/cooperation-network-entity.service';
import { UniversityService } from '../../service/university.service';

@Component({
    selector: 'sis-cooperation-network-selector',
    templateUrl: './cooperation-network-selector.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CooperationNetworkSelectorComponent extends FormControlChangeDetectorComponent<string> implements OnInit {

    @Input() label: string;
    @Input() clearable = false;
    @Input() required = false;
    @Input() removeButton = false;
    @Output() cooperationNetworkRemoved = new EventEmitter<string>();

    options$: Observable<Option[]>;

    getLabelState = getLabelState;

    constructor(
        private cooperationNetworkEntityService: CooperationNetworkEntityService,
        private localeService: LocaleService,
        private translateService: TranslateService,
        private universityService: UniversityService,
    ) {
        super();
    }

    removeCooperationNetwork(): void {
        this.cooperationNetworkRemoved.next(this.control.value);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.options$ = this.cooperationNetworkEntityService.getByUniversityOrgId(this.universityService.getCurrentUniversityOrgId())
            .pipe(
                take(1),
                map((cooperationNetworks) => this.getOptions(cooperationNetworks)),
            );
    }

    private getOptions(cooperationNetworks: CooperationNetwork[]): Option[] {
        return cooperationNetworks.map(cooperationNetwork => ({
            value: cooperationNetwork.id,
            label: this.getLabel(cooperationNetwork),
            info: this.getInfo(cooperationNetwork),
        }));
    }

    private getLabel(cooperationNetwork: CooperationNetwork): string {
        if (cooperationNetwork.abbreviation) {
            return `${cooperationNetwork.abbreviation}, ${this.localeService.localize(cooperationNetwork.name)}`;
        }
        return this.localeService.localize(cooperationNetwork.name);
    }

    private getInfo(network: CooperationNetwork) {
        const validity = this.cooperationNetworkEntityService.getCooperationNetworkValidity(network);
        const validityTranslation = this.translateService.instant(`COOPERATION_NETWORK_VALIDITY.${validity}`);
        return `(${validityTranslation})`;
    }
}
