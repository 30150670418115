import { Component, Input, ViewEncapsulation } from '@angular/core';
import { CompletionMethod, OpenUniversityProduct } from 'common-typescript/types';

@Component({
    selector: 'app-product-basic-info',
    templateUrl: './product-basic-info.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ProductBasicInfoComponent {

    @Input() product: OpenUniversityProduct;
    @Input() completionMethod: CompletionMethod;
}
