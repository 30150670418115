<ng-container *ngIf="data$ | async as data">
  <ng-container
    *ngIf="{ courseUnitRealisation: data[0], enrolmentCalculationConfig: data[2], courseUnit: data[3], enrolmentQuestionnaire: data[4], assesmentItem: enrolment.assessmentItemId | assessmentItem | async } as result">
    <div class="modal-header">
      <div class="row">
        <h1 sisAutofocus
            tabindex="-1" aria-describedby="ssgs-full-notification">{{ (isUpdate ? 'ENROLMENTS.ENROLMENT_MODAL.TITLE_EDIT' : 'ENROLMENTS.ENROLMENT_MODAL.TITLE_CREATE') | translate }}
          {{ result.courseUnit.name | localizedString }}, {{ result.courseUnitRealisation.name | localizedString }} {{ result.courseUnitRealisation.activityPeriod | localDateRange }}</h1>
        <sis-description-list-compact>
          <dl>
            <ng-template #compactListItem>
              <dt class="label colon">{{ 'ENROLMENTS.ENROLMENT_MODAL.CUR_INFO.COURSE_UNIT' | translate }}</dt>
              <dd class="value">
                {{ result.courseUnit.code }}, {{ result.courseUnit.name | localizedString }}
                ({{ result.courseUnit.credits | creditRange }})
              </dd>
            </ng-template>
            <ng-template #compactListItem>
              <dt class="label colon">{{ 'ENROLMENTS.ENROLMENT_MODAL.CUR_INFO.VERSION' | translate }}</dt>
              <dd class="value">
                {{ result.courseUnit.curriculumPeriodIds | curriculumPeriodName | async }}
              </dd>
            </ng-template>
          </dl>
        </sis-description-list-compact>
        <button type="button"
                class="modal-close"
                (click)="close()"
                [attr.aria-label]="'ARIA_LABEL.CLOSE' | translate">
          <sis-icon icon="ring-close"></sis-icon>
        </button>
      </div>
      <div class="row">
      </div>
    </div>
    <div class="modal-body">
      <div id="ssgs-full-notification" aria-atomic="true" aria-live="assertive">
       <sis-contextual-notification *ngIf="requiredStudySubGroupsFull" [content]="'ENROLMENTS.ENROLMENT_MODAL.ERROR.STUDY_GROUP_FULL' | translate" variant="danger">
        </sis-contextual-notification>
      </div>
      <sis-validation-error-summary [validationErrorDetails]="validationDetails"
                                    [description]="'ENROLMENTS.ENROLMENT_MODAL.ERROR.SUMMARY_TITLE' | translate">
      </sis-validation-error-summary>

      <div class="enrolment-wizard__divider"  *ngIf="result.enrolmentCalculationConfig">
        <h3>{{ 'ENROLMENTS.ENROLMENT_MODAL.ENROLMENT_INFO_TITLE' | transloco}}</h3>
        <sis-course-unit-realisation-seats [enrolmentCalculationConfig]="result.enrolmentCalculationConfig"></sis-course-unit-realisation-seats>
        <ng-container *ngIf="result.enrolmentCalculationConfig?.requirementPersonRules?.length > 0">
          <h4>{{ 'ENROLMENTS.ENROLMENT_MODAL.CUR_REQUIREMENTS' | transloco | uppercase}}</h4>
          <ul>
            <li *ngFor="let rule of result.enrolmentCalculationConfig.requirementPersonRules">{{ rule | personRequirementRuleName | async }}</li>
          </ul>
        </ng-container>
      </div>

      <div *ngIf="result.enrolmentCalculationConfig?.orderingPersonRules?.length > 0" class="enrolment-wizard__divider">
        <fudis-link externalLink="/student/courseunit/{{result.courseUnit.id}}/completion-methods?courseUnitRealisation={{result.courseUnitRealisation.id}}&tabId=1" [title]="'ENROLMENTS.ENROLMENT_MODAL.SORTING_RULES_LINK' | translate"></fudis-link>
      </div>
      <div *ngIf="result.courseUnitRealisation?.learningEnvironments?.length > 0">
        <fudis-link externalLink="/student/courseunit/{{result.courseUnit.id}}/completion-methods?courseUnitRealisation={{result.courseUnitRealisation.id}}&tabId=0" [title]="'ENROLMENTS.ENROLMENT_MODAL.LEARNING_ENVIRONMENTS_LINK' | translate"></fudis-link>
      </div>
      <div *ngIf="isUpdate && isCrossStudy()">
        <fudis-link [externalLink]="'/student/ripa-enrolment-information'" [title]="'ENROLMENTS.ENROLMENT_MODAL.CROSS_STUDY.CONSENT_LINK' | translate"></fudis-link>
      </div>

      <div *ngIf="!isConfirmedSsgEdit" class="enrolment-wizard__divider">
        <app-study-right-select [control]="studyRightId"
                                [enrolmentCalculationConfig]="result.enrolmentCalculationConfig"
                                [enrolment]="enrolment">
        </app-study-right-select>
        <sis-contextual-notification *ngIf="invalidCrossStudyRight" [content]="'ENROLMENTS.ENROLMENT_MODAL.ERROR.INVALID_CROSS_STUDY_NETWORK' | translate" variant="danger" class="d-block mt-2">
        </sis-contextual-notification>

        <div *ngIf="enrolmentRightId?.enabled" class="row mt-5">
          <div class="col-12 col-md-8">
            <sis-select-enrolment-right-for-enrolment #enrolmentRightSelector
                                                      [assessmentItemId]="enrolment?.assessmentItemId"
                                                      [control]="enrolmentRightId"
                                                      [courseUnitId]="enrolment?.courseUnitId"
                                                      [courseUnitRealisationId]="enrolment?.courseUnitRealisationId"
                                                      [studyRightId]="studyRightId?.value">
            </sis-select-enrolment-right-for-enrolment>
            <sis-contextual-notification *ngIf="enrolmentRightSelector.options?.length === 0"
                                         variant="danger"
                                         class="d-block mt-2"
                                         data-cy="no-eligible-enrolment-rights-error">
              {{'ENROLMENTS.OPEN_UNIVERSITY_ENROLMENT.NO_ELIGIBLE_ENROLMENT_RIGHTS' | translate}}
            </sis-contextual-notification>
          </div>
        </div>
      </div>

        <div class="enrolment-wizard__divider">
          <h2>{{ 'ENROLMENTS.ENROLMENT_MODAL.CHOOSE_STUDY_GROUPS' | translate }}</h2>
          <div #realisationFullNotificationContainer>
            <sis-contextual-notification tabindex="-1" *ngIf="shouldDisplayRealisationFullWarning()" id="realisation-full-warning"  variant="danger">
              {{ 'ENROLMENTS.ENROLMENT_MODAL.REALISATION_FULL' | translate }}
            </sis-contextual-notification>
          </div>
          <app-study-group-container *ngFor="let studyGroupSet of result.courseUnitRealisation.studyGroupSets"
                                     [studyGroupSetInfo]="studyGroupSetsInfo.get(studyGroupSet.localId)"
                                     [enrolment]="enrolment"
                                     [isConfirmedSsgEdit]="isConfirmedSsgEdit"
                                     [showErrors]="showErrors"
                                     [form]="getStudyGroupSet(studyGroupSet.localId)">
          </app-study-group-container>
        </div>
      <ng-container *ngIf="result.enrolmentQuestionnaire?.enrolmentQuestions as enrolmentQuestions">
        <div *ngIf="enrolmentQuestions.length > 0" class="enrolment-wizard__divider">
          <h2> {{ 'ENROLMENTS.ENROLMENT_MODAL.ENROLMENT_QUESTIONNAIRE' | translate }} </h2>
          <p> {{ 'ENROLMENTS.ENROLMENT_MODAL.ENROLMENT_QUESTIONNAIRE_INFO' | translate }} </p>
          <div *ngFor="let question of enrolmentQuestions"
               class="enrolment-wizard__questionnaire-item">
            <ng-container *ngIf="question.questionType === 'LANGUAGE_SELECT'">
              <label *ngIf="getEnrolmentQuestion(question.localId) as control"
                     [id]="question.localId"
                     [ngClass]="getLabelState(control)"
                     data-cy="language-question-options">
                {{ 'ENROLMENT_QUESTIONAIRE_LANG_ANSWER_TITLE' | translate }}
                  <sis-radio-button *ngFor="let langSelect of languageOptions"
                    [label]="langSelect | codeLocalizedName | async"
                    [control]="control"
                    [value]="langSelect"
                    [name]="question.localId"
                  ></sis-radio-button>
                  <sis-validation-errors [control]="control"></sis-validation-errors>
              </label>
            </ng-container>
            <sis-text-field *ngIf="question.questionType === 'FREE_TEXT'"
                            [id]="question.localId"
                            type="text"
                            [label]="question.question | localizedString"
                            [control]="getEnrolmentQuestion(question.localId)"
                            [textarea]="true"
                            data-cy="question-field">
            </sis-text-field>
          </div>
        </div>
      </ng-container>
      <div class="enrolment-wizard__divider enrolment-wizard__consent" *ngIf="studentConsentForOutboundDataTransfer">
        <h2>{{ 'ENROLMENTS.ENROLMENT_MODAL.CROSS_STUDY.CONSENT_TITLE' | translate }}</h2>
        <sis-contextual-notification variant="danger" *ngIf="studentConsentForOutboundDataTransfer.invalid && showErrors" [content]="'ENROLMENTS.ENROLMENT_MODAL.ERROR.CONSENT_NOT_GIVEN' | translate"></sis-contextual-notification>
        <label class="required">
          {{'ENROLMENTS.ENROLMENT_MODAL.CROSS_STUDY.CONSENT_LABEL' | translate }}
          <sis-form-checkbox [id]="'consent-clause-input'" [name]="'consent-clause-input'" [control]="studentConsentForOutboundDataTransfer">
            <div class="sis-form-checkbox-label-content">
              {{'ENROLMENTS.ENROLMENT_MODAL.CROSS_STUDY.CONSENT_CLAUSE' | translate}} {{'ENROLMENTS.ENROLMENT_MODAL.CROSS_STUDY.CONSENT_ADDITIONAL_INFO' | translate}}
              <fudis-link [externalLink]="'/student/ripa-enrolment-information'" [title]="'ENROLMENTS.ENROLMENT_MODAL.CROSS_STUDY.CONSENT_ADDITIONAL_LINK' | translate"></fudis-link>
            </div>
          </sis-form-checkbox>
        </label>
      </div>
    </div>
    <div class="modal-footer">
      <sis-button (clicked)="close()" [hollow]="true">{{'SIS_COMPONENTS.BUTTON.CANCEL' | translate}}</sis-button>
      <sis-button (clicked)="confirm()"
                  [disabled]="requiredStudySubGroupsFull || shouldDisplayRealisationFullWarning() || crossStudySubmitButtonDisabled()"
                  type="submit">{{(isUpdate ? 'ENROLMENTS.ENROLMENT_MODAL.CONFIRM_EDIT_BUTTON' : 'SIS_COMPONENTS.BUTTON.CONFIRM') | translate}}</sis-button>
    </div>
  </ng-container>
</ng-container>
