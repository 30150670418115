<ng-container *transloco="let t">
  <sis-common-attainment-details [attainment]="attainment" [secondaryAttainments]="secondaryAttainments">
    <ng-template *ngIf="expirationImminent" #expirationNotification>
      <sis-attainment-expiration-notification [attainment]="attainment"></sis-attainment-expiration-notification>
    </ng-template>
    <ng-template *ngIf="attainment.rdiCredits" #rdiCredits>
      <div class="form-group-mimic" role="listitem">
        <dt>{{ t('ATTAINMENTS.ATTAINMENT_DETAILS.RDI_CREDITS') }}</dt>
        <dd>{{ attainment.rdiCredits }}</dd>
      </div>
    </ng-template>
    <ng-template #studyLevel>
      <div class="form-group-mimic" role="listitem">
        <dt>{{ t('ATTAINMENTS.ATTAINMENT_DETAILS.STUDY_LEVEL') }}</dt>
        <dd>{{ attainment.studyLevelUrn | codeLocalizedName | async}}</dd>
      </div>
    </ng-template>
    <ng-template #registrationDetails>
      <div class="form-group-mimic" role="listitem">
        <dt>{{ t('ATTAINMENTS.ATTAINMENT_DETAILS.REGISTRATION_DATE') }}</dt>
        <dd>{{ attainment.registrationDate | localDateFormat }}</dd>
      </div>
    </ng-template>
    <ng-template *ngIf="attainment?.creditTransferInfo" #creditTransferInformation>
      <sis-attainment-credit-transfer-info-details [creditTransferInfo]="attainment.creditTransferInfo">
      </sis-attainment-credit-transfer-info-details>
    </ng-template>
  </sis-common-attainment-details>
</ng-container>
