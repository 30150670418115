<ng-container *ngIf="assessmentItem">
  <h5 class="sis-mt-sm">{{assessmentItem.name | localizedString}} ({{assessmentItem.credits | creditRange:'SYMBOLS'}})</h5>
  <p *ngIf="!courseUnitRealisations?.length" class="sis-guidance">
    {{'OPEN_UNIVERSITY.STUDY_OFFERING.ASSESSMENT_ITEM.NO_CURS' | translate}}
  </p>
  <p *ngIf="courseUnitRealisations?.length > 1" class="sis-guidance">
    {{'OPEN_UNIVERSITY.STUDY_OFFERING.ASSESSMENT_ITEM.SEVERAL_CURS' | translate}}
  </p>
  <sis-expandable *ngFor="let courseUnitRealisation of courseUnitRealisations; trackBy: entityId"
                  (closedChange)="detectChanges()"
                  [title]="(courseUnitRealisation.name | localizedString) + ' ' + (courseUnitRealisation.activityPeriod  | localDateRange)"
                  [variant]="'naked-dotted'"
                  [level]="6">

    <ng-template sisExpandableCustomTitle *ngIf="curIsFull(courseUnitRealisation)">
       <sis-tiny-badge variant="danger">{{ 'OPEN_UNIVERSITY.STUDY_OFFERING.ASSESSMENT_ITEM.CUR_FULL' | translate }}</sis-tiny-badge>
    </ng-template>
    <ng-template sisExpandableContent>
      <app-course-unit-realisation-info [courseUnitRealisation]="courseUnitRealisation"></app-course-unit-realisation-info>
    </ng-template>
  </sis-expandable>
</ng-container>
