<div class="row" *ngIf="learningMaterial || literature?.length > 0">
  <div class="col-md-12">
    <sis-description-list>
      <dl> <!-- This dl element is just to trick SonarQube, it won't actually end up in the final HTML at all -->
        <ng-template #listItem *ngIf="learningMaterial">
          <dt class="label">{{'STUDIES.COURSE_UNIT_INFO_MODAL.LEARNING_MATERIAL' | translate}}</dt>
          <dd class="value">
            <sis-localized-markup-string-view [content]="learningMaterial"></sis-localized-markup-string-view>
          </dd>
        </ng-template>
        <ng-template #listItem *ngIf="literature?.length > 0">
          <dt class="label">{{'STUDIES.COURSE_UNITS_DESCRIPTION.LITERATURE' | translate}}</dt>
          <dd class="value">
            <ul>
              <li *ngFor="let item of literature">
                <span *ngIf="item.type === 'LiteratureName'">{{ item.name }}</span>
                <span *ngIf="item.type === 'LiteratureReference'">
                  <fudis-link [externalLink]="item.url"></fudis-link>
                </span>
              </li>
            </ul>
          </dd>
        </ng-template>
      </dl>
    </sis-description-list>
  </div>
</div>
<div *ngIf="!learningMaterial && literature?.length === 0">
  <span>{{ 'SIS_COMPONENTS.COURSE_UNIT_REALISATION_INFO.NO_INFORMATION_GIVEN' | translate }}</span>
</div>
