import { Injectable } from '@angular/core';
import {
    AssessmentItem,
    CourseUnit,
    LocalizedString,
} from 'common-typescript/types';

import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from '../types/angular-hybrid';

import { NameService } from './name.service';

@StaticMembers<DowngradedService>()
@Injectable({ providedIn: 'root' })
export class AssessmentItemNameService {

    static downgrade: ServiceDowngradeMappings = {
        moduleName: 'sis-common.name.assessment-item-name-service',
        serviceName: 'assessmentItemNameService',
    };

    constructor(private nameService: NameService) { }

    generateFullName(assessmentItem: AssessmentItem): LocalizedString {
        return this.nameService.generateFullName(assessmentItem);
    }

    nameInCUContext(assessmentItem: AssessmentItem, courseUnit: CourseUnit) {
        if (this.isPrimaryCourseUnit(assessmentItem, courseUnit) &&
            this.nameService.localizedStringAreEqual(assessmentItem.nameSpecifier, courseUnit.name ?? {})) {
            return assessmentItem.name;
        }
        return this.generateFullName(assessmentItem);
    }

    isPrimaryCourseUnit(assessmentItem: AssessmentItem, courseUnit: CourseUnit) {
        return assessmentItem?.primaryCourseUnitGroupId === courseUnit?.groupId;
    }
}
