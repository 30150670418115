import { UuidService } from 'sis-common/uuid/uuid.service.ts';
import { ModalService } from 'sis-common/modal/modal.service.ts';
import { commonPlanServiceModule } from 'sis-components/service/plan.service';
import { TimelineNoteModalComponent } from './line/note/timelineAddNoteModal-ng.component.ts';
angular.module('student.timing.timeLineNoteService', [commonPlanServiceModule, ModalService.downgrade.moduleName, UuidService.downgrade.moduleName, 'student.timing.note.timelineNoteModal']).factory('timeLineNoteService', ["$log", "modalService", "$q", "commonPlanService", "uuidService", function ($log, modalService, $q, commonPlanService, uuidService) {
  function noteModalOpen(note) {
    var originalNote = angular.copy(note);
    var modalInstance = modalService.open(TimelineNoteModalComponent, {
      note
    }, {
      size: 'sm'
    });
    return modalInstance.result.then(function () {
      return $q.when(note);
    }, function () {
      if (originalNote) {
        note.text = _.get(originalNote, 'text');
      }
      return $q.reject(note);
    });
  }
  function addLocalId(note) {
    note.localId = uuidService.randomUUID();
  }
  var api = {
    createTimeLineNote: function (period, plan) {
      var note = {
        text: '',
        notePeriods: [period.locator]
      };
      return noteModalOpen(note).then(function () {
        plan.timelineNotes.push(note);
        return commonPlanService.saveMyPlan(plan);
      }, function () {
        $log.debug('note modal cancelled');
      });
    },
    deleteTimeLineNote: function (note, plan) {
      _.pull(plan.timelineNotes, note);
      return commonPlanService.saveMyPlan(plan);
    },
    editTimeLineNote: function (note, plan) {
      return noteModalOpen(note).then(function () {
        return commonPlanService.saveMyPlan(plan);
      }, function () {
        $log.debug('note modal cancelled');
      });
    },
    addNoteToPeriod: function (note, period, plan) {
      note.notePeriods.push(period.locator);
      note.notePeriods = _.chain(note.notePeriods).uniq().orderBy().compact().value();
      return commonPlanService.saveMyPlan(plan);
    },
    moveNoteToPeriod: function (note, period, plan) {
      note.notePeriods.length = 0;
      return api.addNoteToPeriod(note, period, plan);
    },
    removeNoteFromPeriod: function (note, period, plan) {
      _.pull(note.notePeriods, period.locator);
      return commonPlanService.saveMyPlan(plan);
    },
    getTimeLineNotes: function (plan) {
      if (!_.has(plan, 'timelineNotes')) {
        plan.timelineNotes = [];
      }
      _.forEach(plan.timelineNotes, addLocalId);
      return plan.timelineNotes;
    }
  };
  return api;
}]);