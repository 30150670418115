import { Directive, HostListener, Input } from '@angular/core';

@Directive({ selector: '[sisScrollIntoViewOnPageChange]' })
export class ScrollIntoViewOnPageChangeDirective {
    @Input() sisScrollIntoViewOnPageChange: string;

    @HostListener('currentPageChange')
    scrollIntoView() {
        const scrollTarget = document.querySelector(this.sisScrollIntoViewOnPageChange);
        if (scrollTarget) {
            const bodyRect = document.body.getBoundingClientRect().top;
            const scrollTargetRect = scrollTarget.getBoundingClientRect().top;
            const scrollTargetPosition = scrollTargetRect - bodyRect;
            window.scrollTo({
                top: scrollTargetPosition,
            });
        }
    }

}

