<ng-container *transloco="let t">
    <fudis-dialog [size]="'sm'">
      <fudis-heading [level]="2" fudisDialogTitle>{{ t('ENROLMENT.CONTINUE_TO_ENROLMENT_MODAL.TITLE') }}</fudis-heading>
      <fudis-dialog-content>
        <fudis-grid [marginTop]="'md'" [marginBottom]="'md'">
          <fudis-body-text>{{ t('ENROLMENT.CONTINUE_TO_ENROLMENT_MODAL.GUIDANCE', { curName: courseUnitRealisation.name | localizedString, date: courseUnitRealisation.activityPeriod | localDateRange }) }} </fudis-body-text>
        </fudis-grid>
      </fudis-dialog-content>
      <fudis-dialog-actions>
        <fudis-button data-cy="cancel-continue-to-enrolment" fudisDialogClose [variant]="'secondary'" [label]="t('CANCEL')" (handleClick)="this.dialogService.close()" />
        <fudis-button (handleClick)="this.dialogService.close(true)" [label]="t('ENROLMENT.CONTINUE_TO_ENROLMENT_MODAL.TITLE') "></fudis-button>
      </fudis-dialog-actions>
    </fudis-dialog>
</ng-container>
