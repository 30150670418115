import { Injectable } from '@angular/core';
import { TranslationMarkupRendererFactory } from 'ngx-transloco-markup';

import { HtmlElementTranspiler } from '../common/html-element-transpiler';

export const SPAN_START = new (class SpanStart {})();
export const SPAN_END = new (class SpanEnd {})();

@Injectable()
export class SpanTranspiler extends HtmlElementTranspiler {
    constructor(private readonly rendererFactory: TranslationMarkupRendererFactory) {
        super({
            rendererFactory,
            start: {
                search: '<span>',
                token: SPAN_START,
            },
            end: {
                search: '</span>',
                token: SPAN_END,
            },
            elementTag: 'span',
        });
    }
}
