import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

export enum ListShowState {
    ALWAYS_SHOWING_ALL,
    SHOWING_LESS,
    SHOWING_MORE,
}

/**
 * A component that shows a list of texts and initially truncates the list if it has more items than {@link INITIALLY_SHOWN_LIST_ITEMS_MAX_COUNT}.
 * The user can click a "show more" link to show all the items, and then click a "show less" link to go back to the condensed view.
 */
@Component({
    selector: 'sis-show-more-list',
    templateUrl: './show-more-list.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ShowMoreListComponent implements OnInit {
    ListShowState = ListShowState; // Makes enum accessible in template

    /** List description text, shown in a paragraph before the list. */
    @Input() description: string;
    /** List items. */
    @Input() items: string[];
    /** Optional translation key for the text to show when the list is initially truncated and the user has clicked the button to show all items.
        If not given, a generic "showing all" text is shown. */
    @Input() showingAllItemsText?: string;

    readonly INITIALLY_SHOWN_LIST_ITEMS_MAX_COUNT = 20;
    listShowState = ListShowState.ALWAYS_SHOWING_ALL;

    ngOnInit(): void {
        this.listShowState = this.items.length < this.INITIALLY_SHOWN_LIST_ITEMS_MAX_COUNT ?
            ListShowState.ALWAYS_SHOWING_ALL :
            ListShowState.SHOWING_LESS;
    }

    toggleListShowState(): void {
        this.listShowState = this.listShowState === ListShowState.SHOWING_LESS ? ListShowState.SHOWING_MORE : ListShowState.SHOWING_LESS;
    }
}
