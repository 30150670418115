"use strict";

(function () {
  angular.module('student.plan').factory('createPlanService', createPlanService);
  function createPlanService() {
    return {
      getMatchingStudyRight: function (education, studyRights) {
        return _.find(studyRights, function (studyRight) {
          if (studyRight.educationId === education.id) {
            var studyRightEndDate = getStudyRightEndDate(studyRight);
            if (studyRightEndDate && moment().isSameOrAfter(studyRightEndDate, 'days')) {
              return false;
            }
            return true;
          }
          return false;
        });
      },
      getSelectableCurriculumPeriods: function (studyRight, curriculumPeriods) {
        var startMoment, endMoment;
        if (studyRight) {
          startMoment = moment(getStudyRightStartDate(studyRight));
        } else {
          startMoment = moment();
        }
        endMoment = moment().add(6, 'months');
        return _.filter(curriculumPeriods, function (curriculumPeriod) {
          var curriculumPeriodStartMoment = moment(curriculumPeriod.activePeriod.startDate);
          var curriculumPeriodEndMoment = moment(curriculumPeriod.activePeriod.endDate);
          return startMoment.isBetween(curriculumPeriodStartMoment, curriculumPeriodEndMoment, 'days', '[)') || curriculumPeriodStartMoment.isBetween(startMoment, endMoment, 'days', '[)');
        });
      },
      getDefaultCurriculumPeriod: function (studyRight, curriculumPeriods) {
        var referenceMoment;
        var now = moment();
        var studyRightBasedMoment = !!studyRight ? moment(getStudyRightStartDate(studyRight)) : moment();
        if (now.isBefore(studyRightBasedMoment)) {
          referenceMoment = studyRightBasedMoment;
        } else {
          referenceMoment = now;
        }
        var defaultCurriculumPeriod = _.find(curriculumPeriods, function (cp) {
          return referenceMoment.isBetween(cp.activePeriod.startDate, cp.activePeriod.endDate, 'days', '[)');
        });
        if (defaultCurriculumPeriod) {
          return defaultCurriculumPeriod;
        } else {
          var lastPast = _.last(_.filter(curriculumPeriods, function (cp) {
            return referenceMoment.isAfter(cp.activePeriod.endDate);
          }));
          return lastPast ? lastPast : _.first(curriculumPeriods);
        }
      }
    };
    function getStudyRightStartDate(studyRight) {
      return studyRight.valid.startDate;
    }
    function getStudyRightEndDate(studyRight) {
      if (studyRight.transferOutDate && moment(studyRight.transferOutDate).isBefore(studyRight.valid.endDate, 'days')) {
        return studyRight.transferOutDate;
      } else {
        return studyRight.valid.endDate;
      }
    }
  }
})();