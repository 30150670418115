import { Component, Input, ViewEncapsulation } from '@angular/core';
import { PrivatePerson } from 'common-typescript/types';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-person-info-for-graduation',
    templateUrl: './person-info-for-graduation.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class PersonInfoForGraduationComponent {
    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.person.personInfoForGraduation',
        directiveName: 'sisPersonInfoForGraduation',
    };

    @Input() person: PrivatePerson;
}
