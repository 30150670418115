import { Injectable } from '@angular/core';
import { OtmId, StudyPeriodLocator } from 'common-typescript/types';

@Injectable({ providedIn: 'root' })
export class StudyPeriodLocatorService {
    private _locatorPattern: RegExp | undefined;

    /**
     * Parses the given locator. Returns null if the locator could not be parsed.
     */
    parseStudyPeriodLocator(locator: StudyPeriodLocator | null | undefined): ParsedStudyPeriodLocator | null {
        if (!locator || typeof locator !== 'string') {
            return null;
        }

        if (!this._locatorPattern) {
            this._locatorPattern = /^([^/]+)\/(\d{4})\/(\d)\/(\d)$/;
        }

        const match: RegExpMatchArray | null = locator.match(this._locatorPattern);

        return match
            ? <ParsedStudyPeriodLocator>{
                organisationId: match[1],
                year: parseInt(match[2], 10),
                termIndex: parseInt(match[3], 10),
                periodIndex: parseInt(match[4], 10),
            }
            : null;
    }

    /**
     * Tells if {@link locator} occurs before {@link reference}.
     */
    isStudyPeriodEarlier(locator: ParsedStudyPeriodLocator, reference: ParsedStudyPeriodLocator): boolean {
        if (locator.year < reference.year) {
            return true;
        }
        if (locator.year > reference.year) {
            return false;
        }

        // years match --> compare terms
        if (locator.termIndex < reference.termIndex) {
            return true;
        }
        if (locator.termIndex > reference.termIndex) {
            return false;
        }

        // terms match --> compare periods
        return locator.periodIndex < reference.periodIndex;
    }
}

export interface ParsedStudyPeriodLocator {
    readonly organisationId: OtmId;
    readonly year: number;
    readonly termIndex: number;
    readonly periodIndex: number;
}
