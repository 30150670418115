literatureFilter.$inject = ["$translate"];
import angular from 'angular';
import angularTranslate from 'angular-translate';
export const literatureFilterModule = 'sis-components.literature.literatureFilter';
angular.module(literatureFilterModule, [angularTranslate]).filter('literature', literatureFilter);

/**
 * @ngInject
 */
function literatureFilter($translate) {
  return function (input) {
    let retval = '';
    if (input) {
      switch (input.type) {
        case 'LiteratureName':
          retval += input.name;
          break;
        case 'LiteratureReference':
          {
            const textForScreenReaders = $translate.instant('ARIA_LABEL.OPENS_TO_A_NEW_TAB');
            retval += `<a href='${input.url}' target='_blank'><i class="SISUicon-External" aria-hidden="true"></i>${input.url}<span class="visually-hidden">${textForScreenReaders}</span></a>`;
            break;
          }
        default:
          break;
      }
    }
    return retval;
  };
}