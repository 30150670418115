import angular from 'angular';
import angularTranslate from 'angular-translate';
import { OrganisationRoleShare, Urn } from 'common-typescript/types';
import * as _ from 'lodash';
// @ts-ignore (implicit 'any' type)
import { localizedStringFilterModule } from 'sis-common/l10n/localizedStringFilter';

// @ts-ignore (implicit 'any' type)
import { localDateRangeFilterModule } from '../date/filters/localDateRange/localDateRange.filter';

import {
    OrganisationRoleShareValidityGroup,
    OrganisationRoleShareValidityGroupService,
} from './organisation-role-share-validity-group.service';
// @ts-ignore ('cannot find module' for HTML template)
import organisationRoleShareEditorComponentHtml from './organisationRoleShareEditor.component.html';
import { organisationRoleShareValidityGroupEditorModule } from './organisationRoleShareValidityGroupEditor.component';

export const organisationRoleShareEditorModule = 'sis-components.organisation.organisationRoleShareEditor';
class OrganisationRoleShareEditor {
    private organisationRoleShareValidityGroupFactory = new OrganisationRoleShareValidityGroupService();
    private coordinatingOrganisationsAdded: boolean;
    private formName: string;
    private organisations: OrganisationRoleShare[];
    private emptyOrganisationsAllowed: boolean;
    private organisationService: Function;
    private organisationRoleUrn: Urn;
    private validityGroups: OrganisationRoleShareValidityGroup[];
    private valueChange?: Function;
    private formsValid: { [key: string]: boolean };

    // If you change the constructor parameters, remember to update the "OrganisationRoleShareEditor.$inject = ..." line below too.
    constructor(
        private $timeout: any,
    ) {
        this.coordinatingOrganisationsAdded = false;
    }

    $onInit(): void {
        this.validityGroups = this.organisationRoleShareValidityGroupFactory
            .build(_.filter(this.organisations, { roleUrn: this.organisationRoleUrn }));

        if (this.validityGroups.length === 0) {
            this.validityGroups = [{
                validityPeriod: { startDate: null, endDate: null },
                organisationRoleShares: [],
                disabledOrganisationIds: [],
            }];
        }

        if (_.isNil(this.formName)) {
            throw new Error('organisationRoleShareEditor component: formName attribute is required');
        }

        if (_.isNil(this.organisationRoleUrn)) {
            throw new Error('organisationRoleShareEditor component: organisationRoleUrn attribute is required');
        }

        if (_.isEmpty(this.organisationService)) {
            throw new Error('organisationRoleShareEditor component: organisationService attribute is required');
        }
    }

    remove(index: number) {
        if (this.validityGroups.length > 1) {
            this.validityGroups.splice(index, 1);
            this.validityGroups = _.cloneDeep(this.validityGroups);
            this.handleChange();
        }
    }

    addNew(index: number) {
        this.validityGroups.splice(index, 0, {
            validityPeriod: { startDate: null, endDate: null },
            organisationRoleShares: [],
            disabledOrganisationIds: [],
        });
        this.validityGroups = _.cloneDeep(this.validityGroups);
        this.handleChange();
    }

    handleChange() {
        this.$timeout(() => {
            this.organisations = _.concat(
                _.filter(this.organisations, org => org.roleUrn !== this.organisationRoleUrn),
                this.getOrganisationRoleShares(),
            );
            if (this.valueChange) {
                const valid = _.every(_.keys(this.formsValid), key => this.formsValid[key]);
                this.valueChange({ valid, organisations: this.organisations });
            }
        });
    }

    getOrganisationRoleShares() {
        return _.reduce(
            this.validityGroups,
            (organisations, validityGroup) => organisations.concat(validityGroup.organisationRoleShares),
            []);
    }

    isCoordinatingOrganisationUrn() {
        return this.organisationRoleUrn === 'urn:code:organisation-role:coordinating-organisation';
    }

    hasCoordinatingOrganisations() {
        return _.some(this.organisations, { roleUrn: 'urn:code:organisation-role:coordinating-organisation' });
    }

    addCoordinatingOrganisations() {
        this.coordinatingOrganisationsAdded = true;
    }

    showOrganisations() {
        return !this.isCoordinatingOrganisationUrn() ||
            this.hasCoordinatingOrganisations() ||
            this.coordinatingOrganisationsAdded;
    }
}
OrganisationRoleShareEditor.$inject = ['$timeout'];

angular.module(organisationRoleShareEditorModule, [
    angularTranslate,
    localizedStringFilterModule,
    localDateRangeFilterModule,
    'sis-components.date.localDateRangeEditor',
    organisationRoleShareValidityGroupEditorModule,
]).component('organisationRoleShareEditor', {
    template: organisationRoleShareEditorComponentHtml,
    controller: OrganisationRoleShareEditor,
    controllerAs: 'ctrl',
    bindings: {
        formName: '@',
        organisationRoleUrn: '<',
        organisationService: '<',
        emptyOrganisationsAllowed: '<',
        organisations: '=',
        valueChange: '&?',
    },
});
