'use strict';

(function () {
  planRange.$inject = ["translateRange"];
  angular.module('sis-components.planRange', ['sis-components.translateRange']).directive('planRange', planRange);
  function planRange(translateRange) {
    return {
      restrict: 'E',
      scope: {
        credits: '='
      },
      template: '<span translate="{{result.t}}" translate-values="{{result.values}}"></span>',
      link: linkRange
    };
    function linkRange(scope) {
      scope.$watch('credits', function (changed) {
        scope.result = translateRange(changed);
      });
    }
  }
})();