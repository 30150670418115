import * as _ from 'lodash';

import { ApprovalRequiredState, ModuleContentApprovalState, PlanValidationState } from '../../../types';

import { createRootRule, isStudyRightPersonalized } from './moduleRootRuleCreator';
import { ModuleValidation } from './moduleValidation';
import { PlanValidationResult } from './planValidationResult';
import { ValidatablePlan } from './validatablePlan';

export class PlanValidationTs {

    static validatePlan(validatablePlan: ValidatablePlan): PlanValidationResult {

        const planValidationResult = new PlanValidationResult();
        planValidationResult.validatablePlan = validatablePlan;

        const ruleContext = ModuleValidation.validateModule(
            validatablePlan.rootModule,
            validatablePlan,
            planValidationResult,
            PlanValidationTs.resolveCustomRuleIfExists(validatablePlan),
        );
        const validationResult = ruleContext.getResults();

        validationResult.validatablePlan = validatablePlan;

        planValidationResult.rootValidationResult = validationResult;

        return planValidationResult;
    }

    private static resolveCustomRuleIfExists(validatablePlan: ValidatablePlan) {
        return validatablePlan.studyRight && isStudyRightPersonalized(validatablePlan.studyRight) ?
            createRootRule(validatablePlan.rootModule, validatablePlan.studyRight) : undefined;
    }

    // getPlanStateForModule should eventually not be in this class, it is included here only for backward compatibility
    // the function also returns values from two different enums, so it does not seem logically solid either
    // the function should only be used to get a displayable state for the UI

    static getPlanStateForModule(module: any, validatablePlan: ValidatablePlan, planValidationResult: PlanValidationResult, useContextualState: boolean = false) {
        if (!module) {
            return null;
        }
        if (validatablePlan.isModuleAttained(module.id) || _.get(module, 'type') === 'CustomModuleAttainment') {
            return 'ATTAINED';
        }
        if (!planValidationResult) {
            return null;
        }
        const results = _.get(planValidationResult.moduleValidationResults, module.id);

        if (!results) {
            return null;
        }

        if (results.approvalRequiredState === ApprovalRequiredState.APPROVAL_REQUIRED) {
            return ApprovalRequiredState.APPROVAL_REQUIRED;
        }
        const matchingModuleContentApproval = _.get(planValidationResult.matchingModuleContentApprovalsByModuleId, module.id);
        if (results.moduleContentApprovalValidationState === ModuleContentApprovalState.VALID) {
            if (results.validationStateOfChildren === PlanValidationState.PARTS_ATTAINED) {
                switch (matchingModuleContentApproval?.state) {
                    case 'ACCEPTED':
                        return PlanValidationState.APPROVED_PARTS_ATTAINED;
                    case 'REQUESTED':
                        return PlanValidationState.APPROVAL_REQUESTED_PARTS_ATTAINED;
                    case 'CONDITIONAL':
                        return PlanValidationState.APPROVED_CONDITIONALLY_PARTS_ATTAINED;
                    default:
                        return matchingModuleContentApproval?.state;
                }
            }
            switch (matchingModuleContentApproval?.state) {
                case 'ACCEPTED':
                    return PlanValidationState.APPROVED;
                case 'REQUESTED':
                    return PlanValidationState.APPROVAL_REQUESTED;
                case 'CONDITIONAL':
                    return PlanValidationState.APPROVED_CONDITIONALLY;
                default:
                    return matchingModuleContentApproval?.state;
            }

        }

        if (results.moduleContentApprovalValidationState === ModuleContentApprovalState.INVALID) {
            const moduleValidationResult = _.get(planValidationResult.moduleValidationResults, module.id);
            return moduleValidationResult.isModuleContentApproved ?
                'MODULECONTENTAPPROVAL_VALIDATION_STATE_INVALID_ACCEPTED' :
                'MODULECONTENTAPPROVAL_VALIDATION_STATE_INVALID_REQUESTED';
        }

        return useContextualState ? results.contextualState : results.state;
    }

    static getContextualStateForModule(module: any, validatablePlan: ValidatablePlan, planValidationResult: PlanValidationResult) {
        return this.getPlanStateForModule(module, validatablePlan, planValidationResult, true);
    }

}
