import {
    ChangeDetectionStrategy,
    Component,
    ContentChildren,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    signal,
    ViewChildren,
    ViewEncapsulation,
} from '@angular/core';
import { take } from 'rxjs';

import { CollapsibleListContentDirective } from './collapsible-list-content.directive';

@Component({
    selector: 'app-collapsible-list',
    templateUrl: './collapsible-list.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollapsibleListComponent implements OnInit {

    @ViewChildren('listItem') listItems: QueryList<ElementRef<HTMLLIElement>>;
    @ContentChildren(CollapsibleListContentDirective) listContents: QueryList<CollapsibleListContentDirective>;

    @Input({ required: true }) limit: number;
    @Input() initiallyCollapsed = true;

    @Output() collapsedChange = new EventEmitter<boolean>();

    readonly collapsed = signal(true);

    ngOnInit(): void {
        this.collapsed.set(this.initiallyCollapsed ?? true);
    }

    toggleCollapsed() {
        this.collapsed.set(!this.collapsed());
        this.collapsedChange.emit(this.collapsed());
        if (!this.collapsed()) {
            // Focus the first item in the revealed section of the list when expanding.
            // The QueryList is updated with a delay, after the DOM has been updated.
            this.listItems.changes
                .pipe(take(1))
                .subscribe((list: QueryList<ElementRef<HTMLLIElement>>) => list.get(this.limit)?.nativeElement?.focus());
        }
    }
}
