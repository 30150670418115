import { Component, ContentChild, Input, TemplateRef, ViewEncapsulation } from '@angular/core';
import { AssessmentItemAttainment,
    Attainment,
    CourseUnitAttainment,
    CustomCourseUnitAttainment,
    CustomModuleAttainment,
    DegreeProgrammeAttainment,
    ModuleAttainment } from 'common-typescript/types';

@Component({
    selector: 'sis-common-attainment-details',
    templateUrl: './common-attainment-details.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CommonAttainmentDetailsComponent {
    @ContentChild('educationClassification', { read: TemplateRef, descendants: true }) educationClassification: TemplateRef<any>;
    @ContentChild('acceptorOrganisations', { read: TemplateRef, descendants: true }) acceptorOrganisations: TemplateRef<any>;
    @ContentChild('curriculumPeriods', { read: TemplateRef, descendants: true }) curriculumPeriods: TemplateRef<any>;
    @ContentChild('registrationDetails', { read: TemplateRef, descendants: true }) registrationDetails: TemplateRef<any>;
    @ContentChild('expirationNotification', { read: TemplateRef, descendants: true }) expirationNotification: TemplateRef<any>;
    @ContentChild('thesisInformation', { read: TemplateRef, descendants: true }) thesisInformation: TemplateRef<any>;
    @ContentChild('rdiCredits', { read: TemplateRef, descendants: true }) rdiCredits: TemplateRef<any>;
    @ContentChild('studyLevel', { read: TemplateRef, descendants: true }) studyLevel: TemplateRef<any>;
    @ContentChild('creditTransferInformation', { read: TemplateRef, descendants: true }) creditTransferInformation: TemplateRef<any>;
    @ContentChild('cooperationNetworkStatus', { read: TemplateRef, descendants: true }) cooperationNetworkStatus: TemplateRef<any>;
    @ContentChild('assessmentDetails', { read: TemplateRef, descendants: true }) assessmentDetails: TemplateRef<any>;
    @ContentChild('administrativeNote', { read: TemplateRef, descendants: true }) administrativeNote: TemplateRef<any>;
    @ContentChild('verifierAndRegistrationDate', { read: TemplateRef, descendants: true }) verifierAndRegistrationDate: TemplateRef<any>;

    @Input() attainment: DegreeProgrammeAttainment
    | ModuleAttainment
    | CustomModuleAttainment
    | AssessmentItemAttainment
    | CourseUnitAttainment
    | CustomCourseUnitAttainment;

    @Input() secondaryAttainments: Attainment[];
}
