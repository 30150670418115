<div class="modal-header" data-cy="workflow-cancellation-info-modal">
  <h2 tabindex="0" class="modal-title" translate>{{values.title}}</h2>
  <sis-button [hollow]="true"
              [naked]="true"
              [variant]="'secondary'"
              (clicked)="modalInstance.close()"
              [attr.aria-label]="'ARIA_LABEL.CLOSE' | translate">
    <sis-icon [icon]="'ring-close'"></sis-icon>
  </sis-button>
</div>
<div class="modal-body">
  <p> {{ values.description }}</p>
  <sis-description-list>
    <dl>
      <ng-template #listItem>
        <dt>{{'PROFILE.APPLICATIONS.APPLICATION_CANCELLATION_DATE' | translate}}</dt>
        <dd>
          {{ values.cancelTime | localDateFormat }}
        </dd>
      </ng-template>
      <ng-template #listItem>
        <dt>{{'PROFILE.APPLICATIONS.APPLICATION_CANCELLATION_REASON' | translate}}</dt>
        <dd>
          {{ values.cancelReason }}
        </dd>
      </ng-template>
    </dl>
  </sis-description-list>
</div>
