import { AfterViewInit, Component, EventEmitter, Injector, Output } from '@angular/core';

import { InjectorService } from '../injector/injector.service';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from '../types/angular-hybrid';

/**
 * An Angular component that initiates (lazy) Angular app creation as soon as possible, so that its downgraded services can
 * be used by the AngularJS controllers that require them. Adapted from https://stackoverflow.com/a/51175142/305436.
 */
@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-service-bootstrap',
    template: '',
})
export class ServiceBootstrapComponent implements AfterViewInit {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'serviceBootstrap',
        directiveName: 'serviceBootstrap',
    };

    constructor(private injectorService: InjectorService, injector: Injector) {
        injectorService.setRootInjector(injector);
    }

    @Output()
    public initialized: EventEmitter<void> = new EventEmitter();

    public ngAfterViewInit() {
        this.initialized.emit();
    }
}
