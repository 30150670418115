import { Attainment } from 'common-typescript/types';
import { Observable } from 'rxjs';

/**
 * When entering a single-student context, a component can provide an implementation of this class to its subcomponents (and their subcomponents etc...)
 * in order to enable attainment data sharing in the subtree.
 *
 * Implementations must implement shared RxJS streams.
 */
export abstract class StudentContextAttainmentService {
    /**
     * Emits attainments of the current student, that
     * a) are themselves valid
     * or
     * b) are child/descendant attainments of other valid attainments,
     * not in any specific order.
     */
    abstract get validAttainments$(): Observable<readonly Attainment[]>;
}
