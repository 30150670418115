import { Component, Input, ViewEncapsulation } from '@angular/core';
import { FullCalendarStudyEvent, ValidatablePlan } from 'common-typescript/types';
import { ModalService } from 'sis-common/modal/modal.service';

import { FullCalendarViewType } from '../full-calendar.types';

import { StudyEventSummaryModalComponent } from './study-event-preview/study-event-summary-modal.component';

@Component({
    selector: 'app-study-event',
    templateUrl: './study-event.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class StudyEventComponent {

    @Input() viewType: FullCalendarViewType.TIME_GRID_WEEK | FullCalendarViewType.DAY_GRID_MONTH;
    @Input() event: FullCalendarStudyEvent;
    @Input() getValidatablePlan: () => Promise<ValidatablePlan>;

    constructor(private modalService: ModalService) {}

    openStudyEventPreviewModal(): void {
        this.modalService.open(StudyEventSummaryModalComponent, { event: this.event, getValidatablePlan: this.getValidatablePlan });
    }
}
