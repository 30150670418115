<ng-container *ngIf="enrolment && { courseUnit: courseUnit$ | async, courseUnitRealisation: courseUnitRealisation$ | async, assessmentItem: assessmentItem$ | async, enrolmentCalculationConfig: enrolmentCalculationConfig$ | async } as data">
  <ng-container *ngIf="data.courseUnit && data.courseUnitRealisation && data.assessmentItem">
    <sis-study-realisation-box *ngIf="!isCurCancelled()"
                               [level]="4"
                               [name]="data.courseUnit.name"
                               [code]="data.courseUnit.code"
                               [realisationName]="data.courseUnitRealisation.name"
                               [credits]="data.assessmentItem.credits"
                               [activityPeriod]="data.courseUnitRealisation.activityPeriod"
                               [action]="getAction(enrolment)"
                               (actionChange)="enrolmentRemoveAction.emit()"
                               (nameClickAction)="openCourseUnitInfoModal(data.courseUnit, data.courseUnitRealisation)">
      <ng-container study-box=notifications>
        <ng-container>
          <sis-study-notification-box [type]="'none'">
            <sis-button (clicked)="openEnrolmentDialog()" [link]="true">{{ 'ENROLMENTS.OPEN_ENROLMENT_DIALOG' | translate }}</sis-button>
          </sis-study-notification-box>
          <app-enrolment-state-notification-box
            *ngIf="!crossStudyInvalidState()"
            [enrolment]="enrolment"
            (primaryClickAction)="emitPrimaryClickAction()"
            [primaryButtonText]="getTimingInfoToShow() ? null : getPrimaryButtonTextAndAction()?.text"
            (secondaryClickAction)="emitSecondaryClickAction()"
            [secondaryButtonText]="getTimingInfoToShow() ? null : getSecondaryButtonTextAndAction()?.text"
          />

          <!-- Cross study information -->
          <ng-container *ngIf="isCrossStudy()">
            <sis-study-notification-box *ngIf="enrolment.state === 'PROCESSING' && ['NOT_PROCESSED', 'PENDING'].includes(enrolment.processingState)" type="waiting">
              {{ 'ENROLMENTS.CROSS_STUDY.PROCESSING' | translate }}
            </sis-study-notification-box>
            <sis-study-notification-box *ngIf="crossStudyInvalidState()" type="alert">
              {{ 'ENROLMENTS.CROSS_STUDY.INVALID' | translate }}
            </sis-study-notification-box>
            <sis-study-notification-box type="info">
              {{ 'ENROLMENTS.CROSS_STUDY.INFO' | translate }} {{ courseUnitRealisation.universityOrgIds[0] | organisation | async | entityName | localizedString }}
            </sis-study-notification-box>
            <sis-study-notification-box *ngIf="enrolment.state === 'NOT_ENROLLED' && invalidCrossStudyNetwork()" type="info">
              {{ 'ENROLMENTS.CROSS_STUDY.NO_NETWORKS' | translate }}
            </sis-study-notification-box>
          </ng-container>

        </ng-container>

        <!-- Timing info -->
        <ng-container *ngIf="getTimingInfoToShow()">
          <sis-study-notification-box
            type="info"
            (primaryClickAction)="emitPrimaryClickAction()"
            [primaryButtonText]="getPrimaryButtonTextAndAction()?.text"
            (secondaryClickAction)="emitSecondaryClickAction()"
            [secondaryButtonText]="getSecondaryButtonTextAndAction()?.text"
          >
            <!-- Enrolment timing info -->
            <app-enrolment-period-info
              *ngIf="getTimingInfoToShow() === 'enroll'"
              [courseUnitRealisation]="data.courseUnitRealisation"
            />

            <!-- Update groups timing info -->
            <ng-container *ngIf="getTimingInfoToShow() === 'update'">
              {{ 'ENROLMENTS.EDIT_TIMING' | translate: {
                date: getUpdateTiming() | localDateFormat,
                time: getUpdateTiming() | localTimeFormat
              } }}
            </ng-container>

            <!-- Cancellation timing info -->
            <ng-container *ngIf="getTimingInfoToShow() === 'cancel'">
              {{ 'ENROLMENTS.CANCELLATION_TIMING' | translate: {
                date: getCancelTiming() | localDateFormat,
                time: getCancelTiming() | localTimeFormat
              } }}
            </ng-container>

            <!-- Abort timing info -->
            <ng-container *ngIf="getTimingInfoToShow() === 'abort'">
              {{ 'ENROLMENTS.ABORT_TIMING' | translate: {
                date: getAbortTiming() | localDateFormat,
                time: getAbortTiming() | localTimeFormat
              } }}
            </ng-container>

          </sis-study-notification-box>
        </ng-container>

        <!-- External enrolments -->
        <sis-study-notification-box *ngIf="usesExternalEnrolment()" type="info">
          <ng-container *ngIf="hasExternalEnrolmentLink()">
            <ng-container *ngIf="enrolment.state === 'NOT_ENROLLED'">
              {{ 'ENROLMENT.USE_EXTERNAL_ENROLMENT' | translate }}
            </ng-container>
            <ng-container *ngIf="enrolment.state !== 'NOT_ENROLLED'">
              {{ 'ENROLMENT.EXTERNAL_ENROLMENT_USED' | translate }}
            </ng-container>
            <a class="external-link" [href]="data?.courseUnitRealisation?.externalEnrolmentLink?.url | localizedString"
               target="_blank" rel="noopener noreferrer">
              <ng-container *ngIf="hasExternalEnrolmentLinkLabel()">
                {{ data?.courseUnitRealisation?.externalEnrolmentLink?.label | localizedString }}
              </ng-container>
              <ng-container *ngIf="!hasExternalEnrolmentLinkLabel()">
                {{ 'ENROLMENT.EXTERNAL_ENROLMENT_LINK' | translate }}
              </ng-container>
              <span class="visually-hidden" translate>ARIA_LABEL.OPENS_TO_A_NEW_TAB</span>
            </a>
          </ng-container>
          <ng-container *ngIf="!hasExternalEnrolmentLink()">
            {{ 'ENROLMENT.MISSING_EXTERNAL_ENROLMENT_LINK' | translate }}
          </ng-container>
        </sis-study-notification-box>
      </ng-container>
    </sis-study-realisation-box>

    <!-- Cancelled course unit realisations -->
    <sis-study-realisation-box *ngIf="isCurCancelled()"
                               [level]="4"
                               [name]="data.courseUnit.name"
                               [code]="data.courseUnit.code"
                               [realisationName]="data.courseUnitRealisation.name"
                               [credits]="data.assessmentItem.credits"
                               [activityPeriod]="data.courseUnitRealisation.activityPeriod"
                               [action]="'remove-selection'"
                               [showCancelledBadge]="true"
                               (actionChange)="enrolmentRemoveAction.emit()"
                               (nameClickAction)="openCourseUnitInfoModal(data.courseUnit, data.courseUnitRealisation)">
      <ng-container study-box=notifications>
        <sis-study-notification-box>
          {{ 'ENROLMENTS.CUR_CANCELLED' | translate  }}
        </sis-study-notification-box>
      </ng-container>
    </sis-study-realisation-box>
  </ng-container>
</ng-container>
