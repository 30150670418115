import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { CourseUnitRealisation, LocalDateTimeRange, LocalDateTimeString } from 'common-typescript/types';

@Component({
    selector: 'app-enrolment-period-badge',
    templateUrl: './enrolment-period-badge.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnrolmentPeriodBadgeComponent {

    @Input() courseUnitRealisation: CourseUnitRealisation;

    get enrolmentPeriod(): LocalDateTimeRange | null {
        return this.courseUnitRealisation?.enrolmentPeriod ?? null;
    }

    get lateEnrolmentEnd(): LocalDateTimeString | null {
        return this.courseUnitRealisation?.lateEnrolmentEnd ?? null;
    }
}
