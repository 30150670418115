export const termRegistrationPeriodJSDataModelModule = 'sis-components.model.termRegistrationPeriod';
(function () {
  termRegistrationPeriodJSDataModel.$inject = ["DS", "TermRegistrationPeriodEndpoint"];
  angular.module(termRegistrationPeriodJSDataModelModule, ['js-data', 'sis-components.model.organisation']).factory('termRegistrationPeriodJSDataModel', termRegistrationPeriodJSDataModel).constant('TermRegistrationPeriodEndpoint', {
    DEFAULT: '/ori/api/term-registration-periods'
  });

  /**
   * @ngInject
   */
  function termRegistrationPeriodJSDataModel(DS, TermRegistrationPeriodEndpoint) {
    return DS.defineResource({
      idAttribute: 'id',
      name: 'termRegistrationPeriod',
      endpoint: TermRegistrationPeriodEndpoint.DEFAULT,
      relations: {
        hasOne: {
          organisation: {
            localField: 'university',
            localKey: 'universityOrgId'
          }
        }
      }
    });
  }
})();