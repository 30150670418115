import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { HttpConfig, NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { ISO_LOCAL_DATE_TIME_FORMAT } from 'common-typescript/constants';
import { AssessmentItem, AssessmentItemAtDatetime, CourseUnit, CourseUnitRealisation, LocalDateTimeString, OpenUniversityProduct, OtmId } from 'common-typescript/types';
import { sortBy } from 'lodash';
import moment from 'moment';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { CourseUnitEntityService } from './course-unit-entity.service';
import { EntityService } from './entity.service';
import { SisuDataLoader } from './SisuDataLoader';

const CONFIG = {
    ENDPOINTS: {
        backend: '/kori/api',
        snapshotsAtDates: '/kori/api/assessment-items/snapshots-at-dates',
    },
};

@Injectable({
    providedIn: 'root',
})
@NgEntityServiceConfig({
    baseUrl: CONFIG.ENDPOINTS.backend,
    resourceName: 'assessment-items',
})
export class AssessmentItemEntityService extends EntityService<AssessmentItemState> {

    constructor(private courseUnitService: CourseUnitEntityService) {
        super(AssessmentItemStore, AssessmentItemQuery);
        this.snapshotAtDatetimeLoader = new SisuDataLoader<string, AssessmentItemAtDatetime, AssessmentItemAtDatetime>(
            {
                getByIdsCall: snapshotIds => this.createGetBySnapshotIds(snapshotIds),
                resultExtractor: (snapshotId, entities) =>
                    entities.find(entity => toSnapshotId(entity.assessmentItemId, entity.snapshotAtDatetime) === snapshotId),
                bufferSize: 30,
            },
        );
    }

    public readonly snapshotAtDatetimeLoader: SisuDataLoader<string, AssessmentItemAtDatetime, AssessmentItemAtDatetime>;

    /**
     * Similar to `getByIds()`, but sorts the assessment items based on the order defined in the specified course unit.
     * If no `courseUnitId` is given, or if the course unit query fails, works identically to `getByIds()` (i.e. errors
     * thrown by the course unit query will not fail the assessment item query).
     */
    getByIdsSorted(ids: OtmId[], courseUnitId: OtmId, bypassStore?: boolean, config?: HttpConfig) {
        return combineLatest([
            this.getByIds(ids, bypassStore, config),
            (!courseUnitId ? of(null) : this.courseUnitService.getById(courseUnitId, bypassStore))
                .pipe(catchError(() => of(null))),
        ])
            .pipe(
                map(([assessmentItems, courseUnit]) => this.sortByCourseUnit(assessmentItems, courseUnit)),
            );
    }

    getSnapshotAtDateTime(id: OtmId, timestamp: LocalDateTimeString): Observable<AssessmentItem> {
        if (!id) {
            return throwError(() => new Error('The id was missing!'));
        }
        return this.snapshotAtDatetimeLoader.load(toSnapshotId(id, timestamp))
            .pipe(map(assessmentItemAtDatetime => assessmentItemAtDatetime.assessmentItem));
    }

    getSnapshotsAtDateTime(ids: OtmId[], timestamp: LocalDateTimeString): Observable<AssessmentItem[]> {
        return combineLatest(ids.map(id => this.getSnapshotAtDateTime(id, timestamp)));
    }

    getSnapshotsAtDateTimeSorted(ids: OtmId[], timestamp: LocalDateTimeString, courseUnitId: OtmId, bypassStore?: boolean): Observable<AssessmentItem[]> {
        return combineLatest([
            combineLatest(ids.map(id => this.getSnapshotAtDateTime(id, timestamp))),
            this.courseUnitService.getById(courseUnitId, bypassStore),
        ])
            .pipe(
                map(([assessmentItems, courseUnit]) => this.sortByCourseUnit(assessmentItems, courseUnit)),
            );
    }

    /**
     * Returns snapshots of assessment items referenced by courseUnitRealisation that are active on last day of activity period.
     *
     * @param courseUnitRealisation from where assessment item ids and activity period are read
     */
    getAssessmentItemSnapshotsAtEndOfCur(courseUnitRealisation: CourseUnitRealisation): Observable<AssessmentItem[]> {
        const timestamp = moment(courseUnitRealisation.activityPeriod.endDate).subtract(1, 'days').format(ISO_LOCAL_DATE_TIME_FORMAT);
        return combineLatest(courseUnitRealisation.assessmentItemIds.map(id => this.getSnapshotAtDateTime(id, timestamp)));
    }

    findForCourseUnit(courseUnit: CourseUnit, bypassStore?: boolean): Observable<AssessmentItem[]> {
        const assessmentItemIds = [
            ...(courseUnit?.assessmentItemOrder ?? []),
            // Append assessment item ids from completion methods, in case some ids are missing from the course unit
            ...(courseUnit?.completionMethods?.flatMap(cm => cm?.assessmentItemIds ?? []) ?? []),
        ];
        return this.getByIds(assessmentItemIds, bypassStore)
            .pipe(map(assessmentItems => this.sortByCourseUnit(assessmentItems, courseUnit)));
    }

    /**
     * Returns all assessment items listed in the completion method referenced by the product, in the order specified by the course unit.
     */
    findForOpenUniversityProduct(product: OpenUniversityProduct): Observable<AssessmentItem[]> {
        if (!product?.courseUnitId || !product?.completionMethodId) {
            return of(null);
        }

        return this.courseUnitService.getById(product.courseUnitId)
            .pipe(
                switchMap(courseUnit => {
                    const completionMethod = courseUnit?.completionMethods
                        ?.find(cm => cm?.localId === product.completionMethodId && cm.studyType === 'OPEN_UNIVERSITY_STUDIES');
                    return !completionMethod ? of(null) : this.getByIdsSorted(completionMethod.assessmentItemIds, courseUnit.id);
                }),
            );
    }

    sortByCourseUnit(assessmentItems: AssessmentItem[], courseUnit: CourseUnit): AssessmentItem[] {
        if (!assessmentItems) {
            return null;
        }

        const ordering = courseUnit?.assessmentItemOrder;
        if (!assessmentItems?.length || !ordering?.length) {
            // Return a new array to avoid unwanted side effects
            return [...assessmentItems];
        }

        return sortBy(assessmentItems, ai => ordering.includes(ai?.id) ? ordering.indexOf(ai?.id) : 10000);
    }

    private createGetBySnapshotIds(snapshotIds: string[]): Observable<AssessmentItemAtDatetime[]> {
        return this.getHttp().get<AssessmentItemAtDatetime[]>(
            CONFIG.ENDPOINTS.snapshotsAtDates,
            { params: { snapshotAtDate: snapshotIds.toString() } },
        );
    }
}

// We should format timestamp to same format as is in the backend response
const formatTimestamp = (timestamp: LocalDateTimeString) => timestamp ?
    moment(timestamp).format('YYYY-MM-DDTHH:mm:ss') :
    '-inf';
const toSnapshotId = (id: string, timestamp: LocalDateTimeString) => `${id}/${formatTimestamp(timestamp)}`;

type AssessmentItemState = EntityState<AssessmentItem>;

@StoreConfig({ name: 'assessment-items' })
class AssessmentItemStore extends EntityStore<AssessmentItemState> {}

class AssessmentItemQuery extends QueryEntity<AssessmentItemState> {
    constructor(protected store: AssessmentItemStore) {
        super(store);
    }
}
