import { Pipe, PipeTransform } from '@angular/core';
import { CourseUnitRealisation, LocalizedString } from 'common-typescript/types';
import { CourseUnitRealisation as CourseUnitRealisationGql } from 'sis-common/generated/graphql';
import { CourseUnitRealisationNameService } from 'sis-common/name/course-unit-realisation-name.service';

@Pipe({
    name: 'courseUnitRealisationFullName',
})
export class CourseUnitRealisationFullNamePipe implements PipeTransform {

    constructor(private courseUnitRealisationNameService: CourseUnitRealisationNameService) {
    }

    transform(value: CourseUnitRealisation | CourseUnitRealisationGql): LocalizedString {
        if (!value) {
            return null;
        }
        return this.courseUnitRealisationNameService.generateFullName(value);
    }
}
