<sis-expandable [variant]="'link'"
                [title]="('SIS_COMPONENTS.COURSE_UNIT_REALISATION_STRUCTURE.EXCEPTIONS_AND_ADDITIONAL_INFO' | translate) + ' (' + this.irregularEvents.length + ')'"
                class="exceptions-expandable-link-button">
  <ng-template sisExpandableContent>
    <div *ngFor="let event of this.irregularEvents">
      <div class="col-11 col-sm-10">
        <div class="row">
          <div class="col-3 col-xl-2">{{ event.start | date: 'dd.MM.yyyy' }}</div>
          <div class="col-2 col-sm-1">{{ event.start | date: 'EEE' }}</div>
          <div class="col-2">{{ event | eventTimeRange }}</div>
          <div class="locations col-5 col-sm-5 col-md-6">
            <div *ngFor="let locationId of event.irregularLocationIds">
              <span *ngIf="locationId | location | async as location" class="location">
                <sis-icon icon="pin-small"></sis-icon>
                {{location.building.name | localizedString:language}}
                , {{location.name | localizedString:language}}
              </span>
            </div>
            <div *ngFor="let teacherId of event.irregularTeacherIds">
              <span>
                <sis-icon icon="person-small"></sis-icon>
                {{ teacherId | publicPerson | async | personFullName }}
              </span>
            </div>
            <sis-tiny-badge *ngIf="event.cancelled"
                            [variant]="'danger'">{{'SIS_COMPONENTS.COURSE_UNIT_REALISATION_STRUCTURE.CANCELLED' | translate}}</sis-tiny-badge>
            <sis-tiny-badge *ngIf="event.excluded"
                            [variant]="'primary'">{{'SIS_COMPONENTS.COURSE_UNIT_REALISATION_STRUCTURE.EXCLUDED' | translate}}</sis-tiny-badge>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div *ngIf="event.notice" class="notice">
          <span class="event-notice">{{ event.notice | localizedString:language }}</span>
        </div>
      </div>
    </div>
  </ng-template>
</sis-expandable>
