import { Inject, Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { CourseUnit, CourseUnitRealisation, Message, Module, OtmId, Plan, PrivatePerson } from 'common-typescript/types';
import _ from 'lodash';
import { combineLatest, from, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocaleService } from 'sis-common/l10n/locale.service';

import { COMMON_MODULE_SERVICE, COURSE_UNIT_REALISATION_SERVICE } from '../ajs-upgraded-modules';
import { MessageTypes } from '../constant/messageTypes';
import { PlanOperationTypes } from '../constant/planOperationTypes';
import { convertAJSPromiseToNative } from '../util/utils';

import { CourseUnitEntityService } from './course-unit-entity.service';
import { EntityService } from './entity.service';
import { PlanEntityService } from './plan-entity.service';
import { PrivatePersonBasicInfoEntityService } from './private-person-basic-info-entity.service';

const CONFIG = {
    ENDPOINTS: {
        backend: '/osuva/api',
    },
};

@Injectable({
    providedIn: 'root',
})
@NgEntityServiceConfig({
    baseUrl: CONFIG.ENDPOINTS.backend,
    resourceName: 'messages',
})
export class MessageEntityService extends EntityService<MessageState> {

    constructor(private courseUnitEntityService: CourseUnitEntityService,
                private planEntityService: PlanEntityService,
                private privatePersonBasicInfoEntityService: PrivatePersonBasicInfoEntityService,
                @Inject(COMMON_MODULE_SERVICE) private commonModuleService: any,
                @Inject(COURSE_UNIT_REALISATION_SERVICE) private courseUnitRealisationService: any,
                private localeService: LocaleService) {
        super(MessageStore, MessageQuery);
    }

    readonly relationObservables: { [key: string]: Function } = {
        [MessageRelation.PARENTMODULE]: (message: Message) => _.has(message, 'parentModuleId') ? from(convertAJSPromiseToNative(this.commonModuleService.findById(_.get(message, 'parentModuleId')))) : of(null),
        [MessageRelation.MODULE]: (message: Message) => _.has(message, 'moduleId') ? from(convertAJSPromiseToNative(this.commonModuleService.findById(_.get(message, 'moduleId', null)))) : of(null),
        [MessageRelation.COURSEUNIT]: (message: Message) => _.has(message, 'courseUnitId') ? this.courseUnitEntityService.getById(_.get(message, 'courseUnitId')) : of(null),
        [MessageRelation.PLAN]: (message: Message) => _.has(message, 'planId') ? this.planEntityService.getById(_.get(message, 'planId')) : of(null),
        [MessageRelation.STUDENT]: (message: Message) => _.has(message, 'studentId') ? this.privatePersonBasicInfoEntityService.getById(_.get(message, 'studentId')) : of(null),
        [MessageRelation.COURSEUNITREALISATION]: (message: Message) => _.has(message, 'courseUnitRealisationId') ? from(convertAJSPromiseToNative(this.courseUnitRealisationService.findById(_.get(message, 'courseUnitRealisationId')))) : of(null),
    };

    readonly relationEnums: MessageRelation[] = Object.values(MessageRelation);

    loadRelations(message: Message, requestedRelations: MessageRelation[]): Observable<MessageWithResolvedData> {
        return combineLatest([
            of(message),
            ...this.constructRelationObservables<Message, MessageRelation>(this.relationEnums, requestedRelations, this.relationObservables, message),
        ]).pipe(
            map(data => data as unknown as [Message, Module, Module, CourseUnit, Plan, PrivatePerson, CourseUnitRealisation]),
            map(
                (data) => {
                    const [msg, parentModule, module, courseUnit, plan, student, courseUnitRealisation] = data;
                    return {
                        parentModule, module, courseUnit, plan, student, courseUnitRealisation, message: msg,
                    };
                },
            ),
        );
    }

    getMessageTypeTranslationKey(message: Message): string {
        const type: string = _.get(message, 'type');
        const operationType: string = _.get(message, 'operationType');
        if (message && _(MessageTypes).values().map(value => value.toString()).includes(type)) {
            return `SIS_COMPONENTS.MESSENGER.MESSAGE.TYPE.${message.type}.${
                _.get(PlanOperationTypes, operationType, 'NAME')}`;
        }
        return '';
    }

    getMessageTargetNameFromResolvedData(data: MessageWithResolvedData): string {
        const hasCourseUnit = ({ courseUnit }: MessageWithResolvedData): boolean => !_.isEmpty(courseUnit);
        const hasModule = ({ module }: MessageWithResolvedData): boolean => !_.isEmpty(module);
        const hasCustomStudyDraft = ({ message }: MessageWithResolvedData): boolean => _.has(message, 'customStudyDraft');
        const getCourseUnitName = ({ courseUnit }: MessageWithResolvedData) => this.localeService.localize(_.get(courseUnit, 'name'));
        const getModuleName = ({ module }: MessageWithResolvedData) => this.localeService.localize(_.get(module, 'name'));
        const getCustomStudyDraftName = ({ message }: MessageWithResolvedData) => _.get(message, 'customStudyDraft.name');
        return _.cond([
            [hasCourseUnit, getCourseUnitName],
            [hasModule, getModuleName],
            [hasCustomStudyDraft, getCustomStudyDraftName],
            [_.stubTrue, () => null],
        ])(data);
    }

}

export enum MessageRelation {
    PARENTMODULE = 'parentModule',
    MODULE = 'module',
    COURSEUNIT = 'courseUnit',
    PLAN = 'plan',
    STUDENT = 'student',
    COURSEUNITREALISATION = 'courseUnitRealisation',
}

export interface MessageWithResolvedData {
    message: Message;
    parentModule?: Module;
    module?: Module;
    courseUnit?: CourseUnit;
    plan?: Plan;
    student?: PrivatePerson;
    courseUnitRealisation?: CourseUnitRealisation;
}

type MessageState = EntityState<Message, OtmId>;

@StoreConfig({ name: 'messages' })
class MessageStore extends EntityStore<MessageState> {}

class MessageQuery extends QueryEntity<MessageState> {
    constructor(protected store: MessageStore) {
        super(store);
    }
}
