import { Component, Input, ViewEncapsulation } from '@angular/core';
import { LocalizedString } from 'common-typescript/types';
import { LocalizedStringWithoutFallbackPipe } from 'sis-common/l10n/localized-string-without-fallback.pipe';

@Component({
    selector: 'sis-show-more-text',
    templateUrl: './show-more-text.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ShowMoreTextComponent {

    // supports text given as localizedString or as basic string
    // Hides text after certain amount of chars
    // and show button to reveal rest of the text or hide it
    // optional emptyText value (e.g. '-') instead of empty space
    @Input() data: LocalizedString;
    @Input() text: string;
    @Input() showMoreButtonText: string;
    @Input() showLessButtonText: string;
    @Input() maxCharsBeforeOverFlow: number;
    @Input() emptyText?: string;
    @Input() selectedLocale?: string;
    isReadMore = true;

    constructor(private localizedStringWithoutFallbackPipe: LocalizedStringWithoutFallbackPipe) { }

    getText(): string {
        if (!this.text && this.selectedLocale) {
            const text = this.localizedStringWithoutFallbackPipe.transform(this.data, this.selectedLocale);
            return text ? text : (this.emptyText ? this.emptyText : '');
        }
        return this.text ? this.text : (this.emptyText ? this.emptyText : '');
    }

    showText(): void {
        this.isReadMore = !this.isReadMore;
    }

    abbreviateTextWithThreeDots(): string {
        const abbreviatedText = this.getText().slice(0, this.maxCharsBeforeOverFlow);
        return abbreviatedText.endsWith('.') ? `${abbreviatedText}..'` : `${abbreviatedText}...`;
    }
}
