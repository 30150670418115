import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SuomiFiUserBasicDetails, UniversityConfig } from 'common-typescript/types';
import { Observable } from 'rxjs';
import { ConfigService } from 'sis-common/config/config.service';

import { AppErrorHandler } from '../error-handler/app-error-handler';

const CONFIG = {
    ENDPOINTS: {
        backend: '/ilmo/api/open-university/suomi-fi',
    },
};

@Injectable({
    providedIn: 'root',
})
export class SuomiFiService {

    constructor(private appErrorHandler: AppErrorHandler,
                private http: HttpClient,
                private configService: ConfigService) { }

    login(): Observable<SuomiFiUserBasicDetails> {
        return this.http.get(`${CONFIG.ENDPOINTS.backend}/login`) as Observable<SuomiFiUserBasicDetails>;
    }

    logout(returnUrl?: string): void {
        this.http.post(`${CONFIG.ENDPOINTS.backend}/logout`, {})
            .pipe(this.appErrorHandler.defaultErrorHandler())
            .subscribe(() => this.shibbolethLogout(returnUrl));
    }

    private shibbolethLogout(returnUrl?: string) {
        const universityConfig: UniversityConfig = this.configService.get();
        const defaultReturnViewPrefix = `${window.location.origin}/student`;
        const returnView = returnUrl ?? '/search';

        const returnViewUrl = `${defaultReturnViewPrefix}${returnView}`;
        /*
       When "suomiFiShibbolethLogoutUrl" exists we do logout in shibboleth.
       "dev" environment does not use shibboleth so we go instantly to returnView.
        */
        const url = universityConfig.suomiFiShibbolethLogoutUrl ?
            `${universityConfig.suomiFiShibbolethLogoutUrl}?return=${returnViewUrl}` :
            returnViewUrl;
        this.goToHref(url);
    }

    goToHref(url: string) {
        window.location.href = url;
    }
}
