import {
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component, DestroyRef,
    ElementRef, EventEmitter,
    Input, OnInit, Output,
    ViewEncapsulation,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { HashMap } from '@ngneat/transloco';
import { LocalizedMarkupString } from 'common-typescript/types';
import { Observable, Subscriber } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ComponentDowngradeMappings } from 'sis-common/types/angular-hybrid';

import { ReadMoreValues } from '../read-more-ng/read-more-ng-modal/read-more-ng-modal.component';

import { ReadMoreModalService } from './read-more-modal.service';

function getElementResizeEventObservable(element: HTMLElement): Observable<ResizeObserverEntry[]> {
    return new Observable((subscriber: Subscriber<ResizeObserverEntry[]>) => {
        const resizeObserver: ResizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]): void => {
            subscriber.next(entries);
        });
        resizeObserver.observe(element);
        return function unsubscribe(): void {
            resizeObserver.unobserve(element);
        };
    });
}

/**
 * @deprecated This component is not advised to be used in the future.
 */
@Component({
    selector: 'sis-read-more-ng',
    templateUrl: './read-more-ng.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReadMoreNgComponent implements OnInit {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.lib.read-more-ng',
        directiveName: 'sisReadMoreNg',
    };

    constructor(
        private readMoreModalService: ReadMoreModalService,
        private elem: ElementRef,
        private ref: ChangeDetectorRef,
        private destroyRef: DestroyRef,
    ) {}

    truncated: boolean = false;

    @Input() title: string;
    @Input() maxHeight: number;
    @Input() contentHtml: LocalizedMarkupString;
    @Input() contentTranslateKey: string;
    @Input() contentTranslateParameters: HashMap;
    @Output() resizeEventEmitter = new EventEmitter<void>();

    ngOnInit(): void {
        if (this.contentHtml && this.contentTranslateKey) {
            throw new Error('contentHtml and contentTranslateKey cannot be use at the same time.');
        }
        if (this.contentHtml && this.contentTranslateParameters) {
            throw new Error('contentHtml and contentTranslateParameters cannot be use at the same time.');
        }
        if (!this.contentTranslateKey && this.contentTranslateParameters) {
            throw new Error('contentTranslateParameters cannot be used without contentTranslateKey.');
        }
        this.observeFullContentElement();
    }

    contentMaxHeight(): number {
        return this.maxHeight - 3;
    }

    openReadMore(): any {
        this.readMoreModalService.open(this.getReadMoreOptions());
    }

    getReadMoreOptions(): ReadMoreValues {
        return {
            options: {
                contentHtml: this.contentHtml,
                contentTranslateKey: this.contentTranslateKey,
                contentTranslateParameters: this.contentTranslateParameters,
                title: this.title },
        };
    }

    observeFullContentElement(): void {
        const fullContent: HTMLElement = this.elem.nativeElement.querySelector('.full-content');
        const obs: Observable<ResizeObserverEntry[]> = getElementResizeEventObservable(fullContent);
        obs.pipe(debounceTime(250),
                 takeUntilDestroyed(this.destroyRef))
            .subscribe((entries: ResizeObserverEntry[]): void => {
                for (const entry of entries) {
                    this.truncateContentIfNeeded();
                }
            });
    }

    truncateContentIfNeeded(): void {
        const truncateContent: boolean = this.isTruncateNeeded();
        if (this.truncated !== truncateContent) {
            this.truncated = truncateContent;
            this.ref.markForCheck();
            this.resizeEventEmitter.emit();
        }
    }

    isTruncateNeeded(): boolean {
        const fullContent = this.elem.nativeElement.querySelectorAll('.full-content')[0];
        return fullContent.offsetHeight > this.maxHeight;
    }
}
