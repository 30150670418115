import { Component, Input, ViewEncapsulation } from '@angular/core';
import { CourseUnitRealisation } from 'common-typescript/types';
import { trackByEntityId } from 'sis-components/util/utils';

@Component({
    selector: 'app-course-unit-realisation-info',
    templateUrl: './course-unit-realisation-info.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CourseUnitRealisationInfoComponent {

    @Input() courseUnitRealisation: CourseUnitRealisation;

    currentTabIndex = 0;

    readonly entityId = trackByEntityId;
}
