import { commonPlanServiceModule } from 'sis-components/service/plan.service';
(function () {
  planSubstitutionService.$inject = ["$log", "$q", "commonPlanService"];
  angular.module('student.common.service.planSubstitutionService', [commonPlanServiceModule]).factory('planSubstitutionService', planSubstitutionService);
  function planSubstitutionService($log, $q, commonPlanService) {
    const api = {
      removeSubstitutionSubstituteFor: (substitutedCourseUnitId, substitutingCourseUnitId, plan) => {
        const courseUnitSelectionForSubstitutingCourseUnit = plan.getCourseUnitSelection(substitutingCourseUnitId);
        if (courseUnitSelectionForSubstitutingCourseUnit) {
          // removed old substituteFor value
          _.remove(courseUnitSelectionForSubstitutingCourseUnit.substituteFor, {
            substitutedCourseUnitId: substitutedCourseUnitId
          });
          if (_.isEmpty(courseUnitSelectionForSubstitutingCourseUnit.substituteFor) && (!courseUnitSelectionForSubstitutingCourseUnit.parentModuleId || courseUnitSelectionForSubstitutingCourseUnit.parentModuleId === null)) {
            // removed empty courseUnitSelection
            _.remove(plan.courseUnitSelections, {
              courseUnitId: substitutingCourseUnitId
            });
          }
        } else {
          $log.error('removeSubstitutionSubstituteFor > ' + 'courseUnitSelectionForSubstitutingCourseUnit not found for substitutingCourseUnitId', 'substitutedCourseUnitId', substitutedCourseUnitId, 'substitutingCourseUnitId', substitutingCourseUnitId);
        }
      },
      removeOldSubstitutionLinksAndAssesmentItemSelections: (courseUnit, courseUnitSelection, plan) => {
        _.forEach(courseUnitSelection.substitutedBy, function (substitutedBy) {
          _.remove(plan.assessmentItemSelections, assessmentItem => {
            return substitutedBy === assessmentItem.courseUnitId;
          });
          api.removeSubstitutionSubstituteFor(courseUnit.id, substitutedBy, plan);
        });
      },
      removeSubstitution: (courseUnit, plan) => {
        const courseUnitId = _.get(courseUnit, 'id');
        const courseUnitSelection = plan.getCourseUnitSelection(courseUnitId);
        api.removeOldSubstitutionLinksAndAssesmentItemSelections(courseUnit, courseUnitSelection, plan);
        courseUnitSelection.substitutedBy = [];
        return commonPlanService.saveMyPlan(plan);
      },
      saveSubstitution: (courseUnit, substitution, plan, substituteCourseUnitsByGroupId) => {
        const substitutingIds = [];
        const courseUnitSelection = plan.getCourseUnitSelection(courseUnit.id);
        api.removeOldSubstitutionLinksAndAssesmentItemSelections(courseUnit, courseUnitSelection, plan);
        for (let i = 0; i < substitution.length; i++) {
          const courseUnitSubstitution = substitution[i];
          const substitutingCourseUnitGroupId = _.get(courseUnitSubstitution, 'courseUnitGroupId');
          const substitutingCourseUnitId = _.get(_.get(substituteCourseUnitsByGroupId, substitutingCourseUnitGroupId), 'id');
          substitutingIds.push(substitutingCourseUnitId);
          const courseUnitSelectionForSubstitutingCourseUnit = plan.getCourseUnitSelection(substitutingCourseUnitId);
          if (courseUnitSelectionForSubstitutingCourseUnit && !_.isEmpty(courseUnitSelectionForSubstitutingCourseUnit.substitutedBy)) {
            $log.error('Substitution in course unit which is a substitute already.', 'substitutingCourseUnitId', substitutingCourseUnitId, 'plan', plan, 'substitution', substitutingIds);
            return $q.reject();
          }
          const substituteFor = {
            substitutedCourseUnitId: courseUnit.id,
            substitutedCredits: _.get(courseUnitSubstitution, 'credits')
          };
          if (courseUnitSelectionForSubstitutingCourseUnit) {
            if (!courseUnitSelectionForSubstitutingCourseUnit.substituteFor) {
              courseUnitSelectionForSubstitutingCourseUnit.substituteFor = [];
            }
            courseUnitSelectionForSubstitutingCourseUnit.substituteFor.push(substituteFor);
          } else {
            plan.courseUnitSelections.push({
              courseUnitId: substitutingCourseUnitId,
              substitutedBy: [],
              substituteFor: [substituteFor]
            });
          }
        }

        // set substituted courseUnitSelection
        courseUnitSelection.completionMethodId = undefined;
        courseUnitSelection.substitutedBy = substitutingIds;
        courseUnitSelection.plannedPeriods = [];
        return commonPlanService.saveMyPlan(plan);
      }
    };
    return api;
  }
})();