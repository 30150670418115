<div class="show-more-text-container" *ngIf="getText() as stringText">
  <div class="show-more-text-content">
    <sis-markup-string-view [content]="(stringText && stringText.length > maxCharsBeforeOverFlow && this.isReadMore ? abbreviateTextWithThreeDots() : stringText)"></sis-markup-string-view>
  </div>
  <div class="show-more-text-button">
    <button *ngIf="stringText && stringText.length > maxCharsBeforeOverFlow"
            type="button"
            (click)="showText()"
            [attr.aria-label]="isReadMore ? (showMoreButtonText | translate): (showLessButtonText | translate)"
            [attr.aria-expanded]="!isReadMore"
            class="link-button show-more">
      {{ isReadMore ? (showMoreButtonText | translate): (showLessButtonText | translate) }}
    </button>
  </div>
</div>

