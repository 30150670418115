import angular from 'angular';
import _ from 'lodash';
import { ModuleInfoModalService } from '../module-info/module-info-modal.service';
import moduleBoxTpl from './moduleBox.tpl.html';
angular.module('student.common.components.moduleBox', [ModuleInfoModalService.downgrade.moduleName, 'student.common.context.uiContext', 'sis-components.service.colorService']).component('moduleBox', {
  template: moduleBoxTpl,
  transclude: {
    additional: '?additional',
    notifications: '?notifications'
  },
  bindings: {
    module: '<',
    dimmed: '<',
    notifications: '<',
    validatablePlan: '<',
    planValidationResult: '<?'
  },
  controller: ["colorService", "$log", "moduleInfoModalServiceNg", function (colorService, $log, moduleInfoModalServiceNg) {
    const $ctrl = this;
    $ctrl.$onChanges = () => {
      if ($ctrl.validatablePlan) {
        const moduleId = _.get($ctrl.module, 'id');
        $ctrl.moduleAttainment = $ctrl.validatablePlan.getModuleAttainment(moduleId);
        const moduleValidationResults = _.get($ctrl.planValidationResult, 'moduleValidationResults');
        $ctrl.moduleValidationResult = _.get(moduleValidationResults, moduleId);
        setAttainedCredits($ctrl.module);
      }
    };
    $ctrl.openModuleInfo = () => {
      moduleInfoModalServiceNg.open({
        moduleId: $ctrl.module.id,
        sectionExpandableOpenSettings: {
          basics: true
        }
      }).subscribe({
        next: () => {/* modal was closed */},
        error: () => {/* modal was closed */}
      });
    };
    $ctrl.getModuleColorCategory = () => {
      try {
        const colorCategory = colorService.getModuleColorCategory($ctrl.validatablePlan, _.get($ctrl, 'module.id'));
        if (colorCategory) {
          return `cu-color-${colorCategory}`;
        }
        return undefined;
      } catch (e) {
        $log.error('Failed to getModuleColorCategory', e);
        return undefined;
      }
    };
    function setAttainedCredits() {
      if ($ctrl.moduleAttainment) {
        $ctrl.attainedCredits = {};
        $ctrl.attainedCredits.min = $ctrl.moduleAttainment.credits;
        $ctrl.attainedCredits.max = $ctrl.moduleAttainment.credits;
      }
    }
  }]
});