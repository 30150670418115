import { Component, Input, ViewEncapsulation } from '@angular/core';

export type State = 'DRAFT' | 'ACTIVE' | 'DELETED';

/**
 *  Sis-document-state-badge has three states: draft, active and deleted.
 *  Right translation should occur inside badge depending on the state.
 *  Currently, ngx-translate support is not integrated to Storybook, so the translated content is not shown correctly.
 */
@Component({
    selector: 'sis-document-state-badge',
    templateUrl: './document-state-badge.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class DocumentStateBadgeComponent {
    @Input() state?: 'DRAFT' | 'ACTIVE' | 'DELETED' = 'DRAFT';
}
