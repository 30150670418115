'use strict';

(function () {
  sortByLocalizedValue.$inject = ["localeService"];
  angular.module('sis-components.string.sortByLocalizedValueFilter', ['sis-common.l10n.localeService']).filter('sortByLocalizedValue', sortByLocalizedValue);

  /**
   * @ngInject
   */
  function sortByLocalizedValue(localeService) {
    /**
     * Sort by localized value
     * example usage: ng-repeat="cur in curs |sortByLocalizedValue:'name'"
     *
     * @param array
     * @param key|string
     * @param reverse|boolean
     */
    return function (objectList, key, reverse) {
      var sorted = _.sortBy(objectList, function (obj) {
        return localeService.getLocalizedValue(_.result(obj, key));
      });
      if (reverse) {
        sorted.reverse();
      }
      return sorted;
    };
  }
})();