timetableUtil.$inject = ["timetableConfigConstant"];
import * as angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import { timetableModule } from './timetable.module';
angular.module(timetableModule).factory('timetableUtil', timetableUtil);

/** @ngInject */
function timetableUtil(timetableConfigConstant) {
  const api = {
    filterEventsForDate(calendarServiceEvents, date) {
      return _.filter(calendarServiceEvents, calendarServiceEvent => date.isBetween(calendarServiceEvent.start, calendarServiceEvent.end, 'days', '[]'));
    },
    mapToTimetableObject(calendarServiceEvents, date) {
      const eventsList = [];
      const eventsForDate = api.filterEventsForDate(calendarServiceEvents, date);
      _.map(eventsForDate, event => {
        let timetableRangeObj;
        if (event.type === 'OWN_CALENDAR_EVENT') {
          timetableRangeObj = getTimetableRangeOfCalendarEvent(event, date);
        } else {
          timetableRangeObj = getTimetableRangeOfStudyEvent(event);
        }
        eventsList.push(Object.assign({}, event, {
          time: timetableRangeObj,
          code: '',
          backgroundColor: event.color
        }));
      });
      return {
        date,
        events: eventsList
      };
    },
    findEarliestHour(ttEvents) {
      const sorted = _.sortBy(ttEvents, ttEvent => ttEvent.time.start.toDate());
      return _.first(sorted).time.start.hour();
    },
    /**
     * An event that ends after the date, or at midnight should set the latest hour to 25
     * to indicate the numberof rows to be generated for the day. +1 for the end time also to generate the last
     * row
     * @param ttEvents
     * @returns {number}
     */
    findLatestHour(ttEvents) {
      const sorted = _.sortBy(ttEvents, ttEvent => ttEvent.time.end.toDate());
      return _.last(sorted).time.end.hour() === 0 ? 25 : _.last(sorted).time.end.hour() + 1;
    },
    // public for testing
    getTimetableRangeOfCalendarEvent,
    getTimetableRangeOfStudyEvent
  };
  function getTimetableRangeOfCalendarEvent(event, date) {
    const eventTimetableStart = date.clone();
    const eventTimetableEnd = date.clone();
    const calendarEventStart = moment(_.get(event, 'eventObject.start', _.get(event, 'start')));
    const calendarEventEnd = moment(_.get(event, 'eventObject.end', _.get(event, 'end')));
    if (!calendarEventStart.isSame(date, 'day')) {
      eventTimetableStart.set({
        hour: timetableConfigConstant.hours[0],
        minute: 0
      });
    } else {
      eventTimetableStart.set({
        hour: event.start.hour(),
        minute: event.start.minute()
      });
    }
    if (!calendarEventEnd.isSame(date, 'day')) {
      eventTimetableEnd.set({
        hour: timetableConfigConstant.hours[timetableConfigConstant.hours.length - 1],
        minute: 0
      });
    } else {
      eventTimetableEnd.set({
        hour: event.end.hour(),
        minute: event.end.minute()
      });
    }
    return {
      start: eventTimetableStart,
      end: eventTimetableEnd
    };
  }

  // studyEvents doesn't have durations lasting to next day, but in the future if there is, this
  // needs to be rewritten.
  function getTimetableRangeOfStudyEvent(event) {
    return {
      start: event.start,
      end: event.end
    };
  }
  return api;
}