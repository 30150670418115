import { Pipe, PipeTransform } from '@angular/core';
import { CourseUnitRealisation, OtmId } from 'common-typescript/types';

@Pipe({
    name: 'filterCourseUnitRealisationsByAssessmentItemId',
})
export class FilterCourseUnitRealisationsByAssessmentItemIdPipe implements PipeTransform {
    transform(value: CourseUnitRealisation[], assessmentItemId: OtmId): CourseUnitRealisation[] {
        if (!value || !assessmentItemId) {
            return null;
        }
        return value.filter(cur => cur.assessmentItemIds.includes(assessmentItemId));
    }
}
