import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SisCommonModule } from 'sis-common/sis-common.module';

import { SisComponentsModule } from '../../../sis-components.module';

import { AttainmentTreeAttainmentComponent } from './attainment-tree-attainment.component';
import { AttainmentTreeGroupNodeComponent } from './attainment-tree-group-node.component';
import { AttainmentTreeNodesComponent } from './attainment-tree-nodes.component';

@NgModule({
    declarations: [
        AttainmentTreeAttainmentComponent,
        AttainmentTreeGroupNodeComponent,
        AttainmentTreeNodesComponent,
    ],
    imports: [
        CommonModule,
        SisCommonModule,
        SisComponentsModule,
        TranslateModule.forChild(),
    ],
    exports: [
        AttainmentTreeAttainmentComponent,
    ],
})
export class AttainmentTreeModule {
}
