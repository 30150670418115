import { Injectable } from '@angular/core';

import { LocalStorageService } from '../storage/local-storage.service';

@Injectable({
    providedIn: 'root',
})
export class LocalStorageSelectedLanguageService {

    constructor(private localStorageService: LocalStorageService) { }
    selectedLanguageLocalStorageKey = 'selected_language';

    get = () => this.localStorageService.getItem(this.selectedLanguageLocalStorageKey) || 'fi';
    set = (language: string) => this.localStorageService.setItem(this.selectedLanguageLocalStorageKey, language);
}
