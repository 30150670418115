(function () {
  'use strict';

  angular.module('student.common.utils.timingHelper', ['student.common.model.studyPeriodLocator', 'student.common.service.studyPeriodService']).factory('timingHelper', ["$q", "studyPeriodLocatorModel", "studyPeriodService", function ($q, studyPeriodLocatorModel, studyPeriodService) {
    return {
      getFirstTimedPeriod: validatablePlan => {
        const allCourseUnitsInPlan = validatablePlan.getAllCourseUnitsInPlan();
        const periods = [];
        _.forEach(allCourseUnitsInPlan, courseUnit => {
          const courseUnitSelection = validatablePlan.getCourseUnitSelection(_.get(courseUnit, 'id'));
          if (courseUnitSelection) {
            _.forEach(courseUnitSelection.plannedPeriods, period => {
              const studyPeriodLocator = new studyPeriodLocatorModel(period);
              periods.push(studyPeriodLocator);
            });
          }
        });
        _.forEach(_.get(validatablePlan, 'plan.customStudyDrafts'), customStudyDraft => {
          _.forEach(customStudyDraft.plannedPeriods, period => {
            const studyPeriodLocator = new studyPeriodLocatorModel(period);
            periods.push(studyPeriodLocator);
          });
        });
        if (_.isEmpty(periods)) {
          return $q.when();
        }
        periods.sort(sortByPeriodTime);
        const firstTimedCourseUnitLocator = _.first(periods);
        return studyPeriodService.getPeriodsFor(firstTimedCourseUnitLocator.toLocator()).then(studyPeriod => {
          return moment(studyPeriod.startDate, 'YYYY-MM-DD');
        });
      },
      getFirstCourseUnitRealisationStartDateFromSelections: function (courseUnits, selections) {
        if (courseUnits.length === 0 || selections.length === 0) {
          return false;
        }
        var foundSelections = _.filter(selections, function (selection) {
          return _.some(courseUnits, function (courseUnit) {
            if (_.get(courseUnit, 'selectedCompletionMethod', null) === null) {
              return false;
            }
            var assessmentItemIds = Object.keys(courseUnit.selectedCompletionMethod.assessmentItemIds);
            return _.some(assessmentItemIds, function (assessmentItemId) {
              return selection.courseUnitRealisation.assessmentItemIds.indexOf(assessmentItemId) >= 0;
            });
          });
        });
        if (foundSelections.length === 0) {
          return false;
        }
        var sorted = foundSelections.sort(sortByActivityPeriodStartDate);
        var oldest = _.first(sorted);
        return moment(oldest.courseUnitRealisation.activityPeriod.startDate, 'YYYY-MM-DD');
      },
      getFirstStudyRightDate: function (studyRights) {
        var firstDate = false;
        _.each(studyRights, function (studyRight) {
          var startDate = _.get(studyRight, 'valid.startDate', false);
          if (!firstDate && startDate) {
            firstDate = moment(startDate, 'YYYY-MM-DD');
          } else if (startDate) {
            startDate = moment(startDate, 'YYYY-MM-DD');
            if (startDate.isBefore(firstDate)) {
              firstDate = startDate;
            }
          }
        });
        return firstDate;
      },
      getLatest: function (d1, d2) {
        if (d1.isBefore(d2)) {
          return d1;
        }
        return d2;
      },
      /**
       * Repeat missing plannedTeachingPeriods from last planned year to last period's year (end of time line)
       * @param plannedTeachingPeriods
       * @param periodStartYear
       */
      addYearsToPlannedTeachingPeriods: function (plannedTeachingPeriods, periodStartYear) {
        var plannedTeachingPeriodsStudyPeriodLocatorModels = toStudyPeriodLocatorModels(plannedTeachingPeriods);
        var lastPlanningPeriods = getLastYear(plannedTeachingPeriodsStudyPeriodLocatorModels);
        var lastYear = _.first(_.first(lastPlanningPeriods)).year;

        // fill repeatSets to end of time line
        var newYearCount = periodStartYear - lastYear;
        for (var yearIndex = 1; yearIndex <= newYearCount; ++yearIndex) {
          var newRepeatSets = _.cloneDeep(lastPlanningPeriods);
          for (var j = 0; j < newRepeatSets.length; ++j) {
            for (var k = 0; k < newRepeatSets[j].length; ++k) {
              var p = newRepeatSets[j][k];
              p.year += yearIndex;
            }
          }
          // merge arrays
          Array.prototype.push.apply(plannedTeachingPeriods, toStudyPeriodLocators(newRepeatSets));
        }
        function toStudyPeriodLocatorModels(periods) {
          return _.map(periods, function (repeatSet) {
            return _.map(repeatSet, function (p) {
              return new studyPeriodLocatorModel(p);
            });
          });
        }
        function getLastYear(plannedTeachingPeriods) {
          var sortedByYear = _.sortBy(plannedTeachingPeriods, function (pList) {
            return _.first(pList).year;
          });
          var lastYear = _.first(_.last(sortedByYear)).year;
          return _.filter(sortedByYear, function (sortedRepeatSet) {
            return _.first(sortedRepeatSet).year === lastYear;
          });
        }
        function toStudyPeriodLocators(periods) {
          return _.map(periods, function (repeatSet) {
            return _.map(repeatSet, function (p) {
              return p.toLocator();
            });
          });
        }
      },
      getCompletionMethodRepeatSets: function (completionMethods, selectedCompletionMethod) {
        var repeatSets;
        if (selectedCompletionMethod) {
          repeatSets = extractRepeatSets([selectedCompletionMethod]);
        } else {
          repeatSets = extractRepeatSets(completionMethods);
        }
        return repeatSets;
        function extractRepeatSets(completionMethods) {
          var repeatSets = [];
          _.each(completionMethods, function (cm) {
            _.each(cm.repeats, function (repeat) {
              repeatSets.push(repeat.repeatPossibility);
            });
          });
          return repeatSets;
        }
      }
    };
    function sortByPeriodTime(a, b) {
      if (a.year > b.year) {
        return 1;
      } else if (a.year === b.year) {
        if (a.term > b.term) {
          return 1;
        } else if (a.term === b.term) {
          if (a.period > b.period) {
            return 1;
          } else if (a.period === b.period) {
            return 0;
          } else {
            return -1;
          }
        } else {
          return -1;
        }
      }
      return -1;
    }
    function sortByActivityPeriodStartDate(a, b) {
      var aStart = new Date(a.courseUnitRealisation.activityPeriod.startDate);
      var bStart = new Date(b.courseUnitRealisation.activityPeriod.startDate);
      return aStart < bStart ? -1 : 1;
    }
  }]);
})();