<div class="sis-typeahead">
  <input
    #inputElement
    *ngIf="!control"
    class="form-control"
    type="text"
    (click)="click.emit($event)"
    [disabled]="disabled"
    [editable]="editable"
    [attr.id]="id"
    [attr.aria-describedby]="id + '_errors'"
    data-cy="typeahead-input"
    [inputFormatter]="formatInput.bind(this)"
    (keydown)="changeDetectorRef.detectChanges()"
    (keyup)="checkSearchLength($event)"
    [name]="name"
    [ngbTypeahead]="debouncedTypeahead"
    [ngClass]="class"
    [ngModel]="model"
    (ngModelChange)="onModelChange($event)"
    [placeholder]="placeholder || ''"
    [required]="required"
    [resultFormatter]="resultFormatter"
    [resultTemplate]="resultTemplate || defaultTemplate"
    (selectItem)="selectItem.emit($event)"
    (blur)="blurEvent.emit($event)"
  >
  <input
    #inputElement
    *ngIf="control"
    class="form-control"
    type="text"
    (click)="click.emit($event)"
    [formControl]="control"
    [editable]="editable"
    [attr.id]="id"
    [attr.aria-describedby]="id + '_errors'"
    data-cy="typeahead-input"
    [inputFormatter]="formatInput.bind(this)"
    (keydown)="changeDetectorRef.detectChanges()"
    (keyup)="checkSearchLength($event)"
    [name]="name"
    [class.ng-touched]="control?.touched"
    [class.ng-dirty]="control?.dirty"
    [class.ng-invalid]="control?.invalid"
    [ngbTypeahead]="debouncedTypeahead"
    [ngClass]="class"
    [placeholder]="placeholder || ''"
    [resultFormatter]="resultFormatter"
    [resultTemplate]="resultTemplate || defaultTemplate"
    (selectItem)="selectItem.emit($event)"
  >
  <ng-template #searchWithIcon>
    <sis-icon icon="search" class="search-with-svg-icon" aria-hidden="true"></sis-icon>
  </ng-template>
  <sis-button *ngIf="clearable && (this.control?.value || this.modelValueSelected); else searchWithIcon"
              (clicked)="clear()"
              [class]="'clear-value'"
              [link]="true"
              [ariaLabel]="'SIS_COMPONENTS.TYPEAHEAD.CLEAR_FIELD' | translate">
    <sis-icon [icon]="'close'"></sis-icon>
  </sis-button>

  <sis-validation-errors *ngIf="control?.touched" [control]="control" [validationErrors]="validationErrors" [formFieldId]="id" [ariaLive]="false" />
  <sis-validation-errors *ngIf="!control" [validationErrors]="validationErrors" [formFieldId]="id" [ariaLive]="false" />

  <ng-template #defaultTemplate let-result="result" let-term="term">
    <span
      *ngIf="!highlightSearchTerm"
      [class.disabled]="result.disabled"
      data-cy="typeahead-result-item">
      <transloco [content]="resultFormatter ? resultFormatter(result) : result" />
    </span>
    <ngb-highlight
      *ngIf="highlightSearchTerm"
      [class.disabled]="result.disabled"
      [term]="term"
      [result]="resultFormatter ? resultFormatter(result) : result"
      data-cy="typeahead-result-item"
    ></ngb-highlight>
  </ng-template>
</div>
