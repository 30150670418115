(function () {
  'use strict';

  angular.module('student.common.utils.RuleVisitor', []).factory('RuleVisitor', ruleVisitorFactory);
  function ruleVisitorFactory() {
    function RuleVisitor(options) {
      _.assign(this, options);
    }
    RuleVisitor.prototype.visitCompositeRule = function (compositeRule, ctx) {
      var result;
      var self = this;
      _.forEach(compositeRule.rules, function (rule) {
        result = rule.accept(self, ctx);
      }, this);
      return result;
    };
    RuleVisitor.prototype.visitCreditsRule = function (creditsRule, ctx) {
      return creditsRule.rule.accept(this, ctx);
    };
    RuleVisitor.prototype.visitCourseUnitCountRule = function (courseUnitCountRule, ctx) {
      return courseUnitCountRule.rule.accept(this, ctx);
    };
    RuleVisitor.prototype.visitCourseUnitRule = function () {};
    RuleVisitor.prototype.visitModuleRule = function () {};
    RuleVisitor.prototype.visitAnyCourseUnitRule = function () {};
    RuleVisitor.prototype.visitAnyModuleRule = function () {};
    return RuleVisitor;
  }
})();