import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthTokenInterceptorService } from './auth-token-interceptor.service';

@Injectable({ providedIn: 'root' })
export class AuthTokenInterceptor implements HttpInterceptor {

    constructor(private interceptorService: AuthTokenInterceptorService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.interceptorService.handleRequest(req, next);
    }
}
