'use strict';

(function () {
  applicationDecisionService.$inject = ["$http", "$q", "$translate", "localeService", "applicationDecisionJSDataModel", "attainmentJSDataModel", "studentApplicationJSDataModel", "studentApplicationEndpoint", "applicationDecisionEndpoint", "STUDENT_APPLICATION_TYPE", "$log", "APPLICATION_DECISION_TYPE"];
  angular.module('sis-components.service.applicationDecisionService', ['sis-common.l10n.localeService', 'sis-common.errorhandler.errorService', 'sis-components.model.applicationDecision', 'sis-components.model.attainment', 'sis-components.model.studentApplication']).factory('commonApplicationDecisionService', applicationDecisionService);

  /**
   * @ngInject
   */
  function applicationDecisionService($http, $q, $translate, localeService,
  // NOSONAR
  applicationDecisionJSDataModel, attainmentJSDataModel, studentApplicationJSDataModel, studentApplicationEndpoint, applicationDecisionEndpoint, STUDENT_APPLICATION_TYPE, $log, APPLICATION_DECISION_TYPE) {
    return {
      findByApplicationId: function (studentApplicationId) {
        if (!_.isString(studentApplicationId) || _.isEmpty(studentApplicationId)) {
          return $q.when();
        }
        var path = getBasePath(studentApplicationId);
        return $http.get(path).then(injectToStore);
      },
      findByAttainmentId: function (attainmentId) {
        if (!_.isString(attainmentId) || _.isEmpty(attainmentId)) {
          return $q.when();
        }
        var path = getBasePathByAttainmentId(attainmentId);
        return $http.get(path).then(injectToStore);
      },
      save: function (decision) {
        if (_.isNil(decision.id)) {
          return $http.post(getBasePath(decision.applicationId), decision).then(injectToStore);
        } else {
          return $http.put(getBasePath(decision.applicationId), decision).then(injectToStore);
        }
      },
      approve: function (application, decision, attainment) {
        decision.type = resolveDecisionType(application);
        if (!!decision.approvalDate) {
          // Note, this was a conscious decision. Due to validation, we must have a date in a decision.
          // Importing decisions require this validation. This is kinda dirty, but ...
          decision.approvalDate = '1970-01-01';
        }
        var wrapper = {
          decision: decision,
          attainment: attainment
        };
        return $http.post(getBasePath(decision.applicationId) + '/approval', wrapper).then(function (result) {
          studentApplicationJSDataModel.inject(result.data.application);
          applicationDecisionJSDataModel.inject(result.data.decision);
          if (!_.isNil(result.data.attainment)) {
            attainmentJSDataModel.inject(result.data.attainment);
          }
          return result.data;
        });
      },
      conditionallyApprove: function (application, decision) {
        decision.type = resolveDecisionType(application);

        // Note, this was a conscious decision. Due to validation, we must have a date in a decision.
        // Importing decisions require this validation. This is kinda dirty, but ...
        decision.approvalDate = '1970-01-01';
        return $http.post(getBasePath(decision.applicationId) + '/approval/conditional', decision).then(function (result) {
          studentApplicationJSDataModel.inject(result.data.application);
          applicationDecisionJSDataModel.inject(result.data.decision);
          if (!_.isNil(result.data.attainment)) {
            attainmentJSDataModel.inject(result.data.attainment);
          }
          return result.data;
        });
      },
      reject: function (application, decision) {
        decision.type = resolveDecisionType(application);

        // Note, this was a conscious decision. Due to validation, we must have a date in a decision.
        // Importing decisions require this validation. This is kinda dirty, but ...
        decision.approvalDate = '1970-01-01';
        var wrapper = {
          decision: decision
        };
        return $http.post(getBasePath(decision.applicationId) + '/rejection', wrapper).then(function (result) {
          studentApplicationJSDataModel.inject(result.data.application);
          applicationDecisionJSDataModel.inject(result.data.decision);
          return result.data;
        });
      },
      revokeAcceptance: function (applicationId, revokeApplicationAcceptanceRequest) {
        return $http.post(`${getBasePath(applicationId)}/revoke-acceptance`, revokeApplicationAcceptanceRequest).then(result => {
          studentApplicationJSDataModel.inject(result.data);
          return result.data;
        });
      }
    };
    function getBasePath(studentApplicationId) {
      return [_.trimEnd(studentApplicationEndpoint.DEFAULT, '/'), studentApplicationId, _.trimStart(applicationDecisionEndpoint.DEFAULT, '/')].join('/');
    }
    function getBasePathByAttainmentId(attainmentId) {
      return [_.trimEnd(studentApplicationEndpoint.DEFAULT, '/'), _.trimStart(applicationDecisionEndpoint.BY_ATTAINMENT_ID, '/'), attainmentId].join('/');
    }
    function injectToStore(response) {
      if (_.isObject(_.get(response, 'data'))) {
        return applicationDecisionJSDataModel.inject(response.data);
      } else {
        return $q.when();
      }
    }
    function resolveDecisionType(application) {
      if (application.type === STUDENT_APPLICATION_TYPE.PRIOR_LEARNING_INCLUSION_APPLICATION) {
        return APPLICATION_DECISION_TYPE.ATTAINMENT_APPLICATION_DECISION;
      } else if (application.type === STUDENT_APPLICATION_TYPE.PRIOR_LEARNING_SUBSTITUTION_APPLICATION) {
        return APPLICATION_DECISION_TYPE.ATTAINMENT_APPLICATION_DECISION;
      } else if (application.type === STUDENT_APPLICATION_TYPE.CUSTOM_ATTAINMENT_APPLICATION) {
        return APPLICATION_DECISION_TYPE.ATTAINMENT_APPLICATION_DECISION;
      } else if (application.type === STUDENT_APPLICATION_TYPE.CUSTOM_MODULE_CONTENT_APPLICATION) {
        return APPLICATION_DECISION_TYPE.MODULE_CONTENT_APPLICATION_DECISION;
      } else if (application.type === STUDENT_APPLICATION_TYPE.REQUIRED_MODULE_CONTENT_APPLICATION) {
        return APPLICATION_DECISION_TYPE.MODULE_CONTENT_APPLICATION_DECISION;
      } else if (application.type === STUDENT_APPLICATION_TYPE.STUDY_RIGHT_EXTENSION_APPLICATION) {
        return APPLICATION_DECISION_TYPE.STUDY_RIGHT_EXTENSION_APPLICATION_DECISION;
      } else {
        throw 'Unsupported application type: ' + application.type;
      }
    }
  }
})();