<div>
    <div class="rule-header">
        <sis-course-unit-count-phrase
            [rule]="courseUnitCountRule"
            [ruleValidationResult]="ruleValidationResult">

        </sis-course-unit-count-phrase>
    </div>
    <div>
        <sis-rule-select
                [rule]="courseUnitCountRule.rule"
                [parentModule]="parentModule"
                [validatablePlan]="validatablePlan"
                [planValidationResult]="planValidationResult"
                [blockLevel]="blockLevel"
                [planStateObject]="planStateObject"
                [planData]="planData"
                [uiStateObject]="uiStateObject">
        </sis-rule-select>
    </div>
</div>