locationModel.$inject = ["DS"];
import * as angular from 'angular';
export const locationModelModule = 'sis-components.model.location';
angular.module(locationModelModule, ['js-data']).factory('locationJSDataModel', locationModel);

/**
 * @ngInject
 */
function locationModel(DS) {
  return DS.defineResource({
    idAttribute: 'id',
    name: 'location',
    endpoint: '/kori/api/locations'
  });
}