import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ExpectedPermissionsResponse } from 'common-typescript/types';
import { omit } from 'lodash';
import { EMPTY, Observable, of, OperatorFunction } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';
import { fromError, StackFrame } from 'stacktrace-js';

import { AlertsService, AlertType } from '../alerts/alerts-ng.service';
import { CommonCodeService, IndexedCodes } from '../service/common-code.service';

import { accessDeniedModalOpener } from './access-denied-modal/access-denied-modal.component';
import { LogService } from './log.service';
import { systemErrorModalOpener, SystemErrorModalValues } from './system-error-modal/system-error-modal.component';
import { isAccessDeniedResponse, isConstraintViolationExplanation, isSisuLocalizedException } from './type-guards';
import { ValidationErrorHandlerService } from './validation-error-handler.service';
import { validationErrorModalOpener } from './validation-error-modal/validation-error-modal.component';

@Injectable({ providedIn: 'root' })
@StaticMembers<DowngradedService>()
export class AppErrorHandler implements ErrorHandler {

    static downgrade: ServiceDowngradeMappings = {
        moduleName: 'sis-components.error-handler.appErrorHandler.downgraded',
        serviceName: 'appErrorHandler',
    };

    private readonly openAccessDeniedModal = accessDeniedModalOpener();
    private readonly openSystemErrorModal = systemErrorModalOpener();
    private readonly openValidationErrorModal = validationErrorModalOpener();

    private isSystemErrorModalOpen = false;

    constructor(
        @Inject(DOCUMENT) private readonly document: Document,
        private readonly logService: LogService,
        private readonly alertService: AlertsService,
        private readonly codeService: CommonCodeService,
        private readonly translate: TranslateService,
        private readonly validationErrorHandler: ValidationErrorHandlerService,
    ) {
        this.logService = logService;
        this.alertService = alertService;
        this.codeService = codeService;
        this.document = document;
        this.translate = translate;
        this.validationErrorHandler = validationErrorHandler;
    }

    /**
     * Global Angular error handler. All unhandled errors in the Angular part of the application will be caught here.
     * Logs all errors to the backend, and shows a system error modal with the error details to the user.
     */
    async handleError(error: any): Promise<void> {
        this.logService.critical('Angular', { message: 'COMMON.SYSTEM_ERROR', ...this.getCommonLogFields() }, error);

        let stacktrace: string;
        let errorDetails: string;
        if (error instanceof Error) {
            const stackFrames = await this.parseStackFrames(error);
            stacktrace = stackFrames
                .map(frame => `${frame.functionName} (${frame.fileName}:${frame.lineNumber}:${frame.columnNumber})`)
                .join('\n   ');
            errorDetails = error.message;
        } else if (typeof error === 'string') {
            errorDetails = error;
        }

        this.openSystemErrorModalUnlessAlreadyOpen({
            errorDetails,
            stacktrace,
            title: this.translate.instant('COMMON.SYSTEM_ERROR'),
            userAgent: this.document.defaultView.navigator.userAgent,
        });
    }

    /**
     * Catches all errors in the observable and handles them. If an error occurs, the observable will close without emitting anything.
     *
     * Different types of errors are handled differently:
     *
     * - HTTP 401, 404 and 419 errors are ignored
     * - HTTP 403 results in either an alert or an access denied modal
     * - HTTP 422 results in a validation error modal
     * - HTTP 409 and 423 result in a system error modal
     * - All other HTTP errors are logged to the backend and result in a system error modal
     * - All JS/TS errors are logged to the backend and result in a system error modal
     */
    defaultErrorHandler<T>(): OperatorFunction<T, T>;

    /**
     * A variation of {@link defaultErrorHandler} that emits a value after an error has occurred. The observable will close after
     * emitting the value.
     *
     * @param emitOnError The value to emit after an error occurred. `null` is acceptable, `undefined` is not. Useful e.g. when
     * needing to update some component state to a sensible value as a result of an error, to prevent broken/stale UI state.
     */
    defaultErrorHandler<T, R>(emitOnError?: R): OperatorFunction<T, R>;

    defaultErrorHandler<T, R>(emitOnError?: R): OperatorFunction<T, T | R> {
        return catchError<T, Observable<R | never>>((err: unknown) => {
            const result = emitOnError !== undefined ? of(emitOnError) : EMPTY;
            if (!err) {
                return result;
            }

            if (err instanceof HttpErrorResponse) {
                this.handleErrorResponse(err);
            } else {
                this.handleError(err);
            }

            return result;
        });
    }

    private handleErrorResponse(response: HttpErrorResponse): void {
        const { error } = response;
        switch (response.status) {
            case -1: // Canceled or timed out
            case 0: // Unknown error
            case 401: // Unauthorized
            case 404: // Not Found
            case 419: // Authentication Timeout
                // Ignore errors with any of the above statuses
                return;

            case 403: // Forbidden
                this.handleForbidden(error);
                break;

            case 422: // Unprocessable Content (i.e. validation error)
                this.handleUnprocessableContent(error);
                break;

            case 409: // Conflict
            case 423: // Locked
                this.handleSystemError(response, `ERROR.BACKEND.${response.status}.TITLE`, `ERROR.BACKEND.${response.status}.MESSAGE`);
                break;

            default:
                this.logErrorResponse(response);
                this.handleSystemError(response, 'ERROR.BACKEND.DEFAULT.TITLE', `ERROR.BACKEND.${response.status}.MESSAGE`);
        }
    }

    private handleForbidden(error: unknown): void {
        if (isAccessDeniedResponse(error)) {
            switch (error.accessDeniedType) {
                case 'NO_LOGIN_TIME_WINDOW':
                    this.alertService.addTemporaryAlert({
                        type: AlertType.DANGER,
                        message: this.translate.instant('SIS_COMPONENTS.ACCESS_DENIED.NO_LOGIN_TIME_WINDOW'),
                    });
                    break;
                case 'USER_NOT_FOUND':
                    this.alertService.addTemporaryAlert({
                        type: AlertType.DANGER,
                        message: this.translate.instant('SIS_COMPONENTS.ACCESS_DENIED.USER_NOT_FOUND'),
                    });
                    break;
                case 'EXPECTED_PERMISSIONS':
                    this.handleExpectedPermissionsResponse(error as ExpectedPermissionsResponse);
                    break;
                case 'DEFAULT':
                case 'MISSING_ROLE':
                default:
                    this.showDefaultAccessDeniedAlert();
            }
        } else {
            this.showDefaultAccessDeniedAlert();
        }
    }

    private handleExpectedPermissionsResponse(error: ExpectedPermissionsResponse): void {
        const { expectedPermissions: expectedPermissionUrns } = error;
        if (expectedPermissionUrns?.length > 0) {
            const fallbackName = (permissionUrn: string) => ({ fi: permissionUrn, en: permissionUrn, sv: permissionUrn });
            this.codeService.getCodebookObservable('urn:code:permission')
                .pipe(
                    catchError(() => of({} as IndexedCodes)),
                    map(codes => expectedPermissionUrns.map(({ urn }) => codes[urn]?.name ?? fallbackName(urn))),
                    take(1),
                )
                .subscribe(expectedPermissions => this.openAccessDeniedModal({ expectedPermissions }));
        } else {
            this.showDefaultAccessDeniedAlert();
        }
    }

    private handleUnprocessableContent(error: unknown): void {
        // NOTE: The translation keys here are not scoped, so they won't work with Transloco
        if (isSisuLocalizedException(error)) {
            this.openValidationErrorModal({
                titleKey: error.titleKey,
                messageKey: error.messageKey,
            });
        } else {
            this.openValidationErrorModal({
                titleKey: 'ERROR.BACKEND.CONSTRAINT_VIOLATION_TITLE',
                validationErrors: isConstraintViolationExplanation(error) ? this.validationErrorHandler.parseViolations(error) : null,
            });
        }
    }

    private handleSystemError(response: HttpErrorResponse, defaultTitleKey: string, defaultMessageKey: string): void {
        let title;
        let message;
        const { error } = response;
        if (isSisuLocalizedException(error)) {
            title = this.translate.instant(error.titleKey ?? defaultTitleKey);
            message = error.messageKey ?
                this.translateIfExists(error.messageKey, { value: error.messageValue }) : this.translateIfExists(defaultMessageKey);
        } else {
            title = this.translate.instant(defaultTitleKey);
            message = this.translateIfExists(defaultMessageKey) ?? error?.message ?? response.statusText;
        }

        this.openSystemErrorModalUnlessAlreadyOpen({
            title,
            message,
            errorCode: response.status,
            errorDetails: typeof error === 'object' ? JSON.stringify(error) : error.toString(),
            userAgent: this.document.defaultView.navigator.userAgent,
            stacktrace: `URL: ${response.url}`,
        });
    }

    private getCommonLogFields() {
        return {
            href: this.document.defaultView.location.href,
            userAgent: this.document.defaultView.navigator.userAgent,
        };
    }

    private logErrorResponse(response: HttpErrorResponse): void {
        const logObject = {
            ...response,
            ...this.getCommonLogFields(),
        };
        // The headers can contain authorization tokens, don't log them
        this.logService.error('ResponseError', omit(logObject, 'headers'));
    }

    private openSystemErrorModalUnlessAlreadyOpen(values: SystemErrorModalValues): void {
        if (!this.isSystemErrorModalOpen) {
            this.isSystemErrorModalOpen = true;
            this.openSystemErrorModal(values).hidden.subscribe(() => this.isSystemErrorModalOpen = false);
        }
    }

    /**
     * Separate method to allow mocking in tests
     */
    private parseStackFrames(error: Error): Promise<StackFrame[]> {
        return fromError(error);
    }

    private showDefaultAccessDeniedAlert(): void {
        this.alertService.addTemporaryAlert({
            type: AlertType.DANGER,
            message: this.translate.instant('SIS_COMPONENTS.ACCESS_DENIED.ALERT_DEFAULT'),
        });
    }

    private translateIfExists(key: string, params?: Object): string | null {
        const translation = !!key ? this.translate.instant(key, params) : key;
        return translation === key ? null : translation;
    }
}
