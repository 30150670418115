'use strict';

(function () {
  completionMethodRepeatTexts.$inject = ["$filter"];
  angular.module('student.common.components.courseUnitInfoModal.repeatTexts', ['sis-common.l10n.localizedStringFilter']).filter('completionMethodRepeatTexts', completionMethodRepeatTexts);

  /**
   * Join completion method repeat texts to a readable string.
   */
  function completionMethodRepeatTexts($filter) {
    return function (repeatTexts) {
      return _.map(repeatTexts, function (repeatText) {
        return _.map(repeatText, function (obj) {
          return $filter('localizedString')(obj.name);
        }).join(' - ');
      }).join(', ');
    };
  }
})();