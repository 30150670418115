import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { StudySubGroup } from 'common-typescript/types';

@Component({
    selector: 'app-study-sub-group-view',
    templateUrl: './study-sub-group-view.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudySubGroupViewComponent {

    @Input({ required: true })
        studySubGroup: StudySubGroup;

    @Input({ required: true }) style: 'SELECTED' | 'NOT_SELECTED';

}
