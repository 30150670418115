<ng-container *transloco="let t">
  <fudis-dialog [size]="'sm'">
    <fudis-heading [level]="2">{{ title }}</fudis-heading>
    <fudis-dialog-content>
      <sis-localized-markup-string-view *ngIf="contentHtml" [content]="contentHtml" />
      <ng-container *ngIf="contentTranslateKey">
        <transloco [key]="contentTranslateKey" [params]="contentTranslateParameters"></transloco>
      </ng-container>
    </fudis-dialog-content>
  </fudis-dialog>
</ng-container>
