import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CourseUnit, CourseUnitRealisation, Education, Enrolment, StudyRight } from 'common-typescript/types';
import { ModalService } from 'sis-common/modal/modal.service';

export interface CancelOrAbortEnrolmentValues {
    abort: boolean;
    enrolment: Enrolment;
    courseUnit: CourseUnit;
    courseUnitRealisation: CourseUnitRealisation;
    myStudyRightEducations: Education[];
    myStudyRights: StudyRight[];
}

@Component({
    selector: 'app-cancel-or-abort-enrolment-modal',
    template: '<cancel-or-abort-enrolment-modal (close)="activeModal.close($event.$value)" (dismiss)="activeModal.dismiss()" [resolve]="values"></cancel-or-abort-enrolment-modal>',
    encapsulation: ViewEncapsulation.None,
})
export class CancelOrAbortEnrolmentModalComponent {
    constructor(@Inject(ModalService.injectionToken) public values: CancelOrAbortEnrolmentValues,
                public activeModal: NgbActiveModal) {}
}
