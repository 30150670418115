<div class="container sis-pt-md">
  <sis-page-title [title]="'OPEN_UNIVERSITY.STUDIES_ACTIVATE_ERROR.TITLE' | translate"/>
  <div>
    {{ "OPEN_UNIVERSITY.STUDIES_ACTIVATE_ERROR.DESCRIPTION" | translate }}<span *ngIf="!contactLinkObject">.</span>
    <a *ngIf="contactLinkObject?.isEmail; else notEmailLink" [href]="'mailto:' + contactLinkObject?.link">
      ({{ contactLinkObject?.link }}).</a>
    <ng-template #notEmailLink>
      <a *ngIf="contactLinkObject?.contactLinkText" [href]="contactLinkObject?.link">
        ({{ contactLinkObject?.contactLinkText }}).</a>
    </ng-template>
  </div>
</div>
