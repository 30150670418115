import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { OtmId, Plan } from 'common-typescript/types';
import { SelectOption } from 'sis-components/select/select-combobox/select-combobox.component';

@Component({
    selector: 'app-plan-header',
    templateUrl: './plan-header.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanHeaderComponent {

    @Input() planSelectionOptions: SelectOption[];
    @Input() currentPlan: Plan;

    @Output() selectPlan = new EventEmitter<OtmId>();
}
