import { Injectable } from '@angular/core';
import { TranslocoLoader } from '@ngneat/transloco';
import { TranslocoLoaderHelper } from 'sis-common/transloco/transloco-loader-helper';

/**
 * Load frontend-specific translation files.
 */
@Injectable({ providedIn: 'root' })
export class TranslocoStudentLoader implements TranslocoLoader {
    getTranslation(scopedLanguage: string) {
        const language = TranslocoLoaderHelper.getLanguage(scopedLanguage);
        const base = import(`../translate/${language}.json`);
        const scoped = import(`../translate/${language}.vendor.json`);
        return TranslocoLoaderHelper.joinTranslations([base, scoped]);
    }
}
