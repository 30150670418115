<input
  type="text"
  data-cy="input-with-debounce"
  [name]="name"
  [(ngModel)]="value"
  (ngModelChange)="onValueChange($event)"
  [className]="classes"
  placeholder="{{placeholder}}"
  (keyup)="checkSearchLength($event)"
>
<sis-validation-errors [validationErrors]="validationErrors"></sis-validation-errors>
