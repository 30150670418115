function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import _ from 'lodash';
import angularTranslate from 'angular-translate';
import { ModalService } from 'sis-common/modal/modal.service.ts';
import { alertsServiceModule } from '../../alerts/alerts.service';
import { AccessDeniedModalComponent } from '../access-denied-modal/access-denied-modal.component.ts';
import { SystemErrorModalComponent } from '../system-error-modal/system-error-modal.component.ts';
import { ValidationErrorHandlerService } from '../validation-error-handler.service.ts';
import { ValidationErrorModalComponent } from '../validation-error-modal/validation-error-modal.component.ts';
import { globalErrorHandlerLoggerName } from '../log.service.ts';
import { logModule } from '../log.module.ts';
export const errorServiceModule = 'sis-common.errorhandler.errorService';
'use strict';
(function () {
  setGlobalExceptionHandlers.$inject = ["$window"];
  configInterceptor.$inject = ["$httpProvider"];
  ExceptionHandler.$inject = ["$log", "errorService"];
  defaultPromiseHandler.$inject = ["$log"];
  ErrorService.$inject = ["$injector"];
  ErrorServiceImpl.$inject = ["modalService", "logService", "$window", "$log", "$q", "$translate", "defaultPromiseHandler"];
  RequestErrorInterceptor.$inject = ["$q", "$injector", "$timeout", "$window", "errorService", "logService", "$translate", "alertsService"];
  angular.module(errorServiceModule, ['pascalprecht.translate', logModule, alertsServiceModule, ModalService.downgrade.moduleName, angularTranslate, ValidationErrorHandlerService.downgrade.moduleName]).factory('RequestErrorInterceptor', RequestErrorInterceptor).factory('errorServiceImpl', ErrorServiceImpl).factory('errorService', ErrorService).factory('defaultPromiseHandler', defaultPromiseHandler).factory('appExceptionHandler', ExceptionHandler).config(["$provide", $provide => {
    // Extend angular's default exceptionHandler
    $provide.decorator('$exceptionHandler', ['$delegate', 'appExceptionHandler', function ($delegate, appExceptionHandler) {
      return function (exception, cause) {
        appExceptionHandler(exception, cause);
        $delegate(exception, cause);
      };
    }]);
  }]).config(configInterceptor).run(setGlobalExceptionHandlers);
  function setGlobalExceptionHandlers($window) {
    // Both handlers adapted from https://github.com/mperdeck/jsnlog.js/blob/7bbdc259da3206950e28a01e2f8fe8756e7b6f6c/jsnlog.ts
    $window.onerror = (errorMsg, url, lineNumber, column, errorObj) => {
      // Use errorMsg.message if available, so Angular 4 template errors will be logged.
      // See https://github.com/mperdeck/jsnlog.js/pull/68
      const errorMessage = errorMsg ? errorMsg.message || errorMsg : '';
      const logObject = {
        msg: 'Uncaught Exception',
        errorMsg: errorMessage,
        url,
        'line number': lineNumber,
        column
      };
      if (errorMessage.includes('AbortError') || errorMessage.includes('419')) {
        // This appears to come mainly from Yaffle EventSource errors which can be safely ignored
        return true;
      }
      JL(globalErrorHandlerLoggerName).fatalException(addCommonErrorLogFields($window, logObject), errorObj);

      // Tell browser to run its own error handler as well
      return false;
    };

    // Deal with unhandled exceptions thrown in promises
    $window.onunhandledrejection = event => {
      // Need to check both event.reason.message and event.message,
      // because SystemJs wraps exceptions and throws a new object which doesn't have a reason property.
      // See https://github.com/systemjs/systemjs/issues/1309

      const errorMessage = event.reason ? event.reason.message : event.message || null;
      const logObject = {
        msg: 'Unhandled Rejection',
        errorMsg: errorMessage
      };
      // 20 - abort error, see: https://developer.mozilla.org/en-US/docs/Web/API/DOMException
      if (event.reason && event.reason.code === 20) {
        return true;
      }
      if (errorMessage.includes('419')) {
        // This appears to come mainly from Yaffle EventSource errors which can be safely ignored
        return true;
      }
      JL(globalErrorHandlerLoggerName).fatalException(addCommonErrorLogFields($window, logObject), event.reason);
    };
  }

  /**
   * @ngInject
   */
  function defaultPromiseHandler($log) {
    return {
      loggingRejectedPromiseHandler: function (error) {
        if (!_.isNil(error) && error !== 0) {
          $log.error(error);
        }
      },
      ignoringRejectedPromiseHandler: angular.noop,
      log: function (err) {
        $log.error('Rejection', err);
      }
    };
  }
  function configInterceptor($httpProvider) {
    $httpProvider.interceptors.push('RequestErrorInterceptor');
  }
  function ExceptionHandler($log, errorService) {
    return function (exception, cause) {
      $log.error.apply($log, arguments); // Pass off the error to default handler as well

      /* Avoid setting the cause property on exceptions of string type, because that causes a readonly property
       * error. */
      if (typeof exception !== 'string') {
        exception.cause = cause;
      }
      errorService.sendSystemError(exception);
      errorService.showSystemError(exception);
    };
  }

  // why do we have ErrorService separate from ErrorServiceImpl?
  // because of crossdependencies
  function ErrorService($injector) {
    return {
      assert: function (condition, message) {
        const impl = $injector.get('errorServiceImpl');
        impl.assert(condition, message);
      },
      assertDefined: function (variable) {
        const impl = $injector.get('errorServiceImpl');
        impl.assertDefined(variable);
      },
      showTranslatedError: function (translatedError) {
        const impl = $injector.get('errorServiceImpl');
        impl.showTranslatedError(translatedError);
      },
      showSystemError: function (error, onlyForCode, reloadOnClose = false) {
        $injector.get('errorServiceImpl').showError(error, error.titleKey || 'COMMON.SYSTEM_ERROR', reloadOnClose, onlyForCode);
      },
      sendSystemError: function (error) {
        const impl = $injector.get('errorServiceImpl');
        return impl.sendError(error, 'COMMON.SYSTEM_ERROR');
      }
    };
  }
  function ErrorServiceImpl(modalService, logService, $window, $log, $q, $translate, defaultPromiseHandler) {
    let dialogCounter = 0;
    function assert(condition, message) {
      if (!condition) {
        const throwError = true; // you can set this to false in debugger if you wish to examine scopes while stepping out
        message = message || 'Assertion failed';
        if (typeof Error !== 'undefined') {
          /* jshint -W087 */
          debugger; // NOSONAR
          if (throwError) {
            throw new Error(message);
          }
        }
        /* jshint -W087 */
        debugger; // NOSONAR
        if (throwError) {
          throw message; // Fallback
        }
      }
    }
    function assertDefined(variable) {
      assert(variable !== null, 'Variable must not be NULL');
      assert(typeof variable !== 'undefined', 'Variable must be defined');
    }
    function asJson(message) {
      return message ? angular.toJson(message).replace(/^"(.*)"$/g, '$1') : '-';
    }
    return {
      assert,
      assertDefined,
      // Custom look for translated errors
      showTranslatedError: function (modalValues) {
        modalService.open(ValidationErrorModalComponent, modalValues, {
          size: 'sm'
        });
      },
      // Accepts http responses and exceptions.
      showError: function (error, errorTitleKey, reloadOnClose, onlyForCode) {
        let errorCode;
        let errorMessage;
        let errorStackTrace;
        const errorDetails = [];
        if (dialogCounter > 0) {
          return;
        }
        dialogCounter += 1;
        if (errorTitleKey === null) {
          errorTitleKey = 'COMMON.SYSTEM_ERROR';
        }
        const promises = [];
        if (error && _.isObject(error)) {
          // httpResponse
          if (_.has(error, 'status')) {
            errorCode = error.status;
            if (onlyForCode && error.status !== onlyForCode) {
              return;
            }
          }
          if (_.has(error, 'statusText')) {
            errorMessage = error.statusText;
          }
          if (_.has(error, 'data')) {
            if (_.isObject(error.data) && _.has(error.data, 'messages')) {
              errorDetails.push(`Server returned error: ${_.map(error.data.messages, 'text').join(' ')}`);
            } else {
              errorDetails.push(error.data);
            }
          }
          // Angular HttpErrorResponses have an 'error' property instead of 'data', with similar content
          if (_.has(error, 'error')) {
            errorDetails.push(error.error);
          }

          // Angular HttpErrorResponses have a top-level 'url' property instead of 'config.url'
          if (_.has(error, 'config.url') || _.has(error, 'url')) {
            const url = error.config?.url || error.url;
            errorStackTrace = `URL: ${url}`;
          }

          // our own errors
          if (_.has(error, 'errorCode')) {
            errorCode = error.errorCode;
          }
          if (_.has(error, 'errorMessage')) {
            errorMessage = error.errorMessage;
          } else if (_.has(error, 'data.message')) {
            errorMessage = error.data.message;
          }
          if (_.has(error, 'errorDetails')) {
            errorDetails.push(error.errorDetails);
          }
          if (_.has(error, 'errorStackTrace') || _.has(error, 'stack')) {
            if (typeof window.__karma__ !== 'undefined') {
              // At least dump the error to console when running with karma
              $log.error(error);
            }

            // 'StackTrace' is a global object from the library stacktrace-js
            const promise = StackTrace.fromError(error);
            promises.push(promise.then(stackTrace => {
              if (_.isArray(stackTrace)) {
                errorStackTrace = _.join(_.map(stackTrace, ste => `${ste.functionName} (${ste.fileName}:${ste.lineNumber}:${ste.columnNumber})`), '\n   ');
              } else {
                errorStackTrace = stackTrace;
              }
            }));
          }

          // Exception
          if (_.has(error, 'message')) {
            errorDetails.push(error.message);
          }
          if (_.has(error, 'cause')) {
            errorDetails.push(error.cause);
          }
        } else {
          errorDetails.push(error);
        }
        $q.all(promises).then(() => {
          modalService.open(SystemErrorModalComponent, {
            title: $translate.instant(errorTitleKey),
            errorCode,
            message: asJson(errorMessage),
            errorDetails: asJson(_.isArray(errorDetails) ? _.chain(errorDetails).compact().map(angular.toJson).join('\n--') : angular.toJson(errorDetails)),
            stacktrace: errorStackTrace,
            userAgent: $window.navigator.userAgent
          }, {
            size: 'sm'
          }).result.then(() => {
            dialogCounter -= 1;
            errorDialogCloseHandler();
          }).catch(() => {
            dialogCounter -= 1;
            errorDialogCloseHandler();
          }).then(() => {
            if (reloadOnClose) {
              $window.location.reload();
            }
          }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
        });
      },
      sendError: function (error, errorTitleKey) {
        try {
          // Log the JavaScript error to the server, unless we're running within a jasmine unit test
          if (window.currentSpec === undefined) {
            logService.critical('Angular', addCommonErrorLogFields($window, {
              message: errorTitleKey
            }), error);
          }
        } catch (loggingError) {
          // For Developers - log the log-failure.
          $log.warn('Error logging failed');
          $log.log(loggingError);
        }
      }
    };
  }
  function RequestErrorInterceptor($q, $injector, $timeout, $window, errorService, logService, $translate, alertsService // NOSONAR
  ) {
    const errorsToBypass = []; // {status:'<HttpStatusCode>', url: '<UrlPathContainedByAHttpRequest>'}

    function addMessage(response, code) {
      if (response) {
        if (_.isEmpty(_.get(response, 'data.titleKey'))) {
          const titleKey = `ERROR.BACKEND.${code}.TITLE`;
          response.titleKey = titleKey;
        } else {
          response.titleKey = response.data.titleKey;
        }
        if (_.isEmpty(_.get(response, 'data.messageKey'))) {
          const msgKey = `ERROR.BACKEND.${response.status}.MESSAGE`;
          const msg = $translate.instant(msgKey);
          if (msg !== msgKey) {
            // do we have a translation for the message
            response.errorMessage = msg;
          }
        } else if (_.isEmpty(_.get(response, 'data.messageValue'))) {
          response.errorMessage = $translate.instant(response.data.messageKey);
        } else {
          response.errorMessage = $translate.instant(response.data.messageKey, {
            value: response.data.messageValue
          });
        }
      }
    }
    function handleConstraintViolation(errorResponse) {
      const modalValues = {
        titleKey: _.get(errorResponse, 'data.titleKey') || 'ERROR.BACKEND.CONSTRAINT_VIOLATION_TITLE',
        messageKey: _.get(errorResponse, 'data.messageKey') || 'ERROR.BACKEND.CONSTRAINT_VIOLATION',
        // Use $injector to access the validationErrorHandler to achieve "lazy-loading" of the Angular dependency.
        // Injecting it as a function argument would cause the hybrid app initialization to fail during runtime,
        // as the RequestErrorInterceptor is initialized before any Angular modules are bootstrapped.
        validationErrors: $injector.get('validationErrorHandler')?.parseViolations(_.get(errorResponse, 'data'))
      };
      return $q.reject(errorResponse).finally(() => {
        $timeout(() => {
          if (!errorResponse.errorHandled) {
            errorResponse.errorHandled = true;
            errorService.showTranslatedError(modalValues);
          }
        });
      });
    }
    function removeHeadersFromLog(args) {
      if (_.has(args, 'config.headers')) {
        delete args.config.headers;
      }
      if (_.has(args, 'headers')) {
        delete args.headers;
      }
      return args;
    }
    const fallbackName = permissionUrn => ({
      fi: permissionUrn,
      en: permissionUrn,
      sv: permissionUrn
    });
    const defaultAccessDeniedAlert = () => {
      alertsService.error($translate.instant('SIS_COMPONENTS.ACCESS_DENIED.ALERT_DEFAULT'), 10000);
    };
    const showAccessDeniedAlert = expectedPermissions => {
      if (_.size(expectedPermissions) > 0) {
        $injector.get(ModalService.downgrade.serviceName).open(AccessDeniedModalComponent, {
          expectedPermissions
        }, {
          size: 'sm',
          closeWithOutsideClick: true
        });
      } else {
        defaultAccessDeniedAlert();
      }
    };
    const createForbiddenAlert = data => {
      const $http = $injector.get('$http');
      switch (_.get(data, 'accessDeniedType')) {
        case 'NO_LOGIN_TIME_WINDOW':
          alertsService.error($translate.instant('SIS_COMPONENTS.ACCESS_DENIED.NO_LOGIN_TIME_WINDOW'), 10000);
          break;
        case 'USER_NOT_FOUND':
          alertsService.error($translate.instant('SIS_COMPONENTS.ACCESS_DENIED.USER_NOT_FOUND'), 10000);
          break;
        case 'EXPECTED_PERMISSIONS':
          $http.get('/kori/api/cached/codebooks/urn:code:permission').then(response => data.expectedPermissions?.map(({
            urn
          }) => response.data?.[urn]?.name ?? fallbackName(urn))).then(permissionUrns => showAccessDeniedAlert(permissionUrns)).catch(() => defaultAccessDeniedAlert());
          break;
        case 'MISSING_ROLE':
        default:
          defaultAccessDeniedAlert();
          break;
      }
    };
    return {
      /**
       * Add error from a http request to be bypassed (the system error dialog is suspended).
       * Usable for cases where showing the system error notification is more harmful than dealing silently with the error.
       * @param HttpStatusCode The http error code expected. (e.g. bad gateway, '502')
       * @param partialUrlPath A part of the url that was used with the request. (e.g. the service endpoint, '/unavailable/api/breaks')
       */
      addHttpErrorToBypass: function (HttpStatusCode, partialUrlPath) {
        if (!_.find(errorsToBypass, toBypass => toBypass.status === HttpStatusCode && toBypass.url === partialUrlPath)) {
          errorsToBypass.push({
            status: HttpStatusCode,
            url: partialUrlPath
          });
        }
      },
      responseError: function (errorResponse) {
        if (!errorResponse) {
          return $q.reject(errorResponse);
        }
        const skipError = _.find(errorsToBypass, errorToBypass => errorResponse.status === errorToBypass.status && _.includes(_.get(errorResponse, 'url'), errorToBypass.url));
        if (skipError) {
          return $q.reject(errorResponse);
        }

        // Angular HttpErrorResponses have an 'error' property instead of 'data', with similar content
        // inject error-object to errorResponse as data, if one does not exist
        if (!errorResponse.data && errorResponse.error) {
          errorResponse.data = errorResponse.error;
        }
        // Angular HttpErrorResponses don't have a 'config' property, simplify handling by providing an empty one
        if (!errorResponse.config) {
          errorResponse.config = {};
        }
        // Strip possible authorisation tokens from errorResponse
        errorResponse = removeHeadersFromLog(errorResponse);

        // In case bypassErrorInterceptor is set, fail quietly
        if (!_.isEmpty(errorResponse.config)) {
          const {
            config
          } = errorResponse;
          let bypassErrorInterceptor = false;
          if (_.has(config, 'bypassErrorInterceptor')) {
            bypassErrorInterceptor = config.bypassErrorInterceptor;
          } else if (_.has(config, 'data.bypassErrorInterceptor')) {
            bypassErrorInterceptor = config.data.bypassErrorInterceptor;
          }
          if (bypassErrorInterceptor === true || _.isFunction(bypassErrorInterceptor) && bypassErrorInterceptor(errorResponse) === true) {
            return $q.reject(errorResponse);
          }
        }
        switch (errorResponse.status) {
          // No error modal for certain backend errors
          case 0: // Unknown error, likely a connection error; in production, mainly from unavailable page
          case -1: // canceled or timed out -- should we try and differentiate btw. the two??? Show an error modal for timed out? Requests may get canceled on route changes.
          case 401: // unauthorized
          case 404: // not found
          case 419:
            // auth token expired
            return $q.reject(errorResponse);

          // Error modal shown for these errors with backend code specific localized error message
          case 423: // locked
          case 409:
            // conflict
            addMessage(errorResponse, errorResponse.status);
            break;
          case 403:
            // forbidden
            return $q.reject(errorResponse).finally(() => createForbiddenAlert(errorResponse.data));

          // Constraint validation errors have special handling
          case 422:
            // validation error
            return handleConstraintViolation(errorResponse);

          // Error modal with generic error message
          default:
            logService.error('ResponseError', addCommonErrorLogFields($window, errorResponse));
            addMessage(errorResponse, 'DEFAULT');
            break;
        }
        return $q.reject(errorResponse).finally(() => {
          $timeout(() => {
            if (!errorResponse.errorHandled) {
              errorResponse.errorHandled = true;
              errorService.showSystemError(errorResponse);
            }
          });
        });
      }
    };
  }
  function errorDialogCloseHandler() {
    // if we are closing a error dialog and there is an underlying modal, we need this.
    // otherwise the modal below the confirm will not be scrollable.
    window.setTimeout(() => {
      if ($('.modal').hasClass('in')) {
        $('body').addClass('modal-open');
      }
    }, 500);
  }
  function addCommonErrorLogFields($window, logObject) {
    return _objectSpread(_objectSpread({}, logObject), {}, {
      href: $window.location.href,
      userAgent: $window.navigator.userAgent
    });
  }
})();