import angular from 'angular';
export const studyFieldJSDataModelModule = 'sis-components.model.studyField';
(function () {
  initStudyFieldModel.$inject = ["studyFieldJSDataModel"];
  studyField.$inject = ["DS", "$http", "$log"];
  angular.module(studyFieldJSDataModelModule, ['js-data']).factory('studyFieldJSDataModel', studyField).run(initStudyFieldModel);

  /**
   * @ngInject
   */
  function studyField(DS, $http, $log) {
    var model = DS.defineResource({
      idAttribute: 'urn',
      name: 'studyField',
      endpoint: '/kori/api/cached/codebooks/urn:code:study-field',
      watchChanges: false
    });
    model.lazyGetAll = _.once(function () {
      return $http.get(model.endpoint).then(function (response) {
        model.inject(_.values(response.data));
        return model.getAll();
      }).catch(function (error) {
        $log.error(error);
      });
    });
    return model;
  }

  /**
   * @ngInject
   */
  function initStudyFieldModel(studyFieldJSDataModel) {
    studyFieldJSDataModel.lazyGetAll();
  }
})();