import creditPhraseTpl from './creditPhrase.tpl.html';
(function () {
  'use strict';

  CreditPhraseController.$inject = ["Range"];
  angular.module('student.plan.rules.header').component('creditPhrase', {
    template: creditPhraseTpl,
    bindings: {
      creditRule: '<',
      plannedRange: '&',
      targetCredits: '<',
      isOk: '&',
      moduleType: '<',
      level: '<'
    },
    controller: CreditPhraseController
  });
  function CreditPhraseController(Range) {
    var ctrl = this;
    ctrl.showRequired = showRequired;
    ctrl.showRemove = showRemove;
    ctrl.getRequireRange = getRequireRange;
    ctrl.getRemoveRange = getRemoveRange;
    ctrl.getSelectRange = getSelectRange;
    function getRequireRange() {
      var requiredMin;
      var requiredMax;
      if (isRootAndHasTargetCredits()) {
        requiredMin = ctrl.targetCredits.min;
        requiredMax = _.result(ctrl.targetCredits, 'max', null);
      } else {
        requiredMin = ctrl.creditRule.min;
        requiredMax = _.result(ctrl.creditRule, 'max', null);
      }
      var min = 0;
      var plannedMax = ctrl.plannedRange().max;
      if ((plannedMax || plannedMax === 0) && plannedMax < requiredMin) {
        min = requiredMin - plannedMax;
      }
      var max;
      var plannedMin = ctrl.plannedRange().min;
      if (requiredMax && requiredMax > plannedMin) {
        max = requiredMax - plannedMin;
      }
      return new Range(min, max);
    }
    function getRemoveRange() {
      var creditMin, creditMax;
      var plannedMin = ctrl.plannedRange().min;
      var plannedMax = ctrl.plannedRange().max;
      if (isRootAndHasTargetCredits()) {
        var targetCredits = ctrl.targetCredits;
        var targetMin = targetCredits.min;
        var targetMax = targetCredits.max;
        creditMin = targetMin === null ? plannedMin : targetMin;
        creditMax = targetMax === null ? plannedMin : targetMax;
      } else {
        creditMin = ctrl.creditRule.min === null ? plannedMin : ctrl.creditRule.min;
        creditMax = ctrl.creditRule.max === null ? plannedMin : ctrl.creditRule.max;
      }
      var min = 0;
      var max;
      if (plannedMin > creditMax) {
        min = plannedMin - creditMax;
      }
      if (plannedMax && plannedMax > creditMin) {
        max = plannedMax - creditMin;
      }
      return new Range(min, max);
    }
    function getSelectRange() {
      var range;
      if (isRootAndHasTargetCredits()) {
        range = new Range(ctrl.targetCredits.min, ctrl.targetCredits.max);
      } else {
        range = new Range(ctrl.creditRule.min, ctrl.creditRule.max);
      }
      return range;
    }
    function showRequired() {
      var creditMin;
      var plannedMin = ctrl.plannedRange().min;
      var plannedMax = ctrl.plannedRange().max;
      if (isRootAndHasTargetCredits()) {
        var targetMin = ctrl.targetCredits.min;
        creditMin = targetMin === null ? plannedMin : targetMin;
      } else {
        creditMin = ctrl.creditRule.min === null ? plannedMin : ctrl.creditRule.min;
      }
      return (plannedMax || plannedMax === 0) && plannedMax < creditMin;
    }
    function showRemove() {
      var creditMax;
      var plannedMin = ctrl.plannedRange().min;
      if (isRootAndHasTargetCredits()) {
        creditMax = ctrl.targetCredits.max === null ? plannedMin : ctrl.targetCredits.max;
      } else {
        creditMax = ctrl.creditRule.max === null ? plannedMin : ctrl.creditRule.max;
      }
      return creditMax < plannedMin;
    }
    function isRootAndHasTargetCredits() {
      return ctrl.level === 1 && !!ctrl.targetCredits;
    }
  }
})();