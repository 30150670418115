import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { EnrolmentQuestionnaire, OtmId } from 'common-typescript/types';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

import { EntityService } from './entity.service';

const CONFIG = {
    ENDPOINTS: {
        baseUrl: '/kori/api',
        endpoint: '/enrolment-questionnaires',

        byMassExamSessionId(massExamSessionId: OtmId): string {
            return `${this.baseUrl}${this.endpoint}/mass-exam-sessions/${massExamSessionId}`;
        },
        byCourseUnitRealisationId(courseUnitRealisationId: string) {
            return `${this.baseUrl}${this.endpoint}/${courseUnitRealisationId}`;
        },
    },
};

@StaticMembers<DowngradedService>()
@Injectable({
    providedIn: 'root',
})
@NgEntityServiceConfig({
    baseUrl: CONFIG.ENDPOINTS.baseUrl,
    resourceName: 'enrolment-questionnaires',
})
export class EnrolmentQuestionnaireEntityService extends EntityService<EnrolmentQuestionnaireState> {

    static downgrade: ServiceDowngradeMappings = {
        dependencies: [],
        moduleName: 'sis-components.service.enrolmentQuestionnaireEntityService',
        serviceName: 'enrolmentQuestionnaireEntityService',
    };

    constructor() {
        super(EnrolmentQuestionnaireStore, EnrolmentQuestionnaireQuery);
    }

    createForMassExamSessionExams<T>(massExamSessionId: OtmId, enrolmentQuestions: Partial<EnrolmentQuestionnaire>[]): Observable<T> {
        return this.getHttp().post<T>(CONFIG.ENDPOINTS.byMassExamSessionId(massExamSessionId), enrolmentQuestions)
            .pipe(
                tap(() => {
                    enrolmentQuestions.forEach((question) => {
                        // Remove from cache so next get reload changes
                        // We could also update the store here but backend does not return updated results
                        this.store.remove(question.courseUnitRealisationId);
                    });
                }),
            );
    }

    getByCourseUnitRealisationId(courseUnitRealisationId: string): Observable<EnrolmentQuestionnaire> {
        return this.getHttp().get<EnrolmentQuestionnaire>(CONFIG.ENDPOINTS.byCourseUnitRealisationId(courseUnitRealisationId));
    }
}

type EnrolmentQuestionnaireState = EntityState<EnrolmentQuestionnaire, OtmId>;

@StoreConfig({ name: 'enrolment-questionnaires', idKey: 'courseUnitRealisationId' })
class EnrolmentQuestionnaireStore extends EntityStore<EnrolmentQuestionnaireState> {}

class EnrolmentQuestionnaireQuery extends QueryEntity<EnrolmentQuestionnaireState> {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(store: EntityStore<EnrolmentQuestionnaireState>) {
        super(store);
    }
}
