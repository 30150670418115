import { ChangeDetectionStrategy, Component, inject, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FudisDialogService } from '@funidata/ngx-fudis';
import { CourseUnitRealisation } from 'common-typescript/types';

export function continueToEnrolmentModalOpener(): (courseUnitRealisation: CourseUnitRealisation) => MatDialogRef<ContinueToEnrolmentModalComponent> {
    const fudisDialogService = inject(FudisDialogService);
    return (courseUnitRealisation) => fudisDialogService.open(ContinueToEnrolmentModalComponent, { data: courseUnitRealisation });
}

@Component({
    selector: 'app-continue-to-enrolment-modal',
    templateUrl: './continue-to-enrolment-modal.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContinueToEnrolmentModalComponent {
    courseUnitRealisation: CourseUnitRealisation;
    constructor(
        @Inject(MAT_DIALOG_DATA) public selectedCourseUnitRealisation: CourseUnitRealisation,
        public dialogService: FudisDialogService,
    ) {
        this.courseUnitRealisation = selectedCourseUnitRealisation;
    }
}
