<ng-container *transloco="let t">
  <dl [class.text-right]="alignRight">
    <ng-container *ngIf="!showOnlySummary" >
      <dt>{{ t('OPEN_UNIVERSITY_CART.WIZARD.TEACHING.AMOUNT') }}</dt>
      <dd>{{ (cart.priceWithoutVat ?? zeroPrice) | money }}</dd>
      <dt>{{ t('OPEN_UNIVERSITY_CART.WIZARD.TEACHING.VAT', { percentage: cart.vatPercent ?? 0 }) }}</dt>
      <dd>+ {{ (cart.vatTotal ?? zeroPrice) | money }}</dd>
    </ng-container>
    <dt class="summary-text" data-cy="cart-price-label">{{ t('OPEN_UNIVERSITY_CART.WIZARD.TEACHING.PAYMENT_AMOUNT') }}</dt>
    <dd class="summary-text" data-cy="cart-price-text">{{ cart.priceTotal | money }}</dd>
  </dl>
</ng-container>
