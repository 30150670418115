import { Injectable } from '@angular/core';
import { toJson } from 'angular';
import {
    AssessmentItem,
    AssessmentItemAttainment,
    Attainment,
    AttainmentGroupNode,
    AttainmentNode,
    CourseUnit,
    CourseUnitAttainment,
    CustomCourseUnitAttainment,
    CustomModuleAttainment,
    DegreeProgrammeAttainment,
    Module,
    ModuleAttainment,
    OtmId,
} from 'common-typescript/types';
import { combineLatestWith, Observable, of, shareReplay, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    isAssessmentItemAttainment,
    isAttainmentGroupNode,
    isAttainmentReferenceNode,
    isCourseUnitAttainment,
    isCustomCourseUnitAttainment,
    isCustomModuleAttainment,
    isDegreeProgrammeAttainment,
    isModuleAttainment,
} from 'sis-components/attainment/AttainmentUtil';
import { CourseUnitEntityService } from 'sis-components/service/course-unit-entity.service';
import { ModuleEntityService } from 'sis-components/service/module-entity.service';

import { AttainmentSortingService } from './attainment-sorting.service';
import { AttainmentNodeData, AttainmentTreeData, AttainmentTreeDataService } from './attainment-tree-data.service';
import {
    AssessmentItemAttainmentNode,
    AttainmentTreeGroupNode,
    AttainmentTreeNodeType,
    CourseUnitAttainmentNode,
    DegreeProgrammeAttainmentNode,
    ModuleAttainmentNode,
} from './attainment-tree-node.types';
import {
    AssessmentItemAttainmentNodeNameService,
} from './student-assessment-item-attainments/assessment-item-attainment-node-name.service';
import { AssessmentItemReferenceService } from './student-assessment-item-attainments/assessment-item-reference.service';

@Injectable()
export class AttainmentNodeService {
    /**
     * Emits nodes for attainments of the logged-in student.
     */
    readonly attainmentNodes$: Observable<AttainmentNodesResult>;

    constructor(
        private readonly _attainmentTreeDataService: AttainmentTreeDataService,
        private readonly _courseUnitEntityService: CourseUnitEntityService,
        private readonly _assessmentItemReferenceService: AssessmentItemReferenceService,
        private readonly _assessmentItemAttainmentNodeNameService: AssessmentItemAttainmentNodeNameService,
        private readonly _moduleEntityService: ModuleEntityService,
        private readonly _attainmentSortingService: AttainmentSortingService,
    ) {
        this.attainmentNodes$ = this.createAttainmentNodes();
    }

    private getCourseUnitIdToCourseUnitMap(courseUnitIds: ReadonlySet<OtmId>): Observable<ReadonlyMap<OtmId, CourseUnit>> {
        return courseUnitIds.size
            ? this._courseUnitEntityService.getByIds([...courseUnitIds.values()]).pipe(
                map((courseUnits: readonly CourseUnit[]) => new Map<OtmId, CourseUnit>(courseUnits.map(cu => [cu.id, cu]))),
            )
            : of(new Map<OtmId, CourseUnit>());
    }

    private getAssessmentItemAttainmentIdToAssessmentItemMap(
        assessmentItemAttainments: readonly AssessmentItemAttainment[],
    ): Observable<ReadonlyMap<OtmId, AssessmentItem>> {
        return assessmentItemAttainments.length
            ? this._assessmentItemReferenceService.getAssessmentItemPerAttainmentId(assessmentItemAttainments)
            : of(new Map<OtmId, AssessmentItem>());
    }

    private getModuleIdToModuleMap(moduleIds: ReadonlySet<OtmId>): Observable<ReadonlyMap<OtmId, Module>> {
        return moduleIds.size
            ? this._moduleEntityService.getByIds([...moduleIds.values()]).pipe(
                map((modules: readonly Module[]) => new Map<OtmId, Module>(modules.map(module => [module.id, module]))),
            )
            : of(new Map<OtmId, Module>());
    }

    private createAttainmentNodes(): Observable<AttainmentNodesResult> {
        return this._attainmentTreeDataService.attainmentTreeData$.pipe(
            switchMap((attainmentTreeData: AttainmentTreeData) => {
                // IDs of all course units and modules we'll need to fetch
                const courseUnitIds = new Set<OtmId>();
                const moduleIds = new Set<OtmId>();

                // Collect different types of attainments and check which ones will get a root-level node.
                // TODO OTM-31915: Remove precalculated hasValidParentAttainment and hasParentAttainment,
                // for all attainments should eventually get a root-level node and/or child-level nodes.
                const normalCourseUnitAttainmentIdToNodeDataMap = new Map<OtmId, AttainmentNodeData>();
                const customCourseUnitAttainmentIdToNodeDataMap = new Map<OtmId, AttainmentNodeData>();
                const assessmentItemAttainmentIdToNodeDataMap = new Map<OtmId, AttainmentNodeData>();
                const normalModuleAttainmentIdToNodeDataMap = new Map<OtmId, AttainmentNodeData>();
                const customModuleAttainmentIdToNodeDataMap = new Map<OtmId, AttainmentNodeData>();
                // valid
                const validRootLevelNormalCourseUnitAttainments: AttainmentNodeData[] = [];
                const validRootLevelCustomCourseUnitAttainments: AttainmentNodeData[] = [];
                const validRootLevelAssessmentItemAttainments: AttainmentNodeData[] = [];
                const validDegreeProgrammeAttainments: AttainmentNodeData[] = [];
                const validRootLevelNormalModuleAttainments: AttainmentNodeData[] = [];
                const validRootLevelCustomModuleAttainments: AttainmentNodeData[] = [];
                collectAttainments(
                    attainmentTreeData.validAttainments,
                    nodeData => !nodeData.hasValidParentAttainment,
                    validRootLevelNormalCourseUnitAttainments,
                    validRootLevelCustomCourseUnitAttainments,
                    validRootLevelAssessmentItemAttainments,
                    validRootLevelNormalModuleAttainments,
                    validRootLevelCustomModuleAttainments,
                    normalCourseUnitAttainmentIdToNodeDataMap,
                    customCourseUnitAttainmentIdToNodeDataMap,
                    assessmentItemAttainmentIdToNodeDataMap,
                    validDegreeProgrammeAttainments,
                    normalModuleAttainmentIdToNodeDataMap,
                    customModuleAttainmentIdToNodeDataMap,
                    courseUnitIds,
                    moduleIds,
                );
                // invalid
                const invalidRootLevelNormalCourseUnitAttainments: AttainmentNodeData[] = [];
                const invalidRootLevelCustomCourseUnitAttainments: AttainmentNodeData[] = [];
                const invalidRootLevelAssessmentItemAttainments: AttainmentNodeData[] = [];
                const invalidDegreeProgrammeAttainments: AttainmentNodeData[] = [];
                const invalidRootLevelNormalModuleAttainments: AttainmentNodeData[] = [];
                const invalidRootLevelCustomModuleAttainments: AttainmentNodeData[] = [];
                collectAttainments(
                    attainmentTreeData.invalidAttainments,
                    nodeData => !nodeData.hasParentAttainment,
                    invalidRootLevelNormalCourseUnitAttainments,
                    invalidRootLevelCustomCourseUnitAttainments,
                    invalidRootLevelAssessmentItemAttainments,
                    invalidRootLevelNormalModuleAttainments,
                    invalidRootLevelCustomModuleAttainments,
                    normalCourseUnitAttainmentIdToNodeDataMap,
                    customCourseUnitAttainmentIdToNodeDataMap,
                    assessmentItemAttainmentIdToNodeDataMap,
                    invalidDegreeProgrammeAttainments,
                    normalModuleAttainmentIdToNodeDataMap,
                    customModuleAttainmentIdToNodeDataMap,
                    courseUnitIds,
                    moduleIds,
                );

                // references
                const courseUnitIdToCourseUnitMap$: Observable<ReadonlyMap<OtmId, CourseUnit>> = this
                    .getCourseUnitIdToCourseUnitMap(courseUnitIds);
                const assessmentItemAttainmentIdToAssessmentItemMap$: Observable<ReadonlyMap<OtmId, AssessmentItem>> = this
                    .getAssessmentItemAttainmentIdToAssessmentItemMap(
                        [...assessmentItemAttainmentIdToNodeDataMap.values()]
                            .map(nodeData => <AssessmentItemAttainment>nodeData.attainment));
                const moduleIdToModuleMap$: Observable<ReadonlyMap<OtmId, Module>> = this
                    .getModuleIdToModuleMap(moduleIds);

                // create nodes
                return courseUnitIdToCourseUnitMap$.pipe(
                    combineLatestWith(assessmentItemAttainmentIdToAssessmentItemMap$, moduleIdToModuleMap$),
                    map((
                        [
                            courseUnitIdToCourseUnitMap,
                            assessmentItemAttainmentIdToAssessmentItemMap,
                            moduleIdToModuleMap,
                        ]: [
                            ReadonlyMap<OtmId, CourseUnit>,
                            ReadonlyMap<OtmId, AssessmentItem>,
                            ReadonlyMap<OtmId, Module>,
                        ]) => {
                        const parentNodeId: string | null = null;
                        const validAttainmentNodes: AttainmentNodes = createPartialResult(
                            this.createAssessmentItemAttainmentNodes(
                                validRootLevelAssessmentItemAttainments,
                                courseUnitIdToCourseUnitMap,
                                assessmentItemAttainmentIdToAssessmentItemMap,
                                parentNodeId,
                            ),
                            this.createRootLevelCourseUnitAttainmentNodes(
                                validRootLevelNormalCourseUnitAttainments,
                                validRootLevelCustomCourseUnitAttainments,
                                courseUnitIdToCourseUnitMap,
                                assessmentItemAttainmentIdToNodeDataMap,
                                assessmentItemAttainmentIdToAssessmentItemMap,
                            ),
                            this.createRootLevelModuleAttainmentNodes(
                                validRootLevelNormalModuleAttainments,
                                validRootLevelCustomModuleAttainments,
                                normalModuleAttainmentIdToNodeDataMap,
                                customModuleAttainmentIdToNodeDataMap,
                                moduleIdToModuleMap,
                                normalCourseUnitAttainmentIdToNodeDataMap,
                                customCourseUnitAttainmentIdToNodeDataMap,
                                courseUnitIdToCourseUnitMap,
                                assessmentItemAttainmentIdToNodeDataMap,
                                assessmentItemAttainmentIdToAssessmentItemMap,
                            ),
                            this.createDegreeProgrammeAttainmentNodes(
                                validDegreeProgrammeAttainments,
                                normalModuleAttainmentIdToNodeDataMap,
                                customModuleAttainmentIdToNodeDataMap,
                                moduleIdToModuleMap,
                                normalCourseUnitAttainmentIdToNodeDataMap,
                                customCourseUnitAttainmentIdToNodeDataMap,
                                courseUnitIdToCourseUnitMap,
                                assessmentItemAttainmentIdToNodeDataMap,
                                assessmentItemAttainmentIdToAssessmentItemMap,
                            ),
                        );

                        const invalidAttainmentNodes: AttainmentNodes = createPartialResult(
                            this.createAssessmentItemAttainmentNodes(
                                invalidRootLevelAssessmentItemAttainments,
                                courseUnitIdToCourseUnitMap,
                                assessmentItemAttainmentIdToAssessmentItemMap,
                                parentNodeId,
                            ),
                            this.createRootLevelCourseUnitAttainmentNodes(
                                invalidRootLevelNormalCourseUnitAttainments,
                                invalidRootLevelCustomCourseUnitAttainments,
                                courseUnitIdToCourseUnitMap,
                                assessmentItemAttainmentIdToNodeDataMap,
                                assessmentItemAttainmentIdToAssessmentItemMap,
                            ),
                            this.createRootLevelModuleAttainmentNodes(
                                invalidRootLevelNormalModuleAttainments,
                                invalidRootLevelCustomModuleAttainments,
                                normalModuleAttainmentIdToNodeDataMap,
                                customModuleAttainmentIdToNodeDataMap,
                                moduleIdToModuleMap,
                                normalCourseUnitAttainmentIdToNodeDataMap,
                                customCourseUnitAttainmentIdToNodeDataMap,
                                courseUnitIdToCourseUnitMap,
                                assessmentItemAttainmentIdToNodeDataMap,
                                assessmentItemAttainmentIdToAssessmentItemMap,
                            ),
                            this.createDegreeProgrammeAttainmentNodes(
                                invalidDegreeProgrammeAttainments,
                                normalModuleAttainmentIdToNodeDataMap,
                                customModuleAttainmentIdToNodeDataMap,
                                moduleIdToModuleMap,
                                normalCourseUnitAttainmentIdToNodeDataMap,
                                customCourseUnitAttainmentIdToNodeDataMap,
                                courseUnitIdToCourseUnitMap,
                                assessmentItemAttainmentIdToNodeDataMap,
                                assessmentItemAttainmentIdToAssessmentItemMap,
                            ),
                        );

                        return createResult(
                            validAttainmentNodes,
                            invalidAttainmentNodes,
                        );
                    }),
                );
            }),
            shareReplay({ bufferSize: 1, refCount: true }),
        );
    }

    private createAssessmentItemAttainmentNodes(
        assessmentItemAttainments: readonly AttainmentNodeData[],
        courseUnitIdToCourseUnitMap: ReadonlyMap<OtmId, CourseUnit>,
        assessmentItemAttainmentIdToAssessmentItemMap: ReadonlyMap<OtmId, AssessmentItem>,
        parentNodeId: string | null,
    ): AssessmentItemAttainmentNode[] {
        const assessmentItemAttainmentNodes: AssessmentItemAttainmentNode[] = [];

        for (const nodeData of assessmentItemAttainments) {
            const assessmentItemAttainment: AssessmentItemAttainment = <AssessmentItemAttainment>nodeData.attainment;
            const assessmentItem: AssessmentItem | undefined = assessmentItemAttainmentIdToAssessmentItemMap
                .get(assessmentItemAttainment.id);

            // let's skip assessment item attainments not referring to existing assessment items
            if (assessmentItem) {
                const courseUnit: CourseUnit | null = assessmentItemAttainment.courseUnitId
                    ? courseUnitIdToCourseUnitMap.get(assessmentItemAttainment.courseUnitId) ?? null
                    : null;

                assessmentItemAttainmentNodes.push(
                    <AssessmentItemAttainmentNode>{
                        id: createAttainmentNodeId(assessmentItemAttainment, parentNodeId),
                        type: AttainmentTreeNodeType.Attainment,
                        attainment: assessmentItemAttainment,
                        grade: nodeData.grade,
                        hasValidParentAttainment: nodeData.hasValidParentAttainment,
                        code: courseUnit?.code ?? null,
                        name: this._assessmentItemAttainmentNodeNameService.getName(assessmentItem, courseUnit),
                        attainmentDate: assessmentItemAttainment.attainmentDate,
                        registrationDate: assessmentItemAttainment.registrationDate,
                    });
            }
        }

        return this._attainmentSortingService.sortNodesByAttainmentDate(assessmentItemAttainmentNodes);
    }

    /**
     * Returns null if a node can't be created.
     */
    private createNormalCourseUnitAttainmentNode(
        nodeData: AttainmentNodeData,
        courseUnitIdToCourseUnitMap: ReadonlyMap<OtmId, CourseUnit>,
        assessmentItemAttainmentIdToNodeDataMap: ReadonlyMap<OtmId, AttainmentNodeData>,
        assessmentItemAttainmentIdToAssessmentItemMap: ReadonlyMap<OtmId, AssessmentItem>,
        parentNodeId: string | null,
    ): CourseUnitAttainmentNode | null {
        const courseUnitAttainment: CourseUnitAttainment = <CourseUnitAttainment>nodeData.attainment;
        const courseUnit: CourseUnit | undefined = courseUnitIdToCourseUnitMap.get(courseUnitAttainment.courseUnitId);

        // let's skip course unit attainments not referring to existing course unit
        if (!courseUnit) {
            return null;
        }

        const id: string = createAttainmentNodeId(courseUnitAttainment, parentNodeId);

        const childNodes: AssessmentItemAttainmentNode[] = this.createAssessmentItemAttainmentNodes(
            createArray(
                courseUnitAttainment.assessmentItemAttainmentIds,
                assessmentItemAttainmentIdToNodeDataMap,
            ),
            courseUnitIdToCourseUnitMap,
            assessmentItemAttainmentIdToAssessmentItemMap,
            id,
        );

        return <CourseUnitAttainmentNode>{
            id,
            type: AttainmentTreeNodeType.Attainment,
            attainment: courseUnitAttainment,
            grade: nodeData.grade,
            hasValidParentAttainment: nodeData.hasValidParentAttainment,
            code: courseUnit.code,
            name: courseUnit.name ?? {},
            attainmentDate: courseUnitAttainment.attainmentDate,
            registrationDate: courseUnitAttainment.registrationDate,
            childNodes,
        };
    }

    private createCustomCourseUnitAttainmentNode(
        nodeData: AttainmentNodeData,
        parentNodeId: string | null,
    ): CourseUnitAttainmentNode | null {
        const customCourseUnitAttainment: CustomCourseUnitAttainment = <CustomCourseUnitAttainment>nodeData.attainment;
        return <CourseUnitAttainmentNode>{
            id: createAttainmentNodeId(customCourseUnitAttainment, parentNodeId),
            type: AttainmentTreeNodeType.Attainment,
            attainment: customCourseUnitAttainment,
            grade: nodeData.grade,
            hasValidParentAttainment: nodeData.hasValidParentAttainment,
            code: customCourseUnitAttainment.code,
            name: customCourseUnitAttainment.name ?? {},
            attainmentDate: customCourseUnitAttainment.attainmentDate,
            registrationDate: customCourseUnitAttainment.registrationDate,
            childNodes: [],
        };
    }

    private createRootLevelCourseUnitAttainmentNodes(
        rootLevelNormalCourseUnitAttainments: readonly AttainmentNodeData[],
        rootLevelCustomCourseUnitAttainments: readonly AttainmentNodeData[],
        courseUnitIdToCourseUnitMap: ReadonlyMap<OtmId, CourseUnit>,
        assessmentItemAttainmentIdToNodeDataMap: ReadonlyMap<OtmId, AttainmentNodeData>,
        assessmentItemAttainmentIdToAssessmentItemMap: ReadonlyMap<OtmId, AssessmentItem>,
    ): CourseUnitAttainmentNode[] {
        const courseUnitAttainmentNodes: CourseUnitAttainmentNode[] = [];

        const parentNodeId: string | null = null;
        for (const nodeData of rootLevelNormalCourseUnitAttainments) {
            const node: CourseUnitAttainmentNode | null = this.createNormalCourseUnitAttainmentNode(
                nodeData,
                courseUnitIdToCourseUnitMap,
                assessmentItemAttainmentIdToNodeDataMap,
                assessmentItemAttainmentIdToAssessmentItemMap,
                parentNodeId,
            );
            if (node) {
                courseUnitAttainmentNodes.push(node);
            }
        }

        for (const nodeData of rootLevelCustomCourseUnitAttainments) {
            courseUnitAttainmentNodes.push(this.createCustomCourseUnitAttainmentNode(nodeData, parentNodeId));
        }

        return this._attainmentSortingService.sortNodesByAttainmentDate(courseUnitAttainmentNodes);
    }

    private createChildNodes(
        attainmentNodes: readonly AttainmentNode[] | null,
        normalModuleAttainmentIdToNodeDataMap: ReadonlyMap<OtmId, AttainmentNodeData>,
        customModuleAttainmentIdToNodeDataMap: ReadonlyMap<OtmId, AttainmentNodeData>,
        moduleIdToModuleMap: ReadonlyMap<OtmId, Module>,
        normalCourseUnitAttainmentIdToNodeDataMap: ReadonlyMap<OtmId, AttainmentNodeData>,
        customCourseUnitAttainmentIdToNodeDataMap: ReadonlyMap<OtmId, AttainmentNodeData>,
        courseUnitIdToCourseUnitMap: ReadonlyMap<OtmId, CourseUnit>,
        assessmentItemAttainmentIdToNodeDataMap: ReadonlyMap<OtmId, AttainmentNodeData>,
        assessmentItemAttainmentIdToAssessmentItemMap: ReadonlyMap<OtmId, AssessmentItem>,
        parentNodeId: string,
    ): (ModuleAttainmentNode | CourseUnitAttainmentNode | AttainmentTreeGroupNode)[] {
        if (!attainmentNodes?.length) {
            return [];
        }

        const siblingGroupNodeIds = new Set<string>();
        const nodes = attainmentNodes.map(attainmentNode => {
            if (isAttainmentGroupNode(attainmentNode)) {
                const id: string = createGroupNodeId(
                    attainmentNode,
                    parentNodeId,
                    siblingGroupNodeIds,
                );

                const childNodes = this.createChildNodes(
                    attainmentNode.nodes,
                    normalModuleAttainmentIdToNodeDataMap,
                    customModuleAttainmentIdToNodeDataMap,
                    moduleIdToModuleMap,
                    normalCourseUnitAttainmentIdToNodeDataMap,
                    customCourseUnitAttainmentIdToNodeDataMap,
                    courseUnitIdToCourseUnitMap,
                    assessmentItemAttainmentIdToNodeDataMap,
                    assessmentItemAttainmentIdToAssessmentItemMap,
                    id,
                );

                // Group node's attainment & registration dates should correspond to those of the newest attained/registered child
                // Since childNodes is already sorted by attainmentDate, we can take the attainmentDate of the first child node
                const firstChild = childNodes.length > 0 ? childNodes[0] : null;
                const attainmentDate = firstChild?.attainmentDate ?? null;
                const registrationDate = this._attainmentSortingService.getLatestRegistrationDate(childNodes);

                return <AttainmentTreeGroupNode>{
                    id,
                    type: AttainmentTreeNodeType.Group,
                    name: attainmentNode.name ?? {},
                    childNodes,
                    attainmentDate,
                    registrationDate,
                };
            }

            if (isAttainmentReferenceNode(attainmentNode)) {
                if (!attainmentNode.attainmentId) {
                    return null;
                }

                const normalCourseUnitAttainmentNodeData: AttainmentNodeData | undefined =
                    normalCourseUnitAttainmentIdToNodeDataMap.get(attainmentNode.attainmentId);
                if (normalCourseUnitAttainmentNodeData) {
                    return this.createNormalCourseUnitAttainmentNode(
                        normalCourseUnitAttainmentNodeData,
                        courseUnitIdToCourseUnitMap,
                        assessmentItemAttainmentIdToNodeDataMap,
                        assessmentItemAttainmentIdToAssessmentItemMap,
                        parentNodeId,
                    );
                }

                const customCourseUnitAttainmentNodeData: AttainmentNodeData | undefined =
                    customCourseUnitAttainmentIdToNodeDataMap.get(attainmentNode.attainmentId);
                if (customCourseUnitAttainmentNodeData) {
                    return this.createCustomCourseUnitAttainmentNode(customCourseUnitAttainmentNodeData, parentNodeId);
                }

                const normalModuleAttainmentNodeData: AttainmentNodeData | undefined =
                    normalModuleAttainmentIdToNodeDataMap.get(attainmentNode.attainmentId);
                if (normalModuleAttainmentNodeData) {
                    return this.createNormalModuleAttainmentNode(
                        normalModuleAttainmentNodeData,
                        normalModuleAttainmentIdToNodeDataMap,
                        customModuleAttainmentIdToNodeDataMap,
                        moduleIdToModuleMap,
                        normalCourseUnitAttainmentIdToNodeDataMap,
                        customCourseUnitAttainmentIdToNodeDataMap,
                        courseUnitIdToCourseUnitMap,
                        assessmentItemAttainmentIdToNodeDataMap,
                        assessmentItemAttainmentIdToAssessmentItemMap,
                        parentNodeId,
                    );
                }

                const customModuleAttainmentNodeData: AttainmentNodeData | undefined =
                    customModuleAttainmentIdToNodeDataMap.get(attainmentNode.attainmentId);
                if (customModuleAttainmentNodeData) {
                    return this.createCustomModuleAttainmentNode(
                        customModuleAttainmentNodeData,
                        normalModuleAttainmentIdToNodeDataMap,
                        customModuleAttainmentIdToNodeDataMap,
                        moduleIdToModuleMap,
                        normalCourseUnitAttainmentIdToNodeDataMap,
                        customCourseUnitAttainmentIdToNodeDataMap,
                        courseUnitIdToCourseUnitMap,
                        assessmentItemAttainmentIdToNodeDataMap,
                        assessmentItemAttainmentIdToAssessmentItemMap,
                        parentNodeId,
                    );
                }

                // unidentified attainment ID
                return null;
            }

            throw new Error(`Unknown attainment node type: ${attainmentNode.type}`);
        }).filter(Boolean);

        return this._attainmentSortingService.sortNodesByAttainmentDate(nodes);
    }

    /**
     * Returns null if a node can't be created.
     */
    private createNormalModuleAttainmentNode(
        nodeData: AttainmentNodeData,
        normalModuleAttainmentIdToNodeDataMap: ReadonlyMap<OtmId, AttainmentNodeData>,
        customModuleAttainmentIdToNodeDataMap: ReadonlyMap<OtmId, AttainmentNodeData>,
        moduleIdToModuleMap: ReadonlyMap<OtmId, Module>,
        normalCourseUnitAttainmentIdToNodeDataMap: ReadonlyMap<OtmId, AttainmentNodeData>,
        customCourseUnitAttainmentIdToNodeDataMap: ReadonlyMap<OtmId, AttainmentNodeData>,
        courseUnitIdToCourseUnitMap: ReadonlyMap<OtmId, CourseUnit>,
        assessmentItemAttainmentIdToNodeDataMap: ReadonlyMap<OtmId, AttainmentNodeData>,
        assessmentItemAttainmentIdToAssessmentItemMap: ReadonlyMap<OtmId, AssessmentItem>,
        parentNodeId: string | null,
    ): ModuleAttainmentNode | null {
        const moduleAttainment: ModuleAttainment = <ModuleAttainment>nodeData.attainment;
        const module: Module | undefined = moduleIdToModuleMap.get(moduleAttainment.moduleId);

        // let's skip module attainments not referring to existing module
        if (!module) {
            return null;
        }

        const id: string = createAttainmentNodeId(moduleAttainment, parentNodeId);

        return <ModuleAttainmentNode>{
            id,
            type: AttainmentTreeNodeType.Attainment,
            attainment: moduleAttainment,
            grade: nodeData.grade,
            hasValidParentAttainment: nodeData.hasValidParentAttainment,
            code: module.code,
            name: module.name ?? {},
            attainmentDate: moduleAttainment.attainmentDate,
            registrationDate: moduleAttainment.registrationDate,
            childNodes: this.createChildNodes(
                moduleAttainment.nodes,
                normalModuleAttainmentIdToNodeDataMap,
                customModuleAttainmentIdToNodeDataMap,
                moduleIdToModuleMap,
                normalCourseUnitAttainmentIdToNodeDataMap,
                customCourseUnitAttainmentIdToNodeDataMap,
                courseUnitIdToCourseUnitMap,
                assessmentItemAttainmentIdToNodeDataMap,
                assessmentItemAttainmentIdToAssessmentItemMap,
                id,
            ),
        };
    }

    private createCustomModuleAttainmentNode(
        nodeData: AttainmentNodeData,
        normalModuleAttainmentIdToNodeDataMap: ReadonlyMap<OtmId, AttainmentNodeData>,
        customModuleAttainmentIdToNodeDataMap: ReadonlyMap<OtmId, AttainmentNodeData>,
        moduleIdToModuleMap: ReadonlyMap<OtmId, Module>,
        normalCourseUnitAttainmentIdToNodeDataMap: ReadonlyMap<OtmId, AttainmentNodeData>,
        customCourseUnitAttainmentIdToNodeDataMap: ReadonlyMap<OtmId, AttainmentNodeData>,
        courseUnitIdToCourseUnitMap: ReadonlyMap<OtmId, CourseUnit>,
        assessmentItemAttainmentIdToNodeDataMap: ReadonlyMap<OtmId, AttainmentNodeData>,
        assessmentItemAttainmentIdToAssessmentItemMap: ReadonlyMap<OtmId, AssessmentItem>,
        parentNodeId: string | null,
    ): ModuleAttainmentNode {
        const customModuleAttainment: CustomModuleAttainment = <CustomModuleAttainment>nodeData.attainment;

        const id: string = createAttainmentNodeId(customModuleAttainment, parentNodeId);
        return <ModuleAttainmentNode>{
            id,
            type: AttainmentTreeNodeType.Attainment,
            attainment: customModuleAttainment,
            grade: nodeData.grade,
            hasValidParentAttainment: nodeData.hasValidParentAttainment,
            code: customModuleAttainment.code,
            name: customModuleAttainment.name ?? {},
            attainmentDate: customModuleAttainment.attainmentDate,
            registrationDate: customModuleAttainment.registrationDate,
            childNodes: this.createChildNodes(
                customModuleAttainment.nodes,
                normalModuleAttainmentIdToNodeDataMap,
                customModuleAttainmentIdToNodeDataMap,
                moduleIdToModuleMap,
                normalCourseUnitAttainmentIdToNodeDataMap,
                customCourseUnitAttainmentIdToNodeDataMap,
                courseUnitIdToCourseUnitMap,
                assessmentItemAttainmentIdToNodeDataMap,
                assessmentItemAttainmentIdToAssessmentItemMap,
                id,
            ),
        };
    }

    private createRootLevelModuleAttainmentNodes(
        rootLevelNormalModuleAttainments: readonly AttainmentNodeData[],
        rootLevelCustomModuleAttainments: readonly AttainmentNodeData[],
        normalModuleAttainmentIdToNodeDataMap: ReadonlyMap<OtmId, AttainmentNodeData>,
        customModuleAttainmentIdToNodeDataMap: ReadonlyMap<OtmId, AttainmentNodeData>,
        moduleIdToModuleMap: ReadonlyMap<OtmId, Module>,
        normalCourseUnitAttainmentIdToNodeDataMap: ReadonlyMap<OtmId, AttainmentNodeData>,
        customCourseUnitAttainmentIdToNodeDataMap: ReadonlyMap<OtmId, AttainmentNodeData>,
        courseUnitIdToCourseUnitMap: ReadonlyMap<OtmId, CourseUnit>,
        assessmentItemAttainmentIdToNodeDataMap: ReadonlyMap<OtmId, AttainmentNodeData>,
        assessmentItemAttainmentIdToAssessmentItemMap: ReadonlyMap<OtmId, AssessmentItem>,
    ): ModuleAttainmentNode[] {
        const moduleAttainmentNodes: ModuleAttainmentNode[] = [];

        const parentNodeId: string | null = null;
        for (const nodeData of rootLevelNormalModuleAttainments) {
            const node: ModuleAttainmentNode | null = this.createNormalModuleAttainmentNode(
                nodeData,
                normalModuleAttainmentIdToNodeDataMap,
                customModuleAttainmentIdToNodeDataMap,
                moduleIdToModuleMap,
                normalCourseUnitAttainmentIdToNodeDataMap,
                customCourseUnitAttainmentIdToNodeDataMap,
                courseUnitIdToCourseUnitMap,
                assessmentItemAttainmentIdToNodeDataMap,
                assessmentItemAttainmentIdToAssessmentItemMap,
                parentNodeId,
            );
            if (node) {
                moduleAttainmentNodes.push(node);
            }
        }

        for (const nodeData of rootLevelCustomModuleAttainments) {
            moduleAttainmentNodes.push(this.createCustomModuleAttainmentNode(
                nodeData,
                normalModuleAttainmentIdToNodeDataMap,
                customModuleAttainmentIdToNodeDataMap,
                moduleIdToModuleMap,
                normalCourseUnitAttainmentIdToNodeDataMap,
                customCourseUnitAttainmentIdToNodeDataMap,
                courseUnitIdToCourseUnitMap,
                assessmentItemAttainmentIdToNodeDataMap,
                assessmentItemAttainmentIdToAssessmentItemMap,
                parentNodeId,
            ));
        }

        return this._attainmentSortingService.sortNodesByAttainmentDate(moduleAttainmentNodes);
    }

    private createDegreeProgrammeAttainmentNodes(
        degreeProgrammeAttainments: readonly AttainmentNodeData[],
        normalModuleAttainmentIdToNodeDataMap: ReadonlyMap<OtmId, AttainmentNodeData>,
        customModuleAttainmentIdToNodeDataMap: ReadonlyMap<OtmId, AttainmentNodeData>,
        moduleIdToModuleMap: ReadonlyMap<OtmId, Module>,
        normalCourseUnitAttainmentIdToNodeDataMap: ReadonlyMap<OtmId, AttainmentNodeData>,
        customCourseUnitAttainmentIdToNodeDataMap: ReadonlyMap<OtmId, AttainmentNodeData>,
        courseUnitIdToCourseUnitMap: ReadonlyMap<OtmId, CourseUnit>,
        assessmentItemAttainmentIdToNodeDataMap: ReadonlyMap<OtmId, AttainmentNodeData>,
        assessmentItemAttainmentIdToAssessmentItemMap: ReadonlyMap<OtmId, AssessmentItem>,
    ): DegreeProgrammeAttainmentNode[] {
        const degreeProgrammeAttainmentNodes: DegreeProgrammeAttainmentNode[] = [];

        const parentNodeId: string | null = null;
        for (const nodeData of degreeProgrammeAttainments) {
            const degreeProgrammeAttainment: DegreeProgrammeAttainment = <DegreeProgrammeAttainment>nodeData.attainment;
            const module: Module | undefined = moduleIdToModuleMap.get(degreeProgrammeAttainment.moduleId);
            // let's skip degree programme attainments not referring to existing module
            if (module) {
                const id: string = createAttainmentNodeId(degreeProgrammeAttainment, parentNodeId);
                degreeProgrammeAttainmentNodes.push(<DegreeProgrammeAttainmentNode>{
                    id,
                    type: AttainmentTreeNodeType.Attainment,
                    attainment: degreeProgrammeAttainment,
                    grade: nodeData.grade,
                    code: module.code,
                    name: module.name ?? {},
                    attainmentDate: degreeProgrammeAttainment.attainmentDate,
                    registrationDate: degreeProgrammeAttainment.registrationDate,
                    childNodes: this.createChildNodes(
                        degreeProgrammeAttainment.nodes,
                        normalModuleAttainmentIdToNodeDataMap,
                        customModuleAttainmentIdToNodeDataMap,
                        moduleIdToModuleMap,
                        normalCourseUnitAttainmentIdToNodeDataMap,
                        customCourseUnitAttainmentIdToNodeDataMap,
                        courseUnitIdToCourseUnitMap,
                        assessmentItemAttainmentIdToNodeDataMap,
                        assessmentItemAttainmentIdToAssessmentItemMap,
                        id,
                    ),
                });
            }
        }

        return this._attainmentSortingService.sortNodesByAttainmentDate(degreeProgrammeAttainmentNodes);
    }
}

export interface AttainmentNodes {
    readonly assessmentItemAttainmentNodes: readonly AssessmentItemAttainmentNode[];
    readonly courseUnitAttainmentNodes: readonly CourseUnitAttainmentNode[];
    readonly moduleAttainmentNodes: readonly ModuleAttainmentNode[];
    readonly degreeProgrammeAttainmentNodes: readonly DegreeProgrammeAttainmentNode[];
}

export interface AttainmentNodesResult {
    readonly validAttainmentNodes: AttainmentNodes;
    readonly invalidAttainmentNodes: AttainmentNodes;
}

function collectAttainments(
    attainments: readonly AttainmentNodeData[],
    rootLevelNodeConditionFunc: (nodeData: AttainmentNodeData) => boolean,
    collectedRootLevelNormalCourseUnitAttainments: AttainmentNodeData[],
    collectedRootLevelCustomCourseUnitAttainments: AttainmentNodeData[],
    collectedRootLevelAssessmentItemAttainments: AttainmentNodeData[],
    collectedRootLevelNormalModuleAttainments: AttainmentNodeData[],
    collectedRootLevelCustomModuleAttainments: AttainmentNodeData[],
    collectedNormalCourseUnitAttainmentIdToNodeDataMap: Map<OtmId, AttainmentNodeData>,
    collectedCustomCourseUnitAttainmentIdToNodeDataMap: Map<OtmId, AttainmentNodeData>,
    collectedAssessmentItemAttainmentIdToNodeDataMap: Map<OtmId, AttainmentNodeData>,
    collectedDegreeProgrammeAttainments: AttainmentNodeData[],
    collectedNormalModuleAttainmentIdToNodeDataMap: Map<OtmId, AttainmentNodeData>,
    collectedCustomModuleAttainmentIdToNodeDataMap: Map<OtmId, AttainmentNodeData>,
    collectedCourseUnitIds: Set<OtmId>,
    collectedModuleIds: Set<OtmId>,
): void {
    for (const nodeData of attainments) {
        if (isCourseUnitAttainment(nodeData.attainment)) {
            collectedNormalCourseUnitAttainmentIdToNodeDataMap.set(nodeData.attainment.id, nodeData);

            const courseUnitAttainment: CourseUnitAttainment = nodeData.attainment;

            if (courseUnitAttainment.courseUnitId) {
                collectedCourseUnitIds.add(courseUnitAttainment.courseUnitId);
            }

            if (rootLevelNodeConditionFunc(nodeData)) {
                collectedRootLevelNormalCourseUnitAttainments.push(nodeData);
            }
        } else if (isCustomCourseUnitAttainment(nodeData.attainment)) {
            collectedCustomCourseUnitAttainmentIdToNodeDataMap.set(nodeData.attainment.id, nodeData);

            if (rootLevelNodeConditionFunc(nodeData)) {
                collectedRootLevelCustomCourseUnitAttainments.push(nodeData);
            }
        } else if (isAssessmentItemAttainment(nodeData.attainment)) {
            collectedAssessmentItemAttainmentIdToNodeDataMap.set(nodeData.attainment.id, nodeData);

            const assessmentItemAttainment: AssessmentItemAttainment = nodeData.attainment;

            if (assessmentItemAttainment.courseUnitId) {
                collectedCourseUnitIds.add(assessmentItemAttainment.courseUnitId);
            }

            if (rootLevelNodeConditionFunc(nodeData)) {
                collectedRootLevelAssessmentItemAttainments.push(nodeData);
            }
        } else if (isModuleAttainment(nodeData.attainment)) {
            collectedNormalModuleAttainmentIdToNodeDataMap.set(nodeData.attainment.id, nodeData);

            const moduleAttainment: ModuleAttainment = nodeData.attainment;

            if (moduleAttainment.moduleId) {
                collectedModuleIds.add(moduleAttainment.moduleId);
            }

            if (rootLevelNodeConditionFunc(nodeData)) {
                collectedRootLevelNormalModuleAttainments.push(nodeData);
            }
        } else if (isCustomModuleAttainment(nodeData.attainment)) {
            collectedCustomModuleAttainmentIdToNodeDataMap.set(nodeData.attainment.id, nodeData);

            if (rootLevelNodeConditionFunc(nodeData)) {
                collectedRootLevelCustomModuleAttainments.push(nodeData);
            }
        } else if (isDegreeProgrammeAttainment(nodeData.attainment)) {
            collectedDegreeProgrammeAttainments.push(nodeData);

            const degreeProgrammeAttainment: DegreeProgrammeAttainment = nodeData.attainment;

            if (degreeProgrammeAttainment.moduleId) {
                collectedModuleIds.add(degreeProgrammeAttainment.moduleId);
            }

            // all degree programme attainments will get a root-level node
        } else {
            throw new Error(`Unsupported attainment type: ${nodeData.attainment.type}`);
        }
    }
}

function createArray<T>(
    keys: readonly OtmId[] | null,
    keyToItemMap: ReadonlyMap<OtmId, T>,
): T[] {
    return keys?.map(key => keyToItemMap.get(key)).filter(Boolean) ?? [];
}

function createResult(
    validAttainmentNodes: AttainmentNodes,
    invalidAttainmentNodes: AttainmentNodes,
): AttainmentNodesResult {
    return <AttainmentNodesResult>{
        validAttainmentNodes,
        invalidAttainmentNodes,
    };
}

function createPartialResult(
    assessmentItemAttainmentNodes: readonly AssessmentItemAttainmentNode[],
    courseUnitAttainmentNodes: readonly CourseUnitAttainmentNode[],
    moduleAttainmentNodes: readonly ModuleAttainmentNode[],
    degreeProgrammeAttainmentNodes: readonly DegreeProgrammeAttainmentNode[],
): AttainmentNodes {
    return <AttainmentNodes>{
        assessmentItemAttainmentNodes,
        courseUnitAttainmentNodes,
        moduleAttainmentNodes,
        degreeProgrammeAttainmentNodes,
    };
}

function createAttainmentNodeId(attainment: Attainment, parentNodeId: string | null): string {
    const locallyUniqueId: string = `ATTAINMENT_${attainment.id}`;
    return parentNodeId
        ? `${parentNodeId}_${locallyUniqueId}`
        : locallyUniqueId;
}

function createGroupNodeId(
    attainmentGroupNode: AttainmentGroupNode,
    parentNodeId: string,
    siblingGroupNodeIds: Set<string>,
): string {
    // AttainmentGroupNode doesn't have a unique ID,
    // so the name is probably the only way for the user to _identify_ a group node,
    // despite its content.
    const idBase: string = `${parentNodeId}_GROUP_${toJson(attainmentGroupNode.name ?? {})}`;

    // It should be very unlikely that the name is empty or non-unique (among siblings),
    // but better be safe than sorry.
    if (!siblingGroupNodeIds.has(idBase)) {
        siblingGroupNodeIds.add(idBase);
        return idBase;
    }

    // In case of an ID collision, start adding suffixes "-1", "-2" etc. until we find a unique ID.
    let id: string;
    let nextSuffix: number = 1;
    do {
        id = `${idBase}-${nextSuffix}`;
        nextSuffix += 1;
    } while (siblingGroupNodeIds.has(id));

    siblingGroupNodeIds.add(id);
    return id;
}
