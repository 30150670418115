/**
 * Redirects the user to a plan structure view according to the redirect logic (see {@link handlePlanRedirection}).
 */
export const STUDENT_PLAN = 'student.logged-in.new-plan' as const;
/**
 * Special state only used when student creates the first plan.
 */
export const STUDENT_PLAN_CREATE_FIRST_PLAN = `${STUDENT_PLAN}.create-first-plan` as const;
/**
 * Specific plans' structure view.
 */
export const STUDENT_PLAN_STRUCTURE = `${STUDENT_PLAN}.structure` as const;
