import _ from 'lodash';
import angular from 'angular';
import { localeServiceModule } from 'sis-common/l10n/localeService';
import { creditRangeFilterModule } from '../number/creditRange.filter';
import { searchFilterTagModule } from './searchFilters/searchFilterTag.component';
import { searchParameterServiceModule } from './searchParameters';
import { searchComponentUtilsServiceModule } from './searchComponentUtils.service';
import displaySelectedParametersTpl from './displaySelectedParameters.tpl.html';
export const displaySelectedParametersModule = 'sis-components.search.displaySelectedParameters';
(function () {
  displaySelectedParametersCtrl.$inject = ["$log", "OrParameter", "SearchParameter", "searchComponentUtilsService"];
  angular.module(displaySelectedParametersModule, [searchFilterTagModule, searchParameterServiceModule, creditRangeFilterModule, localeServiceModule, searchComponentUtilsServiceModule]).component('displaySelectedParameters', {
    template: displaySelectedParametersTpl,
    controller: displaySelectedParametersCtrl,
    controllerAs: 'displayCtrl',
    bindings: {
      selectableParameters: '<',
      onParameterChange: '&',
      searchParams: '<',
      searchAgain: '&',
      // Used only to search organisations, educations and modules under specific university. Unnecessary otherwise.
      university: '<?',
      readOnly: '<?'
    }
  });

  /**
   * This component displays selected search parameters with options to modify or remove them. Supports read only
   * mode.
   *
   * This component probably should not be binded with searchAgain but instead with removeParameter and
   * modifyParameter callbacks.
   *
   * @ngInject
   * @constructor
   */
  function displaySelectedParametersCtrl($log, OrParameter, SearchParameter, searchComponentUtilsService) {
    const ctrl = this;
    ctrl.$onChanges = function () {
      ctrl.searchParameters = _.keys(ctrl.searchParams);
    };
    ctrl.parameterObject = function (searchParameter) {
      return ctrl.searchParams[searchParameter];
    };
    ctrl.onSelect = function (values, parameterKey) {
      ctrl.onParameterChange({
        parameterKey,
        values
      });
    };
    ctrl.isSearchParameter = function (searchParameter) {
      return ctrl.searchParams[searchParameter] instanceof SearchParameter;
    };
    ctrl.isOrParameter = function (searchParameter) {
      return ctrl.searchParams[searchParameter] instanceof OrParameter;
    };
    ctrl.hasValue = function (searchParameter) {
      if (ctrl.isSearchParameter(searchParameter)) {
        return ctrl.searchParams[searchParameter].isValidForSearch();
      }
    };
    ctrl.getParameterValue = searchParameter => searchComponentUtilsService.getDisplayValueString(ctrl.parameterObject(searchParameter));
    ctrl.removeSelection = function (searchParameter) {
      ctrl.searchParams[searchParameter].removeAllValues();
      $log.debug('Search component - displaySelectedParameters removeSelection - executing search');
      ctrl.searchAgain();
    };
  }
})();