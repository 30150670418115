import { Injectable } from '@angular/core';
import { TermRegistration } from 'common-typescript/types';

export interface StudyYearAndTermRegistrations {
    studyYear: number;
    registrations: TermRegistration[];
}

@Injectable()
export class TermRegistrationGroupingService {

    /**
     * Returns a list of study years and their term registrations, ordered by the study year in descending order (2022, 2021, ...).
     * The registrations within a study year are ordered by the study term index in ascending order (autumn first, spring second).
     *
     * @param registrations term registrations to group
     */
    groupRegistrationsByStudyYear(registrations: TermRegistration[]): StudyYearAndTermRegistrations[] {
        return [...new Set(registrations.map(reg => reg.studyTerm.studyYearStartYear))]
            .sort((a, b) => b - a)
            .map(studyYear => ({
                studyYear,
                registrations: this.sortedTermRegistrationsForStudyYear(registrations, studyYear),
            }));
    }

    private sortedTermRegistrationsForStudyYear(registrations: TermRegistration[], studyYear: number): TermRegistration[] {
        return registrations
            .filter(reg => reg.studyTerm.studyYearStartYear === studyYear)
            .sort((a, b) => a.studyTerm.termIndex - b.studyTerm.termIndex);
    }
}
