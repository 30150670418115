'use strict';

(function () {
  courseUnitLoaderFactory.$inject = ["courseUnitService", "cacheUtils"];
  angular.module('student.common.context.plan-context').factory('courseUnitLoader', courseUnitLoaderFactory);
  function courseUnitLoaderFactory(courseUnitService, cacheUtils) {
    return {
      loadCourseUnits: function (courseUnitAttainments, courseUnitSelections, courseUnitsById) {
        var unloadedCourseUnitIds = cacheUtils.purgeCacheAndGetUnloadedIds(courseUnitsById, _.map(courseUnitAttainments, "courseUnitId"), _.map(courseUnitSelections, "courseUnitId"), _.flatten(_.map(courseUnitSelections, "substitutedBy")));
        return courseUnitService.findByIds(unloadedCourseUnitIds).then(function (courseUnits) {
          return courseUnits;
        });
      }
    };
  }
})();