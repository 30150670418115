'use strict';

(function () {
  gradeScaleJSDataModel.$inject = ["$http", "DS"];
  angular.module('sis-components.model.gradeScale', ['js-data']).factory('gradeScaleJSDataModel', gradeScaleJSDataModel);
  function gradeScaleJSDataModel($http, DS) {
    var model = DS.defineResource({
      idAttribute: 'id',
      name: 'gradeScale',
      endpoint: '/kori/api/grade-scales',
      watchChanges: false
    });
    model.lazyGetAll = _.once(function () {
      return $http.get(model.endpoint).then(function (response) {
        model.inject(_.values(response.data));
        return model.getAll();
      });
    });
    return model;
  }
})();