export const genderModelModule = 'sis-components.model.gender';
'use strict';
(function () {
  genderJSDataModel.$inject = ["DS", "$http"];
  angular.module(genderModelModule, ['js-data']).factory('genderJSDataModel', genderJSDataModel);
  function genderJSDataModel(DS, $http) {
    var model = DS.defineResource({
      idAttribute: 'urn',
      name: 'gender',
      endpoint: '/kori/api/cached/codebooks/urn:code:gender'
    });
    model.lazyGetAll = _.once(function () {
      return $http.get(model.endpoint).then(function (response) {
        model.inject(_.values(response.data));
        return model.getAll();
      });
    });
    return model;
  }
})();