import { Tab as TabContentSwitch } from './tab-content-switch/tab-content-switch.component';
import { Tab as TabNavigation } from './tab-navigation/tab-navigation.component';

// In some cases, where application has refreshed itself, the state of indexOfFocusedTab is lost, so we manually find it
export const getIndexOfFocusedTab = (ulElement: HTMLObjectElement) => {
    const liChildren: HTMLCollection = ulElement.children;
    for (let i = 0; i < liChildren.length; i = i + 1) {
        if (liChildren[i].classList.contains('focusedTab') || liChildren[i].classList.contains('active') || liChildren[i].children[0].getAttribute('aria-selected') === 'true') {
            return i;
        }
    }
};

export const addFocusToTabItem = (ulTabList: HTMLObjectElement, tabData: Array<TabNavigation> | Array<TabContentSwitch>, indexOfFocusedTab: number, direction: string, indexFromClick?: number) => {

    let currentIndex = indexOfFocusedTab;

    if (indexOfFocusedTab === undefined && !Number.isFinite(indexFromClick)) {
        currentIndex = getIndexOfFocusedTab(ulTabList);
    }

    let updatedIndexOfFocusedTab: number;

    if (Number.isFinite(indexFromClick)) {
        updatedIndexOfFocusedTab = indexFromClick;
    } else {
        updatedIndexOfFocusedTab = calculateNewIndexToFocus(direction, currentIndex, tabData.length);
    }

    try {
        const listItemToFocus = ulTabList.children[updatedIndexOfFocusedTab];

        listItemToFocus.classList.add('focusedTab');

        if (listItemToFocus.querySelector('a')) {
            // If using tab-navigation-component
            listItemToFocus.querySelector('a').focus();
        } else {
            // If using tab-content-switch-component
            listItemToFocus.querySelector('button').focus();
        }

    } catch (error) {
        console.warn(error);
    }

    return updatedIndexOfFocusedTab;

};

export const removeFocus = (event: Event) => {
    const eventTarget = event.target as HTMLElement;

    if (eventTarget.parentElement.classList.contains('focusedTab')) {
        eventTarget.parentElement.classList.remove('focusedTab');
    }

    // Clean DOM element from empty classList
    if (eventTarget.parentElement.classList.length === 0) {
        eventTarget.parentElement.removeAttribute('class');
    }
};

const calculateNewIndexToFocus = (direction: string, currentIndex: number, tabDataLength: number) => {

    const calculate: { [key: string]: number } = {
        next: (currentIndex + 1) % tabDataLength,
        previous: currentIndex ? currentIndex - 1 : tabDataLength - 1,
        home: 0,
        end: tabDataLength - 1,
    };

    return calculate[direction];

};

export const commonKeyDown = (event: KeyboardEvent) => {

    switch (event.key) {
        case 'Home':
            event.preventDefault();
            return 'home';
        case 'End':
            event.preventDefault();
            return 'end';
        case ' ':
        case 'ArrowRight':
        case 'ArrowDown':
        case 'ArrowLeft':
        case 'ArrowUp':
            event.preventDefault();
            break;
    }

};

export const commonKeyUp = (event: KeyboardEvent) => {

    switch (event.key) {
        case 'ArrowLeft':
        case 'ArrowUp':
            event.preventDefault();
            return 'previous';
        case 'ArrowRight':
        case 'ArrowDown':
            event.preventDefault();
            return 'next';
        case 'Enter':
        case 'Tab':
            return 'click';
        case ' ':
            // Simulate clicking with Spacebar
            document.getElementById((event.target as Element).id).click();
            break;
    }
};
