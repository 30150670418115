import substitutionTpl from './substitution.tpl.html';
export const substitutionModule = 'sis-components.courseUnit.substitution';
"use strict";
(function () {
  substitutionController.$inject = ["courseUnitInfoModal", "commonCourseUnitService", "defaultPromiseHandler"];
  angular.module('sis-components.courseUnit.substitution', ['pascalprecht.translate', 'sis-common.errorhandler.errorService', 'sis-components.number.creditRangeFilter', 'sis-components.service.courseUnitService', 'sis-components.string.localizedStringEditor', 'sis-components.model.courseUnit']).component('substitution', {
    template: substitutionTpl,
    transclude: true,
    bindings: {
      substitution: '<',
      colorIndex: '<'
    },
    controller: substitutionController
  });
  function substitutionController(courseUnitInfoModal, commonCourseUnitService, defaultPromiseHandler) {
    var $ctrl = this;
    $ctrl.$onChanges = function () {
      getSubstitutionCourseUnits();
    };
    function getSubstitutionCourseUnits() {
      _.forEach($ctrl.substitution, function (courseUnitSubstitution) {
        commonCourseUnitService.findByGroupId(courseUnitSubstitution.courseUnitGroupId, undefined).then(function (courseUnit) {
          courseUnitSubstitution._courseUnit = courseUnit;
        });
      });
    }
    $ctrl.openCourseUnitInfoModal = function (courseUnitId) {
      courseUnitInfoModal.open(courseUnitId).then(angular.noop).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    };
  }
})();