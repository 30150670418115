<ul class="nav nav-pills-localize" role="menu">
  <li *ngFor="let item of languageSelection"
      class="language-pill">
    <button type="button"
            role="menuitem"
            class="language-pill-button"
            [class.gray]="gray"
            [class.active]="selectedLanguage === item.language"
            [class.invalid]="isInvalid(item.language)"
            (click)="changeLanguage(item.language)"
            [attr.data-cy]="'language-pill_' + item.language"
            [attr.aria-current]="selectedLanguage === item.language"
            [attr.aria-label]="item.language + ' ' + ((selectedLanguage === item.language ? 'ARIA_LABEL.SELECTED'
            : 'ARIA_LABEL.CHANGE_LANGUAGE') | translate) + ' ' + (item.name | localizedString )">
      {{item.language}}
    </button>
  </li>
</ul>
