import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

/**
 * @deprecated Use Fudis `LinkComponent` with input externalLink instead
 */

/**
 * An external link component that:
 * <ol>
 *    <li>shows an "external link" icon next to the link</li>
 *    <li>opens to a new tab, and communicates that to screen readers</li>
 * </ol>
 *
 * The link content is passed inside the `<sis-external-link>` element like you would do for regular `<a>` elements.
 */
@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-external-link',
    templateUrl: './external-link.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ExternalLinkComponent {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sisComponents.externalLink',
        directiveName: 'sisExternalLink',
    };

    /** Link URL. */
    @Input() href: string;
}
