import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SisCommonModule } from 'sis-common/sis-common.module';

import { SisComponentsModule } from '../../../sis-components.module';
import { AttainmentTreeModule } from '../../attainment/editableTree/attainment-tree.module';

import { MaaAttainmentExpandableComponent } from './maa-attainment-expandable/maa-attainment-expandable.component';
import { MaaDecisionExpandableComponent } from './maa-decision-expandable/maa-decision-expandable.component';
import { ModuleAttainmentComponent } from './module-attainment/module-attainment.component';

@NgModule({
    declarations: [
        MaaAttainmentExpandableComponent,
        MaaDecisionExpandableComponent,
        ModuleAttainmentComponent,
    ],
    imports: [
        AttainmentTreeModule,
        CommonModule,
        SisComponentsModule,
        SisCommonModule,
        TranslateModule.forChild(),
    ],
    exports: [
        MaaAttainmentExpandableComponent,
        MaaDecisionExpandableComponent,
        ModuleAttainmentComponent,
    ],
})
export class ModuleAttainmentApplicationModule {
}
