import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { AssessmentItem, CourseUnitRealisation, OtmId } from 'common-typescript/types';

import { RemainingEnrolmentCounts } from '../enrolment-right/expandable-body/enrolment-right-expandable-body.component';

@Component({
    selector: 'sis-open-university-assessment-item',
    templateUrl: './open-university-assessment-item.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpenUniversityAssessmentItemComponent {

    @Input() courseUnitRealisations: CourseUnitRealisation[];
    @Input() showCourseUnitRealisationInfoText: boolean;
    @Input() remainingEnrolmentCounts: RemainingEnrolmentCounts;
    @Input() componentType?: 'STAFF' | 'STUDENT' = 'STAFF';

    readonly DEFAULT_SHOW_LIMIT = 3;
    showLimit = this.DEFAULT_SHOW_LIMIT;

    hasNoEnrolmentLimit(): boolean {
        return this.remainingEnrolmentCounts?.total === null || this.remainingEnrolmentCounts?.total === undefined;
    }

    readonly entityId = (i: number, entity: { id: OtmId }) => entity?.id ?? entity;

    onClickShowAll(): void {
        delete this.showLimit;
    }

    onClickShowLess(): void {
        this.showLimit = this.DEFAULT_SHOW_LIMIT;
    }
}
