import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { Attainment, Grade, LocalizedString } from 'common-typescript/types';

import { hasExpired, isAboutToExpire } from '../../attainment/AttainmentUtil';

@Component({
    selector: 'sis-attainment-structure-node',
    templateUrl: './attainment-structure-node.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttainmentStructureNodeComponent {
    @Input({ required: true }) attainment: Attainment;
    @Input({ required: true }) name: LocalizedString;
    @Input({ required: true }) code: string;
    @Input({ required: true }) grade: Grade;
    @Input({ required: true }) expandableLevel: number;
    @Input() hasValidParentAttainment: boolean;
    @Input() closed: boolean = true;

    @Output() openDetailsModal = new EventEmitter<void>();
    @Output() readonly closedChange = new EventEmitter<boolean>();

    isAboutToExpire(attainment: Attainment) {
        return isAboutToExpire(attainment);
    }

    hasExpired(attainment: Attainment) {
        return hasExpired(attainment);
    }
}
