import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Enrolment } from 'common-typescript/types';
import { Observable, take } from 'rxjs';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';

import { EnrolmentStudentService } from '../../common/service/enrolment-student.service';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'app-my-studies',
    templateUrl: './my-studies.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyStudiesComponent implements OnInit {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'student.frontpage.my-studies',
        directiveName: 'appMyStudies',
    };

    enrolments$: Observable<Enrolment[]>;

    constructor(
        private enrolmentService: EnrolmentStudentService,
        private appErrorHandler: AppErrorHandler,
    ) { }

    ngOnInit(): void {
        this.enrolments$ = this.enrolmentService.getOngoingAndUpcomingEnrolments(true)
            .pipe(take(1), this.appErrorHandler.defaultErrorHandler());
    }

}
