import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { CourseUnitRealisation } from 'common-typescript/types';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'app-student-course-unit-realisation-structure',
    templateUrl: './student-course-unit-realisation-structure.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudentCourseUnitRealisationStructureComponent {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'student.downgraded.courseUnitInfoModal.courseUnitRealisation.studentCourseUnitRealisationStructure',
        directiveName: 'appStudentCourseUnitRealisationStructure',
    };

    @Input() courseUnitRealisation: CourseUnitRealisation;
    @Input() language: string;
    @Input() subGroupLevel: number;
}
