<ol class="sis-wizard-progress" [attr.aria-label]="(ariaLabel || '') + ' ' + ('ARIA_LABEL.WIZARD_PROGRESS' | translate)">
  <li class="sis-wizard-progress__step" *ngFor="let step of stepKeys; index as i"
      [ngClass]="{'sis-wizard-progress__step--current': i === currentStep, 'sis-wizard-progress__step--completed': i < currentStep}"
      [attr.aria-current]="i === currentStep">
      <span class="sis-wizard-progress__step__index">
        <ng-container *ngTemplateOutlet="i>0 ? arrowTailMobile : null; context: { arrowClass: 'sis-wizard-progress__step__arrow-mobile__tail' }"></ng-container>
        {{i+1}}.
        <ng-container *ngTemplateOutlet="i< (stepKeys.length -1) ? arrowHeadMobile : null; context: { arrowClass: 'sis-wizard-progress__step__arrow-mobile__head' }"></ng-container>
      </span>
      <span class="sis-wizard-progress__step__space">&nbsp;</span>
      <span class="sis-wizard-progress__step__title">{{step | translate}}</span>
  </li>
</ol>

<ng-template #arrowTailMobile let-arrowClass="arrowClass">
  <svg aria-hidden="true" class={{arrowClass}} width="32" height="4" viewBox="0 0 32 4" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 4V0L16 4L32 0V4H16H0Z" fill="#d4d4d4"/>
  </svg>
</ng-template>

<ng-template #arrowHeadMobile let-arrowClass="arrowClass">
  <svg aria-hidden="true" class={{arrowClass}} width="32" height="4" viewBox="0 0 32 4" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M32 0H0L16 4L32 0Z" fill="#d4d4d4"/>
  </svg>
</ng-template>
