<div>
    <div class="rule-header">
        <sis-credit-phrase
                [parentModule]="parentModule"
                [level]="level"
                [creditsRule]="creditsRule"
                [ruleValidationResult]="ruleValidationResult">
        </sis-credit-phrase>
    </div>
    <div>
        <sis-rule-select
                [rule]="creditsRule.rule"
                [parentModule]="parentModule"
                [validatablePlan]="validatablePlan"
                [planValidationResult]="planValidationResult"
                [blockLevel]="blockLevel"
                [planStateObject]="planStateObject"
                [planData]="planData"
                [uiStateObject]="uiStateObject">
        </sis-rule-select>
    </div>
</div>