export const languageModelModule = 'sis-components.model.language';
'use strict';
(function () {
  languageJSDataModel.$inject = ["DS", "$http"];
  angular.module(languageModelModule, ['js-data']).factory('languageJSDataModel', languageJSDataModel);
  function languageJSDataModel(DS, $http) {
    var model = DS.defineResource({
      idAttribute: 'urn',
      name: 'language',
      endpoint: '/kori/api/cached/codebooks/urn:code:language',
      watchChanges: false
    });
    model.lazyGetAll = _.once(function () {
      return $http.get(model.endpoint).then(function (response) {
        model.inject(_.values(response.data));
        return model.getAll();
      });
    });
    model.lazyGet = function (relJSDataModel, def, instance, orig) {
      // JSDataModel first first return undefined, and when lazyGetAll has initialized the model, the value will change
      // to the actual value by angular watch.
      //
      // Onetime binding works, as there will be a watch as long as the value is undefined
      model.lazyGetAll();
      return orig();
    };
    return model;
  }
})();