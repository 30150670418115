<label *ngIf="label" [class]="getLabelState()">{{label | translate}}</label>
<ng-select [items]="visibleOptions"
           [(ngModel)]="selectedOption"
           bindLabel="label"
           groupBy="groupLabel"
           [clearable]="clearable && !control?.disabled"
           [readonly]="control?.disabled"
           [placeholder]="'SIS_COMPONENTS.SELECT.CODE_SELECTION_EDITOR.SEARCH' | translate"
           [notFoundText]="'SIS_COMPONENTS.OBJECT.REFERRED_OBJECT_SELECT_EDITOR.NOT_FOUND_TEXT' | translate"
           [class.invalid]="control?.enabled && control.invalid && (control.touched || control.dirty)"
           (blur)="onBlur()"
           (change)="onChange($event)"
           (clear)="clearFiltering()"
           (search)="onSearch($event.term)"
           data-cy="code-select-combobox">
</ng-select>
<sis-validation-errors [control]="control"></sis-validation-errors>
