<div *ngIf="module" class="studymodule {{moduleStateObject?.colorCategoryCssClass}}" [class.dimmed]="dimmed">
    <div class="studymodule-top-row">
        <div class="title-box">
            <span class="studymodule-title">
                <sis-tooltip [tooltipText]="module.name | localizedString" [appendToBody]="true">
                    <span class="sis-tooltip-trigger-content">{{ module.name | localizedString }}</span>
                </sis-tooltip>
            </span>
            <span class="studymodule-code">
                <sis-button link="true" (clicked)="openModuleInfoModal()">
                    <sis-icon icon="brochure"></sis-icon>{{ module.code }}
                </sis-button>
                <span *ngIf="module.documentState==='DRAFT' || module.documentState==='DELETED'"
                      class="tiny-badge"
                      [class.accent]="module.documentState==='DRAFT'"
                      [class.danger]="module.documentState==='DELETED'">
                    {{ module.documentState | translate}}</span>
            </span>
        </div><!--
     --><div class="extra-items-holder">

            <ng-content select="[module-action]">

            </ng-content><!--
             --><div *ngIf="moduleStateObject?.attainmentId" class="grade-box">
            <sis-icon icon="achievement"></sis-icon>
            <span class="grade-text">{{ moduleStateObject?.grade.abbreviation | localizedString }}</span>
        </div><!--
         --><div class="credits-box">
                <span class="credit-amount">{{ visibleCredits | creditRange: 'SYMBOLS': true }}</span>

                <span class="credit-abbreviation" translate="SIS_COMPONENTS.CREDIT_RANGE.CREDITS_ABBR"></span>
            </div>
        </div>
    </div>
    <div class="studymodule-notification-bar">
        <ng-content select="[study-module-notifications]"></ng-content>
        <div class="studymodule-notification" *ngIf="moduleStateObject?.invalidSelection && !moduleStateObject.hasModuleContentApproval">
            <span class="studymodule-notification-content">
                <span translate="PLAN.INVALID_SELECTIONS.NOTIFICATION"></span>
            </span><!--
         --><span class="studymodule-notification-header"><sis-icon icon="alert-fill"></sis-icon></span>
        </div>
        <div class="studymodule-notification" *ngIf="moduleStateObject?.invalidSelectionAccordingToModuleContentApproval">
            <span class="studymodule-notification-content">
                <span translate="PLAN.INVALID_ACCORDING_TO_MODULE_CONTENT_APPROVAL.NOTIFICATION"></span>
            </span><!--
         --><span class="studymodule-notification-header"><sis-icon icon="alert-fill"></sis-icon></span>
        </div>
    </div>
</div>
