import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { numberUtils } from 'common-typescript';
import { IntRange } from 'common-typescript/types';
import _ from 'lodash';

export type IntRangePipeVariant = 'TEXT' | 'SYMBOLS';

@Pipe({ name: 'intRange' })
export class IntRangePipe implements PipeTransform {

    constructor(private translateService: TranslateService) {}

    transform(range: IntRange, variant: IntRangePipeVariant = 'TEXT'): any {
        if (_.isNil(range) || (!_.isFinite(range.min) && !_.isFinite(range.max))) {
            return '';
        }

        const min = numberUtils.numberToString(range.min);
        const max = numberUtils.numberToString(range.max);

        let output = null;
        if (!!min && !max) {
            const translationKey = variant === 'TEXT' ?
                'SIS_COMPONENTS.NUMBER.INT_RANGE.MIN' : 'SIS_COMPONENTS.NUMBER.INT_RANGE.MIN_SHORT';
            output = this.translateService.instant(translationKey, { value: min });
        }
        if (!min && !!max) {
            const translationKey = variant === 'TEXT' ?
                'SIS_COMPONENTS.NUMBER.INT_RANGE.MAX' : 'SIS_COMPONENTS.NUMBER.INT_RANGE.MAX_SHORT';
            output = this.translateService.instant(translationKey, { value: max });
        }
        if (!!min && !!max && min === max) {
            output = min;
        }
        if (!!min && !!max && min !== max) {
            output = `${min}–${max}`;
        }

        return _.isEmpty(output) ? '' : output;
    }
}
