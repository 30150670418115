// @ts-nocheck
/* eslint-disable */
import achievementSmallIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/achievement-small.svg';
import achievementIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/achievement.svg';
import alertFillAttentionIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/alert-fill-attention.svg';
import alertFillIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/alert-fill.svg';
import alertSmallIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/alert-small.svg';
import alertIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/alert.svg';
import archiveIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/archive.svg';
import arrowBigIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/arrow-big.svg';
import arrowDashedIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/arrow-dashed.svg';
import arrowSolidIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/arrow-solid.svg';
import backIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/back.svg';
import brochureIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/brochure.svg';
import bulletsIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/bullets.svg';
import calendarIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/calendar.svg';
import changeLogIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/change-log.svg';
import checkIndeterminateSmallIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/check-indeterminate-small.svg';
import checkSmallIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/check-small.svg';
import checkIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/check.svg';
import checkmarkCircleIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/checkmark-circle.svg';
import chevronRingFillIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/chevron-ring-fill.svg';
import chevronRingIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/chevron-ring.svg';
import chevronIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/chevron.svg';
import clockIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/clock.svg';
import closeBigIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/close-big.svg';
import closeIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/close.svg';
import codeIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/code.svg';
import decreaseIndentIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/decrease-indent.svg';
import deleteIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/delete.svg';
import dotIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/dot.svg';
import editIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/edit.svg';
import editorIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/editor.svg';
import exclamationMarkCircleFillIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/exclamation-mark-circle-fill.svg';
import exclamationMarkCircleIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/exclamation-mark-circle.svg';
import exclamationMarkSmallIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/exclamation-mark-small.svg';
import eyeBlindIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/eye-blind.svg';
import eyeIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/eye.svg';
import failIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/fail.svg';
import hourglassIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/hourglass.svg';
import increaseIndentIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/increase-indent.svg';
import infoCircleFillIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/info-circle-fill.svg';
import infoCircleIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/info-circle.svg';
import infoSmallIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/info-small.svg';
import infoIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/info.svg';
import junctionIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/junction.svg';
import linkIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/link.svg';
import listAddIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/list-add.svg';
import listMinusIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/list-minus.svg';
import lockOpenIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/lock-open.svg';
import lockIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/lock.svg';
import magicWandIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/magic-wand.svg';
import mailIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/mail.svg';
import menuIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/menu.svg';
import messageIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/message.svg';
import messagesIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/messages.svg';
import minusRingFillIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/minus-ring-fill.svg';
import minusIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/minus.svg';
import newTabIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/new-tab.svg';
import notebookIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/notebook.svg';
import notificationIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/notification.svg';
import numberingIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/numbering.svg';
import pdfIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/pdf.svg';
import peopleIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/people.svg';
import personSmallIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/person-small.svg';
import personIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/person.svg';
import pickerIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/picker.svg';
import pinSmallIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/pin-small.svg';
import placeRingFillIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/place-ring-fill.svg';
import placeIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/place.svg';
import plusIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/plus.svg';
import printIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/print.svg';
import questionMarkSmallIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/question-mark-small.svg';
import questionMarkIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/question-mark.svg';
import refreshIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/refresh.svg';
import requiredIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/required.svg';
import ringCloseFillIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/ring-close-fill.svg';
import ringCloseIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/ring-close.svg';
import ringPlusFillIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/ring-plus-fill.svg';
import ringPlusIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/ring-plus.svg';
import rosetteIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/rosette.svg';
import ruleIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/rule.svg';
import searchIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/search.svg';
import seatsIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/seats.svg';
import settingsIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/settings.svg';
import shoppingCartIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/shopping-cart.svg';
import sorterIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/sorter.svg';
import starIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/star.svg';
import switchIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/switch.svg';
import threeDotsSmallIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/three-dots-small.svg';
import threeDotsIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/three-dots.svg';
import waitingApprovalIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/waiting-approval.svg';
import waitingDeclineIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/waiting-decline.svg';
import zoomInIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/zoom-in.svg';
import zoomOutIcon from '!!file-loader?name=[name].[contenthash:16].[ext]!sis-assets/icons/zoom-out.svg';

export const iconMap: { [key in string]: string } = {
    'achievement-small': achievementSmallIcon,
    achievement: achievementIcon,
    'alert-fill-attention': alertFillAttentionIcon,
    'alert-fill': alertFillIcon,
    'alert-small': alertSmallIcon,
    alert: alertIcon,
    'arrow-big': arrowBigIcon,
    archive: archiveIcon,
    back: backIcon,
    'arrow-dashed': arrowDashedIcon,
    brochure: brochureIcon,
    bullets: bulletsIcon,
    calendar: calendarIcon,
    'change-log': changeLogIcon,
    'check-indeterminate-small': checkIndeterminateSmallIcon,
    'check-small': checkSmallIcon,
    check: checkIcon,
    'checkmark-circle': checkmarkCircleIcon,
    'chevron-ring-fill': chevronRingFillIcon,
    'chevron-ring': chevronRingIcon,
    clock: clockIcon,
    'close-big': closeBigIcon,
    chevron: chevronIcon,
    code: codeIcon,
    'decrease-indent': decreaseIndentIcon,
    dot: dotIcon,
    edit: editIcon,
    'arrow-solid': arrowSolidIcon,
    close: closeIcon,
    'exclamation-mark-small': exclamationMarkSmallIcon,
    delete: deleteIcon,
    'exclamation-mark-circle-fill': exclamationMarkCircleFillIcon,
    editor: editorIcon,
    'exclamation-mark-circle': exclamationMarkCircleIcon,
    eye: eyeIcon,
    'eye-blind': eyeBlindIcon,
    hourglass: hourglassIcon,
    fail: failIcon,
    'increase-indent': increaseIndentIcon,
    info: infoIcon,
    'info-small': infoSmallIcon,
    'info-circle': infoCircleIcon,
    'info-circle-fill': infoCircleFillIcon,
    link: linkIcon,
    'list-add': listAddIcon,
    'list-minus': listMinusIcon,
    junction: junctionIcon,
    'lock-open': lockOpenIcon,
    'magic-wand': magicWandIcon,
    lock: lockIcon,
    mail: mailIcon,
    menu: menuIcon,
    message: messageIcon,
    messages: messagesIcon,
    minus: minusIcon,
    'minus-ring-fill': minusRingFillIcon,
    notification: notificationIcon,
    'new-tab': newTabIcon,
    people: peopleIcon,
    notebook: notebookIcon,
    'person-small': personSmallIcon,
    picker: pickerIcon,
    person: personIcon,
    'pin-small': pinSmallIcon,
    pdf: pdfIcon,
    place: placeIcon,
    plus: plusIcon,
    'question-mark-small': questionMarkSmallIcon,
    print: printIcon,
    'question-mark': questionMarkIcon,
    refresh: refreshIcon,
    required: requiredIcon,
    'ring-plus': ringPlusIcon,
    'place-ring-fill': placeRingFillIcon,
    'ring-close-fill': ringCloseFillIcon,
    'ring-close': ringCloseIcon,
    rosette: rosetteIcon,
    rule: ruleIcon,
    seats: seatsIcon,
    search: searchIcon,
    'ring-plus-fill': ringPlusFillIcon,
    star: starIcon,
    settings: settingsIcon,
    'shopping-cart': shoppingCartIcon,
    sorter: sorterIcon,
    switch: switchIcon,
    'three-dots': threeDotsIcon,
    'waiting-approval': waitingApprovalIcon,
    'three-dots-small': threeDotsSmallIcon,
    'waiting-decline': waitingDeclineIcon,
    'zoom-out': zoomOutIcon,
    'zoom-in': zoomInIcon,
    numbering: numberingIcon,
};
