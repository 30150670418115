(function () {
  angular.module('sis-components.number.percentInput', []).directive('percentInput', percentInput);

  /**
   * @ngInject
   */

  function percentInput() {
    return {
      require: '?ngModel',
      link: function (scope, elem, attrs, ctrl) {
        if (!ctrl) {
          return;
        }
        ctrl.$formatters.unshift(function (modelValue) {
          return modelValue * 100;
        });
        ctrl.$parsers.unshift(function (viewValue) {
          return viewValue / 100;
        });
      }
    };
  }
})();