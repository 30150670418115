import { Component, Input, ViewEncapsulation } from '@angular/core';
import { AssessmentItem } from 'common-typescript/types';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-completion-method-assessment-items-details',
    templateUrl: './completion-method-assessment-items-details.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class CompletionMethodAssessmentItemsDetailsComponent {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.courseUnit.completionMethods.completionMethodAssessmentItemsDetails',
        directiveName: 'sisCompletionMethodAssessmentItemsDetails',
    };

    @Input() assessmentItem: AssessmentItem;
    @Input() language: string;
    @Input() isStudentInfo: boolean;
    @Input() responsibilityInfoHeaderLevel: number;
    @Input() useLocalizationFallback: boolean;
    @Input() showEmptyFields: boolean;

}
