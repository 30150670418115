import { Injectable } from '@angular/core';
import { Plan, StudyRight } from 'common-typescript/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PlanEntityService } from './plan-entity.service';

@Injectable({ providedIn: 'root' })
export class PrimaryPlanService {
    constructor(private readonly _planEntityService: PlanEntityService) {}

    /**
     * Returns the primary plan of the given study right,
     * or null if not found.
     */
    getPrimaryPlanForStudyRight(studyRight: StudyRight): Observable<Plan | null> {
        return this._planEntityService.getByUserIdAndEducationIdAndLearningOpportunityId(
            studyRight.educationId,
            studyRight.studentId,
            studyRight.learningOpportunityId,
        ).pipe(
            map((plans: Plan[]) => plans.find(plan => plan.primary) ?? null),
        );
    }
}
