<ng-container *transloco="let t">
  <dt>
    {{ t('ATTAINMENTS.ATTAINMENT_DETAILS.ADDITIONAL_INFO') }}
    <sis-language-pills [invalidLocales]="data|localizedStringMissingLocales"
                        [selectedLanguage]="selectedLocale"
                        (selectedLanguageChange)="setLocale($event)"></sis-language-pills>
  </dt>
  <dd>
    {{ data | localizedStringWithoutFallback:selectedLocale }}
  </dd>
</ng-container>
