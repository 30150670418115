import { Injectable } from '@angular/core';
import { PlanValidationTs, ValidatablePlan } from 'common-typescript';
import { Plan, PlanValidationState, StudyRight } from 'common-typescript/types';
import { distinctUntilChanged, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PlanLoaderService } from './plan-loader.service';

@Injectable({ providedIn: 'root' })
export class PlanValidationService {
    constructor(private readonly _planLoaderService: PlanLoaderService) {}

    /**
     * Returns a validation state concerning the whole plan and the given study right,
     * or null if not available.
     */
    getPlanValidationState(plan: Plan, studyRight: StudyRight): Observable<PlanValidationState | null> {
        return this._planLoaderService.createValidatablePlan(plan.id, studyRight.id).pipe(
            // as long as PlanValidationResult.rootValidationResult is of type "any", anything can come out of it
            map((validatablePlan: ValidatablePlan) => <PlanValidationState | null>(PlanValidationTs.validatePlan(validatablePlan).rootValidationResult?.state ?? null)),
            distinctUntilChanged(),
        );
    }
}
