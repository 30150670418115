import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '@uirouter/core';
import { MaxLength } from 'common-typescript/constants';
import { OpenUniversityCart, OpenUniversityUserBasicDetails, PrivatePerson, SuomiFiUserBasicDetails, Urn } from 'common-typescript/types';
import _ from 'lodash';
import { catchError, EMPTY } from 'rxjs';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { AlertsService, AlertType } from 'sis-components/alerts/alerts-ng.service';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { email, maxLength, required, requiredIf } from 'sis-components/form/form-validators';
import { getLabelState, getLocalDateEditorValue } from 'sis-components/form/formUtils';
import { SisFormBuilder } from 'sis-components/form/sis-form-builder.service';
import { OpenUniversityCartCustomerService } from 'sis-components/service/open-university-cart-customer.service';
import { PrivatePersonEntityService } from 'sis-components/service/private-person-entity.service';

import { OpenUniversityErrorHelperService } from '../../service/open-university-error-helper.service';

@Component({
    selector: 'app-open-university-studies-activate-wizard-step2',
    templateUrl: './open-university-studies-activate-wizard-step2.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class OpenUniversityStudiesActivateWizardStep2Component implements OnInit {

    readonly countryCodeBookUrn = 'urn:code:country';
    readonly finnishCitizenshipUrns = ['urn:code:country:246', 'urn:code:country:248'];

    @Input() wizardProgressStepKeys: string[] = [];
    @Input() openUniversityCart: OpenUniversityCart;
    @Input() isSuomiFiPath: boolean;
    @Input() suomiFiUserBasicDetails?: SuomiFiUserBasicDetails;
    @Output() goToLastStep = new EventEmitter<LastStepValuesInterface>();
    onGoingRedeemProcess = false;
    isNewSuomiFiSignin = false;
    isStaffUser = false;
    isPersonInfoInputRequiredView = false;

    person: PrivatePerson = null;
    form: FormGroup;

    getLabelState = getLabelState;

    constructor(private privatePersonEntityService: PrivatePersonEntityService,
                private openUniversityCartCustomerService: OpenUniversityCartCustomerService,
                private openUniversityErrorHelper: OpenUniversityErrorHelperService,
                private fb: SisFormBuilder,
                private alertService: AlertsService,
                private translateService: TranslateService,
                private localeService: LocaleService,
                private appErrorHandler: AppErrorHandler,
                private stateService: StateService) {
        this.isFinnishCitizen = this.isFinnishCitizen.bind(this);

        this.citizenshipUrns?.valueChanges
            .pipe(takeUntilDestroyed())
            .subscribe(() => {
                this.personalIdentityCode.updateValueAndValidity();
            });
    }

    ngOnInit(): void {
        if (this.isSuomiFiPath) {
            this.isPersonInfoInputRequiredView = this.isSuomiFiPath;
            this.isNewSuomiFiSignin = !this.suomiFiUserBasicDetails?.isExistingUser;
            this.buildForm();

            if (this.suomiFiUserBasicDetails?.isExistingUser) {
                this.initPreExistingUserAlert();
            }
        } else {
            this.getPersonInfo();
        }
    }

    private buildForm() {
        this.form = this.fb.group({
            secondaryEmail: this.fb.control(this.suomiFiUserBasicDetails.secondaryEmail, [email(), required(), maxLength(MaxLength.MAX_SHORT_STRING_LENGTH)]),
            phoneNumber: this.fb.control(this.suomiFiUserBasicDetails.phoneNumber, [required(), maxLength(MaxLength.MAX_SHORT_STRING_LENGTH)]),
        });
        if (this.isNewSuomiFiSignin) {
            this.form.addControl('citizenshipUrn', this.fb.control(undefined, required()));
            this.form.addControl('municipalityUrn', this.fb.control(undefined, required()));
            this.form.addControl('motherTongueUrn', this.fb.control(undefined, required()));
            this.form.addControl('preferredLanguageUrn', this.fb.control(this.getCurrentLanguageUrn(), required()));
            this.form.addControl('genderUrn', this.fb.control(undefined, required()));
        }
    }

    private buildFormForStaffUser() {
        this.form = this.fb.group({
            citizenshipUrns: this.fb.array((this.person.citizenshipUrns || []).map(citizenshipUrn => this.fb.control(citizenshipUrn, Validators.required)), required()),
            personalIdentityCode: this.fb.control(this.person.personalIdentityCode, [requiredIf(this.isFinnishCitizen), maxLength(11)]),
            dateOfBirth: this.fb.localDate(this.person.dateOfBirth, { required: true, maxDate: new Date() }),
            secondaryEmail: this.fb.control(this.person.secondaryEmail, [email(), required(), maxLength(MaxLength.MAX_SHORT_STRING_LENGTH)]),
            phoneNumber: this.fb.control(this.person.phoneNumber, [required(), maxLength(MaxLength.MAX_SHORT_STRING_LENGTH)]),
            municipalityUrn: this.fb.control(this.person.municipalityUrn, required()),
            motherTongueUrn: this.fb.control(this.person.motherTongueUrn, required()),
            preferredLanguageUrn: this.fb.control(this.person.preferredLanguageUrn, required()),
            genderUrn: this.fb.control(this.person.genderUrn, required()),
        });
    }

    private getCurrentLanguageUrn(): Urn {
        return `urn:code:preferred-language:${this.localeService.getCurrentLanguage()}`;
    }

    private getPersonInfo() {
        this.privatePersonEntityService.getUserDetails()
            .pipe(this.appErrorHandler.defaultErrorHandler())
            .subscribe((person: PrivatePerson) => {
                this.person = person;
                this.isUsersOnlyRoleStaff();
                if (this.isStaffUser) {
                    this.buildFormForStaffUser();
                }
            });
    }

    private isUsersOnlyRoleStaff() {
        this.isStaffUser = this.person.studentStatus === 'NONE' && this.person.employeeStatus === 'ACTIVE';
        this.isPersonInfoInputRequiredView = this.isStaffUser;
    }

    startRedeemProcess() {
        if (this.onGoingRedeemProcess) {
            return;
        }
        this.onGoingRedeemProcess = true;
        let openUniversityUserBasicDetails: OpenUniversityUserBasicDetails = null;

        if (this.isSuomiFiPath) {
            openUniversityUserBasicDetails = this.getOpenUniversityUserBasicDetails();
        }

        if (this.isStaffUser) {
            openUniversityUserBasicDetails = { ...this.form?.value, dateOfBirth: getLocalDateEditorValue(this.dateOfBirth) };
        }

        if (!this.isPersonInfoInputRequiredView || this.checkFormValidity()) {
            this.openUniversityCartCustomerService.activateOpenUniversityCartWithActivationCode(this.openUniversityCart.id,
                                                                                                this.openUniversityCart.activationCode,
                                                                                                openUniversityUserBasicDetails)
                .pipe(
                    // First handle the error logic with catchError and rethrow the error
                    // defaultErrorHandler will consume the error and return EMPTY
                    // This will result in the observable completing (next will not get called if an error happens)
                    catchError((err) => {
                        if (err.status === 401) {
                            // This can happen when user stays in this view for too long and the session will timeout.
                            this.sessionTimeout();
                        } else {
                            this.openUniversityErrorHelper.showEnrolmentConfirmationFailureAlert(this.openUniversityCart.orderNumber);
                        }
                        return EMPTY;
                    }),
                )
                .subscribe(() => this.goToLastStep.emit({ secondaryEmail: openUniversityUserBasicDetails?.secondaryEmail, isStaffUser: this.isStaffUser }))
                .add(() => this.onGoingRedeemProcess = false);

        }
    }

    private getOpenUniversityUserBasicDetails(): OpenUniversityUserBasicDetails {
        return {
            ...this.suomiFiUserBasicDetails,
            ...this.form?.value,
            citizenshipUrns: (this.citizenshipUrn?.value ? [this.citizenshipUrn.value] : []),
        };
    }

    private checkFormValidity(): boolean {
        const isValid = this.form.valid;
        const identifier = 'open-uni-activate-wizard-validation-err';

        if (!isValid) {
            this.form?.markAllAsTouched();
            this.initValidationErrorAlert(identifier);
            this.onGoingRedeemProcess = false;
        } else {
            this.alertService.dismissAlertIfExists(identifier);
        }

        return isValid;
    }

    private initValidationErrorAlert(identifier: string) {
        this.alertService.addAlert({
            message: this.translateService.instant('OPEN_UNIVERSITY.VALIDATION_ERROR_ALERT'),
            type: AlertType.DANGER,
            scrollToElement: 'cart-error-class',
            identifier,
        });
    }

    private initPreExistingUserAlert() {
        this.alertService.addAlert({
            message: this.translateService.instant('OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.SUOMI_FI.PRE_EXISTING_SISU_USER'),
            type: AlertType.INFO,
        });
    }

    private sessionTimeout() {
        this.alertService.addAlert({
            message: this.translateService.instant('OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.SESSION_TIMEOUT_ALERT'),
            type: AlertType.DANGER,
        });
        this.stateService.go('student.open-university-studies-activate',
                             {
                                 openUniversityCartId: this.openUniversityCart.id,
                                 activationCode: this.openUniversityCart.activationCode,
                             },
                             { custom: { skipConfirmationDialog: true } });
    }

    onSelectCitizenshipUrn(control: AbstractControl, urn: Urn): void {
        if (urn !== control.value) {
            control.setValue(urn);
            control.markAsTouched();
            control.markAsDirty();
            this.personalIdentityCode.updateValueAndValidity();
        }
    }

    isFinnishCitizen(): boolean {
        return _.intersection(this.finnishCitizenshipUrns, _.get(this.citizenshipUrns, 'value', [])).length > 0;
    }

    canAddCitizenship(): boolean {
        return this.citizenshipUrns.length < 20 && !(this.citizenshipUrns.value as Urn[]).some(urn => !urn);
    }

    addCitizenship(): void {
        this.citizenshipUrns.push(this.fb.control(null, required()));
    }

    get email(): FormControl {
        return this.form?.get('secondaryEmail') as FormControl;
    }

    get phone(): FormControl {
        return this.form?.get('phoneNumber') as FormControl;
    }

    // For new student created with suomi.fi require only one citizenshipUrn.
    get citizenshipUrn(): FormControl {
        return this.form?.get('citizenshipUrn') as FormControl;
    }

    // For new student created from existing staff user, we require all citizenshipUrns.
    get citizenshipUrns(): FormArray | null {
        return this.form ? this.form.get('citizenshipUrns') as FormArray : null;
    }

    get municipalityUrn(): FormControl {
        return this.form?.get('municipalityUrn') as FormControl;
    }

    get motherTongueUrn(): FormControl {
        return this.form?.get('motherTongueUrn') as FormControl;
    }

    get genderUrn(): FormControl {
        return this.form?.get('genderUrn') as FormControl;
    }

    get preferredLanguageUrn(): FormControl {
        return this.form?.get('preferredLanguageUrn') as FormControl;
    }

    get personalIdentityCode(): FormControl {
        return this.form?.get('personalIdentityCode') as FormControl;
    }

    get dateOfBirth(): FormControl {
        return this.form?.get('dateOfBirth') as FormControl;
    }
}

export interface LastStepValuesInterface {
    secondaryEmail?: string;
    isStaffUser?: boolean;
}

