import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'sis-text-with-default-missing-value',
    templateUrl: './text-with-default-missing-value.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class TextWithDefaultMissingValueComponent implements OnChanges {
    constructor(private translate: TranslateService) {
    }

    @Input() isOption?: boolean;
    @Input() value?: string;
    @Input() valueKey?: string;

    renderedValue: string;
    hasValue = false;
    missingValue = '';

    ngOnChanges(): void {
        this.renderedValue = this.value || this.valueKey;
        this.hasValue = !!this.renderedValue;
        this.missingValue = this.isOption ? '-' : this.translate.instant('INFORMATION_MISSING');
    }
}
