<ng-container *ngIf="hasOpenUniversityOffering === false">
  <p class="sis-guidance">{{'OPEN_UNIVERSITY.STUDY_OFFERING.NO_PRODUCTS' | translate}}</p>
</ng-container>
<ng-container *ngIf="hasOpenUniversityOffering">

  <div *ngIf="isCurrentOrgIdDifferentThanAllProductOrgIds" class="mb-3">
    <sis-contextual-notification variant="warning">
      <span>
          {{ 'OPEN_UNIVERSITY.STUDY_OFFERING.UNIVERSITY_ORG_ID_MISS_MATCH' | translate:
            { currentUniversityOrg: (currentOrgId | organisation | async)?.name | localizedString } }}
      </span>
    </sis-contextual-notification>
  </div>

  <p *ngIf="courseUnit?.tweetText" class="tweet-text">{{courseUnit.tweetText | localizedStringWithoutFallback}}</p>
  <div class="mb-5">
    <h3 data-cy="basic-info-header">{{'OPEN_UNIVERSITY.STUDY_OFFERING.BASIC_INFO' | translate}}</h3>
    <app-course-unit-info-basic-info-header [courseUnit]="courseUnit"></app-course-unit-info-basic-info-header>
  </div>
  <div class="mb-5">
    <div class="mb-5 mb-sm-0">
      <sis-tab-content-switch type="pills"
                              [currentIndex]="currentTabIndex"
                              (currentIndexChange)="onTabChange($event)"
                              [tabs]="[
        { title: 'OPEN_UNIVERSITY.STUDY_OFFERING.CURRENT_TEACHING' | translate, tail: currentProducts?.length || 0, id: 'header-0' },
        { title: 'OPEN_UNIVERSITY.STUDY_OFFERING.FUTURE_TEACHING' | translate, tail: futureProducts?.length || 0, id: 'header-1'}
      ]"></sis-tab-content-switch>
    </div>

    <ng-container *ngIf="currentTabIndex === TAB.CURRENT_PRODUCTS">
      <ng-container *ngTemplateOutlet="carousel; context: {products: currentProducts}"></ng-container>
    </ng-container>
    <ng-container *ngIf="currentTabIndex === TAB.FUTURE_PRODUCTS">
      <ng-container *ngTemplateOutlet="carousel; context: {products: futureProducts}"></ng-container>
    </ng-container>

    <div *ngIf="!isMobileView && selectedProduct" class="product-container">
      <app-product-details [courseUnit]="courseUnit"
                           [product]="selectedProduct"
                           [mobileView]="false">
      </app-product-details>
    </div>
  </div>
</ng-container>

<ng-template #carousel let-products="products">
  <h3 id="product-selection-header-tab-{{currentTabIndex}}"
      [attr.data-cy]="'product-selection-header-tab-' + currentTabIndex">
    <ng-container *ngIf="currentTabIndex === TAB.CURRENT_PRODUCTS">{{'OPEN_UNIVERSITY.STUDY_OFFERING.CURRENT_TEACHING' | translate}}</ng-container>
    <ng-container *ngIf="currentTabIndex === TAB.FUTURE_PRODUCTS">{{'OPEN_UNIVERSITY.STUDY_OFFERING.FUTURE_TEACHING' | translate}}</ng-container>
  </h3>

  <app-product-carousel *ngIf="products?.length > 0"
                        [products]="products"
                        [initialSelection]="selectedProduct?.id"
                        [courseUnit]="courseUnit"
                        [mobileView]="isMobileView"
                        [name]="'products-on-sale-tab-' + currentTabIndex"
                        [carouselHeaderId]="'products-on-sale-title' + currentTabIndex"
                        (selected)="onProductSelect($event)">
  </app-product-carousel>

  <p *ngIf="products?.length === 0" class="sis-guidance">
    <ng-container *ngIf="currentTabIndex === TAB.CURRENT_PRODUCTS">{{'OPEN_UNIVERSITY.STUDY_OFFERING.NO_CURRENT_PRODUCTS' | translate}}</ng-container>
    <ng-container *ngIf="currentTabIndex === TAB.FUTURE_PRODUCTS">{{'OPEN_UNIVERSITY.STUDY_OFFERING.NO_FUTURE_PRODUCTS' | translate}}</ng-container>
  </p>
</ng-template>
