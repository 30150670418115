import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { CompositeRule, CourseUnitCountRule, CreditRange, DecimalNumberRange, Education, Module, RangeValidationResultState } from 'common-typescript/types';
import _ from 'lodash';
import {
    ComponentDowngradeMappings,
    DowngradedComponent,
    StaticMembers,
} from 'sis-common/types/angular-hybrid';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-count-phrase',
    templateUrl: './count-phrase.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class CountPhraseComponent implements OnChanges {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.plan.rules.header.countPhrase',
        directiveName: 'sisCountPhrase',
    };

    isOk: boolean;
    implicitCount: number;
    directCount: number;
    selected: number;
    tooFewSelected: boolean;
    tooManySelected: boolean;
    addRange: DecimalNumberRange;
    removeRange: DecimalNumberRange;
    targetRange: DecimalNumberRange;
    require: DecimalNumberRange;

    @Input() ruleValidationResult: any;
    @Input() rule: CompositeRule;

    ngOnChanges() {
        this.update();
    }

    update(): void {
        this.isOk = this.ruleValidationResult ? this.ruleValidationResult.result === RangeValidationResultState.OK : false;
        this.require = _.get(this.rule, 'require') || { min: _.size(this.rule.rules), max: _.size(this.rule.rules) };
        this.implicitCount = _.result(this.ruleValidationResult, 'implicitCount', 0);
        this.directCount = _.result(this.ruleValidationResult, 'directCount', 0);
        this.selected = this.implicitCount + this.directCount;
        this.tooFewSelected = !!this.require.min && this.selected < this.require.min;
        this.tooManySelected = !!this.require.max && this.selected > this.require.max;
        this.addRange = this.getAddRange();
        this.removeRange = this.getRemoveRange();
        this.targetRange = this.getTargetRange();
    }

    getAddRange(): DecimalNumberRange {
        const result = {
            min: 0,
            max: undefined,
        } as DecimalNumberRange;

        if (this.tooFewSelected) {
            result.min = this.require.min - this.selected;
            if (this.require.max) {
                result.max = this.require.max - this.selected;
            }
        }

        return result;
    }

    getRemoveRange(): DecimalNumberRange {
        const result = {
            min: 0,
            max: 0,
        } as DecimalNumberRange;

        if (this.tooManySelected) {
            result.min = this.selected - this.require.max;
            const min = this.require.min ? this.require.min : 0;
            result.max = this.selected - min;
        }

        return result;
    }

    getTargetRange(): DecimalNumberRange {
        if (this.ruleValidationResult) {
            return {
                min: _.get(this.ruleValidationResult, 'minRequired'),
                max: _.get(this.ruleValidationResult, 'maxAllowed'),
            } as DecimalNumberRange;
        }
        return null;
    }

}
