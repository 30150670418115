import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { OtmId } from 'common-typescript/types';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { SelectOption } from '../select/select-combobox/select-combobox.component';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-version-selector',
    templateUrl: './version-selector.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VersionSelectorComponent {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.versionSelector.downgraded',
        directiveName: 'sisVersionSelector',
    };

    @Output() change = new EventEmitter<OtmId>();
    @Input() currentVersionId: OtmId;
    @Input() popoverInfoKey: string;
    @Input() popoverLabelKey: string;
    @Input() popoverPlacement = 'top';
    @Input() options: SelectOption[];
    popoverShown: boolean;

    selectVersion(versionId: OtmId): void {
        this.change.emit(versionId);
    }
}
