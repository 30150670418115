import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { OtmId, StudyEvent } from 'common-typescript/types';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

import { EntityService } from './entity.service';

const CONFIG = {
    ENDPOINTS: {
        backend: '/kori/api',
    },
};

@StaticMembers<DowngradedService>()
@Injectable({
    providedIn: 'root',
})
@NgEntityServiceConfig({
    baseUrl: CONFIG.ENDPOINTS.backend,
})
export class StudyEventEntityService extends EntityService<StudyEventsState> {

    static downgrade: ServiceDowngradeMappings = {
        dependencies: [],
        moduleName: 'sis-components.service.studyEventEntityService',
        serviceName: 'studyEventEntityService',
    };

    constructor() {
        super(StudyEventStore, StudyEventQuery);
    }
}

type StudyEventsState = EntityState<StudyEvent, OtmId>;

@StoreConfig({ name: 'study-events' })
class StudyEventStore extends EntityStore<StudyEventsState> {}

class StudyEventQuery extends QueryEntity<StudyEventsState> {
    constructor(protected store: StudyEventStore) {
        super(store);
    }
}
