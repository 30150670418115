"use strict";

(function () {
  studyPeriodService.$inject = ["$http", "$translate", "studyPeriodModel", "universityService"];
  angular.module('student.common.service.studyPeriodService', ['pascalprecht.translate', 'student.common.model.studyPeriod', 'sis-common.university']).factory('studyPeriodService', studyPeriodService);

  /**
   * @ngInject
   */
  function studyPeriodService($http, $translate, studyPeriodModel, universityService) {
    var baseUri = "/kori/api/study-periods";
    var orgIndex = 0;
    var yearIndex = 1;
    return {
      getStudyYears: getStudyYears,
      getPeriodsFor: getPeriodsFor,
      getCurrentAndUpcomingStudyPeriods: getCurrentAndUpcomingStudyPeriods,
      getStudyYearTemplatesForCourseUnit: getStudyYearTemplatesForCourseUnit,
      transformRepeatArrayToStudyPeriodRangesArray: transformRepeatArrayToStudyPeriodRangesArray,
      extractStudyYearPeriodFromPossibility: extractStudyYearPeriodFromPossibility,
      addSpecifiersToDuplicateStudyPeriodNames: addSpecifiersToDuplicateStudyPeriodNames
    };
    function getStudyYearTemplatesForCourseUnit(courseUnit) {
      var currentUniversityOrgId = courseUnit.universityOrgIds;
      var params = {
        organisationId: currentUniversityOrgId
      };
      return $http.get('/kori/api/study-year-templates', {
        params: params,
        cache: true
      }).then(function (result) {
        return result.data;
      });
    }
    function getStudyYears(org, firstYear, numYears) {
      return $http.get(baseUri + "/" + org + "/" + firstYear + "/" + numYears, {
        cache: true
      }).then(function (result) {
        return new studyPeriodModel.newStudyYears(result.data);
      });
    }

    /**
     * Returns the currently ongoing and all upcoming study periods for the current study year, and all
     * study periods for the next study year.
     *
     * @return {StudyPeriod[]}
     */
    function getCurrentAndUpcomingStudyPeriods() {
      var universityOrgId = universityService.getCurrentUniversityOrgId();
      var firstYear = moment().year() - 1;
      var today = moment();
      var todayNextYear = moment().add(1, 'years');
      return getStudyPeriods(universityOrgId, firstYear, 3).then(function (periods) {
        return _.filter(periods, function (period) {
          var periodEndDate = moment(_.get(period, 'valid.endDate'));
          var studyYearStartDate = moment(_.get(period, '$year.startDate'));
          return periodEndDate.isValid() && studyYearStartDate.isValid() && periodEndDate.isAfter(today, 'day') && studyYearStartDate.isSameOrBefore(todayNextYear, 'day');
        });
      });
    }
    function getStudyPeriods(universityOrgId, firstYear, numYears) {
      // Accept only positive integers for amount of study years to fetch, default to 1
      numYears = _.isInteger(numYears) ? Math.max(numYears, 1) : 1;
      return getStudyYears(universityOrgId, firstYear, numYears).then(function (studyYears) {
        var years = _.filter(studyYears, function (year) {
          var firstYearInt = parseInt(firstYear);
          return year.startYear >= firstYearInt && year.startYear < firstYearInt + numYears;
        });
        var periods = [];
        _.forEach(years, function (year) {
          _.forEach(year.$studyTerms, function (studyTerm) {
            _.forEach(studyTerm.$studyPeriods, function (period) {
              periods.push(period);
            });
          });
        });
        return periods;
      });
    }
    function getPeriodsFor(locator) {
      var parts = locator.split('/');
      var organisation = parts[orgIndex];
      var firstYear = parts[yearIndex];
      return getStudyPeriods(organisation, firstYear).then(function (periods) {
        return _.find(periods, function (period) {
          return period.locator === locator;
        });
      });
    }
    function extractStudyYearPeriodFromPossibility(possibilityString, studyYearTemplates) {
      var possibility = possibilityString.split('/');
      var org = possibility[0];
      var year = parseInt(possibility[1], 10);
      var term = parseInt(possibility[2], 10);
      var period = parseInt(possibility[3], 10);
      var templates = _.filter(studyYearTemplates, function (template) {
        return !_.isNil(template.org) && template.org === org;
      });
      var template = _.first(_.filter(templates, function (template) {
        if (_.isNil(template.valid)) {
          return true;
        }
        var firstDay = moment(new Date(year, 7, 1));
        var periodEndDate = moment(_.get(template, 'valid.endDate'));
        var beforeEndDateOrNoEndDate = periodEndDate.isValid() ? periodEndDate.isAfter(firstDay, 'day') : true;
        var periodStartDate = moment(_.get(template, 'valid.startDate'));
        return beforeEndDateOrNoEndDate && periodStartDate.isValid() && periodStartDate.isSameOrBefore(firstDay, 'day');
      }));
      var order = period;
      for (var i = 0; i < term; i++) {
        var length = _.get(template, 'studyTerms[' + i + '].studyPeriods.length');
        if (length === undefined) {
          return undefined;
        }
        order = order + length;
      }
      var studyYearPeriod = _.clone(_.get(template, 'studyTerms[' + term + '].studyPeriods[' + period + ']'));
      if (studyYearPeriod) {
        return _.merge(studyYearPeriod, {
          order: order
        });
      } else {
        return undefined;
      }
    }
    function transformRepeatArrayToStudyPeriodRangesArray(repeatPossibility, studyYearTemplates) {
      var reducedTemplatedPossibilities = _(repeatPossibility).map(function (onePossibility) {
        return extractStudyYearPeriodFromPossibility(onePossibility, studyYearTemplates);
      }).sortBy('order').transform(function (result, template) {
        if (!template) {
          return true;
        }
        var lastAddition = result[result.length - 1];
        if (lastAddition && lastAddition.length > 0) {
          var latestTemplate = lastAddition.pop();
          if (template.order - latestTemplate.order === 1) {
            if (lastAddition.length === 0) {
              lastAddition.push(latestTemplate);
            }
            lastAddition.push(template);
            return true;
          } else {
            lastAddition.push(latestTemplate);
          }
        }
        result.push([template]);
        return true;
      }).value();

      //clean obsolete field order
      _(reducedTemplatedPossibilities).flatMap().forEach(function (possibility) {
        _.unset(possibility, 'order');
      });
      return reducedTemplatedPossibilities;
    }

    /**
     * Adds "first" and "last" specifiers to duplicate study period names to make them unique. If there are
     * more than two duplicates, it is up to the universities to define less of them.
     *
     * @param studyPeriods study periods, must be in ascending date range order; the passed value is mutated
     */
    function addSpecifiersToDuplicateStudyPeriodNames(studyPeriods) {
      _(studyPeriods).groupBy(function (studyPeriod) {
        return studyPeriod.name + ' ' + studyPeriod.$year.name;
      }).pickBy(function (studyPeriodsWithCertainName) {
        return studyPeriodsWithCertainName.length > 1;
      }).values().forEach(function (duplicateStudyPeriods) {
        _.first(duplicateStudyPeriods).name += ' ' + $translate.instant('SEARCH_STUDYPERIOD_OPTION_FIRST_PERIOD');
        _.last(duplicateStudyPeriods).name += ' ' + $translate.instant('SEARCH_STUDYPERIOD_OPTION_LAST_PERIOD');
      });
    }
  }
})();