import { Injectable } from '@angular/core';
import { OtmId } from 'common-typescript/types';
import { AssessmentItem, AssessmentItemAttainment } from 'common-typescript/types/generated/common-backend';
import { NGXLogger } from 'ngx-logger';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AssessmentItemEntityService } from 'sis-components/service/assessment-item-entity.service';

@Injectable()
export class AssessmentItemReferenceService {
    constructor(
        private readonly _assessmentItemEntityService: AssessmentItemEntityService,
        private readonly _ngxLogger: NGXLogger,
    ) { }

    /**
     * Resolves assessment item references of the given assessment item attainments.
     * The result will have entries for given assessment item attainments referring to existing assessment items,
     * with {@link AssessmentItemAttainment.id} as key.
     */
    getAssessmentItemPerAttainmentId(assessmentItemAttainments: readonly AssessmentItemAttainment[]): Observable<ReadonlyMap<OtmId, AssessmentItem>> {
        if (!assessmentItemAttainments.length) {
            return of(new Map<OtmId, AssessmentItem>());
        }

        const assessmentItemFetches: Observable<{ assessmentItemAttainmentId: OtmId, assessmentItem: AssessmentItem | null }>[] = [];

        for (const assessmentItemAttainment of assessmentItemAttainments) {
            if (!!assessmentItemAttainment.assessmentItemId && !!assessmentItemAttainment.attainmentDate) {
                const assessmentItemFetch: Observable<{ assessmentItemAttainmentId: OtmId, assessmentItem: AssessmentItem | null }> = this._assessmentItemEntityService.getSnapshotAtDateTime(assessmentItemAttainment.assessmentItemId, assessmentItemAttainment.attainmentDate).pipe(
                    // Backend can return an error, if a requested assessment item snapshot doesn't exist, so let's just fall back to null.
                    // In fact, it's very probable, that if _any_ of the requested snapshots doesn't exist, all getSnapshotAtDateTime calls will emit an error,
                    // because AssessmentItemEntityService combines individual requests, and the backend API either finds all snapshots or throws an exception.
                    catchError((err: any) => {
                        this._ngxLogger.error(`AssessmentItem snapshot not found id: ${assessmentItemAttainment.assessmentItemId} atDate: ${assessmentItemAttainment.attainmentDate}`, err);
                        return of(null);
                    }),
                    map((assessmentItem: AssessmentItem | null) => ({ assessmentItemAttainmentId: assessmentItemAttainment.id, assessmentItem })),
                );
                assessmentItemFetches.push(assessmentItemFetch);
            }
        }

        if (!assessmentItemFetches.length) {
            return of(new Map<OtmId, AssessmentItem>());
        }

        return combineLatest(assessmentItemFetches).pipe(
            map((assessmentItemFetchResults: { assessmentItemAttainmentId: OtmId, assessmentItem: AssessmentItem | null }[]) => new Map<OtmId, AssessmentItem>(assessmentItemFetchResults
                .filter(assessmentItemFetchResult => assessmentItemFetchResult.assessmentItem)
                .map(assessmentItemFetchResult => [assessmentItemFetchResult.assessmentItemAttainmentId, assessmentItemFetchResult.assessmentItem]),
            )),
        );
    }
}
