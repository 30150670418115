import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Education, EnrolmentRight, Organisation, OtmId, StudyRight } from 'common-typescript/types';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { EducationEntityService } from 'sis-components/service/education-entity.service';
import { EnrolmentRightEntityService } from 'sis-components/service/enrolment-right-entity.service';
import { OrganisationEntityService } from 'sis-components/service/organisation-entity.service';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'app-enrolment-right-student-study-right',
    templateUrl: './enrolment-right-student-study-right.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class EnrolmentRightStudentStudyRightComponent implements OnInit, OnDestroy {
    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'student.downgraded.profile.enrolmentRightStudentStudyRight',
        directiveName: 'appEnrolmentRightStudentStudyRight',
    };

    @Input() studyRight: StudyRight;
    organisation: Organisation;
    enrolmentRights: EnrolmentRight[] = [];
    education: Education;
    currentTermRegistration: any;
    tuitionFeeObligationPeriods: any;
    loaded: boolean;
    private destroyed$ = new Subject<void>();

    readonly entityId = (i: number, entity: { id: OtmId }) => entity?.id ?? entity;

    constructor(private educationService: EducationEntityService,
                private enrolmentRightService: EnrolmentRightEntityService,
                private organisationService: OrganisationEntityService,
                private appErrorHandler: AppErrorHandler) {
        this.loaded = false;
    }

    ngOnInit() {
        combineLatest([
            this.organisationService.getById(this.studyRight.organisationId),
            this.enrolmentRightService.getByStudyRightIds(this.studyRight.id),
            this.educationService.getById(this.studyRight.educationId),
        ])
            .pipe(
                this.appErrorHandler.defaultErrorHandler(),
                takeUntil(this.destroyed$),
            )
            .subscribe({
                next: ([organisation, enrolmentRights, education]) => {
                    this.organisation = organisation;
                    this.enrolmentRights = enrolmentRights?.filter(enrolmentRight => enrolmentRight?.documentState === 'ACTIVE');
                    this.education = education;
                    this.loaded = true;
                } });
        this.currentTermRegistration = null;
        this.tuitionFeeObligationPeriods = null;
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
    }
}
