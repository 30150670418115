import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxFudisModule } from '@funidata/ngx-fudis';
import { TranslateModule } from '@ngx-translate/core';
import { SisCommonModule } from 'sis-common/sis-common.module';
import { SisComponentsModule } from 'sis-components/sis-components.module';

import { CustomerServiceContactLinkComponent } from './customer-service-contact-link/customer-service-contact-link.component';
import { OpenUniversityEnrolledInfoComponent } from './open-university-enrolled-info/open-university-enrolled-info.component';
import { ProductBasicInfoComponent } from './product-basic-info/product-basic-info.component';
import { StudyEventInfoListComponent } from './study-event-info-list/study-event-info-list.component';
import { StudyEventInfoComponent } from './study-event-info/study-event-info.component';

@NgModule({
    declarations: [
        CustomerServiceContactLinkComponent,
        ProductBasicInfoComponent,
        StudyEventInfoComponent,
        StudyEventInfoListComponent,
        OpenUniversityEnrolledInfoComponent,
    ],
    imports: [
        CommonModule,
        SisCommonModule,
        SisComponentsModule,
        TranslateModule.forChild(),
        NgxFudisModule,
    ],
    exports: [
        CustomerServiceContactLinkComponent,
        ProductBasicInfoComponent,
        StudyEventInfoComponent,
        StudyEventInfoListComponent,
        OpenUniversityEnrolledInfoComponent,
    ],
})
export class OpenUniversityCommonModule {}
