import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    Message,
    MessageCategoryType,
    MessageConversation,
    OtmId,
    ReceivedMessageTypeFilter,
    SearchResult,
} from 'common-typescript/types';
import _ from 'lodash';
import { Observable } from 'rxjs';

import { MessageTypes } from '../constant/messageTypes';

export interface MessageConversationsSearch {
    recipientId: string;
    studentSearch: string;
    filterType: ReceivedMessageTypeFilter | null;
    messageTypes: string[];
    messageCategoryType: MessageCategoryType | null;
    isRead: boolean | null;
    isArchived: boolean | null;
    start: number | null;
    limit: number | null;
}

@Injectable({ providedIn: 'root' })
export class MessageConversationService {

    private readonly receivedMessageConversationsUri = '/osuva/api/message-conversations/received';
    private readonly receivedMessageConversationsUriForStaff = '/osuva/api/message-conversations/received/for-staff';
    private readonly receivedForConversationUri = '/osuva/api/messages/received-for-conversation';
    private readonly receivedForConversationUriForStaff = '/osuva/api/messages/received-for-conversation/for-staff';
    private readonly markAsReadUri = '/osuva/api/messages/mark-as-read';
    private readonly markAsUnreadUri = '/osuva/api/messages/mark-as-unread';
    private readonly markAsArchivedUri = '/osuva/api/messages/mark-as-archived';
    private readonly markAsNotArchivedUri = '/osuva/api/messages/mark-as-not-archived';

    constructor(private http: HttpClient) { }

    findReceivedMessageConversationsForStudent(
        personId: OtmId, limit: number,
    ): Observable<SearchResult<MessageConversation>> {
        return this.http.get(
            this.receivedMessageConversationsUri,
            {
                params: {
                    recipientId: personId,
                    filterType: 'STUDENT',
                    limit: String(limit),
                },
            },
        ) as Observable<SearchResult<MessageConversation>>;
    }

    findReceivedMessageConversationsForFrontpage(
        recipientId: OtmId,
        categoryType: MessageCategoryType,
        userType: 'STAFF' | 'STUDENT',
        receivedMessageTypeFilter: ReceivedMessageTypeFilter,
        limit: number,
    ): Observable<SearchResult<MessageConversation>> {
        const uri = userType === 'STAFF' ? this.receivedMessageConversationsUriForStaff : this.receivedMessageConversationsUri;
        return this.http.get(
            uri,
            {
                params: {
                    recipientId,
                    messageCategoryType: categoryType,
                    filterType: receivedMessageTypeFilter,
                    limit: String(limit),
                },
            },
        ) as Observable<SearchResult<MessageConversation>>;
    }

    getReceivedMessagesForConversation(recipientId: string,
                                       conversationId: string,
                                       receivedMessageTypeFilter: ReceivedMessageTypeFilter,
                                       userType: 'STAFF' | 'STUDENT',
                                       messageCategoryType?: string): Observable<Message[]> {
        const params: HttpParams = new HttpParams()
            .set('recipientId', recipientId)
            .set('conversationId', conversationId)
            .set('receivedMessageTypeFilter', receivedMessageTypeFilter);
        const uri = userType === 'STAFF' ? this.receivedForConversationUriForStaff : this.receivedForConversationUri;
        return this.http.get(
            uri,
            { params: messageCategoryType ? params.set('messageCategoryType', messageCategoryType) : params },
        ) as Observable<Message[]>;
    }

    markMessageAsRead(messageId: string): Observable<void> {
        return this.http.put<void>(this.markAsReadUri, null, { params: { messageId } });
    }

    markMessageConversationsAsRead(messageConversationIds: string[]): Observable<void> {
        return this.http.put<void>(this.markAsReadUri, null, { params: { conversationId: messageConversationIds } });
    }

    markMessageConversationsAsUnread(messageConversationIds: string[]): Observable<void> {
        return this.http.put<void>(this.markAsUnreadUri, null, { params: { conversationId: messageConversationIds } });
    }

    markMessageConversationsAsArchived(messageConversationIds: string[]): Observable<void> {
        return this.http.put<void>(this.markAsArchivedUri, null, { params: { conversationId: messageConversationIds } });
    }

    markMessageConversationsAsNotArchived(messageConversationIds: string[]): Observable<void> {
        return this.http.put<void>(this.markAsNotArchivedUri, null, { params: { conversationId: messageConversationIds } });
    }

    searchMessageConversations(search: MessageConversationsSearch, userType: 'STAFF' | 'STUDENT'): Observable<SearchResult<MessageConversation>> {
        const uri = userType === 'STAFF' ? this.receivedMessageConversationsUriForStaff : this.receivedMessageConversationsUri;
        return this.http.get(
            uri,
            { params: this.createQueryParameters(search) }) as Observable<SearchResult<MessageConversation>>;
    }

    createQueryParameters(search: MessageConversationsSearch): HttpParams {
        return _.reduce(
            [
                this.getStudentSearchParameter,
                this.getReceivedMessageFilterTypeParameter,
                this.getMessageTypeFilterParameter,
                this.getMessageCategoryTypeFilterParameter,
                this.getIsReadFilterParameter,
                this.getIsArchivedFilterParameter,
                this.getStartParameter,
                this.getLimitParameter,
            ],
            (parameters: HttpParams, getQueryParams: Function) => getQueryParams(parameters, search),
            new HttpParams().set('recipientId', search.recipientId),
        );
    }

    getStudentSearchParameter(params: HttpParams, search: MessageConversationsSearch): HttpParams {
        if (!_.isEmpty(search.studentSearch)) {
            return params.set('studentSearch', search.studentSearch);
        }
        return params;
    }

    getReceivedMessageFilterTypeParameter(params: HttpParams, search: MessageConversationsSearch): HttpParams {
        if (search.filterType !== null) {
            return params.set('filterType', search.filterType);
        }
        return params;
    }

    getMessageTypeFilterParameter(params: HttpParams, search: MessageConversationsSearch): HttpParams {
        if (!_.isEmpty(search.messageTypes)) {
            const messageTypesString = _.join(search.messageTypes, ',');
            return params.set('messageTypes', messageTypesString);
        }
        return params;
    }

    getMessageCategoryTypeFilterParameter(params: HttpParams, search: MessageConversationsSearch): HttpParams {
        if (search.messageCategoryType !== null) {
            return params.set('messageCategoryType', search.messageCategoryType);
        }
        return params;
    }

    getIsReadFilterParameter(params: HttpParams, search: MessageConversationsSearch): HttpParams {
        if (search.isRead !== null) {
            return params.set('isRead', String(search.isRead));
        }
        return params;
    }

    getIsArchivedFilterParameter(params: HttpParams, search: MessageConversationsSearch): HttpParams {
        if (search.isArchived !== null) {
            return params.set('isArchived', String(search.isArchived));
        }
        return params;
    }

    getStartParameter(params: HttpParams, search: MessageConversationsSearch): HttpParams {
        if (search.start !== null) {
            return params.set('start', String(search.start));
        }
        return params;
    }

    getLimitParameter(params: HttpParams, search: MessageConversationsSearch): HttpParams {
        if (search.limit !== null) {
            return params.set('limit', String(search.limit));
        }
        return params;
    }

    isApplicationMessage(message: Message): boolean {
        return _.includes(
            [
                MessageTypes.PRIOR_LEARNING_INCLUSION_APPLICATION_MESSAGE,
                MessageTypes.PRIOR_LEARNING_SUBSTITUTION_APPLICATION_MESSAGE,
                MessageTypes.MODULE_ATTAINMENT_APPLICATION_MESSAGE,
                MessageTypes.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION_MESSAGE,
                MessageTypes.CUSTOM_ATTAINMENT_APPLICATION_MESSAGE,
                MessageTypes.STUDY_RIGHT_EXTENSION_APPLICATION_MESSAGE,
                MessageTypes.CUSTOM_MODULE_CONTENT_APPLICATION_MESSAGE,
                MessageTypes.REQUIRED_MODULE_CONTENT_APPLICATION_MESSAGE,
            ],
            message.type,
        );
    }

}
