<div class="form-group-mimic grouping-panel" *ngIf="showValidityPeriod">
  <div *ngIf="!hideLabel" role="heading" class="sis-mb-xxs sis-label" [class.required]="required" [attr.aria-level]="level">
    <ng-template [ngTemplateOutlet]="organisationLabel"></ng-template>
  </div>
  <div class="grouping-panel-content">
    <ng-template [ngTemplateOutlet]="organisationGuidance" *ngIf="isCoordinatingOrganisation() && validityGroups.length === 0">
    </ng-template>
    <sis-description-list [columnCount]="1">
      <dl>
        <ng-template #listItem *ngFor="let validityGroup of validityGroups">
          <dt class="sis-label">{{ validityGroup.validityPeriod | localDateRange }}</dt>
          <dd>
            <ng-template
              [ngTemplateOutlet]="organisationRoleShares"
              [ngTemplateOutletContext]="{ shares: validityGroup.organisationRoleShares }"
            ></ng-template>
          </dd>
        </ng-template>
      </dl>
    </sis-description-list>
  </div>
</div>

<div class="form-group-mimic" *ngIf="!showValidityPeriod">
  <sis-description-list [fillByColumn]="false">
    <dl>
      <ng-template #listItem>
        <dt class="sis-label" *ngIf="!hideLabel" [class.required]="required">
          <ng-template [ngTemplateOutlet]="organisationLabel"></ng-template>
        </dt>
        <dd>
          <ng-template [ngTemplateOutlet]="organisationGuidance" *ngIf="isCoordinatingOrganisation() && validityGroups.length === 0">
          </ng-template>
          <div *ngFor="let validityGroup of validityGroups" class="form-group-mimic">
            <ng-template
              [ngTemplateOutlet]="organisationRoleShares"
              [ngTemplateOutletContext]="{ shares: validityGroup.organisationRoleShares }"
            ></ng-template>
          </div>
        </dd>
      </ng-template>
    </dl>
  </sis-description-list>
</div>

<ng-template #organisationLabel>
  {{ getLabelKey() | translate }}
</ng-template>

<ng-template #organisationGuidance>
  <p class="sis-guidance" translate>SIS_COMPONENTS.ORGANISATION.ORGANISATION_ROLE_SHARE.COORDINATING_ORGANISATION_GUIDANCE</p>
</ng-template>

<ng-template #organisationRoleShares let-shares="shares">
  <p class="form-control-static" *ngFor="let share of shares">
    {{ share.educationalInstitutionUrn
    ? (share.educationalInstitutionUrn|codeLocalizedName|async)
    : ((share.organisationId|organisation|async)?.name|localizedString)}}
    {{ share.share | percent }}
  </p>
</ng-template>
