"use strict";

(function () {
  planCopyService.$inject = ["$translate", "planService", "dateTimeService"];
  angular.module('student.common.service.planCopyService', ['pascalprecht.translate', 'student.common.service.planService', 'student.shared']).factory('planCopyService', planCopyService);
  function planCopyService($translate, planService, dateTimeService) {
    return {
      copyPlan: function (plan) {
        removePlanMeta(plan);
        plan.name = getCopyName(plan.name);
        return planService.create(plan);
      },
      copyPlanObject: function (plan) {
        var newPlan = angular.copy(plan);
        removePlanMeta(newPlan);
        newPlan.name = getCopyName(plan.name);
        return newPlan;
      }
    };
    function removePlanMeta(plan) {
      _.unset(plan, 'id');
      _.unset(plan, 'metadata');
      plan.primary = false;
    }
    function getCopyName(name) {
      return name + ' ' + _.toUpper($translate.instant('PLAN.COPY')) + ' ' + dateTimeService.currentDateTime().format('DD.MM.YYYY HH:mm:ss');
    }
  }
})();