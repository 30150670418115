import angular from 'angular';
import searchFilterTpl from './searchFilterRange.tpl.html';
export const searchFilterRangeModule = 'sis-components.search.searchFilterRange';
angular.module(searchFilterRangeModule, []).component('searchFilterRange', {
  bindings: {
    helpText: '<',
    popoverIsOpen: '<',
    selectedCredits: '=',
    submitRangeFilter: '&'
  },
  template: searchFilterTpl,
  controllerAs: 'ctrl',
  controller: ["$scope", function ($scope) {
    $scope.$watch('ctrl.popoverIsOpen', newVal => {
      if (!newVal) {
        // Reset form when popover closes
        $scope.filterSelectorForm.$setPristine();
      }
    });
  }]
});