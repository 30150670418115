import {
    ChangeDetectionStrategy,
    Component,
    computed, inject,
    Input,
    signal,
    ViewEncapsulation,
    WritableSignal,
} from '@angular/core';
import { ValidatablePlan } from 'common-typescript';
import { PlanValidationResult } from 'common-typescript/src/plan/validation/planValidationResult';
import { CourseUnit, CourseUnitRule, EntityWithRule, Rule } from 'common-typescript/types';
import _ from 'lodash';

import { RULE_SERVICE } from '../../../ajs-upgraded-modules';
import {
    PLAN_ACTIONS_SERVICE_INJECTION_TOKEN,
    PlanActionsService,
} from '../../../plan/plan-actions-service/plan-actions.service';
import { PlanRuleData } from '../../../service/plan-rule-data.service';
import { PlanRuleSelectionService } from '../../../service/plan-rule-selection.service';
import { PlanData, PlanStateObject } from '../../../service/plan-state.service';

@Component({
    selector: 'sis-plan-structure-course-unit-rule',
    templateUrl: './plan-structure-course-unit-rule.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanStructureCourseUnitRuleComponent {
    _rule: WritableSignal<CourseUnitRule> = signal(undefined);
    @Input({ required: true }) set rule(rule: CourseUnitRule) {
        this._rule.set(rule);
    }

    _parentModule: WritableSignal<EntityWithRule> = signal(undefined);
    @Input({ required: true }) set parentModule(parentModule: EntityWithRule) {
        this._parentModule.set(parentModule);
    }

    _planValidationResult: WritableSignal<PlanValidationResult> = signal(undefined);
    @Input({ required: true }) set planValidationResult(planValidationResult: PlanValidationResult) {
        this._planValidationResult.set(planValidationResult);
    }

    _planStateObject: WritableSignal<PlanStateObject> = signal(undefined);
    @Input({ required: true }) set planStateObject(planStateObject: PlanStateObject) {
        this._planStateObject.set(planStateObject);
    }

    _planRuleData: WritableSignal<PlanRuleData> = signal(undefined);
    @Input({ required: true }) set planRuleData(planRuleData: PlanRuleData) {
        this._planRuleData.set(planRuleData);
    }

    _validatablePlan: WritableSignal<ValidatablePlan> = signal(undefined);
    @Input({ required: true }) set validatablePlan(validatablePlan: ValidatablePlan) {
        this._validatablePlan.set(validatablePlan);
    }

    _selectionUIState: WritableSignal<'ACTIVE' | 'DISABLED' | 'SELECTABLE'> = signal('ACTIVE');
    @Input({ required: true }) set selectionUIState(selectionUIState: 'ACTIVE' | 'DISABLED' | 'SELECTABLE') {
        this._selectionUIState.set(selectionUIState);
    }

    @Input({ required: true }) planData: PlanData;
    @Input({ required: true }) ruleDepthLevel: number;
    @Input({ required: true }) headingLevel: number;

    ruleService = inject(RULE_SERVICE);
    planRuleSelectionService: PlanRuleSelectionService = inject(PlanRuleSelectionService);
    planActionsService: PlanActionsService = inject(PLAN_ACTIONS_SERVICE_INJECTION_TOKEN);

    ruleValidationResult = computed(this.ruleValidationResultComputation());
    ruleCourseUnit = computed(this.ruleCourseUnitComputation());
    parentRule = computed<Rule>(this.parentRuleComputation());
    isParentRuleSelectAllRule = computed<boolean>(this.isParentRuleSelectAllRuleComputation());

    isRuleSelectionDisabled = computed(this.isRuleSelectionDisabledComputation());
    isImplicit = computed(this.isImplicitComputation());

    handleToggle(): void {
        const active = this.ruleValidationResult().active;
        const courseUnit = this.ruleCourseUnit();
        const parentModule = this._parentModule();
        const validatablePlan = this._validatablePlan();
        const planStateObject = this._planStateObject();
        if (this.planRuleSelectionService.isImplicit(courseUnit, parentModule, planStateObject)
            && this.planRuleSelectionService.canImplicitlySelectedCourseUnitBeMovedToTargetModule(
                courseUnit,
                parentModule,
                validatablePlan,
                planStateObject)) {
            this.planActionsService?.moveCourseUnit?.(courseUnit, parentModule);
        } else if (!active) {
            this.planActionsService?.selectCourseUnit?.(courseUnit, parentModule);
        } else {
            this.planActionsService?.removeCourseUnit?.(courseUnit, parentModule);
        }
    }

    ruleValidationResultComputation(): () => any {
        return () => _.get(this._planValidationResult().ruleValidationResults, [this._parentModule().id, this._rule().localId]);
    }

    ruleCourseUnitComputation(): () => CourseUnit {
        return () => this._planRuleData().ruleCourseUnitVersionsByGroupId[this._rule().courseUnitGroupId];
    }

    parentRuleComputation(): () => Rule {
        return () => this.ruleService.getParentRule(this._parentModule().rule, this._rule());
    }

    isParentRuleSelectAllRuleComputation(): () => boolean {
        return () => this.ruleService.isSelectAllRule(this.parentRule());
    }

    isRuleSelectionDisabledComputation(): () => boolean {
        return () => this._selectionUIState() === 'DISABLED'
            || (this.isParentRuleSelectAllRule() && this.ruleValidationResult()?.active)
            || this.planRuleSelectionService.isCourseUnitSelectionDisabled(this.ruleCourseUnit(), this._parentModule(), this._validatablePlan(), this._planStateObject());
    }

    isImplicitComputation(): () => boolean {
        return () => this.planRuleSelectionService.isImplicit(this.ruleCourseUnit(), this._parentModule(), this._planStateObject());
    }

}
