import angular from 'angular';
import moment from 'moment';
import _ from 'lodash';
export const courseUnitRealisationModelModule = 'sis-components.model.courseUnitRealisation';
(function () {
  courseUnitRealisationModel.$inject = ["DS", "$log", "$q", "courseUnitRealisationNameService", "jsDataRelationHelperService", "RESOLVED_FLOWS_STATE", "languageJSDataModel"];
  angular.module(courseUnitRealisationModelModule, ['js-data', 'sis-common.model.courseUnitRealisationNameService', 'sis-components.service.jsDataRelationHelper', 'sis-components.model.courseUnitRealisationType', 'sis-components.model.assessmentItem', 'sis-components.model.language', 'sis-components.model.organisation']).factory('courseUnitRealisationJSDataModel', courseUnitRealisationModel).constant('SEARCH_FLOWS_STATE', {
    NOT_READY: 'NOT_READY',
    PUBLISHED_ACTIVE: 'PUBLISHED_ACTIVE',
    PUBLISHED_FUTURE: 'PUBLISHED_FUTURE',
    CANCELLED: 'CANCELLED',
    ARCHIVED: 'ARCHIVED',
    values() {
      return _.filter(_.mapValues(this), value => !_.isFunction(value));
    }
  }).constant('RESOLVED_FLOWS_STATE', {
    NOT_READY: 'NOT_READY',
    PUBLISHED_ACTIVE: 'PUBLISHED_ACTIVE',
    PUBLISHED_FUTURE: 'PUBLISHED_FUTURE',
    PUBLISHED_EXPIRED: 'PUBLISHED_EXPIRED',
    CANCELLED_ACTIVE: 'CANCELLED_ACTIVE',
    CANCELLED_EXPIRED: 'CANCELLED_EXPIRED',
    ARCHIVED: 'ARCHIVED',
    DELETED: 'DELETED',
    values() {
      return _.filter(_.mapValues(this), value => !_.isFunction(value));
    }
  }).constant('CUR_ACTIONS_BY_STATE', {
    NOT_READY: ['DELETE', 'PUBLISH', 'ARCHIVE', 'UPDATE_PERSON_RULES', 'UPDATE_ENROLMENT_DATE_TIMES'],
    PUBLISHED_ACTIVE: ['CANCEL', 'UPDATE_PERSON_RULES', 'UPDATE_ENROLMENT_DATE_TIMES'],
    PUBLISHED_FUTURE: ['CANCEL', 'UPDATE_PERSON_RULES', 'UPDATE_ENROLMENT_DATE_TIMES'],
    PUBLISHED_EXPIRED: ['CANCEL', 'ARCHIVE', 'UPDATE_PERSON_RULES', 'UPDATE_ENROLMENT_DATE_TIMES'],
    CANCELLED_ACTIVE: ['PUBLISH', 'UPDATE_PERSON_RULES', 'UPDATE_ENROLMENT_DATE_TIMES'],
    CANCELLED_EXPIRED: ['ARCHIVE', 'UPDATE_PERSON_RULES', 'UPDATE_ENROLMENT_DATE_TIMES'],
    ARCHIVED: ['UPDATE_PERSON_RULES'],
    DELETED: ['UPDATE_PERSON_RULES']
  }).run(["courseUnitRealisationJSDataModel", "courseUnitRealisationTypeModel", "languageJSDataModel", "assessmentItemJSDataModel", "organisationJSDataModel", (courseUnitRealisationJSDataModel, courseUnitRealisationTypeModel, languageJSDataModel, assessmentItemJSDataModel, organisationJSDataModel) => {// NOSONAR
  }]);

  /**
   * @ngInject
   */
  function courseUnitRealisationModel(DS, $log, $q, courseUnitRealisationNameService, jsDataRelationHelperService, RESOLVED_FLOWS_STATE, languageJSDataModel) {
    function loadCURUniversityOrgPersonAndOrganisationRelations(courseUnitRealisation) {
      const relationLoads = [jsDataRelationHelperService.loadOrganisationRelations(courseUnitRealisation.organisations), jsDataRelationHelperService.loadCourseUnitRealisationResponsibilityInfoRelations(courseUnitRealisation.responsibilityInfos), jsDataRelationHelperService.loadUniversityOrgs([courseUnitRealisation])];
      return $q.all(relationLoads).then(() => courseUnitRealisation, error => {
        $log.warn('Failed to load courseUnitRealisation organisations and person relations', courseUnitRealisation, error);
      });
    }
    return DS.defineResource({
      idAttribute: 'id',
      name: 'courseUnitRealisation',
      endpoint: '/kori/api/course-unit-realisations',
      relations: {
        hasOne: {
          courseUnitRealisationType: {
            localField: 'courseUnitRealisationType',
            localKey: 'courseUnitRealisationTypeUrn'
          },
          language: {
            localField: 'teachingLanguage',
            localKey: 'teachingLanguageUrn',
            get: languageJSDataModel.lazyGet
          },
          teaching: {
            localField: 'teaching',
            localKey: 'id'
          }
        },
        hasMany: {
          organisation: {
            localField: 'universityOrgs',
            localKeys: 'universityOrgIds'
          },
          assessmentItem: {
            localField: 'assessmentItems',
            localKeys: 'assessmentItemIds'
          }
        }
      },
      methods: {
        loadRelations() {
          return loadCURUniversityOrgPersonAndOrganisationRelations(this);
        },
        getDefaultName() {
          let defaultName;
          if (this.courseUnitRealisationType) {
            defaultName = this.courseUnitRealisationType.name;
          }
          return _.cloneDeep(defaultName);
        }
      },
      computed: {
        fullName: ['name', 'nameSpecifier', 'activityPeriod', function (name, nameSpecifier, activityPeriod) {
          return courseUnitRealisationNameService.generateFullName({
            name,
            nameSpecifier,
            activityPeriod
          });
        }]
      },
      afterUpdate(resource, courseUnitRealisation, cb) {
        return loadCURUniversityOrgPersonAndOrganisationRelations(courseUnitRealisation).then(() => {
          cb(null, courseUnitRealisation);
        });
      }
    });
  }
})();