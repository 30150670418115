<ul #tabList class="nav nav-{{type}}
    {{wide && 'nav-wide-' + wide}}"
    [class.nav-invert]="invert"
    role="tablist">
  <li *ngFor="let tab of tabs; let index = index;"
      role="presentation"
      [class.active]="isSelected(index)"
      [class.has-tail]="hasTail(tab)">
      <button
        (blur)="handleBlur($event)"
        (keydown)="handleKeyDown($event)"
        (keyup)="handleKeyUp($event, index)"
        role="tab"
        class="link-button"
        [attr.id]="tab.id ? tab.id : tab.title + '-tab'"
        [attr.data-cy]="tab.id"
        [attr.aria-selected]="isSelected(index)"
        [attr.tabindex]="isSelected(index) ? 0 : -1"
        (click)="switchTab(index)">
        {{tab.title | translate}}<span *ngIf="hasTail(tab)" class="tail">{{tab.tail}}</span><span *ngIf="hasAmount(tab)"> ({{tab.amount}})</span>
      </button>
  </li>
</ul>
