"use strict";

(function () {
  angular.module('student.common.model.attainment', ['sis-components.constant.attainmentTypes']).factory('attainmentModel', attainmentModel);

  /**
   * @ngInject
   */
  attainmentModel.$inject = ["AttainmentTypes"];
  function attainmentModel(AttainmentTypes) {
    var attainmentModel = {
      newAttainment: newAttainment,
      DegreeProgrammeAttainment: DegreeProgrammeAttainment,
      ModuleAttainment: ModuleAttainment,
      CourseUnitAttainment: CourseUnitAttainment,
      AssessmentItemAttainment: AssessmentItemAttainment,
      CustomCourseUnitAttainment: CustomCourseUnitAttainment,
      CustomModuleAttainment: CustomModuleAttainment
    };
    function Attainment(data) {
      if (!_.isNumber(data.credits)) {
        throw "numeric credits required for Attainment";
      }
      _.assign(this, data);
      this.isDegreeProgrammeAttainment = isDegreeProgrammeAttainment;
      this.isModuleAttainment = isModuleAttainment;
      this.isCourseUnitAttainment = isCourseUnitAttainment;
      this.isAssessmentItemAttainment = isAssessmentItemAttainment;
      this.isCustomCourseUnitAttainment = isCustomCourseUnitAttainment;
      this.isCustomModuleAttainment = isCustomModuleAttainment;
    }
    function newAttainment(data) {
      if (data) {
        return new attainmentModel[data.type](data);
      } else {
        return null;
      }
    }
    function DegreeProgrammeAttainment(data) {
      Attainment.apply(this, arguments);
      if (!data.moduleId) {
        throw "moduleId required for DegreeProgrammeAttainment";
      }
      if (!data.moduleGroupId) {
        throw "moduleGroupId required for DegreeProgrammeAttainment";
      }
      this.moduleAttainments = [];
      this.courseUnitAttainments = [];
    }
    DegreeProgrammeAttainment.prototype = Object.create(Attainment.prototype);
    DegreeProgrammeAttainment.prototype.constructor = DegreeProgrammeAttainment;
    function ModuleAttainment(data) {
      Attainment.apply(this, arguments);
      if (!data.moduleId) {
        throw "moduleId required for ModuleAttainment";
      }
      if (!data.moduleGroupId) {
        throw "moduleGroupId required for ModuleAttainment";
      }
      this.moduleAttainments = [];
      this.courseUnitAttainments = [];
    }
    ModuleAttainment.prototype = Object.create(Attainment.prototype);
    ModuleAttainment.prototype.constructor = ModuleAttainment;
    function CourseUnitAttainment(data) {
      Attainment.apply(this, arguments);
      if (!data.courseUnitId) {
        throw "courseUnitId required for CourseUnitAttainment";
      }
      if (!data.courseUnitGroupId) {
        throw "courseUnitGroupId required for CourseUnitAttainment";
      }
    }
    CourseUnitAttainment.prototype = Object.create(Attainment.prototype);
    CourseUnitAttainment.prototype.constructor = CourseUnitAttainment;
    function AssessmentItemAttainment(data) {
      Attainment.apply(this, arguments);
    }
    AssessmentItemAttainment.prototype = Object.create(Attainment.prototype);
    AssessmentItemAttainment.prototype.constructor = AssessmentItemAttainment;
    function CustomCourseUnitAttainment(data) {
      Attainment.apply(this, arguments);
    }
    CustomCourseUnitAttainment.prototype = Object.create(Attainment.prototype);
    CustomCourseUnitAttainment.prototype.constructor = CustomCourseUnitAttainment;
    function CustomModuleAttainment(data) {
      Attainment.apply(this, arguments);
      this.moduleAttainments = [];
      this.courseUnitAttainments = [];
    }
    CustomModuleAttainment.prototype = Object.create(Attainment.prototype);
    CustomModuleAttainment.prototype.constructor = CustomModuleAttainment;
    function isDegreeProgrammeAttainment() {
      return this.type === AttainmentTypes.DEGREE_PROGRAMME_ATTAINMENT;
    }
    function isModuleAttainment() {
      return this.type === AttainmentTypes.MODULE_ATTAINMENT;
    }
    function isCourseUnitAttainment() {
      return this.type === AttainmentTypes.COURSE_UNIT_ATTAINMENT;
    }
    function isAssessmentItemAttainment() {
      return this.type === AttainmentTypes.ASSESSMENT_ITEM_ATTAINMENT;
    }
    function isCustomCourseUnitAttainment() {
      return this.type === AttainmentTypes.CUSTOM_COURSE_UNIT_ATTAINMENT;
    }
    function isCustomModuleAttainment() {
      return this.type === AttainmentTypes.CUSTOM_MODULE_ATTAINMENT;
    }
    return attainmentModel;
  }
})();