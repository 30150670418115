import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ErrorInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // As you can see, this interceptor does not do any error handling
        // as most of the stuff is handled by clients. Left this interceptor
        // here, because we will probably need this soon when moving logic
        // from AngularJS errorService.js to Angular.
        return next.handle(req);
    }
}
