import { ChangeDetectionStrategy, Component, inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { StateService } from '@uirouter/core';
import { Attainment, CustomAttainmentWorkflow, WorkflowState } from 'common-typescript/types';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { HeaderService } from 'sis-components/header/header.service';
import { AttainmentEntityService } from 'sis-components/service/attainment-entity.service';
import { WorkflowDataChangeService } from 'sis-components/service/workflow-data-change.service';

import { workflowCancellationDialogOpener } from '../cancel-workflow-dialog/cancel-workflow-dialog.component';

@Component({
    selector: 'app-custom-attainment-workflow',
    templateUrl: './custom-attainment-workflow.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomAttainmentWorkflowComponent implements OnInit {

    @Input() studentWorkflow: CustomAttainmentWorkflow;

    headerService = inject(HeaderService);
    stateService = inject(StateService);
    workflowDataChangeService = inject(WorkflowDataChangeService);
    attainmentEntityService = inject(AttainmentEntityService);
    appErrorHandler = inject(AppErrorHandler);

    private readonly openCancelWorkflowDialog = workflowCancellationDialogOpener();
    activeSupplementRequestDescription$: Observable<string>;
    attainment$: Observable<Attainment>;

    ngOnInit(): void {
        this.headerService.setApplicationStateBadge(this.studentWorkflow);
        this.activeSupplementRequestDescription$ = this.workflowDataChangeService.getActiveSupplementRequestDescription(this.studentWorkflow?.id)
            .pipe(take(1), this.appErrorHandler.defaultErrorHandler());
        this.attainment$ = this.attainmentEntityService.getByWorkflowId(this.studentWorkflow?.id)
            .pipe(
                take(1),
                map(attainments => attainments.find(a => !a.misregistration && !!a.primary)),
                this.appErrorHandler.defaultErrorHandler(),
            );
    }

    openCancelDialog() {
        this.openCancelWorkflowDialog({
            workflowId: this.studentWorkflow?.id,
            workflowType: this.studentWorkflow?.type,
            successCallback: () => this.stateService.reload(),
        });
    }

    shouldShowCancelButton() {
        return (['REQUESTED', 'IN_HANDLING', 'SUPPLEMENT_REQUESTED'] as WorkflowState[]).includes(this.studentWorkflow?.state);
    }

    isWorkflowCancelled() {
        return this.studentWorkflow?.state === 'CANCELLED';
    }

    isWorkflowSupplementRequested() {
        return this.studentWorkflow?.state === 'SUPPLEMENT_REQUESTED';
    }

    isAttachmentDeliveryPossible() {
        return this.studentWorkflow?.state === 'REQUESTED';
    }

    shouldShowDecision() {
        return (['ACCEPTED', 'REJECTED', 'ACCEPTANCE_REVOKED'] as WorkflowState[]).includes(this.studentWorkflow?.state) && !!this.studentWorkflow?.decision;
    }

    navigateToWorkflowSupplement() {
        this.stateService.go('student.logged-in.profile.applications.custom-attainment-workflow.supplement', { applicationId: this.studentWorkflow?.id });
    }
}
