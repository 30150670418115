export const graphqlUtils = {
    // Apollo query passes __typename field for all objects which makes setting readable type -definitions almost impossible.
    // This method will clean-up __typenames from response.
    // Disclaimer: Do NOT remove __typenames if you are planning to use apollo/graphql's own cache system.
    // payload = response.data.searchResult
    removeTypeNames(payload: any): any {
        const omitTypename = (key: any, value: any) => (key === '__typename' ? undefined : value);
        return JSON.parse(JSON.stringify(payload), omitTypename);
    },
};
