import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { DegreeProgramme, StudyModule } from 'common-typescript/types';

@Component({
    selector: 'app-module-info-description',
    templateUrl: './module-info-description.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModuleInfoDescriptionComponent {
    @Input() set module(module: StudyModule | DegreeProgramme) {
        this._module = module;
        this.showDescriptionInfo = this.moduleHasAnyDescriptions(this._module);
    }

    _module: StudyModule | DegreeProgramme;

    showDescriptionInfo = false;

    isDegreeProgramme(module: StudyModule | DegreeProgramme): module is DegreeProgramme { return module.type === 'DegreeProgramme'; }
    isStudyModule(module: StudyModule | DegreeProgramme): module is StudyModule { return module.type === 'StudyModule'; }

    moduleHasAnyDescriptions(module: StudyModule | DegreeProgramme): boolean {
        let anyDegreeProgrammeFields = false;
        if (this.isDegreeProgramme(module)) {
            anyDegreeProgrammeFields = !!module.learningOutcomes
                || !!module.tweetText;
        }
        let anyStudyModuleFields = false;
        if (this.isStudyModule(module)) {
            anyStudyModuleFields = !!module.outcomes
            || !!module.prerequisites
            || module?.searchTags?.length > 0;
        }
        return anyDegreeProgrammeFields
            || anyStudyModuleFields
            || !!module.contentDescription
            || !!module.additionalInfo;
    }
}
