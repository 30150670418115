<h3 *ngIf="title" class="list-title">{{title}}</h3>
<ng-container *ngIf="enrolments.length > 0; else empty">
  <div *ngFor="let enrolment of getEnrolmentList()" class="enrolment-course-unit">
    <app-enrolment-box
      data-cy="enrolments-view-enrolment-box"
      (enrolmentClickAction)="handleEnrolmentButtonClick(enrolment, $event.action)"
      (enrolmentCancelAction)="cancelEnrolment(enrolment, $event.action)"
      (enrolmentRemoveAction)="removeEnrolment(enrolment)"
      [enrolment]="enrolment">
    </app-enrolment-box>
  </div>
  <sis-show-more *ngIf="fullListVisible || enrolments.length > getEnrolmentList().length"
    class="show-more"
    [text]="fullListVisible
      ? ('ENROLMENTS.SHOW_LESS_REALISATIONS' | translate)
      : ('ENROLMENTS.SHOW_MORE_REALISATIONS' | translate: { count: enrolments.length - getEnrolmentList().length })"
    [direction]="fullListVisible ? 'ccw-90' : 'cw-90'"
    [placement]="fullListVisible ? 'top' : 'bottom'"
    (showMoreChanged)="showMoreEnrolments()"
    data-cy="show-more-enrolments">
  </sis-show-more>
</ng-container>

<ng-template #empty>
  <p class="guidance">{{emptyListText}}</p>
</ng-template>
