import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CourseUnitRealisation } from 'common-typescript/types';
import { ModalService } from 'sis-common/modal/modal.service';

export interface AddCourseUnitRealisationValues {
    courseUnitRealisation: CourseUnitRealisation;
}

@Component({
    selector: 'sis-add-course-unit-realisation-dialog',
    template: '<add-course-unit-realisation-dialog (close)="activeModal.close($event.$value)" (dismiss)="activeModal.dismiss()" [resolve]="values"></add-course-unit-realisation-dialog>',
    encapsulation: ViewEncapsulation.None,
})
export class AddCourseUnitRealisationDialogComponent {
    constructor(@Inject(ModalService.injectionToken) public values: AddCourseUnitRealisationValues,
                public activeModal: NgbActiveModal) {}
}
