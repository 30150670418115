import { Directive, ElementRef, Injector, Input, Output } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

import { ApplyForGraduationValues } from './applyForGraduationModal-ng.component';

/**
 * @deprecated
 */
// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'apply-for-graduation-modal' })
export class ApplyForGraduationModalDirective extends UpgradeComponent {

    @Output() close: () => void;
    @Output() dismiss: () => void;
    @Input() resolve: ApplyForGraduationValues;

    constructor(elementRef: ElementRef<any>, injector: Injector) {
        super('applyForGraduationModal', elementRef, injector);
    }
}
