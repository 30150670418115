'use strict';

(function () {
  attainmentBuilder.$inject = ["$log", "moduleModel", "planPriority"];
  angular.module('student.common.context.plan-context').factory('attainmentBuilder', attainmentBuilder);
  function attainmentBuilder($log, moduleModel, planPriority) {
    return {
      connect: function (planContext) {
        var attainmentContext = planContext.getAttainmentContext();
        _.each(attainmentContext.getAssessmentItemAttainments(), _.partial(this.assessmentItem, _, planContext));
        _.each(attainmentContext.getCourseUnitAttainments(), _.partial(this.courseUnit, _, planContext));
        _.each(attainmentContext.getModuleAttainments(), _.partial(this.module, _, planContext));
        _.each(attainmentContext.getCustomModuleAttainments(), _.partial(this.module, _, planContext));
        _.each(attainmentContext.getCustomCourseUnitAttainments(), _.partial(this.courseUnit, _, planContext));
      },
      assessmentItem: function (assessmentItemAttainment, planContext) {
        var assessmentItem = planContext.getAssessmentItem(assessmentItemAttainment.assessmentItemId);
        if (!assessmentItem) {
          $log.error('assessmentItem not found failed to build assessmentItemAttainment.', 'assessmentItemAttainment:', assessmentItemAttainment, 'planContext:', planContext);
          return;
        }
        assessmentItem.attainment = assessmentItemAttainment;
      },
      courseUnit: function (courseUnitAttainment, planContext) {
        var index = 1;
        var courseUnit;
        if (courseUnitAttainment.isCustomCourseUnitAttainment()) {
          courseUnit = planContext.getCustomCourseUnitAttainment(courseUnitAttainment);
        } else {
          courseUnit = planContext.getCourseUnit(courseUnitAttainment.courseUnitId);
        }
        if (!courseUnit) {
          $log.error('courseUnit not found failed to build courseUnitAttainment.', 'courseUnitAttainment:', courseUnitAttainment, 'planContext:', planContext);
          return;
        }
        courseUnit.attainment = courseUnitAttainment;
        _.forEach(courseUnitAttainment.assessmentItemAttainments, function (assessmentItemAttainment) {
          var assessmentItem = planContext.getAssessmentItem(assessmentItemAttainment.assessmentItemId);
          if (assessmentItem) {
            courseUnit.addSelectedAssessmentItem(assessmentItem);
            planContext.lock(assessmentItem, index++);
          } else {
            $log.error('assessmentItem not found failed to build assessmentItemAttainment.', 'assessmentItemAttainment:', assessmentItemAttainment, 'planContext:', planContext);
          }
        });
      },
      module: function (moduleAttainment, planContext) {
        var index = 1;
        var groupIndex = 0;
        var module;
        if (moduleAttainment.isCustomModuleAttainment()) {
          module = planContext.getCustomModuleAttainment(moduleAttainment);
        } else {
          module = planContext.getModule(moduleAttainment.moduleId);
        }
        if (!module) {
          $log.error('module not found failed to build moduleAttainment.', 'moduleAttainment:', moduleAttainment, 'planContext:', planContext);
          return;
        }
        module.attainment = moduleAttainment;
        _.forEach(moduleAttainment.nodes, function (node) {
          processNode(module, node);
        });
        function processNode(parentModule, node) {
          if (node.group) {
            addSyntheticGroupingModule(parentModule, node);
          } else if (node.attainment) {
            if (node.attainment.moduleId || node.attainment.isCustomModuleAttainment()) {
              var childModule;
              if (node.attainment.isCustomModuleAttainment()) {
                childModule = planContext.getCustomModuleAttainment(node.attainment);
              } else {
                childModule = planContext.getModule(node.attainment.moduleId);
              }
              if (childModule) {
                parentModule.addSelectedModule(childModule);
                planContext.lock(childModule, index++);
              } else {
                $log.warn('childModule not found failed to process attainment node.', 'parentModule:', parentModule, 'node:', node);
              }
            } else if (node.attainment.courseUnitId || node.attainment.isCustomCourseUnitAttainment()) {
              var courseUnit;
              if (node.attainment.isCustomCourseUnitAttainment()) {
                courseUnit = planContext.getCustomCourseUnitAttainment(node.attainment);
              } else {
                courseUnit = planContext.getCourseUnit(node.attainment.courseUnitId);
              }
              if (courseUnit) {
                parentModule.addSelectedCourseUnit(courseUnit);
                planContext.lock(courseUnit, index++);
              } else {
                $log.warn('courseUnit not found failed to process attainment nod.', 'parentModule:', parentModule, 'node:', node);
              }
            } else {
              $log.warn('Unhandled node in process moduleAttainment node.', node);
            }
          } else {
            $log.warn('Failed to process attainment node. node.attainment is not defined', node);
          }
        }
        function addSyntheticGroupingModule(parentModule, node) {
          groupIndex++;
          // NOTE: syntheticId is not (supposed to be) compatible with OtmId
          var syntheticId = 'group#' + groupIndex + '-' + parentModule.id;
          // Reuse synthetic group module object to prevent temporary duplication
          var groupingModule = parentModule.findModuleById(syntheticId);
          if (!groupingModule) {
            groupingModule = moduleModel.newModule({
              name: node.name,
              type: 'GroupingModule',
              id: syntheticId,
              groupId: syntheticId
            });
          }
          groupingModule = planContext.addModule(groupingModule, planPriority.firstPriority());
          planContext.lock(groupingModule, index++);
          parentModule.addSelectedModule(groupingModule);
          _.forEach(node.nodes, function (node) {
            processNode(groupingModule, node);
          });
        }
      }
    };
  }
})();