<app-cart-container *ngIf="cart"
                    [pageTitle]="'OPEN_UNIVERSITY_CART.WIZARD.CONFIRMATION.PAGE_TITLE'"
                    [wizardStepKeys]="wizardStepNames"
                    [currentStep]="currentStep">
  <div class="row">
    <div class="col-md-10 col-sm-12">
      <div class="thank-you-texts">
        <p data-cy="thank-you-text-1">{{ 'OPEN_UNIVERSITY_CART.WIZARD.CONFIRMATION.THANK_YOU_TEXT_1' | translate }} <strong>{{cart.orderNumber}}</strong>.</p>
        <p>{{ 'OPEN_UNIVERSITY_CART.WIZARD.CONFIRMATION.THANK_YOU_TEXT_2' | translate }}</p>
        <p data-cy="thank-you-text-3">{{ 'OPEN_UNIVERSITY_CART.WIZARD.CONFIRMATION.THANK_YOU_TEXT_3' | translate}} <strong>{{cart.buyerEmail}}</strong>.</p>
      </div>

      <div class="confirm-enrolments">
        <h2 tabindex="-1" #currentStepNameFocus>{{ 'OPEN_UNIVERSITY_CART.WIZARD.CONFIRMATION.CONFIRM_ENROLMENT_TITLE' | translate }}</h2>
        <div class="row">
          <div class="col-lg-8 col-sm-7">
            <p>{{ 'OPEN_UNIVERSITY_CART.WIZARD.CONFIRMATION.CONFIRM_TEXT_1' | translate }}</p>
            <p data-cy="confirm-text-2">{{ 'OPEN_UNIVERSITY_CART.WIZARD.CONFIRMATION.CONFIRM_TEXT_2' | translate:{buyerEmail: cart.buyerEmail} }}</p>
            <p>{{ 'OPEN_UNIVERSITY_CART.WIZARD.CONFIRMATION.CONFIRM_TEXT_3' | translate }}</p>
          </div>
          <div class="col-lg-4 col-sm-5">
            <sis-button type="button"
                        variant="primary"
                        (clicked)="redirectToStudiesActivateWizard()"
                        data-cy="confirm-enrolment-button">
              {{ 'OPEN_UNIVERSITY_CART.WIZARD.CONFIRMATION.CONFIRM_ENROLMENT_BUTTON' | translate }}
            </sis-button>
          </div>
        </div>
        <div class="row guidance-div">
          <div class="notification-icon"><sis-icon [icon]="'notification'" [color]="'primary'"></sis-icon></div>
          <div class="guidance-text">
            <transloco class="guidance"
                       key="OPEN_UNIVERSITY_CART.WIZARD.CONFIRMATION.GUIDANCE"
                       [params]="{activationCode: cart.activationCode}"
                       data-cy="activation-code-guidance">
            </transloco>
          </div>
        </div>
      </div>

      <h2>{{ 'OPEN_UNIVERSITY_CART.WIZARD.CONFIRMATION.COURSE_UNIT_TITLE' | translate }}</h2>
      <app-cart-accordions [cart]="cart" [isEditable]="false"></app-cart-accordions>
      <div class="row price">
        <div class="col-sm-7"></div>
        <div class="row col-sm-5">
          <app-cart-price [cart]="cart" [showOnlySummary]="true" [alignRight]="true"></app-cart-price>
        </div>
      </div>
    </div>
  </div>
</app-cart-container>
