import { Pipe, PipeTransform } from '@angular/core';

/**
 * This pipe should help with translation params when the parameter depends on another pipe. This prevents unnecessary variable
 * declarations. If you need two translation variables then this pipe does not work. I'm not sure how to even implement a readable pipe
 * that does that.
 *
 * Example 1: course unit realisation to full name to translation parameter
 * fi.json: "STUDIES.SOME_KEY_1": "Toteutukselle on ilmoittautunut henkilö {{personName}}"
 * <label [translate]="'STUDIES.SOME_KEY_1'" [translateParams]="person | personFullNameReversed | toParams:'personName'">
 * </label>
 *
 * Example 2: languageUrn to code name (asynchronously) into translation param
 * fi.json: "STUDIES.SOME_KEY_2": "Olet suorittanut tämän kielellä {{language}}"
 * template:
 * <label [translate]="'STUDIES.SOME_KEY_2'" [translateParams]="languageUrn | codeLocalizedName | async | toParams:'language'">
 * </label>
 */
@Pipe({
    name: 'toParams',
})
export class ToParamsPipe implements PipeTransform {

    transform(value: any, key: string): any {
        const newVar: any = {};
        newVar[key] = value;
        return newVar;
    }
}
