import { Pipe, PipeTransform } from '@angular/core';
import { LocalizedMarkupString } from 'common-typescript/types';
import { LocaleService } from 'sis-common/l10n/locale.service';

import { MarkupService } from '../service/markup.service';

@Pipe({ name: 'localizedMarkupString' })
export class LocalizedMarkupStringPipe implements PipeTransform {

    constructor(
        private localeService: LocaleService,
        private markupService: MarkupService,
    ) { }

    transform(markup: LocalizedMarkupString, locale?: string): string {
        return this.markupService.parse(this.localeService.localize(markup, locale), locale);
    }
}
