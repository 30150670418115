import createPlanTpl from './createPlan.tpl.html';
(function () {
  'use strict';

  CreatePlanController.$inject = ["$log", "selectEducationModal", "$state"];
  angular.module('student.plan').component('createPlan', {
    template: createPlanTpl,
    controller: CreatePlanController
  });
  function CreatePlanController($log, selectEducationModal, $state) {
    var ctrl = this;
    ctrl.openModal = openModal;
    function openModal() {
      selectEducationModal.open().then(function (result) {
        $state.go('student.logged-in.structure', {
          planId: result.planId
        });
      }, function () {
        $log.debug('selectEducationModal cancelled');
      });
    }
  }
})();