import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, Subject } from 'rxjs';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

export const DEFAULT_DISMISS_TIME = 10000;

export enum AlertEventType {
    ADD_ALERT,
    DISMISS_ALERT,
    DISMISS_ALL,
    DISMISS_ALL_MATCHING,
    DISMISS_IF_EXISTS,
}

export enum AlertType {
    DANGER = 'danger',
    WARNING = 'warning',
    INFO = 'info',
    SUCCESS = 'success',
}

export interface AlertEvent {
    type: AlertEventType;
    data?: Alert | String | RegExp;
}

export interface AlertUpdater<T> {
    /** The interval to be used to periodically update the alert by calling {@link updatedMessageProvider}. */
    byInterval?: number;
    /** The observable to be observed to update the alert every time the former emits/notifies by calling {@link updatedMessageProvider}.
     * Optional emitted value is passed to the updatedMessageProvider as the second parameter. */
    byObservable?: Observable<T>;
    /** A callback function that should provide the updated alert message or dismiss the alert by calling {@link dismissAlert} function internally. */
    updatedMessageProvider: (dismissAlert: Function, emittedValue?: T) => string;
}

export interface Alert {
    type: AlertType;
    message: string;
    identifier?: string;
    dismiss?: number;
    hideDismissibleButton?: boolean;
    updater?: AlertUpdater<any>;
    /** Optional ARIA role attribute value to be used for the alert, e.g. 'timer' for timer alerts to prevent assistive technologies from announcing updates. */
    role?: string;
    onClickCallback?: () => void;
    // Declare 'scrollToElement' value when you want alert click to scroll
    // to certain element in the template. It uses template element's classname (not id)
    // because there can be multiple elements with same name in one template.
    scrollToElement?: string;
}

@StaticMembers<DowngradedService>()
@Injectable({
    providedIn: 'root',
})
export class AlertsService {

    static downgrade: ServiceDowngradeMappings = {
        moduleName: 'sisComponents.alerts.sisAlertsService',
        serviceName: 'sisAlertsService',
    };

    alertEvents$: Subject<AlertEvent>;

    constructor(private translate: TranslateService) {
        this.alertEvents$ = new Subject<AlertEvent>();
    }

    addAlert(alert: Alert): void {
        this.alertEvents$.next({
            type: AlertEventType.ADD_ALERT,
            data: alert,
        });
    }

    addTemporaryAlert(alert: Alert): void {
        this.addAlert({ ...alert, dismiss: alert.dismiss ?? DEFAULT_DISMISS_TIME });
    }

    /**
     * A shorthand for adding a simple error alert with the specified message.
     */
    error(message: string, id?: string, translate = true): void {
        this.addSimpleAlert(message, AlertType.DANGER, translate, id);
    }

    /**
     A shorthand for adding a simple warning alert with the specified message.
     */
    warning(message: string, id?: string, translate = true): void {
        this.addSimpleAlert(message, AlertType.WARNING, translate, id);
    }

    /**
     A shorthand for adding a simple info alert with the specified message.
     */
    info(message: string, id?: string, translate = true): void {
        this.addSimpleAlert(message, AlertType.INFO, translate, id);
    }

    /**
     A shorthand for adding a simple success alert with the specified message.
     */
    success(message: string, id?: string, translate = true): void {
        this.addSimpleAlert(message, AlertType.SUCCESS, translate, id);
    }

    addFormSubmissionFailureAlert(identifier?: string): void {
        this.addAlert({
            message: this.translate.instant('SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.FORM_SUBMISSION_FAILED'),
            type: AlertType.DANGER,
            identifier,
        });
    }

    dismissAllAlerts(): void {
        this.alertEvents$.next({
            type: AlertEventType.DISMISS_ALL,
        });
    }

    dismissAllMatchingAlerts(pattern: RegExp): void {
        this.alertEvents$.next({
            type: AlertEventType.DISMISS_ALL_MATCHING,
            data: pattern,
        });
    }

    dismissAlert(identifier: string): void {
        this.alertEvents$.next({
            type: AlertEventType.DISMISS_ALERT,
            data: identifier,
        });
    }

    dismissAlertIfExists(identifier: string): void {
        this.alertEvents$.next({
            type: AlertEventType.DISMISS_IF_EXISTS,
            data: identifier,
        });
    }

    private addSimpleAlert(messageOrKey: string, type: AlertType, translate: boolean, identifier?: string): void {
        (translate ? this.translate.get(messageOrKey) : of(messageOrKey))
            .subscribe(message => this.addAlert({ message, type, identifier }));
    }
}
