import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { OpenUniversityCartUpdateRequest, OtmId, PaymentCreateRequest, PaymentSystemPayment } from 'common-typescript/types';
import { Observable, Subject, switchMap, tap } from 'rxjs';

import { OpenUniversityCartCustomerService } from './open-university-cart-customer.service';

const CONFIG = {
    ENDPOINTS: {
        backend: '/ilmo/api/open-university',
        createPayment() {
            return `${this.backend}/payment-system/create-payment`;
        },
        completePayment() {
            return `${this.backend}/payment-system/complete-payment`;
        },
    },
};

@Injectable({ providedIn: 'root' })
export class PaymentSystemService {

    constructor(
        private http: HttpClient,
        private openUniversityCartService: OpenUniversityCartCustomerService,
        @Inject(DOCUMENT) private document: Document,
    ) {}

    initializePaymentAndNavigateToPaymentSystem(openUniversityCartId: OtmId, language: string, openUniversityCartUpdateRequest: OpenUniversityCartUpdateRequest): Observable<PaymentSystemPayment> {
        const body: PaymentCreateRequest = { openUniversityCartId, language, openUniversityCartUpdateRequest };
        return this.http.post<PaymentSystemPayment>(CONFIG.ENDPOINTS.createPayment(), body)
            .pipe(
                switchMap((payment) => {
                    // Refresh the current cart, and wait for the refresh to complete before navigating to the payment system.
                    // The refresh prevents stale cart state in the UI in browsers that open the payment system in a new tab.
                    // Waiting for the refresh to complete prevents a system error alert that results from a cancelled request.
                    const payment$ = new Subject<PaymentSystemPayment>();
                    this.openUniversityCartService.refreshCurrentCart().add(() => {
                        payment$.next(payment);
                        payment$.complete();
                    });
                    return payment$.asObservable();
                }),
                tap(payment => this.document.location.href = payment.paymentAddress),
            );
    }

    completePayment(
        queryParams?: HttpParams | { [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean> },
        body: any = null,
    ): Observable<PaymentSystemPayment> {
        return this.http.post<PaymentSystemPayment>(
            CONFIG.ENDPOINTS.completePayment(),
            body,
            {
                params: queryParams,
                // Angular doesn't set the Content-Type header if the body is null/undefined.
                // The backend throws an exception without the header, so need to set it manually.
                headers: { 'Content-Type': 'application/json' },
            },
        )
            // Refresh the current cart after the payment has been processed, so that no stale state is displayed e.g. in the timer alert
            .pipe(tap(() => this.openUniversityCartService.refreshCurrentCart()));
    }
}
