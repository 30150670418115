<div class="edit-mode editing">
  <div class="header-edit-mode-with-title">
    <div class="row">
      <div class="title">
        <h3 translate="FILE_UPLOAD.FILE_TITLE"></h3>
      </div>
      <div class="buttons">
        <button (click)="close()"
                type="button"
                class="btn btn-primary btn-hollow btn-lg"
                translate="SIS_COMPONENTS.BUTTON.CANCEL"></button>
        <button (click)="save()"
                type="button"
                class="btn btn-primary btn-lg"
                translate="SIS_COMPONENTS.BUTTON.SAVE"></button>
      </div>
    </div>
  </div>
  <div class="container-content">
    <sis-file-upload
            [isRemovable]="true"
            [isDisabled]="false"
            (droppedFiles)="droppedFiles($event)"
            [acceptedFilesTypes]="acceptedFileTypes"
            [maxFiles]="20"
            [staffEditMode]="true"
            [previousFilesEditable]="true"
            [previouslyAddedFiles]="this.previouslyAddedFiles">
    </sis-file-upload>
  </div>
</div>
