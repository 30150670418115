<nav [attr.aria-label]="'ARIA_LABEL.AUTH_MENU' | translate">
  <div ngbDropdown display="static" class="nav-menu" #dropdown=ngbDropdown>
    <button type="button"
            ngbDropdownToggle
            class="non-button"
            aria-labelledby="user-info">
      <div class="user">
        <div class="user-avatar">
          <span class="initials">{{firstNameInitialLetter}}{{lastNameInitialLetter}}</span>
        </div>
        <div class="user-name" id="user-info">
          <div class="user-name-name notranslate">{{displayName}}</div>
          <div class="user-name-role" data-cy="user-name-role">{{activeRoleNameKey | translate}}</div>
        </div>
        <sis-icon icon="chevron" color="white" class="user-arrow" [iconClass]="'cw-90'"></sis-icon>
      </div>
    </button>

    <div ngbDropdownMenu class="dropdown-menu-right" data-cy="user-settings-menu">
      <div *ngIf="languageSelections?.length > 0" class="language-selections" aria-labelledby="change-language" role="group">
        <div class="dropdown-header" aria-hidden="true" id="change-language">{{'AUTH.CHANGE_LANGUAGE' | translate}}</div>
        <button type="button"
                ngbDropdownItem
                *ngFor="let selection of languageSelections"
                (click)="changeLanguage(selection.language); dropdown.close()"
                (keydown.enter)="changeLanguage(selection.language); dropdown.close()"
                [class.active]="selection.language === currentLanguage"
                [attr.aria-current]="selection.language === currentLanguage">
            {{selection.name}}
          <sis-icon icon="check" *ngIf="selection.language === currentLanguage"></sis-icon>
        </button>
      </div>

      <div *ngIf="universitySelections?.length > 1" aria-labelledby="change-university" role="group">
        <div class="divider"></div>
        <div class="dropdown-header" aria-hidden="true" id="change-university">{{'AUTH.CHANGE_UNIVERSITY' | translate}}</div>
        <button type="button"
                ngbDropdownItem
                *ngFor="let university of universitySelections"
                (click)="changeUniversity(university.id)"
                [class.active]="university.id === currentUniversityOrgId"
                [attr.aria-current]="university.id === currentUniversityOrgId"
                class="university-selection">
          {{university.name}}
          <sis-icon icon="check" *ngIf="university.id === currentUniversityOrgId"></sis-icon>
        </button>
      </div>

      <div *ngIf="userRoleSelections?.length > 0" aria-labelledby="user-role" role="group">
        <div class="divider"></div>
        <div class="dropdown-header" aria-hidden="true" id="user-role">{{'AUTH.USER_ROLE' | translate}}</div>
        <a ngbDropdownItem
           *ngFor="let role of userRoleSelections"
           [href]="role.url"
           (click)="updateActiveRole(role); dropdown.close()"
           [class.active]="role.active"
           [attr.aria-current]="role.active"
           class="role-selection anchor-button"
           data-cy="user-role"
        >
          {{role.nameKey | translate}}
          <sis-icon icon="check" *ngIf="role.active"></sis-icon>
        </a>
      </div>

      <ng-container *ngIf="universityLinks$ | async as universityLinks">
        <ng-container *ngIf="universityLinks?.length > 0">
          <div class="divider"></div>
          <a ngbDropdownItem
             *ngFor="let link of universityLinks"
             [href]="link.url"
             target="_blank"
             class="anchor-button"
             rel="noopener noreferrer">
            {{link.name | localizedString:currentLanguage}}
            <span class="visually-hidden" translate>ARIA_LABEL.OPENS_TO_A_NEW_TAB</span>
            <sis-icon icon="new-tab"></sis-icon>
          </a>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="navigationLinks?.length > 0">
        <div class="divider"></div>
        <a ngbDropdownItem
           *ngFor="let link of navigationLinks"
           [href]="link.url"
           (click)="link.beforeNavigation && link.beforeNavigation($event); dropdown.close()"
           [ngClass]="link.class">
          {{link.nameKey | translate}}
        </a>
      </ng-container>

    </div>
  </div>
</nav>
