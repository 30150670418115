import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import {
    Message,
    MessageConversation,
    OtmId,
} from 'common-typescript/types';
import _ from 'lodash';

import {
    MessageEntityService,
    MessageRelation,
    MessageWithResolvedData,
} from '../service/message-entity.service';

@Component({
    selector: 'sis-message-conversation-list-item',
    templateUrl: './sis-message-conversation-list-item.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class SisMessageConversationListItemComponent implements OnChanges {

    @Input() messageConversations: MessageConversation[];

    @Input() selectedMessageConversationIds: OtmId[];

    @Output() moveToMessageConversationView = new EventEmitter<string>();

    @Output() toggleConversationSelection = new EventEmitter<OtmId>();

    selectedMessageTargetNamesByMessageId: Map<OtmId, string> = new Map<OtmId, string>();

    constructor(private messageEntityService: MessageEntityService) {
    }

    ngOnChanges() {
        this.resolveSelectedMessageTargetNames();
    }

    resolveSelectedMessageTargetNames = () => {
        _.forEach(this.messageConversations, (conversation: MessageConversation) => {
            this.messageEntityService.loadRelations(conversation.selectedMessage, [MessageRelation.MODULE, MessageRelation.COURSEUNIT])
                .subscribe((data: MessageWithResolvedData) => {
                    const { message } = data;
                    this.selectedMessageTargetNamesByMessageId.set(_.get(message, 'id'),
                                                                   this.messageEntityService.getMessageTargetNameFromResolvedData(data));
                });
        });
    };

    getMessageTypeTranslationKey(message: Message): string {
        return this.messageEntityService.getMessageTypeTranslationKey(message);
    }

    getMessageTargetName = (message: Message): string => this.selectedMessageTargetNamesByMessageId.get(message.id);

    getSenderFullName(message: Message): string {
        return _.join(_.compact([message.senderFirstNames, message.senderLastName]), ' ');
    }

    goToSpecificMessageConversation(event: Event, messageConversation: MessageConversation): void {
        this.moveToMessageConversationView.emit(messageConversation.conversationId);
    }

    isMessageConversationSelected(conversationId: OtmId): boolean {
        return _.includes(this.selectedMessageConversationIds, conversationId);
    }

    toggleSelection(conversationId: OtmId) {
        this.toggleConversationSelection.emit(conversationId);
    }
}
