<ng-container *transloco="let t">
  <ng-container *ngIf="plan$ | async as plan">
    <div class="modal-header">
      <h2>{{ t('PLAN.COPY_PLAN_MODAL.TITLE') }}</h2>
      <sis-modal-close-button/>
    </div>
    <div class="modal-body">
      <div class="sis-mb-lg" *ngIf="validationDetails.length > 0">
        <sis-validation-error-summary [validationErrorDetails]="validationDetails"
                                      [description]="t('PLAN.COPY_PLAN_MODAL.VALIDATION.ERROR_SUMMARY_TITLE')">
        </sis-validation-error-summary>
      </div>
      <form [formGroup]="form">
        <div class="form-group">
          <sis-text-field sisAutofocus
                          [id]="'plan-plan-copy-modal-name-input-id'"
                          [label]="t('PLAN.COPY_PLAN_MODAL.NAME')"
                          [control]="name">
          </sis-text-field>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <sis-button [hollow]="true" (clicked)="modal.dismiss()">{{ t('SIS_COMPONENTS.BUTTON.CANCEL') }}</sis-button>
      <sis-button (clicked)="save(plan)">{{ t('PLAN.COPY_PLAN_MODAL.COPY_BUTTON') }}</sis-button>
    </div>
  </ng-container>
</ng-container>
