(function () {
  studentTimingTemplatesService.$inject = ["timingService", "timeLineCourseUnitService"];
  angular.module('student.timing.studentTimingTemplateService', ['student.timing.timingService', 'student.timing.timeLineCourseUnitService']).factory('studentTimingTemplateService', studentTimingTemplatesService);
  function studentTimingTemplatesService(timingService, timeLineCourseUnitService) {
    return {
      introduceTimingTemplateToPlan: function (plan, timingTemplate, validatablePlan, module, validatablePlanConstructor) {
        var unTimedUnattained = timeLineCourseUnitService.getUntimedCourseUnitsForModuleRecursively(module, validatablePlan);
        var unTimedByGroupId = _.keyBy(unTimedUnattained, 'groupId');
        var courseUnitTimingsForTimeLineService = _.chain(timingTemplate.courseUnitTimings).filter(function (courseUnitTiming) {
          return !!_.get(unTimedByGroupId, courseUnitTiming.courseUnitGroupId);
        }).map(function (courseUnitTiming) {
          return {
            courseUnit: _.get(unTimedByGroupId, courseUnitTiming.courseUnitGroupId),
            periodLocators: courseUnitTiming.plannedPeriods
          };
        }).value();
        return timingService.timeMultipleCourseUnitsToPeriods(plan, courseUnitTimingsForTimeLineService, validatablePlanConstructor);
      }
    };
  }
})();