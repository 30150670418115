'use strict';

(function () {
  labelErrorCheck.$inject = ["$log"];
  angular.module('sis-common.form').directive('labelErrorCheck', labelErrorCheck);

  /**
   * @ngInject
   *
   * Handy directive that changes label classes depending on which input
   * the label is linked (with the 'for' attribute).
   * Label's target has to have name attribute.
   *
   * Label can point to ngForm too!
   *
   */
  function labelErrorCheck($log) {
    return {
      restrict: 'A',
      require: '^^form',
      scope: false,
      link: function (scope, elem, attr, form) {
        if (!attr.for) {
          $log.error('"for" attribute mandatory when using label-error-check');
        }
        var labelTargetId = attr.for;
        var labelTargetElement = elem.parent().find('#' + labelTargetId);
        if (!labelTargetElement) {
          $log.error('element referenced by "for" not found by id ' + attr.for);
        }
        if (!labelTargetElement.attr('name')) {
          $log.error('input element with id ' + attr.for + ' missing the "name" attribute');
        }
        var targetElementName = labelTargetElement.attr('name');
        if (!targetElementName) {
          targetElementName = _.get(_.first(labelTargetElement), 'attributes.name.value');
        }
        scope.$watch(function () {
          if (!form[targetElementName]) {
            $log.error('form does not have controller for ' + targetElementName);
            return '';
          }
          return '' + form[targetElementName].$valid + form[targetElementName].$dirty + isRequired(labelTargetElement);
        }, function (watchedValues) {
          if (watchedValues !== '' && form[targetElementName].$dirty) {
            if (form[targetElementName].$invalid) {
              elem.removeClass('valid');
              elem.addClass('invalid');
            } else {
              elem.removeClass('invalid');
              elem.addClass('valid');
            }
          } else {
            elem.removeClass('invalid');
            elem.removeClass('valid');
          }
          if (isRequired(labelTargetElement)) {
            elem.addClass('required');
          } else {
            elem.removeClass('required');
          }
        });
        function isRequired(element) {
          var classesSignifyingRequired = ['ng-invalid-valdr-required', 'ng-valid-valdr-required', 'ng-invalid-valdr-not-empty', 'ng-valid-valdr-not-empty'];
          return element.attr('ng-required') || _.some(classesSignifyingRequired, function (cls) {
            return element.hasClass(cls);
          });
        }
      }
    };
  }
})();