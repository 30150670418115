<ng-container *transloco="let t">
  <sis-description-list>
    <ng-template #listItem>
      <dt>{{t('MODULE_INFO.TITLE_CODE_NAME')}}</dt>
      <dd>{{ _module?.code }} </dd>
      <dd>{{ _module?.name | localizedString}}</dd>
    </ng-template>
    <ng-template *ngIf="_module?.studyLevel" #listItem>
      <dt>{{t('MODULE_INFO.TITLE_STUDY_LEVEL')}}</dt>
      <dd>{{(_module.studyLevel | codeLocalizedName | async)}}</dd>
    </ng-template>
    <ng-template *ngIf="_module?.gradeScaleId">
      <dt>{{t('MODULE_INFO.TITLE_GRADE_SCALE')}}</dt>
      <dd>{{(_module.gradeScaleId | gradeScale | async)?.name | localizedString}}</dd>
    </ng-template>
    <ng-template *ngIf="_module?.targetCredits" #listItem>
      <dt>{{t('MODULE_INFO.TITLE_CREDIT_RANGE')}} ({{ t('MODULE_INFO.CREDITS_ABBREVIATION') }})</dt>
      <dd>{{(_module.targetCredits | creditRange:'TEXT':true)}}</dd>
    </ng-template>
    <ng-template *ngIf="_module?.curriculumPeriodIds?.length > 0" #listItem>
      <dt>{{t('MODULE_INFO.TITLE_CURRICULUMPERIOD')}}</dt>
      <ng-container *ngFor="let curriculumPeriod of sortedCurriculumPeriods$ | async">
        <dd>{{curriculumPeriod.abbreviation | localizedString}}</dd>
      </ng-container>
      <dd></dd>
    </ng-template>
    <ng-template *ngIf="_module?.educationClassificationUrns?.length > 0" #listItem>
      <dt>{{t('MODULE_INFO.TITLE_EDUCATION_CLASSIFICATION')}}</dt>
      <ng-container *ngFor="let educationClassificationUrn of _module?.educationClassificationUrns">
        <dd>{{educationClassificationUrn | codeLocalizedName | async}}</dd>
      </ng-container>
    </ng-template>
    <ng-container *ngIf="validResponsibilityInfos$ | async as validResponsibilityInfos">
      <ng-template *ngIf="validResponsibilityInfos?.length > 0" #listItem>
        <dt>{{t('MODULE_INFO.TITLE_RESPONSIBILITY_INFOS')}}</dt>
        <ng-container *ngFor="let responsibilityInfo of validResponsibilityInfos">
          <dd>
            {{responsibilityInfo.roleUrn | codeLocalizedName | async}}:
            <ng-container *ngIf="(responsibilityInfo?.personId | publicPerson | async) as person">
              {{ person | personFullName}}{{person.emailAddress? ', ' : ''}}{{person.emailAddress}}</ng-container>
            <ng-container *ngIf="responsibilityInfo.text | localizedString as responsibilityInfoText">{{responsibilityInfo?.personId ? ', ' : ''}}{{responsibilityInfoText}}</ng-container>
            <ng-container *ngIf="responsibilityInfo.validityPeriod | dateRangeDisplay as validityPeriod">, {{validityPeriod}}</ng-container>
          </dd>
        </ng-container>
      </ng-template>
    </ng-container>
    <ng-template *ngIf="_module?.universityOrgIds?.length > 0" #listItem>
      <dt>{{t('MODULE_INFO.TITLE_UNIVERSITIES')}}</dt>
      <ng-container *ngFor="let universityId of _module?.universityOrgIds">
        <dd>{{(universityId | organisation | async)?.name | localizedString }}</dd>
      </ng-container>
    </ng-template>
    <ng-template #listItem>
      <dt>{{t('MODULE_INFO.TITLE_RESPONSIBLE_ORGANISATIONS')}}</dt>
      <dd>
        <sis-organisation-role-share [organisations]="_module?.organisations"
                                     [organisationRoleUrn]="'urn:code:organisation-role:responsible-organisation'"
                                     [hideLabel]="true">
        </sis-organisation-role-share>
      </dd>
    </ng-template>
    <ng-template
      *ngIf="_module?.organisations | hasOrganisationRole:'urn:code:organisation-role:coordinating-organisation'"
      #listItem>
      <dt>{{t('MODULE_INFO.TITLE_COORDINATING_ORGANISATIONS')}}</dt>
      <dd>
        <sis-organisation-role-share [organisations]="_module?.organisations"
                                     [organisationRoleUrn]="'urn:code:organisation-role:coordinating-organisation'"
                                     [hideLabel]="true">
        </sis-organisation-role-share>
      </dd>
    </ng-template>
  </sis-description-list>
</ng-container>
