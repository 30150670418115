import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import {
    ComponentDowngradeMappings,
    DowngradedComponent,
    StaticMembers,
} from 'sis-common/types/angular-hybrid';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'app-any-course-unit-rule-ripa-checkbox.component.ts',
    templateUrl: './any-course-unit-rule-ripa-checkbox.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class AnyCourseUnitRuleRipaCheckboxComponent {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'student.plan.anyCourseUnitRuleRipaCheckbox',
        directiveName: 'appAnyCourseUnitRuleRipaCheckbox',
    };

    @Input() checked = false;

    @Output() checkedChange = new EventEmitter<boolean>();

    checkedChangeHandler() {
        this.checked = !this.checked;
        this.checkedChange.emit(this.checked);
    }
}
