import { Pipe, PipeTransform } from '@angular/core';
import { LocalizedString } from 'common-typescript/types';

import { LocaleService } from './locale.service';

function isLocalizedString(obj: unknown): obj is LocalizedString {
    return obj && typeof obj === 'object' && !Array.isArray(obj) &&
        Object.entries(obj).every(([key, value]) => typeof key === 'string' && (!value || typeof value === 'string'));
}

/**
 * A pipe for resolving the localized value of a property in the given object. The property must be a `LocalizedString`.
 * <p/>
 * This pipe is mainly useful when needing to display the localized value of a single property from an object that is
 * fetched asynchronously, e.g.:
 *
 * ```
 * courseUnitId | courseUnit | async | localizeProperty:'name'
 * ```
 *
 * If the object is available directly, it's better to use the `localizedString` pipe. If more than property is needed
 * from the object, it's better to fetch the object into a variable and access the properties from there.
 */
@Pipe({ name: 'localizeProperty' })
export class LocalizePropertyPipe implements PipeTransform {

    constructor(private localeService: LocaleService) {}

    transform<T>(object: T, property: keyof T): string | undefined {
        const propertyValue = (object && property && typeof object === 'object') ? object[property] : undefined;
        return isLocalizedString(propertyValue) ? this.localeService.localize(propertyValue) : undefined;
    }
}
