<div [class.grouping-panel]="!hideGroupingPanel">
  <div *ngIf="!control && heading" role="heading" class="sis-mb-xs sis-label grouping-panel-header" [attr.aria-level]="level">{{ heading | translate }}</div>
  <label *ngIf="control && heading" [class]="'grouping-panel-header ' + labelState">{{ heading | translate }}</label>
  <ng-content select="sis-tiny-badge.header-badge"></ng-content>

  <sis-tooltip *ngIf="tooltipIcon && !popoverTooltip" [tooltipBlockElement]="true" [tooltipClass]="tooltipClass">
    <span class="sis-tooltip-content" [attr.id]="tooltipId" aria-hidden="true">{{tooltipText | translate}}</span>
    <span class="sis-tooltip-trigger-content icons-wrapper" tabindex="0" [attr.aria-describedby]="tooltipId" role="tooltip">
      <sis-icon [icon]="tooltipIcon" [color]="tooltipIconColor"></sis-icon>
    </span>
  </sis-tooltip>

  <sis-popover *ngIf="tooltipIcon && popoverTooltip" popoverClass="dark">
    <button type="button" class="non-button sis-popover-trigger-content" [attr.aria-label]="'ARIA_LABEL.SHOW_DETAILS' | translate">
      <sis-icon [icon]="tooltipIcon" [color]="tooltipIconColor" aria-hidden="true"></sis-icon>
    </button>
    <span class="sis-popover-popover-content" [attr.id]="tooltipId">{{tooltipText | translate}}</span>
  </sis-popover>

  <ng-content select="[basic-grouping-panel=headerButton]"></ng-content>

  <p *ngIf="guidance" class="guidance">{{guidance | translate}}</p>
  <div class="grouping-panel-content">
    <ng-content></ng-content>
  </div>
</div>
