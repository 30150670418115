import { Component, Input, ViewEncapsulation } from '@angular/core';
import { CourseUnitRealisation } from 'common-typescript/types';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

/**
 * No reliance on any enrichment or computed values.
 * This component performs better than CurFlowStateBadgeDynamicComponent when course unit realisation is immutable (or fields are not
 * mutated). If
 * an update on view is desired then a new object reference should be passed. So this component is useless when you have a form that mutates
 * fields of same course unit realisation that is passed here.
 */
@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-cur-flow-state-badge',
    templateUrl: './cur-flow-state-badge.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CurFlowStateBadgeComponent {
    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.badge.curFlowStateBadge',
        directiveName: 'sisCurFlowStateBadge',
    };

    @Input() courseUnitRealisation: CourseUnitRealisation;
    @Input() hidePublishDate?: boolean;
}
