import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { OtmId, PersonInfo } from 'common-typescript/types';

import { EntityService } from './entity.service';

const CONFIG = {
    ENDPOINTS: {
        baseUrl: '/ori/api',
    },
};

@Injectable({ providedIn: 'root' })
@NgEntityServiceConfig({
    baseUrl: CONFIG.ENDPOINTS.baseUrl,
    resourceName: 'person-infos',
})
export class PersonInfoEntityService extends EntityService<PersonInfoState> {
    constructor() {
        super(PersonInfoStore, PersonInfoQuery);
    }
}

type PersonInfoState = EntityState<PersonInfo, OtmId>;

@StoreConfig({ name: 'person-info' })
class PersonInfoStore extends EntityStore<PersonInfoState> {}

class PersonInfoQuery extends QueryEntity<PersonInfoState> {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(store: EntityStore<PersonInfoState>) {
        super(store);
    }
}
