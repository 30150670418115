import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import {
    OtmId,
    PaymentCategory,
    PrivatePerson,
    StudentPayment,
} from 'common-typescript/types';
import _ from 'lodash';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { UniversityService } from '../service/university.service';

import { EntityService } from './entity.service';
import { PaymentCategoryEntityService } from './payment-category-entity.service';
import { PrivatePersonEntityService } from './private-person-entity.service';

const CONFIG = {
    ENDPOINTS: {
        backend: '/ori/api',
        forStudent(studentId: OtmId): string {
            return `${this.backend}/student-payments/for-student/${studentId}`;
        },
        createStudentPayments(): string {
            return `${this.backend}/student-payments/batch`;
        },
    },
};

@Injectable({ providedIn: 'root' })
@NgEntityServiceConfig({ baseUrl: CONFIG.ENDPOINTS.backend })
export class StudentPaymentEntityService extends EntityService<StudentPaymentsState> {

    readonly relationObservables: { [key: string]: Function } = {
        [StudentPaymentRelation.PAYMENT_CATEGORIES]: () => this.paymentCategoryEntityService.getByUniversityOrgId(this.universityService.getCurrentUniversityOrgId()),
        [StudentPaymentRelation.REGISTERER_PERSONS]: (studentPayments: StudentPayment[]) => this.privatePersonEntityService.getByIds(_.compact(_.uniq(_.map(studentPayments, 'registeredBy')))),
        [StudentPaymentRelation.INVALIDATOR_PERSONS]: (studentPayments: StudentPayment[]) => this.privatePersonEntityService.getByIds(_.compact(_.uniq(_.map(studentPayments, 'invalidatedBy')))),
    };

    readonly relationEnums: StudentPaymentRelation[] = Object.values(StudentPaymentRelation);

    constructor(private paymentCategoryEntityService: PaymentCategoryEntityService,
                private universityService: UniversityService,
                private privatePersonEntityService: PrivatePersonEntityService,
    ) {
        super(StudentPaymentStore, StudentPaymentQuery);
    }

    loadRelations(studentPayments: StudentPayment[], requestedRelations: StudentPaymentRelation[]): Observable<StudentPaymentsWithResolvedData> {
        return combineLatest([
            of(studentPayments),
            ...this.constructRelationObservables<StudentPayment[], StudentPaymentRelation>(this.relationEnums, requestedRelations, this.relationObservables, studentPayments),
        ]).pipe(
            map(data => data as unknown as [StudentPayment[], PaymentCategory[], PrivatePerson[], PrivatePerson[]]),
            map(
                (data) => {
                    const [payments, paymentCategories, registererPersons, invalidatorPersons] = data;
                    return {
                        paymentCategories,
                        registererPersons,
                        invalidatorPersons,
                        studentPayments: payments,
                    };
                },
            ),
        );
    }

    findForStudent(studentId: OtmId): Observable<StudentPayment[]> {
        if (!studentId) {
            return of([]);
        }
        return this.getHttp().get<StudentPayment[]>(CONFIG.ENDPOINTS.forStudent(studentId))
            .pipe(
                tap(payments => this.store.upsertMany(payments)),
                switchMap(() => this.selectAll({ filterBy: studentPayment => studentPayment.studentId === studentId })),
            );
    }

    createStudentPayments(studentPayments: StudentPayment[]): Observable<StudentPayment[]> {
        return this.getHttp().post<StudentPayment[]>(CONFIG.ENDPOINTS.createStudentPayments(), studentPayments)
            .pipe(
                tap(payments => this.store.upsertMany(payments)),
                switchMap(payments => this.query.selectMany(payments.map(p => p.id))),
            );
    }
}

export enum StudentPaymentRelation {
    PAYMENT_CATEGORIES = 'paymentCategories',
    REGISTERER_PERSONS = 'registererPersons',
    INVALIDATOR_PERSONS = 'invalidatorPersons',
}

export interface StudentPaymentsWithResolvedData {
    studentPayments: StudentPayment[];
    paymentCategories?: PaymentCategory[];
    registererPersons?: PrivatePerson[];
    invalidatorPersons?: PrivatePerson[];
}

type StudentPaymentsState = EntityState<StudentPayment, OtmId>;

@StoreConfig({ name: 'student-payments' })
class StudentPaymentStore extends EntityStore<StudentPaymentsState> {}

class StudentPaymentQuery extends QueryEntity<StudentPaymentsState> {
    constructor(protected store: StudentPaymentStore) {
        super(store);
    }
}
