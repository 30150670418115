import angular from 'angular';
import 'angular-translate';
import _ from 'lodash';
import { planValidationResultHelperModule } from 'sis-components/service/planValidationResultHelper.service';
import { ModuleContentApprovalState } from 'common-typescript';
import showViewModuleContentApplicationLinkTpl from './showViewModuleContentApplicationLink.tpl.html';
import 'sis-components/sharedModel/shared.module';
import 'sis-components/service/studentApplication.service';
import 'sis-components/applications/moduleContentApplicationStructure.component';
import '../../../profile/applications/showModuleContentApplicationModal.service';
import 'sis-components/error-handler/legacy/errorService';
import 'sis-common/auth/auth.module';
import 'sis-common/auth/auth.service';
(function () {
  ShowViewModuleContentApplicationLinkController.$inject = ["$log", "showViewModuleContentApplicationLinkService", "commonStudentApplicationService", "ShowModuleContentApplicationModal", "AuthService", "defaultPromiseHandler", "uiContext", "errorService", "$translate", "planValidationResultHelper"];
  angular.module('student.common.components.showViewModuleContentApplicationLink', ['pascalprecht.translate', 'student.shared', 'sis-components.service.studentApplicationService', 'sis-components.applications.moduleContentApplicationStructure', 'student.profile.applications.showModuleContentApplicationModal', 'sis-common.errorhandler.errorService', 'sis-common.auth', planValidationResultHelperModule]).component('showViewModuleContentApplicationLink', {
    template: showViewModuleContentApplicationLinkTpl,
    bindings: {
      module: '<',
      validatablePlan: '<'
    },
    controller: ShowViewModuleContentApplicationLinkController
  });
  function ShowViewModuleContentApplicationLinkController(
  // NOSONAR
  $log, showViewModuleContentApplicationLinkService, commonStudentApplicationService, ShowModuleContentApplicationModal, AuthService, defaultPromiseHandler, uiContext, errorService, $translate, planValidationResultHelper) {
    const ctrl = this;
    ctrl.$onChanges = function () {
      ctrl.moduleContentApplication = undefined;
      ctrl.studentViewableModuleContentApplication = undefined;
      if (ctrl.validatablePlan && ctrl.module) {
        ctrl.moduleContentApplication = ctrl.validatablePlan.getEffectiveModuleContentApproval(ctrl.module.id);
      }
      if (!ctrl.moduleContentApplication && ctrl.validatablePlan && ctrl.module) {
        const parentModuleSelection = _.get(ctrl.validatablePlan.moduleIdSelectionMap, ctrl.module.id);
        const parentModuleId = _.get(parentModuleSelection, 'parentModuleId');
        let mcas = _.filter(ctrl.validatablePlan.moduleContentApplications, moduleContentApplication => moduleContentApplication.educationId === _.get(ctrl.validatablePlan.rootModule, 'id') && moduleContentApplication.approvedModuleId === ctrl.module.id && moduleContentApplication.parentModuleId === parentModuleId);

        // rejected or revoked
        // order by last modified date to get the latest first.
        mcas = _.orderBy(mcas, mca => mca.metadata.lastModifiedOn, 'desc');
        ctrl.moduleContentApplication = _.first(mcas);
      }
      if (ctrl.moduleContentApplication && ctrl.module && commonStudentApplicationService.isStudentViewableModuleContentApplication(ctrl.moduleContentApplication.state) && commonStudentApplicationService.isMatchingModuleContentApplication(ctrl.moduleContentApplication, ctrl.validatablePlan, ctrl.module.id)) {
        ctrl.studentViewableModuleContentApplication = ctrl.moduleContentApplication;
      }
    };
    ctrl.hasStudentViewableModuleContentApplication = function () {
      return !!ctrl.studentViewableModuleContentApplication;
    };
    ctrl.openShowModuleContentApplicationModal = function () {
      ShowModuleContentApplicationModal.open(ctrl.module, ctrl.studentViewableModuleContentApplication).then(successResult => {
        if (successResult && successResult.state && (successResult.state === 'CANCELLED' || successResult.state === 'REVOKED')) {
          uiContext.planContext.toPlan().then(plan => {
            uiContext.planContext.buildAndUpdate(plan, true);
            ctrl.$onChanges();
          });
        }
      }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    };
    function getPlanValidationResult() {
      return _.get(uiContext, 'planContext.planValidationResult');
    }
    ctrl.areSelectionsValidByApplication = () => {
      if (ctrl.moduleContentApplication) {
        const moduleValidationResult = planValidationResultHelper.getModuleValidationResult(_.get(ctrl.module, 'id'), getPlanValidationResult());
        return _.get(moduleValidationResult, 'moduleContentApprovalValidationState') === ModuleContentApprovalState.VALID;
      }
      return false;
    };
  }
})();