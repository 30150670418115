import { Injectable } from '@angular/core';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { merge, Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

import { AuthService } from '../auth/auth-service';
import { TokenStorageService } from '../auth/token-storage.service';
import { ConfigService } from '../config/config.service';

/**
 *  - When user is logged in and active, we call preAuth every 3 minutes in order to keep the Shibboleth session alive.
 *  - When user is idle, we do not poll preAuth, so the Shibboleth session may expire.
 *  - When the user is logged in and returns from idling, we immediately call preAuth in order to log the user out in case the Shibboleth session has expired.
 */

export interface SessionTimeoutEvent {
    start?: boolean;
    stop?: boolean;
}

@Injectable({ providedIn: 'root' })
export class IdleService {

    idle = false;
    pollerStarted = false;
    loggedIn = false;
    polling = false;
    pollIntervalInMilliseconds = 120 * 1000; // 2 minutes
    sessionTimeoutEvent$ = new Subject<SessionTimeoutEvent>();
    allowIdleEvents = true;
    defaultIdleTimeout = 50;

    constructor(private ngIdle: Idle,
                private authService: AuthService,
                private configService: ConfigService,
                private tokenStorageService: TokenStorageService,
    ) {
        this.registerUserLoginEvents();
        this.registerIdleEvents();
    }

    getTimeoutInSeconds() {
        return this.configService.get().idleTimeout || this.defaultIdleTimeout;
    }

    pollIfLoggedInAndNotIdle() {
        if (!this.idle && this.loggedIn) {
            this.poll();
        }
    }

    poll(): void {
        if (!this.polling) {
            this.polling = true;
            this.authService.refreshAuthToken().pipe(take(1)).subscribe({
                error: () => {},
                complete: () => {
                    this.polling = false;
                },
            });
        }
    }

    registerIdleEvents(): void {
        this.ngIdle.onIdleEnd.subscribe(() => {
            if (this.allowIdleEvents) {
                this.sessionTimeoutEvent$.next({ stop: true });
                if (this.loggedIn) {
                    this.poll();
                }
                this.idle = false;
            }
        });

        this.ngIdle.onIdleStart.subscribe(() => {
            if (this.allowIdleEvents && this.loggedIn) {
                this.sessionTimeoutEvent$.next({ start: true });
                this.idle = true;
            }
        });

        this.ngIdle.setIdle(this.getTimeoutInSeconds());
        this.ngIdle.setTimeout(0); // keep watching for idleness forever
        this.ngIdle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        this.ngIdle.watch();
    }

    registerUserLoginEvents(): void {
        this.authService.userLoggedIn$.subscribe((loggedIn) => {
            this.loggedIn = loggedIn;

            if (!this.pollerStarted) {
                this.pollerStarted = true;
                setInterval(() => this.pollIfLoggedInAndNotIdle(), this.pollIntervalInMilliseconds);
            }
        });
    }

    getLoginStatus(): boolean {
        return this.loggedIn;
    }

    toggleIdleEvents() {
        this.allowIdleEvents = !this.allowIdleEvents;
    }

    logout(logoutReason?: string) {
        this.authService.clear();
        this.authService.goToLoginPage(logoutReason);
    }

    getSessionTimeoutEvent(): Observable<SessionTimeoutEvent> {
        return this.sessionTimeoutEvent$.asObservable();
    }
}
