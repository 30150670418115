import { Component, Input, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';
import { Event, OtmId, StudyEvent } from 'common-typescript/types';
import _ from 'lodash';

@Component({
    selector: 'sis-study-sub-group-structure-study-event',
    templateUrl: './study-sub-group-structure-study-event.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class StudySubGroupStructureStudyEventComponent implements OnChanges {

    hasTeacherOverrides: boolean;
    cancelledEventCount: number;
    allEventsExcludedOrCancelled: boolean;
    hasNoLocations: boolean;
    hasLocationOverrides: boolean;

    irregularEvents: Event[] = [];

    @Input() teacherIds: OtmId[];
    @Input() studyEvent: StudyEvent;
    @Input() language: string;

    ngOnChanges() {
        this.hasTeacherOverrides = !_.isEmpty(_.filter(this.studyEvent.overrides, override =>
            override.irregularTeacherIds !== null));
        this.cancelledEventCount = _.size(_.filter(this.studyEvent.events, event => event.cancelled || event.excluded));
        this.allEventsExcludedOrCancelled = _.every(this.studyEvent.events, event =>
            event.cancelled || event.excluded);
        this.hasNoLocations = _.isEmpty(this.studyEvent.locationIds);
        this.hasLocationOverrides = !_.isEmpty(_.filter(this.studyEvent.overrides, override =>
            override.irregularLocationIds !== null));
        this.irregularEvents = this.getIrregularEvents();
    }

    getIrregularEvents(): Event[] {
        return this.studyEvent.events.filter((ev: Event) =>
            ev.excluded ||
            ev.cancelled ||
            ev.irregularLocationIds !== null ||
            !!ev.notice ||
            ev.irregularTeacherIds !== null);
    }
}
