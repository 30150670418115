import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { OtmId, TermRegistration, TuitionFeeObligationPeriod } from 'common-typescript/types';
import { Subscription } from 'rxjs';

import { AppErrorHandler } from '../../error-handler/app-error-handler';
import { TuitionFeeObligationPeriodEntityService } from '../../service/tuition-fee-obligation-period.entity.service';

@Component({
    selector: 'sis-study-year-term-registrations',
    templateUrl: './study-year-term-registrations.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class StudyYearTermRegistrationsComponent implements OnChanges, OnInit, OnDestroy {

    /**
     * Determines grouping panel's heading's semantic aria-level for screen readers, default is equivalent for h5
     */
    @Input() level?: number = 5;
    @Input() studyTermNamesByTermIndex: Map<number, string>;
    @Input() studyYearName: string;
    @Input() termRegistrations: TermRegistration[];
    @Input() studyRightId: OtmId;

    tuitionFeeObligationPeriods: TuitionFeeObligationPeriod[] = [];
    hasMissingPayments = false;
    hasTuitionFeeObligationByTermIndex: Map<number, boolean> = new Map();
    subscription = Subscription.EMPTY;

    constructor(
        private tuitionFeeObligationPeriodService: TuitionFeeObligationPeriodEntityService,
        private appErrorHandler: AppErrorHandler,
        private ref: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        this.subscription = this.tuitionFeeObligationPeriodService.findForStudyRight(this.studyRightId)
            .pipe(this.appErrorHandler.defaultErrorHandler())
            .subscribe({
                next: (periods) => {
                    this.tuitionFeeObligationPeriods = periods;
                    this.updateVariables();
                    this.ref.markForCheck();
                },
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.termRegistrations) {
            this.updateVariables();
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    updateVariables() {
        this.hasMissingPayments = this.termRegistrations.some(termRegistration =>
            this.tuitionFeeObligationPeriodService.isTuitionFeePaymentMissing(termRegistration, this.tuitionFeeObligationPeriods));
        this.hasTuitionFeeObligationByTermIndex = new Map(this.termRegistrations.map(termRegistration =>
            [termRegistration.studyTerm.termIndex, this.tuitionFeeObligationPeriodService.hasTuitionFeeObligation(termRegistration, this.tuitionFeeObligationPeriods)],
        ));
    }

}
