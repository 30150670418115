import { Injectable } from '@angular/core';
import { StudyPeriod } from 'common-typescript/types';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';

import { StudyYearsEntityService } from './study-years-entity.service';
import { UniversityService } from './university.service';

@Injectable({ providedIn: 'root' })
export class CurrentStudyPeriodService {
    /**
     * Current study period if found, otherwise null.
     */
    readonly currentStudyPeriod$: Observable<StudyPeriod | null>;

    constructor(
        universityService: UniversityService,
        studyYearsEntityService: StudyYearsEntityService,
    ) {
        // The subject is just a trick to prevent the result from being cached forever.
        // It also delays running any actual logic before the first subscription.
        this.currentStudyPeriod$ = new BehaviorSubject(undefined).pipe(
            switchMap(() => studyYearsEntityService.getCurrentStudyPeriod(universityService.getCurrentUniversityOrgId()).pipe(
                // it's not declared by getCurrentStudyPeriod, but according to its current implementation at least undefined should be possible
                map((currentStudyPeriod: StudyPeriod | undefined) => currentStudyPeriod ?? null),
            )),
            shareReplay({ bufferSize: 1, refCount: true }),
        );
    }
}
