<ng-container *transloco="let t">
  <div class="modal-header">
    <fudis-heading [level]="2" [variant]="'md'">
      {{t('PROFILE.EXTENSIONS.EXTENSION_DECISION')}}
    </fudis-heading>
    <button type="button"
            class="modal-close"
            (click)="close()"
            [attr.aria-label]="t('ARIA_LABEL.CLOSE')">
      <sis-icon icon="ring-close"></sis-icon>
    </button>
  </div>
  <div class="modal-body">
    <fudis-dl>
      <fudis-dl-item>
        <fudis-dt [contentText]="t('PROFILE.EXTENSIONS.STUDENT')"></fudis-dt>
        <fudis-dd [contentText]="values.studyRight.studentId|privatePersonBasicInfo|async|personFullNameReversed"></fudis-dd>
      </fudis-dl-item>
      <fudis-dl-item>
        <fudis-dt [contentText]="t('PROFILE.STUDY_RIGHT')"></fudis-dt>
        <fudis-dd [contentText]="(values.studyRight.educationId|education|async)?.name|localizedString"></fudis-dd>
      </fudis-dl-item>
      <fudis-dl-item>
        <fudis-dt [contentText]="t('PROFILE.EXTENSIONS.VALIDITY')"></fudis-dt>
        <fudis-dd [contentText]="extension|studyRightExtensionValidity|localDateRange"></fudis-dd>
      </fudis-dl-item>
      <fudis-dl-item>
        <fudis-dt [contentText]="t('PROFILE.EXTENSIONS.EXTENSION_LENGTH')"></fudis-dt>
        <fudis-dd [contentText]="t(extension.extensionCount,'PROFILE.EXTENSIONS.',(extension.extensionCount === 1 ? 'SEMESTER' : 'SEMESTERS'))"></fudis-dd>
      </fudis-dl-item>
      <fudis-dl-item *ngIf="applicationDecision?.resolutionRationale">
        <fudis-dt [contentText]="t('PROFILE.EXTENSIONS.DECISION_RATIONALE')"></fudis-dt>
        <fudis-dd [contentText]="applicationDecision.resolutionRationale"></fudis-dd>
      </fudis-dl-item>
      <fudis-dl-item *ngIf="applicationDecision?.extensionInfo">
        <fudis-dt [contentText]="t('PROFILE.EXTENSIONS.ADDITIONAL_INFO')"></fudis-dt>
        <fudis-dd [contentText]="applicationDecision.extensionInfo"></fudis-dd>
      </fudis-dl-item>
      <fudis-dl-item *ngIf="extension.grantReason">
        <fudis-dt [contentText]="t('PROFILE.EXTENSIONS.RATIONALE')"></fudis-dt>
        <fudis-dd [contentText]="extension.grantReason"></fudis-dd>
      </fudis-dl-item>
    </fudis-dl>
    <div *ngIf="applicationDecision" class="mt-5">
      <fudis-link [externalLink]="values.applicationViewUrl" [title]="t('PROFILE.EXTENSIONS.OPEN_APPLICATION')"></fudis-link>
    </div>
  </div>
  <div class="modal-footer">
    <fudis-button [label]="t('SIS_COMPONENTS.BUTTON.CLOSE')" (handleClick)="close()"></fudis-button>
  </div>
</ng-container>
