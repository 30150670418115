export const commonDateConstantsModule = 'sis-components.date.constants';
angular.module(commonDateConstantsModule, []).constant('DATE', {
  REGEX: '^\\d{1,2}\\.\\d{1,2}\\.\\d{4}$',
  MOMENT_FORMAT: 'DD.MM.YYYY',
  DATEPICKER_FORMAT: 'dd.MM.yyyy',
  DATEPICKER_ALT_FORMATS: ['d!.M!.yyyy', 'yyyy-M!-d!']
}).constant('NUMBER', {
  DECIMAL_REGEX: '\d+((\,|\.)\d+)?'
}).constant('dateFormat', {
  LOCAL_DATE: 'YYYY-MM-DD',
  LOCAL_DATETIME: 'YYYY-MM-DDTHH:mm',
  LOCAL_DATETIME_WITH_MILLISECONDS: 'YYYY-MM-DDTHH:mm:ss.SSS',
  TIME: 'HH.mm'
}).constant('pattern', {
  TIME: /^([0-9]|0[0-9]|1[0-9]|2[0-3])\.[0-5][0-9]$/
}).constant('dateUtils', {
  asLocalDate: function (date) {
    if (date === null || date === undefined) {
      return undefined;
    }
    return moment(date).format('YYYY-MM-DD');
  },
  formatShort: function (date) {
    if (date === null || date === undefined) {
      return undefined;
    }
    return moment(date).format('DD.MM.YYYY');
  },
  formatShortWithTime: function (date) {
    if (date === null || date === undefined) {
      return undefined;
    }
    return moment(date).format('D.M.YYYY H.m');
  },
  dateEqual: function (date1, date2) {
    if (!date1 && !date2) {
      return true;
    }
    if (!date1 || !date2) {
      return false;
    }
    const moment1 = moment(date1);
    const moment2 = moment(date2);
    return moment1.isSame(moment2, 'day');
  },
  dateTimeEqual: function (date1, date2) {
    if (!date1 && !date2) {
      return true;
    }
    if (!date1 || !date2) {
      return false;
    }
    const moment1 = moment(date1);
    const moment2 = moment(date2);
    return moment1.isSame(moment2, 'minute');
  }
}).constant('localizedStringUtils', {
  areEqual: function (string1, string2) {
    return string1 && _.keys(string1).sort().join() === _.keys(string2).sort().join() && _.values(string1).sort().join() === _.values(string2).sort().join();
  }
}).constant('DOCUMENT_STATES', {
  DRAFT: {
    name: 'DOCUMENT_STATE.DRAFT',
    id: 'DRAFT'
  },
  ACTIVE: {
    name: 'DOCUMENT_STATE.ACTIVE',
    id: 'ACTIVE'
  },
  DELETED: {
    name: 'DOCUMENT_STATE.DELETED',
    id: 'DELETED'
  },
  values: function () {
    return _.filter(_.mapValues(this), value => !_.isFunction(value));
  }
});