import { Pipe, PipeTransform } from '@angular/core';
import { EntityState } from '@datorama/akita';
import {
    AdmissionTarget,
    AssessmentItem,
    AttainedQualification,
    Attainment,
    CooperationNetwork,
    CourseUnit,
    CourseUnitRealisation,
    CurriculumPeriod,
    Education,
    EnrolmentRight, ExternalIssuer,
    GradeScale,
    Location,
    Module,
    OpenUniversityProduct,
    OpenUniversityProductCode,
    Organisation,
    OtmId,
    PaymentCategory,
    PersonInfo,
    PrivatePerson,
    PrivatePersonBasicInfo,
    PublicPerson,
    Qualification,
    StudyEvent,
    Workflow,
} from 'common-typescript/types';
import { Observable } from 'rxjs';

import { AppErrorHandler } from '../error-handler/app-error-handler';
import { AdmissionTargetEntityService } from '../service/admission-target-entity.service';
import { AssessmentItemEntityService } from '../service/assessment-item-entity.service';
import { AttainedQualificationEntityService } from '../service/attained-qualification-entity.service';
import { AttainmentEntityService } from '../service/attainment-entity.service';
import { CooperationNetworkEntityService } from '../service/cooperation-network-entity.service';
import { CourseUnitEntityService } from '../service/course-unit-entity.service';
import { CourseUnitRealisationEntityService } from '../service/course-unit-realisation-entity.service';
import { CurriculumPeriodEntityService } from '../service/curriculum-period-entity.service';
import { EducationEntityService } from '../service/education-entity.service';
import { EnrolmentRightEntityService } from '../service/enrolment-right-entity.service';
import { EntityService } from '../service/entity.service';
import { ExternalIssuerEntityService } from '../service/external-issuer-entity.service';
import { GradeScaleEntityService } from '../service/grade-scale-entity.service';
import { LocationEntityService } from '../service/location-entity.service';
import { ModuleEntityService } from '../service/module-entity.service';
import { OpenUniversityProductCodeEntityService } from '../service/open-university-product-code-entity.service';
import { OpenUniversityProductEntityService } from '../service/open-university-product-entity.service';
import { OrganisationEntityService } from '../service/organisation-entity.service';
import { PaymentCategoryEntityService } from '../service/payment-category-entity.service';
import { PersonInfoEntityService } from '../service/person-info-entity.service';
import { PrivatePersonBasicInfoEntityService } from '../service/private-person-basic-info-entity.service';
import { PrivatePersonEntityService } from '../service/private-person-entity.service';
import { PublicPersonEntityService } from '../service/public-person-entity.service';
import { QualificationEntityService } from '../service/qualification-entity.service';
import { StudyEventEntityService } from '../service/study-event-entity.service';
import { WorkflowEntityService } from '../service/workflow-entity.service';

export class EntityPipe<T> implements PipeTransform {
    constructor(
        private appErrorHandler: AppErrorHandler,
        private entityService: EntityService<EntityState<T, OtmId>>,
    ) {}

    /**
     * Return null on falsy id.
     * Return observable of null when error is caught. This simulates the pattern where service calls are handled with default promise
     * handler and no value is assigned to variable in component.
     */
    transform(id: OtmId): Observable<T> {
        if (!id) {
            return null;
        }
        return this.entityService.getById(id)
            .pipe(this.appErrorHandler.defaultErrorHandler());
    }
}

@Pipe({ name: 'admissionTarget' })
export class AdmissionTargetPipe extends EntityPipe<AdmissionTarget> implements PipeTransform {
    constructor(appErrorHandler: AppErrorHandler, private service: AdmissionTargetEntityService) {
        super(appErrorHandler, service);
    }
}

@Pipe({ name: 'assessmentItem' })
export class AssessmentItemPipe extends EntityPipe<AssessmentItem> implements PipeTransform {
    constructor(appErrorHandler: AppErrorHandler, private service: AssessmentItemEntityService) {
        super(appErrorHandler, service);
    }
}

@Pipe({ name: 'attainedQualification' })
export class AttainedQualificationPipe extends EntityPipe<AttainedQualification> implements PipeTransform {
    constructor(appErrorHandler: AppErrorHandler, private service: AttainedQualificationEntityService) {
        super(appErrorHandler, service);
    }
}

@Pipe({ name: 'attainment' })
export class AttainmentPipe extends EntityPipe<Attainment> implements PipeTransform {
    constructor(appErrorHandler: AppErrorHandler, private service: AttainmentEntityService) {
        super(appErrorHandler, service);
    }
}

@Pipe({ name: 'cooperationNetwork' })
export class CooperationNetworkPipe extends EntityPipe<CooperationNetwork> implements PipeTransform {
    constructor(appErrorHandler: AppErrorHandler, private service: CooperationNetworkEntityService) {
        super(appErrorHandler, service);
    }
}

@Pipe({ name: 'courseUnit' })
export class CourseUnitPipe extends EntityPipe<CourseUnit> implements PipeTransform {
    constructor(appErrorHandler: AppErrorHandler, private service: CourseUnitEntityService) {
        super(appErrorHandler, service);
    }
}

@Pipe({ name: 'courseUnitRealisation' })
export class CourseUnitRealisationPipe extends EntityPipe<CourseUnitRealisation> implements PipeTransform {
    constructor(appErrorHandler: AppErrorHandler, private service: CourseUnitRealisationEntityService) {
        super(appErrorHandler, service);
    }
}

@Pipe({ name: 'curriculumPeriod' })
export class CurriculumPeriodPipe extends EntityPipe<CurriculumPeriod> implements PipeTransform {
    constructor(appErrorHandler: AppErrorHandler, private service: CurriculumPeriodEntityService) {
        super(appErrorHandler, service);
    }
}

@Pipe({ name: 'enrolmentRight' })
export class EnrolmentRightPipe extends EntityPipe<EnrolmentRight> implements PipeTransform {
    constructor(appErrorHandler: AppErrorHandler, private service: EnrolmentRightEntityService) {
        super(appErrorHandler, service);
    }
}

@Pipe({ name: 'education' })
export class EducationPipe extends EntityPipe<Education> implements PipeTransform {
    constructor(appErrorHandler: AppErrorHandler, private service: EducationEntityService) {
        super(appErrorHandler, service);
    }
}

@Pipe({ name: 'module' })
export class ModulePipe extends EntityPipe<Module> implements PipeTransform {
    constructor(appErrorHandler: AppErrorHandler, private service: ModuleEntityService) {
        super(appErrorHandler, service);
    }
}

@Pipe({ name: 'openUniversityProduct' })
export class OpenUniversityProductPipe extends EntityPipe<OpenUniversityProduct> implements PipeTransform {
    constructor(appErrorHandler: AppErrorHandler, private service: OpenUniversityProductEntityService) {
        super(appErrorHandler, service);
    }
}

@Pipe({ name: 'openUniversityProductCode' })
export class OpenUniversityProductCodePipe extends EntityPipe<OpenUniversityProductCode> implements PipeTransform {
    constructor(appErrorHandler: AppErrorHandler, private service: OpenUniversityProductCodeEntityService) {
        super(appErrorHandler, service);
    }
}

@Pipe({ name: 'organisation' })
export class OrganisationPipe extends EntityPipe<Organisation> implements PipeTransform {
    constructor(appErrorHandler: AppErrorHandler, private service: OrganisationEntityService) {
        super(appErrorHandler, service);
    }
}

@Pipe({ name: 'paymentCategory' })
export class PaymentCategoryPipe extends EntityPipe<PaymentCategory> implements PipeTransform {
    constructor(appErrorHandler: AppErrorHandler, private service: PaymentCategoryEntityService) {
        super(appErrorHandler, service);
    }
}

@Pipe({ name: 'personInfo' })
export class PersonInfoPipe extends EntityPipe<PersonInfo> implements PipeTransform {
    constructor(appErrorHandler: AppErrorHandler, private service: PersonInfoEntityService) {
        super(appErrorHandler, service);
    }
}

@Pipe({ name: 'privatePerson' })
export class PrivatePersonPipe extends EntityPipe<PrivatePerson> implements PipeTransform {
    constructor(appErrorHandler: AppErrorHandler, private service: PrivatePersonEntityService) {
        super(appErrorHandler, service);
    }
}

@Pipe({ name: 'privatePersonBasicInfo' })
export class PrivatePersonBasicInfoPipe extends EntityPipe<PrivatePersonBasicInfo> implements PipeTransform {
    constructor(appErrorHandler: AppErrorHandler, private service: PrivatePersonBasicInfoEntityService) {
        super(appErrorHandler, service);
    }
}

@Pipe({ name: 'publicPerson' })
export class PublicPersonPipe extends EntityPipe<PublicPerson> implements PipeTransform {
    constructor(appErrorHandler: AppErrorHandler, private service: PublicPersonEntityService) {
        super(appErrorHandler, service);
    }
}

@Pipe({ name: 'qualification' })
export class QualificationPipe extends EntityPipe<Qualification> implements PipeTransform {
    constructor(appErrorHandler: AppErrorHandler, private service: QualificationEntityService) {
        super(appErrorHandler, service);
    }
}

@Pipe({ name: 'studyEvent' })
export class StudyEventPipe extends EntityPipe<StudyEvent> implements PipeTransform {
    constructor(appErrorHandler: AppErrorHandler, private service: StudyEventEntityService) {
        super(appErrorHandler, service);
    }
}

@Pipe({ name: 'gradeScale' })
export class GradeScalePipe extends EntityPipe<GradeScale> implements PipeTransform {
    constructor(appErrorHandler: AppErrorHandler, private service: GradeScaleEntityService) {
        super(appErrorHandler, service);
    }
}

@Pipe({ name: 'location' })
export class LocationPipe extends EntityPipe<Location> implements PipeTransform {
    constructor(appErrorHandler: AppErrorHandler, private service: LocationEntityService) {
        super(appErrorHandler, service);
    }
}

@Pipe({ name: 'workflow' })
export class WorkflowPipe extends EntityPipe<Workflow> implements PipeTransform {
    constructor(appErrorHandler: AppErrorHandler, private service: WorkflowEntityService) {
        super(appErrorHandler, service);
    }
}

@Pipe({ name: 'externalIssuer' })
export class ExternalIssuerPipe extends EntityPipe<ExternalIssuer> implements PipeTransform {
    constructor(appErrorHandler: AppErrorHandler, private service: ExternalIssuerEntityService) {
        super(appErrorHandler, service);
    }
}
