import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
    CustomStudyDraft,
    PriorLearningApplication,
    PriorLearningInclusionApplication,
    PriorLearningInclusionWorkflowApplication,
    PriorLearningWorkflowApplication,
} from 'common-typescript/types';
import { Observable } from 'rxjs';
import { AlertsService } from 'sis-components/alerts/alerts-ng.service';
import { FileItem } from 'sis-components/file-upload/file-upload.component';
import { required } from 'sis-components/form/form-validators';
import { getLabelState } from 'sis-components/form/formUtils';
import { SisFormBuilder } from 'sis-components/form/sis-form-builder.service';
import { STUDENT_APPLICATION_TYPE } from 'sis-components/model/student-application-constants';
import { STUDENT_WORKFLOW_APPLICATION_TYPE } from 'sis-components/model/student-workflow-constants';

@Component({
    selector: 'app-prior-learning-application-confirmation',
    templateUrl: './prior-learning-application-confirmation.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class PriorLearningApplicationConfirmationComponent implements OnInit {

    @Input() application: Partial<PriorLearningApplication | PriorLearningWorkflowApplication>;
    @Input() attachments: FileItem[];
    @Input() externalAttainedStudyIds$: Observable<string[]>;
    @Input() disableButtons = false;
    @Output() exit = new EventEmitter<void>();
    @Output() previous = new EventEmitter<void>();
    @Output() submit = new EventEmitter<Partial<PriorLearningApplication | PriorLearningWorkflowApplication>>();

    mustPrintDecision: FormControl;
    getLabelState = getLabelState;

    constructor(
        private alertsService: AlertsService,
        private fb: SisFormBuilder,
    ) {}

    ngOnInit(): void {
        this.mustPrintDecision = this.fb.control(this.application?.mustPrintDecision, required());
    }

    get customStudyDraft(): CustomStudyDraft | null {
        return (this.isInclusionApplication(this.application) || this.isPriorLearningWorkflowInclusionApplication(this.application))
            ? this.application.customStudyDraft : null;
    }

    isInclusionApplication(application: Partial<PriorLearningApplication | PriorLearningWorkflowApplication>):
        application is (PriorLearningInclusionApplication | PriorLearningInclusionWorkflowApplication) {
        return application?.type === STUDENT_APPLICATION_TYPE.PRIOR_LEARNING_INCLUSION_APPLICATION;
    }

    isPriorLearningWorkflowInclusionApplication(application: Partial<PriorLearningApplication | PriorLearningWorkflowApplication>):
        application is PriorLearningInclusionWorkflowApplication {
        return application?.type === STUDENT_WORKFLOW_APPLICATION_TYPE.PRIOR_LEARNING_INCLUSION_APPLICATION;
    }

    onSubmit(): void {
        if (this.mustPrintDecision.valid) {
            this.submit.emit({ mustPrintDecision: this.mustPrintDecision.value });
        } else {
            this.mustPrintDecision.markAsTouched();
            this.alertsService.addFormSubmissionFailureAlert();
        }
    }
}
