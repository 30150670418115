import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { OtmId, Plan } from 'common-typescript/types';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { PlanOption, PlanSelectService } from '../../../common/service/plan-select.service';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'app-choose-plan',
    templateUrl: './choose-plan.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ChoosePlanComponent implements OnInit {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'student.calendar.choosePlan.downgraded',
        directiveName: 'appChoosePlan',
    };

    @Input() plans: Plan[];
    @Input() selectedPlanId: OtmId;
    @Output() selected = new EventEmitter<OtmId>();

    selectedPlan: Plan;
    options: PlanOption[];

    constructor(private planSelectService: PlanSelectService) {}

    ngOnInit() {
        this.selectPlan(this.selectedPlanId);
        this.options = this.planSelectService.createSelectOptions(this.plans);
    }

    onSelect(planId: OtmId): void {
        this.selectPlan(planId);
        this.selected.emit(planId);
    }

    private selectPlan(planId: OtmId): void {
        this.selectedPlan = (this.plans || []).find(plan => plan.id === planId);
    }
}
