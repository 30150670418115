import { Injectable, Provider } from '@angular/core';

import { CommonSisEnvironment } from './commonSisEnvironment';

/**
 * Environment configuration.
 *
 * This service provides Angular's environment configuration (`environment.ts` values) through dependency injection to
 * make mocking "environment variables" easy and robust in tests.
 *
 * To add new environment variables, remember to update `CommonSisEnvironment` interface accordingly and add the new
 * variables as class properties to this class. The values are propagated automatically.
 *
 * @example Provider in root module
 * import { environment } from '../environments/environment';
 *
 * NgModule({ // JSDoc does not accept the 'at' sign inside example block...
 *     providers: [
 *         EnvironmentService.forRoot(environment),
 *     ],
 * })
 *
 * @example Usage in component
 * constructor(private environmentService: EnvironmentService) {}
 *
 * ngOnInit() {
 *     console.log(this.environmentService);
 * }
 */
@Injectable({ providedIn: 'root' })
export class EnvironmentService implements CommonSisEnvironment {
    // Don't hard-code the types, refer to them through CommonSisEnvironment as done below!
    frontendName: CommonSisEnvironment['frontendName'];
    production: CommonSisEnvironment['production'];

    static forRoot(env: CommonSisEnvironment): Provider {
        return {
            provide: EnvironmentService,
            useValue: env,
        };
    }
}
