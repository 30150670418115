import { Title } from '@angular/platform-browser';
import { TranslocoService } from '@ngneat/transloco';
import { RejectType, Transition, TransitionHookFn } from '@uirouter/angular';
import { NGXLogger } from 'ngx-logger';
import { combineLatest, take } from 'rxjs';

/**
 * Returns a transition hook function which prints details of invalid and errored transitions to the console.
 * If the transition failed due to a HTTP 403 error, and this was the initial transition (e.g. initial page load),
 * the handler will initiate a new transition to the provided {@link forbiddenRedirectState}.
 *
 * @param forbiddenRedirectState The name of the state to redirect to after a HTTP 403 error when no previous
 * state is available
 */
export function transitionErrorHandler(forbiddenRedirectState: string): TransitionHookFn {
    return transition => {
        // Log invalid and errored transitions if not caused by an Access Denied error.
        const { detail, type } = transition.error();
        if ((type === RejectType.INVALID || type === RejectType.ERROR) &&
            transition.$to().name !== transition.$from().name && detail.status !== 403) {
            const logger: NGXLogger = transition.injector().get(NGXLogger);
            logger.warn('Transition error', transition.error());
            logger.warn('toState: ', transition.$to());
            logger.warn('toParams: ', transition.$to().params);
            logger.warn('fromState: ', transition.$from());
            logger.warn('fromParams: ', transition.$from().params);
        }
        // Redirect to default view in the case of an access denied error when using a direct link
        if (detail.status === 403 && !transition.$from().name) {
            transition.router.stateService.go(forbiddenRedirectState);
        }
    };
}

/**
 * Returns a transition hook function which updates the HTML document title based on the given transition.
 *
 * @param appTitleKey A translation key for the app title. This will always be included in the document title.
 * @param stateTitleKeyExtractor A function used to extract a translation key from the transition. By default,
 * the `browserTabTitle` data property of the target state (i.e. "to" state) will be used. If defined, the
 * state title will be prepended to the app title.
 */
export function titleUpdateHandler(
    appTitleKey: string,
    stateTitleKeyExtractor: (transition: Transition) => string | null = transition => transition.to().data?.browserTabTitle,
): TransitionHookFn {
    return transition => {
        const title: Title = transition.injector().get(Title);
        const transloco: TranslocoService = transition.injector().get(TranslocoService);
        const stateTitleKey = stateTitleKeyExtractor?.(transition);
        combineLatest([
            transloco.selectTranslate(stateTitleKey),
            transloco.selectTranslate(appTitleKey),
        ])
            .pipe(take(1))
            .subscribe(titles => title.setTitle(titles.filter(Boolean).join(' - ')));
    };
}
