import angular from 'angular';
export const planServiceModule = 'student.common.service.planService';
(function () {
  planServiceFactory.$inject = ["$http"];
  angular.module(planServiceModule, []).factory('planService', planServiceFactory);
  function planServiceFactory($http) {
    var baseUri = "/osuva/api/my-plans";
    var api = {
      get: function (id) {
        return $http.get(baseUri + "/" + id).then(function (result) {
          return result.data;
        });
      },
      delete: function (id) {
        return $http.delete(baseUri + "/" + id);
      },
      findMyPlans: function () {
        return $http.get(baseUri).then(function (result) {
          return result.data;
        });
      },
      createOrUpdate: function (plan) {
        return plan.id ? api.update(plan) : api.create(plan);
      },
      create: function (plan) {
        return $http.post(baseUri, plan).then(function (result) {
          return result.data;
        });
      },
      update: function (plan) {
        return $http.put(baseUri + "/" + plan.id, plan).then(function (result) {
          return result.data;
        });
      },
      createNewPlanObject: function (rootId, curriculumPeriodId, learningOpportunityId, name) {
        return {
          rootId: rootId,
          curriculumPeriodId: curriculumPeriodId,
          learningOpportunityId: learningOpportunityId ? learningOpportunityId : null,
          name: name ? name : null,
          moduleSelections: [{
            moduleId: rootId
          }],
          courseUnitSelections: [],
          customModuleAttainmentSelections: [],
          customCourseUnitAttainmentSelections: [],
          assessmentItemSelections: [],
          timelineNotes: []
        };
      }
    };
    return api;
  }
})();