'use strict';

(function () {
  angular.module('student.common.model.studyPeriod', []).factory('studyPeriodModel', studyPeriodModel);

  /**
   * @ngInject
   */
  function studyPeriodModel() {
    var studyPeriodModel = {
      newStudyYears: newStudyYears,
      StudyYear: StudyYear,
      StudyTerm: StudyTerm,
      StudyPeriod: StudyPeriod
    };
    var YEAR_TARGET_CREDITS = 60;
    function newStudyYears(rawStudyYears) {
      return _.map(rawStudyYears, function (raw) {
        return new StudyYear(raw);
      });
    }
    function StudyYear(data) {
      var self = this;
      var defaults = {};
      enrichAndRenameProps(data, self);
      _.assign(self, defaults, data);
      return self;
    }
    function StudyTerm(data) {
      var self = this;
      var defaults = {};
      _.assign(self, defaults, data);
    }
    function StudyPeriod(data) {
      var self = this;
      var defaults = {
        rows: []
      };
      _.assign(self, defaults, data);
    }

    // Cuts the hierarchical paths so that Angular will not watch the whole hierarchy.
    // (Watch will not follow properties starting with $.)
    // Adds start date for study years.
    // Adds start year for terms.
    // Calculates targetCredits for periods, terms and years.
    function enrichAndRenameProps(rawStudyYear, newStudyYear) {
      rawStudyYear.$studyTerms = [];
      rawStudyYear.targetSize = 0;
      _.forEach(rawStudyYear.studyTerms, function (rawStudyTerm) {
        var studyTerm = new StudyTerm(rawStudyTerm);
        studyTerm.$studyPeriods = [];
        studyTerm.targetSize = 0;
        _.forEach(studyTerm.studyPeriods, function (studyPeriod) {
          studyTerm.startYear = getTermStartyear(rawStudyYear, studyTerm, studyPeriod);
          studyPeriod.$year = newStudyYear;
          studyPeriod.$term = studyTerm;
          if (studyPeriod.includedInTargetCreditsCalculation) {
            studyPeriod.targetSize = studyPeriod.size;
            studyTerm.targetSize += studyPeriod.size;
            rawStudyYear.targetSize += studyPeriod.size;
          } else {
            studyPeriod.targetSize = 0;
          }
          studyTerm.$studyPeriods.push(new StudyPeriod(studyPeriod));
        });
        delete studyTerm.studyPeriods;
        rawStudyYear.$studyTerms.push(studyTerm);
      });
      rawStudyYear.startDate = getStudyYearStartDate(rawStudyYear);
      delete rawStudyYear.studyTerms;
      calculateTargetCredits(rawStudyYear);
    }
    function getTermStartyear(studyYear, studyTerm, studyPeriod) {
      if (!studyTerm.startYear) {
        return studyPeriod.startDate ? moment(studyPeriod.startDate).year() : studyTerm.firstOfYear ? studyYear.startYear : undefined;
      } else {
        return studyTerm.startYear;
      }
    }
    function getStudyYearStartDate(studyYear) {
      return _.get(studyYear, '$studyTerms[0].$studyPeriods[0].$startDate', moment().year(studyYear.startYear).month(7).date(1));
    }
    function calculateTargetCredits(studyYear) {
      var roundedSum = 0;
      var periods = [];
      _.each(studyYear.$studyTerms, function (studyTerm) {
        _.each(studyTerm.$studyPeriods, function (studyPeriod) {
          studyPeriod.rawLimit = YEAR_TARGET_CREDITS * studyPeriod.targetSize / studyYear.targetSize;
          studyPeriod.roundedRaw = Math.round(studyPeriod.rawLimit);
          roundedSum += studyPeriod.roundedRaw;
          periods.push(studyPeriod);
        });
      });
      adjustPeriodTargetCredits(roundedSum, periods);
      _.each(studyYear.$studyTerms, function (studyTerm) {
        studyTerm.targetCredits = 0;
        _.each(studyTerm.$studyPeriods, function (studyPeriod) {
          if (!isNaN(studyPeriod.roundedRaw)) {
            studyPeriod.targetCredits = studyPeriod.roundedRaw;
            studyTerm.targetCredits += studyPeriod.targetCredits;
          }
        });
      });
      studyYear.targetCredits = YEAR_TARGET_CREDITS;
    }
    function adjustPeriodTargetCredits(roundedSum, periods) {
      var minPeriod, minOffset;
      function findMin(period) {
        if (period.targetSize) {
          var offset = (period.roundedRaw + 1 - period.rawLimit) / period.targetSize;
          if (offset < minOffset) {
            minOffset = offset;
            minPeriod = period;
          }
        }
      }
      while (roundedSum < YEAR_TARGET_CREDITS) {
        minPeriod = null;
        minOffset = Infinity;
        _.each(periods, findMin);
        if (!minPeriod) {
          break;
        }
        minPeriod.roundedRaw++;
        roundedSum++;
      }
      function findMinTheOtherWay(period) {
        if (period.targetSize) {
          var offset = (period.rawLimit - period.roundedRaw + 1) / period.targetSize;
          if (offset < minOffset) {
            minOffset = offset;
            minPeriod = period;
          }
        }
      }
      while (roundedSum > YEAR_TARGET_CREDITS) {
        minPeriod = null;
        minOffset = Infinity;
        _.each(periods, findMinTheOtherWay);
        if (!minPeriod) {
          break;
        }
        minPeriod.roundedRaw--;
        roundedSum--;
      }
    }
    return studyPeriodModel;
  }
})();