'use strict';

(function () {
  privatePersonBasicInfoJSDataModel.$inject = ["DS", "PrivatePersonBasicInfoEndpoint", "countryJSDataModel", "personNameService"];
  angular.module('sis-components.model.privatePersonBasicInfo', ['js-data', 'sis-components.model.preferredLanguage', 'sis-components.service.personNameService']).constant('PrivatePersonBasicInfoEndpoint', {
    DEFAULT: '/ori/api/persons-basic'
  }).factory('privatePersonBasicInfoJSDataModel', privatePersonBasicInfoJSDataModel);

  /**
   * @ngInject
   */
  function privatePersonBasicInfoJSDataModel(DS, PrivatePersonBasicInfoEndpoint, countryJSDataModel, personNameService) {
    return DS.defineResource({
      idAttribute: 'id',
      name: 'privatePersonBasicInfo',
      endpoint: PrivatePersonBasicInfoEndpoint.DEFAULT,
      relations: {
        hasOne: {
          preferredLanguage: {
            localField: 'preferredLanguage',
            localKey: 'preferredLanguageUrn'
          }
        }
      },
      computed: {
        fullName: ['firstName', 'firstNames', 'lastName', function (firstName, firstNames, lastName) {
          const personObject = {
            firstName: firstName,
            firstNames: firstNames,
            lastName: lastName
          };
          return personNameService.getFullName(personObject);
        }],
        fullNameReversed: ['firstName', 'firstNames', 'lastName', function (firstName, firstNames, lastName) {
          const personObject = {
            firstName: firstName,
            firstNames: firstNames,
            lastName: lastName
          };
          return personNameService.getFullNameReversed(personObject);
        }]
      }
    });
  }
})();