'use strict';

(function () {
  uiContextFactory.$inject = ["$log", "PlanContext", "PlanManager", "planService", "uiStateStore", "planSeeker", "errorService", "$document", "$animate", "$q", "$timeout", "$anchorScroll", "$rootScope", "planContextEventType", "uiContextEventType", "planSelectionService", "commonStudyRightService", "universityService"];
  angular.module('student.common.context.uiContext', ['ngAnimate', 'sis-common.errorhandler', 'student.common.utils.uiStateStore', 'student.common.service.planService', 'student.common.context.plan-context', 'student.common.components.planSeeker', 'student.common.service.planSelectionService', 'sis-components.service.studyRightService', 'sis-common.university']).constant('uiContextEventType', {
    planContextSet: 'uiContext.planContextSet'
  }).factory('uiContext', uiContextFactory);
  function uiContextFactory(
  // NOSONAR
  $log, PlanContext, PlanManager, planService, uiStateStore, planSeeker, errorService, $document, $animate, $q, $timeout, $anchorScroll, $rootScope, planContextEventType, uiContextEventType, planSelectionService, commonStudyRightService, universityService) {
    var uiContext = this;
    uiContext.newPlan = newPlan;
    uiContext.previewPlanForModule = previewPlanForModule;
    uiContext.loadPlan = loadPlan;
    uiContext.deletePlan = deletePlan;
    uiContext.planContext = null;
    uiContext.planManager = null;
    uiContext.freeEditMode = false;
    uiContext.openFreeEdit = openFreeEdit;
    uiContext.closeFreeEdit = closeFreeEdit;
    uiContext.toggleModuleSelections = toggleModuleSelections;
    uiContext.openModuleSelections = openModuleSelections;
    uiContext.isSelected = isSelected;
    uiContext.hasActiveSelection = hasActiveSelection;
    uiContext.clearSelection = clearSelection;
    uiContext.hasActivePlan = hasActivePlan;
    uiContext.getActivePlanId = getActivePlanId;
    uiContext.isCollapsed = isCollapsed;
    uiContext.toggleCollapsed = toggleCollapsed;
    uiContext.collapsedModules = {};
    // Course units selected in the unplanned side bar to be placed into the plan.
    uiContext.unplannedCourseUnits = [];
    uiContext.unplannedCustomCourseUnitAttainments = [];
    uiContext.unplannedCourseUnitRealisation = undefined;
    uiContext.hasPlanningOfCourseUnit = hasPlanningOfCourseUnit;
    uiContext.hasTargetModule = hasTargetModule;
    uiContext.planCourseUnit = planCourseUnit;
    uiContext.planCourseUnitRealisation = planCourseUnitRealisation;
    uiContext.planCustomCourseUnitAttainment = planCustomCourseUnitAttainment;
    uiContext.cancelPlanningOfCourseUnit = cancelPlanningOfCourseUnit;
    uiContext.getModuleTargetStates = getModuleTargetStates;

    // Feature toggles
    uiContext.freeEditAllowed = true;
    uiContext.priorLearningInclusionApplicationAllowed = true;
    uiContext.customAttainmentApplicationAllowed = true;
    uiContext.readStoredUiContext = readStoredUiContext;

    // private fields
    var sessionStorageKey = "otm.student.uiContext.";
    var _moduleTargetStates = {};
    // modules collapsed when course unit selected in the unplanned side bar.
    var _moarCollapsedModules = {};
    $rootScope.$on(planContextEventType.purge, purgeListener);

    // Set up UI feature toggles from university settings
    universityService.getCurrentUniversitySettings().then(function (data) {
      uiContext.freeEditAllowed = _.get(data, 'frontendFeatureToggles.freeEditModeEnabled', true);
      uiContext.priorLearningInclusionApplicationAllowed = _.get(data, 'frontendFeatureToggles.priorLearningInclusionApplicationEnabled', true);
      uiContext.customAttainmentApplicationAllowed = _.get(data, 'frontendFeatureToggles.customCourseCreditApplicationEnabled', true);
      uiContext.degreeProgrammeAttainmentApplicationAllowed = _.get(data, 'frontendFeatureToggles.degreeProgrammeAttainmentApplicationEnabled', true);
    });
    return uiContext;
    function closeFreeEdit() {
      uiContext.freeEditMode = false;
    }
    function openFreeEdit() {
      uiContext.freeEditMode = true;
    }
    function getModuleTargetStates(moduleId) {
      return _moduleTargetStates[moduleId];
    }
    function planCourseUnit(courseUnit) {
      if (uiContext.planContext) {
        uiContext.unplannedCourseUnitRealisation = undefined;
        uiContext.unplannedCourseUnits = [courseUnit];
        uiContext.unplannedCustomCourseUnitAttainments = [];
        _moduleTargetStates = uiContext.planContext.getCourseUnitTargets(courseUnit);
        setCollapsedModules();
        $timeout(function () {
          $anchorScroll('add-here');
        });
      }
    }
    function planCustomCourseUnitAttainment(customCourseUnitAttainment) {
      if (uiContext.planContext) {
        uiContext.unplannedCourseUnitRealisation = undefined;
        uiContext.unplannedCourseUnits = [];
        uiContext.unplannedCustomCourseUnitAttainments = [customCourseUnitAttainment];
        _moduleTargetStates = uiContext.planContext.getCustomCourseUnitAttainmentTargets();
        setCollapsedModules();
        $timeout(function () {
          $anchorScroll('add-here');
        });
      }
    }
    function planCourseUnitRealisation(courseUnitRealisation, courseUnits) {
      uiContext.unplannedCourseUnits = courseUnits;
      uiContext.unplannedCourseUnitRealisation = courseUnitRealisation;
      uiContext.unplannedCustomCourseUnitAttainments = [];
      _moduleTargetStates = uiContext.planContext.getCourseUnitTargets(courseUnits);
      setCollapsedModules();
      $timeout(function () {
        $anchorScroll('add-here');
      });
    }
    function setCollapsedModules() {
      _.each(_.keys(_moduleTargetStates), function (moduleId) {
        _moarCollapsedModules[moduleId] = _moduleTargetStates[moduleId] && !_moduleTargetStates[moduleId].is_target && !_moduleTargetStates[moduleId].descendant_is_target;
      });
      var moduleAttainments = _.keys(uiContext.planContext.validatablePlan.moduleAttainmentsById);
      var customModuleAttainments = _.map(uiContext.planContext.customModuleAttainmentSelections, 'customModuleAttainmentId');
      _.forEach(_.concat(moduleAttainments, customModuleAttainments), function (moduleId) {
        _moarCollapsedModules[moduleId] = true;
      });
      var suitableModuleTargetModuleIds = _.keys(_.pickBy(_moduleTargetStates, function (moduleTargetState) {
        return moduleTargetState.is_target;
      }));
      if (_.includes(suitableModuleTargetModuleIds, _.get(uiContext, 'selectedModule.id'))) {
        // just leave it open
      } else if (suitableModuleTargetModuleIds.length !== 1) {
        clearSelection();
      }
    }
    function cancelPlanningOfCourseUnit() {
      uiContext.unplannedCourseUnitRealisation = undefined;
      uiContext.unplannedCourseUnits = [];
      uiContext.unplannedCustomCourseUnitAttainments = [];
      _moduleTargetStates = {};
      _moarCollapsedModules = {};
    }
    function hasPlanningOfCourseUnit() {
      return uiContext.unplannedCourseUnits.length > 0 || uiContext.unplannedCustomCourseUnitAttainments.length > 0;
    }
    function hasTargetModule() {
      return !!_.find(_moduleTargetStates, {
        'is_target': true
      });
    }
    function openModule(moduleId) {
      uiContext.selectedModule = moduleId ? uiContext.planContext.getModule(moduleId) : null;
    }
    function toggleModuleSelections(module) {
      if (module.rule && !isSelected(module)) {
        openModule(module.id);
      } else {
        clearSelection();
      }
      storeUiState();
    }
    function openModuleSelections(module) {
      if (module.rule && !isSelected(module)) {
        openModule(module.id);
        storeUiState();
      } else if (module.rule) {
        _.forEach($document.find('course-unit-box.course-unit-box-adding'), function (element) {
          $animate.removeClass(element, 'course-unit-box-adding').then(function () {
            $animate.addClass(element, 'course-unit-box-adding');
          }).catch(angular.noop);
        });
        _.forEach($document.find('custom-course-unit-attainment-box.course-unit-box-adding'), function (element) {
          $animate.removeClass(element, 'course-unit-box-adding').then(function () {
            $animate.addClass(element, 'course-unit-box-adding');
          }).catch(angular.noop);
        });
      }
    }
    function isSelected(module) {
      return !!(module && uiContext.selectedModule && module.id === uiContext.selectedModule.id);
    }
    function isCollapsed(module) {
      if (!module) {
        return false;
      }
      if (hasPlanningOfCourseUnit()) {
        return _moarCollapsedModules[module.id];
      } else {
        return uiContext.collapsedModules[module.id];
      }
    }
    function toggleCollapsed(module) {
      if (module && module.id) {
        if (hasPlanningOfCourseUnit()) {
          toggle(_moarCollapsedModules, module.id);
          // modules toggled in planning mode retain their state after leaving planning mode
          uiContext.collapsedModules[module.id] = _moarCollapsedModules[module.id];
        } else {
          toggle(uiContext.collapsedModules, module.id);
        }
        storeUiState();
      }
    }
    function toggle(o, id) {
      o[id] = !o[id];
    }
    function hasActiveSelection() {
      return !!uiContext.selectedModule;
    }
    function clearSelection() {
      uiContext.selectedModule = null;
      storeUiState();
    }
    function purgeListener() {
      if (uiContext.selectedModule && !uiContext.planContext.getModule(uiContext.selectedModule.id, null)) {
        uiContext.selectedModule = null;
      }
      cancelPlanningOfCourseUnit();
    }
    function hasActivePlan() {
      return !!uiContext.planContext;
    }
    function getActivePlanId() {
      return uiContext.planContext && uiContext.planContext.id;
    }
    function clearPlanContext() {
      if (uiContext.planContext) {
        uiContext.planContext = null;
        uiContext.planManager.dispose();
        uiContext.planManager = null;
        clearSelection();
      }
    }
    function previewPlanForModule(moduleId, curriculumPeriodId) {
      clearPlanContext();
      var planContext = new PlanContext();
      var previewPlan = planService.createNewPlanObject(moduleId, curriculumPeriodId);
      return planSelectionService.makeAutomaticSelectionsForNewPlan(previewPlan, true).then(planContext.build).then(setPlanContext).then(storeUiState).catch(function (error) {
        errorService.showSystemError(error, error.status);
      });
    }
    function newPlan(educationInfo, name, curriculumPeriodId, learningOpportunityId) {
      closeFreeEdit();
      var planContext = new PlanContext();
      return commonStudyRightService.getValidStudyRightByEducationId(educationInfo.id).then(function (studyRight) {
        planContext.studyRight = _.get(studyRight, 'learningOpportunityId') === learningOpportunityId ? studyRight : undefined;
        var newPlan = planService.createNewPlanObject(educationInfo.id, curriculumPeriodId, learningOpportunityId, name);
        return planSelectionService.makeAutomaticSelectionsForNewPlan(newPlan, false, studyRight).then(planService.create).then(res => {
          clearPlanContext();
          return res;
        }).then(planContext.build).then(setPlanContext).then(function (planContext) {
          storeUiState();
          return planContext;
        });
      });
    }
    function removePlanFromSessionStorage() {
      uiStateStore.storeField(sessionStorageKey, 'planId', null);
    }
    function deletePlan() {
      closeFreeEdit();
      var planId = getActivePlanId();
      return planService.delete(planId).then(clearPlanContext).then(removePlanFromSessionStorage).catch(function (error) {
        errorService.showSystemError(error, error.status);
      });
    }
    function loadPlan(planId) {
      closeFreeEdit();
      clearPlanContext();
      var planContext = new PlanContext();
      return planService.get(planId).then(function (plan) {
        return commonStudyRightService.getValidStudyRightByEducationId(plan.rootId).then(function (studyRight) {
          planContext.studyRight = _.get(studyRight, 'learningOpportunityId') === plan.learningOpportunityId ? studyRight : undefined;
          return planContext.build(plan).then(setPlanContext).then(storeUiState);
        });
      });
    }
    function setPlanContext(planContext) {
      uiContext.planContext = planContext;
      if (uiContext.planManager) {
        uiContext.planManager.dispose();
      }
      uiContext.planManager = new PlanManager(uiContext.planContext);
      $rootScope.$broadcast(uiContextEventType.planContextSet);
      return planContext;
    }
    function readStoredUiContext() {
      if (uiContext.planContext && !getStoredOrLastModifiedPlanId() && getStoredOrLastModifiedPlanId() === uiContext.planContext.id) {
        return $q.when();
      }
      uiStateStore.readAndSetField(sessionStorageKey, 'collapsedModules', uiContext, {});
      return getStoredOrLastModifiedPlanId().then(uiContext.loadPlan);
    }
    function getStoredOrLastModifiedPlanId() {
      var storedPlanId = uiStateStore.readField(sessionStorageKey, 'planId');
      if (storedPlanId) {
        return $q.when(storedPlanId);
      }
      return planSeeker.find().then(function (planId) {
        return planId ? $q.when(planId) : $q.reject('no planId');
      });
    }
    function storeUiState() {
      storeField('collapsedModules');
      storeField('selectedModule.id');
      if (uiContext.planContext && uiContext.planContext.id) {
        storeKeyValue('planId', uiContext.planContext.id);
      }
    }
    function storeField(localKey) {
      uiStateStore.storeField(sessionStorageKey, localKey, _.get(uiContext, localKey));
    }
    function storeKeyValue(key, value) {
      uiStateStore.storeField(sessionStorageKey, key, value);
    }
  }
})();