import {
    AfterViewChecked,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    Input,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import angular from 'angular';
import _ from 'lodash';
import { takeWhile } from 'rxjs';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';
import { UuidService } from 'sis-common/uuid/uuid.service';

import { AppErrorHandler } from '../error-handler/app-error-handler';

import { Alert, AlertEvent, AlertEventType, AlertsService } from './alerts-ng.service';

export interface AlertWithState extends Alert {
    state: AlertState | null;
    dismissTimeoutId?: number;
    dismissIntervalId?: number;
}

enum AlertState {
    APPEARED = 'APPEARED',
    CLOSED = 'CLOSED',
}

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-alerts',
    templateUrl: './alerts.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class AlertsComponent implements OnInit, AfterViewChecked {
    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sisComponents.alerts.alerts',
        directiveName: 'sisAlerts',
    };

    static AUTO_COLLAPSE_DELAY = 6000;

    alerts: AlertWithState[] = [];

    /**
     * AngularJS scope to apply changes when using dismiss timeout
     */
    @Input() scope: angular.IScope;

    @ViewChild('alertWrapper') div: ElementRef;

    latestAlertHasBeenFocused = false;
    topNavigationVisible: boolean;

    constructor(
        private appErrorHandler: AppErrorHandler,
        private alertsService: AlertsService,
        private uuidService: UuidService,
        private translateService: TranslateService,
        private readonly changeDetectorRef: ChangeDetectorRef) {}

    ngOnInit() {
        this.alertsService.alertEvents$.subscribe(event => this.handleAlertEvent(event));
        setTimeout(() => this.onWindowScroll());
    }

    ngAfterViewChecked() {

        if (!this.latestAlertHasBeenFocused && this.alerts.length > 0) {

            this.setFocusToAlert();
        }
    }

    private handleAlertEvent(event: AlertEvent): void {

        switch (event.type) {
            case AlertEventType.ADD_ALERT:
                this.latestAlertHasBeenFocused = false;
                this.handleAlert(event.data as Alert);
                break;
            case AlertEventType.DISMISS_ALL:
                this.dismissAllAlerts();
                break;
            case AlertEventType.DISMISS_ALL_MATCHING:
                this.dismissAllMatchingAlerts(event.data as RegExp);
                break;
            case AlertEventType.DISMISS_ALERT:
                this.dismissAlert(event.data as string);
                break;
            case AlertEventType.DISMISS_IF_EXISTS:
                this.dismissAlertIfExists(event.data as string);
                break;
            default:
                break;
        }

    }

    private handleAlert(alert: Alert): void {
        let identifier = _.get(alert, 'identifier');

        if (_.isNil(identifier)) {
            identifier = this.uuidService.randomUUID();
            alert.identifier = identifier;
        }

        if (_.findIndex(this.alerts, { identifier }) >= 0) {
            _.assign(this.alerts[_.findIndex(this.alerts, { identifier })], alert);
        } else {
            this.addAlert(alert);
        }

    }

    private setAlertHeight(alertWithState: AlertWithState): void {
        window.setTimeout(() => {
            const alertElement = document.querySelector(`.alert-id-${alertWithState.identifier}`) as HTMLElement;
            const alertElementInner = document.querySelector(`.alert-id-${alertWithState.identifier} .alert`) as HTMLElement;
            if (alertElement && alertElementInner) {
                alertElement.style.height = `${alertElementInner.offsetHeight}px`;
            }
        });
    }

    private addAlert(alert: Alert): void {
        const alertWithState = alert as AlertWithState;

        if (!alert.role) {
            if (alert.type === 'danger' || alert.type === 'warning') {
                alert.role = 'alert';
            } else {
                alert.role = 'status';
            }
        }

        this.initAlertUpdaterIfDefined(alertWithState);

        this.alerts.push(alertWithState);
        alertWithState.state = AlertState.APPEARED;

        window.setTimeout(() => {
            // Ensure the component is rendered before the next timeout callback is executed
            this.refreshView();
            this.setAlertHeight(alertWithState);
        });

        window.setTimeout(() => {
            alertWithState.state = null;
            this.refreshView();
        }, AlertsComponent.AUTO_COLLAPSE_DELAY);

        const dismiss = _.get(alert, 'dismiss');
        if (!_.isNil(dismiss) && _.isNumber(dismiss)) {
            alertWithState.dismissTimeoutId = window.setTimeout(() => {
                this.dismissAlert(alertWithState.identifier);
                this.refreshView();
            }, dismiss);
        }
    }

    private refreshView(): void {
        if (this.scope) {
            this.scope.$apply();
        }
    }

    private clearAllTimeoutsAndIntervals(): void {
        _.forEach(this.alerts, alert => this.clearTimeoutAndIntervalIfExists(alert));
    }

    private clearTimeoutAndIntervalIfExists(alert: AlertWithState) {
        if (!_.isNil(alert.dismissTimeoutId)) {
            window.clearTimeout(alert.dismissTimeoutId);
        }
        if (!_.isNil(alert.dismissIntervalId)) {
            window.clearInterval(alert.dismissIntervalId);
        }
    }

    dismissAlert(identifier: string): void {
        const alertElement = document.querySelector(`.alert-id-${identifier}`);
        alertElement.addEventListener('transitionend', () => _.remove(this.alerts, { identifier }));
        const alert = _.find(this.alerts, { identifier });

        this.clearTimeoutAndIntervalIfExists(alert);

        if (this.alerts.length === 1) {
            const firstSubmitButton = document.querySelector('button[type="submit"]');
            if (firstSubmitButton) {
                firstSubmitButton.setAttribute('id', 'firstSubmitButton');
                document.getElementById('firstSubmitButton').focus();
            }
        }
        alert.state = AlertState.CLOSED;
    }

    dismissAlertIfExists(identifier: string): void {
        if (!_.isNil(identifier)) {
            if (_.findIndex(this.alerts, { identifier }) >= 0) {
                this.dismissAlert(identifier);
            }
        }
    }

    // "Click" the alert if user presses SpaceBar or Enter
    handleKeyDown(event: KeyboardEvent) {
        if (event.key === ' ' || event.key === 'Enter') {
            event.preventDefault();
            (event.currentTarget as HTMLElement).click();
        }
    }

    setFocusToAlert() {
        const lastAlertsIndex: number = this.alerts.length - 1;
        const closeMessage = this.translateService.instant('ARIA_LABEL.CLOSE');
        const alertRefDiv = this.div.nativeElement.children[lastAlertsIndex];
        alertRefDiv.querySelector('ngb-alert').removeAttribute('role');
        const closeButton = alertRefDiv.querySelector('.btn-close');

        // closeButton doesn't exist when alert.hideDismissibleButton: true
        if (!closeButton) {
            return;
        }
        closeButton.setAttribute('aria-label', `${closeMessage}`);

        // Move focus to clickable alert button if it exists
        if (alertRefDiv.querySelector('.alert-button')) {
            const alertWithButton = alertRefDiv.querySelector('.alert-button');
            alertWithButton.focus();
            this.latestAlertHasBeenFocused = true;
        }
    }

    dismissAllAlerts(): void {
        this.clearAllTimeoutsAndIntervals();
        this.alerts = [];
    }

    dismissAllMatchingAlerts(pattern: RegExp): void {
        if (!_.isNil(pattern)) {
            const matchingAlerts = _.filter(_.map(this.alerts, (alert) => alert.identifier), (id) => pattern.test(id));
            _.forEach(matchingAlerts, (identifier) => this.dismissAlert(identifier));
        }
    }

    onClickAlert(alert: Alert): void {
        if (!_.isNil(alert.onClickCallback)) {
            alert.onClickCallback();
        }

        if (!_.isNil(alert.scrollToElement)) {
            this.scrollToFirstVisibleElement(alert.scrollToElement);
        }
    }

    /**
     * Detect if the top navigation is scrolled out of sight
     */
    @HostListener('window:scroll', [])
    onWindowScroll() {
        this.topNavigationVisible = window.scrollY < 112;
    }

    private scrollToFirstVisibleElement(element: string) {
        const elements: HTMLCollectionOf<any> = document.getElementsByClassName(element);

        if (elements?.length > 0) {
            const firstElement = elements[0] as HTMLElement;
            firstElement.focus();
            firstElement.scrollIntoView({
                block: 'center',
                inline: 'center',
                behavior: 'smooth',
            });
        }
    }

    getAlertStateClassName(alert: AlertWithState): string {
        return alert.state ? alert.state.toLowerCase() : '';
    }

    private initAlertUpdaterIfDefined(alert: AlertWithState): void {
        if (!alert.updater) return;

        const { byInterval, byObservable, updatedMessageProvider } = alert.updater;
        const alertDismisser = () => {
            this.dismissAlertIfExists(alert.identifier);
            this.changeDetectorRef.detectChanges();
        };

        // update by fixed interval
        if (byInterval) {
            alert.dismissIntervalId = window.setInterval(
                () => {
                    alert.message = updatedMessageProvider(alertDismisser);
                    this.changeDetectorRef.detectChanges();
                },
                byInterval,
            );
        }
        // update by notification
        if (byObservable) {
            byObservable
                .pipe(
                    takeWhile(() => alert && alert.state !== AlertState.CLOSED),
                    this.appErrorHandler.defaultErrorHandler(),
                )
                .subscribe({
                    next: (emittedValue?) => {
                        alert.message = updatedMessageProvider(alertDismisser, emittedValue);
                        this.changeDetectorRef.detectChanges();
                    },
                }).add(alertDismisser);
        }
    }
}
