import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MOMENT_DATE_DISPLAY_FORMAT, MOMENT_DATE_TIME_DISPLAY_FORMAT } from 'common-typescript/constants';
import { LocalDateRange, LocalDateTimeRange } from 'common-typescript/types';
import moment from 'moment';

@Pipe({ name: 'dateRangeDisplay' })
export class DateRangeDisplayPipe implements PipeTransform {

    constructor(private translate: TranslateService) {}

    transform(dates: LocalDateRange | LocalDateTimeRange): any {
        if (!dates) {
            return '';
        }
        const localDateRange: LocalDateRange = dates.hasOwnProperty('startDate') ? dates as LocalDateRange : null;
        const localDateTimeRange: LocalDateTimeRange = !localDateRange && dates.hasOwnProperty('startDateTime') ? dates as LocalDateTimeRange : null;

        if ((localDateRange && (localDateRange.startDate || localDateRange.endDate)) || (localDateTimeRange && (localDateTimeRange.startDateTime || localDateTimeRange.endDateTime))) {
            let format: string;
            let startDate: moment.Moment;
            let endDate: moment.Moment;

            if ((localDateRange && localDateRange.startDate) || (localDateTimeRange && localDateTimeRange.startDateTime)) {
                startDate = localDateRange ? moment(localDateRange.startDate, 'YYYY-MM-DD') : moment(localDateTimeRange.startDateTime, 'YYYY-MM-DDTHH-mm');
            }

            if ((localDateRange && localDateRange.endDate) || (localDateTimeRange && localDateTimeRange.endDateTime)) {
                endDate = localDateRange ? moment(localDateRange.endDate, 'YYYY-MM-DD').subtract(1, 'day') : moment(localDateTimeRange.endDateTime, 'YYYY-MM-DDTHH-mm');
            }

            if (startDate && endDate && startDate.isSame(endDate)) {
                format = 'ARE_SAME';
            } else if (startDate && endDate && startDate.isAfter(endDate)) {
                format = 'EMPTY';
            } else if (startDate && !endDate) {
                format = 'START_';
            } else if (!startDate && endDate) {
                format = '_END';
            } else {
                format = 'START_END';
            }
            let startDateString = localDateRange ? startDate ? startDate.format(MOMENT_DATE_DISPLAY_FORMAT) : undefined : startDate ? startDate.format(MOMENT_DATE_TIME_DISPLAY_FORMAT) : undefined;
            if (format === 'START_END' && localDateRange) {
                if (startDate.year() === endDate.year()) {
                    startDateString = startDate.format('D.M.');
                    if (startDate.month() === endDate.month()) {
                        startDateString = startDate.format('D.');
                    }
                }
            }
            return this.translate.instant(`SALES_PERIOD.${format}`, {
                start: startDateString,
                end: localDateRange ? endDate ? endDate.format(MOMENT_DATE_DISPLAY_FORMAT) : undefined : endDate ? endDate.format(MOMENT_DATE_TIME_DISPLAY_FORMAT) : undefined,
            });
        }
        return '';
    }
}
