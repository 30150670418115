import angular from 'angular';
import _ from 'lodash';
import { ModalService } from 'sis-common/modal/modal.service.ts';
import { SelectCourseUnitAndCompletionMethodModalComponent } from './selectCourseUnitAndCompletionMethodModal.component.ts';
import selectCourseUnitAndCompletionMethodModalTpl from './selectCourseUnitAndCompletionMethodModal.tpl.html';
(function () {
  selectCourseUnitAndCompletionMethodModalFactory.$inject = ["modalService"];
  selectCourseUnitAndCompletionMethodModalController.$inject = ["courseUnitInfoModal", "studySelectorService", "commonCurriculumPeriodService", "$q", "courseUnitRealisationNameService"];
  angular.module('student.plan.selectCourseUnitAndCompletionMethodModal', ['student.common.context.plan-context', 'sis-components.service.curriculumPeriodService', 'sis-common.model.courseUnitRealisationNameService', ModalService.downgrade.moduleName]).controller('selectCourseUnitAndCompletionMethodModalController', selectCourseUnitAndCompletionMethodModalController).factory('selectCourseUnitAndCompletionMethodModal', selectCourseUnitAndCompletionMethodModalFactory).component('selectCourseUnitAndCompletionMethodModal', {
    bindings: {
      close: '&',
      resolve: '<'
    },
    template: selectCourseUnitAndCompletionMethodModalTpl,
    controller: selectCourseUnitAndCompletionMethodModalController
  });
  function selectCourseUnitAndCompletionMethodModalFactory(modalService) {
    return {
      open: (courseUnits, validatablePlan, courseUnitRealisation, courseUnitsInPlan, enrolmentTargetId) => modalService.open(SelectCourseUnitAndCompletionMethodModalComponent, {
        courseUnits,
        validatablePlan,
        courseUnitRealisation,
        courseUnitsInPlan,
        enrolmentTargetId
      }, {
        closeWithOutsideClick: true
      }).result.catch(angular.noop)
    };
  }
  function selectCourseUnitAndCompletionMethodModalController(courseUnitInfoModal, studySelectorService, commonCurriculumPeriodService, $q, courseUnitRealisationNameService) {
    const $ctrl = this;
    function createCourseUnitSelectionPromises(courseUnitsByGroupId, currentCurriculumPeriod) {
      return _.map(courseUnitsByGroupId, courseUnitsWithSameGroupId => {
        return studySelectorService.selectStudyForPlan(courseUnitsWithSameGroupId, currentCurriculumPeriod.id, _.get($ctrl.resolve.validatablePlan, 'plan.curriculumPeriodId'));
      });
    }
    $ctrl.$onInit = function () {
      const enrolmentTargetCu = _.find($ctrl.resolve.courseUnits, cu => cu.id === $ctrl.resolve.enrolmentTargetId);
      const courseUnitsNotEnrolled = enrolmentTargetCu ? _.filter($ctrl.resolve.courseUnits, cu => cu.groupId !== enrolmentTargetCu.groupId) : $ctrl.resolve.courseUnits;
      const courseUnitsByGroupId = _.groupBy(courseUnitsNotEnrolled, 'groupId');
      commonCurriculumPeriodService.getCurrentCurriculumPeriod().then(function (currentCurriculumPeriod) {
        const promises = createCourseUnitSelectionPromises(courseUnitsByGroupId, currentCurriculumPeriod);
        return $q.all(promises).then(function (selectedCourseUnitCandidates) {
          $ctrl.courseUnits = _.compact(selectedCourseUnitCandidates);
          if (enrolmentTargetCu) {
            $ctrl.courseUnits.unshift(enrolmentTargetCu);
          }
        }).catch(angular.noop);
      });
      $ctrl.courseUnitRealisationName = courseUnitRealisationNameService.generateShortName($ctrl.resolve.courseUnitRealisation);
      $ctrl.validatablePlan = $ctrl.resolve.validatablePlan;
      $ctrl.courseUnitRealisation = $ctrl.resolve.courseUnitRealisation;
      $ctrl.courseUnitsInPlan = $ctrl.resolve.courseUnitsInPlan;
      $ctrl.enrolmentTargetId = $ctrl.resolve.enrolmentTargetId;
    };
    $ctrl.isAlreadyInPlan = function (courseUnit) {
      return _.some($ctrl.courseUnitsInPlan, ['id', courseUnit.id]);
    };
    $ctrl.isEnrolmentTarget = function (courseUnit) {
      return $ctrl.enrolmentTargetId === courseUnit.id;
    };
    $ctrl.selectCourseUnitForPlanInsertion = function (courseUnit) {
      return $ctrl.close({
        $value: courseUnit
      });
    };
    $ctrl.cancel = function () {
      $ctrl.close({
        $value: null
      });
    };
  }
})();