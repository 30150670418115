import { ChangeDetectionStrategy, Component, Inject, inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Enrolment } from 'common-typescript/types';
import { catchError, EMPTY, Observable, Subject } from 'rxjs';
import { exhaustMap, take, tap } from 'rxjs/operators';
import { ModalService } from 'sis-common/modal/modal.service';
import { AlertsService, AlertType } from 'sis-components/alerts/alerts-ng.service';

import { EnrolmentStudentService } from '../../common/service/enrolment-student.service';

export interface EnrolmentCancellationModalValues {
    enrolment: Enrolment;
}

export function enrolmentCancellationModalOpener(): (enrolment: Enrolment) => NgbModalRef {
    const modalService = inject(ModalService);
    return enrolment => modalService.open(EnrolmentCancellationModalComponent, { enrolment }, { size: 'sm' });
}

@Component({
    selector: 'app-enrolment-cancellation-modal',
    templateUrl: './enrolment-cancellation-modal.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnrolmentCancellationModalComponent implements OnInit, OnDestroy {

    cancel$: Subject<void> = new Subject<void>();
    cancellationFailed$ = new Subject<boolean>();

    constructor(
        private alertsService: AlertsService,
        private enrolmentService: EnrolmentStudentService,
        private modal: NgbActiveModal,
        private translate: TranslateService,
        @Inject(ModalService.injectionToken) private values: EnrolmentCancellationModalValues,
    ) {}

    ngOnInit(): void {
        this.cancel$.pipe(exhaustMap(() => this.cancelEnrolment())).subscribe();
    }

    ngOnDestroy(): void {
        this.cancel$.complete();
    }

    dismiss(): void {
        this.modal.dismiss();
    }

    get enrolment(): Enrolment {
        return this.values?.enrolment;
    }

    private cancelEnrolment(): Observable<Enrolment> {
        return this.enrolmentService.cancelEnrolment(this.enrolment.id)
            .pipe(
                take(1),
                tap(cancelledEnrolment => this.modal.close(cancelledEnrolment)),
                tap(() => this.alertsService.addTemporaryAlert({
                    type: AlertType.INFO,
                    message: this.translate.instant('ENROLMENT.ENROLMENT_CANCEL_SUCCESS'),
                })),
                catchError(() => {
                    this.cancellationFailed$.next(true);
                    return EMPTY;
                }),
            );
    }
}
