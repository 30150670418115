import {
    CustomAttainmentWorkflow,
    CustomModuleContentWorkflow,
    DegreeProgrammeAttainmentWorkflow,
    ModuleAttainmentWorkflow,
    ModuleContentWorkflow,
    PriorLearningInclusionWorkflow,
    PriorLearningSubstitutionWorkflow,
    RequiredModuleContentWorkflow,
    StudyRightExtensionWorkflow,
    Workflow,
} from 'common-typescript/types';

import { STUDENT_WORKFLOW_TYPE } from '../model/student-workflow-constants';

export function isPriorLearningSubstitutionWorkflow(workflow: Workflow): workflow is PriorLearningSubstitutionWorkflow {
    return workflow.type === STUDENT_WORKFLOW_TYPE.PRIOR_LEARNING_SUBSTITUTION_WORKFLOW;
}

export function isPriorLearningInclusionWorkflow(workflow: Workflow): workflow is PriorLearningInclusionWorkflow {
    return workflow.type === STUDENT_WORKFLOW_TYPE.PRIOR_LEARNING_INCLUSION_WORKFLOW;
}

export function isModuleAttainmentWorkflow(workflow: Workflow): workflow is ModuleAttainmentWorkflow {
    return workflow.type === STUDENT_WORKFLOW_TYPE.MODULE_ATTAINMENT_WORKFLOW;
}

export function isCustomAttainmentWorkflow(workflow: Workflow): workflow is CustomAttainmentWorkflow {
    return workflow.type === STUDENT_WORKFLOW_TYPE.CUSTOM_ATTAINMENT_WORKFLOW;
}

export function isRequiredModuleContentWorkflow(workflow: Workflow): workflow is RequiredModuleContentWorkflow {
    return workflow.type === STUDENT_WORKFLOW_TYPE.REQUIRED_MODULE_CONTENT_WORKFLOW;
}

export function isCustomModuleContentWorkflow(workflow: Workflow): workflow is CustomModuleContentWorkflow {
    return workflow.type === STUDENT_WORKFLOW_TYPE.CUSTOM_MODULE_CONTENT_WORKFLOW;
}

export function isModuleContentWorkflow(workflow: Workflow): workflow is ModuleContentWorkflow {
    return isCustomModuleContentWorkflow(workflow) || isRequiredModuleContentWorkflow(workflow);
}

export function isStudyRightExtensionWorkflow(workflow: Workflow): workflow is StudyRightExtensionWorkflow {
    return workflow.type === STUDENT_WORKFLOW_TYPE.STUDY_RIGHT_EXTENSION_WORKFLOW;
}

export function isDegreeProgrammeAttainmentWorkflow(workflow: Workflow): workflow is DegreeProgrammeAttainmentWorkflow {
    return workflow.type === STUDENT_WORKFLOW_TYPE.DEGREE_PROGRAMME_ATTAINMENT_WORKFLOW;
}
