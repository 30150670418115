import {
    ChangeDetectionStrategy,
    Component, computed, DestroyRef, inject,
    Input, OnChanges, OnInit,
    Signal,
    signal,
    ViewEncapsulation,
    WritableSignal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslocoService } from '@ngneat/transloco';
import { ValidatablePlan } from 'common-typescript';
import { PlanValidationResult } from 'common-typescript/src/plan/validation/planValidationResult';
import { CourseUnitCountRule, EntityWithRule, OtmId, RangeValidationResultState } from 'common-typescript/types';
import _ from 'lodash';
import { LocaleService } from 'sis-common/l10n/locale.service';

import { PlanRuleData } from '../../../service/plan-rule-data.service';
import { PlanData, PlanStateObject } from '../../../service/plan-state.service';
import { RuleError, RuleErrorStateService } from '../../rules/rule-error-state.service';
import { RuleClearSignalService } from '../rule-clear-signal.service';

@Component({
    selector: 'sis-plan-structure-course-unit-count-rule',
    templateUrl: './plan-structure-course-unit-count-rule.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanStructureCourseUnitCountRuleComponent implements OnInit, OnChanges {
    ruleClearSignalService: RuleClearSignalService = inject(RuleClearSignalService);
    ruleErrorStateService: RuleErrorStateService = inject(RuleErrorStateService);
    localeService: LocaleService = inject(LocaleService);
    transloco: TranslocoService = inject(TranslocoService);
    destroyRef: DestroyRef = inject(DestroyRef);

    _rule: WritableSignal<CourseUnitCountRule> = signal(undefined);
    @Input({ required: true }) set rule(rule: CourseUnitCountRule) {
        this._rule.set(rule);
    }

    _parentModule: WritableSignal<EntityWithRule> = signal(undefined);
    @Input({ required: true }) set parentModule(parentModule: EntityWithRule) {
        this._parentModule.set(parentModule);
    }

    _planValidationResult: WritableSignal<PlanValidationResult> = signal(undefined);
    @Input({ required: true }) set planValidationResult(planValidationResult: PlanValidationResult) {
        this._planValidationResult.set(planValidationResult);
    }

    @Input({ required: true }) planStateObject: PlanStateObject;
    @Input({ required: true }) planData: PlanData;
    @Input({ required: true }) planRuleData: PlanRuleData;
    @Input({ required: true }) validatablePlan: ValidatablePlan;
    @Input({ required: true }) ruleDepthLevel: number;
    @Input({ required: true }) headingLevel: number;
    @Input({ required: true }) groupPrefix: string;
    @Input({ required: true }) selectionUIState: 'ACTIVE' | 'DISABLED' | 'SELECTABLE' = 'ACTIVE';

    ruleLegendId: Signal<string> = computed(this.ruleLegendIdComputation());
    ruleFocusId: Signal<string> = computed(() => `${this._rule().localId}-focus`);
    ruleValidationResults: Signal<{ [id: string]: any }> = computed(this.ruleValidationResultsComputation());

    ruleErrors: Signal<RuleError[]>;

    // Todo: Fix this workaround when the change detection is possibly fixed in Angular 18
    async ngOnChanges() {
        // https://github.com/angular/angular/issues/50320
        await 0;
        this.updateRuleErrors();
    }

    ngOnInit(): void {
        this.ruleErrors = this.ruleErrorStateService.getRuleErrors(this._rule().localId);
        this.ruleClearSignalService.getClearSignal()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((localId) => {
                if (this._rule().localId === localId) {
                    this.ruleClearSignalService.sendClearSignal(this._rule().rule.localId);
                }
            });
    }

    updateRuleErrors(): void {
        const ruleValidationResults = this.ruleValidationResults()?.result as unknown as RangeValidationResultState;
        const ruleErrors: RuleError[] = [];
        if (ruleValidationResults) {
            if (ruleValidationResults === 'LESS_REQUIRED') {
                ruleErrors.push({
                    errorId: `${this._rule().localId}-LESS_REQUIRED`,
                    errorType: 'LESS_SELECTIONS_REQUIRED',
                });
            }
        }
        const ruleDisplayName = this.resolveRuleDisplayName();
        this.ruleErrorStateService.updateRuleErrorState({
            ruleDisplayName,
            ruleFocusId: this.ruleFocusId(),
            ruleLocalId: this._rule().localId,
            errors: ruleErrors,
        });
    }

    resolveRuleDisplayName(): string {
        if (this.ruleDepthLevel === 1) {
            return this.localeService.localize(this._parentModule().name);
        }
        return `${this.transloco.translate('PLAN_EDIT.SELECTION_MODAL.RULE_BODY.SELECTION_GROUP')} ${this.groupPrefix}`;
    }

    ruleLegendIdComputation(): () => string {
        return () => (`rule-legend-${this._rule().localId}`);
    }

    ruleValidationResultsComputation(): () => { [id: string]: { [id: string]: any } } {
        return () => this.getValidationResultsForRule(
            this._planValidationResult()?.ruleValidationResults,
            this._parentModule().id,
            this._rule()?.localId);
    }

    getValidationResultsForRule(ruleValidationResults: any, parentModuleId: OtmId, ruleLocalId: OtmId): any {
        return _.get(ruleValidationResults,
                     [parentModuleId, ruleLocalId]);
    }
}
