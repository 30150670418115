<div class="course-unit-search">
  <sis-typeahead
    class="multiple-line-items"
    (click)="onTextClick($event)"
    [inputFormatter]="renderCourseUnit"
    [model]="selection"
    (modelChange)="onSelect($event)"
    [placeholder]="'SIS_COMPONENTS.COURSE_UNIT.COURSE_UNIT_SEARCH.SEARCH_PLACEHOLDER' | translate"
    [required]="required"
    [resultTemplate]="courseUnitSearchResult"
    (selectItem)="$event.item.disabled ? $event.preventDefault() : onSelect($event.item)"
    [typeahead]="doSearch.bind(this)"
  ></sis-typeahead>
  <sis-button (clicked)="removeSelection()" link="true" class="remove" *ngIf="selection" [ariaLabel]="'ARIA_LABEL.DELETE' | translate">
    <sis-icon icon="close"></sis-icon>
  </sis-button>
  <div class="row">
    <div class="col-12">
      <ul class="error-list">
        <li *ngIf="truncated">
          <span>{{'SIS_COMPONENTS.COURSE_UNIT.COURSE_UNIT_SEARCH.TOO_MANY_RESULTS' | translate}}</span>
        </li>
      </ul>
    </div>
  </div>
</div>

<ng-template #courseUnitSearchResult let-result="result">
  <div class="row" [class.disabled]="result.disabled">
    <div class="col-9">
      <div class="row">
        <span class="col-7 additional-data">{{ result.code }}</span>
        <span class="col-4 additional-data text-right"> {{result.yearRange | yearRange}}</span>
      </div>
      <div class="row">
        <div class="col-12">{{ result.name }}</div>
      </div>
    </div>
    <div class="col-3 additional-data text-right">
      <span *ngIf="result.credits">{{result.credits | creditRange}}</span>
    </div>
    <div class="col-9 additional-data" *ngIf="result.nameMatch !== result.name">
      <span> {{ result.nameMatch }}</span>
    </div>
  </div>
</ng-template>
