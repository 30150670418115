<div class="modal-header">
  <h2>{{ 'PROFILE.STUDY_RIGHT_CANCEL_DECISION.TITLE' | translate }}</h2>
  <sis-modal-close-button/>
</div>
<div class="modal-body">
  <sis-description-list [fillByColumn]="false">
    <dl>
      <ng-template #listItem>
        <dt class="sis-label">{{ 'PROFILE.STUDY_RIGHT_STATE_TITLE' | translate }}</dt>
        <dd>{{ 'PROFILE.STATE.' + values.cancellationType | translate }}</dd>
      </ng-template>
      <ng-template #listItem>
        <dt class="sis-label">{{ 'PROFILE.STUDY_RIGHT_CANCEL_DECISION.DATE' | translate }}</dt>
        <dd>{{ values.cancellationDate | localDateFormat }}</dd>
      </ng-template>
      <ng-template #listItem>
        <dt class="sis-label">{{ 'PROFILE.STUDY_RIGHT_CANCEL_DECISION.INFO' | translate }}</dt>
        <dd>{{ values.cancellationReason }}</dd>
      </ng-template>
    </dl>
  </sis-description-list>
</div>
