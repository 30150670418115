import { ChangeDetectionStrategy, Component, inject, Input, ViewEncapsulation } from '@angular/core';
import { LearningEnvironmentUrl } from 'common-typescript/types';
import _ from 'lodash';
import { LocaleService } from 'sis-common/l10n/locale.service';

@Component({
    selector: 'app-learning-environments',
    template: `
      <div class="d-flex flex-column" *ngIf="visibleLinks?.length">
        <fudis-link *ngFor="let link of visibleLinks" [externalLink]="link.url" [title]="link.name"></fudis-link>
      </div>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LearningEnvironmentsComponent {

    private readonly localeService = inject(LocaleService);

    visibleLinks: LearningEnvironmentUrl[];

    @Input({ required: true }) set learningEnvironments(learningEnvironments: LearningEnvironmentUrl[]) {
        if (learningEnvironments?.length) {
            const languagePrecedence = new Set([this.localeService.getCurrentLanguage(), ...this.localeService.getOfficialLanguages()]);
            for (const language of languagePrecedence) {
                const links = learningEnvironments.filter(link => link.language === language);
                if (links.length > 0) {
                    this.visibleLinks = _.orderBy(links, 'primary', 'desc');
                    break;
                }
            }
        }
    }
}
