import { Injectable } from '@angular/core';
import { TranslationMarkupRendererFactory } from 'ngx-transloco-markup';

import { HtmlElementTranspiler } from '../common/html-element-transpiler';

export const BOLD_START = new (class BoldStart {})();
export const BOLD_END = new (class BoldEnd {})();

@Injectable()
export class BoldTranspiler extends HtmlElementTranspiler {
    constructor(private readonly rendererFactory: TranslationMarkupRendererFactory) {
        super({
            rendererFactory,
            start: {
                search: '<b>',
                token: BOLD_START,
            },
            end: {
                search: '</b>',
                token: BOLD_END,
            },
            elementTag: 'b',
        });
    }
}
