<ng-container *transloco="let t">
  <ng-container [ngTemplateOutlet]="expirationNotification"></ng-container>
  <h3>{{ t('ATTAINMENTS.ATTAINMENT_DETAILS.HEADINGS.ATTAINMENT_DETAILS') }}</h3>
  <dl>
    <div class="form-group-mimic" role="listitem">
      <dt>{{ t('ATTAINMENTS.ATTAINMENT_DETAILS.STATE') }}</dt>
      <dd>{{ t('ATTAINMENT_STATE.' + attainment.state) }}</dd>
    </div>

    <div class="form-group-mimic" role="listitem">
      <dt>{{ t('ATTAINMENTS.ATTAINMENT_DETAILS.GRADE')}}</dt>
      <dd>{{ attainment.gradeId | grade:attainment.gradeScaleId:'ABBREVIATION' | async }} ({{ attainment.gradeId | grade:attainment.gradeScaleId:'NAME' | async }})</dd>
    </div>

    <div class="form-group-mimic" role="listitem">
      <dt>{{ t('ATTAINMENTS.ATTAINMENT_DETAILS.CREDITS_LABEL') }} ({{ t('CREDITS') }})</dt>
      <dd>
        {{ attainment.credits | decimalNumber }}<ng-container *ngIf="attainment.studyWeeks"> ({{attainment.studyWeeks}} {{ t('ATTAINMENTS.ATTAINMENT_DETAILS.STUDY_WEEKS_ABBR') }}) </ng-container>
      </dd>
    </div>

    <ng-container [ngTemplateOutlet]="rdiCredits"></ng-container>

    <div class="form-group-mimic" role="listitem">
      <dt>{{ t('ATTAINMENTS.ATTAINMENT_DETAILS.ATTAINMENT_DATE')}}</dt>
      <dd>{{ attainment.attainmentDate | localDateFormat }}</dd>
    </div>

    <div class="form-group-mimic" role="listitem">
      <dt>{{ t('ATTAINMENTS.ATTAINMENT_DETAILS.EXPIRY_DATE') }}</dt>
      <dd *ngIf="attainment.expiryDate">{{ attainment.expiryDate | localDateFormat }}</dd>
      <dd *ngIf="!attainment.expiryDate">{{ t('ATTAINMENTS.ATTAINMENT_DETAILS.NO_EXPIRY_DATE') }}</dd>
    </div>

    <div class="form-group-mimic" role="listitem">
      <dt>{{ t('ATTAINMENTS.ATTAINMENT_DETAILS.COMPLETION_LANGUAGE') }}</dt>
      <dd>{{ attainment.attainmentLanguageUrn | codeLocalizedName | async }}</dd>
    </div>

    <ng-container [ngTemplateOutlet]="studyLevel"></ng-container>

    <div [attainment]="attainment" sis-grade-average-description class="form-group-mimic" role="listitem"></div>

    <div class="form-group-mimic" role="listitem">
      <dt>{{ t('ATTAINMENTS.ATTAINMENT_DETAILS.ACCEPTOR_PERSONS') }} </dt>
      <ng-container *ngIf="attainment.acceptorPersons?.length > 0 else emptyField">
        <ng-container  *ngFor="let acceptor of attainment.acceptorPersons">
          <dd *ngIf="acceptor.personId">{{ acceptor.personId | publicPerson | async | personFullNameReversed }}</dd>
          <dd *ngIf="!acceptor.personId">{{ acceptor.text | localizedString}}</dd>
        </ng-container>
      </ng-container>
    </div>

    <div class="form-group-mimic" role="listitem">
      <dt>{{ t('ATTAINMENTS.ATTAINMENT_DETAILS.STUDY_RIGHT_ID') }}</dt>
      <ng-container *ngIf="attainment.studyRightId | studyRightName | async | localizedString as studyRightName; else emptyField">
        <dd>{{ studyRightName }}</dd>
      </ng-container>
    </div>

    <ng-container [ngTemplateOutlet]="curriculumPeriods"></ng-container>

    <div sis-additional-info-details class="form-group-mimic full-width" role="listitem" [data]="attainment.additionalInfo"></div>

    <ng-container [ngTemplateOutlet]="acceptorOrganisations"></ng-container>

    <div class="form-group-mimic" role="listitem">
      <dt>{{ t('ATTAINMENTS.ATTAINMENT_DETAILS.RESPONSIBLE_ORGANISATIONS') }}</dt>
      <dd>
        <sis-organisation-role-share
          *ngIf="attainment.organisations|hasOrganisationRole:'urn:code:organisation-role:responsible-organisation'; else emptyField"
          [hideLabel]="true"
          [organisations]="attainment.organisations"
          [organisationRoleUrn]="'urn:code:organisation-role:responsible-organisation'">
        </sis-organisation-role-share>
      </dd>
    </div>

    <div class="form-group-mimic" role="listitem">
      <dt>{{ t('ATTAINMENTS.ATTAINMENT_DETAILS.COORDINATING_ORGANISATIONS') }}</dt>
      <dd>
        <sis-organisation-role-share
          *ngIf="attainment.organisations|hasOrganisationRole:'urn:code:organisation-role:coordinating-organisation'; else emptyField"
          [hideLabel]="true"
          [organisations]="attainment.organisations"
          [organisationRoleUrn]="'urn:code:organisation-role:coordinating-organisation'">
        </sis-organisation-role-share>
      </dd>
    </div>

    <div *ngIf="attainment.organisations|hasOrganisationRole:'urn:code:organisation-role:source-organisation'"
      class="form-group-mimic full-width" role="listitem">
      <dt data-cy="source-organisations">{{ t('ATTAINMENTS.ATTAINMENT_DETAILS.SOURCE_ORGANISATIONS') }}</dt>
        <dd>
          <sis-organisation-role-share
            [hideLabel]="true"
            [organisations]="attainment.organisations"
            [organisationRoleUrn]="'urn:code:organisation-role:source-organisation'">
          </sis-organisation-role-share>
        </dd>
    </div>

    <div class="form-group-mimic" role="listitem">
      <dt>{{ t('ATTAINMENTS.ATTAINMENT_DETAILS.STUDY_FIELD') }}</dt>
      <ng-container *ngIf="attainment.studyFieldUrn; else emptyField">
        <dd>{{ attainment.studyFieldUrn | codeLocalizedName | async }}</dd>
      </ng-container>
    </div>

    <ng-container [ngTemplateOutlet]="educationClassification"></ng-container>

    <ng-container [ngTemplateOutlet]="registrationDetails"></ng-container>

    <ng-container [ngTemplateOutlet]="verifierAndRegistrationDate"></ng-container>

    <ng-container [ngTemplateOutlet]="administrativeNote"></ng-container>

  </dl>

  <ng-container [ngTemplateOutlet]="assessmentDetails"></ng-container>

  <ng-container [ngTemplateOutlet]="thesisInformation"></ng-container>

  <ng-container [ngTemplateOutlet]="cooperationNetworkStatus"></ng-container>

  <ng-container [ngTemplateOutlet]="creditTransferInformation"></ng-container>

  <sis-secondary-attainment-details *ngIf="secondaryAttainments?.length > 0 && attainment.primary === true"
    [secondaryAttainments]="secondaryAttainments">
  </sis-secondary-attainment-details>
  <ng-template #emptyField>
    <dd> - </dd>
  </ng-template>
</ng-container>
