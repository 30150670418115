import angular from 'angular';
import moment from 'moment';
import _ from 'lodash';
import { studyEventSummaryServiceModule } from 'sis-components/service/studyEventSummary.service';
import studyEventSummaryTpl from './studyEventSummary.tpl.html';
export const studyEventSummaryModule = 'student.calendar.directive.studyEventSummary';
(function () {
  studyEventSummaryController.$inject = ["studyEventSummaryService"];
  angular.module(studyEventSummaryModule, ['pascalprecht.translate', studyEventSummaryServiceModule]).component('studyEventSummary', {
    template: studyEventSummaryTpl,
    bindings: {
      studySubGroup: '<',
      firstEventDateByStudySubGroupId: '<',
      onViewInCalendar: '<?',
      studyEvent: '<'
    },
    controller: studyEventSummaryController
  });

  /**
   * @ngInject
   */
  function studyEventSummaryController(studyEventSummaryService) {
    const $ctrl = this;
    $ctrl.$onInit = () => {
      $ctrl.weekDayOrInterval = studyEventSummaryService.getWeekDayOrInterval($ctrl.studyEvent);
      $ctrl.timeRange = studyEventSummaryService.getTimeRange($ctrl.studyEvent);
      $ctrl.dateRange = studyEventSummaryService.getDateRange($ctrl.studyEvent);
      $ctrl.canShowCalendarButton = !!($ctrl.onViewInCalendar && _.get($ctrl.studyEvent, 'startTime'));
      $ctrl.setRelevantOverrides();
    };

    // $ctrl.canShowCalendarButton checks that 1) date of first event is available for
    // studySubGroup and 2) callback function onViewInCalendar is available. Here the
    // onViewInCalendar function is called with the first event date to scroll the calendar grid to the
    // view that contains the first event date.

    $ctrl.viewInCalendar = () => {
      const date = moment($ctrl.studyEvent.startTime).format('YYYY-MM-DD');
      $ctrl.onViewInCalendar({
        date
      });
    };
    $ctrl.setRelevantOverrides = () => {
      $ctrl.relevantOverrides = _.filter($ctrl.studyEvent.overrides, override => !_.isEmpty(override.irregularLocationIds));
    };
    $ctrl.allEventsExcludedOrCancelled = () => _.every($ctrl.studyEvent.events, event => event.cancelled || event.excluded);
  }
})();