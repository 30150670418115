import { Component, Inject, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import {
    CompletionMethod,
    CompletionMethodRepeat,
    OtmId,
    StudyPeriodTemplate,
    StudyPeriodTemplateLocator,
    StudyYearTemplate,
} from 'common-typescript/types';
import _ from 'lodash';
import moment from 'moment';
import {
    ComponentDowngradeMappings,
    DowngradedComponent,
    StaticMembers,
} from 'sis-common/types/angular-hybrid';

import {
    COMMON_STUDY_PERIOD_SERVICE,
} from '../../../ajs-upgraded-modules';
import { UniversityService } from '../../../service/university.service';

export interface DisplayableStudyPeriodGroup {
    startPeriod: StudyPeriodTemplate;
    endPeriod: StudyPeriodTemplate;
}

export interface StudyPeriodTemplateLocatorObject {
    organisationId: OtmId;
    firstYear: number;
    termIndex: number;
    periodIndex: number;
}

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-completion-method-repeats',
    templateUrl: './completion-method-repeats.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class CompletionMethodRepeatsComponent implements OnChanges {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.courseUnit.completionMethods.completionMethodRepeats',
        directiveName: 'sisCompletionMethodRepeats',
    };

    universityOrgId: OtmId;
    completionMethodRepeats: CompletionMethodRepeat[];
    displayableRepeatGroups: DisplayableStudyPeriodGroup[][];

    @Input() completionMethod: CompletionMethod;
    @Input() language: string;

    constructor(
        private universityService: UniversityService,
        @Inject(COMMON_STUDY_PERIOD_SERVICE) private commonStudyPeriodService: any,
    ) {}

    ngOnChanges() {
        this.universityOrgId = this.universityService.getCurrentUniversityOrgId();
        this.completionMethodRepeats = _.filter(this.completionMethod.repeats, repeat =>
            this.getRepeatOrganisation(repeat) === this.universityOrgId);
        if (_.isEmpty(this.completionMethodRepeats)) {
            this.displayableRepeatGroups = [];
        } else {
            this.commonStudyPeriodService.getStudyYearTemplates(this.universityOrgId).then((studyYearTemplates: StudyYearTemplate[]) => {
                this.displayableRepeatGroups = _.map(this.completionMethodRepeats, (completionMethodRepeat) => this.getRepeatPossibilityObject(completionMethodRepeat, studyYearTemplates));
            });
        }
    }

    getRepeatOrganisation(completionMethodRepeat: CompletionMethodRepeat) {
        const repeatOrganisations = _.map(completionMethodRepeat.repeatPossibility, (studyPeriodLocator) => {
            const components = _.split(studyPeriodLocator as string, '/');
            return _.first(components);
        });
        const uniqueOrganisations = _.uniq(repeatOrganisations);
        if (_.size(uniqueOrganisations) === 1) {
            return _.first(uniqueOrganisations);
        }
        return null;
    }

    getRepeatPossibilityObject(completionMethodRepeat: CompletionMethodRepeat, studyYearTemplates: StudyYearTemplate[]) {
        const completionMethodRepeatStartMoment = moment(`${completionMethodRepeat.studyYearRange.start}-08-01`);
        const matchingStudyYearTemplate = _.find(studyYearTemplates, (studyYearTemplate) => completionMethodRepeatStartMoment.isBetween(
            studyYearTemplate.valid.startDate,
            studyYearTemplate.valid.endDate,
            'days',
            '[)'));
        const repeatPossibilityObjects = _.map(completionMethodRepeat.repeatPossibility, rp =>
            this.getStudyPeriodTemplateLocatorObject(rp));
        const sortedRepeatPossibilityObjects = _.sortBy(repeatPossibilityObjects, 'firstYear', 'termIndex', 'periodIndex');
        const repeatPossibilitiesWithStudyPeriods = _.map(sortedRepeatPossibilityObjects, rpo =>
            ({
                repeatPossibilityObject: rpo,
                studyPeriod: this.getStudyPeriodForRepeatPossibilityObject(rpo, matchingStudyYearTemplate),
            }),
        );
        const displayableStudyPeriodGroups: DisplayableStudyPeriodGroup[] = [];
        _.forEach(repeatPossibilitiesWithStudyPeriods, (repeatPossibilityWithStudyPeriod) => {
            const studyPeriod = repeatPossibilityWithStudyPeriod.studyPeriod;
            if (
                _.size(displayableStudyPeriodGroups) === 0 ||
                _.last(displayableStudyPeriodGroups).endPeriod.defaultValid.endDate !== studyPeriod.defaultValid.startDate
            ) {
                displayableStudyPeriodGroups.push({
                    startPeriod: studyPeriod,
                    endPeriod: studyPeriod,
                });
            } else {
                _.set(_.last(displayableStudyPeriodGroups), 'endPeriod', studyPeriod);
            }
        });
        return displayableStudyPeriodGroups;

    }

    getStudyPeriodTemplateLocatorObject(studyPeriodTemplateLocator: StudyPeriodTemplateLocator): StudyPeriodTemplateLocatorObject {
        const locatorAsString = studyPeriodTemplateLocator as string;
        const componentArray = _.split(locatorAsString, '/');
        return {
            organisationId: componentArray[0],
            firstYear: +componentArray[1],
            termIndex: +componentArray[2],
            periodIndex: +componentArray[3],
        };
    }

    getStudyPeriodForRepeatPossibilityObject(repeatPossibilityObject: StudyPeriodTemplateLocatorObject, studyYearTemplate: StudyYearTemplate) {
        const studyTerm = _.get(studyYearTemplate.studyTerms, repeatPossibilityObject.termIndex);
        const studyPeriod = _.get(studyTerm.studyPeriods, repeatPossibilityObject.periodIndex);
        return studyPeriod;
    }

}
