import { Component, inject, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FudisDialogService } from '@funidata/ngx-fudis';
import { TranslocoService } from '@ngneat/transloco';
import { OtmId, Workflow } from 'common-typescript/types';
import { Observable, Subject, take, tap } from 'rxjs';
import { exhaustMap } from 'rxjs/operators';
import { AlertsService, AlertType } from 'sis-components/alerts/alerts-ng.service';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { WorkflowEntityService } from 'sis-components/service/workflow-entity.service';

export interface CancelWorkflowValues {
    workflowType: string,
    workflowId: OtmId,
    // true if there is additional descriptive text that should be shown on the confirmation modal
    hasDescription?: boolean,
    successCallback?: () => any,
}

export function workflowCancellationDialogOpener(): (data: CancelWorkflowValues) => MatDialogRef<CancelWorkflowDialogComponent> {
    const fudisDialogService = inject(FudisDialogService);
    return data => fudisDialogService.open(CancelWorkflowDialogComponent, { data });
}

@Component({
    selector: 'app-cancel-workflow-dialog',
    templateUrl: './cancel-workflow-dialog.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CancelWorkflowDialogComponent implements OnInit, OnDestroy {

    cancel$: Subject<void> = new Subject<void>();

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: CancelWorkflowValues,
        private dialogService: FudisDialogService,
        private workflowEntityService: WorkflowEntityService,
        private alertsService: AlertsService,
        private appErrorHandler: AppErrorHandler,
        private transloco: TranslocoService,
    ) {
    }

    ngOnInit(): void {
        this.cancel$.pipe(exhaustMap(() => this.confirmCancel())).subscribe();
    }

    ngOnDestroy(): void {
        this.cancel$.complete();
    }

    confirmCancel(): Observable<Workflow> {
        return this.workflowEntityService.cancel(this.data.workflowId)
            .pipe(
                take(1),
                tap(() => {
                    this.closeSurveyDialog();
                    this.alertsService.addTemporaryAlert({
                        message: this.transloco.translate('PROFILE.APPLICATIONS.APPLICATION_CANCELLED_ALERT'),
                        type: AlertType.SUCCESS,
                    });
                    if (!!this.data.successCallback) {
                        this.data.successCallback();
                    }
                }),
                this.appErrorHandler.defaultErrorHandler(),
            );
    }

    closeSurveyDialog() {
        this.dialogService.close();
    }
}
