import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { StudyTerm } from 'common-typescript/types';
import moment from 'moment/moment';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { AttainedCreditsByStudyTerm } from '../../service/study-term.service';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-attained-credits-by-study-term',
    templateUrl: './attained-credits-by-study-term.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class AttainedCreditsByStudyTermComponent implements OnInit {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.lib.applications.attained-credits-by-study-term',
        directiveName: 'sisAttainedCreditsByStudyTerm',
    };

    @Input() attainedCredits: AttainedCreditsByStudyTerm[];

    totalCredits: number;

    ngOnInit(): void {
        this.totalCredits = this.attainedCredits
            .map((attainedCreditsByStudyTerm: AttainedCreditsByStudyTerm) => attainedCreditsByStudyTerm.credits)
            .reduce((result: number, credits: number) => result + (credits || 0), 0);
    }

    getStudyTermYear(studyTerm: StudyTerm) {
        return studyTerm.valid.startDate ? moment(studyTerm.valid.startDate).year() : '';
    }

}
