<ng-container *transloco="let t">
  <dt>
  <span>{{ t('ATTAINMENTS.ATTAINMENT_DETAILS.GRADE_AVERAGE') }}</span>
  <sis-popover (shown)="isOpen()" (hidden)="isHidden()" *ngIf="showInfo">
    <button type="button"
            class="non-button sis-popover-trigger-content"
            [attr.aria-expanded]="popoverShown"
            [attr.aria-label]="t('ARIA_LABEL.SHOW_DETAILS')">
      <sis-icon [icon]="'info-circle'"></sis-icon>
    </button>
    <div class="sis-popover-popover-content">
      <p class="guidance">{{ t('ATTAINMENTS.ATTAINMENT_DETAILS.AVERAGE_NOTIFICATION', { scaleName: _attainment.gradeAverage.gradeScaleId | gradeScale | async | entityAbbreviation | localizedString }) }}</p>
      <ng-content></ng-content>
    </div>
  </sis-popover>
  </dt>
  <dd>{{ (_attainment.gradeAverage?.value | decimalNumber) || '-' }}</dd>
</ng-container>
