import { Component, Inject, Input, OnChanges, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import {
    CreditRange,
    Module,
    ModuleAttainment,
} from 'common-typescript/types';
import _ from 'lodash';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { ModuleStateObject, PlanData, PlanStateObject } from '../../service/plan-state.service';
import { PLAN_ACTIONS_SERVICE_INJECTION_TOKEN, PlanActionsService } from '../plan-actions-service/plan-actions.service';
import { UiStateObject } from '../plan-edit/plan-edit.component';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-plan-module',
    templateUrl: './plan-module.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class PlanModuleComponent implements OnInit, OnChanges {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.plan.planModule',
        directiveName: 'sisPlanModule',
    };

    isOpen: boolean;
    highlighted: boolean;
    showModuleContentApprovalButtonVisible: boolean;
    moduleStateObject: ModuleStateObject;
    modulesById: { [id: string]: Module };
    targetCredits: CreditRange;
    attainment: ModuleAttainment;
    classes: string;

    @Input() module: Module;
    @Input() planValidationResult: any;
    @Input() planStateObject: PlanStateObject;
    @Input() planData: PlanData;
    @Input() isImplicit: boolean;
    @Input() parentModule?: Module;
    @Input() uiStateObject: UiStateObject;
    @Input() level: number;

    constructor(
        @Optional() @Inject(PLAN_ACTIONS_SERVICE_INJECTION_TOKEN) private planActionsService: PlanActionsService,
    ) {
    }

    ngOnChanges() {
        this.moduleStateObject = _.get(this.planStateObject.modules, this.module.id);
        if (this.module.type !== 'GroupingModule') {
            this.targetCredits = _.get(this.module, 'targetCredits');
        }
        if (this.moduleStateObject.attainmentId) {
            this.attainment = this.planData.attainmentsById[this.moduleStateObject.attainmentId] as ModuleAttainment;
        }
        this.classes = this.getClasses();
    }

    ngOnInit() {
        this.isOpen = true;
        this.highlighted = false;
        this.showModuleContentApprovalButtonVisible = true;
    }

    getClasses(): string {
        const classes = [
            `tree-list-item-level-${this.level}`,
        ];
        if (this.level > 1) {
            classes.push('tree-list-item', 'clearfix');
        }
        return classes.join(' ');
    }

    handleModuleHeaderClick() {
        if (!this.moduleStateObject.attainmentId) {
            this.planActionsService?.openModule?.(this.module);
        }
    }

    openModuleSelections() {

    }

    showModuleContentApproval() {

    }

}
