import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { OtmId } from 'common-typescript/types';
import { EMPTY, Observable, ReplaySubject, switchMap } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { OrganisationSettingsService } from 'sis-components/service/organisation-settings.service';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'app-graduation-instructions-url',
    templateUrl: './graduation-instructions-url.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GraduationInstructionsUrlComponent implements OnInit {
    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'student.common.graduation.graduationInstructions.downgraded',
        directiveName: 'appGraduationInstructionsUrl',
    };

    @Input() set organisationId(value: OtmId) {
        this.organisationIdInputSubject$.next(value);
    }

    organisationIdInputSubject$ = new ReplaySubject<OtmId>(1);

    localizedGraduationInstructionsUrl$: Observable<string>;

    constructor(private organisationSettingsService: OrganisationSettingsService,
                private localeService: LocaleService,
                private appErrorHandler: AppErrorHandler) { }

    ngOnInit(): void {
        this.localizedGraduationInstructionsUrl$ =
            this.createLocalizedGraduationInstructionsUrlObservable(this.organisationIdInputSubject$);
    }

    createLocalizedGraduationInstructionsUrlObservable(organisationId$: Observable<OtmId>): Observable<string> {
        return organisationId$.pipe(
            switchMap((organisationId) => {
                if (organisationId) {
                    return this.organisationSettingsService.getInheritedSettingsByOrganisationId(organisationId)
                        .pipe(
                            take(1),
                            this.appErrorHandler.defaultErrorHandler(),
                        );
                }
                return EMPTY;
            }),
            map((organisationSettings) =>
                this.localeService.localize(organisationSettings.graduationInstructionsUrl)),
        );
    }

}
