import { Injectable } from '@angular/core';

import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from '../types/angular-hybrid';

@Injectable({
    providedIn: 'root',
})
@StaticMembers<DowngradedService>()
export class UuidService {

    static downgrade: ServiceDowngradeMappings = {
        moduleName: 'sis-common.uuid.uuidService',
        serviceName: 'uuidService',
    };

    /* eslint-disable no-bitwise */
    randomUUID() {
        let d = new Date().getTime();
        if (window.performance && typeof window.performance.now === 'function') {
            d += performance.now(); // use high-precision timer if available
        }
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
            const r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }

    randomOtmId() {
        return `otm-${this.randomUUID()}`;
    }
}
