<ng-container *transloco="let t">
  <div class="container sis-pt-md">
    <sis-breadcrumbs></sis-breadcrumbs>
    <sis-page-title [title]="t('STUDENT_MESSAGES.MESSAGE_CONVERSATIONS_LIST_VIEW.TITLE')" [showCrumbs]="true"/>
    <div class="row">
      <div class="col-12">
        <div class="d-flex flex-wrap sis-pb-sm">
          <sis-checkbox
            class="sis-pr-lg"
            [checked]="isUnreadMessagesSelected"
            (checkedChange)="filterUnreadMessages($event)"
            [label]="t('STUDENT_MESSAGES.MESSAGE_CONVERSATIONS_LIST_VIEW.CHECKBOX_UNREAD_FILTER')">
          </sis-checkbox>
          <sis-checkbox
            [checked]="isArchivedMessagesSelected"
            (checkedChange)="filterArchivedMessages($event)"
            [label]="t('STUDENT_MESSAGES.MESSAGE_CONVERSATIONS_LIST_VIEW.CHECKBOX_ARCHIVED_FILTER')">
          </sis-checkbox>
        </div>
        <p class="guidance" *ngIf="result && result.total > 0">
          <span *ngIf="result && result.total === 1">
            {{t('STUDENT_MESSAGES.MESSAGE_CONVERSATIONS_LIST_VIEW.NUMBER_OF_CONVERSATIONS_TOTAL_ONE')}}&nbsp;
          </span>
          <span *ngIf="result && result.total > 1">
            {{t('STUDENT_MESSAGES.MESSAGE_CONVERSATIONS_LIST_VIEW.NUMBER_OF_CONVERSATIONS_TOTAL', {conversations: result.total})}}&nbsp;
          </span>
          <span *ngIf="unreadMessagesCount === 1">
            {{t('STUDENT_MESSAGES.MESSAGE_CONVERSATIONS_LIST_VIEW.NUMBER_OF_CONVERSATIONS_UNREAD_ONE')}}
          </span>
          <span *ngIf="unreadMessagesCount > 1">
            {{t('STUDENT_MESSAGES.MESSAGE_CONVERSATIONS_LIST_VIEW.NUMBER_OF_CONVERSATIONS_UNREAD_TOTAL', {unreadMessages: unreadMessagesCount})}}
          </span>
        </p>
      </div>
    </div>
    <div class="row">
      <div class="messages-container">
        <div class="results-header row g-0">
          <div class="action-container">
            <div class="checkbox-container">
              <sis-checkbox
                [checked]="allSelected"
                (checkedChange)="toggleSelectAll()"
                [attr.aria-label]="t('ARIA_LABEL.SELECT_ALL_MESSAGES')">
              </sis-checkbox>
            </div>
            <sis-menu-button color="primary" [options]="massActionOptions" [placeLeft]="true"></sis-menu-button>
          </div>
        </div>
        <div class="results-container">
          <sis-message-conversation-list-item [hidden]="!result || result.total === 0"
                                              [messageConversations]="conversations$ | async"
                                              (moveToMessageConversationView)="moveToMessageConversation($event)"
                                              [selectedMessageConversationIds]="selectedMessageConversationIds"
                                              (toggleConversationSelection)="toggleConversationSelection($event)">
          </sis-message-conversation-list-item>
          <sis-pagination *ngIf="totalItems > itemsPerPage"
                          [currentPage]="currentPage"
                          (currentPageChange)="onPageChange($event)"
                          [totalItems]="totalItems"
                          [itemsPerPage]="itemsPerPage">
          </sis-pagination>
        </div>
      </div>
    </div>
  </div>
</ng-container>
