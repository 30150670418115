import { localeServiceModule } from 'sis-common/l10n/localeService';
import { jsDataCacheHelperModule } from 'sis-components/service/jsDataCacheHelper.service';
import { courseUnitJSDataModelModule } from 'sis-components/model/courseUnit.model';
'use strict';
(function () {
  StudentSearchParameters.$inject = ["localeService", "previewMode"];
  courseUnitService.$inject = ["$http", "SEARCH_URL", "courseUnitModel", "findByIdsHelper", "$q", "curriculumPeriodService", "errorService", "previewMode", "commonOrganisationService", "organisationRoleModel", "gradeScaleJSDataModel", "$log", "urnService", "courseUnitTypeJsDataModel", "organisationSelectorService", "courseUnitJSDataModel", "jsDataCacheHelper"];
  angular.module('student.common.service.courseUnitService', [localeServiceModule, 'sis-common.errorhandler', 'sis-common.organisationSelector.organisationSelectorService', 'sis-components.service.organisationService', 'sis-components.model.organisationRole', 'sis-components.model.gradeScale', 'sis-components.model.courseUnitType', 'student.common.model.courseUnit', 'student.common.utils.findByIdsHelper', 'student.common.utils.previewMode', 'student.common.service.curriculumPeriodService', 'student.common.service.urnService', courseUnitJSDataModelModule, jsDataCacheHelperModule]).factory('courseUnitService', courseUnitService).factory('StudentSearchParameters', StudentSearchParameters).constant('SEARCH_URL', {
    AUTHENTICATED: '/kori/api/authenticated/course-unit-search',
    UNAUTHENTICATED: '/kori/api/course-unit-search'
  });

  /**
   * @ngInject
   */
  function courseUnitService($http, SEARCH_URL, courseUnitModel, findByIdsHelper, $q, curriculumPeriodService, errorService, previewMode, commonOrganisationService, organisationRoleModel, gradeScaleJSDataModel, $log, urnService, courseUnitTypeJsDataModel, organisationSelectorService, courseUnitJSDataModel, jsDataCacheHelper) {
    const baseUri = '/kori/api/course-units';
    const authenticatedUri = '/kori/api/authenticated/course-units';
    var api = {
      get: function (id) {
        return api.findById(id);
      },
      findById: function (id) {
        return api.findByIds([id]).then(list => list[0]);
      },
      findByIds: ids => jsDataCacheHelper.findByIds(courseUnitJSDataModel, ids).then(data => toCourseUnits(data)),
      findByGroupId: function (groupId, courseUnitCache, rootOrgId, documentStates) {
        const uri = previewMode.isPreviewMode() ? authenticatedUri : baseUri;
        if (!documentStates && previewMode.isPreviewMode()) {
          documentStates = previewMode.documentStatesForPreview();
        }
        const params = {
          groupId,
          rootOrg: rootOrgId,
          documentState: documentStates
        };
        return courseUnitJSDataModel.findAll(params, {
          endpoint: uri
        }).then(result => toCourseUnits(result, courseUnitCache));
      },
      findByAssessmentIds: function (ids) {
        return findByIdsHelper(baseUri, ids, 'assessmentItemId').then(data => toCourseUnits(data));
      },
      search: function (searchParameters) {
        const params = getRequestParameters(searchParameters);
        const uri = previewMode.isPreviewMode() ? SEARCH_URL.AUTHENTICATED : SEARCH_URL.UNAUTHENTICATED;
        return $http.get(uri, {
          params
        }).then(result => {
          organisationSelectorService.populate(result.data.searchResults, 'organisations');
          return result.data;
        });
      }
    };
    function toCourseUnits(list, courseUnitCache) {
      return _.map(list, data => {
        if (courseUnitCache && courseUnitCache[data.id]) {
          return courseUnitCache[data.id];
        }
        return courseUnitModel.newCourseUnit(data);
      });
    }
    function getRequestParameters(searchParameters) {
      if (searchParameters.toRequestParameters) {
        return searchParameters.toRequestParameters();
      }
      const searchConfig = searchParameters.build();
      if (searchConfig.params) {
        searchConfig.params.validity = 'ONGOING_AND_FUTURE';
        searchConfig.params.ignoreValidityPeriod = undefined;
        searchConfig.params.documentState = undefined;
      } else {
        $log.warn('Failed to set params to searchConfig', searchConfig);
      }
      return searchConfig.params;
    }
    return api;
  }
  function StudentSearchParameters(localeService, previewMode) {
    return function () {
      this.fullTextQuery = undefined;
      this.uiLang = undefined;
      this.start = undefined;
      this.limit = undefined;
      this.curriculumPeriodIds = undefined;
      this.universityOrgId = undefined;
      this.organisationIds = undefined;
      this.organisationRootIds = undefined;
      this.attainmentLangs = undefined;
      this.assessmentItemType = undefined;
      this.validity = undefined;
      this.studyPeriods = undefined;
      this.sort = undefined;
      this.cooperationNetworkIds = undefined;
      this.cooperationNetworkSearchType = undefined;
      this.validityInCooperationNetwork = undefined;
      this.toRequestParameters = function () {
        let activityPeriods;
        if (_.isArray(this.studyPeriods)) {
          activityPeriods = _.map(this.studyPeriods, studyPeriod => `${studyPeriod.valid.startDate},${studyPeriod.valid.endDate}`);
        } else if (this.studyPeriods) {
          activityPeriods = `${this.studyPeriods.valid.startDate},${this.studyPeriods.valid.endDate}`;
        }
        let documentStates = [];
        if (previewMode.isPreviewMode()) {
          documentStates = previewMode.documentStatesForPreview();
        }
        const params = {
          fullTextQuery: this.fullTextQuery,
          uiLang: this.uiLang || localeService.getCurrentLocale(),
          universityOrgId: this.universityOrgId,
          orgId: this.organisationIds,
          orgRootId: this.organisationRootIds,
          attainmentLanguageUrn: this.attainmentLangs,
          assessmentItemType: this.assessmentItemType,
          start: this.start || 0,
          limit: this.limit || 100,
          validity: this.validity,
          sort: this.sort,
          documentState: documentStates,
          activityPeriod: activityPeriods,
          curriculumPeriodId: this.curriculumPeriodIds,
          cooperationNetworkId: this.cooperationNetworkIds,
          cooperationNetworkSearchType: this.cooperationNetworkSearchType,
          validityInCooperationNetwork: this.validityInCooperationNetwork
        };
        _.forEach(_.keys(params), key => {
          params[key] = ignoreNullAndEmpty(params[key]);
        });
        return params;
      };
      function ignoreNullAndEmpty(value) {
        if (_.isArray(value) && _.isEmpty(value)) {
          return undefined;
        }
        if (value === null || value === '') {
          return undefined;
        }
        return value;
      }
    };
  }
})();