<div class="row">
  <div class="col-md-12">
    <label *ngIf="label">{{ label }}</label>
    <ng-content></ng-content>
    <sis-language-pills [invalidLocales]="invalidLocales"
                        [(selectedLanguage)]="selectedLocale"
                        (selectedLanguageChange)="selectLocale($event)"></sis-language-pills>
    <!-- eslint-disable-next-line custom-rules/no-inner-html-property-binding -->
    <div class="form-control-static" [innerHtml]="value?.[selectedLocale] | markupString:selectedLocale"></div>
  </div>
</div>
