import editModeTpl from './editMode.tpl.html';
"use strict";
(function () {
  angular.module('sis-components.edit.editMode', ['sis-components.whitelayer']).directive('editMode', ["$rootScope", function ($rootScope) {
    return {
      restrict: 'E',
      template: editModeTpl,
      link: function ($scope) {
        $scope.isEditMode = false;
        $rootScope.$on('setEditMode', function (event, data) {
          $scope.isEditMode = data === true || data === 'true';
        });
      }
    };
  }]);
})();