<ng-container *ngIf="isAttainmentTreeGroupNode(attainmentNode)">
  <sis-expandable
          [title]="attainmentNode.name | localizedString"
          [variant]="'naked-dotted'"
          [contentPadding]="'none'"
          [level]="expandableLevel"
          [closed]="isClosed$ | async"
          (closedChange)="setClosed($event)">
    <ng-template sisExpandableContent>
      <ng-container *ngFor="let childNode of attainmentNode.childNodes">
        <app-student-attainment-node-wrapper
                [attainmentNode]="childNode"
                [expandableLevel]="expandableLevel + 1"/>
      </ng-container>
    </ng-template>
  </sis-expandable>
</ng-container>

<ng-container *ngIf="isAttainmentTreeAttainmentNode(attainmentNode)">
  <sis-attainment-structure-node
          [attainment]="attainmentNode.attainment"
          [code]="attainmentNode.code"
          [name]="attainmentNode.name"
          [grade]="attainmentNode.grade"
          [hasValidParentAttainment]="attainmentNode.hasValidParentAttainment"
          [expandableLevel]="expandableLevel"
          [closed]="isClosed$ | async"
          (openDetailsModal)="openAttainmentDetailsModal(attainmentNode.attainment)"
          (closedChange)="setClosed($event)"
  >
    <ng-container *ngFor="let childNode of attainmentNode.childNodes">
      <app-student-attainment-node-wrapper
              [attainmentNode]="childNode"
              [expandableLevel]="expandableLevel + 1"/>
    </ng-container>
  </sis-attainment-structure-node>
</ng-container>
