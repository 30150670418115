'use strict';

export const onEscPressModule = 'sis-components.event.onEscPress';
(function () {
  onEscPressDirective.$inject = ["$document", "$timeout"];
  angular.module(onEscPressModule, []).directive('onEscPress', onEscPressDirective);

  /**
   * Workaround for OTM-17503 (two simultaneous DOM events that both invoke scope.$apply() cause an
   * exception). Invokes the given callback function when Esc is pressed. Uses Angular's $timeout
   * service to prevent conflicts with other simultaneous event handlers.
   *
   * Primarily meant for closing modals that have a search field (i.e. angular-ui typeahead plugin).
   *
   * @ngInject
   */
  function onEscPressDirective($document, $timeout) {
    return {
      restrict: 'A',
      scope: {
        onEscPress: '&'
      },
      link: function (scope) {
        function onEscPress(event) {
          if (event.which === 27) {
            event.preventDefault();
            // Using $timeout() without explicit wait time executes the
            // given function at the end of the current digest cycle
            $timeout(scope.onEscPress);
          }
        }
        if (_.isFunction(scope.onEscPress)) {
          $document.on('keydown', onEscPress);
          scope.$on('$destroy', function () {
            $document.off('keydown', onEscPress);
          });
        }
      }
    };
  }
})();