import angular from 'angular';
export const courseUnitRealisationTypeModelModule = 'sis-components.model.courseUnitRealisationType';
(function () {
  initCourseUnitRealisationTypeModel.$inject = ["courseUnitRealisationTypeModel"];
  courseUnitRealisationTypeModel.$inject = ["DS", "$http", "$log"];
  angular.module(courseUnitRealisationTypeModelModule, ['js-data']).factory('courseUnitRealisationTypeModel', courseUnitRealisationTypeModel).run(initCourseUnitRealisationTypeModel);

  /**
   * @ngInject
   */
  function courseUnitRealisationTypeModel(DS, $http, $log) {
    var model = DS.defineResource({
      idAttribute: 'urn',
      name: 'courseUnitRealisationType',
      endpoint: '/kori/api/cached/codebooks/urn:code:course-unit-realisation-type',
      watchChanges: false
    });
    model.lazyGetAll = _.once(function () {
      return $http.get(model.endpoint).then(function (response) {
        model.inject(_.values(response.data));
        return model.getAll();
      }).catch(function (error) {
        $log.error(error);
      });
    });
    return model;
  }

  /**
   * @ngInject
   */
  function initCourseUnitRealisationTypeModel(courseUnitRealisationTypeModel) {
    courseUnitRealisationTypeModel.lazyGetAll();
  }
})();