import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

import { RuleError } from '../rule-error-state.service';

@Component({
    selector: 'sis-plan-structure-rule-error-list',
    templateUrl: './plan-structure-rule-error-list.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanStructureRuleErrorListComponent {

    @Input({ required: true }) ruleErrors: RuleError[] = [];

}
