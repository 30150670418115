suggestAddMessageService.$inject = ["modalService", "$q"];
import angular from 'angular';
import { ModalService } from 'sis-common/modal/modal.service.ts';
import { SuggestAddMessageModalComponent } from './suggestAddMessage.component.ts';
import { suggestAddMessageServiceComponentModule } from './suggestAddMessage.comp';
angular.module('tutor.student.tutoring.plan.suggestAddMessageService', [suggestAddMessageServiceComponentModule, ModalService.downgrade.moduleName]).factory('suggestAddMessageService', suggestAddMessageService);
function suggestAddMessageService(modalService, $q) {
  return {
    open(plan, validatablePlan) {
      return modalService.open(SuggestAddMessageModalComponent, {
        plan,
        validatablePlan
      }).result.catch(() => $q.reject('CANCEL'));
    }
  };
}