<ng-container *transloco="let t">
  <ng-container *ngIf="this.viewData$ | async as viewData">

    <div class="row gy-4">
      <section *ngFor="let studyGroupSetViewData of viewData">
        <div class="col-12">
          <h3>{{ t('ENROLMENTS.STUDY_GROUPS.STUDY_GROUP_SET_HEADER') }}, {{ studyGroupSetViewData.studyGroupSet.name | localizedString }}</h3>
        </div>
        <section *ngIf="studyGroupSetViewData.studySubGroupsForLaterPlacement.length > 0 && studyGroupSetViewData.confirmedStudySubGroups.length === 0"
                 data-cy="study-sub-groups-for-later-placement">
          <div class="col-12">
            <p>{{ t('COURSE_UNIT_REALISATION.ALLOCATION_RULE.0_0_HAS_GROUPS') }}</p>
          </div>
        </section>
        <section *ngIf="studyGroupSetViewData.confirmedStudySubGroups.length > 0" data-cy="confirmed-study-sub-groups">
          <div class="col-12">
            <h4 class="mt-4">{{ t('ENROLMENTS.STUDY_SUB_GROUPS.STUDENT_SELECTED')}}</h4>
            <ul class="list-unstyled mt-3">
              <li *ngFor="let studySubGroup of studyGroupSetViewData.confirmedStudySubGroups" class="mb-3">
                <app-study-sub-group-view [studySubGroup]="studySubGroup" [style]="'SELECTED'"/>
              </li>
            </ul>
          </div>
        </section>
        <section *ngIf="studyGroupSetViewData.tentativeStudySubGroups.length > 0"
                 data-cy="currently-selected-study-sub-groups">
          <div class="col-12">
            <h4 class="mt-4">{{ t('ENROLMENTS.STUDY_SUB_GROUPS.STUDENT_CURRENTLY_SELECTED')}}</h4>
            <ul class="list-unstyled mt-3">
              <li *ngFor="let studySubGroup of studyGroupSetViewData.tentativeStudySubGroups" class="mb-3">
                <app-study-sub-group-view [studySubGroup]="studySubGroup" [style]="'SELECTED'"/>
              </li>
            </ul>
          </div>
        </section>
        <section *ngIf="studyGroupSetViewData.notSelectedStudySubGroups.length > 0"
                 data-cy="currently-not-selected-study-sub-groups">
          <div class="col-12">
            <h4 class="mt-4">{{ t('ENROLMENTS.STUDY_SUB_GROUPS.STUDENT_CURRENTLY_NOT_SELECTED')}}</h4>
            <ul class="list-unstyled mt-3">
              <li *ngFor="let studySubGroup of studyGroupSetViewData.notSelectedStudySubGroups" class="mb-3">
                <app-study-sub-group-view [studySubGroup]="studySubGroup" [style]="'NOT_SELECTED'"/>
              </li>
            </ul>
          </div>
        </section>
      </section>
    </div>

  </ng-container>
</ng-container>
