<div class="modal-header">
  <h2 class="modal-title" translate>{{values.title}}</h2>
</div>
<div class="modal-body">
    <p *ngIf="this.virusModal">{{'FILE_UPLOAD.INVALID_VIRUS_FILES' | translate }}</p>
    <p *ngIf="!this.virusModal">{{'FILE_UPLOAD.INVALID_FILES' | translate }}</p>
    <div class="object file-preview" *ngFor="let file of values.invalidFileSize">
        <span class="file-name">{{file.name}} ({{readableFileSizeString(file.size)}})</span>
        <p class="error-message">
          <sis-icon icon="alert" color="danger"></sis-icon>{{'FILE_UPLOAD.ACCEPTED_FILE_SIZE_GOES_OVER' | translate: {x: values.maxFileSizeString} }}
        </p>
    </div>
    <div class="object file-preview" *ngFor="let file of values.invalidFileFormat">
        <span class="file-name">{{file.name}} ({{readableFileSizeString(file.size)}})</span>
        <p class="error-message">
          <sis-icon icon="alert" color="danger"></sis-icon>{{'FILE_UPLOAD.UNACCEPTED_FILE_FORMAT' | translate }}
        </p>
    </div>
    <div class="object file-preview" *ngFor="let file of values.filesWithSameFileName">
        <span class="file-name">{{file.name}} ({{readableFileSizeString(file.size)}})</span>
        <p class="error-message" *ngIf="!values.editPossible"><sis-icon icon="alert" color="danger"></sis-icon>{{'FILE_UPLOAD.SAME_FILE_NAME' | translate }}</p>
        <p class="error-message" *ngIf="values.editPossible"><sis-icon icon="alert" color="danger"></sis-icon>{{'FILE_UPLOAD.SAME_FILE_NAME_ABLE_TO_EDIT' | translate }}</p>
    </div>
    <div class="object file-preview" *ngFor="let file of values.filesWithInvalidFileName">
      <span class="file-name">{{file.name}} ({{readableFileSizeString(file.size)}})</span>
      <p class="error-message">
        <sis-icon icon="alert" color="danger"></sis-icon>{{'FILE_UPLOAD.INVALID_FILENAME_MESSAGE' | translate }}
      </p>
    </div>
    <ng-container *ngIf="this.virusModal">
        <div class="object file-preview" *ngFor="let file of values.filesWithVirus">
            <span class="file-name">{{file.name}} ({{readableFileSizeString(file.size)}})</span>
        </div>
        <p class="status">{{'FILE_UPLOAD.UNABLE_TO_SEND_FILES' | translate }}</p>
    </ng-container>
    <ng-container *ngIf="this.otherAcceptedFiles">
        <p class="status">{{'FILE_UPLOAD.OTHER_ACCEPTED' | translate }}</p>
    </ng-container>
</div>
<div class="modal-footer">
  <button class="btn btn-primary btn-lg"
          type="button"
          (click)="modalInstance.close()"
          [translate]="values.confirmText || 'SIS_COMPONENTS.BUTTON.OK'"></button>
</div>
