import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { DocumentState } from 'common-typescript/types';

import { CustomModuleAttainmentStateObject, ModuleStateObject } from '../../service/plan-state.service';

@Component({
    selector: 'sis-plan-structure-module-state-badges',
    templateUrl: './plan-structure-module-state-badges.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanStructureModuleStateBadgesComponent {

    @Input() documentState: DocumentState;
    @Input() stateObject: ModuleStateObject | CustomModuleAttainmentStateObject;

}
