import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';

import {
    FormControlChangeDetectorComponent,
} from '../../form/form-control-change-detector/form-control-change-detector.component';
import { getLabelState } from '../../form/formUtils';
import { SisFormControl } from '../../form/sis-form-control';

@Component({
    selector: 'sis-number-input',
    templateUrl: './number-input.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumberInputComponent extends FormControlChangeDetectorComponent<number> implements OnChanges {
    @Input() id: string;
    @Input() disabled: boolean;
    @Input() keysPressed?: { [key: string]: boolean } = {};
    @Input() label?: string;
    @Input() placeholder?: string;
    /** A string displayed next to the input field, e.g. credit points */
    @Input() valueType?: string;
    @Input() showLabelIcon?: boolean = true;

    ngOnChanges(changes: SimpleChanges) {
        if (changes.keysPressed) {
            this.keysPressed = changes.keysPressed.currentValue;
        }
    }

    // This will prevent input to decrease/increase the value by one
    // when shift + up/down arrow is used for navigation purposes.
    arrowKeyMovement(e: any) {
        if (this.keysPressed['Shift'] && (e.key === 'ArrowDown' || e.key === 'ArrowUp')) {
            e.preventDefault();
        }
    }

    get labelState(): string {
        return this.showLabelIcon ? getLabelState(this.control) : '';
    }

}
