<span #popover="ngbPopover"
      [autoClose]="autoClose"
      [disablePopover]="disablePopover"
      (hidden)="isHidden()"
      [ngbPopover]="defaultTemplate"
      [popoverClass]="'open ' + popoverClass"
      [placement]="popoverPlacement"
      (shown)="isShown()"
      [triggers]="popoverTriggers">
  <button *ngIf="triggerText" class="popover-trigger-text"
          [class.underline]="underlineTriggerText"
          [class.dropdown-item]="dropdownItem">{{ triggerText }}</button>
  <ng-content select=".sis-popover-trigger-content" class="popover-trigger"></ng-content>
</span>
<ng-template #defaultTemplate>
  <span *ngIf="popoverText" class="popover-content">{{ popoverText }}</span>
  <ng-content select=".sis-popover-popover-content" class="popover-content"></ng-content>
  <button type="button" *ngIf="showCloseButton" class="non-button popover-close" (click)="popover.close()" [attr.aria-label]="'ARIA_LABEL.CLOSE' | translate">
    <sis-icon icon="close"></sis-icon>
  </button>
</ng-template>
