import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Injector } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NGXLogger } from 'ngx-logger';

import { InjectorService } from '../injector/injector.service';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from '../types/angular-hybrid';

/**
 * Options that can be passed to NG Bootstrap modals. These include some custom ones and all the
 * <a href="https://ng-bootstrap.github.io/#/components/modal/api#NgbModalOptions">NG Bootstrap Modal options</a>.
 */
export interface ModalOptions extends NgbModalOptions {
    /**
     * Whether the modal closes when the user clicks outside of it. Should only be set to `true` for modals that don't have buttons
     * in the footer, for example brochure modals or simple modals with only a close button in the top corner.
     * Uses internally NgbModalOptions' backdrop option.
     */
    closeWithOutsideClick?: boolean;
}

/**
 * A generic service for opening modals based on NG Bootstrap <a href="https://ng-bootstrap.github.io/#/components/modal/examples">Modal</a>.
 * Provides one method to open the modal: {@link open}.
 * <p>
 * If you need to open a modal that contains an AngularJS component, you need:
 * <ol>
 *   <li>an Angular directive that is an upgraded version of the AngularJS component</li>
 *   <li>a simple wrapper Angular component that just renders the Angular upgrade directive in its template</li>
 *   <li>an AngularJS or Angular service that calls {@link ModalService#open} with the wrapper component and its settings</li>
 * </ol>
 * For an example, see e.g. <code>TermRegistrationPeriodEditDirective</code>, <code>TermRegistrationPeriodEditComponent</code> and the
 * <code>termRegistrationPeriodModal</code> service in the admin frontend.
 */
@StaticMembers<DowngradedService>()
@Injectable({ providedIn: 'root' })
export class ModalService {

    static downgrade: ServiceDowngradeMappings = {
        moduleName: 'sis-common.modal.modalService',
        serviceName: 'modalService',
    };

    static injectionToken = 'ModalValues';

    constructor(private injectorService: InjectorService,
                private logger: NGXLogger,
                // TODO: Change the any type to HTMLDocument when https://github.com/angular/angular/issues/15640 is fixed
                @Inject(DOCUMENT) private document: any) {}

    /**
     * Opens a new modal window with the specified content, values and supplied options.
     *
     * @param content content component type
     * @param values additional values passed to the component; you can retrieve them in the component constructor with the `ModalService.injectionToken` injection token
     * @param options values added to the default options, see {@link ModalOptions} and the <a href="https://ng-bootstrap.github.io/#/components/modal/api#NgbModalOptions">NG Bootstrap Modal options</a>
     * @return modal reference ({@link NgbModalRef}); the `result` field contains a Promise resolving to the value specified by the content component
     */
    open<T>(content: T, values: any, options?: ModalOptions): NgbModalRef {
        const modal = this.modalService.open(content, this.getOptions(values, options));
        return this.adjustRightPaddingToCompensateForPossiblyRemovedScrollbar(modal);
    }

    private get modalService(): NgbModal {
        // ModalService is instantiated during app bootstrapping via AppErrorHandler, and attempting to inject NgbModal
        // during the bootstrapping causes a circular dependency. Therefore, we need to get the NgbModal instance lazily.
        // See: https://github.com/ng-bootstrap/ng-bootstrap/issues/3719
        return this.injectorService.getRootInjector().get(NgbModal);
    }

    private getOptions(values: any, options: ModalOptions = {}): NgbModalOptions {
        const backdrop = options.closeWithOutsideClick || 'static';
        return {
            backdrop,
            centered: false,
            injector: Injector.create({
                providers: [{ provide: ModalService.injectionToken, useValue: values }],
                parent: this.injectorService.getRootInjector(),
            }),
            ...options,
        };
    }

    /**
     * Adds the same padding on the right of our top-nav element than is added to the body element by NG Bootstrap, to compensate for scrollbar width that possibly gets
     * removed when a modal is shown. The padding is removed when the modal is closed.
     *
     * Adapted from two NG Bootstrap methods:
     * <ul>
     *   <li>`open` method at https://github.com/ng-bootstrap/ng-bootstrap/blob/master/src/modal/modal-stack.ts</li>
     *   <li>`_adjustBody` method at https://github.com/ng-bootstrap/ng-bootstrap/blob/master/src/util/scrollbar.ts</li>
     * </ul>
     *
     * @param modal opened modal reference
     * @return opened modal reference
     */
    private adjustRightPaddingToCompensateForPossiblyRemovedScrollbar(modal: NgbModalRef): NgbModalRef {
        const scrollbarCompensationBodyPadding = this.document.body.style.paddingRight; // Padding added by NG Bootstrap if scrollbar was removed when showing the modal
        if (!scrollbarCompensationBodyPadding) {
            return modal;
        }

        // Adjust specific top-nav child elements (instead of top-nav itself) because they may have varying background colors
        const topNavChildElementsToAdjust = [
            { selector: 'university-iframe', property: 'padding-right' },
            { selector: '.top-nav-right', property: 'right' }, // absolutely positioned, so changing padding has no effect
        ]
            .map(({ selector, property }) => {
                const element = this.document.querySelector(`top-nav ${selector}`);
                if (!element) {
                    this.logger.warn(`top-nav ${selector} element not found when compensating for removed scrollbar`);
                }
                return { element, property };
            })
            .filter(({ element }) => element);

        // NOTE: we assume that the initial values are effectively zero, to simplify the code significantly (to avoid `window.getComputedStyle` etc.)

        // Set padding compensation
        topNavChildElementsToAdjust.forEach(({ element, property }) => {
            element.style[property] = scrollbarCompensationBodyPadding;
        });

        // Revert padding compensation when modal is closed
        const revertPaddingChange = () => topNavChildElementsToAdjust.forEach(({ element, property }) => {
            element.style[property] = '';
        });
        modal.result.then(revertPaddingChange, revertPaddingChange);

        return modal;
    }
}
