"use strict";

(function () {
  planManagerFactory.$inject = ["planService", "$log", "previewMode", "$rootScope", "planContextEventType", "defaultPromiseHandler"];
  angular.module('student.common.context.plan-context').factory("PlanManager", planManagerFactory);
  function planManagerFactory(planService, $log, previewMode, $rootScope, planContextEventType, defaultPromiseHandler) {
    // NOSONAR
    return PlanManager;
    function PlanManager(planContext) {
      var self = this;
      self.planContext = planContext;
      var listener = $rootScope.$on(planContextEventType.planUpdated, updateListener);
      this.dispose = function () {
        listener();
      };
      var pendingUpdates = 0;
      function updateListener() {
        if (previewMode.isPreviewMode()) {
          $log.info('previewMode, no update');
        } else if (pendingUpdates++ === 0) {
          savePlan();
        } else {
          $log.info("Defer update");
        }
      }
      function savePlan() {
        var batchSize = pendingUpdates;
        self.planContext.toPlan().then(planService.update).then(function (plan) {
          saveSuccess(batchSize, plan);
        }, saveFailure).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
      }
      function saveSuccess(batchSize, plan) {
        pendingUpdates -= batchSize;
        if (pendingUpdates > 0) {
          self.planContext.updateMetadata(plan);
          savePlan();
        } else {
          // Defer building plan until all pending updates are processed
          self.planContext.build(plan);
        }
      }
      function saveFailure(failure) {
        if (failure.status >= 400 && failure.status < 600) {
          $log.error("Failed to save a plan.", failure.data);
          // This is really problematic, removing the pending update
          // does not rollback the changes actually made in the UI. The plan state
          // is incorrect if the save fails.
          pendingUpdates = 0;
          throw "Failed to save plan. See error log for details";
        }
        if (pendingUpdates > 0) {
          savePlan();
        }
      }
    }
  }
})();