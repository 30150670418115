import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SisCommonModule } from 'sis-common/sis-common.module';

import { SisComponentsModule } from '../../../sis-components.module';

import { StudyRightStatesComponent } from './study-right-states.component';

@NgModule({
    declarations: [
        StudyRightStatesComponent,
    ],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        SisCommonModule,
        SisComponentsModule,
    ],
    exports: [
        StudyRightStatesComponent,
    ],
})
export class StudyRightStatesModule {
}
