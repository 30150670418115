import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StudyRightCancellation } from 'common-typescript/types';
import { ModalService } from 'sis-common/modal/modal.service';

@Component({
    selector: 'sis-study-right-cancel-decision-modal',
    templateUrl: './study-right-cancel-decision-modal.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class StudyRightCancelDecisionModalComponent {

    constructor(@Inject(ModalService.injectionToken) public values: StudyRightCancellation,
                public modal: NgbActiveModal) {}
}
