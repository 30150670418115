import { Pipe, PipeTransform } from '@angular/core';
import { EnrolmentProcessingState } from 'common-typescript/types/generated/common-backend';

import { Icon } from '../../lib/icon/icon.component';

@Pipe({ name: 'enrolmentProcessingStateIcon' })
export class EnrolmentProcessingStateIconPipe implements PipeTransform {

    transform(processingState: EnrolmentProcessingState): Icon {
        if (processingState === 'NOT_PROCESSED') {
            return 'question-mark';
        }
        if (processingState === 'PENDING') {
            return 'hourglass';
        }
        if (['SELECTED', 'CURRENTLY_SELECTED'].includes(processingState)) {
            return 'waiting-approval';
        }
        if (processingState === 'REQ_NOT_FULFILLED') {
            return 'fail';
        }
        // CURRENTLY_NOT_SELECTED and NOT_SELECTED
        return 'waiting-decline';
    }
}
