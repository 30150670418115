(function () {
  'use strict';

  planMapFactory.$inject = ["$log"];
  angular.module('student.common.context.plan-context').factory('PlanMap', planMapFactory);
  function planMapFactory($log) {
    function PlanMap(indexedBy) {
      this.collection = {};
      this.indexedBy = indexedBy || 'id';
    }
    PlanMap.prototype.set = function (obj) {
      try {
        this.collection[obj[this.indexedBy]] = obj;
      } catch (err) {
        $log.error("Failed to execute PlanMap#set", 'obj:', obj, 'error:', err);
      }
      return this;
    };
    PlanMap.prototype.get = function (id, defaultValue) {
      var arr = this.getBy(this.indexedBy, id);
      if (arr.length !== 1) {
        if (defaultValue) {
          return defaultValue;
        } else {
          return null;
        }
      }
      return _.first(arr);
    };
    PlanMap.prototype.getBy = function (key, val) {
      var arr = _.groupBy(this.collection, key);
      return _.result(arr, val) || [];
    };
    PlanMap.prototype.getFirstBy = function (key, val) {
      var arr = _.groupBy(this.collection, key)[val];
      return arr && arr.length > 0 ? arr[0] : null;
    };
    PlanMap.prototype.clear = function () {
      this.collection = {};
      return this;
    };
    return PlanMap;
  }
})();