import angular from 'angular';
import 'angular-dynamic-locale';
'use strict';
(function () {
  localeLoaderService.$inject = ["tmhDynamicLocaleCache"];
  angular.module('sis-common.l10n.localeLoaderService', ['tmh.dynamicLocale']).factory('localeLoaderService', localeLoaderService);

  /**
   * @ngInject
   */
  function localeLoaderService(tmhDynamicLocaleCache) {
    return {
      loadLocales: function (language) {
        return [loadAngularLocale(language).then(() => storeAngularLocale(tmhDynamicLocaleCache, language))];
      }
    };
  }
  function storeAngularLocale(tmhDynamicLocaleCache, language) {
    const loadedLocale = angular.injector(['ngLocale']).get('$locale');
    tmhDynamicLocaleCache.put(language, loadedLocale);
  }
  function loadAngularLocale(language) {
    switch (language) {
      case 'en':
        return import( /* webpackChunkName: "angular-locale_en" */'angular-i18n/angular-locale_en.js');
      case 'fi':
        return import( /* webpackChunkName: "angular-locale_fi" */'angular-i18n/angular-locale_fi.js');
      case 'sv':
        return import( /* webpackChunkName: "angular-locale_sv" */'angular-i18n/angular-locale_sv.js');
      default:
        throw new Error(`Unknown language "${language}" when loading angular locale`);
    }
  }
})();