<ng-container *ngFor="let attachments of  externalAttachments$ | async">
    <ng-container *ngFor="let document of  attachments?.documents">
      <div class="sis-mt-sm" *ngFor="let lang of getAttachmentLanguages(document)">
        <ng-container *ngIf="document.attachments[lang] as attachment">
          <fudis-link *ngIf="showLink" [externalLink]="attachment.preSignedGetUrl" data-cy="attachment"
                      [title]="attachment.name + (attachment.fileType | mimeTypeToExtension) + ' (' + readableFileSizeString(attachment.size) + ')' +  ' (EMREX:' +  document.externalIdentifier + ')'"/>
          <div class="object file-preview" *ngIf="!showLink">
            <span class="file-name">{{attachment.name}}{{attachment.fileType | mimeTypeToExtension}} ({{readableFileSizeString(attachment.size)}}) (EMREX: {{document.externalIdentifier}})</span>
          </div>
          <p>{{getDescriptionForLang(document, lang)}}</p>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
