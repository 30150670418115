import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ScrollService {
    scrollToElementById(id: string) {
        if (id) {
            const element = this.getElementById(id);
            if (element) {
                element.scrollIntoView();
                element.focus();
            }
        } else {
            throw new Error('No element id provided');
        }
    }

    private getElementById(id: string) {
        return document.getElementById(id);
    }
}
