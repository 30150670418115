import timetableGridTpl from './timetableGrid.tpl.html';
(function () {
  'use strict';

  timetableGridController.$inject = ["timetableConfigConstant"];
  angular.module('student.frontpage.timetable-grid').constant('timetableConfigConstant', {
    // timetable hour list
    hours: _.range(0, 25),
    size: {
      hourHeight: 70,
      itemWidth: 150
    }
  }).component('timetableGrid', {
    bindings: {
      'events': '<',
      'hourRange': '<'
    },
    transclude: true,
    template: timetableGridTpl,
    controller: timetableGridController
  });
  /** @ngInject */
  function timetableGridController(timetableConfigConstant) {
    var $ctrl = this;
    $ctrl.hourElementHeight = timetableConfigConstant.size.hourHeight + 'px';
    $ctrl.itemenElementWidth = timetableConfigConstant.size.itemWidth;
    $ctrl.$onInit = function () {
      $ctrl.hours = _.map($ctrl.hourRange, function (hour) {
        var d = moment();
        d.hour(hour);
        return {
          formatted: d.format('H.00'),
          hour: hour
        };
      });

      // check the items' furthest position from left to get the total width of timetableItems
      $ctrl.itemsTotalWidth = _.last(_.map($ctrl.events, 'left').sort());
    };
  }
})();