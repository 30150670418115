import angular from 'angular';
import { decimalNumberFilterModule } from '../number/decimalNumber.filter';
import customStudyDraftTpl from './customStudyDraft.tpl.html';
export const customStudyDraftModule = 'sis-components.customStudyDraft';
(function () {
  angular.module('sis-components.customStudyDraft', [decimalNumberFilterModule]).component('customStudyDraft', {
    template: customStudyDraftTpl,
    controller: customStudyDraftController,
    bindings: {
      customStudyDraft: '<',
      infoModalLinkDisabled: '<?',
      onModalOpen: '&?'
    },
    transclude: true
  });

  /**
   * @ngInject
   */
  function customStudyDraftController() {
    const $ctrl = this;
    $ctrl.openInfoModal = () => {
      if ($ctrl.onModalOpen) {
        $ctrl.onModalOpen({
          customStudyDraftObject: $ctrl.customStudyDraft
        });
      }
    };
  }
})();