(function () {
  'use strict';

  studyPeriodName.$inject = ["localeService"];
  angular.module('student.common.filters.studyPeriod', ['sis-common.l10n.localeService', 'sis-components.date.constants']).filter('studyPeriodName', studyPeriodName);
  function studyPeriodName(localeService) {
    return function (studyPeriod) {
      if (studyPeriod) {
        return localeService.getLocalizedValue(studyPeriod.name) + ' / ' + moment(studyPeriod.startDate).year();
      }
      return '';
    };
  }
})();