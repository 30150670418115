import angular from 'angular';
export const attainmentTypesModule = 'sis-components.constant.attainmentTypes';
(function () {
  angular.module(attainmentTypesModule, []).constant('AttainmentTypes', {
    COURSE_UNIT_ATTAINMENT: 'CourseUnitAttainment',
    CUSTOM_COURSE_UNIT_ATTAINMENT: 'CustomCourseUnitAttainment',
    DEGREE_PROGRAMME_ATTAINMENT: 'DegreeProgrammeAttainment',
    MODULE_ATTAINMENT: 'ModuleAttainment',
    CUSTOM_MODULE_ATTAINMENT: 'CustomModuleAttainment',
    ASSESSMENT_ITEM_ATTAINMENT: 'AssessmentItemAttainment'
  }).constant('AttainmentNodeTypes', {
    ATTAINMENT_GROUP_NODE: 'AttainmentGroupNode',
    ATTAINMENT_REFERENCE_NODE: 'AttainmentReferenceNode'
  });
})();