import { Component, Input, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';
import {
    Attainment,
    CreditRange,
    LocalizedString,
    Module,
} from 'common-typescript/types';
import _ from 'lodash';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { ModuleStateObject, PlanData, PlanStateObject } from '../../../service/plan-state.service';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-rule-module-box',
    templateUrl: './rule-module-box.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class RuleModuleBoxComponent implements OnInit, OnChanges {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.plan.rules.ruleModuleBox',
        directiveName: 'sisRuleModuleBox',
    };

    moduleStateObject: ModuleStateObject;
    attainment: Attainment;
    visibleCredits: CreditRange;
    gradeAbbreviation: LocalizedString;
    showExpiryWarning: boolean;
    dimmed = false;

    @Input() module: Module;
    @Input() planValidationResult: any;
    @Input() planStateObject: PlanStateObject;
    @Input() planData: PlanData;
    @Input() isImplicit: boolean;
    @Input() parentModule?: Module;

    ngOnInit() {
        this.showExpiryWarning = true;
        this.visibleCredits = this.attainment ? { min: this.attainment.credits, max: this.attainment.credits } : _.get(this.module, 'targetCredits');
    }

    ngOnChanges() {
        this.moduleStateObject = _.get(this.planStateObject.modules, this.module.id);
        if (this.moduleStateObject) {
            this.attainment = this.planData.attainmentsById[this.moduleStateObject.attainmentId];
        }
    }

    openModuleInfoModal() {

    }

}
