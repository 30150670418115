import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import {
    CommonSearchFilters,
    OtmId,
    Qualification,
    QualificationSearch, SearchParameters,
    SearchResult,
} from 'common-typescript/types';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

import { QualificationState, QualificationType } from '../model/qualificationConstants';
import { searchRequestToQueryParams, simpleObjectToQueryParams } from '../search-ng/search-utils';

import { EntityService } from './entity.service';

export interface QualificationSearchParams extends CommonSearchFilters {
    qualificationState?: QualificationState[];
}

const CONFIG = {
    ENDPOINTS: {
        backend: '/kori/api',
        get create() {
            return `${this.backend}/qualifications`;
        },
        publish(qualificationId: OtmId) {
            return `${this.backend}/qualifications/publish/${qualificationId}`;
        },
        archive(qualificationId: OtmId) {
            return `${this.backend}/qualifications/archive/${qualificationId}`;
        },
        get searchActive() {
            return `${this.backend}/qualifications-active-search`;
        },
        get search() {
            return `${this.backend}/qualifications-search`;
        },
    },
};

@StaticMembers<DowngradedService>()
@Injectable({
    providedIn: 'root',
})
@NgEntityServiceConfig({
    baseUrl: CONFIG.ENDPOINTS.backend,
    resourceName: 'qualifications',
})
export class QualificationEntityService extends EntityService<QualificationsState> {
    static downgrade: ServiceDowngradeMappings = {
        moduleName: 'sis-components.service.qualificationEntity',
        serviceName: 'qualificationEntityService',
    };

    constructor() {
        super(QualificationStore, QualificationQuery);
    }

    create(qualification: Partial<Qualification>): Observable<Qualification> {
        return this.getHttp().post<Qualification>(CONFIG.ENDPOINTS.create, qualification)
            .pipe(this.upsertAndSwitchToStoreObservable());
    }

    publish(qualificationId: OtmId): Observable<Qualification> {
        return this.getHttp().put(CONFIG.ENDPOINTS.publish(qualificationId), {})
            .pipe(this.upsertAndSwitchToStoreObservable());
    }

    archive(qualificationId: OtmId): Observable<Qualification> {
        return this.getHttp().put(CONFIG.ENDPOINTS.archive(qualificationId), {})
            .pipe(this.upsertAndSwitchToStoreObservable());
    }

    searchActive(searchParams: Partial<QualificationSearch>): Observable<SearchResult<Qualification>> {
        return this.getHttp().get<SearchResult<Qualification>>(CONFIG.ENDPOINTS.searchActive, { params: this.toQueryParams(searchParams) });
    }

    search(searchParams: SearchParameters<QualificationSearchParams>, universityOrgId: OtmId, qualificationType?: QualificationType[]): Observable<SearchResult<Qualification>> {
        const params = simpleObjectToQueryParams({
            ...searchParams?.filters,
            ...searchParams?.options,
            qualificationType,
            universityOrgId,
        });

        return this.getHttp().get<SearchResult<Qualification>>(CONFIG.ENDPOINTS.search, { params });
    }

    private toQueryParams(searchRequest: Partial<QualificationSearch>): { [key: string]: string | string[] } {
        if (_.isEmpty(searchRequest)) {
            return {};
        }

        return _.omitBy(
            {
                ...searchRequestToQueryParams(searchRequest),
                customQualificationType: searchRequest.customQualificationTypeUrns,
                qualificationType: searchRequest.qualificationTypes,
                universityOrgId: searchRequest.universityOrgIds,
            },
            _.isEmpty,
        );
    }

    storeUpsert(qualification: Qualification) {
        this.store.upsert(qualification.id, qualification);
    }
}

type QualificationsState = EntityState<Qualification, OtmId>;

@StoreConfig({ name: 'qualifications' })
class QualificationStore extends EntityStore<QualificationsState> {}

class QualificationQuery extends QueryEntity<QualificationsState> {
    constructor(protected store: QualificationStore) {
        super(store);
    }
}
