'use strict';

export const codeBookJSDataModelModule = 'sis-components.model.codebook';
(function () {
  initCodebookJSDataModel.$inject = ["codebookJSDataModel"];
  codebookJSDataModel.$inject = ["DS"];
  angular.module('sis-components.model.codebook', ['js-data']).factory('codebookJSDataModel', codebookJSDataModel).run(initCodebookJSDataModel);

  /**
   * @ngInject
   */
  function initCodebookJSDataModel(codebookJSDataModel) {} //NOSONAR

  /**
   * @ngInject
   */
  function codebookJSDataModel(DS) {
    return DS.defineResource({
      idAttribute: 'urn',
      name: 'Codebook',
      endpoint: '/kori/api/codebooks'
    });
  }
})();