import { Component, EventEmitter, HostListener, Inject, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PlanValidationTs } from 'common-typescript';
import { createRootRule } from 'common-typescript/src/plan/validation/moduleRootRuleCreator';
import { ValidatablePlan } from 'common-typescript/src/plan/validation/validatablePlan';
import {
    CourseUnit,
    CustomCourseUnitAttainment,
    CustomModuleAttainment,
    CustomStudyDraft,
    Education,
    EntityWithRule,
    GradeScale,
    LearningOpportunitySelectionPath,
    OtmId,
    Plan,
    Rule,
    StudyRight,
    UniversitySettings,
} from 'common-typescript/types';
import _ from 'lodash';
import { combineLatest, from, Observable, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DEFAULT_PROMISE_HANDLER } from 'sis-common/ajs-upgraded-modules';
import { ModalService } from 'sis-common/modal/modal.service';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import {
    COMMON_GRADE_SCALE_SERVICE,
    COMMON_PLAN_SELECTION_SERVICE,
    COMMON_PLAN_SERVICE,
    COMMON_STUDY_RIGHT_SERVICE,
    CUSTOM_STUDY_DRAFT_INFO_MODAL_SERVICE,
    PLAN_STUDY_RIGHT_SERVICE,
} from '../../ajs-upgraded-modules';
import {
    CustomAttainmentCreditInfoModalComponent,
} from '../../attainment/custom-attainment-credit-info-modal/custom-attainment-credit-info-modal.component';
import { Option } from '../../menuButton/menu-button.component';
import { EducationEntityService } from '../../service/education-entity.service';
import { PlanEntityService } from '../../service/plan-entity.service';
import { PlanData, PlanStateObject, PlanStateService } from '../../service/plan-state.service';
import { UniversityService } from '../../service/university.service';
import { convertAJSPromiseToNative } from '../../util/utils';
import { CreateCustomStudyDraftComponent } from '../create-custom-study-draft/create-custom-study-draft.component';
import { GeneralPlanActionsService } from '../plan-actions-service/general-plan-actions.service';
import { PLAN_ACTIONS_SERVICE_INJECTION_TOKEN, PlanActionsService, UiOperation, UiOperationType } from '../plan-actions-service/plan-actions.service';
import { PlanCourseUnitInfoModalComponent } from '../plan-course-unit-info-modal/plan-course-unit-info-modal.component';
import { PlanManager, PlanOperation } from '../plan-manager/plan-manager.service';

export interface UiStateObject {
    selectedModule: EntityWithRule;
    freeEditMode: boolean;
    universitySettings: UniversitySettings;
}

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-plan-edit',
    templateUrl: './plan-edit.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [
        PlanManager,
        { provide: PLAN_ACTIONS_SERVICE_INJECTION_TOKEN, useClass: GeneralPlanActionsService },
    ],
})

export class PlanEditComponent implements OnInit, OnDestroy {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.plan.planEdit',
        directiveName: 'sisPlanEdit',
    };

    @Input() planId: OtmId;
    @Output() planChange = new EventEmitter<Plan>();
    @Output() closeAndSave = new EventEmitter<Plan>();

    plan: Plan;
    planSubscription: Subscription;
    planManagerSubscription: Subscription;
    planOperationSubscription: Subscription;
    uiOperationSubscription: Subscription;
    validatablePlan: ValidatablePlan;
    studyRight: StudyRight;
    education: Education;
    educationOptions: any;
    planData: PlanData;
    selectionPathInPlan: LearningOpportunitySelectionPath;
    planValidationResult: any;
    planStateObject: PlanStateObject;
    gradeScalesById: { [id: string]: GradeScale };
    ready = false;
    collapsed = false;
    uiStateObject: UiStateObject = {
        selectedModule: null,
        freeEditMode: false,
        universitySettings: null,
    };

    selectedRule: Rule;
    customStudyDraftsNotMatchedByRules: CustomStudyDraft[];
    freeEditMenuOptions: Option[];

    constructor(private planEntityService: PlanEntityService,
                private planStateService: PlanStateService,
                private educationEntityService: EducationEntityService,
                private planManager: PlanManager,
                @Inject(PLAN_ACTIONS_SERVICE_INJECTION_TOKEN) private planActionsService: PlanActionsService,
                private translateService: TranslateService,
                private modalService: ModalService,
                private universityService: UniversityService,
                @Inject(COMMON_PLAN_SERVICE) private commonPlanService: any,
                @Inject(COMMON_PLAN_SELECTION_SERVICE) private commonPlanSelectionService: any,
                @Inject(COMMON_STUDY_RIGHT_SERVICE) private commonStudyRightService: any,
                @Inject(PLAN_STUDY_RIGHT_SERVICE) private planStudyRightService: any,
                @Inject(COMMON_GRADE_SCALE_SERVICE) private commonGradeScaleService: any,
                @Inject(CUSTOM_STUDY_DRAFT_INFO_MODAL_SERVICE) private customStudyDraftInfoModalService: any,
                @Inject(DEFAULT_PROMISE_HANDLER) private defaultPromiseHandler: any,
    ) {
    }

    ngOnInit() {
        this.planSubscription = this.planEntityService.getById(this.planId)
            .pipe(switchMap((plan: Plan) => {
                this.plan = _.cloneDeep(plan);

                const validatablePlanObservable: Observable<ValidatablePlan> = from(convertAJSPromiseToNative(this.commonPlanService.getValidatablePlan(this.plan, false, false))) as Observable<ValidatablePlan>;
                const studyRightsObservable: Observable<StudyRight[]> = from(convertAJSPromiseToNative(this.commonStudyRightService.findByPersonId(this.plan.userId, false, false))) as Observable<StudyRight[]>;

                return combineLatest([
                    validatablePlanObservable,
                    studyRightsObservable,
                    this.universityService.getCurrentUniversitySettings(),
                ]);
            })).subscribe(
                ([validatablePlan, studyRights, universitySettings]) => {
                    this.uiStateObject.universitySettings = universitySettings;
                    this.validatablePlan = validatablePlan;
                    this.studyRight = this.planStateService.getMatchingStudyRight(this.plan, studyRights);
                    this.planManager.setValidatablePlan(this.validatablePlan);
                    this.planManager.setStudyRight(this.studyRight);
                    this.planValidationResult = PlanValidationTs.validatePlan(this.validatablePlan);
                    this.education = this.validatablePlan.rootModule as unknown as Education;
                    this.educationOptions = this.planStudyRightService
                        .getValidatedEducationOptions(this.validatablePlan, this.education, this.studyRight);
                    this.selectionPathInPlan = this.planStudyRightService
                        .getSelectionPathInPlan(this.validatablePlan, this.education);

                    const gradeScaleIds = _.chain(_.values(validatablePlan.getAllAttainments()))
                        .map('gradeScaleId')
                        .concat('sis-0-5')
                        .compact()
                        .uniq()
                        .value();
                    this.commonGradeScaleService.findByIds(gradeScaleIds).then((gradeScales: GradeScale[]) => {
                        this.gradeScalesById = _.keyBy(gradeScales, 'id');
                        ({ planData: this.planData, planStateObject: this.planStateObject } = this.planStateService.getPlanStateAndData(
                            this.education,
                            this.validatablePlan,
                            this.planValidationResult,
                            this.educationOptions,
                            this.gradeScalesById,
                            this.studyRight,
                        ));
                        this.planManagerSubscription = this.planManager.validatablePlanSubject.subscribe((newValidatablePlan) => {
                            this.handleValidatablePlanUpdate(newValidatablePlan);
                        });
                        this.ready = true;
                    });
                },
            );
        this.freeEditMenuOptions = [
            {
                name: this.translateService.instant('SIS_COMPONENTS.PLAN.FREE_EDIT_MENU_OPTION'),
                action: () => this.openFreeEdit(),
            },
        ];

        this.planOperationSubscription = this.planActionsService.planOperationSubject.subscribe((planOperation: PlanOperation) => {
            this.planManager.processPlanOperation(planOperation);
        });

        this.uiOperationSubscription = this.planActionsService.uiOperationSubject.subscribe((uiOperation: UiOperation) => {
            this.handleUiOperation(uiOperation);
        });

    }

    openFreeEdit() {
        const newUiStateObject = _.cloneDeep(this.uiStateObject);
        newUiStateObject.freeEditMode = true;
        this.uiStateObject = newUiStateObject;
    }

    closeFreeEdit() {
        const newUiStateObject = _.cloneDeep(this.uiStateObject);
        newUiStateObject.freeEditMode = false;
        this.uiStateObject = newUiStateObject;
    }

    openCustomStudyDraftCreateModal(parentModule: EntityWithRule) {
        this.modalService.open(CreateCustomStudyDraftComponent, { parentModuleId: parentModule?.id }, { size: 'sm' })
            .closed.subscribe((studyDraft: CustomStudyDraft) => this.planActionsService.addCustomStudyDraft(studyDraft, parentModule));
    }

    removeCustomStudyDraft(customStudyDraft: CustomStudyDraft, parentModule: EntityWithRule) {
        this.planActionsService.removeCustomStudyDraft(customStudyDraft, parentModule);
    }

    openCourseUnitInfoModal(courseUnit: CourseUnit) {
        this.modalService.open(
            PlanCourseUnitInfoModalComponent,
            {
                originalCourseUnit: courseUnit,
                validatablePlan: this.validatablePlan,
                planActionsService: this.planActionsService,
                validatablePlanSubject: this.planManager.validatablePlanSubject,
            },
            {
                size: 'xl',
                windowClass: 'brochure-modal',
            },
        );
    }

    openCcuaInfoModal(customAttainment: CustomCourseUnitAttainment | CustomModuleAttainment) {
        this.modalService.open(
            CustomAttainmentCreditInfoModalComponent,
            customAttainment,
            { closeWithOutsideClick: true, size: 'lg' },
        );
    }

    closePlanEditAndSave() {
        this.closeAndSave.emit(this.validatablePlan.plan);
    }

    ngOnDestroy() {
        this.planSubscription.unsubscribe();
        this.planManagerSubscription.unsubscribe();
        this.planOperationSubscription.unsubscribe();
        this.uiOperationSubscription.unsubscribe();
    }

    handleUiOperation(uiOperation: UiOperation) {
        switch (uiOperation.uiOperationType) {
            case UiOperationType.OPEN_MODULE:
                return this.openModule(uiOperation.target as EntityWithRule);
            case UiOperationType.OPEN_CUSTOM_STUDY_DRAFT_CREATION_MODAL:
                return this.openCustomStudyDraftCreateModal(uiOperation.target as EntityWithRule);
            case UiOperationType.OPEN_CUSTOM_STUDY_DRAFT_INFO_MODAL:
                return this.customStudyDraftInfoModalService.open(uiOperation.target as CustomStudyDraft);
            case UiOperationType.OPEN_CUSTOM_ATTAINMENT_INFO_MODAL:
                return this.openCcuaInfoModal(uiOperation.target as CustomCourseUnitAttainment | CustomModuleAttainment);
            case UiOperationType.OPEN_COURSE_UNIT_INFO_MODAL:
                return this.openCourseUnitInfoModal(uiOperation.target as CourseUnit);
            case UiOperationType.CLOSE_AND_SAVE:
                return this.closePlanEditAndSave();
        }
    }

    openModule(module: EntityWithRule): void {
        const newUiStateObject = _.cloneDeep(this.uiStateObject);
        if (this.uiStateObject.selectedModule !== module) {
            newUiStateObject.selectedModule = module;
            this.selectedRule = this.getSelectedRule(module);
        } else {
            newUiStateObject.selectedModule = null;
            this.selectedRule = null;
        }
        newUiStateObject.freeEditMode = false;
        this.uiStateObject = newUiStateObject;
        this.customStudyDraftsNotMatchedByRules = this.getCustomStudyDraftsNotMatchedByRules(this.uiStateObject.selectedModule);
    }

    handleValidatablePlanUpdate(newValidatablePlan: ValidatablePlan) {
        const planValidationResult = PlanValidationTs.validatePlan(newValidatablePlan);
        const educationOptions = this.planStudyRightService
            .getValidatedEducationOptions(this.validatablePlan, this.education, this.studyRight);
        const selectionPathInPlan = this.planStudyRightService
            .getSelectionPathInPlan(this.validatablePlan, this.education);
        const planStateAndData = this.planStateService.getPlanStateAndData(
            this.education,
            newValidatablePlan,
            planValidationResult,
            educationOptions,
            this.gradeScalesById,
            this.studyRight,
        );
        this.planData = planStateAndData.planData;
        this.planStateObject = planStateAndData.planStateObject;
        this.planValidationResult = planValidationResult;
        this.educationOptions = educationOptions;
        this.selectionPathInPlan = selectionPathInPlan;
        this.validatablePlan = newValidatablePlan;
        this.customStudyDraftsNotMatchedByRules = this.getCustomStudyDraftsNotMatchedByRules(this.uiStateObject.selectedModule);
        this.planChange.emit(this.validatablePlan.plan);
    }

    getSelectedRule(selectedModule: EntityWithRule): Rule {
        if (!selectedModule) {
            return null;
        }
        if (selectedModule.type === 'Education' && selectedModule.id === this.validatablePlan.plan.rootId) {
            return createRootRule(selectedModule as Education, this.validatablePlan.studyRight);
        }
        return selectedModule.rule;
    }

    getCustomStudyDraftsNotMatchedByRules(selectedModule: EntityWithRule): CustomStudyDraft[] {
        if (!selectedModule || !this.validatablePlan || !this.planData) {
            return null;
        }

        const ruleValidationResults = _.values(_.get(this.planValidationResult.ruleValidationResults, selectedModule.id));
        const matchedCustomStudyDraftIds = _.flatMap(
            _.compact(_.flatMap(ruleValidationResults, 'selectedCustomStudyDraftsById')),
            _.keys,
        );
        const customStudyDraftsNotMatchedWithRules = _.filter(
            this.validatablePlan.getSelectedCustomStudyDraftsByParentModuleId(selectedModule.id),
            customStudyDraft => !_.includes(matchedCustomStudyDraftIds, customStudyDraft.id));
        return customStudyDraftsNotMatchedWithRules;
    }

    // Function detects window scrolling for sticking the right-column to collapsed page-header.
    @HostListener('window:scroll', [])
    onWindowScroll() {
        const verticalOffset = window.pageYOffset || 0;
        this.collapsed = verticalOffset >= 360; // 360 is the position where collapsed header meets the edit mode header's bottom.
    }

}
