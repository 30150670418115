import angular from 'angular';
import 'js-data';
export const enrolmentQuestionnaireAnswersModelModule = 'sis-components.model.enrolmentQuestionnaireAnswersModel';
(function () {
  enrolmentQuestionnaireAnswersModel.$inject = ["DS", "EnrolmentQuestionnaireAnswersURL"];
  angular.module(enrolmentQuestionnaireAnswersModelModule, ['js-data']).constant('EnrolmentQuestionnaireAnswersURL', {
    DEFAULT: '/ilmo/api/enrolment-questionnaire-answers',
    OPEN_UNIVERSITY: '/ilmo/api/open-university/enrolments/enrolment-questionnaire-answers/',
    ANSWERS_BY_CUR: '/ilmo/api/enrolment-questionnaire-answers-by-course-unit-realisation-id/'
  }).factory('enrolmentQuestionnaireAnswersJSDataModel', enrolmentQuestionnaireAnswersModel);

  /**
   * @ngInject
   */
  function enrolmentQuestionnaireAnswersModel(DS, EnrolmentQuestionnaireAnswersURL) {
    return DS.defineResource({
      idAttribute: 'enrolmentId',
      name: 'enrolmentQuestionnaireAnswers',
      endpoint: EnrolmentQuestionnaireAnswersURL.DEFAULT
    });
  }
})();