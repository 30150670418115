<div class="search-filter course-unit-filter">
  <form name="filterSelectorForm" [formGroup]="filterSelectorForm" novalidate>
    <div class="row">
      <div class="col-12">
        <sis-typeahead class="course-unit-filter-typeahead"
                       [model]="selectedCourseUnit"
                       [placeholder]="'SEARCH.FILTER_SELECTOR.COURSE_UNIT_INPUT_PLACEHOLDER' | translate"
                       [resultTemplate]="customCourseUnitTemplate"
                       (selectItem)="toggleValue.emit({ form: filterSelectorForm, value: $event.item })"
                       [typeahead]="searchCourseUnits.bind(this)">
        </sis-typeahead>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <ul class="filter-selectbox selectbox-multi ng-scope">
          <div *ngFor="let filterValue of listSelected()">
            <li class="ng-binding parent-value">
              <button type="button"
                      class="btn btn-primary btn-naked btn-sm btn-just-icon"
                      (click)="toggleValue.emit({ event: $event, form: filterSelectorForm, value: filterValue })">
                <sis-icon icon="delete"></sis-icon>
              </button>
              <div class="parent-value-text">{{filterValue.name}}</div>
            </li>
          </div>
        </ul>
      </div>
    </div>
    <div class="row filter-preview-row">
      <div class="col-9">
        <div class="filter-preview-holder">
          <span class="filter-preview-title">
            {{filterName}}
          </span>
          <span class="filter-preview-content">
            <span *ngFor="let filterValue of listSelected(); first as first"
                  [class.item-separator]="!first">{{getFilterName(filterValue)}}</span>
          </span>
        </div>
      </div>
      <div class="col-3">
        <button type="submit"
                class="btn btn-lg btn-primary filter-submit"
                (click)="submitFilter.emit()"
                [disabled]="filterSelectorForm.pristine"
                translate="SEARCH.FILTER_SELECTOR.OK">
        </button>
      </div>
    </div>
  </form>
</div>

<ng-template #customCourseUnitTemplate let-result="result">
  <sis-tooltip [tooltipText]="result.name " [appendToBody]="true" tooltipPlacement="left">
    <span class="sis-tooltip-trigger-content">
       {{result.code ? result.code + ', ' : '' }} {{result.name}}
    </span>
  </sis-tooltip>
</ng-template>
