import { Injectable } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from 'sis-common/modal/modal.service';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

import { EmrexResultModalComponent } from './emrex-result-modal.component';

@StaticMembers<DowngradedService>()
@Injectable({
    providedIn: 'root',
})
export class EmrexResultModalService {

    static downgrade: ServiceDowngradeMappings = {
        moduleName: 'student.plan.emrexResultModalService',
        serviceName: 'emrexResultModalServiceNg',
    };

    constructor(private modalService: ModalService) { }

    open(resultCode: string): NgbModalRef {
        return this.modalService.open(
            EmrexResultModalComponent,
            resultCode,
            {
                closeWithOutsideClick: true,
                size: 'md',
            });
    }
}
