import { Component, Inject, Input, ViewEncapsulation } from '@angular/core';
import { ModuleAttainmentApplication } from 'common-typescript/types';
import { DEFAULT_PROMISE_HANDLER } from 'sis-common/ajs-upgraded-modules';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';
import { HeaderService } from 'sis-components/header/header.service';

import { ApplicationModalService } from '../application-modal.service';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'app-module-attainment-application',
    templateUrl: './module-attainment-application.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ModuleAttainmentApplicationComponent {
    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'student.profile.applications.moduleAttainmentApplicationNg',
        directiveName: 'appModuleAttainmentApplication',
    };

    @Input() application: ModuleAttainmentApplication;

    constructor(
        private applicationModalService: ApplicationModalService,
        private headerService: HeaderService,
        @Inject(DEFAULT_PROMISE_HANDLER) private defaultPromiseHandler: any,
    ) { }

    cancelApplication() {
        this.applicationModalService.openCancelApplicationModal(this.application)
            .subscribe({
                error: this.defaultPromiseHandler.loggingRejectedPromiseHandler,
                complete: () => {
                    this.headerService.setApplicationStateBadge(this.application);
                },
            });
    }
}
