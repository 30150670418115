import { Injectable } from '@angular/core';
import {
    CourseUnitSelection,
    CustomStudyDraft,
    LocalId,
    OtmId,
    Plan,
    PlanValidationState,
    StudyPeriod,
    StudyRight,
} from 'common-typescript/types';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CurrentStudyPeriodService } from 'sis-components/service/current-study-period.service';
import { PrimaryPlanService } from 'sis-components/service/primary-plan.service';

import { PlanCourseUnitSelectionService } from './plan-course-unit-selection.service';
import { PlanValidationService } from './plan-validation.service';
import { StudyTimingValidationResult, StudyTimingValidationService } from './study-timing-validation.service';

@Injectable({ providedIn: 'root' })
export class StudyRightExtensionRequirementsService {

    constructor(
        private readonly _primaryPlanService: PrimaryPlanService,
        private readonly _planValidationService: PlanValidationService,
        private readonly _planCourseUnitSelectionService: PlanCourseUnitSelectionService,
        private readonly _currentStudyPeriodService: CurrentStudyPeriodService,
        private readonly _studyTimingValidationService: StudyTimingValidationService,
    ) {
    }

    /**
     * Returns requirement data for applying for extension for the given study right.
     */
    getStudyRightExtensionRequirements(studyRight: StudyRight): Observable<StudyRightExtensionRequirements | null> {
        return this._primaryPlanService.getPrimaryPlanForStudyRight(studyRight).pipe(
            switchMap((primaryPlan: Plan | null) => {
                if (!primaryPlan) {
                    return of(null);
                }

                return combineLatest([
                    this._planValidationService.getPlanValidationState(primaryPlan, studyRight),
                    this.getTimingFields(primaryPlan),
                ]).pipe(
                    map(([planState, timingFields]: [PlanValidationState | null, Partial<StudyRightExtensionRequirements>]) => (<StudyRightExtensionRequirements>{
                        primaryPlan,
                        planState,
                        ...timingFields,
                    })),
                );
            }),
        );
    }

    private getTimingFields(plan: Plan): Observable<Partial<StudyRightExtensionRequirements>> {
        return combineLatest([
            this._planCourseUnitSelectionService.getCourseUnitSelectionsRequiringTiming(plan),
            this._currentStudyPeriodService.currentStudyPeriod$,
        ]).pipe(
            map(([courseUnitSelectionsRequiringTiming, currentStudyPeriod]: [CourseUnitSelection[], StudyPeriod | null]) => {
                // validate course unit selection timing
                let courseUnitIdsWithNoTiming: OtmId[];
                let courseUnitIdsWithTimingInThePast: OtmId[];
                if (courseUnitSelectionsRequiringTiming.length) {
                    const courseUnitSelectionTimingValidationResult: StudyTimingValidationResult<CourseUnitSelection> = this._studyTimingValidationService.validateStudyTimings(courseUnitSelectionsRequiringTiming, currentStudyPeriod);
                    courseUnitIdsWithNoTiming = courseUnitSelectionTimingValidationResult.studiesWithNoTiming.map(cus => cus.courseUnitId);
                    courseUnitIdsWithTimingInThePast = courseUnitSelectionTimingValidationResult.studiesWithTimingInThePast.map(cus => cus.courseUnitId);
                } else {
                    courseUnitIdsWithNoTiming = [];
                    courseUnitIdsWithTimingInThePast = [];
                }

                // validate custom study draft timing
                let customStudyDraftIdsWithNoTiming: LocalId[];
                let customStudyDraftIdsWithTimingInThePast: LocalId[];
                if (plan.customStudyDrafts?.length) {
                    const customStudyDraftTimingValidationResult: StudyTimingValidationResult<CustomStudyDraft> = this._studyTimingValidationService.validateStudyTimings(plan.customStudyDrafts, currentStudyPeriod);
                    customStudyDraftIdsWithNoTiming = customStudyDraftTimingValidationResult.studiesWithNoTiming.map(csd => csd.id);
                    customStudyDraftIdsWithTimingInThePast = customStudyDraftTimingValidationResult.studiesWithTimingInThePast.map(csd => csd.id);
                } else {
                    customStudyDraftIdsWithNoTiming = [];
                    customStudyDraftIdsWithTimingInThePast = [];
                }

                return <Partial<StudyRightExtensionRequirements>>{
                    courseUnitIdsWithNoTiming,
                    courseUnitIdsWithTimingInThePast,
                    customStudyDraftIdsWithNoTiming,
                    customStudyDraftIdsWithTimingInThePast,
                };
            }),
        );
    }
}

export interface StudyRightExtensionRequirements {
    readonly primaryPlan: Plan;
    readonly planState: PlanValidationState | null;
    readonly courseUnitIdsWithNoTiming: readonly OtmId[];
    readonly courseUnitIdsWithTimingInThePast: readonly OtmId[];
    readonly customStudyDraftIdsWithNoTiming: readonly LocalId[];
    readonly customStudyDraftIdsWithTimingInThePast: readonly LocalId[];
}
