import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UrlService } from '@uirouter/core';

/**
 * General cart error component to show simple cart process error messages.
 * If the payment fails there is payment-failed-component.ts for that.
 */
@Component({
    selector: 'app-cart-error',
    templateUrl: './cart-error.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartErrorComponent implements OnInit {

    titleKey: string;
    descriptionKey: string;

    constructor(private urlService: UrlService) { }

    ngOnInit(): void {
        const queryParams = this.urlService.search();

        if (queryParams?.errorType === CART_ERROR_MSG_TYPE.SESSION_TIMEOUT_WHILE_IN_PAYMENT_SYSTEM) {
            this.titleKey = 'OPEN_UNIVERSITY_CART.WIZARD.CART_ERROR.SESSION_TIMEOUT.TITLE';
            this.descriptionKey = 'OPEN_UNIVERSITY_CART.WIZARD.CART_ERROR.SESSION_TIMEOUT.DESC';
        }
    }

}

export enum CART_ERROR_MSG_TYPE {
    SESSION_TIMEOUT_WHILE_IN_PAYMENT_SYSTEM = 'sessionTimeoutWhileInPaymentSystem',
}
