<ng-container *ngIf="assessmentItem && form">
  <h4 class="d-block sis-mb-md">{{assessmentItem.name | localizedString}} ({{assessmentItem.credits | creditRange}})</h4>

  <label>{{'OPENUNIVERSITY.PRODUCT.REGISTRATION_FILTER' | translate}}</label>
  <sis-radio-button
    [label]="'OPENUNIVERSITY.PRODUCT_CREATION_WIZARD.STEP2.REGISTRATION_FILTER_OFF' | translate"
    [control]="form.controls.unlimitedEnrolments"
    [value]="true"
    [name]="assessmentItem.id + '-unlimitedEnrolments'"
  />
  <p class="help-block sis-ml-lg">{{'OPENUNIVERSITY.PRODUCT_CREATION_WIZARD.STEP2.REGISTRATION_FILTER_OFF_INFO' | translate}}</p>

  <sis-radio-button
    [label]="'OPENUNIVERSITY.PRODUCT_CREATION_WIZARD.STEP2.REGISTRATION_FILTER_ON' | translate"
    [control]="form.controls.unlimitedEnrolments"
    [value]="false"
    [name]="assessmentItem.id + '-unlimitedEnrolments'"
  />
  <p class="help-block sis-ml-lg" [class.sis-mb-lg]="form.controls.unlimitedEnrolments.value">
    {{'OPENUNIVERSITY.PRODUCT_CREATION_WIZARD.STEP2.REGISTRATION_FILTER_ON_INFO' | translate}}
  </p>

  <div class="row" *ngIf="!form.controls.unlimitedEnrolments.value">
    <div class="col-10 col-sm-6 col-md-5 col-lg-4">
      <sis-number-input
        [control]="form.controls.maxNumberOfEnrolments"
        [label]="'OPENUNIVERSITY.PRODUCT_CREATION_WIZARD.STEP2.ENROLMENT_LIMIT_INFO' | translate"
        [valueType]="'OPENUNIVERSITY.STUDY_RIGHTS.ENROLMENT_RIGHT_EDIT_MODAL.OF_WHICH_USED' | translate: {count: usedEnrolments ?? 0}"
        [id]="assessmentItem.id + '-maxNumberOfEnrolments'"
        class="d-block sis-mb-lg"
      />
    </div>
  </div>


  <sis-form-checkbox label="OPENUNIVERSITY.STUDY_RIGHTS.ASSESSMENT_ITEM.GUIDANCE"
                     [control]="form.controls.allowAllCourseUnitRealisations"
                     class="mt-3 mb-5">
    <sis-tiny-badge *ngIf="productAllowedCURIds === null" variant="success">
      {{'OPENUNIVERSITY.STUDY_RIGHTS.ENROLMENT_RIGHT_EDIT_MODAL.USED_IN_PRODUCT' | translate}}
    </sis-tiny-badge>
  </sis-form-checkbox>
  <ng-container *ngFor="let cur of visibleCourseUnitRealisations">
    <hr class="my-1"/>
    <div class="cur-selection-row">
      <sis-checkbox
        [checked]="form.controls.allowAllCourseUnitRealisations.value || form.controls.allowedCourseUnitRealisationIds.value.includes(cur.id)"
        (checkedChange)="onSelectCUR(cur.id, $event)"
        [disabled]="form.controls.allowAllCourseUnitRealisations.value"
        class="me-4 mb-0"
      />
      <fudis-link externalLink="/teacher/role/staff/teaching/course-unit-realisations/view/{{cur.id}}/information/basicinfo" [title]="cur | courseUnitRealisationFullName | localizedString" class="me-2"></fudis-link>
      <sis-tiny-badge *ngIf="productAllowedCURIds?.includes(cur.id)" variant="success">
        {{'OPENUNIVERSITY.STUDY_RIGHTS.ENROLMENT_RIGHT_EDIT_MODAL.OFFERED_IN_PRODUCT' | translate}}
      </sis-tiny-badge>
    </div>
  </ng-container>
</ng-container>
