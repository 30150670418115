import angular from 'angular';
export const commonStudentServiceModule = 'sis-components.service.studentService';
(function () {
  studentService.$inject = ["$http", "privatePersonInfoJSDataModel", "PrivatePersonEndpoint"];
  angular.module(commonStudentServiceModule, ['sis-components.model.privatePersonInfo', 'sis-components.search.searchParameters']).factory('commonStudentService', studentService);

  /**
   * @ngInject
   */
  function studentService($http, privatePersonInfoJSDataModel, PrivatePersonEndpoint) {
    return {
      findStudent: function (personId, bypassCache) {
        var options = {
          bypassCache: bypassCache === true
        };
        return privatePersonInfoJSDataModel.find(personId, options);
      },
      hasSearchFilterPermissions: function () {
        return $http.head(PrivatePersonEndpoint.SEARCH_STUDENTS, {
          bypassErrorInterceptor: true
        }).then(function () {
          return true;
        }).catch(function () {
          return false;
        });
      },
      search: function (searchParams) {
        var searchConfig = searchParams.build();
        if (searchConfig.params.fullTextQuery) {
          searchConfig.params.searchString = searchConfig.params.fullTextQuery;
        }
        return $http.get(PrivatePersonEndpoint.SEARCH_STUDENTS, searchConfig).then(result => result.data);
      },
      searchForCourseUnitRealisation: (courseUnitRealisationId, searchParams) => {
        const searchConfig = searchParams.build();
        if (searchConfig.params.fullTextQuery) {
          searchConfig.params.searchString = searchConfig.params.fullTextQuery;
        }
        return $http.get(`${PrivatePersonEndpoint.SEARCH_STUDENTS_FOR_CUR}/${courseUnitRealisationId}`, searchConfig).then(result => result.data);
      },
      searchByPersonalIdentityCode: function (searchParams) {
        var searchConfig = searchParams.build();
        if (searchConfig.params.fullTextQuery) {
          searchConfig.params.searchString = searchConfig.params.fullTextQuery;
        }
        return $http.get(PrivatePersonEndpoint.SEARCH_STUDENTS_BY_PERSONAL_IDENTITY_CODE, searchConfig).then(result => result.data);
      }
    };
  }
})();