import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { dateUtils } from 'common-typescript/src';
import { CooperationNetwork, CooperationNetworkDetails, CooperationNetworkSettings, CourseUnit, LocalDateRange, LocalDateTimeRange, UniversityOrganisation } from 'common-typescript/types';
import moment from 'moment';
import { forkJoin, map, Subject, take, takeUntil } from 'rxjs';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { Breakpoint, BreakpointService } from '../../service/breakpoint.service';
import { CooperationNetworkEntityService } from '../../service/cooperation-network-entity.service';
import { CooperationNetworkSettingsEntityService } from '../../service/cooperation-network-settings-entity.service';
import { OrganisationEntityService } from '../../service/organisation-entity.service';
import { UniversityService } from '../../service/university.service';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-course-unit-cross-study',
    templateUrl: './course-unit-cross-study.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CourseUnitCrossStudyComponent implements OnInit, OnDestroy {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.courseUnit.courseUnitCrossStudy.downgraded',
        directiveName: 'sisCourseUnitCrossStudy',
    };

    @Input() courseUnit: CourseUnit;
    /**
     * Determines heading's semantic aria-level for screen readers, default is equivalent for h3
     */
    @Input() level?: number = 3;

    destroyed$ = new Subject();
    validCooperationNetworks: CooperationNetwork[];
    currentUniversity: UniversityOrganisation;
    cooperationNetworkDetails: CooperationNetworkDetails;
    cooperationNetworkSettings: CooperationNetworkSettings[];
    isMobileView: boolean;

    constructor(
        private universityService: UniversityService,
        private cooperationNetworkEntityService: CooperationNetworkEntityService,
        private cooperationNetworkSettingsEntityService: CooperationNetworkSettingsEntityService,
        private organisationEntityService: OrganisationEntityService,
        private breakpointService: BreakpointService,
    ) {
    }

    ngOnInit(): void {
        this.currentUniversity = this.universityService.getCurrentUniversity();
        this.cooperationNetworkDetails = this.courseUnit?.cooperationNetworkDetails;
        if (this.cooperationNetworkDetails) {
            this.getValidCooperationNetworks();
        }

        this.breakpointService.breakpoint$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(breakpoint => this.isMobileView = (breakpoint < Breakpoint.MD));
    }

    ngOnDestroy() {
        this.destroyed$.unsubscribe();
    }

    getValidCooperationNetworks = () => {
        forkJoin([
            this.cooperationNetworkEntityService.getByUniversityOrgId(this.currentUniversity.id).pipe(take(1)),
            this.organisationEntityService.findUniversityRootOrganisation(this.currentUniversity.id).pipe(take(1)),
        ]).pipe(
            map((results) => this.filterAndSetValidNetworks(results[0], results[1])),
        ).subscribe();
    };

    filterAndSetValidNetworks = (networks: CooperationNetwork[], organisation: any) => {
        const organisationTkCode = this.organisationEntityService.getOrganisationTkCode(organisation);
        this.validCooperationNetworks = networks.filter(network =>
            this.networkIsOngoing(network)
            && this.networkIsListedForCourseUnitAndHasValidPeriod(network)
            && this.organizationBelongsToNetworkAndHasValidPeriod(network, organisationTkCode),
        );
        this.getCooperationNetworkSettings();
    };

    private getCooperationNetworkSettings = () => {
        if (!this.validCooperationNetworks?.length) return;
        // get settings for each cooperation network
        const settingRequests = this.validCooperationNetworks.map(network =>
            this.cooperationNetworkSettingsEntityService.getByCooperationNetworkId(this.currentUniversity.id, network.id),
        );

        forkJoin(settingRequests).subscribe(results => {
            this.cooperationNetworkSettings = results.filter(res => res);
        });
    };

    getNetworkDescription = (network: CooperationNetwork) => {
        const networkSettings = this.cooperationNetworkSettings?.find(setting => setting.cooperationNetworkId === network.id);
        return networkSettings?.homeUniversitySettings?.description;
    };

    private networkIsOngoing = (network: CooperationNetwork) => this.cooperationNetworkEntityService.getCooperationNetworkValidity(network) === 'ONGOING';

    private networkIsListedForCourseUnitAndHasValidPeriod = (network: CooperationNetwork) => this.cooperationNetworkDetails.networks?.some(item => (
        item.cooperationNetworkId === network.id && this.validityPeriodIsOngoing(item.validityPeriod)),
    );

    private organizationBelongsToNetworkAndHasValidPeriod(network: CooperationNetwork, organisationTkCode: any) {
        const match = network.organisations.find(org => org.organisationTkCode === organisationTkCode);
        if (match) {
            return this.validityPeriodIsOngoing(match.validityPeriod);
        }
        return false;
    }

    isCurrentUniversityCourse = () => this.courseUnit?.universityOrgIds.includes(this.currentUniversity.id);

    private validityPeriodIsOngoing = (validityPeriod: LocalDateRange | LocalDateTimeRange) => dateUtils.rangeContains(moment(), validityPeriod);
}
