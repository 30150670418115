import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { OtmId, TuitionFeeObligationPeriod } from 'common-typescript/types';
import { Subscription } from 'rxjs';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { TuitionFeeObligationPeriodEntityService } from '../../service/tuition-fee-obligation-period.entity.service';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-readonly-tuition-fee-periods',
    templateUrl: './readonly-tuition-fee-obligation-period.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ReadonlyTuitionFeeObligationPeriodComponent implements OnInit, OnDestroy {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.read-only-tuition-fee-periods.downgraded',
        directiveName: 'sisReadonlyTuitionFeePeriods',
    };

    @Input() studyRightId: OtmId;
    tuitionFeeObligationPeriods: TuitionFeeObligationPeriod[];
    subscription = Subscription.EMPTY;

    constructor(
        private tuitionFeeObligationPeriodEntityService: TuitionFeeObligationPeriodEntityService,
    ) {}

    ngOnInit(): void {
        this.subscription = this.tuitionFeeObligationPeriodEntityService.findForStudyRight(this.studyRightId)
            .subscribe((periods) => {
                this.tuitionFeeObligationPeriods = periods;
            });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
