<sis-description-list>
  <dl>
    <ng-template #listItem>
      <dt class="sis-label">{{ 'PROFILE.ATTAINED_DEGREES' | translate }}</dt>
      <dd *ngIf="degreeProgrammeAttainments.length">
        <ul class="no-list-style-type">
          <li *ngFor="let attainment of degreeProgrammeAttainments" data-cy="study-right-attained-degree-title">
            {{ attainment.degreeTitleUrn|codeLocalizedName|async }}<ng-container
            *ngIf="attainment.attainmentDate as graduationDate">, {{ graduationDate | localDateFormat }}</ng-container>
            <sis-icon icon="achievement" color="success"/>
          </li>
        </ul>
      </dd>
      <dd *ngIf="!degreeProgrammeAttainments.length">{{ 'PROFILE.NO_ATTAINED_DEGREES' | translate }}</dd>
    </ng-template>
  </dl>
</sis-description-list>
