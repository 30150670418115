import angular from 'angular';
(function () {
  ruleSelect.$inject = ["$compile", "directivesByType"];
  angular.module('student.plan.rules').value('directivesByType', {
    CreditsRule: 'credits-rule',
    CourseUnitCountRule: 'course-unit-count-rule',
    CompositeRule: 'composite-rule',
    ModuleRule: 'module-rule',
    CourseUnitRule: 'course-unit-rule',
    AnyCourseUnitRule: 'any-course-unit-rule',
    AnyModuleRule: 'any-module-rule'
  }).directive('ruleSelect', ruleSelect);
  function ruleSelect($compile, directivesByType) {
    return {
      restrict: 'E',
      scope: {
        module: '<',
        rule: '<',
        validatablePlan: '<',
        planValidationResult: '<',
        educationOptions: '<',
        studyRight: '<',
        openCustomStudyDraftInfoModalCallback: '&'
      },
      template: 'Loading...',
      link: function (scope, element) {
        let childScope;
        scope.$watch('module.id', recompile);
        scope.openCustomStudyDraftInfo = customStudyDraft => {
          scope.openCustomStudyDraftInfoModalCallback({
            customStudyDraft
          });
        };
        function recompile() {
          const directive = directivesByType[scope.rule.type];
          let html = '<' + directive + ' plan-validation-result="planValidationResult" validatable-plan="validatablePlan" module="module" rule="rule" education-options="educationOptions" study-right="studyRight"';
          html += ' open-custom-study-draft-info-modal-callback="openCustomStudyDraftInfo(customStudyDraft)"';
          html += '></' + directive + '>';
          if (childScope) {
            childScope.$destroy();
          }
          childScope = scope.$new();
          element.empty();
          element.append($compile(html)(childScope));
        }
      }
    };
  }
})();