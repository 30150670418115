import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from 'sis-common/modal/modal.service';

/**
 * Modal content.
 */
export interface WorkflowCancellationInfoModalValues {
    /** Modal title, shown as a heading. */
    title: string;
    /** Modal description, shown as a paragraph. */
    description: string;
    /** Cancellation date, shown in a description list component. */
    cancelTime: string;
    /** Cancellation reason, shown in a description list component. */
    cancelReason: string;
}

@Component({
    selector: 'app-workflow-cancellation-info-modal',
    templateUrl: './workflow-cancellation-info-modal.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class WorkflowCancellationInfoModalComponent {
    constructor(@Inject(ModalService.injectionToken) public values: WorkflowCancellationInfoModalValues,
                public modalInstance: NgbActiveModal) {}
}
