cancelApplicationModalController.$inject = ["defaultPromiseHandler", "commonStudentApplicationService"];
cancelApplicationModalService.$inject = ["modalService"];
import angular from 'angular';
import { ModalService } from 'sis-common/modal/modal.service.ts';
import cancelApplicationModalTpl from './cancelApplicationModal.tpl.html';
import { CancelApplicationModalComponent } from './cancelApplicationModal.component.ts';
angular.module('student.profile.applications.cancelApplicationModal', ['sis-common.l10n.localeService', 'sis-components.service.studentApplicationService', 'sis-common.errorhandler.errorService', ModalService.downgrade.moduleName])
/**
 * @deprecated use application-modal.service.ts
 */.factory('cancelApplicationModalService', cancelApplicationModalService).controller('cancelApplicationModalController', cancelApplicationModalController).component('cancelApplicationModal', {
  bindings: {
    close: '&',
    dismiss: '&',
    resolve: '<'
  },
  template: cancelApplicationModalTpl,
  controller: cancelApplicationModalController
});
function cancelApplicationModalService(modalService) {
  return {
    open: application => modalService.open(CancelApplicationModalComponent, {
      application
    }, {
      size: 'sm'
    }).result
  };
}
function cancelApplicationModalController(defaultPromiseHandler, commonStudentApplicationService) {
  const $ctrl = this;
  $ctrl.$onInit = () => {
    $ctrl.application = $ctrl.resolve.application;
  };
  $ctrl.confirm = () => {
    commonStudentApplicationService.cancel($ctrl.application).then(response => {
      $ctrl.close({
        $value: {
          cancelled: response.state === 'CANCELLED'
        }
      });
    }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
  };
}