import * as angular from 'angular';
export const highlightResultFilterModule = 'sis-components.object.highlightResultFilter';
(function () {
  angular.module(highlightResultFilterModule, []).filter('highlightResult', highlightResult);
  function highlightResult() {
    function escapeRegexp(queryToEscape) {
      return ('' + queryToEscape).replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
    }
    return function (matchItem, query) {
      return query && matchItem ? ('' + matchItem).replace(new RegExp(escapeRegexp(query), 'gi'), '<mark>$&</mark>') : matchItem;
    };
  }
})();