import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Attainment } from 'common-typescript/types';
import { ModalService } from 'sis-common/modal/modal.service';

export interface AttainmentMisregistrationRationaleValues {
    attainment: Attainment;
}

@Component({
    selector: 'sis-attainment-misregistration-rationale-modal',
    template: '<attainment-misregistration-rationale-modal (close)="activeModal.close()" [resolve]="values"></attainment-misregistration-rationale-modal>',
    encapsulation: ViewEncapsulation.None,
})
export class AttainmentMisregistrationRationaleModalComponent {
    constructor(@Inject(ModalService.injectionToken) public values: AttainmentMisregistrationRationaleValues,
                public activeModal: NgbActiveModal) {}
}
