import * as _ from 'lodash';

import {
    AttainmentGroupNode,
    AttainmentNode,
    AttainmentReferenceNode,
    AttainmentType,
    CompositeRule,
    CourseUnit,
    CourseUnitCountRule,
    CourseUnitRule,
    CourseUnitSubstitution,
    CreditsRule,
    CustomCourseUnitAttainment,
    CustomModuleAttainment,
    DegreeProgrammeAttainment,
    Module,
    ModuleAttainment,
    ModuleRule,
    OtmId,
    Rule,
    StudentApplication,
} from '../../types';

const MAX_PRECEDENCE_INDEX = 9999;

function getLeafNodes(nodes: AttainmentNode[]): AttainmentReferenceNode[] {
    if (_.isEmpty(nodes)) {
        return [];
    }

    let leafNodes = [...nodes].filter(notNil);
    while (leafNodes.some(({ type }) => type === 'AttainmentGroupNode')) {
        leafNodes = leafNodes
            .map(node => node.type === 'AttainmentGroupNode' ? (node as AttainmentGroupNode).nodes : node)
            .flat()
            .filter(notNil);
    }

    return leafNodes as AttainmentReferenceNode[];
}

export function getAllLeafNodes(attainment: ModuleAttainment | DegreeProgrammeAttainment | CustomModuleAttainment): AttainmentReferenceNode[] {
    const { nodes = [] } = attainment || {};
    return getLeafNodes(nodes).filter(({ attainmentId }) => attainmentId !== attainment.id);
}

export function getAllRules(rule: Rule): Rule[] {
    if (_.isNil(rule)) {
        return [];
    }

    let subRules: Rule[] = [];
    if (['CourseUnitCountRule', 'CreditsRule'].includes(rule.type)) {
        subRules = getAllRules((rule as CourseUnitCountRule | CreditsRule).rule);
    }
    if (rule.type === 'CompositeRule') {
        subRules = ((rule as CompositeRule).rules || []).map(getAllRules).flat();
    }

    return [rule, ...subRules].filter(notNil);
}

export function getCourseUnitPrecedence(rules: Rule[], courseUnit: CourseUnit): number {
    if (_.isNil(courseUnit)) {
        return MAX_PRECEDENCE_INDEX;
    }

    let matchingRuleIndex = (rules || [])
        .findIndex(rule => rule.type === 'CourseUnitRule' && (rule as CourseUnitRule).courseUnitGroupId === courseUnit.groupId);
    if (matchingRuleIndex === -1) {
        matchingRuleIndex = _.get(courseUnit, 'index', -1);
    }

    return matchingRuleIndex < 0 ? MAX_PRECEDENCE_INDEX : matchingRuleIndex;
}

export function getModulePrecedence(rules: Rule[], module: Module): number {
    if (_.isEmpty(rules) || _.isNil(module)) {
        return MAX_PRECEDENCE_INDEX;
    }

    let matchingRuleIndex = rules
        .findIndex(rule => rule.type === 'ModuleRule' && (rule as ModuleRule).moduleGroupId === module.groupId);
    if (matchingRuleIndex === -1) {
        matchingRuleIndex = rules.findIndex(({ type }) => type === 'AnyModuleRule');
    }

    return matchingRuleIndex < 0 ? MAX_PRECEDENCE_INDEX : matchingRuleIndex;
}

export function isCustomAttainment(attainment: any): attainment is (CustomCourseUnitAttainment | CustomModuleAttainment) {
    return [AttainmentType.CUSTOM_COURSE_UNIT_ATTAINMENT, AttainmentType.CUSTOM_MODULE_ATTAINMENT]
        .includes(_.get(attainment, 'type'));
}

/** This helper is mainly meant to distinguish between Educations and the other EntityWithRule subclasses (i.e. Modules). */
export function isModule(module: any): module is Module {
    return ['DegreeProgramme', 'StudyModule', 'GroupingModule'].includes(module?.type);
}

export function isModuleAttainment(attainment: any): attainment is (ModuleAttainment | DegreeProgrammeAttainment) {
    return [AttainmentType.MODULE_ATTAINMENT, AttainmentType.DEGREE_PROGRAMME_ATTAINMENT]
        .includes(_.get(attainment, 'type'));
}

export function isModuleLikeAttainment(attainment: any): attainment is (ModuleAttainment | DegreeProgrammeAttainment | CustomModuleAttainment) {
    return [AttainmentType.MODULE_ATTAINMENT, AttainmentType.DEGREE_PROGRAMME_ATTAINMENT, AttainmentType.CUSTOM_MODULE_ATTAINMENT]
        .includes(_.get(attainment, 'type'));
}

export function notNil<T>(value: T | null | undefined): value is T {
    return !_.isNil(value);
}

export function substitutionMatches(courseUnitSubstitutions: CourseUnitSubstitution[], substitutedByGroupIds: OtmId[]): boolean {
    if (_.isEmpty(courseUnitSubstitutions) || _.isEmpty(substitutedByGroupIds)) {
        return false;
    }

    const courseUnitGroupIds = courseUnitSubstitutions.map(s => s.courseUnitGroupId);
    return courseUnitGroupIds.length === substitutedByGroupIds.length &&
        _.isEmpty(_.difference(courseUnitGroupIds, substitutedByGroupIds));
}

export function isApplicationEffective(application: StudentApplication): boolean {
    return ['REQUESTED', 'IN_HANDLING', 'ACCEPTED', 'CONDITIONAL'].includes(application.state);
}
