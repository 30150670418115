import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-unauthorized',
    templateUrl: './unauthorized.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class UnauthorizedComponent {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.lib.unauthorized',
        directiveName: 'sisUnauthorized',
    };

    @Input() hideTitle: boolean;

}
