import { Directive, ElementRef, Injector, Input, Output } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

import { ShowModuleContentApplicationValues } from './showModuleContentApplicationModal.component';

/**
 * @deprecated
 */
// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'show-module-content-application-modal' })
export class ShowModuleContentApplicationModalDirective extends UpgradeComponent {

    @Output() close: ($value?: any) => void;
    @Output() dismiss: () => void;
    @Input() resolve: ShowModuleContentApplicationValues;

    constructor(elementRef: ElementRef<any>, injector: Injector) {
        super('showModuleContentApplicationModal', elementRef, injector);
    }
}
