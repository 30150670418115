import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PriorLearning, PriorStudies } from 'common-typescript/types';

import { STUDENT_WORKFLOW_APPLICATION_TYPE, STUDENT_WORKFLOW_TYPE } from '../../model/student-workflow-constants';

function isStudies(priorLearning: PriorLearning): priorLearning is PriorStudies {
    return priorLearning?.type === 'STUDIES';
}

@Pipe({ name: 'priorLearningName' })
export class PriorLearningNamePipe implements PipeTransform {

    private readonly ALLOWED_APPLICATION_TYPES: string[] = [
        STUDENT_WORKFLOW_APPLICATION_TYPE.PRIOR_LEARNING_INCLUSION_APPLICATION,
        STUDENT_WORKFLOW_APPLICATION_TYPE.PRIOR_LEARNING_SUBSTITUTION_APPLICATION,
        STUDENT_WORKFLOW_TYPE.PRIOR_LEARNING_INCLUSION_WORKFLOW,
        STUDENT_WORKFLOW_TYPE.PRIOR_LEARNING_SUBSTITUTION_WORKFLOW,
    ];

    constructor(private translate: TranslateService) {}

    /**
     * Returns a name for a PriorLearning object, which is based on the type of the application that contains
     * the object, and the user-provided name of the object.
     *
     * @param priorLearning The PriorLearning object whose name should be resolved
     * @param applicationType The type of the containing application (inclusion or substitution)
     * @param index When displaying a list of objects, the list index can be provided, which will cause
     * the returned values to be prefixed with a human-readable index (i.e. given value + 1)
     */
    transform(priorLearning: PriorLearning, applicationType: string, index?: number): string {
        if (!priorLearning?.name || !priorLearning?.type || !this.ALLOWED_APPLICATION_TYPES.includes(applicationType)) {
            return null;
        }

        const { name, type } = priorLearning;
        const prefix = (Number.isSafeInteger(index) && index >= 0) ? `${index + 1}. ` : '';

        const shortApplicationType =
            // Currently both old application & new workflow values are used in different places.
            // STUDENT_WORKFLOW_APPLICATION_TYPE.PRIOR_LEARNING_INCLUSION_APPLICATION should be removed from here when it is no longer used anywhere
            applicationType === STUDENT_WORKFLOW_APPLICATION_TYPE.PRIOR_LEARNING_INCLUSION_APPLICATION ||
            applicationType === STUDENT_WORKFLOW_TYPE.PRIOR_LEARNING_INCLUSION_WORKFLOW ?
                'INCLUDE' : 'SUBSTITUTE';
        const typeName = this.translate.instant(`STUDENT_APPLICATIONS.PRIOR_LEARNING.${shortApplicationType}_${type}.TITLE_PREVIEW`);
        const code = (isStudies(priorLearning) && priorLearning.code) ? `, ${priorLearning.code}` : '';

        return `${prefix}${typeName}: ${name}${code}`;
    }
}
