<ng-container *transloco="let t">
  <fudis-expandable [title]="t('FREE_EDIT.MODULE_EXPANDABLE.TITLE')"
                    [closed]="false">
    <ng-template fudisContent [type]="'expandable'">
      <fudis-heading [level]="3">{{t('FREE_EDIT.MODULE_EXPANDABLE.ADD_MODULES')}}</fudis-heading>
      <label for="add_module">{{t('FREE_EDIT.MODULE_EXPANDABLE.SEARCH_MODULES_LABEL')}}</label>
      <sis-typeahead id="add_module"
                     (selectItem)="selectModule.emit($event.item)"
                     [typeahead]="doSearch.bind(this)"
                     [placeholder]="t('FREE_EDIT.MODULE_EXPANDABLE.SEARCH_MODULES_PLACEHOLDER')"
                     [resultTemplate]="moduleSearchTemplate">
      </sis-typeahead>
      <p class="help-block">{{t('FREE_EDIT.MODULE_EXPANDABLE.SEARCH_MODULES_HELP_TEXT')}}</p>
      @for (module of allModules(); track module.id) {
        <div class="sis-pt-sm">
          <sis-plan-structure-study-module-box [module]="module"
                                               [planStateObject]="planStateObject()"
                                               [planData]="planData()"
                                               [headingLevel]="4"
                                               [action]="'select-to-plan'"
                                               [selected]="validatablePlan().isModuleInPlan(module.id)"
                                               (actionChange)="toggleModule.emit(
                                             {
                                                module: module,
                                                isInPlan: validatablePlan().isModuleInPlan(module.id)
                                             })">
          </sis-plan-structure-study-module-box>
        </div>
      }
    </ng-template>
  </fudis-expandable>

  <ng-template #moduleSearchTemplate let-result="result">
    <div class="module-search__label">
      <p class="module-search__label__main">
        <span>{{ result.name }}</span>
        <span *ngIf="result?.credits">{{' (' + (result.credits | creditRange) + ')'}}</span>
      </p>
      <p class="module-search__label__sub">{{ result.code }} | {{ t('FREE_EDIT.MODULE_EXPANDABLE.MODULE') }}</p>
    </div>
  </ng-template>
</ng-container>
