'use strict';

angular.module('student.timing.timingViewStateService', ['student.common.utils.uiStateStore', 'student.timing.constant']).factory('timingViewStateService', ["timelineConfig", "uiStateStore", function (timelineConfig, uiStateStore) {
  var api = {
    getInitState: function () {
      return {
        untimedCourseUnitsFocused: uiStateStore.readField(timelineConfig.store.PREFIX, timelineConfig.store.FOCUS_UNTIMED),
        type: timelineConfig.viewStates.DEFAULT,
        showAllPeriods: uiStateStore.readField(timelineConfig.store.PREFIX, timelineConfig.store.SHOW_ALL_PERIODS_KEY, false),
        zoomLevel: uiStateStore.readField(timelineConfig.store.PREFIX, timelineConfig.store.ZOOM_KEY, 2)
      };
    },
    setZoom: function (state, zoomStep) {
      var zoomLevel = state.zoomLevel;
      state.zoomLevel = Math.max(Math.min(zoomLevel + zoomStep, timelineConfig.SCALES.length - 1), 0);
      uiStateStore.storeField(timelineConfig.store.PREFIX, timelineConfig.store.ZOOM_KEY, state.zoomLevel);
    },
    toggleShowAllPeriods: function (state) {
      state.showAllPeriods = !state.showAllPeriods;
      uiStateStore.storeField(timelineConfig.store.PREFIX, timelineConfig.store.SHOW_ALL_PERIODS_KEY, state.showAllPeriods);
    },
    startTimingForNote: function (state, note) {
      state.type = timelineConfig.viewStates.EDIT_NOTE;
      state.activeNote = note;
    },
    startMovingNoteTiming: function (state, note) {
      state.type = timelineConfig.viewStates.MOVE_NOTE_TIMING;
      state.activeNote = note;
    },
    startTimingForCourseUnit: function (state, courseUnit) {
      api.clearCurrentActiveReferences(state);
      state.type = timelineConfig.viewStates.TIME_COURSE_UNIT;
      api.setFocusUntimedCourseUnitFalse(state);
      state.activeCourseUnit = courseUnit;
    },
    startTimingForCustomStudyDraft: function (state, customStudyDraft) {
      api.clearCurrentActiveReferences(state);
      state.type = timelineConfig.viewStates.TIME_CUSTOM_STUDY_DRAFT;
      api.setFocusUntimedCourseUnitFalse(state);
      state.activeCustomStudyDraft = customStudyDraft;
    },
    startUnTimingFromTimeline: function (state) {
      state.type = timelineConfig.viewStates.UNTIME_COURSE_UNIT;
    },
    startMoveCourseUnitTiming: function (state, courseUnit) {
      state.type = timelineConfig.viewStates.MOVE_COURSE_UNIT_TIMING;
      api.setFocusUntimedCourseUnitFalse(state);
      state.activeCourseUnit = courseUnit;
    },
    startMoveCustomStudyDraftTiming: function (state, customStudyDraft) {
      state.type = timelineConfig.viewStates.MOVE_CUSTOM_STUDY_DRAFT_TIMING;
      api.setFocusUntimedCourseUnitFalse(state);
      state.activeCustomStudyDraft = customStudyDraft;
    },
    startUnTimingForDegreeProgramme: function (state) {
      state.type = timelineConfig.viewStates.UNTIME_MULTIPLE;
      api.setFocusUntimedCourseUnitFalse(state);
    },
    startIntroducingTimingTemplate: function (state) {
      state.type = timelineConfig.viewStates.INTRODUCE_TIMING_TEMPLATE;
    },
    onEndTiming: function (state) {
      state.type = timelineConfig.viewStates.VIEW;
      api.clearCurrentActiveReferences(state);
    },
    clearCurrentActiveReferences: state => {
      state.activeNote = undefined;
      state.activeCourseUnit = undefined;
      state.activeCustomStudyDraft = undefined;
    },
    startCreateTimeLineNote: function (state) {
      state.type = timelineConfig.viewStates.ADD_NOTE;
    },
    toggleFocusUntimedCourseUnit: function (state) {
      state.untimedCourseUnitsFocused = !_.get(state, 'untimedCourseUnitsFocused', false);
      uiStateStore.storeField(timelineConfig.store.PREFIX, timelineConfig.store.FOCUS_UNTIMED, state.untimedCourseUnitsFocused);
    },
    setFocusUntimedCourseUnitFalse: function (state) {
      state.untimedCourseUnitsFocused = false;
      uiStateStore.storeField(timelineConfig.store.PREFIX, timelineConfig.store.FOCUS_UNTIMED, state.untimedCourseUnitsFocused);
    }
  };
  return api;
}]);