import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModuleContentApplication, OtmId } from 'common-typescript/types';
import { ModalService } from 'sis-common/modal/modal.service';

export interface CommonShowModuleContentApplicationValues {
    approvalRequest: ModuleContentApplication;
    studentId: OtmId;
}

@Component({
    selector: 'sis-common-show-module-content-application-modal',
    template: '<common-show-module-content-application-modal (close)="activeModal.close()" [resolve]="values"></common-show-module-content-application-modal>',
    encapsulation: ViewEncapsulation.None,
})
export class CommonShowModuleContentApplicationModalComponent {
    constructor(@Inject(ModalService.injectionToken) public values: CommonShowModuleContentApplicationValues,
                public activeModal: NgbActiveModal) {}
}
