<ng-container *transloco="let t">
    <div *ngIf="this.invalidModulesSorted" >
        <fudis-notification [variant]="'danger'">
            <fudis-body-text>
                {{ t('PLAN.STATE.INVALID_SELECTIONS_IN_PLAN') }}
            </fudis-body-text>
            <ul>
                <li class="sis-mt-xxs" *ngFor="let item of this.invalidModulesSorted">
                    <fudis-link
                            (handleClick)="handleFocusClick($event, item.focusId)"
                            [size]="'md'"
                            [href]="'#'"
                            [title]="t('PLAN.STATE.INVALID_SELECTIONS_IN_PLAN_MODULE_TEXT', {name: item.name})"
                    />
                </li>
            </ul>
        </fudis-notification>
    </div>
</ng-container>
