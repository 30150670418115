import attainmentTreeChildTpl from './attainmentTreeChild.tpl.html';
'use strict';
(function () {
  angular.module('sis-components.attainment.tree').component('attainmentTreeChild', {
    template: attainmentTreeChildTpl,
    bindings: {
      row: '<',
      onAttainmentClick: '&',
      attainmentsDisplayed: '<',
      attainmentEditActionSelected: '&',
      showMenuButton: '<'
    }
  });
})();