'use strict';

(function () {
  moduleLoaderFactory.$inject = ["moduleService", "cacheUtils", "previewMode"];
  angular.module('student.common.context.plan-context').factory('moduleLoader', moduleLoaderFactory);
  function moduleLoaderFactory(moduleService, cacheUtils, previewMode) {
    return {
      loadModules: function (rootId, moduleAttainments, moduleSelections, modulesById) {
        var unloadedModuleIds = cacheUtils.purgeCacheAndGetUnloadedIds(modulesById, _.map(moduleAttainments, 'moduleId'), _.map(moduleSelections, 'moduleId'));
        if (previewMode.isPreviewMode()) {
          unloadedModuleIds.push(rootId);
          return moduleService.findByIds(unloadedModuleIds);
        } else {
          return moduleService.findByIds(_.without(unloadedModuleIds, rootId)).then(function (modules) {
            return moduleService.getEducationById(rootId).then(function (education) {
              modules.push(education);
              return modules;
            });
          });
        }
      }
    };
  }
})();