export const jsDataCacheHelperModule = 'sis-components.service.jsDataCacheHelper';
/* globals angular, _ */
(function () {
  jsDataCacheHelper.$inject = ["$q", "RequestSettings"];
  angular.module(jsDataCacheHelperModule, []).factory('SearchByIds', SearchByIds).factory('jsDataCacheHelper', jsDataCacheHelper).constant('RequestSettings', {
    MAX_PARAMETER_LENGTH: 2000
  });

  /**
   * @ngInject
   */
  function jsDataCacheHelper($q, RequestSettings) {
    var api = {
      searchByIds: function (searchByIds) {
        return api.findByIds(searchByIds.jsDataModel, searchByIds.ids, searchByIds.clear, searchByIds.idRequestParameterName, searchByIds.endpoint, searchByIds.extraRequestParameters);
      },
      findByIds: function (jsDataModel, ids, clear, idRequestParameterName, endpoint, extraParameters) {
        ids = _.uniq(_.compact(ids));
        if (!jsDataModel || _.isEmpty(ids)) {
          return $q.when([]);
        }
        const cachedObjects = [];
        let idsForNotCachedObjects = [];
        if (clear) {
          idsForNotCachedObjects = ids;
        } else {
          _.forEach(ids, id => {
            const cachedObject = jsDataModel.get(id);
            if (cachedObject) {
              cachedObjects.push(cachedObject);
            } else {
              idsForNotCachedObjects.push(id);
            }
          });
        }
        if (idsForNotCachedObjects.length > 0) {
          var options = {};
          if (clear) {
            options.bypassCache = true;
          }
          if (endpoint) {
            options.endpoint = endpoint;
          }
          var idParam = idRequestParameterName || 'id';
          var idsString = idsForNotCachedObjects.toString();
          var commaIndex = 0;
          var findAllRequest = [];
          while (idsString.length > 0) {
            commaIndex = idsString.length > RequestSettings.MAX_PARAMETER_LENGTH ? idsString.lastIndexOf(',', RequestSettings.MAX_PARAMETER_LENGTH) : idsString.length;
            var idSubset = idsString.substr(0, commaIndex);
            idsString = idsString.substr(commaIndex + 1);
            var params = _.cloneDeep(extraParameters || {});
            params[idParam] = idSubset.split(',');
            findAllRequest.push(jsDataModel.findAll(params, options));
          }
          return $q.all(findAllRequest).then(function (responses) {
            responses.push(cachedObjects);
            return [].concat.apply([], responses);
          });
        } else {
          return $q.when(cachedObjects);
        }
      }
    };
    return api;
  }

  /**
   * @ngInject
   */
  function SearchByIds() {
    return function (jsDataModel) {
      this.jsDataModel = jsDataModel;
      this.ids = undefined;
      this.clear = undefined;
      this.idRequestParameterName = undefined;
      this.endpoint = undefined;
      this.extraRequestParameters = undefined;
    };
  }
})();