<ng-container *transloco="let t">
  <sis-contextual-notification *ngIf="enrolment.state === 'NOT_ENROLLED'" variant="warning">{{ t('ENROLMENTS.BASIC_INFO.ENROLMENT_MISSING') }}</sis-contextual-notification>
  <ng-container *ngIf="this.personCalculationConfig | async as calculationConfigForPerson">
    <div class="top-spacing" *ngIf="(enrolment.state === 'REJECTED' || enrolment.processingState === 'CURRENTLY_NOT_SELECTED') && noFalseRequirements(calculationConfigForPerson.requirementRules)">
      <sis-icon [icon]="'fail'"
                [color]="'danger'">
      </sis-icon>
      {{ t('ENROLMENTS.BASIC_INFO.NOT_SELECTED_NO_AVAILABLE_SEATS') }}
    </div>
  </ng-container>
  <div class="top-spacing" *ngIf="enrolmentCalculationConfig$ | async as enrolmentCalculationConfig">
    <sis-course-unit-realisation-seats [enrolmentCalculationConfig]="enrolmentCalculationConfig"></sis-course-unit-realisation-seats>
  </div>
  <sis-description-list *ngIf="enrolment.studyRightId" [columnCount]="1">
    <dl>
      <ng-template #listItem>
        <dt>{{ t('ENROLMENTS.BASIC_INFO.STUDY_RIGHT') }}</dt>
        <dd>{{ enrolment.studyRightId | studyRightName | async | localizedString}}</dd>
      </ng-template>
    </dl>
  </sis-description-list>
  <app-enrolment-requirement-rules *ngIf="enrolment.state !== 'NOT_ENROLLED'" [enrolment]="this.enrolment"></app-enrolment-requirement-rules>
  <div *ngIf="enrolmentCalculationConfig$ | async as enrolmentCalculationConfig">
    <fudis-link *ngIf="enrolmentCalculationConfig?.orderingPersonRules?.length > 0" externalLink="/student/courseunit/{{enrolment.courseUnitId}}/completion-methods?courseUnitRealisation={{enrolment.courseUnitRealisationId}}&tabId=1" [title]="'ENROLMENTS.ENROLMENT_MODAL.SORTING_RULES_LINK' | translate"></fudis-link>
  </div>
  <ng-container *ngIf="courseUnitRealisation$ | async as courseUnitRealisation">
    <div>
      <fudis-link *ngIf="courseUnitRealisation?.learningEnvironments?.length > 0" externalLink="/student/courseunit/{{enrolment.courseUnitId}}/completion-methods?courseUnitRealisation={{enrolment.courseUnitRealisationId}}&tabId=0" [title]="'ENROLMENTS.ENROLMENT_MODAL.LEARNING_ENVIRONMENTS_LINK' | translate"></fudis-link>
    </div>
    <div>
      <fudis-link *ngIf="isCrossStudy(courseUnitRealisation)" [externalLink]="'/student/ripa-enrolment-information'" [title]="'ENROLMENTS.ENROLMENT_MODAL.CROSS_STUDY.CONSENT_LINK' | translate"></fudis-link>
    </div>
  </ng-container>
</ng-container>
