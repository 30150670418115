import { inject, Injectable } from '@angular/core';
import { ValidatablePlan } from 'common-typescript';
import { CourseUnit, EntityWithRule } from 'common-typescript/types';
import _ from 'lodash';

import { COMMON_PLAN_SELECTION_SERVICE, RULE_SERVICE } from '../ajs-upgraded-modules';

import { AttainmentEntityService } from './attainment-entity.service';
import { PlanStateObject } from './plan-state.service';

@Injectable({
    providedIn: 'root',
})
export class PlanRuleSelectionService {

    attainmentEntityService: AttainmentEntityService = inject(AttainmentEntityService);
    ruleService: any = inject(RULE_SERVICE);
    commonPlanSelectionService: any = inject(COMMON_PLAN_SELECTION_SERVICE);

    isModuleSelectionDisabled(module: EntityWithRule, parentModule: EntityWithRule, validatablePlan: ValidatablePlan): boolean {
        if (validatablePlan.isModuleAttained(parentModule.id)) {
            return true;
        }
        let currentParent = validatablePlan.getParentModuleOrCustomModuleAttainmentForModule(module);
        if (!currentParent) {
            const moduleSelection = validatablePlan.getModuleSelection(module.id);
            if (_.get(moduleSelection, 'parentModuleId') === validatablePlan.rootModule.id) {
                currentParent = validatablePlan.rootModule;
            }
        }
        if (currentParent && (currentParent.type === 'CustomModuleAttainment' || validatablePlan.isModuleAttained(currentParent.id))) {
            return true;
        }
        const moduleAttainment = validatablePlan.getModuleAttainment(module.id);
        if (moduleAttainment && this.attainmentEntityService.isAttached(moduleAttainment, _.values(validatablePlan.getAllAttainments()))) {
            return true;
        }
        if (parentModule.id === module.id) {
            return true;
        }
        if (currentParent) {
            return this.ruleService.isRequiredModule(currentParent, module);
        }

        return false;
    }

    isCourseUnitSelectionDisabled(courseUnit: CourseUnit, parentModule: EntityWithRule, validatablePlan: ValidatablePlan, planStateObject: PlanStateObject): boolean {
        if (this.isImplicit(courseUnit, parentModule, planStateObject)) {
            return !this.canImplicitlySelectedCourseUnitBeMovedToTargetModule(courseUnit, parentModule, validatablePlan, planStateObject);
        }
        if (validatablePlan.isModuleAttained(parentModule.id)) {
            return true;
        }
        const currentParent = validatablePlan.getParentModuleOrCustomModuleAttainmentForCourseUnit(courseUnit);
        if (currentParent && (currentParent.type === 'CustomModuleAttainment' || validatablePlan.isModuleAttained(currentParent.id))) {
            return true;
        }
        const courseUnitAttainment = validatablePlan.getCourseUnitAttainment(courseUnit.id);
        if (courseUnitAttainment && this.attainmentEntityService.isAttached(courseUnitAttainment, _.values(validatablePlan.getAllAttainments()))) {
            return true;
        }
        if (validatablePlan.isCourseUnitInPlanAsSubstitute(courseUnit)) {
            return true;
        }
        if (currentParent) {
            return this.ruleService.isRequiredCourseUnit(currentParent, courseUnit);
        }
        return false;
    }

    isImplicit(courseUnit: CourseUnit, parentModule: EntityWithRule, planStateObject: PlanStateObject): boolean {
        const courseUnitStateObject = _.get(planStateObject.courseUnits, courseUnit.id);
        return courseUnitStateObject && courseUnitStateObject.isInPlan && courseUnitStateObject.selectedParentModuleId !== parentModule.id;
    }

    canImplicitlySelectedCourseUnitBeMovedToTargetModule(courseUnit: CourseUnit, targetModule: EntityWithRule, validatablePlan: ValidatablePlan, planStateObject: PlanStateObject): boolean {
        const courseUnitAttainment = validatablePlan.getCourseUnitAttainment(courseUnit.id);
        if (courseUnitAttainment && this.attainmentEntityService.isAttached(courseUnitAttainment, _.values(validatablePlan.getAllAttainments()))) {
            return false;
        }
        const courseUnitStateObject = _.get(planStateObject.courseUnits, courseUnit.id);
        if (courseUnitStateObject && courseUnitStateObject.isInPlanAsSubstitute) {
            return false;
        }
        if (this.commonPlanSelectionService.isImplicitCourseUnitObligatory(courseUnit, targetModule)) {
            return true;
        }
        const currentParentModule = validatablePlan.getParentModuleOrCustomModuleAttainmentForCourseUnit(courseUnit);
        if (currentParentModule && !this.commonPlanSelectionService.isImplicitCourseUnitObligatory(courseUnit, currentParentModule)) {
            return true;
        }
        return false;
    }

}
