import { ChangeDetectionStrategy, Component, inject, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'sis-modal-close-button',
    templateUrl: './modal-close-button.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalCloseButtonComponent {

    activeModal = inject(NgbActiveModal);

}
