import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-sort-by-indicator',
    templateUrl: './sort-by-indicator.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class SortByIndicatorComponent {
    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.sort.sortByIndicator',
        directiveName: 'sisSortByIndicator',
        dependencies: [],
    };

    @Input() current: string;
    @Input() column: string;
    @Input() color: string;
    @Input() order: 'asc' | 'desc';
}
