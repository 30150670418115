import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Grant } from 'common-typescript/types';

@Component({
    selector: 'app-study-year-grants',
    templateUrl: './study-year-grants.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class StudyYearGrantsComponent {

    @Input() set grants(grants: Grant[]) {
        this.activeGrants = (grants || []).filter(grant => !!grant && !grant.cancelled);
        this.cancelledGrants = (grants || []).filter(grant => !!grant && grant.cancelled);
    }

    @Input() level?: number = 3;

    activeGrants: Grant[] = [];
    cancelledGrants: Grant[] = [];
}
