import { Pipe, PipeTransform } from '@angular/core';
import { LocalizedString } from 'common-typescript/types';

import { LocaleService } from './locale.service';

@Pipe({ name: 'localizedString' })
export class LocalizedStringPipe implements PipeTransform {

    constructor(private localeService: LocaleService) { }

    transform(value: LocalizedString, language?: string): string {
        return this.localeService.localize(value, language);
    }
}
