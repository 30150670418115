<ng-container *transloco="let t">
  <button class="app-attainment-info non-button" (click)="clicked.emit(attainment)">
    <sis-icon
      class="app-attainment-info__icon"
      [icon]="attainment.state === 'FAILED' ? 'fail' : successIcon"
      [color]="attainment.state === 'FAILED' ? 'danger' : 'success'"
    />
    <div>
      <p class="app-attainment-info__description">
        <ng-container *ngIf="isAssessmentItemAttainment(attainment)">
          {{$any(attainment).assessmentItemId | assessmentItem | async | assessmentItemFullName | localizedString}},
        </ng-container>
        <ng-container *ngIf="isCourseUnitAttainment(attainment)">
          {{$any(attainment).courseUnitId | courseUnit | async | entityName | localizedString}},
        </ng-container>
        <ng-container *ngIf="isCustomCourseUnitAttainment(attainment)">
          {{$any(attainment).name | localizedString}},
        </ng-container>
        {{attainment.credits}} {{t('CREDITS')}},
        <span class="app-attainment-info__description__date">{{attainment.attainmentDate | localDateFormat}}</span>
      </p>
      <p class="app-attainment-info__description">
        {{t('FIELD_NAMES.grade')}}: {{attainment.gradeId | grade: attainment.gradeScaleId: 'ABBREVIATION' | async}} ({{ attainment.gradeId | grade:attainment.gradeScaleId:'NAME' | async }})
      </p>
    </div>
  </button>
</ng-container>
