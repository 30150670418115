<div class="row" *ngIf="outcomes || content || additional">
  <div class="col-md-12">
    <sis-description-list>
      <dl> <!-- This dl element is just to trick SonarQube, it won't actually end up in the final HTML at all -->
        <ng-template #listItem *ngIf="outcomes">
          <dt class="label">{{'STUDIES.COURSE_UNIT_INFO_MODAL.OUTCOMES' | translate}}</dt>
          <dd class="value">
            <sis-localized-markup-string-view [content]="this.outcomes" />
          </dd>
        </ng-template>
        <ng-template #listItem *ngIf="content">
          <dt class="label">{{'STUDIES.COURSE_UNIT_INFO_MODAL.CONTENT' | translate}}</dt>
          <dd class="value">
            <sis-localized-markup-string-view [content]="this.content" />
          </dd>
        </ng-template>
        <ng-template #listItem *ngIf="additional">
          <dt class="label">{{'STUDIES.COURSE_UNIT_INFO_MODAL.ADDITIONAL' | translate}}</dt>
          <dd class="value">
            <sis-localized-markup-string-view [content]="this.additional" />
          </dd>
        </ng-template>
      </dl>
    </sis-description-list>
  </div>
</div>
<div *ngIf="!outcomes && !content && !additional">
  <span>{{ 'SIS_COMPONENTS.COURSE_UNIT_REALISATION_INFO.NO_INFORMATION_GIVEN' | translate }}</span>
</div>
