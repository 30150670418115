<form class="reactive" (ngSubmit)="onSubmit()">
  <div class="modal-header">
    <h2 class="modal-title">{{'CUSTOM_STUDY_DRAFT_CREATE_MODAL.TITLE' | translate}}</h2>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <sis-text-field [label]="'CUSTOM_STUDY_DRAFT.NAME' | translate" [control]="name"></sis-text-field>
    </div>
    <div class="form-group">
      <label [class]="getLabelState(credits)" for="csd-credits">{{'CUSTOM_STUDY_DRAFT.CREDITS' | translate}}</label>
      <div class="row">
        <sis-text-field [control]="credits"
                        [showLabelIcon]="false"
                        id="csd-credits"
                        type="number"
                        class="col-4"
                        valueType="{{'CREDITS'}}">
        </sis-text-field>
      </div>
    </div>
    <div class="form-group">
      <sis-text-field [label]="'CUSTOM_STUDY_DRAFT.LOCATION' | translate" [control]="location"></sis-text-field>
    </div>
    <div class="form-group">
      <sis-text-field [label]="'CUSTOM_STUDY_DRAFT.DESCRIPTION' | translate"
                      [control]="description"
                      [textarea]="true">
      </sis-text-field>
    </div>
  </div>
  <div class="modal-footer">
    <sis-button [hollow]="true" (clicked)="onCancel()">{{'SIS_COMPONENTS.BUTTON.CANCEL' | translate}}</sis-button>
    <sis-button type="submit">{{'CUSTOM_STUDY_DRAFT_CREATE_MODAL.BUTTON_ADD' | translate}}</sis-button>
  </div>
</form>
