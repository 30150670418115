'use strict';

angular.module('student.frontpage.studyProgressGraph').filter('sortByRuleStructure', function () {
  /**
   * Example usage: ng-repeat="module in modules | sortByRuleStructure:education.rule"
   * @param array
   * @param object - rule structure
   * @param boolean - sort order switch
   */
  return function (modules, rule, reverse) {
    var moduleGroupIds = getIds(rule);
    var sorted = _.sortBy(modules, function (m) {
      return _.indexOf(moduleGroupIds, m.groupId);
    });
    if (reverse) {
      sorted.reverse();
    }
    return sorted;
  };
  function getIds(rule) {
    var ids;
    if (rule.rules) {
      ids = _.flatten(_.map(rule.rules, getIds));
    } else {
      ids = [rule.moduleGroupId];
    }
    return ids;
  }
});