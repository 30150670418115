'use strict';

(function () {
  angular.module('sis-components.object.displayUrn', ['sis-common.l10n.localizedStringFilter']).directive('displayUrn', displayUrn);

  /**
   * @ngInject
   */
  function displayUrn() {
    return {
      restrict: 'E',
      scope: {
        urn: '=',
        urnModel: '='
      },
      template: '{{value.name ? (value.name | localizedString) : ""}}',
      link: function (scope) {
        scope.$watch('urn', function (newValue) {
          if (newValue && scope.urnModel) {
            scope.value = scope.urnModel.get(newValue);
          } else {
            scope.value = {
              name: undefined
            };
          }
        });
      }
    };
  }
})();