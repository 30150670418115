import {
    ChangeDetectionStrategy,
    Component,
    Input,
    ViewEncapsulation,
} from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

import { SisFormControl } from './../sis-form-control';

export interface ValidationErrorSummaryDetail {
    inputLabel: string;
    inputId: string;
}

export function getValidationErrorSummaryDetail(control: FormControl<any> | AbstractControl | SisFormControl<any> | null, inputLabel: string, inputId: string): ValidationErrorSummaryDetail {
    if (control && control.invalid && control.enabled && control.errors) return { inputId, inputLabel };
}

@Component({
    selector: 'sis-validation-error-summary',
    templateUrl: './validation-error-summary.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidationErrorSummaryComponent {
    /* id of the focusable element */
    @Input() id?: string = 'validation-error-summary';
    @Input() description?: string;
    /* validationErrorDetails should be passed to component when form is submitted and then focus shifts to this summary component if there are errors.
    * The detail contains a label for the error title and id of the focusable element.
    * When using this, make sure that input id's and form control keys you pass really are unique in the used view/component */
    get validationErrorDetails(): ValidationErrorSummaryDetail[] { return this._validationErrorDetails; }
    @Input() set validationErrorDetails(validationErrorDetails: ValidationErrorSummaryDetail[]) {
        this._validationErrorDetails = validationErrorDetails;
        this.focusOnElement(this.id);
    }

    private _validationErrorDetails: ValidationErrorSummaryDetail[] = [];

    focusOnElement(id: string) {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView();
            element.focus();
        }
    }
}
