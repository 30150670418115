import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { Attainment, WorkflowDecision, WorkflowState, WorkflowType } from 'common-typescript/types';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { STUDENT_WORKFLOW_STATE } from '../../model/student-workflow-constants';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-attainment-workflow-decision-summary',
    templateUrl: './attainment-workflow-decision-summary.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttainmentWorkflowDecisionSummaryComponent {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.applications.attainment-workflow-decision-summary',
        directiveName: 'sisAttainmentWorkflowDecisionSummary',
    };

    @Input() workflowType: WorkflowType;
    @Input() workflowState: WorkflowState;
    @Input() workflowDecision: WorkflowDecision;
    @Input() attainment: Attainment;

    readonly studentWorkflowState = STUDENT_WORKFLOW_STATE;

    shouldShowDecisionState(workflowState: WorkflowState): boolean {
        return workflowState === STUDENT_WORKFLOW_STATE.ACCEPTED ||
            workflowState === STUDENT_WORKFLOW_STATE.REJECTED ||
            workflowState === STUDENT_WORKFLOW_STATE.ACCEPTANCE_REVOKED;
    }
}
