import angular from 'angular';
import searchFilterTpl from './searchFilterDateRange.tpl.html';
export const searchFilterDateRangeModule = 'sis-components.search.searchFilterDateRange';
angular.module(searchFilterDateRangeModule, []).component('searchFilterDateRange', {
  bindings: {
    clonedParameterModel: '=',
    filterName: '<',
    onDateRangeChange: '&',
    popoverIsOpen: '<',
    submitFilter: '&'
  },
  template: searchFilterTpl,
  controllerAs: 'ctrl',
  controller: ["$scope", function ($scope) {
    const ctrl = this;

    // Invoke callback methods that take arguments in a way that allows to keep the template file unchanged
    // (that used to call controller methods directly when UI Bootstrap was used)
    ctrl.toggleValue = (value, form, event) => ctrl.toggleValueCb()(value, form, event);
    $scope.$watch('ctrl.popoverIsOpen', newVal => {
      if (!newVal) {
        // Reset form when popover closes
        $scope.filterSelectorForm.$setPristine();
      }
    });
  }]
});