import { Injectable } from '@angular/core';
import { distinctUntilChanged, Observable, startWith, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { AttainmentTreeNode } from './attainment-tree-node.types';

@Injectable()
export class AttainmentTreeExpansionStateService {
    // nodes are collapsed by default, so let's maintain IDs of expanded nodes instead
    private readonly _expandedNodeIds: Set<string> = new Set<string>();
    private readonly _changed$: Subject<void> = new Subject<void>();

    /**
     * Tells if the given node is collapsed.
     */
    isNodeCollapsed(node: AttainmentTreeNode): Observable<boolean> {
        return this._changed$.pipe(
            startWith(null),
            map(() => this.isCollapsed(node)),
            distinctUntilChanged(),
        );
    }

    /**
     * Sets the given node collapsed (when {@link collapsed} = true) or expanded (when {@link collapsed} = false).
     */
    setNodeCollapsed(node: AttainmentTreeNode, collapsed: boolean): void {
        if (this.isCollapsed(node) === collapsed) {
            // already up to date
            return;
        }

        // toggle expansion state
        this.setCollapsed(node, collapsed);
        this._changed$.next();
    }

    /**
     * Resets node expansion states to the default value.
     */
    reset(): void {
        if (this._expandedNodeIds.size) {
            this._expandedNodeIds.clear();
            this._changed$.next();
        }
    }

    private isCollapsed(node: AttainmentTreeNode): boolean {
        return !this._expandedNodeIds.has(node.id);
    }

    private setCollapsed(node: AttainmentTreeNode, newIsCollapsed: boolean): void {
        if (newIsCollapsed) {
            this._expandedNodeIds.delete(node.id);
        } else {
            this._expandedNodeIds.add(node.id);
        }
    }
}

