import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { OtmId, PaymentCategory } from 'common-typescript/types';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { EntityService } from './entity.service';

const CONFIG = {
    ENDPOINTS: {
        backend: '/kori/api',
    },
};

@Injectable({
    providedIn: 'root',
})
@NgEntityServiceConfig({
    baseUrl: CONFIG.ENDPOINTS.backend,
})
export class PaymentCategoryEntityService extends EntityService<PaymentCategoriesState> {

    constructor() {
        super(PaymentCategoryStore, PaymentCategoryQuery);
    }

    /**
     * Get all payment categories for a specific university.
     * Always returns an observable to the store corresponding to payment categories with the given university id.
     *
     * @param universityOrgId Id of university
     * @param bypassStore Boolean whether to first fetch from REST-api or not. Defaults to true since there is no sure way to deduce whether the
     * store contains entities returned by a non-id-based request. If one is sure all needed data is already loaded, then one can set this to false.
     */
    getByUniversityOrgId(universityOrgId: string, bypassStore: boolean = true): Observable<PaymentCategory[]> {
        if (!bypassStore) {
            return this.selectAll({ filterBy: paymentCategory => paymentCategory.universityOrgId === universityOrgId });
        }
        return this.get({ params: { universityOrgId } })
            .pipe(
                switchMap(() => this.selectAll({ filterBy: paymentCategory => paymentCategory.universityOrgId === universityOrgId
                        && paymentCategory.documentState !== 'DELETED' })),
            );
    }
}

type PaymentCategoriesState = EntityState<PaymentCategory, OtmId>;

@StoreConfig({ name: 'payment-categories' })
class PaymentCategoryStore extends EntityStore<PaymentCategoriesState> {}

class PaymentCategoryQuery extends QueryEntity<PaymentCategoriesState> {
    constructor(protected store: PaymentCategoryStore) {
        super(store);
    }
}
