import angular from 'angular';
import _ from 'lodash';
import { alertsServiceModule } from 'sis-components/alerts/alerts.service';
import { authModule } from 'sis-common/auth/auth.module';
import { localeServiceModule } from 'sis-common/l10n/localeService';
import { NotificationsService } from 'sis-components/service/notifications/notifications.service.ts';
import { uiStateStoreModule } from '../../common/utils/uiStateStore';
import { enrolmentServiceModule } from '../../common/service/enrolment.service';
import { courseUnitRealisationModule } from '../../common/components/course-unit-realisation/courseUnitRealisation.component';
import { FullCalendarMessageService } from '../full-calendar/full-calendar-message.service.ts';
import calendarEnrolmentsTpl from './calendarEnrolments.tpl.html';
export const calendarEnrolmentsModule = 'student.calendar.calendarEnrolments';
(function () {
  calendarEnrolmentsController.$inject = ["$q", "$state", "$translate", "$scope", "$rootScope", "$anchorScroll", "$timeout", "MY_CALENDAR_INSTANCE_KEY", "commonEnrolmentService", "EnrolmentState", "uiStateStore", "localeService", "enrolmentService", "defaultPromiseHandler", "alertsService", "AuthService", "fullCalendarMessageService", "notificationsService"];
  angular.module(calendarEnrolmentsModule, ['sis-components.string.sortByLocalizedValueFilter', courseUnitRealisationModule, 'student.common.service.calendarService', 'sis-components.service.enrolmentService', 'sis-components.service.enrolmentStateService', uiStateStoreModule, 'sis-common.errorhandler.errorService', localeServiceModule, enrolmentServiceModule, alertsServiceModule, authModule, FullCalendarMessageService.downgrade.moduleName, NotificationsService.downgrade.moduleName]).component('calendarEnrolments', {
    template: calendarEnrolmentsTpl,
    bindings: {
      enrolments: '<',
      getValidatablePlan: '<',
      showFilters: '<',
      showFinishedAndAborted: '<',
      showNotStartedAndActive: '<'
    },
    controller: calendarEnrolmentsController
  });

  /**
   * @ngInject
   */
  function calendarEnrolmentsController($q, $state, $translate, $scope, $rootScope, $anchorScroll, $timeout, MY_CALENDAR_INSTANCE_KEY, commonEnrolmentService, EnrolmentState, uiStateStore, localeService, enrolmentService, defaultPromiseHandler, alertsService, AuthService, fullCalendarMessageService, notificationsService) {
    const $ctrl = this;
    $ctrl.enrolmentCalculationResultsById = {};
    const activeFilters = ['NOT_ENROLLED', 'ENROLMENT_NOT_STARTED', 'PROCESSING', 'ENROLLED', 'REJECTED'];
    const finishedAndAbortedFilters = ['ACTIVITY_ENDED', 'ABORTED', 'NOT_ENROLLED_AND_ENROLMENT_PERIODS_PASSED'];
    const stateOrder = _.concat(activeFilters, finishedAndAbortedFilters);
    stateOrder.push(undefined);
    $ctrl.loaded = false;
    const handleEnrolmentUpdated = event => {
      if (event.newState !== event.previousState) {
        switch (event.newState) {
          case 'REJECTED':
            alertsService.warning($translate.instant('ENROLMENT.NOTIFICATION.REJECTED'), 10000, `enrolment-in-calendar-alert-${event.courseUnitRealisationId}`);
            updateDataForExpandedView();
            break;
          case 'ENROLLED':
            alertsService.success($translate.instant('ENROLMENT.NOTIFICATION.ENROLLED'), 10000, `enrolment-in-calendar-alert-${event.courseUnitRealisationId}`);
            break;
          case 'INVALID':
            alertsService.warning($translate.instant('ENROLMENT.NOTIFICATION.INVALID'), 10000, `enrolment-in-calendar-alert-${event.courseUnitRealisationId}`);
            break;
          default:
          // Do nothing
        }
      }
      if (event.newState === 'PROCESSING' && (event.newProcessingState !== event.previousProcessingState || event.newState !== event.previousState)) {
        switch (event.newProcessingState) {
          case 'CURRENTLY_SELECTED':
          case 'SELECTED':
            alertsService.info($translate.instant('ENROLMENT.NOTIFICATION.CURRENTLY_SELECTED'), 10000, `enrolment-in-calendar-alert-${event.courseUnitRealisationId}`);
            break;
          case 'CURRENTLY_NOT_SELECTED':
          case 'NOT_SELECTED':
            alertsService.info($translate.instant('ENROLMENT.NOTIFICATION.CURRENTLY_NOT_SELECTED'), 10000, `enrolment-in-calendar-alert-${event.courseUnitRealisationId}`);
            updateDataForExpandedView();
            break;
          case 'REQ_NOT_FULFILLED':
            alertsService.warning($translate.instant('ENROLMENT.NOTIFICATION.REQ_NOT_FULFILLED'), 10000, `enrolment-in-calendar-alert-${event.courseUnitRealisationId}`);
            updateDataForExpandedView();
            break;
          case 'PENDING':
            alertsService.info($translate.instant('ENROLMENT.NOTIFICATION.PENDING'), 10000, `enrolment-in-calendar-alert-${event.courseUnitRealisationId}`);
            updateDataForExpandedView();
            break;
          default:
          // Do nothing
        }
      }
      commonEnrolmentService.refreshEnrolmentIfChanged(event.enrolmentId, event.revision).then($ctrl.fetchEvents).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    };
    $ctrl.$onDestroy = function () {
      $ctrl.enrolmentChangeObservable.unsubscribe();
    };
    $ctrl.$onInit = function () {
      $ctrl.filters = uiStateStore.readField('student.calendar.calendarEnrolments.', 'filters', enrolmentService.getDefaultCalendarFilterStates());
      if ($state.params.courseUnitRealisationId) {
        uiStateStore.storeField('otm.student.calendar.courseUnitRealisationItem.', 'expanded', [$state.params.courseUnitRealisationId]);
      }
      $ctrl.findEnrolmentCalculationResults().then($ctrl.setVisibleEnrolments).catch(defaultPromiseHandler.loggingRejectedPromiseHandler).finally(() => {
        $ctrl.loaded = true;
        if ($state.params.courseUnitRealisationId !== undefined) {
          $timeout(() => {
            $anchorScroll(`anchor${$state.params.courseUnitRealisationId}`);
          });
        }
      });
      $ctrl.enrolmentChangeObservable = notificationsService.getEventsObservable('enrolmentChange').subscribe(value => handleEnrolmentUpdated(value));
    };
    $ctrl.showEnrolmentInTab = enrolment => {
      const filterKeyForEnrolment = enrolmentService.getFilterKeyForEnrolment(enrolment, enrolment.courseUnitRealisation);
      if ($ctrl.showFinishedAndAborted && _.includes(finishedAndAbortedFilters, filterKeyForEnrolment)) {
        return true;
      }
      return $ctrl.showNotStartedAndActive && _.includes(activeFilters, filterKeyForEnrolment);
    };
    $ctrl.showEnrolmentAfterFilter = function (enrolment) {
      const filterKeyForEnrolment = enrolmentService.getFilterKeyForEnrolment(enrolment, enrolment.courseUnitRealisation);
      return _.get($ctrl.filters, filterKeyForEnrolment, false);
    };
    $ctrl.getVisibleEnrolments = ignoreFilters => _.chain($ctrl.enrolments).filter('isInCalendar').filter($ctrl.showEnrolmentInTab).filter(enrolment => ignoreFilters || $ctrl.showEnrolmentAfterFilter(enrolment)).value();
    $ctrl.sortEnrolmentsByState = enrolment => _.indexOf(stateOrder, enrolmentService.getFilterKeyForEnrolment(enrolment, enrolment.courseUnitRealisation));
    $ctrl.sortEnrolmentsByCurName = enrolment => localeService.getLocalizedValue(enrolment.courseUnitRealisation.name);
    $ctrl.onFilterChange = filter => {
      $ctrl.filters[filter] = !$ctrl.filters[filter];
      uiStateStore.storeField('student.calendar.calendarEnrolments.', 'filters', $ctrl.filters);
      $ctrl.setVisibleEnrolments().then(() => {
        if ($ctrl.filters[filter]) {
          $ctrl.findEnrolmentCalculationResults();
        }
      }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    };
    $ctrl.getFilterValue = filter => $ctrl.filters[filter];
    $ctrl.updateVisibleEnrolments = () => {
      $ctrl.setVisibleEnrolments().catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    };

    // By calling data loading on curs that are visible and expanded enrolments on initialisation and on filter toggle, we ensure that
    // our cur boxes do not spam with multiple data load requests. This tackles the case where you
    // 1. expand multiple curs (their expanded status is stored in storage)
    // 2. hide them with a single filter
    // 3. reload page
    // 4. toggle filter
    // 5. mayhem if data loading is not done in a centralized way
    $ctrl.setVisibleEnrolments = () => {
      const visibleEnrolments = $ctrl.getVisibleEnrolments(!$ctrl.showFilters);
      const expandedEnrolments = $ctrl.filterExpandedEnrolments(visibleEnrolments);
      return enrolmentService.loadDataForCourseUnitRealisationComponent(expandedEnrolments).then(() => {
        $timeout(() => $ctrl.groupEnrolmentsByCourseUnitToVisible(visibleEnrolments));
      });
    };
    $ctrl.groupEnrolmentsByCourseUnitToVisible = enrolments => {
      $ctrl.visibleEnrolmentsByCourseUnitId = _.chain(enrolments).groupBy('courseUnitId').map((enrolmentsForCourseUnit, courseUnitId) => ({
        courseUnitId,
        courseUnitName: enrolmentsForCourseUnit[0].courseUnit.name,
        enrolments: enrolmentsForCourseUnit
      })).value();
    };
    $ctrl.filterExpandedEnrolments = enrolments => {
      const expandedCurs = uiStateStore.readField('otm.student.calendar.courseUnitRealisationItem.', 'expanded', []);
      return _.filter(enrolments, enrolment => _.includes(expandedCurs, enrolment.courseUnitRealisationId));
    };
    $ctrl.enrolmentCountForFilter = filterKey => _.chain($ctrl.enrolments).map(enrolment => enrolmentService.getFilterKeyForEnrolment(enrolment, enrolment.courseUnitRealisation)).filter(key => key === filterKey).size().value();
    $ctrl.findEnrolmentCalculationResults = () => {
      const curIds = _.chain([...$ctrl.getVisibleEnrolments(!$ctrl.showFilters), ...$ctrl.filterExpandedEnrolments($ctrl.enrolments)]).map('courseUnitRealisationId').compact().uniq().value();
      return enrolmentService.findMyCalculationResults(curIds, true).then(results => {
        $ctrl.enrolmentCalculationResultsById = _.keyBy(results, 'courseUnitRealisationId');
      });
    };
    $ctrl.removeFromCalendar = function (enrolment) {
      let removeFromCalendar;
      alertsService.dismissAlertIfExists(`enrolment-updated-alert-${enrolment.courseUnitRealisationId}`);
      alertsService.dismissAlertIfExists(`enrolment-succeeded-alert-${enrolment.courseUnitRealisationId}`);
      alertsService.dismissAlertIfExists(`enrolment-in-calendar-alert-${enrolment.courseUnitRealisationId}`);
      if (enrolment.state === EnrolmentState.NOT_ENROLLED) {
        _.remove($ctrl.enrolments, {
          id: enrolment.id
        });
        removeFromCalendar = commonEnrolmentService.deleteEnrolment;
      } else {
        enrolment.isInCalendar = false;
        removeFromCalendar = commonEnrolmentService.updateEnrolment;
      }
      return removeFromCalendar(enrolment).then($ctrl.setVisibleEnrolments).then($ctrl.fetchEvents, revertOnError(enrolment)).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    };
    $ctrl.toggleStudySubGroupInCalendar = function (enrolment) {
      commonEnrolmentService.injectToJsData(enrolment);
      return commonEnrolmentService.updateEnrolment(enrolment).then($ctrl.fetchEvents, revertOnError(enrolment));
    };
    $ctrl.updateData = function (newEnrolment) {
      commonEnrolmentService.injectToJsData(newEnrolment);
      $ctrl.fetchEvents();
      updateDataForExpandedView();
    };
    $ctrl.selectCalendarDate = function (date) {
      fullCalendarMessageService.goToDateSubject.next(date);
    };
    $ctrl.fetchEvents = () => {
      fullCalendarMessageService.reFetchEventsSubject.next();
    };
    function revertOnError(enrolment) {
      return err => {
        commonEnrolmentService.revert(enrolment);
        return $q.reject(err);
      };
    }
    function refreshExpandedEnrolments() {
      let expandedEnrolmentsList = [];
      $ctrl.visibleEnrolmentsByCourseUnitId.forEach(item => {
        const expandedEnrolments = $ctrl.filterExpandedEnrolments(item.enrolments);
        expandedEnrolmentsList = [...expandedEnrolmentsList, ...expandedEnrolments];
      });
      return enrolmentService.loadDataForCourseUnitRealisationComponent(expandedEnrolmentsList);
    }
    function updateDataForExpandedView() {
      // get calculation results again, so the expanded view texts will update (e.g.: "failedRequirementPersonRulesText")
      $ctrl.findEnrolmentCalculationResults().then(refreshExpandedEnrolments).then(() => $scope.$broadcast('updateDataForExpandedView'));
    }
  }
})();