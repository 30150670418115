<sis-description-list-compact>
  <ng-template #compactListItem *ngIf="courseUnit.possibleAttainmentLanguages?.length > 0">
    <dt class="label colon">{{'STUDIES.COURSE_UNIT_INFO_MODAL.POSSIBLE_ATTAINMENT_LANGUAGES' | translate}}</dt>
    <dd class="value" *ngFor="let languageUrn of courseUnit.possibleAttainmentLanguages; last as isLast">
      {{  languageUrn | codeLocalizedName | async }}<span *ngIf="!isLast">,&nbsp;</span>
    </dd>
  </ng-template>

  <ng-template #compactListItem *ngIf="courseUnit.gradeScaleId">
    <dt class="label colon">{{'STUDIES.COURSE_UNITS_BASICINFO.GRADE_SCALE' | translate}}</dt>
    <dd class="value">
      {{ courseUnit.gradeScaleId | gradeScale | async | entityName | localizedString }}
    </dd>
  </ng-template>

  <ng-template #compactListItem *ngIf="courseUnit.courseUnitType">
    <dt class="label colon">{{'STUDIES.COURSE_UNIT_INFO_MODAL.COURSE_UNIT_TYPE' | translate}}</dt>
    <dd class="value">
      {{ courseUnit.courseUnitType | codeLocalizedName | async }}
    </dd>
  </ng-template>

  <ng-template #compactListItem *ngIf="courseUnit.studyLevel">
    <dt class="label colon">{{'STUDIES.COURSE_UNIT_INFO_MODAL.STUDY_LEVEL' | translate}}</dt>
    <dd class="value">
      {{ courseUnit.studyLevel | codeLocalizedName | async }}
    </dd>
  </ng-template>

  <ng-template #compactListItem *ngIf="courseUnit.organisations?.length > 0">
    <dt class="label colon">{{'STUDIES.COURSE_UNIT_INFO_MODAL.ORGANIZER' | translate}}</dt>
    <dd class="value" *ngFor="let organisation of courseUnit.organisations; last as isLast">
      <span *ngIf="organisation.organisationId">{{ organisation.organisationId | organisation | async | entityName | localizedString }}</span
      ><span *ngIf="organisation.educationalInstitutionUrn">{{  organisation.educationalInstitutionUrn | codeLocalizedName | async }}</span
      ><span *ngIf="!isLast">,&nbsp;</span>
    </dd>
  </ng-template>

  <ng-template #compactListItem *ngIf="courseUnit.universityOrgIds?.length > 0">
    <dt class="label colon">{{'STUDIES.COURSE_UNIT_INFO_MODAL.UNIVERSITY' | translate}}</dt>
    <dd class="value" *ngFor="let universityId of courseUnit.universityOrgIds; last as isLast">
      {{ universityId | organisation | async | entityName | localizedString }}
      <span *ngIf="!isLast">,&nbsp;</span>
    </dd>
  </ng-template>

  <ng-template #compactListItem *ngIf="courseUnit.cefrLevel">
    <dt class="label colon">{{'STUDIES.COURSE_UNIT_INFO_MODAL.CEFR_LEVEL' | translate}}</dt>
    <dd class="value">
      {{ courseUnit.cefrLevel | codeLocalizedName | async }}
    </dd>
  </ng-template>
</sis-description-list-compact>
