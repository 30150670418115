import angular from 'angular';
import _ from 'lodash';
import studentTimingTemplatesTpl from './studentTimingTemplates.tpl.html';
(function () {
  studentTimingTemplatesController.$inject = ["$log", "universityService"];
  angular.module('student.timing.studentTimingTemplates', ['student.timing.studentTimingTemplate', 'sis-common.university']).component('studentTimingTemplates', {
    template: studentTimingTemplatesTpl,
    bindings: {
      selectedPlan: '<',
      validatablePlan: '<',
      validatablePlanConstructor: '<',
      state: '<'
    },
    controller: studentTimingTemplatesController
  });

  /**
   * @ngInject
   */
  function studentTimingTemplatesController($log, universityService) {
    const ctrl = this;
    const orderedDegreeProgramTypes = ['urn:code:degree-program-type:doctor', 'urn:code:degree-program-type:lic', 'urn:code:degree-program-type:masters-degree', 'urn:code:degree-program-type:bachelors-degree'];
    ctrl.$onInit = function () {
      ctrl.universityOrgId = universityService.getCurrentUniversityOrgId();
      if (!ctrl.validatablePlan) {
        $log.error('ValidatablePlan is not set');
      }
      const firstLevelModules = ctrl.validatablePlan.getSelectedModulesById(ctrl.validatablePlan.rootModule);
      ctrl.firstLevelModules = _.sortBy(firstLevelModules, [function (m) {
        return -orderedDegreeProgramTypes.indexOf(m.degreeProgramTypeUrn);
      }]);
    };
  }
})();