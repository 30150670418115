import angular from 'angular';
import 'js-data';
export const enrolmentCalculationConfigModelModule = 'sis-components.model.enrolmentCalculationConfigModel';
(function () {
  enrolmentCalculationConfigModel.$inject = ["DS", "ENROLMENT_CALCULATION_CONFIG_URL"];
  angular.module(enrolmentCalculationConfigModelModule, ['js-data']).constant('ENROLMENT_CALCULATION_CONFIG_URL', {
    DEFAULT: '/ilmo/api/enrolment-calculation-configs'
  }).factory('enrolmentCalculationConfigJSDataModel', enrolmentCalculationConfigModel);

  /**
   * @ngInject
   */
  function enrolmentCalculationConfigModel(DS, ENROLMENT_CALCULATION_CONFIG_URL) {
    return DS.defineResource({
      idAttribute: 'id',
      name: 'enrolmentCalculationConfig',
      endpoint: ENROLMENT_CALCULATION_CONFIG_URL.DEFAULT
    });
  }
})();