import { Injectable } from '@angular/core';
import { dateUtils } from 'common-typescript';
import { OtmId, Plan, StudyRight } from 'common-typescript/types';
import _ from 'lodash';
import moment from 'moment/moment';

@Injectable({
    providedIn: 'root',
})
export class PlanSeekerService {

    /**
     * Resolves the plan that the student should be redirected to:
     *
     *  1. If the student has no plans, return null.
     *  2. If the student has valid study rights, return the last modified plan with valid study right.
     *  3. If the student has no valid study rights:
     *      2.1. Return the latest modified primary plan.
     *      2.2. If there are no primary plans just return the latest modified plan.
     *
     *  Note: Unlike AngularJS version, this does not use the uistatestore (at least yet).
     *
     * @param studentPlans All student plans
     * @param studentStudyRights All student study rights
     */
    resolvePlan(studentPlans: Plan [], studentStudyRights: StudyRight[]): OtmId {
        if (!studentPlans.length) {
            return null;
        }

        const plansByLastModifiedDesc = this.getPlansByLastModifiedDescending(studentPlans);
        const validStudentStudyRights = studentStudyRights.filter((studyRight) => dateUtils.rangeContains(moment(), studyRight.valid));
        if (validStudentStudyRights.length > 0) {
            const lastModifiedPlanWithValidStudyRight = this.resolveLastModifiedPlanWithValidStudyRight(
                plansByLastModifiedDesc,
                validStudentStudyRights);
            if (lastModifiedPlanWithValidStudyRight) {
                return lastModifiedPlanWithValidStudyRight.id;
            }
        }

        return this.resolveLastModifiedPrimaryPlanOrLastModifiedPlan(plansByLastModifiedDesc).id;
    }

    private getPlansByLastModifiedDescending(plans: Plan []): Plan [] {
        return _.orderBy(plans,
                         (plan) =>
                             dateUtils.createMoment(plan?.metadata?.lastModifiedOn ?? null),
                         'desc');
    }

    private resolveLastModifiedPlanWithValidStudyRight(plansByLastModifiedDesc: Plan [], validStudentStudyRights: StudyRight[]): Plan {
        return plansByLastModifiedDesc.find(
            (plan) =>
                validStudentStudyRights.some(
                    (studyRight) =>
                        studyRight.educationId === plan.rootId));
    }

    private resolveLastModifiedPrimaryPlanOrLastModifiedPlan(plansByLastModifiedDesc: Plan []): Plan {
        return plansByLastModifiedDesc.find((plan) => plan.primary) ?? plansByLastModifiedDesc[0];
    }
}
