import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Attainment, Education, StudyRight, StudyRightExtension } from 'common-typescript/types';
import _ from 'lodash';
import { Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

import { isCourseUnitAttainment, isDegreeProgrammeAttainment, isModuleLikeAttainment } from '../../attainment/AttainmentUtil';
import { AppErrorHandler } from '../../error-handler/app-error-handler';
import { EducationEntityService } from '../../service/education-entity.service';
import { MobilityPeriodEntityService } from '../../service/mobilityPeriod-entity.service';
import { StudentContextAttainmentService } from '../../service/student-context-attainment.service';
import { TermRegistrationRequirementsEntityService } from '../../service/term-registration-requirements-entity.service';
import { CommonStudyRightModalService } from '../common-study-right-modal.service';

/**
 * Expects an implementation of {@link StudentContextAttainmentService} to have been provided by a containing component.
 */
@Component({
    selector: 'sis-read-only-study-right',
    templateUrl: './read-only-study-right.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ReadOnlyStudyRightComponent implements OnInit, OnDestroy {

    constructor(
        private educationEntityService: EducationEntityService,
        private commonStudyRightModalService: CommonStudyRightModalService,
        private termRegistrationRequirementsEntityService: TermRegistrationRequirementsEntityService,
        private mobilityPeriodEntityService: MobilityPeriodEntityService,
        private readonly _studentContextAttainmentService: StudentContextAttainmentService,
        private appErrorHandler: AppErrorHandler,
    ) { }

    @Input() studyRight: StudyRight;
    @Input() alwaysDisplayDegreeProgrammeAttainments: boolean;
    @Input() isStaffUser: boolean = false;
    education: Education;
    attainments: Attainment[];
    hasMobilityPeriodForStudyRight = false;
    hasDegreeProgrammeAttainments = false;
    isRegistrationRequired = false;
    attainmentsByModuleGroupId: { [index: string]: Attainment } = {};
    attainmentsByCourseUnitGroupId: { [index: string]: Attainment } = {};
    destroyed$ = new Subject<void>();

    ngOnInit(): void {
        this.educationEntityService.getById(this.studyRight.educationId)
            .pipe(
                takeUntil(this.destroyed$),
                this.appErrorHandler.defaultErrorHandler(),
            )
            .subscribe({
                next: education => this.education = education,
            });
        this._studentContextAttainmentService.validAttainments$
            .pipe(
                takeUntil(this.destroyed$),
                this.appErrorHandler.defaultErrorHandler(),
            )
            .subscribe({
                next: (attainments: Attainment[]) => {
                    this.attainments = attainments;
                    this.attainmentsByModuleGroupId = _.keyBy(attainments.filter(a => isModuleLikeAttainment(a)), 'moduleGroupId');
                    this.attainmentsByCourseUnitGroupId = _.keyBy(attainments.filter(a => isCourseUnitAttainment(a)), 'courseUnitGroupId');
                    this.hasDegreeProgrammeAttainments = attainments
                        .filter(a => isDegreeProgrammeAttainment(a))
                        .some(a => a.studyRightId === this.studyRight.id);
                },
            });

        this.educationEntityService.getById(this.studyRight.educationId)
            .pipe(
                switchMap(education => this.termRegistrationRequirementsEntityService.isTermRegistrationRequired(education.educationType)),
                takeUntil(this.destroyed$),
                this.appErrorHandler.defaultErrorHandler(),
            )
            .subscribe({
                next: isRequired => this.isRegistrationRequired = isRequired,
            });

        this.mobilityPeriodEntityService.getActiveByStudyRightId(this.studyRight.id)
            .pipe(
                takeUntil(this.destroyed$),
                this.appErrorHandler.defaultErrorHandler(),
            )
            .subscribe({
                next: entities => this.hasMobilityPeriodForStudyRight = entities.length > 0,
            });
    }

    openExtensionDetails(extension: StudyRightExtension): void {
        const applicationViewUrl = _.isNil(extension.applicationId) ?
            null : `/student/profile/applications/study-right-extension/${extension.applicationId}`;
        this.commonStudyRightModalService.openExtensionInfoModal(extension, this.studyRight, applicationViewUrl)
            .pipe(this.appErrorHandler.defaultErrorHandler())
            .subscribe();
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
    }

}
