<sis-plan-course-unit *ngIf="courseUnit"
                      [courseUnit]="courseUnit"
                      [parentModule]="parentModule"
                      [planValidationResult]="planValidationResult"
                      [planStateObject]="planStateObject"
                      [planData]="planData">

    <div course-unit-action class="additional-box toggle-box">
        <div class="checkbox checkbox-toggle sis-toggle">
            <label>
                <input type="checkbox" (click)="toggle($event)" [checked]="isSelectedCourseUnit(courseUnit.id)" [disabled]="!isTogglingEnabled(courseUnit)"><span></span>
            </label>
        </div>
    </div>

</sis-plan-course-unit>
