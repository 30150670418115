import angular from 'angular';
export const environmentNgjsServiceModule = 'sis-common.environmentNgjsService';
angular.module(environmentNgjsServiceModule, []).factory('environmentNgjsService', EnvironmentNgjsService);
function EnvironmentNgjsService() {
  let frontendName = '';
  return {
    init: function (env) {
      frontendName = env.frontendName;
    },
    getFrontendName: function () {
      return frontendName;
    }
  };
}