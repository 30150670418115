import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { Event, OtmId } from 'common-typescript/types';

@Component({
    selector: 'sis-study-sub-group-structure-study-event-exceptions',
    templateUrl: './study-sub-group-structure-study-event-exceptions.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudySubGroupStructureStudyEventExceptionsComponent {
    @Input() irregularEvents: Event[];
    @Input() studyEventLocationIds: OtmId[];
    @Input() studyEventTeacherIds: OtmId[];
    @Input() language: string;
}

