import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class PlanSelectorConfig {
    allowAnyCourseUnitRuleSelection = false;
    allowAnyModuleRuleSelection = false;
    allowCourseUnitSelection = true;
    allowEducationSelection = false;
    allowModuleSelection = true;
    allOpen = false; // used in tests to print whole structure.
}
