import { Injectable, Injector } from '@angular/core';

import { InjectorService } from '../injector/injector.service';

/**
 * A service for retrieving the injector of the root downgraded component. The root injector contains the `$scope` provider that is needed for
 * dynamically created upgraded AngularJS components in NG Bootstrap Modals.
 *
 * @see https://github.com/angular/angular/issues/27432#issuecomment-443994673
 * @see https://github.com/angular/angular/issues/27432#issuecomment-444124715
 */
@Injectable()
export class HybridInjectorService implements InjectorService {

    private rootInjector: Injector;

    getRootInjector(): Injector {
        return this.rootInjector;
    }

    setRootInjector(injector: Injector): void {
        this.rootInjector = injector;
    }
}
