<sis-expandable [title]="'PROFILE.STUDY_PATH.INTERNATIONAL_CONTRACTUAL_DEGREE_INFO' | translate"
                [variant]="'link'" data-cy="international-contractual-degree-button" [level]="level">
  <ng-template sisExpandableContent>
    <sis-description-list>
      <dl>
        <ng-template #listItem>
          <dt class="sis-label">{{ 'PROFILE.STUDY_PATH.INTERNATIONAL_CONTRACTUAL_DEGEE_AGREEMENT' | translate }}</dt>
          <dd>{{ internationalContractualDegreeAgreement.name | localizedString }}</dd>
        </ng-template>
        <ng-template #listItem *ngIf="internationalContractualDegreeAgreement.type === 'JOINT'">
          <dt class="sis-label">{{ 'PROFILE.STUDY_PATH.INTERNATIONAL_INSTITUTIONS' | translate }}</dt>
          <dd *ngFor="let attainableDegree of internationalContractualDegree.attainableDegrees">
            {{ attainableDegree.internationalInstitutionUrn|codeLocalizedName|async }}
          </dd>
        </ng-template>
        <ng-container *ngIf="internationalContractualDegreeAgreement.type === 'DOUBLE'">
          <ng-template #listItem *ngFor="let attainableDegree of internationalContractualDegree.attainableDegrees">
            <dt class="sis-label">{{ 'PROFILE.STUDY_PATH.DEGREE_NAME_AND_INTERNATIONAL_INSTITUTION' | translate }}</dt>
            <dd>{{ attainableDegree.degreeName }}</dd>
            <dd>{{ attainableDegree.internationalInstitutionUrn|codeLocalizedName|async }}</dd>
          </ng-template>
        </ng-container>

      </dl>
    </sis-description-list>
  </ng-template>
</sis-expandable>
