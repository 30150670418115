import { Pipe, PipeTransform } from '@angular/core';
import { Education } from 'common-typescript/types';

import { EducationEntityService } from '../service/education-entity.service';

@Pipe({ name: 'isDegreeEducation' })
export class IsDegreeEducationPipe implements PipeTransform {
    constructor(private educationEntityService: EducationEntityService) {
    }

    transform(value: Education): boolean {
        return this.educationEntityService.isDegreeEducation(value);
    }

}
