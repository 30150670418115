<div>
  <sis-description-list [columnCount]="1">
    <dl>
      <ng-template #listItem>
        <dt class="sis-label">{{'PROFILE.PHONE_NUMBER' | translate}}
          <sis-classified-info-toggle *ngIf="isClassified('PhoneNumber')"
                                      [visible]="isClassifiedInfoVisible('PhoneNumber')"
                                      [ariaLabel]="!isClassifiedInfoVisible('PhoneNumber') ?  ('ARIA_LABEL.SHOW_PHONE_NUMBER' | translate) : ('ARIA_LABEL.HIDE_PHONE_NUMBER' | translate )"
                                      (toggle)="toggleFieldVisibility('PhoneNumber')">
          </sis-classified-info-toggle>
        </dt>
        <dd *ngIf="isClassified('PhoneNumber') && !isClassifiedInfoVisible('PhoneNumber')">
          <sis-tiny-badge>{{ 'PROFILE.CLASSIFIED.SECRET' | translate }}</sis-tiny-badge>
        </dd>
        <dd *ngIf="isClassifiedInfoVisible('PhoneNumber')">{{ studentProfile.phoneNumber }}</dd>
      </ng-template>
      <ng-template #listItem>
        <dt class="sis-label">{{'PROFILE.EMAIL' | translate}}</dt>
        <dd class="notranslate">{{ studentProfile.primaryEmail }}</dd>
      </ng-template>
      <ng-template #listItem>
        <dt class="sis-label">{{'PROFILE.EMAIL2' | translate}}
          <sis-classified-info-toggle *ngIf="isClassified('SecondaryEmail')"
                                      [visible]="isClassifiedInfoVisible('SecondaryEmail')"
                                      [ariaLabel]="!isClassifiedInfoVisible('SecondaryEmail') ?  ('ARIA_LABEL.SHOW_SECONDARY_EMAIL' | translate) : ('ARIA_LABEL.HIDE_SECONDARY_EMAIL' | translate )"
                                      (toggle)="toggleFieldVisibility('SecondaryEmail')">
          </sis-classified-info-toggle>
        </dt>
        <dd *ngIf="isClassified('SecondaryEmail') && !isClassifiedInfoVisible('SecondaryEmail')">
          <sis-tiny-badge>{{ 'PROFILE.CLASSIFIED.SECRET' | translate }}</sis-tiny-badge>
        </dd>
        <dd class="notranslate" *ngIf="isClassifiedInfoVisible('SecondaryEmail')">
          {{ studentProfile.secondaryEmail }}
        </dd>
      </ng-template>
      <ng-template #listItem>
        <dt class="sis-label">{{'PROFILE.HOME_MUNICIPALITY' | translate}}
          <sis-classified-info-toggle *ngIf="isClassified('MunicipalityUrn')"
                                      [visible]="isClassifiedInfoVisible('MunicipalityUrn')"
                                      [ariaLabel]="!isClassifiedInfoVisible('MunicipalityUrn') ?  ('ARIA_LABEL.SHOW_HOME_MUNICIPALITY' | translate) : ('ARIA_LABEL.HIDE_HOME_MUNICIPALITY' | translate )"
                                      (toggle)="toggleFieldVisibility('MunicipalityUrn')">
          </sis-classified-info-toggle>
        </dt>
        <dd *ngIf="isClassified('MunicipalityUrn') && !isClassifiedInfoVisible('MunicipalityUrn')">
          <sis-tiny-badge>{{ 'PROFILE.CLASSIFIED.SECRET' | translate }}</sis-tiny-badge>
        </dd>
        <dd *ngIf="isClassifiedInfoVisible('MunicipalityUrn')">
          {{ studentProfile.municipalityUrn | codeLocalizedName | async }}
        </dd>
      </ng-template>
      <ng-template #listItem>
        <div class="form-label grouping-panel">
          <dt class="address-label">
            {{ 'PROFILE.PRIMARY_ADDRESS' | translate }}
            <sis-classified-info-toggle *ngIf="isClassified('PrimaryAddress')"
                                        [visible]="isClassifiedInfoVisible('PrimaryAddress')"
                                        [ariaLabel]="!isClassifiedInfoVisible('PrimaryAddress') ?  ('ARIA_LABEL.SHOW_PRIMARY_ADDRESS' | translate) : ('ARIA_LABEL.HIDE_PRIMARY_ADDRESS' | translate )"
                                        (toggle)="toggleFieldVisibility('PrimaryAddress')">
            </sis-classified-info-toggle>
          </dt>
          <dd>
            <ng-container *ngIf="isClassified('PrimaryAddress') && !isClassifiedInfoVisible('PrimaryAddress')">
              <sis-tiny-badge>{{ 'PROFILE.CLASSIFIED.SECRET' | translate }}</sis-tiny-badge>
            </ng-container>
            <div class="col-12">
              <div class="grouping-panel-content">
                <dl role="list"
                    *ngIf="studentProfile.primaryAddress && isClassifiedInfoVisible('PrimaryAddress')">
                  <div role="listitem" class="grouping-panel-content-grid">
                    <dt>{{ 'PROFILE.STREET_ADDRESS' | translate}}</dt>
                    <dd class="notranslate">{{ studentProfile.primaryAddress.streetAddress }}</dd>
                  </div>
                  <div role="listitem" class="grouping-panel-content-grid">
                    <dt>{{ 'PROFILE.POSTAL_CODE' | translate}}</dt>
                    <dd>{{ studentProfile.primaryAddress.postalCode }}</dd>
                  </div>
                  <div role="listitem" class="grouping-panel-content-grid">
                    <dt>{{ 'PROFILE.CITY' | translate }}</dt>
                    <dd class="notranslate">{{ studentProfile.primaryAddress.city }}</dd>
                  </div>
                  <div role="listitem" class="grouping-panel-content-grid">
                    <dt>{{ 'PROFILE.COUNTRY' | translate }}</dt>
                    <dd>{{ studentProfile.primaryAddress.countryUrn | codeLocalizedName | async }}</dd>
                  </div>
                </dl>
              </div>
            </div>
          </dd>
        </div>
      </ng-template>
      <ng-template #listItem>
        <div class="form-label grouping-panel" *ngIf="studentProfile.secondaryAddress">
          <dt class="address-label">
            {{ 'PROFILE.SECONDARY_ADDRESS' | translate }}
            <sis-classified-info-toggle *ngIf="isClassified('SecondaryAddress')"
                                        [visible]="isClassifiedInfoVisible('SecondaryAddress')"
                                        [ariaLabel]="!isClassifiedInfoVisible('SecondaryAddress') ?  ('ARIA_LABEL.SHOW_SECONDARY_ADDRESS' | translate) : ('ARIA_LABEL.HIDE_SECONDARY_ADDRESS' | translate )"
                                        (toggle)="toggleFieldVisibility('SecondaryAddress')">
            </sis-classified-info-toggle>
          </dt>
          <dd>
            <ng-container *ngIf="isClassified('SecondaryAddress') && !isClassifiedInfoVisible('SecondaryAddress')">
              <sis-tiny-badge>{{ 'PROFILE.CLASSIFIED.SECRET' | translate }}</sis-tiny-badge>
            </ng-container>
            <div class="col-12">
              <div class="grouping-panel-content">
                <dl role="list"
                  *ngIf="isClassifiedInfoVisible('SecondaryAddress') && isSecondaryAddressInFinland">
                  <div role="listitem" class="grouping-panel-content-grid">
                    <dt>{{ 'PROFILE.STREET_ADDRESS' | translate}}</dt>
                    <dd class="notranslate">{{ studentProfile.secondaryAddress.streetAddress }}</dd>
                  </div>
                  <div role="listitem" class="grouping-panel-content-grid">
                    <dt>{{ 'PROFILE.POSTAL_CODE' | translate}}</dt>
                    <dd>{{ studentProfile.secondaryAddress.postalCode }}</dd>
                  </div>
                  <div role="listitem" class="grouping-panel-content-grid">
                    <dt>{{ 'PROFILE.CITY' | translate }}</dt>
                    <dd class="notranslate">{{ studentProfile.secondaryAddress.city }}</dd>
                  </div>
                  <div role="listitem" class="grouping-panel-content-grid">
                    <dt>{{ 'PROFILE.COUNTRY' | translate }}</dt>
                    <dd>{{ studentProfile.secondaryAddress.countryUrn | codeLocalizedName | async }}</dd>
                  </div>
                </dl>
                <dl role="list"
                  *ngIf="isClassifiedInfoVisible('SecondaryAddress') && !isSecondaryAddressInFinland">
                  <div role="listitem" class="grouping-panel-content-grid">
                    <dt>{{ 'PROFILE.SECONDARY_ADDRESS_GENERIC_FORM_TITLE' | translate }}</dt>
                    <dd>{{ studentProfile.secondaryAddress.address }}</dd>
                  </div>
                  <div role="listitem" class="grouping-panel-content-grid">
                    <dt>{{ 'PROFILE.COUNTRY' | translate }}</dt>
                    <dd>{{ studentProfile.secondaryAddress.countryUrn | codeLocalizedName | async }}</dd>
                  </div>
                </dl>
              </div>
            </div>
          </dd>
        </div>
      </ng-template>
    </dl>
  </sis-description-list>
</div>
