import { commonPlanServiceModule } from 'sis-components/service/plan.service';
import { plansModule } from './plans.comp';
(function () {
  angular.module('student.tutoring.route', ['sis-components.plan.planStructure', 'sis-components.messenger.planMessages', 'sis-components.plan.planLoader', commonPlanServiceModule, plansModule]).config(["$stateProvider", function ($stateProvider) {
    var getValidatablePlan = function (PlanLoader, selectedPlan) {
      return PlanLoader.createValidatablePlan(selectedPlan);
    };
    getValidatablePlan.$inject = ["PlanLoader", "selectedPlan"];
    var setPlan = function ($scope, plans, selectedPlan) {
      $scope.plans = plans;
      $scope.selectedPlan = selectedPlan;
    };
    setPlan.$inject = ["$scope", "plans", "selectedPlan"];
    var setPlanForMessagesView = function ($scope, selectedPlan, validatablePlan) {
      $scope.selectedPlan = selectedPlan;
      $scope.validatablePlan = validatablePlan;
    };
    setPlanForMessagesView.$inject = ["$scope", "selectedPlan", "validatablePlan"];
    $stateProvider.state('student.logged-in.plan.tutoring', {
      url: '/tutoring',
      controller: setPlan,
      template: '<div class="container">' + '<plans class="student" plans="plans" selected-plan="selectedPlan" ></plans>' + '</div>'
    }).state('student.logged-in.plan.tutoring.messages', {
      url: '/messages',
      resolve: {
        validatablePlan: getValidatablePlan
      },
      controller: setPlanForMessagesView,
      template: '<plan-messages plan="selectedPlan" validatable-plan="validatablePlan"></plan-messages>'
    });
  }]);
})();