<div class="tiny-static-form-group" [class.plain]="plain" [class]="classes">
  <span class="label colon">{{label | translate}}</span><!--
--><ng-content select=".value"></ng-content>
  <sis-tooltip *ngIf="tooltipText" [tooltipBlockElement]="true">
    <span class="sis-tooltip-content" aria-hidden="true">{{ tooltipText | translate }}</span>
    <span class="sis-tooltip-trigger-content" tabindex="0" role="tooltip" [attr.aria-label]="tooltipText | translate">
      <sis-icon icon="info-circle" color="primary"></sis-icon>
    </span>
  </sis-tooltip>
</div>


