<div class="container">
  <div class="row">
    <div class="offset-md-1 col-md-10 offset-lg-2 col-lg-8">
      <div class="page-title-container fake-crumbs">
        <h1 id="skip-link-target" tabindex="-1">
          {{ ('STUDENT_APPLICATIONS.TYPE.' + workflow.type) | translate }}: {{ this.application.name }}
          <app-workflow-state-badge [state]="workflow.state"></app-workflow-state-badge>
        </h1>
      </div>
      <sis-code-panel [code]="this.workflow.code"></sis-code-panel>
      <sis-contextual-notification *ngIf="this.supplementRequestRationale$ | async as supplementRequestRationale"
                                   [variant]="'warning'">
        {{'PROFILE.APPLICATIONS.SUPPLEMENT_REQUESTED_RATIONALE' | translate}}<br
        aria-hidden="true"/>{{supplementRequestRationale}}
      </sis-contextual-notification>
      <div class="form-group-mimic">
        <sis-wizard-progress [stepKeys]="wizardStepKeys" [currentStep]="currentStep"></sis-wizard-progress>
      </div>
      <ng-container *ngIf="plan$ | async as plan">
        <app-custom-attainment-basic-info-edit [hidden]="!isFirstStep()"
                                               [workflowApplication]="application"
                                               [plan]="plan"
                                               [customStudyDraft]="customStudyDraft"
                                               (exit)="exit()"
                                               (continue)="continue($event)">
        </app-custom-attainment-basic-info-edit>
      </ng-container>
      <ng-container *ngIf="existingAttachments$ | async as existingAttachments">
        <app-prior-learning-and-attachments-edit [hidden]="currentStep !== 1"
                                                 type="INCLUDE"
                                                 [initialPriorLearnings]="application.priorLearnings"
                                                 [initialAttachments]="existingAttachments"
                                                 [customStudyDraft]="customStudyDraft"
                                                 [workflowId]="workflow.id"
                                                 (attachmentsChange)="onAttachmentsChange($event)"
                                                 (exit)="exit()"
                                                 (previous)="previous()"
                                                 (continue)="continue($event)">
        </app-prior-learning-and-attachments-edit>
      </ng-container>
      <app-prior-learning-application-confirmation [hidden]="!isLastStep()"
                                                   [application]="application"
                                                   [attachments]="attachments"
                                                   [disableButtons]="uploading"
                                                   (exit)="exit()"
                                                   (previous)="previous()"
                                                   (submit)="submit($event)">
      </app-prior-learning-application-confirmation>
    </div>
  </div>
</div>
