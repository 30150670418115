<ng-container *transloco="let t">
  <div class="row">
    <div class="col-xl-4 col-md-6 col-sm-12">
      <sis-select-combobox [label]="t('ENTITY_NAMES.plan')"
                           [selection]="this.currentPlan.id"
                           [options]="planSelectionOptions"
                           (selectionChange)="selectPlan.emit($event)">
      </sis-select-combobox>
    </div>
    <div class="col-lg-6 ms-xl-5 ps-xl-5">
      <app-plan-header-info-list [currentPlan]="this.currentPlan"></app-plan-header-info-list>
    </div>
  </div>
  <hr class="sis-hr" />
</ng-container>


