import * as angular from 'angular';
import { IconComponent } from '../icon/icon.component.ts';
import template from './calendarEventTooltip.tpl.html';
export const calendarEventTooltipModule = 'sis-components.calendar.calendarEventTooltip';

/**
 * The eventData argument is an object that can have the following properties:
 *
 * cancelled: boolean (is the event cancelled)
 * eventName: LocalizedString
 * studyGroupSetName: LocalizedString
 * studySubGroupName: LocalizedString
 * teachers: PublicPerson[]
 * teachersRemoved: boolean (should be true if there's an event override that has cleared the default teacher info)
 * locations: Location[]
 * locationsRemoved: boolean (should be true if there's an event override that has cleared the default location info)
 * notice: LocalizedString
 * start: Moment
 * end: Moment
 */
angular.module(calendarEventTooltipModule, [IconComponent.downgrade.moduleName]).component('calendarEventTooltip', {
  template,
  bindings: {
    eventData: '<'
  }
});