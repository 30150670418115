<form>
  <div class="modal-header">
    <h2 class="modal-title" [tabIndex]="0">{{'PROFILE.PRINT_TRANSCRIPT_MODAL.TITLE' | translate }}</h2>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <h3 translate>PROFILE.PRINT_TRANSCRIPT_MODAL.TRANSCRIPT_SELECTION.LABEL</h3>
        <fieldset class="form-group">
          <legend [class]="fullTranscriptSelectedLabelState" translate>
            PROFILE.STUDY_RIGHTS
          </legend>
          <sis-radio-button name="fullTranscriptSelected"
                            [control]="fullTranscriptSelected"
                            [value]="true"
                            [label]="'PROFILE.PRINT_TRANSCRIPT_MODAL.TRANSCRIPT_SELECTION.FULL_TRANSCRIPT' | translate">
          </sis-radio-button>
          <p
            class="guidance">{{'PROFILE.PRINT_TRANSCRIPT_MODAL.TRANSCRIPT_SELECTION.FULL_TRANSCRIPT_GUIDANCE' | translate}}</p>
          <div *ngIf="this.studentStudyRights$ | async as studentStudyRights">
            <sis-radio-button name="fullTranscriptSelected"
                              [disabled]="studentStudyRights.length === 0"
                              [control]="fullTranscriptSelected"
                              [value]="false"
                              [label]="'PROFILE.PRINT_TRANSCRIPT_MODAL.TRANSCRIPT_SELECTION.STUDY_RIGHT_TRANSCRIPTS' | translate">
            </sis-radio-button>
            <sis-contextual-notification *ngIf="studentStudyRights.length === 0"
                                         variant="warning">{{'PROFILE.PRINT_TRANSCRIPT_MODAL.TRANSCRIPT_SELECTION.NO_STUDY_RIGHTS_GUIDANCE' | translate }}</sis-contextual-notification>
            <p *ngIf="fullTranscriptSelected.value"
               class="guidance">{{'PROFILE.PRINT_TRANSCRIPT_MODAL.TRANSCRIPT_SELECTION.STUDY_RIGHT_TRANSCRIPTS_GUIDANCE' | translate}}</p>
            <div *ngIf="!fullTranscriptSelected.value" class="study-rights-container">
              <div class="row study-rights form-group">
                <div class="col-12">
                  <p
                    class="guidance">{{'PROFILE.PRINT_TRANSCRIPT_MODAL.TRANSCRIPT_SELECTION.STUDY_RIGHT_TRANSCRIPTS_GUIDANCE' | translate}}</p>
                  <fieldset>
                    <legend class="visually-hidden" translate>
                      PROFILE.PRINT_TRANSCRIPT_MODAL.TRANSCRIPT_SELECTION.STUDY_RIGHT_TRANSCRIPTS
                    </legend>
                    <ng-container *ngFor="let studyRight of studentStudyRights">
                      <sis-form-checkbox *ngIf="studyRight.educationId | education | async as education"
                                         [name]="studyRight.id+'-checkbox'"
                                         [label]="education.name | localizedString"
                                         [control]="getSelectedStudyRightSelectionControl(studyRight.id)">
                        <sis-study-right-status-badge class="sis-form-checkbox-label-content"
                                                      [studyRightId]="studyRight.id">
                        </sis-study-right-status-badge>
                      </sis-form-checkbox>
                    </ng-container>
                    <sis-validation-errors [control]="selectedStudyRights"></sis-validation-errors>
                  </fieldset>
                </div>
              </div>
            </div>
          </div>
          <div class="row course-assessments">
            <div class="col-12">
              <div class="row">
                <legend translate>PROFILE.PRINT_TRANSCRIPT_MODAL.COURSE_ASSESSMENTS</legend>
              </div>
              <div class="row">
              <sis-form-checkbox
                 [control]="includeAssessmentItems"
                 [name]="'include-assessment-items-checkbox'"
                 [label]="'PROFILE.PRINT_TRANSCRIPT_MODAL.PRINT_COURSE_ASSESSMENTS'">
              </sis-form-checkbox>
              <p class="guidance" translate>PROFILE.PRINT_TRANSCRIPT_MODAL.PRINT_COURSE_ASSESSMENTS_GUIDANCE</p>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </div>

    <div class="form-group">
      <div class="row">
        <div class="col-12">
          <h3 translate>PROFILE.PRINT_TRANSCRIPT_MODAL.PRINT_SETTINGS</h3>
          <fieldset>
            <legend [class]="selectedLanguagesLabelState()" translate>PROFILE.PRINT_TRANSCRIPT_MODAL.LANGUAGE</legend>
            <p class="guidance" translate>PROFILE.PRINT_TRANSCRIPT_MODAL.GUIDANCE</p>
            <div class="checkbox-group">
              <ng-container *ngFor="let option of languageOptions">
                <sis-form-checkbox
                  [name]="option.formKey+'-checkbox'"
                  [control]="getLanguageSelectionControl(option.formKey)"
                  [label]="option.translationKey | translate">
                </sis-form-checkbox>
              </ng-container>
            </div>
            <sis-validation-errors [control]="selectedLanguages"></sis-validation-errors>
          </fieldset>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary btn-hollow btn-lg"
            type="button"
            (click)="this.cancel()"
            translate>PROFILE.PRINT_TRANSCRIPT_MODAL.CANCEL
    </button>
    <button class="btn btn-primary btn-lg"
            type="button"
            (click)="this.sendRequest()"
            translate>PROFILE.PRINT_TRANSCRIPT_MODAL.PRINT
    </button>
  </div>
</form>
