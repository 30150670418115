'use strict';

(function () {
  initAssessmentItemTypeModel.$inject = ["assessmentItemTypeJsDataModel", "$http", "$log"];
  assessmentItemTypeModel.$inject = ["DS"];
  angular.module('sis-components.model.assessmentItemType', ['js-data']).factory('assessmentItemTypeJsDataModel', assessmentItemTypeModel).run(initAssessmentItemTypeModel);
  var ENDPOINT = '/kori/api/cached/codebooks/urn:code:assessment-item-type';

  /**
   * @ngInject
   */
  function assessmentItemTypeModel(DS) {
    return DS.defineResource({
      idAttribute: 'urn',
      name: 'assessmentItemType',
      endpoint: ENDPOINT,
      watchChanges: false
    });
  }

  /**
   * @ngInject
   */
  function initAssessmentItemTypeModel(assessmentItemTypeJsDataModel, $http, $log) {
    $http.get(ENDPOINT).then(function (response) {
      assessmentItemTypeJsDataModel.inject(_.values(response.data));
    }).catch(function (error) {
      $log.error(error);
    });
  }
})();