minorSelectionModalService.$inject = ["modalService"];
import angular from 'angular';
import { ModalService } from 'sis-common/modal/modal.service.ts';
import { MinorSelectionModalComponent } from './minorSelectionModal-ng.component.ts';
angular.module('student.plan.minorSelectionModalService', ['student.plan.minorSelectionModal', ModalService.downgrade.moduleName]).factory('minorSelectionModalService', minorSelectionModalService);
function minorSelectionModalService(modalService) {
  return {
    open: (module, studyRight, validatablePlan) => modalService.open(MinorSelectionModalComponent, {
      module,
      studyRight,
      validatablePlan
    }).result
  };
}