<ul class="list-unstyled sis-m-zero">
  @for (content of listContents; track content.id) {
    @if (!collapsed() || $index < limit) {
      <li #listItem tabindex="-1">
        <ng-container [ngTemplateOutlet]="content.templateRef"/>
      </li>
    }
  }
</ul>

@if (listContents?.length > limit) {
  <sis-show-more
    [text]="(collapsed() ? 'FRONTPAGE.SHOW_MORE' : 'FRONTPAGE.SHOW_LESS') | transloco: { amount: listContents.length - limit }"
    [direction]="collapsed() ? 'cw-90' : 'ccw-90'"
    [placement]="collapsed() ? 'bottom' : 'top'"
    class="d-block sis-pr-lg"
    [class.sis-mt-sm]="!collapsed()"
    (showMoreChanged)="toggleCollapsed()"
  />
}
