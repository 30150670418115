import { Directive, ElementRef, Injector, Input, Output } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

import { CommonShowModuleContentApplicationValues } from './commonShowModuleContentApplicationModal.component';

/**
 * @deprecated
 */
// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'common-show-module-content-application-modal' })
export class CommonShowModuleContentApplicationModalDirective extends UpgradeComponent {

    @Output() close: ($value?: any) => void;
    @Input() resolve: CommonShowModuleContentApplicationValues;

    constructor(elementRef: ElementRef<any>, injector: Injector) {
        super('commonShowModuleContentApplicationModal', elementRef, injector);
    }
}
