<ng-container *transloco="let t">
  <sis-description-list *ngIf="showDescriptionInfo; else noInfo">
    <ng-template *ngIf="_module.learningOutcomes" #listItem>
      <dt>{{t('MODULE_INFO.LEARNING_OUTCOMES')}}</dt>
      <dd><sis-localized-markup-string-view [content]="_module.learningOutcomes"></sis-localized-markup-string-view></dd>
    </ng-template>
    <ng-template *ngIf="_module.contentDescription" #listItem>
      <dt>{{t('MODULE_INFO.CONTENT_DESC')}}</dt>
      <dd><sis-localized-markup-string-view [content]="_module.contentDescription"></sis-localized-markup-string-view></dd>
    </ng-template>
    <ng-template *ngIf="_module.additionalInfo" #listItem>
      <dt>{{t('MODULE_INFO.ADDITIONAL_INFO')}}</dt>
      <dd><sis-localized-markup-string-view [content]="_module.additionalInfo"></sis-localized-markup-string-view></dd>
    </ng-template>
    <ng-template *ngIf="_module.tweetText" #listItem>
      <dt>{{t('MODULE_INFO.TITLE_TWEETTEXT')}}</dt>
      <dd>{{_module.tweetText | localizedString}}</dd>
    </ng-template>
    <ng-template *ngIf="_module.outcomes" #listItem>
      <dt>{{t('MODULE_INFO.TITLE_OUTCOMES')}}</dt>
      <dd><sis-localized-markup-string-view [content]="_module.outcomes"></sis-localized-markup-string-view></dd>
    </ng-template>
    <ng-template *ngIf="_module.prerequisites" #listItem>
      <dt>{{t('MODULE_INFO.TITLE_PREREQUISITES')}}</dt>
      <dd><sis-localized-markup-string-view [content]="_module.prerequisites"></sis-localized-markup-string-view></dd>
    </ng-template>
    <ng-template *ngIf="_module?.searchTags?.length > 0" #listItem>
      <dt>{{t('MODULE_INFO.TITLE_SEARCH_TAGS')}}</dt>
      <ng-container *ngFor="let searchTag of _module.searchTags; let last = last">
        <dd>{{searchTag}}<span *ngIf="!last">,</span></dd>
      </ng-container>
    </ng-template>
  </sis-description-list>
  <ng-template #noInfo>
    <p>{{t('MODULE_INFO.NO_INFORMATION_GIVEN')}}</p>
  </ng-template>
</ng-container>

