import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AssessmentItem, CompletionMethod, CourseUnit, CourseUnitRealisation, Enrolment } from 'common-typescript/types';
import { ModalService } from 'sis-common/modal/modal.service';

export interface SelectAssessmentItemValues {
    assessmentItems: AssessmentItem[];
    completionMethod: CompletionMethod;
    courseUnit: CourseUnit;
    courseUnitRealisation: CourseUnitRealisation;
    enrolment: Enrolment;
}

@Component({
    selector: 'app-select-assessment-item-modal',
    template: '<select-assessment-item-modal (close)="activeModal.close($event.$value)" [resolve]="values"></select-assessment-item-modal>',
    encapsulation: ViewEncapsulation.None,
})
export class SelectAssessmentItemModalComponent {
    constructor(@Inject(ModalService.injectionToken) public values: SelectAssessmentItemValues,
                public activeModal: NgbActiveModal) {}
}
