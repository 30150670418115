import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { EnrolmentAllocationCounts } from 'common-typescript/types';

import { EntityService } from '../service/entity.service';

const CONFIG = {
    ENDPOINTS: {
        backend: '/ilmo/api',
        getAllocationCountsMultiple() {
            return `${this.backend}/enrolment-allocation-counts-by-course-unit-realisation-ids`;
        },
        // This endpoint works with anonymous users.
        getOpenUniversityAllocationCountsMultiple() {
            return `${this.backend}/open-university/enrolments/enrolment-allocation-counts-by-course-unit-realisation-ids`;
        },
    },
};
@Injectable({
    providedIn: 'root',
})
@NgEntityServiceConfig({
    baseUrl: CONFIG.ENDPOINTS.backend,
    resourceName: 'enrolment-allocation-counts',
})
export class EnrolmentAllocationCountsEntityService extends EntityService<EnrolmentAllocationCountsState> {

    constructor() {
        super(EnrolmentAllocationCountsStore, EnrolmentAllocationCountsQuery);
    }

    getAllocationCountsMultiple(courseUnitRealisationIds: string[]) {
        return this.getByPropertyIds(CONFIG.ENDPOINTS.getAllocationCountsMultiple(), 'courseUnitRealisationIds', courseUnitRealisationIds);
    }

    getOpenUniversityAllocationCountsMultiple(courseUnitRealisationIds: string[]) {
        return this.getByPropertyIds(CONFIG.ENDPOINTS.getOpenUniversityAllocationCountsMultiple(), 'courseUnitRealisationIds', courseUnitRealisationIds);
    }
}

type EnrolmentAllocationCountsState = EntityState<EnrolmentAllocationCounts>;

@StoreConfig({ name: 'enrolment-allocation-counts' })
class EnrolmentAllocationCountsStore extends EntityStore<EnrolmentAllocationCountsState> {}

class EnrolmentAllocationCountsQuery extends QueryEntity<EnrolmentAllocationCountsState> {
    constructor(protected store: EnrolmentAllocationCountsStore) {
        super(store);
    }
}
