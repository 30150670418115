import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { LocalizedString } from 'common-typescript/types';
import { LocaleService } from 'sis-common/l10n/locale.service';

@Component({
    selector: 'sis-localized-string',
    templateUrl: './localized-string.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class LocalizedStringComponent implements OnInit {

    constructor(private localeService: LocaleService) {}

    @Input() data: LocalizedString;
    @Input() selectedLocale: string;
    @Output() selectedLocaleChange = new EventEmitter<string>();
    @Input() preventValidation: boolean;
    // Optional text to display in case 'data' is missing (falsy)
    @Input() placeholderText?: string;
    // Optional parameter to display a tooltip box after the locale-buttons.
    @Input() tooltipText?: string;

    // If showMoreText true hides text after certain amount of chars
    // and buttons to reveal or hide rest of the text
    // and showing "-" instead of empty space
    @Input() showMoreText?: boolean;
    @Input() maxCharsBeforeOverFlow: number;
    @Input() showMoreButtonText: string;
    @Input() showLessButtonText: string;
    // Optional text to display in place of an empty value
    @Input() emptyText?: string;

    supportedLocales: string[] = [];

    ngOnInit(): void {
        this.selectedLocale = this.selectedLocale || this.localeService.getCurrentLanguage();
        this.supportedLocales = this.localeService.getOfficialLanguages();
    }

    setLocale(lang: string) {
        this.selectedLocale = lang;
        this.selectedLocaleChange.emit(this.selectedLocale);
    }
}
