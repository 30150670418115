<div class="modal-header">
  <h2 class="modal-title" [tabIndex]="0" translate>{{values.title}}</h2>
</div>
<div class="modal-body">
  <sis-contextual-notification *ngFor="let notification of notifications" variant="warning" class="modal-notification">
    <span>{{ notification }}</span>
  </sis-contextual-notification>

  <p *ngFor="let description of descriptions">
    <transloco [content]="description | translate:{ name: values.name }" />
  </p>

  <p *ngIf="values.objects">
    <transloco [content]="values.listTitle | translate:{ count: values.objects.length }" />
  </p>
  <ul class="objects">
    <li class="object" *ngFor="let object of values.objects">
      {{object}}
    </li>
  </ul>
  <div *ngFor="let descriptionSet of values.descriptionSets">
    <p *ngFor="let description of descriptionSet.descriptions">
      <transloco [content]="description | translate:{ name: descriptionSet.name }" />
    </p>
    <p *ngIf="descriptionSet.listTitle">
      <transloco [content]="values.listTitle | translate:{ count: descriptionSet.objects.length }" />
    </p>
    <ul class="objects">
      <li class="object" *ngFor="let object of descriptionSet.objects">
        {{object}}
      </li>
    </ul>
  </div>
</div>
<div class="modal-footer confirm-dialog-footer">
  <button *ngIf="!values.hideCancel"
          class="btn btn-primary btn-hollow btn-lg"
          data-cy="confirm-dialog-cancel"
          type="button"
          (click)="modalInstance.dismiss()"
          [translate]="values.cancelText || 'SIS_COMPONENTS.CONFIRM.CANCEL'"></button>
  <button class="btn btn-primary btn-lg"
          data-cy="confirm-dialog-confirm"
          type="button"
          (click)="close()"
          [translate]="values.confirmText || 'SIS_COMPONENTS.CONFIRM.YES'"></button>
</div>
