import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { StateService } from '@uirouter/angular';
import {
    DegreeProgrammeAttainment,
    DegreeProgrammeAttainmentWorkflow,
    Module,
    ModuleAttainment,
    OtmId, Plan,
} from 'common-typescript/types';

@Component({
    selector: 'app-student-module-info-modal-graduation-info',
    templateUrl: './student-module-info-modal-graduation-info.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudentModuleInfoModalGraduationInfoComponent {

    @Input() module: Module;
    @Input() plan: Plan;
    @Input() organisationId: OtmId;
    @Input() selectedModuleAttainment: ModuleAttainment | DegreeProgrammeAttainment;
    @Input() degreeProgrammeAttainmentWorkflow: DegreeProgrammeAttainmentWorkflow;
    @Output() closeModal = new EventEmitter<void>();

    constructor(private stateService: StateService) {}

    navigateToDegreeProgrammeAttainmentWorkflowCreation(): void {
        this.stateService.go(
            'student.logged-in.profile.applications.create-degree-programme-attainment-application',
            {
                planId: this.plan.id,
                degreeProgrammeId: this.module.id,
            });
        this.closeModal.emit();
    }

}
