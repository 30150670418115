import { Component, Inject, Input, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { ValidatablePlan } from 'common-typescript/src/plan/validation/validatablePlan';
import { CourseUnit, CourseUnitRule, EntityWithRule, OtmId, Rule } from 'common-typescript/types';
import _ from 'lodash';
import {
    ComponentDowngradeMappings,
    DowngradedComponent,
    StaticMembers,
} from 'sis-common/types/angular-hybrid';

import {
    COURSE_UNIT_SERVICE,
    RULE_SERVICE,
    VALID_ATTAINMENT_FILTER_SERVICE,
} from '../../../ajs-upgraded-modules';
import { PlanRuleSelectionService } from '../../../service/plan-rule-selection.service';
import { PlanData, PlanStateObject } from '../../../service/plan-state.service';
import { StudySelectorService } from '../../../service/study-selector.service';
import { PLAN_ACTIONS_SERVICE_INJECTION_TOKEN, PlanActionsService } from '../../plan-actions-service/plan-actions.service';
import { UiStateObject } from '../../plan-edit/plan-edit.component';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-course-unit-rule',
    templateUrl: './course-unit-rule.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class CourseUnitRuleComponent implements OnInit {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.plan.rules.courseUnitRule',
        directiveName: 'sisCourseUnitRule',
    };

    courseUnit: CourseUnit;
    courseUnitRule: CourseUnitRule;

    selected = false;

    @Input() parentModule: EntityWithRule;
    @Input() rule: Rule;
    @Input() validatablePlan: ValidatablePlan;
    @Input() planValidationResult: any;
    @Input() planStateObject: PlanStateObject;
    @Input() planData: PlanData;
    @Input() uiStateObject: UiStateObject;
    @Input() level: number;
    @Input() blockLevel: number;

    constructor(
        private studySelectorService: StudySelectorService,
        private planRuleSelectionService: PlanRuleSelectionService,
        @Optional() @Inject(PLAN_ACTIONS_SERVICE_INJECTION_TOKEN) private planActionsService: PlanActionsService,
        @Inject(COURSE_UNIT_SERVICE) private courseUnitService: any,
        @Inject(VALID_ATTAINMENT_FILTER_SERVICE) private validAttainmentFilterService: any,
        @Inject(RULE_SERVICE) private ruleService: any,
    ) {}

    ngOnInit() {
        this.courseUnitRule = this.rule as CourseUnitRule;
        this.courseUnit = _.find(_.values(this.planData.courseUnitsById), { groupId: this.courseUnitRule.courseUnitGroupId });
        if (!this.courseUnit) {
            const courseUnitAttainment = this.validatablePlan.getCourseUnitAttainmentByGroupId(this.courseUnitRule.courseUnitGroupId);
            if (courseUnitAttainment) {
                this.courseUnit = _.get(this.validatablePlan.courseUnitsById, courseUnitAttainment.courseUnitId);
            } else {
                this.courseUnit = this.validatablePlan.getCourseUnitInPlanByGroupId(this.courseUnitRule.courseUnitGroupId);
            }
        }
        if (!this.courseUnit) {
            const parentCurriculumPeriodIds = this.validatablePlan.getCurriculumPeriodIdsRecursively(this.parentModule);
            const planCurriculumPeriodId = this.validatablePlan.plan.curriculumPeriodId;
            this.courseUnitService.getDataForStudySelector(this.courseUnitRule.courseUnitGroupId).then((studySelectorData: any) => {
                this.courseUnit = this.studySelectorService.selectStudyOfGroup(
                    studySelectorData.allCourseUnitVersions,
                    parentCurriculumPeriodIds,
                    planCurriculumPeriodId,
                    studySelectorData.allCurriculumPeriods,
                ) as CourseUnit;
            });

        }
    }

    toggle(event: Event) {
        event.preventDefault();
        if (this.planRuleSelectionService.isImplicit(this.courseUnit, this.parentModule, this.planStateObject)
            && this.planRuleSelectionService.canImplicitlySelectedCourseUnitBeMovedToTargetModule(
                this.courseUnit,
                this.parentModule,
                this.validatablePlan,
                this.planStateObject)) {
            this.planActionsService?.moveCourseUnit?.(this.courseUnit, this.parentModule);
            return;
        }
        if (this.planRuleSelectionService.isCourseUnitSelectionDisabled(this.courseUnit, this.parentModule, this.validatablePlan, this.planStateObject)
            && !this.isCourseUnitRemoveAllowed(this.courseUnit, this.parentModule)) {
            return;
        }
        if (!this.isSelectedCourseUnit(this.courseUnit.id)) {
            this.planActionsService?.selectCourseUnit?.(this.courseUnit, this.parentModule);
        } else {
            this.planActionsService?.removeCourseUnit?.(this.courseUnit, this.parentModule);
        }
    }

    isSelectedCourseUnit(courseUnitId: OtmId): boolean {
        const courseUnitStateObject = _.get(this.planStateObject.courseUnits, courseUnitId);
        return courseUnitStateObject && courseUnitStateObject.selectedParentModuleId === this.parentModule.id;
    }

    isTogglingEnabled(courseUnit: CourseUnit): boolean {
        return !this.planRuleSelectionService.isCourseUnitSelectionDisabled(courseUnit, this.parentModule, this.validatablePlan, this.planStateObject);
    }

    isCourseUnitRemoveAllowed(courseUnit: CourseUnit, parentModule: EntityWithRule) {
        const currentParent = this.validatablePlan.getParentModuleOrCustomModuleAttainmentForCourseUnit(courseUnit);
        if (_.get(currentParent, 'id') !== _.get(parentModule, 'id')) {
            // Cannot remove if already selected elsewhere
            return false;
        }
        if (this.validatablePlan.isModuleAttained(parentModule.id)) {
            return false;
        }
        const courseUnitAttainment = this.validatablePlan.getCourseUnitAttainment(courseUnit.id);
        if (courseUnitAttainment && this.validAttainmentFilterService.isAttached(courseUnitAttainment, _.values(this.validatablePlan.getAllAttainments()))) {
            return false;
        }
        if (currentParent) {
            return this.ruleService.isRequiredCourseUnit(currentParent, courseUnit);
        }

        return true;
    }

}
