timelineCustomStudyDraftController.$inject = ["customStudyDraftInfoModalService", "defaultPromiseHandler"];
import angular from 'angular';
import { customStudyDraftInfoModalModule } from 'sis-components/customStudyDraft/customStudyDraftInfoModal.service';
import { errorServiceModule } from 'sis-components/error-handler/legacy/errorService';
import timelineCustomStudyDraftTpl from './timelineCustomStudyDraft.tpl.html';
export const timelineCustomStudyDraftModule = 'student.timing.timelineCustomStudyDraft';
angular.module('student.timing.timelineCustomStudyDraft', [customStudyDraftInfoModalModule, errorServiceModule]).component('timelineCustomStudyDraft', {
  bindings: {
    customStudyDraft: '<',
    isActive: '<',
    hasMenu: '<',
    removeFromPeriod: '&',
    removeFromTimeline: '&',
    startTiming: '&',
    startMoving: '&'
  },
  template: timelineCustomStudyDraftTpl,
  controller: timelineCustomStudyDraftController
});
function timelineCustomStudyDraftController(customStudyDraftInfoModalService, defaultPromiseHandler) {
  const $ctrl = this;
  $ctrl.colorCategory = 'study-draft';
  $ctrl.menuItems = [{
    action: () => $ctrl.removeFromTimeline(),
    name: 'TIMELINE.UNTIME'
  }, {
    action: () => $ctrl.startTiming(),
    name: 'TIMELINE.EDIT_TIMING'
  }, {
    action: () => $ctrl.startMoving(),
    name: 'TIMELINE.MOVE_TIMING'
  }];
  $ctrl.openCustomStudyDraftInfoModal = () => {
    customStudyDraftInfoModalService.open($ctrl.customStudyDraft).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
  };
}