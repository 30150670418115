import { Injectable } from '@angular/core';
import { Attainment, OtmId } from 'common-typescript/types';
import moment from 'moment';

import { AttainmentEntityService } from './attainment-entity.service';

@Injectable({ providedIn: 'root' })
export class AttainmentValidityService {
    constructor(private readonly _attainmentEntityService: AttainmentEntityService) {}

    /**
     * Returns those {@link Attainment.id}s from {@link attainments} that
     * a) are themselves valid
     * or
     * b) are child/descendant attainments of other valid {@link attainments}.
     */
    getValidAttainmentIds(attainments: readonly Attainment[]): Set<OtmId> {
        const validAttainmentIds = new Set<OtmId>();

        if (!attainments.length) {
            return validAttainmentIds;
        }

        const attainmentMap: Map<OtmId, Attainment> = new Map<OtmId, Attainment>(attainments.map(a => [a.id, a]));
        const currentMoment: moment.Moment = moment();

        for (const attainment of attainments) {
            if (!attainment.misregistration && !!attainment.primary && attainment.state !== 'FAILED') {
                if (!attainment.expiryDate || !moment(attainment.expiryDate).isSameOrBefore(currentMoment, 'days')) {
                    validAttainmentIds.add(attainment.id);
                    this.collectValidAttainmentsRecursively(attainment, attainmentMap, validAttainmentIds);
                }
            }
        }
        return validAttainmentIds;
    }

    /**
     * Returns those from {@link attainments} (in original order) that
     * a) are themselves valid
     * or
     * b) are child/descendant attainments of other valid {@link attainments}.
     */
    getValidAttainments(attainments: readonly Attainment[]): Attainment[] {
        if (!attainments.length) {
            return [];
        }

        const validAttainmentIds: Set<OtmId> = this.getValidAttainmentIds(attainments);
        return validAttainmentIds.size > 0
            ? attainments.filter(a => validAttainmentIds.has(a.id))
            : [];
    }

    private collectValidAttainmentsRecursively(attainment: Attainment, attainmentMap: ReadonlyMap<OtmId, Attainment>, validAttainmentIds: Set<OtmId>): void {
        for (const childAttachmentId of this._attainmentEntityService.toChildAttainmentIds(attainment)) {
            if (!validAttainmentIds.has(childAttachmentId)) {
                const child: Attainment | undefined = attainmentMap.get(childAttachmentId);
                if (child) {
                    validAttainmentIds.add(childAttachmentId);
                    this.collectValidAttainmentsRecursively(child, attainmentMap, validAttainmentIds);
                }
            }
        }
    }
}
