<ng-container *transloco="let t">
  <div aria-labelledby="student-profile-applications-tab" id="applications" role="tabpanel" tabindex="0">
    <h2>{{ 'PROFILE.TAB_APPLICATIONS' | transloco }}</h2>
    <ng-container *ngIf="searchResult">
      <ng-container *ngIf="searchResult.searchResults.length > 0; else noApplications">
        <sis-student-workflow-list
          [sortColumn]="sortAndPaginationEvent.sortColumn"
          [reverse]="sortAndPaginationEvent.reverse"
          [studentWorkflowSearchResult]="searchResult"
          [studentPageView]="true"
          [workflowLinkCreator]="workflowLinkCreator"
          (setSortAndPagination)="loadApplications($event)"
          [columns]="['name','initiatorType', 'state', 'lastHandledTime', 'lastHandlerPerson', 'creationTime']"
          [sortColumns]="['code', 'state', 'lastHandledTime', 'creationTime']">
        </sis-student-workflow-list>
      </ng-container>
    </ng-container>
  </div>
  <ng-template #noApplications>
    <p class="guidance">{{t('PROFILE.APPLICATIONS.NO_APPLICATIONS')}}</p>
  </ng-template>
</ng-container>
