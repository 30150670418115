'use strict';

angular.module('student.timing.constant', []).constant('timelineConfig', {
  SCALES: [7.0, 5.0, 3.0, 2.0, 1.0],
  // zoom scales
  studyYearsOffset: 10,
  // years to show from future
  studyYearsVisibleAfterStudyRight: 2,
  // after study right end
  rowType: {
    NOTE: 'note',
    PLANNED_TEACHING: 'plannedTeaching',
    COURSE_UNIT: 'courseUnit',
    CUSTOM_COURSE_UNIT_ATTAINMENT: 'customCourseUnitAttainment',
    CUSTOM_STUDY_DRAFT: 'customStudyDraft'
  },
  viewStates: {
    DEFAULT: 'view',
    VIEW: 'view',
    TIME_COURSE_UNIT: 'timeCourseUnit',
    TIME_CUSTOM_STUDY_DRAFT: 'timeCustomStudyDraft',
    MOVE_COURSE_UNIT_TIMING: 'moveCourseUnitTiming',
    MOVE_CUSTOM_STUDY_DRAFT_TIMING: 'moveCustomStudyDraftTiming',
    MOVE_NOTE_TIMING: 'moveNoteTiming',
    UNTIME_COURSE_UNIT: 'unTimeCourseUnit',
    UNTIME_MULTIPLE: 'unTimeMultipleCourseUnits',
    ADD_NOTE: 'addNote',
    EDIT_NOTE: 'editNote',
    INTRODUCE_TIMING_TEMPLATE: 'introduceTimingTemplate'
  },
  store: {
    PREFIX: 'timeline.',
    ZOOM_KEY: 'zoomLevel',
    SHOW_ALL_PERIODS_KEY: 'showAllPeriodsKey',
    SCROLL_KEY: 'scrollPositions',
    FOCUS_UNTIMED: false
  },
  studyRight: {
    status: {
      ACTIVE: 'active',
      // active study right
      OVER_TIME: 'over-time',
      // study right going over time
      REQUEST_MORE: 'request' // need to request new study right
    }
  }
});