import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CourseUnit, CourseUnitRealisation } from 'common-typescript/types';
import { ModalService } from 'sis-common/modal/modal.service';

export interface CourseUnitInfoValues {
    belongsToCurrentCurriculumPeriod?: boolean;
    courseUnit: CourseUnit;
    courseUnitRealisation?: CourseUnitRealisation;
}

@Component({
    selector: 'sis-course-unit-info-modal',
    template: '<course-unit-info-modal (close)="activeModal.close()" [resolve]="values"></course-unit-info-modal>',
    encapsulation: ViewEncapsulation.None,
})
export class CourseUnitInfoModalComponent {
    constructor(@Inject(ModalService.injectionToken) public values: CourseUnitInfoValues,
                public activeModal: NgbActiveModal) {}
}
