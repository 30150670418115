<div aria-labelledby="student-profile-enrolments-tab" id="enrolments" role="tabpanel" tabindex="0">
  <h2>{{ 'PROFILE.ENROLMENT.TITLE' | transloco }}</h2>
  <ng-container *ngIf="sortedRows$ | async as rows; else loading">
    <table class="table styled-table" *ngIf="rows?.length > 0; else noRows;">
      <caption>{{ 'PROFILE.ENROLMENT.CAPTION' | transloco }}</caption>
      <thead>
      <tr>
        <th>
          <sis-sortable-table-column-header
            name="{{ 'PROFILE.ENROLMENT.STUDY_MODULE' | transloco }}"
            column="name"
            [(reverse)]="this.sorting$.value.reverse"
            [orderKey]="this.sorting$.value.sortKey"
            (orderKeyChange)="sortBy('name')"
            ariaLabelType="ALPHABETICAL_ORDER">
          </sis-sortable-table-column-header>
        </th>
        <th class="without-sorter">
          {{ "PROFILE.ENROLMENT.CODE" | transloco }}
        </th>
        <th>
          <sis-sortable-table-column-header
            name="{{ 'PROFILE.ENROLMENT.CREDITS' | transloco }}"
            column="credits.min"
            [(reverse)]="this.sorting$.value.reverse"
            [orderKey]="this.sorting$.value.sortKey"
            (orderKeyChange)="sortBy('credits.min')"
            ariaLabelType="ALPHABETICAL_ORDER">
          </sis-sortable-table-column-header>
        </th>
        <th>
          <sis-sortable-table-column-header
            name="{{ 'PROFILE.ENROLMENT.STATUS' | transloco }}"
            column="state"
            [(reverse)]="this.sorting$.value.reverse"
            [orderKey]="this.sorting$.value.sortKey"
            (orderKeyChange)="sortBy('state')"
            ariaLabelType="ALPHABETICAL_ORDER">
          </sis-sortable-table-column-header>
        </th>
        <th class="without-sorter">{{ "PROFILE.ENROLMENT.ASSESSMENT_ITEM" | transloco }}</th>
        <th class="without-sorter"
           >{{ 'PROFILE.ENROLMENT.COURSE_UNIT_REALISATION' | transloco }}</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let row of rows; trackBy: getProfileEnrolmentRowEnrolmentId">
        <td [class.sorted-cell]="getSortKey() === 'name'">
          {{ row.courseUnitName | localizedString }}
        </td>
        <td>
          {{ row.code }}
        </td>
        <td [class.sorted-cell]="getSortKey() === 'credits.min'">
          {{ row.credits | creditRange }}
        </td>
        <td [class.sorted-cell]="getSortKey() === 'state'">
          <ng-container [ngSwitch]="row.enrolmentState">
            <sis-icon *ngSwitchCase="'ENROLLED'" [icon]="'check'" [color]="'success'"></sis-icon>
            <sis-icon *ngSwitchCase="'REJECTED'" [icon]="'fail'" [color]="'danger'"></sis-icon>
            <sis-icon *ngSwitchCase="'INVALID'" [icon]="'alert'" [color]="'danger'"></sis-icon>
            <sis-icon *ngSwitchDefault [icon]="'hourglass'" [color]="'default'"></sis-icon>
          </ng-container>
          <span>{{ ('ENROLMENT_STATE.' + row.enrolmentState) | transloco }}</span></td>
        <td>
          {{ row.assessmentItemName | localizedString }}
        </td>
        <td>
          {{ row.courseUnitRealisationName | localizedString }}
          <sis-tiny-badge *ngIf="row.courseUnitRealisationFlowState === 'CANCELLED'"
                      [flowState]="row.courseUnitRealisationFlowState">
            {{ ('RESOLVED_FLOWS_STATE.'+row.courseUnitRealisationFlowState) | transloco }}
          </sis-tiny-badge>
        </td>
      </tr>
      </tbody>
    </table>
    <ng-template #noRows>
      <p class="guidance">{{ "PROFILE.ENROLMENT.NO_ENROLMENTS" | transloco }}</p>
    </ng-template>
  </ng-container>
  <ng-template #loading>
    <div class="spinner-border" role="status">
      <span class="visually-hidden">{{ "PROFILE.ENROLMENT.LOADING" | transloco}}</span>
    </div>
  </ng-template>
</div>
