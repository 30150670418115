<ng-container *transloco="let t">
  <sis-tiny-badge *ngIf="currentPlan.primary" variant="success">{{t('PRIMARY_STUDY_PLAN')}}</sis-tiny-badge>
  <sis-description-list-compact [columnCount]="1">
    <dl>
      <ng-template #compactListItem>
        <dt class="label colon">{{t('ENTITY_NAMES.education')}}</dt>
        <dd class="value" *ngIf="currentPlan.rootId | education | async as education">
          {{t('STUDY_PLAN_EDUCATION_INFO',
                {
                  educationName: education.name | localizedString,
                  learningOpportunityName: currentPlan.learningOpportunityId | learningOpportunityName: education | localizedString,
                  curriculumPeriodName: currentPlan.curriculumPeriodId | curriculumPeriodName | async
                }
          )}}
        </dd>
      </ng-template>

      <ng-template #compactListItem>
        <dt class="label colon">{{t('FIELD_NAMES.created')}}</dt>
        <dd class="value">
            {{currentPlan.metadata.createdOn | localDateFormat}}
        </dd>
      </ng-template>

      <ng-template #compactListItem>
        <dt class="label colon">{{t('FIELD_NAMES.modified')}}</dt>
        <dd class="value">
            {{currentPlan.metadata.lastModifiedOn | localDateFormat}}
        </dd>
      </ng-template>
    </dl>
  </sis-description-list-compact>
</ng-container>
