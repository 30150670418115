import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
export const commonTermRegistrationPeriodServiceModule = 'sis-components.service.termRegistrationPeriodService';
(function () {
  termRegistrationPeriodService.$inject = ["$q", "$translate", "universityService", "termRegistrationPeriodJSDataModel"];
  angular.module(commonTermRegistrationPeriodServiceModule, ['pascalprecht.translate', 'sis-common.university', 'sis-components.model.termRegistrationPeriod']).factory('commonTermRegistrationPeriodService', termRegistrationPeriodService);

  /**
   * @ngInject
   */
  function termRegistrationPeriodService($q, $translate, universityService, termRegistrationPeriodJSDataModel) {
    function find(universityOrgId) {
      const universityId = _.isNil(universityOrgId) ? universityService.getCurrentUniversityOrgId() : universityOrgId;
      return termRegistrationPeriodJSDataModel.findAll({
        universityOrgId: universityId
      });
    }
    function findOngoingForCurrentUniversity() {
      const universityId = universityService.getCurrentUniversityOrgId();
      return termRegistrationPeriodJSDataModel.findAll({
        universityOrgId: universityId,
        validity: 'ONGOING'
      });
    }
    function save(termRegistrationPeriod) {
      return _.isNil(_.get(termRegistrationPeriod, 'id')) ? termRegistrationPeriodJSDataModel.create(termRegistrationPeriod) : termRegistrationPeriodJSDataModel.save(termRegistrationPeriod);
    }
    function revert(termRegistrationPeriod) {
      if (!_.isNil(_.get(termRegistrationPeriod, 'id'))) {
        termRegistrationPeriodJSDataModel.revert(termRegistrationPeriod);
      }
    }
    function destroy(termRegistrationPeriod) {
      return _.isNil(_.get(termRegistrationPeriod, 'id')) ? $q.when() : termRegistrationPeriodJSDataModel.destroy(termRegistrationPeriod.id);
    }
    function hasEndedPeriodForTerm(termRegistrationPeriods, studyTermLocator) {
      const matchingPeriods = (termRegistrationPeriods || []).filter(period => _.isEqual(studyTermLocator, period.term1) || _.isEqual(studyTermLocator, period.term2));
      return !_.isEmpty(matchingPeriods) && hasEveryPeriodEnded(matchingPeriods);
    }
    function hasEveryPeriodEnded(periods) {
      return periods.every(hasPeriodEnded);
    }
    function hasPeriodEnded(period) {
      return moment().isSameOrAfter(_.get(period, 'validityPeriod.endDate'), 'day');
    }

    /**
     * Finds a past registration period for a study term if it exists. If there are multiple registration periods for
     * the same study term (two for spring) the latest period is returned. Returns undefined if no period is found)
     */
    function findPastRegistrationPeriodForStudyTerm(studyTermLocator, universityOrgId) {
      if (!studyTermLocator || !studyTermLocator.studyYearStartYear) {
        return $q.when(false);
      }
      return termRegistrationPeriodJSDataModel.findAll({
        universityOrgId: _.isNil(universityOrgId) ? universityService.getCurrentUniversityOrgId() : universityOrgId,
        studyYearStartYear: studyTermLocator.studyYearStartYear
      }).then(termRegistrationPeriods => latestEndedRegistrationPeriodForTerm(termRegistrationPeriods, studyTermLocator));
    }
    function latestEndedRegistrationPeriodForTerm(termRegistrationPeriods, studyTermLocator) {
      const matchingPeriods = (termRegistrationPeriods || []).filter(period => _.isEqual(studyTermLocator, period.term1) || _.isEqual(studyTermLocator, period.term2));
      if (_.isEmpty(matchingPeriods) || !hasEveryPeriodEnded(matchingPeriods)) {
        return undefined;
      }
      return _.first(_.sortBy(_.filter(matchingPeriods, hasPeriodEnded), 'validityPeriod.endDate'));
    }
    return {
      find,
      findOngoingForCurrentUniversity,
      save,
      revert,
      delete: destroy,
      hasEndedPeriodForTerm,
      findPastRegistrationPeriodForStudyTerm
    };
  }
})();