import attainmentTreeTpl from './attainmentTree.tpl.html';
'use strict';
(function () {
  angular.module('sis-components.attainment.tree').component('attainmentTree', {
    template: attainmentTreeTpl,
    bindings: {
      tree: '<',
      onAttainmentClick: '&',
      attainmentsDisplayed: '<',
      attainmentEditActionSelected: '&',
      view: '<'
    },
    controller: ["$element", "attainmentTreeUtil", "attainmentSortHelper", "$translate", function ($element, attainmentTreeUtil, attainmentSortHelper, $translate) {
      var $ctrl = this;
      $ctrl.sortableFields = {
        name: {
          order: 'asc',
          type: 'localized'
        },
        attainmentDate: {
          order: 'desc',
          type: 'date'
        },
        credits: {
          order: 'asc',
          type: 'integer'
        },
        'grade.numericCorrespondence': {
          order: 'asc',
          type: 'integer'
        }
      };
      $ctrl.currentSort = '';
      $ctrl.$onInit = function () {
        $ctrl.sortBy('attainmentDate');
      };
      $ctrl.refreshDom = function () {
        var children = $element.children().not('.row.header');
        if (children.length > 0) {
          children.remove();
        }
        if ($ctrl.tree && $ctrl.tree.length > 0) {
          for (var i = 0; i < $ctrl.tree.length; ++i) {
            var childScope = {
              onAttainmentClick: $ctrl.onAttainmentClick ? $ctrl.onAttainmentClick : angular.noop,
              attainmentsDisplayed: $ctrl.attainmentsDisplayed,
              attainmentEditActionSelected: $ctrl.attainmentEditActionSelected,
              showMenuButton: $ctrl.showMenuButton(),
              row: $ctrl.tree[i]
            };
            $element.append(attainmentTreeUtil.getDirective(childScope));
          }
        }
      };
      $ctrl.getAriaSortValue = function (sortField) {
        if ($ctrl.currentSort === sortField) {
          if ($ctrl.sortableFields[sortField].order === 'asc') {
            return 'ascending';
          }
          return 'descending';
        }
        return 'none';
      };
      $ctrl.sortBy = function (sortField) {
        if ($ctrl.currentSort === sortField) {
          $ctrl.sortableFields[sortField].order = $ctrl.sortableFields[sortField].order === 'asc' ? 'desc' : 'asc';
        }
        $ctrl.currentSort = sortField;
        $ctrl.tree = sortTreeRecursive($ctrl.tree);
        $ctrl.refreshDom();
        function sortTreeRecursive(attainmentList) {
          var list = _.map(attainmentList, function (obj) {
            if (obj.attainments) {
              obj.attainments = sortTreeRecursive(obj.attainments);
            }
            return obj;
          });
          return attainmentSortHelper.sortBy(list, {
            field: sortField,
            order: $ctrl.sortableFields[sortField].order,
            type: $ctrl.sortableFields[sortField].type
          });
        }
      };
      $ctrl.showMenuButton = function () {
        return $ctrl.view === 'TUTOR';
      };
    }]
  });
})();