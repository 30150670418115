<sis-page-title [title]="'OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.STEP1.TITLE' | translate"
                [dataCy]="'activate-wizard-title-step-1'"/>
<sis-wizard-progress [stepKeys]="wizardProgressStepKeys" [currentStep]="0"></sis-wizard-progress>
<ng-container *ngIf="!isLoggedIn">
  <h2 data-cy="activate-wizard-step-1-heading">
    {{ 'OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.STEP1.SELECT_AUTH_METHOD_TITLE' | translate }}
  </h2>
  <p>{{ 'OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.STEP1.SELECT_AUTH_METHOD_DESC' | translate }}</p>
  <div class="auth-method-box-container">
    <div class="auth-method-box">
      <div class="uni-image-container suomifi-logo"></div>
      <p class="sis-guidance">
        {{ 'OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.STEP1.LOGIN_BOX_NEW_STUDENT_TITLE' | translate }}
      </p>
      <p class="sis-guidance">
        {{ 'OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.STEP1.LOGIN_BOX_NEW_STUDENT_DESC' | translate }}
      </p>
      <sis-button [disabled]="isLoggedIn" (clicked)="suomiFiLogin()" class="center-button" data-cy="suomi-fi-button">
        {{ 'SELECT' | translate }}
      </sis-button>
    </div>
    <div class="auth-method-box">
      <div class="uni-image-container university-top-navigation-logo"></div>
      <p class="sis-guidance">
        {{ 'OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.STEP1.LOGIN_BOX_EXISTING_STUDENT_TITLE' | translate }}
      </p>
      <p class="sis-guidance">
        {{ 'OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.STEP1.LOGIN_BOX_EXISTING_STUDENT_DESC' | translate }}
      </p>
      <sis-button (clicked)="universityLogin()" class="center-button" data-cy="university-login-button">
        {{ 'SELECT' | translate }}
      </sis-button>
    </div>
  </div>
  <sis-expandable [variant]="'naked'"
                  [level]="3"
                  [title]="'OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.STEP1.NO_DIGITAL_AUTH_TITLE' | translate">
    <ng-template sisExpandableContent>
      <p>{{ 'OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.STEP1.NO_DIGITAL_AUTH_DESC' | translate }}</p>
    </ng-template>
  </sis-expandable>
  <sis-expandable [variant]="'naked'"
                  [level]="3"
                  [title]="'OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.STEP1.OTHER_PERSON_ENROLLING_TITLE' | translate">
    <ng-template sisExpandableContent>
      <p>{{ 'OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.STEP1.OTHER_PERSON_ENROLLING_DESC' | translate }}</p>
      <sis-button (clicked)="copyLink()" [link]="true">
        {{ 'OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.STEP1.OTHER_PERSON_ENROLLING_LINK' | translate }}<sis-icon
        *ngIf="copiedLink" icon="check"></sis-icon>
      </sis-button>
    </ng-template>
  </sis-expandable>
</ng-container>
<h2>{{ 'OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.STEP1.REDEEMABLE_COURSE_UNITS_TITLE' | translate }}</h2>
<div *ngFor="let cu of courseUnits" class="cu-row sis-mb-sm">
  <h3 class="disable-default-bold">{{ cu.name | localizedString }}
    ({{ cu.credits | creditRange: 'TEXT': false }})</h3>
  <p data-cy="activate-wizard-step-1-course-unit-code">{{ cu.code }}</p>
</div>
<sis-wizard-step-buttons *ngIf="isLoggedIn"
                         [forwardButtonTitle]="'OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.STEP1.TITLE' | translate"
                         [backButtonDisplay]="false"
                         (forward)="universityLogin()">
</sis-wizard-step-buttons>
