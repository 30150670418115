'use strict';

(function () {
  angular.module('student.common.components.planSeeker', ['student.common.utils.uiStateStore', 'sis-components.service.studyRightService', 'student.common.service.planService']).factory('planSeeker', ["$q", "planService", "commonStudyRightService", "uiStateStore", function ($q, planService, commonStudyRightService, uiStateStore) {
    return {
      find: function () {
        var planInSession = uiStateStore.readField('otm.student.uiContext.', 'planId');
        if (planInSession) {
          return $q.when(planInSession);
        } else {
          return planService.findMyPlans().then(function (plans) {
            return commonStudyRightService.getValidStudyRightsByEducationIds(false, _.map(plans, 'rootId')).then(function (studyRights) {
              var selectPlan = null;
              if (studyRights) {
                plans = _.orderBy(plans, function (plan) {
                  return plan.metadata.lastModifiedOn;
                }, 'desc');
                _.forEach(plans, function (plan) {
                  if (_.find(studyRights, function (studyRight) {
                    return studyRight.educationId === plan.rootId;
                  })) {
                    selectPlan = plan;
                    return false;
                  }
                });
              }
              if (selectPlan) {
                return selectPlan.id;
              } else {
                var plan = null;
                var primaryPlan;
                var newestLastModified = moment(0); // 1970-01-01..
                var newestPrimaryPlanLastModified = moment(0);
                _.forEach(plans, function (planObject) {
                  var currentLastModified = moment(_.result(planObject, 'metadata.lastModifiedOn', 0));
                  if (!plan || currentLastModified.isAfter(newestLastModified)) {
                    plan = planObject;
                    newestLastModified = currentLastModified;
                  }
                  if ((!primaryPlan || currentLastModified.isAfter(newestPrimaryPlanLastModified)) && planObject.primary === true) {
                    primaryPlan = planObject;
                    newestPrimaryPlanLastModified = currentLastModified;
                  }
                });
                if (primaryPlan) {
                  return primaryPlan.id;
                } else if (plan) {
                  return plan.id;
                }
              }
            });
          });
        }
      },
      storeSelectedPlanId: function (selectedPlanId) {
        uiStateStore.storeField('otm.student.uiContext.', 'planId', selectedPlanId);
      }
    };
  }]);
})();