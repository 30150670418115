import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { Attainment } from 'common-typescript/types';
import { isAssessmentItemAttainment, isCourseUnitAttainment, isCustomCourseUnitAttainment } from 'sis-components/attainment/AttainmentUtil';
import { Icon } from 'sis-components/icon/icon.component';

@Component({
    selector: 'app-attainment-info',
    templateUrl: './attainment-info.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttainmentInfoComponent {

    @Input({ required: true }) attainment: Attainment;
    @Output() clicked = new EventEmitter<Attainment>();

    readonly isAssessmentItemAttainment = isAssessmentItemAttainment;
    readonly isCourseUnitAttainment = isCourseUnitAttainment;
    readonly isCustomCourseUnitAttainment = isCustomCourseUnitAttainment;

    get successIcon(): Icon {
        return this.attainment.type === 'AssessmentItemAttainment' ? 'check' : 'achievement';
    }
}
