import codeEditorTpl from './codeEditor.tpl.html';
(function () {
  angular.module("sis-components.string.codeEditor", ['pascalprecht.translate', 'sis-common.l10n.localizedStringFilter']).directive('codeEditor', codeEditorDirective).controller('codeEditorController', codeEditorController);

  /**
   * @ngInject
   */
  function codeEditorDirective() {
    return {
      require: ['^^form', 'ngModel'],
      restrict: 'E',
      scope: {
        name: '@',
        onlyUrnChars: '@attrOnlyUrnChars',
        onChange: '&onChangeCallback',
        minLength: '@',
        maxLength: '@',
        required: '@'
      },
      template: codeEditorTpl,
      bindToController: true,
      controller: 'codeEditorController as $ctrl',
      link: function (scope, elem, attr, ctrls) {
        var $ctrl = scope.$ctrl;
        $ctrl.form = ctrls[0];
        $ctrl.ngModel = ctrls[1];
        if (_.isEmpty(attr.name)) {
          throw 'codeEditor directive: name attribute is required';
        }
        attr.$observe('onlyUrnChars', function () {
          $ctrl.pattern = "([a-z0-9_]+)(-[a-z0-9_]+)*";
        });
        $ctrl.ngModel.$parsers.push(function (value) {
          if (_.isEmpty(value)) {
            return null;
          } else {
            return value;
          }
        });
        $ctrl.ngModel.$formatters.push(function (value) {
          if (_.isEmpty(value)) {
            return "";
          } else {
            return value;
          }
        });
      }
    };
  }

  /**
   * @ngInject
   */
  function codeEditorController() {
    var $ctrl = this;

    // $setViewValue is needed because angular ngModel does not do deep watch on the object, and will not notice
    // changes in the underlying objects.
    //
    // in certain scenarios $ctrl.ngModel may not be initialized
    // when update is called the first time, because controller is created before ngModel is set in the
    // link function
    $ctrl.valueChanged = function () {
      if (_.isFunction(_.get($ctrl.ngModel, "$setViewValue"))) {
        $ctrl.ngModel.$setViewValue(angular.copy($ctrl.ngModel.$viewValue));
        if ($ctrl.onChangeCallback) {
          $ctrl.onChangeCallback($ctrl.ngModel.$modelValue);
        }
      }
    };
  }
})();