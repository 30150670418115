import { Observable, Subscriber } from 'rxjs';

/**
 * Returns an RxJs observable that tracks changes in the size of an HTML element. Emits ResizeObserverEntry[] whenever the element's size changes.
 */
export function getElementResizeEventObservable(element: HTMLElement, options?: ResizeObserverOptions): Observable<ResizeObserverEntry[]> {
    return new Observable((subscriber: Subscriber<ResizeObserverEntry[]>) => {
        const resizeObserver: ResizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]): void => {
            subscriber.next(entries);
        });
        resizeObserver.observe(element, options);
        return function unsubscribe(): void {
            resizeObserver.unobserve(element);
        };
    });
}
