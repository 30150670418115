export const alertsServiceModule = 'sis-components.alertsService';
(function () {
  'use strict';

  angular.module(alertsServiceModule, []).service('alertsService', ["$rootScope", function ($rootScope) {
    return {
      dismissAllAlerts: function (pattern) {
        $rootScope.$broadcast('dismiss-all-alerts');
      },
      dismissAllMatchingAlerts: function (pattern) {
        $rootScope.$broadcast('dismiss-all-matching-alerts', {
          pattern: pattern
        });
      },
      dismissAlertIfExists: function (identifier) {
        $rootScope.$broadcast('dismiss-alert-if-exists', {
          identifier: identifier
        });
      },
      info: function (msg, dismiss, identifier, onClickCallback) {
        $rootScope.$broadcast('alert', {
          type: 'info',
          msg: msg,
          dismiss: dismiss,
          identifier: identifier,
          onClickCallback: onClickCallback
        });
      },
      warning: function (msg, dismiss, identifier, onClickCallback) {
        $rootScope.$broadcast('alert', {
          type: 'warning',
          msg: msg,
          dismiss: dismiss,
          identifier: identifier,
          onClickCallback: onClickCallback
        });
      },
      error: function (msg, dismiss, identifier, onClickCallback) {
        $rootScope.$broadcast('alert', {
          type: 'danger',
          msg: msg,
          dismiss: dismiss,
          identifier: identifier,
          onClickCallback: onClickCallback
        });
      },
      success: function (msg, dismiss, identifier, onClickCallback) {
        $rootScope.$broadcast('alert', {
          type: 'success',
          msg: msg,
          dismiss: dismiss,
          identifier: identifier,
          onClickCallback: onClickCallback
        });
      }
    };
  }]);
})();