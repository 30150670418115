import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    Input,
    OnChanges,
    SimpleChanges,
    ViewEncapsulation,
} from '@angular/core';
import { PlanValidationResult } from 'common-typescript/src/plan/validation/planValidationResult';
import { CreditRange, DegreeProgramme, GroupingModule, Module, StudyModule } from 'common-typescript/types';
import _ from 'lodash';

import { Option } from '../../menuButton/menu-button.component';
import {
    PLAN_ACTIONS_SERVICE_INJECTION_TOKEN,
    PlanActionsService,
} from '../../plan/plan-actions-service/plan-actions.service';
import { ModuleStateObject, PlanData, PlanStateObject } from '../../service/plan-state.service';

@Component({
    selector: 'sis-plan-structure-module',
    templateUrl: './plan-structure-module.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanStructureModuleComponent implements OnChanges {
    @Input({ required: true }) module: GroupingModule | StudyModule | DegreeProgramme;
    @Input() planValidationResult: PlanValidationResult;
    @Input({ required: true }) planStateObject: PlanStateObject;
    @Input({ required: true }) planData: PlanData;
    @Input() isImplicit: boolean;
    @Input() isParentModule?: Module;
    @Input({ required: true }) headingLevel: number;

    targetCredits: CreditRange;
    moduleStateObject: ModuleStateObject;

    constructor(@Inject(PLAN_ACTIONS_SERVICE_INJECTION_TOKEN) private planActionsService: PlanActionsService) {}

    ngOnChanges(changes: SimpleChanges) {
        this.moduleStateObject = _.get(this.planStateObject?.modules, this.module.id);
        if (this.module?.type !== 'GroupingModule') {
            this.targetCredits = this.module.targetCredits;
        }
    }

    openModuleInfoModal() {
        this.planActionsService.openModuleInfoModal(this.module);
    }

    openModule() {
        this.planActionsService.openModule(this.module);
    }
}
