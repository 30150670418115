import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ServiceBreak } from 'common-typescript/types';
import _ from 'lodash';
import { from, Observable } from 'rxjs';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { InfoDialogService } from '../info-dialog/info-dialog.service';
import { ServiceBreakService } from '../service/service-break.service';

@Component({
    selector: 'sis-service-break-banner',
    templateUrl: './sis-service-break-banner.component.html',
    encapsulation: ViewEncapsulation.None,
})
@StaticMembers<DowngradedComponent>()
export class SisServiceBreakBannerComponent implements OnInit {
    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.service-break-banner.sisServiceBreakBanner',
        directiveName: 'sisServiceBreakBanner',
    };

    private observableOfServiceBreaks: Observable<any>;

    currentServiceBreaks: ServiceBreak[];

    constructor(private dialogService: InfoDialogService,
                private localeService: LocaleService,
                private serviceBreakService: ServiceBreakService,
                private translateService: TranslateService) {}

    ngOnInit() {
        this.observableOfServiceBreaks = this.serviceBreakService.getObservableOfCurrentServiceBreaks();
        this.observableOfServiceBreaks.subscribe((breaks: ServiceBreak[]) => this.setCurrentServiceBreaks(breaks));
    }

    /**
     * @return <code>True</code> if there is any service break data to show info banners for.
     */
    get isAnyServiceBreakInfoToShow(): boolean {
        return this.currentServiceBreaks && this.currentServiceBreaks.length >= 1;
    }

    /**
     * @param breaks The service breaks for which to display info banners.
     */
    setCurrentServiceBreaks(breaks: ServiceBreak[]) {
        this.currentServiceBreaks = breaks;
    }

    /**
     * Returns `true` if the given service break has passed, `false` otherwise.
     *
     * @param serviceBreak The service break in question.
     */
    hasServiceBreakPassed(serviceBreak: ServiceBreak): boolean {
        return this.serviceBreakService.hasServiceBreakPassed(serviceBreak);
    }

    /**
     * Builds a localized info text about the service break, based on its type and time period.
     *
     * @param serviceBreak The service break to get the info text about.
     * @return Localized info text about the service break.
     */
    getServiceInfoText(serviceBreak: ServiceBreak): string {
        return this.serviceBreakService.getServiceInfoText(serviceBreak, new Date());
    }

    /**
     * Checks if the 'info' element is provided and contains anything to show.
     *
     * @param serviceBreak The service break in question.
     * @return <code>True</code> if there is additional info to show about the service break.
     */
    isAdditionalInfo(serviceBreak: ServiceBreak): boolean {
        return !_.isEmpty(serviceBreak.info);
    }

    /**
     * Checks if the 'releaseNotesLink' element is provided and has content to use.
     *
     * @param serviceBreak The service break in question.
     * @return <code>True</code> if there are release notes to show.
     */
    isReleaseNotes(serviceBreak: ServiceBreak): boolean {
        return !_.isEmpty(serviceBreak.releaseNotesLink);
    }

    /**
     * Open dialog for showing additional info about the service break.
     *
     * @param serviceBreak The service break in question.
     */
    openInfoDialog(serviceBreak: ServiceBreak): Observable<void> {
        const title = this.translateService.instant('UNAVAILABLE.ADDITIONAL_INFO');
        const description = this.localeService.localize(serviceBreak.info);
        return from(this.dialogService.open({ title, descriptions: [description] }));
    }
}
