function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import angular from 'angular';
import _ from 'lodash';
import { from, isObservable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { filterInput, TypeaheadComponent } from '../typeahead/typeahead.component.ts';
import searchEmployeeTpl from './searchEmployee.tpl.html';
export const searchEmployeeModule = 'sis-components.search.searchEmployee';
(function () {
  searchEmployeeController.$inject = ["$element", "$timeout", "personNameService"];
  angular.module(searchEmployeeModule, ['pascalprecht.translate', 'sis-common.errorhandler.errorService', 'sis-components.service.personNameService', 'sis-common.l10n.localizedStringFilter', TypeaheadComponent.downgrade.moduleName]).component('searchEmployee', {
    template: searchEmployeeTpl,
    controller: searchEmployeeController,
    bindings: {
      onSelect: '&',
      disable: '=?',
      disabledPersonIds: '=?',
      employeeService: '<',
      includeStudents: '<',
      employee: '=?',
      required: '<?'
    }
  });

  /**
   * @ngInject
   */
  function searchEmployeeController($element, $timeout, personNameService) {
    const $ctrl = this;
    $ctrl.$onInit = function () {
      if (_.isEmpty($ctrl.employeeService)) {
        throw new Error('searchEmployee directive: employeeService attribute is required');
      }
      if ($ctrl.employee && !$ctrl.employee.fullNameReversed) {
        $ctrl.employee = _objectSpread(_objectSpread({}, $ctrl.employee), {}, {
          fullNameReversed: personNameService.getFullNameReversed($ctrl.employee)
        });
      }
    };
    $ctrl.selectItem = function (selectedEmployeeSearchResult) {
      const changed = $ctrl.employee !== selectedEmployeeSearchResult;
      $ctrl.employee = selectedEmployeeSearchResult;
      if (angular.isDefined($ctrl.onSelect) && changed) {
        $timeout(() => {
          $ctrl.onSelect({
            personObj: $ctrl.employee
          });
        });
      }
    };
    $ctrl.isDisabled = function (item) {
      if (_.includes(_.get($ctrl, 'disabledPersonIds', []), item.id)) {
        return true;
      }
      return _.get($ctrl, 'employee.id') === item.id;
    };
    $ctrl.doSearch = function (query$) {
      return query$.pipe(filterInput(), switchMap(query => {
        const employeeSearch = $ctrl.employeeService.search(query, !!$ctrl.includeStudents);
        return isObservable(employeeSearch) ? employeeSearch : from(employeeSearch);
      }), map(result => _.map(result.searchResults, resultItem => {
        resultItem.disabled = $ctrl.isDisabled(resultItem);
        return resultItem;
      })));
    };

    // Formats responsible person name and email for the input box AND the dropdown. Unfortunately input is either
    // a PublicPerson object (input box in some cases) or a employee search result item (dropdown / input box in some cases)
    // so we need to handle both here.
    $ctrl.getVisibleTitle = function (personOrResultItem) {
      if (!personOrResultItem) {
        return '';
      }
      const email = personOrResultItem.primaryEmail || personOrResultItem.emailAddress || '';
      return `${personNameService.getFullNameReversed(personOrResultItem)}${email.length > 0 ? ` (${email})` : ''}`;
    };
  }
})();