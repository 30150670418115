import { Component, Input, ViewEncapsulation } from '@angular/core';
import { AttainmentNode } from 'common-typescript/types';

import { AttainmentsById } from './AttainmentTreeTypes';

@Component({
    selector: 'sis-attainment-tree-nodes',
    templateUrl: './attainment-tree-nodes.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class AttainmentTreeNodesComponent {

    @Input() attainmentNodes: AttainmentNode[];
    @Input() attainmentsById: AttainmentsById;
    @Input() selectedLocale?: string;

}
