import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import {
    PersonWithModuleResponsibilityInfoType,
    PublicPerson,
} from 'common-typescript/types';
import _ from 'lodash';
import { take, tap } from 'rxjs/operators';
import {
    ComponentDowngradeMappings,
    DowngradedComponent,
    StaticMembers,
} from 'sis-common/types/angular-hybrid';

import { AppErrorHandler } from '../../error-handler/app-error-handler';
import { PersonNameService } from '../../service/person-name.service';
import { PublicPersonEntityService } from '../../service/public-person-entity.service';

interface SortingCriterion {
    key: (info: any) => any;
    order: 'asc' | 'desc';
}

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-module-responsibility-info',
    templateUrl: './module-responsibility-info.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class ModuleResponsibilityInfoComponent implements OnChanges {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.moduleResponsibilityInfo',
        directiveName: 'sisModuleResponsibilityInfo',
    };

    responsiblePersonsById: { [id: string]: PublicPerson } = {};
    sortedResponsibilityInfos: PersonWithModuleResponsibilityInfoType[] = [];

    @Input() responsibilityInfos: PersonWithModuleResponsibilityInfoType[];
    @Input() language: string;

    constructor(
        private appErrorHandler: AppErrorHandler,
        private personNameService: PersonNameService,
        private publicPersonEntityService: PublicPersonEntityService,
    ) {}

    ngOnChanges() {
        this.updateResponsiblePersons();
    }

    updateResponsiblePersons() {
        const responsiblePersonIds = _.chain(this.responsibilityInfos)
            .map('personId')
            .uniq()
            .compact()
            .value();
        this.publicPersonEntityService.getByIds(responsiblePersonIds).pipe(
            take(1),
            tap((persons: PublicPerson[]) => {
                this.responsiblePersonsById = _.keyBy(persons, 'id');

                const sortingCriteria: SortingCriterion[] = [
                    { key: info => info.roleUrn === 'urn:code:module-responsibility-info-type:contact-info', order: 'desc' },
                    { key: info => info.roleUrn === 'urn:code:module-responsibility-info-type:administrative-person', order: 'desc' },
                    { key: info => info.roleUrn === 'urn:code:module-responsibility-info-type:responsible-teacher', order: 'desc' },
                    { key: info => !info.personId, order: 'asc' },
                    {
                        key: info => _.upperCase(this.personNameService.getFullName(_.get(this.responsiblePersonsById, info.personId))),
                        order: 'asc',
                    },
                ];
                this.sortedResponsibilityInfos = _.orderBy(this.responsibilityInfos, sortingCriteria.map(criterion => criterion.key), sortingCriteria.map(criterion => criterion.order));
            }),
            this.appErrorHandler.defaultErrorHandler())
            .subscribe();
    }
}
