import * as angular from 'angular';
import moment from 'moment';
import { dateUtils } from 'common-typescript';
export const localDateFormatFilterModule = 'sis-components.date.localDateFormatFilter';
(function () {
  localDateFormatUsingDeicticNames.$inject = ["$translate"];
  angular.module(localDateFormatFilterModule, ['pascalprecht.translate']).filter('localDateTimeFormat', localDateTimeFormatFactory).filter('localTimeFormat', localTimeFormatFactory).filter('localDateFormat', localDateFormatFactory).filter('localDateFormatWithDayOfWeek', localDateFormatWithDayOfWeek).filter('localDateTimeFormatWithDayOfWeek', localDateTimeFormatWithDayOfWeek).filter('localDateFormatUsingDeicticNames', localDateFormatUsingDeicticNames);
  function localDateFormatFactory() {
    return input => dateUtils.formatDateIfValid(input, 'l');
  }
  function localDateTimeFormatFactory() {
    return input => dateUtils.formatDateIfValid(input, 'l LT');
  }
  function localTimeFormatFactory() {
    return input => dateUtils.formatDateIfValid(input, 'HH.mm');
  }
  function localDateFormatWithDayOfWeek() {
    return input => dateUtils.formatDateIfValid(input, 'dd D.M.YYYY');
  }
  function localDateTimeFormatWithDayOfWeek() {
    return input => dateUtils.formatDateIfValid(input, 'dd DD.MM.YYYY LT');
  }

  /**
   * @ngInject
   */
  function localDateFormatUsingDeicticNames($translate) {
    return function (input) {
      if (input) {
        var inputMoment = moment(input);
        var today = moment();
        var yesterday = moment().subtract(1, 'day');
        if (inputMoment.isSame(today, 'day')) {
          return $translate.instant('SIS_COMPONENTS.DATE.TODAY');
        } else if (inputMoment.isSame(yesterday, 'day')) {
          return $translate.instant('SIS_COMPONENTS.DATE.YESTERDAY');
        } else {
          return moment(input).format('D.M.YYYY');
        }
      } else {
        return '';
      }
    };
  }
})();