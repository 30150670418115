import { Component, Input, ViewEncapsulation } from '@angular/core';
import { CourseUnit, PersonWithModuleResponsibilityInfoType } from 'common-typescript/types';
import {
    ComponentDowngradeMappings,
    DowngradedComponent,
    StaticMembers,
} from 'sis-common/types/angular-hybrid';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'app-course-unit-info-brochure',
    templateUrl: './course-unit-info-brochure.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CourseUnitInfoBrochureComponent {
    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'student.downgraded.courseUnitInfo.courseUnitInfoBrochure.sisCourseUnitInfoBrochure',
        directiveName: 'appCourseUnitInfoBrochure',
    };

    @Input() courseUnit: CourseUnit;
    @Input() language: string;
    /**
     * Determines heading's semantic aria-level for screen readers, default is equivalent for h3
     */
    @Input() level?: number = 3;
    @Input() sortedByNameResponsibilityInfos: PersonWithModuleResponsibilityInfoType[];
}
