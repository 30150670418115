import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Urn } from 'common-typescript/types';
import { getLabelState } from 'sis-components/form/formUtils';

@Component({
    selector: 'app-prior-competence-edit',
    templateUrl: './prior-competence-edit.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class PriorCompetenceEditComponent {

    @Input() formGroup: FormGroup;
    @Input() title: string;
    @Output() delete = new EventEmitter<void>();

    getLabelState = getLabelState;
    readonly languageCodeBookUrn = 'urn:code:language';

    onAttainmentLanguageChange(language: Urn): void {
        if (language !== this.attainmentLanguage.value) {
            this.attainmentLanguage.setValue(language);
            this.attainmentLanguage.markAsTouched();
            this.attainmentLanguage.markAsDirty();
        }
    }

    get attainmentLanguage(): FormControl {
        return this.formGroup?.get('attainmentLanguage') as FormControl;
    }

    get attainmentPeriod(): FormGroup {
        return this.formGroup?.get('attainmentPeriod') as FormGroup;
    }

    get description(): FormControl {
        return this.formGroup?.get('description') as FormControl;
    }

    get name(): FormControl {
        return this.formGroup?.get('name') as FormControl;
    }

    get organisation(): FormControl {
        return this.formGroup?.get('organisation') as FormControl;
    }
}
