import { Directive, ElementRef, Injector, Input, Output } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

import { AddOwnCalendarEventValues } from './addOwnCalendarEventModal.component';

/**
 * @deprecated
 */
// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'add-own-calendar-event-modal' })
export class AddOwnCalendarEventModalDirective extends UpgradeComponent {

    @Output() close: ($value?: any) => void;
    @Output() dismiss: ($value?: any) => void;
    @Input() resolve: AddOwnCalendarEventValues;

    constructor(elementRef: ElementRef<any>, injector: Injector) {
        super('addOwnCalendarEventModal', elementRef, injector);
    }
}
