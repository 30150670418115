import { UuidService } from 'sis-common/uuid/uuid.service.ts';
import literatureEditorTpl from './literatureEditor.tpl.html';
(function () {
  literatureEditorController.$inject = ["$scope", "uuidService"];
  angular.module('sis-components.literature.literatureEditor', ['pascalprecht.translate', 'sis-common.l10n.localizedStringFilter', UuidService.downgrade.moduleName, 'sis-components.string.urlEditor']).directive('literatureEditor', literatureEditorDirective).controller('literatureEditorController', literatureEditorController);

  /**
   * @ngInject
   */
  function literatureEditorDirective() {
    return {
      restrict: 'E',
      require: '^^form',
      scope: {
        name: '@',
        literature: '=',
        maxCount: '=',
        literatureNameMinLength: '@',
        literatureNameMaxLength: '@',
        literatureUrlMaxLength: '@'
      },
      link: function (scope, element, attrs, formCtrl) {
        var ctrl = scope.ctrl;
        ctrl.form = formCtrl;
      },
      template: literatureEditorTpl,
      controller: 'literatureEditorController as ctrl',
      bindToController: true
    };
  }

  /**
   * @ngInject
   */
  function literatureEditorController($scope, uuidService) {
    var ctrl = this;
    ctrl.possibleTypes = ['LiteratureReference', 'LiteratureName'];
    ctrl.$onInit = function () {
      if (_.isNil(ctrl.literature)) {
        ctrl.literature = [];
      }
    };
    ctrl.atLeastOneErrorWithName = function (errorName, fieldName) {
      var errors = _.get($scope.literatureForm, ['$error', errorName]);
      return _.find(errors, {
        $name: fieldName
      }) !== undefined;
    };
    ctrl.remove = function (item) {
      _.remove(ctrl.literature, {
        localId: item.localId
      });
      ctrl.form.$setDirty();
      ctrl.validate();
    };
    ctrl.addNew = function () {
      ctrl.literature.push({
        localId: uuidService.randomUUID()
      });
      ctrl.form.$setDirty();
      ctrl.validate();
    };
    ctrl.typeChanged = function (item) {
      ctrl.textChanged(item);
    };
    ctrl.isNameType = function (item) {
      var itemType = _.get(item, 'type');
      return itemType === 'LiteratureName' || !itemType;
    };
    ctrl.isUrlType = function (item) {
      var itemType = _.get(item, 'type');
      return itemType === 'LiteratureReference';
    };
    function validateMaxCount(maxCount, literature) {
      if (maxCount) {
        return literature.length <= maxCount;
      }
      return true;
    }
    ctrl.validate = function () {
      ctrl.form.$setValidity(ctrl.name + '.too_many', validateMaxCount(ctrl.maxCount, ctrl.literature));
    };
  }
})();