import { Injectable } from '@angular/core';
import { ModalService } from 'sis-common/modal/modal.service';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

import { InfoDialogComponent, InfoDialogValues } from './info-dialog.component';

/**
 * A service for opening an information dialog modal. The modal has no buttons in the footer but a close 'X' button in the top right corner.
 *
 * If you need confirmation buttons, use {@link ConfirmDialogService} instead.
 *
 * @see {@link InfoDialogComponent}
 */
@StaticMembers<DowngradedService>()
@Injectable({
    providedIn: 'root',
})
export class InfoDialogService {

    static downgrade: ServiceDowngradeMappings = {
        moduleName: 'sis-components.service.infoDialogService',
        serviceName: 'infoDialogService',
    };

    constructor(private modalService: ModalService) {}

    /**
     * Opens a new information dialog modal with the given values.
     *
     * @param values dialog content values
     * @return a Promise that resolves (without a value) when the dialog is closed
     */
    open(values: InfoDialogValues): Promise<void> {
        return this.modalService.open(InfoDialogComponent, values, { size: 'sm' }).result
            .catch(() => {}); // Treat closing with Esc key (that rejects the Promise) similarly to clicking the close icon
    }
}
