import angular from 'angular';
import _ from 'lodash';
import { UuidService } from 'sis-common/uuid/uuid.service.ts';
import { commonPlanServiceModule } from 'sis-components/service/plan.service';
angular.module('student.timing.timeLineCourseUnitService', [commonPlanServiceModule, UuidService.downgrade.moduleName, 'student.timing.note.timelineNoteModal']).factory('timeLineCourseUnitService', ["$log", "$q", "commonPlanService", function ($log, $q, commonPlanService) {
  function getCourseUnitSelection(plan, courseUnit) {
    var courseUnitSelection = plan.getCourseUnitSelection(courseUnit.id);
    if (!courseUnitSelection) {
      $log.warn('timeCourseUnitToPeriods,  courseUnitSelection for courseUnit not found from plan', 'courseUnit:', courseUnit, 'plan:', plan);
      courseUnitSelection = {};
    }
    if (!_.has(courseUnitSelection, 'plannedPeriods')) {
      courseUnitSelection.plannedPeriods = [];
    }
    return courseUnitSelection;
  }
  function getAttainedUntimedCourseUnitsForModule(validatablePlan, module) {
    var courseUnitsForModule = validatablePlan.getCourseUnitsOrSubstitutingCourseUnitsForModule(module);
    var unTimedAndAttainedCourseUnitsForModule = _.filter(courseUnitsForModule, function (courseUnit) {
      return !validatablePlan.isCourseUnitTimed(courseUnit.id) && validatablePlan.isCourseUnitAttained(courseUnit.id);
    });
    var childModulesForModule = validatablePlan.getSelectedModulesById(module);
    return _.concat(unTimedAndAttainedCourseUnitsForModule, _.flatMap(childModulesForModule, function (childModule) {
      return getAttainedUntimedCourseUnitsForModule(validatablePlan, childModule);
    }));
  }
  var api = {
    /**
     * This method times multiple courseUnits. New planned periods are concatenated to existing ones. Does
     * not time courseUnit if courseUnitSelection is not found in plan.
     * @param plan
     * @param courseUnitTimings array of courseUnitId and plannedPeriods pairs
     * @returns persisted plan
     */
    timeMultipleCourseUnitsToPeriods: function (plan, courseUnitTimings) {
      _.forEach(courseUnitTimings, function (courseUnitTiming) {
        var courseUnitSelection = getCourseUnitSelection(plan, courseUnitTiming.courseUnit);
        courseUnitSelection.plannedPeriods.push.apply(courseUnitSelection.plannedPeriods, courseUnitTiming.periodLocators);
      });
      return commonPlanService.saveMyPlan(plan);
    },
    timeMultipleCustomStudyDraftsToPeriods: (plan, customStudyDraftTimings) => {
      _.forEach(customStudyDraftTimings, customStudyDraftTiming => {
        const customStudyDraftSelection = _.find(_.get(plan, 'customStudyDrafts'), customStudyDraft => customStudyDraft.id === customStudyDraftTiming.customStudyDraft.id);
        customStudyDraftSelection.plannedPeriods.push.apply(customStudyDraftSelection.plannedPeriods, customStudyDraftTiming.periodLocators);
      });
      return commonPlanService.saveMyPlan(plan);
    },
    unTimeStudiesFromTimeLine: function (plan, courseUnits, customStudyDrafts) {
      _.forEach(courseUnits, courseUnit => {
        const courseUnitSelection = getCourseUnitSelection(plan, courseUnit);
        courseUnitSelection.plannedPeriods = [];
      });
      _.forEach(customStudyDrafts, customStudyDraft => {
        const customStudyDraftSelection = _.find(plan.customStudyDrafts, customStudyDraftInPlan => customStudyDraftInPlan.id === customStudyDraft.id);
        _.set(customStudyDraftSelection, 'plannedPeriods', []);
      });
      return commonPlanService.saveMyPlan(plan);
    },
    timeCourseUnitToPeriods: function (courseUnit, periodLocators, plan) {
      var courseUnitTimings = [{
        courseUnit: courseUnit,
        periodLocators: periodLocators
      }];
      return api.timeMultipleCourseUnitsToPeriods(plan, courseUnitTimings);
    },
    timeCustomStudyDraftToPeriods: function (customStudyDraft, periodLocators, plan) {
      const customStudyDraftTimings = [{
        customStudyDraft,
        periodLocators
      }];
      return api.timeMultipleCustomStudyDraftsToPeriods(plan, customStudyDraftTimings);
    },
    moveCourseUnitTimingToPeriods: function (courseUnit, periodLocators, plan) {
      var courseUnitSelection = getCourseUnitSelection(plan, courseUnit);
      courseUnitSelection.plannedPeriods.length = 0;
      return api.timeCourseUnitToPeriods(courseUnit, periodLocators, plan);
    },
    moveCustomStudyDraftTimingToPeriods: function (customStudyDraft, periodLocators, plan) {
      const customStudyDraftSelection = _.find(plan.customStudyDrafts, customStudyDraftInPlan => customStudyDraftInPlan.id === customStudyDraft.id);
      customStudyDraftSelection.plannedPeriods.length = 0;
      return api.timeCustomStudyDraftToPeriods(customStudyDraft, periodLocators, plan);
    },
    unTimeCustomStudyDraftFromTimeLine: function (customStudyDraft, plan) {
      const customStudyDraftSelection = _.find(plan.customStudyDrafts, customStudyDraftInPlan => customStudyDraftInPlan.id === customStudyDraft.id);
      _.set(customStudyDraftSelection, 'plannedPeriods', []);
      return commonPlanService.saveMyPlan(plan);
    },
    unTimeCourseUnitFromTimeLine: function (courseUnit, plan) {
      var courseUnitSelection = getCourseUnitSelection(plan, courseUnit);
      courseUnitSelection.plannedPeriods = [];
      return commonPlanService.saveMyPlan(plan);
    },
    unTimeCourseUnitFromPeriod: function (courseUnit, periodLocator, plan) {
      var courseUnitSelection = getCourseUnitSelection(plan, courseUnit);
      _.pull(courseUnitSelection.plannedPeriods, periodLocator);
      return commonPlanService.saveMyPlan(plan);
    },
    unTimeCustomStudyDraftFromPeriod: function (customStudyDraft, periodLocator, plan) {
      const customStudyDraftSelection = _.find(plan.customStudyDrafts, customStudyDraftInPlan => customStudyDraftInPlan.id === customStudyDraft.id);
      _.pull(customStudyDraftSelection.plannedPeriods, periodLocator);
      return commonPlanService.saveMyPlan(plan);
    },
    getUntimedCourseUnitsForModuleRecursively: function (module, validatablePlan) {
      var courseUnitsForModule = validatablePlan.getCourseUnitsOrSubstitutingCourseUnitsForModule(module);
      var unTimedCourseUnitsForModule = _.filter(courseUnitsForModule, function (courseUnit) {
        return !validatablePlan.isCourseUnitAttained(courseUnit.id) && !validatablePlan.isCourseUnitTimed(courseUnit.id);
      });
      var childModulesForModule = validatablePlan.getSelectedModulesById(module);
      const recursiveResult = _.concat(unTimedCourseUnitsForModule, _.flatMap(childModulesForModule, function (childModule) {
        return api.getUntimedCourseUnitsForModuleRecursively(childModule, validatablePlan);
      }));
      return _.uniqBy(recursiveResult, 'id');
    },
    getUntimedCustomStudyDraftsForModuleRecursively: (module, validatablePlan) => {
      const customStudyDraftsForModule = validatablePlan.getSelectedCustomStudyDraftsByParentModuleId(module.id);
      const unTimedCustomStudyDraftsForModule = _.filter(customStudyDraftsForModule, customStudyDraft => {
        return _.isEmpty(_.get(customStudyDraft, 'plannedPeriods'));
      });
      const childModulesForModule = validatablePlan.getSelectedModulesById(module, validatablePlan);
      const recursiveResult = _.concat(unTimedCustomStudyDraftsForModule, _.flatMap(childModulesForModule, childModule => {
        return api.getUntimedCustomStudyDraftsForModuleRecursively(childModule, validatablePlan);
      }));
      return _.uniqBy(recursiveResult, 'id');
    },
    getTimedCustomStudyDraftsForModuleRecursively: (module, validatablePlan) => {
      const customStudyDraftsForModule = validatablePlan.getSelectedCustomStudyDraftsByParentModuleId(module.id);
      const timedCustomStudyDraftsForModule = _.filter(customStudyDraftsForModule, customStudyDraft => {
        return !_.isEmpty(_.get(customStudyDraft, 'plannedPeriods'));
      });
      const childModulesForModule = validatablePlan.getSelectedModulesById(module, validatablePlan);
      const recursiveResult = _.concat(timedCustomStudyDraftsForModule, _.flatMap(childModulesForModule, childModule => {
        return api.getTimedCustomStudyDraftsForModuleRecursively(childModule, validatablePlan);
      }));
      return _.uniqBy(recursiveResult, 'id');
    },
    getTimedCourseUnitsForModuleRecursively: function (module, validatablePlan) {
      var courseUnitsForModule = validatablePlan.getCourseUnitsOrSubstitutingCourseUnitsForModule(module);
      var timedCourseUnitsForModule = _.filter(courseUnitsForModule, function (courseUnit) {
        return validatablePlan.isCourseUnitTimed(courseUnit.id);
      });
      var childModulesForModule = validatablePlan.getSelectedModulesById(module);
      const recursiveResult = _.concat(timedCourseUnitsForModule, _.flatMap(childModulesForModule, function (childModule) {
        return api.getTimedCourseUnitsForModuleRecursively(childModule, validatablePlan);
      }));
      return _.uniqBy(recursiveResult, 'id');
    },
    getTimedOrAttainedCourseUnitsForModuleRecursively: function (module, validatablePlan) {
      var courseUnitsForModule = validatablePlan.getCourseUnitsOrSubstitutingCourseUnitsForModule(module);
      var timedCourseUnitsForModule = _.filter(courseUnitsForModule, function (courseUnit) {
        return validatablePlan.isCourseUnitAttained(courseUnit.id) || validatablePlan.isCourseUnitTimed(courseUnit.id);
      });
      var childModulesForModule = validatablePlan.getSelectedModulesById(module);
      const recursiveResult = _.concat(timedCourseUnitsForModule, _.flatMap(childModulesForModule, function (childModule) {
        return api.getTimedOrAttainedCourseUnitsForModuleRecursively(childModule, validatablePlan);
      }));
      return _.uniqBy(recursiveResult, 'id');
    },
    getCourseUnitAttainmentsForModuleRecursively: function (module, validatablePlan) {
      var courseUnitsForModule = validatablePlan.getCourseUnitsOrSubstitutingCourseUnitsForModule(module);
      var attainmentsForModule = _.chain(courseUnitsForModule).filter(function (courseUnit) {
        return validatablePlan.isCourseUnitAttained(courseUnit.id);
      }).map(function (courseUnit) {
        return validatablePlan.getCourseUnitAttainment(courseUnit.id);
      }).value();
      var childModulesForModule = validatablePlan.getSelectedModulesById(module);
      const recursiveResult = _.concat(attainmentsForModule, _.flatMap(childModulesForModule, function (childModule) {
        return api.getCourseUnitAttainmentsForModuleRecursively(childModule, validatablePlan);
      }));
      return _.uniqBy(recursiveResult, 'id');
    },
    getAttainedUntimedCourseUnitsForPlan: function (validatablePlan) {
      return getAttainedUntimedCourseUnitsForModule(validatablePlan, validatablePlan.rootModule);
    }
  };
  return api;
}]);