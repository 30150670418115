import { Pipe, PipeTransform } from '@angular/core';
import { numberUtils } from 'common-typescript/src/numberUtils';
import _ from 'lodash';

@Pipe({ name: 'decimalNumber' })
export class DecimalNumberPipe implements PipeTransform {
    transform(input: any): string {
        if (_.isNil(input)) {
            return '';
        }
        const inputAsNumber = Number(input);
        if (inputAsNumber.toString() !== input.toString()) {
            return input;
        }
        return numberUtils.numberToString(inputAsNumber);
    }
}
