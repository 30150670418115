import angular from 'angular';
import _ from 'lodash';
import enrolmentQuestionnaireQuestionTpl from './enrolmentQuestionnaireQuestion.tpl.html';
import { MarkupStringComponent } from '../string/markup-string/markup-string.component.ts';
import { languageModelModule } from '../model/language.model';
export const enrolmentQuestionnaireQuestionComponentModule = 'sis-components.enrolmentQuestionnaire.Question';
(function () {
  enrolmentQuestionnaireQuestionController.$inject = ["languageJSDataModel"];
  angular.module(enrolmentQuestionnaireQuestionComponentModule, [languageModelModule, MarkupStringComponent.downgrade.moduleName]).component('enrolmentQuestionnaireQuestion', {
    bindings: {
      question: '<',
      answer: '<',
      questionIndex: '<?'
    },
    controller: enrolmentQuestionnaireQuestionController,
    template: enrolmentQuestionnaireQuestionTpl
  });

  /**
   * @ngInject
   */
  function enrolmentQuestionnaireQuestionController(languageJSDataModel) {
    const $ctrl = this;
    $ctrl.$onInit = function () {
      languageJSDataModel.lazyGetAll();
      $ctrl.answerText = _.get($ctrl, 'answer.answerText', '');
    };
    $ctrl.getLanguage = function () {
      if (_.get($ctrl, ['answer', 'selections', '0'])) {
        return languageJSDataModel.get($ctrl.answer.selections[0]);
      }
      return '';
    };
  }
})();