import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { SearchResult, StudentResultItem } from 'common-typescript/types';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { PersonNameService } from '../../service/person-name.service';
import { PrivatePersonEntityService } from '../../service/private-person-entity.service';
import { filterInput } from '../../typeahead/typeahead.component';

@Component({
    selector: 'sis-search-student',
    templateUrl: './search-student.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class SearchStudentComponent {

    @Input() student: StudentResultItem;
    @Input() id: string;
    @Output() studentChange = new EventEmitter<StudentResultItem>();

    /**
     * Signals to the parent component when a search is ongoing. Emits `true` when a search starts, and `false`
     * when the search completes (or errors out).
     */
    @Output() searching = new EventEmitter<boolean>();

    constructor(
        private personNameService: PersonNameService,
        private privatePersonService: PrivatePersonEntityService,
    ) {
        this.doSearch = this.doSearch.bind(this);
        this.renderStudent = this.renderStudent.bind(this);
    }

    doSearch(text$: Observable<string>): Observable<StudentResultItem[]> {
        return text$.pipe(
            filterInput(),
            tap(() => this.searching.emit(true)),
            switchMap(text => this.privatePersonService.searchStudents({ searchString: text })),
            tap(() => this.searching.emit(false)),
            map((result: SearchResult<StudentResultItem>) => result.searchResults),
            catchError(() => {
                this.searching.emit(false);
                return of([]);
            }),
        );
    }

    renderStudent(student: StudentResultItem): string {
        return `${this.personNameService.getFullNameReversed(student)} / ${student.studentNumber}`;
    }
}
