<div class="row mb-sm-4">
  <div class="col-12 header-container" data-cy="product-details-header">
    <app-add-to-cart-button *ngIf="product?.isOnSale &&
                                  (!product?.isFull || (product?.isFull && product?.isInCart)) &&
                                  !product?.isCurrentOrgIdDifferentThanProductOrgId"
                            [productId]="product?.id"
                            [courseUnitId]="courseUnit?.id"
                            [isProductInCart]="product?.isInCart"
                            [class.mb-5]="mobileView"
                            [class.float-end]="!mobileView">
    </app-add-to-cart-button>
    <div *ngIf="mobileView && !product?.isOnSale" class="mb-5">
      <ng-container *ngTemplateOutlet="futureProductBadge; context: {product: product}"></ng-container>
    </div>

    <h4>
      {{'OPEN_UNIVERSITY.STUDY_OFFERING.PRODUCT_DETAILS' | translate:{productName: product?.name | localizedString} }}
      <ng-container *ngIf="!mobileView && !product?.isOnSale">
        <ng-container *ngTemplateOutlet="futureProductBadge; context: {product: product}"></ng-container>
      </ng-container>
    </h4>

    <div *ngIf="product?.isCurrentOrgIdDifferentThanProductOrgId" class="mt-3 mb-3">
      <sis-contextual-notification variant="warning">
        <span>
            {{ 'OPEN_UNIVERSITY.STUDY_OFFERING.UNIVERSITY_ORG_ORGANIZER' | translate:
                { productUniversityOrg: (product?.organisationId | organisation | async)?.name | localizedString } }}
        </span>
        <span>
            {{ 'OPEN_UNIVERSITY.STUDY_OFFERING.UNIVERSITY_ORG_ID_MISS_MATCH' | translate:
                { currentUniversityOrg: (product?.currentOrgId | organisation | async)?.name | localizedString } }}
        </span>
      </sis-contextual-notification>
    </div>
  </div>
</div>
<app-product-basic-info [product]="product" [completionMethod]="completionMethod"></app-product-basic-info>
<div class="row">
  <div class="col-12">
    <h4>{{'OPEN_UNIVERSITY.STUDY_OFFERING.ASSESSMENT_ITEMS' | translate}}</h4>
    <app-assessment-item-teaching *ngFor="let assessmentItem of assessmentItems$ | async; trackBy: entityId"
                                  [assessmentItem]="assessmentItem"
                                  [courseUnitRealisations]="this.product?.teachingOnSale?.[assessmentItem.id]"
                                  [seatCounts]="product.curAvailableSeats">
    </app-assessment-item-teaching>
  </div>
</div>

<ng-template #futureProductBadge let-product="product">
  <sis-tiny-badge variant="secondary">{{'OPEN_UNIVERSITY.STUDY_OFFERING.SALES_PERIOD_STARTS_AT' | translate:{
     date: product?.salesPeriod?.startDateTime | localDateFormat,
     time: product?.salesPeriod?.startDateTime | localTimeFormat
   } }}
  </sis-tiny-badge>
</ng-template>

