<ng-container *transloco="let t">
  <div tabindex="-1" [id]="id" class="dropdown-multiselect">
      <sis-button class="link-button"
              [variant]="'secondary'"
              [hollow]="true"
              [type]="'button'"
              (clicked)="toggleMenu()"
              [ariaExpanded]="showDropdown"
              [ariaLabel]="
                t('FRONTPAGE.SELECT_WIDGETS.CHOOSE_FRONTPAGE_WIDGETS') + ', '
                + t('FRONTPAGE.SELECT_WIDGETS.NUMBER_OF_SELECTED_WIDGETS', {total: options.length, selected: selected.length})"
              #buttonElement>
        {{t('FRONTPAGE.SELECT_WIDGETS.CHOOSE_FRONTPAGE_WIDGETS')}}
        <sis-icon icon="chevron" [iconClass]="showDropdown ? 'ccw-90' : 'cw-90'"></sis-icon>
      </sis-button>
    <div class="option-menu"
         role="group"
         [id]="'option-menu-' + id"
         *ngIf="showDropdown"
         #optionMenuElement>
         <ul>
            <li *ngFor="let option of options">
              <sis-checkbox
                class="option-menu__checkbox"
                [name]="t(option.labelKey)"
                [label]="t(option.labelKey)"
                [title]="t(option.labelKey)"
                [ariaRole]="'checkbox'"
                [checked]="isChecked(option)"
                (checkedChange)="select(option)">
              </sis-checkbox>
            </li>
         </ul>
    </div>
  </div>
</ng-container>
