import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { FrontendFeatureToggles, UniversitySettings } from 'common-typescript/types';
import {
    BehaviorSubject,
    combineLatest,
    combineLatestWith,
    distinctUntilChanged,
    Observable,
    shareReplay,
    startWith,
} from 'rxjs';
import { map } from 'rxjs/operators';
import { ModalService } from 'sis-common/modal/modal.service';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { Option } from 'sis-components/select/dropdown-select/dropdown-select.component';
import { UniversityService } from 'sis-components/service/university.service';

import { AttainmentStudentModalService } from '../../../common/service/attainment-student-modal-service';
import { PrintTranscriptModalComponent } from '../../attainment/print-transcript-modal/print-transcript-modal.component';

import {
    AttainmentNodes,
    AttainmentNodeService,
    AttainmentNodesResult,
} from './attainment-node.service';
import { AttainmentSortingService } from './attainment-sorting.service';
import { AttainmentTreeDataService } from './attainment-tree-data.service';
import { AttainmentTreeExpansionStateService } from './attainment-tree-expansion-state.service';
import { AssessmentItemAttainmentNodeNameService } from './student-assessment-item-attainments/assessment-item-attainment-node-name.service';
import { AssessmentItemReferenceService } from './student-assessment-item-attainments/assessment-item-reference.service';

export enum AttainmentNodeArrayType {
    VALID_ATTAINMENTS = 'VALID_ATTAINMENTS',
    INVALID_ATTAINMENTS = 'INVALID_ATTAINMENTS',
}

@Component({
    selector: 'app-student-attainments',
    templateUrl: './student-attainments.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [
        AttainmentTreeDataService,
        AttainmentNodeService,
        AssessmentItemAttainmentNodeNameService,
        AssessmentItemReferenceService,
        AttainmentStudentModalService,
        AttainmentTreeExpansionStateService,
        AttainmentSortingService,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudentAttainmentsComponent implements OnInit {
    attainmentOptions$: Observable<Option[]>;
    readonly transcriptPrintingEnabledByUniversity$: Observable<boolean>;

    readonly selectedAttainmentNodes$: Observable<AttainmentNodes>;
    private readonly _selectedDisplay$: BehaviorSubject<AttainmentNodeArrayType> = new BehaviorSubject<AttainmentNodeArrayType>(AttainmentNodeArrayType.VALID_ATTAINMENTS);

    constructor(
        attainmentNodeService: AttainmentNodeService,
        private universityService: UniversityService,
        private modalService: ModalService,
        private translocoService: TranslocoService,
        private appErrorHandler: AppErrorHandler,
        private readonly _attainmentTreeExpansionStateService: AttainmentTreeExpansionStateService,
    ) {
        this.selectedAttainmentNodes$ = this._selectedDisplay$.pipe(
            combineLatestWith(attainmentNodeService.attainmentNodes$),
            map(([selectedDisplay, attainmentNodes]: [AttainmentNodeArrayType, AttainmentNodesResult]) => selectedDisplay === AttainmentNodeArrayType.VALID_ATTAINMENTS
                ? attainmentNodes.validAttainmentNodes
                : attainmentNodes.invalidAttainmentNodes,
            ),
            appErrorHandler.defaultErrorHandler(),
        );

        // initially invisible while waiting for the setting, but after that defaults to visible if not explicitly hidden
        this.transcriptPrintingEnabledByUniversity$ = this.universityService.getCurrentUniversitySettings()
            .pipe(
                map((settings: UniversitySettings | null) => (<FrontendFeatureToggles | null | undefined>settings?.frontendFeatureToggles)?.studentTranscriptPrintable
                    ?? true,
                ),
                this.appErrorHandler.defaultErrorHandler(),
                startWith(false),
                distinctUntilChanged(),
                shareReplay({ bufferSize: 1, refCount: true }),
            );
    }

    get selectedDisplay(): AttainmentNodeArrayType {
        return this._selectedDisplay$.value;
    }

    ngOnInit(): void {
        this.attainmentOptions$ = this.addTranslationsToOptions();
    }

    onDropdownSelectionChange(option: AttainmentNodeArrayType): void {
        this._selectedDisplay$.next(option);
        this._attainmentTreeExpansionStateService.reset();
    }

    printAttainments(): void {
        this.modalService.open(PrintTranscriptModalComponent, {});
    }

    private validAttainmentsDropdownOptionTranslation(): Observable<string> {
        return this.translocoService.selectTranslate('PROFILE.ATTAINMENT.VALID_ATTAINMENTS');
    }

    private invalidAttainmentsDropdownOptionTranslation(): Observable<string> {
        return this.translocoService.selectTranslate('PROFILE.ATTAINMENT.INVALID_ATTAINMENTS');
    }

    addTranslationsToOptions(): Observable<Option[]> {
        return combineLatest([
            this.validAttainmentsDropdownOptionTranslation(),
            this.invalidAttainmentsDropdownOptionTranslation(),
        ]).pipe(
            map(translations => [
                { value: AttainmentNodeArrayType.VALID_ATTAINMENTS, label: translations[0] },
                { value: AttainmentNodeArrayType.INVALID_ATTAINMENTS, label: translations[1] },
            ]),
            this.appErrorHandler.defaultErrorHandler(),
        );
    }
}
