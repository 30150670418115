import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { Attainment, Education, OtmId, StudyRight, StudyRightMinorSelection } from 'common-typescript/types';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-minor-selections',
    templateUrl: './minor-selections.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class MinorSelectionsComponent {
    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.studyRight.minorSelectionsNg',
        directiveName: 'sisMinorSelections',
    };

    /**
     * Determines expandable heading's semantic aria-level for screen readers, default is equivalent for h5
     */
    @Input() level?: number = 5;
    @Input() attainmentsByModuleGroupId: { [index: string]: Attainment };
    @Input() studyRight: StudyRight;
    @Input() education: Education;
    @Input() editMode: boolean;
    @Output() addPhase1MinorSelection = new EventEmitter<void>();
    @Output() addPhase2MinorSelection = new EventEmitter<void>();
    @Output() removePhase1MinorSelection = new EventEmitter<OtmId>();
    @Output() removePhase2MinorSelection = new EventEmitter<OtmId>();
    @Input() hideHeader: boolean;

    localId(index: number, selection: StudyRightMinorSelection): string {
        return selection.localId;
    }

    removeSelection(moduleGroupId: OtmId, phase: number) {
        if (phase === 1) {
            this.removePhase1MinorSelection.emit(moduleGroupId);
        } else {
            this.removePhase2MinorSelection.emit(moduleGroupId);
        }
    }

    addSelection(phase: number) {
        if (phase === 1) {
            this.addPhase1MinorSelection.emit();
        } else {
            this.addPhase2MinorSelection.emit();
        }
    }
}
