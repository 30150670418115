import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HashMap, TranslocoService } from '@ngneat/transloco';
import {
    TokenizeResult,
    TranslationMarkupRenderer,
    TranslationMarkupRendererFactory,
    TranslationMarkupTranspiler,
    TranslationMarkupTranspilerContext,
    TranspileResult,
} from 'ngx-transloco-markup';

import {
    HYPERLINK_END,
    HyperlinkStart,
    hyperlinkTokenizer,
} from './hyperlink-tokenizer';

/**
 * Hyperlink (`<a>`) transpiler.
 *
 * Created to render hyperlinks possibly included in `LocalizedMarkupString`s. Supports parameters and for that reason
 * the implementation does not extend `HtmlElementTranspiler`, rather relying on `hyperlink-tokenizer.ts` to
 * recognize and parse hyperlink elements.
 */
@Injectable()
export class HyperlinkTranspiler implements TranslationMarkupTranspiler {
    constructor(
        private readonly rendererFactory: TranslationMarkupRendererFactory,
        private translocoService: TranslocoService,
        private domSanitizer: DomSanitizer,
    ) {}

    public tokenize(translation: string, offset: number): TokenizeResult | undefined {
        return hyperlinkTokenizer(translation, offset);
    }

    public transpile(
        start: number,
        context: TranslationMarkupTranspilerContext,
    ): TranspileResult | undefined {
        const nextToken = context.tokens[start];

        if (!(nextToken instanceof HyperlinkStart)) {
            return undefined;
        }

        const { nextOffset, renderers } = context.transpileUntil(
            start + 1,
            (token) => token === HYPERLINK_END,
        );

        return {
            nextOffset: Math.min(nextOffset + 1, context.tokens.length),
            renderer: this.createRenderer(renderers, nextToken),
        };
    }

    private createRenderer(
        childRenderers: TranslationMarkupRenderer[],
        token: HyperlinkStart,
    ): TranslationMarkupRenderer {
        const { params } = token;

        if (params.target === '_blank') {
            childRenderers.push(this.createScreenReaderAidRenderer());
        }

        const linkRenderer = this.rendererFactory.createElementRenderer('a', childRenderers);

        return (translationParameters: HashMap): HTMLSpanElement => {
            const linkElement = linkRenderer(translationParameters);

            // Sanitize and apply parameters.
            if (params.href) {
                linkElement.href = this.domSanitizer.sanitize(SecurityContext.URL, params.href);
            }

            if (params.target) {
                linkElement.target = this.domSanitizer.sanitize(SecurityContext.HTML, params.target);
            }

            if (params.rel) {
                linkElement.rel = this.domSanitizer.sanitize(SecurityContext.HTML, params.rel);
            }

            return linkElement;
        };
    }

    /**
     * Renderer for a hidden `<span>` to let screenreader users know the link is going to open in a new window/tab.
     */
    private createScreenReaderAidRenderer(): TranslationMarkupRenderer {
        const label = this.translocoService.translate('ARIA_LABEL.OPENS_TO_A_NEW_TAB');
        const srTextRenderer = this.rendererFactory.createTextRenderer(label);
        const spanRenderer = this.rendererFactory.createElementRenderer('span', [srTextRenderer]);

        return () => {
            const spanElement = spanRenderer({});
            spanElement.className = 'visually-hidden';
            return spanElement;
        };
    }
}
