<ng-container *transloco="let t">
  <sis-contextual-notification [variant]="'danger'">
    <p>{{t('PLAN_EDIT.SELECTION_MODAL.RULE_ERROR_SUMMARY.HELP_TEXT')}}</p>
    <ul>
      <ng-container *ngFor="let ruleErrorStateObject of ruleErrorStates; trackBy:trackByRuleLocalId">
        <li *ngFor="let error of ruleErrorStateObject.errors; trackBy:trackByErrorId">
          <ng-container *ngIf="error.errorType === 'LESS_SELECTIONS_REQUIRED'">
            <sis-button [link]="true" (clicked)="this.errorClick.emit([ruleErrorStateObject, error])">
              {{ t('PLAN_EDIT.SELECTION_MODAL.RULE_ERROR_SUMMARY.RULE_ERRORS.LESS_SELECTIONS_REQUIRED',
              {groupName: ruleErrorStateObject.ruleDisplayName})}}
            </sis-button>
          </ng-container>
        </li>
      </ng-container>
    </ul>
  </sis-contextual-notification>
</ng-container>
