import { Component, Input, ViewEncapsulation } from '@angular/core';
import { CustomCourseUnitAttainment } from 'common-typescript/types';

@Component({
    selector: 'sis-custom-course-unit-attainment-box',
    templateUrl: './custom-course-unit-attainment-box.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CustomCourseUnitAttainmentBoxComponent {
    @Input() attainment: CustomCourseUnitAttainment;
    // css class for root div, possibly a color
    @Input() categoryCssClass?: string;

    canInfoModalBeOpened = false;

}
