<div class="modal-header">
  <h2 class="modal-title">{{ values?.titleKey | translate }}</h2>
  <sis-modal-close-button/>
</div>
<div class="modal-body">
  <p *ngIf="values?.messageKey">{{ values?.messageKey | translate }}</p>
  <ul *ngIf="values?.validationErrors?.length > 0">
    <li *ngFor="let error of values?.validationErrors">
      {{ error.messageKey | translate: error.attributes }}{{ error.entityKey ? ':' : '' }}
      <ng-container *ngIf="error.entityKey">
        {{ 'ENTITY_NAMES.' + error.entityKey | translate }}
        <ng-container *ngIf="error.fieldKeys?.length > 0">&gt;</ng-container>
        <ng-container *ngFor="let fieldKey of error.fieldKeys; last as last">
          {{ 'FIELD_NAMES.' + fieldKey | translate }}
          <ng-container *ngIf="!last">&gt;</ng-container>
        </ng-container>
      </ng-container>
    </li>
  </ul>
</div>
<div class="modal-footer">
  <sis-button (clicked)="modal.dismiss()">{{ 'COMMON.CLOSE_ERROR' | translate }}</sis-button>
</div>
