<div class="form-group">
    <h4 translate="PLAN_EDIT.INLINE_SEARCH_TITLE_MODULE"></h4>
    <p class="guidance guidance__inverted" translate="PLAN_EDIT.INLINE_SEARCH_GUIDANCE"></p>
    <sis-inline-search
            [searchTarget]="searchTarget"
            [id]="rule.localId"
            [parentModule]="parentModule"
            [validatablePlan]="validatablePlan"
            [curriculumPeriodIds]="curriculumPeriodIds"
            [codeUrns]="codeUrns"
            (selectResult)="onSelectModuleResultItem($event)"></sis-inline-search>
</div>
<sis-contextual-notification *ngIf="codeUrns?.length > 0" variant="light">{{'PLAN_EDIT.SEARCH_ONLY_FOR_MINORS_NOTIFICATION' | translate }}</sis-contextual-notification>
<div *ngFor="let module of selectedModules">
    <sis-rule-module-box
                         [module]="module"
                         [planValidationResult]="planValidationResult"
                         [planStateObject]="planStateObject"
                         [planData]="planData">
        <div module-action class="additional-box">
            <sis-button class="button-box"
                        link="true"
                        (clicked)="unselectModule(module)"
                        [ariaLabel]="'ARIA_LABEL.DELETE' |translate">
                <sis-icon icon="delete"></sis-icon>
            </sis-button>
        </div>
    </sis-rule-module-box>
</div>
<div *ngFor="let customModuleAttainment of selectedCustomModuleAttainments">
    <sis-rule-custom-module-attainment-box
            [customModuleAttainment]="customModuleAttainment"
            [planValidationResult]="planValidationResult"
            [planStateObject]="planStateObject"
            [planData]="planData">

        <div custom-module-attainment-action class="additional-box">
            <sis-button class="button-box"
                        link="true"
                        (clicked)="unselectCustomModuleAttainment(customModuleAttainment)"
                        [ariaLabel]="'ARIA_LABEL.DELETE' |translate">
                <sis-icon icon="delete"></sis-icon>
            </sis-button>
        </div>


    </sis-rule-custom-module-attainment-box>
</div>
