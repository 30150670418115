import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewEncapsulation,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { dateUtils } from 'common-typescript';
import {
    CourseUnitResultItem,
    CurriculumPeriod,
    LocalDateRange,
    LocalizedString,
    Organisation,
    OtmId,
    StudyYear,
} from 'common-typescript/types';
import _ from 'lodash';
import moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { OrganisationEntityService } from 'sis-components/service/organisation-entity.service';

import { STUDY_PERIOD_SERVICE } from '../../ajs-upgraded-modules';
import { EnrichedStudyPeriod } from '../search-main/search-main.component';

@Component({
    selector: 'app-search-result-row',
    templateUrl: './search-result-row.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class SearchResultRowComponent implements OnInit, OnDestroy, OnChanges {
    @Input() studyPeriods: EnrichedStudyPeriod[];
    @Input() curriculumPeriods: CurriculumPeriod[];

    @Input() result: CourseUnitResultItem;
    @Input() term: string;
    @Input() searchFilterCurriculumPeriods: SearchParameterFilter[];
    @Input() courseCartCourseUnitIds: OtmId[];
    @Input() loggedIn: boolean;
    @Output() addCourseUnitToCourseCart = new EventEmitter<string>();
    @Output() removeCourseUnitFromCourseCart = new EventEmitter<string>();

    isInCart: boolean;
    matchingOtherLang: boolean;
    organisationNames: string;
    destroyed$ = new Subject<void>();

    renderedActivityPeriods: string;
    renderedCurriculumPeriods: string;
    curLangCodeUrns: string[] = [];

    constructor(
        @Inject(STUDY_PERIOD_SERVICE) private studyPeriodService: any,
        private localeService: LocaleService,
        private translate: TranslateService,
        private organisationEntityService: OrganisationEntityService,
    ) {
    }

    ngOnInit(): void {
        this.matchingOtherLang = this.matchInOtherLang(this.result.name, this.result.nameMatch);

        this.curriculumPeriods = _(this.curriculumPeriods)
            .filter(curriculumPeriod => _.includes(this.result.curriculumPeriodIds, curriculumPeriod.id))
            .orderBy('activePeriod.startDate')
            .value();

        this.renderOrganisationNames();
        // "result.curCodeUrns" can include multiple types of a urns (e.g.: "urn:code:course-unit-realisation-type:teaching-participation-lectures"),
        // but we only need the language urns.
        this.curLangCodeUrns = (this.result.curCodeUrns || []).filter((urn: string) => urn.includes('urn:code:language'));
        this.renderedActivityPeriods = this.renderActivityPeriods();
        this.renderedCurriculumPeriods = this.renderCurriculumPeriods();
    }

    ngOnDestroy() {
        this.destroyed$.next();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.courseCartCourseUnitIds) {
            this.isInCourseCart();
        }

        if (changes.studyPeriods) {
            this.studyPeriods = this.getDeepCopy(this.studyPeriods);
        }

        if (changes.curriculumPeriods) {
            this.curriculumPeriods = this.getDeepCopy(this.curriculumPeriods);
        }
    }

    isInCourseCart() {
        this.isInCart = _.includes(this.courseCartCourseUnitIds, this.result.id);
    }

    renderActivityPeriods(): string {
        _.forEach(this.studyPeriods, (studyPeriod) => {
            // @ts-ignore (studyPeriodService.addSpecifiersToDuplicateStudyPeriodNames() expects name to be a plain string)
            studyPeriod.name = this.localeService.localize(studyPeriod.name);
        });
        this.studyPeriodService.addSpecifiersToDuplicateStudyPeriodNames(this.studyPeriods);

        const activityPeriods: LocalDateRange[] = this.result.activityPeriods || [];
        const overlappingStudyPeriods: EnrichedStudyPeriod[][] = this.getStudyPeriodsOverlappingActivityPeriods(this.studyPeriods, activityPeriods);
        const groupedStudyPeriods: GroupedStudyPeriod[] = this.groupStudyPeriodsPerYearOrRange(overlappingStudyPeriods);

        return groupedStudyPeriods.length > 0 ?
            _.map(groupedStudyPeriods, group => this.renderStudyPeriodGroup(group)).join(', ') :
            this.translate.instant('SEARCH_RESULT.NO_INFO');
    }

    /**
     * Iterates given activity periods and returns overlapping study periods per activity period, discarding
     * empty overlaps and duplicates. The items in the returned array are candidates for showing up as separate
     * items on the page, though they may still be combined in {@link groupStudyPeriodsPerYearOrRange}.
     *
     * @param studyPeriods study periods to filter against the activity periods
     * @param activityPeriods course unit's activity periods
     */
    getStudyPeriodsOverlappingActivityPeriods(studyPeriods: EnrichedStudyPeriod[], activityPeriods: LocalDateRange[]): EnrichedStudyPeriod[][] {
        return _(activityPeriods)
            .map(_.partial(this.mapActivityPeriodToOverlappingStudyPeriods, studyPeriods))
            .reject(_.isEmpty)
            .uniqBy(filteredStudyPeriods => _.map(filteredStudyPeriods, studyPeriod =>
                `${studyPeriod.name} ${studyPeriod.$year.name}`).join(','))
            .value();
    }

    /**
     * Returns the study periods overlapping with the given activity period.
     *
     * @param studyPeriods study periods to filter against the activity period
     * @param activityPeriod course unit's activity period
     */
    mapActivityPeriodToOverlappingStudyPeriods(studyPeriods: EnrichedStudyPeriod[], activityPeriod: LocalDateRange): EnrichedStudyPeriod[] {
        return _.filter(studyPeriods, studyPeriod => dateUtils.dateRangesOverlap(
            studyPeriod.valid.startDate, studyPeriod.valid.endDate, activityPeriod.startDate, activityPeriod.endDate,
        ));
    }

    /**
     * Groups given study periods to be listed as separate items on the page:
     * - individual study periods are grouped by their study year
     * - study period ranges are left as they are
     *
     * @param studyPeriodsPerActivityPeriod an array of arrays of study periods
     */
    groupStudyPeriodsPerYearOrRange(studyPeriodsPerActivityPeriod: EnrichedStudyPeriod[][]): GroupedStudyPeriod[] {
        return _.reduce(studyPeriodsPerActivityPeriod, (groupedStudyPeriods, studyPeriods) => {
            const year: string = studyPeriods[0].$year.name;
            if (studyPeriods.length > 1) {
                groupedStudyPeriods.push({ studyPeriods, isRange: true });
            } else {
                const existingGroup: GroupedStudyPeriod = groupedStudyPeriods.find((period: GroupedStudyPeriod) => !period.isRange && period.year === year);
                if (existingGroup) {
                    existingGroup.studyPeriods.push(studyPeriods[0]);
                } else {
                    groupedStudyPeriods.push({ year, studyPeriods, isRange: false });
                }
            }

            return groupedStudyPeriods;
        }, []);
    }

    /**
     * Renders a study period group to display on the page.
     *
     * @param studyPeriodGroup a group of study periods to show as a list item
     */
    renderStudyPeriodGroup(studyPeriodGroup: GroupedStudyPeriod): string {
        return studyPeriodGroup.isRange ?
            this.renderStudyPeriodRange(studyPeriodGroup.studyPeriods) :
            this.renderSeparateStudyPeriods(studyPeriodGroup.studyPeriods);
    }

    /**
     * Renders a continuous range of study periods.
     *
     * @param studyPeriods study periods constituting a range
     */
    renderStudyPeriodRange(studyPeriods: EnrichedStudyPeriod[]): string {
        const firstPeriod = _.first(studyPeriods);
        const lastPeriod = _.last(studyPeriods);

        if (firstPeriod.$year.name !== lastPeriod.$year.name) {
            return `${firstPeriod.name + this.renderYear(firstPeriod.$year)}–${lastPeriod.name}${this.renderYear(lastPeriod.$year)}`;
        }
        return `${firstPeriod.name}–${lastPeriod.name}${this.renderYear(firstPeriod.$year)}`;
    }

    /**
     * Renders study periods within the same study year that don't constitute a continuous range.
     *
     * @param studyPeriods study periods within the same study year
     */
    renderSeparateStudyPeriods(studyPeriods: EnrichedStudyPeriod[]): string {
        const year = studyPeriods[0].$year;

        if (studyPeriods.length === 1) {
            return studyPeriods[0].name + this.renderYear(year);
        }

        const allButLastPeriodNames: string = _(studyPeriods)
            .dropRight()
            .map('name')
            .join(', ');
        const lastPeriodName = _.last(studyPeriods).name;
        return `${allButLastPeriodNames} ${this.translate.instant('AND')} ${lastPeriodName}${this.renderYear(year)}`;
    }

    renderYear(year: StudyYear): string {
        return ` (${year.name})`;
    }

    renderCurriculumPeriods(): string {
        const curriculumPeriodsToRender: CurriculumPeriod[] = _.isEmpty(this.searchFilterCurriculumPeriods) ?
            this.getMostRelevantCurriculumPeriods() :
            this.getCurriculumPeriodsMatchingSearchFilter();
        return _.map(curriculumPeriodsToRender, curriculumPeriod =>
            this.localeService.localize(curriculumPeriod.abbreviation)).join(', ');
    }

    /**
     * Returns the curriculum periods of the search result that match the curriculum periods in the search
     * filter.
     */
    getCurriculumPeriodsMatchingSearchFilter(): CurriculumPeriod[] {
        const searchFilterCurriculumPeriodIds: string[] = _.map(this.searchFilterCurriculumPeriods, 'id');
        return _.filter(this.curriculumPeriods, curriculumPeriod =>
            _.includes(searchFilterCurriculumPeriodIds, curriculumPeriod.id));
    }

    /**
     * Returns the most relevant curriculum periods of the search result, in practice only one in this order:
     *
     * 1. one that is active now
     * 2. closest one active in the future
     * 3. closest one active in the past
     */
    getMostRelevantCurriculumPeriods(): CurriculumPeriod[] {
        const now = moment();

        const currentCurriculumPeriod: CurriculumPeriod = _.find(this.curriculumPeriods, curriculumPeriod =>
            dateUtils.rangeContains(now, curriculumPeriod.activePeriod));
        if (currentCurriculumPeriod) {
            return [currentCurriculumPeriod];
        }

        const partitionedPeriods: [CurriculumPeriod[], CurriculumPeriod[]] = _.partition(this.curriculumPeriods, curriculumPeriod =>
            dateUtils.isRangeAfter(now, curriculumPeriod.activePeriod));
        const closestPeriodInTheFuture: CurriculumPeriod = _.first(partitionedPeriods[0]);
        if (closestPeriodInTheFuture) {
            return [closestPeriodInTheFuture];
        }
        const closestPeriodInThePast: CurriculumPeriod = _.last(partitionedPeriods[1]);
        if (closestPeriodInThePast) {
            return [closestPeriodInThePast];
        }

        return [];
    }

    renderOrganisationNames() {
        this.organisationEntityService.getByIds(this.result.organisations)
            .pipe(takeUntil(this.destroyed$))
            .subscribe((organisations: Organisation[]) => {
                this.organisationNames = organisations
                    .map(organisation => this.localeService.localize(organisation.name))
                    .join(', ');
            });
    }

    addToCart() {
        this.addCourseUnitToCourseCart.emit(this.result.id);
    }

    removeFromCart() {
        this.removeCourseUnitFromCourseCart.emit(this.result.id);
    }

    matchInOtherLang(valueInUILang: string, matchedValue: string): boolean {
        return valueInUILang !== matchedValue && /<b>/.test(matchedValue);
    }

    // For the situations where object is passed inside multiple child -components, and all children are modifying the object.
    getDeepCopy(obj: any): any {
        return _.cloneDeep(obj);
    }
}

interface SearchParameterFilter {
    id: string;
    name: LocalizedString;
}

interface GroupedStudyPeriod {
    isRange: boolean;
    year: string;
    studyPeriods: EnrichedStudyPeriod[];
}
