import { Pipe, PipeTransform } from '@angular/core';
import { PersonInfo } from 'common-typescript/types';

import { PersonNameService, PersonWithName } from '../service/person-name.service';

@Pipe({
    name: 'personFullNameReversedWithEmail',
})
export class PersonFullNameReversedWithEmailPipe implements PipeTransform {

    constructor(private personNameService: PersonNameService) {
    }

    transform(personInfo: PersonInfo): string {
        if (personInfo) {
            const emailInfo = personInfo.primaryEmail ? `(${personInfo.primaryEmail})` : '';
            return `${this.personNameService.getFullNameReversed(personInfo)} ${emailInfo}`;
        }
        return '';
    }
}
