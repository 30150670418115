import {
    Component,
    computed,
    EventEmitter,
    Inject,
    input,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { ValidatablePlan } from 'common-typescript';
import {
    CourseUnit, CourseUnitResultItem,
    CourseUnitSearch, CurriculumPeriod,
    SearchResult,
} from 'common-typescript/types';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { CURRICULUM_PERIOD_SERVICE } from 'sis-components/ajs-upgraded-modules';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { CourseUnitEntityService } from 'sis-components/service/course-unit-entity.service';
import { PlanData, PlanStateObject } from 'sis-components/service/plan-state.service';
import { UniversityService } from 'sis-components/service/university.service';
import { filterInput } from 'sis-components/typeahead/typeahead.component';

@Component({
    selector: 'app-free-edit-course-unit-expandable',
    templateUrl: './free-edit-course-unit-expandable.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class FreeEditCourseUnitExpandableComponent implements OnInit {
    searchCurriculumPeriods = input<CurriculumPeriod[]>([]);
    @Input() validatablePlan: ValidatablePlan;
    @Input() planData: PlanData;
    @Input() planStateObject: PlanStateObject;
    @Input() allCourseUnits: CourseUnit[];
    @Output() toggledCourseUnit = new EventEmitter<{
        courseUnit: CourseUnit;
        isInPlan: boolean;
    }>();

    @Output() selectedCourseUnit = new EventEmitter<CourseUnit>();

    searchCurriculumPeriodIds =
        computed(() => this.searchCurriculumPeriods().map(cur => cur.id));

    uiLang: string;

    constructor(
        private courseUnitEntityService: CourseUnitEntityService,
        private universityService: UniversityService,
        private localeService: LocaleService,
        private appErrorHandler: AppErrorHandler,
        @Inject(CURRICULUM_PERIOD_SERVICE) private curriculumPeriodService: any,
    ) {}

    ngOnInit(): void {
        this.uiLang = this.localeService.getCurrentLanguage();
    }

    courseUnitSearch(searchString: string): Observable<CourseUnitResultItem[]> {
        const allCourseUnitIds = new Set(this.allCourseUnits.map(item => item.id));
        const searchParams = {
            curriculumPeriodIds: this.searchCurriculumPeriodIds(),
            fullTextQuery: searchString,
            uiLang: this.uiLang,
            start: 0,
            limit: 100,
            validity: 'ONGOING_AND_FUTURE',
            organisationRootIds: [this.universityService.getCurrentUniversityOrgId()],
        } as Partial<CourseUnitSearch>;

        return this.courseUnitEntityService.search(searchParams)
            .pipe(
                map((res: SearchResult<CourseUnitResultItem>) => res.searchResults),
                map(searchResults => searchResults.filter((searchResult: any) => !allCourseUnitIds.has(searchResult.id) && !this.validatablePlan.isCourseUnitInPlan(searchResult))),
                this.appErrorHandler.defaultErrorHandler(),
            );

    }

    getCourseUnitSearchSuggestions(searchText$: Observable<string>): Observable<CourseUnitResultItem[]> {
        return searchText$.pipe(
            filterInput(),
            switchMap(text => this.courseUnitSearch(text)),
        );
    }

    selectCourseUnitFromSelectList(courseUnit: CourseUnit): void {
        this.selectedCourseUnit.emit(courseUnit);
    }

    handleToggle(toggleData: {
        courseUnit: CourseUnit;
        isInPlan: boolean;
    }): void {
        this.toggledCourseUnit.emit(toggleData);
    }
}
