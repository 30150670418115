import { Component, Input, ViewEncapsulation } from '@angular/core';
import { DocumentState } from 'common-typescript/types';

@Component({
    selector: 'sis-non-active-document-state-badge',
    templateUrl: './non-active-document-state-badge.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class NonActiveDocumentStateBadgeComponent {
    @Input() documentState: DocumentState;
}
