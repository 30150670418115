import {
    ChangeDetectionStrategy,
    Component, EventEmitter, Inject,
    Input,
    OnChanges,
    OnInit, Output,
    SimpleChanges,
    ViewEncapsulation,
} from '@angular/core';
import { ValidatablePlan } from 'common-typescript/src/plan/validation/validatablePlan';
import { Attainment, CourseUnit, CourseUnitAttainment, CourseUnitSubstitution, OtmId, Plan } from 'common-typescript/types';
import _ from 'lodash';
import {
    catchError,
    combineLatest,
    from,
    map,
    Observable,
    of,
    OperatorFunction, pipe,
    ReplaySubject,
    shareReplay,
    Subject,
    switchMap,
    takeUntil,
    tap,
    withLatestFrom,
} from 'rxjs';
import { DEFAULT_PROMISE_HANDLER } from 'sis-common/ajs-upgraded-modules';
import { AuthService } from 'sis-common/auth/auth-service';
import { UuidService } from 'sis-common/uuid/uuid.service';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { CourseUnitEntityService } from 'sis-components/service/course-unit-entity.service';
import { convertAJSPromiseToNative } from 'sis-components/util/utils';

import { ATTAINMENT_SERVICE } from '../../../../../ajs-upgraded-modules';
import { PreviewModeService } from '../../../../utils/preview-mode.service';

interface SubstituteCourseUnitsByGroupId { [key: OtmId]: CourseUnit}
export interface SelectSubstitutionsEvent {
    courseUnit: CourseUnit;
    substitution: CourseUnitSubstitution[];
    plan: Plan;
    substituteCourseUnitsByGroupId: SubstituteCourseUnitsByGroupId;
}
export interface RemoveSubstitutionsEvent {
    courseUnit: CourseUnit;
    plan: Plan;

}
export interface CourseUnitSubstitutionWithLocalId {
    localId: string;
    substitution: CourseUnitSubstitution[];
}

interface AttainmentsByCourseUnitGroupId { [key: OtmId]: Attainment}
interface CourseUnitInfoModalSubstitutionCorrespondencesData {
    editable: boolean;
    isLoggedIn: boolean;
    selected: string;
    substitutionOptions: CourseUnitSubstitutionWithLocalId[];
    attainmentsByCourseUnitGroupId: AttainmentsByCourseUnitGroupId;
    allAttainments: Attainment[];
    substituteCourseUnitsByGroupId: SubstituteCourseUnitsByGroupId;
}
@Component({
    selector: 'app-course-unit-info-modal-substitution-correspondences',
    templateUrl: './course-unit-info-modal-substitution-correspondences.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourseUnitInfoModalSubstitutionCorrespondencesComponent implements OnInit, OnChanges {

    @Input() validatablePlan: ValidatablePlan;
    // Notice: At the moment of writing this is actually an enriched JsData plan object
    @Input() plan: Plan;
    @Input() courseUnit: CourseUnit;
    @Input() versionChangeInProgress: boolean;
    @Output() selectSubstitutions = new EventEmitter<SelectSubstitutionsEvent>();
    @Output() removeSubstitutions = new EventEmitter<RemoveSubstitutionsEvent>();
    @Output() openCourseUnitModal = new EventEmitter<OtmId>();

    // These replay subjects are used so that inputs can be used as observable sources.
    validatablePlanInputReplaySubject$ = new ReplaySubject<ValidatablePlan>();
    planInputReplaySubject$ = new ReplaySubject<Plan>();
    courseUnitInputReplaySubject$ = new ReplaySubject<CourseUnit>();

    selectSubstitutionTriggerSubject$ = new Subject<OtmId | null>();
    destroyed$ = new Subject<void>();

    data$: Observable<CourseUnitInfoModalSubstitutionCorrespondencesData>;

    constructor(private uuidService: UuidService,
                private authService: AuthService,
                private previewModeService: PreviewModeService,
                private appErrorHandler: AppErrorHandler,
                @Inject(ATTAINMENT_SERVICE) private attainmentService: any,
                @Inject(DEFAULT_PROMISE_HANDLER) private defaultPromiseHandler: any,
                private courseUnitEntityService: CourseUnitEntityService) { }

    ngOnInit(): void {
        // Instead of always recalculating everything when input values change observables are used here to recalculate
        // values only on relevant changes to that value.
        const editable$: Observable<boolean> = this.createEditableObservable();
        // Options need shareReplay. Otherwise, multiple subscribers will generate different ids for options.
        const substitutionOptions$: Observable<CourseUnitSubstitutionWithLocalId[]> = this.createSubstitutionOptionsObservable().pipe(shareReplay());
        const selected$: Observable<string> = this.createSelectedObservable(substitutionOptions$);
        const isLoggedIn: boolean = this.authService.loggedIn();
        let allAttainments$: Observable<Attainment[]>;
        // Notice: LoggedIn and PreviewMode -status are only checked once during init.
        if (!isLoggedIn || this.previewModeService.isPreviewMode()) {
            allAttainments$ = of([]);
        } else {
            allAttainments$ = this.createAllAttainmentsObservable().pipe(shareReplay());
        }
        const courseUnitAttainments$ = allAttainments$.pipe(this.filterCourseUnitAttainments());
        const attainmentsByCourseUnitGroupId$ = courseUnitAttainments$.pipe(this.keyAttainmentsByCourseUnitGroupId());
        const substituteCourseUnitsByGroupId$ = this.createSubstituteCourseUnitsByGroupIdObservable(courseUnitAttainments$, attainmentsByCourseUnitGroupId$);

        // Data-observable wraps all the observables and will be subscribed to in the template
        this.data$ = combineLatest({
            isLoggedIn: of(isLoggedIn),
            editable: editable$,
            substitutionOptions: substitutionOptions$,
            allAttainments: allAttainments$,
            selected: selected$,
            attainmentsByCourseUnitGroupId: attainmentsByCourseUnitGroupId$,
            substituteCourseUnitsByGroupId: substituteCourseUnitsByGroupId$,
        });

        // Substitution selection is handled through an observable stream so that it can get data from
        // the data-observable.
        this.selectSubstitutionTriggerSubject$.pipe(
            takeUntil(this.destroyed$),
            this.selectOrRemoveSubstitutionOptionHandler(),
        ).subscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        const courseUnitChange = changes?.courseUnit;
        const planChange = changes?.plan;
        const validatablePlanChange = changes?.validatablePlan;

        // Emit changed values through subjects and trigger recalculation for relevant values
        if (courseUnitChange) {
            this.courseUnitInputReplaySubject$.next(courseUnitChange.currentValue);
        }
        if (planChange) {
            this.planInputReplaySubject$.next(planChange.currentValue);
        }
        if (validatablePlanChange) {
            this.validatablePlanInputReplaySubject$.next(validatablePlanChange.currentValue);
        }
    }

    createAllAttainmentsObservable(): Observable<Attainment[]> {
        return from(convertAJSPromiseToNative(this.attainmentService.getMyValidAttainments() as Promise<Attainment[]>))
            // Note: This is an AngularJS service that uses AngularJS http-client.
            .pipe(catchError(this.defaultPromiseHandler.loggingRejectedPromiseHandler));
    }

    filterCourseUnitAttainments(): OperatorFunction<Attainment[], CourseUnitAttainment[]> {
        return map((attainments: Attainment[]) => attainments.filter(attainment => attainment.type === 'CourseUnitAttainment') as CourseUnitAttainment[]);
    }

    keyAttainmentsByCourseUnitGroupId(): OperatorFunction<Attainment[], AttainmentsByCourseUnitGroupId> {
        return map((attainments) => _.keyBy(attainments, 'courseUnitGroupId'));
    }

    /**
     * An observable that recalculates current substitute course units by group id when the input
     * course unit changes.
     *
     * @param courseUnitAttainments$ Course unit attainments filtered from all valid attainments.
     * @param attainmentsByCourseUnitGroupId$
     */
    createSubstituteCourseUnitsByGroupIdObservable(courseUnitAttainments$: Observable<CourseUnitAttainment[]>,
                                                   attainmentsByCourseUnitGroupId$: Observable<AttainmentsByCourseUnitGroupId>):
        Observable<SubstituteCourseUnitsByGroupId> {
        return combineLatest([
            this.courseUnitInputReplaySubject$,
            courseUnitAttainments$,
            attainmentsByCourseUnitGroupId$,
        ]).pipe(this.substituteCourseUnitsByGroupId());
    }

    substituteCourseUnitsByGroupId(): OperatorFunction<[CourseUnit, CourseUnitAttainment[], AttainmentsByCourseUnitGroupId], SubstituteCourseUnitsByGroupId> {
        return switchMap(([courseUnit, courseUnitAttainments, attainmentsByCourseUnitGroupId]) => {
            const substituteCourseUnitGroupIds = this.getAllUniqueSubstituteCourseUnitGroupIds(courseUnit);
            const attainedSubstituteCourseUnitIds = this.getAttainedSubstituteCourseUnitIds(courseUnitAttainments as CourseUnitAttainment[], substituteCourseUnitGroupIds);
            const attainmentGroupIdsSet = new Set(..._.keys(attainmentsByCourseUnitGroupId));
            const courseUnitGroupIdsWithoutAttainments = this.getUniqueCourseUnitGroupIdsForNonAttainedSubstitutes([...substituteCourseUnitGroupIds], attainmentGroupIdsSet);
            const attainedCourseUnits$ = this.courseUnitEntityService.getByIds(attainedSubstituteCourseUnitIds);
            const nonAttainedCourseUnits$ = this.courseUnitEntityService.getByGroupIds(courseUnitGroupIdsWithoutAttainments);
            return combineLatest([attainedCourseUnits$, nonAttainedCourseUnits$]).pipe(
                this.appErrorHandler.defaultErrorHandler(),
                map(([attainedCourseUnits, nonAttainedCourseUnits]) => _.keyBy(_.concat(attainedCourseUnits, nonAttainedCourseUnits), 'groupId')),
            );
        });
    }

    /**
     * An observable that recalculates isEditable-value when plan, validatablePlan or courseUnit -inputs change.
     */
    createEditableObservable(): Observable<boolean> {
        return combineLatest([
            this.planInputReplaySubject$,
            this.validatablePlanInputReplaySubject$,
            this.courseUnitInputReplaySubject$,
        ]).pipe(this.isEditable());
    }

    isEditable(): OperatorFunction<[Plan, ValidatablePlan, CourseUnit], boolean> {
        return map(([plan, validatablePlan, courseUnit]) => !!plan &&
            this.authService.loggedIn() &&
            validatablePlan.isCourseUnitInPlan(courseUnit) &&
            !validatablePlan.isCourseUnitAttained(courseUnit?.id));
    }

    /**
     * An observable that recalculates substitution options when input courseUnit changes.
     */
    createSubstitutionOptionsObservable(): Observable<CourseUnitSubstitutionWithLocalId[]> {
        return this.courseUnitInputReplaySubject$
            .pipe(this.createSubstitutionOptions());
    }

    createSubstitutionOptions(): OperatorFunction<CourseUnit, CourseUnitSubstitutionWithLocalId[]> {
        return map((courseUnit) => courseUnit?.substitutions.map(substitution => ({
            localId: this.uuidService.randomUUID(),
            substitution,
        })) ?? []);
    }

    /**
     * An observable that recalculates the selected substitution option when validatablePlan or courseUnit -inputs change
     * or when substitution options change.
     *
     * @param substitutionOptions$ An observable of current substitution options.
     */
    createSelectedObservable(substitutionOptions$: Observable<CourseUnitSubstitutionWithLocalId[]>): Observable<string> {
        return combineLatest([
            this.validatablePlanInputReplaySubject$,
            this.courseUnitInputReplaySubject$,
            substitutionOptions$,
        ]).pipe(this.findCurrentlySelectedSubstitutionOptionId());
    }

    findCurrentlySelectedSubstitutionOptionId(): OperatorFunction<[ValidatablePlan, CourseUnit, CourseUnitSubstitutionWithLocalId[]], string> {
        return map(([validatablePlan, courseUnit, options]) => {
            const currentSubstitutedBy = validatablePlan.getSubstitutedBy(courseUnit);
            const selectedSubstitution = validatablePlan.findFirstMatchingSubstitution(courseUnit?.substitutions, currentSubstitutedBy);
            return options.find(option => {
                const substitutionOptionGroupIds = option.substitution.map(s => s.courseUnitGroupId);
                const selectedSubstitutionGroupIds = selectedSubstitution?.map(s => s.courseUnitGroupId);
                return _.isEmpty(_.xor(selectedSubstitutionGroupIds, substitutionOptionGroupIds));
            })?.localId;
        });
    }

    getAllUniqueSubstituteCourseUnitGroupIds(courseUnit: CourseUnit): Set<OtmId> {
        return new Set(courseUnit?.substitutions
            .flatMap(substitutions => substitutions)
            .map((substitution => substitution.courseUnitGroupId)) ?? []);
    }

    getAttainedSubstituteCourseUnitIds(courseUnitAttainments: CourseUnitAttainment[], substituteCourseUnitGroupIds: Set<OtmId>): OtmId[] {
        return courseUnitAttainments
            .filter(cuAtt => substituteCourseUnitGroupIds.has(cuAtt.courseUnitGroupId))
            .map(cuAtt => cuAtt.courseUnitId);
    }

    getUniqueCourseUnitGroupIdsForNonAttainedSubstitutes(substituteCourseUnitGroupIds: OtmId[], attainedCourseUnitGroupIds: Set<OtmId>): OtmId[] {
        return [...new Set(substituteCourseUnitGroupIds
            .filter(substituteCourseUnitGroupId => !attainedCourseUnitGroupIds.has(substituteCourseUnitGroupId)))];
    }

    selectOrRemoveSubstitutionOption(selected: Partial<{ selectedSubstitutionsLocalId: string }>): void {
        const { selectedSubstitutionsLocalId } = selected;
        this.selectSubstitutionTriggerSubject$.next(selectedSubstitutionsLocalId);
    }

    selectOrRemoveSubstitutionOptionHandler(): OperatorFunction<OtmId | null, any> {
        return pipe(
            withLatestFrom(this.data$),
            tap(([selectedOptionId, data]) => {
                if (data.editable) {
                    if (!selectedOptionId) {
                        this.removeSubstitutions.emit({
                            courseUnit: this.courseUnit,
                            plan: this.plan,
                        });
                    } else {
                        const substitution = data.substitutionOptions.find((option: CourseUnitSubstitutionWithLocalId) => option.localId === selectedOptionId);
                        if (data.selected !== substitution?.localId) {
                            const event: SelectSubstitutionsEvent = {
                                courseUnit: this.courseUnit,
                                substitution: substitution.substitution,
                                plan: this.plan,
                                substituteCourseUnitsByGroupId: data.substituteCourseUnitsByGroupId,
                            };
                            this.selectSubstitutions.emit(event);
                        }
                    }
                }
            }));
    }

    openCourseUnitInfoModal(courseUnitId: OtmId): void {
        this.openCourseUnitModal.emit(courseUnitId);
    }
}
