import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CreditTransferInfo } from 'common-typescript/types';

@Component({
    selector: 'sis-attainment-credit-transfer-info-details',
    templateUrl: './attainment-credit-transfer-info-details.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttainmentCreditTransferInfoDetailsComponent implements OnInit {

    @Input() creditTransferInfo: CreditTransferInfo;

    /**
     * When international institution or organisation is selected both
     */
    showInternationalInstitutionOrganisation = false;

    ngOnInit() {
        this.showInternationalInstitutionOrganisation =
            this.hasEducationalInstitutionUrnOrOrganisation(this.creditTransferInfo);
    }

    hasEducationalInstitutionUrnOrOrganisation(creditTransferInfo: CreditTransferInfo): boolean {
        return !!creditTransferInfo?.internationalInstitutionUrn || !!creditTransferInfo?.organisation;
    }

}
