import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SisComponentsModule } from 'sis-components/sis-components.module';

import { RemoveAllDialogComponent } from './controls/removeAllDialog-ng.component';
import { RemoveAllDialogDirective } from './controls/removeAllDialog.dir.upgraded';
import { TimelineNoteComponent } from './line/note/timeline-note/timeline-note.component';
import { TimelineNoteModalComponent } from './line/note/timelineAddNoteModal-ng.component';
import { TimelineNoteModalDirective } from './line/note/timelineAddNoteModal.dir.upgraded';

@NgModule({
    declarations: [
        RemoveAllDialogComponent,
        RemoveAllDialogDirective,
        TimelineNoteComponent,
        TimelineNoteModalComponent,
        TimelineNoteModalDirective,
    ],
    imports: [
        CommonModule,
        SisComponentsModule,
    ],
})
export class TimingModule { }
