import { ChangeDetectionStrategy, Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { StudyEvent, StudySubGroup } from 'common-typescript/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { STUDY_EVENT_SERVICE } from 'sis-components/ajs-upgraded-modules';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { StudyEventEntityService } from 'sis-components/service/study-event-entity.service';

@Component({
    selector: 'app-student-study-sub-group-structure',
    templateUrl: './student-study-sub-group-structure.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudentStudySubGroupStructureComponent implements OnInit {

    @Input() studySubGroup: StudySubGroup;
    @Input() language: string;

    $sortedStudyEvents: Observable<StudyEvent[]>;

    constructor(
        private appErrorHandler: AppErrorHandler,
        private studyEventEntityService: StudyEventEntityService,
        @Inject(STUDY_EVENT_SERVICE) private commonStudyEventService: any,
    ) { }

    ngOnInit(): void {
        this.$sortedStudyEvents = this.createSortedStudyEventsObservable();
    }

    createSortedStudyEventsObservable(): Observable<StudyEvent[]> {
        return this.studyEventEntityService.getByIds(this.studySubGroup.studyEventIds)
            .pipe(
                this.appErrorHandler.defaultErrorHandler(),
                map(this.commonStudyEventService.orderStudyEventsByTime),
            ) as Observable<StudyEvent[]>;
    }
}
