import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CourseUnit, CourseUnitRealisation, OtmId, ValidatablePlan } from 'common-typescript/types';
import { ModalService } from 'sis-common/modal/modal.service';

export interface SelectCourseUnitAndCompletionMethodValues {
    courseUnitRealisation: CourseUnitRealisation;
    courseUnits: CourseUnit[];
    courseUnitsInPlan: CourseUnit[];
    enrolmentTargetId: OtmId;
    validatablePlan: ValidatablePlan;
}

@Component({
    selector: 'app-select-course-unit-and-completion-method-modal',
    template: '<select-course-unit-and-completion-method-modal (close)="activeModal.close($event.$value)" [resolve]="values"></select-course-unit-and-completion-method-modal>',
    encapsulation: ViewEncapsulation.None,
})
export class SelectCourseUnitAndCompletionMethodModalComponent {
    constructor(@Inject(ModalService.injectionToken) public values: SelectCourseUnitAndCompletionMethodValues,
                public activeModal: NgbActiveModal) {}
}
