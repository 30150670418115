import { Injectable } from '@angular/core';
import _ from 'lodash';

/**
 * Upgraded from personName.service.js. Code duplication is outweighed by simplicity of imports.
 */
@Injectable({
    providedIn: 'root',
})
export class PersonNameService {

    getFullName(personObject: PersonWithName): string {
        return _.compact([(personObject?.firstNames || personObject?.firstName), personObject?.lastName]).join(' ');
    }

    getFullNameReversed(personObject: PersonWithName): string {
        return _.compact([personObject?.lastName, (personObject?.firstNames || personObject?.firstName)]).join(' ');
    }
}

export interface PersonWithName {
    lastName: string;
    firstName?: string;
    firstNames?: string;
}
