<sis-expandable *ngIf="decision$|async as decision"
                [closed]="false"
                [title]="'STUDENT_APPLICATIONS.MODULE_ATTAINMENT_APPLICATION_ATTAINMENT.REJECT_RATIONALE_TITLE'|translate">
  <ng-template sisExpandableContent>
    <span [translateParams]="{moduleName: ((this.application.moduleId|module|async)?.name | localizedString)}"
          [translate]="'STUDENT_APPLICATIONS.MODULE_ATTAINMENT_APPLICATION_ATTAINMENT.EVALUATION_WAS_REJECTED'">
    </span>
    <span class="rejected-rationale">
    "{{decision?.resolutionRationale}}"
    </span>
  </ng-template>
</sis-expandable>
