<div class="container course-unit-info-as-page sis-pt-md">
  <app-course-unit-info-header [courseUnit]="courseUnit"></app-course-unit-info-header>
  <app-course-unit-info-navigation #navigation></app-course-unit-info-navigation>
  <div class="sis-pt-md">
    <div [attr.id]="tabId" role="tabpanel" tabindex="0">
      <h2 *ngIf="navigation.selectedTab as selectedTab" class="mt-0 mb-4">{{selectedTab?.title | translate}}</h2>
      <div ui-view="course-unit-info-tab-content"></div>
    </div>
  </div>
</div>
