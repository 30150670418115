import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import { Code, Urn } from 'common-typescript/types';
import _ from 'lodash';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-code-selection-editor',
    templateUrl: './code-selection-editor.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CodeSelectionEditorComponent implements OnInit, OnChanges {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.select.codeSelectionEditor',
        directiveName: 'sisCodeSelectionEditor',
    };

    @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;

    @Input() required: boolean;
    @Input() disabled: boolean;
    @Input() selectedCodes: Code[];
    @Input() selectedCode: CodeOption;
    @Input() universityCodes: Code[];
    @Input() otherCodes: Code[];
    @Input() hideOtherCodes: boolean;
    @Input() showNumericalCodeValue: boolean;

    @Input() filterTranslations: any;
    @Input() showAllLanguageVersions: boolean;
    @Input() allowDeprecatedValueSelection: boolean;
    // if true, dropdown will show the last part of urn and also filter input will try to match the last part
    @Input() showLastPart: boolean;
    @Input() id?: string;
    @Input() labelForId?: string;
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onSelect = new EventEmitter<any>();
    @Input() groupResults = true;
    @Input() notFoundKey?: string;

    groupByColumn = 'universityGroupValue';
    codeOptions: CodeOption[];
    universityCodeOptions: CodeOption[];
    otherCodeOptions: CodeOption[];
    codeOptionsResults: (CodeOption | Partial<CodeOption>)[];
    allowClear: boolean;
    locales: string[];
    currentLocale = this.localeService.getCurrentLanguage();
    storedCode: CodeOption;
    previousTerm: string;

    constructor(private localeService: LocaleService,
                private translateService: TranslateService) { }

    ngOnInit(): void {
        if (!this.groupResults) {
            this.groupByColumn = null;
        }
        this.initCodeOptions();
        this.disableItems();
        this.setDefaultCodeOptionsForSearch();
        this.storedCode = this.selectedCode;
        this.allowClear = !this.required;
        this.locales = this.localeService.getOfficialLanguages();
    }

    ngOnChanges() {
        this.initCodeOptions();
        this.setDefaultCodeOptionsForSearch();
        this.allowClear = !this.required;
        if (this.codeOptions) {
            this.disableItems();
        }
    }

    getLastPart(urn: Urn): string {
        const parts = (urn || '').split(':');
        return parts.length === 0 ? '' : parts[parts.length - 1];
    }

    disableItems() {
        this.codeOptions.forEach((code: CodeOption) => {
            // ng-select -library uses objects "disabled" -property for disabling the selection
            code.disabled = this.isOptionDisabled(code);
        });
    }

    initCodeOptions() {
        // Group titles are shown only when there are visible codes from both categories
        const showGroupTitles =
            this.universityCodes?.length > 0 &&
            this.otherCodes?.length > 0 &&
            !this.hideOtherCodes;
        this.universityCodeOptions = this.createCodeOptions(this.universityCodes, showGroupTitles, this.filterTranslations.myUniversity);
        this.otherCodeOptions = this.createCodeOptions(this.otherCodes, showGroupTitles, this.filterTranslations.otherUniversities);

        this.localizeName(this.universityCodeOptions);
        this.localizeName(this.otherCodeOptions);

        this.codeOptions = this.hideOtherCodes
            ? this.universityCodeOptions
            : this.universityCodeOptions.concat(this.otherCodeOptions);

        this.codeOptions = _.orderBy(this.codeOptions, 'localizedName', 'asc');
        this.codeOptions = _.sortBy(
            this.codeOptions,
            result => (
                result.universityGroupValue === this.filterTranslations.myUniversity ? 0 : 1
            ));
    }

    createCodeOptions(codes: Code[], showGroupTitle: boolean, group: string) {
        return codes.map(code => ({
            ...code,
            ...(showGroupTitle) && { universityGroupValue: group },
        } as CodeOption));
    }

    localizeName(codeOptions: CodeOption[]) {
        codeOptions.forEach(option => option.localizedName = this.localeService.localize(option.name));
    }

    isOptionDisabled(code: CodeOption): boolean {
        return this.isSelected(code) || this.isDeprecated(code);
    }

    isSelected(code: CodeOption) {
        if (!code) {
            return false;
        }

        return this.selectedCodes?.some(selectedCode => selectedCode?.urn === code.urn);
    }

    isDeprecated(code: CodeOption) {
        if (this.allowDeprecatedValueSelection) {
            return false;
        }
        return code.deprecated;
    }

    selectCode(newSelectedCode: any) {
        const currentlySelectedCode = this.storedCode;
        if (newSelectedCode && this.isSelected(newSelectedCode)) {
            return;
        }
        this.selectedCode = newSelectedCode;
        this.storedCode = this.selectedCode;

        this.onSelect.emit({
            code: newSelectedCode,
            previous: currentlySelectedCode,
        });
    }

    search(event: any) {
        const term = event.term;

        if (this.previousTerm !== term) {
            this.previousTerm = term;
            this.codeOptionsResults = [];

            const termLowerCase = term.toLowerCase();

            this.codeOptions.forEach((option: CodeOption) => {
                const optionIncludesTerm = this.optionIncludesTerm(option, termLowerCase);

                if (optionIncludesTerm && this.codeOptionsResults.length <= 20) {
                    this.codeOptionsResults.push(option);
                }

                if (this.codeOptionsResults.length === 20) {
                    this.addTooManySearchResultsObject();
                }
            });
        }
    }

    private optionIncludesTerm(option: CodeOption, term: string): boolean {
        const lowerName = option.localizedName.toLowerCase();

        return (
            lowerName.includes(term) ||
            (this.showLastPart && this.getLastPart(option.urn).includes(term)) ||
            (this.showNumericalCodeValue && `${this.getLastPart(option.urn)}, ${lowerName}`.includes(term))
        );
    }

    setDefaultCodeOptionsForSearch(hideSelected?: boolean) {
        if (hideSelected) {
            this.selectedCode = undefined;
        }

        if (this.codeOptions.length > 20) {
            this.codeOptionsResults = this.codeOptions.slice(0, 20);
            this.addTooManySearchResultsObject();
        } else {
            this.codeOptionsResults = this.codeOptions;
        }
    }

    setStoredCodeBackAsSelected() {
        this.selectedCode = this.storedCode;
    }

    addTooManySearchResultsObject() {
        const translationText = this.translateService.instant('SIS_COMPONENTS.OBJECT.REFERRED_OBJECT_SELECT_EDITOR.TOO_MANY_SEARCH_RESULTS');
        const tooManySearchResultObj: Partial<CodeOption> = {
            name: {},
            disabled: true,
            isTooManySearchResult: true,
        };
        tooManySearchResultObj.name[this.currentLocale] = translationText;
        this.codeOptionsResults = [...this.codeOptionsResults, tooManySearchResultObj];
    }

    closeOnBlur() {
        this.ngSelectComponent.close();
    }

    notFoundKeyToUse() {
        return this.notFoundKey || 'SIS_COMPONENTS.OBJECT.REFERRED_OBJECT_SELECT_EDITOR.NOT_FOUND_TEXT';
    }
}

export interface CodeOption extends Code {
    disabled: boolean;
    universityGroupValue: string;
    localizedName: string;
    isTooManySearchResult: boolean
}
