import textWithDefaultMissingValueTpl from './textWithDefaultMissingValue.tpl.html';
'use strict';
(function () {
  angular.module('sis-components.applications.textWithDefaultMissingValue', []).component('textWithDefaultMissingValue', {
    bindings: {
      isOption: '<?',
      value: '<?',
      valueKey: '<?'
    },
    template: textWithDefaultMissingValueTpl,
    controller: ["$translate", function ($translate) {
      var ctrl = this;
      this.$onChanges = function () {
        ctrl.renderedValue = ctrl.value || $translate.instant(ctrl.valueKey);
        ctrl.hasValue = !!ctrl.renderedValue;
        ctrl.missingValue = ctrl.isOption ? '-' : $translate.instant('STUDENT_APPLICATIONS.INFO_MISSING');
      };
    }]
  });
})();