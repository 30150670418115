import { Injectable } from '@angular/core';
import { CurriculumPeriod, LocalizedString, Organisation, OtmId, StudyModule } from 'common-typescript/types';
import _ from 'lodash';
import { combineLatest, iif, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocaleService } from 'sis-common/l10n/locale.service';

import { SelectOption } from '../select/select-combobox/select-combobox.component';

import { CurriculumPeriodEntityService } from './curriculum-period-entity.service';
import { OrganisationEntityService } from './organisation-entity.service';

@Injectable({
    providedIn: 'root',
})
export class CurriculumPeriodNameService {

    constructor(private curriculumPeriodEntityService: CurriculumPeriodEntityService,
                private organisationEntityService: OrganisationEntityService,
                private localeService: LocaleService) { }

    getCurriculumPeriodNameByIds(curriculumPeriodIds: OtmId | OtmId[], displayUniversityAbbreviation: boolean = false): Observable<string> {
        if (curriculumPeriodIds && curriculumPeriodIds.length > 0) {
            const ids = Array.isArray(curriculumPeriodIds) ? curriculumPeriodIds : [curriculumPeriodIds];
            return combineLatest([
                this.curriculumPeriodEntityService.getByIds(ids),
                iif(() => displayUniversityAbbreviation,
                    this.organisationEntityService.getRootOrganisations(),
                    of([])),
            ])
                .pipe(
                    map(([curriculumPeriods, rootOrganisations]) =>
                        this.sortedAbbreviations(curriculumPeriods, rootOrganisations, displayUniversityAbbreviation)),
                    map(abbreviations => this.localeService.localizeArray(abbreviations)),
                );
        }
        return null;
    }

    /**
     * Returns a list of localized string curriculum period names for the given curriculum periods with a concatenated
     * abbreviation of the root organisation.
     *
     * @param curriculumPeriods Curriculum periods to get names for
     * @param rootOrganisations All root organisations
     */
    getCurriculumPeriodNamesWithRootOrganisationAbbreviations(curriculumPeriods: CurriculumPeriod[], rootOrganisations: Organisation[]): LocalizedString[] {
        return this.sortedAbbreviations(curriculumPeriods, rootOrganisations, true);
    }

    private sortedAbbreviations(curriculumPeriods: CurriculumPeriod[],
                                rootOrganisations: Organisation[],
                                displayUniversityAbbreviation: boolean): LocalizedString[] {
        const orgsById = displayUniversityAbbreviation ? _.keyBy(rootOrganisations, 'id') : {};
        return _(curriculumPeriods).sortBy('activePeriod.startDate')
            .map(period => {
                if (!displayUniversityAbbreviation) {
                    return period.abbreviation;
                }
                const abbreviation = {} as LocalizedString;
                for (const [key, value] of Object.entries(period.abbreviation)) {
                    const universityAbbreviation = orgsById[period.universityOrgId]?.abbreviation[key] ?? '';
                    abbreviation[key] = `${value} (${universityAbbreviation})`;
                }
                return abbreviation;
            })
            .value();
    }

    getVersionSelectOptions(versions: StudyModule[], displayUniversityAbbreviation: boolean = false): Observable<SelectOption[]> {
        const allCurriculumPeriodIds = _.chain(versions)
            .flatMap(version => version.curriculumPeriodIds)
            .uniq()
            .value();
        return combineLatest([
            this.curriculumPeriodEntityService.getByIds(allCurriculumPeriodIds),
            iif(() => displayUniversityAbbreviation,
                this.organisationEntityService.getRootOrganisations(),
                of([])),
        ])
            .pipe(
                map(([curriculumPeriods, rootOrganisations]) => {
                    const curriculumPeriodsById = _.keyBy(curriculumPeriods, 'id');
                    const sortedVersions = _.sortBy(versions, (version) => {
                        const sortedCurriculumPeriods = _.chain(version.curriculumPeriodIds)
                            .map(id => _.get(curriculumPeriodsById, id))
                            .sortBy('activePeriod.startDate')
                            .value();
                        const earliestCurriculumPeriod = _.first(sortedCurriculumPeriods);
                        return _.get(earliestCurriculumPeriod, 'activePeriod.startDate');
                    });
                    return _.map(sortedVersions, version => {
                        const sortedAbbreviations = this.sortedAbbreviations(
                            _.map(version.curriculumPeriodIds, id => _.get(curriculumPeriodsById, id)),
                            rootOrganisations,
                            displayUniversityAbbreviation,
                        );
                        return {
                            value: version.id,
                            label: this.localeService.localizeArray(sortedAbbreviations),
                        } as SelectOption;
                    });
                }),
            );
    }
}
