import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StudyTermLocator } from 'common-typescript/types';

@Pipe({ name: 'studyTermName' })
export class StudyTermNamePipe implements PipeTransform {

    constructor(private translate: TranslateService) {}

    /**
     * Returns the translated name of a study term (i.e. Autumn/Spring). Accepts either a `StudyTermLocator` object or
     * the term index (`StudyTermLocator.termIndex`) as input.
     */
    transform(value: StudyTermLocator | number): string {
        const termIndex = (typeof value === 'number') ? value : value?.termIndex;
        return (termIndex === 0 || termIndex === 1) ?
            this.translate.instant(`STUDY_TERMS.${termIndex === 0 ? 'AUTUMN' : 'SPRING'}`) : undefined;
    }
}
