<ng-container *transloco="let t">
  <div class="sis-plan-structure-education">
    <div class="sis-plan-structure-education__header">
      <div class="row">
        <div class="col-12">
          <h2 [attr.id]="educationStateObject?.elementId">{{ education?.name | localizedString }}
            <sis-plan-structure-module-state-badges [documentState]="education.documentState">
            </sis-plan-structure-module-state-badges>
          </h2>
        </div>
      </div>
      <div class="row sis-plan-structure-education__header__additional-info">
        <div class="col-md col-sm-12"><p class="sis-mb-zero">{{education?.code}}</p></div>
        <div class="col-md col-sm-12 mt-3 mt-sm-0">
          <sis-plan-structure-module-selection-state class="float-sm-start float-md-end"
                                                     [stateObject]="planStateObject.education">
          </sis-plan-structure-module-selection-state>
        </div>
        <hr class="sis-hr sis-mb-zero sis-mt-xs"/>
      </div>
    </div>
    <div class="d-flex flex-wrap sis-mb-xs sis-pl-zero">
      <div class="ms-auto flex-md-row order-2 order-lg-3">
        <sis-button [type]="'button'"
                    [variant]="'primary'"
                    [size]="'sm'"
                    [ariaLabel]="t('SIS_COMPONENTS.PLAN.ARIA.OPEN_PLAN_EDIT_MODAL')"
                    [naked]="true"
                    (clicked)="openModule()">
          <sis-icon [icon]="'three-dots'"></sis-icon>
        </sis-button>
      </div>
    </div>
    <sis-plan-structure-module-content [moduleOrEducation]="education"
                                       [planValidationResult]="planValidationResult"
                                       [planData]="planData"
                                       [planStateObject]="planStateObject"
                                       [moduleOrEducationStateObject]="planStateObject?.education"
                                       [headingLevel]="headingLevel + 1">
    </sis-plan-structure-module-content>
  </div>
</ng-container>



