import {
    AfterViewInit,
    Component, DestroyRef, ElementRef, EventEmitter,
    Input,
    OnInit, Output, QueryList, ViewChild, ViewChildren,
    ViewEncapsulation,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FullCalendarComponent as FullCalendar } from '@fullcalendar/angular';
import { CalendarOptions } from '@fullcalendar/core';
import { StateService } from '@uirouter/core';
import { Enrolment, ValidatablePlan } from 'common-typescript/types';
import _ from 'lodash';
import { combineLatest, Observable, Subject, take, tap } from 'rxjs';
import { ModalService } from 'sis-common/modal/modal.service';
import { getElementResizeEventObservable } from 'sis-common/resize-observer/resize-observer';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';
import { CalendarSettingsModalComponent } from 'sis-components/calendar/dialog/calendarSettingsModal.component';

import { AddOwnCalendarEventModalComponent } from '../dialog/addOwnCalendarEventModal.component';

import { FullCalendarMessageService } from './full-calendar-message.service';
import { FullCalendarWebStorageService } from './full-calendar-web-storage.service';
import { FullCalendarService } from './full-calendar.service';
import { HiddenEventPreference } from './full-calendar.types';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'app-full-calendar',
    templateUrl: './full-calendar.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class FullCalendarComponent implements OnInit, AfterViewInit {
    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'student.calendar.fullCalendarNg',
        directiveName: 'appFullCalendar',
    };

    constructor(
        private fullCalendarService: FullCalendarService,
        private modalService: ModalService,
        private state: StateService,
        private fullCalendarMessageService: FullCalendarMessageService,
        private destroyRef: DestroyRef,
        private elementRef: ElementRef,
        private fullCalendarWebStorageService: FullCalendarWebStorageService,
    ) {}

    @ViewChild('breadcrumbsContainer') breadcrumbsContainer: ElementRef;
    @ViewChild('fullCalendar') fullCalendar: FullCalendar;
    @ViewChildren('calendarContainer') calendarContainers: QueryList<ElementRef>;

    @Input() enrolments: Enrolment[];
    @Input() getValidatablePlan: () => Promise<ValidatablePlan>;
    @Output() toggleRightBar = new EventEmitter<void>();

    calendarOptions: Observable<CalendarOptions>;
    cancelledEventsHidden: boolean;
    reFetchStudyEvents$: Subject<void> = new Subject<void>();
    calendarLoaded: Subject<void> = new Subject<void>();

    ngOnInit() {
        this.calendarOptions = this.fullCalendarService.getOptions(this.enrolments, () => this.getContentWidth(), () => this.fullCalendarApi);
        this.cancelledEventsHidden = this.fullCalendarWebStorageService.getHiddenEventPreference().includes('cancelled');
    }

    ngAfterViewInit() {
        this.fullCalendarMessageService.goToDate$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((val: string) => this.fullCalendarApi.gotoDate(val));

        this.fullCalendarMessageService.reFetchEvents$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.fullCalendarApi.refetchEvents());

        this.reFetchStudyEvents$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.fullCalendarApi.getEventSourceById('studyEventSource').refetch());

        this.calendarContainers.changes.pipe(
            tap(() => this.calendarLoaded.next()),
        ).subscribe();

        this.observeBreadcrumbsAndCalendarElement();
    }

    /**
     * A one time operation which waits for breadcrumbs and FullCalendar to render, then sets the calendar height accordingly.
     * If they are already rendered at the time of the ngAfterViewInit lifecycle, set the height without delay.
     * Sequential resize adjustments are handled by FullCalendar's windowResize callback
     */
    observeBreadcrumbsAndCalendarElement(): void {
        if (this.breadcrumbsContainer && this.calendarContainers?.length > 0 && this.fullCalendarApi) {
            this.fullCalendarService.setHeight(() => this.fullCalendarApi, () => this.getContentWidth());
            return;
        }
        const breadcrumbsContent: HTMLElement = this.breadcrumbsContainer.nativeElement;
        const breadcrumbsContentChanges$: Observable<ResizeObserverEntry[]> = getElementResizeEventObservable(breadcrumbsContent);
        combineLatest([this.calendarLoaded, breadcrumbsContentChanges$]).pipe(
            take(1))
            .subscribe((): void => {
                this.fullCalendarService.setHeight(() => this.fullCalendarApi, () => this.getContentWidth());
            });
    }

    getContentWidth(): number {
        return this.elementRef.nativeElement.parentElement.offsetWidth;
    }

    addOwnCalendarEvent() {
        this.modalService.open(AddOwnCalendarEventModalComponent, { })
            .result
            .then(() => this.state.reload())
            .catch(() => {});
    }

    openCalendarSettings() {
        this.modalService.open(CalendarSettingsModalComponent, {});
    }

    toggleCancelledEventVisibility() {
        const currentPreference: HiddenEventPreference = this.fullCalendarWebStorageService.getHiddenEventPreference();
        const updatedPreference: HiddenEventPreference = _.xor(currentPreference, ['cancelled']);
        this.fullCalendarWebStorageService.persistHiddenEventTypePreference(updatedPreference);

        this.reFetchStudyEvents$.next();
    }

    get fullCalendarApi() {
        return this.fullCalendar.getApi();
    }
}
