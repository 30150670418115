<div class="modal-header">
  <h2 [tabIndex]="0" class="modal-title">{{'PROFILE.APPLICATIONS.ADD_PRIOR_LEARNING_MODAL.TITLE' | translate}}</h2>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-12">
      <p>{{'PROFILE.APPLICATIONS.ADD_PRIOR_LEARNING_MODAL.SUBTITLE' | translate}}</p>
    </div>
    <div class="col-12">
      <div class="form-group checkbox-group">
        <sis-radio-button [control]="priorLearningTypeControl"
                          [value]="PRIOR_LEARNING_TYPE.STUDIES"
                          label="PROFILE.APPLICATIONS.ADD_PRIOR_LEARNING_MODAL.STUDIES"
                          name="priorLearningType">
        </sis-radio-button>
        <sis-radio-button [control]="priorLearningTypeControl"
                          [value]="PRIOR_LEARNING_TYPE.COMPETENCE"
                          guidance="PROFILE.APPLICATIONS.ADD_PRIOR_LEARNING_MODAL.OTHER_PRIOR_LEARNING_INFO"
                          label="PROFILE.APPLICATIONS.ADD_PRIOR_LEARNING_MODAL.OTHER_PRIOR_LEARNING"
                          name="priorLearningType">
        </sis-radio-button>
      </div>
    </div>
    <div class="col-12">
      <span class="guidance">{{'PROFILE.APPLICATIONS.ADD_PRIOR_LEARNING_MODAL.INCLUSION_RESTRICTION_NOTE' | translate}}</span>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button"
          class="btn btn-primary btn-hollow btn-lg"
          (click)="cancel()">
    {{'SIS_COMPONENTS.BUTTON.CANCEL' | translate}}
  </button>
  <button type="button"
          class="btn btn-primary btn-lg"
          (click)="confirm()">
    {{'ADD' | translate}}
  </button>
</div>
