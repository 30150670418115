import { StateDeclaration } from '@uirouter/angularjs';
import { Transition } from '@uirouter/core';
import { ValidatablePlan } from 'common-typescript/src';
import {
    Attainment,
    CompletionMethod,
    CourseUnit,
    CourseUnitRealisation,
    Education,
    Plan,
} from 'common-typescript/types';
import { AuthService } from 'sis-common/auth/auth-service';
import { LocaleService } from 'sis-common/l10n/locale.service';

import { CourseUnitInfo } from './course-unit-info-as-page.route';

export function courseUnitInfoResolver(transition: Transition): CourseUnitInfo {
    const commonCourseUnitService = transition.injector().get('commonCourseUnitService');
    const attainmentJSDataModel = transition.injector().get('attainmentJSDataModel');
    const validAttainmentFilterService = transition.injector().get('validAttainmentFilterService');
    const authService = transition.injector().get(AuthService);

    const result: Partial<CourseUnitInfo> = {};

    return commonCourseUnitService.findById(transition.params().courseUnitId, { loadRelations: true })
        .then(
            (courseUnit: any) => {
                result.courseUnit = courseUnit;
                result.completionMethods = courseUnit.completionMethods.filter((cm: { studyType: string }) => cm.studyType !== 'OPEN_UNIVERSITY_STUDIES');
                result.productId = transition.params().productId;
                result.forceEnableCalendarAdd = !!authService.loggedIn();
                result.validatablePlan = new ValidatablePlan({ rootId: 'emptyPlan' } as Plan, [], { id: 'emptyPlan' } as Education, [], [], [], []);
                if (authService.loggedIn()) {
                    return attainmentJSDataModel.findAll({ personId: authService.personId() }, { bypassCache: false })
                        .then((attainments: any) => validAttainmentFilterService.getValidAttainments(attainments))
                        .then((filteredAttainments: any) => {
                            result.attainments = filteredAttainments;
                            return result;
                        });
                }
                return result;
            },
            (error: any) => {
                // Ignore error in promise chain. redirectWhenMissingCourseUnit handles bad results
                console.error('Error occurred while finding course unit', error);
            },
        );
}
courseUnitInfoResolver.$inject = ['$transition$'];

export function courseUnitResolver(courseUnitInfo: CourseUnitInfo): CourseUnit {
    return courseUnitInfo.courseUnit;
}
courseUnitResolver.$inject = ['courseUnitInfo'];

export function validatablePlanResolver(courseUnitInfo: CourseUnitInfo): Partial<ValidatablePlan> {
    return courseUnitInfo.validatablePlan;
}
validatablePlanResolver.$inject = ['courseUnitInfo'];

export function attainmentsResolver(courseUnitInfo: CourseUnitInfo): Attainment[] {
    return courseUnitInfo.attainments;
}
attainmentsResolver.$inject = ['courseUnitInfo'];

export function courseUnitRealisationResolver(transition: Transition): CourseUnitRealisation {
    const courseUnitRealisationId = transition.params().courseUnitRealisation;
    if (!courseUnitRealisationId) return null;
    const commonCourseUnitService = transition.injector().get('commonCourseUnitRealisationService');
    return commonCourseUnitService.findById(courseUnitRealisationId).then((courseUnitRealisation: CourseUnitRealisation) => courseUnitRealisation, (error: any) => {
        console.error('Error occurred while finding courseUnitRealisation', error);
    });
}
courseUnitRealisationResolver.$inject = ['$transition$'];

export function resolveRealisationTabId(transition: Transition): number {
    return parseInt(transition.params().tabId, 10) || 0;
}
resolveRealisationTabId.$inject = ['$transition$'];

export function forceEnableCalendarAddResolver(courseUnitInfo: CourseUnitInfo): boolean {
    return courseUnitInfo.forceEnableCalendarAdd;
}
forceEnableCalendarAddResolver.$inject = ['courseUnitInfo'];

export function completionMethodsResolver(courseUnitInfo: CourseUnitInfo): CompletionMethod[] {
    return courseUnitInfo.completionMethods;
}
completionMethodsResolver.$inject = ['courseUnitInfo'];
export function languageResolver(transition: Transition): string {
    const localeService = transition.injector().get(LocaleService);
    return localeService.getCurrentLanguage();
}
languageResolver.$inject = ['$transition$'];

export function tabIdResolver(transition: Transition): string {
    return transition.params().tabId;
}
tabIdResolver.$inject = ['$transition$'];

export function productIdResolver(transition: Transition): string {
    return transition.params().productId;
}
productIdResolver.$inject = ['$transition$'];
export function previousStateResolver(transition: Transition): StateDeclaration {
    return transition.from();
}
previousStateResolver.$inject = ['$transition$'];
