import { Injectable } from '@angular/core';
import { TranslationMarkupRendererFactory } from 'ngx-transloco-markup';

import { HtmlElementTranspiler } from '../common/html-element-transpiler';

export const EMPHASIS_START = new (class EmphasisStart {})();
export const EMPHASIS_END = new (class EmphasisEnd {})();

@Injectable()
export class EmphasisTranspiler extends HtmlElementTranspiler {
    constructor(private readonly rendererFactory: TranslationMarkupRendererFactory) {
        super({
            rendererFactory,
            start: {
                search: '<em>',
                token: EMPHASIS_START,
            },
            end: {
                search: '</em>',
                token: EMPHASIS_END,
            },
            elementTag: 'em',
        });
    }
}
