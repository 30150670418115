import { Injectable } from '@angular/core';
import { dateUtils } from 'common-typescript';
import { StudyTerm, StudyTermLocator, StudyYear } from 'common-typescript/types';
import { isEqual } from 'lodash';
import moment from 'moment/moment';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';

import { CurrentStudyYearService } from './current-study-year.service';

@Injectable({ providedIn: 'root' })
export class CurrentStudyTermService {
    /**
     * Emits the locator of the current study term of the current study year,
     * or null if the current study year doesn't have a current term.
     * Produces error if the current study year does not exist.
     */
    readonly currentStudyTermLocator$: Observable<StudyTermLocator | null>;

    constructor(currentStudyYearService: CurrentStudyYearService) {
        this.currentStudyTermLocator$ = currentStudyYearService.currentStudyYear$.pipe(
            map((currentStudyYear: StudyYear) => {
                if (!currentStudyYear.startYear || !currentStudyYear.studyTerms) {
                    return null;
                }

                const currentMoment: moment.Moment = moment();
                const currentTermIndex: number = currentStudyYear.studyTerms.findIndex((term: StudyTerm) => !!term.valid && dateUtils.rangeContains(currentMoment, term.valid));

                return currentTermIndex >= 0
                    ? <StudyTermLocator>{
                        studyYearStartYear: currentStudyYear.startYear,
                        termIndex: currentTermIndex,
                    }
                    : null;
            }),
            distinctUntilChanged(isEqual),
            shareReplay({ bufferSize: 1, refCount: true }),
        );
    }
}
