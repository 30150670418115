import { ModalService } from 'sis-common/modal/modal.service.ts';
import selectAssessmentItemModalTpl from './selectAssessmentItemModal.tpl.html';
'use strict';
(function () {
  selectAssessmentItemModalFactory.$inject = ["modalService"];
  selectAssessmentItemModalController.$inject = ["commonEnrolmentService"];
  angular.module('student.common.components.courseUnitInfoModal.selectAssessmentItemModal', ['sis-components.service.enrolmentService', ModalService.downgrade.moduleName]).controller('selectAssessmentItemModalController', selectAssessmentItemModalController).factory('selectAssessmentItemModal', selectAssessmentItemModalFactory).component('selectAssessmentItemModal', {
    bindings: {
      close: '&',
      resolve: '<'
    },
    template: selectAssessmentItemModalTpl,
    controller: selectAssessmentItemModalController
  });
  function selectAssessmentItemModalController(commonEnrolmentService) {
    var $ctrl = this;
    $ctrl.selectedAssessmentItemId = undefined;
    $ctrl.$onInit = function () {
      $ctrl.courseUnit = $ctrl.resolve.courseUnit;
      $ctrl.completionMethod = $ctrl.resolve.completionMethod;
      $ctrl.courseUnitRealisation = $ctrl.resolve.courseUnitRealisation;
      $ctrl.enrolment = $ctrl.resolve.enrolment;
      $ctrl.assessmentItems = $ctrl.resolve.assessmentItems;
      const ids = _.intersection($ctrl.resolve.completionMethod.assessmentItemIds, $ctrl.courseUnitRealisation.assessmentItemIds);
      $ctrl.possibleAssessmentItems = _.filter($ctrl.resolve.assessmentItems, assessmentItem => _.includes(ids, assessmentItem.id));
    };
    $ctrl.confirm = function () {
      if ($ctrl.resolve.enrolment && $ctrl.selectedAssessmentItemId) {
        var changes = false;
        if ($ctrl.selectedAssessmentItemId && $ctrl.resolve.enrolment.assessmentItemId !== $ctrl.selectedAssessmentItemId) {
          $ctrl.resolve.enrolment.assessmentItemId = $ctrl.selectedAssessmentItemId;
          changes = true;
        }
        if ($ctrl.courseUnit && $ctrl.resolve.enrolment.courseUnitId !== $ctrl.courseUnit.id) {
          $ctrl.resolve.enrolment.courseUnitId = $ctrl.courseUnit.id;
          changes = true;
        }
        if (changes) {
          const result = commonEnrolmentService.updateEnrolment($ctrl.resolve.enrolment).then(function () {
            return $ctrl.selectedAssessmentItemId;
          }, function () {
            return undefined;
          });
          close(result);
        } else {
          close($ctrl.selectedAssessmentItemId);
        }
      }
      close();
    };
    $ctrl.selectAssessmentItemId = function (assessmentItemId) {
      $ctrl.selectedAssessmentItemId = assessmentItemId;
    };
    $ctrl.cancel = function () {
      $ctrl.selectedAssessmentItemId = undefined;
      close($ctrl.selectedAssessmentItemId);
    };
    function close(assessmentItemId) {
      $ctrl.close({
        $value: assessmentItemId
      });
    }
  }
  function selectAssessmentItemModalFactory(modalService) {
    return {
      open: (completionMethod, courseUnitRealisation, courseUnit, enrolment, assessmentItems) => modalService.open(SelectAssessmentItemModalComponent, {
        assessmentItems,
        completionMethod,
        courseUnit,
        courseUnitRealisation,
        enrolment
      }, {
        keyboard: false
      })
    };
  }
})();