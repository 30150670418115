import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { ModuleContentWorkflow } from 'common-typescript/types';
import { ModuleContentWorkflowStructureData } from 'sis-components/applications/module-content-workflow.service';

@Component({
    selector: 'app-module-content-workflow-summary',
    templateUrl: './module-content-workflow-summary.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModuleContentWorkflowSummaryComponent {
    @Input() moduleContentWorkflow: ModuleContentWorkflow;
    @Input() moduleContentWorkflowStructureData: ModuleContentWorkflowStructureData;

    @Output() openPlan = new EventEmitter<void>();
}
