import { Injectable } from '@angular/core';
import { Attainment, Grade, GradeScale, OtmId } from 'common-typescript/types';
import { Observable, of, shareReplay, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { AttainmentEntityService } from 'sis-components/service/attainment-entity.service';
import { AttainmentValidityService } from 'sis-components/service/attainment-validity.service';
import { GradeScaleEntityService } from 'sis-components/service/grade-scale-entity.service';

import { MyAttainmentService } from '../../../common/service/my-attainment.service';

@Injectable()
export class AttainmentTreeDataService {
    /**
     * All attainments of the logged-in student with some extra data needed for attainment nodes.
     */
    readonly attainmentTreeData$: Observable<AttainmentTreeData>;

    constructor(
        myAttainmentService: MyAttainmentService,
        attainmentValidityService: AttainmentValidityService,
        gradeScaleEntityService: GradeScaleEntityService,
        private readonly _attainmentEntityService: AttainmentEntityService,
    ) {
        this.attainmentTreeData$ = myAttainmentService.allAttainments$.pipe(
            switchMap((allAttainments: readonly Attainment[]) => {
                if (!allAttainments.length) {
                    return of(<AttainmentTreeData>{
                        validAttainments: [],
                        invalidAttainments: [],
                    });
                }

                const validAttainmentIds: Set<OtmId> = attainmentValidityService.getValidAttainmentIds(allAttainments);

                const validAttainments: Attainment[] = [];
                const invalidAttainments: Attainment[] = [];
                const gradeScaleIds = new Set<OtmId>();

                const childAttainmentIdsOfValidAttainments = new Set<OtmId>();
                const childAttainmentIdsOfAllAttainments = new Set<OtmId>();

                for (const attainment of allAttainments) {
                    if (validAttainmentIds.has(attainment.id)) {
                        validAttainments.push(attainment);

                        this._attainmentEntityService
                            .toChildAttainmentIds(attainment)
                            .forEach(childAttainmentId => {
                                childAttainmentIdsOfValidAttainments.add(childAttainmentId);
                                childAttainmentIdsOfAllAttainments.add(childAttainmentId);
                            });
                    } else if (!attainment.misregistration) {
                        // Misregistered attainments will not be visible under invalid attainments
                        invalidAttainments.push(attainment);

                        // We also treat invalid misregistrations' children as if they don't have a parent
                        this._attainmentEntityService
                            .toChildAttainmentIds(attainment)
                            .forEach(childAttainmentId => childAttainmentIdsOfAllAttainments.add(childAttainmentId));
                    } else {
                        // sometimes we truly need to continue
                        // eslint-disable-next-line no-continue
                        continue;
                    }

                    if (!!attainment.gradeScaleId && !!attainment.gradeId) {
                        gradeScaleIds.add(attainment.gradeScaleId);
                    }
                }

                return gradeScaleIds.size
                    ? gradeScaleEntityService.getByIds([...gradeScaleIds]).pipe(
                        map((gradeScales: GradeScale[]) => AttainmentTreeDataService.createTreeData(
                            validAttainments,
                            invalidAttainments,
                            gradeScales,
                            childAttainmentIdsOfValidAttainments,
                            childAttainmentIdsOfAllAttainments,
                        )),
                    )
                    : of(AttainmentTreeDataService.createTreeData(
                        validAttainments,
                        invalidAttainments,
                        [],
                        childAttainmentIdsOfValidAttainments,
                        childAttainmentIdsOfAllAttainments,
                    ));
            }),
            shareReplay({ bufferSize: 1, refCount: true }),
        );
    }

    private static createTreeData(
        validAttainments: readonly Attainment[],
        invalidAttainments: readonly Attainment[],
        gradeScales: readonly GradeScale[],
        childAttainmentIdsOfValidAttainments: ReadonlySet<OtmId>,
        childAttainmentIdsOfAllAttainments: ReadonlySet<OtmId>,
    ): AttainmentTreeData {
        const gradeMap = new Map<OtmId, Map<number, Grade>>(gradeScales.map(gs => [
            gs.id,
            new Map<number, Grade>(gs.grades?.map(grade => [
                grade.localId,
                grade,
            ])),
        ]));

        return <AttainmentTreeData>{
            validAttainments: createNodeData(
                validAttainments,
                gradeMap,
                childAttainmentIdsOfValidAttainments,
                childAttainmentIdsOfAllAttainments,
            ),
            invalidAttainments: createNodeData(
                invalidAttainments,
                gradeMap,
                childAttainmentIdsOfValidAttainments,
                childAttainmentIdsOfAllAttainments,
            ),
        };
    }
}

export interface AttainmentNodeData {
    readonly attainment: Attainment;
    readonly grade: Grade | null;
    readonly hasValidParentAttainment: boolean;
    readonly hasParentAttainment: boolean;
}

export interface AttainmentTreeData {
    /**
     * Valid attainments of the logged-in student,
     * including their child/descendant attainments.
     */
    readonly validAttainments: readonly AttainmentNodeData[];

    /**
     * Invalid attainments of the logged-in student (except for misregistrations),
     * that are not children/descendants of {@link validAttainments}.
     */
    readonly invalidAttainments: readonly AttainmentNodeData[];
}

function createNodeData(
    attainments: readonly Attainment[],
    gradeMap: ReadonlyMap<OtmId, Map<number, Grade>>,
    childAttainmentIdsOfValidAttainments: ReadonlySet<OtmId>,
    childAttainmentIdsOfAllAttainments: ReadonlySet<OtmId>,
): readonly AttainmentNodeData[] {
    return attainments.map(attainment => (<AttainmentNodeData>{
        attainment,
        grade: !!attainment.gradeScaleId && !!attainment.gradeId
            ? gradeMap.get(attainment.gradeScaleId)?.get(attainment.gradeId) ?? null
            : null,
        hasValidParentAttainment: childAttainmentIdsOfValidAttainments.has(attainment.id),
        hasParentAttainment: childAttainmentIdsOfAllAttainments.has(attainment.id),
    }));
}
