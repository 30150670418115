import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    Code,
    ELMOCredit,
    ExternalAttainedStudy,
    ExternalIssuer,
    LocalizedString, OtmId,
    PriorStudies, Urn,
} from 'common-typescript/types';
import { find, has, values } from 'lodash';
import { forkJoin, from, Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthService } from 'sis-common/auth/auth-service';
import { LocaleService } from 'sis-common/l10n/locale.service';

import { CommonCodeService } from './../service/common-code.service';
import { ExternalIssuerEntityService } from './../service/external-issuer-entity.service';

@Injectable({ providedIn: 'root' })
export class ExternalAttainedStudyService {

    constructor(
        private http: HttpClient,
        private externalIssuerEntityService: ExternalIssuerEntityService,
        private authService: AuthService,
        private commonCodeService: CommonCodeService,
        private localeService: LocaleService,
    ) {}

    getExternalAttainedStudies(): Observable<ExternalAttainedStudy[]> {
        return this.http.get<ExternalAttainedStudy[]>(
            '/ori/api/external-attained-studies',
        );
    }

    getExternalAttainedStudyById(externalAttainedStudyId: OtmId, personId?: OtmId): Observable<ExternalAttainedStudy> {
        const id = personId ? personId : this.authService.personId();
        return this.http.get<ExternalAttainedStudy>(`/ori/api/external-attained-studies/${externalAttainedStudyId}?personId=${id}`);
    }

    getExternalAttainedStudyAsPriorStudies(externalAttainedStudy: ExternalAttainedStudy): Observable<Partial<PriorStudies>> {
        const externalIssuer$ = this.externalIssuerEntityService.getById(externalAttainedStudy.externalIssuerId).pipe(take(1));
        const codeBook$ = externalAttainedStudy.learningOpportunityInstance?.languageOfInstruction ?
            from(this.commonCodeService.getCode(`urn:code:language:${externalAttainedStudy.learningOpportunityInstance.languageOfInstruction}` as Urn)).pipe(take(1)) :
            of(null);
        return forkJoin([externalIssuer$, codeBook$]).pipe(
            map(([externalIssuer, code]) => this.mapExternalAttainedStudyPriorStudies(externalAttainedStudy, externalIssuer, code)));
    }

    private mapExternalAttainedStudyPriorStudies = (externalAttainedStudy: ExternalAttainedStudy, externalIssuer: ExternalIssuer, code: Code) => ({
        externalAttainedStudyId: externalAttainedStudy.id,
        organisation: this.mapElmoLocalizedStringValue(externalIssuer?.title),
        attainmentDate: externalAttainedStudy?.learningOpportunityInstance?.date ? externalAttainedStudy.learningOpportunityInstance.date : null,
        grade: externalAttainedStudy?.learningOpportunityInstance?.resultLabel ? externalAttainedStudy.learningOpportunityInstance.resultLabel : null,
        code: externalAttainedStudy?.learningOpportunitySpecification?.iscedCode ? externalAttainedStudy.learningOpportunitySpecification.iscedCode : null,
        gradeScale: this.mapElmoLocalizedStringValue(externalAttainedStudy?.learningOpportunityInstance?.gradingScheme),
        credits: this.mapElmoCredits(externalAttainedStudy?.learningOpportunityInstance?.credit),
        description: this.mapElmoLocalizedStringValue(externalAttainedStudy?.learningOpportunitySpecification?.description),
        name: this.mapElmoLocalizedStringValue(externalAttainedStudy?.learningOpportunitySpecification?.title),
        attainmentLanguage: code?.urn ? code.urn : null,
    } as Partial<PriorStudies>);

    private mapElmoLocalizedStringValue = (localizedString: LocalizedString) => {
        if (!localizedString) return null;
        let stringValue = localizedString[this.localeService.getCurrentLanguage()];
        if (!stringValue) {
            const primaryLanguages = this.localeService.getOfficialLanguages().filter(item => item !== this.localeService.getCurrentLanguage());
            stringValue = find(primaryLanguages, key => has(primaryLanguages, key) && localizedString[key] !== null && localizedString[key] !== undefined);
            if (!stringValue) stringValue = find(values(localizedString), val => val !== null && val !== undefined);
        }
        return stringValue;
    };

    private mapElmoCredits = (credits: ELMOCredit[]) => {
        if (!credits || credits.length === 0) return null;
        const concatenatedCredits = credits
            .map(credit => this.formatElmoCredit(credit))
            .filter(credit => !!credit)
            .join(', ');
        return concatenatedCredits === '' ? null : concatenatedCredits;
    };

    private formatElmoCredit = (elmoCredit: ELMOCredit) => {
        /* This if-statement cannot be simplified as the value can be 0 */
        if ((elmoCredit.value !== null || elmoCredit.value !== undefined) && elmoCredit.scheme) return `${elmoCredit.value} (${elmoCredit.scheme})`;
        if (elmoCredit.value !== null || elmoCredit.value !== undefined) return `${elmoCredit.value}`;
        return null;
    };
}

