import { ChangeDetectionStrategy, Component, inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Enrolment, EnrolmentCalculationConfigForPerson } from 'common-typescript/types';
import { EMPTY, Observable } from 'rxjs';

import { EnrolmentStudentService } from '../../../common/service/enrolment-student.service';

@Component({
    selector: 'app-enrolment-requirement-rules',
    templateUrl: './enrolment-requirement-rules.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnrolmentRequirementRulesComponent implements OnInit {

    @Input({ required: true }) enrolment: Enrolment;

    calculationConfig: Observable<EnrolmentCalculationConfigForPerson>;

    enrolmentStudentService = inject(EnrolmentStudentService);

    ngOnInit(): void {
        this.calculationConfig = this.enrolment?.courseUnitRealisationId ? this.enrolmentStudentService.getCalculationConfig(this.enrolment.courseUnitRealisationId) : EMPTY;
    }

}
