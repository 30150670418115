import _ from 'lodash';
import angular from 'angular';
import 'js-data';
export const degreeTitleModelModule = 'sis-components.model.degreeTitle';
(function () {
  degreeTitle.$inject = ["DS", "$http", "$log"];
  angular.module(degreeTitleModelModule, ['js-data']).factory('degreeTitleModel', degreeTitle).run(["degreeTitleModel", degreeTitleModel => {
    degreeTitleModel.lazyGetAll();
  }]);

  /**
   * @ngInject
   */
  function degreeTitle(DS, $http, $log) {
    const model = DS.defineResource({
      idAttribute: 'urn',
      name: 'degreeTitle',
      endpoint: '/kori/api/cached/codebooks/urn:code:degree-title',
      watchChanges: false
    });
    model.lazyGetAll = _.once(() => $http.get(model.endpoint).then(response => {
      model.inject(_.values(response.data));
      return model.getAll();
    }).catch(error => {
      $log.error(error);
    }));
    return model;
  }
})();