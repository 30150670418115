import {
    ChangeDetectionStrategy,
    Component, computed,
    inject,
    Input,
    signal,
    ViewEncapsulation,
    WritableSignal,
} from '@angular/core';
import { ValidatablePlan } from 'common-typescript';
import { PlanValidationResult } from 'common-typescript/src/plan/validation/planValidationResult';
import { EntityWithRule, ModuleRule, Rule } from 'common-typescript/types';
import _ from 'lodash';

import { RULE_SERVICE } from '../../../ajs-upgraded-modules';
import {
    PLAN_ACTIONS_SERVICE_INJECTION_TOKEN,
    PlanActionsService,
} from '../../../plan/plan-actions-service/plan-actions.service';
import { PlanRuleData } from '../../../service/plan-rule-data.service';
import { PlanRuleSelectionService } from '../../../service/plan-rule-selection.service';
import { PlanData, PlanStateObject } from '../../../service/plan-state.service';

@Component({
    selector: 'sis-plan-structure-module-rule',
    templateUrl: './plan-structure-module-rule.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanStructureModuleRuleComponent {

    _rule: WritableSignal<ModuleRule> = signal(undefined);
    @Input({ required: true }) set rule(rule: ModuleRule) {
        this._rule.set(rule);
    }

    _parentModule: WritableSignal<EntityWithRule> = signal(undefined);
    @Input({ required: true }) set parentModule(parentModule: EntityWithRule) {
        this._parentModule.set(parentModule);
    }

    _planValidationResult: WritableSignal<PlanValidationResult> = signal(undefined);
    @Input({ required: true }) set planValidationResult(planValidationResult: PlanValidationResult) {
        this._planValidationResult.set(planValidationResult);
    }

    _planStateObject: WritableSignal<PlanStateObject> = signal(undefined);
    @Input({ required: true }) set planStateObject(planStateObject: PlanStateObject) {
        this._planStateObject.set(planStateObject);
    }

    _planRuleData: WritableSignal<PlanRuleData> = signal(undefined);
    @Input({ required: true }) set planRuleData(planRuleData: PlanRuleData) {
        this._planRuleData.set(planRuleData);
    }

    _validatablePlan: WritableSignal<ValidatablePlan> = signal(undefined);
    @Input({ required: true }) set validatablePlan(validatablePlan: ValidatablePlan) {
        this._validatablePlan.set(validatablePlan);
    }

    _selectionUIState: WritableSignal<'ACTIVE' | 'DISABLED' | 'SELECTABLE'> = signal('ACTIVE');
    @Input({ required: true }) set selectionUIState(selectionUIState: 'ACTIVE' | 'DISABLED' | 'SELECTABLE') {
        this._selectionUIState.set(selectionUIState);
    }

    @Input({ required: true }) planData: PlanData;
    @Input({ required: true }) ruleDepthLevel: number;
    @Input({ required: true }) headingLevel: number;

    ruleService = inject(RULE_SERVICE);
    planRuleSelectionService: PlanRuleSelectionService = inject(PlanRuleSelectionService);
    planActionsService: PlanActionsService = inject(PLAN_ACTIONS_SERVICE_INJECTION_TOKEN);

    ruleValidationResult = computed(this.ruleValidationResultComputation());
    ruleModule = computed(this.ruleModuleComputation());
    parentRule = computed<Rule>(this.parentRuleComputation());
    isParentRuleSelectAllRule = computed<boolean>(this.isParentRuleSelectAllRuleComputation());

    isRuleSelectionDisabled = computed(this.isRuleSelectionDisabledComputation());

    handleToggle(): void {
        const active = this.ruleValidationResult().active;
        const module = this.ruleModule();
        const parentModule = this._parentModule();
        if (!active) {
            this.planActionsService?.selectModule(module, parentModule);
        } else {
            this.planActionsService?.removeModule(module, parentModule);
        }
    }

    ruleValidationResultComputation(): () => any {
        return () => _.get(this._planValidationResult().ruleValidationResults, [this._parentModule().id, this._rule().localId]);
    }

    ruleModuleComputation(): () => EntityWithRule {
        return () => this._planRuleData().ruleModuleVersionsByGroupId[this._rule().moduleGroupId];
    }

    parentRuleComputation(): () => Rule {
        return () => this.ruleService.getParentRule(this._parentModule().rule, this._rule());
    }

    isParentRuleSelectAllRuleComputation(): () => boolean {
        return () => this.ruleService.isSelectAllRule(this.parentRule());
    }

    isRuleSelectionDisabledComputation(): () => boolean {
        return () => this._selectionUIState() === 'DISABLED'
            || (this.isParentRuleSelectAllRule() && this.ruleValidationResult()?.active)
            || this.planRuleSelectionService.isModuleSelectionDisabled(this.ruleModule(), this._parentModule(), this._validatablePlan());
    }
}
