import multiInputValidationMessageTpl from './multiInputValidationMessage.tpl.html';
'use strict';
(function () {
  angular.module('sis-common.form').directive('multiInputValidationMessage', multiInputValidationMessage);
  function multiInputValidationMessage() {
    return {
      restrict: 'EA',
      require: '^^form',
      replace: true,
      template: multiInputValidationMessageTpl,
      scope: false,
      link: function (scope, elem, attr, form) {
        var targetProperty = attr.targetProperty;
        var messages = [];
        scope.$watch(function () {
          var state = '';
          messages = getMessages(form, targetProperty);
          _.forEach(messages, function (message) {
            state += message;
          });
          return state;
        }, function () {
          scope.valdrValidationMessages = messages;
        });
        function getMessages(form, targetProperty) {
          var messages = [];
          var valdrErrors = _.get(form, '$error.valdr');
          var matchingErrors = _.filter(valdrErrors, {
            $name: targetProperty
          });
          _.forEach(matchingErrors, function (matchingError) {
            var violations = _.get(matchingError, 'valdrViolations');
            _.forEach(violations, function (violation) {
              var message = _.get(violation, 'message');
              messages.push(message);
            });
          });
          return _.compact(messages);
        }
      }
    };
  }
})();