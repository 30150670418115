export const studyEventJSDataModelModule = 'sis-components.model.studyEvent';
'use strict';
(function () {
  studyEventJSDataModel.$inject = ["DS", "STUDY_EVENT_URL"];
  angular.module(studyEventJSDataModelModule, ['js-data']).factory('studyEventJSDataModel', studyEventJSDataModel).run(["studyEventJSDataModel", function (studyEventJSDataModel) {}]).constant('STUDY_EVENT_URL', {
    DEFAULT: '/kori/api/study-events',
    SEARCH: '/kori/api/study-events/search'
  });

  /**
   * @ngInject
   */
  function studyEventJSDataModel(DS, STUDY_EVENT_URL) {
    return DS.defineResource({
      idAttribute: 'id',
      name: 'studyEvent',
      endpoint: STUDY_EVENT_URL.DEFAULT,
      relations: {
        hasMany: {
          location: {
            localField: 'locations',
            localKeys: 'locationIds'
          }
        }
      },
      computed: {
        startDateTime: ['startTime', function (startTime) {
          return startTime ? moment(startTime).toDate() : startTime;
        }],
        endDateTime: ['startTime', 'duration', function (startTime, duration) {
          if (startTime && duration) {
            return moment(startTime).clone().add(moment.duration(duration).asMinutes(), 'minutes').toDate();
          }
          return startTime;
        }],
        recursUntilDate: ['recursUntil', function (recursUntil) {
          return recursUntil ? moment(recursUntil).toDate() : recursUntil;
        }]
      },
      beforeUpdate: function (resource, studyEvent, cb) {
        _.unset(studyEvent, ['events']);
        cb(null, studyEvent);
      }
    });
  }
})();