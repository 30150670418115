<div *ngIf="showHeader" class="row attainment-tree-header">
  <div class="col-8 name">
    <h4 translate>
      ATTAINMENTS.EDITABLE_ATTAINMENT_TREE.ATTAINMENT_CODE_AND_NAME
    </h4>
  </div>
  <div class="col-1">
    <h4 translate>
      ATTAINMENTS.EDITABLE_ATTAINMENT_TREE.CREDITS
    </h4>
  </div>
  <div class="col-1">
    <h4 translate>
      ATTAINMENTS.EDITABLE_ATTAINMENT_TREE.GRADE
    </h4>
  </div>
  <div class="col-2">
    <h4 translate>
      ATTAINMENTS.EDITABLE_ATTAINMENT_TREE.ATTAINMENT_DATE
    </h4>
  </div>
</div>
<div data-cy="editable-attainment-tree-row" class="row attainment-row">
  <div class="col-8 name">
    <div [ngClass]="{'subheading': !!attainmentWithNodes}">
      <button type="button"
              class="non-button"
              *ngIf="!!attainmentWithNodes"
              (click)="collapsed = !collapsed"
              [attr.aria-expanded]="!collapsed"
              [attr.aria-label]="(attainment|attainmentCode|async) + ' ' + (attainment|attainmentName|async|localizedString)">
        <sis-icon icon="chevron" [iconClass]="collapsed ? null : 'cw-90'"></sis-icon>
      </button>
      <span data-cy="tree-attainment-name">
        {{attainment|attainmentCode|async}} {{attainment|attainmentName|async|localizedString}}
      </span>
    </div>
    <div *ngIf="(attainment.additionalInfo | localizedStringExistingLocales)?.length" class="additional-info-block">
      <sis-button link="true"
                  (clicked)="hideAdditionalInfo = !hideAdditionalInfo"
                  [attr.aria-expanded]="!hideAdditionalInfo">
        <sis-icon icon="chevron" [iconClass]="hideAdditionalInfo ? null : 'cw-90'"></sis-icon>
        {{'ATTAINMENTS.EDITABLE_ATTAINMENT_TREE.ADDITIONAL_INFO' | translate}}
      </sis-button>
      <ng-container *ngIf="(attainment.additionalInfo | localizedStringMissingLocales)?.length">
        <sis-tooltip
          [tooltipText]="'ATTAINMENTS.EDITABLE_ATTAINMENT_TREE.MISSING_ADDITIONAL_INFO_TRANSLATIONS'
          | translate: {locales: (attainment.additionalInfo | localizedStringMissingLocales | localeNames | async | localizedStringArray)}"
          [appendToBody]="true"
          [tooltipBlockElement]="true">
          <span class="sis-tooltip-trigger-content">
            <sis-icon icon="alert-fill"></sis-icon>
          </span>
        </sis-tooltip>
      </ng-container>
      <span *ngIf="!hideAdditionalInfo" class="additional-info-text">
        {{attainment.additionalInfo|localizedString}}
      </span>
    </div>
  </div>
  <div class="col-1">
    {{attainment.credits | decimalNumber}}
    <span translate>CREDITS</span>
  </div>
  <div class="col-1">{{attainment.gradeId|grade:attainment.gradeScaleId|async}}</div>
  <div class="col-2">{{attainment.attainmentDate | localDateFormat}}</div>
</div>
<sis-attainment-tree-nodes
  *ngIf="attainmentWithNodes"
  [hidden]="collapsed"
  [ngClass]="{'collapsed': collapsed}"
  [attainmentNodes]="attainmentWithNodes.nodes"
  [attainmentsById]="attainmentsById"
  [selectedLocale]="selectedLocale">
</sis-attainment-tree-nodes>
