import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import {
    LocalDateTimeString,
    Organisation,
    OrganisationResultItem,
    OrganisationSearchResult,
    OrganisationSnapshotSearch, OrganisationSnapshotSearchQueryParams,
    OtmId, SearchParameters,
    SearchResult,
} from 'common-typescript/types';
import _ from 'lodash';
import { NGXLogger } from 'ngx-logger';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

import { searchRequestToQueryParams, simpleObjectToQueryParams } from '../search-ng/search-utils';

import { EntityService } from './entity.service';

const baseUrl = '/kori/api';
const orgPath = 'organisations';
const endpointUrls = {
    getOrganisations: [baseUrl, orgPath].join('/'),
    searchSnapshotsUnfiltered: [baseUrl, 'organisation-unfiltered-snapshot-search'].join('/'),
    searchSnapshots: [baseUrl, 'organisation-snapshot-search'].join('/'),
    searchForOrganisations: [baseUrl, orgPath, 'search'].join('/'),
    rootOrganisations: [baseUrl, orgPath, 'roots'].join('/'),
    rootOrganisationTkCodes: [baseUrl, orgPath, 'roots/tk-codes'].join('/'),
    organisationByTkCode: [baseUrl, orgPath, 'by-tk-code'].join('/'),
    snapshots: (orgId: OtmId) => [baseUrl, orgPath, orgId, 'snapshots'].join('/'),
    activateSnapshot: (orgId: OtmId) => [baseUrl, orgPath, orgId, 'activate'].join('/'),
    cancelSnapshot: (orgId: OtmId) => [baseUrl, orgPath, orgId, 'cancel'].join('/'),
    deleteSnapshot: (orgId: OtmId) => [baseUrl, orgPath, orgId, 'delete'].join('/'),
    deleteOrganisation: (orgId: OtmId) => [baseUrl, orgPath, orgId].join('/'),
};

@StaticMembers<DowngradedService>()
@Injectable({
    providedIn: 'root',
})
@NgEntityServiceConfig({
    baseUrl,
})
export class OrganisationEntityService extends EntityService<OrganisationsState> {

    constructor(private logger: NGXLogger) {
        super(OrganisationStore, OrganisationQuery);
    }

    static downgrade: ServiceDowngradeMappings = {
        dependencies: [],
        moduleName: 'sis-components.service.organisationEntityService',
        serviceName: 'organisationEntityService',
    };

    findAll(universityOrgId?: OtmId): Observable<Organisation[]> {
        const options = universityOrgId ? { params: { universityOrgId } } : {};
        return this.getHttp().get<Organisation[]>(endpointUrls.getOrganisations, options)
            .pipe(tap(organisations => this.store.upsertMany(organisations)));
    }

    /**
     * Publishes an organisation snapshot i.e., changes the documentState to ACTIVE.
     *
     * @param organisationId Id of the organisation.
     * @param snapshotDateTime DateTime of the snapshot to be published.
     */
    publishOrganisationSnapshot(organisationId: OtmId, snapshotDateTime: LocalDateTimeString): Observable<Organisation> {
        const options = { params: { snapshotDateTime: snapshotDateTime || '' } };
        return this.getHttp().put(endpointUrls.activateSnapshot(organisationId), {}, options) as Observable<Organisation>;
    }

    /**
     * Cancels an organisation snapshot i.e., changes the documentState to DRAFT.
     *
     * @param organisationId Id of the organisation.
     * @param snapshotDateTime DateTime of the snapshot to be cancelled.
     */
    cancelOrganisationSnapshot(organisationId: OtmId, snapshotDateTime: LocalDateTimeString): Observable<Organisation> {
        const options = { params: { snapshotDateTime: snapshotDateTime || '' } };
        return this.getHttp().put(endpointUrls.cancelSnapshot(organisationId), {}, options) as Observable<Organisation>;
    }

    /**
     * Deletes an organisation snapshot i.e., changes the documentState to DELETED.
     *
     * @param organisationId Id of the organisation.
     * @param snapshotDateTime DateTime of the snapshot to be deleted.
     */
    deleteOrganisationSnapshot(organisationId: OtmId, snapshotDateTime: LocalDateTimeString): Observable<Organisation> {
        const options = { params: { snapshotDateTime: snapshotDateTime || '' } };
        return this.getHttp().delete(endpointUrls.deleteSnapshot(organisationId), options) as Observable<Organisation>;
    }

    /**
     * Deletes all snapshots of an organisation.
     *
     * @param organisationId Id of the organisation.
     */
    deleteOrganisation(organisationId: OtmId): Observable<Organisation> {
        return this.getHttp().delete(endpointUrls.deleteOrganisation(organisationId)) as Observable<Organisation>;
    }

    search(fullTextQuery: string, universityOrgId: OtmId): Observable<SearchResult<Organisation>> {
        return this.getHttp().get(this.api, { params: { universityOrgId, name: fullTextQuery } })
            .pipe(
                map((result: Organisation[]) => ({
                    start: 0,
                    total: result.length,
                    searchResults: result,
                    limit: result.length,
                    truncated: false,
                    notifications: null,
                })),
                catchError((err) => {
                    this.logger.error('Failed to search organisations', this.api, fullTextQuery, err);
                    return throwError(() => err);
                }),
            );
    }

    searchSnapshots(fullTextQuery: string, universityOrgId: OtmId): Observable<SearchResult<Organisation>> {
        return this.organisationSnapShotNameSearch(endpointUrls.searchSnapshots, fullTextQuery, universityOrgId);
    }

    private organisationSnapShotNameSearch(endPoint: string, fullTextQuery: string, universityOrgId: OtmId) {
        return this.getHttp().get(endPoint, { params: { universityOrgId, name: fullTextQuery } })
            .pipe(
                map((result: Organisation[]) => ({
                    start: 0,
                    total: result.length,
                    searchResults: result,
                    limit: result.length,
                    truncated: false,
                    notifications: null,
                })),
                catchError((err) => {
                    this.logger.error('Failed to search organisation snapshots', endpointUrls.searchSnapshots, fullTextQuery, err);
                    return throwError(() => err);
                }),
            );
    }

    searchSnapshotsUnfiltered(ids: OtmId[]): Observable<SearchResult<OrganisationResultItem>> {
        return this.getHttp().get(endpointUrls.searchSnapshotsUnfiltered, { params: { id: ids } })
            .pipe(
                map((result: OrganisationResultItem[]) => ({
                    start: 0,
                    total: result.length,
                    searchResults: result,
                    limit: result.length,
                    truncated: false,
                    notifications: null,
                })),
                catchError((err) => {
                    this.logger.error('Failed to search organisation snapshots', endpointUrls.searchSnapshotsUnfiltered, ids, err);
                    return throwError(() => err);
                }),
            );
    }

    findUniversityRootOrganisation(universityOrgId: OtmId): Observable<Organisation> {
        return this.getById(universityOrgId);
    }

    getRootOrganisations(): Observable<Organisation[]> {
        return this.getHttp().get<Organisation[]>(endpointUrls.rootOrganisations)
            .pipe(
                catchError((err) => {
                    this.logger.error('Failed to fetch root organisations', endpointUrls.rootOrganisations, err);
                    return throwError(() => err);
                }),
            );
    }

    getRootOrganisationsAndStore(): Observable<Organisation[]> {
        return this.getHttp().get<Organisation[]>(endpointUrls.rootOrganisations)
            .pipe(tap(organisations => this.store.upsertMany(organisations)));
    }

    getOrganisationByTkCode(organisationTkCode: string): Observable<Organisation> {
        return this.getHttp().get<Organisation>([endpointUrls.organisationByTkCode, organisationTkCode].join('/'))
            .pipe(
                catchError((err) => {
                    this.logger.error('Failed to fetch root organisation tk codes', endpointUrls.rootOrganisationTkCodes, err);
                    return throwError(() => err);
                }),
            );
    }

    /**
     * Gets the snapshots of the specified organisation.
     *
     * @param organisationId The id of the organisation to look the snapshots for.
     * @return Snapshots of the organisation.
     */
    getSnapshotsOfOrganisation(organisationId: OtmId): Observable<Organisation[]> {
        const url = endpointUrls.snapshots(organisationId.toString());
        return (this.getHttp().get(url, { params: {} }) as Observable<Organisation[]>)
            .pipe(
                catchError((err) => {
                    this.logger.error('Failed to load organisation snapshots', url, organisationId, err);
                    return throwError(() => err);
                }),
            );
    }

    /**
     * Search for organisations/snapshots. Searchstring matches name, abbreviation and code fields. Search result
     * will contain the current version of matched organisation and the latest matched snapshot.
     *
     * @param searchParams Search parameters
     * @return Search results for the query
     */
    searchForOrganisations(searchParams: SearchParameters<OrganisationSnapshotSearchQueryParams>): Observable<OrganisationSearchResult> {
        return this.getHttp().get<OrganisationSearchResult>(endpointUrls.searchForOrganisations, { params: simpleObjectToQueryParams({ ...searchParams.options, ...searchParams.filters }) });
    }

    /**
     * Organisation is a root organisation if its universityOrgId is same as the id of itself.
     *
     * @param organisation The organisation to check if it is a root.
     * @return True if a root organisation.
     */
    isRootOrganisation(organisation: Organisation): boolean {
        return organisation && _.get(organisation, 'universityOrgId', 'unknown') === organisation.id;
    }

    toQueryParams(searchParams: Partial<OrganisationSnapshotSearch>): { [key: string]: string | string[] } {
        if (_.isEmpty(searchParams)) {
            return {};
        }

        return _.omitBy(
            {
                ...searchRequestToQueryParams(searchParams),
                universityOrgIds: searchParams.universityOrgIds,
                sort: searchParams.sorts,
            },
            _.isEmpty,
        );
    }

    /**
     * Get entity in cache
     */
    getEntity(id: OtmId): Organisation {
        return this.query.getEntity(id);
    }

    getOrganisationTkCode(organisation: Organisation): string {
        return organisation.cooperationNetworkIdentifiers?.organisationTkCode
            ? organisation.cooperationNetworkIdentifiers?.organisationTkCode
            : organisation.educationalInstitutionUrn?.split(':').at(-1);
    }
}

type OrganisationsState = EntityState<Organisation, OtmId>;

@StoreConfig({ name: 'organisations' })
class OrganisationStore extends EntityStore<OrganisationsState> {
}

class OrganisationQuery extends QueryEntity<OrganisationsState> {
    constructor(protected store: OrganisationStore) {
        super(store);
    }
}
