import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    SimpleChanges,
    ViewEncapsulation,
} from '@angular/core';
import { EnrolmentRight, ResolvedEnrolmentRightState } from 'common-typescript/types';

import { EnrolmentRightEntityService } from '../../../service/enrolment-right-entity.service';

/**
 * State badge for enrolment right
 */
@Component({
    selector: 'sis-enrolment-right-badges',
    templateUrl: './enrolment-right-badges.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnrolmentRightBadgesComponent implements OnChanges {
    @Input() enrolmentRight: EnrolmentRight;

    resolvedEnrolmentRightState: ResolvedEnrolmentRightState;

    constructor(private enrolmentRightEntityService: EnrolmentRightEntityService) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.enrolmentRight) {
            this.resolvedEnrolmentRightState = this.enrolmentRightEntityService.getResolvedEnrolmentRightState(this.enrolmentRight);
        }
    }

    get isSubstitutionRight(): boolean {
        return this.enrolmentRight?.type === 'SUBSTITUTION';
    }
}
