export const activityPeriodsServiceModule = 'sis-components.service.activityPeriodsService';
'use strict';
(function () {
  activityPeriodsService.$inject = ["$q", "curriculumPeriodModel", "commonStudyPeriodService", "universityService", "activityPeriodUri"];
  angular.module(activityPeriodsServiceModule, ['sis-components.model.curriculumPeriod', 'sis-components.service.studyPeriodService', 'sis-common.university']).factory('activityPeriodsService', activityPeriodsService).constant('activityPeriodUri', {
    findAllCurriculumPeriods: '/kori/api/curriculum-periods'
  });

  /**
   * @ngInject
   */
  function activityPeriodsService($q, curriculumPeriodModel, commonStudyPeriodService, universityService, activityPeriodUri) {
    var service = this;
    service.defaultCurriculumPeriodMeta = {
      startYear: '',
      endYear: ''
    };
    service.defaultStudyYearsMeta = {
      startYear: '',
      endYear: ''
    };
    service.defaultStudyTermsMeta = {
      startYear: '',
      endYear: ''
    };
    service.defaultStudyPeriods = [];
    service.defaultStudyYears = [];
    service.defaultStudyTerms = [];
    service.curriculumPeriodConfig = {
      BACKWARD_YEARS: 3,
      FORWARD_YEARS: 1
    };
    service.studyYearConfig = {
      MIN_YEAR: 1950,
      BACKWARD_YEARS: 5,
      FORWARD_YEARS: 2
    };
    service.studyTermConfig = {
      BACKWARD_YEARS: 1,
      FORWARD_YEARS: 1
    };
    function getUniversitySpecificCurriculumPeriods(universityOrgId) {
      return curriculumPeriodModel.findAll({
        universityOrgId: universityOrgId
      }, {
        endpoint: activityPeriodUri.findAllCurriculumPeriods
      }).then(function (result) {
        return result;
      });
    }

    // We dont want error modal if no study years are configured
    var bypassErrorInterceptorConfig = {
      bypassErrorInterceptor: true
    };
    function getStudyYears(universityOrgId) {
      service.defaultStudyYears = [];
      var startYear = moment().year() - service.studyYearConfig.BACKWARD_YEARS;
      var yearNum = service.studyYearConfig.BACKWARD_YEARS + service.studyYearConfig.FORWARD_YEARS + 1;
      return commonStudyPeriodService.getStudyYears(universityOrgId, startYear, yearNum, bypassErrorInterceptorConfig);
    }
    function getStudyTerms(universityOrgId) {
      service.defaultStudyTerms = [];
      var startYear = moment().year() - service.studyTermConfig.BACKWARD_YEARS;
      var yearNum = service.studyTermConfig.BACKWARD_YEARS + service.studyTermConfig.FORWARD_YEARS + 1;
      return commonStudyPeriodService.getStudyTerms(universityOrgId, startYear, yearNum, bypassErrorInterceptorConfig);
    }
    function getStudyTermRanges(universityOrgId) {
      if (_.isEmpty(universityOrgId)) {
        universityOrgId = [universityService.getCurrentUniversityOrgId()];
      }
      service.defaultStudyTerms = [];
      var startYear = service.studyYearConfig.MIN_YEAR;
      var yearNum = moment().year() + service.studyTermConfig.FORWARD_YEARS - service.studyYearConfig.MIN_YEAR;
      return $q.when(commonStudyPeriodService.getStudyTerms(universityOrgId, startYear, yearNum, bypassErrorInterceptorConfig).then(function (studyTerms) {
        return _.orderBy(studyTerms, ['studyYearStart', 'valid.startDate'], ['desc', 'asc']);
      }));
    }
    function getStudyPeriods(universityOrgId) {
      service.defaultStudyPeriods = [];
      var currentYear = moment().year();
      return commonStudyPeriodService.getStudyPeriods(universityOrgId, currentYear, 1, bypassErrorInterceptorConfig);
    }
    return {
      getStudyPeriods: getStudyPeriods,
      getStudyTerms: getStudyTerms,
      getStudyTermRanges: getStudyTermRanges,
      getStudyYears: getStudyYears,
      getUniversitySpecificCurriculumPeriods: getUniversitySpecificCurriculumPeriods
    };
  }
})();