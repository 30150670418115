<form class="reactive" (ngSubmit)="onSubmit()">
  <div class="modal-header">
    <h2 class="modal-title" [translate]="'CREATE_CUSTOM_MODULE_CONTENT_WORKFLOW_MODAL.TITLE'"></h2>
  </div>
  <div class="modal-body">
    <p [translate]="'CREATE_CUSTOM_MODULE_CONTENT_WORKFLOW_MODAL.DESCRIPTION'" [translateParams]="{ moduleName: module.name | localizedString}"></p>
    <sis-module-content-workflow-structure
      [parentModule]="module"
      [selectedCourseUnits]="selectedCourseUnits"
      [selectedModules]="selectedModules"
      [selectedCustomCourseUnitAttainments]="selectedCustomCourseUnitAttainments"
      [selectedCustomModuleAttainments]="selectedCustomModuleAttainments"
      [customStudyDrafts]="customStudyDrafts"
      [attainments]="attainments">
    </sis-module-content-workflow-structure>
    <sis-custom-study-draft-summary
      *ngFor="let customStudyDraft of customStudyDrafts "
      [customStudyDraft]="customStudyDraft">
    </sis-custom-study-draft-summary>
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <sis-text-field
            [control]="applicationRationale"
            [label]="'CREATE_CUSTOM_MODULE_CONTENT_WORKFLOW_MODAL.APPLICATION_RATIONALE_LABEL' | translate"
            [textarea]="true" [helpBlock]="'CREATE_CUSTOM_MODULE_CONTENT_WORKFLOW_MODAL.APPLICATION_RATIONALE_HELP_BLOCK_TEXT'"></sis-text-field>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group checkbox-group">
          <label class="control-label" translate="STUDENT_APPLICATIONS.CANCELLING_DISABLED.LABEL"></label>
          <sis-radio-button [control]="cancellingDisabled" [value]="false" label="STUDENT_APPLICATIONS.CANCELLING_DISABLED.FALSE" name="cancellingDisabled"></sis-radio-button>
          <sis-radio-button [control]="cancellingDisabled" [value]="true" label="STUDENT_APPLICATIONS.CANCELLING_DISABLED.TRUE" name="cancellingDisabled"></sis-radio-button>
          <p class="help-block" translate="STUDENT_APPLICATIONS.CANCELLING_DISABLED.GUIDANCE"></p>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <sis-button [dataCy]="'modal-close'" [hollow]="true" (clicked)="onCancel()" [label]="'BUTTON.CANCEL' | translate"></sis-button>
    <sis-button [dataCy]="'modal-submit'" data-cy type="submit" [label]="'CREATE_CUSTOM_MODULE_CONTENT_WORKFLOW_MODAL.BUTTON_SUBMIT' | translate"></sis-button>
  </div>
</form>
