<ng-container *transloco="let t">
  <div class="container sis-pt-md">
    <div class="row">
      <div class="col-12">
        <sis-page-title [title]="t('RIPA_ENROLMENT_INFORMATION.TITLE')"/>
        <p class="sis-mt-md">{{t('RIPA_ENROLMENT_INFORMATION.DESCRIPTION')}}</p>
        <p><transloco key="RIPA_ENROLMENT_INFORMATION.DETAILS"></transloco></p>
        <div class="col-12">
          <h2>{{t('RIPA_ENROLMENT_INFORMATION.PERSONAL_INFORMATION.TITLE')}}</h2>
          <p>{{t('RIPA_ENROLMENT_INFORMATION.PERSONAL_INFORMATION.DESCRIPTION')}}</p>
          <ul>
            <li>{{t('PROFILE.OPPIJANUMERO')}}</li>
            <li>{{t('PROFILE.PERSONAL_IDENTITY_CODE')}}</li>
            <li>{{t('PROFILE.BIRTH_DATE')}}</li>
            <li>{{t('PROFILE.FIRST_NAMES')}}</li>
            <li>{{t('PROFILE.LAST_NAME')}}</li>
            <li>{{t('RIPA_ENROLMENT_INFORMATION.PERSONAL_INFORMATION.NAME')}}</li>
            <li>{{t('RIPA_ENROLMENT_INFORMATION.PERSONAL_INFORMATION.EPPN')}}</li>
            <li>{{t('PROFILE.STUDENT_NUMBER')}}</li>
            <li>{{t('RIPA_ENROLMENT_INFORMATION.PERSONAL_INFORMATION.NATIONALITIES')}}</li>
            <li>{{t('PROFILE.GENDER')}}</li>
            <li>{{t('PROFILE.MOTHER_TONGUE')}}</li>
            <li>{{t('PROFILE.HOME_MUNICIPALITY')}}</li>
            <li>{{t('RIPA_ENROLMENT_INFORMATION.PERSONAL_INFORMATION.HOME_ADDRESS')}}</li>
            <li>{{t('RIPA_ENROLMENT_INFORMATION.PERSONAL_INFORMATION.EMAIL_ADDRESS')}}</li>
            <li>{{t('PROFILE.PHONE_NUMBER')}}</li>
            <li>{{t('RIPA_ENROLMENT_INFORMATION.PERSONAL_INFORMATION.POSSIBLE_DATA_SAFETY_NON_DISCLOSURE')}}</li>
          </ul>
        </div>
        <div class="col-12">
          <h2>{{t('RIPA_ENROLMENT_INFORMATION.STUDY_RIGHT_INFORMATION.TITLE')}}</h2>
          <p>{{t('RIPA_ENROLMENT_INFORMATION.STUDY_RIGHT_INFORMATION.DESCRIPTION')}}</p>
          <ul>
            <li>{{t('RIPA_ENROLMENT_INFORMATION.STUDY_RIGHT_INFORMATION.STUDY_RIGHT_IDENTIFIER')}}</li>
            <li>{{t('RIPA_ENROLMENT_INFORMATION.STUDY_RIGHT_INFORMATION.STUDY_RIGHT_STATE')}}</li>
            <li>{{t('RIPA_ENROLMENT_INFORMATION.STUDY_RIGHT_INFORMATION.STUDY_RIGHT_VALIDITY_PERIOD')}}</li>
            <li>{{t('RIPA_ENROLMENT_INFORMATION.STUDY_RIGHT_INFORMATION.STUDY_RIGHT_ORGANISATION')}}</li>
            <li>{{t('RIPA_ENROLMENT_INFORMATION.STUDY_RIGHT_INFORMATION.STUDY_RIGHT_TYPE')}}</li>
          </ul>
        </div>
        <div class="col-12">
          <h2>{{t('RIPA_ENROLMENT_INFORMATION.UNIVERSITY_PRIVACY_STATEMENT.TITLE')}}</h2>
          <p>{{t('RIPA_ENROLMENT_INFORMATION.UNIVERSITY_PRIVACY_STATEMENT.DESCRIPTION') }}
            <fudis-link [externalLink]="'https://wiki.eduuni.fi/display/Ristiinopiskelupalvelu/Korkeakoulujen+tietosuojaselosteet'" [title]="t('RIPA_ENROLMENT_INFORMATION.UNIVERSITY_PRIVACY_STATEMENT.LINK')"></fudis-link>
          </p>
        </div>
      </div>
      <div class="col-12 back-button-container">
        <sis-button
          size="lg"
          variant="primary"
          (clicked)="returnToFrontPage()">
          {{ t('RIPA_ENROLMENT_INFORMATION.BACK_TO_FRONT_PAGE') }}
        </sis-button>
      </div>
    </div>
  </div>
</ng-container>

