<sis-basic-grouping-panel [heading]="heading">
  <div class="form-group-mimic">
    <h4 translate="CUSTOM_STUDY_DRAFT_SUMMARY.LOCATION"></h4>
    <span>{{ customStudyDraft.location }}</span>
  </div>

  <div class="form-group-mimic">
    <h4 translate="CUSTOM_STUDY_DRAFT_SUMMARY.DESCRIPTION"></h4>
    <span class="text-area-static">{{ customStudyDraft.description }}</span>
  </div>
</sis-basic-grouping-panel>
