import { Pipe, PipeTransform } from '@angular/core';
import { OtmId } from 'common-typescript/types';
import { Observable } from 'rxjs';

import { CurriculumPeriodNameService } from '../service/curriculum-period-name.service';

@Pipe({ name: 'curriculumPeriodName' })
export class CurriculumPeriodNamePipe implements PipeTransform {

    constructor(private curriculumPeriodNameService: CurriculumPeriodNameService) { }

    transform(curriculumPeriodIds: OtmId | OtmId[], displayUniversityAbbreviation: boolean = false): Observable<string> {
        return this.curriculumPeriodNameService.getCurriculumPeriodNameByIds(curriculumPeriodIds, displayUniversityAbbreviation);
    }

}
