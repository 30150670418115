import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Recommendation, WorkflowType } from 'common-typescript/types';

import { RECOMMENDATION_STATE } from '../../model/student-workflow-constants';

@Component({
    selector: 'sis-recommendation',
    templateUrl: './recommendation.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class RecommendationComponent {
    readonly recommendationState = RECOMMENDATION_STATE;

    @Input() formalRecommendation: Recommendation;
    @Input() contentRecommendation: Recommendation;
    @Input() approvalRequestType: WorkflowType;
}
