import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { LocalizedString } from 'common-typescript/types';
import { LocaleService } from 'sis-common/l10n/locale.service';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'div[sis-additional-info-details]',
    templateUrl: './additional-info-details.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class AdditionalInfoDetailsComponent implements OnInit {

    constructor(private localeService: LocaleService) {}

    @Input() data: LocalizedString;
    selectedLocale: string;
    supportedLocales: string[] = [];

    ngOnInit(): void {
        this.selectedLocale = this.localeService.getCurrentLanguage();
        this.supportedLocales = this.localeService.getOfficialLanguages();
    }

    setLocale(lang: string) {
        this.selectedLocale = lang;
    }

}
