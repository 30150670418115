'use strict';

(function () {
  initOfficialLanguageModel.$inject = ["officialLanguageModel"];
  officialLanguageModel.$inject = ["DS", "$http", "$log"];
  angular.module('sis-components.model.officialLanguage', ['js-data']).factory('officialLanguageModel', officialLanguageModel).run(initOfficialLanguageModel);

  /**
   * @ngInject
   */
  function officialLanguageModel(DS, $http, $log) {
    var model = DS.defineResource({
      idAttribute: 'urn',
      name: 'officialLanguage',
      endpoint: '/kori/api/cached/codebooks/urn:code:official-language',
      watchChanges: false
    });
    model.lazyGetAll = _.once(function () {
      return $http.get(model.endpoint).then(function (response) {
        model.inject(_.values(response.data));
        return model.getAll();
      }).catch(function (error) {
        $log.error(error);
      });
    });
    return model;
  }

  /**
   * @ngInject
   */
  function initOfficialLanguageModel(officialLanguageModel) {
    officialLanguageModel.lazyGetAll();
  }
})();