<div role="checkbox"
     [attr.id]="id"
     [attr.aria-checked]="ariaChecked"
     [attr.disabled]="disabled"
     [attr.aria-disabled]="disabled"
     (keydown.space)="$event.preventDefault()"
     (keyup.enter)="toggle()"
     (keyup.space)="toggle()"
     (click)="toggle()"
     tabindex="0"
     [attr.data-cy]="id">
  <label [for]="id" [class]="labelClasses" [class.disabled]="disabled">
    <span [class]="'icon-checkbox ' + controlClasses" [class.disabled]="disabled">
      <sis-icon *ngIf="checked" icon="check-small"></sis-icon>
      <sis-icon *ngIf="!checked && indeterminate" icon="check-indeterminate-small"></sis-icon>
    </span>
    <span class="labels">
      <span><span *ngIf="code">{{code}}, </span>{{label | localizedString}}</span>
      <span *ngIf="secondaryLabel" class="secondary-label">
        {{secondaryLabel | localizedString}}
      </span>
    </span>
  </label>
</div>
