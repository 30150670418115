import { Pipe, PipeTransform } from '@angular/core';
import { LocalizedString } from 'common-typescript/types';

import { LocaleService } from './locale.service';

@Pipe({ name: 'localizedStringWithoutFallback' })
export class LocalizedStringWithoutFallbackPipe implements PipeTransform {

    constructor(private localeService: LocaleService) { }

    transform(value: LocalizedString, localeOverride?: string): string {
        return value?.[localeOverride ?? this.localeService.getCurrentLanguage()];
    }
}
