import { Component, Inject, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { AssessmentItem, CompletionMethod, CourseUnit, CourseUnitRealisation, OtmId } from 'common-typescript/types';
import _ from 'lodash';
import { map, take } from 'rxjs/operators';
import { DEFAULT_PROMISE_HANDLER } from 'sis-common/ajs-upgraded-modules';
import {
    ComponentDowngradeMappings,
    DowngradedComponent,
    StaticMembers,
} from 'sis-common/types/angular-hybrid';

import {
    COURSE_UNIT_REALISATION_SERVICE,
} from '../../ajs-upgraded-modules';
import { AppErrorHandler } from '../../error-handler/app-error-handler';
import { AssessmentItemEntityService } from '../../service/assessment-item-entity.service';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-completion-methods',
    templateUrl: './completion-methods.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class CompletionMethodsComponent implements OnChanges {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.courseUnit.completionMethods',
        directiveName: 'sisCompletionMethods',
    };

    hasValue: boolean;
    completionMethods: CompletionMethod[];
    assessmentItemsById: { [id: string]: AssessmentItem };
    orderedAssessmentItemIds: OtmId[];
    courseUnitRealisationsById: { [id: string]: CourseUnitRealisation };
    highlightedCompletionMethodIndex = 0;
    selectedCompletionMethodIndex = 0;
    courseUnitRealisationsReady = false;
    highlightedCompletionMethod: CompletionMethod;
    completionMethodAssessmentItems: AssessmentItem[];
    isCourseUnitRealisationExpandedById: { [id: string]: boolean };
    isAssessmentItemExpandedById: { [id: string]: boolean };

    @Input() courseUnit: CourseUnit;
    @Input() language: string;

    constructor(
        private appErrorHandler: AppErrorHandler,
        private assessmentItemEntityService: AssessmentItemEntityService,
        @Inject(COURSE_UNIT_REALISATION_SERVICE) private courseUnitRealisationService: any,
        @Inject(DEFAULT_PROMISE_HANDLER) private defaultPromiseHandler: any,
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.courseUnit) {
            this.courseUnitRealisationsReady = false;
            this.assessmentItemsById = null;
            this.completionMethods = this.courseUnit.completionMethods;
            this.highlightCompletionMethod(0);

            this.getAssessmentItemsAndCourseUnitRealisations();
        }
    }

    highlightCompletionMethod(index: number) {
        this.highlightedCompletionMethodIndex = index;
        this.updateHighlightedCompletionMethod(this.highlightedCompletionMethodIndex);
    }

    selectCompletionMethod(index: number) {
        this.selectedCompletionMethodIndex = index;
        this.highlightedCompletionMethodIndex = index;
        this.updateHighlightedCompletionMethod(this.highlightedCompletionMethodIndex);
    }

    getAssessmentItemsAndCourseUnitRealisations() {
        this.courseUnitRealisationsReady = false;
        const assessmentItemIdsFromCompletionMethods = _.flatMap(this.completionMethods, completionMethod =>
            completionMethod.assessmentItemIds);
        this.orderedAssessmentItemIds = _.compact(_.union(this.courseUnit.assessmentItemOrder, assessmentItemIdsFromCompletionMethods));
        this.assessmentItemEntityService.getByIds(this.orderedAssessmentItemIds).pipe(
            take(1),
            map((assessmentItems) => {
                this.assessmentItemsById = _.keyBy(assessmentItems, 'id');
                this.updateHighlightedCompletionMethod(this.highlightedCompletionMethodIndex);
            }),
            this.appErrorHandler.defaultErrorHandler())
            .subscribe();
        this.getCourseUnitRealisationsForAssessmentItems(this.orderedAssessmentItemIds);
    }

    getCourseUnitRealisationsForAssessmentItems(assessmentItemIds: OtmId[]) {
        this.courseUnitRealisationsById = {};
        const promises = _.map(assessmentItemIds, (assessmentItemId) => this.courseUnitRealisationService.findByAssessmentItemId(assessmentItemId).then((courseUnitRealisations: CourseUnitRealisation[]) => {
            _.forEach(courseUnitRealisations, (courseUnitRealisation) => {
                this.courseUnitRealisationsById[courseUnitRealisation.id] = courseUnitRealisation;
            });
        }));
        Promise.all(promises).then(() => {
            this.courseUnitRealisationsReady = true;
        }).catch(this.defaultPromiseHandler.loggingRejectedPromiseHandler);
    }

    completionMethodContainsAssessmentItemId(completionMethodIndex: number, assessmentItemId: OtmId) {
        return _.includes(this.completionMethods[completionMethodIndex].assessmentItemIds, assessmentItemId);
    }

    updateCompletionMethodAssessmentItems(completionMethod: CompletionMethod) {
        if (!this.assessmentItemsById) {
            return;
        }
        const completionMethodAssessmentItems: AssessmentItem[] = [];
        _.forEach(this.orderedAssessmentItemIds, (assessmentItemId) => {
            if (_.includes(completionMethod.assessmentItemIds, assessmentItemId)) {
                completionMethodAssessmentItems.push(this.assessmentItemsById[assessmentItemId]);
            }
        });
        this.completionMethodAssessmentItems = completionMethodAssessmentItems;
        const openAssessmentItemMap: { [id: string]: boolean } = {};
        const openCourseUnitRealisationMap: { [id: string]: boolean } = {};
        _.forEach(this.completionMethodAssessmentItems, (assessmentItem) => {
            openAssessmentItemMap[assessmentItem.id] = true;
            const courseUnitRealisations = this.getCourseUnitRealisationsForAssessmentItem(assessmentItem);
            _.forEach(courseUnitRealisations, (courseUnitRealisation) => {
                openCourseUnitRealisationMap[courseUnitRealisation.id] = false;
            });
        });
        this.isAssessmentItemExpandedById = openAssessmentItemMap;
        this.isCourseUnitRealisationExpandedById = openCourseUnitRealisationMap;
    }

    isAssessmentItemSelected(index: number) {
        return false;
    }

    getCourseUnitRealisationsForAssessmentItem(assessmentItem: AssessmentItem) {
        return _.chain(_.values(this.courseUnitRealisationsById))
            .filter(cur => _.includes(cur.assessmentItemIds, assessmentItem.id))
            .value();
    }

    navigateToCurSection(courseUnitRealisation: CourseUnitRealisation) {
        const relevantAssessmentItem = _.find(this.completionMethodAssessmentItems, assessmentItem =>
            _.includes(courseUnitRealisation.assessmentItemIds, assessmentItem.id));
        if (relevantAssessmentItem) {
            this.isAssessmentItemExpandedById[relevantAssessmentItem.id] = true;
        }
        this.isCourseUnitRealisationExpandedById[courseUnitRealisation.id] = true;
        setTimeout(() => {
            const element = document.querySelector(`#${courseUnitRealisation.id}`);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        });
    }

    updateHighlightedCompletionMethod(index: number) {
        this.highlightedCompletionMethod = this.completionMethods[index];
        this.updateCompletionMethodAssessmentItems(this.completionMethods[index]);
    }

    toggleAssessmentItem(collapsed: boolean, assessmentItemId: OtmId) {
        this.isAssessmentItemExpandedById[assessmentItemId] = !collapsed;
    }

    toggleCourseUnitRealisation(collapsed: boolean, courseUnitRealisationId: OtmId) {
        this.isCourseUnitRealisationExpandedById[courseUnitRealisationId] = !collapsed;
    }

}
