import Chart from 'chart.js';
import { PopoverComponent } from 'sis-components/popover/popover.component.ts';
import { planValidationResultHelperModule } from 'sis-components/service/planValidationResultHelper.service';
import { CommonGradeAverageService } from 'sis-components/service/common-grade-average.service.ts';
import { planServiceModule } from '../../common/service/planService';
import { StudyProgressGraphDropdownComponent } from './study-progress-graph-dropdown/study-progress-graph-dropdown.component.ts';
'use strict';
angular.module('student.frontpage.studyProgressGraph', [CommonGradeAverageService.downgrade.moduleName, 'sis-components.service.gradeScale', 'student.common.utils.uiStateStore', 'student.common.service.educationsWithPlans', 'student.common.service.moduleService', 'student.common.context.uiContext', 'sis-components.number.decimalNumberFilter', planServiceModule, StudyProgressGraphDropdownComponent.downgrade.moduleName, PopoverComponent.downgrade.moduleName, planValidationResultHelperModule]).config(function () {
  Chart.plugins.register({
    afterUpdate: function (chart) {
      if (chart.config.options.elements.arc.roundedCornersFor !== undefined) {
        chart.config.options.elements.arc.roundedCornersFor.forEach(function (roundedCornersEntity) {
          var arc = chart.getDatasetMeta(0).data[roundedCornersEntity];
          var finalBackgroundColor = "";
          // change the last circle's background to transparent for the end of the chart.
          // we will handle it later with a gradient fill
          if (roundedCornersEntity === chart.config.options.elements.arc.roundedCornersFor.length - 2) {
            finalBackgroundColor = "rgba(255,255,255,0.00)";
          } else {
            finalBackgroundColor = arc._model.backgroundColor;
          }
          arc.round = {
            x: (chart.chartArea.left + chart.chartArea.right) / 2,
            y: (chart.chartArea.top + chart.chartArea.bottom) / 2,
            radius: (chart.outerRadius + chart.innerRadius) / 2,
            thickness: (chart.outerRadius - chart.innerRadius) / 2 - 1,
            backgroundColor: finalBackgroundColor
          };
        });
      }
    },
    afterDraw: function (chart) {
      if (chart.config.options.elements.arc.roundedCornersFor !== undefined) {
        var ctx = chart.chart.ctx;
        chart.config.options.elements.arc.roundedCornersFor.forEach(function (roundedCornersEntity) {
          var arc = chart.getDatasetMeta(0).data[roundedCornersEntity];
          var startAngle = Math.PI / 2 - arc._view.startAngle;
          var endAngle = Math.PI / 2 - arc._view.endAngle;
          ctx.save();
          ctx.translate(arc.round.x, arc.round.y);
          // gradient fill for the last circle at the end of the chart.
          if (roundedCornersEntity === chart.config.options.elements.arc.roundedCornersFor.length - 1) {
            var gradientCoordinates = chart.config.data.datasets[0].gradientCoordinates;
            var gradient = ctx.createLinearGradient(gradientCoordinates.x1, gradientCoordinates.y1, gradientCoordinates.x2, gradientCoordinates.y2);
            gradient.addColorStop(0, chart.config.data.datasets[0].backgroundColor[1]);
            gradient.addColorStop(0.315, chart.config.data.datasets[0].backgroundColor[1]);
            gradient.addColorStop(0.316, "rgba(255,255,255,0.00)");
            gradient.addColorStop(1, "rgba(255,255,255,0.00)");
            ctx.fillStyle = gradient;
          } else {
            ctx.fillStyle = arc.round.backgroundColor;
          }
          ctx.beginPath();
          ctx.arc(arc.round.radius * Math.sin(startAngle), arc.round.radius * Math.cos(startAngle), arc.round.thickness, 0, 2 * Math.PI);
          ctx.arc(arc.round.radius * Math.sin(endAngle), arc.round.radius * Math.cos(endAngle), arc.round.thickness, 0, 2 * Math.PI);
          ctx.closePath();
          ctx.fill();
          ctx.restore();
        });
      }
    }
  });
});