<div *ngIf="customCourseUnitAttainment"
     class="courseunit {{ccuaStateObject?.colorCategoryCssClass}}">
    <div class="courseunit-top-row">
        <div class="credits-box">
            <sis-tooltip [tooltipText]="(visibleCredits | creditRangeLong)" [appendToBody]="true">
                <span class="credit-amount sis-tooltip-trigger-content">{{visibleCredits | creditRange: 'SYMBOLS' : true }}</span>
            </sis-tooltip>
            <span class="credit-abbreviation" translate="SIS_COMPONENTS.CREDIT_RANGE.CREDITS_ABBR"></span>
        </div><!--
     --><div class="grade-box">
        <sis-icon icon="achievement"></sis-icon>
        <span class="grade-text"> {{ ccuaStateObject?.grade.abbreviation | localizedString }} </span>
    </div><!--
     --><div class="title-box">
        <span class="courseunit-code">
            <sis-button link="true"
                        (clicked)="openCustomCourseUnitAttainmentInfoModal()"
                        [ariaLabel]="customCourseUnitAttainment.code + ' ' + (customCourseUnitAttainment.name | localizedString) + ' ' + ('ARIA_LABEL.OPEN_INFO' | translate)">
                <sis-icon icon="brochure"></sis-icon>{{customCourseUnitAttainment.code}}
            </sis-button>
        </span>
        <span class="courseunit-title">
              <sis-tooltip [tooltipText]="(customCourseUnitAttainment.name | localizedString)" [appendToBody]="true">
                <span class="sis-tooltip-trigger-content">{{ customCourseUnitAttainment.name | localizedString }}</span>
              </sis-tooltip>
            </span>
    </div><!--
   --><ng-content select="[custom-course-unit-attainment-action]"></ng-content>
    </div>
    <div class="cu-notification-bar">
        <sis-tooltip
                *ngIf="ccuaStateObject?.invalidSelection && !ccuaStateObject?.hasModuleContentApproval"
                [tooltipText]="'PLAN.COURSE_UNIT.INVALID_SELECTION' | translate">
            <div class="cu-notification sis-tooltip-trigger-content">
                <span class="cu-notification-header"><sis-icon icon="alert-fill"></sis-icon></span><!--
           --><span class="cu-notification-content"><!--
                  --><span translate="SIS_COMPONENTS.PLAN.COURSE_UNIT.INVALID_SELECTION"></span>
              </span>
            </div>
        </sis-tooltip>
        <sis-tooltip *ngIf="ccuaStateObject?.isAttainmentAboutToExpire"
                     [tooltipText]="('PLAN.COURSE_UNIT.EXPIRATION_IMMINENT' | translate) + ' ' + (ccuaStateObject?.attainmentExpiryDate | localDateFormat)">
            <div class="cu-notification sis-tooltip-trigger-content">
                <span class="cu-notification-header"><sis-icon icon="alert-fill"></sis-icon></span><!--
             --><span class="cu-notification-content">
                    <span translate="PLAN.COURSE_UNIT.EXPIRATION_IMMINENT"></span>
                {{ccuaStateObject?.attainmentExpiryDate | localDateFormat}}.
                </span>
            </div>
        </sis-tooltip>
        <sis-tooltip *ngIf="ccuaStateObject?.isInvalidSelection && ccuaStateObject?.hasModuleContentApproval"
                     [tooltipText]="('PLAN.INVALID_SELECTIONS.NOTIFICATION' | translate)">
            <div class="cu-notification sis-tooltip-trigger-content">
                <span class="cu-notification-header"><sis-icon icon="alert-fill"></sis-icon></span><!--
             --><span class="cu-notification-content">
                    <span translate>PLAN.INVALID_SELECTIONS.NOTIFICATION</span>
                </span>
            </div>
        </sis-tooltip>
        <sis-tooltip *ngIf="ccuaStateObject?.isInvalidAccordingToModuleContentApproval"
                     [tooltipText]="('PLAN.INVALID_ACCORDING_TO_MODULE_CONTENT_APPROVAL.NOTIFICATION' | translate)">
            <div class="cu-notification sis-tooltip-trigger-content">
                <span class="cu-notification-header"><sis-icon icon="alert-fill"></sis-icon></span><!--
             --><span class="cu-notification-content">
                    <span translate>PLAN.INVALID_ACCORDING_TO_MODULE_CONTENT_APPROVAL.NOTIFICATION</span>
                </span>
            </div>
        </sis-tooltip>
        <div ng-transclude="notifications">
        </div>
    </div>
</div>
<div *ngIf="!customCourseUnitAttainment" class="courseunit attainment-data-not-found">
    <div class="courseunit-top-row">
        <div class="credits-box">
            <sis-icon icon="alert-fill"></sis-icon>
        </div><!--
        --><div class="grade-box">
        <sis-icon icon="achievement"></sis-icon>
    </div><!--
        --><div class="title-box">
            <span class="courseunit-code">
                <span class="no-icon">
                </span>
            </span>
        <span class="courseunit-title">
            <span translate="SIS_COMPONENTS.CUSTOM_COURSE_UNIT_ATTAINMENT_BOX.ATTAINMENT_DATA_NOT_FOUND"></span>
        </span>
    </div><!--
   --><ng-content select="[unknown-custom-course-unit-attainment-action]"></ng-content>
    </div>
</div>
