import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { ModalService } from 'sis-common/modal/modal.service';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

import { SelectEmrexAccessPointModalComponent } from './select-emrex-access-point-modal.component';

@StaticMembers<DowngradedService>()
@Injectable({
    providedIn: 'root',
})
export class SelectEmrexAccessPointModalService {

    static downgrade: ServiceDowngradeMappings = {
        moduleName: 'student.plan.selectEmrexAccessPointModalService',
        serviceName: 'selectEmrexAccessPointModalServiceNg',
    };

    constructor(private modalService: ModalService) { }

    open(): Observable<void> {
        return from(this.modalService.open(
            SelectEmrexAccessPointModalComponent,
            {
                closeWithOutsideClick: true,
                size: 'md',
            }).result);
    }
}
