import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { AdmissionTarget, OtmId } from 'common-typescript/types';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

import { EntityService } from './entity.service';

const CONFIG = {
    ENDPOINTS: {
        backend: '/kori/api',
    },
};

@StaticMembers<DowngradedService>()
@Injectable({ providedIn: 'root' })
@NgEntityServiceConfig({
    baseUrl: CONFIG.ENDPOINTS.backend,
    resourceName: 'admission-targets',
})
export class AdmissionTargetEntityService extends EntityService<AdmissionTargetState> {

    static downgrade: ServiceDowngradeMappings = {
        dependencies: [],
        moduleName: 'sis-components.service.admissionTargetEntityService',
        serviceName: 'admissionTargetEntityService',
    };

    constructor() {
        super(AdmissionTargetStore, AdmissionTargetQuery);
    }

    getByUniversityOrgId(universityOrgId: OtmId, bypassStore: boolean = true): Observable<AdmissionTarget[]> {
        if (!bypassStore) {
            return this.selectAll({ filterBy: admissionTarget => admissionTarget.universityOrgId === universityOrgId });
        }
        return this.get({ params: { universityOrgId } })
            .pipe(
                switchMap(() => this.selectAll({ filterBy: admissionTarget => admissionTarget.universityOrgId === universityOrgId })),
            );
    }
}

type AdmissionTargetState = EntityState<AdmissionTarget, OtmId>;

@StoreConfig({ name: 'admission-targets' })
class AdmissionTargetStore extends EntityStore<AdmissionTargetState> {}

class AdmissionTargetQuery extends QueryEntity<AdmissionTargetState> {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(store: AdmissionTargetStore) {
        super(store);
    }
}
