import { Injectable } from '@angular/core';
import { TranslationMarkupRendererFactory } from 'ngx-transloco-markup';

import { HtmlElementTranspiler } from '../common/html-element-transpiler';

export const SUP_START = new (class SupStart {})();
export const SUP_END = new (class SupEnd {})();

@Injectable()
export class SupTranspiler extends HtmlElementTranspiler {
    constructor(private readonly rendererFactory: TranslationMarkupRendererFactory) {
        super({
            rendererFactory,
            start: {
                search: '<sup>',
                token: SUP_START,
            },
            end: {
                search: '</sup>',
                token: SUP_END,
            },
            elementTag: 'sup',
        });
    }
}
