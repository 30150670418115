import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SisCommonModule } from 'sis-common/sis-common.module';

import { SisComponentsHybridModule } from '../../../sis-components.module.hybrid';
import { planLoaderProvider } from '../../ajs-upgraded-modules';

import { AttainmentApplicationPlanValidationService } from './attainment-application-plan-validation.service';
import { AttainmentApplicationStructureComponent } from './attainment-application-structure.component';

@NgModule({
    declarations: [
        AttainmentApplicationStructureComponent,
    ],
    imports: [
        CommonModule,
        SisCommonModule,
        SisComponentsHybridModule,
        TranslateModule.forChild(),
    ],
    exports: [
        AttainmentApplicationStructureComponent,
    ],
    providers: [
        AttainmentApplicationPlanValidationService,
        planLoaderProvider,
    ],
})
export class AttainmentApplicationStructureModule {
}
