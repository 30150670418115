<ng-container *transloco="let t">
  <div class="prerequisite-group-container" *ngFor="let prerequisiteGroup of prerequisites; index as prerequisiteIndex">
    <div class="grouping-panel">
      <div role="heading" class="sis-mb-xxs sis-label" [attr.aria-level]="level">
        {{t('STUDIES.PREREQUISITES_EDITOR.PREREQUISITE_CHOICE')}} {{(prerequisiteIndex + 1)}}
      </div>
      <div *ngFor="let prerequisite of prerequisiteGroup.prerequisites" class="prerequisites">
        <sis-course-unit-box *ngIf="prerequisite.type === 'CourseUnit'"
                             [courseUnit]="courseUnitsByGroupId[prerequisite.courseUnitGroupId]"></sis-course-unit-box>
        <sis-module-box *ngIf="prerequisite.type === 'Module'" [module]="modulesByGroupId[prerequisite.moduleGroupId]"></sis-module-box>
      </div>
    </div>
  </div>
  <div *ngIf="prerequisites?.length === 0" class="sis-placeholder">
    <sis-icon icon="alert-fill-attention"></sis-icon>
    {{t('SIS_COMPONENTS.COURSE_UNIT_REALISATION_INFO.NO_INFORMATION_GIVEN')}}
  </div>
</ng-container>
