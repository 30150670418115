import angular from 'angular';
import _ from 'lodash';
import { locationServiceModule } from 'sis-components/service/location.service';
import { studentStudyEventServiceModule } from './studyEvent.service';
import { calendarEventServiceModule } from 'sis-components/calendar/calendarEvent.service';
export const calendarServiceModule = 'student.common.service.calendarService';
(function () {
  calendarService.$inject = ["$q", "studyEventService", "calendarEventService", "fullCalendarService"];
  angular.module(calendarServiceModule, [locationServiceModule, studentStudyEventServiceModule, calendarEventServiceModule, 'sis-components.service.fullCalendarService']).service('calendarService', calendarService).constant('MY_CALENDAR_INSTANCE_KEY', 'myCalendar'); // the main student calendar instance

  /** @ngInject */
  function calendarService($q, studyEventService, calendarEventService, fullCalendarService) {
    const api = {
      currentDate: {},
      // Map calendarInstanceKey -> date to go to
      // Events, StudyEvents, CalendarEvents and events for full calendar might confuse some people. So instead of coming up with
      // sensible names for methods, these methods are under $private.
      $private: {
        findAllStudyEvents(enrolments, start, end) {
          return studyEventService.findMyStudyEvents(start, end).then(studyEvents => studyEventService.loadLocationsForStudyEventsAndEvents(studyEvents)).then(studyEvents => fullCalendarService.studyEventsToFullCalendarEvents(studyEvents, {
            start,
            end
          }, enrolments));
        },
        findOwnEvents(start, end) {
          return calendarEventService.findEvents(start, end).then(calendarEvents => calendarEventService.getFullCalendarEvents(calendarEvents, start, end));
        }
      },
      getFullCalendarEvents(enrolments, start, end) {
        return $q.all([api.$private.findOwnEvents(start, end), api.$private.findAllStudyEvents(enrolments, start, end)]).then(calendarEvents => _.flatten(calendarEvents));
      }
    };
    return api;
  }
})();