<ng-container *transloco="let t">
  <div *ngIf="(courseUnitRealisations$ | async) as realisations">
    <h3 *ngIf="title && subTitle">
      <span class="title">{{title}}</span>
      <span>{{subTitle}}</span>
    </h3>
    <p class="description-text">
      {{selectedCompletionMethodListItem.completionMethod.typeOfRequire === 'ALL_SELECTED_REQUIRED' ?
      t('COMPLETION_METHODS_LIST.DETAIL_VIEW.INFO_TEXT_ALL_REQUIRED') : t('COMPLETION_METHODS_LIST.DETAIL_VIEW.INFO_TEXT_OPTIONAL')}}
    </p>
    <div *ngIf="selectedCompletionMethodListItem.completionMethod.typeOfRequire === 'OPTIONAL_WITH_REQUIRE_RANGE'" class="col-12">
      <span class="control-label form-control-static tiny-static-form-group ng-scope">
        {{t('COMPLETION_METHODS_LIST.DETAIL_VIEW.REQUIRED_RANGE')}}: <span class="description-text">{{selectedCompletionMethodListItem.completionMethod.require | intRange}}</span>
      </span>
    </div>
    <div *ngIf="selectedCompletionMethodListItem.completionMethod.typeOfRequire !== 'ALL_SELECTED_REQUIRED'" class="col-12">
      <span class="control-label form-control-static tiny-static-form-group ng-scope">
        {{t('COMPLETION_METHODS_LIST.DETAIL_VIEW.INSTRUCTIONS')}}:
        <ng-container *ngIf="selectedCompletionMethodListItem.completionMethod.assessmentItemOptionalityDescription as description; else noDescription">
            <span class="description-text">{{description | localizedMarkupString}}</span>
        </ng-container>
        <ng-template #noDescription>-</ng-template>
      </span>
    </div>
    <div class="assessment-item-table-container" *ngFor="let assessmentItem of selectedCompletionMethodListItem.assessmentItems">
      <h3>{{t('COURSE_UNIT_INFO_MODAL.COURSE_UNIT_REALISATION_INFO_COMPLETION_METHOD')}}: {{assessmentItem.name | localizedString}} ({{assessmentItem.credits | creditRange}})</h3>
      <p class="description-text">{{t('COMPLETION_METHODS_LIST.DETAIL_VIEW.INFO_TEXT_ASSESSMENT_ITEM_CHOOSE_ONE')}}</p>
      <ng-container *ngIf="getRealisationsForAssessmentItem(realisations, assessmentItem.id) as realisationsForAssessmentItem">
        <ng-container *ngIf="realisationsForAssessmentItem.length > 0; else noRealisationsBlock">
          <h4>{{t('COMPLETION_METHODS_LIST.DETAIL_VIEW.ASSESSMENT_ITEM_REALISATIONS')}}</h4>
          <table class="table styled-table" [attr.aria-label]="t('COMPLETION_METHODS_LIST.DETAIL_VIEW.ASSESSMENT_ITEM_REALISATIONS')">
            <thead>
            <tr>
              <th>{{t('ASSESSMENT_ITEM.TEACHING')}}</th>
              <th>{{t('CALENDAR_EVENT_DATE_TITLE')}}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let realisation of realisationsForAssessmentItem">
              <td>{{realisation.name | localizedString}}</td>
              <td>{{realisation.activityPeriod | localDateRange}}</td>
            </tr>
            </tbody>
          </table>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <ng-template #noRealisationsBlock>
    <h4>{{t('COURSE_UNIT_INFO_MODAL.NO_UPCOMING_CUR')}}</h4>
  </ng-template>
</ng-container>

