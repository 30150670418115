import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { PlanValidationResult } from 'common-typescript/src/plan/validation/planValidationResult';
import {
    CustomCourseUnitAttainment,
    CustomModuleAttainment, DegreeProgramme,
    Education, GroupingModule,
    Module,
    ModuleAttainment,
    OtmId, StudyModule,
} from 'common-typescript/types';

import {
    EducationStateObject,
    ModuleStateObject,
    PlanData,
    PlanStateObject,
} from '../../service/plan-state.service';

@Component({
    selector: 'sis-plan-structure-module-content',
    templateUrl: './plan-structure-module-content.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanStructureModuleContentComponent {
    @Input({ required: true }) moduleOrEducationStateObject: EducationStateObject | ModuleStateObject;
    @Input({ required: true }) moduleOrEducation: Education | Module;
    @Input() planValidationResult: PlanValidationResult;
    @Input({ required: true }) planStateObject: PlanStateObject;
    @Input({ required: true }) planData: PlanData;
    @Input() isImplicit: boolean;
    @Input({ required: true }) headingLevel: number;

    getCustomCourseUnitAttainment(id: OtmId): CustomCourseUnitAttainment {
        return this.planData.attainmentsById[id] as CustomCourseUnitAttainment;
    }

    getCustomModuleAttainment(id: OtmId): CustomModuleAttainment {
        return this.planData.attainmentsById[id] as CustomModuleAttainment;
    }

    getModuleAttainment(id: OtmId): ModuleAttainment {
        return this.planData.attainmentsById[id] as ModuleAttainment;
    }

    getModule(id: OtmId): StudyModule | DegreeProgramme | GroupingModule {
        return this.planData.modulesById[id] as StudyModule | DegreeProgramme | GroupingModule;
    }
}
