<div role="group"
     [attr.aria-labelledby]="selectionUIState !== 'DISABLED' ? ruleLegendId() : undefined"
     [attr.id]="ruleFocusId()"
     [tabindex]="-1">
  <sis-plan-structure-count-rule-header *ngIf="selectionUIState !== 'DISABLED'"
                                        [onlyShowRule]="selectionUIState === 'SELECTABLE'"
                                        [rule]="_rule()"
                                        [ruleLegendId]="ruleLegendId()"
                                        [ruleValidationResults]="ruleValidationResults()">
  </sis-plan-structure-count-rule-header>
  <sis-plan-structure-rule-error-list *ngIf="ruleErrors && ruleErrors()?.length > 0"
                                      [ruleErrors]="ruleErrors()">
  </sis-plan-structure-rule-error-list>
  <sis-plan-structure-select-rule [parentModule]="_parentModule()"
                                  [headingLevel]="headingLevel"
                                  [rule]="_rule().rule"
                                  [planData]="planData"
                                  [planRuleData]="planRuleData"
                                  [planStateObject]="planStateObject"
                                  [planValidationResult]="_planValidationResult()"
                                  [validatablePlan]="validatablePlan"
                                  [groupPrefix]="groupPrefix"
                                  [ruleDepthLevel]="ruleDepthLevel + 1"
                                  [selectionUIState]="selectionUIState">
  </sis-plan-structure-select-rule>
</div>

