import angular from 'angular';
import { decimalNumberFilterModule } from 'sis-components/number/decimalNumber.filter';
import { BasicGroupingPanelComponent } from 'sis-components/grouping-panel/basic-grouping-panel.component.ts';
import customStudyDraftSummaryTpl from './customStudyDraftSummary.tpl.html';
export const customStudyDraftSummaryModule = 'sis-components.customStudyDraftSummary';
angular.module('sis-components.customStudyDraftSummary', [decimalNumberFilterModule, BasicGroupingPanelComponent.downgrade.moduleName]).component('customStudyDraftSummary', {
  template: customStudyDraftSummaryTpl,
  bindings: {
    customStudyDraft: '<'
  }
});