import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

/**
 * Use this service to download (and possibly open) a file in the browser. You can create the file from existing data using
 * {@link downloadFileFromData} or download it from a URL using {@link downloadFileFromUrl}.
 */
@StaticMembers<DowngradedService>()
@Injectable({ providedIn: 'root' })
export class FileDownloadService {

    static downgrade: ServiceDowngradeMappings = {
        moduleName: 'sis-components.file-download.fileDownloadService',
        serviceName: 'fileDownloadService',
    };

    // TODO: Change the any type to HTMLDocument when https://github.com/angular/angular/issues/15640 is fixed
    constructor(@Inject(DOCUMENT) private document: any) {}

    /**
     * Create a file from existing data and download it in the browser. The browser will not try to open the file by itself,
     * as long as it supports the [download](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#download)</a>
     * attribute.
     *
     * Works by creating a link element on the page, clicking it, and then removing it.
     *
     * @param fileContent file content
     * @param contentType file content type, e.g. `text/csv`
     * @param fileName file name including its suffix
     */
    downloadFileFromData(fileContent: string, contentType: string, fileName: string): void {
        this.downloadFileViaAnchorLink(((anchorElement) => {
            anchorElement.download = fileName;
            anchorElement.href = `data:${contentType};base64,${btoa(fileContent)}`;
        }));
    }

    /**
     * Download a file in the browser from an existing URL. The browser will open the file in another tab if it can, otherwise download it.
     *
     * Works by creating a link element on the page, clicking it, and then removing it.
     *
     * @param url file download URL
     */
    downloadFileFromUrl(url: string): void {
        this.downloadFileViaAnchorLink(((anchorElement) => {
            anchorElement.href = url;
            anchorElement.target = '_blank';
        }));
    }

    private downloadFileViaAnchorLink(setAttributesFn: (anchorElement: HTMLAnchorElement) => void): void {
        const anchorElement = this.document.createElement('a');
        setAttributesFn(anchorElement);
        this.document.body.appendChild(anchorElement); // Required for Firefox
        anchorElement.click();
        anchorElement.remove();
    }
}
