import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { HttpConfig, Msg, NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { HttpMethod } from '@datorama/akita-ng-entity-service/lib/ng-entity-service-notifier';
import {
    DraftStudentResultItem,
    DraftStudentSearchRequest,
    OtmId,
    PersonalIdentityCode,
    PersonDataChange,
    PersonQuery,
    PersonResultItem,
    PrivatePerson,
    PrivatePersonDataChange,
    PrivatePersonFullDataChange,
    SearchResult,
    SensitivePersonDataChange,
    StudentResultItem,
} from 'common-typescript/types';
import * as _ from 'lodash';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

import { searchRequestToQueryParams, simpleObjectToQueryParams } from '../search-ng/search-utils';

import { EntityService } from './entity.service';
import { PrivatePersonBasicInfoEntityService } from './private-person-basic-info-entity.service';

const CONFIG = {
    ENDPOINTS: {
        baseUrl: '/ori/api',
        baseUrlEmployees: '/ori/api/employees',
        baseUrlAllPersons: '/ori/api/persons/all',

        get searchDraftStudents(): string {
            return `${this.baseUrl}/students/draft`;
        },
        get searchStudents(): string {
            return `${this.baseUrl}/students`;
        },
        search(includeStudents?: boolean): string {
            return includeStudents ? this.baseUrlAllPersons : this.baseUrlEmployees;
        },
        fullPersonById(id: OtmId): string {
            return `${this.baseUrl}/persons/${id}/full`;
        },
        activateDraftStudent(id: OtmId): string {
            return `${this.baseUrl}/students/${id}/activate`;
        },
        merge(personId: OtmId, draftPersonId: OtmId): string {
            return `${this.baseUrl}/persons/${personId}/merge/${draftPersonId}`;
        },
        findByPersonalIdentityCode(code: PersonalIdentityCode): string {
            return `${this.baseUrl}/persons/by-personal-identity-code/${code}`;
        },
        get findPossibleDuplicates(): string {
            return `${this.baseUrl}/persons/duplicates`;
        },
        get userDetails(): string {
            return `${this.baseUrl}/user-details`;
        },
        fullHistory(personId: string): string {
            return `${this.baseUrl}/persons/${personId}/history/full`;
        },
        updateClassifiedPersonInfo(personId: OtmId): string {
            return `${this.baseUrl}/persons-classified/${personId}`;
        },
        updateNamesAndIdentityCode(personId: OtmId): string {
            return `${this.baseUrl}/persons/${personId}/sensitive`;
        },
        updateSecondaryEmail(personId: OtmId): string {
            return `${this.baseUrl}/persons/${personId}/update-secondary-email`;
        },
        updatePersonalDataSafetyNonDisclosure(personId: OtmId): string {
            return `${this.baseUrl}/persons/${personId}/personal-data-safety-nondisclosure`;
        },
    },
};

/**
 * A limited version of `PersonQuery`, which excludes all non-primitive types (or arrays of non-primitive types).
 */
type SimplePersonQuery = Omit<PersonQuery,
'attendingTermRegistrations' |
'nonAttendingTermRegistrations' |
'studyRightLearningOpportunities' |
'studyRightTuitionFeeObligationPeriodRanges' |
'studyStartDateRanges'>;

/**
 * For PrivatePersonBasicInfo and PersonInfo, please use PrivatePersonBasicInfoEntityService and PersonInfoEntityService.
 */
@StaticMembers<DowngradedService>()
@Injectable({ providedIn: 'root' })
@NgEntityServiceConfig({
    baseUrl: CONFIG.ENDPOINTS.baseUrl,
    resourceName: 'persons',
})
export class PrivatePersonEntityService extends EntityService<PrivatePersonState> {

    constructor(private privatePersonBasicInfoService: PrivatePersonBasicInfoEntityService) {
        super(PrivatePersonStore, PrivatePersonQuery);
    }

    static downgrade: ServiceDowngradeMappings = {
        dependencies: [],
        moduleName: 'sis-components.service.privatePersonEntityService',
        serviceName: 'privatePersonEntityService',
    };

    /**
     * Update an existing `PrivatePerson` object. Note that the update endpoint expects a `PrivatePersonDataChange` object
     * instead of a `PrivatePerson`, as some properties can't be updated via this endpoint.
     */
    update<T>(id: OtmId, person: Partial<PrivatePersonDataChange>, config?: HttpConfig & { method: HttpMethod.PUT } & Msg): Observable<T> {
        return super.update(id, person, config);
    }

    searchDraftStudents(searchRequest?: Partial<DraftStudentSearchRequest>): Observable<SearchResult<DraftStudentResultItem>> {
        const options = { params: this.toQueryParams(searchRequest) };
        return this.getHttp().get<SearchResult<DraftStudentResultItem>>(CONFIG.ENDPOINTS.searchDraftStudents, options);
    }

    /**
     * Analogous to `getById()`, but returns the full person object (including personal identity code), and requires
     * more privileges.
     */
    getFullPersonById(id: OtmId, bypassStore: boolean = false, config: HttpConfig = {}): Observable<PrivatePerson> {
        if (!id) {
            return throwError(() => new Error('The id was missing!'));
        }
        if (!bypassStore && this.query.hasEntity(id)) {
            return this.query.selectEntity(id);
        }

        return this.getHttp().get(CONFIG.ENDPOINTS.fullPersonById(id), config)
            .pipe(
                // Update store with person manually because regular http.get bypasses the store
                tap((person: PrivatePerson) => this.store.upsert(id, person)),
                // Return store Observable so that subscribers get updates when the entity changes
                switchMap(() => this.query.selectEntity(id)),
            );
    }

    getUserDetails(): Observable<PrivatePerson> {
        return this.getHttp().get(CONFIG.ENDPOINTS.userDetails)
            .pipe(
                tap((person: PrivatePerson) => this.store.upsert(person.id, person)),
                switchMap((person: PrivatePerson) => this.query.selectEntity(person.id)),
            );
    }

    saveUserDetails(profile: PrivatePerson): Observable<PrivatePerson> {
        return this.getHttp().put<PrivatePerson>(CONFIG.ENDPOINTS.userDetails, profile)
            .pipe(
                tap((person: PrivatePerson) => this.store.upsert(person.id, person)),
                switchMap((person: PrivatePerson) => this.query.selectEntity(person.id)),
            );
    }

    /**
     * Get multiple full private persons (including personal identity code).
     * GETs with multiple ids are not supported for private persons so call separate requests for each id
     */
    getFullPersonsByIds(ids: OtmId[], bypassStore: boolean = false, config: HttpConfig = {}): Observable<PrivatePerson[]> {
        const entityIds = (ids || []).filter(Boolean);
        if (_.isEmpty(entityIds)) {
            return of([]);
        }
        return combineLatest(entityIds.map(id => this.getFullPersonById(id, bypassStore, config)));
    }

    getFullPersonDataChanges(personId: OtmId): Observable<PersonDataChange[]> {
        return this.getHttp().get<PersonDataChange[]>(CONFIG.ENDPOINTS.fullHistory(personId));
    }

    activateDraft(id: OtmId, privatePersonFullDataChange: PrivatePersonFullDataChange, config: HttpConfig = {}): Observable<PrivatePerson> {
        if (!id) {
            return throwError(() => new Error('The id was missing!'));
        }
        return this.getHttp().put<PrivatePerson>(CONFIG.ENDPOINTS.activateDraftStudent(id), privatePersonFullDataChange, config);
    }

    delete<T>(id: OtmId): Observable<T> {
        return throwError(() => new Error('Delete not supported for private persons'));
    }

    findByPersonalIdentityCode(code: PersonalIdentityCode, config: HttpConfig = {}): Observable<PrivatePerson | null> {
        return this.getHttp().get<PrivatePerson>(CONFIG.ENDPOINTS.findByPersonalIdentityCode(code), config)
            .pipe(
                tap(person => this.store.upsert(person.id, person)),
                switchMap(person => this.query.selectEntity(person.id)),
                catchError((error) => {
                    // The backend returns 404 if no match is found, convert that to a null result
                    if (error instanceof HttpErrorResponse && error.status === 404) {
                        return of(null);
                    }
                    throw error;
                }),
            );
    }

    findPossibleDuplicates(person: Partial<PrivatePerson>, config: HttpConfig = {}): Observable<PrivatePerson[]> {
        return this.getHttp().post<PrivatePerson[]>(CONFIG.ENDPOINTS.findPossibleDuplicates, person, config);
    }

    mergeDraftPersonWithActivePerson(personId: OtmId, draftPersonId: OtmId, dataChange: PrivatePersonFullDataChange, config: HttpConfig = {}): Observable<PrivatePerson> {
        if (!personId || !draftPersonId) {
            return throwError(() => new Error('The active or draft person id was missing!'));
        }
        return this.getHttp().put<PrivatePerson>(CONFIG.ENDPOINTS.merge(personId, draftPersonId), dataChange, config);
    }

    /**
     * Performs a search from employees or employees and students both.
     *
     * @param fullTextQuery The query string to use.
     * @param includeStudents Setting false will search only from employees. Set true to include students with the search request.
     * @param start The index from which on the result item will be returned. Default is '0'.
     * @param limit The count of items to return with one query. Default is '20'.
     */
    search(fullTextQuery: string, includeStudents = false, start = '0', limit = '20'): Observable<SearchResult<PersonResultItem>> {
        const params = { start, limit, searchString: fullTextQuery };
        return this.getHttp().get(CONFIG.ENDPOINTS.search(includeStudents), { params }) as Observable<SearchResult<PersonResultItem>>;
    }

    searchStudents(query: Partial<SimplePersonQuery>, start = 0, limit = 20): Observable<SearchResult<StudentResultItem>> {
        if (!query || Object.keys(query)?.length === 0) {
            return of(null);
        }
        const params = simpleObjectToQueryParams({ ...query, start, limit });
        return this.getHttp().get<SearchResult<StudentResultItem>>(CONFIG.ENDPOINTS.searchStudents, { params });
    }

    updateClassifiedPersonInfo(personId: OtmId, classifiedPersonInfo: any): Observable<PrivatePerson> {
        return this.getHttp().put<PrivatePerson>(CONFIG.ENDPOINTS.updateClassifiedPersonInfo(personId), classifiedPersonInfo);
    }

    updateSecondaryEmail(personId: OtmId, secondaryEmail: string): Observable<PrivatePerson> {
        return this.getHttp()
            .put<PrivatePerson>(CONFIG.ENDPOINTS.updateSecondaryEmail(personId), { secondaryEmail })
            .pipe(
                tap(person => this.privatePersonBasicInfoService.refresh(person.id)),
            );
    }

    updatePersonalDataSafetyNonDisclosure(personId: OtmId, personalDataSafetyNonDisclosure: boolean): Observable<PrivatePerson> {
        return this.getHttp()
            .put<PrivatePerson>(CONFIG.ENDPOINTS.updatePersonalDataSafetyNonDisclosure(personId), { personalDataSafetyNonDisclosure })
            .pipe(
                tap(person => this.privatePersonBasicInfoService.refresh(person.id)),
            );
    }

    updateNamesAndIdentityCode(personId: OtmId, personDataChange: SensitivePersonDataChange): Observable<PrivatePerson> {
        return this.getHttp()
            .put<PrivatePerson>(CONFIG.ENDPOINTS.updateNamesAndIdentityCode(personId), { ...personDataChange })
            .pipe(
                tap(person => this.privatePersonBasicInfoService.refresh(person.id)),
            );
    }

    private toQueryParams(searchRequest?: Partial<DraftStudentSearchRequest>): { [key: string]: string | string[] } {
        if (_.isEmpty(searchRequest)) {
            return {};
        }

        return _.omitBy(
            {
                ...searchRequestToQueryParams(searchRequest),
                searchString: searchRequest.searchString,
                source: searchRequest.source,
                problems: searchRequest.problems,
                state: searchRequest.states,
            },
            _.isEmpty,
        );
    }
}

type PrivatePersonState = EntityState<PrivatePerson, OtmId>;

@StoreConfig({ name: 'private-person' })
class PrivatePersonStore extends EntityStore<PrivatePersonState> {}

class PrivatePersonQuery extends QueryEntity<PrivatePersonState> {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(store: EntityStore<PrivatePersonState>) {
        super(store);
    }
}
