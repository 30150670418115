import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import moduleRuleTpl from './moduleRule.tpl.html';
(function () {
  ModuleRuleController.$inject = ["PlanState", "uiContext", "commonStudyRightService", "planStudyRightModal", "planStudyRightService", "ruleService", "minorStudyRightService", "minorSelectionModalService", "defaultPromiseHandler"];
  angular.module('student.plan.rules').component('moduleRule', {
    bindings: {
      module: '<',
      rule: '<',
      validatablePlan: '<',
      educationOptions: '<',
      studyRight: '<'
    },
    controller: ModuleRuleController,
    template: moduleRuleTpl
  });
  function ModuleRuleController(
  // NOSONAR
  PlanState, uiContext, commonStudyRightService, planStudyRightModal, planStudyRightService, ruleService, minorStudyRightService, minorSelectionModalService, defaultPromiseHandler) {
    const $ctrl = this;
    let educationOptionNamingTypes;
    $ctrl.notifications = {};
    $ctrl.toggleSelection = toggleSelection;
    $ctrl.selectModule = selectModule;
    $ctrl.removeModule = removeModule;
    $ctrl.isSelected = isSelected;
    $ctrl.isDisabled = isDisabled;
    $ctrl.isImplicitlySelected = isImplicitlySelected;
    $ctrl.isImplicit = isImplicit;
    $ctrl.defaultVersion = undefined;
    $ctrl.$onInit = () => {
      commonStudyRightService.getEducationOptionNamingTypes().then(result => {
        educationOptionNamingTypes = result;
      });
    };
    $ctrl.$onChanges = () => {
      const plannedModule = uiContext.planContext.getPlannedModule($ctrl.rule.moduleGroupId);
      if (plannedModule) {
        $ctrl.ruleModule = plannedModule;
        $ctrl.defaultVersion = plannedModule;
      } else {
        setDefaultVersion();
      }
    };
    function setDefaultVersion() {
      if ($ctrl.defaultVersion) {
        $ctrl.ruleModule = $ctrl.defaultVersion;
      } else {
        uiContext.planContext.getModuleOfGroup($ctrl.rule.moduleGroupId, $ctrl.module.getCurriculumPeriodIds()).then(moduleVersion => {
          if (moduleVersion) {
            $ctrl.defaultVersion = moduleVersion;
          }
          $ctrl.ruleModule = $ctrl.defaultVersion;
        });
      }
    }
    function getRuleValidationResult() {
      return uiContext.planContext.getRuleValidationResult($ctrl.module.id, $ctrl.rule.localId);
    }
    $ctrl.isEducationOption = module => {
      if (!$ctrl.studyRight || planStudyRightService.doesModuleContainOnlyMinors($ctrl.module)) {
        return false;
      }
      const educationOption = $ctrl.getEducationOption(module);
      return !!educationOption && educationOption.isInPlanAsMinor === false;
    };
    $ctrl.getEducationOption = module => {
      if ($ctrl.educationOptions) {
        return planStudyRightService.getPotentialEducationOption(module.groupId, $ctrl.module.groupId, $ctrl.educationOptions, $ctrl.validatablePlan);
      }

      // EducationOptions are passed to this component by parent when the student has a matching studyRight.
      // If this is not the case, educationOptions need to be fetched to determine whether ruleModule
      // conflicts with allowedPaths of the plan learningOpportunity. If ruleModule is educationOption
      // and it is not included in allowedPaths of the learningOpportunity, it will not be shown
      // as a choice to the student regardless of whether the student has a studyRight or not.

      const rootModule = _.get($ctrl.validatablePlan, 'rootModule');

      // if rootModule type is not Education, we are in previewMode
      if (_.get(rootModule, 'type') === 'Education') {
        const educationOptions = planStudyRightService.getEducationOptions($ctrl.validatablePlan, rootModule);
        return planStudyRightService.getPotentialEducationOption(module.groupId, $ctrl.module.groupId, educationOptions, $ctrl.validatablePlan);
      }
      return undefined;
    };
    $ctrl.getEducationOptionNamingTypeForModule = module => {
      if (educationOptionNamingTypes) {
        const educationOption = $ctrl.getEducationOption(module);
        const namingTypeUrn = _.get(educationOption, 'namingType');
        if (namingTypeUrn) {
          return _.get(educationOptionNamingTypes, namingTypeUrn);
        }
        return null;
      }
      return null;
    };
    $ctrl.isModuleAlreadyInPlan = module => !isSelected(module) && $ctrl.validatablePlan.isModuleInPlan(module.id);
    $ctrl.handleAdditionalBoxClick = (module, $event) => {
      if ($event.target.localName === 'div') {
        toggleSelection(module);
      }
    };
    $ctrl.handleCheckboxClick = (module, $event) => {
      if ($event.target.localName === 'input') {
        toggleSelection(module);
      }
    };
    $ctrl.isInvalidSelection = () => _.get(getRuleValidationResult(), 'invalidSelection') === true;
    $ctrl.isInvalidAccordingToModuleContentApproval = () => _.get(getRuleValidationResult(), 'invalidAccordingToModuleContentApproval') === true;
    $ctrl.hasModuleContentApproval = () => _.get(getRuleValidationResult(), 'invalidAccordingToModuleContentApproval') !== undefined;
    $ctrl.showEducationPaths = () => {
      getMatchingStudyRight().then(studyRight => {
        if (studyRight) {
          planStudyRightModal.open(uiContext.planContext.root, studyRight, $ctrl.validatablePlan, $ctrl.educationOptions).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
        }
      });
    };
    $ctrl.isModuleHidden = () => !$ctrl.isModuleSelectedUnderThisRule() && $ctrl.isModuleConflictingWithPlanLearningOpportunity();
    $ctrl.isModuleSelectedUnderThisRule = () => {
      if (!$ctrl.ruleModule) {
        return false;
      }
      return $ctrl.validatablePlan.isModuleInPlan($ctrl.ruleModule.id) && _.get($ctrl.validatablePlan.getParentModuleOrCustomModuleAttainmentForModule($ctrl.ruleModule), 'id') === $ctrl.module.id;
    };
    $ctrl.isModuleConflictingWithPlanLearningOpportunity = () => {
      if (!$ctrl.ruleModule) {
        return false;
      }
      const parentRule = ruleService.getParentRule($ctrl.module.rule, $ctrl.rule);
      if (!parentRule || parentRule.type !== 'CompositeRule' || ruleService.isSelectAllRule(parentRule)) {
        return false;
      }
      const educationOption = $ctrl.getEducationOption($ctrl.ruleModule);
      const learningOpportunityId = _.get($ctrl.validatablePlan, 'plan.learningOpportunityId');
      const education = _.get($ctrl.validatablePlan, 'rootModule');
      if (!educationOption || !learningOpportunityId || !education || education.type !== 'Education') {
        return false;
      }
      const learningOpportunity = _.find(education.structure.learningOpportunities, {
        localId: learningOpportunityId
      });
      if (!learningOpportunity) {
        return false;
      }
      const allowedPaths = _.isNil(_.get($ctrl.studyRight, 'personalizedSelectionPath')) ? learningOpportunity.allowedPaths : planStudyRightService.createPersonalizedAllowedPaths(learningOpportunity.allowedPaths, $ctrl.studyRight);
      return !_.some(allowedPaths, allowedPath => _.get(allowedPath, educationOption.educationPathKey) === educationOption.moduleGroupId);
    };
    $ctrl.showMinorSelectionBlock = () => {
      const educationOption = $ctrl.getEducationOption($ctrl.ruleModule);
      if (!!educationOption && educationOption.isInPlan && !educationOption.isInPlanAsMinor) {
        return false;
      }
      return !!$ctrl.studyRight && !$ctrl.validatablePlan.isModuleAttained($ctrl.ruleModule.id) && minorStudyRightService.isPotentialMinorSelection($ctrl.ruleModule, $ctrl.module);
    };
    $ctrl.getApprovedMinorSelection = () => minorStudyRightService.getApprovedMinorSelection($ctrl.ruleModule, $ctrl.studyRight, $ctrl.validatablePlan);
    $ctrl.isTeacherAcceptanceRequired = () => minorStudyRightService.isTeacherAcceptanceRequired($ctrl.ruleModule);
    $ctrl.isMinorSelectionOngoing = () => {
      const endDate = _.get($ctrl.getApprovedMinorSelection(), 'validityPeriod.endDate');
      return _.isNil(endDate) || moment().isBefore(moment(endDate));
    };
    $ctrl.openMinorSelectionModal = () => {
      minorSelectionModalService.open($ctrl.ruleModule, $ctrl.studyRight, $ctrl.validatablePlan).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    };
    function toggleSelection(module) {
      if (!isDisabled(module)) {
        if (isSelected(module)) {
          return removeModule(module);
        }
        return selectModule(module);
      }
      return undefined;
    }
    function getMatchingStudyRight() {
      return commonStudyRightService.getStudyRightsAffectingPlanByEducationIds([uiContext.planContext.root.id], false).then(studyRights => _.find(studyRights, studyRight => studyRight.documentState === 'ACTIVE'));
    }
    function selectModule(module) {
      uiContext.planContext.selectModule($ctrl.module, module);
    }
    function removeModule(module) {
      uiContext.planContext.removeModule(module);
    }
    function isSelected(module) {
      if (!module) {
        return false;
      }
      return uiContext.planContext.isSelectedModule($ctrl.module, module);
    }
    function isDisabled(module) {
      if (!module) {
        return false;
      }
      return uiContext.planContext.isModuleSelectionDisabled(module, $ctrl.module);
    }
    function isImplicit(module) {
      const validatablePlan = _.get(uiContext, 'planContext.validatablePlan');
      if (module && validatablePlan) {
        const ruleValidationResult = $ctrl.getRuleValidationResult();
        return validatablePlan.isModuleInPlan(module) && _.get(ruleValidationResult, 'state') === PlanState.IMPLICIT;
      }
      return false;
    }
    function isImplicitlySelected(module) {
      const selectedModulesById = _.get($ctrl.getRuleValidationResult(), 'selectedModulesById');
      return isImplicit(module) && !!_.get(selectedModulesById, module.id);
    }
  }
})();