<div *ngIf="attainment" class="studymodule" [ngClass]="[categoryCssClass ? categoryCssClass : '']">
    <div class="studymodule-top-row">
        <div class="title-box">
            <sis-tooltip [tooltipText]="(attainment.name | localizedString)" [appendToBody]="true">
                <span class="studymodule-title sis-tooltip-trigger-content">{{attainment.name | localizedString}}</span>
            </sis-tooltip>
            <span class="studymodule-code">{{ attainment.code }}</span>
        </div>
        <div class="extra-items-holder">
            <div class="additional-box toggle-box">
                <ng-content select="[attainment-box=extraitemholder]"></ng-content>
            </div>
            <div class="grade-box">
                <sis-icon icon="achievement"></sis-icon>
                <span class="grade-text">{{attainment.gradeId | grade: attainment.gradeScaleId | async }}</span>
            </div>
            <div class="credits-box">
                <span class="credit-amount">{{{min: attainment.credits, max: attainment.credits} | creditRange:'SYMBOLS':true}}</span>
                <span class="credit-abbreviation" translate>SIS_COMPONENTS.CREDIT_RANGE.CREDITS_ABBR</span>
            </div>
        </div>
    </div>
    <div class="studymodule-notification-bar">
        <ng-content select="[attainment-box=notifications]" class="notifications"></ng-content>
    </div>
</div>
