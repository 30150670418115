<div class="selected float-end">
    <span
            translate="PLAN_EDIT.SELECTION_ASSISTANT.RULE_HEADER.CREDIT_STATUS"
            [translateParams]="{from: plannedCredits.min, to: targetCredits | translateRange}">
    </span>
</div>
<div class="badge" *ngIf="!isOk">
    <sis-icon icon="rule" color="white"></sis-icon>
    <span
            *ngIf="notEnoughCreditsSelected"
            translate="PLAN_EDIT.SELECTION_ASSISTANT.RULE_HEADER.CREDITS_TO_ADD"
            [translateParams]="{range: addRange | translateRange}">
    </span>
    <span
            *ngIf="tooManyCreditsSelected"
            translate="PLAN_EDIT.SELECTION_ASSISTANT.RULE_HEADER.CREDITS_TO_REMOVE"
            [translateParams]="{range: removeRange | translateRange}">
    </span>
</div>
