<ng-container *transloco="let t">
  <form>
    <fudis-heading [level]="2">{{t('PLAN.FETCH_EMREX_ATTAINMENTS.TITLE')}}</fudis-heading>
    <div class="modal-header">
      <sis-modal-close-button/>
    </div>
    <div class="modal-body">
      <p>
        {{t('PLAN.FETCH_EMREX_ATTAINMENTS.GUIDANCE')}}
      </p>
      {{t('COMMON.HELP')}}:
      <ol>
        <li>
          {{t('PLAN.FETCH_EMREX_ATTAINMENTS.GUIDANCE_1')}}
        </li>
        <li>
          {{t('PLAN.FETCH_EMREX_ATTAINMENTS.GUIDANCE_2')}}
        </li>
      </ol>
      <div class="sis-mt-md sis-mb-xl col-9" *ngIf="EMPOptions$ | async as data; else loading">
        <label class="form-label">{{t('PLAN.FETCH_EMREX_ATTAINMENTS.ACCESS_POINT_FIELD')}}</label><span> ({{t('PLAN.FETCH_EMREX_ATTAINMENTS.MANDATORY')}})</span>
        <sis-dropdown-select
          [placeholder]="t('SEARCH.PLACEHOLDER_SELECT')"
          [options]="data"
          (selectionChange)="setSelectedAccessPointUrl($event)"
        ></sis-dropdown-select>
      </div>
    </div>
    <div class="modal-footer confirm-dialog-footer">
      <sis-button [hollow]="true" (clicked)="modal.dismiss()">{{ t('CANCEL') }}</sis-button>
      <sis-button (clicked)="redirectToAccessPoint()">{{ t('PLAN.FETCH_EMREX_ATTAINMENTS.MOVE_TO_EMREX_SERVICE') }}</sis-button>
    </div>
  </form>
  <ng-template #loading>
    <sis-small-spinner />
  </ng-template>
</ng-container>
