import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ClassProvider } from '@angular/core';

import { AuthRetryInterceptor } from './auth-retry-interceptor';
import { AuthTokenInterceptor } from './auth-token-interceptor';
import { ErrorInterceptor } from './error-interceptor';
import { FrontendInfoInterceptor } from './frontend-info/frontend-info-interceptor';

/**
 * HTTP interceptor providers in outside-in order: requests will flow in the order of this list, responses in the opposite order.
 */
export const httpInterceptorProviders: ClassProvider[] = [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: FrontendInfoInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthRetryInterceptor, multi: true },
];
