<div *ngFor="let priorLearning of priorLearnings; index as i" class="form-group-mimic application-attributes">
  <sis-expandable *ngIf="showAsExpandable; else defaultTemplate" [level]="level" [variant]="'naked-dotted'">
    <ng-template sisExpandableCustomTitle>
      <ng-container [ngTemplateOutlet]="title" [ngTemplateOutletContext]="{priorLearning, i}"></ng-container>
    </ng-template>
    <ng-template sisExpandableContent>
      <ng-container [ngTemplateOutlet]="priorLearningContent" [ngTemplateOutletContext]="{priorLearning}"></ng-container>
    </ng-template>
  </sis-expandable>
  <ng-template #defaultTemplate>
    <div role="heading" class="fudis-heading__lg" [attr.aria-level]="level"><ng-container [ngTemplateOutlet]="title" [ngTemplateOutletContext]="{priorLearning, i}"></ng-container></div>
    <ng-container [ngTemplateOutlet]="priorLearningContent" [ngTemplateOutletContext]="{priorLearning}"></ng-container>
  </ng-template>
</div>

<ng-template #priorLearningContent let-priorLearning="priorLearning">
  <sis-prior-studies-summary *ngIf="priorLearning.type === PRIOR_LEARNING_TYPE.STUDIES" [personId]="personId" [priorStudies]="priorLearning"></sis-prior-studies-summary>
  <sis-prior-competence-summary *ngIf="priorLearning.type === PRIOR_LEARNING_TYPE.COMPETENCE" [priorCompetence]="priorLearning"></sis-prior-competence-summary>
</ng-template>

<ng-template #title let-priorLearning="priorLearning" let-i="i">
  <div>
    {{priorLearning | priorLearningName:applicationType:(priorLearnings.length > 1 ? i : null)}}
    <fudis-badge class="sis-ml-xs" variant="primary" *ngIf="hasExternalAttainedStudyId(priorLearning)">{{'STUDENT_APPLICATIONS.PRIOR_LEARNING.PRIOR_LEARNING_EMREX_BADGE' | transloco}}</fudis-badge>
    <fudis-body-text class="sis-mt-sm" *ngIf="hasExternalAttainedStudyId(priorLearning)" [variant]="'md-light'">{{ 'STUDENT_APPLICATIONS.PRIOR_LEARNING.SELECTED_EMREX_ATTAINMENT_GUIDANCE' | transloco }}</fudis-body-text>
  </div>
</ng-template>
