import 'js-data';
import 'js-data-angular';
import '../service/jsDataRelationHelper.service';
import '../model/cefrLevel.model';
import '../model/studyField.model';
import '../model/studyLevel.model';
import '../model/gradeScale.model';
import '../model/officialLanguage.model';
import '../model/curriculumPeriod.model';
import '../model/courseUnitType.model';
import './s2r2Classification.model';
import { CourseUnitEntityService } from '../service/course-unit-entity.service';
export const courseUnitJSDataModelModule = 'sis-components.model.courseUnit';
"use strict";
(function () {
  courseUnitModel.$inject = ["DS", "$q", "$log", "$injector", "$rootScope", "jsDataRelationHelperService", "COURSE_UNIT_URL"];
  angular.module(courseUnitJSDataModelModule, ['js-data', 'sis-components.service.jsDataRelationHelper', 'sis-components.model.cefrLevel', 'sis-components.model.studyField', 'sis-components.model.studyLevel', 'sis-components.model.gradeScale', 'sis-components.model.officialLanguage', 'sis-components.model.curriculumPeriod', 'sis-components.model.courseUnitType', 'sis-components.model.s2r2Classification', CourseUnitEntityService.downgrade.moduleName]).factory('courseUnitJSDataModel', courseUnitModel).constant('COURSE_UNIT_URL', {
    ENDPOINT_URL: '/kori/api/course-units',
    SEARCH_URL: '/kori/api/course-unit-search',
    AUTHENTICATED_ENDPOINT_URL: '/kori/api/authenticated/course-units',
    AUTHENTICATED_SEARCH_URL: '/kori/api/authenticated/course-unit-search',
    COURSE_UNIT_REALISATION_TEACHER_URL: '/kori/api/course-units/course-unit-realisation-teacher',
    FIND_BY_GROUP_ID: '/kori/api/course-units/by-group-id'
  }).constant('STUDY_TYPE', {
    DEGREE_STUDIES: 'DEGREE_STUDIES',
    OPEN_UNIVERSITY_STUDIES: 'OPEN_UNIVERSITY_STUDIES',
    SEPARATE_STUDIES: 'SEPARATE_STUDIES'
  }).run(["cefrLevelJSDataModel", "courseUnitJSDataModel", "studyFieldJSDataModel", "studyLevelModel", "courseUnitTypeJsDataModel", "officialLanguageModel", "s2r2ClassificationJsDataModel", function (cefrLevelJSDataModel, courseUnitJSDataModel, studyFieldJSDataModel, studyLevelModel,
  //NOSONAR
  courseUnitTypeJsDataModel, officialLanguageModel, s2r2ClassificationJsDataModel) {//NOSONAR
  }]);

  /**
   * @ngInject
   */
  function courseUnitModel(DS, $q, $log, $injector, $rootScope, jsDataRelationHelperService, COURSE_UNIT_URL) {
    function loadCourseUnitUniversityOrgPersonAndOrganisationRelations(courseUnit) {
      const relationLoads = [jsDataRelationHelperService.loadOrganisationRelations(courseUnit.organisations), jsDataRelationHelperService.loadModuleResponsibilityInfoRelations(courseUnit.responsibilityInfos), jsDataRelationHelperService.loadUniversityOrgs([courseUnit]), jsDataRelationHelperService.loadCurriculumPeriods([courseUnit])];
      return $q.all(relationLoads).then(function () {
        return courseUnit;
      }, function (error) {
        $log.warn("Failed to load courseUnit organisations and person relations", courseUnit, error);
      });
    }
    return DS.defineResource({
      idAttribute: 'id',
      name: 'courseUnit',
      endpoint: COURSE_UNIT_URL.ENDPOINT_URL,
      relations: {
        hasOne: {
          gradeScale: {
            localField: 'gradeScale',
            localKey: 'gradeScaleId'
          },
          studyLevel: {
            localField: 'studyLevelLocal',
            localKey: 'studyLevel'
          },
          cefrLevel: {
            localField: 'cefrLevelLocal',
            localKey: 'cefrLevel'
          },
          courseUnitType: {
            localField: 'courseUnitTypeLocal',
            localKey: 'courseUnitType'
          },
          s2r2Classification: {
            localField: 's2r2ClassificationLocal',
            localKey: 's2r2Classification'
          }
        },
        hasMany: {
          studyField: {
            localField: 'localStudyFields',
            localKeys: 'studyFields'
          },
          organisation: {
            localField: 'universityOrgs',
            localKeys: 'universityOrgIds'
          },
          curriculumPeriod: {
            localField: 'curriculumPeriods',
            localKeys: 'curriculumPeriodIds'
          },
          language: {
            localField: 'attainmentLanguages',
            localKeys: 'possibleAttainmentLanguages'
          }
        }
      },
      methods: {
        loadRelations: function () {
          return loadCourseUnitUniversityOrgPersonAndOrganisationRelations(this);
        }
      },
      beforeUpdate: function (resource, courseUnit, cb) {
        if (courseUnit.validityPeriod && !courseUnit.validityPeriod.startDate && !courseUnit.validityPeriod.endDate) {
          courseUnit.validityPeriod = undefined;
        }
        cb(null, courseUnit);
      },
      afterCreate: (resource, courseUnit, cb) => {
        const courseUnitEntityService = $injector.get('courseUnitEntityService');
        courseUnitEntityService.storeUpsert(_.cloneDeep(courseUnit));
        $rootScope.$broadcast('CourseUnitChange', courseUnit);
        cb(null, courseUnit);
      },
      afterUpdate: function (resource, courseUnit, cb) {
        const courseUnitEntityService = $injector.get('courseUnitEntityService');
        courseUnitEntityService.storeUpsert(_.cloneDeep(courseUnit));
        $rootScope.$broadcast('CourseUnitChange', courseUnit);
        return loadCourseUnitUniversityOrgPersonAndOrganisationRelations(courseUnit).then(function () {
          cb(null, courseUnit);
        });
      }
    });
  }
})();