import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { EnrolmentRight } from 'common-typescript/types';

import { Option } from '../../../menuButton/menu-button.component';
import { EnrolmentRightModalService } from '../enrolment-right-modal.service';

@Component({
    selector: 'sis-enrolment-right-expandable',
    templateUrl: './enrolment-right-expandable.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class EnrolmentRightExpandableComponent {

    @Input() enrolmentRight: EnrolmentRight;
    @Input() componentType?: 'STAFF' | 'STUDENT' = 'STAFF';
    @Output() refreshStudyRightTrigger? = new EventEmitter<void>();

    readonly menuOptions: Option[] = [
        {
            action: () => this.enrolmentRightModalService.openEditModal({ enrolmentRight: this.enrolmentRight })
                .then(res => this.componentType === 'STAFF' ? this.refreshStudyRightTrigger.emit() : null)
                .catch(() => { /* catch modal dismiss = do nothing */ }),
            name: 'OPENUNIVERSITY.STUDY_RIGHTS.ENROLMENT_RIGHT.EDIT',
            disabled: () => this.enrolmentRight?.state !== 'ACTIVATED',
        },
        {
            action: () => this.enrolmentRightModalService.openCancelModal({ enrolmentRight: this.enrolmentRight })
                .then(res => this.componentType === 'STAFF' ? this.refreshStudyRightTrigger.emit() : null)
                .catch(() => { /* catch modal dismiss = do nothing */ }),
            name: 'OPENUNIVERSITY.STUDY_RIGHTS.ENROLMENT_RIGHT.CANCEL',
            disabled: () => this.enrolmentRight?.state !== 'ACTIVATED',
        },
    ];

    collapsed = true;

    constructor(
        private enrolmentRightModalService: EnrolmentRightModalService,
    ) {}
}
