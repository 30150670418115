import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import {
    Event,
    OtmId,
    StudyEvent,
} from 'common-typescript/types';
import _ from 'lodash';
import moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { StudyEventEntityService } from 'sis-components/service/study-event-entity.service';

@Component({
    selector: 'app-study-sub-group-info',
    templateUrl: './study-sub-group-info.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudySubGroupInfoComponent implements OnInit {
    @Input() studyEventIds: OtmId[] = [];
    @Input() teacherIds: OtmId[] = [];
    @Input() ssgSize?: number;

    studyEvents$: Observable<StudyEvent[]>;
    visibleStudyEvents$ = new BehaviorSubject<StudyEvent[]>([]);
    irregularEvents: Event[] = [];

    constructor(private studyEventEntityService: StudyEventEntityService,
                private localeService: LocaleService,
                private appErrorHandler: AppErrorHandler) { }

    ngOnInit(): void {
        this.studyEvents$ = this.getEvents();
    }

    getEvents(): Observable<StudyEvent[]> {
        if (!this.studyEventIds || this.studyEventIds.length === 0) return new BehaviorSubject([]);
        return this.studyEventEntityService.getByIds(this.studyEventIds).pipe(
            this.appErrorHandler.defaultErrorHandler(),
            tap(studyEvents => this.irregularEvents = this.getIrregularEvents(studyEvents)),
            map(studyEvents => this.orderStudyEventsByTime(studyEvents)),
            tap(studyEvents => this.setVisibleEnrolments(studyEvents.length <= 3, studyEvents)),
        );
    }

    setVisibleEnrolments(showAll: boolean, studyEvents: StudyEvent[]): void {
        this.visibleStudyEvents$.next(showAll ? studyEvents : studyEvents.slice(0, 3));
    }

    getCurrentLanguage = () => this.localeService.getCurrentLanguage();

    getTime = (dateTime: string) => moment(dateTime).format('H.mm');

    hasIrregularLocations = (events: Event[]): boolean => _(events)
        .filter({ excluded: false, cancelled: false })
        .some(event => _.isArray(event.irregularLocationIds));

    private getIrregularEvents = (studyEvents: StudyEvent[]): Event[] => _(studyEvents)
        .flatMap('events')
        .filter(this.hasIrregularities)
        .sortBy(['start'])
        .value();

    private hasIrregularities = (event: Event): boolean => event.excluded || event.cancelled || !_.isNil(event.irregularLocationIds) ||
        !_.isNil(event.notice);

    /* Moved from angular service - If will be used in multiple places, a new service would be handy */
    private orderStudyEventsByTime = (studyEvents: StudyEvent[]): StudyEvent[] => {
        const sortByStartDate = (studyEvent: StudyEvent) => moment(studyEvent.startTime).format('YYYYMMDD');
        const sortByEndDate = (studyEvent: StudyEvent) => moment(studyEvent.recursUntil).format('YYYYMMDD');
        const sortByRecurs = (studyEvent: StudyEvent) => {
            switch (studyEvent.recursEvery) {
                case 'NEVER':
                    return 0;
                case 'DAILY':
                    return 1;
                case 'WEEKLY':
                    return 2;
                case 'EVERY_SECOND_WEEK':
                    return 3;
                case 'MONTHLY':
                    return 4;
                default:
                    return 0;
            }
        };
        const sortByTime = (studyEvent: StudyEvent) => moment(studyEvent.startTime).format('HHmmss');
        return _.sortBy(studyEvents, sortByStartDate, sortByEndDate, sortByRecurs, sortByTime);
    };
}
