import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { DisclosureAuthorization, OtmId } from 'common-typescript/types';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { EntityService } from './entity.service';

const CONFIG = {
    ENDPOINTS: {
        backend: '/ori/api',
        disclosures(): string {
            return `${this.backend}/disclosures`;
        },
        createDisclosureForStudent(personId: OtmId): string {
            return `${this.backend}/disclosures/${personId}`;
        },
    },
};

@Injectable({ providedIn: 'root' })
@NgEntityServiceConfig({ baseUrl: CONFIG.ENDPOINTS.backend })
export class DisclosureAuthorizationEntityService extends EntityService<DisclosureAuthorizationState> {
    constructor() {
        super(DisclosureAuthorizationStore, DisclosureAuthorizationQuery);
    }

    getActiveAuthorizations(personId: OtmId, universityOrgId: OtmId): Observable<DisclosureAuthorization[]> {
        return this.getHttp().get<DisclosureAuthorization[]>(CONFIG.ENDPOINTS.disclosures(), { params: { studentId: personId, universityOrgId } })
            .pipe(
                tap((disclosureAuthorizations) => this.store.upsertMany(disclosureAuthorizations)),
                switchMap((disclosureAuthorizations) => this.query.selectMany(disclosureAuthorizations.map(discs => discs.id))),
            );
    }

    createNewAnswer(disclosureAuthorization: DisclosureAuthorization): Observable<DisclosureAuthorization> {
        return this.getHttp().post<DisclosureAuthorization>(CONFIG.ENDPOINTS.disclosures(), disclosureAuthorization).pipe(
            tap(entity => this.store.upsert(entity.id, entity)),
            switchMap(entity => this.query.selectEntity(entity.id)),
        );
    }

    createForStudent(personId: OtmId, disclosureAuthorization: DisclosureAuthorization): Observable<DisclosureAuthorization> {
        return this.getHttp().post<DisclosureAuthorization>(CONFIG.ENDPOINTS.createDisclosureForStudent(personId), disclosureAuthorization).pipe(
            tap(entity => this.store.upsert(entity.id, entity)),
            switchMap(entity => this.query.selectEntity(entity.id)),
        );
    }

    updateAnswer(disclosureAuthorization: DisclosureAuthorization): Observable<DisclosureAuthorization> {
        return this.getHttp().put<DisclosureAuthorization>(CONFIG.ENDPOINTS.disclosures(), disclosureAuthorization).pipe(
            tap(entity => this.store.upsert(entity.id, entity)),
            switchMap(entity => this.query.selectEntity(entity.id)),
        );
    }
}

type DisclosureAuthorizationState = EntityState<DisclosureAuthorization, OtmId>;

@StoreConfig({ name: 'disclosure-authorization', idKey: 'id' })
class DisclosureAuthorizationStore extends EntityStore<DisclosureAuthorizationState> {}

class DisclosureAuthorizationQuery extends QueryEntity<DisclosureAuthorizationState> {
    constructor(protected store: DisclosureAuthorizationStore) {
        super(store);
    }
}
