"use strict";

(function () {
  ruleSearchParametersService.$inject = ["curriculumPeriodService"];
  angular.module('student.plan.ruleSearchParametersService', ['student.common.service.curriculumPeriodService']).factory('ruleSearchParametersService', ruleSearchParametersService);
  function ruleSearchParametersService(curriculumPeriodService) {
    var api = {
      getCurriculumPeriodsForSearch: function (planCurriculumPeriodId) {
        var result = [];
        return curriculumPeriodService.findAll().then(function (curriculumPeriods) {
          sortCurriculumPeriods(curriculumPeriods);
          var firstIndex = _.findIndex(curriculumPeriods, function (curriculumPeriod) {
            return curriculumPeriod.id === planCurriculumPeriodId;
          });
          if (firstIndex === -1) {
            return [];
          } else {
            if (firstIndex !== curriculumPeriods.length - 1) {
              result.push(curriculumPeriods[firstIndex]);
              var remainingCurriculumPeriods = _.slice(curriculumPeriods, firstIndex + 1);
              var currentMoment = moment();
              _.forEach(remainingCurriculumPeriods, function (cp) {
                if (moment(cp.activePeriod.startDate).isSameOrBefore(currentMoment, 'day')) {
                  result.push(cp);
                }
              });
              var lastIndex = _.indexOf(remainingCurriculumPeriods, _.last(result));
              var lastResult = _.last(result);
              if (currentMoment.isBetween(lastResult.activePeriod.startDate, lastResult.activePeriod.endDate, 'days', '[)') && lastIndex !== remainingCurriculumPeriods.length - 1) {
                result.push(remainingCurriculumPeriods[lastIndex + 1]);
              }
            }
          }
          return result;
        });
      }
    };
    function sortCurriculumPeriods(curriculumPeriods) {
      curriculumPeriods.sort(function (a, b) {
        return moment(a.activePeriod.startDate).isBefore(b.activePeriod.startDate, 'day') ? -1 : 1;
      });
    }
    return api;
  }
})();