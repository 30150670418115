(function () {
  angular.module('sis-components.string.textareaLength', []).directive('textareaLength', textareaLength).controller('textareaLengthController', textareaLengthController);

  /**
   * @ngInject
   */
  function textareaLength() {
    return {
      restrict: 'A',
      scope: {
        textareaCharactersLeft: '=textareaCharactersLeft'
      },
      transclude: {
        content: 'textareaContent',
        additional: '?textareaAdditional'
      },
      template: '<div ng-transclude="content"></div><span class="angular-animate textarea-length-indicator" ng-class="{\'max-length-reached\': ctrl.getCharactersLeft() < 1, \'indicator-hidden\': ctrl.indicatorHidden}">{{ctrl.getCharactersLeft()}}</span><div ng-transclude="additional"></div>',
      link: function (scope, elem, attr, ctrl) {
        var innerTextarea = $(elem).find('textarea');
        innerTextarea.attr("maxlength", attr.textareaLength);
        ctrl.setOriginalMaxLength(attr.textareaLength);
        ctrl.setCharactersLeft(attr.textareaLength - parseInt(innerTextarea.val().length));
        elem.on('input propertychange', 'textarea', function () {
          // every time the content of the textarea changes, flick indicator hidden off and on again to cause a delayed css animation for hiding
          let currentLength = parseInt($(this).val().length);
          ctrl.setCharactersLeft(ctrl.getOriginalMaxLength() - currentLength);
          ctrl.indicatorHidden = false;
          if (!scope.$$phase) {
            scope.$apply();
          }
          if (currentLength !== 0) {
            ctrl.indicatorHidden = true;
            if (!scope.$$phase) {
              scope.$apply();
            }
          }
        });
        setTimeout(function () {
          innerTextarea.trigger('propertychange');
          ctrl.indicatorHidden = false;
        }, 100);
      },
      controller: 'textareaLengthController as ctrl'
    };
  }
  function textareaLengthController() {
    var ctrl = this;
    var originalMaxLength = 0;
    var charactersLeft = 0;
    ctrl.indicatorHidden = false;
    ctrl.setOriginalMaxLength = function (origLength) {
      originalMaxLength = origLength;
      return originalMaxLength;
    };
    ctrl.getOriginalMaxLength = function () {
      return originalMaxLength;
    };
    ctrl.setCharactersLeft = function (newLength) {
      charactersLeft = newLength;
      return charactersLeft;
    };
    ctrl.getCharactersLeft = function () {
      return charactersLeft;
    };
    ctrl.setCharactersLeft(ctrl.getOriginalMaxLength());
  }
})();