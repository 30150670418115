import { Injectable } from '@angular/core';
import { LocalizedString, Organisation, OtmId } from 'common-typescript/types';
import { map, Observable } from 'rxjs';
import { LocalizedStringPipe } from 'sis-common/l10n/localized-string.pipe';

import { EntityNamePipe } from '../entity-pipe/entity-name.pipe';
import { OrganisationPipe } from '../entity-pipe/entity.pipe';

@Injectable({
    providedIn: 'root',
})
export class OrganisationNameService {

    constructor(
        private organisationPipe: OrganisationPipe,
        private entityNamePipe: EntityNamePipe,
        private localizedStringPipe: LocalizedStringPipe,
    ) {}

    getOrgName(organisationId: OtmId): Observable<string> {
        return this.organisationPipe.transform(organisationId).pipe(
            map((value: Organisation) => this.entityNamePipe.transform(value)),
            map((value: LocalizedString) => this.localizedStringPipe.transform(value)),
        );
    }
}
