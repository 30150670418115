/**
* @deprecated: Use this instead: "frontend-angular/projects/sis-common/webapp/lib/name/assessment-item-name.service.ts"
* */
'use strict';

(function () {
  assessmentItemNameService.$inject = ["localeService"];
  angular.module('sis-common.model.assessmentItemNameService', ['sis-common.l10n.localeService']).factory('assessmentItemNameService', assessmentItemNameService);

  /**
   * @ngInject
   */
  function assessmentItemNameService(localeService) {
    return {
      generateFullName: function (assessmentItem) {
        var fullName = {};
        var nameSpecifier = assessmentItem.nameSpecifier;
        nameSpecifier = !nameSpecifier || nameSpecifier === null ? {} : nameSpecifier;
        var name = assessmentItem.name;
        name = !name || name === null ? {} : name;
        var fallbackName;
        var fallbackNameSpecifier;
        _.forEach(localeService.supportedLocales, function (locale) {
          if (!fallbackNameSpecifier && nameSpecifier[locale] && nameSpecifier[locale].trim().length > 0) {
            fallbackNameSpecifier = nameSpecifier[locale];
          }
          if (!fallbackName && name[locale] && name[locale].trim().length > 0) {
            fallbackName = name[locale];
          }
        });
        fallbackName = fallbackName ? fallbackName : '';
        fallbackNameSpecifier = fallbackNameSpecifier ? fallbackNameSpecifier : '';
        _.forEach(localeService.supportedLocales, function (locale) {
          var nameSpecifierForLocale = nameSpecifier[locale];
          nameSpecifierForLocale = nameSpecifierForLocale && nameSpecifierForLocale.trim().length > 0 ? nameSpecifierForLocale : fallbackNameSpecifier;
          nameSpecifierForLocale += nameSpecifierForLocale.length === 0 ? '' : ', ';
          var nameForLocale = name[locale];
          nameForLocale = nameForLocale && nameForLocale.trim().length > 0 ? nameForLocale : fallbackName;
          fullName[locale] = _.trim(nameSpecifierForLocale + nameForLocale);
        });
        return fullName;
      }
    };
  }
})();