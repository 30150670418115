<div>
  <div class="row">
    <div class="col-md-6 form-group-mimic">
      <h4>{{'STUDENT_APPLICATIONS.STUDENT' | translate}}</h4>
      <p *ngIf="moduleContentWorkflow.studentId | privatePersonBasicInfo | async as student">
        {{student | personFullName}} ({{student.studentNumber}})
      </p>
    </div>
    <div class="col-md-6 form-group-mimic">
      <h4>{{'STUDENT_APPLICATIONS.STUDY_RIGHT' | translate}}</h4>
      <p>{{(moduleContentWorkflow.educationId | education | async)?.name | localizedString}}</p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 form-group-mimic">
      <h4>{{'SHOW_MODULE_CONTENT_APPROVAL_MODAL.STUDY_MODULE' | translate}}</h4>
      <p>{{(moduleContentWorkflow.approvedModuleId | module | async)?.name | localizedString}}</p>
    </div>
    <div class="col-md-6 form-group-mimic">
      <h4>{{'SHOW_MODULE_CONTENT_APPROVAL_MODAL.' + moduleContentWorkflow.type + '.APPLIED_ON' | translate }}</h4>
      <p>{{moduleContentWorkflow.metadata.createdOn | localDateFormat}}</p>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <h3>{{'SHOW_MODULE_CONTENT_APPROVAL_MODAL.' + moduleContentWorkflow.type + '.SITUATION_DESCRIPTION_TITLE' | translate}}</h3>
      <transloco key="{{'SHOW_MODULE_CONTENT_APPROVAL_MODAL.' + moduleContentWorkflow.type + '.SITUATION_DESCRIPTION.' + moduleContentWorkflow.state}}"
                 [params]="{modulename: (moduleContentWorkflow.approvedModuleId | module | async)?.name | localizedString}">
      </transloco>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 button-holder">
      <sis-button [hollow]=true
                  [variant]="'secondary'"
                  [size]="'sm'"
                  (clicked)="openPlan.emit()">
        <sis-icon [icon]="'new-tab'"></sis-icon>
        {{'SHOW_MODULE_CONTENT_APPROVAL_MODAL.SHOW_MODULE_IN_PLAN' | translate}}
      </sis-button>
      <span class="visually-hidden">{{'ARIA_LABEL.OPENS_TO_A_NEW_TAB' | translate}}</span>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
        <sis-module-content-workflow-structure [parentModule]="moduleContentWorkflowStructureData.module"
                                               [selectedCourseUnits]="moduleContentWorkflowStructureData.courseUnitSelections"
                                               [selectedModules]="moduleContentWorkflowStructureData.moduleSelections"
                                               [selectedCustomCourseUnitAttainments]="moduleContentWorkflowStructureData.customCourseUnitAttainments"
                                               [selectedCustomModuleAttainments]="moduleContentWorkflowStructureData.customModuleAttainments"
                                               [customStudyDrafts]="moduleContentWorkflow.customStudyDrafts"
                                               [attainments]="moduleContentWorkflowStructureData.attainments">
        </sis-module-content-workflow-structure>
      <ng-container *ngFor="let customStudyDraft of moduleContentWorkflow.customStudyDrafts">
        <sis-custom-study-draft-summary [customStudyDraft]="customStudyDraft">
        </sis-custom-study-draft-summary>
      </ng-container>
    </div>
  </div>
  <div class="row">
    <div class="col-12 form-group-mimic">
      <h4>{{'SHOW_MODULE_CONTENT_APPROVAL_MODAL.' + moduleContentWorkflow.type + '.APPLICATION_RATIONALE' | translate}}</h4>
      <p class="text-area-static">{{ moduleContentWorkflow.applicationRationale }}</p>
    </div>
  </div>
</div>
