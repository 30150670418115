'use strict';

angular.module('student.frontpage.studyProgressGraph').factory('studyProgressGraphDropDownContentFetcher', ["$q", "$log", "$filter", "educationsWithPlans", "moduleService", "planService", function ($q, $log, $filter, educationsWithPlans, moduleService, planService) {
  return {
    findEducationsWithPrimaryPlans: function () {
      return planService.findMyPlans().then(plans => educationsWithPlans.findMyPlans(plans)).then(function (educations) {
        educations = $filter('sortByLocalizedValue')(educations, 'name', false);
        return educationsWithPlans.filterEducationsWithActiveStudyRight(educations).then(function (educations) {
          var educationsWithPrimaryPlans = filterPrimaryPlans(educations);
          return getMenuItems(educationsWithPrimaryPlans);
        });
      });
    }
  };
  function filterPrimaryPlans(educations) {
    _.each(educations, function (education) {
      // should return primary plan but currently there are no primaries so returns the first if no primary found
      education.plan = _.find(education.plans, {
        primary: true
      }) || education.plans[0];
    });
    return educations;
  }
  function getMenuItems(educations) {
    var moduleIds = _.uniq(_.flatten(_.map(_.flatten(_.map(educations, 'plans')), getSelectedRootModuleIds)));
    return moduleService.findByIds(moduleIds).then(function (modules) {
      return _.map(educations, function (education) {
        return {
          education: education,
          plan: education.plan,
          modules: _.map(getSelectedRootModuleIds(education.plan), function (moduleId) {
            return _.find(modules, {
              id: moduleId
            });
          })
        };
      });
    });
  }
  // return only modules found from root
  function getSelectedRootModuleIds(plan) {
    return _.uniq(_.map(_.filter(plan.moduleSelections, function (selection) {
      return selection.parentModuleId === plan.rootId;
    }), 'moduleId'));
  }
}]);