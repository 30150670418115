<div class="mt-5" *ngIf="enrolment.courseUnitRealisationId | courseUnitRealisation | async as courseUnitRealisation">
  <h3>{{ studyGroupSetInfo.studyGroupSet.name | localizedString }}</h3>
  <ng-container *ngTemplateOutlet="studyGroupSetInfo.selectionType === 'MANDATORY' || studyGroupSetInfo.selectionType === 'NO_SELECTION' ? view : select; context: {
    courseUnitRealisation
  }"></ng-container>
</div>

<ng-template #view let-courseUnitRealisation="courseUnitRealisation">
  <h4 class="mt-4">{{ 'ENROLMENTS.ENROLMENT_MODAL.STUDY_GROUP.GUIDANCE_TITLE' | translate }}</h4>
  <ng-container [ngTemplateOutlet]="guidance"
                [ngTemplateOutletContext]="{
                  min: studyGroupSetInfo.studyGroupSet.subGroupRange.min,
                  max: studyGroupSetInfo.studyGroupSet.subGroupRange.max ? studyGroupSetInfo.studyGroupSet.subGroupRange.max : studyGroupSetInfo.availableStudySubGroupCount,
                  showAdditionalInfo: showAdditionalInfo(courseUnitRealisation)
                }"></ng-container>
  <h4 class="mt-4">{{ 'ENROLMENTS.ENROLMENT_MODAL.STUDY_GROUP.CHOOSE_STUDY_SUB_GROUPS' | translate }}</h4>
  <app-study-sub-group-container
    *ngFor="let studySubGroup of studyGroupSetInfo.studyGroupSet.studySubGroups"
    [isInCalendar]="isInCalendar(studySubGroup.id)"
    [allocation]="studyGroupSetInfo.studySubGroupAllocations.get(studySubGroup.id)"
    [enrolment]="enrolment"
    [studySubGroup]="studySubGroup"
    [control]="getStudySubGroup(studySubGroup.id)"
    [isMandatory]="studyGroupSetInfo.selectionType === 'MANDATORY' || studyGroupSetInfo.selectionType === 'NO_SELECTION'">
  </app-study-sub-group-container>
</ng-template>

<ng-template #select let-courseUnitRealisation="courseUnitRealisation">
  <div class="row mb-2 mt-4">
    <h4>{{ 'ENROLMENTS.ENROLMENT_MODAL.STUDY_GROUP.GUIDANCE_TITLE' | translate }}</h4>
    <ng-container [ngTemplateOutlet]="guidance"
                  [ngTemplateOutletContext]="{
                    min: studyGroupSetInfo.studyGroupSet.subGroupRange.min,
                    max: studyGroupSetInfo.studyGroupSet.subGroupRange.max ? studyGroupSetInfo.studyGroupSet.subGroupRange.max : studyGroupSetInfo.availableStudySubGroupCount,
                    showAdditionalInfo: showAdditionalInfo(courseUnitRealisation)
                  }"></ng-container>
  </div>

  <div class="row mb-5 mt-4" *ngIf="!isConfirmedSsgEdit && studyGroupSetInfo.selectionType === 'RANGE'">
    <label class="required"
           id="range-label">{{ 'ENROLMENTS.ENROLMENT_MODAL.STUDY_GROUP.CHOOSE_RANGE_VALUE' | translate }}
      <div class="col-2">
      <select aria-labelledby="range-label"
              [id]="studyGroupSetInfo.studyGroupSet.localId + '-targetStudySubGroupAmount'"
              [attr.aria-invalid]="targetStudySubGroupAmount.invalid && targetStudySubGroupAmount.touched"
              [attr.aria-describedby]="studyGroupSetInfo.studyGroupSet.localId + 'targetStudySubGroupAmount-error'"
              [formControl]="targetStudySubGroupAmount"
              class="form-select">
        <option [ngValue]="null"
                disabled selected>{{ 'ENROLMENTS.ENROLMENT_MODAL.STUDY_GROUP.CHOOSE_RANGE_VALUE_SELECT' | translate }}</option>
        <option [ngValue]="value" *ngFor="let value of studyGroupSetInfo.range">{{value}}</option>
      </select>
        <sis-validation-errors [id]="studyGroupSetInfo.studyGroupSet.localId + 'targetStudySubGroupAmount-error'" [control]="targetStudySubGroupAmount"></sis-validation-errors>
      </div>
    </label>
    </div>
    <fieldset [id]="studyGroupSetInfo.studyGroupSet.localId">
    <legend>
      <span>{{ 'ENROLMENTS.ENROLMENT_MODAL.STUDY_GROUP.CHOOSE_STUDY_SUB_GROUPS' | translate }}</span>
      <sis-contextual-notification *ngIf="showErrors && studySubGroups.invalid" variant="danger">
        {{ studySubGroups.errors?.invalidSubGroupRange?.translationKey | translate:studySubGroups.errors?.invalidSubGroupRange?.translationParams }}
      </sis-contextual-notification>
    </legend>
      <ul>
        <li *ngFor="let studySubGroup of studyGroupSetInfo.studyGroupSet.studySubGroups;">
          <app-study-sub-group-container
            *ngIf="getStudySubGroup(studySubGroup.id)"
            [allocation]="studyGroupSetInfo.studySubGroupAllocations.get(studySubGroup.id)"
            [isInCalendar]="isInCalendar(studySubGroup.id)"
            [isConfirmedSsgEdit]="isConfirmedSsgEdit"
            [enrolment]="enrolment"
            [studySubGroup]="studySubGroup"
            [control]="getStudySubGroup(studySubGroup.id)"
          >
          </app-study-sub-group-container>
        </li>
      </ul>
    </fieldset>
</ng-template>

<ng-template #guidance let-min="min" let-max="max" let-showAdditionalInfo="showAdditionalInfo">
  <ng-container *ngIf="studyGroupSetInfo.selectionType === 'MANDATORY' || studyGroupSetInfo.selectionType === 'NO_SELECTION'">
    <p>{{ studyGroupSetInfo.selectionType === 'NO_SELECTION' ? ('ENROLMENTS.ENROLMENT_MODAL.STUDY_GROUP.GUIDANCE_NO_SELECTION' | translate) : ('ENROLMENTS.ENROLMENT_MODAL.STUDY_GROUP.GUIDANCE_MANDATORY' | translate) }}</p>
  </ng-container>
  <ng-container *ngIf="isConfirmedSsgEdit">
    <p *ngIf="studyGroupSetInfo.selectionType === 'MIN'">{{ 'ENROLMENTS.ENROLMENT_MODAL.STUDY_GROUP.GUIDANCE_CONFIRMED_MIN' | translate: { count: min } }}</p>
    <p *ngIf="studyGroupSetInfo.selectionType === 'RANGE' && min === 0">{{ 'ENROLMENTS.ENROLMENT_MODAL.STUDY_GROUP.GUIDANCE_CONFIRMED_RANGE_0' | translate: { max: max } }}</p>
    <p *ngIf="studyGroupSetInfo.selectionType === 'RANGE' && min !== 0">{{ 'ENROLMENTS.ENROLMENT_MODAL.STUDY_GROUP.GUIDANCE_CONFIRMED_RANGE' | translate: { min: min, max: max } }}</p>
    <p *ngIf="studyGroupSetInfo.selectionType !== 'MANDATORY'">{{ 'ENROLMENTS.ENROLMENT_MODAL.STUDY_GROUP.GUIDANCE_CONFIRMED' | translate }}</p>
  </ng-container>
  <ng-container *ngIf="!isConfirmedSsgEdit">
    <ng-container *ngIf="studyGroupSetInfo.selectionType === 'MIN'">
      <p>{{ 'ENROLMENTS.ENROLMENT_MODAL.STUDY_GROUP.GUIDANCE_MIN_PART_1' | translate: {count: min} }}</p>
      <p>{{ 'ENROLMENTS.ENROLMENT_MODAL.STUDY_GROUP.GUIDANCE_MIN_PART_2' | translate }}</p>
      <p>{{ 'ENROLMENTS.ENROLMENT_MODAL.STUDY_GROUP.GUIDANCE_MIN_PART_3' | translate: {count: min} }}</p>
    </ng-container>
    <ng-container *ngIf="studyGroupSetInfo.selectionType === 'RANGE'">
      <p
        *ngIf="studyGroupSetInfo.studyGroupSet.subGroupRange.min === 0">{{ 'ENROLMENTS.ENROLMENT_MODAL.STUDY_GROUP.GUIDANCE_RANGE_0_PART_1' | translate: {count: max} }}</p>
      <p
        *ngIf="studyGroupSetInfo.studyGroupSet.subGroupRange.min !== 0">{{ 'ENROLMENTS.ENROLMENT_MODAL.STUDY_GROUP.GUIDANCE_RANGE_PART_1' | translate: {
        min: min,
        max: max
      } }}</p>
      <p>{{ 'ENROLMENTS.ENROLMENT_MODAL.STUDY_GROUP.GUIDANCE_RANGE_PART_2' | translate }}</p>
      <p>{{ 'ENROLMENTS.ENROLMENT_MODAL.STUDY_GROUP.GUIDANCE_RANGE_PART_3' | translate }}</p>
      <p>{{ 'ENROLMENTS.ENROLMENT_MODAL.STUDY_GROUP.GUIDANCE_RANGE_PART_4' | translate }}</p>
    </ng-container>
    <p *ngIf="showAdditionalInfo">{{ 'ENROLMENTS.ENROLMENT_MODAL.STUDY_GROUP.GUIDANCE_CONTINUOUS_AND_LATE' | translate }}</p>
  </ng-container>
</ng-template>
