import { Injectable } from '@angular/core';
import { StudyPeriod, StudyPeriodLocator } from 'common-typescript/types';
import { ParsedStudyPeriodLocator, StudyPeriodLocatorService } from 'sis-components/service/study-period-locator.service';

@Injectable({ providedIn: 'root' })
export class StudyTimingValidationService {
    constructor(private readonly _studyPeriodLocatorService: StudyPeriodLocatorService) {}

    /**
     * Returns those of {@link studies} (in the original order), that either:
     * * haven't been timed
     * OR
     * * have been timed before {@link currentStudyPeriod}, that is provided and has a valid {@link StudyPeriod.locator}.
     */
    validateStudyTimings<T extends TimeableStudy>(studies: readonly T[], currentStudyPeriod: StudyPeriod | null): StudyTimingValidationResult<T> {
        // we can run existence validation even without the current study period
        const parsedCurrentStudyPeriodLocator: ParsedStudyPeriodLocator | null = currentStudyPeriod
            ? this._studyPeriodLocatorService.parseStudyPeriodLocator(currentStudyPeriod.locator)
            : null;

        const studiesWithNoTiming: T[] = [];
        const studiesWithTimingInThePast: T[] = [];

        for (const study of studies) {
            // It should be a rare or non-existing corner case, that a period's locator has wrong encoding,
            // but if it should happen, let's ignore such periods.
            const parsedPlannedPeriodLocators: ParsedStudyPeriodLocator[] | undefined = study.plannedPeriods
                ?.map(plannedPeriod => this._studyPeriodLocatorService.parseStudyPeriodLocator(plannedPeriod))
                .filter(locator => !!locator);

            if (!parsedPlannedPeriodLocators?.length) {
                studiesWithNoTiming.push(study);
            } else if (this.isTimingInThePast(parsedPlannedPeriodLocators, parsedCurrentStudyPeriodLocator)) {
                studiesWithTimingInThePast.push(study);
            }
        }

        return <StudyTimingValidationResult<T>>{
            studiesWithNoTiming,
            studiesWithTimingInThePast,
        };
    }

    private isTimingInThePast(parsedPlannedPeriodLocators: readonly ParsedStudyPeriodLocator[], parsedCurrentStudyPeriodLocator: ParsedStudyPeriodLocator | null): boolean {
        // It should be a rare or non-existing corner case, that we cannot parse the current study period's locator,
        // so there's really no right answer to what we should do in such a case.
        if (!parsedCurrentStudyPeriodLocator) {
            return false;
        }

        // We're not making any assumptions about the order, so let's just check if all of them are in the future.
        return parsedPlannedPeriodLocators.every(parsedPlannedPeriodLocator => this._studyPeriodLocatorService.isStudyPeriodEarlier(parsedPlannedPeriodLocator, parsedCurrentStudyPeriodLocator));
    }
}

export interface TimeableStudy {
    /**
     * Locators of planned periods in any order.
     */
    readonly plannedPeriods: readonly StudyPeriodLocator[] | null;
}

export interface StudyTimingValidationResult<T extends TimeableStudy> {
    readonly studiesWithNoTiming: readonly T[];
    readonly studiesWithTimingInThePast: readonly T[];
}
