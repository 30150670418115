import { ConfirmDialogService } from 'sis-components/confirm/confirm-dialog.service.ts';
import { IconComponent } from 'sis-components/icon/icon.component.ts';
import 'sis-components/applications/moduleContentApplicationStructure.component';
import 'sis-common/auth/auth.module';
import 'sis-common/auth/auth.service';
import 'sis-common/l10n/l10n.module';
import './header/ruleHeaderText.module';
import './../../common/context/uiContext';
import './../../common/service/minorStudyRight.service';
import './../../common/service/moduleService';
import './../../common/components/module-box/moduleBox.component';
import './../../common/components/course-unit-info-modal/courseUnitInfoModal';
import 'sis-components/range/rangeFilter';
import './../minorSelectionModal/minorSelectionModal.service';
import './ruleSearchParameters.service';
import 'sis-components/sharedModel/shared.module';
import './../../common/components/inline-search/inlineSearch';
import 'sis-components/attainment/customCourseUnitAttainmentBox/customCourseUnitAttainmentBox.component';
import 'sis-components/attainment/customModuleAttainmentBox/customModuleAttainmentBox.component';
import './../../common/service/rawPlanEdit.service';
import 'sis-components/service/studentApplication.service';
import 'sis-components/applications/moduleContentApplicationStructure.component';
import './../../common/components/show-view-module-content-application-link/showViewModuleContentApplicationLink.component';
import 'sis-components/service/studyRight.service';
import './../planStudyRightModal/planStudyRightModal.service';
import 'sis-components/planStudyRight/planStudyRight.service';
import 'sis-components/service/rule.service';
import 'sis-components/service/validAttainmentFilter.service';
import 'sis-components/customStudyDraft/customStudyDraft.component';
import 'sis-components/customStudyDraft/customStudyDraftInfoModal.service';
import { ReadMoreNgComponent } from 'sis-components/read-more-ng/read-more-ng.component.ts';
import './any-course-unit-rule-ripa-checkbox/any-course-unit-rule-ripa-checkbox.component.ts';
import { localizedMarkupStringFilterModule } from 'sis-components/string/localizedMarkupString.filter';
import { commonUniversityServiceModule } from 'sis-common/university/university.service.ts';
import { AnyCourseUnitRuleRipaCheckboxComponent } from './any-course-unit-rule-ripa-checkbox/any-course-unit-rule-ripa-checkbox.component.ts';
(function () {
  angular.module('student.plan.rules', ['sis-common.l10n', 'student.plan.rules.header', 'student.common.context.uiContext', 'student.common.service.minorStudyRightService', 'student.common.service.moduleService', 'student.common.components.moduleBox', 'student.common.components.courseUnitInfoModal', 'sis-components.rangeFilter', 'student.plan.minorSelectionModalService', 'student.plan.ruleSearchParametersService', 'student.shared', 'student.common.components.inlineSearch', 'sis-components.attainment.customCourseUnitAttainmentBox', 'sis-components.attainment.customModuleAttainmentBox', 'student.common.service.rawPlanEdit', 'sis-components.applications.moduleContentApplicationStructure', 'sis-common.auth', 'student.common.components.showViewModuleContentApplicationLink', 'sis-components.service.studyRightService', 'student.plan.planStudyRightModal', 'sis-components.service.planStudyRightService', 'sis-components.service.ruleService', 'sis-components.service.validAttainmentFilterService', ConfirmDialogService.downgrade.moduleName, IconComponent.downgrade.moduleName, localizedMarkupStringFilterModule, commonUniversityServiceModule, AnyCourseUnitRuleRipaCheckboxComponent.downgrade.moduleName, ReadMoreNgComponent.downgrade.moduleName]);
})();