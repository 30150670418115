<div class="container">
  <div class="row">
    <div class="col-12 col-md-3">
      <div class="title-container">
        <h1 id="skip-link-target" tabindex="-1" class="fake-crumbs" [translate]="'SIS_COMPONENTS.MESSENGER.MESSAGE_CONVERSATIONS.MESSAGE_CONVERSATIONS_LIST_VIEW_TITLE'">Viestit ja ilmoitukset</h1>
      </div>
      <div class="stack-header-menu">
        <h2>
          <button type="button"
                  class="menu-option"
                  [ngClass]="{'selected': messageConversationsSearch.messageCategoryType === null && messageConversationsSearch.isArchived === false}"
                  (click)="setViewStateToMessagesAndNotifications()">
            <span [translate]="'SIS_COMPONENTS.MESSENGER.MESSAGE_CONVERSATIONS.RECEIVED'">Saapuneet</span>
          </button>
        </h2>
        <h3>
          <button type="button"
                  class="menu-option menu-child-option"
                  [ngClass]="{'selected': messageConversationsSearch.messageCategoryType === 'MESSAGE'}"
                  (click)="setViewStateToMessages()">
            <span [translate]="'SIS_COMPONENTS.MESSENGER.MESSAGE_CONVERSATIONS.MESSAGES'">Viestit</span>
          </button>
        </h3>
        <h3>
          <button type="button"
                  class="menu-option menu-child-option"
                  [ngClass]="{'selected': messageConversationsSearch.messageCategoryType === 'NOTIFICATION'}"
                  (click)="setViewStateToNotifications()">
            <span [translate]="'SIS_COMPONENTS.MESSENGER.MESSAGE_CONVERSATIONS.NOTIFICATIONS'">Ilmoitukset</span>
          </button>
        </h3>
      </div>
      <div class="stack-header-menu">
        <h2>
          <button type="button"
                  class="menu-option" [ngClass]="{'selected': messageConversationsSearch.isArchived === true}"
                  (click)="setViewStateToArchived()">
            <span [translate]="'SIS_COMPONENTS.MESSENGER.MESSAGE_CONVERSATIONS.ARCHIVED'">Arkistoidut</span>
          </button>
        </h2>
      </div>
    </div>
    <div class="col-12 col-md-9">
      <div class="search-and-results">
        <div class="row">
          <div class="col-12 col-md-10 col-lg-9 col-xl-8 search-input-field">
            <div class="search-group">
              <sis-input-with-debounce name="fullTextQuery"
                                       [value]="messageConversationsSearch.studentSearch"
                                       (valueChange)="onFullTextQueryChange($event)"
                                       [debounce]="700"
                                       classes="form-control"
                                       placeholder="{{'SIS_COMPONENTS.MESSENGER.MESSAGE_CONVERSATIONS.TEXT_SEARCH_PLACEHOLDER_TEXT' | translate}}">
              </sis-input-with-debounce>
              <sis-icon icon="search" class="search-with-svg-icon" aria-hidden="true"></sis-icon>
              <search-filter-options-menu class="input-group-btn"
                                          (onSelect)="onSearchParameterChange($event)"
                                          (searchAgain)="syncSearchParametersAndSearch()"
                                          [searchParameterOptions]="searchParameterOptions"
                                          [searchParams]="searchParameters">
              </search-filter-options-menu>
            </div>
            <div class="row">
              <div class="col-12 search-filters">
                <display-selected-parameters (onParameterChange)="onSearchParameterChange($event)"
                                             (searchAgain)="syncSearchParametersAndSearch()"
                                             [selectableParameters]="searchParameterOptions"
                                             [searchParams]="searchParameters">
                </display-selected-parameters>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="messages-container">
        <div class="results-header row g-0">
          <div class="action-container col-2">
            <div class="checkbox-container">
              <sis-checkbox [checked]="allSelected" (checkedChange)="toggleSelectAll()"></sis-checkbox>
            </div>
            <sis-menu-button [options]="dropdownMenuOptions" [placeLeft]="true"></sis-menu-button>
          </div>
          <div class="guidance col-lg-6 col-12">
            <span *ngIf="result && result.total > 1 && !isFilteredResult()">
                {{'SIS_COMPONENTS.MESSENGER.MESSAGE_CONVERSATIONS.FOUND_SEVERAL_RESULTS' | translate:{count: result.total} }}
            </span>
            <span *ngIf="result && result.total === 1 && !isFilteredResult()"
                  translate="SIS_COMPONENTS.MESSENGER.MESSAGE_CONVERSATIONS.FOUND_ONE_RESULT">
            </span>
            <span *ngIf="result && result.total === 0 && !isFilteredResult()"
                  translate="SIS_COMPONENTS.MESSENGER.MESSAGE_CONVERSATIONS.FOUND_NO_RESULTS">
            </span>
            <span *ngIf="result && result.total > 1 && isFilteredResult()">
                {{'SIS_COMPONENTS.MESSENGER.MESSAGE_CONVERSATIONS.FOUND_SEVERAL_FILTERED_RESULTS' | translate:{count: result.total} }}
            </span>
            <span *ngIf="result && result.total === 1 && isFilteredResult()"
                  translate="SIS_COMPONENTS.MESSENGER.MESSAGE_CONVERSATIONS.FOUND_ONE_FILTERED_RESULT">
            </span>
            <span *ngIf="result && result.total === 0 && isFilteredResult()"
                  translate="SIS_COMPONENTS.MESSENGER.MESSAGE_CONVERSATIONS.FOUND_NO_FILTERED_RESULTS">
            </span>
          </div>
        </div>
        <div class="results-container">
          <sis-message-conversation-list-item [hidden]="!result || result.total === 0"
                                              [messageConversations]="conversations$ | async"
                                              (moveToMessageConversationView)="goToMessageConversation($event)"
                                              [selectedMessageConversationIds]="selectedMessageConversationIds"
                                              (toggleConversationSelection)="toggleConversationSelection($event)">

          </sis-message-conversation-list-item>

          <sis-pagination *ngIf="totalItems > itemsPerPage"
                          [currentPage]="currentPage"
                          (currentPageChange)="onPageChange($event)"
                          [totalItems]="totalItems"
                          [itemsPerPage]="itemsPerPage">
          </sis-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
