import { Pipe, PipeTransform } from '@angular/core';
import _ from 'lodash';

@Pipe({ name: 'percent' })
export class PercentPipe implements PipeTransform {

    transform(value: any): string {
        if (_.isNumber(value)) {
            return `${_.round(100 * value, 0)} %`;
        }
        return '';
    }

}
