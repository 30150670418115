import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChildren,
    ViewEncapsulation,
} from '@angular/core';
import { StateService } from '@uirouter/core';
import {
    CompletionMethod,
    CourseUnit,
    LocalId,
    OpenUniversityCart,
    OpenUniversityProduct,
    OtmId,
} from 'common-typescript/types';
import _ from 'lodash';
import { EMPTY, forkJoin, Observable, of, Subject } from 'rxjs';
import { map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { EnrichedAssessmentItemsForOpenUniProductQueryService } from 'sis-common/generated/graphql';
import { graphqlUtils } from 'sis-common/graphql/graphqlUtils';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { ExpandableComponent } from 'sis-components/expandables/expandable/expandable.component';
import { CourseUnitEntityService } from 'sis-components/service/course-unit-entity.service';
import { OpenUniversityCartCustomerService } from 'sis-components/service/open-university-cart-customer.service';
import { OpenUniversityProductEntityService } from 'sis-components/service/open-university-product-entity.service';

import { OpenUniversityEnrichedEnrolmentsService } from '../../common/service/open-university-enriched-enrolments.service';
import { OpenUniversityModalService } from '../../common/service/open-university-modal.service';
import {
    EnrichedAssItem,
    EnrichedEnrolment,
} from '../../open-university-enrolment-wizard/open-university-enrolment-wizard.component';
import { OpenUniversityErrorHelperService } from '../../service/open-university-error-helper.service';

@Component({
    selector: 'app-cart-accordions',
    templateUrl: './cart-accordions.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CartAccordionsComponent implements OnDestroy, OnInit {

    @Input() cart: OpenUniversityCart;
    @Input() isEditable? = false;
    @Input() checkEnrolments$: Subject<void>;
    @Output() hasValidEnrolments = new EventEmitter<void>();

    assessmentItemsData: EnrichedAssItem[] = [];
    enrolmentsByCartItemId: { [key: string]: EnrichedEnrolment[] } = {};
    productsByCartItemId: { [key: string]: OpenUniversityProduct } = {};
    courseUnitsByCartItemId: { [key: string]: CourseUnit } = {};
    assessmentItemIdsByCartItemId: { [key: string]: OtmId[] } = {};
    errorCartItemIds: LocalId[] = [];
    isValidByCartItemId: { [key: string]: boolean } = {};
    products: OpenUniversityProduct[] = [];
    cuIdsAndCmIdsOfProducts: { courseUnitId: OtmId; completionMethodId: LocalId }[] = [];
    assessmentItemIdsOfProducts: OtmId[] = [];
    loaded = false;
    requestInProgress: boolean;
    destroyed$ = new Subject<void>();

    @ViewChildren('selectionExpandable') expandables: QueryList<ExpandableComponent>;

    constructor( // NOSONAR
        private openUniversityCartCustomerService: OpenUniversityCartCustomerService,
        private openUniversityErrorHelper: OpenUniversityErrorHelperService,
        private openUniversityModalService: OpenUniversityModalService,
        private openUniversityProductEntityService: OpenUniversityProductEntityService,
        private courseUnitEntityService: CourseUnitEntityService,
        private assessmentItemsQueryService: EnrichedAssessmentItemsForOpenUniProductQueryService,
        private openUniversityEnrichedEnrolmentsService: OpenUniversityEnrichedEnrolmentsService,
        private state: StateService,
        private appErrorHandler: AppErrorHandler,
    ) {
    }

    ngOnInit(): void {
        this.updateProducts().pipe(
            switchMap((products: OpenUniversityProduct[]) => this.updateCourseUnits(products)),
            switchMap((courseUnits: CourseUnit[]) => this.updateAssessmentItems(courseUnits)),
            switchMap((assessmentItemsData: EnrichedAssItem[]) => this.getOpenUniStudentEnrolments(assessmentItemsData)),
            tap(() => this.validateCartItems()),
            takeUntil(this.destroyed$),
            this.appErrorHandler.defaultErrorHandler(),
        ).subscribe({
            next: () => {
                this.loaded = true;
            },
        });
        this.subscribeEnrolmentCheck();
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
    }

    subscribeEnrolmentCheck() {
        if (this.checkEnrolments$) {
            this.checkEnrolments$.pipe(
                takeUntil(this.destroyed$),
            ).subscribe(() => {
                this.checkEnrolmentsAndOpenErrorExpandables();
            });
        }
    }

    updateProducts(): Observable<OpenUniversityProduct[]> {
        const productIds: OtmId[] = _.uniq(this.cart.items.map(item => item.openUniversityProductId));
        return this.openUniversityProductEntityService.getByIds(productIds)
            .pipe(
                tap((products: OpenUniversityProduct[]) => {
                    this.cart.items.forEach(item => {
                        this.productsByCartItemId[item.localId] =
                            products.find(product => product.id === item.openUniversityProductId);
                    });
                    // completionMethodId is not unique (localId), so we have to take a pair of courseUnitId and completionMethodId,
                    // so we can filter necessary completeMethods later
                    this.cuIdsAndCmIdsOfProducts = products.map(p => ({ courseUnitId: p.courseUnitId, completionMethodId: p.completionMethodId }));
                    this.products = products;
                }),
            );
    }

    updateCourseUnits(products: OpenUniversityProduct[]): Observable<CourseUnit[]> {
        const courseUnitIds: OtmId[] = _.uniq(products.map(product => product.courseUnitId));
        return this.courseUnitEntityService.getByIds(courseUnitIds)
            .pipe(
                tap((courseUnits: CourseUnit[]) => {
                    Object.entries(this.productsByCartItemId).forEach(([cartItemId, product]) => {
                        this.courseUnitsByCartItemId[cartItemId] = courseUnits.find(cu => cu.id === product.courseUnitId);
                        this.assessmentItemIdsByCartItemId[cartItemId] =
                            this.courseUnitsByCartItemId[cartItemId].completionMethods
                                .find(cm => cm.localId === product.completionMethodId)?.assessmentItemIds;
                    });
                }),
            );
    }

    updateAssessmentItems(courseUnits: CourseUnit[]): Observable<EnrichedAssItem[]> {
        this.assessmentItemIdsOfProducts = [...new Set(courseUnits.flatMap(
            cu => cu.completionMethods
                .filter(cm => Boolean(this.cuIdsAndCmIdsOfProducts.find(o => o.courseUnitId === cu.id && o.completionMethodId === cm.localId)))
                .flatMap(completionMethod => completionMethod.assessmentItemIds)),
        )];
        return this.assessmentItemsQueryService.fetch({
            assessmentItemIds: this.assessmentItemIdsOfProducts,
        }).pipe(
            takeUntil(this.destroyed$),
            map(rawAssItemData => graphqlUtils.removeTypeNames(rawAssItemData.data.searchResult)),
            tap((assItemData) => {
                this.assessmentItemsData = assItemData;
            }),
        );
    }

    getOpenUniStudentEnrolments(assessmentItemsData: EnrichedAssItem[]): Observable<EnrichedEnrolment[][]> {
        const requests = this.products.map((product) =>
            this.openUniversityEnrichedEnrolmentsService.getExistingEnrolmentsForCartItem(assessmentItemsData, this.cart, product)
                .pipe(
                    take(1),
                    tap((enrolments) => {
                        const cartItemId = this.cart.items.find(item => item.openUniversityProductId === product.id).localId;
                        this.enrolmentsByCartItemId[cartItemId] = enrolments;
                    }),
                ));
        return forkJoin(requests);
    }

    getCompletionMethod(courseUnit: CourseUnit, product: OpenUniversityProduct): CompletionMethod {
        return courseUnit?.completionMethods
            ?.find(cm => product.completionMethodId === cm.localId && cm.studyType === 'OPEN_UNIVERSITY_STUDIES');
    }

    removeItemFromCart(productId: OtmId, courseUnitId: OtmId): void {
        this.requestInProgress = true;
        this.validateNoPaymentInProgress()
            .pipe(
                switchMap(() => this.openUniversityModalService.openRemoveProductFromCartConfirmation(courseUnitId)),
                switchMap(() => this.openUniversityCartCustomerService.removeProductFromCurrentCart(productId)),
                take(1),
                this.appErrorHandler.defaultErrorHandler(),
            )
            .subscribe(() => this.state.reload())
            .add(() => this.requestInProgress = false);
    }

    goEnrolmentWizard(courseUnit: CourseUnit, product: OpenUniversityProduct): void {
        this.state.go(
            'student.course-unit.open-university-enrolment-wizard',
            { courseUnitId: courseUnit.id, openUniversityCartId: this.cart?.id, openUniversityProductId: product.id },
        );
    }

    validateCartItems(): void {
        // Check that person has enrolled for all assessmentItems.
        // If not, put cartItemId to the error list
        this.errorCartItemIds = Object.entries(this.assessmentItemIdsByCartItemId)
            .filter(([cartItemId, aiIds]) => {
                const enrolmentAiIds = this.enrolmentsByCartItemId[cartItemId].map(e => e.assessmentItemId);
                return !aiIds.every(aiId => enrolmentAiIds.includes(aiId));
            })
            .map(([cartItemId]) => cartItemId);

        this.cart.items.forEach(item => {
            // we use this data structure in html template, so we avoid unnecessary rendering of the page
            this.isValidByCartItemId[item.localId] = !this.errorCartItemIds.includes(item.localId);
        });
    }

    checkEnrolmentsAndOpenErrorExpandables(): void {
        if (!this.loaded) return;

        if (this.errorCartItemIds.length === 0) {
            this.hasValidEnrolments.emit();
            return;
        }
        this.expandables.forEach((item) => {
            if (this.errorCartItemIds.includes(item.ref.nativeElement.id)) {
                item.closed = false;
            }
        });
    }

    private validateNoPaymentInProgress(): Observable<void | never> {
        return this.openUniversityCartCustomerService.isPaymentInProgress()
            .pipe(
                take(1),
                tap(isPaymentInProgress => isPaymentInProgress && this.openUniversityErrorHelper.showPaymentInProgressFailureAlert()),
                switchMap(isPaymentInProgress => isPaymentInProgress ? EMPTY : of(null)),
            );
    }
}
