import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { OtmId, PaymentSystemLog } from 'common-typescript/types';
import { NGXLogger } from 'ngx-logger';
import { EMPTY, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { EntityService } from '../service/entity.service';

const CONFIG = {
    ENDPOINTS: {
        backend: '/ilmo/api/payment-system-log',
        createAnonymous() {
            return '/ilmo/api/open-university/payment-system-log';
        },
    },
};

@Injectable({ providedIn: 'root' })
@NgEntityServiceConfig({
    baseUrl: CONFIG.ENDPOINTS.backend,
})
export class PaymentSystemLogEntityService extends EntityService<PaymentSystemLogEntityState> {

    constructor(private logger: NGXLogger) {
        super(PaymentSystemLogEntityStore, PaymentSystemLogEntityQuery);
    }

    create(paymentSystemLog: Partial<PaymentSystemLog>) {
        this.getHttp().post(CONFIG.ENDPOINTS.createAnonymous(), paymentSystemLog)
            .pipe(
                catchError((err) => {
                    this.logger.error('Failed to post payment system log: ', CONFIG.ENDPOINTS.createAnonymous(), err);
                    return EMPTY;
                }),
            )
            .subscribe();
    }

    getAllById(paymentId: OtmId): Observable<PaymentSystemLog[]> {
        return this.getHttp().get(CONFIG.ENDPOINTS.backend, { params: { paymentId } })
            .pipe(
                map((logs: PaymentSystemLog[]) =>
                    logs.map(log => ({ ...log, jsonLog: log.jsonLog === '' ? '' : JSON.parse(log.jsonLog) }))),
                catchError((err) => {
                    this.logger.error('Failed to getAll payment system logs: ', CONFIG.ENDPOINTS.backend, err);
                    throw err;
                }),
            ) as Observable<PaymentSystemLog[]>;
    }
}

type PaymentSystemLogEntityState = EntityState<PaymentSystemLog>;

@StoreConfig({ name: 'payment-system-log-entity' })
class PaymentSystemLogEntityStore extends EntityStore<PaymentSystemLogEntityState> {
}

class PaymentSystemLogEntityQuery extends QueryEntity<PaymentSystemLogEntityState> {
    constructor(protected store: PaymentSystemLogEntityStore) {
        super(store);
    }
}
