import _ from 'lodash';
import angular from 'angular';
import moment from 'moment';
import { dateUtils, ValidatablePlan } from 'common-typescript';
import { commonUniversityServiceModule } from 'sis-common/university/university.service.ts';
import '../model/plan.model';
import '../model/courseUnit.model';
import '../model/module.model';
import '../model/assessmentItem.model';
import '../model/education.model';
import '../model/curriculumPeriod.model';
import '../sharedModel/shared.module';
import './assessmentItem.service';
import './courseUnit.service';
import './education.service';
import './jsDataRelationHelper.service';
import './module.service';
import './planAttainments.service';
import './studentApplication.service';
import './studentAttainment.service';
import './studyPeriod.service';
import './studyTerm.service';
import { commonStudyRightServiceModule } from 'sis-components/service/studyRight.service';
import { courseUnitRealisationServiceModule } from 'sis-components/service/courseUnitRealisation.service';
export const commonPlanServiceModule = 'sis-components.service.planService';
(function () {
  commonPlanService.$inject = ["$log", "$q", "$rootScope", "planJSDataModel", "commonEducationService", "planAttainmentsService", "jsDataRelationHelperService", "courseUnitJSDataModel", "planModuleJSDataModel", "assessmentItemJSDataModel", "jsDataCacheHelper", "educationJSDataModel", "curriculumPeriodModel", "commonCourseUnitRealisationService", "commonStudentApplicationService", "commonStudyPeriodService", "commonStudyRightService", "commonStudyTermService", "universityService", "PLAN_URL", "planServiceEventType", "Range"];
  angular.module(commonPlanServiceModule, [courseUnitRealisationServiceModule, commonUniversityServiceModule, commonStudyRightServiceModule, 'sis-components.model.plan', 'sis-components.model.courseUnit', 'sis-components.model.module', 'sis-components.model.assessmentItem', 'sis-components.model.education', 'sis-components.model.curriculumPeriod', 'sis-components.service.planAttainmentsService', 'sis-components.service.educationService', 'sis-components.service.courseUnitService', 'sis-components.service.moduleService', 'sis-components.service.studentAttainmentService', 'sis-components.service.assessmentItemService', 'sis-components.service.jsDataRelationHelper', 'sis-components.service.studentApplicationService', 'sis-components.service.studyPeriodService', 'sis-components.service.studyTermService', 'student.shared']).constant('planServiceEventType', {
    planSaved: 'planServiceEventType.planSaved'
  }).factory('commonPlanService', commonPlanService);

  /**
   * @ngInject
   */
  function commonPlanService(
  // NOSONAR
  $log, $q, $rootScope, planJSDataModel, commonEducationService, planAttainmentsService, jsDataRelationHelperService, courseUnitJSDataModel, planModuleJSDataModel, assessmentItemJSDataModel, jsDataCacheHelper, educationJSDataModel, curriculumPeriodModel, commonCourseUnitRealisationService, commonStudentApplicationService, commonStudyPeriodService, commonStudyRightService, commonStudyTermService, universityService, PLAN_URL, planServiceEventType, Range) {
    function allAssessmentItemsInProgressOrAttained(validatablePlan, assessmentItemIds, enrolments) {
      for (let i = 0; i < assessmentItemIds.length; i++) {
        const assessmentItemId = assessmentItemIds[i];
        const isAttained = validatablePlan.isAssessmentItemAttained(assessmentItemId);
        const isInProgress = api.private.assessmentItemHasActiveEnrolment(assessmentItemId, enrolments);
        if (!isAttained && !isInProgress) {
          return false;
        }
      }
      return true;
    }
    function assessmentItemHasActiveEnrolment(assessmentItemId, enrolments) {
      if (_.isEmpty(enrolments)) {
        return false;
      }
      const activeEnrolmentStates = ['ENROLLED', 'NOT_ENROLLED', 'PROCESSING'];
      for (let i = 0; i < enrolments.length; i++) {
        const enrolment = enrolments[i];
        if (enrolment && _.includes(activeEnrolmentStates, enrolment.state) && enrolment.assessmentItemId === assessmentItemId) {
          return true;
        }
      }
      return false;
    }
    function findAssessmentItemsThatNeedTeaching(courseUnits, validatablePlan) {
      return _.chain(courseUnits).flatMap(courseUnit => {
        const selectedCompletionMethod = validatablePlan.getSelectedCompletionMethod(courseUnit.id);
        if (selectedCompletionMethod) {
          return selectedCompletionMethod.assessmentItemIds;
        }
        return _.chain(courseUnit.completionMethods).flatMap('assessmentItemIds').compact().uniq().value();
      }).compact().uniq().value();
    }
    function assessmentItemHasTeaching(assessmentItemsThatNeedTeaching, assessmentItemsThatHaveActiveTeaching) {
      for (let i = 0; i < assessmentItemsThatNeedTeaching.length; i++) {
        const studentsAssessmentItemThatNeedTeaching = assessmentItemsThatNeedTeaching[i];
        if (_.includes(assessmentItemsThatHaveActiveTeaching, studentsAssessmentItemThatNeedTeaching)) {
          return true;
        }
      }
      return false;
    }
    function setHasTeachingForCourseUnit(courseUnitThatNeedsTeaching, assessmentItemsThatHaveActiveTeaching, enrolments, validatablePlan) {
      const selectedCompletionMethod = validatablePlan.getSelectedCompletionMethod(courseUnitThatNeedsTeaching.id);
      let selectableAssessmentItemsForStudent;
      if (selectedCompletionMethod) {
        selectableAssessmentItemsForStudent = selectedCompletionMethod.assessmentItemIds;
      } else {
        selectableAssessmentItemsForStudent = _.flatMap(courseUnitThatNeedsTeaching.completionMethods, 'assessmentItemIds');
      }
      selectableAssessmentItemsForStudent = _.chain(selectableAssessmentItemsForStudent).compact().uniq().value();
      const studentsAssessmentItemsThatNeedTeaching = _.filter(selectableAssessmentItemsForStudent, assessmentItemId => {
        const isAttained = validatablePlan.isAssessmentItemAttained(assessmentItemId);
        const isInProgress = api.private.assessmentItemHasActiveEnrolment(assessmentItemId, enrolments);
        return !isAttained && !isInProgress;
      });
      courseUnitThatNeedsTeaching.$hasTeaching = api.private.assessmentItemHasTeaching(studentsAssessmentItemsThatNeedTeaching, assessmentItemsThatHaveActiveTeaching);
    }
    function findAssessmentItemsThatHaveActiveTeaching(courseUnitsThatNeedTeaching, validatablePlan) {
      if (_.isEmpty(courseUnitsThatNeedTeaching)) {
        return $q.when([]);
      }
      const assessmentItemsThatNeedTeaching = api.private.findAssessmentItemsThatNeedTeaching(courseUnitsThatNeedTeaching, validatablePlan);
      return commonCourseUnitRealisationService.findActiveCourseUnitRealisationsForAssessmentItemIds(assessmentItemsThatNeedTeaching, false).then(courseUnitRealisations => _.chain(courseUnitRealisations).flatMap('assessmentItemIds').compact().uniq().value());
    }
    function setHasTeachingForCourseUnits(courseUnitsThatNeedTeaching, validatablePlan, enrolments) {
      return api.private.findAssessmentItemsThatHaveActiveTeaching(courseUnitsThatNeedTeaching, validatablePlan).then(assessmentItemsThatHaveActiveTeaching => {
        _.forEach(courseUnitsThatNeedTeaching, courseUnitThatNeedsTeaching => {
          api.private.setHasTeachingForCourseUnit(courseUnitThatNeedsTeaching, assessmentItemsThatHaveActiveTeaching, enrolments, validatablePlan);
        });
        return courseUnitsThatNeedTeaching;
      });
    }
    function resolveStudyRight(preLoadedPlanData, selectedPlan) {
      return commonStudyRightService.getValidStudyRightsByPersonIdEducationIds(selectedPlan.userId, false, [selectedPlan.rootId]).then(studyRights => _.find(studyRights, sr => sr.learningOpportunityId === selectedPlan.learningOpportunityId)).then(studyRight => {
        preLoadedPlanData.studyRight = studyRight;
      });
    }
    function preLoadedPlanDataForNonPreviewMode(preLoadedPlanData, selectedPlan, ignoreModuleContentApprovals) {
      const preLoads = [];

      // Education
      preLoads.push(commonEducationService.findById(selectedPlan.rootId, false).then(education => {
        preLoadedPlanData.planEducation = education;
      }));

      // Module content approvals
      preLoadedPlanData.moduleContentApprovals = [];
      if (!ignoreModuleContentApprovals) {
        preLoads.push(commonStudentApplicationService.getApplicationsForStudentByTypes(selectedPlan.userId, ['CustomModuleContentApplication', 'RequiredModuleContentApplication']).then(moduleContentApprovals => {
          preLoadedPlanData.moduleContentApprovals = moduleContentApprovals;
        }));
      }

      // Course units
      preLoads.push(planAttainmentsService.getAllCourseUnitAttainments(selectedPlan).then(planCourseUnitAttainments => {
        preLoadedPlanData.planCourseUnitAttainments = planCourseUnitAttainments;
        const courseUnitIds = _.chain(selectedPlan.courseUnitSelections).union(planCourseUnitAttainments).map('courseUnitId').value();
        return jsDataCacheHelper.findByIds(courseUnitJSDataModel, courseUnitIds).then(planCourseUnits => {
          preLoadedPlanData.planCourseUnits = planCourseUnits;
        });
      }));

      // Modules
      preLoads.push(planAttainmentsService.getAllModuleAttainments(selectedPlan).then(planModuleAttainments => {
        preLoadedPlanData.planModuleAttainments = planModuleAttainments;
        const moduleSelections = _.filter(selectedPlan.moduleSelections, 'parentModuleId');
        const moduleIds = _.chain(planModuleAttainments).union(moduleSelections).map('moduleId').value();
        return jsDataCacheHelper.findByIds(planModuleJSDataModel, moduleIds).then(planModules => {
          preLoadedPlanData.planModules = planModules;
        });
      }));

      // Assessment items
      const assessmentItemSelectionIds = _.map(selectedPlan.assessmentItemSelections, 'assessmentItemId');
      preLoads.push(jsDataCacheHelper.findByIds(assessmentItemJSDataModel, assessmentItemSelectionIds).then(planAssessmentItems => {
        preLoadedPlanData.planAssessmentItems = planAssessmentItems;
      }));

      // Student attainments
      preLoads.push(planAttainmentsService.getAllAttainments(selectedPlan).then(studentAttainments => {
        preLoadedPlanData.studentAttainments = studentAttainments;
      }));

      // Study right
      preLoads.push(resolveStudyRight(preLoadedPlanData, selectedPlan));
      return preLoads;
    }
    function preLoadedPlanDataForPreviewMode(preLoadedPlanData, selectedPlan) {
      const preLoads = [];

      // Module content approvals
      preLoadedPlanData.moduleContentApprovals = [];

      // Course units
      const courseUnitIds = _.map(selectedPlan.courseUnitSelections, 'courseUnitId');
      preLoads.push(jsDataCacheHelper.findByIds(courseUnitJSDataModel, courseUnitIds).then(planCourseUnits => {
        preLoadedPlanData.planCourseUnits = planCourseUnits;
        preLoadedPlanData.planCourseUnitAttainments = [];
      }));

      // Modules & education
      const moduleIds = _.chain(selectedPlan.moduleSelections).filter('parentModuleId').map('moduleId').concat(selectedPlan.rootId).uniq().value();
      preLoads.push(jsDataCacheHelper.findByIds(planModuleJSDataModel, moduleIds).then(planModules => {
        preLoadedPlanData.planModules = planModules;
        preLoadedPlanData.planModuleAttainments = [];
        preLoadedPlanData.planEducation = _.find(planModules, {
          id: selectedPlan.rootId
        });
      }));

      // Assessment items
      const assessmentItemSelectionIds = _.map(selectedPlan.assessmentItemSelections, 'assessmentItemId');
      preLoads.push(jsDataCacheHelper.findByIds(assessmentItemJSDataModel, assessmentItemSelectionIds).then(planAssessmentItems => {
        preLoadedPlanData.planAssessmentItems = planAssessmentItems;
      }));

      // Student attainments
      preLoadedPlanData.studentAttainments = [];
      return preLoads;
    }
    function educationPhaseContainsModule(educationPhase, moduleGroupId) {
      return _.get(educationPhase, 'options', []).map(option => option.moduleGroupId).includes(moduleGroupId);
    }
    const api = {
      private: {
        // private functions exposed for testing
        findAssessmentItemsThatNeedTeaching,
        setHasTeachingForCourseUnits,
        setHasTeachingForCourseUnit,
        assessmentItemHasActiveEnrolment,
        findAssessmentItemsThatHaveActiveTeaching,
        assessmentItemHasTeaching
      },
      delete: function (id) {
        return planJSDataModel.destroy(id);
      },
      findNotScheduledCourseUnits(validatablePlan, enrolments) {
        const notScheduledCourseUnitIds = [];
        const courseUnitIdsInPlan = validatablePlan.getIdForAllCourseUnitsInPlan();
        _.forEach(courseUnitIdsInPlan, courseUnitId => {
          if (api.isCourseUnitUnscheduled(courseUnitId, validatablePlan, enrolments)) {
            notScheduledCourseUnitIds.push(courseUnitId);
          }
        });
        return jsDataCacheHelper.findByIds(courseUnitJSDataModel, notScheduledCourseUnitIds).then(courseUnits => api.private.setHasTeachingForCourseUnits(courseUnits, validatablePlan, enrolments));
      },
      loadPlanRelations(plans, bypassCache) {
        if (_.isEmpty(plans)) {
          return $q.when([]);
        }
        const relations = [educationJSDataModel, curriculumPeriodModel];
        function loadRelations(jsDataModel) {
          return jsDataRelationHelperService.loadRelationForArray(jsDataModel, planJSDataModel, plans, !!bypassCache);
        }
        const loads = _.map(relations, loadRelations);
        return $q.all(loads).then(() => plans);
      },
      findAllByUserId(userId, bypassCache, loadRelations) {
        const options = {
          bypassCache: !!bypassCache
        };
        const findAll = planJSDataModel.findAll({
          userId
        }, options);
        if (loadRelations) {
          return findAll.then(plans => {
            const bypassCacheWithRelations = false;
            return api.loadPlanRelations(plans, bypassCacheWithRelations);
          }).catch(() => {});
        }
        return findAll;
      },
      findById(planId, bypassCache) {
        const options = {
          bypassCache: !!bypassCache
        };
        if (planId) {
          return planJSDataModel.find(planId, options);
        }
        $log.warn('planId not defined!');
        return $q.reject('planId not defined!');
      },
      findAllPlansForUser(userId, educationId, learningOpportunityId) {
        const params = {
          educationId,
          userId,
          learningOpportunityId
        };
        const options = {
          endpoint: PLAN_URL.FOR_USER_AND_EDUCATION,
          bypassCache: true
        };
        return planJSDataModel.findAll(params, options);
      },
      saveMyPlan(plan) {
        return planJSDataModel.save(plan, {
          endpoint: PLAN_URL.MY_PLANS
        }).then(() => {
          $rootScope.$broadcast(planServiceEventType.planSaved);
        });
      },
      savePlanAsStaff(plan) {
        planJSDataModel.inject(plan);
        return planJSDataModel.save(plan, {
          endpoint: PLAN_URL.DEFAULT
        });
      },
      updateMyPlan(plan) {
        return planJSDataModel.update(plan.id, plan, {
          endpoint: PLAN_URL.MY_PLANS
        }).then(result => {
          $rootScope.$broadcast(planServiceEventType.planSaved);
          return result;
        });
      },
      updatePlanAsStaff(plan) {
        return planJSDataModel.update(plan.id, plan, {
          endpoint: PLAN_URL.DEFAULT
        });
      },
      preLoadedPlanData(selectedPlan, isPreviewMode, ignoreModuleContentApprovals) {
        if (!selectedPlan) {
          return $q.when({});
        }
        const preLoadedPlanData = {};
        const preLoads = isPreviewMode ? preLoadedPlanDataForPreviewMode(preLoadedPlanData, selectedPlan) : preLoadedPlanDataForNonPreviewMode(preLoadedPlanData, selectedPlan, ignoreModuleContentApprovals);
        return $q.all(preLoads).then(() => preLoadedPlanData);
      },
      createValidatablePlan(selectedPlan, preLoadedPlanData) {
        return selectedPlan ? new ValidatablePlan(selectedPlan, preLoadedPlanData.studentAttainments, preLoadedPlanData.planEducation, preLoadedPlanData.planModules, preLoadedPlanData.planCourseUnits, preLoadedPlanData.planAssessmentItems, preLoadedPlanData.moduleContentApprovals, preLoadedPlanData.studyRight) : selectedPlan;
      },
      getValidatablePlan(rawPlan, isPreviewMode, ignoreModuleContentApprovals) {
        return api.preLoadedPlanData(rawPlan, isPreviewMode, ignoreModuleContentApprovals).then(preLoads => api.createValidatablePlan(rawPlan, preLoads));
      },
      isCourseUnitUnscheduled(courseUnitId, validatablePlan, enrolments) {
        let courseUnitScheduled = true;
        const hasAttainment = validatablePlan.isCourseUnitAttained(courseUnitId);
        if (!hasAttainment) {
          const hasCompletionMethodSelected = validatablePlan.isAnyCompletionMethodSelected(courseUnitId);
          if (hasCompletionMethodSelected) {
            const selectedCompletionMethod = validatablePlan.getSelectedCompletionMethod(courseUnitId);
            const {
              assessmentItemIds
            } = selectedCompletionMethod;
            const hasUnHandledAssessmentItems = !allAssessmentItemsInProgressOrAttained(validatablePlan, assessmentItemIds, enrolments);
            if (hasUnHandledAssessmentItems) {
              courseUnitScheduled = false;
            }
          } else {
            courseUnitScheduled = false;
          }
        }
        return !courseUnitScheduled;
      },
      /**
       * Groups all credits from course unit and custom course unit attainments by the education phase under which the
       * attainments have been placed in the given plan. Currently only works for degree educations. Returns an object
       * with the following structure:
       *
       * <pre>
       * {
       *   phase1Progress: {
       *     degreeProgrammeId: <id>,
       *     phaseName: <localized name>,
       *     targetCredits: <creditRange>,
       *     attainedCredits: <credits>
       *   },
       *   phase2Progress: {
       *     degreeProgrammeId: <id>,
       *     phaseName: <localized name>,
       *     targetCredits: <creditRange>,
       *     attainedCredits: <credits>
       *   }
       * }
       * </pre>
       *
       * Either/both keys can be missing, depending on what the state of the plan is, and how many phases the education has.
       *
       * @param {ValidatablePlan} validatablePlan
       * @returns {Object}
       */
      getStudyProgressByEducationPhase(validatablePlan, studyRight) {
        if (_.isEmpty(_.get(validatablePlan, 'plan.moduleSelections')) || _.isNil(_.get(validatablePlan, 'plan.rootId'))) {
          return $q.when(null);
        }
        return commonEducationService.findById(validatablePlan.plan.rootId).then(education => {
          const {
            phase1,
            phase2
          } = education.structure;
          return _(validatablePlan.plan.moduleSelections).filter({
            parentModuleId: validatablePlan.plan.rootId
          }).map('moduleId').map(id => validatablePlan.getModule(id)).filter({
            type: 'DegreeProgramme'
          }).reduce((result, degreeProgramme) => {
            const attainedCredits = _(validatablePlan.getAllCourseUnitAndCustomCourseUnitAttainmentsUnderModule(degreeProgramme.id)).map('credits').compact().sum() || 0;
            const personalizedPhase1ModuleGroupId = _.get(studyRight, 'personalizedSelectionPath.phase1.moduleGroupId');
            const personalizedPhase2ModuleGroupId = _.get(studyRight, 'personalizedSelectionPath.phase2.moduleGroupId');
            if (personalizedPhase1ModuleGroupId && personalizedPhase1ModuleGroupId === degreeProgramme.groupId || !personalizedPhase1ModuleGroupId && educationPhaseContainsModule(phase1, degreeProgramme.groupId)) {
              result.phase1Progress = {
                degreeProgrammeId: degreeProgramme.id,
                phaseName: phase1.name,
                targetCredits: degreeProgramme.targetCredits,
                attainedCredits
              };
            } else if (personalizedPhase2ModuleGroupId && personalizedPhase2ModuleGroupId === degreeProgramme.groupId || !personalizedPhase2ModuleGroupId && educationPhaseContainsModule(phase2, degreeProgramme.groupId)) {
              result.phase2Progress = {
                degreeProgrammeId: degreeProgramme.id,
                phaseName: phase2.name,
                targetCredits: degreeProgramme.targetCredits,
                attainedCredits
              };
            }
            return result;
          }, {});
        });
      },
      /**
       * Groups timed course units and custom study drafts in the given plan by the study term during which they
       * are planned to be attained. Course units that span multiple study terms will be grouped under the last
       * study term (i.e. the one when the attainment is expected to be received). The timingInfo property has
       * course unit ids as keys and lists of corresponding study term locators as values. The results will also
       * contain sums of planned credits, both for all planned course units and for each study term.
       *
       * Returns study terms starting from the one ongoing on fromDate up to the last study term with timed
       * course units.
       *
       * Will return an object with the following structure:
       * <pre>
       * {
       *   studiesByStudyTerm: [
       *     {
       *       studyTerm: {...},
       *       courseUnits: [
       *         {...}, {...}, ...
       *       ],
       *       courseUnitTimingInfo: {...},
       *       plannedCredits: {min: ?[, max: ?]}
       *       customStudyDrafts: [
       *           {...}, {...}, {...}
       *       ],
       *       customStudyDraftTimingInfo: {...}
       *     },
       *     ...
       *   ],
       *   plannedCreditsTotal: {min: ?[, max: ?]}
       * }
       * </pre>
       *
       * @param {Plan} plan A cleaned study plan
       * @param {string | moment.Moment} [fromDate] Determines the first study term to include in the results
       * @param {Array<string>} [ignoredCourseUnitIds] Course units to ignore (e.g. ones that have an attainment)
       */
      groupPlannedStudiesByStudyTerm(plan, fromDate = moment(), ignoredCourseUnitIds = []) {
        const courseUnitSelections = _.get(plan, 'courseUnitSelections') || [];
        const uniqueCourseUnitSelections = _.uniqBy(courseUnitSelections, 'courseUnitId');
        const timedCourseUnitSelections = uniqueCourseUnitSelections.filter(({
          courseUnitId
        }) => !ignoredCourseUnitIds.includes(courseUnitId)).filter(({
          plannedPeriods
        }) => plannedPeriods && plannedPeriods.length > 0).filter(({
          substitutedBy
        }) => _.isEmpty(substitutedBy));
        const timedCustomStudyDrafts = _.get(plan, 'customStudyDrafts', []).filter(({
          plannedPeriods
        }) => !_.isEmpty(plannedPeriods));
        if (_.isEmpty(timedCourseUnitSelections) && _.isEmpty(timedCustomStudyDrafts)) {
          return $q.when(null);
        }
        fromDate = moment(fromDate).isValid() ? moment(fromDate) : moment();
        const universityOrgId = universityService.getCurrentUniversityOrgId();
        const lastStudyTerm = _(_.concat(timedCourseUnitSelections, timedCustomStudyDrafts)).flatMap('plannedPeriods').map(commonStudyPeriodService.parseStudyPeriodLocator).maxBy(({
          year,
          termIndex
        }) => year * 10 + termIndex);
        const firstYear = fromDate.year() - 1;
        const numYears = lastStudyTerm.year - fromDate.year() + 2;
        const plannedStudies = {
          studiesByStudyTerm: []
        };
        const promises = [];
        return commonStudyPeriodService.getStudyYears(universityOrgId, firstYear, numYears).then(studyYears => {
          studyYears.forEach(({
            studyTerms,
            startYear
          }) => {
            studyTerms.forEach((studyTerm, termIndex) => {
              // Filter out study terms that have ended before fromDate or are after the last
              // timed course unit
              if (fromDate.isSameOrAfter(studyTerm.valid.endDate, 'day') || startYear > lastStudyTerm.year || startYear === lastStudyTerm.year && termIndex > lastStudyTerm.termIndex) {
                return;
              }

              // Group course units based on the last study term with timing info
              const courseUnitSelectionsForTerm = _.filter(timedCourseUnitSelections, selection => {
                const lastPeriod = _(selection.plannedPeriods).map(commonStudyPeriodService.parseStudyPeriodLocator).sortBy(['year', 'termIndex', 'periodIndex']).last();
                return !_.isNil(lastPeriod) && lastPeriod.year === startYear && lastPeriod.termIndex === termIndex;
              });
              const customStudyDraftsForTerm = _.filter(timedCustomStudyDrafts, customStudyDraft => {
                const lastPeriod = _(customStudyDraft.plannedPeriods).map(commonStudyPeriodService.parseStudyPeriodLocator).sortBy(['year', 'termIndex', 'periodIndex']).last();
                return !_.isNil(lastPeriod) && lastPeriod.year === startYear && lastPeriod.termIndex === termIndex;
              });
              const courseUnitIds = courseUnitSelectionsForTerm.map(selection => selection.courseUnitId);
              const courseUnitTimingInfo = _.mapValues(_.keyBy(courseUnitSelectionsForTerm, 'courseUnitId'), selection => _(selection.plannedPeriods).map(commonStudyPeriodService.parseStudyPeriodLocator).map(periodLocator => ({
                studyYearStartYear: periodLocator.year,
                termIndex: periodLocator.termIndex
              })).uniqWith(_.isEqual).orderBy(['studyYearStartYear', 'termIndex']).value());
              const customStudyDraftTimingInfo = _.mapValues(_.keyBy(customStudyDraftsForTerm, 'id'), selection => _(selection.plannedPeriods).map(commonStudyPeriodService.parseStudyPeriodLocator).map(periodLocator => ({
                studyYearStartYear: periodLocator.year,
                termIndex: periodLocator.termIndex
              })).uniqWith(_.isEqual).orderBy(['studyYearStartYear', 'termIndex']).value());
              promises.push(jsDataCacheHelper.findByIds(courseUnitJSDataModel, courseUnitIds).then(courseUnits => {
                const courseUnitsAndCustomStudyDrafts = _.concat(courseUnits, customStudyDraftsForTerm);
                const plannedCredits = courseUnitsAndCustomStudyDrafts.reduce((sum, {
                  credits
                }) => credits ? sum.add(credits) : sum, new Range(0));
                plannedStudies.studiesByStudyTerm.push({
                  studyTerm,
                  courseUnits,
                  courseUnitTimingInfo,
                  plannedCredits,
                  customStudyDrafts: customStudyDraftsForTerm,
                  customStudyDraftTimingInfo
                });
              }));
            });
          });
          return $q.all(promises).then(() => {
            plannedStudies.plannedCreditsTotal = plannedStudies.studiesByStudyTerm.reduce((sum, {
              plannedCredits
            }) => plannedCredits ? sum.add(plannedCredits) : sum, new Range(0));

            // StudyTerms are handler in chronological order, but since they are added to
            // the studiesByStudyTerm array in the order that the promises get
            // resolved, the order of the result array may not be chronological.
            // To prevent random order the array has to be sorted again.

            plannedStudies.studiesByStudyTerm.sort((a, b) => moment(_.get(a, 'studyTerm.valid.startDate')).isBefore(_.get(b, 'studyTerm.valid.startDate')) ? -1 : 1);
            return plannedStudies;
          });
        });
      },
      /**
       * Groups all (custom) course unit attainments, timed (non-attained) course units, custom study drafts and
       * timeline notes made by the student by the study period where they're attained at / timed to
       * (respectively). Returns study periods starting from the given fromDate up until one of the following,
       * whichever is latest:
       *
       * * The given minimumUntilDate
       * * The latest attainment date among the returned attainments
       * * The latest period with timed course units, custom study drafts or timeline notes
       *
       * The parameters fromDate and minimumUntilDate only control the study terms and periods that will be returned.
       * If these dates are in the middle of a study term, all attainments and course units for the term will be included
       * in the results, even if they fall outside of the given dates.
       *
       * Course units that are timed to several study periods will be included under all of those periods in the results.
       *
       * Returns an array of objects with the following structure:
       * <pre>
       * [
       *   {
       *     studyYear: {...},
       *     studyTerm: {...},
       *     studiesByPeriod: [
       *       {
       *         studyPeriod: {...},
       *         courseUnits: [{...}, {...}, ...],
       *         attainments: [{...}, {...}, ...],
       *         notes: ['...', '...', ...],
       *         customStudyDrafts: [{...}, {...}, {...}]
       *       },
       *       ...
       *     ]
       *   },
       *   ...
       * ]
       * </pre>
       *
       * @param {ValidatablePlan} validatablePlan The plan whose attainments and course unit selections to group
       * @param {moment.Moment | string} fromDate The results will contain study terms starting from this date
       * @param {moment.Moment | string} [minimumUntilDate] The results will contain study terms at least until this date
       * (but might contain more, if there are attainments or timed course units after this date)
       * @returns {Array | null}
       */
      groupAttainmentsAndCourseUnitsByStudyPeriod(validatablePlan, fromDate, minimumUntilDate) {
        const from = fromDate ? moment(fromDate) : moment.invalid();
        const until = minimumUntilDate ? moment(minimumUntilDate) : null;
        if (!validatablePlan || !from.isValid() || !_.isNil(until) && from.isAfter(until)) {
          return $q.when([]);
        }
        const attainments = validatablePlan.getAllCourseUnitAndCustomCourseUnitAttainmentsInPlan();
        const courseUnitSelections = _.get(validatablePlan, 'plan.courseUnitSelections') || [];
        const uniqueCourseUnitSelections = _.uniqBy(courseUnitSelections, 'courseUnitId');
        const timedCourseUnitSelections = uniqueCourseUnitSelections.filter(selection => !_.isEmpty(selection.plannedPeriods) && _.isEmpty(_.get(selection, 'substitutedBy')) && !attainments.some(({
          courseUnitId
        }) => courseUnitId === selection.courseUnitId));
        const customStudyDrafts = _.get(validatablePlan, 'plan.customStudyDrafts') || [];
        const timelineNotes = _.get(validatablePlan, 'plan.timelineNotes') || [];
        const timedCustomStudyDrafts = _.filter(customStudyDrafts, customStudyDraft => !_.isEmpty(customStudyDraft.plannedPeriods));
        if (_.isEmpty(attainments) && _.isEmpty(timedCourseUnitSelections) && _.isEmpty(timelineNotes) && _.isEmpty(timedCustomStudyDrafts)) {
          return $q.when([]);
        }
        const allPeriodLocators = _.concat(_.flatMap(timedCourseUnitSelections, 'plannedPeriods'), _.flatMap(timedCustomStudyDrafts, 'plannedPeriods'), _.flatMap(timelineNotes, 'notePeriods'));
        const lastTermWithTimings = _.chain(allPeriodLocators).compact().uniq().map(commonStudyPeriodService.parseStudyPeriodLocator).orderBy(['year', 'termIndex']).last().value();
        const lastTermLocator = lastTermWithTimings ? {
          studyYearStartYear: lastTermWithTimings.year,
          termIndex: lastTermWithTimings.termIndex
        } : null;
        const endBoundary = attainments.map(({
          attainmentDate
        }) => attainmentDate ? moment(attainmentDate) : null).concat(until).concat(lastTermLocator ? commonStudyTermService.getTermEndDate(lastTermLocator) : null).filter(date => date && date.isValid()).reduce((a, b) => moment.max(a, b));
        const universityOrgId = universityService.getCurrentUniversityOrgId();
        const firstYear = from.year() - 1;
        const numYears = endBoundary.year() - from.year() + 2;
        return commonStudyPeriodService.getStudyYears(universityOrgId, firstYear, numYears).then(studyYears => {
          const results = [];
          studyYears.forEach(studyYear => {
            studyYear.studyTerms.forEach((studyTerm, termIndex) => {
              const {
                startDate: termStartDate,
                endDate: termEndDate
              } = studyTerm.valid;
              if (!dateUtils.dateRangesOverlap(termStartDate, termEndDate, fromDate, endBoundary)) {
                return;
              }
              const studyTermEntry = {
                studyYear,
                studyTerm,
                studiesByPeriod: []
              };
              studyTerm.studyPeriods.forEach((studyPeriod, periodIndex) => {
                const studyPeriodEntry = {
                  studyPeriod
                };
                const {
                  startDate: periodStartDate,
                  endDate: periodEndDate
                } = studyPeriod.valid;
                studyPeriodEntry.attainments = attainments.filter(({
                  attainmentDate
                }) => moment(attainmentDate).isBetween(periodStartDate, periodEndDate, 'day', '[)'));
                const periodLocatorMatcher = new RegExp(`^[^/]+/${studyYear.startYear}/${termIndex}/${periodIndex}$`);
                studyPeriodEntry.courseUnits = timedCourseUnitSelections.filter(({
                  plannedPeriods
                }) => plannedPeriods.some(locator => locator.match(periodLocatorMatcher))).map(({
                  courseUnitId
                }) => validatablePlan.getCourseUnit(courseUnitId));
                studyPeriodEntry.customStudyDrafts = timedCustomStudyDrafts.filter(({
                  plannedPeriods
                }) => plannedPeriods.some(locator => locator.match(periodLocatorMatcher)));
                studyPeriodEntry.notes = timelineNotes.filter(note => (note.notePeriods || []).some(locator => locator.match(periodLocatorMatcher))).map(note => note.text);
                studyTermEntry.studiesByPeriod.push(studyPeriodEntry);
              });
              results.push(studyTermEntry);
            });
          });
          return results;
        });
      }
    };
    return api;
  }
})();