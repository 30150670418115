'use strict';

(function () {
  assessmentItemLoaderFactory.$inject = ["assessmentItemService", "cacheUtils"];
  angular.module('student.common.context.plan-context').factory('assessmentItemLoader', assessmentItemLoaderFactory);
  function assessmentItemLoaderFactory(assessmentItemService, cacheUtils) {
    return {
      loadAssessmentItems: function (assessmentItemAttainments, courseUnitSelections, assessmentItemsById) {
        var unloadedAssessmentItemsIds = cacheUtils.purgeCacheAndGetUnloadedIds(assessmentItemsById, _.map(assessmentItemAttainments, "assessmentItemId"), _.map(courseUnitSelections, "assessmentItemId"));
        return assessmentItemService.findByIds(unloadedAssessmentItemsIds);
      },
      loadByIds: function (ids) {
        return assessmentItemService.findByIds(ids);
      }
    };
  }
})();