import { ChangeDetectionStrategy, Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { TranslateService } from '@ngx-translate/core';
import { StateService, UIRouterGlobals } from '@uirouter/core';
import {
    Message,
    MessageCategoryType,
    MessageConversation,
    OtmId,
    ReceivedMessageTypeFilter,
    SearchFilterChangeEvent,
    SearchFilterType,
    SearchResult,
} from 'common-typescript/types';
import _ from 'lodash';
import { Observable, tap } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { DEFAULT_PROMISE_HANDLER } from 'sis-common/ajs-upgraded-modules';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { COMMON_MESSAGE_SERVICE, SEARCH_PARAMETERS } from '../ajs-upgraded-modules';
import { AlertsService, AlertType } from '../alerts/alerts-ng.service';
import { AppErrorHandler } from '../error-handler/app-error-handler';
import { Option } from '../menuButton/menu-button.component';
import { MessageConversationService, MessageConversationsSearch } from '../service/message-conversation.service';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-message-conversations',
    templateUrl: './sis-message-conversations.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class SisMessageConversationsComponent implements OnInit {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-common.messages.sisMessageConversations',
        directiveName: 'sisMessageConversations',
    };

    @Input() recipientId: string;

    @Input() filterType: ReceivedMessageTypeFilter = 'ALL';

    @Input() returnStateParams: null;

    @Input() filter: 'ALL' | 'MESSAGE' | 'NOTIFICATION' | 'ARCHIVED' | null;

    result: SearchResult<MessageConversation>;
    conversations$: Observable<MessageConversation[]>;
    searchParameters: any;
    searchParameterOptions: any;
    messageConversationsSearch: MessageConversationsSearch;
    itemsPerPage = 10;
    totalItems = 0;
    fullTextQuery = '';
    currentPage = 1;
    singleMessageConversationStateName: string;
    dropdownMenuOptions: Option[];
    selectedMessageConversationIds: OtmId[] = [];
    allSelected = false;

    constructor(private appErrorHandler: AppErrorHandler,
                private messageConversationService: MessageConversationService,
                private stateService: StateService,
                private uiRouterGlobals: UIRouterGlobals,
                private translateService: TranslateService,
                private translocoService: TranslocoService,
                private alertsService: AlertsService,
                @Inject(COMMON_MESSAGE_SERVICE) private commonMessageService: any,
                @Inject(SEARCH_PARAMETERS) private SearchParameters: any,
                @Inject(DEFAULT_PROMISE_HANDLER) private defaultPromiseHandler: any,
    ) {
        this.searchParameters = new SearchParameters().fetchLimitedResults();
        this.searchParameterOptions = {
            searchString: {
                name: 'SEARCHSTRING',
                type: SearchFilterType.NO_POPOVER,
                hide: true,
            },
            messageType: {
                name: 'MESSAGETYPE',
                type: SearchFilterType.MULTI,
                options: this.commonMessageService.getMessageTypeOptionsForSearch(),
            },
            messageReadState: {
                name: 'MESSAGEREADSTATE',
                type: SearchFilterType.MULTI,
                options: this.commonMessageService.getMessageStateOptionsForSearch(),
            },
        };
        this.search = this.search.bind(this);
    }

    ngOnInit() {
        this.currentPage = _.get(this.returnStateParams, 'page') || 1;
        this.singleMessageConversationStateName = _.get(this.uiRouterGlobals.current, 'data.singleMessageConversationStateName');
        this.dropdownMenuOptions = this.getDropdownMenuOptions();
        this.initMessageConversationsSearch();
        this.initSearchParams();
        this.search();
    }

    initSearchParams(): void {
        this.searchParameters.searchString.value = _.get(this.returnStateParams, 'studentSearch') || '';
        const messageTypes = (_.get(this.returnStateParams, 'messageTypes') || []) as string[];
        this.searchParameters.messageType.value = _.map(
            messageTypes,
            messageType => _.find(this.commonMessageService.getMessageTypeOptionsForSearch(), { id: messageType }),
        );
        const isRead = this.getIsReadParameter();
        // eslint-disable-next-line
        if (isRead === false) {
            this.searchParameters.messageReadState.value = [_.first(this.commonMessageService.getMessageStateOptionsForSearch())];
        } else {
            this.searchParameters.messageReadState.value = [];
        }
    }

    search(): void {
        this.conversations$ = this.messageConversationService.searchMessageConversations(
            this.messageConversationsSearch,
            'STAFF',
        )
            .pipe(
                map((response) => {
                    this.result = response;
                    this.totalItems = response.total;
                    return response.searchResults;
                }),
                this.appErrorHandler.defaultErrorHandler(),
            );
    }

    toggleSelectAll(): void {
        if (this.allSelected) {
            this.allSelected = false;
            this.unselectAll();
        } else {
            this.allSelected = true;
            this.selectAll();
        }
    }

    toggleConversationSelection(conversationId: OtmId): void {
        if (_.includes(this.selectedMessageConversationIds, conversationId)) {
            _.pull(this.selectedMessageConversationIds, conversationId);
        } else {
            this.selectedMessageConversationIds.push(conversationId);
        }
    }

    selectAll(): void {
        this.selectedMessageConversationIds = _.map(this.result.searchResults, 'conversationId');
    }

    unselectAll(): void {
        this.selectedMessageConversationIds = [];
    }

    onFullTextQueryChange(query: string): void {
        const trimmed = query?.trim() ?? null;
        if (!trimmed || trimmed.length >= 3) {
            this.messageConversationsSearch.studentSearch = trimmed;
            this.searchParameters.searchString.value = trimmed;
            this.setPage(1);
            this.selectedMessageConversationIds = [];
            this.allSelected = false;
            this.search();
        }
    }

    onSearchParameterChange(event: SearchFilterChangeEvent): void {
        if (event.parameterKey === 'messageType') {
            this.searchParameters.messageType.value = event.values;
        }
        if (event.parameterKey === 'messageReadState') {
            this.searchParameters.messageReadState.value = event.values;
        }

        this.setPage(1);
        this.selectedMessageConversationIds = [];
        this.allSelected = false;
        this.syncSearchParametersAndSearch();
    }

    onPageChange(currentPage: number): void {
        const page = _.isFinite(currentPage) ? currentPage : 1;
        this.setPage(page);
        this.selectedMessageConversationIds = [];
        this.allSelected = false;
        this.search();
    }

    setPage(page: number) {
        this.currentPage = page;
        this.messageConversationsSearch.start = (this.currentPage - 1) * this.itemsPerPage;
    }

    syncSearchParametersAndSearch(): void {
        this.messageConversationsSearch.messageTypes = _.map(this.searchParameters.messageType.value, 'id');
        this.messageConversationsSearch.studentSearch = this.searchParameters.searchString.value;
        const values = this.searchParameters.messageReadState.value;
        if (_.isEmpty(values) || _.size(values) > 1) {
            this.messageConversationsSearch.isRead = null;
        } else {
            const [value] = values;
            if (value.id === 'Read') {
                this.messageConversationsSearch.isRead = true;
            }
            if (value.id === 'Unread') {
                this.messageConversationsSearch.isRead = false;
            }

        }
        this.search();
    }

    isFilteredResult(): boolean {
        return this.messageConversationsSearch.isRead !== null ||
            this.messageConversationsSearch.studentSearch !== '' ||
            !_.isEmpty(this.messageConversationsSearch.messageTypes);
    }

    getMessageTypeTranslationKey(message: Message): string {
        return this.commonMessageService.getMessageTypeTranslationKey(message);
    }

    setViewStateToMessagesAndNotifications(): void {
        const newState = this.getNewStateName('all');
        this.stateService.go(newState);
    }

    setViewStateToMessages(): void {
        const newState = this.getNewStateName('messages');
        this.stateService.go(newState);
    }

    setViewStateToNotifications(): void {
        const newState = this.getNewStateName('notifications');
        this.stateService.go(newState);
    }

    setViewStateToArchived(): void {
        const newState = this.getNewStateName('archived');
        this.stateService.go(newState);
    }

    getNewStateName(childStatePostfix: string): string {
        const parentState = this.uiRouterGlobals.$current.parent;
        return `${parentState.name}.${childStatePostfix}`;
    }

    getMessageCategoryType(): MessageCategoryType | null {
        if (this.filter === 'MESSAGE' || this.filter === 'NOTIFICATION') {
            return this.filter;
        }
        return null;
    }

    initMessageConversationsSearch(): void {
        this.messageConversationsSearch = {
            recipientId: this.recipientId,
            studentSearch: _.get(this.returnStateParams, 'studentSearch') || '',
            filterType: this.filterType,
            messageTypes: _.get(this.returnStateParams, 'messageTypes') || [],
            messageCategoryType: this.getMessageCategoryType(),
            isRead: this.getIsReadParameter(),
            isArchived: this.filter === 'ARCHIVED',
            start: ((_.get(this.returnStateParams, 'page') || 1) - 1) * this.itemsPerPage,
            limit: this.itemsPerPage,
        };
    }

    getIsReadParameter(): boolean | null {
        const isRead = _.get(this.returnStateParams, 'isRead');
        return _.isNil(isRead) ? null : isRead;
    }

    goToMessageConversation(messageConversationId: string): void {
        this.stateService.go(this.singleMessageConversationStateName, {
            messageConversationId,
            returnRoute: this.uiRouterGlobals.current.name,
            returnParams: {
                page: this.currentPage,
                studentSearch: this.messageConversationsSearch.studentSearch,
                messageTypes: this.messageConversationsSearch.messageTypes,
                isRead: this.messageConversationsSearch.isRead,
            },
        });
    }

    getDropdownMenuOptions(): Option[] {
        if (this.filter === 'ARCHIVED') {
            return [
                {
                    name: this.translateService.instant('SIS_COMPONENTS.MESSENGER.MESSAGE_CONVERSATION.MARK_AS_NOT_ARCHIVED'),
                    action: () => this.markSelectedMessageConversationsAsNotArchived(),
                },
            ];
        }
        return [
            {
                name: this.translateService.instant('SIS_COMPONENTS.MESSENGER.MESSAGE_CONVERSATION.MARK_AS_READ'),
                action: () => this.markSelectedMessageConversationsAsRead(),
            },
            {
                name: this.translateService.instant('SIS_COMPONENTS.MESSENGER.MESSAGE_CONVERSATION.MARK_AS_UNREAD'),
                action: () => this.markSelectedMessageConversationsAsUnread(),
            },
            {
                name: this.translateService.instant('SIS_COMPONENTS.MESSENGER.MESSAGE_CONVERSATION.MARK_AS_ARCHIVED'),
                action: () => this.markSelectedMessageConversationsAsArchived(),
            },
        ];
    }

    markSelectedMessageConversationsAsRead(): void {
        this.messageConversationService.markMessageConversationsAsRead(this.selectedMessageConversationIds)
            .pipe(
                first(),
                tap((res) => {
                    this.showSuccessAlert(
                        'SIS_COMPONENTS.MESSENGER.ALERTS.MARK_READ_SUCCESS_SINGULAR',
                        'SIS_COMPONENTS.MESSENGER.ALERTS.MARK_READ_SUCCESS_PLURAL',
                    );
                }),
                this.appErrorHandler.defaultErrorHandler(),
            )
            .subscribe(this.search);
    }

    markSelectedMessageConversationsAsUnread(): void {
        this.messageConversationService.markMessageConversationsAsUnread(this.selectedMessageConversationIds)
            .pipe(
                first(),
                tap((res) => {
                    this.showSuccessAlert(
                        'SIS_COMPONENTS.MESSENGER.ALERTS.MARK_UNREAD_SUCCESS_SINGULAR',
                        'SIS_COMPONENTS.MESSENGER.ALERTS.MARK_UNREAD_SUCCESS_PLURAL',
                    );
                }),
                this.appErrorHandler.defaultErrorHandler(),
            )
            .subscribe(this.search);
    }

    markSelectedMessageConversationsAsArchived(): void {
        this.messageConversationService.markMessageConversationsAsArchived(this.selectedMessageConversationIds)
            .pipe(
                first(),
                tap((res) => {
                    this.showSuccessAlert(
                        'SIS_COMPONENTS.MESSENGER.ALERTS.ARCHIVE_SUCCESS_SINGULAR',
                        'SIS_COMPONENTS.MESSENGER.ALERTS.ARCHIVE_SUCCESS_PLURAL',
                    );
                }),
                this.appErrorHandler.defaultErrorHandler(),
            )
            .subscribe(this.search);
    }

    markSelectedMessageConversationsAsNotArchived(): void {
        this.messageConversationService.markMessageConversationsAsNotArchived(this.selectedMessageConversationIds)
            .pipe(
                first(),
                tap((res) => {
                    this.showSuccessAlert(
                        'SIS_COMPONENTS.MESSENGER.ALERTS.UNARCHIVE_SUCCESS_SINGULAR',
                        'SIS_COMPONENTS.MESSENGER.ALERTS.UNARCHIVE_SUCCESS_PLURAL',
                    );
                }),
                this.appErrorHandler.defaultErrorHandler(),
            )
            .subscribe(this.search);
    }

    showSuccessAlert(singularMessageKey: string, pluralMessageKey: string): void {
        let alertMessage: string;
        const messageCount = _.size(this.selectedMessageConversationIds);
        if (messageCount === 1) {
            alertMessage = this.translocoService.translate(singularMessageKey);
        } else {
            alertMessage = this.translocoService.translate(pluralMessageKey, { count: messageCount });
        }
        this.alertsService.addAlert({
            message: alertMessage,
            type: AlertType.SUCCESS,
        });
    }

}
