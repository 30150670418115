import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StateService } from '@uirouter/core';
import { CourseUnit, OpenUniversityCart, OtmId } from 'common-typescript/types';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { ConfirmDialogValues, getConfirmationModalOpener } from 'sis-components/confirm/confirm-dialog.component';
import { CourseUnitEntityService } from 'sis-components/service/course-unit-entity.service';
import { OpenUniversityCartCustomerService } from 'sis-components/service/open-university-cart-customer.service';

@Injectable({ providedIn: 'root' })
export class OpenUniversityModalService {

    readonly confirm = getConfirmationModalOpener();

    constructor(
        private courseUnitEntityService: CourseUnitEntityService,
        private localeService: LocaleService,
        private openUniversityCartService: OpenUniversityCartCustomerService,
        private state: StateService,
        private modal: NgbModal,
    ) {}

    openProductAddedToCartModal(cart: OpenUniversityCart, productId: OtmId, courseUnitId: OtmId): void {
        if (!cart?.id || !productId || !courseUnitId) {
            return;
        }

        const confirmValues: ConfirmDialogValues<never> = {
            title: 'OPEN_UNIVERSITY.STUDY_OFFERING.ADDED_TO_CART_MODAL.TITLE',
            descriptions: ['OPEN_UNIVERSITY.STUDY_OFFERING.ADDED_TO_CART_MODAL.DESCRIPTION'],
            cancelText: 'OPEN_UNIVERSITY.STUDY_OFFERING.ADDED_TO_CART_MODAL.CANCEL',
            confirmText: 'OPEN_UNIVERSITY.STUDY_OFFERING.ADDED_TO_CART_MODAL.CONFIRM',
        };

        this.openUniversityCartService.getReservationTimeLeft(cart.id)
            .pipe(catchError(() => of(null)))
            .subscribe(reservationTimeLeft => {
                const minutesLeft = Math.round(reservationTimeLeft?.asMinutes());
                if (minutesLeft > 0 && minutesLeft < 60) {
                    confirmValues.descriptions.push('OPEN_UNIVERSITY.STUDY_OFFERING.ADDED_TO_CART_MODAL.TIME_LEFT');
                    confirmValues.name = minutesLeft.toString();
                }

                this.confirm(confirmValues, true).closed.subscribe(() => {
                    this.modal.dismissAll('Close opened modals when navigating to enrolment wizard, e.g.: when opening open university study offerings from study brochure modal');
                    this.state.go(
                        'student.course-unit.open-university-enrolment-wizard',
                        { courseUnitId, openUniversityCartId: cart?.id, openUniversityProductId: productId });
                });
            });
    }

    /**
     * Opens a confirmation dialog for the removal of a product from the cart, and returns an `Observable` which emits
     * an empty value and then completes if the user confirms the action. If the user cancels, the `Observable`
     * completes without emitting anything.
     */
    openRemoveProductFromCartConfirmation(courseUnitId: OtmId): Observable<void> {
        const confirmValues: ConfirmDialogValues<never> = {
            title: 'OPEN_UNIVERSITY.STUDY_OFFERING.REMOVE_FROM_CART_MODAL.TITLE',
            descriptions: [
                'OPEN_UNIVERSITY.STUDY_OFFERING.REMOVE_FROM_CART_MODAL.DESCRIPTION_1',
                'OPEN_UNIVERSITY.STUDY_OFFERING.REMOVE_FROM_CART_MODAL.DESCRIPTION_2',
            ],
            confirmText: 'OPEN_UNIVERSITY.STUDY_OFFERING.REMOVE_FROM_CART',
        };

        return this.courseUnitEntityService.getById(courseUnitId)
            .pipe(
                take(1),
                catchError(() => of(null)),
                map((courseUnit: CourseUnit) => this.localeService.localize(courseUnit?.name) ?? ''),
                switchMap(name => this.confirm({ ...confirmValues, name }, true).closed),
            );
    }

    /**
     * Opens a confirmation dialog for the replacement of a product in the cart, and returns an `Observable` which emits
     * an empty value and then completes if the user confirms the action. If the user cancels, the `Observable`
     * completes without emitting anything.
     */
    openProductReplacementConfirmation(): Observable<void> {
        return this.confirm({
            title: 'OPEN_UNIVERSITY.STUDY_OFFERING.PRODUCT_REPLACEMENT_CONFIRMATION.TITLE',
            descriptions: [
                'OPEN_UNIVERSITY.STUDY_OFFERING.PRODUCT_REPLACEMENT_CONFIRMATION.DESCRIPTION_1',
                'OPEN_UNIVERSITY.STUDY_OFFERING.PRODUCT_REPLACEMENT_CONFIRMATION.DESCRIPTION_2',
                'OPEN_UNIVERSITY.STUDY_OFFERING.PRODUCT_REPLACEMENT_CONFIRMATION.DESCRIPTION_3',
            ],
            confirmText: 'OPEN_UNIVERSITY.STUDY_OFFERING.PRODUCT_REPLACEMENT_CONFIRMATION.CONFIRM',
        }, true).closed;
    }
}
