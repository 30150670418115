<ng-container *transloco="let t">
  <fudis-grid>
    <fudis-dl [columns]="{ sm: 1, md: 2 }">
      <fudis-dl-item data-cy="caws-student" *ngIf="studentWorkflow.studentId | privatePerson | async as student">
        <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.STUDENT')"/>
        <fudis-dd [contentText]="(student | personFullName) + ' (' + student.studentNumber + ')'" />
      </fudis-dl-item>
      <fudis-dl-item>
        <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.STUDY_RIGHT')"/>
        <fudis-dd
          [contentText]="studentWorkflow.studyRightId | studyRightName | async | localizedString" />
      </fudis-dl-item>
      <fudis-dl-item data-cy="caws-name">
        <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.SUGGESTED_ATTAINMENT_NAME')"/>
        <fudis-dd [contentText]="studentWorkflow.name" />
      </fudis-dl-item>
      <fudis-dl-item data-cy="caws-creation-time">
        <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.APPLICATION_ARRIVED')"/>
        <fudis-dd [contentText]="studentWorkflow.creationTime | localDateFormat" />
      </fudis-dl-item>
      <fudis-dl-item *ngIf="studentWorkflow.degreeProgrammeId | module | async as degreeProgramme">
        <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME')"/>
        <fudis-dd [contentText]="degreeProgramme.name | localizedString" />
      </fudis-dl-item>
      <fudis-dl-item *ngIf="studentWorkflow.plannedParentModuleId | module | async as plannedParentModule">
        <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.PLANNED_LOCATION')"/>
        <fudis-dd [contentText]="plannedParentModule.name | localizedString" />
      </fudis-dl-item>
      <fudis-dl-item *ngIf="studentWorkflow.studentId | privatePerson | async as student">
        <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.PREFERRED_LANGUAGE')"/>
        <fudis-dd
          [contentText]="!!student.preferredLanguageUrn ? (student.preferredLanguageUrn | codeLocalizedName | async) : t('STUDENT_APPLICATIONS.INFO_MISSING')" />
      </fudis-dl-item>
      <fudis-dl-item>
        <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.CUSTOM_ATTAINMENT.ATTAINMENT_DESCRIPTION')"/>
        <fudis-dd
          [contentText]="studentWorkflow.attainmentDescription" />
      </fudis-dl-item>
      <fudis-dl-item data-cy="caws-email" *ngIf="studentWorkflow.studentId | privatePerson | async as student">
        <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.CONTACT_INFO.EMAIL')"/>
        <fudis-dd [contentText]="student.primaryEmail" />
      </fudis-dl-item>
    </fudis-dl>

    <fudis-dl [columns]="{ sm: 1, md: 2 }">
      <fudis-dl-item data-cy="caws-rationale">
        <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.CUSTOM_ATTAINMENT.APPLICATION_RATIONALE')"/>
        <fudis-dd [contentText]="studentWorkflow.applicationRationale" />
      </fudis-dl-item>
      <fudis-dl-item data-cy="caws-responsible-teacher">
        <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.CUSTOM_ATTAINMENT.RESPONSIBLE_TEACHER')"/>
        <fudis-dd [contentText]="studentWorkflow.responsibleTeacher" />
      </fudis-dl-item>
      <fudis-dl-item>
        <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.CUSTOM_ATTAINMENT.PLANNED_CREDITS')"/>
        <fudis-dd
          [contentText]="studentWorkflow.plannedCredits + ' ' + t('STUDENT_APPLICATIONS.CUSTOM_ATTAINMENT.CREDITS')" />
      </fudis-dl-item>
      <fudis-dl-item>
        <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.ATTAINMENT_LANGUAGE')"/>
        <fudis-dd [contentText]="studentWorkflow.attainmentLanguage | codeLocalizedName | async" />
      </fudis-dl-item>
    </fudis-dl>

    <ng-container *ngIf="!!studentWorkflow.customStudyDraft">
      <fudis-heading [level]="3">{{ t('STUDENT_APPLICATIONS.CUSTOM_STUDY_DRAFT_THAT_APPLICATION_IS_BASED_ON') }}
      </fudis-heading>
      <fudis-dl [columns]="{ sm: 1, md: 2 }">
        <fudis-dl-item data-cy="caws-csd-name">
          <fudis-dt [contentText]="t('CUSTOM_STUDY_DRAFT.NAME')"/>
          <fudis-dd [contentText]="studentWorkflow.customStudyDraft.name" />
        </fudis-dl-item>
        <fudis-dl-item data-cy="caws-csd-credits">
          <fudis-dt [contentText]="t('CUSTOM_STUDY_DRAFT.CREDITS')"/>
          <fudis-dd [contentText]="studentWorkflow.customStudyDraft.credits + ' ' + t('CREDITS')" />
        </fudis-dl-item>
        <fudis-dl-item data-cy="caws-csd-location">
          <fudis-dt [contentText]="t('CUSTOM_STUDY_DRAFT.LOCATION')"/>
          <fudis-dd [contentText]="studentWorkflow.customStudyDraft.location" />
        </fudis-dl-item>
        <fudis-dl-item data-cy="caws-csd-description">
          <fudis-dt [contentText]="t('CUSTOM_STUDY_DRAFT.DESCRIPTION')"/>
          <fudis-dd [contentText]="studentWorkflow.customStudyDraft.description" />
        </fudis-dl-item>
      </fudis-dl>
    </ng-container>

    <ng-container>
      <sis-application-files [applicationId]="studentWorkflow.id" [studentId]="studentWorkflow.studentId"
                             [editable]="isWorkflowRequested()"/>
    </ng-container>
  </fudis-grid>
</ng-container>
