import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StudyGroupSet } from 'common-typescript/types';

import { EnrolmentValidationService } from '../service/enrolment-validation.service';

@Pipe({ name: 'allocationRuleDescription' })
export class AllocationRuleDescriptionPipe implements PipeTransform {

    constructor(
        private enrolmentValidationService: EnrolmentValidationService,
        private translate: TranslateService,
    ) {}

    /**
     * Returns a translated text describing the rules of the study group set regarding how many study subgroups a student
     * can/must select during enrolment. Using this pipe is equivalent to using the AngularJS `enrolmentValidationService`
     * functions `getSgsAllocationRuleTranslationKey()` and `getSgsAllocationRuleTranslationValues()`, and feeding their
     * output to `TranslateService`.
     *
     * @param studyGroupSet The study group set whose allocation rule description to return
     * @param translationKeyPrefix The translation key prefix (w/o a trailing dot) to prepend to the rule type returned by
     * `EnrolmentValidationService.getSgsAllocationRuleType()` (defaults to `COURSE_UNIT_REALISATION.ALLOCATION_RULE`)
     */
    transform(studyGroupSet: StudyGroupSet, translationKeyPrefix = 'COURSE_UNIT_REALISATION.ALLOCATION_RULE'): string {
        const ruleType = this.enrolmentValidationService.getSgsAllocationRuleType(studyGroupSet);
        if (!ruleType) {
            return null;
        }

        return this.translate.instant(`${translationKeyPrefix}.${ruleType}`, studyGroupSet.subGroupRange);
    }
}
