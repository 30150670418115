import unplannedCourseUnitRealisationTpl from './unplannedCourseUnitRealisation.tpl.html';
'use strict';
angular.module('student.plan.unplanned.courseUnitRealisation', ['sis-common.model.courseUnitRealisationNameService']).component('unplannedCourseUnitRealisation', {
  bindings: {
    courseUnitRealisation: '<',
    active: '<',
    dimmed: '<',
    onPlan: '&',
    deactivate: '&',
    enrolment: '<'
  },
  template: unplannedCourseUnitRealisationTpl,
  controller: ["courseUnitInfoModal", "uiContext", "courseUnitRealisationNameService", function (courseUnitInfoModal, uiContext, courseUnitRealisationNameService) {
    const $ctrl = this;
    $ctrl.activate = function () {
      if ($ctrl.active) {
        $ctrl.deactivate();
      } else {
        $ctrl.onPlan({
          courseUnitRealisation: $ctrl.courseUnitRealisation
        });
      }
    };
    $ctrl.$onInit = function () {
      if ($ctrl.enrolment && $ctrl.enrolment.courseUnitRealisation && $ctrl.enrolment.courseUnit) {
        $ctrl.courseUnitName = courseUnitRealisationNameService.generateFullNameFromCourseUnit($ctrl.enrolment.courseUnitRealisation, $ctrl.enrolment.courseUnit);
      }
    };
  }]
});