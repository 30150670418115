import angular from 'angular';
import { localizedStringModule } from '../string/localizedString.dir';
export const personNameAndTitleModule = 'sis-components.person.personNameAndTitle';
const template = `<span ng-if="$ctrl.person.fullName">
  {{$ctrl.person.fullName}}{{$ctrl.title ? (', ' + ($ctrl.title | localizedString)) : ''}}
</span>`;
angular.module(personNameAndTitleModule, [localizedStringModule]).component('personNameAndTitle', {
  template,
  bindings: {
    person: '<',
    title: '<'
  }
});