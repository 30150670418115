import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { ApplicationType, OtmId, StudentApplication } from 'common-typescript/types';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { EntityService } from './entity.service';

const CONFIG = {
    ENDPOINTS: {
        backend: '/ori/api',
        getApplicationsForStudentByTypes(studentId: OtmId) {
            return `${this.backend}/student-applications/${studentId}/by-types`;
        },
    },
};

@Injectable({
    providedIn: 'root',
})
@NgEntityServiceConfig({
    baseUrl: CONFIG.ENDPOINTS.backend,
    resourceName: 'student-applications',
})
export class StudentApplicationEntityService extends EntityService<StudentApplicationState> {

    constructor() {
        super(StudentApplicationStore, StudentApplicationQuery);
    }

    getApplicationsForStudentByTypes(studentId: OtmId, applicationTypes: ApplicationType[]): Observable<StudentApplication[]> {
        if (!studentId || !applicationTypes?.length) {
            return of([]);
        }

        return this.getHttp().get<StudentApplication[]>(CONFIG.ENDPOINTS.getApplicationsForStudentByTypes(studentId), {
            params: {
                types: applicationTypes,
            },
        })
            .pipe(
                tap(applications => this.store.upsertMany(applications)),
                switchMap(applications => this.query.selectMany(applications.map(application => application.id))),
            );
    }
}

type StudentApplicationState = EntityState<StudentApplication, OtmId>;

@StoreConfig({ name: 'student-application' })
class StudentApplicationStore extends EntityStore<StudentApplicationState> {}

class StudentApplicationQuery extends QueryEntity<StudentApplicationState> {
    constructor(protected store: StudentApplicationStore) {
        super(store);
    }
}
