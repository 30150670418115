<div class="modal-header">
  <h2 class="modal-title">{{'ENROLMENTS.SELECT_REALISATIONS' | translate}}</h2>
</div>
<div class="modal-body">
  <p>
    {{'ENROLMENTS.OPEN_UNIVERSITY_ENROLMENT.CHOOSE_TEACHING_FOR_COURSE_UNIT' | translate}}
    <strong>{{courseUnit.name | localizedString}} ({{courseUnit.credits | creditRange}})</strong>.
  </p>
  <ng-container *ngIf="studies$ | async as studies">
    <ng-container *ngFor="let item of studies; last as last">
      <div class="section-header">
        <h3 class="section-title">{{item.assessmentItem.name | localizedString}} ({{item.assessmentItem.credits | creditRange}})</h3>

        <span *ngIf="item.aiAttainment as attainment" class="attainment-info">
          <sis-icon icon="achievement" color="success"></sis-icon>{{'ENROLMENTS.OPEN_UNIVERSITY_ENROLMENT.ATTAINED_WITH_GRADE' | translate:
            {grade: attainment.gradeId | grade: attainment.gradeScaleId: 'NAME' | async} }}
        </span>

        <span *ngIf="item.enrolmentLimit > 0" class="enrolment-count-indicator">
          <ng-container *ngIf="item.enrolmentsRemaining > 0">
            <strong>{{item.enrolmentsRemaining}}/{{item.enrolmentLimit}}</strong>
            {{'ENROLMENT.OPEN_UNIVERSITY_ENROLMENT.ENROLMENTS_REMAINING' | translate}}
          </ng-container>
          <strong *ngIf="item.enrolmentsRemaining === 0">
            {{'ENROLMENT.OPEN_UNIVERSITY_ENROLMENT.NO_ENROLMENTS_REMAINING' | translate}}
          </strong>
        </span>
      </div>

      <app-course-unit-realisation-expandable *ngFor="let courseUnitRealisation of item.courseUnitRealisations"
                                              [courseUnit]="courseUnit"
                                              [courseUnitRealisation]="courseUnitRealisation"
                                              [enrolmentLimitReached]="item.enrolmentsRemaining === 0"
                                              (enrolClicked)="onEnrolClick(courseUnitRealisation.id, item.assessmentItem.id)">
      </app-course-unit-realisation-expandable>
      <p *ngIf="!item.courseUnitRealisations?.length" class="guidance">
        {{'ENROLMENTS.OPEN_UNIVERSITY_ENROLMENT.NO_TEACHING_AVAILABLE' | translate}}
      </p>
      <hr *ngIf="!last"/>
    </ng-container>
  </ng-container>
</div>
<div class="modal-footer">
  <sis-button (clicked)="close()" [hollow]="true">{{'SIS_COMPONENTS.BUTTON.CANCEL' | translate}}</sis-button>
</div>
