<ng-container  *transloco="let t">
  <fudis-dl [columns]="{ sm: 1, md: 2 }" [marginBottom]="'md'">
    <fudis-dl-item>
      <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.CONTACT_INFO.PHONE_NUMBER')"/>
      <fudis-dd [contentText]="person?.phoneNumber"/>
    </fudis-dl-item>
    <fudis-dl-item>
      <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.CONTACT_INFO.EMAIL')"/>
      <fudis-dd [contentText]="person?.primaryEmail"/>
    </fudis-dl-item>
    <fudis-dl-item *ngIf="person?.secondaryEmail">
      <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.CONTACT_INFO.SECONDARY_EMAIL')"/>
      <fudis-dd [contentText]="person?.secondaryEmail"/>
    </fudis-dl-item>
  </fudis-dl>

  <fudis-grid [columns]="{ sm: 1, md: 2 }" [marginBottom]="'md'">
    <fudis-grid-item>
      <fudis-heading [level]="level" [variant]="'sm'">
        {{ t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.CONTACT_INFO.PRIMARY_ADDRESS') }}
      </fudis-heading>
      <fudis-dl [variant]="'compact'">
        <fudis-dl-item>
          <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.CONTACT_INFO.STREET_ADDRESS')"/>
          <fudis-dd [contentText]="person?.primaryAddress?.streetAddress"/>
        </fudis-dl-item>
        <fudis-dl-item>
          <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.CONTACT_INFO.POSTAL_CODE')"/>
          <fudis-dd [contentText]="person?.primaryAddress?.postalCode"/>
        </fudis-dl-item>
        <fudis-dl-item>
          <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.CONTACT_INFO.CITY')"/>
          <fudis-dd [contentText]="person?.primaryAddress?.city"/>
        </fudis-dl-item>
        <fudis-dl-item>
          <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.CONTACT_INFO.COUNTRY')"/>
          <fudis-dd [contentText]="person?.primaryAddress?.countryUrn|codeLocalizedName|async"/>
        </fudis-dl-item>
      </fudis-dl>
    </fudis-grid-item>
    <fudis-grid-item *ngIf="person?.secondaryAddress">
      <fudis-heading [level]="level" [variant]="'sm'">
        {{ t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.CONTACT_INFO.SECONDARY_ADDRESS') }}
      </fudis-heading>
      <fudis-dl [variant]="'compact'">
        <ng-container *ngIf="person.secondaryAddress.type === 'FinnishAddress' ? person.secondaryAddress : null; let finnishAddress">
          <fudis-dl-item>
            <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.CONTACT_INFO.STREET_ADDRESS')"/>
            <fudis-dd [contentText]="finnishAddress.streetAddress"/>
          </fudis-dl-item>
          <fudis-dl-item>
            <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.CONTACT_INFO.POSTAL_CODE')"/>
            <fudis-dd [contentText]="finnishAddress.postalCode"/>
          </fudis-dl-item>
          <fudis-dl-item>
            <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.CONTACT_INFO.CITY')"/>
            <fudis-dd [contentText]="finnishAddress.city"/>
          </fudis-dl-item>
        </ng-container>
        <fudis-dl-item *ngIf="person.secondaryAddress.type !== 'FinnishAddress' && parsedSecondaryAddress">
          <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.CONTACT_INFO.ADDRESS_INFO')"/>
          <fudis-dd [contentText]="parsedSecondaryAddress"/>
        </fudis-dl-item>
        <fudis-dl-item>
          <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.CONTACT_INFO.COUNTRY')"/>
          <fudis-dd [contentText]="person.secondaryAddress.countryUrn|codeLocalizedName|async"/>
        </fudis-dl-item>
      </fudis-dl>
    </fudis-grid-item>
  </fudis-grid>
</ng-container>
