<div class="ssg-name">
  <div class="fudis-heading__xxs sis-mt-xs">{{ studySubGroup.name | localizedString:language}}</div>
  <sis-tiny-badge *ngIf="studySubGroup.cancelled"
                  [variant]="'danger'">
    {{'STUDY_SUB_GROUP_EVENT_INFO.CANCELLED' | translate}}
  </sis-tiny-badge>
</div>
<ng-container *ngIf="$sortedStudyEvents | async as sortedStudyEvents">
  <ng-container *ngIf="sortedStudyEvents?.length > 0; else noStudyEvents">
    <div *ngFor="let studyEvent of sortedStudyEvents" class="study-sub-group-info time-series-border">
      <app-student-study-event-structure [studyEvent]="studyEvent"
                                         [teacherIds]="studySubGroup.teacherIds"
                                         [language]="language">
      </app-student-study-event-structure>
    </div>
  </ng-container>
  <ng-template #noStudyEvents>
    <div class="no-events sis-mb-sm">
      {{'SIS_COMPONENTS.COURSE_UNIT_REALISATION_STRUCTURE.NO_STUDY_EVENT' | translate}}
    </div>
  </ng-template>
</ng-container>
