import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Attainment, CustomModuleAttainment } from 'common-typescript/types';

@Component({
    selector: 'app-custom-module-attainment-details',
    templateUrl: './custom-module-attainment-details.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CustomModuleAttainmentDetailsComponent {
    @Input() attainment: CustomModuleAttainment;
    @Input() secondaryAttainments: Attainment[];
    @Input() expirationImminent: boolean;
}
