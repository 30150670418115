import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { ValidatablePlan } from 'common-typescript';
import { CourseUnit } from 'common-typescript/types';

import {
    ModalTab,
} from '../../course-unit-info-modal-header/course-unit-info-modal-header-tabs/course-unit-info-modal-header-tabs.component';

@Component({
    selector: 'app-student-course-unit-info-modal-header',
    templateUrl: './student-course-unit-info-modal-header.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class StudentCourseUnitInfoModalHeaderComponent {

    @Input() courseUnit: CourseUnit;
    @Input() validatablePlan: ValidatablePlan;

    @Output() closeModal = new EventEmitter<any>();

    tabs: ModalTab[];

    ngOnInit() {
        this.tabs = [
            {
                id: 'course-unit-info-modal-brochure-tab',
                title: 'COURSE_UNIT_INFO_MODAL.TAB_HEADINGS.INFO',
                name: 'BASIC',
            },
        ];
    }

    selectTab() {

    }

}
