import { Injectable } from '@angular/core';
import { TranslationMarkupRendererFactory } from 'ngx-transloco-markup';

import { HtmlElementTranspiler } from '../common/html-element-transpiler';

export const ITALIC_START = new (class ItalicStart {})();
export const ITALIC_END = new (class ItalicEnd {})();

@Injectable()
export class ItalicTranspiler extends HtmlElementTranspiler {
    constructor(private readonly rendererFactory: TranslationMarkupRendererFactory) {
        super({
            rendererFactory,
            start: {
                search: '<i>',
                token: ITALIC_START,
            },
            end: {
                search: '</i>',
                token: ITALIC_END,
            },
            elementTag: 'i',
        });
    }
}
