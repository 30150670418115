import { TranslateService } from '@ngx-translate/core';
import { Transition } from '@uirouter/core';
import {
    CustomAttainmentWorkflow,
    DegreeProgrammeAttainmentWorkflow,
    ModuleContentWorkflow,
    Plan,
    PriorLearningSubstitutionWorkflow,
} from 'common-typescript/types';
import * as _ from 'lodash';
import { firstValueFrom } from 'rxjs';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { CodePanelComponent } from 'sis-components/applications/code-panel/code-panel.component';
import { ConfirmDialogService } from 'sis-components/confirm/confirm-dialog.service';
import { STUDENT_WORKFLOW_APPLICATION_TYPE, STUDENT_WORKFLOW_TYPE } from 'sis-components/model/student-workflow-constants';
import { getInjectables } from 'sis-components/router/router-utils';
import { SisStateDeclaration } from 'sis-components/router/types';
import { CourseUnitEntityService } from 'sis-components/service/course-unit-entity.service';
import { EducationEntityService } from 'sis-components/service/education-entity.service';
import { ModuleEntityService } from 'sis-components/service/module-entity.service';
import { PlanEntityService } from 'sis-components/service/plan-entity.service';
import { StudyRightEntityService } from 'sis-components/service/study-right-entity.service';
import { WorkflowEntityService } from 'sis-components/service/workflow-entity.service';

import {
    CreateDegreeProgrammeAttainmentApplicationComponent,
} from './create/create-degree-programme-attainment-application/create-degree-programme-attainment-application.component';
import {
    CustomAttainmentApplicationSupplementWizardComponent,
} from './create/custom-attainment-application-supplement-wizard/custom-attainment-application-supplement-wizard.component';
import { CustomAttainmentWorkflowApplicationWizardComponent } from './create/custom-attainment-workflow-application-wizard/custom-attainment-workflow-application-wizard.component';
import {
    CreateExtensionApplicationComponent,
} from './create/extension-application/create-extension-application.component';
import { PriorLearningInclusionWorkflowApplicationWizardComponent } from './create/prior-learning-inclusion-workflow-application-wizard/prior-learning-inclusion-workflow-application-wizard.component';
import {
    PriorLearningInclusionWorkflowSupplementWizardComponent,
} from './create/prior-learning-inclusion-workflow-supplement-wizard/prior-learning-inclusion-workflow-supplement-wizard.component';
import { PriorLearningSubstitutionWorkflowApplicationWizardComponent } from './create/prior-learning-substitution-workflow-application-wizard/prior-learning-substitution-workflow-application-wizard.component';
import {
    PriorLearningSubstitutionWorkflowSupplementWizardComponent,
} from './create/prior-learning-substitution-workflow-supplement-wizard/prior-learning-substitution-workflow-supplement-wizard.component';
import { CustomAttainmentWorkflowComponent } from './custom-attainment-workflow/custom-attainment-workflow.component';
import { DegreeProgrammeAttainmentWorkflowComponent } from './degree-programme-attainment-workflow/degree-programme-attainment-workflow.component';
import { ModuleContentWorkflowComponent } from './module-content-workflow/module-content-workflow.component';
import { PriorLearningWorkflowComponent } from './prior-learning-workflow/prior-learning-workflow.component';
import { StudentApplicationsComponent } from './student-applications/student-applications.component';

export const studentApplicationStates: SisStateDeclaration[] = [
    {
        name: 'student.logged-in.profile.applications.create-study-right-extension-application',
        url: '/study-right-extension?planId&studyRightId',
        headerParams: {
            displayNameFunction: (extensionApplicationDisplayName),
        },
        data: {
            showHeader: true,
            headerWithoutBreadcrumbs: true,
        },
        views: {
            '': {
                component: CreateExtensionApplicationComponent,
            },
            'title@student.logged-in.profile': {},
            'secondaryNavigation@student.logged-in.profile': {},
        },
        resolve: {
            plan: resolvePlan,
            studyRight: resolveStudyRight,
            education: resolveEducation,
        },
    },
    {
        name: 'student.logged-in.profile.applications.create-custom-attainment-application',
        url: '/custom-attainment?planId&customStudyDraftId',
        headerParams: {
            displayNameKey: `STUDENT_APPLICATIONS.TYPE.${STUDENT_WORKFLOW_APPLICATION_TYPE.CUSTOM_ATTAINMENT_APPLICATION}`,
        },
        data: {
            showHeader: true,
            headerWithoutBreadcrumbs: true,
        },
        views: {
            '': {
                component: CustomAttainmentWorkflowApplicationWizardComponent,
            },
            'title@student.logged-in.profile': {},
            'secondaryNavigation@student.logged-in.profile': {},
        },
        resolve: {
            plan: findPlan,
            customStudyDraftId: resolveCustomStudyDraftId,
        },
    },
    {
        name: 'student.logged-in.profile.applications.custom-attainment-workflow.supplement',
        url: '/supplement',
        focusMode: {
            defaultExitState: '^',
            titleKey: 'STUDENT_APPLICATIONS.TYPE.CustomAttainmentWorkflow',
        },
        data: {
            showHeader: false,
            browserTabTitle: 'STUDENT_APPLICATIONS.TYPE.CustomAttainmentWorkflow',
        },
        views: {
            '@': {
                component: CustomAttainmentApplicationSupplementWizardComponent,
            },
            'title@student.logged-in.profile': {},
            'secondaryNavigation@student.logged-in.profile': {},
        },
        resolve: {
            workflow: resolveStudentWorkflow,
        },
        onExit: onSupplementWizardExit,
    },
    {
        name: 'student.logged-in.profile.applications.create-prior-learning-inclusion-application',
        url: '/prior-learning-inclusion/?planId&customStudyDraftId',
        headerParams: {
            displayNameKey: `STUDENT_APPLICATIONS.TYPE.${STUDENT_WORKFLOW_APPLICATION_TYPE.PRIOR_LEARNING_INCLUSION_APPLICATION}`,
        },
        data: {
            showHeader: true,
            headerWithoutBreadcrumbs: true,
        },
        views: {
            '': {
                component: PriorLearningInclusionWorkflowApplicationWizardComponent,
            },
            'title@student.logged-in.profile': {},
            'secondaryNavigation@student.logged-in.profile': {},
        },
        resolve: {
            plan: findPlan,
            customStudyDraftId: resolveCustomStudyDraftId,
        },
    },
    {
        name: 'student.logged-in.profile.applications.prior-learning-application.supplement-prior-learning-inclusion-workflow',
        url: '/inclusion/supplement',
        focusMode: {
            defaultExitState: '^',
            titleKey: 'STUDENT_APPLICATIONS.TYPE.PriorLearningInclusionWorkflow',
        },
        data: {
            showHeader: false,
            browserTabTitle: 'STUDENT_APPLICATIONS.TYPE.PriorLearningInclusionWorkflow',
        },
        views: {
            '@': {
                component: PriorLearningInclusionWorkflowSupplementWizardComponent,
            },
            'title@student.logged-in.profile': {},
            'secondaryNavigation@student.logged-in.profile': {},
        },
        resolve: {
            workflow: resolveStudentWorkflow,
        },
        onExit: onSupplementWizardExit,
    },
    {
        name: 'student.logged-in.profile.applications.create-degree-programme-attainment-application',
        url: '/degree-programme?planId&degreeProgrammeId',
        headerParams: {
            displayNameFunction: (transition => {
                const [localeService, translate, degreeProgramme] =
                    getInjectables(transition, LocaleService, TranslateService, 'degreeProgramme');
                const applicationTypeName = translate.instant('STUDENT_APPLICATIONS.TYPE.DegreeProgrammeAttainmentApplication');
                return `${applicationTypeName}: ${localeService.localize(degreeProgramme?.name)}`;
            }),
        },
        data: {
            showHeader: true,
            headerWithoutBreadcrumbs: true,
        },
        views: {
            '': {
                component: CreateDegreeProgrammeAttainmentApplicationComponent,
            },
            'title@student.logged-in.profile': {},
            'secondaryNavigation@student.logged-in.profile': {},
        },
        resolve: {
            plan: findPlan,
            planValidationResult: resolvePlanValidationResult,
            validatablePlan: resolveValidatablePlan,
            degreeProgramme: degreeProgrammeResolver,
        },
    },
    {
        name: 'student.logged-in.profile.applications.create-prior-learning-substitution-application',
        url: '/prior-learning-substitution/?planId&courseUnitId',
        headerParams: {
            displayNameFunction: (transition) => {
                const [localeService, translate, courseUnit] = getInjectables(transition, LocaleService, TranslateService, 'courseUnit');
                const applicationTypeName = translate
                    .instant(`STUDENT_APPLICATIONS.TYPE.${STUDENT_WORKFLOW_APPLICATION_TYPE.PRIOR_LEARNING_SUBSTITUTION_APPLICATION}`);
                return `${applicationTypeName}: ${localeService.localize(courseUnit?.name)}`;
            },
        },
        data: {
            showHeader: true,
            headerWithoutBreadcrumbs: true,
        },
        views: {
            '': {
                component: PriorLearningSubstitutionWorkflowApplicationWizardComponent,
            },
            'title@student.logged-in.profile': {},
            'secondaryNavigation@student.logged-in.profile': {},
        },
        resolve: {
            plan: findPlan,
            courseUnit: findCourseUnit,
        },
    },
    {
        name: 'student.logged-in.profile.applications.prior-learning-application.supplement-prior-learning-substitution-workflow',
        url: '/substitution/supplement',
        focusMode: {
            defaultExitState: '^',
            titleKey: 'STUDENT_APPLICATIONS.TYPE.PriorLearningSubstitutionWorkflow',
        },
        data: {
            showHeader: false,
            browserTabTitle: 'STUDENT_APPLICATIONS.TYPE.PriorLearningSubstitutionWorkflow',
        },
        views: {
            '@': {
                component: PriorLearningSubstitutionWorkflowSupplementWizardComponent,
            },
            'title@student.logged-in.profile': {},
            'secondaryNavigation@student.logged-in.profile': {},
        },
        resolve: {
            workflow: resolveStudentWorkflow,
        },
        onExit: onSupplementWizardExit,
    },
    {
        name: 'student.logged-in.profile.applications.degree-programme-attainment-workflow',
        url: '/degree-programme/:applicationId',
        views: {
            '': {
                component: DegreeProgrammeAttainmentWorkflowComponent,
            },
            'title@student.logged-in.profile': {},
            'secondaryNavigation@student.logged-in.profile': {},
            'codepanel@': {
                component: CodePanelComponent,
            },
        },
        headerParams: {
            displayNameFunction: (transition => {
                const [localeService, translate, degreeProgramme] =
                    getInjectables(transition, LocaleService, TranslateService, 'degreeProgramme');
                const applicationTypeName = translate.instant('STUDENT_APPLICATIONS.TYPE.DegreeProgrammeAttainmentApplication');
                return `${applicationTypeName}: ${localeService.localize(degreeProgramme?.name)}`;
            }),
        },
        resolve: {
            studentWorkflow: resolveStudentWorkflow,
            degreeProgramme: resolveWorkflowDegreeProgramme,
            code: resolveWorkflowCode,
        },
        data: {
            showHeader: true,
        },
    },
    {
        name: 'student.logged-in.profile.applications.custom-attainment-workflow',
        url: '/custom-attainment/:applicationId',
        views: {
            '': {
                component: CustomAttainmentWorkflowComponent,
            },
            'title@student.logged-in.profile': {},
            'secondaryNavigation@student.logged-in.profile': {},
            'codepanel@': {
                component: CodePanelComponent,
            },
        },
        headerParams: {
            displayNameFunction: (transition => {
                const [translate, studentWorkflow] =
                    getInjectables(transition, TranslateService, 'studentWorkflow');
                const applicationTypeName = translate.instant('STUDENT_APPLICATIONS.TYPE.CustomAttainmentWorkflow');
                return `${applicationTypeName}: ${(studentWorkflow as CustomAttainmentWorkflow).name}`;
            }),
        },
        resolve: {
            studentWorkflow: resolveStudentWorkflow,
            code: resolveWorkflowCode,
        },
        data: {
            showHeader: true,
        },
    },
    {
        name: 'student.logged-in.profile.applications.module-content-application',
        url: '/module-content-application/:applicationId',
        views: {
            '': {
                component: ModuleContentWorkflowComponent,
            },
            'title@student.logged-in.profile': {},
            'secondaryNavigation@student.logged-in.profile': {},
            'codepanel@': {
                component: CodePanelComponent,
            },
        },
        headerParams: {
            displayNameFunction: (transition) => {
                const [translate, localeService, workflow, education] =
                    getInjectables(transition, TranslateService, LocaleService, 'studentWorkflow', 'education');
                const applicationTypeName = translate.instant(`STUDENT_APPLICATIONS.TYPE.${workflow.type}`);
                return `${applicationTypeName}: ${localeService.localize(education?.name)}`;
            },
        },
        resolve: {
            studentWorkflow: resolveStudentWorkflow,
            studentModule: resolveWorkflowStudyModule,
            education: resolveWorkflowEducation,
            code: resolveWorkflowCode,
        },
        data: {
            showHeader: true,
        },
    },
    {
        name: 'student.logged-in.profile.applications.prior-learning-application',
        url: '/prior-learning/:applicationId',
        views: {
            '': {
                component: PriorLearningWorkflowComponent,
            },
            'title@student.logged-in.profile': {},
            'secondaryNavigation@student.logged-in.profile': {},
            'codepanel@': {
                component: CodePanelComponent,
            },
        },
        headerParams: {
            displayNameFunction: (resolveWorkflowDisplayName),
        },
        resolve: {
            studentWorkflow: resolveStudentWorkflow,
            courseUnit: resolveWorkflowCourseUnit,
            code: resolveWorkflowCode,
        },
        data: {
            showHeader: true,
        },
    },
    {
        name: 'student.logged-in.profile.applications.list',
        url: '/list',
        component: StudentApplicationsComponent,
        headerParams: {
            displayPageTitleNameKey: 'MY_INFORMATION',
            skipBreadcrumb: true,
        },
    },
];

function extensionApplicationDisplayName(transition: Transition): string {
    const [$translate, localeService, education] =
        getInjectables(transition, '$translate', 'localeService', 'education');
    const applicationTypeName = $translate.instant('STUDENT_APPLICATIONS.TYPE.StudyRightExtensionApplication');
    return `${applicationTypeName}: ${localeService.getLocalizedValue(education?.name)}`;
}
extensionApplicationDisplayName.$inject = ['$transition$'];

export function resolveWorkflowDisplayName(transition: Transition) {
    const [translate, localeService, workflow] =
        getInjectables(transition, TranslateService, LocaleService, 'studentWorkflow');
    const workflowTypeName = translate.instant(`STUDENT_APPLICATIONS.TYPE.${workflow.type}`);
    switch (workflow.type) {
        case STUDENT_WORKFLOW_TYPE.PRIOR_LEARNING_SUBSTITUTION_WORKFLOW: {
            const [courseUnit] = getInjectables(transition, 'courseUnit');
            return `${workflowTypeName}: ${localeService.localize(courseUnit?.name)}`;
        }
        case STUDENT_WORKFLOW_TYPE.PRIOR_LEARNING_INCLUSION_WORKFLOW:
            return `${workflowTypeName}: ${workflow.name}`;
        default:
            return null;
    }
}
resolveWorkflowDisplayName.$inject = ['$transition$'];

export function findCourseUnit(transition: Transition) {
    return transition.injector().get('commonCourseUnitService').findById(transition.params().courseUnitId, { loadRelations: true });
}
findCourseUnit.$inject = ['$transition$'];

export function findPlan(transition: Transition) {
    return transition.injector().get('commonPlanService').findById(transition.params().planId, true);
}
findPlan.$inject = ['$transition$'];

export function resolvePlan(transition: Transition) {
    return firstValueFrom(transition.injector().get(PlanEntityService).getById(transition.params().planId));
}
resolvePlan.$inject = ['$transition$'];
export function resolveStudyRight(transition: Transition) {
    return firstValueFrom(transition.injector().get(StudyRightEntityService).getById(transition.params().studyRightId, true));
}
resolveStudyRight.$inject = ['$transition$'];
export function resolveEducation(transition: Transition, plan: Plan) {
    return firstValueFrom(transition.injector().get(EducationEntityService).getById(plan.rootId, true));
}
resolveEducation.$inject = ['$transition$', 'plan'];

export function getPlanValidationResult(transition: Transition) {
    return transition.injector().get('PlanLoader').validatePlan(transition.params().planId);
}
getPlanValidationResult.$inject = ['$transition$'];

export function resolvePlanValidationResult(transition: Transition, plan: any) {
    return transition.injector().get('PlanLoader').validatePlanWithThisPlan(plan);
}
resolvePlanValidationResult.$inject = ['$transition$', 'plan'];

export function resolveValidatablePlan(planValidationResult: any) {
    return _.get(planValidationResult, 'validatablePlan');
}
resolveValidatablePlan.$inject = ['planValidationResult'];

export function resolveCustomStudyDraftId(transition: Transition) {
    return transition.params().customStudyDraftId;
}
resolveCustomStudyDraftId.$inject = ['$transition$'];

export function degreeProgrammeResolver(validatablePlan: any, $transition$: any) {
    return _.get(validatablePlan.modulesById, $transition$.params().degreeProgrammeId);
}
degreeProgrammeResolver.$inject = ['validatablePlan', '$transition$'];

export function resolveStudentWorkflow($transition$: Transition) {
    const [workflowEntityService] = getInjectables($transition$, WorkflowEntityService);
    const workflowId = $transition$.params().applicationId;
    return firstValueFrom(workflowEntityService.getById(workflowId));
}
resolveStudentWorkflow.$inject = ['$transition$'];

export function resolveWorkflowDegreeProgramme($transition$: Transition, studentWorkflow: DegreeProgrammeAttainmentWorkflow) {
    const [moduleEntityService] = getInjectables($transition$, ModuleEntityService);
    return firstValueFrom(moduleEntityService.getById(studentWorkflow.moduleId));
}
resolveWorkflowDegreeProgramme.$inject = ['$transition$', 'studentWorkflow'];

export function resolveWorkflowStudyModule($transition$: Transition, studentWorkflow: ModuleContentWorkflow) {
    const [moduleEntityService] = getInjectables($transition$, ModuleEntityService);
    return firstValueFrom(moduleEntityService.getById(studentWorkflow.approvedModuleId));
}
resolveWorkflowStudyModule.$inject = ['$transition$', 'studentWorkflow'];

export function resolveWorkflowEducation($transition$: Transition, studentWorkflow: ModuleContentWorkflow) {
    const [educationEntityService] = getInjectables($transition$, EducationEntityService);
    return firstValueFrom(educationEntityService.getById(studentWorkflow.educationId));
}
resolveWorkflowEducation.$inject = ['$transition$', 'studentWorkflow'];

export function resolveWorkflowCourseUnit($transition$: Transition, studentWorkflow: PriorLearningSubstitutionWorkflow) {
    const [courseUnitEntityService] = getInjectables($transition$, CourseUnitEntityService);
    if (!studentWorkflow.courseUnitId) return null;
    return firstValueFrom(courseUnitEntityService.getById(studentWorkflow.courseUnitId));
}
resolveWorkflowCourseUnit.$inject = ['$transition$', 'studentWorkflow'];

export function resolveWorkflowCode($transition$: Transition, studentWorkflow: ModuleContentWorkflow) {
    return studentWorkflow.code;
}
resolveWorkflowCode.$inject = ['$transition$', 'studentWorkflow'];

export function onSupplementWizardExit($transition$: Transition, $state: any) {
    const confirmDialogService = $transition$.injector().get(ConfirmDialogService);
    if ($transition$.$to().name === $transition$.$from().name) {
        return;
    }
    if ($transition$.options().custom.skipConfirmationDialog) {
        return;
    }

    // These translations are shared with application create wizards
    const confirmModalTexts = {
        title: 'PROFILE.APPLICATIONS.CONFIRM_DISCARD_APPLICATION.TITLE',
        description: 'PROFILE.APPLICATIONS.CONFIRM_DISCARD_APPLICATION.BODY',
    };
    return confirmDialogService.confirm(confirmModalTexts)
        .then(() => {})
        .catch(
            () => {
                /* Same trick as in create wizards, if the browser already changed the url
                this will undo it */
                const { location } = window;
                if (location.pathname + location.search !== $state.href($state.$current)) {
                    window.history.replaceState(null, null, $state.href($state.$current));
                }
                return false;
            });
}
onSupplementWizardExit.$inject = ['$transition$', '$state'];
