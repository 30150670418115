export const localDateTimeRangeFilterModule = 'sis-components.date.localDateTimeRangeFilter';
(function () {
  localDateTimeRange.$inject = ["$translate"];
  angular.module(localDateTimeRangeFilterModule, ['pascalprecht.translate']).filter('localDateTimeRange', localDateTimeRange);

  /**
   * @ngInject
   */
  function localDateTimeRange($translate) {
    return function (input) {
      if (input && (input.startDateTime || input.endDateTime)) {
        var format;
        var startDateTime;
        var endDateTime;
        if (input.startDateTime) {
          startDateTime = moment(input.startDateTime, moment.ISO_8601).format('D.M.YYYY HH.mm');
        }
        if (input.endDateTime) {
          endDateTime = moment(input.endDateTime, moment.ISO_8601).format('D.M.YYYY HH.mm');
        }
        if (startDateTime && endDateTime && startDateTime === endDateTime) {
          format = 'ARE_SAME';
        } else if (startDateTime && !endDateTime) {
          format = 'START_';
        } else if (!startDateTime && endDateTime) {
          format = '_END';
        } else {
          format = 'START_END';
        }
        return $translate.instant('DATE_TIME_RANGE.' + format, {
          start: startDateTime,
          end: endDateTime
        });
      }
      return '';
    };
  }
})();