import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalDateTimeRange, LocalDateTimeString } from 'common-typescript/types';
import moment from 'moment';

@Pipe({ name: 'localDateTimeRange' })
export class LocalDateTimeRangePipe implements PipeTransform {

    constructor(private translate: TranslateService) {}

    transform(localDateTimeRange: LocalDateTimeRange): any {
        if (localDateTimeRange && (localDateTimeRange.startDateTime || localDateTimeRange.endDateTime)) {
            let format: string;
            let startDateTime: LocalDateTimeString;
            let endDateTime: LocalDateTimeString;

            if (localDateTimeRange.startDateTime) {
                startDateTime = moment(localDateTimeRange.startDateTime, moment.ISO_8601).format('D.M.YYYY HH.mm');
            }

            if (localDateTimeRange.endDateTime) {
                endDateTime = moment(localDateTimeRange.endDateTime, moment.ISO_8601).format('D.M.YYYY HH.mm');
            }

            if (startDateTime && endDateTime && startDateTime === endDateTime) {
                format = 'ARE_SAME';
            } else if (startDateTime && !endDateTime) {
                format = 'START_';
            } else if (!startDateTime && endDateTime) {
                format = '_END';
            } else {
                format = 'START_END';
            }

            return this.translate.instant(`DATE_TIME_RANGE.${format}`, { start: startDateTime, end: endDateTime });
        }
        return '';

    }
}
