import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import { errorServiceModule } from 'sis-components/error-handler/legacy/errorService';
import { educationModelModule } from '../model/education.model';
import { organisationModelModule } from '../model/organisation.model';
import { studentApplicationModelModule } from '../model/studentApplication.model';
import { studyRightExpirationRulesModelModule } from '../model/studyRightExpirationRules.model';
import { degreeProgrammeModelModule } from '../model/degreeProgramme.model';
import { studyRightModelModule } from '../model/studyRight.model';
import { jsDataCacheHelperModule } from './jsDataCacheHelper.service';
import { commonCurriculumPeriodServiceModule } from './curriculumPeriod.service';
import { jsDataRelationHelperModule } from './jsDataRelationHelper.service';
import { commonStudyTermServiceModule } from './studyTerm.service';
import { CommonCodeService } from './common-code.service.ts';
export const commonStudyRightServiceModule = 'sis-components.service.studyRightService';
(function () {
  studyRightService.$inject = ["$q", "$log", "$http", "commonCurriculumPeriodService", "jsDataRelationHelperService", "studyRightJSDataModel", "educationJSDataModel", "organisationJSDataModel", "studyRightExpirationRulesJSDataModel", "StudyRightEndpoint", "studyRightState", "commonStudyTermService", "commonCodeService", "defaultPromiseHandler", "jsDataCacheHelper"];
  angular.module(commonStudyRightServiceModule, [errorServiceModule, educationModelModule, organisationModelModule, studentApplicationModelModule, studyRightExpirationRulesModelModule, degreeProgrammeModelModule, studyRightModelModule, commonCurriculumPeriodServiceModule, jsDataRelationHelperModule, commonStudyTermServiceModule, CommonCodeService.downgrade.moduleName, jsDataCacheHelperModule]).constant('studyRightState', {
    NOT_STARTED: 'NOT_STARTED',
    ACTIVE: 'ACTIVE',
    ACTIVE_NONATTENDING: 'ACTIVE_NONATTENDING',
    PASSIVE: 'PASSIVE',
    GRADUATED: 'GRADUATED',
    EXPIRED: 'EXPIRED',
    RESCINDED: 'RESCINDED',
    CANCELLED_BY_ADMINISTRATION: 'CANCELLED_BY_ADMINISTRATION'
  }).factory('commonStudyRightService', studyRightService);

  /**
   * @ngInject
   */
  function studyRightService(
  // NOSONAR
  $q, $log, $http, commonCurriculumPeriodService, jsDataRelationHelperService, studyRightJSDataModel, educationJSDataModel, organisationJSDataModel, studyRightExpirationRulesJSDataModel, StudyRightEndpoint, studyRightState, commonStudyTermService, commonCodeService, defaultPromiseHandler, jsDataCacheHelper) {
    var api = {
      inject,
      eject,
      initStudyRight: initStudyRight,
      create: create,
      update: update,
      revert: revert,
      findById,
      findByIds,
      studyRights: getStudyRights,
      hasStudyRights: hasStudyRights,
      getStudyRightByEducation: getStudyRightByEducation,
      isValid: isValid,
      loadStudyRightRelations: loadStudyRightRelations,
      findByPersonId: findByPersonId,
      findStudyRightIdsWithFutureSnapshot: findStudyRightIdsWithFutureSnapshot,
      getEducationOptionNamingTypes: getEducationOptionNamingTypes,
      getValidStudyRightsByPersonIdEducationIds: getValidStudyRightsByPersonIdEducationIds,
      getValidStudyRightByEducationId: getValidStudyRightByEducationId,
      getValidStudyRightsByEducationIds: getValidStudyRightsByEducationIds,
      getStudyRightsAffectingPlanByEducationIds: getStudyRightsAffectingPlanByEducationIds,
      filterStudyRightsAffectingPlansByEducationIds: filterStudyRightsAffectingPlansByEducationIds,
      cancelStudyRight: cancelStudyRight,
      save: save,
      requestSelectionPath: requestSelectionPath,
      extendStudyRight: extendStudyRight,
      removeStudyRightExtension: removeStudyRightExtension,
      getStudyRightExtensionEndDate: getStudyRightExtensionEndDate,
      getStudyRightExtensionValidity: getStudyRightExtensionValidity,
      getSpentStudyTerms,
      addPhase1MinorSelection: addPhase1MinorSelection,
      addPhase2MinorSelection: addPhase2MinorSelection,
      deletePhase1MinorSelection: deletePhase1MinorSelection,
      deletePhase2MinorSelection: deletePhase2MinorSelection,
      addModuleSelection,
      deleteModuleSelection,
      addCourseUnitSelection,
      deleteCourseUnitSelection,
      saveCooperationNetworkRight,
      deleteCooperationNetworkRight,
      getMinorSelectionGroupIds,
      getLearningOpportunity: getLearningOpportunity,
      allowedToUpdateFutureSnapshot: allowedToUpdateFutureSnapshot,
      getPersonalizedSelectionPathGroupIds: getPersonalizedSelectionPathGroupIds,
      resolveEducationClassificationUrnForPhase: resolveEducationClassificationUrnForPhase,
      updateStudyRightGraduationsForPhase: updateStudyRightGraduationsForPhase,
      getStudyRightIdsByEducationId,
      getStudyRightIdsByLearningOpportunityId,
      getValidCooperationNetworkIds
    };
    return api;
    function inject(studyRight) {
      return studyRightJSDataModel.inject(studyRight);
    }
    function eject(studyRightId) {
      return studyRightJSDataModel.eject(studyRightId);
    }

    /**
     * Initializes a new StudyRight instance, but does not persist it.
     */
    function initStudyRight(education, studentId, learningOpportunityId) {
      var params = {
        studentId: studentId,
        educationId: _.get(education, 'id'),
        learningOpportunityId: learningOpportunityId,
        state: 'ACTIVE',
        valid: {},
        decreeOnUniversityDegreesUrn: _.get(education, 'defaultDecreeOnUniversityDegreesUrn')
      };
      if (_.get(education, 'organisations', []).length === 1) {
        params.organisationId = education.organisations[0].organisationId;
      }
      var studyRight = studyRightJSDataModel.createInstance(params);
      return studyRightJSDataModel.loadRelations(studyRight).then(function (studyRight) {
        return commonCurriculumPeriodService.getNextCurriculumPeriod().then(function (period) {
          if (_.has(period, 'activePeriod.startDate')) {
            studyRight.valid.startDate = period.activePeriod.startDate;
          }
          return studyRight;
        });
      });
    }
    function create(studyRight) {
      return studyRightJSDataModel.create(studyRight).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    }
    function update(studyRight) {
      return studyRightJSDataModel.update(studyRight.id, studyRight);
    }
    function revert(studyRight) {
      return studyRightJSDataModel.revert(studyRight);
    }
    function findById(studyRightId, bypassCache, loadRelations) {
      const options = {
        bypassCache: !!bypassCache
      };
      return studyRightJSDataModel.find(studyRightId, options).then(studyRight => {
        if (loadRelations) {
          return api.loadStudyRightRelations([studyRight], bypassCache);
        }
        return [studyRight];
      }).then(studyRights => studyRights[0]);
    }
    function findByIds(ids) {
      return jsDataCacheHelper.findByIds(studyRightJSDataModel, ids);
    }
    function getStudyRights(bypassCache, loadRelations) {
      var options = {
        bypassCache: !!bypassCache,
        endpoint: StudyRightEndpoint.OWN
      };
      return studyRightJSDataModel.findAll({}, options).then(function (studyRights) {
        if (!!loadRelations) {
          return api.loadStudyRightRelations(studyRights, bypassCache);
        } else {
          return studyRights;
        }
      });
    }
    function hasStudyRights(educationId) {
      return api.studyRights(false, false).then(function (studyRight) {
        return _.some(studyRight, {
          educationId: educationId
        });
      });
    }
    function getStudyRightByEducation(education) {
      return api.studyRights(false, false).then(function (studyRights) {
        studyRights = _.filter(studyRights, {
          educationId: education.id
        });
        studyRights = _.filter(studyRights, _.partial(api.isValid, moment()));
        return studyRights;
      });
    }
    function isValid(compareDate, studyRight) {
      if (!studyRight) {
        return false;
      }
      if (!_.includes([studyRightState.ACTIVE, studyRightState.ACTIVE_NONATTENDING, studyRightState.NOT_STARTED, studyRightState.PASSIVE], studyRight.state)) {
        return false;
      }
      var studyRightEndDate = _.get(studyRight.valid, 'endDate');
      if (studyRightEndDate) {
        return compareDate.isBetween(studyRight.valid.startDate, studyRightEndDate, 'days', '[)');
      } else {
        return compareDate.isSameOrAfter(studyRight.valid.startDate, 'days');
      }
    }
    function loadStudyRightRelations(studyRights, bypassCache, relations) {
      if (!_.isArray(relations) || _.isEmpty(relations)) {
        relations = [educationJSDataModel, organisationJSDataModel];
      }
      function loadRelations(jsDataModel) {
        return jsDataRelationHelperService.loadRelationForArray(jsDataModel, studyRightJSDataModel, studyRights, bypassCache);
      }
      var loads = _.map(relations, loadRelations);
      loads.push(studyRightExpirationRulesJSDataModel.lazyGetAll());
      return $q.all(loads).then(function () {
        return studyRights;
      });
    }
    function findByPersonId(personId, bypassCache, loadRelations) {
      const options = {
        bypassCache: !!bypassCache,
        endpoint: StudyRightEndpoint.DEFAULT,
        onConflict: 'replace'
      };
      return studyRightJSDataModel.findAll({
        studentId: personId
      }, options).then(studyRights => {
        if (loadRelations) {
          return api.loadStudyRightRelations(studyRights, bypassCache);
        }
        return studyRights;
      });
    }
    function findStudyRightIdsWithFutureSnapshot(personId) {
      return $http.get(`/ori/api/study-rights/with-future-snapshot/${personId}`).then(result => result.data);
    }
    function save(studyRight) {
      return studyRightJSDataModel.save(studyRight).then(function (studyRight) {
        return api.loadStudyRightRelations([studyRight], true).then(function (studyRights) {
          return _.first(studyRights);
        });
      });
    }
    function requestSelectionPath(studyRight) {
      return $http.put([StudyRightEndpoint.DEFAULT, studyRight.id, 'request-selection-path'].join('/'), studyRight).then(function (response) {
        var options = {
          onConflict: 'replace'
        };
        var updatedSR = studyRightJSDataModel.inject(response.data, options);
        return api.loadStudyRightRelations([updatedSR], true).then(function (studyRights) {
          return _.first(studyRights);
        });
      });
    }
    function getEducationOptionNamingTypes() {
      return commonCodeService.getCodebook('urn:code:education-option-naming-type');
    }
    function getValidStudyRightsByPersonIdEducationIds(personId, bypassCache, educationIds) {
      return api.findByPersonId(personId, bypassCache).then(function (studyRights) {
        return filterValidEducations(studyRights, educationIds);
      });
    }
    function getValidStudyRightByEducationId(educationId) {
      return getValidStudyRightsByEducationIds(true, [educationId]).then(function (studyRights) {
        return _.first(studyRights);
      });
    }
    function getValidStudyRightsByEducationIds(bypassCache, educationIds) {
      return api.studyRights(bypassCache, false).then(function (studyRights) {
        return filterValidEducations(studyRights, educationIds);
      });
    }
    function getStudyRightsAffectingPlanByEducationIds(educationIds, bypassCache) {
      return api.studyRights(bypassCache, false).then(function (studyRights) {
        return filterStudyRightsAffectingPlansByEducationIds(studyRights, educationIds);
      });
    }
    function filterStudyRightsAffectingPlansByEducationIds(studyRights, educationIds) {
      const currentMoment = moment();
      const resultStudyRights = _.filter(studyRights, studyRight => {
        if (!_.includes(educationIds, studyRight.educationId)) {
          return false;
        }
        if (!_.includes([studyRightState.ACTIVE, studyRightState.ACTIVE_NONATTENDING, studyRightState.NOT_STARTED, studyRightState.PASSIVE], studyRight.state)) {
          return false;
        }
        const studyRightEndDate = _.get(studyRight.valid, 'endDate');
        if (studyRightEndDate && currentMoment.isSameOrAfter(studyRightEndDate, 'days')) {
          return false;
        }
        return true;
      });
      return resultStudyRights;
    }
    function cancelStudyRight(studyRightId, studyRightCancellation) {
      return $http.put([StudyRightEndpoint.DEFAULT, studyRightId, 'cancel'].join('/'), studyRightCancellation).then(function (response) {
        return studyRightJSDataModel.inject(response.data);
      });
    }
    function extendStudyRight(studyRightId, extensionCount, extensionStartDate, extensionReason) {
      return $http.post("/ori/api/study-rights/" + studyRightId + "/extensions", {
        extensionCount: extensionCount,
        extensionStartDate: extensionStartDate,
        grantReason: extensionReason
      }).then(function (response) {
        return studyRightJSDataModel.inject(response.data);
      });
    }
    function removeStudyRightExtension(studyRightId, studyRightExtensionId, removalReason) {
      return $http.put("/ori/api/study-rights/" + studyRightId + "/extensions/" + studyRightExtensionId, {
        "deleteReason": removalReason
      }).then(function (response) {
        return studyRightJSDataModel.inject(response.data);
      });
    }
    function getStudyRightExtensionEndDate(extension) {
      const validity = api.getStudyRightExtensionValidity(extension);
      return _.isNil(validity) ? null : moment(validity.endDate).subtract(1, 'days');
    }
    function getStudyRightExtensionValidity(extension) {
      const startDate = moment(_.get(extension, 'extensionStartDate'), moment.ISO_8601);
      const semesters = _.toSafeInteger(_.get(extension, 'extensionCount'));
      if (!startDate.isValid() || semesters < 1) {
        return null;
      }
      const semesterStart = startDate.month(startDate.month() < 7 ? 0 : 7).date(1);
      for (let i = 0; i < semesters; i++) {
        if (semesterStart.month() === 0) {
          semesterStart.month(7);
        } else {
          semesterStart.add(1, 'years').month(0);
        }
      }
      return {
        startDate: extension.extensionStartDate,
        endDate: semesterStart.format('YYYY-MM-DD')
      };
    }
    function filterValidEducations(studyRights, educationIds) {
      var finalRights = [];
      _.forEach(educationIds, function (educationId) {
        var eduStudyRights = _.filter(studyRights, {
          educationId: educationId
        });
        if (eduStudyRights) {
          eduStudyRights = _.filter(eduStudyRights, _.partial(api.isValid, moment()));
          finalRights = _.concat(finalRights, eduStudyRights);
        }
      });
      return _.uniq(finalRights);
    }
    function getSpentStudyTerms(studyRight) {
      if (!studyRight || _.isEmpty(studyRight.valid)) {
        return null;
      }
      const spentTerms = {
        usedAttendanceTerms: _.get(studyRight, 'studyRightTransfer.usedTerms', 0),
        usedAbsenceTerms: _.get(studyRight, 'studyRightTransfer.usedAbsenceTerms', 0),
        usedStatutoryAbsenceTerms: _.get(studyRight, 'studyRightTransfer.usedStatutoryAbsenceTerms', 0),
        termsWithoutRegistration: 0
      };
      if (moment().isBefore(studyRight.valid.startDate, 'day')) {
        return spentTerms;
      }
      const registrations = studyRight.termRegistrations || [];
      const endBoundary = studyRight.valid.endDate ? moment.min(moment(), moment(studyRight.valid.endDate).subtract(1, 'day')) : moment();
      let locator = commonStudyTermService.getStudyTermLocator(studyRight.valid.startDate);
      while (commonStudyTermService.getTermStartDate(locator).isSameOrBefore(endBoundary, 'day')) {
        const {
          studyYearStartYear,
          termIndex
        } = locator;
        const registration = registrations.find(({
          studyTerm
        }) => studyTerm.studyYearStartYear === studyYearStartYear && studyTerm.termIndex === termIndex);
        if (!registration || registration.termRegistrationType === 'MISSING' || registration.termRegistrationType === 'NEGLECTED') {
          spentTerms.termsWithoutRegistration += 1;
        } else if (registration.termRegistrationType === 'ATTENDING') {
          spentTerms.usedAttendanceTerms += 1;
        } else if (registration.termRegistrationType === 'NONATTENDING') {
          if (registration.statutoryAbsence) {
            spentTerms.usedStatutoryAbsenceTerms += 1;
          } else {
            spentTerms.usedAbsenceTerms += 1;
          }
        }
        locator = commonStudyTermService.getNextStudyTermLocator(commonStudyTermService.getTermStartDate(locator));
      }
      return spentTerms;
    }
    function addPhase1MinorSelection(studyRightId, minorSelection) {
      return $http.post(`${StudyRightEndpoint.DEFAULT}/${studyRightId}/minor-selections/phase1`, minorSelection).then(response => studyRightJSDataModel.inject(response.data));
    }
    function addPhase2MinorSelection(studyRightId, minorSelection) {
      return $http.post(`${StudyRightEndpoint.DEFAULT}/${studyRightId}/minor-selections/phase2`, minorSelection).then(response => studyRightJSDataModel.inject(response.data));
    }
    function deletePhase1MinorSelection(studyRightId, moduleGroupId) {
      return $http.delete(`${StudyRightEndpoint.DEFAULT}/${studyRightId}/minor-selections/phase1/${moduleGroupId}`).then(response => studyRightJSDataModel.inject(response.data));
    }
    function deletePhase2MinorSelection(studyRightId, moduleGroupId) {
      return $http.delete(`${StudyRightEndpoint.DEFAULT}/${studyRightId}/minor-selections/phase2/${moduleGroupId}`).then(response => studyRightJSDataModel.inject(response.data));
    }
    function addModuleSelection(studyRightId, moduleSelection) {
      return $http.post(`${StudyRightEndpoint.DEFAULT}/${studyRightId}/module-selections`, moduleSelection).then(response => studyRightJSDataModel.inject(updateValidOnNullValues(response.data)));
    }
    function deleteModuleSelection(studyRightId, localId) {
      return $http.delete(`${StudyRightEndpoint.DEFAULT}/${studyRightId}/module-selections/${localId}`).then(response => studyRightJSDataModel.inject(updateValidOnNullValues(response.data)));
    }
    function addCourseUnitSelection(studyRightId, courseUnitSelection) {
      return $http.post(`${StudyRightEndpoint.DEFAULT}/${studyRightId}/course-unit-selections`, courseUnitSelection).then(response => studyRightJSDataModel.inject(updateValidOnNullValues(response.data)));
    }
    function deleteCourseUnitSelection(studyRightId, localId) {
      return $http.delete(`${StudyRightEndpoint.DEFAULT}/${studyRightId}/course-unit-selections/${localId}`).then(response => studyRightJSDataModel.inject(updateValidOnNullValues(response.data)));
    }
    function saveCooperationNetworkRight(studyRightId, networkRight) {
      return $http.post(`${StudyRightEndpoint.DEFAULT}/${studyRightId}/cooperation-network-rights`, networkRight).then(response => studyRightJSDataModel.inject(updateValidOnNullValues(response.data)));
    }
    function deleteCooperationNetworkRight(studyRightId, localId) {
      return $http.delete(`${StudyRightEndpoint.DEFAULT}/${studyRightId}/cooperation-network-rights/${localId}`).then(response => studyRightJSDataModel.inject(updateValidOnNullValues(response.data)));
    }

    /**
     * This function is necessary, because studyRight.valid doesn't get properly updated if startDate or endDate
     * becomes null. This ensures that {startDate: x} equals {startDate: x, endDate: null}, which fixes jsData injection.
     */
    function updateValidOnNullValues(studyRight) {
      if (!studyRight) {
        return {};
      }
      if (!studyRight.valid) {
        studyRight.valid = {
          startDate: null,
          endDate: null
        };
      }
      if (!studyRight.valid.startDate) {
        studyRight.valid.startDate = null;
      }
      if (!studyRight.valid.endDate) {
        studyRight.valid.endDate = null;
      }
      return studyRight;
    }
    function getMinorSelectionGroupIds(studyRight) {
      return _.chain(['phase1MinorSelections', 'phase2MinorSelections']).flatMap(prop => _.get(studyRight, prop, [])).map('moduleGroupId').compact().uniq().value();
    }
    function getLearningOpportunity(studyRight, education) {
      return _.find(_.get(education, 'structure.learningOpportunities', []), {
        localId: _.get(studyRight, 'learningOpportunityId')
      });
    }
    function allowedToUpdateFutureSnapshot(studyRight) {
      return $http.get(`/ori/api/study-rights/${studyRight.id}/allowed-to-update-future-study-right`).then(response => response.data);
    }
    function getPersonalizedSelectionPathGroupIds(studyRight) {
      return _.compact([_.get(studyRight, 'personalizedSelectionPath.phase1.moduleGroupId'), _.get(studyRight, 'personalizedSelectionPath.phase1.childModuleGroupId'), _.get(studyRight, 'personalizedSelectionPath.phase2.moduleGroupId'), _.get(studyRight, 'personalizedSelectionPath.phase2.childModuleGroupId')]);
    }
    function resolveEducationClassificationUrnForPhase(education, studyRight, phaseIndex) {
      if (!_.get(studyRight, `phase${phaseIndex}EducationClassificationUrn`) && (_.get(studyRight, `studyRightGraduation.phase${phaseIndex}GraduationDate`) || _.get(studyRight, `personalizedSelectionPath.phase${phaseIndex}`))) {
        return getEducationClassificationUrnResult(null, 'COMMON_STUDY_RIGHT_SERVICE.EDUCATION_CLASSIFICATION_SET_MANUALLY_TO_NULL');
      }
      if (_.get(studyRight, `studyRightGraduation.phase${phaseIndex}GraduationDate`)) {
        return getEducationClassificationUrnResult(null, 'COMMON_STUDY_RIGHT_SERVICE.EDUCATION_CLASSIFICATION_SET_MANUALLY_WITH_GRADUATION');
      }
      if (_.get(studyRight, `personalizedSelectionPath.phase${phaseIndex}`)) {
        return getEducationClassificationUrnResult(null, 'COMMON_STUDY_RIGHT_SERVICE.EDUCATION_CLASSIFICATION_SET_MANUALLY_WITH_PERSONALIZATION');
      }
      if (_.get(studyRight, `phase${phaseIndex}EducationClassificationLocked`)) {
        return getEducationClassificationUrnResult(null, 'COMMON_STUDY_RIGHT_SERVICE.EDUCATION_CLASSIFICATION_SET_MANUALLY');
      }
      const phaseGroupId = _.get(studyRight, `acceptedSelectionPath.educationPhase${phaseIndex}GroupId`);
      const phaseChildGroupId = _.get(studyRight, `acceptedSelectionPath.educationPhase${phaseIndex}ChildGroupId`);
      if (phaseGroupId) {
        const educationOptions = _.get(education, `structure.phase${phaseIndex}.options`);
        const educationOption = _.find(educationOptions, eo => eo.moduleGroupId === phaseGroupId);
        if (educationOption && phaseChildGroupId) {
          const educationChildOptions = _.get(educationOption, 'childOptions');
          const childOption = _.find(educationChildOptions, eco => eco.moduleGroupId === phaseChildGroupId);
          if (childOption && childOption.educationClassificationUrn) {
            return getEducationClassificationUrnResult(childOption.educationClassificationUrn, 'COMMON_STUDY_RIGHT_SERVICE.EDUCATION_CLASSIFICATION_BASED_ON_EDUCATION_CHILD_OPTION');
          }
        }
        if (educationOption && educationOption.educationClassificationUrn) {
          return getEducationClassificationUrnResult(educationOption.educationClassificationUrn, 'COMMON_STUDY_RIGHT_SERVICE.EDUCATION_CLASSIFICATION_BASED_ON_EDUCATION_OPTION');
        }
      }
      const learningOpportunities = _.get(education, 'structure.learningOpportunities');
      const learningOpportunity = _.find(learningOpportunities, lo => lo.localId === studyRight.learningOpportunityId);
      const educationClassificationUrn = _.get(learningOpportunity, `phase${phaseIndex}EducationClassificationUrn`);
      if (educationClassificationUrn) {
        return getEducationClassificationUrnResult(educationClassificationUrn, 'COMMON_STUDY_RIGHT_SERVICE.EDUCATION_CLASSIFICATION_BASED_ON_LEARNING_OPPORTUNITY');
      }
      return getEducationClassificationUrnResult(null, 'COMMON_STUDY_RIGHT_SERVICE.EDUCATION_CLASSIFICATION_COULD_NOT_BE_RESOLVED');
    }
    function updateStudyRightGraduationsForPhase(studyRight, degreeProgrammeAttainments, phaseIndex) {
      const phaseGroupId = _.get(studyRight, `acceptedSelectionPath.educationPhase${phaseIndex}GroupId`);
      const matchingDegreeProgrammeAttainment = _.find(degreeProgrammeAttainments, {
        moduleGroupId: phaseGroupId
      });
      const phaseGraduationDateKey = `phase${phaseIndex}GraduationDate`;
      const studyRightGraduation = _.get(studyRight, 'studyRightGraduation');
      if (matchingDegreeProgrammeAttainment) {
        if (studyRightGraduation) {
          _.set(studyRightGraduation, phaseGraduationDateKey, matchingDegreeProgrammeAttainment.attainmentDate);
        } else if (phaseIndex === 1) {
          studyRight.studyRightGraduation = {};
          _.set(studyRight.studyRightGraduation, phaseGraduationDateKey, matchingDegreeProgrammeAttainment.attainmentDate);
        } else {
          studyRight.studyRightGraduation = null;
        }
        return;
      }
      if (phaseIndex === 1 || !studyRightGraduation) {
        studyRight.studyRightGraduation = null;
      } else {
        _.set(studyRightGraduation, phaseGraduationDateKey, null);
      }
    }
    function getEducationClassificationUrnResult(educationClassificationUrn, helpTextKey) {
      return {
        urn: educationClassificationUrn,
        helpTextKey
      };
    }
    function getStudyRightIdsByEducationId(educationId) {
      return $http.get(`${StudyRightEndpoint.DEFAULT}/study-right-ids-by-education`, {
        params: {
          educationId
        }
      }).then(response => response.data);
    }
    function getStudyRightIdsByLearningOpportunityId(educationId, learningOpportunityId) {
      return $http.get(`${StudyRightEndpoint.DEFAULT}/study-right-ids-by-learning-opportunity`, {
        params: {
          educationId,
          learningOpportunityId
        }
      }).then(response => response.data);
    }
    function getValidCooperationNetworkIds(studyRight) {
      const currentMoment = moment();
      return _.chain(studyRight.cooperationNetworkRights).filter(cooperationNetworkRight => !(cooperationNetworkRight.validityPeriod?.endDate && currentMoment.isSameOrAfter(cooperationNetworkRight.validityPeriod.endDate))).map(cooperationNetworkRight => cooperationNetworkRight.cooperationNetworkId).value();
    }
  }
})();