import { ChangeDetectionStrategy, Component, Inject, inject, ViewEncapsulation } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LocalizedString } from 'common-typescript/types';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { ModalService } from 'sis-common/modal/modal.service';

export interface AccessDeniedModalValues {
    expectedPermissions: LocalizedString[];
}

export function accessDeniedModalOpener(): (values: AccessDeniedModalValues) => NgbModalRef {
    const modalService = inject(ModalService);
    return values => modalService.open(AccessDeniedModalComponent, values, { size: 'sm', closeWithOutsideClick: true });
}

@Component({
    selector: 'sis-access-denied-modal',
    templateUrl: './access-denied-modal.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessDeniedModalComponent {

    localizedNames: string[] = [];

    constructor(
        localeService: LocaleService,
        @Inject(ModalService.injectionToken) protected values: AccessDeniedModalValues,
    ) {
        this.localizedNames = (values?.expectedPermissions ?? [])
            .map(permissionName => localeService.localize(permissionName))
            .filter(Boolean)
            .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
    }
}
