import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { OtmId,
} from 'common-typescript/types';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { OrganisationNameService } from '../../organisation/organisation-name.service';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-prior-learning-substitutions',
    templateUrl: './prior-learning-substitutions.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class PriorLearningSubstitutionsComponent implements OnInit {
    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.courseUnit.prior-learning-substitutions.sisPriorLearningSubstitutions.downgraded',
        directiveName: 'sisPriorLearningSubstitutions',
    };

    /**
     * Determines heading's semantic aria-level for screen readers, default is equivalent for h3
     */
    @Input() level?: number = 3;
    @Input() courseUnit: any;
    @Input() contentLanguage: any;
    @Input() hasValidatablePlan: boolean;
    @Output() openCourseUnitModalEvent = new EventEmitter<OtmId>();
    universityName: string;

    constructor(private organisationNameService: OrganisationNameService) {
    }

    ngOnInit(): void {
        this.updateUniversityName();
    }

    private updateUniversityName() {
        if (this.courseUnit.universityOrgIds.length > 0) {
            this.organisationNameService.getOrgName(this.courseUnit.universityOrgIds[0]).subscribe((name: string) => {
                this.universityName = name;
            });
        } else {
            this.universityName = null;
        }
    }

}
