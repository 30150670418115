import { Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { Enrolment } from 'common-typescript/types';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'frontpage' })
export class FrontpageDirective extends UpgradeComponent {

    @Input() userHasPlans: boolean;
    @Input() enrolments: Enrolment[];

    constructor(elementRef: ElementRef<any>, injector: Injector) {
        super('frontpage', elementRef, injector);
    }
}
