import angular from 'angular';
import 'js-data';
export const decreeOnUniversityDegreesModelModule = 'sis-components.model.decreeOnUniversityDegrees';
(function () {
  initDecreeOnUniversityDegreesModel.$inject = ["$http", "$log", "decreeOnUniversityDegreesJSDataModel"];
  decreeOnUniversityDegreesJSDataModel.$inject = ["DS"];
  angular.module(decreeOnUniversityDegreesModelModule, ['js-data']).factory('decreeOnUniversityDegreesJSDataModel', decreeOnUniversityDegreesJSDataModel).run(initDecreeOnUniversityDegreesModel);

  /**
   * @ngInject
   */
  function decreeOnUniversityDegreesJSDataModel(DS) {
    return DS.defineResource({
      idAttribute: 'urn',
      name: 'decreeOnUniversityDegrees',
      endpoint: '/kori/api/cached/codebooks/urn:code:decree-on-university-degrees',
      watchChanges: false
    });
  }
  function initDecreeOnUniversityDegreesModel($http, $log, decreeOnUniversityDegreesJSDataModel) {
    $http.get(decreeOnUniversityDegreesJSDataModel.endpoint).then(function (response) {
      decreeOnUniversityDegreesJSDataModel.inject(_.values(response.data));
    }).catch(function (error) {
      $log.error(error);
    });
  }
})();