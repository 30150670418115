import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Literature, LiteratureName, LiteratureReference } from 'common-typescript/types';

/**
 * @deprecated Use `LiteratureComponent` instead.
 */
@Pipe({ name: 'literature' })
export class LiteraturePipe implements PipeTransform {

    constructor(private translate: TranslateService) {}

    transform(value: Literature): string {
        let retval;
        let literature: LiteratureName | LiteratureReference;
        const textForScreenReaders = this.translate.instant('ARIA_LABEL.OPENS_TO_A_NEW_TAB');

        if (value) {
            switch (value.type) {
                case 'LiteratureName':
                    literature = value as LiteratureName;
                    retval = literature.name;
                    break;
                case 'LiteratureReference':
                    literature = value as LiteratureReference;
                    retval = `<a href='${literature.url}' target='_blank'><i class="SISUicon-External" aria-hidden="true"></i>${literature.url}<span class="visually-hidden">${textForScreenReaders}</span></a>`;
                    break;
                default:
                    break;
            }
        }
        return retval;
    }
}
