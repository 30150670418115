calendarSettingsModalService.$inject = ["modalService"];
calendarSettingsModalController.$inject = ["defaultPromiseHandler", "calendarEventService", "localeService", "$location"];
import angular from 'angular';
import { ModalService } from 'sis-common/modal/modal.service.ts';
import { calendarEventServiceModule } from 'sis-components/calendar/calendarEvent.service';
import { TooltipComponent } from 'sis-components/tooltip/tooltip.component.ts';
import { CalendarSettingsModalComponent } from './calendarSettingsModal.component.ts';
import calendarSettingsModalTpl from './calendarSettingsModal.tpl.html';
export const calendarSettingsModalServiceModule = 'sis-components.calendar.service.calendarSettingsModal';
angular.module(calendarSettingsModalServiceModule, ['sis-common.l10n.localeService', calendarEventServiceModule, 'ngclipboard', TooltipComponent.downgrade.moduleName, ModalService.downgrade.moduleName]).component('calendarSettingsModal', {
  bindings: {
    dismiss: '&',
    resolve: '<'
  },
  controller: calendarSettingsModalController,
  controllerAs: 'ctrl',
  template: calendarSettingsModalTpl
}).factory('calendarSettingsModalService', calendarSettingsModalService).constant('pattern', {
  TIME: /^([0-9]|0[0-9]|1[0-9]|2[0-3])\.[0-5][0-9]$/
}).constant('dateFormat', {
  LOCAL_DATE: 'YYYY-MM-DD',
  LOCAL_DATETIME: 'YYYY-MM-DDTHH:mm',
  TIME: 'HH.mm'
});
function calendarSettingsModalService(modalService) {
  return {
    open(forTeacher) {
      return modalService.open(CalendarSettingsModalComponent, {
        forTeacher
      }, {
        closeWithOutsideClick: true,
        size: 'sm'
      }).result;
    }
  };
}
function calendarSettingsModalController(defaultPromiseHandler, calendarEventService, localeService, $location) {
  const $ctrl = this;
  $ctrl.$onInit = () => {
    $ctrl.isOpen = false;
    calendarEventService.getCalendarShareToken(localeService.getCurrentLocale(), $ctrl.resolve.forTeacher).then(result => {
      $ctrl.token = `${$location.protocol()}://${$location.host()}:${$location.port()}/ilmo/api/calendar-share/${result.token}`;
    }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
  };
}