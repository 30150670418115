import jwt_decode, { JwtPayload } from 'jwt-decode';

export type ParsedToken = JwtPayload & SisuJwtPayload;
export interface SisuJwtPayload {
    scope: string
    anyScopes: string[]
    exp: number
    roles: string
    personid: string
    userinfo: {
        callname: string,
        firstnames: string,
        lastname: string,
    }
}

export interface AuthenticatedUser {
    username: string
    scope: string
    anyScopes: string[]
    expires: number
    roles: string
    id: string
    displayname: string
    firstNameInitialLetter: string
    lastNameInitialLetter: string
}
export function parseToken(rawToken: string): ParsedToken {
    return jwt_decode(rawToken);
}
