import { Pipe, PipeTransform } from '@angular/core';
import { StudyTermLocator } from 'common-typescript/types';

@Pipe({ name: 'studyYearName' })
export class StudyYearNamePipe implements PipeTransform {

    /**
     * Returns the name of a study year (i.e. `<start-year>–<end-year>`). Accepts either a `StudyTermLocator` object or
     * the study year start year (`StudyTermLocator.studyYearStartYear`) as input.
     */
    transform(value: StudyTermLocator | number): string {
        const startYear = (typeof value === 'number') ? value : value?.studyYearStartYear;
        return Number.isSafeInteger(startYear) ? `${startYear}–${startYear + 1}` : undefined;
    }
}
