import _ from 'lodash';
import angular from 'angular';
import angularTranslate from 'angular-translate';
import { localizedStringFilterModule } from 'sis-common/l10n/localizedStringFilter';
import { localeServiceModule } from 'sis-common/l10n/localeService';
import { ModalService } from 'sis-common/modal/modal.service.ts';
import { SearchFilterOrganisationType } from 'common-typescript/types/baseTypes';
import { SelectOrganisationComponent } from '../../select/select-organisation/select-organisation.component.ts';
import { SelectEducationComponent } from '../../select/select-education/select-education.component.ts';
import { commonCourseUnitServiceModule } from '../../service/courseUnit.service';
import { commonEmployeeServiceModule } from '../../service/employee.service';
import { commonEducationServiceModule } from '../../service/education.service';
import { commonModuleServiceModule } from '../../service/module.service';
import { commonOrganisationServiceModule } from '../../service/organisation.service';
import { PopoverComponent } from '../../popover/popover.component.ts';
import { SearchFilterCourseUnitComponent } from './search-filter-course-unit.component.ts';
import { SearchFilterEducationComponent } from './search-filter-education.component.ts';
import { SearchFilterModuleComponent } from './search-filter-module.component.ts';
import { SearchFilterOrganisationComponent } from './search-filter-organisation.component.ts';
import searchFilterTpl from './searchFilter.tpl.html';
import { searchFilterDateModule } from './searchFilterDate.component';
import { searchFilterDateRangeModule } from './searchFilterDateRange.component';
import { searchFilterMultiModule } from './searchFilterMulti.component';
import { searchFilterMultiWithHeadingsModule } from './searchFilterMultiWithHeadings.component';
import { searchFilterRangeModule } from './searchFilterRange.component';
import { searchFilterResponsibilityInfosModule } from './searchFilterResponsibilityInfos.component';
import { searchFilterSalesPeriodModule } from './searchFilterSalesPeriod.component';
import { searchFilterSingleModule } from './searchFilterSingle.component';
export const searchFilterModule = 'sis-components.search.searchFilter';

/* globals $ */
(function () {
  SearchFilterController.$inject = ["$log", "$q", "$scope", "$element", "$translate", "localeService", "localizedStringFilter", "$timeout", "commonEmployeeService", "SearchFilterSettings", "SearchFilterTypes", "commonCourseUnitService", "commonEducationService", "commonOrganisationService", "commonModuleService", "modalService"];
  angular.module(searchFilterModule, [angularTranslate, localizedStringFilterModule, commonEmployeeServiceModule, localeServiceModule, commonCourseUnitServiceModule, commonEducationServiceModule, commonOrganisationServiceModule, commonModuleServiceModule, PopoverComponent.downgrade.moduleName, searchFilterDateRangeModule, SearchFilterCourseUnitComponent.downgrade.moduleName, SearchFilterEducationComponent.downgrade.moduleName, SearchFilterModuleComponent.downgrade.moduleName, searchFilterMultiModule, searchFilterMultiWithHeadingsModule, SearchFilterOrganisationComponent.downgrade.moduleName, searchFilterRangeModule, searchFilterResponsibilityInfosModule, searchFilterSalesPeriodModule, searchFilterSingleModule, searchFilterDateModule, ModalService.downgrade.moduleName]).component('searchFilter', {
    bindings: {
      hasTriggerContent: '<',
      onSelect: '&',
      option: '<',
      parameterModel: '<',
      popoverIsOpen: '=',
      // Necessary only if searchFilterType is 'Education', 'Organisation' or 'OrganisationRoots'
      searchAgain: '&?',
      // Necessary only if searchFilterType is 'Education', 'Organisation' or 'OrganisationRoots'
      searchParams: '<?',
      university: '<',
      // This fixes parameter update problem between Angular and AngularJS
      updateManually: '<?'
    },
    transclude: true,
    controller: SearchFilterController,
    template: searchFilterTpl
  }).controller('SearchFilterController', SearchFilterController).constant('SearchFilterTypes', {
    MULTI: 'Multi',
    SINGLE: 'Single',
    RANGE: 'Range',
    DATE: 'Date',
    DATE_RANGE: 'DateRange',
    SALES_PERIOD: 'SalesPeriod',
    ORGANISATION: 'Organisation',
    ORGANISATIONROOTS: 'OrganisationRoots',
    EDUCATION: 'Education',
    MODULE: 'Module',
    RESPONSIBILITY_INFOS: 'ResponsibilityInfos',
    NO_POPOVER: 'NoPopover',
    MULTI_WITH_HEADINGS: 'MultiWithHeadings',
    COURSE_UNIT: 'CourseUnit'
  }).constant('SearchFilterSettings', {
    LIMIT_WHEN_SEARCH_INPUT: 13,
    FILTERS_LIMIT: 100
  });

  /**
   * @ngInject
   */
  function SearchFilterController(
  // NOSONAR
  $log, $q, $scope, $element, $translate, localeService, localizedStringFilter, $timeout, commonEmployeeService, SearchFilterSettings, SearchFilterTypes, commonCourseUnitService, commonEducationService, commonOrganisationService, commonModuleService, modalService) {
    const ctrl = this;
    ctrl.employeeService = commonEmployeeService;
    ctrl.filtersLimit = SearchFilterSettings.FILTERS_LIMIT;
    ctrl.startIndex = 0;
    ctrl.primaryFilters = [];
    ctrl.secondaryFilters = [];
    ctrl.allFilters = [];
    ctrl.isPageRendered = false;
    ctrl.currentLocale = localeService.getCurrentLocale();
    ctrl.searchWithinFilter = '';
    ctrl.isInitialized = false;
    ctrl.searching = null;
    ctrl.titleTextKey = null;
    ctrl.placeholderTextKey = null;
    ctrl.organisations = [];
    ctrl.$onInit = () => {
      ctrl.filterValues = ctrl.option.options;
      ctrl.filterPlacement = _.get(ctrl, 'filterPlacement', 'left right');
      ctrl.searchFilterType = ctrl.option.type;
      ctrl.filterName = $translate.instant(`SEARCH.FILTER_TAGS.${ctrl.option.name.toUpperCase()}`);
      ctrl.titleTextKey = _.get(ctrl, 'option.titleTextKey', null);
      ctrl.placeholderTextKey = _.get(ctrl, 'option.placeholderTextKey', null);
      if (ctrl.updateManually !== undefined) {
        // eslint-disable-next-line no-unused-vars
        ctrl.updateManually.subscribe(() => {
          $scope.$apply(); // triggers a digest cycle
        });
      }

      // This empty $timeout forces search filters to appear. The dropdown menu had empty items on initial load that appeared after
      // some time.
      $timeout(() => {});
    };
    ctrl.isParentFilter = filter => filter && filter.isLeafNode === false;
    const resolveNameForStudyTerm = filter => `${filter.studyYearStart}-${filter.studyYearEnd}: ${localizedStringFilter(filter.name)}`;
    ctrl.getFilterName = filter => {
      if (filter.constructor.name === 'CurriculumPeriod') {
        return localizedStringFilter(filter.abbreviation);
      }
      if (typeof filter.name === 'string') {
        return $translate.instant(filter.name);
      }
      if (filter.studyYearStart && filter.studyYearEnd) {
        return resolveNameForStudyTerm(filter);
      }
      return localizedStringFilter(filter.name);
    };
    ctrl.filterMatchesSearch = filter => {
      if (ctrl.searchWithinFilter.length === 0) {
        return true;
      }
      const searchTerms = ctrl.searchWithinFilter.toLowerCase();
      const searchWords = searchTerms.split(' ');
      let name;
      if (!filter.name) {
        return false;
      }
      if (typeof filter.name === 'string') {
        name = filter.name.toLowerCase();
      } else {
        name = localizedStringFilter(filter.name).toLowerCase();
      }
      if (filter.startYear) {
        name += ` ${filter.startYear}`;
      }
      const atLeastOneNotMatching = _.some(searchWords, word => name.indexOf(word) === -1);
      return !atLeastOneNotMatching;
    };
    ctrl.getSelectionNames = () => {
      if (ctrl.clonedParameterModel) {
        return ctrl.clonedParameterModel.displayValue();
      }
    };
    ctrl.selectedCredits = {};
    ctrl.submitFilter = () => {
      ctrl.popoverIsOpen = false;
      ctrl.onSelect({
        values: ctrl.listSelected()
      });
    };
    ctrl.submitRangeFilter = () => {
      ctrl.popoverIsOpen = false;
      if (!ctrl.selectedCredits.min) {
        ctrl.selectedCredits.min = 0;
      }
      ctrl.onSelect({
        values: ctrl.selectedCredits
      });
    };
    ctrl.onDateRangeChange = () => {
      if (!_.get(ctrl.clonedParameterModel, 'value.startDate')) {
        _.unset(ctrl.clonedParameterModel, 'value.endDate');
      }
    };
    function markFormAsDirty(form) {
      if (form && form.$setDirty) {
        // AngularJS
        form.$setDirty();
      } else if (form && form.markAsDirty) {
        // Angular
        form.markAsDirty();
      } else {
        throw new Error(`Cannot mark unexpected form as dirty: ${JSON.stringify(form)}`);
      }
    }
    ctrl.setValue = (value, form) => {
      if (ctrl.clonedParameterModel) {
        markFormAsDirty(form);
        ctrl.clonedParameterModel.removeAllValues();
        ctrl.clonedParameterModel.toggleValue(value);
      }
    };
    ctrl.toggleValue = (value, form, event) => {
      if (event) {
        // Stop events from propagating: a propagated mouse click will close the filter item list too early,
        // which makes the click happen outside the item list and thus closes the whole filter dropdown.
        // This behavior happens consistently in student but never in staff, and the difference is still a
        // mystery.
        //
        // The solution was found from a similar issue in https://github.com/angular-ui/bootstrap/issues/5628.
        event.stopPropagation();
      }
      if (ctrl.clonedParameterModel) {
        markFormAsDirty(form);
        ctrl.clonedParameterModel.toggleValue(value);
      }
    };
    ctrl.toggleChildValue = (value, childValue, form, event) => {
      if (event) {
        // Stop events from propagating: a propagated mouse click will close the filter item list too early,
        // which makes the click happen outside the item list and thus closes the whole filter dropdown.
        // This behavior happens consistently in student but never in staff, and the difference is still a
        // mystery.
        //
        // The solution was found from a similar issue in https://github.com/angular-ui/bootstrap/issues/5628.
        event.stopPropagation();
      }
      if (ctrl.clonedParameterModel) {
        markFormAsDirty(form);
        ctrl.clonedParameterModel.toggleChildValue(value, childValue);
      }
    };
    ctrl.isValueSelected = value => {
      if (ctrl.clonedParameterModel) {
        return ctrl.clonedParameterModel.isSelected(value);
      }
    };
    ctrl.isAnyChecked = () => {
      if (ctrl.clonedParameterModel) {
        return ctrl.clonedParameterModel.isValidForSearch();
      }
    };
    ctrl.listSelected = () => {
      if (ctrl.clonedParameterModel) {
        return ctrl.clonedParameterModel.getValues();
      }
    };
    ctrl.populateSelectedValuesAndReturnFirst = filters => {
      ctrl.clonedParameterModel = ctrl.parameterModel.clone();
      if (ctrl.searchFilterType === SearchFilterTypes.RANGE) {
        ctrl.selectedCredits = ctrl.clonedParameterModel.getValues();
      }
      return _.findIndex(filters, filter => ctrl.isValueSelected(filter));
    };
    ctrl.clickTrigger = () => {
      if (ctrl.searchFilterType === SearchFilterTypes.ORGANISATIONROOTS) {
        ctrl.openOrganisationDialog();
      }
    };
    ctrl.focusOnMostRelevantElement = firstSelectedFilterIndex => {
      if (firstSelectedFilterIndex > SearchFilterSettings.FILTERS_LIMIT) {
        ctrl.startIndex = firstSelectedFilterIndex - Math.floor(SearchFilterSettings.FILTERS_LIMIT / 2);
      }
      $timeout(() => {
        if (firstSelectedFilterIndex === -1 && $element.find('.search-within-filter,.search-range-min,sis-typeahead input').is(':visible')) {
          $element.find('.search-within-filter,.search-range-min,sis-typeahead input').focus();
        } else {
          const filterIndex = firstSelectedFilterIndex > 0 ? firstSelectedFilterIndex : 0;
          $element.find('.filter-selectbox').find(`[data-filter-index="${filterIndex}"]`).find('input[type="checkbox"]').focus();
        }
      }, 50);
    };
    ctrl.getAllFilters = () => $q.when(ctrl.filterValues instanceof Function ? ctrl.filterValues(getUniversityOrgIds()) : ctrl.filterValues).then(filters => {
      if (filters && filters.length > SearchFilterSettings.LIMIT_WHEN_SEARCH_INPUT) {
        ctrl.filterSearch = true;
      } else {
        ctrl.filterSearch = false;
      }
      return filters;
    });
    ctrl.showAllFiltersForUser = filters => {
      ctrl.searching = false;
      const partitionedFilters = _.partition(filters, 'isSecondary');
      ctrl.primaryFilters = partitionedFilters[1];
      ctrl.secondaryFilters = partitionedFilters[0];
      if (_.some(ctrl.primaryFilters, 'headingObject')) {
        ctrl.primaryFiltersByHeading = _.groupBy(ctrl.primaryFilters, 'headingObject.id');
      }
      if (!ctrl.option.disableSortByName) {
        ctrl.primaryFilters = _.sortBy(ctrl.primaryFilters, ctrl.getFilterLocaleName);
        ctrl.secondaryFilters = _.sortBy(ctrl.secondaryFilters, ctrl.getFilterLocaleName);
      }
      ctrl.allFilters = ctrl.primaryFilters.concat(ctrl.secondaryFilters);
      return ctrl.allFilters;
    };
    ctrl.sortFiltersIfTreeStructure = filters => {
      const atLeastOneParent = _.find(filters, filter => filter.isLeafNode === false);
      if (atLeastOneParent) {
        // We only want to show filters and their top most parents
        // EducationTypes can have multiple parents chained,
        // so we need to filter those and map leafs to grand parents.
        const mappedParentUrns = {};
        return _(filters).filter(filter => {
          if (filter.isLeafNode === false && filter.parentUrn) {
            mappedParentUrns[filter.urn] = filter.parentUrn;
            return false;
          }
          return true;
        }).map(filter => {
          filter.parentUrn = mappedParentUrns[filter.parentUrn] || filter.parentUrn;
          return filter;
        }).orderBy(a => a.parentUrn ? a.parentUrn + a.urn : a.urn).value();
      }
      return filters;
    };

    // If we get error response, dont break down!
    ctrl.inCaseOfError = error => {
      ctrl.searching = false;
      ctrl.primaryFilters = [];
      ctrl.secondaryFilters = [];
      ctrl.allFilters = [];
    };
    ctrl.getFilterLocaleName = filterValue => filterValue.name[ctrl.currentLocale];
    ctrl.getFilterIndex = filterValue => _.findIndex(ctrl.allFilters, {
      name: filterValue.name
    });
    ctrl.getEducations = searchString => {
      const query = {
        name: searchString,
        universityOrgId: getUniversityOrgIds()
      };
      _.assign(query, ctrl.option.searchDefaults);
      return commonEducationService.findAll(query, {
        bypassCache: true
      }).then(data => {
        const currentLang = localeService.getCurrentLocale();
        const sortedEducations = _.sortBy(data, `name.${currentLang}`);
        _.forEach(sortedEducations, education => {
          // TODO ENRICHMENT
          education.children = _.map(_.get(education, 'structure.learningOpportunities'), lo => _.pick(lo, ['localId', 'name']));
        });
        return sortedEducations;
      });
    };
    ctrl.getCourseUnits = searchString => {
      const query = {
        fullTextQuery: searchString,
        universityOrgId: getUniversityOrgIds()
      };
      _.assign(query, ctrl.option.searchDefaults);
      return commonCourseUnitService.searchWithParams(query).then(searchResult => searchResult.searchResults);
    };
    ctrl.getModules = searchString => {
      const query = {
        fullTextQuery: searchString,
        universityOrgId: getUniversityOrgIds(),
        documentState: ['ACTIVE', 'DRAFT']
      };
      _.assign(query, ctrl.option.searchDefaults);
      return commonModuleService.searchWithParams(query).then(searchResult => searchResult.searchResults);
    };
    ctrl.toggleModuleValue = (value, form, event) => {
      // This is done mainly because commonModuleService.searchWithParams returns results that have html tags in name
      commonModuleService.findById(value.id).then(module => ctrl.toggleValue(module, form, event));
    };
    function getUniversityOrgIds() {
      const universityId = _.get(ctrl.university, 'id');
      return universityId ? [universityId] : [];
    }
    ctrl.openEducationModal = () => {
      ctrl.popoverIsOpen = false;
      const selectedEducationIds = _.chain(_.get(ctrl.clonedParameterModel, 'value', [])).flatMap(education => {
        const idChildIdPairs = _.map(education.children, childValue => `${education.id}_${childValue.localId}`);
        return _.concat(education.id, idChildIdPairs);
      }).compact().value();
      const university = ctrl.organisations.find(org => org.id === ctrl.university.id);
      ctrl.getEducations().then(educations => {
        const inputs = {
          educations,
          university: university || ctrl.university,
          selectedIds: selectedEducationIds,
          hideLearningOpportunities: false
        };
        const options = {
          closeWithOutsideClick: true
        };
        const ref = modalService.open(SelectEducationComponent, inputs, options);
        return ref.result;
      }).then(educations => {
        ctrl.searchParams.educations.removeAllValues();
        _.forEach(educations, education => {
          ctrl.searchParams.educations.toggleValue(education);
        });
        $log.debug('Search component - searchFilter education dialog - executing search');
        ctrl.searchAgain();
      }).catch(() => {});
    };
    ctrl.getOrganisations = searchString => {
      const query = {
        name: searchString,
        universityOrgId: getUniversityOrgIds()
      };
      return commonOrganisationService.findAll(query, true).then(data => {
        const currentLang = localeService.getCurrentLocale();
        return _.sortBy(data, `name.${currentLang}`);
      });
    };
    ctrl.openOrganisationDialog = (organisationType = SearchFilterOrganisationType.DEFAULT) => {
      ctrl.popoverIsOpen = false;
      // LOL IDK why ctrl.university doesn't have a proper localized name rip.
      const university = ctrl.organisations.find(org => org.id === ctrl.university.id);
      const organisationsValue = organisationType === SearchFilterOrganisationType.DEFAULT ? ctrl.searchParams.organisations.value : ctrl.searchParams.studyRightOrganisations.value;
      const organisationRootsValue = organisationType === SearchFilterOrganisationType.DEFAULT ? ctrl.searchParams.organisationRoots.value : ctrl.searchParams.studyRightParentOrganisations.value;
      ctrl.getOrganisations().then(organisations => {
        const inputs = {
          organisations,
          university: university || ctrl.university,
          selectedIds: organisationsValue.map(org => org.id),
          selectedParentIds: organisationRootsValue.map(org => org.id)
        };
        const options = {
          closeWithOutsideClick: true
        };
        const ref = modalService.open(SelectOrganisationComponent, inputs, options);
        return ref.result;
      }).then(({
        selectedParents,
        selectedLeafs
      }) => {
        if (organisationType === SearchFilterOrganisationType.DEFAULT) {
          ctrl.searchParams.organisations.value = selectedLeafs;
          ctrl.searchParams.organisationRoots.value = selectedParents;
        } else {
          ctrl.searchParams.studyRightOrganisations.value = selectedLeafs;
          ctrl.searchParams.studyRightParentOrganisations.value = selectedParents;
        }
        ctrl.searchAgain();
      }).catch(() => {});
    };
    ctrl.getPrimaryFilterHeadings = () => _(ctrl.primaryFilters).map('headingObject').uniqBy('id').value();
    $scope.$watch('$ctrl.popoverIsOpen', newVal => {
      if (newVal) {
        // This is inside $timeout, so popover is rendered first and then ngRepeat is populated
        // So user gets to see 'Loading..' text
        // Only set the flag when fetching the filters for the first time, to avoid the text blinking quickly
        if (ctrl.searching === null) {
          ctrl.searching = true;
        }
        $timeout(() => {
          ctrl.getAllFilters().then(ctrl.sortFiltersIfTreeStructure).then(ctrl.showAllFiltersForUser).then(ctrl.populateSelectedValuesAndReturnFirst).then(ctrl.focusOnMostRelevantElement).catch(ctrl.inCaseOfError).finally(() => {
            ctrl.isInitialized = true;
          });
        });
      } else {
        ctrl.isInitialized = false;
      }
    });
  }
})();