import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { Literature, LiteratureName, LiteratureReference } from 'common-typescript/types';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-literature',
    template: `
      <ng-container *ngIf="isName(literature)">{{$any(literature).name}}</ng-container>
      <sis-external-link *ngIf="isReference(literature)" [href]="$any(literature).url">
        {{$any(literature).url}}
      </sis-external-link>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LiteratureComponent {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.literature.literature.downgraded',
        directiveName: 'sisLiterature',
    };

    @Input({ required: true }) literature: Literature;

    isName(literature: Literature): literature is LiteratureName {
        return literature?.type === 'LiteratureName';
    }

    isReference(literature: Literature): literature is LiteratureReference {
        return literature?.type === 'LiteratureReference';
    }
}
