import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { ValidatablePlan } from 'common-typescript/src/plan/validation/validatablePlan';
import { CourseUnitCountRule, EntityWithRule, Rule } from 'common-typescript/types';
import _ from 'lodash';
import {
    ComponentDowngradeMappings,
    DowngradedComponent,
    StaticMembers,
} from 'sis-common/types/angular-hybrid';

import { PlanData, PlanStateObject } from '../../../service/plan-state.service';
import { UiStateObject } from '../../plan-edit/plan-edit.component';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-course-unit-count-rule',
    templateUrl: './course-unit-count-rule.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class CourseUnitCountRuleComponent implements OnChanges {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.plan.rules.courseUnitCountRule',
        directiveName: 'sisCourseUnitCountRule',
    };

    courseUnitCountRule: CourseUnitCountRule;
    ruleValidationResult: any;

    @Input() parentModule: EntityWithRule;
    @Input() rule: Rule;
    @Input() validatablePlan: ValidatablePlan;
    @Input() planValidationResult: any;
    @Input() planStateObject: PlanStateObject;
    @Input() planData: PlanData;
    @Input() uiStateObject: UiStateObject;
    @Input() level: number;
    @Input() blockLevel: number;

    ngOnChanges() {
        this.courseUnitCountRule = this.rule as CourseUnitCountRule;
        this.ruleValidationResult = this.getRuleValidationResult(this.planValidationResult, this.parentModule);
    }

    getRuleValidationResult(planValidationResult: any, module: EntityWithRule): any {
        return _.get(planValidationResult.ruleValidationResults, [module.id, this.courseUnitCountRule.localId]);
    }

}
