import { Injectable } from '@angular/core';
import { NgbDate, NgbDateParserFormatter, NgbDatepicker, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DATE_INPUT_REGEX, MOMENT_DATE_DISPLAY_FORMAT } from 'common-typescript/constants';
import moment from 'moment';

/**
 * Our custom date parser and formatter for ng-bootstrap's {@link NgbDatepicker}.
 *
 * Converts {@link NgbDateStruct}s used by {@link NgbDatepicker} to strings shown in input fields, and vice versa.
 */
@Injectable()
export class DateParserFormatter extends NgbDateParserFormatter {

    format(date: NgbDateStruct): string {
        if (!date) {
            return '';
        }

        const momentDate = moment({ year: date.year, month: date.month - 1, day: date.day });
        if (!momentDate.isValid()) {
            return '';
        }

        return momentDate.format(MOMENT_DATE_DISPLAY_FORMAT);
    }

    parse(value: string): NgbDateStruct {
        if (!value || !DATE_INPUT_REGEX.test(value)) {
            return undefined;
        }

        const momentDate = moment(value, MOMENT_DATE_DISPLAY_FORMAT);
        if (!momentDate.isValid()) {
            return undefined;
        }

        return new NgbDate(momentDate.year(), momentDate.month() + 1, momentDate.date());
    }
}
