import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UrlService } from '@uirouter/core';

@Component({
    selector: 'app-suomi-fi-error',
    templateUrl: './suomi-fi-error.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuomiFiErrorComponent implements OnInit {

    titleKey: string;
    descriptionKey: string;

    constructor(
        private urlService: UrlService,
    ) {}

    ngOnInit(): void {
        // read query params from URL
        const queryParams = this.urlService.search();
        if (queryParams?.eventType === 'Login') {
            this.titleKey = 'SUOMI_FI.ERRORS.LOGIN_FAILED_TITLE';
            this.descriptionKey = 'SUOMI_FI.ERRORS.LOGIN_FAILED_DESCRIPTION';
        } else if (queryParams?.eventType === 'Logout') {
            this.titleKey = 'SUOMI_FI.ERRORS.EIDAS_LOGOUT_TITLE';
            this.descriptionKey = 'SUOMI_FI.ERRORS.EIDAS_LOGOUT_DESCRIPTION';
        } else {
            this.titleKey = 'SUOMI_FI.ERRORS.UNKNOWN_ERROR_TITLE';
            this.descriptionKey = 'SUOMI_FI.ERRORS.UNKNOWN_ERROR_DESCRIPTION';
        }
    }

}
