import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { PrivatePerson } from 'common-typescript/types';

@Component({
    selector: 'app-basic-info-personal-info',
    templateUrl: './basic-info-personal-info.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasicInfoPersonalInfoComponent {

    @Input() person: PrivatePerson;

    isPersonalIdentityCodeVisible = false;
}
