<app-course-unit-info-modal-substitution-correspondences [validatablePlan]="validatablePlan"
                                                         [plan]="plan"
                                                         [courseUnit]="courseUnit"
                                                         [versionChangeInProgress]="versionChangeInProgress"
                                                         (selectSubstitutions)="this.selectSubstitutions.emit($event)"
                                                         (removeSubstitutions)="this.removeSubstitutions.emit($event)"
                                                         (openCourseUnitModal)="this.openCourseUnitModal.emit($event)">
</app-course-unit-info-modal-substitution-correspondences>
<app-course-unit-info-modal-prior-learning *ngIf="courseUnit?.universityOrgIds.includes(currentUniversityOrgId)"
                                           [courseUnit]="courseUnit"
                                           [plan]="plan"
                                           [openSubstitutionForm]="openSubstitutionForm"
                                           [openApplication]="openApplication"
                                           [studyRight]="studyRight">
</app-course-unit-info-modal-prior-learning>
