import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ValidatablePlan } from 'common-typescript/src/plan/validation/validatablePlan';
import { CourseUnitResultItem, EntityWithRule, ModuleResultItem, OtmId, SearchResult } from 'common-typescript/types';
import _ from 'lodash';
import { from, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { singleConcurrentSearchWithThrottle } from 'sis-common/search/search-utils';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { silentErrorHandler } from '../../error-handler/silent-error-handler';
import { SearchResultsHelperService } from '../../service/search-results-helper.service';
import { UniversityService } from '../../service/university.service';
import { filterInput } from '../../typeahead/typeahead.component';

export enum InlineSearchTarget {
    COURSE_UNIT = 'CourseUnit',
    MODULE = 'Module',
}

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-inline-search',
    templateUrl: './inline-search.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class InlineSearchComponent implements OnInit, OnDestroy {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.plan.inlineSearch',
        directiveName: 'sisInlineSearch',
    };

    @Input() searchTarget: InlineSearchTarget;

    @Input() curriculumPeriodIds: OtmId[];

    @Input() codeUrns: string[] = [];

    @Input() validatablePlan: ValidatablePlan;

    @Input() parentModule: EntityWithRule;

    @Input() id: string;

    @Input() cooperationNetworkIds?: OtmId[];

    @Input() disablePlaceholder = false;

    @Output() selectResult = new EventEmitter<CourseUnitResultItem | ModuleResultItem>();

    placeholderText: string;
    currentUniversityOrgId: OtmId;

    SEARCH_TEXT_MIN_LENGTH = 3;

    // authenticated endpoints are to be used, when in previewMode

    COURSE_UNIT_SEARCH_ENDPOINT = '/kori/api/course-unit-search';
    COURSE_UNIT_SEARCH_ENDPOINT_AUTHENTICATED = '/kori/api/authenticated/course-unit-search';
    MODULE_SEARCH_ENDPOINT = '/kori/api/module-search';
    MODULE_SEARCH_ENDPOINT_AUTHENTICATED = '/kori/api/authenticated/module-search';

    destroyed$ = new Subject<void>();

    constructor(private httpClient: HttpClient,
                private universityService: UniversityService,
                private translateService: TranslateService,
                private searchResultsHelperService: SearchResultsHelperService,
                private localeService: LocaleService) { }

    ngOnInit() {
        if (this.disablePlaceholder) {
            this.placeholderText = '';
        } else if (this.searchTarget === InlineSearchTarget.MODULE) {
            this.placeholderText = this.translateService.instant('SIS_COMPONENTS.INLINE_SEARCH.MODULE_SEARCH_PLACEHOLDER');
        } else {
            this.placeholderText = this.translateService.instant('SIS_COMPONENTS.INLINE_SEARCH.COURSE_UNIT_SEARCH_PLACEHOLDER');
        }
        this.currentUniversityOrgId = this.universityService.getCurrentUniversityOrgId();
    }

    ngOnDestroy() {
        this.destroyed$.next();
    }

    onSelectResult(selectedResult: CourseUnitResultItem | ModuleResultItem): void {
        if (this.isSearchResultDisabled(selectedResult)) {
            return;
        }
        this.selectResult.emit(selectedResult);
    }

    search(textQuery$: Observable<string>): Observable<CourseUnitResultItem[] | ModuleResultItem[]> {
        return textQuery$.pipe(
            filterInput(this.SEARCH_TEXT_MIN_LENGTH),
            takeUntil(this.destroyed$),
            singleConcurrentSearchWithThrottle((params: any) => this.searchStudies(params)),
            map((result: any) => result.searchResults),
        );
    }

    searchStudies(textQuery: string): Observable<unknown> {
        const searchParameterObject = {
            curriculumPeriodId: this.curriculumPeriodIds,
            fullTextQuery: textQuery,
            limit: '100',
            orgRootId: this.currentUniversityOrgId,
            start: '0',
            uiLang: this.localeService.getCurrentLanguage(),
            validity: 'ONGOING_AND_FUTURE',
            codeUrn: this.codeUrns,
        };
        const cooperationNetworkParametersObject = this.cooperationNetworkIds ? {
            cooperationNetworkId: this.cooperationNetworkIds,
            cooperationNetworkSearchType: 'COURSE_UNITS_COMBINED_WITH_COOPERATION_NETWORKS',
            validityInCooperationNetwork: 'ONGOING',
        } : {};
        const combinedParametersObject = Object.assign(searchParameterObject, cooperationNetworkParametersObject);
        const params = new HttpParams({
            fromObject: combinedParametersObject,
        });
        const endpoint = this.searchTarget === InlineSearchTarget.MODULE ? this.MODULE_SEARCH_ENDPOINT : this.COURSE_UNIT_SEARCH_ENDPOINT;
        return from(this.getRequest(endpoint, params)).pipe(silentErrorHandler);
    }

    getRequest(endpoint: string, params: HttpParams): Observable<SearchResult<CourseUnitResultItem | ModuleResultItem>> {
        return this.httpClient.get<SearchResult<CourseUnitResultItem | ModuleResultItem>>(endpoint, { params });
    }

    matchInOtherLang(valueInUILang: string, matchedValue: string) {
        if (valueInUILang !== matchedValue && /<b>/.test(matchedValue)) {
            return matchedValue;
        }
        return undefined;
    }

    someOtherFieldThanNameInUILangMatched(resultItem: CourseUnitResultItem) {
        return this.matchInOtherLang(resultItem.name, resultItem.nameMatch) ||
            this.matchInOtherLang(resultItem.name, resultItem.searchTagsMatch);
    }

    isSearchResultDisabled(resultItem: CourseUnitResultItem | ModuleResultItem) {
        if (this.searchTarget === InlineSearchTarget.MODULE) {
            return this.searchResultsHelperService.isModuleSearchResultDisabled(resultItem as ModuleResultItem, this.parentModule, this.validatablePlan);
        }
        return this.searchResultsHelperService.isCourseUnitSearchResultDisabled(resultItem as CourseUnitResultItem, this.parentModule, this.validatablePlan);
    }

    isHomeUniversityResult(result: CourseUnitResultItem | ModuleResultItem) {
        if (this.searchTarget === 'CourseUnit') {
            const courseUnitResult = result as CourseUnitResultItem;
            return _.includes(courseUnitResult.universityOrgIds, this.currentUniversityOrgId);
        }
        return true;
    }
}
