import { IconComponent } from '../icon/icon.component.ts';
import planSelectorTpl from './planSelector.tpl.html';
import { PlanSelectorConfig } from './planSelectorConfig.ts';
'use strict';
(function () {
  EducationSelectionCtrl.$inject = ["commonEducationService", "SELECTION_TYPES"];
  angular.module('sis-components.plan.planSelector', ['sis-components.plan.moduleSelection', 'sis-components.plan.elementSelect', 'sis-components.service.educationService', 'sis-components.service.planService', 'sis-common.l10n.localizedStringFilter', 'sis-components.plan.planSelectorService', IconComponent.downgrade.moduleName]).component('planSelector', {
    template: planSelectorTpl,
    controller: EducationSelectionCtrl,
    bindings: {
      plan: '<',
      validatablePlan: '<',
      onSelect: '&',
      config: '<' // PlanSelectorConfig
    }
  });

  /**
   * @ngInject
   */
  function EducationSelectionCtrl(commonEducationService, SELECTION_TYPES) {
    const $ctrl = this;
    $ctrl.SELECTION_TYPES = SELECTION_TYPES;
    $ctrl.$onInit = function () {
      $ctrl.isOpen = true;
      if (!$ctrl.config) {
        $ctrl.config = new PlanSelectorConfig();
      }
    };
    $ctrl.$onChanges = function (changesObject) {
      if (changesObject.plan && changesObject.plan.currentValue) {
        const newPlan = changesObject.plan.currentValue;
        if (newPlan) {
          commonEducationService.findById(newPlan.rootId).then(education => {
            $ctrl.education = education;
          });
          $ctrl.childModules = newPlan.findChildModuleSelections ? newPlan.findChildModuleSelections(newPlan.rootId) : [];
        } else {
          $ctrl.education = undefined;
          $ctrl.childModules = [];
        }
      }
    };
    $ctrl.openAccordion = function ($event) {
      $ctrl.isOpen = !$ctrl.isOpen;
      $event.preventDefault();
    };
    $ctrl.onElementSelect = function (selectionObject) {
      $ctrl.onSelect({
        selectedObject: selectionObject
      });
    };
  }
})();