'use strict';

(function () {
  attainmentContextBuilder.$inject = ["$log", "$q", "attainmentModel", "attainmentService", "commonGradeScaleService", "previewMode"];
  angular.module('student.common.context.attainmentContextBuilder', ['sis-components.service.gradeScale', 'student.common.model.attainment', 'student.common.service.attainmentService', 'student.common.utils.previewMode']).factory('attainmentContextBuilder', attainmentContextBuilder);

  /**
   * @ngInject
   */
  function attainmentContextBuilder($log, $q, attainmentModel, attainmentService, commonGradeScaleService, previewMode) {
    var attainmentsById = {},
      moduleAttainmentsByGroupId = {},
      courseUnitAttainmentsByGroupId = {},
      assessmentItemAttainmentsById = {},
      customModuleAttainments = {},
      customCourseUnitAttainments = {},
      attainmentContext;
    return {
      buildContext: buildContext,
      resetContext: resetContext
    };
    function resetContext() {
      attainmentContext = null;
    }
    function buildContext() {
      if (previewMode.isPreviewMode()) {
        attainmentContext = $q.when(buildAttainments({}));
      } else if (!attainmentContext) {
        attainmentContext = loadAttainments();
      }
      return attainmentContext;
    }
    function loadAttainments() {
      return attainmentService.getMyValidModelAttainments().then(buildAttainments);
    }
    function buildAttainments(attainments) {
      var attainmentContext = {
        getAttainments: getAttainments,
        getModuleAttainmentByGroupId: getModuleAttainmentByGroupId,
        getModuleAttainments: getModuleAttainments,
        getCourseUnitAttainments: getCourseUnitAttainments,
        getCourseUnitAttainmentByGroupId: getCourseUnitAttainmentByGroupId,
        getAssessmentItemAttainments: getAssessmentItemAttainments,
        getAssessmentItemAttainmentById: getAssessmentItemAttainmentById,
        getCustomModuleAttainments: getCustomModuleAttainments,
        getCustomCourseUnitAttainments: getCustomCourseUnitAttainments
      };

      // Register attainments
      var promises = _.map(attainments, registerAttainment);

      // Connect attainments to their children and load referenced data
      _.forEach(courseUnitAttainmentsByGroupId, buildCourseUnitAttainment);
      _.forEach(moduleAttainmentsByGroupId, buildModuleAttainment);
      _.forEach(customCourseUnitAttainments, buildCourseUnitAttainment);
      _.forEach(customModuleAttainments, buildModuleAttainment);
      return $q.all(promises).then(function () {
        return attainmentContext;
      }, function (error) {
        error.titleKey = 'ERROR.GRADE_SCALE_NOT_FOUND';
        throw error;
      });
    }
    function getAttainments() {
      return _.union(_.values(moduleAttainmentsByGroupId), _.values(courseUnitAttainmentsByGroupId), _.values(assessmentItemAttainmentsById), _.values(customModuleAttainments), _.values(customCourseUnitAttainments));
    }
    function getModuleAttainments() {
      return _.values(moduleAttainmentsByGroupId);
    }
    function getModuleAttainmentByGroupId(id) {
      return moduleAttainmentsByGroupId[id];
    }
    function getCourseUnitAttainments() {
      return _.values(courseUnitAttainmentsByGroupId);
    }
    function getCourseUnitAttainmentByGroupId(id) {
      return courseUnitAttainmentsByGroupId[id];
    }
    function getAssessmentItemAttainments() {
      return _.values(assessmentItemAttainmentsById);
    }
    function getAssessmentItemAttainmentById(id) {
      return assessmentItemAttainmentsById[id];
    }
    function getCustomModuleAttainments() {
      return _.values(customModuleAttainments);
    }
    function getCustomCourseUnitAttainments() {
      return _.values(customCourseUnitAttainments);
    }
    function buildCourseUnitAttainment(courseUnitAttainment) {
      courseUnitAttainment.assessmentItemAttainments = _.map(courseUnitAttainment.assessmentItemAttainmentIds, function (attainmentId) {
        var attainment = attainmentsById[attainmentId];
        // Override possible primary attainment with this bound attainment
        assessmentItemAttainmentsById[attainment.assessmentItemId] = attainment;
        return attainment;
      });
    }
    function buildModuleAttainment(moduleAttainment) {
      _.forEach(moduleAttainment.nodes, function (node) {
        buildNode(moduleAttainment, node);
      });
    }
    function buildNode(moduleAttainment, node) {
      if (node.type === 'AttainmentGroupNode') {
        node.group = true;
        _.forEach(node.nodes, function (node) {
          buildNode(moduleAttainment, node);
        });
      } else if (node.type === 'AttainmentReferenceNode') {
        node.group = false;
        var attainment = attainmentsById[node.attainmentId];
        if (attainment) {
          node.attainment = attainment;
          if (attainment.isModuleAttainment() || attainment.isDegreeProgrammeAttainment()) {
            // Override possible primary attainment with this bound attainment
            moduleAttainmentsByGroupId[attainment.moduleGroupId] = attainment;
            moduleAttainment.moduleAttainments.push(attainment);
          } else if (attainment.isCourseUnitAttainment()) {
            // Override possible primary attainment with this bound attainment
            courseUnitAttainmentsByGroupId[attainment.courseUnitId] = attainment;
            moduleAttainment.courseUnitAttainments.push(attainment);
          } else if (attainment.isCustomModuleAttainment()) {
            customModuleAttainments[attainment.id] = attainment;
            moduleAttainment.moduleAttainments.push(attainment);
          } else if (attainment.isCustomCourseUnitAttainment()) {
            customCourseUnitAttainments[attainment.id] = attainment;
            moduleAttainment.courseUnitAttainments.push(attainment);
          } else {
            $log.warn('Unhandled attainment type in buildNode ', attainment);
          }
        } else {
          $log.warn('We have a module attainment referring to a missing attainment: ', node);
        }
      } else {
        $log.warn('Unsupported AttainmentNode type ', node);
      }
    }
    function registerAttainment(attainment) {
      attainment = attainmentModel.newAttainment(attainment);
      attainmentsById[attainment.id] = attainment;
      if (attainment.state !== 'FAILED' && !attainment.misregistration && attainment.primary) {
        if (attainment.isModuleAttainment() || attainment.isDegreeProgrammeAttainment()) {
          moduleAttainmentsByGroupId[attainment.moduleGroupId] = attainment;
        } else if (attainment.isCourseUnitAttainment()) {
          courseUnitAttainmentsByGroupId[attainment.courseUnitGroupId] = attainment;
        } else if (attainment.isAssessmentItemAttainment()) {
          assessmentItemAttainmentsById[attainment.assessmentItemId] = attainment;
        } else if (attainment.isCustomModuleAttainment()) {
          customModuleAttainments[attainment.id] = attainment;
        } else if (attainment.isCustomCourseUnitAttainment()) {
          customCourseUnitAttainments[attainment.id] = attainment;
        } else {
          $log.warn('failed to register attainment.', attainment);
        }
      }
      return commonGradeScaleService.getGradeScale(attainment.gradeScaleId).then(function (gradeScale) {
        attainment.grade = gradeScale.grades[attainment.gradeId];
      });
    }
  }
})();