'use strict';

(function () {
  multiInputLabelErrorCheck.$inject = ["$log", "$timeout"];
  angular.module('sis-common.form').directive('multiInputLabelErrorCheck', multiInputLabelErrorCheck);
  function multiInputLabelErrorCheck($log, $timeout) {
    return {
      restrict: 'EA',
      require: '^^form',
      scope: false,
      link: function (scope, elem, attr, form) {
        if (!attr.for) {
          $log.error('"for" attribute mandatory when using label-error-check');
        }
        var propertyName = attr.multiInputLabelErrorCheck;
        var labelTargetId = attr.for;
        var labelTargetElement = elem.parent().find('#' + labelTargetId);
        var targetElements;
        $timeout(function () {
          targetElements = elem.parent().find('input');
        });
        scope.$watch(function () {
          targetElements = elem.parent().find('input');
          var value = '';
          _.forEach(targetElements, function (element) {
            value += element.value;
            value += _.get(_.get(_.get(form, propertyName), element.name), '$valid', '');
          });
          value += form.$error.length;
          return value;
        }, updateValidityIndicators);
        function updateValidityIndicators() {
          if (isRequired(labelTargetElement)) {
            elem.addClass('required');
          }
          var pristine = true;
          var valid = true;
          _.forEach(targetElements, function (element) {
            var formElement = _.get(_.get(form, propertyName), element.name);
            if (_.get(formElement, '$pristine') === false) {
              pristine = false;
            }
            if (_.get(formElement, '$valid') === false) {
              valid = false;
            }
          });
          if (_.get(_.get(form, propertyName), '$valid') === false) {
            valid = false;
          }
          var formErrors = _.flatMap(_.values(_.get(form, '$error')));
          _.forEach(formErrors, function (formError) {
            if (formError.$name === propertyName) {
              valid = false;
            }
          });
          if (!pristine && valid) {
            elem.removeClass('invalid');
            elem.addClass('valid');
          }
          if (!pristine && !valid) {
            elem.removeClass('valid');
            elem.addClass('invalid');
          }
        }
        function isRequired(element) {
          var classesSignifyingRequired = ['ng-invalid-valdr-required', 'ng-valid-valdr-required', 'ng-invalid-valdr-not-empty', 'ng-valid-valdr-not-empty'];
          return element.attr('ng-required') || _.some(classesSignifyingRequired, function (cls) {
            return element.hasClass(cls);
          });
        }
      }
    };
  }
})();