import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-markup-string',
    templateUrl: './markup-string.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class MarkupStringComponent {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.markup-string.downgraded',
        directiveName: 'sisMarkupString',
    };

    @Input() label?: string;
    @Input() value: string;
}
