import angular from 'angular';
export const privatePersonModule = 'sis-components.model.privatePerson';
(function () {
  privatePersonNameJSDataModel.$inject = ["DS", "personNameService"];
  privatePersonJSDataModel.$inject = ["DS", "municipalityJSDataModel", "countryJSDataModel", "languageJSDataModel", "personNameService"];
  angular.module(privatePersonModule, ['js-data', 'sis-components.model.gender', 'sis-components.model.country', 'sis-components.model.language', 'sis-components.model.municipality', 'sis-components.model.preferredLanguage', 'sis-components.model.schoolEducationLanguage', 'sis-components.model.language', 'sis-components.service.personNameService', 'sis-components.model.privatePersonInfo']).factory('privatePersonJSDataModel', privatePersonJSDataModel).factory('privatePersonNameJSDataModel', privatePersonNameJSDataModel).run(["privatePersonJSDataModel", "privatePersonNameJSDataModel", function (privatePersonJSDataModel, privatePersonNameJSDataModel) {}]); //NOSONAR

  /**
   * @ngInject
   */
  function privatePersonJSDataModel(DS, municipalityJSDataModel, countryJSDataModel, languageJSDataModel, personNameService) {
    return DS.defineResource({
      idAttribute: 'id',
      name: 'privatePerson',
      endpoint: '/ori/api/persons',
      relations: {
        hasOne: {
          gender: {
            localField: 'gender',
            localKey: 'genderUrn'
          },
          language: {
            localField: 'motherTongue',
            localKey: 'motherTongueUrn',
            get: languageJSDataModel.lazyGet
          },
          preferredLanguage: {
            localField: 'preferredLanguage',
            localKey: 'preferredLanguageUrn'
          },
          schoolEducationLanguage: {
            localField: 'schoolEducationLanguage',
            localKey: 'schoolEducationLanguageUrn'
          },
          municipality: {
            localField: 'municipality',
            localKey: 'municipalityUrn',
            get: municipalityJSDataModel.lazyGet
          }
        },
        hasMany: {
          country: {
            localField: 'citizenships',
            localKeys: 'citizenshipUrns',
            get: countryJSDataModel.lazyGet
          }
        }
      },
      computed: {
        fullName: ['firstName', 'firstNames', 'lastName', function (firstName, firstNames, lastName) {
          const personObject = {
            firstName: firstName,
            firstNames: firstNames,
            lastName: lastName
          };
          return personNameService.getFullName(personObject);
        }],
        fullNameReversed: ['firstName', 'firstNames', 'lastName', function (firstName, firstNames, lastName) {
          const personObject = {
            firstName: firstName,
            firstNames: firstNames,
            lastName: lastName
          };
          return personNameService.getFullNameReversed(personObject);
        }]
      }
    });
  }

  /**
   * @ngInject
   */
  function privatePersonNameJSDataModel(DS, personNameService) {
    return DS.defineResource({
      idAttribute: 'id',
      name: 'privatePersonName',
      endpoint: '/ori/api/persons',
      computed: {
        fullName: ['firstName', 'firstNames', 'lastName', function (firstName, firstNames, lastName) {
          const personObject = {
            firstName: firstName,
            firstNames: firstNames,
            lastName: lastName
          };
          return personNameService.getFullName(personObject);
        }],
        fullNameReversed: ['firstName', 'firstNames', 'lastName', function (firstName, firstNames, lastName) {
          const personObject = {
            firstName: firstName,
            firstNames: firstNames,
            lastName: lastName
          };
          return personNameService.getFullNameReversed(personObject);
        }]
      }
    });
  }
})();