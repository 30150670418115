import { ChangeDetectionStrategy, Component, inject, Input, ViewEncapsulation } from '@angular/core';
import { CourseUnitRealisation, LocalDateTimeString } from 'common-typescript/types';
import moment from 'moment';
import { catchError, map, Observable, of } from 'rxjs';
import { LocalDateFormatPipe } from 'sis-components/date/pipes/local-date-format/local-date-format.pipe';
import { LocalTimeFormatPipe } from 'sis-components/date/pipes/local-time-format/local-time-format.pipe';
import { CourseUnitRealisationEntityService, EnrolmentPeriodValidity } from 'sis-components/service/course-unit-realisation-entity.service';
import { UniversityService } from 'sis-components/service/university.service';

import { EnrolmentStudentService } from '../../../service/enrolment-student.service';

@Component({
    selector: 'app-enrolment-period-info',
    templateUrl: './enrolment-period-info.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnrolmentPeriodInfoComponent {

    @Input({ required: true }) set courseUnitRealisation(courseUnitRealisation: CourseUnitRealisation) {
        this._courseUnitRealisation = courseUnitRealisation;
        const isCrossStudyEnrolment = !this.courseUnitRealisation?.universityOrgIds?.includes(this.universityService.getCurrentUniversityOrgId());
        this.isLateEnrolment = !isCrossStudyEnrolment && !!courseUnitRealisation?.enrolmentPeriod?.endDateTime &&
            !!courseUnitRealisation?.lateEnrolmentEnd && moment().isAfter(courseUnitRealisation.enrolmentPeriod.endDateTime);

        if (courseUnitRealisation) {
            const { id } = courseUnitRealisation;
            this.enrolmentPeriodValidity$ = (id ? this.enrolmentStudentService.getCalculationConfig(id) : of(null))
                .pipe(
                    catchError(() => of(null)),
                    map(config => this.courseUnitRealisationService
                        .getEnrolmentPeriodValidity(courseUnitRealisation, config?.enrolmentCalculationState)),
                );
        } else {
            this.enrolmentPeriodValidity$ = of(null);
        }
    }

    get courseUnitRealisation(): CourseUnitRealisation {
        return this._courseUnitRealisation;
    }

    private readonly courseUnitRealisationService = inject(CourseUnitRealisationEntityService);
    private readonly enrolmentStudentService = inject(EnrolmentStudentService);
    private readonly universityService = inject(UniversityService);

    private readonly localDateFormatPipe = inject(LocalDateFormatPipe);
    private readonly localTimeFormatPipe = inject(LocalTimeFormatPipe);

    enrolmentPeriodValidity$: Observable<EnrolmentPeriodValidity>;
    isLateEnrolment = false;

    private _courseUnitRealisation: CourseUnitRealisation;

    getDateTimeParams(dateTime: LocalDateTimeString): { date: string, time: string } {
        return {
            date: this.localDateFormatPipe.transform(dateTime),
            time: this.localTimeFormatPipe.transform(dateTime),
        };
    }
}
