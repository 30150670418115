import { Pipe, PipeTransform } from '@angular/core';
import { AttainmentState } from 'common-typescript/types';

@Pipe({
    name: 'attainmentStatusTranslation',
})
export class AttainmentStatusTranslationPipe implements PipeTransform {

    transform(attainmentState: AttainmentState): string {
        const stateTranslations = {
            ATTAINED: 'PROFILE.ATTAINMENT.STATE.ATTAINED',
            INCLUDED: 'PROFILE.ATTAINMENT.STATE.INCLUDED',
            SUBSTITUTED: 'PROFILE.ATTAINMENT.STATE.SUBSTITUTED',
            FAILED: 'PROFILE.ATTAINMENT.STATE.FAILED',
        };
        return stateTranslations[attainmentState];
    }

}
