import { Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { CustomAttainmentApplication } from 'common-typescript/types';

/**
 * @deprecated
 */
// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'custom-attainment-application-summary' })
export class CustomAttainmentApplicationSummaryDirective extends UpgradeComponent {

    @Input() application: Partial<CustomAttainmentApplication>;
    @Input() shortVersion: boolean;

    constructor(elementRef: ElementRef, injector: Injector) {
        super('customAttainmentApplicationSummary', elementRef, injector);
    }
}
