import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

import {
    CustomModuleAttainmentStateObject,
    EducationStateObject,
    ModuleStateObject,
} from '../../service/plan-state.service';

@Component({
    selector: 'sis-plan-structure-module-selection-state',
    templateUrl: './plan-structure-module-selection-state.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanStructureModuleSelectionStateComponent {
    @Input({ required: true }) stateObject: ModuleStateObject | EducationStateObject | CustomModuleAttainmentStateObject;
}
