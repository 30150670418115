import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import {
    Attainment,
    CourseUnit,
    CourseUnitAttainment,
    CustomCourseUnitAttainment,
    CustomModuleAttainment,
    CustomStudyDraft,
    DegreeProgrammeAttainment,
    Module,
    ModuleAttainment,
} from 'common-typescript/types';
import _ from 'lodash';

@Component({
    selector: 'sis-module-content-workflow-structure',
    templateUrl: './module-content-workflow-structure.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class ModuleContentWorkflowStructureComponent implements OnChanges {

    modules: Module[];
    groupingModules: Module[];
    customStudyDraftsById: { [id: string]: CustomStudyDraft };
    effectiveCustomStudyDrafts: CustomStudyDraft[];
    selectedCourseUnitAttainmentsByGroupId: { [id: string]: CourseUnitAttainment };
    selectedModuleAttainmentsByGroupId: { [id: string]: ModuleAttainment | DegreeProgrammeAttainment };

    @Input() parentModule: Module;
    @Input() selectedCourseUnits: CourseUnit[];
    @Input() selectedModules: Module[];
    @Input() selectedCustomCourseUnitAttainments: CustomCourseUnitAttainment[];
    @Input() selectedCustomModuleAttainments: CustomModuleAttainment[];
    @Input() customStudyDrafts: CustomStudyDraft[];
    @Input() attainments: Attainment[];

    ngOnChanges() {
        this.modules = _.filter(this.selectedModules, selectedModule => selectedModule.type !== 'GroupingModule');
        this.groupingModules = _.difference(this.selectedModules, this.modules);
        const courseUnitAttainments = _.filter(this.attainments, attainment => attainment.type === 'CourseUnitAttainment') as CourseUnitAttainment[];
        const moduleAttainments = _.filter(this.attainments, attainment =>
            _.includes(['ModuleAttainment', 'DegreeProgrammeAttainment'], attainment.type)) as (ModuleAttainment | DegreeProgrammeAttainment)[];
        this.selectedCourseUnitAttainmentsByGroupId = _.chain(this.selectedCourseUnits)
            .map(cu => _.find(courseUnitAttainments, cua => cua.courseUnitId === cu.id))
            .compact()
            .keyBy('courseUnitGroupId')
            .value();

        this.selectedModuleAttainmentsByGroupId = _.chain(this.selectedModules)
            .map(mod => _.find(moduleAttainments, ma => ma.moduleId === mod.id))
            .compact()
            .keyBy('moduleGroupId')
            .value();

        this.customStudyDraftsById = _.keyBy(this.customStudyDrafts, 'id');
        const customCourseUnitAttainmentsByCustomStudyDraftId = _.chain(this.selectedCustomCourseUnitAttainments)
            .filter(ccua => !!ccua.customStudyDraftId)
            .keyBy('customStudyDraftId')
            .value();

        this.effectiveCustomStudyDrafts = _.filter(this.customStudyDrafts, csd =>
            !_.get(customCourseUnitAttainmentsByCustomStudyDraftId, csd.id));
    }

}
