import gradeScaleSelectEditorTpl from './gradeScaleSelectEditor.tpl.html';
(function () {
  angular.module("sis-components.gradeScale.gradeScaleSelectEditor", ['pascalprecht.translate', 'js-data', 'sis-common.l10n.localizedStringFilter']).directive('gradeScaleSelectEditor', gradeScaleSelectEditorDirective).controller('gradeScaleSelectEditorController', gradeScaleSelectEditorController);

  /**
   * @ngInject
   */
  function gradeScaleSelectEditorDirective() {
    return {
      restrict: 'E',
      scope: {
        'name': '@',
        'required': '@',
        'gradeScaleModel': '=',
        'disable': '<'
      },
      require: ['^^form', 'ngModel'],
      template: gradeScaleSelectEditorTpl,
      bindToController: true,
      controller: 'gradeScaleSelectEditorController as ctrl',
      link: function (scope, elem, attr, controllers) {
        var ctrl = scope.ctrl;
        ctrl.form = controllers[0];
        ctrl.ngModel = controllers[1];

        // remove empties from model values. if no values, then the model is null.
        ctrl.ngModel.$parsers.push(function (value) {
          return value.id;
        });

        // if model value is null, fill view model with empty object
        ctrl.ngModel.$formatters.push(function (value) {
          if (value) {
            return ctrl.gradeScaleModel.get(value);
          } else {
            return null;
          }
        });
      }
    };
  }

  /**
   * @ngInject
   */
  function gradeScaleSelectEditorController() {
    var ctrl = this;
    ctrl.$onInit = function () {
      if (_.isEmpty(ctrl.gradeScaleModel)) {
        throw 'gradeScaleSelectEditor directive: gradeScaleModel attribute is required';
      }
      ctrl.possibleGradeScales = ctrl.gradeScaleModel.getAll();
      ctrl.value = ctrl.possibleGradeScales[0];
    };

    // $setViewValue is needed because angular ngModel does not do deep watch on the object, and will not notice
    // changes in the underlying objects.
    //
    // in certain scenarios ctrl.ngModel may not be initialized
    // when update is called the first time, because controller is created before ngModel is set in the
    // link function
    ctrl.onChange = function () {
      if (_.isFunction(_.get(ctrl.ngModel, "$setViewValue"))) {
        ctrl.ngModel.$setViewValue(ctrl.ngModel.$viewValue);
      }
    };
  }
})();