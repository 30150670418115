import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { MoneyString } from 'common-typescript/types';

/**
 * Converts a `MoneyString` into a localized format to be displayed in the UI. This pipe simply parses the `MoneyString` into
 * its parts, and passes them on to the Angular `CurrencyPipe`, which will do the actual localized formatting.
 */
@Pipe({ name: 'money' })
export class MoneyPipe implements PipeTransform {

    constructor(private currencyPipe: CurrencyPipe) {}

    transform(value: MoneyString): string {
        if (!value?.trim()) {
            return null;
        }

        const matches = /^([A-Z]{3})\s(-?\d+(?:\.\d+)?$)/.exec(value);
        if (matches) {
            const currency = matches[1];
            const amount = Number.parseFloat(matches[2]);
            if (Number.isNaN(amount)) {
                return null;
            }

            return this.currencyPipe.transform(amount, currency, 'symbol');
        }

        return null;
    }
}
