import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, inject, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from 'sis-common/modal/modal.service';

export interface SystemErrorModalValues {
    title: string;
    message?: string;
    errorCode?: number;
    errorDetails?: string;
    stacktrace?: string;
    userAgent?: string;
}

export function systemErrorModalOpener(): (values: SystemErrorModalValues) => NgbModalRef {
    const modalService = inject(ModalService);
    return values => modalService.open(SystemErrorModalComponent, values, { size: 'sm' });
}

@Component({
    selector: 'sis-system-error-modal',
    templateUrl: './system-error-modal.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SystemErrorModalComponent {

    readonly errorInfo: string;

    constructor(
        protected modal: NgbActiveModal,
        @Inject(ModalService.injectionToken) protected values: SystemErrorModalValues,
        @Inject(DOCUMENT) private document: Document,
    ) {
        this.errorInfo = `\
DETAILS:
${values.errorDetails ?? '-'}

ERROR CODE:
${values.errorCode ?? '-'}

STRING:
${values.userAgent ?? '-'}

STACKTRACE:
${values.stacktrace ?? '-'}`;
    }

    copyToClipboard(): void {
        this.document.defaultView.navigator.clipboard.writeText(this.errorInfo);
    }

    refreshPage(): void {
        this.document.location.reload();
    }
}
