import { InMemoryCache } from '@apollo/client/core';
import { ApolloLink } from '@apollo/client/link/core';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { QueryRef, WatchQueryOptions } from 'apollo-angular';
import { HttpBatchLink } from 'apollo-angular/http';
import { GraphQLError } from 'graphql';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';

export const apolloFactory = (httpBatchLink: HttpBatchLink) => {

    const errorHandler = onError((errorResponse: ErrorResponse) => {
        if (errorResponse.graphQLErrors) {
            // eslint-disable-next-line array-callback-return
            errorResponse.graphQLErrors.map(({ message, locations, path }) => {
                console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
            });
        }

        if (errorResponse.networkError) {
            console.error(`[Network error]: ${errorResponse.networkError}`);
        }
    });

    const retryLink = new RetryLink({
        delay: {
            initial: 500,
            max: Infinity,
            jitter: true,
        },
        attempts: {
            max: 5,
            retryIf: (error, _operation) => !!error,
        },
    });

    const batchLink = httpBatchLink.create({
        uri: '/api',
        batchInterval: 50,
        batchMax: 20,
    });

    const link = ApolloLink.from([
        retryLink,
        errorHandler,
        batchLink,
    ]);

    return {
        link,
        cache: new InMemoryCache({
            typePolicies: {
                OpenUniversityProduct: {
                    keyFields: ['id'],
                },
                CourseUnitRealisation: {
                    keyFields: ['id'],
                },
                CourseUnit: {
                    keyFields: ['id'],
                },
                Query: {
                    fields: {
                        search_course_unit_realisations_for_teacher: {
                            keyArgs: [
                                'activityStatus',
                                'flowStates',
                                'enrolmentOngoing',
                                'evaluationPeriod',
                                'pastArchivedAndCancelled',
                                'fullTextQuery',
                                'activityEnd',
                                'uiLang',
                                'continuousEnrolment',
                                'codeUrns',
                                'fetchOnlyHiddenCurs',
                            ],
                            merge(existing, incoming, { args: { start = 0 } }) {
                                if (!incoming) { return existing; }
                                if (!existing) { return incoming; }
                                const merged = existing.searchResults ? existing.searchResults.slice(0) : [];
                                for (let i = 0; i < incoming.searchResults.length; i += 1) {
                                    merged[start + i] = incoming.searchResults[i];
                                }
                                return { ...incoming, searchResults: merged.slice(0, start + incoming.searchResults.length) };
                            },
                        },
                    },
                },
            },
        }),
        defaultOptions: {
            watchQuery: {
                errorPolicy: 'all',
            },
        },
    };
};

export const queryOptions: Omit<WatchQueryOptions<any>, 'query' | 'variables'> = {
    nextFetchPolicy: 'cache-first',
    fetchPolicy: 'cache-first',
    returnPartialData: false,
};

export interface LoadingAndError {
    loading: Observable<boolean>;
    errors: Observable<GraphQLError[]>; // Removed readonly from here because for some reason it caused errors with downgraded components
}

export const initLoadingAndError = (observable: QueryRef<any>): LoadingAndError =>
    ({
        loading: observable.valueChanges.pipe(startWith({ loading: true }), map(({ loading }) => loading), catchError(_ => of(false))),
        errors: observable.valueChanges.pipe(startWith({ errors: undefined }), map(({ errors }) => errors), catchError(error => of(error))),
    });

export const catchErrorHandler = <T>() => catchError<T, Observable<T>>((error) => {
    console.error(error);
    return EMPTY;
});
