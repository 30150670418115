import { ChangeDetectionStrategy, Component, inject, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
    AssessmentItem,
    AssessmentItemAttainment,
    AttainmentType,
    CourseUnit,
    CourseUnitRealisation,
    Enrolment,
    EnrolmentRight,
    OtmId,
    UsedEnrolments,
} from 'common-typescript/types';
import { cloneDeep } from 'lodash';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { AuthService } from 'sis-common/auth/auth-service';
import { ModalService } from 'sis-common/modal/modal.service';
import { UuidService } from 'sis-common/uuid/uuid.service';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { AssessmentItemEntityService } from 'sis-components/service/assessment-item-entity.service';
import { AttainmentEntityService } from 'sis-components/service/attainment-entity.service';
import { CourseUnitRealisationEntityService } from 'sis-components/service/course-unit-realisation-entity.service';
import { EnrolmentRightEntityService } from 'sis-components/service/enrolment-right-entity.service';

import { EnrolmentStudentService } from '../../../common/service/enrolment-student.service';
import { enrolmentWizardModalOpener } from '../../enrolment-wizard/enrolment-wizard.component';

export interface OpenUniversityEnrolmentModalValues {
    courseUnit: CourseUnit;
    enrolmentRight: EnrolmentRight;
}

export function openUniversityEnrolmentModalOpener(): (values: OpenUniversityEnrolmentModalValues) => NgbModalRef {
    const modalService = inject(ModalService);
    return values => modalService.open(OpenUniversityEnrolmentModalComponent, values, { size: 'lg' });
}

interface AggregateStudyInfo {
    assessmentItem: AssessmentItem;
    aiAttainment?: AssessmentItemAttainment;
    courseUnitRealisations: CourseUnitRealisation[];
    /** The maximum amount of times the student is allowed to enrol. */
    enrolmentLimit: number;
    /** The amount of times the student is still allowed to enrol. */
    enrolmentsRemaining: number;
}

@Component({
    selector: 'app-open-university-enrolment-modal',
    templateUrl: './open-university-enrolment-modal.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpenUniversityEnrolmentModalComponent implements OnInit {

    studies$: Observable<AggregateStudyInfo[]>;
    openEnrolmentWizardModal = enrolmentWizardModalOpener();

    constructor(
        private appErrorHandler: AppErrorHandler,
        private assessmentItemService: AssessmentItemEntityService,
        private attainmentService: AttainmentEntityService,
        private authService: AuthService,
        private courseUnitRealisationService: CourseUnitRealisationEntityService,
        private modal: NgbActiveModal,
        private enrolmentRightService: EnrolmentRightEntityService,
        private enrolmentStudentService: EnrolmentStudentService,
        private uuidService: UuidService,
        @Inject(ModalService.injectionToken) private values: OpenUniversityEnrolmentModalValues,
    ) {}

    ngOnInit(): void {
        const assessmentItemIds = this.enrolmentRight.enrolmentConstraints.map(constraint => constraint.assessmentItemId);
        this.studies$ = combineLatest([
            this.assessmentItemService.getByIdsSorted(assessmentItemIds, this.courseUnit.id),
            this.findAssessmentItemAttainments(assessmentItemIds),
            this.courseUnitRealisationService.findForEnrolmentRight(this.enrolmentRight, ['ACTIVE'], ['PUBLISHED']),
            this.enrolmentRightService.countUsedEnrolments(this.enrolmentRight.id),
        ])
            .pipe(
                take(1),
                map(([assessmentItems, aiAttainments, cursByAiId, usedEnrolments]) => this.mergeStudyData(assessmentItems, aiAttainments, cursByAiId, usedEnrolments)),
                this.appErrorHandler.defaultErrorHandler(),
            );
    }

    close(): void {
        this.modal.close();
    }

    onEnrolClick(courseUnitRealisationId: OtmId, assessmentItemId: OtmId): void {
        this.enrolmentStudentService.findEnrolment(courseUnitRealisationId)
            .pipe(
                take(1),
                switchMap(enrolment => !!enrolment ? of({ ...enrolment, enrolmentRightId: this.enrolmentRight.id, studyRightId: this.enrolmentRight.studyRightId }) : this.createEnrolment(courseUnitRealisationId, assessmentItemId)),
                take(1),
                this.appErrorHandler.defaultErrorHandler(),
            )
            .subscribe(enrolment => {
                this.close();
                this.openEnrolmentWizardModal({ enrolment, isUpdate: false, isConfirmedSsgEdit: false, isEnrolmentFromOpenUniversityModal: true });
            });
    }

    get courseUnit(): CourseUnit {
        return this.values?.courseUnit;
    }

    get enrolmentRight(): EnrolmentRight {
        return this.values?.enrolmentRight;
    }

    private findAssessmentItemAttainments(assessmentItemIds: OtmId[]): Observable<AssessmentItemAttainment[]> {
        return this.attainmentService.findForPerson(
            this.authService.personId(),
            {
                assessmentItemId: assessmentItemIds,
                attainmentType: AttainmentType.ASSESSMENT_ITEM_ATTAINMENT,
                documentState: 'ACTIVE',
                primary: true,
            },
        )
            .pipe(map(attainments => attainments as AssessmentItemAttainment[]));
    }

    private mergeStudyData(
        assessmentItems: AssessmentItem[],
        aiAttainments: AssessmentItemAttainment[],
        cursByAiId: { [assessmentItemId: string]: CourseUnitRealisation[] },
        usedEnrolments: UsedEnrolments[],
    ): AggregateStudyInfo[] {
        return assessmentItems.map(assessmentItem => {
            const constraint = this.enrolmentRight.enrolmentConstraints.find(c => c.assessmentItemId === assessmentItem.id);
            const hasEnrolmentLimit = Number.isInteger(constraint.maxNumberOfEnrolments);
            const enrolmentLimit = hasEnrolmentLimit ? Math.max(0, constraint.maxNumberOfEnrolments) : null;
            const enrolmentCount = usedEnrolments.find(entry => entry.enrolmentRightId === this.enrolmentRight.id &&
                entry.assessmentItemId === assessmentItem.id)?.enrolmentCount ?? 0;
            const enrolmentsRemaining = hasEnrolmentLimit ? Math.max(0, enrolmentLimit - Math.max(0, enrolmentCount)) : null;
            return {
                assessmentItem,
                enrolmentLimit,
                enrolmentsRemaining,
                aiAttainment: aiAttainments.find(attainment => attainment.assessmentItemId === assessmentItem.id),
                // The legacy CUR info component expects a mutable CUR, so clone the immutable objects from Akita
                courseUnitRealisations: cursByAiId[assessmentItem.id]?.map(cloneDeep) ?? [],
            };
        });
    }

    private createEnrolment(courseUnitRealisationId: OtmId, assessmentItemId: OtmId): Observable<Enrolment> {
        return this.enrolmentStudentService.add({
            assessmentItemId,
            courseUnitRealisationId,
            id: this.uuidService.randomOtmId(),
            courseUnitId: this.courseUnit.id,
            enrolmentRightId: this.enrolmentRight.id,
            studyRightId: this.enrolmentRight.studyRightId,
            personId: this.authService.personId(),
            state: 'NOT_ENROLLED',
        } as Enrolment);
    }
}
