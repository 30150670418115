import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OrganisationSettings } from 'common-typescript/types';
import { Observable } from 'rxjs';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

@StaticMembers<DowngradedService>()
@Injectable({
    providedIn: 'root',
})
export class OrganisationSettingsService {

    static downgrade: ServiceDowngradeMappings = {
        moduleName: 'sis-components.service.organisationSettingsService',
        serviceName: 'organisationSettingsService',
    };

    private readonly organisationSettingsUri = '/kori/api/organisation-settings';
    private readonly organisationSettingsForUniversityUri = '/kori/api/organisation-settings/for-university';

    constructor(private http: HttpClient) {}

    getByOrganisationId(organisationId: string): Observable<OrganisationSettings> {
        return this.http.get(`${this.organisationSettingsUri}/${organisationId}`) as Observable<OrganisationSettings>;
    }

    getInheritedSettingsByOrganisationId(organisationId: string): Observable<OrganisationSettings> {
        return this.http.get(`${this.organisationSettingsUri}/${organisationId}/inherited`) as Observable<OrganisationSettings>;
    }

    getOrganisationSettingsForUniversity(universityOrgId: string): Observable<OrganisationSettings[]> {
        return this.http.get(`${this.organisationSettingsForUniversityUri}/${universityOrgId}`) as Observable<OrganisationSettings[]>;
    }

    updateOrganisationSettingsForUniversity(universityOrgId: string, organisationSettings: OrganisationSettings[]): Observable<OrganisationSettings[]> {
        return this.http.post(`${this.organisationSettingsForUniversityUri}/${universityOrgId}`, organisationSettings) as Observable<OrganisationSettings[]>;
    }
}
