import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    CustomStudyDraft,
    OtmId,
    Plan,
    PriorLearningInclusionWorkflowApplication,
    PriorLearningWorkflowApplication,
    StudyRight,
} from 'common-typescript/types';
import { map, Observable, Subject, take } from 'rxjs';
import { DEFAULT_PROMISE_HANDLER } from 'sis-common/ajs-upgraded-modules';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { FileItem } from 'sis-components/file-upload/file-upload.component';
import { HeaderService } from 'sis-components/header/header.service';
import { STUDENT_APPLICATION_TYPE } from 'sis-components/model/student-application-constants';
import { UiRouterService } from 'sis-components/router/ui-router.service';
import { filterStudyRights, StudyRightEntityService } from 'sis-components/service/study-right-entity.service';

import { ApplicationCreationService } from '../../../../common/service/application-creation.service';

@Component({
    selector: 'app-prior-learning-inclusion-application-wizard',
    templateUrl: './prior-learning-inclusion-workflow-application-wizard.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class PriorLearningInclusionWorkflowApplicationWizardComponent implements OnInit {

    @Input() plan: Plan;
    @Input() customStudyDraftId?: OtmId;

    readonly wizardStepKeys = [
        'PROFILE.APPLICATIONS.PRIOR_LEARNING.PHASE_1',
        'PROFILE.APPLICATIONS.PRIOR_LEARNING.PHASE_2',
        'PROFILE.APPLICATIONS.PRIOR_LEARNING.PHASE_3',
    ];

    currentStep = 0;

    workflowApplication: Partial<PriorLearningInclusionWorkflowApplication> = {};
    attachments: FileItem[] = [];
    externalAttainedStudyIds$: Subject<string[]> = new Subject<string[]>();
    customStudyDraft: CustomStudyDraft;
    uploading = false;

    constructor(
        private applicationCreationService: ApplicationCreationService,
        private headerService: HeaderService,
        private studyRightEntityService: StudyRightEntityService,
        private translate: TranslateService,
        private uiRouterService: UiRouterService,
        private appErrorHandler: AppErrorHandler,
        @Inject(DEFAULT_PROMISE_HANDLER) private defaultPromiseHandler: any,
    ) {}

    ngOnInit(): void {
        this.workflowApplication = {
            planId: this.plan?.id,
            type: STUDENT_APPLICATION_TYPE.PRIOR_LEARNING_INCLUSION_APPLICATION,
        };

        this.getStudyRightForPlan(this.plan)
            .pipe(take(1), this.appErrorHandler.defaultErrorHandler())
            .subscribe({
                next: studyRight => this.workflowApplication.studyRightId = studyRight?.id,
            });

        if (this.customStudyDraftId) {
            this.customStudyDraft = this.plan?.customStudyDrafts?.find(draft => draft?.id === this.customStudyDraftId);
            this.workflowApplication.customStudyDraft = this.customStudyDraft ? { ...this.customStudyDraft } : undefined;
        }
    }

    onFormValueChange(changes: Partial<PriorLearningInclusionWorkflowApplication>): void {
        if (changes) {
            this.workflowApplication = { ...this.workflowApplication, ...changes };
        }
    }

    onAttachmentsChange(attachments: FileItem[]): void {
        this.attachments = attachments ?? [];
    }

    onExternalAttachmentsChange($event: string[]): void {
        this.externalAttainedStudyIds$.next($event);
    }

    isFirstStep(): boolean {
        return this.currentStep === 0;
    }

    isLastStep(): boolean {
        return this.currentStep === this.wizardStepKeys.length - 1;
    }

    exit(): void {
        this.uiRouterService.goToPreviousState();
    }

    previous(): void {
        if (!this.isFirstStep()) {
            this.currentStep -= 1;
        }
    }

    continue(changes: Partial<PriorLearningInclusionWorkflowApplication>): void {
        this.onFormValueChange(changes);
        if (!this.isLastStep()) {
            if (this.isFirstStep()) {
                const applicationTypeName = this.translate
                    .instant(`STUDENT_APPLICATIONS.TYPE.${STUDENT_APPLICATION_TYPE.PRIOR_LEARNING_INCLUSION_APPLICATION}`);
                this.headerService.setCustomPageTitle(`${applicationTypeName}: ${this.workflowApplication?.name}`);
            }
            this.currentStep += 1;
        }
    }

    submit(changes: Partial<PriorLearningWorkflowApplication>) {
        if (!this.uploading) {
            this.onFormValueChange(changes as PriorLearningInclusionWorkflowApplication);
            this.uploading = true;
            this.applicationCreationService.createWorkflowApplication(
                this.workflowApplication,
                this.attachments,
                'student.logged-in.profile.applications.prior-learning-application')
                .pipe(take(1), this.appErrorHandler.defaultErrorHandler())
                .subscribe({
                    complete: () => {
                        this.uploading = false;
                    },
                });
        }
    }

    private getStudyRightForPlan(plan: Plan): Observable<StudyRight | null> {
        return this.studyRightEntityService.findStudyRightsForPlan(plan)
            .pipe(
                filterStudyRights({
                    onlyOngoing: true,
                    states: ['ACTIVE', 'PASSIVE', 'ACTIVE_NONATTENDING'],
                }),
                // There should only be one active study right per education + learning opportunity combo
                map(studyRights => studyRights?.length > 0 ? studyRights[0] : null),
            );
    }
}
