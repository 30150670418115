import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component, ElementRef,
    Input,
    OnInit, QueryList, TemplateRef, ViewChild, ViewChildren,
    ViewEncapsulation,
} from '@angular/core';
import { FudisDialogService } from '@funidata/ngx-fudis';
import { PrivatePerson } from 'common-typescript/types';
import { UniversitySettings } from 'common-typescript/types/generated/common-backend';
import _ from 'lodash';
import { skip } from 'rxjs';
import { take } from 'rxjs/operators';
import { ClassifiedPersonInfoToggleModalComponent } from 'sis-components/classified-person-info-toggle-modal/classified-person-info-toggle-modal.component';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { PrivatePersonEntityService } from 'sis-components/service/private-person-entity.service';
import { UniversityService } from 'sis-components/service/university.service';

@Component({
    selector: 'app-basic-info',
    templateUrl: './basic-info.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
})
export class BasicInfoComponent implements OnInit, AfterViewInit {

    @ViewChildren('dialogOpenTriggerButton') dialogOpenTriggerButton: QueryList<ElementRef>;
    @ViewChild('editContactDialog') editContactDialog: TemplateRef<unknown>;
    @Input() studentProfile: PrivatePerson;

    countryUrnFI = 'urn:code:country:246';
    isEditEnabledByUniversity = false;
    classifiedInfoEditEnabledByUniversity = false;
    studentProfileCopy: PrivatePerson;

    constructor(private universityService: UniversityService,
                private appErrorHandler: AppErrorHandler,
                private privatePersonService: PrivatePersonEntityService,
                private dialogService: FudisDialogService) {}

    ngOnInit(): void {
        this.studentProfileCopy = { ...this.studentProfile };

        this.universityService.getCurrentUniversitySettings()
            .pipe(
                take(1),
                this.appErrorHandler.defaultErrorHandler())
            .subscribe((universitySettings) => {
                if (_.get(universitySettings, 'frontendFeatureToggles.studentDetailsEditEnabled') !== false) {
                    this.isEditEnabledByUniversity = true;
                }
                if (_.get(universitySettings, 'frontendFeatureToggles.studentClassifiedInfoToggleable') !== false) {
                    this.classifiedInfoEditEnabledByUniversity = true;
                }
            });
    }

    ngAfterViewInit() {
        // Return focus to toggle button when cancelling cancel-operation
        this.dialogOpenTriggerButton.changes.pipe(skip(1)).subscribe(() => {
            if (this.dialogOpenTriggerButton.length > 0) {
                document.getElementById('dialogOpenTriggerButton').focus();
            }
        });
    }

    openClassifiedInfoDialog(): void {
        this.dialogService.open(ClassifiedPersonInfoToggleModalComponent, { data: { privatePerson: this.studentProfileCopy, staffEdit: false } })
            .afterClosed().subscribe((updatedValues) => {
                if (updatedValues) {
                    this.studentProfileCopy = updatedValues;
                }
            });
    }

    openEditDialog() {
        if (!this.isEditEnabledByUniversity) {
            return;
        }

        this.dialogService.open(this.editContactDialog);

        if (_.isNil(this.studentProfileCopy.primaryAddress) || _.isNil(this.studentProfileCopy.primaryAddress.countryUrn)) {
            _.set(this.studentProfileCopy, 'primaryAddress.countryUrn', this.countryUrnFI);
            _.set(this.studentProfileCopy, 'primaryAddress.type', 'FinnishAddress');
        }
    }

    handleSave(updatedStudentProfile: PrivatePerson) {
        this.studentProfileCopy = updatedStudentProfile;
        this.privatePersonService.saveUserDetails(updatedStudentProfile)
            .pipe(
                take(1),
                this.appErrorHandler.defaultErrorHandler())
            .subscribe({
                next: (person: PrivatePerson) => {
                    this.studentProfileCopy = _.cloneDeep(person);
                },
            });
        this.dialogService.close();
    }
}
