import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SisCommonModule } from 'sis-common/sis-common.module';
import { EnrolmentModule } from 'sis-components/enrolment/enrolment.module';
import { SisComponentsModule } from 'sis-components/sis-components.module';

import { StudentCommonModule } from '../../common/common.module';
import { OpenUniversityCommonModule } from '../common/open-university-common.module';

import { AddToCartButtonComponent } from './add-to-cart-button/add-to-cart-button.component';
import { AssessmentItemTeachingComponent } from './assessment-item-teaching/assessment-item-teaching.component';
import { CarouselProductInfoComponent } from './carousel-product-info/carousel-product-info.component';
import { CourseUnitRealisationInfoComponent } from './course-unit-realisation-info/course-unit-realisation-info.component';
import { OpenUniversityOfferingComponent } from './open-university-offering/open-university-offering.component';
import { ProductCarouselComponent } from './product-carousel/product-carousel.component';
import { ProductSlideBannerPipe } from './product-carousel/product-slide-banner.pipe';
import { ProductDetailsComponent } from './product-details/product-details.component';

@NgModule({
    declarations: [
        AddToCartButtonComponent,
        AssessmentItemTeachingComponent,
        CarouselProductInfoComponent,
        CourseUnitRealisationInfoComponent,
        OpenUniversityOfferingComponent,
        ProductCarouselComponent,
        ProductDetailsComponent,
        ProductSlideBannerPipe,
    ],
    imports: [
        CommonModule,
        EnrolmentModule,
        OpenUniversityCommonModule,
        SisCommonModule,
        SisComponentsModule,
        StudentCommonModule,
        TranslateModule.forChild(),
    ],
    exports: [
        OpenUniversityOfferingComponent,
    ],
})
export class StudyOfferingModule {}
