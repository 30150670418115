import { Injectable } from '@angular/core';
import {
    TokenizeResult,
    TranslationMarkupRendererFactory,
    TranslationMarkupTranspiler,
    TranslationMarkupTranspilerContext,
    TranspileResult,
} from 'ngx-transloco-markup';

export const BR_TOKEN = new (class BrToken {})();

@Injectable()
export class BrTranspiler implements TranslationMarkupTranspiler {
    constructor(private readonly rendererFactory: TranslationMarkupRendererFactory) {
    }

    tokenize(translation: string, offset: number): TokenizeResult | undefined {
        const tags = ['<br>', '<br/>', '<br />'];

        for (const tag of tags) {
            if (translation.startsWith(tag, offset)) {
                return {
                    nextOffset: offset + tag.length,
                    token: BR_TOKEN,
                };
            }
        }

        return undefined;
    }

    transpile(offset: number, context: TranslationMarkupTranspilerContext): TranspileResult | undefined {
        const nextToken = context.tokens[offset];
        const nextOffset = offset + 1;

        if (nextToken !== BR_TOKEN) {
            return undefined;
        }

        context.transpile(nextOffset);

        return {
            nextOffset,
            renderer: this.rendererFactory.createElementRenderer('br'),
        };
    }
}
