import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Attainment, CustomModuleAttainment, DegreeProgrammeAttainment, ModuleAttainment } from 'common-typescript/types';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { isModuleLikeAttainment } from '../AttainmentUtil';

import { AttainmentsById } from './AttainmentTreeTypes';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-attainment-tree-attainment',
    templateUrl: './attainment-tree-attainment.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class AttainmentTreeAttainmentComponent implements OnInit {
    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.attainment.editableTree.attainmentTreeAttainment',
        directiveName: 'sisAttainmentTreeAttainment',
    };

    @Input() attainment: Attainment;
    @Input() attainmentsById: AttainmentsById;
    @Input() expanded?: boolean;
    @Input() showHeader?: boolean;
    @Input() selectedLocale?: string;
    collapsed = true;
    hideAdditionalInfo = true;
    attainmentWithNodes: CustomModuleAttainment | DegreeProgrammeAttainment | ModuleAttainment;

    ngOnInit(): void {
        this.collapsed = !this.expanded;
        if (isModuleLikeAttainment(this.attainment)) {
            this.attainmentWithNodes = this.attainment;
        }
    }
}
