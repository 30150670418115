<div *ngIf="!completionMethods || completionMethods.length === 0">
  <span translate="STUDIES.COURSE_UNIT_INFO_MODAL.NO_COMPLETION_METHODS"></span>
</div>
<div *ngIf="assessmentItemsById">
  <table class="sis-table table-completion-method-matrix" [attr.aria-label]="'COMPLETION_METHOD_MATRIX' | translate" *ngIf="completionMethods?.length > 0">
    <thead>
    <tr role="menu">
      <th *ngFor="let completionMethod of completionMethods; index as cmIndex"
          [class.is-highlighted]="highlightedCompletionMethod?.localId === completionMethod.localId"
          class="col-completion-method-matrix-completion-selection">
        <button type="button" class="non-button completion-method-container" (click)="highlightCompletionMethod(completionMethod)">
          <span [attr.aria-label]="('ARIA_LABEL.VIEW_COMPLETION_METHOD_INFO' | translate) + ' ' +  ('STUDIES.COURSE_UNITS_COMPLETION_METHODS.METHOD'|translate) + ' ' + (cmIndex + 1)"
                class="completion-method-header">{{'STUDIES.COURSE_UNITS_COMPLETION_METHODS.METHOD'|translate}} {{cmIndex + 1}}</span>
          <div>
            <span class="tiny-badge secondary">
              {{('STUDIES.COURSE_UNITS_COMPLETION_METHODS.COMPLETION_METHOD.STUDY_TYPE.' +
              (completionMethod.studyType === 'OPEN_UNIVERSITY_STUDIES' ? 'OPEN_UNIVERSITY_STUDIES' : 'DEGREE_STUDIES')) | translate
              }}
            </span>
          </div>
          <span class="selected-method" [class.active]="selectedCompletionMethod?.localId === completionMethod.localId">
            <span translate="STUDIES.COURSE_UNITS_COMPLETION_METHODS.SELECTED"></span>
          </span>
        </button>
      </th>
      <th class="completion-method-table-title">
        <h4 translate="STUDIES.COURSE_UNIT_INFO_MODAL.ASSESSMENT_ITEM_HEADER"></h4>
      </th>
      <th class="completion-method-table-title">
        <h4 translate="STUDIES.COURSE_UNIT_INFO_MODAL.COURSE_UNIT_REALISATIONS"></h4>
      </th>
    </tr>
    </thead>
    <tbody>

    <tr *ngFor="let assessmentItemId of orderedAssessmentItemIds; index as rowIndex"
        [class.highlighted-completion-methods]="completionMethodContainsAssessmentItemId(highlightedCompletionMethod, assessmentItemId)"
        [class.is-selected]="isAssessmentItemSelectedForCourseUnit(assessmentItemId)"
        class="col-completion-method-matrix-assessment-item">
      <td *ngFor="let completionMethod of completionMethods; index as colIndex"
          class="col-completion-method-matrix-completion-selection"
          [class.is-highlighted]="highlightedCompletionMethod?.localId === completionMethod.localId">
        <div *ngIf="completionMethodContainsAssessmentItemId(completionMethod, assessmentItemId)">
          <span *ngIf="completionMethod.typeOfRequire ===  'ALL_SELECTED_REQUIRED'">
            <span *ngIf="completionMethod.localId !== selectedCompletionMethod?.localId">
              <sis-icon icon="dot"></sis-icon>
            </span>
            <span *ngIf="completionMethod.localId === selectedCompletionMethod?.localId">
              <sis-icon *ngIf="isAssessmentItemSelectedInCompletionMethod(assessmentItemId, completionMethod)" icon="check"></sis-icon>
              <span *ngIf="!isAssessmentItemSelectedInCompletionMethod(assessmentItemId, completionMethod)">
                <label class="control control-inline control-checkbox sis-checkbox">
                  <input type="checkbox"
                         [checked]="false"
                         [disabled]="!canAssessmentItemBeSelected(assessmentItemId)"
                         (click)="toggleAssessmentItemSelection(assessmentItemId, completionMethod)">
                  <div class="control-indicator"></div>
                </label>
              </span>
            </span>
          </span>
          <span *ngIf="completionMethod.typeOfRequire !== 'ALL_SELECTED_REQUIRED'">
            <label class="control control-inline control-checkbox sis-checkbox"
                   [class.disabled]="completionMethod.localId !== selectedCompletionMethod?.localId">
              <input type="checkbox"
                     [checked]="isAssessmentItemSelectedInCompletionMethod(assessmentItemId, completionMethod)"
                     [disabled]="completionMethod.localId !== selectedCompletionMethod?.localId || !canAssessmentItemBeSelected(assessmentItemId)"
                     (click)="toggleAssessmentItemSelection(assessmentItemId, completionMethod)">
              <div class="control-indicator"></div>
            </label>
          </span>
        </div>
      </td>
      <td class="assessment-items">
        {{ assessmentItemsById[assessmentItemId] | assessmentItemNameInCourseUnitContext: courseUnit | localizedString }}
        <span *ngIf="!assessmentItemAttainmentsById[assessmentItemId]">
          ({{ assessmentItemsById[assessmentItemId].credits | creditRange }})
        </span>
        <span *ngIf="assessmentItemAttainmentsById[assessmentItemId]">
          ({{ assessmentItemAttainmentsById[assessmentItemId].credits }} <span translate="CREDITS"></span>)
          <sis-icon icon="achievement"></sis-icon>
          {{ assessmentItemAttainmentsById[assessmentItemId].gradeId | grade: assessmentItemAttainmentsById[assessmentItemId].gradeScaleId | async }}
        </span>
        <span *ngIf="completionMethodContainsAssessmentItemId(selectedCompletionMethod, assessmentItemId) && !isAssessmentItemSelectedForCourseUnit(assessmentItemId) && !canAssessmentItemBeSelected(assessmentItemId)">
          <br/>
          <sis-icon icon="alert" color="attention"></sis-icon>
          <span [translate]="'STUDIES.COURSE_UNIT_INFO_MODAL.ASSESSMENT_ITEM_ALREADY_SELECTED_UNDER_ANOTHER_COURSE_UNIT'"
                [translateParams]="{courseUnitName: getCourseUnitForAssessmentItem(assessmentItemId) | entityName | localizedString}"></span>
        </span>
      </td>
      <td class="course-unit-realisations">
        <ng-container *ngIf="courseUnitRealisationsReady">
          <span class="course-unit-realisations-list"
                *ngFor="let courseUnitRealisation of getCourseUnitRealisationsForAssessmentItem(assessmentItemsById[assessmentItemId])|slice:0:shownRelations">
            <a href="javascript:void(0);"
               (click)="navigateToCurSection(courseUnitRealisation)"
               *ngIf="completionMethodContainsAssessmentItemId(highlightedCompletionMethod, assessmentItemId)">{{ courseUnitRealisation | courseUnitRealisationFullName | localizedString }}</a>
            <span *ngIf="!completionMethodContainsAssessmentItemId(highlightedCompletionMethod, assessmentItemId)">{{ courseUnitRealisation | courseUnitRealisationFullName | localizedString }}</span>
            <sis-cur-flow-state-badge [courseUnitRealisation]="courseUnitRealisation" [hidePublishDate]="true"></sis-cur-flow-state-badge>
          </span>
          <span *ngIf="getCourseUnitRealisationsForAssessmentItem(assessmentItemsById[assessmentItemId])?.length === 0"
                class="guidance"
                translate="STUDIES.COURSE_UNIT_INFO_MODAL.NO_COURSE_UNIT_REALISATIONS">
          </span>
          <button type="button" class="link-button expand"
                  (click)="shownRelations = getCourseUnitRealisationsForAssessmentItem(assessmentItemsById[assessmentItemId])?.length"
                  *ngIf="getCourseUnitRealisationsForAssessmentItem(assessmentItemsById[assessmentItemId])?.length > shownRelations"
          >{{'STUDIES.COURSE_UNIT_INFO_MODAL.SHOW'|translate}}</button>
          <button type="button" class="link-button expand"
                  (click)="shownRelations = 5"
                  *ngIf="getCourseUnitRealisationsForAssessmentItem(assessmentItemsById[assessmentItemId])?.length === shownRelations && shownRelations > 5"
          >{{'STUDIES.COURSE_UNIT_INFO_MODAL.HIDE'|translate}}</button>
        </ng-container>
      </td>
    </tr>
    </tbody>
  </table>
</div>
<div *ngIf="assessmentItemsById && completionMethods && completionMethods.length > 0" class="completion-method-info-container">
  <h2>
    <span translate="STUDIES.COURSE_UNITS_COMPLETION_METHODS.METHOD_LONG"
          [translateParams]="{n: getHighlightedCompletionMethodIndex() + 1}"></span>
    <span *ngIf="highlightedCompletionMethod && selectedCompletionMethod?.localId === highlightedCompletionMethod.localId" class="completion-method-selected">
      <sis-icon icon="check"></sis-icon>
      <span translate="STUDIES.COURSE_UNITS_COMPLETION_METHODS.SELECTED"></span>
    </span>
  </h2>
  <button type="button" class="btn btn-success btn-lg select-method"
          *ngIf="highlightedCompletionMethod && selectedCompletionMethod?.localId !== highlightedCompletionMethod.localId"
          (click)="selectCompletionMethod(highlightedCompletionMethod)"
          translate="STUDIES.COURSE_UNITS_COMPLETION_METHODS.SELECT_CURRENT_COMPLETION_METHOD"></button>

  <div *ngIf="highlightedCompletionMethod?.typeOfRequire === 'ALL_SELECTED_REQUIRED'" class="guidance-info">
    <h4 translate>STUDIES.COURSE_UNIT_INFO_MODAL.REQUIRE_RANGE_TITLE</h4>
    <span translate="STUDIES.COURSE_UNIT_INFO_MODAL.ALL_ASSESSMENT_ITEMS_REQUIRED"></span>
  </div>

  <div *ngIf="highlightedCompletionMethod?.typeOfRequire !== 'ALL_SELECTED_REQUIRED'" class="guidance-info">
    <div *ngIf="highlightedCompletionMethod?.typeOfRequire === 'OPTIONAL_WITH_REQUIRE_RANGE'">
      <h4 translate>STUDIES.COURSE_UNIT_INFO_MODAL.REQUIRE_RANGE_TITLE</h4>
      <div>{{ highlightedCompletionMethod?.require | intRange }}</div>
    </div>
    <div *ngIf="highlightedCompletionMethod?.assessmentItemOptionalityDescription">
      <h4 translate>STUDIES.COURSE_UNIT_INFO_MODAL.OPTIONALITY_DESCRIPTION_TITLE</h4>
      <sis-course-unit-info-modal-text-field [localizedMarkupStringValue]="highlightedCompletionMethod?.assessmentItemOptionalityDescription"
                                             [language]="language"></sis-course-unit-info-modal-text-field>
    </div>
  </div>

  <div class="row assessment-item-info" *ngIf="highlightedCompletionMethod">
    <div class="col-4 form-group-mimic">
      <h4 translate>STUDIES.COURSE_UNITS_COMPLETION_METHODS.COMPLETION_METHOD.DESCRIPTION</h4>
      <sis-course-unit-info-modal-text-field [localizedMarkupStringValue]="highlightedCompletionMethod.description"
                                             [language]="language"></sis-course-unit-info-modal-text-field>
    </div>
    <div class="col-4 form-group-mimic">
      <h4 translate>STUDIES.COURSE_UNITS_COMPLETION_METHODS.COMPLETION_METHOD.EVALUATION_CRITERIA</h4>
      <sis-course-unit-info-modal-text-field [localizedStringValue]="highlightedCompletionMethod.evaluationCriteria"
                                             [language]="language"></sis-course-unit-info-modal-text-field>
    </div>
    <div class="col-4 form-group-mimic">
      <h4 translate>STUDIES.COURSE_UNITS_COMPLETION_METHODS.COMPLETION_METHOD.STUDY_PERIOD_REPEAT</h4>
      <sis-completion-method-repeats [completionMethod]="highlightedCompletionMethod" [language]="language"></sis-completion-method-repeats>
    </div>
  </div>

  <h3 translate>STUDIES.COURSE_UNIT_INFO_MODAL.ASSESSMENT_ITEMS</h3>
  <div *ngIf="completionMethodAssessmentItems && completionMethodAssessmentItems.length === 0" class="form-group-mimic">
    -
  </div>
  <div *ngFor="let assessmentItem of completionMethodAssessmentItems">
    <sis-expandable [level]="4"
                    [title]="(assessmentItem | assessmentItemNameInCourseUnitContext: courseUnit | localizedString) + ' ' + (assessmentItem.credits | creditRange)"
                    [closed]="!isAssessmentItemExpandedById[assessmentItem.id]"
                    (closedChange)="toggleAssessmentItem($event, assessmentItem.id)">
      <ng-template sisExpandableContent>
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group-mimic">
              <h4 translate>STUDIES.COURSE_UNIT_ASSESSMENT_ITEMS.NAME_SPECIFIER</h4>
              <p class="form-control-static">{{ assessmentItem.nameSpecifier | localizedString }}</p>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group-mimic">
              <h4 translate>STUDIES.COURSE_UNIT_ASSESSMENT_ITEMS.TYPE</h4>
              <p class="form-control-static">{{ assessmentItem.assessmentItemType | codeLocalizedName | async }}</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group-mimic">
              <h4 translate>STUDIES.COURSE_UNIT_ASSESSMENT_ITEMS.POSSIBLE_ATTAINMENT_LANGUAGES</h4>
              <p class="form-control-static" *ngFor="let languageUrn of assessmentItem.possibleAttainmentLanguages">
                {{ languageUrn | codeLocalizedName | async }}
              </p>
              <p *ngIf="assessmentItem.possibleAttainmentLanguages?.length === 0" class="form-control-static">-</p>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group-mimic">
              <h4 translate>STUDIES.COURSE_UNIT_ASSESSMENT_ITEMS.STUDY_FORMAT</h4>
              <p class="form-control-static">
                <sis-course-unit-info-modal-text-field [localizedMarkupStringValue]="assessmentItem.studyFormat" [language]="language"></sis-course-unit-info-modal-text-field>
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group-mimic">
              <h4 translate>STUDIES.COURSE_UNIT_ASSESSMENT_ITEMS.LEARNING_MATERIAL</h4>
              <p class="form-control-static">
                <sis-course-unit-info-modal-text-field [localizedMarkupStringValue]="assessmentItem.learningMaterial" [language]="language"></sis-course-unit-info-modal-text-field>
              </p>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group-mimic">
              <h4 translate>STUDIES.COURSE_UNIT_ASSESSMENT_ITEMS.LITERATURE</h4>
              <div *ngFor="let item of assessmentItem.literature">
                <span *ngIf="item.type === 'LiteratureName'">{{ item.name }}</span>
                <span *ngIf="item.type === 'LiteratureReference'">
                  <fudis-link [externalLink]="item.url"/>
              </span>
              </div>
              <div *ngIf="assessmentItem.literature?.length === 0">-</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group-mimic">
              <h4 translate>STUDIES.COURSE_UNIT_ASSESSMENT_ITEMS.CONTENT_DESCRIPTION</h4>
              <p class="form-control-static">
                <sis-course-unit-info-modal-text-field [localizedMarkupStringValue]="assessmentItem.contentDescription" [language]="language"></sis-course-unit-info-modal-text-field>
              </p>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group-mimic">
              <h4 translate>STUDIES.COURSE_UNIT_ASSESSMENT_ITEMS.GRADING</h4>
              <p class="form-control-static">
                <sis-course-unit-info-modal-text-field [localizedMarkupStringValue]="assessmentItem.grading" [language]="language"></sis-course-unit-info-modal-text-field>
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group-mimic">
              <h4 translate>STUDIES.COURSE_UNIT_ASSESSMENT_ITEMS.STUDY_FIELD</h4>
              <p class="form-control-static">{{ assessmentItem.studyField | codeLocalizedName | async }}</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 teacher">
            <div class="form-group-mimic">
              <h4 translate="STUDIES.COURSE_UNIT_ASSESSMENT_ITEMS.RESPONSIBILITY_INFOS"></h4>
              <sis-module-responsibility-info [responsibilityInfos]="assessmentItem.responsibilityInfos" [language]="language"></sis-module-responsibility-info>
              <p *ngIf="assessmentItem.responsibilityInfos?.length === 0" class="form-control-static">-</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <sis-responsible-organisation-info [organisations]="assessmentItem.organisations"></sis-responsible-organisation-info>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <h3 translate>STUDIES.COURSE_UNIT_INFO_MODAL.COURSE_UNIT_REALISATIONS</h3>
            <ng-container *ngFor="let courseUnitRealisation of getCourseUnitRealisationsForAssessmentItem(assessmentItem)">
              <div id="{{courseUnitRealisation.id}}">
                <sis-expandable [variant]="'naked-dotted'"
                                [closed]="!isCourseUnitRealisationExpandedById[courseUnitRealisation.id]"
                                (closedChange)="toggleCourseUnitRealisation($event, courseUnitRealisation.id)"
                                [title]="courseUnitRealisation | courseUnitRealisationFullName | localizedString"
                                [level]="4">
                  <ng-template sisExpandableCustomTitle>
                    <sis-tiny-badge *ngIf="courseUnitRealisation.flowState === 'CANCELLED' || courseUnitRealisation.flowState === 'ARCHIVED'"
                                    variant="secondary">{{('FLOW_STATE.' + courseUnitRealisation.flowState) | translate}}</sis-tiny-badge>
                  </ng-template>
                  <ng-template sisExpandableContent>
                    <sis-course-unit-info-modal-course-unit-realisation [courseUnitRealisation]="courseUnitRealisation"
                                                                        [language]="language"></sis-course-unit-info-modal-course-unit-realisation>
                  </ng-template>
                </sis-expandable>
              </div>
            </ng-container>
            <p class="guidance" *ngIf="getCourseUnitRealisationsForAssessmentItem(assessmentItem)?.length === 0"
               translate="STUDIES.COURSE_UNIT_INFO_MODAL.NO_COURSE_UNIT_REALISATIONS">
            </p>
          </div>
        </div>
      </ng-template>
    </sis-expandable>
  </div>
</div>
