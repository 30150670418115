import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

export type BadgeVariant = 'accent' | 'danger' | 'primary' | 'secondary' | 'success';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-tiny-badge',
    templateUrl: './tiny-badge.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TinyBadgeComponent {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.badge.tiny-badge.downgraded',
        directiveName: 'sisTinyBadge',
    };

    /**
     * The content of the badge. Using content projection for defining the content is preferable, since it's
     * more flexible. This input property mainly exists to make writing Storybook stories easier.
     */
    @Input() content?: string;

    /** Defines the color of the badge (see badge.scss). Defaults to 'secondary'. */
    @Input() variant?: BadgeVariant = 'secondary';

    /** Any custom CSS classes for the badge. */
    @Input() customClasses?: string;

    /** Alternatively badge color can be dependent on flowState. Defaults to 'secondary' */
    @Input() flowState?: string;

    getBadgeClass() {
        const successFlowStates = ['PUBLISHED_ACTIVE', 'PUBLISHED_FUTURE', 'PUBLISHED_EXPIRED', 'ACTIVE', 'PUBLISHED'];
        const accentFlowStates = ['NOT_READY', 'DRAFT'];
        const dangerFlowStates = ['CANCELLED_ACTIVE', 'CANCELLED_EXPIRED', 'CANCELLED'];

        if (successFlowStates.indexOf(this.flowState) > -1) {
            return 'success';
        }
        if (accentFlowStates.indexOf(this.flowState) > -1) {
            return 'accent';
        }
        if (dangerFlowStates.indexOf(this.flowState) > -1) {
            return 'danger';
        }
        return 'secondary';
    }
}
