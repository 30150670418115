import angular from 'angular';
import { localeServiceModule } from 'sis-common/l10n/localeService';
import { MarkupService } from '../service/markup.service.ts';
export const localizedMarkupStringFilterModule = 'sis-components.string.localizedMarkupStringFilterModule';
(function () {
  localizedMarkupString.$inject = ["localeService", "markupService"];
  angular.module(localizedMarkupStringFilterModule, [localeServiceModule, MarkupService.downgrade.moduleName]).filter('localizedMarkupString', localizedMarkupString);

  /**
   * @ngInject
   */
  function localizedMarkupString(localeService, markupService) {
    return function (markup, locale) {
      return markupService.parse(localeService.getLocalizedValue(markup, locale), locale);
    };
  }
})();