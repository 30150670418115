import * as _ from 'lodash';

import {
    ApprovalRequiredState,
    EntityWithRule,
    Module,
    ModuleContentApplication,
    ModuleContentApprovalState, WorkflowState,
} from '../../../types';

import { ModuleContext } from './context/moduleContext';
import { PlanValidationResult } from './planValidationResult';

export class ModuleContentApplicationValidation {

    static validateModuleContentApplication(
        moduleContentApplication: ModuleContentApplication,
        moduleContext: ModuleContext,
        planValidationResult: PlanValidationResult,
    ): ModuleContentApprovalState {

        let validPerMCA = true;

        validPerMCA = ModuleContentApplicationValidation.goThroughCourseUnitSelections(moduleContentApplication, moduleContext, planValidationResult, validPerMCA);
        validPerMCA = ModuleContentApplicationValidation.goThroughModuleSelections(moduleContentApplication, moduleContext, planValidationResult, validPerMCA);
        validPerMCA = ModuleContentApplicationValidation.goThroughCustomCourseUnitAttainmentSelections(moduleContentApplication, moduleContext, planValidationResult, validPerMCA);
        validPerMCA = ModuleContentApplicationValidation.goThroughCustomModuleAttainmentSelections(moduleContentApplication, moduleContext, planValidationResult, validPerMCA);
        validPerMCA = ModuleContentApplicationValidation.goThroughCustomStudyDrafts(moduleContentApplication, moduleContext, planValidationResult, validPerMCA);

        _.forEach(moduleContext.unmatchedCourseUnitsById,
                  (unmatched) => {
                      validPerMCA = false;
                      _.set(planValidationResult.courseUnitValidationResults[unmatched.id], 'invalidAccordingToModuleContentApproval', true);
                  });
        _.forEach(moduleContext.unmatchedModulesById,
                  (unmatched) => {
                      validPerMCA = false;
                      _.set(planValidationResult.moduleValidationResults[unmatched.id], 'invalidAccordingToModuleContentApproval', true);
                  });
        _.forEach(moduleContext.unmatchedCustomCourseUnitAttainmentsById,
                  (unmatched) => {
                      validPerMCA = false;
                      _.set(planValidationResult.customCourseUnitAttainmentValidationResults[unmatched.id], 'invalidAccordingToModuleContentApproval', true);
                  });
        _.forEach(moduleContext.unmatchedCustomModuleAttainmentsById,
                  (unmatched) => {
                      validPerMCA = false;
                      _.set(planValidationResult.customModuleAttainmentValidationResults[unmatched.id], 'invalidAccordingToModuleContentApproval', true);
                  });

        if (!_.isEmpty(moduleContext.unmatchedCustomStudyDraftsById)) {
            validPerMCA = false;
        }

        return validPerMCA ? ModuleContentApprovalState.VALID : ModuleContentApprovalState.INVALID;
    }

    static goThroughCourseUnitSelections(moduleContentApplication: ModuleContentApplication, moduleContext: ModuleContext, planValidationResult: PlanValidationResult, validPerMCAOld: boolean): boolean {
        let validPerMCA = validPerMCAOld;
        _.forEach(moduleContentApplication.courseUnitSelections, (approvalCourseUnitSelection) => {
            const unmatched = _.find(moduleContext.unmatchedCourseUnitsByGroupId, courseUnit => courseUnit.groupId === approvalCourseUnitSelection);
            if (!unmatched) {
                validPerMCA = false;
            } else if (moduleContext.consumeCourseUnit(unmatched, planValidationResult)) {
                _.set(planValidationResult.courseUnitValidationResults[unmatched.id], 'invalidAccordingToModuleContentApproval', false);
            } else {
                _.set(planValidationResult.courseUnitValidationResults[unmatched.id], 'invalidAccordingToModuleContentApproval', true);
                validPerMCA = false;
            }
        });
        return validPerMCA;
    }

    static goThroughModuleSelections(moduleContentApplication: ModuleContentApplication, moduleContext: ModuleContext, planValidationResult: PlanValidationResult, validPerMCAOld: boolean): boolean {
        let validPerMCA = validPerMCAOld;
        _.forEach(moduleContentApplication.moduleSelections, (approvalModuleSelection) => {
            const unmatched = _.find(moduleContext.unmatchedModulesByGroupId, module => module.groupId === approvalModuleSelection);
            if (!unmatched) {
                validPerMCA = false;
            } else if (moduleContext.consumeModule(unmatched, planValidationResult)) {
                _.set(planValidationResult.moduleValidationResults[unmatched.id], 'invalidAccordingToModuleContentApproval', false);
            } else {
                _.set(planValidationResult.moduleValidationResults[unmatched.id], 'invalidAccordingToModuleContentApproval', true);
                validPerMCA = false;
            }
        });
        return validPerMCA;
    }

    static goThroughCustomCourseUnitAttainmentSelections(moduleContentApplication: ModuleContentApplication, moduleContext: ModuleContext, planValidationResult: PlanValidationResult, validPerMCAOld: boolean): boolean {
        let validPerMCA = validPerMCAOld;
        _.forEach(moduleContentApplication.customCourseUnitAttainmentSelections, (approvalCustomCourseUnitAttainmentSelection) => {
            const unmatched = _.find(moduleContext.unmatchedCustomCourseUnitAttainmentsById, customCourseUnitAttainment =>
                customCourseUnitAttainment.id === approvalCustomCourseUnitAttainmentSelection);
            if (!unmatched) {
                validPerMCA = false;
            } else if (moduleContext.consumeCustomCourseUnitAttainment(unmatched, planValidationResult)) {
                _.set(planValidationResult.customCourseUnitAttainmentValidationResults[unmatched.id], 'invalidAccordingToModuleContentApproval', false);
            } else {
                _.set(planValidationResult.customCourseUnitAttainmentValidationResults[unmatched.id], 'invalidAccordingToModuleContentApproval', true);
                validPerMCA = false;
            }
        });
        return validPerMCA;
    }

    static goThroughCustomModuleAttainmentSelections(moduleContentApplication: ModuleContentApplication, moduleContext: ModuleContext, planValidationResult: PlanValidationResult, validPerMCAOld: boolean): boolean {
        let validPerMCA = validPerMCAOld;
        _.forEach(moduleContentApplication.customModuleAttainmentSelections, (approvalCustomModuleAttainmentSelection) => {
            const unmatched = _.find(
                moduleContext.unmatchedCustomModuleAttainmentsById,
                customModuleAttainment => customModuleAttainment.id === approvalCustomModuleAttainmentSelection);
            if (!unmatched) {
                validPerMCA = false;
            } else if (moduleContext.consumeCustomModuleAttainment(unmatched, planValidationResult)) {
                _.set(planValidationResult.customModuleAttainmentValidationResults[unmatched.id], 'invalidAccordingToModuleContentApproval', false);
            } else {
                _.set(planValidationResult.customModuleAttainmentValidationResults[unmatched.id], 'invalidAccordingToModuleContentApproval', true);
                validPerMCA = false;
            }
        });
        return validPerMCA;
    }

    static goThroughCustomStudyDrafts(moduleContentApplication: ModuleContentApplication, moduleContext: ModuleContext, planValidationResult: PlanValidationResult, validPerMCAOld: boolean): boolean {
        let validPerMCA = validPerMCAOld;
        _.forEach(moduleContentApplication.customStudyDrafts, (approvalCustomStudyDraft) => {
            const matchingCustomCourseUnitAttainment = _.find(
                moduleContext.unmatchedCustomCourseUnitAttainmentsById,
                planCustomCourseUnitAttainment => planCustomCourseUnitAttainment.customStudyDraftId === approvalCustomStudyDraft.id,
            );
            const matchingCustomStudyDraft = _.find(
                moduleContext.unmatchedCustomStudyDraftsById,
                customStudyDraft => customStudyDraft.id === approvalCustomStudyDraft.id,
            );

            if (!matchingCustomStudyDraft && !matchingCustomCourseUnitAttainment) {
                validPerMCA = false;
            }

            if (matchingCustomCourseUnitAttainment) {
                if (moduleContext.consumeCustomCourseUnitAttainment(matchingCustomCourseUnitAttainment, planValidationResult)) {
                    _.set(
                        planValidationResult.customCourseUnitAttainmentValidationResults[matchingCustomCourseUnitAttainment.id],
                        'invalidAccordingToModuleContentApproval',
                        false,
                    );
                } else {
                    _.set(
                        planValidationResult.customCourseUnitAttainmentValidationResults[matchingCustomCourseUnitAttainment.id],
                        'invalidAccordingToModuleContentApproval',
                        true,
                    );
                    validPerMCA = false;
                }
            } else if (matchingCustomStudyDraft) {
                moduleContext.consumeCustomStudyDraft(matchingCustomStudyDraft);
            }

        });
        return validPerMCA;
    }

    static getApprovalRequiredState(module: Module, planValidationResult: PlanValidationResult): ApprovalRequiredState | null {
        const matchingModuleContentApproval = _.get(planValidationResult.matchingModuleContentApprovalsByModuleId, module.id);
        if (!!module.moduleContentApprovalRequired && !!matchingModuleContentApproval &&
            this.isModuleContentApprovalEffective(matchingModuleContentApproval)) {
            return ApprovalRequiredState.HAS_MODULE_CONTENT_APPROVAL_OBJECT;
        }
        if (!!module.moduleContentApprovalRequired) {
            return ApprovalRequiredState.APPROVAL_REQUIRED;
        }
        return null;
    }

    static getModuleContentApprovalState(module: EntityWithRule, planValidationResult: PlanValidationResult): WorkflowState {
        const matchingModuleContentApproval = _.get(planValidationResult.matchingModuleContentApprovalsByModuleId, module.id);
        return _.get(matchingModuleContentApproval, 'state') || null;
    }

    static isModuleContentApproved(moduleContentApprovalState: string): boolean {
        return _.includes(['ACCEPTED', 'CONDITIONAL'], moduleContentApprovalState);
    }

    static isModuleContentApprovalEffective(mca: ModuleContentApplication): boolean {
        return _.includes(['REQUESTED', 'IN_HANDLING', 'ACCEPTED', 'CONDITIONAL'], mca.state);
    }

}
