import { Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '@uirouter/core';
import { MaxLength } from 'common-typescript/src/validationConstants';
import {
    AssessmentItem,
    CompletionMethod,
    CourseUnit,
    CourseUnitRealisation,
    CourseUnitRealisationSeats,
    CreditRange,
    Enrolment,
    EnrolmentAllocationCounts,
    EnrolmentQuestion,
    EnrolmentQuestionAnswer,
    EnrolmentQuestionnaire,
    EnrolmentQuestionnaireAnswers,
    EnrolmentReservationRequest,
    EnrolmentStudySubGroup,
    EnrolmentStudySubGroupAllocationCounts,
    EntityMetadata,
    IntRange,
    OpenUniversityCart,
    OpenUniversityProduct,
    OtmId,
    StudyGroupSet,
    StudySubGroup,
} from 'common-typescript/types';
import { catchError, combineLatest, EMPTY, from, Observable, of, Subject } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { EnrichedAssessmentItemsForOpenUniProductQueryService, StudyEvent } from 'sis-common/generated/graphql';
import { graphqlUtils } from 'sis-common/graphql/graphqlUtils';
import { LocalizedStringPipe } from 'sis-common/l10n/localized-string.pipe';
import { COMMON_ENROLMENT_QUESTIONNAIRE_SERVICE } from 'sis-components/ajs-upgraded-modules';
import { AlertsService, AlertType } from 'sis-components/alerts/alerts-ng.service';
import { ConfirmDialogService } from 'sis-components/confirm/confirm-dialog.service';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { maxLength, required, requiredIf } from 'sis-components/form/form-validators';
import { SisFormBuilder } from 'sis-components/form/sis-form-builder.service';
import { CreditRangePipe } from 'sis-components/number/credit-range.pipe';
import { CourseUnitRealisationEntityService } from 'sis-components/service/course-unit-realisation-entity.service';
import { EnrolmentAllocationCountsEntityService } from 'sis-components/service/enrolment-allocation-counts-entity.service';
import { EnrolmentCalculationConfigEntityService } from 'sis-components/service/enrolment-calculation-config-entity.service';
import { convertAJSPromiseToNative } from 'sis-components/util/utils';

import { EnrolmentStudentService } from '../../common/service/enrolment-student.service';
import { OpenUniversityEnrichedEnrolmentsService } from '../common/service/open-university-enriched-enrolments.service';

import { EmittedCurDataChangeObj } from './open-university-cur-selection-step/open-university-cur-selection-step.component';

@Component({
    selector: 'app-open-university-enrolment-wizard',
    templateUrl: './open-university-enrolment-wizard.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class OpenUniversityEnrolmentWizardComponent implements OnInit, OnDestroy {

    @Input() courseUnit: CourseUnit;
    @Input() openUniversityCart: OpenUniversityCart;
    @Input() openUniversityProduct: OpenUniversityProduct;
    selectedCompletionMethod: CompletionMethod;
    assessmentItemsData: EnrichedAssItem[];
    openUniStudentEnrolments: EnrichedEnrolment[];
    enrolmentAllocationCountsList: EnrolmentAllocationCounts[];
    wizardProgressStepKeys: string[] = [];
    currentStep = 0;
    destroyed$ = new Subject<void>();
    loading = true;
    saving = false;
    currentStepName: string;
    form: FormArray;
    showErrors = false;
    curSeats: CourseUnitRealisationSeats[];

    constructor(private assessmentItemsQueryService: EnrichedAssessmentItemsForOpenUniProductQueryService,
                private alertService: AlertsService,
                private courseUnitRealisationService: CourseUnitRealisationEntityService,
                private translateService: TranslateService,
                private localizedString: LocalizedStringPipe,
                private creditRangePipe: CreditRangePipe,
                private fb: SisFormBuilder,
                private enrolmentStudentService: EnrolmentStudentService,
                private stateService: StateService,
                private enrolmentAllocationCountsEntityService: EnrolmentAllocationCountsEntityService,
                private enrolmentCalculationConfigService: EnrolmentCalculationConfigEntityService,
                private confirmDialogService: ConfirmDialogService,
                private titleService: Title,
                private openUniversityEnrichedEnrolmentsService: OpenUniversityEnrichedEnrolmentsService,
                private appErrorHandler: AppErrorHandler,
                @Inject(COMMON_ENROLMENT_QUESTIONNAIRE_SERVICE) private enrolmentQuestionnaireService: any) {
    }

    @ViewChild('currentStepNameFocus', {}) currentStepNameFocus: ElementRef;

    ngOnInit(): void {
        this.selectedCompletionMethod = this.findSelectedCompletionMethodWithOpenUniProduct();
        this.assessmentItemsQueryService.fetch({
            assessmentItemIds: this.selectedCompletionMethod.assessmentItemIds,
        }).pipe(
            map(assItemData => {
                const assItems = graphqlUtils.removeTypeNames(assItemData.data.searchResult);
                assItems.forEach((assItem: EnrichedAssItem) => {
                    const filteredCurs = assItem.courseUnitRealisations.filter((cur) => this.curFoundFromEnrolmentConstraintCurs(assItem, cur));
                    assItem.courseUnitRealisations = this.courseUnitRealisationService.sortByActivityPeriodAndName(filteredCurs);
                });
                return assItems;
            }),
            switchMap(assessmentItems => combineLatest([
                of(assessmentItems),
                this.getExistingEnrolments(assessmentItems),
                this.getEnrolmentAllocationCounts(assessmentItems),
                this.enrolmentCalculationConfigService.getOpenUniversityProductSeats(this.openUniversityProduct.id),
            ])),
            takeUntil(this.destroyed$),
            this.appErrorHandler.defaultErrorHandler(),
        )
            .subscribe({
                next: ([assessmentItems, enrolments, enrolmentAllocationCountsList, pdroductSeats]) => {
                    this.assessmentItemsData = assessmentItems;
                    this.openUniStudentEnrolments = enrolments;
                    this.enrolmentAllocationCountsList = enrolmentAllocationCountsList;
                    this.curSeats = pdroductSeats?.curSeats;
                    this.countIfSSGsAreFull(enrolmentAllocationCountsList);
                    this.initWizardProgressStepKeys();
                    this.setCurrentStepName();
                    this.initForm();
                    this.loading = false;
                },
            });
    }

    private curFoundFromEnrolmentConstraintCurs(assItem: EnrichedAssItem, cur: EnrichedCur): boolean {
        // Empty enrolmentConstraints list means that all curs all allowed
        if (this.openUniversityProduct.enrolmentConstraints.length === 0) {
            return true;
        }

        const enrolmentConstraint = this.openUniversityProduct.enrolmentConstraints.find(enrolmentConstr => enrolmentConstr.assessmentItemId === assItem.id);
        if (enrolmentConstraint) {
            return enrolmentConstraint.overrideEnrolmentPeriodForCURIds.some(curId => curId === cur.id);
        }
        return false;
    }

    ngOnDestroy(): void {
        // Restore default title when leaving the page, because titleService is not used globally
        // in this app's views.
        this.titleService.setTitle(this.translateService.instant('PAGE_TITLE_STUDENT'));
        this.destroyed$.next();
    }

    getExistingEnrolments(assessmentItemsData: EnrichedAssItem[]): Observable<EnrichedEnrolment[]> {
        return this.openUniversityEnrichedEnrolmentsService.getExistingEnrolmentsForCartItem(assessmentItemsData, this.openUniversityCart, this.openUniversityProduct);
    }

    initForm() {
        this.form = this.fb.array(
            this.assessmentItemsData.map(assItem => this.createAssItemGroup(assItem)),
        );

        this.tryInitFormWithPreExistingData();
    }

    private tryInitFormWithPreExistingData() {
        this.assessmentItemsData.forEach((assItem, index) => {
            const existingCurId = this.getExistingEnrolmentByAssItemId(assItem.id)?.courseUnitRealisationId;
            if (existingCurId) {
                const cur = assItem?.courseUnitRealisations?.find(courseUnitRealisation => courseUnitRealisation.id === existingCurId);
                // Temporarily set currentStep to match current assItem iteration so that, existing data is populated correctly
                // to the form.
                this.currentStep = index;
                this.updateForm({ cur, isFormDataFirstUpdate: true });
            }
        });
        this.currentStep = 0;
    }

    createAssItemGroup(assItem: EnrichedAssItem): FormGroup {
        const existingCurId = this.getExistingEnrolmentByAssItemId(assItem.id)?.courseUnitRealisationId;

        return this.fb.group({
            cur: this.fb.control(existingCurId ? existingCurId : null, required()),
            sgs: this.fb.array([]),
            enrolmentQuestionAnswers: this.fb.array([]),
        });
    }

    private getExistingEnrolmentByAssItemId(assItemId?: OtmId): EnrichedEnrolment {
        const id = assItemId ? assItemId : this.assessmentItemsData[this.currentStep].id;
        return this.openUniStudentEnrolments.find(enrolment => enrolment?.assessmentItemId === id);
    }

    createSGSGroup(curDataChangeObj: EmittedCurDataChangeObj) {
        const form = this.form.at(this.currentStep).get('sgs') as FormArray;
        form.clear();
        curDataChangeObj.cur.studyGroupSets.forEach(sgs => {
            const group = this.fb.group({
                [sgs.localId]: this.fb.array(this.createSSGControls(sgs, curDataChangeObj.isFormDataFirstUpdate),
                                             [
                                                 this.studyGroupSetHasCorrectAmountOfSSGSelections(sgs.subGroupRange),
                                             ]),
            });
            form.push(group);
        });
        form.updateValueAndValidity();
    }

    private createSSGControls(sgs: StudyGroupSet, isFormDataFirstUpdate: boolean): FormControl[] {
        // "max: 1" === create single value radio-button selection
        if (sgs.subGroupRange.min === 1 && sgs.subGroupRange.max === 1 && sgs.studySubGroups.length > 1) {
            if (isFormDataFirstUpdate) {
                const enrolment = this.getExistingEnrolmentByAssItemId();
                const ssgId = enrolment?.studySubGroups?.find(studySubGroup => sgs.studySubGroups.find(ssg => ssg.id === studySubGroup?.studySubGroupId))?.studySubGroupId;
                if (ssgId) {
                    return [new FormControl(ssgId)];
                }
            }
            return [new FormControl(null)];
        }

        return sgs.studySubGroups.map((ssg: StudySubGroup) => this.createSSGControl(sgs, ssg, isFormDataFirstUpdate));
    }

    private createSSGControl(sgs: StudyGroupSet, ssg: StudySubGroup, isFormDataFirstUpdate: boolean): FormControl {
        // Select groups automatically when required groups size is same as available groups
        if (sgs.studySubGroups.length === sgs.subGroupRange.min) {
            return new FormControl(ssg.id);
        }

        // Do not map form with pre-existing data after initialization.
        if (!isFormDataFirstUpdate) {
            return null;
        }

        const enrolment = this.getExistingEnrolmentByAssItemId();
        const ssgId = enrolment?.studySubGroups?.find(studySubGroup => studySubGroup?.studySubGroupId === ssg.id)?.studySubGroupId;
        if (ssgId) {
            return new FormControl(ssgId);
        }

        return new FormControl(null);
    }

    studyGroupSetHasCorrectAmountOfSSGSelections(subGroupRange: IntRange): ValidatorFn {
        return (formArray: FormArray): ValidationErrors | null => {
            const countOfValidItems = formArray.value.filter((i: any) => i !== null).length;

            if (
                countOfValidItems >= subGroupRange.min
                && (countOfValidItems <= subGroupRange.max || subGroupRange.max == null)
            ) {
                return null;
            }
            return {
                incorrectAmountOfSelections: {
                    translationKey: 'OPEN_UNIVERSITY.ENROLMENT_WIZARD.STUDY_SUB_GROUP_SELECTIONS_VALIDATION_ERROR',
                },
            };
        };
    }

    createEnrolmentQuestionAnswerGroup(curDataChangeObj: EmittedCurDataChangeObj) {
        const form = this.form.at(this.currentStep).get('enrolmentQuestionAnswers') as FormArray;
        form.clear();
        curDataChangeObj.cur.enrolmentQuestionnaire?.enrolmentQuestions.forEach(q => {
            const group = this.fb.group({
                [q.localId]: this.fb.control(this.populateEnrolmentQuestionAnswer(q, curDataChangeObj.isFormDataFirstUpdate),
                                             [maxLength(MaxLength.MAX_MEDIUM_STRING_LENGTH), requiredIf(() => q.required)]),
            });
            form.push(group);
        });
        form.updateValueAndValidity();
    }

    private populateEnrolmentQuestionAnswer(enrolmentQuestion: EnrolmentQuestion, isFormDataFirstUpdate: boolean): string {
        // Do not map form with pre-existing data after initialization.
        if (!isFormDataFirstUpdate) {
            return '';
        }

        const enrolment = this.getExistingEnrolmentByAssItemId();
        const qAnswer = enrolment?.enrolmentQuestionnaireAnswers?.answers?.find(answer => answer.questionId === enrolmentQuestion.localId);

        if (qAnswer) {
            if (enrolmentQuestion.questionType === 'LANGUAGE_SELECT') {
                // This is for some reason used only to store a single radiobutton selection (not multiple values as selections list indicates).
                return qAnswer.selections.toString();
            }
            if (enrolmentQuestion.questionType === 'FREE_TEXT') {
                return qAnswer.answerText;
            }
        }
        return '';
    }

    updateForm(curDataChangeObj: EmittedCurDataChangeObj) {
        this.createSGSGroup(curDataChangeObj);
        this.createEnrolmentQuestionAnswerGroup(curDataChangeObj);
    }

    findSelectedCompletionMethodWithOpenUniProduct(): CompletionMethod {
        return this.courseUnit.completionMethods.find(completionMethod => completionMethod.localId === this.openUniversityProduct.completionMethodId);
    }

    initWizardProgressStepKeys() {
        let i = 0;
        this.assessmentItemsData.forEach(aiData => {
            i += 1;
            this.wizardProgressStepKeys.push(`${this.localizedString.transform(aiData.name)} (${this.creditRangePipe.transform(aiData.credits as CreditRange)})`);
        });
        i += 1;
        this.wizardProgressStepKeys.push(this.translateService.instant('OPEN_UNIVERSITY.ENROLMENT_WIZARD.SELECT_TEACHING_CONFIRMATION'));
    }

    setCurrentStepName() {
        this.currentStepName = this.wizardProgressStepKeys[this.currentStep];
        this.titleService.setTitle(`${this.translateService.instant('OPEN_UNIVERSITY.ENROLMENT_WIZARD.SELECT_TEACHING')}, ${this.wizardProgressStepKeys[this.currentStep]} - ${this.translateService.instant('PAGE_TITLE_STUDENT')}`);
    }

    back() {
        this.currentStep -= 1;
        this.setCurrentStepName();
        this.currentStepNameFocus.nativeElement.focus();
    }

    forward() {
        if (this.isFinalStep()) {
            this.confirm();
        } else {
            this.continue();
        }
    }

    continue() {
        if (this.checkFormValidity()) {
            this.save();
        }
    }

    confirm() {
        const texts = {
            title: 'OPEN_UNIVERSITY.ENROLMENT_WIZARD.CONFIRM_MODAL.CONFIRM_TITLE',
            descriptions: ['OPEN_UNIVERSITY.ENROLMENT_WIZARD.CONFIRM_MODAL.CONFIRM_DESC'],
            confirmText: 'OPEN_UNIVERSITY.ENROLMENT_WIZARD.CONFIRM_MODAL.CONFIRM_BUTTON_MOVE_TO_CART',
            cancelText: 'OPEN_UNIVERSITY.ENROLMENT_WIZARD.CONFIRM_MODAL.CONFIRM_BUTTON_CONINUE_SEARCH',
        };
        this.confirmDialogService.confirm(texts)
            .then(() => {
                this.stateService.go('student.open-university-cart', {}, { custom: { skipConfirmationDialog: true } });
            }, () => {
                this.stateService.go('student.search.open-university', {}, { custom: { skipConfirmationDialog: true } });
            });
    }

    save() {
        this.saving = true;
        const enrolmentReservationRequest = this.parseEnrolmentReservationRequest();
        this.enrolmentStudentService.reserveEnrolment(enrolmentReservationRequest)
            .pipe(
                switchMap(enrolmentResponse => combineLatest([
                    of(enrolmentResponse),
                    from(convertAJSPromiseToNative(this.createEnrolmentQuestionAnswers(enrolmentResponse))),
                ])),
                map(([enrolmentRes, enrolmentQuestionnaireAnswers]) => ({ ...enrolmentRes, enrolmentQuestionnaireAnswers })),
                take(1),
                catchError(error => {
                    // HTTP 400 indicates that the enrolment was refused e.g. due to the CUR or some study sub-group being full
                    if (error.status === 400) {
                        this.alertService.error('OPEN_UNIVERSITY.ENROLMENT_WIZARD.ENROLMENT_FAILED');
                        return EMPTY;
                    }
                    throw error;
                }),
                this.appErrorHandler.defaultErrorHandler(),
            )
            .subscribe({
                next: (createdEnrolment: EnrichedEnrolment) => {
                    this.findAndReplaceOpenUniStudentEnrolmentWithSameAssItemId(createdEnrolment);
                    this.currentStep += 1;
                    this.setCurrentStepName();
                    this.currentStepNameFocus.nativeElement.focus();
                },
            })
            .add(() => this.saving = false);
    }

    forwardButtonTitle(): string {
        if (this.isFinalStep()) {
            return 'BUTTON.CONFIRM_SELECTIONS';
        }
        return 'BUTTON.CONTINUE';
    }

    private findAndReplaceOpenUniStudentEnrolmentWithSameAssItemId(enrolment: EnrichedEnrolment) {
        const index = this.openUniStudentEnrolments.findIndex(existingEnrolment => existingEnrolment.assessmentItemId === enrolment.assessmentItemId);
        if (index !== -1) {
            this.openUniStudentEnrolments[index] = enrolment;
        } else {
            this.openUniStudentEnrolments.push(enrolment);
        }
    }

    private createEnrolmentQuestionAnswers(enrolment: Enrolment): Promise<EnrolmentQuestionnaireAnswers> {
        const enrolmentQuestionnaireAnswers = this.parseEnrolmentQuestionnaireAnswers(enrolment);

        if (enrolmentQuestionnaireAnswers.answers.length === 0) {
            return Promise.resolve(null);
        }

        return this.enrolmentQuestionnaireService.updateOpenUniversityQuestionnaireAnswers(enrolmentQuestionnaireAnswers);
    }

    private parseEnrolmentReservationRequest(): EnrolmentReservationRequest {
        const assItemFromGroupData = this.form.at(this.currentStep).value;

        return {
            assessmentItemId: this.assessmentItemsData[this.currentStep].id,
            courseUnitId: this.courseUnit.id,
            courseUnitRealisationId: assItemFromGroupData.cur,
            educationId: this.openUniversityProduct.educationId,
            openUniversityCartId: this.openUniversityCart.id,
            openUniversityCartItemId: this.openUniversityCart.items.find(item => item.openUniversityProductId === this.openUniversityProduct.id).localId,
            selectionCriteria: 'Reserving for open university purchase',
            studySubGroups: this.parseEnrolmentStudySubGroups(assItemFromGroupData),
        };
    }

    private parseEnrolmentStudySubGroups(assItemFromGroupData: any): EnrolmentStudySubGroup[] {
        const listOfEnrolmentStudySubGroups: EnrolmentStudySubGroup[] = [];

        const studySubGroupIds: OtmId[] = assItemFromGroupData.sgs.flatMap((StudySubGroupIdsListOfStudyGroupSet: { [key: string]: string[] }) =>
            Object.values(StudySubGroupIdsListOfStudyGroupSet)[0]
                .filter(Boolean)
                .map(ssgIds => ssgIds));

        if (studySubGroupIds.length === 0) {
            return null;
        }

        studySubGroupIds.forEach(ssgId => {
            listOfEnrolmentStudySubGroups.push({
                enrolmentStudySubGroupPriority: 'PRIMARY',
                isInCalendar: true,
                studySubGroupId: ssgId,
            });
        });
        return listOfEnrolmentStudySubGroups;
    }

    private parseEnrolmentQuestionnaireAnswers(enrolment: Enrolment): EnrolmentQuestionnaireAnswers {
        const assItemFromGroupData = this.form.at(this.currentStep).value;

        return {
            answers: this.parseEnrolmentQuestionAnswers(assItemFromGroupData.enrolmentQuestionAnswers, assItemFromGroupData.cur),
            courseUnitRealisationId: assItemFromGroupData.cur,
            documentState: 'ACTIVE',
            enrolmentId: enrolment.id,
            metadata: this.getMetadataForEnrolmentQuestionnaireAnswers(enrolment.id),
            studentId: null,
        };
    }

    private getMetadataForEnrolmentQuestionnaireAnswers(enrolmentId: OtmId): EntityMetadata {
        return this.openUniStudentEnrolments.find(({ id }) => id === enrolmentId)?.enrolmentQuestionnaireAnswers?.metadata ?? null;
    }

    private parseEnrolmentQuestionAnswers(enrolmentQuestionAnswers: { [key: string]: string }[], curId: string): EnrolmentQuestionAnswer[] {
        const enrolmentQuestionAnswersList: EnrolmentQuestionAnswer[] = [];

        enrolmentQuestionAnswers.forEach(answerObj => {
            const answerKeyQuestionLocalId = Object.keys(answerObj)[0];
            const selectedCur = this.assessmentItemsData[this.currentStep].courseUnitRealisations.find(cur => cur?.id === curId);
            const enrolmentQuestion = selectedCur.enrolmentQuestionnaire.enrolmentQuestions.find(q => q.localId === answerKeyQuestionLocalId);

            if (enrolmentQuestion.questionType === 'FREE_TEXT') {
                enrolmentQuestionAnswersList.push({
                    answerText: Object.values(answerObj)[0],
                    questionId: answerKeyQuestionLocalId,
                    selections: [],
                });
            } else if (enrolmentQuestion.questionType === 'LANGUAGE_SELECT') {
                enrolmentQuestionAnswersList.push({
                    answerText: null,
                    questionId: answerKeyQuestionLocalId,
                    selections: [Object.values(answerObj)[0]],
                });
            }
        });

        return enrolmentQuestionAnswersList;
    }

    isFinalStep(): boolean {
        return this.currentStep === (this.wizardProgressStepKeys.length - 1);
    }

    checkFormValidity(): boolean {
        const assItemForm = this.form.at(this.currentStep) as FormGroup;
        const isValid = assItemForm.valid;
        const identifier = 'open-uni-enrolment-wizard-validation-err';

        if (!isValid) {
            assItemForm.markAllAsTouched();
            this.showErrors = true;
            this.initValidationErrorAlert(identifier);
        } else {
            this.showErrors = false;
            this.alertService.dismissAlertIfExists(identifier);
        }

        return isValid;
    }

    initValidationErrorAlert(identifier: string) {
        this.alertService.addAlert({
            message: this.translateService.instant('OPEN_UNIVERSITY.VALIDATION_ERROR_ALERT'),
            type: AlertType.DANGER,
            scrollToElement: 'error-box',
            identifier,
        });
    }

    private getEnrolmentAllocationCounts(assessmentItemsData: EnrichedAssItem[]): Observable<EnrolmentAllocationCounts[]> {
        const curIdList: OtmId[] = assessmentItemsData.flatMap(assItem => assItem.courseUnitRealisations.map(cur => cur.id));
        return this.enrolmentAllocationCountsEntityService.getOpenUniversityAllocationCountsMultiple(curIdList);
    }

    private countIfSSGsAreFull(enrolmentAllocationCountsList: EnrolmentAllocationCounts[]) {
        this.assessmentItemsData.forEach(assItem => assItem.courseUnitRealisations.forEach(cur => {
            const enrolmentAllocationCounts = enrolmentAllocationCountsList.find(enrolmentAllocationCount => enrolmentAllocationCount.courseUnitRealisationId === cur.id);
            cur.studyGroupSets?.forEach(sgs =>
                sgs.studySubGroups?.forEach(ssg =>
                    ssg.isFull = this.countIfSSGIsFull(
                        enrolmentAllocationCounts?.studySubGroupAllocationCounts?.find(ssgAllocationCount => ssgAllocationCount.studySubGroupId === ssg.id),
                    ),
                ),
            );
        }));
    }

    private countIfSSGIsFull(ssgAllocationCount: EnrolmentStudySubGroupAllocationCounts): boolean {
        if (!ssgAllocationCount) {
            return false;
        }
        // strict check with null and undefined because 0 == false --> true
        if (ssgAllocationCount.maxSelected === undefined || ssgAllocationCount.maxSelected === null) {
            return false;
        }
        const enrolledCount = ssgAllocationCount?.enrolledCount ?? 0;
        const reservedCount = ssgAllocationCount?.reservedCount ?? 0;
        return ssgAllocationCount.maxSelected <= (enrolledCount + reservedCount);
    }

    navigateBackInView(step: number) {
        this.currentStep = step;
        this.setCurrentStepName();
        this.currentStepNameFocus.nativeElement.focus();
    }
}

export interface EnrichedAssItem extends Partial<AssessmentItem> {
    courseUnitRealisations: EnrichedCur[];
}

export interface EnrichedCur extends Partial<CourseUnitRealisation> {
    enrolmentQuestionnaire: EnrolmentQuestionnaire;
    studyGroupSets: EnrichedStudyGroupSet[];
}

export interface EnrichedStudyGroupSet extends StudyGroupSet {
    studySubGroups: EnrichedStudySubGroup[];
}

export interface EnrichedStudySubGroup extends StudySubGroup {
    isFull?: boolean;
    studyEvents: StudyEvent[];
}

export interface EnrichedEnrolment extends Enrolment {
    enrolmentQuestionnaireAnswers?: EnrolmentQuestionnaireAnswers;
}
