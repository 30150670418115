import { Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { CourseUnit, CourseUnitRealisation, Enrolment, OtmId } from 'common-typescript/types';

/**
 * @deprecated
 */
// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'course-unit-realisation' })
export class CourseUnitRealisationDirective extends UpgradeComponent {

    @Input() courseUnitRealisation: CourseUnitRealisation;

    @Input() courseUnit: CourseUnit;

    @Input() colorIndex: number;

    @Input() enrolment: Enrolment;
    @Input() frontPageView: boolean;

    constructor(elementRef: ElementRef<any>, injector: Injector) {
        super('courseUnitRealisation', elementRef, injector);
    }
}
