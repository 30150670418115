import { Injectable } from '@angular/core';
import { NgbDate, NgbDateAdapter, NgbDatepicker, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { MOMENT_DATE_DISPLAY_FORMAT } from 'common-typescript/constants';
import moment from 'moment';

/**
 * Our custom date adapter for ng-bootstrap's {@link NgbDatepicker}.
 *
 * Converts Finnish date strings to {@link NgbDateStruct}s used by {@link NgbDatepicker}, and vice versa.
 *
 * Also accepts ISO-8601 local date time strings as inputs to fromModel(), but note that the time part will be lost.
 */
@Injectable({ providedIn: 'root' })
export class DateAdapter extends NgbDateAdapter<string> {

    fromModel(value: string): NgbDate {
        const date = moment(value, [MOMENT_DATE_DISPLAY_FORMAT, moment.ISO_8601], true);
        return date.isValid() ? new NgbDate(date.year(), date.month() + 1, date.date()) : undefined;
    }

    toModel(date: NgbDateStruct): string {
        if (!date) {
            return undefined;
        }

        const momentDate = moment({ year: date.year, month: date.month - 1, day: date.day });
        if (!momentDate.isValid()) {
            return undefined;
        }

        return momentDate.format(MOMENT_DATE_DISPLAY_FORMAT);
    }
}
