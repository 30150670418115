<div class="container-fluid">
  <div class="row">
    <div class="col-12 col-lg-9">
      <sis-breadcrumbs></sis-breadcrumbs>
      <sis-page-title [dataCy]="'course-unit-info-heading'"
                      [title]="(courseUnit.name|localizedString)+' ('+(courseUnit.credits|creditRange:'TEXT')+')'"
                      [subTitle]="courseUnit.code"
                      [showCrumbs]="true">
        <ng-container page-title="badges">
          <sis-tiny-badge *ngIf="courseUnit.documentState === 'DRAFT' || courseUnit.documentState === 'DELETED'"
                          [variant]="courseUnit.documentState==='DELETED' ? 'danger' : 'accent'">
            {{ courseUnit.documentState | translate}}
          </sis-tiny-badge>
        </ng-container>
      </sis-page-title>
    </div>
    <div class="col-12 col-lg-3">
      <div *ngIf="isLoggedIn" class="float-end pt-lg-5 mt-lg-5 mb-3 mb-lg-0">
          <button type="button" class="btn btn-primary btn-naked btn-sm" *ngIf="!inCart"
                  (click)="addCourseUnitToCourseCart()"
                  data-cy="add-to-course-cart-brochure"
                  [attr.aria-label]="('ARIA_LABEL.ADD_TO_CART' | translate) + ' ' + (courseUnit.name | plainText)">
            <sis-icon aria-hidden="true" icon="list-add"></sis-icon>
            <span>{{ 'ADD_TO_CART' | translate }}</span>
          </button>
          <button type="button" class="btn btn-secondary btn-naked btn-sm" *ngIf="inCart"
                  (click)="removeCourseUnitFromCourseCart()"
                  data-cy="remove-from-course-cart-brochure"
                  [attr.aria-label]="('ARIA_LABEL.REMOVE_FROM_CART' | translate) + ' ' + (courseUnit.name | plainText)">
            <sis-icon aria-hidden="true" icon="list-minus"></sis-icon>
            <span>{{ 'REMOVE_FROM_CART' | translate }}</span>
          </button>
        </div>
    </div>
  </div>
  <div class="row">
    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
      <sis-version-selector
        [currentVersionId]="courseUnit.id"
        [options]="options"
        [popoverInfoKey]="'STUDIES.COURSE_UNIT_INFO_MODAL.VERSION_POPOVER_INFO'"
        [popoverLabelKey]="'STUDIES.COURSE_UNIT_INFO_MODAL.COURSE_VERSION'"
        (change)="goToCourseUnitVersion($event)">
      </sis-version-selector>
    </div>
  </div>
</div>
