<div class="checkbox-wrapper">
  <sis-checkbox [checked]="checked" (checkedChange)="checkedChangeHandler()" [label]="'PLAN.RIPA_CHECKBOX_LABEL' | translate">
  </sis-checkbox>
  <sis-tooltip [tooltipText]="'PLAN.RIPA_FILTER_GUIDANCE' | translate" [tooltipBlockElement]="true" [appendToBody]="true">
    <div class="sis-tooltip-trigger-content">
      <sis-icon [icon]="'info-circle'" [color]="'white'"></sis-icon>
    </div>
  </sis-tooltip>

</div>


