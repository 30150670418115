import {
    ChangeDetectionStrategy,
    Component,
    Input,
    ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
    Enrolment,
    StudySubGroup,
} from 'common-typescript/types';

import { Allocation } from '../../enrolmentWizardUtils';

@Component({
    selector: 'app-study-sub-group-container',
    templateUrl: './study-sub-group-container.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudySubGroupContainerComponent {
    @Input() control: FormGroup;
    @Input() studySubGroup: StudySubGroup;
    @Input() isConfirmedSsgEdit?: boolean = false;
    @Input() isInCalendar = false;
    @Input() isMandatory?: boolean = false;
    @Input() enrolment: Enrolment;
    @Input() allocation: Allocation;

    get isPrimary(): FormControl {
        return this.control?.get('isPrimary') as FormControl;
    }

    get isSelected(): FormControl {
        return this.control?.get('isSelected') as FormControl;
    }
}
