'use strict';

(function () {
  planLoaderFactory.$inject = ["$q", "moduleLoader", "courseUnitLoader", "assessmentItemLoader", "curriculumPeriodService"];
  angular.module('student.common.context.plan-context').factory('planLoader', planLoaderFactory);
  function planLoaderFactory($q, moduleLoader, courseUnitLoader, assessmentItemLoader, curriculumPeriodService) {
    return {
      loadStudyReferenceData: function (plan, attainmentContext, idMap) {
        return $q.all([moduleLoader.loadModules(plan.rootId, attainmentContext.getModuleAttainments(), plan.moduleSelections, idMap.modules), courseUnitLoader.loadCourseUnits(attainmentContext.getCourseUnitAttainments(), plan.courseUnitSelections, idMap.courseUnits), assessmentItemLoader.loadAssessmentItems(attainmentContext.getAssessmentItemAttainments(), plan.assessmentItemSelections, idMap.assessmentItems), curriculumPeriodService.loadCurriculumPeriodsStartingFrom(plan.curriculumPeriodId)]).then(function (resolved) {
          return {
            modules: resolved[0],
            courseUnits: resolved[1],
            assessmentItems: resolved[2],
            curriculumPeriods: resolved[3]
          };
        }, function (error) {
          error.titleKey = "ERROR.PLAN_REFERENCE_DATA_NOT_FOUND";
          throw error;
        });
      }
    };
  }
})();