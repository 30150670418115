export const preferredLanguageModelModule = 'sis-components.model.preferredLanguage';
'use strict';
(function () {
  preferredLanguageJSDataModel.$inject = ["DS", "$http"];
  angular.module(preferredLanguageModelModule, ['js-data']).factory('preferredLanguageJSDataModel', preferredLanguageJSDataModel);
  function preferredLanguageJSDataModel(DS, $http) {
    var model = DS.defineResource({
      idAttribute: 'urn',
      name: 'preferredLanguage',
      endpoint: '/kori/api/cached/codebooks/urn:code:preferred-language'
    });
    model.lazyGetAll = _.once(function () {
      return $http.get(model.endpoint).then(function (response) {
        model.inject(_.values(response.data));
        return model.getAll();
      });
    });
    return model;
  }
})();