import {
    ChangeDetectionStrategy,
    Component, DestroyRef,
    Inject,
    inject,
    Input,
    OnInit,
    Optional,
    ViewEncapsulation,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FudisDialogService } from '@funidata/ngx-fudis';
import { PlanValidationTs, ValidatablePlan } from 'common-typescript';
import {
    Attainment,
    CourseUnit, CurriculumPeriod,
    DegreeProgramme,
    GroupingModule,
    Module,
    StudyModule,
} from 'common-typescript/types';
import _ from 'lodash';
import {
    combineLatestWith,
    exhaustMap, from, map,
    merge,
    mergeMap,
    Observable,
    of, scan, shareReplay,
    Subject, switchMap, take, tap,
    withLatestFrom,
} from 'rxjs';
import { DEFAULT_PROMISE_HANDLER } from 'sis-common/ajs-upgraded-modules';
import { CURRICULUM_PERIOD_SERVICE, PLAN_STUDY_RIGHT_SERVICE } from 'sis-components/ajs-upgraded-modules';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import {
    PLAN_ACTIONS_SERVICE_INJECTION_TOKEN,
    PlanActionsService,
} from 'sis-components/plan/plan-actions-service/plan-actions.service';
import { PlanManager } from 'sis-components/plan/plan-manager/plan-manager.service';
import { GradeScaleEntityService } from 'sis-components/service/grade-scale-entity.service';
import { PlanEntityService } from 'sis-components/service/plan-entity.service';
import { PlanData, PlanStateObject, PlanStateService } from 'sis-components/service/plan-state.service';
import { RawPlanEditService } from 'sis-components/service/raw-plan-edit.service';
import { convertAJSPromiseToNative } from 'sis-components/util/utils';

import { PlanStructureEditModalValues } from '../plan-structure-edit-modal.component';

interface FreeEditPlanData {
    planData: PlanData;
    allModules: Module[];
    searchCurriculumPeriods: CurriculumPeriod[];
    allCourseUnits: CourseUnit[];
    planStateObject: PlanStateObject;
    validatablePlan: ValidatablePlan;
}

@Component({
    selector: 'app-plan-structure-free-edit',
    templateUrl: './plan-structure-free-edit.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class PlanStructureFreeEditComponent implements OnInit {
    private dialogService = inject(FudisDialogService);
    @Input() modalValues: PlanStructureEditModalValues;

    submitClick$: Subject<void> = new Subject();

    data$: Observable<FreeEditPlanData>;

    unattachedAttainments: Attainment[] = [];
    isPlanRootModule: boolean;

    constructor(
        private planEntityService: PlanEntityService,
        private destroyRef: DestroyRef,
        private planManager: PlanManager,
        private planStateService: PlanStateService,
        private gradeScaleEntityService: GradeScaleEntityService,
        private rawPlanEditService: RawPlanEditService,
        private appErrorHandler: AppErrorHandler,
        @Inject(CURRICULUM_PERIOD_SERVICE) private curriculumPeriodService: any,
        @Inject(PLAN_STUDY_RIGHT_SERVICE) private planStudyRightService: any,
        @Inject(DEFAULT_PROMISE_HANDLER) private defaultPromiseHandler: any,
        @Optional() @Inject(PLAN_ACTIONS_SERVICE_INJECTION_TOKEN) private planActionsService: PlanActionsService) {}

    ngOnInit(): void {
        this.planManager.setValidatablePlan(_.cloneDeep(this.modalValues.validatablePlan));
        this.data$ = this.createDataObservable();
        this.createPlanOperationSubjectSubscription();
        this.createSubmitClickSubscription();
        this.unattachedAttainments = this.rawPlanEditService.getAllUnattachedAttainments(this.getAllAttainments());
        this.isPlanRootModule = _.get(this.modalValues.module, 'id') === this.modalValues.education.id;
    }

    /**
     * Subscribes to planOperationSubject and processes the operations by passing them directly to planManager.
     */
    createPlanOperationSubjectSubscription(): void {
        this.planActionsService.planOperationSubject.pipe(
            takeUntilDestroyed(this.destroyRef),
            mergeMap((operation) => this.planManager.processPlanOperation(operation)),
        ).subscribe();
    }

    createDataObservable(): Observable<FreeEditPlanData> {
        // First emitted value will be the initial validatable plan, after that,
        // emit values from planManager.validatablePlanSubject
        const validatablePlan$ = merge(of(this.modalValues.validatablePlan),
                                       this.planManager.validatablePlanSubject).pipe(shareReplay({ refCount: true, bufferSize: 1 }));

        // Accumulate all course units that have been selected during this edit session
        const allCourseUnits$ = validatablePlan$.pipe(
            scan((acc: CourseUnit[], validatablePlan: ValidatablePlan) => [...new Set([
                ...acc,
                ...validatablePlan.getSelectedCourseUnitsUnderModule(this.modalValues.module),
            ])] as CourseUnit[], []),
        );

        // Accumulate all modules that have been selected during this edit session
        const allModules$ = validatablePlan$.pipe(
            scan((acc: Module[], validatablePlan: ValidatablePlan) => [...new Set([
                ...acc,
                ...validatablePlan.getSelectedModulesUnderModule(this.modalValues.module),
            ])] as Module[], []),
        );
        const gradeScalesById$ = validatablePlan$.pipe(
            switchMap((validatablePlan) => this.createGradeScalesByIdObservable(validatablePlan)),
        );

        const searchCurriculumPeriods$ =
            from(convertAJSPromiseToNative(
                this.curriculumPeriodService.getCurriculumPeriodsForSearch(this.modalValues.validatablePlan.plan.curriculumPeriodId))
                .catch(this.defaultPromiseHandler.loggingRejectedPromiseHandler),
            ) as Observable<CurriculumPeriod[]>;

        return validatablePlan$.pipe(combineLatestWith(allModules$, allCourseUnits$, gradeScalesById$, searchCurriculumPeriods$),
                                     map(([
                                         validatablePlan,
                                         allModules,
                                         allCourseUnits,
                                         gradeScalesById,
                                         searchCurriculumPeriods]) => {
                                         const planValidationResult = PlanValidationTs.validatePlan(validatablePlan);
                                         const educationOptions = this.planStudyRightService.getValidatedEducationOptions(
                                             validatablePlan, this.modalValues.education,
                                             this.modalValues.validatablePlanStudyRight);
                                         const planStateAndData = this.planStateService.getPlanStateAndData(
                                             this.modalValues.education,
                                             validatablePlan,
                                             planValidationResult,
                                             educationOptions,
                                             gradeScalesById,
                                             this.modalValues.validatablePlanStudyRight);

                                         return {
                                             validatablePlan,
                                             allModules,
                                             allCourseUnits,
                                             searchCurriculumPeriods,
                                             planData: planStateAndData.planData,
                                             planStateObject: planStateAndData.planStateObject,
                                         };
                                     }));
    }

    /**
     * Gathers all grade scale ids from plan attainments and returns
     * them in an object with the grade scale id as the key.
     *
     * @param validatablePlan Current validatable plan.
     */
    createGradeScalesByIdObservable(validatablePlan: ValidatablePlan): Observable<{ [id: string]: any }> {
        return this.gradeScaleEntityService.getByIds(_.chain(_.values(validatablePlan.getAllAttainments()))
            .map('gradeScaleId')
            .concat('sis-0-5')
            .compact()
            .uniq()
            .value())
            .pipe(
                map((gradeScales) => _.keyBy(gradeScales, 'id')),
            );
    }

    createSubmitClickSubscription() {
        this.submitClick$.pipe(
            withLatestFrom(this.data$),
            exhaustMap(
                ([, data]) => this.planEntityService.updateMyPlan(data.validatablePlan.plan).pipe(
                    take(1),
                    this.appErrorHandler.defaultErrorHandler(),
                    tap(() => this.dialogService.closeAll()),
                ),
            ))
            .subscribe();
    }

    dismiss() {
        this.dialogService.close();
    }

    handleCourseUnitToggle(toggleData: { courseUnit: CourseUnit, isInPlan: boolean }): void {
        const { courseUnit, isInPlan } = toggleData;
        if (isInPlan) {
            this.planActionsService.forceRemoveCourseUnit(courseUnit, this.modalValues.module);
        } else {
            this.planActionsService.forceSelectCourseUnitById(courseUnit.id, this.modalValues.module);
        }
    }

    selectedCourseUnit(courseUnit: CourseUnit): void {
        const attainedVersionId = this.rawPlanEditService.findAttainedVersionIdForCourseUnit(courseUnit.groupId, this.unattachedAttainments);
        const versionIdToBeSelected = attainedVersionId || courseUnit.id;
        this.planActionsService.forceSelectCourseUnitById(versionIdToBeSelected, this.modalValues.module);
    }

    selectedModule(module: GroupingModule | StudyModule | DegreeProgramme): void {
        const attainedVersionId = this.rawPlanEditService.findAttainedVersionIdForModule(module.groupId, this.unattachedAttainments);
        const versionIdToBeSelected = attainedVersionId || module.id;
        this.planActionsService.forceSelectModuleById(versionIdToBeSelected, this.modalValues.module);
    }

    handleModuleToggle(toggleData: {
        module: (GroupingModule | StudyModule | DegreeProgramme);
        isInPlan: boolean;
    }): void {
        const { module, isInPlan } = toggleData;
        if (isInPlan) {
            this.planActionsService.forceRemoveModule(module, this.modalValues.module);
        } else {
            this.planActionsService.forceSelectModuleById(module.id, this.modalValues.module);
        }
    }

    getAllAttainments(): Attainment[] {
        return _.values(this.modalValues.validatablePlan.getAllAttainments());
    }
}
