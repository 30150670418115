'use strict';

(function () {
  planStateFactory.$inject = ["defineEnum", "RangeValidationResult"];
  angular.module('student.shared').factory('PlanState', planStateFactory);
  function planStateFactory(defineEnum, RangeValidationResult) {
    var PlanState = defineEnum("INVALID", "INCOMPLETE", "PLANNED", "APPROVED", "APPROVED_CONDITIONALLY", "APPROVAL_REQUESTED", "APPROVAL_REQUESTED_PARTS_ATTAINED", "APPROVED_CONDITIONALLY_PARTS_ATTAINED", "APPROVED_PARTS_ATTAINED", "PARTS_ATTAINED", "ATTAINED", "IMPLICIT", "EMPTY");
    PlanState.higherPriorityOf = function (state1, state2) {
      if (!state1) {
        return state2;
      }
      if (!state2) {
        return state1;
      }
      return PlanState.compare(state1, state2) <= 0 ? state1 : state2;
    };
    PlanState.fromRangeValidationResult = function (rangeValidationResult, successState) {
      if (rangeValidationResult.result) {
        rangeValidationResult = rangeValidationResult.result;
      }
      switch (rangeValidationResult) {
        case RangeValidationResult.IMPLICIT:
          return PlanState.IMPLICIT;
        case RangeValidationResult.MORE_REQUIRED:
          return PlanState.INCOMPLETE;
        case RangeValidationResult.LESS_REQUIRED:
          return PlanState.INVALID;
        case RangeValidationResult.IMPLICIT_OK:
          return successState;
        case RangeValidationResult.OK:
          return successState;
        default:
          throw "Unrecognized Range.ValidationResult: " + rangeValidationResult;
      }
    };
    return PlanState;
  }
})();