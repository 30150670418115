<div class="enrolment-state" data-cy="enrolment-state-text">
  <sis-icon icon="check" *ngIf="enrolment.state === 'ENROLLED'"></sis-icon>
  <ng-container *ngIf="enrolment.state !== 'NOT_ENROLLED'">
    <ng-container *ngIf="enrolment.state === 'ENROLLED' && (courseUnitRealisation.activityPeriod|dateRangeOngoing); then ongoingState; else otherStates"></ng-container>
  </ng-container>
</div>

<ng-template #ongoingState>
  <span>{{'COURSE_UNIT_REALISATION.ENROLMENT_STATE_FOR_VIEW.ENROLLED_IN_PROGRESS'|translate}}</span>
</ng-template>

<ng-template #otherStates>
  <span>{{'COURSE_UNIT_REALISATION.ENROLMENT_STATE_FOR_VIEW.' + enrolment.state | translate}}</span>
</ng-template>
