export const applicationDecisionModelModule = 'sis-components.model.applicationDecision';
'use strict';
(function () {
  applicationDecisionJSDataModel.$inject = ["DS", "applicationDecisionEndpoint"];
  angular.module(applicationDecisionModelModule, ['js-data', 'sis-components.model.attainment', 'sis-components.model.publicPerson', 'sis-components.model.studentApplication']).factory('applicationDecisionJSDataModel', applicationDecisionJSDataModel).constant('applicationDecisionEndpoint', {
    DEFAULT: '/decision',
    BY_ATTAINMENT_ID: '/decisions/attainment'
  }).constant('RECOMMENDATION_STATE', {
    NOT_HANDLED: 'NOT_HANDLED',
    RECOMMENDED: 'RECOMMENDED',
    NOT_RECOMMENDED: 'NOT_RECOMMENDED'
  }).constant('APPLICATION_DECISION_TYPE', {
    ATTAINMENT_APPLICATION_DECISION: "AttainmentApplicationDecision",
    MODULE_CONTENT_APPLICATION_DECISION: "ModuleContentApplicationDecision",
    STUDY_RIGHT_EXTENSION_APPLICATION_DECISION: 'StudyRightExtensionApplicationDecision'
  }).constant('ADMINISTRATIVE_REVIEW_RESULT', {
    REQUIREMENTS_MET: 'REQUIREMENTS_MET',
    REQUIREMENTS_NOT_MET: 'REQUIREMENTS_NOT_MET',
    REVIEW_NOT_PERFORMED: 'REVIEW_NOT_PERFORMED'
  }).constant('PROPOSAL_RESULT', {
    APPROVE: 'APPROVE',
    REJECT: 'REJECT'
  }).run(["applicationDecisionJSDataModel", function (applicationDecisionJSDataModel) {}]); //NOSONAR

  /**
   * @ngInject
   */
  function applicationDecisionJSDataModel(DS, applicationDecisionEndpoint) {
    return DS.defineResource({
      idAttribute: 'id',
      name: 'applicationDecision',
      endpoint: applicationDecisionEndpoint.DEFAULT,
      relations: {
        belongsTo: {
          studentApplication: {
            localField: 'application',
            localKey: 'applicationId',
            parent: true
          }
        },
        hasOne: {
          publicPerson: [{
            localField: 'approver',
            localKey: 'approvedBy'
          }, {
            localField: 'formalAssessor',
            localKey: 'formalRecommendation.responsiblePerson'
          }, {
            localField: 'contentAssessor',
            localKey: 'contentRecommendation.responsiblePerson'
          }, {
            localField: 'administrativeReviewer',
            localKey: 'administrativeReview.reviewerId'
          }, {
            localField: 'presenter',
            localKey: 'proposal.presenterId'
          }]
        }
      }
    });
  }
})();