export const privatePersonInfoModelModule = 'sis-components.model.privatePersonInfo';
(function () {
  privatePersonInfoJSDataModel.$inject = ["DS", "personNameService"];
  angular.module(privatePersonInfoModelModule, ['js-data', 'sis-components.service.personNameService']).constant('PrivatePersonEndpoint', {
    SEARCH_STUDENTS: '/ori/api/students',
    SEARCH_STUDENTS_BY_PERSONAL_IDENTITY_CODE: '/ori/api/students/by-personal-identity-code',
    SEARCH_STUDENTS_FOR_CUR: '/ori/api/students/course-unit-realisation',
    SEARCH_EMPLOYEES: '/ori/api/employees'
  }).factory('privatePersonInfoJSDataModel', privatePersonInfoJSDataModel);

  /**
   * @ngInject
   */
  function privatePersonInfoJSDataModel(DS, personNameService) {
    return DS.defineResource({
      idAttribute: 'id',
      name: 'privatePersonInfo',
      endpoint: '/ori/api/person-infos',
      computed: {
        fullName: ['firstName', 'firstNames', 'lastName', function (firstName, firstNames, lastName) {
          var personObject = {
            firstName: firstName,
            firstNames: firstNames,
            lastName: lastName
          };
          return personNameService.getFullName(personObject);
        }],
        fullNameReversed: ['firstName', 'firstNames', 'lastName', function (firstName, firstNames, lastName) {
          var personObject = {
            firstName: firstName,
            firstNames: firstNames,
            lastName: lastName
          };
          return personNameService.getFullNameReversed(personObject);
        }]
      }
    });
  }
})();