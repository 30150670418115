import { ChangeDetectionStrategy, Component, inject, Input, TrackByFunction, ViewEncapsulation } from '@angular/core';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { ServiceStatus, StatusService } from './status.service';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-about',
    templateUrl: './about.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [StatusService],
})
export class AboutComponent {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sisComponents.about',
        directiveName: 'sisAbout',
    };

    @Input() version: any;

    readonly serviceStatus$ = inject(StatusService).getServiceStatus();
    readonly serviceName: TrackByFunction<ServiceStatus> = (i, status) => status?.name ?? i;
}
