import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OtmId } from 'common-typescript/types';
import { ModalService } from 'sis-common/modal/modal.service';

export interface SelectEducationValues {
    studyRightId?: OtmId;
}

@Component({
    selector: 'app-select-education-modal',
    template: '<select-education-modal (close)="activeModal.close($event.$value)" (dismiss)="activeModal.dismiss()" [resolve]="values"></select-education-modal>',
    encapsulation: ViewEncapsulation.None,
})
export class SelectEducationModalComponent {
    constructor(@Inject(ModalService.injectionToken) public values: SelectEducationValues,
                public activeModal: NgbActiveModal) {}
}
