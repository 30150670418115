<div class="expandable expandable__variant__{{variant}}" [class.closed]="_closed" data-cy="expandable">
  <div class="expandable__header expandable__header__variant__{{variant}}" [class.closed]="_closed" [class.expandable__header__slim]="slim">
    <div role="heading" [attr.aria-level]="level" class="expandable__header__heading">
      <button type="button"
              class="expandable__title expandable__title__variant__{{variant}} expandable__title__size__{{_size}}"
              [attr.id]="elementId"
              [class.link-button]="link"
              [class.closed]="_closed"
              [attr.data-cy]="'expandable-toggle'"
              [attr.aria-expanded]="!_closed"
              (click)="setClosedStatus(!_closed)">
        <sis-icon class="expandable__title__icon" [icon]="naked || link ? 'chevron' : 'chevron-ring-fill'" [iconClass]="_closed ? null : 'cw-90'"></sis-icon>
        <span class="expandable__title__container">
          <span *ngIf="title" class="expandable__title__text">{{title}}</span>
          <ng-container *ngIf="!link">
          <ng-container *ngTemplateOutlet="customTitleTemplate"></ng-container>
          <span *ngIf="!!subTitle && !slim" class="expandable__title__sub-title expandable__title__sub-title__size__{{_size}}">{{subTitle}}</span>
        </ng-container>
        </span>
      </button>
    </div>
    <div class=expandable__header__buttons>
      <ng-container *ngTemplateOutlet="headerButtonsTemplate"></ng-container>
    </div>
  </div>
  <div *ngIf="_openedOnce"
       class="expandable__content expandable__content__padding__{{_contentPadding}}"
       [class.expandable__content__dotted]="dotted"
       [class.expandable__content__transparent]="transparent"
       [attr.aria-hidden]="_closed"
       [hidden]="_closed">
    <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
  </div>
</div>
