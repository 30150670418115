import { Pipe, PipeTransform } from '@angular/core';

import { LocaleService } from './locale.service';

@Pipe({ name: 'localizedStringExistingLocales' })
export class LocalizedStringExistingLocalesPipe implements PipeTransform {

    constructor(private localeService: LocaleService) { }

    transform(value: any): string[] {
        return this.localeService.getOfficialLanguages().filter(language => !!value?.[language]);
    }
}
