import { Inject, Pipe, PipeTransform } from '@angular/core';
import { LocalDateRange, StudyRightExtension } from 'common-typescript/types';

import { COMMON_STUDY_RIGHT_SERVICE } from '../ajs-upgraded-modules';

@Pipe({ name: 'studyRightExtensionValidity' })
export class StudyRightExtensionValidityPipe implements PipeTransform {

    constructor(@Inject(COMMON_STUDY_RIGHT_SERVICE) private commonStudyRightService: any) {
    }

    transform(value: StudyRightExtension): LocalDateRange {
        return this.commonStudyRightService.getStudyRightExtensionValidity(value);
    }

}
