import angular from 'angular';
export const educationalInstitutionModelModule = 'sis-components.model.educationalInstitution';
(function () {
  educationalInstitutionJSDataModel.$inject = ["DS", "$http"];
  angular.module(educationalInstitutionModelModule, ['js-data']).factory('educationalInstitutionJSDataModel', educationalInstitutionJSDataModel);
  function educationalInstitutionJSDataModel(DS, $http) {
    var model = DS.defineResource({
      idAttribute: 'urn',
      name: 'educationalInstitution',
      endpoint: '/kori/api/cached/codebooks/urn:code:educational-institution'
    });
    model.lazyGetAll = _.once(function () {
      return $http.get(model.endpoint).then(function (response) {
        model.inject(_.values(response.data));
        return model.getAll();
      });
    });
    return model;
  }
})();