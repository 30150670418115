<ng-container *ngIf="options">
  <ng-container *ngIf="options.length === 1 && !options[0].disabled; else selectEnrolmentRight">
    <sis-description-list>
      <dl>
        <ng-template #listItem>
          <dt>{{'ENTITY_NAMES.EnrolmentRight' | translate}}</dt>
          <dd>{{options[0].label}} <ng-container *ngIf="options[0].info">({{options[0].info}})</ng-container></dd>
        </ng-template>
      </dl>
    </sis-description-list>
  </ng-container>
  <ng-template #selectEnrolmentRight>
    <label [for]="id" [class]="getLabelState(control)">
      {{'ENTITY_NAMES.EnrolmentRight' | translate}}
    </label>
    <sis-dropdown-select [options]="options"
                         [control]="control"
                         [id]="id">
    </sis-dropdown-select>
  </ng-template>
</ng-container>
