import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CROSS_STUDY_FEATURES_ENABLED_FOR_STUDENT_DEFAULT, OPEN_UNIVERSITY_FEATURES_ENABLED_FOR_STUDENT_DEFAULT } from 'common-typescript/constants';
import { UniversitySettings } from 'common-typescript/types';
import { get } from 'lodash';
import { firstValueFrom } from 'rxjs';
import { UniversityService } from 'sis-components/service/university.service';
import { Tab } from 'sis-components/tabs/tab-navigation/tab-navigation.component';

@Component({
    selector: 'app-search-tabs',
    templateUrl: './search-tabs.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class SearchTabsComponent implements OnInit {

    viewLoaded = false;

    tabs: Tab[] = [
        { path: 'student.search.main', title: 'SEARCH_UNIVERSITY_OFFERING' },
        { path: 'student.search.open-university', title: 'SEARCH_OPEN_UNIVERSITY_OFFERING' },
        { path: 'student.search.cross-study', title: 'SEARCH_CROSS_STUDY_OFFERING' },
    ];

    constructor(private universityService: UniversityService) {
    }

    ngOnInit(): void {
        firstValueFrom(this.universityService.getCurrentUniversitySettings())
            .then((settings: UniversitySettings) => {
                const openUniversityFeaturesEnabledForStudent = get(
                    settings,
                    'frontendFeatureToggles.openUniversityFeaturesEnabledForStudent',
                    OPEN_UNIVERSITY_FEATURES_ENABLED_FOR_STUDENT_DEFAULT,
                );
                if (!openUniversityFeaturesEnabledForStudent) {
                    this.tabs = this.tabs.filter(item => item.path !== 'student.search.open-university');
                }
                const crossStudyFeaturesEnabledForStudent = get(
                    settings,
                    'frontendFeatureToggles.crossStudyFeaturesEnabledForStudent',
                    CROSS_STUDY_FEATURES_ENABLED_FOR_STUDENT_DEFAULT,
                );
                if (!crossStudyFeaturesEnabledForStudent) {
                    this.tabs = this.tabs.filter(item => item.path !== 'student.search.cross-study');
                }
                this.viewLoaded = true;
            });
    }
}

