import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { HttpConfig, NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import {
    EnrolmentCalculationConfig,
    OpenUniversityProductSeats,
    OtmId,
} from 'common-typescript/types';
import _ from 'lodash';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, first, map, switchMap, tap } from 'rxjs/operators';

import { EntityService } from './entity.service';
import { SisuDataLoader } from './SisuDataLoader';

const CONFIG = {
    ENDPOINTS: {
        backend: '/ilmo/api',

        get getEnrolmentCalculationConfigs() {
            return `${this.backend}/enrolment-calculation-configs`;
        },
        get getByOpenUniversityProductId() {
            return `${this.backend}/enrolment-calculation-configs/by-open-university-product-id`;
        },
        get getOpenUniversityProductSeats() {
            return `${this.backend}/open-university-product-seats`;
        },
        forEnrolmentCalculationState(courseUnitRealisationId: OtmId) {
            return `${this.backend}/enrolment-calculation-results/state/${courseUnitRealisationId}`;
        },
        getEnrolmentCalculationConfig(courseUnitRealisationId: OtmId) {
            return `${this.backend}/enrolment-calculation-configs/${courseUnitRealisationId}`;
        },
    },
};

@Injectable({
    providedIn: 'root',
})
@NgEntityServiceConfig({
    baseUrl: CONFIG.ENDPOINTS.backend,
    resourceName: 'enrolment-calculation-configs',
})
export class EnrolmentCalculationConfigEntityService extends EntityService<EnrolmentCalculationConfigState> {

    constructor() {
        super(EnrolmentCalculationConfigStore, EnrolmentCalculationConfigQuery);
        this.courseUnitRealisationIdDataloader = new SisuDataLoader<OtmId, EnrolmentCalculationConfig, EnrolmentCalculationConfig>(
            {
                getByIdsCall: courseUnitRealisationIds => this.createGetByCourseUnitRealisationIdsCall(courseUnitRealisationIds),
                resultExtractor: (id, entities) => entities.find(entity => entity.id === id),
            },
        );
    }

    public readonly courseUnitRealisationIdDataloader: SisuDataLoader<OtmId, EnrolmentCalculationConfig, EnrolmentCalculationConfig>;

    getOpenUniversityProductSeats(id: OtmId): Observable<OpenUniversityProductSeats>;
    getOpenUniversityProductSeats(ids: OtmId[]): Observable<OpenUniversityProductSeats[]>;
    getOpenUniversityProductSeats(ids: OtmId | OtmId[]): Observable<OpenUniversityProductSeats | OpenUniversityProductSeats[]> {
        const shouldReturnArray = Array.isArray(ids);
        const uniqueIds = _.uniq(Array.isArray(ids) ? ids : [ids]).filter(Boolean);
        if (uniqueIds.length === 0) {
            return of(shouldReturnArray ? [] : null);
        }
        return this.getHttp().get<OpenUniversityProductSeats[]>(
            CONFIG.ENDPOINTS.getOpenUniversityProductSeats,
            { params: { openUniversityProductId: uniqueIds } },
        )
            .pipe(map(seats => shouldReturnArray ? seats : seats?.[0]));
    }

    /**
     * This overrides EntityService::getById
     * It uses courseUnitRealisationIdDataloader instead of the default dataloader.
     */
    getById(id: OtmId, bypassStore: boolean = false, config: HttpConfig = {}): Observable<EnrolmentCalculationConfig> {
        if (!id) {
            return throwError(() => new Error('The id was missing!'));
        }

        return this.courseUnitRealisationIdDataloader.load(id);
    }

    /**
     * Overrides EntityService::getByIds. If a config object is not found for one or more ids, no exception will be thrown,
     * but the returned array will simply not contain a matching config object.
     */
    getByIds(ids: OtmId[], bypassStore: boolean = false, config: HttpConfig = {}): Observable<EnrolmentCalculationConfig[]> {
        const filteredIds = _.uniq(ids ?? []).filter(Boolean);
        if (filteredIds.length === 0) {
            return of([]);
        }
        return combineLatest(filteredIds.map(id => this.getById(id, bypassStore, config)))
            .pipe(map(configs => configs?.filter(Boolean) ?? []));
    }

    getByOpenUniversityProductIds(openUniversityProductIds: OtmId[]): Observable<EnrolmentCalculationConfig[]> {
        const filteredIds = _.uniq(openUniversityProductIds ?? []).filter(Boolean);
        if (filteredIds.length === 0) {
            return of([]);
        }
        return this.getHttp().get<EnrolmentCalculationConfig[]>(
            CONFIG.ENDPOINTS.getByOpenUniversityProductId,
            { params: { openUniversityProductId: filteredIds.toString() } },
        );
    }

    private createGetByCourseUnitRealisationIdsCall(courseUnitRealisationIds: OtmId[]): Observable<EnrolmentCalculationConfig[]> {
        return this.getHttp().get<EnrolmentCalculationConfig[]>(
            CONFIG.ENDPOINTS.getEnrolmentCalculationConfigs,
            { params: { courseUnitRealisationIds: courseUnitRealisationIds.toString() } },
        );
    }

    getEnrolmentCalculationConfigOrNull(courseUnitRealisationId: OtmId): Observable<EnrolmentCalculationConfig> {
        if (!courseUnitRealisationId) {
            return throwError(() => new Error('The course unit realisation id was missing!'));
        }
        return this.getHttp().get<EnrolmentCalculationConfig>(CONFIG.ENDPOINTS.getEnrolmentCalculationConfig(courseUnitRealisationId))
            .pipe(
                catchError(error => this.returnNullOrThrowError(error)),
                tap(config => this.store.upsert(config.id, config)),
                switchMap(config => this.query.selectEntity(config.id)),
            );
    }

    getEnrolmentCalculationStateOrNull(courseUnitRealisationId: OtmId): Observable<EnrolmentCalculationConfigState> {
        if (!courseUnitRealisationId) {
            return throwError(() => new Error('The course unit realisation id was missing!'));
        }
        return this.getHttp().get<EnrolmentCalculationConfigState>(CONFIG.ENDPOINTS.forEnrolmentCalculationState(courseUnitRealisationId))
            .pipe(
                catchError(error => this.returnNullOrThrowError(error)),
                first(),
            );
    }

    returnNullOrThrowError(error: Error) {
        // The backend returns 404 if no match is found, convert that to a empty result
        if (error instanceof HttpErrorResponse && error.status === 404) {
            return of(null);
        }
        throw error;
    }

}

type EnrolmentCalculationConfigState = EntityState<EnrolmentCalculationConfig, OtmId>;

@StoreConfig({ name: 'enrolment-calculation-configs' })
class EnrolmentCalculationConfigStore extends EntityStore<EnrolmentCalculationConfigState> {}

class EnrolmentCalculationConfigQuery extends QueryEntity<EnrolmentCalculationConfigState> {
    constructor(protected store: EnrolmentCalculationConfigStore) {
        super(store);
    }
}
