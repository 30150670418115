import { Inject, Pipe, PipeTransform } from '@angular/core';
import { Module, OtmId } from 'common-typescript/types';
import { Observable, of } from 'rxjs';

import { AppErrorHandler } from '../error-handler/app-error-handler';
import { ModuleEntityService } from '../service/module-entity.service';

/**
 * Similar to EntityPipe but query is not with entity id.
 */
@Pipe({ name: 'moduleGroup' })
export class ModuleGroupPipe implements PipeTransform {
    constructor(
        private appErrorHandler: AppErrorHandler,
        private moduleEntityService: ModuleEntityService,
    ) {
    }

    transform(id: OtmId): Observable<Module> {
        if (!id) {
            return null;
        }
        return this.moduleEntityService.getByGroupId(id)
            .pipe(this.appErrorHandler.defaultErrorHandler());
    }
}
