import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { LocalDateRange, LocalDateTimeRange } from 'common-typescript/types';
import { CooperationNetwork } from 'common-typescript/types/generated/common-backend';
import { isEqual } from 'lodash';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { required } from '../../form/form-validators';
import { getLocalDateRangeEditorValue } from '../../form/formUtils';
import { SisFormBuilder } from '../../form/sis-form-builder.service';
import { SisFormControl } from '../../form/sis-form-control';

import {
    CooperationNetworkShareListItem,
} from './cooperation-network-details-editor.component';

@Component({
    selector: 'sis-cooperation-network-share-editor',
    templateUrl: './cooperation-network-share-editor.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CooperationNetworkShareEditorComponent implements OnInit, OnDestroy {

    @Input() cooperationNetworkShareListItem: CooperationNetworkShareListItem;
    @Input() cooperationNetworks: Partial<CooperationNetwork>[];
    @Input() organisationTkCode: string;
    @Input() label: string;
    @Input() enableRemoveButton = true;
    @Output() shareRemoved = new EventEmitter<number>();
    universityOrgId: string;
    showValidityPeriod: boolean;

    private destroyed$ = new Subject<void>();

    constructor(
        private rootFormGroup: FormGroupDirective,
        private fb: SisFormBuilder,
    ) {}

    ngOnInit(): void {
        const validityPeriod = this.cooperationNetworkShareListItem?.cooperationNetworkShare?.validityPeriod;
        this.showValidityPeriod = !!validityPeriod && (!!validityPeriod.startDate || !!validityPeriod.endDate);
        this.buildForm();
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
    }

    buildForm() {
        const control = this.fb.group({
            validityPeriod: this.fb.localDateRange({
                startDate: this.cooperationNetworkShareListItem.cooperationNetworkShare?.validityPeriod?.startDate,
                endDate: this.cooperationNetworkShareListItem.cooperationNetworkShare?.validityPeriod?.endDate },
                                                   { required: false }),
            cooperationNetworkId: this.fb.sisFormControl(this.cooperationNetworkShareListItem?.cooperationNetworkShare?.cooperationNetworkId, required()),
        });
        this.rootFormGroup.control.addControl(this.shareFormName, control);
        this.setupValueChangeListeners();
    }

    private setupValueChangeListeners() {
        this.validityPeriod.valueChanges
            .pipe(takeUntil(this.destroyed$),
                  distinctUntilChanged(isEqual))
            .subscribe((validityPeriod: LocalDateRange) => {
                this.cooperationNetworkShareListItem.cooperationNetworkShare.validityPeriod = getLocalDateRangeEditorValue(validityPeriod);
            });
        this.cooperationNetworkId.valueChanges
            .pipe(
                takeUntil(this.destroyed$),
            )
            .subscribe((cooperationNetworkId) => {
                this.cooperationNetworkShareListItem.cooperationNetworkShare.cooperationNetworkId = cooperationNetworkId;
            });
    }

    get shareForm() {
        return this.rootFormGroup.control.get(this.shareFormName) as FormGroup;
    }

    get shareFormName() {
        return `shareFormGroup${this.cooperationNetworkShareListItem.formId}`;
    }

    organisationValidityPeriod(cooperationNetworkId: string): LocalDateTimeRange {
        const cooperationNetwork = this.cooperationNetworks?.find(network => network.id === cooperationNetworkId);
        return cooperationNetwork?.organisations.find(org => org.organisationTkCode === this.organisationTkCode)?.validityPeriod;
    }

    addValidityPeriod() {
        this.showValidityPeriod = true;
    }

    removeValidityPeriod() {
        this.showValidityPeriod = false;
        this.validityPeriod.setValue({
            startDate: null,
            endDate: null,
        });
        this.cooperationNetworkShareListItem.cooperationNetworkShare.validityPeriod = null;
    }

    get validityPeriod(): FormControl {
        return this.shareForm.get('validityPeriod') as FormControl;
    }

    get cooperationNetworkId(): SisFormControl<string> {
        return this.shareForm.get('cooperationNetworkId') as SisFormControl<string>;
    }

    onCooperationNetworkRemoved(eventData: string) {
        this.shareRemoved.next(this.cooperationNetworkShareListItem.formId);
    }
}
