import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OtmId } from 'common-typescript/types';
import { ModalService } from 'sis-common/modal/modal.service';

export interface ChangeLearningOpportunityValues {
    name: string;
    learningOpportunityId: OtmId;
    learningOpportunityOptions: {
        value: OtmId;
        label: string;
    }[];
}

@Component({
    selector: 'app-change-learning-opportunity-modal',
    template: '<change-learning-opportunity-modal (close)="activeModal.close($event.$value)" (dismiss)="activeModal.dismiss()" [resolve]="values"></change-learning-opportunity-modal>',
    encapsulation: ViewEncapsulation.None,
})
export class ChangeLearningOpportunityModalComponent {
    constructor(@Inject(ModalService.injectionToken) public values: ChangeLearningOpportunityValues,
                public activeModal: NgbActiveModal) {}
}
