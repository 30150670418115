<ng-container *transloco="let t">
  <sis-course-unit-info-modal-text-field
    class="tweet-text"
    [localizedStringValue]="courseUnit.tweetText"
    [language]="language">
  </sis-course-unit-info-modal-text-field>
  <div class="row basic-info">
    <div class="col-12 col-md-6">
      <sis-tiny-static-form-group [label]="t('STUDIES.COURSE_UNITS_BASICINFO.CREDIT_RANGE')">
        <span class="value" *ngIf="courseUnit.credits; else noContent">
          {{courseUnit.credits | creditRange: 'TEXT': false }}
        </span>
      </sis-tiny-static-form-group>
      <sis-tiny-static-form-group [label]="t('STUDIES.COURSE_UNIT_INFO_MODAL.POSSIBLE_ATTAINMENT_LANGUAGES')">
        <span class="value" *ngIf="courseUnit.possibleAttainmentLanguages?.length > 0; else noContent">
          <ng-container *ngFor="let languageUrn of courseUnit.possibleAttainmentLanguages; last as isLast">
            {{ languageUrn | codeLocalizedName | async }}<span *ngIf="!isLast">,</span>
          </ng-container>
        </span>
      </sis-tiny-static-form-group>
      <sis-tiny-static-form-group [label]="t('STUDIES.COURSE_UNITS_BASICINFO.GRADE_SCALE')">
        <span class="value" *ngIf="courseUnit.gradeScaleId; else noContent">
          {{ courseUnit.gradeScaleId | gradeScale | async | entityName | localizedString }}
        </span>
      </sis-tiny-static-form-group>
    </div>
    <div class="col-12 col-md-6">
      <sis-tiny-static-form-group [label]="t('STUDIES.COURSE_UNIT_INFO_MODAL.COURSE_UNIT_TYPE')">
        <span class="value" *ngIf="courseUnit.courseUnitType; else noContent">
          {{ courseUnit.courseUnitType | codeLocalizedName | async }}
        </span>
      </sis-tiny-static-form-group>
      <sis-tiny-static-form-group [label]="t('STUDIES.COURSE_UNIT_INFO_MODAL.STUDY_LEVEL')">
        <span class="value" *ngIf="courseUnit.studyLevel; else noContent">
          {{ courseUnit.studyLevel | codeLocalizedName | async }}
        </span>
      </sis-tiny-static-form-group>
      <sis-tiny-static-form-group [label]="t('STUDIES.COURSE_UNIT_INFO_MODAL.CEFR_LEVEL')">
        <span class="value" *ngIf="courseUnit.cefrLevel; else noContent">
          {{ courseUnit.cefrLevel | codeLocalizedName | async }}
        </span>
      </sis-tiny-static-form-group>
    </div>
  </div>

  <sis-expandable [level]="3"
                  [title]="t('STUDIES.COURSE_UNIT_INFO_MODAL.DESCRIPTION')"
                  [closed]="false">
    <ng-template sisExpandableContent>
      <div class="row">
        <div class="col-12 col-md-6 form-group-mimic">
          <h4>{{t('STUDIES.COURSE_UNIT_INFO_MODAL.OUTCOMES')}}</h4>
          <sis-course-unit-info-modal-text-field [localizedMarkupStringValue]="courseUnit.outcomes" [language]="language"></sis-course-unit-info-modal-text-field>
        </div>
        <div class="col-12 col-md-6 form-group-mimic">
          <h4>{{t('STUDIES.COURSE_UNIT_INFO_MODAL.CONTENT')}}</h4>
          <sis-course-unit-info-modal-text-field [localizedMarkupStringValue]="courseUnit.content" [language]="language"></sis-course-unit-info-modal-text-field>
        </div>
        <div class="col-12 col-md-6 form-group-mimic">
          <h4>{{t('STUDIES.COURSE_UNIT_INFO_MODAL.ADDITIONAL')}}</h4>
          <sis-course-unit-info-modal-text-field [localizedMarkupStringValue]="courseUnit.additional" [language]="language"></sis-course-unit-info-modal-text-field>
        </div>
      </div>

    </ng-template>
  </sis-expandable>

  <sis-expandable [level]="3"
                  [title]="t('STUDIES.COURSE_UNIT_INFO_MODAL.OVERVIEW_PREREQUISITES')"
                  [closed]="false">
    <ng-template sisExpandableContent>
      <div class="row">
        <div class="col-12 col-md-6 form-group-mimic">
          <h4>{{t('STUDIES.COURSE_UNIT_INFO_MODAL.PREREQUISITES_DESCRIPTION')}}</h4>
          <sis-course-unit-info-modal-text-field [localizedMarkupStringValue]="courseUnit.prerequisites" [language]="language"></sis-course-unit-info-modal-text-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-5">
          <sis-basic-grouping-panel [hideGroupingPanel]="!courseUnit.compulsoryFormalPrerequisites || courseUnit.compulsoryFormalPrerequisites.length === 0"
                                    [heading]="'STUDIES.COURSE_UNIT_INFO_MODAL.OVERVIEW_COMPULSORY_FORMAL_PREREQUISITES'">
            <sis-prerequisites [prerequisites]="courseUnit.compulsoryFormalPrerequisites"></sis-prerequisites>
          </sis-basic-grouping-panel>
        </div>
        <div class="col-12 col-md-5 offset-md-1">
          <sis-basic-grouping-panel [hideGroupingPanel]="!courseUnit.recommendedFormalPrerequisites || courseUnit.recommendedFormalPrerequisites.length === 0"
                                    [heading]="'STUDIES.COURSE_UNIT_INFO_MODAL.OVERVIEW_RECOMMENDED_FORMAL_PREREQUISITES'">
            <sis-prerequisites [prerequisites]="courseUnit.recommendedFormalPrerequisites"></sis-prerequisites>
          </sis-basic-grouping-panel>
        </div>
      </div>
    </ng-template>
  </sis-expandable>

  <sis-expandable [level]="3"
                  [title]="t('STUDIES.COURSE_UNITS_DESCRIPTION.LEARNING_MATERIAL')"
                  [closed]="false">
    <ng-template sisExpandableContent>
      <div class="row">
        <div class="col-12 col-md-6 form-group-mimic">
          <h4>{{t('STUDIES.COURSE_UNIT_INFO_MODAL.LEARNING_MATERIAL')}}</h4>
          <sis-course-unit-info-modal-text-field
            [localizedMarkupStringValue]="courseUnit.learningMaterial"
            [language]="language">
          </sis-course-unit-info-modal-text-field>
        </div>
        <div class="col-12 col-md-6 form-group-mimic">
          <h4>{{t('STUDIES.COURSE_UNITS_DESCRIPTION.LITERATURE')}}</h4>
          <ng-container *ngIf="courseUnit.literature?.length > 0; else noContent">
            <sis-markup-string-view *ngFor="let item of courseUnit.literature" [content]="item | literature"></sis-markup-string-view>
          </ng-container>
        </div>
      </div>
    </ng-template>
  </sis-expandable>

  <sis-expandable [level]="3"
                  [title]="t('STUDIES.COURSE_UNIT_INFO_MODAL.TITLE_CLASSIFICATION')"
                  [closed]="false">
    <ng-template sisExpandableContent>
      <div class="row">
        <div class="col-12 col-md-6 form-group-mimic">
          <h4>{{t('STUDIES.COURSE_UNIT_INFO_MODAL.STUDY_FIELDS')}}</h4>
          <ng-container *ngIf="courseUnit.studyFields?.length > 0; else noContent">
            <span *ngFor="let studyFieldUrn of courseUnit.studyFields">{{ studyFieldUrn | codeLocalizedName | async }}</span>
          </ng-container>
        </div>
        <div class="col-12 col-md-6 form-group-mimic">
          <div class="search-tags">
            <h4>{{t('STUDIES.COURSE_UNIT_INFO_MODAL.SEARCH_TAGS')}}</h4>
            <ng-container *ngIf="courseUnit.searchTags?.length > 0; else noContent">
              <span *ngFor="let tag of courseUnit.searchTags; last as isLast">{{tag}}<span *ngIf="!isLast">,&nbsp;</span></span>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-template>
  </sis-expandable>

  <sis-expandable [level]="3"
                  [title]="t('STUDIES.COURSE_UNIT_INFO_MODAL.RESPONSIBILITY_TEACHERS_AND_CONTACT_INFO')"
                  [closed]="false">
    <ng-template sisExpandableContent>
      <sis-module-responsibility-info [responsibilityInfos]="courseUnit.responsibilityInfos"
                                      [language]="language"></sis-module-responsibility-info>
      <sis-responsible-organisation-info [organisations]="courseUnit.organisations"></sis-responsible-organisation-info>
    </ng-template>
  </sis-expandable>

  <ng-template #noContent>
    <span class="sis-placeholder">
    <sis-icon icon="alert-fill-attention"></sis-icon>{{t('SIS_COMPONENTS.COURSE_UNIT_REALISATION_INFO.NO_INFORMATION_GIVEN')}}
    </span>
  </ng-template>
</ng-container>
