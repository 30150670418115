<ng-container *ngIf="data$ | async as data">
  <div *ngIf="!suggestAttainment">
    <app-custom-study-draft-info
      [studyDraft]="data.studyDraft"
      [isSuggestButtonVisible]="data.isSuggestButtonVisible"
      (close)="cancel()"
      (suggestAttainment)="suggestAttainment = true"
    ></app-custom-study-draft-info>
  </div>
  <div *ngIf="suggestAttainment">
    <app-apply-custom-study-draft-credit
      [options]="data.viewOptions"
      [studyDraftName]="data.studyDraft.name"
      [homeUniversityName]="data.homeUniversityName"
      (close)="cancel()"
      (back)="suggestAttainment = false"
      (suggestedAttainment)="this.startAttainmentApplicationProcess($event)"
    ></app-apply-custom-study-draft-credit>
  </div>
</ng-container>
