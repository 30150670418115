<div *ngIf="terms && !neverExpires && !setEndDateManually">
  <div>
    <sis-basic-grouping-panel [heading]="'SEMESTERS_INDICATOR.TITLE' | translate" [level]="level"
      [tooltipText]="'SEMESTERS_INDICATOR.TOOLTIP_INFO' | translate" [tooltipIcon]="'info-circle'"
      [tooltipIconColor]="'primary'" [tooltipClass]="'tooltip-text'" [popoverTooltip]=true>
      <sis-description-list [columnCount]="1">
        <dl>
          <ng-template #listItem>
            <dt class="sis-label">{{ 'SEMESTERS_INDICATOR.DEGREE_TIME' | translate }}</dt>
            <dd>
              <sis-description-list-compact>
                <dl>
                  <ng-template #compactListItem>
                    <dt class="sis-label colon">{{ 'SEMESTERS_INDICATOR.TERMS_AVAILABLE' | translate}}</dt>
                    <dd class="sis-value">{{ 'SEMESTERS_INDICATOR.TERMS_AMOUNT' | translate: { amount: terms.unused } }}</dd>
                  </ng-template>
                  <ng-template #compactListItem>
                    <dt class="sis-label colon">{{ 'SEMESTERS_INDICATOR.TERMS_USED' | translate}}</dt>
                    <dd class="sis-value">{{ 'SEMESTERS_INDICATOR.TERMS_AMOUNT' | translate: { amount: terms.used } }}</dd>
                  </ng-template>
                </dl>
              </sis-description-list-compact>
            </dd>
          </ng-template>
          <ng-template #listItem>
            <dt class="sis-label">{{ 'SEMESTERS_INDICATOR.ABSENT_TIME' | translate }}</dt>
            <dd>
              <sis-description-list-compact>
                <dl>
                  <ng-template #compactListItem>
                    <dt class="sis-label colon">{{ 'SEMESTERS_INDICATOR.TERMS_AVAILABLE' | translate}}</dt>
                    <dd class="sis-value">{{ 'SEMESTERS_INDICATOR.TERMS_AMOUNT' | translate: { amount: getUnusedAbsentAmount(terms) } }}</dd>
                  </ng-template>
                  <ng-template #compactListItem>
                    <dt class="sis-label colon">{{ 'SEMESTERS_INDICATOR.TERMS_USED' | translate}}</dt>
                    <dd class="sis-value">{{ 'SEMESTERS_INDICATOR.TERMS_AMOUNT' | translate: { amount: terms.absentUsed } }}</dd>
                  </ng-template>
                </dl>
              </sis-description-list-compact>
            </dd>
          </ng-template>
          <ng-template #listItem>
            <dt class="sis-label">{{ 'SEMESTERS_INDICATOR.STATUTORY_TIME' | translate }}</dt>
            <dd>
              <sis-description-list-compact>
                <dl>
                  <ng-template #compactListItem>
                    <dt class="sis-label colon">{{ 'SEMESTERS_INDICATOR.TERMS_USED' | translate}}</dt>
                    <dd class="sis-value">{{ 'SEMESTERS_INDICATOR.TERMS_AMOUNT' | translate: { amount: terms.statutoryAbsentUsed } }}</dd>
                  </ng-template>
                </dl>
              </sis-description-list-compact>
            </dd>
          </ng-template>
          <ng-template #listItem>
            <dt class="sis-label">{{ 'SEMESTERS_INDICATOR.EXTENSION_TIME' | translate }}</dt>
            <dd>
              <sis-description-list-compact>
                <dl>
                  <ng-template #compactListItem *ngIf="terms.totalExtension">
                    <dt class="sis-label colon">{{ 'SEMESTERS_INDICATOR.TERMS_AVAILABLE' | translate}}</dt>
                    <dd class="sis-value">{{ 'SEMESTERS_INDICATOR.TERMS_AMOUNT' | translate: { amount: terms.extensionUnused } }}</dd>
                  </ng-template>
                  <ng-template #compactListItem *ngIf="terms.totalExtension">
                    <dt class="sis-label colon">{{ 'SEMESTERS_INDICATOR.TERMS_USED' | translate}}</dt>
                    <dd class="sis-value">{{ 'SEMESTERS_INDICATOR.TERMS_AMOUNT' | translate: { amount: terms.extensionUsed } }}</dd>
                  </ng-template>
                  <ng-template #compactListItem *ngIf="!terms.totalExtension">
                    <dt class="sis-label colon">{{ 'SEMESTERS_INDICATOR.TERMS_AVAILABLE' | translate}}</dt>
                    <dd class="sis-value">{{ 'SEMESTERS_INDICATOR.EXTENSION_UNSET' | translate }}</dd>
                  </ng-template>
                </dl>
              </sis-description-list-compact>
            </dd>
          </ng-template>
        </dl>
      </sis-description-list>
    </sis-basic-grouping-panel>
  </div>
</div>
