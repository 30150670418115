'use strict';

(function () {
  studyYearJSDataModel.$inject = ["DS", "StudyYearEndpoint"];
  angular.module('sis-components.model.studyYear', ['js-data']).factory('studyYearJSDataModel', studyYearJSDataModel).constant('StudyYearEndpoint', {
    DEFAULT: '/kori/api/study-years'
  });

  /**
   * @ngInject
   */
  function studyYearJSDataModel(DS, StudyYearEndpoint) {
    return DS.defineResource({
      idAttribute: 'id',
      name: 'studyYear',
      endpoint: StudyYearEndpoint.DEFAULT,
      computed: {
        id: ['universityOrgId', 'valid', function (org, valid) {
          return _.join([org, valid.startDate || 'null', valid.endDate || 'null'], '_');
        }]
      }
    });
  }
})();