import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TimelineNote } from 'common-typescript/types';
import { ModalService } from 'sis-common/modal/modal.service';

export interface TimelineNoteValues {
    note: TimelineNote;
}

@Component({
    selector: 'app-timeline-note-modal',
    template: '<timeline-note-modal (close)="activeModal.close()" (dismiss)="activeModal.dismiss()" [resolve]="values"></timeline-note-modal>',
    encapsulation: ViewEncapsulation.None,
})
export class TimelineNoteModalComponent {
    constructor(@Inject(ModalService.injectionToken) public values: TimelineNoteValues,
                public activeModal: NgbActiveModal) {}
}
