import { LanguagePillsComponent } from './../language-pills/language-pills.component.ts';
import localizedStringEditorTpl from './localizedStringEditor.tpl.html';
import _ from "lodash";
export const localizedStringEditorModule = 'sis-components.string.localizedStringEditor';

/*
 * localizedStringEditor
 *
 * You can pass NULL model value.
 * Model values will not contain empty values (including empty strings). If view contains no values, model value will be NULL.
 * Invalid values are returned, so that they can be used in the validation. Check form validity before submitting the form.
 * The form will use "name" attribute, so you can embed several components on a page.
 * The changes to the model value are watched and will propagate to the view value.
 * Set require attribute to require at least one value to be set.
 * if on-change attribute is set, it will be called when values are modified
 * We try to avoid using extra deep watches.
 * hideErrors -- if set to true, hide all the error messages
 *
 */

(function () {
  localizedStringEditorController.$inject = ["$scope", "localeService"];
  angular.module(localizedStringEditorModule, ['sis-common.l10n.localeService', 'sis-common.l10n.localizedStringFilter', LanguagePillsComponent.downgrade.moduleName]).directive('localizedStringEditor', localizedStringEditor).controller('localizedStringEditorController', localizedStringEditorController);

  /**
   * @ngInject
   */
  function localizedStringEditor() {
    return {
      require: ['ngModel', '^^form', 'localizedStringEditor'],
      restrict: 'E',
      scope: {
        name: '@',
        guidanceText: '@',
        selectedLocale: '=?',
        onChangeCallBack: '&',
        hideErrors: '@',
        placeholder: '@',
        minLength: '@',
        maxLength: '@',
        useTextArea: '<',
        readonly: '<?',
        modelDebounce: '@?',
        locales: '<?'
      },
      template: localizedStringEditorTpl,
      controller: 'localizedStringEditorController as ctrl',
      bindToController: true,
      transclude: true,
      // Do not use required and all-required at the same time
      link: function (scope, element, attrs, ctrls) {
        const ngModel = ctrls[0];
        const form = ctrls[1];
        const ctrl = ctrls[2];
        if (_.isEmpty(attrs.name)) {
          throw '"name" attribute is required';
        }
        ctrl.ngModel = ngModel;
        ctrl.form = form;
        ctrl.directiveElement = element;
        function hasValues(value) {
          return _.compact(_.values(value)).length > 0;
        }

        // focus input when tab clicked
        ctrl.focusInput = function (attr) {
          setTimeout(() => {
            element.find(attr).focus();
          });
        };

        // when the focus changes, change selected tab also
        ctrl.onFocus = function (tabindex) {
          if (tabindex !== scope.activeTab) {
            scope.activeTab = tabindex;
          }
        };
        ctrl.getInputFieldId = function (locale) {
          return `${attrs.name}_languageInput_${locale}`;
        };
        // This is a hack for intellij's inspections about dublicate id attributes
        ctrl.getTextareaFieldId = ctrl.getInputFieldId;

        // remove empties from model values. if no values, then the model is null.
        ngModel.$parsers.push(value => {
          if (hasValues(value)) {
            return _.omitBy(value, _.isEmpty);
          }
          return null;
        });

        // if model value is null, fill view model with empty object
        ngModel.$formatters.push(value => {
          if (!_.isObject(value)) {
            return {};
          }
          return value;
        });

        // Do not use required and all-required at the same time
        attrs.$observe('required', value => {
          if (value) {
            // validate that we have values. this error is also used in require parameter of input fields.
            ngModel.$validators.requireTranslations = function (modelValue) {
              return hasValues(modelValue);
            };
            ctrl.realMinLength = ctrl.minLength;
            ngModel.$validate();
          } else {
            delete ngModel.$validators.requireTranslations;
            ngModel.$setValidity('requireTranslations', true);
            // it is legal to have required disabled but have minimum length of 1 in backend.
            // in this case, empty value, i.e. null, is allowed, but empty string "" is not.
            // so, we disable minlength checking if required is not set.
            ctrl.realMinLength = 0;
          }
        });
        // Do not use required and all-required at the same time
        attrs.$observe('allRequired', () => {
          ngModel.$validators.requireAllTranslations = function (modelValue) {
            return _.every(ctrl.supportedLocales, locale => !!_.get(modelValue, locale));
          };
          ctrl.realMinLength = ctrl.minLength;
          ngModel.$validate();
        });
      }
    };
  }
  function localizedStringEditorController($scope, localeService) {
    const ctrl = this;
    ctrl.scope = $scope;
    ctrl.$onInit = function () {
      if (ctrl.locales) {
        ctrl.selectedLocale = !ctrl.selectedLocale ? _.first(ctrl.locales) : ctrl.selectedLocale;
        ctrl.supportedLocales = ctrl.locales;
      } else {
        ctrl.selectedLocale = !ctrl.selectedLocale ? 'fi' : ctrl.selectedLocale;
        ctrl.supportedLocales = localeService.supportedLocales;
      }

      // Update the language pill error indicators whenever the validity of the input fields change. This is a bit hacky,
      // but AngularJS doesn't support listening to form value changes, and the form value/validity hasn't been updated
      // yet in ctrl.onChange(), so this seemed like the next best option...
      const inputValidityWatchExpressions = ctrl.supportedLocales.map(locale => `ctrl.form.${ctrl.name}.${locale}.$invalid`);
      $scope.$watchGroup(inputValidityWatchExpressions, () => {
        ctrl.invalidLangFields = _.filter(ctrl.supportedLocales, locale => _.get(ctrl.form, `${ctrl.name}.${locale}.$invalid`));
      });
    };
    ctrl.selectLocale = function (locale) {
      ctrl.selectedLocale = locale;
    };
    ctrl.isSelectedLocale = function (locale) {
      return locale === ctrl.selectedLocale;
    };
    ctrl.focusLocaleInput = function (locale) {
      ctrl.directiveElement.find(`#${ctrl.name}_languageInput_${locale}`).focus();
    };

    // $setViewValue is needed because angular ngModel does not do deep watch on the object, and will not notice
    // changes in the underlying objects.
    //
    // in certain scenarios ctrl.ngModel may not be initialized
    // when update is called the first time, because controller is created before ngModel is set in the
    // link function
    ctrl.onChange = function () {
      if (_.isFunction(_.get(ctrl.ngModel, '$setViewValue'))) {
        ctrl.ngModel.$setViewValue(angular.copy(ctrl.ngModel.$viewValue));
        if (ctrl.onChangeCallBack) {
          ctrl.onChangeCallBack({
            value: ctrl.ngModel.$viewValue
          });
        }
      }
    };

    // return list of locales that have this error
    ctrl.errors = function (error) {
      return _.filter(ctrl.supportedLocales, locale => {
        if (!_.isNil(ctrl.form) && !_.isNil(ctrl.form[ctrl.name]) && !_.isNil(ctrl.form[ctrl.name][locale])) {
          return ctrl.form[ctrl.name][locale].$error[error];
        }
        return false;
      });
    };
  }
})();