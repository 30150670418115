import {
    ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges,
    ViewEncapsulation,
} from '@angular/core';
import { PrivatePerson } from 'common-typescript/types';
import _ from 'lodash';

@Component({
    selector: 'app-contact-information',
    templateUrl: './contact-information.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactInformationComponent implements OnInit, OnChanges {

    @Input() studentProfile: PrivatePerson;

    classifiedVisibilityOverrides: any;
    isSecondaryAddressInFinland: boolean;

    ngOnInit(): void {
        this.classifiedVisibilityOverrides = {
            phoneNumberVisible: false,
            secondaryEmailVisible: false,
            primaryAddressVisible: false,
            secondaryAddressVisible: false,
            municipalityVisible: false,
        };
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.studentProfile?.currentValue?.secondaryAddress !== changes.studentProfile?.previousValue?.secondaryAddress) {
            this.isSecondaryAddressInFinland = !!this.studentProfile.secondaryAddress ? _.get(this.studentProfile, 'secondaryAddress.type') === 'FinnishAddress' : null;
        }
    }

    toggleFieldVisibility(fieldName: string) {
        const field = `${_.camelCase(fieldName)}Visible`;
        _.set(this.classifiedVisibilityOverrides, field, !_.get(this.classifiedVisibilityOverrides, field));
    }

    isClassifiedInfoVisible(fieldName: string) {
        const fieldOverride = `${_.camelCase(fieldName)}Visible`;
        const classifiedField = `classifiedPersonInfo.is${fieldName}Classified`;
        const override = _.get(this.classifiedVisibilityOverrides, fieldOverride);
        const classified = !_.get(this.studentProfile, classifiedField);
        return override || classified;
    }

    isClassified(fieldName: string) {
        const classifiedField = `classifiedPersonInfo.is${fieldName}Classified`;
        return !!_.get(this.studentProfile, classifiedField);
    }

}
