import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Plan, ValidatablePlan } from 'common-typescript/types';
import { ModalService } from 'sis-common/modal/modal.service';

export interface SuggestAddMessageValues {
    plan: Plan;
    validatablePlan: ValidatablePlan;
}

@Component({
    selector: 'sis-suggest-add-message-modal',
    template: '<suggest-add-message-modal (close)="activeModal.close($event.$value)" (dismiss)="activeModal.dismiss()" [resolve]="values"></suggest-add-message-modal>',
    encapsulation: ViewEncapsulation.None,
})
export class SuggestAddMessageModalComponent {
    constructor(@Inject(ModalService.injectionToken) public values: SuggestAddMessageValues,
                public activeModal: NgbActiveModal) {}
}
