import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterModule } from '@uirouter/angular';
import { SisCommonModule } from 'sis-common/sis-common.module';
import { SisComponentsModule } from 'sis-components/sis-components.module';

import { StudentCommonModule } from '../../common.module';

import { ModuleInfoHeaderComponent } from './module-info-header/module-info-header.component';
import { ModuleInfoModalComponent } from './module-info-modal/module-info-modal.component';
import { ModuleInfoVersionChangeWarningComponent } from './module-info-version-change-warning/module-info-version-change-warning.component';

@NgModule({
    declarations: [
        ModuleInfoModalComponent,
        ModuleInfoHeaderComponent,
        ModuleInfoVersionChangeWarningComponent,
    ],
    imports: [
        CommonModule,
        TranslocoModule,
        TranslateModule.forChild(),
        SisComponentsModule,
        SisCommonModule,
        StudentCommonModule,
        UIRouterModule,
    ],
})
export class ModuleInfoModule { }
