import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { TranslateService } from '@ngx-translate/core';
import { LocalizedString, SearchParameterOption } from 'common-typescript/types';
import _ from 'lodash';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { Option } from 'sis-components/select/combobox/combobox.component';

export const SEARCH_CONSTANTS = {
    messagesPerPage: 20,
    queryTextMinLength: 3,
    queryTextMaxLength: 255,
    maxSize: 5,
};

export interface LanguageHeaderIndex {
    index: number;
    option: Option;
}

@Injectable()
export class SearchService {

    constructor(
        private localeService: LocaleService,
        private translate: TranslateService,
        private translocoService: TranslocoService,
    ) {
    }

    mapSelectedValuesToSearchParameters(parameter: any, selected: any[]) {
        return selected.map((item: any) => {
            const value: any = {};
            parameter.parameterAllowedFields.forEach(
                (field: string) => {
                    const fieldVal = item.value ? this.getDescendantProp(item.value, field) : this.getDescendantProp(item, field);
                    if (fieldVal) {
                        this.setDescendantProp(field, fieldVal, value);
                    }
                });
            return value;
        });
    }

    mapSelectedOptions(searchParameterOptions: any, searchParameters: any): any[] {
        if (searchParameterOptions.options && searchParameters) {
            const parameterValues = searchParameterOptions.options.filter((option: any) => searchParameters.value.some((s: any) => _.isEqual(s.name, option.name)));
            return parameterValues.map((parameterValue: any) => ({
                label: typeof (parameterValue.name) === 'string' ? parameterValue.name : this.localeService.localize(parameterValue.name),
                value: parameterValue,
            }));
        }

        if (!searchParameterOptions.options && searchParameters.value.length > 0) {
            return searchParameters.value.map((s: any) => ({
                label: typeof (s.name) === 'string' ? s.name : this.localeService.localize(s.name),
                value: s,
            }));
        }
        return [];
    }

    mapSearchOptions(parameter: SearchParameterOption, headers: any[]): any[] {
        let list: any[] = [];
        if (parameter.options !== undefined) {
            list = parameter.options.map((option: any) => ({
                label: typeof (option.name) === 'string' ? option.name : this.localeService.localize(option.name),
                value: option,
            }));
            if (headers) {
                headers.forEach(header => list.splice(header.index, 0, header.option));
            }
        }
        return list;
    }

    translateForLocales(translationKey: string, itemCopyName: LocalizedString) {
        const locales: string[] = _.keys(itemCopyName);
        return from(locales)
            .pipe(
                mergeMap((locale: string) => itemCopyName[locale] = this.translocoService.translate(translationKey, {}, locale)),
            );
    }

    getFilterTitleLabel(activeFilters: number) {
        if (activeFilters === 0) {
            return this.translate.instant('SEARCH_FILTERS_TITLE');
        }
        return `${this.translate.instant('SEARCH_FILTERS_TITLE')} ${this.translate.instant('SEARCH_FILTERS_CHOSEN', { count: activeFilters })}`;
    }

    private getDescendantProp = (obj: any, desc: string) => desc.split('.').reduce((a, b) => a[b], obj);

    private setDescendantProp(path: string, value: any, obj: any) {
        let schema = obj; // a moving reference to internal objects within obj
        const pList = path.split('.');
        const len = pList.length;
        for (let i = 0; i < len - 1; i += 1) {
            const elem = pList[i];
            if (!schema[elem]) schema[elem] = {};
            schema = schema[elem];
        }
        schema[pList[len - 1]] = value;
    }
}
