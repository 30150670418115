angular.module('student.frontpage.studyProgressGraph').constant('studyProgressGraphConfig', {
  stateStore: {
    KEY: 'student.studyProgressGraph'
  },
  viewStatus: {
    NOT_SELECTED: 'not_selected',
    LOADING: 'loading',
    LOADED: 'loaded'
  },
  studies: {
    TARGET_YEARLY: 60
  },
  chartjs: {
    OPTIONS: {
      type: 'doughnut',
      data: {
        datasets: [{
          data: [35, 65, 17 // "empty" part of the doughnut, do not change this value
          ],
          backgroundColor: ["rgba(35,136,219,1)", "rgba(35,136,219,0.3)", "rgba(0,0,0,0)"],
          borderWidth: 0,
          gradientCoordinates: {
            x1: 10,
            y1: 82,
            x2: 86,
            y2: 45
          }
        }]
      },
      options: {
        responsive: false,
        hover: false,
        tooltips: {
          enabled: false
        },
        cutoutPercentage: 85,
        rotation: 0.65 * Math.PI,
        elements: {
          arc: {
            roundedCornersFor: [2, 1, 0]
          }
        },
        animation: {
          animateRotate: false
        }
      }
    }
  }
});