import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { StateService } from '@uirouter/core';
import { CourseUnit, OpenUniversityCart, OpenUniversityProduct } from 'common-typescript/types';

import { EnrichedAssItem } from '../../open-university-enrolment-wizard/open-university-enrolment-wizard.component';

@Component({
    selector: 'app-open-university-studies-activate-wizard-step3',
    templateUrl: './open-university-studies-activate-wizard-step3.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpenUniversityStudiesActivateWizardStep3Component {

    @Input() wizardProgressStepKeys: string[] = [];
    @Input() openUniversityCart: OpenUniversityCart;
    @Input() openUniversityProducts: OpenUniversityProduct[] = [];
    @Input() courseUnits: CourseUnit[] = [];
    @Input() assessmentItemsData: EnrichedAssItem[] = [];
    @Input() isSuomiFiPath: boolean;
    @Input() isStaffUser?: boolean;
    @Output() logoutFromSuomiFi = new EventEmitter<void>();

    constructor(private stateService: StateService) { }

    goToFrontPage() {
        this.stateService.go('student.logged-in.frontpage', {},
                             { custom: { skipConfirmationDialog: true } });
    }

    goToSearch() {
        this.logoutFromSuomiFi.emit();
        this.stateService.go('student.search.open-university', {}, { custom: { skipConfirmationDialog: true } });
    }
}
