import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { getEntityType as EntityType } from '@datorama/akita/src/lib/types';
import { CooperationNetworkSettings, Enrolment, OtmId, PrivatePerson } from 'common-typescript/types';
import { Observable, throwError } from 'rxjs';
import { delay, map, switchMap, tap } from 'rxjs/operators';

import { EntityService } from './entity.service';

const CONFIG = {
    ENDPOINTS: {
        backend: '/kori/api',
        getByCooperationNetworkId(universityOrgId: OtmId, cooperationNetworkId: OtmId) {
            return `${this.backend}/cooperation-network-settings/by-cooperation-network-id/${universityOrgId}/${cooperationNetworkId}`;
        },
        createCooperationNetworkSettings() {
            return `${this.backend}/cooperation-network-settings`;
        },
        updateCooperationNetworkSettings(id: OtmId) {
            return `${this.backend}/cooperation-network-settings/${id}`;
        },
    },
};

@Injectable({
    providedIn: 'root',
})
@NgEntityServiceConfig({
    baseUrl: CONFIG.ENDPOINTS.backend,
    resourceName: 'cooperation-network-settings',
})
export class CooperationNetworkSettingsEntityService extends EntityService<CooperationNetworkSettingsState> {

    constructor() {
        super(CooperationNetworkSettingsStore, CooperationNetworkSettingsQuery);
    }

    getByCooperationNetworkId(universityOrgId: OtmId, cooperationNetworkId: OtmId, bypassStore = false): Observable<CooperationNetworkSettings> {
        const url = CONFIG.ENDPOINTS.getByCooperationNetworkId(universityOrgId, cooperationNetworkId);

        if (!universityOrgId) {
            return throwError(() => new Error('UniversityOrgId was missing'));
        }
        if (!cooperationNetworkId) {
            return throwError(() => new Error('cooperationNetworkId was missing'));
        }

        const matcher = (e: CooperationNetworkSettings) =>
            e.universityOrgId === universityOrgId &&
            e.cooperationNetworkId === cooperationNetworkId;

        if (!bypassStore && this.query.hasEntity(matcher)) {
            return this.query.selectEntity(matcher);
        }

        return this.getHttp()
            .get<CooperationNetworkSettings>(url)
            .pipe(
                this.upsertSettingsAndSwitchToStoreObservable(universityOrgId, cooperationNetworkId),
            );
    }

    protected upsertSettingsAndSwitchToStoreObservable(universityOrgId: OtmId, cooperationNetworkId: OtmId): (source: Observable<CooperationNetworkSettings>) => Observable<CooperationNetworkSettings> {
        return source => source.pipe(
            tap(entity => {
                if (entity) this.store.upsert(entity.id, entity);
            }),
            switchMap(entity => this.query.selectAll({
                filterBy: e => e.cooperationNetworkId === cooperationNetworkId && e.universityOrgId === universityOrgId,
                limitTo: 1,
            })),
            map(list => {
                if (!list) return undefined;
                if (list.length > 1) throw Error(`Too many Cooperation network settings, should be 1, got ${list.length}`);
                return list[0];
            }),
        );
    }

    createCooperationNetworkSettings(settings: CooperationNetworkSettings): Observable<CooperationNetworkSettings> {
        return this.getHttp()
            .post<CooperationNetworkSettings>(CONFIG.ENDPOINTS.createCooperationNetworkSettings(), settings)
            .pipe(this.upsertSettingsAndSwitchToStoreObservable(settings.universityOrgId, settings.cooperationNetworkId));
    }

    updateCooperationNetworkSettings(id: OtmId, settings: CooperationNetworkSettings): Observable<CooperationNetworkSettings> {
        return this.getHttp()
            .put<CooperationNetworkSettings>(CONFIG.ENDPOINTS.updateCooperationNetworkSettings(id), settings)
            .pipe(this.upsertSettingsAndSwitchToStoreObservable(settings.universityOrgId, settings.cooperationNetworkId));
    }
}

type CooperationNetworkSettingsState = EntityState<CooperationNetworkSettings, OtmId>;

@StoreConfig({ name: 'cooperation-network-settings' })
class CooperationNetworkSettingsStore extends EntityStore<CooperationNetworkSettingsState> {}

class CooperationNetworkSettingsQuery extends QueryEntity<CooperationNetworkSettingsState> {
    constructor(protected store: CooperationNetworkSettingsStore) {
        super(store);
    }
}
