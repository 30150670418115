import { Pipe, PipeTransform } from '@angular/core';
import { OtmId } from 'common-typescript/types';
import { get } from 'lodash';
import { EMPTY, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { AppErrorHandler } from '../error-handler/app-error-handler';
import { ModuleEntityService } from '../service/module-entity.service';
import { StudyRightEntityService } from '../service/study-right-entity.service';

@Pipe({ name: 'studyRightActivePhaseModule' })
export class StudyRightActivePhaseModulePipe implements PipeTransform {

    constructor(
        private appErrorHandler: AppErrorHandler,
        private moduleEntityService: ModuleEntityService,
        private studyRightEntityService: StudyRightEntityService,
    ) {}

    /**
     * @param studyRightId The id of the study right whose active phase module group id is used to fetch the actual Module.
     * @param property The optional name of the property whose value should be returned instead of the whole Module if given.
     * @returns Either the whole Module (DegreeProgramme, StudyModule or GroupingModule) related to the given `studyRightId`
     * or the value of its specific property denoted by `property`.
     */
    transform(studyRightId: OtmId, property?: string): Observable<any> {
        if (!studyRightId) {
            return null;
        }
        return this.studyRightEntityService.getStudyRightActivePhaseModuleGroupId(studyRightId)
            .pipe(
                mergeMap(studyRightActivePhaseModule =>
                    studyRightActivePhaseModule.moduleGroupId
                        ? this.moduleEntityService.getByGroupId(studyRightActivePhaseModule.moduleGroupId)
                        : EMPTY,
                ),
                map(module => {
                    if (module && property) {
                        if (!(property in module)) {
                            // not reasonable to throw an error here because the property might still be correct albeit missing
                            console.debug(`Property '${property}' not present in module.`);
                        }
                        return get(module, property);
                    }
                    return module;
                }),
                this.appErrorHandler.defaultErrorHandler(),
            );
    }
}
