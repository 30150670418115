<ng-container *transloco="let t">
  <nav role="navigation" class="top-navigation-icons" [attr.aria-label]="t('ARIA_LABEL.SECONDARY_MENU')">
    <ul #navList class="top-navigation-icons__list">
      <ng-container *ngIf="!mobileMode && frontendName === 'STUDENT'"
                    [ngTemplateOutlet]="listItem" [ngTemplateOutletContext]="{ variant:'open-university-cart' }"/>
      <ng-container *ngIf="authenticated() && frontendName !== 'ADMIN'"
                    [ngTemplateOutlet]="listItem" [ngTemplateOutletContext]="{ variant:'messages', mobileMode }"/>
      <ng-container *ngIf="authenticated() && frontendName === 'STUDENT'"
                    [ngTemplateOutlet]="listItem" [ngTemplateOutletContext]="{ variant:'notes', mobileMode }"/>
      <ng-container [ngTemplateOutlet]="listItem" [ngTemplateOutletContext]="{ variant:'help', mobileMode }"/>
      <ng-container [ngTemplateOutlet]="listItem" [ngTemplateOutletContext]="{ variant:'feedback', mobileMode }"/>
    </ul>
  </nav>
</ng-container>

<ng-template #listItem let-variant="variant" let-mobileMode="mobileMode">
  <li class="top-navigation-icons__list__item">
    <sis-top-navigation-icon [variant]="variant"
                             [mobileMode]="mobileMode"
                             (blurMobileMenuIcon)="blurMobileMenuIcons.emit($event)"/>
  </li>
</ng-template>
