<ng-container *transloco="let t">
  <ng-container *ngIf="data$ | async as data">
    <app-student-module-info-modal-header [module]="data.selectedModule" [attainment]="data.selectedModuleAttainment" (closeModal)="close()"></app-student-module-info-modal-header>
    <app-student-module-info-modal-module-attainment-applications-block *ngIf="data.activeModuleAttainmentWorkflow"
                                                                        [moduleAttainmentWorkflow]="data.activeModuleAttainmentWorkflow"
                                                                        (closeModal)="close()">
    </app-student-module-info-modal-module-attainment-applications-block>
    <app-student-module-info-modal-create-module-attainment-application-block
      *ngIf="data.showCreateModuleAttainmentApplicationBlock && !data.activeModuleAttainmentWorkflow"
      [module]="data.selectedModule"
      [validatablePlan]="validatablePlan"
      [planValidationResult]="planValidationResult"
      [studyRight]="studyRight"
      (refreshData)="refreshData()">
    </app-student-module-info-modal-create-module-attainment-application-block>
    <app-student-module-info-modal-dp-attainment-application-block
      *ngIf="data.degreeProgrammeAttainmentWorkflow"
      [degreeProgrammeAttainmentWorkflow]="data.degreeProgrammeAttainmentWorkflow"
      [selectedModule]="data.selectedModule"
      (closeModal)="close()">

    </app-student-module-info-modal-dp-attainment-application-block>
    <div class="modal-body">
      <div class="row">
        <div class="col-12">
          <sis-expandable [title]="t('MODULE_INFO.TITLE_BASIC')"
                          [level]="3"
                          [closed]="false">
            <ng-template sisExpandableContent>
              <app-module-info-basic-info [module]="$any(data.selectedModule)"></app-module-info-basic-info>
            </ng-template>
          </sis-expandable>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <sis-expandable [title]="t('MODULE_INFO.TITLE_DESCRIPTIONS')"
                          [level]="3"
                          [closed]="false">
            <ng-template sisExpandableContent>
              <app-module-info-description [module]="$any(data.selectedModule)"></app-module-info-description>
            </ng-template>
          </sis-expandable>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <sis-expandable [title]="t('MODULE_INFO.TITLE_CLASSIFICATION')"
                          [level]="3"
                          [closed]="false">
            <ng-template sisExpandableContent>
              <app-module-info-classification [module]="$any(data.selectedModule)"></app-module-info-classification>
            </ng-template>
          </sis-expandable>
        </div>
      </div>
      <div class="row" *ngIf="data.showGraduationSection">
        <div class="col-12">
          <sis-expandable [title]="t('MODULE_INFO.TITLE_GRADUATION')"
                          [level]="3"
                          [closed]="false">
            <ng-template sisExpandableContent>
              <app-student-module-info-modal-graduation-info
                [module]="data.selectedModule"
                [organisationId]="studyRight?.organisationId"
                [plan]="validatablePlan?.plan"
                [selectedModuleAttainment]="data.selectedModuleAttainment"
                [degreeProgrammeAttainmentWorkflow]="data.degreeProgrammeAttainmentWorkflow"
                (closeModal)="close()">
              </app-student-module-info-modal-graduation-info>
            </ng-template>
          </sis-expandable>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
