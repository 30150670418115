<fudis-dl [columns]="{ sm: 1, md: 2 }" [rowGap]="'xl'" [marginBottom]="'lg'">
  <fudis-dl-item>
    <fudis-dt [contentText]="'STUDENT_APPLICATIONS.STUDENT' | translate"/>
    <fudis-dd *ngIf="workflow.studentId | privatePersonBasicInfo | async as student"
               [contentText]="(student | personFullName) + ' (' + student.studentNumber + ')'"/>
  </fudis-dl-item>

  <fudis-dl-item>
    <fudis-dt [contentText]="'STUDENT_APPLICATIONS.APPLICATION_ARRIVED' | translate"/>
    <fudis-dd [contentText]="workflow.creationTime | localDateFormat"/>
  </fudis-dl-item>

  <fudis-dl-item *ngIf="$any(workflow)?.courseUnitId | courseUnit | async as courseUnit; else noCourseUnit">
    <fudis-dt [contentText]="'STUDENT_APPLICATIONS.SUBSTITUTED_COURSE_UNIT' | translate"/>
    <fudis-dd>
        <ng-container *ngIf="workflow.type !== studentApplicationType.PRIOR_LEARNING_SUBSTITUTION_WORKFLOW">
          ({{'STUDENT_APPLICATIONS.VERSION' | translate}} {{courseUnit.curriculumPeriodIds | curriculumPeriodName:true | async}})
        </ng-container>
        <sis-button *ngIf="workflow.type === studentApplicationType.PRIOR_LEARNING_SUBSTITUTION_WORKFLOW" [link]="true" (clicked)="openCourseUnitInfoModal.emit()">
          <fudis-icon [icon]="'brochure'"/>
          <span>{{courseUnit.name | localizedString }}</span>
          <span> ({{'STUDENT_APPLICATIONS.VERSION' | translate}} {{courseUnit.curriculumPeriodIds | curriculumPeriodName:true | async}})</span>
        </sis-button>
    </fudis-dd>
  </fudis-dl-item>

  <fudis-dl-item>
    <fudis-dt [contentText]="'STUDENT_APPLICATIONS.STUDY_RIGHT' | translate"/>
    <fudis-dd [contentText]="workflow.studyRightId | studyRightName | async | localizedString"/>
  </fudis-dl-item>

  <ng-template #noCourseUnit>
    <fudis-dl-item>
      <fudis-dt [contentText]="'STUDENT_APPLICATIONS.SUGGESTED_ATTAINMENT_NAME' | translate"/>
      <fudis-dd [contentText]="$any(workflow)?.name"/>
    </fudis-dl-item>
  </ng-template>

  <fudis-dl-item *ngIf="workflow.type === studentApplicationType.PRIOR_LEARNING_INCLUSION_WORKFLOW">
    <fudis-dt [contentText]="'STUDENT_APPLICATIONS.DEGREE_PROGRAMME' | translate"/>
    <fudis-dd [contentText]="($any(workflow)?.degreeProgrammeId | module | async)?.name | localizedString"/>
  </fudis-dl-item>

  <ng-container *ngIf="workflow.studentId | privatePersonBasicInfo | async as student">
    <fudis-dl-item>
      <fudis-dt [contentText]="'STUDENT_APPLICATIONS.PREFERRED_LANGUAGE' | translate"/>
      <fudis-dd [contentText]="(student?.preferredLanguageUrn | codeLocalizedName | async) ?? '-'"/>
    </fudis-dl-item>

    <fudis-dl-item>
      <fudis-dt [contentText]="'STUDENT_APPLICATIONS.CONTACT_INFO.EMAIL' | translate"/>
      <fudis-dd [contentText]="student.primaryEmail"/>
    </fudis-dl-item>
  </ng-container>
</fudis-dl>

<ng-container *ngIf="$any(workflow)?.customStudyDraft as customStudyDraft">
  <fudis-heading [level]="3">
    {{ 'STUDENT_APPLICATIONS.CUSTOM_STUDY_DRAFT_THAT_APPLICATION_IS_BASED_ON' | translate }}
  </fudis-heading>
  <fudis-dl [columns]="{ sm: 1, md: 2 }" [rowGap]="'xl'" [marginBottom]="'lg'">
    <fudis-dl-item>
      <fudis-dt [contentText]="'CUSTOM_STUDY_DRAFT.NAME' | translate"/>
      <fudis-dd [contentText]="customStudyDraft.name"/>
    </fudis-dl-item>
    <fudis-dl-item>
      <fudis-dt [contentText]="'CUSTOM_STUDY_DRAFT.CREDITS' | translate"/>
      <fudis-dd [contentText]="customStudyDraft.credits + ('CREDITS' | translate)"/>
    </fudis-dl-item>
    <fudis-dl-item>
      <fudis-dt [contentText]="'CUSTOM_STUDY_DRAFT.LOCATION' | translate"/>
      <fudis-dd [contentText]="customStudyDraft.location"/>
    </fudis-dl-item>
    <fudis-dl-item>
      <fudis-dt [contentText]="'CUSTOM_STUDY_DRAFT.DESCRIPTION' | translate"/>
      <fudis-dd [contentText]="customStudyDraft.description"/>
    </fudis-dl-item>
  </fudis-dl>
</ng-container>
