import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
    Attainment,
    CourseUnit,
    CourseUnitAttainment,
    GradeRaiseAttempt,
    RdiCreditsEnabled,
} from 'common-typescript/types';
import { Observable, of, take } from 'rxjs';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { CourseUnitEntityService } from 'sis-components/service/course-unit-entity.service';

@Component({
    selector: 'app-course-unit-attainment-details',
    templateUrl: './course-unit-attainment-details.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CourseUnitAttainmentDetailsComponent implements OnInit {
    rdiCreditsEnabled: RdiCreditsEnabled;
    completionMethodsAvailable: boolean = false;
    gradeRaiseCourseUnitVersion$: Observable<CourseUnit>;

    @Input() attainment: CourseUnitAttainment;
    @Input() secondaryAttainments: Attainment[];
    @Input() modalRef: NgbActiveModal;
    @Input() expirationImminent: boolean;
    @Input() gradeRaiseEnabled: boolean;
    @Input() gradeRaiseAttempt: GradeRaiseAttempt;

    constructor(
        private courseUnitEntityService: CourseUnitEntityService,
        private appErrorHandler: AppErrorHandler,
    ) { }

    ngOnInit() {
        this.courseUnitEntityService.getById(this.attainment.courseUnitId)
            .pipe(
                take(1),
                this.appErrorHandler.defaultErrorHandler())
            .subscribe((courseUnit: CourseUnit) => {
                this.rdiCreditsEnabled = courseUnit.rdiCreditsEnabled;
                this.completionMethodsAvailable = courseUnit.completionMethods.length > 0;
                if (this.gradeRaiseAttempt && this.gradeRaiseAttempt.courseUnitId !== courseUnit.id) {
                    this.gradeRaiseCourseUnitVersion$ = this.courseUnitEntityService.getById(this.gradeRaiseAttempt.courseUnitId)
                        .pipe(
                            take(1),
                            this.appErrorHandler.defaultErrorHandler());
                } else {
                    this.gradeRaiseCourseUnitVersion$ = of(courseUnit);
                }
            });
    }

    gradeRaiseButtonTranslationKey(): string {
        return this.gradeRaiseAttempt ? 'ATTAINMENTS.ATTAINMENT_DETAILS.GRADE_RAISE_CHANGE_COMPLETION_METHOD'
            : 'ATTAINMENTS.ATTAINMENT_DETAILS.GRADE_RAISE_ATTEMPT_BUTTON';
    }

    getCompletionMethodIndexForGradeRaise(courseUnit: CourseUnit): number {
        const index = courseUnit.completionMethods.map(completionMethod =>
            completionMethod.localId).indexOf(this.gradeRaiseAttempt.completionMethodId);
        return index >= 0 ? index + 1 : undefined;
    }
}
