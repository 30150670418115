<fieldset (window:resize)="updateVisibleItems()">
  <legend>
    <label data-cy="combobox-label" [id]="'combobox-label-' + id">{{ label }}</label>
  </legend>
  <div tabindex="0" [id]="id" class="combobox" [class.focused]="onFocus" #comboboxElement>
    <div class="selected-items" #selectedItemsElement *ngIf="selected.length"
         [ngClass]="{'cut-text': visibleSelectedItems.length === 1 && !onFocus}">
      <button *ngFor="let item of visibleSelectedItems"
              role="menuitem"
              data-cy="combobox-selected-option"
              type="button"
              [id]="item.value"
              [attr.aria-label]="('ARIA_LABEL.DELETE' | translate) + ' ' + item.label"
              (click)="removeSelected($event, item)" class="non-button selected-item">
        <sis-icon [iconClass]="'selected-item-icon'" icon="close"></sis-icon>
        {{ item.label }} <span *ngIf="item.secondaryLabel" class="secondary-label">{{ item.secondaryLabel }}</span>
      </button>
      <span class="selected-item" *ngIf="hiddenItemCount">+ {{ hiddenItemCount }}</span>
    </div>
    <input
      #inputElement
      data-cy="combobox-input"
      [class.hidden]="!onFocus && (visibleSelectedItems.length > 0 || hiddenItemCount !== 0)"
      class="combobox-input"
      type="text"
      [placeholder]="placeholder"
      [value]="searchTerm"
      (keyup)="search($event)"/>
    <div class="combobox-buttons" #controlsElement>
      <button class="link-button"
              data-cy="combobox-clear-button"
              type="button"
              [class.hidden]="selected.length === 0"
              #deleteButtonElement
              (click)="clearAll()"
              [attr.aria-label]="'SIS_COMPONENTS.SELECT.CLEAR_FIELD' | translate">
        <sis-icon icon="close"></sis-icon>
      </button>
      <span *ngIf="predictive"
            #searchIconElement
            [attr.aria-label]="'SIS_COMPONENTS.SELECT.SEARCH' | translate">
      <sis-icon icon="search"></sis-icon>
    </span>
      <button class="link-button"
              data-cy="combobox-toggle-button"
              type="button"
              tabindex="-1"
              #menuButtonElement
              *ngIf="!predictive"
              (mouseup)="toggleMenu()"
              [attr.aria-label]="this.showDropdown ? ('ARIA_LABEL.CLOSE' | translate) : ('ARIA_LABEL.EXPAND' | translate)">
        <sis-icon icon="chevron" [iconClass]="showDropdown && onFocus ? 'ccw-90' : 'cw-90'"></sis-icon>
      </button>
    </div>
    <div class="dropdown"
         #dropdownElement
         data-cy="combobox-menu"
         role="list"
         [id]="'combobox-menu-' + id"
         *ngIf="showDropdown && onFocus && (!predictive || predictive && nonGroupedOptions.length > 0 || hasGroupedOptions())">
        <sis-checkbox data-cy="combobox-option"
                      *ngFor="let option of nonGroupedOptions"
                      [attr.id]="'sis-combobox-' + id + '-option-' + option.indexId"
                      [ariaRole]="'option'"
                      [name]="option.label"
                      [label]="option.label"
                      [secondaryLabel]="option.secondaryLabel"
                      [title]="option.label"
                      [checked]="isChecked(option)"
                      (checkedChange)="select(option)"
                      (keydown)="handleMenuKeyDown($event, option.indexId)"></sis-checkbox>
        <ng-container *ngFor="let header of headersToLoop(); let last = last; let i = index">
          <div [attr.aria-labelledby]="'sis-combobox-' + id + '-header-' + i"
               [attr.role]="'group'">
            <div
              class="option-header"
              role="presentation"
              [attr.aria-hidden]="true"
              [attr.id]="'sis-combobox-' + id + '-header-' + i">
              {{ groupedOptions[header].headerLabel }}
            </div>
              <sis-checkbox data-cy="combobox-option"
                            *ngFor="let option of groupedOptions[header].options; let i = index"
                            [attr.id]="'sis-combobox-' + id + '-option-' + option.indexId"
                            [ariaRole]="'option'"
                            [ariaPosinset]="i + 1"
                            [ariaSetsize]="groupedOptions[header].options.length"
                            [name]="option.label"
                            [label]="option.label"
                            [secondaryLabel]="option.secondaryLabel"
                            [title]="option.label"
                            [checked]="isChecked(option)"
                            (checkedChange)="select(option)"
                            (keydown)="handleMenuKeyDown($event, option.indexId)"></sis-checkbox>
            <hr *ngIf="!last" />
          </div>
        </ng-container>
      <ng-container *ngIf="showNoResults()">
        <span disabled> {{ 'SEARCH.FILTER_SELECTOR.NO_RESULTS' | translate }} </span>
      </ng-container>
    </div>
  </div>
  <p *ngIf="helpBlock" class="help-block">{{helpBlock | translate}}</p>
</fieldset>
