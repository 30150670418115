import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { OpenUniversityCart } from 'common-typescript/types';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-cart-check-selections',
    templateUrl: './cart-check-selections.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CartCheckSelectionsComponent {

    @Input() cart: OpenUniversityCart;
    @Input() checkEnrolments$: Subject<void>;
    @Output() hasValidEnrolments = new EventEmitter<void>();
}
