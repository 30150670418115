<ng-container *transloco="let t">
  <div class='sis-mb-sm' *ngFor="let error of ruleErrors">
    <ng-container *ngIf="error.errorType === 'LESS_SELECTIONS_REQUIRED'">
      <sis-contextual-notification [id]="error.errorId"
                                   [variant]="'danger'">
        {{ t('PLAN_EDIT.SELECTION_MODAL.RULE_ERRORS.LESS_SELECTIONS_REQUIRED') }}
      </sis-contextual-notification>
    </ng-container>
  </div>
</ng-container>


