import { inject } from '@angular/core';
import { CourseUnit, CourseUnitRealisation, EnrolmentData, EnrolmentDataChange, SisTranslationKeyAndParams } from 'common-typescript/types';
import { LocaleService } from 'sis-common/l10n/locale.service';

export const enrolmentDataChangeDescriptionFetcher = () => {
    const localeService = inject(LocaleService);
    return (enrolmentDataChange: EnrolmentDataChange, enrolmentData: EnrolmentData) => getEnrolmentChangeDescription(enrolmentDataChange, enrolmentData, localeService);
};

export const getEnrolmentChangeDescription = (enrolmentDataChange: EnrolmentDataChange, enrolmentData: EnrolmentData, localeService: LocaleService): SisTranslationKeyAndParams => {
    if (!enrolmentData) {
        return undefined;
    }
    const description: SisTranslationKeyAndParams = {
        translationKey: 'ENROLMENT_DATA_CHANGE.NOTIFICATION.DESCRIPTION',
        translationParams: {
            name: getLocalizedName(enrolmentData.courseUnit, enrolmentData.courseUnitRealisation, localeService),
        },
    };
    if (enrolmentDataChange.newState !== enrolmentDataChange.previousState) {
        switch (enrolmentDataChange.newState) {
            case 'REJECTED':
                description.translationKey = 'ENROLMENT_DATA_CHANGE.NOTIFICATION.REJECTED';
                break;
            case 'ENROLLED':
                description.translationKey = 'ENROLMENT_DATA_CHANGE.NOTIFICATION.ENROLLED';
                break;
            case 'INVALID':
                description.translationKey = 'ENROLMENT_DATA_CHANGE.NOTIFICATION.INVALID';
                break;
            case 'ABORTED_BY_TEACHER':
                description.translationKey = 'ENROLMENT_DATA_CHANGE.NOTIFICATION.ABORTED_BY_TEACHER';
                break;
            default:
            // Do nothing
        }
    }
    if (enrolmentDataChange.newState === 'PROCESSING' &&
        (enrolmentDataChange.newProcessingState !== enrolmentDataChange.previousProcessingState || enrolmentDataChange.newState !== enrolmentDataChange.previousState)) {

        switch (enrolmentDataChange.newProcessingState) {
            case 'CURRENTLY_SELECTED':
            case 'SELECTED':
                description.translationKey = 'ENROLMENT_DATA_CHANGE.NOTIFICATION.SELECTED';
                break;
            case 'CURRENTLY_NOT_SELECTED':
            case 'NOT_SELECTED':
                description.translationKey = 'ENROLMENT_DATA_CHANGE.NOTIFICATION.NOT_SELECTED';
                break;
            case 'REQ_NOT_FULFILLED':
                description.translationKey = 'ENROLMENT_DATA_CHANGE.NOTIFICATION.REQ_NOT_FULFILLED';
                break;
            case 'PENDING':
                description.translationKey = 'ENROLMENT_DATA_CHANGE.NOTIFICATION.PENDING';
                break;
            default:
            // Do nothing
        }
    }
    return description;
};

const getLocalizedName = (courseUnit: CourseUnit, courseUnitRealisation: CourseUnitRealisation, localeService: LocaleService) =>
    `${localeService.localize(courseUnit?.name)}, ${localeService.localize(courseUnitRealisation?.name)}`;
