import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Education, Plan, StudyRight } from 'common-typescript/types';
import { ModalService } from 'sis-common/modal/modal.service';

export interface ForceLearningOpportunityValues {
    myEducations: Education[];
    myStudyRights: StudyRight[];
    plans: Plan[];
}

@Component({
    selector: 'app-force-learning-opportunity-modal',
    template: '<force-learning-opportunity-modal (close)="activeModal.close()" (dismiss)="activeModal.dismiss()" [resolve]="values"></force-learning-opportunity-modal>',
    encapsulation: ViewEncapsulation.None,
})
export class ForceLearningOpportunityModalComponent {
    constructor(@Inject(ModalService.injectionToken) public values: ForceLearningOpportunityValues,
                public activeModal: NgbActiveModal) {}
}
