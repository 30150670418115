import { Injectable } from '@angular/core';
import { TranslationMarkupRendererFactory } from 'ngx-transloco-markup';

import { HtmlElementTranspiler } from '../common/html-element-transpiler';

export const MARK_START = new (class MarkStart {})();
export const MARK_END = new (class MarkEnd {})();

@Injectable()
export class MarkTranspiler extends HtmlElementTranspiler {

    constructor(readonly rendererFactory: TranslationMarkupRendererFactory) {
        super({
            rendererFactory,
            start: {
                search: '<mark>',
                token: MARK_START,
            },
            end: {
                search: '</mark>',
                token: MARK_END,
            },
            elementTag: 'mark',
        });
    }
}
