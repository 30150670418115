import { Pipe, PipeTransform } from '@angular/core';
import { AssessmentItem, CourseUnit, LocalizedString } from 'common-typescript/types';
import { AssessmentItemNameService } from 'sis-common/name/assessment-item-name.service';

@Pipe({
    name: 'assessmentItemNameInCourseUnitContext',
})
export class AssessmentItemNameInCourseUnitContextPipe implements PipeTransform {

    constructor(private assessmentItemNameService: AssessmentItemNameService) {
    }

    transform(value: AssessmentItem, courseUnit: CourseUnit): LocalizedString {
        if (!value) {
            return null;
        }
        if (value.primaryCourseUnitGroupId === courseUnit?.groupId) {
            return value.name;
        }
        return this.assessmentItemNameService.generateFullName(value);
    }
}
