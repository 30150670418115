import { Injectable } from '@angular/core';
import { Attainment, CourseUnitSelection, OtmId, Plan } from 'common-typescript/types';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { isCourseUnitAttainment } from 'sis-components/attainment/AttainmentUtil';
import { StudentAttainmentService } from 'sis-components/service/student-attainment.service';

@Injectable({ providedIn: 'root' })
export class PlanCourseUnitSelectionService {

    constructor(private readonly _studentAttainmentService: StudentAttainmentService) {}

    /**
     * Returns course unit selections of {@link plan} (in the original order),
     * that haven't been attained or substituted.
     */
    getCourseUnitSelectionsRequiringTiming(plan: Plan): Observable<CourseUnitSelection[]> {
        if (!plan.courseUnitSelections?.length) {
            return of([]);
        }

        return this.getAttainedCourseUnitIds(plan.userId).pipe(
            map((attainedCourseUnitIds: Set<OtmId>) => plan
                .courseUnitSelections
                .filter((courseUnitSelection: CourseUnitSelection) => !courseUnitSelection.substitutedBy?.length
                        && !attainedCourseUnitIds.has(courseUnitSelection.courseUnitId)),
            ),
        );
    }

    private getAttainedCourseUnitIds(personId: OtmId): Observable<Set<OtmId>> {
        return this._studentAttainmentService.findValidAttainmentsByPersonId(personId).pipe(
            map((validAttainments: Attainment[]) => {
                const attainedCourseUnitIds = new Set<OtmId>();

                for (const validAttainment of validAttainments) {
                    if (isCourseUnitAttainment(validAttainment) && !!validAttainment.courseUnitId) {
                        attainedCourseUnitIds.add(validAttainment.courseUnitId);
                    }
                }

                return attainedCourseUnitIds;
            }),
        );
    }
}
