'use strict';

(function () {
  angular.module('sis-components.util.edit-mode', []).directive('ngAutofocus', focus);

  /**
   * @ngInject
   */
  function focus() {
    return {
      link: function (scope, element, attrs) {
        scope.$watch(attrs.ngAutofocus, () => {
          element[0].focus();
        });
      }
    };
  }
})();