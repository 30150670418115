<ng-container *transloco="let t">
  <h3>{{ t('ATTAINMENTS.ATTAINMENT_DETAILS.HEADINGS.SECONDARY_ATTAINMENTS') }}</h3>
  <sis-expandable *ngFor="let secondaryAttainment of secondaryAttainments"
                  [level]="4"
                  [variant]="'naked-dotted'"
                  [title]="secondaryAttainment.attainmentDate | localDateFormat">
    <ng-template sisExpandableCustomTitle>
      ({{ t('ATTAINMENTS.ATTAINMENT_DETAILS.GRADE') }} {{secondaryAttainment.gradeId | grade:secondaryAttainment.gradeScaleId:'ABBREVIATION' | async}})
    </ng-template>
    <ng-template sisExpandableContent>
      <dl>
        <div class="form-group-mimic" role="listitem">
          <dt>{{t('ATTAINMENTS.ATTAINMENT_DETAILS.GRADE')}}</dt>
          <dd>
            {{ secondaryAttainment.gradeId | grade:secondaryAttainment.gradeScaleId:'ABBREVIATION' | async }}
            ({{ secondaryAttainment.gradeId | grade:secondaryAttainment.gradeScaleId:'NAME' | async }})
          </dd>
        </div>

        <div class="form-group-mimic" role="listitem">
          <dt>{{t('ATTAINMENTS.ATTAINMENT_DETAILS.CREDITS_LABEL')}}</dt>
          <dd>
            {{ secondaryAttainment.credits }} {{ t('CREDITS') }} <ng-container *ngIf="secondaryAttainment.studyWeeks"> ({{secondaryAttainment.studyWeeks}} {{ t('ATTAINMENTS.ATTAINMENT_DETAILS.STUDY_WEEKS_ABBR') }}) </ng-container>
          </dd>
        </div>

        <div class="form-group-mimic" role="listitem">
          <dt>{{t('ATTAINMENTS.ATTAINMENT_DETAILS.ATTAINMENT_DATE')}}</dt>
          <dd>{{ secondaryAttainment.attainmentDate | localDateFormat }}</dd>
        </div>

        <div class="form-group-mimic" role="listitem">
          <dt>{{t('ATTAINMENTS.ATTAINMENT_DETAILS.EXPIRY_DATE')}}</dt>
          <dd *ngIf="secondaryAttainment.expiryDate">{{ secondaryAttainment.expiryDate | localDateFormat }}</dd>
          <dd *ngIf="!secondaryAttainment.expiryDate">{{ t('ATTAINMENTS.ATTAINMENT_DETAILS.NO_EXPIRY_DATE') }}</dd>
        </div>

        <div sis-additional-info-details class="form-group-mimic full-width" role="listitem" [data]="secondaryAttainment.additionalInfo"></div>

        <div class="form-group-mimic" role="listitem">
          <dt> {{ t('ATTAINMENTS.ATTAINMENT_DETAILS.ACCEPTOR_PERSONS') }} </dt>
          <ng-container *ngIf="secondaryAttainment.acceptorPersons?.length > 0 else emptyField">
            <ng-container  *ngFor="let acceptor of secondaryAttainment.acceptorPersons">
              <dd *ngIf="acceptor.personId">{{ acceptor.personId | publicPerson | async | personFullNameReversed }}</dd>
              <dd *ngIf="!acceptor.personId">{{ acceptor.text | localizedString}}</dd>
            </ng-container>
          </ng-container>
        </div>
      </dl>
    </ng-template>
  </sis-expandable>
</ng-container>
<ng-template #emptyField>
  <dd> - </dd>
</ng-template>
