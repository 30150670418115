import angular from 'angular';
import { localeServiceModule } from './localeService';
export const localizedStringFilterModule = 'sis-common.l10n.localizedStringFilter';
(function () {
  singleDecimal.$inject = ["$locale"];
  localizedStringArray.$inject = ["localeService"];
  localizedString.$inject = ["localeService"];
  localeNames.$inject = ["localeService"];
  angular.module(localizedStringFilterModule, [localeServiceModule]).filter('localeNames', localeNames).filter('localizedString', localizedString).filter('localizedStringArray', localizedStringArray).filter('singleDecimal', singleDecimal);

  /**
   * @ngInject
   */
  function localeNames(localeService) {
    return function (input) {
      return localeService.getLocaleLocalizedNames(input);
    };
  }

  /**
   * @ngInject
   */
  function localizedString(localeService) {
    return function (input, localeOverride) {
      return localeService.getLocalizedValue(input, localeOverride);
    };
  }

  /**
   * @ngInject
   */
  function localizedStringArray(localeService) {
    return function (input) {
      return localeService.getLocalizedArrayValue(input);
    };
  }

  /**
   * @ngInject
   */
  function singleDecimal($locale) {
    return function (input) {
      const formats = $locale.NUMBER_FORMATS;
      if (isNaN(input)) {
        return input;
      }
      const decimalSep = formats.DECIMAL_SEP;
      const k = Math.round(input * 10);
      const p = k % 10;
      const w = (k - p) / 10;
      if (p) {
        return `${w}${decimalSep}${p}`;
      }
      return `${w}`;
    };
  }
})();