import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { dateUtils } from 'common-typescript';
import { ServiceBreak, UniversityConfig } from 'common-typescript/types';
import * as _ from 'lodash';
import moment, { Moment } from 'moment';
import { EMPTY, interval, Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { catchError, startWith, switchMap } from 'rxjs/operators';
import { ConfigService } from 'sis-common/config/config.service';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

import { Alert, AlertsService, AlertType } from '../alerts/alerts-ng.service';

/**
 * Provides the regular and critical service break data from service break API
 */
@Injectable({
    providedIn: 'root',
})
@StaticMembers<DowngradedService>()
export class ServiceBreakService implements OnDestroy {
    static downgrade: ServiceDowngradeMappings = {
        moduleName: 'sis-components.service.serviceBreakService',
        serviceName: 'serviceBreakService',
    };

    // Url path for getting the breaks.json served by nginx
    readonly breaksJsonLocation = '/unavailable/api/breaks';
    readonly criticalBreakJsonLocation = '/unavailable/api/break';
    private readonly updateInterval = Subscription.EMPTY;
    private readonly criticalBreakUpdateInterval = Subscription.EMPTY;
    private readonly langChange = Subscription.EMPTY;
    private readonly unavailableDataUrl: string;
    private readonly criticalUnavailableDataUrl: string;
    private readonly updateIntervalMS: number;
    private criticalAlertIds: string[];
    private serviceBreaks: ServiceBreak[];
    private criticalServiceBreak: ServiceBreak;
    private currentBreaksSource = new ReplaySubject<ServiceBreak[]>(1);
    private criticalBreakSource = new ReplaySubject<ServiceBreak>(1);
    private pollIntervalMinutes = 5; // 5 minutes by default
    private lastBreaksUpdateMoment: number;
    private lastCriticalBreakUpdateMoment: number;

    constructor(private alertsService: AlertsService, private http: HttpClient, private configService: ConfigService,
                private translateService: TranslateService) {
        const universityConfig = this.configService.get() as UniversityConfig;
        if (universityConfig.serviceBreakUpdateIntervalMinutes) {
            this.pollIntervalMinutes = universityConfig.serviceBreakUpdateIntervalMinutes;
        }
        if (universityConfig.unavailableDataUrl) {
            this.unavailableDataUrl = universityConfig.unavailableDataUrl;
        } else {
            this.unavailableDataUrl = this.breaksJsonLocation;
        }
        if (universityConfig.criticalUnavailableDataUrl) {
            this.criticalUnavailableDataUrl = universityConfig.criticalUnavailableDataUrl;
        } else {
            this.criticalUnavailableDataUrl = this.criticalBreakJsonLocation;
        }
        this.updateIntervalMS = this.getPollIntervalMinutes() * 60000; // milliseconds in X minutes
        this.updateInterval = this.createBreaksInterval(this.updateIntervalMS);
        this.criticalBreakUpdateInterval = this.createCriticalBreakInterval(this.updateIntervalMS);

        this.langChange = this.translateService.onLangChange
            .subscribe((event: LangChangeEvent) => {
                this.refresh();
            });
    }

    ngOnDestroy(): void {
        this.langChange.unsubscribe();
        this.updateInterval.unsubscribe();
        this.criticalBreakUpdateInterval.unsubscribe();
    }

    private createBreaksInterval(updateIntervalMS: number) {
        return interval(updateIntervalMS).pipe(
            startWith(0),
            switchMap(() =>
                this.loadServiceBreaksData().pipe(
                    catchError(error => {
                        // The system error dialog is intentionally blocked, but something should be logged just in case bad things did happen
                        let errorMessage;
                        if (error instanceof HttpErrorResponse) {
                            errorMessage = error.message;
                        } else {
                            errorMessage = JSON.stringify(error);
                        }
                        console.warn(`Could not load service breaks data. ${errorMessage}`);
                        return EMPTY;
                    }),
                ),
            ))
            .subscribe({
                next: (breaks) => {
                    const filtered = this.filterServiceBreaksByOrigin(breaks, this.getHostOrigin());
                    filtered.forEach(this.setDefaults);
                    this.updateServiceBreaks(filtered);
                },
                error: (error) => {
                    console.warn(`Could not load service breaks data. ${error.message ? error.message : JSON.stringify(error)}`);
                },
            });
    }

    private createCriticalBreakInterval(updateIntervalMS: number) {
        return interval(updateIntervalMS).pipe(
            startWith(0),
            switchMap(() =>
                this.loadCriticalServiceBreakData().pipe(
                    catchError(error => {
                        // break API is specified to return 404 if there is no critical break to show
                        if (error.status === 404) {
                            return of(null);
                        }

                        // The system error dialog is intentionally blocked, but something should be logged just in case bad things did happen
                        let errorMessage;
                        if (error instanceof HttpErrorResponse) {
                            errorMessage = error.message;
                        } else {
                            errorMessage = JSON.stringify(error);
                        }
                        console.warn(`Could not load critical service break data. ${errorMessage}`);
                        return EMPTY;
                    }),
                ),
            ))
            .subscribe({
                next: (criticalBreak: ServiceBreak) => {
                    this.updateCriticalServiceBreak((criticalBreak ? this.setCriticalDefaults(criticalBreak) : null));
                },
                error: (error) => {
                    console.warn(`Could not load critical service break data. ${error.message ? error.message : JSON.stringify(error)}`);
                },
            });
    }

    /**
     * Refresh the service break info that should be currently displayed.
     */
    public refresh(): void {
        this.updateServiceBreaks(this.getServiceBreaks());
        this.updateCriticalServiceBreak(this.getCriticalServiceBreak());
    }

    /**
     * Get the host origin (url) to filter the service breaks according to their affect.
     *
     * @return The origin url used for the services.
     */
    getHostOrigin(): string {
        return window.location.origin;
    }

    /**
     * Subscribe to this to listen updates in current service breaks data in general.
     *
     * @return Observable of the current service breaks.
     */
    getObservableOfCurrentServiceBreaks(): Observable<ServiceBreak[]> {
        return this.currentBreaksSource.asObservable();
    }

    /**
     * Subscribe to this for listening updates in current critical service breaks data.
     *
     * @return Observable of the critical service breaks.
     */
    getObservableOfCriticalServiceBreak(): Observable<ServiceBreak> {
        return this.criticalBreakSource.asObservable();
    }

    getPollIntervalMinutes(): number {
        return this.pollIntervalMinutes;
    }

    private dismissAlerts(ids: string[]) {
        let filtered: string[];
        if (ids) {
            filtered = ids.filter((id) => id != null);
        }

        _.forEach(filtered, (id) => {
            this.alertsService.dismissAlertIfExists(id);
        });
    }

    /**
     * Set the service breaks data and update what is displayed. Fills in default values when needed.
     *
     * @param breaks The data for service breaks.
     */
    private updateServiceBreaks(breaks: ServiceBreak[]) {
        const lastUpdateMillis = this.lastBreaksUpdateMoment ? moment.now() - this.lastBreaksUpdateMoment : 100;
        if (lastUpdateMillis < 100) {
            // Prevent from firing updates too rapidly after previous one (happens occasionally)
            return;
        }
        setTimeout(() => {
            if (breaks) {
                this.serviceBreaks = breaks;
            } else {
                this.serviceBreaks = [];
            }
            this.currentBreaksSource.next(this.getActiveServiceBreaks());
        });
        this.lastBreaksUpdateMoment = moment.now();
    }

    /**
     * Set the critical service break data and set critical alert when needed. Fills in default values when needed.
     *
     * @param criticalBreak The data for critical service break.
     */
    private updateCriticalServiceBreak(criticalBreak: ServiceBreak) {
        const lastUpdateMillis = this.lastCriticalBreakUpdateMoment ? moment.now() - this.lastCriticalBreakUpdateMoment : 100;
        if (lastUpdateMillis < 100) {
            // Prevent from firing updates too rapidly after previous one (happens occasionally)
            return;
        }
        const alertsToRemove = this.criticalAlertIds; // ids of critical alerts that are (plausibly) visible at the moment
        this.criticalAlertIds = [];
        setTimeout(() => {
            if (criticalBreak && this.isCriticalServiceBreakActive(criticalBreak, moment())) {
                this.criticalServiceBreak = criticalBreak;
                this.criticalBreakSource.next(criticalBreak);
                const alertMessage = this.getServiceInfoText(criticalBreak);
                const alert: Alert = {
                    type: AlertType.DANGER,
                    message: alertMessage,
                    identifier: `serviceBreak_${this.stringToHashCode(criticalBreak.serviceType + criticalBreak.serviceTime.startDateTime + criticalBreak.serviceTime.endDateTime)}`,
                };
                this.alertsService.addAlert(alert);
                this.criticalAlertIds.push(alert.identifier);
                _.remove(alertsToRemove, key => key === alert.identifier);
            }
            this.dismissAlerts(alertsToRemove);
        });
        this.lastCriticalBreakUpdateMoment = moment.now();
    }

    /**
     * Sets default values for a service break object when it has some required data missing.
     *
     * @param serviceBreak The object to set default values for.
     * @return The modified object.
     */
    private setDefaults(serviceBreak: ServiceBreak): ServiceBreak {
        if (!serviceBreak.serviceType) {
            serviceBreak.serviceType = 'MAINTENANCE';
        }
        return serviceBreak;
    }

    /**
     * Sets default values for a critical service break object when it has some or all data missing
     *
     * @param serviceBreak The object to set default values for.
     * @return The modified object.
     */
    private setCriticalDefaults(serviceBreak: ServiceBreak): ServiceBreak {
        if (!serviceBreak.serviceType) {
            serviceBreak.serviceType = 'INCIDENT';
        }
        if (!serviceBreak.serviceTime) {
            serviceBreak.serviceTime = { startDateTime: null, endDateTime: null };
        }

        if (!serviceBreak.criticalAlert) {
            serviceBreak.criticalAlert = 'PT30M';
        }

        return serviceBreak;
    }

    /**
     * @return The host to load service breaks data from.
     */
    getUnavailableDataUrl(): string {
        return this.unavailableDataUrl;
    }

    /**
     * @return The host to load critical service break data from.
     */
    getCriticalUnavailableDataUrl(): string {
        return this.criticalUnavailableDataUrl;
    }

    /**
     * @return The frequently updating data about service breaks. Empty array if data is not yet loaded.
     */
    getServiceBreaks(): ServiceBreak[] {
        if (this.serviceBreaks) {
            return this.serviceBreaks;
        }
        return [];
    }

    /**
     * @return The frequently updating data about current critical service break. Null if data is not yet loaded.
     */
    getCriticalServiceBreak(): ServiceBreak {
        if (this.criticalServiceBreak) {
            return this.criticalServiceBreak;
        }
        return null;
    }

    /**
     * Filters the given set of service breaks by their affected origin.
     *
     * @param breaks The list of service breaks to filter the ones having origin as one of their affected origins.
     * @param origin The origin url of the host used (e.g. 'https://sis-demo.funidata.fi'). If not defined, returns all.
     */
    filterServiceBreaksByOrigin(breaks: ServiceBreak[], origin?: string): ServiceBreak[] {
        if (breaks) {
            if (!origin) {
                return breaks;
            }
            return _.filter(breaks, ((serviceBreak: ServiceBreak) =>
                (_.isEmpty(serviceBreak.affectedOrigins) || _.includes(serviceBreak.affectedOrigins, origin))));
        }
        return [];
    }

    /**
     * Get all the service breaks which displayTime is ongoing.
     *
     * @return A list of service break objects. May be empty if none has its displayTime active.
     */
    private getActiveServiceBreaks(): ServiceBreak[] {
        return this.getActiveServiceBreaksOfDate(this.getServiceBreaks(), new Date());
    }

    /**
     * Get all the service breaks which displayTime contains the given moment.
     *
     * @param breaks The service breaks to get the critical ones from.
     * @param now The current date and time which should be contained by each breaks displayTime.
     * @return A list of service break objects. May be empty if none has its displayTime active.
     */
    getActiveServiceBreaksOfDate(breaks: ServiceBreak[], now: Date): ServiceBreak[] {
        if (!now) {
            return [];
        }

        return _.filter(breaks, ((serviceBreak: ServiceBreak) => {
            const displayStart = _.get(serviceBreak, 'displayTime.startDateTime', false);
            const displayEnd = _.get(serviceBreak, 'displayTime.endDateTime', false);
            const serviceStart = _.get(serviceBreak, 'serviceTime.startDateTime', false);
            const serviceEnd = _.get(serviceBreak, 'serviceTime.endDateTime', false);

            if (!displayStart && !displayEnd) {
                // Do not show notification at all
                return false;
            }

            if (displayStart && displayEnd) {
                return (now >= new Date(displayStart) && now < new Date(displayEnd));
            }
            if (displayStart && serviceEnd) {
                // Show notification until we can assume the break should be ended
                // case: No need to show notification after the service ends
                return (now > new Date(displayStart)) && now < new Date(serviceEnd);
            }
            if (displayStart && serviceStart) {
                // There was no known end time, so only until we know the break should begin
                // (frontend should be unavailable anyway)
                // case: The break is ongoing end the end times are plausibly updated later
                return (now > new Date(displayStart)) && now < new Date(serviceStart);
            }
            if (serviceEnd && displayEnd) {
                // There was no known start time for displaying the notification, but we known when we should not notify about it anymore
                // case: There was some sort of event and this is post notification
                return (now > new Date(serviceEnd)) && now < new Date(displayEnd);
            }
            if (serviceStart && displayEnd) {
                // There was no known start time for displaying the notification, but we known when we should not notify about it anymore
                // case: There was some sort of event and this is post notification, but the break is still ongoing and we have no known end time
                return (now > new Date(serviceStart)) && now < new Date(displayEnd);
            }
            return false;
        }));
    }

    /**
     * Verifies whether breaks 'criticalAlert' time is "active": Either ongoing or sooner than the perdiod specified by its criticalAlert -field. Critical alerts are by default displayed 30 minutes before their given startTimes.
     *
     * If the break has no serviceTime or serviceTime.startTime, display it. In some cases the critical break API might return an empty object, which indicates an ongoing incident and should result in alert being displayed to users.
     *
     * If the serviceTime has started, critical alert should be displayed as long as it is returned from the critical break API, ignoring its serviceTime.endTime.
     *
     * @param criticalBreak The service break to check.
     * @param now The current moment time.
     * return true if break has its critical period ongoing.
     */
    isCriticalServiceBreakActive(criticalBreak: ServiceBreak, now: Moment): boolean {
        if (!now || !criticalBreak) {
            return false;
        }

        if (!criticalBreak.serviceTime || !criticalBreak.serviceTime.startDateTime) {
            return true;
        }

        const serviceStart = moment(criticalBreak.serviceTime?.startDateTime, moment.ISO_8601);
        if (!serviceStart.isValid() || !criticalBreak.criticalAlert) {
            return false;
        }

        let criticalAlert = moment.duration(criticalBreak.criticalAlert);
        if (criticalAlert.asMilliseconds() < 1) {
            // If the syntax was off, default to 30 minutes
            criticalAlert = moment.duration('PT30M');
        }

        const criticalStart = serviceStart.subtract(criticalAlert);

        return now.isSameOrAfter(criticalStart);
    }

    /**
     * Loads service breaks data from API specified by unavailableDataUrl, which can be configured in universitySettings.
     *
     * @return Observable of the ServiceBreaks data.
     */
    private loadServiceBreaksData(): Observable<ServiceBreak[]> {
        return this.http.get<ServiceBreak[]>(this.getUnavailableDataUrl());
    }

    /**
     * Loads next upcoming or ongoing critical service break data from API specified by criticalUnavailableDataUrl, which can be configured in universitySettings.
     *
     * @return Observable of the ServiceBreak data.
     */
    private loadCriticalServiceBreakData(): Observable<ServiceBreak> {
        return this.http.get<ServiceBreak>(this.getCriticalUnavailableDataUrl());
    }

    /**
     * Returns `true` if the given service break serviceTime.endTime has passed. If break `serviceTime.endTime` is not
     * set, `false` is returned. This method should not be used when determining the visibility alerts for critical
     * service breaks: Those should remain visible until the critical break API stops returning break data.
     *
     * @param serviceBreak The service break to check
     * @param now The current moment of time. Leaving undefined will create a new date for checking the current time as needed.
     */
    hasServiceBreakPassed(serviceBreak: ServiceBreak, now?: Date): boolean {
        const currentDate = now || new Date();
        return serviceBreak.serviceTime && serviceBreak.serviceTime.endDateTime && currentDate > new Date(serviceBreak.serviceTime.endDateTime);
    }

    /**
     * Builds a localized info text about the service break, based on its type and time period of occurrence.
     * The 'service upgrade' and 'incident'-breaks have different text to show after the end of the service time has passed.
     * The 'degraded performance' notification contains no duration information since it is irrelevant when the issue
     * has started, and it is unknown when it will be fixed. Also, when it's fixed the notification should not been shown
     * anymore.
     *
     * @param serviceBreak The service break to get the info text about.
     * @param now The current moment of time. Leaving undefined will create a new date for checking the current time as needed.
     * @return Localized info text about the service break.
     */
    getServiceInfoText(serviceBreak: ServiceBreak, now?: Date): string {
        const textKey = `UNAVAILABLE.${serviceBreak.serviceType}`;
        if (serviceBreak.serviceType === 'DEGRADED_PERFORMANCE') {
            return this.translateService.instant(textKey);
        }
        if ((serviceBreak.serviceType === 'SERVICE_UPGRADE' || serviceBreak.serviceType === 'INCIDENT')
            && this.hasServiceBreakPassed(serviceBreak, now)) {
            return this.translateService.instant(`${textKey}_AFTER`);
        }
        return this.translateService.instant(textKey) + this.getServicePeriodText(serviceBreak);
    }

    /**
     * Gets a proper time period info text to show for the service break.
     * Cases: Start and end both know, only start known, only end known, neither known.
     *
     * @param serviceBreak The service break to get the time period info.
     * @return Time period info text about the service break.
     */
    getServicePeriodText(serviceBreak: ServiceBreak): string {
        let text;
        if (serviceBreak.serviceTime?.startDateTime && serviceBreak.serviceTime?.endDateTime) {
            const start = dateUtils.extractDateAndTime(serviceBreak.serviceTime.startDateTime);
            const startDate = dateUtils.convertIsoLocalDateToDisplayFormat(start.date);
            const startTime = dateUtils.convertIsoLocalTimeToDisplayFormat(start.time);
            const end = dateUtils.extractDateAndTime(serviceBreak.serviceTime.endDateTime);
            const endDate = dateUtils.convertIsoLocalDateToDisplayFormat(end.date);
            const endTime = dateUtils.convertIsoLocalTimeToDisplayFormat(end.time);
            text = this.translateService.instant('UNAVAILABLE.PERIOD_KNOWN', { startDate, startTime, endDate, endTime });
        } else if (serviceBreak.serviceTime?.startDateTime) {
            const start = dateUtils.extractDateAndTime(serviceBreak.serviceTime.startDateTime);
            const startDate = dateUtils.convertIsoLocalDateToDisplayFormat(start.date);
            const startTime = dateUtils.convertIsoLocalTimeToDisplayFormat(start.time);
            text = this.translateService.instant('UNAVAILABLE.PERIOD_START_KNOWN', { startDate, startTime });
        } else if (serviceBreak.serviceTime?.endDateTime) {
            const end = dateUtils.extractDateAndTime(serviceBreak.serviceTime.endDateTime);
            const endDate = dateUtils.convertIsoLocalDateToDisplayFormat(end.date);
            const endTime = dateUtils.convertIsoLocalTimeToDisplayFormat(end.time);
            text = this.translateService.instant('UNAVAILABLE.PERIOD_END_KNOWN', { endDate, endTime });
        } else {
            text = this.translateService.instant('UNAVAILABLE.PERIOD_UNKNOWN');
        }
        return text;
    }

    stringToHashCode(text: string): number {
        let hash = 0;
        const textLength = text.length;
        let i = 0;
        if (textLength > 0) {
            while (i < textLength) {
                // eslint-disable-next-line no-bitwise
                hash = (hash << 5) - hash + text.charCodeAt(i) | 0;
                i += 1;
            }
        }
        return hash;
    }
}
