import { Injectable } from '@angular/core';
import { ValidatablePlan } from 'common-typescript/src/plan/validation/validatablePlan';
import { Attainment, CourseUnit, CourseUnitSubstitution, OtmId } from 'common-typescript/types';
import _ from 'lodash';
import { AttainmentEntityService } from 'sis-components/service/attainment-entity.service';
import { UniversityService } from 'sis-components/service/university.service';

import { CourseUnitSubstitutionWithLocalId } from '../course-unit-info-modal-substitution-correspondences/course-unit-info-modal-substitution-correspondences.component';

import { SubstitutionSelectOption } from './course-unit-info-modal-substitutions-correspondences-select-form.component';

@Injectable({
    providedIn: 'root',
})
export class SubstitutionSelectOptionsService {

    constructor(private attainmentEntityService: AttainmentEntityService, private universityService: UniversityService) {
    }

    getSubstitutionSelectOptions(
        substitutionOptions: CourseUnitSubstitutionWithLocalId[],
        attainmentsByCourseUnitGroupId: { [key: OtmId]: Attainment },
        allAttainments: Attainment[],
        validatablePlan: ValidatablePlan,
        substituteCourseUnitsByGroupId: { [key: OtmId]: CourseUnit },
        isEditable: boolean,
        versionChangeInProgress: boolean): SubstitutionSelectOption[] {
        return substitutionOptions?.map((substitutionOption) => {
            const isAttainmentAlreadyAttached = this.attainmentAlreadyAttached(substitutionOption.substitution, attainmentsByCourseUnitGroupId, allAttainments);
            const isSubstituteCourseUnitAlreadyInPlan = this.substituteCourseUnitAlreadyInPlan(substitutionOption.substitution, validatablePlan);
            const optionCourseUnits: CourseUnit[] = substitutionOption.substitution.map(courseUnitSubstitution => substituteCourseUnitsByGroupId[courseUnitSubstitution.courseUnitGroupId]);
            const belongsToCurrentUniversity = _.every(optionCourseUnits, optionCourseUnit => optionCourseUnit && optionCourseUnit.universityOrgIds.includes(this.universityService.getCurrentUniversityOrgId()));
            return {
                ...substitutionOption,
                isSelectable: isEditable && !versionChangeInProgress && !isAttainmentAlreadyAttached && !isSubstituteCourseUnitAlreadyInPlan,
                isAttainmentAlreadyAttached,
                isSubstituteCourseUnitAlreadyInPlan,
                belongsToCurrentUniversity,
                isGroup: substitutionOption.substitution.length > 1,
            } as SubstitutionSelectOption;
        }).filter(option => option.belongsToCurrentUniversity);
    }

    private attainmentAlreadyAttached(substitutions: CourseUnitSubstitution[], attainmentsByCourseUnitGroupId: { [key: OtmId]: Attainment }, allAttainments: Attainment[]) {
        return _.some(substitutions, (courseUnitSubstitution) => {
            const attainment = _.get(attainmentsByCourseUnitGroupId, courseUnitSubstitution.courseUnitGroupId);
            return !!attainment && this.attainmentEntityService.isAttached(attainment, allAttainments);
        });
    }

    private substituteCourseUnitAlreadyInPlan(substitutions: CourseUnitSubstitution[], validatablePlan: ValidatablePlan) {
        return _.some(substitutions, (courseUnitSubstitution) => {
            const courseUnitInPlan = validatablePlan.getCourseUnitInPlanByGroupId(courseUnitSubstitution.courseUnitGroupId);
            return !!courseUnitInPlan && !validatablePlan.isCourseUnitInPlanAsSubstitute(courseUnitInPlan);
        });
    }

}
