import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '@uirouter/angular';
import {
    Attachment,
    EntityMetadata,
    OtmId,
    StudentApplication,
    StudentWorkflow,
    Workflow,
    WorkflowApplication,
} from 'common-typescript/types';
import { finalize, mergeMap, Observable, of, switchMap, tap } from 'rxjs';
import { COMMON_STUDENT_APPLICATION_SERVICE } from 'sis-components/ajs-upgraded-modules';
import { AlertsService, AlertType } from 'sis-components/alerts/alerts-ng.service';
import { ApplicationAttachmentUploadService } from 'sis-components/file-upload/application-attachment-upload.service';
import { FileItem } from 'sis-components/file-upload/file-upload.component';
import { WorkflowEntityService } from 'sis-components/service/workflow-entity.service';

@Injectable({ providedIn: 'root' })
export class ApplicationCreationService {

    constructor(
        private alertsService: AlertsService,
        private applicationAttachmentUploadService: ApplicationAttachmentUploadService,
        private state: StateService,
        private translate: TranslateService,
        private workflowEntityService: WorkflowEntityService,
        @Inject(COMMON_STUDENT_APPLICATION_SERVICE) private commonStudentApplicationService: any,
        @Inject(DOCUMENT) private document: Document,
    ) { }

    /**
     * Saves the given application, uploads the given application attachments (if any), and takes care or displaying/dismissing
     * any relevant alerts.
     *
     * @param application The application to be created
     * @param attachments The attachments related to the application
     * @param successState The name of the router state into which a transition should be made in case the application creation succeeds
     */
    async createApplication<T extends StudentApplication>(
        application: Partial<T>,
        attachments?: FileItem[],
        successState?: string,
    ): Promise<T> {
        const savedApplication: T = await this.commonStudentApplicationService.create(application);

        if (attachments?.length > 0) {
            this.alertsService.addAlert({
                type: AlertType.WARNING,
                message: this.translate.instant('FILE_UPLOAD.SENDING'),
                identifier: savedApplication.id,
            });
            await this.applicationAttachmentUploadService
                .uploadApplicationAttachments(savedApplication.id, attachments, savedApplication.studentId);
            this.alertsService.dismissAlert(savedApplication.id);
        }

        this.alertsService.addAlert({
            type: AlertType.SUCCESS,
            message: this.translate.instant('PROFILE.APPLICATIONS.APPLICATION_SENT'),
        });

        if (successState) {
            this.document.defaultView?.scrollTo(0, 0);
            this.state.go(successState, { applicationId: savedApplication.id }, { custom: { skipConfirmationDialog: true } });
        }

        return savedApplication;
    }

    createWorkflowApplication<T extends WorkflowApplication>(
        workflowApplication: Partial<T>,
        attachments?: FileItem[],
        successState?: string,
    ): Observable<StudentWorkflow> {
        return this.workflowEntityService.createAsStudent(workflowApplication)
            .pipe(
                switchMap((savedStudentWorkflow) => {
                    if (attachments?.length > 0) {
                        this.alertsService.addAlert({
                            type: AlertType.WARNING,
                            message: this.translate.instant('FILE_UPLOAD.SENDING'),
                            identifier: savedStudentWorkflow.id,
                        });
                        return this.applicationAttachmentUploadService.uploadApplicationAttachmentsObservable(savedStudentWorkflow.id, attachments, savedStudentWorkflow.studentId)
                            .pipe(
                                finalize(() => {
                                    this.alertsService.dismissAlert(savedStudentWorkflow.id);
                                    this.alertsService.addAlert({
                                        type: AlertType.SUCCESS,
                                        message: this.translate.instant('PROFILE.APPLICATIONS.APPLICATION_SENT'),
                                    });
                                    if (successState) {
                                        this.document.defaultView?.scrollTo(0, 0);
                                        this.state.go(successState, { applicationId: savedStudentWorkflow.id }, { custom: { skipConfirmationDialog: true } });
                                    }
                                }),
                                switchMap(() => of(savedStudentWorkflow)),
                            );
                    }
                    this.alertsService.addAlert({
                        type: AlertType.SUCCESS,
                        message: this.translate.instant('PROFILE.APPLICATIONS.APPLICATION_SENT'),
                    });
                    if (successState) {
                        this.document.defaultView?.scrollTo(0, 0);
                        this.state.go(successState, { applicationId: savedStudentWorkflow.id }, { custom: { skipConfirmationDialog: true } });
                    }
                    return of(savedStudentWorkflow);
                }),
            );
    }

    supplementWorkflowApplication<T extends WorkflowApplication>(
        workflowId: OtmId,
        studentId: OtmId,
        workflowApplication: Partial<T>,
        allFileItems: FileItem[],
        initialAttachments?: Attachment[],
        metadata?: EntityMetadata,
    ): Observable<Workflow> {
        const [addedFileItems, currentAttachments, uploadRequired] = this.resolveUploadableFiles(allFileItems, initialAttachments);
        let saveAttachments$ = of({});
        if (uploadRequired) {
            this.alertsService.addAlert({
                type: AlertType.WARNING,
                message: this.translate.instant('FILE_UPLOAD.SENDING'),
                identifier: workflowId,
            });
            saveAttachments$ = this.applicationAttachmentUploadService
                .uploadApplicationAttachmentsObservable(workflowId, addedFileItems, studentId, metadata, currentAttachments)
                .pipe(
                    finalize(() => this.alertsService.dismissAlert(workflowId)),
                );
        }
        return saveAttachments$.pipe(
            mergeMap(() => this.workflowEntityService.supplementWorkflowApplication(workflowId, workflowApplication)),
            tap(() => this.alertsService.addAlert({
                type: AlertType.SUCCESS,
                message: this.translate.instant('PROFILE.APPLICATIONS.APPLICATION_SENT'),
            })),
        );
    }

    /**
     * @param allFileItems all file items associated with the application - new, initial, and initial with modified description
     * @param initialAttachments a pristine list of initial attachments.
     * this is used to determine whether any initial attachments have been removed (no localId match in allFileItems) or if its description has been modified
     *
     * @return tuple of:
     * 0: list of added file items
     * 1: list of attachments with a possibly updated description. if an initial attachment was removed, it is no longer present here
     * 2: boolean indicating if an application attachment upload is required.
     * */
    resolveUploadableFiles(allFileItems: FileItem[], initialAttachments: Attachment[]): [FileItem[], Attachment[], boolean] {
        let anyDescriptionHasChanged = false;

        const newItems: FileItem[] = allFileItems.filter((attachment: FileItem) => !attachment?.localId);

        const currentAttachments: Attachment[] = allFileItems
            .filter((fileItem: FileItem) => fileItem.localId)
            .map((fileItem: FileItem) => {
                const existingAttachment = initialAttachments.find((attachment: Attachment) => fileItem.localId === attachment.localId);
                if (existingAttachment?.comment !== fileItem.explanation) {
                    anyDescriptionHasChanged = true;
                    return { ...existingAttachment, comment: fileItem.explanation };
                }
                return existingAttachment;
            });
        const uploadRequired = newItems?.length > 0 || anyDescriptionHasChanged || initialAttachments?.length !== currentAttachments?.length;
        return [newItems, currentAttachments, uploadRequired];
    }
}

