/**
 * ToggleBox directive
 *
 * Simple directive to toggle a panels visibility. Nothing else.
 * Title is always shown, and body and footer are hidden.
 *
 */
(function () {
  angular.module('sis-components.toggleBox.toggleBox', []).directive('toggleBox', toggleBox);

  /**
   * @ngInject
   */
  function toggleBox() {
    return {
      restrict: 'E',
      transclude: {
        title: 'toggleTitle',
        body: '?toggleBody',
        footer: '?toggleFooter'
      },
      scope: true,
      template: '<div class="panel panel-default">' + '<div ng-transclude="title" class="panel-heading" ng-click="showBox = !showBox"></div>' + '<div ng-transclude="body" class="panel-body ng-hide" ng-show="showBox && !hideBody"></div>' + '<div ng-transclude="footer" class="list-group ng-hide" ng-show="showBox" ></div>' + '</div>',
      link: function (scope, elem, attr) {
        scope.showBox = false;
        scope.hideBody = !!attr.hideBody;
      }
    };
  }
})();