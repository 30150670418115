<div class="modal-header" data-cy="info-modal">
  <h2 class="modal-title" translate>{{values.title}}</h2>
  <button type="button"
          class="modal-close"
          (click)="modalInstance.close()"
          [attr.aria-label]="'ARIA_LABEL.CLOSE' | translate">
    <sis-icon icon="ring-close"></sis-icon>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-12">
      <sis-markup-string-view *ngFor="let description of values.descriptions" [content]="description | translate"></sis-markup-string-view>
      <sis-show-more-list *ngIf="values.list"
                          [description]="values.list.description"
                          [items]="values.list.items"
                          [showingAllItemsText]="values.list.showingAllItemsText">
      </sis-show-more-list>
    </div>
  </div>
</div>
