import _ from 'lodash';
import angular from 'angular';
import { localeServiceModule } from 'sis-common/l10n/localeService';
import { commonOrganisationServiceModule } from '../service/organisation.service';
export const jsDataFilteringSearchModule = 'sis-components.search.jsDataFilteringSearch';
(function () {
  organisationFilter.$inject = ["$log", "localeService", "commonOrganisationService"];
  jsDataFilterSearch.$inject = ["localeService"];
  angular.module(jsDataFilteringSearchModule, [localeServiceModule, commonOrganisationServiceModule]).directive('jsDataFilterSearch', jsDataFilterSearch).directive('organisationFilter', organisationFilter).directive('jsDataFilterResults', jsDataFilterResults);

  /**
   * @ngInject
   */
  function organisationFilter($log, localeService, commonOrganisationService) {
    return {
      scope: {
        model: '=ngModel'
      },
      require: ['^^form', 'ngModel'],
      link: function (scope, elem, attrs, ctrls) {
        var form = ctrls[0];
        var ngModel = ctrls[1];
        var filterData = function (filterString) {
          var currentLang = localeService.getCurrentLocale();
          form.filterResults = [{
            name: {}
          }];
          if (!filterString || filterString.length < 3) {
            form.filterResults[0].name[currentLang] = "Type min 3 chars to search."; //TODO: Localisation
          } else {
            var query = {
              name: filterString
            };
            commonOrganisationService.findAll(query, true).then(function (data) {
              data = _.sortBy(data, 'name.' + currentLang);
              form.filterResults = data;
            }, function (err) {
              form.filterResults[0].name[currentLang] = "System error";
              $log.error("findAll failed with organisationModel", err);
            });
          }
        };
        scope.$watch('model', function () {
          if (ngModel.$viewValue) {
            if (ngModel.$dirty && ngModel.$valid) {
              filterData(ngModel.$modelValue);
            }
          } else {
            form.filterResults = [];
          }
        });
      }
    };
  }

  /**
   * @ngInject
   */
  function jsDataFilterSearch(localeService) {
    return {
      scope: {
        model: '=ngModel',
        jsDataModel: '=jsDataFilterSearch',
        plainTextFilter: '=plainTextFilter'
      },
      require: ['^^form', 'ngModel'],
      link: function (scope, elem, attrs, ctrls) {
        var form = ctrls[0];
        var ngModel = ctrls[1];
        var plainText = scope.plainTextFilter && scope.plainTextFilter === true;
        var filterData = function (filterString) {
          var query;
          if (plainText) {
            query = filterString;
          } else {
            var currentLang = localeService.getCurrentLocale();
            query = {
              where: {}
            };
            query.where['name.' + currentLang] = {
              'likei': '%' + filterString + '%'
            };
          }
          var filterResult = scope.jsDataModel.filter(query);
          if (filterResult && filterResult.then) {
            filterResult.then(function (result) {
              form.filterResults = result;
            });
          } else {
            form.filterResults = filterResult;
          }
        };
        scope.$watch('model', function () {
          if (ngModel.$viewValue) {
            if (ngModel.$dirty && ngModel.$valid) {
              filterData(ngModel.$modelValue);
            }
          } else {
            form.filterResults = [];
          }
        });
      }
    };
  }

  /**
   * @ngInject
   */
  function jsDataFilterResults() {
    return {
      scope: true,
      require: ['^^form', '^^searchAndResults'],
      link: function (scope, elem, attrs, ctrls) {
        scope.formCtrl = ctrls[0];
        scope.searchAndResultsCtrl = ctrls[1];
        scope.anyResults = function () {
          return !!(scope.filterResults && scope.filterResults.lenght > 0);
        };
      }
    };
  }
})();