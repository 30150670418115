import angular from 'angular';
import _ from 'lodash';
import { commonUniversityServiceModule } from 'sis-common/university/university.service.ts';
import { localizedStringFilterModule } from 'sis-common/l10n/localizedStringFilter';
import { ContextualNotificationComponent } from 'sis-components/contextual-notification/contextual-notification.component.ts';
import { ChoosePlanComponent } from './choose-plan/choose-plan.component.ts';
import courseUnitsNotInCalendarTpl from './courseUnitsNotInCalendar.tpl.html';
export const courseUnitsNotInCalendarModule = 'student.calendar.courseUnitsNotInCalendar';
(function () {
  courseUnitsNotInCalendarController.$inject = ["$q", "$state", "defaultPromiseHandler", "colorService", "commonStudyPeriodService", "courseUnitInfoModal", "planSeeker", "universityService"];
  angular.module(courseUnitsNotInCalendarModule, ['ui.router', commonUniversityServiceModule, localizedStringFilterModule, 'sis-components.courseUnit', 'sis-components.service.studyPeriodService', 'sis-common.errorhandler.errorService', 'student.common.filters.studyPeriod', ChoosePlanComponent.downgrade.moduleName, ContextualNotificationComponent.downgrade.moduleName, 'student.common.components.courseUnitInfoModal', 'student.common.components.planSeeker', 'sis-components.service.colorService', 'sis-components.service.enrolmentService']).component('courseUnitsNotInCalendar', {
    template: courseUnitsNotInCalendarTpl,
    bindings: {
      validatablePlan: '<',
      plans: '<',
      notScheduledCourseUnits: '<'
    },
    controller: courseUnitsNotInCalendarController
  });

  /**
   * @ngInject
   */
  function courseUnitsNotInCalendarController($q, $state, defaultPromiseHandler, colorService, commonStudyPeriodService, courseUnitInfoModal, planSeeker, universityService) {
    const $ctrl = this;
    $ctrl.studyYearsByStartYear = {};
    $ctrl.$onInit = function () {
      const courseUnitsByPeriods = _.groupBy($ctrl.notScheduledCourseUnits, courseUnit => {
        if ($ctrl.validatablePlan) {
          return _.head($ctrl.validatablePlan.getPlannedPeriods(courseUnit));
        }
        return undefined;
      });
      $ctrl.setStudyYearsByStartYear(_.keys(courseUnitsByPeriods));
      $ctrl.notScheduledCourseUnitsByPeriod = {};
      _.chain(courseUnitsByPeriods).keys().sortBy().forEach(key => {
        $ctrl.notScheduledCourseUnitsByPeriod[key] = courseUnitsByPeriods[key];
      }).value();
      $q.all(_.chain($ctrl.notScheduledCourseUnitsByPeriod).keys().compact().uniq().value().map(periodId => {
        if (periodId !== 'undefined') {
          return commonStudyPeriodService.getPeriodsFor(periodId);
        }
      })).then(studyPeriods => {
        $ctrl.studyPeriods = _.keyBy(studyPeriods, 'locator');
      }, defaultPromiseHandler.log);
    };
    $ctrl.onPlanChange = function (planId) {
      // Need to reload the state in order to update the validatablePlan which is resolved in a parent router state,
      // and used in several children states. A proper fix would require rewriting the frontend plan handling logic.
      planSeeker.storeSelectedPlanId(planId);
      $state.reload();
    };
    $ctrl.setStudyYearsByStartYear = function (studyPeriodLocators) {
      const parsedLocators = _.chain(studyPeriodLocators).uniq().filter(locator => locator !== 'undefined').map(commonStudyPeriodService.parseStudyPeriodLocator).value();
      if (!_.isEmpty(parsedLocators)) {
        const organisationId = universityService.getCurrentUniversityOrgId();
        const years = _.map(parsedLocators, 'year');
        const firstYear = _.min(years);
        const numOfYears = _.max(years) - firstYear + 1;
        commonStudyPeriodService.getStudyYears(organisationId, firstYear, numOfYears).then(studyYears => {
          $ctrl.studyYearsByStartYear = _.keyBy(studyYears, 'startYear');
        });
      }
    };
    $ctrl.getStudyPeriodNameByPeriodId = function (periodId) {
      return _.get($ctrl.studyPeriods, `[${periodId}].name`);
    };
    $ctrl.getStudyYearNameByPeriodId = function (periodId) {
      const year = _.get(commonStudyPeriodService.parseStudyPeriodLocator(periodId), 'year');
      return _.get($ctrl.studyYearsByStartYear, `[${year}].name`);
    };
    $ctrl.openCourseUnitModal = function (courseUnit) {
      const colorCategory = colorService.getCourseUnitColorCategory($ctrl.validatablePlan, courseUnit.id);
      courseUnitInfoModal.showCompletionMethodsForCourseUnit(courseUnit.id, $ctrl.validatablePlan, `cu-color-${colorCategory}`);
    };
  }
})();