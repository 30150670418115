import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CustomAttainmentApplication, CustomAttainmentWorkflowApplication } from 'common-typescript/types';
import { AlertsService } from 'sis-components/alerts/alerts-ng.service';
import { FileItem } from 'sis-components/file-upload/file-upload.component';
import { required } from 'sis-components/form/form-validators';
import { getLabelState } from 'sis-components/form/formUtils';
import { SisFormBuilder } from 'sis-components/form/sis-form-builder.service';

@Component({
    selector: 'app-caa-confirmation',
    templateUrl: './caa-confirmation.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CaaConfirmationComponent implements OnInit {

    @Input() application: Partial<CustomAttainmentApplication | CustomAttainmentWorkflowApplication>;
    @Input() attachments: FileItem[];
    @Input() disableButtons = false;
    @Output() exit = new EventEmitter<void>();
    @Output() previous = new EventEmitter<void>();
    @Output() submit = new EventEmitter<Partial<CustomAttainmentApplication>>();

    mustPrintDecision: FormControl;

    getLabelState = getLabelState;

    constructor(
        private alertsService: AlertsService,
        private fb: SisFormBuilder,
    ) {}

    ngOnInit(): void {
        this.mustPrintDecision = this.fb.control(this.application?.mustPrintDecision ?? null, required());
    }

    onSubmit(): void {
        if (this.mustPrintDecision.valid) {
            this.submit.emit({ mustPrintDecision: this.mustPrintDecision.value });
        } else {
            this.mustPrintDecision.markAsTouched();
            this.alertsService.addFormSubmissionFailureAlert();
        }
    }
}
