import { Pipe, PipeTransform } from '@angular/core';
import { dateUtils } from 'common-typescript';
import moment from 'moment';

@Pipe({ name: 'localDateTimeFormat' })
export class LocalDateTimeFormatPipe implements PipeTransform {
    transform(value: string | Date | moment.Moment): any {
        return dateUtils.formatDateIfValid(value, 'l LT');
    }
}
