import angular from 'angular';
import { localizedMarkupStringFilterModule } from 'sis-components/string/localizedMarkupString.filter';
import { IconComponent } from '../icon/icon.component.ts';
import { PriorLearningListComponent } from './prior-learning-list/prior-learning-list.component.ts';
import studentApplicationPrintTpl from './studentApplicationPrint.tpl.html';
angular.module('sis-components.applications.studentApplicationPrint', ['pascalprecht.translate', 'sis-components.applications.customApplicationAttainmentSummary', 'sis-components.model.applicationDecision', 'sis-components.service.studentApplicationService', PriorLearningListComponent.downgrade.moduleName, IconComponent.downgrade.moduleName, localizedMarkupStringFilterModule]).component('studentApplicationPrint', {
  template: studentApplicationPrintTpl,
  bindings: {
    applicationId: '<'
  },
  controller: ["$window", "$document", "$timeout", "commonStudentApplicationService", "applicationDecisionJSDataModel", "STUDENT_APPLICATION_STATE", "STUDENT_APPLICATION_TYPE", function ($window, $document, $timeout, commonStudentApplicationService, applicationDecisionJSDataModel, STUDENT_APPLICATION_STATE, STUDENT_APPLICATION_TYPE) {
    const $ctrl = this;
    $ctrl.print = function () {
      $window.print();
    };
    $ctrl.$onInit = function () {
      $ctrl.loadStudentApplication().then(() => {
        $timeout($ctrl.print);
      });
    };
    $ctrl.goBack = function () {
      if ($window.history) {
        $window.history.back();
      }
    };
    $ctrl.loadStudentApplication = function () {
      return commonStudentApplicationService.findById($ctrl.applicationId, {
        bypassCache: true
      }).then(application => {
        if (application.decision) {
          return loadApplicationDecision(application);
        }
        return application;
      }).then(application => {
        $ctrl.application = application;
        $ctrl.hasDecision = !!application.decision;
        $ctrl.inclusionApplicationInstruction = _.get(application, 'degreeProgramme.inclusionApplicationInstruction', _.get(application, 'courseUnit.inclusionApplicationInstruction'));
        return application;
      });
    };
    function loadApplicationDecision(application) {
      return applicationDecisionJSDataModel.loadRelations(application.decision).then(() => application);
    }
    $ctrl.isPriorLearningApplication = function () {
      return _.includes([STUDENT_APPLICATION_TYPE.PRIOR_LEARNING_SUBSTITUTION_APPLICATION, STUDENT_APPLICATION_TYPE.PRIOR_LEARNING_INCLUSION_APPLICATION], _.get($ctrl, 'application.type'));
    };
    $ctrl.isCustomAttainmentApplication = function () {
      return _.get($ctrl, 'application.type') === STUDENT_APPLICATION_TYPE.CUSTOM_ATTAINMENT_APPLICATION;
    };
    $ctrl.getStatusBadgeColor = function () {
      switch (_.get($ctrl, 'application.state')) {
        case STUDENT_APPLICATION_STATE.ACCEPTED:
          return 'success';
        case STUDENT_APPLICATION_STATE.REJECTED:
          return 'danger';
        default:
          return 'secondary';
      }
    };
  }]
});