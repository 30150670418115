import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Attainment, CompletionMethod, CourseUnit, CourseUnitRealisation, Module, Plan } from 'common-typescript/types';
/**
 * this is a proxy component for completionMethods.component.upgraded.ts
 * directly providing an upgraded component as an argument for a UI Router view does not work
 **/
@Component({
    selector: 'app-completion-methods-ng',
    templateUrl: './completion-methods-ng.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CompletionMethodsNgComponent {
    @Input() plan: Plan;
    @Input() attainments: Attainment[];
    @Input() courseUnit: CourseUnit;
    @Input() completionMethods: CompletionMethod[];
    @Input() courseUnitRealisation: CourseUnitRealisation;
    @Input() forceEnableCalendarAdd: boolean;
    @Input() language: string;
    @Input() versionChangeInProgress: boolean;
    @Input() unplannedCourseUnit: CourseUnit;
    @Input() module: Module;
    @Input() realisationTabId: number;
}
