import { Injectable } from '@angular/core';
import {
    Education,
    EducationChildOption,
    EducationOption,
    EducationOptionAcceptanceType,
    EducationPhase,
    LearningOpportunity,
    LearningOpportunitySelectionPath,
    OtmId,
    PersonalizedPhase,
    StudyRight,
    Urn,
} from 'common-typescript/types';

import { LearningOpportunityAllowedPathService } from './learning-opportunity-allowed-path.service';

@Injectable({ providedIn: 'root' })
export class PreviewModelService {
    constructor(private readonly _learningOpportunityAllowedPathService: LearningOpportunityAllowedPathService) {}

    /**
     * Creates a preview model for the learning opportunity defined by the given education and study right.
     */
    getPreviewModelForLearningOpportunity(education: Education, studyRight: StudyRight): PreviewModel {
        const leftPhaseObject: { [index: string]: PreviewModelObject } = {};
        const rightPhaseObject: { [index: string]: PreviewModelObject } = {};
        const result: PreviewModel = <PreviewModel>{
            left: leftPhaseObject,
            right: rightPhaseObject,
        };

        if (!education.structure) {
            return result;
        }

        const learningOpportunity: LearningOpportunity | undefined = education.structure.learningOpportunities?.find(lo => lo.localId === studyRight.learningOpportunityId);

        this.handlePhase(
            studyRight,
            learningOpportunity,
            education.structure.phase1,
            studyRight.personalizedSelectionPath?.phase1,
            'educationPhase1GroupId',
            'educationPhase1ChildGroupId',
            leftPhaseObject,
        );
        this.handlePhase(
            studyRight,
            learningOpportunity,
            education.structure.phase2,
            studyRight.personalizedSelectionPath?.phase2,
            'educationPhase2GroupId',
            'educationPhase2ChildGroupId',
            rightPhaseObject,
        );

        return result;
    }

    private handlePhase(
        studyRight: StudyRight,
        learningOpportunity: LearningOpportunity | undefined,
        phase: EducationPhase | null,
        personalizedPhase: PersonalizedPhase | null | undefined,
        educationPathKey: keyof LearningOpportunitySelectionPath,
        childOptionPathKey: keyof LearningOpportunitySelectionPath,
        phaseObject: { [index: string]: PreviewModelObject },
    ): void {
        if (!phase) {
            return;
        }

        let index: number = 1;

        if (personalizedPhase) {
            const childOption: NecessaryEducationChildOptionProperties | null = generateChildOption(personalizedPhase);
            const option: NecessaryEducationOptionProperties = generateMainOption(personalizedPhase, childOption);

            phaseObject[index] = createPreviewModelObject(option, studyRight, index, educationPathKey, childOptionPathKey);
            index += 1;
            if (childOption) {
                phaseObject[index] = createPreviewModelChildObject(childOption, option, studyRight, index, educationPathKey, childOptionPathKey);
            }
        } else if (learningOpportunity?.allowedPaths?.length) {
            phase.options?.forEach(option => {
                if (this._learningOpportunityAllowedPathService.isModuleInLearningOpportunityAllowedPaths(learningOpportunity.allowedPaths, option.moduleGroupId, educationPathKey)) {
                    phaseObject[index] = createPreviewModelObject(option, studyRight, index, educationPathKey, childOptionPathKey);
                    index += 1;
                    option.childOptions?.forEach(childOption => {
                        if (this._learningOpportunityAllowedPathService.isModuleInLearningOpportunityAllowedPaths(learningOpportunity.allowedPaths, childOption.moduleGroupId, childOptionPathKey)) {
                            phaseObject[index] = createPreviewModelChildObject(childOption, option, studyRight, index, educationPathKey, childOptionPathKey);
                            index += 1;
                        }
                    });
                }
            });
        }
    }
}

export interface PreviewModelObject {
    readonly index: number;
    readonly size: 'small' | 'large';
    readonly educationPathKey: keyof LearningOpportunitySelectionPath;
    readonly moduleGroupId: OtmId | null;
    readonly parentModuleGroupId?: OtmId | null;
    readonly color: number;
    readonly matchesAcceptedSelectionPath: boolean;
    readonly acceptanceType: EducationOptionAcceptanceType | null;
    readonly childOptionNaming?: Urn | null;
    readonly childOptionPathKey?: keyof LearningOpportunitySelectionPath | null
}

export interface PreviewModel {
    readonly left: { readonly [index: string]: PreviewModelObject };
    readonly right: { readonly [index: string]: PreviewModelObject };
}

type NecessaryEducationOptionProperties = Partial<EducationOption> & {
    acceptanceType: EducationOptionAcceptanceType | null,
    childNaming: Urn | null,
    childOptions: EducationChildOption[] | null,
    moduleGroupId: OtmId,
};

type NecessaryEducationChildOptionProperties = Partial<EducationChildOption> & {
    acceptanceType: EducationOptionAcceptanceType | null,
    moduleGroupId: OtmId,
};

function generateChildOption(phase: PersonalizedPhase): NecessaryEducationChildOptionProperties | null {
    return phase.childModuleGroupId
        ? <NecessaryEducationChildOptionProperties>{
            acceptanceType: 'ACCEPTED_BY_TEACHER',
            moduleGroupId: phase.childModuleGroupId,
        }
        : null;
}

function generateMainOption(phase: PersonalizedPhase, childOption: NecessaryEducationChildOptionProperties | null): NecessaryEducationOptionProperties {
    return <NecessaryEducationOptionProperties>{
        acceptanceType: 'ACCEPTED_BY_TEACHER',
        childNaming: childOption ? phase.childNamingUrn : null,
        childOptions: childOption ? [childOption] : null,
        moduleGroupId: phase.moduleGroupId,
    };
}

function indexToColor(index: number): number {
    return (index - 1) % 11 + 1;
}

function createPreviewModelObject(
    option: NecessaryEducationOptionProperties,
    studyRight: StudyRight,
    index: number,
    educationPathKey: keyof LearningOpportunitySelectionPath,
    childOptionPathKey: keyof LearningOpportunitySelectionPath,
): PreviewModelObject {
    return <PreviewModelObject>{
        index,
        size: 'large',
        educationPathKey,
        moduleGroupId: option.moduleGroupId,
        color: indexToColor(index),
        matchesAcceptedSelectionPath: studyRight.acceptedSelectionPath[educationPathKey] === option.moduleGroupId,
        acceptanceType: option.acceptanceType,
        childOptionPathKey: option.childOptions?.length ? childOptionPathKey : null,
    };
}

function createPreviewModelChildObject(
    childOption: NecessaryEducationChildOptionProperties,
    option: NecessaryEducationOptionProperties,
    studyRight: StudyRight,
    index: number,
    parentOptionPathKey: keyof LearningOpportunitySelectionPath,
    childOptionPathKey: keyof LearningOpportunitySelectionPath,
): PreviewModelObject {
    return <PreviewModelObject>{
        index,
        size: 'small',
        educationPathKey: childOptionPathKey,
        parentModuleGroupId: option.moduleGroupId,
        moduleGroupId: childOption.moduleGroupId,
        color: indexToColor(index),
        childOptionNaming: option.childNaming,
        matchesAcceptedSelectionPath: childOptionMatchesAcceptedSelectionPath(
            childOption,
            option,
            studyRight,
            parentOptionPathKey,
            childOptionPathKey,
        ),
        acceptanceType: childOption.acceptanceType,
    };
}

function childOptionMatchesAcceptedSelectionPath(
    childOption: NecessaryEducationChildOptionProperties,
    option: NecessaryEducationOptionProperties,
    studyRight: StudyRight,
    parentOptionPathKey: keyof LearningOpportunitySelectionPath,
    childOptionPathKey: keyof LearningOpportunitySelectionPath,
) {
    const parentOptionMatches: boolean = studyRight.acceptedSelectionPath[parentOptionPathKey] === option.moduleGroupId;
    const childOptionMatches: boolean = studyRight.acceptedSelectionPath[childOptionPathKey] === childOption.moduleGroupId;
    return parentOptionMatches && childOptionMatches;
}
