import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { numberUtils } from 'common-typescript';
import { CreditRange } from 'common-typescript/types';
import _ from 'lodash';

export type CreditRangeLongPipeVariant = 'NORMAL' | 'ATTAINED';

/**
 * Transforms a CreditRange object to a string. Has two variants, NORMAL and ATTAINED. They differ in how translations. Translations of the
 * former speak possible future attainment while the latter speaks of attained credits.
 *
 * The NORMAL and ATTAINED variants are equivalent with the creditRangeLong and creditRangeLongAttained filters in AngularJS, respectively.
 */
@Pipe({ name: 'creditRangeLong' })
export class CreditRangeLongPipe implements PipeTransform {
    normalKeys: CreditRangeLongTranslationKeys = {
        onlyMin: 'SIS_COMPONENTS.NUMBER.CREDIT_RANGE_FILTER.CREDITS_MIN_DESCRIPTION',
        onlyMax: 'SIS_COMPONENTS.NUMBER.CREDIT_RANGE_FILTER.CREDITS_MAX_DESCRIPTION',
        minMaxEqual: 'SIS_COMPONENTS.NUMBER.CREDIT_RANGE_FILTER.CREDITS_ONE_DESCRIPTION',
        minMaxNotEqual: 'SIS_COMPONENTS.NUMBER.CREDIT_RANGE_FILTER.CREDITS_RANGE_DESCRIPTION',
    };

    attainedKeys: CreditRangeLongTranslationKeys = {
        onlyMin: 'SIS_COMPONENTS.NUMBER.CREDIT_RANGE_FILTER.CREDITS_MIN_DESCRIPTION_ATTAINED',
        onlyMax: 'SIS_COMPONENTS.NUMBER.CREDIT_RANGE_FILTER.CREDITS_MAX_DESCRIPTION_ATTAINED',
        minMaxEqual: 'SIS_COMPONENTS.NUMBER.CREDIT_RANGE_FILTER.CREDITS_ONE_DESCRIPTION_ATTAINED',
        minMaxNotEqual: 'SIS_COMPONENTS.NUMBER.CREDIT_RANGE_FILTER.CREDITS_RANGE_DESCRIPTION_ATTAINED',
    };

    constructor(private translateService: TranslateService) {
    }

    transform(credits: CreditRange, variant: CreditRangeLongPipeVariant = 'NORMAL'): any {
        if (_.isNil(credits) || (!_.isFinite(credits.min) && !_.isFinite(credits.max))) {
            return '';
        }
        const keys: CreditRangeLongTranslationKeys = variant === 'NORMAL' ? this.normalKeys : this.attainedKeys;

        const minValue = numberUtils.numberToString(credits.min);
        const maxValue = numberUtils.numberToString(credits.max);

        if (!minValue) {
            if (!maxValue) {
                return '';
            }
            return this.translateService.instant(keys.onlyMax, { value: maxValue });

        }
        if (!maxValue) {
            return this.translateService.instant(keys.onlyMin, { value: minValue });
        }
        if (minValue === maxValue) {
            return this.translateService.instant(keys.minMaxEqual, { value: minValue });
        }
        return this.translateService.instant(keys.minMaxNotEqual, { min: minValue, max: maxValue });
    }
}

class CreditRangeLongTranslationKeys {
    onlyMin: string;
    onlyMax: string;
    minMaxEqual: string;
    minMaxNotEqual: string;
}
