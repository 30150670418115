<ng-container *ngIf="enrolment?.state">
  <sis-tiny-badge *ngIf="enrolment.state === 'PROCESSING'"
                  variant="accent">
    {{'ENROLMENTS.OPEN_UNIVERSITY_ENROLMENT.ENROLMENT_PROCESSING' | translate}}
  </sis-tiny-badge>
  <sis-tiny-badge *ngIf="enrolment.state === 'ENROLLED'"
                  variant="success">
    {{'ENROLMENTS.OPEN_UNIVERSITY_ENROLMENT.ENROLMENT_ACCEPTED' | translate}}
  </sis-tiny-badge>
  <sis-tiny-badge *ngIf="enrolment.state === 'REJECTED'"
                  variant="danger">
    {{'ENROLMENTS.OPEN_UNIVERSITY_ENROLMENT.ENROLMENT_REJECTED' | translate}}
  </sis-tiny-badge>
  <sis-tiny-badge *ngIf="enrolment.state === 'ABORTED_BY_STUDENT' || enrolment.state === 'ABORTED_BY_TEACHER'"
                  variant="danger">
    {{'ENROLMENTS.OPEN_UNIVERSITY_ENROLMENT.ENROLMENT_ABORTED' | translate}}
  </sis-tiny-badge>
</ng-container>
