import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { numberUtils } from 'common-typescript';
import {
    Attachment, ExternalAttainedStudyAttachments,
    ExternalAttainedStudyDocument,
    OtmId,
} from 'common-typescript/types';
import { Observable, Subject } from 'rxjs';
import { ModalService } from 'sis-common/modal/modal.service';
import { EditApplicationAttachmentsModalComponent } from 'sis-components/file-upload/edit-application-attachments-modal/edit-application-attachments-modal.component';
import { FileItem } from 'sis-components/file-upload/file-upload.component';

@Component({
    selector: 'app-application-attachments',
    templateUrl: './application-attachments.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ApplicationAttachmentsComponent {

    @Input() applicationId?: OtmId;
    @Input() initialAttachments: FileItem[] = [];
    @Input() externalAttainedStudyIds$: Observable<string[]>;

    @Output() updateAttachments = new EventEmitter<FileItem[]>();

    currentExternalAttachments: ExternalAttainedStudyAttachments[] = [];

    constructor(
        private modalService: ModalService,
    ) { }

    editAttachments(): void {
        const componentRef = this.modalService.open(
            EditApplicationAttachmentsModalComponent,
            {
                previouslyAddedFiles: this.initialAttachments,
                externalAttachments: this.currentExternalAttachments,
            },
            { size: 'sm' },
        );

        componentRef.result
            .then((data) => {
                this.initialAttachments = data;
                this.updateAttachments.emit(this.initialAttachments);
            })
            .catch(() => {}); // The modal was dismissed => do nothing
    }

    readableFileSizeString(number: number) {
        return numberUtils.readableFileSizeString(number);
    }

    getAttachments(document: ExternalAttainedStudyDocument): Attachment[] {
        return Object.values(document.attachments);
    }

    onExternalAttachmentsChange($event: ExternalAttainedStudyAttachments[]) {
        this.currentExternalAttachments = $event;
    }
}
