import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MOMENT_DATE_DISPLAY_FORMAT } from 'common-typescript/constants';
import { LocalDateRange } from 'common-typescript/types';
import moment from 'moment';

@Pipe({ name: 'salesPeriod' })
export class SalesPeriodPipe implements PipeTransform {

    constructor(private translate: TranslateService) {}

    transform(localDateRange: LocalDateRange): any {
        if (localDateRange && (localDateRange.startDate || localDateRange.endDate)) {
            let format: string;
            let startDate: moment.Moment;
            let endDate: moment.Moment;

            if (localDateRange.startDate) {
                startDate = moment(localDateRange.startDate, 'YYYY-MM-DD');
            }

            if (localDateRange.endDate) {
                // End date is always excluded in our date ranges, so display the end date correctly
                endDate = moment(localDateRange.endDate, 'YYYY-MM-DD').subtract(1, 'day');
            }

            if (startDate && endDate && startDate.isSame(endDate)) {
                format = 'ARE_SAME';
            } else if (startDate && endDate && startDate.isAfter(endDate)) {
                format = 'EMPTY';
            } else if (startDate && !endDate) {
                format = 'START_';
            } else if (!startDate && endDate) {
                format = '_END';
            } else {
                format = 'START_END';
            }

            return this.translate.instant(`SALES_PERIOD.${format}`, {
                start: startDate ? startDate.format(MOMENT_DATE_DISPLAY_FORMAT) : undefined,
                end: endDate ? endDate.format(MOMENT_DATE_DISPLAY_FORMAT) : undefined,
            });
        }
        return '';

    }
}
