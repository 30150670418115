import {
    AfterViewInit,
    Component, ElementRef,
    EventEmitter,
    Input,
    Output, ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { CustomStudyDraft } from 'common-typescript/types';

@Component({
    selector: 'app-custom-study-draft-info',
    templateUrl: './custom-study-draft-info.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CustomStudyDraftInfoComponent implements AfterViewInit {

    @Input() studyDraft: CustomStudyDraft;
    @Input() isSuggestButtonVisible: boolean;
    @Output() close = new EventEmitter<void>();
    @Output() suggestAttainment = new EventEmitter<void>();

    @ViewChild('headingElementRef') headingElementRef: ElementRef;

    ngAfterViewInit(): void {
        this.headingElementRef.nativeElement.focus();
    }

}
