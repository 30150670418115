import { ChangeDetectionStrategy, Component, inject, signal, ViewEncapsulation } from '@angular/core';
import { CourseUnitRealisation, OtmId } from 'common-typescript/types';
import { catchError, Observable, of } from 'rxjs';
import { SessionStorageService } from 'sis-common/storage/session-storage.service';

import { COURSE_UNIT_INFO_MODAL } from '../../../ajs-upgraded-modules';

import { StudyData, UpcomingStudiesService } from './upcoming-studies.service';

const COLLAPSED_KEY = 'student.frontpage.upcomingStudies.collapsed';

@Component({
    selector: 'app-upcoming-studies',
    templateUrl: './upcoming-studies.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpcomingStudiesComponent {

    private readonly sessionStorageService = inject(SessionStorageService);
    private readonly upcomingStudiesService = inject(UpcomingStudiesService);
    private readonly courseUnitInfoModal = inject(COURSE_UNIT_INFO_MODAL);

    readonly upcomingStudies$: Observable<StudyData[]> = this.upcomingStudiesService.getUpcomingStudies()
        .pipe(
            catchError(() => {
                this.searchFailed.set(true);
                return of([]);
            }),
        );

    readonly initiallyCollapsed = this.sessionStorageService.getValueOrDefault(COLLAPSED_KEY, true);

    readonly searchFailed = signal(false);

    openCourseUnitInfoModal(courseUnitId: OtmId, courseUnitRealisation: CourseUnitRealisation) {
        this.courseUnitInfoModal.showCourseUnitRealisationForCourseUnit(courseUnitId, courseUnitRealisation);
    }

    isCurCancelled(data: StudyData): boolean {
        return data.courseUnitRealisation?.flowState === 'CANCELLED';
    }

    onCollapsedChange(collapsed: boolean) {
        this.sessionStorageService.setValue(COLLAPSED_KEY, collapsed);
    }
}
