import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { OiliPayment, OtmId, PaymentCategory, StudentPayment } from 'common-typescript/types';
import { LocaleService } from 'sis-common/l10n/locale.service';

@Component({
    selector: 'app-oili-payments-table',
    templateUrl: './oili-payments-table.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class OiliPaymentsTableComponent implements OnInit {
    @Input() payments: OiliPayment[];
    @Input() caption: string;

    selectedDescriptionLanguage: string;

    constructor(private localeService: LocaleService) {}

    ngOnInit(): void {
        this.selectedDescriptionLanguage = this.localeService.getCurrentLanguage();
    }

    getDescriptionWithSelectedLanguage(category: PaymentCategory): string {
        return category?.description?.[this.selectedDescriptionLanguage] ?? '';
    }

    getStudyTermNameTranslationKey(payment: StudentPayment): string {
        if (!payment.studyTerm) {
            return 'STUDY_TERMS.AUTUMN_AND_SPRING';
        }
        if (payment.studyTerm.termIndex === 0) {
            return 'STUDY_TERMS.AUTUMN';
        }
        if (payment.studyTerm.termIndex === 1) {
            return 'STUDY_TERMS.SPRING';
        }
        return 'STUDY_TERMS.AUTUMN_AND_SPRING';
    }

}
