<ng-container *transloco="let t">
  <div class="row message-{{ message.isRead || isExpanded ? 'read': 'unread'}}">
    <sis-expandable
      [variant]="'naked'"
      [closed]="!isExpanded"
      [title]="messageTitle || messageLocalizedTitle ? (messageTitle ? messageTitle : '') + ' ' + (messageLocalizedTitle ? (messageLocalizedTitle | localizedString) : '') :
              (messageTypeTranslationKey ? t('' + messageTypeTranslationKey) : t('SIS_COMPONENTS.MESSENGER.MESSAGE_CONVERSATION.NO_TITLE'))"
      [attr.aria-label]="message.isRead ? '' : t('ARIA_LABEL.UNREAD_MESSAGE')"
      (closedChange)="toggleExpanded($event)"
      [size]="'md'">
      <ng-template sisExpandableCustomTitle>
        <sis-tiny-badge *ngIf="message.isArchived">
          <span> {{ t('SIS_COMPONENTS.MESSENGER.MESSAGE_CONVERSATION.ARCHIVED')}}</span>
        </sis-tiny-badge>

        <div class="message-additional-title">
          <span class="additional-title-message-date-time float-end">
            {{message.messageTime | localDateFormat }}
            {{t('TIME.WITH_PREFIX', { time: message.messageTime | localTimeFormat })}}
          </span>
          <span class="additional-title-name-type-group d-flex flex-row flex-wrap">
            <span class="additional-title-buffer">
              <span class="additional-title-sender-border"
                    [attr.aria-label]=" (t('ARIA_LABEL.SENDER') + ' ' + (message.type === 'AutomaticCourseUnitRealisationMessage' || message.type === 'EnrolmentReminderMessage' ? (messageSenderName | localizedString) : senderFullName))">
                {{ message.type === 'AutomaticCourseUnitRealisationMessage' || message.type === 'EnrolmentReminderMessage' ? (messageSenderName | localizedString) : senderFullName}}
                <span *ngIf="!hideSenderStudentNumber && message.senderStudentNumber">
                  ({{ message.senderStudentNumber }})</span>
              </span>
            </span>
            <span class="additional-title-buffer">
              <span class="additional-title-type-border"
                    [attr.aria-label]=" (t('ARIA_LABEL.MESSAGE_TYPE') + ' ' + t('' + messageTypeTranslationKey) + ' ' + (messageTargetName ? messageTargetName : ''))">
                {{ t('' + messageTypeTranslationKey) + ' ' + (messageTargetName ? messageTargetName : '')}}
              </span>
            </span>
          </span>
        </div>
      </ng-template>
      <ng-template sisExpandableContent>
        <div class="message-content">
          <div *ngIf="suggestionForAddition" class="suggestion-for-addition">
            <p><transloco key="SIS_COMPONENTS.MESSENGER.SUGGEST_ADD.SENT_BY_TUTOR" [params]="{name: senderFullName}"/></p>
            <div class="row box-container">
              <div class="col-6">
                <sis-course-unit-box *ngIf="messageCourseUnitId"
                                     [courseUnitId]="messageCourseUnitId">
                </sis-course-unit-box>
                <sis-module-box *ngIf="messageModuleId"
                                [moduleId]="messageModuleId">
                </sis-module-box>
              </div>
            </div>
            <p *ngIf="suggestedParentModule" class="parent-module-info">
              <transloco key="SIS_COMPONENTS.MESSENGER.SUGGEST_ADD.UNDER_MODULE" [params]="{ code: (suggestedParentModule.code || ''), name: (suggestedParentModule.name | localizedString)}"/>
            </p>
          </div>
          <div [class.form-group-mimic]="suggestionForAddition">
            <h3 *ngIf="suggestionForAddition" class="fudis-heading__sm">
              {{t('SIS_COMPONENTS.MESSENGER.SUGGEST_ADD.MESSAGE_FROM_TUTOR')}}:
            </h3>
            <div class="text-area-static sis-pb-sm">
              <span>{{ messageComment ? t(messageComment) : '' }}</span>
              <sis-localized-markup-string-view [content]="messageLocalizedComment"></sis-localized-markup-string-view>
            </div>
          </div>
          <div *ngIf="isApplicationMsg" class="button-container">
            <ng-container [ngSwitch]="messageOperationType">
              <ng-container *ngSwitchCase="'REQUESTED'">
                <ng-container *ngIf="message.type === messageTypes.PRIOR_LEARNING_INCLUSION_APPLICATION_MESSAGE || message.type === messageTypes.PRIOR_LEARNING_SUBSTITUTION_APPLICATION_MESSAGE"
                              [ngTemplateOutlet]="showPriorLearningMessage"></ng-container>
                <ng-container *ngIf="message.type === messageTypes.MODULE_ATTAINMENT_APPLICATION_MESSAGE"
                              [ngTemplateOutlet]="goToApplicationLink"
                              [ngTemplateOutletContext]="{translationKey: 'SHOW_MODULE_ATTAINMENT_MESSAGE'}"></ng-container>
                <ng-container *ngIf="message.type === messageTypes.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION_MESSAGE"
                              [ngTemplateOutlet]="goToApplicationLink"
                              [ngTemplateOutletContext]="{translationKey: 'SHOW_DEGREE_PROGRAMME_ATTAINMENT_MESSAGE'}"></ng-container>
                <ng-container *ngIf="message.type === messageTypes.CUSTOM_ATTAINMENT_APPLICATION_MESSAGE"
                              [ngTemplateOutlet]="goToApplicationLink"
                              [ngTemplateOutletContext]="{translationKey: 'SHOW_CUSTOM_ATTAINMENT_MESSAGE'}"></ng-container>
                <ng-container *ngIf="message.type === messageTypes.CUSTOM_MODULE_CONTENT_APPLICATION_MESSAGE || message.type === messageTypes.REQUIRED_MODULE_CONTENT_APPLICATION_MESSAGE"
                              [ngTemplateOutlet]="goToApplicationLink" [ngTemplateOutletContext]="{translationKey: 'SHOW_APPLICATION'}"></ng-container>
                <ng-container *ngIf="message.type === messageTypes.STUDY_RIGHT_EXTENSION_APPLICATION_MESSAGE"
                              [ngTemplateOutlet]="goToApplicationLink"
                              [ngTemplateOutletContext]="{translationKey: 'SHOW_STUDY_RIGHT_EXTENSION_MESSAGE'}"></ng-container>
              </ng-container>
              <div *ngSwitchCase="'CANCELLED'">
                <ng-container *ngIf="message.type === messageTypes.PRIOR_LEARNING_INCLUSION_APPLICATION_MESSAGE || message.type === messageTypes.PRIOR_LEARNING_SUBSTITUTION_APPLICATION_MESSAGE"
                              [ngTemplateOutlet]="goToApplicationLink"
                              [ngTemplateOutletContext]="{translationKey: 'SHOW_CANCELLED_PRIOR_LEARNING_MESSAGE'}"></ng-container>
                <ng-container *ngIf="message.type === messageTypes.MODULE_ATTAINMENT_APPLICATION_MESSAGE">
                  <ng-container [ngTemplateOutlet]="showInPlan"></ng-container>
                  <ng-container [ngTemplateOutlet]="goToApplicationLink"
                                [ngTemplateOutletContext]="{translationKey: 'SHOW_CANCELLED_MODULE_ATTAINMENT_MESSAGE'}"></ng-container>
                </ng-container>
                <ng-container *ngIf="message.type === messageTypes.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION_MESSAGE">
                  <ng-container [ngTemplateOutlet]="showInPlan"></ng-container>
                  <ng-container [ngTemplateOutlet]="goToApplicationLink"
                                [ngTemplateOutletContext]="{translationKey: 'SHOW_CANCELLED_DEGREE_PROGRAMME_ATTAINMENT_MESSAGE'}"></ng-container>
                </ng-container>
                <ng-container *ngIf="message.type === messageTypes.CUSTOM_MODULE_CONTENT_APPLICATION_MESSAGE || message.type === messageTypes.REQUIRED_MODULE_CONTENT_APPLICATION_MESSAGE"
                              [ngTemplateOutlet]="goToApplicationLink"
                              [ngTemplateOutletContext]="{translationKey: 'SHOW_CANCELLED_APPLICATION'}"></ng-container>
                <ng-container *ngIf="message.type === messageTypes.STUDY_RIGHT_EXTENSION_APPLICATION_MESSAGE"
                              [ngTemplateOutlet]="goToApplicationLink"
                              [ngTemplateOutletContext]="{translationKey: 'SHOW_CANCELLED_STUDY_RIGHT_EXTENSION_MESSAGE'}"></ng-container>
              </div>
              <ng-container *ngSwitchCase="'ACCEPTED'">
                <ng-container *ngIf="message.type !== messageTypes.CUSTOM_MODULE_CONTENT_APPLICATION_MESSAGE && message.type !== messageTypes.REQUIRED_MODULE_CONTENT_APPLICATION_MESSAGE && message.type !== messageTypes.STUDY_RIGHT_EXTENSION_APPLICATION_MESSAGE"
                              [ngTemplateOutlet]="openAttainmentLink" [ngTemplateOutletContext]="{translationKey: 'SHOW_ATTAINMENT'}"></ng-container>
                <ng-container *ngIf="message.type === messageTypes.PRIOR_LEARNING_INCLUSION_APPLICATION_MESSAGE || message.type === messageTypes.PRIOR_LEARNING_SUBSTITUTION_APPLICATION_MESSAGE"
                              [ngTemplateOutlet]="showDecision"></ng-container>
                <ng-container *ngIf="message.type === messageTypes.PRIOR_LEARNING_INCLUSION_APPLICATION_MESSAGE"
                              [ngTemplateOutlet]="placeAttainment"></ng-container>
                <ng-container *ngIf="message.type === messageTypes.CUSTOM_ATTAINMENT_APPLICATION_MESSAGE">
                  <ng-container [ngTemplateOutlet]="showDecision"></ng-container>
                  <ng-container [ngTemplateOutlet]="placeAttainment"></ng-container>
                </ng-container>
                <ng-container
                  *ngIf="message.type === messageTypes.CUSTOM_MODULE_CONTENT_APPLICATION_MESSAGE || message.type === messageTypes.REQUIRED_MODULE_CONTENT_APPLICATION_MESSAGE">
                  <ng-container [ngTemplateOutlet]="showInPlan"></ng-container>
                  <ng-container [ngTemplateOutlet]="showDecision"></ng-container>
                </ng-container>
                <ng-container *ngIf="message.type === messageTypes.STUDY_RIGHT_EXTENSION_APPLICATION_MESSAGE"
                              [ngTemplateOutlet]="showDecision"></ng-container>
              </ng-container>
              <ng-container *ngSwitchCase="'REJECTED'">
                <ng-container *ngIf="message.type === messageTypes.PRIOR_LEARNING_INCLUSION_APPLICATION_MESSAGE || message.type === messageTypes.PRIOR_LEARNING_SUBSTITUTION_APPLICATION_MESSAGE"
                              [ngTemplateOutlet]="showRejectedDecision"></ng-container>
                <ng-container *ngIf="message.type === messageTypes.MODULE_ATTAINMENT_APPLICATION_MESSAGE">
                  <ng-container [ngTemplateOutlet]="openMessageInPlanLink"
                                [ngTemplateOutletContext]="{translationKey: 'SHOW_MODULE_IN_PLAN'}"></ng-container>
                  <ng-container [ngTemplateOutlet]="goToApplicationLink"
                                [ngTemplateOutletContext]="{translationKey: 'SHOW_REJECTED_MODULE_ATTAINMENT_DECISION'}"></ng-container>
                </ng-container>
                <ng-container *ngIf="message.type === messageTypes.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION_MESSAGE">
                  <ng-container [ngTemplateOutlet]="openMessageInPlanLink"
                                [ngTemplateOutletContext]="{translationKey: 'SHOW_DEGREE_PROGRAMME_IN_PLAN'}"></ng-container>
                  <ng-container [ngTemplateOutlet]="goToApplicationLink"
                                [ngTemplateOutletContext]="{translationKey: 'SHOW_REJECTED_DEGREE_PROGRAMME_ATTAINMENT_DECISION'}"></ng-container>
                </ng-container>
                <ng-container *ngIf="message.type === messageTypes.CUSTOM_ATTAINMENT_APPLICATION_MESSAGE"
                              [ngTemplateOutlet]="showRejectedDecision"></ng-container>
                <ng-container *ngIf="message.type === messageTypes.CUSTOM_MODULE_CONTENT_APPLICATION_MESSAGE || message.type === messageTypes.REQUIRED_MODULE_CONTENT_APPLICATION_MESSAGE">
                  <ng-container [ngTemplateOutlet]="showInPlan"></ng-container>
                  <ng-container [ngTemplateOutlet]="showDecision"></ng-container>
                </ng-container>
                <ng-container *ngIf="message.type === messageTypes.STUDY_RIGHT_EXTENSION_APPLICATION_MESSAGE"
                              [ngTemplateOutlet]="showRejectedDecision"></ng-container>
              </ng-container>
              <ng-container *ngSwitchCase="'CONDITIONAL'">
                <ng-container *ngIf="message.type === messageTypes.CUSTOM_MODULE_CONTENT_APPLICATION_MESSAGE || message.type === messageTypes.REQUIRED_MODULE_CONTENT_APPLICATION_MESSAGE">
                  <ng-container [ngTemplateOutlet]="showInPlan"></ng-container>
                  <ng-container [ngTemplateOutlet]="showDecision"></ng-container>
                </ng-container>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <ng-container [ngTemplateOutlet]="showPriorLearningMessage"></ng-container>
              </ng-container>
            </ng-container>
          </div>
          <div class="button-container">
            <ng-container *ngIf="message.type === messageTypes.ENROLMENT_REMINDER_MESSAGE" [ngTemplateOutlet]="goToEnrolmentLink"
                          [ngTemplateOutletContext]="{translationKey: 'OPEN_ENROLMENTS'}"></ng-container>
            <ng-container *ngIf="message.type === messageTypes.AUTOMATIC_COURSE_UNIT_REALISATION_MESSAGE || message.type === messageTypes.MANUAL_COURSE_UNIT_REALISATION_MESSAGE"
                          [ngTemplateOutlet]="goToCalendarLink" [ngTemplateOutletContext]="{translationKey: 'OPEN_CALENDAR'}"></ng-container>
            <ng-container *ngIf="message.type === messageTypes.COURSE_UNIT_MANUAL_EVALUATION_REQUIRED_MESSAGE"
                          [ngTemplateOutlet]="goToCourseUnitEvaluationLink"
                          [ngTemplateOutletContext]="{translationKey: 'OPEN_COURSE_UNIT_EVALUATION'}"></ng-container>
            <ng-container *ngIf="showPlanMessageBtn" [ngTemplateOutlet]="showInPlan"></ng-container>
          </div>
        </div>
      </ng-template>
    </sis-expandable>
  </div>
  <ng-template #showInPlan>
    <ng-container [ngTemplateOutlet]="openMessageInPlanLink" [ngTemplateOutletContext]="{translationKey: 'SHOW_IN_PLAN'}"></ng-container>
  </ng-template>
  <ng-template #showDecision>
    <ng-container [ngTemplateOutlet]="goToApplicationLink" [ngTemplateOutletContext]="{translationKey: 'SHOW_DECISION'}"></ng-container>
  </ng-template>
  <ng-template #showPriorLearningMessage>
    <ng-container [ngTemplateOutlet]="goToApplicationLink" [ngTemplateOutletContext]="{translationKey: 'SHOW_PRIOR_LEARNING_MESSAGE'}"></ng-container>
  </ng-template>
  <ng-template #showRejectedDecision>
    <ng-container [ngTemplateOutlet]="goToApplicationLink" [ngTemplateOutletContext]="{translationKey: 'SHOW_REJECTED_DECISION'}"></ng-container>
  </ng-template>
  <ng-template #placeAttainment>
    <ng-container [ngTemplateOutlet]="openMessageInPlanLink" [ngTemplateOutletContext]="{translationKey: 'PLACE_ATTAINMENT'}"></ng-container>
  </ng-template>
  <ng-template #goToApplicationLink let-translationKey="translationKey">
    <a href="javascript:void(0);" class="btn btn-primary btn-sm anchor-button" (click)="goToApplication()">
      {{ t('SIS_COMPONENTS.MESSENGER.' + translationKey) }}
    </a>
  </ng-template>
  <ng-template #goToEnrolmentLink let-translationKey="translationKey">
    <a href="javascript:void(0);" class="btn btn-primary btn-sm anchor-button" (click)="goToEnrolment()">
      {{ t('SIS_COMPONENTS.MESSENGER.' + translationKey) }}
    </a>
  </ng-template>
  <ng-template #goToCalendarLink let-translationKey="translationKey">
    <a href="javascript:void(0);" class="btn btn-primary btn-sm anchor-button" (click)="goToCalendar()">
      {{ t('SIS_COMPONENTS.MESSENGER.' + translationKey) }}
    </a>
  </ng-template>
  <ng-template #goToCourseUnitEvaluationLink let-translationKey="translationKey">
    <a href="javascript:void(0);" class="btn btn-primary btn-sm anchor-button" (click)="goToCourseUnitEvaluation()">
      {{ t('SIS_COMPONENTS.MESSENGER.' + translationKey) }}
    </a>
  </ng-template>
  <ng-template #openMessageInPlanLink let-translationKey="translationKey">
    <a href="javascript:void(0);" class="btn btn-primary btn-sm anchor-button" (click)="openMessageInPlan()">
      {{ t('SIS_COMPONENTS.MESSENGER.' + translationKey) }}
    </a>
  </ng-template>
  <ng-template #openAttainmentLink let-translationKey="translationKey">
    <a href="javascript:void(0);" class="btn btn-primary btn-sm anchor-button" (click)="openAttainment()">
      {{ t('SIS_COMPONENTS.MESSENGER.' + translationKey) }}
    </a>
  </ng-template>
</ng-container>
