<ng-container *transloco="let t">
  <ng-container *ngIf="options$ | async as options; else loading">
    <p *ngIf="studyRightCount() === 0" class="app-widget-container__content__info-text sis-mb-sm">
      {{t('FRONTPAGE.SELECT_PLAN_DROPDOWN.NO_STUDY_RIGHTS')}}
    </p>
    <p *ngIf="studyRightCount() > 0 && options.length === 0" class="app-widget-container__content__info-text sis-mb-sm">
      {{t('FRONTPAGE.SELECT_PLAN_DROPDOWN.PLAN_MISSING')}}
    </p>
    <p *ngIf="searchFailed()" class="app-widget-container__content__info-text sis-mb-sm">
      {{t('FRONTPAGE.SELECT_PLAN_DROPDOWN.FETCHING_PLANS_FAILED')}}
    </p>

    <label for="select-plan" *ngIf="options.length > 0" class="mb-2">{{ t('FRONTPAGE.SELECT_PLAN_DROPDOWN.EDUCATION') }}</label>
    <!-- This is two because header is count as one -->
    <p *ngIf="options.length === 2" class="app-widget-container__content__info-text sis-mb-sm">
      {{ options[1].label }}
    </p>
    <ng-container *ngIf="options.length > 2">
      <sis-dropdown-select
        (selectionChange)="onSelect($event)"
        id="select-plan"
        [options]="options"
        [selection]="selection()"/>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #loading>
  <div aria-busy="true" class="d-flex justify-content-center pt-3">
    <sis-small-spinner />
  </div>
</ng-template>
