import { Transition } from '@uirouter/core';
import { combineLatestWith, firstValueFrom } from 'rxjs';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { SisStateDeclaration } from 'sis-components/router/types';
import { PlanEntityService } from 'sis-components/service/plan-entity.service';
import { StudyRightEntityService } from 'sis-components/service/study-right-entity.service';

import { CreateFirstPlanComponent } from './create-first-plan/create-first-plan.component';
import { PlanNgComponent } from './plan-ng.component';
import { STUDENT_PLAN, STUDENT_PLAN_CREATE_FIRST_PLAN, STUDENT_PLAN_STRUCTURE } from './plan-ng.route.constant';
import { PlanRedirectionService } from './plan-redirection.service';

const handlePlanRedirection = async (transition: Transition) => {
    const planEntityService: PlanEntityService = transition.injector().get(PlanEntityService) as PlanEntityService;
    const studyRightEntityService: StudyRightEntityService = transition.injector().get(StudyRightEntityService) as StudyRightEntityService;
    const appErrorHandler = transition.injector().get(AppErrorHandler) as AppErrorHandler;
    const planRedirectionService = transition.injector().get(PlanRedirectionService) as PlanRedirectionService;

    const [studentPlans, studentStudyRights] = await firstValueFrom(planEntityService.getMyPlans()
        .pipe(
            combineLatestWith(studyRightEntityService.getStudyRightsForCurrentUser()),
            appErrorHandler.defaultErrorHandler(),
        ));

    return planRedirectionService.resolvePlanRedirectionState(studentPlans, studentStudyRights);
};

export const planStates: SisStateDeclaration[] = [
    {
        name: STUDENT_PLAN,
        url: '/new-plan',
        headerParams: {
            skipBreadcrumb: true,
        },
        redirectTo: handlePlanRedirection,
    },
    {
        name: STUDENT_PLAN_CREATE_FIRST_PLAN,
        url: '/create-first-plan',
        data: {
            browserTabTitle: 'APPLICATION_STRUCTURE_OF_STUDIES',
            hideLastNode: false,
            showHome: true,
        },
        headerParams: {
            displayNameKey: 'APPLICATION_STRUCTURE_OF_STUDIES',
            skipBreadcrumb: false,
        },
        component: CreateFirstPlanComponent,
    },
    {
        name: STUDENT_PLAN_STRUCTURE,
        dynamic: true,
        url: '/:planId',
        data: {
            browserTabTitle: 'APPLICATION_STRUCTURE_OF_STUDIES',
            hideLastNode: false,
            showHome: true,
        },
        headerParams: {
            displayNameKey: 'APPLICATION_STRUCTURE_OF_STUDIES',
            skipBreadcrumb: false,
        },
        resolve: [
            {
                token: 'planId', deps: [Transition],
                resolveFn: (transition: Transition) => transition.params().planId,
            },
        ],
        component: PlanNgComponent,
    },
];
