import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UIRouterGlobals } from '@uirouter/core';
import { IdentityProviderLogin, LocalizedString } from 'common-typescript/types';
import { Observable } from 'rxjs';

import { ConfigService } from '../../config/config.service';
import { AuthService } from '../auth-service';

export interface IdpItem {
    name: LocalizedString,
    action: Function,
}
@Component({
    selector: 'sis-auth-selection-page',
    templateUrl: './auth-selection-page.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class AuthSelectionPageComponent implements OnInit {

    logins: IdpItem[];
    loggedOut: boolean;
    showLoginOptions$: Observable<boolean>;
    @Input() continueWithoutLogin: string;
    @Input() loginTarget: string;

    constructor(
        private configService: ConfigService,
        private authService: AuthService,
        private uiRouterGlobals: UIRouterGlobals,
    ) {
    }

    ngOnInit() {
        this.loggedOut = this.uiRouterGlobals.params.loggedOut;
        this.logins = this.configService.get()?.identityProviderLogins.map((idpLogin: IdentityProviderLogin) => ({
            name: idpLogin.name,
            action: () => this.authService.moveToAuthUrl(idpLogin.shibbolethUrl, this.loginTarget),
        }));
        this.showLoginOptions$ = this.authService.initialized$;
    }

    selectAction(item: IdpItem): void {
        item.action();
    }
}
