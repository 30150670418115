import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { StudyTermLocator } from 'common-typescript/types';

@Component({
    selector: 'sis-course-unit-timing-info-tooltip',
    templateUrl: './course-unit-timing-info-tooltip.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourseUnitTimingInfoTooltipComponent {

    @Input() studyTermLocators?: StudyTermLocator[];
    @Input() isCustomStudyDraft?: boolean;
}
