import angular from 'angular';

// This file was cleaned in SVG icon updates in OTM-27666. The follow-up ticket is OTM-28040 to convert this file to TS

export const commonEnrolmentStateServiceModule = 'sis-components.service.enrolmentStateService';
(function () {
  angular.module(commonEnrolmentStateServiceModule, []).constant('EnrolmentState', {
    ENROLLED: 'ENROLLED',
    PROCESSING: 'PROCESSING',
    NOT_ENROLLED: 'NOT_ENROLLED',
    REJECTED: 'REJECTED',
    INVALID: 'INVALID',
    ABORTED_BY_STUDENT: 'ABORTED_BY_STUDENT',
    ABORTED_BY_TEACHER: 'ABORTED_BY_TEACHER',
    RESERVED: 'RESERVED',
    CONFIRMED: 'CONFIRMED'
  }).constant('ProcessingState', {
    NOT_PROCESSED: 'NOT_PROCESSED',
    PENDING: 'PENDING',
    CURRENTLY_SELECTED: 'CURRENTLY_SELECTED',
    CURRENTLY_NOT_SELECTED: 'CURRENTLY_NOT_SELECTED',
    REQ_NOT_FULFILLED: 'REQ_NOT_FULFILLED',
    SELECTED: 'SELECTED',
    NOT_SELECTED: 'NOT_SELECTED'
  }).constant('EnrolmentCalculationState', {
    NOT_STARTED: 'NOT_STARTED',
    TENTATIVE_CALCULATION: 'TENTATIVE_CALCULATION',
    FINAL_CALCULATION_ONGOING: 'FINAL_CALCULATION_ONGOING',
    FINAL_CALCULATION_FINISHED: 'FINAL_CALCULATION_FINISHED',
    CONFIRMED: 'CONFIRMED'
  }).constant('EnrolmentCalculationResultState', {
    UNKNOWN: 'UNKNOWN',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR'
  }).constant('EnrolmentResolvedState', {
    NOT_ENROLLED_FILTER: 'NOT_ENROLLED_FILTER',
    PROCESSING_FILTER: 'PROCESSING_FILTER',
    PROCESSING_SELECTED_FILTER: 'PROCESSING_SELECTED_FILTER',
    PROCESSING_NOT_SELECTED_FILTER: 'PROCESSING_NOT_SELECTED_FILTER',
    PROCESSING_REQ_NOT_FULFILLED_FILTER: 'PROCESSING_REQ_NOT_FULFILLED_FILTER',
    ENROLLED_FILTER: 'ENROLLED_FILTER',
    REJECTED_FILTER: 'REJECTED_FILTER',
    ABORTED_FILTER: 'ABORTED_FILTER'
  }).service('enrolmentStateService');
})();