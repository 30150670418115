import angular from 'angular';
import { commonAttainmentServiceModule } from 'sis-components/service/attainment.service';
import attainmentGroupNodeTpl from './attainmentGroupNode.tpl.html';
export const attainmentGroupNodeModule = 'student.plan.attainmentGroupNode';
(function () {
  attainmentGroupNodeController.$inject = ["commonAttainmentService"];
  angular.module(attainmentGroupNodeModule, [commonAttainmentServiceModule]).component('attainmentGroupNode', {
    template: attainmentGroupNodeTpl,
    bindings: {
      attainmentGroupNode: '<',
      validatablePlan: '<',
      planValidationResult: '<',
      educationOptions: '<',
      level: '<',
      studyRight: '<'
    },
    controller: attainmentGroupNodeController
  });
  function attainmentGroupNodeController(commonAttainmentService) {
    const $ctrl = this;
    $ctrl.$onInit = () => {
      $ctrl.isCollapsed = false;
    };
    $ctrl.$onChanges = () => {
      updateChildStudies();
      $ctrl.classes = getClasses();
    };
    $ctrl.getCustomCourseUnitAttainment = id => $ctrl.validatablePlan.getCustomCourseUnitAttainment(id);
    $ctrl.getCustomModuleAttainment = customModuleAttainmentId => $ctrl.validatablePlan.getCustomModuleAttainment(customModuleAttainmentId);
    $ctrl.toggleCollapsed = () => {
      $ctrl.isCollapsed = !$ctrl.isCollapsed;
    };
    function getClasses() {
      const classes = [`tree-list-item-level-${$ctrl.level}`];
      if ($ctrl.level > 1) {
        classes.push('tree-list-item', 'clearfix');
      }
      return classes.join(' ');
    }
    function updateChildStudies() {
      const childStudies = commonAttainmentService.getChildStudiesFromAttainmentNodes($ctrl.attainmentGroupNode.nodes, $ctrl.validatablePlan);
      $ctrl.selectedCourseUnits = childStudies.courseUnits;
      $ctrl.selectedCustomCourseUnitAttainmentIds = childStudies.customCourseUnitAttainmentIds;
      $ctrl.selectedModules = childStudies.modulesAndGroupNodes;
      $ctrl.selectedCustomModuleAttainmentIds = childStudies.customModuleAttainmentIds;
    }
  }
})();