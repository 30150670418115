import { ChangeDetectionStrategy, Component, inject, signal, ViewEncapsulation } from '@angular/core';
import { EMPTY, finalize, from, Observable, switchMap, take } from 'rxjs';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { StudyProgressGraphResults, StudyProgressGraphResultService } from 'sis-components/plan/study-progress-graph-result.service';

import { PlanLoaderService } from '../../../common/service/plan-loader.service';
import { DropdownSelections } from '../select-plan-dropdown/select-plan-dropdown.component';

@Component({
    selector: 'app-study-progress-graph',
    templateUrl: './study-progress-graph.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudyProgressGraphComponent {
    private readonly appErrorHandler = inject(AppErrorHandler);
    private readonly planLoaderService = inject(PlanLoaderService);
    private readonly studyProgressGraphResultService = inject(StudyProgressGraphResultService);

    readonly loading = signal(false);
    studyProgressGraphResults$: Observable<StudyProgressGraphResults>;

    onDropdownSelectionChange(dropdownSelections: DropdownSelections) {
        const { plan, module, studyRightId } = dropdownSelections ?? {};
        if (plan) {
            this.loading.set(true);
            this.studyProgressGraphResults$ = this.planLoaderService.createValidatablePlan(plan.id, studyRightId)
                .pipe(
                    switchMap(validatablePlan => from(this.studyProgressGraphResultService.createResults(validatablePlan, module, true))),
                    take(1),
                    this.appErrorHandler.defaultErrorHandler(),
                    finalize(() => this.loading.set(false)),
                );
        } else {
            this.studyProgressGraphResults$ = EMPTY;
        }
    }
}
