import { inject, Injectable } from '@angular/core';
import { AssessmentItem, CourseUnit, LocalizedString } from 'common-typescript/types';
import { NameService } from 'sis-common/name/name.service';

@Injectable()
export class AssessmentItemAttainmentNodeNameService {

    private nameService = inject(NameService);

    /**
     * Creates a name for an assessment item attainment node.
     */
    getName(
        assessmentItem: AssessmentItem,
        courseUnit: CourseUnit | null,
    ): LocalizedString {
        return this.nameService.generateFullName({
            name: assessmentItem.name,
            nameSpecifier: courseUnit?.name ?? assessmentItem.nameSpecifier,
        });
    }
}
