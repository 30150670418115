<div class="clear">
  <ul class="tree-list tree-list-study-plan clearfix">
    <li class="{{classes}}">
      <div class="module-border" [class.collapsed]="!isOpen" [class.module-border-selected]="false">
        <div class="tree-list-heading tree-list-heading-has-children clearfix no-padding">
          <div class="accordion-panel accordion-panel-long-title accordion-panel-noblock">
            <div class="header has-header-items">
              <button type="button"
                      class="collapse non-button"
                      (click)="isOpen = !isOpen"
                      [class.collapsed]="!isOpen"
                      [attr.aria-expanded]="isOpen"
                      [attr.aria-label]="(customModuleAttainment?.name | localizedString)">
                <sis-icon icon="chevron-ring-fill" [iconClass]="isOpen ? 'cw-90' : null"></sis-icon>
              </button>
              <div class="title">
                <span class="plan-module-name">{{customModuleAttainment?.name | localizedString}}</span>
                <span class="additional">
                  <span *ngIf="customModuleAttainmentStateObject?.isAboutToExpire" class="tiny-badge accent">
                    <span translate>PLAN.MODULE.EXPIRATION_IMMINENT</span>
                    {{ customModuleAttainmentStateObject?.attainmentExpiryDate | localDateFormat }}
                  </span>
                  <span *ngIf="customModuleAttainmentStateObject?.invalidSelection && !customModuleAttainmentStateObject?.hasModuleContentApproval" class="tiny-badge danger">
                    <span translate>PLAN.INVALID_SELECTIONS.NOTIFICATION</span>
                  </span>
                  <span *ngIf="customModuleAttainmentStateObject?.invalidSelectionAccordingToModuleContentApproval" class="tiny-badge danger">
                    <span translate>PLAN.INVALID_ACCORDING_TO_MODULE_CONTENT_APPROVAL.NOTIFICATION</span>
                  </span>
                  {{ customModuleAttainment?.code }}
                </span>
                <ng-container *ngIf="!customModuleAttainment">
                  <span class="custom-module-attainment-data-not-found">
                    <sis-icon icon="alert" color="danger"></sis-icon>
                    <span translate="SIS_COMPONENTS.PLAN.CUSTOM_MODULE_ATTAINMENT_DATA_NOT_FOUND"></span>
                  </span>
                </ng-container>
              </div>
              <div class="cell average" *ngIf="customModuleAttainmentStateObject?.gradeAverage">
                <div>{{customModuleAttainmentStateObject.gradeAverage}}</div>
              </div>
              <div class="cell status">
                <div class="state-attained">
                  <span translate="SIS_COMPONENTS.PLAN_STATE.ATTAINED"></span>
                  <sis-icon icon="achievement" color="success"></sis-icon>
                  <span class="grade-text"> {{ customModuleAttainmentStateObject?.grade.abbreviation | localizedString }} </span>
                </div>
              </div>
              <div class="cell module-credits">
                <div class="planned-attained-credits">
                  <sis-tooltip [tooltipText]="('SIS_COMPONENTS.PLAN.LABEL.SELECTED_CREDITS' | translate) + ' ' + (customModuleAttainment?.credits | decimalNumber) + ' ' + ('CREDITS' | translate)"
                               [appendToBody]="true"
                               tooltip-placement="top-right">
                    <div class="planned-credits sis-tooltip-trigger-content">
                      {{customModuleAttainment?.credits | decimalNumber}}
                    </div>
                  </sis-tooltip>
                  <sis-tooltip [tooltipText]="('SIS_COMPONENTS.PLAN.LABEL.ATTAINED' | translate) + ' ' + (customModuleAttainment?.credits | decimalNumber) + ' ' + ('CREDITS' | translate)"
                               [appendToBody]="true"
                               tooltip-placement="top-left">
                    <div class="attained-credits sis-tooltip-trigger-content">
                      {{customModuleAttainment?.credits | decimalNumber}}
                    </div>
                  </sis-tooltip>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="isOpen && customModuleAttainment">
            <sis-attainment-node-structure [attainmentNodes]="customModuleAttainment.nodes"
                                           [planStateObject]="planStateObject"
                                           [planData]="planData"
                                           [uiStateObject]="uiStateObject"
                                           [level]="level">
            </sis-attainment-node-structure>
          </div>
        </div>
      </div>
    </li>
  </ul>
</div>
