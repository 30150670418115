import customAttainmentApplicationSummaryTpl from './customAttainmentApplicationSummary.tpl.html';
'use strict';
(function () {
  customAttainmentApplicationSummaryController.$inject = ["$q", "commonModuleService", "commonStudyRightService", "preferredLanguageJSDataModel"];
  angular.module('sis-components.applications.customApplicationAttainmentSummary', ['sis-components.applications.textWithDefaultMissingValue', 'sis-components.model.preferredLanguage', 'sis-components.service.moduleService', 'sis-components.service.studyRightService']).component('customAttainmentApplicationSummary', {
    template: customAttainmentApplicationSummaryTpl,
    bindings: {
      application: '<',
      shortVersion: '<',
      showLinksForStaff: '<'
    },
    controller: customAttainmentApplicationSummaryController
  });

  /**
   * @ngInject
   */
  function customAttainmentApplicationSummaryController($q, commonModuleService, commonStudyRightService, preferredLanguageJSDataModel) {
    const ctrl = this;
    ctrl.$onInit = function () {
      if (ctrl.showLinksForStaff) {
        ctrl.studentLink = getStudentLink();
        ctrl.studyPlanLink = getStudyPlanLink();
      }
      if (!ctrl.shortVersion) {
        preferredLanguageJSDataModel.lazyGetAll().then(function (languages) {
          ctrl.preferredLanguages = languages;
        });
        resolveEducation(ctrl.application);
        resolvePlannedParentModule(ctrl.application);
      }
    };
    ctrl.getLanguageName = function (languageUrn) {
      return _.get(_.find(ctrl.preferredLanguages, {
        urn: languageUrn
      }), 'name');
    };
    function resolveEducation(application) {
      if (application.studyRightId) {
        commonStudyRightService.findById(application.studyRightId, false, true).then(function (studyRight) {
          ctrl.education = studyRight.education;
        });
      }
    }
    function resolvePlannedParentModule(application) {
      if (application.plannedParentModuleId) {
        return commonModuleService.findById(application.plannedParentModuleId).then(function (module) {
          ctrl.plannedParentModule = module;
        });
      }
    }
    function getStudentLink() {
      if (!ctrl.application.student) {
        return null;
      }
      const studentId = ctrl.application.student.id;
      return `/tutor/role/staff/student/${studentId}/basic/basic-info`;
    }
    function getStudyPlanLink() {
      if (!ctrl.application.student || !ctrl.application.planId) {
        return null;
      }
      const studentId = ctrl.application.student.id;
      const studyPlanId = ctrl.application.planId;
      return `/tutor/role/staff/student/${studentId}/tutoring/plan/${studyPlanId}/structure`;
    }
  }
})();