import angularTranslate from 'angular-translate';
export const salesPeriodFilterModule = 'sis-components.date.salesPeriodFilter';
(function () {
  localDateRange.$inject = ["$translate"];
  angular.module(salesPeriodFilterModule, [angularTranslate]).filter('salesPeriod', localDateRange);

  /**
   * @ngInject
   */
  function localDateRange($translate) {
    return function (input) {
      if (input && (input.startDate || input.endDate)) {
        var format;
        var startDate;
        var endDate;
        if (input.startDate) {
          startDate = moment(input.startDate, 'YYYY-MM-DD');
        }
        if (input.endDate) {
          // End date is always excluded in our date ranges, so display the end date correctly
          endDate = moment(input.endDate, 'YYYY-MM-DD').subtract(1, 'day');
        }
        if (startDate && endDate && startDate.isSame(endDate)) {
          format = 'ARE_SAME';
        } else if (startDate && endDate && startDate.isAfter(endDate)) {
          format = 'EMPTY';
        } else if (startDate && !endDate) {
          format = 'START_';
        } else if (!startDate && endDate) {
          format = '_END';
        } else {
          format = 'START_END';
        }
        return $translate.instant('SALES_PERIOD.' + format, {
          start: startDate ? startDate.format('l') : undefined,
          end: endDate ? endDate.format('l') : undefined
        });
      }
      return '';
    };
  }
})();