import { Pipe, PipeTransform } from '@angular/core';
import { OtmId } from 'common-typescript/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppErrorHandler } from '../error-handler/app-error-handler';
import { CourseUnitRealisationEntityService } from '../service/course-unit-realisation-entity.service';

@Pipe({
    name: 'courseUnitRealisationCourseUnitCodes',
})
export class CourseUnitRealisationCourseUnitCodesPipe implements PipeTransform {

    constructor(
        private courseUnitRealisationEntityService: CourseUnitRealisationEntityService,
        private appErrorHandler: AppErrorHandler,
    ) {}

    transform(courseUnitRealisationId: OtmId, separator: string = ', '): Observable<string> {
        if (!courseUnitRealisationId) {
            return null;
        }
        return this.courseUnitRealisationEntityService.getCourseUnitRealisationCourseUnitCodes(courseUnitRealisationId)
            .pipe(
                map(curCodes => curCodes
                    .courseUnitCodes
                    ?.filter(Boolean)
                    .join(separator)),
                this.appErrorHandler.defaultErrorHandler(),
            );
    }
}
