<div *ngIf="menuItems?.length > 0" class="study-status-header">
  <h2 id="skip-link-target" tabindex="-1" class="d-inline-block fudis-heading__xs study-status-header__title">
    {{'FRONTPAGE.STUDY_PROGRESS_GRAPH.STUDY_STATUS' | translate}}
  </h2>
  <div ngbDropdown #dropdown=ngbDropdown>
    <button type="button"
            ngbDropdownToggle
            class="text-dropdown"
            [attr.aria-label]="(state?.item?.module?.name | localizedString) + ' ' + ('ARIA_LABEL.SHOW_OTHER_EDUCATIONS_STUDY_STATUS' | translate)">
      {{state?.item?.module?.name | localizedString}}
      <sis-icon icon="chevron" iconClass="cw-90"></sis-icon>
    </button>
    <div ngbDropdownMenu class="dropdown-menu-right" role="menu">
      <ng-container *ngFor="let menuItem of menuItems">
        <span class="dropdown-header" role="heading">{{menuItem.education?.name | localizedString}}</span>
        <button ngbDropdownItem
                role="option"
                *ngFor="let module of menuItem.modules"
                (click)="onSelect(menuItem.education, menuItem.plan, module); dropdown.close()"
                [attr.aria-current]="state?.item?.module?.id === module.id"
                [class.active]="state?.item?.module?.id === module.id">
          {{module.name | localizedString}}
          <span *ngIf="state?.item?.module?.id === module.id" class="visually-hidden">{{'ARIA_LABEL.SELECTED' | translate}}</span>
        </button>
      </ng-container>
    </div>
  </div>
</div>
