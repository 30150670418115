<div class="module-content-application-structure">
  <div class="mca-border">
    <div class="accordion-panel accordion-panel-noblock-dotted-content">
      <div class="header">
        <a class="collapse disabled"
           role="button"
           aria-hidden="true">
          <sis-icon [icon]="'chevron-ring'" [color]="'light'" [iconClass]="'cw-90'"/></a>
        <div class="title">
          <span>{{ parentModule.name | localizedString}}</span>
          <span class="additional" *ngIf="parentModule.code">{{ parentModule.code }}</span>
        </div>
      </div>
      <div class="content">
        <div class="content-row">
          <div class="content-text">
            <div class="row">
              <div class="col-sm-6" *ngFor="let courseUnit of selectedCourseUnits">
                <sis-course-unit-box
                  [courseUnit]="courseUnit"
                  [attainment]="selectedCourseUnitAttainmentsByGroupId[courseUnit.groupId]"
                  [categoryCssClass]="'cu-color-1'"></sis-course-unit-box>
              </div>
              <div class="col-sm-6" *ngFor="let customCourseUnitAttainment of selectedCustomCourseUnitAttainments">
                <sis-custom-course-unit-attainment-box [attainment]="customCourseUnitAttainment" [categoryCssClass]="'cu-color-1'">
                  <div notifications *ngIf="customStudyDraftsById[customCourseUnitAttainment.customStudyDraftId]">
                    <sis-tooltip
                      [tooltipText]="('SIS_COMPONENTS.MODULE_CONTENT_APPLICATION_STRUCTURE.REPLACES_CUSTOM_STUDY_DRAFT' | translate) + ' ' +  (customStudyDraftsById[customCourseUnitAttainment.customStudyDraftId].name)">
                      <div class="cu-notification sis-tooltip-trigger-content">
                        <span class="cu-notification-header"><sis-icon icon="info"></sis-icon></span><!--
                       --><span class="cu-notification-content">
                            <span translate="SIS_COMPONENTS.MODULE_CONTENT_APPLICATION_STRUCTURE.REPLACES_CUSTOM_STUDY_DRAFT"></span>:
                            {{ customStudyDraftsById[customCourseUnitAttainment.customStudyDraftId].name }}
                          </span>
                      </div>
                    </sis-tooltip>
                  </div>
                </sis-custom-course-unit-attainment-box>
              </div>
              <div class="col-sm-6" *ngFor="let customStudyDraft of effectiveCustomStudyDrafts">
                <sis-plan-custom-study-draft [customStudyDraft]="customStudyDraft"></sis-plan-custom-study-draft>
              </div>
              <div class="col-sm-6" *ngFor="let module of modules">
                <sis-module-box [module]="module" [attainment]="selectedModuleAttainmentsByGroupId[module.groupId]"></sis-module-box>
              </div>

              <div class="col-sm-6" *ngFor="let customModuleAttainment of selectedCustomModuleAttainments">
                <sis-custom-module-attainment-box [attainment]="customModuleAttainment"></sis-custom-module-attainment-box>
              </div>
              <div class="col-sm-12" *ngFor="let groupingModule of groupingModules">
                <sis-module-box [module]="groupingModule"></sis-module-box>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
