import { Transition } from '@uirouter/core';
import { UniversityConfig } from 'common-typescript/types';
import _ from 'lodash';
import { ConfigService } from 'sis-common/config/config.service';

// redirects /feature-toggle transitions to provided route when isTestModeEnabled = false
export const redirectIfFeatureTogglesAreNotEnabled = (fallbackRoute: string) =>
    ($transition$: Transition) => Promise.resolve($transition$.injector().get(ConfigService).get())
        .then((config: UniversityConfig) => {
            const isTestModeEnabled = _.get(
                config,
                'isTestModeEnabled',
                false,
            );
            if (isTestModeEnabled) {
                return;
            }
            return fallbackRoute;
        });
redirectIfFeatureTogglesAreNotEnabled.$inject = ['$transition$'];
