import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { Enrolment } from 'common-typescript/types';

import { enrolmentWizardModalOpener } from '../enrolment-wizard/enrolment-wizard.component';

export interface CancelEvent {
    enrolment: Enrolment;
    action: 'cancel' | 'abort';
}

@Component({
    selector: 'app-enrolment-state-course-units',
    templateUrl: './enrolment-state-course-units.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class EnrolmentStateCourseUnitsComponent {
    @Input() title: string;
    @Input() enrolments: Enrolment[];
    @Input() filters: any;
    @Input() emptyListText: string;
    @Output() handleEnrolment = new EventEmitter<Enrolment>();
    @Output() handleRemoveEnrolment = new EventEmitter<Enrolment>();
    @Output() handleCancelEnrolment = new EventEmitter<CancelEvent>();

    initialListLength = 5;
    fullListVisible = false;
    showMoreCount = 1;
    openEnrolmentModal = enrolmentWizardModalOpener();

    getEnrolmentList() {
        return this.enrolments.slice(0, this.initialListLength * this.showMoreCount);
    }

    removeEnrolment(enrolment: Enrolment) {
        this.handleRemoveEnrolment.emit(enrolment);
    }

    cancelEnrolment(enrolment: Enrolment, action: 'cancel' | 'abort') {
        this.handleCancelEnrolment.emit({ enrolment, action });
    }

    handleEnrolmentButtonClick(enrolment: Enrolment, action: 'enroll' | 'edit' | 'updateGroups') {
        const isUpdate = action === 'edit' || action === 'updateGroups';
        const isConfirmedSsgEdit = action === 'updateGroups';

        this.openEnrolmentModal({ enrolment, isUpdate, isConfirmedSsgEdit })
            .closed.subscribe((e: Enrolment) => {
                this.handleEnrolment.emit(e);
            });
    }

    showMoreEnrolments() {
        if (!this.fullListVisible) {
            this.showMoreCount += 1;
            if (this.showMoreCount * this.initialListLength >= this.enrolments.length) {
                this.fullListVisible = true;
            }
        } else {
            this.showMoreCount = 1;
            this.fullListVisible = false;
        }
    }
}

