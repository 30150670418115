<ng-container *transloco="let t">
  <div class="sis-attainment-structure-node">
    <sis-expandable
            [title]="(name | localizedString) + (attainment.credits ? (' (' + attainment.credits + '&nbsp;' + t('CREDITS') + ')') : '')"
            [variant]="'naked-dotted'"
            [contentPadding]="'none'"
            [level]="expandableLevel"
            [closed]="closed"
            (closedChange)="closedChange.emit($event)">
      <ng-template sisExpandableCustomTitle>
        <fudis-badge *ngIf="!attainment.primary" class="sis-mr-xs" variant="primary">
          {{ t('PROFILE.ATTAINMENT.NOT_PRIMARY') }}
        </fudis-badge>
        <fudis-badge *ngIf="isAboutToExpire(attainment) && !hasValidParentAttainment" class="sis-mr-xs" variant="danger">
          {{ t('PROFILE.ATTAINMENT.EXPIRATION_IMMINENT') }} {{ attainment.expiryDate | localDateFormat }}
        </fudis-badge>
        <fudis-badge *ngIf="hasExpired(attainment) && !hasValidParentAttainment" class="sis-mr-xs" variant="secondary">
          {{ t('PROFILE.ATTAINMENT.EXPIRED') }}
        </fudis-badge>
        <fudis-badge *ngIf="attainment.misregistration" variant="danger">
          {{ t('PROFILE.ATTAINMENT.MISREGISTRATION') }}
        </fudis-badge>
        <div class="expandable__title__sub-title expandable__title__sub-title__size__md">
          <fudis-grid [columns]="{ md: 'auto 45rem' }" [rowGap]="'xxs'">
            <fudis-body-text class="sis-attainment-structure-node__code" data-cy="attainment-structure-node-code-container">
              {{ code ? code + ' | ' : '' }}{{ t('PROFILE.ATTAINMENT.TYPE_IN_ATTAINMENT_STRUCTURE.' + attainment.type) }}
            </fudis-body-text>
            <fudis-grid-item>
              <fudis-dl [variant]="'compact'" [columns]="{ md: '3fr 4fr' }" [columnGap]="'xs'" data-cy="attainment-structure-node-date-grade-container">
                  <fudis-dl-item>
                    <fudis-dt [contentText]="t('PROFILE.ATTAINMENT.DATE' )"/>
                    <fudis-dd [contentText]="attainment.attainmentDate | localDateFormat"/>
                  </fudis-dl-item>
                  <fudis-dl-item *ngIf="grade">
                    <fudis-dt [contentText]="t('PROFILE.ATTAINMENT.GRADE')"/>
                    <fudis-dd [contentText]="(grade.abbreviation | localizedString) + (grade.name ? (' (' + (grade.name | localizedString) + ')') : '')"/>
                  </fudis-dl-item>
              </fudis-dl>
            </fudis-grid-item>
          </fudis-grid>
        </div>
        <hr class="sis-hr sis-mb-zero sis-mt-xxs"/>
      </ng-template>
      <ng-template sisExpandableContent>
        <div class="sis-attainment-structure-node__open-info">
          <sis-button [link]="true" (clicked)="openDetailsModal.emit()">
            {{ t('PROFILE.ATTAINMENT.ATTAINMENT_INFO') }}
          </sis-button>
        </div>
        <ng-content></ng-content>
      </ng-template>
    </sis-expandable>
  </div>
</ng-container>
