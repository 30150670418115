import angular from 'angular';
import parse from 'url-parse';
angular.module('student.plan').config(["$stateProvider", $stateProvider => {
  const initPlanCheck = function (initialAppCheck, plans) {
    return initialAppCheck.triggerSelectEducationModalIfNoPlans(plans);
  };
  initPlanCheck.$inject = ["initialAppCheck", "plans"];
  const initLearningOpportunityCheck = function ($transition$, initialAppCheck, plans) {
    if ($transition$.params().planId !== '') {
      return initialAppCheck.triggerForceLearningOpportunityModalIfNotSelected(plans);
    }
  };
  initLearningOpportunityCheck.$inject = ["$transition$", "initialAppCheck", "plans"];
  const loadPlan = function (uiContext, $state, $transition$, $filter, $log, errorService, planSeeker) {
    // NOSONAR
    let currentPlanId;
    if (!$transition$.params().planId) {
      currentPlanId = planSeeker.find().then(planId => {
        if (_.isNil(planId)) {
          $log.warn('Default plan not found', '$transition$.params():', $transition$.params());
          $state.go('student.logged-in.frontpage');
        } else {
          return planId;
        }
      }).catch(error => {
        $log.warn('Error finding default plan', error, '$transition$.params():', $transition$.params());
        $state.go('student.logged-in.frontpage');
      });
    } else {
      currentPlanId = $transition$.params().planId;
    }
    return uiContext.loadPlan(currentPlanId).catch(err => {
      $log.warn('Error in loadPlan', err);
      errorService.showTranslatedError({
        titleKey: 'ERROR.PLAN_WITH_GIVEN_ID_NOT_FOUND'
      });
      $state.go('student.logged-in.frontpage');
    });
  };
  loadPlan.$inject = ["uiContext", "$state", "$transition$", "$filter", "$log", "errorService", "planSeeker"];
  const loadEducationsWithPlans = function (educationsWithPlans, plans) {
    return educationsWithPlans.findMyPlans(plans);
  };
  loadEducationsWithPlans.$inject = ["educationsWithPlans", "plans"];
  const findPlansForUser = function (commonPlanService, AuthService) {
    return commonPlanService.findAllByUserId(AuthService.personId(), true, true);
  };
  findPlansForUser.$inject = ["commonPlanService", "AuthService"];
  const getPlanValidationResult = function (PlanLoader, selectedPlan) {
    return PlanLoader.validatePlan(selectedPlan);
  };
  getPlanValidationResult.$inject = ["PlanLoader", "selectedPlan"];
  const planSelectController = function ($scope, $state, myEducations, uiContext, plans, defaultPromiseHandler) {
    this.$onInit = function () {
      $scope.educations = myEducations;
      $scope.plans = plans;
      if (!_.isEmpty(myEducations)) {
        uiContext.readStoredUiContext().then(() => {
          if (uiContext.planContext) {
            $state.go('student.logged-in.structure', {
              planId: uiContext.planContext.id
            });
          }
        }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
      }
    };
  };
  planSelectController.$inject = ["$scope", "$state", "myEducations", "uiContext", "plans", "defaultPromiseHandler"];
  const planStructureController = function ($scope, $transition$, loadPlan, plans) {
    $scope.plans = plans;
    $scope.openUnplanned = $transition$.params().openUnplanned;
    $scope.unplannedCourseUnitId = $transition$.params().unplannedCourseUnitId;
    $scope.emrexResultCode = $transition$.params().emrexResultCode;
  };
  planStructureController.$inject = ["$scope", "$transition$", "loadPlan", "plans"];
  const planPreviewController = function ($scope, $transition$, previewMode) {
    $scope.moduleId = $transition$.params().moduleId;
    $scope.curriculumPeriodId = $transition$.params().curriculumPeriodId;
    $scope.returnUrl = $transition$.params().returnUrl;
    if ($transition$.params().moduleId) {
      previewMode.setPreviewMode();
    }
  };
  planPreviewController.$inject = ["$scope", "$transition$", "previewMode"];
  const planPrintController = function ($scope, planValidationResult) {
    $scope.validatablePlan = planValidationResult.validatablePlan;
    $scope.planValidationResult = planValidationResult;
    $scope.stateBack = {
      name: 'student.logged-in.structure',
      params: {
        planId: planValidationResult.validatablePlan.plan.id
      }
    };
  };
  planPrintController.$inject = ["$scope", "planValidationResult"];
  $stateProvider.state('student.logged-in.planPreview', {
    url: '/plan/preview/:moduleId/:curriculumPeriodId?returnUrl',
    template: '<plan module-id="moduleId" ' + 'curriculum-period-id="curriculumPeriodId" ' + 'return-url="returnUrl"></plan>',
    controller: planPreviewController,
    focusMode: {
      exitUrlResolver: transition => {
        const {
          returnUrl
        } = transition.params();
        return returnUrl ? parse(returnUrl)?.pathname : null;
      },
      titleKey: 'STUDY_PLAN_PREVIEW'
    }
  }).state('student.logged-in.planSelect', {
    url: '/plan',
    data: {
      browserTabTitle: 'APPLICATION_STRUCTURE_OF_STUDIES'
    },
    template: '',
    resolve: {
      plans: findPlansForUser,
      myEducations: loadEducationsWithPlans,
      appCheck: initPlanCheck
    },
    controller: planSelectController
  }).state('student.logged-in.structure', {
    url: '/structure/:planId?openUnplanned&emrexResultCode',
    template: '<plan plans="plans" open-unplanned="openUnplanned" emrex-result-code="emrexResultCode"></plan>"',
    resolve: {
      loadPlan,
      plans: findPlansForUser,
      learningOpportunityCheck: initLearningOpportunityCheck
    },
    params: {
      unplannedCourseUnitId: undefined,
      enrolmentStudyRightId: undefined
    },
    data: {
      hideLastNode: false,
      showHome: true,
      browserTabTitle: 'APPLICATION_STRUCTURE_OF_STUDIES'
    },
    headerParams: {
      displayNameKey: 'APPLICATION_STRUCTURE_OF_STUDIES',
      skipBreadcrumb: false
    },
    controller: planStructureController
  }).state('student.logged-in.plan.print', {
    url: '/print',
    resolve: {
      planValidationResult: getPlanValidationResult
    },
    template: '<print-plan ' + 'validatable-plan="validatablePlan" ' + 'plan-validation-result="planValidationResult" ' + 'state-name-to-go-back="stateBack.name" ' + 'state-params-to-go-back="stateBack.params"></print-plan>',
    controller: planPrintController
  });
}]);