import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    signal,
    ViewEncapsulation,
} from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { TransitionService } from '@uirouter/angular';
import { UIRouterGlobals } from '@uirouter/core';
import { map, Observable, of, Subject, takeUntil } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { ModalService } from 'sis-common/modal/modal.service';

import { FeedbackModalComponent } from '../../../../feedback-modal/feedback-modal.component';
import { NotificationsService } from '../../../../service/notifications/notifications.service';
import { OpenUniversityCartCustomerService } from '../../../../service/open-university-cart-customer.service';
import { UniversityService } from '../../../../service/university.service';
import { NavUtilsService } from '../../../nav-utils/nav-utils.service';

@Component({
    selector: 'sis-top-navigation-icon',
    templateUrl: './top-navigation-icon.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopNavigationIconComponent implements OnDestroy, OnInit {
    constructor(
        private navUtilsService: NavUtilsService,
        private notificationsService: NotificationsService,
        private universityService: UniversityService,
        private openUniversityCartCustomerService: OpenUniversityCartCustomerService,
        private changeDetectorRef: ChangeDetectorRef,
        private transitionService: TransitionService,
        private uiRouterGlobals: UIRouterGlobals,
        private translocoService: TranslocoService,
        private modalService: ModalService,
    ) {}

    @Input() variant: 'open-university-cart' | 'messages' | 'notes' | 'help' | 'feedback';
    @Input() mobileMode = false;

    @Output() blurMobileMenuIcon = new EventEmitter<any>();

    path = signal<string>(null);
    destroyed$ = new Subject<void>();
    notificationObservable: Observable<number>;
    enableCartObservable: Observable<boolean>;
    isActive: boolean;
    ariaLabel: string;
    name: string;

    deregisterActiveUrlChangeHook: Function;

    ngOnInit() {
        switch (this.variant) {
            case 'open-university-cart':
                this.name = this.translocoService.translate('ARIA_LABEL.OPEN_UNI_CART');
                this.ariaLabel = this.translocoService.translate('ARIA_LABEL.PRODUCTS_IN_CART');
                this.enableCartObservable = this.universityService.openUniversityFeaturesEnabledForStudent().pipe(take(1));
                // This should receive updated cart object every time when akita store is updated
                this.notificationObservable = this.openUniversityCartCustomerService.getCurrentCart()
                    .pipe(
                        takeUntil(this.destroyed$),
                        map(cart => {
                            if (cart?.state === 'RESERVED' || cart?.state === 'TENTATIVE') {
                                return cart?.items?.length || 0;
                            }
                            return 0;
                        }),
                        catchError(err => of(null)),
                    );
                break;
            case 'messages':
                this.path.set(this.navUtilsService.getMessagesHref());
                this.name = this.translocoService.translate('ARIA_LABEL.MESSAGES');
                this.ariaLabel = this.translocoService.translate('ARIA_LABEL.UNREAD');
                this.notificationObservable = this.notificationsService.getEventsObservable<number>('messageCount')
                    .pipe(takeUntil(this.destroyed$));
                break;
            case 'notes':
                this.name = this.translocoService.translate('ARIA_LABEL.NOTES');
                break;
            case 'help':
                this.navUtilsService.getHelpUrl().subscribe((url) => this.path.set(url));
                this.name = this.translocoService.translate('ARIA_LABEL.HELP');
                this.ariaLabel = `${this.name} ${this.translocoService.translate('ARIA_LABEL.OPENS_TO_A_NEW_TAB')}`;
                break;
            case 'feedback':
                this.name = this.translocoService.translate('ARIA_LABEL.FEEDBACK');
                break;
            default:
                break;
        }
        this.isActive = this.isStateActive(this.uiRouterGlobals.current.name);
        this.deregisterActiveUrlChangeHook = this.registerActiveUrlChangeHook();
    }

    ngOnDestroy() {
        this.destroyed$.complete();
        this.deregisterActiveUrlChangeHook();
    }

    get dataCy(): string {
        return `top-navigation-icon-${this.variant}`;
    }

    registerActiveUrlChangeHook() {
        return this.transitionService.onSuccess({}, (transition) => {
            this.isActive = this.isStateActive(transition.to().name);
            this.changeDetectorRef.markForCheck();
        });
    }

    isStateActive(activePathName: string) {
        switch (this.variant) {
            case 'open-university-cart':
                return activePathName.includes('student.open-university-cart');
            case 'messages':
                return activePathName.includes('logged-in.message-conversations') || activePathName.includes('logged-in.messages');
            case 'notes':
                return activePathName.includes('student.logged-in.course-cart');
            default:
                return false;
        }
    }

    openFeedbackModal() {
        return this.modalService.open(FeedbackModalComponent, null, {
            windowClass: 'feedback-modal',
            closeWithOutsideClick: true,
        }).result;
    }
}
