<div *transloco="let t" class="container">
  <ng-container *ngIf="studentWorkflow">
    <div class="row gy-4">
      <ng-container *ngIf="isWorkflowRequestedOrInHandling()">
        <div class="col-12 d-flex justify-content-end">
          <fudis-button [label]="t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.CANCEL_APPLICATION')" (handleClick)="openCancelDialog()" [variant]="'primary'" data-cy="dpaw-cancel-button" />
        </div>
      </ng-container>

      <ng-container *ngIf="isWorkflowCancelled()">
        <div class="col-12">
          <fudis-notification [variant]="'warning'">
            <fudis-body-text>
              {{ t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.APPLICATION_CANCELLED') }}
            </fudis-body-text>
          </fudis-notification>
        </div>
      </ng-container>

      <ng-container *ngIf="isWorkflowRequestedOrInHandling()">
        <div class="col-12">
          <fudis-notification [variant]="'info'">
            <fudis-body-text>
              <transloco *ngIf="studentWorkflow.moduleId | module | async as degreeProgramme"
                         [params]="{ degreeProgrammeName: degreeProgramme.name | localizedString }"
                         key='STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.APPLICATION_REQUESTED'></transloco>
            </fudis-body-text>
          </fudis-notification>
        </div>
      </ng-container>

      <ng-container *ngIf="isWorkflowRejected()">
        <sis-contextual-notification [variant]="'danger'">
          <p>
            <transloco *ngIf="studentWorkflow.moduleId | module | async as degreeProgramme"
              [params]="{ degreeProgrammeName: degreeProgramme.name | localizedString }"
              key='STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.APPLICATION_REJECTED'></transloco>
          </p>
          <p>{{ getResolutionRationale() }}</p>
        </sis-contextual-notification>
      </ng-container>

      <ng-container *ngIf="isWorkflowAcceptanceRevoked()">
        <sis-contextual-notification [variant]="'danger'" *ngIf="latestAttainment$ | async as attainment">
          <p>
            <transloco *ngIf="studentWorkflow.moduleId | module | async as degreeProgramme"
                       [params]="{ degreeProgrammeName: degreeProgramme.name | localizedString }"
                       key='STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.APPLICATION_ACCEPTANCE_REVOKED'></transloco>
          </p>
          <p>{{ attainment.misregistrationRationale }}</p>
        </sis-contextual-notification>
      </ng-container>

      <div class="col-12">
        <h2>{{ t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.PERSON_INFO.TITLE') }}</h2>
        <sis-person-info-for-graduation [person]="studentWorkflow.studentId | privatePerson | async"></sis-person-info-for-graduation>
      </div>

      <div class="col-12">
        <h2>{{ t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.CONTACT_INFO.TITLE') }}</h2>
        <sis-contact-info-for-graduation [level]="3" [person]="studentWorkflow.studentId | privatePerson | async"></sis-contact-info-for-graduation>
      </div>

      <div class="col-12" *ngIf="shouldShowOtherInfo()">
        <h2>{{ t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.OTHER_INFO.TITLE') }}</h2>
        <sis-degree-programme-attainment-application-answers *ngIf="studentWorkflow.questionnaireAnswers" [workflow]="studentWorkflow"></sis-degree-programme-attainment-application-answers>
        <ng-container *ngIf="studentWorkflow.additionalInfo">
          <h3>{{ t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.OTHER_INFO.ADDITIONAL_INFO') }}</h3>
          <p>{{ studentWorkflow.additionalInfo }}</p>
        </ng-container>
      </div>

      <div class="col-12">
        <h2>{{ t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.DEGREE_DELIVERY.TITLE') }}</h2>
        <p *ngIf="isDeliveryByPickUp()"><transloco [key]="'STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.DEGREE_DELIVERY.PICK_UP_INFO'" /></p>
        <p *ngIf="isDeliveryByMail()"><transloco [key]="'STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.DEGREE_DELIVERY.MAIL_INFO'" /></p>
        <p *ngIf="isDigitalDegreeCertificatePreferred()"><transloco [key]="'STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.DEGREE_DELIVERY.DIGITAL_DEGREE_CERTIFICATE'" /></p>
      </div>
      <div class="col-12" *ngIf="isDeliveryByMail()">
        <ng-container *ngIf="isDeliveryAddressInFinland()">
          <span>{{ getStreetAddress() }}, </span>
          <span>{{ getPostalCode() }}, </span>
          <span>{{ getCity() }}, </span>
        </ng-container>
        <ng-container *ngIf="!isDeliveryAddressInFinland()">
          <span>{{ getAddress() }}, </span>
        </ng-container>
        <span>{{ studentWorkflow.deliveryAddress?.countryUrn | codeLocalizedName | async }}</span>
      </div>

      <div class="col-12" *ngIf="shouldShowAlumniAssociationInfo()">
        <h2>{{ t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.ALUMNI_ASSOCIATION.TITLE') }}</h2>
        <div *ngIf="studentWorkflow.joinsAlumniAssociation">
          <p>{{ t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.ALUMNI_ASSOCIATION.JOINED') }}</p>
          <p>{{ t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.ALUMNI_ASSOCIATION.TRANSFERRED_INFOS') }}</p>
        </div>
        <div *ngIf="!studentWorkflow.joinsAlumniAssociation">
          <p>{{ t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.ALUMNI_ASSOCIATION.NOT_JOINED') }}</p>
        </div>
      </div>
    </div>
  </ng-container>
</div>
