<div class="col-sm-12 col-md-8 col-xl-8" *ngIf="studyRights$ | async as studyRights">
  <ng-container *ngIf="studyRights.length > 1" [ngTemplateOutlet]="select"></ng-container>
  <ng-container *ngIf="studyRights.length === 1" [ngTemplateOutlet]="view"></ng-container>
  <ng-container *ngIf="{
    courseUnit: enrolment.courseUnitId | courseUnit | async,
    courseUnitRealisation: enrolment.courseUnitRealisationId | courseUnitRealisation | async
  } as enrolmentData">
    <div class="mt-3" aria-atomic="true" id="cu-notification" *ngIf="enrolmentData.courseUnit && enrolmentData.courseUnitRealisation">
      <sis-contextual-notification *ngIf="showCourseUnitNotInPrimaryPlanNotification(studyRights, enrolmentData.courseUnit, enrolmentData.courseUnitRealisation)"
                                   [content]="'ENROLMENTS.ENROLMENT_MODAL.CU_NOT_IN_PRIMARY_PLAN' | translate"
                                   variant="warning">
      </sis-contextual-notification>
      <sis-contextual-notification *ngIf="showCourseUnitNotInSelectionsNotification(studyRights, enrolmentData.courseUnit, enrolmentData.courseUnitRealisation)"
                                   [content]="'ENROLMENTS.ENROLMENT_MODAL.CU_NOT_IN_SELECTIONS' | translate"
                                   variant="warning">
      </sis-contextual-notification>
    </div>
  </ng-container>
  <ng-template #select>
    <label
      [ngClass]="getLabelState(control)" id="select-study-right-label" translate>ENROLMENTS.STUDY_RIGHT
      <select [formControl]="control"
              [id]="id"
              [ngClass]="{'invalid': getLabelState(control) === 'invalid'}"
              aria-describedby="cu-notification"
              class="form-select form-control"
              aria-labelledby="select-study-right-label"
              data-cy="select-study-right">
        <option [ngValue]="null" disabled selected>{{'ENROLMENTS.STUDY_RIGHT_SELECT' | translate}}</option>
        <option *ngFor="let sr of studyRights"
                [ngValue]="sr.id">{{ sr.educationId | education | async | entityName | localizedString }}
          ({{'STUDY_RIGHT_STATE.' + sr.state | translate}})
        </option>
      </select>
      <sis-validation-errors [control]="control" [validationErrors]="control.errors"></sis-validation-errors>
    </label>
  </ng-template>
  <ng-template #view>
    <label for="study-right" translate>ENROLMENTS.STUDY_RIGHT</label>
    <p *ngIf="studyRights.length === 1" id="study-right" aria-labelledby="select-study-right"
       aria-describedby="cu-notification">
      {{studyRights[0].educationId | education | async | entityName | localizedString }}
      ({{'STUDY_RIGHT_STATE.' + studyRights[0].state | translate}})
    </p>
  </ng-template>
</div>
