<sis-description-list>
  <dl>
    <ng-template #listItem>
      <dt class="sis-label">{{'FIELD_NAMES.studentNumber' | translate}}</dt>
      <dd class="notranslate">{{ person.studentNumber }}</dd>
    </ng-template>
    <ng-template #listItem>
      <dt class="sis-label">{{'PROFILE.USER_NAME' | translate}}</dt>
      <dd class="notranslate">{{ person.userName }}</dd>
    </ng-template>
    <ng-template #listItem>
      <dt class="sis-label">{{'PROFILE.GENDER' | translate}}</dt>
      <dd>{{ person.genderUrn | codeLocalizedName | async }}</dd>
    </ng-template>
    <ng-template #listItem>
      <dt class="label">{{'PROFILE.CITIZENSHIP' | translate}}</dt>
      <dd *ngFor="let countryUrn of person.citizenshipUrns">
          {{ countryUrn | codeLocalizedName | async }}<br/>
        </dd>
    </ng-template>
    <ng-template #listItem>
      <dt class="sis-label">{{'PROFILE.SCHOOL_EDUCATION_LANGUAGE' | translate}}</dt>
      <dd>
        <span *ngFor="let language of person.schoolEducationLanguageUrns; let last = last">
          {{ language | codeLocalizedName | async }}<br/>
        </span>
      </dd>
    </ng-template>
    <ng-template #listItem>
      <dt class="sis-label">{{'PROFILE.OPPIJANUMERO' | translate}}</dt>
      <dd class="notranslate">{{ person.oppijanumero }}</dd>
    </ng-template>
    <ng-template #listItem>
      <dt class="sis-label">{{'PROFILE.BIRTH_DATE' | translate}}</dt>
      <dd>{{ person.dateOfBirth | localDateFormat }}</dd>
    </ng-template>
    <ng-template #listItem>
      <dt class="sis-label">{{'PROFILE.MOTHER_TONGUE' | translate}}</dt>
      <dd>{{ person.motherTongueUrn | codeLocalizedName | async }}</dd>
    </ng-template>
    <ng-template #listItem>
      <dt class="sis-label">{{'PROFILE.PREFERRED_LANGUAGE' | translate}}</dt>
      <dd>{{ person.preferredLanguageUrn | codeLocalizedName | async }}</dd>
    </ng-template>
    <ng-template #listItem>
      <dt class="sis-label">{{'PROFILE.EMPLOYEE_NUMBER' | translate}}</dt>
      <dd class="notranslate">{{ person.employeeNumber }}</dd>
    </ng-template>
  </dl>
</sis-description-list>
