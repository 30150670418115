(function () {
  'use strict';

  angular.module('student.common.model.studyPeriodLocator', []).factory('studyPeriodLocatorModel', function () {
    var studyPeriodLocatorModel = function (data) {
      if (_.isString(data)) {
        _.assign(this, parse(data));
      } else if (!_.isNil(data)) {
        this.organisationId = data.organisationId;
        this.year = data.organisationId;
        this.period = data.organisationId;
        this.term = data.organisationId;
      }
      return this;
    };
    studyPeriodLocatorModel.prototype.toLocator = function () {
      return [this.organisationId, this.year, this.period, this.term].join('/');
    };
    function parse(locatorString) {
      var periodParts = locatorString.split('/');
      if (periodParts.length > 0) {
        return {
          organisationId: periodParts[0],
          year: parseInt(periodParts[1], 10),
          period: parseInt(periodParts[2], 10),
          term: parseInt(periodParts[3], 10)
        };
      }
    }
    return studyPeriodLocatorModel;
  });
})();