import angular from 'angular';
import { ExternalLinkComponent } from 'sis-components/external-link/external-link.component.ts';
import _ from 'lodash';
import learningEnvironmentUrlTpl from './learningEnvironmentUrl.tpl.html';
export const learningEnvironmentUrlModule = 'student.common.components.learningEnvironmentUrl';
angular.module(learningEnvironmentUrlModule, [ExternalLinkComponent.downgrade.moduleName, 'sis-common.l10n.localeService']).component('learningEnvironmentUrl', {
  bindings: {
    courseUnitRealisation: '<'
  },
  template: learningEnvironmentUrlTpl,
  controller: ["localeService", function (localeService) {
    const $ctrl = this;
    $ctrl.$onInit = function () {
      $ctrl.learningEnvironments = filterLearningEnvironments($ctrl.courseUnitRealisation);
    };
    function filterLearningEnvironments(courseUnitRealisation) {
      if (!courseUnitRealisation) {
        return [];
      }
      const language = localeService.getCurrentLocale();
      const learningEnvironments = [];
      const langs = localeService.getLocaleOrder();
      if (courseUnitRealisation.learningEnvironments) {
        _.forEach(courseUnitRealisation.learningEnvironments, function (learningEnvironment) {
          getLocalizedLearningEnvironments(learningEnvironment);
        });
        if (_.isEmpty(learningEnvironments)) {
          getLearningEnvironmentsForLanguage(langs);
        }
      }
      return learningEnvironments;
      function getLocalizedLearningEnvironments(learningEnvironment, languageVersion) {
        var useLanguage = language;
        if (languageVersion) {
          useLanguage = languageVersion;
        }
        if (learningEnvironment.language === useLanguage) {
          if (learningEnvironment.primary) {
            var counter = 0;
            _.forEach(learningEnvironments, function (le) {
              if (le.primary === true) {
                counter++;
              }
            });
            learningEnvironments.splice(counter, 0, learningEnvironment);
          } else {
            learningEnvironments.push(learningEnvironment);
          }
        }
      }
      function getLearningEnvironmentsForLanguage(languages) {
        _.forEach(languages, function (language) {
          if (_.isEmpty(learningEnvironments)) {
            _.forEach(courseUnitRealisation.learningEnvironments, function (le) {
              getLocalizedLearningEnvironments(le, language);
            });
          }
        });
      }
    }
  }]
});