import { ChangeDetectionStrategy, Component, ContentChild, Input, TemplateRef, ViewEncapsulation } from '@angular/core';
import { LocalId, OtmId, StudyGroupSet } from 'common-typescript/types';

import { EnrichedAssItem, EnrichedEnrolment } from '../../open-university-enrolment-wizard/open-university-enrolment-wizard.component';

@Component({
    selector: 'app-open-university-enrolled-info',
    templateUrl: './open-university-enrolled-info.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
/**
 * This data is per one open-university-product.
 * */
export class OpenUniversityEnrolledInfoComponent {

    @ContentChild('modifySelections', { static: false }) modifySelectionsTemplateRef: TemplateRef<any>;

    @Input() level?: number = 3;
    @Input() assessmentItemsData: EnrichedAssItem[] = [];
    @Input() openUniStudentEnrolments: EnrichedEnrolment[];
    @Input() hideEnrolmentQuestionnaire?: boolean;

    filterAssessmentItemCurs(assessmentItem: EnrichedAssItem) {
        return assessmentItem?.courseUnitRealisations.filter(cur => this.curIdMatchToEnrolment(cur.id));
    }

    curIdMatchToEnrolment(curId: OtmId): boolean {
        return this.openUniStudentEnrolments.some(enrolment => enrolment.courseUnitRealisationId === curId);
    }

    sgsSsgIdMatchToEnrolmentSSG(sgs: StudyGroupSet) {
        return sgs.studySubGroups.some(ssg => this.ssgIdMatchToEnrolmentSSG(ssg.id));
    }

    ssgIdMatchToEnrolmentSSG(ssgId: OtmId): boolean {
        return this.openUniStudentEnrolments.some(enrolment => enrolment?.studySubGroups.find(enrolmentSsg => enrolmentSsg.studySubGroupId === ssgId));
    }

    findEnrolmentQuestionAnswer(questionId: LocalId, questionType: string): string {
        let answerString;
        const qAnswer = this.openUniStudentEnrolments?.flatMap(enrolment => enrolment?.enrolmentQuestionnaireAnswers?.answers ?? [])
            ?.find(answer => answer?.questionId === questionId);

        if (qAnswer) {
            if (questionType === 'LANGUAGE_SELECT') {
                // This is for some reason used only to store a single radiobutton selection (not multiple values as selections list indicates).
                answerString = qAnswer.selections.toString();
            }
            if (questionType === 'FREE_TEXT') {
                answerString = qAnswer.answerText;
            }
        }
        return answerString ? answerString : '-';
    }

    parseFreetextQuestionTitle(index: number, question: string): string {
        const strippedQuestion = question ? question.replace(/<p>/i, '').replace(/<\/p>/i, '') : '';
        return `${index}. ${strippedQuestion}`;
    }
}
