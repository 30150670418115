<ng-container *ngIf="control">
  <div class="row local-date-range-editor">
    <div class="col-5 local-date-range-editor__first-input">
      <label [class]="getLabelStateFor(startDate)"
             *ngIf="!hideLabels"
             translate>SIS_COMPONENTS.DATE.LOCAL_DATE_RANGE_EDITOR.START_DATE</label>
        <sis-local-date-editor
            [name]="name ? name + '_startDate' : 'startDate'"
            [control]="startDate"
            [datepickerPlacement]="datepickerPlacementStartDate"
            [showLabelIcon]="showLabelIcons"
            data-cy="start-date-editor"
        ></sis-local-date-editor>
    </div>
    <span class="date-range-gutter"></span>
    <div class="col-5">
      <label [class]="getLabelStateFor(endDate)"
             *ngIf="!hideLabels && !endNotRequired"
             translate>SIS_COMPONENTS.DATE.LOCAL_DATE_RANGE_EDITOR.END_DATE</label>
      <label *ngIf="!hideLabels && endNotRequired"
             translate>SIS_COMPONENTS.DATE.LOCAL_DATE_RANGE_EDITOR.END_DATE</label>
      <div class="inner-addon">
        <sis-local-date-editor
            [name]="name ? name + '_endDate' : 'endDate'"
            [control]="endDate"
            [minDate]="minDateValue"
            [startDate]="endDateStartDate"
            [datepickerPlacement]="datepickerPlacementEndDate"
            [showLabelIcon]="showLabelIcons"
            data-cy="end-date-editor"
        ></sis-local-date-editor>
      </div>
    </div>
  </div>
  <sis-validation-errors [control]="control"></sis-validation-errors>
</ng-container>
