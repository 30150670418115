import {
    Component,
    ContentChildren,
    Input,
    TemplateRef,
    ViewEncapsulation,
} from '@angular/core';

@Component({
    selector: 'sis-description-list-compact',
    templateUrl: './description-list-compact.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class DescriptionListCompactComponent {

    @Input() fillByColumn = true;
    @Input() columnCount = 2;

    @ContentChildren('compactListItem', { read: TemplateRef, descendants: true }) childTemplates: TemplateRef<any>[];

}
