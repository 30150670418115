import {
    ChangeDetectionStrategy,
    Component,
    computed,
    Input,
    Signal,
    signal,
    ViewEncapsulation,
    WritableSignal,
} from '@angular/core';
import { CompositeRule, CourseUnitCountRule, IntRange, RangeValidationResultState } from 'common-typescript/types';
import _ from 'lodash';

@Component({
    selector: 'sis-plan-structure-count-rule-header',
    templateUrl: './plan-structure-count-rule-header.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanStructureCountRuleHeaderComponent {

    _rule: WritableSignal<CompositeRule | CourseUnitCountRule> = signal(undefined);
    @Input({ required: true }) set rule(rule: CompositeRule | CourseUnitCountRule) {
        this._rule.set(rule);
    }

    _ruleValidationResults: WritableSignal<any> = signal(undefined);
    @Input({ required: true }) set ruleValidationResults(ruleValidationResults: any) {
        this._ruleValidationResults.set(ruleValidationResults);
    }

    @Input() ruleLegendId: string;
    @Input() onlyShowRule: boolean;

    allMandatory: Signal<boolean> = computed(this.allMandatoryComputation());
    selectedCount: Signal<number> = computed(this.selectedCountComputation());
    ruleRequireRange: Signal<IntRange> = computed(this.ruleRequireRangeComputation());
    rangeValidationResultState: Signal<RangeValidationResultState> = computed(this.rangeValidationResultStateComputation());

    allMandatoryComputation(): () => boolean {
        return () => {
            const rule = this._rule();
            if (rule.type === 'CompositeRule') {
                return rule.allMandatory;
            }
            return false;
        };
    }

    selectedCountComputation(): () => number {
        return () => {
            const implicitCount =
                _.result(this._ruleValidationResults(), 'implicitCount', 0) as unknown as number;
            const directCount =
                _.result(this._ruleValidationResults(), 'directCount', 0) as unknown as number;
            return implicitCount + directCount;
        };
    }

    ruleRequireRangeComputation(): () => IntRange {
        return () => {
            const rule = this._rule();
            if (rule.type === 'CourseUnitCountRule') {
                return rule.count;
            }
            if (rule.require) {
                return rule.require;
            }
            const subRuleCount = rule.rules.length;
            return <IntRange>{
                min: subRuleCount,
                max: subRuleCount,
            };
        };
    }

    rangeValidationResultStateComputation(): () => RangeValidationResultState {
        return () => this._ruleValidationResults()?.result as unknown as RangeValidationResultState;
    }

}
