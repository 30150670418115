import { Component, Input, ViewEncapsulation } from '@angular/core';
import { FlowState, LocalDateString, ResolvedFlowState } from 'common-typescript/types';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { BadgeVariant } from '../tiny-badge/tiny-badge.component';

/**
 * This component is dynamic related to input. This component should be used when cur is mutated on same view.
 */
@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-cur-flow-state-badge-dynamic',
    templateUrl: './cur-flow-state-badge-dynamic.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CurFlowStateBadgeDynamicComponent {
    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.badge.curFlowStateBadgeDynamic',
        directiveName: 'sisCurFlowStateBadgeDynamic',
    };

    @Input() publishDate: LocalDateString;
    get flowState(): FlowState {
        return this._flowState;
    }

    @Input() set flowState(flowState: FlowState) {
        this._flowState = flowState;
        this.badgeVariant = this.calculateBadgeVariant();
        this.translationKey = this.calculateTranslationKey();
    }

    @Input() set resolvedFlowState(resolvedFlowState: ResolvedFlowState) {
        this._resolvedFlowState = resolvedFlowState;
        this.badgeVariant = this.calculateBadgeVariant();
        this.translationKey = this.calculateTranslationKey();
    }

    badgeVariant: BadgeVariant;
    translationKey: string;
    private _flowState: FlowState;
    private _resolvedFlowState: ResolvedFlowState;

    private calculateBadgeVariant(): BadgeVariant {
        const state = this._resolvedFlowState || this._flowState;
        switch (state) {
            case 'PUBLISHED':
            case 'PUBLISHED_ACTIVE':
            case 'PUBLISHED_FUTURE':
            case 'PUBLISHED_EXPIRED':
                return 'success';
            case 'NOT_READY':
                return 'accent';
            case 'CANCELLED':
            case 'CANCELLED_ACTIVE':
            case 'CANCELLED_EXPIRED':
                return 'danger';
            default:
                return 'secondary';
        }
    }

    private calculateTranslationKey(): string {
        if (this._resolvedFlowState) {
            return `RESOLVED_FLOWS_STATE.${this._resolvedFlowState}`;
        }
        if (this.flowState) {
            return `FLOW_STATE.${this._flowState}`;
        }
        return '';
    }
}
