import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { OtmId, TermRegistrationPeriod } from 'common-typescript/types';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { EntityService } from './entity.service';

const CONFIG = {
    ENDPOINTS: {
        backend: '/ori/api/term-registration-periods',
        termRegistrationPeriods() {
            return `${this.backend}`;
        },
        createTermRegistrationPeriod() {
            return `${this.backend}`;
        },
        updateTermRegistrationPeriod(periodId: OtmId) {
            return `${this.backend}/${periodId}`;
        },
        deleteTermRegistrationPeriod(periodId: OtmId) {
            return `${this.backend}/${periodId}`;
        },
    },
};

@Injectable({ providedIn: 'root' })
@NgEntityServiceConfig({
    baseUrl: CONFIG.ENDPOINTS.backend,
    resourceName: 'term-registration-periods',
})
export class TermRegistrationPeriodEntityService extends EntityService<TermRegistrationPeriodState> {

    constructor() {
        super(TermRegistrationPeriodStore, TermRegistrationPeriodQuery);
    }

    getPeriods(universityOrgId: OtmId): Observable<TermRegistrationPeriod[]> {
        return this.getHttp().get<TermRegistrationPeriod[]>(CONFIG.ENDPOINTS.termRegistrationPeriods(), { params: { universityOrgId } })
            .pipe(
                tap(periods => this.store.upsertMany(periods)),
            );
    }

    savePeriod(period: TermRegistrationPeriod): Observable<TermRegistrationPeriod> {
        return this.getHttp()
            .post<TermRegistrationPeriod>(CONFIG.ENDPOINTS.createTermRegistrationPeriod(), period)
            .pipe(
                tap(result => this.store.upsert(result.id, result)),
            );
    }

    updatePeriod(period: TermRegistrationPeriod): Observable<TermRegistrationPeriod> {
        return this.getHttp()
            .put<TermRegistrationPeriod>(CONFIG.ENDPOINTS.updateTermRegistrationPeriod(period.id), period)
            .pipe(
                tap(result => this.store.upsert(result.id, result)),
            );
    }

    deletePeriod(periodId: OtmId): Observable<TermRegistrationPeriod> {
        return this.getHttp()
            .delete<TermRegistrationPeriod>(CONFIG.ENDPOINTS.deleteTermRegistrationPeriod(periodId))
            .pipe(
                tap(() => this.store.remove(periodId)),
            );
    }
}

type TermRegistrationPeriodState = EntityState<TermRegistrationPeriod, OtmId>;

@StoreConfig({ name: 'term-registration-periods' })
class TermRegistrationPeriodStore extends EntityStore<TermRegistrationPeriodState> {}

class TermRegistrationPeriodQuery extends QueryEntity<TermRegistrationPeriodState> {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(store: EntityStore<TermRegistrationPeriodState>) {
        super(store);
    }
}
