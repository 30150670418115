import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { ApplicationDecision, OtmId } from 'common-typescript/types';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { EntityService } from './entity.service';

const CONFIG = {
    ENDPOINTS: {
        backend: '/ori/api',
        getById(applicationId: OtmId) {
            return `${this.backend}/student-applications/${applicationId}/decision`;
        },
    },
};

@Injectable({
    providedIn: 'root',
})
@NgEntityServiceConfig({
    baseUrl: CONFIG.ENDPOINTS.backend,
    resourceName: 'student-applications',
})
export class ApplicationDecisionEntityService extends EntityService<ApplicationDecisionState> {

    constructor() {
        super(ApplicationDecisionStore, ApplicationDecisionQuery);
    }

    getById(applicationId: OtmId, bypassStore: boolean = false, config = {}): Observable<ApplicationDecision> {
        return this.getHttp().get<ApplicationDecision>(CONFIG.ENDPOINTS.getById(applicationId))
            .pipe(
                tap(decision => this.store.upsert(applicationId, decision)),
                switchMap(() => this.query.selectEntity(applicationId)),
            );
    }
}

type ApplicationDecisionState = EntityState<ApplicationDecision, OtmId>;

@StoreConfig({ name: 'application-decision', idKey: 'applicationId' })
class ApplicationDecisionStore extends EntityStore<ApplicationDecisionState> {}

class ApplicationDecisionQuery extends QueryEntity<ApplicationDecisionState> {
    constructor(protected store: ApplicationDecisionStore) {
        super(store);
    }
}
