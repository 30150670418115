import { Injectable } from '@angular/core';
import { ModalService } from 'sis-common/modal/modal.service';

import {
    WorkflowCancellationInfoModalComponent, WorkflowCancellationInfoModalValues,
} from './workflow-cancellation-info-modal.component';

@Injectable({
    providedIn: 'root',
})
export class WorkflowCancellationInfoModalService {
    constructor(private modalService: ModalService) {}
    /**
     * Opens a new modal with the given values.
     *
     * @param values dialog content values
     * @return a Promise that resolves (without a value) when the dialog is closed
     */
    open(values: WorkflowCancellationInfoModalValues): Promise<void> {
        return this.modalService.open(WorkflowCancellationInfoModalComponent, values, { size: 'sm' }).result
            .catch(() => {}); // Treat closing with Esc key (that rejects the Promise) similarly to clicking the close icon
    }
}
