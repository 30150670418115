<div class="composite-rule">
    <div class="rule-header">
        <sis-count-phrase
                *ngIf="showCountPhrase"
                [rule]="compositeRule"
                [ruleValidationResult]="ruleValidationResult"
        ></sis-count-phrase>
    </div>
    <div class="rule-body">
        <ng-container *ngFor="let rule of sortedRules">
            <div class="{{ subRuleClassesByLocalId[rule.localId] }}">
                <sis-rule-select
                        [rule]="rule"
                        [parentModule]="parentModule"
                        [validatablePlan]="validatablePlan"
                        [planValidationResult]="planValidationResult"
                        [blockLevel]="blockLevel + 1"
                        [planStateObject]="planStateObject"
                        [planData]="planData"
                        [uiStateObject]="uiStateObject">
                </sis-rule-select>
            </div>
        </ng-container>

    </div>
</div>
