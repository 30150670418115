export const personGroupDetailsModelModule = 'sis-components.model.personGroupDetails';
(function () {
  personGroupDetailsJSDataModel.$inject = ["DS", "PersonGroupDetailsEndpoint", "PersonGroupTypes"];
  angular.module(personGroupDetailsModelModule, ['js-data', 'sis-components.constant.personGroupTypes']).factory('personGroupDetailsJSDataModel', personGroupDetailsJSDataModel).constant('PersonGroupDetailsEndpoint', {
    DEFAULT: '/ori/api/person-group-details',
    OWN: '/ori/api/person-group-details/own'
  });

  /**
   * @ngInject
   */
  function personGroupDetailsJSDataModel(DS, PersonGroupDetailsEndpoint, PersonGroupTypes) {
    return DS.defineResource({
      idAttribute: 'id',
      name: 'personGroupDetails',
      endpoint: PersonGroupDetailsEndpoint.DEFAULT,
      computed: {
        isTargetStudentGroup: ['type', function (type) {
          return type === PersonGroupTypes.TARGET_STUDENT_GROUP;
        }],
        isTutoringStudentGroup: ['type', function (type) {
          return type === PersonGroupTypes.TUTORING_STUDENT_GROUP;
        }]
      }
    });
  }
})();