<div class="modal-header">
  <h2 class="modal-title">{{ values?.title }}</h2>
  <sis-modal-close-button/>
</div>
<div class="modal-body">
  <p *ngIf="values?.message">{{ values.message }}</p>
  <sis-expandable [title]="'COMMON.SHOW_ERROR_DETAILS' | translate" level="3" size="xs" variant="link">
    <ng-template sisExpandableContent>
      <textarea [value]="errorInfo" class="form-control sis-mb-xxs" rows="10" readonly></textarea>
      <sis-button (clicked)="copyToClipboard()" size="sm" hollow="true">{{ 'COMMON.COPY_ERROR' | translate }}</sis-button>
    </ng-template>
  </sis-expandable>
</div>
<div class="modal-footer">
  <sis-button (clicked)="refreshPage()" [hollow]="true">{{ 'COMMON.REFRESH_PAGE' | translate }}</sis-button>
  <sis-button (clicked)="modal.dismiss()">{{ 'COMMON.CLOSE_ERROR' | translate }}</sis-button>
</div>
