import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
    CustomCourseUnitAttainment,
    CustomModuleAttainment,
    Workflow,
} from 'common-typescript/types';
import { EMPTY, Observable, take } from 'rxjs';
import { AuthService } from 'sis-common/auth/auth-service';
import { ModalService } from 'sis-common/modal/modal.service';

import { AppErrorHandler } from '../../error-handler/app-error-handler';
import { RECOMMENDATION_STATE, STUDENT_WORKFLOW_STATE } from '../../model/student-workflow-constants';
import { WorkflowEntityService } from '../../service/workflow-entity.service';

@Component({
    selector: 'sis-custom-attainment-credit-info-modal',
    templateUrl: './custom-attainment-credit-info-modal.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomAttainmentCreditInfoModalComponent implements OnInit {
    currentTabIndex = 0;
    workflowObs$: Observable<Workflow>;
    readonly studentWorkflowState = STUDENT_WORKFLOW_STATE;
    readonly recommendationState = RECOMMENDATION_STATE;

    constructor(@Inject(ModalService.injectionToken) public attainment: CustomCourseUnitAttainment | CustomModuleAttainment,
                public modal: NgbActiveModal,
                private workflowEntityService: WorkflowEntityService,
                private appErrorHandler: AppErrorHandler,
                private authService: AuthService) {
    }

    ngOnInit(): void {
        if (this.isLoggedIn()) {
            this.workflowObs$ = this.attainment.workflowId ?
                this.workflowEntityService.getById(this.attainment.workflowId)
                    .pipe(
                        take(1),
                        this.appErrorHandler.defaultErrorHandler(),
                    )
                : EMPTY;
        }
    }

    isLoggedIn(): boolean {
        return this.authService.loggedIn();
    }
}
