import { ChangeDetectionStrategy, Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CourseUnitAttainment, Thesis, ThesisSearchRequest } from 'common-typescript/types';
import { Observable } from 'rxjs';

import { SEARCH_PARAMETERS } from '../../ajs-upgraded-modules';
import { AppErrorHandler } from '../../error-handler/app-error-handler';
import { ThesisService } from '../../service/thesis.service';

@Component({
    selector: 'sis-thesis-details',
    templateUrl: './thesis-details.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThesisDetailsComponent implements OnInit {

    thesisSearchRequest: ThesisSearchRequest;
    thesisObs$: Observable<Thesis[]>;

    constructor(
        private errorHandler: AppErrorHandler,
        private thesisService: ThesisService,
        private appErrorHandler: AppErrorHandler,
        @Inject(SEARCH_PARAMETERS) private SearchParameters: any,
    ) { }

    @Input() attainment: CourseUnitAttainment;

    ngOnInit() {
        this.thesisSearchRequest = new this.SearchParameters().fetchLimitedResults();
        this.thesisSearchRequest.attainmentId = this.attainment.id;
        this.thesisSearchRequest.personId = this.attainment.personId;
        this.thesisObs$ = this.thesisService.search(this.thesisSearchRequest).pipe(this.errorHandler.defaultErrorHandler());
    }
}
