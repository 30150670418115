import { Component, Inject, Input, OnChanges, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { ValidatablePlan } from 'common-typescript/src/plan/validation/validatablePlan';
import { CurriculumPeriod, CustomModuleAttainment, EntityWithRule, Module, ModuleResultItem, OtmId, Rule } from 'common-typescript/types';
import _ from 'lodash';
import {
    ComponentDowngradeMappings,
    DowngradedComponent,
    StaticMembers,
} from 'sis-common/types/angular-hybrid';

import {
    CURRICULUM_PERIOD_SERVICE,
} from '../../../ajs-upgraded-modules';
import { PlanData, PlanStateObject } from '../../../service/plan-state.service';
import { InlineSearchTarget } from '../../inline-search/inline-search.component';
import { PLAN_ACTIONS_SERVICE_INJECTION_TOKEN, PlanActionsService } from '../../plan-actions-service/plan-actions.service';
import { UiStateObject } from '../../plan-edit/plan-edit.component';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-any-module-rule',
    templateUrl: './any-module-rule.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class AnyModuleRuleComponent implements OnInit, OnChanges {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.plan.rules.anyModuleRule',
        directiveName: 'sisAnyModuleRule',
    };

    selectedModules: Module[];
    selectedCustomModuleAttainments: CustomModuleAttainment[];
    curriculumPeriodIds: OtmId[];
    searchTarget = InlineSearchTarget.MODULE;
    codeUrns: string[] = [];

    MINOR_STUDY_RIGHT_SELECTION_TYPE_URN = 'urn:code:study-right-selection-type:minor-study-right';

    @Input() parentModule: EntityWithRule;
    @Input() rule: Rule;
    @Input() validatablePlan: ValidatablePlan;
    @Input() planValidationResult: any;
    @Input() planStateObject: PlanStateObject;
    @Input() planData: PlanData;
    @Input() uiStateObject: UiStateObject;
    @Input() level: number;
    @Input() blockLevel: number;

    constructor(
        @Optional() @Inject(PLAN_ACTIONS_SERVICE_INJECTION_TOKEN) private planActionsService: PlanActionsService,
        @Inject(CURRICULUM_PERIOD_SERVICE) private curriculumPeriodService: any,
    ) {}

    ngOnInit() {
        this.curriculumPeriodService.getCurriculumPeriodsForSearch(this.validatablePlan.plan.curriculumPeriodId).then((curriculumPeriods: CurriculumPeriod[]) => {
            this.curriculumPeriodIds = _.map(curriculumPeriods, 'id');
        });
    }

    ngOnChanges() {
        const ruleValidationResult = _.get(this.planValidationResult.ruleValidationResults, [this.parentModule.id, this.rule.localId]);
        if (ruleValidationResult) {
            const selectedModulesUnderAnyModuleRule = _.values(ruleValidationResult.selectedModulesById);
            const selectedCustomModuleAttainmentsUnderAnyModuleRule = _.values(ruleValidationResult.selectedCustomModuleAttainmentsById);
            const selectedModuleIdsUnderModule = _.map(this.validatablePlan.getSelectedModulesUnderModule(this.parentModule), 'id');
            const selectedCustomModuleAttainmentIdsUnderModule = _.map(this.validatablePlan.getSelectedCustomModuleAttainmentsUnderModule(this.parentModule), 'id');
            this.selectedModules = _.filter(
                selectedModulesUnderAnyModuleRule,
                module => _.includes(selectedModuleIdsUnderModule, module.id),
            );
            this.selectedCustomModuleAttainments = _.filter(
                selectedCustomModuleAttainmentsUnderAnyModuleRule,
                customModuleAttainment => _.includes(selectedCustomModuleAttainmentIdsUnderModule, customModuleAttainment.id),
            );
        }
        this.codeUrns = _.get(this.parentModule, 'contentFilter.studyRightSelectionType') === this.MINOR_STUDY_RIGHT_SELECTION_TYPE_URN ?
            [this.MINOR_STUDY_RIGHT_SELECTION_TYPE_URN] : [];
    }

    onSelectModuleResultItem(moduleResultItem: ModuleResultItem) {
        this.planActionsService?.selectModuleByGroupId?.(moduleResultItem.groupId, this.parentModule);
    }

    unselectModule(module: Module) {
        this.planActionsService?.removeModule?.(module, this.parentModule);
    }

    unselectCustomModuleAttainment(customModuleAttainment: CustomModuleAttainment) {
        this.planActionsService?.removeCustomModuleAttainmentById?.(customModuleAttainment.id, this.parentModule);
    }

}
