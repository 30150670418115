import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SisCommonModule } from 'sis-common/sis-common.module';
import { SisComponentsModule } from 'sis-components/sis-components.module';
import { StudyRightStatesModule } from 'sis-components/studyRight/study-right-states/study-right-states.module';
import { StudyRightStatusBadgeModule } from 'sis-components/studyRight/study-right-status-badge/study-right-status-badge.module';
import { StudyRightModule } from 'sis-components/studyRight/study-right.module';

import { CreateStudyCertificateModalComponent } from './create-study-certificate-modal/create-study-certificate-modal.component';
import { EnrolmentRightStudentStudyRightComponent } from './enrolment-right-student-study-right/enrolment-right-student-study-right.component';
import { StudyRightsComponent } from './study-rights/study-rights.component';

@NgModule({
    declarations: [
        StudyRightsComponent,
        CreateStudyCertificateModalComponent,
        EnrolmentRightStudentStudyRightComponent,
    ],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        SisCommonModule,
        SisComponentsModule,
        StudyRightModule,
        StudyRightStatusBadgeModule,
        StudyRightStatesModule,
    ],
})
export class StudyRightsModule {
}
