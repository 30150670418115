import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { DisclosureAuthorizationCategory, OtmId } from 'common-typescript/types';
import * as _ from 'lodash';
import { Observable, switchMap } from 'rxjs';
import { tap } from 'rxjs/operators';

import { EntityService } from './entity.service';

const CONFIG = {
    ENDPOINTS: {
        backend: '/kori/api/disclosures',
        categories(): string {
            return `${this.backend}/categories`;
        },
        archived(): any {
            return `${this.backend}/categories/archived`;
        },
        unanswered(studentId: OtmId): string {
            return `${this.backend}/categories/${studentId}/unanswered`;
        },
        archive(categoryId: any): any {
            return `${this.backend}/categories/${categoryId}/archive`;
        },
    },
};

@Injectable({ providedIn: 'root' })
@NgEntityServiceConfig({
    baseUrl: CONFIG.ENDPOINTS.backend,
    resourceName: 'disclosure-authorization-category',
})
export class DisclosureAuthorizationCategoryEntityService extends EntityService<DisclosureAuthorizationCategoryState> {

    constructor() {
        super(DisclosureAuthorizationCategoryStore, DisclosureAuthorizationCategoryQuery);
    }

    getCategories(universityOrgId: OtmId): Observable<DisclosureAuthorizationCategory[]> {
        return this.getHttp().get<DisclosureAuthorizationCategory[]>(CONFIG.ENDPOINTS.categories(), { params: { universityOrgId } })
            .pipe(
                tap((categories) => this.store.upsertMany(categories)),
                switchMap((categories) => this.query.selectMany(categories.map(cat => cat.id))),
            );
    }

    createCategory(category: Partial<DisclosureAuthorizationCategory>): Observable<DisclosureAuthorizationCategory> {
        return this.getHttp().post<DisclosureAuthorizationCategory>(CONFIG.ENDPOINTS.categories(), category)
            .pipe(
                tap(newCategory => this.store.upsert(newCategory.id, newCategory)),
                switchMap(newCategory => this.query.selectEntity(newCategory.id)),
            );
    }

    getArchivedCategories(universityOrgId: OtmId): Observable<DisclosureAuthorizationCategory[]> {
        return this.getHttp().get<DisclosureAuthorizationCategory[]>(CONFIG.ENDPOINTS.archived(), { params: { universityOrgId } })
            .pipe(
                tap((categories) => this.store.upsertMany(categories)),
                switchMap((categories) => this.query.selectMany(categories.map(cat => cat.id))),
            );
    }

    archiveCategory(category: Partial<DisclosureAuthorizationCategory>): Observable<DisclosureAuthorizationCategory> {
        _.unset(category, 'archivalDate');
        return this.getHttp().put<DisclosureAuthorizationCategory>(CONFIG.ENDPOINTS.archive(category.id), category)
            .pipe(
                tap(updatedCategory => this.store.upsert(category.id, updatedCategory)),
                switchMap(updatedCategory => this.query.selectEntity(updatedCategory.id)),
            );
    }
}
type DisclosureAuthorizationCategoryState = EntityState<DisclosureAuthorizationCategory, OtmId>;

@StoreConfig({ name: 'disclosure-authorization-category', idKey: 'id' })
class DisclosureAuthorizationCategoryStore extends EntityStore<DisclosureAuthorizationCategoryState> {}

class DisclosureAuthorizationCategoryQuery extends QueryEntity<DisclosureAuthorizationCategoryState> {
    constructor(protected store: DisclosureAuthorizationCategoryStore) {
        super(store);
    }
}
