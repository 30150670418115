import {
    ChangeDetectionStrategy,
    Component, EventEmitter,
    Inject,
    Input,
    OnChanges,
    Optional, Output,
    ViewEncapsulation,
} from '@angular/core';
import { Attainment, CourseUnit, Module, OtmId } from 'common-typescript/types';
import _ from 'lodash';

import {
    PLAN_ACTIONS_SERVICE_INJECTION_TOKEN,
    PlanActionsService,
} from '../../plan/plan-actions-service/plan-actions.service';
import { CourseUnitStateObject, PlanData, PlanStateObject } from '../../service/plan-state.service';
import { ScrollService } from '../../service/scroll.service';
import { UniversityService } from '../../service/university.service';
import { Action } from '../../study-boxes/study-action-box/study-action-box.component';

@Component({
    selector: 'sis-plan-structure-implicit-course-unit',
    templateUrl: './plan-structure-implicit-course-unit.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanStructureImplicitCourseUnitComponent implements OnChanges {

    @Input({ required: true }) courseUnit: CourseUnit;
    @Input({ required: true }) planStateObject: PlanStateObject;
    @Input({ required: true }) planData: PlanData;
    @Input({ required: true }) headingLevel: number;
    @Input() showSkipLink: boolean = false;
    @Input() selected?: boolean;
    @Input() action?: Action;
    @Output() actionChange? = new EventEmitter<OtmId>();

    courseUnitStateObject: CourseUnitStateObject;
    attainment: Attainment;
    isSubstituted: boolean = false;
    studyState: 'DRAFT' | 'DELETED';
    isCrossStudyCourseUnit: boolean = false;
    parentModule: Module;
    parentModuleId: OtmId;
    parentModuleElementId: string;
    isAboutToExpire: boolean;
    newerVersionAvailable: boolean = false;
    constructor(@Optional() @Inject(PLAN_ACTIONS_SERVICE_INJECTION_TOKEN) private planActionsService: PlanActionsService,
                private universityService: UniversityService,
                private scrollService: ScrollService) {
    }

    ngOnChanges(): void {
        this.courseUnitStateObject = _.get(this.planStateObject.courseUnits, this.courseUnit.id);
        if (this.courseUnitStateObject) {
            this.attainment = this.planData.attainmentsById[this.courseUnitStateObject.attainmentId];
            this.isSubstituted = this.attainment?.state === 'SUBSTITUTED';
            this.newerVersionAvailable = this.courseUnitStateObject.newerVersionAvailable;
        }
        this.parentModuleId = this.courseUnitStateObject.selectedParentModuleId;
        this.parentModule = this.planData?.modulesById[this.parentModuleId];
        this.parentModuleElementId = this.planStateObject.modules[this.parentModuleId]?.elementId;

        const isCourseUnitsOrganisationSameAsStudents = this.courseUnit?.universityOrgIds?.includes(this.universityService.getCurrentUniversityOrgId());
        if (!isCourseUnitsOrganisationSameAsStudents && this.courseUnit?.cooperationNetworkDetails?.networks.length > 0) {
            this.isCrossStudyCourseUnit = true;
        }
        if (this.courseUnit.documentState !== 'ACTIVE') {
            this.studyState = this.courseUnit.documentState;
        }
    }

    openCourseUnitInfoModal() {
        this.planActionsService?.openCourseUnitInfoModal?.(this.courseUnit);
    }

    scrollToParentModule() {
        this.scrollService.scrollToElementById(this.parentModuleElementId);
    }

}
