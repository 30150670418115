import angular from 'angular';
import { ModalService } from 'sis-common/modal/modal.service.ts';
import { commonStudyRightServiceModule } from 'sis-components/service/studyRight.service';
import { EnrolmentQuestionnaireModalComponent } from 'sis-components/enrolmentQuestionnaire/enrolmentQuestionnaireModal-ng.component.ts';
import { enrolmentQuestionnaireModalModule } from 'sis-components/enrolmentQuestionnaire/enrolmentQuestionnaireModal.component';
import { enrolmentCalculationModule } from 'sis-components/service/enrolmentCalculation.service';
import { educationServiceModule } from '../../../service/educationService';
import { planServiceModule } from '../../../service/planService';
import { CancelOrAbortEnrolmentModalComponent } from './cancelOrAbortEnrolmentModal-ng.component.ts';
import { cancelOrAbortEnrolmentModalModule } from './cancelOrAbortEnrolmentModal.component';
export const enrolmentModalServiceModule = 'student.common.components.course-unit-realisation.enrolmentModalService';
(function () {
  enrolmentModalService.$inject = ["$q", "modalService", "commonStudyRightService", "educationService", "planService", "enrolmentCalculationService"];
  angular.module(enrolmentModalServiceModule, [commonStudyRightServiceModule, educationServiceModule, planServiceModule, cancelOrAbortEnrolmentModalModule, enrolmentQuestionnaireModalModule, ModalService.downgrade.moduleName, enrolmentCalculationModule]).service('enrolmentModalService', enrolmentModalService);
  function enrolmentModalService($q, modalService, commonStudyRightService, educationService, planService, enrolmentCalculationService) {
    return {
      /**
       * Returns promise that resolves to object containing updated enrolment if operation was success.
       * Returns a rejected promise if user clicks on cancel.
       */
      openCancelEnrolmentModal(enrolment, courseUnitRealisation, courseUnit) {
        return $q.all([educationService.getMyStudyRightEducations(true), commonStudyRightService.studyRights()]).then(([myStudyRightEducations, myStudyRights]) => modalService.open(CancelOrAbortEnrolmentModalComponent, {
          abort: false,
          courseUnit,
          courseUnitRealisation,
          enrolment,
          myStudyRightEducations,
          myStudyRights
        }, {
          ariaLabelledBy: 'modal-title',
          size: 'sm'
        }).result);
      },
      /**
       * Returns promise that resolves to object containing updated enrolment if operation was success.
       * Returns a rejected promise if user clicks on cancel.
       */
      openAbortEnrolmentModal(enrolment, courseUnitRealisation, courseUnit) {
        return $q.all([educationService.getMyStudyRightEducations(true), commonStudyRightService.studyRights()]).then(([myStudyRightEducations, myStudyRights]) => modalService.open(CancelOrAbortEnrolmentModalComponent, {
          abort: true,
          courseUnit,
          courseUnitRealisation,
          enrolment,
          myStudyRightEducations,
          myStudyRights
        }, {
          ariaLabelledBy: 'modal-title',
          size: 'sm'
        }).result);
      },
      openEnrolmentQuestionnaireModal(enrolmentQuestionnaire, enrolmentQuestionnaireAnswers, courseUnitRealisation) {
        return modalService.open(EnrolmentQuestionnaireModalComponent, {
          enrolmentQuestionnaire,
          enrolmentQuestionnaireAnswers,
          courseUnitRealisation
        }, {
          size: 'sm',
          closeWithOutsideClick: true
        }).result;
      }
    };
  }
})();