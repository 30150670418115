<ng-container *transloco="let t">
  <div *ngIf="personGroupDetails$ | async as personGroupDetails">
    <div aria-labelledby="student-profile-person-groups-tab" id="person-groups" role="tabpanel" tabindex="0">
      <h2>{{ t('PROFILE.PERSON_GROUPS.TITLE') }}</h2>
      <div class="row" *ngIf="!personGroupDetails || personGroupDetails.length === 0">
        <div class="col-12 col-lg-7">
          <p class="guidance">
            {{ t('PROFILE.PERSON_GROUPS.NO_GROUPS') }}
          </p>
        </div>
      </div>

      <div *ngIf="personGroupDetails?.length > 0">
        <div class="row">
          <div class="col-12 col-lg-7">
            <p class="guidance">
              {{ t('PROFILE.PERSON_GROUPS.DESCRIPTION') }}
            </p>
          </div>
        </div>

        <div class="table-responsive">
          <table class="table styled-table" aria-label="{{ t('PROFILE.PERSON_GROUPS.TITLE') }}">
            <thead>
            <tr class="d-flex">
              <th class="col-4">
                {{ t('PROFILE.PERSON_GROUPS.NAME_TITLE') }}
              </th>
              <th class="col-4">
                {{ t('PROFILE.PERSON_GROUPS.TYPE_TITLE') }}
              </th>
              <th class="col-4">
                {{ t('PROFILE.PERSON_GROUPS.RESPONSIBLE_PERSONS_TITLE') }}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let group of personGroupDetails" class="d-flex">
              <td class="col-4">{{ group.name | localizedString }}</td>
              <td class="col-4">{{ t('PROFILE.PERSON_GROUPS.TYPE.' + group.type) }}</td>
              <td class="col-4">
                <ul class="responsibility-infos">
                  <li *ngFor="let responsibilityInfo of group.responsibilityInfos | slice:0:(customLimitsByGroupId[group.id] || RESPONSIBILITY_INFO_LIMIT); let i = index">
                    {{ responsibilityInfo | groupResponsibilityInfoName }}, {{responsibilityInfo.roleUrn | codeLocalizedName | async}}
                </ul>
                <sis-button *ngIf="group.responsibilityInfos.length > RESPONSIBILITY_INFO_LIMIT && !customLimitsByGroupId[group.id]" [link]="true" (clicked)="setCustomLimit(group)">
                  {{
                    t('PROFILE.PERSON_GROUPS.SHOW_MORE_RESPONSIBLE_PERSONS', { moreCount: (group.responsibilityInfos.length - RESPONSIBILITY_INFO_LIMIT) })
                  }}
                </sis-button>
                <sis-button *ngIf="(customLimitsByGroupId[group.id] || 0) > RESPONSIBILITY_INFO_LIMIT && customLimitsByGroupId[group.id]" [link]="true" (clicked)="resetCustomLimit(group)">
                  {{ t('PROFILE.PERSON_GROUPS.SHOW_LESS_RESPONSIBLE_PERSONS') }}
                </sis-button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</ng-container>


