import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CourseUnitRealisation } from 'common-typescript/types';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { CourseUnitRealisationEntityService } from 'sis-components/service/course-unit-realisation-entity.service';

import { CompletionMethodListItem } from '../completion-methods-list.component';

@Component({
    selector: 'app-completion-methods-list-view',
    templateUrl: './completion-methods-list-view.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompletionMethodsListViewComponent implements OnInit {
    @Input() selectedCompletionMethodListItem: CompletionMethodListItem;
    @Input() title?: string;
    @Input() subTitle?: string;
    courseUnitRealisations$: Observable<CourseUnitRealisation[]>;

    constructor(private courseUnitRealisationEntityService: CourseUnitRealisationEntityService,
                private appErrorHandler: AppErrorHandler) {
    }

    ngOnInit(): void {
        this.courseUnitRealisations$ = this.courseUnitRealisationEntityService.getPublishedAndActiveCourseUnitRealisationsByAssessmentItemIds(
            this.selectedCompletionMethodListItem.assessmentItems.map(assItem => assItem.id),
        ).pipe(
            this.appErrorHandler.defaultErrorHandler(),
        );
    }

    getRealisationsForAssessmentItem(realisations: CourseUnitRealisation[], assessmentItemId: string) {
        const filteredRealisation = _.filter(realisations, realisation => realisation.assessmentItemIds.includes(assessmentItemId));
        return _.orderBy(filteredRealisation, 'activityPeriod.startDate');
    }
}
