import angular from 'angular';
import { DOCUMENT } from '@angular/common';
import { injectHook } from 'sis-components/router/router-utils.ts';
(function () {
  angular.module('student.profile').config(["$stateProvider", $stateProvider => {
    const getProfile = function (studentProfileService) {
      return studentProfileService.profile();
    };
    getProfile.$inject = ["studentProfileService"];
    $stateProvider.state('student.logged-in.profile', {
      url: '/profile',
      resolve: {
        profile: getProfile
      },
      views: {
        '': {
          controller: ["profile", function (profile) {
            this.profile = profile;
          }],
          template: '<student-profile></student-profile>'
        },
        'title@student.logged-in.profile': {
          template: ''
        },
        'secondaryNavigation@student.logged-in.profile': {
          template: '<app-profile-secondary-navigation></app-profile-secondary-navigation>'
        }
      },
      data: {
        showHeader: true,
        showHome: true,
        hideLastNode: false,
        browserTabTitle: 'APPLICATION_STUDENT_PROFILE'
      },
      headerParams: {
        skipBreadcrumb: false,
        displayNameKey: 'MY_INFORMATION'
      },
      redirectTo: 'student.logged-in.profile.study-right'
    }).state('student.logged-in.profile.applications', {
      url: '/applications',
      headerParams: {
        displayNameKey: 'PROFILE.TAB_APPLICATIONS'
      },
      redirectTo: 'student.logged-in.profile.applications.list',
      template: '<ui-view></ui-view>',
      resolve: {
        codebooks: ["$q", "languageJSDataModel", "gradeScaleJSDataModel", ($q, languageJSDataModel, gradeScaleJSDataModel) => $q.all([languageJSDataModel.lazyGetAll(), gradeScaleJSDataModel.lazyGetAll()])]
      },
      onEnter: injectHook(transition => transition.injector().get(DOCUMENT)?.defaultView?.scrollTo(0, 0))
    });
  }]);
})();