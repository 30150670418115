export const personNameServiceModule = 'sis-components.service.personNameService';

/**
 * Upgraded in person-name.service.ts. Code duplication is outweighed by simplicity of imports.
 */
(function () {
  angular.module(personNameServiceModule, []).factory('personNameService', personNameService);

  /**
   * @ngInject
   */
  function personNameService() {
    var api = {
      getFullName: function (personObject) {
        return _.compact([personObject.firstNames || personObject.firstName, personObject.lastName]).join(' ');
      },
      getFullNameReversed: function (personObject) {
        return _.compact([personObject.lastName, personObject.firstNames || personObject.firstName]).join(' ');
      }
    };
    return api;
  }
})();