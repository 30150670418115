import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import angular from 'angular';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { addFocusToTabItem, commonKeyDown, commonKeyUp, removeFocus } from '../tabHelpers';

/**
 *  Tail: Contains possible extra information next to the tab title. This information can be a string or a number.
 *  Amount: Contains e.g. number of possible actions. This information can be a number.
 *  Id: Can be used as a data attribute for cypress tests etc.
 */
export interface Tab {
    title: string;
    tail?: string | number;
    amount?: number;
    id?: string;
}

/**
 *  Type defines the appearance of the navigation tab list.
 *  Tabs is usually for first level and pills for second level navigation.
 *  Wide is stacked mobile option.
 */
export type NavType = 'tabs' | 'pills' | 'wide';

/**
 * Size defines the breakpoint size up to which the navigation tab list is shown as a wide tab pile.
 */
export type NavSize = 'xs' | 'sm' | 'md';

/**
 * Sisu has two tab components, they have the same look and feel but few minor differences.
 *
 * <h2>Tab-content-switch</h2>
 * Component is made for use cases where changing tabs does not change the page URL, e.g. in modals/dialogs and wizards. That means each tab is a button instead of a link.
 * Tab content is shown based on the active tab index which is passed via `currentIndex` and `currentIndexChange`.
 *
 * <h2>Tab-navigation</h2>
 * Component is made for use cases where changing tabs changes the page URL. That means each tab is a link.
 * Link uses uiSref which references a state and automatically builds a href attribute based on state's url.
 *
 * <h2>Usage</h2>
 * <h3>Variants</h3>
 * There are three different tab variations in both components: `tabs`, `pills` and `wide`.
 * Essentially `tabs` is the primary tabs with $primary-color, `pills` is the secondary tabs with $middle-gray and `wide` is mobile version of the primary tabs where tabs are stacked.
 *
 * <h3>Tab signatures</h3>
 * Both components' tabs array need `title` for each tab. Tab-navigation also needs `path` which can take `params`.
 *
 * Each tab can have additional information as follows.
 *
 * Both components tab can use `tail` property. It is meant for secondary tabs ('pills'), it can be string or a number. This additional information is separated with a vertical line. This is used e.g. in open university offering and payments and scholarships.
 *
 * In addition, tab-content-switch has `amount` property. It is meant for primary tabs ('tabs' and 'wide'), it can only be a number and contains information about possible actions on certain elements. Information is inside brackets. This is used e.g. in bulk edit wizard.
 *
 */
@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-tab-content-switch',
    templateUrl: './tab-content-switch.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class TabContentSwitchComponent {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sisComponents.tabContentSwitch',
        directiveName: 'sisTabContentSwitch',
    };

    indexOfFocusedTab: number;

    /** Array of tabs */
    @Input() tabs: Tab[];
    /** Appearance of the tabs */
    @Input() type?: NavType = 'tabs';
    /** Breakpoint up to which tabs are shown in wide appearance i.e. stacked, works with type 'tabs' */
    @Input() wide?: NavSize;
    /** Inverted tab colors where active tab is black and others are blue, works with type 'tabs' */
    @Input() invert?: boolean;
    /** The `$scope` of the parent AngularJS component. Required when this component is used inside an AngularJS component to prevent any delays in change detection. */
    @Input() scope?: angular.IScope;
    /** Track active tab */
    @Input() currentIndex?: number;
    /** Index change emitter */
    @Output() currentIndexChange = new EventEmitter<number>();
    /** Component's wrapper ul element */
    @ViewChild('tabList') ul: ElementRef;

    constructor() {
        this.currentIndex = 0;
    }

    /** Check if additional information exists as tail */
    hasTail(tab: Tab): boolean {
        return (typeof tab?.tail === 'string' && tab.tail.length > 0) || typeof tab?.tail === 'number';
    }

    /** Check if additional information exists as amount */
    hasAmount(tab: Tab): boolean {
        return typeof tab?.amount === 'number';
    }

    /** Active tab handling */
    isSelected(index: number): boolean {
        return this.currentIndex === index;
    }

    /** Update and emit index when switching tab */
    switchTab(index: number): void {
        this.indexOfFocusedTab = index;
        this.currentIndex = index;

        if (this.scope) {
            this.scope.$apply(() => {
                this.currentIndexChange.emit(index);
            });
        } else {
            this.currentIndexChange.emit(index);
        }
    }

    /** Common key up handling */
    protected handleKeyUp(event: KeyboardEvent, index: number) {
        const direction = commonKeyUp(event);

        if (direction && direction !== 'click') {
            this.handleFocusChange(direction);
        } else if (direction === 'click') {
            this.handleFocusChange(direction, index);
        }
    }

    /** Enable home (go to the first tab element) and end (go to the last tab element) key actions */
    protected handleKeyDown(event: KeyboardEvent) {
        const direction = commonKeyDown(event);

        if (direction) {
            this.handleFocusChange(direction);
        }
    }

    /** Remove focus when blurring out of the tab */
    protected handleBlur(event: Event) {
        removeFocus(event);
    }

    /** Updated index of focused tab */
    private handleFocusChange(direction: string, index?: number) {
        this.indexOfFocusedTab = addFocusToTabItem(this.ul.nativeElement, this.tabs, this.indexOfFocusedTab, direction, index);
    }
}
