export const moduleResponsibilityInfoTypeModule = 'sis-components.model.moduleResponsibilityInfoType';
(function () {
  initModuleResponsibilityInfoTypeModel.$inject = ["moduleResponsibilityInfoTypeJSDataModel", "$http", "$log"];
  moduleResponsibilityInfoTypeModel.$inject = ["DS"];
  angular.module(moduleResponsibilityInfoTypeModule, ['js-data']).factory('moduleResponsibilityInfoTypeJSDataModel', moduleResponsibilityInfoTypeModel).run(initModuleResponsibilityInfoTypeModel);

  /**
   * @ngInject
   */
  function moduleResponsibilityInfoTypeModel(DS) {
    return DS.defineResource({
      idAttribute: 'urn',
      name: 'moduleResponsibilityInfoType',
      endpoint: '/kori/api/cached/codebooks/urn:code:module-responsibility-info-type',
      watchChanges: false
    });
  }

  /**
   * @ngInject
   */
  function initModuleResponsibilityInfoTypeModel(moduleResponsibilityInfoTypeJSDataModel, $http, $log) {
    $http.get(moduleResponsibilityInfoTypeJSDataModel.endpoint).then(function (response) {
      moduleResponsibilityInfoTypeJSDataModel.inject(_.values(response.data));
    }).catch(function (error) {
      $log.error(error);
    });
  }
})();