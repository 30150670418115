import { ChangeDetectionStrategy, Component, Inject, inject, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ParsedConstraintViolation } from 'common-typescript/types';
import { ModalService } from 'sis-common/modal/modal.service';

export interface ValidationErrorModalValues {
    titleKey: string;
    messageKey?: string;
    validationErrors?: ParsedConstraintViolation[];
}

export function validationErrorModalOpener(): (values: ValidationErrorModalValues) => NgbModalRef {
    const modalService = inject(ModalService);
    return values => modalService.open(ValidationErrorModalComponent, values, { size: 'sm', closeWithOutsideClick: true });
}

@Component({
    selector: 'sis-validation-error-modal',
    templateUrl: './validation-error-modal.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidationErrorModalComponent {

    constructor(
        protected modal: NgbActiveModal,
        @Inject(ModalService.injectionToken) protected values: ValidationErrorModalValues,
    ) {}
}
