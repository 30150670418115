<form class="reactive" (ngSubmit)="onSubmit()">
  <div class="modal-header">
    <h2 class="modal-title" [tabIndex]="0">{{'SIS_COMPONENTS.PLAN.EDIT_PLAN_NAME_MODAL.TITLE' | translate}}</h2>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <sis-text-field [label]="'SIS_COMPONENTS.PLAN.EDIT_PLAN_NAME_MODAL.NAME' | translate" [control]="name"></sis-text-field>
    </div>
  </div>
  <div class="modal-footer">
    <sis-button [dataCy]="'modal-close'" [hollow]="true" (clicked)="onCancel()">{{'SIS_COMPONENTS.PLAN.EDIT_PLAN_NAME_MODAL.CANCEL' | translate}}</sis-button>
    <sis-button [dataCy]="'modal-submit'" data-cy type="submit">{{'SIS_COMPONENTS.PLAN.EDIT_PLAN_NAME_MODAL.CONFIRM' | translate}}</sis-button>
  </div>
</form>
