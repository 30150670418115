<div class="search-filter education-filter">
  <div *ngIf="titleTextKey" class="filter-guidance">{{titleTextKey | translate}}</div>
  <form name="filterSelectorForm" [formGroup]="filterSelectorForm" novalidate>
    <div class="row">
      <div class="col-12">
        <sis-typeahead class="education-filter-typeahead"
                       data-cy="module-search-filter-input"
                       [model]="selectedModule"
                       [placeholder]="(placeholderTextKey ? placeholderTextKey : 'SEARCH.FILTER_SELECTOR.MODULE_INPUT_PLACEHOLDER') | translate"
                       [resultTemplate]="customModuleTemplate"
                       (selectItem)="toggleModuleValue.emit({ form: filterSelectorForm, value: $event.item })"
                       [typeahead]="searchModules.bind(this)">
        </sis-typeahead>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <ul class="filter-selectbox selectbox-multi ng-scope">
          <div *ngFor="let filterValue of listSelected()">
            <li  class="ng-binding parent-value">
            <button type="button"
                    class="btn btn-primary btn-naked btn-sm btn-just-icon"
                    (click)="toggleValue.emit({ event: $event, form: filterSelectorForm, value: filterValue })">
              <sis-icon icon="delete"></sis-icon>
            </button>
            <div class="parent-value-text">{{filterValue.name | localizedString}}</div>
          </li>
          </div>
        </ul>
      </div>
    </div>
    <div class="row filter-preview-row">
      <div class="col-9">
        <div class="filter-preview-holder">
          <span class="filter-preview-title">
            {{filterName}}
          </span>
          <span class="filter-preview-content">
            <span *ngFor="let filterValue of listSelected(); first as first"
                  [class.item-separator]="!first">{{getFilterName(filterValue)}}</span>
          </span>
        </div>
      </div>
      <div class="col-3">
        <button type="submit"
                class="btn btn-lg btn-primary filter-submit"
                (click)="submitFilter.emit()"
                [disabled]="filterSelectorForm.pristine"
                translate="SEARCH.FILTER_SELECTOR.OK">
        </button>
      </div>
    </div>
  </form>
</div>

<ng-template #customModuleTemplate let-result="result">
  <sis-tooltip [tooltipInnerHtml]="result.name" [appendToBody]="true" tooltipPlacement="left">
    <div class="sis-tooltip-trigger-content">
      <!--result item name is not localized string-->
      <span>{{result.code ? result.code + ', ' : '' }}{{result.name}}</span>
    </div>
  </sis-tooltip>
</ng-template>
