"use strict";

(function () {
  queueFactory.$inject = ["$q", "$log", "errorService"];
  angular.module('student.common.utils.queueFactory', ['sis-common.errorhandler.errorService']).factory('queueFactory', queueFactory);

  /**
   * Usage:
   *   1) var myQueue = queueFactory();
   *   2) myQueue.execute(valueForThis, callback, [arg1, arg2...])
   *   3) myQueue.execute(callback, [arg1, arg2...])
   *
   * @ngInject
   */
  function queueFactory($q, $log, errorService) {
    return function () {
      var queue = [],
        inProgress = false;
      return {
        isEmpty: isEmpty,
        size: size,
        execute: execute
      };
      function isEmpty() {
        return size() === 0;
      }
      function size() {
        return inProgress ? queue.length + 1 : queue.length;
      }
      function execute() {
        var valueForThis, callback, args;
        if (typeof arguments[0] === "function") {
          valueForThis = null;
          callback = arguments[0];
          args = Array.prototype.slice.call(arguments, 1);
        } else {
          valueForThis = arguments[0];
          callback = arguments[1];
          args = Array.prototype.slice.call(arguments, 2);
        }
        if (typeof callback !== "function") {
          throw "Expected callback function as either first or second argument, got " + callback;
        }
        return enqueue(valueForThis, callback, args);
      }
      function enqueue(valueForThis, callback, args) {
        var element = {
          deferred: $q.defer(),
          valueForThis: valueForThis,
          callback: callback,
          args: args
        };
        queue.push(element);
        next();
        return element.deferred.promise;
      }
      function next() {
        if (queue.length > 0 && !inProgress) {
          inProgress = true;
          var element = queue.splice(0, 1)[0];
          try {
            $q.when(element.callback.apply(element.valueForThis, element.args)).then(function (success) {
              inProgress = false;
              return element.deferred.resolve(success);
            }, function (failure) {
              $log.error(failure);
              errorService.showSystemError(failure);
              inProgress = false;
              return element.deferred.reject(failure);
            }, function (notify) {
              element.deferred.notify(notify);
            }).finally(function () {
              next();
            });
          } catch (e) {
            $log.error(e);
            inProgress = false;
            element.deferred.reject(e);
            next();
          }
        }
      }
    };
  }
})();