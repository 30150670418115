<section class="glider-contain" [ngClass]="containerWidthClass" [attr.aria-labelledby]="carouselHeaderId">
  <div class="glider-controls" [attr.aria-labelledby]="carouselHeaderId" [ngClass]="{'hide': hideControls()}">
    <button [attr.aria-label]="(prevAriaLabel | translate)" class="glider-prev" #prevRef>
      <sis-icon icon="arrow-big" [iconClass]="'ccw-90'" color="primary"></sis-icon>
    </button>
    <div *ngIf="isMobileView()" class="browsing-details">
      <div *ngIf="mobileViewDescription">{{ mobileViewDescription | translate }}</div>
      <div>{{ getCurrentSlideCount() }}</div>
    </div>
    <button [attr.aria-label]="(nextAriaLabel | translate)" class="glider-next" #nextRef>
      <sis-icon icon="arrow-big" [iconClass]="'cw-90'" color="primary"></sis-icon>
    </button>
  </div>
  <div class="glider" tabindex="-1" #gliderRef>
    <div class="glider-track" [attr.role]="contentRole" [attr.aria-labelledby]="carouselHeaderId">
      <ng-content></ng-content>
    </div>
  </div>
</section>
