import angular from 'angular';
export const enrolmentCalculationResultForPersonModelModule = 'student.common.model.enrolmentCalculationResultForPerson';
(function () {
  enrolmentCalculationResultForPersonModel.$inject = ["DS"];
  angular.module(enrolmentCalculationResultForPersonModelModule, ['js-data']).factory('enrolmentCalculationResultForPersonModel', enrolmentCalculationResultForPersonModel);

  /**
   * @ngInject
   */
  function enrolmentCalculationResultForPersonModel(DS) {
    // Save, delete, update will not work
    return DS.defineResource({
      idAttribute: 'courseUnitRealisationId',
      name: 'enrolmentCalculationResultForPerson',
      endpoint: '/ilmo/api/my-enrolment-calculation-results'
    });
  }
})();