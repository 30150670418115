<sis-course-unit-count-rule *ngIf="rule.type === 'CourseUnitCountRule'"
                            [rule]="rule"
                            [parentModule]="parentModule"
                            [validatablePlan]="validatablePlan"
                            [planValidationResult]="planValidationResult"
                            [blockLevel]="blockLevel"
                            [planStateObject]="planStateObject"
                            [planData]="planData"
                            [uiStateObject]="uiStateObject">

</sis-course-unit-count-rule>
<sis-credits-rule *ngIf="rule.type === 'CreditsRule'"
                  [creditsRule]="rule"
                  [parentModule]="parentModule"
                  [validatablePlan]="validatablePlan"
                  [planValidationResult]="planValidationResult"
                  [blockLevel]="blockLevel"
                  [planStateObject]="planStateObject"
                  [planData]="planData"
                  [uiStateObject]="uiStateObject">

</sis-credits-rule>
<sis-composite-rule *ngIf="rule.type === 'CompositeRule'"
                    [compositeRule]="rule"
                    [parentModule]="parentModule"
                    [validatablePlan]="validatablePlan"
                    [planValidationResult]="planValidationResult"
                    [blockLevel]="blockLevel"
                    [planStateObject]="planStateObject"
                    [planData]="planData"
                    [uiStateObject]="uiStateObject">

</sis-composite-rule>
<sis-course-unit-rule *ngIf="rule.type === 'CourseUnitRule'"
                      [rule]="rule"
                      [parentModule]="parentModule"
                      [validatablePlan]="validatablePlan"
                      [planValidationResult]="planValidationResult"
                      [blockLevel]="blockLevel"
                      [planStateObject]="planStateObject"
                      [planData]="planData"
                      [uiStateObject]="uiStateObject">

</sis-course-unit-rule>
<sis-module-rule *ngIf="rule.type === 'ModuleRule'"
                 [rule]="rule"
                 [parentModule]="parentModule"
                 [validatablePlan]="validatablePlan"
                 [planValidationResult]="planValidationResult"
                 [blockLevel]="blockLevel" [planStateObject]="planStateObject"
                 [planData]="planData"
                 [uiStateObject]="uiStateObject">

</sis-module-rule>
<sis-any-course-unit-rule *ngIf="rule.type === 'AnyCourseUnitRule'"
                          [rule]="rule"
                          [parentModule]="parentModule"
                          [validatablePlan]="validatablePlan"
                          [planValidationResult]="planValidationResult"
                          [blockLevel]="blockLevel"
                          [planStateObject]="planStateObject"
                          [planData]="planData"
                          [uiStateObject]="uiStateObject">

</sis-any-course-unit-rule>
<sis-any-module-rule *ngIf="rule.type === 'AnyModuleRule'"
                     [rule]="rule"
                     [parentModule]="parentModule"
                     [validatablePlan]="validatablePlan"
                     [planValidationResult]="planValidationResult"
                     [blockLevel]="blockLevel"
                     [planStateObject]="planStateObject"
                     [planData]="planData"
                     [uiStateObject]="uiStateObject"
></sis-any-module-rule>