<sis-description-list [fillByColumn]="false">
  <dl> <!-- This dl element is just to trick SonarQube, it won't actually end up in the final HTML at all -->
    <ng-template #listItem *ngFor="let responsibilityInfo of sortedResponsibilityInfos">
      <dt class="sis-label">
        {{ responsibilityInfo.roleUrn | codeLocalizedName | async }} {{responsibilityInfo.validityPeriod.startDate || responsibilityInfo.validityPeriod.endDate ? '(' + (responsibilityInfo.validityPeriod | localDateRange) + ')' : ''}}
      </dt>
      <dd>
        <ng-container *ngIf="responsibilityInfo.personId">
          <p class="bold compact">{{responsiblePersonsById[responsibilityInfo.personId] | personFullName }}</p>
          <p class="compact">{{ responsiblePersonsById[responsibilityInfo.personId]?.emailAddress }}</p>
        </ng-container>
        <p *ngIf="responsibilityInfo.text" class="compact"
           [class.additional-data]="responsibilityInfo.personId"
           [class.additional-data-no-person]="!responsibilityInfo.personId">
          {{ responsibilityInfo.text | localizedString: language }}
        </p>
      </dd>
    </ng-template>
  </dl>
</sis-description-list>
