import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { EnvironmentService } from '../../environmentService/environment.service';

import { key as frontendInfoHeaderKey, value as frontendInfoHeaderValue } from './frontend-info-header';

@Injectable({ providedIn: 'root' })
export class FrontendInfoInterceptor implements HttpInterceptor {

    constructor(private environmentService: EnvironmentService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // do not add headers to file upload/download requests
        if (request.headers.get('isPreSignedUrl')) {
            return next.handle(request);
        }

        const modifiedRequest = request.clone({
            setHeaders: {
                [this.getFrontendInfoHeaderKey()]: this.getFrontendInfoHeaderValue(),
            },
        });

        return next.handle(modifiedRequest);
    }

    getFrontendInfoHeaderKey(): string {
        return frontendInfoHeaderKey();
    }

    getFrontendInfoHeaderValue(): string {
        const frontendName = this.environmentService.frontendName;
        return frontendInfoHeaderValue(frontendName);
    }
}
