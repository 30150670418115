import { Component, HostBinding, ViewEncapsulation } from '@angular/core';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-generic-container',
    templateUrl: './generic-container.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class GenericContainerComponent {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.lib.containers.generic-container',
        directiveName: 'sisGenericContainer',
    };

    @HostBinding('class') classes = 'sis-generic-container';

}
