import { Component, Inject, Input, OnChanges, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { ValidatablePlan } from 'common-typescript/src/plan/validation/validatablePlan';
import { CourseUnit, CourseUnitResultItem, CurriculumPeriod, CustomCourseUnitAttainment, CustomStudyDraft, EntityWithRule, OtmId, Rule } from 'common-typescript/types';
import _ from 'lodash';
import {
    ComponentDowngradeMappings,
    DowngradedComponent,
    StaticMembers,
} from 'sis-common/types/angular-hybrid';

import {
    COMMON_PLAN_SELECTION_SERVICE,
    COMMON_STUDY_RIGHT_SERVICE,
    CURRICULUM_PERIOD_SERVICE,
} from '../../../ajs-upgraded-modules';
import { PlanData, PlanStateObject } from '../../../service/plan-state.service';
import { StudySelectorService } from '../../../service/study-selector.service';
import { InlineSearchTarget } from '../../inline-search/inline-search.component';
import { PLAN_ACTIONS_SERVICE_INJECTION_TOKEN, PlanActionsService } from '../../plan-actions-service/plan-actions.service';
import { UiStateObject } from '../../plan-edit/plan-edit.component';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-any-course-unit-rule',
    templateUrl: './any-course-unit-rule.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class AnyCourseUnitRuleComponent implements OnInit, OnChanges {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.plan.rules.anyCourseUnitRule',
        directiveName: 'sisAnyCourseUnitRule',
    };

    courseUnits: CourseUnit[];
    customCourseUnitAttainments: CustomCourseUnitAttainment[];
    customStudyDrafts: CustomStudyDraft[];
    curriculumPeriodIds: OtmId[];
    searchTarget = InlineSearchTarget.COURSE_UNIT;
    crossStudyFeaturesEnabled = false;
    cooperationNetworkIds: OtmId[] = null;
    effectiveCooperationNetworkIdsForSearch: OtmId[];
    ripaStudiesIncluded = false;

    @Input() parentModule: EntityWithRule;
    @Input() rule: Rule;
    @Input() validatablePlan: ValidatablePlan;
    @Input() planValidationResult: any;
    @Input() planStateObject: PlanStateObject;
    @Input() planData: PlanData;
    @Input() uiStateObject: UiStateObject;
    @Input() level: number;
    @Input() blockLevel: number;

    constructor(
        private studySelectorService: StudySelectorService,
        @Optional() @Inject(PLAN_ACTIONS_SERVICE_INJECTION_TOKEN) private planActionsService: PlanActionsService,
        @Inject(COMMON_PLAN_SELECTION_SERVICE) private planSelectionService: any,
        @Inject(CURRICULUM_PERIOD_SERVICE) private curriculumPeriodService: any,
        @Inject(COMMON_STUDY_RIGHT_SERVICE) private commonStudyRightService: any,
    ) {}

    ngOnInit() {
        this.curriculumPeriodService.getCurriculumPeriodsForSearch(this.validatablePlan.plan.curriculumPeriodId).then((curriculumPeriods: CurriculumPeriod[]) => {
            this.curriculumPeriodIds = _.map(curriculumPeriods, 'id');
        });
        this.crossStudyFeaturesEnabled = _.get(this.uiStateObject, 'universitySettings.frontendFeatureToggles.crossStudyFeaturesEnabledForStudent') || false;
        if (this.crossStudyFeaturesEnabled && this.planStateObject.studyRight) {
            this.cooperationNetworkIds = this.commonStudyRightService.getValidCooperationNetworkIds(this.planStateObject.studyRight);
        }
    }

    ngOnChanges() {
        const ruleValidationResult = _.get(this.planValidationResult.ruleValidationResults, [this.parentModule.id, this.rule.localId]);
        if (ruleValidationResult) {
            this.courseUnits = _.chain(ruleValidationResult.selectedCourseUnitsById)
                .values()
                .filter(courseUnit => !this.validatablePlan.isCourseUnitInPlanAsSubstitute(courseUnit))
                .value();
            this.customCourseUnitAttainments = _.values(ruleValidationResult.selectedCustomCourseUnitAttainmentsById);
            this.customStudyDrafts = _.values(ruleValidationResult.selectedCustomStudyDraftsById);
        }
    }

    onSelectCourseUnitResultItem(courseUnitResultItem: CourseUnitResultItem) {
        this.planActionsService?.selectCourseUnitByGroupId?.(courseUnitResultItem.groupId, this.parentModule);
    }

    unselectCourseUnit(courseUnit: CourseUnit) {
        this.planActionsService?.removeCourseUnit?.(courseUnit, this.parentModule);
    }

    unselectCustomCourseUnitAttainment(customCourseUnitAttainment: CustomCourseUnitAttainment) {
        this.planActionsService?.removeCustomCourseUnitAttainmentById?.(customCourseUnitAttainment.id, this.parentModule);
    }

    unselectCustomStudyDraft(customStudyDraft: CustomStudyDraft) {
        this.planActionsService?.removeCustomStudyDraft?.(customStudyDraft, this.parentModule);
    }

    toggleRipaStudiesForSearch = (ripaStudiesIncluded: boolean) => {
        this.ripaStudiesIncluded = ripaStudiesIncluded;
        this.effectiveCooperationNetworkIdsForSearch = ripaStudiesIncluded ? this.cooperationNetworkIds : null;
    };

}
