<ng-container *transloco="let t">
  <app-cart-container [pageTitle]="'OPEN_UNIVERSITY_CART.WIZARD.PAYMENT_PROCESSING.NAME'">
    <div class="row">
      <div class="col-md-10 col-sm-12">
        <p class="mt-3">{{ t('OPEN_UNIVERSITY_CART.WIZARD.PAYMENT_PROCESSING.DESCRIPTION') }}</p>
        <p data-cy="contact-organizer-guidance">
          <ng-container *ngTemplateOutlet="contactOrganizer"></ng-container>
          <ng-container *ngIf="(cart$ | async)?.orderNumber as orderNumber">
            <transloco key="OPEN_UNIVERSITY.ERRORS.PROVIDE_ORDER_NUMBER" [params]="{ orderNumber: orderNumber }" />
          </ng-container>
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-10 col-sm-12">
        <sis-wizard-step-buttons
          [backButtonDisplay]="false"
          [forwardButtonTitle]="'OPEN_UNIVERSITY_CART.WIZARD.PAYMENT_PROCESSING.RETURN_TO_SEARCH_BUTTON' | translate"
          (forward)="backToSearch()">
        </sis-wizard-step-buttons>
      </div>
    </div>
  </app-cart-container>

  <ng-template #contactOrganizer>
    {{ t('OPEN_UNIVERSITY_CART.WIZARD.PAYMENT_PROCESSING.GUIDANCE_CONTACT_ORGANIZER') }}<!--
    --><ng-container *ngIf="openUniversitySettings$ | async as settings">
      (<app-customer-service-contact-link [settings]="settings"></app-customer-service-contact-link>)<!--
    --></ng-container>.
  </ng-template>
</ng-container>
