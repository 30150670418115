<sis-basic-grouping-panel [heading]="studyYearName" [level]="level">
  <sis-tiny-badge *ngIf="hasMissingPayments" variant="danger" class="header-badge">
    {{'STUDY_RIGHTS.TUITION_FEE_PAYMENT_MISSING' | translate}}
  </sis-tiny-badge>
  <sis-description-list [columnCount]="1">
    <dl>
      <ng-template #listItem *ngFor="let registration of termRegistrations">
        <dt class="sis-label" [attr.data-cy]="'registration-term'">{{ studyTermNamesByTermIndex.get(registration.studyTerm.termIndex) }}</dt>
        <dd [attr.data-cy]="'registration-item'">
          <sis-description-list-compact>
            <dl>
              <ng-template #compactListItem>
                <dt class="sis-label colon" [attr.data-cy]="'registration-item-label'">{{ 'STUDY_RIGHTS.TERM_REGISTRATION' | translate }}</dt>
                <dd class="sis-value">
                  {{ ('STUDY_RIGHTS.REGISTRATION.' + (registration.termRegistrationType || 'MISSING')) | translate
                  }}<ng-container *ngIf="registration.statutoryAbsence">, {{'STUDY_RIGHTS.STATUTORY_ABSENCE' | translate}}</ng-container>
                </dd>
              </ng-template>
              <ng-template #compactListItem>
                <dt class="sis-label colon" [attr.data-cy]="'registration-item-label'">{{ 'STUDY_RIGHTS.REGISTRATION_DATE' | translate }}</dt>
                <dd class="sis-value">{{ registration.registrationDate | localDateFormat }}</dd>
              </ng-template>
              <ng-template #compactListItem *ngIf="registration.previousRegistrationType">
                <dt class="sis-label colon" [attr.data-cy]="'registration-item-label'">{{ 'STUDY_RIGHTS.PREVIOUS_TERM_REGISTRATION' | translate }}</dt>
                <dd class="sis-value">
                  {{ ('STUDY_RIGHTS.REGISTRATION.' + registration.previousRegistrationType) | translate }},
                  {{ registration.previousRegistrationDate|localDateFormat }}
                </dd>
              </ng-template>
              <ng-template #compactListItem *ngIf="hasTuitionFeeObligationByTermIndex.get(registration.studyTerm.termIndex)">
                <dt class="sis-label colon" [attr.data-cy]="'registration-item-label'">{{ 'STUDY_RIGHTS.TUITION_FEE_PAYMENT' | translate }}</dt>
                <dd class="sis-value">
                  <sis-icon [icon]="registration.tuitionFeePaymentState === 'PAID' ? 'check' : 'fail'"
                            [color]="registration.tuitionFeePaymentState === 'PAID' ? 'success' : 'danger'"></sis-icon>
                  {{ 'STUDY_RIGHTS.TUITION_FEE_PAYMENT_STATE.' + (registration.tuitionFeePaymentState || 'OUTSTANDING') | translate }}
                </dd>
              </ng-template>
            </dl>
          </sis-description-list-compact>
        </dd>
      </ng-template>
    </dl>
  </sis-description-list>
</sis-basic-grouping-panel>
