<div *ngFor="let ongoingTermRegistrationPeriod of ongoingTermRegistrationPeriods$ | async" class="row alert-top-spacing">
  <div class="col-md-12">
    <sis-contextual-notification variant="warning">{{ongoingTermRegistrationPeriod?.registrationInfo | localizedString}}</sis-contextual-notification>
  </div>
</div>
<div *ngFor="let  neglectedTermRegistrationPeriod of this.neglectedTermRegistrationPeriods$ | async" class="row alert-top-spacing">
  <div class="col-md-12">
    <sis-contextual-notification variant="danger">{{neglectedTermRegistrationPeriod?.studyRightRecoveryInfo | localizedString}}</sis-contextual-notification>
  </div>
</div>
