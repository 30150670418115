<ng-container *ngIf="control">
  <label [class]="labelState">
    <ng-container *ngIf="label">
      {{label}}
    </ng-container>
    <sis-markup-string-view *ngIf="labelHTML" [content]="labelHTML"></sis-markup-string-view>
    <sis-popover *ngIf="guidanceText"
      popoverClass="dark"
      [popoverPlacement]="'top'"
                 (shown)="popoverShown = true"
                 (hidden)="popoverShown = false">
      <button type="button"
              class="non-button sis-popover-trigger-content"
              [attr.aria-label]="'ARIA_LABEL.SHOW_DETAILS' | translate"
              [attr.aria-expanded]="popoverShown">
        <sis-icon icon="info-circle-fill" aria-hidden="true"></sis-icon>
      </button>
      <div class="sis-popover-popover-content">
        <p>{{guidanceText}}</p>
      </div>
    </sis-popover>
    <div class="sis-guidance sis-guidance__primary" *ngIf="helpBlockHTML">
      <sis-markup-string-view [content]="helpBlockHTML"></sis-markup-string-view>
    </div>
    <div class="d-flex input-and-type">
      <input *ngIf="!textarea"
             [attr.id]="id"
             [type]="type"
             class="form-control"
             [class.invalid]="control.touched && control.invalid"
             [name]="name"
             [formControl]="control"
             [attr.placeholder]="placeholder"
             [attr.autocomplete]="autocomplete"
             [attr.disabled]="disabled ? '' : null"
             [attr.aria-required]="isRequired"
             [attr.aria-describedby]="id + '_errors ' + id + '_help-block'"
             data-cy="text-field-input"
      />
      <span *ngIf="valueType" class="type-block ms-4">{{valueType | translate }}</span>
    </div>
    <textarea *ngIf="textarea"
              [attr.id]="id"
              class="form-control"
              [class.invalid]="control.touched && control.invalid"
              [name]="name"
              [formControl]="control"
              data-cy="text-field-input"
              [attr.disabled]="disabled ? '' : null"
              [attr.placeholder]="placeholder"
              [attr.autocomplete]="autocomplete"
              [attr.aria-required]="control.errors?.required !== null"
              [attr.aria-describedby]="id + '_errors ' + id + '_help-block'"
    >
    </textarea>
  </label>
  <sis-validation-errors *ngIf="isValidationErrorBlockVisible()" [validationErrors]="control.errors" [formFieldId]="id" [ariaLive]="false"/>
  <p *ngIf="helpBlock" [id]="id + '_help-block'" class="help-block">{{helpBlock | translate}}</p>
</ng-container>
