<ng-container *ngIf="this.enrolment | async as enrolment">
  <ng-container *transloco="let t">
    <div class="modal-header">
      <app-enrolment-header-info [enrolment]="enrolment"></app-enrolment-header-info>
      <sis-modal-close-button/>
    </div>
    <div class="modal-body">
      <h3>{{ t('ENROLMENTS.ENROLMENT_DIALOG.HEADER') }}</h3>
      <app-enrolment-basic-info [enrolment]="enrolment"></app-enrolment-basic-info>
      <ng-container *ngIf="this.enrolment.state !== 'NOT_ENROLLED'">
        <app-enrolment-study-groups-info [enrolment]="enrolment"></app-enrolment-study-groups-info>
        <ng-container *ngIf="(enrolmentQuestionnaire$ | async)?.enrolmentQuestions?.length > 0">
          <h3> {{ t('ENROLMENTS.ENROLMENT_MODAL.ENROLMENT_QUESTIONNAIRE') }}</h3>
          <app-enrolment-questionnaire-answers [enrolment]="enrolment" />
        </ng-container>
      </ng-container>
    </div>
    <div class="modal-footer confirm-dialog-footer">
      <sis-button (clicked)="activeModal.dismiss()">{{ t('BUTTON.CLOSE') }}</sis-button>
    </div>
  </ng-container>
</ng-container>
