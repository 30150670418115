<article
  [class.not-selected]="this.style === 'NOT_SELECTED'"
  [class.selected]="this.style === 'SELECTED'">
  <div [class.not-selected-header]="this.style === 'NOT_SELECTED'"
       [class.selected-header]="this.style === 'SELECTED'"
       class="study-sub-group-view__header p-4">
    <h5>{{ studySubGroup.name | localizedString }}</h5>
  </div>
  <div class="p-4">
    <app-study-sub-group-info [ssgSize]="studySubGroup.size"
                              [studyEventIds]="studySubGroup.studyEventIds ? studySubGroup.studyEventIds : []"
                              [teacherIds]="studySubGroup.teacherIds ? studySubGroup.teacherIds : []"></app-study-sub-group-info>
  </div>
</article>
