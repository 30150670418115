<sis-description-list [columnCount]="1">
  <dl>
    <ng-template *ngFor="let codeBook of codebooks" #listItem>
      <dt class="sis-label">{{ codeBook.name | localizedString }}</dt>
      <dd>
        <p *ngFor="let codeUrn of customCodesByCodebookUrnMap[codeBook.urn]">
          {{ customCodes[codeUrn]?.name | localizedString }}
        </p>
      </dd>
    </ng-template>
  </dl>
</sis-description-list>
