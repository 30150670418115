import { inject, Injectable } from '@angular/core';
import { dateUtils } from 'common-typescript';
import {
    AssessmentItem,
    Attainment,
    CourseUnit,
    CourseUnitRealisation,
    Enrolment,
    EnrolmentState,
    LocalDateTimeRange,
    OtmId,
    StudyPeriod,
} from 'common-typescript/types';
import moment from 'moment';
import { combineLatest, map, Observable, of, switchMap } from 'rxjs';
import { isAssessmentItemAttainment } from 'sis-components/attainment/AttainmentUtil';
import { AssessmentItemEntityService } from 'sis-components/service/assessment-item-entity.service';
import { CourseUnitEntityService } from 'sis-components/service/course-unit-entity.service';
import { CourseUnitRealisationEntityService } from 'sis-components/service/course-unit-realisation-entity.service';
import { StudyYearsEntityService } from 'sis-components/service/study-years-entity.service';
import { UniversityService } from 'sis-components/service/university.service';

import { AttainmentStudentService } from '../../../common/service/attainment-student.service';
import { EnrolmentStudentService } from '../../../common/service/enrolment-student.service';

function hasAttainmentForCourseUnitRealisation(attainments: Attainment[], courseUnitRealisationId: OtmId): boolean {
    return attainments.filter(isAssessmentItemAttainment).some(att => att.courseUnitRealisationId === courseUnitRealisationId);
}

export interface StudyData {
    enrolment: Enrolment;
    courseUnit: CourseUnit;
    assessmentItem: AssessmentItem;
    courseUnitRealisation: CourseUnitRealisation;
}

@Injectable({ providedIn: 'root' })
export class UpcomingStudiesService {

    private readonly assessmentItemService = inject(AssessmentItemEntityService);
    private readonly attainmentService = inject(AttainmentStudentService);
    private readonly courseUnitRealisationService = inject(CourseUnitRealisationEntityService);
    private readonly courseUnitService = inject(CourseUnitEntityService);
    private readonly enrolmentService = inject(EnrolmentStudentService);
    private readonly studyYearService = inject(StudyYearsEntityService);
    private readonly universityService = inject(UniversityService);

    private readonly acceptedEnrolmentStates: EnrolmentState[] = ['ENROLLED', 'RESERVED', 'CONFIRMED'];
    private readonly includedEnrolmentStates: EnrolmentState[] = ['NOT_ENROLLED', 'PROCESSING', 'INVALID', 'REJECTED',
        ...this.acceptedEnrolmentStates];

    getUpcomingStudies(): Observable<StudyData[]> {
        return combineLatest([
            this.enrolmentService.getAllEnrolments(),
            this.attainmentService.getMyValidAttainments(),
        ])
            .pipe(
                map(([enrolments, attainments]) => enrolments
                    .filter(enrolment => this.includedEnrolmentStates.includes(enrolment.state))
                    .filter(enrolment => !hasAttainmentForCourseUnitRealisation(attainments, enrolment.courseUnitRealisationId)),
                ),
                switchMap(enrolments => combineLatest([
                    of(enrolments),
                    this.courseUnitRealisationService.getByIds(enrolments.map(enrolment => enrolment.courseUnitRealisationId)),
                    this.studyYearService.getCurrentStudyPeriod(this.universityService.getCurrentUniversityOrgId()),
                ])),
                map(([enrolments, courseUnitRealisations, currentStudyPeriod]) =>
                    this.courseUnitRealisationService.sortByActivityPeriodAndName(courseUnitRealisations)
                        .map(courseUnitRealisation => ({
                            courseUnitRealisation,
                            enrolment: enrolments.find(enrolment => enrolment.courseUnitRealisationId === courseUnitRealisation.id),
                        }))
                        .filter(({ enrolment, courseUnitRealisation }) =>
                            this.isUpcomingStudy(enrolment, courseUnitRealisation, currentStudyPeriod)),
                ),
                switchMap(partialUpcomingStudies => combineLatest([
                    of(partialUpcomingStudies),
                    this.assessmentItemService.getByIds(partialUpcomingStudies.map(study => study.enrolment.assessmentItemId)),
                    this.courseUnitService.getByIds(partialUpcomingStudies.map(study => study.enrolment.courseUnitId)),
                ])),
                map(([partialUpcomingStudies, assessmentItems, courseUnits]) => partialUpcomingStudies
                    .map(({ enrolment, courseUnitRealisation }) => ({
                        enrolment,
                        courseUnitRealisation,
                        assessmentItem: assessmentItems.find(assessmentItem => assessmentItem.id === enrolment.assessmentItemId),
                        courseUnit: courseUnits.find(courseUnit => courseUnit.id === enrolment.courseUnitId),
                    })),
                ),
            );
    }

    private isUpcomingStudy(
        enrolment: Enrolment,
        courseUnitRealisation: CourseUnitRealisation,
        currentStudyPeriod: StudyPeriod,
    ): boolean {
        if (!enrolment?.state) {
            return false;
        }

        if (enrolment.state === 'NOT_ENROLLED') {
            // Include studies that have been added to the calendar only if enrolment period is ongoing
            const effectiveEnrolmentPeriod: LocalDateTimeRange = {
                startDateTime: courseUnitRealisation.enrolmentPeriod?.startDateTime,
                endDateTime: courseUnitRealisation.lateEnrolmentEnd ?? courseUnitRealisation.enrolmentPeriod?.endDateTime,
            };
            return effectiveEnrolmentPeriod.startDateTime && effectiveEnrolmentPeriod.endDateTime &&
                enrolment.isInCalendar && dateUtils.rangeContains(moment(), effectiveEnrolmentPeriod);
        }

        if (this.acceptedEnrolmentStates.includes(enrolment.state)) {
            // Include studies where enrolment has been accepted only if the teaching starts in a future study period
            return dateUtils.dateIsSameOrAfter(currentStudyPeriod?.valid?.endDate, courseUnitRealisation.activityPeriod?.startDate);
        }

        // Include all studies where enrolment has been rejected or is still being processed, and the teaching has not ended
        return moment().isBefore(courseUnitRealisation.activityPeriod?.endDate);
    }
}
