<ng-container *transloco="let t">
  <ng-container *ngIf="enrolmentPeriodValidity$ | async as validity">
    <ng-container *ngIf="validity === 'ONGOING'">
      <span *ngIf="!isLateEnrolment">
        {{ t('ENROLMENT.ENROLMENT_PERIOD_ENDS', getDateTimeParams(courseUnitRealisation?.enrolmentPeriod?.endDateTime)) }}
      </span>
      <span *ngIf="isLateEnrolment">
        {{ t('ENROLMENT.LATE_ENROLMENT_PERIOD_ENDS', getDateTimeParams(courseUnitRealisation?.lateEnrolmentEnd)) }}
      </span>
    </ng-container>

    <ng-container *ngIf="validity === 'PAST'">
      <span *ngIf="!isLateEnrolment">
        {{ t('ENROLMENT.ENROLMENT_ENDED_DATE', getDateTimeParams(courseUnitRealisation?.enrolmentPeriod?.endDateTime)) }}
      </span>
      <span *ngIf="isLateEnrolment">
        {{ t('ENROLMENT.LATE_ENROLMENT_ENDED_DATE', getDateTimeParams(courseUnitRealisation?.lateEnrolmentEnd)) }}
      </span>
    </ng-container>

    <ng-container *ngIf="validity === 'FUTURE'">
      <ng-container *ngIf="!isLateEnrolment">
        <span class="d-block">
          {{ t('ENROLMENT.ENROLMENT_PERIOD_STARTS', getDateTimeParams(courseUnitRealisation?.enrolmentPeriod?.startDateTime)) }}
        </span>
        <span class="d-block">
          {{ t('ENROLMENT.ENROLMENT_PERIOD_ENDS', getDateTimeParams(courseUnitRealisation?.enrolmentPeriod?.endDateTime)) }}
        </span>
      </ng-container>
      <span *ngIf="isLateEnrolment">
          {{ t('ENROLMENT.LATE_ENROLMENT_PERIOD_NOT_STARTED', getDateTimeParams(courseUnitRealisation?.lateEnrolmentEnd)) }}
      </span>
    </ng-container>

    <span *ngIf="validity === 'MISSING'">
      {{ t('ENROLMENT.NO_ENROLMENT_DATE') }}
    </span>
  </ng-container>
</ng-container>
