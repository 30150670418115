import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { SessionStorageService } from '../storage/session-storage.service';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from '../types/angular-hybrid';

import { parseToken } from './auth-utils';

@StaticMembers<DowngradedService>()
@Injectable({
    providedIn: 'root',
})
export class TokenStorageService {

    static downgrade: ServiceDowngradeMappings = {
        moduleName: 'common.tokenStorageService',
        serviceName: 'tokenStorageService',
    };

    constructor(
        private sessionStorageService: SessionStorageService,
    ) { }

    tokenStorageKey = 'auth_token';
    tokenExpirationTime = 'auth_token_expiration_time';
    sessionTimeoutAtKey = 'session_timeout_at';

    userSessionChanged$: Subject<void> = new Subject<void>();

    store(token: string, tokenExpiresIn: number, sessionTimeoutAt?: number) {
        const currentToken = this.sessionStorageService.getItem(this.tokenStorageKey);

        // If user has changed, then broadcast that user session has changed
        if (currentToken && (parseToken(currentToken).sub !== parseToken(token).sub)) {
            this.userSessionChanged$.next();
        }

        const expirationTime = new Date().getTime() + tokenExpiresIn * 1000;
        this.sessionStorageService.setItem(this.tokenExpirationTime, expirationTime.toString());
        if (sessionTimeoutAt) {
            this.sessionStorageService.setItem(this.sessionTimeoutAtKey, sessionTimeoutAt.toString());
        }
        return this.sessionStorageService.setItem(this.tokenStorageKey, token);
    }

    retrieve() {
        const expirationTime = parseInt(this.sessionStorageService.getItem(this.tokenExpirationTime), 10);
        const token = this.sessionStorageService.getItem(this.tokenStorageKey);
        if (token) {
            return { authToken: token, expirationTime };
        }
        return null;
    }

    get token() {
        return this.sessionStorageService.getItem(this.tokenStorageKey);
    }

    clear() {
        this.sessionStorageService.clear();
    }
}
