<div *ngFor="let node of attainmentNodes">
  <sis-attainment-tree-attainment
    *ngIf="node.type === 'AttainmentReferenceNode'"
    [attainment]="attainmentsById[node.attainmentId]"
    [attainmentsById]="attainmentsById"
    [selectedLocale]="selectedLocale">
  </sis-attainment-tree-attainment>
  <sis-attainment-tree-group-node
    *ngIf="node.type === 'AttainmentGroupNode'"
    [groupingNode]="node"
    [attainmentsById]="attainmentsById"
    [selectedLocale]="selectedLocale">
  </sis-attainment-tree-group-node>
</div>
