import { Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FudisHeadingLevel } from '@funidata/ngx-fudis/lib/types/typography';
import { PrivatePerson } from 'common-typescript/types';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-contact-info-for-graduation',
    templateUrl: './contact-info-for-graduation.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ContactInfoForGraduationComponent implements OnChanges {
    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.person.contactInfoForGraduation',
        directiveName: 'sisContactInfoForGraduation',
    };

    @Input() person: PrivatePerson;
    @Input() level: FudisHeadingLevel = 4;

    protected parsedSecondaryAddress: string | null;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.person?.currentValue?.secondaryAddress?.address !== changes.person?.previousValue?.secondaryAddress?.address) {
            this.parsedSecondaryAddress = changes.person.currentValue.secondaryAddress.address.replace(/\n/g, ', ');

        } else if (!changes.person?.currentValue?.secondaryAddress?.address) {
            this.parsedSecondaryAddress = null;
        }
    }
}
