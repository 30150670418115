import {
    AnyCourseUnitRule,
    AnyModuleRule,
    CompositeRule,
    CourseUnitCountRule,
    CourseUnitRule,
    CreditsRule,
    ModuleRule,
    Rule,
} from '../types';

export const ruleUtils = {

    isCourseUnitRule(rule: Rule): rule is CourseUnitRule {
        return rule?.type === 'CourseUnitRule';
    },

    isModuleRule(rule: Rule): rule is ModuleRule {
        return rule?.type === 'ModuleRule';
    },

    isAnyCourseUnitRule(rule: Rule): rule is AnyCourseUnitRule {
        return rule?.type === 'AnyCourseUnitRule';
    },

    isAnyModuleRule(rule: Rule): rule is AnyModuleRule {
        return rule?.type === 'AnyModuleRule';
    },

    isCompositeRule(rule: Rule): rule is CompositeRule {
        return rule?.type === 'CompositeRule';
    },

    isCreditsRule(rule: Rule): rule is CreditsRule {
        return rule?.type === 'CreditsRule';
    },

    isCourseUnitCountRule(rule: Rule): rule is CourseUnitCountRule {
        return rule?.type === 'CourseUnitCountRule';
    },

};
