import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import angular from 'angular';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

export type IconPlacement = 'before' | 'after' | 'top' | 'bottom';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-show-more',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './show-more.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ShowMoreComponent implements OnInit {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sisComponents.showMore',
        directiveName: 'sisShowMore',
    };

    /**
     * The $scope instance of the containing AngularJS component. Required when this component is used in an AngularJS
     * component; irrelevant otherwise. Without this the AngularJS change detection will not pick up the change of the expanded state
     * in this component.
     */
    @Input() scope: angular.IScope;
    @Input() text: string;
    @Input() showMore?: boolean;
    /**
     * Placement is used in defining icon placement with text. Only before and after styles include icon animation when clicked.
     */
    @Input() placement?: IconPlacement = 'before';
    @Input() direction: string;
    @Input() verticalPointer?: boolean;

    @Output() showMoreChanged = new EventEmitter<boolean>();

    ngOnInit(): void {
        this.getDirection();
    }

    onClick(): void {
        this.showMore = !this.showMore;

        if (this.scope) {
            this.scope.$apply(() => this.showMoreChanged.emit(!this.showMore));
        } else {
            this.showMoreChanged.emit(!this.showMore);
        }
        this.iconDirection();
    }

    private getDirection(): void {
        switch (this.placement) {
            case 'bottom':
                this.direction = 'cw-90';
                this.verticalPointer = true;
                break;
            case 'top':
                this.direction = 'ccw-90';
                this.verticalPointer = true;
                break;
            default:
                this.direction = null;
                break;
        }

    }

    iconDirection(): string {
        if (this.verticalPointer) {
            return this.direction;
        }
        if (this.showMore && !this.verticalPointer) {
            return this.direction = 'cw-90';
        }
        this.direction = null;
    }
}
