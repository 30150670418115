import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Module, StudyRight, ValidatablePlan } from 'common-typescript/types';
import { ModalService } from 'sis-common/modal/modal.service';

export interface MinorSelectionValues {
    module: Module;
    studyRight: StudyRight;
    validatablePlan: ValidatablePlan;
}

@Component({
    selector: 'app-minor-selection-modal',
    template: '<minor-selection-modal (close)="activeModal.close($event ? $event.value : null)" [resolve]="values"></minor-selection-modal>',
    encapsulation: ViewEncapsulation.None,
})
export class MinorSelectionModalComponent {
    constructor(@Inject(ModalService.injectionToken) public values: MinorSelectionValues,
                public activeModal: NgbActiveModal) {}
}
