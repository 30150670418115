'use strict';

(function () {
  initS2R2ClassificationJsDataModel.$inject = ["s2r2ClassificationJsDataModel"];
  s2r2Classification.$inject = ["DS", "$http", "$log"];
  angular.module('sis-components.model.s2r2Classification', ['js-data']).factory('s2r2ClassificationJsDataModel', s2r2Classification).run(initS2R2ClassificationJsDataModel);

  /**
   * @ngInject
   */
  function s2r2Classification(DS, $http, $log) {
    var model = DS.defineResource({
      idAttribute: 'urn',
      name: 's2r2Classification',
      endpoint: '/kori/api/cached/codebooks/urn:code:s2r2-classification',
      watchChanges: false
    });
    model.lazyGetAll = _.once(function () {
      return $http.get(model.endpoint).then(function (response) {
        model.inject(_.values(response.data));
        return model.getAll();
      }).catch(function (error) {
        $log.error(error);
      });
    });
    return model;
  }

  /**
   * @ngInject
   */
  function initS2R2ClassificationJsDataModel(s2r2ClassificationJsDataModel) {
    s2r2ClassificationJsDataModel.lazyGetAll();
  }
})();