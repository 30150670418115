<ng-container *transloco="let t">
  <sis-description-list-compact *ngIf="studyEvent" [columnCount]="twoColumns ? 2 : 1">
    <dl>
      <ng-template #compactListItem>
        <dt class="sis-label colon">{{ t('OPEN_UNIVERSITY.STUDY_EVENT_INFO.DATE') }}</dt>
        <dd class="sis-value">{{ studyEvent | studyEventWeekdayOrInterval }} {{ studyEvent | studyEventDateRange }}</dd>
      </ng-template>
      <ng-template #compactListItem>
        <dt class="sis-label colon">{{ t('OPEN_UNIVERSITY.STUDY_EVENT_INFO.TIME') }}</dt>
        <dd class="sis-value">{{ studyEvent | studyEventTimeRange }}</dd>
      </ng-template>
      <ng-template #compactListItem>
        <dt class="sis-label colon">{{ t('OPEN_UNIVERSITY.STUDY_EVENT_INFO.PLACE') }}</dt>
        <dd class="sis-value" *ngFor="let location of studyEvent.locations; last as last">
          <ng-container *ngTemplateOutlet="locationDetails; context: {location: location, last: last}"></ng-container>
        </dd>
        <dd class="sis-value" *ngFor="let locationId of studyEvent.locationIds; last as last">
          <ng-container *ngTemplateOutlet="locationDetails; context: {location: locationId | location | async, last: last}"></ng-container>
        </dd>
      </ng-template>
      <ng-template #compactListItem *ngIf="teacherIds?.length > 0">
        <dt class="sis-label colon">{{ t('COURSE_UNIT_REALISATION_INFO.TEACHER') }}</dt>
        <dd class="sis-value" *ngFor="let teacherId of teacherIds; last as last">
          <ng-container *ngIf="teacherId | publicPerson | async as teacher">
            {{ teacher | personFullName }}{{ last ? '' : ',' }}
          </ng-container>
        </dd>
      </ng-template>
      <ng-template #compactListItem *ngIf="studyEvent.cancellations?.length > 0">
        <dt class="sis-label colon">{{ t('OPEN_UNIVERSITY.STUDY_EVENT_INFO.CANCELLED_EVENTS') }}</dt>
        <dd class="sis-value">{{ studyEvent.cancellations.length }}</dd>
      </ng-template>
    </dl>

  </sis-description-list-compact>
</ng-container>

<ng-template #locationDetails let-location="location" let-last="last">
  {{ location?.building?.name | localizedString }}, {{ location?.name | localizedString }}{{ last ? '' : ';' }}
</ng-template>
