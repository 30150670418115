<ng-container *transloco="let t">
  <div class="container">
    <table class="table styled-table">
      <caption>
        {{ t('SIS_COMPONENTS.NAVIGATION.MODULE_TOGGLE_TABLE_TITLE') }}
      </caption>
      <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Override setting</th>
          <th scope="col">Persisted setting</th>
          <th scope="col">Description</th>
        </tr>
      </thead>
      <tbody>
        @for (entry of moduleToggles$ | async; track $index) {
          <tr>
            <td>
              {{ entry.moduleToggle }}
              <br/>
              <button (click)="toggleOverrideValue(entry.moduleToggle)">Toggle override value</button>
            </td>
            <td>
              {{ entry.overrideValue }}
              <sis-icon *ngIf="entry.overrideValue !== null && entry.overrideValue !== entry.persistedValue"
                        icon="alert"
                        color="danger"
              ></sis-icon>
            </td>
            <td>{{ entry.persistedValue }}</td>
            <td>{{ entry.description }}</td>
          </tr>
        }
      </tbody>
    </table>
    <button (click)="clear()">Reset override values</button>
  </div>
</ng-container>
