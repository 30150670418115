import { get } from 'lodash';
import { TokenizeResult } from 'ngx-transloco-markup';
import { parseFragment } from 'parse5';

/**
 * Supported HTML attributes in hyperlink transpilation.
 */
enum HyperlinkParamNames {
    href = 'href',
    target = 'target',
    rel = 'rel',
}

export type HyperlinkParams = {
    [key in HyperlinkParamNames]?: string
};

export class HyperlinkStart {
    constructor(readonly params: HyperlinkParams) { }
}
export const HYPERLINK_END = new (class HyperlinkEnd {})();

/**
 * Parse supported parameters from given string.
 *
 * Input should represent exactly one HTML tag as a string. Multiple elements will result in an error. Only
 * supported parameters are extracted.
 */
const getParamsFromTag = (tag: string): HyperlinkParams => {
    const { childNodes } = parseFragment(tag);

    if (childNodes.length !== 1) {
        throw new Error('Hyperlink tokenizer encountered an unexpected amount of tags to parse.');
    }

    const hyperlinkNode = childNodes[0];
    const attributes = get(hyperlinkNode, 'attrs') as Array<{ name: string, value: string }>;
    const paramNames = Object.values(HyperlinkParamNames);

    return Object.fromEntries(
        paramNames
            .map(name => [
                name,
                get(attributes.find(attr => attr.name === name), 'value', undefined),
            ])
            .filter(([_, value]) => value !== undefined),
    );
};

/**
 * Identify hyperlink start and parse optional attributes.
 */
const recognizeHyperlinkStartToken = (translation: string, offset: number): TokenizeResult | undefined => {
    const HYPERLINK_START_TOKEN = '<a';

    if (!translation.startsWith(HYPERLINK_START_TOKEN, offset)) {
        return undefined;
    }

    const end = translation.indexOf('>', offset + HYPERLINK_START_TOKEN.length);

    if (end < 0) {
        return undefined;
    }

    const openingTag = translation.slice(offset, end + 1);
    const params = getParamsFromTag(openingTag);

    return {
        nextOffset: end + 1,
        token: new HyperlinkStart(params),
    };
};

const recognizeHyperlinkEndToken = (translation: string, offset: number): TokenizeResult | undefined => {
    const HYPERLINK_END_TOKEN = '</a>';

    if (!translation.startsWith(HYPERLINK_END_TOKEN, offset)) {
        return undefined;
    }

    return {
        nextOffset: offset + HYPERLINK_END_TOKEN.length,
        token: HYPERLINK_END,
    };
};

export const hyperlinkTokenizer = (translation: string, offset: number): TokenizeResult | undefined =>
    recognizeHyperlinkStartToken(translation, offset) || recognizeHyperlinkEndToken(translation, offset);
