<div class="teaching-not-selected-container">
  <sis-expandable [variant]="'naked-dotted'" [closed]="isMobileView" [size]="'xl'">
    <ng-template sisExpandableCustomTitle>
      <span class="unscheduled-items-title">{{ 'ENROLMENTS.UNSCHEDULED_COURSE_UNITS' | translate }}</span>
    </ng-template>
    <ng-template sisExpandableContent>
      <ng-container *ngIf="studyRights$ | async as studyRights">
        <label id="select-study-right" translate>ENROLMENTS.STUDY_RIGHT</label>
        <div class="col-sm-12 col-md-8 col-lg-6 col-xl-5" *ngIf="studyRights.length > 0">
          <ng-container *ngIf="studyRights.length > 1" [ngTemplateOutlet]="select"></ng-container>
          <ng-container *ngIf="studyRights.length === 1" [ngTemplateOutlet]="view"></ng-container>
        </div>

        <sis-contextual-notification
          *ngIf="studyRights.length === 0"
          variant="warning"
          [content]="'ENROLMENTS.NO_STUDY_RIGHTS' | translate">
        </sis-contextual-notification>

        <ng-template #select>
          <select class="form-select"
                  [ngModel]="studyRight"
                  (ngModelChange)="onStudyRightChange($event)"
                  aria-labelledby="select-study-right"
                  data-cy="select-study-right">
            <option [ngValue]="undefined" disabled selected>{{'ENROLMENTS.STUDY_RIGHT_SELECT' | translate}}</option>
            <option *ngFor="let sr of studyRights" [selected]="studyRight === sr"
                    [ngValue]="sr">{{ sr.educationId | education | async | entityName | localizedString }}
              ({{'STUDY_RIGHT_STATE.' + sr.state | translate}})
            </option>
          </select>
        </ng-template>
        <ng-template #view>
          <p *ngIf="studyRights.length === 1" aria-labelledby="select-study-right">
            {{studyRight.educationId | education | async | entityName | localizedString }}
            ({{'STUDY_RIGHT_STATE.' + studyRight.state | translate}})
          </p>
        </ng-template>
      </ng-container>

      <ng-container *ngIf="(education$ | async)?.studySelectionRequirement as studySelectionRequirement">
        <ng-container *ngIf="studySelectionRequirement === 'UNRESTRICTED' || studySelectionRequirement === 'SELECTION_REQUIRED'">
          <h3>{{ 'ENROLMENTS.PRIMARY_PLAN_COURSE_UNITS_TITLE' | translate }}</h3>
          <p class="guidance">{{ 'ENROLMENTS.PRIMARY_PLAN_COURSE_UNITS_CONTENT' | translate }}</p>

          <app-degree-studies-untimed [validatablePlan]="validatablePlan$ | async"
                                      [enrolmentsInCalendar]="enrolmentsInCalendar"
          ></app-degree-studies-untimed>
        </ng-container>

        <ng-container *ngIf="studySelectionRequirement === 'ENROLMENT_RIGHT_REQUIRED'">
          <app-open-studies-untimed [studyRight]="studyRight"></app-open-studies-untimed>
        </ng-container>
      </ng-container>
    </ng-template>
  </sis-expandable>
</div>
