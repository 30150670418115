import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ValidatablePlan } from 'common-typescript/src/plan/validation/validatablePlan';
import { CourseUnit, CourseUnitAttainment } from 'common-typescript/types';
import _ from 'lodash';
import { Subject, Subscription } from 'rxjs';
import { DEFAULT_PROMISE_HANDLER } from 'sis-common/ajs-upgraded-modules';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { LocalizedStringPipe } from 'sis-common/l10n/localized-string.pipe';
import { ModalService } from 'sis-common/modal/modal.service';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import {
    COURSE_UNIT_SERVICE,
} from '../../ajs-upgraded-modules';
import { CourseUnitVersion } from '../../courseUnitInfoModal/course-unit-version-selector/course-unit-version-selector.component';
import { AppErrorHandler } from '../../error-handler/app-error-handler';
import { CourseUnitEntityService } from '../../service/course-unit-entity.service';
import { PersonNameService } from '../../service/person-name.service';
import { PlanCourseUnitService } from '../../service/plan-course-unit.service';
import { PublicPersonEntityService } from '../../service/public-person-entity.service';
import { PlanActionsService } from '../plan-actions-service/plan-actions.service';

export interface CourseUnitInfoModalValues {
    originalCourseUnit: CourseUnit;
    validatablePlan: ValidatablePlan;
    planActionsService: PlanActionsService;
    validatablePlanSubject: Subject<ValidatablePlan>;
}

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-plan-course-unit-info-modal',
    templateUrl: './plan-course-unit-info-modal.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class PlanCourseUnitInfoModalComponent implements OnInit, OnDestroy {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sisPlancourseUnitInfoModal',
        directiveName: 'sisPlanCourseUnitInfoModal',
    };

    courseUnit: CourseUnit;
    courseUnitAttainment: CourseUnitAttainment;
    courseUnitVersionInPlan: CourseUnit;
    activeTab = 0;
    contentLanguage: string;
    allVersions: CourseUnit[] = [];
    fallbackName: string;
    displayNamesByCourseUnitId: { [id: string]: string } = {};
    belongsToCurrentCurriculumPeriod = true;
    locales: string[];
    courseUnitVersions: CourseUnitVersion[] = [];
    hasCoordinatingOrganisations = false;
    validatablePlanSubject: Subject<ValidatablePlan>;
    validatablePlanSubscription: Subscription;
    validatablePlan: ValidatablePlan;
    planActionsService: PlanActionsService;
    isCurrentVersionInPlan = false;
    isAnyVersionInPlan = false;
    courseUnitVersionInPlanIsSubstituted = false;
    canCurrentVersionBeSelectedInPlan = false;

    constructor(
        private appErrorHandler: AppErrorHandler,
        private localeService: LocaleService,
        private localizedStringPipe: LocalizedStringPipe,
        private courseUnitEntityService: CourseUnitEntityService,
        private publicPersonEntityService: PublicPersonEntityService,
        private personNameService: PersonNameService,
        private planCourseUnitService: PlanCourseUnitService,
        @Inject(COURSE_UNIT_SERVICE) private commonCourseUnitService: any,
        @Inject(ModalService.injectionToken) private values: CourseUnitInfoModalValues,
        @Inject(DEFAULT_PROMISE_HANDLER) private defaultPromiseHandler: any,
        public modal: NgbActiveModal,
    ) {
        this.courseUnit = values.originalCourseUnit;
        this.validatablePlan = values.validatablePlan;
        this.planActionsService = values.planActionsService;
        this.validatablePlanSubject = values.validatablePlanSubject;
    }

    ngOnInit() {
        // all courseUnitInfoModal implementations use getSupportedLocales here,
        // but getOfficialLanguages would probably be more suitable
        this.locales = this.localeService.getSupportedLanguages();
        this.contentLanguage = this.localeService.getCurrentLanguage();

        this.fallbackName = this.localizedStringPipe.transform(this.courseUnit.name);

        this.commonCourseUnitService.findAllVersions(this.courseUnit.groupId).then((allVersions: CourseUnit[]) => {
            this.allVersions = allVersions;
            this.commonCourseUnitService.getVersionSelectorDataForCourseUnitVersions(this.allVersions)
                .then((result: any) => {
                    this.courseUnitVersions = result.courseUnitVersions;
                    this.displayNamesByCourseUnitId = result.displayNamesByCourseUnitId;
                }).catch(this.defaultPromiseHandler.loggingRejectedPromiseHandler);
        }).catch(this.defaultPromiseHandler.loggingRejectedPromiseHandler);
        this.updateHasCoordinatingOrganisations();
        this.updateCourseUnitData();
        this.validatablePlanSubscription = this.validatablePlanSubject
            .pipe(this.appErrorHandler.defaultErrorHandler())
            .subscribe((validatablePlan: ValidatablePlan) => {
                this.validatablePlan = validatablePlan;
                this.updateCourseUnitData();
            });
    }

    updateCourseUnitData() {
        this.courseUnitAttainment = this.validatablePlan?.getCourseUnitAttainment(this.courseUnit?.id);
        this.isCurrentVersionInPlan = this.isCurrentCourseUnitVersionInPlan();
        this.isAnyVersionInPlan = this.isAnyCourseUnitVersionInPlan();
        this.courseUnitVersionInPlan = this.validatablePlan?.getCourseUnitInPlanByGroupId(this.courseUnit.groupId);
        this.courseUnitVersionInPlanIsSubstituted = this.validatablePlan?.isSubstituted(this.courseUnitVersionInPlan);
        this.planCourseUnitService.isVersionChangeAllowed(this.courseUnit, this.courseUnitVersionInPlan, this.validatablePlan).then((result) => {
            this.canCurrentVersionBeSelectedInPlan = result;
        });
    }

    ngOnDestroy() {
        this.validatablePlanSubscription?.unsubscribe();
    }

    setCourseUnitVersion(courseUnit: CourseUnit) {
        this.courseUnit = courseUnit;
        this.updateHasCoordinatingOrganisations();
        this.updateCourseUnitData();
    }

    changeCourseUnitVersionInPlan() {
        if (this.canCurrentVersionBeSelectedInPlan) {
            this.planActionsService?.changeCourseUnitVersion?.(this.courseUnitVersionInPlan, this.courseUnit);
        }
    }

    isCurrentCourseUnitVersionInPlan(): boolean {
        const courseUnitInPlan = this.validatablePlan?.getCourseUnitInPlanByGroupId(this.courseUnit.groupId);
        if (!courseUnitInPlan || courseUnitInPlan.id !== this.courseUnit.id) {
            return false;
        }
        return true;
    }

    isAnyCourseUnitVersionInPlan() {
        const courseUnitInPlan = this.validatablePlan?.getCourseUnitInPlanByGroupId(this.courseUnit.groupId);
        if (!courseUnitInPlan) {
            return false;
        }
        return true;
    }

    openTab(tabIndex: number) {
        this.activeTab = tabIndex;
    }

    dismiss() {
        this.modal.dismiss();
    }

    updateHasCoordinatingOrganisations() {
        this.hasCoordinatingOrganisations = _.some(
            this.courseUnit.organisations,
            { roleUrn: 'urn:code:organisation-role:coordinating-organisation' },
        );
    }

}
