import angular from 'angular';
import _ from 'lodash';
import substitutionInfoTpl from './substitutionInfo.tpl.html';
export const substitutionInfoModule = 'sis-components.substitutionInfo';
angular.module('sis-components.substitutionInfo', []).component('substitutionInfo', {
  template: substitutionInfoTpl,
  bindings: {
    courseUnitId: '<',
    validatablePlan: '<'
  },
  controller: substitutionInfoController
});
function substitutionInfoController() {
  const $ctrl = this;
  $ctrl.$onInit = () => {
    $ctrl.courseUnit = $ctrl.validatablePlan.getCourseUnit($ctrl.courseUnitId);
    $ctrl.substitutingCourseUnits = _.map($ctrl.validatablePlan.getSubstitutedBy($ctrl.courseUnit), courseUnitId => $ctrl.validatablePlan.getCourseUnit(courseUnitId));
  };
  $ctrl.substitutionCredits = substituteCourseUnit => $ctrl.validatablePlan.getSubstitutionCredits($ctrl.courseUnit, substituteCourseUnit);
}