'use strict';

(function () {
  initStudyLevelModel.$inject = ["studyLevelModel"];
  studyLevel.$inject = ["DS", "$http", "$log"];
  angular.module('sis-components.model.studyLevel', ['js-data']).factory('studyLevelModel', studyLevel).run(initStudyLevelModel);

  /**
   * @ngInject
   */
  function studyLevel(DS, $http, $log) {
    var model = DS.defineResource({
      idAttribute: 'urn',
      name: 'studyLevel',
      endpoint: '/kori/api/cached/codebooks/urn:code:study-level',
      watchChanges: false
    });
    model.lazyGetAll = _.once(function () {
      return $http.get(model.endpoint).then(function (response) {
        model.inject(_.values(response.data));
        return model.getAll();
      }).catch(function (error) {
        $log.error(error);
      });
    });
    return model;
  }

  /**
   * @ngInject
   */
  function initStudyLevelModel(studyLevelModel) {
    studyLevelModel.lazyGetAll();
  }
})();