(function () {
  whiteLayer.$inject = ["whiteLayerService", "$log", "$rootScope", "$transitions"];
  angular.module('sis-components.whitelayer', []).directive('whiteLayer', whiteLayer).factory('whiteLayerService', function () {
    var whiteLayerElements = {};
    return {
      show: function (id) {
        whiteLayerElements[id] = true;
        if (this.isVisible()) {
          $("top-nav").addClass('top-nav-white-layer');
        }
      },
      hide: function (id) {
        whiteLayerElements[id] = false;
        if (!this.isVisible()) {
          $("top-nav").removeClass('top-nav-white-layer');
        }
      },
      hideAll: function () {
        _.forIn(whiteLayerElements, function (value, key) {
          whiteLayerElements[key] = false;
        });
      },
      isVisible: function () {
        var isVisible = false;
        _.each(Object.keys(whiteLayerElements), function (id) {
          if (whiteLayerElements[id] === true) {
            isVisible = true;
            return false;
          }
        });
        return isVisible;
      }
    };
  });
  function whiteLayer(whiteLayerService, $log, $rootScope, $transitions) {
    return {
      restrict: 'C',
      scope: {
        editMode: '<',
        whiteLayerId: '@'
      },
      link: function ($scope, element, attrs) {
        // this rootscope stuff is to support the global 'editMode' directive
        $rootScope.$on('setEditMode', function (event, data) {
          if (data) {
            whiteLayerService.show('global-rootscope-editmode');
          } else {
            whiteLayerService.hide('global-rootscope-editmode');
          }
          visualizeTopNavWhiteLayer();
        });
        // show error if we are missing the white-layer-id attribute
        // and exit out. we can't know which white layer we are changing otherwise.
        if (_.result(attrs, 'whiteLayerId', '') === '') {
          $log.warn("whiteLayerId missing!");
          return;
        }
        // watch for editmode changes to display the top-nav layer
        $scope.$watch(attrs.editMode, function (whiteLayerVisible) {
          if (whiteLayerVisible) {
            whiteLayerService.show($scope.whiteLayerId);
          } else if (!whiteLayerVisible) {
            whiteLayerService.hide($scope.whiteLayerId);
          }
          visualizeTopNavWhiteLayer();
        });
        // if we navigate away from the page f.ex. pressing the back button on mouse,
        // hide the white layer so we are not stuck behind it
        $transitions.onStart({}, function () {
          whiteLayerService.hide('global-rootscope-editmode');
          $rootScope.$emit('setEditMode', false);
        });
      }
    };
    function visualizeTopNavWhiteLayer() {
      if (whiteLayerService.isVisible()) {
        $("top-nav").addClass('top-nav-white-layer');
      } else {
        $("top-nav").removeClass('top-nav-white-layer');
      }
    }
  }
})();