import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OtmId } from 'common-typescript/types';
import { ModalService } from 'sis-common/modal/modal.service';

export interface ApplyForGraduationValues {
    moduleId: OtmId;
    organisationId: OtmId;
    planId: OtmId;
}

@Component({
    selector: 'app-apply-for-graduation-modal',
    template: '<apply-for-graduation-modal (close)="activeModal.close()" (dismiss)="activeModal.dismiss()" [resolve]="values"></apply-for-graduation-modal>',
    encapsulation: ViewEncapsulation.None,
})
export class ApplyForGraduationModalComponent {
    constructor(@Inject(ModalService.injectionToken) public values: ApplyForGraduationValues,
                public activeModal: NgbActiveModal) {}
}
