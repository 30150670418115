<div class="modal-header">
  <h2 *ngIf="this.leavingFeedbackAllowed()" class="modal-title" [tabIndex]="0" translate="FEEDBACK.NPS_QUESTION"></h2>
  <h2 *ngIf="!this.leavingFeedbackAllowed()" class="modal-title" [tabIndex]="0" translate="FEEDBACK.FEEDBACK_GIVEN"></h2>
</div>
<div class="modal-body">
  <ng-container *ngIf="this.leavingFeedbackAllowed()">
    <div class="row">
      <div class="col-12 d-sm-none">
        <span translate="FEEDBACK.GUIDE.GUIDE_TEXT_BEGINNING"></span>
        <span translate="FEEDBACK.GUIDE.NPS_ZERO"></span>
        <span translate="FEEDBACK.GUIDE.GUIDE_TEXT_END"></span>
        <span translate="FEEDBACK.GUIDE.NPS_TEN"></span>
      </div>
    </div>
    <div class="visually-hidden">
      <span translate="FEEDBACK.GUIDE.GUIDE_TEXT_BEGINNING"></span>
      <span translate="FEEDBACK.GUIDE.NPS_ZERO"></span>
      <span translate="FEEDBACK.GUIDE.GUIDE_TEXT_END"></span>
      <span translate="FEEDBACK.GUIDE.NPS_TEN"></span>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="feedback-values">
          <button type="button" class="btn-circle" (click)="this.leaveFeedback(0)">0</button>
          <button type="button" class="btn-circle" (click)="this.leaveFeedback(1)">1</button>
          <button type="button" class="btn-circle" (click)="this.leaveFeedback(2)">2</button>
          <button type="button" class="btn-circle" (click)="this.leaveFeedback(3)">3</button>
          <button type="button" class="btn-circle" (click)="this.leaveFeedback(4)">4</button>
          <button type="button" class="btn-circle" (click)="this.leaveFeedback(5)">5</button>
          <button type="button" class="btn-circle" (click)="this.leaveFeedback(6)">6</button>
          <button type="button" class="btn-circle" (click)="this.leaveFeedback(7)">7</button>
          <button type="button" class="btn-circle" (click)="this.leaveFeedback(8)">8</button>
          <button type="button" class="btn-circle" (click)="this.leaveFeedback(9)">9</button>
          <button type="button" class="btn-circle" (click)="this.leaveFeedback(10)">10</button>
        </div>
      </div>
    </div>
    <div class="row" aria-hidden="true">
      <div class="col-12">
        <div class="scale">
          <p class="d-none d-sm-block" translate>FEEDBACK.GUIDE.NPS_ZERO</p>
          <p class="d-none d-sm-block" translate>FEEDBACK.GUIDE.NPS_TEN</p>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary btn-lg"
          (click)="modal.close()" translate="BUTTON.CLOSE">
  </button>
</div>
