customStudyDraftInfoModalService.$inject = ["modalService"];
import angular from 'angular';
import { ModalService } from 'sis-common/modal/modal.service.ts';
import { decimalNumberFilterModule } from '../number/decimalNumber.filter';
import { CustomStudyDraftInfoModalComponent } from './customStudyDraftInfoModal.component.ts';
import customStudyDraftInfoModalTpl from './customStudyDraftInfoModal.tpl.html';
export const customStudyDraftInfoModalModule = 'sis-components.customStudyDraftInfoModal';
angular.module(customStudyDraftInfoModalModule, [decimalNumberFilterModule, ModalService.downgrade.moduleName]).component('customStudyDraftInfoModal', {
  bindings: {
    close: '&',
    resolve: '<'
  },
  template: customStudyDraftInfoModalTpl
}).factory('customStudyDraftInfoModalService', customStudyDraftInfoModalService);
function customStudyDraftInfoModalService(modalService) {
  return {
    open: customStudyDraft => modalService.open(CustomStudyDraftInfoModalComponent, {
      customStudyDraft
    }, {
      closeWithOutsideClick: true,
      size: 'sm'
    }).result
  };
}