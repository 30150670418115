import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Attainment, ModuleAttainment, ModuleAttainmentApplication } from 'common-typescript/types';
import { Subscription } from 'rxjs';

import { AppErrorHandler } from '../../../error-handler/app-error-handler';
import { AttainmentEntityService } from '../../../service/attainment-entity.service';
import { ViewOptions } from '../module-attainment/module-attainment.component';

@Component({
    selector: 'sis-maa-attainment-expandable',
    templateUrl: './maa-attainment-expandable.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class MaaAttainmentExpandableComponent implements OnInit, OnDestroy {
    @Input() application: ModuleAttainmentApplication;
    @Input() hideAdministrativeNote: boolean;
    viewOptions: ViewOptions;
    attainment: ModuleAttainment;
    attainments: Attainment[];
    private subscription = Subscription.EMPTY;

    constructor(
        private appErrorHandler: AppErrorHandler,
        private attainmentEntityService: AttainmentEntityService,
    ) {}

    ngOnInit(): void {
        this.viewOptions = { evaluationType: 'APPLICATION', hideAdministrativeNote: this.hideAdministrativeNote };
        this.subscription = this.attainmentEntityService.getByPersonId(this.application.studentId)
            .pipe(this.appErrorHandler.defaultErrorHandler())
            .subscribe({
                next: (attainments) => {
                    this.attainments = attainments;
                    this.attainment = attainments.find(att => att.studentApplicationId === this.application.id) as ModuleAttainment;
                },
            });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
