import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApplicationDecision, Module, ModuleContentApplication } from 'common-typescript/types';
import { ModalService } from 'sis-common/modal/modal.service';

export interface ShowModuleContentApplicationValues {
    application: ModuleContentApplication;
    decision?: ApplicationDecision;
    module: Module;
    readOnly: boolean;
}

@Component({
    selector: 'app-show-module-content-application-modal',
    template: '<show-module-content-application-modal (close)="activeModal.close($event.$value)" (dismiss)="activeModal.dismiss()" [resolve]="values"></show-module-content-application-modal>',
    encapsulation: ViewEncapsulation.None,
})
export class ShowModuleContentApplicationModalComponent {
    constructor(@Inject(ModalService.injectionToken) public values: ShowModuleContentApplicationValues,
                public activeModal: NgbActiveModal) {}
}
