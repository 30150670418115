import {
    ChangeDetectionStrategy,
    Component, EventEmitter, Input, Output,
    ViewEncapsulation,
} from '@angular/core';
import { StateService } from '@uirouter/angular';
import { ModuleAttainmentWorkflow } from 'common-typescript/types';

@Component({
    selector: 'app-student-module-info-modal-module-attainment-applications-block',
    templateUrl: './student-module-info-modal-module-attainment-applications-block.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudentModuleInfoModalModuleAttainmentApplicationsBlockComponent {

    @Input() moduleAttainmentWorkflow: ModuleAttainmentWorkflow;
    @Output() closeModal = new EventEmitter<void>();

    constructor(private stateService: StateService) {}
    navigateToModuleAttainmentApplication() {
        this.stateService.go(
            'student.logged-in.profile.applications.module-attainment-application',
            { applicationId: this.moduleAttainmentWorkflow.id },
        );
        this.closeModal.emit();

    }
}

