import { Injectable } from '@angular/core';
import { ModalService } from 'sis-common/modal/modal.service';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

import { ConfirmDialogComponent, ConfirmDialogValues } from './confirm-dialog.component';

/**
 * @deprecated Use {@link getConfirmationModalOpener} instead to open the confirmation dialog modal.
 */
@StaticMembers<DowngradedService>()
@Injectable({
    providedIn: 'root',
})
export class ConfirmDialogService {

    static downgrade: ServiceDowngradeMappings = {
        moduleName: 'sis-components.service.confirmDialogService',
        serviceName: 'confirmDialogService',
    };

    constructor(private modalService: ModalService) {}

    confirm(values: ConfirmDialogValues<any>): Promise<any> {
        return this.modalService.open(ConfirmDialogComponent, values, { windowClass: 'confirm-dialog', size: 'sm' }).result;
    }
}
