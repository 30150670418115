import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '@uirouter/core';
import { CourseUnit, LocalId, OpenUniversityCart, OpenUniversityProduct, OtmId, SuomiFiUserBasicDetails } from 'common-typescript/types';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { EnrichedAssessmentItemsForOpenUniProductQueryService } from 'sis-common/generated/graphql';
import { graphqlUtils } from 'sis-common/graphql/graphqlUtils';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { CourseUnitEntityService } from 'sis-components/service/course-unit-entity.service';
import { OpenUniversityProductEntityService } from 'sis-components/service/open-university-product-entity.service';
import { SuomiFiService } from 'sis-components/service/suomi-fi.service';

import { EnrichedAssItem } from '../open-university-enrolment-wizard/open-university-enrolment-wizard.component';

import { LastStepValuesInterface } from './open-university-studies-activate-wizard-step2/open-university-studies-activate-wizard-step2.component';

@Component({
    selector: 'app-open-university-studies-activate-wizard',
    templateUrl: './open-university-studies-activate-wizard.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class OpenUniversityStudiesActivateWizardComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('currentStepNameFocus', {}) currentStepNameFocus: ElementRef;

    @Input() openUniversityCart: OpenUniversityCart;
    @Input() currentStep: number;

    // Only received when last step and using suomi.fi login
    @Input() secondaryEmail?: string;
    // Only received when using suomi.fi login
    @Input() suomiFiUserBasicDetails?: SuomiFiUserBasicDetails;
    // Only received when last step and using normal login as staff user
    @Input() isStaffUser?: boolean;

    wizardProgressStepKeys: string[] = [];
    openUniversityProducts: OpenUniversityProduct[] = [];
    courseUnits: CourseUnit[] = [];
    cuIdsAndCmIdsOfProducts: { courseUnitId: OtmId; completionMethodId: LocalId }[] = [];
    assessmentItemsData: EnrichedAssItem[] = [];
    isSuomiFiPath: boolean;

    destroyed$ = new Subject();

    constructor(private translateService: TranslateService,
                private openUniversityProductEntityService: OpenUniversityProductEntityService,
                private courseUnitEntityService: CourseUnitEntityService,
                private assessmentItemsQueryService: EnrichedAssessmentItemsForOpenUniProductQueryService,
                private suomiFiService: SuomiFiService,
                private stateService: StateService,
                private titleService: Title,
                private appErrorHandler: AppErrorHandler) {
    }

    ngOnInit(): void {
        this.wizardProgressStepKeys = [
            this.translateService.instant('OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.STEP_KEYS.STEP1'),
            this.translateService.instant('OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.STEP_KEYS.STEP2'),
            this.translateService.instant('OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.STEP_KEYS.STEP3'),
        ];
        if (this.openUniversityCart) {
            this.getOpenUniversityProducts();
        }
        this.isSuomiFiPath = this.isSuomiFiView();

    }

    ngAfterViewInit() {
        if (this.currentStepNameFocus) {
            this.currentStepNameFocus.nativeElement.focus();
        }
        this.titleService.setTitle(`${this.translateService.instant('OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.PAGE_TAB_TITLE')}, ${this.translateService.instant(this.wizardProgressStepKeys[this.currentStep])} - ${this.translateService.instant('PAGE_TITLE_STUDENT')}`);
    }

    private isSuomiFiView(): boolean {
        return !!(window.location.pathname.includes('authenticated-suomi-fi') && this.suomiFiUserBasicDetails)
                    || !!(this.stateService.is('student.open-university-studies-activate-confirmation-completed-suomi-fi') && this.secondaryEmail);
    }

    ngOnDestroy() {
        this.titleService.setTitle(this.translateService.instant('PAGE_TITLE_STUDENT'));
    }

    getOpenUniversityProducts() {
        const openUniversityProductIds: OtmId[] = this.openUniversityCart.items.flatMap(item => item.openUniversityProductId);
        this.openUniversityProductEntityService.getByIds(openUniversityProductIds)
            .pipe(
                switchMap(openUniversityProducts => combineLatest([
                    of(openUniversityProducts),
                    this.getCourseUnitsByOupIds(openUniversityProducts),
                ])),
                switchMap(([openUniversityProducts, courseUnits]) => combineLatest([
                    of(openUniversityProducts),
                    of(courseUnits),
                    this.getAssItemDataWithCuAssItemIds(courseUnits),
                ])),
                this.appErrorHandler.defaultErrorHandler(),
            )
            .subscribe(([openUniversityProducts, courseUnits, assItemData]) => {
                this.openUniversityProducts = openUniversityProducts;
                this.courseUnits = courseUnits;
                this.assessmentItemsData = assItemData;
            });
    }

    getCourseUnitsByOupIds(openUniversityProducts: OpenUniversityProduct[]): Observable<CourseUnit[]> {
        const cuIds = openUniversityProducts.map(oup => oup.courseUnitId);
        this.cuIdsAndCmIdsOfProducts = openUniversityProducts.map(p => ({ courseUnitId: p.courseUnitId, completionMethodId: p.completionMethodId }));
        return this.courseUnitEntityService.getByIds(cuIds);
    }

    getAssItemDataWithCuAssItemIds(courseUnits: CourseUnit[]): Observable<any> {
        const assessmentItemIdsOfProducts = [...new Set(courseUnits.flatMap(
            cu => cu.completionMethods
                .filter(cm => Boolean(this.cuIdsAndCmIdsOfProducts.find(o => o.courseUnitId === cu.id && o.completionMethodId === cm.localId)))
                .flatMap(completionMethod => completionMethod.assessmentItemIds)),
        )];
        return this.assessmentItemsQueryService.fetch({
            assessmentItemIds: assessmentItemIdsOfProducts,
        }).pipe(
            takeUntil(this.destroyed$),
            map(assItemData => graphqlUtils.removeTypeNames(assItemData.data.searchResult)),
        );
    }

    goToLastStep(lastStepValues: LastStepValuesInterface) {
        // go to a new route (but same component), so we can skip/use a different modal on exit
        if (this.isSuomiFiPath) {
            this.stateService.go(
                'student.open-university-studies-activate-confirmation-completed-suomi-fi',
                {
                    cartParam: this.openUniversityCart,
                    emailParam: lastStepValues.secondaryEmail,
                },
                { custom: { skipConfirmationDialog: true } },
            );
        } else {
            this.stateService.go(
                'student.logged-in.open-university-studies-activate-confirmation-completed',
                {
                    cartParam: this.openUniversityCart,
                    isStaffUserParam: lastStepValues.isStaffUser,
                },
                { custom: { skipConfirmationDialog: true } },
            );
        }
    }

    // When user is leaving the studies-activate-wizard -view
    // while using suomi-fi-session,
    // we will clear suomi.fi related session data.
    suomiFiLogout() {
        if (this.isSuomiFiPath) {
            this.suomiFiService.logout('/search/open-university');
        }
    }
}
