import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { Attainment } from 'common-typescript/types';
import {
    ComponentDowngradeMappings,
    DowngradedComponent,
    StaticMembers,
} from 'sis-common/types/angular-hybrid';

import { isDegreeProgrammeAttainment } from '../../../attainment/AttainmentUtil';
import { Option } from '../../../menuButton/menu-button.component';

export type AttainmentEditAction = 'EDIT' | 'EDIT_DPA' | 'MISREGISTER';
@StaticMembers<DowngradedComponent>()

@Component({
    selector: 'sis-attainment-row-menu-button',
    templateUrl: './attainment-row-menu-button.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class AttainmentRowMenuButtonComponent {
    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sisComponents.attainmentRowMenuButton',
        directiveName: 'sisAttainmentRowMenuButton',
    };

    menuOptions: Option[] = [];
    @Output() attainmentEditActionSelected = new EventEmitter<{ actionType: AttainmentEditAction; attainment: Attainment }>();
    @Input() set attainment(attainment: Attainment) {
        const isDpa = isDegreeProgrammeAttainment(attainment);

        this.menuOptions = [
            {
                action: () => this.attainmentEditActionSelected.emit({ actionType: 'EDIT', attainment }),
                name: 'ATTAINMENTS.ATTAINMENT_DETAILS.MENU_BUTTON.EDIT_ATTAINMENT_INFO',
                hide: () => isDpa,
            },
            {
                action: () => this.attainmentEditActionSelected.emit({ actionType: 'EDIT_DPA', attainment }),
                name: 'ATTAINMENTS.ATTAINMENT_DETAILS.MENU_BUTTON.EDIT_DEGREE_PROGRAMME_ATTAINMENT_MODAL',
                hide: () => !isDpa,
            },
            {
                action: () => this.attainmentEditActionSelected.emit({ actionType: 'MISREGISTER', attainment }),
                name: 'ATTAINMENTS.ATTAINMENT_DETAILS.MENU_BUTTON.SET_AS_MISREGISTRATION',
                hide: () => isDpa,
            },
        ];
    }
}
