import countPhraseTpl from './countPhrase.tpl.html';
(function () {
  'use strict';

  angular.module('student.plan.rules.header').component('countPhrase', {
    template: countPhraseTpl,
    bindings: {
      directCount: '<',
      implicitCount: '<',
      rule: '<',
      getTargetRange: '&',
      isOk: '&'
    },
    controller: CountPhraseController
  });
  function CountPhraseController() {
    var ctrl = this;
    ctrl.addRange = buildAddRange;
    ctrl.removeRange = buildRemoveRange;
    ctrl.selected = selected;
    ctrl.tooManySelected = tooManySelected;
    ctrl.tooFewSelected = tooFewSelected;
    function buildAddRange() {
      var addRange = {
        min: 0,
        max: undefined
      };
      if (tooFewSelected()) {
        var selectedCount = selected();
        addRange.min = ctrl.rule.min - selectedCount;
        if (ctrl.rule.max) {
          addRange.max = ctrl.rule.max - selectedCount;
        }
      }
      return addRange;
    }
    function selected() {
      var implicitCount = _.result(ctrl, 'implicitCount', 0);
      var directCount = _.result(ctrl, 'directCount', 0);
      return implicitCount + directCount;
    }
    function buildRemoveRange() {
      var removeRange = {
        min: 0,
        max: 0
      };
      if (tooManySelected()) {
        var selectedCount = ctrl.selected();
        removeRange.min = selectedCount - ctrl.rule.max;
        var min = ctrl.rule.min ? ctrl.rule.min : 0;
        removeRange.max = selectedCount - min;
      }
      return removeRange;
    }
    function tooFewSelected() {
      return !!ctrl.rule.min && selected() < ctrl.rule.min;
    }
    function tooManySelected() {
      return !!ctrl.rule.max && selected() > ctrl.rule.max;
    }
  }
})();