import { Directive, ElementRef, EventEmitter, Injector, Input, Output } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { Organisation, SearchParameterOption, UniversityOrganisation } from 'common-typescript/types';

/**
 * @deprecated
 */
// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'search-filter-menu-item' })
export class SearchFilterMenuItemDirective extends UpgradeComponent {

    @Input() option: SearchParameterOption;
    @Input() parameterModel: any;
    @Input() searchParams: any;
    @Input() university: Organisation | UniversityOrganisation;
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onSelect: EventEmitter<any>;
    @Output() searchAgain: EventEmitter<void>;

    constructor(elementRef: ElementRef, injector: Injector) {
        super('searchFilterMenuItem', elementRef, injector);
    }
}
