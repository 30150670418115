<form class="reactive">
  <div class="modal-header">
    <h2 tabindex="0">{{ 'FILE_UPLOAD.FILE_EDIT' | translate }}</h2>
  </div>
  <div class="modal-body">
    <sis-file-upload [acceptedFilesTypes]="acceptedFileTypes"
                     [previouslyAddedFiles]="this.values.previouslyAddedFiles"
                     [externalAttachments]="this.values.externalAttachments"
                     [previousFilesEditable]="true"
                     [description]="true"
                     (droppedFiles)="droppedFiles($event)">
    </sis-file-upload>
  </div>
  <div class="modal-footer">
    <sis-button (clicked)="close()"
                [hollow]="true">
      {{ 'SIS_COMPONENTS.BUTTON.CANCEL' | translate }}
    </sis-button>
    <sis-button (clicked)="save()">
      {{ 'SIS_COMPONENTS.BUTTON.SAVE' | translate }}
    </sis-button>
  </div>
</form>
