import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import { dateUtils } from 'common-typescript';
import { commonPlanServiceModule } from 'sis-components/service/plan.service';
(function () {
  planCourseUnitService.$inject = ["$log", "$q", "planSubstitutionService", "commonPlanService", "universityService", "commonCurriculumPeriodService", "planSelectionService"];
  angular.module('student.common.service.planCourseUnitService', [commonPlanServiceModule, 'student.common.service.planSubstitutionService', 'sis-components.service.curriculumPeriodService', 'sis-common.university', 'student.common.service.planSelectionService']).factory('planCourseUnitService', planCourseUnitService);
  function planCourseUnitService($log, $q, planSubstitutionService, commonPlanService, universityService, commonCurriculumPeriodService, planSelectionService) {
    function sortAssessmentItemIds(ids, courseUnit) {
      if (!_.isEmpty(courseUnit.assessmentItemOrder)) {
        const ignored = [];
        _.forEach(courseUnit.assessmentItemOrder, id => {
          if (_.indexOf(ids, id) === -1) {
            ignored.push(id);
          }
        });
        const matchingOrderedIds = _.difference(courseUnit.assessmentItemOrder, ignored);
        const nonOrderedIds = _.difference(ids, matchingOrderedIds);
        return _.concat(matchingOrderedIds, nonOrderedIds);
      }
      // if nothing to sort, return original order
      return ids;
    }
    const api = {
      getCourseUnitLocationInPlan: function (courseUnit, validatablePlan) {
        return _.get(validatablePlan.getParentModuleOrCustomModuleAttainmentForCourseUnit(courseUnit), 'name');
      },
      getAllAssessmentItemIds: function (courseUnit) {
        let allAssessmentItemIds = _.flatMap(courseUnit.completionMethods, completionMethod => completionMethod.assessmentItemIds);
        allAssessmentItemIds = _.chain(allAssessmentItemIds).compact().uniq().value();
        return sortAssessmentItemIds(allAssessmentItemIds, courseUnit);
      },
      getAssessmentItemIdsByStudyTypes: function (courseUnit, studyTypes) {
        if (_.isEmpty(studyTypes)) {
          return [];
        }
        let allAssessmentItemIds = _.flatMap(courseUnit.completionMethods, completionMethod => {
          if (_.includes(studyTypes, completionMethod.studyType)) {
            return completionMethod.assessmentItemIds;
          }
          return [];
        });
        allAssessmentItemIds = _.chain(allAssessmentItemIds).compact().uniq().value();
        return sortAssessmentItemIds(allAssessmentItemIds, courseUnit);
      },
      courseUnitRealisationMatchesTiming: function (courseUnitSelection, courseUnitRealisation, periods) {
        let teachingMatchesTiming = false;
        if (courseUnitSelection) {
          _.forEach(courseUnitSelection.plannedPeriods, periodLocator => {
            const matchingPeriod = _.head(_.filter(periods, p => p.locator === periodLocator));
            const rangesOverLap = matchingPeriod && dateUtils.dateRangesOverlap(courseUnitRealisation.activityPeriod.startDate, courseUnitRealisation.activityPeriod.endDate, matchingPeriod.valid.startDate, matchingPeriod.valid.endDate);
            if (rangesOverLap) {
              teachingMatchesTiming = true;
            }
          });
        }
        return teachingMatchesTiming;
      },
      addCourseUnitSelection: function (courseUnitId, parentModuleId, plan) {
        let courseUnitSelection = plan.getCourseUnitSelection(courseUnitId);
        if (!courseUnitSelection) {
          $log.error('Trying to add duplicated courseUnitSelection to plan', 'courseUnitId', courseUnitId, 'parentModuleId', parentModuleId, 'plan', plan);
          return $q.when();
        }
        if (!plan.courseUnitSelections) {
          plan.courseUnitSelections = [];
        }
        courseUnitSelection = {
          courseUnitId,
          parentModuleId
        };
        plan.courseUnitSelections.push(courseUnitSelection);
        return commonPlanService.saveMyPlan(plan);
      },
      isVersionSwitchAllowed: function (newCourseUnitVersion, oldCourseUnitVersion, validatablePlan, plan) {
        if (validatablePlan.isCourseUnitAttained(newCourseUnitVersion.id)) {
          return $q.when(true);
        }
        if (!validatablePlan.isCourseUnitAttained(oldCourseUnitVersion.id)) {
          const currentUniversityOrgId = universityService.getCurrentUniversityOrgId();
          return commonCurriculumPeriodService.findAll().then(allCurriculumPeriods => {
            const ownUniversityPeriods = [];
            const otherUniversityPeriods = [];
            _.forEach(allCurriculumPeriods, curriculumPeriod => {
              if (_.includes(newCourseUnitVersion.curriculumPeriodIds, curriculumPeriod.id)) {
                if (curriculumPeriod.universityOrgId === currentUniversityOrgId) {
                  ownUniversityPeriods.push(curriculumPeriod);
                } else {
                  otherUniversityPeriods.push(curriculumPeriod);
                }
              }
            });
            const planCurriculumPeriod = _.find(allCurriculumPeriods, {
              id: _.get(plan, 'curriculumPeriodId')
            });
            if (api.courseUnitHasValidOwnUniversityCurriculumPeriodsForPlan(planCurriculumPeriod, ownUniversityPeriods)) {
              return true;
            }
            if (api.courseUnitHasValidOtherUniversityCurriculumPeriodsForPlan(otherUniversityPeriods)) {
              return true;
            }
            return false;
          });
        }
        return $q.when(false);
      },
      changeCourseUnitVersion: function (newVersionCourseUnit, oldVersionCourseUnitId, plan) {
        const substituteCourseUnitSelection = api.getSubstituteCourseUnitSelection(oldVersionCourseUnitId, plan);
        if (substituteCourseUnitSelection) {
          return api.changeSubstituteCourseUnitVersion(substituteCourseUnitSelection, newVersionCourseUnit, oldVersionCourseUnitId, plan);
        }
        return api.changeVersionOfNotSubstitutedCourseUnit(newVersionCourseUnit, oldVersionCourseUnitId, plan);
      },
      getSubstituteCourseUnitSelection: function (courseUnitId, plan) {
        const substituteCourseUnitSelection = _.find(plan.courseUnitSelections, courseUnitSelection => courseUnitSelection.courseUnitId === courseUnitId && !_.isEmpty(courseUnitSelection.substituteFor));
        return substituteCourseUnitSelection;
      },
      changeVersionOfNotSubstitutedCourseUnit: function (newVersionCourseUnit, oldVersionCourseUnitId, plan) {
        const courseUnitSelectionForOldVersion = _.find(plan.courseUnitSelections, selection => selection.courseUnitId === oldVersionCourseUnitId && _.isEmpty(_.get(selection, 'substituteFor', null)));
        if (!courseUnitSelectionForOldVersion) {
          $log.error('courseUnitSelectionForOldVersion NOT FOUND can not change courseUnit version', 'oldVersionCourseUnitId', oldVersionCourseUnitId, 'plan', plan);
          return $q.when(plan);
        }
        if (!_.isEmpty(courseUnitSelectionForOldVersion.substitutedBy)) {
          _.forEach(courseUnitSelectionForOldVersion.substitutedBy, substitutedBy => {
            planSubstitutionService.removeSubstitutionSubstituteFor(oldVersionCourseUnitId, substitutedBy, plan);
          });
        }
        _.remove(plan.courseUnitSelections, {
          courseUnitId: oldVersionCourseUnitId
        });
        _.remove(plan.assessmentItemSelections, {
          courseUnitId: oldVersionCourseUnitId
        });
        const courseUnitSelection = {
          courseUnitId: newVersionCourseUnit.id,
          parentModuleId: courseUnitSelectionForOldVersion.parentModuleId
        };
        plan.courseUnitSelections.push(courseUnitSelection);
        planSelectionService.makeAutomaticSelectionsForCourseUnits(plan, [newVersionCourseUnit]);
        return commonPlanService.saveMyPlan(plan);
      },
      changeSubstituteCourseUnitVersion: function (courseUnitSelection, newVersionCourseUnit, oldVersionCourseUnitId, plan) {
        const substitutedCourseUnitIds = _.map(courseUnitSelection.substituteFor, 'substitutedCourseUnitId');
        _.forEach(substitutedCourseUnitIds, substitutedCourseUnitId => {
          const substitutedCourseUnitSelection = _.find(plan.courseUnitSelections, {
            courseUnitId: substitutedCourseUnitId
          });
          if (substitutedCourseUnitSelection) {
            const substitutedBy = _.get(substitutedCourseUnitSelection, 'substitutedBy');
            _.pull(substitutedBy, oldVersionCourseUnitId);
            substitutedBy.push(newVersionCourseUnit.id);
          }
        });
        courseUnitSelection.courseUnitId = newVersionCourseUnit.id;
        _.remove(plan.assessmentItemSelections, {
          courseUnitId: oldVersionCourseUnitId
        });
        planSelectionService.makeAutomaticSelectionsForCourseUnits(plan, [newVersionCourseUnit]);
        return commonPlanService.saveMyPlan(plan);
      },
      courseUnitHasValidOwnUniversityCurriculumPeriodsForPlan: function (planCurriculumPeriod, currentUniversityCurriculumPeriods) {
        let validCurriculumPeriods = [];
        if (planCurriculumPeriod) {
          const planCurriculumPeriodStartDate = moment(planCurriculumPeriod.activePeriod.startDate);
          validCurriculumPeriods = _.filter(currentUniversityCurriculumPeriods, curriculumPeriod => {
            const courseUnitCurriculumPeriodActivePeriodStartDate = moment(curriculumPeriod.activePeriod.startDate);
            return courseUnitCurriculumPeriodActivePeriodStartDate.isSameOrAfter(planCurriculumPeriodStartDate, 'days');
          });
        }
        return !_.isEmpty(validCurriculumPeriods);
      },
      courseUnitHasValidOtherUniversityCurriculumPeriodsForPlan: function (otherUniversityCurriculumPeriods) {
        const today = moment();
        const validCurriculumPeriods = _.filter(otherUniversityCurriculumPeriods, curriculumPeriod => {
          const curriculumPeriodEndMoment = moment(curriculumPeriod.activePeriod.endDate);
          return curriculumPeriodEndMoment.isAfter(today, 'days');
        });
        return !_.isEmpty(validCurriculumPeriods);
      }
    };
    return api;
  }
})();