import { Injectable } from '@angular/core';
import { Education, LearningOpportunitySelectionPath, StudyRight } from 'common-typescript/types';
import _ from 'lodash';

import { PreviewModel, PreviewModelObject } from './preview-model.service';
import { StudyRightSelectionPathService } from './study-right-selection-path.service';

@Injectable({ providedIn: 'root' })
export class ConnectionModelService {
    constructor(private readonly _studyRightSelectionPathService: StudyRightSelectionPathService) {}

    /**
     * Returns a model telling which keys of {@link PreviewModel.left} and connected to which keys of {@link PreviewModel.right}
     * and which keys of {@link PreviewModel.left} are themselves active in the first place (i.e. when an entry exists).
     */
    getConnectionModel(previewModel: PreviewModel, education: Education, studyRight: StudyRight): ConnectionModel {
        const connectionModel: { [index: string]: { [index: string]: 'active' | 'inactive' } } = {};

        if (!education.structure) {
            return <ConnectionModel>connectionModel;
        }

        const allowedPaths: LearningOpportunitySelectionPath[] = this._studyRightSelectionPathService.getStudyRightSelectionPaths(education, studyRight);
        if (!allowedPaths.length) {
            return <ConnectionModel>connectionModel;
        }

        education.structure.phase2
            ? getConnectionModelForTwoPhaseEducation(previewModel, allowedPaths, studyRight.acceptedSelectionPath, connectionModel)
            : getConnectionModelForSinglePhaseEducation(previewModel, allowedPaths, studyRight.acceptedSelectionPath, connectionModel);

        return <ConnectionModel>connectionModel;
    }
}

export interface ConnectionModel {
    readonly [index: string]: { readonly [index: string]: 'active' | 'inactive' };
}

function getConnectionModelForTwoPhaseEducation(
    previewModel: PreviewModel,
    allowedPaths: LearningOpportunitySelectionPath[],
    acceptedSelectionPath: LearningOpportunitySelectionPath | null,
    connectionModel: { [index: string]: { [index: string]: 'active' | 'inactive' } },
): void {
    _.forEach(previewModel.left, (leftModule: PreviewModelObject, leftIndex: string) => {
        _.forEach(previewModel.right, (rightModule: PreviewModelObject, rightIndex: string) => {
            const pathCandidate: LearningOpportunitySelectionPath = getEducationPath(leftModule, rightModule);

            if (!allowedPaths.some(allowedPath => arePathsEqual(allowedPath, pathCandidate))) {
                return;
            }

            if (!connectionModel[leftIndex]) {
                connectionModel[leftIndex] = {};
            }

            if (!!acceptedSelectionPath && arePathsEqual(acceptedSelectionPath, pathCandidate)) {
                connectionModel[leftIndex][rightIndex] = 'active';
            } else {
                connectionModel[leftIndex][rightIndex] = 'inactive';
            }
        });
    });
}

function getConnectionModelForSinglePhaseEducation(
    previewModel: PreviewModel,
    allowedPaths: LearningOpportunitySelectionPath[],
    acceptedSelectionPath: LearningOpportunitySelectionPath | null,
    connectionModel: { [index: string]: { [index: string]: 'active' | 'inactive' } },
): void {
    if (!acceptedSelectionPath) {
        return;
    }

    _.forEach(previewModel.left, (leftModule: PreviewModelObject, leftIndex: string) => {
        const pathCandidate: LearningOpportunitySelectionPath = getEducationPath(leftModule);

        if (arePathsEqual(acceptedSelectionPath, pathCandidate) && allowedPaths.some(allowedPath => arePathsEqual(allowedPath, pathCandidate))) {
            connectionModel[leftIndex] = {};
        }
    });
}

function getEducationPath(leftModule: PreviewModelObject, rightModule?: PreviewModelObject): LearningOpportunitySelectionPath {
    // Null properties of backend-side LearningOpportunitySelectionPath are not serialized, so they never arrive to frontend.
    // So let's not explicitly set null nor undefined property values here either,
    // so these path stay comparable with backend-originated objects.
    const path: LearningOpportunitySelectionPath = <LearningOpportunitySelectionPath>{};

    if (leftModule.parentModuleGroupId) {
        path.educationPhase1GroupId = leftModule.parentModuleGroupId;

        if (leftModule.moduleGroupId) {
            path.educationPhase1ChildGroupId = leftModule.moduleGroupId;
        }
    } else if (leftModule.moduleGroupId) {
        path.educationPhase1GroupId = leftModule.moduleGroupId;
    }

    if (rightModule) {
        if (rightModule.parentModuleGroupId) {
            path.educationPhase2GroupId = rightModule.parentModuleGroupId;

            if (rightModule.moduleGroupId) {
                path.educationPhase2ChildGroupId = rightModule.moduleGroupId;
            }
        } else if (rightModule.moduleGroupId) {
            path.educationPhase2GroupId = rightModule.moduleGroupId;
        }
    }

    return path;
}

function arePathsEqual(a: LearningOpportunitySelectionPath, b: LearningOpportunitySelectionPath): boolean {
    return a.educationPhase1GroupId === b.educationPhase1GroupId
        && a.educationPhase1ChildGroupId === b.educationPhase1ChildGroupId
        && a.educationPhase2GroupId === b.educationPhase2GroupId
        && a.educationPhase2ChildGroupId === b.educationPhase2ChildGroupId;
}
