import {
    Component,
    ViewEncapsulation,
} from '@angular/core';

@Component({
    selector: 'app-empty-cart',
    templateUrl: './empty-cart.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class EmptyCartComponent {
}
