import { Inject, Injectable } from '@angular/core';
import { ValidatablePlan } from 'common-typescript/src/plan/validation/validatablePlan';
import { CourseUnit, CourseUnitSelection, CurriculumPeriod, LocalId, OtmId, Plan, SubstituteFor } from 'common-typescript/types';
import _ from 'lodash';
import moment from 'moment';

import {
    CURRICULUM_PERIOD_SERVICE,
} from '../ajs-upgraded-modules';
import { UniversityService } from '../service/university.service';

@Injectable({ providedIn: 'root' })
export class PlanCourseUnitService {

    constructor(
        private universityService: UniversityService,
        @Inject(CURRICULUM_PERIOD_SERVICE) private commonCurriculumPeriodService: any,
    ) {}

    isVersionChangeAllowed(newCourseUnitVersion: CourseUnit, oldCourseUnitVersion: CourseUnit, validatablePlan: ValidatablePlan): Promise<boolean> {
        if (!newCourseUnitVersion || !oldCourseUnitVersion) {
            return Promise.resolve(false);
        }
        if (validatablePlan.isCourseUnitAttained(newCourseUnitVersion.id)) {
            return Promise.resolve(true);
        }
        if (!validatablePlan.isCourseUnitAttained(oldCourseUnitVersion.id)) {
            const currentUniversityOrgId = this.universityService.getCurrentUniversityOrgId();
            return this.commonCurriculumPeriodService.findAll().then((allCurriculumPeriods: CurriculumPeriod[]) => {
                const ownUniversityPeriods: CurriculumPeriod[] = [];
                const otherUniversityPeriods: CurriculumPeriod[] = [];
                _.forEach(allCurriculumPeriods, (curriculumPeriod) => {
                    if (_.includes(newCourseUnitVersion.curriculumPeriodIds, curriculumPeriod.id)) {
                        if (curriculumPeriod.universityOrgId === currentUniversityOrgId) {
                            ownUniversityPeriods.push(curriculumPeriod);
                        } else {
                            otherUniversityPeriods.push(curriculumPeriod);
                        }
                    }
                });
                const planCurriculumPeriod = _.find(allCurriculumPeriods, { id: _.get(validatablePlan.plan, 'curriculumPeriodId') });
                if (this.courseUnitHasValidOwnUniversityCurriculumPeriodsForPlan(planCurriculumPeriod, ownUniversityPeriods)) {
                    return true;
                }
                if (this.courseUnitHasValidOtherUniversityCurriculumPeriodsForPlan(otherUniversityPeriods)) {
                    return true;
                }
                return false;
            });
        }
        return Promise.resolve(false);
    }

    courseUnitHasValidOwnUniversityCurriculumPeriodsForPlan(
        planCurriculumPeriod: CurriculumPeriod,
        ownUniversityCurriculumPeriods: CurriculumPeriod[],
    ): boolean {
        let validCurriculumPeriods: CurriculumPeriod[] = [];
        if (planCurriculumPeriod) {
            const planCurriculumPeriodStartMoment = moment(planCurriculumPeriod.activePeriod.startDate);
            validCurriculumPeriods = _.filter(ownUniversityCurriculumPeriods, (curriculumPeriod) => {
                const courseUnitCurriculumPeriodActivePeriodStartMoment = moment(curriculumPeriod.activePeriod.startDate);
                return courseUnitCurriculumPeriodActivePeriodStartMoment.isSameOrAfter(planCurriculumPeriodStartMoment, 'days');
            });
        }
        return !_.isEmpty(validCurriculumPeriods);
    }

    courseUnitHasValidOtherUniversityCurriculumPeriodsForPlan(otherUniversityCurriculumPeriods: CurriculumPeriod[]): boolean {
        const today = moment();
        const validCurriculumPeriods = _.filter(otherUniversityCurriculumPeriods, (curriculumPeriod) => {
            const curriculumPeriodEndMoment = moment(curriculumPeriod.activePeriod.endDate);
            return curriculumPeriodEndMoment.isAfter(today, 'days');
        });
        return !_.isEmpty(validCurriculumPeriods);
    }

    changeCourseUnitVersion(oldVersionCourseUnit: CourseUnit, newVersionCourseUnit: CourseUnit, validatablePlan: ValidatablePlan) {
        const plan = _.cloneDeep(validatablePlan.plan);
        const substituteCourseUnitSelection = this.getSubstituteCourseUnitSelection(oldVersionCourseUnit.id, plan);
        if (substituteCourseUnitSelection) {
            return this.changeSubstituteCourseUnitVersion(substituteCourseUnitSelection, oldVersionCourseUnit.id, newVersionCourseUnit.id, plan);
        }
        return this.changeVersionOfNotSubstitutedCourseUnit(oldVersionCourseUnit.id, newVersionCourseUnit.id, plan);
    }

    getSubstituteCourseUnitSelection(courseUnitId: OtmId, plan: Plan) {
        const substituteCourseUnitSelection = _.find(plan.courseUnitSelections, courseUnitSelection =>
            courseUnitSelection.courseUnitId === courseUnitId && !_.isEmpty(courseUnitSelection.substituteFor));
        return substituteCourseUnitSelection;
    }

    changeVersionOfNotSubstitutedCourseUnit(oldVersionCourseUnitId: OtmId, newVersionCourseUnitId: OtmId, plan: Plan) {

        const courseUnitSelectionForOldVersion = _.find(plan.courseUnitSelections, selection =>
            selection.courseUnitId === oldVersionCourseUnitId && _.isEmpty(_.get(selection, 'substituteFor', null)));

        if (!courseUnitSelectionForOldVersion) {
            return plan;
        }

        courseUnitSelectionForOldVersion.courseUnitId = newVersionCourseUnitId;
        courseUnitSelectionForOldVersion.completionMethodId = null;
        courseUnitSelectionForOldVersion.substitutedBy = [];
        courseUnitSelectionForOldVersion.substituteFor = [];

        _.remove(plan.assessmentItemSelections, { courseUnitId: oldVersionCourseUnitId });

        return plan;

    }

    changeSubstituteCourseUnitVersion(courseUnitSelection: CourseUnitSelection, oldVersionCourseUnitId: OtmId, newVersionCourseUnitId: OtmId, plan: Plan) {
        const substitutedCourseUnitIds = _.map(courseUnitSelection.substituteFor, 'substitutedCourseUnitId');
        _.forEach(substitutedCourseUnitIds, (substitutedCourseUnitId) => {
            const substitutedCourseUnitSelection = _.find(plan.courseUnitSelections, { courseUnitId: substitutedCourseUnitId });
            if (substitutedCourseUnitSelection) {
                const substitutedBy = _.get(substitutedCourseUnitSelection, 'substitutedBy');
                _.pull(substitutedBy, oldVersionCourseUnitId);
                substitutedBy.push(newVersionCourseUnitId);
            }
        });
        courseUnitSelection.courseUnitId = newVersionCourseUnitId;
        courseUnitSelection.completionMethodId = null;
        _.remove(plan.assessmentItemSelections, { courseUnitId: oldVersionCourseUnitId });
        return plan;
    }

}
