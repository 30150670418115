import angular from 'angular';
export const publicPersonModuleModel = 'sis-components.model.publicPerson';
(function () {
  publicPersonModel.$inject = ["DS", "personNameService"];
  angular.module(publicPersonModuleModel, ['js-data', 'sis-components.service.personNameService']).factory('publicPersonModel', publicPersonModel);

  /**
   * @ngInject
   */
  function publicPersonModel(DS, personNameService) {
    return DS.defineResource({
      defaultFilter: function (collection, resourceName, params) {
        return params;
      },
      idAttribute: 'id',
      name: 'publicPerson',
      endpoint: '/kori/api/persons',
      computed: {
        fullName: ['firstName', 'firstNames', 'lastName', function (firstName, firstNames, lastName) {
          var personObject = {
            firstName: firstName,
            firstNames: firstNames,
            lastName: lastName
          };
          return personNameService.getFullName(personObject);
        }],
        fullNameReversed: ['firstName', 'firstNames', 'lastName', function (firstName, firstNames, lastName) {
          var personObject = {
            firstName: firstName,
            firstNames: firstNames,
            lastName: lastName
          };
          return personNameService.getFullNameReversed(personObject);
        }]
      }
    });
  }
})();