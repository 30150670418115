<app-open-university-enrolled-info
        [assessmentItemsData]="assessmentItemsData"
        [openUniStudentEnrolments]="openUniStudentEnrolments">
    <ng-template let-assItemIndex #modifySelections>
        <div class="col-md-4 col-sm-12 my-4">
            <sis-button class="edit-selections"
                        [link]="true"
                        title="{{ 'OPEN_UNIVERSITY.ENROLMENT_WIZARD.MODIFY_SELECTIONS' | translate }}"
                        (clicked)="navigateBackToStep(assItemIndex)">
                {{ 'OPEN_UNIVERSITY.ENROLMENT_WIZARD.MODIFY_SELECTIONS' | translate }}
            </sis-button>
        </div>
    </ng-template>
</app-open-university-enrolled-info>
