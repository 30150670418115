import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { EditModeService } from '../edit/editMode.service';
import { FileItem } from '../file-upload/file-upload.component';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-edit-application-attachments',
    templateUrl: './edit-application-attachments.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class EditApplicationAttachmentsComponent implements OnInit {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.editApplicationAttachments.downgraded',
        directiveName: 'sisEditApplicationAttachments',
    };

    addedFiles: FileItem[];
    acceptedFileTypes = ['application/pdf', 'text/plain', 'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/png', 'image/jpeg'];

    @Input() previouslyAddedFiles: FileItem[] = [];
    @Output() newFiles = new EventEmitter<FileItem[]>();
    @Output() editMode = new EventEmitter<boolean>();
    constructor(private editModeService: EditModeService) {}

    ngOnInit(): void {
        this.addedFiles = this.previouslyAddedFiles;
        this.editModeService.setEditMode(true);
    }

    droppedFiles(files: FileItem[]) {
        this.addedFiles = files;
    }

    close() {
        this.editModeService.setEditMode(false);
        this.editMode.emit(false);
    }

    save() {
        this.newFiles.emit(this.addedFiles);
        this.editMode.emit(false);
        this.editModeService.setEditMode(false);
    }
}
