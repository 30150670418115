import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { OtmId, StudyEvent } from 'common-typescript/types';

@Component({
    selector: 'app-student-study-event-summary',
    templateUrl: './student-study-event-summary.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudentStudyEventSummaryComponent {
    @Input() studyEvent: StudyEvent;
    @Input() hasNoLocations: boolean;
    @Input() hasIrregularLocations: boolean;
    @Input() allStudyEventTeacherIds: OtmId[];
    @Input() cancelledOrExcludedEventCount: number;
    @Input() allEventsCancelledOrExcluded: boolean;
    @Input() language: string;
}
