import { Pipe, PipeTransform } from '@angular/core';
import { LocalizedString } from 'common-typescript/types';

@Pipe({
    name: 'entityName',
})
export class EntityNamePipe implements PipeTransform {

    transform(value: any): LocalizedString {
        return value?.name ? value.name : null;
    }
}
