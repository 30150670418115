'use strict';

(function () {
  backendPollService.$inject = ["$log", "$interval", "BACKEND_POLL_INTERVAL"];
  angular.module('tutor.service.backendPoll', []).factory('backendPollService', backendPollService).constant('BACKEND_POLL_INTERVAL', 60000);
  /**
   * @ngInject
   */
  function backendPollService($log, $interval, BACKEND_POLL_INTERVAL) {
    var intervalJob;
    var backendPolls = {};
    function executeBackendPolls() {
      $log.info('executeBackendPolls:', moment().format(), _.keys(backendPolls));
      _.forEach(_.values(backendPolls), function (execution) {
        execution();
      });
    }
    function startPolling() {
      if (!intervalJob) {
        $log.info('Activate backend poll, Interval:', BACKEND_POLL_INTERVAL / 1000 + 's');
        intervalJob = $interval(executeBackendPolls, BACKEND_POLL_INTERVAL);
        executeBackendPolls();
      }
    }
    function stopPolling() {
      $log.info('stop backend polling');
      if (angular.isDefined(intervalJob)) {
        $interval.cancel(intervalJob);
        intervalJob = undefined;
      }
    }
    return {
      start: function ($window) {
        angular.element($window).on('blur', stopPolling);
        angular.element($window).on('focus', startPolling);
        startPolling();
      },
      removePoll: function (id) {
        if (_.has(backendPolls, id)) {
          delete backendPolls[id];
        }
      },
      addPoll: function (id, pollFunction) {
        if (!_.has(backendPolls, id)) {
          backendPolls[id] = pollFunction;
        }
      },
      getPolls: function () {
        return backendPolls;
      }
    };
  }
})();