import { KeyValue } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { StudentPayment } from 'common-typescript/types';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { StudentPaymentEntityService } from 'sis-components/service/student-payment-entity.service';
import { getStudyTermLocator } from 'sis-components/study-terms/study-year-utils';

@Component({
    selector: 'app-payments',
    templateUrl: './payments.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class PaymentsComponent implements OnInit, OnDestroy {
    @Input() studentProfile: any;

    private paymentSubscription: Subscription;

    readonly currentAndFuturePayments: Map<number, StudentPayment[]> = new Map();
    readonly pastPayments: Map<number, StudentPayment[]> = new Map();

    /** A comparator function that causes the `keyvalue` pipe to sort the entries by key in descending order. */
    readonly reverseKeyOrder = (a: KeyValue<number, any>, b: KeyValue<number, any>) => b.key - a.key;

    constructor(private studentPaymentEntityService: StudentPaymentEntityService,
                private appErrorHandler: AppErrorHandler,
    ) {}

    ngOnInit() {
        this.paymentSubscription = this.studentPaymentEntityService.findForStudent(this.studentProfile.id)
            .pipe(
                this.appErrorHandler.defaultErrorHandler(),
                map(payments => payments.sort((a, b) => (b.registrationDate ?? '').localeCompare(a.registrationDate ?? ''))),
            )
            .subscribe(
                studentPayments => this.groupAndSetPayments(studentPayments),
            );
    }

    ngOnDestroy(): void {
        this.paymentSubscription?.unsubscribe();
    }

    private groupAndSetPayments(payments: StudentPayment[]) {
        const currentStudyTerm = getStudyTermLocator();
        const studyYearsWithPayments = payments
            .map(payment => payment?.studyYearStartYear)
            .filter(Boolean)
            .concat(currentStudyTerm.studyYearStartYear)
            .reduce((set, year) => set.add(year), new Set<number>());

        studyYearsWithPayments.forEach((studyYearStartYear) => {
            const paymentsForStudyYear = payments.filter(payment => payment?.studyYearStartYear === studyYearStartYear);
            if (studyYearStartYear >= currentStudyTerm.studyYearStartYear) {
                this.currentAndFuturePayments.set(studyYearStartYear, paymentsForStudyYear);
            } else {
                this.pastPayments.set(studyYearStartYear, paymentsForStudyYear);
            }
        });
    }
}
