<ng-container *transloco="let t">
  <div class="sis-px-md px-md-0 sis-pt-md" aria-labelledby="student-profile-basic-info-tab" id="basic-info" role="tabpanel" tabindex="0">
    <fudis-heading [level]="2">{{ t('PERSONAL_INFORMATION') }}</fudis-heading>
    <div class="row">
      <div class="col-12 col-lg-6" id="student-basic-info-personal-information">
        <fudis-heading [level]="3">{{ t('PROFILE.BASIC_INFO') }}</fudis-heading>
        <div class="row">
          <div class="col-12">
            <app-basic-info-personal-info [person]="studentProfileCopy"></app-basic-info-personal-info>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-11 sis-mb-lg">
            <app-basic-info-personal-info-history [person]="studentProfileCopy"></app-basic-info-personal-info-history>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <app-basic-info-personal-info-details [person]="studentProfileCopy"></app-basic-info-personal-info-details>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="row">
          <div class="col-8">
            <fudis-heading [level]="3">{{ t('PROFILE.CONTACT_INFO') }}</fudis-heading>
          </div>
          <div class="col-4">
            <fudis-button #dialogOpenTriggerButton
                          *ngIf="isEditEnabledByUniversity === true"
                          [id]="'dialogOpenTriggerButton'"
                          [label]="t('SIS_COMPONENTS.BUTTON.EDIT')"
                          (handleClick)="openEditDialog()" />
          </div>
        </div>
        <app-contact-information [studentProfile]="studentProfileCopy"></app-contact-information>
        <div *ngIf="classifiedInfoEditEnabledByUniversity" class="sis-mb-md">
            <fudis-button [label]="t('PROFILE.CLASSIFIED.TITLE')" [icon]="'lock'" [variant]="'tertiary'" (handleClick)="openClassifiedInfoDialog()" />
        </div>
        <ng-template #editContactDialog>
          <app-edit-contact-information [studentProfile]="studentProfileCopy"
                                        (saveEvent)="handleSave($event)">
          </app-edit-contact-information>
        </ng-template>
        <sis-disclosure-authorizations [person]="studentProfileCopy"
                                       [editDisabled]="!isEditEnabledByUniversity">
        </sis-disclosure-authorizations>
        <div class="student-personal-data-safety-nondisclosure">
          <fudis-heading [level]="3">{{ t('FIELD_NAMES.personalDataSafetyNonDisclosure') }}</fudis-heading>
          <fudis-body-text [variant]="'lg-light'">
            <ng-container *ngIf="studentProfileCopy?.personalDataSafetyNonDisclosure">
              {{'PROFILE.PERSONAL_DATA_SAFETY_NON_DISCLOSURE' | translate}}
            </ng-container>
            <ng-container *ngIf="!studentProfileCopy?.personalDataSafetyNonDisclosure">
              {{'PROFILE.NO_PERSONAL_DATA_SAFETY_NON_DISCLOSURE' | translate}}
            </ng-container>
          </fudis-body-text>
        </div>
      </div>
    </div>
  </div>
</ng-container>
