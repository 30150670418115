import _ from 'lodash';
import angular from 'angular';
import moment from 'moment';
import { from, isObservable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { localeServiceModule } from 'sis-common/l10n/localeService';
import { filterInput, TypeaheadComponent } from 'sis-components/typeahead/typeahead.component.ts';
import searchOrganisationTpl from './searchOrganisation.tpl.html';
export const searchOrganisationModule = 'sis-components.organisation.searchOrganisation';
(function () {
  searchOrganisationController.$inject = ["$translate", "$element", "$log", "$timeout", "universityService", "localizedStringFilter", "translateLocales"];
  angular.module(searchOrganisationModule, ['pascalprecht.translate', 'sis-common.university', 'sis-common.l10n.localizedStringFilter', localeServiceModule, TypeaheadComponent.downgrade.moduleName]).component('searchOrganisation', {
    template: searchOrganisationTpl,
    controller: searchOrganisationController,
    bindings: {
      forceUpdate: '<',
      onSelect: '&',
      disabledOrganisationIds: '=?',
      organisation: '<',
      organisationService: '<',
      required: '<?'
    }
  });

  /**
   * @ngInject
   */
  function searchOrganisationController($translate, $element, $log, $timeout, universityService, localizedStringFilter, translateLocales) {
    var $ctrl = this;
    $ctrl.$onInit = function () {
      if (_.isEmpty($ctrl.organisationService)) {
        throw 'organisationSearch directive: organisationService attribute is required';
      }
      $ctrl.inputElement = $element.find('search-organisation');
      $ctrl.inputItem = $ctrl.organisation;
    };
    $ctrl.$onChanges = function (changes) {
      // Don't update inputItem with an empty value by default, it clears the input field too soon
      if (_.get(changes, 'organisation.currentValue') || _.get(changes, 'forceUpdate') && _.get(changes, 'organisation')) {
        $ctrl.inputItem = changes.organisation.currentValue;
      }
    };
    $ctrl.inputChanged = function (item) {
      if (!item) {
        $ctrl.selectItem(null);
      }
    };
    $ctrl.selectItem = function (item) {
      if (_.isNil(item)) {
        $ctrl.organisation = null;
      } else {
        $ctrl.organisation = item;
      }
      if (angular.isDefined($ctrl.onSelect)) {
        $timeout(() => $ctrl.onSelect({
          orgObj: $ctrl.organisation
        }));
      }
    };
    $ctrl.isDisabled = function (item) {
      return (!$ctrl.organisation || $ctrl.organisation.id !== item.id) && (!$ctrl.disabledOrganisationIds || _.includes($ctrl.disabledOrganisationIds, item.id));
    };
    $ctrl.doSearch = function (query$) {
      return query$.pipe(filterInput(), switchMap(query => {
        const universityOrgId = universityService.getCurrentUniversityOrgId();
        const organisationSnapshots = $ctrl.organisationService.searchSnapshots(query, universityOrgId);
        return isObservable(organisationSnapshots) ? organisationSnapshots : from(organisationSnapshots);
      }), map(results => {
        const today = moment().startOf('day');
        const finalResults = _.sortBy(_.map(results.searchResults, snapshot => {
          if (today.isBefore(moment(snapshot.snapshotDateTime))) {
            snapshot.isFuture = true;
          }
          snapshot.disabled = $ctrl.isDisabled(snapshot);
          return snapshot;
        }), snapshot => localizedStringFilter(snapshot.name));

        // If search got more results than we can fit in the page
        const more = results.total - results.start - results.searchResults.length;
        if (more > 0) {
          finalResults.push({
            disabled: true,
            name: _.chain(translateLocales).keyBy().mapValues(locale => $translate.instant('SIS_COMPONENTS.ORGANISATION.SEARCH_ORGANISATION.SEARCH_MORE_ITEMS', {
              count: more
            }, null, locale)).value()
          });
        }
        return finalResults;
      }));
    };
    $ctrl.renderOrganisation = organisation => localizedStringFilter(organisation.name);
  }
})();