import { errorServiceModule } from 'sis-components/error-handler/legacy/errorService';
import { ModalService } from 'sis-common/modal/modal.service.ts';
import { CustomAttainmentCreditInfoModalComponent } from 'sis-components/attainment/custom-attainment-credit-info-modal/custom-attainment-credit-info-modal.component.ts';
import timelineCustomCourseUnitAttainmentTpl from './timelineCustomCourseUnitAttainment.tpl.html';
export const timelineCustomCourseUnitAttainmentModule = 'student.timing.timelineCustomCourseUnitAttainment';
'use strict';
(function () {
  timelineCustomCourseUnitAttainmentController.$inject = ["$log", "courseUnitInfoModal", "colorService", "modalService"];
  angular.module('student.timing.timelineCustomCourseUnitAttainment', ['sis-common.l10n', errorServiceModule, 'sis-components.service.colorService', ModalService.downgrade.moduleName]).component('timelineCustomCourseUnitAttainment', {
    bindings: {
      customCourseUnitAttainment: '<',
      validatablePlan: '<'
    },
    template: timelineCustomCourseUnitAttainmentTpl,
    controller: timelineCustomCourseUnitAttainmentController
  });

  /**
   * @ngInject
   */
  function timelineCustomCourseUnitAttainmentController($log, courseUnitInfoModal, colorService, modalService) {
    var $ctrl = this;
    $ctrl.$onInit = function () {
      $ctrl.colorCategory = colorService.getCourseUnitColorCategory($ctrl.validatablePlan, $ctrl.customCourseUnitAttainment.id) || '';
    };
    $ctrl.openCustomCourseUnitAttainmentInfo = function () {
      return modalService.open(CustomAttainmentCreditInfoModalComponent, $ctrl.customCourseUnitAttainment, {
        closeWithOutsideClick: true,
        size: 'lg'
      });
    };
    $ctrl.getGradeAbbreviation = attainment => {
      if (attainment && attainment.gradeScale) {
        const gradeId = attainment.gradeId;
        let gradeText = '';
        if (gradeId !== undefined && gradeId !== null) {
          const grades = attainment.gradeScale.grades;
          gradeText = _.result(_.find(grades, grade => grade.localId === gradeId), 'abbreviation');
        }
        return gradeText;
      } else {
        $log.warn('gradeScale undefined for customCourseUnitAttainment:', attainment);
        return attainment.gradeId;
      }
    };
  }
})();