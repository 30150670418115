<div class="row" *ngIf="prerequisites">
  <div class="col-12 col-md-6">
    <div class="form-group-mimic">
      <sis-description-list>
        <dl>
          <ng-template #listItem>
            <dt class="sis-label">{{'STUDIES.COURSE_UNIT_INFO_MODAL.PREREQUISITES_DESCRIPTION' | translate}}</dt>
            <dd><sis-localized-markup-string-view [content]="prerequisites"></sis-localized-markup-string-view></dd>
          </ng-template>
        </dl>
      </sis-description-list>
    </div>
  </div>
</div>
<div class="row" *ngIf="compulsoryFormalPrerequisites?.length > 0 || recommendedFormalPrerequisites?.length > 0">
  <div class="col-12 col-md-5" *ngIf="compulsoryFormalPrerequisites?.length > 0">
    <sis-basic-grouping-panel [heading]="'STUDIES.COURSE_UNIT_INFO_MODAL.OVERVIEW_COMPULSORY_FORMAL_PREREQUISITES'" [level]="5" [size]="'sm'">
      <sis-prerequisites [prerequisites]="compulsoryFormalPrerequisites" [level]="6"></sis-prerequisites>
    </sis-basic-grouping-panel>
  </div>
  <div class="col-12 col-md-5 offset-md-1" *ngIf="recommendedFormalPrerequisites?.length > 0">
    <sis-basic-grouping-panel [heading]="'STUDIES.COURSE_UNIT_INFO_MODAL.OVERVIEW_RECOMMENDED_FORMAL_PREREQUISITES'" [level]="5" [size]="'sm'">
      <sis-prerequisites [prerequisites]="recommendedFormalPrerequisites" [level]="6"></sis-prerequisites>
    </sis-basic-grouping-panel>
  </div>
</div>
<div *ngIf="!prerequisites && compulsoryFormalPrerequisites?.length === 0 && recommendedFormalPrerequisites?.length === 0">
  <span>{{ 'SIS_COMPONENTS.COURSE_UNIT_REALISATION_INFO.NO_INFORMATION_GIVEN' | translate }}</span>
</div>
