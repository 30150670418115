import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { TransitionService } from '@uirouter/angular';
import { StateService } from '@uirouter/core';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

@Injectable({ providedIn: 'root' })
@StaticMembers<DowngradedService>()
export class UiRouterService {

    static downgrade: ServiceDowngradeMappings = {
        moduleName: 'sis-components.uiRouterService',
        serviceName: 'uiRouterService',
    };

    private previousState: { name: string; params: { [key: string]: any } };

    constructor(
        transitionService: TransitionService,
        private stateService: StateService,
        @Inject(DOCUMENT) private document: Document,
    ) {
        transitionService.onSuccess({}, (transition) => {
            this.previousState = {
                name: transition.from().name,
                params: transition.params('from'),
            };
        });
    }

    /**
     * Starts a transition to the state the user was in before coming to the current state. This is similar to
     * clicking the browser's back button, except that this function causes a new history entry to be created,
     * instead of going back in history. Usage of this function is preferable to using history.back(), as the
     * latter can cause problems with ui-router transition hooks.
     *
     * If there is no known previous state (e.g. the user came into the current state with a direct URL from
     * another frontend app / domain, or the current page was just refreshed), this function falls back to using
     * history.back(). In this case a new history entry will not be created, but the browser will in fact go
     * back in history.
     */
    goToPreviousState(): void {
        if (this.previousState?.name) {
            this.stateService.go(this.previousState.name, this.previousState.params);
        } else {
            this.document.defaultView?.history.back();
        }
    }
}
