<ng-container *transloco="let t">
  <div class="course-unit-info-modal cu-color-primary">
    <app-student-course-unit-info-modal-header [courseUnit]="courseUnit" [validatablePlan]="validatablePlan" (closeModal)="close()"></app-student-course-unit-info-modal-header>

    <div class="modal-body">
      <h3 class="sis-mt-zero ng-binding">{{ t('COURSE_UNIT_INFO_MODAL.TAB_HEADINGS.INFO') }}</h3>
      <app-course-unit-info-brochure
        [courseUnit]="courseUnit" [level]="4"
        [sortedByNameResponsibilityInfos]="filteredResponsibilityInfos">
      </app-course-unit-info-brochure>
    </div>
  </div>
</ng-container>
