'use strict';

(function () {
  angular.module('student.common.model.assessmentItem', ['sis-common.model.assessmentItemNameService', 'student.shared']).factory('assessmentItemModel', assessmentItemModel);
  assessmentItemModel.$inject = ["Range", "assessmentItemNameService"];
  function assessmentItemModel(Range, assessmentItemNameService) {
    var assessmentItemModel = {
      newAssessmentItem: newAssessmentItem,
      AssessmentItem: AssessmentItem
    };
    function newAssessmentItem(data) {
      if (data) {
        return new AssessmentItem(data);
      } else {
        return null;
      }
    }
    function AssessmentItem(data) {
      var self = this;
      var defaults = {
        selectedParent: null
      };
      _.assign(self, defaults, data);
      self.fullName = assessmentItemNameService.generateFullName(self);
      self.credits = data.credits ? new Range(data.credits) : Range.zero();
    }
    AssessmentItem.prototype.detach = function () {
      if (this.selectedParent) {
        // Clear selectedParent before removing from parent which calls right back to this detach
        var parent = this.selectedParent;
        this.selectedParent = null;
        parent.removeSelectedAssessmentItem(this);
      }
    };
    return assessmentItemModel;
  }
})();