import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { DegreeProgrammeAttainmentApplicationAnswer } from 'common-typescript/types';

@Component({
    selector: 'sis-degree-programme-attainment-application-answer',
    templateUrl: './degree-programme-attainment-application-answer.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DegreeProgrammeAttainmentApplicationAnswerComponent {

    @Input() answer: DegreeProgrammeAttainmentApplicationAnswer;

}
