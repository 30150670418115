import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CountryCode, EMP, GenerateEmrexSessionResponse } from 'common-typescript/types';
import _ from 'lodash';
import { Observable, switchMap } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { LocalizedStringPipe } from 'sis-common/l10n/localized-string.pipe';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { Option } from 'sis-components/select/dropdown-select/dropdown-select.component';
import { CommonCodeService, IndexedCodes } from 'sis-components/service/common-code.service';
import { EmrexService } from 'sis-components/service/emrex.service';

@Component({
    selector: 'app-select-emrex-access-point-modal',
    templateUrl: './select-emrex-access-point-modal.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectEmrexAccessPointModalComponent implements OnInit {
    selectedAccessPointUrl: string;
    EMPOptions$: Observable<Option[]>;

    constructor(
        @Inject(DOCUMENT)
        private document: Document,
        private commonCodeService: CommonCodeService,
        private localizedStringPipe: LocalizedStringPipe,
        private emrexService: EmrexService,
        private appErrorHandling: AppErrorHandler,
        public modal: NgbActiveModal) {
    }

    ngOnInit(): void {
        this.EMPOptions$ = this.getEMPOptions();
    }

    private getEMPOptions(): Observable<Option[]> {
        return this.emrexService.getEMPList().pipe(
            take(1),
            switchMap(empList => this.fetchCountries().pipe(
                map(countries => this.mapAndSortOptions(empList, countries)),
            )),
            this.appErrorHandling.defaultErrorHandler(),
        );
    }

    private fetchCountries(): Observable<IndexedCodes> {
        return this.commonCodeService.getCodebookObservable('urn:code:country');
    }

    mapAndSortOptions(empList: EMP[], countries: IndexedCodes) {
        const options = _.chain(empList)
            .groupBy('countryCode')
            .map((group, countryCode) => this.translateAndMap(group, countryCode, countries))
            .value();

        return _.sortBy(options, 'countryName').flatMap(option => [
            { value: option.countryCode, label: option.countryName, header: true },
            ...option.group,
        ]);
    }

    private translateAndMap(group: EMP[], countryCode: string, countries: IndexedCodes): any {
        const code = Object.values(countries).find((cc: CountryCode) => cc.alpha2 === countryCode.toLowerCase()) as CountryCode;
        const translatedCountryName = code ? this.localizedStringPipe.transform(code.name) : countryCode;

        return {
            countryCode,
            countryName: translatedCountryName,
            group: _.sortBy(group, emp => emp.name).map(emp => ({
                value: emp.url,
                label: emp.name,
            })),
        };
    }

    setSelectedAccessPointUrl(url: string): void {
        this.selectedAccessPointUrl = url;
    }

    redirectToAccessPoint() {
        if (this.selectedAccessPointUrl) {
            this.emrexService.generateSession(this.selectedAccessPointUrl, this.getRedirectBaseUrl() + window.location.pathname)
                .pipe(
                    tap(generatedSession => this.createFormAndRedirect(this.selectedAccessPointUrl, generatedSession)),
                    this.appErrorHandling.defaultErrorHandler(),
                ).subscribe();
        }
    }

    private getRedirectBaseUrl() {
        const protocol = window.location.protocol;
        const hostname = window.location.hostname;
        const port = window.location.port;

        let fullUrl = `${protocol}//${hostname}`;
        if (port) {
            fullUrl += `:${port}`;
        }
        return fullUrl;
    }

    private createFormAndRedirect(url: string, generatedSession: GenerateEmrexSessionResponse) {
        const form = this.document.createElement('form');
        form.method = 'POST';
        form.target = '_top';
        form.action = url;

        this.createInput(form, 'sessionId', generatedSession.sessionId);
        this.createInput(form, 'returnUrl', `${this.getRedirectBaseUrl()}/elmo/api/emrex/elmo`);

        this.document.body.appendChild(form);
        form.submit();
    }

    private createInput(form: HTMLFormElement, name: string, value: string) {
        const input = this.document.createElement('input');
        input.type = 'hidden';
        input.name = name;
        input.value = value;

        form.append(input);
    }
}
