import angular from 'angular';
export const educationPhaseProgressModule = 'sis-components.applications.educationPhaseProgress';
const template = `<span ng-if="$ctrl.item">
  {{$ctrl.item.phaseName | localizedString}}
  {{$ctrl.item.attainedCredits || 0}}/{{$ctrl.item.targetCredits.min || 0}}
  {{'CREDITS' | translate}}
</span>`;
angular.module(educationPhaseProgressModule, []).component('educationPhaseProgress', {
  template,
  bindings: {
    item: '<'
  }
});