<ng-container *ngFor="let assessmentItem of assessmentItemsData; let assItemIndex = index">
    <div *ngFor="let cur of filterAssessmentItemCurs(assessmentItem)" class="cur-separator">

        <div class="row cur-enrolment-container">
            <div class="col-md-8 col-sm-12">
                <div role="heading" [attr.aria-level]="level" class="fudis-heading__lg sis-mt-sm">{{ cur.name | localizedString }} {{ cur.activityPeriod | localDateRange }}</div>

                <div *ngFor="let sgs of cur.studyGroupSets" class="sis-my-sm">
                    <div *ngIf="sgsSsgIdMatchToEnrolmentSSG(sgs)" role="heading" [attr.aria-level]="level+1"
                        class="fudis-heading__md sis-my-sm">
                        {{ sgs.name | localizedString }}
                    </div>
                    <div *ngFor="let ssg of sgs.studySubGroups">
                      <sis-description-list *ngIf="ssgIdMatchToEnrolmentSSG(ssg.id)">
                        <dl>
                          <ng-template #listItem>
                            <dt class="sis-label">{{ssg.name | localizedString}}</dt>
                            <dd>
                              <app-study-event-info-list [studyEvents]="ssg.studyEvents" [teacherIds]="ssg.teacherIds"/>
                            </dd>
                          </ng-template>
                        </dl>
                      </sis-description-list>

                    </div>
                </div>
                <div *ngIf="!hideEnrolmentQuestionnaire && cur.enrolmentQuestionnaire?.enrolmentQuestions?.length > 0">
                    <div role="heading" [attr.aria-level]="level+1" class="fudis-heading__md">{{ 'OPEN_UNIVERSITY.ENROLMENT_WIZARD.ENROLMENT_QUESTIONAIRE_TITLE' | translate }}</div>
                    <div *ngFor="let enrolmentQuestion of cur?.enrolmentQuestionnaire?.enrolmentQuestions; let enrolmentQIndex = index" class="mb-4">
                      <sis-description-list *ngIf="enrolmentQuestion?.questionType === 'FREE_TEXT'">
                        <dl>
                          <ng-template #listItem>
                            <dt class="sis-label">{{parseFreetextQuestionTitle((enrolmentQIndex + 1), (enrolmentQuestion?.question | localizedString))}}</dt>
                            <dd>{{ findEnrolmentQuestionAnswer(enrolmentQuestion.localId, 'FREE_TEXT') }}</dd>
                          </ng-template>
                        </dl>
                      </sis-description-list>
                      <sis-description-list *ngIf="enrolmentQuestion.questionType === 'LANGUAGE_SELECT'">
                        <dl>
                          <ng-template #listItem>
                            <dt class="sis-label">{{ 'ENROLMENT_QUESTIONAIRE_LANG_ANSWER_TITLE' | translate }}</dt>
                            <dd>{{ findEnrolmentQuestionAnswer(enrolmentQuestion.localId, 'LANGUAGE_SELECT') | codeLocalizedName | async }}</dd>
                          </ng-template>
                        </dl>
                      </sis-description-list>
                    </div>
                </div>
            </div>

            <ng-container
                    *ngIf="modifySelectionsTemplateRef"
                    [ngTemplateOutlet]="modifySelectionsTemplateRef"
                    [ngTemplateOutletContext]="{$implicit:assItemIndex}">
            </ng-container>
        </div>
    </div>
</ng-container>

