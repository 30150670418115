import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import { PlanValidationTs } from 'common-typescript';
import { createRootRule } from 'common-typescript/src/plan/validation/moduleRootRuleCreator.ts';
import { EditPlanNameModalComponent } from 'sis-components/plan/edit-plan-name-modal/edit-plan-name-modal.component.ts';
import { take } from 'rxjs';
import planTpl from './plan.tpl.html';
import { StudentCustomStudyDraftModalComponent } from './custom-study-draft-modal/custom-study-draft-modal.component.ts';
(function () {
  PlanController.$inject = ["$state", "uiContext", "confirmDialogService", "ChangeLearningOpportunityModal", "commonStudyRightService", "previewMode", "$window", "$q", "planService", "planCopyService", "educationsWithPlans", "$log", "curriculumPeriodService", "$scope", "planContextEventType", "defaultPromiseHandler", "planStudyRightService", "planStudyRightModal", "commonEducationService", "moduleType", "PlanState", "graduationModalService", "$rootScope", "degreeProgrammeAttainmentApplicationService", "STUDENT_APPLICATION_STATE", "minorStudyRightService", "minorSelectionModalService", "alertsService", "$translate", "$filter", "$location", "showViewModuleContentApplicationLinkService", "studentPlanOutdatedCourseUnitsService", "rawPlanEditService", "serviceBreakService", "modalService", "commonPlanService", "appErrorHandler", "outdatedCourseUnitsModalServiceNg", "selectEmrexAccessPointModalServiceNg", "emrexResultModalServiceNg", "universityService"];
  angular.module('student.plan').component('plan', {
    template: planTpl,
    bindings: {
      moduleId: '<',
      curriculumPeriodId: '<',
      returnUrl: '<',
      plans: '<',
      openUnplanned: '<?',
      unplannedCourseUnitId: '<?',
      emrexResultCode: '<?'
    },
    controller: PlanController
  });
  function PlanController(
  // NOSONAR
  $state, uiContext, confirmDialogService, ChangeLearningOpportunityModal, commonStudyRightService, previewMode, $window, $q, planService, planCopyService, educationsWithPlans, $log, curriculumPeriodService, $scope, planContextEventType, defaultPromiseHandler, planStudyRightService, planStudyRightModal, commonEducationService, moduleType, PlanState, graduationModalService, $rootScope, degreeProgrammeAttainmentApplicationService, STUDENT_APPLICATION_STATE, minorStudyRightService, minorSelectionModalService, alertsService, $translate, $filter, $location, showViewModuleContentApplicationLinkService, studentPlanOutdatedCourseUnitsService, rawPlanEditService, serviceBreakService, modalService, commonPlanService, appErrorHandler, outdatedCourseUnitsModalServiceNg, selectEmrexAccessPointModalServiceNg, emrexResultModalServiceNg, universityService) {
    const $ctrl = this;
    $ctrl.educations = [];
    $ctrl.planCurriculumPeriods = [];
    $ctrl.planMenuOptions = [];
    $ctrl.freeEditMenuOptions = [{
      action: () => $ctrl.toggleFreeEditMode(),
      name: 'FREE_EDIT.TITLE'
    }];
    $ctrl.uiContext = uiContext;
    $ctrl.getPlan = getPlan;
    $ctrl.isRightBarOpen = isRightBarOpen;
    $ctrl.moveFocusToElement = moveFocusToElement;
    $ctrl.isLeftBarOpen = false;
    $ctrl.openLeftBar = openLeftBar;
    $ctrl.closeLeftBar = closeLeftBar;
    $ctrl.getPlanContext = getPlanContext;
    $ctrl.showPlanCourseUnitInstructions = uiContext.hasPlanningOfCourseUnit;
    $ctrl.hasTargetModule = uiContext.hasTargetModule;
    $ctrl.activePlanName = getPlan();
    $ctrl.deletePlan = deletePlan;
    $ctrl.changeLearningOpportunity = changeLearningOpportunity;
    $ctrl.canLeftBarBeOpened = canLeftBarBeOpened;
    $ctrl.loadPreview = loadPreview;
    $ctrl.returnToStaff = returnToStaff;
    $ctrl.isPlanStateInvalid = isPlanStateInvalid;
    $ctrl.copyPlan = copyPlan;
    $ctrl.toggleFreeEditMode = toggleFreeEditMode;
    $ctrl.isGraduationAllowed = isGraduationAllowed;
    $ctrl.isGraduationRequestedForModule = isGraduationRequested;
    const resolveRootRule = () => createRootRule(uiContext.planContext.getModule($ctrl.educationId), $ctrl.validatablePlan.studyRight);
    $ctrl.$onInit = function () {
      if (previewMode.isPreviewMode()) {
        $ctrl.isPreview = true;
        loadPreview();
      } else {
        $ctrl.isPreview = false;
        if (uiContext.planContext && uiContext.planContext.primary) {
          checkIfPlanIsDeletable();
        }
      }
      if ($ctrl.openUnplanned) {
        $ctrl.isLeftBarOpen = true;
      }
      if ($ctrl.emrexResultCode) {
        handleEmrexResultCode($ctrl.emrexResultCode);
      }
      if (!$ctrl.isPreview && uiContext.planContext) {
        $ctrl.validatablePlan = getPlan().validatablePlan;
        $ctrl.educationId = getPlan().root.id;
        $ctrl.learningOpportunityId = getPlan().learningOpportunityId;
        commonStudyRightService.getEducationOptionNamingTypes().then(result => {
          $ctrl.educationOptionNamingTypes = result;
        }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
        commonStudyRightService.studyRights(false).then(allStudyRights => {
          $ctrl.studyRights = commonStudyRightService.filterStudyRightsAffectingPlansByEducationIds(allStudyRights, _.map($ctrl.plans, 'educationId'));
          $ctrl.matchingStudyRight = _.find($ctrl.studyRights, studyRight => {
            if (studyRight.educationId !== $ctrl.educationId) {
              return false;
            }
            if (studyRight.learningOpportunityId !== $ctrl.learningOpportunityId) {
              return false;
            }
            if (studyRight.documentState !== 'ACTIVE') {
              return false;
            }
            if (!_.includes(['ACTIVE', 'ACTIVE_NONATTENDING', 'PASSIVE', 'NOT_STARTED'], studyRight.state)) {
              return false;
            }
            return true;
          });
          $ctrl.studyRightMatchingPlanEducation = _.find($ctrl.studyRights, studyRight => studyRight.educationId === $ctrl.educationId && studyRight.documentState === 'ACTIVE' && (studyRight.state === 'ACTIVE' || studyRight.state === 'PASSIVE'));
          $ctrl.studyRightMatchingPlanEducationAndLearningOpportunity = _.find(allStudyRights, {
            educationId: $ctrl.educationId,
            learningOpportunityId: $ctrl.learningOpportunityId
          });
          initEducations();
          initSelectedPlan();
          universityService.getCurrentUniversitySettings().then(universitySettings => {
            const includeEmrex = _.get(universitySettings, 'frontendFeatureToggles.emrexIntegrationEnabled', false);
            $ctrl.planMenuOptions = getPlanMenuOptions(includeEmrex);
          }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
        }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
        $ctrl.rootRule = resolveRootRule();
      }
      $ctrl.customCourseUnitAttainmentSelectionsByParent = getCustomCourseUnitAttainmentsByParentId();
      $ctrl.customModuleAttainmentSelectionsByParent = getCustomModuleAttainmentsByParentId();
      $ctrl.requestedDegreeProgrammeAttainmentApplications = null;
    };
    $scope.$on(planContextEventType.planBuilt, () => {
      $ctrl.customCourseUnitAttainmentSelectionsByParent = getCustomCourseUnitAttainmentsByParentId();
      $ctrl.customModuleAttainmentSelectionsByParent = getCustomModuleAttainmentsByParentId();
      initSelectedPlan();
    });
    $scope.$on('studyRightChanged', () => {
      initSelectedPlan();
    });
    $ctrl.openOutdatedCourseUnitsModal = () => {
      outdatedCourseUnitsModalServiceNg.open({
        courseUnits: $ctrl.outDatedCourseUnitsInPlan,
        validatablePlan: $ctrl.validatablePlan
      }).subscribe({
        next: () => {/* Modal was closed */},
        error: () => {/* Modal was closed */}
      });
    };
    $ctrl.markAsPrimary = function () {
      if (uiContext.planContext && !uiContext.planContext.primary) {
        uiContext.planContext.setPrimary(true);
        return savePlan().then(checkIfPlanIsDeletable).then(() => {
          $state.reload();
        }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
      }
    };
    $ctrl.getSelectedRule = () => {
      const selectedModuleId = _.get(uiContext, 'selectedModule.id');
      if (selectedModuleId) {
        const module = uiContext.planContext.getModule(selectedModuleId);
        return $ctrl.isPlanRootModule(module) ? $ctrl.rootRule : module.rule;
      }
      return undefined;
    };
    $ctrl.getSelectedModule = function () {
      const selectedModuleId = _.get(uiContext, 'selectedModule.id');
      if (selectedModuleId) {
        return uiContext.planContext.getModule(selectedModuleId);
      }
      return undefined;
    };
    $ctrl.isPlanRootModule = module => _.get(module, 'id') === $ctrl.educationId;
    $ctrl.closeRightBar = function () {
      uiContext.closeFreeEdit();
      $ctrl.uiContext.clearSelection();
      if (uiContext.hasPlanningOfCourseUnit()) {
        $ctrl.isLeftBarOpen = true;
      }

      // Check if there is information, that which cell was clicked to open up right bar. If there is, we focus to it! This is originally initiated in planModule.component.js.
      const statusCellToFocus = window.sessionStorage.getItem('clickedStatusCell');
      if (statusCellToFocus) {
        document.getElementById(statusCellToFocus)?.focus();
        sessionStorage.removeItem('clickedStatusCell');
      }
    };
    $rootScope.$on('closeLeftBar', () => {
      $ctrl.isLeftBarOpen = false;
    });
    $rootScope.$on('openLeftBarCloseRightBar', () => {
      $ctrl.isLeftBarOpen = true;
      $ctrl.closeRightBar();
    });
    $ctrl.showEducationPaths = function () {
      planStudyRightModal.open($ctrl.uiContext.planContext.root, $ctrl.matchingStudyRight, $ctrl.validatablePlan, $ctrl.educationOptions).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    };
    $ctrl.isEducationOption = function (module) {
      if (module && $ctrl.educationOptions) {
        const educationOption = $ctrl.getEducationOption(module);
        if (educationOption && educationOption.isInPlan && !educationOption.isInPlanAsMinor) {
          return true;
        }
        return false;
      }
      return false;
    };
    $ctrl.getEducationOption = function (module) {
      if (module && $ctrl.educationOptions) {
        return planStudyRightService.getMatchingEducationOption(module.groupId, $ctrl.educationOptions, $ctrl.validatablePlan);
      }
      return undefined;
    };
    $ctrl.getEducationOptionNamingTypeNameForModule = function (module) {
      if ($ctrl.educationOptionNamingTypes) {
        const educationOption = $ctrl.getEducationOption(module);
        if (educationOption && educationOption.namingType) {
          return _.get($ctrl.educationOptionNamingTypes, educationOption.namingType).name;
        }
        return null;
      }
      return null;
    };

    /**
     * Determines if the user can apply for custom attainments for the currently selected plan. This applies to the
     * prior learning inclusion and custom attainment applications, both of which result in a custom attainment.
     */
    $ctrl.canApplyForCustomAttainments = function () {
      const planContext = getPlan();
      if (planContext && !_.isEmpty($ctrl.educations) && !_.isEmpty($ctrl.studyRights)) {
        const education = _.find($ctrl.educations, {
          id: _.get(planContext, 'root.id')
        });
        if (education && commonEducationService.isDegreeEducation(education)) {
          const {
            validatablePlan
          } = planContext;
          const educationChildModules = validatablePlan.getSelectedModulesById(validatablePlan.rootModule);
          return !_.isEmpty(educationChildModules) && _.every(educationChildModules, {
            type: moduleType.degreeProgramme
          }) && _.some($ctrl.studyRights, {
            educationId: _.get(planContext, 'root.id'),
            learningOpportunityId: planContext.learningOpportunityId
          });
        }
      }
      return false;
    };
    function isModulePlanStateValidForGraduation(module) {
      return _.includes([PlanState.PARTS_ATTAINED, PlanState.APPROVED_PARTS_ATTAINED, PlanState.APPROVED_CONDITIONALLY_PARTS_ATTAINED], PlanValidationTs.getPlanStateForModule(module, $ctrl.validatablePlan, $ctrl.planValidationResult));
    }
    function isApplicationRequested(module) {
      return !_.isNil($ctrl.requestedDegreeProgrammeAttainmentApplications) && _.includes(_.map($ctrl.requestedDegreeProgrammeAttainmentApplications, 'moduleId'), module.id);
    }
    function isModuleValidForGraduate(module) {
      return _.get(module, 'type') === moduleType.degreeProgramme && !isApplicationRequested(module) && isModulePlanStateValidForGraduation(module) && !$ctrl.validatablePlan.isModuleAttained(_.get(module, 'id'));
    }
    function isGraduationAllowed() {
      return uiContext.degreeProgrammeAttainmentApplicationAllowed && _.get($ctrl.validatablePlan, 'plan.primary');
    }
    function isGraduationRequested(module) {
      return _.get(module, 'type') === moduleType.degreeProgramme && isApplicationRequested(module) && !$ctrl.validatablePlan.isModuleAttained(_.get(module, 'id'));
    }
    function setRequestedDegreeProgrammeAttainmentApplications() {
      return degreeProgrammeAttainmentApplicationService.findByStates([STUDENT_APPLICATION_STATE.REQUESTED, STUDENT_APPLICATION_STATE.IN_HANDLING], false).then(applications => {
        $ctrl.requestedDegreeProgrammeAttainmentApplications = applications;
      }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    }
    const debouncedHandleAlerts = _.debounce(() => {
      handleMinorSelectionAlerts();
      handleGraduationAlerts();
    }, 1000);
    function handleAlerts() {
      // Avoid dismissing all alerts and hiding actual alerts
      alertsService.dismissAllMatchingAlerts(/plan-alert/);
      serviceBreakService.refresh(); // Restore alerts about service breaks
      debouncedHandleAlerts();
    }
    function handleMinorSelectionAlerts() {
      _.forEach(getConfirmableMinorSelections(), selection => {
        alertsService.info($translate.instant('PLAN.MINOR_SELECTION_TO_CONFIRM', {
          moduleName: $filter('localizedString')(selection.name)
        }), undefined, `plan-alert-minor-selection-confirmation-${selection.id}`);
      });
    }
    function getConfirmableMinorSelections() {
      return _.chain($ctrl.validatablePlan.modulesById).filter(module => minorStudyRightService.isMinorSelection(module, $ctrl.validatablePlan)).filter(module => minorStudyRightService.isModuleSelectedUnderStudyRightPhase(module, $ctrl.matchingStudyRight, $ctrl.validatablePlan) && _.isNil(minorStudyRightService.getMinorSelection(module, $ctrl.matchingStudyRight, $ctrl.validatablePlan))).value();
    }
    function handleGraduationAlerts() {
      _.forEach(getGraduatableDegreeProgrammes(), degreeProgramme => {
        alertsService.success($translate.instant('PLAN.CAN_GRADUATE_TEXT', {
          module: $filter('localizedString')(degreeProgramme.name)
        }), undefined, `plan-alert-graduatable-${degreeProgramme.id}`);
      });
      _.forEach(getDegreeProgrammesWithGraduationRequested(), degreeProgramme => {
        alertsService.info($translate.instant('PLAN.GRADUATION_REQUESTED_ALERT', {
          module: $filter('localizedString')(degreeProgramme.name)
        }), undefined, `plan-alert-graduation-requested-${degreeProgramme.id}`);
      });
    }
    function getGraduatableDegreeProgrammes() {
      if (isGraduationAllowed()) {
        return _.chain($ctrl.validatablePlan.modulesById).values().filter(isModuleValidForGraduate).value();
      }
      return [];
    }
    function getDegreeProgrammesWithGraduationRequested() {
      return _.chain($ctrl.validatablePlan.modulesById).values().filter(isGraduationRequested).value();
    }
    $ctrl.graduationRequestedForSelectedModule = function () {
      return isGraduationRequested($ctrl.getSelectedModule());
    };
    $ctrl.canGraduateFromSelectedModule = function () {
      return isGraduationAllowed() && isModuleValidForGraduate($ctrl.getSelectedModule());
    };
    $ctrl.applyForGraduation = function () {
      graduationModalService.openApplyForGraduationModal(_.get($ctrl.studyRightMatchingPlanEducation, 'organisationId'), $ctrl.getSelectedModule().id, $ctrl.getPlan().id).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    };
    $ctrl.navigateToDegreeProgrammeAttainmentApplication = function () {
      const applicationId = _.chain($ctrl.requestedDegreeProgrammeAttainmentApplications).find({
        moduleId: $ctrl.getSelectedModule().id
      }).get('id').value();
      $state.go('student.logged-in.profile.applications.degree-programme-attainment-workflow', {
        applicationId
      });
    };
    $ctrl.showMinorSelectionBlock = function () {
      return $ctrl.matchingStudyRight && minorStudyRightService.isMinorSelection($ctrl.getSelectedModule(), $ctrl.validatablePlan);
    };
    $ctrl.getApprovedMinorSelection = function () {
      return minorStudyRightService.getApprovedMinorSelection($ctrl.getSelectedModule(), $ctrl.matchingStudyRight, $ctrl.validatablePlan);
    };
    $ctrl.isMinorSelectionOngoing = function () {
      const endDate = _.get($ctrl.getApprovedMinorSelection(), 'validityPeriod.endDate');
      return _.isNil(endDate) || moment().isBefore(moment(endDate));
    };
    $ctrl.isTeacherAcceptanceRequired = function () {
      return minorStudyRightService.isTeacherAcceptanceRequired($ctrl.getSelectedModule());
    };
    $ctrl.openMinorSelectionModal = function (module) {
      minorSelectionModalService.open(module || $ctrl.getSelectedModule(), $ctrl.matchingStudyRight, $ctrl.validatablePlan).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    };
    $ctrl.shouldShowModuleContentApplicationLink = function () {
      if (getPlan()) {
        const {
          validatablePlan
        } = getPlan();
        const selectedModule = $ctrl.getSelectedModule();
        if (!!validatablePlan && !!selectedModule) {
          return showViewModuleContentApplicationLinkService.shouldShowModuleContentApplicationLink(validatablePlan, selectedModule);
        }
      }
      return false;
    };
    $ctrl.showNotificationNoStudyRight = () => {
      if (!getPlan() || !$ctrl.studyRights) {
        return false;
      }
      return !_.some($ctrl.studyRights, studyRight => {
        const allowedStates = ['ACTIVE', 'ACTIVE_NONATTENDING', 'NOT_STARTED'];
        return studyRight.educationId === $ctrl.educationId && studyRight.documentState === 'ACTIVE' && _.includes(allowedStates, studyRight.state);
      });
    };
    $ctrl.openSelectEmrexAccessPointModal = () => {
      selectEmrexAccessPointModalServiceNg.open().subscribe({
        next: () => {/* Modal was closed */},
        error: () => {/* Modal was closed */}
      });
    };
    $ctrl.showNotificationLearningOpportunityDoesNotMatchStudyRight = () => {
      if (!getPlan() || !$ctrl.studyRights) {
        return false;
      }
      const matchingStudyRight = _.find($ctrl.studyRights, {
        educationId: $ctrl.educationId,
        documentState: 'ACTIVE',
        state: 'ACTIVE'
      });
      if (matchingStudyRight && matchingStudyRight.learningOpportunityId !== $ctrl.learningOpportunityId) {
        return true;
      }
      return false;
    };
    $ctrl.hasPlanStateNotification = () => _.get($ctrl, 'selectionPathInPlan.noDuplicates') === false || $ctrl.isPlanStateInvalid() || $ctrl.planConflictsWithStudyRight || $ctrl.showNotificationLearningOpportunityDoesNotMatchStudyRight() || $ctrl.showNotificationNoStudyRight() || $ctrl.outDatedCourseUnitsInPlan;
    $ctrl.createNewCustomStudyDraft = result => {
      if (_.get(result, 'operation') === 'ADD' && _.get(result, 'customStudyDraft')) {
        const rawPlan = $ctrl.validatablePlan.plan;
        rawPlanEditService.addCustomStudyDraft(result.customStudyDraft, rawPlan);
        updatePlan(rawPlan);
      }
    };
    $ctrl.openCustomStudyDraftInfo = customStudyDraft => {
      const options = {
        customAttainmentApplicationsDisabled: !$ctrl.canApplyForCustomAttainments(),
        priorLearningInclusionApplicationHidden: !uiContext.priorLearningInclusionApplicationAllowed,
        customAttainmentApplicationHidden: !uiContext.customAttainmentApplicationAllowed
      };
      const studentId = _.get($ctrl.validatablePlan, 'plan.userId');
      modalService.open(StudentCustomStudyDraftModalComponent, {
        studentId,
        customStudyDraft,
        options
      }, {
        size: 'sm'
      }).result.then(result => {
        const operation = _.get(result, 'operation');
        const customStudyDraftId = customStudyDraft.id;
        const planId = _.get($ctrl.validatablePlan, 'plan.id');
        const stateParams = {
          customStudyDraftId,
          planId
        };
        if (operation === 'INCLUSION_APPLICATION') {
          $state.go('student.logged-in.profile.applications.create-prior-learning-inclusion-application', stateParams);
        }
        if (operation === 'CUSTOM_ATTAINMENT_APPLICATION') {
          $state.go('student.logged-in.profile.applications.create-custom-attainment-application', stateParams);
        }
      }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    };
    const updatePlan = rawPlan => $ctrl.uiContext.planContext.buildAndUpdate(rawPlan);
    $ctrl.removeCustomStudyDraft = customStudyDraftId => {
      const rawPlan = $ctrl.validatablePlan.plan;
      const customStudyDraft = _.find(rawPlan.customStudyDrafts, {
        id: customStudyDraftId
      });
      if (!customStudyDraft) {
        $log.warn('Could not find customStudyDraft by id: ', customStudyDraftId);
        return;
      }
      const confirmTexts = {
        title: 'PLAN.DELETE_CUSTOM_STUDY_DRAFT_MODAL.TITLE',
        description: 'PLAN.DELETE_CUSTOM_STUDY_DRAFT_MODAL.DESCRIPTION',
        name: customStudyDraft.name
      };
      confirmDialogService.confirm(confirmTexts).then(() => {
        rawPlanEditService.removeCustomStudyDraft(customStudyDraftId, rawPlan);
        updatePlan(rawPlan);
      }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    };
    $ctrl.getCustomStudyDrafts = module => {
      if (!module || !$ctrl.validatablePlan) {
        return null;
      }

      // show here only customStudyDrafts that do not match with possible anyCourseUnitRule

      const planValidationResult = _.get(uiContext, 'planContext.planValidationResult');
      const ruleValidationResults = _.values(_.get(planValidationResult.ruleValidationResults, module.id));
      const matchedCustomStudyDraftIds = _.flatMap(_.compact(_.flatMap(ruleValidationResults, 'selectedCustomStudyDraftsById')), _.keys);
      const customStudyDraftsNotMatchWithRule = _.filter($ctrl.validatablePlan.getSelectedCustomStudyDraftsByParentModuleId(module.id), customStudyDraft => !_.includes(matchedCustomStudyDraftIds, customStudyDraft.id));
      return customStudyDraftsNotMatchWithRule;
    };
    function checkIfPlanIsDeletable() {
      return commonStudyRightService.hasStudyRights(uiContext.planContext.root.id).then(hasStudyRight => {
        if (hasStudyRight) {
          uiContext.planContext.forbidDeletion();
        }
      }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    }
    function returnToStaff() {
      if ($ctrl.returnUrl) {
        $window.location.pathname = $ctrl.returnUrl;
      }
    }
    function loadPreview() {
      $ctrl.uiContext.previewPlanForModule($ctrl.moduleId, $ctrl.curriculumPeriodId).then(() => {
        $ctrl.customCourseUnitAttainmentSelectionsByParent = [];
        $ctrl.customModuleAttainmentSelectionsByParent = [];
        $ctrl.planValidationResult = _.get(uiContext, 'planContext.planValidationResult');
      }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    }
    function getPlanContext() {
      return uiContext.planContext;
    }
    function isRightBarOpen() {
      return uiContext.hasActiveSelection();
    }
    function moveFocusToElement(location) {
      const targetPlanDialog = document.getElementById('plan-right-bar');
      const focusableElements = getKeyboardFocusableElements(targetPlanDialog);
      if (location === 'last') {
        focusableElements[focusableElements.length - 1].focus();
        return;
      }
      if (location === 'first') {
        focusableElements[0].focus();
      }
    }
    function getKeyboardFocusableElements(targetElement) {
      return [...targetElement.querySelectorAll('a[href], button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])')].filter(el => !el.hasAttribute('disabled') && !el.getAttribute('aria-hidden'));
    }
    function openLeftBar() {
      uiContext.closeFreeEdit();
      $ctrl.isLeftBarOpen = true;
      $ctrl.closeRightBar();
    }
    function closeLeftBar() {
      $ctrl.isLeftBarOpen = false;
      uiContext.cancelPlanningOfCourseUnit();
    }
    function getPlan() {
      return uiContext.planContext;
    }
    $ctrl.openTutoring = function () {
      if (uiContext && uiContext.planContext && uiContext.planContext.id && uiContext.planContext.userId) {
        $state.go('student.logged-in.plan.tutoring.messages', {
          planId: uiContext.planContext.id
        });
      }
    };
    function deletePlan() {
      const confirmTexts = {
        title: 'PLAN.DELETE_PLAN_MODAL.TITLE',
        description: 'PLAN.DELETE_PLAN_MODAL.DESCRIPTION',
        name: $ctrl.getPlan().name,
        cancelText: 'PLAN.DELETE_PLAN_MODAL.BUTTON_CANCEL',
        confirmText: 'PLAN.DELETE_PLAN_MODAL.BUTTON_DELETE'
      };
      return confirmDialogService.confirm(confirmTexts).then(() => {
        $ctrl.uiContext.deletePlan().then(() => {
          $state.go('student.logged-in.planSelect');
        });
      }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    }
    $ctrl.editPlanName = () => {
      const planCopy = _.cloneDeep($ctrl.validatablePlan.plan);
      modalService.open(EditPlanNameModalComponent, {
        plan: planCopy
      }, {
        closeWithOutsideClick: false
      }).result.then(plan => {
        commonPlanService.updateMyPlan(plan).then(() => {
          $state.reload();
        }, error => {
          $log.error('Failed to save plan.', error.data);
        }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
      }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    };
    function changeLearningOpportunity() {
      return ChangeLearningOpportunityModal.open(uiContext.planContext.name, uiContext.planContext.root, $ctrl.studyRightMatchingPlanEducation, uiContext.planContext.learningOpportunityId).then(selectedLearningOpportunityId => {
        const jsDataPlan = _.find($ctrl.plans, {
          id: uiContext.planContext.id
        });
        if (jsDataPlan) {
          jsDataPlan.learningOpportunityId = selectedLearningOpportunityId;
        }
        uiContext.planContext.learningOpportunityId = selectedLearningOpportunityId;
        return savePlan().then(() => {
          $state.go('student.logged-in.planSelect');
        }).catch(err => {
          $log.error('failed to save plan after changing learning opportunity', err);
        });
      }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    }
    function copyPlan() {
      return getPlan().toPlan().then(rawPlan => {
        planCopyService.copyPlan(rawPlan).then(plan => {
          $state.go('student.logged-in.structure', {
            planId: plan.id
          });
        }).catch(err => {
          $log.error('plan copy error', err);
        });
      }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    }
    function canLeftBarBeOpened(unplannedCount) {
      return !$ctrl.isLeftBarOpen && unplannedCount && unplannedCount > 0 && uiContext.planContext;
    }
    function isPlanStateInvalid() {
      const state = _.get(uiContext, 'planContext.validationResults.state');
      return state === 'INVALID';
    }
    function initEducations() {
      return educationsWithPlans.findMyPlans($ctrl.plans).then(educations => {
        $ctrl.educations = educations;
        return initCurriculumPeriods();
      }).catch(err => {
        $log.error('failed to init educations', err);
      });
    }
    function initSelectedPlan() {
      if (uiContext.hasActivePlan()) {
        $ctrl.validatablePlan = getPlan().validatablePlan;
        studentPlanOutdatedCourseUnitsService.checkLatestCourseUnitsAreInSelectedPlan($ctrl.validatablePlan).pipe(take(1), appErrorHandler.defaultErrorHandler()).subscribe(result => {
          $scope.$evalAsync(() => {
            $ctrl.outDatedCourseUnitsInPlan = result;
          });
        });
        return uiContext.planContext.toPlan().then(plan => {
          $ctrl.selectedPlan = plan;
          $ctrl.customCourseUnitAttainmentSelectionsByParent = getCustomCourseUnitAttainmentsByParentId();
          $ctrl.customModuleAttainmentSelectionsByParent = getCustomModuleAttainmentsByParentId();
          $ctrl.planValidationResult = _.get(uiContext, 'planContext.planValidationResult');
          if (!$ctrl.isPreview) {
            $ctrl.educationOptions = planStudyRightService.getValidatedEducationOptions($ctrl.validatablePlan, $ctrl.uiContext.planContext.root, $ctrl.matchingStudyRight);
            $ctrl.selectionPathInPlan = planStudyRightService.getSelectionPathInPlan($ctrl.validatablePlan, $ctrl.uiContext.planContext.root);
            $ctrl.planConflictsWithStudyRight = planStudyRightService.planConflictsWithStudyRight($ctrl.educationOptions);
            setRequestedDegreeProgrammeAttainmentApplications().then(() => {
              handleAlerts();
            });
          }
        }).catch(err => {
          $log.error('failed to init selectedPlan', err);
        });
      }
    }
    function savePlan() {
      return uiContext.planContext.toPlan().then(plan => planService.update(plan));
    }
    function initCurriculumPeriods() {
      const curriculumPeriodIds = [];
      _.forEach($ctrl.educations, education => {
        _.forEach(education.plans, plan => {
          curriculumPeriodIds.push(plan.curriculumPeriodId);
        });
      });
      return curriculumPeriodService.findByIds(curriculumPeriodIds).then(curriculumPeriods => {
        $ctrl.planCurriculumPeriods = curriculumPeriods;
      }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    }
    function toggleFreeEditMode() {
      if (uiContext.freeEditMode === false && !$ctrl.isPreview) {
        $ctrl.closeLeftBar();
        const planContext = getPlan();
        $ctrl.validatablePlan = planContext.validatablePlan;
        planContext.toPlan().then(rawPlan => {
          $ctrl.rawPlan = rawPlan;
          uiContext.openFreeEdit();
        }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
      } else {
        uiContext.closeFreeEdit();
      }
    }
    function getCustomCourseUnitAttainmentsByParentId() {
      const courseUnitAttainmentsByParentId = [];
      if (uiContext.planContext) {
        _.forEach(uiContext.planContext.customCourseUnitAttainmentSelections, customCourseUnitAttainmentSelection => {
          if (!courseUnitAttainmentsByParentId[customCourseUnitAttainmentSelection.parentModuleId]) {
            courseUnitAttainmentsByParentId[customCourseUnitAttainmentSelection.parentModuleId] = [];
          }
          courseUnitAttainmentsByParentId[customCourseUnitAttainmentSelection.parentModuleId].push(customCourseUnitAttainmentSelection.customCourseUnitAttainmentId);
        });
      }
      return courseUnitAttainmentsByParentId;
    }
    function getCustomModuleAttainmentsByParentId() {
      const moduleAttainmentsByParentId = [];
      if (uiContext.planContext) {
        _.forEach(uiContext.planContext.customModuleAttainmentSelections, customModuleAttainmentSelection => {
          if (!moduleAttainmentsByParentId[customModuleAttainmentSelection.parentModuleId]) {
            moduleAttainmentsByParentId[customModuleAttainmentSelection.parentModuleId] = [];
          }
          moduleAttainmentsByParentId[customModuleAttainmentSelection.parentModuleId].push(customModuleAttainmentSelection.customModuleAttainmentId);
        });
      }
      return moduleAttainmentsByParentId;
    }
    function handleEmrexResultCode(emrexResultCode) {
      if (emrexResultCode === 'EMP_CANCEL') {
        alertsService.warning($translate.instant('PLAN.EMREX_RESULT_MODAL.CANCELLED'));
      } else {
        emrexResultModalServiceNg.open(emrexResultCode).closed.subscribe({
          next: () => {},
          error: () => {}
        });
      }
      $location.search('emrexResultCode', null);
      $location.replace();
    }
    function getPlanMenuOptions(includeEmrexIntegrationOption) {
      const options = [{
        action: () => $ctrl.editPlanName(),
        name: 'PLAN.RENAME'
      }, {
        action: () => $ctrl.deletePlan(),
        disabled: () => _.get($ctrl.getPlan(), 'deleteNotAllowed', false),
        name: 'DELETE_PLAN'
      }, {
        action: () => $state.go('student.logged-in.plan.print', {
          planId: $ctrl.getPlan().id
        }),
        name: 'PRINT_PLAN'
      }, {
        action: () => $ctrl.markAsPrimary(),
        disabled: () => _.get($ctrl.getPlan(), 'primary', false),
        name: 'MAKE_PRIMARY'
      }, {
        action: () => $ctrl.copyPlan(),
        name: 'PLAN.COPY_NEW'
      }, {
        action: () => $ctrl.showEducationPaths(),
        hide: () => !$ctrl.matchingStudyRight,
        name: 'PLAN.SHOW_SELECTION_PATHS'
      }, {
        action: () => $ctrl.changeLearningOpportunity(),
        disabled: () => _.get($ctrl.getPlan(), 'primary', false),
        name: 'PLAN.CHANGE_LEARNING_OPPORTUNITY'
      }, {
        action: () => $state.go('student.logged-in.profile.applications.create-prior-learning-inclusion-application', {
          planId: $ctrl.getPlan().id
        }),
        disabled: () => !$ctrl.canApplyForCustomAttainments(),
        hide: () => !_.get($ctrl.uiContext, 'priorLearningInclusionApplicationAllowed', true),
        name: 'PLAN.OPEN_PRIOR_LEARNING_INCLUSION_APPLICATION'
      }, {
        action: () => $state.go('student.logged-in.profile.applications.create-custom-attainment-application', {
          planId: $ctrl.getPlan().id
        }),
        disabled: () => !$ctrl.canApplyForCustomAttainments(),
        hide: () => !_.get($ctrl.uiContext, 'customAttainmentApplicationAllowed', true),
        name: 'PLAN.OPEN_CUSTOM_ATTAINMENT_APPLICATION'
      }];
      if (includeEmrexIntegrationOption) {
        options.push({
          action: () => $ctrl.openSelectEmrexAccessPointModal(),
          name: 'PLAN.FETCH_EMREX_ATTAINMENTS.TITLE'
        });
      }
      return options;
    }
  }
})();