import { Component, Input, ViewEncapsulation } from '@angular/core';
import { numberUtils } from 'common-typescript';
import { Attachment, ExternalAttainedStudyAttachments, ExternalAttainedStudyDocument } from 'common-typescript/types';
import { Observable } from 'rxjs';
import { FileItem } from 'sis-components/file-upload/file-upload.component';

/**
 * A simple presentational component for listing attachments added to an application. Similar to ApplicationFilesComponent
 * in sis-components, with the difference that this component is meant to be used while creating the application, before
 * anything has been saved to the database, while the latter component only works for files that exist in the database.
 */
@Component({
    selector: 'app-attachments-preview',
    templateUrl: './attachments-preview.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class AttachmentsPreviewComponent {

    @Input() attachments: FileItem[];
    @Input() externalAttainedStudyIds$: Observable<string[]>;

    currentExternalAttachments: ExternalAttainedStudyAttachments[] = [];

    readableFileSizeString = numberUtils.readableFileSizeString;

    getAttachments(document: ExternalAttainedStudyDocument): Attachment[] {
        return Object.values(document.attachments);
    }

    onExternalAttachmentsChange($event: ExternalAttainedStudyAttachments[]) {
        this.currentExternalAttachments = $event;
    }
}
