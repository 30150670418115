import { Pipe, PipeTransform } from '@angular/core';
import { Attainment, LocalizedString } from 'common-typescript/types';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AttainmentEntityService } from '../service/attainment-entity.service';

@Pipe({ name: 'attainmentName' })
export class AttainmentNamePipe implements PipeTransform {

    constructor(
        private attainmentEntityService: AttainmentEntityService,
    ) {
    }

    transform(attainment: Attainment): Observable<LocalizedString> {
        return this.attainmentEntityService.getAttainmentName(attainment)
            .pipe(catchError(() => of(null)));
    }
}
