<sis-description-list-compact [columnCount]="1">
  <dl>
    <ng-template #compactListItem>
      <dt data-cy="course-unit-info-modal-placement" class="label colon">{{'COURSE_UNIT_INFO_MODAL.PLACED' | translate}}</dt>
      <dd *ngIf="planLocation; else notPlaced" class="value">
        {{ planLocation | localizedString }}
      </dd>
      <ng-template #notPlaced>
        <dd class="value">
          {{'COURSE_UNIT_INFO_MODAL.NOT_PLACED' | translate}}
        </dd>
      </ng-template>
    </ng-template>

    <ng-template #compactListItem>
      <dt data-cy="course-unit-info-modal-status" class="label colon">{{'COURSE_UNIT_INFO_MODAL.STATUS' | translate}}</dt>
      <dd *ngIf="courseUnitAttainment; else notAttained" class="value">
        {{ courseUnitAttainment.state | attainmentStatusTranslation | translate }}
        <span *ngIf="courseUnitAttainment.credits">({{courseUnitAttainment.credits | decimalNumber}} {{'CREDITS' | translate}})</span>
      </dd>
      <ng-template #notAttained>
        <dd class="value">
          {{'COURSE_UNIT_INFO_MODAL.NOT_ATTAINED' | translate}}
        </dd>
      </ng-template>
    </ng-template>

    <ng-template #compactListItem>
      <dt data-cy="course-unit-info-modal-grade" class="label colon">{{'FIELD_NAMES.grade' | translate}}</dt>
      <dd *ngIf="courseUnitAttainment; else notGraded" class="value">
        {{ courseUnitAttainment.gradeId | grade: courseUnitAttainment.gradeScaleId : 'NAME' | async }}
      </dd>
      <ng-template #notGraded>
        <dd class="value">
          {{'COURSE_UNIT_INFO_MODAL.NOT_GRADED' | translate}}
        </dd>
      </ng-template>
    </ng-template>
  </dl>
</sis-description-list-compact>
