<ng-container *transloco="let t">
  <ng-container *ngIf="thesisObs$ | async as thesis">
    <ng-container *ngIf="thesis && thesis.length > 0">
      <h3>{{ t('ATTAINMENTS.ATTAINMENT_DETAILS.HEADINGS.THESIS_DETAILS') }}</h3>
      <dl>
        <div class="form-group-mimic" role="listitem">
          <dt>{{ t('ATTAINMENTS.ATTAINMENT_DETAILS.THESIS_NAME') }}</dt>
          <dd>{{ (thesis[0]?.title | localizedString) ?? '-' }}</dd>
        </div>
        <div class="form-group-mimic" role="listitem">
          <dt>{{ t('ATTAINMENTS.ATTAINMENT_DETAILS.THESIS_TYPE') }}</dt>
          <dd>{{ (thesis[0]?.thesisTypeUrn | codeLocalizedName | async) ?? '-' }}</dd>
        </div>
        <div class="form-group-mimic" role="listitem">
          <dt>{{ t('ATTAINMENTS.ATTAINMENT_DETAILS.THESIS_SUBJECT') }}</dt>
          <dd>{{ (thesis[0]?.subject | localizedString) ?? '-' }}</dd>
        </div>
        <div class="form-group-mimic" role="listitem">
          <dt>{{ t('ATTAINMENTS.ATTAINMENT_DETAILS.THESIS_PUBLIC_INSPECTION_DATE') }}</dt>
          <dd>{{ (thesis[0]?.publicInspectionDate | localDateFormat) ? (thesis[0]?.publicInspectionDate | localDateFormat) : '-' }}</dd>
        </div>
        <div class="form-group-mimic" role="listitem">
          <dt>{{ t('ATTAINMENTS.ATTAINMENT_DETAILS.THESIS_RESPONSIBILITY_PERSONS') }}</dt>
          <dd *ngIf="thesis[0].responsibilityInfos; else emptyField">
            <sis-responsibility-info *ngFor="let responsibilityInfo of thesis[0].responsibilityInfos"
                                     [responsibilityInfo]="responsibilityInfo">
            </sis-responsibility-info>
          </dd>
        </div>
        <div class="form-group-mimic" role="listitem">
          <dt>{{ t('ATTAINMENTS.ATTAINMENT_DETAILS.THESIS_RESPONSIBLE_ORGANISATIONS') }}</dt>
          <dd *ngIf="thesis[0].organisations | hasOrganisationRole:'urn:code:organisation-role:responsible-organisation'; else emptyField">
            <sis-organisation-role-share
              [hideLabel]="true"
              [organisations]="thesis[0].organisations"
              [organisationRoleUrn]="'urn:code:organisation-role:responsible-organisation'">
            </sis-organisation-role-share>
          </dd>
        </div>
      </dl>
      <ng-template #emptyField>
        <dd> - </dd>
      </ng-template>
    </ng-container>
  </ng-container>
</ng-container>
