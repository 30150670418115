import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import angular from 'angular';
import {
    Organisation,
    SearchFilterChangeEvent,
    SearchParameterOption,
    UniversityOrganisation,
} from 'common-typescript/types';
import _ from 'lodash';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

interface MenuItemGroup {
    titleName: string;
    parameterKeys: string[];
}

/**
 * @Deprecated Use components under webapp/lib/search-ng instead.
 */
@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-search-filter-options-menu',
    templateUrl: './search-filter-options-menu.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class SearchFilterOptionsMenuComponent implements OnInit, OnChanges {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.search.searchFilterOptionsMenu.downgraded',
        directiveName: 'sisSearchFilterOptionsMenu',
    };

    /**
     * Can be used to group the searchParameterOptions into groups with their own sub-headers. The keys are the names of
     * the groups, and the values are the translation keys for the group headers. The grouping is made by matching the
     * group names with the 'title' property in the searchParameterOptions objects.
     */
    @Input() searchParameterTitles: { [title: string]: string } = {};
    /** The search parameters to show in the dropdown menu. */
    @Input() searchParameterOptions: { [key: string]: SearchParameterOption } = {};
    /** Controls the state of the button that opens the menu. */
    @Input() disable = false;
    /** An instance of the AngularJS SearchParameters object. */
    @Input() searchParams: any;
    /** Used only to search organisations, educations and modules under specific university. Unnecessary otherwise. */
    @Input() university: Organisation | UniversityOrganisation;
    /** Translation key of the button that opens the menu, defaults to SEARCH.FILTER_TAGS.FILTER */
    @Input() mainButtonKey: string;
    /**
     * The $scope instance of the containing AngularJS component. Required when this component is used in an AngularJS
     * component; irrelevant otherwise. Without this the AngularJS change detection will not pick up the changes made
     * in this component.
     */
    @Input() scope: angular.IScope;

    /** Emits changes to the values of search filters (excluding the selection modals; see searchAgain). */
    @Output() selected = new EventEmitter<SearchFilterChangeEvent>();
    /**
     * Emits empty events when search filters are changed using a separate modal (e.g. the hierarchical organisation and
     * education selectors). The values selected in the modal are updated directly to the searchParams object, and can be
     * read from there in response to this event.
     */
    @Output() searchAgain = new EventEmitter<void>();

    menuItemGroups: MenuItemGroup[] = [];

    @ViewChild('dropdown', { static: true }) dropdown: NgbDropdown;

    constructor(private translate: TranslateService) {}

    ngOnInit() {
        this.setSearchParameterOptions();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.setSearchParameterOptions();
    }

    onSelect(parameterKey: string, values: any): void {
        this.dropdown.close();
        if (this.scope) {
            setTimeout(() => this.scope.$apply(() => this.selected.emit({ parameterKey, values })));
        } else {
            this.selected.emit({ parameterKey, values });
        }
    }

    onSearchAgain(): void {
        this.dropdown.close();
        if (this.scope) {
            setTimeout(() => this.scope.$apply(() => this.searchAgain.emit()));
        } else {
            this.searchAgain.emit();
        }
    }

    setSearchParameterOptions() {
        const menuTitleNamesByKey = Object.assign({ default: undefined }, this.searchParameterTitles);
        const menuItemGroupsByTitle: { [title: string]: MenuItemGroup } = Object.entries(menuTitleNamesByKey)
            .reduce((acc, [title, titleName]) => ({ ...acc, [title]: { titleName, parameterKeys: [] } }), {});
        Object.entries(this.searchParameterOptions)
            .filter(([, option]) => !option.hide)
            .forEach(([key, option]) => {
                if (option.title && menuItemGroupsByTitle.hasOwnProperty(option.title)) {
                    menuItemGroupsByTitle[option.title].parameterKeys.push(key);
                } else {
                    menuItemGroupsByTitle.default.parameterKeys.push(key);
                }
            });

        Object.keys(menuItemGroupsByTitle).forEach(key => {
            menuItemGroupsByTitle[key].parameterKeys = _.sortBy(menuItemGroupsByTitle[key].parameterKeys, (pk) => this.translate.instant(`SEARCH.FILTER_TAGS.${this.searchParameterOptions[pk].name.toUpperCase()}`));
        });

        this.menuItemGroups = Object.values(menuItemGroupsByTitle)
            .filter(group => group.parameterKeys.length > 0);
    }

    /** Implementation to keyboard navigation */
    onKeyboardButtonInteraction(event: KeyboardEvent) {
        if (this.dropdown.isOpen() && event.code !== 'Tab') {
            const listItems = this.getDropdownItems() as HTMLInputElement[];
            if (listItems.length > 0) { listItems[0].focus(); }
        }
    }

    onKeyboardMenuInteraction(event: KeyboardEvent) {
        const eventElement = event.target as HTMLInputElement;

        switch (event.code) {
            case 'Escape': {
                const activeListItem = eventElement.closest('search-filter-menu-item') as HTMLInputElement;

                if (activeListItem.querySelector('ngb-popover-window')) {
                    activeListItem.querySelector('button').click();
                    activeListItem.querySelector('button').focus();
                } else {
                    this.dropdown.close();
                }
            } break;
            case 'Enter':
                eventElement.click();
                break;
            case 'ArrowDown': {
                const listItems = this.getDropdownItems(eventElement);
                const index = listItems.indexOf(eventElement);
                if (listItems.length === 0 && index < 0) { return; }
                const current = listItems[index + 1] || listItems[0];
                current.focus();
            } break;
            case 'ArrowUp': {
                const listItems = this.getDropdownItems(eventElement);
                const index = listItems.indexOf(eventElement);
                if (listItems.length === 0 && index < 0) { return; }
                const current = listItems[index - 1] || listItems[listItems.length - 1];
                current.focus();
            } break;
        }
    }

    getDropdownItems(eventElement?: HTMLInputElement) {
        const dropdownMenu = document.getElementById('search-filter-options-dropdown-menu');
        let target;
        if (eventElement) {
            target = eventElement.className
                ? `${eventElement.tagName.toLowerCase()}.${eventElement.className.replace(' ', '.')}`
                : eventElement.tagName;
        } else {
            target = 'button';
        }
        const listItems = dropdownMenu.querySelectorAll(target);
        return Array.prototype.filter.call(listItems, (item: any) => item.tabIndex >= '0');
    }
}
