import { Component, Inject, inject, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CustomStudyDraft } from 'common-typescript/types';
import { ModalService } from 'sis-common/modal/modal.service';

export interface CustomStudyDraftInfoValues {
    customStudyDraft: CustomStudyDraft;
}

export function customStudyDraftInfoModalOpener(): (customStudyDraft: CustomStudyDraft) => NgbModalRef {
    const modalService = inject(ModalService);
    return customStudyDraft => modalService.open(CustomStudyDraftInfoModalComponent, { customStudyDraft }, { size: 'sm' });
}

@Component({
    selector: 'sis-custom-study-draft-info-modal',
    template: '<custom-study-draft-info-modal (close)="activeModal.close()" [resolve]="values"></custom-study-draft-info-modal>',
    encapsulation: ViewEncapsulation.None,
})
export class CustomStudyDraftInfoModalComponent {
    constructor(@Inject(ModalService.injectionToken) public values: CustomStudyDraftInfoValues,
                public activeModal: NgbActiveModal) {}
}
