<div class="modal-header">
  <button class="modal-close"
          (click)="close()"
          type="button"
          [attr.aria-label]="'ARIA_LABEL.CLOSE' | translate">
    <sis-icon icon="ring-close"></sis-icon>
  </button>
  <h2 class="modal-title" translate>PROFILE.STUDY_PATH.PERSONALIZED_SELECTION_RATIONALE</h2>
</div>
<div class="modal-body">
  <div class="form-group-mimic" *ngIf="phase1">
    <h4 translate *ngIf="phase1.name">{{phase1.name|localizedString}}</h4>
    <p *ngIf="phase1.description">{{phase1.description}}</p>
  </div>
  <div class="form-group-mimic" *ngIf="phase2">
    <h4 translate *ngIf="phase2.name">{{phase2.name|localizedString}}</h4>
    <p *ngIf="phase2.description">{{phase2.description}}</p>
  </div>
</div>
