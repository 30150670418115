<ng-container *transloco="let t">
  <fudis-dialog [size]="'sm'">
    <fudis-dialog-content>
      <fudis-form [titleVariant]="'lg'"
                  [title]="t('STUDY_PLAN_CREATE_NEW_LONG') "
                  [level]="2"
                  [errorSummaryHelpText]="t('PLAN.CREATE_PLAN_MODAL.ERROR_SUMMARY')">
        <ng-template fudisContent [type]="'form'">
          <fudis-heading [level]="3" [variant]="'sm'">
            {{ t('PLAN.CREATE_PLAN_MODAL.TABS.OWN_EDUCATIONS') }}
          </fudis-heading>
          <fudis-grid [rowGap]="'md'" [marginBottom]="'md'">
            @if (data$ | async; as data) {
              <fudis-select [control]="form.controls.education"
                            [selectionClearButton]="false"
                            [placeholder]="t('SIS_COMPONENTS.SELECT.CHOOSE')"
                            [label]="t('PLAN.CREATE_PLAN_MODAL.FIELDS.EDUCATION')">
                <ng-template fudisContent type="select-options">
                  @for (option of data.educationOptions; track option.value.id) {
                    <fudis-select-option [data]="option"/>
                  }
                </ng-template>
              </fudis-select>
              <fudis-select [label]="t('PLAN.CREATE_PLAN_MODAL.FIELDS.CURRICULUM_PERIOD')"
                            [placeholder]="t('SIS_COMPONENTS.SELECT.CHOOSE')"
                            [selectionClearButton]="false"
                            [disabled]="!form.controls.education.value"
                            [control]="form.controls.curriculumPeriod">
                <ng-template fudisContent type="select-options">
                  @for (option of data.curriculumPeriodOptions; track option.value.id) {
                    <fudis-select-option [data]="option"/>
                  }
                </ng-template>
              </fudis-select>
              <fudis-text-input [label]="t('PLAN.CREATE_PLAN_MODAL.FIELDS.NAME')"
                                [disabled]="!form.controls.education.value"
                                [control]="form.controls.name">
              </fudis-text-input>
            }
          </fudis-grid>
        </ng-template>
        <ng-template fudisActions [type]="'form'">
          <fudis-button fudisDialogClose [label]="t('SIS_COMPONENTS.BUTTON.CANCEL')" [variant]="'secondary'"/>
          <fudis-button fudisFormSubmit [formValid]="form.valid" [label]="t('PLAN.CREATE_PLAN_MODAL.SUBMIT_BUTTON')"
                        (handleClick)="submitSubject$.next()"/>
        </ng-template>
      </fudis-form>
    </fudis-dialog-content>
  </fudis-dialog>
</ng-container>
