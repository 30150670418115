import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'sis-loading-screen',
    templateUrl: './loading-screen.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class LoadingScreenComponent {

    @Input() descriptionKey1?: string;
    @Input() descriptionKey2?: string;
}
