import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { Attainment, DegreeProgrammeAttainment, OtmId } from 'common-typescript/types';
import _ from 'lodash';
import moment from 'moment';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { isDegreeProgrammeAttainment } from '../../attainment/AttainmentUtil';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-attained-degrees',
    templateUrl: './attained-degrees.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class AttainedDegreesComponent implements OnChanges {
    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.studyRight.attainedDegreesNg',
        directiveName: 'sisAttainedDegrees',
    };

    /**
     * Assuming attainments are valid.
     */
    @Input() attainments: Attainment[] = [];
    @Input() studyRightId: OtmId;

    degreeProgrammeAttainments: DegreeProgrammeAttainment[] = [];

    ngOnChanges(): void {
        const now = moment();
        const degreeProgrammeAttainments: DegreeProgrammeAttainment[] = (this.attainments || [])
            .filter(att => isDegreeProgrammeAttainment(att))
            .map(att => att as DegreeProgrammeAttainment)
            .filter(att => this.studyRightId === att.studyRightId)
            .filter(att => moment(att.attainmentDate).isSameOrBefore(now));
        this.degreeProgrammeAttainments = _.orderBy(degreeProgrammeAttainments, 'attainmentDate');
    }
}
