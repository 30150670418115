<sis-description-list>
  <dl>
    <ng-template #listItem>
      <dt class="sis-label">{{'PROFILE.FIRST_NAMES' | translate}}</dt>
      <dd class="notranslate">{{ person.firstNames }}</dd>
    </ng-template>
    <ng-template #listItem>
      <dt class="sis-label">
        {{'FIELD_NAMES.personalIdentityCode' | translate}}
        <sis-classified-info-toggle [visible]="isPersonalIdentityCodeVisible"
                                    [ariaLabel]="(isPersonalIdentityCodeVisible ? 'ARIA_LABEL.HIDE_PERSONAL_ID_NUMBER' : 'ARIA_LABEL.SHOW_PERSONAL_ID_NUMBER') | translate"
                                    (toggle)="isPersonalIdentityCodeVisible = !isPersonalIdentityCodeVisible">
        </sis-classified-info-toggle>
      </dt>
      <dd>
        <span *ngIf="!isPersonalIdentityCodeVisible" aria-hidden="true">&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;</span>
        <span *ngIf="isPersonalIdentityCodeVisible" class="notranslate">{{ person.personalIdentityCode }}</span>
      </dd>
    </ng-template>
    <ng-template #listItem>
      <dt class="sis-label">{{'PROFILE.LAST_NAME' | translate}}</dt>
      <dd class="notranslate">{{ person.lastName }}</dd>
    </ng-template>
    <ng-template #listItem>
      <dt class="sis-label">{{'FIELD_NAMES.callName' | translate}}</dt>
      <dd class="notranslate">{{ person.callName }}</dd>
    </ng-template>
  </dl>
</sis-description-list>
