<div class="study-sub-group-container"
     [ngClass]="[isSelected.value || (isMandatory && !studySubGroup.cancelled && allocation.hasSpace) ? 'container__selected' : 'container__not-selected']">
  <div class="study-sub-group-container__header"
       [ngClass]="{'header__selected': isSelected.value || (isMandatory && !studySubGroup.cancelled && allocation.hasSpace), 'header__cancelled': studySubGroup.cancelled && !allocation.hasSpace, 'header__mandatory': isMandatory && !studySubGroup.cancelled && allocation.hasSpace}">
    <ng-container *ngTemplateOutlet="control.disabled ? view : select"></ng-container>
  </div>
  <div class="study-sub-group-container__body p-4" data-cy="ssg-container-body">
    <app-study-sub-group-info [ssgSize]="studySubGroup.size"
                              [teacherIds]="studySubGroup.teacherIds ? studySubGroup.teacherIds : []"
                              [studyEventIds]="studySubGroup.studyEventIds ? studySubGroup.studyEventIds : []">
    </app-study-sub-group-info>
  </div>
</div>

<ng-template #view>
  <div class="study-sub-group-container__header__container" data-cy="ssg-container-header">
    <div class="study-sub-group-container__header__container__title">
      {{ studySubGroup.name | localizedString }}
      <sis-tiny-badge *ngIf="studySubGroup.cancelled"
                      variant="danger">{{ 'ENROLMENTS.ENROLMENT_MODAL.STUDY_GROUP.EVENTS_CANCELLED' | translate }}</sis-tiny-badge>
      <sis-tiny-badge *ngIf="!allocation.hasSpace"
                      variant="danger">{{ 'ENROLMENTS.ENROLMENT_MODAL.STUDY_GROUP.FULL' | translate }}</sis-tiny-badge>
      <sis-tiny-badge *ngIf="isInCalendar"
                      variant="primary">{{ 'ENROLMENTS.ENROLMENT_MODAL.STUDY_GROUP.IN_CALENDAR' | translate }}</sis-tiny-badge>
    </div>
  </div>
</ng-template>
<ng-template #select>
  <div class="study-sub-group-container__header__container">
    <div>
      <div class="d-flex flex-wrap">
        <div [ngClass]="{ 'study-sub-group-container__header__container__checkbox': isInCalendar }">
          <sis-form-checkbox [label]="studySubGroup.name | localizedString"
                             [id]="studySubGroup.id"
                             [control]="isSelected"
                             [name]="'ssg-primary-checkbox'"
                             data-cy="ssg-primary-checkbox"
                             class="study-sub-group-container__header__container__title">
          </sis-form-checkbox>
        </div>
        <sis-tiny-badge *ngIf="isInCalendar" variant="primary">{{ 'ENROLMENTS.ENROLMENT_MODAL.STUDY_GROUP.IN_CALENDAR' | translate }}</sis-tiny-badge>
      </div>
      <sis-form-checkbox *ngIf="!isConfirmedSsgEdit"
                         [label]="'ENROLMENTS.ENROLMENT_MODAL.STUDY_GROUP.PRIMARY' | translate"
                         [name]="'ssg-secondary-checkbox'"
                         [control]="isPrimary"
                         class="study-sub-group-container__header__secondary-select">
      </sis-form-checkbox>
    </div>
  </div>
</ng-template>

