<ng-container *transloco="let t">
  <h3>{{ t('ATTAINMENTS.ATTAINMENT_DETAILS.HEADINGS.CREDIT_TRANSFER_ACCEPTANCE_DETAILS') }}</h3>
  <dl>
    <div class="form-group-mimic" role="listitem">
      <dt>{{ t('ATTAINMENTS.ATTAINMENT_DETAILS.CREDIT_TRANSFER_ACCEPTANCE_DATE') }}</dt>
      <dd>{{ (creditTransferInfo?.creditTransferDate | localDateFormat) ?? '-' }}</dd>
    </div>
    <div class="form-group-mimic" role="listitem"
         [class.break-grid-row]="this.showInternationalInstitutionOrganisation">
      <dt>{{ t('ATTAINMENTS.ATTAINMENT_DETAILS.CREDIT_TRANSFER_ORIGINAL_EDUCATION_INSTITUTION') }}</dt>
      <dd>{{ (creditTransferInfo?.educationalInstitutionUrn | codeLocalizedName | async) ?? '-'}}</dd>
    </div>
    <ng-container *ngIf="this.showInternationalInstitutionOrganisation">
      <div class="form-group-mimic" role="listitem">
        <dt>{{ t('ATTAINMENTS.ATTAINMENT_DETAILS.CREDIT_TRANSFER_INTERNATIONAL_INSTITUTION') }}</dt>
        <dd *ngIf="creditTransferInfo.internationalInstitutionUrn">
          {{ creditTransferInfo.internationalInstitutionUrn | codeLocalizedName | async}}
        </dd>
        <dd *ngIf="creditTransferInfo.organisation">{{creditTransferInfo.organisation}}</dd>
      </div>
    </ng-container>
  </dl>
</ng-container>
