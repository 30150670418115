'use strict';

(function () {
  attainmentTreeUtil.$inject = ["$compile", "$rootScope"];
  angular.module('sis-components.attainment.tree').factory('attainmentTreeUtil', attainmentTreeUtil);

  /** @ngInject */
  function attainmentTreeUtil($compile, $rootScope) {
    return {
      getDirective: function (scope) {
        var newScope = $rootScope.$new(true);
        newScope.row = scope.row;
        newScope.onAttainmentClick = scope.onAttainmentClick;
        newScope.attainmentsDisplayed = scope.attainmentsDisplayed;
        newScope.showMenuButton = scope.showMenuButton;
        newScope.attainmentEditActionSelected = scope.attainmentEditActionSelected;
        if (scope.row.type === 'parent') {
          return $compile('<attainment-tree-parent on-attainment-click="onAttainmentClick({row: row})" row="row" attainments-displayed="attainmentsDisplayed" attainment-edit-action-selected="attainmentEditActionSelected({event})" show-menu-button="showMenuButton"></attainment-tree-parent>')(newScope);
        } else if (scope.row.type === 'child') {
          return $compile('<attainment-tree-child on-attainment-click="onAttainmentClick({row: row})" row="row" attainments-displayed="attainmentsDisplayed" attainment-edit-action-selected="attainmentEditActionSelected({event})" show-menu-button="showMenuButton"></attainment-tree-child>')(newScope);
        }
      }
    };
  }
})();