import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import angular from 'angular';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

/**
 * A popover component based on NG Bootstrap's <a href="https://ng-bootstrap.github.io/#/components/popover/examples">Popover</a>.
 *
 * A popover has two parts: the trigger and the actual popover. Both can be set with a simple string with the {@link triggerText} and
 * {@link popoverText} parameters, or with more complicated structures as `<sis-popover>` children with the classnames
 * `sis-popover-trigger-content` and `sis-popover-popover-content` respectively. An example:
 *
 * <pre>
 *   <sis-popover>
 *     <sis-icon icon="info-circle-fill" class="sis-popover-trigger-content clickable"></sis-icon>
 *     <div class="sis-popover-popover-content study-status-sub-graph">
 *       <study-progress-graph-sub-module-element ng-repeat="result in $ctrl.results.subModuleResults"
 *                                                result="result.planned"
 *                                                type="'sub'"
 *       ></study-progress-graph-sub-module-element>
 *     </div>
 *   </sis-popover>
 * </pre>
 */
@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-popover',
    templateUrl: './popover.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class PopoverComponent implements OnChanges {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sisComponents.popover.popover',
        directiveName: 'sisPopover',
    };

    /**
     * AngularJS scope to apply show and hidden function calls
     */
    @Input() scope: angular.IScope;

    /**
     * Whether the popover should be disabled.
     */
    @Input() disablePopover?: boolean;

    /**
     * An event emitted when the popover is hidden.
     */
    @Output() hidden = new EventEmitter<void>();

    /**
     * A boolean flag that controls when the popover is shown or hidden.
     */
    @Input() isOpen?: boolean;

    /**
     * An optional classname applied to the popover window element.
     */
    @Input() popoverClass = '';

    /**
     * The preferred placement of the popover.
     */
    @Input() popoverPlacement = 'auto';

    /**
     * Text to show in the popover content body.
     */
    @Input() popoverText?: string;

    /**
     * Specifies events that should trigger the tooltip.
     */
    @Input() popoverTriggers = 'click';

    /**
     * Whether to show a close button inside the popover content body.
     */
    @Input() showCloseButton = true;

    /**
     * Indicates whether the popover should be closed on Escape key and inside/outside clicks:
     * true - closes on both outside and inside clicks as well as Escape presses
     * false - disables the autoClose feature (NB: triggers still apply)
     * "inside" - closes on inside clicks as well as Escape presses
     * "outside" - closes on outside clicks (sometimes also achievable through triggers) as well as Escape presses
     */
    @Input() autoClose: boolean | 'inside' | 'outside' = 'outside';

    /**
     * An event emitted when the popover is shown.
     */
    @Output() shown = new EventEmitter<void>();

    /**
     * Text to show in the popover trigger part.
     */
    @Input() triggerText?: string;

    /**
     * Whether the trigger text should be underlined.
     */
    @Input() underlineTriggerText = true;

    /**
     * Whether the popover is also a dropdown item (gets right styles).
     */
    @Input() dropdownItem = false;

    @ViewChild(NgbPopover) popover: NgbPopover;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.isOpen && !changes.isOpen.isFirstChange()) {
            if (changes.isOpen.currentValue) {
                this.popover.open();
            } else {
                this.popover.close();
            }
        }
    }

    isShown() {
        if (this.scope) {
            this.scope.$apply(() => this.shown.emit());
        } else {
            this.shown.emit();
        }
    }

    isHidden() {
        if (this.scope) {
            this.scope.$apply(() => this.hidden.emit());
        } else {
            this.hidden.emit();
        }
    }
}
