import { commonPlanServiceModule } from 'sis-components/service/plan.service';
(function () {
  planCompletionMethodService.$inject = ["$q", "$log", "commonPlanService"];
  angular.module('student.common.service.planCompletionMethodService', [commonPlanServiceModule]).factory('planCompletionMethodService', planCompletionMethodService);
  function planCompletionMethodService($q, $log, commonPlanService) {
    var api = {
      isCompletionMethodSelected: function (courseUnitId, completionMethod, plan) {
        if (plan) {
          var courseUnitSelection = plan.getCourseUnitSelection(courseUnitId);
          return courseUnitSelection && completionMethod && courseUnitSelection.completionMethodId === completionMethod.localId;
        }
      },
      selectCompletionMethod: function (courseUnitId, completionMethod, plan, unplannedCourseUnit, module) {
        var courseUnitSelection = plan.getCourseUnitSelection(courseUnitId);
        if (!courseUnitSelection && unplannedCourseUnit && module) {
          plan.courseUnitSelections.push({
            courseUnitId: unplannedCourseUnit.id,
            parentModuleId: module.id
          });
          courseUnitSelection = plan.getCourseUnitSelection(courseUnitId);
        }
        if (!courseUnitSelection) {
          $log.warn('Trying to select completionMethod for courseUnit that is not selected to plan', courseUnitId, completionMethod, plan);
          return $q.reject();
        } else {
          // remove old assessmentItemSelections for courseUnit
          _.remove(plan.assessmentItemSelections, {
            courseUnitId: courseUnitId
          });
          courseUnitSelection.completionMethodId = completionMethod.localId;
          if (completionMethod.typeOfRequire === 'ALL_SELECTED_REQUIRED') {
            if (!plan.assessmentItemSelections) {
              plan.assessmentItemSelections = [];
            }
            _.forEach(completionMethod.assessmentItemIds, function (assessmentItemId) {
              plan.assessmentItemSelections.push({
                assessmentItemId: assessmentItemId,
                courseUnitId: courseUnitId
              });
            });
          }
          return commonPlanService.saveMyPlan(plan);
        }
      }
    };
    return api;
  }
})();