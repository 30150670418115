"use strict";

/**
 * Our custom frontend implementation of @EndDateRequired validation annotation.
 */
(function () {
  angular.module('sis-common.form').factory('endDateRequiredValidator', endDateRequiredValidator);

  /**
   * @ngInject
   */
  function endDateRequiredValidator() {
    return {
      name: 'fi.helsinki.otm.common.validation.EndDateRequired',
      /**
       * Checks that the endDate of dateRange is not falsey, an empty object, collection, map, or set or string.
       *
       * @param value the dateRange to validate
       * @returns {boolean} true if the endDate of the dateRange is not empty
       */
      validate: function (dateRange) {
        return !_.isEmpty(_.get(dateRange, 'endDate'));
      }
    };
  }
})();