import { Component, Input, ViewEncapsulation } from '@angular/core';
import { AssessmentItem, OpenUniversityProduct } from 'common-typescript/types';
import { Observable } from 'rxjs';
import { AssessmentItemEntityService } from 'sis-components/service/assessment-item-entity.service';
import { trackByEntityId } from 'sis-components/util/utils';

@Component({
    selector: 'app-carousel-product-info',
    templateUrl: './carousel-product-info.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CarouselProductInfoComponent {

    @Input() set product(product: OpenUniversityProduct) {
        this._product = product;
        this.assessmentItems$ = this.assessmentItemService.findForOpenUniversityProduct(product);
    }

    get product() {
        return this._product;
    }

    assessmentItems$: Observable<AssessmentItem[]>;
    private _product: OpenUniversityProduct;

    readonly entityId = trackByEntityId;

    constructor(
        private assessmentItemService: AssessmentItemEntityService,
    ) {}
}
