<div [class.courseunit-substitution-group]="substitution.length > 1" class="prior-learning-expandable">
  <ng-container *ngFor="let courseUnitSubstitution of substitution">
    <div *ngIf="openCourseUnitModalEvent.observers.length > 0; then linkCourseUnitBox else nonLinkCourseUnitBox"></div>
    <ng-template #linkCourseUnitBox>
      <sis-course-unit-box [courseUnitGroupId]="courseUnitSubstitution.courseUnitGroupId"
                           [categoryCssClass]="'cu-color-' + (colorIndex + 1)"
                           (codeClickAction)="openCourseUnitModalEvent.emit($event)">
        <div course-unit-box="notifications" *ngIf="courseUnitSubstitution.credits" class="cu-notification-bar">
          <div class="cu-notification">
          <span class="cu-notification-header">
            <sis-icon icon="info"></sis-icon>
          </span>
            <span class="cu-notification-content" translate="STUDIES.SUBSTITUTION_EDITOR.SUBSTITUTED_CREDITS"
                  [translateParams]="{credits: courseUnitSubstitution.credits}">
          </span>
          </div>
        </div>
      </sis-course-unit-box>
    </ng-template>
    <ng-template #nonLinkCourseUnitBox>
      <sis-course-unit-box [courseUnitGroupId]="courseUnitSubstitution.courseUnitGroupId"
                           [categoryCssClass]="'cu-color-' + (colorIndex + 1)">
        <div course-unit-box="notifications" *ngIf="courseUnitSubstitution.credits" class="cu-notification-bar">
          <div class="cu-notification">
          <span class="cu-notification-header">
            <sis-icon icon="info"></sis-icon>
          </span>
            <span class="cu-notification-content" translate="STUDIES.SUBSTITUTION_EDITOR.SUBSTITUTED_CREDITS"
                  [translateParams]="{credits: courseUnitSubstitution.credits}">
          </span>
          </div>
        </div>
      </sis-course-unit-box>
    </ng-template>
  </ng-container>
</div>
