<ng-container *transloco="let t">
  <div class="app-student-attainments">
    <fudis-section [title]="t('PROFILE.ATTAINMENTS')"
                   [marginTop]="'lg'"
                   [titleVariant]="'xl'"
                   [level]="2">
      <ng-template fudisActions [type]="'section'">
        <fudis-button *ngIf="transcriptPrintingEnabledByUniversity$ | async"
                      [label]="t('PROFILE.ATTAINMENT.BTN_PRINT')"
                      [variant]="'secondary'"
                      [size]="'small'"
                      (handleClick)="printAttainments()"
        />
      </ng-template>
      <ng-template fudisContent [type]="'section'">
        <div class="col-12 col-md-5 col-lg-3">
          <label class="form-label">{{ t('PROFILE.ATTAINMENT.ATTAINMENT_STATE') }}</label>
          <sis-dropdown-select [options]="attainmentOptions$ | async"
                               [selection]="selectedDisplay"
                               (selectionChange)="onDropdownSelectionChange($event)"
          >
          </sis-dropdown-select>
        </div>
        <ng-container *ngIf="selectedAttainmentNodes$ | async as attainmentNodes; else loading">
          <fudis-section [title]="t('PROFILE.ATTAINMENT.COMPILED_' + selectedDisplay)"
                         [marginTop]="'lg'"
                         [level]="3"
                         data-cy="student-attainments-compiled-section">
            <ng-template fudisContent [type]="'section'">
              <fudis-section [title]="t('PROFILE.ATTAINMENT.DEGREE_PROGRAMMES')"
                             [marginTop]="'md'"
                             [titleVariant]="'md'"
                             [level]="4"
                             data-cy="student-attainments-degree-programmes-section">
                <ng-template fudisContent [type]="'section'">
                  <ng-container *ngIf="attainmentNodes.degreeProgrammeAttainmentNodes.length > 0 else noAttainments">
                    <app-student-attainment-node-wrapper
                            *ngFor="let degreeProgrammeNode of attainmentNodes.degreeProgrammeAttainmentNodes"
                            [attainmentNode]="degreeProgrammeNode"
                            [expandableLevel]="5"
                    />
                  </ng-container>
                </ng-template>
              </fudis-section>
              <fudis-section [title]="t('PROFILE.ATTAINMENT.MODULES')"
                             [marginTop]="'md'"
                             [titleVariant]="'md'"
                             [level]="4"
                             data-cy="student-attainments-modules-section">
                <ng-template fudisContent [type]="'section'">
                  <ng-container *ngIf="attainmentNodes.moduleAttainmentNodes.length > 0 else noAttainments">
                    <app-student-attainment-node-wrapper
                            *ngFor="let moduleAttainmentNode of attainmentNodes.moduleAttainmentNodes"
                            [attainmentNode]="moduleAttainmentNode"
                            [expandableLevel]="5"
                    />
                  </ng-container>
                </ng-template>
              </fudis-section>
            </ng-template>
          </fudis-section>
          <fudis-section [title]="t('PROFILE.ATTAINMENT.UNATTACHED_' + selectedDisplay)"
                         [marginTop]="'lg'"
                         [level]="3"
                         data-cy="student-attainments-unattached-section">
            <ng-template fudisContent [type]="'section'">
              <fudis-section [title]="t('PROFILE.ATTAINMENT.COURSE_UNITS')"
                             [marginTop]="'md'"
                             [titleVariant]="'md'"
                             [level]="4"
                             data-cy="student-attainments-course-units-section">
                <ng-template fudisContent [type]="'section'">
                  <ng-container *ngIf="attainmentNodes.courseUnitAttainmentNodes.length > 0 else noAttainments">
                    <app-student-attainment-node-wrapper
                            *ngFor="let courseUnitAttainmentNode of attainmentNodes.courseUnitAttainmentNodes"
                            [attainmentNode]="courseUnitAttainmentNode"
                            [expandableLevel]="5"
                    />
                  </ng-container>
                </ng-template>
              </fudis-section>
              <fudis-section [title]="t('PROFILE.ATTAINMENT.ASSESSMENT_ITEMS')"
                             [marginTop]="'md'"
                             [titleVariant]="'md'"
                             [level]="4"
                             data-cy="student-attainments-assessment-items-section">
                <ng-template fudisContent [type]="'section'">
                  <ng-container *ngIf="attainmentNodes.assessmentItemAttainmentNodes.length > 0 else noAttainments">
                    <app-student-attainment-node-wrapper
                            *ngFor="let assessmentItemAttainmentNode of attainmentNodes.assessmentItemAttainmentNodes"
                            [attainmentNode]="assessmentItemAttainmentNode"
                            [expandableLevel]="5"
                    />
                  </ng-container>
                </ng-template>
              </fudis-section>
            </ng-template>
          </fudis-section>
        </ng-container>
      </ng-template>
    </fudis-section>

    <ng-template #loading>
      <div aria-busy="true" class="d-flex justify-content-center">
        <sis-small-spinner />
      </div>
    </ng-template>

    <ng-template #noAttainments>
      <fudis-body-text>{{ t('PROFILE.ATTAINMENT.EMPTY') }}</fudis-body-text>
    </ng-template>
  </div>
</ng-container>
