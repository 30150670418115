import angular from 'angular';
import moment from 'moment';
import _ from 'lodash';
import { errorServiceModule } from 'sis-components/error-handler/legacy/errorService';
import { ModalService } from 'sis-common/modal/modal.service.ts';
import { calendarEventServiceModule } from 'sis-components/calendar/calendarEvent.service';
import { localDateEditorModule } from 'sis-components/date/local-date-editor/localDateEditor/localDateEditor.component';
import { localDateFormatFilterModule } from 'sis-components/date/filters/localDateFormat/localDateFormat.filter';
import addOwnCalendarEventModalTpl from './addOwnCalendarEventModal.tpl.html';
import { AddOwnCalendarEventModalComponent } from './addOwnCalendarEventModal.component.ts';
export const addOwnCalendarEventModalModule = 'student.calendar.service.addOwnCalendarEventModal';
(function () {
  addOwnCalendarEventModalService.$inject = ["modalService"];
  addOwnCalendarEventModalController.$inject = ["dateFormat", "pattern", "calendarEventService", "$timeout", "defaultPromiseHandler"];
  angular.module(addOwnCalendarEventModalModule, ['pascalprecht.translate', 'sis-common.l10n.localeService', 'sis-common.datepicker', localDateEditorModule, calendarEventServiceModule, errorServiceModule, localDateFormatFilterModule, ModalService.downgrade.moduleName]).controller('addOwnCalendarEventModalController', addOwnCalendarEventModalController).component('addOwnCalendarEventModal', {
    bindings: {
      close: '&',
      dismiss: '&',
      resolve: '<'
    },
    template: addOwnCalendarEventModalTpl,
    controller: 'addOwnCalendarEventModalController',
    controllerAs: 'ctrl'
  }).factory('addOwnCalendarEventModal', addOwnCalendarEventModalService).constant('pattern', {
    TIME: /^([0-9]|0[0-9]|1[0-9]|2[0-3])\.[0-5][0-9]$/
  }).constant('dateFormat', {
    LOCAL_DATE: 'YYYY-MM-DD',
    LOCAL_DATETIME: 'YYYY-MM-DDTHH:mm',
    TIME: 'HH.mm'
  });

  /**
   * @ngInject
   */
  function addOwnCalendarEventModalService(modalService) {
    return {
      open(calendarEvent) {
        return modalService.open(AddOwnCalendarEventModalComponent, {
          existingCalendarEvent: calendarEvent
        }).result;
      }
    };
  }

  /**
   * @ngInject
   */
  function addOwnCalendarEventModalController(dateFormat, pattern, calendarEventService, $timeout, defaultPromiseHandler) {
    const ctrl = this;
    ctrl.eventInstances = [];
    ctrl.recursionDisabled = false;
    ctrl.multiDateEvent = false;
    ctrl.calendarEvent = {};
    ctrl.$onInit = () => {
      if (ctrl.resolve.existingCalendarEvent) {
        ctrl.editMode = true;
        getDataFromExistingCalendarEvent(ctrl.resolve.existingCalendarEvent);
      } else {
        ctrl.editMode = false;
        ctrl.calendarEvent = {
          name: undefined,
          recursEvery: 'NEVER',
          startTime: moment().format(dateFormat.LOCAL_DATETIME),
          duration: 'PT1H',
          recursUntil: undefined,
          exceptions: []
        };
        ctrl.eventStartDate = moment().format(dateFormat.LOCAL_DATE);
        ctrl.eventStartTime = moment().format(dateFormat.TIME);
        const eventEnd = moment().add(1, 'hours');
        ctrl.eventEndDate = eventEnd.format(dateFormat.LOCAL_DATE);
        ctrl.eventEndTime = eventEnd.format(dateFormat.TIME);
        updateCalendarEventInstances();
      }
    };
    function getDataFromExistingCalendarEvent(calendarEvent) {
      const startMoment = moment(calendarEvent.startTime);
      const endMoment = moment(calendarEvent.startTime).add(moment.duration(calendarEvent.duration));
      ctrl.eventStartDate = startMoment.format(dateFormat.LOCAL_DATE);
      ctrl.eventStartTime = startMoment.format(dateFormat.TIME);
      ctrl.eventEndDate = endMoment.format(dateFormat.LOCAL_DATE);
      ctrl.eventEndTime = endMoment.format(dateFormat.TIME);
      updateCalendarEventInstances();
      $timeout(() => {
        ctrl.calendarEvent = _.cloneDeep(calendarEvent);
        ctrl.calendarEvent.recursUntil = ctrl.calendarEvent.recursUntil === null ? undefined : ctrl.calendarEvent.recursUntil;
      });
      validateRecursionEndDate();
    }
    ctrl.selectDeleteMode = function () {
      ctrl.deleteMode = true;
      getDataFromExistingCalendarEvent(ctrl.resolve.existingCalendarEvent);
    };
    ctrl.handleStartDateChange = function () {
      const startTimeValue = parseTimeString(ctrl.eventStartTime);
      let endTimeValue = parseTimeString(ctrl.eventEndTime);
      const startDateTime = moment(ctrl.eventStartDate).hours(startTimeValue.hours).minutes(startTimeValue.minutes);
      const endDateTime = moment(ctrl.eventEndDate).hours(endTimeValue.hours).minutes(endTimeValue.minutes);
      if (!startDateTime.isBefore(endDateTime)) {
        endTimeValue = startDateTime.add(1, 'hour');
        ctrl.eventEndDate = endTimeValue.format(dateFormat.LOCAL_DATE);
        ctrl.eventEndTime = endTimeValue.format(dateFormat.TIME);
      }
      ctrl.handleDateChanges();
    };
    ctrl.handleDateChanges = function () {
      ctrl.calendarEvent.recursEvery = 'NEVER';
      ctrl.calendarEvent.recursUntil = undefined;
      ctrl.calendarEvent.exceptions = [];
      ctrl.isRecursUntilDateAfterEventStart = undefined;
      ctrl.isRecursUntilDateValid = undefined;
      ctrl.handleChanges();
    };
    ctrl.handleRecursionSelection = function () {
      if (ctrl.calendarEvent.recursEvery === 'NEVER') {
        ctrl.calendarEvent.recursUntil = undefined;
        ctrl.calendarEvent.exceptions = [];
        ctrl.eventInstances = [];
        ctrl.isRecursUntilDateAfterEventStart = undefined;
        ctrl.isRecursUntilDateValid = undefined;
      } else if (!angular.isDefined(ctrl.calendarEvent.recursUntil) || moment(ctrl.calendarEvent.recursUntil).isBefore(moment(ctrl.eventStartDate))) {
        ctrl.calendarEvent.recursUntil = ctrl.eventStartDate;
      }
      updateCalendarEventInstances();
    };
    ctrl.handleChanges = function () {
      if (validateTimes() && validateRange()) {
        const startTimeValue = parseTimeString(ctrl.eventStartTime);
        const endTimeValue = parseTimeString(ctrl.eventEndTime);
        const startDateTime = moment(ctrl.eventStartDate).hours(startTimeValue.hours).minutes(startTimeValue.minutes);
        const endDateTime = moment(ctrl.eventEndDate).hours(endTimeValue.hours).minutes(endTimeValue.minutes);
        ctrl.calendarEvent.startTime = startDateTime.format(dateFormat.LOCAL_DATETIME);
        ctrl.calendarEvent.duration = moment.duration(endDateTime.diff(ctrl.calendarEvent.startTime)).toJSON();
        if (!endDateTime.isBefore(startDateTime.clone().add(1, 'days'))) {
          ctrl.recursionDisabled = true;
          ctrl.calendarEvent.recursEvery = 'NEVER';
          ctrl.calendarEvent.recursUntil = undefined;
          ctrl.calendarEvent.exceptions = [];
        } else {
          ctrl.recursionDisabled = false;
        }
        ctrl.multiDateEvent = !endDateTime.isSame(startDateTime, 'day');
        updateCalendarEventInstances();
      }
    };
    ctrl.ok = function () {
      if (!ctrl.editMode) {
        return calendarEventService.save(ctrl.calendarEvent).then(ctrl.close).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
      }
      return calendarEventService.update(ctrl.calendarEvent).then(ctrl.close).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    };
    ctrl.delete = function () {
      return calendarEventService.delete(ctrl.calendarEvent).then(ctrl.close).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    };
    ctrl.toggleExceptionDate = function (calendarEvent, eventInstance) {
      calendarEventService.toggleExceptionDate(calendarEvent, eventInstance);
      updateCalendarEventInstances();
    };
    ctrl.isFormDataValid = function () {
      if (ctrl.calendarEvent.recursEvery === 'NEVER') {
        return ctrl.isRangeValid && validateTimes();
      }
      return ctrl.isRangeValid && validateTimes() && ctrl.isRecursUntilDateValid !== false && ctrl.isRecursUntilDateAfterEventStart !== false;
    };
    ctrl.validateRecursionEndDate = validateRecursionEndDate;
    ctrl.isEventInstanceExclusionPossible = function () {
      return ctrl.eventInstances.length - ctrl.calendarEvent.exceptions.length > 1;
    };
    function updateCalendarEventInstances() {
      ctrl.eventInstances = calendarEventService.createEventInstances(ctrl.calendarEvent);
    }
    function validateTimes() {
      ctrl.isStartTimeValid = isValidTime(ctrl.eventStartTime);
      ctrl.isStartDateValid = isValidDate(ctrl.eventStartDate);
      ctrl.isEndTimeValid = isValidTime(ctrl.eventEndTime);
      ctrl.isEndDateValid = isValidDate(ctrl.eventEndDate);
      if (ctrl.isStartTimeValid && ctrl.isEndTimeValid && ctrl.isStartDateValid && ctrl.isEndDateValid) {
        return true;
      }
      ctrl.isRangeValid = undefined;
      return false;
    }
    function validateRange() {
      const startTimeValue = parseTimeString(ctrl.eventStartTime);
      const startMoment = moment(ctrl.eventStartDate).hours(startTimeValue.hours).minutes(startTimeValue.minutes);
      const endTimeValue = parseTimeString(ctrl.eventEndTime);
      const endMoment = moment(ctrl.eventEndDate).hours(endTimeValue.hours).minutes(endTimeValue.minutes);
      ctrl.isRangeValid = startMoment.isBefore(endMoment, 'minutes');
      return ctrl.isRangeValid;
    }
    function validateRecursionEndDate() {
      if (ctrl.calendarEvent.recursEvery !== 'NEVER' && ctrl.calendarEvent.recursUntil !== '') {
        const startTimeValue = parseTimeString(ctrl.eventStartTime);
        const startMoment = moment(ctrl.eventStartDate).hours(startTimeValue.hours).minutes(startTimeValue.minutes);
        const recursUntilMoment = moment(ctrl.calendarEvent.recursUntil);
        ctrl.isRecursUntilDateValid = recursUntilMoment.isValid();
        if (ctrl.isRecursUntilDateValid) {
          if (recursUntilMoment.isAfter(startMoment, 'days')) {
            ctrl.isRecursUntilDateAfterEventStart = true;
            ctrl.calendarEvent.recursUntilDate = ctrl.recursUntilDate;
            updateCalendarEventInstances();
          } else {
            ctrl.isRecursUntilDateAfterEventStart = false;
            ctrl.eventInstances = [];
          }
        } else {
          ctrl.isRecursUntilDateAfterEventStart = undefined;
          ctrl.eventInstances = [];
        }
      }
      if (ctrl.eventInstances.length - ctrl.calendarEvent.exceptions.length < 1) {
        ctrl.calendarEvent.exceptions = [];
        updateCalendarEventInstances();
      }
    }
    function isValidTime(timeValue) {
      return timeValue && pattern.TIME.test(timeValue);
    }
    function isValidDate(dateValue) {
      return moment(dateValue).isValid();
    }
    function parseTimeString(value) {
      if (!_.isNil(value)) {
        let minutes = 0;
        const parts = value.split('.');
        const hours = parseInt(parts[0], 10);
        if (parts.length > 0) {
          minutes = parseInt(parts[1], 10);
        }
        return {
          hours,
          minutes
        };
      }
      return {
        hours: 0,
        minutes: 0
      };
    }
  }
})();