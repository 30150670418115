export const previewModeModule = 'student.common.utils.previewMode';
angular.module(previewModeModule, []).factory('previewMode', function () {
  var documentStates = ['ACTIVE', 'DRAFT'];
  var isPreviewMode = false;
  return {
    setPreviewMode: function () {
      isPreviewMode = true;
    },
    isPreviewMode: function () {
      return isPreviewMode;
    },
    documentStatesForPreview: function () {
      return documentStates;
    }
  };
});