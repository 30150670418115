<div class="row form-control-static">
    <div class="col-2 col-md-2 col-lg-2">
        <span>{{ learningEnvironment.language | uppercase }}</span>
    </div>
    <div class="col-5 col-md-10 col-lg-5">
      <fudis-link [externalLink]="learningEnvironment.url" [title]="linkName"></fudis-link>
    </div>
    <div class="col-5 col-md-12 col-lg-5" *ngIf="learningEnvironment.primary">
        <span translate>SIS_COMPONENTS.LEARNING_ENVIRONMENT_EDITOR.PRIMARY</span>
    </div>
    <div class="col-5 col-md-12 col-lg-5" *ngIf="learningEnvironment.primary">

    </div>
</div>
