import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { DegreeProgrammeAttainmentApplicationQuestionnaire, OtmId } from 'common-typescript/types';

import { EntityService } from './entity.service';

const CONFIG = {
    ENDPOINTS: {
        backend: '/kori/api',
        degreeProgrammeAttainmentApplicationQuestionnaire(degreeProgrammeId: OtmId) {
            return `${this.backend}/degree-programme-attainment-application-questionnaires/${degreeProgrammeId}`;
        },
    },
};

@Injectable({
    providedIn: 'root',
})
@NgEntityServiceConfig({
    baseUrl: CONFIG.ENDPOINTS.backend,
    resourceName: 'degree-programme-attainment-application-questionnaires',
})
export class DegreeProgrammeAttainmentApplicationQuestionnaireService extends EntityService<DegreeProgrammeAttainmentApplicationQuestionnaireState> {

    constructor() {
        super(DegreeProgrammeAttainmentApplicationQuestionnaireStore, DegreeProgrammeAttainmentApplicationQuestionnaireQuery);
    }

    getByDegreeProgrammeId(degreeProgrammeId: OtmId) {
        return this.getHttp().get<DegreeProgrammeAttainmentApplicationQuestionnaire>(CONFIG.ENDPOINTS.degreeProgrammeAttainmentApplicationQuestionnaire(degreeProgrammeId));
    }

    updateDegreeProgrammeQuestionnaire(degreeProgrammeId: OtmId, questionnaire: DegreeProgrammeAttainmentApplicationQuestionnaire) {
        return this.getHttp().put<DegreeProgrammeAttainmentApplicationQuestionnaire>(CONFIG.ENDPOINTS.degreeProgrammeAttainmentApplicationQuestionnaire(degreeProgrammeId), questionnaire);
    }
}

type DegreeProgrammeAttainmentApplicationQuestionnaireState = EntityState<DegreeProgrammeAttainmentApplicationQuestionnaire>;

@StoreConfig({ name: 'degree-programme-attainment-application-questionnaires' })
class DegreeProgrammeAttainmentApplicationQuestionnaireStore extends EntityStore<DegreeProgrammeAttainmentApplicationQuestionnaireState> {}

class DegreeProgrammeAttainmentApplicationQuestionnaireQuery extends QueryEntity<DegreeProgrammeAttainmentApplicationQuestionnaireState> {
    constructor(protected store: DegreeProgrammeAttainmentApplicationQuestionnaireStore) {
        super(store);
    }
}
