import { ChangeDetectorRef, Component, Input, ViewEncapsulation } from '@angular/core';
import { AssessmentItem, CourseUnitRealisation, CourseUnitRealisationSeats } from 'common-typescript/types';
import { trackByEntityId } from 'sis-components/util/utils';

@Component({
    selector: 'app-assessment-item-teaching',
    templateUrl: './assessment-item-teaching.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class AssessmentItemTeachingComponent {

    @Input() assessmentItem: AssessmentItem;
    @Input() courseUnitRealisations: CourseUnitRealisation[];
    @Input() seatCounts: CourseUnitRealisationSeats[];
    @Input() productIsFull: boolean;

    readonly entityId = trackByEntityId;

    constructor(private ref: ChangeDetectorRef) {}

    detectChanges() {
        this.ref.detectChanges();
    }

    curIsFull(cur: CourseUnitRealisation) {
        const curSeatCount = this.seatCounts?.length && this.seatCounts.find(seatCount => seatCount.curId === cur.id);
        return curSeatCount?.availableSeats === 0;
    }
}
