import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { TranslocoService } from '@ngneat/transloco';
import { of } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';
import { ConfigService } from 'sis-common/config/config.service';
import { EnvironmentService } from 'sis-common/environmentService/environment.service';

import { UniversityService } from '../service/university.service';

@Component({
    selector: 'sis-footer',
    templateUrl: './footer.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {

    @Input({ required: true }) aboutHref: string;

    constructor(
        private transloco: TranslocoService,
        private universityService: UniversityService,
        private configService: ConfigService,
        private environmentService: EnvironmentService,
    ) {}

    registryDescriptionUrl = toSignal(this.universityService.getCurrentUniversitySettings()
        .pipe(
            map(settings => settings?.registryDescriptionUrl),
            catchError(() => of(null)),
        ));

    featureToggleAllowed = this.configService.get().isTestModeEnabled;
    featureToggleUrl = `/${this.environmentService.frontendName.toLowerCase()}/feature-toggle`;

    currentLanguage = toSignal(this.transloco.langChanges$
        .pipe(startWith(this.transloco.getActiveLang() ?? this.transloco.getDefaultLang())));
}
