import angular from 'angular';
import searchFilterTpl from './searchFilterMulti.tpl.html';
export const searchFilterMultiModule = 'sis-components.search.searchFilterMulti';
angular.module(searchFilterMultiModule, []).component('searchFilterMulti', {
  bindings: {
    allFilters: '<',
    filterMatchesSearchCb: '&',
    filterName: '<',
    filterSearch: '<',
    filtersLimit: '<',
    getFilterIndexCb: '&',
    getFilterNameCb: '&',
    helpText: '<',
    isInitialized: '<',
    isParentFilterCb: '&',
    isValueSelectedCb: '&',
    listSelected: '&',
    popoverIsOpen: '<',
    primaryFilters: '<',
    searching: '<',
    searchWithinFilter: '=',
    secondaryFilters: '<',
    startIndex: '<',
    submitFilter: '&',
    toggleValueCb: '&'
  },
  template: searchFilterTpl,
  controllerAs: 'ctrl',
  controller: ["$scope", function ($scope) {
    const ctrl = this;

    // Invoke callback methods that take arguments in a way that allows to keep the template file unchanged
    // (that used to call controller methods directly when UI Bootstrap was used)
    ctrl.filterMatchesSearch = filter => ctrl.filterMatchesSearchCb()(filter);
    ctrl.getFilterIndex = filter => ctrl.getFilterIndexCb()(filter);
    ctrl.getFilterName = filter => ctrl.getFilterNameCb()(filter);
    ctrl.isParentFilter = filter => ctrl.isParentFilterCb()(filter);
    ctrl.isValueSelected = value => ctrl.isValueSelectedCb()(value);
    ctrl.toggleValue = (value, form, event) => ctrl.toggleValueCb()(value, form, event);
    $scope.$watch('ctrl.popoverIsOpen', newVal => {
      if (!newVal) {
        // Reset form when popover closes
        $scope.filterSelectorForm.$setPristine();
      }
    });
  }]
});