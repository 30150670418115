PlanMessageController.$inject = ["$element", "$timeout", "commonMessageService"];
import _ from 'lodash';
import angular from 'angular';
import { ModuleBoxComponent } from '../module/module-box/module-box.component.ts';
import planMessageTpl from './planMessage.tpl.html';
import { moduleContentApplicationMessageModule } from './moduleContentApplicationMessage.comp';
angular.module('sis-components.messenger.planMessage', [moduleContentApplicationMessageModule, 'sis-common.l10n.localeService', 'pascalprecht.translate', 'sis-components.service.commonMessageService', 'sis-components.date.localDateFormatFilter', 'sis-components.courseUnit', ModuleBoxComponent.downgrade.moduleName]).component('planMessage', {
  template: planMessageTpl,
  controller: PlanMessageController,
  bindings: {
    message: '<',
    validatablePlan: '<?'
  }
});
function PlanMessageController($element, $timeout, commonMessageService) {
  const $ctrl = this;
  $ctrl.isSuggestionForAddition = () => _.isObject($ctrl.message) && $ctrl.message.operationType === 'SUGGEST_ADD' && (!_.isEmpty($ctrl.message.courseUnitId) || !_.isEmpty($ctrl.message.moduleId));
  $ctrl.getMessageTypeTranslationKey = message => commonMessageService.getMessageTypeTranslationKey(message);
  $ctrl.messageHasCuOrModuleBox = () => $ctrl.message.isPlanCourseUnitComment || $ctrl.message.isPlanModuleComment || $ctrl.message.isPlanCustomStudyDraftComment || $ctrl.isSuggestionForAddition();
}