import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StudyEvent } from 'common-typescript/types';
import _ from 'lodash';
import { LocaleService } from 'sis-common/l10n/locale.service';

@Pipe({ name: 'studyEventWeekdayOrInterval' })
export class StudyEventWeekdayOrIntervalPipe implements PipeTransform {

    constructor(
        private datePipe: DatePipe,
        private translateService: TranslateService,
        private localeService: LocaleService,
    ) {}

    transform(studyEvent: StudyEvent): string {
        if (!studyEvent) {
            return '';
        }
        if (_.includes([null, 'NEVER', 'WEEKLY'], studyEvent.recursEvery)) {
            return this.getWeekDay(studyEvent.startTime);
        }
        if (studyEvent.recursEvery === 'DAILY') {
            return this.translateService.instant('SIS_COMPONENTS.STUDY_EVENT_RECURSION.DAILY');
        }
        if (studyEvent.recursEvery === 'EVERY_SECOND_WEEK') {
            return _.join(
                [
                    this.translateService.instant('SIS_COMPONENTS.STUDY_EVENT_RECURSION.EVERY_SECOND_WEEK'),
                    this.getWeekDay(studyEvent.startTime),
                ],
                ' ');
        }
        if (studyEvent.recursEvery === 'MONTHLY') {
            return this.translateService.instant('SIS_COMPONENTS.STUDY_EVENT_RECURSION.MONTHLY');
        }
        return '';
    }

    getWeekDay(dateTime: string) {
        return this.datePipe.transform(dateTime, 'EEE', null, this.localeService.getCurrentLanguage());
    }
}
