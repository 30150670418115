<div *ngIf="hasInvalidSelections" class="invalid-selections">
    <h4 translate>SIS_COMPONENTS.PLAN.INVALID_SELECTIONS.TITLE</h4>

    <div class="rule-body">
        <div *ngFor="let courseUnit of invalidCourseUnitSelections">
            <sis-plan-course-unit [courseUnit]="courseUnit"
                              [planValidationResult]="planValidationResult"
                              [planStateObject]="planStateObject"
                              [planData]="planData"
                              [parentModule]="parentModule">
                <div course-unit-action class="additional-box">
                    <sis-button class="button-box" link="true"
                                (clicked)="removeCourseUnit(courseUnit)"
                                [ariaLabel]="'ARIA_LABEL.DELETE' |translate">
                        <sis-icon icon="delete"></sis-icon>
                    </sis-button>
                </div>
            </sis-plan-course-unit>
        </div>

        <div *ngFor="let module of invalidModuleSelections">
            <sis-rule-module-box [module]="module"
                                 [planValidationResult]="planValidationResult"
                                 [planStateObject]="planStateObject"
                                 [planData]="planData"
                                 [parentModule]="parentModule">
                <div module-action class="additional-box">
                    <sis-button class="button-box" link="true"
                                (clicked)="removeModule(module)"
                                [ariaLabel]="'ARIA_LABEL.DELETE' |translate">
                        <sis-icon icon="delete"></sis-icon>
                    </sis-button>
                </div>
            </sis-rule-module-box>
        </div>

        <div *ngFor="let customCourseUnitAttainment of invalidCustomCourseUnitAttainmentSelections">
            <sis-plan-custom-course-unit-attainment
                    [customCourseUnitAttainment]="customCourseUnitAttainment"
                    [planStateObject]="planStateObject">
                <div custom-course-unit-attainment-action class="additional-box">
                    <sis-button class="button-box" link="true"
                                (clicked)="removeCustomCourseUnitAttainment(customCourseUnitAttainment)"
                                [ariaLabel]="'ARIA_LABEL.DELETE' |translate">
                        <sis-icon icon="delete"></sis-icon>
                    </sis-button>
                </div>
            </sis-plan-custom-course-unit-attainment>
        </div>
        <div *ngFor="let customCourseUnitAttainmentId of customCourseUnitAttainmentSelectionsWithInvalidAttainmentReference">
            <sis-plan-custom-course-unit-attainment>
                <div unknown-custom-course-unit-attainment-action class="additional-box">
                    <sis-button class="button-box" link="true"
                                (clicked)="removeCustomCourseUnitAttainmentById(customCourseUnitAttainmentId)"
                                [ariaLabel]="'ARIA_LABEL.DELETE' |translate">
                        <sis-icon icon="delete"></sis-icon>
                    </sis-button>
                </div>
            </sis-plan-custom-course-unit-attainment>
        </div>

        <div *ngFor="let customModuleAttainment of invalidCustomModuleAttainmentSelections">
            <sis-rule-custom-module-attainment-box [customModuleAttainment]="customModuleAttainment"
                                                   [planValidationResult]="planValidationResult"
                                                   [planStateObject]="planStateObject"
                                                   [planData]="planData">
                <div custom-module-attainment-action class="additional-box">
                    <sis-button class="button-box" link="true"
                                (clicked)="removeCustomModuleAttainment(customModuleAttainment)"
                                [ariaLabel]="'ARIA_LABEL.DELETE' |translate">
                        <sis-icon icon="delete"></sis-icon>
                    </sis-button>
                </div>
            </sis-rule-custom-module-attainment-box>
        </div>
        <div *ngFor="let customModuleAttainmentId of customModuleAttainmentSelectionsWithInvalidAttainmentReference">
            <sis-rule-custom-module-attainment-box>
                <div unknown-custom-module-attainment-action class="additional-box">
                    <sis-button class="button-box" link="true"
                                (clicked)="removeCustomModuleAttainmentById(customModuleAttainmentId)"
                                [ariaLabel]="'ARIA_LABEL.DELETE' |translate">
                        <sis-icon icon="delete"></sis-icon>
                    </sis-button>
                </div>
            </sis-rule-custom-module-attainment-box>
        </div>

    </div>
</div>
