<fudis-footer *transloco="let t" class="d-flex flex-column">
  <ng-template fudisFooterContentLeft>
    <fudis-link
      [title]="t('SIS_COMPONENTS.NAVIGATION.JOIN_FUNILAB')"
      [externalLink]="'https://digitalist.leanlab.co/cgi/view?sid=20b01d167963a06a'"
    />
    <fudis-link
       *ngIf="featureToggleAllowed"
       [title]="t('SIS_COMPONENTS.NAVIGATION.FEATURE_TOGGLE_URL')"
       [href]="featureToggleUrl"
    />
  </ng-template>
  <ng-template fudisFooterContentRight>
    <fudis-link
      *ngIf="registryDescriptionUrl()"
      [title]="t('SIS_COMPONENTS.NAVIGATION.REGISTRY_DESCRIPTION_URL')"
      [externalLink]="registryDescriptionUrl() + '?lang=' + currentLanguage()"
    />
    <fudis-link
      [title]="t('SIS_COMPONENTS.NAVIGATION.ACCESSIBILITY_STATEMENT_URL')"
      [externalLink]="'/accessibility-statement/' + currentLanguage()"
    />
    <fudis-link
      *ngIf="aboutHref"
      [title]="t('ABOUT.TITLE')"
      [href]="aboutHref"
    />
  </ng-template>
</fudis-footer>
