import angular from 'angular';
import _ from 'lodash';
import planCustomModuleAttainmentTpl from './planCustomModuleAttainment.tpl.html';
(function () {
  PlanCustomModuleAttainmentController.$inject = ["$q", "$state", "PlanState", "uiContext", "moduleType", "moduleService", "validAttainmentFilterService", "colorService", "planStudyRightService", "moduleAttainmentApplicationService", "defaultPromiseHandler", "commonAttainmentService"];
  angular.module('student.plan').component('planCustomModuleAttainment', {
    template: planCustomModuleAttainmentTpl,
    bindings: {
      customModuleAttainment: '<',
      validatablePlan: '<',
      planValidationResult: '<',
      educationOptions: '<',
      level: '<',
      studyRight: '<'
    },
    controller: PlanCustomModuleAttainmentController
  });
  function PlanCustomModuleAttainmentController(
  // NOSONAR
  $q, $state, PlanState, uiContext, moduleType, moduleService, validAttainmentFilterService, colorService, planStudyRightService, moduleAttainmentApplicationService, defaultPromiseHandler, commonAttainmentService) {
    const $ctrl = this;
    $ctrl.$onChanges = function () {
      updateChildStudies();
      $ctrl.classes = getClasses();
      $ctrl.isInvalidSelection = isInvalidSelection();
    };
    $ctrl.$onInit = () => {
      $ctrl.isAboutToExpire = isAboutToExpire($ctrl.customModuleAttainment);
      $ctrl.attainedCredits = getAttainedCredits();
      $ctrl.hasCustomCourseUnitAttainments = hasCustomCourseUnitAttainments();
      $ctrl.hasCustomModuleAttainments = hasCustomModuleAttainments();
    };
    $ctrl.isSelected = isSelected;
    $ctrl.toggleCollapsed = toggleCollapsed;
    $ctrl.isCollapsed = isCollapsed;
    function getClasses() {
      const classes = [`tree-list-item-level-${$ctrl.level}`];
      if ($ctrl.level > 1) {
        classes.push('tree-list-item', 'clearfix');
      }
      return classes.join(' ');
    }
    function isSelected(module) {
      return uiContext.isSelected(module);
    }
    function getAttainedCredits() {
      return _.get($ctrl.customModuleAttainment, 'credits');
    }
    function toggleCollapsed() {
      uiContext.toggleCollapsed($ctrl.customModuleAttainment);
    }
    function isCollapsed() {
      return uiContext.isCollapsed($ctrl.customModuleAttainment);
    }
    function isInvalidSelection() {
      const customModuleAttainmentValidationResults = _.get(uiContext, 'planContext.planValidationResult.customModuleAttainmentValidationResults');
      const customModuleAttainmentValidationResult = _.get(customModuleAttainmentValidationResults, _.get($ctrl.customModuleAttainment, 'id'));
      return _.get(customModuleAttainmentValidationResult, 'invalidSelection') === true;
    }
    function isAboutToExpire(customModuleAttainment) {
      if (!customModuleAttainment) {
        return false;
      }
      return validAttainmentFilterService.isAttainmentAboutToExpire(customModuleAttainment) && !isParentAttained(customModuleAttainment);
    }
    function isParentAttained(customModuleAttainment) {
      if (!customModuleAttainment) {
        return false;
      }
      return validAttainmentFilterService.isAttached(customModuleAttainment, $ctrl.validatablePlan.getAllAttainments());
    }
    function hasCustomCourseUnitAttainments() {
      return !_.isEmpty($ctrl.selectedCustomCourseUnitAttainments);
    }
    function hasCustomModuleAttainments() {
      return !_.isEmpty($ctrl.selectedCustomModuleAttainments);
    }
    function updateChildStudies() {
      const attainmentNodes = _.get($ctrl.customModuleAttainment, 'nodes') || [];
      const childStudies = commonAttainmentService.getChildStudiesFromAttainmentNodes(attainmentNodes, $ctrl.validatablePlan);
      $ctrl.selectedCourseUnits = childStudies.courseUnits;
      $ctrl.selectedModules = childStudies.modulesAndGroupNodes;
      $ctrl.selectedCustomCourseUnitAttainments = _.map(childStudies.customCourseUnitAttainmentIds, id => $ctrl.validatablePlan.getCustomCourseUnitAttainment(id));
      $ctrl.selectedCustomModuleAttainments = _.map(childStudies.customModuleAttainmentIds, id => $ctrl.validatablePlan.getCustomModuleAttainment(id));
    }
  }
})();