<ng-container *transloco="let t">
  <div *ngIf="courseUnit" class="container-fluid ng-course-unit-info-modal-header">
    <div class="row">
      <div class="col-7 pe-0">
        <div class="mt-4">
          <h2 class="modal-title" id="student-course-unit-modal-heading" tabindex="0">
            {{ courseUnit.name | localizedString}} ({{ courseUnit.credits | creditRange }})
            <sis-non-active-document-state-badge [documentState]="courseUnit.documentState"></sis-non-active-document-state-badge>
          </h2>
        </div>
        <div class="course-unit-code mb-3">
          <span>{{ courseUnit.code }} | {{ 'ENTITY_NAMES.courseUnit' | translate }}</span>
        </div>
      </div>
      <div class="col-5 ps-0">
        <div class="float-end mt-3 pe-3">
          <sis-button dataCy="course-unit-info-header-close-button" link="true" (clicked)="closeModal.emit()" [ariaLabel]="'ARIA_LABEL.CLOSE_BROCHURE' | translate">
            <sis-icon icon="close-big" color="light"></sis-icon>
          </sis-button>
        </div>
      </div>
    </div>
    <ng-container>
      <div class="row">
        <div class="col-xl-4 col-md-6 col-sm-12">
          <!-- Version selector should be here -->
        </div>
        <div class="col-lg-6 ms-xl-5 ps-xl-5" data-cy="course-unit-info-modal-info-list">
          <app-course-unit-info-modal-header-info-list
            [courseUnit]="courseUnit"
            [validatablePlan]="validatablePlan">
          </app-course-unit-info-modal-header-info-list>
        </div>
      </div>
      <div aria-atomic="true" aria-live="polite" role="region" class="pe-md-3">
        <!-- notifications should be here -->
      </div>
    </ng-container>
    <div class="bottom-section">
      <app-course-unit-info-modal-header-tabs *ngIf="tabs"
                                              [openTab]="'BASIC'"
                                              [tabs]="tabs"
                                              (selectTab)="selectTab()">
      </app-course-unit-info-modal-header-tabs>
    </div>
  </div>
</ng-container>
