import { HttpErrorResponse } from '@angular/common/http';
import { EMPTY, forkJoin, Observable, of, Subject, throwError } from 'rxjs';
import { bufferTime, catchError, filter, first, map, mergeMap, share, switchMap, tap } from 'rxjs/operators';

export interface DataloaderConfig<T, R, S> {
    getByIdsCall?: (ids: T[]) => Observable<R[]>;
    successEntitiesCallback?: (entities: R[]) => void;
    resultExtractor?: (id: T, entities: R[]) => S;
    bufferSize?: number;
    bufferTime?: number;
}

/**
 * Combines single id's received through load-method and calls async method with batches.
 * Waits for BUFFER_TIME milliseconds or count of distinct ids to reach BUFFER_SIZE before sending a batch.
 * The backend request is cached for each id.
 * Cache is invalidated when response is resolved.
 * If single batch fails for HTTP 403/404, then each id is retried individually.
 *
 * T - the id type
 * R - the type of array that is received with http. In other words we expect to receive R[] from getByIds http call.
 * S - the type of desired response: usually same as R but also could be R[]
 *
 * Example of normal use case is SisuDataLoader<OtmId, CourseUnit, CourseUnit> or
 * SisuDataLoader<OtmId, TuitionFeeObligationPeriod, TuitionFeeObligationPeriod[]>. Latter one is used when multiple entities are
 * expected for single id.
 */
export class SisuDataLoader<T, R, S> {
    /**
     *
     * @param getByIdsCall callback is used to get entities by ids. Usually this means that it maps ids to HTTP GET request. Instead of HTTP
     * GET it could be anything.
     * @param successEntitiesCallback what to do with entities (non errors) that were retrieved from result.
     * @param resultExtractor how to map full result (of getByIdsCall) into a response for single id.
     * @param BUFFER_SIZE max size of batch. Default 50.
     * @param BUFFER_TIME max interval (millis) between receiving "first" id of batch and sending a batch. Default 20.
     */
    constructor(
        dataloaderConfig: DataloaderConfig<T, R, S>,
    ) {
        this.getByIdsCall = dataloaderConfig.getByIdsCall ?? ((_ids: T[]) => EMPTY);
        this.successEntitiesCallback = dataloaderConfig.successEntitiesCallback ?? (() => {});
        this.resultExtractor = dataloaderConfig.resultExtractor ?? ((_id: T, _entities: R[]) => undefined);
        this.BUFFER_SIZE = dataloaderConfig.bufferSize ?? 50;
        this.BUFFER_TIME = dataloaderConfig.bufferTime ?? 20;

        this.requests =
            this.keySubject
                .pipe(
                    filter(id => !this.distinctIds.has(id)),
                    tap(id => this.distinctIds.add(id)),
                    bufferTime(this.BUFFER_TIME, null, this.BUFFER_SIZE),
                    filter(ids => ids.length > 0),
                    mergeMap(
                        ids =>
                            this.fetchBatch(Array.from(new Set(ids)))
                                .pipe(
                                    tap(() => ids.forEach(id => this.distinctIds.delete(id))),
                                    catchError((err) => {
                                        ids.forEach(id => this.distinctIds.delete(id));
                                        return throwError(() => err);
                                    }),
                                ),
                    ),
                    share(),
                );
    }

    private readonly getByIdsCall: (ids: T[]) => Observable<R[]>;
    private readonly successEntitiesCallback: (entities: R[]) => void;
    private readonly resultExtractor: (id: T, entities: R[]) => S;
    private readonly BUFFER_SIZE;
    private readonly BUFFER_TIME;

    /**
     * This will ensure distinct ids and duplicate ids will be resolved when the response "of the first id" is received.
     */
    private readonly distinctIds = new Set<T>();
    private readonly keySubject = new Subject<T>();
    private readonly requests: Observable<BatchEvent<T, S>>;

    load(id: T): Observable<S> {
        return new Observable<BatchEvent<T, S>>((observer) => {
            const subscription = this.requests.subscribe(observer);
            subscription.add(() => {
                this.distinctIds.delete(id);
            });
            this.keySubject.next(id);
        })
            .pipe(
                first(batchEvent => batchEvent.successEvents.has(id) || batchEvent.errorEvents.has(id)),
                switchMap((batchEvent: BatchEvent<T, S>) => {
                    if (!batchEvent.successEvents.has(id)) {
                        return throwError(() => batchEvent.errorEvents.get(id));
                    }
                    return of(batchEvent.successEvents.get(id));
                }),
                share(),
            );
    }

    /**
     * Fetch batch, convert entity array to Map<id, entity>. If response status is 403/404 then handle error in other method.
     */
    private fetchBatch(ids: T[]): Observable<BatchEvent<T, S>> {
        return this.getByIdsCall(ids)
            .pipe(
                map((entities: R[]) => {
                    const successEvents: Map<T, S> = new Map();
                    this.successEntitiesCallback(entities);
                    ids.forEach((id) => {
                        successEvents.set(id, this.resultExtractor(id, entities));
                    });
                    return { successEvents, errorEvents: new Map() };
                }),
                catchError((err: HttpErrorResponse) => {
                    if (![403, 404].includes(err.status)) {
                        // eslint-disable-next-line @typescript-eslint/no-throw-literal
                        throw err;
                    }
                    if (ids.length === 1) {
                        const errorEvents = new Map();
                        errorEvents.set(ids[0], err);
                        return of({ errorEvents, successEvents: new Map() });
                    }
                    return this.handleBatchError(ids);
                }),
            );
    }

    /**
     * Because of failure and we don't know from which individual sources the requests have been made, we will fetch entity individually.
     * This way we will detect which id's cause 403/404 error and we can return those errors.
     */
    private handleBatchError(ids: T[]): Observable<BatchEvent<T, S>> {
        const fetchOne = (id: T): Observable<BatchEventItem<T, R[]>> =>
            this.getByIdsCall([id])
                .pipe(
                    map((entities: R[]) => ({ id, $$entity: entities })),
                    catchError((err: HttpErrorResponse) => of({ id, $$error: err })),
                );

        return forkJoin(ids.map(id => fetchOne(id)))
            .pipe(
                map((items: BatchEventItem<T, R[]>[]) => {
                    const errorEvents: Map<T, Error> = new Map();
                    const successEvents: Map<T, S> = new Map();
                    const successEntities: R[] = [];
                    items.forEach((item) => {
                        if (item.$$entity) {
                            successEntities.push(...item.$$entity);
                            successEvents.set(item.id, this.resultExtractor(item.id, item.$$entity));
                        }
                        if (item.$$error) {
                            errorEvents.set(item.id, item.$$error);
                        }
                    });
                    this.successEntitiesCallback(successEntities);
                    return { successEvents, errorEvents };
                }),
            );
    }
}

interface BatchEvent<T, R> {
    successEvents: Map<T, R>;
    errorEvents: Map<T, Error>;
}

interface BatchEventItem<T, R> {
    id: T;
    $$entity?: R;
    $$error?: Error;
}
